// @flow

import React from "react";

type Props = {
    label: string,
    isSelected: boolean,
    onCheckboxChange: any,
    name: string
};

/** Checkbox instance counter. */
let lastId: number = 0;

class Checkbox extends React.Component<Props> {
    /** Instance ID */
    id: number;

    /**
     * Checkbox constructor.
     * 
     * @param {Props} props Checkbox properties. 
     */
    constructor(props: Props) {
        super(props);
        lastId++;
        this.id = lastId;
    }

    /**
     * Render checkbox.
     */
    render() {
        const inputId = `checkbox-${this.id}`;
        return (
            <div className="custom-control custom-checkbox">
                <input
                    id={inputId}
                    type="checkbox"
                    name={this.props.name}
                    checked={this.props.isSelected}
                    onChange={this.props.onCheckboxChange}
                    className="custom-control-input"
                />
                <label className="custom-control-label" htmlFor={inputId}>{this.props.label}</label>
            </div>
        );
    }
}

export default Checkbox;
