// @flow

// main imports
import React, { Component } from "react";
import ReactRouterPropTypes from "react-router-prop-types";
import { FormattedMessage } from "react-intl";

// backend
import { getBackend as getBackend2 } from "../../lib/backend/Backend2";
import * as t from "../../lib/backend/manufacturing2.generated.types";
import { ILabelValuePairEx, ILabelValuePair, getLinkForUserDocumentation, UserDocumentationLinksEnum } from "../../lib/Util";
import * as BusinessLogic from "../../lib/BusinessLogic";
import { PEOPLE_TAGS, PEOPLE_TAGS_DEFAULT } from "../../lib/ManufacturingTags.generated";
import { PEOPLE_TAGS as PEOPLE_TAGS_EDITOR } from "../../lib/ManufacturingTagsEditor.generated";

import { getLang } from "../IntlProviderWrapper";

// import subcomponents
import ErrorComponent from "../ErrorComponent";
import { translate } from "../IntlProviderWrapper";
import Toggle from "react-toggle";
import Select from "react-select";
import PeopleShiftEdit from "./PeopleShiftEdit";

import ReactTable from "../react/ReactTable";
import {
    rowStyle,
    mapData,
    mapUnknownData,
    Columns,
    onChangeUpdateTags,
    getInitialState
} from "../react/ReactTableTags";

// defining types
type Props = {
    match: ReactRouterPropTypes.match,
    history: ReactRouterPropTypes.history,
    location: ReactRouterPropTypes.location
};

type State = {
    active: boolean,
    adjuster: boolean,
    componentTitle: string,
    editFlag: boolean,
    error: string,
    external_id: string,
    name: string,
    plants: ILabelValuePair[],
    line_groups: ILabelValuePairEx[],
    plants_choice: t.IPlantData[],
    shift_preference: boolean[],
    success: string,
    tags: t.ITags,
    ted_id: string,
    uuid: string,
    has_enabled_future_shifts: boolean
};

/**
 * Displaying production lines list.
 */
class PeopleCreate extends Component<Props, State> {
    /**
     * Constructor.
     *
     * @param {Props} props Props object.
     * @param {State} state State object.
     */

    constructor(props: Props, state: State) {
        super(props, state);

        // prepare default tags
        const tags: t.ITags = {}
        tags[PEOPLE_TAGS.reduced_worktime] = PEOPLE_TAGS_DEFAULT.reduced_worktime;
        // initialize state
        this.state = {
            uuid: "",
            name: "",
            external_id: "",
            active: true,
            adjuster: false,
            ted_id: "",
            shift_preference: [false, false, false],
            componentTitle: translate("Manufacturing.People.new_person_details", "New Person Details"),
            error: "",
            success: "",
            editFlag: false,
            tags,
            plants: [],
            plants_choice: [],
            line_groups: [],
            has_enabled_future_shifts: false
        }
    }

    /**
     * Loading person data.
     */
    async componentDidMount() {
        try {
            const res = await getBackend2().manufacturing.getPlants({});
            const plants = res.plants;
            const line_groups = await this.getLineGroupsForDropdown(plants);
            this.setState({ plants_choice: plants, line_groups });
        } catch (err) {
            this.setState({ error: err.message });
        }
    }

    getLineGroupsForDropdown(plants: t.IPlantData[]): ILabelValuePairEx[] {
        const line_groups = [];

        plants.forEach(plant => {
            const line_groups_for_user = BusinessLogic.getLineGroupsForUserPlant(plant.uuid);

            line_groups_for_user.forEach(lg => {
                if (plant) {
                    const label: string = `${plant.title} - ${lg.title}`;
                    line_groups.push({ label, value: lg.external_id, uuid: lg.uuid });
                }
            });
        });

        line_groups.sort((a, b) => a.label.localeCompare(b.label));

        return line_groups;
    }

    /**
     * Handling change of any input component.
     */
    handleInputChange = (event: Event) => {
        if (event.currentTarget instanceof HTMLInputElement || event.currentTarget instanceof HTMLSelectElement) {
            // read new values
            const target = event.currentTarget;
            let value = target.value;
            const name = target.name;

            // hack for toggle element
            if ((name === "adjuster") || (name === "active")) {
                //$FlowFixMe
                value = target.checked;
            }

            // update new datasource placeholder
            this.setState(prev_state => {
                prev_state[name] = value;
                return prev_state;
            });
        }
    };

    /**
     * Handling multi-select change.
     */
    handleMultiSelectChange = (data: any) => {
        this.setState({ plants: data });
    };

    /**
     * Handling shift change.
     *
     * @param {array} data Array of preferred shifts (array of 3 booleans for 1st, 2nd and 3rd shift)
     */
    handleShiftChange = (data: boolean[]) => {
        this.setState({ shift_preference: data })
    }

    async handleSubmit() {
        if (this.isValidForm()) {
            try {
                const plant_uuids = this.state.plants.map(plant => plant.value);
                const data = await getBackend2().manufacturing.createPerson({
                    active: this.state.active,
                    adjuster: this.state.adjuster,
                    plant_uuids,
                    shift_preference: this.state.shift_preference,
                    ted_id: this.state.ted_id,
                    external_id: this.state.external_id,
                    name: this.state.name,
                    tags: this.state.tags
                });
                this.props.history.push(`/digital-twin/resources/people/${data.uuid}`);
            } catch (err) {
                this.setState({ error: err.message });
            }
        }
    }

    handleCancel() {
        this.props.history.push("/digital-twin/resources/people");
    }

    handleLineGroupChange = (option: ILabelValuePair) => {
        this.setState(prev_state => {
            prev_state.ted_id = option.value;
            return prev_state;
        });
    }

    getSelectedLineGroup = (): ?ILabelValuePair => {
        if (!!this.state.ted_id) {
            return this.state.line_groups.find(lg => lg.value === this.state.ted_id);
        }

        return null;
    }

    isValidForm = (): boolean => {
        const is_valid = (
            !!this.state.ted_id &&
            this.state.plants.length > 0 &&
            !!this.state.name &&
            !!this.state.external_id
        );

        if (!is_valid) {
            this.setState({
                error: translate("Manufacturing.LineGroups.cannot_submit_missing_values", "Cannot submit a form with missing values")
            });
            window.scrollTo(0, 0);
            return false;
        }

        return true;
    }

    onChangeTag = (name: string) => (value: boolean) => {
        this.setState({ tags: onChangeUpdateTags(this.state.tags, name, value) });
    }

    renderCannotDeactivate = () => {
        if (!this.state.has_enabled_future_shifts) return null;

        return <i
            className="fas fa-info-circle"
            style={{ color: "#b84747", fontSize: "25px" }}
            title={translate("common.cannot_deactivate", "Some actions are not allowed, person still has enabled shifts")}
        ></i>;
    }

    render() {
        const plant_options = this.state.plants_choice.map((plant) => {
            return { value: plant.uuid, label: plant.title };
        });
        const data_std = mapData(this.state.tags, PEOPLE_TAGS_EDITOR);
        const data_nonstd = mapUnknownData(this.state.tags, PEOPLE_TAGS_EDITOR);

        let customStyles = {};
        if (this.state.has_enabled_future_shifts) {
            customStyles = { multiValueRemove: (base) => ({ ...base, display: 'none' }) }
        }

        return (
            <div key="ds_new">
                <section key="1">
                    <img src="/img/banner.png" alt="banner" className="img-fluid-header banner_img" />
                </section>
                <section id="profile_edit" className="new_line" key="2">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="white_box">
                                    <div className="tab-content tab-content-wide">
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <h4> {this.state.componentTitle}</h4>
                                            {this.renderCannotDeactivate()}
                                        </div>
                                        <ErrorComponent msg={this.state.error} type="error" />
                                        <div className="form-group">
                                            <label><FormattedMessage id="common.external_id" defaultMessage="External ID" />:</label>
                                            <input name="external_id" type="text" disabled={this.state.editFlag} className="form-control"
                                                value={this.state.external_id}
                                                onChange={this.handleInputChange} />
                                        </div>

                                        <div className="form-group">
                                            <label><FormattedMessage id="common.name" defaultMessage="Name" />:</label>
                                            <input name="name" type="text" disabled={this.state.editFlag} className="form-control"
                                                value={this.state.name}
                                                onChange={this.handleInputChange} />
                                        </div>

                                        <div className="form-group">
                                            <label><FormattedMessage id="common.active" defaultMessage="Active" />:</label><br />
                                            <Toggle
                                                name="active"
                                                checked={this.state.active}
                                                disabled={this.state.active && this.state.has_enabled_future_shifts}
                                                onChange={this.handleInputChange} />
                                            <br />
                                        </div>

                                        <div className="form-group">
                                            <label><FormattedMessage id="common.adjuster" defaultMessage="Adjuster" />:</label><br />
                                            <Toggle
                                                name="adjuster"
                                                checked={this.state.adjuster}
                                                onChange={this.handleInputChange} />
                                        </div>

                                        <div className="form-group">
                                            <label><FormattedMessage id="common.linegroup" defaultMessage="Line-group" />:</label>
                                            <Select
                                                name="ted_id"
                                                value={this.getSelectedLineGroup()}
                                                options={this.state.line_groups}
                                                isSearchable
                                                onChange={this.handleLineGroupChange}
                                            />
                                        </div>

                                        <div className="form-group">
                                            <label><FormattedMessage id="Manufacturing.People.plant_membership" defaultMessage="Plant membership" />:</label>
                                            <Select name="plants"
                                                value={this.state.plants}
                                                options={plant_options}
                                                isMulti
                                                styles={customStyles}
                                                isClearable={!this.state.has_enabled_future_shifts}
                                                onChange={this.handleMultiSelectChange} />
                                        </div>

                                        <div className="form-group">
                                            <label><FormattedMessage id="Manufacturing.People.allowed_shifts" defaultMessage="Allowed shifts" />:</label>
                                            <PeopleShiftEdit name="shift_preference"
                                                value={this.state.shift_preference}
                                                onChange={this.handleShiftChange} />
                                        </div>

                                        <div className="form-group">
                                            <h4><FormattedMessage id="common.standard_data" defaultMessage="Standard data" /></h4>
                                            <a href={getLinkForUserDocumentation(UserDocumentationLinksEnum.people, getLang())}
                                                target="_blank"
                                                style={{ position: 'relative', float: 'right', paddingTop: '30px' }}>
                                                <img src={"/img/question_light.svg"} style={{ paddingRight: "3px", paddingBottom: "2px" }} />
                                                <FormattedMessage id="Header.menu.user_documentation" defaultMessage="User documentation" />
                                                <img src={"/img/arrow_up_right_corner.svg"} style={{ position: "relative", width: "15px", height: "15px", paddingLeft: "3px", paddingBottom: "4px", bottom: "3px" }} />
                                            </a>
                                            <ReactTable
                                                className="tags-react-table"
                                                data={data_std}
                                                onChangeCell={this.onChangeTag}
                                                rowStyle={rowStyle}
                                                columns={Columns}
                                                initialState={getInitialState()}
                                            />
                                        </div>

                                        {data_nonstd.length > 0 && <div className="form-group">
                                            <h4><FormattedMessage id="common.nonstandard_data" defaultMessage="Non-standard data" /></h4>
                                            <ReactTable
                                                className="tags-react-table"
                                                data={data_nonstd}
                                                onChangeCell={this.onChangeTag}
                                                rowStyle={rowStyle}
                                                columns={Columns}
                                                initialState={getInitialState()}
                                            />
                                        </div>}

                                        <div className="button-list order-last ml-auto">
                                            <button className="btn btn-outline-primary" onClick={() => this.handleSubmit()}>
                                                <FormattedMessage id="common.save" defaultMessage="Save" />
                                            </button>
                                            <button className="btn btn-outline-secondary" onClick={() => this.handleCancel()}>
                                                <FormattedMessage id="common.back" defaultMessage="Back" />
                                            </button>
                                        </div>

                                        <ErrorComponent msg={this.state.success} type="success" />
                                    </div>
                                </div>
                                <div className="space30"></div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default PeopleCreate;
