// @flow

// main imports
import React, { Component } from "react";
import ReactRouterPropTypes from "react-router-prop-types";

import { weekNumber, stringCompare } from "../../lib/Util";
import { PEOPLE_TAGS, PEOPLE_TAGS_DEFAULT } from "../../lib/ManufacturingTags.generated";

// backend
import { getBackend } from "../../lib/backend/Backend2";
import * as t from "../../lib/backend/manufacturing2.generated.types";

// import subcomponents
import ShiftAssignmentsPeople from "./ShiftAssignmentsPeople";
import ShiftAssignmentDetails from "./ShiftAssignmentDetails";

// navigation filter
export type ShiftsFilterProps = {
    year: number,
    week: number
}

export function initShiftFilterProps(): ShiftsFilterProps {
    // set current year and week
    let now = new Date();
    let week = weekNumber(now);
    let year = now.getFullYear();
    // if we have week 1 extending into previous year
    if ((now.getMonth() === 11) && (week === 1)) {
        year = year + 1;
    }
    // return defaults
    return { week, year };
}

// defining types
type Props = {
    match: ReactRouterPropTypes.match,
    history: ReactRouterPropTypes.history,
    location: ReactRouterPropTypes.location,
    year: number,
    week: number,
    selected_plant: string,
    selected_line_group: string
}

type State = {
    people: Map<string, t.IPersonData>,
    competences: Set<string>,
    error: string,
    warning: string,
    selected: t.IPersonData | null,
    name_filter: string,
    wError: boolean
}

/**
 * Displaying people's shift-assignments list.
 */
class ShiftAssignments extends Component<Props, State> {
    unique_person_names: Map<string, string>;

    /**
     * Constructor.
     * @param {Props} props Props object.
     */
    constructor(props: Props) {
        super(props);

        const state: State = {
            people: new Map(),
            competences: new Set(),
            people_shifts: [],
            available_shifts: [],
            error: "",
            warning: "",
            selected: null,
            name_filter: "",
            wError: false
        };
        this.state = state;
        this.unique_person_names = new Map();
    }

    /**
     * Actual loading of data from backend.
     */
    async loadComponent() {
        // get available people
        const res = await getBackend().manufacturing.getPeople({});
        const people = new Map();
        res.people.sort((a, b) => stringCompare(a.name, b.name)).forEach(person => {
            people.set(person.uuid, person);
        })

        // prepare cache of competences
        const res_competences = await getBackend().manufacturing.getCompetence({
            linegroup_uuid: this.props.selected_line_group
        });
        const competences = new Set();
        res_competences.competences.forEach(x => {
            competences.add(`${x.person_uuid}_${x.line_uuid}`);
        })

        // make the first active person as selected
        const people_active = [...people.values()].filter(person => person.active);
        if (people_active.length === 0) {
            this.setState({ error: "No people data found" });
            return;
        }
        let selected = null;

        // if a person is selected in URI, use that one
        const selected_uuid = this.props.match.params.view_params;
        if (selected_uuid) {
            selected = people.get(selected_uuid) || null;
        }

        this.setState({
            people,
            competences,
            selected,
            error: "",
            warning: "",
        });
    }

    /**
     * Handling any change in input components.
     */
    handleInputChange = (event: Event) => {
        if (event.currentTarget instanceof HTMLInputElement || event.currentTarget instanceof HTMLSelectElement) {
            // read new values
            const target = event.currentTarget;
            let value = target.value;
            const name = target.name;

            if (name === "name_filter") {
                this.setState({ name_filter: value });
            }
        }
    };

    /**
     *
     * @param {t.IPersonData} person    Selected person.
     * @param {t.IPersonData[]} people  List of all people.
     *
     * @return {string} Unique name for a person.
     */
    createUniquePersonName(selected: t.IPersonData) {
        // find selected abbrevation - unique name
        const names = selected.name.split(" ");
        let not_unique_name = true;
        let i = 0;
        let sname = "";
        while (not_unique_name) {
            let unique_name = true;
            sname = names[0];
            if (i > 0 && names.length > 1) { sname += " " + names[1].substr(0, i) + "."; }
            this.state.people.forEach(person => {
                if (selected.uuid !== person.uuid) {
                    const tnames = person.name.split(" ");
                    let tname = tnames[0];
                    if (i > 0 && tnames.length > 1) { tname += " " + tnames[1].substr(0, i) + "."; }
                    if (tname === sname) {
                        unique_name = false;
                    }
                }
            });

            not_unique_name = !unique_name;
            // give up after few iterations
            if (i > 10) { not_unique_name = false; }
            i++;
        }

        return sname;
    }

    uniquePersonName(uuid: string) {
        if (uuid === "") return "";
        if (uuid === null) return "";
        if (uuid === undefined) return "";

        const name = this.unique_person_names.get(uuid);
        if (name !== undefined) {
            return name;
        }

        const person = this.state.people.get(uuid);
        if (person === undefined) {
            return "";
        }

        const unique_name = this.createUniquePersonName(person);
        this.unique_person_names.set(uuid, unique_name);
        return unique_name;
    }

    isPersonAdjuster(uuid: string) {
        if (uuid === "") return false;
        if (uuid === null) return false;
        if (uuid === undefined) return false;

        const person = this.state.people.get(uuid);
        if (person === undefined) {
            return false
        }

        return person.adjuster;
    }

    /**
     * Handling click on a particular person. Remembering the selected person.
     *
     * @param {t.IPersonData} person
     */
    handlePeopleClick(person: t.IPersonData) {
        let selected = person;
        this.setState({ selected: selected });

        // update url with each click
        this.props.history.push("/manufacturing/people/shifts/" + person.uuid);
    }

    /**
     * Mounting data for this component.
     */
    componentDidMount() {
        this.loadComponent();
    }

    componentDidUpdate(prevProps: Props) {
        if (
            prevProps.selected_line_group !== this.props.selected_line_group ||
            prevProps.selected_plant !== this.props.selected_plant
        ) {
            this.setState({ selected: null });
            this.loadComponent();
        }
    }

    handlePersonAddedToSchedule() {}

    handleShiftsSaved() {}

    /**
     * Handling error from subordinate component.
     */
    handleToError = (str: string) => {
        this.setState({ error: str });
    }

    /**
     * Forward handlers
     */
    handleCopyPreviousWeek = () => {
        this.refs["ShiftAssignmentDetails"].copyPreviousWeek();
    }

    exportToHtmlWeekend = () => {
        this.refs["ShiftAssignmentDetails"].exportToHtmlWeekend();
    }

    handleShiftPrintWeek = (include_prev_sunday: boolean) => {
        this.refs["ShiftAssignmentDetails"].exportToHtmlWeek(include_prev_sunday);
    }

    handleShiftPrintDay = (day_of_week: number) => {
        this.refs["ShiftAssignmentDetails"].startExportToHtmlDay(day_of_week);
    }

    handleSaveChanges = () => {
        this.refs["ShiftAssignmentDetails"].saveChanges();
    }

    /**
     * Handling production line submit event.
     */
    handleSubmit = (e: Event) => {
        e.preventDefault();
    }

    canPersonWorkInShift(person_uuid: string, shift_of_day: number): boolean {
        const person = this.state.people.get(person_uuid);
        if (person === undefined) {
            return false;
        }

        return person.shift_preference[shift_of_day] || false;
    }

    canPersonWorkOnLine(person_uuid: string, line_uuid: string): boolean {
        const person = this.state.people.get(person_uuid);
        if (person === undefined) {
            return false;
        }

        return this.state.competences.has(`${person_uuid}_${line_uuid}`);
    }

    personMaxNumberShifts(person_uuid: string) {
        // prepare default value
        const default_shifts: number = parseInt(PEOPLE_TAGS_DEFAULT.reduced_worktime, 10);
        // check if we have the person
        const person = this.state.people.get(person_uuid);
        if (person === undefined) {
            return default_shifts;
        }

        // check if we have a tag
        if (person.tags[PEOPLE_TAGS.reduced_worktime] !== undefined) {
            return parseInt(person.tags[PEOPLE_TAGS.reduced_worktime], 10);
        }

        return default_shifts;
    }

    personWorks12Hours(person_uuid: string): boolean {
        const person = this.state.people.get(person_uuid);
        if (person === undefined) {
            return false;
        }

        if (person.tags[PEOPLE_TAGS.shift_board_works_12h_shifts] === "true") {
            return true;
        }
        return false;
    }

    personAllowedShifts(person_uuid: string): boolean[] {
        // prepare default value
        const default_shifts: boolean[] = [true, true, true];
        // check if we have the person
        const person = this.state.people.get(person_uuid);
        if (person === undefined) {
            return default_shifts;
        }

        return person.shift_preference;
    }

    /**
     * Rendering JSX for current component.
     */
    render() {
        return (
            <article className="article">
                <section className="shift-assignments">
                    <div id="shift-assignments-section">
                        <ShiftAssignmentsPeople
                            history={this.props.history}
                            handlePeopleClick={person => { this.handlePeopleClick(person); }}
                            people={[...this.state.people.values()]}
                            selected={this.state.selected}
                            selectedPlant={this.props.selected_plant}
                            selectedLineGroup={this.props.selected_line_group}
                        />
                        <ShiftAssignmentDetails
                            key={`week_${this.props.week}_plant_${this.props.selected_plant}_lg_${this.props.selected_line_group}`}
                            uniquePersonName={str => this.uniquePersonName(str)}
                            isPersonAdjuster={str => this.isPersonAdjuster(str)}
                            selectedPerson={this.state.selected}
                            week={this.props.week}
                            year={this.props.year}
                            plant={this.props.selected_plant}
                            line_group_uuid={this.props.selected_line_group}
                            ref="ShiftAssignmentDetails"
                            handlePersonAddedToSchedule={() => this.handlePersonAddedToSchedule()}
                            handleShiftsSaved={() => this.handleShiftsSaved()}
                            canPersonWorkOnLine={(person_uuid: string, line_uuid: string) => this.canPersonWorkOnLine(person_uuid, line_uuid)}
                            canPersonWorkInShift={(person_uuid: string, shift_of_day: number) => this.canPersonWorkInShift(person_uuid, shift_of_day)}
                            personMaxNumberShifts={(person_uuid: string) => this.personMaxNumberShifts(person_uuid)}
                            personAllowedShifts={(person_uuid: string) => this.personAllowedShifts(person_uuid)}
                            personWorks12Hours={(person_uuid: string) => this.personWorks12Hours(person_uuid)}
                        />
                    </div>
                </section>
            </article>
        );
    }
}

export default ShiftAssignments;
