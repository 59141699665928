// @flow

import * as React from "react";
import Select from "react-select";
import { FormattedMessage } from "react-intl";
import { Modal, Tabs, Tab } from "react-bootstrap";
import ErrorComponent from "./ErrorComponent";
import { getLang } from "./IntlProviderWrapper";
import { getBackend } from "../lib/backend/Backend2";
import * as ct from "../lib/backend/common.generated.types";
import * as ut from "../lib/backend/users.generated.types";
import * as mt from "../lib/backend/manufacturing2.generated.types";
import {
    createPlainTextAndDownload, niceDateTime, createDurationString,
    createBinaryAndDownload
} from "../lib/Util";
import { JOB_TYPES } from "../lib/Models";
import { JOB_STATUS } from "../lib/CommonConsts.generated";
import { ResourcesLayout } from "./MainContent";

const JOB_TYPES_OPTIONS = [];
for (const key of Object.keys(JOB_TYPES)) {
    JOB_TYPES_OPTIONS.push({ value: key, label: key });
}

const JOB_STATUS_OPTIONS = [];
for (const key of Object.keys(JOB_STATUS)) {
    JOB_STATUS_OPTIONS.push({ value: key, label: key });
}

const COLOR_ADD = "rgba(101, 214, 173, 0.15)";
const COLOR_REM = "rgba(255, 119, 119, 0.15)";

/** Utility function for getting the list of blob-storage items */
async function getItems(req: ct.ISearchJobsReq = {}): Promise<ct.ISearchJobItem[]> {
    const x = await getBackend().common.searchJobs(req);
    return x.hits;
}

function renderValue(value: any) {
    if (typeof value === "object" && value !== null) {
        return JSON.stringify(value);
    }
    return value;
}

type IndexedItem = {
    index: number,
    item: any
};

type ChangesModalProps = {
    job_info: ct.IGetJobInfoRes,
    show: boolean,
    onHide: () => void
};

type ChangesModalState = {
    job_info: ct.IGetJobInfoRes | null,
    job_uuid: string,
    lines: Map<string, mt.ILineData>,
    error: string
};

class ChangesModal extends React.Component<ChangesModalProps, ChangesModalState> {
    constructor(props) {
        super(props);
        this.state = {
            job_info: null,
            job_uuid: "",
            lines: new Map(),
            error: ""
        };
    }

    handleFilterChange = async (event: SyntheticInputEvent<HTMLInputElement>) => {
        this.setState({ job_uuid: event.target.value });
    }

    handleSubmit = async (event: SyntheticEvent<HTMLFormElement>) => {
        event.preventDefault();

        const job_uuid = this.state.job_uuid.trim();
        if (this.state.job_info && this.state.job_info.uuid === job_uuid) {
            return;
        }

        try {
            const job_info = await getBackend().common.getJobInfo({ id: this.state.job_uuid.trim() });
            let lines: Map<string, mt.ILineData> = new Map();

            if (job_info.type === this.props.job_info.type && job_info.type === JOB_TYPES.production_simulation) {
                const job_info1 = this.props.job_info;
                const job_info2 = job_info;
                const line_uuids = [...new Set([
                    ...(job_info1.input && job_info1.input.data && job_info1.input.data.lines &&
                        job_info1.input.data.lines.map(l => l.line) || []),
                    ...(job_info2.input && job_info2.input.data && job_info2.input.data.lines &&
                        job_info2.input.data.lines.map(l => l.line) || [])
                ])];
                if (line_uuids) {
                    const res = await getBackend().manufacturing.getLines({ line_uuids });
                    res.lines.forEach(line => { lines.set(line.uuid, line) });
                }
            }

            this.setState({
                job_info,
                lines,
                error: ""
            });
        } catch (err) {
            this.setState({
                job_info: null,
                error: err.message
            });
        }
    }

    handleExit = () => {
        this.setState({
            job_info: null,
            job_uuid: "",
            error: ""
        });
    }

    renderEmptyItem = (span: number, bg_color: string, key: string) => {
        return (
            <tr key={key} style={{ backgroundColor: bg_color }}>
                <td colSpan={span} />
            </tr>
        );
    }

    renderItem = (indexed_item: IndexedItem, props: string[], bg_color: string, key: string) => {
        return (
            <tr key={key} style={{ backgroundColor: bg_color }}>
                <td>{indexed_item.index + 1}</td>
                {props.map(prop => (
                    <td key={prop}>{renderValue(indexed_item.item[prop])}</td>
                ))}
            </tr>
        );
    }

    renderItemDiff = (indexed_item1: IndexedItem, indexed_item2: IndexedItem, props: string[], key: string) => {
        const index_bg_color = indexed_item1.index !== indexed_item2.index
            ? COLOR_REM
            : "transparent";
        return (
            <tr key={key}>
                <td>
                    <span
                        className="d-inline-block"
                        style={{ backgroundColor: index_bg_color }}
                    >
                        {indexed_item1.index + 1}
                    </span>
                </td>
                {props.map(prop => {
                    const value_bg_color = indexed_item1.item[prop] !== indexed_item2.item[prop]
                        ? COLOR_REM
                        : "transparent";
                    return (
                        <td key={prop}>
                            <span
                                className="d-inline-block"
                                style={{ backgroundColor: value_bg_color }}
                            >
                                {renderValue(indexed_item1.item[prop])}
                            </span>
                        </td>
                    );
                })}
            </tr>
        );
    }

    renderItemsDiff = (
        items1: any[] | null,
        items2: any[] | null,
        getId: (item: any) => string,
        sort?: (item1: any, item2: any) => number
    ) => {
        const items1_props: Set<string> = new Set();
        const indexed_items1_map: Map<string, IndexedItem> = new Map();
        if (items1 !== null) {
            items1.forEach((item, index) => {
                indexed_items1_map.set(getId(item), { index, item });
                Object.keys(item).forEach(key => { items1_props.add(key); });
            });
        }

        const items2_props: Set<string> = new Set();
        const indexed_items2_map: Map<string, IndexedItem> = new Map();
        if (items2 !== null) {
            items2.forEach((item, index) => {
                indexed_items2_map.set(getId(item), { index, item });
                Object.keys(item).forEach(key => { items2_props.add(key); });
            });
        }

        let pairs: [IndexedItem | null, IndexedItem | null][] = [];
        for (const indexed_item1 of indexed_items1_map.values()) {
            const indexed_item2 = indexed_items2_map.get(getId(indexed_item1.item));
            pairs.push([indexed_item1, indexed_item2 || null]);
        }

        for (const indexed_item2 of indexed_items2_map.values()) {
            if (!indexed_items1_map.has(getId(indexed_item2.item))) {
                pairs.push([null, indexed_item2]);
            }
        }

        if (sort !== undefined) {
            pairs = pairs.sort((ii1, ii2) => {
                const indexed_item1 = ii1[0] || ii1[1];
                const indexed_item2 = ii2[0] || ii2[1];
                if (!indexed_item1 || !indexed_item2) {
                    return 0;
                }

                return sort ? sort(indexed_item1.item, indexed_item2.item) : 0;
            });

            let index1 = 0;
            let index2 = 0;
            for (let pair of pairs) {
                if (pair[0] !== null) {
                    pair[0].index = index1;
                    index1 += 1;
                }

                if (pair[1] !== null) {
                    pair[1].index = index2;
                    index2 += 1;
                }
            }
        }

        let items_bg_color = "transparent";
        if (items1 !== null && items2 === null) {
            items_bg_color = COLOR_REM;
        } else if (items1 === null && items2 !== null) {
            items_bg_color = COLOR_ADD;
        }

        return (
            <div className="row" style={{ backgroundColor: items_bg_color }}>
                <div className="col-6">
                    <div className="background-job-table-wrapper">
                        <table className="background-job-table table table-bordered mb-0">
                            <thead>
                                <tr>
                                    <th />
                                    {[...items1_props].map(prop => {
                                        const bg_color = items2 && items2.length > 0 && !items2_props.has(prop)
                                            ? COLOR_REM : "transparent";
                                        return (
                                            <th key={prop}>
                                                <span style={{ backgroundColor: bg_color }}>{prop}</span>
                                            </th>
                                        );
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {pairs.map(([indexed_item1, indexed_item2], i) => {
                                    if (indexed_item1 === null) {
                                        return this.renderEmptyItem(items1_props.size + 1, COLOR_ADD, `${i}`);
                                    }

                                    if (indexed_item2 === null) {
                                        return this.renderItem(indexed_item1, [...items1_props], COLOR_REM, `${i}`);
                                    }

                                    return this.renderItemDiff(indexed_item1, indexed_item2, [...items1_props], `${i}`);
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col-6">
                    <div className="background-job-table-wrapper">
                        <table className="background-job-table table table-bordered mb-0">
                            <thead>
                                <tr>
                                    <th />
                                    {[...items2_props].map(prop => {
                                        const bg_color = items1 && items1.length > 0 && !items1_props.has(prop)
                                            ? COLOR_ADD : "transparent";
                                        return (
                                            <th key={prop}>
                                                <span style={{ backgroundColor: bg_color }}>{prop}</span>
                                            </th>
                                        );
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {pairs.map(([indexed_item1, indexed_item2], i) => {
                                    if (indexed_item2 === null) {
                                        return this.renderEmptyItem(items2_props.size + 1, COLOR_REM, `${i}`);
                                    }

                                    if (indexed_item1 === null) {
                                        return this.renderItem(indexed_item2, [...items2_props], COLOR_ADD, `${i}`);
                                    }

                                    return this.renderItemDiff(indexed_item2, indexed_item1, [...items2_props], `${i}`);
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }

    renderLineDiff = (line1: any, line2: any) => {
        const line_uuid = (line1 && line1.line) || (line2 && line2.line);
        if (!line_uuid) {
            return null;
        }

        let line_bg_color = "transparent";
        if (line1 !== null && line2 === null) {
            line_bg_color = COLOR_REM;
        } else if (line1 === null && line2 !== null) {
            line_bg_color = COLOR_ADD;
        }

        const line = this.state.lines.get(line_uuid);

        return (
            <div key={line_uuid}>
                <p className="h6 mt-4">
                    Line: {line ? `${line.title} (${line.external_id})` : line_uuid}
                </p>
                <div style={{ backgroundColor: line_bg_color }}>
                    {this.renderItemsDiff(
                        line1 && line1.orders || null,
                        line2 && line2.orders || null,
                        order => order.uuid,
                        (item1: any, item2: any) => {
                            if (item1.earliest_start > item2.earliest_start) {
                                return 1;
                            } else if (item1.earliest_start < item2.earliest_start) {
                                return -1;
                            }

                            return 0;
                        }
                    )}
                </div>
            </div>
        );
    }

    renderUnscheduledDiff = (unscheduled1: any, unscheduled2: any) => {
        let bg_color = "transparent";
        if (unscheduled1 !== null && unscheduled2 === null) {
            bg_color = COLOR_REM;
        } else if (unscheduled1 === null && unscheduled2 !== null) {
            bg_color = COLOR_ADD;
        }

        return (
            <div>
                <p className="h6 mt-4">
                    Unscheduled
                </p>
                <div style={{ backgroundColor: bg_color }}>
                    {this.renderItemsDiff(
                        unscheduled1,
                        unscheduled2,
                        order => order.uuid,
                        (item1: any, item2: any) => {
                            if (item1.earliest_start > item2.earliest_start) {
                                return 1;
                            } else if (item1.earliest_start < item2.earliest_start) {
                                return -1;
                            }

                            return 0;
                        }
                    )}
                </div>
            </div>
        );
    }

    renderProductionSimulationDiff = (job_info1: ct.IGetJobInfoRes, job_info2: ct.IGetJobInfoRes) => {
        if (job_info2 === null || !job_info2.input || !job_info2.input.data || !job_info2.input.data.lines ||
            !job_info1.input || !job_info1.input.data || !job_info1.input.data.lines) {
            return null;
        }

        const lines1 = job_info1.input.data.lines;
        const lines2 = job_info2.input.data.lines;
        const lines2_map: Map<string, any> = new Map();
        lines2.forEach(line => {
            lines2_map.set(line.line, line);
        });

        const diffs = [];
        for (const line1 of lines1) {
            if (lines2_map.has(line1.line)) {
                const line2 = lines2_map.get(line1.line);
                diffs.push(this.renderLineDiff(line1, line2));
                lines2_map.delete(line1.line);
            } else {
                diffs.push(this.renderLineDiff(line1, null));
            }
        }

        for (const line2 of lines2_map.values()) {
            diffs.push(this.renderLineDiff(null, line2));
        }

        diffs.push(this.renderUnscheduledDiff(
            job_info1.input.data.unscheduled || null,
            job_info2.input.data.unscheduled || null
        ));

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-6">
                        <p className="h6 pb-2 mt-4 mb-0 border border-top-0 border-right-0 border-left-0">
                            Job: {job_info1.uuid}
                        </p>
                    </div>
                    <div className="col-6">
                        <p className="h6 pb-2 mt-4 mb-0 border border-top-0 border-right-0 border-left-0">
                            Job: {job_info2.uuid}
                        </p>
                    </div>
                </div>
                {diffs}
            </React.Fragment>
        );
    }

    renderDiff = () => {
        if (this.state.error !== "") {
            return (
                <ErrorComponent
                    className="mb-0"
                    type="warning"
                    msg={this.state.error}
                />
            )
        }

        const job_info1 = this.props.job_info;
        const job_info2 = this.state.job_info;

        if (job_info2 === null) {
            return null;
        }

        if (job_info1.type !== job_info2.type) {
            return (
                <ErrorComponent
                    className="mb-0"
                    type="warning"
                    msg="Can not compare jobs of different type"
                />
            );
        }

        if (job_info2.type === JOB_TYPES.production_simulation) {
            return this.renderProductionSimulationDiff(job_info1, job_info2);
        }

        return null;
    }

    render() {
        return (
            <Modal
                dialogClassName="modal-dialog-scrollable modal-max"
                show={this.props.show}
                onExited={this.handleExit}
            >
                <Modal.Header>
                    <Modal.Title>
                        <span>Compare {this.props.job_info.type} {this.props.job_info.uuid}</span>
                    </Modal.Title>
                    <button type="button" className="close" onClick={this.props.onHide}>
                        <span aria-hidden="true">×</span>
                        <span className="sr-only">Close</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={this.handleSubmit}>
                        <div className="input-group">
                            <input
                                type="text"
                                className="form-control form-control-sm h-auto"
                                placeholder="Job UUID"
                                value={this.state.job_uuid}
                                onChange={this.handleFilterChange}
                            />
                            <div className="input-group-append">
                                <button
                                    className="btn btn-lg btn-primary"
                                    type="submit"
                                    disabled={this.state.job_uuid === ""}
                                >
                                    Compare
                                </button>
                            </div>
                        </div>
                    </form>
                    {this.renderDiff()}
                </Modal.Body>
            </Modal>
        );
    }
}

type ItemListProps = {
    items: ct.ISearchJobItem[],
    users: ut.IGetUsersResItem[],
    selected_key: string,
    handleClick: (string) => void,
    handleFilterChange: (req: ct.ISearchJobsReq) => Promise<void>
};

type ItemListState = {
    usr_filter: string,
    session_filter: string,
    job_type_filter: string,
    job_status_filter: string
};

type DropdownOption = {
    value: string,
    label: string
};

class ItemList extends React.Component<ItemListProps, ItemListState> {
    constructor(props) {
        super(props);
        this.state = {
            usr_filter: "",
            session_filter: "",
            job_type_filter: "",
            job_status_filter: "",
        };
    }

    handleDropdownStatusChange = (event: DropdownOption) => {

        const key = event === null ? "" : event.value;

        this.setState({
            "job_status_filter": key
        }, () => {
            this.props.handleFilterChange({
                cache_hash: this.state.session_filter,
                created_by: this.state.usr_filter,
                status: key,
                type: this.state.job_type_filter,
            });
        });
    }

    handleDropdownTypeChange = (event: DropdownOption) => {

        const key = event === null ? "" : event.value;

        this.setState({
            "job_type_filter": key
        }, () => {
            this.props.handleFilterChange({
                cache_hash: this.state.session_filter,
                created_by: this.state.usr_filter,
                status: this.state.job_status_filter,
                type: key,
            });
        });
    }

    handleInputChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
        const { target } = event;

        if (event.target instanceof HTMLInputElement) {
            this.setState({
                [target.name]: target.value
            }, () => {
                this.props.handleFilterChange({
                    cache_hash: this.state.session_filter,
                    created_by: this.state.usr_filter,
                    status: this.state.job_status_filter,
                    type: this.state.job_type_filter,
                });
            });
        }

    }

    render() {
        const props = this.props;
        const users_map: Map<string, ut.IGetUsersResItem> = new Map();
        props.users.forEach(user => {
            users_map.set(user.uuid, user);
        });
        const list = props.items.map((item, i) => {
            const css = "nav-link " + (item.uuid === props.selected_key ? "active show" : "");
            const user = users_map.get(item.created_by);
            const username = user ? user.username : item.created_by;
            return <li className="nav-item" key={i} onClick={() => props.handleClick(item.uuid)}>
                <a itemRef={"a" + i} className={css} data-toggle="tab">
                    <div>{item.uuid} - {item.status} - {item.type} - {item.title}</div>
                    <div className="text-muted">
                        {item.status === "ended" ?
                            `Ended at ${niceDateTime(new Date(item.ended_at))} by ${username}` :
                            `Created at at ${niceDateTime(new Date(item.created_at))} by ${username}`}
                    </div>
                </a>
            </li>;
        });
        return (
            <React.Fragment>
                <div className="sub-filter-bar">
                    <div className="row w-100 mx-0">
                        <div className="col-6 pl-0 pr-2">
                            <div className="form-group form-group-stretch">
                                <input
                                    type="text"
                                    className="form-control search_bar"
                                    placeholder="Filter by user..."
                                    name="usr_filter"
                                    value={this.state.usr_filter}
                                    onChange={this.handleInputChange}
                                />
                            </div>
                        </div>
                        <div className="col-6 pl-2 pr-0 pb-2">
                            <div className="form-group form-group-stretch">
                                <input
                                    type="text"
                                    className="form-control search_bar"
                                    placeholder="Filter by session..."
                                    name="session_filter"
                                    value={this.state.session_filter}
                                    onChange={this.handleInputChange}
                                />
                            </div>
                        </div>

                        <div className="col-6 pl-0 pr-2">
                            <div className="form-group form-group-stretch">
                                <Select
                                    options={JOB_STATUS_OPTIONS}
                                    placeholder={"Filter by job status..."}
                                    name="job_status_filter"
                                    onChange={this.handleDropdownStatusChange}
                                    isClearable={true}
                                />
                            </div>
                        </div>

                        <div className="col-6 pl-2 pr-0">
                            <div className="form-group form-group-stretch">
                                <Select
                                    options={JOB_TYPES_OPTIONS}
                                    placeholder={"Filter by job type..."}
                                    name="job_type_filter"
                                    onChange={this.handleDropdownTypeChange}
                                    isClearable={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <ul className="nav nav-tabs" style={{ top: 228 }}>
                    {list}
                </ul>
            </React.Fragment>
        );
    }
}

type TabKey = "input" | "result";

type DataTable = {
    id: string,
    title: string,
    data: any,
    renderers: TableRenderers
};

type TableRenderers = {
    [key: string]: (cell: any, parent?: any) => React.Node
};

type PlantRow = {
    uuid: string,
    title: string
};

type Props = {};

type State = {
    content: string,
    error: string,
    items: ct.ISearchJobItem[],
    selected_item: ct.IGetJobInfoRes,
    selected_key: string,
    show_changes_modal: boolean,
    original_content: string,
    new_selected_key: ?string,
    tab_key: TabKey,
    material_titles: {
        [uuid: string]: string;
    },
    plants: mt.IPlantData[],
    users: ut.IGetUsersResItem[]
};

class BackgroundJobs extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        const state: State = {
            content: "",
            original_content: "",
            error: "",
            items: [],
            selected_item: {
                created_at: 0,
                created_by: "",
                ended_at: 0,
                enqueued_at: 0,
                input: "",
                org_uuid: "",
                result: "",
                retry_cnt: 0,
                started_at: 0,
                status: "unknown",
                status_msg: "",
                tags: {},
                title: "",
                type: "",
                uuid: ""
            },
            selected_key: "",
            new_selected_key: "",
            show_changes_modal: false,
            tab_key: "input",
            material_titles: {},
            plants: [],
            users: []
        };
        this.state = state;
    }

    async showKey(key: string): Promise<void> {
        try {
            this.setState({ error: "", selected_key: key });
            const res = await getBackend().common.getJobInfo({ id: key });
            const material_titles = {};

            if (res.type === JOB_TYPES.production_planning || res.type === JOB_TYPES.production_simulation) {
                const materials_uuids = this.getMaterialUuids(res.input);
                const res2 = await getBackend().manufacturing.getMaterialsTranslations(
                    { language_code: getLang(), materials_uuids }
                );

                res2.translations_materials.forEach(material => {
                    material_titles[material.uuid] = material.title;
                });
            }

            this.setState({
                content: JSON.stringify(res, null, "    "),
                selected_item: res,
                original_content: "",
                material_titles
            });
        } catch (err) {
            this.setState({ error: "" + err });
            console.log(err);
        }
    };

    async componentDidMount() {
        try {
            const { plants } = await getBackend().manufacturing.getPlants({});
            const { users } = await getBackend().users.getUsers({});
            this.setState({
                plants,
                users
            });
        } catch (err) {
            console.log(err);
        }

        this.filterItems({});
    }

    filterItems = async (req: ct.ISearchJobsReq) => {
        try {
            const items = await getItems(req);
            this.setState({ items });
            if (items.length > 0) {
                await this.showKey(items[0].uuid);
            }
        } catch (err) {
            this.setState({ error: "" + err, items: [], content: "", selected_key: "", original_content: "" });
            console.log(err);
        }
    }

    getMaterialUuids = (input: any): string[] => {
        // Deep search for all `material_uuid`s in input
        if (!input || typeof input !== "object") {
            return [];
        }

        if (Array.isArray(input)) {
            let uuids = [];
            input.forEach(item => {
                const item_uuids = this.getMaterialUuids(item);
                uuids = [...uuids, ...item_uuids];
            });
            return [...new Set(uuids)];
        } else {
            let uuids = [];
            Object.keys(input).forEach(key => {
                if (key === "material_uuid" && typeof input[key] === "string") {
                    uuids = [...uuids, input[key]];
                } else if (key === "material_uuids" && Array.isArray(input[key])) {
                    uuids = [...uuids, ...input[key]];
                } else {
                    const property_uuids = this.getMaterialUuids(input[key]);
                    uuids = [...uuids, ...property_uuids];
                }
            });
            return [...new Set(uuids)];
        }
    }

    mapPlants = (plant_uuids: string[]): PlantRow[] => {
        const plants: PlantRow[] = [];
        for (const plant_uuid of plant_uuids) {
            const plant = this.state.plants.find(p => p.uuid === plant_uuid);
            plants.push({
                uuid: plant_uuid,
                title: plant ? plant.title : ""
            });
        }

        return plants;
    }

    onDownloadInput = () => {
        const data = this.state.selected_item;
        if (data != null) {
            createPlainTextAndDownload(JSON.stringify(data.input, null, "  "), `input_${data.uuid}.json`);
        }
    }

    onDownloadResult = async () => {
        if (this.state.selected_item.result !== null) {
            createPlainTextAndDownload(
                JSON.stringify(this.state.selected_item.result, null, "  "),
                `result_${this.state.selected_item.uuid}.json`
            );
        } else {
            const x = await getBackend().common.getJobResultRaw({}, this.state.selected_key);
            createBinaryAndDownload(x, `result_${this.state.selected_item.uuid}.json.gz`);
        }
    }

    onChange = (e: Event) => {
        let target = e.target;
        if (target instanceof HTMLTextAreaElement) {
            this.setState({ content: target.value });
        }
    }

    onClickItemList = (new_key: string) => {
        this.showKey(new_key);
    }

    onTabSelect = async (tab_key: TabKey) => {
        if (tab_key === "result" && this.state.selected_item.result === null) {
            const { result } = await getBackend().common.getJobResultRaw2({ id: this.state.selected_key });
            this.setState(prev_state => ({
                ...prev_state,
                selected_item: {
                    ...this.state.selected_item,
                    result
                }
            }));
        }

        this.setState({ tab_key });
    }

    onTocLinkClick = (event: SyntheticMouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();

        if (event.target instanceof HTMLAnchorElement) {
            const href = event.target.getAttribute("href");

            if (!href) {
                return;
            }

            const href_target = document.getElementById(href.slice(1));

            if (!href_target) {
                return;
            }

            const rect = href_target.getBoundingClientRect();
            window.scrollTo(0, rect.top - 150);
        }
    }

    onCompareClick = () => {
        this.setState({
            show_changes_modal: true
        });
    }

    onChangesModalClose = () => {
        this.setState({
            show_changes_modal: false
        });
    }

    renderDate = (ts: number) => {
        return new Date(ts).toJSON();
    }

    renderMaterial = (uuid: string) => {
        const material_title = this.state.material_titles[uuid];

        if (!material_title) {
            return uuid;
        }

        return <p className="mb-0">{uuid} <em>({material_title})</em></p>;
    }

    renderBoolean = (value: boolean) => {
        return value ? "true" : "false";
    }

    renderValue = (value: any) => {
        if (typeof value === "boolean") {
            return this.renderBoolean(value);
        }

        if (Array.isArray(value)) {
            return this.renderList(value);
        }

        if (typeof value === "object") {
            return JSON.stringify(value);
        }

        return value;
    }

    renderList = (items: any[]) => {
        return (
            <ul className="list-unstyled mb-0">
                {items.map((item, i) => <li key={i}>{this.renderValue(item)}</li>)}
            </ul>
        );
    }

    renderHorizontalTable = (id: string, title: string, data: any[], renderers?: TableRenderers) => {
        if (!data || !data.length) {
            return null;
        }

        const unique_headers = new Set();
        data.forEach(item => {
            const keys = Object.keys(item);
            keys.forEach(key => {
                unique_headers.add(key);
            });
        });
        const headers = [...unique_headers];
        return (
            <div key={id} className="background-job-table-wrapper">
                {title && <h2 id={`table-${id}`} className="mb-2">{title}</h2>}
                <table className="background-job-table table table-bordered table-hover mb-0">
                    <thead>
                        <tr>{headers.map((header, i) => <th key={i} scope="col">{header}</th>)}</tr>
                    </thead>
                    <tbody>
                        {data.map((row, i) => (
                            <tr key={i} scope="row">
                                {headers.map((col, j) => {
                                    const td_value = renderers && renderers[col]
                                        ? renderers[col](row[col], row)
                                        : this.renderValue(row[col]);
                                    return (
                                        <td key={j} className="align-top" title={col}>
                                            {td_value}
                                        </td>
                                    );
                                })}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }

    renderVerticalTable = (id: string, title: string, data: { [key: string]: any }, renderers?: TableRenderers) => {
        if (!data) {
            return null;
        }

        const keys = Object.keys(data);

        if (!keys.length) {
            return null;
        }

        return (
            <div key={id} className="background-job-table-wrapper">
                {title && <h2 id={`table-${id}`} className="mb-2">{title}</h2>}
                <table className="background-job-table table table-bordered table-hover mb-0">
                    <tbody>
                        {keys.map((key, i) => {
                            const td_value = renderers && renderers[key]
                                ? renderers[key](data[key], data)
                                : this.renderValue(data[key]);
                            return (
                                <tr key={i} scope="row">
                                    <th className="align-top">{key}</th>
                                    <td className="align-top" title={key}>
                                        {td_value}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );
    }

    renderListTable = (id: string, title: string, data: any[], renderers?: TableRenderers) => {
        if (!data || !data.length) {
            return null;
        }

        return (
            <div key={id} className="background-job-table-wrapper">
                {title && <h2 id={`table-${id}`} className="mb-2">{title}</h2>}
                <table className="background-job-table table table-bordered table-hover mb-0">
                    <tbody>
                        {data.map((row, i) => {
                            const td_value = renderers && renderers[id]
                                ? renderers[id](row, data)
                                : this.renderValue(row);
                            return (
                                <tr key={i} scope="row">
                                    <td className="align-top">
                                        {td_value}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );
    }

    renderTables = (tables: DataTable[]) => {
        return (
            <React.Fragment>
                <ul className="pl-3 mt-3 mb-0">
                    {tables.map(table => (table.data && Object.keys(table.data).length > 0 && (
                        <li key={table.id}>
                            <a href={`#table-${table.id}`} onClick={this.onTocLinkClick}>
                                {table.title}
                            </a>
                        </li>
                    )))}
                </ul>
                {tables.map(table => {
                    if (!table.data) {
                        return null;
                    }

                    if (Array.isArray(table.data)) {
                        if (!table.data.length) {
                            return null;
                        }

                        if (typeof table.data[0] === "object") {
                            return this.renderHorizontalTable(table.id, table.title, table.data, table.renderers);
                        }

                        return this.renderListTable(table.id, table.title, table.data, table.renderers);
                    }

                    return this.renderVerticalTable(table.id, table.title, table.data, table.renderers);
                })}
            </React.Fragment>
        );
    };

    renderProductionPlanningInput() {
        if (!this.state.selected_item.input) {
            return null;
        }

        const { model, params, ...input } = this.state.selected_item.input;
        let tables: DataTable[] = [];

        if (input && Object.keys(input).length > 0) {
            tables.push({
                id: "input",
                title: "Input",
                data: input,
                renderers: {}
            });
        }

        if (params) {
            tables.push({
                id: "params",
                title: "Params",
                data: params,
                renderers: {
                    criteria: criteria => this.renderVerticalTable("", "", criteria)
                }
            });
        }

        if (model) {
            tables = [...tables, ...[
                {
                    id: "needs",
                    title: "Needs",
                    data: model.needs,
                    renderers: {
                        date: this.renderDate,
                        material_uuid: this.renderMaterial
                    }
                },
                {
                    id: "orders",
                    title: "Orders",
                    data: model.orders.map(order => order && ({
                        external_id: order.external_id,
                        material_uuid: order.material_uuid,
                        order_sequence_weight: order.order_sequence_weight,
                        operations: order.operations,
                        ...order
                    })),
                    renderers: {
                        material_uuid: this.renderMaterial,
                        operations: (operations) => this.renderHorizontalTable("orders_operations", "", operations, {
                            material_uuid: this.renderMaterial,
                            bom: bom => this.renderHorizontalTable("orders_operations_bom", "", bom, {
                                material_uuid: this.renderMaterial
                            }),
                            resources: resources => this.renderVerticalTable("", "", resources),
                            current_resources: resources => this.renderVerticalTable("", "", resources)
                        }),
                        prod_vers: (prod_vers) => this.renderHorizontalTable("orders_prod_vers", "", prod_vers, {
                            routing: (routing) => this.renderHorizontalTable("orders_prod_vers_routing", "", routing)
                        })
                    }
                },
                {
                    id: "stocks",
                    title: "Stocks",
                    data: model.stocks,
                    renderers: {
                        date: this.renderDate,
                        material_uuid: this.renderMaterial
                    }
                },
                {
                    id: "schedule",
                    title: "Schedule",
                    data: model.schedule,
                    renderers: {
                        end_time: this.renderDate,
                        start_time: this.renderDate
                    }
                },
                {
                    id: "materials",
                    title: "Materials",
                    data: model.materials,
                    renderers: {}
                },
                {
                    id: "resources",
                    title: "Resources",
                    data: model.resources.map(resource => {
                        const { last_produced, ...rest } = resource;
                        return {
                            ...rest,
                            ...last_produced
                        };
                    }),
                    renderers: {
                        material_uuid: this.renderMaterial
                    }
                },
                {
                    id: "constraints",
                    title: "Constraints",
                    data: model.constraints,
                    renderers: {
                        material_uuid: this.renderMaterial
                    }
                },
                {
                    id: "freeze_orders",
                    title: "Freeze orders",
                    data: model.freeze_orders,
                    renderers: {
                        end_time: this.renderDate,
                        start_time: this.renderDate
                    }
                },
                {
                    id: "material_norms",
                    title: "Material norms",
                    data: model.material_norms,
                    renderers: {
                        material_uuid: this.renderMaterial
                    }
                },
                {
                    id: "time_horizonts",
                    title: "Time Horizons",
                    data: model.time_horizonts,
                    renderers: {}
                },
                {
                    id: "stock_quick_sim_report_uuid",
                    title: "Stock quick simulation report",
                    data: [model.stock_quick_sim_report_uuid] ?? [""],
                    renderers: {}
                }
            ]];
        }

        return this.renderTables(tables);
    }

    renderProductionPlanningResult() {
        if (!this.state.selected_item.result) {
            return null;
        }

        const { debug } = this.state.selected_item.result;
        return Array.isArray(debug) ? (
            <pre className="bg-white p-2">{debug.join("\n")}</pre>
        ) : null;
    }

    renderProductionSimulationInput() {
        if (!this.state.selected_item.input) {
            return null;
        }

        const { data, ...input } = this.state.selected_item.input;
        let tables: DataTable[] = [];

        if (input && Object.keys(input).length > 0) {
            tables.push({
                id: "input",
                title: "Input",
                data: input,
                renderers: {}
            });
        }

        if (data) {
            const {
                lines,
                unscheduled,
                plant_uuids,
                material_uuids,
                next_shift_time,
                analysis_configuration,
                ...data_props
            } = data;
            tables = [...tables, ...[
                {
                    id: "orders",
                    title: "Orders",
                    data: lines.map(({ shifts, ...cols }) => cols),
                    renderers: {
                        orders: (orders) => this.renderHorizontalTable("lines_orders", "", orders, {
                            time_start: this.renderDate
                        })
                    }
                },
                {
                    id: "shifts",
                    title: "Shifts",
                    data: lines.map(line => ({
                        line: line.line,
                        shifts: line.shifts
                    })),
                    renderers: {
                        shifts: (shifts) => this.renderHorizontalTable("lines_shifts", "", shifts, {})
                    }
                },
                {
                    id: "unscheduled",
                    title: "Unscheduled",
                    data: unscheduled,
                    renderers: {}
                },
                {
                    id: "plants",
                    title: "Plants",
                    data: plant_uuids && this.mapPlants(plant_uuids),
                    renderers: {}
                },
                {
                    id: "material",
                    title: "Materials",
                    data: material_uuids && material_uuids.map(uuid => ({
                        uuid,
                        title: this.state.material_titles[uuid]
                    })),
                    renderers: {
                        material_uuids: this.renderMaterial
                    }
                },
                {
                    id: "next_shift_time",
                    title: "Next shift time",
                    data: next_shift_time,
                    renderers: {}
                },
                {
                    id: "analysis_configuration",
                    title: "Analysis configuration",
                    data: analysis_configuration,
                    renderers: {}
                },
                {
                    id: "data_props",
                    title: "Data properties",
                    data: data_props,
                    renderers: {}
                }
            ]];
        }

        return (
            <React.Fragment>
                {this.renderTables(tables)}
            </React.Fragment>
        );
    }

    renderActions() {
        if (this.state.selected_item.type !== JOB_TYPES.production_simulation) {
            return null;
        }

        return (
            <React.Fragment>
                <div className="button-list d-flex justify-content-end mt-3">
                    <button className="btn btn-outline-primary" onClick={this.onCompareClick}>
                        Compare with
                    </button>
                </div>
                <ChangesModal
                    show={this.state.show_changes_modal}
                    onHide={this.onChangesModalClose}
                    job_info={this.state.selected_item}
                />
            </React.Fragment>
        );
    }

    render() {
        const { input } = this.state.selected_item;
        return (
            <ResourcesLayout id="admin-background-jobs">
                <ResourcesLayout.Header
                    title={<React.Fragment>
                        <h2>
                            <FormattedMessage id="Header.menu.background_jobs" defaultMessage="Background jobs" />
                        </h2>
                    </React.Fragment>}
                    buttons={
                        <React.Fragment>
                            <button onClick={() => this.onDownloadInput()} className="btn ml-3">Download input</button>
                            <button onClick={() => this.onDownloadResult()} className="btn ml-3">Download result</button>
                        </React.Fragment>
                    }
                />
                <ResourcesLayout.Main>
                    <ResourcesLayout.List>
                        <ItemList
                            items={this.state.items}
                            users={this.state.users}
                            selected_key={this.state.selected_key}
                            handleClick={this.onClickItemList}
                            handleFilterChange={this.filterItems}
                        />
                    </ResourcesLayout.List>
                    <ResourcesLayout.Content>
                        <ErrorComponent
                            type="error"
                            msg={this.state.error} />
                        {this.renderActions()}
                        {this.state.selected_item && <div className="row mt-3">
                            <div className="col-lg-6 col-md-6">
                                <div>
                                    Type: {this.state.selected_item.type}
                                </div>
                                <div>
                                    Title: {this.state.selected_item.title}
                                </div>
                                <div>
                                    UUID: {this.state.selected_item.uuid}
                                </div>
                                <div>
                                    Status: {this.state.selected_item.status}
                                </div>
                                <div>
                                    Status message: {this.state.selected_item.status_msg}
                                </div>
                                <div>
                                    User UUID: {this.state.selected_item.created_by}
                                </div>
                                {input && input.data && input.data.cache_hash && (
                                    <div>
                                        Session hash: {input.data.cache_hash}
                                    </div>
                                )}
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div>
                                    Created at: {niceDateTime(new Date(this.state.selected_item.created_at))}
                                </div>
                                <div>
                                    Enqueued at: {niceDateTime(new Date(this.state.selected_item.enqueued_at))}
                                </div>
                                <div>
                                    Started at: {niceDateTime(new Date(this.state.selected_item.started_at))}
                                </div>
                                <div>
                                    Ended at: {niceDateTime(new Date(this.state.selected_item.ended_at))}
                                </div>
                                <div>
                                    Duration: {createDurationString(this.state.selected_item.ended_at - this.state.selected_item.started_at, true)}
                                </div>
                            </div>
                        </div>}
                        {Object.keys(this.state.selected_item.tags).length > 0 && (
                            <div>
                                Tags:
                                {this.renderVerticalTable("", "", this.state.selected_item.tags, {
                                    line_uuids: uuids => this.renderList(uuids.split(",")),
                                    client_started_ts: ts => this.renderDate(Number(ts))
                                })}
                            </div>
                        )}
                        {this.state.selected_item.type === JOB_TYPES.production_planning ? (
                            <Tabs
                                className="background-job-tabs mt-3"
                                bsStyle="pills"
                                animation={false}
                                activeKey={this.state.tab_key}
                                onSelect={this.onTabSelect}
                                id="production-planning-tabs"
                            >
                                <Tab eventKey="input" title="Input" tabClassName="nav-item">
                                    {this.renderProductionPlanningInput()}
                                </Tab>
                                <Tab eventKey="result" title="Result" tabClassName="nav-item">
                                    {this.renderProductionPlanningResult()}
                                </Tab>
                            </Tabs>
                        ) : this.state.selected_item.type === JOB_TYPES.production_simulation ? (
                            <div className="mt-3">
                                {this.renderProductionSimulationInput()}
                            </div>
                        ) : (
                            <textarea
                                className="mt-3"
                                rows={35}
                                onChange={this.onChange}
                                value={this.state.content}
                                style={{ width: "100%" }}
                            />
                        )}
                    </ResourcesLayout.Content>
                </ResourcesLayout.Main>
            </ResourcesLayout>
        );
    }
}
export default BackgroundJobs;
