// @flow
import * as React from "react";
import { connect } from "react-redux";
import * as tt from "../../lib/backend/ticketing.generated.types";
import { SET_INBOX_ITEM_VIEW } from "./reducers/ticketing";
import TicketStatus from "./TicketStatus";
import { translate } from "../IntlProviderWrapper";
import ErrorComponent from "../ErrorComponent";
import { getBackend as getBackend2 } from "../../lib/backend/Backend2";

import type { SelectInboxItemView } from "./reducers/ticketing";

import { niceDate, niceDateTime, niceTime } from "../../lib/Util";
import { FormattedMessage } from "react-intl";

type WorkerInboxItemShortProps = {
    ticket: tt.ITicketShortInfo,
    reduxDispatch: (args: SelectInboxItemView) => void
}
type WorkerInboxItemShortState = {
    ticket_info: tt.IGetUserStepInfoRes
}

class WorkerInboxItemShort extends React.Component<WorkerInboxItemShortProps, WorkerInboxItemShortState> {

    constructor(props: WorkerInboxItemShortProps) {
        super(props);
        this.getData(this.props.ticket.uuid);
    }


    onClick = () => {
        this.props.reduxDispatch({
            type: SET_INBOX_ITEM_VIEW,
            data: this.props.ticket.uuid
        });
    }

    async getData(ticket_uuid: string): Promise<void> {
        const ticket_info = await getBackend2().ticketing.getUserStepInfo({
            uuid: ticket_uuid
        });

        this.setState({
            ticket_info: ticket_info
        });
    }

    drawTicket() {

        
        if (!this.state || !this.props.ticket) {
            return null;
        }

        const ticket = this.props.ticket;
        const assigned_on = new Date(ticket.assigned_on);
        const due_date: Date | null = ticket.due_date == null ? new Date() : new Date(ticket.due_date);
        return (<div style={{ width: "100%" }}>

            <div style={{ display: "flex", paddingTop: "5px", justifyContent: "space-between", alignContent: "center", textAlign: "center" }}>
                <span style={{ fontSize: "0.85rem", paddingTop: "3px" }}>
                    {ticket.workflow_title}
                </span>
                <TicketStatus
                    status={""}
                    color={ticket.status_color}
                    bgcolor={ticket.status_bgcolor}
                    is_closed={ticket.is_closed}
                    is_error={ticket.is_error}
                />
            </div>

            <div style={{ fontWeight: "600", fontSize: "1.4rem", paddingBottom: "7px" }}>
                {this.state.ticket_info && this.state.ticket_info.title}
            </div>

            <div style={{ fontSize: "1rem" }}>
                {this.state.ticket_info && this.state.ticket_info.text}
            </div>

            <div style={{ fontSize: "0.9rem", paddingTop: "10px" }}>
                <FormattedMessage id="Ticketing.assigned_on" />:
                <img src={"../../img/calendar-check-solid.svg"}
                    style={{ width: "16px", height: "16px", marginLeft: "5px", marginRight: "5px" }}
                />
                <strong>
                    {niceDate(assigned_on)} &nbsp;
                    {niceTime(assigned_on)}
                </strong>
                {due_date != null && <span className="badge badge-primary ml-2">
                    <FormattedMessage id="Ticketing.due" />: &nbsp;
                    {niceDateTime(due_date)}
                </span>

                }
            </div>
        </div>

        );
    }


    render() {
        /** add assignee translation */
        return <div id="worker-inbox-item" className="worker-inbox-item" onClick={this.onClick}>
            {this.drawTicket()}
        </div>
    }
}
const WorkerInboxItemShortConnected = connect(
    null,
    (dispatch) => ({ reduxDispatch: dispatch })
)(WorkerInboxItemShort);

type Props = {
    tickets: tt.ITicketShortInfo[]
};
type State = {};

export default class WorkerInbox extends React.Component<Props, State> {

    renderTickets(): React.Node {
        return this.props.tickets.map(t => <WorkerInboxItemShortConnected ticket={t} key={"key-" + t.uuid} />);
    }

    renderEmpty() {
        return <ErrorComponent msg={translate("common.your_inbox_empty", "Your inbox is empty")} type={"info"} />;
    }

    render() {
        return <div className="worker-inbox">
            {this.props.tickets.length > 0 ? this.renderTickets() : this.renderEmpty()}
        </div>
    }
}


