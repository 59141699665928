// @flow
import type { LineOrders, IOrderProducedModelEx } from "./linesOrders";

export const mapLinesOrdersToListOrders = (lines_orders: LineOrders[]): IOrderProducedModelEx[] => {
    const orders: IOrderProducedModelEx[] = [];

    for (const line of lines_orders) {
        for (const order of line.orders) {
            orders.push(order);
        }
    }

    return orders;
}

export const countUnscheduledOrders = (unscheduled_orders: LineOrders[]): number =>  {
    return unscheduled_orders.length;
}

export const findOrderIndex = (order_uuid: string, unscheduled_orders: LineOrders[]) => {
    for (const line of unscheduled_orders) {
        const index = line.orders.findIndex(o => o.uuid === order_uuid);
        if (index >= 0) return index;
    }

    return -1;
}

export const findLineIndex = (order_uuid: string, unscheduled_orders: LineOrders[]) => {
    return unscheduled_orders.findIndex(l => l.orders.find(o => o.uuid === order_uuid));
}
