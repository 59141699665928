// @flow
import React from "react";
import { Modal } from "react-bootstrap";
import { translate } from "./IntlProviderWrapper";

type Props = {};
type State = {
    show_version_notification: boolean
}

let remind_me_timeout = null;

const REMIND_ME_TIMEOUT_MS = 1000 * 60 * 60; // 1h

// utility internal function for hard-reloading of page
export const hardReload = async () => {
    const caches_local = caches || window.caches;
    if (caches_local) {
        const names = await caches_local.keys();
        for (const name of names) {
            await caches.delete(name);
        }
        window.location.reload();
    }
    window.location.reload();
}

const remindMeLater = () => {
    if (!remind_me_timeout) {
        remind_me_timeout = setTimeout(() => {
            const first = translate("Header.VersionNotification.sentence1", "Nova verzija je bila nameščena na strežnik.");
            const second = translate("common.new_version", "Do you want to refresh the page?");
            if (window.confirm(`${first}\n${second}`) === true) {
                if (remind_me_timeout) {
                    clearTimeout(remind_me_timeout);
                }
                hardReload();
                return;
            }
            if (remind_me_timeout) {
                clearTimeout(remind_me_timeout);
            }
            remindMeLater();
        }, REMIND_ME_TIMEOUT_MS);
    }
}

export default class VersionCheck extends React.Component<Props, State> {

    show_version_modal_listener = null;

    state = {
        show_version_notification: false
    }

    static clearRemindMe = () => {
        if (remind_me_timeout) {
            clearTimeout(remind_me_timeout);
        }
    }

    static showVersionModal = () => {
        window.dispatchEvent(new CustomEvent("show-version-modal-listener"));
    }

    componentDidMount() {
        this.show_version_modal_listener = () => this.showVersionModal();
        window.addEventListener("show-version-modal-listener", this.show_version_modal_listener);
    }

    componentWillUnmount() {
        VersionCheck.clearRemindMe();
        window.removeEventListener(
            "show-version-modal-listener",
            this.show_version_modal_listener
        );
    }

    showVersionModal = () => {
        if (remind_me_timeout || this.state.show_version_notification) {
            return;
        }
        this.setState({
            show_version_notification: true
        });
    }

    onRefresh = () => {
        this.setState({
            show_version_notification: false
        })
        hardReload();
    }

    onRemindMeLater = () => {
        remindMeLater();
        this.setState({
            show_version_notification: false
        });
    }

    render() {
        return <Modal
            show={this.state.show_version_notification}
        >
            <Modal.Header>
                <Modal.Title>
                    <span>
                        {translate("Header.VersionNotification.title", "New version")}
                    </span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {translate("Header.VersionNotification.sentence1", "Nova verzija je bila nameščena na strežnik.")}
                <br/>
                {translate("common.new_version", "Do you want to refresh the page?")}
            </Modal.Body>
            <Modal.Footer>
                <div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                    <button className="btn btn-primary" onClick={this.onRemindMeLater}>
                        {translate("common.remind_me_later", "Remind me later")} (1h)
                    </button>
                    <button className="btn btn-primary" onClick={this.onRefresh}>
                        {translate("common.refresh", "Refresh")}
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    }
}