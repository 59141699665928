// @flow
import * as React from "react";
import * as Utils from "../lib/Util";
import { Sparklines, SparklinesLine } from "react-sparklines";

type Props = {
    label: string,
    comment?: string,
    value: number,
    unit?: string,
    note?: string,
    icon?: string,
    sparkline?: Array<number>,
    color?: string
};

const Box = (props: Props) => {
    return (
        <div className="white_box boxstat">
            <dl>
                <dt>{props.label} <span>{props.note}</span></dt>
                <dd className="clearfix">
                    <p>
                        <span>{props.comment} </span>
                        {Utils.niceNumber(props.value)}
                        <span> {props.unit}</span>
                    </p>
                    <div className="sparkline-box">
                        <Sparklines data={props.sparkline}>
                            <SparklinesLine color={props.color} />
                        </Sparklines>
                    </div>
                </dd>
            </dl>
        </div>
    );
}

export default Box;
