// @flow
import * as React from "react";
import ReactRouterPropTypes from "react-router-prop-types";
import * as Auth from "../lib/Auth";

type Props = {
    match: ReactRouterPropTypes.match,
    history: Array<mixed>
};

type State = {
    message: string
};

class Oauth2Step2 extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            message: ""
        };
    }

    async componentDidMount() {
        try {
            const urlParams = new URLSearchParams(window.location.search);
            const code = urlParams.get("code");
            const state = urlParams.get("state");
            const request_server = await Auth.oauthEngine(state);
            if (request_server) {
                await Auth.oauth2Step2(code, state);
                this.setState({ message: "" });
                this.props.history.push("/");
            }
        } catch (err) {
            this.setState({ message: err.message });
        }
    }

    render() {
        return (
            <section id="register" key="1">
                <div className="caption_div">
                    <h3 className="text-center pt-3">Signing In</h3>
                    <hr />
                    <div className="text-center">
                        Please wait while we sign you in...
                    </div>
                </div>
            </section>
        );
    }
};

export default Oauth2Step2;
