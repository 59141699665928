// @flow
import * as t2 from "../../../../lib/SimulationReportModels";
import {
    RESET_GANTT,
    UPDATE_REPORT_ACTION_NAME,
    UPDATE_REPORT_ACTION_NAME2,
    MOVE_ORDER_GANTT_ACTION_NAME
} from "./common";
import { dateFromWeekAndShift, deepClone } from "../../../../lib/Util";

import type { PayloadAction } from "../../../redux/types"
import type {
    ResetGanttAction,
    SetAllAsUnscheduledAction,
    UpdateReport2Action,
    UpdateReportAction,
    MoveOrderGanttAction
} from "./common";
import type { IExtraLines } from "../../../../lib/backend/manufacturing2.generated.types";
import { ReportMaps } from "./Mappers";

export const REPORT_LOADING_ACTION_NAME: "report_loading" = "report_loading";
export const RUN_CALCULATION_ACTION_NAME: "run_calculation" = "run_calculation";
export const RESET_REPORT_ACTION_NAME: "reset_report" = "reset_report";
export const ADD_KPI_ACTION_NAME: "add_kpis" = "add_kpis";
export const ADD_EXTRA_LINES_ACTION_NAME: "add_extra_lines" = "add_extra_lines"; // improves quickSimulation performance, avoids many db queries


export type ReportLoadingAction = PayloadAction<typeof REPORT_LOADING_ACTION_NAME, boolean>;
export type RunCalculationAction = PayloadAction<typeof RUN_CALCULATION_ACTION_NAME, typeof undefined>;
export type ResetReportAction = PayloadAction<typeof RESET_REPORT_ACTION_NAME, typeof undefined>;
export type AddExtraLinesAction = PayloadAction<typeof ADD_EXTRA_LINES_ACTION_NAME, IExtraLines[]>;

export type ReportState = {
    report_loading: boolean,
    run_calculation: number,
    report_data: t2.IReportModelEx | null,
    original_report_uuid: string | null,
    original_report_created_at: number | null,
    current_report_uuid: string | null,
    extra_lines: IExtraLines[] | null
}

export const report_initial_state: ReportState = {
    report_loading: true,
    run_calculation: 0,
    report_data: null,
    original_report_uuid: null,
    current_report_uuid: null,
    extra_lines: null,
    original_report_created_at: null
}

export type ReportActionsTypes = (
    UpdateReportAction | ReportLoadingAction |
    RunCalculationAction | ResetReportAction | ResetGanttAction |
    AddExtraLinesAction | UpdateReport2Action | SetAllAsUnscheduledAction |
    MoveOrderGanttAction
);

export const reportReducer = (state: ReportState, action: ReportActionsTypes) => {
    if (!state) state = deepClone(report_initial_state)
    let new_state = state;
    if (action.type === RESET_GANTT) {
        ReportMaps.clearMaps();
        return deepClone(report_initial_state);
    } if (action.type === UPDATE_REPORT_ACTION_NAME) {
        new_state.report_data = action.data;


        if (new_state.original_report_uuid === null) {
            new_state.original_report_uuid = action.data.uuid;
        } else {
            new_state.current_report_uuid = action.data.uuid;
        }
        if (new_state.original_report_created_at === null) {
            new_state.original_report_created_at = action.data.created_at
        }
        const {
            week,
            shift_number,
            year
        } = new_state.report_data.result.line_shift_features[0].shifts[0].shift_time;
        new_state = {
            ...new_state,
            first_shift_time_ts: (dateFromWeekAndShift(week, year, shift_number)).getTime()
        };
    } else if (action.type === UPDATE_REPORT_ACTION_NAME2) {
        new_state.report_data = action.data.quick_report;
        const report_uuid = new_state.report_data.uuid;
        if (new_state.original_report_uuid === null) {
            new_state.original_report_uuid = report_uuid;
        } else {
            new_state.current_report_uuid = report_uuid;
        }
        if (new_state.original_report_created_at === null) {
            new_state.original_report_created_at = new_state.report_data.created_at
        }

        new_state.extra_lines = action.data.extra_lines;
        const {
            week,
            shift_number,
            year
        } = new_state.report_data.result.line_shift_features[0].shifts[0].shift_time;
        new_state = {
            ...new_state,
            first_shift_time_ts: (dateFromWeekAndShift(week, year, shift_number)).getTime()
        };
    } else if (action.type === RUN_CALCULATION_ACTION_NAME) {
        new_state.run_calculation++;
        new_state = { ...new_state };
    } else if (action.type === REPORT_LOADING_ACTION_NAME) {
        new_state.report_loading = action.data;
        return {...new_state};
    } else if (action.type === RESET_REPORT_ACTION_NAME) {
        return {...report_initial_state};
    } else if (action.type === MOVE_ORDER_GANTT_ACTION_NAME) {
        new_state.report_loading = true;
        return {...new_state};
    }

    return new_state;
}
