// @flow
import React from "react";
import { connect } from "react-redux";
import Select from "react-select";

import { RESET_FILTERS } from "../../reducers/reducersFilters/filters";
import { translate } from "../../../../IntlProviderWrapper";
import { LinesOrdersMaps } from "../../reducers/Mappers";
import { ReduxFilters } from "../../reducers/BusinessLogic";

import type { SetLockFilter } from "../../reducers/common";
import type { SetRescheduleOrderAction, SetRescheduleOrderBundleAction } from "../../reducers/common";
import type { SetHighlightedOrders, SetLastClickedOrderUuid } from "../../reducers/planningTable";
import type { LineOrders } from "../../reducers/linesOrders";
import type { SelectOptionItem } from "./TypeFilterConsts";
import type { ReduxState } from "../../reducers/reducers";
import type { ResetFiltersAction } from "../../reducers/reducersFilters/filters";
import type { OrderHighlights } from "../../reducers/linesOrders";

type Props = {
    reduxDispatch: (args: SetRescheduleOrderAction |
        ResetFiltersAction | SetHighlightedOrders | SetLastClickedOrderUuid
        | SetRescheduleOrderBundleAction | SetLockFilter | ResetFiltersAction
    ) => void,
    orders: LineOrders[],
    reschedule_order_uuid: string,
    is_filter_locked: boolean,
    highlighted_orders_map: Map<string, OrderHighlights> | null
};
type State = {};

class ReworkOrderFilter extends React.Component<Props, State> {

    onChange = (option: SelectOptionItem) => {
        if (!option) {
            this.props.reduxDispatch({ type: RESET_FILTERS, data: undefined });
        } else {
            ReduxFilters.setRescheduleOrderUuid(this.props.reduxDispatch, option.value, "line_order_list", this.props.is_filter_locked);
        }
    }

    getSelectedOptions = (): SelectOptionItem | null => {
        for (const line_order of this.props.orders) {
            for (const order of line_order.orders) {
                if (this.props.highlighted_orders_map && this.props.highlighted_orders_map.has(order.uuid)) {
                    return {
                        value: order.uuid,
                        label: order.external_id + " - " + order.process_num
                    }
                }
            }
        }
        return null;
    }

    getOptions = (): SelectOptionItem[] => {
        const base_orders_uuids = [...LinesOrdersMaps.base_uuid_rework_orders.keys()];
        const rework_orders_uuids = [...LinesOrdersMaps.rework_uuid_base_order.keys()];
        const rework_related_uuids = base_orders_uuids.concat(rework_orders_uuids);
        const options = []
        for (const line_order of this.props.orders) {
            for (const order of line_order.orders) {
                if (!rework_related_uuids.includes(order.uuid)) {
                    continue;
                }
                options.push({
                    value: order.uuid,
                    label: order.external_id + " - " + order.process_num
                });
            }
        }
        return options;
    }

    render() {
        return (
            <div className="order-filter">
                <Select
                    value={this.getSelectedOptions()}
                    placeholder={translate("common.operation", "Operation")}
                    options={this.getOptions()}
                    onChange={this.onChange}
                    isSearchable={true}
                    isClearable
                />
            </div>
        );
    }
}

export default connect(
    (state: ReduxState) => {
        let orders = state.gantt_chart_lines_orders.lines_orders.concat(
            state.gantt_chart_lines_orders.unscheduled_orders
        );
        const highlighted_orders_map = state.gantt_chart_planning_table.highlighted_orders.highlighted_orders_map
        return {
            highlighted_orders_map: highlighted_orders_map,
            orders,
            is_filter_locked: state.gantt_chart_filters.is_filter_locked,
        }
    },
    (dispatch) => ({reduxDispatch: dispatch})
)(ReworkOrderFilter);