// @flow

import React from "react";

import * as t from "../../lib/backend/manufacturing2.generated.types";
import * as sf from "../../lib/StockForecast";
import { translate } from "../IntlProviderWrapper";
import { niceNumber, weekNumber, isValueAlmostZero } from "../../lib/Util";
import MaterialStockForecastCell from "./MaterialStockForecastCell";
import MaterialStockForecastNoMaterials from "./MaterialStockForecastNoMaterials";
import TranslatedMaterialTitle from "../TranslatedMaterialTitle";

type DayHeader = {
    date: string,
    day: string,
    col_width: number
}

type Props = {
    shift_headers: sf.ShiftHeader[],
    show_affiliated_stock: boolean,
    safety_stock_show: boolean,
    safety_stock_account_for: boolean,
    day_headers: DayHeader[],
    selected_subtype: sf.MaterialForecastSubtype,
    selected_aggregate?: string,
    selected_display_type?: string,
    only_accounted_for_initial_stock: string,
    selected_location?: string,
    hide_details: boolean,
    materials: t.IMaterialForecast[],
    filter_title: string,
    getForecast?: Function,
    forecasts_materials_changing: t.IMaterialForecast[],
    forecasts_materials_nonchanging: t.IMaterialForecast[],
    forecasts_materials_zero: t.IMaterialForecast[],
    showMaterialInfoModal: (string) => void,
    report_id?: string
}

type MaterialProps = {
    material: t.IMaterialForecast,
    shift_headers: sf.ShiftHeader[],
    show_affiliated_stock: boolean,
    safety_stock_show: boolean,
    safety_stock_account_for: boolean,
    selected_subtype: string,
    selected_aggregate?: string,
    selected_aggregate?: string,
    selected_display_type?: string,
    only_accounted_for_initial_stock: string,
    selected_location?: string,
    hide_details: boolean,
    show_no_change: boolean,
    hide_arrows: boolean,
    idx: number,
    getForecast?: Function,
    forecasts_materials_changing: t.IMaterialForecast[],
    forecasts_materials_nonchanging: t.IMaterialForecast[],
    forecasts_materials_zero: t.IMaterialForecast[],
    showMaterialInfoModal: (string) => void,
    report_id?: string
}

function getCellCss(value: number, is_lite_css: boolean, prev_summation: ?number) {
    let css = "text-right material-stock-prediction-cell";

    if (value < 0) {
        css += " stock_missing";
    } else if (value === 0 && prev_summation === 0) {
        css += " stock_empty";
    } else {
        css += " stock_available";
    }

    if (value == prev_summation || (value !== 0 && is_lite_css)) {
        css += "_lite"
    }

    return css;
}

const getStrippedBackgroundWeek = (values1: any, idx: number) => {


    const values = [];

    for (let i = 0; i < values1.length; i++) {

        const value = values1[i];
        if (isValueAlmostZero(value.stock)) {
            value.stock = 0;
        }

        values.push(value);
    }

    const css_style = "text-right material-stock-prediction-cell ";

    let count_positive = 0;
    let count_negative = 0;
    let count_zero = 0;
    let changed = 0;

    if (idx > 22) {

        for (let i = 0; i < 21; i++) {
            if (values[idx - i].stock > 0) {
                count_positive++;
            } else if (values[idx - i].stock < 0) {
                count_negative++;
            } else {
                count_zero++;
            }
            if (values[idx - i].change === true) {
                changed++;
            }
        }

        if (count_zero === 21) {
            return css_style + "stock_empty";
        } else if ((count_positive + count_zero) === 21) {
            if (changed === 0) {
                return css_style + "stock_available_lite";
            } else {
                return css_style + "stock_available";
            }
        } else if (count_negative === 21) {
            if (changed === 0) {
                return css_style + "stock_missing_lite";
            } else {
                return css_style + "stock_missing";
            }
        } else {
            if (changed > 0) {
                if (values[idx - 1].stock >= 0) {
                    return css_style + "stock_stripped";
                } else {
                    return css_style + "stock_missing";
                }
            }
        }
    } else {
        // idx < 21

        for (let i = 0; i < idx; i++) {
            if (values[i].stock > 0) {
                count_positive++;
            } else if (values[i].stock < 0) {
                count_negative++;
            } else {
                count_zero++;
            }
            if (values[i].change === true) {
                changed++;
            }
        }

        if (count_zero === idx) {
            return css_style + "stock_empty_lite";
        } else if ((count_positive + count_zero) === idx) {
            if (changed === 0) {
                return css_style + "stock_available_lite";
            } else {
                return css_style + "stock_available";
            }
        } else if (count_negative === idx) {
            if (changed === 0) {
                return css_style + "stock_missing_lite";
            } else {
                return css_style + "stock_missing";
            }
        } else {
            if (changed > 0) {
                if (values[idx].stock >= 0) {
                    return css_style + "stock_stripped";
                } else {
                    return css_style + "stock_missing";
                }
            }
        }
    }
}

const getStrippedBackgroundDay = (values1: any, idx: number) => {
    const values = [];

    for (let i = 0; i < values1.length; i++) {

        const value = values1[i];
        if (isValueAlmostZero(value.stock)) {
            value.stock = 0;
        }

        values.push(value);
    }

    const css_style = "text-right material-stock-prediction-cell ";

    if (idx === 1) {
        if (values[idx].stock >= 0) {
            if ((values[idx].change === true || values[idx - 1].change === true)) {
                return css_style + "stock_available";
            } else {
                return css_style + "stock_available_lite";
            }
        } else if (values[idx].stock < 0) {
            if (values[idx].change === true || values[idx - 1].change === true) {
                return css_style + "stock_missing";
            } else {
                return css_style + "stock_missing_lite";
            }
        } else {
            return css_style + "stock_empty_lite";
        }
    } else if (idx == 0) {
        if (values[idx].stock >= 0) {
            if (values[idx].change === true) {
                return css_style + "stock_available";
            } else {
                return css_style + "stock_available_lite";
            }
        } if (values[idx].stock === 0) {
            return css_style + "stock_empty_lite";
        } else {
            if (values[idx].change === true) {
                return css_style + "stock_missing";
            } else {
                return css_style + "stock_missing_lite";
            }
        }
    }

    if (values[idx].stock === 0 && values[idx - 1].stock === 0 && values[idx - 2].stock === 0 &&
        values[idx].change === false && values[idx - 1].change === false && values[idx - 2].change === false) {
        return css_style + "stock_empty_lite";
    }

    if (values[idx].stock >= 0) {
        // last shift is positive, check if there was a negative stock
        if (values[idx - 1].stock >= 0) {
            // middle shift is positive, check if there was a negative stock in the first shift
            if (values[idx - 2].stock >= 0) {
                if (values[idx].change === true || values[idx - 1].change === true || values[idx - 2].change === true) {
                    // positive on all shifts and there was a change
                    return css_style + "stock_available";
                } else {
                    // so it is positive, but no change
                    return css_style + "stock_available_lite";
                }
            } else if (values[idx - 2].stock === 0) {
                return css_style + "stock_available";
            } else {
                if (values[idx - 2].change === true) {
                    // first shift was negative, but it changed to positive on middle shift
                    return css_style + "stock_stripped";
                } else {
                    // if negative stock if from before, then it is counted as available
                    return css_style + "stock_available";
                }
            }
        } else {
            if (values[idx - 1].change === false) {
                if (values[idx - 2].change === false) {
                    // if negative stock if from before, then it is counted as available
                    return css_style + "stock_available";
                }
                if (values[idx - 2].stock >= 0) {
                    // we ended first shift in positive stock, second shift had negative stock and we ended in positive
                    return css_style + "stock_stripped";
                }
            } else {
                return css_style + "stock_stripped";
            }
        }
    } else {
        if (values[idx].change === true || values[idx - 1].change === true || values[idx - 2].change === true) {
            return css_style + "stock_missing";
        } else {
            return css_style + "stock_missing_lite";
        }
    }
}

const getForecastCss = (
    selected_aggregate,
    values: any,
    material: any,
    show_no_change: boolean,
    safety_stock_account_for: boolean
) => {
    if (!values) return [];

    let summation_change: ?number = null;
    let summation: ?number = null;
    let isFullDay = false;
    let isLastDayOfWeek = false;
    let prev_summation: ?number = material.current_stock;

    return values.map((x, idx) => {
        let reset = false;

        if (idx === values.length - 1) {
            isFullDay = true;
            isLastDayOfWeek = true;
        } else if (x.shift_date !== values[idx + 1].shift_date) {
            isFullDay = true;
            isLastDayOfWeek = weekNumber(new Date(x.shift_date)) !== weekNumber(new Date(values[idx + 1].shift_date));
        }

        summation = x.stock;
        // check if we should include safety stock in the summation
        if (!safety_stock_account_for) {
            summation += Math.abs(material.safety_stock);
        }

        // the type of aggregation decides how the columns will span (1, 2, 3) and
        // when the summation through material forecast values will reset
        if (selected_aggregate === "shift") {
            // default aggregation
            summation_change = x.change ? 1 : 0;
            reset = true;
        } else if (selected_aggregate === "day") {
            summation_change += x.change;

            if (!isFullDay) {
                isFullDay = false;
                return null;
            }

            reset = true;
        } else if (selected_aggregate === "week") {
            summation_change += x.change;

            if (!isLastDayOfWeek) {
                isFullDay = false
                return null;
            }

            reset = true;
        }

        if (isValueAlmostZero(summation)) {
            summation = 0;
        }

        let css = getCellCss(
            summation,
            !summation_change && show_no_change,
            prev_summation
        );

        if (selected_aggregate === 'day') {
            css = getStrippedBackgroundDay(values, idx);
        } else if (selected_aggregate === 'week') {
            css = getStrippedBackgroundWeek(values, idx);
        }
        prev_summation = summation;

        if (reset) {
            summation = null;
            summation_change = 0;
            isLastDayOfWeek = false;
            isFullDay = false;
        }

        return css;
    });
}

const getForecastData = (
    material_external_id,
    forecasts_materials_changing,
    forecasts_materials_nonchanging,
    forecasts_materials_zero
) => {
    let current_material_forecasts = forecasts_materials_changing.filter(
        x => x.material_external_id === material_external_id
    );

    if (current_material_forecasts.length > 0) {
        return current_material_forecasts[0];
    }

    current_material_forecasts = forecasts_materials_nonchanging.filter(
        x => x.material_external_id === material_external_id
    );

    if (current_material_forecasts.length > 0) {
        return current_material_forecasts[0];
    }

    current_material_forecasts = forecasts_materials_zero.filter(
        x => x.material_external_id === material_external_id
    );

    if (current_material_forecasts.length > 0) {
        return current_material_forecasts[0];
    }

    return {};
}

type Value = {
    shift_date: string,
    shift_tag: string,
    stock: number,
    change: boolean
}
const getValues = (material: t.IMaterialForecast, shift_headers: sf.ShiftHeader[]): Value[] => {
    // actual values
    const values: t.IMaterialForecastItem[] = material.forecasts.map(x => x).slice(0, shift_headers.length);

    if (values.length === 0) {
        shift_headers.forEach(x => {
            values.push({
                shift_date: "",
                shift_tag: "",
                stock: 0,
                change: false
            });
        });
    }
    return values;
}

const Material = (props: MaterialProps) => {
    const {
        material,
        shift_headers,
        show_affiliated_stock,
        safety_stock_show,
        safety_stock_account_for,
        selected_subtype,
        selected_aggregate,
        hide_details,
        hide_arrows,
        idx: parentIdx,
        getForecast,
        selected_display_type,
        only_accounted_for_initial_stock,
        selected_location,
        show_no_change,
        // forecasts is used to color the non-combined subtype cells the same color as in combined subtype
        forecasts_materials_changing,
        forecasts_materials_nonchanging,
        forecasts_materials_zero,
        report_id
    } = props;

    // actual values
    const values = getValues(material, shift_headers);

    let summation_change: ?number = null;
    let summation: ?number = null;
    let prev_summation: ?number = material.current_stock;
    let isFullDay = false;
    let isLastDayOfWeek = false;

    if (selected_subtype === "combined" && !safety_stock_account_for) {
        prev_summation += Math.abs(material.safety_stock);
    }

    const rowTestProps = { "data-test-name": "material" };
    const testProps = { "data-test": material.current_stock, "data-test-name": `current_stock_${parentIdx}` };
    const current_material_forecasts = getForecastData(
        material.material_external_id,
        forecasts_materials_changing,
        forecasts_materials_nonchanging,
        forecasts_materials_zero
    );

    if (!current_material_forecasts.forecasts) return null;

    const forecast_cell_css = getForecastCss(
        selected_aggregate,
        current_material_forecasts.forecasts.map(x => x).slice(0, shift_headers.length),
        material,
        show_no_change,
        safety_stock_account_for
    );

    const material_el = TranslatedMaterialTitle(
        {
            material_external_id: material.material_external_id,
            material_title: material.material_title
        }
    );

    const safety_stock_fix = safety_stock_account_for ? 0.0 : Math.abs(material.safety_stock);

    return (<tr {...rowTestProps} key={material.material_uuid}>
        <td style={material.highlight_material ? {backgroundColor: "#FFD700"} : {}}>
            {material_el} {" (" + material.material_external_id + ")"}
            <img
                style={{ marginLeft: "20px", width: "20px", height: "20px" }}
                src="/img/eye_grey.svg"
                alt="show_details"
                onClick={() => props.showMaterialInfoModal(material.material_external_id)}
                title={translate("common.tooltip_show_details", "Show details")}
            />
        </td>
        <td className="day-end text-right" {...testProps}>
            {niceNumber(material.current_stock + safety_stock_fix, 0)}
        </td>
        {show_affiliated_stock &&
            <td className="day-end text-right" {...testProps}>
                {niceNumber(material.affiliated_stock, 0)}
            </td>
        }
        {safety_stock_show &&
            <td className="day-end text-right" {...testProps}>
                {niceNumber(Math.abs(material.safety_stock), 0)}
            </td>
        }
        {
            values.map((x, idx) => {
                let cellProps = {};
                let reset = false;

                if (idx === values.length - 1) {
                    isFullDay = true;
                    isLastDayOfWeek = true;
                } else if (x.shift_date !== values[idx + 1].shift_date) {
                    isFullDay = true;
                    isLastDayOfWeek = weekNumber(new Date(x.shift_date)) !== weekNumber(new Date(values[idx + 1].shift_date));
                }

                if (["produced_by_shift", "consumed_by_shift", "received_by_shift", "required_by_shift"].includes(selected_subtype)) {
                    summation += x.stock;
                } else {
                    summation = x.stock;
                    // if we are showing combined stock level and do not want safety stock
                    // to be accounted for we need to add safety stock to the level
                    if (selected_subtype === "combined" && !safety_stock_account_for) {
                        summation += Math.abs(material.safety_stock);
                    }
                }

                // the type of aggregation decides how the columns will span (1, 2, 3) and
                // when the summation through material forecast values will reset
                if (selected_aggregate === "shift") {
                    cellProps = { colSpan: 1 };
                    // default aggregation
                    summation_change = x.change ? 1 : 0;
                    reset = true;
                } else if (selected_aggregate === "day") {
                    cellProps = { colSpan: 3 };
                    summation_change += x.change;

                    if (!isFullDay) {
                        isFullDay = false;
                        return null;
                    }

                    reset = true;
                } else if (selected_aggregate === "week") {
                    cellProps = { colSpan: 3 };
                    summation_change += x.change;

                    if (!isLastDayOfWeek) {
                        isFullDay = false
                        return null;
                    }

                    reset = true;
                }

                const cell_data = {
                    idx,
                    material_external_id: material.material_external_id,
                    material_title: material.material_title,
                    summation,
                    prev_summation,
                    summation_change,
                    hide_arrows: hide_arrows,
                    is_last_shift: isFullDay,
                    selected_subtype: selected_subtype,
                    selected_aggregate,
                    selected_display_type,
                    only_accounted_for_initial_stock,
                    selected_location,
                    shift_date: x.shift_date
                };

                cellProps = {
                    ...cellProps,
                    cell_data,
                    "data-test": JSON.stringify(cell_data),
                    "data-test-name": `stock_${idx}`
                };

                const rowEl = <MaterialStockForecastCell
                    key={idx + "_stock_sm"}
                    current_value={summation}
                    prev_value={prev_summation}
                    hide_details={hide_details}
                    cellProps={cellProps}
                    hide_arrows={hide_arrows}
                    getForecast={getForecast}
                    cell_data={cell_data}
                    forecast_cell_css={forecast_cell_css[idx]}
                    report_id={report_id}
                />;

                prev_summation = summation;

                if (reset) {
                    summation = null;
                    summation_change = 0;
                    isLastDayOfWeek = false;
                    isFullDay = false;
                }

                return rowEl;
            })
        }
    </tr>);
}

type SummationProps = {
    sum: number,
    columns_summations: Array<Array<CellData>>,
    selected_aggregate: ?string,
    show_affiliated_stock: boolean,
    affiliated_stock_sum: number,
    safety_stock_show: boolean,
    safety_stock_sum: number
}
const Summation = (props: SummationProps) => {
    const summations = [];
    const col_span = props.selected_aggregate === "shift" ? 1 : 3;

    for (const cs of props.columns_summations) {
        for (const [index, cell_data] of cs.entries()) {
            if (cell_data === null) {
                continue;
            }
            if (summations[index]) {
                summations[index] += cell_data.summation;
            }
            else {
                summations[index] = cell_data.summation;
            }
        }
    }

    return <tr>
        <td></td>
        <td style={{ textAlign: "right" }}>{niceNumber(props.sum, 2)}</td>
        {
            props.show_affiliated_stock ? <td style={{ textAlign: "right" }}>{niceNumber(props.affiliated_stock_sum, 2)}</td> : null
        }
        {
            props.safety_stock_show ? <td style={{ textAlign: "right" }}>{niceNumber(props.safety_stock_sum, 2)}</td> : null
        }
        {summations.map((s, i) => {
            return <td key={i} style={{ textAlign: "right" }} colSpan={col_span}>{niceNumber(s, 2)}</td>
        })}
    </tr>
}

type CellData = {
    idx: number,
    material_external_id: string,
    summation: number,
    prev_summation: ?number,
    summation_change: ?number | null,
    is_last_shift: boolean,
    selected_aggregate: ?string,
    shift_date: string,
    cellProps: Object
} | null

const valuesMap = (
    material: t.IMaterialForecast,
    values: Value[],
    selected_aggregate: ?string,
    selected_subtype: string
): CellData[] => {
    let summation_change: ?number = null;
    let summation: ?number = null;
    let prev_summation: ?number = material.current_stock;
    let isFullDay = false;
    let isLastDayOfWeek = false;

    return values.map((x: Material, idx: number) => {
        let cellProps = {};
        let reset = false;

        if (idx === values.length - 1) {
            isFullDay = true;
            isLastDayOfWeek = true;
        } else if (x.shift_date !== values[idx + 1].shift_date) {
            isFullDay = true;
            isLastDayOfWeek = weekNumber(new Date(x.shift_date)) !== weekNumber(new Date(values[idx + 1].shift_date));
        }

        if (["produced_by_shift", "consumed_by_shift", "received_by_shift", "required_by_shift"].includes(selected_subtype)) {
            summation += x.stock;
        } else {
            summation = x.stock;
        }

        // the type of aggregation decides how the columns will span (1, 2, 3) and
        // when the summation through material forecast values will reset
        if (selected_aggregate === "shift") {
            // default aggregation
            summation_change = x.change ? 1 : 0;
            reset = true;
        } else if (selected_aggregate === "day") {
            summation_change += x.change;

            if (!isFullDay) {
                isFullDay = false;
                return null;
            }

            reset = true;
        } else if (selected_aggregate === "week") {
            summation_change += x.change;

            if (!isLastDayOfWeek) {
                isFullDay = false
                return null;
            }

            reset = true;
        }

        const cell_data = {
            idx,
            material_external_id: material.material_external_id,
            summation,
            prev_summation,
            summation_change,
            is_last_shift: isFullDay,
            selected_aggregate,
            shift_date: x.shift_date,
            cellProps
        };

        prev_summation = summation;

        if (reset) {
            summation = null;
            summation_change = 0;
            isLastDayOfWeek = false;
            isFullDay = false;
        }

        return cell_data;
    }).filter(el => el)
}


const MaterialStockForecastBody = (props: Props) => {
    const {
        hide_details,
        shift_headers,
        show_affiliated_stock,
        safety_stock_show,
        safety_stock_account_for,
        selected_subtype,
        selected_aggregate,
        selected_display_type,
        only_accounted_for_initial_stock,
        selected_location,
        filter_title,
        // getForecast is needed to fetch forecasts when clicking on tooltip
        getForecast,
        // forecasts is used to color the non-combined subtype cells the same color as in combined subtype
        forecasts_materials_changing,
        forecasts_materials_nonchanging,
        forecasts_materials_zero,
        report_id
    } = props;

    const hide_arrows = selected_subtype !== sf.MATERIAL_FORECAST_SUBTYPES.combined;

    const show_no_change = true;

    let material_stock_sum = 0;
    let affiliated_stock_sum = 0;
    let safety_stock_sum = 0;

    const columns_summations = [];
    const highlighted_materials: t.IMaterialForecast[] = [];
    const non_highlighted_materials: t.IMaterialForecast[] = [];
    for (const material of props.materials) {
        const material_title = TranslatedMaterialTitle({
            material_external_id: material.material_external_id,
            material_title: material.material_title
        });
        if (!filter_title || (
            filter_title.length > 0 &&
            material_title.toLowerCase().includes(filter_title) ||
            material.material_external_id.toLowerCase().includes(filter_title)
        )) {
            if (material.highlight_material) {
                highlighted_materials.push(material);
            } else {
                non_highlighted_materials.push(material);
            }
        }
    }

    const materials = [...highlighted_materials, ...non_highlighted_materials];

    return <tbody>
        {
            materials.length === 0 ?
                <MaterialStockForecastNoMaterials /> :
                // $FlowFixMe
                materials.map((material: t.IMaterialForecast, idx): Array<t.IMaterialForecast> => {
                    material_stock_sum += material.current_stock;
                    affiliated_stock_sum += material.affiliated_stock;
                    safety_stock_sum += Math.abs(material.safety_stock);
                    const values = getValues(material, shift_headers);
                    const columns_summation = valuesMap(material, values, selected_aggregate, selected_subtype)
                    columns_summations.push(columns_summation);
                    return <Material
                        key={idx}
                        material={material}
                        forecasts_materials_changing={forecasts_materials_changing}
                        forecasts_materials_nonchanging={forecasts_materials_nonchanging}
                        forecasts_materials_zero={forecasts_materials_zero}
                        shift_headers={shift_headers}
                        show_affiliated_stock={show_affiliated_stock}
                        safety_stock_show={safety_stock_show}
                        safety_stock_account_for={safety_stock_account_for}
                        hide_arrows={hide_arrows}
                        show_no_change={show_no_change}
                        hide_details={hide_details}
                        only_accounted_for_initial_stock={only_accounted_for_initial_stock}
                        selected_location={selected_location}
                        selected_aggregate={selected_aggregate}
                        selected_subtype={selected_subtype}
                        selected_display_type={selected_display_type}
                        idx={idx}
                        showMaterialInfoModal={props.showMaterialInfoModal}
                        // getForecast is needed to fetch forecasts when clicking on tooltip
                        getForecast={getForecast}
                        report_id={report_id}
                    />
                })
        }
        <Summation
            sum={material_stock_sum}
            columns_summations={columns_summations}
            selected_aggregate={selected_aggregate}
            show_affiliated_stock={show_affiliated_stock}
            affiliated_stock_sum={affiliated_stock_sum}
            safety_stock_show={safety_stock_show}
            safety_stock_sum={safety_stock_sum}
        />
    </tbody>
}

export default MaterialStockForecastBody;
