// @flow

import React from "react";
import SvgIcon, { type SvgIconProps } from "../SvgIcon";

const LeapLogo = (props: SvgIconProps) => (
    <SvgIcon {...props}>
        <svg viewBox="0 0 161 24.43">
            <g transform="translate(-124.423 -274.896)">
                <g transform="translate(229.562 279.807)">
                    <path
                        d="M354.862,300l-8,.009a.4.4,0,0,1-.4-.491l2.843-13.494a.391.391,0,0,1,.4-.313l1.608,0a.4.4,0,0,1,.4.491l-2.665,12.511a.4.4,0,0,0,.4.491l5.45-.007a.4.4,0,0,1,.4.491h0A.539.539,0,0,1,354.862,300Z"
                        transform="translate(-346.457 -285.502)"
                    />
                    <path
                        d="M380.668,286.523l-5.9.007a.428.428,0,0,0-.4.313L373.3,291.8a.4.4,0,0,0,.4.491l4.825-.006a.4.4,0,0,1,.4.491h0a.429.429,0,0,1-.4.313l-5.182.007a.427.427,0,0,0-.4.313l-1.155,5.362a.4.4,0,0,0,.4.491l5.9-.007a.4.4,0,0,1,.4.491h0a.391.391,0,0,1-.4.313l-8.4.01a.4.4,0,0,1-.4-.491l2.843-13.494a.391.391,0,0,1,.4-.313l8.086-.01a.4.4,0,0,1,.4.491h0A.39.39,0,0,1,380.668,286.523Z"
                        transform="translate(-358.475 -285.529)"
                    />
                    <path
                        d="M404.27,299.738l-1.832,0a.429.429,0,0,1-.4-.312l-.764-4.019a.391.391,0,0,0-.4-.312l-5.048.006a.554.554,0,0,0-.357.179l-2.5,4.247a.406.406,0,0,1-.357.179h-.626a.4.4,0,0,1-.313-.625l8.784-13.635a.337.337,0,0,1,.313-.179h.625a.429.429,0,0,1,.4.313l2.831,13.621A.381.381,0,0,1,404.27,299.738ZM401,293.846l-.944-4.913a.388.388,0,0,0-.715-.133l-2.9,4.917a.4.4,0,0,0,.358.581l3.842,0A.406.406,0,0,0,401,293.846Z"
                        transform="translate(-370.224 -285.268)"
                    />
                    <path
                        d="M430.521,285.614c2.1,0,3.083.175,3.887.665a2.449,2.449,0,0,1,1.164,2.277,4.961,4.961,0,0,1-1.96,4.112c-1.116.85-2.455,1.12-4.868,1.123h-1.429a.429.429,0,0,0-.4.313l-1.155,5.451a.392.392,0,0,1-.4.313l-1.608,0a.4.4,0,0,1-.4-.491l2.932-13.449a.391.391,0,0,1,.4-.313ZM429.1,293.12a3.327,3.327,0,0,0,2.724-1.075,5.544,5.544,0,0,0,1.2-3.575c0-1.474-.806-2.143-2.549-2.141l-1.563,0a.428.428,0,0,0-.4.313l-1.244,5.943a.4.4,0,0,0,.4.491H429.1Z"
                        transform="translate(-386.933 -285.45)"
                    />
                </g>
                <path
                    d="M294.293,295.928l-.008-6.66c0-2.7-1.13-3.913-3.515-3.91l-6.7.008c-2.385,0-3.553,1.264-3.55,3.919l.008,6.7c0,2.61,1.219,3.913,3.56,3.91l6.7-.008c2.295-.048,3.508-1.354,3.505-3.964Zm-9.225-.394-.007-5.85a.4.4,0,0,1,.4-.405l3.915,0a.4.4,0,0,1,.405.4l.007,5.85a.4.4,0,0,1-.4.405l-3.915,0A.474.474,0,0,1,285.068,295.534Z"
                    transform="translate(-82.178 -5.508)"
                />
                <path
                    d="M253.525,299.9l3.992,0a.4.4,0,0,0,.4-.4l-.012-9.777a.4.4,0,0,1,.4-.4l3.5,0a.4.4,0,0,0,.4-.4l0-3.14a.4.4,0,0,0-.4-.4l-12.648.016a.4.4,0,0,0-.4.4l0,3.139a.4.4,0,0,0,.4.4l3.678,0a.4.4,0,0,1,.4.4l.012,9.777A.276.276,0,0,0,253.525,299.9Z"
                    transform="translate(-65.457 -5.508)"
                />
                <path
                    d="M221.666,299.9l8.763-.01a.4.4,0,0,0,.4-.4l0-3.175a.4.4,0,0,0-.4-.4l-7.78.009a.4.4,0,0,1-.4-.4l-.007-5.813a.4.4,0,0,1,.4-.4l7.825-.009a.4.4,0,0,0,.4-.4l0-3.13a.4.4,0,0,0-.4-.4l-8.943.011c-2.37,0-3.8,1.346-3.8,3.895l.008,6.573C217.729,298.608,219.028,299.9,221.666,299.9Z"
                    transform="translate(-49.117 -5.508)"
                />
                <path
                    d="M157.379,299.9l11.027-.013a.4.4,0,0,0,.4-.406l0-3.2a.4.4,0,0,0-.406-.4l-6.211.008a.4.4,0,0,1-.405-.4l-.012-9.721a.4.4,0,0,0-.406-.4l-4.051.005a.4.4,0,0,0-.4.405l.017,13.772A.535.535,0,0,0,157.379,299.9Z"
                    transform="translate(-17.105 -5.508)"
                />
                <path
                    d="M138.236,295.956l-.008-6.685c0-2.692-1.134-3.949-3.521-3.946l-6.729.008c-2.388,0-3.559,1.263-3.555,3.955l.008,6.685c0,2.6,1.22,3.906,3.565,3.9l2.344,0a.389.389,0,0,1,.391.39v.564c0,2.648,1.307,3.992,4,3.989l2.952,0a.389.389,0,0,0,.391-.391l0-3.212a.389.389,0,0,0-.391-.39l-2,0a.389.389,0,0,1-.391-.39v-.26a.336.336,0,0,1,.3-.348c1.736-.306,2.646-1.61,2.644-3.867Zm-9.248-.423-.007-5.9a.39.39,0,0,1,.39-.392l3.951,0a.389.389,0,0,1,.391.39l.007,5.9a.389.389,0,0,1-.39.391l-3.951,0A.39.39,0,0,1,128.989,295.533Z"
                    transform="translate(0 -5.49)"
                />
                <path
                    d="M198.944,295.973l-8.865.011a.4.4,0,0,1-.405-.4v-1.215a.4.4,0,0,1,.4-.405l8.5-.01a.4.4,0,0,0,.4-.405l-.005-4.275c0-2.7-1.13-3.913-3.515-3.91l-6.7.008c-2.385,0-3.553,1.264-3.55,3.919l.008,6.7c0,2.61,1.219,3.913,3.56,3.91l9.809-.012a.4.4,0,0,0,.4-.405l0-3.51Zm-9.186-5.163v-1.08a.4.4,0,0,1,.4-.405l4.5-.006a.4.4,0,0,1,.405.4v1.08a.4.4,0,0,1-.4.405l-4.5.006A.4.4,0,0,1,189.758,290.81Z"
                    transform="translate(-32.004 -5.508)"
                />
                <path
                    d="M313.381,299.9l4.2-.005a.413.413,0,0,0,.415-.416l-.011-9.322a.413.413,0,0,1,.415-.416l2.123,0a.414.414,0,0,1,.416.415l0,1.8a.414.414,0,0,0,.416.415l3.923,0a.413.413,0,0,0,.415-.416l0-3.646c0-1.985-.7-2.907-2.772-2.9l-2.63,0c-1.108,0-1.8.141-2.26.972v-.6a.414.414,0,0,0-.416-.415l-4.2.005a.414.414,0,0,0-.415.416l.017,13.752A.34.34,0,0,0,313.381,299.9Z"
                    transform="translate(-99.278 -5.508)"
                />
                <g transform="translate(280.368 274.896)">
                    <path
                        d="M458.8,277.356a2.529,2.529,0,1,1-2.521-2.46A2.472,2.472,0,0,1,458.8,277.356Zm-4.425,0a1.906,1.906,0,0,0,1.92,1.965,1.883,1.883,0,0,0,1.875-1.949,1.9,1.9,0,1,0-3.795-.016Zm1.515,1.291h-.571v-2.46a4.975,4.975,0,0,1,.945-.076,1.544,1.544,0,0,1,.855.179.683.683,0,0,1,.241.541.629.629,0,0,1-.51.569v.031a.724.724,0,0,1,.449.6,1.911,1.911,0,0,0,.181.616h-.616a2.114,2.114,0,0,1-.194-.6c-.045-.27-.2-.39-.51-.39h-.27Zm.014-1.4h.27c.315,0,.57-.1.57-.359,0-.225-.165-.375-.525-.375a1.393,1.393,0,0,0-.315.029Z"
                        transform="translate(-453.747 -274.896)"
                    />
                </g>
            </g>
        </svg>
    </SvgIcon>
);

export default LeapLogo;
