// @flow
import * as React from "react";
import { Button, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import * as Auth from "../lib/Auth";
import ErrorComponent from "./ErrorComponent";
type Props = {
    history: Array<mixed>
};

type State = {
    email: string,
    submitted: boolean,
    message: string
};

class RecoverPassword extends React.Component<Props, State> {
    constructor() {
        super();
        this.state = {
            email: "",
            submitted: false,
            message: ""
        };
    }

    validateForm() {
        return Auth.validateEmail(this.state.email);
    }

    handleChange = (event: Event) => {
        let target = event.target;
        if (target instanceof HTMLInputElement) {
            if (target.id === "email") {
                const email = target.value;
                this.setState({ email });
            }
        }
    }

    handleSubmit = async (event: Event) => {
        event.preventDefault();
        try {
            await Auth.recoverPassword(this.state.email);
            this.setState({ message: "", submitted: true });
        } catch (err) {
            this.setState({ message: err.message });
        }
    }

    render_alert() {
        return (
            <ErrorComponent msg={this.state.message} type="warning" />
        );
    }

    render() {
        if (this.state.submitted) {
            // we already submitted for notification
            return (
                <section id="register" key="1">
                    <div className="caption_div">
                        <form className="form_box">
                            <h3>
                                <FormattedMessage id="Settings.recover_password" defaultMessage="Recover password" />
                            </h3>
                            {this.state.message !== "" && this.render_alert()}
                            <div className="form-group row m-t-30">
                                <br />
                                <ControlLabel>
                                    An email has been send to <b>{this.state.email}</b>.
                                    Please check for an email from our company and
                                    click on the included link to reset your password
                                </ControlLabel>
                            </div>
                            <div className="form-group row m-t-30">
                                <div className="col-sm-7">
                                    <Link className="blue_link" to="/login">
                                        <FormattedMessage id="common.sign_in" defaultMessage="Login" />
                                    </Link>
                                </div>
                            </div>
                        </form>
                    </div>
                </section>
            );
        }

        // we are waiting for the user to enter email
        return (
            <section id="register" key="1">
                <div className="caption_div">
                    <form className="form_box" onSubmit={this.handleSubmit}>
                        <h3>
                            <FormattedMessage id="Settings.recover_password" defaultMessage="Recover password" />
                        </h3>
                        {this.state.message !== "" && this.render_alert()}
                        <FormGroup controlId="email" bsSize="large" className="form-group">
                            <ControlLabel>
                                <FormattedMessage id="Settings.enter_email" defaultMessage="Enter email" />
                            </ControlLabel>
                            <FormControl autoFocus type="text" placeholder="Your email address"
                                value={this.state.email} onChange={this.handleChange} />
                        </FormGroup>
                        <Button block className="btn_register btn-primary" disabled={!this.validateForm()} type="submit">
                            <FormattedMessage id="common.reset" defaultMessage="Reset" />
                        </Button>
                        <div className="row">
                            <div className="col-sm-7">
                                <Link className="blue_link" to="/login">
                                    <FormattedMessage id="common.sign_in" defaultMessage="Login" />
                                </Link>
                            </div>
                            <div className="col-sm-5 text-right">
                                <Link className="blue_link" to="/register">
                                    <FormattedMessage id="common.create_account" defaultMessage="Create an account" />
                                </Link>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        );
    }
}

export default RecoverPassword;
