// @flow
import * as React from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { connect } from "react-redux";

import { translate } from "../../IntlProviderWrapper";
import {
    LINE_TAGS_ACCESS,
    MATERIAL_TAGS_ACCESS,
    ORDER_TAGS,
    ORDER_TAGS_ACCESS,
    PLANT_TAGS_ACCESS
} from "../../../lib/ManufacturingTags.generated";
import { UPDATE_OPERATION_LINKS } from "./reducers/linesOrders";
import { getBackend } from "../../../lib/backend/Backend2";
import { ORDER_STATUS, ORDER_TYPE } from "../../../lib/ManufacturingConsts.generated";
import ErrorComponent from "../../ErrorComponent";
import { arrayIntersection, classNames } from "../../../lib/Util";
import { LinesOrdersMaps } from "./reducers/Mappers";
import { ReduxFilters } from "./reducers/BusinessLogic";
import DraggableModal from "../../DraggableModal";
import { getPlantTags } from "../../../lib/BusinessLogic";
import Loader from "../../Loader";

import type { SetRescheduleOrderBundleAction } from "./reducers/common";
import type { ReduxState } from "./reducers/reducers";
import type {
    LineOrders,
    IOrderProducedModelEx,
    UpdateOperationLinksAction,
    OperationLink
} from "./reducers/linesOrders";
import type {
    IOrderModelBase,
    IGetOrdersSimpleReq,
    IMaterialModel
} from "../../../lib/backend/manufacturing2.generated.types";

// $FlowFixMe
import "../../../styles/link_orders_modal.scss";

export const LINK_ORDERS_MODE = {
    order: "order",
    parent_order: "parent_order"
};

const CHECKBOX_STATE = {
    checked: "checked",
    indeterminate: "indeterminate",
    unchecked: "unchecked"
};

export type LinkOrdersMode = $Keys<typeof LINK_ORDERS_MODE>;

type CheckboxState = $Keys<typeof CHECKBOX_STATE>;

type OrderOption = {
    id: string
};

type OperationsMap = Map<string, IOrderModelBase>;
type MaterialsMap = Map<string, IMaterialModel>;

type LinkTargetState = {
    uuid: string,
    linked: boolean
};

type LinksMap = Map<string, LinkTargetState>;

type Props = {
    rework_operation: IOrderProducedModelEx | null,
    link_mode: LinkOrdersMode | null,
    lines_orders: LineOrders[],
    unscheduled_orders: LineOrders[],
    ignored_orders: LineOrders[],
    extra_lines_operation_links: OperationLink[],
    is_filter_locked: boolean,
    reduxDispatch: (args: UpdateOperationLinksAction | SetRescheduleOrderBundleAction) => void,
    onClose: () => void
};

type State = {
    error: string,
    order_ids: string[],
    selected_order: string | null,
    rework_operations: OperationsMap,
    base_operations: OperationsMap,
    operation_link_candidates: Map<string, string[]>,
    operation_lines: Map<string, string[]>,
    links: LinksMap,
    materials: MaterialsMap,
    is_loading: boolean
};

const getOrderOptionValue = (option: OrderOption): string => {
    return option.id;
};

const isOperationLinked = (uuid: string) => {
    const rework_orders = LinesOrdersMaps.base_uuid_rework_orders.get(uuid) || [];
    return rework_orders.length > 0;
};

const isPairLinked = (rework_operation: IOrderModelBase, base_operation: IOrderModelBase): boolean => {
    return ORDER_TAGS_ACCESS.linked_operation(rework_operation.tags) === base_operation.uuid;
};

const isValidReworkOperation = (operation: IOrderModelBase): boolean => {
    return !isOperationLinked(operation.uuid) && !LINE_TAGS_ACCESS.planning_autopilot(operation.line.tags);
};

const isValidBaseOperation = (
    base_operation: IOrderModelBase,
    rework_operation: IOrderModelBase,
    materials: MaterialsMap,
    rework_line_uuids: string[],
    base_line_uuids: string[],
    link_mode: LinkOrdersMode
): boolean => {
    const base_order_id =
        link_mode === LINK_ORDERS_MODE.order
            ? base_operation.external_id
            : ORDER_TAGS_ACCESS.external_parent_order_id(base_operation.tags);
    const rework_order_id =
        link_mode === LINK_ORDERS_MODE.order
            ? rework_operation.external_id
            : ORDER_TAGS_ACCESS.external_parent_order_id(rework_operation.tags);
    let position_match = true;
    if (link_mode === LINK_ORDERS_MODE.parent_order) {
        const rework_order_position = ORDER_TAGS_ACCESS.external_parent_order_position(rework_operation.tags);
        const base_order_position = ORDER_TAGS_ACCESS.external_parent_order_position(base_operation.tags);
        if (rework_order_position !== base_order_position) {
            position_match = false;
        }
    }

    const is_base_external = !LinesOrdersMaps.all_orders_map.get(base_operation.uuid);
    const is_rework_external = !LinesOrdersMaps.all_orders_map.get(rework_operation.uuid);
    const valid_line_uuids_intersection = arrayIntersection(rework_line_uuids, base_line_uuids);

    return (
        base_operation.uuid !== rework_operation.uuid &&
        base_operation.plant_uuid === rework_operation.plant_uuid &&
        base_operation.process_num === rework_operation.process_num &&
        materialsMatch(base_operation.material_uuid, rework_operation.material_uuid, materials) &&
        valid_line_uuids_intersection.length > 0 &&
        !LINE_TAGS_ACCESS.planning_autopilot(base_operation.line.tags) &&
        ORDER_TAGS_ACCESS.linked_operation(base_operation.tags) === "" &&
        ((is_base_external && is_rework_external) || (!is_base_external && !is_rework_external)) &&
        base_order_id !== "" &&
        base_order_id !== rework_order_id &&
        position_match
    );
};

const getValidOperationLines = async (operations: IOrderModelBase[]): Promise<Map<string, string[]>> => {
    const plant_tags = getPlantTags(localStorage.getItem("last-plant") || "");
    const res = await getBackend().manufacturing.getOperationLineMatrix({
        operations: operations.map(operation => ({
            op_uuid: operation.uuid,
            order_external_id: operation.external_id,
            fixed_line_uuid: ORDER_TAGS_ACCESS.fix_line(operation.tags) || undefined
        })),
        use_production_versions: PLANT_TAGS_ACCESS.planning_table_production_versions(plant_tags),
        constraints: {
            fix_unplanned_ops: false,
            ignore_operation_fix_line_constraint: true,
            planned_ord_same_bom: true,
            planned_ord_same_op_structure: true,
            prod_ord_same_bom: true,
            prod_ord_same_op_structure: true,
            prod_ord_can_change_line: false
        }
    });
    const operation_lines: Map<string, string[]> = new Map();
    for (const op_lines of res.operation_lines) {
        operation_lines.set(
            op_lines.operation_uuid,
            op_lines.lines.map(line => line.line_uuid)
        );
    }

    return operation_lines;
};

const getLinkedReworkOperations = (
    base_operation: IOrderModelBase,
    rework_operations: IOrderModelBase[]
): IOrderModelBase[] => {
    const linked_rework_operations: IOrderModelBase[] = [];
    for (const rework_operation of rework_operations) {
        if (isPairLinked(rework_operation, base_operation)) {
            linked_rework_operations.push(rework_operation);
        }
    }

    return linked_rework_operations;
};

const materialsMatch = (material_uuid1: string, material_uuid2: string, materials: MaterialsMap): boolean => {
    const material1 = materials.get(material_uuid1);
    const material2 = materials.get(material_uuid2);
    if (material1 === undefined || material2 === undefined) {
        return false;
    }

    const external_parent_material_id1 = MATERIAL_TAGS_ACCESS.external_parent_material_id(material1.tags);
    const material_id1 =
        MATERIAL_TAGS_ACCESS.make_to_order(material1.tags) && external_parent_material_id1 !== ""
            ? external_parent_material_id1
            : material1.external_id;

    const external_parent_material_id2 = MATERIAL_TAGS_ACCESS.external_parent_material_id(material2.tags);
    const material_id2 =
        MATERIAL_TAGS_ACCESS.make_to_order(material2.tags) && external_parent_material_id2 !== ""
            ? external_parent_material_id2
            : material2.external_id;

    return material_id1 === material_id2;
};

const compareOperations = (a: IOrderModelBase, b: IOrderModelBase) => {
    if (a.external_id === b.external_id) {
        return a.process_num.localeCompare(b.process_num);
    }

    return a.external_id.localeCompare(b.external_id);
};

const getSelectedOrder = (
    rework_operations: OperationsMap,
    base_operations: OperationsMap,
    link_mode: LinkOrdersMode
): string | null => {
    const order_link_count: Map<string, number> = new Map();
    for (const rework_operation of rework_operations.values()) {
        const linked_operation = ORDER_TAGS_ACCESS.linked_operation(rework_operation.tags);
        if (linked_operation !== "") {
            const base_operation = base_operations.get(linked_operation);
            if (base_operation !== undefined) {
                const order_id =
                    link_mode === LINK_ORDERS_MODE.order
                        ? base_operation.external_id
                        : ORDER_TAGS_ACCESS.external_parent_order_id(base_operation.tags);
                let count = order_link_count.get(order_id) || 0;
                order_link_count.set(order_id, count + 1);
            }
        }
    }

    let max_count = 0;
    let selected_order_id = null;
    order_link_count.forEach((count, order_id) => {
        if (count > max_count) {
            max_count = count;
            selected_order_id = order_id;
        }
    });

    return selected_order_id;
};

export const OperationInfo = (props: { operation: IOrderModelBase | IOrderProducedModelEx }) => {
    const { operation } = props;
    let title = "";
    if (operation.status !== ORDER_STATUS.open) {
        title = translate("common.operation_is_closed", "Operation is closed");
    } else {
        // $FlowFixMe
        const line_title = operation.line ? operation.line.title : operation.line_title;
        title = `${translate(
            "common.operation_is_on_extra_line",
            "Operation is on related line from other workshop"
        )} (${line_title})`;
    }
    return <i className="fas fa-info-circle" title={title} />;
};

class LinkOrdersModal extends React.Component<Props, State> {
    links_checkbox_ref: HTMLInputElement | null = null;

    constructor(props: Props) {
        super(props);
        const state: State = {
            error: "",
            order_ids: [],
            selected_order: null,
            rework_operations: new Map(),
            base_operations: new Map(),
            operation_link_candidates: new Map(),
            operation_lines: new Map(),
            links: new Map(),
            materials: new Map(),
            is_loading: false
        };
        this.state = state;
    }

    async componentDidMount() {
        if (this.props.link_mode === null) {
            return;
        }

        await this.initOrders();
    }

    async componentDidUpdate(prev_props: Props) {
        if (this.props.link_mode === null) {
            return;
        }

        const { link_mode, rework_operation } = this.props;
        const prev_rework_operation = prev_props.rework_operation;
        if (
            (rework_operation && rework_operation.uuid) !== (prev_rework_operation && prev_rework_operation.uuid) ||
            (link_mode !== prev_props.link_mode && link_mode !== null)
        ) {
            await this.initOrders();
        }
    }

    loadMaterials = async (material_external_ids: string[]): Promise<MaterialsMap> => {
        const materials_map: MaterialsMap = new Map();
        if (material_external_ids.length === 0) {
            return materials_map;
        }

        try {
            const res = await getBackend().manufacturing.searchMaterialsSimple({
                external_ids: material_external_ids
            });

            const external_parent_material_ids = new Set<string>();
            for (const material of res.data) {
                materials_map.set(material.uuid, material);
                const external_parent_material_id = MATERIAL_TAGS_ACCESS.external_parent_material_id(material.tags);
                if (MATERIAL_TAGS_ACCESS.make_to_order(material.tags) && external_parent_material_id !== "") {
                    external_parent_material_ids.add(external_parent_material_id);
                }
            }

            if (external_parent_material_ids.size > 0) {
                const res2 = await getBackend().manufacturing.searchMaterialsSimple({
                    external_parent_material_ids: [...external_parent_material_ids]
                });
                for (const material of res2.data) {
                    materials_map.set(material.uuid, material);
                }
            }
        } catch (error) {
            this.setState({
                error: error.message
            });
        }

        return materials_map;
    };

    initOrders = async () => {
        const { link_mode, rework_operation } = this.props;
        if (link_mode === null || rework_operation === null) {
            return;
        }

        this.setState({ is_loading: true });
        try {
            const req: IGetOrdersSimpleReq = {
                status: ORDER_STATUS.open,
                order_types: [ORDER_TYPE.full, ORDER_TYPE.plan]
            };

            if (link_mode === LINK_ORDERS_MODE.order) {
                req.order_external_id = rework_operation.external_id;
            } else {
                const external_parent_order_id = ORDER_TAGS_ACCESS.external_parent_order_id(rework_operation.tags);
                req.tags_obj = {
                    [ORDER_TAGS.external_parent_order_id]: external_parent_order_id
                };
            }

            const rework_res = await getBackend().manufacturing.getOrdersSimple(req);
            const valid_rework_operations: IOrderModelBase[] = [];
            const material_external_ids: Set<string> = new Set();
            for (const operation of rework_res.operations) {
                if (!isValidReworkOperation(operation)) {
                    continue;
                }

                valid_rework_operations.push(operation);
                material_external_ids.add(operation.material_external_id);
            }

            valid_rework_operations.sort(compareOperations);

            const materials = await this.loadMaterials([...material_external_ids.values()]);
            const base_res = await getBackend().manufacturing.getOrdersSimple({
                material_uuids: [...materials.values()].map(m => m.uuid),
                status: ORDER_STATUS.open,
                order_types: [ORDER_TYPE.full, ORDER_TYPE.plan]
            });
            const operation_lines = await getValidOperationLines([...valid_rework_operations, ...base_res.operations]);

            base_res.operations.sort(compareOperations);

            const operation_link_candidates: Map<string, string[]> = new Map();
            const base_operations: OperationsMap = new Map();
            const order_ids: Set<string> = new Set();
            for (const base_operation of base_res.operations) {
                const base_line_uuids = this.getValidBaseLineUuids(
                    base_operation,
                    valid_rework_operations,
                    operation_lines
                );
                let is_valid_base_operation = false;
                for (const rework_operation of valid_rework_operations) {
                    const rework_line_uuids = operation_lines.get(rework_operation.uuid) || [];
                    if (
                        isValidBaseOperation(
                            base_operation,
                            rework_operation,
                            materials,
                            rework_line_uuids,
                            base_line_uuids,
                            link_mode
                        )
                    ) {
                        const base_uuids = operation_link_candidates.get(rework_operation.uuid) || [];
                        if (!base_uuids.includes(base_operation.uuid)) {
                            base_uuids.push(base_operation.uuid);
                        }

                        operation_link_candidates.set(rework_operation.uuid, base_uuids);
                        is_valid_base_operation = true;
                    }
                }

                if (!is_valid_base_operation) {
                    continue;
                }

                const order_id =
                    link_mode === LINK_ORDERS_MODE.order
                        ? base_operation.external_id
                        : ORDER_TAGS_ACCESS.external_parent_order_id(base_operation.tags);
                order_ids.add(order_id);
                base_operations.set(base_operation.uuid, base_operation);
            }

            const sim_operations = LinesOrdersMaps.all_orders_map;
            const extra_lines_operation_links_map = new Map(this.props.extra_lines_operation_links);
            const rework_operations: OperationsMap = new Map();
            for (const rework_operation of valid_rework_operations) {
                let base_operation_uuid = extra_lines_operation_links_map.get(rework_operation.uuid);
                if (base_operation_uuid === undefined) {
                    const sim_operation = sim_operations.get(rework_operation.uuid);
                    if (sim_operation !== undefined) {
                        base_operation_uuid = ORDER_TAGS_ACCESS.linked_operation(sim_operation.tags) || null;
                    }
                }

                if (base_operation_uuid !== undefined) {
                    if (base_operation_uuid !== null) {
                        rework_operation.tags[ORDER_TAGS.linked_operation] = base_operation_uuid;
                    } else {
                        delete rework_operation.tags[ORDER_TAGS.linked_operation];
                    }
                }

                rework_operations.set(rework_operation.uuid, rework_operation);
            }

            const selected_order = getSelectedOrder(rework_operations, base_operations, link_mode);
            this.setState(
                {
                    order_ids: [...order_ids.values()],
                    rework_operations,
                    base_operations,
                    selected_order,
                    operation_link_candidates,
                    operation_lines,
                    materials
                },
                () => {
                    const links = this.getOrderLinks(selected_order);
                    this.setState({
                        links
                    });
                }
            );
        } catch (error) {
            this.setState({
                error: error.message
            });
        }

        this.setState({ is_loading: false });
    };

    getScheduledLineUuid = (operation: IOrderModelBase): string | null => {
        for (const line of this.props.lines_orders) {
            for (const order of line.orders) {
                if (order.uuid === operation.uuid) {
                    return line.line_uuid;
                }
            }
        }

        return null;
    };

    getValidBaseLineUuids = (
        base_operation: IOrderModelBase,
        rework_operations: IOrderModelBase[],
        operation_lines: Map<string, string[]>
    ): string[] => {
        const valid_base_line_uuid = this.getScheduledLineUuid(base_operation);
        if (valid_base_line_uuid !== null) {
            return [valid_base_line_uuid];
        }

        let base_line_uuids = operation_lines.get(base_operation.uuid) || [];
        const linked_rework_operations = getLinkedReworkOperations(base_operation, rework_operations);
        for (const rework_operation of linked_rework_operations) {
            const rework_line_uuids = operation_lines.get(rework_operation.uuid) || [];
            base_line_uuids = arrayIntersection(base_line_uuids, rework_line_uuids);
        }

        return base_line_uuids;
    };

    getLinksCheckboxState = (): CheckboxState => {
        const { links } = this.state;
        let link_count = 0;
        links.forEach(({ uuid: base_operation_uuid, linked }, rework_operation_uuid) => {
            const rework_operation = this.state.rework_operations.get(rework_operation_uuid);
            const base_operation = this.state.base_operations.get(base_operation_uuid);
            if (rework_operation !== undefined && base_operation !== undefined && linked) {
                link_count += 1;
            }
        });

        if (link_count === 0) {
            return CHECKBOX_STATE.unchecked;
        }

        if (link_count > 0 && link_count < links.size) {
            return CHECKBOX_STATE.indeterminate;
        }

        return CHECKBOX_STATE.checked;
    };

    changeLinkState = (rework_operation_uuid: string, linked: boolean) => {
        const link_state = this.state.links.get(rework_operation_uuid);
        if (link_state === undefined) {
            return;
        }

        const links = new Map(this.state.links);
        links.set(rework_operation_uuid, {
            uuid: link_state.uuid,
            linked
        });
        this.setState({ links });
    };

    hasConfigurationChanged = (): boolean => {
        const { links, rework_operations } = this.state;
        if (this.props.link_mode !== null) {
            for (const [rework_operation_uuid, { uuid, linked }] of [...links.entries()]) {
                const rework_operation = rework_operations.get(rework_operation_uuid);
                if (rework_operation !== undefined) {
                    const was_linked = ORDER_TAGS_ACCESS.linked_operation(rework_operation.tags) === uuid;
                    if (was_linked !== linked) {
                        return true;
                    }
                }
            }
        }

        return false;
    };

    getRescheduleUuid = (): string => {
        const { rework_operation } = this.props;
        const { links, base_operations } = this.state;
        if (rework_operation !== null) {
            const link_state = links.get(rework_operation.uuid);
            if (link_state !== undefined && link_state.linked) {
                const base_operation = base_operations.get(link_state.uuid);
                if (
                    base_operation !== undefined &&
                    LinesOrdersMaps.all_orders_map.get(base_operation.uuid) !== undefined
                ) {
                    return base_operation.uuid;
                }
            }

            return rework_operation.uuid;
        }

        return "";
    };

    getValidBaseOperation = (rework_operation: IOrderModelBase, selected_order: string): IOrderModelBase | null => {
        const { link_mode } = this.props;
        if (link_mode === null) {
            return null;
        }

        const { base_operations, rework_operations, operation_link_candidates, materials, operation_lines } =
            this.state;
        const base_operation_uuids = operation_link_candidates.get(rework_operation.uuid);
        if (base_operation_uuids === undefined || base_operation_uuids.length === 0) {
            return null;
        }

        const rework_line_uuids = this.state.operation_lines.get(rework_operation.uuid) || [];
        for (const base_operation_uuid of base_operation_uuids) {
            const base_operation = base_operations.get(base_operation_uuid);
            if (base_operation === undefined) {
                continue;
            }

            const base_line_uuids = this.getValidBaseLineUuids(
                base_operation,
                [...rework_operations.values()],
                operation_lines
            );
            if (
                base_operation !== undefined &&
                ((link_mode === LINK_ORDERS_MODE.order && base_operation.external_id === selected_order) ||
                    (link_mode === LINK_ORDERS_MODE.parent_order &&
                        ORDER_TAGS_ACCESS.external_parent_order_id(base_operation.tags) === selected_order)) &&
                isValidBaseOperation(
                    base_operation,
                    rework_operation,
                    materials,
                    rework_line_uuids,
                    base_line_uuids,
                    link_mode
                )
            ) {
                return base_operation;
            }
        }

        return null;
    };

    getOrderLinks = (order_id: string | null): LinksMap => {
        const links: LinksMap = new Map();
        if (order_id === null) {
            return links;
        }

        const { rework_operations } = this.state;
        rework_operations.forEach(rework_operation => {
            if (order_id === null) {
                return;
            }

            const base_operation = this.getValidBaseOperation(rework_operation, order_id);
            if (base_operation !== null) {
                links.set(rework_operation.uuid, {
                    uuid: base_operation.uuid,
                    linked: isPairLinked(rework_operation, base_operation)
                });
            }
        });

        return links;
    };

    handleOrderChange = async (order: OrderOption | null) => {
        const link_mode = this.props.link_mode;
        if (link_mode === null) {
            return;
        }

        const selected_order = order && order.id;
        const links = this.getOrderLinks(selected_order);
        this.setState({
            selected_order,
            links
        });
    };

    handleClose = () => {
        this.setState({
            error: "",
            order_ids: [],
            selected_order: null,
            rework_operations: new Map(),
            base_operations: new Map(),
            operation_link_candidates: new Map(),
            links: new Map()
        });
        this.props.onClose();
    };

    handleLinkApply = (event: SyntheticEvent<HTMLFormElement>) => {
        event.preventDefault();

        const { link_mode } = this.props;
        const { links, rework_operations } = this.state;
        const operation_links: OperationLink[] = [];
        if (link_mode !== null) {
            links.forEach(({ uuid, linked }, rework_operation_uuid) => {
                const rework_operation = rework_operations.get(rework_operation_uuid);
                if (rework_operation === undefined) {
                    return;
                }

                const linked_operation = ORDER_TAGS_ACCESS.linked_operation(rework_operation.tags);
                if (linked && linked_operation !== uuid) {
                    operation_links.push([rework_operation_uuid, uuid]);
                } else if (!linked && linked_operation === uuid) {
                    operation_links.push([rework_operation_uuid, null]);
                }
            });
        }

        this.props.reduxDispatch({
            type: UPDATE_OPERATION_LINKS,
            data: {
                links: operation_links
            }
        });
        ReduxFilters.setRescheduleOrderUuid(
            this.props.reduxDispatch,
            this.getRescheduleUuid(),
            "line_order_list",
            this.props.is_filter_locked
        );
        this.handleClose();
    };

    handleLinkStateChange = (event: SyntheticEvent<HTMLInputElement | HTMLTableRowElement>) => {
        const target = event.currentTarget;
        let name = "";
        let value = "";
        let checked = false;
        if (target instanceof HTMLTableRowElement) {
            const checkbox = target.querySelector("input[type=checkbox]");
            if (checkbox !== null && checkbox instanceof HTMLInputElement) {
                name = checkbox.name;
                value = checkbox.value;
                checked = !checkbox.checked;
            }
        } else {
            name = target.name;
            value = target.value;
            checked = target.checked;
        }

        if (name === "links") {
            const links: LinksMap = new Map();
            this.state.links.forEach((link_state, rework_operation_uuid) => {
                links.set(rework_operation_uuid, {
                    ...link_state,
                    linked: checked
                });
            });
            this.setState({ links });
        }

        this.changeLinkState(value, checked);
    };

    renderModalTitle = () => {
        const { rework_operation, link_mode } = this.props;
        let title = "";
        let order_title;
        if (link_mode === LINK_ORDERS_MODE.order) {
            title = translate("common.link_order", "Link order");
            if (rework_operation !== null) {
                order_title = (
                    <span>
                        {" "}
                        - <span className="text-muted">{rework_operation.external_id}</span>
                    </span>
                );
            }
        } else if (link_mode === LINK_ORDERS_MODE.parent_order) {
            title = translate("common.link_parent_order", "Link parent order");
            if (rework_operation !== null) {
                const external_parent_order_id = ORDER_TAGS_ACCESS.external_parent_order_id(rework_operation.tags);
                order_title = (
                    <span>
                        {" "}
                        - <span className="text-muted">{external_parent_order_id}</span>
                    </span>
                );
            }
        }

        return (
            <Modal.Title>
                {title}
                {order_title}
            </Modal.Title>
        );
    };

    renderStatus = () => {
        const status = [];
        if (this.state.error !== "") {
            status.push(<ErrorComponent key="error" type="error" msg={this.state.error} />);
        }

        return status.length > 0 && <div className="modal-statusbar">{status}</div>;
    };

    renderLinkOrderTable = () => {
        const { link_mode } = this.props;
        const links_checkbox_state = this.getLinksCheckboxState();
        const is_indeterminate = links_checkbox_state === CHECKBOX_STATE.indeterminate;
        const is_checked = links_checkbox_state === CHECKBOX_STATE.checked;
        if (this.links_checkbox_ref !== null) {
            this.links_checkbox_ref.indeterminate = is_indeterminate;
        }

        let last_external_id = "";
        return (
            <table className="table mb-0">
                <thead>
                    <tr>
                        <th className="column-link">
                            <input
                                type="checkbox"
                                name="links"
                                ref={ref => {
                                    this.links_checkbox_ref = ref;
                                }}
                                checked={is_checked}
                                title={
                                    is_indeterminate || !is_checked
                                        ? translate("common.select_all", "Select all")
                                        : translate("common.unselect_all", "Unselect all")
                                }
                                onChange={this.handleLinkStateChange}
                            />
                        </th>
                        {link_mode === LINK_ORDERS_MODE.parent_order && (
                            <React.Fragment>
                                <th className="column-rework-order-id">
                                    {translate("common.trailing_order", "Trailing order")}
                                </th>
                                <th className="column-base-order-id">
                                    {translate("common.leading_order", "Leading order")}
                                </th>
                            </React.Fragment>
                        )}
                        <th className="column-process-num">{translate("common.process_num", "Process number")}</th>
                        <th className="column-name">{translate("common.process", "Process")}</th>
                    </tr>
                </thead>
                <tbody>
                    {[...this.state.rework_operations.entries()].map(([rework_operation_uuid, rework_operation], i) => {
                        const link = this.state.links.get(rework_operation_uuid);
                        const linked_operation = ORDER_TAGS_ACCESS.linked_operation(rework_operation.tags);
                        const base_operation = link !== undefined ? this.state.base_operations.get(link.uuid) : null;
                        const is_first_order_row = rework_operation.external_id !== last_external_id;
                        last_external_id = rework_operation.external_id;
                        return (
                            <tr
                                key={rework_operation.uuid}
                                className={classNames({
                                    "false-link": link
                                        ? linked_operation !== "" && linked_operation !== link.uuid
                                        : false,
                                    "disabled-link": link === undefined,
                                    "first-order-row": is_first_order_row
                                })}
                                onClick={this.handleLinkStateChange}
                            >
                                <td className="column-link">
                                    <input
                                        type="checkbox"
                                        name="link"
                                        value={rework_operation.uuid}
                                        checked={link !== undefined ? link.linked : linked_operation !== ""}
                                        onChange={this.handleLinkStateChange}
                                        disabled={link === undefined}
                                    />
                                </td>
                                {link_mode === LINK_ORDERS_MODE.parent_order && (
                                    <React.Fragment>
                                        <td className="column-rework-order-id">
                                            {rework_operation.external_id}{" "}
                                            {LinesOrdersMaps.all_orders_map.get(rework_operation.uuid) ===
                                                undefined && <OperationInfo operation={rework_operation} />}
                                        </td>
                                        <td className="column-base-order-id">
                                            {base_operation && base_operation.external_id}{" "}
                                            {base_operation &&
                                                LinesOrdersMaps.all_orders_map.get(base_operation.uuid) ===
                                                    undefined && <OperationInfo operation={base_operation} />}
                                        </td>
                                    </React.Fragment>
                                )}
                                <td className="column-process-num">
                                    {rework_operation.process_num}{" "}
                                    {link_mode === LINK_ORDERS_MODE.order &&
                                        LinesOrdersMaps.all_orders_map.get(rework_operation.uuid) === undefined && (
                                            <OperationInfo operation={rework_operation} />
                                        )}
                                </td>
                                <td className="column-name">{rework_operation.title}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    };

    renderForm = () => {
        const options: OrderOption[] = this.state.order_ids.map(id => ({ id }));
        const value: OrderOption | null =
            this.state.selected_order !== null
                ? {
                      id: this.state.selected_order
                  }
                : null;
        return (
            <form id="link-orders-form" onSubmit={this.handleLinkApply}>
                <div className="modal-toolbar">
                    <div className="form-group w-100 mb-0">
                        <label htmlFor="order-select">
                            {this.props.link_mode === LINK_ORDERS_MODE.parent_order
                                ? translate("common.leading_parent_order", "Leading parent order")
                                : translate("common.leading_order", "Leading order")}
                        </label>
                        <Select
                            inputId="order-select"
                            name="order"
                            value={value}
                            placeholder={`${translate("common.select", "Select")}...`}
                            noOptionsMessage={() => translate("common.no_data", "No data available")}
                            options={options}
                            getOptionLabel={getOrderOptionValue}
                            getOptionValue={getOrderOptionValue}
                            onChange={this.handleOrderChange}
                            isClearable
                            autoFocus
                        />
                    </div>
                </div>
                {this.state.selected_order !== null && this.renderLinkOrderTable()}
            </form>
        );
    };

    render() {
        const { link_mode } = this.props;
        const link_mode_class = link_mode !== null ? `link-orders-modal--${link_mode.replace(/\_/g, "-")}` : "";
        return (
            <DraggableModal>
                <Modal
                    dialogClassName={classNames("link-orders-modal", link_mode_class)}
                    show={link_mode !== null}
                    animation={false}
                >
                    <Modal.Header>
                        {this.renderModalTitle()}
                        <button type="button" className="close" onClick={this.handleClose}>
                            <span aria-hidden="true">×</span>
                            <span className="sr-only">{translate("common.close", "Close")}</span>
                        </button>
                    </Modal.Header>
                    {this.renderStatus()}
                    <Modal.Body>
                        {link_mode !== null && (this.state.is_loading ? <Loader small /> : this.renderForm())}
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-outline-secondary" onClick={this.handleClose}>
                            {translate("common.cancel", "Cancel")}
                        </button>
                        <button
                            className="btn btn-primary"
                            disabled={!this.hasConfigurationChanged()}
                            type="submit"
                            form="link-orders-form"
                        >
                            {translate("common.ok", "OK")}
                        </button>
                    </Modal.Footer>
                </Modal>
            </DraggableModal>
        );
    }
}

export default connect(
    (state: ReduxState, own_props: Props) => {
        return {
            lines_orders: state.gantt_chart_lines_orders.lines_orders,
            unscheduled_orders: state.gantt_chart_lines_orders.unscheduled_orders,
            ignored_orders: state.gantt_chart_lines_orders.ignored_orders,
            extra_lines_operation_links: state.gantt_chart_lines_orders.extra_lines_operation_links,
            is_filter_locked: state.gantt_chart_filters.is_filter_locked
        };
    },
    dispatch => ({ reduxDispatch: dispatch })
)(LinkOrdersModal);
