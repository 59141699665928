// @flow

import * as React from "react";
import type { Node } from "react";
import { createPortal } from "react-dom";
import { getScrollTop, getScrollLeft, getScrollParent } from "../lib/Util";


export type PopupPosition = "absolute" | "fixed";
export type PopupAlignment = "start" | "end" | "center" | "justify";
export type PopupPlacement = "bottom" | "top";

type Props = {
    alignment: PopupAlignment,
    // autoScroll: boolean,
    children?: Node,
    isOpen: boolean,
    maxHeight: number,
    maxWidth: number,
    // minHeight: number,
    // minWidth: number,
    placement: PopupPlacement,
    portalTarget?: HTMLElement,
    position: PopupPosition,
    target: HTMLElement
};


class Popup extends React.Component<Props> {
    static defaultProps: Props = {
        alignment: "start",
        // autoScroll: true,
        isOpen: false,
        maxHeight: 500,
        maxWidth: 640,
        // minHeight: 250,
        // minWidth: 320,
        placement: "bottom",
        position: "absolute",
        target: document.createElement("div"),
    };
    popupEl: HTMLElement;
    popupContentEl: HTMLElement;
    portalTargetEl: Element;

    constructor(props: Props) {
        super(props);
        this.popupEl = document.createElement("div");
        this.popupEl.className = "popup";
        this.popupEl.style.position = this.props.position;
        this.portalTargetEl = this.props.portalTarget || this.props.target.parentElement || this.props.target;
        
    }

    componentDidMount() {
        this.portalTargetEl.appendChild(this.popupEl);
        this.setGeometry();
    }

    componentWillUnmount() {
        this.portalTargetEl.removeChild(this.popupEl);
    }

    componentDidUpdate() {
        this.setGeometry();
    }

    setGeometry() {
        const {
            alignment,
            maxWidth,
            maxHeight,
            placement,
            position,
            target
        } = this.props;

        const popup = this.popupContentEl;
        const popupContainer = this.popupEl;
        const documentEl = document.documentElement;
        let geometry = { 
            left: 0,
            top: 0,
            maxWidth,
            maxHeight 
        };

        if (!popup || !popupContainer || !target || !documentEl) {
            return;
        }

        const isPositionFixed = position === "fixed";
        const scrollParent = getScrollParent(popupContainer);
        const offsetParent = popupContainer.offsetParent || documentEl;
        
        // Reset styles
        popup.style.width = "auto";
        popup.style.maxHeight = maxHeight ? `${maxHeight}px` : "none";
        popup.style.maxWidth = maxWidth ? `${maxWidth}px` : "none";

        // Get dimensions
        let {
            width: popupWidth,
            height: popupHeight,
        } = popup.getBoundingClientRect();

        const {
            top: targetTop,
            right: targetRight,
            bottom: targetBottom,
            left: targetLeft,
            width: targetWidth,
        } = target.getBoundingClientRect();

        const { 
            top: scrollAreaTop,
            right: scrollAreaRight,
            bottom: scrollAreaBottom,
            left: scrollAreaLeft,
        } = scrollParent.getBoundingClientRect();

        const {
            top: containerTop,
            left: containerLeft
        } = offsetParent.getBoundingClientRect();

        const viewWidth = window.innerWidth;
        const viewHeight = window.innerHeight;
        const scrollTop = getScrollTop(scrollParent);
        const scrollLeft = getScrollLeft(scrollParent);

        const marginTop = parseInt(getComputedStyle(popup).marginTop, 10);
        const marginRight = parseInt(getComputedStyle(popup).marginRight, 10);
        const marginBottom = parseInt(getComputedStyle(popup).marginBottom, 10);
        const marginLeft = parseInt(getComputedStyle(popup).marginLeft, 10);
        const horizontalMargin = marginLeft + marginRight;
        const verticalMargin = marginTop + marginBottom;
        
        let viewSpaceLeft;
        let viewSpaceRight;
        let viewSpaceCenter;
        let viewSpaceAbove;
        let viewSpaceBelow;

        if (isPositionFixed) {
            viewSpaceLeft = targetLeft + targetWidth - horizontalMargin;
            viewSpaceRight = viewWidth - targetRight + targetWidth - horizontalMargin;
            viewSpaceAbove = targetTop - verticalMargin;
            viewSpaceBelow = viewHeight - targetBottom - verticalMargin;
        } else {
            viewSpaceLeft = targetLeft + targetWidth - horizontalMargin - Math.max(0, scrollAreaLeft);
            viewSpaceRight = Math.min(viewWidth, scrollAreaRight) - targetRight + targetWidth - horizontalMargin
            viewSpaceAbove = targetTop - verticalMargin - Math.max(0, scrollAreaTop);
            viewSpaceBelow = Math.min(viewHeight, scrollAreaBottom) - targetBottom - verticalMargin;
        }

        viewSpaceCenter = 2 * Math.min(viewSpaceLeft, viewSpaceRight) - targetWidth;

        // Calculate placement
        switch (placement) {
            case "bottom":
                // 1: If there is enough space available below, place below.
                if (viewSpaceBelow >= popupHeight) {
                    geometry.top = targetBottom;

                    break;
                }

                // 2: If there is more space available above, place above (and constrain height).
                // Absolutely positioned popup must have enough space above!
                if (
                    (viewSpaceAbove > viewSpaceBelow) && 
                    (isPositionFixed || viewSpaceAbove + scrollTop >= popupHeight)
                ) {
                    if (isPositionFixed) {
                        geometry.maxHeight = Math.min(maxHeight, viewSpaceAbove);
                    }

                    geometry.top = targetTop - verticalMargin - Math.min(popupHeight, geometry.maxHeight);

                    break;
                }

                // 3: Else place below and constrain height.
                geometry.top = targetBottom;

                if (isPositionFixed) {
                    geometry.maxHeight = Math.min(maxHeight, viewSpaceBelow);
                }

                break;
            case "top":
                // 1: If there is enough space available above, place above.
                if (viewSpaceAbove >= popupHeight) {
                    geometry.top = targetTop - popupHeight - verticalMargin;

                    break;
                }

                // 2: If there is more space available below, place below (and constrain height).
                if (viewSpaceBelow > viewSpaceAbove) {
                    if (isPositionFixed) {
                        geometry.maxHeight = Math.min(maxHeight, viewSpaceBelow);
                    }

                    geometry.top = targetBottom;

                    break;
                }

                // 3: Else place above (and constrain height).
                if (isPositionFixed) {
                    geometry.maxHeight = Math.min(maxHeight, viewSpaceAbove);
                }

                geometry.top = targetTop - verticalMargin - Math.min(popupHeight, geometry.maxHeight);

                break;
            default:
                throw new Error(`Invalid placement provided "${placement}".`);
        }

        if (!isPositionFixed) {
            geometry.top -= containerTop;
        }

        // Set placement
        popupContainer.style.top = `${geometry.top}px`;
        popup.style.maxHeight = geometry.maxHeight ? `${geometry.maxHeight}px` : "none";

        let {
            width: popupWidthNew,
            height: popupHeightNew,
        } = popup.getBoundingClientRect();

        popupWidth = popupWidthNew;
        popupHeight = popupHeightNew;

        // Calculate alignment
        switch (alignment) {
            case "justify":
                // Align popup with target.
                geometry.left = targetLeft;
                geometry.maxWidth = targetWidth;

                break;
            case "start":
                // 1: If there is enough space available on the reight, align to the left.
                if (viewSpaceRight >= popupWidth) {
                    geometry.left = targetLeft;

                    break;
                }

                // 2: If there is more space available in the middle, center horizontally.
                if (
                    (viewSpaceCenter > viewSpaceRight) && 
                    (viewSpaceCenter > viewSpaceLeft) && 
                    (viewSpaceCenter >= popupWidth || isPositionFixed)
                ) {
                    if (isPositionFixed) {
                        geometry.maxWidth = Math.min(maxWidth, viewSpaceCenter);
                    }

                    geometry.left = targetLeft + Math.round((targetWidth - Math.min(popupWidth, geometry.maxWidth)) / 2) - marginLeft;

                    break;
                }

                // 3: If there is more space available on the left, align to the right.
                if (
                    (viewSpaceLeft > viewSpaceRight) && 
                    (isPositionFixed || viewSpaceLeft + scrollLeft >= popupWidth)
                ) {
                    if (isPositionFixed) {
                        geometry.maxWidth = Math.min(maxWidth, viewSpaceLeft);
                    }

                    geometry.left = targetRight - horizontalMargin - Math.min(popupWidth, geometry.maxWidth);

                    break;
                }

                // 4: Else align to the left (and constrain width).
                geometry.left = targetLeft;

                if (isPositionFixed) {
                    geometry.maxWidth = Math.min(maxWidth, viewSpaceRight);
                }

                break;
            case "end":
                // 1: If there is enough space available on the left, align to the right.
                if (viewSpaceLeft >= popupWidth) {
                    geometry.left = targetRight - horizontalMargin - popupWidth;

                    break;
                }

                // 2: If there is more space available in the middle, center horizontally.
                if (
                    (viewSpaceCenter > viewSpaceRight) && 
                    (viewSpaceCenter > viewSpaceLeft) && 
                    (viewSpaceCenter >= popupWidth || isPositionFixed)
                ) {
                    if (isPositionFixed) {
                        geometry.maxWidth = Math.min(maxWidth, viewSpaceCenter);
                    }

                    geometry.left = targetLeft + Math.round((targetWidth - Math.min(popupWidth, geometry.maxWidth)) / 2) - marginLeft;

                    break;
                }

                // 3: If there is more space available on the right, align to the left.
                if (
                    (viewSpaceRight > viewSpaceLeft) || 
                    (!isPositionFixed && viewSpaceRight < popupWidth)
                ) {
                    if (isPositionFixed) {
                        geometry.maxWidth = Math.min(maxWidth, viewSpaceRight);
                    }

                    geometry.left = targetLeft;

                    break;
                }

                // 4: Else align to the right (and constrain width).
                if (isPositionFixed) {
                    geometry.maxWidth = Math.min(maxWidth, viewSpaceRight);
                }

                geometry.left = targetRight - horizontalMargin - Math.min(popupWidth, geometry.maxWidth);

                break;
            case "center":
                // 1: If there is enough space available in the middle, center horizontally.
                if (viewSpaceCenter >= popupWidth) {
                    geometry.left = targetLeft + Math.round((targetWidth - popupWidth) / 2) - marginLeft;

                    break;
                }

                // 2: If there is more space available on the left, align to the right.
                if (
                    (viewSpaceLeft > viewSpaceCenter) && 
                    (isPositionFixed || viewSpaceLeft + scrollLeft >= popupWidth)
                ) {
                    if (isPositionFixed) {
                        geometry.maxWidth = Math.min(maxWidth, viewSpaceLeft);
                    }

                    geometry.left = targetRight - horizontalMargin - Math.min(popupWidth, geometry.maxWidth);

                    break;
                }

                // 2: If there is more space available on the right, align to the left.
                if (
                    ((viewSpaceRight > viewSpaceCenter) && 
                    (viewSpaceRight > viewSpaceLeft)) ||
                    (!isPositionFixed && viewSpaceCenter < popupWidth)
                ) {
                    if (isPositionFixed) {
                        geometry.maxWidth = Math.min(maxWidth, viewSpaceRight);
                    }

                    geometry.left = targetLeft;

                    break;
                }

                // 4: Else center horizontally (and constrain width).
                if (isPositionFixed) {
                    geometry.maxWidth = Math.min(maxWidth, viewSpaceCenter);
                }

                geometry.left = targetLeft + Math.round((targetWidth - Math.min(popupWidth, geometry.maxWidth)) / 2) - marginLeft;

                break;
            default:
                throw new Error(`Invalid alignment provided "${alignment}".`);
        }

        if (!isPositionFixed) {
            geometry.left -= containerLeft;
        }

        // Set alignment
        popupContainer.style.left = `${geometry.left}px`;
        popup.style.maxWidth = geometry.maxWidth ? `${geometry.maxWidth}px` : "none";

        if (alignment === "justify") {
            popup.style.width = popup.style.maxWidth;
        }
    }

    render() {
        return this.props.isOpen ? createPortal(
            <div className="popup-content" ref={el => el && (this.popupContentEl = el)}>
                {this.props.children}
            </div>,
            this.popupEl
        ) : null;
    }
}

export default Popup;
