// @flow

import React from "react";
import {
    rowStyle,
    mapData,
    Columns,
    getInitialState
} from "../react/ReactTableTags";
import ReactTable from "../react/ReactTable";
import { LINE_TAGS } from "../../lib/ManufacturingTagsEditor.generated"

import { FormattedMessage } from "react-intl";

import { getLinkForUserDocumentation, UserDocumentationLinksEnum } from "../../lib/Util";
import { getLang } from "../IntlProviderWrapper";

type Props = {
    tags: Object,
    onChangeCell: Function
}

const LineEditTags = (props: Props) => {
    // TODO - add support for source
    const line_tags = { ...LINE_TAGS };
    line_tags.values = line_tags.values
        .filter(el => el.name !== "type");
    //.filter(el => (!el.source && el.name !== "subtype" && el.type !== "enum"));

    return <div>
        <a href={getLinkForUserDocumentation(UserDocumentationLinksEnum.lines, getLang())}
            target="_blank"
            style={{ position: 'relative', float: 'right', paddingTop: '30px' }}>
            <img src={"/img/question_light.svg"} style={{ paddingRight: "3px", paddingBottom: "2px" }} />
            <FormattedMessage id="Header.menu.user_documentation" defaultMessage="User documentation" />
            <img src={"/img/arrow_up_right_corner.svg"} style={{ position: "relative", width: "15px", height: "15px", paddingLeft: "3px", paddingBottom: "4px", bottom: "3px" }} />
        </a>
        <ReactTable
            className="tags-react-table"
            data={mapData(props.tags, line_tags)}
            columns={Columns}
            onChangeCell={props.onChangeCell}
            rowStyle={rowStyle}
            initialState={getInitialState()}
        />
    </div>
}

export default LineEditTags;
