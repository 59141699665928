// @flow
import React from "react";
import { connect } from "react-redux";
import { Overlay, Tooltip } from "react-bootstrap";
import { translate } from "../../IntlProviderWrapper";
import {
    GanttChartSource,
    getPlanningTableDivWidth,
    zoomGanttChart
} from "./reducers/properties";
import { PropertiesLogic } from "./reducers/BusinessLogic";

import type { ReduxState } from "./reducers/reducers";
import type { UpdatePlanningTableWidthAction } from "./reducers/properties";
import type { GanttChartSourceTypes, PropertiesState } from "./reducers/properties";
import CurrentDowntimes from "./CurrentDowntimes";

type ToolbarWidthProps = {
    planning_table_width: number,
    show_tooltip: boolean,
    reduxDispatch: (args: UpdatePlanningTableWidthAction) => void,
    closeTooltip: () => void,
    planning_table_days: number,
    source_type: GanttChartSourceTypes,
    line_group_uuid: string,
    properties: PropertiesState
};
type ToolbarWidthState = {
    min_width: number,
    max_width: number
};

const MIN_POSITION = 0;
const MAX_POSITION = 100;
const SLIDER_STEP = 5;

class ToolbarWidth extends React.Component<ToolbarWidthProps, ToolbarWidthState> {

    constraintDays = (days: number): number => {
        const min = this.getMinValue();
        const max = this.getMaxValue();
        return Math.min(Math.max(days, min), max);
    }

    getMinDayWidth() {
        return this.props.properties.min_day_width;
    }

    calculateLog = (position: number) => {
        // position will be between 0 and 100
        const min_position = MIN_POSITION;
        const max_position = MAX_POSITION;
    
        // The result should be between 100 an 10000000
        const min_value = Math.log(this.getMinValue());
        const max_value = Math.log(this.getMaxValue());
    
        // calculate adjustment factor
        const scale = (max_value - min_value) / (max_position - min_position);
        return Math.exp(min_value + scale * (position - min_position));
    }

    onChangeSlider = (event: Event) => {
        if (event.currentTarget instanceof HTMLInputElement) {
            const value = this.calculateLog(parseFloat(event.currentTarget.value));
            this.updatePlanningTableWidth(value);
        }
    }

    updatePlanningTableWidth = (days: number) => {
        const real_days = this.constraintDays(days);
        zoomGanttChart(real_days, this.props.source_type, this.props.reduxDispatch);
    }

    onClickMonth = () => {
        const min_value = this.getMinValue();
        this.updatePlanningTableWidth(min_value);
    }

    onClickWeek = () => {
        const mid_value = this.getMidValue();
        this.updatePlanningTableWidth(mid_value);
    }

    onClickDay = () => {
        const mid2_value = this.getMid2Value();
        this.updatePlanningTableWidth(mid2_value);
    }

    onClickShift = () => {
        const max_value = this.getMaxValue();
        this.updatePlanningTableWidth(max_value);
    }

    fitNumDaysVisibleDiv = (fit_num_days: number) => {
        const planning_table_width = getPlanningTableDivWidth() - this.props.properties.space_for_label;
        // how much should be the width of the day so that we can pack 7 days inside the visible div width
        return Math.max(this.getMinDayWidth(), planning_table_width / fit_num_days);
    }

    getMinValue = (): number => {
        // month
        return this.getMinDayWidth();
    }

    getMidValue = () => {
        // week
        return this.fitNumDaysVisibleDiv(7);
    }

    getMid2Value = () => {
        // day
        return this.fitNumDaysVisibleDiv(1);
    }

    getMaxValue = () => {
        // shift
        return this.fitNumDaysVisibleDiv(1/3);
    }


    getSliderValue = () => {
        const value = this.props.properties.width / this.props.properties.days

        // position will be between 0 and 100
        const min_position = MIN_POSITION;
        const max_position = MAX_POSITION;
    
        // The result should be between 100 an 10000000
        const min_value = Math.log(this.getMinValue());
        const max_value = Math.log(this.getMaxValue());
    
        // calculate adjustment factor
        const scale = (max_value - min_value) / (max_position - min_position);
        return min_position + ((Math.log(value) - min_value) / scale);
    }

    getSliderZoomStep = () => {
        return SLIDER_STEP;
    }

    onClickMinus = () => {
        const slider_value = this.getSliderValue();
        const value = Math.max(this.calculateLog(slider_value - SLIDER_STEP), this.getMinValue());
        this.updatePlanningTableWidth(value);
    }

    onClickPlus = () => {
        const slider_value = this.getSliderValue();
        const value = Math.min(this.calculateLog(slider_value + SLIDER_STEP), this.getMaxValue());
        this.updatePlanningTableWidth(value);
    }

    onClose = () => {
        this.props.closeTooltip();
    }

    onReset = () => {
        this.updatePlanningTableWidth(this.getMinValue());
        this.props.closeTooltip();
    }

    render() {
        return <div id="toolbar">
            <div className="close">
                <i onClick={() => this.props.closeTooltip()} className="fas fa-times"></i>
            </div>
            <div className="ctrl">
                <button className="btn btn-light" onClick={() => this.onClickMonth()}>{translate("Calendar.month", "Month")}</button>
            </div>
            <div className="ctrl">
                <button className="btn btn-light" onClick={() => this.onClickWeek()}>{translate("common.week", "Week")}</button>
            </div>
            <div className="ctrl">
                <button className="btn btn-light" onClick={() => this.onClickDay()}>{translate("common.day", "Day")}</button>
            </div>
            <div className="ctrl">
                <button className="btn btn-light" onClick={() => this.onClickShift()}>{translate("common.shift", "Shift")}</button>
            </div>
            <div className="ctrl" style={{ display: "flex" }}>
                <i id="circle-btn" onClick={this.onClickMinus} className="fas fa-minus-circle fa-2x"></i>
                <input
                    type="range"
                    min={SLIDER_STEP}
                    max={MAX_POSITION}
                    value={this.getSliderValue()}
                    step={this.getSliderZoomStep()}
                    onChange={this.onChangeSlider}
                ></input>
                <i id="circle-btn" onClick={this.onClickPlus} className="fas fa-plus-circle fa-2x"></i>
            </div>
            <hr/>
            <div className="save-buttons" style={{marginBottom: "5px"}}>
                <button className="btn btn-primary" style={{marginRight: "15px"}} onClick={this.onReset}> {translate("common.reset", "Reset")} </button>
                <button className="btn btn-primary" onClick={this.onClose}> {translate("common.close", "Close")} </button>
            </div>
        </div>
    }
}

const ConnectedToolbarWidth = connect(
    (state: ReduxState) => {
        const properties = state.gantt_chart_properties;
        return {
            planning_table_width: properties.width,
            planning_table_days: properties.days,
            properties
        }
    },
    (dispatch) => ({reduxDispatch: dispatch})
)(ToolbarWidth);

type Props = {
    width: number,
    style: Object,
    source_type: GanttChartSourceTypes
};
type State = {
    show_tooltip: boolean
};


class Toolbar extends React.Component<Props, State> {

    state = {
        show_tooltip: false
    }

    zoom_button_ref = null;

    constructor(props: Props) {
        super(props);
        this.zoom_button_ref = React.createRef();
    }

    closeTooltip = (e: Event) => {
        if (e && e.srcElement.id === "toolbar-slider") return;

        if (this.state.show_tooltip) {
            this.setState({ show_tooltip: false });
        }
    }

    showTooltip = (e: Event) => {
        this.setState({ show_tooltip: true });
        if (e && e.preventDefault) {
            e.preventDefault();
            e.stopPropagation();
        }
    }

    getDefaultStyle = () => {
        return {
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            zIndex: 2,
            width: "50px"
        };
    }

    render() {
        let default_style = this.getDefaultStyle();
        if (this.props.source_type === GanttChartSource.gantt || this.props.source_type === GanttChartSource.tool_changes) {
            default_style.width = "unset";
        }

        const is_planning_table = PropertiesLogic.isPlanningTable(this.props.source_type);

        return <div style={this.props.style || default_style}>
            {this.zoom_button_ref && <Overlay target={this.zoom_button_ref.current} show={this.state.show_tooltip} rootClose onHide={this.closeTooltip} placement="right">
                <Tooltip id="planning-table-width">
                    <ConnectedToolbarWidth
                        show_tooltip={this.state.show_tooltip}
                        closeTooltip={this.closeTooltip}
                        source_type={is_planning_table ? GanttChartSource.planning_table_parallel : this.props.source_type}
                    />
                </Tooltip>
            </Overlay>}
            <div
                id="toolbar-button-container"
                style={{
                    padding: is_planning_table ? "0 10px" : 0,
                    backgroundColor: !is_planning_table ? "white" : ""
                }}
            >
                <button
                    className="btn btn-link"
                    ref={this.zoom_button_ref}
                    style={{ textDecoration: "none", minWidth: 0, padding: 0, border: 0 }}
                    onClick={this.showTooltip}
                    title={translate("common.tooltip_planningtable_zoom_view", "Zoom view")}
                >
                    <i style={{ color: "#99A5B0" }} id="toolbar-icon" className="fas fa-sliders-h d-block"></i>
                </button>
                <CurrentDowntimes />
            </div>
        </div>
    }
}

export default connect(
    (state: ReduxState) => {
        const planning_table_width = state.gantt_chart_properties.width;
        const source_type = state.gantt_chart_properties.source_type;
        return { width: planning_table_width, source_type };
    },
    (dispatch) => ({reduxDispatch: dispatch})
)(Toolbar);
