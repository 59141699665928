// @flow
import React from "react";
import { connect } from "react-redux";

import { HAS_INSIGHTS_FILTER } from "./reducers/reducersFilters/stockRequirementsFilters";
import { translate  } from "../../IntlProviderWrapper";
import MaterialFilter from "./lineOrderList/filters/MaterialFilter";
import { ConnectedLockLine } from "./lineOrderList/LineFilter";

import type {
    SetStockReqFilterMaterials,
    StockRequirementsFiltersState,
    SetHasInsightsFilter,
    ResetFiltersAction
} from "./reducers/reducersFilters/stockRequirementsFilters";
import type { StockRequirement } from "./reducers/stockRequirements";
import type { SetHighlightedOrders } from "./reducers/planningTable";
import type { SetSelectedLineUuidsAction } from "./reducers/reducersFilters/filters";
import type { ReportLoadingAction } from "./reducers/report";
import type { ReduxState, } from "./reducers/reducers";
import type { UpdateStockRequirementsHeightAction } from "./reducers/properties";
import type { LineOrders } from "./reducers/linesOrders";

type Props = {
    reduxDispatch: (
        args: (
            SetStockReqFilterMaterials |
            UpdateStockRequirementsHeightAction |
            SetHasInsightsFilter |
            ResetFiltersAction |
            ReportLoadingAction |
            SetHighlightedOrders |
            SetSelectedLineUuidsAction
        )
    ) => void,
    stock_requirements_filters: StockRequirementsFiltersState,
    stock_requirements: StockRequirement[],
    filtered_materials: StockRequirement[],
    has_insights_filter: boolean,
    lines_orders: LineOrders[],
    selected_filter_type: string,
    selected_stock_requirement: string,
    is_filter_locked: boolean
};
type State = {};

class StockRequirementsFilters extends React.Component<Props, State> {

    getMaterialExternalIds = (): string[] => {
        return [...new Set(...[this.props.stock_requirements.map(sr => sr.material_external_id)])];
    }

    onHasInsights = () => {
        this.props.reduxDispatch({ type: HAS_INSIGHTS_FILTER, data: !this.props.has_insights_filter });
    }

    isFilterDisabled = () => {
        return !!(
            this.props.is_filter_locked ||
            this.props.selected_stock_requirement
        );
    }

    render() {
        return (
            <div className="planning-table-filters p-3">
                <div className="form-row">
                    <div className="form-group col-12 mb-2">
                        <div className="stock-requirements-filters">
                            <MaterialFilter
                                lines_orders={this.props.lines_orders}
                                filter_material_external_ids={this.getMaterialExternalIds()}
                                is_disabled={this.isFilterDisabled()}
                            />
                            {!this.isFilterDisabled() && <ConnectedLockLine />}
                        </div>
                    </div>
                </div>
                <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                    <input
                        type="checkbox"
                        checked={this.props.has_insights_filter}
                        onChange={this.onHasInsights}
                        disabled={this.isFilterDisabled()}
                    />{" "}
                    <label
                        className="mb-0"
                        onClick={this.onHasInsights}
                        style={{ marginLeft: "5px", cursor: "pointer"}}
                    >{translate("common.has_insights", "Has insights")}</label>
                </div>
            </div>
        )
    }
}

export default connect(
    (state: ReduxState) => {
        const has_insights_filter = state.gantt_chart_stock_requirements_filter.has_insights_filter
        const stock_requirements = state.gantt_chart_stock_requirements.stock_requirements;
        const lines_orders = state.gantt_chart_lines_orders.lines_orders;
        const filtered_materials = state.gantt_chart_filters.selected_materials;
        const selected_filter_type = state.gantt_chart_filters.selected_filter_type;
        const selected_stock_requirement = state.gantt_chart_stock_requirements_filter.selected_stock_requirement;
        const is_filter_locked = state.gantt_chart_filters.is_filter_locked;

        return {
            has_insights_filter,
            filtered_materials,
            stock_requirements,
            lines_orders,
            selected_filter_type,
            selected_stock_requirement,
            is_filter_locked
        }
    },
    (dispatch) => ({reduxDispatch: dispatch})
)(StockRequirementsFilters)
