// @flow

import React from "react";
import { connect } from "react-redux";
import { getBackend as getBackend2 } from "../../../lib/backend/Backend2";
import { translate } from "../../IntlProviderWrapper";
import * as t from "../../../lib/backend/manufacturing2.generated.types";
import SaveToSap, { getChangedShifts } from "./SaveToSap/SaveToSap";
import SaveToTxtButton from "./SaveToTxtButton";
import LogsButton from "./LogsButton";
import PlanningTableSaveSpinner from "./PlanningTableSaveSpinner";
import { SelectedOrdersProvider } from "./SaveToSap/SelectedOrdersContext";
import { PLAN_IMPORT_TYPE } from "../../../lib/ManufacturingConsts.generated";
import ImportPlanOndemand from "./ImportPlanOndemand";
import * as BusinessLogic from "../../../lib/BusinessLogic";

import type { LineOrders } from "./reducers/linesOrders";
import type { ReduxState } from "./reducers/reducers";

type SaveShiftsButtonProps = {
    changed_shifts: t.IShiftLineRec[],
    loading: boolean,
    disabled: boolean,
    saveShifts: () => Promise<void>
};

const SaveShiftsButton = (props: SaveShiftsButtonProps) => {
    return (
        <button
            className="dropdown-item"
            onClick={props.saveShifts}
            disabled={props.changed_shifts.length === 0 || props.disabled}
        >
            {props.loading ? <i className="fas fa-spinner fa-spin"></i> : translate("common.save_only_shifts", "Save only shifts")}
        </button>
    )
}

type ImportPlanButtonProps = {
    import_type: string,
    disabled: boolean,
    showImportPlanOnDemand: (import_type: string) => void
};

const ImportPlanButton = (props: ImportPlanButtonProps) => {
    return (
        <button
            className="dropdown-item"
            disabled={props.disabled}
            onClick={() => { props.showImportPlanOnDemand(props.import_type) }}
        >
            {props.import_type === PLAN_IMPORT_TYPE.lists
                ? translate("common.import_orders", "Refresh from ERP order in shopfloor")
                : translate("common.import_orders_plant", "Refresh from ERP order in plant")}
        </button>
    );
};

type Props = {
    plant_uuid: string,
    line_group_uuid: string,
    lines_orders: LineOrders[],
    shifts: t.IShiftLineRec[]
};

type State = {
    changed_shifts: t.IShiftLineRec[],
    loading: boolean,
    show_css: string,
    plan_import_type: string
}

class SaveButtonsContainer extends React.Component<Props, State> {

    state = {
        changed_shifts: [],
        loading: false,
        show_css: "",
        plan_import_type: ""
    }

    componentDidUpdate = (prev_props: Props) => {
        const changed_shifts = getChangedShifts(
            prev_props.lines_orders,
            this.props.lines_orders,
            this.state.changed_shifts
        );

        if (this.state.changed_shifts !== changed_shifts) {
            this.setState({ changed_shifts });
        }
    }

    saveShifts = async () => {
        this.setSuccessClassNameOnSave("");
        const save_shifts: Array<t.IShiftUpdateRec> = [];
        if (this.state.changed_shifts.length > 0) {
            this.setState({ loading: true });
            for (const shift of this.state.changed_shifts) {
                save_shifts.push({
                    enabled: shift.enabled,
                    line_uuid: shift.line_uuid,
                    shift_uuid: shift.shift_uuid
                });
            }

            try {
                await getBackend2().manufacturing.updateShifts({ shifts: save_shifts });
                this.setSuccessClassNameOnSave("success");
                setTimeout(() => this.setSuccessClassNameOnSave(""), 2000);
                this.setState({ changed_shifts: [] });
            } catch (e) {
                console.log(e);
            }
            this.setState({ loading: false });
        }
    }

    setSuccessClassNameOnSave = (css: string) => {
        this.setState({show_css: css})
    }

    showImportPlanOnDemand = (type: string) => {
        this.setState({ plan_import_type: type });
    }

    closeImportPlanOnDemand = () => {
        this.setState({ plan_import_type: "" });
    }

    render() {
        const plants = BusinessLogic.getPlantsForUser();
        const plant = plants.find(p => p.uuid === this.props.plant_uuid);
        const is_saving_disabled = BusinessLogic.isQlectorUserOnProd();
        return <div id="save-buttons-container" className="btn-group">
            <button type="button"
                id="save-buttons-container"
                className={`btn btn-outline-primary btn-blue dropdown-toggle ${this.state.show_css}`}
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
            >
                <PlanningTableSaveSpinner /> {translate("common.synchronize", "Synchronize")}
            </button>
            {plant && !!this.state.plan_import_type && (
                <ImportPlanOndemand
                    import_type={this.state.plan_import_type}
                    line_group_uuid={this.props.line_group_uuid}
                    plant_external_id={plant.external_id}
                    onCloseImportPlanOnDemand={this.closeImportPlanOnDemand}
                />
            )}
            <div className="dropdown-menu dropdown-menu-right">
                {plant && (
                    <React.Fragment>
                        <SelectedOrdersProvider>
                            <SaveToSap
                                saveShifts={this.saveShifts}
                                plant_external_id={plant.external_id}
                                plant_uuid={this.props.plant_uuid}
                                line_group_uuid={this.props.line_group_uuid}
                            />
                        </SelectedOrdersProvider>
                        <div className="dropdown-divider" />
                    </React.Fragment>
                )}
                <ImportPlanButton
                    import_type={PLAN_IMPORT_TYPE.lists}
                    disabled={is_saving_disabled}
                    showImportPlanOnDemand={this.showImportPlanOnDemand}
                />
                <ImportPlanButton
                    import_type={PLAN_IMPORT_TYPE.plant}
                    disabled={is_saving_disabled}
                    showImportPlanOnDemand={this.showImportPlanOnDemand}
                />
                <SaveShiftsButton
                    changed_shifts={this.state.changed_shifts}
                    disabled={is_saving_disabled}
                    loading={this.state.loading}
                    saveShifts={this.saveShifts}
                    setSuccessClassNameOnSave={this.setSuccessClassNameOnSave}
                />
                <SaveToTxtButton />
                <LogsButton />
            </div>
        </div>
    }
}

export default connect(
    (state: ReduxState) => {
        return {
            lines_orders: state.gantt_chart_lines_orders.lines_orders
        }
    },
    (dispatch) => ({ reduxDispatch: dispatch })
)(SaveButtonsContainer);

