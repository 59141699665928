// @flow
// $FlowFixMe
import "../../../styles/planning_table.scss";
import React from "react";
import { Provider, connect } from 'react-redux';
import { combineReducers, createStore } from 'redux';
import { reducers } from "./reducers/reducers";

import RecommendPlan from "./RecommendPlan";
import PlanningTable from "./PlanningTable";
import Recalculate from "./Recalculate";
import InsightTypesSelect from "./InsightTypesSelect";
import DisplayTypesMenu from "./DisplayTypesMenu";
import SaveButtonsContainer from "./SaveButtonsContainer";
import { PLANNING_TABLE_DISPLAY_TYPES } from "./DisplayTypesMenu";
import { RESET_FILTERS } from "./reducers/reducersFilters/filters";
import { DraggableConsumer, DraggableProvider } from "./Draggable/DraggableContext";
import { translate } from "./../../IntlProviderWrapper";
import { INSIGHT_TYPES } from "../../../lib/ManufacturingTags.generated";
import { AreYouSureModal } from "../../react/AreYouSureModal";
import { SET_ALL_AS_UNSCHEDULED } from "./reducers/common";
import { SET_SHOW_STOCK_REQUIREMENTS } from "./reducers/planningTable";
import { REPORT_LOADING_ACTION_NAME } from "./reducers/report";
import MouseCursor from "./MouseCursor";
import { DraggableLogic, ReduxFilters } from "./reducers/BusinessLogic";
import { ConnectedViewMenuAlerts } from "./Alerts/AlertsModal"
import RetryButton from "./RetryButton";
import { PerformanceAnalysisButton } from "../../react/performance/PlanningTablePerformance";
import BadgeNumberAlerts from "./Alerts/BadgeNumberAlerts";
import AbortButton from "./AbortButton";
import OperationLinksModal from "./OperationLinksModal";

import type { ReportLoadingAction } from "./reducers/report";
import type { ResetFiltersAction } from "./reducers/reducersFilters/stockRequirementsFilters";
import type { ReduxState } from "./reducers/reducers";
import type { SetShowStockRequirements, SetLeftMenuSelectedView } from "./reducers/planningTable";
import type { DraggableProviderContext } from  "./Draggable/DraggableContext";

type StockRequirementsToggleProps = {
    show_stock_requirements: boolean,
    reduxDispatch: (args: SetShowStockRequirements | ResetFiltersAction | ReportLoadingAction | SetLeftMenuSelectedView) => void
};
type StockRequirementsToggleState = {};

class StockRequirementsToggle extends React.Component<StockRequirementsToggleProps, StockRequirementsToggleState> {

    onToggle = () => {
        this.props.reduxDispatch({
            type: REPORT_LOADING_ACTION_NAME,
            data: true
        });
        setTimeout(() => {
            this.props.reduxDispatch({
                type: SET_SHOW_STOCK_REQUIREMENTS,
                data: !this.props.show_stock_requirements
            });
            this.props.reduxDispatch({ type: RESET_FILTERS, data: undefined });
            this.props.reduxDispatch({ type: REPORT_LOADING_ACTION_NAME, data: false });
        }, 10)
    }

    render() {
        const menu_text = this.props.show_stock_requirements ?
            translate("common.hide_stock_requirements", "Hide stock requirements") :
            translate("common.show_stock_requirements", "Show stock requirements");

        return <button
            className="dropdown-item"
            onClick={this.onToggle}
        >{menu_text}</button>
    }
}

const ConnectedStockRequirementsToggle = connect(
    (state: ReduxState) => {
        const planning_table = state.gantt_chart_planning_table;
        return {
            show_stock_requirements: planning_table.show_stock_requirements
        }
    },
    (dispatch) => ({ reduxDispatch: dispatch })
)(StockRequirementsToggle);

type PlanningTableMenuProps = {
    show_import_plan_modal: boolean,
    show_import_plant_plan_modal: boolean,
    show_operation_links_modal: boolean,
    show_planning_table_filter: boolean,
    line_group_uuid: string,
    plant_uuid: string,
    selected_display_type: string,
    onResetFilter: () => void,
    onShowPlanningTableFilter: () => void,
    onShowImportPlanOnDemand: () => void,
    onCloseImportPlantPlanOnDemand: () => void,
    onShowImportPlantPlanOnDemand: () => void,
    onCloseImportPlanOnDemand: () => void,
    onShowOperationLinksModal: () => void,
    onCloseOperationLinksModal: () => void,
    onDisplayTypeChanged: (display_type_value: $Keys<typeof PLANNING_TABLE_DISPLAY_TYPES>) => void,
    onSetAllAsUnscheduled: () => void
}
type PlanningTableMenuState = { }

class PlanningTableMenu extends React.Component<PlanningTableMenuProps, PlanningTableMenuState> {

    renderSaveButton = () => {
        return <SaveButtonsContainer
            plant_uuid={this.props.plant_uuid}
            line_group_uuid={this.props.line_group_uuid}
        />
    }

    renderShowStockRequirementsToggle = () => {
        return <ConnectedStockRequirementsToggle />
    }

    renderOperationLinksModalButton = () => {
        return (
            <React.Fragment>
                <button
                    className="dropdown-item"
                    onClick={this.props.onShowOperationLinksModal}
                >
                    {translate("common.linked_operations_overview", "Linked operations overview")}
                </button>
                <OperationLinksModal
                    show={this.props.show_operation_links_modal}
                    onClose={this.props.onCloseOperationLinksModal}
                />
            </React.Fragment>
        );
    }

    render() {
        return <div className="planning-table-app-menu" style={{padding: "10px", justifyContent: "space-between"}}>
            <div style={{display: "flex"}}>
                <div className="nav-item dropdown active" key="div_view">
                    <a className="nav-link" to="#" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false" id="dropdownMenuManufacturing" style={{cursor: "pointer"}}>
                        <div style={{ display: "flex" }}>
                            <span><img src="/img/eye-solid.svg" /></span>
                            <span style={{marginLeft: "5px"}}>{translate("common.view", "View")}</span>
                            <BadgeNumberAlerts />
                        </div>
                    </a>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuManufacturing">
                        <button
                            className="dropdown-item"
                            onClick={this.props.onShowPlanningTableFilter}
                        >{this.props.show_planning_table_filter ? translate("common.hide_left_menu", "Hide left menu") : translate("common.show_left_menu", "Show left menu")}</button>
                        {this.renderShowStockRequirementsToggle()}
                        {this.renderOperationLinksModalButton()}
                        <ConnectedViewMenuAlerts />
                    </div>
                </div>
                <div className="nav-item dropdown active" key="div_actions">
                    <a className="nav-link" to="#" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false" id="dropdownMenuManufacturing"
                        style={{cursor: "pointer"}}
                    >
                        <span><img src="/img/pen-ruler-solid.svg" /></span>
                        <span style={{marginLeft: "5px"}}>{translate("common.actions", "Actions")}</span>
                        <i className="fas fa-chevron-down" style={{marginLeft: "5px", fontSize: "10px"}}></i>
                    </a>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuManufacturing">
                        <button
                            className="dropdown-item"
                            onClick={this.props.onSetAllAsUnscheduled}
                        >{translate("common.set_all_as_unscheduled", "Set all as unscheduled")}</button>
                        <RetryButton />
                    </div>
                </div>
                <div style={{ marginLeft: "10px", display: "flex", alignItems: "center" }}>
                    <button className="btn btn-primary" style={{ width: "100%", fontWeight: 300, alignItems: "center", display: "flexN" }} onClick={this.props.onResetFilter}>
                        <img src="/img/filter-slash-solid.svg" />
                        <span style={{marginLeft: "5px"}}>{translate("common.reset_filters", "Reset filters")}</span>
                    </button>
                </div>
                <div style={{ marginLeft: "10px", display: "flex", alignItems: "center" }}>
                    <PerformanceAnalysisButton />
                </div>
            </div>
            <div style={{display: "flex", float: "right"}}>
                <div style={{ marginRight: "10px" }}>
                    <InsightTypesSelect
                        hide_insight_types={[INSIGHT_TYPES.man_downtime]}
                    />
                </div>
                <div style={{ marginRight: "10px" }}>
                    <DisplayTypesMenu
                        show_advanced_menu={this.props.show_planning_table_filter}
                        selected_display_type={this.props.selected_display_type}
                        line_group_uuid={this.props.line_group_uuid}
                        onDisplayTypeChanged={this.props.onDisplayTypeChanged}
                    />
                </div>
                <div style={{ marginRight: "10px" }}>
                    {this.renderSaveButton()}
                </div>
                <div style={{ marginRight: "10px" }}>
                    <RecommendPlan plant_uuid={this.props.plant_uuid} line_group_uuid={this.props.line_group_uuid} />
                </div>
                <AbortButton />
            </div>
        </div>
    }
}

type PlanningTableProps = {
    plant_uuid: string,
    line_group_uuid: string,
    reload?: () => void
};
type PlanningTableState = {
    show_import_plan_modal: boolean,
    show_import_plant_plan_modal: boolean,
    show_operation_links_modal: boolean,
    show_planning_table_filter: boolean,
    show_are_you_sure: boolean,
    selected_display_type: $Keys<typeof PLANNING_TABLE_DISPLAY_TYPES>
};

export default class PlanningTableApp extends React.Component<PlanningTableProps, PlanningTableState> {
    store = null;

    state = {
        show_import_plan_modal: false,
        show_import_plant_plan_modal: false,
        show_operation_links_modal: false,
        show_planning_table_filter: localStorage.getItem("show_planning_table_filter") === "true",
        show_are_you_sure: false,
        selected_display_type: PLANNING_TABLE_DISPLAY_TYPES.gantt
    };

    recalculate = null;

    constructor(props: PlanningTableProps) {
        super(props);
        this.store = createStore(combineReducers(reducers));
        this.recalculate = new Recalculate(this.store.dispatch, this.store);
        ReduxFilters.planning_table_store = this.store;
    }

    onShowImportPlanOnDemand = () => {
        this.setState({ show_import_plan_modal: true })
    }

    onShowImportPlantPlanOnDemand = () => {
        this.setState({ show_import_plant_plan_modal: true })
    }

    onShowOperationLinksModal = () => {
        this.setState({ show_operation_links_modal: true });
    }

    onCloseImportPlanOnDemand = () => {
        this.setState({ show_import_plan_modal: false })
    }

    onCloseImportPlantPlanOnDemand = () => {
        this.setState({ show_import_plant_plan_modal: false })
    }

    onCloseOperationLinksModal = () => {
        this.setState({ show_operation_links_modal: false });
    }

    onDisplayTypeChanged = (display_type_value: $Keys<typeof PLANNING_TABLE_DISPLAY_TYPES>) => {
        this.setState({ selected_display_type: display_type_value });
    }

    onResetFilter = () => {
        if (this.store) {
            this.store.dispatch({ type: RESET_FILTERS, data: undefined });
            DraggableLogic.changeMouseCursor(null);
        }
    }

    onShowPlanningTableFilter = () => {
        const show_planning_table_filter = !this.state.show_planning_table_filter;
        this.setState({ show_planning_table_filter });
        localStorage.setItem("show_planning_table_filter", show_planning_table_filter.toString());
    }

    onSetAllAsUnscheduled = () => {
        this.setState({ show_are_you_sure: true });
    }

    onAreYouSureAccept = () => {
        this.setState({ show_are_you_sure: false }, () => {
            const store =  this.store;
            if (store) {
                store.dispatch({ type: SET_ALL_AS_UNSCHEDULED, data: undefined });
            }
        });
    }

    onAreYouSureCancel = () => {
        this.setState({ show_are_you_sure: false });
    }

    render() {
        return <Provider store={this.store}>
            <AreYouSureModal
                onAccept={this.onAreYouSureAccept}
                onCancel={this.onAreYouSureCancel}
                show={this.state.show_are_you_sure}
            ></AreYouSureModal>
            <PlanningTableMenu
                plant_uuid={this.props.plant_uuid}
                line_group_uuid={this.props.line_group_uuid}
                show_import_plan_modal={this.state.show_import_plan_modal}
                show_import_plant_plan_modal={this.state.show_import_plant_plan_modal}
                show_operation_links_modal={this.state.show_operation_links_modal}
                show_planning_table_filter={this.state.show_planning_table_filter}
                onResetFilter={this.onResetFilter}
                onShowPlanningTableFilter={this.onShowPlanningTableFilter}
                onDisplayTypeChanged={this.onDisplayTypeChanged}
                onCloseImportPlantPlanOnDemand={this.onCloseImportPlantPlanOnDemand}
                onShowImportPlantPlanOnDemand={this.onShowImportPlantPlanOnDemand}
                onCloseImportPlanOnDemand={this.onCloseImportPlanOnDemand}
                onShowImportPlanOnDemand={this.onShowImportPlanOnDemand}
                onCloseOperationLinksModal={this.onCloseOperationLinksModal}
                onShowOperationLinksModal={this.onShowOperationLinksModal}
                onSetAllAsUnscheduled={this.onSetAllAsUnscheduled}
                selected_display_type={this.state.selected_display_type}
            />
            <DraggableProvider>
                <DraggableConsumer>
                    {(value?: DraggableProviderContext | null) => {
                        return (
                            <PlanningTable
                                plant_uuid={this.props.plant_uuid}
                                line_group_uuid={this.props.line_group_uuid}
                                show_planning_table_filter={this.state.show_planning_table_filter}
                                selected_display_type={this.state.selected_display_type}
                                draggableDispatch={value != null ? value.dispatch : () => {}}
                            />
                        );
                    }}
                </DraggableConsumer>
            </DraggableProvider>
            <MouseCursor />
            <div id="detached-draggable-order"></div>
        </Provider>
    }
}

