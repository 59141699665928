// @flow
import ReactRouterPropTypes from "react-router-prop-types";

import { compressPersistentFiltersParams } from "../redux/reducers/persistentFiltersReducer";
import { compressStockStatusTableFiltersParams } from "../redux/reducers/stockStatusTableFiltersReducer";
import { compressStockStatusFiltersParams } from "../redux/reducers/stockStatusFiltersReducer";
import { compressResourceEditFiltersParams } from "../redux/reducers/resourceEditFiltersReducer";

import type { PersistentFiltersState } from "../redux/reducers/persistentFiltersReducer";
import type { StockStatusTableFiltersState } from "../redux/reducers/stockStatusTableFiltersReducer";
import type { StockStatusFiltersState } from "../redux/reducers/stockStatusFiltersReducer";
import type { ResourceEditFiltersState } from "../redux/reducers/resourceEditFiltersReducer";

import { translate } from "../../components/IntlProviderWrapper";
import {
    LINES_VIEWS_VIEW_TYPES,
    STOCKS_VIEWS_VIEW_TYPES,
    TOOLS_VIEW_TYPES,
    PEOPLE_VIEW_TYPES
} from "../../components/ViewsCommon";

const parseUrlParameters = (hash: string): Object => {
    let new_compressed_params = {};
    // parse current url params
    let current_params = hash.split("#");

    if (current_params.length > 0) {
        const temp = new URLSearchParams(current_params[1]);

        Array.from(temp.keys()).forEach(key => {
            new_compressed_params[key] = temp.get(key);
        });
    }

    return new_compressed_params;
}

const changeDocumentTitle = (pathname: string) => {

    const key_options = [];

    for (const key of Object.keys(LINES_VIEWS_VIEW_TYPES)) {
        key_options.push({
            key: LINES_VIEWS_VIEW_TYPES[key].id,
            code: LINES_VIEWS_VIEW_TYPES[key].title_code,
            default_value: LINES_VIEWS_VIEW_TYPES[key].title
        });
    }

    for (const key of Object.keys(STOCKS_VIEWS_VIEW_TYPES)) {
        key_options.push({
            key: STOCKS_VIEWS_VIEW_TYPES[key].id,
            code: STOCKS_VIEWS_VIEW_TYPES[key].title_code,
            default_value: STOCKS_VIEWS_VIEW_TYPES[key].title
        });
    }

    for (const key of Object.keys(PEOPLE_VIEW_TYPES)) {
        key_options.push({
            key: PEOPLE_VIEW_TYPES[key].id,
            code: PEOPLE_VIEW_TYPES[key].title_code,
            default_value: PEOPLE_VIEW_TYPES[key].title
        });
    }

    for (const key of Object.keys(TOOLS_VIEW_TYPES)) {
        key_options.push({
            key: TOOLS_VIEW_TYPES[key].id,
            code: TOOLS_VIEW_TYPES[key].title_code,
            default_value: TOOLS_VIEW_TYPES[key].title
        });
    }

    const splitted_pathname = pathname.split("/");
    const view = splitted_pathname[splitted_pathname.length - 1];

    const view_title = key_options.find(option => option.key === view);

    if (view_title) {
        document.title = translate(view_title.code, view_title.default_value) + " | Qlector LEAP";
    } else {
        document.title = "Qlector LEAP";
    }


}

const redirect = (
    history: ReactRouterPropTypes.history, // get current params
    pathname: string, // new url redirect pathname
    new_compressed_params: $Shape<PersistentFiltersState>,
    force_replace: boolean = false
) => {

    for (const [key, value] of Object.entries(new_compressed_params)) {
        if (typeof value === "string" && value.length === 0 || value === undefined || value === null) {
            delete new_compressed_params[key];
        }
    }
    const new_hash = "#" + new URLSearchParams(new_compressed_params).toString();
    if (force_replace && history.location.hash !== new_hash) {
        // sometimes we dont want a redirect, sometimes we just need url cleaned up
        history.replace(new_hash)
    } else if (history.location.pathname !== pathname || history.location.hash !== new_hash) {
        history.push(pathname + new_hash);
    }

    changeDocumentTitle(pathname);

}
export const persistentFilters = (
    history: ReactRouterPropTypes.history, // get current params
    pathname: string, // new url redirect pathname
    data: PersistentFiltersState, // new url params
    force_replace: boolean = false
) => {

    const new_compressed_params = {
        ...parseUrlParameters(history.location.hash),
        ...compressPersistentFiltersParams(data)
    }

    redirect(history, pathname, new_compressed_params, force_replace);
}

export const stockStatusTableFilters = (
    history: ReactRouterPropTypes.history, // get current params
    pathname: string, // new url redirect pathname
    data: StockStatusTableFiltersState, // new url params
    force_replace: boolean = false
) => {
    const new_compressed_params = {
        ...parseUrlParameters(history.location.hash),
        ...compressStockStatusTableFiltersParams(data)
    }
    redirect(history, pathname, new_compressed_params, force_replace);
}

export const stockStatusFilters = (
    history: ReactRouterPropTypes.history, // get current params
    pathname: string, // new url redirect pathname
    data: StockStatusFiltersState, // new url params
    force_replace: boolean = false
) => {
    const new_compressed_params = {
        ...parseUrlParameters(history.location.hash),
        ...compressStockStatusFiltersParams(data)
    }
    redirect(history, pathname, new_compressed_params, force_replace);
}

export const resourceEditFilters = (
    history: ReactRouterPropTypes.history, // get current params
    pathname: string, // new url redirect pathname
    data: ResourceEditFiltersState, // new url params
    force_replace: boolean = false
) => {
    const new_compressed_params = {
        ...parseUrlParameters(history.location.hash),
        ...compressResourceEditFiltersParams(data)
    }
    redirect(history, pathname, new_compressed_params, force_replace);
}
