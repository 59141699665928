// @flow
import * as React from "react";
import ReactRouterPropTypes from "react-router-prop-types";

import { getBackend } from "../../../lib/backend/Backend2";
import { INSIGHT_TYPES, INSIGHT_TAGS_ACCESS, ORDER_TAGS_ACCESS } from "../../../lib/ManufacturingTags.generated";
import { getLineGroupForUser, getLineTitle, getLineWeight } from "../../../lib/BusinessLogic";

import ErrorComponent from "../../ErrorComponent";
import Loader from "../../Loader";
import {
    TIME_RANGES,
    classNames,
    niceDate,
    dateFromShiftTime,
    shiftNumber
} from "../../../lib/Util";
import { getLang, translate } from "../../IntlProviderWrapper";

import type { IEventDataEx } from "../../../lib/backend/manufacturing2.generated.types";

// $FlowFixMe
import "../../../styles/tool_setup.scss";

type OperationDetails = {
    comment?: string,
    process_num?: string,
    title?: string
}

type Props = {
    history: ReactRouterPropTypes.history,
    data_def?: any
};

type State = {
    insights: IEventDataEx[],
    insight_operation: Map<string, OperationDetails>,
    is_loading: boolean,
    error: string
};

const groupInsights = (insights: IEventDataEx[]): Map<string, IEventDataEx[]> => {
    const line_insights_map: Map<string, IEventDataEx[]> = new Map();
    const sorted_insights = [...insights].sort((a, b) => a.ts - b.ts);
    for (const insight of sorted_insights) {
        const line_uuid = insight.tags.line_uuid;
        if (line_uuid === undefined) {
            continue;
        }

        let line_insights = line_insights_map.get(line_uuid);
        if (line_insights === undefined) {
            line_insights = [];
            line_insights_map.set(line_uuid, line_insights);
        }

        line_insights.push(insight);
    }

    return line_insights_map;
};

const sortLines = (lines: string[]): string[] => {
    const line_weights: Map<string, number> = new Map();
    for (const line of lines) {
        line_weights.set(line, getLineWeight(line));
    }

    return [...lines].sort((a, b) => {
        const weight_a = line_weights.get(a);
        const weight_b = line_weights.get(b);
        if (weight_a === undefined || weight_b === undefined) {
            return 0;
        }

        return weight_a - weight_b;
    });
};

class ToolSetup extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const state: State = {
            insights: [],
            insight_operation: new Map(),
            is_loading: true,
            error: ""
        };
        this.state = state;
    }

    async componentDidMount() {
        await this.loadInsights();
    }

    async componentDidUpdate(prev_props: Props) {
        if (prev_props.data_def !== this.props.data_def) {
            await this.loadInsights();
        }
    }

    loadInsights = async () => {
        const data_def = this.props.data_def;

        let plant_uuid = null;
        if (data_def && data_def.filters && data_def.filters.plant_uuid) {
            plant_uuid = data_def.filters.plant_uuid;
        }

        let line_group_uuid = null;
        if (data_def && data_def.filters && data_def.filters.line_group) {
            line_group_uuid = data_def.filters.line_group;
        }

        if (plant_uuid === null || line_group_uuid === null) {
            this.setState({
                is_loading: false
            });
            return;
        }

        this.setState({
            is_loading: true
        });

        try {
            const line_group = getLineGroupForUser(line_group_uuid);
            const lines = line_group ? line_group.line_uuids : [];

            const { next_shift_time } = await getBackend().reports.getLatestReportShift({ });
            const from = dateFromShiftTime(next_shift_time).getTime();
            const to = from + TIME_RANGES.WEEK;

            const { insights } = await getBackend().manufacturing.getInsights({
                types: [INSIGHT_TYPES.tool_setup],
                plants: [plant_uuid],
                lines,
                from,
                to
            });
            const shown_insights = insights.sort((a, b) => a.ts - b.ts).slice(0, 25);

            const { operations } = await getBackend().manufacturing.getOrdersSimple({
                uuids: shown_insights.map(insight => INSIGHT_TAGS_ACCESS.order_uuid(insight.tags))
            });
            const insight_operation = new Map();
            for (const operation of operations) {
                const comment = ORDER_TAGS_ACCESS.comment(operation.tags);
                const process_num = operation.process_num;
                const title = operation.title;
                insight_operation.set(operation.uuid, {
                    comment: comment.length > 0 ? comment : undefined,
                    process_num,
                    title
                });
            }

            this.setState({
                insights: shown_insights,
                insight_operation,
                is_loading: false
            });
        } catch (error) {
            this.setState({
                error: error.message,
                is_loading: false
            });
        }
    };

    renderInsight = (
        insight: IEventDataEx,
        first_on_line: boolean,
        line_class: string
    ) => {
        const rounding_factor = 15 * 60 * 1000;
        const round_ts = Math.round(insight.ts / (rounding_factor)) * rounding_factor;
        const date = new Date(round_ts);
        const shift_number = shiftNumber(date);
        const order_external_id = insight.extra_data.order_external_id || insight.tags.order_external_id;
        const material_external_id = insight.extra_data.material_canonical || insight.extra_data.material;
        const user_lang = getLang();
        const line = insight.tags.line_uuid;
        let material_title = insight.extra_data.material_title;
        if (insight.extra_data.title_lang && insight.extra_data.title_lang[user_lang]) {
            material_title = insight.extra_data.title_lang[user_lang];
        }
        if (material_title.length > 30) {
            material_title = material_title.slice(0, 27) + "...";
        }

        const order_uuid = INSIGHT_TAGS_ACCESS.order_uuid(insight.tags);
        const operation_details = this.state.insight_operation.get(order_uuid) || {};
        const comment = operation_details.comment;
        const process_num = operation_details.process_num;
        let operation_title = operation_details.title || "";
        if (operation_title.length > 30) {
            operation_title = operation_title.slice(0, 27) + "...";
        }

        return (
            <dl>
                {first_on_line &&
                <div className={classNames("tool-setup-line", line_class)} key={line}>
                    {getLineTitle(line)}
                </div>}
                <div className="tool-setup-insight-date">
                    <dd>
                        <strong>{shift_number.day.slice(0, 3)} {`${shift_number.shift_day + 1}.`} {translate("common.shift_lc", "shift")}</strong>
                    </dd>
                    <dd>
                        {niceDate(date)}
                    </dd>
                    <dd>{order_external_id}</dd>
                    <dd><strong>{material_external_id}</strong></dd>
                </div>
                <div className="tool-setup-insight-material">
                    <dd>{material_title}</dd>
                    <dd>{process_num} - {operation_title}</dd>
                </div>
                {comment && (
                    <div className="tool-setup-insight-comment">
                        <dd>{comment}</dd>
                    </div>
                )}
            </dl>
        );
    };

    renderInsights = () => {
        const { insights } = this.state;
        const line_insights_map = groupInsights(insights);
        const lines = sortLines([...line_insights_map.keys()]);
        const items = [];
        for (let i = 0; i < lines.length; i++) {
            const line = lines[i];
            const line_insights = line_insights_map.get(line);
            if (line_insights !== undefined) {
                const line_class = i % 2 === 0 ? "even" : "even";
                for (let j = 0; j < line_insights.length; j++) {
                    const insight = line_insights[j];
                    const insight_class = j % 2 === 0 ? "even" : "odd";
                    items.push(
                        <li
                            className={classNames("tool-setup-insight", `${line_class}-${insight_class}`)}
                            key={insight.uuid}
                        >
                            {this.renderInsight(insight, j === 0, line_class)}
                        </li>
                    );
                }
            }
        }

        return <ul className="tool-setup-insights">{items}</ul>;
    };

    render() {
        const { error, is_loading } = this.state;
        return (
            <div className="tool-setup card">
                {error !== "" && <ErrorComponent type="error" msg={error} />}
                {is_loading ? <Loader /> : this.renderInsights()}
            </div>
        );
    }
}

export default ToolSetup;
