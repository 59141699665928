// @flow
import React from "react";
import { connect } from "react-redux";
import { translate } from "../../IntlProviderWrapper";
import { niceNumber, niceDateTime } from "../../../lib/Util";
import { ReduxFilters } from "./reducers/BusinessLogic";
import { UPDATE_STOCK_REQUIREMENTS_HEIGHT } from  "./reducers/properties";
import { UPDATE_STOCK_REQUIREMENTS } from "./reducers/stockRequirements";
import { LinesOrdersLogic } from "./reducers/BusinessLogic";
import * as t2 from "../../../lib/SimulationReportModels";
import StockRequirementsFilters from "./StockRequirementsFilters";
import { numStockRequirementsAfterResolution } from "./reducers/properties";
import { UserActions } from "./reducers/BusinessLogic";
import Loader from "../../Loader";

import type { ReduxState } from "./reducers/reducers";
import type { StockRequirement } from "./reducers/stockRequirements";
import type { LineOrders } from "./reducers/linesOrders";
import type { PropertiesState } from "./reducers/properties";
import type { StockRequirementActions } from "./reducers/BusinessLogic";
import type { PlanningTableFiltersState } from "./reducers/reducersFilters/filters";
import type { StockRequirementsFiltersState } from "./reducers/reducersFilters/stockRequirementsFilters";
import type { InsightsMapStockRequirements } from  "./reducers/insights";

type Props = {
    stock_requirements: StockRequirement[],
    properties: PropertiesState,
    filtered_stock_requirements_materials: string[],
    selected_stock_requirement: string,
    current_report_uuid: string,
    report: t2.IReportModelEx,
    lines_orders: LineOrders[],
    unscheduled_orders: LineOrders[],
    show_planning_table_filter: boolean,
    line_group_uuid: string,
    original_report_uuid: string,
    orders_filters: PlanningTableFiltersState,
    stock_requirements_filters: StockRequirementsFiltersState,
    insights_map_stock_requirements: InsightsMapStockRequirements,
    reduxDispatch: (args: StockRequirementActions) => void
};

type State = { loading: boolean };

class StockRequirementsList extends React.Component<Props, State> {

    state = {
        loading: false
    }

    async componentDidMount() {
        if (this.props.lines_orders.length > 0) {
            await this.load();
        }
    }

    componentDidUpdate(prev_props: Props) {
        if (prev_props.line_group_uuid !== this.props.line_group_uuid) {
            this.setState({loading: true});
        }

        if (prev_props.lines_orders !== this.props.lines_orders && this.props.lines_orders.length > 0) {
            this.load();
        }

        if (prev_props.selected_stock_requirement !== this.props.selected_stock_requirement && this.props.selected_stock_requirement) {
            const uuid = this.props.selected_stock_requirement;
            const rect = document.querySelector(`[name='stock-requirement-row'][data-uuid='${uuid}']`);

            if (rect) {
                // $FlowFixMe
                rect.scrollIntoView({ block: "center", inline: "center" });
            }
        }
    }

    async load() {
        this.setState({ loading: true });
        const plant_uuid = localStorage.getItem("last-plant");
        const lines_orders = this.props.lines_orders;

        if (!lines_orders || !plant_uuid || lines_orders && lines_orders.length === 0) {
            console.log("No report...", lines_orders);
            console.log("No plant...", plant_uuid);
            return;
        }

        const line_uuids = lines_orders.map(lol => lol.line_uuid);

        let stock_requirements = await LinesOrdersLogic.getStockRequirements(
            plant_uuid,
            line_uuids,
            this.props.properties
        );

        stock_requirements = stock_requirements.map(sr => ({
            date_required: sr.date_required,
            date_expected: sr.date_expected,
            shift_start_time: sr.shift_start_time,
            quantity: sr.quantity,
            material_title: sr.material_title,
            material_external_id: sr.material_external_id,
            material_uuid: sr.material_uuid,
            uuid: sr.uuid,
            shift_uuid: sr.shift_uuid,
            plant_uuid,
            transaction_external_id: sr.transaction_external_id
        }));

        this.props.reduxDispatch({
            type: UPDATE_STOCK_REQUIREMENTS,
            data: stock_requirements
        });

        this.props.reduxDispatch({
            type: UPDATE_STOCK_REQUIREMENTS_HEIGHT,
            data: {
                num_stock_requirements: numStockRequirementsAfterResolution(stock_requirements, this.props.properties)
            }
        });

        this.setState({ loading: false });
    }

    onClick = (stock_requirement: StockRequirement) => async (e: Event) => {
        this.props.reduxDispatch({ type: "report_loading", data: true });

        UserActions.onStockRequirementClick(
            stock_requirement.uuid,
            stock_requirement.material_external_id,
            this.props.lines_orders,
            this.props.reduxDispatch,
            this.props.selected_stock_requirement === stock_requirement.uuid
        );
    }

    renderStockRequirement = (stock_requirement: StockRequirement) => {
        const border = this.props.selected_stock_requirement === stock_requirement.uuid ? "1px solid black" : "";
        const insights_map_stock_requirements = this.props.insights_map_stock_requirements;

        let backgroundColor = "";
        if (insights_map_stock_requirements && insights_map_stock_requirements.has(stock_requirement.uuid)) {
            backgroundColor = "rgb(254, 243, 242)";
        }

        return <React.Fragment>
            <tr
                key={stock_requirement.uuid}
                name="stock-requirement-row"
                data-uuid={stock_requirement.uuid}
                style={{ cursor: "pointer", border, backgroundColor, borderBottom: "unset" }}
                onClick={this.onClick(stock_requirement)}
            >
                <td>
                    <strong>{stock_requirement.material_external_id}</strong> <br />
                    {stock_requirement.material_title}
                </td>
                <td>{niceDateTime(new Date(stock_requirement.shift_start_time))}</td>
                <td>{niceNumber(Math.abs(stock_requirement.quantity), 10)}</td>
            </tr>
            <tr onClick={this.onClick(stock_requirement)} style={{ marginBottom: "5px", border, backgroundColor, borderTop: "unset" }}>
                <td colSpan="3">{stock_requirement.transaction_external_id}</td>
            </tr>
            <tr><td style={{height: "20px"}}></td></tr>
        </React.Fragment>
    }

    getStockRequirements = () => {
        return ReduxFilters.filterStockRequirements(
            this.props.lines_orders,
            this.props.unscheduled_orders,
            this.props.stock_requirements,
            this.props.orders_filters,
            this.props.stock_requirements_filters,
            this.props.insights_map_stock_requirements
        );
    }

    renderTable() {
        if (this.state.loading) {
            return <Loader small />;
        }
        const stock_requirements = this.getStockRequirements();

        let el = stock_requirements.map(this.renderStockRequirement);
        if (stock_requirements.length === 0) {
            el = <tr>
                <td colSpan={3} style={{padding: "15px"}}>
                    <span>{translate("common.no_data", "No data available")}</span>
                </td>
            </tr>
        }

        return <div className="planning-table__row mt-0" style={{ caretColor: "transparent" }}>
            <table className="planning-table__sortable-list">
                <thead>
                    <tr>
                        <th className="sortable-element__material">{translate("common.material", "Material")}</th>
                        <th className="sortable-element__workorder">{translate("common.date", "Date")}</th>
                        <th className="sortable-element__unit">{translate("common.quantity", "Quantity")}</th>
                    </tr>
                </thead>
                <tbody>
                    {el}
                </tbody>
            </table>
        </div>
    }

    render() {
        return <div
            className="left-menu-unscheduled-orders flex-column h-100"
        >
            <StockRequirementsFilters
                show_planning_table_filter={this.props.show_planning_table_filter}
                line_group_uuid={this.props.line_group_uuid}
                original_report_uuid={this.props.original_report_uuid}
            />
            <div className="unscheduled-orders-mode flex-grow overflow-auto">
                <div className="unscheduled-orders-container">
                    {this.renderTable()}
                </div>
            </div>
        </div>
    }
}

export default connect(
    (state: ReduxState, own_props: Props) => {
        const stock_requirements = state.gantt_chart_stock_requirements.stock_requirements;
        const lines_orders = state.gantt_chart_lines_orders.lines_orders;
        const unscheduled_orders = state.gantt_chart_lines_orders.unscheduled_orders;
        const stock_requirements_filters = state.gantt_chart_stock_requirements_filter;
        const insights_map_stock_requirements = state.gantt_chart_insights.insights_map_stock_requirements;

        return {
            properties: state.gantt_chart_properties,
            stock_requirements: stock_requirements,
            stock_requirements_filters,
            selected_stock_requirement: stock_requirements_filters.selected_stock_requirement,
            lines_orders,
            orders_filters: state.gantt_chart_filters,
            unscheduled_orders,
            report_loading: state.gantt_chart_report.report_loading,
            insights_map_stock_requirements
        }
    },
    (dispatch) => ({ reduxDispatch: dispatch })
)(StockRequirementsList)
