// @flow
import { STOCKS_VIEWS_VIEW_TYPES } from "./ViewsCommon";
import { getBackend } from "../lib/backend/Backend2";
import { SET_TRANSLATIONS_MATERIALS, SET_TRANSLATIONS_LOADING } from "./redux/reducers/translationsMaterialsReducer";
import { getLang } from "./IntlProviderWrapper";
import { store } from "../index";

const request_cache = new Set<string>();

export class MaterialTranslations {

    static addRequestCache(view: string, plant_uuid: string, line_group_uuid: string) {
        request_cache.add(view + "_" + plant_uuid + "_" + line_group_uuid + "_" + getLang());
    }

    static hasCache(view: string, plant_uuid: string, line_group_uuid: string) {
        return request_cache.has(view + "_" + plant_uuid + "_" + line_group_uuid + "_" + getLang());
    }

    static getView() {
        const pathname = window.location.pathname;

        if (pathname.includes("stock_requirements_inbounds")) {
            return STOCKS_VIEWS_VIEW_TYPES.stock_requirements_inbounds.id;
        }
        if (pathname.includes("stock_requirements_outbounds")) {
            return STOCKS_VIEWS_VIEW_TYPES.stock_requirements_outbounds.id;
        }
    }

    static exit() {
        store.dispatch({ type: SET_TRANSLATIONS_LOADING, data: false });
    }

    static async waitForTranslationsAsync(plant_uuid: string, line_group_uuid: string) {
        store.dispatch({ type: SET_TRANSLATIONS_LOADING, data: true });
        if (!plant_uuid || !line_group_uuid) {
            return MaterialTranslations.exit();
        }
        const current_view = MaterialTranslations.getView();

        if (!current_view) {
            return MaterialTranslations.exit();
        };

        const has_cache = MaterialTranslations.hasCache(current_view, plant_uuid, line_group_uuid);
        if (!has_cache) {
            MaterialTranslations.addRequestCache(current_view, plant_uuid, line_group_uuid);
        } else {
            return MaterialTranslations.exit();
        }

        if (current_view === STOCKS_VIEWS_VIEW_TYPES.stock_requirements_inbounds.id && !has_cache) {
            return await MaterialTranslations.loadStockRequirements(plant_uuid, line_group_uuid);
        } else if (current_view === STOCKS_VIEWS_VIEW_TYPES.stock_requirements_outbounds.id && !has_cache) {
            return await MaterialTranslations.loadStockRequirements(plant_uuid, line_group_uuid);
        }

        return MaterialTranslations.exit();
    }

    static async loadStockRequirements(plant_uuid: string, line_group_uuid: string) {
        const res = await getBackend().manufacturing.getStockRequirements(
            {
                line_group_uuid: line_group_uuid,
                plant_uuid: plant_uuid
            }
        );
        const stock_requirements = res.stock_requirements;
        const materials_uuids = new Set();
        for (const sr of stock_requirements) {
            if (sr.material_uuid) {
                materials_uuids.add(sr.material_uuid)
            }
        }
        if (materials_uuids.size > 0) {
            return await this.loadTranslations([...materials_uuids]);
        }
        return MaterialTranslations.exit();
    }

    static async loadTranslations(materials_uuids: string[]) {
        const res = await getBackend().manufacturing.getMaterialsTranslations(
            { language_code: getLang(), materials_uuids }
        );

        store.dispatch({ type: SET_TRANSLATIONS_MATERIALS, data: res.translations_materials });
        return MaterialTranslations.exit();
    }

    static getMaterialsViews = (): string[] => {
        return [
            STOCKS_VIEWS_VIEW_TYPES.stock_requirements_inbounds.id,
            STOCKS_VIEWS_VIEW_TYPES.stock_requirements_outbounds.id
        ]
    }
}


