// @flow
import * as React from "react";
import * as Utils from "../lib/Util";
import { Sparklines, SparklinesLine } from "react-sparklines";

import ChevronRightIcon from "./react/Icon/ChevronRight";

// $FlowFixMe
import "../styles/kpi_box.scss";

type KpiBoxItemProps = {
    value: number,
    unit: string,
    comment?: string,
    sparkline?: number[],
    color?: string,
    class_name?: string
};

type Props = {
    label: string,
    items: KpiBoxItemProps[],
    note?: string
};

const KpiBoxItem = (props: KpiBoxItemProps) => {
    const is_comment_visible = props.comment !== undefined && props.comment.trim().length > 0;
    return (
        <li className={Utils.classNames("kpi-box-item col-12 px-2", props.class_name || "")}>
            {props.comment !== undefined && (
                <p
                    className={Utils.classNames("kpi-box-comment mb-0", {
                        invisible: !is_comment_visible
                    })}
                >
                    {props.comment}
                </p>
            )}
            <div className="row mx-n2">
                <div className="col px-2">
                    <div className="kpi-box-number">
                        <ChevronRightIcon size="xs" class_name="mb-1" />{" "}
                        <strong>{Utils.niceNumber(props.value)}</strong> <em>{props.unit}</em>
                    </div>
                </div>
                {props.sparkline && (
                    <div className="kpi-box-sparkline col-4 d-flex align-items-end px-2">
                        <Sparklines data={props.sparkline}>
                            <SparklinesLine color={props.color} />
                        </Sparklines>
                    </div>
                )}
            </div>
        </li>
    );
};

const KpiBox = (props: Props) => {
    return (
        <div className="kpi-box">
            <div className="kpi-box-header d-flex align-items-baseline px-3">
                <span className="kpi-box-label flex-grow-1 text-truncate" title={props.label}>
                    {props.label}
                </span>
                {props.note && <span className="kpi-box-note text-muted text-nowrap ml-2">{props.note}</span>}
            </div>
            <div className="kpi-box-body p-3">
                <ul className="kpi-box-list list-unstyled row mx-n2 mb-0">
                    {props.items.map((item_props, i) => (
                        <React.Fragment key={i}>
                            <KpiBoxItem {...item_props} />
                        </React.Fragment>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default KpiBox;
