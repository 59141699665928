// @flow
import * as React from "react";
import AsyncSelect from "react-select/async";
import Toggle from "react-toggle";

import { classNames, niceDate, TIME_RANGES } from "../../lib/Util";
import { translate } from "../IntlProviderWrapper";
import { getBackend } from "../../lib/backend/Backend2";
import { ORDER_STATUS } from "../../lib/ManufacturingConsts.generated";
import { MATERIAL_TAGS, MATERIAL_TAGS_ACCESS, ORDER_TAGS_ACCESS } from "../../lib/ManufacturingTags.generated";
import { getAllLinesForLineGroup } from "../../lib/BusinessLogic";
import ErrorComponent from "../ErrorComponent";
import Loader from "../Loader";
import ReactTable from "../react/ReactTable";
import MarkedText from "../MarkedText";

import type { Column, Cell, Row, ColumnInstance, FilterProps } from "react-table";
import type { IMaterialModel, IOrderModelBase, ITags } from "../../lib/backend/manufacturing2.generated.types";

// $FlowFixMe
import "../../styles/tool_management.scss";
import Tooltip from "../Tooltip";

const TIME_WINDOW = TIME_RANGES.DAY * 365;

const TOOL_STATUS = {
    maintenance: "maintenance",
    ready: "ready"
};

const TOOL_COLUMNS = {
    tool: "tool",
    operation: "operation",
    material: "material",
    status: "status"
};

type SelectOption = {
    label: string,
    value: string
};

type ToolStatus = $Keys<typeof TOOL_STATUS>;

type ToolRow = {
    tool: IMaterialModel,
    operation: IOrderModelBase,
    material: IMaterialModel | null,
    status: ToolStatus
};

type Props = {
    plant_uuid: string,
    line_group_uuid: string
};

type State = {
    error: string,
    is_loading: boolean,
    is_updating: boolean,
    operations: IOrderModelBase[],
    materials_map: Map<string, IMaterialModel>,
    tools_map: Map<string, IMaterialModel>,
    tool_filter: string,
    operation_filter: string
};

const ToolCell = ({ column, value }: Cell<ToolRow, IMaterialModel>) => {
    const search = column.filterValue;
    return (
        <span>
            <strong className="font-weight-500">
                <MarkedText text={value.external_id} search={search} />
            </strong>{" "}
            <span className="ml-2">
                <MarkedText text={value.title} search={search} />
            </span>
        </span>
    );
};

const filterOperation = (rows: Row<ToolRow>[], columnId: string, filterValue: string): Row<ToolRow>[] => {
    return rows.filter(row => {
        const operation: IOrderModelBase = row.values[columnId];
        return (
            operation.external_id.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0 ||
            operation.material_external_id.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0 ||
            operation.material_title.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0 ||
            operation.title.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0 ||
            operation.process_num.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0 ||
            operation.line.title.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0
        );
    });
};

const filterTool = (rows: Row<ToolRow>[], columnId: string, filterValue: string): Row<ToolRow>[] => {
    return rows.filter(row => {
        const tool: IMaterialModel = row.values[columnId];
        return (
            tool.external_id.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0 ||
            tool.title.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0
        );
    });
};

const compareOperations = (a: IOrderModelBase, b: IOrderModelBase): number => {
    if (a.status === ORDER_STATUS.closed && b.status === ORDER_STATUS.open) {
        return 1;
    }

    if (a.status === ORDER_STATUS.open && b.status === ORDER_STATUS.closed) {
        return -1;
    }

    const start_diff = a.earliest_start - b.earliest_start;
    if (start_diff !== 0) {
        return start_diff;
    }

    return a.created_at - b.created_at;
};

const mapMaterialOption = (material: IMaterialModel): SelectOption => ({
    label: `${material.external_id} - ${material.title}`,
    value: material.external_id
});

class ToolManagement extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const state: State = {
            error: "",
            is_loading: false,
            is_updating: false,
            operations: [],
            materials_map: new Map(),
            tools_map: new Map(),
            tool_filter: "",
            operation_filter: ""
        };
        this.state = state;
    }

    async componentDidMount() {
        this.setState({ is_loading: true });
        await this.loadData();
        this.setState({ is_loading: false });
    }

    async componentDidUpdate(prev_props: Props) {
        if (
            prev_props.plant_uuid !== this.props.plant_uuid ||
            prev_props.line_group_uuid !== this.props.line_group_uuid
        ) {
            this.setState({ is_loading: true });
            await this.loadData();
            this.setState({ is_loading: false });
        }
    }

    loadData = async () => {
        try {
            const now = new Date().getTime();
            const { lines } = await getAllLinesForLineGroup(this.props.line_group_uuid);
            const { operations } = await getBackend().manufacturing.getOrdersSimple({
                line_uuids: lines.map(line => line.uuid),
                earliest_end_min: now - TIME_WINDOW,
                statuses: [ORDER_STATUS.open, ORDER_STATUS.closed]
            });

            const tool_ids: Set<string> = new Set();
            let tool_operations: IOrderModelBase[] = [];
            for (const operation of operations) {
                const tool_id = ORDER_TAGS_ACCESS.production_tool_external_id(operation.tags);
                if (ORDER_TAGS_ACCESS.production_tool_external_id(operation.tags) !== "") {
                    tool_ids.add(tool_id);
                    tool_operations.push(operation);
                }
            }

            const { data: tools } = await getBackend().manufacturing.searchMaterialsSimple({
                external_ids: [...tool_ids]
            });

            const material_ids: Set<string> = new Set();
            const tools_map: Map<string, IMaterialModel> = new Map();
            for (const tool of tools) {
                if (tool.tags.production_resource === "true") {
                    tools_map.set(tool.external_id, tool);
                    const material_id = MATERIAL_TAGS_ACCESS.production_resource_set_to(tool.tags);
                    if (material_id !== "") {
                        material_ids.add(material_id);
                    }
                }
            }

            tool_operations = tool_operations
                .filter(
                    operation =>
                        tools_map.get(ORDER_TAGS_ACCESS.production_tool_external_id(operation.tags)) !== undefined
                )
                .sort(compareOperations);

            const { data: materials } = await getBackend().manufacturing.searchMaterialsSimple({
                external_ids: [...material_ids]
            });
            const materials_map = new Map(materials.map(material => [material.external_id, material]));

            this.setState({
                operations: tool_operations,
                materials_map,
                tools_map
            });
        } catch (error) {
            this.setState({ error: `${error}` });
        }
    };

    updateToolTags = async (tool_id: string, tags: ITags) => {
        const tool = this.state.tools_map.get(tool_id);
        if (tool === undefined) {
            console.error(`Tool not found: ${tool_id}`);
            return;
        }

        const new_tags: ITags = {
            ...tool.tags,
            ...tags
        };
        for (const key of Object.keys(tags)) {
            if (tags[key] === "") {
                delete new_tags[key];
            }
        }

        const tools_map = new Map(this.state.tools_map);
        tool.tags = new_tags;
        tools_map.set(tool_id, tool);
        this.setState({ tools_map, is_updating: true }, async () => {
            await getBackend().manufacturing.updateMaterial({
                uuid: tool.uuid,
                tags: new_tags
            });
            this.setState({ is_updating: false });
        });
    };

    setTool = async (tool_id: string, material_id: string) => {
        this.setState({ is_updating: true });
        await this.updateToolTags(tool_id, {
            [MATERIAL_TAGS.production_resource_set_to]: material_id
        });

        if (this.state.materials_map.get(material_id) === undefined) {
            await this.getMaterialOptions(material_id);
        }

        this.setState({ is_updating: false });
    };

    getColumns = (): Column<ToolRow>[] => [
        {
            accessor: TOOL_COLUMNS.tool,
            Header: "",
            filterInputPlaceholder: translate("common.tool", "Tool"),
            disableFilters: false,
            Filter: this.renderTextFilter,
            Cell: ToolCell,
            filter: filterTool
        },
        {
            accessor: TOOL_COLUMNS.operation,
            Header: "",
            filterInputPlaceholder: translate("common.next_planned_use", "Next planned use"),
            disableFilters: false,
            Filter: this.renderTextFilter,
            Cell: this.renderOperation,
            filter: filterOperation
        },
        {
            accessor: TOOL_COLUMNS.material,
            Header: translate("common.tool_set_for", "Tool set for"),
            disableFilters: true,
            Cell: this.renderInputMaterial
        },
        {
            accessor: TOOL_COLUMNS.status,
            Header: translate("common.status", "Status"),
            disableFilters: true,
            Cell: this.renderStatus
        }
    ];

    getRows = (): ToolRow[] => {
        const rows: ToolRow[] = [];
        for (const operation of this.state.operations) {
            const tool = this.state.tools_map.get(ORDER_TAGS_ACCESS.production_tool_external_id(operation.tags));
            if (tool !== undefined) {
                const material_id = MATERIAL_TAGS_ACCESS.production_resource_set_to(tool.tags);
                const material = this.state.materials_map.get(material_id) || null;
                const status =
                    MATERIAL_TAGS_ACCESS.production_resource_status(tool.tags) === TOOL_STATUS.maintenance
                        ? TOOL_STATUS.maintenance
                        : TOOL_STATUS.ready;
                const row: ToolRow = {
                    tool,
                    operation,
                    material,
                    status
                };
                rows.push(row);
            }
        }

        return rows;
    };

    getMaterialOptions = async (input_value: string) => {
        const { data: materials } = await getBackend().manufacturing.searchMaterialsSimple({
            external_id: input_value
        });
        const materials_map = new Map(this.state.materials_map);
        for (const material of materials) {
            if (materials_map.get(material.external_id) === undefined) {
                materials_map.set(material.external_id, material);
            }
        }

        this.setState({ materials_map });
        return materials.map(order => mapMaterialOption(order));
    };

    handleInputMaterialChange = async (tool_id: string, option: SelectOption | null) => {
        this.updateToolTags(tool_id, {
            [MATERIAL_TAGS.production_resource_set_to]: option !== null ? option.value : ""
        });
    };

    handleStatusChange = (event: SyntheticEvent<HTMLInputElement>) => {
        this.updateToolTags(event.currentTarget.value, {
            [MATERIAL_TAGS.production_resource_status]: event.currentTarget.checked
                ? TOOL_STATUS.ready
                : TOOL_STATUS.maintenance
        });
    };

    handleTextFilterChange = (event: SyntheticEvent<HTMLInputElement>, column: ColumnInstance<ToolRow>) => {
        const value = event.currentTarget.value;
        const id = column.id;
        column.setFilter(value);

        if (id === TOOL_COLUMNS.tool) {
            this.setState({ tool_filter: value });
        } else if (id === TOOL_COLUMNS.operation) {
            this.setState({ operation_filter: value });
        }
    };

    renderTextFilter = ({ column }: FilterProps<ToolRow>) => {
        const label = column.filterInputPlaceholder;
        const value = column.filterValue !== undefined ? column.filterValue : "";
        const filter = (
            <input
                className="form-control column-filter"
                value={value}
                onChange={event => this.handleTextFilterChange(event, column)}
                placeholder={label}
                title={label}
            />
        );

        return filter;
    };

    renderOperation = ({ column, row, value }: Cell<ToolRow, IOrderModelBase>) => {
        if (value.status === ORDER_STATUS.closed) {
            return "-";
        }

        const search = column.filterValue;
        const is_set_button_enabled =
            !this.state.is_updating &&
            (row.values.material === null || row.values.material.external_id !== value.material_external_id);
        return (
            <dl className="tool-operation">
                <div>
                    <dt>{<MarkedText text={value.external_id} search={search} />}</dt>
                    <dd>
                        <em className="mr-2">{niceDate(new Date(value.earliest_start))} </em>
                        <span>{<MarkedText text={value.line.title} search={search} />}</span>
                    </dd>
                </div>
                <div>
                    <dt>{translate("common.material", "Material")}</dt>
                    <dd>
                        <span>
                            <MarkedText text={value.material_title} search={search} />
                        </span>{" "}
                        <span className="text-muted mr-2">
                            (<MarkedText text={value.material_external_id} search={search} />)
                        </span>
                        <Tooltip
                            content={
                                is_set_button_enabled
                                    ? translate("common.set_tool_for_this_material", "Set tool for this material")
                                    : ""
                            }
                        >
                            <button
                                className="btn btn-short btn-outline-secondary px-2 py-1"
                                onClick={async () => {
                                    this.setTool(row.values.tool.external_id, value.material_external_id);
                                }}
                                disabled={!is_set_button_enabled}
                            >
                                <i className="d-block fas fa-arrow-right" />
                            </button>
                        </Tooltip>
                    </dd>
                </div>
                <div>
                    <dt>{translate("common.operation", "Operation")}</dt>
                    <dd>
                        <span>
                            <MarkedText text={value.title} search={search} />
                        </span>{" "}
                        <span className="text-muted">
                            (<MarkedText text={value.process_num} search={search} />)
                        </span>
                    </dd>
                </div>
            </dl>
        );
    };

    renderInputMaterial = ({ column, row, value }: Cell<ToolRow, IMaterialModel | null>) => {
        const is_match = value !== null && value.external_id === row.values.operation.material_external_id;
        const tooltip = is_match
            ? translate(
                  "common.tool_set_for_order_product",
                  "The tool is set for the product that is being produced on this order"
              )
            : translate(
                  "common.tool_not_set_for_order_product",
                  "The tool is not set for the product that is being produced on this order"
              );
        return (
            <div className="d-flex align-items-center">
                <Tooltip content={tooltip}>
                    <span
                        className={classNames("material-match-indicator mr-3", {
                            "is-match": is_match
                        })}
                    />
                </Tooltip>
                <Tooltip content={value !== null ? `${value.external_id} - ${value.title}` : ""}>
                    <span className="flex-grow-1">
                        <AsyncSelect
                            classNamePrefix="react-select"
                            name="material"
                            placeholder={`${translate("common.search", "Search")}...`}
                            noOptionsMessage={input =>
                                input.inputValue === ""
                                    ? translate("common.search", "Search")
                                    : translate("common.no_materials_found", "No materials found")
                            }
                            loadOptions={this.getMaterialOptions}
                            value={value !== null ? mapMaterialOption(value) : null}
                            isClearable={true}
                            onChange={option => this.handleInputMaterialChange(row.values.tool.external_id, option)}
                            isDisabled={this.state.is_updating}
                        />
                    </span>
                </Tooltip>
            </div>
        );
    };

    renderStatus = ({ row, value }: Cell<ToolRow, ToolStatus>) => {
        return (
            <Tooltip content={value}>
                <label className="text-center mb-0">
                    <Toggle
                        className="d-block"
                        name="status"
                        value={row.values.tool.external_id}
                        checked={value === TOOL_STATUS.ready}
                        onChange={this.handleStatusChange}
                        disabled={this.state.is_updating}
                    />
                </label>
            </Tooltip>
        );
    };

    renderTools = () => {
        if (this.state.is_loading) {
            return <Loader />;
        }

        if (this.state.error !== "") {
            return <ErrorComponent className="my-0" type={"error"} msg={this.state.error} />;
        }

        return (
            <div className="white_box px-0 pt-0 mb-0">
                <ReactTable
                    className="tool-management-table"
                    columns={this.getColumns()}
                    data={this.getRows()}
                    initialState={{
                        pageSize: 25,
                        filters: [
                            {
                                id: TOOL_COLUMNS.tool,
                                value: this.state.tool_filter
                            },
                            {
                                id: TOOL_COLUMNS.operation,
                                value: this.state.operation_filter
                            }
                        ]
                    }}
                    Pagination
                />
            </div>
        );
    };

    render() {
        return (
            <article className="article">
                <section id="statistics" className="data_sources h-100 pb-0">
                    <div className="container-fluid py-3">{this.renderTools()}</div>
                </section>
            </article>
        );
    }
}

export default ToolManagement;
