// @flow
import * as React from "react";

type Props = {
    children: React.Node
};
type State = {};

export default class Grid extends React.Component<Props, State> {

    render() {
        let className = "";
        if (localStorage.getItem("grid-layout") === "true") {
            className = "grid-layout grid-layout-overrides";
        }
        return <div className={className}>
            {this.props.children}
        </div>
    }
}