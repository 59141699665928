// @flow
import type { PayloadAction } from "../../../redux/types";
import * as t from "../../../../lib/backend/manufacturing2.generated.types";

import { RESET_GANTT, RETRY_LINES_ORDERS } from "./common";
import { findOrderIndex, findLineIndex } from "./UnscheduledOrdersHelper";
import { arrayMove } from "react-sortable-hoc";
import { LINE_TAGS, ORDER_TAGS, PLANT_TAGS, UNSCHEDULED_SORT_DIRECTION } from "../../../../lib/ManufacturingTags.generated";
import { ORDER_TYPE, ORDER_GENERATED_BY } from "../../../../lib/ManufacturingConsts.generated";
import { LinesOrdersLogic } from "./BusinessLogic";
import { SET_ALL_AS_UNSCHEDULED, MOVE_ORDER_GANTT_ACTION_NAME } from "./common";
import * as BusinessLogic from "../../../../lib/BusinessLogic"
import { deepClone } from "../../../../lib/Util";
import { LinesOrdersMaps } from "./Mappers";

import type { MoveOrderGanttActionData } from "./common";
import type { ITags } from "../../../../lib/Models";
import type { FilterLinesOrders } from "../../../../lib/GanttData";
import type { ResetGanttAction, SetAllAsUnscheduledAction, RetryLinesOrdersAction } from "./common";


export type ChunkedOrder = {
    uuid: string,
    external_id: string,
    process_num: string,
    quantity_produced: number,
    quantity_total: number,
    parent_order_uuid: string,
    line_uuid: string
}

export type IOrderProducedModelEx = t.IOrderProducedModel & {
    has_production?: boolean,
    unscheduled_highlighted?: boolean,
    unscheduled_selected?: boolean,
    failed_scheduling?: boolean,
    estimated_duration?: number,
    line_title?: string // only filled inside alerts SET_PAST_ORDERS
}

const updateOrderSequenceWeight = (orders: IOrderProducedModelEx[]): IOrderProducedModelEx[] => {
    for (let i = 0; i < orders.length; i++) {
        orders[i].sequence_weight = i + 1;
    }
    return orders;
}

export const saveUnscheduledOrdersLocalStorage = (report_uuid: string, unscheduled_orders: LineOrders[]) => {
    localStorage.setItem(report_uuid + "_unscheduled_orders" , JSON.stringify(unscheduled_orders));
}
export const saveLinesOrdersLocalStorage = (report_uuid: string, lines_orders: LineOrders[]) => {
    localStorage.setItem(report_uuid + "_lines_orders", JSON.stringify(lines_orders));
}

export const getUnscheduledOrdersLocalStorage = (report_uuid: string): LineOrders[] | null => {
    const data = localStorage.getItem(report_uuid + "_unscheduled_orders");
    if (data) {
        return JSON.parse(data);
    }
    return null;
}

export const getLinesOrdersLocalStorage = (report_uuid: string): LineOrders[] | null => {
    const data = localStorage.getItem(report_uuid + "_lines_orders");
    if (data) {
        return JSON.parse(data);
    }
    return null;
}

export type OrderHighlights = {
    is_highlighted: boolean,
    type?: "input" | "order" | "output"
}

export type LineOrders = {
    line_title: string;
    line_uuid: string;
    line_hash: string;
    line_index: number;
    line_external_id: string,
    error?: string | null;
    orders: IOrderProducedModelEx[];
    shifts: t.IShiftLineRec[];
    tags: ITags
};

type UpdateLineOrderActionData = {
    order_uuid: string,
    line_uuid: string,
    order: IOrderProducedModelEx
}
type MoveLineOrderActionData = {
    line_uuid: string,
    old_index: number,
    new_index: number
}

type UpdateShiftsActionData = {
    line_uuid: string,
    shifts: t.IShiftLineRec[]
}

type UnscheduledOrderUuidActionData = {
    line_uuid: string,
    order_uuid: string
}

type UpdateRecommendPlan = {
    plan_uuid: string,
    lines_orders: LineOrders[]
}

type SnapToEarliestStart = {
    order_uuid: string,
    time_start: number
}

type SnapToEarliestEnd = {
    order_uuid: string,
    time_end: number
}

type UpdateAllOrders = {
    lines_orders: LineOrders[],
    unscheduled_orders: LineOrders[],
    plan_uuid: string,
    selected_line_uuids: string[]
}

type UpdateLineOrderCommentData = {
    order_uuid: string,
    comment: string
}

type IgnoreOrdersData = {
    order_uuids: string[]
};

type UnignoreOrdersData = {
    order_uuids: string[]
};

type UpdateUnscheduledOrderData = {
    order: IOrderProducedModelEx,
    run_quick_simulation: boolean
};

type UpdateIgnoredOrderData = {
    order: IOrderProducedModelEx,
    run_quick_simulation: boolean
};

export type OperationLink = [string, string | null];

type LinkOperationsData = {
    links: OperationLink[]
};

type ReworkOrderActionData = {
    rework_order: IOrderProducedModelEx,
    base_order: IOrderProducedModelEx
}

type UnlinkOrderActionData = {
    order_uuid: string
}

type ChunkedOrdersActionData = {
    split_orders: t.ILinePlanningSplitOrder[]
}

type UpdateChunkedOrderActionData = {
    parent_order_uuid: string,
    chunked_orders: $Shape<t.IOrderProducedModel>[]
}

// action constants
export const UPDATE_LINE_ORDERS_ACTION_NAME: "update_line_orders" = "update_line_orders";
export const UPDATE_LINE_ORDER_ACTION_NAME: "update_line_order" = "update_line_order";
export const MOVE_LINE_ORDER_ACTION_NAME: "move_line_order" = "move_line_order";
export const LINE_ORDERS_LOADING_ACTION_NAME: "line_orders_loading" = "line_orders_loading";
export const UPDATE_LINES_ORDERS_ACTION_NAME: "update_lines_orders" = "update_lines_orders";
export const UPDATE_SHIFTS_ACTION_NAME: "update_shifts" = "update_shifts";
export const RESET_LINES_ORDERS_ACTION_NAME: "reset_lines_orders" = "reset_lines_orders";
export const SET_IGNORED_ORDERS_ACTION_NAME: "ignored_orders" = "ignored_orders";
export const SET_UNSCHEDULED_ORDERS_ACTION_NAME: "unscheduled_orders" = "unscheduled_orders";
export const SET_UNSCHEDULED_ORDER_UUID: "set_unscheduled_order_uuid" = "set_unscheduled_order_uuid";
export const SET_FILTERED_OUT_ORDERS: "set_filtered_out_orders" = "set_filtered_out_orders";
export const UPDATE_LINES_ORDERS_PLAN_ACTION: "recommend_plan_lines_orders" = "recommend_plan_lines_orders";
export const RESET_PLAN_UUID: "reset_plan_uuid" = "reset_plan_uuid";
export const LOAD_VERSION: "load_version" = "load_version";
export const SNAP_TO_EARLIEST_START: "snap_to_earliest_start" = "snap_to_earliest_start";
export const SNAP_TO_EARLIEST_END: "snap_to_earliest_end" = "snap_to_earliest_end";
export const UPDATE_ALL_ORDERS_RECOMMEND_PLAN: "update_all_orders_recommend_plan" = "update_all_orders_recommend_plan";
export const RECALCULATE_REPORT: "recalculate_report" = "recalculate_report";
export const UPDATE_LINE_ORDER_COMMENT: "comment" = "comment";
export const UPDATE_UNSCHEDULED_ORDER: "update_unscheduled_order" = "update_unscheduled_order";
export const UPDATE_IGNORED_ORDER: "update_ignored_order" = "update_ignored_order";
export const IGNORE_ORDERS: "ignore_orders" = "ignore_orders";
export const UNIGNORE_ORDERS: "unignore_orders" = "unignore_orders";
export const CLEAR_IGNORE_ORDERS_STATUS: "clear_ignore_orders_status" = "clear_ignore_orders_status";
export const UPDATE_OPERATION_LINKS: "update_operation_links" = "update_operation_links";
export const CLEAR_EXTRA_LINES_OPERATION_LINKS: "clear_extra_lines_operation_links" = "clear_extra_lines_operation_links";
export const REWORK_ORDER_ACTION_NAME: "rework_order_action" = "rework_order_action";
export const UNLINK_ORDER: "unlink_order" = "unlink_order";
export const SET_CHUNKED_ORDERS: "set_chunked_order" = "set_chunked_order";
export const UPDATE_CHUNKED_ORDERS: "update_chunked_order" = "update_chunked_order";

// action types
export type UpdateLineOrdersAction = PayloadAction<typeof UPDATE_LINE_ORDERS_ACTION_NAME, LineOrders>;
export type UpdateLineOrderAction = PayloadAction<typeof UPDATE_LINE_ORDER_ACTION_NAME, UpdateLineOrderActionData>;
export type MoveLineOrderAction = PayloadAction<typeof MOVE_LINE_ORDER_ACTION_NAME, MoveLineOrderActionData>;
export type MoveOrderGanttAction = PayloadAction<typeof MOVE_ORDER_GANTT_ACTION_NAME, MoveOrderGanttActionData>;
export type LoadingAction = PayloadAction<typeof LINE_ORDERS_LOADING_ACTION_NAME, boolean>;
export type UpdateLinesOrdersAction = PayloadAction<typeof UPDATE_LINES_ORDERS_ACTION_NAME, LineOrders[]>;
export type UpdateShiftsAction = PayloadAction<typeof UPDATE_SHIFTS_ACTION_NAME, UpdateShiftsActionData>;
export type SetIgnoredOrdersAction = PayloadAction<typeof SET_IGNORED_ORDERS_ACTION_NAME, LineOrders[]>;
export type SetUnscheduledOrdersAction = PayloadAction<typeof SET_UNSCHEDULED_ORDERS_ACTION_NAME, LineOrders[]>;
export type SetUnscheduledOrderUuidAction = PayloadAction<typeof SET_UNSCHEDULED_ORDER_UUID, UnscheduledOrderUuidActionData>;
export type ResetLinesOrdersAction = PayloadAction<typeof RESET_LINES_ORDERS_ACTION_NAME, typeof undefined>;
export type SetFilteredOutAction = PayloadAction<typeof SET_FILTERED_OUT_ORDERS, FilterLinesOrders[]>;
export type UpdateLinesOrdersPlanAction = PayloadAction<typeof UPDATE_LINES_ORDERS_PLAN_ACTION, UpdateRecommendPlan>;
export type ResetPlanUuid = PayloadAction<typeof RESET_PLAN_UUID, typeof undefined>;
export type LoadVersionAction = PayloadAction<typeof LOAD_VERSION, string>;
export type SnapToEarliestStartAction = PayloadAction<typeof SNAP_TO_EARLIEST_START, SnapToEarliestStart>;
export type SnapToEarliestEndAction = PayloadAction<typeof SNAP_TO_EARLIEST_END, SnapToEarliestEnd>;
export type UpdateAllOrdersRecommendPlanAction = PayloadAction<typeof UPDATE_ALL_ORDERS_RECOMMEND_PLAN, UpdateAllOrders>;
export type RecalculateReport = PayloadAction<typeof RECALCULATE_REPORT, typeof undefined>;
export type UpdateLineOrderComment = PayloadAction<typeof UPDATE_LINE_ORDER_COMMENT, UpdateLineOrderCommentData>;
export type UpdateUnscheduledOrder = PayloadAction<typeof UPDATE_UNSCHEDULED_ORDER, UpdateUnscheduledOrderData>;
export type UpdateIgnoredOrder = PayloadAction<typeof UPDATE_IGNORED_ORDER, UpdateIgnoredOrderData>;
export type IgnoreOrdersAction = PayloadAction<typeof IGNORE_ORDERS, IgnoreOrdersData>;
export type UnignoreOrdersAction = PayloadAction<typeof UNIGNORE_ORDERS, UnignoreOrdersData>;
export type ClearIgnoreOrdersStatusAction = PayloadAction<typeof CLEAR_IGNORE_ORDERS_STATUS, void>;
export type UpdateOperationLinksAction = PayloadAction<typeof UPDATE_OPERATION_LINKS, LinkOperationsData>;
export type ClearExtraLinesOperationLinksAction = PayloadAction<typeof CLEAR_EXTRA_LINES_OPERATION_LINKS, void>;
export type ReworkOrderAction = PayloadAction<typeof REWORK_ORDER_ACTION_NAME, ReworkOrderActionData>;
export type UnlinkOrderAction = PayloadAction<typeof UNLINK_ORDER, UnlinkOrderActionData>;
export type ChunkedOrdersAction = PayloadAction<typeof SET_CHUNKED_ORDERS, ChunkedOrdersActionData>;
export type UpdateChunkedOrderAction = PayloadAction<typeof UPDATE_CHUNKED_ORDERS, UpdateChunkedOrderActionData>;

export type LineOrdersActionsTypes = (
    UpdateLineOrdersAction | UpdateLineOrderAction | MoveLineOrderAction | MoveOrderGanttAction | LoadingAction |
    UpdateLinesOrdersAction | ResetLinesOrdersAction | UpdateShiftsAction | SetUnscheduledOrdersAction | SetIgnoredOrdersAction |
    SetUnscheduledOrderUuidAction | SetFilteredOutAction | ResetGanttAction | UpdateLinesOrdersPlanAction |
    ResetPlanUuid | LoadVersionAction | SetAllAsUnscheduledAction | SnapToEarliestStartAction | SnapToEarliestEndAction |
    RecalculateReport | UpdateAllOrdersRecommendPlanAction | UpdateLineOrderComment | UpdateUnscheduledOrder | UpdateIgnoredOrder |
    RetryLinesOrdersAction | IgnoreOrdersAction | UnignoreOrdersAction | ClearIgnoreOrdersStatusAction |
    ReworkOrderAction | UnlinkOrderAction | UpdateOperationLinksAction | ClearExtraLinesOperationLinksAction
);

type OriginalOrdersMapValue = {
    line_uuid: string,
    earliest_start: string
}
type OriginalOrdersMap = Map<string, OriginalOrdersMapValue> | null;

export type IgnoreOrdersMap = Map<string, boolean>;

export type LinesOrdersState = {
    loading: boolean,
    plan_uuid: string | null,
    lines_orders: LineOrders[],
    unscheduled_orders: LineOrders[],
    ignored_orders: LineOrders[],
    original_orders: OriginalOrdersMap,
    original_lines_orders: LineOrders[] | null,
    original_unscheduled_orders: LineOrders[] | null,
    updated_order_uuid: string | null,
    ignore_orders_status: IgnoreOrdersMap | null,
    filtered_orders: string[],
    extra_lines_operation_links: OperationLink[],
    split_orders: t.ILinePlanningSplitOrder[]
}

export const lines_orders_initial_state: LinesOrdersState = {
    loading: false,
    plan_uuid: null,
    lines_orders: [],
    unscheduled_orders: [],
    ignored_orders: [],
    original_orders: null,
    original_lines_orders: null,
    original_unscheduled_orders: null,
    updated_order_uuid: null,
    ignore_orders_status: null,
    filtered_orders: [],
    extra_lines_operation_links: [],
    split_orders: []
}

export const sortUnscheduled = (unscheduled_orders: IOrderProducedModelEx[]): IOrderProducedModelEx[] => {
    const plant_uuid = localStorage.getItem("last-plant");
    let sort_direction = UNSCHEDULED_SORT_DIRECTION.sort_by_earliest_start;

    if (plant_uuid) {
        sort_direction = BusinessLogic.getPlantTagStr(
            plant_uuid,
            PLANT_TAGS.planning_table_sort_direction,
            UNSCHEDULED_SORT_DIRECTION.sort_by_earliest_start
        );
    }
    if (sort_direction === UNSCHEDULED_SORT_DIRECTION.group_by_process_num) {
        const order_map = new Map<string, number>();
        for (const order of unscheduled_orders) {
            const earliest_start = order_map.get(order.external_id);
            if (earliest_start === undefined) {
                order_map.set(order.external_id, order.earliest_start);
                continue;
            }
            if (order.earliest_start < earliest_start) {
                order_map.set(order.external_id, order.earliest_start);
            }
        }
        return unscheduled_orders.sort((a, b) => {
            const a_earliest_start = order_map.get(a.external_id) || 0;
            const b_earliest_start = order_map.get(b.external_id) || 0;

            if (a_earliest_start < b_earliest_start) {
                return -1;
            } else if (a_earliest_start > b_earliest_start) {
                return 1;
            }
            if (a.external_id < b.external_id) {
                return -1;
            } else if (a.external_id > b.external_id) {
                return 1;
            }
            if (a.process_num < b.process_num) {
                return -1;
            } else if (a.process_num > b.process_num) {
                return 1;
            }
            return 0;
        });
    }

    return unscheduled_orders.sort((a, b) => {
        if (a.earliest_start < b.earliest_start) {
            return -1;
        }
        return 1;
    });
}

const sortUnscheduledLineOrders = (unscheduled_orders: LineOrders[]): LineOrders[] => {
    for (const unscheduled_line of unscheduled_orders) {
        unscheduled_line.orders = unscheduled_line.orders.sort((a, b) => {
            if (a.earliest_start > b.earliest_start) {
                return -1
            }
            return 1;
        });
    }
    return unscheduled_orders;
}

export const reworkSort = (a: any, b: any): number => {
    if (a.uuid < b.uuid) {
        return -1;
    }
    return 1;
}

type NewLineOrders = {
    new_lines_orders: LineOrders[],
    new_unscheduled_orders: LineOrders[],
}
const removeExistingReworkOrders = (
    rework_order_uuids: string[],
    state_lines_orders: LineOrders[],
    state_unscheduled_orders: LineOrders[]
): NewLineOrders => {
    for (const rework_order_uuid of rework_order_uuids) {
        for (const line of state_lines_orders) {
            for (const order of line.orders) {
                if (order.uuid !== rework_order_uuid) {
                    continue;
                }
                delete order.tags[ORDER_TAGS.linked_operation];
            }
        }
        for (const line of state_unscheduled_orders) {
            for (const order of line.orders) {
                if (order.uuid !== rework_order_uuid) {
                    continue;
                }
                delete order.tags[ORDER_TAGS.linked_operation];
            }
        }
    }

    return {
        new_lines_orders: state_lines_orders,
        new_unscheduled_orders: state_unscheduled_orders
    }
}



const updateReworkOrder = (
    base_order: IOrderProducedModelEx,
    rework_order: IOrderProducedModelEx,
    state_lines_orders: LineOrders[],
    state_unscheduled_orders: LineOrders[],
    state_ignored_orders: LineOrders[],
    state_ignore_orders_status: IgnoreOrdersMap | null
) => {
    let lines_orders = deepClone(state_lines_orders);
    let unscheduled_orders = deepClone(state_unscheduled_orders);
    let ignored_orders = deepClone(state_ignored_orders);
    const ignore_orders_status = state_ignore_orders_status ? new Map(state_ignore_orders_status) : new Map();

    let base_line = lines_orders.find(l => l.orders.find(o => o.uuid === base_order.uuid));
    if (!base_line) {
        base_line = unscheduled_orders.find(l => l.orders.find(o => o.uuid === base_order.uuid));
    }
    let rework_line = lines_orders.find(l => l.orders.find(o => o.uuid === rework_order.uuid));
    if (!rework_line) {
        rework_line = unscheduled_orders.find(l => l.orders.find(o => o.uuid === rework_order.uuid));
    }
    if (!rework_line) {
        rework_line = ignored_orders.find(l => l.orders.find(o => o.uuid === rework_order.uuid));
    }

    rework_line.orders.splice(rework_line.orders.findIndex(o => o.uuid === rework_order.uuid), 1);
    rework_line.orders = updateOrderSequenceWeight(rework_line.orders);
    rework_order.skip_sim = base_order.skip_sim;
    rework_order.time_start = base_order.time_start;
    rework_order.tags[ORDER_TAGS.subline_index] = base_order.tags[ORDER_TAGS.subline_index];
    rework_order.tags[ORDER_TAGS.linked_operation] = base_order.uuid;
    rework_order.tags[ORDER_TAGS.ignore_on_planning_board] = "false";
    ignore_orders_status.set(rework_order.uuid, false);
    rework_order.line_uuid = base_order.line_uuid;

    const base_order_index = base_line.orders.findIndex(o => o.uuid === base_order.uuid);
    const last_rework_order_index = base_line.orders.findIndex(
        order => order.tags[ORDER_TAGS.linked_operation] === base_order.uuid
    );

    if (last_rework_order_index === -1) {
        rework_order.sequence_weight = base_order.sequence_weight + 0.1;
        base_line.orders.splice(base_order_index + 1, 0, rework_order);
    } else {
        rework_order.sequence_weight = base_order.sequence_weight + (0.1) * (last_rework_order_index - base_order_index);
        base_line.orders.splice(last_rework_order_index + 1, 0, rework_order);
        const rework_subset = base_line.orders.slice(base_order_index + 1, last_rework_order_index + 1);
        rework_subset.sort(reworkSort);
        base_line.orders.splice(base_order_index + 1, rework_subset.length, ...rework_subset);
    }

    base_line.orders = updateOrderSequenceWeight(base_line.orders);

    return {
        lines_orders,
        unscheduled_orders,
        ignored_orders,
        ignore_orders_status
    }
}


export const linesOrdersReducerCore = (state: LinesOrdersState, action: LineOrdersActionsTypes): LinesOrdersState => {
    if (!state) state = deepClone(lines_orders_initial_state);
    let state_lines_orders: LineOrders[] = state.lines_orders;
    let line_index;

    if (action.type === RESET_GANTT) {
        LinesOrdersMaps.clearMaps();
        return deepClone(lines_orders_initial_state);
    } else if (action.type === RETRY_LINES_ORDERS) {
        return {
            ...state,
            lines_orders: deepClone(state_lines_orders),
        }
    } else if (action.type === LINE_ORDERS_LOADING_ACTION_NAME) {
        if (action.data === true) {
            state_lines_orders = [];
        }
        return { ...state, loading: action.data, lines_orders: state_lines_orders };
    } else if (action.type === UPDATE_LINES_ORDERS_ACTION_NAME) {
        const lines_orders = action.data;
        for (const line_orders of lines_orders) {
            for (let i = 0; i < line_orders.orders.length; i++) {
                line_orders.orders[i].sequence_weight = i + 1;
            }
        }
        state_lines_orders = lines_orders;
        if (!state.original_lines_orders) {
            state.original_lines_orders = deepClone(state_lines_orders);
        }

        return {
            ...state,
            lines_orders: state_lines_orders,
            original_lines_orders: state.original_lines_orders

        }
    } else if (action.type === UPDATE_LINES_ORDERS_PLAN_ACTION) {
        const { lines_orders, plan_uuid } = action.data;
        for (const line_orders of lines_orders) {
            for (let i = 0; i < line_orders.orders.length; i++) {
                line_orders.orders[i].sequence_weight = i + 1;
            }
        }
        state_lines_orders = lines_orders;
        return { ...state, plan_uuid: plan_uuid, lines_orders: state_lines_orders };
    } else if (action.type === UPDATE_LINE_ORDERS_ACTION_NAME) {
        const data: LineOrders = action.data;
        line_index = state_lines_orders.findIndex(l => l.line_uuid === data.line_uuid);
        if (line_index >= 0) {
            state_lines_orders[line_index] = action.data;
        } else {
            const line_order = action.data;
            line_order.line_index = line_index;
            state_lines_orders.push(action.data);
        }
        state_lines_orders = [...state_lines_orders];
    } else if (action.type === UPDATE_LINE_ORDER_ACTION_NAME) {
        const data: UpdateLineOrderActionData = action.data;
        line_index = state_lines_orders.findIndex(l => l.line_uuid === data.line_uuid);
        if (line_index >= 0) {
            const order_index = state_lines_orders[line_index].orders.findIndex(o => o.uuid === data.order_uuid);

            if (order_index >= 0) {
                state_lines_orders[line_index].orders[order_index] = {...data.order};
                state_lines_orders = [...state_lines_orders];
            }
        }
    } else if (action.type === UPDATE_SHIFTS_ACTION_NAME) {
        const data: UpdateShiftsActionData = action.data;
        line_index = state_lines_orders.findIndex(l => l.line_uuid === data.line_uuid);
        if (line_index >= 0) {
            const lines_orders = deepClone(state_lines_orders);
            lines_orders[line_index].shifts = deepClone(data.shifts);

            return { ...state, lines_orders }
        }
    } else if (action.type === MOVE_LINE_ORDER_ACTION_NAME) {
        const data: MoveLineOrderActionData = action.data;
        line_index = state_lines_orders.findIndex(l => l.line_uuid === data.line_uuid);
        if (line_index >= 0) {
            const { old_index, new_index } = action.data;
            state_lines_orders[line_index].orders = arrayMove(state_lines_orders[line_index].orders, old_index, new_index);
            state_lines_orders[line_index].orders[new_index].sim_ignore_earliest_start = true;
            // update sequence weight
            const orders = updateOrderSequenceWeight(state_lines_orders[line_index].orders);
            state_lines_orders[line_index].orders = orders;
            state_lines_orders = [...state_lines_orders];
        }
    } else if (action.type === MOVE_ORDER_GANTT_ACTION_NAME) {
        const {
            original_line_uuid,
            new_line_uuid,
            new_order_index,
            earliest_start,
            is_unscheduled_order,
            order_uuid,
            production_version,
            override_earliest_start
        } = action.data;

        let new_line_index = state_lines_orders.findIndex(l => l.line_uuid === new_line_uuid);

        let original_line_index;
        let original_order_index;
        if (is_unscheduled_order) {
            original_line_index = new_line_index;
            original_order_index = new_order_index;
        } else {
            original_line_index = state_lines_orders.findIndex(l => l.line_uuid === original_line_uuid);
            original_order_index = LinesOrdersLogic.findOrderIndexLinesOrders(state_lines_orders, order_uuid);
            if (original_order_index === -1) {
                original_order_index = new_order_index;
            }
        }

        let original_orders = state_lines_orders[original_line_index].orders;
        let rework_orders = [];

        let base_order = null;

        if (is_unscheduled_order) {
            const unscheduled_order_index = findOrderIndex(order_uuid, state.unscheduled_orders);
            const unscheduled_line_index = findLineIndex(order_uuid, state.unscheduled_orders);

            let unscheduled_order = state.unscheduled_orders[unscheduled_line_index].orders[unscheduled_order_index];
            unscheduled_order.skip_sim = false;
            if (production_version !== undefined) {
                unscheduled_order.tags[ORDER_TAGS.production_version] = production_version;
            }
            if (action.data.process_uuid !== undefined) {
                const process_uuid = action.data.process_uuid;
                if (process_uuid) {
                    unscheduled_order.process_uuid = process_uuid;
                }
            }
            delete unscheduled_order.tags[ORDER_TAGS.fix_line];

            // original line index is the default line set for the unscheduled order
            // insert order at original_order_index index (usually 0)
            original_orders.splice(original_order_index, 0, deepClone(unscheduled_order));

            rework_orders = state.unscheduled_orders[unscheduled_line_index].orders.filter(
                o => o.tags[ORDER_TAGS.linked_operation] === order_uuid
            );
            // delete order from unscheduled orders
            state.unscheduled_orders[unscheduled_line_index].orders.splice(unscheduled_order_index, 1);
            state.unscheduled_orders = deepClone(state.unscheduled_orders);
        } else {
            const original_line = state.lines_orders.find(l => l.line_uuid === original_line_uuid);
            if (original_line) {
                rework_orders = original_line.orders.filter(
                    o => o.tags[ORDER_TAGS.linked_operation] === order_uuid
                );
            }
        }

        if (original_line_index === new_line_index) {
            original_orders = arrayMove(original_orders, original_order_index, new_order_index).filter(el => el);
            const new_order = original_orders[new_order_index];
            base_order = new_order;
            if (!new_order) return state;
            const is_overlaping = state_lines_orders[original_line_index].tags[LINE_TAGS.overlapping_orders_line] === "true";
            if ((new_order_index >= 0 && new_order_index < original_orders.length) && earliest_start === undefined) {
                new_order.sim_ignore_earliest_start = true;
                delete new_order.time_start;
                if (override_earliest_start && is_overlaping) {
                    new_order.earliest_start = override_earliest_start;
                    new_order.time_start = override_earliest_start;
                }
            } else if (earliest_start) {
                new_order.sim_ignore_earliest_start = false;
                //new_order.earliest_end = (new_order.earliest_end - new_order.earliest_start) + earliest_start
                new_order.earliest_start = earliest_start;
                new_order.time_start = earliest_start;
            }

            const line_index = action.data.line_index;
            if (line_index != undefined) {
                new_order.tags[ORDER_TAGS.subline_index] = line_index;
            }
            original_orders[new_order_index] = new_order;
            state_lines_orders[original_line_index] = {
                ...state_lines_orders[original_line_index],
                orders: [...updateOrderSequenceWeight(original_orders)]
            }

        } else {
            const original_order: IOrderProducedModelEx = deepClone(original_orders[original_order_index]);
            base_order = original_order;
            let new_orders = state_lines_orders[new_line_index].orders;
            const is_overlaping = state_lines_orders[new_line_index].tags[LINE_TAGS.overlapping_orders_line] === "true";
            // set 24/7 if order is between two orders
            if ((new_order_index >= 0 && new_order_index < new_orders.length) && earliest_start === undefined) {
                original_order.sim_ignore_earliest_start = true;
                delete original_order.time_start;
                if (override_earliest_start && is_overlaping) {
                    original_order.earliest_start = override_earliest_start;
                    original_order.time_start = override_earliest_start;
                }
            } else if (earliest_start) {
                original_order.sim_ignore_earliest_start = false;
                //original_order.earliest_end = (original_order.earliest_end - original_order.earliest_start) + earliest_start;
                original_order.earliest_start = earliest_start;
                original_order.time_start = earliest_start;
            }
            const process_uuid = action.data.process_uuid;
            if (process_uuid) {
                original_order.process_uuid = process_uuid;
            }
            const process_num = action.data.process_num;
            if (process_num) {
                original_order.process_num = process_num;
            }
            if (production_version != undefined) {
                original_order.tags[ORDER_TAGS.production_version] = production_version;
            }
            const line_index = action.data.line_index;
            if (line_index != undefined) {
                original_order.tags[ORDER_TAGS.subline_index] = line_index + "";
            }
            delete original_order.tags[ORDER_TAGS.fix_line];

            // TODO - check if this is ok
            original_order.line_uuid = state_lines_orders[new_line_index].line_uuid;

            // remove order from previous lines
            original_orders.splice(original_order_index, 1)
            // insert order at specific index
            new_orders.splice(new_order_index, 0,  {...original_order});

            // update sequence weight of orders
            new_orders = [...updateOrderSequenceWeight(new_orders)]

            state_lines_orders[new_line_index] = {
                ...state_lines_orders[new_line_index],
                orders: new_orders
            };
        }

        let state_unscheduled_orders = state.unscheduled_orders;
        const rework_order_uuids = rework_orders.map(o => o.uuid);
        // we need to update rework orders
        let {
            new_lines_orders,
            new_unscheduled_orders
        } = removeExistingReworkOrders(rework_order_uuids, state_lines_orders, state_unscheduled_orders);

        state_lines_orders = new_lines_orders;
        state_unscheduled_orders = new_unscheduled_orders;

        for (const rework_order of rework_orders) {
            let {
                lines_orders: lo,
                unscheduled_orders: uo,
                ignored_orders: io,
                ignore_orders_status: ios
            } = updateReworkOrder(
                base_order,
                rework_order,
                state_lines_orders,
                state_unscheduled_orders,
                state.ignored_orders,
                state.ignore_orders_status
            )
            state_lines_orders = lo;
            state_unscheduled_orders = uo;
            state.ignored_orders = io;
            state.ignore_orders_status = ios;
        }

        return {
            ...state,
            lines_orders: deepClone(state_lines_orders),
            unscheduled_orders: state_unscheduled_orders
        }
    } else if (action.type === RESET_LINES_ORDERS_ACTION_NAME) {
        return deepClone(lines_orders_initial_state);
    } else if (action.type === SET_IGNORED_ORDERS_ACTION_NAME) {
        return {
            ...state,
            ignored_orders: deepClone(action.data)
        }
    } else if (action.type === SET_UNSCHEDULED_ORDERS_ACTION_NAME) {
        state.unscheduled_orders = deepClone(action.data);
        if (!state.original_unscheduled_orders) {
            state.original_unscheduled_orders = deepClone(state.unscheduled_orders);
        }
        state = { ...state };
    } else if (action.type === SET_UNSCHEDULED_ORDER_UUID) {
        const data: UnscheduledOrderUuidActionData = action.data;
        const line_index = state_lines_orders.findIndex(line => line.line_uuid === data.line_uuid);
        if (line_index >= 0) {
            const line = state_lines_orders[line_index];
            const order_index = line.orders.findIndex(el => el.uuid === data.order_uuid);

            if (order_index >= 0) {
                const order = line.orders[order_index];
                let unscheduled_line_index = state.unscheduled_orders.findIndex(u => u.line_uuid === data.line_uuid);
                if (unscheduled_line_index === -1) {
                    const new_line = { ...line, orders: [] };
                    state.unscheduled_orders.push(new_line);
                    unscheduled_line_index = state.unscheduled_orders.length - 1;
                }
                // insert new order into unscheduled_orders array
                state.unscheduled_orders[unscheduled_line_index].orders.push(order);
                // delete order from existing line
                state_lines_orders[line_index].orders.splice(order_index, 1);
                // sync linked operations
                const rework_orders = line.orders.filter(o => o.tags[ORDER_TAGS.linked_operation] === data.order_uuid);
                for (const rework_order of rework_orders) {
                    rework_order.skip_sim = false;
                    const rework_order_index = line.orders.findIndex(o => o.uuid === rework_order.uuid);
                    if (rework_order_index >= 0) {
                        line.orders.splice(rework_order_index, 1);
                        state.unscheduled_orders[unscheduled_line_index].orders.push(rework_order);
                    }
                }
                state_lines_orders[line_index].orders = deepClone(state_lines_orders[line_index].orders);
                // new reference of state_lines_orders will fire recalculate
                state_lines_orders = deepClone(state_lines_orders);
                // new reference of unscheduled orders will redraw linesorders list
                state.unscheduled_orders = deepClone(state.unscheduled_orders);
                return { ...state, lines_orders: state_lines_orders };
            }
        }
    } else if (action.type === UPDATE_UNSCHEDULED_ORDER) {
        const data = action.data;
        let unscheduled_orders = state.unscheduled_orders;
        let found = false;
        for (let i = 0; i < unscheduled_orders.length; i++) {
            const line = unscheduled_orders[i];
            for (let j = 0; j < line.orders.length; j++) {
                const order = line.orders[j];
                if (order.uuid === data.order.uuid) {
                    line.orders[j] = { ...data.order };
                    unscheduled_orders = [...unscheduled_orders];
                    found = true;
                    break;
                }
            }

            if (found) {
                break;
            }
        }

        let lines_orders = state_lines_orders;
        if (data.run_quick_simulation) {
            lines_orders = [...lines_orders];
        }
        return {
            ...state,
            lines_orders,
            unscheduled_orders
        };
    } else if (action.type === SET_FILTERED_OUT_ORDERS) {
        const filtered_orders = action.data;
        const unscheduled_orders = state.unscheduled_orders;
        for (const line of filtered_orders) {
            const filtered_order_uuids = line.order_uuids;

            // filter scheduled line
            const new_line = state_lines_orders.find(l => l.line_uuid === line.line_uuid);
            if (new_line && new_line.orders.length > 0) {
                new_line.orders = deepClone(new_line.orders.filter(o => !filtered_order_uuids.includes(o.uuid)));
            }
            // filter unscheduled line
            const unscheduled_line = unscheduled_orders.find(l => l.line_uuid === line.line_uuid);
            if (unscheduled_line && unscheduled_line.orders.length > 0) {
                unscheduled_line.orders = deepClone(unscheduled_line.orders.filter(o => !filtered_order_uuids.includes(o.uuid)));
            }
        }

        const new_filtered_orders: Set<string> = new Set(state.filtered_orders);
        for (const line of filtered_orders) {
            for (const order_uuid of line.order_uuids) {
                new_filtered_orders.add(order_uuid);
            }
        }

        return {
            ...state,
            lines_orders: state_lines_orders,
            unscheduled_orders: deepClone(unscheduled_orders),
            filtered_orders: [...new_filtered_orders]
        };
    } else if (action.type === RESET_PLAN_UUID) {
        return { ...state, plan_uuid: null, lines_orders: state_lines_orders };
    } else if (action.type === LOAD_VERSION) {
        const lines_orders_version_data = getLinesOrdersLocalStorage(action.data);
        if (lines_orders_version_data) {
            state_lines_orders = lines_orders_version_data;
        }
        const unscheduled_orders_version_data = getUnscheduledOrdersLocalStorage(action.data);
        if (unscheduled_orders_version_data) {
            state.unscheduled_orders = unscheduled_orders_version_data;
        }
    } else if (action.type === SET_ALL_AS_UNSCHEDULED) {
        const new_lines_orders = deepClone(state_lines_orders);
        for (const line of new_lines_orders) {
            line.orders = line.orders.filter(o => !LinesOrdersLogic.isOrderReadOnly(line, o));
        }
        const new_unscheduled_orders: LineOrders[] = state.unscheduled_orders.concat(new_lines_orders);
        for (const line of state_lines_orders) {
            line.orders = line.orders.filter(o => LinesOrdersLogic.isOrderReadOnly(line, o));
        }
        return {
            ...state,
            lines_orders: deepClone(state_lines_orders),
            unscheduled_orders: new_unscheduled_orders
        }
    } else if (action.type === SNAP_TO_EARLIEST_START) {
        const { order_uuid, time_start } = action.data;
        for (const line_orders of state_lines_orders) {
            for (const order of line_orders.orders) {
                if (order.uuid === order_uuid) {
                    order.earliest_start = time_start;
                    order.time_start = time_start;
                    order.sim_ignore_earliest_start = false;
                    break;
                }
            }
        }
        return {
            ...state,
            lines_orders: deepClone(state_lines_orders)
        };
    } else if (action.type === SNAP_TO_EARLIEST_END) {
        const { order_uuid, time_end } = action.data;
        for (const line_orders of state_lines_orders) {
            for (const order of line_orders.orders) {
                if (order.uuid === order_uuid) {
                    order.earliest_start = time_end;
                    order.time_start = time_end;
                    order.sim_ignore_earliest_start = false;
                    break;
                }
            }
        }
        return {
            ...state,
            lines_orders: deepClone(state_lines_orders)
        };
    } else if (action.type === UPDATE_ALL_ORDERS_RECOMMEND_PLAN) {
        const {
            plan_uuid,
            unscheduled_orders,
            selected_line_uuids
        } = action.data;

        let lines_orders = action.data.lines_orders;
        if (!selected_line_uuids || selected_line_uuids.length === 0) {
            for (const line_orders of lines_orders) {
                for (let i = 0; i < line_orders.orders.length; i++) {
                    line_orders.orders[i].sequence_weight = i + 1;
                }
            }
        } else {
            const new_lines_orders = [];
            for (let line_orders of state.lines_orders) {
                if (selected_line_uuids.includes(line_orders.line_uuid)) {
                    const overwrite_lines_orders = lines_orders.find(l => line_orders.line_uuid === l.line_uuid);
                    if (overwrite_lines_orders) {
                        for (let i = 0; i < overwrite_lines_orders.orders.length; i++) {
                            overwrite_lines_orders.orders[i].sequence_weight = i + 1;
                        }
                        new_lines_orders.push(overwrite_lines_orders)
                    }
                } else {
                    new_lines_orders.push(line_orders);
                }
            }
            lines_orders = deepClone(new_lines_orders);
        }

        const new_unscheduled_orders = sortUnscheduledLineOrders(deepClone(unscheduled_orders));
        if (!state.original_unscheduled_orders) {
            state.original_unscheduled_orders = deepClone(new_unscheduled_orders);
        }
        return {
            ...state,
            plan_uuid,
            lines_orders: deepClone(lines_orders),
            unscheduled_orders: new_unscheduled_orders
        };
    } else if (action.type === RECALCULATE_REPORT) {
        state_lines_orders = deepClone(state_lines_orders);
    } else if (action.type === UPDATE_LINE_ORDER_COMMENT) {
        const { order_uuid, comment } = action.data;
        let found_order = false;
        for (const line of state_lines_orders) {
            for (let order of line.orders) {
                if (order.uuid === order_uuid) {
                    found_order = true;
                    order.tags[ORDER_TAGS.comment] = comment;
                    order = deepClone(order);
                    break;
                }
            }
        }
        if (!found_order) {
            for (const line of state.unscheduled_orders) {
                let order = line.orders.find(o => o.uuid === order_uuid);
                if (order) {
                    order.tags[ORDER_TAGS.comment] = comment;
                    order = deepClone(order);
                    break;
                }
            }
        }

        return {
            ...state,
            lines_orders: state_lines_orders,
            updated_order_uuid: order_uuid
        };
    } else if (action.type === IGNORE_ORDERS) {
        const ignore_orders_status: IgnoreOrdersMap = state.ignore_orders_status !== null
            ? new Map(state.ignore_orders_status)
            : new Map();
        for (const order_uuid of action.data.order_uuids) {
            ignore_orders_status.set(order_uuid, true);
            const rework_orders = LinesOrdersMaps.base_uuid_rework_orders.get(order_uuid) || [];
            for (const rework_order of rework_orders) {
                delete rework_order.tags[ORDER_TAGS.linked_operation]
            }
        }

        const ignored_orders_map: Map<string, [LineOrders, Map<string, IOrderProducedModelEx>]> = new Map();
        for (const line of state.ignored_orders) {
            const orders_map: Map<string, IOrderProducedModelEx> = new Map();
            for (const order of line.orders) {
                orders_map.set(order.uuid, order);
            }

            ignored_orders_map.set(line.line_uuid, [line, orders_map]);
        }

        const ignoreOrders = (lines_orders: LineOrders[], skip_empty_lines = false): LineOrders[] => {
            const new_lines_orders: LineOrders[] = [];
            for (const line of lines_orders) {
                const new_line: LineOrders = {
                    ...line,
                    orders: []
                }

                let [line_orders, orders_map] = ignored_orders_map.get(line.line_uuid) || [line, undefined];
                for (const order of line.orders) {
                    if (ignore_orders_status.get(order.uuid) === true) {
                        if (orders_map === undefined) {
                            orders_map = new Map();
                        }

                        order.skip_sim = true;
                        order.tags[ORDER_TAGS.ignore_on_planning_board] = "true";
                        delete order.tags[ORDER_TAGS.linked_operation];
                        orders_map.set(order.uuid, order);
                    } else {
                        new_line.orders.push(order);
                    }
                }

                if (orders_map !== undefined) {
                    ignored_orders_map.set(line.line_uuid, [line_orders, orders_map]);
                }

                if (!skip_empty_lines || new_line.orders.length > 0) {
                    new_lines_orders.push(new_line);
                }
            }

            return new_lines_orders;
        };

        const lines_orders = ignoreOrders(state.lines_orders);
        const unscheduled_orders = ignoreOrders(state.unscheduled_orders, true);
        const ignored_orders: LineOrders[] = [];
        ignored_orders_map.forEach(([line_orders, orders_map]) => {
            ignored_orders.push({
                ...line_orders,
                orders: [...orders_map.values()]
            });
        });
        return {
            ...state,
            lines_orders,
            unscheduled_orders,
            ignored_orders,
            ignore_orders_status
        };
    } else if (action.type === UNIGNORE_ORDERS) {
        const ignore_orders_status: IgnoreOrdersMap = state.ignore_orders_status !== null
            ? new Map(state.ignore_orders_status)
            : new Map();
        for (const order_uuid of action.data.order_uuids) {
            ignore_orders_status.set(order_uuid, false);
        }

        const unscheduled_orders_map: Map<string, [LineOrders, Map<string, IOrderProducedModelEx>]> = new Map();
        for (const line of state.unscheduled_orders) {
            const orders_map: Map<string, IOrderProducedModelEx> = new Map();
            for (const order of line.orders) {
                orders_map.set(order.uuid, order);
            }

            unscheduled_orders_map.set(line.line_uuid, [line, orders_map]);
        }

        const ignored_orders: LineOrders[] = [];
        for (const line of state.ignored_orders) {
            const new_line: LineOrders = {
                ...line,
                orders: []
            };
            for (const order of line.orders) {
                if (ignore_orders_status.get(order.uuid) === false) {
                    order.tags[ORDER_TAGS.ignore_on_planning_board] = "false";
                    const [line_orders, orders_map] = unscheduled_orders_map.get(line.line_uuid) || [{
                        ...line,
                        orders: []
                    }, new Map()];
                    orders_map.set(order.uuid, order);
                    unscheduled_orders_map.set(line.line_uuid, [line_orders, orders_map]);
                } else {
                    new_line.orders.push(order);
                }
            }

            if (new_line.orders.length > 0) {
                ignored_orders.push(new_line);
            }
        }

        const unscheduled_orders: LineOrders[] = [];
        unscheduled_orders_map.forEach(([line_orders, orders_map]) => {
            unscheduled_orders.push({
                ...line_orders,
                orders: [...orders_map.values()]
            });
        });
        let new_unscheduled_orders = sortUnscheduledLineOrders(unscheduled_orders);
        return {
            ...state,
            lines_orders: [...state.lines_orders],
            unscheduled_orders: new_unscheduled_orders,
            ignored_orders,
            ignore_orders_status
        };
    } else if (action.type === CLEAR_IGNORE_ORDERS_STATUS) {
        return {
            ...state,
            ignore_orders_status: null
        };
    } else if (action.type === UPDATE_IGNORED_ORDER) {
        const data = action.data;
        let ignored_orders = state.ignored_orders;
        let found = false;
        for (let i = 0; i < ignored_orders.length; i++) {
            const line = ignored_orders[i];
            for (let j = 0; j < line.orders.length; j++) {
                const order = line.orders[j];
                if (order.uuid === data.order.uuid) {
                    line.orders[j] = { ...data.order };
                    ignored_orders = [...ignored_orders];
                    found = true;
                    break;
                }
            }

            if (found) {
                break;
            }
        }

        let lines_orders = state_lines_orders;
        if (data.run_quick_simulation) {
            lines_orders = [...lines_orders];
        }
        return {
            ...state,
            lines_orders,
            ignored_orders
        };
    } else if (action.type === UPDATE_OPERATION_LINKS) {
        let lines_orders = state_lines_orders;
        let unscheduled_orders = state.unscheduled_orders;
        let ignored_orders = state.ignored_orders;
        let ignore_orders_status = state.ignore_orders_status;
        let extra_lines_operation_links_map = new Map(state.extra_lines_operation_links);

        for (const [rework_operation_uuid, base_operation_uuid] of action.data.links) {
            const rework_operation = LinesOrdersMaps.all_orders_map.get(rework_operation_uuid);
            const base_operation = base_operation_uuid !== null
                ? LinesOrdersMaps.all_orders_map.get(base_operation_uuid)
                : null;

            if (rework_operation !== undefined) {
                if (base_operation != null) {
                    // Link rework operation from selected line group to base operation from selected line group
                    const result = updateReworkOrder(
                        base_operation,
                        rework_operation,
                        lines_orders,
                        unscheduled_orders,
                        ignored_orders,
                        ignore_orders_status
                    );

                    lines_orders = result.lines_orders;
                    unscheduled_orders = result.unscheduled_orders;
                    ignored_orders = result.ignored_orders;
                    ignore_orders_status = result.ignore_orders_status;
                } else if (base_operation === null) {
                    // Unlink rework operation from selected line group
                    const is_unscheduled = LinesOrdersMaps.unscheduled_orders.has(rework_operation_uuid);
                    const current_lines_orders = is_unscheduled ? unscheduled_orders : lines_orders;
                    for (const line of current_lines_orders) {
                        const operation = line.orders.find(op => op.uuid === rework_operation_uuid);
                        if (operation) {
                            delete operation.tags[ORDER_TAGS.linked_operation];
                            if (is_unscheduled) {
                                unscheduled_orders = deepClone(unscheduled_orders);
                            } else {
                                lines_orders = deepClone(current_lines_orders);
                            }

                            break;
                        }
                    }
                } else {
                    // Link rework operation from selected line group to operation from another line group
                    // Not allowed!
                }
            } else {
                extra_lines_operation_links_map.set(rework_operation_uuid, base_operation_uuid);
                if (base_operation != null) {
                    // Link operation from another line group to operation from selected line group
                    // Not allowed!
                } else {
                    // (Un)Link operation from another line group to operation from another line group
                    extra_lines_operation_links_map.set(rework_operation_uuid, base_operation_uuid);
                }
            }
        }

        return {
            ...state,
            lines_orders,
            unscheduled_orders,
            ignored_orders,
            ignore_orders_status,
            extra_lines_operation_links: [...extra_lines_operation_links_map.entries()]
        };
    } else if (action.type === CLEAR_EXTRA_LINES_OPERATION_LINKS) {
        return {
            ...state,
            extra_lines_operation_links: []
        };
    } else if (action.type === REWORK_ORDER_ACTION_NAME) {
        const { base_order, rework_order } = action.data;
        const {
            lines_orders,
            unscheduled_orders,
            ignored_orders,
            ignore_orders_status
        } = updateReworkOrder(
            base_order,
            rework_order,
            state_lines_orders,
            state.unscheduled_orders,
            state.ignored_orders,
            state.ignore_orders_status
        );
        return {
            ...state,
            lines_orders,
            unscheduled_orders,
            ignored_orders,
            ignore_orders_status
        }
    } else if (action.type === UNLINK_ORDER) {
        const unlink_order_uuid = action.data.order_uuid;
        const is_unscheduled = LinesOrdersMaps.unscheduled_orders.has(unlink_order_uuid);
        let state_unscheduled_orders = state.unscheduled_orders;
        if (is_unscheduled) {
            for (const line of state_unscheduled_orders) {
                const order = line.orders.find(order => order.uuid === unlink_order_uuid);
                if (order) {
                    delete order.tags[ORDER_TAGS.linked_operation];
                    state_unscheduled_orders = deepClone(state_unscheduled_orders);
                    break;
                }
            }
        } else {
            for (const line of state.lines_orders) {
                const order = line.orders.find(order => order.uuid === unlink_order_uuid);
                if (order) {
                    delete order.tags[ORDER_TAGS.linked_operation];
                    state_lines_orders = deepClone(state.lines_orders);
                    break;
                }

            }
        }

        return {
            ...state,
            lines_orders: state_lines_orders,
            unscheduled_orders: state_unscheduled_orders
        }
    } else if (action.type === SET_CHUNKED_ORDERS) {
        const { split_orders } = action.data;
        return {
            ...state,
            split_orders
        }
    } else if (action.type === UPDATE_CHUNKED_ORDERS) {
        const { parent_order_uuid } = action.data;
        let parent_order = (
            LinesOrdersMaps.all_orders_map.get(parent_order_uuid) ||
            LinesOrdersMaps.parents_of_chunks.get(parent_order_uuid)
        )
        let line_orders  = null;
        const action_chunked_orders = action.data.chunked_orders;
        const action_chunked_orders_map = new Map<string, $Shape<t.IOrderProducedModel>>();
        const parent_line_uuid = parent_order ? parent_order.line_uuid : null;
        for (const chunk of action_chunked_orders) {
            action_chunked_orders_map.set(chunk.uuid, chunk);
        }
        let parent_order_line_index = null;
        let parent_order_index = null;
        let parent_still_exists = false;
        let programmatically_removed = false;
        const removed_nonsaved_chunks = [];
        // update existing chunk orders
        for (let i = 0; i < state_lines_orders.length; i++) {
            const line = state_lines_orders[i];
            let updated_order = false;
            for (let j = 0; j < line.orders.length; j++) {
                let order = line.orders[j];
                const chunk = action_chunked_orders_map.get(order.uuid);
                if (chunk) {
                    // chunk order was updated
                    order.quantity_total = chunk.quantity_total;
                    action.data.chunked_orders.splice(action.data.chunked_orders.indexOf(chunk), 1);
                    programmatically_removed = true;
                    updated_order = true;
                } else if (order.parent_order_uuid === parent_order_uuid &&
                    order.tags[ORDER_TAGS.split_chunk_index] == null) {
                    removed_nonsaved_chunks.push(order.uuid);
                }

                if (parent_order_line_index === null &&
                    (order.parent_order_uuid === parent_order_uuid || order.uuid === parent_order_uuid)) {
                    if (order.uuid === parent_order_uuid) {
                        parent_still_exists = true;
                    }
                    parent_order_index = j
                }
            }

            line.orders = line.orders.filter(o => !removed_nonsaved_chunks.includes(o.uuid));

            if (updated_order) {
                state_lines_orders[i] = deepClone(line);
            }

            state_lines_orders[i] = line;
            if (parent_line_uuid === line.line_uuid) {
                line_orders = line.orders;
                parent_order_line_index = i
            }
        }

        // at this point we have lines_orders without chunks and the position of the new chunks
        // we need split_orders to remove chunks that were not saved

        if (parent_order_line_index == null || parent_order_index == null) {
            return state;
        }

        if (line_orders && parent_order && action.data.chunked_orders.length === 0 && !programmatically_removed) {
            // all chunks have been removed, this can only happen if chunk orders have not yet been saved
            // in this case revert parent order, move it back to lines orders on the first chunk order position
            parent_order.skip_sim_internal = false;
            line_orders.splice(parent_order_index, 0, parent_order);
            line_orders = updateOrderSequenceWeight(line_orders);
            state_lines_orders[parent_order_line_index].orders = line_orders;
            let split_orders = state.split_orders.find(o => o.parent.uuid === parent_order_uuid);
            if (!split_orders) {
                return state;
            }
            return {
                ...state,
                lines_orders: deepClone(state_lines_orders),
                split_orders: deepClone(state.split_orders.filter(o => o.parent.uuid !== parent_order_uuid))
            }
        }

        if (!parent_order) {
            console.log("Error occured when updating chunked orders", parent_order);
            return state;
        }

        // new chunked orders need to be added to lines orders
        const chunked_orders = action.data.chunked_orders;
        const chunked_lines_orders: t.IOrderProducedModel[] = [];

        for (let i = 0; i < chunked_orders.length; i++) {
            const input_child_order = chunked_orders[i];
            // also chunked orders that were saved into db should be intact and quantity updated accordingly
            let new_child_order = LinesOrdersMaps.chunk_orders.get(input_child_order.uuid) || deepClone(parent_order);
            new_child_order.uuid = input_child_order.uuid;
            new_child_order.order_type = ORDER_TYPE.chunk;
            new_child_order.quantity_total = input_child_order.quantity_total;
            new_child_order.quantity_produced = input_child_order.quantity_produced;
            new_child_order.generated_by = ORDER_GENERATED_BY.planning_board_split;
            new_child_order.parent_order_uuid = parent_order_uuid;
            new_child_order.skip_sim = false;
            new_child_order.skip_sim_internal = false;
            const existing_chunk = LinesOrdersMaps.chunk_orders.get(input_child_order.uuid);
            if (existing_chunk) {
                new_child_order = {
                    ...existing_chunk,
                    ...new_child_order
                }
            }
            chunked_lines_orders.push(new_child_order);
        }

        const new_split_orders = state.split_orders;
        // parent order found
        if (line_orders !== null && parent_order_index != null && parent_order_line_index != null) {
            line_orders.splice(
                parent_order_index,
                parent_still_exists ? 1 : 0, // we remove parent but we already removed chunks so if we use 1 we will remove some random order
                ...chunked_lines_orders
            )

            parent_order.skip_sim_internal = true;
            const parent_order_new = deepClone(parent_order);
            LinesOrdersMaps.parents_of_chunks.set(parent_order.uuid, parent_order_new);
            line_orders = updateOrderSequenceWeight(line_orders);
            state_lines_orders[parent_order_line_index].orders = deepClone(line_orders);
            const existing_split_orders = new_split_orders.find(o => o.parent.uuid === parent_order_uuid);
            if (!existing_split_orders) {
                new_split_orders.push({
                    parent: parent_order_new,
                    chunks: chunked_lines_orders
                })
            } else {
                const updated_chunks = [];
                for (const existing of existing_split_orders.chunks) {
                    const update_chunk = action_chunked_orders_map.get(existing.uuid)
                    if (update_chunk) {
                        existing.quantity_total = update_chunk.quantity_total;
                        updated_chunks.push(existing);
                    }
                }
                existing_split_orders.chunks = updated_chunks.concat(chunked_lines_orders);
            }
        }

        return {
            ...state,
            lines_orders: deepClone(state_lines_orders),
            split_orders: deepClone(new_split_orders)
        }
    }

    if (state.lines_orders !== state_lines_orders) {
        return { ...state, lines_orders: state_lines_orders}
    }

    return state;
}

export const linesOrdersReducer = (state: LinesOrdersState, action: LineOrdersActionsTypes): LinesOrdersState => {
    const new_state = linesOrdersReducerCore(state, action);
    if (new_state && state) {
        if (
            state.lines_orders !== new_state.lines_orders ||
            state.unscheduled_orders !== new_state.unscheduled_orders ||
            state.ignored_orders !== new_state.ignored_orders
        ) {
            LinesOrdersMaps.updateMaps(
                new_state.lines_orders,
                new_state.unscheduled_orders,
                new_state.ignored_orders
            )
        }
        if (state.split_orders !== new_state.split_orders) {
            LinesOrdersMaps.updateChunkOrders(new_state.split_orders);
        }
    }

    return new_state;
}