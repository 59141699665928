// @flow
import * as React from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";

import { translate } from "./../../../IntlProviderWrapper";
import { ValidLinesOrdersHelper } from "../reducers/ValidLinesOrdersHelper";
import { DraggableConsumer } from "../Draggable/DraggableContext";

import type { LineOrders } from "../reducers/linesOrders"
import type { ReduxState } from "../reducers/reducers"
import type { DraggableProviderContext } from "../Draggable/DraggableContext";

export type ProcessNumRectProps = {
    process_num: string,
    width: number,
    x: number,
    y: number,
    order_external_id: string,
    last_clicked_order_uuid: string,
    order_uuid: string,
    height: number,
    lines_orders: LineOrders[],
    unscheduled_orders: LineOrders[]
}

type ProcessNumState = {
    selected_order_external_id: string | null,
    selected_order_uuid: string | null,
    computed_text_width: number | null
}

class ProcessNumRect extends React.PureComponent<ProcessNumRectProps, ProcessNumState> {

    state = {
        selected_order_external_id: null,
        selected_order_uuid: null,
        computed_text_width: null
    }

    text_ref =  null;

    constructor() {
        super();
        this.text_ref = React.createRef();
    }

    componentDidMount() {
        if (this.props.last_clicked_order_uuid) {
            this.load()
        }
    }

    componentDidUpdate() {
        if (this.props.last_clicked_order_uuid && this.state.selected_order_uuid !== this.props.last_clicked_order_uuid) {
            this.load()
        }
    }

    load() {
        const order_external_id = this.getOrderExternalIdOfSelectedOrder();
        this.setState({
            selected_order_external_id: order_external_id,
            selected_order_uuid: this.props.last_clicked_order_uuid,
        }, () => {
            if (this.text_ref && this.text_ref.current !== null) {
                this.setState({
                    computed_text_width: this.text_ref.current.getComputedTextLength()
                })
            }
        })
    }

    getOrderExternalIdOfSelectedOrder = () => {
        const operations = [
            ...ValidLinesOrdersHelper.getOperationsFromLineOrders(this.props.lines_orders),
            ...ValidLinesOrdersHelper.getOperationsFromLineOrders(this.props.unscheduled_orders)
        ];
        const selected_operation = operations.find(operation => operation.op_uuid === this.props.last_clicked_order_uuid);
        if (selected_operation) {
            return selected_operation.order_external_id;
        }
        return null;
    }

    renderProcessNumRect = () => {
        const order_uuid = this.props.order_uuid;
        const padding_x = 4;
        const padding_y = padding_x / 2;
        const font_size = 12;
        return <g id="process-num-rect">
            <rect
                x={this.props.x + this.props.width}
                y={this.props.y}
                width={this.state.computed_text_width + 2 * padding_x}
                height={font_size + 2 * padding_y}
                fill="white"
                stroke="black"
                strokeWidth="1"
                style={{ userSelect: "none" }}
            >
                <title>{translate("common.process_num", "Process num")}</title>
            </rect>
            <text
                ref={this.text_ref}
                fontSize={font_size}
                fontWeight="700"
                x={this.props.x + this.props.width + padding_x}
                y={this.props.y + padding_y + 1}
                style={{ userSelect: "none" }}
                order_uuid={order_uuid}
                fill={"black"}
                textAnchor="start"
                alignmentBaseline="hanging"
            >
                {this.props.process_num}
            </text>
        </g>;
    }

    render() {
        if (this.state.selected_order_external_id !== this.props.order_external_id) {
            return null;
        }

        const order_uuid = this.props.order_uuid;
        const process_nums_group = document.getElementById("gantt-chart-process-nums");

        return (
            <DraggableConsumer>
                {(value: ?DraggableProviderContext) => {
                    if (
                        process_nums_group === null ||
                        value && value.state.dragging_order && value.state.dragging_order.order_uuid === order_uuid
                    ) {
                        return this.renderProcessNumRect();
                    }

                    return ReactDOM.createPortal(this.renderProcessNumRect(), process_nums_group);
                }}
            </DraggableConsumer>
        );
    }
}

export default connect(
    (state: ReduxState, own_props: ProcessNumRectProps) => {
        const last_clicked_order_uuid = state.gantt_chart_planning_table.last_clicked_order_uuid;
        const lines_orders = state.gantt_chart_lines_orders.lines_orders;
        const unscheduled_orders = state.gantt_chart_lines_orders.unscheduled_orders;
        return {
            last_clicked_order_uuid,
            lines_orders,
            unscheduled_orders
        };
    },
    (dispatch) => ({ reduxDispatch: dispatch })
)(ProcessNumRect);