// @flow

/************************************************************************************/
/* WARNING: This file is generated automatically. Don't edit it by hand             */
/************************************************************************************/

import * as api from "./restApi.js";
import * as t from "./batch_operations.generated.types.js";

function enc(s: any): string { return encodeURIComponent("" + s); }

export interface IBatchOperations {
    /** Gets archive of batch operations for given group */
    getArchive(req: t.IGetArchiveReq): Promise<t.IGetArchiveRes>;

    /** Gets archive count of batch operations for given group */
    getArchiveCount(req: t.IGetArchiveCountReq): Promise<t.IGetArchiveCountRes>;

    /** Gets work-orders that can be currently entered for given location. */
    getWorkOrderSuggestions(req: t.IGetWorkOrderSuggestionsReq): Promise<t.IGetWorkOrderSuggestionsRes>;

    /** Updates a batch operations item */
    batchopUpdateOperationArchiveItem(req: t.IBatchopUpdateOperationArchiveItemReq): Promise<t.IBatchopUpdateOperationArchiveItemRes>;

    /** Gets current state of batch operations for given group */
    getCurrentStatus(req: t.IGetCurrentStatusReq): Promise<t.IGetCurrentStatusRes>;

    /** Updates a batch operations group */
    updateGroup(req: t.IUpdateGroupReq): Promise<t.IUpdateGroupRes>;

    /** Deletes a batch operations group */
    deleteGroup(req: t.IDeleteGroupReq): Promise<t.IDeleteGroupRes>;

    /** Gets batch operations groups */
    getGroups(req: t.IGetGroupsReq): Promise<t.IGetGroupsRes>;

    /** Adds new batch operations group */
    createGroup(req: t.ICreateGroupReq): Promise<t.ICreateGroupRes>;

    /** Updates a batch operations location */
    updateLocation(req: t.IUpdateLocationReq): Promise<t.IUpdateLocationRes>;

    /** Deletes a batch operations location */
    deleteLocation(req: t.IDeleteLocationReq): Promise<t.IDeleteLocationRes>;

    /** Gets list of locations (for given group) */
    getLocations(req: t.IGetLocationsReq): Promise<t.IGetLocationsRes>;

    /** Adds new batch operations location */
    createLocation(req: t.ICreateLocationReq): Promise<t.ICreateLocationRes>;

    /** Archives current batch operations */
    archive(req: t.IArchiveReq): Promise<t.IArchiveRes>;

    /** Updates current state of given batch operations */
    update(req: t.IUpdateReq): Promise<t.IUpdateRes>;

    /** Inserts current state of given batch operation */
    insert(req: t.IInsertReq): Promise<t.IInsertRes>;

}

export class BatchOperations implements IBatchOperations {

    client: api.ApiClient;

    constructor(client: api.ApiClient) { this.client = client; }

    /** Gets archive of batch operations for given group */
    async getArchive(req: t.IGetArchiveReq): Promise<t.IGetArchiveRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/batch_operations/group/${enc(req.group_id)}/archive`;
        delete reqx.group_id;
        return (await this.client.get(url, reqx)).data;
    }

    /** Gets archive count of batch operations for given group */
    async getArchiveCount(req: t.IGetArchiveCountReq): Promise<t.IGetArchiveCountRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/batch_operations/group/${enc(req.group_id)}/archive_count`;
        delete reqx.group_id;
        return (await this.client.post(url, reqx)).data;
    }

    /** Gets work-orders that can be currently entered for given location. */
    async getWorkOrderSuggestions(req: t.IGetWorkOrderSuggestionsReq): Promise<t.IGetWorkOrderSuggestionsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/batch_operations/location/${enc(req.location_id)}/work-orders`;
        delete reqx.location_id;
        return (await this.client.get(url, reqx)).data;
    }

    /** Updates a batch operations item */
    async batchopUpdateOperationArchiveItem(req: t.IBatchopUpdateOperationArchiveItemReq): Promise<t.IBatchopUpdateOperationArchiveItemRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/batch_operations/group/${enc(req.group_id)}/archive_item/${enc(req.item_id)}`;
        delete reqx.group_id;
        delete reqx.item_id;
        return (await this.client.put(url, reqx)).data;
    }

    /** Gets current state of batch operations for given group */
    async getCurrentStatus(req: t.IGetCurrentStatusReq): Promise<t.IGetCurrentStatusRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/batch_operations/group/${enc(req.group_id)}`;
        delete reqx.group_id;
        return (await this.client.get(url, reqx)).data;
    }

    /** Updates a batch operations group */
    async updateGroup(req: t.IUpdateGroupReq): Promise<t.IUpdateGroupRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/batch_operations/group/${enc(req.group_id)}`;
        delete reqx.group_id;
        return (await this.client.put(url, reqx)).data;
    }

    /** Deletes a batch operations group */
    async deleteGroup(req: t.IDeleteGroupReq): Promise<t.IDeleteGroupRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/batch_operations/group/${enc(req.group_id)}`;
        delete reqx.group_id;
        return (await this.client.delete(url, reqx)).data;
    }

    /** Gets batch operations groups */
    async getGroups(req: t.IGetGroupsReq): Promise<t.IGetGroupsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/batch_operations/group`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Adds new batch operations group */
    async createGroup(req: t.ICreateGroupReq): Promise<t.ICreateGroupRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/batch_operations/group`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Updates a batch operations location */
    async updateLocation(req: t.IUpdateLocationReq): Promise<t.IUpdateLocationRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/batch_operations/location/${enc(req.uuid)}`;
        delete reqx.uuid;
        return (await this.client.put(url, reqx)).data;
    }

    /** Deletes a batch operations location */
    async deleteLocation(req: t.IDeleteLocationReq): Promise<t.IDeleteLocationRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/batch_operations/location/${enc(req.uuid)}`;
        delete reqx.uuid;
        return (await this.client.delete(url, reqx)).data;
    }

    /** Gets list of locations (for given group) */
    async getLocations(req: t.IGetLocationsReq): Promise<t.IGetLocationsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/batch_operations/location`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Adds new batch operations location */
    async createLocation(req: t.ICreateLocationReq): Promise<t.ICreateLocationRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/batch_operations/location`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Archives current batch operations */
    async archive(req: t.IArchiveReq): Promise<t.IArchiveRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/batch_operations/${enc(req.uuid)}/archive`;
        delete reqx.uuid;
        return (await this.client.post(url, reqx)).data;
    }

    /** Updates current state of given batch operations */
    async update(req: t.IUpdateReq): Promise<t.IUpdateRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/batch_operations/${enc(req.uuid)}`;
        delete reqx.uuid;
        return (await this.client.put(url, reqx)).data;
    }

    /** Inserts current state of given batch operation */
    async insert(req: t.IInsertReq): Promise<t.IInsertRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/batch_operations`;
        return (await this.client.post(url, reqx)).data;
    }

}
