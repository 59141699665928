// @flow

// main imports

// load subcomponents
import { LineGroupCreate } from "./LineGroup.create";

// backend
import { getBackend } from "../../lib/backend/Backend2";

// type definitions

/**
 * This objects extends line create. It only changes the way the data is loaded.
 */
class LineGroupEdit extends LineGroupCreate {

    is_edit = true;

    /**
     * Loading the component (plants and lines data).
     */
    async componentDidMount() {
        // loading data for the line
        // linegroup_uuid is uuid parameter from URL path
        const { uuid } = this.props.match.params;

        const { line_groups } = await getBackend().manufacturing.getLineGroups({});
        const line_group = line_groups.filter(x => x.uuid === uuid)[0];

        if (line_group !== null) {
            let tags = line_group.tags || {};
            let tag_state = {};

            //overwrite with existing tags
            Object.keys(this.state.tags).forEach((el) => {
                // add missing GUI controled tags
                if (!tags.hasOwnProperty(el)) { tags[el] = this.state.tags[el]; }
                // set indicators for GUI controled tags
                tag_state[el] = (line_group.tags[el] === "true");
            });

            // merging two objects into a single state
            this.setState({
                ...{
                    external_id: line_group.external_id,
                    uuid: line_group.uuid,
                    title: line_group.title,
                    weight: line_group.weight,
                    tags: tags
                },
                ...tag_state
            })
        }
    }
}

export default LineGroupEdit;
