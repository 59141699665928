// @flow
import { deepClone } from "../../../../lib/Util";
import type { PayloadAction } from "../../../redux/types";

export const UPDATE_STOCK_REQUIREMENTS: "update_stock_requirements" = "update_stock_requirements";

export type StockRequirement = {
    uuid: string;
    transaction_external_id: string;
    material_uuid: string;
    material_title: string;
    material_external_id: string;
    shift_uuid: string;
    plant_uuid: string;
    quantity: number;
    date_expected: number;
    date_required: number;
    shift_start_time: number;
};

export type StockRequirementsState = {
    stock_requirements: StockRequirement[]
};
export type UpdateStockRequirements = PayloadAction<typeof UPDATE_STOCK_REQUIREMENTS, StockRequirement[]>;
export type StockRequirementsActions = UpdateStockRequirements;


const stock_requirements_state = {
    stock_requirements: []
}

export const stockRequirementsReducer = (state: StockRequirementsState, action: StockRequirementsActions): StockRequirementsState => {
    if (!state) {
        state = deepClone(stock_requirements_state);
    }

    if (action.type === UPDATE_STOCK_REQUIREMENTS) {
        return {
            ...state,
            stock_requirements: action.data
        };
    }

    return state;
}
