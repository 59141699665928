// @flow
import * as React from "react";
import { getBackend } from "../../lib/backend/Backend2";
import * as t from "../../lib/backend/datasources.generated.types";

import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import HighchartsLine from "../HighchartsLine";
import type { TsObj, TsObjNull } from "../../lib/Models";
import highchartsMore from "highcharts/highcharts-more.js";
import solidGauge from "highcharts/modules/solid-gauge.js";


highchartsMore(Highcharts);
solidGauge(Highcharts);

type Props = {
    data_def?: any,
    widget?: any,
    insights?: Array<any>
};

type State = {
    ts: Array<any>,
    insights: Array<any>,
    data: any,
    error: string | null
};

class IotStandalone extends React.Component<Props, State> {
    interval: any;

    constructor(props: Props) {
        super(props);

        const data_def = this.props.data_def;
        let data_def_iot = (data_def && data_def.external && data_def.external["iot"]) || "";
        const sensor_code = (data_def_iot && data_def_iot.code) || "";

        this.state = {
            data: {
                // "good_parts": {
                //     "code": `${sensor_code}_gp.input`,
                //     "chartOptions": {},
                //     "chartType": "line"
                // },
                "counter": {
                    "code": `${sensor_code}_gp_counter.input`,
                    "chartOptions": {},
                    "chartType": "line",
                    "resetCounter": true,
                    "insertNulls": true,
                    "extraOptions": {
                        "y_label": "Good Parts [pieces]",
                        "chart_type": "column"
                    }
                },
                // "bad_parts": {
                //     "code": `${sensor_code}_bp.input`,
                //     "chartOptions": {},
                //     "chartType": "line"
                // },
                "cycle_time": {
                    "code": `${sensor_code}_gp_cycle_time.input`,
                    "chartOptions": {},
                    "chartType": "line",
                    "removeOutliers": true,
                    "insertNulls": true,
                    "msToSecs": true,
                    "extraOptions": {
                        "y_label": "Cycle Time [s]",
                        "chart_type": "line"
                    }
                },
                // "data_delay": {
                //     "code": `${sensor_code}_gp_data_delay.input`,
                //     "chartOptions": {},
                //     "chartType": "line"
                // },

                "gp_gauge": {
                    "code": `${sensor_code}_gp.input`,
                    "chartOptions": {},
                    "extraOptions": {
                        y_axis_max: 20,
                        unit: "pieces"
                    },
                    "chartType": "gauge"
                },
                "bp_gauge": {
                    "code": `${sensor_code}_bp.input`,
                    "chartOptions": {},
                    "extraOptions": {
                        y_axis_max: 50,
                        unit: "pieces"
                    },
                    "chartType": "gauge"
                },
                "cycle_time_gauge": {
                    "code": `${sensor_code}_gp_cycle_time.input`,
                    "chartOptions": {},
                    "extraOptions": {
                        y_axis_max: 200,
                        unit: "s"
                    },
                    "chartType": "gauge",
                    "msToSecs": true
                }
            },
            error: "",
            ts: [],
            insights: props.insights || [],
        };
        this.prepareGraphs();
    }

    prepareOptionsForLineChart = (data_ts: Array<TsObj> | Array<TsObjNull> | null, options: Object = {}) => {

        const data_def = this.props.data_def;
        const widget = this.props.widget;
        const from = data_def && data_def.from;
        const to = data_def && data_def.to;
        const height = options.height || 300;
        const y_label = options.y_label || null;
        const chart_type = options.chart_type || "line";
        const y_max = options.y_max || null;
        const ts = data_ts || [];

        let showDowntimes = widget && widget.show_downtimes;
        if (typeof showDowntimes === 'undefined') {
            showDowntimes = true;
        }

        // Prepare plot bands for downtime events
        let bands = [];
        let events = data_def && data_def.events;
        if (showDowntimes && events) {
            let downtime_to = null;
            let bandColor = 'rgba(255, 0, 0, 0.2)'; // last value is opacity
            for (let event of events) {
                if (event.type === "downtime") {
                    if (event.extra_data.extreme_type === "end") {
                        downtime_to = event.ts;
                    }

                    if (event.extra_data.extreme_type === "start") {
                        bands.push({
                            from: event.ts,
                            to: (downtime_to) ? downtime_to : new Date().getTime(),
                            color: bandColor
                        });
                    }
                }
            }
        }

        const input_ts_data = {
            height: height,
            name: "raw input",
            y_label: y_label,
            y_max_val: y_max,
            x_min_val: from,
            x_max_val: to,
            type: chart_type,
            ts: ts,
            x_bands: bands
        };

        return input_ts_data;
    }

    // TODO: add optional parameter for specifing y max, and color ranges, maybe also labels
    prepareOptionsForGaugeChart = (options: Object) => {
        const y_axis_max = options.y_axis_max || 200;
        const unit = options.unit || "";

        // gauge settings
        let gaugeOptions = {
            chart: {
                height: 200,
                type: "solidgauge",
            },
            title: null,
            pane: {
                center: ['50%', '85%'],
                size: '150%',
                startAngle: -90,
                endAngle: 90,
                background: {
                    backgroundColor:
                        Highcharts.defaultOptions.legend.backgroundColor || '#EEE',
                    innerRadius: '60%',
                    outerRadius: '100%',
                    shape: 'arc'
                }
            },
            tooltip: {
                enabled: false
            },

            yAxis: {
                min: 0,
                max: y_axis_max,
                stops: [
                    [0.1, '#55BF3B'], // green
                    [0.5, '#DDDF0D'], // yellow
                    [0.9, '#DF5353'] // red
                ],
                lineWidth: 0,
                tickWidth: 0,
                minorTickInterval: null,
                tickAmount: 2,
                labels: {
                    y: 16
                }
            },
            plotOptions: {
                solidgauge: {
                    dataLabels: {
                        y: 5,
                        borderWidth: 0,
                        useHTML: true
                    }
                }
            },
            series: [{
                data: [0],
                dataLabels: {
                    format:
                        '<div style="text-align:center">' +
                        '<span style="font-size:25px">{y}</span><br/>' +
                        `<span style="font-size:12px;opacity:0.4">${unit}</span>` +
                        '</div>'
                },
            }]
        }

        return gaugeOptions;
    }

    prepareGraphs = () => {
        // if only data_def is present
        const data_def = this.props.data_def;
        let data_def_iot = data_def && data_def.external && data_def.external["iot"];

        if (data_def_iot) {
            let data = { ...this.state.data };

            for (let series in this.state.data) {
                if (data[series]["chartType"] === "line") {
                    data[series]["chartOptions"] = this.prepareOptionsForLineChart(null, data[series]["extraOptions"]);
                }

                else if (data[series]["chartType"] === "gauge") {
                    data[series]["chartOptions"] = this.prepareOptionsForGaugeChart(data[series]["extraOptions"]);
                }

                else {
                    data[series]["chartOptions"] = this.prepareOptionsForLineChart(null, data[series]["extraOptions"]);
                }
            }
        }
    }

    findNextTsToReset = (ts: number): number => {
        let hour = new Date(ts).getHours();

        // shift ends at 14h
        if (hour >= 6 && hour < 14) {
            return new Date(ts).setHours(14, 0, 0);
        }

        // shift ends at 22h
        if (hour >= 14 && hour < 22) {
            return new Date(ts).setHours(22, 0, 0);
        }

        // shift ends at 6h
        if (hour >= 22 || hour < 6) {
            let tomorrow = new Date(ts);
            tomorrow.setDate(new Date(ts).getDate() + 1);
            return new Date(tomorrow).setHours(6, 0, 0);
        }

        return 0; // This should never happen
    }

    resetCounter = (ts_data: t.ITimeseriesDataItem[]): void => {
        let resetTs: number = 0;
        let startVal: number = 0;

        ts_data.forEach(obj => {
            if (obj.ts > resetTs) {
                startVal = obj.val;
                resetTs = this.findNextTsToReset(obj.ts);
            }
            obj.val -= startVal;
        });
    }

    removeOutliers = (ts_data: t.ITimeseriesDataItem[]): t.ITimeseriesDataItem[] => {
        // compute average
        // const sum = ts_data.map((obj) => obj.val).reduce((a,b) => a + b, 0);
        // const avg = (sum / ts_data.length) || 0;

        // compute median
        const mid = Math.floor(ts_data.length / 2);
        const nums = ts_data.map((obj) => obj.val).sort((a, b) => a - b);
        const median = ts_data.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;

        // remove outliers (2*median)
        const filtered = ts_data.filter(obj => obj.val < (2 * median));
        // const filtered = ts_data.filter(obj => obj.val < (2 * avg));

        return filtered;
    }

    msToSecs = (ts_data: t.ITimeseriesDataItem[]): void => {
        ts_data.forEach((obj) => { obj.val = obj.val / 1000 });
    }

    insertNulls = (ts_data: Array<TsObj>): Array<TsObjNull> => {
        let ts_data_null: Array<TsObjNull> = ts_data.map(x => ({ ts: x.ts, val: x.val }));
        let prev = ts_data_null[0];
        let idx = 0;
        let gap = 0;
        // gap = 600000; // for gaps larger than 10min

        // calculate gap as average or median of ts differences
        let sum = 0;
        for (let curr of ts_data_null) {
            sum += (curr.ts - prev.ts);
            prev = curr;
        }
        const avg = (sum / ts_data_null.length) || 0;
        gap = avg;
        prev = ts_data_null[0];

        for (let curr of ts_data_null) {
            if ((curr.ts - prev.ts) > gap) {
                ts_data_null.splice(idx, 0, { ts: prev.ts + 1, val: null });
                ts_data_null.splice(idx + 1, 0, { ts: curr.ts - 1, val: null });
            }
            prev = curr;
            idx++
        }
        return ts_data_null
    }

    fetchData = async () => {
        try {
            // if only data_def is present
            const data_def = this.props.data_def;
            const widget = this.props.widget;
            let data_def_iot = data_def && data_def.external && data_def.external.iot;
            let aggregateTs = widget && widget.aggregate_ts;
            if (typeof aggregateTs === 'undefined') {
                aggregateTs = false;
            }

            if (data_def && data_def_iot) {
                let data = { ...this.state.data };

                // update from and to
                let range = data_def.to - data_def.from;
                let to = new Date().getTime();
                let from = to - range;

                for (let series in this.state.data) {
                    const data_series = data[series];
                    const timeseries_code = data_series.code;

                    // aggregated ts or raw
                    const hits = await getBackend().datasources.searchTSeries({ code: timeseries_code });
                    let ts: t.ITimeseriesDataItem[] = [];
                    if (aggregateTs) {
                        const res = await getBackend().datasources.getSingleTSeriesData({ id: hits[0].uuid, from, to });
                        ts = res.data;
                    } else {
                        const res = await getBackend().datasources.getSingleTSeriesDataAggregated({ id: hits[0].uuid, from, to });
                        ts = res.data.map(obj => ({ ts: obj.ts, val: obj.avg }));
                    }
                    if (data_series.chartType === "line") {
                        if (data_series.resetCounter) {
                            this.resetCounter(ts);
                        }

                        if (data_series.msToSecs) {
                            this.msToSecs(ts);
                        }

                        if (data_series.removeOutliers) {
                            ts = this.removeOutliers(ts);
                        }

                        if (data_series.insertNulls) {
                            data_series.chartOptions = this.prepareOptionsForLineChart(this.insertNulls(ts), data_series.extraOptions);
                        } else {
                            data_series.chartOptions = this.prepareOptionsForLineChart(ts, data_series.extraOptions);
                        }

                    } else if (data_series.chartType === "gauge") {
                        let val = (ts.length !== 0) ? [ts[ts.length - 1].val] : [];

                        if (data_series.msToSecs) {
                            if (val.length) {
                                val[0] = Math.round(val[0] / 1000);
                            }
                        }

                        data_series.chartOptions = {
                            series: [{
                                data: val
                            }]
                        }
                    } else {
                        data_series.chartOptions = this.prepareOptionsForLineChart(ts, data_series.extraOptions);
                    }
                    this.setState({ data: data });
                }
            }
        } catch (err) {
            this.setState({ error: err });
        }
    }

    componentDidMount() {
        let refreshInterval = 60 * 1000; // in ms

        this.fetchData();
        this.interval = setInterval(() => {
            this.fetchData();
        }, refreshInterval);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        return (
            <div className="dsh_chart_container">
                <div className="row">
                    <div className="col dsh_chart_container">
                        <h2 className="text-center">Good Parts</h2>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={this.state.data.gp_gauge.chartOptions}
                            key="Gauge1"
                        />
                    </div>
                    <div className="col dsh_chart_container">
                        <h2 className="text-center">Bad Parts</h2>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={this.state.data.bp_gauge.chartOptions}
                            key="Gauge2"
                        />
                    </div>
                    <div className="col dsh_chart_container">
                        <h2 className="text-center">Cycle Time</h2>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={this.state.data.cycle_time_gauge.chartOptions}
                            key="Gauge3"
                        />
                    </div>
                </div>

                <div className="row dsh_chart_container">
                    <div className="col">
                        <h2 className="text-center">Counter</h2>
                        <HighchartsLine
                            key="Chart1"
                            options={this.state.data.counter.chartOptions} />
                        {/* events={this.props.data_def.events} /> */}
                    </div>
                </div>
                <div className="row dsh_chart_container">
                    <div className="col">
                        <h2 className="text-center">Cycle Time</h2>
                        <HighchartsLine
                            key="Chart2"
                            options={this.state.data.cycle_time.chartOptions} />
                        {/* events={this.props.data_def.events} /> */}
                    </div>
                </div>

            </div>
        );
    }
}

// https://github.com/highcharts/highcharts-react
// https://stackblitz.com/edit/react-4ded5d?file=index.js
// https://codesandbox.io/s/highcharts-react-demo-7d1nd
// https://codesandbox.io/s/rkzro8yy4

export default IotStandalone;
