// @flow

// React
import React, { Component } from "react";
import ChevronListItem from "../react/ChevronListItem";

// Models
import type { ILineData, ILineGroupData } from "../../lib/backend/manufacturing2.generated.types";

type Props = {
    filter: string,
    plantFilter: string,
    selected: ILineGroupData | null,
    lines: ILineData[],
    line_groups: ILineGroupData[],
    plant_uuid_to_title: Map<string, string>,
    handleItemClick: any
}

/**
 * LineGroups list component.
 */
class LineGroupsList extends Component<Props> {
    /**
     * Handle `click` events for the line item.
     */
    handleItemClick(line: ILineGroupData, i: number) {
        // Ignore clicks for currently selected line.
        if (line === this.props.selected) {
            return;
        }
        // Handle parent's click events.
        this.props.handleItemClick(line);
    }

    /**
     * Linegroups list filter.
     *
     * @param {string} needle Search term.
     * @param {LineGroupObj} line Line object to match search term against.
     *
     * @return {boolean} `true` if line matches given search term, `false` otherwise.
     */
    includes(needle: string, line_group: ILineGroupData) {
        needle = needle.toLowerCase();
        if (needle.length === 0) return true;
        if (line_group.title.toLowerCase().indexOf(needle) > -1) return true;
        if (line_group.external_id.toLowerCase().indexOf(needle) > -1) return true;
        /*
        if (line.plant_title.toLowerCase().indexOf(needle) > -1) return true;
        if (line.plant_uuid.toLowerCase().indexOf(needle) > -1) return true;
        */
        // search tag keys and values
        return false;
    }

    /**
     * Render component.
     */
    render() {
        const linegroupsList = this.props.line_groups.map((line_group, i) => {
            let is_active = this.props.selected === line_group;

            if (this.props.plantFilter && !line_group.plant_uuids.includes(this.props.plantFilter)) {
                return null;
            }
            if (!this.includes(this.props.filter, line_group)) return null;

            // prepare plant title
            const plant_title = line_group.plant_uuids
                .filter(uuid => (this.props.plant_uuid_to_title.has(uuid)))
                .map(uuid => (this.props.plant_uuid_to_title.get(uuid)))
                .join(", ");
            
            return <ChevronListItem
                text={<div>{line_group.title}<br /><small>{plant_title}</small></div>}
                onClick={() => this.handleItemClick(line_group, i)}
                is_active={is_active}
            />
        })

        return (
            <ul className="nav nav-tabs">
                <div className="nav-tabs-parent" ref="nav">
                    {linegroupsList}
                </div>
            </ul>
        )
    }
}

export default LineGroupsList;
