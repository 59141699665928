// @flow
import  * as React from "react";
import { connect } from "react-redux";
import { SET_ORDER_TABLE_PRODUCTION_MODAL } from "../reducers/ganttChartStandalone";
import OrderTableProductionModal from "../../OrderTableProductionModal";
import { PropertiesLogic } from "../reducers/BusinessLogic";
import { GanttChartSource } from "../reducers/properties";

import type { GanttChartSourceTypes } from "../reducers/properties";
import type { SetOrderTableProductionModalAction } from "../reducers/ganttChartStandalone";
import type { ReduxState } from "../reducers/reducers";
import type { IOrderModelBase } from "../../../../lib/backend/manufacturing2.generated.types";

type Props = {
    show_modal: boolean,
    order_process_num: string,
    order_external_id: string,
    selected_order_uuid: string,
    reduxDispatch: (args: SetOrderTableProductionModalAction) => void,
    onOrderChange?: (order: IOrderModelBase) => void,
    source_type: GanttChartSourceTypes
};

type State = { };

class ShowGanttOrderDataOnClick extends React.Component<Props, State> {
    ref = null;

    constructor(props: Props) {
        super(props);
        this.ref = React.createRef();
    }

    onCloseModal = () => {
        this.props.reduxDispatch({
            type: SET_ORDER_TABLE_PRODUCTION_MODAL,
            data: {
                show_modal: false,
                order_external_id: "",
                order_uuid: "",
                order_process_num: ""
            }
        });
    }

    render() {
        if (!this.props.show_modal) {
            return null;
        }

        return <div ref={this.ref}>
            <OrderTableProductionModal
                show={this.props.show_modal}
                selected_order_uuid={this.props.selected_order_uuid}
                selected_order_external_id={this.props.order_external_id}
                process_num={this.props.order_process_num}
                onShowOrHideOrderModal={this.onCloseModal}
                onOrderSave={this.props.onOrderChange}
                show_edit_button={this.props.source_type === GanttChartSource.gantt || PropertiesLogic.isPlanningTable(this.props.source_type)}
                override_description_fields={[
                    "external_id",
                    "material_external_id",
                    "material_title",
                    "quantity_total"
                ]}
                show_columns={[
                    "process_num",
                    "title",
                    "quantity_produced",
                    "quantity_scrap",
                    "quantity_total",
                    "output_stock",
                    "status",
                    "skip_sim"
                ]}
            />
        </div>
    }
}


export default connect(
    (state: ReduxState) => {
        const modal = state.standalone_gantt.order_table_production_modal;

        return {
            show_modal: modal.show_modal,
            order_external_id: modal.order_external_id,
            selected_order_uuid: modal.order_uuid,
            order_process_num: modal.order_process_num,
            source_type: state.gantt_chart_properties.source_type
        }
    },
    (dispatch) => ({reduxDispatch: dispatch})
)(ShowGanttOrderDataOnClick)
