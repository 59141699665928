// @flow
import React from "react";
import { connect } from "react-redux";
import * as constants from "./constants";
import * as t from "../../../../lib/backend/reports.generated.types";
import * as rt from "../../../../lib/SimulationReportModels";
import { getShiftXPosition } from "../reducers/properties";

import type { ReduxState } from "../reducers/reducers";
import type { PropertiesState, GanttChartSourceTypes, ShiftXPositions } from "../reducers/properties";

type Props = {
    shifts: t.ISimulationReportOrderLineShiftFeatureItem[],
    properties: PropertiesState,
    grid_lines_loaded: boolean,
    line_offset: number,
    source_type: GanttChartSourceTypes,
    earliest_start: number,
    shift_number: number,
    line: rt.ISimulationReportOrderLineEx,
    line_index: number,
    source_type: GanttChartSourceTypes,
    is_extra_line: boolean,
    shift_x_positions: ShiftXPositions,
    xscale: (val: number) => void,
    bar_height: number,
    bars_start: number
};

type State = {};

class DisabledShifts extends React.Component<Props, State> {

    getSourceType = () => {
        return this.props.source_type || this.props.properties.source_type;
    }

    renderDisabledShifts = () => {
        const DisabledShifts = [];
        const bar_height = this.props.bar_height;
        const xscale = this.props.xscale;

        if (!xscale) return null;

        const w_shift = this.props.xscale(constants.SHIFT_H);
        const shifts = this.props.shifts
        for (let i = 0; i < shifts.length; i++) {
            const shift = shifts[i];
            if (shift.enabled !== false) {
                continue;
            }
            const shift_time = shift.shift_time;
            const x = this.props.bars_start + getShiftXPosition(
                {
                    shift_x_positions: this.props.shift_x_positions,
                    shift_number: shift_time.shift_number,
                    week: shift_time.week,
                }
            );
            // WARP : temporary hack, need to fix
            if (x === undefined) { continue; }
            const y = this.props.line_offset;
            const width = w_shift;
            const height = bar_height;

            DisabledShifts.push(
                <g id="disabled-shift"
                    style={{ pointerEvents: "none" }}
                    key={shift_time.shift_number + "-" + shift_time.week}
                    shift_number={shift_time.shift_number}
                    week={shift_time.week}
                >
                    <rect
                        style={{pointerEvents: "none"}}
                        x={x}
                        y={y}
                        width={width}
                        height={height}
                        fill={"#9aa5b1"}
                        opacity={0.45}
                    />
                </g>
            );
        }

        return DisabledShifts;
    }

    render() {
        if (!this.props.shift_x_positions) {
            return null;
        }

        return <g id="disabled-shifts">
            {this.renderDisabledShifts()}
        </g>
    }
}

export default connect(
    (state: ReduxState) => {
        const properties = state.gantt_chart_properties;
        return {
            xscale: properties.xscale,
            bar_height: properties.bar_height,
            source_type: properties.source_type,
            shift_x_positions: properties.shift_x_positions,
            bars_start: properties.bars_start
        }
    },
    (dispatch) => ({ reduxDispatch: dispatch })
)(DisabledShifts);
