// @flow

// main imports
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import ReactRouterPropTypes from "react-router-prop-types";

// internal
import type { MaterialObj } from "../../lib/Models";
import ErrorComponent from "../ErrorComponent";
import Loader from "../Loader";
import { getBackend } from "../../lib/backend/Backend2";
import * as t from "../../lib/backend/manufacturing2.generated.types";
import { isNumber, stringCompare } from "../../lib/Util";
import { translate } from "../IntlProviderWrapper";

// type definitions
type Props = {
    match: ReactRouterPropTypes.match,
    history: ReactRouterPropTypes.history,
    location: ReactRouterPropTypes.location
}

type State = {
    line_uuid: string,
    kanban: t.IKanbanLine,
    kanban_loaded: boolean,
    materials: t.IMaterialModel[],
    materials_loaded: boolean,
    error: string,
    warning: string,
    adding_material_external_id: string,
    adding_material_min: number,
    adding_material_max: number,
    adding_materials_stock_locations: any,
    adding_material_uuid: string,
    adding_material_title: string,
    adding_material_unconfirmed_stock: boolean
}

/**
 * This objects extends line create. It only changes the way the data is loaded.
 */
class LineKanbanEdit extends Component<Props, State> {

    /**
     * Constructor.
     */
    constructor(props: Props) {
        super(props);
        const state: State = {
            line_uuid: "",
            kanban: {
                enabled: false,
                line_title: "",
                line_uuid: "",
                materials: []
            },
            kanban_loaded: false,
            materials: [],
            materials_loaded: false,
            error: "",
            warning: "",
            adding_material_external_id: "",
            adding_material_min: 0,
            adding_material_max: 0,
            adding_materials_stock_locations: [],
            adding_material_uuid: "",
            adding_material_title: "",
            adding_material_unconfirmed_stock: true
        };
        this.state = state;
    }

    /**
     * Loading component data (plants).
     */
    async componentDidMount() {
        try {
            // get line parameter
            const { uuid } = this.props.match.params;
            this.setState({ line_uuid: uuid });
            // get existing kanban parameters if any

            const res = await getBackend().manufacturing.getLineKanban({ uuid });
            // ensure stock locations do not duplicate
            for (const material of res.data.materials) {
                material.stock_locations = [...new Set < string > (material.stock_locations)];
            }
            
            this.setState({ kanban: res.data, kanban_loaded: true });

            try {
                // load possible materials for this line
                const res2 = await getBackend().manufacturing.searchMaterials({
                    line_uuid: uuid, only_bottleneck: false, stock_locations: true, consumed: false
                });

                const all_materials_uuids = res2.data.map(m => { return m.uuid });

                const materials_to_get_from_backend = [];
                for (const material of res.data.materials) {
                    if (!all_materials_uuids.includes(material.material_uuid)) {
                        materials_to_get_from_backend.push(material.material_uuid);
                    }
                }

                let res_sum = res2.data;

                if (materials_to_get_from_backend.length > 0) {
                    // gets extra materials from backend 
                    // Materials that are not in the search result but are in the kanban (custom added materials)
                    const res3 = await getBackend().manufacturing.searchMaterials({
                        material_uuids: materials_to_get_from_backend, stock_locations: true, consumed: false
                    });

                    res_sum = res2.data.concat(res3.data);
                }

                const materials = res_sum.sort((a, b) => stringCompare(a.title, b.title));
                const kanban = this.state.kanban;
                for (const kanban_material of kanban.materials) {
                    for (const material of materials) {
                        if ((kanban_material.material_uuid === material.uuid) &&
                            material.stock_locations &&
                            (kanban_material.stock_locations.length === 0)) {

                            // default is all locations
                            kanban_material.stock_locations = material.stock_locations.map(sl => sl.external_id);
                        }
                    }
                }
                this.setState({ kanban, materials, materials_loaded: true });

            } catch (err) {
                this.setState({ error: err.message, materials_loaded: true });
            }
        } catch (err) {
            this.setState({ error: err.message, kanban_loaded: true });
        }
    }

    isLoaded(): boolean {
        return this.state.kanban_loaded && this.state.materials_loaded;
    }

    getMaterialTitle(material_uuid: string) {
        for (const material of this.state.materials) {
            if (material.uuid === material_uuid) {
                return material.title;
            }
        }
        return "unknown";
    }

    validateNumber(num: any, name: string, material: string): string {
        if (isNumber(num)) {
            // all good
        } else {
            // we have
            if (!isNumber(parseFloat(num))) {
                return `${name} '${num}' for material ${material} is not a number!`;
            }
        }
        return "";
    }

    validateShallow(): string {
        for (const material of this.state.kanban.materials) {
            // validate minimal stock number
            const min_stock_check = this.validateNumber(material.min_stock,
                "Minimal stock level", this.getMaterialTitle(material.material_title));
            if (min_stock_check.length > 0) { return min_stock_check; }
            // validate maximal stock number
            const max_stock_check = this.validateNumber(material.max_stock,
                "Maximal stock level", this.getMaterialTitle(material.material_title));
            if (max_stock_check.length > 0) { return max_stock_check; }
            // validate 0 <= min < max
            if (parseFloat(material.min_stock) < 0) {
                return `Minimal stock level of '${material.min_stock}' cannot be lower than zero!`;
            }
            if (parseFloat(material.max_stock) <= parseFloat(material.min_stock)) {
                return `Maximal stock level of '${material.max_stock}' cannot be same or lower than Minimal stock level of '${material.min_stock}'!`;
            }
        }
        return "";
    }

    handleStockLocationEnabled = (event: Event, material_uuid: string, stock_location_external_id: string) => {
        const target = event.target;
        if (target instanceof HTMLInputElement) {
            if (target.type === "checkbox") {
                if (target.checked === false) {
                    // me must remove stock location from material
                    let kanban = this.state.kanban;
                    for (const kanban_material of kanban.materials) {
                        if (kanban_material.material_uuid === material_uuid) {
                            kanban_material.stock_locations = kanban_material.stock_locations
                                .filter(sl => { return sl !== stock_location_external_id });
                        }
                    }
                    this.setState({ kanban });
                } else {
                    // me must add stock location to material
                    let kanban = this.state.kanban;
                    for (const kanban_material of kanban.materials) {
                        if (kanban_material.material_uuid === material_uuid) {
                            kanban_material.stock_locations.push(stock_location_external_id);
                        }
                    }
                    this.setState({ kanban });
                }
            }
        }
    }

    handleUnconfirmedStockEnabled = (event: Event, material_uuid: string) => {
        const target = event.target;
        if (target instanceof HTMLInputElement) {
            if (target.type === "checkbox") {
                // get new value
                const value = target.checked;
                // update the use unconfirmed stock flag
                let kanban = this.state.kanban;
                for (const kanban_material of kanban.materials) {
                    if (kanban_material.material_uuid === material_uuid) {
                        kanban_material.unconfirmed_stock = value;
                    }
                }
                this.setState({ kanban });
            }
        }
    }

    handleEnabled = (event: Event, material: MaterialObj) => {
        const target = event.target;
        if (target instanceof HTMLInputElement) {
            if (target.type === "checkbox") {
                if (target.checked === false) {
                    // we must delete material from kanban
                    let kanban = this.state.kanban;
                    kanban.materials = kanban.materials.filter(m => { return m.material_uuid !== material.uuid; });
                    kanban.enabled = (kanban.materials.length > 0);
                    this.setState({ kanban });
                } else {
                    // we must add material to kanban
                    let kanban = this.state.kanban;
                    kanban.enabled = true;
                    kanban.materials.push({
                        material_title: this.getMaterialTitle(material.uuid),
                        material_uuid: material.uuid,
                        min_stock: 0, max_stock: 0,
                        stock_locations: material.stock_locations ? material.stock_locations.map(sl => sl.external_id) : [],
                        unconfirmed_stock: true
                    });
                    this.setState({ kanban });
                }
            }
        }
    }

    handleRange = (event: Event, material_uuid: string) => {
        const target = event.target;
        if (target instanceof HTMLInputElement) {
            if (target.type === "text") {
                const value = target.value;
                const name = target.name;
                // update the state with new value
                this.setState(prevState => {
                    for (const material of prevState.kanban.materials) {
                        if (material.material_uuid === material_uuid) {
                            material[name] = value;
                            break;
                        }
                    }
                    return prevState;
                });
            }
        }
    }

    isMaterialEnabled = (material_uuid: string): boolean => {
        for (const material of this.state.kanban.materials) {
            if (material.material_uuid === material_uuid) {
                return true;
            }
        }
        return false;
    }

    getKanbanMaterial = (material_uuid: string): t.IKanbanMaterial | null => {
        for (const material of this.state.kanban.materials) {
            if (material.material_uuid === material_uuid) {
                return material;
            }
        }
        return null;
    }

    /**
     * Handling submission of the form. Basic checks and saves with error handling.
     */
    handleSubmit = async () => {
        const validation_string = this.validateShallow();
        if (validation_string === "") {
            try {

                const kanban = this.state.kanban;

                kanban.materials.sort((a, b) => stringCompare(a.material_title, b.material_title));
                // update current object

                await getBackend().manufacturing.updateLineKanban({
                    enabled: kanban.enabled,
                    line_title: kanban.line_title,
                    materials: kanban.materials,
                    uuid: this.state.line_uuid
                });

                // move to lines
                this.props.history.push("/digital-twin/resources/lines/" + encodeURI(this.state.line_uuid));

            } catch (err) {
                this.setState({ error: err });
            }
        } else {
            this.setState({ warning: validation_string });
        }
    }

    handleAddNewMaterialToKanban = async () => {
        try {

            const kanban = this.state.kanban;

            if (this.state.adding_material_uuid !== "" &&
                this.state.adding_material_min >= 0 &&
                this.state.adding_material_max > 0 &&
                this.state.adding_material_max > this.state.adding_material_min) {

                if (kanban.materials.find(m => m.material_uuid === this.state.adding_material_uuid) === undefined) {
                    kanban.materials.push({
                        material_uuid: this.state.adding_material_uuid,
                        material_title: this.state.adding_material_title,
                        max_stock: this.state.adding_material_max,
                        min_stock: this.state.adding_material_min,
                        stock_locations: this.state.adding_materials_stock_locations.map((sl) => (sl.checked && sl.external_id)),
                        unconfirmed_stock: this.state.adding_material_unconfirmed_stock
                    });

                    kanban.enabled = true;
                } else {
                    this.setState({ error: translate("KanbanList.material_already_in_kanban", "Material is already in the kanban.") });
                    return;
                }

            } else {
                this.setState({ error: translate("KanbanList.check_min_and_max_values", "Please check min and max values.") });
                return;
            }

            kanban.materials.sort((a, b) => stringCompare(a.material_title, b.material_title));
            // update current object

            await getBackend().manufacturing.updateLineKanban({
                enabled: kanban.enabled,
                line_title: kanban.line_title,
                materials: kanban.materials,
                uuid: this.state.line_uuid
            });

            // move to lines
            this.props.history.push("/digital-twin/resources/lines/" + encodeURI(this.state.line_uuid));

        } catch (err) {
            this.setState({ error: err });
        }
    }

    handleCancel = () => {
        this.props.history.push("/digital-twin/resources/lines/" + encodeURI(this.state.line_uuid))
    }

    renderStockLocations(i: number, material: t.IMaterialModel, kanban_material: t.IKanbanMaterial) {
        if (material.stock_locations) {
            // keep track so stock locations do not duplicate (same location, different plant_uuid)
            const done_locations = new Set < string > ();
            return (<tr key={i + "sl"}>
                <td></td>
                <td colSpan="3">
                    <table className="table">
                        <thead>
                            <tr>
                                <th></th>
                                <th><FormattedMessage id="Manufacturing.Kanban.stock_location" defaultMessage="Stock location" /></th>
                            </tr>
                        </thead>
                        <tbody>
                            {material.stock_locations.map((stock_location, j) => {
                                if (done_locations.has(stock_location.external_id)) { return null; }
                                done_locations.add(stock_location.external_id);
                                const enabled = kanban_material.stock_locations.includes(stock_location.external_id);
                                return (<tr key={j}>
                                    <td>
                                        <input type="checkbox" name="enabled" checked={enabled}
                                            onChange={(event) => { this.handleStockLocationEnabled(event, material.uuid, stock_location.external_id) }} />
                                    </td>
                                    <td>{stock_location.title.replace("_", " ")}</td>
                                </tr>)
                            })}
                        </tbody>
                    </table>
                    <table className="table">
                        <tbody>
                            <tr>
                                <td>
                                    <input type="checkbox" name="unconfirmed_stock" checked={kanban_material.unconfirmed_stock}
                                        onChange={(event) => { this.handleUnconfirmedStockEnabled(event, kanban_material.material_uuid) }}
                                    />
                                </td>
                                <td>
                                    <FormattedMessage id="Manufacturing.Kanban.use_unconfirmed_stock" defaultMessage="Add derived quntities" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>);
        }
        return null;
    }

    renderAddingMaterial() {
        return (
            <table className="table">
                <thead>
                    <tr>
                        <th><FormattedMessage id="Manufacturing.Kanban.material" defaultMessage="Material" /></th>
                        <th><FormattedMessage id="Manufacturing.Kanban.min" defaultMessage="Min" /></th>
                        <th><FormattedMessage id="Manufacturing.Kanban.max" defaultMessage="Max" /></th>
                    </tr>
                </thead>
                <tr>
                    <td>{this.state.adding_material_title}</td>
                    <td>
                        <input className="form-control min-width80px"
                            type="number"
                            value={this.state.adding_material_min || 0}
                            onChange={(e) => this.setState({ adding_material_min: e.target.value })}
                        />
                    </td>
                    <td>
                        <input className="form-control min-width80px"
                            type="number"
                            value={this.state.adding_material_max || 0}
                            onChange={(e) => this.setState({ adding_material_max: e.target.value })}
                        />
                    </td>
                </tr>
            </table>
        );
    }

    renderMaterials() {
        return (
            this.state.materials.map((material, i) => {
                const enabled = this.isMaterialEnabled(material.uuid);
                const kanban_material = this.getKanbanMaterial(material.uuid);
                const result = [
                    (<tr key={i}>
                        <td>
                            <input type="checkbox" name="enabled" checked={enabled}
                                onChange={(event) => { this.handleEnabled(event, material) }}
                            />
                        </td>
                        <td>{material.title}</td>
                        <td>
                            <input className="form-control min-width80px"
                                disabled={!enabled} type="text" name={"min_stock"}
                                value={(enabled && kanban_material !== null) ? kanban_material.min_stock : "-"}
                                onChange={(event) => { this.handleRange(event, material.uuid) }}
                            />
                        </td>
                        <td>
                            <input className="form-control min-width80px"
                                disabled={!enabled} type="text" name={"max_stock"}
                                value={(enabled && kanban_material !== null) ? kanban_material.max_stock : "-"}
                                onChange={(event) => { this.handleRange(event, material.uuid) }}
                            />
                        </td>
                    </tr>),
                    (<tr key={i + "us"}>
                        <td></td>
                        <td colSpan={3}>
                        </td>
                    </tr>)
                ];
                // if material is selected, also offer stock locations to monitor
                if (enabled && kanban_material !== null) {
                    result.push(this.renderStockLocations(i, material, kanban_material));
                }
                return result;
            })
        );
    }

    handleAddMaterialWithExternalId = async () => {

        const data = await getBackend().manufacturing
            .getStockLocationsForMaterial({ material_external_id: this.state.adding_material_external_id });

        if (data.stock_locations.length === 0) {
            this.setState({
                error: translate("KanbanList.no_stock_locations_found",
                    `No stock locations found for material with external id ${this.state.adding_material_external_id}`
                ).replace("{material_external_id}", this.state.adding_material_external_id)
            });
            return;
        }

        this.setState({
            adding_materials_stock_locations: data.stock_locations,
            adding_material_uuid: data.material_uuid,
            adding_material_title: data.material_title
        });
    }

    renderAddNewMaterial() {
        return (
            <div style={{
                paddingBottom: "30px", borderTop: "2px solid black",
                paddingTop: "10px", marginTop: "20px"
            }}>
                <div style={{ paddingBottom: "5px", fontWeight: "600", fontSize: "16px" }}>
                    <FormattedMessage id="Manufacturing.Materials.add_material" defaultMessage="Add new material" />
                </div>

                <div style={{ display: "flex" }}>
                    <div style={{ position: "relative", display: "flex", alignItems: "center", flexDirection: "flex-start", width: "80px" }}>
                        Material id
                    </div>
                    <input
                        style={{ width: "150px" }}
                        className="form-control min-width80px"
                        type="text" name="material_external_id"
                        value={this.state.adding_material_external_id}
                        onChange={(e) => this.setState({ adding_material_external_id: e.target.value })}
                    />
                    <button
                        className="btn btn-outline-primary"
                        style={{ height: "40px", marginLeft: "10px" }}
                        onClick={() => this.handleAddMaterialWithExternalId()}>
                        <FormattedMessage id="common.search" defaultMessage="Search" />
                    </button>
                </div>
            </div>
        );
    }

    renderAddNewMaterialStockLocations() {

        if (this.state.adding_materials_stock_locations.length === 0) {
            return null;
        }

        return (
            <div>
                {this.renderAddingMaterial()}
                <table className="table">
                    <thead>
                        <tr>
                            <th></th>
                            <th><FormattedMessage id="Manufacturing.Kanban.stock_location" defaultMessage="Stock location" /></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.adding_materials_stock_locations.map((stock_location, i) => {

                            const enabled = this.state.adding_materials_stock_locations[i].checked;
                            return (<tr key={i}>
                                <td></td>
                                <td>
                                    <input type="checkbox" name="enabled" checked={enabled}
                                        onChange={(e) => { this.changeCheckedStockLocation(i, e.target.checked) }} />
                                </td>
                                <td> {stock_location.title}</td>
                            </tr>)
                        })}
                        <tr>
                            <td></td>
                            <td>
                                <input type="checkbox"
                                    checked={this.state.adding_material_unconfirmed_stock}
                                    onChange={(e) => { this.setState({ adding_material_unconfirmed_stock: e.target.checked }) }}
                                />
                            </td>
                            <td>
                                <FormattedMessage id="Manufacturing.Kanban.use_unconfirmed_stock" defaultMessage="Add derived quntities" />
                            </td>
                        </tr>

                    </tbody>
                </table>
                <button
                    className="btn btn-outline-primary"
                    onClick={this.handleAddNewMaterialToKanban}>
                    <FormattedMessage id="common.add" defaultMessage="Add this material to kanban" />
                </button>
            </div>
        );

    }

    renderTable() {
        return (
            <div>
                <ErrorComponent msg={this.state.error} type="error" />
                <ErrorComponent msg={this.state.warning} type="warning" />
                <h4><FormattedMessage id="Manufacturing.Kanban.edit_title" defaultMessage="Edit kanban parameters" /></h4>
                <h5>{this.state.kanban.line_title}</h5>

                <table className="table">
                    <thead>
                        <tr>
                            <th></th>
                            <th><FormattedMessage id="common.material" defaultMessage="Material" /></th>
                            <th>Min</th>
                            <th>Max</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderMaterials()}
                    </tbody>
                </table>

                <ErrorComponent msg={this.state.error} type="error" />
                <ErrorComponent msg={this.state.warning} type="warning" />

                <div className="button-list order-last ml-auto">
                    <button className="btn btn-outline-primary" onClick={this.handleSubmit}>
                        <FormattedMessage id="common.save" defaultMessage="Save" />
                    </button>
                    <button className="btn btn-outline-secondary" onClick={this.handleCancel}>
                        <FormattedMessage id="common.back" defaultMessage="Back" />
                    </button>
                </div>

                {this.renderAddNewMaterial()}
                {this.renderAddNewMaterialStockLocations()}

                {this.state.adding_material_uuid && <ErrorComponent msg={this.state.error} type="error" />}
                {this.state.adding_material_uuid && <ErrorComponent msg={this.state.warning} type="warning" />}
            </div>
        );
    }

    changeCheckedStockLocation = (i: number, checked: boolean) => {
        const adding_materials_stock_locations = this.state.adding_materials_stock_locations;
        adding_materials_stock_locations[i].checked = checked;
        this.setState({ adding_materials_stock_locations: adding_materials_stock_locations });
    }

    /**
     * Rendering JSX for current component.
     */
    render() {
        return (
            <div key="ds_new">
                <section key="1">
                    <img src="/img/banner.png" alt="banner" className="img-fluid-header banner_img" />
                </section>
                <section id="profile_edit" className="new_data_source" key="2">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="white_box">
                                    <div className="tab-content">
                                        {this.isLoaded() ? this.renderTable() : (<Loader />)}
                                        <div className="space30"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default LineKanbanEdit;
