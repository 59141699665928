// @flow
import React from "react";
import Select from "react-select";
import { connect } from "react-redux";
import Toggle from "react-toggle";
import KpiTable from "./KpiTable";
import { ADD_KPI_ACTION_NAME } from "./reducers/kpis";
import { getBackend as getBackend2 } from "../../../lib/backend/Backend2";
import { niceDateTime } from "../../../lib/Util";
import { translate } from "../../IntlProviderWrapper";
import { LINE_GROUP_TAGS } from "../../../lib/ManufacturingTags.generated";
import * as BusinessLogic from "../../../lib/BusinessLogic";
import { FormGroup, ControlLabel, Modal } from "react-bootstrap";
import { PLAN_HORIZON_UNIT_AUTOMATIC_SCHEDULING } from "../../../lib/ManufacturingTags.generated";

import type { RecommendPlanParamsAndCriteria } from "./RecommendPlan";
import type { RecommendPlan } from "./reducers/recommendPlan";
import type { AddKpisAction, Kpis } from "./reducers/kpis";
import type { ReduxState } from "./reducers/reducers";
import type { IWeekKPIs } from "../../../lib/backend/reports.generated.types";

type RecommendPlanSettingsProps = {
    parameters: RecommendPlanParamsAndCriteria,
    line_group_uuid: string,
    show_modal: boolean,
    onCloseModal: () => void
};
type RecommendPlanSettingsState = {};

const isWeekUnit = (line_group_uuid: string): boolean => {
    return BusinessLogic.getLineGroupTagStr(
        line_group_uuid,
        LINE_GROUP_TAGS.planning_time_horizont_unit,
        PLAN_HORIZON_UNIT_AUTOMATIC_SCHEDULING.week_unit
    ) === PLAN_HORIZON_UNIT_AUTOMATIC_SCHEDULING.week_unit;
}

class RecommendPlanInfoModal extends React.Component<RecommendPlanSettingsProps, RecommendPlanSettingsState> {

    getHorizonUnit = () => {
        const automatic_scheduling_unit = BusinessLogic.getLineGroupTagStr(
            this.props.line_group_uuid,
            LINE_GROUP_TAGS.planning_time_horizont_unit,
            PLAN_HORIZON_UNIT_AUTOMATIC_SCHEDULING.week_unit
        );

        if (automatic_scheduling_unit === PLAN_HORIZON_UNIT_AUTOMATIC_SCHEDULING.shift_unit) {
            return translate("common.shift", "Shift");
        }

        return translate("common.week", "Week");
    }

    render() {
        const show_break_orders = BusinessLogic.getLineGroupTagBool(this.props.line_group_uuid, LINE_GROUP_TAGS.planning_break_orders, false);
        const show_customer_priority = BusinessLogic.getLineGroupTagBool(this.props.line_group_uuid, LINE_GROUP_TAGS.planning_track_customer_priorty, false);
        const show_track_stock_levels = BusinessLogic.getLineGroupTagBool(this.props.line_group_uuid, LINE_GROUP_TAGS.planning_track_stock_levels, false);

        const {
            recommend_param_break_orders,
            recommend_param_track_input_material,
            recommend_param_use_safety_stock,
            recommend_param_include_plan_orders,
            recommend_param_freeze_work_orders,
            recommend_param_freeze_shifts,
            recommend_param_time_horizont_shifts,
            recommend_param_customer_priority,
            recommend_criteria_delays,
            recommend_criteria_tool_changes,
            recommend_criteria_intermediate_stock,
        } = this.props.parameters;

        return <Modal show={this.props.show_modal} onHide={this.props.onCloseModal}>
            <Modal.Header>
                <Modal.Title>
                    {translate("Manufacturing.Planning.recommend_params_title", "Scheduling parameters and criteria")}
                </Modal.Title>
                <button type="button" className="close" onClick={this.props.onCloseModal}>
                    <span aria-hidden="true">×</span>
                    <span className="sr-only">
                        {translate("common.close", "Close")}
                    </span>
                </button>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <h5>{translate("Manufacturing.Planning.parameters", "Parameters")}</h5>
                    {show_break_orders && <FormGroup controlId="param_break_orders" bsSize="small">
                        <Toggle id="recommend_param_break_orders" className="float-right"
                            checked={recommend_param_break_orders} />
                        <ControlLabel>
                            {translate("Manufacturing.Planning.param_break_orders", "Break order in multuple parts")}
                        </ControlLabel>
                    </FormGroup>}
                    <FormGroup controlId="param_track_input_material" bsSize="small">
                        <Toggle id="recommend_param_track_input_material" className="float-right"
                            checked={recommend_param_track_input_material} />
                        <ControlLabel>
                            {translate("Manufacturing.Planning.param_track_input_material", "Track input materials")}
                        </ControlLabel>
                    </FormGroup>
                    <FormGroup controlId="param_use_safety_stock" bsSize="small">
                        <Toggle id="recommend_param_use_safety_stock" className="float-right"
                            checked={recommend_param_use_safety_stock} />
                        <ControlLabel>
                            {translate("common.include_safety_stocks", "Allow usage of safety stock")}
                        </ControlLabel>
                    </FormGroup>
                    <FormGroup controlId="param_plan_orders" bsSize="small">
                        <Toggle id="recommend_param_include_plan_orders" className="float-right"
                            checked={recommend_param_include_plan_orders} />
                        <ControlLabel>
                            {translate("Manufacturing.Planning.param_plan_orders", "Include plan orders")}
                        </ControlLabel>
                    </FormGroup>
                    <FormGroup controlId="param_freez_work_orders" bsSize="small">
                        <Toggle id="recommend_param_freeze_work_orders" className="float-right"
                            checked={recommend_param_freeze_work_orders} />
                        <ControlLabel>
                            {translate("Manufacturing.Planning.param_freeze_work_orders", "Freez work orders")}
                        </ControlLabel>
                    </FormGroup>
                    <FormGroup controlId="param_freeze_shifts" bsSize="small">
                        <input className="float-right form-control"
                            style={{ width: "80px", marginLeft: "10px" }}
                            type="number"
                            value={recommend_param_freeze_shifts}
                            disabled
                        />
                        <ControlLabel>
                            {translate("Manufacturing.Planning.param_freeze_shifts", "Freeze shifts")}
                        </ControlLabel>
                    </FormGroup>
                    <FormGroup controlId="param_plan_horizont" bsSize="small">
                        <input className="float-right form-control"
                            style={{ width: "80px", marginLeft: "10px" }}
                            type="number"
                            value={isWeekUnit(this.props.line_group_uuid) ? Math.round(recommend_param_time_horizont_shifts / 21) : recommend_param_time_horizont_shifts}
                            disabled
                        />
                        <ControlLabel>
                            {translate("Manufacturing.Planning.param_plan_horizont_unit", `Plan horizont`) + ` (${this.getHorizonUnit()})`}
                        </ControlLabel>
                    </FormGroup>
                    {show_customer_priority && <FormGroup controlId="param_customer_priority" bsSize="small">
                        <Toggle id="recommend_param_customer_priority" className="float-right"
                            checked={recommend_param_customer_priority}
                            disabled
                        />
                        <ControlLabel>
                            {translate("Manufacturing.Planning.param_customer_priority", "Account for customer priority")}
                        </ControlLabel>
                    </FormGroup>}

                    <h5>
                        {translate("Manufacturing.Planning.criteria", "Criteria")}
                    </h5>
                    <FormGroup controlId="criteria_delays" bsSize="small">
                        <Select className="float-right"
                            value={{
                                label: `${recommend_criteria_delays}`,
                                value: recommend_criteria_delays
                            }}
                            options={[0, 1, 2, 3, 4, 5].map(v => ({ label: `${v}`, value: v }))}
                            disabled
                        />
                        <ControlLabel>
                            {translate("Manufacturing.Planning.criteria_delays", "Delays")}
                        </ControlLabel>
                    </FormGroup>
                    <FormGroup controlId="criteria_tool_changes" bsSize="small">
                        <Select className="float-right"
                            value={{
                                label: `${recommend_criteria_tool_changes}`,
                                value: recommend_criteria_tool_changes
                            }}
                            options={[0, 1, 2, 3, 4, 5].map(v => ({ label: `${v}`, value: v }))}
                            disabled
                        />
                        <ControlLabel>
                            {translate("Manufacturing.Planning.criteria_tool_changes", "Tool changes")}
                        </ControlLabel>
                    </FormGroup>
                    {show_track_stock_levels && <FormGroup controlId="criteria_intermediate_stock" bsSize="small">
                        <Select className="float-right"
                            value={{
                                label: `${recommend_criteria_intermediate_stock}`,
                                value: recommend_criteria_intermediate_stock
                            }}
                            options={[0].map(v => ({ label: `${v}`, value: v }))}
                            disabled
                        />
                        <ControlLabel>
                            {translate("Manufacturing.Planning.criteria_intermediate_stock", "Value of intermediate stock")}
                        </ControlLabel>
                    </FormGroup>}
                </div>
            </Modal.Body>
        </Modal>
    }
}

function calcKpiSum(kpis: IWeekKPIs[]): IWeekKPIs {
    const sum = (a, b) => a + b;
    return {
        available_hours: kpis.map(kpi => kpi.available_hours).reduce(sum, 0),
        delay_operations: kpis.map(kpi => kpi.delay_operations).reduce(sum, 0),
        no_input_materials_operations: kpis.map(kpi => kpi.no_input_materials_operations).reduce(sum, 0),
        tool_changes_count: kpis.map(kpi => kpi.tool_changes_count).reduce(sum, 0),
        tool_changes_hours: kpis.map(kpi => kpi.tool_changes_hours).reduce(sum, 0),
        used_hours: kpis.map(kpi => kpi.used_hours).reduce(sum, 0),
        week: -1,
        worker_hours: kpis.map(kpi => kpi.worker_hours).reduce(sum, 0),
        year: -1
    }
}

type Props = {
    is_loading: boolean,
    planning_weeks: number,
    original_report_uuid: string,
    report_uuid: string,
    line_group_uuid: string,
    is_report_loading: boolean,
    kpis: Kpis[],
    line_uuids: string[],
    recommend_plans: RecommendPlan[],
    reduxDispatch: (args: AddKpisAction) => void
};

type State = {
    dropdown2_value: string,
    dropdown1_value: string,
    new_base: boolean,
    loading: boolean,
    parameters: RecommendPlanParamsAndCriteria | null,
    show_modal: boolean
}

type SelectOption = {
    label: string,
    value: string
};

class KpisContainer extends React.Component<Props, State> {

    state = {
        dropdown2_value: "",
        dropdown1_value: "",
        loading: false,
        new_base: true,
        parameters: null,
        show_modal: false
    }

    componentDidMount() {
        this.load();
        this.setState({ loading: this.props.is_loading });
    }

    componentDidUpdate = async (prev_props: Props, prev_state: State) => {
        if (prev_props.is_loading !== this.props.is_loading) {
            this.setState({ loading: this.props.is_loading })
        }

        if (prev_state.dropdown2_value !== this.state.dropdown2_value && this.state.dropdown2_value) {
            this.loadReport(this.state.dropdown2_value, true);
        }

        if (prev_state.dropdown1_value !== this.state.dropdown1_value && this.state.dropdown1_value) {
            this.loadReport(this.state.dropdown1_value);
        }

        if (prev_props.recommend_plans !== this.props.recommend_plans ||
            prev_props.original_report_uuid !== this.props.original_report_uuid ||
            prev_props.report_uuid !== this.props.report_uuid) {
            this.load();
        }
    }

    trimKpis = (weeks: IWeekKPIs[]): IWeekKPIs[] => {
        if (weeks) {
            if (weeks.length > this.props.planning_weeks) {
                return weeks.slice(0, this.props.planning_weeks);
            }
            return weeks;
        }
        return [];
    }

    loadReport = async (report_uuid: string, is_initial?: boolean) => {
        const report_exists = this.props.kpis.some(k => k.report_uuid === report_uuid);
        if (report_exists) {
            return;
        }

        this.setState({ loading: true })
        const res = await getBackend2().reports.getReportKPIs({
            id: report_uuid,
            line_uuids: this.props.line_uuids
        });

        if (res && res.weeks) {
            const date = new Date();
            this.props.reduxDispatch({
                type: ADD_KPI_ACTION_NAME,
                data: {
                    name: niceDateTime(date),
                    time_ts: date.getTime(),
                    is_initial,
                    report_uuid: report_uuid,
                    kpis: this.trimKpis(res.weeks)
                }
            });
        }
        this.setState({ loading: false });
    }

    isRecommendPlanReport = (report_uuid: string) => {
        return this.props.recommend_plans.some(k => k.report_uuid === report_uuid);
    }

    getOptions = (): SelectOption[] => {
        const data = [];
        for (const recommend_plan of this.props.recommend_plans) {
            const is_not_current_report = recommend_plan.report_uuid !== this.props.report_uuid; // will be added at the end
            if (recommend_plan.report_uuid && is_not_current_report) {
                data.push({
                    value: recommend_plan.report_uuid,
                    label: niceDateTime(new Date(recommend_plan.plan_created_ts))
                });
            }
        }

        const report_uuid = this.props.original_report_uuid;
        if (!report_uuid) {
            return [];
        }

        const options = [
            {
                value: report_uuid,
                label: translate("common.initial_version", "Initial version")
            },
            ...data
        ];

        if (this.props.report_uuid !== this.props.original_report_uuid && this.props.report_uuid) {
            const is_recommend = this.isRecommendPlanReport(this.props.report_uuid);
            options.push(
                {
                    value: this.props.report_uuid,
                    label: translate("common.current_version", "Current version") + (is_recommend ? " (A)" : "")
                }
            );
        }

        return options;
    }

    onDropdown2Change = (option: SelectOption) => {
        this.setState({ dropdown2_value: option.value });
    }

    onDropdown1Change = (option: SelectOption) => {
        this.setState({ dropdown1_value: option.value });
    }

    load = () => {
        const options = this.getOptions();

        // dropdown 1 holds current report
        if (options.length > 0 && this.state.dropdown1_value !== this.props.report_uuid) {
            this.setState({ dropdown1_value: this.props.report_uuid });
        }

        // dropdown 2 holds first report
        if (options.length > 0 && this.state.dropdown2_value !== this.props.original_report_uuid) {
            return this.setState({ dropdown2_value: this.props.original_report_uuid });
        }
    }

    getDropdownValue2 = () => {
        const options = this.getOptions();
        return options.find(o => o.value === this.state.dropdown2_value) || null;
    }

    getDropdownValue1 = () => {
        const options = this.getOptions();
        return options.find(o => o.value === this.state.dropdown1_value) || null;
    }

    renderKpiTable = () => {
        if (!(this.state.dropdown2_value && this.state.dropdown1_value)) {
            return null
        }

        const base_kpi = this.props.kpis.find(k => k.report_uuid === this.state.dropdown2_value);
        const kpi = this.props.kpis.find(k => k.report_uuid === this.state.dropdown1_value);

        if (!base_kpi || !kpi) {
            return null;
        }

        return <KpiTable
            is_loading={this.props.is_loading}
            planning_weeks={this.props.planning_weeks}
            original_report_uuid={base_kpi.report_uuid}
            report_uuid={kpi.report_uuid}
            line_uuids={this.props.line_uuids}
            base_kpi_sum={calcKpiSum(base_kpi.kpis)}
            kpi_sum={calcKpiSum(kpi.kpis)}
            kpis={kpi.kpis}
            base_kpis={base_kpi.kpis}
        />;

    }

    onOpenInfoModal = (option: SelectOption) => (e: Event) => {
        const recommend_plan = this.props.recommend_plans.find(rp => rp.report_uuid === option.value);
        if (!recommend_plan) {
            return null;
        }

        this.setState({
            show_modal: true,
            parameters: recommend_plan.parameters.state
        })
    }

    onCloseModal = () => {
        this.setState({ show_modal: false, parameters: null });
    }

    render() {
        const options = this.getOptions();
        const dropdown2_value = this.getDropdownValue2();
        const dropdown1_value = this.getDropdownValue1();

        let is_dropdown1_recommend = false;
        if (dropdown1_value) {
            is_dropdown1_recommend = this.isRecommendPlanReport(dropdown1_value.value);
        }

        let is_dropdown2_recommend = false;
        if (dropdown2_value) {
            is_dropdown2_recommend = this.isRecommendPlanReport(dropdown2_value.value)
        }

        return <div>
            <div className="kpis-container-dropdowns">
                <div className="dropdown1">
                    <button
                        id="save-buttons-container"
                        className="btn btn-outline-primary btn-blue"
                        onClick={dropdown1_value && this.onOpenInfoModal(dropdown1_value)}
                        type="button"
                        aria-expanded="false"
                        disabled={!is_dropdown1_recommend}
                    >
                        <i className="fas fa-users-cog"></i>
                    </button>
                    <Select
                        id="kpis-dropdown1"
                        value={dropdown1_value}
                        options={options}
                        onChange={this.onDropdown1Change}
                    />
                </div>
                <span style={{display: "flex", alignSelf: "center", marginLeft: "10px"}}>/</span>
                <div className="dropdown2">
                    <Select
                        id="kpis-dropdown2"
                        value={dropdown2_value}
                        options={options}
                        onChange={this.onDropdown2Change}
                    />
                    <button
                        id="save-buttons-container"
                        onClick={dropdown2_value && this.onOpenInfoModal(dropdown2_value)}
                        type="button"
                        className="btn btn-outline-primary btn-blue"
                        aria-haspopup="true"
                        aria-expanded="false"
                        style={{marginLeft: "10px"}}
                        disabled={!is_dropdown2_recommend}
                    >
                        <i className="fas fa-users-cog"></i>
                    </button>
                </div>
            </div>
            {this.renderKpiTable()}
            {this.state.parameters && <RecommendPlanInfoModal
                line_group_uuid={this.props.line_group_uuid}
                parameters={this.state.parameters}
                show_modal={this.state.show_modal}
                onCloseModal={this.onCloseModal}
            />}
        </div>
    }

}

export default connect(
    (state: ReduxState) => {
        // we show same number of weeks as on the planning board
        const planning_weeks = Math.floor(state.gantt_chart_properties.days / 7) ?? 0;
        // we use original report as base
        const original_report_uuid = state.gantt_chart_report.original_report_uuid;
        // list of lines we are planning so we focus with KPIs only on those
        const line_uuids = state.gantt_chart_lines_orders.lines_orders.map(lo => lo.line_uuid);
        const recommend_plans = state.gantt_chart_recommend_plans.recommend_plans;
        const report_uuid = state.gantt_chart_report.current_report_uuid;

        return {
            kpis: state.gantt_chart_kpis.kpis,
            planning_weeks,
            line_uuids,
            original_report_uuid,
            recommend_plans,
            report_uuid: report_uuid || original_report_uuid
        }
    },
    (dispatch) => ({ reduxDispatch: dispatch })
)(KpisContainer);
