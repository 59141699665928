// @flow
import * as React from "react";
import * as Highcharts from "highcharts";
import { hashCode } from "../../lib/Util";

// extra import of bullet module since bullet graph is not part of the default highcharts lib
require("highcharts/modules/bullet")(Highcharts);

type DashboardBulletGraphBand = {
    from: number,
    to: number,
    color: string
};

type Props = {
    id: string,
    value: number,
    bands: Array<DashboardBulletGraphBand>,
    onClick: any
};
type State = {
    highchartsOk: boolean
};

class BulletGraph extends React.Component<Props, State> {
    constructor() {
        super();
        this.state = {
            highchartsOk: true
        };
    }

    componentDidMount() {
        try {
            // figure out min and max bands values
            let min = (this.props.bands.length > 0) ? this.props.bands[0].from : 0;
            let max = (this.props.bands.length > 0) ? this.props.bands[0].to : 0;
            for (let band of this.props.bands) {
                if (band.from < min) { min = band.from; }
                if (band.to > max) { max = band.to; }
            }

            Highcharts.chart(this.props.id, {
                chart: {
                    inverted: true,
                    type: "bullet"
                },
                legend: {
                    enabled: false
                },
                xAxis: {
                    categories: [""]
                },
                yAxis: {
                    gridLineWidth: 0,
                    plotBands: this.props.bands,
                    min: min,
                    max: max,
                    title: null
                },
                series: [{
                    color: "#000",
                    data: [{
                        y: this.props.value,
                        target: this.props.value
                    }]
                }],
                tooltip: {
                    pointFormat: "<b>{point.y}</b>"
                },
                credits: {
                    enabled: false
                },
                exporting: {
                    enabled: false
                },
                title: { enabled: false, text: null },
                subtitle: { enabled: false, text: null }
            });
        } catch (err) {
            console.log("Error rendering highcharts bullet graph:", err);
            this.setState({ highchartsOk: false });
        }
    }

    render() {
        return this.state.highchartsOk ? (
            <div id={this.props.id}
                 className="dsh_bullet_graph_container"
                 onClick={() => this.props.onClick() }>
            </div>
        ) : (<span>Error rendering bullet graph</span>);
    }
}

// factory method that collects the necessary data and parameters and construct Bullet Graph
/**
   Example widget config:

{
    "type": "bullet-graph",
    "key": "kpi_pee",
    "bands": [
        {
            "from": 0,
            "to": 0.5,
            "color": "#e33200"
        }, {
            "from": 0.5,
            "to": 0.9,
            "color": "#afca0b"
        }, {
            "from": 0.9,
            "to": 1.0,
            "color": "#e39b00"
        }
    ]
}
   */
export function createBulletGraph(data: any, widget: any) {
    // use key as the ID identifying container for the highcharts
    const id = widget.key;
    // collect the value of the indicator we are displaying
    const hits = data.indicators.filter(x => x.key === widget.key);
    // default value, when indicator does not exist is 0.0
    const value = (hits.length > 0) ? hits[0].val : (widget.value ? widget.value : 0.0);
    // get bands details
    const bands = widget.bands;
    // get URL to redirect when we click on the widget
    const url = widget.url;
    // get unique key
    const hashKey = hashCode(JSON.stringify(data || "") + JSON.stringify(widget || ""));

    // create the widget!
    return (<BulletGraph id={id} value={value} bands={bands} key={hashKey}
            onClick={() => { if (url) { window.location = url; } } }
    />);
}

export default BulletGraph;
