// @flow
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Modal } from "react-bootstrap";
import { SortableContainer, SortableElement, arrayMove } from "react-sortable-hoc";
import Toggle from "react-toggle";
import TranslatedMaterialTitle from "../../TranslatedMaterialTitle";
import { niceNumber, parseFloatSmart, niceDate, classNames } from "../../../lib/Util";
import { getLineGroupsForUser, getPlantsForUser } from "../../../lib/BusinessLogic";
import { INSIGHT_TYPES, LINE_GROUP_TAGS_ACCESS, ORDER_TAGS, ORDER_TAGS_ACCESS, PLANT_TAGS_ACCESS } from "../../../lib/ManufacturingTags.generated";
import { ORDER_TYPE } from "../../../lib/ManufacturingConsts.generated";
import Authorization from "../../Authorization";
import { PERMISSION_NAMES } from "../../../lib/Auth";
import { translate } from "../../IntlProviderWrapper";
import { getBackend } from "../../../lib/backend/Backend2";
import ConfirmationModal from "../../ConfirmationModal";
import { OperationsTable } from "../PlanningTable2/LeftMenu/IgnoredOrders";
import ErrorComponent from "../../ErrorComponent";

import * as t from "../../../lib/backend/manufacturing2.generated.types";
import type { IEventDataEx } from "../../../lib/backend/reports.generated.types";
import type { IOrderProducedModelEx } from "./LinePlanning";

export type OrderObjGui = {
    capacity_factor: number,
    capacity_factor_str: string,
    capacity_overriden: boolean,
    earliest_end: string,
    earliest_start: string,
    earliest_end_original: number,
    earliest_start_original: number,
    enabled: boolean,
    ignore_on_planning_board: boolean,
    external_id: string,
    has_production?: boolean,
    initial_earliest_end: string,
    initial_earliest_start: string,
    material_external_id: string,
    material_title: string,
    measurement_unit: string,
    order_type: string,
    process_uuid: string,
    production_version: string,
    quantity_total: number,
    quantity_total_str: string,
    sequence_weight: number,
    sim_ignore_earliest_start: boolean,
    uuid: string,
    external_parent_order_id: string,
    process_num: string,
    skip_sim_enabled: boolean,
    hide_skip_sim: boolean,
    show_ignore_order: boolean,
    show_ignore_parent_order: boolean,
    is_paralel_order: boolean
}

const IGNORE_ACTIONS = {
    ignore_order: "ignore_order",
    ignore_parent_order: "ignore_parent_order"
};

const isParallelOrder = (order: IOrderProducedModelEx | t.IOrderModelBase): boolean => {
    return (
        ORDER_TAGS_ACCESS.parallel_order(order.tags) ||
        ORDER_TAGS_ACCESS.parallel_parent(order.tags)
    );
};

function createOrderObjGui(input: IOrderProducedModelEx): OrderObjGui {
    const digits = input.measurement_unit === "KG" ? 3 : 0;
    const plant = getPlantsForUser().find(p => p.uuid === input.plant_uuid);
    const line_group_uuid = localStorage.getItem("last-linegroup");
    const line_group = getLineGroupsForUser().find(lg => lg.uuid === line_group_uuid);
    const plan_import_scheduled_statuses = PLANT_TAGS_ACCESS.plan_import_scheduled_statuses(
        plant !== undefined ? plant.tags : {}
    );
    const planning_board = LINE_GROUP_TAGS_ACCESS.planning_board(line_group !== undefined ? line_group.tags : {});
    const plant_tags = plant !== undefined && plant.tags !== undefined ? plant.tags : {};
    const show_ignore_order = PLANT_TAGS_ACCESS.planning_table_show_ignore_order(plant_tags);
    const show_ignore_parent_order = PLANT_TAGS_ACCESS.planning_table_show_ignore_parent_order(plant_tags);
    const is_paralel_order = isParallelOrder(input);

    return {
        capacity_factor: input.override_capacity_factor || input.capacity_factor,
        capacity_factor_str: niceNumber(100 * (input.override_capacity_factor || input.capacity_factor), 0),
        capacity_overriden: (input.override_capacity_factor !== null),
        earliest_end: niceDate(new Date(input.earliest_end)),
        earliest_end_original: input.earliest_end,
        earliest_start: niceDate(new Date(input.earliest_start)),
        earliest_start_original: input.earliest_start,
        enabled: !input.skip_sim,
        ignore_on_planning_board: ORDER_TAGS_ACCESS.ignore_on_planning_board(input.tags),
        external_id: input.external_id,
        has_production: input.has_production,
        initial_earliest_end: niceDate(new Date(input.initial_earliest_end)),
        initial_earliest_start: niceDate(new Date(input.initial_earliest_start)),
        material_external_id: input.material_external_id,
        material_title: input.material_title,
        measurement_unit: input.measurement_unit && input.measurement_unit.trim().toUpperCase(),
        order_type: input.order_type,
        process_uuid: input.process_uuid,
        production_version: input.tags[ORDER_TAGS.production_version] || "",
        quantity_total: input.quantity_total,
        quantity_total_str: niceNumber(input.quantity_total, digits),
        sequence_weight: input.sequence_weight,
        sim_ignore_earliest_start: input.sim_ignore_earliest_start,
        uuid: input.uuid,
        external_parent_order_id: ORDER_TAGS_ACCESS.external_parent_order_id(input.tags),
        process_num: input.process_num,
        skip_sim_enabled: !plan_import_scheduled_statuses,
        hide_skip_sim: !planning_board && !plan_import_scheduled_statuses,
        show_ignore_order,
        show_ignore_parent_order,
        is_paralel_order
    };
}

///////////////////////////////////////////////////
// Production plan order table item
function getInsights(order_uuid, insights_map, parent_children_map) {
    if (!insights_map) { return []; }
    // first take all direct insights
    const order_insights = insights_map.get(order_uuid) || [];
    // we have a map to keep stuff unique
    const insights = new Map();
    for (const order_insight of order_insights) {
        insights.set(order_insight.title, order_insight);
    }
    // collect insights from children
    const children_uuids = parent_children_map.get(order_uuid) || [];
    for (const child_uuid of children_uuids) {
        const child_insights = insights_map.get(child_uuid) || [];
        for (const child_insight of child_insights) {
            insights.set(child_insight.title, child_insight);
        }
    }
    return [...insights.values()];
}

function getWarningColor(insight_type: string): string {
    if (insight_type === INSIGHT_TYPES.tool_setup) { return "insight_info"; }
    if (insight_type === INSIGHT_TYPES.out_of_stock_shopfloor) { return "insight_error"; }
    if (insight_type === INSIGHT_TYPES.out_of_stock_plant) { return "insight_error"; }
    return "";
}

type EditableProps = {
    row_class: string,
    sim_ignore_earliest_start: boolean,
    earliest_start: string,
    capacity_factor_str: string,
    index: number,
    selected_order: Object,
    handleChange: Function
}

const EditableCell = (props: EditableProps) => {
    const {
        sim_ignore_earliest_start,
        earliest_start,
        handleChange,
        index,
        selected_order
    } = props;

    const selectStyle = {
        color: "inherit",
        width: "100px",
        height: "30px",
        fontWeight: "500"
    };

    const inputStyle = {
        background: !selected_order.enabled ? "inherit" : "white",
        color: !selected_order.enabled ? "inherit" : "black",
        width: "60px",
        height: "30px"
    };

    const show_ignore_order = !selected_order.is_paralel_order && selected_order.show_ignore_order;
    const show_ignore_parent_order = (
        !selected_order.is_paralel_order &&
        selected_order.show_ignore_parent_order &&
        selected_order.external_parent_order_id !== ""
    );

    return <tr className={props.row_class}>
        <td className="planning-table">
           <FormattedMessage id="Manufacturing.Planning.from" defaultMessage="From" />:<br />
            <Authorization.select
                className="form-control form-control-sm"
                name="sim_ignore_earliest_start"
                permission={PERMISSION_NAMES.OrdersAndShiftsEdit}
                onChange={(event) => {handleChange(index, event);}}
                style={selectStyle}
                value={sim_ignore_earliest_start.toString()}
            >
                <option value="true">24/7</option>
                <option value="false">{earliest_start}</option>
            </Authorization.select>
        </td>
        <td className="planning-table">
            <FormattedMessage id="Manufacturing.Planning.number_of_capacities" defaultMessage="Capacities [%]" />:<br />
            <Authorization.input
                disabled={!selected_order.enabled}
                permission={PERMISSION_NAMES.OrdersAndShiftsEdit}
                className={classNames("form-control form-control-sm", {
                    "color-blue-important": selected_order.capacity_overriden
                })}
                style={inputStyle}
                type={"text"}
                name={"capacity_factor"}
                value={selected_order.capacity_factor_str}
                onFocus={(event) => { handleChange(index, event) }}
                onBlur={(event) => { handleChange(index, event) }}
                onChange={(event) => { handleChange(index, event) }} />
        </td>
        {!selected_order.hide_skip_sim && (
            <td className="planning-table">
                <label
                    key="edit04"
                    className="mb-0"
                    title={translate("Manufacturing.Planning.ignore_in_sim_description")}
                >
                    <span className="d-flex flex-column">
                        <span>
                            <FormattedMessage id="Manufacturing.Planning.ignore_in_sim" defaultMessage="Unscheduled" />:
                        </span>
                        <Authorization.Toggle
                            className="d-block mr-auto"
                            id="skip_sim"
                            permission={PERMISSION_NAMES.OrdersAndShiftsEdit}
                            name="enabled"
                            checked={!selected_order.enabled}
                            onChange={(event) => { handleChange(index, event) }}
                            disabled={!selected_order.skip_sim_enabled}
                        />
                    </span>
                </label>
            </td>
        )}
        <td className="planning-table">
            <label
                key="edit05"
                className="mr-2 mb-0"
                title={translate("Manufacturing.Planning.ignore_on_planning_board_description")}
            >
                <span className="d-flex flex-column">
                    <span>
                        <FormattedMessage id="Manufacturing.Planning.ignore_on_planning_board" defaultMessage="Ignore" />:
                    </span>
                    <Authorization.Toggle
                        className="d-block mr-auto"
                        id="ignore_on_planning_board"
                        permission={PERMISSION_NAMES.OrdersAndShiftsEdit}
                        name="ignore_on_planning_board"
                        checked={selected_order.ignore_on_planning_board}
                        onChange={(event) => { handleChange(index, event) }}
                    />
                </span>
            </label>
            {(show_ignore_order || show_ignore_parent_order) && (
                <div className="d-inline-block align-bottom mt-2">
                    <button
                        className="btn btn-sm btn-short btn-outline-secondary"
                        style={{ width: 30 }}
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        <i className="fas fa-ellipsis-v" />
                    </button>
                    <div className="dropdown-menu dropdown-menu-right">
                        {show_ignore_order && (
                            <button
                                className="dropdown-item"
                                name={IGNORE_ACTIONS.ignore_order}
                                onClick={(event) => { handleChange(index, event) }}
                            >
                                {translate("common.ignore_order", "Ignore order")}
                            </button>
                        )}
                        {show_ignore_parent_order && (
                            <button
                                className="dropdown-item"
                                name={IGNORE_ACTIONS.ignore_parent_order}
                                onClick={(event) => { handleChange(index, event) }}
                            >
                                {translate("common.ignore_parent_order", "Ignore parent order")}
                            </button>
                        )}
                    </div>
                </div>
            )}
        </td>
    </tr>
}

let SortableItem = SortableElement(({ value, sortIndex, handleEdit, last_row, insights_map, parent_children_map, handleChange }) => {
    // figure out cell class based on being last row or not
    const row_class = !value.enabled ? "table-inactive" : "";

    // prepare item
    return (
        <li className={value.order_type === ORDER_TYPE.plan ? "planning-order" : ""}>
            <table test-id="planning-table" className="table planning-table mb-0">
                <tbody>
                    <tr className={row_class} key={sortIndex + "_r1"}>
                        <td className="planning-table no-border">
                            {value.order_type !== ORDER_TYPE.plan &&
                            <FormattedMessage id="Manufacturing.Planning.work_order" defaultMessage="Work order" />}
                            {value.order_type === ORDER_TYPE.plan &&
                            <FormattedMessage id="Manufacturing.Planning.plan_order" defaultMessage="Plan order" />}
                            : <strong>{value.external_id}</strong>
                            {value.order_type === ORDER_TYPE.parallel &&
                            <React.Fragment>
                                <br/><FormattedMessage id="Manufacturing.Planning.parallel_order" defaultMessage="Parallel order"></FormattedMessage>
                            </React.Fragment>}
                        </td>
                        <td className="planning-table no-border">
                            <FormattedMessage id="Manufacturing.Planning.ident" defaultMessage="Ident" />: <strong>{value.material_external_id}</strong>
                        </td>
                        <td className="planning-table no-border width130px" colSpan={2}>
                            <strong>{value.quantity_total_str} {value.measurement_unit}</strong>
                        </td>
                    </tr>
                    <tr className={row_class} key={sortIndex + "_r2"}>
                        <td className="planning-table no-border" colSpan={4}>
                            {value.process_num && <span style={{marginRight: "20px"}}>
                                <FormattedMessage id="OrderTableProduction.process_num" defaultMessage="Process number" />: {value.process_num}
                            </span>}
                            <TranslatedMaterialTitle material_external_id={value.material_external_id} material_title={value.material_title} />
                        </td>
                    </tr>
                    <EditableCell
                        row_class={row_class}
                        index={sortIndex}
                        earliest_start={value.earliest_start}
                        capacity_factor_str={value.capacity_factor_str}
                        sim_ignore_earliest_start={value.sim_ignore_earliest_start}
                        selected_order={value}
                        handleChange={handleChange}
                    />
                    {getInsights(value.uuid, insights_map, parent_children_map).map((insight, idx) => (
                        <tr className={row_class} key={`${sortIndex}_r4_${idx}`}>
                            <td colSpan={3} className={getWarningColor(insight.type)}>
                                <b>{insight.title}</b>
                            </td>
                        </tr>))}
                </tbody>
            </table>
        </li>
    );
});

///////////////////////////////////////////////////
// Production plan order list
let SortableList = SortableContainer(({ items, handleEdit, handleClick, insights_map, parent_children_map, compact, filtered_order_uuids }) => {
    const is_sort_disabled = items.length === 1 || filtered_order_uuids !== undefined && filtered_order_uuids.length === 1;
    return (
        <ul className={compact ? "order-list mb-0" : "mb-0"}>
            {items.map((value, index) => {
                if (filtered_order_uuids && filtered_order_uuids.length > 0 && !filtered_order_uuids.includes(value.uuid)) {
                    return null;
                }

                return (
                    <SortableItem
                        key={`item-${index}`}
                        value={value}
                        index={index}
                        sortIndex={index}
                        handleEdit={() => { handleEdit(index); }}
                        handleChange={handleClick}
                        last_row={index === (items.length - 1)}
                        insights_map={insights_map}
                        parent_children_map={parent_children_map}
                        disabled={is_sort_disabled}
                    />
                );
            })}
        </ul>
    );
});

///////////////////////////////////////////////////
// Production plan order table widget
type Props = {
    filtered_order_uuids?: string[],
    orders: IOrderProducedModelEx[],
    insights_map: Map<string, IEventDataEx[]>,
    parent_children_map: Map<string, string[]>,
    compact: boolean
};

type State = {
    items: Array<OrderObjGui>,
    updated: boolean,
    // order edit modal parameters
    show_order_edit_modal: boolean,
    selected_order_index: number,
    ignore_operations: t.IOrderModelBase[],
    ignore_action: string | null,
    show_warning: boolean
};

class LineOrderList extends React.Component<Props, State> {
    state: State;

    constructor(props: Props) {
        super(props);
        this.state = {
            items: [],
            updated: false,
            show_order_edit_modal: false,
            selected_order_index: 0,
            ignore_operations: [],
            ignore_action: null,
            show_warning: false
        };
    }

    componentDidMount() {
        this.updateItems();
    }

    updateItems = (): Promise<void> => {
        let items = [ ];
        if (this.props.orders) {
            for (let i = 0; i < this.props.orders.length; i++) {
                let item = this.props.orders[i];
                if (item.uuid) {
                    items.push(createOrderObjGui(item));
                }
            }
        }
        return new Promise((resolve) => {
            this.setState({
                items: items,
                updated: false,
                show_order_edit_modal: false
            }, resolve);
        });
    }

    onSortEnd = ({ oldIndex, newIndex }: Object) => {
        this.setState({
            items: arrayMove(this.state.items, oldIndex, newIndex),
            updated: true
        });
    };

    getOrders(): Array<OrderObjGui> {
        this.setWeights();
        return this.state.items;
    }

    getEnabledItems(): Array<OrderObjGui> {
        this.setWeights();
        return this.state.items.filter(x => x.enabled);
    };

    setWeights() {
        let prev = this.state.items;
        for (let i = 0; i < prev.length; i++) {
            prev[i].sequence_weight = i + 1;
        }
        this.setState({ items: prev });
    }

    /////////////////////////////////////////
    // Order edit modal dialog
    showOrderEditModal = (order_index: number) => {
        this.setState({
            show_order_edit_modal: true,
            selected_order_index: order_index
        });
    }

    hideOrderEditModal = () => {
        this.setState({ show_order_edit_modal: false });
    }

    async handleClick(index: number, event: Event) {
        let items = this.state.items;
        let target = event.target;

        if (target instanceof HTMLSelectElement) {
            if (target.type === "select-one") {
                if (target.name === "sim_ignore_earliest_start") {
                    items[index].sim_ignore_earliest_start = target.value === "true";
                }
            };
            this.setState({ items: items });
        } else if (target instanceof HTMLButtonElement) {
            let operations: t.IOrderModelBase[] = [];
            if (target.name === IGNORE_ACTIONS.ignore_parent_order) {
                const res = await getBackend().manufacturing.getOrdersSimple({
                    tags_obj: {
                        [ORDER_TAGS.external_parent_order_id]: items[index].external_parent_order_id
                    },
                    order_types: [ORDER_TYPE.full, ORDER_TYPE.plan]
                });
                if (res.operations.some(o => isParallelOrder(o))) {
                    this.setState({
                        show_warning: true
                    });
                    return;
                }

                operations = res.operations;
            } else {
                const res = await getBackend().manufacturing.getOrdersSimple({
                    order_external_id: items[index].external_id,
                    order_types: [ORDER_TYPE.full, ORDER_TYPE.plan]
                });
                if (res.operations.some(o => isParallelOrder(o))) {
                    this.setState({
                        show_warning: true
                    });
                    return;
                }

                operations = res.operations;
            }

            const unignored_operations = operations
                .filter(op => !ORDER_TAGS_ACCESS.ignore_on_planning_board(op.tags))
                .sort((a: t.IOrderModelBase, b: t.IOrderModelBase) => {
                    const external_id_comparison = a.external_id.localeCompare(b.external_id);
                    return (external_id_comparison !== 0)
                        ? external_id_comparison
                        : a.process_num.padStart(20, "0").localeCompare(b.process_num.padStart(20, "0"));
                });
            this.setState({
                ignore_operations: unignored_operations,
                ignore_action: target.name
            });
        } else if (target instanceof HTMLInputElement) {
            if (target.type === "checkbox") {
                if (target.name === "sim_ignore_earliest_start") {
                    items[index].sim_ignore_earliest_start = target.checked;
                } else if (target.name === "enabled") {
                    items[index].enabled = !target.checked;
                    // if skip_sim is false, ignore_on_planning_board must also be false
                    if (!target.checked) {
                        items[index].ignore_on_planning_board = target.checked;
                    }
                } else if (target.name === "ignore_on_planning_board") {
                    items[index].ignore_on_planning_board = target.checked;
                    // if ignore_on_planning_board is true, skip_sim must also be true
                    if (items[index].skip_sim_enabled && !items[index].hide_skip_sim && target.checked) {
                        items[index].enabled = !target.checked;
                    }
                }
            } else if (target.type === "text") {
                const value = target.value;
                if (target.name === "quantity_total") {
                    if (event.type === "focus") {
                        // on focus show the full precision
                        const quantity_total = items[index].quantity_total;
                        items[index].quantity_total_str = niceNumber(quantity_total, 16);
                    }
                    if (event.type === "blur") {
                        // parse on blur and change numeric value on success
                        // revert to previous value if parsing fails
                        const quantity_total = parseFloatSmart(value, items[index].quantity_total);
                        items[index].quantity_total = quantity_total;
                        const digits = items[index].measurement_unit === "KG" ? 3 : 0;
                        items[index].quantity_total_str = niceNumber(quantity_total, digits);
                    } else if (event.type === "change") {
                        // change only string value on change
                        items[index].quantity_total_str = value;
                    }
                } else if (target.name === "capacity_factor") {
                    if (event.type === "focus") {
                        // on focus show the full precision
                        const capacity_factor = items[index].capacity_factor;
                        items[index].capacity_factor_str = niceNumber(100 * capacity_factor, 16);
                    }
                    if (event.type === "blur") {
                        // parse on blur and change numeric value on success
                        // revert to previous value if parsing fails
                        const capacity_factor = parseFloatSmart(value, items[index].capacity_factor) / 100;
                        items[index].capacity_factor = capacity_factor;
                        items[index].capacity_factor_str = niceNumber(100 * capacity_factor, 0);
                    } else if (event.type === "change") {
                        // change only string value on change
                        items[index].capacity_factor_str = value;
                    }
                }
            } else {
                return;
            }
            this.setState({ items: items });
        }
    }

    handleAcceptIgnoreOperations = async () => {
        const { ignore_action, ignore_operations, items } = this.state;
        if (ignore_operations.length < 1 || items.length < 1) {
            this.setState({
                ignore_operations: [],
                ignore_action: null
            });
            return;
        }

        const { skip_sim_enabled, hide_skip_sim } = items[0];
        const ignore_external_id = ignore_action === IGNORE_ACTIONS.ignore_order
            ? ignore_operations[0].external_id
            : ORDER_TAGS_ACCESS.external_parent_order_id(ignore_operations[0].tags);
        for (const item of items) {
            const item_ignore_external_id = ignore_action === IGNORE_ACTIONS.ignore_order
                ? item.external_id
                : item.external_parent_order_id;
            if (item_ignore_external_id === ignore_external_id) {
                item.ignore_on_planning_board = true;
                if (item.skip_sim_enabled && !item.hide_skip_sim) {
                    item.enabled = false;
                }
            }
        }
        
        const update_orders: t.IUpdateOrderType[] = [];
        for (const operation of ignore_operations) {
            const update_order: t.IUpdateOrderType = {
                uuid: operation.uuid,
                tags: {
                    ...operation.tags,
                    [ORDER_TAGS.ignore_on_planning_board]: "true"
                }
            };
            if (skip_sim_enabled && !hide_skip_sim) {
                update_order.skip_sim = true;
            }

            update_orders.push(update_order);
        }

        await getBackend().manufacturing.updateOrders({
            orders: update_orders
        });
        this.setState({
            items: items,
            ignore_operations: [],
            ignore_action: null
        });
    };

    handleCancelIgnoreOperations = () => {
        this.setState({
            ignore_operations: [],
            ignore_action: null
        });
    };

    handleWarningClose = () => {
        this.setState({
            show_warning: false
        });
    };

    renderOrderEditModal() {
        const selected_order = this.state.items[this.state.selected_order_index];
        return (
            <Modal show={this.state.show_order_edit_modal}
                className="batch-operation-modal"
                dialogClassName="modal-dialog-scrollable"
                onHide={this.hideOrderEditModal}>
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id="Manufacturing.Planning.edit_order" defaultMessage="Edit work order" />
                        <span> - </span>
                        {selected_order && selected_order.external_id}
                    </Modal.Title>
                    <button type="button" className="close" onClick={this.hideOrderEditModal}>
                        <span aria-hidden="true">×</span>
                        <span className="sr-only"><FormattedMessage id="common.close" defaultMessage="Close" /></span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    {selected_order &&
                        [<div className="form-group" key="edit01">
                            <label><FormattedMessage id="Manufacturing.Planning.final_quantity" defaultMessage="Final quantity" /></label>
                            <input className="form-control min-width80px"
                                type={"text"}
                                name={"quantity_total"}
                                value={selected_order.quantity_total_str}
                                onFocus={(event) => { this.handleClick(this.state.selected_order_index, event) }}
                                onBlur={(event) => { this.handleClick(this.state.selected_order_index, event) }}
                                onChange={(event) => { this.handleClick(this.state.selected_order_index, event) }} />
                        </div>,
                        <div className="form-group" key="edit02">
                            <label><FormattedMessage id="Manufacturing.Planning.number_of_capacities" defaultMessage="Capacities [%]" /></label>
                            <input className={"form-control min-width50px " + (selected_order.capacity_overriden ? " color-blue-important" : "")}
                                type={"text"}
                                name={"capacity_factor"}
                                value={selected_order.capacity_factor_str}
                                onFocus={(event) => { this.handleClick(this.state.selected_order_index, event) }}
                                onBlur={(event) => { this.handleClick(this.state.selected_order_index, event) }}
                                onChange={(event) => { this.handleClick(this.state.selected_order_index, event) }} />
                        </div>,
                        <div className="form-group" key="edit03">
                            <label><FormattedMessage id="Manufacturing.Planning.24_7" defaultMessage="24/7" /></label><br/>
                            <Toggle
                                name="sim_ignore_earliest_start"
                                checked={selected_order.sim_ignore_earliest_start}
                                onChange={(event) => { this.handleClick(this.state.selected_order_index, event) }} />
                        </div>,
                        <div className="form-group"  key="edit04">
                            <label><FormattedMessage id="Manufacturing.Planning.ignore_in_sim" defaultMessage="Ignore" /></label><br/>
                            <Toggle
                                name="enabled"
                                checked={!selected_order.enabled}
                                onChange={(event) => { this.handleClick(this.state.selected_order_index, event) }} />
                        </div>]
                    }
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-outline-secondary"
                        onClick={this.hideOrderEditModal}>
                        <FormattedMessage id="common.close" defaultMessage="Close" />
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }

    renderIgnoreConfirmationModal = () => {
        const { ignore_operations, ignore_action } = this.state;
        const has_ignore_operations = ignore_operations.length > 0;
        return (
            <ConfirmationModal
                class_name="modal-dialog-scrollable confirmation-modal-ignore-operations"
                show={ignore_action !== null}
                title={translate("common.ignore_operations", "Ignore operations")}
                onAccept={
                    has_ignore_operations
                        ? this.handleAcceptIgnoreOperations
                        : this.handleCancelIgnoreOperations
                }
                onCancel={has_ignore_operations ? this.handleCancelIgnoreOperations : undefined}
                accept_label={has_ignore_operations ? undefined : translate("common.ok", "OK")}
            >
                <p
                    className={classNames({
                        "mb-0": !has_ignore_operations
                    })}
                >
                    {has_ignore_operations
                        ? translate(
                            "common.are_you_sure_ignore_operations",
                            "Are you sure you want to ignore the following operations?"
                          )
                          : translate(
                            "common.all_operations_already_ignored",
                            "All operations are already ignored."
                          )}
                </p>
                {has_ignore_operations && <OperationsTable operations={ignore_operations} />}
            </ConfirmationModal>
        );
    };

    renderWarningModal = () => {
        return (
            <ConfirmationModal
                show={this.state.show_warning}
                title={translate("common.unsupported_action", "Unsupported action")}
                accept_label={translate("common.ok", "OK")}
                onAccept={this.handleWarningClose}
                onClose={this.handleWarningClose}
            >
                <ErrorComponent
                    className="my-0"
                    type="warning"
                    msg={translate(
                        "common.action_not_supported_on_parallel_operations",
                        "This action is currently not supported, because one of the operations is part of a parallel order."
                    )}
                />
            </ConfirmationModal>
        );
    };

    /////////////////////////////////////////
    // Render component
    render() {
        if (this.state) {
            
            return (
                <div className="white_box planning-table">
                    <div className="chart-wrapper">
                        {this.renderOrderEditModal()}
                        {this.renderIgnoreConfirmationModal()}
                        {this.renderWarningModal()}
                        <SortableList
                            helperClass="planning-table-dragged-order-list-item"
                            items={this.state.items}
                            onSortEnd={this.onSortEnd}
                            handleEdit={this.showOrderEditModal}
                            handleClick={(index, event) => this.handleClick(index, event)}
                            insights_map={this.props.insights_map}
                            parent_children_map={this.props.parent_children_map}
                            compact={this.props.compact}
                            filtered_order_uuids={this.props.filtered_order_uuids}
                        />
                    </div>
                </div>
            );
        } else {
            return <div />
        }
    }
}

export default LineOrderList;
