// @flow
import React from "react";
import ReactRouterPropTypes from "react-router-prop-types";
import ReactTable from "../react/ReactTable";
import {
    rowStyle,
    mapData,
    Columns,
    updateTagsConfig,
    onChangeUpdateTags,
    getInitialState
} from "../react/ReactTableTags";
import EntityEditTemplate from "../EntityEditTemplate";
import { MATERIAL_PLANT_TAGS } from "../../lib/ManufacturingTagsEditor.generated"
import { getBackend as getBackend2 } from "../../lib/backend/Backend2";
import { translate } from "../IntlProviderWrapper";
import type { IUpdateMaterialPlantReq } from "../../lib/backend/manufacturing2.generated.types";
import type { ITags } from "../../lib/Models";
import * as t from "../../lib/backend/manufacturing2.generated.types";

import ErrorComponent from "../ErrorComponent";

type Props = {
    match: ReactRouterPropTypes.match,
    history: ReactRouterPropTypes.history,
    location: ReactRouterPropTypes.location,
    plant_title: string,
    material_title: string
};

type State = {
    tags: ITags,
    title: string,
    error: string | null,
    plant: t.IPlantData | null,
    material: t.IMaterialModel | null
};

class MaterialPlantEdit extends React.Component<Props, State> {

    state = {
        error: null,
        tags: {},
        title: "",
        plant: null,
        material: null
    };

    componentDidMount() {
        this.load();
    }

    getMaterialUuid = () => {
        return this.props.match.params.material_uuid;
    }

    getPlantUuid = () => {
        return this.props.match.params.plant_uuid;
    }

    load = async () => {
        try {
            this.setState({ error: null });

            const res_material_plant_tags = await getBackend2().manufacturing.getMaterialPlantTags({
                uuid: this.getMaterialUuid(),
                plant_uuid: this.getPlantUuid(),
            });

            let material_plant_tags = res_material_plant_tags.data.find(
                material_plant_tag => material_plant_tag.material_uuid === this.getMaterialUuid()
            );

            const res_plant = await getBackend2().manufacturing.getPlants({});
            const plant = res_plant.plants.find(p => p.uuid === this.getPlantUuid());

            const res_material = await getBackend2().manufacturing.searchMaterials({
                material_uuid: this.getMaterialUuid()
            });

            let material = res_material.data.find(m => m.uuid === this.getMaterialUuid());

            this.setState({
                tags: material_plant_tags ? material_plant_tags.tags : {},
                plant: plant,
                material: material
            });
        } catch (e) {
            this.setState({
                error: translate("common.error", "An error occured while fetching data.")
            });
        }
    }

    onChangeCell = (name: string) => (value: boolean) => {
        this.setState({ tags: onChangeUpdateTags(this.state.tags, name, value) });
    }

    renderError = () => {
        if (this.state.error) {
            return (<ErrorComponent msg={this.state.error} type="error" />);
        }
        return null;
    }

    onBack = () => {
        this.props.history.goBack();
    }

    onSave = async () => {
        try {
            const uuid = this.getMaterialUuid();

            if (uuid) {
                let material_params: IUpdateMaterialPlantReq = {
                    material_uuid: this.getMaterialUuid(),
                    plant_uuid: this.getPlantUuid(),
                    tags: this.state.tags
                };

                await getBackend2().manufacturing.updateMaterialPlant(material_params);
                this.props.history.goBack();
            }
        } catch (e) {
            console.log(e);
            this.setState({
                error: translate("common.error_save", "An error occured while saving data.")
            });
        }
    }

    getTitle = () => {
        return <div>
            <span>{translate("common.plant", "Plant")}: {this.state.plant ? this.state.plant.title : "?"}</span>
            <br />
            <span>{translate("common.material", "Material")}: {this.state.material ? this.state.material.title : "?"}</span>
        </div>
    }
    render() {
        return (
            <div className="material-edit-container">
                <EntityEditTemplate onSave={this.onSave} title={this.getTitle()} onBack={this.onBack}>
                    {this.renderError()}
                    <ReactTable
                        className="tags-react-table"
                        data={mapData(this.state.tags, updateTagsConfig(MATERIAL_PLANT_TAGS, {}))}
                        onChangeCell={this.onChangeCell}
                        rowStyle={rowStyle}
                        columns={Columns}
                        initialState={getInitialState()}
                    />
                </EntityEditTemplate>
            </div>
        )
    }
}


export default MaterialPlantEdit;
