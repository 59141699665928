// @flow

// main imports
// $FlowFixMe
import React, { Component, Suspense } from "react";
import { injectIntl, FormattedMessage } from "react-intl";

import {
    weekNumber, getDateOfISOWeek, toISODateTimeString,
    dateFromWeekAndShift, myday, niceNumber, niceDate
} from "../../lib/Util";
import * as BusinessLogic from "../../lib/BusinessLogic";
import Loader from "../Loader";
import { Modal } from "react-bootstrap";
import { IntlProviderWrapper, translate } from "../IntlProviderWrapper";

// models
import type { WeeklyReportWorker, WeeklyReport } from "../../lib/Models";

// backend
import { getBackend as getBackend2 } from "../../lib/backend/Backend2";
import * as t from "../../lib/backend/manufacturing2.generated.types";
import { loadRealizationWithPlan } from "../../lib/WeeklyRealization";

// import subcomponents
import ErrorComponent from "../ErrorComponent";
import { INSIGHT_TYPES } from "../../lib/ManufacturingTags.generated";

import WeeklyRealization from './WeeklyRealization';
import { MaterialsWeek, MaterialsDay } from "./WeeklyRealization/WeeklyRealizationElements";

import type { ScrapUptimeMatrixLines } from "../manufacturing/WeeklyRealizationCommon"

// $FlowFixMe
const ExportToXLSX = React.lazy(() => import("./ExportToXLSX"));

// navigation filter
export type MicroplanSummaryFilterProps = {
    year: number,
    week: number,
    breakdown_level: string,
    produced_scrap_downtime: string
}

export function initMicroplanSummaryFilterProps() {
    // set current year and week
    let now = new Date();
    let week = weekNumber(now);
    let year = now.getFullYear();
    // if we have week 1 extending into previous year
    if ((now.getMonth() === 11) && (week === 1)) {
        year = year + 1;
    }

    // return defaults
    return {
        week,
        year,
        breakdown_level: "shift",
        produced_scrap_downtime: "produced"
    };
}

type ProductionMatrixObj = {
    val: number,
    plan: number,
    prediction: number,
    status: string,
    worker: WeeklyReportWorker[],
    insights: t.IEventDataEx[]
}

type Props = {
    intl: any,
    plant_uuid: string,
    line_group_uuid: string,
    filter: MicroplanSummaryFilterProps,
    exportToXLSXButtonSetLoadingState: Function,
    scrap_uptime_matrix?: ScrapUptimeMatrixLines
};

type State = {
    error: string,
    warning: string,
    loaded: boolean,
    merged_data: WeeklyReport[],
    insights: t.IEventDataEx[][],
    show_line_day_sum: boolean,
    show_production_details: boolean,
    production_details_line_title: string,
    production_details_line_uuid: string,
    production_details_start: string,
    production_details_end: string,
    production_details_data: t.IProducedModel[],
    production_details_insights: t.IEventDataEx[],
    scrap_uptime_matrix?: ScrapUptimeMatrixLines
};


/**
 * Displaying production lines list.
 */
class MicroplanSummary extends Component<Props, State> {
    constructor(props: Props, state: State) {
        super(props);

        this.state = {
            error: "",
            warning: "",
            loaded: false,
            merged_data: [],
            insights: [],
            show_line_day_sum: false,
            show_production_details: false,
            production_details_line_title: "",
            production_details_line_uuid: "",
            production_details_start: "",
            production_details_end: "",
            production_details_data: [],
            production_details_insights: [],
            scrap_uptime_matrix: new Map()
        }
    }

    /**
     * Mounting data for this component.
     */
    async componentDidMount() {
        await BusinessLogic.waitForManufacturingAsync();
        this.load();
    }

    async componentDidUpdate(prev_props: Props) {
        if ((prev_props.plant_uuid !== this.props.plant_uuid ||
            prev_props.line_group_uuid !== this.props.line_group_uuid ||
            prev_props.filter.year !== this.props.filter.year ||
            prev_props.filter.week !== this.props.filter.week ||
            prev_props.filter.breakdown_level !== this.props.filter.breakdown_level ||
            prev_props.filter.produced_scrap_downtime !== this.props.filter.produced_scrap_downtime) &&
            this.props.line_group_uuid !== "") {

            await BusinessLogic.waitForManufacturingAsync();
            this.load();
        }
    }

    async load() {
        // get internationalization module
        const { intl } = this.props;
        // get lines we are collecting data for
        const line_group = BusinessLogic.getLineGroupForUser(this.props.line_group_uuid);
        let line_uuids = line_group ? line_group.line_uuids : [];
        // reset state
        this.setState({
            loaded: false,
            merged_data: [],
            warning: "", error: ""
        });

        try {
            const lines = await getBackend2().manufacturing.getLines({ line_uuids: line_uuids });
            const remove_line_uuids = lines.lines.filter(el => el.tags.skip_weekly_realization === "true").map(el => el.uuid);
            line_uuids = line_uuids.filter(line_uuid => !remove_line_uuids.includes(line_uuid));

            // cache simulation reports when preparing weekly realization report export
            const sim_report_add_to_cache = true;

            // go to the backend and get realization
            const result = await loadRealizationWithPlan(this.props.filter.week,
                this.props.filter.year, this.props.plant_uuid, line_uuids, false, sim_report_add_to_cache);

            // render any errors or warnings
            if (result.error) {
                this.setState({ error: result.error });
            } else if (result.warning) {
                this.setState({ warning: intl.formatMessage({ id: result.warning }) });
            }

            // get downtime insights
            const insights = await this.loadInsights(line_uuids);
            // remember what we got

            if (this.props.filter.produced_scrap_downtime === "produced") {
                this.setState({
                    merged_data: result.data,
                    insights,
                    loaded: true
                });
            } else {
                // if its not "produced", go fetch data that is needed for scrap and downtime

                const producedAll = await this.getProducedAllResponse(line_uuids);
                const sumWorkingHours = await this.getPlanningData(line_uuids);

                // This is the big map that i'm going to return
                const scrap_uptime_matrix = new Map();

                // to prepare big_matrix for all lines
                for (const line_uuid of line_uuids) {

                    let map = new Map();
                    map.set("materials", new Map())
                    scrap_uptime_matrix.set(line_uuid, map);
                }

                // So far we have big_matrix with keys.
                // Keys are line_uuids
                // in this for loop i will go through every produced value that i have and insert it in big matrix
                for (const produced of producedAll["data"]) {

                    // to get specific line from big_matrix
                    const specific_line_matrix = scrap_uptime_matrix.get(produced.line_uuid);
                    if (!specific_line_matrix) {
                        continue;
                    }

                    const material_external_id = produced.material_external_id;

                    const materials = specific_line_matrix.get("materials");
                    if (!materials) {
                        continue;
                    }

                     // if we have seen this line, then we have map of all materials
                     let specific_line_materials_map = materials || new Map();
                     let specific_material_array;

                    if (materials.get(material_external_id) === undefined) {
                        // if we didnt get any map from big_matrix, it means that i have never seen this line
                        // so i have to create new array for all data to send into other components
                        specific_material_array = [];
                    } else {
                        // if i have seen this, i just get that map<material_external_id, [shift_cells]>
                        specific_material_array = materials.get(material_external_id)
                    }

                    if (specific_material_array === undefined) {
                        continue;
                    }

                    // This cell has all data that i need
                    let shift_cell = {
                        produced: produced.quantity_produced,
                        scrap: produced.quantity_scrap,
                        shift_number: Number(produced.shift_number),
                        worker: [produced.worker_id]
                    };

                    specific_material_array.push(shift_cell);

                    specific_line_materials_map.set(material_external_id, specific_material_array);

                    specific_line_matrix.set("materials", specific_line_materials_map);
                    scrap_uptime_matrix.set(produced.line_uuid, specific_line_matrix);

                }


                // Here i go through all workingHours for every line
                // and i insert allShifts to specific_line
                for (const workingHours of sumWorkingHours) {
                    const [line_uuid, shifts] = workingHours;

                    const specific_line = scrap_uptime_matrix.get(line_uuid);
                    if (!specific_line) {
                        continue;
                    }
                    specific_line.set("workingShifts", shifts);
                    scrap_uptime_matrix.set(line_uuid, specific_line);
                }

                this.setState({
                    merged_data: result.data,
                    insights,
                    loaded: true,
                    scrap_uptime_matrix: scrap_uptime_matrix
                });
            }

        } catch (err) {
            this.setState({ error: err, merged_data: [], loaded: true });
        }
    }

    async getProducedAllResponse(line_uuids) {
        // This function returns how many products were produced and scraped
        // from start to end time on line_uuids

        const start_time = dateFromWeekAndShift(this.props.filter.week, this.props.filter.year, 0).getTime();
        const end_time = dateFromWeekAndShift(this.props.filter.week, this.props.filter.year, 21).getTime();

        const res = await getBackend2().manufacturing.getProducedAll({
            start: start_time,
            end: end_time,
            line_uuids: line_uuids
        });

        return res;
    }

    async getPlanningData(line_uuids) {
        /**
         * This function returns all working hours for these line_uuids.
         * This data is used for showing downtime vs uptime
         */
        const planningDataResponse = await getBackend2().manufacturing.getPlanningData({
            calculate_hash: false,
            week_from: this.props.filter.week,
            year: this.props.filter.year,
            week_count: 1,
            line_uuids: line_uuids
        });

        let workingPlanPerLine = new Map<string, any>;

        if(!planningDataResponse || planningDataResponse === undefined || planningDataResponse.lines === undefined) {
            return workingPlanPerLine;
        }

        for (const line of planningDataResponse.lines) {
            workingPlanPerLine.set(line.line_uuid, line.shifts);
        }

        return workingPlanPerLine;
    }

    async loadInsights(line_uuids: string[]): Promise<t.IEventDataEx[][]> {
        const start_time = dateFromWeekAndShift(this.props.filter.week, this.props.filter.year, 0).getTime();
        const end_time = dateFromWeekAndShift(this.props.filter.week, this.props.filter.year, 21).getTime();
        const res_insights = await getBackend2().manufacturing.getInsights({
            from: start_time, to: end_time,
            types: [INSIGHT_TYPES.man_downtime],
            lines: line_uuids
        });
        const insights_data = res_insights.insights;
        // filter out insights that start at the end of range or finish at start of range
        const insights_list = insights_data.filter(insight => {
            return insight.ts_to > start_time && end_time > insight.ts;
        })
        // assing to shifts for faster look-up when rendering
        const insights_shifts: t.IEventDataEx[][] = [];
        for (let shift_number = 0; shift_number < 21; shift_number++) { insights_shifts.push([]); }
        for (const insight of insights_list) {
            if (insight.tags.shift !== undefined) {
                const shift_number = parseInt(insight.tags.shift, 10);
                if (0 <= shift_number && shift_number < 21) {
                    insights_shifts[shift_number].push(insight);
                }
            }
        }
        return insights_shifts;
    }

    getStickyOffsetTop = () => {
        let top;
        const el = document.querySelector(".views-filter-bar") || document.querySelector(".navigation-bar");
        if (el) {
            const rect = el.getBoundingClientRect();
            top = rect.top + rect.height;
        }
        return top;
    }

    dataLoaded(): boolean {
        return this.state.loaded;
    }

    getEmptyProductionMatrix(slots: number): ProductionMatrixObj[] {
        const result = [];
        for (let slot = 0; slot < slots; slot++) {
            result.push({
                val: 0,
                plan: 0,
                prediction: 0,
                status: "",
                worker: [],
                insights: []
            });
        }
        return result;
    }

    getProducedRatio(produced: number, planned: number, is_export: boolean = false): string {
        return (planned > 0) ? (this.getNumber(100.0 * (produced / planned), 1, is_export) + "%") : "-"
    }

    getNumber(number: string | number, decimal: ?number, is_export: ?boolean = false): number | string {
        // to use niceNumber inside getNumber please use the decimal param
        if (number === "-" && is_export) {
            return "0";
        }

        if (is_export) {
            return number;
        }

        return (decimal || decimal === 0) && typeof number !== "string" ? niceNumber(number, decimal) : number;
    }

    renderMaterials(is_export: boolean = false) {
        if (!this.dataLoaded()) {
            return <Loader />;
        } else if (this.props.filter.breakdown_level === "shift") {
            return this.renderMaterialsShift(is_export);
        } else if (this.props.filter.breakdown_level === "day") {
            return this.renderMaterialsDay(is_export);
        } else {
            return this.renderMaterialsWeek(is_export);
        }
    }

    renderMaterialsWeek(is_export: boolean) {
        return <MaterialsWeek
            is_export={false}
            merged_data={this.state.merged_data}
            line_group_uuid={this.props.line_group_uuid}
            filter={this.props.filter}
            scrap_uptime_matrix={this.state.scrap_uptime_matrix}
        />
    }

    prettyWorkerName(worker: WeeklyReportWorker[]): string[] {
        // we have no workers
        if (worker.length === 0) { return ["-"]; }

        const surnames = worker.map(w => {
            if (w.name !== undefined && w.name !== null && w.name.length > 0) {
                // get surname (first part of name)
                return w.name.split(" ")[0];
            } else {
                // unknown worker, return external id
                return translate("common.reg_number_short", "RN") + " " + w.external_id;
            }
        });
        const unique_surnames = [...new Set(surnames)];
        return unique_surnames;
    }

    renderLineMaterialDetail(
        i: number,
        l: number,
        line_title: string,
        line_uuid: string,
        material_title: string,
        material_external_id: string,
        production_matrix: ProductionMatrixObj[],
        material_norm: t.IWeeklyProducedNormRec | null,
        is_export: boolean = false,
        show_workers: boolean = true
    ) {
        if (i !== 0) {
            line_title = "";
        };
        let tr_class = "";

        if (l > 1) {
            if (i === 0) {
                tr_class = "tr-top";
            } else if (i < l) {
                tr_class = "tr-top";
            } else {
                tr_class = "tr-sum";
            }
        }
        const show_predicted = this.getShowPredicted();
        // compute total statistics
        let total_sum = 0, total_plan = 0, total_predicted = 0;
        for (let production of production_matrix) {
            total_sum += production.val;
            total_plan += production.plan;
            total_predicted += production.prediction;
        }
        const total_ratio = this.getProducedRatio(total_sum, total_plan, is_export);

        // prepare realization table cells
        const production_cells = production_matrix.map((el, i) => {
            const tooltip = (this.props.filter.breakdown_level === "shift") ?
                `${myday(Math.floor(i / 3))}, ${(i % 3 + 1)} ${translate("common.shift_lc", "shift")}` :
                `${myday(Math.floor(i))}`

            // flag planned productions (i.e. non-zero predictions)
            const flag_class_name = (el.status === "prediction" && el.prediction > 0) ? `tr-realization-flag-1-yellow` : "";
            const status_class_name = `td-weekly-realization-${el.status}`;
            const class_name = `${flag_class_name} ${status_class_name}`;

            // get times for production details
            let val = (el.val > 0) ? this.getNumber(el.val, 0, is_export) : "";
            let worker = null;

            if ((el.val > 0 || el.plan > 0) && (el.status !== "prediction")) {
                worker = this.prettyWorkerName(el.worker).join(", ");
            }

            let start_time = 0;
            let end_time = 0;

            if (this.props.filter.breakdown_level === "shift") {
                start_time = dateFromWeekAndShift(this.props.filter.week, this.props.filter.year, i).getTime();
                end_time = dateFromWeekAndShift(this.props.filter.week, this.props.filter.year, i + 1).getTime();
            } else {
                start_time = dateFromWeekAndShift(this.props.filter.week, this.props.filter.year, i * 3).getTime();
                end_time = dateFromWeekAndShift(this.props.filter.week, this.props.filter.year, i * 3 + 3).getTime();
            }

            // check if we have insights
            let insight_render = null;
            if (el.insights.length > 0) {
                const insight_tooltips = [];
                for (const insight of el.insights) {
                    insight_tooltips.push(BusinessLogic.getInsightTooltipText(insight));
                }

                insight_render = <img src="/img/warning.svg" alt="Insights"
                    title={insight_tooltips.join("\n")}
                    className={"microplan-warning-events-image"} />
            }

            const hasWorker = worker !== "-" && worker;

            if (!hasWorker || !show_workers) {
                return <React.Fragment>
                    <td
                        colspan={is_export ? 1 : 2}
                        title={tooltip}
                        style={{ textAlign: "center" }}
                        className={class_name}
                        key={i}
                        onClick={() => { this.loadProductionDetails(line_title, line_uuid, material_external_id, start_time, end_time); }}>
                        {insight_render}
                        {val}
                    </td>
                    {is_export ? <td></td> : null}
                </React.Fragment>
            }

            return <React.Fragment>
                <td
                    colspan={1}
                    title={tooltip}
                    style={{ textAlign: "center" }}
                    className={class_name}
                    key={i}
                    onClick={() => { this.loadProductionDetails(line_title, line_uuid, material_external_id, start_time, end_time); }}>
                    {insight_render}
                    {val}
                </td>
                <td
                    title={tooltip}
                    style={{ textAlign: "center" }}
                    className={class_name}
                    key={i}
                    onClick={() => { this.loadProductionDetails(line_title, line_uuid, material_external_id, start_time, end_time); }}>
                    {worker}
                </td>
            </React.Fragment>
        });

        // if we have day view we have one row, if we have shift view we have three rows
        if (this.props.filter.breakdown_level === "shift") {
            // break down by shift
            const production_cells_shifts = [[], [], []];
            const production_cells_shifts_sums = [];

            for (let i = 0; i < production_cells.length; i++) {
                production_cells_shifts[i % 3].push(production_cells[i]);
                if (i % 3 === 0) {
                    let sum = production_matrix[i].val + production_matrix[i + 1].val + production_matrix[i + 2].val;

                    production_cells_shifts_sums.push(
                        <React.Fragment>
                            <td className="text-center">{this.getNumber(sum, 0, is_export)}</td>
                            <td></td>
                        </React.Fragment>
                    );
                }
            }

            let plannedRatioEl = null;

            if (is_export) {
                plannedRatioEl = (
                    <React.Fragment>
                        <td rowSpan="4" className={`text-center`}>{this.getNumber(total_plan, 0, is_export)}</td>
                        <td rowSpan="4" className={`text-center`}>{this.getNumber(total_ratio, null, is_export)}</td>
                    </React.Fragment>
                )
            } else {
                plannedRatioEl = <React.Fragment>
                    <td rowSpan="4" className={`text-center`}>{this.getNumber(total_plan, 0, is_export)} ({total_ratio})</td>;
                </React.Fragment>
            }

            return (
                <React.Fragment>
                    <tr className={tr_class} key={i + "s1"}>
                        <td rowSpan="4" className={`reduce-row-width`}>{line_title}</td>
                        <td rowSpan="4" className={`reduce-row-width`}>{material_external_id}</td>
                        <td rowSpan="4" className={`reduce-row-width`}>{material_title}</td>
                        <td rowSpan="4" className={`reduce-row-width`} style={{ textAlign: "center" }}>
                            {material_norm !== null && material_norm.norm > 0 ?
                                this.getNumber(material_norm.norm, 0, is_export) : ""}
                        </td>
                        {plannedRatioEl}
                        <td style={{ textAlign: "center" }}>1</td>
                        {production_cells_shifts[0]}
                        <td rowSpan="4" className={`text-center`}>{this.getNumber(total_sum, 0, is_export)}</td>
                    </tr>
                    <tr className={tr_class} key={i + "s2"}>
                        <td style={{ textAlign: "center" }}>2</td>
                        {production_cells_shifts[1]}
                    </tr>
                    <tr className={tr_class} key={i + "s3"}>
                        <td style={{ textAlign: "center" }}>3</td>
                        {production_cells_shifts[2]}
                    </tr>
                    <tr className="materials-shift-sums">
                        <td style={{ textAlign: "center" }}>{translate("common.total", "Total")}</td>
                        {production_cells_shifts_sums}
                    </tr>
                </React.Fragment>
            )
        } else {
            let totalRatioEl = null;

            if (is_export) {
                totalRatioEl = (
                    <React.Fragment>
                        <td className={`text-center`}>{this.getNumber(total_sum, 0, is_export)}</td>
                        <td className={`text-center`}>{this.getNumber(total_ratio, null, is_export)}</td>
                        {show_predicted && <td className={`text-center`}>{this.getNumber(total_predicted, 0, is_export)}</td>}
                    </React.Fragment>
                )
            } else {
                totalRatioEl = <React.Fragment>
                    <td className={`text-center`}>
                        {this.getNumber(total_sum, 0, is_export)} ({total_ratio})
                        <br />
                        {show_predicted && this.getNumber(total_predicted, 0, is_export)}
                    </td>
                </React.Fragment>
            }

            // one row since no shifts
            return (
                <React.Fragment>
                    <tr className={tr_class} key={i}>
                        <td className={`reduce-row-width`}>{line_title}</td>
                        <td className={`reduce-row-width`}>{material_external_id}</td>
                        <td className={`reduce-row-width`}>{material_title}</td>
                        <td className={`reduce-row-width text-center`}>
                            {material_norm !== null && material_norm.norm > 0 ?
                                this.getNumber(material_norm.norm, 0, is_export) : ""}
                        </td>
                        <td className={`text-center`}>{this.getNumber(total_plan, 0, is_export)}</td>
                        {production_cells}
                        {totalRatioEl}
                    </tr>
                </React.Fragment>
            )
        }
    }

    getShowPredicted = () => {
        return this.props.filter.week >= weekNumber(new Date());
    }

    renderMaterialsDay(is_export: boolean) {
        return <MaterialsDay
            is_export={false}
            line_group_uuid={this.props.line_group_uuid}
            merged_data={this.state.merged_data}
            filter={this.props.filter}
            show_line_day_sum={this.state.show_line_day_sum}
            scrap_uptime_matrix={this.state.scrap_uptime_matrix}
            produced_scrap_downtime={this.props.filter.produced_scrap_downtime}
        />;
    }

    renderMaterialsShift(is_export: boolean) {
        const days = Array(7).fill(0).map((el, i) => { return myday(i, 3) });
        // merge simulation data with SQL data
        let merged_data = this.state.merged_data;
        // we assume that rows are grouped by lines/materials from the SQL call in the backend
        const distinctLines = [...new Set(merged_data.map(x => x.line_uuid))];
        // map line_uuid to line_title
        const line_title_map = new Map();
        for (const x of merged_data) {
            line_title_map.set(x.line_uuid, x.line_title);
        }

        // map numeric status to text status
        const status_labels = ["confirmed", "unconfirmed", "prediction"];
        let shift_done = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        let weekly_done = 0, weekly_planned = 0;

        const mat = distinctLines.map((line, i) => {
            const line_title = line_title_map.get(line) || "";
            let filteredByLine = merged_data.filter(x => x.line_uuid === line);

            const distinctMaterials = [...new Set(filteredByLine.map(x => x.material_title))];
            const distinctMaterialIds = [...new Set(filteredByLine.map(x => x.material_external_id))];

            let pieces = [];
            let linesJSX = [];
            distinctMaterialIds.forEach((material_external_id, i) => {
                // make a copy of the object (otherwise it stays the same)
                let material_production: ProductionMatrixObj[] = this.getEmptyProductionMatrix(21);
                let filteredByMaterial = filteredByLine.filter(x => x.material_external_id === material_external_id);
                // get normative for the material if we have it
                const material_norm = (filteredByMaterial.length > 0) ? filteredByMaterial[0].norm :
                    { capacity_factor: 0.0, norm: 0.0, norm_base_units: 0.0, norm_break_time_hours: 0.0, norm_machine_time: 0.0 }
                filteredByMaterial.forEach((line) => {
                    if (line.shift_time && line.shift_time.shift_number !== undefined) {
                        const shift_number = line.shift_time.shift_number;
                        material_production[shift_number].val = (line.status !== undefined && line.status < 2) ? line.sum : 0;
                        material_production[shift_number].plan = line.plan ? line.plan : 0;
                        material_production[shift_number].prediction = (line.status !== undefined && line.status === 2) ? line.sum : 0;
                        material_production[shift_number].status = status_labels[line.status !== undefined ? line.status : 0];
                        material_production[shift_number].worker = material_production[shift_number].worker.concat(line.worker);
                        // get total sums
                        shift_done[shift_number] += material_production[shift_number].val;
                        weekly_done += material_production[shift_number].val;
                        weekly_planned += material_production[shift_number].plan;
                        // get insights if any
                        for (const insight of this.state.insights[shift_number]) {
                            if (line.line_uuid === insight.tags.line_uuid) {
                                material_production[shift_number].insights.push(insight);
                            }
                        }
                    }
                });
                pieces.push(material_production);
                linesJSX.push(
                    this.renderLineMaterialDetail(
                        i,
                        distinctMaterialIds.length,
                        line_title,
                        line,
                        distinctMaterials[i],
                        material_external_id,
                        material_production,
                        material_norm,
                        is_export
                    )
                );
            });

            if (this.state.show_line_day_sum && (distinctMaterialIds.length > 1)) {
                // create sum for all materials by line
                let sum: ProductionMatrixObj[] = this.getEmptyProductionMatrix(21);
                for (let i = 0; i < 21; i++) {
                    for (let j = 0; j < distinctMaterialIds.length; j++) {
                        sum[i].val += pieces[j][i].val;
                        sum[i].plan += pieces[j][i].plan;
                        if (pieces[j][i].status !== "confirmed" && pieces[j][i].status !== "") {
                            sum[i].status = pieces[j][i].status;
                        }
                    }
                }
                linesJSX.push(
                    this.renderLineMaterialDetail(
                        distinctMaterialIds.length,
                        distinctMaterialIds.length,
                        line_title,
                        line,
                        "SUM",
                        "",
                        sum,
                        null,
                        is_export
                    )
                );
            }
            return linesJSX;
        });

        const top = this.getStickyOffsetTop()
        const stickyStyle = { top };

        return <div className="white_box charts">
            <table id="microplan-summary-shift-table" className="table">
                <colgroup>
                    <col></col>
                    <col></col>
                    <col className={"breakdown-border-thick"}></col>
                    <col className={"breakdown-border-thick"}></col>
                    <col className={"breakdown-border-thick"}></col>
                    <col className={"breakdown-border-thick"}></col>
                    <col className={"breakdown-border breakdown-total"}></col>
                    <col span="2" className={"breakdown-border"}></col>
                    <col span="2" className={"breakdown-border"}></col>
                    <col span="2" className={"breakdown-border"}></col>
                    <col span="2" className={"breakdown-border"}></col>
                    <col span="2" className={"breakdown-border"}></col>
                    <col span="2" className={"breakdown-border breakdown-weekend"}></col>
                    <col span="2" className={"breakdown-weekend"}></col>
                    <col className={"breakdown-border-thick-left breakdown-border breakdown-total"}></col>
                </colgroup>
                <thead>
                    <tr>
                        <th style={stickyStyle}><FormattedMessage id="common.line" defaultMessage="Line" /></th>
                        <th style={stickyStyle}>Id</th>
                        <th style={stickyStyle}><FormattedMessage id="common.material" defaultMessage="Material" /></th>
                        <th style={stickyStyle}>
                            <FormattedMessage id="common.norm" defaultMessage="Norm" /><br />
                            / <FormattedMessage id="common.shift" defaultMessage="Shift" />
                        </th>
                        <th colSpan={is_export ? 2 : 1} className="text-center style={stickyStyle}">
                            <FormattedMessage id="common.planned_plural" defaultMessage="Planned" /><br />
                            / <FormattedMessage id="common.week" defaultMessage="Week" />
                        </th>
                        <th style={stickyStyle}><FormattedMessage id="common.shift" defaultMessage="Shift" /></th>
                        {days.map((day, i) => {
                            return <th colSpan={2} className="text-center" key={`header_${i}`}>{day}</th>;
                        })}
                        <th className="text-center" style={stickyStyle}>
                            <FormattedMessage id="common.total" defaultMessage="Total" />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {mat}
                    <tr className="tr-sum">
                        <td colSpan="4" rowSpan="3" className="text-center">
                            <FormattedMessage id="Manufacturing.MicroplanSummary.weekly_realisation" defaultMessage="Weekly realisation" />
                        </td>
                        <td colSpan={is_export ? 2 : 1} rowSpan="3" className="text-center">{this.getNumber(weekly_planned, 0, is_export)}</td>
                        <td style={{ textAlign: "center" }}>1</td>
                        {days.map((_, i) => (<td colSpan={2} className="text-center" key={`sum_1_${i}`}>{this.getNumber(shift_done[i * 3], 0, is_export)}</td>))}
                        <td rowSpan="4" className="text-center">{this.getNumber(weekly_done, 0, is_export)}</td>
                    </tr>
                    <tr className="tr-sum">
                        <td style={{ textAlign: "center" }}>2</td>
                        {days.map((_, i) => (<td colSpan={2} className="text-center" key={`sum_2_${i}`}>{this.getNumber(shift_done[i * 3 + 1], 0, is_export)}</td>))}
                    </tr>
                    <tr className="tr-sum">
                        <td style={{ textAlign: "center" }}>3</td>
                        {days.map((_, i) => (<td colSpan={2} className="text-center" key={`sum_3_${i}`}>{this.getNumber(shift_done[i * 3 + 2], 0, is_export)}</td>))}
                    </tr>
                </tbody>
            </table>
        </div>;
    }

    async loadProductionDetails(line_title: string, line_uuid: string, material_external_id: string, start_time: number, end_time: number) {
        try {
            // reset what we have
            this.setState({
                production_details_line_title: line_title,
                production_details_line_uuid: line_uuid,
                production_details_start: toISODateTimeString(new Date(start_time)),
                production_details_end: toISODateTimeString(new Date(end_time)),
                production_details_data: [],
                production_details_insights: []
            });
            // get new production realization data for selected time range
            const res_produced = await getBackend2().manufacturing.getProduced({
                end: end_time, start: start_time, uuid: line_uuid
            });
            const production_details_data = res_produced.data
                .filter(x => x.material_external_id === material_external_id);
            this.setState({ production_details_data });
            this.showProductionDetails();
            // load insights to show downtimes and comments
            const insight_criteria: t.IGetInsightsReq = {
                from: start_time,
                to: end_time,
                types: [INSIGHT_TYPES.man_downtime],
                lines: [line_uuid]
            }
            const res_insights = await getBackend2().manufacturing.getInsights(insight_criteria);
            const insights_data = res_insights.insights;
            // filter out insights that start at the end of range or finish at start of range
            const insights = insights_data.filter(insight => {
                return insight.ts_to > start_time && end_time > insight.ts;
            })
            // add to the state
            this.setState({ production_details_insights: insights });

        } catch (e) {
            this.setState({ error: e.message });
        }
    };

    showProductionDetails = () => {
        this.setState({ show_production_details: true });
    };

    hideProductionDetails = () => {
        this.setState({ show_production_details: false });
    };

    renderProductionDetails() {
        return (
            <Modal bsSize="large"
                show={this.state.show_production_details}
                onHide={this.hideProductionDetails}>
                <Modal.Header>
                    <Modal.Title>
                        <dl className="property-list">
                            <dd>
                                {this.state.production_details_line_title}
                            </dd>
                        </dl>
                        <dl>
                            <dd className="small">
                                {this.state.production_details_start} - {this.state.production_details_end}
                            </dd>
                        </dl>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4><FormattedMessage id="common.production" defaultMessage="Production" /></h4>

                    <table className="table">
                        <colgroup>
                            <col className={"breakdown-border"}></col>
                            <col className={"breakdown-border"}></col>
                            <col className={"breakdown-border"}></col>
                            <col className={"breakdown-border"}></col>
                            <col className={"breakdown-border"}></col>
                        </colgroup>
                        <thead>
                            <tr>
                                <th><FormattedMessage id="common.material" defaultMessage="Material" /></th>
                                <th><FormattedMessage id="common.worker" defaultMessage="Worker" /></th>
                                <th><FormattedMessage id="common.time" defaultMessage="Time" /></th>
                                <th><FormattedMessage id="common.total" defaultMessage="Total" /></th>
                                <th><FormattedMessage id="common.scrap_parts" defaultMessage="Scrap" /></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.production_details_data.map((production, i) => (
                                    <tr key={"production" + i}>
                                        <td>{production.material_title}</td>
                                        <td>{production.worker_id}</td>
                                        <td>
                                            <FormattedMessage id="Manufacturing.Planning.from" defaultMessage="From" /><span>: </span>
                                            {toISODateTimeString(new Date(production.shift_start_time))}
                                            <br />
                                            <FormattedMessage id="Manufacturing.Planning.until" defaultMessage="Until" /><span>: </span>
                                            {toISODateTimeString(new Date(production.shift_end_time))}
                                        </td>
                                        <td>{production.quantity_produced}</td>
                                        <td>{production.quantity_scrap}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>

                    <h4><FormattedMessage id="common.downtimes" defaultMessage="Downtimes" /></h4>

                    <table className="table">
                        <colgroup>
                            <col className={"breakdown-border"}></col>
                            <col className={"breakdown-border"}></col>
                            <col className={"breakdown-border"}></col>
                            <col className={"breakdown-border"}></col>
                            <col className={"breakdown-border"}></col>
                        </colgroup>
                        <thead>
                            <tr>
                                <th><FormattedMessage id="common.time" defaultMessage="Time" /></th>
                                <th><FormattedMessage id="common.duration" defaultMessage="Duration" /></th>
                                <th><FormattedMessage id="common.worker" defaultMessage="Worker" /></th>
                                <th><FormattedMessage id="common.downtime" defaultMessage="Downtime" /></th>
                                <th><FormattedMessage id="common.comment" defaultMessage="Comment" /></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.production_details_insights.map((insight, i) => (
                                    <tr key={"insight" + i}>
                                        <td>
                                            <FormattedMessage id="Manufacturing.Planning.from" defaultMessage="From" /><span>: </span>
                                            {toISODateTimeString(new Date(insight.ts))}
                                            <br />
                                            <FormattedMessage id="Manufacturing.Planning.until" defaultMessage="Until" /><span>: </span>
                                            {toISODateTimeString(new Date(insight.ts_to))}
                                        </td>
                                        <td>{this.getNumber(insight.extra_data.duration, 2)}h</td>
                                        <td>{(insight.tags.person_external_id !== "null") ? insight.tags.person_external_id : "-"}</td>
                                        <td>{insight.extra_data.title}</td>
                                        <td>{insight.extra_data.user_comment}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>

                </Modal.Body>
                <Modal.Footer>
                    <button onClick={this.hideProductionDetails}>
                        <FormattedMessage id="common.back" defaultMessage="Back" />
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }

    renderWeeklyRealizationShift(is_export: boolean) {
        return <WeeklyRealization
            breakdown_level="shift"
            is_export={is_export}
            plant_uuid={this.props.plant_uuid}
            line_group_uuid={this.props.line_group_uuid}
            filter={this.props.filter}
            exportToXLSXButtonSetLoadingState={this.props.exportToXLSXButtonSetLoadingState}
            scrap_uptime_matrix={this.state.scrap_uptime_matrix}
        />
    }

    render() {
        // handle date formatting
        let dt = getDateOfISOWeek(this.props.filter.week, this.props.filter.year);
        const from_date = niceDate(dt);
        dt.setDate(dt.getDate() + 7);
        const to_date = niceDate(dt);

        return (
            <article id="weekly-realization-article" className="article" key="1">
                <section id="statistics" className="data_sources">
                    <div className="vertical_tab">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12 col-md-12">
                                    {this.renderProductionDetails()}
                                    <ErrorComponent msg={this.state.error} type="error" />
                                    <ErrorComponent msg={this.state.warning} type="warning" />
                                    <dl className="property-list">
                                        <div>
                                            <dd>{from_date} - {to_date}</dd>
                                        </div>
                                    </dl>
                                    {!this.dataLoaded() ? <Loader /> :
                                        this.props.filter.breakdown_level === "shift" ?
                                            this.renderWeeklyRealizationShift(false) : this.renderMaterials()}
                                </div>
                            </div>
                        </div>
                    </div>

                    {this.props.filter.breakdown_level !== "shift" &&
                        <Suspense fallback={<Loader />}>
                            <ExportToXLSX
                                renderedTableToXLSX={
                                    <IntlProviderWrapper>
                                        {this.renderMaterials(true)}
                                    </IntlProviderWrapper>
                                }
                                exportToXLSXButtonSetLoadingState={this.props.exportToXLSXButtonSetLoadingState}
                                loading={!this.state.loaded}
                            />
                        </Suspense>
                    }
                </section>
            </article>
        );
    }
}

export default injectIntl(MicroplanSummary);
