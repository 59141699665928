// @flow
import React from "react";
import Select from "react-select";
import { connect } from "react-redux";
import { translate } from "../../../../IntlProviderWrapper";

import { getBackend as getBackend2 } from "../../../../../lib/backend/Backend2";
import { INSIGHT_TYPES } from "../../../../../lib/ManufacturingTags.generated";
import { SET_INSIGHT_TYPE, RESET_FILTERS } from "../../reducers/reducersFilters/filters";

import type { SetInsightTypeAction, ResetFiltersAction } from "../../reducers/reducersFilters/filters";
import type { ReduxState } from "../../reducers/reducers";

export type SelectOptionItem = {
    label: string;
    value: string;
}

type Props = {
    reduxDispatch: (args: SetInsightTypeAction | ResetFiltersAction) => any,
    selected_insight_type: string | null
};

type State = {
    insight_types_translated: { [string]: string }
};
const FILTER_INSIGHT_TYPES = [
    INSIGHT_TYPES.tool_overlap,
    INSIGHT_TYPES.operation_no_input_material,
    INSIGHT_TYPES.operation_delay_downstream,
    INSIGHT_TYPES.operation_constraint_violated,
    INSIGHT_TYPES.freeze_order_move,
    INSIGHT_TYPES.partially_scheduled,
    INSIGHT_TYPES.conditional_capacity_violated
];

class InsightsTypeFilter extends React.Component<Props, State> {

    state = {
        insight_types_translated: { }
    }

    async componentDidMount() {
        const res = await getBackend2().manufacturing.getInsightTypes({});
        const insight_types_translated: { [string]: string } = { };
        for (const insight_type of res.insight_types) {
            insight_types_translated[insight_type.code] = insight_type.title;
        }
        this.setState({ insight_types_translated });
    }

    getInsightType = (insight_type: string) => {
        return {
            label: this.state.insight_types_translated[insight_type] ?
                this.state.insight_types_translated[insight_type] : insight_type,
            value: insight_type
        };
    }

    getOptions = (): SelectOptionItem[] => {
        return FILTER_INSIGHT_TYPES.map(insight_type => this.getInsightType(insight_type));
    }

    getSelectedOptions = (insight_type: string | null) => {
        if (insight_type === null) { return null; }
        return this.getInsightType(insight_type);
    }

    onChange = (option?: SelectOptionItem) => {
        if (!option) {
            this.props.reduxDispatch({ type: RESET_FILTERS, data: undefined });
        } else {
            this.props.reduxDispatch({ type: SET_INSIGHT_TYPE, data: option.value });
        }
    }

    render() {
        return <div className="order-filter">
            <Select
                value={this.getSelectedOptions(this.props.selected_insight_type)}
                placeholder={translate("common.select_filter_value", "Select value")}
                options={this.getOptions()}
                onChange={this.onChange}
                isSearchable={true}
                isClearable
            />
        </div>
    }
}

export default connect(
    (state: ReduxState, own_props) => {
        const selected_insight_type = state.gantt_chart_filters.selected_insight_type;

        return {
            selected_insight_type
        };
    },
    (dispatch) => ({reduxDispatch: dispatch})
)(InsightsTypeFilter);
