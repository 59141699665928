// @flow

import { niceNumber } from "../../lib/Util";
import { translate } from "../IntlProviderWrapper";
import type { WeeklyReportWorker } from "../../lib/Models";
import * as t from "../../lib/backend/manufacturing2.generated.types";

export type ProductionMatrixObj = {
    val: number,
    plan: number,
    prediction: number,
    status: string,
    worker: WeeklyReportWorker[],
    insights: t.IEventDataEx[],
    duration: number,
    potencially_created: number,
    downtime: number,
    scrap: number
}

export type ProductionModalParams = {
    line_title: string,
    line_uuid: string,
    material_external_id: string,
    start_time: number,
    end_time: number
}

export type MicroplanSummaryFilterProps = {
    year: number,
    week: number,
    breakdown_level: string,
    produced_scrap_downtime: string
}

export type ScrapUptimeMatrixLines = Map<string, Map<string, any>>;


export const getNumber = (number: string | number, decimal: ?number, is_export: ?boolean = false): number | string  =>{
    // to use niceNumber inside getNumber please use the decimal param
    if (number === "-" && is_export) {
        return "0";
    }

    if (is_export) {
        return number;
    }

    return (decimal || decimal === 0) && typeof number !== "string" ? niceNumber(number, decimal) : number;
}

export const getProducedRatio = (produced: number, planned: number, is_export: boolean = false): string => {
    return (planned > 0) ? (getNumber(100.0 * (produced / planned), 1, is_export) + "%") : "-"
}

export const prettyWorkerName = (worker: WeeklyReportWorker[], split: boolean = true): string[] => {
    // we have no workers
    if (worker.length === 0) { return ["-"]; }

    const surnames = worker.map(w => {
        if (w.name !== undefined && w.name !== null && w.name.length > 0) {
            if (!split) {
                return w.name;
            }
            // get surname (first part of name)
            return w.name.split(" ")[0];
        } else {
            // unknown worker, return external id
            return translate("common.reg_number_short", "RN") + " " + w.external_id;
        }
    });
    const unique_surnames = [...new Set(surnames)];
    return unique_surnames;
}

export const getEmptyProductionMatrix = (slots: number): ProductionMatrixObj[] => {
    const result = [];
    for (let slot = 0; slot < slots; slot++) {
        result.push({
            val: 0,
            plan: 0,
            prediction: 0,
            status: "",
            worker: [],
            insights: [],
            duration: 0,
            scrap: 0,
            downtime: 0,
            potencially_created: 0
        });
    }
    return result;
}

