// @flow
import React from "react";
import * as t from "../../lib/backend/manufacturing2.generated.types";
import { getBackend as getBackend2 } from "../../lib/backend/Backend2";
import { niceNumber, SHIFT_CONSTS, niceDate, TIME_RANGES, shiftNumber, uniqueValues } from "../../lib/Util";
import { renderToString } from "react-dom/server";
import { workerAssignmentsExport } from "./WorkerAssignmentsExport"
import ShiftNumber from "../OrdersShifts/cells/ShiftNumber";
import { MSG_TYPES, subscribe, unsubscribe } from "../../lib/PubSub";

type Props = {
    plant_uuid: string;
    line_group_uuid: string;
    selected_shift_number: number,
    selected_shift_date: Date
};

type State = {
    orders: t.IOrderModelBase[];
    orders_future: t.IOrderModelBase[];
};

class WorkerAssignments extends React.Component<Props, State> {

    // columns = getTableColumns();
    ref = React.createRef();
    state: State = {
        orders: [],
        orders_future: []
    };
    subscription = null;

    componentDidMount = async () => {
        this.createSubscription();
        await this.load();
    }

    componentDidUpdate = async (prevProps: Props, prevState: State) => {
        if (prevProps.plant_uuid !== this.props.plant_uuid ||
            prevProps.line_group_uuid !== this.props.line_group_uuid ||
            prevProps.selected_shift_number !== this.props.selected_shift_number ||
            prevProps.selected_shift_date !== this.props.selected_shift_date) {
            await this.load();
        }
        if (this.state.orders.length > 0 && prevState.orders !== this.state.orders) {
            this.createSubscription();
        }
    }

    createSubscription() {
        if (this.subscription !== null) {
            unsubscribe(this.subscription);
        }
        this.subscription = subscribe(MSG_TYPES.export_worker_assignments_for_print, async () => {
            const html = renderShiftTable(this.state);
            workerAssignmentsExport(renderToString(html));
        });
    }

    getTimeOfSelectedShift(hour: number): number {
        const res = new Date(this.props.selected_shift_date);
        res.setMilliseconds(0);
        res.setMinutes(0);
        res.setSeconds(0);
        res.setHours(hour);
        return res.getTime();
    }

    getSelectedTimeEndMin = (): number => {
        const hour = SHIFT_CONSTS.FIRST_SHIFT_STARTING_HOUR + (
            SHIFT_CONSTS.SHIFT_DURATION_HOURS * this.props.selected_shift_number
        );
        return this.getTimeOfSelectedShift(hour);
    }

    getSelectedTimeEndMax = (): number => {
        const hour = SHIFT_CONSTS.FIRST_SHIFT_STARTING_HOUR + (
            SHIFT_CONSTS.SHIFT_DURATION_HOURS * (this.props.selected_shift_number + 1)
        );
        return this.getTimeOfSelectedShift(hour);
    }


    load = async () => {
        const api = getBackend2().manufacturing;
        const res = (await api.getLines({
            plant_uuid: this.props.plant_uuid,
            line_group_uuids: [this.props.line_group_uuid]
        })).lines;
        const line_uuids = res.map(line => line.uuid);
        const time_end_max = this.getSelectedTimeEndMax();
        const req: t.IGetOrdersSimpleReq = {
            line_uuids,
            time_end_max: time_end_max + 5 * TIME_RANGES.DAY,
            time_end_min: this.getSelectedTimeEndMin()
        };
        const res_orders = (await api.getOrdersSimple(req)).operations;
        // load parent orders to see if chunks should be ignored due to 
        // status of the parent order, e.g. skip_sim
        const parent_order_uuids = [...new Set(res_orders.map(x => x.parent_order_uuid || "").filter(x => x !== ""))];
        const res_orders_parents = (await api.getOrdersSimple({
            line_uuids,
            uuids: parent_order_uuids
        })).operations;
        // collect parent that should be worked on
        const parent_orders_map: Map<string, t.IOrderModelBase> = new Map();
        res_orders_parents
            .filter(o => o.skip_sim === false && o.quantity_total > 0)
            .forEach(o => parent_orders_map.set(o.uuid, o));
        const chunk_orders = res_orders
            .filter(o => o.parent_order_uuid !== undefined && o.quantity_total > 0 && parent_orders_map.has(o.parent_order_uuid || ""));
        this.setState({
            orders: chunk_orders.filter(x => (x.time_end || 0) <= time_end_max),
            orders_future: chunk_orders
        });
    }

    render() {
        return <div className="vertical-tab" style={{ marginTop: "15px" }}>
            <div ref={this.ref} id="worker-assignments" className="container-fluid">
                <div className="co-md-12 text-center">
                    <div className="white_box charts stock-requirements">
                        {renderShiftTable(this.state)}
                    </div>
                </div>
            </div>
        </div>;
    }
}

function renderShiftTable(state: State) {
    const { header, rows } = prepareRenderingData(state.orders_future);
    return <table className="table table-bordered text-left worker-assignments-table">
        <tr>
            <td>&nbsp;</td>
            {header.map(x => <th> {x} </th>)}
        </tr>
        {rows.map(row => {
            return <tr>
                <td>{row.person} ({ row.person_external_id })</td>
                {row.items.map(item => {
                    return <td className="worker-assignments-cell">
                        <div className="worker-assignments-shift-indicator">
                            {item.shift_index > 0 && <ShiftNumber value={item.shift_index} />}
                        </div>
                        <div className="worker-assignments-materials">
                            {item.materials.map(material => <div>{material.material} {material.quantity}</div>)}
                        </div>
                    </td>;
                })}
            </tr>;
        })}
    </table>;
}


function prepareRenderingData(orders_future: t.IOrderModelBase[]) {
    const distinct_days: Map<string, Date> = new Map();
    const distinct_people: Map<string, number> = new Map();
    const cells: Map<string, t.IOrderModelBase[]> = new Map();
    const orders_tmp = orders_future
        .sort((a, b) => (a.time_start || 0) - (b.time_start || 0));
    const map_person_name2ext_id: Map<string, string> = new Map();
    orders_tmp.forEach(x => {
        const sn = shiftNumber(x.time_start || 0);
        const day_tag = "" + (sn.year * 1000 + sn.week * 10 + sn.day_number);
        const person = x.tags.person_name;
        map_person_name2ext_id.set(person, x.tags.person_external_id);
        const key = day_tag + "#" + person;
        const shift_index = (sn.shift % 3) + 1;
        distinct_days.set(day_tag, new Date(sn.ts_start));
        if (!distinct_people.has(person)) {
            distinct_people.set(person, shift_index);
        }
        if (Math.round(x.quantity_total) > 0) {
            // sometimes chunks contain quantity < 0.5, here we skip them
            cells.set(key, (cells.get(key) || []).concat(x));
        }
    });

    const days = uniqueValues([...distinct_days.entries()].map(x => x[0])).sort();
    const rows = [...distinct_people.entries()]
        .sort((a, b) => {
            if (a[1] !== b[1]) {
                return a[1] - b[1];
            }
            return a[0].localeCompare(b[0]);
        })
        .map(x => x[0])
        .map(person => {
            const items = days.map(day_tag => {
                const key = day_tag + "#" + person;
                const materials: Array<{ m: string, q: number }> = [];
                let shift_index: number = -1;
                (cells.get(key) || []).sort((a, b) => (a.time_start || 0) - (b.time_start || 0)).forEach(order => {
                    const sn = shiftNumber(order.time_start || 0);
                    shift_index = (sn.shift % 3) + 1;
                    let found = false;
                    for (const mat of materials) {
                        if (mat.m === order.material_external_id) {
                            mat.q += order.quantity_total;
                            found = true;
                            break;
                        }
                    }
                    if (!found) {
                        materials.push({ m: order.material_external_id, q: order.quantity_total });
                    }
                });
                return {
                    shift_index,
                    materials: materials.map(material => ({
                        material: material.m,
                        quantity: niceNumber(material.q, 0)
                    }))
                };
            });

            return { person, person_external_id: map_person_name2ext_id.get(person), items };
        });

    const header = days.map(x => {
        const d = distinct_days.get(x) || new Date();
        return niceDate(d);
    });

    return { header, rows };
}

export default WorkerAssignments;
