// @flow

import React, { Component } from "react";
import ReactRouterPropTypes from "react-router-prop-types";

import * as t from "../../lib/backend/manufacturing2.generated.types";
import { translate } from "../IntlProviderWrapper";

import PeopleList from "./PeopleList";

type Props = {
    history: ReactRouterPropTypes.history,
    handlePeopleClick: t.IPersonData => void,
    people: t.IPersonData[],
    selected: t.IPersonData | null,
    selectedPlant: string,
    selectedLineGroup: string
};

type State = {
    name_filter: string
};

class ShiftAssignmentsPeople extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            name_filter: ""
        };
    }

    handleInputChange = (event: Event) => {
        if (
            event.currentTarget instanceof HTMLInputElement ||
            event.currentTarget instanceof HTMLSelectElement
        ) {
            // read new values
            const target = event.currentTarget;
            let value = target.value;
            const name = target.name;

            if (name === "name_filter") {
                this.setState({ name_filter: value });
            }
        }
    };

    onKeyDown = (event: SyntheticKeyboardEvent<HTMLInputElement>) => {
        event.nativeEvent.stopImmediatePropagation();

        if (event.keyCode === 13) {
            event.preventDefault();
            return false;
        }
    };

    render() {
        return (
            <div id="shift-assignments-sidebar">
                <div className="vertical_tab">
                    <div className="sub-filter-bar sub-filter-bar2 nav-narrow">
                        <input
                            type="text"
                            className="form-control search_bar"
                            placeholder={translate("common.search", "Search")}
                            name="name_filter"
                            value={this.state.name_filter}
                            onChange={this.handleInputChange}
                            onKeyDown={this.onKeyDown}
                        />
                    </div>
                    <PeopleList
                        activeFilter={true}
                        narrow={true}
                        people={this.props.people}
                        selected={this.props.selected}
                        filter={this.state.name_filter}
                        plantFilter={this.props.selectedPlant}
                        handlePeopleClick={this.props.handlePeopleClick}
                        line_group_uuid={this.props.selectedLineGroup}
                        showInactive={false}
                    />
                </div>
            </div>
        );
    }
}

export default ShiftAssignmentsPeople;
