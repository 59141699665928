// @flow

/************************************************************************************/
/* WARNING: This file is generated automatically. Don't edit it by hand             */
/************************************************************************************/

import * as api from "./restApi.js";
import * as t from "./ticketing.generated.types.js";

function enc(s: any): string { return encodeURIComponent("" + s); }

export interface ITicketing {
    /** Starts new ticket for given workflow */
    startTicket(req: t.IStartTicketReq): Promise<t.IStartTicketRes>;

    /** Retrieves workflow definition */
    getWorkflow(req: t.IGetWorkflowReq): Promise<t.IGetWorkflowRes>;

    /** Retrieves list of defined workflow names */
    getWorkflows(req: t.IGetWorkflowsReq): Promise<t.IGetWorkflowsRes>;

    /** Sets workflow definition */
    setWorkflow(req: t.ISetWorkflowReq): Promise<t.ISetWorkflowRes>;

    /** Retrieves pending user-steps assigned to current user */
    getUserInbox(req: t.IGetUserInboxReq): Promise<t.IGetUserInboxRes>;

    /** Retrieves pending service-step list */
    getServiceSteps(req: t.IGetServiceStepsReq): Promise<t.IGetServiceStepsRes>;

    /** Search tickets */
    searchTickets(req: t.ISearchTicketsReq): Promise<t.ISearchTicketsRes>;

    /** Quick lookup of ticket statuses */
    getTicketStatuses(req: t.IGetTicketStatusesReq): Promise<t.IGetTicketStatusesRes>;

    /** Retrieves ticket basic info */
    getTicketBasicInfo(req: t.IGetTicketBasicInfoReq): Promise<t.IGetTicketBasicInfoRes>;

    /** Retrieves ticket history */
    getTicketHistory(req: t.IGetTicketHistoryReq): Promise<t.IGetTicketHistoryRes>;

    /** Retrieves ticket variables */
    getTicketVariables(req: t.IGetTicketVariablesReq): Promise<t.IGetTicketVariablesRes>;

    /** Retrieves ticket attachments */
    getTicketAttachments(req: t.IGetTicketAttachmentsReq): Promise<t.IGetTicketAttachmentsRes>;

    /** Add ticket attachment */
    addTicketAttachment(req: t.IAddTicketAttachmentReq): Promise<t.IAddTicketAttachmentRes>;

    /** Retrieves current user-step info */
    getUserStepInfo(req: t.IGetUserStepInfoReq): Promise<t.IGetUserStepInfoRes>;

    /** Sends data to fulfill user-step */
    closeUserStep(req: t.ICloseUserStepReq): Promise<t.ICloseUserStepRes>;

    /** Retrieves current service-step info to prepare execution of service step */
    getServiceStepInfo(req: t.IGetServiceStepInfoReq): Promise<t.IGetServiceStepInfoRes>;

    /** Sends data to fulfill service-step */
    closeServiceStep(req: t.ICloseServiceStepReq): Promise<t.ICloseServiceStepRes>;

    /** Registers event when ticket encoutered an error */
    registerTicketRetryError(req: t.IRegisterTicketRetryErrorReq): Promise<t.IRegisterTicketRetryErrorRes>;

    /** Enables caller to make 'overrides' in the ticket, used for admins or watchers */
    updateAndOverrideState(req: t.IUpdateAndOverrideStateReq): Promise<t.IUpdateAndOverrideStateRes>;

    /** Re-assign ticket to another user */
    reassignTicket(req: t.IReassignTicketReq): Promise<t.IReassignTicketRes>;

    /** Closes ticket manually */
    closeTicket(req: t.ICloseTicketReq): Promise<t.ICloseTicketRes>;

    /** Sets or removes ticket error flag */
    setTicketErrorFlag(req: t.ISetTicketErrorFlagReq): Promise<t.ISetTicketErrorFlagRes>;

}

export class Ticketing implements ITicketing {

    client: api.ApiClient;

    constructor(client: api.ApiClient) { this.client = client; }

    /** Starts new ticket for given workflow */
    async startTicket(req: t.IStartTicketReq): Promise<t.IStartTicketRes> {
        const reqx: any = req;
        const url = `/api/v1.0/ticketing/workflow/${enc(req.name)}/start-ticket`;
        delete reqx.name;
        return (await this.client.post(url, reqx)).data;
    }

    /** Retrieves workflow definition */
    async getWorkflow(req: t.IGetWorkflowReq): Promise<t.IGetWorkflowRes> {
        const reqx: any = req;
        const url = `/api/v1.0/ticketing/workflow/${enc(req.name)}`;
        delete reqx.name;
        return (await this.client.get(url, reqx)).data;
    }

    /** Retrieves list of defined workflow names */
    async getWorkflows(req: t.IGetWorkflowsReq): Promise<t.IGetWorkflowsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/ticketing/workflow`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Sets workflow definition */
    async setWorkflow(req: t.ISetWorkflowReq): Promise<t.ISetWorkflowRes> {
        const reqx: any = req;
        const url = `/api/v1.0/ticketing/workflow`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Retrieves pending user-steps assigned to current user */
    async getUserInbox(req: t.IGetUserInboxReq): Promise<t.IGetUserInboxRes> {
        const reqx: any = req;
        const url = `/api/v1.0/ticketing/inbox`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Retrieves pending service-step list */
    async getServiceSteps(req: t.IGetServiceStepsReq): Promise<t.IGetServiceStepsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/ticketing/service-step/list`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Search tickets */
    async searchTickets(req: t.ISearchTicketsReq): Promise<t.ISearchTicketsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/ticketing/ticket`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Quick lookup of ticket statuses */
    async getTicketStatuses(req: t.IGetTicketStatusesReq): Promise<t.IGetTicketStatusesRes> {
        const reqx: any = req;
        const url = `/api/v1.0/ticketing/ticket-statuses`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Retrieves ticket basic info */
    async getTicketBasicInfo(req: t.IGetTicketBasicInfoReq): Promise<t.IGetTicketBasicInfoRes> {
        const reqx: any = req;
        const url = `/api/v1.0/ticketing/ticket/${enc(req.uuid)}/info`;
        delete reqx.uuid;
        return (await this.client.get(url, reqx)).data;
    }

    /** Retrieves ticket history */
    async getTicketHistory(req: t.IGetTicketHistoryReq): Promise<t.IGetTicketHistoryRes> {
        const reqx: any = req;
        const url = `/api/v1.0/ticketing/ticket/${enc(req.uuid)}/history`;
        delete reqx.uuid;
        return (await this.client.get(url, reqx)).data;
    }

    /** Retrieves ticket variables */
    async getTicketVariables(req: t.IGetTicketVariablesReq): Promise<t.IGetTicketVariablesRes> {
        const reqx: any = req;
        const url = `/api/v1.0/ticketing/ticket/${enc(req.uuid)}/variables`;
        delete reqx.uuid;
        return (await this.client.get(url, reqx)).data;
    }

    /** Retrieves ticket attachments */
    async getTicketAttachments(req: t.IGetTicketAttachmentsReq): Promise<t.IGetTicketAttachmentsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/ticketing/ticket/${enc(req.uuid)}/attachments`;
        delete reqx.uuid;
        return (await this.client.get(url, reqx)).data;
    }

    /** Add ticket attachment */
    async addTicketAttachment(req: t.IAddTicketAttachmentReq): Promise<t.IAddTicketAttachmentRes> {
        const reqx: any = req;
        const url = `/api/v1.0/ticketing/ticket/${enc(req.uuid)}/attachments`;
        delete reqx.uuid;
        return (await this.client.post(url, reqx)).data;
    }

    /** Retrieves current user-step info */
    async getUserStepInfo(req: t.IGetUserStepInfoReq): Promise<t.IGetUserStepInfoRes> {
        const reqx: any = req;
        const url = `/api/v1.0/ticketing/ticket/${enc(req.uuid)}/user-step`;
        delete reqx.uuid;
        return (await this.client.get(url, reqx)).data;
    }

    /** Sends data to fulfill user-step */
    async closeUserStep(req: t.ICloseUserStepReq): Promise<t.ICloseUserStepRes> {
        const reqx: any = req;
        const url = `/api/v1.0/ticketing/ticket/${enc(req.uuid)}/user-step`;
        delete reqx.uuid;
        return (await this.client.post(url, reqx)).data;
    }

    /** Retrieves current service-step info to prepare execution of service step */
    async getServiceStepInfo(req: t.IGetServiceStepInfoReq): Promise<t.IGetServiceStepInfoRes> {
        const reqx: any = req;
        const url = `/api/v1.0/ticketing/ticket/${enc(req.uuid)}/service-step`;
        delete reqx.uuid;
        return (await this.client.get(url, reqx)).data;
    }

    /** Sends data to fulfill service-step */
    async closeServiceStep(req: t.ICloseServiceStepReq): Promise<t.ICloseServiceStepRes> {
        const reqx: any = req;
        const url = `/api/v1.0/ticketing/ticket/${enc(req.uuid)}/service-step`;
        delete reqx.uuid;
        return (await this.client.post(url, reqx)).data;
    }

    /** Registers event when ticket encoutered an error */
    async registerTicketRetryError(req: t.IRegisterTicketRetryErrorReq): Promise<t.IRegisterTicketRetryErrorRes> {
        const reqx: any = req;
        const url = `/api/v1.0/ticketing/ticket/${enc(req.uuid)}/register-retry-error`;
        delete reqx.uuid;
        return (await this.client.post(url, reqx)).data;
    }

    /** Enables caller to make 'overrides' in the ticket, used for admins or watchers */
    async updateAndOverrideState(req: t.IUpdateAndOverrideStateReq): Promise<t.IUpdateAndOverrideStateRes> {
        const reqx: any = req;
        const url = `/api/v1.0/ticketing/ticket/${enc(req.uuid)}/update-and-override-state`;
        delete reqx.uuid;
        return (await this.client.post(url, reqx)).data;
    }

    /** Re-assign ticket to another user */
    async reassignTicket(req: t.IReassignTicketReq): Promise<t.IReassignTicketRes> {
        const reqx: any = req;
        const url = `/api/v1.0/ticketing/ticket/${enc(req.uuid)}/reassign`;
        delete reqx.uuid;
        return (await this.client.post(url, reqx)).data;
    }

    /** Closes ticket manually */
    async closeTicket(req: t.ICloseTicketReq): Promise<t.ICloseTicketRes> {
        const reqx: any = req;
        const url = `/api/v1.0/ticketing/ticket/${enc(req.uuid)}/close`;
        delete reqx.uuid;
        return (await this.client.post(url, reqx)).data;
    }

    /** Sets or removes ticket error flag */
    async setTicketErrorFlag(req: t.ISetTicketErrorFlagReq): Promise<t.ISetTicketErrorFlagRes> {
        const reqx: any = req;
        const url = `/api/v1.0/ticketing/ticket/${enc(req.uuid)}/set-error-flag`;
        delete reqx.uuid;
        return (await this.client.post(url, reqx)).data;
    }

}
