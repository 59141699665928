// @flow
import * as React from "react";
import { weekNumber, getDateRangeStrForWeek, getDateOfISOWeek } from "../../lib/Util";
import { translate } from "../IntlProviderWrapper";

type TooltipProps = {
    target: Element | null,
    children: React.Node,
    show: boolean
}

type TooltipState = {
    top: number,
    left: number
};

class CustomTooltip extends React.Component<TooltipProps, TooltipState> {

    state = {
        top: 0,
        left: 0
    }

    componentDidMount() {
        if (this.props.target) {
            this.updatePosition();
        }
    }

    componentDidUpdate(prev_props: TooltipProps) {
        if (prev_props.target !== this.props.target) {
            this.updatePosition();
        }
    }

    updatePosition = () => {
        const el = this.props.target;
        if (el) {
            const rect = el.getBoundingClientRect();
            this.setState({
                top: rect.top + window.scrollY,
                left: rect.left + window.scrollX
            });
        }
    }

    render() {
        if (!this.props.target) return null;
        const custom_style = {
            top: this.state.top,
            left: this.state.left,
            position: "fixed",
            zIndex: 2000,
            backgroundColor: "white"
        }

        return <div id="reusable-tooltip" style={custom_style}>
            {this.props.children}
        </div>
    }
}

type ISelectOption = {
    value: number,
    label: number
}

type WeekSelectProps = {
    onClickWeek: (option: number) => (e: Event) => void,
    selected_week: number | null,
    selected_year: number | null
}
type WeekSelectState = {
    options: ISelectOption[]
}

class WeekSelect extends React.Component<WeekSelectProps, WeekSelectState> {

    state = {
        options: []
    }

    componentDidMount() {
        this.load();
    }

    componentDidUpdate(prev_props: WeekSelectProps) {
        if (this.props.selected_week && prev_props.selected_week === this.props.selected_week) {
            const el = document.querySelector("#week-" + this.props.selected_week);
            if (el) {
                el.scrollIntoView();
            }
        }
    }

    load() {
        const weeks = [];
        for (let counter = 1; counter < 54; counter++) {
            weeks.push({ label: counter, value: counter });
        }

        this.setState({ options: weeks });
    }

    renderOption = (option: ISelectOption) => {
        const selected_year = this.props.selected_year;
        if (!selected_year) return null;

        const selectedClassName = this.props.selected_week === option.value ? "selected" : "";
        const className = "custom-select-item " + selectedClassName;
        const date_range = getDateRangeStrForWeek(option.value, selected_year);
        if (!date_range.includes(selected_year + "")) return null;

        return <div
            id={`week-${option.value}`}
            className={className}
            title={date_range}
            onClick={this.props.onClickWeek(option.value)}
        >
            {option.label}
        </div>
    }

    render() {
        return <div className="custom-select-menu-week">
            {this.state.options.map(this.renderOption)}
        </div>;
    }
}


type YearSelectProps = {
    onClickYear: (option: number) => (e: Event) => void,
    selected_year: number | null
};
type YearSelectState = {
    options: ISelectOption[]
};

class YearSelect extends React.Component<YearSelectProps, YearSelectState> {

    state = {
        options: []
    }

    componentDidMount() {
        this.load();
    }

    load() {
        const current_year = new Date().getFullYear();
        const options = [
            {
                value: current_year - 1,
                label: current_year - 1
            },
            {
                value: current_year,
                label: current_year
            },
            {
                value: current_year + 1,
                label: current_year + 1
            },
        ];
        this.setState({ options });
    }

    renderOption = (option: ISelectOption) => {
        const selectedClassName = this.props.selected_year === option.value ? "selected" : "";
        const className = "custom-select-item " + selectedClassName;
        return <div className={className} onClick={this.props.onClickYear(option.value)}>
            {option.label}
        </div>
    }

    render() {
        return <div className="custom-select-menu-year">
            {this.state.options.map(this.renderOption)}
        </div>;
    }
}

type Props = {
    onWeekPickerChange: (week: number, year: number) => void,
    filter_week: number,
    filter_year: number
};
type State = {
    selected_year: number | null,
    selected_week: number | null,
    show_week_picker: boolean
};

export default class WeekPicker extends React.Component<Props, State> {

    state = {
        show_week_picker: false,
        selected_year: null,
        selected_week: null
    }

    myRef = null;

    click_listener = null;

    componentDidMount() {
        this.myRef = React.createRef();
        this.click_listener = window.addEventListener("click", (e) => this.onWindowClick(e))
        // state initialized from the ... OUTSIDE
        if (this.props.filter_week && this.props.filter_year) {
            this.setState({
                selected_week: this.props.filter_week,
                selected_year: this.props.filter_year
            });
        }
    }

    componentWilUnmount() {
        if (this.click_listener) {
            window.removeEventListener("click", this.click_listener);
        }
    }

    onWindowClick = (e: Event) => {
        const current_target = e.target;
        if (current_target instanceof HTMLElement) {
            const should_close = !current_target.closest("[id^=custom-week-picker]");
            if (should_close) {
                this.setState({ show_week_picker: false });
            }
        }
    }

    onClick = (e: Event) => {
        e.stopPropagation();
        this.setState({ show_week_picker: true });
    }

    onClose = (e: Event) => {
        e.stopPropagation();
        this.setState({ show_week_picker: false });
    }

    onSave = (e: Event) => {
        e.stopPropagation();
        if (this.state.selected_week && this.state.selected_year) {
            this.props.onWeekPickerChange(this.state.selected_week, this.state.selected_year);
        }
        this.setState({ show_week_picker: false });
    }

    onClickYear = (selected_year: number) => (e: Event) => {
        this.setState({ selected_year });
    }

    onClickWeek = (selected_week: number) => (e: Event) => {
        this.setState({ selected_week });
    }

    onClickLeft = () => {
        if (this.state.selected_week && this.state.selected_year) {
            const date = getDateOfISOWeek(this.state.selected_week - 1, this.state.selected_year);
            const week = weekNumber(date);
            const year = date.getFullYear();

            this.setState({
                selected_week: week,
                selected_year: year
            });
            this.props.onWeekPickerChange(week, year);
        }
    }

    onClickRight = () => {
        if (this.state.selected_week && this.state.selected_year) {
            const date = getDateOfISOWeek(this.state.selected_week + 1, this.state.selected_year);
            const week = weekNumber(date);
            const year = date.getFullYear();
            this.setState({
                selected_week: week,
                selected_year: year
            });
            this.props.onWeekPickerChange(week, year);
        }
    }

    render() {
        return <div id="custom-week-picker-tooltip-container" className="custom-week-picker-tooltip-container" ref={this.myRef}>
            {this.state.show_week_picker && <CustomTooltip target={this.myRef && this.myRef.current} show={this.state.show_week_picker}>
                <div id="custom-week-picker-tooltip" className="custom-week-picker-tooltip" style={{ width: "200px"}}>
                    <div className="custom-week-picker-tooltip-border">
                        <div className="week-select">
                            <WeekSelect
                                onClickWeek={this.onClickWeek}
                                selected_week={this.state.selected_week}
                                selected_year={this.state.selected_year}
                            />
                        </div>
                        <div className="year-select">
                            <YearSelect
                                onClickYear={this.onClickYear}
                                selected_year={this.state.selected_year}
                            />
                        </div>
                    </div>
                    <div>
                        <button className="btn btn-primary" style={{position: "relative", width: "200px", height: "30px"}} onClick={this.onSave}>
                            {translate("common.save", "Save")}
                        </button>
                    </div>
                </div>
            </CustomTooltip>}
            <div>
                <button className="btn btn-primary decrement" onClick={this.onClickLeft}>
                    <i className="fas fa-caret-left"></i>
                </button>
                <button className="btn btn-primary" onClick={this.onClick}>
                    {this.state.selected_week} / {this.state.selected_year}
                </button>
                <button className="btn btn-primary increment" onClick={this.onClickRight}>
                    <i className="fas fa-caret-right"></i>
                </button>
            </div>
        </div>
    }
}
