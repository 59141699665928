// @flow

// main imports
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

// backend
import { getBackend } from "../../lib/backend/Backend2";
import * as t from "../../lib/backend/manufacturing2.generated.types";
import * as Auth from "../../lib/Auth";
import * as Util from "../../lib/Util";

// import subcomponents
import PeopleCompetence from "./PeopleCompetence";
import PeopleCompetenceModal from "./PeopleCompetenceModal";
import ErrorComponent from "../ErrorComponent";
import { translate } from "../IntlProviderWrapper";
import ReactTable from "../react/ReactTable";

// defining types
type Props = {
    selected: t.IPersonData,
    lines: t.ILineData[],
    plants: t.IPlantData[],
    standalone?: boolean
}

type State = {
    showModal: boolean,
    competences: t.ICompetenceData[],
    shifts: t.IShiftsPeopleEx[],
    error: string,
    warning: string
}

/////////////////////////////////////////
// MAIN COMPONENT

/**
 * Displaying people details and manage competences.
 */
class PeopleDetails extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        const state: State = {
            showModal: false,
            competences: [],
            shifts: [],
            error: "",
            warning: ""
        }
        this.state = state;
    }

    /** Load competences on first load */
    async componentDidMount() {
        await this.loadCompetences();
        await this.loadSchedule();
    }

    /**
     * Component updates by clicking another person. Competences need to be refreshed
     * from API.
     */
    async componentDidUpdate(prevProps: Props) {
        // we need to trigger reload only if it has not been triggered before
        if (prevProps.selected.uuid !== this.props.selected.uuid) {
            await this.loadCompetences();
            await this.loadSchedule();
        }
    }

    async loadCompetences() {
        try {
            const person_uuid = this.props.selected.uuid;
            const data = await getBackend().manufacturing.getCompetence({ person_uuid });
            this.setState({ competences: data.competences, error: "" });
        } catch (err) {
            this.setState({ error: err.message });
        }
    }

    async loadSchedule() {
        try {
            const person_uuid = this.props.selected.uuid;
            const data = await getBackend().manufacturing.getPeopleShiftsSimple({
                from_shift: Util.shiftNumber(Date.now() - Util.TIME_RANGES.WEEK).shift_tag,
                person_uuid,
                to_shift: Util.shiftNumber(Date.now() + Util.TIME_RANGES.WEEK).shift_tag
            });
            this.setState({ shifts: data.shifts_people, error: "" });
        } catch (err) {
            this.setState({ error: err.message });
        }
    }

    /**
     * Method triggered when Add/Update/Edit button is clicked. It opens competences modal.
     */
    handleModalShowClick() {
        this.setState({ showModal: true });
    }

    /**
     * Method triggered when modal close button (or modal background overlay) is clicked.
     * It closes the modal.
     */
    async handleModalCloseClick(do_refresh: boolean) {
        if (do_refresh) {
            await this.loadCompetences();
        }
        this.setState({ showModal: false });
    }

    renderTags() {
        const keys = Object.keys(this.props.selected.tags).sort((a, b) => a.localeCompare(b));
        const result = [];
        for (const key of keys) {
            result.push(<div key={key}>
                <dt>{translate(`Manufacturing.People.${key}`, key)}</dt>
                <dd>{this.props.selected.tags[key]}</dd>
            </div>);
        }
        return result;
    }

    renderCompetences() {
        let competences = this.state.competences;
        competences.sort((a, b) => {
            if (a.plant_title !== b.plant_title) {
                return Util.stringCompare(a.plant_title, b.plant_title);
            }
            return Util.stringCompare(a.line_title, b.line_title);
        });

        let grouppedCompetenceList = [];
        let plant_uuids = [...new Set(competences.map(x => x.plant_uuid))];
        plant_uuids.forEach(plant => {
            const competences_for_plant = competences.filter(x => x.plant_uuid === plant);
            grouppedCompetenceList.push(<h5 key={"competence_line_" + competences_for_plant[0].plant_uuid}>
                {competences_for_plant[0].plant_title}
            </h5>);
            const items = competences_for_plant.forEach((competence, i) => grouppedCompetenceList.push(<PeopleCompetence key={"competence_" + plant + "_" + i}
                level={competence.level}
                title={competence.line_title} />));
            grouppedCompetenceList.push(<ul className="list-group list-group-flush" key={"competence_line_group_" + plant}>
                {items}
            </ul>);
        });
        return grouppedCompetenceList;
    }

    renderScehulde() {
        if (this.state.shifts.length == 0) {
            return null;
        }
        const columns = [
            {
                Header: translate("common.shift", "Shift"),
                accessor: "shift_tag",
                disableFilters: true,
                Cell: (val) => {
                    const rec: t.IShiftsPeopleEx = val.cell.row.original;
                    const d = Util.fromShiftTag(rec.shift_tag)
                    return Util.niceDateTime(d);
                }
            },
            {
                Header: translate("common.line", "Line"),
                accessor: "line_title",
                disableFilters: true
            }
        ];
        const data = this.state.shifts.sort((a, b) => a.shift_tag.localeCompare(b.shift_tag));
        return <div>
            <h3>
                <FormattedMessage id="common.schedule" defaultMessage="Schedule" />
            </h3>
            <ReactTable
                data={data}
                columns={columns}
            />
        </div>;
    }

    /**
     * Rendering JSX for current component.
     */
    render() {

        // only show form if a person is selected and not null
        if (
            this.props.selected &&
            !(
                (this.props.selected.uuid === "") ||
                (this.props.selected.uuid === undefined)
            )
        ) {
            const can_edit = Auth.isInRoles([Auth.ROLE_ADMIN, Auth.ROLE_POWER_USER, Auth.ROLE_DEMO_USER, Auth.ROLE_MAN_PLANNER, Auth.ROLE_MAN_SHOPFLOOR_MANAGER]);

            // create a list of competences
            // sort competences by line
            let grouppedCompetenceList = this.renderCompetences();

            let selected_name = "";
            let selected_uuid = "";
            let selected_external_id = "";
            let selected_line_group_external_id = "";
            let isAdjuster = false;
            let shift_preference = [];
            let plants = "";

            if (this.props.selected !== null) {
                selected_name = this.props.selected.name;
                selected_uuid = this.props.selected.uuid;
                selected_external_id = this.props.selected.external_id;
                selected_line_group_external_id = this.props.selected.ted_id;
                isAdjuster = this.props.selected.adjuster;
                shift_preference = this.props.selected.shift_preference;

                // create hash table of plants
                let plants_hash = {};
                for (let i = 0; i < this.props.plants.length; i++) {
                    plants_hash[this.props.plants[i].uuid] = this.props.plants[i].title;
                }

                const plants_array = this.props.selected.plant_uuids;
                plants = plants_array.map(plant => {
                    return plants_hash[plant];
                }).join(", ");
            }

            let shifts = "";
            if (shift_preference !== undefined) {
                shifts = shift_preference.map((val, i) => {
                    let shift = i + 1;
                    if (val === true) {
                        return (
                            <span key={i} className={"badge badge-shift badge-shift-" + shift}>{shift}</span>
                        );
                    } else {
                        return null;
                    }
                })
            }

            const updateButton = can_edit ? (
                <button className="btn btn-outline-primary ml-auto" onClick={() => this.handleModalShowClick()}>
                    <FormattedMessage id="Manufacturing.People.edit_competences" defaultMessage="Edit competences" />
                </button>
            ) : null;

            // if we are in standalone mode back link on edit page is different
            const edit_url = (this.props.standalone === true) ?
                `/digital-twin/resources/person/edit/${selected_uuid}?back=view` :
                `/digital-twin/resources/person/edit/${selected_uuid}`;

            // render the whole component
            return (
                <div>
                    <ErrorComponent msg={this.state.error} type="error" />
                    <ErrorComponent msg={this.state.warning} type="warning" />
                    <div className="tab-content">
                        <div className="tab-pane active tab-pane-text" id="home">
                            <div className="d-flex">
                                {can_edit && <div className="button-list order-last ml-auto">
                                    <Link className="btn btn-outline-primary" to={edit_url}>
                                        <FormattedMessage id="common.edit" defaultMessage="Edit" />
                                    </Link>
                                </div>}
                                <div className="order-first">
                                    <h5 className="pane-title">{selected_name} {isAdjuster &&
                                        <span className="badge-list">
                                            <span className="badge badge-primary">
                                                <FormattedMessage id="common.adjuster" defaultMessage="adjuster" />
                                            </span>
                                        </span>
                                    }</h5>
                                    <dl className="property-list">
                                        <div>
                                            <dt><FormattedMessage id="common.personel_id" defaultMessage="Personel ID" /></dt>
                                            <dd><div>{selected_external_id}</div></dd>
                                        </div>
                                        <div>
                                            <dt><FormattedMessage id="common.linegroup" defaultMessage="Line-group" /></dt>
                                            <dd><div>{selected_line_group_external_id}</div></dd>
                                        </div>
                                        <div>
                                            <dt><FormattedMessage id="Manufacturing.People.allowed_shifts" defaultMessage="Allowed shifts" /></dt>
                                            <dd><span className="badge-list">{shifts}</span></dd>
                                        </div>
                                        <div>
                                            <dt><FormattedMessage id="Manufacturing.People.plant_membership" defaultMessage="Plant membership" /></dt>
                                            <dd><div>{plants}</div></dd>
                                        </div>
                                        <div>
                                            <dt><FormattedMessage id="common.tags" defaultMessage="Tags" /></dt>
                                            <div>{this.renderTags()}</div>
                                        </div>
                                        <div>
                                            <dt className="light"><FormattedMessage id="common.uuid" defaultMessage="UUID" /></dt>
                                            <dd className="light"><div>{selected_uuid}</div></dd>
                                        </div>
                                    </dl>
                                    {this.renderScehulde()}
                                </div>
                            </div>

                            <div className="d-flex align-items-center">
                                <h2>
                                    <FormattedMessage id="Manufacturing.People.line_competences" defaultMessage="Line competences" />
                                </h2>
                                {this.state.showModal && <PeopleCompetenceModal
                                    show={this.state.showModal}
                                    lines={this.props.lines}
                                    person_uuid={this.props.selected.uuid}
                                    plant_uuids={this.props.selected.plant_uuids}
                                    handleModalCloseClick={(do_refresh: boolean) => { this.handleModalCloseClick(do_refresh); }}
                                />}
                                {updateButton}
                            </div>
                            <div className="competences">
                                <div className="card">
                                    {grouppedCompetenceList.length > 0 && grouppedCompetenceList}
                                    {grouppedCompetenceList.length === 0 && <h5>
                                        <FormattedMessage id="Manufacturing.People.no_competences" defaultMessage="No competences" />
                                    </h5>}
                                    <div className="space15"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        // return empty element if there are no available competences
        return <div />;
    }
}

export default PeopleDetails;
