// @flow
import React from "react";
import { translate } from "../../../IntlProviderWrapper";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { SET_NUM_UNREAD_ALERTS } from "../reducers/alertsReducer";
import ReactTable from "../../../react/ReactTable";
import { niceDateTime } from "../../../../lib/Util";

import type { SetNumUnreadAlerts } from "../reducers/alertsReducer";
import type { ReduxState } from "../reducers/reducers";
import type { Alert } from "../reducers/alertsReducer";
import type { ReactTableColumn } from "../../../react/ReactTable";
import type { FilteredTypeOrder } from "../../../../lib/GanttData"

// $FlowFixMe
import "./AlertsModal.scss";

type Props = {
    show_alerts_modal: boolean,
    alerts: Alert[],
    onCloseAlertsModal: () => void
};
type State = {};

type Column = FilteredTypeOrder & {
    created_at: string,
    original_created_at: number,
    message: string,
    unread: boolean,
    line_title: string
}

const rowStyle = (row: any) => {
    let style = {};
    if (row.values.unread === true) {
        style["backgroundColor"] = "#eaeaea";
    } else {
        style["backgroundColor"] = "unset";
    }

    return style;
}

const getColumns = (): ReactTableColumn<$Shape<Column>>[] => {
    return [
        {
            Header: "",
            filterInputPlaceholder: translate("common.line", "Line"),
            accessor: "line_title"
        },
        {
            Header: "",
            filterInputPlaceholder: translate("common.order_external_id", "Order external ID"),
            accessor: "order_external_id"
        },
        {
            Header: "",
            filterInputPlaceholder: translate("common.process_num", "Process Num"),
            accessor: "process_num"
        },
        {
            Header: "",
            filterInputPlaceholder: translate("common.messages", "Message"),
            accessor: "message"
        },
        {
            Header: translate("OrderTableProduction.created_at", "Created At"),
            accessor: "created_at",
            disableFilters: true
        },
        {
            Header: "Read",
            accessor: "unread",
            disableFilters: true
        }
    ]
}

class AlertsModal extends React.Component<Props, State> {

    getData(): Column[] {
        const alerts = [];
        for (const alert of this.props.alerts) {
            for (const line of alert.filtered_lines || []) {
                for (const order of line.orders) {
                    alerts.push({
                        message: order.message || "",
                        created_at: niceDateTime(new Date(alert.created_at)),
                        original_created_at: alert.created_at,
                        line_title: line.line_title ? line.line_title : "-",
                        process_num: order.process_num,
                        order_external_id: order.order_external_id,
                        unread: alert.unread
                    });
                }
            }
            for (const past_order of alert.past_orders || []) {
                alerts.push({
                    message: translate("common.order_is_in_the_past", "Order is in the past") + " - " + niceDateTime(new Date(past_order.earliest_start)),
                    created_at: niceDateTime(new Date(alert.created_at)),
                    original_created_at: alert.created_at,
                    line_title: past_order.line_title ? past_order.line_title : "-",
                    process_num: past_order.process_num,
                    order_external_id: past_order.external_id,
                    unread: alert.unread
                });
            }
        }
        return alerts.sort((a, b) => a.original_created_at > b.original_created_at ? -1 : 1);
    }

    render() {
        const data = this.getData();
        const columns = getColumns();

        return <Modal
            show={this.props.show_alerts_modal}
            id="alerts-modal"
            onHide={this.props.onCloseAlertsModal}
            bsSize="large"
        >
            <Modal.Header>
                <Modal.Title style={{width: "100%"}}>
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <span>{translate("Header.menu.alerts", "Alerts")}</span>
                        <button type="button" className="close" onClick={(e: Event) => {e.stopPropagation(); this.props.onCloseAlertsModal()}}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ReactTable
                    columns={columns}
                    hiddenColumns={["unread"]}
                    rowStyle={rowStyle}
                    data={data}
                />
            </Modal.Body>
        </Modal>
    }
}

const ConnectedAlertsModal = connect(
    (state: ReduxState) => {
        const alerts = state.alerts.alerts;
        return { alerts }
    },
    dispatch => ({ reduxDispatch: dispatch })
)(AlertsModal);


type ViewMenuAlertsProps = {
    num_unread_alerts: number,
    reduxDispatch: (dispatch: SetNumUnreadAlerts) => void
}
type ViewMenuAlertsState = {
    show_alerts_modal: boolean
};

export const DARK_RED = "#bb1e1d";

class ViewMenuAlerts extends React.Component<ViewMenuAlertsProps, ViewMenuAlertsState> {
    state = {
        show_alerts_modal: false
    }

    onShowAlertsModal = () => {
        this.setState({ show_alerts_modal: true });
    }

    onCloseAlertsModal = () => {
        this.props.reduxDispatch({ type: SET_NUM_UNREAD_ALERTS, data: 0 });
        this.setState({ show_alerts_modal: false });
    }

    render() {
        return (
            <button
                className="dropdown-item"
                onClick={this.onShowAlertsModal}
            >
                {translate("Header.menu.alerts", "Alerts")} {this.props.num_unread_alerts ? `(${this.props.num_unread_alerts})` : "" }
                <ConnectedAlertsModal
                    show_alerts_modal={this.state.show_alerts_modal}
                    onCloseAlertsModal={this.onCloseAlertsModal}
                />
            </button>
        )
    }
}

export const ConnectedViewMenuAlerts = connect(
    (state: ReduxState) => {
        return {
            num_unread_alerts: state.alerts.num_unread_alerts
        }
    },
    dispatch => ({ reduxDispatch: dispatch })
)(ViewMenuAlerts);