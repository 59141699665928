// @flow
import * as React from "react";
import ReactRouterPropTypes from "react-router-prop-types";

import { getBackend } from "../../lib/backend/Backend2";
import InsightsList from "./InsightsList";
import Loader from "../Loader";
import { toISODateTimeString, TIME_RANGES } from "../../lib/Util";
import * as t from "../../lib/backend/manufacturing2.generated.types";
import { FormattedMessage } from "react-intl";

import ErrorComponent from "../ErrorComponent";

type Props = {
    history: ReactRouterPropTypes.history,
    match: ReactRouterPropTypes.match
};

type State = {
    insights_all: t.IEventDataEx[],
    insights_fetched: boolean,
    error: string,
    report1: t.ISimulationReportData | null,
    report2: t.ISimulationReportData | null
};

class InsightsPage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        const state: State = {
            insights_all: [],
            insights_fetched: false,
            error: "",
            report1: null,
            report2: null
        };
        this.state = state;
    }

    async componentDidMount() {
        await this.reloadInsights();
    }

    async reloadInsights() {
        try {
            const res = await getBackend().manufacturing.getInsights({});
            this.setState({
                insights_all: res.insights,
                insights_fetched: true,
                report1: res.report1,
                report2: res.report2
            });
            // schedule another reload after 10 minutes
            setTimeout(() => {
                this.reloadInsights();
            }, 10 * TIME_RANGES.MINUTE);
        } catch (e) {
            this.setState({ error: e.message });
        }
    }

    render() {
        const rep1 = this.state.report1;
        const rep2 = this.state.report2;
        if (this.state.error) {
            return (
                <ErrorComponent msg={this.state.error} type="warning" />
            );
        } else if (this.state.insights_fetched && rep1 && rep2) {
            return (
                <article className="article">
                    <section id="statistics" className="data_sources">
                        <div className="filter_bar">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <ul className="list-inline">
                                            <li className="list-inline-item">
                                                <h2>
                                                    <FormattedMessage id="Manufacturing.InsightsPage.manufacturing_insights" defaultMessage="Manufacturing insights" />
                                                </h2>
                                            </li>
                                            <li className="list-inline-item">
                                                <dl className="property-list">
                                                    <div>
                                                        <dt>
                                                            <FormattedMessage id="Manufacturing.InsightsPage.latest_simulation" defaultMessage="Latest simulation" />
                                                        </dt>
                                                        <dd>{toISODateTimeString(new Date(rep1.created_at))}</dd>
                                                    </div>
                                                    <div>
                                                        <dt>
                                                            <FormattedMessage id="Manufacturing.InsightsPage.compared_with" defaultMessage="Compared with" />
                                                        </dt>
                                                        <dd>{toISODateTimeString(new Date(rep2.created_at))}</dd>
                                                    </div>
                                                </dl>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="vertical_tab">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                        <br /><br /><br /><br />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                        <div className="card card-insights">
                                            <InsightsList
                                                history={this.props.history}
                                                insights={this.state.insights_all}
                                                reportid={rep1.uuid}
                                                week={rep1.shift_time.week_number}
                                                year={rep1.shift_time.year}
                                                show_filter={true}
                                                show_only_future={true}
                                                show_links={true}
                                                show_actions={true} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </article>
            )
        } else {
            return (
                <article className="article">
                    <section id="statistics" className="data_sources">
                        <Loader />
                    </section>
                </article>
            )
        }
    }
}
export default InsightsPage;
