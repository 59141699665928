// @flow
import React from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import Loader from "../Loader";
import ErrorComponent from "../ErrorComponent";
import { TicketDetailsDiv } from "./TicketDetailsDiv";
import { getBackend as getBackend2 } from "../../lib/backend/Backend2";

import type { TicketState } from "./CommonTicketTypes"

type Props = {
    show_ticket_details_uuid: string | null,
    onClose: () => void,
    requestRefreshCallback: () => Promise<void | null> | Promise<null> | null
};


type State = {
    loading: boolean,
    ticket: TicketState,
};

export default class TicketDetailsModal extends React.Component<Props, State> {

    state: State = {
        loading: false,
        ticket: {
            user_step: null,
            history: null,
            variables: null,
            title: null,
            is_error: false,
            uuid: "",
            is_closed: true,
            assigned_to: null,
            assigned_on: "",
            workflow_title: null,
            step: null,
            workflow_version: null
        }
    }

    componentDidMount() {
        if (this.props.show_ticket_details_uuid) {
            this.load();
        }
    }
    componentDidUpdate(prev_props: Props) {
        if (prev_props.show_ticket_details_uuid !== this.props.show_ticket_details_uuid && this.props.show_ticket_details_uuid) {
            this.load();
        }
    }

    async load() {
        this.setState({ loading: true });
        const ticket_details_uuid = this.props.show_ticket_details_uuid;
        if (!ticket_details_uuid) {
            return;
        }
        const res_ticket = await getBackend2().ticketing.getTicketBasicInfo({
            uuid: ticket_details_uuid
        });

        const history = await getBackend2().ticketing.getTicketHistory({
            uuid: ticket_details_uuid
        });
        const { variables } = await getBackend2().ticketing.getTicketVariables({
            uuid: ticket_details_uuid
        });
        this.setState({
            ticket: {
                ...res_ticket,
                user_step: null,
                history: history.items,
                variables
            },
            loading: false
        });
    }

    async onRequestRefresh(): Promise<void> {
        await this.load();
        if (this.props.requestRefreshCallback != null) {
            this.props.requestRefreshCallback();
        }
    }

    renderTicket() {
        return <div>
            <TicketDetailsDiv
                ticket={this.state.ticket}
                show_history={true}
                show_actions={this.props.requestRefreshCallback != null}
                requestRefreshCallback={() => { this.onRequestRefresh(); }}
                hide_tabs={false}
            />
        </div>;
    }

    renderBody() {
        if (this.state.loading) {
            return <Loader />
        }
        if (this.state.ticket) {
            return this.renderTicket()
        }

        return <ErrorComponent msg={"Could not load data"} type="warning" />;
    }

    render() {
        return <Modal bsSize="large" show={this.props.show_ticket_details_uuid} onHide={this.props.onClose}>
            <Modal.Header>
                <Modal.Title>
                    <FormattedMessage id="Ticketing.ticket_details" defaultMessage="Ticket details" />
                </Modal.Title>
                <button type="button" className="close" onClick={this.props.onClose}>
                    <span aria-hidden="true">×</span>
                </button>
            </Modal.Header>
            <Modal.Body>
                {this.renderBody()}
            </Modal.Body>
        </Modal>;
    }
}
