// @flow

import React from "react";

// $FlowFixMe
import { ReactComponent as PowerOffIcon } from "@fortawesome/fontawesome-free/svgs/solid/power-off.svg";
import SvgIcon, { type SvgIconProps } from "../SvgIcon";

const PowerOff = (props: SvgIconProps) => (
    <SvgIcon {...props} size={props.size || "fs"}>
        <PowerOffIcon />
    </SvgIcon>
);

export default PowerOff;
