// @flow

// main imports
import React, { Component } from "react";
import { Link } from "react-router-dom";
import ReactRouterPropTypes from "react-router-prop-types";
import { FormattedMessage } from "react-intl";

// models
import type { IBatchOperationLocation, IBatchOperationGroup } from "../../lib/backend/batch_operations.generated.types";
import type { ILineGroupData } from "../../lib/backend/manufacturing2.generated.types";

// backend
import { getBackend } from "../../lib/backend/Backend2";
import {
    waitForManufacturingAsync, getLineGroupsForUser
} from "../../lib/BusinessLogic";
import * as Auth from "../../lib/Auth";
import { stringCompare } from "../../lib/Util";

// import subcomponents
import BatchopGroupsList from "./BatchopGroupsList"
import BatchopGroupsDetails from "./BatchopGroupsDetails"
import ErrorComponent from "../ErrorComponent";
import { translate } from "../IntlProviderWrapper";
import { ResourcesLayout } from "../MainContent";


// defining types
type Props = {
    match: ReactRouterPropTypes.match,
    history: ReactRouterPropTypes.history,
    location: ReactRouterPropTypes.location
}

type State = {
    batchop_groups: IBatchOperationGroup[],
    batchop_locations: IBatchOperationLocation[],
    line_groups: ILineGroupData[],
    plant_uuid: string,
    error: string,
    warning: string,
    selected: IBatchOperationGroup | null,
    title_filter: string
}

/**
 * Displaying batch operations groups.
 */
class BatchopGroupsComponent extends Component<Props, State> {
    batchopGroupsListRef: { current: null | BatchopGroupsList };

    /**
     * Constructor.
     * @param {Props} props Props object.
     * @param {State} state State object.
     */
    constructor(props: Props, state: State) {
        super(props);
        this.state = {
            batchop_groups: [],
            batchop_locations: [],
            line_groups: [],
            plant_uuid: "",
            error: "",
            warning: "",
            selected: null,
            title_filter: ""
        };
        this.batchopGroupsListRef = React.createRef();
    }

    /**
     * Load component data from backend.
     */
    async loadComponent() {

        try {
            await waitForManufacturingAsync();
            const line_groups = getLineGroupsForUser();
            const batchop_groups = (await getBackend().batchOperations.getGroups({})).items;
            let selected = null;

            // Select initial batchop_group.
            if (this.props.match.params.uuid) {
                let filtered = batchop_groups.filter(batchop_group => batchop_group.uuid === this.props.match.params.uuid);
                if (filtered.length) {
                    selected = filtered[0];
                }
            } else if (batchop_groups.length) {
                selected = batchop_groups[0];
            }

            let batchop_locations: IBatchOperationLocation[] = [];

            if (selected !== null) {
                batchop_locations = await this.loadBatchopLocations(selected.uuid);
            }

            this.setState({
                batchop_groups,
                batchop_locations,
                line_groups,
                selected,
                error: "",
                warning: ""
            });
        } catch (e) {
            this.setState({ error: e });
        }

    }

    /**
     * Load batchop groups.
     */
    async loadBatchopGroups() {
        // get available batchop groups
        const s = this.state.selected;
        if (s !== null) {
            const i = this.state.batchop_groups.findIndex(x => x.uuid === s.uuid);
            // $FlowFixMe -- there is an await; no promise!
            const batchop_groups = (await getBackend().batchOperations.getGroups({})).items
                .sort((a, b) => stringCompare(a.title, b.title));
            const batchop_locations = await this.loadBatchopLocations(batchop_groups[i].uuid);
            this.setState({
                batchop_groups,
                batchop_locations,
                selected: batchop_groups[i]
            });
        };
    }

    /**
     * Load batchop locations.
     */
    async loadBatchopLocations(uuid: string) {
        const { items } = await getBackend().batchOperations.getLocations({
            group_uuid: uuid
        });
        return items;
    }

    /**
     * Mounting data for this component.
     */
    async componentDidMount() {
        await this.loadComponent();
    }

    /**
     * Deleting a selected line.
     *
     * @param {string} uuid Line universal unique identifier.
     */
    async handleToDelete(uuid: string) {
        try {
            await getBackend().batchOperations.deleteGroup({ group_id: uuid });
            this.handleToUpdate();
        } catch (e) {
            this.handleToError(e);
        };
    }

    /**
     * Select the first line that matches all the given criteria.
     * @param {string} contentFilter Line search term.
     * @param {string} plantUuid Plant UUID.
     * @param {LineGroupObj[]} [lines] List of lines to select from.
     */
    selectBatchopGroup(contentFilter: string, batchop_groups: IBatchOperationGroup[] = this.state.batchop_groups): IBatchOperationGroup | null {
        for (let batchop_group of batchop_groups) {
            const visible = this.batchopGroupsListRef.current && this.batchopGroupsListRef.current.includes(contentFilter, batchop_group);
            if (visible) {
                return batchop_group;
            }
        }

        return null;
    }

    /**
     * Handling change in any input component.
     */
    handleInputChange = async (event: Event) => {
        if (event.currentTarget instanceof HTMLInputElement || event.currentTarget instanceof HTMLSelectElement) {
            // read new values
            const target = event.currentTarget;
            const value = target.value;
            const name = target.name;

            // update new datasource placeholder
            this.setState(prev_state => {
                prev_state[name] = value;
                return prev_state;
            });

            let titleFilter = this.state.title_filter;
            if (name === "title_filter") {
                titleFilter = value;
            }

            const selected = this.selectBatchopGroup(titleFilter);
            let batchop_locations: IBatchOperationLocation[] = [];
            if (selected !== null) {
                batchop_locations = await this.loadBatchopLocations(selected.uuid);
            }

            this.setState({
                batchop_locations,
                selected
            });

        }
    };

    /**
     * Handle `click` events for line items.
     */
    async handleBatchopGroupClick(batchop_group: IBatchOperationGroup) {
        const batchop_locations = await this.loadBatchopLocations(batchop_group.uuid);
        this.setState({
            batchop_locations,
            selected: batchop_group
        });

        // Update URL.
        this.props.history.push(`/digital-twin/resources/batchop_groups/${batchop_group.uuid}`);
    }

    /**
     * Handle, that can be called by the children, to update the data.
     */
    handleToUpdate = () => {
        this.loadComponent();
    }

    /**
     * Handling error from subordinate component.
     */
    handleToError = (str: string) => {
        this.setState({ error: str });
    }

    /**
     * Rendering JSX for current component.
     */
    render() {
        const can_edit = Auth.isInRole(Auth.ROLE_ADMIN) || Auth.isInRole(Auth.ROLE_POWER_USER) || Auth.isInRole(Auth.ROLE_DEMO_USER);
        return (
            <ResourcesLayout>
                <ResourcesLayout.Main>
                    <ResourcesLayout.List>
                        <ResourcesLayout.Search>
                            <div className="form-group form-group-stretch">
                                <input type="text" className="form-control search_bar" placeholder={translate("common.search", "Search") + " ..."}
                                    name="title_filter" value={this.state.title_filter} onChange={this.handleInputChange} />
                            </div>
                            {can_edit && <div className="form-group">
                                <Link to="/digital-twin/resources/batchop_group/create" className="btn btn-inv-primary btn-icon btn-icon-add"
                                    title={translate("Manufacturing.BatchopGroups.add_new_batchop_group", "Add new batch operations group")}>
                                    <span className="sr-only">
                                        <FormattedMessage id="Manufacturing.BatchopGroups.add_new_batchop_group" defaultMessage="Add new batch operations group" />
                                    </span>
                                </Link>
                            </div>}
                        </ResourcesLayout.Search>
                        <BatchopGroupsList
                            ref={this.batchopGroupsListRef}
                            batchop_groups={this.state.batchop_groups}
                            selected={this.state.selected}
                            filter={this.state.title_filter}
                            handleItemClick={(item) => { this.handleBatchopGroupClick(item) }} />
                    </ResourcesLayout.List>
                    <ResourcesLayout.Content>
                        {
                            this.state.batchop_groups === 0 && <ErrorComponent
                                key="empty"
                                className="my-0 border-left-0 border-right-0 rounded-0"
                                type="no-data"
                                msg={translate("common.no_data", "No data available")}
                            />
                        }
                        <ErrorComponent msg={this.state.error} type="error" />
                        <ErrorComponent msg={this.state.warning} type="warning" />
                        {this.state.selected &&
                            <BatchopGroupsDetails
                                selected={this.state.selected}
                                batchop_locations={this.state.batchop_locations}
                                line_groups={this.state.line_groups}
                                editLink="/digital-twin/resources/batchop_group/edit/"
                                handleToDelete={(uuid) => this.handleToDelete(uuid)}
                                loadBatchopGroups={async () => this.loadBatchopGroups()}
                            />
                        }
                    </ResourcesLayout.Content>
                </ResourcesLayout.Main>
            </ResourcesLayout>
        )
    }
}

export default BatchopGroupsComponent;
