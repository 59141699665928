// @flow
import * as React from "react";
import { FormGroup, ControlLabel } from "react-bootstrap";

import { getBackend as getBackend2 } from "../lib/backend/Backend2";
import type { IPlantData, ILineGroupData } from "../lib/backend/manufacturing2.generated.types";
import * as t from "../lib/backend/users.generated.types";
import * as Auth from "../lib/Auth";
import * as BusinessLogic from "../lib/BusinessLogic";
import { FormattedMessage } from "react-intl";
import Toggle from "react-toggle";

import DataFields from "./react/DataFields";
import Loader from "./Loader";
import SettingsNotifications from "./SettingsNotifications";
import ChangePassword from "./ChangePassword";
import { getAnonymization, setAnonymization } from "../lib/Anonymization";
import { publishAndWait, MSG_TYPES } from "../lib/PubSub";
import { translate } from "./IntlProviderWrapper";
import TwoFA from "./TwoFA";
import { USER_TAGS_ACCESS } from "../lib/CommonTags.generated";

type Props = {};

type State = {
    message: string,
    show_anonymization_loader: boolean,
    show_password_change: boolean,
    line_groups: ILineGroupData[],
    plants: IPlantData[],
    user_info?: t.IGetCurrentUserDataRes,
    anonymize: boolean,
    fixed_quick_sim_cache_hash: boolean
};

class Settings extends React.Component<Props, State> {
    handleChange: any;
    handleCheck: any;
    getInitialState: any;

    constructor() {
        super();
        const state: State = {
            message: "",
            line_groups: [],
            plants: [],
            show_anonymization_loader: false,
            show_password_change: false,
            anonymize: false,
            fixed_quick_sim_cache_hash: (localStorage.getItem("fixed_quick_sim_cache_hash") === "true")
        };
        this.state = state;
    }

    async componentDidMount() {
        await this.loadData();
    }

    async loadData() {
        try {
            await BusinessLogic.waitForManufacturingAsync();
            const user_info = await getBackend2().users.getCurrentUserData({});
            this.setState({
                anonymize: getAnonymization(),
                user_info: user_info,
                line_groups: BusinessLogic.getLineGroupsForUser(),
                plants: BusinessLogic.getPlantsForUser()
            });
        } catch (err) {
            console.log(err);
        }
    }

    handleNotificationsToggle = (event: Event) => {
        this.setState(prev_state => {
            if (prev_state.user_info) {
                prev_state.user_info.user_data.notifications.enabled = !prev_state.user_info.user_data.notifications.enabled;
                return prev_state;
            }
        });
    };

    handleAnonymizationToggle = async (event: Event) => {
        let target = event.target;
        if (target instanceof HTMLInputElement) {
            if (target.type === "checkbox") {
                const anonymize: boolean = target.checked;
                this.setState({ anonymize, show_anonymization_loader: true });
                await setAnonymization(anonymize);
                // tell everyone that user's profile was updated so that caches are refreshed
                this.setState({ show_anonymization_loader: false });
                await publishAndWait(MSG_TYPES.user_profile_reloaded, {});
                await this.loadData();
            }
        }
    };

    handleNotificationsEmail = (event: Event) => {
        let target = event.target;
        if (target instanceof HTMLInputElement) {
            this.setState(prev_state => {
                if (prev_state.user_info) {
                    prev_state.user_info.user_data.notifications.email = target.value;
                }
                return prev_state;
            });
        }
    };

    handleNotificationsSubmit = async (event: Event) => {
        event.preventDefault();
        if (this.state.user_info) {
            try {
                const notifications = this.state.user_info.user_data.notifications;
                await getBackend2().users.setUserNotifications({
                    enabled: notifications.enabled,
                    id: Auth.getLoggedinUser().uuid,
                    notify_capacity: notifications.notify_capacity || false,
                    notify_downtime: notifications.notify_downtime || false,
                    notify_plan_change: notifications.notify_plan_change || false,
                    notify_plan_problems: notifications.notify_plan_problems || false,
                    notify_scrap: notifications.notify_scrap || false,
                    notify_stock: notifications.notify_stock || false,
                    notify_tool_setup: notifications.notify_tool_setup || false,
                    email: notifications.email,
                    line_group: notifications.line_group,
                    plant: notifications.line_group
                });
                this.setState({ message: "Notification settings updated!" });
            } catch (err) {
                this.setState({ message: "Error updating notification setttings" });
                console.log(err);
            }
        }
    };

    validateEmail = () => {
        return this.state.user_info &&
            this.state.user_info.user_data.notifications &&
            this.state.user_info.user_data.notifications.email &&
            Auth.validateEmail(this.state.user_info.user_data.notifications.email);
    };

    renderPasswordChange = () => {
        if (this.state.show_password_change) {
            return <ChangePassword
                onClose={() => { this.setState({ show_password_change: false }) }}
            />;
        } else {
            return (<div className="text-center">
                <button className="btn" onClick={() => { this.setState({ show_password_change: true }) }}><FormattedMessage id="Settings.change_password" defaultMessage="Change password" /></button>
            </div>);
        }
    };

    render2FA = () => {
        if (this.state.user_info) {
            const has_2fa = USER_TAGS_ACCESS.twofa(this.state.user_info.tags);
            return <TwoFA twofa={has_2fa} />;
        }
    }

    renderAnonymizationForm = () => {
        const env_flag = BusinessLogic.getSysFlag("env");
        if (env_flag !== "dev" && env_flag !== "beta") { return null; }

        const user_info = this.state.user_info;
        const roles = this.state.user_info && this.state.user_info.roles;

        const can_anonymize = (
            user_info &&
            roles &&
            (roles.includes(Auth.ROLE_ADMIN) || roles.includes(Auth.ROLE_DEMO_USER))
        );
        if (can_anonymize) {
            if (this.state.show_anonymization_loader) {
                return <Loader />;
            } else {
                return (<FormGroup controlId="anon" bsSize="small">
                    <ControlLabel>
                        <b><FormattedMessage id="common.anonymization" defaultMessage="Anonymization" /></b>
                        <FormattedMessage id="Settings.anonymization" />
                    </ControlLabel>
                    <Toggle id="cb_anonymize" className="float-right"
                        checked={this.state.anonymize}
                        onChange={this.handleAnonymizationToggle} />
                </FormGroup>);
            }
        }
    }

    handeQuickSimCachehash = async (event: Event) => {
        let target = event.target;
        if (target instanceof HTMLInputElement) {
            if (target.type === "checkbox") {
                const fixed_quick_sim_cache_hash: boolean = target.checked;
                // store new value to local storage
                localStorage.setItem("fixed_quick_sim_cache_hash", fixed_quick_sim_cache_hash ? "true" : "false");
                this.setState({ fixed_quick_sim_cache_hash });
            }
        }
    };

    renderQuickSimDebug = () => {
        const env_flag = BusinessLogic.getSysFlag("env");
        // only show this in dev
        if (env_flag !== "dev") { return null; }

        return (<FormGroup controlId="anon" bsSize="small">
            <ControlLabel>
                <FormattedMessage id="Settings.fixed_quick_sim_cache_hash" defaultMessage="Fixed quick sim cache hash" />
            </ControlLabel>
            <Toggle id="cb_fixed_cache_hash" className="float-right"
                checked={this.state.fixed_quick_sim_cache_hash}
                onChange={this.handeQuickSimCachehash} />
        </FormGroup>);
    }

    setUserLang = async (lang: string) => {
        await getBackend2().users.setUserLang({ lang });
        Auth.setUserLang(lang);
    }

    renderLang = () => {
        return <FormGroup bsSize="small">
            <ControlLabel><FormattedMessage id="Settings.set_language" defaultMessage="Language" /></ControlLabel>
            <button className="btn ml-1 float-right" onClick={() => { this.setUserLang("en") }}>English</button>
            <button className="btn ml-1 float-right" onClick={() => { this.setUserLang("de") }}>Deutsch</button>
            <button className="btn ml-1 float-right" onClick={() => { this.setUserLang("cs") }}>Český</button>
            <button className="btn ml-1 float-right" onClick={() => { this.setUserLang("sl") }}>Slovensko</button>
        </FormGroup>;
    }

    renderRolesAndPlants = () => {
        let roles = [];
        if (this.state.user_info) {
            roles = this.state.user_info.roles.map((x, i) => {
                let css = "badge badge-";
                if (x === "Admin") {
                    css += "success";
                } else {
                    css += "secondary";
                }
                return (<span key={i} className={css}>{x}</span>);
            });
        }
        const line_groups = this.state.line_groups
            .map((x, i) => {
                let css = "badge badge-secondary mr-2";
                return (<span key={i} className={css}>{x.title}</span>);
            });
        const plants = this.state.plants
            .map((x, i) => {
                let css = "badge badge-secondary mr-2";
                return (<span key={i} className={css}>{x.title}</span>);
            });
        return <div className="white_box">
            <div>
                <h4> <FormattedMessage id="Settings.roles" defaultMessage="Roles" />:</h4>
                <p> {roles} </p>
            </div>
            {
                plants.length > 0 && <div>
                    <h4> <FormattedMessage id="common.plants" defaultMessage="Plants" />:</h4>
                    <p> {plants} </p>
                </div>
            }
            {
                line_groups.length > 0 && <div>
                    <h4> <FormattedMessage id="common.linegroups" defaultMessage="Line-groups" />:</h4>
                    <p> {line_groups} </p>
                </div>
            }
        </div>;
    }

    render() {
        const user_data_auth = Auth.getLoggedinUser();
        let data_fields = [];

        const uinfo = this.state.user_info;
        if (uinfo != undefined) {
            data_fields = [
                [{ label: translate("common.username", "Username"), text: user_data_auth.username }],
                [{ label: translate("common.title", "Title"), text: uinfo.user_data.title }],
                [{ label: translate("common.language", "Language"), text: Auth.getUserLang() ?? "-" }],
                [{ label: translate("common.uuid", "UUID"), text: uinfo.user_data.uuid }]
            ];
        }
        return (
            <div key="user_info">
                <section key="1">
                    <img src="img/banner.png" alt="banner" className="img-fluid-header banner_img" />
                </section>
                <section id="profile_edit" key="2">,
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="white_box">
                                    <div className="upload_img">
                                        <img src="img/profile_image.png" alt="profile_image" className="img-fluid" />
                                    </div>
                                    <input type="file" className="hide" name="" />
                                    <h1>
                                        {this.state.user_info ? this.state.user_info.user_data.title : ""}
                                    </h1>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <DataFields rows={data_fields}></DataFields>
                                        </div>
                                        <div className="col-md-8">
                                            {this.renderPasswordChange()}
                                            <hr />
                                            {this.render2FA()}
                                            <hr />
                                            {this.renderAnonymizationForm()}
                                            {this.renderQuickSimDebug()}
                                            <hr />
                                            {this.renderLang()}
                                        </div>
                                    </div>
                                </div>
                                <div className="space30"></div>
                                {this.renderRolesAndPlants()}
                                <div className="space30"></div>
                                <SettingsNotifications />
                                <div className="space30"></div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default Settings;
