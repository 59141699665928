// @flow

// $FlowFixMe
import React, { useState, useEffect } from 'react';
import Toggle from "react-toggle";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { translate } from "../IntlProviderWrapper";
import ErrorComponent from "../ErrorComponent";

import { getBackend } from "../../lib/backend/Backend2";
import * as u from "../../lib/Util";
import * as t from "../../lib/backend/manufacturing2.generated.types";
import * as t2 from "../../lib/backend/reports.generated.types";

/** Assignments for single line */
export type LineAssignments = {
    line_uuid: string,
    persons: t.IShiftPersonLineRecPerson[]
};

type GetUniquePersonNameFunction = (string) => string;

/** Result of this modal page */
export type PoolEditModalType = {
    /** Flag if user wants information to be persisted */
    hasConfirmed: boolean,
    /** New assignments */
    assignments: LineAssignments[],
    /** Shift index for which these assignments are to be updated */
    shift_idx: number,
    /** New hash returned after databse save */
    hash: string
};

/** Callback type when modal closes */
export type OnClosePoolEditModalType = (PoolEditModalType) => void;

type Props = {
    show: boolean,
    onClose: OnClosePoolEditModalType,
    pool_line: t.IShiftPersonLineRec,
    lines: t.IShiftPersonLineRec[],
    lines_pooled: t.IShiftPersonLineRec[],
    shift_idx: number,
    week: number,
    year: number,
    hash: string,
    uniquePersonName: GetUniquePersonNameFunction,
}

type State = {
    selected_person: t.IShiftPersonLineRecPerson,
    assignments: LineAssignments[],
    weekly_rep: t2.IWeeklyRealizationReport[],
    worker_hours: Map<string, number>,
    assigned_workers: Set<string>,
    show_disabled_shifts: boolean,
    errorMsg: ?string
}

const initialState: State = {
    selected_person: {name: "", uuid: ""},
    assignments: [],
    weekly_rep: [],
    worker_hours: new Map(),
    assigned_workers: new Set(),
    show_disabled_shifts: false,
    errorMsg: null
}

const ShiftAssignmentPoolEdit = (props: Props) => {

    const [state, setState] = useState(initialState);

    useEffect(() => {
        state.errorMsg = "";
        state.assignments = props.lines.map(line => {
            let persons = [];
            if (line.shifts[props.shift_idx] !== undefined) {
                persons = [];
                line.shifts[props.shift_idx].persons.forEach(x => {
                    persons.push(x);
                });
            }
            return { line_uuid: line.line_uuid, persons };
        });
        setState({ ...state });
        getWorkerHours();
        getAssignedWorkers();
        loadWorkOrders();
    }, [props]);

    const loadWorkOrders = async () => {
        if (props.show) {
            try {
                const res = await getBackend().reports.searchReports({ num_reports: 1 });
                if (res.reports && res.reports.length > 0) {
                    const report_uuid = res.reports[0].uuid;
                    const report = await getBackend().reports.getReportWeeklyRealization({
                        id: report_uuid,
                        line_uuids: props.lines_pooled.map(x => x.line_uuid).join(","),
                        order_breakdown: true,
                        shift_number: props.shift_idx,
                        week: props.week
                    });
                    state.weekly_rep = report.report.result;
                    setState({ ...state });
                }
            } catch (err) {
                setState({ ...state, errorMsg: err.message });
            }
        }
    }

    const getAssignedWorkers = () => {
        if (props.show) {
            try {
                state.assigned_workers.clear();
                // get array of only lines in pool
                const pooled_lines = props.lines_pooled.map(l => l.line_uuid);
                // filter assigments to only pooled lines
                const pooled_lines_assignments = state.assignments.filter(l => pooled_lines.includes(l.line_uuid));
                // unique assigned worker ids
                pooled_lines_assignments.forEach((line) => {
                    line.persons.forEach((person) => {
                        state.assigned_workers.add(person.uuid);
                    })
                })
                setState({ ...state });
            } catch (e) {
                setState({ ...state, errorMsg: e.message });
            }
        }
    }

    const getWorkerHours = () => {
        if (props.show) {
            try {
                state.worker_hours.clear();
                props.lines_pooled.forEach((line, i) => {
                    const shift = line.shifts[props.shift_idx];
                    const persons = state.assignments.find(x => x.line_uuid === line.line_uuid).persons;
                    persons.forEach(person => {
                        const prev_val = state.worker_hours.has(person.uuid) ? state.worker_hours.get(person.uuid) : 0.0;
                        const curr_val = shift.worker_hours / persons.length; // If more persons, distribute work evenly
                        state.worker_hours.set(person.uuid, prev_val + curr_val);
                    });
                });
                setState({ ...state });
            } catch (e) {
                setState({ ...state, errorMsg: e.message });
            }
        }
    }

    const getWorkOrdersForLine = (line_uuid: string) => {
        const orders = state.weekly_rep.filter(x => x.line_uuid === line_uuid);
        return orders.map(order => <div key={"order-" + order.order_uuid}>
            <span className={"badge badge-pill badge-secondary"}
                title={translate("ShiftAssignmentPoolEdit.order", "Order")}>
                {order.order_external_id}
            </span> <span className={"badge badge-pill badge-info"}
                title={translate("ShiftAssignmentPoolEdit.material", "Material")}>
                {order.material_external_id}
            </span> {order.material_title}
        </div>);
    }

    const onConfirm = async () => {
        try {
            props.onClose({
                hasConfirmed: true,
                assignments: state.assignments,
                shift_idx: props.shift_idx,
                hash: props.hash
            });
        } catch (e) {
            setState({ ...state, errorMsg: e.message });
        }
    };

    const onCancel = () => {
        props.onClose({ hasConfirmed: false, assignments: [], shift_idx: -1, hash: "" });
    };

    const assignOrRemoveCurrent = (line_uuid: string, state: State) => {
        if (state.selected_person.uuid === "") {
            return;
        }
        const lines = state.assignments.filter(x => x.line_uuid === line_uuid);
        if (lines.length > 0) {
            const line = lines[0];
            if (line.persons.some(x => x.uuid === state.selected_person.uuid)) {
                line.persons = line.persons.filter(x => x.uuid !== state.selected_person.uuid);
            } else {
                line.persons.push(state.selected_person);
            }
            setState({ ...state });
            getWorkerHours();
            getAssignedWorkers();
        }
    };

    let bullet_key_counter = 0;
    const createBullet = () => {
        const tooltip = translate("ShiftAssignmentPoolEdit.icon_text.too_many_hours", "Too many working hours")
        const key = "bullet" + bullet_key_counter++;
        return <span key={key} className={"badge badge-pill badge-danger pool-worker-alert"} title={tooltip}>{"!"}</span>
    }

    const alertWorkerHours = (person: string) => {
        const worker_hours_thr = 8; // person assigned to more than 8 hous in one shift
        if (state.worker_hours.get(person) > worker_hours_thr) {
            return createBullet();
        }
    }

    const renderLines = () => {
        if (!props.show) { return null; }
        const lines = props.lines_pooled;
        return <table className="table table-hover table-sm">
            <thead className="thead-light">
                <tr>
                    <th>{translate("ShiftAssignmentPoolEdit.persons", "Persons")}</th>
                    <th>{translate("ShiftAssignmentPoolEdit.line", "Line")}</th>
                    <th>{translate("ShiftAssignmentPoolEdit.work_hours", "Work-hours")}</th>
                    <th>{translate("ShiftAssignmentPoolEdit.work_orders", "Work-order(s)")}</th>
                    <th>{translate("ShiftAssignmentPoolEdit.tool_change", "Tool-change")}</th>
                </tr>
            </thead>
            <tbody>
                {lines.map((line, i) => {
                    const shift = line.shifts[props.shift_idx];
                    if (!state.show_disabled_shifts && !shift.enabled) {
                        return null;
                    }
                    const css = shift.enabled ? "" : "pool-edit-row-disabled";
                    return <tr key={"" + i} className={css} onClick={() => { assignOrRemoveCurrent(line.line_uuid, state) }}>
                        <td>
                            {state.assignments.filter(x => x.line_uuid === line.line_uuid)[0].persons.map(person => {
                                let css = "pool-edit-person-cell";
                                if (person.uuid === state.selected_person.uuid) {
                                    css += " pool-edit-person-selected";
                                }
                                if (!props.pool_line.shifts[props.shift_idx].persons.some(p => p.uuid === person.uuid)) {
                                    css += " pool-edit-person-not-in-pool"
                                }
                                return (
                                    <div className={css} key={person.uuid}>
                                        <span>{person.name}</span>
                                        {alertWorkerHours(person.uuid)}
                                    </div>
                                );
                            })}
                        </td>
                        <td> {line.line_title} </td>
                        <td>{shift.worker_hours && u.niceNumber(shift.worker_hours, 1)}</td>
                        <td>{getWorkOrdersForLine(line.line_uuid)}</td>
                        <td>
                            {shift.tool_changes
                                .map(i => <div>
                                    <span className="badge badge-pill badge-info" > {i.material_ext_id}</span> {i.title}
                                </div>)
                            }
                        </td>
                    </tr>
                })}
            </tbody>
        </table>;
    }

    const renderPeople = () => {
        if (!props.show) { return null; }
        const people = props.pool_line.shifts[props.shift_idx].persons;
        return <div>
            {people.map((person, i) => {
                let css = "pool-edit-person";
                if (person.uuid === state.selected_person.uuid) {
                    css += " pool-edit-person-selected";
                }
                if (!state.assigned_workers.has(person.uuid)) {
                    css += " pool-edit-person-not-assigned";
                }
                return <div
                    key={"" + i}
                    className={css}
                    onClick={() => {
                        if (state.selected_person.uuid === person.uuid) {
                            setState({ ...state, selected_person: {name: "", uuid: ""} });
                        } else {
                            setState({ ...state, selected_person: person });
                        }
                    }}>
                    {person.name}
                </div>
            })}
        </div>;
    }

    return (
        <Modal bsSize="large" className="batchop-archive-item-modal" show={props.show} onHide={onCancel}>
            <Modal.Header>
                <Modal.Title>
                    {translate("ShiftAssignmentPoolEdit.assignments", "Assignments")} - {translate("common.week", "week")} {props.week} {props.year}
                </Modal.Title>

                <button type="button" className="close" onClick={onCancel}>
                    <span>×</span>
                </button>
            </Modal.Header>
            <Modal.Body>
                <React.Fragment>
                    <ErrorComponent msg={state.errorMsg} type="error" />
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-3">
                                {renderPeople()}
                            </div>
                            <div className="col-sm-9">
                                {renderLines()}
                                <div className="row">
                                    <div className="col-sm-6">
                                        <span>
                                            <FormattedMessage id="ShiftAssignmentPoolEdit.show_lines" defaultMessage="Show lines with disabled shifts" />
                                        </span>
                                        <br />
                                        <Toggle name="toggle_show_c"
                                            checked={state.show_disabled_shifts}
                                            onChange={() => setState({ ...state, show_disabled_shifts: !state.show_disabled_shifts })} />
                                    </div>
                                    <div className="col-sm-6 button-list order-last text-right max-width-50perc">
                                        <dl className="property-list shift-legend">
                                            <dt>{translate("common.legend", "Legend")}:</dt>
                                            <dt>{createBullet()}</dt>
                                            <dd><FormattedMessage id="ShiftAssignmentPoolEdit.icon_text.too_many_hours" defaultMessage="Too many working hours" /></dd>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            </Modal.Body>
            <Modal.Footer>
                <button
                    onClick={onCancel}
                    className="btn"
                >
                    <FormattedMessage id="common.cancel" defaultMessage="Cancel" />
                </button>
                <button
                    onClick={onConfirm}
                    className="btn btn-primary"
                >
                    <FormattedMessage id="common.save" defaultMessage="Save" />
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default ShiftAssignmentPoolEdit;
