// @flow
import React from "react";
import { connect } from "react-redux";
import Tooltip from "../../../Tooltip";
import StockRequirementsRectsTitle from "./StockRequirementsRectsTitle";
import { GanttChartSource } from "../reducers/properties";
import { TIME_RANGES, getDateOfISOWeekShift, niceDateTime } from "../../../../lib/Util";
import { translate } from "../../../IntlProviderWrapper";
import { UserActions } from "../reducers/BusinessLogic";

import type { LineOrders } from "../reducers/linesOrders";
import type { ReduxState } from "../reducers/reducers";
import type { StockRequirement } from "../reducers/stockRequirements";
import type { PropertiesState } from "../reducers/properties";
import type { StockRequirementActions } from "../reducers/BusinessLogic";

type Props = {
    properties: PropertiesState,
    line_index: number,
    uuid: string,
    is_ctrl_down: boolean,
    is_selected: boolean,
    stock_requirement: StockRequirement,
    lines_orders: LineOrders[],
    has_insight: boolean,
    reduxDispatch: (args: StockRequirementActions) => void,
    show_stock_requirements: boolean
}
type State = {};

class StockRequirementsRect extends React.Component<Props, State> {

    calculateY0 = (line_counter: number, radius: number) => {
        return ((line_counter * radius * 2)) + radius;
    }

    getStockRequirementStartDate = (date_required: number, start_date_ts: number) => {
        const date = new Date(date_required);
        date.setHours(0);
        const start_date = (date.getTime() - start_date_ts) / TIME_RANGES.HOUR;
        if (start_date < 0) {
            return 0;
        }
        return start_date;
    }

    onClick = (stock_requirement: StockRequirement) => (e: Event) => {
        UserActions.onStockRequirementClick(
            stock_requirement.uuid,
            stock_requirement.material_external_id,
            this.props.lines_orders,
            this.props.reduxDispatch,
            this.props.is_selected
        );
    }

    getTooltipContent = (stock_requirement: StockRequirement) => {
        return <div>
            <span>{stock_requirement.material_title}</span><br /><br />
            <span style={{ fontWeight: "450" }}>{translate("common.transaction_external_id", "Transaction ID")}</span>: <span>{stock_requirement.transaction_external_id}</span><br />
            <span style={{ fontWeight: "450" }}>{translate("Manufacturing.StocksView.date_expected", "Date expected")}</span>: <span>{niceDateTime(new Date(stock_requirement.date_expected))}</span><br />
            <span style={{ fontWeight: "450" }}>{translate("Manufacturing.StocksView.date_required", "Date required")}</span>: <span>{niceDateTime(new Date(stock_requirement.date_required))}</span><br />
            <span style={{ fontWeight: "450" }}>{translate("Manufacturing.StocksView.quantity", "Quantity")}</span>: <span>{Math.abs(stock_requirement.quantity)}</span>
        </div>
    }

    render() {
        const xscale = this.props.properties.xscale;
        const shift_width = this.props.properties.shift_width;
        const bar_height = this.props.properties.bar_height * 0.5;
        const current_shift = this.props.properties.current_shift;
        const week = this.props.properties.week;
        const year = this.props.properties.year;
        const bars_start = this.props.properties.bars_start;
        const space_between_bars = this.props.properties.space_between_bars / 2;

        if (!week || !year || !shift_width) {
            return null;
        }

        const start_date_ts = getDateOfISOWeekShift(current_shift, week, year);

        if (!xscale) {
            return null;
        }
        const sr = this.props.stock_requirement;
        const start_x = xscale(this.getStockRequirementStartDate(sr.shift_start_time, start_date_ts));
        const rx = 7;
        const x = bars_start + start_x;
        const y = bar_height * this.props.line_index + (this.props.line_index * space_between_bars);
        const stroke_width = this.props.is_selected ? "3" : "1";

        return <Tooltip content={this.getTooltipContent(this.props.stock_requirement)}>
            <g key={sr.uuid} name="stock-requirements-rect" data-uuid={sr.uuid} onClick={this.onClick(sr)}>
                <rect
                    x={x}
                    y={y}
                    height={bar_height}
                    width={shift_width * 3}
                    rx={rx}
                    shift_width={shift_width}
                    bar_height={bar_height}
                    material_title={sr.material_title}
                    material_external_id={sr.material_external_id}
                    is_ctrl_down={this.props.is_ctrl_down}
                    fill={this.props.has_insight ? "url(#stock-requirements-gradient-insight)" : "url(#stock-requirements-gradient)"}
                    stroke={"black"}
                    style={{ strokeWidth: stroke_width, stroke: "#606E7C", cursor: "pointer" }}
                />
                <StockRequirementsRectsTitle
                    x={x + 5}
                    y={y + (bar_height / 2) + 5}
                    source_type={GanttChartSource.stock_requirements}
                    width={shift_width * 3}
                    is_ctrl_down={this.props.is_ctrl_down}
                    material_external_id={sr.material_external_id}
                    material_title={sr.material_title}
                    tooltip_text={this.getTooltipContent(this.props.stock_requirement)}
                    show_stock_requirements={this.props.show_stock_requirements}
                />
            </g>
        </Tooltip>
    }
}

export default connect(
    (state: ReduxState, own_props: Props) => {
        return {
            show_stock_requirements: state.gantt_chart_planning_table.show_stock_requirements,
            is_selected: state.gantt_chart_stock_requirements_filter.selected_stock_requirement === own_props.uuid,
            lines_orders: state.gantt_chart_lines_orders.lines_orders
        }
    },
    (dispatch) => ({ reduxDispatch: dispatch })
)(StockRequirementsRect)
