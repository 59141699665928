// @flow
import * as React from "react";

import ReactRouterPropTypes from "react-router-prop-types";

import * as BusinessLogic from "../../lib/BusinessLogic";
import { NavigationBarWithDropdown } from "../NavigationBar";
import { HOME_VIEW_TYPES, redirectSelectedView } from "../ViewsCommon";
import PlantStatistics from "./PlantStatistics";

const VIEW_TYPES = HOME_VIEW_TYPES;

type HomeViewParams = {
    id?: string,
    type: string,
    title?: string,
    title_code?: string,
    dashboard?: string
};

type Props = {
    history: ReactRouterPropTypes.history,
    match: ReactRouterPropTypes.match,
    location: ReactRouterPropTypes.location,
    plant_uuid: string,
    line_group_uuid: string
};

type State = {
    views: HomeViewParams[],
    selected_view: string
};

class HomeView extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            views: [],
            selected_view: VIEW_TYPES.overview.id
        };
    }

    async componentDidMount() {
        await BusinessLogic.waitForManufacturingAsync();
        this.loadViews();
    }

    async componentDidUpdate(prev_props: Props) {
        if (
            prev_props.plant_uuid !== this.props.plant_uuid ||
            prev_props.line_group_uuid !== this.props.line_group_uuid ||
            (this.props.match.params.selected_view &&
                this.props.match.params.selected_view !== this.state.selected_view)
        ) {
            await BusinessLogic.waitForManufacturingAsync();
            this.loadViews();
        }
    }

    async loadViews() {
        let line_group = BusinessLogic.getLineGroupForUser(this.props.line_group_uuid);
        if (line_group === null) {
            return;
        }

        // create views
        const views: HomeViewParams[] = [
            {
                id: VIEW_TYPES.overview.id,
                type: VIEW_TYPES.overview.id,
                title: VIEW_TYPES.overview.title,
                title_code: VIEW_TYPES.overview.title_code
            }
        ];

        // assing appropriate view
        const url_view = this.props.match.params.selected_view;
        let selected_view = "";
        if (url_view) {
            // we have view selected by the URL, check if even possible
            if (views.find(view => view.id === url_view)) {
                // all is good, mark it selected
                selected_view = url_view;
            } else {
                // url_view no longer available, select the top one
                selected_view = views.length > 0 && views[0].id ? views[0].id : "";
            }
        } else {
            // no view indicated in url, select the top one
            selected_view = views.length > 0 && views[0].id ? views[0].id : "";
        }
        this.setState({
            views,
            selected_view
        });
        this.props.history.push(`/manufacturing/home/${selected_view}`);
    }

    handleViewChange = (event: Event) => {
        if (
            event.currentTarget instanceof HTMLInputElement ||
            event.currentTarget instanceof HTMLSelectElement ||
            event.currentTarget instanceof HTMLButtonElement
        ) {
            // read new value
            const selected_view = event.currentTarget.value;
            // find new id
            this.setState({ selected_view });
            redirectSelectedView(selected_view, this.props.history);
        }
    };

    renderContent(view: HomeViewParams) {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <PlantStatistics
                            plant_uuid={this.props.plant_uuid}
                            line_group_uuid={this.props.line_group_uuid} />
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const view = this.state.views.find(view => view.id === this.state.selected_view);
        return (
            <React.Fragment>
                <NavigationBarWithDropdown
                    handleViewChange={this.handleViewChange}
                    key="A"
                    nav="views"
                    nav_id="home"
                    location={this.props.location}
                    line_group_uuid={this.props.line_group_uuid}
                    plant_uuid={this.props.plant_uuid}
                />
                <div className="views-content hide-filter" key="C">
                    {view && this.renderContent(view)}
                </div>
            </React.Fragment>
        );
    }
}

export default HomeView;
