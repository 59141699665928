// @flow
import * as React from "react";
import ReactTable, { type ReactTableColumn } from "../react/ReactTable";
import { connect } from "react-redux";
import { translate } from "../IntlProviderWrapper";
import TicketStatus from "./TicketStatus";
import { ActionCell } from "./ActionCell";
import { SET_INBOX_ITEM_VIEW } from "./reducers/ticketing";
import TicketDetailsModal from "./TicketDetailsModal";
import { getBackend as getBackend2 } from "../../lib/backend/Backend2";
import * as t from "../../lib/backend/ticketing.generated.types";
import { niceDateTime } from "../../lib/Util";

import { reducers } from "./reducers/reducers";
import type { SelectInboxItemView } from "./reducers/ticketing";
import type { ReduxState } from "./reducers/reducers";
import { combineReducers, createStore } from 'redux';

type Props = {
    include_closed: boolean,
    selected_workflow: string | null,
    reduxDispatch: (args: SelectInboxItemView) => void,
    tickets: t.ITicketShortInfo[]
};
type State = {
    show_ticket_details_uuid: string | null,
    show_ticket_edit_uuid: string | null,
    loading: boolean
};

type TicketRow = {
    uuid: string,
    title: string,
    workflow_title: string,
    workflow_name: string,
    workflow_version: string,
    status_title: string,
    status_color?: string,
    step: string,
    status_bgcolor?: string,
    assigned_on: number,
    assigned_to: string,
    due_date?: number,
    is_closed: boolean,
    is_error: boolean
}

export type CellArgs = {
    cell: any
}

const StatusCell = ({ cell }: CellArgs): React.Node => {
    return <TicketStatus
        status={cell.value}
        is_closed={cell.row.original.is_closed}
        is_error={cell.row.original.is_error}
        color={cell.row.original.status_color}
        bgcolor={cell.row.original.status_bgcolor}
    />;
}

const DateCell = ({ cell }: CellArgs): React.Node => {
    return <span>{niceDateTime(new Date(cell.value))}</span>;
}

const getColumns = (onClickDetails, onClickEdit): ReactTableColumn<$Shape<TicketRow>>[] => {
    return [
        {
            "Header": "",
            "filterInputPlaceholder": translate("common.title", "Title"),
            "accessor": "title",
        },
        {
            "Header": translate("Ticketing.assignee", "Assignee"),
            "accessor": "assigned_to",
            disableFilters: true
        },
        {
            "Header": translate("Ticketing.assigned_on", "Assigned on"),
            "accessor": "assigned_on",
            disableFilters: true,
            Cell: DateCell
        },
        {
            "Header": translate("Ticketing.workflow", "Workflow"),
            "accessor": "workflow_title",
            disableFilters: true
        },
        {
            "Header": "Workflow name",
            "accessor": "workflow_name",
            disableFilters: true
        },
        {
            "Header": translate("common.version", "Version"),
            "accessor": "workflow_version",
            disableFilters: true
        },
        {
            "Header": translate("common.status", "Status"),
            "accessor": "status_title",
            disableFilters: true,
            Cell: StatusCell
        },
        {
            "Header": "",
            "accessor": "uuid",
            disableFilters: true,
            Cell: ActionCell(onClickDetails, onClickEdit)
        }
    ];
}


class TicketList extends React.Component<Props, State> {
    store = null;

    constructor(props: any) {
        super(props);
        this.store = createStore(combineReducers(reducers));
    }

    state: State = {
        show_ticket_details_uuid: null,
        show_ticket_edit_uuid: null,
        loading: true
    }

    componentDidMount() {
        this.load();
    }
    componentDidUpdate(prev_props: Props) {
        if (prev_props.selected_workflow != this.props.selected_workflow) {
            this.load();
        }
    }

    async load() {
        const selected_workflow = this.props.selected_workflow;
        if (selected_workflow == null) {
            this.setState({ loading: false });
            return;
        }
        const res = await getBackend2().ticketing.searchTickets({
            include_closed: this.props.include_closed,
            include_errored: true,
            workflow_name: selected_workflow
        });
        const tickets = res.hits.sort((a, b) => a.assigned_on - b.assigned_on);

        if (this.store !== null) {
            this.store.getState().ticketing.tickets = tickets;
        }
        this.setState({ loading: false });
    }

    onClickDetails = (ticket_uuid: string) => {
        this.setState({ show_ticket_details_uuid: ticket_uuid });
    }

    onCloseDetails = () => {
        this.setState({ show_ticket_details_uuid: null });
    }

    onClickEdit = (ticket_uuid: string) => {
        this.props.reduxDispatch({ type: SET_INBOX_ITEM_VIEW, data: ticket_uuid });
    }

    onCellClick = (cell: any) => {
        const uuid = cell.row.values.uuid;
        this.setState({ show_ticket_details_uuid: uuid });
    }

    onRequestRefresh = async () => {
        await this.load();
    }

    render() {
        return <React.Fragment>
            <div className="main-body">
                <ReactTable
                    isLoading={this.state.loading}
                    small_loader={true}
                    columns={getColumns(this.onClickDetails, this.onClickEdit)}
                    data={this.props.tickets || []}
                    onCellClick={this.onCellClick}
                    hiddenColumns={["workflow_name", "workflow_version"]}
                />
                <TicketDetailsModal
                    show_ticket_details_uuid={this.state.show_ticket_details_uuid}
                    onClose={this.onCloseDetails}
                    requestRefreshCallback={this.onRequestRefresh}
                />
            </div>
        </React.Fragment>
    }
}

export default connect(
    (state: ReduxState) => {
        const selected_workflow = state.ticketing.selected_workflow;
        const tickets = state.ticketing.tickets;
        const include_closed = state.ticketing.include_closed;

        return { include_closed, selected_workflow, tickets }
    },
    (dispatch) => ({ reduxDispatch: dispatch })
)(TicketList);
