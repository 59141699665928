// @flow
import * as React from "react";
import type { PayloadAction } from "../../../redux/types";

export const SET_STOCK_FORECAST_TOOLTIP: "set_stock_forecast_tooltip" = "set_stock_forecast_tooltip";

export type SetStockForecastTooltip = PayloadAction<typeof SET_STOCK_FORECAST_TOOLTIP, $Shape<StockForecastTooltipState>>;

export type MaterialForecastCellData = {
    idx: number,
    material_external_id: string,
    summation: number,
    prev_summation: number,
    summation_change: number,
    hide_arrows: boolean,
    is_last_shift: boolean,
    selected_subtype: string,
    selected_aggregate: string,
    selected_display_type: string,
    only_accounted_for_initial_stock: boolean,
    selected_location: string,
    shift_date: number
}


export type StockForecastTooltipState = {
    show_tooltip: boolean | null,
    cell_data: MaterialForecastCellData | null,
    getForecast: null,
    prev_value: number | null,
    target_current: React.Node | null,
    report_id?: string
}

const stock_forecast_tooltip_initial: StockForecastTooltipState = {
    show_tooltip: false,
    cell_data: null,
    getForecast: null,
    prev_value: null,
    target_current: null
}

export type StockForecastTooltipActions = SetStockForecastTooltip;

export const stockForecastTooltipReducer = (
    state: StockForecastTooltipState = stock_forecast_tooltip_initial,
    action: StockForecastTooltipActions
): StockForecastTooltipState => {
    if (action.type === SET_STOCK_FORECAST_TOOLTIP) {
        return {...state, ...action.data};
    }

    return state;
}
