// @flow
import * as React from "react";
import ReactRouterPropTypes from "react-router-prop-types";
import { FormattedMessage } from "react-intl";
import { translate } from "../IntlProviderWrapper";

import * as BusinessLogic from "../../lib/BusinessLogic";
import { MSG_TYPES, publish } from "../../lib/PubSub";
import {
    LINE_GROUP_TAGS, PLANNING_MODE, INSIGHT_TYPES
} from "../../lib/ManufacturingTags.generated";
import { weekNumber, getDateOfISOWeek, dayStrings, TIME_RANGES /*, SHIFT_CONSTS*/ } from "../../lib/Util";

import {NavigationBarWithDropdown} from "../NavigationBar";

import ShiftAssignments from "./ShiftAssignments";
import { initShiftFilterProps } from "./ShiftAssignments";
import type { ShiftsFilterProps } from "./ShiftAssignments";
import WorkerAssignments from "./WorkerAssignments";
import InsightsView from "./InsightsView";
import DateTimePicker from "../DateTimePicker";
import Authorization from "../Authorization";
import * as Auth from "../../lib/Auth";
import { PEOPLE_VIEW_TYPES, redirectSelectedView } from "../ViewsCommon";

const VIEW_TYPES = PEOPLE_VIEW_TYPES;

type PeopleViewParams = {
    id?: string,
    type: string,
    title?: string,
    title_code?: string,
    dashboard?: string
};

type Props = {
    history: ReactRouterPropTypes.history,
    match: ReactRouterPropTypes.match,
    location: ReactRouterPropTypes.location,
    plant_uuid: string,
    line_group_uuid: string
};

type State = {
    views: PeopleViewParams[],
    selected_view: string,
    // filters for individual views
    shifts_filter: ShiftsFilterProps,
    shift_number: number,
    shift_date: Date
};

class PeopleView extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            views: [],
            selected_view: VIEW_TYPES.shifts.id,
            shifts_filter: initShiftFilterProps(),
            shift_number: 0,
            shift_date: new Date()
        }
    }

    async componentDidMount() {
        await BusinessLogic.waitForManufacturingAsync();
        this.loadViews();
    }

    async componentDidUpdate(prev_props: Props) {
        if (prev_props.plant_uuid !== this.props.plant_uuid ||
            prev_props.line_group_uuid !== this.props.line_group_uuid ||
            (this.props.match.params.view_params && (prev_props.match.params.view_params !== this.props.match.params.view_params)) ||
            (this.props.match.params.selected_view && this.props.match.params.selected_view !== this.state.selected_view)) {

            await BusinessLogic.waitForManufacturingAsync();
            this.loadViews();
        }
    }

    loadViews() {
        // get list of lines from the plant / line_group
        let line_group = BusinessLogic.getLineGroupForUser(this.props.line_group_uuid);
        if (line_group === null) { return; }

        const is_people_first_planning = (line_group.tags[LINE_GROUP_TAGS.planning_mode] === PLANNING_MODE.people_first);

        // create views
        const views = [];
        if (line_group.tags && line_group.tags[LINE_GROUP_TAGS.shift_board] === "true") {
            views.push({
                id: VIEW_TYPES.shifts.id,
                type: VIEW_TYPES.shifts.id,
                title: VIEW_TYPES.shifts.title,
                title_code: VIEW_TYPES.shifts.title_code
            });
            if (!is_people_first_planning) {
                views.push({
                    id: VIEW_TYPES.people_insights.id,
                    type: VIEW_TYPES.people_insights.id,
                    title: VIEW_TYPES.people_insights.title,
                    title_code: VIEW_TYPES.people_insights.title_code
                });
            }
            if (is_people_first_planning) {
                views.push({
                    id: VIEW_TYPES.worker_assignments.id,
                    type: VIEW_TYPES.worker_assignments.id,
                    title: VIEW_TYPES.worker_assignments.title,
                    title_code: VIEW_TYPES.worker_assignments.title_code
                });
            }
        }

        // assing appropriate view
        const url_view = this.props.match.params.selected_view;
        let selected_view = "";
        if (url_view) {
            // we have view selected by the URL, check if even possible
            if (views.find(view => view.id === url_view)) {
                // all is good, mark it selected
                selected_view = url_view;
            } else {
                // url_view no longer available, select the top one
                selected_view = (views.length > 0 && views[0].id) ? views[0].id : "";
            }
        } else {
            // no view indicated in url, select the top one
            selected_view = (views.length > 0 && views[0].id) ? views[0].id : "";
        }
        this.setState({ views, selected_view });
        if (views.length > 0 && this.props.match.params.view_params) {
            const view_params = this.props.match.params.view_params;
            this.props.history.push(`/manufacturing/people/${selected_view}/${view_params}`);
        } else {
            this.props.history.push(`/manufacturing/people/${selected_view}`);
        }
    }

    handleViewChange = (event: Event) => {
        if (event.currentTarget instanceof HTMLInputElement ||
            event.currentTarget instanceof HTMLSelectElement || 
            event.currentTarget instanceof HTMLButtonElement) {
            // read new value
            const selected_view = event.currentTarget.value;
            // find new id
            this.setState({ selected_view });
            redirectSelectedView(selected_view, this.props.history);
        }
    }

    /////////////////////////////////////////
    // Shifts
    handleShiftWeekClick(val: number) {
        // handle year flip
        let dt = getDateOfISOWeek(this.state.shifts_filter.week, this.state.shifts_filter.year);
        dt.setDate(dt.getDate() + val * 7);
        let week = weekNumber(dt);
        let year = dt.getFullYear();
        let month = dt.getMonth();
        // fixing problem, when week starts before first day of the year
        if ((month === 11) && (week === 1)) { year = year + 1; }
        // update state
        const shifts_filter: ShiftsFilterProps = {
            year, week
        };
        this.setState({ shifts_filter });
    }

    handleShiftCopyPreviousWeek = () => {
        this.refs["ShiftAssignments"].handleCopyPreviousWeek();
    }

    handleShiftPrintWeekend = () => {
        this.refs["ShiftAssignments"].exportToHtmlWeekend();
    }

    handleShiftPrintWeek = (include_prev_sunday: boolean) => {
        this.refs["ShiftAssignments"].handleShiftPrintWeek(include_prev_sunday);
    }

    handleShiftPrintDay = (day_of_week: number) => {
        this.refs["ShiftAssignments"].handleShiftPrintDay(day_of_week);
    }

    handleShiftSaveChanges = () => {
        this.refs["ShiftAssignments"].handleSaveChanges();
    }

    renderShiftFilter() {
        // prepare days of week
        const monday = getDateOfISOWeek(this.state.shifts_filter.week, this.state.shifts_filter.year);
        const days_of_week = dayStrings().map((day, i) => {
            const date = new Date(monday.getTime() + i * TIME_RANGES.DAY);
            return `${day} - ${date.getDate()}. ${date.getMonth() + 1}.`;
        });

        // compose filters
        return <li className="list-inline-item">
            <label><FormattedMessage id="common.week" defaultMessage="Week" /></label>
            <div className="form-group">
                <span className="week-picker">
                    <button className="btn btn-light btn-short" onClick={() => this.handleShiftWeekClick(-1)}>&lt;</button>
                    <span>
                        {this.state.shifts_filter.week} / {this.state.shifts_filter.year}
                    </span>
                    <button className="btn btn-light btn-short" onClick={() => this.handleShiftWeekClick(+1)}>&gt;</button>
                </span>
            </div>
            <div className="form-group">
                <button type="button" className="btn btn-outline-primary" onClick={this.handleShiftCopyPreviousWeek}>
                    <FormattedMessage id="ShiftDetails.copy_prev_week" defaultMessage="Copy previous week" />
                </button>
            </div>
            <div className="form-group">
                <div className="btn-group">
                    <button type="button" className="btn btn-outline-primary dropdown-toggle"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <FormattedMessage id="common.print" defaultMessage="Print" />
                    </button>
                    <div className="dropdown-menu">
                        <button className="dropdown-item" onClick={() => this.handleShiftPrintWeek(false)}>
                            <FormattedMessage id="ShiftDetails.print_without_sunday" defaultMessage="Whole week (Mon-Sun)" />
                        </button>
                        <button className="dropdown-item" onClick={() => this.handleShiftPrintWeek(true)}>
                            <FormattedMessage id="ShiftDetails.print_with_sunday" defaultMessage="Whole week (Sun-Sun)" />
                        </button>
                        <div className="dropdown-divider"></div>
                        <button className="dropdown-item" onClick={this.handleShiftPrintWeekend}>
                            <FormattedMessage id="ShiftDetails.print_weekend" defaultMessage="Weekend (Fri,Sat,Sun)" />
                        </button>
                        <div className="dropdown-divider"></div>
                        {days_of_week.map((day, day_of_week) =>
                            <button key={day_of_week} className="dropdown-item" onClick={() => this.handleShiftPrintDay(day_of_week)}>
                                {day}
                            </button>
                        )}
                    </div>
                </div>
            </div>
            <div className="form-group">
                <Authorization.button
                    permission={Auth.PERMISSION_NAMES.PeopleEdit}
                    type="button"
                    className="btn btn-primary"
                    onClick={this.handleShiftSaveChanges}
                >
                    <FormattedMessage id="common.save" defaultMessage="Save" />
                </Authorization.button>
            </div>
        </li>;
    }

    /////////////////////////////////////////
    // People View

    onExportWorkerAssignment = () => {
        publish(MSG_TYPES.export_worker_assignments_for_print, {});
    }

    onChangeShiftNumber = (e: Event) => {
        if (e.currentTarget instanceof HTMLInputElement || e.currentTarget instanceof HTMLSelectElement) {
            this.setState({ shift_number: parseInt(e.currentTarget.value, 10) });
        }
    }

    onChangeShiftDate = (value: Date) => {
        this.setState({ shift_date: value });
    }

    renderWorkerAssignmentFilter = () => {
        return <li className="list-inline-item">
            <div className="form-group" style={{ display: "flex" }}>
                {/* <div style={{ width: "50px", marginRight: "5px" }}>
                    <select
                        className="form-control"
                        value={this.state.shift_number}
                        onChange={this.onChangeShiftNumber}
                        style={{ height: "40px" }}
                    >
                        {SHIFT_CONSTS.SELECT_OPTIONS.map(s => <option value={s}>{s + 1}</option>)}
                    </select>
                </div> */}

                <div style={{ marginRight: "5px" }}>
                    <DateTimePicker
                        inputId={`shift-date-filter}`}
                        name="shift_date"
                        value={this.state.shift_date}
                        pickerType="date"
                        popupPostion="fixed"
                        onChange={this.onChangeShiftDate}
                    />
                </div>
                <button className="btn btn-primary" style={{ float: "right" }} onClick={this.onExportWorkerAssignment}>
                    <span>{translate("ShiftDetails.export_for_print", "Export for print")}</span>
                </button>
            </div>
        </li>;
    }

    renderFilter(view: PeopleViewParams) {
        if (view) {
            if (view.type === VIEW_TYPES.shifts.id) {
                return this.renderShiftFilter();
            } else if (view.type === VIEW_TYPES.worker_assignments.id) {
                return this.renderWorkerAssignmentFilter();
            }
        }
        // nothing valid selected
        return <li className="list-inline-item"></li>;
    }

    renderContent(view: PeopleViewParams) {
        if (view) {
            if (view.type === VIEW_TYPES.shifts.id) {
                return <ShiftAssignments
                    selected_plant={this.props.plant_uuid}
                    selected_line_group={this.props.line_group_uuid}
                    week={this.state.shifts_filter.week}
                    year={this.state.shifts_filter.year}
                    history={this.props.history}
                    location={this.props.location}
                    match={this.props.match}
                    ref="ShiftAssignments"
                />;
            } else if (view.type === VIEW_TYPES.people_insights.id) {
                const insight_types = [
                    INSIGHT_TYPES.no_person,
                    INSIGHT_TYPES.no_shift
                ];
                return <InsightsView history={this.props.history} match={this.props.match}
                    plant_uuid={this.props.plant_uuid} line_group_uuid={this.props.line_group_uuid}
                    insight_types={insight_types} from_diff={-8 * TIME_RANGES.HOUR} />;
            } else if (view.type === VIEW_TYPES.worker_assignments.id) {
                return <WorkerAssignments
                    plant_uuid={this.props.plant_uuid}
                    line_group_uuid={this.props.line_group_uuid}
                    selected_shift_number={this.state.shift_number}
                    selected_shift_date={this.state.shift_date}
                />
            } else {
                return <div></div>;
            }
        }
        // nothing valid selected
        return <div></div>;
    }

    renderViewSelect() {
        return <select name="select_view" className="form-control select_top_control"
            value={this.state.selected_view} onChange={this.handleViewChange}>
            {
                this.state.views.map((view, i) => (view.title_code) ?
                    <option key={i} value={view.id}>{translate(view.title_code, view.title)}</option> :
                    <option key={i} value={view.id}>{view.title}</option>
                )
            }
        </select>;
    }

    renderEmpty() {
        const line_group = BusinessLogic.getLineGroupForUser(this.props.line_group_uuid);

        return line_group ?
            <strong>
                <FormattedMessage id="Manufacturing.PeopleView.no_views" defaultMessage="Managing people currently not supported for" /> "{line_group.title}"
            </strong> : null;
    }

    shouldHideFilter = (view?: PeopleViewParams): boolean => {
        if (!view) {
            return true;
        }

        const view_types = [
            VIEW_TYPES.shifts.id,
            VIEW_TYPES.worker_assignments.id
        ];

        return !view_types.includes(view.type);
    }

    renderFilterContainer = (view?: PeopleViewParams) => {
        const should_hide_filter = this.shouldHideFilter(view);
        if (should_hide_filter) {
            return null;
        }

        return <div className="views-filter-bar views_always_top" key="B">
            <div className="row">
                <div className="col-lg-12 col-md-12">
                    <ul className="list-inline">
                        <li className="list-inline-item">
                            {!view && this.renderEmpty()}
                        </li>
                        {view && this.renderFilter(view)}
                    </ul>
                </div>
            </div>
        </div>;
    }

    render() {
        const view = this.state.views.find((view) => (view.id === this.state.selected_view));
        const should_hide_filter = this.shouldHideFilter(view);
        const style = {
            paddingTop: view && view.type === VIEW_TYPES.shifts.id ? "90px" : ""
        };
        return [
            <NavigationBarWithDropdown
                handleViewChange={this.handleViewChange}
                key="A"
                nav="views"
                nav_id="people"
                location={this.props.location}
                line_group_uuid={this.props.line_group_uuid}
                plant_uuid={this.props.plant_uuid}
            />,
            this.renderFilterContainer(view),
            <div
                style={style}
                className={"views-content" + (should_hide_filter ? " hide-filter" : "")}
                key="C"
            >
                    {view && this.renderContent(view)}
            </div>
        ];
    }
}

export default PeopleView;
