// @flow

// load subcomponents
import { LineCreate } from "./Line.create";

// backend
import { getBackend } from "../../lib/backend/Backend2";

// type definitions
import { LINE_TAGS } from "../../lib/ManufacturingTags.generated";

/**
 * This objects extends line create. It only changes the way the data is loaded.
 */
class LineEdit extends LineCreate {

    /**
     * Loading the component (plants and lines data).
     */
    async componentDidMount() {
        try {
            const { plants } = await getBackend().manufacturing.getPlants({});

            this.setState({
                plants: plants,
                plant_uuid: plants[0].uuid
            });

            // loading data for the line
            const { uuid } = this.props.match.params;
            const { lines } = await getBackend().manufacturing.getLines({
                uuid: uuid
            });
            const data = lines[0];
            data.tags = data.tags || {};

            this.setState({
                bottleneck: (data.tags[LINE_TAGS.bottleneck] === "true"),
                external_id: data.external_id,
                uuid: data.uuid,
                title: data.title,
                plant_uuid: data.plant_uuid,
                tags: data.tags,
                type: data.tags[LINE_TAGS.type],
                skip_planning: (data.tags[LINE_TAGS.skip_planning] === "true"),
                skip_shift_board: (data.tags[LINE_TAGS.skip_shift_board] === "true"),
                skip_stock_analysis: (data.tags[LINE_TAGS.skip_stock_analysis] === "true"),
                weight: data.weight
            })
        } catch (e) {
            this.setState({ error: e });
        }
    }
}

export default LineEdit;