// @flow
import * as React from "react";
import { Modal } from "react-bootstrap";
import AsyncSelect from "react-select/async";
import { FormattedMessage } from "react-intl";

import { ORDER_STATUS, ORDER_TYPE, ORDER_GENERATED_BY } from "../../lib/ManufacturingConsts.generated";
import { ORDER_TAGS_ACCESS } from "../../lib/ManufacturingTags.generated";
import { USER_TAGS } from "../../lib/CommonTags.generated";
import { niceNumber, TIME_RANGES } from "../../lib/Util";
import { getBackend } from "../../lib/backend/Backend2";
import { translate } from "../IntlProviderWrapper";
import ErrorComponent from "../ErrorComponent";
import DateTimePicker from "../DateTimePicker";
import Loader from "../Loader";
import * as Auth from "../../lib/Auth";
import * as t from "../../lib/backend/manufacturing2.generated.types";

type Action = "add" | "edit";

export type FormData = {
    order: string,
    quantity: number,
    comment: string,
    time_start: number,
    time_end: number
};

type SelectOption = {
    label: string,
    value: string
};

type Props = {
    order: t.IOrderModelBase | null,
    show: boolean,
    line_uuids: string[],
    is_archive: boolean,
    automatic_import: boolean,
    onAccept: () => void,
    onCancel: () => void
};

type State = {
    orders: t.IOrderModelBase[] | null,
    order: t.IOrderModelBase | null,
    quantity: number,
    comment: string,
    time_start: number,
    time_end: number,
    required_deadline: number,
    error: string,
    action: Action,
    is_loading: boolean,
    is_submitting: boolean
};

export const getEndTime = (order: t.IOrderModelBase, time_start: number): number => {
    let duration = order.norm_tool_setup_time;
    if (order.norm_base_units !== 0) {
        duration = (order.quantity_total / order.norm_base_units) * order.norm_machine_time;
    }

    return time_start + duration * TIME_RANGES.HOUR;
};

const mapOrderOption = (order: t.IOrderModelBase): SelectOption => ({
    label: `${order.external_id} - ${order.process_num} - ${order.title}`,
    value: order.external_id
});

const sanitizeQuantityInput = (value: string) => {
    return Number(value) || 0;
};

const validateQuantity = (quantity: number, max: number) => {
    return quantity >= 0 && quantity <= max;
};

class ManualEntryOrderInputModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const state: State = {
            orders: null,
            order: null,
            quantity: 0,
            comment: "",
            time_start: 0,
            time_end: 0,
            required_deadline: 0,
            error: "",
            action: "add",
            is_loading: false,
            is_submitting: false,
            quantity_error: ""
        };
        this.state = state;
    }

    async componentDidUpdate(prev_props: Props) {
        const { order } = this.props;
        if (order !== null && (prev_props.order === null || prev_props.order.uuid !== order.uuid)) {
            let orders = this.state.orders;
            if (orders === null) {
                this.setState({ is_loading: true });
                orders = await this.loadOrders();
                this.setState({ is_loading: false });
            }

            const parent_order = orders.find(o => o.uuid === order.parent_order_uuid) || null;
            this.setState({
                orders,
                order: parent_order,
                quantity: order.quantity_total,
                comment: ORDER_TAGS_ACCESS.comment(order.tags),
                time_start: order.time_start,
                time_end: order.time_end,
                required_deadline: ORDER_TAGS_ACCESS.required_deadline(order.tags),
                action: "edit"
            });
        }
    }

    loadOrders = async () => {
        const { operations } = await getBackend().manufacturing.getOrdersSimple({
            line_uuids: this.props.line_uuids,
            order_types: [ORDER_TYPE.full],
            status: ORDER_STATUS.open
        });
        this.setState({ orders: operations });
        return operations;
    };

    getOrderOptions = async (input_value: string) => {
        const orders = this.state.orders || (await this.loadOrders());
        const filter = input_value.toLowerCase();
        return orders
            .filter(
                order =>
                    order.external_id.toLowerCase().indexOf(filter) > -1 ||
                    order.title.toLowerCase().indexOf(filter) > -1
            )
            .map(order => mapOrderOption(order));
    };

    getSelectedOrderOption = () => {
        const order = this.state.order;
        if (order) {
            return mapOrderOption(order);
        }

        return null;
    };

    validate = () => {
        const { order, quantity } = this.state;
        return order !== null && validateQuantity(quantity, order.quantity_total);
    };

    handleSubmit = async (event: SyntheticEvent<HTMLFormElement>) => {
        event.preventDefault();

        const { quantity, comment, time_start, time_end } = this.state;
        const order = this.props.order || this.state.order;
        if (order === null) {
            this.props.onAccept();
            return;
        }

        let required_deadline;
        if (this.state.required_deadline) {
            required_deadline = this.state.required_deadline;
        }

        this.setState({
            error: "",
            is_submitting: true
        });

        try {
            if (this.state.action === "add") {
                await getBackend().manufacturing.insertManualEntryOrder({
                    uuid: order.uuid,
                    generated_by: ORDER_GENERATED_BY.manual_entry_subcontractor,
                    quantity,
                    comment,
                    time_start,
                    time_end,
                    required_deadline,
                    edited_time_end: true,
                    edited_required_deadline: true
                });
            } else {
                if (this.props.is_archive) {
                    await getBackend().manufacturing.updateArchivedManualEntryOrder({
                        uuid: order.uuid,
                        quantity,
                        time_start,
                        time_end,
                        required_deadline,
                        edited_time_end: true,
                        edited_required_deadline: true
                    });
                } else {
                    await getBackend().manufacturing.updateManualEntryOrder({
                        uuid: order.uuid,
                        quantity,
                        comment,
                        time_start,
                        time_end,
                        required_deadline,
                        edited_time_end: true,
                        edited_required_deadline: true
                    });
                }
            }

            this.props.onAccept();
        } catch (error) {
            this.setState({ error: error.message });
        }

        this.setState({ is_submitting: false });
    };

    handleCommentChange = (event: SyntheticEvent<HTMLInputElement>) => {
        this.setState({
            comment: event.currentTarget.value,
            error: ""
        });
    };

    handleQuantityChange = (event: SyntheticEvent<HTMLInputElement>) => {
        const quantity = sanitizeQuantityInput(event.currentTarget.value);
        this.setState({
            quantity,
            error: ""
        });
    };

    handleOrderChange = (option: SelectOption) => {
        const order =
            (this.state.orders && this.state.orders.find(order => order.external_id === option.value)) || null;
        const time_start = new Date().getTime();
        const time_end = order ? getEndTime(order, time_start) : time_start;
        this.setState({
            order,
            quantity: order ? order.quantity_total : 0,
            time_start,
            time_end,
            required_deadline: time_end
        });
    };

    handleStartTimeChange = (date: Date) => {
        const time_start = date.getTime();
        this.setState({
            time_start,
            time_end: this.state.order ? getEndTime(this.state.order, time_start) : time_start
        });
    };

    handleEndTimeChange = (date: Date) => {
        this.setState({
            time_end: date.getTime()
        });
    };

    handleRequiredDeadlineChange = (date: Date) => {
        this.setState({
            required_deadline: date.getTime()
        });
    };

    handleExit = () => {
        this.setState({
            order: null,
            quantity: 0,
            comment: "",
            time_start: 0,
            time_end: 0,
            required_deadline: 0,
            error: "",
            action: "add"
        });
    };

    render() {
        const order = this.state.order;
        const is_add = this.state.action === "add";
        const quantity_error =
            order && !validateQuantity(this.state.quantity, order.quantity_total) ? (
                <FormattedMessage
                    id="common.value_must_be_between_min_and_max"
                    defaultMessage="Value must be between {min} and {max}."
                    values={{ min: 0, max: niceNumber(order.quantity_total, 0) }}
                />
            ) : null;

        const time_start = this.state.time_start ? new Date(this.state.time_start) : undefined;
        const time_end = this.state.time_end ? new Date(this.state.time_end) : undefined;
        const required_deadline = this.state.required_deadline ? new Date(this.state.required_deadline) : undefined;
        const is_kiosk_user = Auth.getUserTag(USER_TAGS.kiosk) === "true";
        const has_permission = Auth.hasPermission(Auth.PERMISSION_NAMES.ManualEntryOrderTimeEdit);
        return (
            <Modal show={this.props.show} onExited={this.handleExit}>
                <Modal.Header>
                    <Modal.Title>
                        {is_add ? translate("common.add", "Add") : translate("common.edit", "Edit")}
                    </Modal.Title>
                    <button type="button" className="close" onClick={this.props.onCancel}>
                        <span aria-hidden="true">×</span>
                        <span className="sr-only">{translate("common.close", "Close")}</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    {this.state.is_loading ? (
                        <Loader />
                    ) : (
                        <form id="manual-entry-line-input-form" onSubmit={this.handleSubmit}>
                            {is_add && (
                                <div className={`form-group${order ? "" : " mb-0"}`}>
                                    <label htmlFor="manual-entry-line-input-order">
                                        {translate("common.order", "Order")}
                                    </label>
                                    <AsyncSelect
                                        inputId="manual-entry-line-input-order"
                                        classNamePrefix="react-select"
                                        name="order"
                                        placeholder={`${translate("common.search", "Search")}...`}
                                        noOptionsMessage={input =>
                                            input.inputValue === ""
                                                ? translate("common.search", "Search")
                                                : translate("Manufacturing.Planning.no_orders", "No orders found.")
                                        }
                                        value={order && mapOrderOption(order)}
                                        loadOptions={this.getOrderOptions}
                                        autoFocus={true}
                                        onChange={this.handleOrderChange}
                                    />
                                </div>
                            )}
                            {order && (
                                <React.Fragment>
                                    <table className="table table-sm table-bordered">
                                        <tbody>
                                            <tr>
                                                <th>{translate("common.order", "Order")}</th>
                                                <td>{order.external_id}</td>
                                            </tr>
                                            <tr>
                                                <th>{translate("common.process_num", "Process number")}</th>
                                                <td>{order.process_num}</td>
                                            </tr>
                                            <tr>
                                                <th>{translate("common.operation", "Operation")}</th>
                                                <td>{order.title}</td>
                                            </tr>
                                            <tr>
                                                <th>{translate("common.material", "Material")}</th>
                                                <td>{order.material_title}</td>
                                            </tr>
                                            <tr>
                                                <th>{translate("common.line", "Line")}</th>
                                                <td>{order.line.title}</td>
                                            </tr>
                                            {/* <tr>
                                                <th>{translate("common.location", "Location")}</th>
                                                <td>UNKNOWN</td>
                                            </tr> */}
                                        </tbody>
                                    </table>
                                    <div className="form-group">
                                        <label htmlFor="manual-entry-line-input-quantity">
                                            {translate("common.quantity", "Quantity")}
                                        </label>
                                        <input
                                            id="manual-entry-line-input-quantity"
                                            className={`form-control ${quantity_error ? "is-invalid" : ""}`}
                                            type="number"
                                            name="quantity"
                                            min={0}
                                            max={order.quantity_total}
                                            value={`${this.state.quantity}`}
                                            autoFocus={!is_add}
                                            onChange={this.handleQuantityChange}
                                            disabled={is_kiosk_user || this.props.automatic_import}
                                        />
                                        {quantity_error && (
                                            <div className="invalid-feedback d-block">{quantity_error}</div>
                                        )}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="manual-entry-line-input-comment">
                                            {translate("common.comment", "Comment")}
                                        </label>
                                        <input
                                            id="manual-entry-line-input-comment"
                                            className="form-control"
                                            type="text"
                                            name="comment"
                                            value={this.state.comment}
                                            onChange={this.handleCommentChange}
                                            disabled={!(has_permission || is_kiosk_user)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="manual-entry-line-input-time-start">
                                            {translate("common.start_noun", "Start")}
                                        </label>
                                        <DateTimePicker
                                            inputId="manual-entry-line-input-time-start"
                                            name="time_start"
                                            value={time_start}
                                            placeholder={`${translate("common.select", "Select")}...`}
                                            onChange={this.handleStartTimeChange}
                                            popupPostion="fixed"
                                            disabled={is_kiosk_user || this.props.automatic_import}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="manual-entry-line-input-time-end">
                                            {translate("common.confirmed_end", "Confirmed end")}
                                        </label>
                                        <DateTimePicker
                                            inputId="manual-entry-line-input-time-end"
                                            name="time_end"
                                            value={time_end}
                                            minValue={time_start}
                                            placeholder={`${translate("common.select", "Select")}...`}
                                            onChange={this.handleEndTimeChange}
                                            popupPostion="fixed"
                                            disabled={!(has_permission || is_kiosk_user)}
                                        />
                                    </div>
                                    <div className="form-group mb-0">
                                        <label htmlFor="manual-entry-line-input-required-deadline">
                                            {translate("common.required_deadline", "Required deadline")}
                                        </label>
                                        <DateTimePicker
                                            inputId="manual-entry-line-input-required-deadline"
                                            name="required_deadline"
                                            value={required_deadline}
                                            placeholder={`${translate("common.select", "Select")}...`}
                                            onChange={this.handleRequiredDeadlineChange}
                                            popupPostion="fixed"
                                            disabled={is_kiosk_user || !has_permission}
                                        />
                                    </div>
                                </React.Fragment>
                            )}
                            {this.state.error && (
                                <ErrorComponent className="mb-0" type="error" msg={this.state.error} />
                            )}
                        </form>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    {this.state.is_submitting && (
                        <i className="fas fa-spinner fa-spin mr-auto" style={{ fontSize: 16 }} aria-hidden="true" />
                    )}
                    <button
                        className="btn btn-primary"
                        type="submit"
                        form="manual-entry-line-input-form"
                        disabled={!this.validate() || this.state.is_submitting}
                    >
                        {is_add ? translate("common.add", "Add") : translate("common.save", "Save")}
                    </button>
                    <button className="btn btn-outline-secondary" onClick={this.props.onCancel}>
                        {translate("common.cancel", "cancel")}
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default ManualEntryOrderInputModal;
