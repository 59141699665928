// @flow

// main imports
import React, { Component } from "react";
import { connect } from "react-redux";
import ReactRouterPropTypes from "react-router-prop-types";
import { FormattedMessage } from "react-intl";
// backend
import { getBackend } from "../../lib/backend/Backend2";
import * as t from "../../lib/backend/manufacturing2.generated.types";
import { translate } from "../IntlProviderWrapper";
import * as BusinessLogic from "../../lib/BusinessLogic";

// import subcomponents
import MaterialsList from "./MaterialsList";
import MaterialsDetails from "./MaterialsDetails";
import ErrorComponent from "../ErrorComponent";

import * as Redirect from "../URLHandler/Redirect";
import { ResourcesLayout } from "../MainContent";
import * as Auth from "../../lib/Auth";
import Authorization from "../Authorization";

// $FlowFixMe
import "./materials_new.scss"

import type { ResourceEditFiltersState } from "../redux/reducers/resourceEditFiltersReducer";

// defining types
type Props = {
    match: ReactRouterPropTypes.match,
    history: ReactRouterPropTypes.history,
    location: ReactRouterPropTypes.location,
    resource_edit_filters: ResourceEditFiltersState
}

export type FilterLineGroup = {
    title: string,
    uuid: string,
    weight: number,
    plants: t.IPlantData[]
}

type State = {
    line_groups: FilterLineGroup[],
    materials: t.IMaterialModel[],
    parallel_materials: t.IParallelMaterialModel[],
    plants: t.IPlantData[],
    selected: t.IMaterialModel | null,
    selected_type_external: string | null,
    selected_plant_uuid: string | null,
    selected_plant_tags: t.IMaterialPlantModel[] | null,
    parallel_selected: t.IParallelMaterialModel | null,
    error: string,
    warning: string,
    only_bottleneck: boolean,
    offset: number,
    last_row: number,
    type_externals: string[],
    has_next_page: boolean,
    is_pagination: boolean
}

/**
 * Displaying materials list.
 */
class Materials extends Component<Props, State> {
    /**
     * Constructor.
     * @param {Props} props Props object.
     * @param {State} state State object.
     */
    constructor(props: Props, state: State) {
        super(props);
        this.state = {
            line_groups: [],
            materials: [],
            parallel_materials: [],
            plants: [],
            selected: null,
            selected_type_external: null,
            selected_plant_uuid: null,
            parallel_selected: null,
            error: "",
            warning: "",
            only_bottleneck: false,
            offset: 0,
            last_row: 0,
            type_externals: [],
            has_next_page: false,
            selected_plant_tags: null,
            is_pagination: false
        }
    }

    getMaterialUuid = () => {
        return this.props.match.params.uuid;
    }

    fetchNext = () => {
        this.setState({ offset: this.state.offset + 1 });
    }

    /**
     * Actual loading data from backend.
     */
    async loadComponent() {
        try {
            // prepare map of line-group names for each line
            await BusinessLogic.waitForManufacturingAsync();
            const plants = BusinessLogic.getPlantsForUser();
            this.setState({ plants });
            this.loadMaterials();
        } catch (err) {
            this.setState({ error: err.message });
        }
    }

    /**
     * Loading materials for selected production line.
     *
     * @param {string} line_group_uuid Line uuid.
     * @param {boolean} only_bottleneck Bottleneck indicator.
     */
    async loadMaterials() {
        let parallel_materials = [];
        try {
            const limit = 500;
            const res = await getBackend().manufacturing.searchMaterialsAll({
                limit,
                offset: this.state.offset * limit,
                search_query: this.props.resource_edit_filters.title_filter ? this.props.resource_edit_filters.title_filter : undefined,
                type_external: this.state.selected_type_external ?? undefined
            });
            const materials = res.materials
            const count = res.count
            const type_externals = res.type_externals;

            // remember the materials
            this.setState({
                materials: this.state.is_pagination ? [...this.state.materials, ...materials] : [...materials],
                type_externals,
                parallel_materials,
                error: "",
                warning: "",
                has_next_page: this.state.offset * limit < (count - limit),
                is_pagination: false,
            }, () => {
                // select the first on the list if we have any
                if (this.state.materials.length > 0) {
                    const uuid = this.getMaterialUuid();
                    const material = this.state.materials.find(m => m.uuid === uuid) || this.state.materials[0];
                    this.handleMaterialClick(material);
                }
            });
        } catch (err) {
            console.log('errr', err)
            this.setState({ error: err.message });
        }
    }

    /**
     * Handling change in any input component.
     */
    handleInputChange = async (event: Event) => {
        if (event.currentTarget instanceof HTMLInputElement || event.currentTarget instanceof HTMLSelectElement) {
            // read new values
            const target = event.currentTarget;
            const value = target.value;
            const name = target.name;

            // update new datasource placeholder
            this.setState(prev_state => {
                prev_state[name] = value;
                return prev_state;
            });

            const uuid = (this.state.selected ? this.state.selected.uuid : "");

            // reload materials
            if (name === "title_filter") {
                this.setState({
                    offset: 0
                })
                return Redirect.resourceEditFilters(
                    this.props.history,
                    "/digital-twin/resources/materials/" + uuid,
                    {
                        ...this.props.resource_edit_filters,
                        title_filter: value
                    }
                );
            } else if (name === "produced_consumed") {
                return Redirect.resourceEditFilters(
                    this.props.history,
                    "/digital-twin/resources/materials/" + uuid,
                    {
                            ...this.props.resource_edit_filters,
                            produced_consumed: value === "consumed" ? "consumed" : "produced"
                    }
                );
            }
        }
    };
    /**
     * Mounting data for this component.
     */
    componentDidMount() {
        this.loadComponent();
    }

    async componentDidUpdate(prevProps: Props, prevState: State) {
        if (prevProps.resource_edit_filters.line_group_uuid !== this.props.resource_edit_filters.line_group_uuid ||
            prevProps.resource_edit_filters.title_filter !== this.props.resource_edit_filters.title_filter) {
            this.setState({ materials: [], has_next_page: true });
        }
        if (prevProps.resource_edit_filters.line_group_uuid !== this.props.resource_edit_filters.line_group_uuid ||
            prevState.offset !== this.state.offset ||
            prevProps.resource_edit_filters.title_filter !== this.props.resource_edit_filters.title_filter  ||
            prevState.selected_plant_uuid !== this.state.selected_plant_uuid ||
            prevState.selected_type_external !== this.state.selected_type_external) {
            await this.loadComponent();
        }
    }

    /**
     * Handle `click` events for material items.
     */
    handleMaterialClick = async (material: t.IMaterialModel) => {
        const plant_tags_res = await getBackend().manufacturing.getMaterialPlantTags({ uuid: material.uuid });

        this.setState({
            selected: material,
            selected_plant_tags: plant_tags_res.data
        });

        // get child materials in case it's a parallel material
        if (this.props.resource_edit_filters.line_group_uuid === "parallel") {
            const { data } = await getBackend().manufacturing.parallelMaterialGet({ uuid: material.uuid });
            this.setState({ selected: material, parallel_selected: data });
        } else {
            this.setState({ selected: material, parallel_selected: null });
        }

        this.props.history.push(`/digital-twin/resources/materials/${material.uuid}`);
    }

    onScroll = (e: Event) => {
        const target = e.target;
        if (target instanceof HTMLDivElement) {
            const percentage = (target.scrollTop / (target.scrollHeight - target.clientHeight)) * 100
            if (percentage > 80 && this.state.has_next_page) {
                this.setState({
                    offset: this.state.offset + 1,
                    has_next_page: false,
                    is_pagination: true
                })
            }
        }
    }

    handlePlantChange = (e: Event) => {
        const target = e.target;
        if (target instanceof HTMLSelectElement) {
            const value = target.value;
            this.setState({
                selected_plant_uuid: value,
                is_pagination: false
            })
        }
    }

    handleTypeExternalChange = (e: Event) => {
        const target = e.target;
        if (target instanceof HTMLSelectElement) {
            const value = target.value;
            this.setState({
                selected_type_external: value !== "-1" ? value : null,
                is_pagination: false
            })
        }
    }

    /**
     * Rendering JSX for current component.
     */
    render() {
        return (<ResourcesLayout>
                <ResourcesLayout.Main>
                    <ResourcesLayout.List onScroll={this.onScroll}>
                        <ResourcesLayout.Search id="materials-search">
                            <div>
                                <input
                                    type="text"
                                    className="form-control search_bar"
                                    placeholder={translate("common.search", "Search") + " ..."}
                                    name="title_filter"
                                    value={this.props.resource_edit_filters.title_filter}
                                    onChange={this.handleInputChange}
                                />
                            </div>
                            <div style={{display: "flex"}}>
                                <select
                                    name="plant_uuid"
                                    className="form-control select_filter_control"
                                    value={this.state.selected_plant_uuid}
                                    onChange={this.handlePlantChange}
                                >
                                    <option key={-1} value="">
                                        {translate("Manufacturing.LineGroups.all_plants", "All Plants")}
                                    </option>
                                    {
                                        this.state.plants.map((plant, i) => {
                                            return <option key={i} value={plant.uuid}>{plant.title}</option>
                                        })
                                    }
                                </select>
                                <select
                                    name="type_externals"
                                    className="form-control select_filter_control"
                                    value={this.state.selected_type_external}
                                    onChange={this.handleTypeExternalChange}
                                >
                                    <option value={-1}>{translate("tag_groups.MATERIAL_TAGS.values.title.type_external", "Type externals")}</option>
                                    {
                                        this.state.type_externals.map((type_external, i) => {
                                            if (type_external) {
                                                return <option key={i} value={type_external}>{type_external}</option>
                                            }
                                            return null;
                                        })
                                    }
                                </select>
                            </div>
                            {(this.props.resource_edit_filters.line_group_uuid === "parallel") && <div className="form-group">
                                <Authorization.Link
                                    to="/digital-twin/resources/material/create"
                                    className="btn btn-inv-primary btn-icon btn-icon-add"
                                    title={translate("Manufacturing.Materials.add_new_parallel_material", "Add new multipart material")}
                                    permission={Auth.PERMISSION_NAMES.MultipartMaterialAdd}
                                >
                                    <span className="sr-only">
                                        <FormattedMessage id="Manufacturing.Materials.add_new_parallel_material" defaultMessage="Add new multipart material" />
                                    </span>
                                </Authorization.Link>
                            </div>}
                    </ResourcesLayout.Search>
                    <MaterialsList
                        materials={this.state.materials}
                        parallel_materials={this.state.parallel_materials}
                        selected={this.state.selected}
                        parallel_selected={this.state.parallel_selected}
                        filter={""}
                        handleMaterialClick={this.handleMaterialClick}
                    />
                </ResourcesLayout.List>

                <ResourcesLayout.Content>
                    <ErrorComponent msg={this.state.error} type="error" />
                    <ErrorComponent msg={this.state.warning} type="warning" />
                    <MaterialsDetails
                        selected={this.state.selected}
                        selected_line_group={null}
                        selected_plant_tags={this.state.selected_plant_tags}
                        parallel_selected={this.state.parallel_selected} />
                </ResourcesLayout.Content>
            </ResourcesLayout.Main>
        </ResourcesLayout>)
    }
}

export default connect(
    state => {
        return { resource_edit_filters: state.resource_edit_filters }
    },
    null
)(Materials);
