// @flow

// main imports
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

import Loader from "../Loader";

// subcomponents
import ErrorComponent from "../ErrorComponent";
import DateTimePicker from "../DateTimePicker";

// models;
import * as t from "../../lib/backend/batch_operations.generated.types";
import { getBackend } from "../../lib/backend/Backend2";
import { translate } from "../IntlProviderWrapper";

import type { IBatchOperationOrder } from "../../lib/backend/batch_operations.generated.types";

type Props = {
    halb: boolean,
    show: boolean,
    silo_input: boolean,
    location: t.IBatchOperationLocationStatus,
    error: string,
    onSave: Function,
    onCancel: Function
}

function transformOrder(input: IBatchOperationOrder): IBatchOperationOrder {
    return {
        order_num: input.order_num,
        material_title: input.material_title,
        name: input.name,
        quantity: input.quantity,
        batch: input.batch,
        comment: input.comment,
        person_id: input.person_id,
        ts_delivered: input.ts_delivered,
    };
}
function transformOrderBack(input: IBatchOperationOrder): IBatchOperationOrder {
    return {
        order_num: input.order_num,
        material_title: input.material_title,
        name: input.name,
        quantity: input.quantity,
        batch: input.batch,
        comment: input.comment,
        person_id: input.person_id,
        ts_delivered: input.ts_delivered
    };
}

type State = {
    duration: string,
    error: string,
    is_insert: boolean,
    operation: t.IBatchOperationInfo,
    start_time: Date,
    end_time: Date,
    orders: IBatchOperationOrder[],
    work_order_candidates: t.IWorkOrderSuggestion[],
    loading: boolean
}

const emptyOrder: IBatchOperationOrder = {
    order_num: "",
    material_title: "",
    name: "",
    quantity: 0,
    batch: "",
    comment: "",
    id: 0
};

/** Instance counter */
let lastId: number = 0;

class BatchOperationModalManual extends Component<Props, State> {

    id: number;

    constructor(props: Props) {
        super(props);
        this.state = this.createState(props);
        this.id = ++lastId;
    }

    createState(props: Props): State {
        // copy input data so that it is not overwritten until Save is pressed
        let operation = (props.location ? props.location.operation : null);
        const is_insert = (operation === null);
        if (!operation) {
            // create new operation
            operation = {
                end_time: Date.now() + 0 * 60 * 60 * 1000,
                orders: [],
                recipe_duration: 0,
                recipe_title: "",
                start_time: Date.now(),
                uuid: "" // empty uuid means new, unsaved operation
            };
        }
        const {
            start_time,
            end_time,
            orders
        } = operation;

        const newOrders: IBatchOperationOrder[] = [];
        if (orders.length === 0) {
            // Initialize empty orders - add 1 line
            for (let i = 0; i < 1; i++) {
                newOrders.push(transformOrder(emptyOrder));
            }
        } else {
            // Copy existing orders
            for (let order of orders) {
                newOrders.push(transformOrder(order));
            }
        }

        const res: State = {
            duration: ((new Date(end_time) - new Date(start_time)) / (1000 * 60 * 60)).toString(),
            error: "",
            is_insert,
            operation,
            start_time: new Date(start_time),
            end_time: new Date(end_time),
            orders: newOrders,
            work_order_candidates: [],
            loading: false
        };
        return res;
    }

    handleSubmit = (event: Event) => {
        event.preventDefault();
        const locationOrig = this.props.location;
        const {
            is_insert,
            start_time,
            end_time,
            orders,
            operation
        } = this.state;

        if (is_insert) {
            if (orders.some(x => x.quantity <= 0)) {
                this.setState({
                    error: translate("Manufacturing.BatchOp.QuantityZeroOrNegative", "Quantity cannot be zero or negative")
                });
                return false;
            }
            operation.orders = orders.filter(x => x.quantity > 0).map(transformOrderBack);
        } else {
            if (orders.some(x => x.quantity < 0)) {
                this.setState({
                    error: translate("Manufacturing.BatchOp.QuantityNegative", "Quantity cannot be negative")
                });
                return false;
            }
            operation.orders = orders.filter(x => x.quantity >= 0).map(transformOrderBack);
        }

        this.setState({ error: "", loading: true });

        const location: t.IBatchOperationLocationStatus = {
            disabled: locationOrig.disabled,
            uuid: locationOrig.uuid,
            name: locationOrig.name,
            size: locationOrig.size,
            group_uuid: locationOrig.group_uuid,
            operation: {
                start_time: start_time.getTime(),
                end_time: end_time.getTime(),
                orders: operation.orders,
                recipe_duration: operation.recipe_duration,
                recipe_title: operation.recipe_title,
                uuid: operation.uuid
            }
        };
        this.props.onSave(location);
    }

    handleAdd(event: Event) {
        event.preventDefault();
        this.addEmptyOrder();
    }

    addEmptyOrder() {
        const { orders } = this.state;
        orders.push(JSON.parse(JSON.stringify(emptyOrder)));
        this.setState({ orders });
    }

    handleDateChange(date: Date, name: string) {
        if (name === "start_time") {
            this.setState(state => ({
                start_time: date,
                end_time: new Date(date.getTime() + parseFloat(this.state.duration) * 1000 * 60 * 60)
            }));
        } else {
            this.setState({
                end_time: date
            });
        }
    }

    inputValue(event: Event) {
        const { currentTarget: input } = event;
        if (input instanceof HTMLInputElement) {
            return input.value;
        } else {
            return "";
        }
    }

    handleInputChange(event: Event) {
        const { currentTarget: input } = event;
        if (input instanceof HTMLInputElement) {
            const match = input.name.match(/(\w+)\[(\d+)\]/);
            if (match) {
                const idx = Number(match[2]);
                this.setState((state) => {
                    state.orders[idx][match[1]] = input.value;
                    return state;
                });
            } else {
                this.setState((state) => {
                    state[input.name] = input.value;
                    return state;
                });
            }
        }
    }

    removeOrder(event: Event, idx: number) {
        event.preventDefault();
        const orders = this.state.orders.slice(0, idx)
            .concat(this.state.orders.slice(idx + 1));
        this.setState({ orders });
    }

    async getMaterialTitle(i: number, val: string) {
        if (val !== "") {
            const res = await getBackend().manufacturing.searchMaterials({ external_id: val });
            let material_title = "";
            if (res.data.length > 0) {
                material_title = res.data[0].title;
            }
            const old = this.state.orders;
            old[i].material_title = material_title;
            this.setState({ orders: old });
        }
    }

    onKeyPress = (e: KeyboardEvent) => {
        if (e.charCode === 13) {
            this.handleSubmit(e);
        }
    }

    render() {
        const {
            show,
            location,
            onCancel
        } = this.props;

        return (
            <Modal
                show={show}
                bsSize="large"
                className="batch-operation-modal"
                dialogClassName="modal-dialog-scrollable"
                onHide={onCancel}
                onKeyPress={this.onKeyPress}
            >
                <Modal.Header>
                    <Modal.Title>{location && location.name}</Modal.Title>
                    <button type="button" className="close" onClick={onCancel}>
                        <span aria-hidden="true">×</span>
                        <span className="sr-only"><FormattedMessage id="common.close" defaultMessage="Close" /></span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    {
                        this.state.loading ? <Loader /> :
                            <form id="batch-operation-form" onSubmit={this.handleSubmit}>
                                <ErrorComponent msg={this.props.error} type="error" />
                                <ErrorComponent msg={this.state.error} type="error" />
                                <div className="form-row">
                                    <div className="col-sm-4 form-group">
                                        <label htmlFor={`batch-operation-modal-start-time-${this.id}`}>
                                            <FormattedMessage id="common.duration" defaultMessage="Duration" />[h]
                                        </label>
                                        <input
                                            test-id="duration"
                                            className="form-control"
                                            type="text"
                                            name="duration"
                                            value={this.state.duration}
                                            onChange={event => {
                                                let duration = this.inputValue(event);
                                                if (duration === "") {
                                                    duration = 0;
                                                    this.setState({ duration: "" });
                                                } else {
                                                    this.setState({ duration: duration });
                                                }
                                                const end_time = this.state.start_time.getTime() + parseFloat(duration) * 60 * 60 * 1000;
                                                this.setState({ end_time: new Date(end_time) });
                                            }}
                                        />
                                    </div>
                                    <div className="col-sm-4 form-group">
                                        <label htmlFor={`batch-operation-modal-start-time-${this.id}`}>
                                            <FormattedMessage id="common.start_noun" defaultMessage="Start" />
                                        </label>
                                        <DateTimePicker
                                            inputId={`batch-operation-modal-start-time-${this.id}`}
                                            name="start_time"
                                            value={this.state.start_time}
                                            pickerType="date-time"
                                            popupPostion="fixed"
                                            disabled={true}
                                        />
                                    </div>
                                    <div className="col-sm-4 form-group">
                                        <label htmlFor={`batch-operation-modal-end-time-${this.id}`}>
                                            <FormattedMessage id="common.end" defaultMessage="End" />
                                        </label>
                                        <DateTimePicker
                                            inputId={`batch-operation-modal-end-time-${this.id}`}
                                            name="end_time"
                                            value={this.state.end_time}
                                            minValue={this.state.start_time}
                                            pickerType="date-time"
                                            popupPostion="fixed"
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div className="form-row order-header">
                                    <div className="col form-group mb-0">
                                        <label htmlFor="">
                                            <FormattedMessage id="Manufacturing.BatchOp.Ident" defaultMessage="Ident" />
                                        </label>
                                    </div>
                                    <div className="col form-group mb-0">
                                        <label htmlFor="">
                                            <FormattedMessage id="Manufacturing.BatchOp.Title" defaultMessage="Naziv" />
                                        </label>
                                    </div>
                                    <div className="col form-group mb-0">
                                        <label htmlFor="">
                                            <FormattedMessage id="Manufacturing.BatchOp.Quantity" defaultMessage="Količina" />
                                        </label>
                                    </div>
                                    <div className="col form-group mb-0">
                                        <label htmlFor="">
                                            <FormattedMessage id="Manufacturing.BatchOp.Package" defaultMessage="Paleta" />
                                        </label>
                                    </div>
                                    <div className="col form-group mb-0">
                                        <label htmlFor="">
                                            <FormattedMessage id="Manufacturing.BatchOp.Comment" defaultMessage="Komentar" />
                                        </label>
                                    </div>
                                </div>
                                {this.state.orders.map((order, i) => {
                                    const lastStr = i === this.state.orders.length - 1 ? "-last" : "";
                                    const input_class = this.props.silo_input && i === this.state.orders.length - 1 ?
                                        "form-control selected_input" : "form-control";
                                    return (
                                        // TODO re-enable warning when clients are ready
                                        <div className="" key={"batchop_orders_child_" + i}>
                                            <div className={"form-row order"} key={i}>
                                                <div className="col form-group">
                                                    <input
                                                        test-id={`recipe-name-input${lastStr}`}
                                                        className={input_class}
                                                        type="text"
                                                        name={`name[${i}]`}
                                                        value={this.state.orders[i].name}
                                                        onChange={event => {
                                                            const material_external_id = this.inputValue(event);
                                                            this.getMaterialTitle(i, material_external_id);
                                                            this.handleInputChange(event);
                                                        }}
                                                    />
                                                </div>
                                                <div className="col form-group">
                                                    <input test-id={`recipe-material_title-input${lastStr}`} className={input_class} type="text" name={`material_title[${i}]`} readOnly value={this.state.orders[i].material_title || ''} />
                                                </div>
                                                <div className="col form-group">
                                                    <input test-id={`recipe-quantity-input${lastStr}`} className={input_class} type="number" name={`quantity[${i}]`} value={this.state.orders[i].quantity || ''} min="0" onChange={event => { this.handleInputChange(event) }} />
                                                </div>
                                                <div className="col form-group">
                                                    <input test-id={`recipe-batch-input${lastStr}`} className={input_class} type="text" name={`batch[${i}]`} value={this.state.orders[i].batch || ''} onChange={event => { this.handleInputChange(event) }} />
                                                </div>
                                                <div className="col form-group">
                                                    <input test-id={`recipe-comment-input${lastStr}`} className={input_class} type="text" name={`comment[${i}]`} value={this.state.orders[i].comment || ''} onChange={event => { this.handleInputChange(event) }} />
                                                </div>
                                                <div className="col form-group flex-grow-0">
                                                    <button className="btn btn-outline-secondary btn-icon btn-icon-remove" onClick={event => this.removeOrder(event, i)} disabled={this.state.orders.length === 1}>
                                                        <span className="sr-only">Remove</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                                <div className="pull-right">
                                    <button test-id="recipe-add-button" className="btn btn-primary btn-icon btn-icon-add" onClick={event => { this.handleAdd(event) }}>
                                        <span className="sr-only"><FormattedMessage id="common.add" defaultMessage="Add" /></span>
                                    </button>
                                </div>
                            </form>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary"
                        test-id="recipe-save-button"
                        type="submit"
                        form="batch-operation-form"
                        disabled={this.state.loading}
                    >
                        <FormattedMessage id="common.save" defaultMessage="Save" />
                    </button>
                    {
                        !this.state.loading &&
                        <button className="btn btn-outline-secondary"
                            onClick={onCancel}>
                            <FormattedMessage id="common.cancel" defaultMessage="Cancel" />
                        </button>
                    }
                </Modal.Footer>
            </Modal>
        );
    }
}

export default BatchOperationModalManual;
