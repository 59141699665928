// @flow
import * as React from "react";
import { Modal } from "react-bootstrap";
import { translate } from "../IntlProviderWrapper";


type Props = {
    text: string,
    title: string | null,
    onClose: () => void,
    show: boolean
}

export default function AlertModal(props: Props): any | null {
    if (!props.show) {
        return null;
    }

    return <Modal.Dialog className="contained-modal" show={props.show} onHide={props.onClose}>
        {props.title != null && <Modal.Header>
            <Modal.Title>
                <span>{props.title} </span>
            </Modal.Title>
        </Modal.Header>}
        <Modal.Body>
            {props.text}
        </Modal.Body>
        <Modal.Footer>
            <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                <button className="btn btn-primary" onClick={props.onClose}>
                    {translate("common.close", "Closex")}
                </button>
            </div>
        </Modal.Footer>
    </Modal.Dialog>
}
