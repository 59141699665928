// @flow

// main imports
import React, { Component } from "react";
import { Link } from "react-router-dom";
import ReactRouterPropTypes from "react-router-prop-types";
import { FormattedMessage } from "react-intl";

// models
import type { IBatchOperationLocation, IBatchOperationGroup } from "../../lib/backend/batch_operations.generated.types";

// backend
import { getBackend } from "../../lib/backend/Backend2";
import * as Auth from "../../lib/Auth";

// import subcomponents
import BatchopLocationsList from "./BatchopLocationsList";
import BatchopLocationsDetails from "./BatchopLocationsDetails";
import ErrorComponent from "../ErrorComponent";
import { translate } from "../IntlProviderWrapper";
import { ResourcesLayout } from "../MainContent";


// defining types
type Props = {
    match: ReactRouterPropTypes.match,
    history: ReactRouterPropTypes.history,
    location: ReactRouterPropTypes.location
}

type State = {
    locations: IBatchOperationLocation[],
    batchop_groups: IBatchOperationGroup[],
    group_uuid: string,
    error: string,
    warning: string,
    selected: IBatchOperationLocation | null,
    title_filter: string
}

/**
 * Displaying production lines list.
 */
class BatchopLocationsComponent extends Component<Props, State> {
    locationsListRef: { current: null | BatchopLocationsList };

    /**
     * Constructor.
     * @param {Props} props Props object.
     * @param {State} state State object.
     */
    constructor(props: Props, state: State) {
        super(props);
        this.state = {
            locations: [],
            batchop_groups: [],
            group_uuid: "",
            error: "",
            warning: "",
            selected: null,
            title_filter: ""
        };
        this.locationsListRef = React.createRef();
    }

    /**
     * Load component data from backend.
     */
    async loadComponent() {
        try {
            const batchop_groups = (await getBackend().batchOperations.getGroups({})).items;
            let locations = (await getBackend().batchOperations.getLocations({})).items;

            locations = locations.filter(location => location.disabled !== true);

            // Select initial location.
            let selected = null;
            if (this.props.match.params.uuid) {
                let filtered = locations.filter(line => line.uuid === this.props.match.params.uuid);
                if (filtered.length > 0) {
                    selected = filtered[0];
                }
                if (selected === null) {
                    selected = locations[0];
                }
            } else if (locations.length) {
                selected = locations[0];
            }

            this.setState({
                batchop_groups,
                locations,
                selected,
                error: "",
                warning: ""
            })
        } catch (e) {
            this.setState({ error: e });
        }
    }

    /**
     * Mounting data for this component.
     */
    async componentDidMount() {
        await this.loadComponent();
    }

    /**
     * Deleting a selected line.
     *
     * @param {string} uuid Line universal unique identifier.
     */
    handleToDelete = async (uuid: string) => {
        try {
            await getBackend().batchOperations.deleteLocation({ uuid: uuid });
            this.handleToUpdate();
        } catch (err) {
            this.handleToError(err.message);
        }
    }

    /**
     * Select the first line that matches all the given criteria.
     * @param {string} contentFilter Batchop group search term.
     * @param {string} groupUuid Group UUID.
     * @param {IBatchOperationLocation[]} [locations] List of lines to select from.
     */
    selectLocation(contentFilter: string, groupUuid: string, locations: IBatchOperationLocation[] = this.state.locations): IBatchOperationLocation | null {
        for (let location of locations) {
            const visible = this.locationsListRef.current && this.locationsListRef.current.includes(contentFilter, location);

            if ((groupUuid === location.group_uuid || groupUuid === "") && visible) {
                return location;
            }
        }

        return null;
    }

    /**
     * Handling change in any input component.
     */
    handleInputChange = (event: Event) => {
        if (event.currentTarget instanceof HTMLInputElement || event.currentTarget instanceof HTMLSelectElement) {
            // read new values
            const target = event.currentTarget;
            const value = target.value;
            const name = target.name;

            // update new datasource placeholder
            this.setState(prev_state => {
                prev_state[name] = value;
                return prev_state;
            });

            let titleFilter = this.state.title_filter;
            let groupUuid = this.state.group_uuid;

            if (name === "group_uuid") {
                groupUuid = value;
                this.props.history.push("/digital-twin/resources/batchop_locations");
            } else if (name === "title_filter") {
                titleFilter = value;
            }

            this.setState({
                selected: this.selectLocation(titleFilter, groupUuid)
            });
        }
    };

    /**
     * Handle `click` events for line items.
     */
    handleLocationClick(location: IBatchOperationLocation) {
        this.setState({ selected: location });

        // Update URL.
        this.props.history.push(`/digital-twin/resources/batchop_locations/${location.uuid}`);
    }

    /**
     * Handle, that can be called by the children, to update the data.
     */
    handleToUpdate = () => {
        this.loadComponent();
    }

    /**
     * Handling error from subordinate component.
     */
    handleToError = (str: string) => {
        this.setState({ error: str });
    }

    /**
     * Rendering JSX for current component.
     */
    render() {
        const can_edit = Auth.isInRole(Auth.ROLE_ADMIN) || Auth.isInRole(Auth.ROLE_POWER_USER)|| Auth.isInRole(Auth.ROLE_DEMO_USER);
        return (
            <ResourcesLayout>
                <ResourcesLayout.Main>
                    <ResourcesLayout.List>
                        <ResourcesLayout.Search>
                            <div className="form-group form-group-stretch">
                                <input type="text" className="form-control search_bar" placeholder={translate("common.search", "Search") + " ..."}
                                    name="title_filter" value={this.state.title_filter} onChange={this.handleInputChange} />
                            </div>
                            <div className="form-group">
                                <select name="group_uuid" className="form-control select_filter_control" value={this.state.group_uuid} onChange={this.handleInputChange}>
                                    <option key={-1} value="">
                                        {translate("Manufacturing.BatchopLocations.all_groups", "All Groups")}
                                    </option>
                                    {
                                        this.state.batchop_groups.map((group, i) => {
                                            return <option key={i} value={group.uuid}>{group.name}</option>
                                        })
                                    }
                                </select>
                            </div>
                            {can_edit && <div className="form-group">
                                <Link to="/digital-twin/resources/batchop_location/create" className="btn btn-inv-primary btn-icon btn-icon-add"
                                    title={translate("Manufacturing.Lines.add_new_line", "Add new line")}>
                                    <span className="sr-only">
                                        <FormattedMessage id="Manufacturing.Lines.add_new_line" defaultMessage="Add new line" />
                                    </span>
                                </Link>
                            </div>}
                        </ResourcesLayout.Search>
                        <BatchopLocationsList
                            ref={this.locationsListRef}
                            locations={this.state.locations}
                            selected={this.state.selected}
                            filter={this.state.title_filter}
                            groupFilter={this.state.group_uuid}
                            batchop_groups={this.state.batchop_groups}
                            handleLocationClick={(l) => { this.handleLocationClick(l) }} />
                    </ResourcesLayout.List>
                    <ResourcesLayout.Content>
                        {
                            this.state.locations.length === 0 && <ErrorComponent
                                key="empty"
                                className="my-0 border-left-0 border-right-0 rounded-0"
                                type="no-data"
                                msg={translate("common.no_data", "No data available")}
                            />
                        }
                        <ErrorComponent msg={this.state.error} type="error" />
                        <ErrorComponent msg={this.state.warning} type="warning" />
                        {this.state.selected &&
                            <BatchopLocationsDetails
                                selected={this.state.selected}
                                editLink="/digital-twin/resources/batchop_location/edit/"
                                handleToDelete={this.handleToDelete}
                            />
                        }
                    </ResourcesLayout.Content>
                </ResourcesLayout.Main>
            </ResourcesLayout>
        )
    }

}

export default BatchopLocationsComponent;
