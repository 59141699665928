// @flow
import * as React from "react";
import * as BusinessLogic from "../../../lib/BusinessLogic";
// $FlowFixMe
import "./chevron_list_item.scss";

type Props = {
    icon?: React.Node,
    text: React.Node,
    onClick: Function,
    is_active: boolean,
    className?: string
};
type State = {};


export default class ChevronListItem extends React.Component<Props, State> {
    
    getIsActive() {
        return this.props.is_active ? "active" : "";
    }

    render() {
        return <div className={(this.props.className || "") + " chevron-list-item " + this.getIsActive()} onClick={this.props.onClick}>
            <div className="chevron">
                <i class="fas fa-chevron-right"></i>
            </div>
            <div className="text">{this.props.text}</div>
        </div>
    }
}

export class DropdownChevronListItem extends React.Component<Props, State> {
    
    getIsActive() {
        return this.props.is_active ? "active" : "";
    }

    render() {
        const sys_flag = BusinessLogic.getSysFlag("env");
        return <div
            data-sys-flag={sys_flag}
            className={(this.props.className || "") + " dropdown-chevron-list-item " + this.getIsActive()}
            onClick={this.props.onClick}
        >
            <div className="chevron"></div>
            <div className="text">
                {this.props.icon}
                <span className="label">
                    {this.props.text}
                </span>
            </div>
        </div>
    }
}