// @flow
import React from "react";

type Props = {
    position: Object,
    style: Object,
    num_alerts: number
}

export const Badge = (props: Props) => {
    const position = props.position;
    const style = props.style;
    return <span class="fas fa-circle" style={style}>
        <span style={{...position}}>
            {props.num_alerts}
        </span>
    </span>
}