// @flow

// main imports
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import ConfirmationModal from "../ConfirmationModal";

// models
import type { IBatchOperationGroup, IBatchOperationLocation } from "../../lib/backend/batch_operations.generated.types";

// backend
import { waitForManufacturingAsync } from "../../lib/BusinessLogic";
import { getBackend } from "../../lib/backend/Backend2";
import * as Auth from "../../lib/Auth";

// subcomponents
import { translate } from "../IntlProviderWrapper";

// defining types
type Props = {
    selected: IBatchOperationLocation,
    editLink: string,
    handleToDelete: any
}

type State = {
    batchopGroups: IBatchOperationGroup[],
    showDeleteModal: boolean
}

/**
 * Batch operations locations details component.
 */
class BatchopLocationsDetails extends Component<Props, State> {

    constructor(props: Props, state: State) {
        super(props, state);
        this.state = {
            batchopGroups: [],
            showDeleteModal: false
        };
    }

    componentDidMount() {
        // update manufacturing meta-data
        this.loadBatchopGroups();
    }

    async componentDidUpdate(prevProps: Props) {
        // do nothing if not selected
        if (!this.props.selected) { return; }
        // check if selected line changed
        if (!prevProps.selected || this.props.selected.uuid !== prevProps.selected.uuid) {
            // update manufacturing meta-data
            this.loadBatchopGroups();
        }
    }

    async loadBatchopGroups() {
        // update manufacturing meta-data
        await waitForManufacturingAsync();
        // get batchop groups for selected batchop location
        const batchopGroups = (await getBackend().batchOperations.getGroups({})).items;
        // let batchopGroups = batchop_groups.filter(x => x.line_uuids.indexOf(this.props.selected.uuid) >= 0);
        this.setState({ batchopGroups });
    }

    /**
     * Handle delete button clicks.
     */
    handleDelete = () => {
        if (this.props.selected) {
            this.props.handleToDelete(this.props.selected.uuid);
        }

        this.setState({
            showDeleteModal: false
        });
    }

    onShowDeleteModal = (show: boolean) => {
        return () => {
            this.setState({
                showDeleteModal: show
            });
        }
    }

    /**
     * Render component.
     */
    render() {
        if (this.props.selected === null) {
            return null;
        }
        const can_edit = Auth.isInRole(Auth.ROLE_ADMIN) || Auth.isInRole(Auth.ROLE_POWER_USER)|| Auth.isInRole(Auth.ROLE_DEMO_USER);
        const editLink = `${this.props.editLink}${this.props.selected.uuid}`;
        let tags = "/";

        if ((this.props.selected.tags !== undefined) && (Object.keys(this.props.selected.tags).length !== 0)) {
            // generate tags
            tags = Object.keys(this.props.selected.tags).map((el, i) => {
                const val = this.props.selected.tags[el];
                let style = "badge";
                if (val === "true") {
                    style += " badge-success";
                } else if (val === "false") {
                    style += " badge-danger";
                } else {
                    style += " badge-secondary";
                }
                return (<span className={style} style={{ marginRight: 3 }} key={i}>{el + ": " + val}</span>);
            });
        }

        // extract batchop group
        let batchopGroup;
        if (this.props.selected.group_uuid !== undefined) {
            const batchop_group_uuid = this.props.selected.group_uuid;
            // extract line group title
            const bg_titles = this.state.batchopGroups.filter((x) => x.uuid === batchop_group_uuid);
            let batchop_group_title = "not found in the list";
            if (bg_titles.length > 0) {
                batchop_group_title = bg_titles[0].name;
                batchopGroup = <Link to={`/digital-twin/resources/batchop_groups/${batchop_group_uuid}`}>{batchop_group_title}</Link>
            } else {
                batchopGroup = translate("Manufacturing.BatchopLocation.batchop_group_not_found") + " (" + batchop_group_uuid + ")";
            }
        }

        return (
            <div className="tab-content">
                <div className="tab-pane active tab-pane-text">
                    <div className="d-flex">
                        <ConfirmationModal
                            show={this.state.showDeleteModal} size="small"
                            title={translate("common.confirmation", "Confirmation")}
                            onAccept={this.handleDelete}
                            onCancel={this.onShowDeleteModal(false)}
                        >
                            <FormattedMessage id="Manufacturing.BatchopLocation.delete_location_modal" defaultMessage="Are you sure you want to delete this location?" />
                        </ConfirmationModal>
                        {can_edit && <div className="button-list order-last ml-auto">
                            <Link className="btn btn-outline-primary" to={editLink}>
                                <FormattedMessage id="common.edit" defaultMessage="Edit" />
                            </Link>
                            <button className="btn btn-outline-secondary" onClick={this.onShowDeleteModal(true)}>
                                <FormattedMessage id="common.delete" defaultMessage="Delete" />
                            </button>
                        </div>}

                        <div className="order-first">
                            <h5 className="pane-title">
                                <em><FormattedMessage id="common.batchop_location" defaultMessage="Batch Operations Location" /></em>
                                {this.props.selected.name}
                            </h5>
                            <dl className="property-list">
                                <div>
                                    <dt><FormattedMessage id="common.batchop_group" defaultMessage="Batch Operations Group" /></dt>
                                    <dd><div>{batchopGroup}</div></dd>
                                </div>
                                <div>
                                    <dt><FormattedMessage id="common.size" defaultMessage="Size" /></dt>
                                    <dd><div>{this.props.selected.size}</div></dd>
                                </div>
                                <div>
                                    <dt><FormattedMessage id="common.tags" defaultMessage="Tags" /></dt>
                                    <dd>{tags}</dd>
                                </div>
                                <div>
                                    <dt className="light"><FormattedMessage id="common.uuid" defaultMessage="UUID" /></dt>
                                    <dd className="light"><div>{this.props.selected.uuid}</div></dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default BatchopLocationsDetails;
