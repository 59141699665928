// @flow

/************************************************************************************/
/* WARNING: This file is generated automatically. Don't edit it by hand             */
/************************************************************************************/

import * as api from "./restApi.js";
import * as t from "./datasources.generated.types.js";

function enc(s: any): string { return encodeURIComponent("" + s); }

export interface IDatasources {
    /** Gets datasource statistics for user's org */
    getStats(req: t.IGetStatsReq): Promise<t.IGetStatsRes>;

    /** Gets analyses for single datasource */
    getDatasourceAnalyses(req: t.IGetDatasourceAnalysesReq): Promise<t.IGetDatasourceAnalysesRes>;

    /** Gets all details for single datasource */
    getDatasourceDetails(req: t.IGetDatasourceDetailsReq): Promise<t.IGetDatasourceDetailsRes>;

    /** Gets stats for single datasource */
    getDatasourceStats(req: t.IGetDatasourceStatsReq): Promise<t.IGetDatasourceStatsRes>;

    /** Gets data for single datasource */
    getSingleDatasource(req: t.IGetSingleDatasourceReq): Promise<t.IGetSingleDatasourceRes>;

    /** Gets datasources that match given criteria */
    searchDatasources(req: t.ISearchDatasourcesReq): Promise<t.ISearchDatasourcesRes>;

    /** Inserts new datasource */
    insertDatasource(req: t.IInsertDatasourceReq): Promise<t.IInsertDatasourceRes>;

    /** Gets data from single timeseries */
    getSingleTSeriesData(req: t.IGetSingleTSeriesDataReq): Promise<t.IGetSingleTSeriesDataRes>;

    /** Gets aggregated data about single timeseries */
    getSingleTSeriesDataAggregated(req: t.IGetSingleTSeriesDataAggregatedReq): Promise<t.IGetSingleTSeriesDataAggregatedRes>;

    /** Gets meta-data about single timeseries */
    getSingleTSeries(req: t.IGetSingleTSeriesReq): Promise<t.IGetSingleTSeriesRes>;

    /** Search for timeseries */
    searchTSeries(req: t.ISearchTSeriesReq): Promise<t.ISearchTSeriesRes>;

    /** Gets data for single analysis */
    getSingleAnalysis(req: t.IGetSingleAnalysisReq): Promise<t.IGetSingleAnalysisRes>;

    /** Updates data for single analysis */
    updateAnalysis(req: t.IUpdateAnalysisReq): Promise<t.IUpdateAnalysisRes>;

    /** Inserts new analysis */
    insertAnalysis(req: t.IInsertAnalysisReq): Promise<t.IInsertAnalysisRes>;

}

export class Datasources implements IDatasources {

    client: api.ApiClient;

    constructor(client: api.ApiClient) { this.client = client; }

    /** Gets datasource statistics for user's org */
    async getStats(req: t.IGetStatsReq): Promise<t.IGetStatsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/datasources/stats`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Gets analyses for single datasource */
    async getDatasourceAnalyses(req: t.IGetDatasourceAnalysesReq): Promise<t.IGetDatasourceAnalysesRes> {
        const reqx: any = req;
        const url = `/api/v1.0/datasources/${enc(req.id)}/analyses`;
        delete reqx.id;
        return (await this.client.get(url, reqx)).data;
    }

    /** Gets all details for single datasource */
    async getDatasourceDetails(req: t.IGetDatasourceDetailsReq): Promise<t.IGetDatasourceDetailsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/datasources/${enc(req.id)}/details`;
        delete reqx.id;
        return (await this.client.get(url, reqx)).data;
    }

    /** Gets stats for single datasource */
    async getDatasourceStats(req: t.IGetDatasourceStatsReq): Promise<t.IGetDatasourceStatsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/datasources/${enc(req.id)}/stats`;
        delete reqx.id;
        return (await this.client.get(url, reqx)).data;
    }

    /** Gets data for single datasource */
    async getSingleDatasource(req: t.IGetSingleDatasourceReq): Promise<t.IGetSingleDatasourceRes> {
        const reqx: any = req;
        const url = `/api/v1.0/datasources/${enc(req.id)}`;
        delete reqx.id;
        return (await this.client.get(url, reqx)).data;
    }

    /** Gets datasources that match given criteria */
    async searchDatasources(req: t.ISearchDatasourcesReq): Promise<t.ISearchDatasourcesRes> {
        const reqx: any = req;
        const url = `/api/v1.0/datasources`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Inserts new datasource */
    async insertDatasource(req: t.IInsertDatasourceReq): Promise<t.IInsertDatasourceRes> {
        const reqx: any = req;
        const url = `/api/v1.0/datasources`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Gets data from single timeseries */
    async getSingleTSeriesData(req: t.IGetSingleTSeriesDataReq): Promise<t.IGetSingleTSeriesDataRes> {
        const reqx: any = req;
        const url = `/api/v1.0/timeseries/${enc(req.id)}/data`;
        delete reqx.id;
        return (await this.client.get(url, reqx)).data;
    }

    /** Gets aggregated data about single timeseries */
    async getSingleTSeriesDataAggregated(req: t.IGetSingleTSeriesDataAggregatedReq): Promise<t.IGetSingleTSeriesDataAggregatedRes> {
        const reqx: any = req;
        const url = `/api/v1.0/timeseries/${enc(req.id)}/data_aggr`;
        delete reqx.id;
        return (await this.client.get(url, reqx)).data;
    }

    /** Gets meta-data about single timeseries */
    async getSingleTSeries(req: t.IGetSingleTSeriesReq): Promise<t.IGetSingleTSeriesRes> {
        const reqx: any = req;
        const url = `/api/v1.0/timeseries/${enc(req.id)}`;
        delete reqx.id;
        return (await this.client.get(url, reqx)).data;
    }

    /** Search for timeseries */
    async searchTSeries(req: t.ISearchTSeriesReq): Promise<t.ISearchTSeriesRes> {
        const reqx: any = req;
        const url = `/api/v1.0/timeseries`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Gets data for single analysis */
    async getSingleAnalysis(req: t.IGetSingleAnalysisReq): Promise<t.IGetSingleAnalysisRes> {
        const reqx: any = req;
        const url = `/api/v1.0/analysis/${enc(req.id)}`;
        delete reqx.id;
        return (await this.client.get(url, reqx)).data;
    }

    /** Updates data for single analysis */
    async updateAnalysis(req: t.IUpdateAnalysisReq): Promise<t.IUpdateAnalysisRes> {
        const reqx: any = req;
        const url = `/api/v1.0/analysis/${enc(req.id)}`;
        delete reqx.id;
        return (await this.client.put(url, reqx)).data;
    }

    /** Inserts new analysis */
    async insertAnalysis(req: t.IInsertAnalysisReq): Promise<t.IInsertAnalysisRes> {
        const reqx: any = req;
        const url = `/api/v1.0/analysis`;
        return (await this.client.post(url, reqx)).data;
    }

}
