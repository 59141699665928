// @flow
import * as React from "react";
// import { Link } from "react-router-dom";
import { select } from "d3-selection";
import { mapStatusToStyle } from "./DashboardUtils";
import { hashCode, niceNumber } from "../../lib/Util";

type SimpleKpi = {
    status: string,
    title: string,
    url: string,
    x: number,
    y: number
};

type ManLineKpi = {
    downtime: number,
    has_live_data: Boolean,
    parts_current: number,
    parts_produced: number,
    parts_total: number,
    person: string,
    product: string,
    scrap: number,
    status: string,
    title: string,
    url: string,
    workorder_is_late: Boolean,
    x: number,
    y: number
};

type State = {
    width: number,
    height: number,
    svg: any
};

type Props = {
    // handleClick: Function,
    background_url: string,
    kpis: Array<SimpleKpi>,
    title: string,
    manLineKpis: Array<ManLineKpi>,
};

const TOTAL_WIDTH = 1000;
const TOTAL_HEIGHT = 500;
const TITLE_WIDTH = 200;
const FONT_SIZE = 9;
const FONT_SIZE2 = 9;
const FONT_SIZE3 = 7;
const PADDING = 4;
const PADDING2 = 4;
const ROUND_CORNER = 2;
const ICON_COLOR = "#666666"; // "#CBD2D9";
const TEXT_COLOR = "#222222";
const KPI_WIDTH = 130;
const KPI_WIDTH_SMALL = 120;

class OverlayDash extends React.Component<Props, State> {

    node: any;

    componentDidMount() {
        window.addEventListener("resize", () => this.updateDimensions());
        this.updateDimensions();
    }

    updateDimensions() {
        const width = window.innerWidth - 20;
        const height = width / 2;
        const node = this.node;
        this.setState({ width: width, height: height });
        select(node).selectAll("*").remove();
        const svg = select(node)
            .append("svg")
            .attr("width", width)
            .attr("height", height)
            .attr("viewBox", `0 0 ${TOTAL_WIDTH} ${TOTAL_HEIGHT}`);
        this.setState({ svg: svg }, () => this.createDisplay());
    }

    createSimpleKpis(svg) {
        for (const kpi of this.props.kpis) {
            const g = svg.append("g")
                .attr("transform", function (d) { return "translate(" + kpi.x + "," + kpi.y + ")"; });
            const width = KPI_WIDTH_SMALL;
            const rect = g.append("rect")
                .attr("x", 0)
                .attr("y", 0)
                .attr("rx", 4)
                .attr("rx", 4)
                .attr("fill-opacity", "0.8")
                .attr("class", mapStatusToStyle(kpi.status))
                .attr("width", width)
                .attr("height", 2 * PADDING + FONT_SIZE);
            const text = g.append("text")
                .attr("x", width / 2)
                .attr("y", PADDING + FONT_SIZE / 2)
                .attr("font-family", "sans-serif")
                .attr("font-size", FONT_SIZE + "px")
                .attr("fill", "white")
                .attr("dominant-baseline", "central")
                .attr("text-anchor", "middle")
                .text(kpi.title);
            if (kpi.url) {
                rect.on("click", () => { window.location = kpi.url; });
                rect.style("cursor", "pointer");
                text.on("click", () => { window.location = kpi.url; });
                text.style("cursor", "pointer");
            }
        }
    }

    createManLineKpis(svg) {
        for (const kpi of this.props.manLineKpis) {
            const g = svg.append("g")
                .attr("transform", function (d) { return "translate(" + kpi.x + "," + kpi.y + ")"; });
            const width = KPI_WIDTH;

            const header_height = 2 * PADDING + FONT_SIZE;
            const total_height =
                header_height +
                1 * PADDING +
                1 * (PADDING2 + FONT_SIZE2) + 
                3 * (PADDING2 + FONT_SIZE3);
            const ctrls = [];
            const rect = g.append("rect")
                .attr("x", 0).attr("y", 0).attr("rx", ROUND_CORNER).attr("rx", ROUND_CORNER)
                .attr("fill", "#ffffff")
                .attr("fill-opacity", "0.7")
                .attr("width", width).attr("height", total_height);
            ctrls.push(rect);

            // create header

            const recx = g.append("rect")
                .attr("x", 0).attr("y", 0).attr("rx", 4).attr("rx", 4)
                .attr("class", mapStatusToStyle(kpi.status === "on" ? "ok" : (kpi.status === "downtime" ? "error" : "inactive")))
                .attr("width", width).attr("height", header_height);
            ctrls.push(recx);
            const text = g.append("text")
                .attr("x", width / 2).attr("y", PADDING + FONT_SIZE / 2)
                .attr("font-size", FONT_SIZE + "px")
                .attr("fill", "white")
                .attr("dominant-baseline", "central")
                .attr("text-anchor", "middle")
                .text(kpi.title);
            ctrls.push(text);

            // create texts
            let line_index = 0;
            const y_3 = header_height + (line_index + 1) * PADDING2 + line_index * FONT_SIZE2;
            const status3 = (kpi.downtime >= 2 ? "error" : (kpi.downtime >= 1 ? "warning" : "ok"));
            const text3 = g.append("text")
                .attr("x", PADDING + 3 * FONT_SIZE2 + 2).attr("y", y_3 + FONT_SIZE2 / 2)
                .attr("font-size", FONT_SIZE2 + "px")
                .attr("fill", TEXT_COLOR)
                .attr("dominant-baseline", "central")
                .attr("text-anchor", "start")
                .text(niceNumber(kpi.downtime, 2) + " h");
            ctrls.push(text3);
            const led3 = g.append("circle")
                .attr("r", FONT_SIZE2 / 2)
                .attr("cx", PADDING + 2 * FONT_SIZE2 + 2)
                .attr("cy", y_3 + FONT_SIZE2 / 2)
                .attr("class", mapStatusToStyle(status3));
            ctrls.push(led3);

            //line_index++;
            const y_4 = header_height + (line_index + 1) * PADDING2 + line_index * FONT_SIZE2;
            const status4 = (kpi.scrap >= 0.05 ? "error" : (kpi.scrap >= 0.01 ? "warning" : "ok"));
            const text4 = g.append("text")
                .attr("x", width / 2 + 3 * FONT_SIZE2 + 2)
                .attr("y", y_4 + FONT_SIZE2)
                .attr("font-size", FONT_SIZE2 + "px")
                .attr("fill", TEXT_COLOR)
                .attr("text-anchor", "start")
                .text(niceNumber(kpi.scrap * 100, 1) + " %");
            ctrls.push(text4);
            const led4 = g.append("circle")
                .attr("r", FONT_SIZE2 / 2)
                .attr("cx", width / 2 + 2 * FONT_SIZE2 + 2)
                .attr("cy", y_4 + FONT_SIZE2 / 2)
                .attr("class", mapStatusToStyle(status4));
            ctrls.push(led4);

            line_index++;
            const y_2 = header_height + (line_index + 1) * PADDING2 + line_index * FONT_SIZE2;
            const led2 = g.append("circle")
                .attr("r", 4)
                .attr("cx", PADDING + 2 * FONT_SIZE2 + 2)
                .attr("cy", y_2 + FONT_SIZE2 / 2)
                .attr("class", mapStatusToStyle(kpi.workorder_is_late ? "error" : "ok"));
            ctrls.push(led2);
            if (kpi.has_live_data) {
                // we have live data
                const text2 = g.append("text")
                    .attr("x", PADDING + 4 * FONT_SIZE2).attr("y", y_2 + FONT_SIZE2 / 2)
                    .attr("font-size", FONT_SIZE3 + "px")
                    .attr("fill", TEXT_COLOR)
                    .attr("dominant-baseline", "central")
                    .attr("text-anchor", "start")
                    .text(
                        niceNumber(kpi.parts_current, 0) + " / " +
                        niceNumber(kpi.parts_produced, 0) + " / " +
                        niceNumber(kpi.parts_total, 0));
                ctrls.push(text2);
                const led22 = g.append("circle")
                    .attr("r", 4)
                    .attr("cx", PADDING + 3 * FONT_SIZE2 + 2)
                    .attr("cy", y_2 + FONT_SIZE2 / 2)
                    .attr("class", "dsh_blinking_led " + mapStatusToStyle("info"));
                ctrls.push(led22);
            } else {
                // no live data
                const text2 = g.append("text")
                    .attr("x", PADDING + 3 * FONT_SIZE2 + 2).attr("y", y_2 + FONT_SIZE2 / 2)
                    .attr("font-size", FONT_SIZE3 + "px")
                    .attr("fill", TEXT_COLOR)
                    .attr("dominant-baseline", "central")
                    .attr("text-anchor", "start")
                    .text(niceNumber(kpi.parts_produced, 0) + " / " + niceNumber(kpi.parts_total, 0));
                ctrls.push(text2);
            }

            line_index++;
            let line_index2 = 0;
            const y_5 = header_height + (line_index+1) * PADDING2 + line_index * FONT_SIZE2 + line_index2 * FONT_SIZE3;
            const text5 = g.append("text")
                .attr("x", width / 2)
                .attr("y", y_5 + FONT_SIZE3)
                .attr("font-size", FONT_SIZE3 + "px")
                .attr("fill", TEXT_COLOR)
                .attr("text-anchor", "middle")
                .text(kpi.product);
            ctrls.push(text5);

            line_index2++;
            const y_6 = header_height + (line_index+1) * PADDING2 + line_index * FONT_SIZE2 + line_index2 * FONT_SIZE3;
            const text6 = g.append("text")
                .attr("x", width / 2)
                .attr("y", y_6 + FONT_SIZE3)
                .attr("font-size", FONT_SIZE3 + "px")
                .attr("fill", TEXT_COLOR)
                .attr("text-anchor", "middle")
                .text(kpi.person);
            ctrls.push(text6);

            // append icons

            const icon_parts = g.append("g")
                .attr("transform", `translate(${PADDING},${y_2 - FONT_SIZE2 / 2}) scale(0.75)`);
            icon_parts.append("path")
                .attr("fill", ICON_COLOR)
                .attr("d", "M4.9,7.6l6.8-3c0.2-0.1,0.4-0.1,0.6,0l6.8,3c0.4,0.2,0.5,0.6,0.4,1c-0.1,0.2-0.2,0.3-0.4,0.4l-6.8,3 c-0.2,0.1-0.4,0.1-0.6,0l-6.8-3c-0.4-0.2-0.5-0.6-0.4-1C4.7,7.8,4.8,7.6,4.9,7.6z");
            icon_parts.append("path")
                .attr("fill", ICON_COLOR)
                .attr("d", "M5.9,10.9l5.8,2.6c0.2,0.1,0.4,0.1,0.6,0l5.8-2.6l1,0.4c0.4,0.2,0.5,0.6,0.4,1c-0.1,0.2-0.2,0.3-0.4,0.4l-6.8,3 c-0.2,0.1-0.4,0.1-0.6,0l-6.8-3c-0.4-0.2-0.5-0.6-0.4-1c0.1-0.2,0.2-0.3,0.4-0.4C4.9,11.3,5.9,10.9,5.9,10.9z M5.9,14.6l5.8,2.6 c0.2,0.1,0.4,0.1,0.6,0l5.8-2.6l1,0.4c0.4,0.2,0.5,0.6,0.4,1c-0.1,0.2-0.2,0.3-0.4,0.4l-6.8,3c-0.2,0.1-0.4,0.1-0.6,0l-6.8-3 c-0.4-0.2-0.5-0.6-0.4-1c0.1-0.2,0.2-0.3,0.4-0.4C4.9,15.1,5.9,14.6,5.9,14.6z");

            const icon_downtime = g.append("g")
                .attr("transform", `translate(${PADDING},${y_3 - FONT_SIZE2 / 2}) scale(0.75)`);
            icon_downtime.append("path")
                .attr("fill", ICON_COLOR)
                .attr("d", "M19.4,8.8l-4.2-4.2c0,0-0.1-0.1-0.2-0.1H9c-0.1,0-0.1,0-0.2,0.1L4.6,8.8c0,0-0.1,0.1-0.1,0.2v6 c0,0.1,0,0.1,0.1,0.2l4.2,4.2c0,0,0.1,0.1,0.2,0.1h6c0.1,0,0.1,0,0.2-0.1l4.2-4.2c0,0,0.1-0.1,0.1-0.2V9C19.5,8.9,19.5,8.9,19.4,8.8 z M12.8,7.3l-0.2,6.6h-0.9l-0.2-6.6H12.8z M12.1,16.7c-0.5,0-0.8-0.4-0.8-0.8c0-0.5,0.3-0.8,0.8-0.8c0.5,0,0.8,0.4,0.8,0.8 C12.9,16.3,12.6,16.7,12.1,16.7z");

            const icon_scrap = g.append("g")
                .attr("transform", `translate(${/*PADDING*/ width / 2},${y_4 - FONT_SIZE2 / 2}) scale(0.75)`);
            icon_scrap.append("path")
                .attr("fill", ICON_COLOR)
                .attr("d", "M6.8,6.8h10.5l-0.7,11.3c0,0.8-0.7,1.4-1.5,1.4H8.9c-0.8,0-1.5-0.6-1.5-1.4L6.8,6.8z M10.5,10.5 c-0.4,0-0.8,0.3-0.8,0.8v4.5c0,0.4,0.3,0.8,0.8,0.8s0.8-0.3,0.8-0.8v-4.5C11.2,10.8,10.9,10.5,10.5,10.5z M13.5,10.5 c-0.4,0-0.8,0.3-0.8,0.8v4.5c0,0.4,0.3,0.8,0.8,0.8s0.8-0.3,0.8-0.8v-4.5C14.2,10.8,13.9,10.5,13.5,10.5z");
            icon_scrap.append("path")
                .attr("fill", ICON_COLOR)
                .attr("d", "M9.4,6l1.3-1.3c0.1-0.1,0.3-0.2,0.5-0.2h1.5c0.2,0,0.4,0.1,0.5,0.2L14.6,6h2.7C17.7,6,18,6.3,18,6.8 s-0.3,0.8-0.8,0.8H6.8C6.3,7.5,6,7.2,6,6.8S6.3,6,6.8,6H9.4z");

            if (kpi.url) {
                // make svg elements clickable
                ctrls.forEach(ctrl => {
                    ctrl.on("click", () => { window.location = kpi.url; });
                    ctrl.style("cursor", "pointer");
                });
            }
        }
    }

    createDisplay() {
        if (this.state) {
            const svg = this.state.svg;
            const url = this.props.background_url || "http://lorempixel.com/500/250/"
            svg.append("image")
                .attr("xlink:href", url)
                .attr("width", 1000)
                .attr("height", 500);

            const g = svg.append("g")
                .attr("transform", `translate(${TOTAL_WIDTH - TITLE_WIDTH - PADDING2}, ${PADDING2})`);
            g.append("rect")
                .attr("x", 0).attr("y", ROUND_CORNER).attr("rx", ROUND_CORNER).attr("rx", ROUND_CORNER)
                .attr("fill", "#1C2837")
                .attr("width", TITLE_WIDTH).attr("height", FONT_SIZE2 + 2 * PADDING2);
            g.append("text")
                .attr("x", PADDING2).attr("y", PADDING2 + FONT_SIZE2)
                .attr("font-size", FONT_SIZE2 + "px")
                .attr("fill", "#ffffff")
                .attr("text-anchor", "start")
                .text(this.props.title);
            
            this.createSimpleKpis(svg);
            this.createManLineKpis(svg);
        }
    }

    render() {
        return <div className="chart-wrapper" ref={node => this.node = node} ></div>;
    }
}

export function createOverlayDashboard(root: any, data: any) {

    // const title = root.title;
    const background_url = root.background || "/img/tmp/kfh_floor_flat.svg";
    const hashKey = hashCode(JSON.stringify(root || ""));
    root.indicators = root.indicators || [];
    data.indicators = data.indicators || [];
    const joined = root.indicators
        .map(x => {
            const hits = data.indicators.filter(y => x.key === y.key);
            if (hits.length === 0) {
                return null;
            }
            return { layout: x, data: hits[0] };
        })
        .filter(x => !!x);
    const kpis = joined
        .filter(x => x.data.type === "ts")
        .map(x => ({
            x: x.layout.x,
            y: x.layout.y,
            title: x.layout.title,
            status: x.data.status,
            value: +x.data.value,
            url: x.layout.url
        }));
    const manLineKpis: ManLineKpi[] = joined
        .filter(x => x.data.type === "line")
        .map(x => {
            const res: ManLineKpi = {
                downtime: x.data.downtime,
                has_live_data: x.data.has_live_data,
                parts_current: x.data.parts_current,
                parts_produced: x.data.parts_produced,
                parts_total: x.data.parts_total,
                person: x.data.person,
                product: x.data.product,
                scrap: x.data.scrap,
                status: x.data.status,
                title: x.data.title,
                url: x.layout.url,
                workorder_is_late: x.data.workorder_is_late,
                x: x.layout.x,
                y: x.layout.y
            };
            return res;
        });

    return (
        // class "ql-dash-finalized" is used to determine when the dashboard rendering has been finalized
        // this is important for png and pdf rendering of reports
        <div className={"full-height ql-dash-finalized"}>
            {/* <header className={"header-small"}>
                <div className="container-fluid">
                    <nav className="navbar navbar-expand-md">
                        <Link to="/" className="navbar-brand">
                            <img src="/img/logo.svg" alt="qlector" className="logo" />
                        </Link>
                        <h1>{title}</h1>
                    </nav>
                </div>
            </header> */}
            <OverlayDash key={"overlay_" + hashKey} background_url={background_url}
                kpis={kpis} manLineKpis={manLineKpis} title={root.title}></OverlayDash>
        </div>
    )
}
