// @flow

import React from "react";
import SvgIcon, { type SvgIconProps } from "../SvgIcon";

const Home = (props: SvgIconProps) => (
    <SvgIcon {...props}>
        <svg viewBox="0 0 20.249 18">
            <path d="M17.993,10.111l.025,5.629a2.528,2.528,0,0,1-.018.285v.57A1.406,1.406,0,0,1,16.594,18h-.562c-.039,0-.077-.032-.116,0-.049-.028-.1,0-.148,0H13.781a1.406,1.406,0,0,1-1.406-1.406V13.5a1.124,1.124,0,0,0-1.125-1.125H9A1.124,1.124,0,0,0,7.875,13.5v3.094A1.406,1.406,0,0,1,6.469,18H4.5c-.053,0-.105,0-.158-.007S4.261,18,4.219,18H3.656A1.406,1.406,0,0,1,2.25,16.594V12.656c0-.032,0-.067,0-.1V10.111H1.127A1.114,1.114,0,0,1,0,8.982a1.142,1.142,0,0,1,.352-.844L9.366.282A1.013,1.013,0,0,1,10.139,0a1.183,1.183,0,0,1,.742.247L14.625,3.54c1.117,1,.006,0,3.375,2.964l1.856,1.635a.955.955,0,0,1,.387.844,1.136,1.136,0,0,1-1.125,1.129Z" />
        </svg>
    </SvgIcon>
);

export default Home;
