// @flow
import * as React from "react";
import ReactDOM from "react-dom";
import Tooltip from "../../../Tooltip";
import { getCursorValue } from "../reducers/properties";
import TranslatedMaterialTitle from "../../../TranslatedMaterialTitle";
import { translate } from "./../../../IntlProviderWrapper";
import { onMouseEnterDispatch } from "../Draggable/DraggableElements";

import type { GanttChartSourceTypes } from "../reducers/properties";

type MaterialTitlePhantomProps = {
    onPhantomLoaded: (computed_material_title: string, width: number) => void,
    material_title: string,
    width: number,
    x: number,
    y: number,
    should_truncate_title?: boolean,
    material_external_id: string
}

class TranslatedMaterialTitlePhantom extends React.Component<MaterialTitlePhantomProps, any> {

    ref = null;
    num_texts = 5;

    constructor(props: MaterialTitlePhantomProps) {
        super(props);
        this.ref = React.createRef()
        this.state = {
            computed_material_title: props.material_title
        }
    }

    componentDidMount() {
        this.computeMaterialTitle();
    }

    componentDidUpdate() {
        this.computeMaterialTitle();
    }

    unmountPhantom = (title: string, text_width: number) => {
        this.props.onPhantomLoaded(title, text_width);
        if (this.ref && this.ref.current) {
            ReactDOM.unmountComponentAtNode(this.ref.current);
        }
        return;
    }

    getMaterialTitle = () => {
        return TranslatedMaterialTitle({
            material_title: this.props.material_title,
            material_external_id: this.props.material_external_id
        });
    }

    computeMaterialTitle = () => {
        if (this.props.should_truncate_title) {
            return this.unmountPhantom(this.props.material_title, this.props.width);
        }
        let material_titles = []
        let text_widths = [];
        if (!this.ref || !this.ref.current) return this.unmountPhantom("", 0);
        const nodes = this.ref.current.childNodes;
        if (!nodes) return this.unmountPhantom("", 0);

        for (const node of nodes) {
            const text_width = node.getComputedTextLength();
            if (text_width < this.props.width - 10) {
                material_titles.push(node.innerHTML)
                text_widths.push(text_width);
            }
        }

        const max = Math.max(...text_widths); // Find the maximum number in the array
        const maxIndex = text_widths.indexOf(max); // Find the index of the maximum number
        if (maxIndex === -1) {
            return this.unmountPhantom("", 0);
        }
        const original_title = this.props.material_title
        const text_width = text_widths[maxIndex];
        const material_title = material_titles[maxIndex];
        if (original_title !== material_title) {
            this.unmountPhantom(material_title, text_width);
        } else {
            this.unmountPhantom(material_title, text_width);
        }
    }

    render() {
        const titles = [];
        for (let i = 0; i <= this.num_texts; i++) {
            const material_title = this.props.material_title;
            let title = material_title.slice(0, (this.props.material_title.length / this.num_texts) * (i + 1))
            if (title !== material_title) {
                title += "..."
            }
            titles.push(
                <text x={this.props.x} y={this.props.y} fontSize="12px" fontWeight="700" opacity={0}>
                    {title}
                </text>
            )
        }
        return <g ref={this.ref}>
            {titles}
        </g>
    }
}


export type MaterialTitleProps = {
    material_title: string,
    material_external_id: string,
    material: string,
    width: number,
    x: number,
    y: number,
    should_truncate_title?: boolean,
    tooltip_text?: React.Node,
    is_rescheduled: boolean,
    order_uuid: string,
    source_type: GanttChartSourceTypes,
    failed_scheduling: boolean,
    is_ctrl_down: boolean,
    is_translucent: boolean | null,
    order_rect_x: number,
    order_rect_y: number,
    order_width: number,
    earliest_end: number,
    shift_number: number,
    order_index: number,
    line_uuid: string,
    line_index: number,
    is_extra_line: boolean
}

type MaterialTitleState = {
    computed_material_title: string | null,
    width: number | null
}

export default class MaterialTitle extends React.Component<MaterialTitleProps, MaterialTitleState> {

    node = null;

    constructor(props: MaterialTitleProps) {
        super(props);
        this.node = React.createRef();
    }

    state: MaterialTitleState = {
        computed_material_title: null,
        width: null
    }

    componentDidUpdate(prev_props: MaterialTitleProps, prev_state: MaterialTitleState) {
        if (prev_props.width !== this.props.width) {
            this.setState({ computed_material_title: null, width: null });
        }
    }

    onPhantomLoaded = (title: string, text_width: number) => {
        this.setState({ computed_material_title: title, width: text_width });
    }

    renderMaterialTitle = () => {
        const order_uuid = this.props.order_uuid;
        const width = this.state.width;
        if (this.state.computed_material_title && width) {
            const cursor = getCursorValue({
                is_ctrl_down: this.props.is_ctrl_down,
                is_rescheduled: this.props.is_rescheduled,
                source_type: this.props.source_type
            });

            return <React.Fragment>
                {this.props.failed_scheduling && (
                    <Tooltip
                        content={<p className="font-weight-500 mb-0">
                            {translate("common.failed_scheduling", "Failed scheduling")}
                        </p>}
                    >
                        <text
                            fontSize="25px"
                            fontWeight="800"
                            fill="#e12d39"
                            fontStyle={{color: "#e12d39"}}
                            x={this.props.should_truncate_title === false ? this.props.x : (this.props.x - (width / 2))}
                            y={this.props.y + 5}
                            style={{ cursor, color: "#e12d39" }}
                            material-external-id={this.props.material_external_id}
                        >!</text>
                    </Tooltip>
                )}
                <Tooltip content={this.props.tooltip_text}>
                    <text
                        fontSize="12px"
                        fontWeight="700"
                        x={this.props.should_truncate_title === false ? this.props.x : (this.props.x - (width / 2))}
                        y={this.props.y}
                        style={{ cursor }}
                        order_rect_x={this.props.order_rect_x}
                        order_rect_y={this.props.order_rect_y}
                        order_width={this.props.order_width}
                        order_uuid={order_uuid}
                        order_index={this.props.order_index}
                        line_uuid={this.props.line_uuid}
                        material-external-id={this.props.material_external_id}
                        earliest_end={this.props.earliest_end}
                        line_index={this.props.line_index}
                        source_type={this.props.source_type}
                        is_extra_line={this.props.is_extra_line ? this.props.is_extra_line.toString() : undefined}
                        onMouseEnter={() => { onMouseEnterDispatch(order_uuid, { name: "material-title", order_uuid }); }}
                    >
                        {this.state.computed_material_title}
                    </text>
                </Tooltip>
            </React.Fragment>
        }
    }

    render() {
        const opacity = this.props.is_translucent ? 0.5 : 1;
        return (
            <g id="material-title" order-uuid={this.props.order_uuid} opacity={opacity}>
                {this.state.computed_material_title === null && <TranslatedMaterialTitlePhantom
                    key={this.props.material_external_id}
                    ref={this.node}
                    onPhantomLoaded={this.onPhantomLoaded}
                    width={this.props.width}
                    x={this.props.x}
                    y={this.props.y}
                    should_truncate_title={this.props.should_truncate_title}
                    material_external_id={this.props.material_external_id}
                    material_title={this.props.material_title}
                />}
                {this.renderMaterialTitle()}
            </g>
        )
    }
}
