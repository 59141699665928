// @flow

import React from "react";
import Select from "react-select";
import { connect } from "react-redux";
import { SET_SELECTED_MATERIAL_TAG_VALS, RESET_FILTERS } from "../../reducers/reducersFilters/filters";

import type { SetSelectedMaterialTagValsAction, ResetFiltersAction } from "../../reducers/reducersFilters/filters";
import type { ReduxState } from "../../reducers/reducers";
import type { IMaterialModel } from "../../../../../lib/backend/manufacturing2.generated.types";

export type SelectOption = {
    label: string,
    value: string
};

type Props = {
    material_tag: string,
    materials: IMaterialModel[],
    palceholder: string,
    reduxDispatch: (args: SetSelectedMaterialTagValsAction | ResetFiltersAction) => void,
    selected_material_tag_values: string[]
};

type State = {};

const replaceSpaces = (text: string = "") => {
    return text
        .split(" ")
        .filter(v => v.trim())
        .join(" ");
};

const filterOption = (candidate: { label: string, value: string, data: any }, input: string) => {
    if (input) {
        return (
            replaceSpaces(candidate.label).toLowerCase().includes(replaceSpaces(input).toLowerCase()) ||
            candidate.value === (input || "").trim()
        );
    }
    return true;
};

// TODO - when reschedule order or line is enabled then we need to reset the filter
class MaterialTagFilter extends React.Component<Props, State> {
    componentDidMount() {
        const data = {
            selected_material_tag: this.props.material_tag,
            selected_material_tag_values: []
        };
        this.props.reduxDispatch({ type: SET_SELECTED_MATERIAL_TAG_VALS, data });
    }

    getOptions = (): SelectOption[] => {
        const material_tag_value_set = new Set();
        const options: SelectOption[] = [];
        for (const material of this.props.materials) {
            const material_tag_value = material.tags[this.props.material_tag];
            if (material_tag_value !== undefined && !material_tag_value_set.has(material_tag_value)) {
                material_tag_value_set.add(material_tag_value);
                options.push({
                    label: material_tag_value,
                    value: material_tag_value
                });
            }
        }

        return options;
    };

    getSelectedOptions = (values: string[] | null) => {
        if (values === null) {
            return null;
        }

        return this.getOptions().filter(el => values !== null && values.includes(el.value));
    };

    handleChange = (option?: SelectOption) => {
        if (!option) {
            this.props.reduxDispatch({ type: RESET_FILTERS, data: undefined });
        } else {
            const data = {
                selected_material_tag: this.props.material_tag,
                selected_material_tag_values: [option.value]
            };
            this.props.reduxDispatch({ type: SET_SELECTED_MATERIAL_TAG_VALS, data });
        }
    };

    render() {
        return (
            <div className="material-tag-filter">
                <Select
                    value={this.getSelectedOptions(this.props.selected_material_tag_values)}
                    placeholder={this.props.palceholder}
                    options={this.getOptions()}
                    onChange={this.handleChange}
                    isSearchable={true}
                    isClearable
                    filterOption={filterOption}
                />
            </div>
        );
    }
}

export default connect(
    (state: ReduxState, props: Props) => {
        const selected_material_tag_values = state.gantt_chart_filters.selected_material_tag_values;
        const materials = state.gantt_chart.materials;

        return {
            selected_material_tag_values,
            materials
        };
    },
    dispatch => ({ reduxDispatch: dispatch })
)(MaterialTagFilter);
