// @flow
import {
    shiftNumber,
    toISODateString
} from "../../lib/Util";

import type {
    // Insight,
    ShiftNumber
} from "../../lib/Models";

import * as t from "../../lib/backend/manufacturing2.generated.types";

export const filterInsightsByStateCriteria = (insight: t.IEventDataEx, selected_line_uuids: any, selected_insight_types: any) => {
    let isLineOK = true;
    if (insight.tags.line_uuid) {
        isLineOK = selected_line_uuids[insight.tags.line_uuid];
    } else if (insight.tags.line_uuids) {
        const lines = insight.tags.line_uuids.split(",").map(x => x.trim());
        const lines2 = lines.filter(x => selected_line_uuids[x]);
        isLineOK = lines2.length > 0;
    }

    const isInsightTypeOK = selected_insight_types[insight.type];
    return isLineOK && isInsightTypeOK;
}

export const getDateClass = (ts: number) => {
    const currentDateStr = toISODateString(new Date());
    const dateStr = toISODateString(new Date(ts));
    let dateClass;

    if (dateStr < currentDateStr) {
        dateClass = "future-date";
    } else if (dateStr > currentDateStr) {
        dateClass = "future-date";
    } else {
        dateClass = "current-date";
    }

    return dateClass;
}

export const highlightCurrShift = (shift_obj: ShiftNumber) => {
    const curr_shift_obj = shiftNumber(new Date());

    if ((shift_obj.shift === curr_shift_obj.shift) &&
        (shift_obj.week === curr_shift_obj.week) &&
        (shift_obj.year === curr_shift_obj.year)) {
        return "current-shift";
    } else {
        return "";
    }
}
