// @flow

// main imports
import React, { Component } from "react";
import ReactRouterPropTypes from "react-router-prop-types";

// backend
import { getBackend } from "../../lib/backend/Backend2";
import { stringCompare } from "../../lib/Util";
import * as t from "../../lib/backend/manufacturing2.generated.types";
import {
    waitForManufacturingAsync, getLineGroupsForUser, filterLinesForUser
} from "../../lib/BusinessLogic";

// import subcomponents
import ErrorComponent from "../ErrorComponent";
import ProcessesList from "./ProcessesList";
import ProcessesDetails from "./ProcessesDetails";
import { translate } from "../IntlProviderWrapper";
import { ResourcesLayout } from "../MainContent";

// defining types
type Props = {
    match: ReactRouterPropTypes.match,
    history: ReactRouterPropTypes.history,
    location: ReactRouterPropTypes.location
}

export type FilterLine = {
    title: string,
    uuid: string,
    external_id: string,
    plant_external_id: string,
    plant_uuid: string,
    plant_title: string,
    line_group_title: string,
}

type State = {
    processes: t.IProcessData[],
    lines: FilterLine[],
    error: string,
    warning: string,
    line_uuid: string,
    selected: t.IProcessData | null,
    title_filter: string,
    plant_uuid: string,
    only_bottleneck: boolean
}

/**
 * Displaying processes list.
 */
class Processes extends Component<Props, State> {
    processesListRef: { current: null | ProcessesList };

    /**
     * Constructor.
     * @param {Props} props Props object.
     */
    constructor(props: Props) {
        super(props);
        const state: State = {
            lines: [],
            processes: [],
            error: "",
            warning: "",
            line_uuid: "",
            only_bottleneck: false,
            selected: null,
            title_filter: "",
            plant_uuid: ""
        };
        this.state = state;
        this.processesListRef = React.createRef();
    }

    /**
     * Actual loading of data from backend.
     */
    async loadComponent() {
        try {
            // prepare map of line-group names for each line
            await waitForManufacturingAsync();
            const line_groups = getLineGroupsForUser();
            const lines = filterLinesForUser(
                (await getBackend().manufacturing.getLines({})).lines, true
            );

            const line_uuid_to_group_titles: Map<string, string[]> = new Map();
            for (const line_group of line_groups) {
                for (const line_uuid of line_group.line_uuids) {
                    const group_titles = line_uuid_to_group_titles.get(line_uuid);
                    if (group_titles !== undefined) {
                        group_titles.push(line_group.title);
                        line_uuid_to_group_titles.set(line_uuid, group_titles);
                    } else {
                        line_uuid_to_group_titles.set(line_uuid, [line_group.title]);
                    }
                }
            }

            const line_uuid_to_group_title: Map<string, string> = new Map();
            for (const line of lines) {
                const line_groups = line_uuid_to_group_titles.get(line.uuid);
                const line_groups_title = (line_groups !== undefined) ? line_groups.join(", ") : "/";
                console.log(line_groups_title);
                line_uuid_to_group_title.set(line.uuid, line_groups_title);
            }

            // sort lines
            const lines_sorted: FilterLine[] = lines.map(line => {
                const line_group_title = line_uuid_to_group_title.get(line.uuid);
                return {
                    title: line.title,
                    uuid: line.uuid,
                    external_id: line.external_id,
                    plant_external_id: line.plant_external_id,
                    plant_uuid: line.uuid,
                    plant_title: line.plant_title,
                    line_group_title: line_group_title !== undefined ? line_group_title : ""
                };
            });
            lines_sorted.sort((a, b) => {
                const a_title = a.plant_title + a.line_group_title + a.title;
                const b_title = b.plant_title + b.line_group_title + b.title;
                return stringCompare(a_title, b_title);
            });

            // select first line on the list
            let line_uuid = this.state.line_uuid;
            if (line_uuid === "") {
                if (lines.length > 0) {
                    line_uuid = lines[0].uuid;
                }
                await this.loadProcesses(line_uuid, this.state.only_bottleneck);
            }

            this.setState({
                lines: lines_sorted,
                line_uuid: line_uuid
            });
        } catch (err) {
            this.setState({ error: err.message });
        }
    }

    /**
     * Loading of processes data for a particular production line.
     * @param {string} line_uuid Line uuid.
     */
    async loadProcesses(line_uuid: string, only_bottleneck: boolean) {
        try {
            // get materials
            const res = await getBackend().manufacturing.getProcesses({ line_uuid, only_bottleneck });
            this.setState({
                processes: res.processes,
                selected: this.selectProcess(this.state.title_filter, line_uuid, res.processes),
                error: "",
                warning: ""
            })
        } catch (err) {
            this.setState({ error: err.message });
        };
    }

    /**
     * Select the first process that matches all the given criteria.
     * @param {string} contentFilter Process search term.
     * @param {string} lineUuid Line UUID.
     * @param {t.IProcessData[]} [processes] List of processes to select from.
     */
    selectProcess(contentFilter: string, lineUuid: string, processes: t.IProcessData[] = this.state.processes): t.IProcessData | null {
        for (let process of processes) {
            const visible = this.processesListRef.current && this.processesListRef.current.includes(contentFilter, process);

            if ((lineUuid === process.line_uuid || lineUuid === "") && visible) {
                return process;
            }
        }
        return null;
    }

    /**
     * Handling any change in input components.
     */
    handleInputChange = (event: Event) => {
        if (event.currentTarget instanceof HTMLInputElement || event.currentTarget instanceof HTMLSelectElement) {
            // read new values
            const target = event.currentTarget;
            const value = target.value;
            const name = target.name;

            // update new datasource placeholder
            this.setState(prev_state => {
                prev_state[name] = value;
                return prev_state;
            });

            if (name === "line_uuid") {
                this.loadProcesses(value, this.state.only_bottleneck);
                this.props.history.push("/digital-twin/resources/processes");
            } else if (name === "title_filter") {
                this.setState({
                    selected: this.selectProcess(value, this.state.line_uuid)
                });
            }
        }
    };

    /**
     * Handling click on a particular process. Remembering the selected process.
     *
     * @param {t.IProcessData} process
     */
    handleProcessClick(process: t.IProcessData) {
        this.setState({ selected: process });

        // update url with each click
        let location: string = this.props.location.pathname;
        if (location.indexOf("processes/") !== -1) {
            // if parameter is already given, change only the parameter in the path
            this.props.history.push(process.uuid);
        } else {
            // if parameter is not given, add "process"
            this.props.history.push("processes/" + process.uuid);
        }
    }

    /**
     * Mounting data for this component.
     */
    componentDidMount() {
        this.loadComponent();
    }


    /**
     * Handling error from subordinate component.
     */
    handleToError = (str: string) => {
        this.setState({ error: str });
    }

    /**
     * Handling production line submit event.
     */
    handleSubmit = (e: Event) => {
        e.preventDefault();
    }

    /**
     * Handling toggling bottleneck component.
     */
    handleBottleneckToggle = (e: Event) => {
        const target = e.target;
        if (target instanceof HTMLInputElement) {
            const curr = target.checked;
            this.setState({ only_bottleneck: curr });
            let line_uuid = this.state.line_uuid;
            this.loadProcesses(line_uuid, curr);
        }
    }

    /**
     * Rendering JSX for current component.
     */
    render() {
        return (
            <ResourcesLayout>
                <ResourcesLayout.Main>
                    <ResourcesLayout.List>
                        <ResourcesLayout.Search>
                            <div className="form-group form-group-stretch">
                                <input type="text" className="form-control search_bar" placeholder={translate("common.search", "Search") + " ..."}
                                    name="title_filter" value={this.state.title_filter} onChange={this.handleInputChange} />
                            </div>
                            <div className="form-group">
                                <form onSubmit={this.handleSubmit}>
                                    <select name="line_uuid" className="form-control select_top_control" value={this.state.line_uuid} onChange={this.handleInputChange}>
                                        {this.state.lines.map((line, i) => {
                                            return <option key={i} value={line.uuid}>{line.plant_title} - {line.line_group_title} - {line.title}</option>
                                        })}
                                    </select>
                                </form>
                            </div>
                            {/* <div className="form-group" >
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="tooltip-only-bottleneck">Only bottleneck</Tooltip>}
                                >
                                    <div>
                                        <Toggle checked={this.state.only_bottleneck}
                                            onChange={this.handleBottleneckToggle} className="vertical-align-middle" />
                                    </div>
                                </OverlayTrigger>
                            </div> */}
                        </ResourcesLayout.Search>
                        <ProcessesList
                            ref={this.processesListRef}
                            processes={this.state.processes}
                            selected={this.state.selected}
                            filter={this.state.title_filter}
                            handleProcessClick={(process) => { this.handleProcessClick(process); }}
                        />
                    </ResourcesLayout.List>
                    <ResourcesLayout.Content>
                        <ErrorComponent msg={this.state.error} type="error" />
                        <ErrorComponent msg={this.state.warning} type="warning" />
                        <ProcessesDetails
                            selected={this.state.selected}
                        />
                    </ResourcesLayout.Content>
                </ResourcesLayout.Main>
            </ResourcesLayout>
        )
    }
}

export default Processes;
