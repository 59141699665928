// @flow
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import ReactRouterPropTypes from "react-router-prop-types";
import { translate } from "../../../IntlProviderWrapper";
import * as rt from "../../../../lib/SimulationReportModels";
import * as t from "../../../../lib/backend/reports.generated.types";
import * as BusinessLogic from "../../../../lib/BusinessLogic";
import * as t2 from "../../../../lib/backend/manufacturing2.generated.types";
import Order from "./Order";
import { SET_MAX_LINE_LABEL_LENGTH, GanttChartSource } from "../reducers/properties";
import {
    orderColor,
    TIME_RANGES,
    dateFromShiftTime,
    shiftTag,
    shiftNumber
} from "../../../../lib/Util";
import TranslatedMaterialTitle from "../../../TranslatedMaterialTitle";
import { SET_RESCHEDULE_LINE_UUID } from "../reducers/common";
import { RESET_FILTERS } from "../reducers/reducersFilters/filters";
import { ORDER_TAGS_ACCESS, PLANT_TAGS_ACCESS } from "../../../../lib/ManufacturingTags.generated";
import { ORDER_STATUS } from "../../../../lib/ManufacturingConsts.generated";
import { ReduxFilters, PropertiesLogic } from "../reducers/BusinessLogic";
import { TypeFilterValues } from "../lineOrderList/filters/TypeFilterConsts";
import { ValidLinesOrdersHelper } from "../reducers/ValidLinesOrdersHelper";
import ReworkOrderLinks from "./ReworkOrderLinks";
import * as srm from "../../../../lib/SimulationReportModels";

import type { ReworkParams } from "./ReworkOrderLinks";
import type { SetRescheduleOrderAction } from "../reducers/common";
import type { LineOrders } from "../reducers/linesOrders"
import type { PropertiesState, SetMaxLineLabelLengthAction, GanttChartSourceTypes } from "../reducers/properties";
import type { InsightsMapped } from "../reducers/insights";
import type { OrderRectangleAttributes } from "./Order";
import type { MaterialTitleProps } from "./MaterialTitle";
import type { ValidOrder } from "../reducers/common";
import type { ReduxState } from "../reducers/reducers";
import type { ResetFiltersAction } from "../reducers/reducersFilters/filters";
import type { SetRescheduleLineAction, SetRescheduleOrderBundleAction } from "../reducers/common";
import type { AvailableInsightTypes } from "../reducers/ganttChartStandalone";
import type { SetLastClickedOrderUuid, SetHighlightedOrders } from "../reducers/planningTable";
import type { ITags } from "../../../../lib/Models";

type LineLabelProps = {
    line_uuid: string,
    line_title: string,
    line_index: number,
    properties: PropertiesState,
    history: ReactRouterPropTypes.history,
    line_offset: number,
    is_filter_locked: boolean,
    is_reschedule_line: boolean,
    is_unscheduled: boolean,
    order_uuid?: string,
    reduxDispatch: (
        args: SetMaxLineLabelLengthAction | SetRescheduleLineAction |
        SetRescheduleOrderAction | ResetFiltersAction | SetLastClickedOrderUuid |
        SetHighlightedOrders | SetRescheduleOrderBundleAction
    ) => void,
    source_type: GanttChartSourceTypes,
    current_downtimes: t2.ICurrentDowntime[],
    is_workorder?: boolean,
    line_external_id?: string,
    original_report_created_at: number,
    last_clicked_order_uuid: string,
    reschedule_order_uuid: string
}

type LineLabelState = {};

class LineLabel extends React.Component<LineLabelProps, LineLabelState> {

    node = null;

    constructor(props) {
        super(props);
        this.node = React.createRef();
    }

    componentDidMount() {
        if (this.node && this.node.current) {
            this.props.reduxDispatch({
                type: SET_MAX_LINE_LABEL_LENGTH,
                data: this.node.current.getComputedTextLength()
            });
        }
    }

    onClick = async () => {
        if (this.props.properties.source_type === GanttChartSource.gantt) {
            this.props.history.push(`/manufacturing/lines/microplan/${this.props.line_uuid}`);
        } else {
            // clicking on label of unscheduled order is same as clicking on order
            const order_uuid = this.props.order_uuid;
            if (this.props.is_unscheduled && order_uuid) {
                ReduxFilters.setRescheduleOrderUuid(this.props.reduxDispatch, order_uuid, "gantt", this.props.is_filter_locked);
            } else if (!this.props.is_filter_locked) {
                // only works when filter is not locked
                if (this.props.is_reschedule_line) {
                    this.props.reduxDispatch({ type: RESET_FILTERS, data: undefined });
                } else {
                    this.props.reduxDispatch({ type: SET_RESCHEDULE_LINE_UUID, data: this.props.line_uuid });
                }
            }
        }
    }

    render() {
        const line_uuid = this.props.line_uuid;
        let title = this.props.line_title;

        const properties: PropertiesState = this.props.properties;
        const source_type = properties.source_type;
        const title_len = title.length;
        const max_title_len = Math.floor(properties.space_for_label / 8.8); // 8.8 = 220/25
        let tooltip_title = title;

        if (this.props.line_external_id !== undefined && this.props.line_external_id !== "") {
            tooltip_title += " [" + this.props.line_external_id + "]";
        }

        if (title_len > max_title_len) {
            const last_word = title.substr(max_title_len - 6, title_len - 1);
            const last_chars = last_word.slice(-3);
            title = title.substr(0, max_title_len - 6) + "..." + last_chars;
        }

        const y0 = this.props.line_offset;

        const is_droppable = ReduxFilters.isDroppableLineUuid(
            this.props.line_uuid,
            this.props.last_clicked_order_uuid,
            this.props.is_unscheduled
        );

        let stroke = null;
        if (this.props.is_reschedule_line && !this.props.is_unscheduled || is_droppable) {
            stroke = "#616e7c";
        }

        let fill = null;
        const clicked_order_uuid = this.props.last_clicked_order_uuid || this.props.reschedule_order_uuid;
        if (clicked_order_uuid && !this.props.is_unscheduled) {
            const line_uuids = ValidLinesOrdersHelper.getPreferredLineUuids(clicked_order_uuid);
            if (line_uuids && line_uuids.includes(this.props.line_uuid)) {
                fill = "#FFD700"
            }
        }

        if (source_type === GanttChartSource.gantt) {
            tooltip_title = translate("common.tooltip_planningtable_line_gotomicroplan", "Go to Microplan for line") + ` ${tooltip_title}`;
        }

        // show downtimes only when we have a line name as well
        let downtime_severity = 0;
        const current_downtime_idx = this.props.current_downtimes.findIndex(downtime => downtime.line_uuid === line_uuid);
        if (current_downtime_idx !== -1) {
            const current_downtime = this.props.current_downtimes[current_downtime_idx];
            downtime_severity = current_downtime.severity;
            tooltip_title += `\n--\n${current_downtime.description}`;
        }
        const is_downtime = !this.props.is_unscheduled && downtime_severity > 0 && title.length > 0;

        return <g key={line_uuid} name="line-label" line-index={this.props.line_index} line-uuid={line_uuid}>
            {is_downtime ?
                (<circle
                    cx={7}
                    cy={y0 + 15}
                    r={5}
                    fill={downtime_severity > 1 ? "#F00" : "#FFA500"}>
                    <title>{tooltip_title}</title>
                </circle>) :
                null}
            {
                fill && <rect
                    x={0}
                    y={y0}
                    width={properties.max_line_label_length}
                    height={properties.bar_height}
                    fill={fill}
                >
                </rect>
            }
            <text
                key={line_uuid + "-text-" + (stroke || "")}
                id={this.props.is_unscheduled ? "unscheduled-line-title" : "line-title"}
                stroke={stroke}
                ref={this.node}
                textAnchor="left"
                fontFamily="Arial"
                style={{
                    fill: this.props.is_workorder ? "red" : "#616e7c",
                    cursor: "pointer",
                    fontSize: "14px"
                }}
                line_uuid={line_uuid}
                line_title={title}
                line_index={this.props.line_index}
                x={is_downtime ? 15 : 0}
                y={y0 + 20}
                onClick={this.onClick}
                title={tooltip_title}
            >
                {title}
                <title>{tooltip_title}</title>
            </text>
        </g>
    }
}

export const ConnectedLineLabel = withRouter(connect(
    (state: ReduxState, own_props: LineLabelProps) => {
        const current_downtimes = state.gantt_chart_insights.current_downtimes;
        const planning_table = state.gantt_chart_planning_table;

        const filters = state.gantt_chart_filters;
        const is_reschedule_line =
            filters.selected_filter_type === TypeFilterValues.reschedule_line &&
            filters.reschedule_line_uuid === own_props.line_uuid;

        return {
            is_reschedule_line,
            last_clicked_order_uuid: planning_table.last_clicked_order_uuid,
            source_type: state.gantt_chart_properties.source_type,
            current_downtimes,
            original_report_created_at: state.gantt_chart_report.original_report_created_at,
            is_filter_locked: filters.is_filter_locked,
            reschedule_order_uuid: filters.reschedule_order_uuid
        }
    },
    (dispatch) => ({ reduxDispatch: dispatch })
)(LineLabel));

const invalidProperties = (properties: PropertiesState) => {
    const {
        current_shift,
        week,
        year,
        xscale
    } = properties;

    if (current_shift === null) {
        return console.error("Line.jsx, invalidProperties: Current shift is missing");
    }

    if (week === null) {
        return console.error("Line.jsx, invalidProperties: week is missing");
    }

    if (year === null) {
        return console.error("Line.jsx, invalidProperties: year is missing");
    }

    if (xscale === null) {
        return console.error("Line.jsx, invalidProperties: xscale is missing");
    }
}


type LineProps = {
    line: rt.ISimulationReportOrderLineEx,
    shifts: t.ISimulationReportOrderLineShiftFeature,
    properties: PropertiesState,
    original_report_created_at: number | null,
    handleOrderClick: (e: Event) => any,
    line_index: number,
    line_uuid: string,
    insights_mapped: InsightsMapped,
    insights: t2.IEventDataEx[],
    line_offset: number,
    valid_orders_map: Map<string, ValidOrder> | null,
    is_parallel_line: boolean,
    source_type: GanttChartSourceTypes,
    is_extra_line: boolean,
    filtered_insight_types: AvailableInsightTypes[],
    line_orders?: LineOrders,
    line_external_id?: string,
    report_data: srm.IReportModelEx | null,
    orders: t2.IOrderModelBase[]
}

class Line extends React.PureComponent<LineProps, any> {

    renderOrders = () => {
        // components
        let Orders: Order[] = [];

        const properties: PropertiesState = this.props.properties;
        const source_type = properties.source_type;

        const orders = this.props.line.production;
        const bars_start = this.props.properties.bars_start;

        const { show_only_tool_changes }: PropertiesState = properties;
        const y0 = this.props.line_offset;
        const report_data = this.props.report_data;
        if (invalidProperties(properties) || !report_data) {
            return null;
        }
        const next_shift_time = report_data.result.next_shift_time;
        const from_shift_time_ts = dateFromShiftTime(report_data.result.from_shift_time).getTime();
        const next_shift_time_ts = dateFromShiftTime(report_data.result.next_shift_time).getTime();
        const next_shift_time_h = (next_shift_time_ts - from_shift_time_ts) / TIME_RANGES.HOUR;
        const shift_tag_index = new Map<string, number>();
        const shifts = this.props.shifts.shifts;

        for (let i=0; i < shifts.length; i++) {
            const shift_time = shifts[i].shift_time;

            const shift_tag = shiftTag(shift_time.year, shift_time.week, shift_time.shift_number);
            shift_tag_index.set(shift_tag, i);
        }
        // prepare map of all orders from valid line if we have it
        const valid_orders_map: Map<string, ValidOrder> = this.props.valid_orders_map || new Map();
        const all_orders_map: Map<string, ReworkParams> = new Map();
        const rework_orders = [];

        const base_orders_map: Map<string, t2.IOrderModelBase> = new Map(
            this.props.orders.map(order => [order.uuid, order])
        );

        const plant_tags = BusinessLogic.getPlantTags(this.props.line.plant);
        const display_system_status_released_false =
            PLANT_TAGS_ACCESS.planning_table_display_system_status_released(plant_tags);

        for (let i = 0; i < orders.length; i++) {
            const xscale = properties.xscale;
            if (!xscale) {
                console.error("Xscale not set");
                return null;
            }

            const order = orders[i];
            const estimated_start = order.estimated_start[1].val;
            const estimated_completion = order.estimated_completion[1].val;
            const estimated_end_ts = from_shift_time_ts + (estimated_completion * TIME_RANGES.HOUR);

            let estimated_tool_setup_end = 0;
            if (order.estimated_tool_setup_end) {
                estimated_tool_setup_end = order.estimated_tool_setup_end[1].val;
            }

            // dont display work orders with estimated_diration == 0
            if (order.estimated_duration[1].val === 0 &&
                estimated_start === estimated_completion) {
                continue;
            }
            if (show_only_tool_changes && estimated_tool_setup_end === estimated_start) {
                continue;
            }

            // start of the order in hours
            let time_start = estimated_start;

            // beginning of the order in pixels
            let x0 = bars_start + xscale(time_start);

            // length of an order in pixels
            // ending of an order in hours
            let time_end = (show_only_tool_changes ? estimated_tool_setup_end : estimated_completion);
            let w = xscale(time_end - time_start);

            // middle of an order in pixels
            let x1_2 = x0 + w / 2;
            // counter of full shifts inside of an order

            let line_order = null;
            const lines_orders = this.props.line_orders;
            if (lines_orders) {
                line_order = lines_orders.orders.find(o => o.uuid === order.order_id);
            }

            const has_insight = this.props.insights_mapped.has(order.order_id);
            const insights = this.props.insights_mapped.get(order.order_id);

            let order_tags: ITags = {};
            if (line_order) {
                order_tags = line_order.tags;
            } else {
                const base_order = base_orders_map.get(order.order_id);
                if (base_order !== undefined) {
                    order_tags = base_order.tags;
                }
            }

            const is_unreleased = !ORDER_TAGS_ACCESS.system_status_released(order_tags) &&
                display_system_status_released_false;
            const order_color = orderColor({
                order_type: order.order_type,
                has_insight,
                failed_scheduling: order.failed_scheduling,
                filtered_insight_types: this.props.filtered_insight_types,
                insights,
                tags: order_tags,
                is_unreleased
            });

            // iterate all shifts of order and draw a rect for each shift
            let end_shift_number = 0;

            let is_translucent = false;
            if (PropertiesLogic.isPlanningTable(this.props.source_type)) {
                const valid_order = valid_orders_map.get(order.order_id);
                is_translucent = valid_order && valid_order.is_translucent;
            }

            const insight_tooltips = (this.props.insights_mapped.get(order.order_id) || []).map(
                insight => BusinessLogic.getInsightTooltipContent(insight));
            const insight_hash = (this.props.insights_mapped.get(order.order_id) || []).map(
                insight => insight.tags["hash"] || "").join(',');

            let history_x = null;
            if (estimated_start < next_shift_time_h && estimated_completion > next_shift_time_h) {
                history_x = bars_start + xscale(next_shift_time_h);
            } else if (estimated_start < next_shift_time_h) {
                history_x = bars_start + xscale(estimated_completion);
            }

            const order_closed = order.order_status !== ORDER_STATUS.open;
            const is_planned = order.order_type === "plan";
            let fill_opacity;
            if (is_planned) {
                fill_opacity = 0.3;
            } else if (order_closed || is_translucent) {
                fill_opacity = 0.1;
            }
            const order_rectangle_attributes: OrderRectangleAttributes = {
                x: x0,
                y: y0,
                rx: 0,
                ry: 0,
                width: w,
                height: properties.bar_height,
                fill: order_color,
                order,
                has_insight,
                insight_tooltips,
                insight_hash,
                line_uuid: this.props.line.line,
                order_uuid: order.order_id,
                source_type,
                insight_freeze_order: order.insight_freeze_order,
                earliest_end: estimated_end_ts,
                estimated_start,
                order_index: i,
                line_index: this.props.line_index,
                is_extra_line: this.props.is_extra_line,
                is_filter_highlighted: false,
                production_version: ORDER_TAGS_ACCESS.production_version(order_tags),
                history_x,
                fill_opacity
            };
            /* REWORK ORDERS FEATURE */
            all_orders_map.set(
                order.order_id,
                {
                    x: x0 + w / 2,
                    y: (y0 + properties.bar_height),
                    width: w,
                    order_uuid: order.order_id
                }
            );

            const is_linked_operation = ORDER_TAGS_ACCESS.linked_operation(order_tags) !== "";
            if (line_order && is_linked_operation) {
                rework_orders.push(line_order);
            }

            const material_title_attributes: $Shape<MaterialTitleProps> = {
                x: x1_2,
                y: y0 + properties.bar_height / 2 + 5,
                material_title: TranslatedMaterialTitle({
                    material_title: order.material_title,
                    material_external_id: order.material
                }),
                width: w,
                order_uuid: order.order_id,
                source_type,
                material_external_id: order.material,
                failed_scheduling: order.failed_scheduling,
                is_translucent,
                order_rect_x: x0,
                order_rect_y: y0,
                order_width: w,
                earliest_end: time_end,
                order_index: i,
                line_index: this.props.line_index,
                line_uuid: this.props.line.line,
                source_type,
                is_extra_line: this.props.is_extra_line
            }

            const first_shift = shiftNumber(new Date(from_shift_time_ts + (estimated_start * TIME_RANGES.HOUR)));
            const first_shift_tag = shiftTag(first_shift.year, first_shift.week, first_shift.shift);
            const last_shift = shiftNumber(new Date(estimated_end_ts));
            const last_shift_tag = shiftTag(last_shift.year, last_shift.week, last_shift.shift);
            const from_index = shift_tag_index.get(first_shift_tag);
            if (from_index === undefined) {
                continue;
            }

            let to_index = shift_tag_index.get(last_shift_tag);
            if (to_index === undefined) {
                to_index = shifts.length - 1;
            }

            const disabled_shifts = shifts.slice(from_index, to_index).filter(shift => !shift.enabled);

            Orders.push(<Order
                key={i}
                order_uuid={order.order_id}
                order_index={i}
                order_type={order.order_type}
                line_uuid={this.props.line.line}
                line_index={this.props.line_index}
                current_shift_number={next_shift_time.shift_number}
                order_color={order_color}
                material_title={order.material_title}
                onClick={this.props.handleOrderClick}
                order_rectangle_attributes={order_rectangle_attributes}
                material_title_attributes={material_title_attributes}
                last_shift_number={end_shift_number}
                is_parallel_line={this.props.is_parallel_line}
                earliest_start={order.earliest_start}
                source_type={source_type}
                sim_ignore_earliest_start={order.sim_ignore_earliest_start}
                order_process_num={order.process_num}
                material_external_id={order.material}
                disabled_shifts={disabled_shifts}
                is_linked_operation={is_linked_operation}
            />)
        }

        return <React.Fragment>
            {Orders}
            <ReworkOrderLinks
                rework_orders={rework_orders}
                all_orders_map={all_orders_map}
            />
        </React.Fragment>
    }


    render() {
        return <g id="gantt-chart-line-group" line-uuid={this.props.line_uuid} line-index={this.props.line_index}>
            {this.renderOrders()}
        </g>
    }
}

export default Line;
