// @flow
import React from "react";
import { connect } from "react-redux";

import type { ReduxState } from "./reducers/reducers";

export type CursorKeys = "not-allowed" | "copy" | "cell" | "move";

type Props = {
    reschedule_in_progress: boolean
};
type State = {
    key: CursorKeys | null,
    style: string,
    date_changed_ts: number | null
};

export const SET_MOUSE_CURSOR = "SET_MOUSE_CURSOR";

class MouseCursor extends React.PureComponent<Props, State> {

    state = {
        key: null,
        style: "",
        date_changed_ts: null
    }

    mouse_cursor_event = null;

    componentDidUpdate(prev_props: Props) {
        if (prev_props.reschedule_in_progress !== this.props.reschedule_in_progress &&
            !this.props.reschedule_in_progress) {
            this.resetCursor();
        }
    }

    resetCursor = () => {
        this.setState({ key: null, style: "", date_changed_ts: Date.now() });
    }

    componentDidMount() {
        this.mouse_cursor_event = (e) => this.onSetMouseCursor(e);
        document.addEventListener(SET_MOUSE_CURSOR, this.mouse_cursor_event);

    }

    componentWillUnmount() {
        if (this.mouse_cursor_event) {
            document.removeEventListener(SET_MOUSE_CURSOR, this.mouse_cursor_event);
        }
    }

    onSetMouseCursor = (e: Event) => {
        // $FlowFixMe
        const { key } = e.detail;

        if (this.state.key === key) {
            return;
        }

        let style = "";
        if (key === "not-allowed") {
            style = "body * { cursor: not-allowed !important; }";
        } else if (key === "copy") {
            style = "body * { cursor: copy !important; }";
        } else if (key === "cell") {
            style = "body * { cursor: cell !important; }";
        } else if (key === "move") {
            style = "body * { cursor: move !important; }";
        }
        this.setState({
            key,
            style,
            date_changed_ts: Date.now()
        });
    }

    render() {
        return <style id="mouse-cursor" key={this.state.date_changed_ts} type="text/css">
            {this.state.style}
        </style>;
    }
}

export default connect(
    (state: ReduxState) => {
        const reschedule_in_progress = !!state.gantt_chart_filters.reschedule_order_uuid
        return { reschedule_in_progress }
    },
    null
)(MouseCursor);