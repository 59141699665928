// @flow
import * as React from "react";
import { Fragment } from "react";

type Props = {
    panels: Array<Object>
};
type State = { };

class Content extends React.Component<Props, State> {
    constructor() {
        super();
        this.state = { };
    }

    render() {
        let panels = this.props.panels.map((panel, i) => {
            return <Fragment key={i}>{panel}</Fragment>;
        });
        return (<div className="dsh_content">{panels}</div>);
    }
}

export default Content;
