// @flow
import { getBackend } from "./Backend";
import { getBackend as getBackend2 } from "./backend/Backend2";
import { encodeUrlQuery } from "./Util";

/** Use parse url query params using `parseUrlQueryVariables()` from Utils */
export function resolveAka(params: any, done: (url: string) => void, err: (str: string) => void) {
    const type = params.type || "";
    switch (type) {
        case "dashboard": getDashboard(params, done, err); break;
        case "man_material": getManMaterial(params, done, err); break;
        case "man_line": getManLine(params, done, err); break;
        case "man_plant": getManPlant(params, done, err); break;
        default: err("Unknown redirect type: " + type); break;
    }
}

/** Find dashboard that best matches given parameters. */
function getDashboard(params: any, done: (url: string) => void, err: (str: string) => void) {
    const backend = getBackend();
    const dash_backend = backend.dash;
    const default_url = "/dashboards";
    if (params.uuid) {
        dash_backend.getDashboards(
            (dash_list) => {
                const hits = dash_list
                    .filter(x => x.uuid === params.uuid)
                    .map(x => x.uuid);
                if (hits.length === 0) {
                    done(default_url);
                } else {
                    done("/dashboard/" + hits[0]);
                }
            },
            err
        );
    } else {
        // filter dashboards by tags - they are given as parameters with tag_ prefix
        const PREFIX = "tag_";
        const filter_tags = {};
        Object.keys(params)
            .filter(x => x.startsWith(PREFIX))
            .map(x => { return [x.slice(PREFIX.length), params[x]]; })
            .forEach(x => {
                filter_tags[x[0]] = x[1];
            });

        // utility function for checking that incoming tags are a match for filters
        const isMatch = (xtags) => {
            for (const tag_name of Object.keys(filter_tags)) {
                if (xtags[tag_name] !== filter_tags[tag_name]) {
                    return false;
                }
                return true;
            }
        }
        dash_backend.getDashboards(
            (dash_list) => {
                const hits = dash_list
                    .filter(x => isMatch(x.tags))
                    .map(x => x.uuid);
                if (hits.length === 0) {
                    done(default_url);
                } else {
                    done("/dashboard/" + hits[0]);
                }
            },
            err
        );
    }
}

/** Find page that best represents given line. */
async function getManLine(params: any, done: (url: string) => void, err: (str: string) => void) {
    try {
        const default_url = "/manufacturing/lines/microplan/";
        if (params.uuid) {
            done(default_url + params.uuid);
        } else if (params.external_id) {
            const { lines } = await getBackend2().manufacturing.getLines({ external_id: params.external_id });
            if (lines.length === 0) {
                done(default_url);
            } else {
                params.uuid = lines[0].uuid;
                done(default_url + lines[0].uuid);
            }
        } else {
            done(default_url);
        }
    } catch (e) {
        err(e.message);
    }
}

/** Find page that best represents given material. */
function getManMaterial(params: any, done: (url: string) => void, err: (str: string) => void) {
    done("/digital-twin/resources/materials");
}

/** Find page that best represents given plant. */
async function getManPlant(params: any, done: (url: string) => void, err: (str: string) => void) {
    try {
        const backend = getBackend();
        const dash_backend = backend.dash;
        const default_url = "/digital-twin/resources/plants";
        if (params.uuid) {
            dash_backend.getDashboards(
                (dash_list) => {
                    const hits = dash_list
                        .filter(x => x.tags.type === "man" && x.tags.subtype === "plant" && x.tags.line === params.uuid)
                        .map(x => x.uuid);
                    if (hits.length === 0) {
                        done(default_url);
                    } else {
                        done("/dashboard/" + hits[0]);
                    }
                },
                err
            );
        } else if (params.external_id) {
            const res = await getBackend2().manufacturing.getPlants({});
            const hits = res.plants
                .filter(x => x.external_id === params.external_id)
                .map(x => x.uuid);
            if (hits.length === 0) {
                done(default_url);
            } else {
                params.uuid = hits[0];
                getManPlant(params, done, err);
            }
        } else {
            done(default_url);
        }
    } catch (e) {
        err(e.message);
    }
}

/** Simple utility function for creating AKA url. */
export function createAkaUrl(params: any) {
    return "/aka?" + encodeUrlQuery(params);
}