// @flow

/************************************************************************************/
/* WARNING: This file is generated automatically. Don't edit it by hand             */
/************************************************************************************/

import * as api from "./restApi.js";
import * as t from "./alerts.generated.types.js";

function enc(s: any): string { return encodeURIComponent("" + s); }

export interface IAlerts {
    /** Purges dangling alerts */
    purgeAlerts(req: t.IPurgeAlertsReq): Promise<t.IPurgeAlertsRes>;

    /** Gets events statistics */
    getStats(req: t.IGetStatsReq): Promise<t.IGetStatsRes>;

    /** Gets single event */
    getSingleEvent(req: t.IGetSingleEventReq): Promise<t.IGetSingleEventRes>;

    /** Searches for alerts */
    searchEvents(req: t.ISearchEventsReq): Promise<t.ISearchEventsRes>;

}

export class Alerts implements IAlerts {

    client: api.ApiClient;

    constructor(client: api.ApiClient) { this.client = client; }

    /** Purges dangling alerts */
    async purgeAlerts(req: t.IPurgeAlertsReq): Promise<t.IPurgeAlertsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/alerts/purge`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Gets events statistics */
    async getStats(req: t.IGetStatsReq): Promise<t.IGetStatsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/events/stats`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Gets single event */
    async getSingleEvent(req: t.IGetSingleEventReq): Promise<t.IGetSingleEventRes> {
        const reqx: any = req;
        const url = `/api/v1.0/events/${enc(req.id)}`;
        delete reqx.id;
        return (await this.client.get(url, reqx)).data;
    }

    /** Searches for alerts */
    async searchEvents(req: t.ISearchEventsReq): Promise<t.ISearchEventsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/events`;
        return (await this.client.get(url, reqx)).data;
    }

}
