// @flow
import * as React from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { connect } from "react-redux";

import { translate } from "../../IntlProviderWrapper";
import { ORDER_TAGS_ACCESS } from "../../../lib/ManufacturingTags.generated";
import { REWORK_ORDER_ACTION_NAME, UNLINK_ORDER } from "./reducers/linesOrders";
import { LinesOrdersMaps } from "./reducers/Mappers";
import { ReduxFilters } from "./reducers/BusinessLogic";
import DraggableModal from "../../DraggableModal";
import { ValidLinesOrdersHelper } from "./reducers/ValidLinesOrdersHelper";
import Loader from "../../Loader";

import type { SetRescheduleOrderBundleAction } from "./reducers/common";
import type { UnlinkOrderAction } from "./reducers/linesOrders";
import type { ReduxState } from "./reducers/reducers";
import type { LineOrders, IOrderProducedModelEx, ReworkOrderAction } from "./reducers/linesOrders";
import type { IOrderModelBase } from "../../../lib/backend/manufacturing2.generated.types";

// $FlowFixMe
import "../../../styles/link_operations_modal.scss";
import { arrayIntersection, classNames } from "../../../lib/Util";
import ErrorComponent from "../../ErrorComponent";
import { getBackend } from "../../../lib/backend/Backend2";
import { ORDER_STATUS } from "../../../lib/ManufacturingConsts.generated";

type SelectFilterOption = {
    data: IOrderProducedModelEx,
    label: React.Node,
    value: string
};

type Props = {
    rework_operation: IOrderProducedModelEx | null,
    show: boolean,
    lines_orders: LineOrders[],
    unscheduled_orders: LineOrders[],
    reduxDispatch: (args: ReworkOrderAction | UnlinkOrderAction | SetRescheduleOrderBundleAction) => void,
    onClose: () => void,
    is_filter_locked: boolean
};

type State = {
    error: string,
    base_operation: IOrderProducedModelEx | null,
    external_base_operation: IOrderModelBase | null,
    is_loading: boolean
};

const getOperationOptionLabel = (option: IOrderProducedModelEx) => {
    return (
        <span>
            <strong>{option.external_id}</strong> - <em>{option.process_num}</em> - {option.title}
        </span>
    );
};

const getOperationOptionValue = (option: IOrderProducedModelEx) => {
    return option.uuid;
};

const filterOperationOption = (option: SelectFilterOption, input_value: string) => {
    const filter = input_value.toLowerCase();
    return (
        option.data.external_id.toLowerCase().indexOf(filter) >= 0 ||
        (option.data.title !== undefined && option.data.title.toLowerCase().indexOf(filter) >= 0) ||
        option.data.process_num.indexOf(filter) >= 0
    );
};

class LinkOperationsModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const state: State = {
            error: "",
            base_operation: null,
            external_base_operation: null,
            is_loading: false
        };
        this.state = state;
    }

    async componentDidMount() {
        if (this.props.show) {
            await this.initBaseOperation();
        }
    }

    async componentDidUpdate(prev_props: Props) {
        const { rework_operation, show } = this.props;
        const { rework_operation: prev_rework_operation, show: prev_show } = prev_props;
        if (
            (rework_operation && rework_operation.uuid) !== (prev_rework_operation && prev_rework_operation.uuid) ||
            (show !== prev_show && show)
        ) {
            await this.initBaseOperation();
        }
    }

    getOperations = (): IOrderProducedModelEx[] => {
        const { rework_operation } = this.props;
        if (rework_operation === null) {
            return [];
        }

        const operations_map: Map<string, IOrderProducedModelEx> = new Map();
        const valid_rework_line_uuids = ValidLinesOrdersHelper.getLineUuids(rework_operation.uuid) || [];
        for (const line of this.props.lines_orders) {
            for (const operation of line.orders) {
                const is_link_allowed =
                    operation.uuid !== rework_operation.uuid &&
                    ORDER_TAGS_ACCESS.linked_operation(operation.tags) === "" &&
                    // If base operation is scheduled, check if rework operation is compatible
                    // with current line
                    valid_rework_line_uuids.includes(line.line_uuid);
                if (!is_link_allowed) {
                    continue;
                }

                operations_map.set(operation.uuid, operation);
            }
        }

        for (const line of this.props.unscheduled_orders) {
            for (const operation of line.orders) {
                const valid_base_line_uuids = ValidLinesOrdersHelper.getLineUuids(operation.uuid) || [];
                const valid_line_uuids_intersection = arrayIntersection(valid_rework_line_uuids, valid_base_line_uuids);
                const is_link_allowed =
                    operation.uuid !== rework_operation.uuid &&
                    ORDER_TAGS_ACCESS.linked_operation(operation.tags) === "" &&
                    // If base operation is unscheduled, check if rework operation is compatible
                    // with any of valid lines from intersection of valid lines for base operation
                    // and valid lines for trailing operations.
                    valid_line_uuids_intersection.length > 0;
                if (!is_link_allowed) {
                    continue;
                }

                operations_map.set(operation.uuid, operation);
            }
        }

        return [...operations_map.values()];
    };

    initBaseOperation = async () => {
        const operations = this.getOperations();
        const { rework_operation } = this.props;
        if (rework_operation === null) {
            return;
        }

        const linked_operation = ORDER_TAGS_ACCESS.linked_operation(rework_operation.tags);
        const base_operation = operations.find(op => op.uuid === linked_operation) || null;
        let external_base_operation = null;
        if (base_operation === null) {
            this.setState({ is_loading: true });
            try {
                const res = await getBackend().manufacturing.getOrdersSimple({
                    uuids: [linked_operation]
                });
                if (res.operations.length > 0) {
                    external_base_operation = res.operations[0];
                }
            } catch (error) {
                this.setState({
                    error
                });
            }

            this.setState({ is_loading: false });
        }

        this.setState({
            base_operation,
            external_base_operation
        });
    };

    unlinkOperation = (order_uuid: string) => {
        this.props.reduxDispatch({
            type: UNLINK_ORDER,
            data: {
                order_uuid
            }
        });

        ReduxFilters.setRescheduleOrderUuid(
            this.props.reduxDispatch,
            order_uuid,
            "line_order_list",
            this.props.is_filter_locked
        );
    };

    linkOperation = (rework_uuid: string, base_uuid: string) => {
        const rework_order = LinesOrdersMaps.all_orders_map.get(rework_uuid);
        const base_order = LinesOrdersMaps.all_orders_map.get(base_uuid);
        if (base_order && rework_order) {
            this.props.reduxDispatch({
                type: REWORK_ORDER_ACTION_NAME,
                data: {
                    base_order,
                    rework_order
                }
            });
            ReduxFilters.setRescheduleOrderUuid(
                this.props.reduxDispatch,
                base_uuid,
                "line_order_list",
                this.props.is_filter_locked
            );
        }
    };

    isBaseOperationMissing = (): boolean => {
        const { rework_operation } = this.props;
        const { base_operation } = this.state;
        const linked_operation = rework_operation !== null
            ? ORDER_TAGS_ACCESS.linked_operation(rework_operation.tags)
            : "";
        return (
            rework_operation !== null &&
            base_operation === null &&
            linked_operation !== "" &&
            LinesOrdersMaps.all_orders_map.get(linked_operation) === undefined
        );
    };

    hasConfigurationChanged = (): boolean => {
        const { rework_operation } = this.props;
        const { base_operation } = this.state;
        const prev_base_operation_uuid =
            rework_operation !== null ? ORDER_TAGS_ACCESS.linked_operation(rework_operation.tags) : "";
        return (
            rework_operation !== null &&
            ((base_operation === null && prev_base_operation_uuid !== "") ||
                (base_operation !== null && base_operation.uuid !== prev_base_operation_uuid))
        );
    };

    handleOperationChange = (operation: IOrderProducedModelEx | null) => {
        this.setState({
            base_operation: operation
        });
    };

    handleClose = () => {
        this.setState({
            error: "",
            base_operation: null,
            external_base_operation: null
        });
        this.props.onClose();
    };

    handleLinkSubmit = (event: SyntheticEvent<HTMLFormElement>) => {
        event.preventDefault();

        const { rework_operation } = this.props;
        const { base_operation } = this.state;
        if (rework_operation !== null) {
            if (base_operation) {
                this.linkOperation(rework_operation.uuid, base_operation.uuid);
            } else {
                this.unlinkOperation(rework_operation.uuid);
            }
        }

        this.props.onClose();
    };

    renderModalTitle = () => {
        const { rework_operation } = this.props;
        let title = "";
        if (rework_operation !== null) {
            title = `${rework_operation.external_id} - ${rework_operation.process_num}`;
            if (rework_operation.title !== undefined) {
                title += ` - ${rework_operation.title}`;
            }
        }

        return (
            <Modal.Title className="text-truncate" title={title}>
                {translate("common.link_operation", "Link operation")}
                {rework_operation !== null && (
                    <span>
                        {" "}
                        - <span className="text-muted">{title}</span>
                    </span>
                )}
            </Modal.Title>
        );
    };

    renderStatus = () => {
        const { external_base_operation } = this.state;
        const status = [];
        if (this.state.error !== "") {
            status.push(<ErrorComponent key="error" type="error" msg={this.state.error} />);
        }

        if (this.isBaseOperationMissing() && !this.state.is_loading) {
            let message = translate("common.leading_operation_not_found", "Leading operation not found");
            if (external_base_operation !== null) {
                if (external_base_operation.status !== ORDER_STATUS.open) {
                    message = translate("common.leading_operation_is_closed", "Leading operation is closed");
                } else {
                    message = translate(
                        "common.leading_operation_is_on_extra_line",
                        "Leading operation is on related line from other workshop"
                    );
                }
            }

            status.push(<ErrorComponent key="warning" type="warning" msg={message} />);
        }

        return status.length > 0 && <div className="modal-statusbar">{status}</div>;
    };

    renderForm = () => {
        const { external_base_operation } = this.state;
        const operations = this.getOperations();
        const is_base_operation_missing = this.isBaseOperationMissing();

        return (
            <form id="linik-operations-form" onSubmit={this.handleLinkSubmit}>
                <div className="form-group mb-0">
                    <label htmlFor="base-operation-select">
                        {translate("common.leading_operation", "Leading operation")}
                    </label>
                    <Select
                        inputId="base-operation-select"
                        name="operation"
                        value={this.state.base_operation}
                        placeholder={
                            is_base_operation_missing && external_base_operation !== null
                                ? `${external_base_operation.external_id} - ${external_base_operation.process_num} - ${external_base_operation.title}`
                                : `${translate("common.select", "Select")}...`
                        }
                        noOptionsMessage={() => translate("common.no_data", "No data available")}
                        options={operations}
                        getOptionLabel={getOperationOptionLabel}
                        getOptionValue={getOperationOptionValue}
                        filterOption={filterOperationOption}
                        onChange={this.handleOperationChange}
                        isClearable
                        autoFocus
                    />
                </div>
            </form>
        );
    };

    render() {
        const is_base_operation_missing = this.isBaseOperationMissing();
        return (
            <DraggableModal>
                <Modal dialogClassName="link-operations-modal" show={this.props.show} animation={false}>
                    <Modal.Header>
                        {this.renderModalTitle()}
                        <button type="button" className="close" onClick={this.handleClose}>
                            <span aria-hidden="true">×</span>
                            <span className="sr-only">{translate("common.close", "Close")}</span>
                        </button>
                    </Modal.Header>
                    {this.renderStatus()}
                    <Modal.Body>
                        {this.state.is_loading ? <Loader small /> : this.renderForm()}
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-outline-secondary" onClick={this.props.onClose}>
                            {translate("common.cancel", "Cancel")}
                        </button>
                        <button
                            className={classNames("btn", {
                                "btn-primary": !is_base_operation_missing,
                                "btn-danger": is_base_operation_missing
                            })}
                            type="submit"
                            form="linik-operations-form"
                            disabled={!this.hasConfigurationChanged()}
                        >
                            {is_base_operation_missing
                                ? translate("common.remove", "Remove")
                                : translate("common.ok", "OK")}
                        </button>
                    </Modal.Footer>
                </Modal>
            </DraggableModal>
        );
    }
}

export default connect(
    (state: ReduxState, own_props: Props) => {
        const is_filter_locked = state.gantt_chart_filters.is_filter_locked;
        return {
            lines_orders: state.gantt_chart_lines_orders.lines_orders,
            unscheduled_orders: state.gantt_chart_lines_orders.unscheduled_orders,
            is_filter_locked
        };
    },
    dispatch => ({ reduxDispatch: dispatch })
)(LinkOperationsModal);
