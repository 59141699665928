// @flow

import React from "react";
import { connect } from "react-redux";
import * as sf from "../../../lib/StockForecast";
import Loader from "../../Loader";
import MaterialStockForecast from "../MaterialStockForecast";
import { PLANNING_TABLE_DISPLAY_TYPES } from "./DisplayTypesMenu";
import { SKIP_STOCK_REPORT } from "./reducers/properties";
import { RECALCULATE_REPORT } from "./reducers/linesOrders";
import { getBackend } from "../../../lib/backend/Backend2";
import { MATERIAL_TAGS_ACCESS } from "../../../lib/ManufacturingTags.generated";
import { ValidLinesOrdersHelper } from "./reducers/ValidLinesOrdersHelper";

import type { StockForecastFilterProps } from "../MaterialStockForecastView";
import type { SetSkipStockReport, PropertiesState } from "./reducers/properties";
import type { ReduxState } from "./reducers/reducers";
import type { ReportParameters, ValidOrder } from "./reducers/common";
import type { RecalculateReport } from "./reducers/linesOrders";
import type { IMaterialForecast } from "../../../lib/backend/manufacturing2.generated.types";
import type { IReportModelEx } from "../../../lib/SimulationReportModels";
import type {
    IMaterialModel,
    IParallelMaterialModel,
    IViewOrderBomInput
} from "../../../lib/backend/manufacturing2.generated.types";

type Props = {
    report_uuid: string | null,
    line_group_uuid: string,
    planning_weeks: number,
    selected_display_type: $Keys<typeof PLANNING_TABLE_DISPLAY_TYPES>,
    report_current_parameters: ReportParameters | null,
    recommend_include_unscheduled_stock?: boolean,
    reduxDispatch: (args: SetSkipStockReport | RecalculateReport) => void,
    properties: PropertiesState,
    valid_orders_map: Map<string, ValidOrder> | null,
    report_data: IReportModelEx | null,
    materials: IMaterialModel[],
    reschedule_line_uuid: string | null
};

type State = {
    material_forecast_out: sf.MaterialForecast | null,
    material_forecast_in: sf.MaterialForecast | null,
    parallel_materials: Map<string, IParallelMaterialModel> | null,
    input_materials: Map<string, Map<string, IViewOrderBomInput>> | null,
    material_uuids: Set<string>,
    preferred_material_uuids: Set<string>,
    filter: StockForecastFilterProps,
    loading: boolean
};

const filterForcastByMaterial = (
    material_forecasts: IMaterialForecast[],
    material_uuids: Set<string>,
    preferred_material_uuids: Set<string>,
): IMaterialForecast[] => {
    const new_material_forecasts: IMaterialForecast[] = [];
    for (const material_forecast of material_forecasts) {
        if (material_uuids.has(material_forecast.material_uuid)) {
            new_material_forecasts.push({
                ...material_forecast,
                highlight_material: preferred_material_uuids.has(material_forecast.material_uuid)
            });
        }
    }

    return new_material_forecasts;
};


const getMaterialUuids = (
    material_external_id: string,
    material_external_ids_map: Map<string, IMaterialModel>,
    parallel_materials: Map<string, IParallelMaterialModel>,
    input_materials: IViewOrderBomInput[],
    selected_display_type: string
): Set<string> => {
    const material_uuids: Set<string> = new Set();
    if (selected_display_type === PLANNING_TABLE_DISPLAY_TYPES.material_forecast_out) {
        const material = material_external_ids_map.get(material_external_id);
        if (material !== undefined) {
            if (MATERIAL_TAGS_ACCESS.parallel(material.tags)) {
                const parallel_material = (parallel_materials &&
                    parallel_materials.get(material.uuid)) || null;
                if (parallel_material !== null) {
                    for (const child_material of parallel_material.child_materials) {
                        material_uuids.add(child_material.uuid);
                    }
                }
            } else {
                material_uuids.add(material.uuid);
            }
        }

        for (const input_material of input_materials) {
            if (input_material.factor < 0) {
                material_uuids.add(input_material.input_material_uuid);
            }
        }
    } else {
        for (const input_material of input_materials) {
            if (input_material.factor >= 0) {
                material_uuids.add(input_material.input_material_uuid);
            }
        }
    }

    return material_uuids;
};

const isForecastInDisplayType = (display_type: string): boolean => {
    return [
        PLANNING_TABLE_DISPLAY_TYPES.material_forecast_in,
        PLANNING_TABLE_DISPLAY_TYPES.material_recommend_forecast_in
    ].includes(display_type);
}


class MaterialStockForecastContainer extends React.Component<Props, State> {

    state = {
        material_forecast_out: null,
        material_forecast_in: null,
        parallel_materials: null,
        input_materials: null,
        material_uuids: new Set(),
        preferred_material_uuids: new Set(),
        filter: {
            selected_display_type: isForecastInDisplayType(this.props.selected_display_type)
                ? sf.MATERIAL_FORECAST_TYPES.in
                : sf.MATERIAL_FORECAST_TYPES.out,
            selected_subtype: sf.MATERIAL_FORECAST_SUBTYPES.combined,
            title: "",
            only_accounted_for_initial_stock: sf.MATERIAL_FORECAST_ONLY_ACCOUNTED.no,
            safety_stock_show: false,
            safety_stock_can_be_used: false,
            selected_location: sf.MATERIAL_FORECAST_LOCATIONS.plant,
            selected_line_uuid: "",
            lines: [],
            selected_aggregate: "shift"
        },
        loading: true
    }

    async componentDidMount() {
        await this.loadMaterials();
        await this.load(this.props.selected_display_type);
        this.updateMaterialUuids();
    }

    async componentDidUpdate(prev_props: Props, prev_state: State) {
        if (prev_props.selected_display_type !== this.props.selected_display_type ||
            prev_props.report_uuid !== this.props.report_uuid) {
            await this.load(this.props.selected_display_type);
        }

        if (
            this.state.parallel_materials === null ||
            prev_props.properties.line_group_uuid !== this.props.properties.line_group_uuid
        ) {
            await this.loadMaterials();
        }

        if (
            prev_props.selected_display_type !== this.props.selected_display_type ||
            prev_props.valid_orders_map !== this.props.valid_orders_map
        ) {
            this.updateMaterialUuids();
        }
    }

    updateMaterialUuids = () => {
        const {
            materials,
            report_data,
            selected_display_type,
            valid_orders_map,
            reschedule_line_uuid
        } = this.props;
        const { input_materials, parallel_materials } = this.state;

        if (input_materials === null || parallel_materials === null) {
            return;
        }

        const is_forecast_out = selected_display_type === PLANNING_TABLE_DISPLAY_TYPES.material_forecast_out;
        const preferred_operation_uuids: Set<string> = new Set(
            reschedule_line_uuid && ValidLinesOrdersHelper.getPreferredOperationUuids(reschedule_line_uuid)
        );
        const valid_operations = valid_orders_map !== null
            ? [...valid_orders_map.values()].filter(v => !v.is_translucent)
            : [];
        const valid_operation_uuids = new Set(valid_operations.map(o => o.uuid));
        const material_external_ids_map = new Map(materials.map(m => [m.external_id, m]));
        const material_uuids = new Set();
        const preferred_material_uuids = new Set();
        if (report_data !== null) {
            for (const line of report_data.result.orders) {
                for (const operation of line.production) {
                    if (valid_operation_uuids.has(operation.order_id)) {
                        const input_materials_map = input_materials.get(operation.order_id) || new Map();
                        const new_material_uuids = getMaterialUuids(
                            operation.material,
                            material_external_ids_map,
                            parallel_materials,
                            [...input_materials_map.values()],
                            selected_display_type
                        );
                        const is_preferred = preferred_operation_uuids.has(operation.order_id);
                        new_material_uuids.forEach(material_uuid => {
                            if (is_forecast_out && is_preferred) {
                                preferred_material_uuids.add(material_uuid);
                            }

                            material_uuids.add(material_uuid);
                        });
                    }
                }
            }

            for (const operation of report_data.result.unscheduled) {
                if (valid_operation_uuids.has(operation.order_uuid)) {
                    const input_materials_map = input_materials.get(operation.order_uuid) || new Map();
                    const new_material_uuids = getMaterialUuids(
                        operation.material_uuid,
                        material_external_ids_map,
                        parallel_materials,
                        [...input_materials_map.values()],
                        selected_display_type
                    );
                    const is_preferred = preferred_operation_uuids.has(operation.order_uuid);
                    new_material_uuids.forEach(material_uuid => {
                        if (is_forecast_out && is_preferred) {
                            preferred_material_uuids.add(material_uuid);
                        }

                        material_uuids.add(material_uuid);
                    });
                }
            }
        }

        this.setState({
            material_uuids,
            preferred_material_uuids
        });
    };

    loadMaterials = async () => {
        const { materials, report_data } = this.props;

        try {
            const uuids = [];
            for (const material of materials) {
                if (MATERIAL_TAGS_ACCESS.parallel(material.tags)) {
                    uuids.push(material.uuid);
                }
            }

            const res = await getBackend().manufacturing.parallelMaterialsGet({ uuids });
            const parallel_materials = new Map(res.data.map(m => [m.uuid, m]));

            const input_materials: Map<string, Map<string, IViewOrderBomInput>> = new Map();            
            if (report_data !== null) {
                const order_uuids: string[] = [];
                for (const line of report_data.result.orders) {
                    for (const operation of line.production) {
                        order_uuids.push(operation.order_id);
                    }
                }
    
                for (const operation of report_data.result.unscheduled) {
                    order_uuids.push(operation.order_uuid);
                }

                const res2 = await getBackend().manufacturing.getInputMaterials({ order_uuids });
                for (const bom_input of res2.input_materials) {
                    const bom_inputs = input_materials.get(bom_input.order_uuid) || new Map();
                    bom_inputs.set(bom_input.input_material_uuid, bom_input);
                    input_materials.set(bom_input.order_uuid, bom_inputs);
                }
            }

            this.setState({ parallel_materials, input_materials });
        } catch (error) {
            console.error(error.message);
        }
    };

    async load(selected_forecast_type: $Keys<typeof PLANNING_TABLE_DISPLAY_TYPES>) {
        const report_uuid = this.props.report_uuid;

        if (this.props.properties.skip_stock === true) {
            // if the last report was generated by skip_stock
            this.setState({ loading: true });
            this.props.reduxDispatch({ type: SKIP_STOCK_REPORT, data: false });
            this.props.reduxDispatch({ type: RECALCULATE_REPORT, data: undefined });
            return;
        }

        if (!report_uuid) {
            return null;
        }

        this.setState({ loading: true });

        if (selected_forecast_type === PLANNING_TABLE_DISPLAY_TYPES.material_forecast_out) {
            const material_forecast_out = await sf.getMaterialForecast(
                this.props.line_group_uuid,
                sf.MATERIAL_FORECAST_TYPES.out,
                sf.MATERIAL_FORECAST_SUBTYPES.combined,
                sf.MATERIAL_FORECAST_ONLY_ACCOUNTED.no,
                sf.MATERIAL_FORECAST_LOCATIONS.plant,
                7 * this.props.planning_weeks, // days
                undefined,
                report_uuid
            );
            this.setState({ material_forecast_out, material_forecast_in: null });
        } else if (selected_forecast_type === PLANNING_TABLE_DISPLAY_TYPES.material_forecast_in) {
            const material_forecast_in = await sf.getMaterialForecast(
                this.props.line_group_uuid,
                sf.MATERIAL_FORECAST_TYPES.in,
                sf.MATERIAL_FORECAST_SUBTYPES.combined,
                sf.MATERIAL_FORECAST_ONLY_ACCOUNTED.no,
                sf.MATERIAL_FORECAST_LOCATIONS.plant,
                7 * this.props.planning_weeks, // days
                undefined,
                report_uuid
            );
            this.setState({ material_forecast_in, material_forecast_out: null });
        } else if (selected_forecast_type === PLANNING_TABLE_DISPLAY_TYPES.material_recommend_forecast_in) {
            const material_forecast_in = await sf.getMaterialForecast(
                this.props.line_group_uuid,
                sf.MATERIAL_FORECAST_TYPES.in,
                sf.MATERIAL_FORECAST_SUBTYPES.combined,
                sf.MATERIAL_FORECAST_ONLY_ACCOUNTED.no,
                sf.MATERIAL_FORECAST_LOCATIONS.plant,
                7 * this.props.planning_weeks, // days
                undefined,
                report_uuid,
                undefined,
                undefined,
                this.props.recommend_include_unscheduled_stock
            );
            this.setState({ material_forecast_in, material_forecast_out: null });
        }

        this.setState({ loading: false });
    }

    handleTitleChange = (event: Event) => {
        const current_target = event.currentTarget;
        if (current_target instanceof HTMLInputElement) { 
            this.setState(state => ({
                ...state,
                filter: {
                    ...state.filter,
                    title: current_target.value
                }
            }))
        };
    };

    handleAggregationChange = (aggregation: string) => {
        this.setState(state => ({
            ...state,
            filter: {
                ...state.filter,
                selected_aggregate: aggregation
            }
        }));
    };

    renderMaterialForecastTypeOut = () => {
        const { selected_display_type } = this.props;
        const { filter, material_forecast_out, material_uuids, preferred_material_uuids } = this.state;
        if (material_forecast_out && selected_display_type === PLANNING_TABLE_DISPLAY_TYPES.material_forecast_out) {
            const materials_changing = filterForcastByMaterial(
                material_forecast_out.materials_changing,
                material_uuids,
                preferred_material_uuids
            );
            const materials_nonchanging = filterForcastByMaterial(
                material_forecast_out.materials_nonchanging,
                material_uuids,
                preferred_material_uuids
            );
            const materials_zero = filterForcastByMaterial(
                material_forecast_out.materials_zero,
                material_uuids,
                preferred_material_uuids
            );

            return <MaterialStockForecast
                shift_headers={material_forecast_out.shift_headers}
                show_affiliated_stock={false}
                safety_stock_show={filter.safety_stock_show}
                safety_stock_account_for={!filter.safety_stock_can_be_used}
                materials_changing={materials_changing}
                materials_nonchanging={materials_nonchanging}
                materials_zero={materials_zero}
                only_accounted_for_initial_stock={filter.only_accounted_for_initial_stock}
                selected_subtype={filter.selected_subtype}
                selected_aggregate={filter.selected_aggregate}
                forecasts_materials_changing={materials_changing}
                forecasts_materials_nonchanging={materials_nonchanging}
                forecasts_materials_zero={materials_zero}
                no_overflow={true}
                onTitleChange={this.handleTitleChange}
                stock_forecast_filter={this.state.filter}
                changeSelectedHorizonStockForecast={this.handleAggregationChange}
                show_aggregation_dropdown={true}
            />
        }
        return null;
    }

    renderMaterialForecastTypeIn = () => {
        const { selected_display_type } = this.props;
        const { filter, material_forecast_in, material_uuids, preferred_material_uuids } = this.state;
        if (material_forecast_in && isForecastInDisplayType(selected_display_type)) {
            const materials_changing = filterForcastByMaterial(
                material_forecast_in.materials_changing,
                material_uuids,
                preferred_material_uuids
            );
            const materials_nonchanging = filterForcastByMaterial(
                material_forecast_in.materials_nonchanging,
                material_uuids,
                preferred_material_uuids
            );
            const materials_zero = filterForcastByMaterial(
                material_forecast_in.materials_zero,
                material_uuids,
                preferred_material_uuids
            );

            return <MaterialStockForecast
                shift_headers={material_forecast_in.shift_headers}
                show_affiliated_stock={false}
                safety_stock_show={filter.safety_stock_show}
                safety_stock_account_for={true}
                materials_changing={materials_changing}
                materials_nonchanging={materials_nonchanging}
                materials_zero={materials_zero}
                forecasts_materials_changing={materials_changing}
                forecasts_materials_nonchanging={materials_nonchanging}
                forecasts_materials_zero={materials_zero}
                only_accounted_for_initial_stock={filter.only_accounted_for_initial_stock}
                selected_subtype={filter.selected_subtype}
                selected_aggregate={filter.selected_aggregate}
                no_overflow={true}
                onTitleChange={this.handleTitleChange}
                stock_forecast_filter={this.state.filter}
                changeSelectedHorizonStockForecast={this.handleAggregationChange}
                show_aggregation_dropdown={true}
            />
        }
        return null;
    }

    render() {
        if (!this.props.report_uuid) {
            return null;
        }
        if (this.state.loading) {
            return <Loader />;
        }

        return <div>
            <div>
                {this.renderMaterialForecastTypeIn()}
                {this.renderMaterialForecastTypeOut()}
            </div>
        </div>
    }

}

export default connect(
    (state: ReduxState, own_props: Props): $Shape<Props> => {
        const properties: PropertiesState = state.gantt_chart_properties;
        const recommend_plans = state.gantt_chart_recommend_plans.recommend_plans;

        const recommend_include_unscheduled_stock = (recommend_plans && recommend_plans.length > 0) ?
            recommend_plans[recommend_plans.length - 1].parameters.state.recommend_param_use_productions_of_unscheduled_operations : false;

        const valid_orders_map = state.gantt_chart_planning_table.show_valid_lines.valid_orders_map;
        const report_data = state.gantt_chart_report.report_data;
        const materials = state.gantt_chart.materials;
        const reschedule_line_uuid = state.gantt_chart_filters.reschedule_line_uuid;
        return {
            properties,
            recommend_include_unscheduled_stock,
            valid_orders_map,
            report_data,
            materials,
            reschedule_line_uuid
        };
    },
    (dispatch) => ({ reduxDispatch: dispatch })
)(MaterialStockForecastContainer);
