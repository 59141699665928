// @flow
import React from "react";
import {connect} from "react-redux";
import * as r from "../../../../lib/backend/reports.generated.types";
import { DraggableConsumer } from "../Draggable/DraggableContext";
import { getShiftXPosition, getShiftWidth} from "../reducers/properties";

import type { DraggableProviderContext } from "../Draggable/DraggableContext";
import type { ShiftXPositions } from "../reducers/properties";
import type { ReduxState } from "../reducers/reducers";

type Props = {
    order_uuid: string,
    y: number,
    disabled_shifts: r.ISimulationReportOrderLineShiftFeatureItem[],
    xscale: any => any,
    material_title: string,
    order_uuid: string,
    is_dragging?: boolean,
    bar_height?: number,
    bars_start?: number,
    shift_x_positions?: ShiftXPositions,
    xscale?: any => any,
    order_rect_stroke: number
};
type State = {};

class OrderDisabledShiftsClass extends React.Component<Props, State> {

    renderDisabledShifts = () => {
        const shift_x_positions = this.props.shift_x_positions;
        if (!shift_x_positions) {
            return null;
        }
        if (!this.props.disabled_shifts || this.props.disabled_shifts.length === 0) return null;
        const bar_height = this.props.bar_height || 0;
        const order_stroke = this.props.order_rect_stroke / 2;
        return this.props.disabled_shifts.map((shift, i) => {
            let shift_x = getShiftXPosition({
                shift_x_positions: shift_x_positions,
                shift_number: shift.shift_time.shift_number,
                week: shift.shift_time.week
            });

            let x = shift_x;
            let width = getShiftWidth(this.props.xscale);

            return <rect
                key={`order-disabled-shift-${this.props.order_uuid}-${i}`}
                id={"order-disabled-shift-" + this.props.order_uuid}
                material_title={this.props.material_title}
                style={{pointerEvents: "none"}}
                x={this.props.bars_start + x}
                y={this.props.y + order_stroke}
                width={width}
                height={bar_height - 2*order_stroke}
                fill={"#ADB2BC"}
            />
        });
    }

    render() {
        if (this.props.is_dragging) {
            return null;
        }
        return this.renderDisabledShifts();
    }
}

const ConnectedOrderDisabledShifts = connect(
    (state: ReduxState) => {
        const properties = state.gantt_chart_properties;
        return {
            xscale: properties.xscale,
            bar_height: properties.bar_height,
            source_type: properties.source_type,
            shift_x_positions: properties.shift_x_positions,
            bars_start: properties.bars_start,
            xscale: properties.xscale
        }
    },
    (dispatch) => ({ reduxDispatch: dispatch })
)(OrderDisabledShiftsClass);

const OrderDisabledShifts = (props: Props) => {
    return <DraggableConsumer>
        {(value: ?DraggableProviderContext) => {
            if (!value) {
                return null;
            }
            const is_dragging = (value.state.dragging_order && value.state.dragging_order.order_uuid === props.order_uuid);
            return <ConnectedOrderDisabledShifts is_dragging={is_dragging} {...props} />;
        }}
    </DraggableConsumer>;
}

export default OrderDisabledShifts;