// @flow
import * as React from "react";

// $FlowFixMe
import { useState, useRef, useEffect } from "react";
import Select from "react-select";
import { Overlay, Tooltip } from "react-bootstrap";

import * as sf from "../../lib/StockForecast";

import { classNames, niceDate, weekNumber } from "../../lib/Util";

import { translate } from "../IntlProviderWrapper";
import { translateAggregationTimeWindow } from "./MaterialStockForecast";

import type { StockForecastFilterProps } from "./MaterialStockForecastView";
import type { ILineData } from "../../lib/backend/manufacturing2.generated.types";
import type { AggregationTimeWindow } from "./MaterialStockForecast"

type DayHeader = {
    date: string,
    day: string,
    col_width: number
}

type BaseProps = {
    day_headers: DayHeader[],
    stickyOffsetTop: number
}

type Props = {
    selected_aggregate?: string,
    onTitleChange?: (e: Event) => void,
    shift_headers: sf.ShiftHeader[],
    stickyOffsetTop: number,
    show_affiliated_stock: boolean,
    safety_stock_show: boolean,
    handleStockForecastDisplayTypeChange?: (value: string) => void,
    changeSelectedLineUUID?: (value: string) => void,
    onChangeOrderExternalId?: (value: string) => void,
    changeSelectedHorizonStockForecast?: (value: AggregationTimeWindow) => void,
    stock_forecast_filter?: StockForecastFilterProps,
    show_toolbox?: boolean,
    hide_lines_filter?: boolean,
    hide_display_type_filter?: boolean
} & BaseProps;

type ShiftProps = {
    shift_headers: sf.ShiftHeader[]
} & BaseProps;

const ShiftAggregation = (props: ShiftProps) => {
    const { shift_headers, day_headers } = props;

    const firstColSpan = 3 - shift_headers[0].shift;
    const lastColSpan = 3 - shift_headers[day_headers.length - 1].shift;

    return day_headers.map((dh, idx) => {
        let colSpan = 3;

        if (idx === 0) {
            colSpan = firstColSpan
        }

        if (idx === day_headers.length - 1) {
            colSpan = lastColSpan;
        }

        return <th
            key={idx + "_day_header"}
            colSpan={colSpan || 3} rowSpan={1}
            className="day-end date"
            style={{top: props.stickyOffsetTop}}
        >
            <span>{niceDate(new Date(dh.date))}</span> <br></br>
            <span>{dh.day}</span>
        </th>
    });
}

const DayAggregation = (props: BaseProps) => {
    const { day_headers } = props;

    return day_headers.map((dh, idx) => {
        return <th key={idx + "_day_header"} colSpan={3} rowSpan={2} className="day-end date" style={{ top: props.stickyOffsetTop }}>
            <span>{niceDate(new Date(dh.date))}</span> <br></br>
            <span id="day-header">{dh.day}</span>
        </th>
    });
}

const WeekAggregation = (props: BaseProps) => {
    const { day_headers } = props;

    let first_date = day_headers[0].date;
    let last_date = null;
    let last_week_number = weekNumber(new Date(day_headers[0].date));

    return day_headers.map((dh, idx) => {
        if (first_date === null) {
            first_date = last_date;
        }

        const is_last_header = idx === day_headers.length - 1;
        let current_week_number = weekNumber(new Date(dh.date));
        let el = null;

        if ((last_week_number !== current_week_number || is_last_header) && first_date && last_date) {
            if (is_last_header) {
                last_date = dh.date;
            }

            el = <th key={idx + "_day_header"} colSpan={3} rowSpan={2} className="day-end date" style={{ top: props.stickyOffsetTop }}>
                {niceDate(new Date(first_date))} - {niceDate(new Date(last_date))}
            </th>;

            last_week_number = current_week_number;
            first_date = null;
        }

        last_date = dh.date;
        return el;
    });
}

type ToolBoxProps = {
    stock_forecast_filter?: StockForecastFilterProps,
    handleStockForecastDisplayTypeChange?: (value: string) => any,
    changeSelectedLineUUID?: (value: string) => any,
    onChangeOrderExternalId?: (value: string) => any,
    hide_display_type_filter?: boolean,
    hide_lines_filter?: boolean

}

const SelectStyle = {
    control: base => ({ ...base, display: "none" }),
    menu: base => ({ ...base, boxShadow: "none", width: "200px" }),
    menuList: base => ({ ...base, width: "200px" })
}

type Option = {
    code: string,
    title: string
}
type SelectOption = {
    label: string,
    value: string
}

const mapDisplayTypes = (options: Option[]): SelectOption[] => {
    return options.map(o => ({label: o.title, value: o.code}))
}

const mapLines = (lines: ILineData[]): SelectOption[] => {
    const all_lines_value = {
        label: translate("Manufacturing.LinesView.all_lines", "All lines"),
        value: ""
    };
    return [all_lines_value, ...lines.map(l => ({label: l.title, value: l.uuid}))];
}

const TOOLBOX_DISPLAY_TYPE: "display_type" = "display_type";
const TOOLBOX_SELECTED_LINE: "selected_line" = "selected_line";
const TOOLBOX_WORKORDER: "work_order" = "work_order";

type TooltipSearchOrderProps = {
    tooltip_ref: React.Node,
    open_menu: boolean,
    setStateOpenMenu: (value: boolean | null) => void,
    onChange: (value: string) => void,
    order_external_ids: ?string[]
}

const TooltipSearchOrder = (props: TooltipSearchOrderProps) => {
    const {
        tooltip_ref,
        open_menu,
        order_external_ids
    } = props;

    const onChangeInputValue = (event: Event) => {
        if (event.currentTarget instanceof HTMLInputElement) {
            setStateInputValue(event.currentTarget.value);
        }
    }

    const [input_value, setStateInputValue] = useState("");

    useEffect(() => {
        setStateInputValue(order_external_ids && order_external_ids.length > 0 ? order_external_ids.join(",") : "")
    }, [props.order_external_ids])

    return <Overlay
        target={tooltip_ref}
        show={open_menu === TOOLBOX_WORKORDER}
        placement="right"
    >
        <Tooltip id="stock-forecast-toolbox-tooltip" style={{zIndex: "999"}}>
            <input type="text" className="form-control search_bar search_bar_small"
                placeholder={translate("Manufacturing.StockStatusView.search_by_order_external_id", "Filter by work order")}
                name="order_external_id_filter"
                value={input_value}
                style={{marginRight: "15px", minWidth: "200px"}}
                onChange={onChangeInputValue}
                onBlur={(ev) => {ev.currentTarget.focus()}}
            />
            <button className="btn btn-primary" onClick={() => props.onChange(input_value)}>
                {translate("common.search", "Search")}
            </button>
        </Tooltip>
    </Overlay>
}

const eventListenerCloseMenu = (setStateOpenMenu: (args: boolean | null) => void) => (event: Event) => {
    if (event.currentTarget instanceof HTMLElement) {
        // $FlowFixMe
        const target: HTMLElement = event.target;

        if (target) {
            let should_close = (!target.closest("#stock-forecast-toolbox") && !target.closest("#stock-forecast-toolbox-tooltip"));

            if (should_close) {
                setStateOpenMenu(null);
            }
        }
    }
}

let closeMenuEventListener = null;

const handleSelectChange = (value: string, cb) => {
    if (!cb) return;
    cb(value);
}


const ToolBox = (props: ToolBoxProps) => {
    const tooltip_ref = useRef(null);
    const [open_menu, setStateOpenMenu] = useState(null);

    const onOpenMenu = (type) => (e: Event) => open_menu === type ? setStateOpenMenu(null) : setStateOpenMenu(type);

    if (!props.stock_forecast_filter) return null;

    const {
        display_types,
        lines,
        order_external_ids,
        selected_display_type,
        selected_line_uuid
    } = props.stock_forecast_filter

    const getFocus = (type) => open_menu === type ? "focus" : "";

    const optionsDisplayTypes = display_types ? mapDisplayTypes(display_types) : [];
    const optionsLines = lines && lines.length > 0 ? mapLines(lines) : [];

    useEffect(() => {
        return () => {
            if (closeMenuEventListener) {
                const el = document.querySelector("body");
                if (el) {
                    el.removeEventListener("click", closeMenuEventListener);
                }
            }
        }
    });

    if (closeMenuEventListener === null) {
        const el = document.querySelector("body");
        if (el) {
            if (!closeMenuEventListener) {
                closeMenuEventListener = eventListenerCloseMenu(setStateOpenMenu);
            }
            el.addEventListener("click", closeMenuEventListener);
            closeMenuEventListener = null;
        }
    }

    return <div id="stock-forecast-toolbox">
        <div id="icon-container">
            {!props.hide_display_type_filter &&
                <React.Fragment>
                    <i
                        onClick={onOpenMenu(TOOLBOX_DISPLAY_TYPE)}
                        className={`fas fa-box-open fa-2x ${getFocus(TOOLBOX_DISPLAY_TYPE)}`}
                        title={translate("common.tooltip_show_input_produced", "Show input or produced materials")}
                    ></i>
                    <Select
                        id={"selected-display-type"}
                        styles={SelectStyle}
                        options={optionsDisplayTypes}
                        menuIsOpen={open_menu === TOOLBOX_DISPLAY_TYPE}
                        value={optionsDisplayTypes.find(dt => dt.value === selected_display_type)}
                        onChange={(selected: SelectOption) => handleSelectChange(selected.value, props.handleStockForecastDisplayTypeChange)}
                    />
                </React.Fragment>
            }
        </div>

        <div id="icon-container">
            {!props.hide_lines_filter &&
                <React.Fragment>
                    <i
                        className={`fas fa-bars fa-2x ${getFocus(TOOLBOX_SELECTED_LINE)}`}
                        onClick={onOpenMenu(TOOLBOX_SELECTED_LINE)}
                        title={translate("common.tooltip_filter_production_lines", "Filter by production lines")}
                    ></i>
                    <Select
                        id={"selected-line"}
                        styles={SelectStyle}
                        options={optionsLines}
                        onChange={(selected: SelectOption) => handleSelectChange(selected.value, props.changeSelectedLineUUID)}
                        value={optionsLines.find(l => l.value === selected_line_uuid)}
                        menuIsOpen={open_menu === TOOLBOX_SELECTED_LINE}
                    />
                </React.Fragment>
            }
        </div>

        <div ref={tooltip_ref}>
            <i
                className={`fas fa-file-alt fa-2x ${getFocus(TOOLBOX_WORKORDER)}`}
                onClick={onOpenMenu(TOOLBOX_WORKORDER)}
                title={translate("common.tooltip_filter_production_orders", "Filter by production orders")}
            ></i>
            {props.onChangeOrderExternalId && <TooltipSearchOrder
                tooltip_ref={tooltip_ref.current}
                open_menu={open_menu}
                setStateOpenMenu={setStateOpenMenu}
                order_external_ids={order_external_ids}
                onChange={props.onChangeOrderExternalId}
            />}
        </div>
    </div>
}

type MaterialTitleProps = {
    title: string,
    onTitleChange?: (e: Event) => void
}
type MaterialTitleState = {
    title: string
}
class MaterialTitle extends React.Component<MaterialTitleProps, MaterialTitleState> {

    state = {
        title: ""
    }

    componentDidMount(){
        this.setState({
            title: this.props.title
        });
    }

    onChange = (event: Event) => {
        if (event.currentTarget instanceof HTMLInputElement) {
            // read new values
            const target = event.currentTarget;
            const value = target.value;

            this.setState({
                title: value
            });

            if (this.props.onTitleChange) {
                this.props.onTitleChange(event)
            }
        }
    }

    render() {
      if (!this.props.onTitleChange) {
        return <span className="material-title">{translate("common.material", "Material")}</span>
      }

      return <input type="text" className="form-control"
            name={"input_title"}
            value={this.state.title}
            onChange={this.onChange}
            style={{ opacity: "0.5", minWidth: "100px" }}
            placeholder={translate("common.material", "Material")}
        />
    }
}

type AggregationDropdownProps = {
    selected_aggregate: string,
    onAggreagationChange: (value: AggregationTimeWindow) => void
};

const AggregationDropdown = (props: AggregationDropdownProps) => {
    return (
        <div className="dropdown ml-2">
            <button
                className="btn btn-outline-secondary dropdown-toggle"
                type="button"
                id="aggregationDropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
            >
                {translateAggregationTimeWindow(props.selected_aggregate)}
            </button>
            <div className="dropdown-menu" aria-labelledby="aggregationDropdownMenuButton">
                <a
                    className={classNames("dropdown-item", {
                        active: props.selected_aggregate === "shift"
                    })}
                    onClick={() => props.onAggreagationChange("shift")}
                >
                    {translateAggregationTimeWindow("shift")}
                </a>
                <a
                    className={classNames("dropdown-item", {
                        active: props.selected_aggregate === "day"
                    })}
                    onClick={() => props.onAggreagationChange("day")}
                >
                    {translateAggregationTimeWindow("day")}
                </a>
                <a
                    className={classNames("dropdown-item", {
                        active: props.selected_aggregate === "week"
                    })}
                    onClick={() => props.onAggreagationChange("week")}
                >
                    {translateAggregationTimeWindow("week")}
                </a>
            </div>
        </div>
    );
};

const MaterialStockForecastHeader = (props: Props) => {
    const {
        day_headers, shift_headers, onTitleChange,
        selected_aggregate, show_affiliated_stock,
        safety_stock_show, stock_forecast_filter
    } = props;

    if (!selected_aggregate || day_headers.length === 0) return null;

    const title = (stock_forecast_filter && stock_forecast_filter.title) || "";

    let ShiftHeaderComponent = null;
    let DayHeaderComponent = null;

    if (selected_aggregate === "shift") {
        DayHeaderComponent = <ShiftAggregation day_headers={day_headers} shift_headers={shift_headers} stickyOffsetTop={props.stickyOffsetTop} />;

        const shift_names = ["1", "2", "3"];

        ShiftHeaderComponent = shift_headers.map((x, idx) =>
            <th key={idx + "_shift_headers"}
                id="shift-number" className={x.shift === 2 ? "day-end" : ""}
                style={{top: props.stickyOffsetTop + 40}}
            >
                {shift_names[x.shift]}
            </th>
        );
    }

    let sticky_stock_status_offset = 40;

    if (selected_aggregate === "day") {
        DayHeaderComponent = <DayAggregation day_headers={day_headers} stickyOffsetTop={props.stickyOffsetTop}/>;
        sticky_stock_status_offset = 14;
    }

    if (selected_aggregate === "week") {
        DayHeaderComponent = <WeekAggregation day_headers={day_headers} stickyOffsetTop={props.stickyOffsetTop} />;
        sticky_stock_status_offset = 14;
    }

    return (
        <thead>
            <tr>
                <th rowSpan="2" name="title" className="day-end date" style={{ top: props.stickyOffsetTop }}>
                    <div className="stock-forecast-input-and-toolbox">
                        <MaterialTitle onTitleChange={onTitleChange} title={title} />

                        {props.changeSelectedHorizonStockForecast && (
                            <AggregationDropdown
                                selected_aggregate={selected_aggregate}
                                onAggreagationChange={props.changeSelectedHorizonStockForecast}
                            />
                        )}

                        {props.show_toolbox && <ToolBox
                            key={"toolbox"}
                            stock_forecast_filter={props.stock_forecast_filter}
                            handleStockForecastDisplayTypeChange={props.handleStockForecastDisplayTypeChange}
                            changeSelectedLineUUID={props.changeSelectedLineUUID}
                            onChangeOrderExternalId={props.onChangeOrderExternalId}
                            hide_display_type_filter={props.hide_display_type_filter}
                            hide_lines_filter={props.hide_lines_filter}
                            onChange={() => { console.log("On change event ToolBox...") }}
                        />}
                    </div>
                </th>

                <th className="day-end date" style={{top: props.stickyOffsetTop, height: "15px"}}></th>
                { show_affiliated_stock &&
                    <th className="day-end date" style={{top: props.stickyOffsetTop, height: "15px"}}></th>}
                { safety_stock_show &&
                    <th className="day-end date" style={{top: props.stickyOffsetTop, height: "15px"}}></th>}
                { DayHeaderComponent }
            </tr>
            <tr>
                <th id="stock-status" className="day-end" style={{ top: props.stickyOffsetTop + sticky_stock_status_offset, zIndex: 0 }}>
                    {translate("Manufacturing.StocksView.stock_status", "Stock Status")}
                </th>
                { show_affiliated_stock &&
                    <th id="affiliated-stock-status" className="day-end" style={{ top: props.stickyOffsetTop + sticky_stock_status_offset }}>
                        {translate("Manufacturing.MaterialStockForecast.affiliated_stock_status", "Affiliated stock")}
                    </th>
                }
                { safety_stock_show &&
                    <th id="affiliated-stock-status" className="day-end" style={{ top: props.stickyOffsetTop + sticky_stock_status_offset }}>
                        {translate("Manufacturing.MaterialStockForecast.safety_stock_status", "Safety stock")}
                    </th>
                }

                {ShiftHeaderComponent}
            </tr>
        </thead>
    );
}

export default MaterialStockForecastHeader;


