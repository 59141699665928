// @flow
import * as React from "react";
import { translate } from "../IntlProviderWrapper";

import { getEmptyWeekShiftGrid } from "../../lib/Insights";
import type { InsightWeekShiftGrid } from "../../lib/Insights";
import { getDateRangeStrForWeek, shiftNumber, dateFromWeekAndShift } from "../../lib/Util";
import type { WeeklyReport } from "../../lib/Models";
import { realizationGrid } from "../../lib/WeeklyRealization";
import * as t2 from "../../lib/SimulationReportModels";

import ShiftTableProduction from "./ShiftTableProduction";

type State = {
};

type Props = {
    line_uuid: string,
    realization: Map<number, WeeklyReport[]>,
    orders: t2.ISimulationReportOrderEx[],
    events: Map<number, InsightWeekShiftGrid>,
    current_shift: number,
    week_offsets: number[],
    week: number,
    year: number
};


class PlanShiftTableProduction extends React.Component<Props, State> {

    renderShiftTables() {
        // define layout based on the number of weeks
        let layoutDef = "col";
        if (this.props.week_offsets.length === 3) {
            layoutDef = "col-xl-4 col-lg-12";
        }
        if (this.props.week_offsets.length === 4) {
            layoutDef = "col-lg-6 col-md-12";
        }

        // prepare order map that is shared between all week tables
        const orders_map: Map<string, t2.ISimulationReportOrderEx> = new Map();
        for (const order of this.props.orders) {
            orders_map.set(order.order_id, order);
        }

        // create tables
        let tables = this.props.week_offsets.map((week_ref, i) => {
            const week_year = shiftNumber(dateFromWeekAndShift(this.props.week + week_ref, this.props.year, 0));
            const table_week = week_year.week;
            const table_year = week_year.year;

            const curr_shift  = (week_ref === 0) ? this.props.current_shift : -1;
            // get realization grid
            let week_realization = this.props.realization.get(table_week);
            if (!week_realization) { week_realization = [ ]; }
            const week_realization_grid = realizationGrid(this.props.line_uuid, week_realization);
            // get insights grid
            let week_insights_grid = this.props.events.get(table_week);
            if (!week_insights_grid) { week_insights_grid = getEmptyWeekShiftGrid(); }
            // render table
            return (
                // fix this key
                <div className={layoutDef} key={`sht_${this.props.line_uuid}_${this.props.year}_${this.props.week}_${week_ref}`}>
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="white_box charts">
                                <h6 className="clearfix">
                                    <b>
                                        {translate("PlanShiftTableProduction.planned_shifts_for_week", "Planned shifts for week")}
                                        <span> </span>
                                        {table_week}/{table_year}
                                    </b>
                                    <i>{getDateRangeStrForWeek(table_week, table_year)}</i>
                                </h6>
                                <div className="chart-wrapper shift-table-wrapper">
                                    <ShiftTableProduction
                                        shifts={week_realization_grid}
                                        orders={orders_map}
                                        insights={week_insights_grid}
                                        week={table_week} year={table_year}
                                        current_week={this.props.week} current_shift={curr_shift}
                                        show_dates={true}
                                        handleClick={() => {}} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        });
        return tables;
    }

    render() {
        return (
            <div className="row">
                {this.renderShiftTables()}
            </div>
        );
    }

}

export default PlanShiftTableProduction;
