// @flow
import * as React from "react";
import { connect } from "react-redux";
import ErrorComponent from "../../ErrorComponent";
import { UPDATE_LINES_ORDERS_ACTION_NAME } from "./reducers/linesOrders"
import { SET_ERROR } from "./reducers/planningTable";
import { translate } from "../../IntlProviderWrapper";

import type { ReduxState } from "./reducers/reducers";
import type { UpdateLinesOrdersAction, LineOrders } from "./reducers/linesOrders";
import type { SetErrorAction } from "./reducers/planningTable";
import { deepClone } from "../../../lib/Util";

type Props = {
    reduxDispatch: (
        args: UpdateLinesOrdersAction | SetErrorAction
    ) => any,
    lines_orders: LineOrders[],
    report_loading: boolean,
    error: string
}
type State = {
    event_msg: string | null,
    onCancel: () => void
}


export class ErrorHandler extends React.Component<Props, State> {

    nodeRef = null;
    errorHandlerListener = null;

    state = {
        event_msg: null,
        onCancel: () => {}
    }

    constructor(props: Props) {
        super(props);
        this.nodeRef = React.createRef();
    }

    componentDidMount() {
        if (this.nodeRef && this.nodeRef.current) {
            this.errorHandlerListener = (e: Event) => this.onErrorEventHandler(e);
            this.nodeRef.current.addEventListener("error-handler-event", this.errorHandlerListener);
        }
    }
    
    onErrorEventHandler = (event: Event) => {
        const detail = event instanceof CustomEvent ? event.detail : {};
        this.setState({ event_msg: detail.msg, onCancel: detail.onCancel });
    }

    componentWillUnmount() {
        if (this.nodeRef && this.nodeRef.current && this.errorHandlerListener) {
            this.nodeRef.current.removeEventListener("error-handler-event", this.errorHandlerListener);
        }
    }

    onClickRetry = () => {
        this.props.reduxDispatch({ type: SET_ERROR, data: null });
        this.props.reduxDispatch({
            type: UPDATE_LINES_ORDERS_ACTION_NAME,
            data: deepClone(this.props.lines_orders)
        });
    }

    retrySimulationEl = () => {
        if (this.props.report_loading) {
            return null;
        }

        const style_close = {
            top: "0px",
            float: "right",
            cursor: "pointer",
            marginBottom: "5px"
        };

        return <div onClick={this.onClickRetry} style={style_close}>
            <i className="fas fa-redo"></i>
        </div>
    }

    onCancel = () => {
        if (this.state.onCancel) {
            this.state.onCancel();
        }
        this.setState({ event_msg: "" });
    }

    getCancelEl = (): React.Node => {
        /*if (!this.props.report_loading) {
            return null;
        }
        return <div onClick={this.state.onCancel} style={{marginLeft: "5px"}}>
            <button className="btn btn-primary" style={{margin: "unset"}} onClick={this.onCancel}>
                {translate("common.cancel", "Cancel")}
            </button>
        </div>*/

        // TODO - we are currently not supporting canceling of simulation requests
        return null;
    }

    onClose = () => {
        this.setState({ event_msg: "" });
        this.props.reduxDispatch({ type: SET_ERROR, data: null });
    }

    errorMsg = () => {
        if (this.props.error) {
            return <span title={this.props.error}>
                {translate("common.simulation_error", "Please try again. An error occured while processing simulation data.")}
            </span>
        }
    }

    render() {
        let error_el = null;
        if (this.state.event_msg && this.props.report_loading) {
            error_el = <div className="left-menu-error-sticky" style={{ display: "flex" }}>
                <div
                    style={{ wordBreak: "break-word" }}
                    className="alert alert-warning mb-0 rounded-0"
                    style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}
                >
                    {this.state.event_msg} {this.getCancelEl()}
                </div>
            </div>
        } else if (this.props.error) {
            error_el = <div className="left-menu-error-sticky">
                <ErrorComponent
                    className="mb-0 rounded-0"
                    msg={this.errorMsg()}
                    type="warning"
                    onlyDiv={true}
                    is_closable={true}
                    onClose={this.onClose}
                />
            </div>
        }

        return <div id="error-handler" ref={this.nodeRef}>
            {error_el}
        </div>
    }
    
}

export default connect(
    (state: ReduxState) => {
        const report = state.gantt_chart_report;
        const planning_table = state.gantt_chart_planning_table;
        const lines_orders = state.gantt_chart_lines_orders.lines_orders;

        return {
            report_loading: report.report_loading,
            lines_orders,
            error: planning_table.error
        };
    },
    (dispatch) => ({ reduxDispatch: dispatch })
)(ErrorHandler);
