// @flow
import * as React from "react";

import * as Auth from "../lib/Auth";
import ChangePassword from "./ChangePassword";
import { translate } from "./IntlProviderWrapper";


type Props = {
    history: Array<mixed>
};

type State = {
    redirect_url: string
};

class ChangePasswordStandalone extends React.Component<Props, State> {
    constructor() {
        super();
        this.state = {
            redirect_url: Auth.getLoginRedirectURL()
        };
    }

    onClose() {
        const redirectURL = Auth.getLoginRedirectURL();
        this.props.history.push(redirectURL);
    }

    render() {
        return (
            <section id="register" key="1">
                <div className="caption_div">
                    <div className="form_box">
                        <ChangePassword
                            message={ translate("Login.PwdExpired", "Your password expired and needs to be changed")}
                            onClose={() => this.onClose()}
                        />
                    </div>
                </div>
            </section>
        );
    }
};

export default ChangePasswordStandalone;
