// @flow
import * as React from "react";
import { Button, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import { Link } from "react-router-dom";

import type { RegisterUserObj } from "../lib/Models";
import * as Auth from "../lib/Auth";
import ErrorComponent from "./ErrorComponent";
type Props = {
    history: Array<mixed>
};

type State = {
    user: RegisterUserObj,
    message: string,
    providers: string[]
};

class Register extends React.Component<Props, State> {
    constructor() {
        super();

        this.state = {
            user: {
                title: "",
                username: "",
                password: "",
                password2: "",
                accept_terms: false,
                provider: "",
                provider_username: ""
            },
            message: "",
            providers: []
        };
    }

    async componentDidMount() {
        try {
            const providers = await Auth.oauth2Providers();
            this.setState({ providers: providers });
        } catch (err) {
            console.log(err);
        }
    };

    validateForm() {
        return this.state.user.accept_terms;
    }

    handleChange = (event: Event) => {
        let target = event.target;
        if (target instanceof HTMLInputElement) {
            // collect changed field name and value
            const field = target.id;
            const value = target.type === 'checkbox' ?
                target.checked : target.value;
            // update
            this.setState(prev_state => {
                prev_state.user[field] = value;
                return prev_state;
            });
        }
    }

    handleSubmit = async (event: Event) => {
        event.preventDefault();
        try {
            // send collected parameters through validation
            const message = Auth.validate(this.state.user);
            // report in case we got something
            this.setState({ message });
            // if we got no message, proceed with registration
            if (message === "") {
                await Auth.register(this.state.user);
                this.setState({ message: "" });
                this.props.history.push("/login");
            }
        } catch (err) {
            this.setState({ message: err.message });
        }
    };

    loginOauth2 = async (provider: string) => {
        try {
            await Auth.oauth2Initiate(provider, "register");
        } catch (err) {
            this.setState({ message: err.message });
        }
    }

    renderOauthProvider = (type: string, title: string) => {
        return <Button block className="btn_register" key={type} onClick={() => { this.loginOauth2(type); }}>{title}</Button>
    }

    renderOauthProviders = () => {
        if (this.state.providers.length === 0) {
            return null;
        }
        const res = [<hr key="xyz" />, <h3 key="xyz2"> Log in using external ID</h3>];
        if (this.state.providers.indexOf("github") >= 0) {
            res.push(this.renderOauthProvider("github", "GitHub"));
        }
        if (this.state.providers.indexOf("azure-ad") >= 0) {
            res.push(this.renderOauthProvider("azure-ad", "Microsoft Azure Active Directory"));
        }
        if (this.state.providers.indexOf("adfs") >= 0) {
            res.push(this.renderOauthProvider("adfs", "Active Directory"));
        }
        return res;
    }

    render_alert() {
        return (
            <div className="row">
                <div className="col-12">
                    <ErrorComponent msg={this.state.message} type="warning" />
                </div>
            </div>
        );
    }

    render() {
        return (
            <section id="register">
                <div className="caption_div">
                    <form onSubmit={this.handleSubmit} className="form-horizontal m-t-20">
                        <div className="form_box">
                            <h3>Create an account</h3>
                            {this.state.message !== "" && this.render_alert()}
                            <FormGroup controlId="title" bsSize="large" className="form-label-group">
                                <FormControl autoFocus type="text" placeholder="Your full name"
                                    value={this.state.user.title} onChange={this.handleChange} />
                                <ControlLabel>Name</ControlLabel>
                            </FormGroup>
                            <FormGroup controlId="username" bsSize="large" className="form-label-group">
                                <FormControl type="text" placeholder="Your email address"
                                    value={this.state.user.username} onChange={this.handleChange} />
                                <ControlLabel>E-mail address</ControlLabel>
                            </FormGroup>
                            <FormGroup controlId="password" bsSize="large" className="form-label-group">
                                <FormControl type="password" placeholder="Your password"
                                    value={this.state.user.password} onChange={this.handleChange} />
                                <ControlLabel>Password</ControlLabel>
                            </FormGroup>
                            <FormGroup controlId="password2" bsSize="large" className="form-label-group">
                                <FormControl type="password" placeholder="Your password"
                                    value={this.state.user.password2} onChange={this.handleChange} />
                                <ControlLabel>Re-enter Password</ControlLabel>
                            </FormGroup>
                            <FormGroup controlId="accept_terms" bsClass="custom-control custom-checkbox checkbox">
                                <FormControl type="checkbox" checked={this.state.user.accept_terms}
                                    onChange={this.handleChange} bsClass="custom-control-input" />
                                <label className="custom-control-label" htmlFor="accept_terms">
                                    I accept <Link to="/terms" className="blue_link_inline">Terms and Conditions</Link> and <Link to="/privacy" className="blue_link_inline">Privacy Policy</Link>
                                </label>
                            </FormGroup>
                            <div className="form-group row">
                                <div className="col-12">
                                    <Button block className="btn_register btn-primary"
                                        disabled={!this.validateForm()} type="submit">Register</Button>
                                </div>
                            </div>
                            <Link to="/Login" className="blue_link text-center">Already have an account?</Link>
                            {this.renderOauthProviders()}
                        </div>
                    </form>
                </div>
            </section>
        );
    }
};

export default Register;
