// @flow
import * as React from "react";
import { IS_INSIDE_ORDER, TRANSLATE_CURSOR, DRAGGING_ORDER } from "./reducers";
import { DraggableConsumer } from "./DraggableContext";
import { DraggableLogic, ReduxFilters } from "../reducers/BusinessLogic";
import { SHIFT_CONSTS, TIME_RANGES } from "../../../../lib/Util";
import { DraggableEventHandler } from "./DraggableContextHelper";

import type { GanttChartSourceTypes } from "../reducers/properties";
import type { DraggingOrder, TranslatePoint } from "./reducers";
import type { DraggableProviderContext } from "./DraggableContext";


type DraggableRectProps = {
    x: number,
    y: number,
    width: number,
    line_uuid: string,
    order_index?: number,
    order_uuid: string,
    children?: React.Node,
    line_index?: number,
    earliest_start?: number,
    earliest_end: number,
    source_type: GanttChartSourceTypes,
    is_unscheduled: boolean,
    is_extra_line?: boolean,
    production_version: string,
    is_highlighted?: boolean
};

type DraggableShiftRectProps = {
    x: number,
    y: number,
    width: number,
    line_uuid: string,
    children?: React.Node,
    line_index?: number,
    earliest_start?: number,
    earliest_end: number,
    source_type: GanttChartSourceTypes,
    is_unscheduled: boolean,
    is_extra_line?: string,
    is_first_shift?: boolean
};


const hasTranslateChanged = (x: number, y: number, translate: TranslatePoint | null): boolean => {
    if (!translate) {
        return true;
    }
    return translate.translate_x !== x || translate.translate_y !== y;
}

export const getOrders = (line_index?: number, current_order_uuid: string | null, earliest_end?: number, source_type: string): HTMLElement[] => {
    if (line_index === undefined || current_order_uuid === null || !earliest_end) {
        return [];
    }

    // first check if the order lies on a parallel line, if so we ignore line index and use line uuid
    const el = DraggableLogic.querySelector(source_type, `#pane1 g[name="line-label"][line-index="${line_index}"]`);
    if (el && current_order_uuid) {
        const line_uuid = el.getAttribute("line-uuid");
        if (line_uuid) {
            const orders = Array.from(
                document.querySelectorAll(`#pane1 g[name="order"][line-uuid="${line_uuid}"]`)
            );
            const filtered_orders = [];
            for (const order of orders) {
                const order_uuid_el = order.closest("[order-uuid]") || (order.getAttribute("order-uuid") && order);
                if (order_uuid_el && order_uuid_el.getAttribute("order-uuid") === current_order_uuid) {
                    continue;
                }
                const order_earliest_end = parseFloat(order.getAttribute("earliest-end"));
                if (order_earliest_end <= earliest_end) {
                    filtered_orders.push(order);
                }
            }

            return filtered_orders;
        }
    }
    return [];
}


type RectState = {};

const onMouseEnterEventName = (order_uuid: string) => {
    return `mouse-enter-${order_uuid}`;
}


type MouseEnterEventState = {
    name: string,
    order_uuid?: string
};
export const onMouseEnterDispatch = (order_uuid: string, data?: MouseEnterEventState) => {
    const event = new CustomEvent(onMouseEnterEventName(order_uuid), {detail: data});
    window.dispatchEvent(event);
}

const disableDrop = (value: DraggableProviderContext) => {
    if (value.state.is_inside_order) {
        value.dispatch({ type: IS_INSIDE_ORDER, data: false });
    }
    DraggableLogic.changeMouseCursor("not-allowed");
}

const enableDrop = (value: DraggableProviderContext) => {
    if (!value.state.is_inside_order) {
        value.dispatch({ type: IS_INSIDE_ORDER, data: true });
    }
    DraggableLogic.changeMouseCursor("move");
}

type OrderRectProps = DraggableRectProps;
type OrderRectState = {};

export class DraggableRect extends React.PureComponent<OrderRectProps, OrderRectState> {

    onMouseEnterEventListener = null;

    componentDidMount() {
        this.load();
    }

    componentWillUnmount() {
        this.unload();
    }

    load() {
        this.onMouseEnterEventListener = (e: Event) => this.onMouseEnter(e);
        window.addEventListener(
            onMouseEnterEventName(this.props.order_uuid),
            this.onMouseEnterEventListener
        );
    }

    unload() {
        if (this.onMouseEnterEventListener) {
            window.removeEventListener(
                onMouseEnterEventName(this.props.order_uuid), this.onMouseEnterEventListener
            );
        }
        this.onMouseEnterEventListener = null;

    }

    onMouseEnter = async (e: Event) => {
        e.stopPropagation();
        e.preventDefault();
        const line_index = this.props.line_index;
        if (line_index == undefined) {
            console.error("MISSING LINE INDEX!", this.props);
            return;
        }
        const data = {
            line_uuid: this.props.line_uuid,
            is_unscheduled: this.props.is_unscheduled,
            x: this.props.x,
            y: this.props.y,
            width: this.props.width,
            is_extra_line: !!this.props.is_extra_line,
            line_index: line_index,
            earliest_end: this.props.earliest_end,
            source_type: this.props.source_type,
            production_version: this.props.production_version,
            inside_order_uuid: this.props.order_uuid
        };
        DraggableEventHandler.dispatchInsideOrderEvent(data);
    }

    render() {
        return <rect
            {...this.props}
            is_unscheduled={this.props.is_unscheduled ? "true" : "false"}
            is_extra_line={this.props.is_extra_line ? "true" : "false"}
            is-highlighted={this.props.is_highlighted ? "true" : "false"}
            onMouseEnter={this.onMouseEnter}
        >
            {this.props.children ? this.props.children : null}
        </rect>
    }
}

const manualDispatchOrderMouseEnter = (dispatch_to_order_uuid: string) => {
    const event = new CustomEvent(onMouseEnterEventName(dispatch_to_order_uuid), {detail: { order_uuid: dispatch_to_order_uuid }});
    window.dispatchEvent(event);
}

class ShiftRect extends React.PureComponent<DraggableShiftRectProps & {value: DraggableProviderContext}, RectState> {
    rect_ref: Element | null = null;

    componentDidMount() {
        if (this.rect_ref !== null) {
            this.rect_ref.addEventListener("drag", this.onDrag);
        }
    }

    componentWillUnmount() {
        if (this.rect_ref !== null) {
            this.rect_ref.removeEventListener("drag", this.onDrag);
        }
    }

    skipDrag = (dragging_order_uuid: string) => {
        // if an order is ended inside the shift, then we do not initiate drag and drop
        const earliest_start = this.props.earliest_start;
        if (!earliest_start) {
            return false;
        }

        const is_droppable = ReduxFilters.isDroppableLineUuid(
            this.props.line_uuid,
            dragging_order_uuid,
            this.props.is_unscheduled
        );
        if (!is_droppable) {
            return true;
        }

        const line_uuid = this.props.line_uuid;
        const line_index = this.props.line_index || 0;

        const orders = document.querySelectorAll(
            `[name="order"][line-uuid="${line_uuid}"][line-index="${line_index}"]:not([order-uuid="${dragging_order_uuid}"]):not([data-is-unscheduled="true"])`
        );

        let order_uuid = null;
        for (const order of orders) {
            const order_earliest_end = parseFloat(order.getAttribute("earliest-end"));
            const shift_h = SHIFT_CONSTS.SHIFT_DURATION_HOURS * TIME_RANGES.HOUR;
            if (order_earliest_end >= earliest_start && order_earliest_end < earliest_start + shift_h) {
                order_uuid = order.getAttribute("order-uuid");
            } else {
                if (order_uuid) {
                    break;
                }
            }
        }
        if (order_uuid) {
            manualDispatchOrderMouseEnter(order_uuid);
            return true;
        }
        return false;
    }

    translate = (dragging_order: DraggingOrder, x: number, earliest_start = this.props.earliest_start) => {
        const value = this.props.value;
        if (hasTranslateChanged(this.props.x, this.props.y, value.state.translate_cursor)) {
            value.dispatch({
                type: TRANSLATE_CURSOR,
                data: {
                    translate_x: x,
                    translate_y: this.props.y
                }
            });
        }

        if (hasTranslateChanged(this.props.x, this.props.y, dragging_order)) {
            if (dragging_order) {
                const new_order_index = getOrders(
                    this.props.line_index, dragging_order.order_uuid, earliest_start, this.props.source_type
                ).length;

                // $FlowFixMe
                value.dispatch({
                    type: DRAGGING_ORDER,
                    data: {
                        translate_x: x,
                        translate_y: this.props.y,
                        new_line_uuid: this.props.line_uuid,
                        new_order_index,
                        line_index: this.props.line_index,
                        earliest_start,
                        is_unscheduled_order: dragging_order.is_unscheduled_order
                    }
                });
            }
        }
    }

    isPreciseMode = (is_shift_down: boolean): boolean => {
        const value = this.props.value;
        return value.state.snap_to_shift && is_shift_down || !value.state.snap_to_shift && !is_shift_down;
    };

    onMouseEnter = (e: MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();

        if (this.isPreciseMode(e.shiftKey)) {
            return;
        }

        const value = this.props.value;
        const dragging_order = value.state.dragging_order;
        if (!dragging_order) {
            return;
        }

        if (this.skipDrag(dragging_order.order_uuid) && !this.props.is_first_shift) {
            disableDrop(value);
            return;
        }

        enableDrop(value);

        const x = this.props.is_first_shift ? this.props.x + this.props.width : this.props.x;
        this.translate(dragging_order, x);
    }

    onDrag = (e: MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();

        if (!this.isPreciseMode(e.shiftKey)) {
            return;
        }

        const value = this.props.value;
        const dragging_order = value.state.dragging_order;
        if (!dragging_order) {
            return;
        }

        enableDrop(value);

        let x = this.props.x;
        let earliest_start = this.props.earliest_start
        if (e.target instanceof Element) {
            const rect = e.target.getBoundingClientRect();
            const rel_x = e.clientX - rect.left;
            x += rel_x;
            earliest_start += rel_x / this.props.width * SHIFT_CONSTS.SHIFT_DURATION_HOURS * TIME_RANGES.HOUR;
        }

        this.translate(dragging_order, x, earliest_start);
    }

    render() {
        return <rect
            ref={(ref) => { this.rect_ref = ref; }}
            {...this.props}
            is_first_shift={this.props.is_first_shift ? "true" : "false"}
            is_unscheduled={this.props.is_unscheduled ? "true" : "false"}
            onMouseEnter={this.onMouseEnter}
        >
            {this.props.children ? this.props.children : null}
        </rect>
    }
}


export class DraggableShiftRect extends React.Component<DraggableShiftRectProps, any> {
    render() {
        if (this.props.is_extra_line) {
            return <rect
                {...this.props}
            >
                {this.props.children ? this.props.children : null}
            </rect>
        }
        return <DraggableConsumer>
            {(value: ?DraggableProviderContext) => {
                if (!value) {
                    return null;
                }
                return <ShiftRect
                    {...this.props}
                    dragging_order={value.state.dragging_order}
                    value={value}
                >
                    {this.props.children ? this.props.children : null}
                </ShiftRect>
            }}
        </DraggableConsumer>
    }
}

type DraggableEmitterProps = {
    order_uuid: string,
    name: string,
    style?: any,
    is_extra_line: boolean,
    is_disabled_shift: boolean
}


export const DraggableEmitterRect = (props: DraggableEmitterProps) => {
    if (props.is_extra_line) {
        return <rect
            {...props}
            is_extra_line={props.is_extra_line ? "true" : "false"}
        ></rect>
    }
    return <DraggableConsumer>
        {(value: ?DraggableProviderContext) => {
            if (!value) {
                return null;
            }
            const order_uuid = props.order_uuid;
            let filter = "";
            if (props.name === "shift" && props.is_disabled_shift &&
                !(value.state.dragging_order && value.state.dragging_order.order_uuid === order_uuid)) {
                filter = "url(#grayscale)";
            }
            return <rect
                {...props}
                filter={filter}
                is_extra_line={props.is_extra_line ? "true" : "false"}
                onMouseEnter={() => { onMouseEnterDispatch(order_uuid, { name: props.name, order_uuid }); }}
            ></rect>

        }}
    </DraggableConsumer>
}
