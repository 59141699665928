// @flow
import * as t from "../../../../lib/backend/manufacturing2.generated.types";

import type { LineOrders } from "./linesOrders";
import type { RecommendPlanParamsAndCriteria } from "../RecommendPlan";
import type { PayloadAction } from "../../../redux/types";
import { deepClone } from "../../../../lib/Util";

export const UPDATE_RECOMMEND_PLAN: "update_recommend_plan" = "update_recommend_plan";
export const UPDATE_REPORT_UUID: "update_report_uuid" = "update_report_uuid";


export type RunRecommendPlanParameters = {
    line_group_uuid: string,
    line_uuids: string[],
    lines_orders: LineOrders[],
    state: RecommendPlanParamsAndCriteria,
    planning_weeks: number,
    freeze_orders: t.IRunLinegroupPlanningFreezeOrder[]
}

export type RecommendPlan = {
    plan_uuid: string,
    parameters: RunRecommendPlanParameters,
    report_uuid?: string,
    plan_created_ts: number,
    stock_report_uuid?: string
}

export type UpdateRecommendPlan = {
    plan_uuid: string,
    parameters: RunRecommendPlanParameters,
    plan_created_ts: number,
    stock_report_uuid?: string
}

export type RecommendPlanState = {
    recommend_plans: RecommendPlan[]
}

export type UpdateRecommendPlanAction = PayloadAction<typeof UPDATE_RECOMMEND_PLAN, UpdateRecommendPlan>;
export type UpdateReportRecommendPlanAction = PayloadAction<typeof UPDATE_REPORT_UUID, UpdateReportRecommendPlan>;
export type RecommendPlanActions = UpdateRecommendPlanAction | UpdateReportRecommendPlanAction;

type UpdateReportRecommendPlan = {
    plan_uuid: string,
    report_uuid: string
}

const recommend_plan_state = {
    recommend_plans: []
}

export const recommendPlanReducer = (state: RecommendPlanState, action: RecommendPlanActions): RecommendPlanState => {
    if (!state) {
        state = deepClone(recommend_plan_state);
    }

    if (action.type === UPDATE_RECOMMEND_PLAN) {
        const recommend_plans = state.recommend_plans;
        recommend_plans.push({
            plan_uuid: action.data.plan_uuid,
            parameters: action.data.parameters,
            plan_created_ts: action.data.plan_created_ts,
            stock_report_uuid: action.data.stock_report_uuid
        });
        return { ...state, recommend_plans: [...recommend_plans] };
    } else if (action.type === UPDATE_REPORT_UUID) {
        const { plan_uuid, report_uuid } = action.data;
        const plan = state.recommend_plans.find(p => p.plan_uuid === plan_uuid);
        if (plan) {
            plan.report_uuid = report_uuid;
            return { recommend_plans: [...state.recommend_plans] };
        }
    }

    return state;
}
