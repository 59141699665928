// @flow
import * as React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { getBackend as getBackend2 } from "../lib/backend/Backend2";
import * as ta from "../lib/backend/alerts.generated.types";
import * as td from "../lib/backend/datasources.generated.types";

import type {
    SelectedDatasourceObj
} from "../lib/Models";
import { niceNumber } from "../lib/Util";

import EventTable from "./EventTable";
import Box from "./Box";
import HighchartsLine from "./HighchartsLine";
import ErrorComponent from "./ErrorComponent";

// function that retreives additional data needed to display event
// and prepares the selected datasource object to be stored in the datasource
// state page
export async function getDatasourceDetails(datasource_uuid: string): Promise<SelectedDatasourceObj> {
    // placeholder for results
    let res: SelectedDatasourceObj = {
        datasource: null,
        analyses: [],
        analysis_data: [],
        recent_stats: null,
        recent_events_stats: null,
        extra_data: null,
        events: []
    };
    const res_events = await getBackend2().alerts.searchEvents({ datasource_uuid, limit: 10 });
    res.events = res_events.data;
    const res_events2 = await getBackend2().alerts.getStats({ datasource_uuid, n_hours: 24 });
    const res_ds = await getBackend2().datasources.getDatasourceDetails({ id: datasource_uuid });
    res.datasource = res_ds.data;
    res.extra_data = res_ds.extra_data_ts;
    res.recent_stats = res_ds.stats;
    res.recent_events_stats = res_events2;
    res.analyses = res_ds.analyses;
    return res;
}

type Props = {
    selected: SelectedDatasourceObj,
    error: string | null
};

type State = {};

// Prepares a list of events to be displayed on event page as a scrollable side bar on the le
class DatasourceDetails extends React.Component<Props, State> {

    // render main event and datapoint indicators
    renderIndicators(recent_stats: td.IDatasourceStats, recent_event_stats: ta.IGetStatsRes) {
        return (
            <div className="row">
                <div className="col-lg-6 col-md-6">
                    <Box value={recent_event_stats.alerts_data_24h}
                        label={"Incidents detected (<24h)"}
                        icon={"img/alert.svg"}
                        link={"/events"}
                        color="#ff352b"
                        sparkline={recent_event_stats.alerts_data_24h_by_hour.map(x => x.val)} />
                </div>
                <div className="col-lg-6 col-md-6">
                    <Box value={recent_stats.total}
                        label={"Datapoints recived (<24h)"}
                        icon={"img/linechart.png"}
                        link={"/datasources"}
                        color="#174291"
                        sparkline={recent_stats.by_hour.map(x => x.val)} />
                </div>
            </div>
        );
    };

    // render extra data associated with the current selected datasource type
    renderExtraData(selected: SelectedDatasourceObj) {
        let render_divs = [];
        // show input data
        const datasource = selected.datasource;
        if (!datasource) {
            return null;
        }
        const analyses = selected.analyses;
        const events = selected.events;
        const extra_data = selected.extra_data;
        if (datasource.type === "timeseries") {
            // prepare time series for high-carts component
            const input_ts_data = {
                id: "extra_data_input_" + datasource.uuid,
                height: 150,
                name: "raw input",
                y_label: "values",
                type: "line",
                ts: extra_data.input_ts,
                x_bands: []
            };
            // Prepare plot bands for downtime events
            let bands = [];
            let to = null;
            let bandColor = 'rgba(255, 0, 0, 0.2)'; // last value is opacity
            for (let event of events) {
                if (event.type === "downtime") {
                    if (event.extra_data.extreme_type === "end") {
                        to = event.ts;
                    }

                    if (event.extra_data.extreme_type === "start") {
                        bands.push({
                            from: event.ts,
                            to: (to) ? to : new Date().getTime(),
                            color: bandColor
                        });
                    }
                }
            }
            input_ts_data.x_bands = bands;


            render_divs.push((
                <div className="row" key="main-datasource-div">
                    <div className="col-lg-12 col-md-12">
                        <h3 className="table_header">Historic data</h3>
                        <div className="white_box charts">
                            <div className="row">
                                <div className="col-lg-12">
                                    {<HighchartsLine key={"a" + input_ts_data.ts.length} options={input_ts_data} events={events} />}
                                </div>
                            </div>
                        </div>
                        <div className="light" >Time-series uuid: {datasource.ingestion.input_ts_id}</div>
                    </div>
                </div>
            ));
        } else if (datasource.type === "events") {
            // render_divs.push((
            //     <div className="row" key="main-datasource-div">
            //         <div className="col-lg-12 col-md-12">
            //             <h3 className="table_header">Events</h3>
            //             <div>
            //                 <EventTable datasource_uuid={{ events: events }} />
            //             </div>
            //         </div>
            //     </div>
            // ));
        }

        if (analyses) {
            for (let an of analyses) {
                const data_matches = selected.analysis_data.filter(x => x.uuid === an.uuid);
                let an_data: td.ITimeseriesDataItem[] = [];
                if (data_matches.length > 0) {
                    an_data = data_matches[0].data;
                }
                if (an.analysis_type === "thresholds") {
                    render_divs.push((
                        <div className="row" key={"analysis-thresholds-" + an.uuid}>
                            <div className="col-lg-12 col-md-12">
                                <hr />
                                <div className="row justify-content-between">
                                    <div className="col-4">
                                        <h3 className="table_header">Manual Thresholds</h3>
                                    </div>
                                    <div className="col-4 text-right">
                                        <a href={"/analysis_edit/" + an.uuid} className="text">Edit</a>
                                    </div>
                                </div>
                                <div className="white_box charts">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <p className="data_source_text">
                                                <span>Alert below:</span> {an.analysis.params.threshold_low == null ? "-" : an.analysis.params.threshold_low}
                                            </p>
                                        </div>
                                        <div className="col-lg-6">
                                            <p className="data_source_text">
                                                <span>Alert above:</span> {an.analysis.params.threshold_high == null ? "-" : an.analysis.params.threshold_high}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ));
                }
                if (an.analysis_type === "spike") {
                    const output_ts_data = {
                        id: "extra_data_CDF_" + datasource.uuid,
                        height: 100,
                        name: "CDF",
                        y_max_val: 1.0,
                        y_label: "CDF",
                        type: "column",
                        color: 'gray',
                        ts: an_data
                    };
                    render_divs.push((
                        <div className="row" key={"analysis-spike-" + an.uuid}>
                            <div className="col-lg-12 col-md-12">
                                <hr />
                                <div className="row justify-content-between">
                                    <div className="col-4">
                                        <h3 className="table_header">Spike detection</h3>
                                    </div>
                                    <div className="col-4 text-right">
                                        <a href={"/analysis_edit/" + an.uuid} className="text">Edit</a>
                                    </div>
                                </div>
                                <div className="white_box charts">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <p className="data_source_text"><span>Alert below: </span>
                                                {an.analysis.params.alert_below_cdf == null ? "-" : an.analysis.params.alert_below_cdf * 100 + "%"}
                                            </p>
                                        </div>
                                        <div className="col-lg-6">
                                            <p className="data_source_text"><span>Alert above: </span>
                                                {an.analysis.params.alert_above_cdf == null ? "-" : an.analysis.params.alert_above_cdf * 100 + "%"}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="white_box charts">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            {<HighchartsLine key={"b" + output_ts_data.ts.length} options={output_ts_data} />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ));
                }
                if (an.analysis_type === "signal_edge") {
                    const output_ts_data = {
                        id: "sliding_average_" + datasource.uuid,
                        height: 100,
                        name: "avg",
                        y_label: "avg",
                        type: "line",
                        color: "gray",
                        ts: an_data
                    };
                    render_divs.push((
                        <div className="row" key={"analysis-signal-edge-" + an.uuid}>
                            <div className="col-lg-12 col-md-12">
                                <hr />
                                <div className="row justify-content-between">
                                    <div className="col-4">
                                        <h3 className="table_header">Signal edge</h3>
                                    </div>
                                    <div className="col-4 text-right">
                                        <a href={"/analysis_edit/" + an.uuid} className="text">Edit</a>
                                    </div>
                                </div>
                                <div className="white_box charts">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <p className="data_source_text"><span>Minimum outage duration: </span>
                                                {an.analysis.params.min_outage_duration == null ? "-" :
                                                    niceNumber(an.analysis.params.min_outage_duration / 60 / 1000, 2) + " min"}
                                            </p>
                                        </div>
                                        <div className="col-lg-6">
                                            <p className="data_source_text"><span>Data delay: </span>
                                                {an.analysis.params.data_delay == null ? "-" :
                                                    niceNumber(an.analysis.params.data_delay / 60 / 1000, 2) + " min"}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="white_box charts">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            {<HighchartsLine key={"c" + output_ts_data.ts.length} options={output_ts_data} />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ));
                }
                if (an.analysis_type === "downtime") {
                    render_divs.push((
                        <div className="row" key={"analysis-downtime-" + an.uuid}>
                            <div className="col-lg-12 col-md-12">
                                <hr />
                                <div className="row justify-content-between">
                                    <div className="col-4">
                                        <h3 className="table_header">Downtime detection</h3>
                                    </div>
                                    <div className="col-4 text-right">
                                        <a href={"/analysis_edit/" + an.uuid} className="text">Edit</a>
                                    </div>
                                </div>
                                <div className="white_box charts">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <p className="data_source_text"><span>Minimum outage duration: </span>
                                                {an.analysis.params.min_outage_duration == null ? "-" :
                                                    niceNumber(an.analysis.params.min_outage_duration / 60 / 1000, 2) + " min"}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ));
                }
                render_divs.push(<div className="light" key={"analysis-uuid-" + an.uuid}>Analysis uuid: {an.uuid}</div>);
            }
        }
        return render_divs;
    }

    renderEvents(events: any) {
        return (
            <div className="row">
                <div className="col-lg-12 col-md-12">
                    <hr />
                    <h3 className="table_header">
                        <FormattedMessage id="Header.menu.events" defaultMessage="Events" />
                    </h3>
                    <EventTable events={events} />
                    <Link className="blue_link" to="/admin/events">More &#187;</Link>
                </div>
            </div>
        )
    }

    // prepare list of events
    render() {
        if (this.props.selected && this.props.selected.datasource) {
            const selected = this.props.selected;
            const datasource = this.props.selected.datasource;
            return (
                <div className="tab-content" key={datasource.uuid}>
                    <div className="tab-pane active" id="home">
                        <ErrorComponent msg={this.props.error} type="error" />
                        <div className="row">
                            <div className="col-6">
                                <div className="active_data">
                                    <h5>{datasource.title}</h5>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="dropdown manage_drop filter_drop">
                                    <a className="dropdown-toggle" id="dropdown_manage" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <img src="/img/manage.png" alt="manage" /> Manage
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby="dropdown_manage">
                                        <div className="buttons">
                                            <a href={"/analysis_new/" + datasource.uuid} className="text-dark">New analysis...</a>
                                            {/* <a onClick={event => { this.handleNewAnalysis(); }}>New analysis...</a> */}
                                            {/* <button type="submit" className="btn_full" onClick={this.handleNewAnalysis}>
                                Add new analysis
                              </button> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <div className="white_box charts">
                                    <p className="data_source_text"><span>Code:</span> {datasource.code} </p>
                                    <p className="data_source_text light"><span>UUID:</span> {datasource.uuid} </p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="white_box charts">
                                    <p className="data_source_text"><span>Type:</span> {datasource.type} </p>
                                    <p className="data_source_text"><span>Status:</span> {datasource.active ? "Active" : "Inactive"} </p>
                                </div>
                            </div>
                        </div>
                        {selected.recent_stats && selected.recent_events_stats &&
                            this.renderIndicators(selected.recent_stats, selected.recent_events_stats)}
                        {this.renderExtraData(selected)}
                        {this.renderEvents(selected.events)}
                    </div>
                </div>
            );
        }
        return <div />;
    }
}

export default DatasourceDetails;
