// @flow

import { INSIGHT_TYPES } from "../../../../lib/ManufacturingTags.generated";

import type { UpdateLineOrderComment } from "./linesOrders";
import type { PayloadAction } from "../../../redux/types";

export const INSIGHT_FILTERS = {
    filter_account_for_deliveries : "filter_account_for_deliveries",
    filter_include_verp : "filter_include_verp",
    filter_use_safety_stock: "filter_use_safety_stock"
}

export const AVAILABLE_TYPES = [
    INSIGHT_TYPES.tool_setup,
    INSIGHT_TYPES.tool_overlap,
    INSIGHT_TYPES.man_downtime,
    INSIGHT_TYPES.operation_no_input_material,
    INSIGHT_FILTERS.filter_use_safety_stock,
    INSIGHT_FILTERS.filter_account_for_deliveries,
    INSIGHT_FILTERS.filter_include_verp,
    INSIGHT_TYPES.operation_delay_downstream,
    INSIGHT_TYPES.operation_constraint_violated,
    INSIGHT_TYPES.freeze_order_move,
    INSIGHT_TYPES.conditional_capacity_violated,
    INSIGHT_TYPES.partially_scheduled
];

export type AdvancedOrderMenuState = {
    order_uuid: string | null
}

export type AvailableInsightTypes = (
    typeof INSIGHT_TYPES.tool_setup |
    typeof INSIGHT_TYPES.tool_overlap |
    typeof INSIGHT_TYPES.man_downtime |
    typeof INSIGHT_TYPES.operation_no_input_material |
    typeof INSIGHT_FILTERS.filter_use_safety_stock |
    typeof INSIGHT_FILTERS.filter_account_for_deliveries |
    typeof INSIGHT_FILTERS.filter_include_verp |
    typeof INSIGHT_TYPES.operation_delay_downstream |
    typeof INSIGHT_TYPES.operation_constraint_violated |
    typeof INSIGHT_TYPES.conditional_capacity_violated |
    typeof INSIGHT_TYPES.partially_scheduled
);

type OrderTableProductionModal = {
    show_modal: boolean,
    order_external_id: string,
    order_uuid: string,
    order_process_num: string
}

export const SET_INSIGHTS_ACTIONS_TYPES: "set_insights_actions_types" = "set_insights_actions_types";
export const SET_ORDER_TABLE_PRODUCTION_MODAL: "set_order_table_production_modal" = "set_order_table_production_modal";

export type SetInsightTypesActions = PayloadAction<typeof SET_INSIGHTS_ACTIONS_TYPES, AvailableInsightTypes[]>;
export type SetOrderTableProductionModalAction = PayloadAction<typeof SET_ORDER_TABLE_PRODUCTION_MODAL, OrderTableProductionModal>;

export type StandaloneGanttState = {
    insight_types: AvailableInsightTypes[],
    order_table_production_modal: OrderTableProductionModal,
    updated_order_uuid: string | null
}

export const getSelectedInsightTypes = (): AvailableInsightTypes[] => {
    const storage_insight_types = localStorage.getItem("insights-types");
    if (storage_insight_types) return JSON.parse(storage_insight_types);

    return AVAILABLE_TYPES;
}

const order_table_production_modal_state = {
    show_modal: false,
    order_external_id: "",
    order_uuid: "",
    order_process_num: ""
}

const getInitialState = (): StandaloneGanttState => {
    const storage_insight_types = localStorage.getItem("insights-types");
    if (storage_insight_types) {
        return {
            insight_types: JSON.parse(storage_insight_types),
            order_table_production_modal: order_table_production_modal_state,
            updated_order_uuid: null
        };
    }

    return {
        insight_types: AVAILABLE_TYPES,
        order_table_production_modal: order_table_production_modal_state,
        updated_order_uuid: null
    }
}

export type StandaloneGanttActions = SetInsightTypesActions | SetOrderTableProductionModalAction | UpdateLineOrderComment;

export const standaloneGanttReducer = (
    state: StandaloneGanttState,
    action: StandaloneGanttActions
): StandaloneGanttState => {
    if (!state) {
        return getInitialState();
    }

    if (action.type === SET_INSIGHTS_ACTIONS_TYPES) {
        state.insight_types = action.data;
        localStorage.setItem("insights-types", JSON.stringify(action.data));
        return { ...state };
    } else if (action.type === SET_ORDER_TABLE_PRODUCTION_MODAL) {
        state.order_table_production_modal = action.data;
        return { ...state };
    }

    return state;
}
