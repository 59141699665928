// @flow
import * as React from "react";
import { connect } from "react-redux";
import * as rt from "../../../../lib/SimulationReportModels";
import * as t2 from "../../../../lib/backend/reports.generated.types";
import * as BusinessLogic from "../../../../lib/BusinessLogic";
import Line, { ConnectedLineLabel } from "./Line";
import DisabledLine from "./DisabledLine";
import DisabledShifts from "./DisabledShifts";
import EnabledShifts from "./EnabledShifts";
import Insights, { InsightsCommonPatterns } from "./Insights";
import Cursor from "./Cursor";
import { ConnectedGridLines, GridGroups, GridTextDates, CurrentShiftDashedLine } from "./GanttChartGrid";
import { ReduxFilters, LinesOrdersLogic, PropertiesLogic } from "../reducers/BusinessLogic";
import {
    UPDATE_PROPERTIES_ACTION_NAME,
    UPDATE_PLANNING_TABLE_HEIGHT,
    GanttChartSource,
    countLinesOnGantt,
    countUnscheduledLinesOnGantt,
    numStockRequirementsAfterResolution
} from "../reducers/properties";
import LineGroupLabel from "./LineGroupLabel";
import ReportLoader from "./ReportLoader";
import { debounce } from "../../../../lib/Util";
import { LINE_GROUP_TAGS_ACCESS, LINE_TAGS } from "../../../../lib/ManufacturingTags.generated";
import { ScrollHandler } from "../EventListeners/ScrollHandler";
import { LinesOrdersMaps, ReportMaps } from "../reducers/Mappers";

import type {
    UpdatePropertiesActionType,
    PropertiesState,
    GanttChartSourceTypes,
    UpdatePlanningTableWidthAction,
    UpdatePlanningTableHeightAction,
    ShiftXPositions
} from "../reducers/properties";
import type { IOrderModelBase } from "../../../../lib/backend/manufacturing2.generated.types";
import type { InsightsMapped } from "../reducers/insights";
import type { ReportState } from "../reducers/report";
import type { ReduxState } from "../reducers/reducers";
import type { LineOrders } from "../reducers/linesOrders";
import type { ResetGanttAction, ValidLineOrders, ValidOrder } from "../reducers/common";
import type { AvailableInsightTypes } from "../reducers/ganttChartStandalone";
import type { StockRequirement } from "../reducers/stockRequirements";
import type { PlanningTableState } from "../reducers/planningTable";

type LineWeight = {
    uuid: string,
    weight: number,
    index: number
}

type GanttChartContentProps = {
    options: rt.ISimulationReportOrderLineEx[],
    shifts: t2.ISimulationReportOrderLineShiftFeature[],
    insights_mapped: InsightsMapped,
    insights: t2.IEventDataEx[],
    reduxDispatch: any => any,
    properties: PropertiesState,
    valid_lines_map: Map<string, ValidLineOrders> | null,
    valid_orders_map: Map<string, ValidOrder> | null,
    line_uuid: string,
    lines_orders: LineOrders[],
    source_type: GanttChartSourceTypes,
    filtered_insight_types: AvailableInsightTypes[],
    stock_requirements: StockRequirement[],
    planning_table: PlanningTableState,
    original_report_created_at: number | null,
    simulation_uuid: string,
    report_loading: boolean,
    next_shift_time: t2.ISimulationReportShiftTime,
    report_data: rt.IReportModelEx | null,
    orders: IOrderModelBase[]
};
type GanttChartContentState = {
    should_render_content: boolean,
    lines: Map<string, string>,
    scroll_counter: number
}
type LinesMap = {
    [key: string]: number
}
type LineOffset = {
    [key: string]: number
}

type LineOffsetsIndexes = {
    line_offsets: LineOffset,
    line_indexes: LineOffset
}


class GanttChartContent extends React.PureComponent<GanttChartContentProps, GanttChartContentState> {

    phantomNode = null;
    paneScrollListener = null;

    state: GanttChartContentState = {
        should_render_content: false,
        lines: new Map(),
        scroll_counter: 0
    }

    constructor(props) {
        super(props);
        this.phantomNode = React.createRef();
    }

    async componentDidMount() {
        const pane1 = document.getElementById("pane1");
        this.paneScrollListener = debounce(this.paneScrollListener2.bind(this), 40);
        if (pane1) {
            pane1.addEventListener("scroll", this.paneScrollListener);
        }

        // waiting for phantom text node to be rendered inside child components
        this.setState({ should_render_content: true });
        const lines_map: Map<string, string> = new Map();

        if (this.props.properties && this.props.properties.line_group_uuid) {
            const line_group_uuid = this.props.properties.line_group_uuid;
            const lines_resp = await BusinessLogic.getAllLinesForLineGroup(line_group_uuid);

            for(const line of lines_resp.lines) {
                lines_map.set(line.uuid, line.external_id);
            }
        }


        this.setState({ lines: lines_map });
    }

    paneScrollListener2 = (e: Event) => {
        this.setState({ scroll_counter: this.state.scroll_counter + 1 });
    }

    generateLineOffsets = (orders: rt.ISimulationReportOrderEx[], height_counter: number, line_offset: number): LineOffsetsIndexes => {
        let line_offsets = {};
        let line_indexes = {};
        for (const order of orders) {
            line_indexes[order.order_id] = height_counter;
            line_offsets[order.order_id] = line_offset;
            line_offsets[order.order_external_id] = line_offset;
            line_indexes[order.order_external_id] = height_counter;
        }
        return {
            line_offsets,
            line_indexes
        }
    }

    handleOrderClick = (e) => {e.preventDefault(); e.stopPropagation();}

    componentWillUnmount() {
        ReportMaps.clearMaps();
        LinesOrdersMaps.clearMaps();
        const pane1 = document.getElementById("pane1");
        if (pane1 && this.paneScrollListener) {
            pane1.removeEventListener("scroll", this.paneScrollListener);
        }
    }

    renderContent = () => {
        const line_group_uuid = this.props.properties.line_group_uuid;
        if (!line_group_uuid) {
            return null;
        }

        const line_group_tags = BusinessLogic.getLineGroupTags(line_group_uuid);
        const lazy_rendering_enabled = LINE_GROUP_TAGS_ACCESS.planning_table_lazy_rendering(line_group_tags);
        const source_type = this.props.source_type || this.props.properties.source_type;

        if (Number.isNaN(this.props.properties.bars_start)) {
            return null;
        }
        if (!this.props.valid_lines_map && PropertiesLogic.isPlanningTable(this.props.source_type)) return null;

        // sort by line weights
        let weighted_lines: LineWeight[] = [];
        let index = 0; // index is used later for this.props.options and this.props.shifts

        const pane1 = document.getElementById("pane1");
        let miny = 0;
        let maxy = 0;
        const LAZY_OFFSET = 200;
        if (pane1) {
            miny = pane1.scrollTop - LAZY_OFFSET;
            maxy = pane1.scrollTop + pane1.getBoundingClientRect().height + LAZY_OFFSET;
        }

        for (const option of this.props.options) {
            const weight = BusinessLogic.getLineWeight(option.line);
            // if we have valid lines, check if we should show current line
            if (this.props.valid_lines_map && !this.props.valid_lines_map.has(option.line)) { continue; }
            // yes, remember parameters
            // do not show lines that are not visible to the eye
            weighted_lines.push({
                uuid: option.line,
                weight: weight >= 0 ? weight : Number.MAX_SAFE_INTEGER,
                index: index
            });
            index++;
        }

        weighted_lines.sort((a, b) => a.weight - b.weight);

        let heightcounter = 0;
        const Lines = [];
        const LineLabels = [];
        const DisabledShiftsList = [];
        const EnabledShiftsList = [];
        const LinesRegion = [];
        const DisabledLines = [];
        const HorizontalLines = [];

        let line_offsets = {};
        let line_indexes = {};
        const line_uuid_indexes = {};

        const { top_padding, bar_height, space_between_bars, width } = this.props.properties;

        const lines_map_index: LinesMap = {}; // duplicate lines
        let prev_line_uuid: string | null = null;
        const line_group_lines_map = new Map < string, LineWeight[]> ();
        const LineGroupLabels = [];

        // current line group is always on top
        line_group_lines_map.set(line_group_uuid, []);
        for (const line of weighted_lines) {
            const line_group = LinesOrdersLogic.getLineGroupForLine(line.uuid, line_group_uuid);
            if (!line_group) {
                continue;
            }
            const lines = line_group_lines_map.get(line_group.uuid) || [];
            line_group_lines_map.set(line_group.uuid, [...lines, line]);
        }

        let prev_line_group_uuid = this.props.properties.line_group_uuid;
        for (const [line_group_uuid, weighted_lines] of line_group_lines_map.entries()) {
            const line_group = BusinessLogic.getLineGroupForUser(line_group_uuid);
            if (line_group && line_group.uuid !== prev_line_group_uuid) {
                prev_line_group_uuid = line_group.uuid;
                LineGroupLabels.push(
                    <LineGroupLabel
                        key={"lgl" + line_group.uuid}
                        title={line_group.title}
                        line_group_uuid={line_group.uuid}
                        line_offset={top_padding + heightcounter * (bar_height + space_between_bars)}
                        external_id={line_group.external_id}
                    />
                );
                heightcounter++;
            }
            for (let j = 0; j < weighted_lines.length; j++) {
                let line = this.props.options.find(l => l.line === weighted_lines[j].uuid);

                if (!line) {
                    continue;
                }

                let valid_line = null;
                if (this.props.valid_lines_map) {
                    valid_line = this.props.valid_lines_map.get(line.line);
                }

                let is_extra_line = valid_line ? valid_line.is_extra : false;

                // if a line has conflicting orders then we need to duplicate the line and put the conflicting orders
                // in the new line. In which case we need to correctly loop through the list of lines
                const line_uuid = line.line;
                let index = 0;
                let show_disabled_line = false;
                if (lines_map_index[line_uuid] === undefined) {
                    lines_map_index[line_uuid] = 0;
                } else {
                    lines_map_index[line_uuid] += 1;
                    index = lines_map_index[line_uuid];
                    line = this.props.options.filter(line => line.line === line_uuid)[index];
                }

                const positiony = heightcounter * (this.props.properties.bar_height + this.props.properties.space_between_bars) + this.props.properties.top_padding;

                let virtual_show_line = true;
                if (pane1 && !(positiony > miny && positiony < maxy) && lazy_rendering_enabled) {
                    virtual_show_line = false
                }

                const line_orders = this.props.lines_orders.find(l => line && l.line_uuid === line.line);
                if (line_orders) {
                    const max_num_overlaping_lines = line_orders.tags[LINE_TAGS.max_num_overlaping_lines];
                    if (max_num_overlaping_lines && index >= parseInt(max_num_overlaping_lines)) {
                        // this will disable drag and drop on the line
                        is_extra_line = true;
                        // this will draw a grey rectangle over the line
                        show_disabled_line = true;
                    }
                }

                const line_offset = top_padding + heightcounter * (bar_height + space_between_bars);

                ScrollHandler.addLineOffsetForScrollEvents(
                    line_offset,
                    line.production,
                    this.props.properties
                );

                let line_title = line.line_title;

                let is_parallel_line = false;
                if (prev_line_uuid == line.line) {
                    line_title = ""; // non-first occurence of line should not display title
                    is_parallel_line = true;
                } else if (j + 1 < weighted_lines.length && weighted_lines[j + 1].uuid === line.line) {
                    is_parallel_line = true;
                }

                let line_external_id = this.state.lines && this.state.lines.get(line.line);
                if (line_external_id === undefined) {
                    line_external_id = "";
                }
                // if next line is the same line
                prev_line_uuid = line.line;
                LineLabels.push(virtual_show_line ? <ConnectedLineLabel
                    key={"cll" + line.line + "-" + j}
                    line_uuid={line.line}
                    line_title={line_title}
                    line_external_id={line_external_id}
                    properties={this.props.properties}
                    line_index={heightcounter}
                    line_offset={line_offset}
                    virtual_show_line={virtual_show_line}
                /> : null);

                const shift_index = this.props.shifts.findIndex(l => line && l.line === line.line);

                if (show_disabled_line && virtual_show_line) {
                    DisabledLines.push(
                        virtual_show_line ? <DisabledLine
                            key={"disabled-" + "l" + line.line + "-" + j}
                            properties={this.props.properties}
                            line_offset={line_offset}
                            virtual_show_line={virtual_show_line}
                        /> : null
                    )
                }
                const line_key = this.props.valid_lines_map ? JSON.stringify(this.props.valid_lines_map.values()) : "";

                Lines.push(
                    virtual_show_line ? <Line
                        key={"l" + line.line + "-" + j + "-" + line_offset + "-" + line_key + "-"}
                        line={line}
                        shifts={this.props.shifts[shift_index]}
                        properties={this.props.properties}
                        original_report_created_at={this.props.original_report_created_at}
                        handleOrderClick={this.handleOrderClick}
                        line_index={heightcounter}
                        line_uuid={line.line}
                        insights_mapped={this.props.insights_mapped}
                        insights={this.props.insights}
                        line_offset={line_offset}
                        valid_orders_map={this.props.valid_orders_map}
                        is_parallel_line={is_parallel_line}
                        source_type={this.props.source_type}
                        is_extra_line={is_extra_line}
                        filtered_insight_types={this.props.filtered_insight_types}
                        line_orders={line_orders}
                        report_data={this.props.report_data}
                        virtual_show_line={virtual_show_line}
                        orders={this.props.orders}
                    /> : null
                );

                DisabledShiftsList.push(
                    virtual_show_line ? <DisabledShifts
                        key={"ds" + line.line + "-" + j}
                        shifts={this.props.shifts[shift_index].shifts}
                        line_offset={line_offset}
                        line_index={heightcounter}
                        line={line}
                        properties={this.props.properties}
                        is_extra_line={false}
                        virtual_show_line={virtual_show_line}
                    /> : null
                );

                if (!is_extra_line) {
                    EnabledShiftsList.push(
                        virtual_show_line ? <EnabledShifts
                            key={"es" + line.line + "-" + j}
                            shifts={this.props.shifts[shift_index].shifts}
                            line_offset={line_offset}
                            line_index={heightcounter}
                            line={line}
                            properties={this.props.properties}
                            is_extra_line={is_extra_line}
                        /> : null
                    );
                }

                LinesRegion.push(
                    virtual_show_line ? <g id={"lines-region"} key={"lr" + line.line + "-" + j}>
                        <line
                            id="upper"
                            x1={this.props.properties.bars_start}
                            y1={line_offset}
                            x2={width}
                            y2={line_offset}
                            style={{ strokeWidth: "1", stroke: "#B7BDC4" }}
                        ></line>
                        <line
                            id="lower"
                            x1={this.props.properties.bars_start}
                            y1={line_offset + bar_height}
                            x2={width}
                            y2={line_offset + bar_height}
                            style={{ strokeWidth: "1", stroke: "#B7BDC4" }}
                        ></line>
                    </g> : null
                );

                HorizontalLines.push(
                    <g id="horizontal-lines" key={"hl" + line.line + "-" + j}>
                        <line
                            id="upper"
                            x1={0}
                            y1={line_offset}
                            x2={width}
                            y2={line_offset}
                            style={{ strokeWidth: "1", stroke: "#B7BDC4" }}
                        ></line>
                        <line
                            id="lower"
                            x1={0}
                            y1={line_offset + bar_height}
                            x2={this.props.properties.bars_start}
                            y2={line_offset + bar_height}
                            style={{ strokeWidth: "1", stroke: "#B7BDC4" }}
                        ></line>
                    </g>
                )
                const { line_offsets: line_offset2, line_indexes: line_indexes2 } = this.generateLineOffsets(
                    line.production, heightcounter, line_offset
                );
                line_offsets = { ...line_offsets, ...line_offset2 };
                line_indexes = { ...line_indexes, ...line_indexes2 };
                if (line_uuid_indexes[line.line] === undefined) {
                    line_uuid_indexes[line.line] = heightcounter;
                }
                heightcounter++;
            }
        }
        const show_line_labels = source_type !== GanttChartSource.microplan;

        return <React.Fragment>
            <g id="group-shifts">
                {EnabledShiftsList}
                {DisabledShiftsList}
            </g>
            {LinesRegion}
            {Lines}
            {this.props.properties.show_only_tool_changes && <InsightsCommonPatterns />}
            {!this.props.properties.show_only_tool_changes && <Insights
                properties={this.props.properties}
                insights={this.props.insights}
                options={this.props.options}
                line_offsets={line_offsets}
                line_indexes={line_indexes}
                line_uuid_indexes={line_uuid_indexes}
            />}
            {show_line_labels && <g id="line-labels-group" style={{ willChange: "transform" }}>
                <rect id="line-labels-group-white-rect" height="100%" width={this.props.properties.max_line_label_length} fill="white" display-outside="block" />
                {LineLabels}
                {LineGroupLabels}
                {HorizontalLines}
            </g>}
            <Cursor />
            {DisabledLines}
        </React.Fragment>;
    }

    render() {
        return <React.Fragment>
            {this.renderContent()}
        </React.Fragment>
    }
}

const ConnectedGanttChartContent = connect(
    (state: ReduxState, ownProps: GanttChartContentProps) => {
        const properties = state.gantt_chart_properties;
        const report = state.gantt_chart_report.report_data;
        const insights_mapped = state.gantt_chart_insights.insights_mapped;
        const insights = state.gantt_chart_insights.insights;
        const show_valid_lines = state.gantt_chart_planning_table.show_valid_lines;
        const planning_table = state.gantt_chart_planning_table;

        let options = [];
        let shifts = [];
        let simulation_uuid = null;
        if (report) {
            options = report.result.orders;
            shifts = report.result.line_shift_features;
            simulation_uuid = report.uuid;
        }
        return {
            properties,
            insights_mapped,
            insights,
            options,
            shifts,
            simulation_uuid,
            report_loading: state.gantt_chart_report.report_loading,
            valid_lines_map: show_valid_lines.valid_lines_map,
            valid_orders_map: show_valid_lines.valid_orders_map,
            lines_orders: state.gantt_chart_lines_orders.lines_orders,
            filtered_insight_types: state.standalone_gantt.insight_types,
            original_report_created_at: state.gantt_chart_report.original_report_created_at,
            planning_table,
            report_data: report,
            orders: state.gantt_chart.orders
        };
    },
    (dispatch) => ({ reduxDispatch: dispatch })
)(GanttChartContent)

const getReportCreatedAt = (report: ReportState) => {
    if (!report || !report.report_data) return null;
    return report.report_data.created_at;
}

type GanttChartProps = {
    properties: PropertiesState,
    report: ReportState,
    line_group_uuid: string,
    reduxDispatch: (args: UpdatePropertiesActionType | ResetGanttAction | UpdatePlanningTableWidthAction | UpdatePlanningTableHeightAction) => any,
    created_at: number | null,
    unscheduled_orders: LineOrders[],
    report_loading: boolean,
    source_type: GanttChartSourceTypes,
    valid_lines_map: Map<string, ValidLineOrders>,
    valid_orders_map: Map<string, ValidOrder> | null,
    show_only_unscheduled?: boolean,
    stock_requirements: StockRequirement[],
    shifts: t2.ISimulationReportOrderLineShiftFeature[],
    shift_x_positions: ShiftXPositions
}

class GanttChart extends React.Component<GanttChartProps, any> {
    ref: HTMLDivElement | null = null;

    state = {
        properties_loaded: false,
        scroll_counter: 0
    }

    phantomNode = null;

    constructor(props) {
        super(props);
        this.phantomNode = React.createRef();
    }

    resizeListener = null;

    componentWillUnmount() {
        ReduxFilters.onUnmount();
    }

    componentDidMount = () => {
        // calculate width and height
        if (!this.resizeListener) {
            this.resizeListener = this.updateDimensions(this);
        }

        window.addEventListener("resize", this.resizeListener);

        if (this.props.report && this.props.report.report_data) {
            this.onUpdateReportUpdateProperties();
        }
    }

    componentDidUpdate = (prev_props: GanttChartProps) => {
        if (prev_props.created_at !== this.props.created_at ||
            prev_props.unscheduled_orders !== this.props.unscheduled_orders) {
            this.onUpdateReportUpdateProperties();
        }

        // figure out if valid lines changed, first guess is if the size changed
        let valid_lines_changed = false;
        if (prev_props.valid_lines_map == null && this.props.valid_lines_map != null) {
            valid_lines_changed = true;
        } else if (prev_props.valid_lines_map != null && this.props.valid_lines_map == null) {
            valid_lines_changed = true;
        } else if (prev_props.valid_lines_map != null && this.props.valid_lines_map != null) {
            valid_lines_changed = prev_props.valid_lines_map.size != this.props.valid_lines_map.size;
            // if the size did not change, we need to find at least one line that is different
            if (!valid_lines_changed) {
                for (const line of this.props.valid_lines_map.values()) {
                    if (!prev_props.valid_lines_map.has(line.line_uuid)) {
                        valid_lines_changed = true;
                        break;
                    }
                }
            }
        }

        if (
            valid_lines_changed ||
            JSON.stringify(prev_props.properties) !== JSON.stringify(this.props.properties) ||
            prev_props.report_loading !== this.props.report_loading
        ) {
            if (this.ref !== null) {
                this.ref.dispatchEvent(new Event("gantt-change", {
                    bubbles: true
                }));
            }
        }

        if (valid_lines_changed) {
            const { count_non_empty_lines, num_unscheduled_lines } = this.props.properties;
            if (count_non_empty_lines !== null) {
                this.props.reduxDispatch({
                    type: UPDATE_PLANNING_TABLE_HEIGHT,
                    data: { count_non_empty_lines, num_unscheduled_lines }
                });
            }
        }
    }

    componentWillUnmount() {
        this.removeListeners();
    }

    removeListeners = () => {
        window.removeEventListener("resize", this.resizeListener);
    }

    updateDimensions = (self) => () => {
        const properties = self.props.properties;

        const count_non_empty_lines = properties.count_non_empty_lines;
        const num_unscheduled_lines = properties.num_unscheduled_lines;

        if (count_non_empty_lines && num_unscheduled_lines) {
            self.props.reduxDispatch({
                type: UPDATE_PLANNING_TABLE_HEIGHT,
                data: { count_non_empty_lines, num_unscheduled_lines }
            })
        }
    }

    getIsPropertiesLoaded = () => {
        return this.props.properties.loaded;
    }

    onUpdateReportUpdateProperties = () => {
        // when we recieve report data we must update a few properties:
        // - count_non_empty_lines height based on number of lines
        // - start day which simulation generates
        // - days because it is dependent on current shift, which changes over time
        const report_data = this.props.report.report_data;
        if (!report_data) return;

        const start_day = Math.floor(report_data.result.next_shift_time.shift_number / 3);
        let count_non_empty_lines = countLinesOnGantt({
            valid_lines_map: this.props.valid_lines_map || new Map(),
            line_group_uuid: this.props.line_group_uuid,
            report_data,
            source_type: this.props.source_type
        });

        const num_unscheduled_lines = countUnscheduledLinesOnGantt({
            valid_orders_map: this.props.valid_orders_map || new Map(),
            unscheduled_orders: this.props.unscheduled_orders,
            source_type: this.props.source_type
        });

        const num_stock_requirements = numStockRequirementsAfterResolution(this.props.stock_requirements,
            { ...this.props.properties, current_shift: report_data.result.next_shift_time.shift_number });

        const shifts = report_data.result.line_shift_features;
        if (!shifts[0]) {
            return;
        }
        const properties = {
            start_day,
            count_non_empty_lines,
            num_unscheduled_lines,
            loaded: true,
            source_type: this.props.source_type,
            num_stock_requirements,
            line_group_uuid: this.props.line_group_uuid,
            current_shift: report_data.result.next_shift_time.shift_number,
            show_only_unscheduled: this.props.show_only_unscheduled,
            num_shifts: shifts[0].shifts.length,
        };

        this.props.reduxDispatch({ type: UPDATE_PROPERTIES_ACTION_NAME, data: properties });
    }

    getSvgAttributes() {
        let svg_attributes = {};
        const { width, height } = this.props.properties;

        if (width || height) {
            svg_attributes = {
                width: width,
                height: height
            }
        }

        return svg_attributes;
    }

    getSourceType = () => {
        return this.props.source_type || this.props.properties.source_type;
    }

    render() {
        const top_padding = this.props.properties.top_padding;
        const svg_attributes = this.getSvgAttributes();
        const class_name = `${this.props.properties.no_overflow ? "" : "chart-wrapper"} UUID_${this.props.report.original_report_uuid || ""}`;
        const line_shifts = this.props.shifts[0] ? this.props.shifts[0].shifts : [];
        const shift_x_positions = this.props.shift_x_positions;
        return (
            <div
                id={this.getSourceType()}
                style={{ zIndex: 1, opacity: 1 }}
                className={class_name}
                ref={el => { this.ref = el; }}
            >
                <ReportLoader pane_id={"pane1"} />
                {getReportCreatedAt(this.props.report) && <svg id="gantt-chart" {...svg_attributes}>
                    <g id="grid">
                        <text ref={this.phantomNode} fontSize={"14px"} fontFamily={"Arial"} fontWeight={"400"} opcaity={0} />
                        <rect height={`${this.props.properties.top_padding}px`} width="100%" fill="#ffffff"></rect>
                        <ConnectedGridLines
                            gantt_chart_properties={this.props.properties}
                            shifts={line_shifts}
                            shift_x_positions={shift_x_positions}
                        />
                        <GridGroups
                            gantt_chart_properties={this.props.properties}
                            source_type={this.props.source_type}
                            shifts={line_shifts}
                            shift_x_positions={shift_x_positions}
                        />
                        <ConnectedGanttChartContent source_type={this.props.source_type} />
                        <g id="gantt-chart-process-nums" style={{ outline: "none" }} />
                        <g id="grid-groups-header-shifts" style={{ willChange: "transform" }}>
                            <rect width="100%" height={top_padding} fill="white" />
                            <GridTextDates
                                gantt_chart_properties={this.props.properties}
                                source_type={this.props.source_type}
                                reduxDispatch={this.props.reduxDispatch}
                                shifts={line_shifts}
                                shift_x_positions={shift_x_positions}
                            />
                        </g>
                        <CurrentShiftDashedLine
                            gantt_chart_properties={this.props.properties}
                            source_type={this.props.source_type}
                            shifts={line_shifts}
                            shift_x_positions={shift_x_positions}
                            report={this.props.report}
                        />
                    </g>
                    <g id="detached-draggable-order"></g>
                </svg>}
            </div>
        );
    }
}

export default connect(
    (state: ReduxState, own_props: $Shape<GanttChartProps>) => {
        const properties = state.gantt_chart_properties;
        const planning_table = state.gantt_chart_planning_table;
        const valid_lines_map = planning_table.show_valid_lines.valid_lines_map;
        const valid_orders_map = planning_table.show_valid_lines.valid_orders_map;
        const stock_requirements = state.stock_requirements.stock_requirements;
        const report = state.gantt_chart_report.report_data;
        const shift_x_positions = state.gantt_chart_properties.shift_x_positions;
        let shifts = [];
        if (report) {
            shifts = report.result.line_shift_features;
        }
        return {
            report_loading: state.gantt_chart_report.report_loading,
            report: state.gantt_chart_report,
            created_at: getReportCreatedAt(state.gantt_chart_report),
            properties,
            unscheduled_orders: state.gantt_chart_lines_orders.unscheduled_orders,
            valid_lines_map,
            valid_orders_map,
            stock_requirements,
            shifts,
            shift_x_positions
        }
    },
    (dispatch) => ({ reduxDispatch: dispatch })
)(GanttChart);
