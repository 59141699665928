// @flow
import { connect } from "react-redux";
import App from "./App";
import React from "react";

import {
    SET_RESOURCE_EDIT_FILTERS,
    diffREFFiltersAndURLParams,
    ref_initial_state
} from "./redux/reducers/resourceEditFiltersReducer";

import {
    SET_STOCK_STATUS_TABLE_FILTERS,
    diffSSFTFiltersAndURLParams,
    ssft_initial_state
} from "./redux/reducers/stockStatusTableFiltersReducer";
import {
    SET_STOCK_STATUS_FILTERS,
    diffSSFFiltersAndURLParams,
    ssf_initial_state
} from "./redux/reducers/stockStatusFiltersReducer";
import {
    SET_PERSISTENT_FILTERS_ACTION,
    diffPersistentFiltersAndURLParams
} from "./redux/reducers/persistentFiltersReducer";
import type {
    PersistentFiltersState
} from "./redux/reducers/persistentFiltersReducer";
import type {
    StockStatusTableFiltersState
} from "./redux/reducers/stockStatusTableFiltersReducer";

import type {
    StockStatusFiltersState
} from "./redux/reducers/stockStatusFiltersReducer";

import type { 
    ResourceEditFiltersState
} from "./redux/reducers/resourceEditFiltersReducer";

import * as Redirect from "./URLHandler/Redirect";

type Props = {
    history: Object,
    location: Object,
    persistent_filters: PersistentFiltersState,
    // old filters
    stock_status_filters: StockStatusFiltersState,
    stock_status_table_filters: StockStatusTableFiltersState,
    resource_edit_filters: ResourceEditFiltersState,
    reduxDispatch: Function,
    children: Object
};

type State = {};

class URLHandler extends React.Component<Props, State> {
    state = {
        location_search: ""
    };

    constructor(props) {
        super(props);
        if (props.location.hash) {
            this.loadFilters(props.location.hash);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.hash && prevProps.location.hash !== this.props.location.hash) {
            this.loadFilters(this.props.location.hash);
        }

        this.resetSSFTFilters();
        this.resetSSFFilters();
        this.resetRefFilters();
        
    }

    isOnPage = (pathnames: string[] | string): boolean => {
        let is_on_page = false;

        if (typeof pathnames === "string") {
            is_on_page = this.props.location.pathname.includes(pathnames);
        } else {
            is_on_page = !!pathnames.find(p => this.props.location.pathname.includes(p));
        }

        return is_on_page;
    }

    resetSSFFilters = () => {
        const pathnames = "/stocks/stock_status";
        const is_on_page = this.isOnPage(pathnames);

        // in case we leave the page where we set our filters, then we need to reset them
        if (!is_on_page && JSON.stringify(this.props.stock_status_filters) !== JSON.stringify(ssf_initial_state)) {
            Redirect.stockStatusFilters(
                this.props.history,
                this.props.location.pathname,
                ssf_initial_state,
                true
            );
        }
    }

    resetRefFilters = () => {
        const pathnames = ["/resources/lines", "/resources/materials", "/resources/line", "/resources/stock_location"];
        const is_on_page = this.isOnPage(pathnames);

        // in case we leave the page where we set our filters, then we need to reset them
        if (!is_on_page && JSON.stringify(this.props.resource_edit_filters) !== JSON.stringify(ref_initial_state)) {
            Redirect.resourceEditFilters(
                this.props.history,
                this.props.location.pathname,
                ref_initial_state,
                true
            );
        }
    }

    resetSSFTFilters = () => {
        const pathnames = "/stocks/stock_status";
        const is_on_page = this.isOnPage(pathnames);

        // in case we leave the page where we set our filters, then we need to reset them
        if (!is_on_page && JSON.stringify(this.props.stock_status_table_filters) !== JSON.stringify(ssft_initial_state)) {
            Redirect.stockStatusTableFilters(
                this.props.history,
                this.props.location.pathname,
                ssft_initial_state,
                true
            );
        }
    }

    loadFilters(hash: string) {
        const new_persistent_filters = diffPersistentFiltersAndURLParams(this.props.persistent_filters, hash);
        if (new_persistent_filters) {
            this.props.reduxDispatch({ type: SET_PERSISTENT_FILTERS_ACTION, data: new_persistent_filters });
        }

        const new_ssft_filters = diffSSFTFiltersAndURLParams(this.props.stock_status_table_filters, hash);
        if (new_ssft_filters) {
            this.props.reduxDispatch({
                type: SET_STOCK_STATUS_TABLE_FILTERS,
                data: new_ssft_filters
            });
        }

        const new_ssf_filters = diffSSFFiltersAndURLParams(this.props.stock_status_filters, hash);
        if (new_ssf_filters) {
            this.props.reduxDispatch({
                type: SET_STOCK_STATUS_FILTERS,
                data: new_ssf_filters
            });
        }

        const new_ref_filters = diffREFFiltersAndURLParams(this.props.resource_edit_filters, hash);
        if (new_ref_filters) {
            this.props.reduxDispatch({ type: SET_RESOURCE_EDIT_FILTERS, data: new_ref_filters });
        }
    }

    render() {
        return null;
    }
}

const ConnectedURLHandler = connect(
    state => {
        return {
            persistent_filters: state.persistent_filters,
            stock_status_table_filters: state.stock_status_table_filters,
            stock_status_filters: state.stock_status_filters,
            resource_edit_filters: state.resource_edit_filters
        }
    },
    dispatch => { return { reduxDispatch: dispatch } }
)(URLHandler);

class URLHandlerContainer extends React.PureComponent<Props, State> {

    componentDidUpdate(prevProps: Props) {
        if (this.props.location.hash === "" && prevProps.location.hash !== "") {
            return this.props.history.replace(prevProps.location.hash);
        }
    }

    render() {
        return <React.Fragment>
            <ConnectedURLHandler history={this.props.history} location={this.props.location} />
            <App history={this.props.history} location={this.props.location} />
        </React.Fragment>;
    }
}

export default URLHandlerContainer;
