// @flow
import * as React from "react";
import { FormattedMessage } from "react-intl";

type Props = {
    message?: string;
    small?: boolean;
};
type State = { };

class Loader extends React.Component<Props, State> {
    render() {
        if (this.props.small) {
            return (
                <div>
                    <div className="loader_box">
                        <div className="loader-small" />
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    <h2 className="text-center">
                        <FormattedMessage id="common.loading" defaultMessage="Loading..." />
                        {this.props.message && <br />}{this.props.message}
                    </ h2>
                    <div className="loader_box">
                        <div className="loader" />
                    </div>
                </div>
            );
        }
    }
}

export default Loader;
