// @flow
import * as React from "react";

import type { Cell, Row } from "react-table";
import ReactTable from "../react/ReactTable";
import { translate } from "../IntlProviderWrapper";
import { getBackend } from "../../lib/backend/Backend2";
import * as t from "../../lib/backend/manufacturing2.generated.types";
import { niceDate, niceNumber } from "../../lib/Util";
import TranslatedMaterialTitle from "../TranslatedMaterialTitle";
import Loader from "../Loader";
import moment from "moment";
import { FormattedMessage } from "react-intl";

import StockRequirementModal from "./StockRequirementsModal";

const STOCK_REQUIREMENTS_COLUMNS = {
    comment: "comment",
    date_expected: "date_expected",
    date_required: "date_required",
    vendor_title: "vendor_title",
    transaction_external_id: "transaction_external_id",
    material_title: "material_title",
    material_external_id: "material_external_id",
    stock_location_title: "stock_location_title",
    quantity: "quantity"
};

const renderDate = ({ value }: Cell) => niceDate(new Date(value));
const renderNumber = ({ value }: Cell) => niceNumber(value, 0);
const sortString = (row_a: Row, row_b: Row, column_id: string) => {
    const a = row_a.values[column_id].toLowerCase();
    const b = row_b.values[column_id].toLowerCase();
    return a === b ? 0 : a > b ? 1 : -1;
}

let show_transaction_external_id = false;

const getTableColumns = (showEditCommentModal) => {

    const table_columns = [];
    table_columns.push({
        Header: translate("Manufacturing.StocksView.date_expected", "Date expected"),
        accessor: STOCK_REQUIREMENTS_COLUMNS.date_expected,
        disableFilters: true,
        enableSorting: true,
        Cell: renderDate

    });

    table_columns.push({
        Header: translate("Manufacturing.StocksView.date_required", "Date required"),
        accessor: STOCK_REQUIREMENTS_COLUMNS.date_required,
        disableFilters: true,
        enableSorting: true,
        Cell: renderDate
    });

    table_columns.push({
        Header: "",
        accessor: STOCK_REQUIREMENTS_COLUMNS.vendor_title,
        filterInputPlaceholder: translate("Manufacturing.StocksView.vendor_title", "Vendor"),
        enableSorting: true,
        sortType: sortString
    });

    if (show_transaction_external_id) {
        table_columns.push({
            Header: "",
            accessor: STOCK_REQUIREMENTS_COLUMNS.transaction_external_id,
            filterInputPlaceholder: translate("Manufacturing.StocksView.transaction_external_id", "transaction_external_id"),
            enableSorting: true,
            sortType: sortString
        });
    }

    table_columns.push({
        Header: "",
        accessor: STOCK_REQUIREMENTS_COLUMNS.material_title,
        filterInputPlaceholder: translate("Manufacturing.StocksView.material_title", "Material"),
        enableSorting: true,
        sortType: sortString
    });

    table_columns.push({
        Header: "",
        accessor: STOCK_REQUIREMENTS_COLUMNS.material_external_id,
        filterInputPlaceholder: translate("Manufacturing.StocksView.material_external_id", "Ident"),
        enableSorting: true
    });

    table_columns.push({
        Header: "",
        accessor: STOCK_REQUIREMENTS_COLUMNS.stock_location_title,
        filterInputPlaceholder: translate("Manufacturing.StocksView.stock_location_title", "Stock location"),
        enableSorting: true,
        sortType: sortString
    });

    table_columns.push({
        Header: translate("Manufacturing.StocksView.quantity", "Quantity"),
        accessor: STOCK_REQUIREMENTS_COLUMNS.quantity,
        disableFilters: true,
        enableSorting: true,
        sortType: "basic",
        Cell: renderNumber
    });

    table_columns.push({
        Header: "",
        accessor: STOCK_REQUIREMENTS_COLUMNS.comment,
        filterInputPlaceholder: translate("common.comment", "Comment"),
        enableSorting: true,
        sortType: sortString,
        Cell: ({ row }: Cell) => {
            const { original } = row;
            const { comment } = original;

            if (!comment) {
                return (
                    <div style={{ textAlign: "right" }}>
                        <button
                            className={"btn btn-outline-primary"}
                            style={{ marginLeft: "5px", minWidth: "0.4rem", width: "40px" }}
                            onClick={() => showEditCommentModal(row)}>
                            <i className="fas fa-plus align-middle" />
                        </button >
                    </div>

                )
            }

            return (
                <div style={{ textAlign: "right" }}>
                    {comment}
                    <button
                        className={"btn btn-outline-primary"}
                        style={{ marginLeft: "5px", minWidth: "0.4rem", width: "40px" }}
                        onClick={() => showEditCommentModal(row)}>

                        <i className="fas fa-edit align-middle" />
                    </button>
                </div>
            )

        }
    });

    return table_columns;
}

export type IStockRequirementsRow = {
    comment: string,
    date_expected: number,
    date_required: number,
    material_external_id: string,
    transaction_external_id: string,
    material_title: React.Node,
    quantity: number,
    stock_location_title: string,
    vendor_title: string
}


type State = {
    stock_requirements: IStockRequirementsRow[],
    is_loading: boolean,
    show_edit_comment: boolean,
    comment: string,
    transaction_external_id: string,
    selected_stock_req: any,
}

type Props = {
    plant_uuid: string,
    line_group_uuid: string,
    stock_requirements_type: string,
}

const mapData = (stock_requirements: t.IStockRequirements[]): IStockRequirementsRow[] => {
    return stock_requirements.map(sr => {
        const material_title_el = TranslatedMaterialTitle(
            {
                material_external_id: sr.material_external_id,
                material_title: sr.material_title
            }
        );

        return {
            comment: sr.comment,
            date_expected: sr.date_expected,
            date_required: sr.date_required,
            material_external_id: sr.material_external_id,
            transaction_external_id: sr.transaction_external_id,
            material_title: material_title_el,
            quantity: sr.quantity,
            stock_location_title: sr.stock_location_title,
            vendor_title: sr.vendor_title
        }
    })
}

class StockRequirements extends React.Component<Props, State> {
    state = {
        stock_requirements: [],
        is_loading: true,
        show_edit_comment: false,
        comment: "",
        transaction_external_id: "",
        selected_stock_req: null
    }

    componentDidMount() {
        this.load();
    }

    async componentDidUpdate(prev_props: Props) {
        if (prev_props.line_group_uuid !== this.props.line_group_uuid || prev_props.stock_requirements_type !== this.props.stock_requirements_type) {
            await this.load();
        }
    }

    async load() {
        this.setState({ is_loading: true });
        const line_group_uuid = this.props.line_group_uuid;
        const plant_uuid = this.props.plant_uuid;

        const all_plants: t.IGetPlantsRes = await getBackend().manufacturing.getPlants({});
        const plant = all_plants && all_plants.plants.filter(p => p.uuid === plant_uuid)[0];

        const plant_external_id: string = plant && plant.external_id;

        if (plant_external_id !== undefined) {

            const res1 = await getBackend().manufacturing.getPlantTags(
                { plant_external_id: plant_external_id }
            );

            show_transaction_external_id = res1.tags.show_transaction_external_id === "true";
        }

        const res = await getBackend().manufacturing.getStockRequirements(
            { line_group_uuid, plant_uuid }
        );

        const filtered_sr_before_map = res.stock_requirements.filter(sr => sr.stock_requirements_type === this.props.stock_requirements_type);
        const filtered_stock_requirements = mapData(filtered_sr_before_map);

        this.setState({
            stock_requirements: filtered_stock_requirements,
            is_loading: false,
            show_edit_comment: false,
            comment: "",
            selected_stock_req: {}
        });
    }

    showEditCommentModal = (row: any) => {

        const selected_stock_req = this.state.stock_requirements.find(sr => sr.transaction_external_id === row.original.transaction_external_id);


        this.setState({
            show_edit_comment: true,
            comment: row.original.comment,
            transaction_external_id: row.original.transaction_external_id,
            selected_stock_req: selected_stock_req
        });
    }

    updateComment = (comment: string) => {

        const transaction_external_id = this.state.transaction_external_id;

        if (this.state.transaction_external_id === "") {
            return;
        }

        const changing_sr = this.state.stock_requirements.find(sr => sr.transaction_external_id === transaction_external_id);
        if (!changing_sr) {
            return;
        }

        changing_sr.comment = comment;

        getBackend().manufacturing.updateStockRequirementsComment(
            { transaction_external_id, comment }
        );

        this.closeEditCommentModal();
    }

    closeEditCommentModal = () => {
        this.setState({ show_edit_comment: false });
    }

    getMaterialInfo = () => {
        const transaction_external_id = this.state.transaction_external_id;

        const changing_sr = this.state.stock_requirements.find(sr => sr.transaction_external_id === transaction_external_id);

        if (!changing_sr) {
            return "";
        }

        return (
            <div style={{ paddingBottom: "30px" }}>
                <div>
                    <span className="font-weight-bold">
                        <FormattedMessage id="Manufacturing.StocksView.date_expected" defaultMessage="Date expected" />:&nbsp;
                    </span>
                    <span>{moment(changing_sr.date_expected).format("YYYY-MM-DD")}</span>
                </div>
                <div>
                    <span className="font-weight-bold">
                        <FormattedMessage id="Manufacturing.StocksView.date_required" defaultMessage="Date required" />:&nbsp;
                    </span>
                    <span>{moment(changing_sr.date_required).format("YYYY-MM-DD")}</span>
                </div>
                <div>
                    <span className="font-weight-bold">
                        <FormattedMessage id="Manufacturing.StocksView.vendor_title" defaultMessage="Vendor title" />:&nbsp;
                    </span>
                    <span>{changing_sr.vendor_title}</span>
                </div>
                <div>
                    <span className="font-weight-bold">
                        <FormattedMessage id="Manufacturing.StocksView.transaction_external_id" defaultMessage="Transaction external id" />:&nbsp;
                    </span>
                    <span>{changing_sr.transaction_external_id}</span>
                </div>
                <div>
                    <span className="font-weight-bold">
                        <FormattedMessage id="Manufacturing.StocksView.material_title" defaultMessage="Material title" />:&nbsp;
                    </span>
                    <span>{changing_sr.material_title}</span>
                </div>
                <div>
                    <span className="font-weight-bold">
                        <FormattedMessage id="Manufacturing.StocksView.material_external_id" defaultMessage="Material external id" />:&nbsp;
                    </span>
                    <span>{changing_sr.material_external_id}</span>
                </div>
                <div>
                    <span className="font-weight-bold">
                        <FormattedMessage id="Manufacturing.StocksView.stock_location_title" defaultMessage="Stock location title" />:&nbsp;
                    </span>
                    <span>{changing_sr.stock_location_title}</span>
                </div>
                <div>
                    <span className="font-weight-bold">
                        <FormattedMessage id="Manufacturing.StocksView.quantity" defaultMessage="Quantity" />:&nbsp;
                    </span>
                    <span>{changing_sr.quantity}</span>
                </div>
            </div>
        );

    }

    render() {
        return (
            <div className="container-fluid">
                <div className="co-md-12 text-center">
                    <div className="white_box charts stock-requirements">
                        {this.state.is_loading === true ? <Loader /> : <ReactTable
                            data={this.state.stock_requirements}
                            columns={getTableColumns(this.showEditCommentModal)}
                            isSticky={true}
                            initialState={{
                                sortBy: [
                                    {
                                        id: STOCK_REQUIREMENTS_COLUMNS.date_expected,
                                        desc: false
                                    }
                                ]
                            }}
                        />}
                    </div>
                </div>
                {this.state.show_edit_comment &&
                    <StockRequirementModal
                        show={this.state.show_edit_comment}
                        comment={this.state.comment}
                        updateComment={this.updateComment}
                        closeEditCommentModal={this.closeEditCommentModal}
                        stock_req={this.state.selected_stock_req}
                    />}

            </div>
        );
    }
}
// $FlowFixMe
export default React.memo(StockRequirements);
