// @flow

// main imports
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import ErrorComponent from "./ErrorComponent";

// backend
import { getBackend } from "../lib/backend/Backend2";
import { stringCompare } from "../lib/Util";
import * as t from "../lib/backend/manufacturing2.generated.types";
import * as t2 from "../lib/backend/users.generated.types";

type Props = {
    show: boolean,
    userUuid: string | null,
    onClose: Function
};

type State = {
    allLineGroups: t.ILineGroupData[],
    error: string | null,
    plants: t.IPlantData[],
    selectedLineGroups: string[],
    userInfo: t2.IGetSingleUserRes | null
};

/**  Form to edit user's line-group permissions. */
class UserEditLineGroups extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            allLineGroups: [],
            error: null,
            plants: [],
            selectedLineGroups: [],
            userInfo: null
        }
    }

    async componentDidMount() {
        await this.fetchData();
    }
    async componentDidUpdate(prevProps: Props) {
        if (prevProps.userUuid !== this.props.userUuid) {
            await this.fetchData();
        }
    }

    async fetchData() {
        try {
            const userUuid = this.props.userUuid;
            if (userUuid !== null) {
                const res_all_groups = await getBackend().manufacturing.getLineGroups({});
                const res_plants = await getBackend().manufacturing.getPlants({});
                const res_groups_for_user = await getBackend().manufacturing.getLineGroups({ user_uuid: userUuid });
                const res_user_data = await getBackend().users.getSingleUser({ id: userUuid });

                const plant_map: Map<string, t.IPlantData> = new Map();
                res_plants.plants.forEach(p => plant_map.set(p.uuid, p));

                res_all_groups.line_groups.forEach(lg => {
                    // prepend plant(s) name(s) to line-group title
                    let titles = lg.plant_uuids.map(p => {
                        const plant = plant_map.get(p);
                        return (plant ? plant.title : "");
                    });
                    lg.title = `[${titles.sort().join(", ")}] ${lg.title}`;
                });
                this.setState({
                    allLineGroups: res_all_groups.line_groups.sort((a, b) => stringCompare(a.title, b.title)),
                    plants: res_plants.plants,
                    selectedLineGroups: res_groups_for_user.line_groups.map(x => x.uuid),
                    userInfo: res_user_data
                });
            } else {
                this.setState({ allLineGroups: [], selectedLineGroups: [], userInfo: null });
                return;
            }
        } catch (err) {
            console.log(err);
            this.setState({ error: err.message });
        }
    }

    drawLineGroup(line_group: t.ILineGroupData) {
        const isChecked = this.state.selectedLineGroups.indexOf(line_group.uuid) >= 0;
        return (
            <tr key={"user-line-groups:" + line_group.uuid}>
                <td>
                    <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={() => {
                            this.setState(prevState => {
                                if (isChecked) {
                                    prevState.selectedLineGroups = prevState.selectedLineGroups
                                        .filter(x => x !== line_group.uuid);
                                } else {
                                    prevState.selectedLineGroups.push(line_group.uuid);
                                }
                                return prevState;
                            });
                        }} />
                </td>
                <td>{line_group.title}</td>
            </tr>
        );
    }

    async handleSave() {
        try {
            const userUuid = this.props.userUuid;
            if (userUuid !== null) {
                await getBackend().manufacturing.setLineGroupsForUser({
                    line_group_uuids: this.state.selectedLineGroups,
                    user_uuid: userUuid
                });
            }
            this.props.onClose();
        } catch (err) {
            this.setState({ error: err.message });
        }
    }

    render() {
        const { show, onClose } = this.props;
        return (
            <Modal
                show={show}
                dialogClassName="modal-dialog-scrollable"
                onHide={onClose}
            >
                <Modal.Header>
                    <Modal.Title>
                        {this.state.userInfo && this.state.userInfo.title} - <FormattedMessage id="common.linegroups" defaultMessage="Line-groups" />
                    </Modal.Title>
                    <button type="button" className="close" onClick={onClose}>
                        <span aria-hidden="true">×</span>
                        <span className="sr-only"><FormattedMessage id="common.close" defaultMessage="Close" /></span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    {this.state.error && <ErrorComponent msg={this.state.error} type="error" />}
                    <table className="table">
                        <tbody>
                            {this.state.allLineGroups.map(lg => this.drawLineGroup(lg))}
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary"
                        onClick={() => this.handleSave()}
                        disabled={false}>
                        <FormattedMessage id="common.save" defaultMessage="Save" />
                    </button>
                    <button className="btn btn-outline-secondary"
                        onClick={onClose}>
                        <FormattedMessage id="common.cancel" defaultMessage="Cancel" />
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default UserEditLineGroups;
