// @flow
import * as React from "react";
import ReactRouterPropTypes from "react-router-prop-types";
import Barcode from "react-barcode";

import * as Auth from "../../lib/Auth";
import { PERMISSION_NAMES } from "../../lib/Auth";
import {
    TIME_RANGES,
    orderColorCSS,
    niceNumber,
    niceDate,
    niceDateTime,
    dateFromWeekAndShift,
    SHIFT_CONSTS,
    classNames
} from "../../lib/Util";
import * as t from "../../lib/backend/manufacturing2.generated.types";
import * as t2 from "../../lib/SimulationReportModels";
import OrderTableProductionModal from "./OrderTableProductionModal";
import MaterialInfoModal from "./MaterialInfoModal";
import { translate } from "../IntlProviderWrapper";
import Authorization from "../Authorization";
import TranslatedMaterialTitle from "../TranslatedMaterialTitle";
import { ORDER_STATUS, ORDER_TYPE } from "../../lib/ManufacturingConsts.generated";
import { getOrderModelParamsTooltip } from "../../lib/BusinessLogic";
import { MATERIAL_TAGS, ORDER_TAGS, ORDER_TAGS_ACCESS } from "../../lib/ManufacturingTags.generated";
import { exportBarcode, hexToRgb, shiftNumber } from "../../lib/Util";
import { getBackend } from "../../lib/backend/Backend2";
import ErrorComponent from "../ErrorComponent";

import type { OrderShift } from "./ShiftTableStandalone";

// $FlowFixMe
import "../../styles/order_table_production.scss";

export const WORK_ORDER_TABLE_COLUMNS = {
    material: "material",
    material_comment: "material_comment",
    order_id: "order_id",
    operation: "operation",
    operation_comment: "operation_comment",
    sap_start: "sap_start",
    sap_completion: "sap_completion",
    expected_start: "expected_start",
    expected_completion: "expected_completion",
    time_to_complete: "time_to_complete",
    quantity: "quantity",
    quantity_in_operation_unit: "quantity_in_operation_unit"
};

type SortDirection = "asc" | "desc";

type OrderRows = {
    order_id: string,
    material: string,
    expected_start: Date | void,
    expected_completion: Date | void,
    earliest_end: Date,
    comment: string,
    nodes: React.Node[]
};

type OrderTableProductionHeaderProps = {
    selected_columns?: string[],
    show_material_comment: boolean,
    compact: boolean,
    show_order_id: boolean,
    show_material: boolean,
    show_operation: boolean,
    show_operation_comment: boolean,
    earliest_dates: boolean,
    show_order_shifts: boolean,
    show_expected_start: boolean,
    show_expected_completion: boolean,
    show_time_to_complete: boolean,
    show_quantity: boolean,
    show_alternative_unit: boolean,
    measurement_unit: string,
    line_uuid: string,
    is_print_export: boolean,
    sim_created_at: number | null,
    sort_column: string,
    sort_direction: SortDirection,
    onToggleSort: (column: string) => void
}

type OrderTableProductionHeaderState = {};

class OrderTableProductionHeader extends React.Component<OrderTableProductionHeaderProps, OrderTableProductionHeaderState> {

    scroll_listener = null;
    resize_listener = null;

    constructor(props) {
        super(props);
        this.scroll_listener = (e) => this.onScroll(e);
        window.addEventListener("scroll", this.scroll_listener, false);
        this.resize_listener = () => this.onResize();
        window.addEventListener("resize", this.resize_listener);
    }

    onResize = () => {
        this.scrollStickyHeader();
    }

    componentDidUpdate(prev_props: OrderTableProductionHeaderProps) {
        if (prev_props.sim_created_at !== this.props.sim_created_at && this.props.sim_created_at) {
            this.scrollStickyHeader();
        }
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.scroll_listener);
        window.removeEventListener("resize", this.resize_listener);
    }

    onScroll = (e: Event) => {
        if (this.props.is_print_export) {
            return;
        }
        this.scrollStickyHeader();
    }

    scrollStickyHeader = (e) => {
        let filter_bar_lower_side = null;
        const filter_bar_el = document.querySelector(".views-filter-bar") || document.querySelector(".navigation-bar");
        if (filter_bar_el) {
            const rect = filter_bar_el.getBoundingClientRect();
            if (rect) {
                filter_bar_lower_side = rect.bottom;
            }
        }

        const header_el = document.querySelector(`#order-table-production-header[data-line-uuid='${this.props.line_uuid}']`);
        const wrapper_el = document.querySelector(`table[data-line-uuid='${this.props.line_uuid}']`);

        if (wrapper_el && header_el && filter_bar_lower_side) {
            const wrapper_rect = wrapper_el.getBoundingClientRect();
            header_el.style.position = "sticky";
            if (filter_bar_lower_side > wrapper_rect.top) {
                header_el.style.top = (filter_bar_lower_side - wrapper_rect.top) + "px";
            } else {
                header_el.style.top = "unset";
            }
            header_el.style.backgroundColor = "white";
            header_el.style.zIndex = "1"
        }
    }

    countVisibleColumns = (): number => {
        let count = Object.keys(WORK_ORDER_TABLE_COLUMNS).reduce(
            (acc, column) => acc + Number(this.isColumnVisible(column)),
            0
        );
        count += Number(!this.props.compact);
        return count;
    }

    getQuantityColumnStyle = () => (this.props.show_order_shifts ? {
        width: `${100 / this.countVisibleColumns()}%`
    } : {});

    getTimeColumnStyle = () => (this.props.show_order_shifts ? {
        width: `${100 / this.countVisibleColumns() + 5}%`
    } : {});

    isColumnSelected = (column: string) => {
        if (!this.props.selected_columns) {
            return true;
        }

        return this.props.selected_columns.indexOf(column) >= 0;
    }

    isColumnVisible = (column: string) => {
        const is_column_selected = this.isColumnSelected(column);

        switch (column) {
            case WORK_ORDER_TABLE_COLUMNS.material:
                return is_column_selected && this.props.show_material;
            case WORK_ORDER_TABLE_COLUMNS.material_comment:
                return is_column_selected && !this.props.compact && this.props.show_material_comment;
            case WORK_ORDER_TABLE_COLUMNS.order_id:
                return is_column_selected && this.props.show_order_id;
            case WORK_ORDER_TABLE_COLUMNS.operation:
                return is_column_selected && this.props.show_operation;
            case WORK_ORDER_TABLE_COLUMNS.operation_comment:
                return is_column_selected && !this.props.compact && this.props.show_operation_comment;
            case WORK_ORDER_TABLE_COLUMNS.sap_start:
                return is_column_selected && !this.props.compact && this.props.earliest_dates;
            case WORK_ORDER_TABLE_COLUMNS.sap_completion:
                return is_column_selected && !this.props.compact && this.props.earliest_dates;
            case WORK_ORDER_TABLE_COLUMNS.expected_start:
                return is_column_selected && !this.props.compact && !this.props.show_order_shifts && this.props.show_expected_start;
            case WORK_ORDER_TABLE_COLUMNS.expected_completion:
                return is_column_selected && !this.props.compact && !this.props.show_order_shifts && this.props.show_expected_completion;
            case WORK_ORDER_TABLE_COLUMNS.time_to_complete:
                return is_column_selected && !this.props.compact && this.props.show_time_to_complete;
            case WORK_ORDER_TABLE_COLUMNS.quantity:
                return is_column_selected && !this.props.compact && this.props.show_quantity;
            case WORK_ORDER_TABLE_COLUMNS.quantity_in_operation_unit:
                return is_column_selected && !this.props.compact && this.props.show_alternative_unit;
            default:
                return is_column_selected;
        }
    }

    renderSortIndicator = (column: string) => {
        if (this.props.sort_column !== column) {
            return null;
        }

        const direction = this.props.sort_direction === "asc" ? "up" : "down";
        return <i className={`fas fa-long-arrow-alt-${direction}`} />;
    }

    render() {
        const quant_col_style = this.getQuantityColumnStyle();
        const time_col_style = this.getTimeColumnStyle();

        let id = "";
        if (this.props.is_print_export) {
            id = "order-table-production-header-export";
        } else {
            id = "order-table-production-header";
        }

        return <thead id={id} data-line-uuid={this.props.line_uuid}>
            <tr>
                {this.isColumnVisible(WORK_ORDER_TABLE_COLUMNS.material) && (
                    <th
                        className="material is-sortable"
                        title={translate("common.toggle_sorting", "Toggle sorting")}
                        onClick={() => {
                            this.props.onToggleSort(WORK_ORDER_TABLE_COLUMNS.material);
                        }}
                    >
                        {translate("common.material", "Material")} {this.renderSortIndicator(WORK_ORDER_TABLE_COLUMNS.material)}
                    </th>
                )}
                {this.isColumnVisible(WORK_ORDER_TABLE_COLUMNS.material_comment) && <th className="material-comment">
                    {translate("common.material_comment", "Material comment")}
                </th>}
                {this.isColumnVisible(WORK_ORDER_TABLE_COLUMNS.order_id) && (
                    <th
                        className="order is-sortable"
                        title={translate("common.toggle_sorting", "Toggle sorting")}
                        onClick={() => {
                            this.props.onToggleSort(WORK_ORDER_TABLE_COLUMNS.order_id);
                        }}
                    >
                        {translate("OrderTableProduction.order_id", "Order ID")} {this.renderSortIndicator(WORK_ORDER_TABLE_COLUMNS.order_id)}
                    </th>
                )}
                {this.isColumnVisible(WORK_ORDER_TABLE_COLUMNS.operation) && <th className="operation">
                    {translate("common.operation", "Operation")}
                </th>}
                {this.isColumnVisible(WORK_ORDER_TABLE_COLUMNS.operation_comment) && (
                    <th
                        className="operation-comment is-sortable"
                        title={translate("common.toggle_sorting", "Toggle sorting")}
                        onClick={() => {
                            this.props.onToggleSort(WORK_ORDER_TABLE_COLUMNS.operation_comment);
                        }}
                    >
                        {translate("common.comment", "Comment")} {this.renderSortIndicator(WORK_ORDER_TABLE_COLUMNS.operation_comment)}
                    </th>
                )}
                {this.isColumnVisible(WORK_ORDER_TABLE_COLUMNS.sap_start) && <th>
                    {translate("OrderTableProduction.earliest_start", "ERP start")}
                </th>}
                {this.isColumnVisible(WORK_ORDER_TABLE_COLUMNS.sap_completion) && <th>
                    {translate("OrderTableProduction.earliest_end", "ERP completion")}
                </th>}
                {this.isColumnVisible(WORK_ORDER_TABLE_COLUMNS.expected_start) && (
                    <th
                        className="is-sortable"
                        title={translate("common.toggle_sorting", "Toggle sorting")}
                        onClick={() => {
                            this.props.onToggleSort(WORK_ORDER_TABLE_COLUMNS.expected_start);
                        }}
                    >
                        {translate("OrderTableProduction.predicted_start", "Expected start")} {this.renderSortIndicator(WORK_ORDER_TABLE_COLUMNS.expected_start)}
                    </th>
                )}
                {this.isColumnVisible(WORK_ORDER_TABLE_COLUMNS.expected_completion) && (
                    <th
                    className="is-sortable"
                        title={translate("common.toggle_sorting", "Toggle sorting")}
                        onClick={() => {
                            this.props.onToggleSort(WORK_ORDER_TABLE_COLUMNS.expected_completion);
                        }}>
                        {translate("OrderTableProduction.predicted_end", "Expected completion")} {this.renderSortIndicator(WORK_ORDER_TABLE_COLUMNS.expected_completion)}
                    </th>
                )}
                {this.isColumnVisible(WORK_ORDER_TABLE_COLUMNS.quantity_in_operation_unit) && <th style={quant_col_style}>
                    {translate("OrderTableProduction.quantity_in_operation_unit", "Quantity in operation measurement unit")}
                </th>}
                {this.isColumnVisible(WORK_ORDER_TABLE_COLUMNS.time_to_complete) && <th style={time_col_style}>
                    {translate("OrderTableProduction.time_to_complete", "Time to complete [h]")}
                </th>}
                {this.isColumnVisible(WORK_ORDER_TABLE_COLUMNS.quantity) && <th style={quant_col_style}>
                    {translate("OrderTableProduction.done_plan_quant", "Produced/Target quantity")} [{this.props.measurement_unit}]
                </th>}
                {!this.props.compact && <th className="work-order-actions"></th>}
            </tr>
        </thead>
    }
}

export const cleanExternalId = (id: string) => {
    return id.replace(/_.*/, "");
}

export const getBarcodeProps = (id: string) => ({
    value: `CL${id}`,
    background: "white",
    lineColor: "black",
    width: 1,
    height: 50
});

const compareString = (a: string, b: string, direction: SortDirection): number => {
    if (a !== "" && b === "") {
        return -1;
    }

    if (a === "" && b !== "") {
        return 1;
    }

    return direction === "asc" ? a.localeCompare(b) : b.localeCompare(a);
};

const compareNumber = (a: number | void, b: number | void, direction: SortDirection): number => {
    if (a !== undefined && b !== undefined) {
        return direction === "asc" ? a - b : b - a;
    }

    if (a !== undefined && b === undefined) {
        return -1;
    }

    if (a === undefined && b !== undefined) {
        return 1;
    }

    return 0;
}

const compareOrderRows = (a: OrderRows, b: OrderRows, direction: SortDirection): number => {
    const expected_start_a = a.expected_start && a.expected_start.getTime();
    const expected_start_b = b.expected_start && b.expected_start.getTime();
    const expected_start_cmp = compareNumber(expected_start_a, expected_start_b, direction);
    if (expected_start_cmp !== 0) {
        return expected_start_cmp;
    }

    const earliest_end_a = a.earliest_end.getTime();
    const earliest_end_b = b.earliest_end.getTime();
    return compareNumber(earliest_end_a, earliest_end_b, direction);
};

type ShiftTableLine = {
    uuid: string,
    title: string
};

type Props = {
    history: ReactRouterPropTypes.history,
    orders: t2.ISimulationReportOrderEx[],
    unscheduled_orders: t.IOrderProducedModel[],
    order_shifts: Map<string, OrderShift[]>,
    start_timestamp: number,
    compact: boolean,
    earliest_dates: boolean,
    show_order_shifts: boolean,
    show_setup_code: boolean,
    show_barcode: boolean,
    show_operation_comment: boolean,
    show_material_comment: boolean,
    show_external_operation_id: boolean,
    show_alternative_unit: boolean,
    show_material: boolean,
    show_order_id: boolean,
    show_operation: boolean,
    show_expected_start: boolean,
    show_expected_completion: boolean,
    show_time_to_complete: boolean,
    show_quantity: boolean,
    showLinePlanningModal: (order_uuids: string[]) => void,
    line: ShiftTableLine,
    materials: t.IMaterialModel[],
    operations: t.IOrderModelBase[],
    onOrderChange?: (order: t.IOrderModelBase) => void,
    selected_columns?: string[],
    render_to_string?: boolean,
    shifts_limit?: number,
    sim_created_at: number | null,
    report_uuid?: string,
    scheduling_horizon: number
};

type OrderCommentsMap = Map<string, string>;

type State = {
    show: boolean,
    show_material_info_modal: boolean,
    selected_material_external_id: string,
    barecodes: Map<string, string>,
    selected_order_uuid: string,
    selected_order_external_id: string,
    selected_order_process_num: string,
    order_comments_map: OrderCommentsMap,
    sort_column: string,
    sort_direction: SortDirection,
    edited_operation: t.IOrderModelBase | t.IOrderProducedModel | null,
    edited_operation_comment: string | null,
    edited_operation_error: string,
    is_saving: boolean
};

class OrderTableProduction extends React.Component<Props, State> {

    is_admin = false;

    constructor(props: Props) {
        super(props);

        this.is_admin = Auth.isInRole(Auth.ROLE_ADMIN);

        const state: State = {
            show: false,
            show_material_info_modal: false,
            selected_material_external_id: "",
            barecodes: new Map(),
            selected_order_uuid: "",
            selected_order_process_num: "",
            selected_order_external_id: "",
            order_comments_map: new Map(),
            columns_sort: new Map(),
            sort_column: "",
            sort_direction: "asc",
            edited_operation: null,
            edited_operation_comment: null,
            edited_operation_error: "",
            is_saving: false
        }
        this.state = state;
    }

    componentDidMount() {
        this.loadComments();
    }

    componentDidUpdate(prev_props: Props, prev_state: State) {
        if (prev_props.orders !== this.props.orders ||
            prev_state.show !== this.state.show && !this.state.show) {
            this.loadComments();
        }
    }

    loadComments = async () => {
        const order_uuid_parent = new Map < string, string> ();
        const parent_order_comments_map = new Map < string, string> ();
        const order_comments_map: OrderCommentsMap = new Map();
        // get comments for all chunk orders
        const chunk_orders = this.props.operations.filter(o => o.order_type === ORDER_TYPE.chunk);
        if (chunk_orders.length > 0) {
            for (const chunk_order of chunk_orders) {
                if (chunk_order.parent_order_uuid) {
                    order_uuid_parent.set(chunk_order.uuid, chunk_order.parent_order_uuid);
                }
            }

            if (order_uuid_parent.size > 0) {
                const res_parent = await getBackend().manufacturing.getOrdersSimple({
                    uuids: [...(new Set(order_uuid_parent.values()))]
                });
                const parent_orders = res_parent.operations;
                for (const parent_order of parent_orders) {
                    parent_order_comments_map.set(parent_order.uuid, parent_order.tags[ORDER_TAGS.comment])
                }
            }

            for (const [order_uuid, parent_uuid] of order_uuid_parent.entries()) {
                const comment = parent_order_comments_map.get(parent_uuid)
                if (comment) {
                    order_comments_map.set(order_uuid, comment);
                }
            }

            this.setState({ order_comments_map });
        }
    }

    isColumnSelected = (column: string) => {
        if (!this.props.selected_columns) {
            return true;
        }

        return this.props.selected_columns.indexOf(column) >= 0;
    }

    isColumnVisible = (column: string) => {
        const is_column_selected = this.isColumnSelected(column);
        switch (column) {
            case WORK_ORDER_TABLE_COLUMNS.material:
                return is_column_selected && this.props.show_material;
            case WORK_ORDER_TABLE_COLUMNS.material_comment:
                return is_column_selected && !this.props.compact && this.props.show_material_comment;
            case WORK_ORDER_TABLE_COLUMNS.order_id:
                return is_column_selected && this.props.show_order_id;
            case WORK_ORDER_TABLE_COLUMNS.operation:
                return is_column_selected && this.props.show_operation;
            case WORK_ORDER_TABLE_COLUMNS.operation_comment:
                return is_column_selected && !this.props.compact && this.props.show_operation_comment;
            case WORK_ORDER_TABLE_COLUMNS.sap_start:
                return is_column_selected && !this.props.compact && this.props.earliest_dates;
            case WORK_ORDER_TABLE_COLUMNS.sap_completion:
                return is_column_selected && !this.props.compact && this.props.earliest_dates;
            case WORK_ORDER_TABLE_COLUMNS.expected_start:
                return is_column_selected && !this.props.compact && !this.props.show_order_shifts && this.props.show_expected_start;
            case WORK_ORDER_TABLE_COLUMNS.expected_completion:
                return is_column_selected && !this.props.compact && !this.props.show_order_shifts && this.props.show_expected_completion;
            case WORK_ORDER_TABLE_COLUMNS.time_to_complete:
                return is_column_selected && !this.props.compact && this.props.show_time_to_complete;
            case WORK_ORDER_TABLE_COLUMNS.quantity:
                return is_column_selected && !this.props.compact && this.props.show_quantity;
            case WORK_ORDER_TABLE_COLUMNS.quantity_in_operation_unit:
                return is_column_selected && !this.props.compact && this.props.show_alternative_unit;
            default:
                return is_column_selected;
        }
    }

    countVisibleColumns = (): number => {
        let count = Object.keys(WORK_ORDER_TABLE_COLUMNS).reduce(
            (acc, column) => acc + Number(this.isColumnVisible(column)),
            0
        );
        count += Number(!this.props.compact);
        return count;
    }

    getQuantityColumnStyle = () => (this.props.show_order_shifts ? {
        width: `${100 / this.countVisibleColumns()}%`
    } : {});

    getTimeColumnStyle = () => (this.props.show_order_shifts ? {
        width: `${100 / this.countVisibleColumns() + 5}%`
    } : {});

    onShowOrHideOrderModal = (show: boolean, order_uuid: string, order_external_id: string, process_num: string) => {
        this.setState({
            show,
            selected_order_uuid: order_uuid,
            selected_order_external_id: order_external_id,
            selected_order_process_num: process_num
        });
    }

    handleColumnSortTogle = (column: string) => {
        this.setState(prev_state => {
            const { sort_column, sort_direction, ...state } = prev_state;
            let new_column = column;
            let new_direction: SortDirection = "asc";

            if (column === sort_column) {
                if (sort_direction === "asc") {
                    new_direction = "desc";
                } else {
                    new_column = "";
                }
            }

            return {
                ...state,
                sort_column: new_column,
                sort_direction: new_direction
            };
        });
    }

    renderLineOrderShifts(order: t2.ISimulationReportOrderEx, shifts: OrderShift[]) {
        const rows = [];
        const order_start_date = this.props.start_timestamp + Math.round(order.estimated_start[1].val * 6) / 6 * TIME_RANGES.HOUR;
        const order_tool_setup_end = this.props.start_timestamp + Math.round(order.estimated_tool_setup_end[1].val * 6) / 6 * TIME_RANGES.HOUR;
        const order_end_date = this.props.start_timestamp + Math.round(order.estimated_completion[1].val * 6) / 6 * TIME_RANGES.HOUR;
        // const num_cols = this.countVisibleColumns();
        const is_placeholder_col = Object.keys(WORK_ORDER_TABLE_COLUMNS).some(col => ![
            WORK_ORDER_TABLE_COLUMNS.quantity_in_operation_unit,
            WORK_ORDER_TABLE_COLUMNS.time_to_complete,
            WORK_ORDER_TABLE_COLUMNS.quantity
        ].includes(col) && this.isColumnVisible(col));
        const is_quantity_in_operation_unit = this.isColumnVisible(WORK_ORDER_TABLE_COLUMNS.quantity_in_operation_unit);
        const is_time_to_complete = this.isColumnVisible(WORK_ORDER_TABLE_COLUMNS.time_to_complete);
        const is_quantity = this.isColumnVisible(WORK_ORDER_TABLE_COLUMNS.quantity);
        const quant_col_style = this.getQuantityColumnStyle();
        const time_col_style = this.getTimeColumnStyle();
        let skip_shift_i = -1;
        for (let shift_i = 0; shift_i < shifts.length; shift_i++) {
            const shift = shifts[shift_i];
            const shift_start_date = dateFromWeekAndShift(shift.shift_time.week, shift.shift_time.year, shift.shift_time.shift_number).getTime();
            const shift_end_date = dateFromWeekAndShift(shift.shift_time.week, shift.shift_time.year, shift.shift_time.shift_number + 1).getTime();
            // skip if shift before order even starts in this quantile
            if (shift_end_date <= order_start_date) {
                skip_shift_i = shift_i;
                continue;
            }

            const is_first_valid_shift = skip_shift_i === shift_i - 1;
            // compute intersection between shift and order slot
            const start_date = is_first_valid_shift ? order_start_date : shift_start_date;
            const end_date = (shift_i === (shifts.length - 1)) ? order_end_date : shift_end_date;
            const operation = this.props.operations.find(o => o.uuid === order.order_id);
            const measurement_unit = order.measurement_unit;
            const alt_measurement_unit = (operation && operation.tags[ORDER_TAGS.total_quantity_measurement_unit]) || measurement_unit;
            const conversion_factor = Number((operation && operation.tags[ORDER_TAGS.operation_unit_conversion_factor]) || 1);

            // collect detected realization in this shift for this order
            const realization_in_shift = (shift_i === 0) ? order.quantity_produced_next : 0;
            let tooltip: string | null = null;
            if (realization_in_shift > 0) {
                tooltip = [
                    `${translate("common.current_shift", "Current shift")}:`,
                    ` - ${translate("common.produced", "Produced").toLowerCase()}: ${niceNumber(realization_in_shift, 0)}`,
                    ` - ${translate("common.predicted", "Predicted").toLowerCase()}: ${niceNumber(shift.quantity_predicted, 0)}`
                ].join("\n");

            }

            // check if before, during or after tool setup
            if (order_tool_setup_end <= start_date) {
                // no tool setup or we are already after it
                rows.push(
                    <tr key={shift_i}>
                        {is_placeholder_col && <td></td>}
                        {is_quantity_in_operation_unit && <td style={quant_col_style}>
                            {niceNumber(shift.quantity_predicted * conversion_factor, 0)} {alt_measurement_unit}
                        </td>}
                        {is_time_to_complete && <td style={time_col_style}>
                            {niceDateTime(new Date(start_date))} - {niceDateTime(new Date(end_date))}
                        </td>}
                        {is_quantity && <td style={quant_col_style} title={tooltip}>
                            {realization_in_shift > 0 ? `* ${niceNumber(realization_in_shift, 0)} / ` : ""}
                            {niceNumber(shift.quantity_predicted, 0)} {measurement_unit}
                        </td>}
                        {!this.props.compact && <td className="work-order-actions"></td>}
                    </tr>
                );
            } else if (end_date <= order_tool_setup_end) {
                // we are during tool setup
                rows.push(
                    <tr key={shift_i}>
                        {is_placeholder_col && <td></td>}
                        {is_quantity_in_operation_unit && <td style={quant_col_style}>
                            {translate("common.tool_setup", "Tool setup")}
                        </td>}
                        {is_time_to_complete && <td style={time_col_style}>
                            {niceDateTime(new Date(start_date))} - {niceDateTime(new Date(end_date))}
                        </td>}
                        {is_quantity && <td style={quant_col_style}>
                            {translate("common.tool_setup", "Tool setup")}
                        </td>}
                        {!this.props.compact && <td className="work-order-actions"></td>}
                    </tr>
                );
            } else {
                // we are in the slot where tool setup end and production starts
                if (order_start_date != order_tool_setup_end) {
                    rows.push(
                        <tr key={`${shift_i}_X`}>
                            {is_placeholder_col && <td></td>}
                            {is_quantity_in_operation_unit && <td style={quant_col_style}>
                                {translate("common.tool_setup", "Tool setup")}
                            </td>}
                            {is_time_to_complete && <td style={time_col_style}>
                                {niceDateTime(new Date(start_date))} - {niceDateTime(new Date(order_tool_setup_end))}
                            </td>}
                            {is_quantity && <td style={quant_col_style}>
                                {translate("common.tool_setup", "Tool setup")}
                            </td>}
                            {!this.props.compact && <td className="work-order-actions"></td>}
                        </tr>
                    );
                }
                rows.push(
                    <tr key={`${shift_i}_Y`}>
                        {is_placeholder_col && <td></td>}
                        {is_quantity_in_operation_unit && <td style={quant_col_style}>
                            {niceNumber(shift.quantity_predicted * conversion_factor, 0)} {alt_measurement_unit}
                        </td>}
                        {is_time_to_complete && <td style={time_col_style}>
                            {niceDateTime(new Date(order_tool_setup_end))} - {niceDateTime(new Date(end_date))}
                        </td>}
                        {is_quantity && <td style={quant_col_style} title={tooltip}>
                            {realization_in_shift > 0 ? `* ${niceNumber(realization_in_shift, 0)} / ` : ""}
                            {niceNumber(shift.quantity_predicted, 0)} {measurement_unit}
                        </td>}
                        {!this.props.compact && <td className="work-order-actions"></td>}
                    </tr>
                );
            }
        }
        return (
            <table className="order-table-shifts">
                <tbody>{rows}</tbody>
            </table>
        );
    }

    renderBarcode = (id: string) => {
        if (this.state.barecodes.get(id)) {
            return;
        }

        exportBarcode(getBarcodeProps(id), (svg) => {
            if (!this.state.barecodes.get(id)) {
                const barecodes = this.state.barecodes;
                barecodes.set(id, svg);
                this.setState({
                    barecodes
                });
            }
        });
    }

    getComment = (operation: t.IOrderModelBase | t.IOrderProducedModel) => {
        if (operation.order_type === ORDER_TYPE.chunk) {
            return this.state.order_comments_map.get(operation.uuid);
        }
        return operation.tags[ORDER_TAGS.comment];
    }

    getUnscheduledOrders(): t2.ISimulationReportOrderEx[] {
        const material_order_map: Map<string, t.IOrderProducedModel> = new Map();
        for (const order of this.props.unscheduled_orders) {
            const material_order = material_order_map.get(order.material_uuid);
            if (material_order === undefined) {
                material_order_map.set(order.material_uuid, order);
            } else if (order.earliest_start < material_order.earliest_start) {
                material_order_map.set(order.material_uuid, order);
            }
        }

        const orders: t2.ISimulationReportOrderEx[] = [];
        material_order_map.forEach(order => {
            orders.push({
                batches: [],
                bom_inputs: [],
                capacity_factor: order.capacity_factor,
                earliest_end: new Date(order.earliest_end).toISOString(),
                earliest_start: new Date(order.earliest_start).toISOString(),
                estimated_completion: [],
                estimated_duration: [],
                estimated_start: [],
                estimated_tool_setup_end: [],
                failed_scheduling: false,
                freeze_time_end: 0,
                freeze_time_start: 0,
                initial_earliest_end: new Date(order.initial_earliest_end).toISOString(),
                initial_earliest_start: new Date(order.initial_earliest_start).toISOString(),
                insight_freeze_order: false,
                line_uuid: order.line_uuid,
                material: order.material_external_id,
                material_color_counter: 0,
                material_title: order.material_title,
                measurement_unit: order.measurement_unit,
                model_intercept: 0,
                model_normative_fallback: false,
                model_slope: 0,
                operation_external_id: order.external_operation_id,
                order_color_counter: 0,
                order_external_id: order.external_id,
                order_id: order.uuid,
                order_status: order.status,
                order_type: order.order_type,
                original_production_version: "",
                person_per_shift: 0,
                process: "",
                process_num: Number(order.process_num),
                process_title: order.title || "",
                production_version: "",
                quantity_produced: order.quantity_produced,
                quantity_produced_fixed: order.quantity_produced,
                quantity_produced_next: 0,
                quantity_total: order.quantity_total,
                sequence_weight: order.sequence_weight,
                sim_ignore_earliest_start: order.sim_ignore_earliest_start,
                tool_setup: false,
            });
        });
        orders.sort((a, b) => a.earliest_end.localeCompare(b.earliest_end));

        return orders;
    }

    getOrderColorCSS(order: t2.ISimulationReportOrderEx, is_unscheduled?: boolean) {
        return is_unscheduled ? "" : orderColorCSS(order.order_type, order.order_color_counter)
    }

    isOrderWithinShifts(order: t2.ISimulationReportOrderEx, shifts: number): boolean {
        if (!order.estimated_start.length || !order.estimated_completion.length) {
            return false;
        }

        const { start_timestamp } = this.props;
        const order_start_ts = new Date(start_timestamp + Math.round(order.estimated_start[1].val * 6) / 6 * TIME_RANGES.HOUR).getTime();
        const order_end_ts = new Date(start_timestamp + Math.round(order.estimated_completion[1].val * 6) / 6 * TIME_RANGES.HOUR).getTime();
        const shifts_start_ts = shiftNumber(start_timestamp).ts_start;
        const shifts_end_ts = shiftNumber(start_timestamp + (shifts - 1) * SHIFT_CONSTS.SHIFT_DURATION_HOURS * 60 * 60 * 1000).ts_end;

        return (order_start_ts >= shifts_start_ts && order_start_ts <= shifts_end_ts) ||
            (order_end_ts >= shifts_start_ts && order_end_ts <= shifts_end_ts);
    }

    sortOrdersRows = (order_rows: OrderRows[]): OrderRows[] => {
        if (this.state.sort_column === WORK_ORDER_TABLE_COLUMNS.order_id) {
            return order_rows.sort((a, b) => {
                const order_id_cmp = this.state.sort_direction === "asc"
                    ? a.order_id.localeCompare(b.order_id)
                    : b.order_id.localeCompare(a.order_id);
                if (order_id_cmp !== 0) {
                    return order_id_cmp;
                }

                return compareOrderRows(a, b, "asc");
            });
        } else if (this.state.sort_column === WORK_ORDER_TABLE_COLUMNS.material) {
            return order_rows.sort((a, b) => {
                const material_cmp = this.state.sort_direction === "asc"
                    ? a.material.localeCompare(b.material)
                    : b.material.localeCompare(a.material);
                if (material_cmp !== 0) {
                    return material_cmp;
                }

                return compareOrderRows(a, b, "asc");
            });
        } else if (this.state.sort_column === WORK_ORDER_TABLE_COLUMNS.expected_start) {
            return order_rows.sort((a, b) => {
                const time_a = a.expected_start && a.expected_start.getTime();
                const time_b = b.expected_start && b.expected_start.getTime();
                const expected_start_cmp = compareNumber(time_a, time_b, this.state.sort_direction);
                if (expected_start_cmp !== 0) {
                    return expected_start_cmp;
                }

                return compareOrderRows(a, b, "asc");
            });
        } else if (this.state.sort_column === WORK_ORDER_TABLE_COLUMNS.expected_completion) {
            return order_rows.sort((a, b) => {
                const time_a = a.expected_completion && a.expected_completion.getTime();
                const time_b = b.expected_completion && b.expected_completion.getTime();
                const expected_completion_cmp = compareNumber(time_a, time_b, this.state.sort_direction);
                if (expected_completion_cmp !== 0) {
                    return expected_completion_cmp;
                }

                return compareOrderRows(a, b, "asc");
            });
        } else if (this.state.sort_column === WORK_ORDER_TABLE_COLUMNS.operation_comment) {
            order_rows.sort((a, b) => {
                const comment_cmp = compareString(a.comment, b.comment, this.state.sort_direction);
                if (comment_cmp !== 0) {
                    return comment_cmp;
                }

                return compareOrderRows(a, b, "asc");
            });
        }

        return order_rows;
    };

    handleOperationCommentEdit = async (operation?: t.IOrderModelBase | t.IOrderProducedModel) => {
        if (operation === undefined) {
            return;
        }

        if (this.state.edited_operation !== null) {
            await this.handleOperationCommentEditSave();
        }

        this.setState({
            edited_operation: operation || null,
            edited_operation_comment: this.getComment(operation)
        });
    };

    handleOperationCommentEditFocus = (event: SyntheticEvent<HTMLInputElement>) => {
        event.currentTarget.select();
    };

    handleOperationCommentEditChange = (event: SyntheticEvent<HTMLInputElement>) => {
        this.setState({
            edited_operation_comment: event.currentTarget.value
        });
    };

    handleOperationCommentEditSave = async () => {
        const operation = this.state.edited_operation;
        let save_error = "";
        if (operation !== null && this.state.edited_operation_comment !== this.getComment(operation)) {
            this.setState({
                is_saving: true
            });

            try {
                const uuid = operation.order_type === ORDER_TYPE.chunk && operation.parent_order_uuid != null
                    ? operation.parent_order_uuid
                    : operation.uuid;
                const { operations } = await getBackend().manufacturing.getOrdersSimple({
                    uuids: [uuid]
                });

                const order = operations[0];

                if (order !== undefined) {
                    order.tags = {
                        ...order.tags,
                        [ORDER_TAGS.comment]: this.state.edited_operation_comment
                    }
                    await getBackend().manufacturing.updateOrders({
                        orders: [
                            {
                                uuid: order.uuid,
                                tags: order.tags
                            }
                        ]
                    });

                    this.props.onOrderChange && this.props.onOrderChange(order);
                    if (operation.order_type === ORDER_TYPE.chunk) {
                        await this.loadComments();
                    }
                } else {
                    save_error = translate("common.error_save", "An error occured while saving data");
                }
            } catch (error) {
                save_error = error.message;
            }
        }

        if (save_error !== "") {
            this.setState({
                is_saving: false,
                edited_operation_error: save_error
            });
        } else {
            this.setState({
                is_saving: false,
                edited_operation: null,
                edited_operation_comment: null,
                edited_operation_error: ""
            });
        }
    };

    handleOperationCommentEditCancel = () => {
        this.setState({
            edited_operation: null,
            edited_operation_comment: null,
            edited_operation_error: ""
        });
    };

    handleOperationCommentEditKeyPress = (event: SyntheticKeyboardEvent<HTMLFormElement>) => {
        if (event.key === "Escape") {
            event.preventDefault();
            this.handleOperationCommentEditCancel();
        }
    };

    renderOperationComment = (operation?: t.IOrderModelBase | t.IOrderProducedModel) => {
        if (operation === undefined) {
            return null
        }

        const edited_operation_uuid = this.state.edited_operation && this.state.edited_operation.uuid;
        if (operation.uuid === edited_operation_uuid) {
            return (
                <form
                    className="order-table-production__comment-form"
                    onKeyUp={this.handleOperationCommentEditKeyPress}
                    onSubmit={(e) => {
                        e.preventDefault();
                        this.handleOperationCommentEditSave();
                    }}
                >
                    <div className="form-row">
                        <input
                            className="form-control"
                            value={this.state.edited_operation_comment}
                            onChange={this.handleOperationCommentEditChange}
                            onFocus={this.handleOperationCommentEditFocus}
                            disabled={this.state.is_saving}
                            autoFocus
                        />
                    </div>
                    <div className="form-row">
                        <button
                            className="btn btn-primary"
                            type="submit"
                            disabled={this.state.is_saving}
                        >
                            {translate("common.save", "Save")}
                        </button>
                        <button
                            className="btn btn-outline-secondary"
                            onClick={this.handleOperationCommentEditCancel}
                            disabled={this.state.is_saving}
                        >
                            {translate("common.cancel", "Cancel")}
                        </button>
                        <i
                            className={classNames("fas fa-spinner", {
                                "fa-spin visible": this.state.is_saving
                            })}
                            aria-hidden="true"
                        />
                    </div>
                    {this.state.edited_operation_error && (
                        <div className="form-row">
                            <ErrorComponent type="error" msg={this.state.edited_operation_error} />
                        </div>
                    )}
                </form>
            );
        }

        const comment = this.getComment(operation);
        const show_edit_button = (
            operation.order_type !== ORDER_TYPE.parallel || ORDER_TAGS_ACCESS.parallel_order(operation.tags)
        );
        return (
            <p
                className="order-table-production__comment"
            >
                {show_edit_button && (
                    <button
                        className="btn btn-short btn-flat-light"
                        onClick={(e) => { this.handleOperationCommentEdit(operation); }}
                    >
                        <span className="sr-only">{translate("common.edit", "Edit")}</span>
                    </button>
                )}
                {comment ? <span>{comment}</span> : <span className="text-muted">-</span>}
            </p>
        );
    };

    renderExpectedStart = (order: t2.ISimulationReportOrderEx, expected_start?: Date) => {
        if (expected_start === undefined) {
            return "-";
        }

        if (order.quantity_produced_fixed === 0) {
            return niceDateTime(expected_start);
        }

        if (expected_start.getTime() <= this.props.start_timestamp) {
            return translate("OrderTableProduction.running", "Running");
        }

        return `${translate("common.resumes_at", "Resumes at")}: ${niceDateTime(expected_start)}`;
    };

    renderLineOrderRows() {
        const all_orders = [...this.props.orders, ...this.getUnscheduledOrders()];
        const orders = [];
        for (const order of all_orders) {
            // skip orders of total == 0
            if (order.quantity_total === 0 || order.order_status !== ORDER_STATUS.open) { continue; }
            // we keep this guy
            orders.push(order);
        }

        const quant_col_style = this.getQuantityColumnStyle();
        const time_col_style = this.getTimeColumnStyle();
        let orders_rows: OrderRows[] = [];
        for (const order of orders) {
            const is_unscheduled = order.estimated_duration.length === 0;

            if (this.props.shifts_limit && !this.isOrderWithinShifts(order, this.props.shifts_limit)) {
                continue;
            }

            const show_barcode = this.props.show_barcode && order.order_type !== ORDER_TYPE.plan;
            const operation_external_id = cleanExternalId(order.operation_external_id);

            if (show_barcode && this.props.render_to_string) {
                this.renderBarcode(operation_external_id);
            }

            const std = is_unscheduled ? 0 : (order.estimated_duration[2].val - order.estimated_duration[0].val) / 2;
            const start_date = is_unscheduled
                ? undefined
                : new Date(this.props.start_timestamp + Math.round(order.estimated_start[1].val * 6) / 6 * TIME_RANGES.HOUR);
            const end_date = is_unscheduled
                ? undefined
                : new Date(this.props.start_timestamp + Math.round(order.estimated_completion[1].val * 6) / 6 * TIME_RANGES.HOUR);
            const material_el = TranslatedMaterialTitle(
                {
                    material_external_id: order.material,
                    material_title: order.material_title
                }
            );
            const material = this.props.materials.find(m => m.external_id === order.material);

            const setup_code_style = {};
            if (material) {
                if (material.tags[MATERIAL_TAGS.background_color]) {
                    setup_code_style.backgroundColor = hexToRgb(material.tags[MATERIAL_TAGS.background_color]);
                }

                if (material.tags[MATERIAL_TAGS.font_color]) {
                    setup_code_style.color = hexToRgb(material.tags[MATERIAL_TAGS.font_color]);
                    setup_code_style.fontWeight = 700;
                }
            }

            const operation = (is_unscheduled ? this.props.unscheduled_orders : this.props.operations)
                .find(o => o.uuid === order.order_id);

            const setup_code = this.props.show_setup_code && material && material.tags[MATERIAL_TAGS.setup_code]
                ? <span> - <span className="setup-code" style={setup_code_style}>{material.tags[MATERIAL_TAGS.setup_code]}</span></span>
                : null;
            const barcode = show_barcode
                ? (this.props.render_to_string && this.state.barecodes.get(operation_external_id)
                    ? <div dangerouslySetInnerHTML={{ __html: this.state.barecodes.get(operation_external_id) }} />
                    : <div><Barcode {...getBarcodeProps(operation_external_id)} /></div>)
                : undefined;
            const operation_comment = this.props.show_operation_comment && this.renderOperationComment(operation);
            const material_comment = this.props.show_material_comment && material && material.tags[MATERIAL_TAGS.comment]
                ? <p className="mb-0">{material.tags[MATERIAL_TAGS.comment]}</p>
                : null;
            const external_operation_id = this.props.show_external_operation_id && order.order_type !== ORDER_TYPE.plan
                ? <span>{operation_external_id} - </span>
                : null;
            const failed_scheduling = order.failed_scheduling
                ? (
                    <span
                        title={translate(
                            "common.failed_scheduling_description",
                            "Start and completion dates are not determined"
                        ).replace(/\{\{date\}\}/g, niceDateTime(new Date(this.props.scheduling_horizon)))}
                    >
                        {translate("common.not_determined", "Not determined")}
                    </span>
                )
                : null;
            const measurement_unit = order.measurement_unit;
            const alt_measurement_unit = operation && operation.tags[ORDER_TAGS.total_quantity_measurement_unit] || measurement_unit;
            const conversion_factor = Number(operation && operation.tags[ORDER_TAGS.operation_unit_conversion_factor] || 1);

            const order_rows: OrderRows = {
                order_id: order.order_external_id,
                material: order.material,
                expected_start: start_date,
                expected_completion: end_date,
                earliest_end: new Date(order.earliest_end),
                comment: operation && this.getComment(operation) || "",
                nodes: []
            };

            if (this.props.compact) {
                order_rows.nodes.push(
                    <tr key={order.order_id} className={this.getOrderColorCSS(order, is_unscheduled)}>
                        <td>{order.material} - {material_el}{setup_code}</td>
                        <td>{order.order_external_id}</td>
                    </tr>
                );
            } else {
                // append indication of detected productions in current shift
                const tooltip_array = [ ];
                if (order.quantity_produced_fixed !== order.quantity_produced) {
                    tooltip_array.push(
                        `${translate("common.tooltip_microplan_qantity_fixed", "Quantity increased based on realization of subsequent operations")}: ` +
                        `${order.quantity_produced}=>${order.quantity_produced_fixed}`);
                }
                if (order.quantity_produced_next > 0) {
                    tooltip_array.push(`${translate("Manufacturing.MicroplanSummary.done", "Done")}:`);
                    tooltip_array.push(` - ${translate("common.previous_shifts", "Previous shifts").toLowerCase()}: ${niceNumber(order.quantity_produced_fixed, 0)}`);
                    tooltip_array.push(` - ${translate("common.current_shift", "Current shift").toLowerCase()}: ${niceNumber(order.quantity_produced_next, 0)}`);
                }
                const tooltip = (tooltip_array.length > 0) ? tooltip_array.join("\n") : null;
                const start_indicator = tooltip_array.length > 0 ? "* " : null;

                order_rows.nodes.push(
                    <tr key={order.order_id} className={this.getOrderColorCSS(order, is_unscheduled)}>
                        {this.isColumnVisible(WORK_ORDER_TABLE_COLUMNS.material) && <td className="material">
                            {order.material} - {material_el}{setup_code}
                            <img
                                style={{ marginLeft: "20px", width: "20px", height: "20px" }}
                                src="/img/eye_grey.svg"
                                alt="show_details"
                                onClick={() => this.setState({ show_material_info_modal: true, selected_material_external_id: order.material })}
                                title={translate("common.tooltip_show_details", "Show details")}
                            />
                        </td>}
                        {this.isColumnVisible(WORK_ORDER_TABLE_COLUMNS.material_comment) && <td className="material-comment">
                            {material_comment}
                        </td>}
                        {this.isColumnVisible(WORK_ORDER_TABLE_COLUMNS.order_id) && <td className="order">
                            {order.order_type == ORDER_TYPE.plan && <span className="badge badge-dark">P</span>} {order.order_external_id}
                        </td>}
                        {this.isColumnVisible(WORK_ORDER_TABLE_COLUMNS.operation) && <td className="operation">
                            {external_operation_id}{order.process_num} - {order.process_title}{barcode}
                        </td>}
                        {this.isColumnVisible(WORK_ORDER_TABLE_COLUMNS.operation_comment) && <td className="operation-comment">
                            {operation_comment}
                        </td>}
                        {this.isColumnVisible(WORK_ORDER_TABLE_COLUMNS.sap_start) && <td>
                            {niceDate(new Date(order.earliest_start))}
                        </td>}
                        {this.isColumnVisible(WORK_ORDER_TABLE_COLUMNS.sap_completion) && <td>
                            {niceDate(new Date(order.earliest_end))}
                        </td>}
                        {this.isColumnVisible(WORK_ORDER_TABLE_COLUMNS.expected_start) && <td>
                            {failed_scheduling || this.renderExpectedStart(order, start_date)}
                        </td>}
                        {this.isColumnVisible(WORK_ORDER_TABLE_COLUMNS.expected_completion) && <td>
                            {failed_scheduling || (end_date && (order.quantity_produced_fixed < order.quantity_total) && niceDateTime(end_date)) || "-"}
                        </td>}
                        {this.isColumnVisible(WORK_ORDER_TABLE_COLUMNS.quantity_in_operation_unit) && <td style={quant_col_style}>
                            {niceNumber(order.quantity_produced_fixed * conversion_factor, 0) + "/" + niceNumber(order.quantity_total * conversion_factor, 0)} {alt_measurement_unit}
                        </td>}
                        {this.isColumnVisible(WORK_ORDER_TABLE_COLUMNS.time_to_complete) && <td style={time_col_style} title={getOrderModelParamsTooltip(order)}>
                            {!is_unscheduled && (order.model_normative_fallback ?
                                niceNumber(order.estimated_duration[1].val, 1) :
                                niceNumber(order.estimated_duration[1].val, 1) + " \u00B1 " + niceNumber(std, 1)) || "-"}
                        </td>}
                        {this.isColumnVisible(WORK_ORDER_TABLE_COLUMNS.quantity) && <td style={quant_col_style} title={tooltip}>
                            {start_indicator}
                            {niceNumber(order.quantity_produced_fixed + order.quantity_produced_next, 0) + " / " + niceNumber(order.quantity_total, 0)} {measurement_unit}
                        </td>}
                        <td className="work-order-actions">
                            <button
                                className="far fa-eye btn btn-short btn-outline-primary"
                                onClick={() => { this.onShowOrHideOrderModal(true, order.order_id, order.order_external_id, order.process_num.toString()) }}
                                title={translate("common.tooltip_microplan_details", "Show order details")}
                            ></button>
                            {<Authorization.button
                                permission={PERMISSION_NAMES.ShiftTableEdit}
                                className="far fa-edit btn btn-short btn-outline-primary"
                                style={{ marginLeft: "5px", cursor: "pointer" }}
                                onClick={() => { this.props.showLinePlanningModal([order.order_id]); }}
                                title={translate("common.tooltip_microplan_edit", "Go to Microplan for line ") + ` ${this.props.line.title}`}
                            />}
                        </td>
                    </tr>
                );
                if (this.props.show_order_shifts && this.props.order_shifts.has(order.order_id) && !is_unscheduled && (
                    this.isColumnVisible(WORK_ORDER_TABLE_COLUMNS.quantity_in_operation_unit) ||
                    this.isColumnVisible(WORK_ORDER_TABLE_COLUMNS.time_to_complete) ||
                    this.isColumnVisible(WORK_ORDER_TABLE_COLUMNS.quantity)
                )) {
                    const shifts = this.props.order_shifts.get(order.order_id) || [];
                    const cal_span = this.countVisibleColumns();
                    order_rows.nodes.push(
                        <tr key={`${order.order_id}_prod`} className={this.getOrderColorCSS(order, is_unscheduled)}>
                            <td className="line-order-shifts px-0" colSpan={cal_span}>
                                {this.renderLineOrderShifts(order, shifts)}
                            </td>
                        </tr>
                    );
                }
            }

            orders_rows.push(order_rows);
        }

        orders_rows = this.sortOrdersRows(orders_rows);

        let nodes = [];
        orders_rows.forEach(order_row => {
            nodes = [...nodes, ...order_row.nodes];
        });

        return nodes;
    }

    renderLineOrdersTable() {
        // get measurement unit from the first order
        let measurement_unit = "-";
        for (const order of this.props.orders.values()) {
            measurement_unit = order.measurement_unit;
            break;
        }

        // compose table
        return (
            <div className="white_box charts">
                <h6>{translate("OrderTableProduction.open_workorders", "Open work-orders")}</h6>
                <div className="chart-wrapper order-table-wrapper">
                    <table className="table order-table order-table-production" data-line-uuid={this.props.line.uuid}>
                        <OrderTableProductionHeader
                            key={(new Date()).getTime()}
                            is_print_export={!!this.props.render_to_string}
                            line_uuid={this.props.line.uuid}
                            selected_columns={this.props.selected_columns}
                            show_material_comment={this.props.show_material_comment}
                            compact={this.props.compact}
                            show_order_id={this.props.show_order_id}
                            show_material={this.props.show_material}
                            show_operation={this.props.show_operation}
                            show_operation_comment={this.props.show_operation_comment}
                            earliest_dates={this.props.earliest_dates}
                            show_order_shifts={this.props.show_order_shifts}
                            show_expected_start={this.props.show_expected_start}
                            show_expected_completion={this.props.show_expected_completion}
                            show_time_to_complete={this.props.show_time_to_complete}
                            show_quantity={this.props.show_quantity}
                            show_alternative_unit={this.props.show_alternative_unit}
                            measurement_unit={measurement_unit}
                            sim_created_at={this.props.sim_created_at}
                            sort_column={this.state.sort_column}
                            sort_direction={this.state.sort_direction}
                            onToggleSort={this.handleColumnSortTogle}
                        />
                        <tbody>
                            {this.renderLineOrderRows()}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className="row">
                <div className="col-lg-12 col-md-12">
                    {<OrderTableProductionModal
                        show={this.state.show}
                        selected_order_uuid={this.state.selected_order_uuid}
                        selected_order_external_id={this.state.selected_order_external_id}
                        process_num={this.state.selected_order_process_num}
                        onShowOrHideOrderModal={this.onShowOrHideOrderModal}
                        onOrderSave={this.props.onOrderChange}
                        override_description_fields={[
                            "external_id",
                            "material_external_id",
                            "material_title",
                            "quantity_total"
                        ]}
                        show_columns={[
                            "process_num",
                            "title",
                            "quantity_produced",
                            "quantity_scrap",
                            "quantity_total",
                            "output_stock",
                            "status",
                            "skip_sim"
                        ]}
                        report_uuid={this.props.report_uuid}
                    />}
                    {
                        <MaterialInfoModal
                            show={this.state.show_material_info_modal}
                            material_external_id={this.state.selected_material_external_id}
                            onShowOrHideOrderModal={() => this.setState({ show_material_info_modal: false })}
                        />
                    }

                    {this.renderLineOrdersTable()}
                </div>
            </div>
        );
    }
}

export default OrderTableProduction;
