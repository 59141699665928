// @flow
import { translate } from "./IntlProviderWrapper";
import * as BusinessLogic from "../lib/BusinessLogic";
import { LINE_GROUP_TAGS, LINE_GROUP_TAGS_ACCESS } from "../lib/ManufacturingTags.generated";
import { PLANNING_MODE } from "../lib/ManufacturingTags.generated";
import * as Auth from "../lib/Auth";
import ReactRouterPropTypes from "react-router-prop-types";
import { getBackend } from "../lib/backend/Backend2";
import HomeIcon from "./react/Icon/Home";

import type { ITags } from "../lib/Models";

export type ViewsDropdownOption = {
    id?: string,
    type: string,
    title: string,
    title_code?: string,
    dashboard?: string
}

export type NAV_IDS = "home" | "lines" | "stocks" | "people" | "tools";

export const navs = (current_view?: NAV_IDS | null) => {
    const home_view_selected = getSelectedViewHomeView();
    const home_view = HOME_VIEW_TYPES[home_view_selected];
    let home_view_text = translate("Header.menu.home", "Home");
    if (current_view === "home") {
        home_view_text += " / " + translate(home_view.title_code, home_view.title);
    }

    const lines_view_selected = getSelectedViewLinesView();
    const lines_view = LINES_VIEWS_VIEW_TYPES[lines_view_selected];
    let lines_view_text = translate("Header.menu.plan", "Plan");
    if (current_view === "lines") {
        lines_view_text += " / " + translate(lines_view.title_code, lines_view.title);
    }

    const stocks_view_selected = getSelectedViewStocksView();
    const stocks_view = STOCKS_VIEWS_VIEW_TYPES[stocks_view_selected] || TAGGED_VIEWS[stocks_view_selected];
    let stocks_view_text = translate("Header.menu.stocks", "Stocks");

    if (current_view === "stocks") {
        stocks_view_text += " / " + translate(stocks_view.title_code, stocks_view.title);
    }

    const people_view_selected = getSelectedViewPeopleView();
    const people_view = PEOPLE_VIEW_TYPES[people_view_selected];
    let people_view_text = translate("Header.menu.people", "People")
    if (current_view === "people") {
        people_view_text += " / " + translate(people_view.title_code, people_view.title)
    }

    const tools_view_selected = getSelectedViewToolsView();
    const tools_view = TOOLS_VIEW_TYPES[tools_view_selected];
    let tools_view_text = translate("Header.menu.tools", "Tools");

    if (current_view === "tools") {
        tools_view_text += " / " + translate(tools_view.title_code, tools_view.title);
    }

    const is_admin = Auth.isInRole(Auth.ROLE_ADMIN);

    const resources = [
        {
            "text": "Plants",
            "path": "/digital-twin/resources/plants",
            "intl": "Header.menu.plants",
        },
        {
            "text": "Line Groups",
            "path": "/digital-twin/resources/line_groups",
            "intl": "Header.menu.linegroups",
        },
        {
            "text": "Lines",
            "path": "/digital-twin/resources/lines",
            "intl": "Header.menu.lines",
        },
        {
            'text': 'Batchop Groups',
            'path': '/digital-twin/resources/batchop_groups',
            'intl': 'Header.menu.batchop_groups',
        },
        {
            'text': 'Batchop Locations',
            'path': '/digital-twin/resources/batchop_locations',
            'intl': 'Header.menu.batchop_locations',
        },
        {
            'text': 'Stock Locations',
            'path': '/digital-twin/resources/stock_locations',
            'intl': 'Header.menu.stock_locations'
        },
        {
            "text": "Materials",
            "path": "/digital-twin/resources/materials",
            "intl": "Header.menu.materials",
        },
        {
            "text": "Processes",
            "path": "/digital-twin/resources/processes",
            "intl": "Header.menu.processes",
        },
        {
            "text": "People",
            "path": "/digital-twin/resources/people",
            "intl": "Header.menu.people",
        }];

    if (is_admin) {
        resources.push(
            {
                "text": "Organisation",
                "path": "/digital-twin/resources/organisation",
                "intl": "Header.menu.organisation",
            }
        );
    }


    return {
        "views": [
            {
                "text": home_view_text,
                "path": `/manufacturing/home/${home_view_selected}`,
                "intl": home_view_text,
                "nav_id": "home",
                "default": "overview",
                "icon": HomeIcon
            },
            {
                "text": lines_view_text,
                "path": `/manufacturing/lines/${lines_view_selected}`,
                "intl": lines_view_text,
                "nav_id": "lines"
            },
            {
                "text": stocks_view_text,
                "path": `/manufacturing/stocks/${stocks_view_selected}`,
                "intl": stocks_view_text,
                "nav_id": "stocks"
            },
            {
                "text": people_view_text,
                "path": `/manufacturing/people/${people_view_selected}`,
                "intl": people_view_text,
                "nav_id": "people"
            },
            {
                "text": tools_view_text,
                "path": `/manufacturing/tools/${tools_view_selected}`,
                "intl": tools_view_text,
                "nav_id": "tools"
            }
        ],
        "resources": [...resources]
    }
}

type IViewType = {
    id: string,
    title: string,
    title_code: string
}

type IViewTypes = {
    [key: string]: IViewType
};

export const HOME_VIEW_TYPES: IViewTypes = {
    overview: { id: "overview", title: "overview", title_code: "Manufacturing.HomeView.overview" }
};

export const LINES_VIEWS_VIEW_TYPES: IViewTypes = {
    gantt: { id: "gantt", title: "gantt", title_code: "Manufacturing.LinesView.gantt" },
    microplan: { id: "microplan", title: "microplan", title_code: "Manufacturing.LinesView.microplan" },
    layout: { id: "layout", title: "layout", title_code: "Manufacturing.LinesView.layout" },
    realization: { id: "realization", title: "realization", title_code: "Manufacturing.LinesView.realization" },
    orders_and_shifts: { id: "orders_and_shifts", title: "orders_and_shifts", title_code: "Manufacturing.LinesView.orders_and_shifts" },
    planning_table: { id: "planning_table", title: "planning_table", title_code: "Manufacturing.LinesView.planning_table" },
    batch_op: { id: "batch_op", title: "batch_op", title_code: "Manufacturing.LinesView.batch_op" },
    subcontracting: { id: "subcontracting", title: "subcontracting", title_code: "Manufacturing.LinesView.subcontracting" },
    manual_order_entry: { id: "manual_order_entry", title: "Manually entered orders", title_code: "Manufacturing.LinesView.manual_order_entry" },
    plan_change: { id: "plan_change", title: "plan change", title_code: "Manufacturing.LinesView.plan_change" },
    plan_insights: { id: "plan_insights", title: "plan insights", title_code: "Manufacturing.LinesView.plan_insights" },
    downtimes: { id: "downtimes", title: "downtimes", title_code: "Manufacturing.LinesView.downtimes" },
    scrap: { id: "scrap", title: "scrap", title_code: "Manufacturing.LinesView.scrap" },
    prediction_vs_real: { id: "prediction_vs_real", title: "Evaluation", title_code: "Header.menu.manufacturing_eval" },
    orders_shifts: { id: "orders_shifts", title: "Orders", title_code: "common.orders" }
};

export const STOCKS_VIEWS_VIEW_TYPES: IViewTypes = {
    stock_forecast: { id: "stock_forecast", title: "stock forecast", title_code: "Manufacturing.StocksView.stock_forecast" },
    long_term_stock_forecast: { id: "long_term_stock_forecast", title: "long-term stock forecast", title_code: "Manufacturing.StocksView.long_term_stock_forecast" },
    stock_status: { id: "stock_status", title: "stock status", title_code: "Manufacturing.StocksView.stock_status" },
    out_of_stock: { id: "out_of_stock", title: "out of stock", title_code: "Manufacturing.StocksView.out_of_stock" },
    stock_requirements_inbounds: { id: "stock_requirements_inbounds", title: "stock requirements_inbounds", title_code: "Manufacturing.StocksView.stock_requirements_inbounds" },
    stock_requirements_outbounds: { id: "stock_requirements_outbounds", title: "stock requirements_outbounds", title_code: "Manufacturing.StocksView.stock_requirements_outbounds" },
    stock_requirements_delay: { id: "stock_requirements_delay", title: "steck requirements delay", title_code: "Manufacturing.StocksView.stock_requirements_delay" },
    kanban: { id: "kanban", title: "kanban", title_code: "Manufacturing.StocksView.kanban" }
};

export const TOOLS_VIEW_TYPES = {
    gantt_tools: { id: "gantt_tools", title: "gantt", title_code: "Manufacturing.ToolsView.gantt" },
    tool_change: { id: "tool_change", title: "Tool changes", title_code: "Manufacturing.ToolsView.tool_change" },
    tool_change_new: { id: "tool_change_new", title: "Tool changes", title_code: "Manufacturing.ToolsView.tool_change" },
    tool_management: { id: "tool_management", title: "Tool management", title_code: "Manufacturing.ToolsView.tool_management" }
};

const findKeyFromURL = (valid_options: string[], default_value: string) => {
    const parts = window.location.pathname.split("/");
    const first_index = parts.indexOf("manufacturing")
    if (parts.length >= first_index + 2) {
        const option = parts[first_index + 2]
        if (!valid_options.includes(option)) return default_value;
        return option;
    }
    return default_value;
}

export const getSelectedViewHomeView = (): string => {
    const valid_options = Object.keys(HOME_VIEW_TYPES);
    return findKeyFromURL(valid_options, HOME_VIEW_TYPES.overview.id);
}

export const getSelectedViewLinesView = (): string => {
    const valid_options = Object.keys(LINES_VIEWS_VIEW_TYPES);
    return findKeyFromURL(valid_options, LINES_VIEWS_VIEW_TYPES.gantt.id);
}

export const getSelectedViewStocksView = (): string => {
    const valid_options = [...Object.keys(TAGGED_VIEWS), ...Object.keys(STOCKS_VIEWS_VIEW_TYPES)];
    return findKeyFromURL(valid_options, STOCKS_VIEWS_VIEW_TYPES.stock_forecast.id);
}

export const getSelectedViewPeopleView = (): string => {
    const valid_options = Object.keys(PEOPLE_VIEW_TYPES);
    return findKeyFromURL(valid_options, PEOPLE_VIEW_TYPES.shifts.id);
}

export const getSelectedViewToolsView = (): string => {
    const valid_options = Object.keys(TOOLS_VIEW_TYPES);
    return findKeyFromURL(valid_options, TOOLS_VIEW_TYPES.tool_change.id);
}

type LinesDashboards = {
    gantt_dashboard: string,
    layout_dashboard: string,
    microplan_dashboard: string,
    line_dashboards: any
}

const getLinesDashboards = async (line_group_uuid: string, plant_uuid: string): Promise<LinesDashboards> => {
    // get dashboards from the backend
    const { dashboards } = await getBackend().dashboards.searchDashboards({
        active: true,
        tags: {
            type: "man"
        }
    });

    let gantt_dashboard = "";
    let layout_dashboard = "";
    let microplan_dashboard = "";
    let line_group = BusinessLogic.getLineGroupForUser(line_group_uuid);

    const line_uuids = line_group ? line_group.line_uuids : [];
    let line_dashboards = [];

    // go over dashboards and identify relevant ones
    for (const dashboard of dashboards) {
        // get line group gantt and microplan
        if (dashboard.tags.line_group === line_group_uuid) {
            if (dashboard.tags.subtype === "plant-gantt") {
                gantt_dashboard = dashboard.uuid;
            } else if (dashboard.tags.subtype === "plant-microplan-main") {
                microplan_dashboard = dashboard.uuid;
            } else if (dashboard.tags.subtype === "plant-layout") {
                layout_dashboard = dashboard.uuid;
            }
        }
        // as a backup show plant level gantt or microplan
        if (dashboard.tags.plant === plant_uuid) {
            if (gantt_dashboard === null && dashboard.tags.subtype === "plant-gantt") {
                gantt_dashboard = dashboard.uuid;
            } else if (dashboard.tags.subtype === "plant-microplan-main") {
                microplan_dashboard = dashboard.uuid;
            } else if (layout_dashboard === null && dashboard.tags.subtype === "plant-layout") {
                layout_dashboard = dashboard.uuid;
            }
        }

        // get lines dashboards
        const line_uuid = dashboard.tags.line;
        if (dashboard.tags.subtype === "line" && line_uuids.includes(line_uuid)) {
            const line_weight = BusinessLogic.getLineWeight(line_uuid);
            line_dashboards.push({ uuid: line_uuid, dashboard: dashboard.uuid, title: dashboard.title, line_weight });
        }
    }

    return { gantt_dashboard, layout_dashboard, microplan_dashboard, line_dashboards }
}

export const getLinesViews = async (line_group_uuid: string, plant_uuid: string): Promise<ViewsDropdownOption[]> => {
    let line_group = BusinessLogic.getLineGroupForUser(line_group_uuid);
    if (!line_group) return [];

    const VIEW_TYPES = LINES_VIEWS_VIEW_TYPES;
    const is_people_first_planning = (line_group.tags[LINE_GROUP_TAGS.planning_mode] === PLANNING_MODE.people_first);
    const is_admin = Auth.isInRole(Auth.ROLE_ADMIN);
    const is_power_user = Auth.isInRole(Auth.ROLE_POWER_USER);
    const is_demo_user = Auth.isInRole(Auth.ROLE_DEMO_USER);
    const is_planner = Auth.isInRole(Auth.ROLE_MAN_PLANNER);

    const {
        gantt_dashboard,
        layout_dashboard,
        microplan_dashboard,
        line_dashboards
    } = await getLinesDashboards(line_group_uuid, plant_uuid);

    const views = [];
    if (line_group.tags && line_group.tags[LINE_GROUP_TAGS.gannt] === "true") {
        views.push({
            id: VIEW_TYPES.gantt.id,
            type: VIEW_TYPES.gantt.id,
            title: VIEW_TYPES.gantt.title,
            title_code: VIEW_TYPES.gantt.title_code,
            dashboard: gantt_dashboard
        });
    }

    if (microplan_dashboard || line_dashboards.length > 0) {
        // prepare view, with the first dashboard as leading
        views.push({
            id: VIEW_TYPES.microplan.id,
            type: VIEW_TYPES.microplan.id,
            title: VIEW_TYPES.microplan.title,
            title_code: VIEW_TYPES.microplan.title_code,
            dashboard: microplan_dashboard
        });
    }

    // we show planing only when we have microplan enabled
    views.push({
        id: VIEW_TYPES.orders_and_shifts.id,
        type: VIEW_TYPES.orders_and_shifts.id,
        title: VIEW_TYPES.orders_and_shifts.title,
        title_code: VIEW_TYPES.orders_and_shifts.title_code
    });
    if ((is_admin || is_power_user || is_demo_user || is_planner) && (line_group.tags[LINE_GROUP_TAGS.planning_board] === "true")) {
        views.push({
            id: VIEW_TYPES.planning_table.id,
            type: VIEW_TYPES.planning_table.id,
            title: VIEW_TYPES.planning_table.title,
            title_code: VIEW_TYPES.planning_table.title_code,
        });
    }
    if (!is_people_first_planning) {
        views.push({
            id: VIEW_TYPES.plan_change.id,
            type: VIEW_TYPES.plan_change.id,
            title: VIEW_TYPES.plan_change.title,
            title_code: VIEW_TYPES.plan_change.title_code
        });
        views.push({
            id: VIEW_TYPES.plan_insights.id,
            type: VIEW_TYPES.plan_insights.id,
            title: VIEW_TYPES.plan_insights.title,
            title_code: VIEW_TYPES.plan_insights.title_code
        });
    }

    // batch operations view
    if (line_group.tags && line_group.tags[LINE_GROUP_TAGS.batch_op] === "true") {
        views.push({
            id: VIEW_TYPES.batch_op.id,
            type: VIEW_TYPES.batch_op.id,
            title: VIEW_TYPES.batch_op.title,
            title_code: VIEW_TYPES.batch_op.title_code
        });
    }
    if (line_group.tags && line_group.tags[LINE_GROUP_TAGS.subcontracting] === "true") {
        views.push({
            id: VIEW_TYPES.subcontracting.id,
            type: VIEW_TYPES.subcontracting.id,
            title: VIEW_TYPES.subcontracting.title,
            title_code: VIEW_TYPES.subcontracting.title_code
        });
    }
    // manual order entry
    if (LINE_GROUP_TAGS_ACCESS.manual_order_entry(line_group.tags)) {
        views.push({
            id: VIEW_TYPES.manual_order_entry.id,
            type: VIEW_TYPES.manual_order_entry.id,
            title: VIEW_TYPES.manual_order_entry.title,
            title_code: VIEW_TYPES.manual_order_entry.title_code
        });
    }
    // layout dasboards
    if (layout_dashboard) {
        views.push({
            id: VIEW_TYPES.layout.id,
            type: VIEW_TYPES.layout.id,
            title: VIEW_TYPES.layout.title,
            title_code: VIEW_TYPES.layout.title_code,
            dashboard: layout_dashboard
        });
    }
    // realization view
    if (line_group.tags && line_group.tags[LINE_GROUP_TAGS.weekly_realization] === "true") {
        views.push({
            id: VIEW_TYPES.realization.id,
            type: VIEW_TYPES.realization.id,
            title: VIEW_TYPES.realization.title,
            title_code: VIEW_TYPES.realization.title_code
        });
        views.push({
            id: VIEW_TYPES.downtimes.id,
            type: VIEW_TYPES.downtimes.id,
            title: VIEW_TYPES.downtimes.title,
            title_code: VIEW_TYPES.downtimes.title_code
        });
        views.push({
            id: VIEW_TYPES.scrap.id,
            type: VIEW_TYPES.scrap.id,
            title: VIEW_TYPES.scrap.title,
            title_code: VIEW_TYPES.scrap.title_code
        });
    }

    // comparison of prediction vs realization for admins
    if ((is_admin || is_power_user || is_demo_user) && !is_people_first_planning) {
        views.push({
            id: VIEW_TYPES.prediction_vs_real.id,
            type: VIEW_TYPES.prediction_vs_real.id,
            title: VIEW_TYPES.prediction_vs_real.title,
            title_code: VIEW_TYPES.prediction_vs_real.title_code
        });
    }

    if (is_people_first_planning) {
        views.push({
            id: VIEW_TYPES.orders_shifts.id,
            type: VIEW_TYPES.orders_shifts.id,
            title: VIEW_TYPES.orders_shifts.title,
            title_code: VIEW_TYPES.orders_shifts.title_code,
        });
    }

    return views;
}

type TAGGED_VIEWS_TYPE = {
    stock_forecast_produced_scenario: ViewsDropdownOption,
    stock_forecast_produced_required_scenario: ViewsDropdownOption,
    stock_forecast_consumed_scenario: ViewsDropdownOption,
    stock_forecast_consumed_received_scenario: ViewsDropdownOption
}

export const TAGGED_VIEWS: TAGGED_VIEWS_TYPE = {
    stock_forecast_produced_scenario: {
        id: "stock_forecast_produced_scenario",
        type: "stock_forecast_produced_scenario",
        title: "- output materials",
        title_code: "common.stock_forecast_produced_scenario"
    },
    stock_forecast_produced_required_scenario: {
        id: "stock_forecast_produced_required_scenario",
        type: "stock_forecast_produced_required_scenario",
        title: "- output materials requirements",
        title_code: "common.stock_forecast_produced_required_scenario"
    },
    stock_forecast_consumed_scenario: {
        id: "stock_forecast_consumed_scenario",
        type: "stock_forecast_consumed_scenario",
        title: "- input materials",
        title_code: "common.stock_forecast_consumed_scenario"
    },
    stock_forecast_consumed_received_scenario: {
        id: "stock_forecast_consumed_received_scenario",
        type: "stock_forecast_consumed_received_scenario",
        title: "- input materials delivery",
        title_code: "common.stock_forecast_consumed_received_scenario"
    }
}


const getTaggedViews = (plant_tags: ITags): ViewsDropdownOption[] => {
    const views = [];

    for (const key of Object.keys(TAGGED_VIEWS)) {
        const show_tagged_view = plant_tags[key] === "true";
        if (show_tagged_view) {
            views.push(TAGGED_VIEWS[key]);
        }
    }

    return views;
}

const getPlantTags = (plant_uuid: string): ITags => {
    const plant = BusinessLogic.getPlantsForUser().find(p => p.uuid === plant_uuid);
    if (plant) {
        return plant.tags || {};
    }
    return {};
}


export const getStocksViews = (line_group_uuid: string, plant_uuid: string): ViewsDropdownOption[] => {
    let line_group = BusinessLogic.getLineGroupForUser(line_group_uuid);
    if (!line_group) return [];

    const is_people_first_planning = (line_group.tags[LINE_GROUP_TAGS.planning_mode] === PLANNING_MODE.people_first);
    const VIEW_TYPES = STOCKS_VIEWS_VIEW_TYPES;
    const plant_tags = getPlantTags(plant_uuid);

    let views = [];

    if (!is_people_first_planning) {
        views.push({
            id: VIEW_TYPES.stock_status.id,
            type: VIEW_TYPES.stock_status.id,
            title: VIEW_TYPES.stock_status.title,
            title_code: VIEW_TYPES.stock_status.title_code
        });
    }
    if (
        line_group.tags &&
        line_group.tags[LINE_GROUP_TAGS.stock_forecast] === "true" &&
        !is_people_first_planning
    ) {
        views.push({
            id: VIEW_TYPES.stock_forecast.id,
            type: VIEW_TYPES.stock_forecast.id,
            title: VIEW_TYPES.stock_forecast.title,
            title_code: VIEW_TYPES.stock_forecast.title_code
        });

        views = [...views, ...getTaggedViews(plant_tags)];

        views.push({
            id: VIEW_TYPES.out_of_stock.id,
            type: VIEW_TYPES.out_of_stock.id,
            title: VIEW_TYPES.out_of_stock.title,
            title_code: VIEW_TYPES.out_of_stock.title_code
        });
    }
    if (
        line_group.tags &&
        line_group.tags[LINE_GROUP_TAGS.long_term_stock_forecast] === "true"
    ) {
        views.push({
            id: VIEW_TYPES.long_term_stock_forecast.id,
            type: VIEW_TYPES.long_term_stock_forecast.id,
            title: VIEW_TYPES.long_term_stock_forecast.title,
            title_code: VIEW_TYPES.long_term_stock_forecast.title_code
        });
    }
    views.push({
        id: VIEW_TYPES.stock_requirements_inbounds.id,
        type: VIEW_TYPES.stock_requirements_inbounds.id,
        title: VIEW_TYPES.stock_requirements_inbounds.title,
        title_code: VIEW_TYPES.stock_requirements_inbounds.title_code
    });
    views.push({
        id: VIEW_TYPES.stock_requirements_outbounds.id,
        type: VIEW_TYPES.stock_requirements_outbounds.id,
        title: VIEW_TYPES.stock_requirements_outbounds.title,
        title_code: VIEW_TYPES.stock_requirements_outbounds.title_code
    });
    if (
        line_group.tags &&
        line_group.tags[LINE_GROUP_TAGS.stock_forecast] === "true" &&
        !is_people_first_planning
    ) {
        views.push({
            id: VIEW_TYPES.stock_requirements_delay.id,
            type: VIEW_TYPES.stock_requirements_delay.id,
            title: VIEW_TYPES.stock_requirements_delay.title,
            title_code: VIEW_TYPES.stock_requirements_delay.title_code
        });
    }

    views.push({
        id: VIEW_TYPES.kanban.id,
        type: VIEW_TYPES.kanban.id,
        title: VIEW_TYPES.kanban.title,
        title_code: VIEW_TYPES.kanban.title_code,
        dashboard: VIEW_TYPES.kanban.id
    });

    return views;
}

export const PEOPLE_VIEW_TYPES = {
    shifts: { id: "shifts", title: "Shift-board", title_code: "Manufacturing.PeopleView.shifts" },
    people_insights: { id: "people_insights", title: "People insights", title_code: "Manufacturing.PeopleView.people_insights" },
    worker_assignments: { id: "worker_assignments", title: "Worker assignments", title_code: "common.worker_assignments" },
};

export const getPeopleViews = (line_group_uuid: string): ViewsDropdownOption[] => {
    let line_group = BusinessLogic.getLineGroupForUser(line_group_uuid);
    if (!line_group) return [];

    const is_people_first_planning = (line_group.tags[LINE_GROUP_TAGS.planning_mode] === PLANNING_MODE.people_first);
    const VIEW_TYPES = PEOPLE_VIEW_TYPES;

    const views = [];
    if (line_group.tags && line_group.tags[LINE_GROUP_TAGS.shift_board] === "true") {
        views.push({
            id: VIEW_TYPES.shifts.id,
            type: VIEW_TYPES.shifts.id,
            title: VIEW_TYPES.shifts.title,
            title_code: VIEW_TYPES.shifts.title_code
        });
        if (!is_people_first_planning) {
            views.push({
                id: VIEW_TYPES.people_insights.id,
                type: VIEW_TYPES.people_insights.id,
                title: VIEW_TYPES.people_insights.title,
                title_code: VIEW_TYPES.people_insights.title_code
            });
        }
        if (is_people_first_planning) {
            views.push({
                id: VIEW_TYPES.worker_assignments.id,
                type: VIEW_TYPES.worker_assignments.id,
                title: VIEW_TYPES.worker_assignments.title,
                title_code: VIEW_TYPES.worker_assignments.title_code
            });
        }
    }

    return views;
}

type ToolsDashboards = {
    gantt_dashboard: string
}

const getToolsDashboards = async (line_group_uuid: string, plant_uuid: string): Promise<ToolsDashboards> => {
    // get line-group tool-change gantt
    const res1 = await getBackend().dashboards.searchDashboards({
        active: true,
        tags: {
            line_group: line_group_uuid,
            type: "man",
            subtype: "plant-gantt-tool-setup"
        }
    });
    // as a backup show plant-level tool-change gantt
    const res2 = await getBackend().dashboards.searchDashboards({
        active: true,
        tags: {
            plant: plant_uuid,
            type: "man",
            subtype: "plant-gantt-tool-setup"
        }
    });

    let gantt_dashboard = "";
    // go over dashboards and identify relevant ones
    const dashboards_toolsetup = [...res1.dashboards, ...res2.dashboards];
    if (dashboards_toolsetup.length > 0) {
        gantt_dashboard = dashboards_toolsetup[0].uuid;
    }
    return { gantt_dashboard };
}

export const getToolsView = async (line_group_uuid: string, plant_uuid: string): Promise<ViewsDropdownOption[]> => {
    let line_group = BusinessLogic.getLineGroupForUser(line_group_uuid);
    if (line_group === null) { return []; }

    const is_people_first_planning = (line_group.tags[LINE_GROUP_TAGS.planning_mode] === PLANNING_MODE.people_first);
    const VIEW_TYPES = TOOLS_VIEW_TYPES;

    const gantt_dashboard = await getToolsDashboards(line_group_uuid, plant_uuid);
    // create views
    const views: ViewsDropdownOption[] = [];

    if (!is_people_first_planning) {
        const is_ticketing_user = Auth.isInRole(Auth.ROLE_TICKET_USER);

        if (is_ticketing_user == false) {
            // old view for tool change
            views.push({
                id: VIEW_TYPES.tool_change.id,
                type: VIEW_TYPES.tool_change.id,
                title: VIEW_TYPES.tool_change.title,
                title_code: VIEW_TYPES.tool_change.title_code
            });
        } else {
            // new view for tool change
            views.push({
                id: VIEW_TYPES.tool_change_new.id,
                type: VIEW_TYPES.tool_change_new.id,
                title: VIEW_TYPES.tool_change_new.title,
                title_code: VIEW_TYPES.tool_change_new.title_code
            });
        }

        if (gantt_dashboard) {
            views.push({
                id: VIEW_TYPES.gantt_tools.id,
                type: VIEW_TYPES.gantt_tools.id,
                title: VIEW_TYPES.gantt_tools.title,
                title_code: VIEW_TYPES.gantt_tools.title_code,
                dashboard_uuid: gantt_dashboard
            });
        }

        views.push({
            id: VIEW_TYPES.tool_management.id,
            type: VIEW_TYPES.tool_management.id,
            title: VIEW_TYPES.tool_management.title,
            title_code: VIEW_TYPES.tool_management.title_code
        });
    }

    return views;
}

export const getHomeViews = async (line_group_uuid: string, plant_uuid: string): Promise<ViewsDropdownOption[]> => {
    const VIEW_TYPES = HOME_VIEW_TYPES;
    const views: ViewsDropdownOption[] = [
        {
            id: VIEW_TYPES.overview.id,
            type: VIEW_TYPES.overview.id,
            title: VIEW_TYPES.overview.title,
            title_code: VIEW_TYPES.overview.title_code
        }
    ];

    return views;
}

export const redirectSelectedView = (selected_view: string, history: ReactRouterPropTypes.history) => {
    let main_view = "home";

    if (HOME_VIEW_TYPES[selected_view]) {
        main_view = "home";
    }

    if (LINES_VIEWS_VIEW_TYPES[selected_view]) {
        main_view = "lines";
    }

    if (STOCKS_VIEWS_VIEW_TYPES[selected_view] || TAGGED_VIEWS[selected_view]) {
        main_view = "stocks";
    }

    if (PEOPLE_VIEW_TYPES[selected_view]) {
        main_view = "people";
    }

    if (TOOLS_VIEW_TYPES[selected_view]) {
        main_view = "tools";
    }

    history.push(`/manufacturing/${main_view}/${selected_view}`);
}
