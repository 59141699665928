// @flow
// main imports
import * as React from "react";
import { connect } from "react-redux";
import * as t from "../../lib/backend/manufacturing2.generated.types";
import { niceDateTime, niceNumber } from "../../lib/Util";
import Toggle from "react-toggle";
import StockStatusModal from "./StockStatusModal";
import { translate } from "../IntlProviderWrapper";
import ReactTable from "../react/ReactTable";
import * as Auth from "../../lib/Auth";
import { withRouter } from "react-router"
import * as Redirect from "../URLHandler/Redirect";
import Loader from "../Loader";

import TranslatedMaterialTitle from "../TranslatedMaterialTitle";
import { getLineGroupTags } from "../../lib/BusinessLogic";
import { LINE_GROUP_TAGS_ACCESS, STOCK_STATUS_QUANTITY_TYPE } from "../../lib/ManufacturingTags.generated";

type Props = {
    materials: t.IStockStatus[],
    showEditModal: Function,
    type: string,
    isLoading: boolean,
    plant_uuid: string,
    line_group_uuid: string,
    history: Object,
    stock_status_table_filters: Object,
    toggleZeroQuantity: () => void,
    include_zero_quantity: boolean
}

type State = {
    show_details_checked?: boolean,
    hide_columns: boolean,
    show: boolean,
    material_uuid: string,
    location_uuid: string,
    data: Array<Object> | null,
    columns: any
}

const renderNumber = (x: number) => {
    return (typeof x === "number") ? niceNumber(x, 0) : "-";
}

const mapMaterialsDataColumns = (hide_columns: boolean, line_group_uuid: string) => {
    const line_group_tags = getLineGroupTags(line_group_uuid);
    const basic_view_quantity_column = LINE_GROUP_TAGS_ACCESS.stock_status_basic_view_quantity_column(line_group_tags);

    const available_quantity_column = {
        "Header": translate("Manufacturing.StockStatusView.quantity_total", "Total quantity"),
        "accessor": "quantity_total",
        disableFilters: true,
    };
    const erp_quantity_column = {
        "Header": translate("common.quantity", "Quantity"),
        "accessor": "quantity_display",
        disableFilters: true
    };
    const additional_columns = [];

    if (basic_view_quantity_column === STOCK_STATUS_QUANTITY_TYPE.available_quantity) {
        additional_columns.push(available_quantity_column);
    }

    if (!hide_columns) {
        if (basic_view_quantity_column !== STOCK_STATUS_QUANTITY_TYPE.available_quantity) {
            additional_columns.push(available_quantity_column);
        }

        additional_columns.push({
            "Header": translate("Manufacturing.StockStatusView.quantity_unconfirmed", "Changed quanitity"),
            "accessor": "quantity_unconfirmed",
            disableFilters: true
        });
        additional_columns.push({
            "Header": translate("Manufacturing.StockStatusView.quantity_confirmed", "Last input quantity"),
            "accessor": "quantity",
            disableFilters: true
        });

        if (basic_view_quantity_column !== STOCK_STATUS_QUANTITY_TYPE.erp_quantity) {
            additional_columns.push(erp_quantity_column);
        }
    }

    if (basic_view_quantity_column === STOCK_STATUS_QUANTITY_TYPE.erp_quantity) {
        additional_columns.push(erp_quantity_column);
    }

    return [
        {
            "Header": "",
            "accessor": "material_title",
            "filterInputPlaceholder": translate("common.material", "Material")
        },
        {
            "Header": "",
            "accessor": "material_external_id",
            "filterInputPlaceholder": translate("common.external_id", "External ID")
        },
        {
            "Header": "",
            "accessor": "stock_location",
            "filterInputPlaceholder": translate("common.stock_location", "Stock location")
        },
        ...additional_columns,
        {
            "Header": translate("common.quantity", "Quantity"),
            "accessor": "quantity_total_original",
            disableFilters: true
        },
        {
            "Header": translate("common.quantity", "Quantity"),
            "accessor": "quantity_display_original",
            disableFilters: true
        },
        {
            "Header": translate("Manufacturing.StockStatusView.last_update", "Last update"),
            "accessor": "ts_update",
            disableFilters: true
        },
        {
            "Header": "",
            "accessor": "material_uuid",
            disableFilters: true
        },
        {
            "Header": "",
            "accessor": "location_uuid",
            disableFilters: true
        }
    ]
}

const mapMaterialsData = ({ materials, type, showEditModal, showDetailsModal }) => {
    const ret_materials = materials.filter(item => item.material_type === type).map((item, idx) => {
        const is_kiosk = Auth.getUserTag("kiosk") === "true";

        const lastUpdate = <React.Fragment>
            {item.ts_update ? niceDateTime(new Date(item.ts_update)) : "-"}

            {type === "out" && item.derived && <img style={{marginLeft: "20px"}} src="/img/edit.svg" alt="edit" onClick={() => showEditModal(item.material_uuid)} />}

            {
                !is_kiosk && item.material_uuid && item.location_uuid &&
                <img
                    style={{ marginLeft: "20px", width: "20px", height: "20px" }}
                    src="/img/eye_grey.svg"
                    alt="edit"
                    onClick={showDetailsModal(item.material_uuid, item.location_uuid)}
                    title={translate("common.tooltip_show_details", "Show details")}
                />
            }
        </React.Fragment>;

        const quantity = item.quantity;
        const quantity_unconfirmed = item.quantity_confirmed + item.quantity_unconfirmed;
        const quantity_total = quantity + quantity_unconfirmed;
        // for derived matierals display computed quantity (SAP + SINAPRO changes)
        // for standard materials display the raw quantity (SAP) without changes (SINAPRO)
        const quantity_display = item.derived ? quantity_total : quantity;

        const material_el = TranslatedMaterialTitle(
            {
                material_external_id: item.material_external_id,
                material_title: item.material_title
            }
        );

        return {
            material_title: material_el,
            material_external_id: item.material_external_id,
            stock_location: item.location_title,
            location_title: item.location_title,
            quantity_total_original: quantity_total,
            quantity_total: renderNumber(quantity_total),
            ts_update: lastUpdate,
            quantity_unconfirmed: renderNumber(quantity_unconfirmed),
            quantity: renderNumber(quantity),
            quantity_display_original: quantity_display,
            quantity_display: renderNumber(quantity_display),
            material_uuid: item.material_uuid,
            location_uuid: item.location_uuid
        }
    });

    return ret_materials;
}

type LastRowProps = {
    last_row_index: number,
    current_row_index: number,
    rows: any
}

type LastRowState = { };

class LastRow extends React.Component<LastRowProps, LastRowState>  {
    renderLastRow() {
        let quantity_sum = 0;
        let quantity_total_sum = 0;

        let row = {};
        for (row of this.props.rows) {
            quantity_sum += row.cells.find(c => c.column.id === "quantity_display_original").value;
            quantity_total_sum += row.cells.find(c => c.column.id === "quantity_total_original").value;
        }

        return <tr>
            {
                row.cells.map(c => {
                    if (c.column.id === "quantity_total_original") return null;
                    if (c.column.id === "quantity_display") return <td style={{ textAlign: "right" }}>{renderNumber(quantity_sum)}</td>
                    if (c.column.id === "quantity_total") return <td style={{ textAlign: "right" }}>{renderNumber(quantity_total_sum)}</td>
                    return <td></td>
                })
            }
        </tr>;
    }

    render() {
        return this.renderLastRow();
    }
}

class StockStatusViewTable extends React.Component<Props, State> {

    constructor(props) {
        super(props);
        this.state = {
            hide_columns: true,
            show_details_checked: false,
            stock_status_table_filters: {},
            show: false,
            material_uuid: "",
            location_uuid: "",
            columns: mapMaterialsDataColumns(true, this.props.line_group_uuid),
            data: null
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.materials !== this.props.materials) {
            this.setState({
                data: null
            }); // this will cause removal of react table and avoid filter confusion when switching between lines

            const data = mapMaterialsData({
                materials: this.props.materials,
                type: this.props.type,
                hide_columns: this.state.hide_columns,
                showEditModal: this.props.showEditModal,
                showDetailsModal: this.showDetailsModal
            });

            this.setState({ data });
        }
    }

    hideDetailsModal = () => {
        this.setState({
            show: false
        });
    }

    showDetailsModal = (material_uuid, location_uuid) => () => {
        this.setState({
            material_uuid,
            location_uuid,
            show: true
        });
    };

    toggleShowDetails = () => {
        this.setState({
            hide_columns: !this.state.hide_columns,
            show_details_checked: !this.state.show_details_checked,
            columns: mapMaterialsDataColumns(!this.state.hide_columns, this.props.line_group_uuid)
        });
    }

    getReactTable = () => {
        if (this.props.isLoading || this.state.data === null) {
            return <Loader />
        }

        return <ReactTable
            data={this.state.data}
            columns={this.state.columns}
            hiddenColumns={["material_uuid", "location_uuid", "quantity_total_original", "quantity_display_original"]}
            LastRow={LastRow}
            filters={{
                material_title: {
                    filterValue: this.props.stock_status_table_filters.material_title,
                    setCustomFilter: (value) => {
                        const ssft = this.props.stock_status_table_filters;
                        Redirect.stockStatusTableFilters(
                            this.props.history,
                            this.props.history.location.pathname,
                            {
                                material_title: value,
                                stock_location: ssft.stock_location,
                                material_external_id: ssft.material_external_id
                            }
                        );
                    },
                },
                stock_location: {
                    filterValue: this.props.stock_status_table_filters.stock_location,
                    setCustomFilter: (value) => {
                        const ssft = this.props.stock_status_table_filters;
                        Redirect.stockStatusTableFilters(
                            this.props.history,
                            this.props.history.location.pathname,
                            {
                                stock_location: value,
                                material_external_id: ssft.material_external_id,
                                material_title: ssft.material_title
                            }
                        );
                    }
                },
                material_external_id: {
                    filterValue: this.props.stock_status_table_filters.material_external_id,
                    setCustomFilter: (value) => {
                        const ssft = this.props.stock_status_table_filters;
                        Redirect.stockStatusTableFilters(
                            this.props.history,
                            this.props.history.location.pathname,
                            {
                                material_external_id: value,
                                material_title: ssft.material_title,
                                stock_location: ssft.stock_location
                            }
                        );
                    }
                }
            }}
        />
    }

    getZeroStockToggleLabel = (): string => {
        if (!this.props.include_zero_quantity) {
            return translate(
                "Manufacturing.MaterialStockForecast.show_zero_stocks",
                "Show materials with empty stock"
            );
        }

        return translate(
            "Manufacturing.MaterialStockForecast.show_zero_stocks",
            "Hide materials with empty stock"
        );
    }

    render() {
        return (
            <div className="white_box charts stock-status-table">
                    <StockStatusModal
                        show={this.state.show}
                        material_uuid={this.state.material_uuid}
                        location_uuid={this.state.location_uuid}
                        closeModal={this.hideDetailsModal}
                    />
                    <div className="stock-table-header" style={{display: "flex", justifyContent: "flex-end"}}>
                        <h5 style={{color: "white"}}>.</h5>
                        <div className="stock-table-header__toggle">
                            <div>
                            <Toggle checked={this.props.include_zero_quantity} onChange={() => this.props.toggleZeroQuantity()}></Toggle>
                            </div>
                            <div className="stock-table-header__text">
                                <span>{this.getZeroStockToggleLabel()}</span>
                            </div>
                        </div>
                        <div className="stock-table-header__toggle" style={{paddingLeft: "5px"}}>
                            <div>
                                <Toggle checked={this.state.show_details_checked} onChange={this.toggleShowDetails}></Toggle>
                            </div>
                            <div className="stock-table-header__text">
                                <span>{translate("common.details", "Details")}</span>
                            </div>
                        </div>
                    </div>
                    {this.getReactTable()}
            </div>
        );
    }
}

export default connect(
    state => {
        return {
            stock_status_table_filters: state.stock_status_table_filters
        }
    },
    dispatch => { return { reduxDispatch: dispatch } }
)(withRouter(StockStatusViewTable));
