// @flow
import * as React from "react";
import * as Auth from "../lib/Auth";
import * as Util from "../lib/Util";
import { Button, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import ErrorComponent from "./ErrorComponent";

type Props = {
    onClose: any
};

type State = {
    username: string,
    cookie: string,
    new_password1: string,
    new_password2: string,
    message: string,
    update_ok: boolean
};

class ChangePasswordWithCookie extends React.Component<Props, State> {
    constructor() {
        super();

        this.state = {
            username: "",
            cookie: "",
            new_password1: "",
            new_password2: "",
            message: "",
            update_ok: false
        };
    }

    componentDidMount() {
        const params = Util.parseUrlQueryVariables();
        this.setState({ username: params.username, cookie: params.cookie });
    }

    handleChange = (event: Event) => {
        let target = event.target;
        if (target instanceof HTMLInputElement) {
            const field = target.name;
            const value = target.value;
            this.setState(prev_state => {
                prev_state[field] = value;
                return prev_state;
            });
        }
    }

    handleSubmit = async (event: Event) => {
        event.preventDefault();
        try {
            // send collected parameters through validation
            if (this.state.new_password1 !== this.state.new_password2) {
                this.setState({ message: "Passwords do not match" });
                return
            }

            await Auth.changePasswordWithCookie(this.state.username, this.state.new_password1, this.state.cookie);
            this.setState({ message: "Password changed successfully", update_ok: true });
        } catch (err) {
            this.setState({ message: err.message });
        }
    }

    handleCancel = () => {
        this.props.onClose();
    }

    renderAlert() {
        return (
            <div className="row">
                <div className="col-12">
                    <ErrorComponent msg={this.state.message} type="warning" />
                </div>
            </div>
        );
    }

    render() {
        if (this.state.update_ok === true) {
            return <Redirect to='/login' />
        } else {
            return (
                <section id="register" key="1">
                    <div className="caption_div">
                        <form className="form_box" onSubmit={this.handleSubmit}>
                            <h3>
                                <FormattedMessage id="Settings.change_password" defaultMessage="Change password" />
                            </h3>
                            {this.state.message !== "" && this.renderAlert()}
                            <FormGroup controlId="username" bsSize="large" className="form-label-group">
                                <FormControl autoFocus type="text" placeholder="Your email address"
                                    value={this.state.username} onChange={this.handleChange} />
                                <ControlLabel>
                                    <FormattedMessage id="common.email" defaultMessage="Email address" />
                                </ControlLabel>
                            </FormGroup>
                            <FormGroup controlId="new_password1" bsSize="large" className="form-label-group">
                                <FormControl type="password" placeholder="Your password" name="new_password1"
                                    value={this.state.new_password1} onChange={this.handleChange} />
                                <ControlLabel>
                                    <FormattedMessage id="common.new_password" defaultMessage="Password" />
                                </ControlLabel>
                            </FormGroup>
                            <FormGroup controlId="new_password2" bsSize="large" className="form-label-group">
                                <FormControl type="password" placeholder="Retype password" name="new_password2"
                                    value={this.state.new_password2} onChange={this.handleChange} />
                                <ControlLabel>
                                    <FormattedMessage id="common.reenter_password" defaultMessage="Retype password" />
                                </ControlLabel>
                            </FormGroup>
                            <Button block className="btn_register btn-primary" type="submit">
                                <FormattedMessage id="common.update_password" defaultMessage="Update password" />
                            </Button>
                        </form>
                    </div>
                </section>
            );
        }
    }
}

export default ChangePasswordWithCookie;
