// @flow
import React from "react";
import { connect } from "react-redux";
import { translate } from "../IntlProviderWrapper";
import { getBackend } from "../../lib/backend/Backend2";
import * as u from "../../lib/Util";
import * as man_t from "../../lib/backend/manufacturing2.generated.types";
import * as reducersTypes from "./reducers";

type Props = {
    orders: reducersTypes.Order[],
    reduxDispatch: (args: reducersTypes.UpdateOrdersAction) => void
};

type State = {
};

class OrdersShiftsSaveButton extends React.Component<Props, State> {

    state: State = {};

    handleOrdersShiftSaveChanges = async () => {
        const order_uuids = this.props.orders.map(o => o.uuid);
        const orders = this.props.orders.filter(o => order_uuids.includes(o.uuid));

        const updated_orders: man_t.IUpdateOrderType[] = orders
            .filter(o => o.changed === true)
            .map(o => {
                const res: man_t.IUpdateOrderType = {
                    earliest_end: (new Date(o.earliest_end)).getTime(),
                    earliest_start: (new Date(o.earliest_start)).getTime(),
                    quantity_total: o.quantity_total,
                    skip_sim: o.skip_sim,
                    tags: o.tags,
                    uuid: o.uuid
                };
                // set earliest_start according to tag value
                if (o.tags.start_with_shift != null) {
                    const d = u.fromShiftTag(o.tags.start_with_shift);
                    res.earliest_start = d.getTime();
                }
                return res;
            });

        try {
            await getBackend().manufacturing.updateOrders({ orders: updated_orders });

            orders.forEach(o => {
                o.changed = false;
            });

            this.props.reduxDispatch({
                type: reducersTypes.UPDATE_ORDERS_ACTION_NAME,
                data: { orders }
            });

            this.setState({});
        } catch (e) {
            console.error(e);
        }
    }

    render() {
        return (
            <React.Fragment>
                <button type="button" className="btn btn-primary" onClick={this.handleOrdersShiftSaveChanges}>
                    {translate("common.save", "Save")}
                </button>
            </React.Fragment>
        )
    }
}


export default connect(
    (state) => {
        return {
            orders: state.orders,
            has_values_changed: state.has_changed
        };
    },
    (dispatch) => ({ reduxDispatch: dispatch })
)(OrdersShiftsSaveButton);
