// @flow
import * as React from "react";
import { Modal } from "react-bootstrap";

import Loader from "./Loader";
import ErrorComponent from "./ErrorComponent";
import ConfirmationModal from "./ConfirmationModal";
import { translate } from "./IntlProviderWrapper";

type FileNameModalProps = {
    show: boolean,
    name?: string,
    items: string[],
    cleanName?: (name: string) => string,
    onConfirm: (name: string) => Promise<void>,
    onCancel: () => void
};

type FileNameModalState = {
    error: string,
    name: string
};

class FileNameModal extends React.Component<FileNameModalProps, FileNameModalState> {
    constructor(props) {
        super(props);
        this.state = {
            error: "",
            name: ""
        };
    }

    componentDidMount() {
        const name = this.props.name || "";
        this.setState({
            name
        });
        this.validateName(name);
    }

    componentDidUpdate(prev_props: FileNameModalProps) {
        if (this.props.name !== prev_props.name) {
            const name = this.props.name || "";
            this.setState({
                name
            });
            this.validateName(name);
        }
    }

    cleanName = (name: string): string => {
        return this.props.cleanName ? this.props.cleanName(name) : name;
    };

    validateName = (name: string) => {
        if (this.props.items.includes(this.cleanName(name))) {
            this.setState({
                error: translate("common.file_already_exists", "File already exists")
            });
        }
    };

    handleSubmit = (event: SyntheticEvent<HTMLFormElement>) => {
        event.preventDefault();
        const form_data = new FormData(event.currentTarget);
        const name = form_data.get("name");
        if (name && typeof name === "string") {
            this.setState({
                error: ""
            });
            this.props.onConfirm(this.cleanName(name));
        }
    };

    handleNameChange = (event: SyntheticEvent<HTMLInputElement>) => {
        const name = event.currentTarget.value;
        this.setState({
            name,
            error: ""
        });
        this.validateName(name);
    };

    handleExit = () => {
        this.setState({
            name: "",
            error: ""
        });
    };

    render() {
        return (
            <Modal show={this.props.show} onExited={this.handleExit}>
                <Modal.Header>
                    <Modal.Title>{translate("common.enter_file_name", "Enter file name")}</Modal.Title>
                    <button type="button" className="close" onClick={this.props.onCancel}>
                        <span aria-hidden="true">×</span>
                        <span className="sr-only">{translate("common.close", "Close")}</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <form id="file-name-form" onSubmit={this.handleSubmit}>
                        <div className="form-group mb-0">
                            <label className="sr-only" htmlFor="file-name-input">
                                {translate("common.file_name", "File name")}
                            </label>
                            <input
                                id="file-name-input"
                                className={`form-control${this.state.error ? " is-invalid" : ""}`}
                                type="text"
                                name="name"
                                value={this.state.name.trim()}
                                onChange={this.handleNameChange}
                                autoFocus
                            />
                            {this.state.error && <div className="invalid-feedback">{this.state.error}</div>}
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn btn-primary"
                        type="submit"
                        form="file-name-form"
                        disabled={!this.state.name || this.state.error}
                    >
                        {translate("common.ok", "OK")}
                    </button>
                    <button className="btn btn-outline-secondary" onClick={this.props.onCancel}>
                        {translate("common.cancel", "cancel")}
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }
}

type Props = {
    show: boolean,
    title?: string,
    getItems: () => Promise<string[]>,
    getItem: (key: string) => Promise<string>,
    setItem?: (key: string, content: string) => Promise<void>,
    deleteItem?: (key: string) => Promise<void>,
    cleanItem?: (key: string) => string,
    formatItem?: (key: string) => string,
    validateContent?: (content: string) => number[],
    onHide: () => void
};

type State = {
    content: string,
    original_content: string,
    filter: string,
    error: string,
    is_loading: boolean,
    items: string[],
    selected_key: string,
    next_selected_key: string,
    new_file_name: string,
    show_file_name_modal: boolean,
    show_save_changes_modal: boolean,
    show_delete_modal: boolean
};

class BlobStorageItemsModal extends React.Component<Props, State> {
    upload_file_input_ref: { current: null | HTMLInputElement };
    line_numbers_ref: { current: null | HTMLDivElement };

    constructor(props: Props) {
        super(props);
        this.state = {
            content: "",
            original_content: "",
            filter: "",
            error: "",
            is_loading: true,
            items: [],
            selected_key: "",
            next_selected_key: "",
            new_file_name: "",
            show_file_name_modal: false,
            show_save_changes_modal: false,
            show_delete_modal: false
        };
        this.upload_file_input_ref = React.createRef();
        this.line_numbers_ref = React.createRef();
    }

    async componentDidMount() {
        try {
            const items = await this.props.getItems();
            this.setState({ items });

            const filtered_items = items.filter(this.filterItem);
            if (filtered_items.length > 0) {
                await this.loadItem(filtered_items[0]);
            }
        } catch (err) {
            this.setState({ error: `${err}` });
            console.log(err);
        }

        this.setState({ is_loading: false });
    }

    loadItem = async (key: string) => {
        try {
            const content = await this.props.getItem(key);
            this.setState({
                selected_key: key,
                content,
                original_content: content
            });
        } catch (err) {
            this.setState({ error: `${err}` });
            console.log(err);
        }
    };

    filterItem = (item: string) => {
        const extracted_item = (this.props.cleanItem && this.props.cleanItem(item)) || "";
        if (!extracted_item) {
            return false;
        }

        return extracted_item.indexOf(this.state.filter) >= 0;
    };

    formatItem = (item: string) => {
        return this.props.formatItem ? this.props.formatItem(item) : item;
    };

    cleanItem = (item: string) => {
        return (this.props.cleanItem && this.props.cleanItem(item)) || item;
    };

    handleItemSelect = async (key: string) => {
        if (key === this.state.selected_key) {
            return;
        }

        if (this.state.content === this.state.original_content) {
            await this.loadItem(key);
        } else {
            this.setState({
                next_selected_key: key,
                show_save_changes_modal: this.props.setItem !== undefined
            });
        }
    };

    handleContentChange = (event: SyntheticEvent<HTMLTextAreaElement>) => {
        const content = event.currentTarget.value;
        this.setState({
            content
        });
    };

    handleContentSave = async () => {
        const { setItem } = this.props;
        if (!setItem) {
            return;
        }

        try {
            this.setState({ error: "" });
            await setItem(this.state.selected_key, this.state.content);
            this.setState({
                original_content: this.state.content
            });
        } catch (err) {
            this.setState({ error: `${err}` });
            console.log(err);
        }
    };

    handleContentReset = () => {
        this.setState(prev_state => ({
            ...prev_state,
            content: prev_state.original_content
        }));
    };

    handleSaveChangesConfirm = async () => {
        await this.handleContentSave();
        await this.loadItem(this.state.next_selected_key);
        this.setState({
            show_save_changes_modal: false,
            next_selected_key: ""
        });
    };

    handleSaveChangesCancel = async () => {
        await this.loadItem(this.state.next_selected_key);
        this.setState({
            show_save_changes_modal: false,
            next_selected_key: ""
        });
    };

    handleFileUpload = () => {
        if (this.upload_file_input_ref.current !== null) {
            this.upload_file_input_ref.current.click();
        }
    };

    handleUploadFileSelect = async (event: SyntheticEvent<HTMLInputElement>) => {
        const file: any = event.currentTarget.files[0];
        if (file === undefined) {
            return;
        }

        this.setState({
            new_file_name: file.name,
            show_file_name_modal: true
        });
    };

    handleFileNameCancel = () => {
        this.setState({
            show_file_name_modal: false,
            new_file_name: ""
        });

        const file_input = this.upload_file_input_ref.current;
        if (file_input) {
            file_input.value = "";
        }
    };

    handleFileNameConfirm = async (file_name: string) => {
        const { setItem } = this.props;
        if (!setItem) {
            return;
        }

        this.setState({
            show_file_name_modal: false
        });

        let content = "";
        const file_input = this.upload_file_input_ref.current;
        if (this.state.new_file_name && file_input) {
            const file: any = file_input.files[0];
            if (file) {
                content = await file.text();
                file_input.value = "";
            }

            this.setState({
                new_file_name: ""
            });
        }

        const formatted_file_name = this.formatItem(file_name);
        try {
            await setItem(formatted_file_name, content);
            const items = await this.props.getItems();
            this.setState({ items }, () => {
                this.loadItem(formatted_file_name);
            });
        } catch (err) {
            this.setState({ error: `${err}` });
            console.log(err);
        }
    };

    handleFileCreate = () => {
        this.setState({
            show_file_name_modal: true
        });
    };

    handleFilterChange = (event: SyntheticEvent<HTMLInputElement>) => {
        this.setState({
            filter: event.currentTarget.value
        });
    };

    handleFileDelete = () => {
        this.setState({
            show_delete_modal: true
        });
    };

    handleDeleteCancel = async () => {
        this.setState({
            show_delete_modal: false
        });
    };

    handleDeleteConfirm = async () => {
        const { deleteItem } = this.props;
        if (this.state.selected_key === "" || !deleteItem) {
            return;
        }

        this.setState({
            show_delete_modal: false
        });

        try {
            await deleteItem(this.state.selected_key);
            const items = await this.props.getItems();
            this.setState({ items, selected_key: "" }, () => {
                if (items.length) {
                    this.loadItem(items[0]);
                }
            });
        } catch (err) {
            this.setState({ error: `${err}` });
            console.log(err);
        }
    };

    handleContentScroll = (event: SyntheticEvent<HTMLDivElement>) => {
        if (this.line_numbers_ref.current !== null) {
            this.line_numbers_ref.current.scrollTop = event.currentTarget.scrollTop;
        }
    };

    renderItems = () => {
        return (
            <React.Fragment>
                <Modal.Header className="py-2 border border-top-0 border-left-0 rounded-0 bg-white">
                    <input
                        className="form-control search_bar form-control-sm w-100"
                        type="text"
                        name="search"
                        placeholder={translate("common.search", "Search")}
                        value={this.state.filter}
                        disabled={this.state.items.length === 0}
                        onChange={this.handleFilterChange}
                    />
                    {this.props.setItem && (
                        <button
                            className="btn btn-short btn-outline-secondary px-2 ml-2"
                            title={translate("common.new", "New")}
                            style={{ width: 30 }}
                            onClick={this.handleFileCreate}
                        >
                            <i className="fas fa-plus align-middle" />
                        </button>
                    )}
                    {this.props.setItem && (
                        <span className="d-inline-block ml-2">
                            <button
                                className="btn btn-short btn-outline-secondary"
                                title={translate("common.upload", "Upload")}
                                style={{ width: 30 }}
                                onClick={this.handleFileUpload}
                            >
                                <i className="fas fa-file-upload align-middle" />
                            </button>
                            <input
                                className="d-none"
                                type="file"
                                ref={this.upload_file_input_ref}
                                onChange={this.handleUploadFileSelect}
                            />
                        </span>
                    )}
                    {this.props.deleteItem && (
                        <button
                            className="btn btn-short btn-outline-secondary px-2 ml-2"
                            title={translate("common.delete", "Delete")}
                            style={{ width: 30 }}
                            disabled={this.state.items.length === 0}
                            onClick={this.handleFileDelete}
                        >
                            <i className="fas fa-trash align-middle" />
                        </button>
                    )}
                </Modal.Header>
                <div className="list-group list-group-actions h-100 overflow-auto">
                    {this.state.items.filter(this.filterItem).map((item, i) => {
                        const is_active = item === this.state.selected_key;
                        const item_label = this.cleanItem(item);
                        return (
                            <button
                                key={i}
                                className={`list-group-item rounded-0 list-group-item-action${
                                    is_active ? " active" : ""
                                }`}
                                aria-current={is_active ? "true" : "false"}
                                onClick={() => {
                                    this.handleItemSelect(item);
                                }}
                            >
                                <span className="d-block text-truncate w-100">{item_label}</span>
                            </button>
                        );
                    })}
                </div>
            </React.Fragment>
        );
    };

    renderLineNumbers = (content: string, invalid_lines: number[]) => (
        <div
            ref={this.line_numbers_ref}
            className="line-numbers overflow-hidden text-right text-monospace text-muted p-2 pb-4"
        >
            {content.split("\n").map((l, i) => (
                <div key={i} className={`${invalid_lines.includes(i) ? "invalid-line mx-n2 px-2" : ""}`}>{i + 1}</div>
            ))}
        </div>
    );

    renderItemContent = () => {
        if (!this.state.selected_key) {
            return null;
        }

        const invalid_lines = this.props.validateContent ? this.props.validateContent(this.state.content) : [];

        return (
            <React.Fragment>
                <div className="d-flex align-items-stretch h-100 overflow-hidden">
                    {this.renderLineNumbers(this.state.content, invalid_lines)}
                    <textarea
                        className="text-monospace p-2"
                        style={{ whiteSpace: "pre" }}
                        value={this.state.content}
                        readOnly={!this.props.setItem}
                        onChange={this.handleContentChange}
                        onScroll={this.handleContentScroll}
                    />
                </div>
                {invalid_lines.length > 0 && (
                    <Modal.Footer className="d-block bg-white p-0 border-top-0">
                        <ErrorComponent
                            className="my-0 py-2 border-left-0 border-right-0 border-bottom-0 rounded-0"
                            type="warning"
                            msg={`${translate("common.invalid_content", "Invalid content")} (${invalid_lines.length})`}
                        />
                    </Modal.Footer>
                )}
                {this.state.content !== this.state.original_content && (
                    <Modal.Footer className="py-2 bg-white">
                        <button className="btn btn-primary ml-auto mr-0" onClick={this.handleContentSave}>
                            {translate("common.save", "Save")}
                        </button>
                        <button className="btn btn-outline-secondary ml-2" onClick={this.handleContentReset}>
                            {translate("common.reset", "Reset")}
                        </button>
                    </Modal.Footer>
                )}
            </React.Fragment>
        );
    };

    renderStatus = () => {
        if (this.state.error === "") {
            return null;
        }

        return (
            <Modal.Header className="d-block p-0">
                <ErrorComponent
                    className="my-0 border-left-0 border-right-0 rounded-0"
                    type="error"
                    msg={this.state.error}
                />
            </Modal.Header>
        );
    };

    render() {
        return (
            <React.Fragment>
                <Modal
                    dialogClassName="modal-dialog-scrollable blob-storage-items-modal modal-max h-100"
                    show={this.props.show}
                >
                    <Modal.Header>
                        <Modal.Title>
                            {this.props.title || translate("Header.menu.custom_files", "Custom files")}
                        </Modal.Title>
                        <button type="button" className="close" onClick={this.props.onHide}>
                            <span aria-hidden="true">×</span>
                            <span className="sr-only">{translate("common.close", "Close")}</span>
                        </button>
                    </Modal.Header>
                    {this.renderStatus()}
                    <Modal.Body className="p-0 overflow-hidden">
                        {this.state.is_loading ? (
                            <Loader />
                        ) : (
                            <div className="row align-items-stretch h-100 m-0">
                                <div className="d-flex flex-column col-sm-6 col-md-5 col-lg-4 col-xl-3 h-100 p-0">
                                    {this.renderItems()}
                                </div>
                                <div className="d-flex flex-column col-sm-6 col-md-7 col-lg-8 col-xl-9 h-100 p-0">
                                    {this.renderItemContent()}
                                </div>
                            </div>
                        )}
                    </Modal.Body>
                </Modal>
                <FileNameModal
                    show={this.state.show_file_name_modal}
                    name={this.cleanItem(this.state.new_file_name)}
                    items={this.state.items.map(item => this.cleanItem(item))}
                    cleanName={this.props.cleanItem}
                    onCancel={this.handleFileNameCancel}
                    onConfirm={this.handleFileNameConfirm}
                />
                <ConfirmationModal
                    title={translate("common.save", "Save")}
                    show={this.state.show_save_changes_modal}
                    onAccept={this.handleSaveChangesConfirm}
                    onCancel={this.handleSaveChangesCancel}
                >
                    {translate("common.resource_edit_modal", "Do you want to save your changes?")}
                </ConfirmationModal>
                <ConfirmationModal
                    title={translate("common.delete", "Delete")}
                    show={this.state.show_delete_modal}
                    onAccept={this.handleDeleteConfirm}
                    onCancel={this.handleDeleteCancel}
                >
                    {translate("common.are_you_sure_delete_file", "Are you sure you want to delete this file?")}
                </ConfirmationModal>
            </React.Fragment>
        );
    }
}

export default BlobStorageItemsModal;
