// @flow
import * as React from "react";
import { Button, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import Toggle from "react-toggle";
import Select from "react-select";
import { FormattedMessage } from "react-intl";

import { validateEmail, getLoggedinUser } from "../lib/Auth";
import * as BusinessLogic from "../lib/BusinessLogic";

import { getBackend } from "../lib/backend/Backend2";
import * as t from "../lib/backend/users.generated.types";
import { translate } from "./IntlProviderWrapper";
import ErrorComponent from "./ErrorComponent";
type SelectOptionItem = {
    label: string;
    value: string;
}

type Props = {};

type State = {
    message: string,
    user_info?: t.IGetCurrentUserDataRes,
    plants: SelectOptionItem[],
    selected_plant: SelectOptionItem,
    line_groups: SelectOptionItem[],
    selected_line_group: SelectOptionItem
};


class SettingsNotifications extends React.Component<Props, State> {
    handleChange: any;
    handleCheck: any;
    getInitialState: any;
    all_plants_option = { label: translate("Settings.Notifications.all_plants", "All plants"), value: "all" }
    all_line_group_options = { label: translate("Settings.Notifications.all_linegroups", "All line groups"), value: "all" };

    constructor() {
        super();
        const state: State = {
            message: "",
            plants: [],
            selected_plant: this.all_plants_option,
            line_groups: [],
            selected_line_group: this.all_line_group_options
        };
        this.state = state;
    }

    async componentDidMount() {
        try {
            // get plants for user
            const plants = BusinessLogic.getPlantsForUser();
            let plants_options = plants.map((plant): SelectOptionItem => ({
                value: plant.uuid,
                label: plant.title
            }));
            plants_options.unshift(this.all_plants_option);

            // initialize line_group_options
            let line_group_options = [this.all_line_group_options];
            let selected_line_group = line_group_options[0];
            let selected_plant = plants_options[0];

            // initialize toggles from storage
            const user_info = await getBackend().users.getCurrentUserData({});
            user_info.user_data.notifications.notify_capacity = !!user_info.user_data.notifications.notify_capacity;
            user_info.user_data.notifications.notify_downtime = !!user_info.user_data.notifications.notify_downtime;
            user_info.user_data.notifications.notify_plan_change = !!user_info.user_data.notifications.notify_plan_change;
            user_info.user_data.notifications.notify_plan_problems = !!user_info.user_data.notifications.notify_plan_problems;
            user_info.user_data.notifications.notify_stock = !!user_info.user_data.notifications.notify_stock;
            user_info.user_data.notifications.notify_scrap = !!user_info.user_data.notifications.notify_scrap;
            user_info.user_data.notifications.notify_tool_setup = !!user_info.user_data.notifications.notify_tool_setup;

            // If user has selected plant in storage
            if (user_info.user_data.notifications.plant) {
                // set selected plant to show in dropdown
                selected_plant = plants_options.filter(plant => user_info.user_data.notifications.plant === plant.value)[0];
                // set line groups for selected plant
                line_group_options = this.extractLineGroupOptions(user_info.user_data.notifications.plant || "");
            };

            // If user has selected line group in storage
            if (user_info.user_data.notifications.line_group) {
                selected_line_group = line_group_options.filter(group => user_info.user_data.notifications.line_group === group.value)[0];
            }

            this.setState({
                selected_plant,
                plants: plants_options,
                selected_line_group,
                line_groups: line_group_options,
                user_info
            });

        } catch (err) {
            console.log(err);
        }
    }

    extractLineGroupOptions (plant_uuid: string): SelectOptionItem[] {
        let options = BusinessLogic.getLineGroupsForUser()
            .filter(x => x.plant_uuids.indexOf(plant_uuid) >= 0)
            .map(x => ({ value: x.uuid, label: x.title }));
        options.unshift(this.all_line_group_options);
        return options;
    }

    handlePlantChange = (selectedValue: Object) => {
        const line_group_options = this.extractLineGroupOptions(selectedValue.value);
        this.setState({
            selected_plant: selectedValue,
            line_groups: line_group_options
        });
    };

    handleLineGroupChange = (selectedValue: Object) => {
        this.setState({ selected_line_group: selectedValue });
    };

    handleNotificationsToggle = (event: Event) => {
        let target = event.target;
        if (target instanceof HTMLInputElement) {
            this.setState(prev_state => {
                if (prev_state.user_info && prev_state.user_info.user_data.notifications) {
                    const n = prev_state.user_info.user_data.notifications;
                    console.log(target.id, n);
                    switch (target.id) {
                        case "notify_capacity":
                            n.notify_capacity = !n.notify_capacity;
                            break;
                        case "notify_downtime":
                            n.notify_downtime = !n.notify_downtime;
                            break;
                        case "notify_plan_change":
                            n.notify_plan_change = !n.notify_plan_change;
                            break;
                        case "notify_plan_problems":
                            n.notify_plan_problems = !n.notify_plan_problems;
                            break;
                        case "notify_stock":
                            n.notify_stock = !n.notify_stock;
                            break;
                        case "notify_scrap":
                            n.notify_scrap = !n.notify_scrap;
                            break;
                        case "notify_tool_setup":
                            n.notify_tool_setup = !n.notify_tool_setup;
                            break;
                        default:
                            n.enabled = !n.enabled;
                            break;
                    }
                    return prev_state;
                }
            });
        }
    };

    handleNotificationsEmail = (event: Event) => {
        let target = event.target;
        if (target instanceof HTMLInputElement) {
            this.setState(prev_state => {
                if (prev_state.user_info) {
                    prev_state.user_info.user_data.notifications.email = target.value;
                }
                return prev_state;
            });
        }
    };

    handleNotificationsSubmit = async (event: Event) => {
        try {
            event.preventDefault();
            if (this.state.user_info) {
                let notifications = this.state.user_info.user_data.notifications;
                // update selected plant and line group
                if (this.state.selected_plant && this.state.selected_plant.value !== "all") {
                    notifications.plant = this.state.selected_plant.value;
                } else {
                    delete notifications.plant;
                };
                if (this.state.selected_line_group && this.state.selected_line_group.value !== "all") {
                    notifications.line_group = this.state.selected_line_group.value;
                } else {
                    delete notifications.line_group;
                };
                await getBackend().users.setUserNotifications({
                    email: notifications.email,
                    enabled: notifications.enabled,
                    id: getLoggedinUser().uuid,
                    line_group: notifications.line_group,
                    notify_capacity: notifications.notify_capacity || false,
                    notify_downtime: notifications.notify_downtime || false,
                    notify_plan_change: notifications.notify_plan_change || false,
                    notify_plan_problems: notifications.notify_plan_problems || false,
                    notify_scrap: notifications.notify_scrap || false,
                    notify_stock: notifications.notify_stock || false,
                    notify_tool_setup: notifications.notify_tool_setup || false,
                    plant: notifications.plant
                })
                this.setState({ message: translate("Settings.Notifications.notifications_updated", "Notification settings updated!") });
            }
        } catch (error) {
            this.setState({ message: translate("Settings.Notifications.error_updating_notifications", "Error updating notification setttings") });
            console.log(error);
        }
    };

    validateEmail = () => {
        return this.state.user_info && this.state.user_info.user_data.notifications &&
            this.state.user_info.user_data.notifications.email && validateEmail(this.state.user_info.user_data.notifications.email);
    };

    renderNotifications = (user_info: t.IGetCurrentUserDataRes, message: string) => {
        const show_manufacturing = BusinessLogic.displayFeature("manufacturing");
        const notifications = user_info.user_data.notifications;
        return (
            <div className="white_box">
                <h4><FormattedMessage id="common.notifications" defaultMessage="Notifications" /></h4>
                <div className="tab-content">
                    <form onSubmit={this.handleNotificationsSubmit} className="form-horizontal m-t-20" key="3">
                        <Toggle
                            checked={notifications.enabled}
                            onChange={this.handleNotificationsToggle} />
                        <FormGroup controlId="username" bsSize="large">
                            <ControlLabel>{translate("common.email", "Email")}</ControlLabel>
                            <FormControl onChange={this.handleNotificationsEmail}
                                value={notifications.email}
                                disabled={!notifications.enabled}
                                className="form-control" type="text"
                                placeholder={translate("common.your_email_address", "Your email address")}
                                />
                        </FormGroup>
                        {show_manufacturing &&
                            <div>
                                <h4><FormattedMessage id="Settings.Notifications.plant_and_linegroup" defaultMessage="Plant and linegroup" /></h4>
                                <FormGroup controlId="scrap2" bsSize="small">
                                    <ControlLabel><FormattedMessage id="common.plant" defaultMessage="Plant" /></ControlLabel>
                                    <Select
                                        value={this.state.selected_plant}
                                        options={this.state.plants}
                                        disabled={!notifications.enabled}
                                        onChange={event => this.handlePlantChange(event)} />
                                </FormGroup>
                                <FormGroup controlId="Downtimes" bsSize="small">
                                    <ControlLabel><FormattedMessage id="common.linegroup" defaultMessage="Line group" /></ControlLabel>
                                    <Select
                                        value={this.state.selected_line_group}
                                        options={this.state.line_groups}
                                        disabled={!notifications.enabled}
                                        onChange={event => this.handleLineGroupChange(event)} />
                                </FormGroup>

                                <h4><FormattedMessage id="Settings.Notifications.notification_types" defaultMessage="Notification types" /></h4>
                                <FormGroup controlId="scrap" bsSize="small">
                                    <Toggle id="notify_scrap" className="float-right"
                                        checked={notifications.notify_scrap}
                                        disabled={!notifications.enabled}
                                        onChange={this.handleNotificationsToggle} />
                                    <ControlLabel><FormattedMessage id="common.scrap" defaultMessage="Scrap" /></ControlLabel>
                                </FormGroup>
                                <FormGroup controlId="Downtimes" bsSize="small">
                                    <Toggle id="notify_downtime" className="float-right"
                                        checked={notifications.notify_downtime}
                                        disabled={!notifications.enabled}
                                        onChange={this.handleNotificationsToggle} />
                                    <ControlLabel><FormattedMessage id="common.downtimes" defaultMessage="Downtimes" /></ControlLabel>
                                </FormGroup>
                                <FormGroup controlId="Capacity" bsSize="small">
                                    <Toggle id="notify_capacity" className="float-right"
                                        checked={notifications.notify_capacity}
                                        disabled={!notifications.enabled}
                                        onChange={this.handleNotificationsToggle} />
                                    <ControlLabel><FormattedMessage id="common.capacity" defaultMessage="Capacity" /></ControlLabel>
                                </FormGroup>
                                <FormGroup controlId="ToolSetup" bsSize="small">
                                    <Toggle id="notify_tool_setup" className="float-right"
                                        checked={notifications.notify_tool_setup}
                                        disabled={!notifications.enabled}
                                        onChange={this.handleNotificationsToggle} />
                                    <ControlLabel><FormattedMessage id="common.tool_setup" defaultMessage="Tool setup" /></ControlLabel>
                                </FormGroup>
                                <FormGroup controlId="PlanChanges" bsSize="small">
                                    <Toggle id="notify_plan_change" className="float-right"
                                        checked={notifications.notify_plan_change}
                                        disabled={!notifications.enabled}
                                        onChange={this.handleNotificationsToggle} />
                                    <ControlLabel><FormattedMessage id="common.plan_changes" defaultMessage="Plan changes" /></ControlLabel>
                                </FormGroup>
                                <FormGroup controlId="PlanEarlyLate" bsSize="small">
                                    <Toggle id="notify_plan_problems" className="float-right"
                                        checked={notifications.notify_plan_problems}
                                        disabled={!notifications.enabled}
                                        onChange={this.handleNotificationsToggle} />
                                    <ControlLabel><FormattedMessage id="common.plan_problems" defaultMessage="Plan problems" /></ControlLabel>
                                </FormGroup>
                                <FormGroup controlId="stock" bsSize="small">
                                    <Toggle id="notify_stock" className="float-right"
                                        checked={notifications.notify_stock}
                                        disabled={!notifications.enabled}
                                        onChange={this.handleNotificationsToggle} />
                                    <ControlLabel><FormattedMessage id="common.stock" defaultMessage="Stock" /></ControlLabel>
                                </FormGroup>
                            </div>
                        }
                        {message && (<ErrorComponent msg={message} type="warning" />)}
                        <Button block
                            className="btn_save"
                            disabled={!this.validateEmail()}
                            type="submit">{translate("Settings.save", "Save")}</Button>
                    </form>
                </div>
            </div>
        );
    };

    render() {
        if (!this.state.user_info) {
            return null;
        }
        return this.renderNotifications(this.state.user_info, this.state.message);
    }
}

export default SettingsNotifications;
