// @flow

// React
import React, { Component } from "react";
import ChevronListItem from "../react/ChevronListItem";
// Models
import type { ILineData } from "../../lib/backend/manufacturing2.generated.types";

type Props = {
    filter: string,
    plantFilter: string,
    selected: ILineData | null,
    lines: ILineData[],
    line_uuid_to_group_titles: Map<string, string[]>,
    handleLineClick: any
}

/**
 * Lines list component.
 */
class LinesList extends Component<Props> {
    /**
     * Handle `click` events for the line item.
     */
    handleLineClick(line: ILineData, i: number) {
        // Ignore clicks for currently selected line.
        if (line === this.props.selected) {
            return;
        }

        // Handle parent's click events.
        this.props.handleLineClick(line);
    }

    /**
     * Line list filter.
     *
     * @param {string} needle Search term.
     * @param {LineObj} line Line object to match search term against.
     *
     * @return {boolean} `true` if line matches given search term, `false` otherwise.
     */
    includes(needle: string, line: any) {
        needle = needle.toLowerCase();
        if (needle.length === 0) return true;
        if (line.title.toLowerCase().indexOf(needle) > -1) return true;
        if (line.external_id.toLowerCase().indexOf(needle) > -1) return true;
        if (line.plant_title.toLowerCase().indexOf(needle) > -1) return true;
        if (line.plant_uuid.toLowerCase().indexOf(needle) > -1) return true;
        // search tag keys and values
        let tagsSearch = false;
        if (line.tags !== null) {
            Object.keys(line.tags).forEach((el, i) => {
                // comparing values
                if (String(line.tags[el]).toLowerCase().indexOf(needle) > -1) {
                    tagsSearch = true;
                }
                // comparing keys
                if (el.toLowerCase().indexOf(needle) > -1) {
                    tagsSearch = true;
                }
            });
        };
        if (tagsSearch === true) return true;
        return false;
    }

    /**
     * Render component.
     */
    render() {
        const linesList = this.props.lines.map((line, i) => {
            let is_active = this.props.selected === line;

            if (this.props.plantFilter && line.plant_uuid !== this.props.plantFilter) return null;
            if (!this.includes(this.props.filter, line)) return null;

            // compose list of line-groups
            const line_groups = this.props.line_uuid_to_group_titles.get(line.uuid);
            const line_groups_title = (line_groups !== undefined) ? line_groups.join(", ") : "/";
            return <ChevronListItem
                text={
                    <div>
                        <dt>{`[${line.external_id}] - ` + line.title}<br />
                            <small>{line.plant_title} - {line_groups_title}</small></dt>
                    </div>
                }
                onClick={() => this.handleLineClick(line, i)}
                is_active={is_active}
            />
        })

        return (
            <ul className="nav nav-tabs">
                <div className="nav-tabs-parent" ref="nav">
                    {linesList}
                </div>
            </ul>
        )
    }
}

export default LinesList;
