// @flow
export const MIN_DAY_WIDTH = 40; // ~~ 900 for 4 weeks
export const MAX_SPACE_FOR_LABEL = 220;
export const PADDING = 30;
export const LINE_HEIGHT = 40;
export const TOP_PADDING = 75;
export const BAR_HEIGHT = 30;

export const SPACE_BETWEEN_BARS = 10;
export const SHIFTS_PER_DAY = 3;
export const SHIFT_H = 8;
export const SHIFTS_IN_WEEK = SHIFTS_PER_DAY * 7;
export const MIN_DAYS = 60;
