// @flow
import React from "react";

// $FlowFixMe
import { Modal, FormControl } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import DateTimePicker from "../DateTimePicker";
import ErrorComponent from "../ErrorComponent";

import { getBackend } from "../../lib/backend/Backend2";
import type { IBatchOperationOrderArchive } from "../../lib/backend/batch_operations.generated.types";

// ArchiveItemModalDataType - contains all the data needed to update archive item (item_id, group_id)
type Props = {
    hideModal: () => void,
    reload: () => Promise<void>,
    ts_delivered: ?number,
    person_id: ?string,
    line_group_uuid: string,
    show_modal: boolean,
    item_id: number
}

type State = {
    ts_delivered: ?number,
    person_id: ?string,
    error_msg: ?string
}

class BatchOperationsArchiveItemModal extends React.Component<Props, State> {
    state: State = {
        ts_delivered: null,
        person_id: null,
        error_msg: null,
        show: false
    }

    componentDidMount() {
        this.load();
    }

    load() {
        const ts_delivered = this.props.ts_delivered && this.props.ts_delivered > 0 ? this.props.ts_delivered : (new Date()).getTime();
        const person_id = this.props.person_id ? this.props.person_id : null;

        this.setState({ ts_delivered, person_id });
    }

    onConfirm = async () => {
        try {
            const ts_delivered = this.state.ts_delivered;
            const person_id = this.state.person_id;

            if (this.props.line_group_uuid && ts_delivered && person_id) {
                await getBackend().batchOperations.batchopUpdateOperationArchiveItem({
                    item_id: this.props.item_id,
                    group_id: this.props.line_group_uuid,
                    ts_delivered,
                    person_id
                });

                this.props.hideModal();
                this.props.reload();
            }
        } catch (e) {
            this.setState({ error_msg: e.message });
        }
    }


    onPersonIdChange = (event: Event) => {
        const target = event.target;
        if (target instanceof HTMLInputElement) {
            this.setState({ person_id: target.value });
        }
    }

    onChange = (value: Date) => {
        const ts_delivered = value.getTime();
        this.setState({ ts_delivered });
    }

    getTsDelivered() {
        return this.state.ts_delivered ? new Date(this.state.ts_delivered) : new Date();
    }

    getIsConfirmedEnabled() {
        return this.state.ts_delivered && this.state.person_id;
    }

    render() {
        return (
            <Modal className="batchop-archive-item-modal" show={this.props.show_modal} onHide={this.props.hideModal}>
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id="Manufacturing.BatchOperationsArchiveItemModal.modal_title" defaultMessage="Batch operations archive item modal" />
                    </Modal.Title>

                    <button type="button" className="close" onClick={this.props.hideModal}>
                        <span>×</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <React.Fragment>
                        <div>
                            {this.state.error_msg && <ErrorComponent msg={this.state.error_msg} type="error" onlyDiv={true} is_closable={true} />}
                            <label>
                                <FormattedMessage id="Manufacturing.BatchOperationsArchiveItemModal.person_id" defaultMessage="Person ID" />:
                                </label>
                            <div className="form-group">
                                <FormControl type="text" value={this.state.person_id} onChange={this.onPersonIdChange} />
                            </div>
                        </div>
                        <div>
                            <label>
                                <FormattedMessage id="Manufacturing.BatchOperationsArchiveItemModal.date_delivered" defaultMessage="Date Delivered" />:
                                </label>
                            <div className="form-group">
                                <DateTimePicker
                                    name="ts_delivered"
                                    value={this.getTsDelivered()}
                                    pickerType="date"
                                    popupPostion="fixed"
                                    onChange={this.onChange}
                                />
                            </div>
                        </div>
                    </React.Fragment>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        disabled={!this.getIsConfirmedEnabled()}
                        onClick={this.onConfirm}
                        className="btn btn-primary"
                    >
                        <FormattedMessage id="common.update" defaultMessage="Update" />
                    </button>
                </Modal.Footer>
            </Modal>
        )
    }
}

type EditButtonProps = {
    order: IBatchOperationOrderArchive,
    line_group_uuid: string,
    reload: () => Promise<void>
}

type EditButtonState = {
    show_modal: boolean
}

export class EditButton extends React.Component<EditButtonProps, EditButtonState> {

    state: EditButtonState = {
        show_modal: false
    }

    hideModal = () => {
        this.setState({ show_modal: false });
    }

    showModal = () => {
        this.setState({ show_modal: true })
    }

    render() {
        return <React.Fragment>
            {this.state.show_modal && <BatchOperationsArchiveItemModal
                reload={this.props.reload}
                hideModal={this.hideModal}
                line_group_uuid={this.props.line_group_uuid}
                person_id={this.props.order.person_id}
                ts_delivered={this.props.order.ts_delivered}
                item_id={this.props.order.id}
                show_modal={this.state.show_modal}
            />}
            <button className="btn btn-outline-primary" onClick={this.showModal}>
                <FormattedMessage id="common.edit" defaultMessage="Edit" />
            </button>
        </React.Fragment>
    }
}
