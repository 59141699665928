// @flow
import * as React from "react";
import { connect } from "react-redux";

import { orderColor } from "../../../../lib/Util";
import { ConnectedOrderRectangle, TooltipText } from "./Order";
import MaterialTitle from "./MaterialTitle";
import DraggableOrder from "./DraggableOrder";
import { getCursorValue } from "../reducers/properties";
import { TypeFilterValues } from "../lineOrderList/filters/TypeFilterConsts";

import type { ShiftProps } from "./Shifts";
import type { OrderRectangleProps } from "./Order";
import type { MaterialTitleProps } from "./MaterialTitle";
import type { ReduxState } from "../reducers/reducers"
import type { GanttChartSourceTypes } from "../reducers/properties";
import type { IOrderProducedModelEx } from "../reducers/linesOrders";
import type { IMaterialModel } from "../../../../lib/backend/manufacturing2.generated.types";

type Props = {
    order_uuid: string,
    line_index: number,
    line_uuid: string,
    order_type: string,
    shift_number: number,
    shifts_attributes: ShiftProps[],
    order_rectangle_attributes: OrderRectangleProps,
    material_title_attributes: MaterialTitleProps,
    is_reschedule: boolean,
    is_ctrl_down: boolean,
    source_type: GanttChartSourceTypes,
    shift_width: number,
    unscheduled_order: IOrderProducedModelEx,
    is_ctrl_down: boolean,
    order_process_num: string,
    production_version: string,
    updated_order_uuid: string | null,
    is_linked_operation: boolean,
    earliest_start: number,
    material?: IMaterialModel
}

type State = {};

class UnscheduledOrder extends React.Component<Props, State> {
    componentDidUpdate(prev_props: Props) {
        if (this.props.is_reschedule && this.props.is_reschedule === prev_props.is_reschedule &&
            this.props.earliest_start !== prev_props.earliest_start
        ) {
            const el = document.querySelector(`g[name="order"][order-uuid="${this.props.unscheduled_order.uuid}"]`);
            if (el !== null) {
                // $FlowFixMe
                setTimeout(() => { el.scrollIntoView({ block: "center", inline: "center" }); }, 300);
            }
        }
    }
    render() {
        const tooltip_text = <TooltipText
            order={null}
            unscheduled_order={this.props.unscheduled_order}
            insight_tooltips={[]}
            properties={[]}
            updated_order_uuid={this.props.updated_order_uuid}
            material={this.props.material}
        />
        const cursor = getCursorValue({
            is_ctrl_down: this.props.is_ctrl_down,
            is_rescheduled: this.props.is_reschedule,
            source_type: this.props.source_type
        });
        return <DraggableOrder
            draggable={"true"}
            order_uuid={this.props.order_uuid}
            line_index={this.props.line_index}
            order_index={0}
            cursor={cursor}
            order_color={orderColor(
                {
                    order_type: this.props.order_type,
                    has_insight: false
                }
            )}
            line_uuid={this.props.line_uuid}
            shift_number={this.props.shift_number}
            is_unscheduled={true}
            source_type={this.props.source_type}
            x={this.props.order_rectangle_attributes.x}
            y={this.props.order_rectangle_attributes.y}
            width={Math.max(this.props.order_rectangle_attributes.width, this.props.shift_width)}
            height={this.props.order_rectangle_attributes.height}
            is_parallel_line={false}
            opacity={1}
            earliest_start={(new Date(this.props.order_rectangle_attributes.earliest_start)).getTime()}
            earliest_end={this.props.order_rectangle_attributes.earliest_end}
            order_process_num={this.props.order_process_num}
            production_version={this.props.order_rectangle_attributes.production_version}
            material_external_id={this.props.material_title_attributes.material_external_id}
            is_linked_operation={this.props.is_linked_operation}
        >
            <ConnectedOrderRectangle tooltip_text={tooltip_text} {...this.props.order_rectangle_attributes} />
            <MaterialTitle
                key={this.props.material_title_attributes.material_external_id}
                {...this.props.material_title_attributes}
                is_rescheduled={this.props.is_reschedule}
                tooltip_text={tooltip_text}
                is_ctrl_down={this.props.is_ctrl_down}
            />
        </DraggableOrder>
    }
}

export default connect(
    (state: ReduxState, own_props: Props) => {
        const is_reschedule =
            state.gantt_chart_filters.selected_filter_type === TypeFilterValues.reschedule_operation &&
            state.gantt_chart_filters.reschedule_order_uuid === own_props.order_uuid;
        const planning_table = state.gantt_chart_planning_table;
        const properties = state.gantt_chart_properties;
        const updated_order_uuid = state.gantt_chart_lines_orders.updated_order_uuid;
        const material = state.gantt_chart.materials.find(
            material => material.external_id === own_props.material_title_attributes.material_external_id
        );

        return {
            is_reschedule,
            is_ctrl_down: planning_table.is_ctrl_down,
            shift_width: properties.shift_width,
            updated_order_uuid,
            material
        };
    },
    (dispatch) => ({ reduxDispatch: dispatch })
)(UnscheduledOrder);
