// @flow
import React from "react";
import { connect } from "react-redux";
import { getBackend as getBackend2 } from "../../../lib/backend/Backend2";
import * as t from "../../../lib/backend/manufacturing2.generated.types";
import { translate } from "../../../components/IntlProviderWrapper";
import { niceDateTime, createPlainTextAndDownload } from "../../../lib/Util";
import { SET_ERROR } from "./reducers/planningTable";
import { ReportLogic } from "./reducers/BusinessLogic";
import {
    UPDATE_PARALLEL_REPORT_ACTION_NAME,
    PARALLEL_REPORT_LOADING_ACTION_NAME,
    ADD_REPORT_TO_DELETE
} from "./reducers/parallelReport";
import type { ReportParameters } from "./reducers/common";
import type { SetErrorAction } from "./reducers/planningTable"
import type { LineOrders } from "./reducers/linesOrders";
import type { ReduxState } from "./reducers/reducers";
import type {
    UpdateParallelReportAction,
    ParallelReportLoading,
    AddReportToDelete,
    ReportsToDelete,
    UnsetReportToDelete
} from "./reducers/parallelReport"

type Props = {
    parallel_original_report_uuid: string,
    parallel_current_report_uuid: string,
    lines_orders: LineOrders[],
    parallel_report_loading: boolean,
    reduxDispatch: (args: UnsetReportToDelete | SetErrorAction | UpdateParallelReportAction | ParallelReportLoading | AddReportToDelete) => void,
    parameters: ReportParameters,
    reports_to_delete: ReportsToDelete
};

type State = {
    loading: boolean
};

type OrdersToolsMap = {
    [key: string]: t.IOrderTool
}

class SaveToTxtButton extends React.Component<Props, State> {

    state: State = {
        loading: false
    }

    componentDidMount = async () => {
        if (this.props.parameters) {
            const report_uuid = await this.generateParallelReport(this.props.parameters);
            if (!report_uuid) return;
            this.props.reduxDispatch({ type: UPDATE_PARALLEL_REPORT_ACTION_NAME, data: report_uuid });
        }
    }

    generateParallelReport = async (params: ReportParameters): Promise<string | null | typeof undefined> => {
        try {
            const ret = await ReportLogic.generateParallelReportUuidWarning(this.props.parameters);
            if (ret) {
                return ret.report_uuid;
            }
        } catch (e) {
            this.props.reduxDispatch({ type: SET_ERROR, data: e.message })
        }
        return null;
    }

    shouldGenerateOrigReportUuid = (prev_parameters: ReportParameters, parameters: ReportParameters) => {
        return (
            (prev_parameters && parameters && (
                prev_parameters.config.line_group_uuid !== parameters.config.line_group_uuid ||
                prev_parameters.config.plant_uuid !== parameters.config.plant_uuid
            )) ||
            parameters && !this.props.parallel_original_report_uuid
        );
    }

    componentDidUpdate = async (prev_props: Props) => {
        if (this.shouldGenerateOrigReportUuid(prev_props.parameters, this.props.parameters)) {
            const report_uuid = await this.generateParallelReport(this.props.parameters);
            if (!report_uuid) return;
            // generate original report_uuid
            this.props.reduxDispatch({ type: UPDATE_PARALLEL_REPORT_ACTION_NAME, data: report_uuid });
        }

        if (prev_props.parallel_original_report_uuid === null && this.props.parallel_original_report_uuid) {
            this.props.reduxDispatch({ type: ADD_REPORT_TO_DELETE, data: this.props.parallel_original_report_uuid });
        }
    }

    getTools = async (order_external_ids: string[]): Promise<OrdersToolsMap> => {
        const res = await getBackend2().manufacturing.getOrdersTools({ order_external_ids });

        const orders_tools_map = {};
        for (const ot of res.orders_tools) {
            orders_tools_map[ot.order_external_id] = ot;
        }
        return orders_tools_map;
    }

    onExport = async () => {
        const line_uuids = this.props.lines_orders.map(l => l.line_uuid);

        this.props.reduxDispatch({ type: PARALLEL_REPORT_LOADING_ACTION_NAME, data: true });
        let result = null;

        try {
            const current_report_uuid = await this.generateParallelReport(this.props.parameters);
            if (!current_report_uuid) return;

            result = await getBackend2().reports.getPlanTableExport({
                initial_report_uuid: this.props.parallel_original_report_uuid,
                final_report_uuid: current_report_uuid,
                line_uuids
            });

            this.props.reduxDispatch({ type: ADD_REPORT_TO_DELETE, data: current_report_uuid });
            this.props.reduxDispatch({ type: PARALLEL_REPORT_LOADING_ACTION_NAME, data: false });
        } catch (e) {
            this.props.reduxDispatch({ type: PARALLEL_REPORT_LOADING_ACTION_NAME, data: false });
            return this.props.reduxDispatch({ type: SET_ERROR, data: e.message });
        }

        let txt_file = "";
        if (result.report_export && result.report_export.order_changes) {
            const order_external_ids = result.report_export.order_changes.map(
                oc => oc.order_external_id
            );
            const orders_tools = await this.getTools(order_external_ids);

            const missing_tools = [];
            for (const order of result.report_export.order_changes) {
                for (const change of order.op_changes) {
                    const tool = orders_tools[order.order_external_id];
                    let tool_external_id = "-";
                    if (tool) {
                        tool_external_id = tool.tool_external_id;
                    } else {
                        missing_tools.push(order.order_external_id);
                    }

                    let line = `${order.order_external_id}\t` +
                        `${change.process_num}\t` +
                        `${change.final_line_external_id || change.initial_line_external_id || "N/A"}\t` +
                        `${niceDateTime(new Date(change.final_epoch_start))}\t` +
                        `${niceDateTime(new Date(change.final_epoch_end))}\t` +
                        `${tool_external_id}`;

                    txt_file += line + "\n";
                }
            }
            if (missing_tools.length > 0) {
                const error_msg = translate(
                    "common.error_tool",
                    "Some orders do not have tools."
                ) + ": " + missing_tools.join(",");
                this.props.reduxDispatch({ type: SET_ERROR, data: error_msg });
            }
        }

        const filename = `planning_table_export_${(new Date()).getTime()}.txt`;
        return createPlainTextAndDownload(txt_file, filename);
    }

    isDisabled = () => {
        return !this.props.parallel_original_report_uuid;
    }

    render() {
        const is_disabled = this.isDisabled();
        return (
            <button
                className="dropdown-item"
                onClick={this.onExport}
                disabled={is_disabled}
            >
                {is_disabled && <i className="fas fa-spinner fa-spin" />} {translate("common.export_in_text_format", "Export in text format")}
            </button>
        );
    }
}

export default connect(
    (state: ReduxState) => {
        const parallel_report = state.gantt_chart_parallel_report;

        return {
            parallel_report_loading: parallel_report.parallel_report_loading,
            parallel_original_report_uuid: parallel_report.parallel_original_report_uuid,
            lines_orders: state.gantt_chart_lines_orders.lines_orders,
            parameters: parallel_report.parameters,
            reports_to_delete: parallel_report.reports_to_delete
        }
    },
    (dispatch) => ({ reduxDispatch: dispatch })
)(SaveToTxtButton);
