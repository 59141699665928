// @flow
import * as React from "react";
import { getBackend } from "../lib/backend/Backend2";
import * as t from "../lib/backend/apps.generated.types";
import * as ct from "../lib/CommonTags.generated";
import { FormattedMessage } from "react-intl";
import Toggle from "react-toggle";
import ErrorComponent from "./ErrorComponent";
import { translate } from "./IntlProviderWrapper";

type Props = {};

type State = {
    apps: Array<t.IAppData>,
    is_adding: boolean,
    is_external_app: boolean,
    new_app_title: string,
    new_api_key: string,
    new_uuid: string,
    err_msg: string
};

class MyApps extends React.Component<Props, State> {
    handleChange: any;
    handleCheck: any;
    getInitialState: any;

    constructor() {
        super();
        let state: State = {
            apps: [],
            is_adding: false,
            is_external_app: false,
            new_app_title: "",
            new_api_key: "",
            new_uuid: "",
            err_msg: ""
        };
        this.state = state;
    }

    async componentDidMount() {
        await this.loadApps();
    }

    loadApps = async () => {
        try {
            const res = await getBackend().apps.searchApps({});
            this.setState({ apps: res.items });
        } catch (err) {
            console.log(err);
        };
    };

    showAddApp = () => {
        this.setState({ is_adding: true, err_msg: "", new_api_key: "" });
    }
    cancelAddApp = () => {
        this.setState({ is_adding: false });
    }
    performAddApp = async () => {
        this.setState({ err_msg: "", new_api_key: "" });
        try {
            const obj = await getBackend().apps.createNewApp({ title: this.state.new_app_title, is_external: this.state.is_external_app });
            this.setState({ is_adding: false, new_api_key: obj.api_key, new_uuid: obj.uuid });
            await this.loadApps();
        } catch (err) {
            this.setState({ err_msg: err.message });
        }
    }

    regenerateExistingApiKey = async (my_app: t.IAppData) => {
        this.setState({ new_api_key: "" });
        try {
            const res = await getBackend().apps.regenerateApiKey({ id: my_app.uuid });
            this.setState({ new_uuid: my_app.uuid, new_api_key: res.api_key });
        } catch (err) {
            this.setState({ err_msg: err.message });
        }
    };
    activateApp = async (my_app: t.IAppData) => {
        try {
            await getBackend().apps.setActive({ id: my_app.uuid });
            await this.loadApps();
        } catch (err) {
            this.setState({ err_msg: err.message });
        }
    };
    deactivateApp = async (my_app: t.IAppData) => {
        try {
            await getBackend().apps.setInactive({ id: my_app.uuid });
            await this.loadApps();
        } catch (err) {
            this.setState({ err_msg: err.message });
        }
    };

    renderErrorMessage = () => {
        return (this.state.err_msg && this.state.err_msg.length > 0 &&
            <ErrorComponent msg={this.state.err_msg} type="warning" />
        );
    };

    renderApiKey = () => {

        const value = "UUID: " + this.state.new_uuid + "\n" + "API key: " + this.state.new_api_key;

        return (this.state.new_api_key && this.state.new_api_key.length > 0 &&
            <ErrorComponent msg={value} type="info" onlyDiv={true} />
        );
    };

    changeTitle = (event: Event) => {
        if (event.currentTarget instanceof HTMLInputElement) {
            // read new values
            const target = event.currentTarget;
            const value = target.value;
            // update new datasource placeholder
            this.setState({ new_app_title: value });
        }
    };

    handleCheckboxChange = (event: Event) => {
        if (event.currentTarget instanceof HTMLInputElement) {
            // read new values
            const target = event.currentTarget;
            let value = target.checked;
            this.setState({ is_external_app: value });
        }
    };

    render() {
        const isExternalApp = (app: t.IAppData): boolean => {
            return ct.APP_TAGS_ACCESS.is_external(app.tags);
        };
        const appList = this.state.apps.map(x => {
            return (
                <div key={x.uuid} className="my-app-item">
                    <div className="float-right">
                        {x.active && <button className="btn" onClick={() => this.deactivateApp(x)}>{translate("common.deactivate", "Deactivate")}</button>}
                        {!x.active && <button className="btn" onClick={() => this.activateApp(x)}>{translate("common.activate", "Activate")}</button>}
                        <button className="btn" onClick={() => this.regenerateExistingApiKey(x)}>{translate("common.regenerate_api_key", "Regenerate API key")}</button>
                    </div>
                    <div className="bold">{x.title}</div>
                    <div>
                        {x.active && <span className="badge badge-success">{translate("common.active", "Active")}</span>}
                        {!x.active && <span className="badge badge-secondary">{translate("common.inactive", "Inactive")}</span>}
                        {isExternalApp(x) && <span className="badge badge-secondary">{translate("common.external", "External")}</span>}
                        {" \u00b7 "}
                        <span className="light">{x.uuid}</span>
                    </div>
                </div>);
        });
        return (
            <div className="container py-3">
                <div className="row">
                    <div className="col-md-12">
                        <div className="white_box my-apps">
                            {!this.state.is_adding &&
                                <div>
                                    <div className="float-right">
                                        <button className="btn" onClick={this.showAddApp}><FormattedMessage id="Settings.register_new_app" defaultMessage="Register new app" /></button>
                                    </div>
                                    <h4>{translate("Header.menu.apps", "Apps")}</h4>
                                    {this.renderApiKey()}
                                    <div>
                                        {appList.length === 0 && translate("common.no_apps_registered", "No apps registered.")}
                                        {appList}
                                    </div>
                                </div>
                            }
                            {this.state.is_adding &&
                                <div className="tab-content">
                                    <h4>
                                        <FormattedMessage id="Settings.register_new_app" defaultMessage="Register new app" />
                                    </h4>
                                    {this.renderErrorMessage()}
                                    <div className="form-group">
                                        <label>
                                            <FormattedMessage id="common.title" defaultMessage="Title" />:
                                        </label>
                                        <input name="title" type="text" disabled="" className="form-control"
                                            value={this.state.new_app_title} onChange={this.changeTitle} />
                                    </div>
                                    <div className="form-group">
                                        <label>
                                            <FormattedMessage id="Settings.external_app" defaultMessage="External app" />:
                                        </label>
                                        <div>
                                            <Toggle
                                                name="is_admin"
                                                checked={this.state.is_external_app}
                                                onChange={this.handleCheckboxChange} />
                                        </div>
                                    </div>
                                    <div>
                                        <button className="btn_save btn btn-default" onClick={this.performAddApp}>
                                            <FormattedMessage id="common.register" defaultMessage="Register" />
                                        </button>
                                        <button className="btn_cancel btn" onClick={this.cancelAddApp}>
                                            <FormattedMessage id="common.cancel" defaultMessage="Cancel" />
                                        </button>
                                    </div>
                                    {this.renderApiKey()}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}

export default MyApps;
