// @flow

/** Well-known message types */
export const MSG_TYPES = {
    auth_token_refreshed: "auth_token_refreshed",
    login: "login",
    logout: "logout",
    logout_request: "logout_request",
    manufacturing_data_updated: "manufacturing_data_updated",
    modal_loader: "modal_loader",
    redirect_to_login: "redirect_to_login",
    reload_shift_orders: "reload_shift_orders",
    user_profile_reloaded: "user_profile_reloaded",
    weekly_realization_export_to_xlsx: "weekly_realization_export_to_xlsx",
    change_orders_shifts_view_template: "change_orders_shifts_view_template",
    export_worker_assignments_for_print: "export_worker_assignments_for_print",
    export_people_planning_table: "export_people_planning_table",
    user_tickets_reloaded: "user_tickets_reloaded",
};

/** Handler of subscription */
export type SubHandler = (any) => Promise<void>;

type Subscription = {
    token: string,
    handler: SubHandler
}

/** Simple class for publish-subscribe pattern */
export class PubSub {

    subscriptions: Map<string, Subscription[]>;
    counter: number;

    constructor() {
        this.subscriptions = new Map();
        this.counter = 1;
    }

    subscribe(topic: string, handler: SubHandler, unique: boolean = false): string {
        if (!handler) {
            return "";
        }

        const subs = this.subscriptions.get(topic) || [];

        if (unique) {
            this.subscriptions.set(topic, [{ token: "uuid_0", handler }]);
            return "uuid_0";
        }

        const token = "uuid_" + (this.counter++);
        subs.push({ token, handler });
        this.subscriptions.set(topic, subs);
        return token;
    }

    unsubscribe(token: string): void {
        const topics = this.subscriptions.keys();
        for (const topic of topics) {
            const subs = this.subscriptions.get(topic) || [];
            const new_subs = subs.filter(x => x.token !== token);
            this.subscriptions.set(topic, new_subs);
        }
    }

    publish(topic: string, payload: any): void {
        const subs = this.subscriptions.get(topic) || [];
        for (const sub of subs) {
            setTimeout(async () => {
                try {
                    await sub.handler(payload);
                } catch (err) {
                    console.error("Error in pub-sub handler");
                    console.error(err);
                }
            }, 0);
        }
    }

    async publishAndWait(topic: string, payload: any): Promise<void> {
        const subs = this.subscriptions.get(topic) || [];
        for (const sub of subs) {
            await sub.handler(payload);
        }
    }
}

const pubsub_singleton = new PubSub();

/** Adds now subscription */
export function subscribe(topic: string, handler: SubHandler, unique: boolean = false): string {
    return pubsub_singleton.subscribe(topic, handler, unique);
}

/** Remove all subscriptions for this token. */
export function unsubscribe(token: string): void {
    pubsub_singleton.unsubscribe(token);
}

/** Function that notifies subscribers in a separate tick.
 * Call will NOT wait for execution, errors will not be propagated.
 * All subscribers will be notified.
 */
export function publish(topic: string, payload: any): void {
    // console.log("PubSub publish", topic, payload);
    pubsub_singleton.publish(topic, payload);
}

/** Function that notifies subscribers and waits for their execution.
 * Subscribers are notified sequentially and call waits until the last subscriber finishes the execution.
 * Errors are propagated to caller!
 */
export async function publishAndWait(topic: string, payload: any): Promise<void> {
    // console.log("PubSub publishAndWait", topic, payload);
    await pubsub_singleton.publishAndWait(topic, payload);
}
