// @flow
import * as React from "react";
import ReactRouterPropTypes from "react-router-prop-types";
import { FormattedMessage } from "react-intl";
import { renderToString } from "react-dom/server";

import { getBackend } from "../../lib/backend/Backend2";
import * as t from "../../lib/backend/manufacturing2.generated.types";
import * as ti from "../../lib/backend/ticketing.generated.types";
import * as BusinessLogic from "../../lib/BusinessLogic";
import { INSIGHT_TYPES, PLANT_TAGS_ACCESS, INSIGHT_TAGS_ACCESS } from "../../lib/ManufacturingTags.generated";
import { niceDate, createMap } from "../../lib/Util.jsx";
import * as Auth from "../../lib/Auth";
import { exportInsightsHTML } from "../../lib/Insights";

import TicketStatus from "../ticketing/TicketStatus";
import Loader from "../Loader";
import ErrorComponent from "../ErrorComponent";
import AlertModal from "../react/AlertModal";
import { translate, getLang, IntlProviderWrapper } from "../IntlProviderWrapper";

import UserStep from "../ticketing/UserStep";

import { Modal } from "react-bootstrap";

///////////////////////////////////////////////////////////////////////////////////////////////////////

const TOOL_SETUP_WORKFLOW_NAME = "tool_setup"; // TODO move to server?

function translatedInsightContent(insight: t.IEventDataEx) {
    const user_lang = getLang();
    if (insight.extra_data.title_lang && insight.extra_data.title_lang[user_lang]) {
        return (
            <div className="">
                <span>
                    <b> {insight.extra_data.title_lang[user_lang]} </b> - {insight.extra_data.description_lang[user_lang]}
                </span>
            </div>
        );
    } else {
        return (
            <div className="">
                <span>
                    <b> {insight.title} </b> - {insight.description}
                </span>
            </div>
        );
    }
}
///////////////////////////////////////////////////////////////////////////////////////////////////////

type IEventDataWithTicket = t.IEventDataEx & {
    ticket: ti.IGetTicketBasicInfoRes | null;
    can_print_tool_change: boolean;
};

type Props = {
    history: ReactRouterPropTypes.history,
    match: ReactRouterPropTypes.match,
    plant_uuid: string,
    line_group_uuid: string,
    from?: number
};

type State = {
    insights_all: IEventDataWithTicket[],
    insights_fetched: boolean,
    error: string,
    has_ticketing_workflow: boolean,
    is_ticketing_user: boolean,
    show_modal: boolean,
    modal_title: string | null,
    modal_text: string,
    report1: t.ISimulationReportData | null,
    report2: t.ISimulationReportData | null,
    show_modal_ticket_start: boolean,
    insight: t.IEventDataEx | null,
    ticket_uuid: string | null,
    permanent_insight_uuid: string | null,
    ticket: ti.IGetTicketBasicInfoRes | null,
    wait_for: ti.IWaitForIndication | null
};

class InsightsViewToolSetup extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        const state: State = {
            insights_all: [],
            insights_fetched: false,
            has_ticketing_workflow: false,
            is_ticketing_user: false,
            show_modal: false,
            modal_title: null,
            modal_text: "",
            error: "",
            report1: null,
            report2: null,
            show_modal_ticket_start: false,
            insight: null,
            ticket_uuid: "",
            permanent_insight_uuid: "",
            ticket: null,
            wait_for: null,
        };
        this.state = state;
    }

    async componentDidMount() {
        await BusinessLogic.waitForManufacturingAsync();
        await this.checkTicketing();
        await this.reloadInsights();
    }

    async componentDidUpdate(prev_props: Props) {
        if (
            prev_props.plant_uuid !== this.props.plant_uuid ||
            prev_props.line_group_uuid !== this.props.line_group_uuid
        ) {
            await this.reloadInsights();
        }
    }

    async checkTicketing() {
        const wfs = await getBackend().ticketing.getWorkflows({});
        const has_ticketing_workflow = wfs.workflows.some(x => x.name == TOOL_SETUP_WORKFLOW_NAME);

        const is_ticketing_user = Auth.isInRole(Auth.ROLE_TICKET_USER);

        this.setState({ has_ticketing_workflow, is_ticketing_user });
    }

    async reloadInsights() {
        try {
            // prepare search criteria for relevant insights
            let from = this.props.from || (new Date().getTime());
            const insight_criteria: t.IGetInsightsReq = {
                from,
                types: [INSIGHT_TYPES.tool_setup]
            };
            // get all lines for the line group
            let line_group = BusinessLogic.getLineGroupForUser(this.props.line_group_uuid);
            if (line_group === null) { return; }
            const line_uuids = line_group ? line_group.line_uuids : [];
            // add to search criteria
            insight_criteria.lines = line_uuids;

            // retrieve insights
            const res = await getBackend().manufacturing.getInsights(insight_criteria);
            let insights_all = res.insights.map(x => {
                const res: IEventDataWithTicket = {
                    ...x,
                    ticket: null,
                    can_print_tool_change: false
                };
                return res;
            }).sort((a, b) => a.ts - b.ts);

            // check if permanent ids exist
            if (this.state.has_ticketing_workflow) {
                const map_external_id2insight = createMap(insights_all, x => x.external_id);
                const external_ids = res.insights.map(x => x.external_id);
                const res2 = await getBackend().manufacturing.searchPermanentInsights({ external_ids });
                for (const permanent of res2.hits) {
                    const ticket_uuid = permanent.tags.ticket_uuid;
                    if (ticket_uuid == undefined) {
                        continue;
                    }
                    try {
                        const res_ticket = await getBackend().ticketing.getTicketBasicInfo({ uuid: ticket_uuid });
                        const hit = map_external_id2insight.get(permanent.external_id);
                        if (hit != undefined) {
                            hit.ticket = res_ticket;
                        }
                    } catch (err) {
                        // write to console but don't do anything
                        console.log(err);
                    }
                }
            }

            const { plants } = await getBackend().manufacturing.getPlants({});
            const plants_with_tool_change = plants
                .filter(x => PLANT_TAGS_ACCESS.enable_tool_transfer_print(x.tags))
                .map(x => x.uuid);
            const plants_with_tool_change_set = new Set < string > (plants_with_tool_change);
            for (const insight of insights_all) {
                const flag = plants_with_tool_change_set.has(INSIGHT_TAGS_ACCESS.plant_uuid(insight.tags));
                insight.can_print_tool_change = flag;
            }

            this.setState({
                insights_all,
                insights_fetched: true,
                report1: res.report1,
                report2: res.report2
            });
        } catch (e) {
            this.setState({ error: e.message });
        }
    }

    async startTicket(insight: t.IEventDataEx) {
        try {

            // make insight permanent
            const { permanent_insight_uuid } = await getBackend().manufacturing.createPermanentInsight({
                insight_uuid: insight.uuid
            });
            // create new ticket for this permanent insight
            const { ticket_uuid, wait_for } = await getBackend().ticketing.startTicket({
                name: TOOL_SETUP_WORKFLOW_NAME,
                field_values: [
                    { name: "pinsight_uuid", value: permanent_insight_uuid }
                ]
            });

            if (wait_for !== undefined) {
                this.setState({
                    show_modal_ticket_start: true,
                    wait_for
                });
            }

            // now also store reference to ticket into permanent insight
            await getBackend().manufacturing.setPermanentInsightTags({
                override: false,
                uuid: permanent_insight_uuid,
                tags: { ticket_uuid }
            });

            this.setState({
                permanent_insight_uuid: permanent_insight_uuid,
                ticket_uuid: ticket_uuid,
                insight: insight
            });

            await this.reloadInsights();

        } catch (err) {
            this.setState({
                show_modal: true,
                show_modal_ticket_start: false,
                modal_title: translate("ToolSetup.ticket_error_during_creation", "Error while creating ticket"),
                modal_text: err.message
            });
        }
    }

    renderActions(insight: IEventDataWithTicket): any[] {
        const res: any[] = [];

        // show ticketing actions
        if (this.state.is_ticketing_user && this.state.has_ticketing_workflow) {
            if (insight.ticket != null) {
                res.push(<TicketStatus
                    key={"btn-lbl-1" + insight.uuid}
                    bgcolor={insight.ticket.status_bgcolor}
                    color={insight.ticket.status_color}
                    is_closed={insight.ticket.is_closed}
                    is_error={insight.ticket.is_error}
                    status={insight.ticket.status_title}
                />);
            } else {
                res.push(
                    <button
                        className="btn"
                        key={"btn-act-1" + insight.uuid}
                        onClick={() => this.startTicket(insight)}
                    >
                        {translate("ToolSetup.ticket_start", "Start ticket")}
                    </button>
                );
            }
        }
        // Optional button for printing tool change document
        if (insight.can_print_tool_change) {
            const exdata = insight.extra_data;
            const url = `/manufacturing/tool_transfer/${exdata.order_external_id}/${exdata.process_num}`;
            res.push(
                <button
                    className="btn ml-2"
                    key={"btn-act-2" + insight.uuid}
                    onClick={() => this.props.history.push(url)}
                >
                    {translate("ToolSetup.print_tool_transfer", "Print tool transfer")}
                </button>
            );
        }

        return res;
    }

    renderInsights(is_export?: boolean, num_days?: number): any {
        const rows = [];

        this.state.insights_all.forEach((insight, i) => {
            // const shift_str = `${shift_obj.shift_day + 1}`;
            rows.push(<tr key={i}>
                <td>{niceDate(new Date(insight.ts))}</td>
                <td>{new Date(insight.ts).toISOString().substring(11, 16)}</td>
                <td>{insight.extra_data.line_title || insight.tags.line_title || insight.tags.line_titles || ""}</td>
                <td>
                    <div>
                        {translatedInsightContent(insight)}
                    </div>
                </td>
                {!is_export && <td className="text-right"> {this.renderActions(insight)} </td>}
            </tr>
            );
        });

        return (
            <table className="table table-insights">
                <thead>
                    <tr>
                        <th className="font-size-14">
                            {translate("common.day", "Day")}
                        </th>
                        <th className="font-size-14">
                            {translate("common.hour", "Hour")}
                        </th>
                        <th className="font-size-14">
                            {translate("common.line", "Line")}
                        </th>
                        <th className="font-size-14">
                            {translate("common.insight", "Insight")}
                        </th>
                        {!is_export && <th className="font-size-14"></th>}
                    </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </table>
        );
    }

    onDownloadHTML() {
        exportInsightsHTML(renderToString(
            <IntlProviderWrapper>
                {this.renderInsights(true, 5)}
            </IntlProviderWrapper>
        ));
    }

    async handleSave() {

        const { insight } = this.state;

        if (insight === null) {
            return;
        }

        const { permanent_insight_uuid, ticket_uuid } = this.state;


        if (ticket_uuid && permanent_insight_uuid) {
            // now also store reference to ticket into permanent insight
            await getBackend().manufacturing.setPermanentInsightTags({
                override: false,
                uuid: permanent_insight_uuid,
                tags: { ticket_uuid }
            });

            this.setState({
                show_modal_ticket_start: false,
                ticket_uuid: null
            }, await this.reloadInsights());
        }
    }

    modalAssignTicket = () => {

        const insight = this.state.insight;
        if (insight === null) {
            return null;
        }

        return (
            <Modal
                bsSize="medium"
                show={this.state.show_modal_ticket_start}
            >
                <Modal.Header>
                    <Modal.Title>
                        {insight.title}
                    </Modal.Title>
                    <button
                        type="button"
                        className="close"
                        onClick={() => this.setState({ show_modal_ticket_start: false })}
                    >
                        <span aria-hidden="true">×</span>
                        <span className="sr-only">
                            {translate("common.close", "Close")}
                        </span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <UserStep
                        ticket_uuid={this.state.ticket_uuid && this.state.ticket_uuid.length > 3 && this.state.ticket_uuid || ""}
                        is_kiosk={false}
                        wait_for={this.state.wait_for}
                        close={() => this.setState({ show_modal_ticket_start: false })}
                        is_open={this.state.show_modal_ticket_start}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={() => this.setState({ show_modal_ticket_start: false })} className="btn btn-outline-secondary">
                        <FormattedMessage id="common.back" defaultMessage="Back" />
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }

    render(): any {
        const rep1 = this.state.report1;
        const rep2 = this.state.report2;
        if (this.state.error) {
            return (
                <ErrorComponent msg={this.state.error} type="warning" />
            );
        } else if (this.state.insights_fetched && rep1 && rep2) {
            return (
                <article className="article">
                    <section id="statistics" className="data_sources">
                        <div className="vertical_tab">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                        <div className="card card-insights">
                                            <div className="card-body text-right">
                                                <button className="btn btn-primary" onClick={() => this.onDownloadHTML()}>
                                                    <FormattedMessage id="ShiftDetails.export_for_print" defaultMessage="Export for print" />
                                                </button>
                                            </div>
                                            <AlertModal
                                                text={this.state.modal_text}
                                                title={this.state.modal_title}
                                                onClose={() => this.setState({ show_modal: false })}
                                                show={this.state.show_modal}
                                            />
                                            {this.modalAssignTicket()}
                                            {this.renderInsights()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </article >
            )
        } else {
            return (
                <article className="article">
                    <section id="statistics" className="data_sources">
                        <Loader />
                    </section>
                </article>
            )
        }
    }
}
export default InsightsViewToolSetup;
