// @flow
import type { PayloadAction } from "../../../redux/types";
import * as t from "../../../../lib/backend/manufacturing2.generated.types";
import { deepClone } from "../../../../lib/Util";

export const UPDATE_MATERIALS: "update_materials" = "update_materials";
export const UPDATE_ORDERS: "update_orders" = "update_orders";

export type UpdateMaterialsAction = PayloadAction<typeof UPDATE_MATERIALS, t.IMaterialModel[]>;
export type UpdateOrdersAction = PayloadAction<typeof UPDATE_ORDERS, t.IOrderModelBase[]>;

export type GanttChartActions = UpdateMaterialsAction | UpdateOrdersAction;

export type GanttChartState = {
    materials: t.IMaterialModel[],
    orders: t.IOrderModelBase[]
};

const initial_state: GanttChartState = {
    materials: [],
    orders: []
};

export const ganttChartReducer = (state: GanttChartState, action: GanttChartActions): GanttChartState => {
    if (!state) {
        state = deepClone(initial_state);
    }

    if (action.type === UPDATE_MATERIALS) {
        return {
            ...state,
            materials: deepClone(action.data)
        };
    } else if (action.type === UPDATE_ORDERS) {
        return {
            ...state,
            orders: deepClone(action.data)
        };
    }

    return state;
};
