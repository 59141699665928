// @flow
import React from "react";
import { translate } from "../IntlProviderWrapper";

type Props = {
    ticket_uuid: string,
    onClickDetails: (uuid: string) => void,
    onClickEdit: (uuid: string) => void
};
type State = {};

class Cell extends React.Component<Props, State> {

    onClickDetails = () => {
        this.props.onClickDetails(this.props.ticket_uuid);
    }

    render() {
        return <div className="actions">
            <span title={translate("common.details", "Details")} onClick={this.onClickDetails}>
                <i className="fas fa-eye fa-2x"></i>
            </span>
        </div>
    }
}

type ActionCellArgs = {
    cell: any
}

export const ActionCell = (
    onClickDetails: (uuid: string) => void,
    onClickEdit: (uuid: string) => void
) => {
    return ({ cell }: ActionCellArgs) => {
        return <Cell ticket_uuid={cell.value} onClickDetails={onClickDetails} onClickEdit={onClickEdit} />
    }
}
