// @flow

// $FlowFixMe
import "../styles/app.scss";
// $FlowFixMe
import "../styles/grid_layout.scss"

import * as React from "react";
import { Route, Redirect, Switch} from "react-router-dom";
import ReactRouterPropTypes from "react-router-prop-types";
import { connect } from 'react-redux';
import Grid from "./Grid";
import MainContent from "./MainContent";
import MainLeftMenu from "./MainLeftMenu";

import * as Auth from "../lib/Auth";
import * as BusinessLogic from "../lib/BusinessLogic";
import { USER_TAGS } from "../lib/CommonTags.generated";
import type { IPlantData, ILineGroupData } from "../lib/backend/manufacturing2.generated.types";

import Header from "./Header";
import Home from "./Home";

import HomeView from "./manufacturing/HomeView";
import LinesView from "./manufacturing/LinesView";
import StocksView from "./manufacturing/StocksView";
import PeopleView from "./manufacturing/PeopleView";
import ToolsView from "./manufacturing/ToolsView";
import TicketingApp from "./ticketing/TicketingApp";

import InsightsPage from "./manufacturing/InsightsPage";
import Plants from "./manufacturing/Plants";
import LineGroups from "./manufacturing/LineGroups";
import { LineGroupCreate } from "./manufacturing/LineGroup.create";
import LineGroupEdit from "./manufacturing/LineGroup.edit";
import LineGroupMatrix from "./manufacturing/LineGroupMatrix";
import Lines from "./manufacturing/Lines";
import Models from "./manufacturing/Models";
import { LineCreate } from "./manufacturing/Line.create";
import LineEdit from "./manufacturing/Line.edit";
import LineKanbanEdit from "./manufacturing/Line.kanban";
import MaterialParallelCreate from "./manufacturing/MaterialParallel.create";
import MaterialParallelEdit from "./manufacturing/MaterialParallel.edit";
import MaterialEdit from "./manufacturing/MaterialEdit";
import MaterialPlantEdit from "./manufacturing/MaterialPlantEdit";
import PlantEdit from "./manufacturing/PlantEdit";
import Processes from "./manufacturing/Processes";
import People from "./manufacturing/People";
import PeopleCreate from "./manufacturing/PeopleCreate";
import PeopleEdit from "./manufacturing/PeopleEdit";
import PeopleDetailsStandalone from "./manufacturing/PeopleDetailsStandalone";
import BatchOperationsStandalone from "./manufacturing/BatchOperationsStandalone";
import BatchOperationsArchiveStandalone from "./manufacturing/BatchOperationsArchiveStandalone";
import KanbanStandalone from "./manufacturing/KanbanStandalone";
import BatchopGroups from "./manufacturing/BatchopGroups";
import { BatchopGroupCreate } from "./manufacturing/BatchopGroup.create";
import BatchopGroupEdit from "./manufacturing/BatchopGroup.edit";
import BatchopLocations from "./manufacturing/BatchopLocations";
import { BatchopLocationCreate } from "./manufacturing/BatchopLocation.create";
import BatchopLocationEdit from "./manufacturing/BatchopLocation.edit";
import StockLocations from "./manufacturing/StockLocations";
import { StockLocationEdit } from "./manufacturing/StockLocationEdit";

import ManufacturingAdmin from "./manufacturing/Admin";
import Users from "./Users";
import UserInfo from "./UserInfo";
import UserNew from "./User_new";
import Aka from "./Aka";
import Evaluation from "./manufacturing/Evaluation";

import Datasources from "./Datasources";
import Events from "./Events";
import DatasourceNew from "./DatasourceNew";
import Settings from "./Settings";
import AnalysisNew from "./AnalysisNew";
import AnalysisEdit from "./AnalysisEdit";
import MyApps from "./MyApps";

import DashboardStandalone from "./DashboardStandalone";
import DashboardEdit from "./Dashboard.edit";
import Dashboards from "./Dashboards";

import SysStatus from "./SysStatus";

import Oauth2Step2 from "./Oauth2Step2";
import Login from "./Login";
import Register from "./Register";
import RecoverPassword from "./RecoverPassword";
import ChangePasswordStandalone from "./ChangePasswordStandalone";
import ChangePasswordWithCookie from "./ChangePasswordWithCookie";
import TermsAndConditions from "./TermsAndConditions";
import PrivacyPolicy from "./PrivacyPolicy";
import Footer from "./Footer";

import { registerCurrentURL } from "../lib/Tracking";
import { subscribe, MSG_TYPES } from "../lib/PubSub";
import { LINE_GROUP_TAGS } from "../lib/ManufacturingTags.generated";
import ModalLoader from './ModalLoader';

import ToolTransfer from "./manufacturing/ToolTransfer";

import DemandForecasting from "./DemandForecasting";

import * as URLRedirect from "./URLHandler/Redirect";
import '@fortawesome/fontawesome-free/css/all.min.css';
import BlobStorageItems from "./BlobStorageItems";
import BackgroundJobs from "./BackgroundJobs";
import Audit from "./Audit";
import { AdminShell } from "./manufacturing/AdminShell";
import OrganisationEdit from "./manufacturing/OrganisationEdit";
import TwoFAStandalone from "./TwoFAStandalone";
import Electricity from "./Electricity";
import ManualEntryOrders from "./manufacturing/ManualEntryOrders";
import PlanningTablePerformance from "./react/performance/PlanningTablePerformance";
import { PLANT_TAGS_ACCESS } from "../lib/ManufacturingTags.generated";
import KioskUndefined from "./manufacturing/KioskUndefined";
import Materials from "./manufacturing/Materials";

import type { PersistentFiltersState } from "./redux/reducers/persistentFiltersReducer";

type Props = {
    history: ReactRouterPropTypes.history,
    location: ReactRouterPropTypes.location,
    persistent_filters: PersistentFiltersState
};

type State = {
    username: string,
    env_flag: string,
    show_manufacturing: boolean,
    // manufacturing terminal context
    plants: IPlantData[],
    plant_uuid: string,
    line_groups: ILineGroupData[],
    line_group_uuid: string,
    last_url: string
};

// list of URL paths that do not require user to be registered
const NO_LOGIN_PATHS = [
    "/login",
    "/register",
    "/recover_password",
    "/terms",
    "/privacy",
    "/dashboard-public",
    "/auth/callback/github",
    "/auth/callback/azure-ad",
    "/users/check-pwd-reset-with-cookie"
];

function noLoginRequired(path: string): boolean {
    if (path === "/") return false;
    return NO_LOGIN_PATHS.some(x => path.startsWith(x));
}

// list of URL paths that have fixed header
const FIXED_PATHS = [
    "/manufacturing",
    "/insights_page",
    "/resources",
    "/shifts",
    "/users",
    "/material_stock_forecast",
    "/kiosk/batch_operations",
    "/kiosk/batch_operations_archive",
    "/kiosk/kanban",
    "/kiosk/manual_entry_order",
    "/kiosk",
    "/events",
    "/datasources",
    "/dashboard",
    "/dashboards",
    "/demand-forecasting",
    "/background_jobs",
    "/long-term-stock-forecast",
    "/electricity"
];

export const MANUFACTURING_PREFIX = "/manufacturing";
export const DIGITAL_TWIN_PREFIX = "/digital-twin";
export const MANUFACTURING_SKIP_BACK = ["/manufacturing/admin", "/manufacturing/eval"];
export const ADMIN_PREFIX = "/admin";

function isFixedHeader(path: string): boolean {
    // for nested roots based on parameters
    // e.g. /alerts/12345
    let path_parts = path.split("/");
    if (path_parts.length > 1) {
        path = "/" + path_parts[1];
    }
    return FIXED_PATHS.includes(path);
}

// list of URL paths that have no header
const NO_HEADER_PATHS = ["/planning-table-performance"];

const NO_HEADER_PATHS_MULTIPART = ["/dashboard-public"];

function isNoHeader(path: string): boolean {
    // for nested roots based on parameters
    // e.g. /alerts/12345
    let path_parts = path.split("/");
    let was_multipart = false;
    if (path_parts.length > 1) {
        was_multipart = (path_parts.length > 2);
        path = "/" + path_parts[1];
    }

    return NO_HEADER_PATHS.includes(path) || (NO_HEADER_PATHS_MULTIPART.includes(path) && was_multipart);
}

class App extends React.Component<Props, State> {

    constructor() {
        super();
        // initialize authentification from the local storage
        const state: State = {
            username: "",
            env_flag: "",
            show_manufacturing: false,
            plants: [],
            plant_uuid: "",
            line_groups: [],
            line_group_uuid: "",
            last_url: "/manufacturing/home"
        };
        this.state = state;
        // if user was changed we need to reload the header and other stuff
        const handleUserChange = async () => {
            this.setState(
                { username: Auth.getLoggedinUser().username },
                () => {
                    this.load();
                }
            );
        }
        subscribe(MSG_TYPES.logout, handleUserChange);
        subscribe(MSG_TYPES.login, handleUserChange);
        subscribe(MSG_TYPES.user_profile_reloaded, handleUserChange);
        subscribe(MSG_TYPES.manufacturing_data_updated, handleUserChange);
    }

    async componentDidMount() {
        this.load();
    }

    getLineGroups = (plant_uuid: string) => {
        return BusinessLogic.getLineGroupsForUserPlant(plant_uuid).filter(lg => lg.tags.hide_in_navigation !== "true");
    }

    shouldComponentUpdate(prevProps, prevState) {
        if (JSON.stringify(this.state) === JSON.stringify(prevState) &&
            prevProps.history === this.props.history &&
            prevProps.location === this.props.location &&
            prevProps.persistent_filters === this.props.persistent_filters) {
            return false;
        }

        return true;
    }

    async load() {
        await BusinessLogic.waitForManufacturingAsync();

        try {
            // collect environment parameters
            const env_flag = BusinessLogic.getSysFlag("env");
            const show_manufacturing = BusinessLogic.displayFeature("manufacturing");
            // filtered out plants that have no line groups;
            const plants = [];
            for (const plant of BusinessLogic.getPlantsForUser()) {
                const line_groups = this.getLineGroups(plant.uuid);

                if (line_groups.length === 0) continue;
                plants.push(plant);
            }

            if (plants.length === 0) {
                console.log("User has permission to access NO plants");
                return;
            }

            let plant_uuid = this.props.persistent_filters.plant_uuid;
            let plant_changed = false;
            // check if the user still has permision to see the plant
            if (plants.findIndex(p => (p.uuid === plant_uuid)) === -1) {
                const last_plant = plants.find(p => p.uuid === localStorage.getItem("last-plant"));
                plant_uuid = last_plant ? last_plant.uuid : plants[0].uuid;
                plant_changed = true;
            }

            const line_groups = this.getLineGroups(plant_uuid);
            if (line_groups.length === 0) {
                console.log("User has permission to access NO line groups in selected plant");
                return;
            }

            let line_group_uuid = this.props.persistent_filters.line_group_uuid;
            let line_group_changed = false;
            // check if the user still has permission to see the line group
            if (line_groups.findIndex(lg => (lg.uuid === line_group_uuid)) === -1) {
                const last_linegroup = line_groups.find(lg => lg.uuid === localStorage.getItem("last-linegroup"));
                line_group_uuid = last_linegroup ? last_linegroup.uuid : line_groups[0].uuid;
                line_group_changed = true;
            }

            const plant_tags = BusinessLogic.getPlantTags(plant_uuid);
            const enable_home = PLANT_TAGS_ACCESS.new_feature_home_button(plant_tags);

            let last_url = this.state.last_url;

            if (!enable_home && last_url.includes("/manufacturing/home")) {
                last_url = "/manufacturing/lines";
            }

            this.setState({
                env_flag,
                show_manufacturing,
                plants,
                line_groups,
                last_url
            }, () => {
                if (plant_changed || line_group_changed) {
                    URLRedirect.persistentFilters(
                        this.props.history,
                        this.props.location.pathname + this.props.location.search,
                        { plant_uuid, line_group_uuid }
                    );
                } else {
                    this.returnLocation(this.props.location.pathname + this.props.location.search);
                }
            });
        } catch (err) {
            console.log(err);
        }
    }

    setPlant = (plant_uuid: string) => {
        // get list of linegroups for the plant, ignoring ones that are marked as not to show in navigation bar
        const line_groups = BusinessLogic.getLineGroupsForUserPlant(plant_uuid).filter(line_group =>
            (line_group.tags[LINE_GROUP_TAGS.hide_in_navigation] === undefined || line_group.tags[LINE_GROUP_TAGS.hide_in_navigation] === "false"));
        // make sure we have something
        if (line_groups.length === 0) {
            console.log("User has permission to access NO line groups in selected plant");
            return;
        }
        const line_group_uuid = line_groups[0].uuid;
        const is_on_planning_table = window.location.pathname.includes("/planning_table");
        if (is_on_planning_table) {
            localStorage.setItem("last-plant", plant_uuid);
            localStorage.setItem("last-linegroup", line_group_uuid);
            window.location.hash = "";
            window.location.reload();
        } else {
            URLRedirect.persistentFilters(
                this.props.history,
                this.props.location.pathname,
                {
                    plant_uuid,
                    line_group_uuid
                }
            );
            this.setState({ line_groups });
        }
    }

    setLineGroup = (line_group_uuid: string) => {
        const is_on_planning_table = window.location.pathname.includes("/planning_table");
        if (is_on_planning_table) {
            localStorage.setItem("last-linegroup", line_group_uuid);
            window.location.hash = "";
            window.location.reload();
        } else {
            URLRedirect.persistentFilters(
                this.props.history,
                this.props.location.pathname,
                {
                    line_group_uuid,
                    plant_uuid: this.props.persistent_filters.plant_uuid
                }
            );
        }
    }

    async componentDidUpdate(prev_props: Props, prev_state: State) {
        if (prev_state.username !== this.state.username && this.state.username) {
            await BusinessLogic.forceManufacturingCacheRefresh();
            return this.load();
        }
        const path = this.props.location.pathname;
        if (prev_props.location.pathname !== path) {
            this.returnLocation(path);
        }
    }

    returnLocation(path: string) {
        // handle users returning to manufacturing from other parts
        if (path.startsWith(MANUFACTURING_PREFIX)) {
            if (path === MANUFACTURING_PREFIX) {
                // no specific target, check if we have last position
                if ((this.state.last_url.length > MANUFACTURING_PREFIX.length)) {
                    this.props.history.push(this.state.last_url);
                }
            } else if (MANUFACTURING_SKIP_BACK.every(x => (x !== path))) {
                // we have a specific target, which is not on skip list
                this.setState({ last_url: path });
            }
        }
    }

    setLocalStorage = (): boolean => {
        const path = this.props.location.pathname;
        if (!path.includes("/admin") && !path.includes("/digital-twin")) {
            localStorage.setItem("grid-layout", "false");
            return false;
        } else {
            localStorage.setItem("grid-layout", "true");
        }
        return true;
    }

    render() {
        // get current path
        const path = this.props.location.pathname;
        registerCurrentURL();
        const show_header = !isNoHeader(path);

        // check if we have a kiosk user
        const is_kiosk = Auth.getUserTag(USER_TAGS.kiosk) === "true";
        // if we do, also check if we need a redirect
        if (is_kiosk) {
            const kiosk_screen = Auth.getUserTag(USER_TAGS.kiosk_screen);
            const kiosk_url = `kiosk/${kiosk_screen !== null ? kiosk_screen : "not_defined"}`;
            // kiosk can only watch dedicated screens or public dashboards
            if (!path.includes(kiosk_url) && !path.includes("dashboard-public")) {
                return <Redirect to={`/${kiosk_url}`} />;
            }
        }

        let component = null;
        const is_grid_layout = this.setLocalStorage();
        // check if we are logged in or are we asking for an open page
        if (Auth.isAuth()) {
            // user is logged in
            component = <Grid>
                    <ModalLoader key="global_modal" />
                    <Header history={this.props.history} location={path}
                        show={show_header} fixed={isFixedHeader(path)} username={this.state.username}
                        env_flag={this.state.env_flag} show_manufacturing={this.state.show_manufacturing}
                        plants={this.state.plants} plant_uuid={this.props.persistent_filters.plant_uuid} set_plant_uuid={this.setPlant}
                        line_groups={this.state.line_groups} line_group_uuid={this.props.persistent_filters.line_group_uuid}
                        set_line_group_uuid={this.setLineGroup} key="header" />
                    {is_grid_layout && <MainLeftMenu history={this.props.history} />}
                    <MainContent>
                        <Switch>
                            <Route path="/" exact={true} component={Home} key="0" />

                            { /*manufacturing */}
                            <Route path="/manufacturing/home/:selected_view?/:view_params?" exact={true} key="1004" render={(props) =>
                                <HomeView
                                    {...props}
                                    plant_uuid={this.props.persistent_filters.plant_uuid}
                                    line_group_uuid={this.props.persistent_filters.line_group_uuid}
                                />}
                            />
                            <Route path="/manufacturing/lines/:selected_view?/:view_params?" exact={true} key="1000" render={(props) =>
                                <LinesView
                                    {...props}
                                    plant_uuid={this.props.persistent_filters.plant_uuid}
                                    line_group_uuid={this.props.persistent_filters.line_group_uuid}
                                />}
                            />
                            <Route path="/manufacturing/stocks/:selected_view?" exact={true} key="1001" render={(props) =>
                                <StocksView {...props} plant_uuid={this.props.persistent_filters.plant_uuid} line_group_uuid={this.props.persistent_filters.line_group_uuid} />}
                            />
                            <Route path="/manufacturing/people/:selected_view?/:view_params?" exact={true} key="1002" render={(props) =>
                                <PeopleView {...props} plant_uuid={this.props.persistent_filters.plant_uuid} line_group_uuid={this.props.persistent_filters.line_group_uuid} />}
                            />
                            <Route path="/manufacturing/tools/:selected_view?" exact={true} key="1003" render={(props) =>
                                <ToolsView {...props} plant_uuid={this.props.persistent_filters.plant_uuid} line_group_uuid={this.props.persistent_filters.line_group_uuid} />}
                            />
                            <Route path="/insights_page" component={InsightsPage} key="131" />

                            { /* manufacturing batch operations kiosk mode */ }
                            <Route path="/kiosk/batch_operations/:group_uuid?" component={BatchOperationsStandalone} key="600" />
                            <Route path="/kiosk/batch_operations_archive/:group_uuid" component={BatchOperationsArchiveStandalone} key="602" />
                            <Route path="/kiosk/kanban" component={KanbanStandalone} key="604" />
                            <Route path="/kiosk/ticketing" component={TicketingApp} key="605" />
                            <Route path="/kiosk/manual_entry_order" render={() => <ManualEntryOrders is_kiosk={true} line_group_uuid={""} />} key="606" />
                            <Route path="/kiosk/undefined" component={KioskUndefined} key="607" />
                            <Route path="/ticketing" component={TicketingApp} key="608" />

                            { /* manufacturing admin */ }
                            <Route path="/manufacturing/admin" component={ManufacturingAdmin} key="110" />
                            <Route path="/aka" component={Aka} key="500" />

                            { /* datasources */ }
                            <Route path="/settings" component={Settings} key="204" />
                            <Route path="/analysis_new/:ds_uuid" component={AnalysisNew} key="205" />
                            <Route path="/analysis_edit/:an_uuid" component={AnalysisEdit} key="206" />

                            { /* dashboards */ }
                            <Route path="/dashboard/:uuid" key="14.0" render={(routeProps) =>
                                <DashboardStandalone {...routeProps} show_filter_bar={true} />}
                            />
                            <Route path="/dashboard-public/:uuid" key="14.1" render={(routeProps) =>
                                <DashboardStandalone {...routeProps} show_filter_bar={true} />}
                            />

                            { /* demand forecasting */ }
                            <Route path="/demand-forecasting/:plant_external_id?/:material_external_id?" component={DemandForecasting} key="650" />

                            { /* misc */ }
                            <Route path="/change_password" component={ChangePasswordStandalone} key="700" />
                            <Route path="/manufacturing/tool_transfer/:order_external_id/:process_num" component={ToolTransfer} key="701" />
                            <Route path="/activate_twofa" component={TwoFAStandalone} key="702" />

                            { /* admin */ }
                            <Route path="/admin/users" component={Users} key="400" />
                            <Route path="/admin/admin_shell" component={AdminShell} key="621" />
                            <Route path="/admin/blob_storage_items" component={BlobStorageItems} key="207" />
                            <Route path="/admin/background_jobs" component={BackgroundJobs} key="208" />
                            <Route path="/admin/audit" component={Audit} key="209" />
                            <Route path="/admin/dashboards" component={Dashboards} key="15" />
                            <Route path="/admin/dashboard_edit/:uuid" component={DashboardEdit} key="14.2" />
                            <Route path="/admin/dashboard_new" component={DashboardEdit} key="14.3" />
                            <Route path="/admin/events/:event_uuid?" component={Events} key="202" />
                            <Route path="/admin/datasources/:handle?" component={Datasources} key="200" />
                            <Route path="/admin/user_info/:user_uuid" component={UserInfo} key="401" />
                            <Route path="/admin/user_new" component={UserNew} key="410" />
                            <Route path="/admin/datasource_new" component={DatasourceNew} key="203" />
                            <Route path="/admin/settings" component={ManufacturingAdmin} key="110" />
                            <Route path="/admin/apps" component={MyApps} key="113" />
                            <Route path="/admin" component={SysStatus} key="620" />
                            <Route path="/planning-table-performance" component={PlanningTablePerformance} key="623" />

                            { /* Digital twin */ }
                            <Route path="/digital-twin/resources/plants/:uuid?" component={Plants} key="30" />
                            <Route path="/digital-twin/resources/line_groups/:uuid?" render={props => <LineGroups {...props} />} key="90" />
                            <Route path="/digital-twin/resources/line_group/create" component={LineGroupCreate} key="94" />
                            <Route path="/digital-twin/resources/line_group/edit/:uuid" component={LineGroupEdit} key="98" />
                            <Route path="/digital-twin/resources/line_group/matrix/:uuid" component={LineGroupMatrix} key="99" />
                            <Route path="/digital-twin/resources/lines/:uuid?" render={props => <Lines {...props} />} key="20" />
                            <Route path="/digital-twin/resources/line/create" component={LineCreate} key="21" />
                            <Route path="/digital-twin/resources/line/edit/:uuid" component={LineEdit} key="24" />
                            <Route path="/digital-twin/resources/line/kanban/:uuid" component={LineKanbanEdit} key="25" />
                            <Route path="/digital-twin/resources/materials/:uuid?" render={props => <Materials {...props} />} key="40" />
                            <Route path="/digital-twin/resources/material/create" component={MaterialParallelCreate} key="41" />
                            <Route path="/digital-twin/resources/material_parallel/edit/:uuid?" component={MaterialParallelEdit} key="42" />
                            <Route path="/digital-twin/resources/material/edit/:uuid?" component={MaterialEdit} key="43" />
                            <Route path="/digital-twin/resources/material_plant/edit/:material_uuid/:plant_uuid?" component={MaterialPlantEdit} key="44" />
                            <Route path="/digital-twin/resources/plant/edit/:uuid?" component={PlantEdit} key="45" />
                            <Route path="/digital-twin/resources/processes/:uuid?" component={Processes} key="50" />
                            <Route path="/digital-twin/resources/people/:uuid?" component={People} key="70" />
                            <Route path="/digital-twin/resources/person/edit/:uuid" component={PeopleEdit} key="71" />
                            <Route path="/digital-twin/resources/person/create" component={PeopleCreate} key="72" />
                            <Route path="/digital-twin/resources/person/view/:uuid" component={PeopleDetailsStandalone} key="73" />
                            <Route path="/digital-twin/resources/batchop_groups/:uuid?" component={BatchopGroups} key="60" />
                            <Route path="/digital-twin/resources/batchop_group/create" component={BatchopGroupCreate} key="64" />
                            <Route path="/digital-twin/resources/batchop_group/edit/:uuid" component={BatchopGroupEdit} key="68" />
                            <Route path="/digital-twin/resources/batchop_locations/:uuid?" component={BatchopLocations} key="61" />
                            <Route path="/digital-twin/resources/batchop_location/create" component={BatchopLocationCreate} key="65" />
                            <Route path="/digital-twin/resources/batchop_location/edit/:uuid" component={BatchopLocationEdit} key="69" />
                            <Route path="/digital-twin/resources/stock_locations/:uuid?" render={props => <StockLocations {...props} />} key="62" />
                            <Route path="/digital-twin/resources/stock_location/edit/:uuid?" component={StockLocationEdit} key="63" />
                            <Route path="/digital-twin/resources/organisation" component={OrganisationEdit} key="523" />
                            <Route path="/digital-twin/models/eval" component={Evaluation} key="111" />
                            <Route path="/digital-twin/models" render={props => <Models {...props} />} key="20" />
                            <Route path="/digital-twin/models/search" render={props => <Models {...props} />} key="641" />
                            <Route path="/digital-twin/electricity" component={Electricity} key="640" />
                            <Route path="/digital-twin" component={Plants} key="523" />
                        </Switch>
                    </MainContent>
                </Grid>
        } else if (noLoginRequired(path)) {
            // user is not logged in
            component = (
                [
                    <div id="wrapper" key="wrapper">
                        <Route
                            path="/dashboard-public/:uuid"
                            render={(routeProps) => <DashboardStandalone {...routeProps}
                                asPublic={true} show_filter_bar={true} />}
                            key="14"
                        />
                        <Route path="/auth/callback/:type" component={Oauth2Step2} key="4" />
                        <Route path="/login" component={Login} key="5" />
                        <Route path="/register" component={Register} key="6" />
                        <Route path="/recover_password" component={RecoverPassword} key="7" />
                        <Route path="/terms" component={TermsAndConditions} key="8" />
                        <Route path="/privacy" component={PrivacyPolicy} key="9" />
                        <Route path="/users/check-pwd-reset-with-cookie" component={ChangePasswordWithCookie} key="10" />
                        <Footer position="fixed" key="footer" />
                    </div>
                ]
            );
        } else {
            // we are not logged in and are asking for page that requires login
            component = (<Redirect to="/login" />);
        }

        return (
            <React.Fragment>
                {component}
            </React.Fragment>
        )
    }
}

export default connect(
    state => {
        return { persistent_filters: state.persistent_filters }
    },
    null
)(App);
