// @flow

import React from "react";
import { translate } from "../IntlProviderWrapper";

const MaterialStockForecastNoMaterials = () => {
    return (
        <tr>
            <td colSpan="100" style={{ paddingTop: "30px" }}>
                <p>{translate("Manufacturing.MaterialStockForecast.no_materials_to_show", "No materials to show")}</p>
            </td>
        </tr>
    );
}

export default MaterialStockForecastNoMaterials;
