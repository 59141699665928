// @flow
import React from "react";
import { classNames } from "../../../lib/Util";
import { ResizeLeftMenu, enableUserSelect, disableUserSelect } from "./reducers/BusinessLogic";

type Props = {
    show: boolean
};
type State = {
    is_divider_selected: boolean
};

export default class ResizeVerticalDivider extends React.Component<Props, State> {

    mouse_move_event = null;
    mouse_up_event = null;

    state = {
        is_divider_selected: false
    }

    componentDidMount() {
        this.mouse_move_event = window.addEventListener("mousemove", this.onMouseMove);
        this.mouse_up_event = window.addEventListener("mouseup", this.onMouseUp);
    }

    componentWillUnmount() {
        ResizeLeftMenu.onUnmount();
        window.removeEventListener("mousemove", this.mouse_move_event);
        window.removeEventListener("mousemove", this.mouse_up_event);
    }

    onMouseUp = (e: Event) => {
        if (this.state.is_divider_selected) {
            this.setState({
                is_divider_selected: false
            });
            enableUserSelect();
        }
    }

    onMouseMove = (e: Event) => {
        if (this.state.is_divider_selected) {
            // $FlowFixMe
            ResizeLeftMenu.onResize(e.x);
        }
    }

    onMouseDown = (e: Event) => {
        disableUserSelect();
        this.setState({ is_divider_selected: true });
    }

    render() {
        const width = ResizeLeftMenu.getWidth();
        return <div
            onMouseDown={this.onMouseDown}
            className={classNames("resize-vertical-divider", {
                "d-none": !this.props.show
            })}
            style={{left: (width - 5) + "px"}}
        />;
    }
}