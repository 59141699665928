// @flow
import * as React from "react";
import ReactRouterPropTypes from "react-router-prop-types";
import { Modal } from "react-bootstrap";

import {
    shiftNumber, getURLHashParamsMap, updateURLHash, stringCompare
} from "../../lib/Util";
import { translate, IntlProviderWrapper } from "../IntlProviderWrapper";
import {
    storeInsightsSelectionLineUUIDs,
    storeInsightsSelectionEventTypes,
    storeInsightsShowCheckboxesOption,
    setInsightSelectionLineUUIDsWIP,
    getInsightsSelectionWIP,
    getDefaultInsightTypesForUser,
    exportInsightsHTML
} from "../../lib/Insights";
import { INSIGHT_ACTIONS } from "../../lib/Models";
import type {
    ShiftNumber
} from "../../lib/Models";
// import { getBackend } from "../../lib/Backend";
import { getBackend as getBackend2 } from "../../lib/backend/Backend2";
import * as t from "../../lib/backend/manufacturing2.generated.types";
import {
    filterLinesForUser, getLineGroupsForUser,
    getPlantsForUser,
    waitForManufacturingAsync
} from "../../lib/BusinessLogic";
import { FormattedMessage } from "react-intl";
import Checkbox from "../Checkbox";
import Insights from './Insights';
import Loader from "../Loader";
import { renderToString } from "react-dom/server";
import ReactDOM from 'react-dom';


const getNumberOfDays = (): number => {
    let num_days = Number(localStorage.getItem("insights-print-number-of-days"));
    return Number.isNaN(num_days) ? 0 : num_days;
}

const storeNumberOfDays = (num_days: number) => {
    localStorage.setItem("insights-print-number-of-days", `${num_days}`);
}

type PrintModalProps = {
    show: boolean,
    onPrint: (state: PrintModalState) => void,
    onHide: () => void
};

type PrintModalState = {
    num_days: number
};

class PrintModal extends React.Component<PrintModalProps, PrintModalState> {
    constructor(props: PrintModalProps) {
        super(props);
        this.state = {
            num_days: getNumberOfDays()
        };
    }

    handleDaysChange = (event: SyntheticEvent<HTMLInputElement>) => {
        const target = event.target;
        if (target instanceof HTMLInputElement) {
            this.setState({
                num_days: Number(target.value)
            });
        }
    }

    handleDaysFocus = (event: SyntheticEvent<HTMLInputElement>) => {
        const target = event.target;
        if (target instanceof HTMLInputElement) {
            target.select();
        }
    }

    handlePrintClick = () => {
        storeNumberOfDays(this.state.num_days);
        this.props.onPrint(this.state);
    }

    render() {
        return (
            <Modal bsSize="small" show={this.props.show} onHide={this.props.onHide}>
                <Modal.Header>
                    <Modal.Title>
                        {translate("common.print", "Print")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-group mb-0">
                            <label htmlFor="number-of-days-input">
                                {translate("common.number_of_days", "Number of days")} (0 = {translate("common.print_all", "Print all")})
                            </label>
                            <input
                                id="number-of-days-input"
                                className="form-control"
                                type="number"
                                value={`${this.state.num_days}`.replace(/^0+(.+)/, "$1")}
                                onChange={this.handleDaysChange}
                                onFocus={this.handleDaysFocus}
                                min={0}
                            />
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={this.props.onHide} className="btn btn-outline-primary">
                        {translate("common.cancel", "Cancel")}
                    </button>
                    <button onClick={this.handlePrintClick} className="btn btn-outline-primary">
                        {translate("common.print", "Print")}
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }
}

type Props = {
    history: ReactRouterPropTypes.history,
    insights: Array<t.IEventDataEx>,
    reportid: string,
    year: number,
    week: number,
    show_filter: boolean,
    show_only_future: boolean,
    show_links: boolean,
    show_actions: boolean,
    reverse_order?: boolean,
    allowed_actions?: $Keys<typeof INSIGHT_ACTIONS>[],
    onActionSelect?: (action: t.IInsightAction, insight: t.IEventDataEx) => void
};

type IUuidSelected = {
    [key: string]: boolean
};

type ILocalInsightType = {
    type: string,
    name: string
};

type ILocalLine = {
    uuid: string,
    title: string,
    plant_uuid: string
};

type State = {
    // if only future insights should be displayed in insights table
    insight_types: ILocalInsightType[],
    lines: ILocalLine[],
    line_groups: t.ILineGroupData[],
    plants: t.IPlantData[],
    selected_insight_types: IUuidSelected,
    selected_line_uuids: IUuidSelected,
    selected_plant_uuids: IUuidSelected,
    selected_line_group_uuids: IUuidSelected,
    insights_show_checkboxes: boolean,
    insight_actions: Array<t.IInsightAction>,
    insight_actions_uuid: string,
    loading_actions: boolean,
    actions_error: boolean,
    allowed_plants: string[],
    allowed_line_groups: string[],
    loading: boolean,
    show_print_modal: boolean
};

// Displays the list of insights
class InsightsList extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        const show_checkboxes = false; // getInsightsShowCheckboxesOption();
        this.state = {
            insight_types: [],
            plants: [],
            line_groups: [],
            lines: [],
            selected_insight_types: {},
            selected_line_uuids: {},
            selected_plant_uuids: {},
            selected_line_group_uuids: {},
            insights_show_checkboxes: show_checkboxes,
            insight_actions_uuid: "",
            insight_actions: [],
            loading_actions: false,
            actions_error: false,
            allowed_plants: [],
            allowed_line_groups: [],
            loading: true,
            show_print_modal: false
        };
    }

    arrayToSelectedCheckboxesMap(array: Array<string>) {
        if (array) {
            return array.reduce((map, key) => {
                map[key] = true;
                return map
            }, {});
        }
        return {};
    }

    getInsightTypeToURLMappings(insight_types: Array<string>) {
        return insight_types.reduce((hashmap, item) => {
            const urlString = item.replace(/_/g, "-").replace(/\./g, "-");
            hashmap[item] = urlString;
            return hashmap;
        }, {});
    }

    getInsightTypeFromURLMappings(insight_types: any) {
        return insight_types.reduce((hashmap, item) => {
            const urlString = item.type.replace(/_/g, "-").replace(/\./g, "-");
            hashmap[urlString] = item.type;
            return hashmap;
        }, {});
    }

    updateURLHash() {
        if (!getInsightsSelectionWIP()) {
            let lines_selected: any = {}; // getInsightSelectionLineUUIDs();
            let insights_selected: any = {}; // getInsightSelectionEventTypes();
            let lines: string[] = [];
            let insights: string[] = [];
            const urlHashParams: any = {};
            if (lines_selected) {
                lines = Object.keys(lines_selected).filter((key) => lines_selected[key]).sort();
            }
            if (insights_selected) {
                insights = Object.keys(insights_selected).filter((key) => insights_selected[key]).sort();
                const insightsURLMapping = this.getInsightTypeToURLMappings(insights);
                insights = insights.map(item => insightsURLMapping[item]);
            }
            urlHashParams.line_uuids = lines;
            urlHashParams.insight_types = insights;

            updateURLHash(urlHashParams);
        }
    }

    componentWillUnmount() {
        const el = ReactDOM.findDOMNode(this);

        if (el instanceof HTMLElement) {
            el.removeEventListener('export-html-insights', this.handleExportHTML);
        }
    }

    async componentDidMount() {
        const el = ReactDOM.findDOMNode(this);

        if (el instanceof HTMLElement) {
            el.addEventListener('export-html-insights', this.handleExportHTML);
        }

        try {
            await waitForManufacturingAsync();
            // get list of all lines

            setInsightSelectionLineUUIDsWIP(true);

            // select all plants that user has access to
            const selected_plant_uuids: IUuidSelected = {};
            let plants = getPlantsForUser();
            plants.forEach(plant => selected_plant_uuids[plant.uuid] = true);

            // select all linegroup that user has access to
            const linegroups_selected: IUuidSelected = {};
            const line_groups = getLineGroupsForUser();
            line_groups.forEach(x => {
                linegroups_selected[x.uuid] = true;
            })

            let selected_line_uuids: IUuidSelected = {};
            const res_lines = await getBackend2().manufacturing.getLines({});
            const lines = filterLinesForUser(
                res_lines.lines.map(line => ({ uuid: line.uuid, title: line.title, plant_uuid: line.plant_uuid }))
            );
            lines.forEach(line => {
                selected_line_uuids[line.uuid] = true;
            });

            this.setState({
                lines,
                selected_line_uuids,
                selected_plant_uuids,
                plants,
                line_groups,
                selected_line_group_uuids: linegroups_selected
            });
            this.updateURLHash();

            const res2 = await getBackend2().manufacturing.getInsightTypes({});

            const insight_types = res2.insight_types
                .filter(insight => !!insight.code)
                .map(insight => ({ type: insight.code, name: insight.title }))
                .sort((a, b) => stringCompare(a.name, b.name));

            let selected_insight_types: any = {};
            if (this.props.show_filter) {
                const hashparams: any = getURLHashParamsMap();
                const paramsFromURL = Object.keys(hashparams).length > 0;
                if (paramsFromURL) {
                    selected_insight_types = this.arrayToSelectedCheckboxesMap(hashparams.insight_types);
                    const insightsURLMapping = this.getInsightTypeFromURLMappings(insight_types);
                    selected_insight_types = Object.keys(selected_insight_types)
                        .reduce((hashmap, item) => {
                            hashmap[insightsURLMapping[item]] = true;
                            return hashmap;
                        }, {});
                }
                const default_insights = getDefaultInsightTypesForUser();
                insight_types.forEach(insight => {
                    selected_insight_types[insight.type] = default_insights.indexOf(insight.type) >= 0;
                });
            } else {
                // just turn everything on
                insight_types.forEach(insight => {
                    selected_insight_types[insight.type] = true;
                });
            }
            this.setState({
                insight_types,
                selected_insight_types,
                loading: false
            });
            this.updateURLHash();
        } catch (error) {
            console.log(error);
        }
    }

    handlePlantChange = (event: Event) => {
        const target = event.target;
        if (target instanceof HTMLInputElement) {
            let { name } = target;
            name = name.replace("plant-checkbox-", "");
            this.setState(
                (prev_state: State) => {
                    const selected_plant_uuids = {
                        ...prev_state.selected_plant_uuids,
                        [name]: !prev_state.selected_plant_uuids[name]
                    };
                    prev_state.selected_plant_uuids = selected_plant_uuids;
                    // this.updateURLHash();
                    return prev_state;
                },
                () => { this.calculateLineFilters(); }
            );
        }
    };

    handleLinegroupChange = (event: Event) => {
        const target = event.target;
        if (target instanceof HTMLInputElement) {
            let { name } = target;
            name = name.replace("linegroup-checkbox-", "");
            this.setState(
                (prev_state: State) => {
                    const new_value = !prev_state.selected_line_group_uuids[name];
                    const selected_line_group_uuids = {
                        ...prev_state.selected_line_group_uuids,
                        [name]: new_value
                    };
                    prev_state.selected_line_group_uuids = selected_line_group_uuids;
                    this.state.line_groups.forEach(lg => {
                        if (lg.uuid === name) {
                            // enable/disable all lines that belong to this line_group
                            lg.line_uuids.forEach(line_uuid => {
                                prev_state.selected_line_uuids[line_uuid] = new_value;
                            })
                        }
                    });
                    //this.updateURLHash();
                    return prev_state;
                },
                () => { this.calculateLineFilters(); }
            );
        }
    };

    calculateLineFilters = () => {
        this.setState((prev_state: State) => {
            // first select all lines
            Object.keys(prev_state.selected_line_uuids)
                .forEach(uuid => { prev_state.selected_line_uuids[uuid] = true; });

            // new deselect lines for unselected line-groups and plants
            prev_state.line_groups.forEach(lg => {
                const new_value = prev_state.selected_line_group_uuids[lg.uuid];
                if (!new_value) {
                    // disable all lines that belong to this line_group
                    lg.line_uuids
                        .forEach(line_uuid => {
                            prev_state.selected_line_uuids[line_uuid] = false;
                        });
                }
            });
            prev_state.plants.forEach(plant => {
                const new_value = prev_state.selected_plant_uuids[plant.uuid];
                if (!new_value) {
                    // disable all lines that belong to this plant
                    prev_state.lines
                        .filter(line => line.plant_uuid === plant.uuid)
                        .forEach(line => {
                            prev_state.selected_line_uuids[line.uuid] = false;
                        });
                }
            });
            return prev_state;
        });
    }

    handleLineChange = (event: Event) => {
        const target = event.target;
        if (target instanceof HTMLInputElement) {
            let { name } = target;
            name = name.replace("line-checkbox-", "");
            this.setState((prev_state: State) => {
                const new_value = !prev_state.selected_line_uuids[name];
                const selected_line_uuids = {
                    ...prev_state.selected_line_uuids,
                    [name]: new_value
                };
                // storeInsightsSelectionLineUUIDs(selected_line_uuids);
                prev_state.selected_line_uuids = selected_line_uuids;
                this.updateURLHash();
                return prev_state;
            });
        }
    };

    handleTypeChange = (event: Event) => {
        const target = event.target;
        if (target instanceof HTMLInputElement) {
            let { name } = target;
            name = name.replace("type-checkbox-", "");
            this.setState((prev_state: State) => {
                const selected_insight_types = {
                    ...prev_state.selected_insight_types,
                    [name]: !prev_state.selected_insight_types[name]
                };
                // storeInsightsSelectionEventTypes(selected_insight_types);
                prev_state.selected_insight_types = selected_insight_types;
                this.updateURLHash();
                return prev_state;
            });
        }
    };

    setAllPlantsFilter = (value: boolean) => {
        this.setState(
            (prev_state: State) => {
                for (const field of Object.keys(prev_state.selected_plant_uuids)) {
                    prev_state.selected_plant_uuids[field] = value;
                    //storeInsightsSelectionLineUUIDs(prev_state.selected_plant_uuids);
                }
                //this.updateURLHash();
                return prev_state;
            },
            () => { this.calculateLineFilters(); }
        );
    };

    setAllLinegroupsFilter = (value: boolean) => {
        this.setState(
            (prev_state: State) => {
                for (const field of Object.keys(prev_state.selected_line_group_uuids)) {
                    prev_state.selected_line_group_uuids[field] = value;
                    //storeInsightsSelectionLineUUIDs(prev_state.selected_plant_uuids);
                }
                //this.updateURLHash();
                return prev_state;
            },
            () => { this.calculateLineFilters(); }
        );
    };

    setAllLinesFilter = (value: boolean) => {
        this.setState((prev_state: State) => {
            for (const field of Object.keys(prev_state.selected_line_uuids)) {
                prev_state.selected_line_uuids[field] = value;
                storeInsightsSelectionLineUUIDs(prev_state.selected_line_uuids);
            }
            this.updateURLHash();
            return prev_state;
        });
    };

    setAllTypesFilter = (value: boolean) => {
        this.setState((prev_state: State) => {
            for (const field of Object.keys(prev_state.selected_insight_types)) {
                prev_state.selected_insight_types[field] = value;
                storeInsightsSelectionEventTypes(prev_state.selected_insight_types);
            }
            this.updateURLHash();
            return prev_state;
        });
    };

    toggleFilters = (event: Event) => {
        event.preventDefault();
        this.setState((prev_state: State) => {
            prev_state.insights_show_checkboxes = !prev_state.insights_show_checkboxes;
            storeInsightsShowCheckboxesOption(prev_state.insights_show_checkboxes);
            return prev_state;
        });
    };

    highlightCurrShift(shift_obj: ShiftNumber) {
        const curr_shift_obj = shiftNumber(new Date());
        if ((shift_obj.shift === curr_shift_obj.shift) &&
            (shift_obj.week === curr_shift_obj.week) &&
            (shift_obj.year === curr_shift_obj.year)) {
            return "current-shift";
        } else {
            return "";
        }
    }

    renderPlantCheckboxes(plants: any) {
        const checkboxes = plants.map(plant => (
            <div className="col-sm-4 col-lg-4 col-xl-3" key={"plant-checkbox-w-" + plant.uuid}>
                <Checkbox
                    label={plant.title}
                    isSelected={this.state.selected_plant_uuids[plant.uuid]}
                    onCheckboxChange={this.handlePlantChange}
                    name={"plant-checkbox-" + plant.uuid}
                    key={"plant-checkbox-" + plant.uuid} />
            </div>
        )
        );
        if (this.state.insights_show_checkboxes) {
            const totalCounter = Object.keys(this.state.selected_plant_uuids).length;
            const selectedCounter = this.countSelectedCheckboxes(this.state.selected_plant_uuids);
            const selectAllActive = totalCounter && selectedCounter === totalCounter;
            const deselectAllActive = totalCounter && selectedCounter === 0;
            return (
                <div className="filter-group">
                    <div className="filter-group-header">
                        <h6><FormattedMessage id="common.plants" defaultMessage="Plants" /></h6>
                        <div className="filter-group-actions ml-auto">
                            <a className={selectAllActive ? "is-active" : null} onClick={() => { this.setAllPlantsFilter(true); }}>
                                <FormattedMessage id="common.select_all" defaultMessage="Select all" />
                            </a>
                            <a className={deselectAllActive ? "is-active" : null} onClick={() => { this.setAllPlantsFilter(false); }}>
                                <FormattedMessage id="common.deselect_all" defaultMessage="Deselect all" />
                            </a>
                        </div>
                    </div>
                    <div className="row">
                        {checkboxes}
                    </div>
                </div>
            );
        }
    }

    renderLinegroupCheckboxes(line_groups: any) {
        const checkboxes = line_groups.map(line_group => (
            <div className="col-sm-4 col-lg-4 col-xl-3" key={"linegroup-checkbox-w-" + line_group.uuid}>
                <Checkbox
                    label={line_group.title}
                    isSelected={this.state.selected_line_group_uuids[line_group.uuid]}
                    onCheckboxChange={this.handleLinegroupChange}
                    name={"linegroup-checkbox-" + line_group.uuid}
                    key={"linegroup-checkbox-" + line_group.uuid} />
            </div>
        )
        );
        if (this.state.insights_show_checkboxes) {
            const totalCounter = Object.keys(this.state.selected_line_group_uuids).length;
            const selectedCounter = this.countSelectedCheckboxes(this.state.selected_line_group_uuids);
            const selectAllActive = totalCounter && selectedCounter === totalCounter;
            const deselectAllActive = totalCounter && selectedCounter === 0;
            return (
                <div className="filter-group">
                    <div className="filter-group-header">
                        <h6><FormattedMessage id="common.linegroups" defaultMessage="Line-groups" /></h6>
                        <div className="filter-group-actions ml-auto">
                            <a className={selectAllActive ? "is-active" : null} onClick={() => { this.setAllLinegroupsFilter(true); }}>
                                <FormattedMessage id="common.select_all" defaultMessage="Select all" />
                            </a>
                            <a className={deselectAllActive ? "is-active" : null} onClick={() => { this.setAllLinegroupsFilter(false); }}>
                                <FormattedMessage id="common.deselect_all" defaultMessage="Deselect all" />
                            </a>
                        </div>
                    </div>
                    <div className="row">
                        {checkboxes}
                    </div>
                </div>
            );
        }
    }

    renderLineCheckboxes(lines: any) {
        const checkboxes = lines.map(line => (
            <div className="col-sm-4 col-lg-4 col-xl-3" key={"line-checkbox-w-" + line.uuid}>
                <Checkbox
                    label={line.title}
                    isSelected={this.state.selected_line_uuids[line.uuid]}
                    onCheckboxChange={this.handleLineChange}
                    name={"line-checkbox-" + line.uuid}
                    key={"line-checkbox-" + line.uuid} />
            </div>
        )
        );
        if (this.state.insights_show_checkboxes) {
            const totalCounter = Object.keys(this.state.selected_line_uuids).length;
            const selectedCounter = this.countSelectedCheckboxes(this.state.selected_line_uuids);
            const selectAllActive = totalCounter && selectedCounter === totalCounter;
            const deselectAllActive = totalCounter && selectedCounter === 0;
            return (
                <div className="filter-group">
                    <div className="filter-group-header">
                        <h6><FormattedMessage id="common.lines" defaultMessage="Lines" /></h6>
                        <div className="filter-group-actions ml-auto">
                            <a className={selectAllActive ? "is-active" : null} onClick={() => { this.setAllLinesFilter(true); }}>
                                <FormattedMessage id="common.select_all" defaultMessage="Select all" />
                            </a>
                            <a className={deselectAllActive ? "is-active" : null} onClick={() => { this.setAllLinesFilter(false); }}>
                                <FormattedMessage id="common.deselect_all" defaultMessage="Deselect all" />
                            </a>
                        </div>
                    </div>
                    <div className="row">
                        {checkboxes}
                    </div>
                </div>
            );
        }
    }

    renderInsightTypeCheckboxes(insightTypes: any) {
        const checkboxes = insightTypes.map(insight => (
            <div className="col-sm-4 col-lg-4 col-xl-3" key={"type-checkbox-w-" + insight.type}>
                <Checkbox
                    label={insight.name}
                    isSelected={this.state.selected_insight_types[insight.type]}
                    onCheckboxChange={this.handleTypeChange}
                    name={"type-checkbox-" + insight.type}
                    key={"type-checkbox-" + insight.type} />
            </div>)
        );
        if (this.state.insights_show_checkboxes) {
            const totalCounter = Object.keys(this.state.selected_insight_types).length;
            const selectedCounter = this.countSelectedCheckboxes(this.state.selected_insight_types);
            const selectAllActive = totalCounter && selectedCounter === totalCounter;
            const deselectAllActive = totalCounter && selectedCounter === 0;
            return (
                <div className="filter-group">
                    <div className="filter-group-header">
                        <h6><FormattedMessage id="common.insight_types" defaultMessage="Insight types" /></h6>
                        <div className="filter-group-actions ml-auto">
                            <a className={selectAllActive ? "is-active" : null} onClick={() => { this.setAllTypesFilter(true); }}>
                                <FormattedMessage id="common.select_all" defaultMessage="Select all" />
                            </a>
                            <a className={deselectAllActive ? "is-active" : null} onClick={() => { this.setAllTypesFilter(false); }}>
                                <FormattedMessage id="common.deselect_all" defaultMessage="Deselect all" />
                            </a>
                        </div>
                    </div>
                    <div className="row">
                        {checkboxes}
                    </div>
                </div>
            );
        }
    }

    countSelectedCheckboxes(checkboxesMap: any) {
        let selectedCounter = 0;
        Object.entries(checkboxesMap).forEach(
            ([key, value]) => {
                if (value) {
                    selectedCounter = selectedCounter + 1;
                }
            }
        );
        return selectedCounter;
    }

    checkBoxesSelectedOptionsMessage() {
        let totalCounter = Object.keys(this.state.selected_insight_types).length + Object.keys(this.state.selected_line_uuids).length;
        let selectedCounter = this.countSelectedCheckboxes(this.state.selected_insight_types) + this.countSelectedCheckboxes(this.state.selected_line_uuids);
        if (selectedCounter === totalCounter) {
            return "common.filters_selected_all";
        }
        if (selectedCounter === 0) {
            return "common.filters_selected_none";
        }
        return "common.filters_selected_some";
    }

    exportHTML = (num_days: number) => {
        exportInsightsHTML(renderToString(
            <IntlProviderWrapper>
                {this.renderInsights(true, num_days)}
            </IntlProviderWrapper>
        ));
    }

    handleExportHTML = () => {
        this.setState({
            show_print_modal: true
        });
    }

    handlePrintModalPrint = (state: PrintModalState) => {
        this.setState({
            show_print_modal: false
        });
        this.exportHTML(state.num_days);
    }

    handlePrintModalHide = () => {
        this.setState({
            show_print_modal: false
        });
    }

    renderInsights(is_export: boolean, num_days: number = 0) {
        let selected_insight_types = this.state.selected_insight_types;

        if (is_export) {
            for (const el in selected_insight_types) {
                selected_insight_types[el] = false;
            }

            selected_insight_types.tool_setup = true;
        }

        return (
            <table className="table table-insights">
                <thead>
                    <tr>
                        <th className="font-size-14">
                            {translate("common.shift", "Shift")}
                        </th>
                        <th className="font-size-14">
                            {translate("common.line", "Line")}
                        </th>
                        <th className="font-size-14">
                            {translate("common.insight", "Insight")}
                        </th>
                        {!is_export && this.props.show_actions !== false && <th className="font-size-14"></th>}
                    </tr>
                </thead>
                <tbody>
                    <Insights
                        insights={this.props.insights}
                        selected_line_uuids={this.state.selected_line_uuids}
                        selected_insight_types={selected_insight_types}
                        show_only_future={this.props.show_only_future}
                        history={this.props.history}
                        show_links={this.props.show_links}
                        insight_actions_uuid={this.state.insight_actions_uuid}
                        insight_actions={this.state.insight_actions}
                        reportid={this.props.reportid}
                        showActions={this.props.show_actions}
                        reverse_order={this.props.reverse_order}
                        is_export={is_export}
                        num_days={num_days}
                        allowed_actions={this.props.allowed_actions}
                        onActionSelect={this.props.onActionSelect}
                    />
                </tbody>
            </table>
        );
    }

    render() {
        return (
            <div id="insights-list" className="insights-list">
                {this.props.show_filter &&
                    <a className={"card-header bg-transparent d-flex align-items-center" + (this.state.insights_show_checkboxes ? " is-expanded" : "")} onClick={this.toggleFilters}>
                        {!this.state.insights_show_checkboxes && <FormattedMessage id="common.show_filters" defaultMessage="Show filters" />}
                        {this.state.insights_show_checkboxes && <FormattedMessage id="common.hide_filters" defaultMessage="Hide filters" />}
                        &nbsp;<span className="text-muted">(<FormattedMessage id={this.checkBoxesSelectedOptionsMessage()} defaultMessage="" />)</span>
                    </a>}
                <div className="card-body">
                    {this.props.show_filter && this.renderPlantCheckboxes(this.state.plants)}
                    {this.props.show_filter && this.renderLinegroupCheckboxes(this.state.line_groups)}
                    {this.props.show_filter && this.renderInsightTypeCheckboxes(this.state.insight_types)}
                    {
                        this.state.loading ?
                            <Loader /> :
                            this.renderInsights(false)
                    }
                </div>
                <PrintModal
                    show={this.state.show_print_modal}
                    onPrint={this.handlePrintModalPrint}
                    onHide={this.handlePrintModalHide}
                />
            </div>
        );
    }
}

export default InsightsList;
