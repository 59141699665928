// @flow
import * as React from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import Loader from "../../Loader";
import { translate } from "../../IntlProviderWrapper";
import { getBackend as getBackend2 } from "../../../lib/backend/Backend2";
import { ORDER_TYPE, PLAN_IMPORT_TYPE } from "../../../lib/ManufacturingConsts.generated";
import ErrorComponent from "../../ErrorComponent";
import * as BusinessLogic from "../../../lib/BusinessLogic";
import { JOB_STATUS } from "../../../lib/CommonConsts.generated";

import * as t from "../../../lib/backend/manufacturing2.generated.types";
import type { IGetJobStatusRes } from "../../../lib/backend/common.generated.types";
import type { ReduxState } from "./reducers/reducers";
import type { LineOrders } from "./reducers/linesOrders";

type Props = {
    import_type: string,
    plant_external_id: string,
    line_group_uuid: string,
    onCloseImportPlanOnDemand: () => void,
    lines_orders: LineOrders[],
    unscheduled_orders: LineOrders[],
    extra_lines: t.IExtraLines[],
    lol_is_loading: boolean
};

type State = {
    loading: boolean,
    error: string,
    success: boolean,
    job_status: IGetJobStatusRes | null
};

export class ImportPlanOnDemandJobHandler {

    static timeout = null;

    static async runImportPlanOnDemand(
        data: t.IRunImportPlanOnDemandReq,
        onSuccess: () => void,
        onFailure: (e: string) => void,
        onUpdate: (status: IGetJobStatusRes
    ) => void) {
        const res = await getBackend2().manufacturing.runImportPlanOnDemand(data);
        await ImportPlanOnDemandJobHandler.getImportPlanStatus(res.job_uuid, onSuccess, onFailure, onUpdate);
    }

    static clearTimeout = () => {
        if (ImportPlanOnDemandJobHandler.timeout) {
            clearTimeout(ImportPlanOnDemandJobHandler.timeout);
        }
    }

    static async getImportPlanStatus(job_uuid: string, onSuccess: any, onFailure: any, onUpdate: any) {
        try {
            ImportPlanOnDemandJobHandler.clearTimeout();
            const res = await getBackend2().common.getJobStatus({ id: job_uuid });
            onUpdate(res);
            const status = res.status
            if (status === JOB_STATUS.ended || status === JOB_STATUS.errored) {
                const res2 = await getBackend2().manufacturing.getImportPlanOnDemandResult({ job_uuid });
                if (status === JOB_STATUS.errored) {
                    return onFailure("Import failed due to an internal error");
                } else {
                    return onSuccess(res2.result);
                }
            } else {
                ImportPlanOnDemandJobHandler.timeout = setTimeout(() => {
                    ImportPlanOnDemandJobHandler.getImportPlanStatus(job_uuid, onSuccess, onFailure, onUpdate);
                }, 1500);
            }
        } catch (e) {
            console.error(e);
            onFailure(e.message);
        }
    }
}

export class ImportPlanOndemand extends React.Component<Props, State> {

    state = {
        success: false,
        error: "",
        job_status: null,
        loading: false
    }

    componentDidMount() {
        this.setState({
            success: false,
            error: "",
            job_status: null,
            loading: false
        });
    }

    componentWillUnmount() {
        ImportPlanOnDemandJobHandler.clearTimeout();
    }

    getImportPlanOndemandData = (): t.IRunImportPlanOnDemandReq => {
        const order_ids = new Set();
        const plan_order_ids = new Set();

        for (const line of this.props.lines_orders) {
            if (this.props.extra_lines.some(l => l.line === line.line_uuid)) continue;
            for (const order of line.orders) {
                if (order.order_type === ORDER_TYPE.plan) {
                    plan_order_ids.add(order.external_id)
                } else {
                    order_ids.add(order.external_id);
                }
            }
        }

        for (const unscheduled_line of this.props.unscheduled_orders) {
            for (const unscheduled_order of unscheduled_line.orders) {
                if (unscheduled_order.order_type === ORDER_TYPE.plan) {
                    plan_order_ids.add(unscheduled_order.external_id);
                } else {
                    order_ids.add(unscheduled_order.external_id);
                }
            }
        }

        const import_type = this.props.import_type;
        if (import_type == PLAN_IMPORT_TYPE.lists) {
            return {
                order_ids: [...order_ids],
                plan_order_ids: [...plan_order_ids],
                import_type: PLAN_IMPORT_TYPE.lists,
                plant_external_id: this.props.plant_external_id
            }
        } else if (import_type == PLAN_IMPORT_TYPE.plant) {
            const plan_import_weeks = Math.floor((
                BusinessLogic.getLineGroupTagInt(this.props.line_group_uuid, "planning_table_horizon_days", 7 * 3)) / 7);
            return {
                import_type: PLAN_IMPORT_TYPE.plant,
                plan_import_weeks,
                plant_external_id: this.props.plant_external_id
            }
        } else {
            throw new Error("Unsupported import_type: " + this.props.import_type);
        }
    }

    onImportSuccess = () => {
        this.setState({ loading: false, success: true, error: "" })
        ImportPlanOnDemandJobHandler.clearTimeout();
        this.setState({ loading: false })
    }

    onImportFailure = (e: string) => {
        this.setState({ loading: false, error: e });
    }

    onImportUpdate = (e: IGetJobStatusRes) => {
        this.setState({ job_status: e });
    }

    onImportPlanOnDemand = async () => {
        this.setState({ loading: true });
        try {
            const data = this.getImportPlanOndemandData();
            await ImportPlanOnDemandJobHandler.runImportPlanOnDemand(
                data,
                this.onImportSuccess,
                this.onImportFailure,
                this.onImportUpdate
            );
        } catch (e) {
            this.setState({ loading: false, error: e.message });
        }
    }

    getModalBody = () => {
        const { job_status } = this.state;
        const status = job_status && job_status.status_msg && job_status.status_msg !== "UNKNOWN" ? (
            <ErrorComponent
                msg={job_status.status_msg}
                type={job_status.status === JOB_STATUS.errored ? "error": "info"}
                onlyDiv
            />
        ) : null;

        if (this.state.loading) {
            return <React.Fragment>
                {status}
                <div style={{marginTop: "-50px"}}>
                    <Loader small />
                </div>
            </React.Fragment>;
        }

        if (this.state.success) {
            return <React.Fragment>
                {status}
                <ErrorComponent
                    msg={translate("common.successfully_import_erp", "Data successfully imported from ERP")}
                    type="success"
                    onlyDiv
                />
            </React.Fragment>;
        }

        if (this.state.error) {
            return <React.Fragment>
                {status}
                <ErrorComponent msg={this.state.error} type="error" onlyDiv={true} />
            </React.Fragment>;
        }

        return translate("common.are_you_sure", "This action will overwrite your current changes. Are you sure you want to continue?");
    }

    getButtons = () => {
        if (this.state.success) {
            return <button className="btn btn-primary" onClick={this.onRefresh}>
                {translate("common.refresh", "Refresh")}
            </button>
        }

        if (this.state.error) {
            return <button className="btn btn-primary" onClick={this.props.onCloseImportPlanOnDemand}>
                {translate("common.ok", "Ok")}
            </button>
        }

        const save_disabled = this.props.lol_is_loading || this.state.loading;

        return <React.Fragment>
            <button className="btn btn-primary" onClick={this.onImportPlanOnDemand} disabled={save_disabled}>
                {translate("common.yes", "Yes")}
            </button>
            <button className="btn btn-primary" onClick={this.props.onCloseImportPlanOnDemand} disabled={save_disabled}>
                {translate("common.no", "No")}
            </button>
        </React.Fragment>
    }

    onRefresh = () => {
        window.location.reload();
    }

    render() {
        return <Modal.Dialog style={{top: "100px"}}>
            <Modal.Header onClose={this.props.onCloseImportPlanOnDemand}>
                <Modal.Title><span>{
                    this.props.import_type == PLAN_IMPORT_TYPE.lists ?
                        translate("common.import_orders", "Refresh from ERP order in shop floor") :
                        translate("common.import_orders_plant", "Refresh from ERP order in plant")
                }</span></Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {this.getModalBody()}
            </Modal.Body>

            <Modal.Footer>
                <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                    {this.getButtons()}
                </div>
            </Modal.Footer>
        </Modal.Dialog>
    }
}

export default connect(
    (state: ReduxState) => {
        const unscheduled_orders = state.gantt_chart_lines_orders.unscheduled_orders;
        const extra_lines = state.gantt_chart_report.extra_lines;
        const lines_orders = state.gantt_chart_lines_orders.lines_orders;

        return {
            lol_is_loading: state.gantt_chart_lines_orders.loading,
            lines_orders,
            unscheduled_orders,
            extra_lines
        }
    },
    null
)(ImportPlanOndemand);
