// @flow
import React from "react";

import { translate } from "../../IntlProviderWrapper";
import LogsModal from "./LogsModal";

type Props = {};
type State = {
    show_logs_modal: boolean
};

class LogsButton extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            show_logs_modal: false
        };
    }

    handleButtonClick = () => {
        this.setState({ show_logs_modal: true });
    };

    handleModalClose = () => {
        this.setState({ show_logs_modal: false });
    };

    render() {
        return (
            <React.Fragment>
                <button className="dropdown-item" onClick={this.handleButtonClick}>
                    {translate("common.logs", "Logs")}
                </button>
                <LogsModal show={this.state.show_logs_modal} onHide={this.handleModalClose} />
            </React.Fragment>
        );
    }
}

export default LogsButton;
