// @flow

import * as t from "./backend/tracking.generated.types";
import { getBackend as getBackend2 } from "../lib/backend/Backend2";
import { getLoggedinUser } from "./Auth";

const logToServer = () => {
    if (!window.tabId) {
        console.error("Failed to get tab id.")
        return;
    }

    const entry: t.IPerformanceEntry = {
        heap: window.performance.memory.usedJSHeapSize,
        tab_id: window.tabId + "",
        user_uuid: getLoggedinUser().uuid,
        epoch_ts: (new Date()).getTime()
    }

    try {
        getBackend2().tracking.logPerformance({
            performance_entry: entry
        });
    } catch (e) {
        console.error(e);
    }
}

export const initPerformanceLogging = () => {
    // ensure window.performance.memory exists 
    // it's non-standard extension for Chromium, doesn't exist in FF
    if (!window.performance || !window.performance.memory){
        window.performance = window.performance || {};
        window.performance.memory = window.performance.memory || { usedJSHeapSize: 0 };
    }
    window.addEventListener("beforeunload", function (e) {
        if (window.tabId !== undefined) {
            window.sessionStorage.tabId = window.tabId;
        }
        return null;
    });

    if (window.sessionStorage.tabId) {
        window.tabId = window.sessionStorage.tabId;
        window.sessionStorage.removeItem("tabId");
    } else {
        window.tabId = Math.floor(Math.random() * 1000000);
    }

    const interval_ms = 5 * 60 * 1000; // 5 minutes
    setInterval(logToServer, interval_ms);
}
