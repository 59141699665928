// @flow
import * as React from "react";
import ReactTable from "../../ReactTable";
type Props = {};
type State = {};

const columns = [
    {
        accessor: "name",
        Header: "",
        filterInputPlaceholder: "Measurement name",
        disableFilters: false
    },
    {
        accessor: "view",
        Header: "View",
        disableFilters: true
    },
    {
        accessor: "time_s",
        Header: "Time (seconds)",
        disableFilters: true,
    }
];


type MeasurementsRow = {
    name: string,
    view: string,
    time_s: number
}

export default class MeasurementsView extends React.Component<Props, State> {

    getTableData = () => {
        const exists = localStorage.getItem("performance-measurement-saved");
        let data: MeasurementsRow[] = [];

        if (exists) {
            data = JSON.parse(exists);
        }

        return data;
    }

    render() {
        const data = this.getTableData();
        return <div className="num-renders-view">
            <ReactTable
                data={data}
                columns={columns}
            />
        </div>;
    }
}