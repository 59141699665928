// @flow
import React from "react";
import { connect } from "react-redux";
import { combineReducers, createStore } from 'redux';
import { TICKETING_VIEWS } from "./reducers/ticketing";
import { Provider } from 'react-redux';
import { reducers } from "./reducers/reducers";
import WorkerInboxContainer from "./WorkerInboxContainer";
import InboxItem from "./InboxItem";
import TicketingNavigation from "./TicketingNavigation";
import TicketList from "./TicketList";
import * as Auth from "../../lib/Auth";
import { USER_TAGS } from "../../lib/CommonTags.generated";
import type { ReduxState } from "./reducers/reducers";

type TicketingViewsProps = {
    selected_view: $Keys<typeof TICKETING_VIEWS>,
    is_kiosk: boolean
};
type TicketingViewsState = {};

class TicketingViews extends React.Component<TicketingViewsProps, TicketingViewsState> {

    render() {
        const { selected_view } = this.props;
        switch (selected_view) {
            case TICKETING_VIEWS.tickets:
                return <TicketList />;
            case TICKETING_VIEWS.inbox:
                return <WorkerInboxContainer />;
            case TICKETING_VIEWS.inbox_item:
                return <InboxItem
                    is_kiosk={this.props.is_kiosk}
                />;
            default:
                return null;
        }
    }
}

const ConnectedTicketingViews = connect(
    (state: ReduxState) => {
        const selected_view = state.ticketing.selected_view;
        return { selected_view };
    },
    (dispatch) => ({ reduxDispatch: dispatch })
)(TicketingViews);


type TicketingAppProps = { is_kiosk: boolean };
type TicketingAppState = {};

export default class TicketingApp extends React.Component<TicketingAppProps, TicketingAppState> {
    store = null;
    is_kiosk = Auth.getUserTag(USER_TAGS.kiosk) === "true";

    constructor(props: TicketingAppProps) {
        super(props);
        this.store = createStore(combineReducers(reducers));
    }

    render() {
        if (!this.store) {
            return null
        };

        return <Provider store={this.store}>
            <div className="ticketing">
                <TicketingNavigation
                    reduxDispatch={this.store.dispatch}
                    is_kiosk={this.is_kiosk}
                />
                <ConnectedTicketingViews
                    reduxDispatch={this.store.dispatch}
                    is_kiosk={this.is_kiosk}
                />
            </div>
        </Provider>
    }
}
