// @flow

// main imports
import React, { Component } from "react";

// models
import * as t from "../../lib/backend/manufacturing2.generated.types";

// backend

// import subcomponents
import { FormattedMessage } from "react-intl";

// defining types
type Props = {
    selected: t.IProcessData | null
}
type State = {}

/**
 * Displaying production lines list.
 */
class ProcessesDetails extends Component<Props, State> {
    /**
     * Rendering JSX for current component.
     */
    render() {
        if (this.props.selected === null) {
            return null;
        }

        let isBottleNeck = this.props.selected.bottleneck;

        return (
            <div className="tab-content">
                <div className="tab-pane active tab-pane-text" id="home">
                    <h5 className="pane-title"><em><FormattedMessage id="common.process" defaultMessage="Process" /></em> {this.props.selected.title} {isBottleNeck &&
                        <span className="badge-list">
                            <span className="badge badge-danger"><FormattedMessage id="Manufacturing.Processes.bottleneck" defaultMessage="bottleneck" /></span>
                        </span>
                    }</h5>
                    <dl className="property-list">
                        <div>
                            <dt><FormattedMessage id="Manufacturing.Processes.workplace_id" defaultMessage="Workplace ID" /></dt>
                            <dd>{this.props.selected.workplace_id}</dd>
                        </div>
                        <div>
                            <dt><FormattedMessage id="Manufacturing.Processes.process_number" defaultMessage="Process number" /></dt>
                            <dd>{this.props.selected.process_num}</dd>
                        </div>
                        <div>
                            <dt className="light"><FormattedMessage id="common.uuid" defaultMessage="UUID" /></dt>
                            <dd className="light">{this.props.selected.uuid}</dd>
                        </div>
                    </dl>

                    <div className="card-deck">
                        <div className="card">
                            <div className="card-header text-uppercase bg-transparent"><FormattedMessage id="common.material" defaultMessage="Material" /></div>
                            <div className="card-body">
                                <dl className="property-list clearfix">
                                    <div>
                                        <dt><FormattedMessage id="common.material" defaultMessage="Material" /></dt>
                                        <dd>{this.props.selected.material_title}</dd>
                                    </div>
                                    <div>
                                        <dt><FormattedMessage id="common.external_id" defaultMessage="External id" /></dt>
                                        <dd>{this.props.selected.material_external_id}</dd>
                                    </div>
                                    <div>
                                        <dt className="light"><FormattedMessage id="common.uuid" defaultMessage="UUID" /></dt>
                                        <dd className="light">{this.props.selected.material_uuid}</dd>
                                    </div>
                                </dl>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header text-uppercase bg-transparent"><FormattedMessage id="common.line" defaultMessage="Line" /></div>
                            <div className="card-body">
                                <dl className="property-list clearfix">
                                    <div>
                                        <dt><FormattedMessage id="common.line" defaultMessage="Line" /></dt>
                                        <dd>{this.props.selected.line_title}</dd>
                                    </div>
                                    <div>
                                        <dt><FormattedMessage id="common.external_id" defaultMessage="External id" /></dt>
                                        <dd>{this.props.selected.line_external_id}</dd>
                                    </div>
                                    <div>
                                        <dt className="light"><FormattedMessage id="common.uuid" defaultMessage="UUID" /></dt>
                                        <dd className="light">{this.props.selected.line_uuid}</dd>
                                    </div>
                                </dl>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header text-uppercase bg-transparent"><FormattedMessage id="common.plant" defaultMessage="Plant" /></div>
                            <div className="card-body">
                                <dl className="property-list clearfix">
                                    <div>
                                        <dt><FormattedMessage id="common.plant" defaultMessage="Plant" /></dt>
                                        <dd>{this.props.selected.plant_title}</dd>
                                    </div>
                                    <div className="separator"></div>
                                    <div>
                                        <dt><FormattedMessage id="common.external_id" defaultMessage="External id" /></dt>
                                        <dd>{this.props.selected.plant_external_id}</dd>
                                    </div>
                                    <div>
                                        <dt className="light"><FormattedMessage id="common.uuid" defaultMessage="UUID" /></dt>
                                        <dd className="light">{this.props.selected.plant_uuid}</dd>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ProcessesDetails;