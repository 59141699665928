// @flow

import type { ILineData } from "../../lib/backend/manufacturing2.generated.types";
import { getLineGroupTags } from "../../lib/BusinessLogic";
import { LINE_GROUP_TAGS_ACCESS } from "../../lib/ManufacturingTags.generated";
import { STOCK_DIRECTION } from "../../lib/ManufacturingConsts.generated";

// navigation filter
type DisplayType = {
    code: string;
    title: string;
}

export const MATERIAL_FORECAST_TYPES = {
    in: STOCK_DIRECTION.in,
    out: STOCK_DIRECTION.out
};

export type MaterialStockStatusType = "in" | "out";

export type StockStatusFilterProps = {
    display_types?: DisplayType[],
    selected_line_uuid?: string,
    selected_display_type: MaterialStockStatusType,
    order_external_id: string,
    lines: ILineData[]
}


export const getMaxDaysForHorizon = (line_group_uuid?: string, horizon: ?string) => {
    if (!line_group_uuid || !horizon) return 7;
    const tags = getLineGroupTags(line_group_uuid);

    return {
        "shift": LINE_GROUP_TAGS_ACCESS.stock_forecast_display_horizon_shift(tags),
        "day": LINE_GROUP_TAGS_ACCESS.stock_forecast_display_horizon_day(tags),
        "week": LINE_GROUP_TAGS_ACCESS.stock_forecast_display_horizon_week(tags)
    }[horizon];
}
