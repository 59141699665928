// @flow
import * as React from "react";

type Props = {
    label: string
};
type State = { };

class Subtitle extends React.Component<Props, State> {
    constructor() {
        super();
        this.state = { label: "Title" };
    }

    render() {
        return (
            <h2>{this.props.label}</h2>
        );
    }
}

export default Subtitle;
