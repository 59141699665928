// @flow

export const LINE_TAGS = {
    "name": "LINE_TAGS",
    "type": "tags",
    "comment": "Tags that are assignable to single line",
    "generate_translations": true,
    "values": [
        {
            "name": "bottleneck",
            "type": "boolean",
            "title": "Bottleneck",
            "comment": "Flag if this line is bottleneck"
        },
        {
            "name": "plan_import_output_stock",
            "type": "boolean",
            "title": "Plan import: output to stock",
            "comment": "If true operations on this line can output to stock, if false they cannot. Default true."
        },
        {
            "name": "multiple_machines_child",
            "type": "boolean",
            "comment": "Flag if this line is a child line based on its machine_external_id",
            "title": "Multiple machine tag"
        },
        {
            "name": "calendar_line",
            "type": "string",
            "title": "Calendar for line",
            "comment": "21 characters long numerical string, each number represents shift of the week"
        },
        {
            "name": "multiple_machines_default_child",
            "type": "string",
            "comment": "external_id of default multiple machine child line",
            "title": "Multiple machines default child"
        },
        {
            "name": "multiple_machines_parent",
            "type": "boolean",
            "title": "Multiple machines parent",
            "comment": "Flag if this is a parent line, supporting multiple machine child lines"
        },
        {
            "name": "multiple_machines_parent_line_external_id",
            "type": "string",
            "title": "Multiple machines parent-line external id",
            "comment": "External id of multiple machine parent line"
        },
        {
            "name": "machine_external_id",
            "type": "string",
            "title": "External id of machine",
            "comment": "External id of machine if available"
        },
        {
            "name": "skip_planning",
            "type": "boolean",
            "title": "Skip planning",
            "comment": "Flag if planning should be skipped for line"
        },
        {
            "name": "ignore_prediction_model",
            "type": "boolean",
            "title": "Indicate that normatives are used for predictions",
            "comment": "Parameter to control if the predictions on this line will use normatives insted of historical data models"
        },
        {
            "name": "skip_shift_board",
            "type": "boolean",
            "title": "Skip shift-assignments board",
            "comment": "Flag if shift assignments should be skipped for line"
        },
        {
            "name": "skip_stock_analysis",
            "type": "boolean",
            "title": "Skip stock analysis",
            "comment": "Flag if stock analysis should be skipped for line"
        },
        {
            "name": "skip_weekly_realization",
            "type": "boolean",
            "title": "Skip weekly realization",
            "comment": "Flag that excludes line from weekly realization"
        },
        {
            "name": "type",
            "type": "enum",
            "source": "LINE_TYPES",
            "title": "Type",
            "comment": "Type of this line, for possible values see LINE_TYPES",
            "mandatory": true,
            "values": [
                {
                    "name": "aging",
                    "comment": "Special type of production - aging and similar batch operations."
                },
                {
                    "name": "assembly",
                    "comment": "Type of production line that involves adding parts and components by workers."
                },
                {
                    "name": "cameraman",
                    "comment": "Virtual line for assigning cameraman."
                },
                {
                    "name": "control",
                    "comment": "Virtual line for assigning quality control."
                },
                {
                    "name": "line_group_lead",
                    "comment": "Virtual line for assigning line-group lead."
                },
                {
                    "name": "non_work",
                    "comment": "Manufacturing line used only for evidenting orders, no work needs to be scheduled or planned."
                },
                {
                    "name": "ordinary",
                    "comment": "Normal manufacturing line that processes orders."
                },
                {
                    "name": "packaging",
                    "comment": "Packaging operations."
                },
                {
                    "name": "pool",
                    "comment": "Virtual line representing pool of people."
                },
                {
                    "name": "shift_lead",
                    "comment": "Virtual line for assigning shift lead."
                },
                {
                    "name": "sick_leave",
                    "comment": "Virtual line for assigning sick leave."
                },
                {
                    "name": "subcontraction",
                    "comment": "Virtual line for denoting subcontraction."
                },
                {
                    "name": "tool_setup",
                    "comment": "Virtual line for assigning tool-setup team."
                }
            ]
        },
        {
            "name": "copy_week_strategy",
            "title": "Copy-week strategy",
            "comment": "Strategy for copying shift assignments from previous week.",
            "type": "enum",
            "source": "COPY_WEEKLY_ASSIGNMENTS_STRATEGY",
            "values": [
                {
                    "name": "copy",
                    "comment": "Simple copy of the assignments"
                },
                {
                    "name": "four_shift_rotation",
                    "comment": "Continuous shift rotation with 4 groups (6-days-work + 2-days-rest)"
                },
                {
                    "name": "shift_preference_rotate",
                    "comment": "Smart rotation based on shift preference"
                },
                {
                    "name": "simple_rotate",
                    "comment": "Simple rotation"
                }
            ]
        },
        {
            "name": "rotate_opposite_direction",
            "title": "Rotate shifts in opposite direction",
            "comment": "Parameter defines direction of rotation when copying previous week's schedule when using `shift_preference_rotate` and `four_shift_rotation` strategies. Option `true` means 321 shift rotation. Option `false` means 123 shift rotation.",
            "type": "boolean"
        },
        {
            "name": "skip_autoclose_orders",
            "title": "Skip automatic closing of orders",
            "comment": "Parameter that controls if orders can be automatically closed when productions reach total order quantity. Default is 'false' and allows such automatic closing.",
            "type": "boolean"
        },
        {
            "name": "insights_skip_no_work_in_shift",
            "title": "Skip insights of the type 'no_work_in_shift'",
            "comment": "Parameter that controls if insights of the type 'no_work_in_shift' should be skipped for this line. Default is 'false'.",
            "type": "boolean"
        },
        {
            "name": "insights_skip_no_person",
            "title": "Skip insights of the type 'no_person'",
            "comment": "Parameter that controls if insights of the type 'no_person' should be skipped for this line. Default is 'false'.",
            "type": "boolean"
        },
        {
            "name": "insights_skip_no_shift",
            "title": "Skip insights of the type 'no_shift'",
            "comment": "Parameter that controls if insights of the type 'no_shift' should be skipped for this line. Default is 'false'.",
            "type": "boolean"
        },
        {
            "name": "insights_skip_plan_change",
            "title": "Skip insights of the type 'plan_change'",
            "comment": "Parameter that controls if insights of the types 'plan_change_quantity', 'plan_change_shifts', 'plan_change_start' and 'plan_change_end' should be skipped for this line. Default is 'false'.",
            "type": "boolean"
        },
        {
            "name": "capacity_factor",
            "title": "Capacity factor",
            "comment": "How many work orders can be executed in parallel on the line. This parameters is used as constraint by automatic planning. When >1, simulation allows for parallel execution of work orders starting with `time_start` and regardles of capacity. Default is `1`.",
            "type": "number",
            "default": 1
        },
        {
            "name": "overlapping_orders_line",
            "title": "Operations can overlap",
            "comment": "If orders can overlap on this line. If 'true', automatic planning will use `capacity_factor` tag. Default is 'false'.",
            "type": "boolean"
        },
        {
            "name": "sublines_number",
            "title": "Number of sublines",
            "comment": "If defined, the line is treated as having sublines. Each subline is displayed separately on the planning board. Default is 1, interpreted as a standard line (without multiple sublines).",
            "type": "number",
            "default": 1
        },
        {
            "name": "conditional_capacity_constraints",
            "title": "Conditional capacity constraints",
            "comment": "If `true` then conditional capacity constraints will be used in the simulation that will restrict which operations can overlap based on their codes. Default is `false`",
            "type": "boolean",
            "default": false
        },
        {
            "name": "max_num_overlaping_lines",
            "title": "Maximum number of overlaping lines",
            "comment": "Overlaping lines",
            "type": "number"
        },
        {
            "name": "gantt_chunk_orders",
            "comment": "Chunk orders support on gantt chart",
            "title": "Chunk orders support on gantt chart",
            "type": "boolean"
        },
        {
            "name": "manual_entry",
            "title": "Manual entry of chunk orders",
            "comment": "Indicates if line supports manual entry of chunk orders. Default is `false`.",
            "type": "boolean",
            "default": false
        },
        {
            "name": "electricity_profit_center",
            "title": "Electricity profit center",
            "comment": "Profit center for computing eletricity cost of material",
            "type": "string"
        },
        {
            "name": "planning_autopilot",
            "title": "Planning autopilot",
            "comment": "Simulation automatically schedules operations on this line according to other operations from same order",
            "type": "boolean",
            "default": false
        },
        {
            "name": "tool_setup_calc_type",
            "type": "enum",
            "source": "TOOL_SETUP_CALC_TYPES",
            "title": "Tool-setup calculation type",
            "comment": "Type of calculation for tool setup, for possible values see TOOL_SETUP_CALC_TYPES",
            "values": [
                {
                    "name": "matrix",
                    "comment": "matrix based (specifies duration in hours for transitions between codes"
                },
                {
                    "name": "code_and_operation_fixed",
                    "comment": "when codes differ use tool setup duration stored on operation"
                },
                {
                    "name": "always",
                    "comment": "always use tool setup, the duration is stored on operation"
                }
            ]
        },
        {
            "name": "tool_setup_code_type",
            "type": "enum",
            "source": "TOOL_SETUP_CODE_TYPES",
            "title": "Tool-setup code type",
            "comment": "Determines how the codes used by tool-setup calculation are computed, for possible values see TOOL_SETUP_CODE_TYPES",
            "values": [
                {
                    "name": "material",
                    "comment": "material external id"
                },
                {
                    "name": "material_process",
                    "comment": "material external id + process number"
                },
                {
                    "name": "explicit",
                    "comment": "explicit code on operation"
                }
            ]
        },
        {
            "name": "kpi_oee_calculation",
            "title": "OEE KPI",
            "comment": "When true, line is included in KPI OEE calculations",
            "section": "kpi",
            "type": "boolean",
            "default": false
        },
        {
            "name": "kpi_stock_tracking",
            "title": "Stock tracking KPI",
            "comment": "When true, line is included in stock tracking KPIs",
            "section": "kpi",
            "type": "boolean",
            "default": false
        },
        {
            "name": "kpi_workforce",
            "title": "Stock tracking KPI",
            "comment": "When true, line is included in workforce capacity KPIs",
            "section": "kpi",
            "type": "boolean",
            "default": false
        },
        {
            "name": "kpi_workforce_shared_line",
            "title": "Worker sharing",
            "comment": "External ID of the line, which is also handled by worker scheduled on this line.",
            "section": "kpi",
            "type": "string"
        },
        {
            "name": "use_only_for_bom_in_simulation",
            "type": "boolean",
            "title": "Use only for BOM in simulation",
            "comment": "When `true` operations on line are only used for BOM and are not terminated or simulated otherwise. Their BOM is attached to the first previous operation not on use_only_for_bom_in_simulation line. In case no such operation is available, then it is used on the first next such operation.",
            "default": false
        },
        {
            "name": "erp_date_determination",
            "type": "boolean",
            "title": "ERP date determination",
            "comment": "ERP defines start and end time for operation that is used in simulation.",
            "default": false
        },
        {
            "name": "plan_import_set_batch",
            "type": "boolean",
            "title": "Plan import: set batch",
            "comment": "Set `batch=true`` to all processes on this line during plan import. The flag `batch=true`` is needed on processes in order to simulate them as batches.",
            "default": false
        },
        {
            "name": "stock_available_type",
            "type": "enum",
            "source": "LINE_STOCK_AVAILABLE_TYPES",
            "default": "immediately",
            "title": "Type of stock-column availability calculation",
            "comment": "Defines how availabe column is computed for operations preceeding operations on this line.",
            "values": [
                {
                    "name": "immediately",
                    "comment": "Zero delay between produced and avilable"
                },
                {
                    "name": "norm_total",
                    "comment": "Delay is computed from normative of the operation using total quantity"
                },
                {
                    "name": "norm_base",
                    "comment": "Delay is computed from normative of the operation using norma base quantity"
                },
                {
                    "name": "time_end_time_start",
                    "comment": "Delay is computed by taking the fixed duration of the operation"
                },
                {
                    "name": "constant",
                    "comment": "Delay is provided as constant specified in a separate tag on the line"
                }
            ]
        },
        {
            "name": "stock_available_delay_hours",
            "type": "number",
            "title": "Delay in hours (stock availability)",
            "comment": "When `stock_available_type` is set to `constant` this tag defines the delay in hours"
        }
    ]
};

export const LINE_GROUP_TAGS = {
    "name": "LINE_GROUP_TAGS",
    "type": "tags",
    "table_name": "qldb_man.linegroups",
    "generate_translations": true,
    "values": [
        {
            "name": "microplan_refresh_minutes",
            "title": "Microplan refresh report every (minutes)",
            "comment": "Refresh report on microplan page (in minutes)",
            "type": "number"
        },
        {
            "name": "extract_conditional_capacity_constraint_insights",
            "title": "Extract conditional capacity constraint insights",
            "comment": "If true, extract conditional_capacity_constraint insights, which checks compatibility of overlaping operations",
            "type": "boolean",
            "default": false
        },
        {
            "name": "batch_op",
            "title": "Batch operations",
            "comment": "Flag indicating that this line-group represents batch operations, e.g. aging furnaces.",
            "type": "boolean"
        },
        {
            "name": "day_shift_assignment_format",
            "title": "Day shift assignment format",
            "comment": "Parameter which defines what shifts are exported in the daily export. Option `123` means export day's morning afternoon and night shifts. `231` means export day's afternoon and night shifts and following day's morning shift.",
            "type": "string"
        },
        {
            "name": "rotate_opposite_direction",
            "title": "Parameter defines the direction of the shift rotation",
            "comment": "Parameter defines direction of rotation when copying previous week when using `shift_preference_rotate` strategy. Option `true` means 321 shift rotation. Option `false` means 123 shift rotation.",
            "type": "boolean"
        },
        {
            "name": "gannt",
            "title": "Gantt",
            "comment": "Deprecated. Flag if this line-group uses gantt-chart.",
            "type": "boolean"
        },
        {
            "name": "gantt_show_history",
            "title": "Gantt show history",
            "comment": "Defines if 'Show history' option in gantt chart is enabled by default. Default value is `true`.",
            "type": "boolean"
        },
        {
            "name": "gantt_number_of_days",
            "title": "Gantt number of days",
            "comment": "Optional parameter, which defines how many days are shown on the Gantt chart. Default value is `28`.",
            "type": "number",
            "default": 28
        },
        {
            "name": "gantt_past_weeks",
            "title": "Gantt past weeks",
            "comment": "Optional parameter, which defines how many past weeks are shown on the Gantt chart. Default value is given by the simulation `1`.",
            "type": "number",
            "default": 1
        },
        {
            "name": "gantt_min_day_width",
            "title": "Gantt min day width",
            "comment": "Optional parameter which defines minimal number of pixles one day should take in gantt chart. Default is `40`.",
            "type": "number",
            "default": 40
        },
        {
            "name": "has_pool",
            "title": "Has pool",
            "comment": "Parameter which indicates that this line-group uses pool for people assignments.",
            "type": "boolean"
        },
        {
            "name": "print_only_assigned_lines",
            "title": "Print only assigned lines",
            "comment": "Print shift-board only for lineswith assigned worker for the line-group",
            "type": "boolean"
        },
        {
            "name": "no_worker_horizon_shifts",
            "title": "No worker horizon shifts",
            "comment": "Parameter defines how many shifts in the future 'No worker' insights should be generated for pooled lines. It can take up to 10 minutes for the changes to be visible.",
            "type": "number",
            "default": 3
        },
        {
            "name": "hide_in_navigation",
            "title": "Hide in navigation",
            "comment": "If true hides this line from GUI navigation",
            "type": "boolean"
        },
        {
            "name": "microplan",
            "title": "Microplan",
            "comment": "Indicator that this line-group uses microplan for planning",
            "type": "boolean"
        },
        {
            "name": "microplan_weeks",
            "title": "Microplan weeks",
            "comment": "Optional parameter, which defines which weeks are shown on the microplan week-table view. Default value is `[-1,0,1,2]`",
            "type": "string"
        },
        {
            "name": "microplan_gantt_number_of_days",
            "title": "Microplan gantt number of days",
            "comment": "Optional parameter, which defines how many days are shown on the Gantt chart showing a single line. Default value is `28`.",
            "type": "number",
            "default": 28
        },
        {
            "name": "microplan_gantt_show_history",
            "title": "Microplan gantt show history",
            "comment": "Optional parameter, which defines if single line gantt shows history. Default is `true`",
            "type": "boolean",
            "default": true
        },
        {
            "name": "microplan_show_shift_table",
            "title": "Microplan show shift table",
            "comment": "Optional parameter, which defines if shift table is shown. Default is `false`",
            "type": "boolean",
            "default": false
        },
        {
            "name": "microplan_show_order_table",
            "title": "Microplan show order table",
            "comment": "Optional parameter, which defines if order table is shown. Default is `true`",
            "type": "boolean",
            "default": true
        },
        {
            "name": "microplan_show_sap_dates",
            "title": "Microplan show ERP dates",
            "comment": "Show ERP dates in microplan order list. Default is `true`",
            "type": "boolean",
            "default": true
        },
        {
            "name": "microplan_show_order_table_shifts",
            "title": "Microplan show order shifts",
            "comment": "Optional parameter, which defines if order table details individual shifts. Default is `false`",
            "type": "boolean"
        },
        {
            "name": "microplan_recalc_on_save",
            "title": "Microplan recalculate on save",
            "comment": "Run new full simulation in the background on plan changes in microplan. Default is `false`",
            "type": "boolean"
        },
        {
            "name": "microplan_stock_forecast_location",
            "title": "Microplan stock forecast location",
            "comment": "Storage locations that should be displayed on microplan stock forecast views.",
            "type": "string",
            "values": [
                {
                    "name": "shopfloor"
                },
                {
                    "name": "plant"
                }
            ]
        },
        {
            "name": "shift_board",
            "title": "Shift board",
            "comment": "Indicator that this line-group uses shift-board for assinments of people to lines",
            "type": "boolean"
        },
        {
            "name": "stock_forecast",
            "type": "boolean",
            "title": "Stock forecast",
            "comment": "Forecasting enables warehouses to keep track of sales and anticipated demand, so they can ensure that the available stock is adequate to meet customer needs."
        },
        {
            "name": "stock_forecast_show_shopfloor_option",
            "title": "Stock forecast show shopfloor option",
            "comment": "Shall we show the option to toggle between shop-floor only and overall inventory level on stock forecast page. Default is `true`",
            "type": "boolean",
            "default": true
        },
        {
            "name": "stock_forecast_shopfloor_option_default",
            "title": "Shop-floor only toggle default state",
            "comment": "Default state of the shop-floor only toggle. Default is `true`",
            "type": "boolean",
            "default": true
        },
        {
            "name": "stock_forecast_show_safety_stock",
            "title": "Show safety stock",
            "comment": "Show safety stock levels on stock forecast page. Default is `false`.",
            "type": "boolean"
        },
        {
            "name": "stock_forecast_withdrawn_and_unaccounted_default",
            "title": "Default state of the 'Withdrawn and unaccounted' option",
            "comment": "Default state of the 'Withdrawn and unaccounted' option checkbox for stock forecast. Default is `false`.",
            "type": "boolean",
            "default": false
        },
        {
            "name": "subcontracting",
            "title": "Subcontracting",
            "comment": "Indicator that this line-group uses subcontracting",
            "type": "boolean"
        },
        {
            "name": "weekly_realization",
            "title": "Weekly realization",
            "comment": "Indicator that this line-group uses weekly realization",
            "type": "boolean"
        },
        {
            "name": "weekly_realization_scrap_view",
            "title": "Weekly realization scrap view",
            "comment": "Indicator to show scrap in dropdown",
            "type": "boolean",
            "default": "false"
        },
        {
            "name": "weekly_realization_downtimes_view",
            "title": "Weekly realization downtimes view",
            "comment": "Indicator to show downtimes in dropdown",
            "type": "boolean",
            "default": "false"
        },
        {
            "name": "weekly_realization_show_workers",
            "title": "Weekly realization show workers",
            "comment": "Optional parameter, which defines if we show workers in the weekly realization view. Default value is `true`.",
            "type": "boolean"
        },
        {
            "name": "planning_board",
            "title": "Planning board",
            "comment": "Enable planning board",
            "type": "boolean",
            "default": "false"
        },
        {
            "name": "planning_table_save_to_erp",
            "title": "Enable saving to ERP",
            "comment": "Enable planning board save to erp button",
            "type": "boolean",
            "default": "false"
        },
        {
            "name": "automatic_scheduling",
            "title": "Automatic scheduling",
            "comment": "Does linegroup supports automatic scheduling on the planning board?",
            "type": "boolean",
            "default": "false"
        },
        {
            "name": "planning_table_weeks",
            "title": "Planning table weeks",
            "comment": "Number of weeks displayed on the planning board",
            "type": "number",
            "default": "4"
        },
        {
            "name": "planning_table_save_to_erp_weeks",
            "title": "Number of weeks used for recalculating simulation when saving to ERP",
            "comment": "Number of weeks used for recalculating simulation when saving to ERP",
            "type": "number",
            "default": "16"
        },
        {
            "name": "planning_table_horizon_days",
            "title": "Planning table horizon days",
            "comment": "Plan only orders with earliest start before 'planning_table_horizon_days' from now",
            "type": "number",
            "default": "21"
        },
        {
            "name": "planning_table_unscheduled_move",
            "title": "Move unscheduled orders to the end of planning horizon",
            "comment": "When saving to ERP, move unscheduled orders to the end of planning horizon or leave them as is (default false)",
            "type": "boolean",
            "default": "false"
        },
        {
            "name": "planning_table_lazy_rendering",
            "title": "Planning table lazy rendering",
            "comment": "Speeds up planning table when dealing with many orders",
            "type": "boolean",
            "default": "false"
        },
        {
            "name": "plan_import_scheduled_statuses_skip_sim",
            "type": "boolean",
            "comment": "Sets skip_sim flag for operations that are not schedulled in external system",
            "title": "Set skip_sim based on externally scheduled statuses",
            "default": "false"
        },
        {
            "name": "planning_mode",
            "title": "Planning mode",
            "comment": "Mode of automatic planning - by default 'lines_first'.",
            "type": "enum",
            "source": "PLANNING_MODE",
            "default": "lines_first",
            "values": [
                {
                    "name": "lines_first",
                    "comment": "Plan work on lines and ignore people assignments"
                },
                {
                    "name": "people_first",
                    "comment": "Plan work according to people pool-assignments and include lines in second step."
                }
            ]
        },
        {
            "name": "planning_meta_optimization",
            "title": "Meta optimization",
            "comment": "Meta-optimizations of enabled shifts to be followed in cron-initiated automatic scheduling",
            "type": "enum",
            "source": "PLANNING_META_OPTIMIZATION",
            "values": [
                {
                    "name": "none",
                    "comment": "No meta optimization."
                },
                {
                    "name": "max_resources",
                    "comment": "Find optimal plan given the needs and criteria using at most maximal number of resources per shift, provided in the input model."
                },
                {
                    "name": "time_front",
                    "comment": "Find optimal plan that tries to ensure all available resources are utilized as much as possible from the start. This is done by keeping a time front (e.g. start with initial 24 hours) and make sure we schedule everything that can be scheduled there before moving the front forward"
                },
                {
                    "name": "stock",
                    "comment": "Find optimal plan given the needs and criteria while also keeping stock low. This is done by finding optimal plan utilizing all available resources and then greedily removing resources from the plan that do not affect the delay score of the plan. This is repeated until no more resources can be removed."
                }
            ]
        },
        {
            "name": "planning_exact_tolerance",
            "title": "Tolerance allowed when tracking materials exactly",
            "comment": "Tolerance allowed when tracking materials exactly. Example, 90% tolerance equals value 0.9. Default is 1.0 (no tolerance).",
            "type": "number",
            "default": 1
        },
        {
            "name": "planning_break_orders",
            "title": "Break planned orders",
            "comment": "Can planned order be broken into smaller chunks. Default is `false`.",
            "type": "boolean"
        },
        {
            "name": "planning_split_order_min_hours",
            "title": "Minimal length of chunks in hours. Default is 8.",
            "comment": "Minimal length of chunks in hours. Default is 8.",
            "type": "number",
            "default": 8
        },
        {
            "name": "planning_ramp_up_factor",
            "title": "Ramp-up factor for planning - 'warm-up' penalty",
            "comment": "Ramp-up factor for planning - 'warm-up' penalty. Default is 1.",
            "type": "number",
            "default": 1
        },
        {
            "name": "planning_setup_code_change_factor",
            "title": "Increase of penalty for material-change when setup-code is different",
            "comment": "Increase of penalty for material-change when setup-code is different. Default is 2.",
            "type": "number",
            "default": 2
        },
        {
            "name": "planning_smooth_needs",
            "title": "Needs penalty smoothed",
            "comment": "Should needs penalty be smoothed. Default is `true`.",
            "type": "boolean",
            "default": true
        },
        {
            "name": "planning_time_horizont_shifts",
            "title": "Planning time-horizon shifts",
            "comment": "The time-horizon for planning. Default is 42 (two weeks).",
            "type": "number",
            "default": 42
        },
        {
            "name": "planning_time_horizont_buffer",
            "title": "Planning time-horizon buffer",
            "comment": "The time-horizon extra buffer to account for the time orders starting in time horizont need to finish. Should correspond to maximal order lenght. Default is 7.",
            "type": "number",
            "default": 7
        },
        {
            "name": "planning_time_horizont_needs",
            "title": "Planning time-horizon needs",
            "comment": "The time-horizon extra days beyond time horizont for including needs in the planning process. Default is 7.",
            "type": "number",
            "default": 7
        },
        {
            "name": "planning_tool_setup_factor",
            "title": "Tool setup penalty factor",
            "comment": "Tool setup penalty share in BFS score. This is multiplied with the tool setup criteria score. Default is 4.",
            "type": "number",
            "default": 4
        },
        {
            "name": "planning_track_input_material",
            "title": "Track input materials",
            "comment": "Should input-materials be tracked duringh planning. Default is `false`.",
            "type": "boolean"
        },
        {
            "name": "planning_override_shifts",
            "title": "Override shifts",
            "comment": "Number of shifts we assume, when defined it overrides existing enabled shifts. Value 0 means enable shifts with at least one person scheduled to work.",
            "type": "number",
            "default": -1
        },
        {
            "name": "planning_track_stock_levels",
            "title": "Should planning account for value of material on stock",
            "comment": "Should planning account for value of material on stock. Default is `false`.",
            "type": "boolean"
        },
        {
            "name": "planning_track_customer_priorty",
            "title": "Should planning account for customer priority",
            "comment": "Should planning account for customer priority. Default is `false`",
            "type": "boolean"
        },
        {
            "name": "planning_complete_materials_at_start",
            "title": "Complete input materials must be available at the start of the operation",
            "comment": "Complete input materials must be available at the start of the operation (no overlapping). Default is `true`",
            "type": "boolean",
            "default": true
        },
        {
            "name": "planning_order_operations_overlap",
            "title": "Allow operations within the order to overlap",
            "comment": "Allow operations within the order to overlap. Default is `false`",
            "type": "boolean"
        },
        {
            "name": "planning_use_material_codes",
            "title": "Use material codes",
            "comment": "Use material codes in planning to compute changover times. Default is `false`",
            "type": "boolean",
            "default": false
        },
        {
            "name": "planning_work_duration_factor",
            "title": "Work duration factor",
            "comment": "Default factor for weighting planned work duration in planning. Default is 0.",
            "type": "number",
            "default": 0
        },
        {
            "name": "shift_assignment_group_by_people",
            "title": "Shift assignment group by people",
            "comment": "Parameter which groups the exported of the daily schedule by people instead of grouping by lines",
            "type": "boolean"
        },
        {
            "name": "copy_week_strategy",
            "title": "Week-copying strategy",
            "comment": "Strategy for copying shift assignments from previous week, for all lines in this linegroup.",
            "type": "enum",
            "source": "COPY_WEEKLY_ASSIGNMENTS_STRATEGY",
            "values": [
                {
                    "name": "copy",
                    "comment": "Simple copy of the assignments"
                },
                {
                    "name": "four_shift_rotation",
                    "comment": "Continuous shift rotation with 4 groups (6-days-work + 2-days-rest)"
                },
                {
                    "name": "shift_preference_rotate",
                    "comment": "Smart rotation based on shift preference"
                },
                {
                    "name": "simple_rotate",
                    "comment": "Simple rotation"
                }
            ]
        },
        {
            "name": "microplan_show_order_table_operation_comment",
            "type": "boolean",
            "title": "Show operation comment in work orders table on microplan",
            "comment": "Indicates if operation comment should be shown in work orders table on microplan"
        },
        {
            "name": "microplan_show_order_table_material_comment",
            "type": "boolean",
            "title": "Show material comment in work orders table on microplan",
            "comment": "Indicates if material comment should be shown in work orders table on microplan"
        },
        {
            "name": "microplan_show_order_table_external_operation_id",
            "type": "boolean",
            "title": "Show external operation id in work orders table on microplan",
            "comment": "Indicates if external operation id should be shown in work orders table on microplan"
        },
        {
            "name": "microplan_show_alternative_unit",
            "type": "boolean",
            "title": "Show alternative unit on microplan",
            "comment": "Indicates if alternative unit should be shown on microplan"
        },
        {
            "name": "gantt_show_empty_lines",
            "type": "boolean",
            "default": "false",
            "title": "Show empty lines on Gantt chart",
            "comment": "Indicates if empty lines should be shown on Gantt chart"
        },
        {
            "name": "microplan_show_order_table_material",
            "type": "boolean",
            "default": "true",
            "title": "Show material in work orders table on microplan",
            "comment": "Indicates if material should be shown in work orders table on microplan"
        },
        {
            "name": "microplan_show_order_table_order_id",
            "type": "boolean",
            "default": "true",
            "title": "Show order ID in work orders table on microplan",
            "comment": "Indicates if order ID should be shown in work orders table on microplan"
        },
        {
            "name": "microplan_show_order_table_operation",
            "type": "boolean",
            "default": "true",
            "title": "Show operation in work orders table on microplan",
            "comment": "Indicates if operation should be shown in work orders table on microplan"
        },
        {
            "name": "microplan_show_order_table_expected_start",
            "type": "boolean",
            "default": "true",
            "title": "Show expected start in work orders table on microplan",
            "comment": "Indicates if expected start should be shown in work orders table on microplan (only if `microplan_show_order_table_shifts` is disabled)"
        },
        {
            "name": "microplan_show_order_table_expected_completion",
            "type": "boolean",
            "default": "true",
            "title": "Show expected completion in work orders table on microplan",
            "comment": "Indicates if expected completion should be shown in work orders table on microplan (only if `microplan_show_order_table_shifts` is disabled)"
        },
        {
            "name": "microplan_show_order_table_time_to_complete",
            "type": "boolean",
            "default": "true",
            "title": "Show time to complete in work orders table on microplan",
            "comment": "Indicates if time to complete should be shown in work orders table on microplan"
        },
        {
            "name": "microplan_show_order_table_quantity",
            "type": "boolean",
            "default": "true",
            "title": "Show quantity in work orders table on microplan",
            "comment": "Indicates if quantity should be shown in work orders table on microplan"
        },
        {
            "name": "microplan_show_order_table_unscheduled_orders",
            "type": "boolean",
            "default": "false",
            "title": "Show unscheduled orders in work orders table on microplan",
            "comment": "Indicates if unscheduled orders should be shown in work orders table on microplan"
        },
        {
            "name": "planning_table_show_tool_change",
            "title": "Show tool changes on planning table",
            "comment": "Indicates if tool changes should be shown on planning table",
            "type": "boolean",
            "default": "false"
        },
        {
            "name": "planning_time_horizont_unit",
            "title": "Horizon unit for automatic scheduling on planning table",
            "comment": "Choose unit for horizon on automatic scheduling on planning table",
            "type": "enum",
            "source": "PLAN_HORIZON_UNIT_AUTOMATIC_SCHEDULING",
            "values": [
                {
                    "name": "shift_unit",
                    "comment": "Use shifts unit on automatic scheduling on planning table"
                },
                {
                    "name": "week_unit",
                    "comment": "Use weeks unit on automatic scheduling on planning table"
                }
            ]
        },
        {
            "name": "long_term_stock_forecast",
            "title": "Show long-term stock forecast in stocks view",
            "comment": "Indicates if long-term stock forecast should be shown in stocks view",
            "type": "boolean",
            "default": "false"
        },
        {
            "name": "insight_downstream_tolerance",
            "title": "Tolerance for downstream delays",
            "comment": "Tolerance for creating downstream delays. Default values is 0.0001.",
            "type": "number",
            "default": 0.0001
        },
        {
            "name": "planning_meta_optimization_param",
            "title": "Show meta-optimization parameter in scheduling options",
            "comment": "Indicates if meta-optimization parameter should be shown in scheduling options. Default is `false`.",
            "type": "boolean",
            "default": "false"
        },
        {
            "name": "manual_order_entry",
            "title": "Show manual order entry page in plan view",
            "comment": "Indicates if manual order entry page should be shown in plan view. Default is `false`.",
            "type": "boolean",
            "default": "false"
        },
        {
            "name": "stock_forecast_display_horizon_shift",
            "title": "Stock forecast display horizon in 'shift' view",
            "comment": "The number of days that defines the time window for displaying stock forecast in 'shift' view. Value must be between 1 and 28. Default is 7.",
            "type": "number",
            "default": 7
        },
        {
            "name": "stock_forecast_display_horizon_day",
            "title": "Stock forecast display horizon in 'day' view",
            "comment": "The number of days that defines the time window for displaying stock forecast in 'day' view. Value must be between 1 and 28. Default is 14.",
            "type": "number",
            "default": 14
        },
        {
            "name": "stock_forecast_display_horizon_week",
            "title": "Stock forecast display horizon in 'week' view",
            "comment": "The number of days that defines the time window for displaying stock forecast in 'week' view. Value must be between 1 and 28. Default is 28.",
            "type": "number",
            "default": 28
        },
        {
            "name": "planning_table_orders_with_editable_quantity",
            "title": "Orders with editable quantity on planning table",
            "comment": "Determines for which orders users can edit quantity on planning table. Default is `all_orders`.",
            "type": "enum",
            "source": "ORDERS_WITH_EDITABLE_QUANTITY",
            "default": "all_orders",
            "values": [
                {
                    "name": "all_orders"
                },
                {
                    "name": "planning_orders"
                },
                {
                    "name": "no_orders"
                }
            ]
        },
        {
            "name": "planning_table_show_link_operation",
            "title": "Show 'link operation' on planning table",
            "comment": "Indicates if 'link operation' action should be available on planning table. Default is `false`.",
            "type": "boolean",
            "default": false
        },
        {
            "name": "save_all_operations_to_erp",
            "title": "Automatically (un)select all operations of the same order in save dialog",
            "comment": "Indicates if all operations of the same order shuld be (un)selected when single operation is (un)selected in save dialog on planning table. Default is `false`.",
            "type": "boolean",
            "default": false
        },
        {
            "name": "unscheduled_operations_marked_unchanged",
            "title": "Operations that keept unscheduled status are marked a s unchanged",
            "comment": "If status of an operation stays unscheduled, do not highlight it as changed operation on Synchronize and refresh dialog box. Changed operations are marked white, while unchanged operations are marked grey. For example if a user schedules an operation to a line, but moves it back to unscheduled in the same iteration, the operation will be marked as unchanged. Default is `false`.",
            "type": "boolean",
            "default": false
        },
        {
            "name": "save_whole_lines_to_erp",
            "title": "Automatically (un)select all operations on the same initial/final line in save dialog",
            "comment": "Indicates if all operations on the same initial/final line shuld be (un)selected when single operation is (un)selected in save dialog on planning table. Default is `false`.",
            "type": "boolean",
            "default": false
        },
        {
            "name": "planning_table_show_issues_tab",
            "title": "Show issues tab on planning table",
            "comment": "Indicates if issues tab should be shown on planning table. Default is `false`.",
            "type": "boolean",
            "default": false
        },
        {
            "name": "stock_status_basic_view_quantity_column",
            "title": "Quantity column in basic view on stock status page",
            "comment": "Determines which quantity is displayed in basic view on stock status page. Default is `erp_quantity`.",
            "type": "enum",
            "source": "STOCK_STATUS_QUANTITY_TYPE",
            "default": "erp_quantity",
            "values": [
                {
                    "name": "available_quantity",
                    "comment": "Available quantity"
                },
                {
                    "name": "erp_quantity",
                    "comment": "ERP quantity"
                }
            ]
        },
        {
            "name": "stock_forecast_show_withdrawn_and_unaccounted_option",
            "title": "Show 'Withdrawn and unaccounted' option on stock forecast page",
            "comment": "Indicates if 'Withdrawn and unaccounted' option should be shown on stock forecast page. Default is `true`.",
            "type": "boolean",
            "default": true
        }
    ]
};

export const BATCHOP_GROUP_TAGS = {
    "name": "BATCHOP_GROUP_TAGS",
    "comment": "Tags for decorating batch-operation group.",
    "type": "tags",
    "values": [
        {
            "name": "line_group",
            "comment": "UUID of the line-group that this batch-operation group maps to.",
            "type": "string",
            "mandatory": true
        },
        {
            "name": "external_id",
            "comment": "Optional external id of group, if provided.",
            "type": "string"
        },
        {
            "name": "halb",
            "type": "string",
            "comment": "If this flag is set to true, ident of input material with type HALB is used for batch-operations."
        },
        {
            "name": "no_orders",
            "comment": "Flag for setting batch operations in mode that works without work orders.",
            "type": "boolean"
        },
        {
            "name": "silo_input",
            "comment": "Flag for setting batch operations silo input mode. This influences current status of batch page.",
            "type": "boolean"
        }
    ]
};

export const BATCHOP_LOCATION_TAGS = {
    "name": "BATCHOP_LOCATION_TAGS",
    "comment": "Tags for decorating batch-operation location.",
    "type": "tags",
    "values": [
        {
            "name": "line",
            "comment": "UUID of the line that this location maps to - used for retrieving work-orders.",
            "type": "string"
        }
    ]
};

export const PLANT_TAGS = {
    "name": "PLANT_TAGS",
    "comment": "Tags for decorating single plant.",
    "type": "tags",
    "generate_translations": true,
    "values": [
        {
            "name": "company_id",
            "type": "string",
            "title": "Company ID",
            "comment": "Company ID when several companies belong to the same organization"
        },
        {
            "name": "norm_fallback_parallel_order",
            "type": "boolean",
            "title": "Fallback to normative for parallel orders",
            "comment": "Use normatives (instead of simulation models) to estimate duration of parallel orders. Default: false.",
            "default": "false"
        },
        {
            "name": "plan_import_delivered_quantities",
            "type": "boolean",
            "title": "Plan import: retrieve delivered quantites",
            "comment": "Plan import will retrieve quantites delivered for each active order of the given plant"
        },
        {
            "name": "plan_import_scheduled_statuses",
            "type": "boolean",
            "title": "Plan import: scheduled status of operations",
            "comment": "Plan import will retrieve scheduled statuses for all imported operations"
        },
        {
            "name": "calendar_plant",
            "type": "string",
            "default": "111111111111111111111",
            "title": "Calendar",
            "comment": "21 characters long numerical string, each number represents shift of the week"
        },
        {
            "name": "plan_import_convert_operation_units",
            "type": "boolean",
            "title": "Plan import: convert operation measurement untis",
            "comment": "Convert operation unit of measure to plan unit of measure. Modifies normative values. (default false)"
        },
        {
            "name": "plan_import_operation_output_stock_keys",
            "type": "string",
            "comment": "Comma separated list of operation output stock keys used for deciding if an operation outputs to stock. Used when importing plan.",
            "title": "Plan import: operation output stock keys"
        },
        {
            "name": "plan_import_output_stock_postprocess",
            "type": "enum",
            "values": [
                {
                    "name": "nop"
                },
                {
                    "name": "external_resolved"
                },
                {
                    "name": "rules"
                }
            ],
            "comment": "Type of post-processing output stock flag when importing plan. Value `nop` means no posprocessing, value `external_resolved` uses output_stock that comes through import, but ensures that exactly one operation has output stock true for each work order. Value `rules` corresponds to ignoring external output_stock and determine which operation outputs to stock based on plant and line tags. Default `external_resolved`",
            "title": "Plan import: operation output stock post-processing"
        },
        {
            "name": "plan_import_planned_orders",
            "type": "boolean",
            "title": "Import planned orders",
            "comment": "Flag for importing planned orders for the plant"
        },
        {
            "name": "plan_import_horizon",
            "type": "number",
            "default": "8",
            "title": "Plan import: horizon",
            "comment": "Defines for how many weeks in the future the plan will be imported"
        },
        {
            "name": "plan_import_from_days",
            "type": "number",
            "default": "10",
            "title": "Plan import from how many days back",
            "comment": "Indicate how many days is the starting date for importing plan"
        },
        {
            "name": "plan_sap_lang",
            "type": "string",
            "default": "SL",
            "comment": "Indicates language for ERP plan data import",
            "title": "Plan import language"
        },
        {
            "name": "has_multiple_machines",
            "type": "boolean",
            "comment": "Indicates whether the plant has work-centers with multiple machines. when turned on, machine_assignment task provides machine assignment data for the plant",
            "title": "Has multiple machines"
        },
        {
            "name": "stock_forecast_produced_scenario",
            "type": "boolean",
            "comment": "Stock forecast: output materials",
            "title": "Stock forecast: output materials"
        },
        {
            "name": "stock_forecast_produced_required_scenario",
            "type": "boolean",
            "comment": "Stock forecast: output materials requirements",
            "title": "Stock forecast: output materials requirements"
        },
        {
            "name": "stock_forecast_consumed_scenario",
            "type": "boolean",
            "comment": "Stock forecast: input materials",
            "title": "Stock forecast: input materials"
        },
        {
            "name": "stock_forecast_consumed_received_scenario",
            "type": "boolean",
            "comment": "Stock forecast: input materials delivery",
            "title": "Stock forecast: input materials delivery"
        },
        {
            "name": "stock_requirements_import_types",
            "type": "string",
            "comment": "Types of stock requirements to import for the plant",
            "title": "Stock requirements import types"
        },
        {
            "name": "ignore_material_tags",
            "type": "string",
            "comment": "Ignore materials with the tags in the plant. For example: OROD, VERP, LEIH",
            "title": "Ignore material tags"
        },
        {
            "name": "week_start_shift_offset",
            "title": "Start-shift week offset",
            "comment": "Offset of start-shift in week. Default is 0.",
            "type": "number",
            "default": 0
        },
        {
            "name": "simulation_current_shift_stock_in_erp",
            "type": "boolean",
            "title": "Simulation current shift stock handling",
            "comment": "Handles stock changes in current shift that were transfered to ERP",
            "default": "false"
        },
        {
            "name": "sim_ignore_earliest_start",
            "type": "boolean",
            "title": "Ignore earliest start",
            "comment": "Set sim_ignore_earliest_start (24/7) for orders in the plant"
        },
        {
            "name": "work_schedule_sunday_logic",
            "title": "Sunday-schedule logic",
            "comment": "Controls swapping Friday last shift with Sunday last shift",
            "type": "enum",
            "values": [
                {
                    "name": "nop"
                },
                {
                    "name": "swap"
                }
            ]
        },
        {
            "name": "work_schedule_preferred_shifts",
            "title": "Preferred shifts",
            "comment": "Controls mapping from workday hours to enabled shifts (what shift comes first)",
            "type": "enum",
            "values": [
                {
                    "name": "123"
                },
                {
                    "name": "132"
                },
                {
                    "name": "213"
                },
                {
                    "name": "231"
                },
                {
                    "name": "312"
                },
                {
                    "name": "321"
                }
            ]
        },
        {
            "name": "insight_freeze_order",
            "title": "Freeze order insights",
            "comment": "Controls if we want to compute freeze-order-change insights",
            "type": "boolean"
        },
        {
            "name": "insight_no_work_shifts",
            "title": "No-work insights horizon",
            "comment": "Horizon in number of shifts for which we compute no-work insights for lines in the plant",
            "type": "number",
            "default": 21
        },
        {
            "name": "shift_board_support_12h_shifts",
            "title": "Support 12h shifts",
            "comment": "Indicates that the plant supports 12h shifts",
            "type": "boolean"
        },
        {
            "name": "enable_tool_transfer_print",
            "title": "Enable tool transfer print",
            "comment": "Enable printing of the tool transfer from the list of tool changes",
            "type": "boolean"
        },
        {
            "name": "tools_stock_location",
            "title": "Stock location for tools",
            "comment": "Sets stock location for tools in the plant",
            "type": "string"
        },
        {
            "name": "planning_table_production_versions",
            "title": "Use production versions on planning table",
            "comment": "Use production versions on planning table",
            "type": "boolean",
            "default": "false"
        },
        {
            "name": "planning_table_ignore_horizon_work_orders",
            "title": "Ignore horizon for work orders on planning table",
            "comment": "Ignores horizon for work orders on planning table",
            "type": "boolean",
            "default": false
        },
        {
            "name": "planning_table_sort_direction",
            "title": "Sort unscheduled options",
            "comment": "Sort unscheduled options",
            "type": "enum",
            "source": "UNSCHEDULED_SORT_DIRECTION",
            "values": [
                {
                    "name": "group_by_process_num",
                    "comment": "Sort by earliest start and group by process num"
                },
                {
                    "name": "sort_by_earliest_start",
                    "comment": "Sort by earliest start"
                }
            ]
        },
        {
            "name": "planning_table_save_to_leap_mode",
            "title": "Save to Leap mode",
            "comment": "Which data can be saved to server from planning table",
            "type": "enum",
            "source": "PLANNING_TABLE_SAVE_TO_LEAP_MODE",
            "default": "light",
            "values": [
                {
                    "name": "light",
                    "comment": "Only override capacity and tags"
                },
                {
                    "name": "full",
                    "comment": "Override all data, dates, sequence number, tags, capacity etc"
                }
            ]
        },
        {
            "name": "do_not_override_existing_opened_orders",
            "title": "Do not override existing open orders",
            "comment": "When using 'Save to Leap', do not override existing open orders with new orders data from plan",
            "type": "boolean",
            "default": "false",
            "editable": false
        },
        {
            "name": "write_to_erp_timeout",
            "title": "Write-to-ERP timeout",
            "comment": "Waiting period (defined in minutes) when writing to ERP",
            "type": "number",
            "default": 2
        },
        {
            "name": "show_barcode",
            "title": "Show order barcode",
            "comment": "Show ID order barcode in order modal dialog",
            "type": "boolean",
            "default": "false"
        },
        {
            "name": "contact_person",
            "title": "Contact person",
            "comment": "Contact person for the plant visible on 'Help' menu",
            "type": "string"
        },
        {
            "name": "contact_person_email",
            "title": "Contact person's e-mail address",
            "comment": "Contact person's e-mail address used for 'mailto' link on contact person's menu item",
            "type": "string"
        },
        {
            "name": "planning_table_parallel",
            "title": "Show parallel orders on planning table",
            "comment": "Show parallel orders on planning table",
            "type": "boolean",
            "default": "false"
        },
        {
            "name": "skip_too_optimistic_fallback_to_normative",
            "title": "Skip fallback to normative",
            "comment": "Skip fallback to normative in cases when model seems too optimistic",
            "type": "boolean",
            "default": "false"
        },
        {
            "name": "norm_fallback_threshold_h",
            "title": "Normative-fallback threshold for duration",
            "comment": "Threshold for duration below which the duration falls back to normative",
            "type": "number",
            "default": 8
        },
        {
            "name": "microplan_show_setup_code",
            "title": "Show setup code on microplan",
            "comment": "Indicates if material's setup code should be displayed in work-orders table on microplan",
            "type": "boolean",
            "default": "false"
        },
        {
            "name": "make_to_order",
            "title": "Make to order production strategy",
            "comment": "If true indicates that the plant is using make to order production strategy.",
            "type": "boolean",
            "default": "false"
        },
        {
            "name": "fix_line_external_order_type",
            "type": "string",
            "title": "Order type with fixed line",
            "comment": "External type of order which have fixed line"
        },
        {
            "name": "planning_table_show_ignore_order",
            "type": "boolean",
            "title": "Show 'Ignore order' action on palnning table",
            "comment": "Indicates if 'Ignore order' action should be available in advanced order menu on planning table. Default value is `false`.",
            "default": "false"
        },
        {
            "name": "planning_table_show_ignore_parent_order",
            "type": "boolean",
            "title": "Show 'Ignore parent order' action on planning table",
            "comment": "Indicates if 'Ignore parent order' action should be available in advanced order menu on planning table. Default value is `false`.",
            "default": "false"
        },
        {
            "name": "import_production_tools",
            "type": "boolean",
            "title": "Import tools for this plant",
            "comment": "Indicates if 'tools' should be imported with materials",
            "default": "false"
        },
        {
            "name": "rework_order_external_types",
            "type": "string",
            "comment": "Types of rework order",
            "title": "Rework order types"
        },
        {
            "name": "tool_setup_calc_type",
            "type": "enum",
            "source": "TOOL_SETUP_CALC_TYPES",
            "title": "Tool-setup calculation",
            "comment": "Type of calculation for tool setup, for possible values see TOOL_SETUP_CALC_TYPES",
            "values": [
                {
                    "name": "matrix",
                    "comment": "matrix based (specifies duration in hours for transitions between codes"
                },
                {
                    "name": "code_and_operation_fixed",
                    "comment": "when codes differ use tool setup duration stored on operation"
                },
                {
                    "name": "always",
                    "comment": "always use tool setup, the duration is stored on operation"
                }
            ]
        },
        {
            "name": "tool_setup_code_type",
            "type": "enum",
            "source": "TOOL_SETUP_CODE_TYPES",
            "title": "Tool-setup code",
            "comment": "Determines how the codes used by tool setup calculation are computed, for possible values see TOOL_SETUP_CODE_TYPES",
            "values": [
                {
                    "name": "material",
                    "comment": "material external id"
                },
                {
                    "name": "material_process",
                    "comment": "material external id + process number"
                },
                {
                    "name": "explicit",
                    "comment": "explicit code on operation"
                }
            ]
        },
        {
            "name": "new_feature_home_button",
            "type": "boolean",
            "title": "Show new navigation layout",
            "comment": "Shows new navigation layout with home button",
            "default": "false"
        },
        {
            "name": "enable_produce_together_with_next_operation",
            "type": "boolean",
            "default": "false",
            "title": "Enable produce together with next operation",
            "comment": "Enable logic for matching operations on import to be simulated and scheduled together."
        },
        {
            "name": "show_transaction_external_id",
            "title": "Show transaction_external_id on stock requirements",
            "comment": "Show transaction_external_id on stock requirements",
            "type": "boolean",
            "default": "false"
        },
        {
            "name": "plan_import_new_orders_skip_sim",
            "type": "boolean",
            "title": "Plan import: skip simulation for new orders",
            "comment": "Sets skip_sim flag for new orders. This means new orders will be unscheduled"
        },
        {
            "name": "new_feature_planning_table_chunking",
            "type": "boolean",
            "title": "Enable chunking of orders on planning table",
            "comment": "Enable chunking of orders on planning table"
        },
        {
            "name": "stock_requirements_date_use",
            "type": "enum",
            "source": "STOCK_REQUIREMENTS_DATE_TYPES",
            "default": "date_required",
            "title": "Stock requirement date to use",
            "comment": "Which stock requirement date to use when calculating stock requirements for this material (default is 'date_required')",
            "values": [
                {
                    "name": "date_required",
                    "comment": "Date required"
                },
                {
                    "name": "date_expected",
                    "comment": "Date expected"
                }
            ]
        },
        {
            "name": "planning_table_snap_to_shift",
            "type": "boolean",
            "default": true,
            "title": "Snap operation to shift when dragging on planning table",
            "comment": "If `true` operation snaps to the beginning of the shift under cursor when dragging by default and precise mode (without snapping) is activated by holding down SHIFT key. If `false` precise mode is the default and snapping is activated by holding down SHIFT key. Default value is `true`."
        },
        {
            "name": "planning_table_display_system_status_released",
            "type": "boolean",
            "title": "Display operations with system_status_released=false",
            "comment": "Differentiate operations with the flag system_status_released=false on the planning board. Default value is `false`.",
            "default": false
        }
    ]
};

export const PEOPLE_TAGS = {
    "name": "PEOPLE_TAGS",
    "comment": "Tags for decorating single person.",
    "type": "tags",
    "generate_translations": true,
    "values": [
        {
            "name": "reduced_worktime",
            "comment": "Number of shifts per week that this person is allowed to work.",
            "title": "Shifts per week",
            "type": "enum",
            "default": "5",
            "values": [
                {
                    "name": "0"
                },
                {
                    "name": "1"
                },
                {
                    "name": "2"
                },
                {
                    "name": "3"
                },
                {
                    "name": "4"
                },
                {
                    "name": "5"
                },
                {
                    "name": "6"
                }
            ]
        },
        {
            "name": "max_h_per_shift",
            "comment": "Number of hours per shift that this person is allowed to work.",
            "title": "Maximal hours per shift",
            "type": "number",
            "default": "8"
        },
        {
            "name": "allowed_complexities",
            "title": "Allowed complexities",
            "comment": "List of complexities that is allowed for this person",
            "type": "string",
            "default": ""
        },
        {
            "name": "shift_board_works_12h_shifts",
            "title": "12h shifts",
            "comment": "Indicates that the person is working in 12h shifts",
            "type": "boolean"
        }
    ]
};

export const ORDER_TAGS = {
    "name": "ORDER_TAGS",
    "comment": "Tags for decorating single workorder.",
    "type": "tags",
    "values": [
        {
            "name": "total_quantity_measurement_unit",
            "comment": "Measurement unit used for reporting productions",
            "title": "Measurement unit used for reporting productions",
            "type": "string"
        },
        {
            "name": "operation_unit_conversion_factor",
            "comment": "Conversion factor between order and operation measurement units",
            "title": "Conversion factor between order and operation measurement units",
            "type": "number"
        },
        {
            "name": "scheduled",
            "comment": "The operation is scheduled on the planning board",
            "title": "Operation is scheduled",
            "type": "boolean"
        },
        {
            "name": "scheduling_owner",
            "comment": "the operation dates are determined by LEAP and sent to ERP",
            "type": "boolean"
        },
        {
            "name": "delivered_quantity",
            "comment": "quantity delivered to stock by workorder",
            "type": "number"
        },
        {
            "name": "confirmed_yield",
            "comment": "quantity yielded by operation",
            "type": "number"
        },
        {
            "name": "output_stock_modified_by_triggered_production",
            "title": "Output stock modified by triggered production",
            "comment": "Flag if this operation's output_stock was modified by triggered production logic",
            "type": "boolean"
        },
        {
            "name": "parallel_order",
            "comment": "Flag if this is a parallel order, so there is a sibling somewhere",
            "type": "boolean"
        },
        {
            "name": "parallel_parent",
            "comment": "Flag if this is a parallel-parent order, so there are child orders somewhere",
            "type": "boolean"
        },
        {
            "name": "parallel_principal_child",
            "comment": "UUID of the order from which parallel order derived quantity. This order will be used when selecting the model.",
            "type": "string"
        },
        {
            "name": "split_chunk_parent",
            "comment": "Flag if this is the chunk-parent order (produced by split), so there are child-chunk orders somewhere",
            "type": "boolean"
        },
        {
            "name": "split_chunk_index",
            "comment": "This is the chunk order (produced by split) and this is it's index",
            "type": "number"
        },
        {
            "name": "produce_trigger_op",
            "comment": "UUID of operation that whose stock will be triggered.",
            "type": "string"
        },
        {
            "name": "produce_trigger_outstanding",
            "comment": "Type of handling of outstanding productions that should trigger.",
            "type": "enum",
            "values": [
                {
                    "name": "current_stock"
                },
                {
                    "name": "nop"
                },
                {
                    "name": "produced_minus_consumed"
                }
            ]
        },
        {
            "name": "produce_trigger_workdays",
            "comment": "Number of workdays in a week used when computing triggering delay",
            "type": "number"
        },
        {
            "name": "produce_trigger_delay_ms",
            "comment": "Number of milliseconds for the duration of producting the triggered production.",
            "type": "number"
        },
        {
            "name": "unconfirmed_stock_ignore_production",
            "comment": "If true, unconfirmed production logic will ignore productions of this order",
            "type": "boolean"
        },
        {
            "name": "operation_was_rewired",
            "comment": "If true, it means that the operation was rewired (bom inputs and material changed)",
            "type": "boolean"
        },
        {
            "name": "priority",
            "comment": "Order's priority, will be used for scheduling. From highest (A) to lowest (E). Medium (C) is the default.",
            "type": "enum",
            "default": "C",
            "source": "ORDER_PRIORITIES",
            "values": [
                {
                    "name": "A",
                    "comment": "Highest priority"
                },
                {
                    "name": "B",
                    "comment": "High priority"
                },
                {
                    "name": "C",
                    "comment": "Medium priority"
                },
                {
                    "name": "D",
                    "comment": "Low priority"
                },
                {
                    "name": "E",
                    "comment": "Lowest priority"
                }
            ]
        },
        {
            "name": "production_tool_external_id",
            "comment": "External id of the production tool on order (part_number field from ERP)",
            "type": "string"
        },
        {
            "name": "production_tool_title",
            "comment": "Title of the production tool on order.",
            "type": "string"
        },
        {
            "name": "bom_external_id",
            "comment": "External id of bom that was used.",
            "type": "string"
        },
        {
            "name": "superior_order_external_id",
            "comment": "Superior order external id",
            "type": "string"
        },
        {
            "name": "bom_usage",
            "comment": "External id of bom usage.",
            "type": "string"
        },
        {
            "name": "production_version",
            "comment": "External id of production version.",
            "type": "string"
        },
        {
            "name": "routing_external_id",
            "comment": "External id of routing.",
            "type": "string"
        },
        {
            "name": "routing_group_external_id",
            "comment": "External id of routing group.",
            "type": "string"
        },
        {
            "name": "routing_item_external_id",
            "comment": "External id of routing item.",
            "type": "string"
        },
        {
            "name": "task_list_type",
            "comment": "External id of task list type (routing type).",
            "type": "string"
        },
        {
            "name": "insight_freeze_order",
            "title": "Freeze order",
            "comment": "If this value is set to `true`, automatic planning will not move the order",
            "type": "boolean"
        },
        {
            "name": "freeze_time_start",
            "comment": "Start time of the frozen order in miliseconds",
            "type": "string"
        },
        {
            "name": "freeze_time_end",
            "comment": "End time of the frozen order in miliseconds",
            "type": "string"
        },
        {
            "name": "comment",
            "comment": "Custom comment for order"
        },
        {
            "name": "shadow_of",
            "comment": "External id of operation that this operation is shadow-ing.",
            "type": "string"
        },
        {
            "name": "shadow_factor",
            "comment": "Factor for conversion of quantity when shadow-ing. Parent operation's output quantity should be multiplied by this factor to get this operation's output quantity.",
            "type": "number"
        },
        {
            "name": "subline_index",
            "comment": "Zero based index of subline to which the operation is assigned. Used with combination of sublines_number line tag. Default is 0.",
            "type": "number",
            "default": 0
        },
        {
            "name": "fix_line",
            "type": "string",
            "comment": "Order is fixed on the line with value being line's UUID"
        },
        {
            "name": "external_parent_order_id",
            "type": "string",
            "comment": "External parent order ID"
        },
        {
            "name": "external_parent_order_position",
            "type": "string",
            "comment": "External parent order position"
        },
        {
            "name": "conditional_capacity_constraint_code",
            "type": "string",
            "title": "Conditional capacity constraint code",
            "comment": "Operations with the same code can be executed simultaneously"
        },
        {
            "name": "tool_setup_code",
            "type": "string",
            "title": "Tool setup code",
            "comment": "Used when determining if tool setup is needed"
        },
        {
            "name": "ignore_on_planning_board",
            "type": "boolean",
            "title": "Ignore on planning board",
            "comment": "If true, order will not be shown on planning board"
        },
        {
            "name": "manual_entry_order_edited",
            "type": "boolean",
            "default": false,
            "title": "Manual-entry order edit state",
            "comment": "Indicates if order was edited by user. Default is `false`."
        },
        {
            "name": "manual_entry_order_time_end_edited",
            "type": "boolean",
            "default": false,
            "title": "Manual-entry order edit state",
            "comment": "Indicates if time end of order was edited by user. Default is `false`."
        },
        {
            "name": "manual_entry_order_required_deadline_edited",
            "type": "boolean",
            "default": false,
            "title": "Manual-entry order edit state",
            "comment": "Indicates if required date of order was edited by user. Default is `false`."
        },
        {
            "name": "linked_operation",
            "comment": "UUID of linked operation.",
            "type": "string"
        },
        {
            "name": "required_deadline",
            "type": "number",
            "default": 0,
            "title": "Required deadline",
            "comment": "Required deadline for manual-entry order"
        },
        {
            "name": "produce_together_with_next_operation",
            "type": "boolean",
            "default": false,
            "comment": "Indicator that operation should be scheduled to start and finish together with the following uperation and have duration equal to larger of current and following operation."
        },
        {
            "name": "order_external_type",
            "type": "string",
            "title": "Order external type",
            "comment": "Order external type"
        },
        {
            "name": "erp_date_determination",
            "type": "boolean",
            "comment": "ERP defines start and end time for operation that is used in simulation."
        },
        {
            "name": "productions_initial_import",
            "type": "boolean",
            "title": "Imported all initial productions",
            "comment": "Imported all productions regardless of time window for fetching productions",
            "default": false
        },
        {
            "name": "system_status_released",
            "type": "boolean",
            "title": "Released status of production order",
            "comment": "Released production order refers to an order that has been created and released for production execution. Default is `true`.",
            "default": true
        }
    ]
};

export const STOCK_REQUIREMENT_TAGS = {
    "name": "STOCK_REQUIREMENT_TAGS",
    "comment": "Tags for decorating single stock-requirement.",
    "type": "tags",
    "values": [
        {
            "name": "priority",
            "comment": "Stock-requirements's priority, will be used for scheduling. From highest (A) to lowest (E). Medium (C) is the default.",
            "type": "enum",
            "source": "ORDER_PRIORITIES",
            "values": [
                {
                    "name": "A",
                    "comment": "Highest priority"
                },
                {
                    "name": "B",
                    "comment": "High priority"
                },
                {
                    "name": "C",
                    "comment": "Medium priority"
                },
                {
                    "name": "D",
                    "comment": "Low priority"
                },
                {
                    "name": "E",
                    "comment": "Lowest priority"
                }
            ]
        }
    ]
};

export const STOCK_LOCATION_TAGS = {
    "name": "STOCK_LOCATION_TAGS",
    "comment": "Tags for decorating single stock-location.",
    "type": "tags",
    "generate_translations": true,
    "values": [
        {
            "name": "affiliated_site",
            "comment": "If true indicates that the storage location is owned by another plant and only affiliated with plant.",
            "title": "Affiliated site",
            "type": "boolean"
        },
        {
            "name": "derived",
            "comment": "Flag if this stock-location is derived from another one.",
            "title": "Derived",
            "type": "boolean",
            "editable": false
        },
        {
            "name": "production_site",
            "comment": "If true indicates that the storage location is available on the shopfloor.",
            "title": "Production site",
            "type": "boolean"
        },
        {
            "name": "safety_stock",
            "comment": "Flag if this stock-location represents safety stock.",
            "type": "boolean",
            "title": "Safety stock",
            "editable": false
        },
        {
            "name": "skip_timeseries",
            "comment": "If true indicates new timeseries will not be created for this stock location.",
            "title": "Skip timeseries",
            "type": "boolean"
        },
        {
            "name": "storage_site",
            "comment": "If true indicates that the storage location is not available on the shopfloor but can be retrieved with some time cost when needed.",
            "title": "Storage site",
            "type": "boolean"
        },
        {
            "name": "withdrawal",
            "comment": "If true indicates that the storage location is used for material withdrawals. This is used for stock_requirements. Default value is `false`.",
            "title": "Withdrawal",
            "type": "boolean",
            "editable": false
        },
        {
            "name": "work_orders",
            "title": "Work orders internal stock",
            "comment": "If true indicates that the storage location is a system stock location used for tracking withdrawn and consumed on work orders. Default value is `false`.",
            "type": "boolean",
            "editable": false
        },
        {
            "name": "yield_not_delivered",
            "title": "Yield not delivered",
            "comment": "If true indicates that the storage location is a system stock location used for tracking quantity yielded on operation that outputs to stock but is not delivered to stock yet. Default value is `false`.",
            "type": "boolean",
            "editable": false
        },
        {
            "name": "make_to_order",
            "title": "Make to order",
            "comment": "If true indicates that the storage location is a system stock location used for tracking withdrawn and consumed on make to order orders. Default value is `false`.",
            "type": "boolean",
            "editable": false
        },
        {
            "name": "shared_consumption",
            "title": "Shared consumption",
            "comment": "If true indicates that the storage location can be used by other plants in shared consumption mode. Default value is `false`.",
            "type": "boolean",
            "default": "false",
            "editable": true
        },
        {
            "name": "returns",
            "comment": "If true indicates that the storage location is used for returned items.",
            "title": "Returns",
            "type": "boolean"
        }
    ]
};

export const MATERIAL_TAGS = {
    "name": "MATERIAL_TAGS",
    "type": "tags",
    "comment": "Tags for individual material. For additional tags, specific to ERP, see extra documentation at the bottom.",
    "generate_translations": true,
    "values": [
        {
            "name": "type_external",
            "title": "External type",
            "comment": "A string that encodes the external type code. By default the type is not known.",
            "type": "string"
        },
        {
            "name": "type_source",
            "title": "External type source",
            "comment": "A string that encodes the external source that defined the type code (SAP for example). By default the source is unknown. An example is `SAP`.",
            "type": "string"
        },
        {
            "name": "skip_stock_analysis",
            "title": "Skip stock analysis",
            "comment": "Ignore this material when doing stock forecasting and alerting. Default is `false`",
            "type": "boolean"
        },
        {
            "name": "skip_input_tracking",
            "title": "Skip input tracking",
            "comment": "Always skip input tracking for this material in planning. Default is `false`",
            "type": "boolean"
        },
        {
            "name": "skip_planning",
            "title": "Skip planning",
            "comment": "Ignore this material when scanning orders. Default is `false`",
            "type": "boolean"
        },
        {
            "name": "material_subcontracted",
            "title": "Material subcontracted",
            "comment": "Indicates that this material is currently being produced by a subcontractor. Default is `false`",
            "type": "boolean"
        },
        {
            "name": "complexity",
            "title": "Complexity",
            "comment": "A string indicating material complexity",
            "type": "string"
        },
        {
            "name": "setup_code",
            "title": "Setup code",
            "comment": "Code indicating setup id for this material. Will be used by planning together with a switch-matrix to indicate complexity of switching between certain materials.",
            "type": "string"
        },
        {
            "name": "parallel",
            "title": "Parallel material",
            "comment": "Flag indicating this material is meta-material placeholder for parallel materials.",
            "type": "boolean"
        },
        {
            "name": "font_color",
            "title": "Font color",
            "comment": "Font color for setup code",
            "type": "string"
        },
        {
            "name": "background_color",
            "title": "Background color",
            "comment": "Background color for setup code",
            "type": "string"
        },
        {
            "name": "comment",
            "title": "Comment",
            "comment": "Comment for material",
            "type": "string"
        },
        {
            "name": "make_to_order",
            "title": "Make to order",
            "comment": "Flag indicating this material should be closely coupled with specific order (make to order). Default is `false`",
            "type": "boolean"
        },
        {
            "name": "external_parent_material_id",
            "title": "External parent material ID",
            "comment": "External parent material ID",
            "type": "string"
        },
        {
            "name": "external_parent_order_id",
            "title": "External parent order ID",
            "comment": "External parent order ID",
            "type": "string"
        },
        {
            "name": "material_type",
            "title": "Material type",
            "comment": "Material type",
            "type": "string"
        },
        {
            "name": "material_client",
            "title": "Material client",
            "comment": "Material client",
            "type": "string"
        },
        {
            "name": "material_client_project",
            "title": "Material client project",
            "comment": "Material client + material project",
            "type": "string"
        },
        {
            "name": "material_project",
            "title": "Material project",
            "comment": "Material project",
            "type": "string"
        },
        {
            "name": "material_product_type",
            "title": "Material product type",
            "comment": "Material product type",
            "type": "string"
        },
        {
            "name": "old_material_no",
            "title": "Alternative name for material",
            "comment": "Alternative name for material",
            "type": "string"
        },
        {
            "name": "raw_material",
            "title": "Raw material",
            "comment": "External type is ROH",
            "type": "boolean",
            "default": false,
            "editable": false
        },
        {
            "name": "half_product",
            "title": "Half product",
            "comment": "External type is HALB",
            "type": "boolean",
            "default": false,
            "editable": false
        },
        {
            "name": "finished_product",
            "title": "Finished product",
            "comment": "External type is FERT",
            "type": "boolean",
            "default": false,
            "editable": false
        },
        {
            "name": "procurement_code_external",
            "title": "External procurement type code",
            "comment": "A string that encodes the external procurement type code. By default the type is not known.",
            "type": "string"
        },
        {
            "name": "production_resource_status",
            "title": "Tool status",
            "comment": "Tool status",
            "type": "enum",
            "values": [
                {
                    "name": "maintenance"
                },
                {
                    "name": "ready"
                }
            ]
        },
        {
            "name": "production_resource_set_to",
            "title": "Tool set to",
            "comment": "External ID of the material the tool is set to.",
            "type": "string"
        }
    ]
};

export const MATERIAL_PLANT_TAGS = {
    "name": "MATERIAL_PLANT_TAGS",
    "type": "tags",
    "comment": "Tags for material+plant combination.",
    "generate_translations": true,
    "values": [
        {
            "name": "material_subcontracted",
            "type": "boolean",
            "title": "Material subcontracted",
            "comment": "Indicating that subcontracting is supplying this material. Default is `false`"
        },
        {
            "name": "material_subcontracting_lead_time",
            "type": "number",
            "title": "Material subcontracting lead time",
            "comment": "Specify delivery time for chunk orders in hours."
        },
        {
            "name": "bulk",
            "title": "Bulk",
            "comment": "If true it indicates that the material is a bulk material. By default `false`.",
            "type": "boolean"
        },
        {
            "name": "dummy",
            "title": "Dummy",
            "comment": "If true it indicates that the material is a dummy (phantom) material. By default `false`.",
            "type": "boolean"
        },
        {
            "name": "subcontractor",
            "title": "Subcontractor",
            "comment": "If true it indicates that the material is a subcontractor material. By default `false`.",
            "type": "boolean"
        },
        {
            "name": "stock_requirements_import_include_types",
            "title": "Stock-requirements import include types",
            "comment": "Stock-requirements import types to include for the material. E.g: AR,BA,BE,VE",
            "type": "string"
        },
        {
            "name": "stock_requirements_import_exclude_types",
            "title": "Stock requirements import exclude types",
            "comment": "Stock-requirements import-types to exclude for the material. E.g: AR,BA,BE,VE",
            "type": "string"
        },
        {
            "name": "moving_pr",
            "title": "Moving price",
            "comment": "Moving average price/periodic unit price",
            "type": "string"
        },
        {
            "name": "std_price",
            "title": "Std price",
            "comment": "Standard price",
            "type": "string"
        },
        {
            "name": "price_unit",
            "title": "Price unit",
            "comment": "Price unit",
            "type": "string"
        },
        {
            "name": "currency",
            "title": "Currency",
            "comment": "Currency",
            "type": "string"
        },
        {
            "name": "currency_iso",
            "title": "Currency ISO",
            "comment": "ISO code of currency",
            "type": "string"
        },
        {
            "name": "consumes_from_plant",
            "title": "Consumes from plant",
            "comment": "The uuid from the plant that this material consumes from",
            "type": "string"
        },
        {
            "name": "procurement_code",
            "title": "Procurement code",
            "comment": "A string that encodes the procurement type code",
            "type": "string"
        }
    ]
};

export const ORG_TAGS = {
    "name": "ORG_TAGS",
    "type": "tags",
    "comment": "Tags that are assignable to single organization",
    "values": [
        {
            "name": "skip_insights",
            "type": "boolean",
            "comment": "Skip preparations of insights for this org"
        },
        {
            "name": "skip_check_all",
            "type": "boolean",
            "comment": "Skip all manufacturing checks"
        },
        {
            "name": "skip_check_plan",
            "type": "boolean",
            "comment": "Skip plan checks"
        },
        {
            "name": "skip_check_production",
            "type": "boolean",
            "comment": "Skip production checks"
        },
        {
            "name": "skip_check_report",
            "type": "boolean",
            "comment": "Skip report checks"
        },
        {
            "name": "skip_check_stock",
            "type": "boolean",
            "comment": "Skip stock checks"
        },
        {
            "name": "skip_check_stock_requirements",
            "type": "boolean",
            "comment": "Skip stock requirements checks"
        },
        {
            "name": "skip_check_downtimes",
            "type": "boolean",
            "comment": "Skip downtime checks"
        },
        {
            "name": "use_sim2",
            "type": "boolean",
            "comment": "Use sim2 algorithm"
        },
        {
            "name": "stock_check_allowed_delay",
            "type": "number",
            "comment": "Maximal allowed age (in hours) of stock data that we allow. Default is 25.",
            "default": 25
        },
        {
            "name": "stock_requirements_check_allowed_delay",
            "type": "number",
            "comment": "Maximal allowed age (in hours) of stock requirements data that we allow. Default is 25.",
            "default": 25
        },
        {
            "name": "plan_check_allowed_delay",
            "type": "number",
            "comment": "Maximal allowed age (in hours) of plan data that we allow. Default is 1.5.",
            "default": 1.5
        },
        {
            "name": "use_recently_closed",
            "type": "boolean",
            "default": "false",
            "comment": "Flag if recently closed orders should be used in simulation. Default is 'false'"
        },
        {
            "name": "manual_order_entry_automatic_import",
            "type": "boolean",
            "default": "false",
            "title": "Automatic import of manual-entry orders",
            "comment": "Indicates if chunk orders on manual-entry lines are automatically imported. Default is `false`."
        },
        {
            "name": "operation_quantity_compensating",
            "type": "boolean",
            "default": "true",
            "comment": "Flag to enable disabling operation realized quantity compensation, which makes realization within order decrease monotonicaly over operations."
        },
        {
            "name": "autocreate_users_from_people",
            "type": "boolean",
            "default": "false",
            "comment": "Flag if LEAP users should be automatically created from people list, when requested. Default is 'false'"
        },
        {
            "name": "enable_sync_extra_line_order_changes",
            "type": "boolean",
            "default": "false",
            "title": "Sync of changes on extra lines",
            "comment": "Enable syncing of changes on extra lines on planning board for the case when changes on the planned lines affect scheduling status or timing of orders of extra lines (e.g. autopilot)."
        },
        {
            "name": "ignored_materials",
            "type": "string",
            "default": "",
            "title": "Ignored materials",
            "comment": "Comma separated list of external_types of materials that should be ignored in planning. Default is empty string."
        }
    ]
};

export const SENSOR_TAGS = {
    "name": "SENSOR_TAGS",
    "type": "tags",
    "comment": "Tags that are assignable to single sensor",
    "values": [
        {
            "name": "line_external_id",
            "type": "string",
            "comment": "External id of line that this sensor is associated with"
        }
    ]
};

export const TOOL_TAGS = {
    "name": "TOOL_TAGS",
    "type": "tags",
    "comment": "Tags that are assignable to single tool",
    "values": [
        {
            "name": "active",
            "type": "boolean",
            "comment": "Flag that denotes if this tool is active"
        },
        {
            "name": "material_external_id",
            "type": "string",
            "comment": "Optional indication which material is produced by this tool, if applicable."
        }
    ]
};

export const INSIGHT_TAGS = {
    "name": "INSIGHT_TAGS",
    "type": "tags",
    "comment": "Tags that are assignable to single insight",
    "values": [
        {
            "name": "analysis_uuid",
            "type": "string",
            "comment": "UUID of the analysis that produced this event (insight)"
        },
        {
            "name": "datasource_uuid",
            "type": "string",
            "comment": "UUID of the datasource that contains data that this event (insight) is based on"
        },
        {
            "name": "hash",
            "type": "string",
            "comment": "Hash of this insight. Used for de-duplication and updates."
        },
        {
            "name": "line_uuid",
            "type": "string",
            "comment": "UUID of the associated line"
        },
        {
            "name": "line_uuids",
            "type": "string",
            "comment": "Associated line uuids, concatenated with ','"
        },
        {
            "name": "material_external_id",
            "type": "string",
            "comment": "External id of the associated material"
        },
        {
            "name": "material_external_type",
            "type": "string",
            "comment": "External type of the associated material"
        },
        {
            "name": "material_title",
            "type": "string",
            "comment": "Title of the associated material"
        },
        {
            "name": "material_uuid",
            "type": "string",
            "comment": "UUID of the associated material"
        },
        {
            "name": "material_uuids",
            "type": "string",
            "comment": "Associated material uuids, concatenated with ','"
        },
        {
            "name": "ongoing",
            "type": "boolean",
            "comment": "True when insight already started and is still ongoing"
        },
        {
            "name": "operation_no_input_material_circle",
            "type": "enum",
            "values": [
                {
                    "name": "internal",
                    "comment": "Only account for changes due to internal production and consumption"
                },
                {
                    "name": "external",
                    "comment": "Internal + changes due to stock requirements and deliveries"
                }
            ],
            "comment": "From what circle is the input material shortage, more possible"
        },
        {
            "name": "order_external_id",
            "type": "string",
            "comment": "External id of the associated work-order"
        },
        {
            "name": "order_process_num",
            "type": "string",
            "comment": "Operation process number"
        },
        {
            "name": "order_uuid",
            "type": "string",
            "comment": "UUID of the associated work-order"
        },
        {
            "name": "order_uuids",
            "type": "string",
            "comment": "UUIDs of the associated work-orders, concatenated with ','"
        },
        {
            "name": "plant_external_id",
            "type": "string",
            "comment": "External ID of associated plant"
        },
        {
            "name": "plant_title",
            "type": "string",
            "comment": "Title of the associated plant"
        },
        {
            "name": "plant_uuid",
            "type": "string",
            "comment": "UUID of the associated plant"
        },
        {
            "name": "shift_tag",
            "type": "string",
            "comment": "Tag of the shift to which this insight points"
        },
        {
            "name": "ticket_uuids",
            "type": "string",
            "comment": "UUIDs (separated by ',') of tickets that are associated with this insight"
        },
        {
            "name": "translated_description",
            "type": "string",
            "comment": "Prefix for description translations"
        },
        {
            "name": "translated_title",
            "type": "string",
            "comment": "Prefix for title translations"
        },
        {
            "name": "below_safety_stock",
            "type": "boolean",
            "comment": "No input material insight still holds even if we allow orders to use safety stock"
        }
    ]
};

export const PRODUCTION_TAGS = {
    "name": "PRODUCTION_TAGS",
    "type": "tags",
    "comment": "Tags that are assignable to a production",
    "values": [
        {
            "name": "subcontractor_delivery_document_id",
            "type": "string",
            "comment": "Delivery document ID, for productions based on receiving materials from subcontractor"
        }
    ]
};

export const PROCESS_TAGS = {
    "name": "PROCESS_TAGS",
    "type": "tags",
    "comment": "Tags that are assignable to a process",
    "values": [
        {
            "name": "use_in_planning",
            "type": "boolean",
            "comment": "This process can be used as alternative in the automatic planning process"
        },
        {
            "name": "manual_use_in_planning",
            "type": "boolean",
            "comment": "Process can be used in manual mode on planning table"
        },
        {
            "name": "production_versions",
            "type": "string",
            "comment": "These production versions are applicable (comma separated production version external ids)"
        },
        {
            "name": "preferred",
            "type": "boolean",
            "default": false,
            "comment": "The line related to the process is preferred"
        }
    ]
};
