// @flow
import * as React from "react";
import { Modal } from "react-bootstrap";
import { translate } from "../IntlProviderWrapper";


type Props = {
    onAccept: () => void,
    onCancel: () => void,
    show: boolean
}

export const AreYouSureModal = (props: Props) => {
    if (!props.show) {
        return null;
    }

    return <Modal.Dialog className="contained-modal" show={props.show} onHide={props.onCancel}>
        <Modal.Header>
            <Modal.Title>
                <span>{translate("common.are_you_sure2", "Are you sure you wish to continue?")}</span>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {translate("common.are_you_sure3", "This action will overwrite your current changes. Are you sure you want to continue?")}
        </Modal.Body>
        <Modal.Footer>
            <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                <button className="btn btn-primary" onClick={props.onAccept}>
                    {translate("common.yes", "Yes")}
                </button>
                <button className="btn btn-primary" onClick={props.onCancel}>
                    {translate("common.no", "No")}
                </button>
            </div>
        </Modal.Footer>
    </Modal.Dialog>
}
