// @flow
import * as React from "react";
import { getBackend as getBackend2 } from "../lib/backend/Backend2";
import { QRCodeCanvas } from "qrcode.react";
import { FormattedMessage } from "react-intl";
import ErrorComponent from "./ErrorComponent";

type State = {
    twofa: boolean,
    secret: string,
    otp: string,
    disableTwoFA: boolean,
    showQRCode: boolean,
    message: string,
    success?: string
};

type Props = {
    twofa?: boolean,
    showQRCode?: boolean,
    twofaEnable?: boolean,
    onClose?: () => void,
    onSave?: () => void
}

class TwoFA extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            twofa: this.props.twofa ?? false,
            secret: "",
            otp: "",
            disableTwoFA: false,
            showQRCode: this.props.showQRCode || false,
            message: ""
        };
    }

    async componentDidMount() {
        const twofaEnableProps = this.props.twofaEnable;
        if (twofaEnableProps) {
            const secret = await getBackend2().users.generateKey2fa({ twofa: twofaEnableProps });
            this.setState({ secret: secret.otpauth_url, showQRCode: true, twofa: true, otp: "" });
        }
    }

    handle2FAButton = async (event: Event) => {
        const twofa: boolean = !this.state.twofa;
        if (twofa) {
            const secret = await getBackend2().users.generateKey2fa({ twofa: twofa });
            this.setState({ secret: secret.otpauth_url, showQRCode: true, twofa: true, otp: "", message: "", disableTwoFA: false });
        } else {
            this.setState({ disableTwoFA: true, otp: "" });
        }
    }

    handleChange = (event: Event) => {
        let target = event.target;
        if (target instanceof HTMLInputElement) {
            // collect changed field name and value
            const field = target.name;
            const value = target.value;
            // update
            this.setState(prev_state => {
                prev_state[field] = value;
                return prev_state;
            });
        }
    }

    handleSubmit = async (event: Event) => {
        event.preventDefault();
        try {
            const verified = await getBackend2().users.verifyOTP({ otp: this.state.otp });
            if (verified.verified) {
                this.setState({ twofa: true, showQRCode: false, message: "", success: "2FA activated" })
            }

            if (this.props.onSave) {
                this.props.onSave();
            }

        } catch (err) {
            this.setState({ message: err.message });
        }
    }

    handleSubmitDeactivate = async (event: Event) => {
        event.preventDefault();
        try {
            const verified = await getBackend2().users.disable2FA({ otp: this.state.otp });
            if (verified) {
                this.setState({ twofa: false, showQRCode: false, message: "", success: "2FA deactivated" });
            }
        } catch (err) {
            this.setState({ message: err.message });
        }
    }

    handleCancel = () => {
        if (this.props.onClose) {
            this.props.onClose();
        } else {
            this.setState({ twofa: false, showQRCode: false, message: "" });
        }
    }

    handleCancelDeactivate = () => {
        this.setState({ showQRCode: false, message: "", disableTwoFA: false });
    }

    renderSuccess = () => {
        if (this.state.success) {
            <ErrorComponent msg={this.state.success} type="success" />
        }
        return null;
    }

    renderQRCode = () => {
        if (this.state.showQRCode) {
            return <>
                <div className="mb-5 text-center">
                    <FormattedMessage id="common.twofa_scan_activate_text" defaultMessage="Scan QR Code with your Google Authenticator app. Type generated OTP (One Time Passowrd) into the input field." />
                </div>
                <div>
                    <QRCodeCanvas value={this.state.secret} />
                </div>
                <div className="form-group">
                    {this.state.message !== "" && this.renderAlert()}
                    <input name="otp" type="text" disabled="" placeholder="OTP" className="form-control"
                        value={this.state.otp} onChange={this.handleChange} />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <button className="btn_save btn btn-default" onClick={this.handleSubmit}>
                        <FormattedMessage id="common.save" defaultMessage="Save" />
                    </button>
                    <button className="btn_cancel btn" onClick={this.handleCancel}>
                        <FormattedMessage id="common.close" defaultMessage="Close" />
                    </button>
                </div>
            </>
        } else {
            if (this.state.disableTwoFA === true) {
                return <>
                    <div className="text-center">
                        <FormattedMessage id="common.twofa_scan_text" defaultMessage="Type generated OTP (One Time Passowrd) into the input field." />
                    </div>
                    <div className="form-group">
                        {this.state.message !== "" && this.renderAlert()}
                        <input name="otp" type="text" disabled="" placeholder="OTP" className="form-control"
                            value={this.state.otp} onChange={this.handleChange} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <button className="btn_save btn btn-default" onClick={this.handleSubmitDeactivate}>
                            <FormattedMessage id="Deactivate 2FA" defaultMessage="Deactivate 2fa" />
                        </button>
                        <button className="btn_cancel btn" onClick={this.handleCancelDeactivate}>
                            <FormattedMessage id="common.close" defaultMessage="Close" />
                        </button>
                    </div>
                </>
            } else {
                return <>
                    <button className="btn" onClick={this.handle2FAButton}>
                        <FormattedMessage id="Settings.reset_2fa" defaultMessage="Remove 2FA" />
                    </button>
                </>
            }

        }
    }

    renderAlert() {
        return (
            <div className="row">
                <div className="col-12">
                    <ErrorComponent msg={this.state.message} type="warning" />
                </div>
            </div>
        );
    }

    render2FA = () => {
        if (this.state.twofa) {
            return <div>
                {this.renderSuccess()}
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    {this.renderQRCode()}
                </div>
            </div>
        } else {
            return <div>
                {this.renderSuccess()}
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <button className="btn" onClick={this.handle2FAButton}>
                        <FormattedMessage id="Settings.set_2fa" defaultMessage="Set 2FA" />
                    </button>
                </div>
            </div>
        }

    }

    render() {
        return (
            <div>
                {this.render2FA()}
            </div>
        );
    }
}

export default TwoFA;
