// @flow

import * as React from "react";
import * as t from "../lib/backend/alerts.generated.types";
import { renderEventListRow } from "./EventDetails";
import ChevronListItem from "./react/ChevronListItem";

type Props = {
    selected: Object | null,
    events: Array<t.IEventData>,
    handleEventClick: (t.IEventData) => void
};

type State = {};

// Prepares a list of events to be displayed on event page as a scrollable side bar on the left
class EventList extends React.Component<Props, State> {

    handleEventClick(event: t.IEventData, i: number) {
        this.props.handleEventClick(event);
    }

    // prepare list of events
    render() {
        // check what is the id of currently selected event so we can highlight it
        const selected_uuid = this.props.selected && this.props.selected.event ?
            this.props.selected.event.uuid : "";
        // render list of events to HTML
        const eventList = this.props.events.map((event: t.IEventData, i: number) => {
            // check if event is selected
            let list_data = renderEventListRow(event, i);
            return <ChevronListItem
                text={list_data.extra_data}
                is_active={event.uuid === selected_uuid}
                onClick={() => this.handleEventClick(event, i)}
            />
        });
        // pack inside list group and return
        return (
            <ul className="nav nav-tabs">
                <div className="nav-tabs-parent" ref="nav">
                    {eventList}
                </div>
            </ul>
        );
    }
}

export default EventList;
