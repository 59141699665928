// @flow

// main imports
import React, { Component } from "react";

// defining types
type Props = {
    value: boolean[],
    onChange: any
};

type State = {
    shifts: boolean[]
};

/**
 * Displaying production lines list.
 */
class PeopleShiftEdit extends Component<Props, State> {
    /**
     * Constructor.
     *
     * @param {Props} props Props object.
     * @param {State} state State object.
     */
    constructor(props: Props, state: State) {
        super(props, state);
        this.state = {
            shifts: [ false, false, false ],
        }
    }

    componentDidUpdate(prevProps: Props) {
        // update state with new props
        if (JSON.stringify(prevProps.value) !== JSON.stringify(this.props.value)){
            this.setState({ shifts: this.props.value });
        }
    }

    badgeClick(i: number) {
        let shifts = this.state.shifts;
        shifts[i] = !shifts[i];
        this.setState({shifts: shifts});

        // e.currentTarget = this;
        this.props.onChange(shifts);
    }

    render() {
        let preferences = this.state.shifts.map((val, i) => {
            let styles = [ "shift badge-shift-1", "shift badge-shift-2", "shift badge-shift-3" ];
            let myStyle = "badge badge-"
            if (this.state.shifts[i] !== false) {
                myStyle += styles[i];
            } else {
                myStyle += "light";
            }
            return <span key={i}
                style={{fontSize: "30px", marginLeft: "2px", cursor: "pointer"}}>
                    <span
                        onClick={() => this.badgeClick(i)}
                        className={myStyle}>
                            {i + 1}
                    </span>
                </span>
        })
        return (
            <div>
                {preferences}
            </div>
        );
    }
}

export default PeopleShiftEdit;