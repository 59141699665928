// @flow
import * as React from "react";
import { Tabs, Tab, Modal } from "react-bootstrap";
import moment from "moment";
import * as XLSX from "xlsx";

import { uniqueValues, classNames, niceNumber, niceDate } from "../lib/Util";
import { getBackend } from "../lib/backend/Backend2";
import { LINE_TAGS_ACCESS } from "../lib/ManufacturingTags.generated";
import { JOB_STATUS } from "../lib/CommonConsts.generated";
import { translate } from "./IntlProviderWrapper";
import { getLineGroupsForUser } from "../lib/BusinessLogic";
import * as Auth from "../lib/Auth";

import DateTimePicker from "./DateTimePicker";
import ErrorComponent from "./ErrorComponent";
import Loader from "./Loader";
import ReactTable from "./react/ReactTable";
import TreeView from "./TreeView";

import * as t from "../lib/backend/manufacturing2.generated.types";
import type { Column, Cell, Row, ColumnInstance, FilterProps } from "react-table";
import type { TreeColumn, TreeNode } from "./TreeView";

// $FlowFixMe
import "../styles/electricity.scss";

const SETTINGS_FILE_NAME = "electricity_settings.json";
const INPUT_FILE_NAME = "electricity_input.json";

const MAIN_VIEWS = {
    input: "input",
    result: "result"
};

const MATERIAL_PROFILE_VIEWS = {
    input_materials: "input_materials",
    stock_realization: "stock_realization",
    duration_realization: "duration_realization"
};

const MATERIAL_PROFILE_COLUMNS = {
    material: "material",
    profit_center_id: "profit_center_id",
    duration: "duration",
    stock: "stock",
    measurement_unit: "measurement_unit",
    electricity_consumption: "electricity_consumption",
    total_electricity_consumption: "total_electricity_consumption",
    missing_input_materials: "missing_input_materials",
    actions: "actions"
};

const MATERIAL_PROFILE_REALIZATION_COLUMNS = {
    line: "line",
    process_num: "process_num",
    shift_date: "shift_date",
    shift_number: "shift_number",
    duration: "duration",
    quantity: "quantity",
    measurement_unit: "measurement_unit"
};

const CONSUMPTION_SCOPES = {
    profit_center: "profit_center",
    extras: "extras"
};

type MaterialColumn = {
    title: string,
    external_id: string
};

type MaterialProfileRow = {
    material: MaterialColumn,
    profit_center_id: string,
    duration: number,
    stock: number,
    measurement_unit: string,
    electricity_consumption: number,
    total_electricity_consumption: number,
    missing_input_materials: boolean,
    actions: t.IElectricityOutputMaterial
};

type ProfitCenterRatio = {
    profit_center_id: string,
    ratio: number
};

type ProfitCenterSplit = {
    profit_center_id: string,
    split: number
};

type ExtraRow = {
    name: string,
    profit_center_splits: ProfitCenterSplit[]
};

type IgnoreBom = {
    input_material_external_id: string,
    output_material_external_id: string
};

type ElectricitySettings = {
    profit_center_ratios: ProfitCenterRatio[],
    extras_matrix: ExtraRow[],
    ignore_bom: IgnoreBom[]
};

type LineColumn = {
    title: string,
    external_id: string
};

type MaterialRealizationRow = {
    line: LineColumn,
    process_num: string,
    shift_date: string,
    shift_number: number,
    duration: number,
    quantity: number
};

type InputMaterialRow = {
    material: MaterialColumn,
    factor: number,
    profit_center: string,
    duration: number,
    stock: number,
    measurement_unit: string,
    net_consumption: number,
    gross_consumption: number,
    missing: boolean
};

type ConsumptionInput = Map<string, number | null>;

type ConsumptionScope = $Keys<typeof CONSUMPTION_SCOPES>;

type ConsumptionInputKeyMap = {
    scope: ConsumptionScope,
    name: string,
    year: number,
    month: number
};

type Props = {};

type State = {
    error: string,
    is_electricity_admin: boolean,
    is_electricity_user: boolean,
    profit_center_ids: string[],
    visible_profit_center_ids: string[],
    settings: ElectricitySettings | null,
    consumption_input: ConsumptionInput,
    is_calculating: boolean,
    is_loading: boolean,
    is_saving: boolean,
    selected_tab: string,
    calculation_time_from: Date,
    calculation_time_to: Date,
    input_time_from: Date,
    input_time_to: Date,
    input: t.INewElectricityReportReq | null,
    stored_input: string | null,
    profit_center_profiles: t.IElectricityOutputProfitCenter[],
    material_profiles: t.IElectricityOutputMaterial[],
    selected_material_profile: t.IElectricityOutputMaterial | null,
    show_material_profile_details: $Keys<typeof MATERIAL_PROFILE_VIEWS> | null,
    material_filter: string,
    profit_center_filter: string
};

const limitDate = (date: Date, min: Date, max: Date) => {
    return new Date(Math.min(Math.max(date.getTime(), min.getTime()), max.getTime()));
};

const isMonthInRange = (date: Date, from: Date, to: Date): boolean => {
    const date_from = moment(from).startOf("month").toDate();
    const date_to = moment(to).endOf("month").toDate();
    return date >= date_from && date <= date_to;
}

const renderInteger = (value: number) => {
    return niceNumber(value, 0);
};

const renderFloat = (value: number) => {
    return niceNumber(value, 3);
};

const renderBoolean = (value: boolean) => {
    return value ? "YES" : "NO";
};

const DateCell = ({ value }: Cell<MaterialProfileRow, number>) => {
    return niceDate(new Date(value));
};

const IntegerCell = ({ value }: Cell<MaterialProfileRow, number>) => {
    return renderInteger(value);
};

const MaterialCell = ({ value }: Cell<MaterialProfileRow, MaterialColumn>) => {
    return (
        <p className="mb-0">
            {value.title} <span className="text-muted">({value.external_id})</span>
        </p>
    );
};

const BooleanCell = ({ value }: Cell<MaterialProfileRow, boolean>) => {
    return value ? "YES" : "NO";
};

const filterMaterial = (rows: Row<MaterialProfileRow>[], columnId: string, filterValue: string) => {
    return rows.filter(row => {
        const material: MaterialColumn = row.values[columnId];
        return (
            material.title.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0 ||
            material.external_id.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0
        );
    });
};

const LineCell = ({ value }: Cell<MaterialRealizationRow, LineColumn>) => {
    return (
        <p className="mb-0">
            {value.title} <span className="text-muted">({value.external_id})</span>
        </p>
    );
};

const filterLine = (rows: Row<MaterialRealizationRow>[], columnId: string, filterValue: string) => {
    return rows.filter(row => {
        const line: LineColumn = row.values[columnId];
        return (
            line.title.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0 ||
            line.external_id.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0
        );
    });
};

const parseSettings = (content: string): ElectricitySettings | null => {
    try {
        const raw_settings = JSON.parse(content);
        const settings: ElectricitySettings = {
            profit_center_ratios: [],
            extras_matrix: [],
            ignore_bom: []
        };

        if (!Array.isArray(raw_settings.profit_center_ratios) || !Array.isArray(raw_settings.extras_matrix)) {
            console.error("Invalid settings format");
            return null;
        }

        for (const profit_center_ratio of raw_settings.profit_center_ratios) {
            if (
                typeof profit_center_ratio !== "object" ||
                typeof profit_center_ratio.profit_center_id !== "string" ||
                typeof profit_center_ratio.ratio !== "number"
            ) {
                console.error("Invalid profit center ratio format");
                continue;
            }

            settings.profit_center_ratios.push({
                profit_center_id: profit_center_ratio.profit_center_id,
                ratio: profit_center_ratio.ratio
            });
        }

        for (const extra_row of raw_settings.extras_matrix) {
            if (
                typeof extra_row !== "object" ||
                typeof extra_row.name !== "string" ||
                !Array.isArray(extra_row.profit_center_splits)
            ) {
                console.error("Invalid extra row format");
                continue;
            }

            const row: ExtraRow = {
                name: extra_row.name,
                profit_center_splits: []
            };

            for (const profit_center_split of extra_row.profit_center_splits) {
                if (
                    typeof profit_center_split !== "object" ||
                    typeof profit_center_split.profit_center_id !== "string" ||
                    typeof profit_center_split.split !== "number"
                ) {
                    console.error("Invalid profit center split format");
                    continue;
                }

                row.profit_center_splits.push({
                    profit_center_id: profit_center_split.profit_center_id,
                    split: profit_center_split.split
                });
            }

            settings.extras_matrix.push(row);
        }

        if (Array.isArray(raw_settings.ignore_bom)) {
            for (const ignore_bom of raw_settings.ignore_bom) {
                if (
                    typeof ignore_bom !== "object" ||
                    typeof ignore_bom.input_material_external_id !== "string" ||
                    typeof ignore_bom.output_material_external_id !== "string"
                ) {
                    console.error("Invalid ignore bom format");
                    continue;
                }

                settings.ignore_bom.push({
                    input_material_external_id: ignore_bom.input_material_external_id,
                    output_material_external_id: ignore_bom.output_material_external_id
                });
            }
        }

        return settings;
    } catch (err) {
        return null;
    }
};

const isValidConsumption = (consumption: number): boolean => {
    return !Number.isNaN(consumption) && consumption >= 0;
};

const isNullConsumptionInput = (consumption_input: ConsumptionInput): boolean => {
    return consumption_input.size > 0 && [...consumption_input.values()].every(value => value === null);
};

const isValidConsumptionInput = (consumption_input: ConsumptionInput): boolean => {
    return ![...consumption_input.values()].some(value => value !== null && !isValidConsumption(value));
};

const sumConsumptionPerMonth = (
    consumption_per_month: t.IElectricityConsumptionPerMonth[],
    ratio: number = 1.0
): number => {
    return consumption_per_month.reduce((sum, cpm) => sum + cpm.consumption * ratio, 0);
};

const renderNumberOrNull = (value: number | null) => {
    return value === null ? "-" : niceNumber(value, 0);
};

const getMonths = (from: Date, to: Date): Date[] => {
    const months: Date[] = [];
    const max_month = moment(to).startOf("month");
    let month = moment(from).startOf("month");
    while (month.isBefore(max_month)) {
        months.push(month.toDate());
        month = month.add(1, "month");
    }

    months.push(month.toDate());
    return months;
};

const getConsumptionInputKey = (
    scope: $Keys<typeof CONSUMPTION_SCOPES>,
    name?: string,
    year?: number,
    month?: number
): string => {
    let key = `consumption[${scope}]`;
    if (name === undefined) {
        return key;
    }

    key += `[${name}]`;
    if (year === undefined) {
        return key;
    }

    key += `[${year}]`;
    if (month === undefined) {
        return key;
    }

    key += `[${month}]`;
    return key;
};

const mapConsumptionInputKey = (key: string): ConsumptionInputKeyMap | null => {
    const match = key.match(/^([a-z_]+)\[(.+)\]\[(.+)\]\[(\d+)\]\[(\d+)\]$/);
    if (match == null) {
        return null;
    }

    const year = parseInt(match[4], 10);
    const month = parseInt(match[5], 10);
    if (Number.isNaN(year) || Number.isNaN(month)) {
        return null;
    }

    const scope = match[2] === CONSUMPTION_SCOPES.extras ? CONSUMPTION_SCOPES.extras : CONSUMPTION_SCOPES.profit_center;
    const name = match[3];
    return {
        scope,
        name,
        year,
        month
    };
};

const getConsumptionPerMonth = (
    key: string,
    value: number | null,
    ratio: number = 1.0
): t.IElectricityConsumptionPerMonth | null => {
    const key_map = mapConsumptionInputKey(key);
    if (key_map === null) {
        return null;
    }

    return {
        year: key_map.year,
        month: key_map.month,
        consumption: (value === null ? 0 : value) * ratio
    };
};

const mapConsumptionPerMonth = (
    consumption_input: ConsumptionInput,
    ratio: number = 1.0
): t.IElectricityConsumptionPerMonth[] => {
    const consumption_per_month: t.IElectricityConsumptionPerMonth[] = [];
    consumption_input.forEach((value, key) => {
        const cpm = getConsumptionPerMonth(key, value, ratio);
        if (cpm !== null) {
            consumption_per_month.push(cpm);
        }
    });
    return consumption_per_month;
};

const limitConsumptionPerMonth = (
    consumption_per_month: t.IElectricityConsumptionPerMonth[],
    start_time: number,
    end_time: number
): t.IElectricityConsumptionPerMonth[] => {
    const start = new Date(start_time);
    const end = new Date(end_time);
    const result: t.IElectricityConsumptionPerMonth[] = [];
    for (const consumption of consumption_per_month) {
        const date = new Date(consumption.year, consumption.month);
        if (isMonthInRange(date, start, end)) {
            result.push(consumption);
        }
    }

    return result;
};

class Electricity extends React.Component<Props, State> {
    calculate_interval: IntervalID;
    state: State;
    constructor(props: Props) {
        super(props);
        this.state = {
            error: "",
            profit_center_ids: [],
            visible_profit_center_ids: [],
            settings: null,
            is_electricity_admin:
                Auth.isInRole(Auth.ROLE_ADMIN)||
                Auth.isInRole(Auth.ROLE_POWER_USER) ||
                Auth.isInRole(Auth.ROLE_MAN_ELECTRICITY_ADMIN),
            is_electricity_user: Auth.isInRole(Auth.ROLE_MAN_ELECTRICITY_USER),
            consumption_input: new Map(),
            is_loading: false,
            is_calculating: false,
            is_saving: false,
            selected_tab: MAIN_VIEWS.input,
            calculation_time_from: new Date(),
            calculation_time_to: new Date(),
            input_time_from: new Date(),
            input_time_to: new Date(),
            input: null,
            stored_input: null,
            profit_center_profiles: [],
            material_profiles: [],
            selected_material_profile: null,
            show_material_profile_details: null,
            material_filter: "",
            profit_center_filter: ""
        };
    }

    async componentDidMount() {
        this.setState({ is_loading: true });
        await this.loadData();
        this.setState({ is_loading: false });
    }

    getConsumptionInput = (scope?: ConsumptionScope, name?: string): ConsumptionInput => {
        if (scope === undefined) {
            return this.state.consumption_input;
        }

        const key_prefix = getConsumptionInputKey(scope, name);
        const filtered_array = [...this.state.consumption_input.entries()].filter(([key, value]) =>
            key.startsWith(key_prefix)
        );
        const filtered_map: ConsumptionInput = new Map(filtered_array);
        return filtered_map;
    };

    loadData = async () => {
        try {
            const { content: settings_file_content } = await getBackend().common.getBlobStorageItemAsStringOrNull({
                key: SETTINGS_FILE_NAME
            });
            if (settings_file_content === null) {
                this.setState({
                    error: "Failed to load electricity settings"
                });

                return;
            }

            const settings = parseSettings(settings_file_content);
            if (settings === null) {
                this.setState({
                    error: "Invalid electricity settings"
                });

                return;
            }
            const valid_profit_center_set = new Set(settings.profit_center_ratios.map(ratio => ratio.profit_center_id));

            const { lines } = await getBackend().manufacturing.getLines({});
            const profit_centers_set = new Set(
                lines
                    .map(line => LINE_TAGS_ACCESS.electricity_profit_center(line.tags))
                    .filter(pc => pc !== "")
                    .filter(pc => valid_profit_center_set.has(pc))
            );
            const profit_center_ids = [...profit_centers_set];

            const { input: report_input, profit_center_profiles, material_profiles } =
                await getBackend().manufacturing.latestElectricityReport({});
            material_profiles.sort(mp1 => (mp1.is_final ? -1 : 1));

            const { content: stored_input } = await getBackend().common.getBlobStorageItemAsStringOrNull({
                key: INPUT_FILE_NAME
            });

            let input = report_input;
            if (stored_input !== null) {
                input = JSON.parse(stored_input);
            }

            this.initConsumptionInput(profit_center_ids, settings, input);

            const visible_profit_center_ids = [];
            if (this.state.is_electricity_admin) {
                visible_profit_center_ids.push(...profit_center_ids);
            } else {
                // get lines user has access to
                const line_uuids = [];
                for (const linegroup of getLineGroupsForUser()) {
                    line_uuids.push(...linegroup.line_uuids);
                }
                // get profit centers from lines
                const { lines } = await getBackend().manufacturing.getLines({ line_uuids });
                visible_profit_center_ids.push(
                    ...uniqueValues(
                        lines
                            .map(line => LINE_TAGS_ACCESS.electricity_profit_center(line.tags))
                            .filter(pc => pc !== "")
                    )
                );
            }

            const calculation_time_from = report_input.start_time !== 0 ? new Date(report_input.start_time) : new Date();
            const calculation_time_to = report_input.end_time !== 0 ? new Date(report_input.end_time) : new Date;
            this.setState({
                profit_center_ids,
                visible_profit_center_ids,
                settings,
                input,
                stored_input,
                profit_center_profiles,
                material_profiles,
                calculation_time_from,
                calculation_time_to
            });
        } catch (err) {
            this.setState({ error: `${err}` });
        }
    };

    initConsumptionInput = (
        profit_center_ids: string[],
        settings: ElectricitySettings,
        input: t.INewElectricityReportReq
    ) => {
        const consumption_input: ConsumptionInput = new Map();
        const input_time_from = input.start_time !== 0 ? new Date(input.start_time): new Date();
        const input_time_to = input.end_time !== 0 ? new Date(input.end_time) : new Date;
        const months = getMonths(input_time_from, input_time_to);
        for (const profit_center_id of profit_center_ids) {
            for (const date of months) {
                const key = getConsumptionInputKey(
                    CONSUMPTION_SCOPES.profit_center,
                    profit_center_id,
                    date.getFullYear(),
                    date.getMonth()
                );
                consumption_input.set(key, 0);
            }

            const profit_center = input.profit_centers.find(pc => pc.profit_center_id === profit_center_id);
            if (
                profit_center !== undefined &&
                profit_center.machine_electricity_consumption_per_month !== undefined &&
                profit_center.overhead_electricity_consumption_per_month !== undefined
            ) {
                for (const machine_cpm of profit_center.machine_electricity_consumption_per_month) {
                    const key = getConsumptionInputKey(
                        CONSUMPTION_SCOPES.profit_center,
                        profit_center_id,
                        machine_cpm.year,
                        machine_cpm.month
                    );
                    let value = machine_cpm.consumption;
                    const overhead_cpm = profit_center.overhead_electricity_consumption_per_month.find(
                        cpm => cpm.year === machine_cpm.year && cpm.month === machine_cpm.month
                    );
                    if (overhead_cpm !== undefined) {
                        value += overhead_cpm.consumption;
                    }

                    consumption_input.set(key, value);
                }
            }
        }

        for (const extra_row of settings.extras_matrix) {
            for (const date of months) {
                const key = getConsumptionInputKey(
                    CONSUMPTION_SCOPES.extras,
                    extra_row.name,
                    date.getFullYear(),
                    date.getMonth()
                );
                consumption_input.set(key, 0);
            }

            const extra_row_input = input.extras_matrix.find(e => e.title === extra_row.name);
            if (
                extra_row_input !== undefined &&
                extra_row_input.total_electricity_consumption_per_month !== undefined
            ) {
                for (const total_cpm of extra_row_input.total_electricity_consumption_per_month) {
                    const key = getConsumptionInputKey(
                        CONSUMPTION_SCOPES.extras,
                        extra_row.name,
                        total_cpm.year,
                        total_cpm.month
                    );
                    const value = total_cpm.consumption;
                    consumption_input.set(key, value);
                }
            }
        }

        this.setState({
            input_time_from,
            input_time_to,
            consumption_input
        });
    };

    getRatio = (profit_center_id: string): number | null => {
        const { settings } = this.state;
        if (settings === null) {
            return null;
        }

        const profit_center_ratio = settings.profit_center_ratios.find(
            pcr => pcr.profit_center_id === profit_center_id
        );
        if (profit_center_ratio === undefined) {
            return null;
        }

        return profit_center_ratio.ratio;
    };

    getMachineConsumptionPerMonth = (profit_center_id: string): t.IElectricityConsumptionPerMonth[] => {
        const ratio = this.getRatio(profit_center_id);
        if (ratio === null) {
            return [];
        }

        const consumption_input = this.getConsumptionInput(CONSUMPTION_SCOPES.profit_center, profit_center_id);
        if (isNullConsumptionInput(consumption_input) || !isValidConsumptionInput(consumption_input)) {
            return [];
        }

        return mapConsumptionPerMonth(consumption_input, ratio);
    };

    getOverheadConsumptionPerMonth = (profit_center_id: string): t.IElectricityConsumptionPerMonth[] => {
        const ratio = this.getRatio(profit_center_id);
        if (ratio === null) {
            return [];
        }

        const consumption_input = this.getConsumptionInput(CONSUMPTION_SCOPES.profit_center, profit_center_id);

        if (isNullConsumptionInput(consumption_input) || !isValidConsumptionInput(consumption_input)) {
            return [];
        }

        return mapConsumptionPerMonth(consumption_input, 1 - ratio);
    };

    getExtras = (profit_center_id: string): number | null => {
        const { settings, calculation_time_from, calculation_time_to } = this.state;
        if (settings === null) {
            return null;
        }

        let sum = 0;
        for (const extra_row of settings.extras_matrix) {
            const consumption_input = this.getConsumptionInput(CONSUMPTION_SCOPES.extras, extra_row.name);
            if (isNullConsumptionInput(consumption_input) || !isValidConsumptionInput(consumption_input)) {
                return null;
            }

            const profit_center_split = extra_row.profit_center_splits.find(
                pcr => pcr.profit_center_id === profit_center_id
            );
            if (profit_center_split !== undefined) {
                const consumption_per_month = limitConsumptionPerMonth(
                    mapConsumptionPerMonth(consumption_input, profit_center_split.split),
                    calculation_time_from,
                    calculation_time_to
                );
                sum += sumConsumptionPerMonth(consumption_per_month);
            }
        }

        return sum;
    };

    getExtrasPerMonth = (extra_name: string, split: number = 1.0): t.IElectricityConsumptionPerMonth[] => {
        const consumption_input = this.getConsumptionInput(CONSUMPTION_SCOPES.extras, extra_name);
        return mapConsumptionPerMonth(consumption_input, split);
    };

    isInputValid = (): boolean => {
        const { is_loading, profit_center_ids, settings } = this.state;
        if (is_loading || settings === null) {
            return false;
        }

        for (const profit_center_id of profit_center_ids) {
            const consumption_input = this.getConsumptionInput(CONSUMPTION_SCOPES.profit_center, profit_center_id);
            if (isNullConsumptionInput(consumption_input) || !isValidConsumptionInput(consumption_input)) {
                return false;
            }
        }

        for (const extra_row of settings.extras_matrix) {
            const consumption_input = this.getConsumptionInput(CONSUMPTION_SCOPES.extras, extra_row.name);

            if (isNullConsumptionInput(consumption_input) || !isValidConsumptionInput(consumption_input)) {
                return false;
            }
        }

        return true;
    };

    hasInputChanged = (): boolean => {
        const { stored_input } = this.state;
        if (stored_input === null) {
            return true;
        }

        const new_input = JSON.stringify(this.getInput(true));
        if (stored_input !== new_input) {
            return true;
        }

        return false;
    };

    updateConsumptionInput = () => {
        const months = getMonths(this.state.input_time_from, this.state.input_time_to);
        const consumption_input: ConsumptionInput = new Map();
        for (const profit_center_id of this.state.profit_center_ids) {
            for (const date of months) {
                const key = getConsumptionInputKey(
                    CONSUMPTION_SCOPES.profit_center,
                    profit_center_id,
                    date.getFullYear(),
                    date.getMonth()
                );
                const old_value = this.state.consumption_input.get(key);
                consumption_input.set(key, old_value !== undefined ? old_value : 0);
            }
        }

        const { settings } = this.state;
        if (settings !== null) {
            for (const extra_row of settings.extras_matrix) {
                for (const date of months) {
                    const key = getConsumptionInputKey(
                        CONSUMPTION_SCOPES.extras,
                        extra_row.name,
                        date.getFullYear(),
                        date.getMonth()
                    );
                    const old_value = this.state.consumption_input.get(key);
                    consumption_input.set(key, old_value !== undefined ? old_value : 0);
                }
            }
        }

        this.setState({ consumption_input });
    };

    getInput = (save: boolean = false): t.INewElectricityReportReq | null => {
        const { profit_center_ids, settings } = this.state;
        if (!this.isInputValid() || settings === null) {
            return null;
        }

        const time_from = save ? this.state.input_time_from : this.state.calculation_time_from;
        const time_to = save ? this.state.input_time_to : this.state.calculation_time_to;
        const profit_centers: t.IElectricityInputProfitCenter[] = [];
        for (const profit_center_id of profit_center_ids) {
            const machine_electricity_consumption_per_month = limitConsumptionPerMonth(
                this.getMachineConsumptionPerMonth(profit_center_id),
                time_from,
                time_to
            );
            const machine_electricity_consumption = sumConsumptionPerMonth(machine_electricity_consumption_per_month);
            const overhead_electricity_consumption_per_month = limitConsumptionPerMonth(
                this.getOverheadConsumptionPerMonth(profit_center_id),
                time_from,
                time_to
            );
            const overhead_electricity_consumption = sumConsumptionPerMonth(overhead_electricity_consumption_per_month);

            profit_centers.push({
                machine_electricity_consumption,
                machine_electricity_consumption_per_month,
                overhead_electricity_consumption,
                overhead_electricity_consumption_per_month,
                profit_center_id
            });
        }

        const extras_matrix: t.IElectricityInputExtrasMatrix[] = [];
        for (const extra_row of settings.extras_matrix) {
            const profit_center_splits: t.IElectricityInputProfitCenterSplits[] = [];
            const total_electricity_consumption_per_month = limitConsumptionPerMonth(
                this.getExtrasPerMonth(extra_row.name),
                time_from,
                time_to
            );
            const total_electricity_consumption = sumConsumptionPerMonth(total_electricity_consumption_per_month);

            for (const profit_center_split of extra_row.profit_center_splits) {
                const consumption_per_month = limitConsumptionPerMonth(
                    this.getExtrasPerMonth(extra_row.name, profit_center_split.split),
                    time_from,
                    time_to
                );
                profit_center_splits.push({
                    profit_center_id: profit_center_split.profit_center_id,
                    electricity_consumption: sumConsumptionPerMonth(consumption_per_month),
                    electricity_consumption_per_month: consumption_per_month
                });
            }

            extras_matrix.push({
                title: extra_row.name,
                total_electricity_consumption,
                total_electricity_consumption_per_month,
                profit_center_splits
            });
        }

        const ignore_bom: t.IElectricityInputIgnoreBom[] = settings.ignore_bom;
        const input: t.INewElectricityReportReq = {
            start_time: moment(time_from).startOf("day").toDate().getTime(),
            end_time: moment(time_to).endOf("day").toDate().getTime(),
            profit_centers,
            extras_matrix,
            ignore_bom
        }

        return input;
    };

    handleCalculationTimeFromChange = (date: Date) => {
        const {
            calculation_time_to,
            input_time_from,
            input_time_to
        } = this.state;
        const time_from = limitDate(date, input_time_from, input_time_to);
        const time_to = limitDate(calculation_time_to, time_from, input_time_to);
        this.setState({
            calculation_time_from: time_from,
            calculation_time_to: time_to
        });
    };

    handleCalculationTimeToChange = (date: Date) => {
        const {
            calculation_time_from,
            input_time_from,
            input_time_to
        } = this.state;
        const time_to = limitDate(date, input_time_from, input_time_to);
        const time_from = limitDate(calculation_time_from, input_time_from, time_to);
        this.setState({
            calculation_time_from: time_from,
            calculation_time_to: time_to
        });
    };


    handleInputTimeFromChange = (date: Date) => {
        const {
            calculation_time_from,
            calculation_time_to,
            input_time_to
        } = this.state;
        const time_from = date;
        const time_to = time_from > input_time_to ? time_from : input_time_to;
        this.setState(
            {
                calculation_time_from: limitDate(calculation_time_from, time_from, time_to),
                calculation_time_to: limitDate(calculation_time_to, time_from, time_to),
                input_time_from: time_from,
                input_time_to: time_to
            },
            () => {
                this.updateConsumptionInput();
            }
        );
    };

    handleInputTimeToChange = (date: Date) => {
        const {
            calculation_time_from,
            calculation_time_to,
            input_time_from
        } = this.state;
        const time_to = date;
        const time_from = time_to < input_time_from ? time_to : input_time_from;
        this.setState(
            {
                calculation_time_from: limitDate(calculation_time_from, time_from, time_to),
                calculation_time_to: limitDate(calculation_time_to, time_from, time_to),
                input_time_from: time_from,
                input_time_to: time_to
            },
            () => {
                this.updateConsumptionInput();
            }
        );
    };

    handleConsumptionInputChange = (event: SyntheticEvent<HTMLInputElement>) => {
        const key = event.currentTarget.name;
        const key_map = mapConsumptionInputKey(key);
        if (key_map === null) {
            return;
        }

        const consumption_input = new Map(this.state.consumption_input);
        const months = getMonths(this.state.input_time_from, this.state.input_time_to);
        for (const date of months) {
            const key = getConsumptionInputKey(key_map.scope, key_map.name, date.getFullYear(), date.getMonth());
            const value = consumption_input.get(key);
            if (value === undefined) {
                consumption_input.set(key, 0);
            }
        }

        const value = parseFloat(event.currentTarget.value);
        const consumption = Number.isNaN(value) ? null : value;
        consumption_input.set(key, consumption);
        this.setState({ consumption_input });
    };

    handleCalculate = async () => {
        const input = this.getInput();
        if (input === null) {
            return;
        }

        try {
            this.setState({ is_calculating: true });
            const { token } = await getBackend().manufacturing.newElectricityReport(input);
            await new Promise(resolve => {
                this.calculate_interval = setInterval(async () => {
                    try {
                        const { input, result, status, status_msg } = await getBackend().common.getJobInfo({
                            id: token
                        });
                        if (status === JOB_STATUS.ended) {
                            result.material_profiles.sort(mp1 => (mp1.is_final ? -1 : 1));
                            this.setState({
                                input,
                                profit_center_profiles: result ? result.profit_center_profiles : [],
                                material_profiles: result ? result.material_profiles : [],
                                selected_tab: MAIN_VIEWS.result
                            });
                            clearInterval(this.calculate_interval);
                            resolve();
                        } else if (status === JOB_STATUS.errored) {
                            this.setState({ error: status_msg });
                            clearInterval(this.calculate_interval);
                            resolve();
                        }
                    } catch (err) {
                        this.setState({ error: `${err}` });
                        clearInterval(this.calculate_interval);
                        resolve();
                    }
                }, 1000);
            });
            this.setState({ is_calculating: false });
        } catch (err) {
            this.setState({
                is_calculating: false,
                error: `${err}`
            });
        }
    };

    handleSaveInput = async () => {
        const input = this.getInput(true);
        if (input === null) {
            return;
        }

        this.setState({
            is_saving: true,
            error: ""
        });

        try {
            const content = JSON.stringify(input, null, 4);
            await getBackend().common.setBlobStorageItemAsString({
                key: INPUT_FILE_NAME,
                content
            });
            this.setState({ stored_input: content });
        } catch (error) {
            this.setState({ error: `${error}` });
        }

        this.setState({
            is_saving: false
        });
    };

    handleTabSelect = (selected_tab: string) => {
        this.setState({
            selected_tab
        });
    };

    handleExport = () => {
        const { material_profiles } = this.state;

        const wb = XLSX.utils.book_new();
        const data = [
            [
                "MATNR",
                "MEINH",
                "UMREZ",
                "UMREN",
                "Profit center",
                "Title",
                "Duration",
                "Stock",
                "Unit",
                "Missing"
            ]
        ];
        const missing_data = [
            [
                "MATNR",
                "Profit center",
                "Title",
                "Duration",
                "Stock",
                "Unit",
                "Missing"
            ]
        ];
        for (const material of material_profiles) {
            let umrez = 10000;
            let umren = material.total_unit_electricity_consumption * 10000;

            while (umrez > 1 && umren > 999999) {
                umrez /= 10;
                umren /= 10;
            }
            umren = Math.round(umren);

            data.push([
                material.material_external_id,
                "EEK",
                umrez,
                umren,
                material.profit_center_id,
                material.material_title,
                material.duration_hours,
                material.stock_count,
                material.measurement_unit,
                material.missing_input_materials ? "YES" : "NO"
            ]);
            if (material.missing_input_materials) {
                missing_data.push([
                    material.material_external_id,
                    material.profit_center_id,
                    material.material_title,
                    material.duration_hours,
                    material.stock_count,
                    material.measurement_unit,
                    "YES"
                ]);
            }
        }

        const worksheet = XLSX.utils.aoa_to_sheet(data);
        XLSX.utils.book_append_sheet(wb, worksheet, "Materials");

        const missing_worksheet = XLSX.utils.aoa_to_sheet(missing_data);
        XLSX.utils.book_append_sheet(wb, missing_worksheet, "Missing materials");

        XLSX.writeFile(wb, `electricity-consumption-${new Date().getTime()}.xlsx`);
    };

    handleMaterialProfileDetailsShow = (
        event: SyntheticEvent<HTMLButtonElement>,
        material_profile: t.IElectricityOutputMaterial
    ) => {
        this.setState({
            selected_material_profile: material_profile,
            show_material_profile_details: MATERIAL_PROFILE_VIEWS[event.currentTarget.name]
        });
    };

    handleMaterialProfileDetailsHide = () => {
        this.setState({
            selected_material_profile: null,
            show_material_profile_details: null
        });
    };

    handleTextFilterChange = (event: SyntheticEvent<HTMLInputElement>, column: ColumnInstance<MaterialProfileRow>) => {
        const value = event.currentTarget.value;
        const id = column.id;
        column.setFilter(value);

        if (id === MATERIAL_PROFILE_COLUMNS.material) {
            this.setState({ material_filter: value });
        } else if (id === MATERIAL_PROFILE_COLUMNS.profit_center_id) {
            this.setState({ profit_center_filter: value });
        }
    };

    renderToolbar = () => {
        const {
            is_electricity_admin,
            profit_center_profiles,
            is_calculating,
            is_loading,
            is_saving,
            selected_tab,
            calculation_time_from,
            calculation_time_to,
            input_time_from,
            input_time_to
        } = this.state;
        return (
            <div className="filter_bar views-filter-bar">
                <div className="row align-items-center">
                    <div className="col col-auto">
                        <h2 className="my-0">Calculation of electricity consumption</h2>
                    </div>
                    <div className="col col-auto ml-auto">
                        <div className="form-row">
                            {selected_tab === MAIN_VIEWS.input && (
                                <React.Fragment>
                                    <div className="col-auto">
                                        <label className="mb-0 mr-2" htmlFor="electricity-calculation-time-from">
                                            {translate("common.from", "From")}
                                        </label>
                                        <DateTimePicker
                                            className="d-inline-block"
                                            inputClassName="form-control-sm"
                                            inputId="electricity-calculation-time-from"
                                            name="calculation-time-from"
                                            value={is_loading ? undefined : calculation_time_from}
                                            minValue={input_time_from}
                                            maxValue={input_time_to}
                                            pickerType="date"
                                            placeholder={`${translate("common.select", "Select")}...`}
                                            onChange={this.handleCalculationTimeFromChange}
                                            popupPostion="fixed"
                                            disabled={is_calculating || is_loading || is_saving}
                                        />
                                    </div>
                                    <div className="col-auto">
                                        <label className="mb-0 mr-2" htmlFor="electricity-calculation-time-to">
                                            {translate("common.to", "To")}
                                        </label>
                                        <DateTimePicker
                                            className="d-inline-block"
                                            inputClassName="form-control-sm"
                                            inputId="electricity-calculation-time-to"
                                            name="calculation-time-to"
                                            value={is_loading ? undefined : calculation_time_to}
                                            minValue={calculation_time_from}
                                            minValue={new Date(
                                                Math.max(input_time_from.getTime(), calculation_time_from.getTime())
                                            )}
                                            maxValue={input_time_to}
                                            pickerType="date"
                                            placeholder={`${translate("common.select", "Select")}...`}
                                            onChange={this.handleCalculationTimeToChange}
                                            popupPostion="fixed"
                                            disabled={is_calculating || is_loading || is_saving}
                                        />
                                    </div>
                                    {is_electricity_admin &&
                                    <div className="col-auto">
                                        <button
                                            className="btn btn-primary"
                                            disabled={!this.isInputValid() || is_calculating || is_loading || is_saving}
                                            onClick={this.handleCalculate}
                                        >
                                            <i
                                                className={classNames("fas mr-2", {
                                                    "fa-spinner fa-spin": is_calculating,
                                                    "fa-calculator": !is_calculating
                                                })}
                                                aria-hidden="true"
                                            />
                                            {translate("common.calculate", "Calculate")}
                                        </button>
                                    </div>}
                                </React.Fragment>
                            )}
                            {selected_tab === MAIN_VIEWS.result && (
                                <div className="col-auto">
                                    <button
                                        className="btn btn-primary"
                                        disabled={profit_center_profiles.length === 0}
                                        onClick={this.handleExport}
                                    >
                                        <i className="fas fa-file-excel mr-2" />
                                        {translate("common.export_xlsx", "Export to Excel")}
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    renderInputTimeRange = () => {
        const { input_time_from, input_time_to, is_calculating, is_loading, is_saving } = this.state;
        return (
            <div className="electricity-input-toolbar">
                <div className="form-row">
                    <div className="col-auto">
                        <label className="mb-0 mr-2" htmlFor="electricity-input-from">
                            {translate("common.from", "From")}
                        </label>
                        <DateTimePicker
                            className="d-inline-block"
                            inputClassName="form-control-sm"
                            inputId="electricity-input-from"
                            name="input-time-from"
                            value={input_time_from}
                            pickerType="date"
                            placeholder={`${translate("common.select", "Select")}...`}
                            onChange={this.handleInputTimeFromChange}
                            popupPostion="fixed"
                            disabled={is_calculating || is_saving}
                        />
                    </div>
                    <div className="col-auto">
                        <label className="mb-0 mr-2" htmlFor="electricity-input-to">
                            {translate("common.to", "To")}
                        </label>
                        <DateTimePicker
                            className="d-inline-block"
                            inputClassName="form-control-sm"
                            inputId="electricity-input-to"
                            name="input-time-to"
                            value={input_time_to}
                            minValue={input_time_from}
                            pickerType="date"
                            placeholder={`${translate("common.select", "Select")}...`}
                            onChange={this.handleInputTimeToChange}
                            popupPostion="fixed"
                            disabled={is_calculating || is_saving}
                        />
                    </div>
                    <div className="col-auto">
                        <button
                            className="btn btn-primary"
                            disabled={!this.hasInputChanged() || !this.isInputValid() || is_calculating || is_loading || is_saving}
                            onClick={this.handleSaveInput}
                        >
                            <i
                                className={classNames("fas mr-2", {
                                    "fa-spinner fa-spin": is_saving,
                                    "fa-save": !is_saving
                                })}
                                aria-hidden="true"
                            />
                            {translate("common.save", "Save")}
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    renderInputMonthsHeader = () => {
        const {
            calculation_time_from,
            calculation_time_to,
            input_time_from,
            input_time_to
        } = this.state;
        const months = getMonths(input_time_from, input_time_to);
        return months.map(date => (
            <th
                key={date.toISOString()}
                className={classNames("column-consumption", {
                    "is-selected": isMonthInRange(date, calculation_time_from, calculation_time_to)
                })}
            >
                <div className="text-nowrap">
                    <span>{translate(`common.month${date.getMonth()}`).slice(0, 3)}</span>{" "}
                    <span>{date.getFullYear()}</span>{" "}
                    <span>[kWh]</span>
                </div>
            </th>
        ));
    };

    renderProfitCentersInput = () => {
        const {
            calculation_time_from,
            calculation_time_to,
            visible_profit_center_ids,
            is_calculating,
            is_saving,
            input_time_from,
            input_time_to
        } = this.state;

        const months = getMonths(input_time_from, input_time_to);
        return (
            <div className="card">
                <div className="card-header bg-transparent">
                    <h3 className="h6 mb-0">Profit centers</h3>
                </div>
                <div className="card-body p-0">
                    <table className="electricity-table electricity-table-input table">
                        <thead>
                            <tr>
                                <th className="column-name">Name</th>
                                {this.renderInputMonthsHeader()}
                                <th className="column-cost">Machines</th>
                                <th className="column-overhead">Rest</th>
                                <th className="column-extras">Extras</th>
                            </tr>
                        </thead>
                        <tbody>
                            {visible_profit_center_ids.map(profit_center_id => {
                                const consumption_input = this.getConsumptionInput(
                                    CONSUMPTION_SCOPES.profit_center,
                                    profit_center_id
                                );
                                const has_values = [...consumption_input.values()].some(value => value !== null);
                                const cost = has_values
                                    ? sumConsumptionPerMonth(limitConsumptionPerMonth(
                                        this.getMachineConsumptionPerMonth(profit_center_id),
                                        calculation_time_from,
                                        calculation_time_to
                                    ))
                                    : null;
                                const overhead = has_values
                                    ? sumConsumptionPerMonth(limitConsumptionPerMonth(
                                        this.getOverheadConsumptionPerMonth(profit_center_id),
                                        calculation_time_from,
                                        calculation_time_to
                                    ))
                                    : null;
                                const extras = this.getExtras(profit_center_id);
                                const is_invalid =
                                    consumption_input !== undefined && !isValidConsumptionInput(consumption_input);
                                return (
                                    <tr key={profit_center_id} className={classNames({ "is-invalid": is_invalid })}>
                                        <td className="column-name">{profit_center_id}</td>
                                        {months.map((date, i) => {
                                            const consumption = this.state.consumption_input.get(
                                                getConsumptionInputKey(
                                                    CONSUMPTION_SCOPES.profit_center,
                                                    profit_center_id,
                                                    date.getFullYear(),
                                                    date.getMonth()
                                                )
                                            );
                                            return (
                                                <td
                                                    key={date.toISOString()}
                                                    className={classNames("column-consumption", {
                                                        "is-selected": isMonthInRange(date, calculation_time_from, calculation_time_to)
                                                    })}
                                                >
                                                    <div className="input-group flex-nowrap">
                                                        <input
                                                            className={classNames("form-control h-auto", {
                                                                "is-invalid":
                                                                    consumption != null &&
                                                                    !isValidConsumption(consumption)
                                                            })}
                                                            type="number"
                                                            name={getConsumptionInputKey(
                                                                CONSUMPTION_SCOPES.profit_center,
                                                                profit_center_id,
                                                                date.getFullYear(),
                                                                date.getMonth()
                                                            )}
                                                            value={consumption}
                                                            min={0}
                                                            onChange={this.handleConsumptionInputChange}
                                                            disabled={is_calculating || is_saving}
                                                        />
                                                    </div>
                                                </td>
                                            );
                                        })}
                                        <td className="column-cost">{renderNumberOrNull(cost)}</td>
                                        <td className="column-overhead">{renderNumberOrNull(overhead)}</td>
                                        <td className="column-extras">{renderNumberOrNull(extras)}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    };

    renderExtrasInput = () => {
        const {
            settings,
            is_calculating,
            is_saving,
            calculation_time_from,
            calculation_time_to,
            input_time_from,
            input_time_to
        } = this.state;
        if (settings === null) {
            return null;
        }

        const months = getMonths(input_time_from, input_time_to);
        return (
            <div className="card d-inline-block">
                <div className="card-header bg-transparent">
                    <h3 className="h6 mb-0">Extras</h3>
                </div>
                <div className="card-body p-0">
                    <table className="electricity-table electricity-table-input table">
                        <thead>
                            <tr>
                                <th className="column-name">Name</th>
                                {this.renderInputMonthsHeader()}
                            </tr>
                        </thead>
                        <tbody>
                            {settings.extras_matrix.map(extra_row => {
                                const consumption_input = this.getConsumptionInput(
                                    CONSUMPTION_SCOPES.extras,
                                    extra_row.name
                                );
                                const is_invalid =
                                    consumption_input !== undefined && !isValidConsumptionInput(consumption_input);
                                return (
                                    <tr key={extra_row.name} className={classNames({ "is-invalid": is_invalid })}>
                                        <td className="column-name">{extra_row.name}</td>
                                        {months.map((date, i) => {
                                            const consumption = this.state.consumption_input.get(
                                                getConsumptionInputKey(
                                                    CONSUMPTION_SCOPES.extras,
                                                    extra_row.name,
                                                    date.getFullYear(),
                                                    date.getMonth()
                                                )
                                            );
                                            return (
                                                <td
                                                    key={date.toISOString()}
                                                    className={classNames("column-consumption", {
                                                        "is-selected": isMonthInRange(date, calculation_time_from, calculation_time_to)
                                                    })}
                                                >
                                                    <div className="input-group flex-nowrap">
                                                        <input
                                                            className={classNames("form-control h-auto", {
                                                                "is-invalid":
                                                                    consumption != null &&
                                                                    !isValidConsumption(consumption)
                                                            })}
                                                            type="number"
                                                            name="consumption"
                                                            name={getConsumptionInputKey(
                                                                CONSUMPTION_SCOPES.extras,
                                                                extra_row.name,
                                                                date.getFullYear(),
                                                                date.getMonth()
                                                            )}
                                                            value={consumption}
                                                            min={0}
                                                            onChange={this.handleConsumptionInputChange}
                                                            disabled={is_calculating || is_saving}
                                                        />
                                                    </div>
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    };

    renderActionsCell = ({ value }: Cell<MaterialProfileRow, t.IElectricityOutputMaterial>) => {
        const is_input_materials_disabled = value.input_materials.length === 0;
        const is_stock_realization_disabled = value.stock_realizations.length === 0;
        const is_duration_realization_disabled = value.duration_realizations.length === 0;
        return (
            <div className="dropdown">
                <button
                    className="btn btn-short btn-outline-secondary dropdown-toggle"
                    data-toggle="dropdown"
                    aria-expanded="false"
                    title="Show details"
                    disabled={
                        is_input_materials_disabled && is_stock_realization_disabled && is_duration_realization_disabled
                    }
                >
                    <i className="far fa-eye" />
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                    <button
                        className="dropdown-item"
                        name={MATERIAL_PROFILE_VIEWS.input_materials}
                        onClick={event => {
                            this.handleMaterialProfileDetailsShow(event, value);
                        }}
                        disabled={is_input_materials_disabled}
                    >
                        Input Materials
                    </button>
                    <button
                        className="dropdown-item"
                        name={MATERIAL_PROFILE_VIEWS.stock_realization}
                        onClick={event => {
                            this.handleMaterialProfileDetailsShow(event, value);
                        }}
                        disabled={is_stock_realization_disabled}
                    >
                        Stock Realization
                    </button>
                    <button
                        className="dropdown-item"
                        name={MATERIAL_PROFILE_VIEWS.duration_realization}
                        onClick={event => {
                            this.handleMaterialProfileDetailsShow(event, value);
                        }}
                        disabled={is_duration_realization_disabled}
                    >
                        Duration Realization
                    </button>
                </div>
            </div>
        );
    };

    renderTextFilter = ({ column }: FilterProps<MaterialProfileRow>) => {
        const label = column.filterInputPlaceholder;
        const value = column.filterValue;
        const filter = (
            <input
                className="form-control column-filter"
                value={value}
                onChange={event => this.handleTextFilterChange(event, column)}
                placeholder={label}
                title={label}
            />
        );

        return filter;
    };

    renderMaterialProfiles = () => {
        const { material_profiles } = this.state;
        const columns: Column<MaterialProfileRow>[] = [
            {
                accessor: MATERIAL_PROFILE_COLUMNS.material,
                Header: "",
                filterInputPlaceholder: "Material",
                disableFilters: false,
                Cell: MaterialCell,
                Filter: this.renderTextFilter,
                filter: filterMaterial
            },
            {
                accessor: MATERIAL_PROFILE_COLUMNS.profit_center_id,
                Header: "",
                filterInputPlaceholder: "Profit center",
                Filter: this.renderTextFilter,
                disableFilters: false,
                enableSorting: false
            },
            {
                accessor: MATERIAL_PROFILE_COLUMNS.duration,
                Header: "Duration [h]",
                disableFilters: true,
                enableSorting: true,
                Cell: IntegerCell
            },
            {
                accessor: MATERIAL_PROFILE_COLUMNS.stock,
                Header: "Stock [pcs]",
                disableFilters: true,
                enableSorting: true,
                Cell: IntegerCell
            },
            {
                accessor: MATERIAL_PROFILE_COLUMNS.measurement_unit,
                Header: "Unit",
                disableFilters: true,
                enableSorting: false
            },
            {
                accessor: MATERIAL_PROFILE_COLUMNS.electricity_consumption,
                Header: "Neto consumption [kWh/10000pcs]",
                disableFilters: true,
                enableSorting: true,
                Cell: IntegerCell
            },
            {
                accessor: MATERIAL_PROFILE_COLUMNS.total_electricity_consumption,
                Header: "Gross consumption [kWh/10000pcs]",
                disableFilters: true,
                enableSorting: true,
                Cell: IntegerCell
            },
            {
                accessor: MATERIAL_PROFILE_COLUMNS.missing_input_materials,
                Header: "Missing input materials",
                disableFilters: true,
                enableSorting: true,
                Cell: BooleanCell
            },
            {
                accessor: MATERIAL_PROFILE_COLUMNS.actions,
                Header: "",
                disableFilters: true,
                Cell: this.renderActionsCell
            }
        ];
        const data: MaterialProfileRow[] = [];

        for (const material_profile of material_profiles) {
            data.push({
                material: {
                    external_id: material_profile.material_external_id,
                    title: material_profile.material_title
                },
                profit_center_id: material_profile.profit_center_id,
                duration: material_profile.duration_hours,
                stock: material_profile.stock_count,
                measurement_unit: material_profile.measurement_unit,
                electricity_consumption: material_profile.unit_electricity_consumption * 10000.0,
                total_electricity_consumption: material_profile.total_unit_electricity_consumption * 10000.0,
                missing_input_materials: material_profile.missing_input_materials,
                actions: material_profile
            });
        }

        return (
            <div className="card pb-3">
                <ReactTable
                    className="electricity-table electricity-table-output"
                    columns={columns}
                    data={data}
                    initialState={{
                        pageSize: 100,
                        filters: [
                            {
                                id: MATERIAL_PROFILE_COLUMNS.material,
                                value: this.state.material_filter
                            },
                            {
                                id: MATERIAL_PROFILE_COLUMNS.profit_center_id,
                                value: this.state.profit_center_filter
                            }
                        ]
                    }}
                    Pagination
                />
            </div>
        );
    };

    renderMaterialProfileRealization = (realization: t.IElectricityOutputRealization[]) => {
        const columns = [
            {
                accessor: MATERIAL_PROFILE_REALIZATION_COLUMNS.line,
                Header: "",
                filterInputPlaceholder: "Line",
                disableFilters: false,
                Cell: LineCell,
                filter: filterLine
            },
            {
                accessor: MATERIAL_PROFILE_REALIZATION_COLUMNS.process_num,
                Header: "Process number",
                disableFilters: true
            },
            {
                accessor: MATERIAL_PROFILE_REALIZATION_COLUMNS.shift_date,
                Header: "Date",
                disableFilters: true,
                enableSorting: true,
                Cell: DateCell
            },
            {
                accessor: MATERIAL_PROFILE_REALIZATION_COLUMNS.shift_number,
                Header: "Shift",
                disableFilters: true
            },
            {
                accessor: MATERIAL_PROFILE_REALIZATION_COLUMNS.duration,
                Header: "Duration [h]",
                disableFilters: true,
                enableSorting: true,
                Cell: IntegerCell
            },
            {
                accessor: MATERIAL_PROFILE_REALIZATION_COLUMNS.quantity,
                Header: "Quantity",
                disableFilters: true,
                enableSorting: true,
                Cell: IntegerCell
            },
            {
                accessor: MATERIAL_PROFILE_REALIZATION_COLUMNS.measurement_unit,
                Header: "Unit",
                disableFilters: true,
                enableSorting: false
            }
        ];
        const rows = realization.map(data => ({
            line: {
                title: data.line_title,
                external_id: data.line_external_id
            },
            process_num: data.process_num,
            shift_date: data.shift_date,
            shift_number: (data.shift_number % 3) + 1,
            duration: data.duration_hours,
            quantity: data.quantity_realized,
            measurement_unit: data.measurement_unit
        }));

        return <ReactTable className="electricity-table electricity-table-realization" columns={columns} data={rows} />;
    };

    getInputMaterailColumns = (): TreeColumn<InputMaterialRow>[] => [
        {
            id: "material",
            Header: "Material",
            Cell: (material: any) => (
                <span>
                    {material.title} <em>{material.external_id}</em>
                </span>
            )
        },
        {
            id: "factor",
            Header: "Factor",
            // $FlowFixMe
            Cell: renderFloat
        },
        {
            id: "profit_center",
            Header: "Profit center"
        },
        {
            id: "duration",
            Header: "Duration [h]",
            // $FlowFixMe
            Cell: renderInteger
        },
        {
            id: "stock",
            Header: "Stock [pcs]",
            Cell: renderInteger
        },
        {
            id: "measurement_unit",
            Header: "Unit"
        },
        {
            id: "net_consumption",
            Header: "Net consumption [kWh/10000pcs]",
            Cell: renderInteger
        },
        {
            id: "gross_consumption",
            Header: "Gross consumption [kWh/10000pcs]",
            Cell: renderInteger
        },
        {
            id: "missing",
            Header: "Missing",
            // $FlowFixMe
            Cell: renderBoolean
        }
    ];

    getInputMaterialNodes = (
        input_materials: t.IElectricityOutputInputMaterial[],
        material_profiles_map: Map<string, t.IElectricityOutputMaterial>
    ): TreeNode<InputMaterialRow>[] => {
        const nodes: TreeNode<InputMaterialRow>[] = [];
        for (const input_material of input_materials) {
            const material_profile = material_profiles_map.get(input_material.material_external_id);
            if (material_profile !== undefined) {
                nodes.push({
                    row: {
                        material: {
                            external_id: material_profile.material_external_id,
                            title: material_profile.material_title
                        },
                        factor: input_material.factor,
                        profit_center: material_profile.profit_center_id,
                        duration: material_profile.duration_hours,
                        stock: material_profile.stock_count,
                        measurement_unit: material_profile.measurement_unit,
                        net_consumption: material_profile.unit_electricity_consumption * 10000.0,
                        gross_consumption: material_profile.total_unit_electricity_consumption * 10000.0,
                        missing: material_profile.missing_input_materials
                    },
                    children: this.getInputMaterialNodes(input_material.input_materials, material_profiles_map)
                });
            }
        }

        return nodes;
    };

    renderMaterialProfileInputMaterials = () => {
        const material_profile = this.state.selected_material_profile;
        if (material_profile === null) {
            return null;
        }

        const material_profiles = this.state.material_profiles;
        const material_profiles_map: Map<string, t.IElectricityOutputMaterial> = new Map();
        for (const material_profile of material_profiles) {
            material_profiles_map.set(material_profile.material_external_id, material_profile);
        }

        const input_material_columns = this.getInputMaterailColumns();
        const input_material_nodes = this.getInputMaterialNodes(
            [
                {
                    material_external_id: material_profile.material_external_id,
                    material_title: material_profile.material_title,
                    material_uuid: material_profile.material_uuid,
                    factor: 1.0,
                    input_materials: material_profile.input_materials
                }
            ],
            material_profiles_map
        );

        return (
            <TreeView
                class_name="electricity-input-materials-tree"
                columns={input_material_columns}
                nodes={input_material_nodes}
            />
        );
    };

    renderMaterialProfileStockRealization = () => {
        const material_profile = this.state.selected_material_profile;
        if (material_profile === null) {
            return null;
        }

        return this.renderMaterialProfileRealization(material_profile.stock_realizations);
    };

    renderMaterialProfileDurationRealization = () => {
        const material_profile = this.state.selected_material_profile;
        if (material_profile === null) {
            return null;
        }

        return this.renderMaterialProfileRealization(material_profile.duration_realizations);
    };

    renderMaterialProfileDetails = () => {
        const material_profile = this.state.selected_material_profile;
        const material = material_profile && (
            <span>
                {material_profile.material_title}{" "}
                <span className="text-muted">({material_profile.material_external_id})</span>
            </span>
        );
        const views = {
            [MATERIAL_PROFILE_VIEWS.input_materials]: {
                title: <span>Input Materials: {material}</span>,
                content: this.renderMaterialProfileInputMaterials()
            },
            [MATERIAL_PROFILE_VIEWS.stock_realization]: {
                title: <span>Stock Realization: {material}</span>,
                content: this.renderMaterialProfileStockRealization()
            },
            [MATERIAL_PROFILE_VIEWS.duration_realization]: {
                title: <span>Duration Realization: {material}</span>,
                content: this.renderMaterialProfileDurationRealization()
            }
        };
        const view = this.state.show_material_profile_details && views[this.state.show_material_profile_details];

        return (
            <Modal
                dialogClassName="modal-dialog-scrollable modal-max"
                show={material_profile !== null}
                bsSize="lg"
                animation={false}
            >
                <Modal.Header>
                    <Modal.Title>{view && view.title}</Modal.Title>
                    <button type="button" className="close" onClick={this.handleMaterialProfileDetailsHide}>
                        <span aria-hidden="true">×</span>
                        <span className="sr-only">{translate("common.close", "Close")}</span>
                    </button>
                </Modal.Header>
                <Modal.Body className="p-0">{view && view.content}</Modal.Body>
            </Modal>
        );
    };

    render() {
        const { is_electricity_admin, settings, selected_tab } = this.state;
        return (
            <article className="article">
                <section id="statistics" className="data_sources h-100 pb-0">
                    {this.renderToolbar()}
                    <div
                        className="container-fluid pb-3"
                        style={{
                            paddingTop: 68,
                            minWidth: "fit-content"
                        }}
                    >
                        {this.state.is_loading ? (
                            <Loader />
                        ) : (
                            <React.Fragment>
                                {this.state.error && (
                                    <ErrorComponent className="mt-0" type="error" msg={this.state.error} />
                                )}
                                {this.state.profit_center_ids.length > 0 ? (
                                    <Tabs
                                        className="electricity-tabs"
                                        activeKey={selected_tab}
                                        onSelect={this.handleTabSelect}
                                        id="electricity-tabs"
                                        animation={false}
                                    >
                                        <Tab eventKey={MAIN_VIEWS.input} title="Input" tabClassName="nav-item">
                                            <div className="row">
                                                <div className="col-12">{this.renderInputTimeRange()}</div>
                                                <div className="col-12">{this.renderProfitCentersInput()}</div>
                                                {settings !== null &&
                                                settings.extras_matrix.length > 0 &&
                                                is_electricity_admin && (
                                                    <div className="col-12 mt-3">{this.renderExtrasInput()}</div>
                                                )}
                                            </div>
                                        </Tab>
                                        <Tab eventKey={MAIN_VIEWS.result} title="Result" tabClassName="nav-item">
                                            <div className="row">
                                                <div className="col-12">
                                                    {this.renderMaterialProfiles()}
                                                    {this.renderMaterialProfileDetails()}
                                                </div>
                                            </div>
                                        </Tab>
                                    </Tabs>
                                ) : (
                                    <ErrorComponent
                                        className="my-0"
                                        type="no-data"
                                        msg={translate("common.no_data", "No data available")}
                                    />
                                )}
                            </React.Fragment>
                        )}
                    </div>
                </section>
            </article>
        );
    }
}

export default Electricity;
