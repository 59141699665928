// @flow

// main imports
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import Authorization from "../Authorization";
import * as Auth from "../../lib/Auth";
import { translate } from "../IntlProviderWrapper";

// models
import * as t from "../../lib/backend/manufacturing2.generated.types";


// defining types
type Props = {
    plantFilter: string,
    selected: t.IPlantData | null,
    line_groups: t.ILineGroupData[],
    lines: t.ILineData[]
}

/**
 * Plants details component.
 */
class PlantsDetails extends Component<Props> {

    getSelectedUuid = (): string => {
        return this.props.selected ? this.props.selected.uuid : "";
    }

    getSelectedExternalId = (): string => {
        return this.props.selected ? this.props.selected.external_id : "";
    }

    getSelectedWeight = (): number => {
        return this.props.selected ? this.props.selected.weight : -1;
    }

    getSelectedTitle = (): string => {
        return this.props.selected ? this.props.selected.title : "";
    }

    /**
     * Render component.
     */
    render() {
        if (this.props.selected === null) {
            return null;
        }

        let tags = "";

        if (this.props.selected.tags == null) {
            tags = "/";
        } else {
            // generate tags
            tags = Object.keys(this.props.selected.tags)
                .sort((a, b) => a.localeCompare(b))
                .map((el, i) => {
                    // flow does not handle the line below correctly, it returns error although checking for el is implemented above
                    // $FlowFixMe
                    return(<span className="badge badge-secondary" style={{marginRight: 3}} key={i}>{el + ": " + this.props.selected.tags[el]}</span>);
                });
        }

        return (
            <div className="tab-content">
                <div className="tab-pane active tab-pane-text">
                    <div className="d-flex" style={{float: "right"}}>
                        <Authorization.Link
                            permission={Auth.PERMISSION_NAMES.PlantEdit}
                            className="btn btn-outline-primary"
                            to={`/digital-twin/resources/plant/edit/${this.getSelectedUuid()}`}
                        >
                            {translate("common.edit", "edit")}
                        </Authorization.Link>
                    </div>
                    <h5 className="pane-title"><em><FormattedMessage id="common.plant" defaultMessage="Plant" /></em> {this.getSelectedTitle()}</h5>
                    <dl className="property-list">
                        <div>
                            <dt><FormattedMessage id="common.external_id" defaultMessage="External ID" /></dt>
                            <dd><div>{this.getSelectedExternalId()}</div></dd>
                        </div>
                        <div>
                            <dt><FormattedMessage id="common.weight" defaultMessage="Weight" /></dt>
                            <dd><div>{this.getSelectedWeight()}</div></dd>
                        </div>
                        <div>
                            <dt><FormattedMessage id="common.tags" defaultMessage="Tags" /></dt>
                            <dd>{tags}</dd>
                        </div>
                        <div>
                            <dt className="light"><FormattedMessage id="common.uuid" defaultMessage="UUID" /></dt>
                            <dd className="light"><div>{this.getSelectedUuid()}</div></dd>
                        </div>
                    </dl>

                    <div className="card-deck">
                        <div className="card">
                            <div className="card-header text-uppercase bg-transparent">
                                <FormattedMessage id="common.lines" defaultMessage="Lines" /> (
                                    {this.props.lines.filter(line => (line.plant_uuid === this.props.plantFilter)).length}
                                )
                            </div>
                            <div className="list-group list-group-flush">
                                {this.props.lines.map((line) => {
                                    if (line.plant_uuid !== this.props.plantFilter) {
                                        return null;
                                    }
                                    return (
                                        <Link className="list-group-item list-group-item-action"
                                            to={`/digital-twin/resources/lines/${line.uuid}`}
                                            key={line.uuid}>{line.title}</Link>
                                    )
                                })}
                            </div>
                            <div className="space15"></div>
                        </div>

                        <div className="card">
                            <div className="card-header text-uppercase bg-transparent">
                                <FormattedMessage id="common.linegroups" defaultMessage="Line-groups" /> (
                                    {this.props.line_groups.filter(line_group => line_group.plant_uuids.includes(this.props.plantFilter)).length}
                                )
                            </div>
                            <div className="list-group list-group-flush">
                                {this.props.line_groups.map((line_group) => {
                                    if (!line_group.plant_uuids.includes(this.props.plantFilter)) {
                                        return null;
                                    }
                                    return (
                                        <Link className="list-group-item list-group-item-action"
                                            to={`/digital-twin/resources/line_groups/${line_group.uuid}`}
                                            key={line_group.uuid}>{line_group.title}</Link>
                                    )
                                })}
                            </div>
                            <div className="space15"></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PlantsDetails;
