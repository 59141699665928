// @flow
import React from "react";
import { Provider } from 'react-redux';
import { combineReducers, createStore } from 'redux';
import { reducers } from "./reducers/reducers";
import GanttChartContainer from "./GanttChartContainer";
import GanttChartStandaloneCallbacks from "./Callbacks/GanttChartStandaloneCallbacks"
import Loader from "../../Loader";
import InsightsTypesSelect from "./InsightTypesSelect";
import ShowPastProductionsToggle from "./ShowPastProductionsToggle";
import { GanttChartSource } from "./reducers/properties";
import * as rt from "../../../lib/SimulationReportModels";
import * as t from "../../../lib/backend/manufacturing2.generated.types";
import * as t2 from "../../../lib/backend/reports.generated.types";
import { DraggableProvider } from "./Draggable/DraggableContext";

import type { GanttChartStandaloneCallbacksType } from "./Callbacks/GanttChartStandaloneCallbacks";
import type { GanttChartSourceTypes } from "./reducers/properties";

export type GanttChartContainerProps2 = {
    handleClick: Function,
    options?: rt.ISimulationReportOrderLineEx[],
    shifts?: t2.ISimulationReportOrderLineShiftFeature[],
    insights: Array<t.IEventDataEx>,
    insight_types: string[],
    report: rt.IReportModelEx | null,
    show_past_productions: boolean,
    current_shift?: number,
    past_weeks: number,
    start_day?: number,
    days: number,
    week?: number,
    year: number,
    simulation_uuid: string,
    space_for_label?: number,
    show_labels?: boolean,
    top_padding?: number,
    bar_height?: number,
    space_between_bars?: number,
    no_overflow?: boolean,
    show_only_tool_changes: boolean,
    line_group_uuid: string,
    loading?: boolean,
    source_type: GanttChartSourceTypes,
    gantt_chart_standalone_callbacks?: GanttChartStandaloneCallbacksType,
    show_only_unscheduled?: boolean,
    disable_zoom?: boolean,
    onOrderChange?: (order: t.IOrderModelBase) => void
};

type GanttChartAppState = {};

export default class GanttChartApp extends React.Component<GanttChartContainerProps2, GanttChartAppState> {
    store = null;

    constructor(props: GanttChartContainerProps2) {
        super(props);
        this.store = createStore(combineReducers(reducers));
    }

    render() {
        if (this.props.loading) {
            return <Loader small/>;
        }

        // Wait for gantt data
        const sim = this.props.report;
        if (!sim) {
            return (<h2>Waiting...</h2>);
        }
        const insights = this.props.insights || [];
        // If no orders were found
        if (sim.result.orders.length === 0) {
            return (<div>No gantt data</div>)
        }

        if (!this.store) return null;

        return <DraggableProvider>
            <Provider store={this.store}>
                <div id="gantt-chart-app" className="gantt-chart-app">
                    {this.props.source_type === GanttChartSource.gantt &&
                        <div className="custom-filter-bar">
                            <InsightsTypesSelect />
                            <ShowPastProductionsToggle line_group_uuid={this.props.line_group_uuid} />
                        </div>
                    }
                    <div id="gantt-chart-container" className="gantt-chart-container">
                        <GanttChartContainer
                            report={sim}
                            current_shift={sim.result.next_shift_time.shift_number}
                            week={sim.result.next_shift_time.week}
                            year={sim.result.next_shift_time.year}
                            simulation_uuid={sim.uuid}
                            line_group_uuid={this.props.line_group_uuid}
                            insights={insights}
                            insight_types={this.props.insight_types}
                            past_weeks={this.props.past_weeks}
                            days={this.props.days}
                            show_past_productions={this.props.show_past_productions}
                            show_only_tool_changes={this.props.show_only_tool_changes}
                            handleClick={this.props.handleClick}
                            source_type={this.props.source_type}
                            gantt_chart_standalone_callbacks={this.props.gantt_chart_standalone_callbacks}
                            show_only_unscheduled={this.props.show_only_unscheduled}
                            onOrderChange={this.props.onOrderChange}
                            disable_zoom={this.props.disable_zoom}
                        />
                        {
                            this.props.gantt_chart_standalone_callbacks &&
                            <GanttChartStandaloneCallbacks {...this.props.gantt_chart_standalone_callbacks} />
                        }
                    </div>
                </div>
            </Provider>
        </DraggableProvider>
    }
}
