// @flow
import * as t2 from "../../../../lib/SimulationReportModels";
import * as t from "../../../../lib/backend/manufacturing2.generated.types";

import { ORDER_TAGS } from "../../../../lib/ManufacturingTags.generated";

import type { IOrderProducedModelEx, LineOrders} from "./linesOrders";
import type { ISimulationReportOrderEx } from "../../../../lib/SimulationReportModels";

export class ReportMaps {

    static report_orders: Map<string, ISimulationReportOrderEx> = new Map();
    static order_on_line: Map<string, string> = new Map();

    static clearMaps() {
        ReportMaps.report_orders.clear();
    }

    static updateMaps(report_data: t2.IReportModelEx) {
        ReportMaps.clearMaps();
        for (const line of report_data.result.orders) {
            for (const order of line.production) {
                ReportMaps.report_orders.set(order.order_id, order);
                ReportMaps.order_on_line.set(order.order_id, line.line);
            }
        }

    }
}

export class LinesOrdersMaps {

    static rework_uuid_base_order: Map<string, IOrderProducedModelEx> = new Map();
    static base_uuid_rework_orders: Map<string, IOrderProducedModelEx[]> = new Map();
    static all_orders_map: Map<string, IOrderProducedModelEx> = new Map();
    static unscheduled_orders: Map<string, IOrderProducedModelEx> = new Map();
    // parents of chunks are removed from lines orders and this is the only map available
    static parents_of_chunks: Map<string, t.IOrderProducedModel> = new Map();
    static chunk_orders: Map<string, t.IOrderProducedModel> = new Map();

    static clearMaps = () => {
        LinesOrdersMaps.rework_uuid_base_order.clear();
        LinesOrdersMaps.base_uuid_rework_orders.clear();
        LinesOrdersMaps.all_orders_map.clear();
        LinesOrdersMaps.unscheduled_orders.clear();
    }

    static updateMaps = (lines_orders: LineOrders[], unscheduled_orders: LineOrders[], ignored_orders: LineOrders[]) => {
        LinesOrdersMaps.clearMaps();
        LinesOrdersMaps.updateOrdersMaps(lines_orders);
        LinesOrdersMaps.updateOrdersMaps(unscheduled_orders, true);
        LinesOrdersMaps.updateOrdersMaps(ignored_orders);
    }

    static updateChunkOrders = (chunk_orders: t.ILinePlanningSplitOrder[]) => {
        if (!chunk_orders) {
            console.log("Chunk orders not found...", chunk_orders);
            return;
        }
        for (const parent of chunk_orders) {
            LinesOrdersMaps.parents_of_chunks.set(parent.parent.uuid, parent.parent);
            for (const chunk of parent.chunks) {
                LinesOrdersMaps.chunk_orders.set(chunk.uuid, chunk);
            }
        }
    }

    static updateOrdersMaps = (lines_orders: LineOrders[], is_unscheduled: boolean = false) => {
        for (const line of lines_orders) {
            for (const order of line.orders) {
                LinesOrdersMaps.all_orders_map.set(order.uuid, order);
                if (is_unscheduled) {
                    LinesOrdersMaps.unscheduled_orders.set(order.uuid, order);
                }
                if (order.tags[ORDER_TAGS.linked_operation]) {
                    const base_uuid = order.tags[ORDER_TAGS.linked_operation];
                    const rework_orders = LinesOrdersMaps.base_uuid_rework_orders.get(base_uuid);
                    if (!rework_orders) {
                        LinesOrdersMaps.base_uuid_rework_orders.set(base_uuid, [order]);
                    } else {
                        rework_orders.push(order);
                        LinesOrdersMaps.base_uuid_rework_orders.set(base_uuid, rework_orders);
                    }
                }
            }
        }

        for (const [base_order_uuid, orders] of LinesOrdersMaps.base_uuid_rework_orders.entries()) {
            const base_order = LinesOrdersMaps.all_orders_map.get(base_order_uuid);
            if (base_order) {
                for (const order of orders) {
                    LinesOrdersMaps.rework_uuid_base_order.set(order.uuid, base_order);
                }
            }
        }
    }

}