// @flow

// main imports
import React, { Component } from "react";

// backend
import { getBackend } from "../../lib/backend/Backend2";
import * as Util from "../../lib/Util";
import ErrorComponent from "../ErrorComponent";
import { FormattedMessage } from "react-intl";
import { translate } from "../IntlProviderWrapper";
import { LINE_TAGS } from "../../lib/ManufacturingTags.generated";

type ShiftPredVsReal = {
    pred: number;
    real: number;
}

type MaterialPredVsReal = {
    material_external_id: string,
    material_title: string,
    shifts: ShiftPredVsReal[];
}

type LinePredVsReal  = {
    line_uuid: string;
    line_title: string;
    materials: MaterialPredVsReal[];
}

type DayPredVsReal = {
    day: string;
    lines: LinePredVsReal[];
}

const OFFSET = 24; // how many hours before shift we check predictions
const SHIFTS = 21; // how many shifts do we check

// defining types
type Props = {
    plant_uuid: string,
    line_group_uuid: string
}

type State = {
    result: DayPredVsReal[],
    message: string
}

class PredictionVsReal extends Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            result: [],
            message: ""
        };
    }

    async componentDidMount() {
        await this.refreshReportList();
    }

    async componentDidUpdate(prev_props: Props, prev_state: State) {
        if (prev_props.plant_uuid !== this.props.plant_uuid ||
            prev_props.line_group_uuid !== this.props.line_group_uuid) {

            await this.refreshReportList();
        }
    }

    async refreshReportList() {
        // figure out last report time
        const latest = await getBackend().reports.searchReports({ num_reports: 1 });
        if (latest.reports.length === 0) {
            this.setState({ result: [], message: translate("common.no_reports", "No reports") });
            return;
        } else {
            this.setState({ message: translate("common.loading", "Loading...") });
        }
        // we start with shift before shift of the latest report, for which
        // already have both predictions and realization are the database
        let shift_date = new Date(latest.reports[0].created_at - 8 * Util.TIME_RANGES.HOUR);
        // placeholder for storing results of individual days
        const result: DayPredVsReal[] = [ ];
        // go back in time and get evaluation for each shift
        for (let shift = 0; shift < SHIFTS; shift++) {
            // get shift from current timestamp
            const shift_number = Util.shiftNumber(shift_date);
            const day_number = Math.floor(shift_number.shift / 3);
            // move date to shift start
            shift_date = Util.fromShiftTag(shift_number.shift_tag);
            // get nearest report uuid
            const date = new Date(shift_date.getTime() - OFFSET * Util.TIME_RANGES.HOUR).toISOString();
            const { reports } = await getBackend().reports.searchReports({ date });
            if (reports.length === 0) {
                this.setState({
                    message: translate("common.no_reports_before_date", "No reports before {date}!").replace('{date}', date)
                });
                break;
            }
            const report_uuid = reports[0].uuid;
            // retrieve results
            const { lines } = await getBackend().reports.predVsReal({
                plant_uuid: this.props.plant_uuid,
                linegroup_uuid: this.props.line_group_uuid,
                report_uuid: report_uuid,
                year: shift_number.year,
                week: shift_number.week,
                shift_number: shift_number.shift
            });
            // check if we need to create new day
            if (result.length === 0 || shift_number.shift % 3 === 2) {
                result.push({
                    day: `${shift_number.year} ` +
                         `${translate("common.week_short", "W")}${shift_number.week} ` +
                         `${translate(`common.weekday${day_number}`)}`,
                    lines: [ ]
                });
            }
            const last_day_idx = result.length - 1;
            // add to the day
            for (const line of lines) {
                // skip non-bottleneck lines
                if (line.line_tags[LINE_TAGS.bottleneck] !== "true") { continue; }
                // find line in the day and if not exist create
                let line_idx = -1;
                for (let i = 0; i < result[last_day_idx].lines.length; i++) {
                    if (result[last_day_idx].lines[i].line_uuid === line.line_uuid) {
                        line_idx = i;
                        break;
                    }
                }
                // if we did not find the line, add it
                if (line_idx === -1) {
                    result[last_day_idx].lines.push({
                        line_uuid: line.line_uuid,
                        line_title: line.line_title,
                        materials: []
                    });
                    line_idx = result[last_day_idx].lines.length - 1;
                }
                // go over all materails in the result and add them to the table
                const materials = result[last_day_idx].lines[line_idx].materials;
                for (const material of line.materials) {
                    let material_idx = -1;
                    for (let i = 0; i < materials.length; i++) {
                        if (materials[i].material_external_id === material.material_external_id) {
                            material_idx = i;
                            break;
                        }
                    }
                    // check if first time we see it
                    if (material_idx === -1) {
                        materials.push({
                            material_external_id: material.material_external_id,
                            material_title: material.material_title,
                            shifts: [
                                { pred: 0, real: 0 },
                                { pred: 0, real: 0 },
                                { pred: 0, real: 0 }
                            ]
                        });
                        material_idx = materials.length - 1;
                    }
                    // update the current shift
                    materials[material_idx].shifts[shift_number.shift_day] = {
                        pred: material.pred,
                        real: material.real
                    };
                }

            }
            // update state so we render results as they come in
            console.log(result);
            this.setState({ result, message: translate("common.loading", "Loading...") });
            // move back in time
            shift_date = new Date(shift_date.getTime() - 8 * Util.TIME_RANGES.HOUR);
        }
        this.setState({ message: "" });
    }

    renderRows(lines: LinePredVsReal[]) {
        const rows = [];
        for (const line of lines) {
            if (line.materials.length === 0) {
                rows.push(<tr key={line.line_uuid}>
                    <td>{line.line_title}</td>
                    <td colSpan={6}>---</td>
                </tr>)
            } else {
                let first = true;
                for (const material of line.materials) {
                    if (first) {
                        rows.push(<tr key={line.line_uuid + material.material_external_id}>
                            <td rowSpan={line.materials.length}>{line.line_title}</td>
                            <td>{material.material_title}</td>
                            <td>{Math.round(material.shifts[0].pred)}</td>
                            <td>{Math.round(material.shifts[0].real)}</td>
                            <td>{Math.round(material.shifts[1].pred)}</td>
                            <td>{Math.round(material.shifts[1].real)}</td>
                            <td>{Math.round(material.shifts[2].pred)}</td>
                            <td>{Math.round(material.shifts[2].real)}</td>
                        </tr>);
                    } else {
                        rows.push(<tr key={line.line_uuid + material.material_external_id}>
                            <td>{material.material_title}</td>
                            <td>{Math.round(material.shifts[0].pred)}</td>
                            <td>{Math.round(material.shifts[0].real)}</td>
                            <td>{Math.round(material.shifts[1].pred)}</td>
                            <td>{Math.round(material.shifts[1].real)}</td>
                            <td>{Math.round(material.shifts[2].pred)}</td>
                            <td>{Math.round(material.shifts[2].real)}</td>
                        </tr>);
                    }
                    first = false;
                }
            }
        }
        return rows;
    }

    renderDay(day: DayPredVsReal) {
        return (<React.Fragment>
            <h4>{day.day}</h4>
            <table className="table">
                <thead>
                    <tr>
                        <th colSpan={2}></th>
                        <th colSpan={2}><span className="badge badge-shift badge-shift-1">1</span></th>
                        <th colSpan={2}><span className="badge badge-shift badge-shift-2">2</span></th>
                        <th colSpan={2}><span className="badge badge-shift badge-shift-3">3</span></th>
                    </tr>
                    <tr>
                        <th><FormattedMessage id="common.Line" defaultMessage="Line" /></th>
                        <th><FormattedMessage id="common.material" defaultMessage="Material" /></th>
                        <th><FormattedMessage id="common.predicted" defaultMessage="Predicted" /></th>
                        <th><FormattedMessage id="common.produced" defaultMessage="Produced" /></th>
                        <th><FormattedMessage id="common.predicted" defaultMessage="Predicted" /></th>
                        <th><FormattedMessage id="common.produced" defaultMessage="Produced" /></th>
                        <th><FormattedMessage id="common.predicted" defaultMessage="Predicted" /></th>
                        <th><FormattedMessage id="common.produced" defaultMessage="Produced" /></th>
                    </tr>
                </thead>
                <tbody>
                    {this.renderRows(day.lines)}
                </tbody>
            </table>
            <br />
        </React.Fragment>);
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="white_box space10i">
                            <ErrorComponent msg={this.state.message} type="info" />

                            {this.state.result.map(day => this.renderDay(day))}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PredictionVsReal;
