// @flow

import { getBackend as getBackend2 } from "./backend/Backend2";
import { getLang, translate } from "../components/IntlProviderWrapper";
import * as t from "./backend/reports.generated.types";
import * as t2 from "./SimulationReportModels";

export type FilteredTypeOrder = {
    order_external_id: string,
    process_num: string | number,
    message?: string
}
export type FilterLinesOrders = {
    is_extra_line: boolean,
    line_uuid: string,
    line_title?: string,
    order_uuids: string[],
    orders: FilteredTypeOrder[]
}

export type OrderZeroDurationType = t.ISimulationReportUnscheduledOrder | t.ISimulationReportOrder | t2.ISimulationReportOrderEx;

const mapOrderWithZeroDuration = (order: OrderZeroDurationType, message?: string) => {
    return {
        order_external_id: order.order_external_id,
        process_num: order.process_num,
        message
    }
}

export function getOrdersWithZeroDuration(
    simulation: t.IReportModel | t2.IReportModelEx,
    extra_line_uuids: string[]
): FilterLinesOrders[] {
    const extra_line_uuid_set = new Set(extra_line_uuids);
    const filtered_orders_map: Map<string, FilterLinesOrders> = new Map();

    // we check for any order with estimated duration of 0 or marked as closed
    for (const line of simulation.result.orders) {
        for (const order of line.production) {
            // chunks created on planning board are never fitered
            if (order.order_type === "chunk") { continue; }

            const duration = order.estimated_duration.length > 0 ? order.estimated_duration[1].val : 0.0;
            const closed = order.order_status !== "open";

            if (duration === 0 || closed) {
                let message = translate("common.alert_duration0", "Filtered out orders with 0 duration");
                if (closed) {
                    message = translate("common.alert_closed_overflow", "Filtered out orders with all parts produced")
                } else if (duration === 0) {
                    message = translate("common.alert_norm", "Filtered out orders with normative 0")
                }
                console.log(`Filtering scheduled order ${order.order_external_id}-${order.process_num} with duration ${duration} and status ${order.order_status}`, order);
                const filter_line = filtered_orders_map.get(line.line);
                if (filter_line) {
                    filter_line.order_uuids.push(order.order_id);
                    const mapped_order = mapOrderWithZeroDuration(order, message);
                    filter_line.orders.push(mapped_order);
                } else {
                    const mapped_order = mapOrderWithZeroDuration(order, message);
                    filtered_orders_map.set(line.line, {
                        is_extra_line: extra_line_uuid_set.has(line.line),
                        line_uuid: line.line,
                        line_title: line.line_title,
                        order_uuids: [order.order_id],
                        orders: [mapped_order]
                    });
                }
            }
        }
    }

    // do same check for unscheduled orders
    for (const order of simulation.result.unscheduled) {
        const duration = order.estimated_duration.length > 0 ? order.estimated_duration[1].val : 0.0;
        const closed = order.order_status !== "open";

        if (duration === 0 || closed) {
            let message = translate("common.alert_duration0", "Filtered out orders with 0 duration");
            if (closed) {
                message = translate("common.alert_closed_overflow", "Filtered out orders with all parts produced")
            } else if (duration === 0) {
                message = translate("common.alert_norm", "Filtered out orders with normative 0")
            }
            console.log(`Filtering unscheduled order ${order.order_external_id}-${order.process_num} with duration ${duration} and status ${order.order_status}`, order);
            const filter_line = filtered_orders_map.get(order.line_uuid);
            if (filter_line) {
                filter_line.order_uuids.push(order.order_uuid);
                const mapped_order = mapOrderWithZeroDuration(order, message);
                filter_line.orders.push(mapped_order);
            } else {
                const mapped_order = mapOrderWithZeroDuration(order, message);
                filtered_orders_map.set(order.line_uuid, {
                    is_extra_line: extra_line_uuid_set.has(order.line_uuid),
                    line_uuid: order.line_uuid,
                    order_uuids: [order.order_uuid],
                    orders: [mapped_order]
                });
            }
        }
    }

    return [...filtered_orders_map.values()];
}

/** Enrich incoming data */
export function addMaterialColors(simulation_orig: t.IReportModel | t2.IReportModelEx): t2.IReportModelEx {
    const tmp: any = simulation_orig;
    const simulation: t2.IReportModelEx = tmp;

    // orders
    const lines = simulation.result.orders;
    for (const line of lines) {
        const orders = line.production
            .filter(x => x.estimated_duration.length > 0 && x.estimated_duration[1].val !== -1);
        let material_dict: Map<string, number> = new Map();
        let material_counter = 0;
        for (let order_counter = 0; order_counter < orders.length; order_counter++) {
            const order = orders[order_counter];
            order.order_color_counter = order_counter;
            if (!material_dict.has(order.material)) {
                material_dict.set(order.material, material_counter);
                material_counter++;
            }
            order.material_color_counter = material_dict.get(order.material) || 0;
        }
        line.production = orders;
    }
    return simulation;
}

/** Get data for gantt chart */
export async function getGanttDataSimpleAsync(
    report_uuid: string | null,
    lines: Array<string>,
    strip_shifts?: number,
    history_days?: number
): Promise<t2.IReportModelEx> {
    const line_uuids = lines.join(",");
    const res = report_uuid
            ? await getBackend2().reports.getReport({ id: report_uuid, line_uuids, language_code: getLang() })
            : await getBackend2().reports.getLatestReport({
                line_uuids,
                strip_shifts,
                language_code: getLang(),
                history_days
            });
    return addMaterialColors(res.report);
}

/** Gets complete structure for displaying gantt data */
export function getGanttDataSimple(report: string | null, lines: Array<string>, done: (t2.IReportModelEx => void), err: (string => void)) {
    getGanttDataSimpleAsync(report, lines)
        .then(done)
        .catch(error => err(error.message));
}
