// @flow
import { windows } from "./PlanningTablePerformance";
import * as BusinessLogic from "../../../lib/BusinessLogic";
import * as Auth from "../../../lib/Auth";

type ComponentDidUpdateEntry = {
    ts: number,
    prop_key: string,
    prev_value: string,
    current_value: string,
    component: string | null
}

export let render_performance_data = new Map<string, number[]>();
export let componentdidupdate_data = new Map<string, Array<ComponentDidUpdateEntry[]>>();
export let num_mutations = new Map<number, any>();

export default class PerformanceAnalysis {

    static observer = null;

    static hasPermissions = () => {
        const is_dev_env = BusinessLogic.getSysFlag("env") === "dev";
        const is_admin = Auth.isInRole(Auth.ROLE_ADMIN)
        return is_dev_env && is_admin;
    }

    static clearPerformance = (): void => {
        render_performance_data = new Map<string, number[]>();
        componentdidupdate_data = new Map<string, Array<ComponentDidUpdateEntry[]>>();
        num_mutations = new Map<number, number>();
        const win = windows[0]
        if (win) {
            win.render_performance_data = render_performance_data;
            win.componentdidupdate_data = componentdidupdate_data;
            win.num_mutations = num_mutations;
        }
    }

    static componentDidUpdate(component: string | null, prev_props: any, props: any, time: number) {
        if (!component || !PerformanceAnalysis.hasPermissions()) {
            return;
        }

        const prop_keys = Object.keys(props);
        const entries = [];
        const now_ts = time;
        for (let prop_key of prop_keys) {
            const prev_value = prev_props[prop_key];
            const current_value = props[prop_key];
            if (prev_value !== current_value) {
                const entry: ComponentDidUpdateEntry = {
                    ts: now_ts,
                    prop_key,
                    prev_value,
                    current_value,
                    component
                };
                entries.push(entry);
            }
        }

        if (!componentdidupdate_data.has(component)) {
            componentdidupdate_data.set(component, [entries]);
        } else {
            const data = componentdidupdate_data.get(component);
            if (data) {
                data.push(entries);
                componentdidupdate_data.set(component, data);
            }
        }

        if (windows[0]) {
            windows[0].componentdidupdate_data = componentdidupdate_data;
        }
    }

    static render(key: string | null, time: number) {
        if (!key || !PerformanceAnalysis.hasPermissions()) {
            return;
        }
        if (!render_performance_data.has(key)) {
            render_performance_data.set(key, [time]);
        } else {
            const data = render_performance_data.get(key);
            if (data) {
                data.push(time);
                render_performance_data.set(key, data);
            }
        }

        if (windows[0]) {
            windows[0].render_performance_data = render_performance_data;
        }
    }

    static startObserving() {
        if (!PerformanceAnalysis.hasPermissions()) {
            return;
        }
        function callback(mutations: any) {
            // code to handle the mutations
            num_mutations.set(Date.now(), mutations);

            if (windows[0]) {
                windows[0].num_mutations = num_mutations;
            }
        }
    
        const observer = new MutationObserver(callback);
        const options = { childList: true };
        const elements = document.querySelectorAll("*");

        for (let element of elements) {
            observer.observe(element, options);
        }

        PerformanceAnalysis.observer = observer;
    }

    static stopObserving() {
        if (PerformanceAnalysis.observer) {
            PerformanceAnalysis.observer.disconnect();
        }
    }
}
