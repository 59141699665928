// @flow

import { propertiesReducer, renderOrderReducer } from "./properties";
import { reportReducer } from "./report";
import { parallelReportReducer } from "./parallelReport";
import { linesOrdersReducer } from "./linesOrders";
import { planningTableReducer } from "./planningTable";
import { draggedOrderReducer } from "./draggedOrder";
import { insightsReducer } from "./insights";
import { controlsReducer } from "./controls";
import { planningTableFiltersReducer } from "./reducersFilters/filters";
import { standaloneGanttReducer } from "./ganttChartStandalone";
import { kpisReducer } from "./kpis";
import { recommendPlanReducer } from "./recommendPlan";
import { stockForecastTooltipReducer } from "./stockForecastTooltipReducer";
import { stockRequirementsReducer } from "./stockRequirements";
import { stockRequirementsFiltersReducer } from "./reducersFilters/stockRequirementsFilters";
import { alertsReducer } from "./alertsReducer";
import { ganttChartReducer } from "./ganttChart";

import type { StockRequirementsFiltersState, StockRequirementsFiltersActions } from "./reducersFilters/stockRequirementsFilters";
import type { DraggedOrderState, DraggedOrderActions } from "./draggedOrder";
import type { PropertiesState, PropertiesActionsTypes, RenderOrderState, RenderOrderActions } from "./properties";
import type { PlanningTableState, PlanningTableActionsTypes } from "./planningTable";
import type { LinesOrdersState, LineOrdersActionsTypes } from "./linesOrders";
import type { ReportState, ReportActionsTypes } from "./report";
import type { ParallelReportState, ParallelReportActionsTypes } from "./parallelReport";
import type { InsightsState, InsightsActionsTypes } from "./insights";
import type { PlanningTableFiltersState, PlanningTableFiltersActionTypes } from "./reducersFilters/filters";
import type { ControlsActions, ControlsState } from "./controls";
import type { StandaloneGanttState, StandaloneGanttActions } from "./ganttChartStandalone";
import type { KpisState, KpisActions } from "./kpis";
import type { RecommendPlanState, RecommendPlanActions } from "./recommendPlan";
import type { StockForecastTooltipState, StockForecastTooltipActions } from "./stockForecastTooltipReducer";
import type { StockRequirementsState, StockRequirementsActions } from "./stockRequirements";
import type { AlertsState, AlertsActions } from "./alertsReducer";
import type { GanttChartState, GanttChartActions } from "./ganttChart";

export type ActionReduxState = {
    gantt_chart: (state: GanttChartState, action: GanttChartActions) => GanttChartState,
    gantt_chart_recommend_plans: (state: RecommendPlanState, action: RecommendPlanActions) => RecommendPlanState,
    gantt_chart_lines_orders: (state: LinesOrdersState, action: LineOrdersActionsTypes) => LinesOrdersState,
    gantt_chart_dragged_order: (state: DraggedOrderState, action: DraggedOrderActions) => DraggedOrderState,
    gantt_chart_planning_table: (state: PlanningTableState, action: PlanningTableActionsTypes) => PlanningTableState,
    gantt_chart_report: (state: ReportState, action: ReportActionsTypes) => ReportState,
    gantt_chart_properties: (state: PropertiesState, action: PropertiesActionsTypes) => PropertiesState,
    gantt_chart_insights: (state: InsightsState, action: InsightsActionsTypes) => InsightsState,
    gantt_chart_filters: (state: PlanningTableFiltersState, action: PlanningTableFiltersActionTypes) => PlanningTableFiltersState,
    gantt_chart_render_order: (state: RenderOrderState, action: RenderOrderActions) => RenderOrderState,
    gantt_chart_controls: (state: ControlsState, action: ControlsActions) => ControlsState,
    gantt_chart_parallel_report: (state: ParallelReportState, action: ParallelReportActionsTypes) => ParallelReportState,
    gantt_chart_kpis: (state: KpisState, action: KpisActions) => KpisState,
    gantt_chart_stock_requirements_filter: (state: StockRequirementsFiltersState, actions: StockRequirementsFiltersActions) => StockRequirementsFiltersState,
    gantt_chart_stock_requirements: (state: StockRequirementsState, actions: StockRequirementsActions) => StockRequirementsState,
    standalone_gantt: (state: StandaloneGanttState, action: StandaloneGanttActions) => StandaloneGanttState,
    stock_forecast_tooltip: (state: StockForecastTooltipState, action: StockForecastTooltipActions) => StockForecastTooltipState,
    stock_requirements: (state: StockRequirementsState, action: StockRequirementsActions) => StockRequirementsState,
    alerts: (state: AlertsState, action: AlertsActions) => AlertsState
}

export type ReduxState = {
    gantt_chart: GanttChartState,
    gantt_chart_recommend_plans: RecommendPlanState,
    gantt_chart_lines_orders: LinesOrdersState,
    gantt_chart_dragged_order: DraggedOrderState,
    gantt_chart_planning_table: PlanningTableState,
    gantt_chart_report: ReportState,
    gantt_chart_properties: PropertiesState,
    gantt_chart_insights: InsightsState,
    gantt_chart_filters: PlanningTableFiltersState,
    gantt_chart_render_order: RenderOrderState,
    gantt_chart_controls: ControlsState,
    gantt_chart_parallel_report: ParallelReportState,
    gantt_chart_kpis: KpisState,
    gantt_chart_stock_requirements: StockRequirementsState,
    gantt_chart_stock_requirements_filter: StockRequirementsFiltersState,
    stock_requirements: StockRequirementsState,
    standalone_gantt: StandaloneGanttState,
    stock_forecast_tooltip: StockForecastTooltipState,
    alerts: AlertsState
}

export const reducers: ActionReduxState = {
    gantt_chart: ganttChartReducer,
    gantt_chart_dragged_order: draggedOrderReducer,
    gantt_chart_planning_table: planningTableReducer,
    gantt_chart_report: reportReducer,
    gantt_chart_properties: propertiesReducer,
    gantt_chart_insights: insightsReducer,
    gantt_chart_filters: planningTableFiltersReducer,
    gantt_chart_render_order: renderOrderReducer,
    gantt_chart_controls: controlsReducer,
    gantt_chart_parallel_report: parallelReportReducer,
    gantt_chart_kpis: kpisReducer,
    gantt_chart_recommend_plans: recommendPlanReducer,
    stock_requirements: stockRequirementsReducer,
    gantt_chart_stock_requirements: stockRequirementsReducer,
    gantt_chart_stock_requirements_filter: stockRequirementsFiltersReducer,
    standalone_gantt: standaloneGanttReducer,
    stock_forecast_tooltip: stockForecastTooltipReducer,
    gantt_chart_lines_orders: linesOrdersReducer,
    alerts: alertsReducer
};

