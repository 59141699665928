// @flow
import React from "react";
import Toggle from "react-toggle";
import { connect } from "react-redux";
import { getShowPastProductions, GanttChartSource, setShowPastProductions } from "./reducers/properties";
import { SET_SHOW_PAST_PRODUCTIONS } from "./reducers/properties";
import { translate } from "../../IntlProviderWrapper";
import { LINE_GROUP_TAGS } from "../../../lib/ManufacturingTags.generated";
import * as BusinessLogic from "../../../lib/BusinessLogic";

import type { ReduxState } from "./reducers/reducers";
import type { SetShowPastProductions, ResetProperties, GanttChartSourceTypes } from "./reducers/properties";
import type { ResetGanttAction } from "./reducers/common";

type Props = {
    line_group_uuid: string,
    show_past_productions: boolean | null,
    source_type: GanttChartSourceTypes,
    gantt_past_weeks: number,
    reduxDispatch: (args: SetShowPastProductions | ResetProperties | ResetGanttAction) => void
};

type State = {};

class ShowPastProductionsToggle extends React.Component<Props, State> {

    componentDidMount() {
        this.load();
    }

    load() {
        const gantt_past_weeks = this.getGanttPastWeeks();
        if (!gantt_past_weeks) {
            return;
        }

        const past_productions = getShowPastProductions(this.props.source_type, this.props.line_group_uuid);
        if (this.props.show_past_productions !== past_productions) {
            this.props.reduxDispatch({
                type: SET_SHOW_PAST_PRODUCTIONS,
                data: getShowPastProductions(this.props.source_type, this.props.line_group_uuid)
            });
        }
    }

    handleShowHistoryGantt = () => {
        let show_past_productions = !this.props.show_past_productions;
        setShowPastProductions(this.props.source_type, show_past_productions);
        this.props.reduxDispatch({ type: SET_SHOW_PAST_PRODUCTIONS, data: show_past_productions });
    }

    getGanttPastWeeks = () => {
        return BusinessLogic.getLineGroupTagInt(this.props.line_group_uuid, LINE_GROUP_TAGS.gantt_past_weeks, 1);
    }

    render() {
        const gantt_past_weeks = this.getGanttPastWeeks();
        if (this.props.source_type !== GanttChartSource.gantt || !gantt_past_weeks) return null;

        return <div className="show-past-productions-toggle">
            <label>
                {translate("common.show_history", "Show History")}
            </label>
            <div className="form-group">
                <Toggle
                    checked={this.props.show_past_productions}
                    onChange={this.handleShowHistoryGantt}
                />
            </div>
        </div>
    }
}

export default connect(
    (state: ReduxState) => {
        const properties = state.gantt_chart_properties;
        return {
            source_type: properties.source_type,
            show_past_productions: properties.show_past_productions,
            gantt_past_weeks: properties.gantt_past_weeks
        }
    },
    (dispatch) => ({ reduxDispatch: dispatch })
)(ShowPastProductionsToggle);
