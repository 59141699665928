// @flow
import * as React from "react";
import { connect } from "react-redux";
import OrderChangesModal from "./SaveToSap/OrderChangesModal";
import { getLang } from "../../IntlProviderWrapper";
import { getBackend as getBackend2 } from "../../../lib/backend/Backend2";
import { SET_TRANSLATIONS_MATERIALS } from "../../redux/reducers/translationsMaterialsReducer";
import { translate } from "../../IntlProviderWrapper";

import type { ReduxState } from "./reducers/reducers";
import type {
    IReportExportOrderChange,
    IRunPlanTableExportResultOutput
} from "../../../lib/backend/reports.generated.types";
import type { LineOrders } from "./reducers/linesOrders";
import type { ReportParameters } from "./reducers/common";
import type { RemoveReportToDelete } from "./reducers/parallelReport";
import type { SetTranslationsMaterials } from "../../redux/reducers/translationsMaterialsReducer";
import type { IExtraLines } from "../../../lib/backend/manufacturing2.generated.types";

const getLineUuids = (lines_orders: LineOrders[] | null): string[] => {
    if (!lines_orders) return [];
    return lines_orders.map(l => l.line_uuid);
}

type Props = {
    line_group_uuid: string,
    original_report_uuid: string | null,
    current_report_uuid: string | null,
    lines_orders: LineOrders[] | null,
    parameters: ReportParameters,
    reduxDispatch: (args: RemoveReportToDelete | SetTranslationsMaterials) => void,
    show_order_external_id: string | null,
    show_order_line_title: string | null,
    filter_order_uuids: string[],
    onCloseModal: () => void,
    extra_lines: IExtraLines[] | null
}

type State = {
    save_report: IRunPlanTableExportResultOutput | null,
    order_changes: IReportExportOrderChange[],
    loading_canceled: boolean
};

class OrderInsightsDiff extends React.Component<Props, State> {
    state = {
        save_report: null,
        order_changes: [],
        order_export_result: null,
        loading_canceled: false
    }

    async componentDidUpdate(prev_props: Props) {
        if (
            (prev_props.show_order_external_id !== this.props.show_order_external_id && this.props.show_order_external_id) ||
            ((prev_props.show_order_line_title !== this.props.show_order_line_title || this.state.loading_canceled) && this.props.show_order_line_title)
        ) {
            await this.load();
        }
    }

    onCloseModal = () => {
        this.setState({
            save_report: null,
            order_changes: []
        }, () => {
            this.props.onCloseModal();
        });
    }

    load = async () => {
        const original_report_uuid = this.props.original_report_uuid;
        const current_report_uuid = this.props.current_report_uuid || original_report_uuid;
        const line_uuids = getLineUuids(this.props.lines_orders);

        if (original_report_uuid != undefined &&
            current_report_uuid != undefined &&
            line_uuids != undefined
        ) {
            if (this.state.loading_canceled) {
                this.setState({
                    loading_canceled: false
                });
            }

            try {
                const res = await getBackend2().reports.getPlanTableExport({
                    initial_report_uuid: original_report_uuid,
                    final_report_uuid: current_report_uuid,
                    line_uuids,
                    language_code: getLang(),
                    filter_order_uuids: this.props.filter_order_uuids,
                    extra_lines: this.props.extra_lines,
                    dont_filter_extra_lines: !this.props.show_order_line_title
                });

                if (res.report_export.translations) {
                    this.props.reduxDispatch({
                        type: SET_TRANSLATIONS_MATERIALS,
                        data: res.report_export.translations.materials
                    });
                }

                const order_changes = res.report_export.order_changes;
                if (order_changes.length > 0) {
                    this.setState({ order_changes });
                }
            } catch (err) {
                console.log("Error in plan table export: " + err.message);
            }
        } else {
            if (!this.state.loading_canceled) {
                this.setState({
                    loading_canceled: true
                });
            }
            // inform the user
            console.log("Line uuids, initial or final reports uuids not set - cannot export plan table");
            return;
        }
    }

    getIsDisabled = () => {
        return !this.props.original_report_uuid;
    }

    getDisabledOrderExternalIds = () => {
        if (!this.props.extra_lines) return [];

        const extra_lines = this.props.extra_lines.map(el => el.line);
        const order_external_ids = [];
        for (const order_change of this.state.order_changes) {
            for (const op of order_change.op_changes) {
                if (extra_lines && extra_lines.includes(op.final_line_uuid)) {
                    order_external_ids.push(order_change.order_external_id);
                }
            }
        }

        return order_external_ids;
    }

    render() {
        const initial_filters = [];

        if (this.props.show_order_external_id) {
            initial_filters.push({
                id: "external_id",
                value: this.props.show_order_external_id
            });
        }

        if (this.props.show_order_line_title) {
            initial_filters.push({
                id: "line_title",
                value: this.props.show_order_line_title
            });
        }

        if (!initial_filters.length) {
            return null;
        }

        return <OrderChangesModal
            modal_title={translate("common.orders", "Orders")}
            line_group_uuid={this.props.line_group_uuid}
            raw_order_changes={this.state.order_changes}
            save_report={this.state.save_report}
            show_modal={!!this.props.show_order_external_id || !!this.props.show_order_line_title}
            hidden_columns={["save_checkbox"]}
            initial_filters={initial_filters}
            disabled_order_external_ids={this.getDisabledOrderExternalIds()}
            onCloseModal={this.onCloseModal}
            warnings={[]}
        />
    }
}


export default connect(
    (state: ReduxState) => {
        const report = state.gantt_chart_report;
        const parallel_report = state.gantt_chart_parallel_report;
        const properties = state.gantt_chart_properties;
        return {
            original_report_uuid: report.original_report_uuid,
            current_report_uuid: report.current_report_uuid,
            line_group_uuid: properties.line_group_uuid,
            lines_orders: state.gantt_chart_lines_orders.lines_orders,
            parameters: parallel_report.parameters,
            extra_lines: report.extra_lines
        }
    },
    (dispatch) => ({ reduxDispatch: dispatch })
)(OrderInsightsDiff);
