// @flow

// react
import * as React from "react";

type ErrorType = "error" | "warning" | "success" | "info" | "no-data";

// types
type Props = {
    msg: React.Node | string | null,
    type: ErrorType,
    onlyDiv?: boolean | null,
    is_closable?: boolean,
    custom_button?: React.Node,
    tooltip?: string,
    className?: string,
    onClose?: () => void
};

type State = {
    message: React.Node | string,
    type: ErrorType,
    is_closed: boolean
};

/**
 * Error banner in CRUD components.
 */
class ErrorComponent extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        const state: State = {
            message: props.msg || "",
            type: props.type,
            is_closed: false
        }
        this.state = state;
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.msg !== prevProps.msg) {
            // update state with new props
            const msg = this.props.msg || "";
            this.setState({
                message: msg,
                is_closed: false
            });
        }
    }

    onClickClose = () => {
        this.setState({ is_closed: true });
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    renderClose() {
        if (!this.props.is_closable) return null;
        if (this.props.custom_button) return this.props.custom_button;

        const style_close = {
            top: "0px",
            float: "right",
            cursor: "pointer",
            marginBottom: "5px"
        }

        return <div onClick={this.onClickClose} style={style_close}><i className="fas fa-times"></i></div>
    }

    render() {
        if (this.state.is_closed === true) return null;

        let typeClass: string = "alert-danger";
        let iconName: string = "fas fa-exclamation-triangle";

        if (this.state.type === "warning") {
            typeClass = "alert-warning";
            iconName = "fas fa-exclamation-circle";
        } else if (this.state.type === "success") {
            typeClass = "alert-success";
            iconName = "fas fa-check-circle";
        } else if (this.state.type === "info") {
            typeClass = "alert-info";
            iconName = "fas fa-info-circle";
        } else if (this.state.type === "no-data") {
            typeClass = "alert-no-data";
            iconName = "fas fa-dot-circle";
        }

        const class_name = `alert ${typeClass}${this.props.className ? ` ${this.props.className}` : ""}`;

        if (this.state.message !== "") {
            if (this.props.onlyDiv) {
                return (
                    <div className={class_name} style={{ wordBreak: "break-word", whiteSpace: "pre-line" }}>
                        {this.renderClose()}
                        {this.state.message}
                    </div>
                );
            } else {
                return (
                    <div className={class_name} style={{ display: 'flex' }}>
                        <div style={{ paddingRight: '10px' }}>
                            <i className={iconName}></i>
                        </div>
                        {this.state.message}
                    </div>
                );
            }
        } else {
            return null;
        }
    }
}

export default ErrorComponent;
