// @flow

import * as React from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import ReactRouterPropTypes from "react-router-prop-types";

import { getBackend as getBackend2 } from "../lib/backend/Backend2";
import { convertToNumbersOrNull } from "../lib/Util";
import ErrorComponent from "./ErrorComponent";

type State = {
    an_uuid: string,
    ds_uuid: string,
    params: any,
    // currently selected item of a dropdown menu
    selected_item: any,
    // if new analysis was submitted
    submitted: boolean,
    // error messages
    message: string,
};

type Props = {
    match: ReactRouterPropTypes.match,
    history: ReactRouterPropTypes.history,
    location: ReactRouterPropTypes.location
}

const ParameterTitles = {
    type: "Type",
    alert_above_cdf: "Alert above CDF",
    alert_below_cdf: "Alert below CDF",
    eps: "Eps parameter of quantiles",
    init_min_vals: "Minimal data points",
    threshold_low: "Low threshold",
    threshold_high: "High threshold",
    min_outage_duration: "Minimum outage duration (in ms)",
    data_delay: "Data delay (in ms)"
};

function getCaption(key: string) {
    return ParameterTitles[key] || key;
}

class AnalysisNew extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        const state: State = {
            an_uuid: "",
            ds_uuid: "",
            params: {},
            selected_item: "",
            submitted: false,
            message: ""
        };
        this.state = state;
    }

    // check all the parameters entered are valid
    validateShallow(): boolean {
        return (Object.keys(this.state.params).length > 0) &&
            (this.state.ds_uuid.length > 0);
    };

    validateDeep(): boolean {
        // check if min_outage_duration is not null
        if (this.state.params.hasOwnProperty("min_outage_duration") &&
            !this.state.params.min_outage_duration) {
            this.setState({ message: "Parameter min_outage_duration should not be null" });
            return false;
        }
        // check if data_delay is not null
        if (this.state.params.hasOwnProperty("data_delay") &&
            !this.state.params.data_delay) {
            this.setState({ message: "Parameter data_delay should not be null" });
            return false;
        }
        this.setState({ message: "" });
        return true;
    };

    handleInputChange = (event: Event) => {
        if (event.currentTarget instanceof HTMLInputElement) {
            // read new values
            const target = event.currentTarget;
            const value = target.value;
            const name = target.name;

            // update new datasource placeholder
            this.setState(state => {
                state.params[name] = value;
                return state;
            });
        }
    };

    handleSelectChange(selectedValue: Object) {
        this.setState({
            selected_item: selectedValue,
            ds_uuid: selectedValue.ds_uuid,
            params: selectedValue.params
        });
    }

    handleFormReset = () => {
        this.setState(prev_state => {
            prev_state.an_uuid = "";
            prev_state.ds_uuid = "";
            prev_state.params = {};
            prev_state.selected_item = "";
            prev_state.submitted = false;
            return prev_state;
        });
    };

    handleSubmit = async (event: Event) => {
        if (this.validateDeep()) {
            try {
                const res = await getBackend2().datasources.insertAnalysis({
                    ds_uuid: this.state.ds_uuid,
                    params: convertToNumbersOrNull(this.state.params),
                    tags: {}
                });
                this.setState({ an_uuid: res.uuid, submitted: true });
            } catch (err) {
                this.setState({ message: err.message });
            }
        }
    };

    showParametersList(items: any) {
        let params = (items === "") ? [] : Object.keys(items.params);
        return (
            <div className="form-group">
                <br />
                {this.validateShallow() && <h5>Parameters:</h5>}
                {params.map((key) => (
                    <div className="form-group row" key={key}>
                        <div className="col-md-4">
                            <label className="col-form-label">{getCaption(key)}</label>
                        </div>
                        <div className="col-md">
                            {Number.isNaN(Number(items.params[key])) &&
                                <input type="text" className="form-control"
                                    value={items.params[key]}
                                    name={key}
                                    disabled={key === "type" || key === "eps" || key === "init_min_vals"}
                                    onChange={(event) => { this.handleInputChange(event) }}
                                    placeholder={items.params[key]} />}
                            {!Number.isNaN(Number(items.params[key])) &&
                                <input type="number" className="form-control"
                                    value={items.params[key]}
                                    name={key}
                                    disabled={key === "type" || key === "eps" || key === "init_min_vals"}
                                    onChange={(event) => { this.handleInputChange(event) }}
                                    placeholder={items.params[key]} />}
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    renderMessage() {
        if (this.state.message) {
            return (
                <ErrorComponent msg={this.state.message} type="warning" />
            );
        }
    };

    render() {
        // TODO: read this from db
        let items_available = [
            {
                value: "spike",
                label: "Spike",
                ds_uuid: this.props.match.params.ds_uuid,
                params: {
                    alert_above_cdf: 0.99,
                    alert_below_cdf: 0.01,
                    eps: 0.001,
                    init_min_vals: 40,
                    type: "spike"
                }
            },
            {
                value: "thresholds",
                label: "Thresholds",
                ds_uuid: this.props.match.params.ds_uuid,
                params: {
                    threshold_low: 0.01,
                    threshold_high: 0.99,
                    type: "thresholds"
                }
            },
            {
                value: "signal_edge",
                label: "Signal edge",
                ds_uuid: this.props.match.params.ds_uuid,
                params: {
                    min_outage_duration: 10 * 60 * 1000,
                    data_delay: 1 * 60 * 1000,
                    type: "signal_edge"
                }
            },
            {
                value: "downtime",
                label: "Downtime",
                ds_uuid: this.props.match.params.ds_uuid,
                params: {
                    min_outage_duration: 5 * 60 * 1000,
                    type: "downtime"
                }
            }
        ];

        if (!this.state.submitted) {
            return (
                <div key="ds_new">
                    <section key="1">
                        <img src="../img/banner.png" alt="banner" className="img-fluid-header banner_img" />
                    </section>
                    <section id="profile_edit" className="new_data_source" key="2">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="white_box">
                                        <div className="tab-content">
                                            {this.state.message && this.renderMessage()}
                                            <h4>Add New Analysis</h4>
                                            <div className="form-group">
                                                <Select value={this.state.selected_item} options={items_available} onChange={
                                                    (event) => { this.handleSelectChange(event) }} />
                                            </div>
                                            {this.showParametersList(this.state.selected_item)}
                                            <button className="btn_save" onClick={this.handleSubmit}
                                                disabled={!this.validateShallow()}>Save</button>
                                        </div>
                                    </div>
                                    <div className="space30"></div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            );
        } else {
            return (
                <div key="ds_new">
                    <section key="1">
                        <img src="../img/banner.png" alt="banner" className="img-fluid-header banner_img" />
                    </section>
                    <section id="profile_edit" className="new_data_source" key="2">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="white_box">
                                        <div className="tab-content">
                                            {this.state.message && this.renderMessage()}
                                            <h4>Add New Analysis</h4>
                                            <div className="form-group">
                                                <Select value={this.state.selected_item} options={items_available}
                                                    onChange={(event) => { this.handleSelectChange(event) }} />
                                            </div>
                                            {this.showParametersList(this.state.selected_item)}
                                            <ErrorComponent
                                                msg={"Successfuly added new analysis: " + JSON.stringify(this.state.an_uuid)}
                                                type="success" />

                                            <br />
                                            <Link className="blue_link text-center" to={"/datasources/" +
                                                this.props.match.params.ds_uuid}>Back to datasources list &#187;
                                            </Link>
                                            <button className="btn_save btn_wide" onClick={this.handleFormReset}>
                                                Create another analysis
                                            </button>
                                        </div>
                                    </div>
                                    <div className="space30"></div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            )
        }
    }
};

export default AnalysisNew;
