// @flow
import React from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { Modal } from "react-bootstrap";
import { translate } from "../../IntlProviderWrapper";

import { classNames, dateFromWeekAndShift, niceDateTime } from "../../../lib/Util";
import * as t from "../../../lib/backend/reports.generated.types";
import * as rt from "../../../lib/SimulationReportModels";
import { UPDATE_UNSCHEDULED_ORDER } from "./reducers/linesOrders";
import { MOVE_ORDER_GANTT_ACTION_NAME } from "./reducers/common";

import ReactTable from "../../react/ReactTable";
import { getOrders } from "./Draggable/DraggableElements";
import { GanttChartSource } from "./reducers/properties";
import { SET_HIGHLIGHTED_MOVE_ORDER } from "./reducers/planningTable";
import { getBackend as getBackend2 } from "../../../lib/backend/Backend2";
import DateTimePicker from "../../DateTimePicker";
import { LINE_TAGS_ACCESS } from "../../../lib/ManufacturingTags.generated";
import { calculateReportOrderEpochs, ReduxFilters } from "./reducers/BusinessLogic";
import DraggableModal from "../../DraggableModal";
import { MoveOrderModalHelper } from "./MoveOrderModalHelper";

import type {
    ResetFiltersAction, SetLastClickedOrderUuid,
    SetHighlightedMoveOrder, SetHighlightedOrders
} from "./reducers/planningTable";
import type { Cell } from "react-table";
import type {
    IOrderProducedModelEx,
    LineOrders,
    MoveOrderGanttAction,
    UpdateUnscheduledOrder
} from "./reducers/linesOrders";
import type { ReduxState } from "./reducers/reducers";
import type { SetRescheduleOrderAction, SetRescheduleOrderBundleAction } from "./reducers/common";
import type { PropertiesState } from "./reducers/properties";

type GetProcessesRet = {
    process_num?: string,
    process_uuid?: string
}

type TableRow = {
    process_num: string,
    material_title: string,
    external_id: string,
    order_uuid: string,
    line_uuid: string,
    time_start: number | null,
    time_end: number | null
};

type Props = {
    onClose: () => void,
    shifts: t.ISimulationReportOrderLineShiftFeature[],
    valid_lines: LineOrders[],
    lines_orders: LineOrders[],
    last_clicked_order_uuid: string,
    order: IOrderProducedModelEx,
    order_index: number,
    show_modal: boolean,
    line_uuid: string,
    properties: PropertiesState,
    reduxDispatch: (
        args: SetRescheduleOrderAction | MoveOrderGanttAction |
        SetHighlightedOrders | SetHighlightedMoveOrder | ResetFiltersAction |
        UpdateUnscheduledOrder | SetRescheduleOrderAction | SetLastClickedOrderUuid |
        SetRescheduleOrderBundleAction
    ) => void,
    is_unscheduled: boolean,
    orders: rt.ISimulationReportOrderLineEx[],
    from_shift_time: t.ISimulationReportShiftTime | null,
    is_schedule: boolean,
    is_filter_locked: boolean
};
type State = {
    selected_order_uuid: string | null,
    selected_line_uuid: string | null,
    selected_time_label: string,
    selected_time: number
};

type SelectOption = {
    label: string,
    value: string
};

const MOVE_ORDER_TIME_LABEL_STORAGE_KEY = "moveordermodal-time-label";
const MOVE_ORDER_TIME_LABELS = {
    now: "now",
    specific_time: "specific_time",
    after_seleted_order: "after_seleted_order",
    same_time_as_selected_order: "same_time_as_selected_order"
};

const loadTimeLabelFromStorage = (): string => {
    const time_label = localStorage.getItem(MOVE_ORDER_TIME_LABEL_STORAGE_KEY);
    return time_label && Object.values(MOVE_ORDER_TIME_LABELS).includes(time_label)
        ? time_label
        : MOVE_ORDER_TIME_LABELS.now;
};

const storeTimeLabelToStorage = (time_label: string) => {
    if (Object.values(MOVE_ORDER_TIME_LABELS).includes(time_label)) {
        localStorage.setItem(MOVE_ORDER_TIME_LABEL_STORAGE_KEY, time_label);
        return;
    }

    localStorage.setItem(MOVE_ORDER_TIME_LABEL_STORAGE_KEY, MOVE_ORDER_TIME_LABELS.now);
};

const getShiftTs = (shift_time: t.ISimulationReportShiftTime): number => {
    return dateFromWeekAndShift(shift_time.week, shift_time.year, shift_time.shift_number).getTime();
};

const DateTimeCell = ({ value }: Cell<TableRow, number>) => niceDateTime(new Date(value)).slice(0, -3);

class MoveOrderModal extends React.Component<Props, State> {
    state = {
        selected_order_uuid: null,
        selected_line_uuid: null,
        selected_time_label: loadTimeLabelFromStorage(),
        selected_time: 0
    }

    onChangeTimeLabel = (option: SelectOption) => {
        const shift_time = this.props.from_shift_time;
        const selected_time_label = option.value;
        let selected_time = 0;
        if (shift_time !== null && selected_time_label === MOVE_ORDER_TIME_LABELS.specific_time) {
            selected_time = getShiftTs(shift_time);
        }

        const selected_order_uuid = this.hasOrderList(selected_time_label) ? this.state.selected_order_uuid : null;
        this.setState({
            selected_order_uuid,
            selected_time_label,
            selected_time
        });
        this.setHighlightedOrders(selected_order_uuid);
        storeTimeLabelToStorage(selected_time_label);
    }

    onChangeTime = (date: Date) => {
        this.setState({ selected_time: date.getTime() });
    }

    onChangeLine = (option: SelectOption) => {
        const time_label_options = this.getTimeLabelOptions(option.value);
        const selected_option = time_label_options.find(o => o.value === this.state.selected_time_label) || time_label_options[0];
        this.setState({
            selected_line_uuid: option.value,
            selected_order_uuid: null,
            selected_time_label: selected_option.value
        });
    }

    getSelectedLineOption = (order_uuid: string | null): ?SelectOption => {
        const rows = this.getLineOptions();
        return rows.find(r => r.value === this.state.selected_line_uuid);
    }

    async componentDidMount() {
        // mark we are moving the order
        await ReduxFilters.setRescheduleOrderUuid(
            this.props.reduxDispatch,
            this.props.order.uuid,
            "line_order_list",
            this.props.is_filter_locked
        );
        const selected_line_uuid = this.props.line_uuid;
        const shift_time = this.props.from_shift_time;
        let selected_time_label = loadTimeLabelFromStorage();
        const time_label_options = this.getTimeLabelOptions(selected_line_uuid);
        const selected_option = time_label_options.find(o => o.value === selected_time_label) || time_label_options[0];
        selected_time_label = selected_option.value;
        const selected_time = shift_time !== null && selected_time_label === MOVE_ORDER_TIME_LABELS.specific_time
            ? getShiftTs(shift_time)
            : 0;
        this.setState({
            selected_line_uuid,
            selected_time_label,
            selected_time
        });
    }

    componentWillUnmount() {
        this.setHighlightedOrders(null);
    }

    setHighlightedOrders = (current_order_uuid: string | null) => {
        this.props.reduxDispatch({
            type: SET_HIGHLIGHTED_MOVE_ORDER,
            data: current_order_uuid
        });
    }

    async componentDidUpdate(prev_props: Props, prev_state: State) {
        if (prev_state.selected_order_uuid !== this.state.selected_order_uuid || prev_props.show_modal !== this.props.show_modal) {
            this.setHighlightedOrders(this.props.show_modal ? this.state.selected_order_uuid : null);
        }

        if ((prev_props.line_uuid !== this.props.line_uuid && this.props.line_uuid && this.props.line_uuid !== this.state.selected_line_uuid) ||
            (prev_props.is_unscheduled !== this.props.is_unscheduled)) {
            const selected_line_uuid = this.props.line_uuid;
            let selected_time_label = this.state.selected_time_label;
            const time_label_options = this.getTimeLabelOptions(selected_line_uuid);
            const selected_option = time_label_options.find(o => o.value === selected_time_label) || time_label_options[0];
            selected_time_label = selected_option.value;
            this.setState({
                selected_line_uuid,
                selected_time_label
            });
        }

        if (prev_props.show_modal !== this.props.show_modal && this.props.show_modal) {
            await ReduxFilters.setRescheduleOrderUuid(this.props.reduxDispatch,
                this.props.order.uuid, "line_order_list", this.props.is_filter_locked);
        }
    }

    getRows = (line_uuid = null, include_all = false, add_empty = false): TableRow[] => {
        const orders: TableRow[] = [];
        const valid_lines = this.props.valid_lines;
        if (!valid_lines) {
            return orders;
        }

        const orders_map: Map<string, rt.ISimulationReportOrderEx> = new Map();
        for (const line of this.props.orders) {
            for (const order of line.production) {
                orders_map.set(order.order_id, order);
            }
        }

        for (const line of valid_lines) {
            if (line_uuid !== null && line_uuid !== line.line_uuid) {
                continue;
            }

            let prev_time_end = null;
            for (const valid_order of line.orders) {
                const order = orders_map.get(valid_order.uuid);
                if (order && (order.order_id !== this.props.order.uuid || include_all)) {
                    let time_start = null;
                    let time_end = null;
                    if (this.props.from_shift_time !== null) {
                        const epochs = calculateReportOrderEpochs(order, this.props.from_shift_time, 1);
                        time_start = epochs.initial_epoch_start;
                        time_end = epochs.initial_epoch_end;
                    }

                    const row: TableRow = {
                        process_num: `${order.process_num}`,
                        material_title: order.material_title,
                        external_id: order.order_external_id,
                        order_uuid: order.order_id,
                        line_uuid: line.line_uuid,
                        time_start,
                        time_end
                    };

                    if (add_empty && prev_time_end !== null && row.time_start !== null && prev_time_end < row.time_start) {
                        orders.push({
                            process_num: "",
                            material_title: "",
                            external_id: "",
                            order_uuid: "",
                            line_uuid: "",
                            time_start: prev_time_end,
                            time_end: row.time_start
                        });
                    }

                    orders.push(row);
                    prev_time_end = row.time_end;
                }
            }

            if (line_uuid !== null && line_uuid === line.line_uuid) {
                break;
            }
        }

        return orders;
    }

    getLineOptions = (): SelectOption[] => {
        const valid_lines = this.props.valid_lines;
        if (!valid_lines) {
            return [];
        }
        const rows_lines = new Map<string, SelectOption>();

        for (const line of valid_lines) {
            const is_droppable = ReduxFilters.isDroppableLineUuid(
                line.line_uuid,
                this.props.last_clicked_order_uuid,
                false
            );
            if (!is_droppable) {
                continue;
            }
            rows_lines.set(
                line.line_uuid,
                { label: line.line_title, value: line.line_uuid }
            );
        }
        return [...rows_lines.values()].filter(el => el.label);
    }

    getProcesses = async (): GetProcessesRet => {
        const selected_line_uuid = this.state.selected_line_uuid
        let process_uuid = undefined;
        let process_num = undefined;

        if (selected_line_uuid) {
            const res = await getBackend2().manufacturing.getProcesses({
                line_uuid: selected_line_uuid,
                material_external_id: this.props.order.material_external_id,
                process_num: this.props.order.process_num,
                only_bottleneck: false
            });

            if (res.processes.length > 0) {
                process_uuid = res.processes[0].uuid;
                process_num = res.processes[0].process_num;
            }
        }

        return { process_num, process_uuid };
    }

    getTimeLabelOptions = (line_uuid?: string): SelectOption[] => {
        const options = [
            {
                label: translate("Calendar.now", "Now"),
                value: "now"
            },
            {
                label: translate("Manufacturing.Planning.specific_time", "Specific time"),
                value: "specific_time"
            }
        ];

        if (this.props.is_unscheduled && !this.props.is_schedule) {
            return options;
        }

        const selected_line_uuid = line_uuid || this.state.selected_line_uuid;
        const rows = this.getRows(selected_line_uuid);
        if (rows.length === 0) {
            return options;
        }

        options.push({
            label: translate("Manufacturing.Planning.after_selected_order", "After selected order"),
            value: "after_seleted_order"
        });

        const line = this.props.lines_orders.find(l => l.line_uuid === selected_line_uuid);
        const capacity_factor = LINE_TAGS_ACCESS.capacity_factor(line !== undefined ? line.tags : {});
        if (capacity_factor > 1) {
            options.push({
                label: translate("Manufacturing.Planning.same_time_as_selected_order", "Same time as selected order"),
                value: "same_time_as_selected_order"
            });
        }

        return options;
    }

    onMoveOrder2 = async () => {
        let selected_line_uuid = this.state.selected_line_uuid;
        let data = null;

        if (this.state.selected_time_label === MOVE_ORDER_TIME_LABELS.specific_time) {
            data = await MoveOrderModalHelper.moveOrderToSpecificTime({
                selected_line_uuid: selected_line_uuid || this.props.line_uuid,
                new_earliest_start: this.state.selected_time,
                current_line_uuid: this.props.line_uuid,
                current_order_uuid: this.props.order.uuid,
                current_order_material_external_id: this.props.order.material_external_id,
                current_order_process_num: this.props.order.process_num,
                is_unscheduled_order: this.props.is_unscheduled
            });
        }

        if (data === null) {
            return;
        }

        this.props.reduxDispatch({ type: MOVE_ORDER_GANTT_ACTION_NAME, data });
    }

    onMoveOrder = async () => {
        if (this.state.selected_time_label === MOVE_ORDER_TIME_LABELS.specific_time && (!this.props.is_unscheduled || this.props.is_schedule)) {
            this.onMoveOrder2();
            return;
        }

        const rows = this.getRows(this.state.selected_line_uuid, true);
        const row = rows.find(r => r.order_uuid === this.state.selected_order_uuid);
        const is_247 = this.state.selected_time_label === MOVE_ORDER_TIME_LABELS.now ||
            this.state.selected_time_label === MOVE_ORDER_TIME_LABELS.after_seleted_order;

        if (row && this.hasOrderList()) {
            const line_gantt = document.querySelector(`[line_uuid='${row.line_uuid}']`);
            if (!line_gantt) {
                return;
            }

            const order_gantt = document.querySelector(`[order-uuid='${row.order_uuid}']`);
            if (!order_gantt) {
                return;
            }

            const line_index = parseInt(line_gantt.getAttribute("line_index"));
            const earliest_end = parseFloat(order_gantt.getAttribute("earliest-end"));

            const {
                process_num,
                process_uuid
            } = this.getProcesses();

            const new_order_index = getOrders(
                line_index,
                this.props.order.uuid,
                earliest_end,
                GanttChartSource.planning_table_parallel
            ).length;

            const available_lines = await getBackend2().manufacturing.getOrderLines({ uuid: this.props.order.uuid });
            const selected_line = available_lines.lines.find(line => line.uuid === row.line_uuid) || { prod_ver_ext_id: "" };

            let earliest_start = this.props.order.earliest_start;
            const is_same_time_as_selected_order = this.state.selected_time_label === MOVE_ORDER_TIME_LABELS.same_time_as_selected_order;
            if (is_same_time_as_selected_order && row.time_start !== null) {
                earliest_start = row.time_start;
            }

            const data = {
                new_line_uuid: row.line_uuid,
                new_order_index: new_order_index,
                earliest_start: is_247 ? undefined : earliest_start,
                override_earliest_start: earliest_start,
                original_line_uuid: this.props.line_uuid,
                order_uuid: this.props.order.uuid,
                is_unscheduled_order: this.props.is_unscheduled,
                process_uuid,
                process_num,
                production_version: selected_line.prod_ver_ext_id || ""
            };
            this.props.reduxDispatch({ type: MOVE_ORDER_GANTT_ACTION_NAME, data });
            this.props.onClose();
        } else {
            const selected_line_uuid = this.state.selected_line_uuid;
            if (selected_line_uuid) {
                const {
                    process_num,
                    process_uuid
                } = await this.getProcesses();

                let new_order_index = 0;
                let earliest_start = this.props.order.earliest_start;
                const is_specific_time = this.state.selected_time_label === MOVE_ORDER_TIME_LABELS.specific_time;
                if (is_specific_time && this.state.selected_time !== 0) {
                    earliest_start = this.state.selected_time;
                    let selected_duration = 0;
                    for (const { order_uuid, time_start, time_end } of rows) {
                        if (time_start === null || time_end === null) {
                            continue;
                        }

                        if (order_uuid === this.props.order.uuid) {
                            selected_duration = time_end - time_start;
                            continue;
                        }

                        if (time_end - selected_duration <= earliest_start) {
                            if (order_uuid !== "") {
                                new_order_index++;
                            }
                        } else {
                            break;
                        }
                    }
                }

                if (this.props.is_unscheduled && !this.props.is_schedule) {
                    const order: IOrderProducedModelEx = { ...this.props.order };
                    const time_start = is_247 && this.props.from_shift_time !== null ? getShiftTs(this.props.from_shift_time) : earliest_start;
                    order.earliest_start = time_start;
                    order.time_start = time_start;
                    this.props.reduxDispatch({
                        type: UPDATE_UNSCHEDULED_ORDER,
                        data: {
                            order,
                            run_quick_simulation: false
                        }
                    });
                    this.props.onClose();
                    return;
                }

                const data = {
                    new_line_uuid: selected_line_uuid,
                    new_order_index,
                    earliest_start: is_247 ? undefined : earliest_start,
                    override_earliest_start: earliest_start,
                    original_line_uuid: this.props.line_uuid,
                    order_uuid: this.props.order.uuid,
                    is_unscheduled_order: this.props.is_unscheduled,
                    process_uuid,
                    process_num,
                    production_version: undefined
                };

                this.props.reduxDispatch({ type: MOVE_ORDER_GANTT_ACTION_NAME, data });
                this.props.onClose();
            } else {
                const data = {
                    new_line_uuid: this.props.line_uuid,
                    new_order_index: this.props.order_index,
                    earliest_start: is_247 ? undefined : this.props.order.earliest_start,
                    original_line_uuid: this.props.line_uuid,
                    order_uuid: this.props.order.uuid,
                    is_unscheduled_order: this.props.is_unscheduled,
                    process_uuid: undefined,
                    process_num: undefined,
                    production_version: undefined
                };
                this.props.reduxDispatch({ type: MOVE_ORDER_GANTT_ACTION_NAME, data });
                this.props.onClose();
            }
        }
    }

    getColumns = () => {
        return [
            {
                Header: "",
                accessor: "order_uuid",
                disableFilters: false
            },
            {
                Header: "",
                accessor: "line_uuid",
                disableFilters: false
            },
            {
                Header: "",
                accessor: "external_id",
                disableFilters: false,
                filterInputPlaceholder: translate("OrderTableProduction.external_id", "Order ID"),
            },
            {
                Header: "",
                filterInputPlaceholder: translate("common.material", "Material"),
                accessor: "material_title",
                disableFilters: false
            },
            {
                Header: "",
                filterInputPlaceholder: translate("OrderTableProduction.process_num", "Process number"),
                accessor: "process_num",
                disableFilters: false
            },
            {
                Header: translate("OrderTableProduction.time_start", "Start time"),
                accessor: "time_start",
                disableFilters: true,
                Cell: DateTimeCell
            },
            {
                Header: translate("OrderTableProduction.time_end", "End time"),
                accessor: "time_end",
                disableFilters: true,
                Cell: DateTimeCell
            }
        ]
    }

    rowStyle = (row: any) => {
        if (row.values.order_uuid === "") {
            return {
                backgroundColor: "#effcf6"
            }
        }

        if (row.values.order_uuid === this.props.order.uuid) {
            return {
                backgroundColor: "#ffe3e3"
            };
        }

        if (row.values.order_uuid === this.state.selected_order_uuid) {
            if (this.state.selected_time_label === MOVE_ORDER_TIME_LABELS.after_seleted_order) {
                return {
                    backgroundColor: "rgba(33, 134, 235, 0.1)",
                    borderBottom: "4px solid rgba(33, 134, 235, 0.8)",
                    transition: "none"
                };
            }

            if (this.state.selected_time_label === MOVE_ORDER_TIME_LABELS.same_time_as_selected_order) {
                return {
                    backgroundColor: "rgba(33, 134, 235, 0.1)",
                    borderTop: "4px solid rgba(33, 134, 235, 0.8)",
                    transition: "none"
                };
            }
        }

        return {};
    }

    getCellProps = (cell: Cell<TableRow>) => {
        let style = {};
        if (cell.row.values.order_uuid === this.props.order.uuid || cell.row.values.order_uuid === "") {
            style = {
                ...style,
                cursor: "default"
            };
        }

        if (
            cell.column.id === "time_start" &&
            this.state.selected_time_label === MOVE_ORDER_TIME_LABELS.same_time_as_selected_order
        ) {
            style = {
                ...style,
                backgroundColor: "rgba(33, 134, 235, 0.1)"
            };
        } else if (
            cell.column.id === "time_end" &&
            this.state.selected_time_label === MOVE_ORDER_TIME_LABELS.after_seleted_order
        ) {
            style = {
                ...style,
                backgroundColor: "rgba(33, 134, 235, 0.1)"
            };
        }

        return { style };
    }

    onCellClick = (cell: Cell<TableRow>) => {
        const selected_order_uuid = cell.row.values.order_uuid;
        if (selected_order_uuid === "" || selected_order_uuid === this.props.order.uuid) {
            return;
        }

        if (this.state.selected_order_uuid === selected_order_uuid) {
            this.setState({ selected_order_uuid: null });
        } else {
            const el = document.querySelector(`g[name='order'][order-uuid='${selected_order_uuid}']`);
            if (el) {
                // $FlowFixMe
                el.scrollIntoView({ block: "center", inline: "center" });
            }

            let selected_line_uuid = this.state.selected_line_uuid;

            for (const line of this.props.valid_lines) {
                const has_order = line.orders.some(o => o.uuid === selected_order_uuid);
                if (has_order) {
                    selected_line_uuid = line.line_uuid;
                    break;
                }
            }

            this.setState({ selected_order_uuid, selected_line_uuid });
        }
    }

    hasOrderList = (selected_time_label?: string) => [
        MOVE_ORDER_TIME_LABELS.after_seleted_order,
        MOVE_ORDER_TIME_LABELS.same_time_as_selected_order
    ].includes(selected_time_label || this.state.selected_time_label);

    render() {
        const time_label_options = this.getTimeLabelOptions();
        const selected_time_label_option = time_label_options.find(o => o.value === this.state.selected_time_label);
        const line_options = this.getLineOptions().filter(el => el);
        const selected_line_option = this.getSelectedLineOption(this.state.selected_order_uuid);
        const rows = this.getRows(this.state.selected_line_uuid, true, true);
        const has_order_list = this.hasOrderList();
        const is_specific_time = this.state.selected_time_label === MOVE_ORDER_TIME_LABELS.specific_time;
        const is_move_unscheduled = this.props.is_unscheduled && !this.props.is_schedule;
        let title = translate("common.tooltip_planning_table_moveorder", "Move order");
        if (this.props.is_unscheduled) {
            title = this.props.is_schedule
                ? translate("common.schedule_order", "Schedule order")
                : translate("common.change_time", "Change time");
        }

        return (
            <DraggableModal>
                <Modal
                    className={classNames("move-order-modal", {
                        "modal-large": has_order_list
                    })}
                    show={this.props.show_modal}
                >
                    <Modal.Header>
                        <Modal.Title>
                            {title}
                        </Modal.Title>
                        <button type="button" className="close" onClick={this.props.onClose}>
                            <span aria-hidden="true">×</span>
                            <span className="sr-only">{translate("common.close", "Close")}</span>
                        </button>
                    </Modal.Header>
                    <Modal.Body className="pb-0_">
                        <div className="form-row align-items-end">
                            <div
                                className={classNames("col-12", {
                                    "col-md-6": has_order_list
                                })}
                            >
                                <div className={classNames("form-group", {
                                    "mb-0": is_move_unscheduled && !is_specific_time
                                })}>
                                    <label htmlFor="move-order-modal-time-label">
                                        {translate("common.time", "Time")}
                                    </label>
                                    <Select
                                        classNamePrefix="react-select"
                                        inputId="move-order-modal-time-label"
                                        value={selected_time_label_option}
                                        options={time_label_options}
                                        onChange={this.onChangeTimeLabel}
                                        autoFocus
                                    />
                                </div>
                            </div>
                            {is_specific_time && (
                                <div className="col-12">
                                    <div className={classNames("form-group", {
                                        "mb-0": is_move_unscheduled
                                    })}>
                                        <label className="sr-only" htmlFor="move-order-modal-time">
                                            {translate("Manufacturing.Planning.specific_time", "Specific time")}
                                        </label>
                                        <DateTimePicker
                                            inputId="move-order-modal-time"
                                            name="time_start"
                                            value={new Date(this.state.selected_time)}
                                            minValue={
                                                this.props.from_shift_time !== null
                                                    ? new Date(getShiftTs(this.props.from_shift_time))
                                                    : undefined
                                            }
                                            onChange={this.onChangeTime}
                                            popupPostion="fixed"
                                        />
                                    </div>
                                </div>
                            )}
                            {!is_move_unscheduled && (
                                <div
                                    className={classNames("col-12", {
                                        "col-md-6": has_order_list
                                    })}
                                >
                                    <div
                                        className={classNames("form-group", {
                                            "mb-0": !has_order_list
                                        })}
                                    >
                                        <label htmlFor="move-order-modal-line">
                                            {translate("common.line", "Line")}
                                        </label>
                                        <Select
                                            classNamePrefix="react-select"
                                            inputId="move-order-modal-line"
                                            value={selected_line_option}
                                            options={line_options}
                                            onChange={this.onChangeLine}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                        {has_order_list && (
                            <div className="table-wrapper overflow-auto" style={{ maxHeight: "500px" }}>
                                <ReactTable
                                    onCellClick={this.onCellClick}
                                    columns={this.getColumns()}
                                    data={rows}
                                    rowStyle={this.rowStyle}
                                    getCellProps={this.getCellProps}
                                    selected_order_uuid={this.state.selected_order_uuid}
                                    error_msg={translate("common.order_will_set_beginning", "Order will be set beginning of selected line")}
                                    hiddenColumns={["order_uuid", "line_uuid"]}
                                />
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-primary"
                            disabled={has_order_list && !this.state.selected_order_uuid}
                            onClick={this.onMoveOrder}
                        >
                            {translate("common.ok", "OK")}
                        </button>
                    </Modal.Footer>
                </Modal>
            </DraggableModal>
        );
    }
}

export default connect(
    (state: ReduxState) => {
        const valid_lines = state.gantt_chart_planning_table.show_valid_lines.valid_lines;
        const report_data = state.gantt_chart_report.report_data;
        const properties = state.gantt_chart_properties;
        const lines_orders = state.gantt_chart_lines_orders.lines_orders;
        const is_filter_locked = state.gantt_chart_filters.is_filter_locked;

        let shifts = [];
        if (report_data) {
            shifts = report_data.result.line_shift_features;
        }

        const orders = report_data !== null ? report_data.result.orders : [];
        const from_shift_time = report_data !== null ? report_data.result.from_shift_time : null;

        return {
            valid_lines,
            shifts,
            properties,
            lines_orders,
            orders,
            from_shift_time,
            is_filter_locked,
            last_clicked_order_uuid: state.gantt_chart_planning_table.last_clicked_order_uuid,
        }
    },
    (dispatch) => ({reduxDispatch: dispatch})
)(MoveOrderModal);
