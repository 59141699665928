// @flow

// main imports
import React, { Component } from "react";
// backend
import { ORG_TAGS as MAN_ORG_TAGS } from "../../lib/ManufacturingTagsEditor.generated";
import { ORG_TAGS } from "../../lib/CommonTagsEditor.generated";
import type { ITags } from "../../lib/Models";
import type { IGetCurrentOrganisationRes } from "../../lib/backend/common.generated.types";
import ReactRouterPropTypes from "react-router-prop-types";
import { getBackend as getBackend2 } from "../../lib/backend/Backend2";
import EntityEditTemplate from "../EntityEditTemplate";
import ReactTable from "../react/ReactTable";
import { Columns, getInitialState, mapData, onChangeUpdateTags, rowStyle, updateTagsConfig } from "../react/ReactTableTags";
import { translate } from "../IntlProviderWrapper";
import DataFields from "../react/DataFields";
import ErrorComponent from "../ErrorComponent";

// defining types
type Props = {
    match: ReactRouterPropTypes.match,
    history: ReactRouterPropTypes.history,
    location: ReactRouterPropTypes.location
};

type State = {
    org_data: IGetCurrentOrganisationRes | null,
    gui_tags: ITags,
    man_tags: ITags,
    tags: ITags,
    title: string,
    success: string | null,
    error: string | null,
    weight: number
};

/**
 * Displaying production lines list.
 */
class OrganisationEdit extends Component<Props, State> {
    /**
     * Constructor.
     *
     * @param {Props} props Props object.
     * @param {State} state State object.
     */

    state: State = {
        error: null,
        gui_tags: {},
        man_tags: {},
        org_data: null,
        tags: {},
        success: null,
        title: "",
        weight: 0
    };

    componentDidMount() {
        this.load();
    }

    load = async () => {
        try {
            this.setState({ error: null });
            const man_res = await getBackend2().manufacturing.getCurrentOrganisation({});
            const gui_res = await getBackend2().common.getCurrentOrganisation({});
            this.setState({ org_data: gui_res });
            if (man_res.tags) {
                this.setState({ man_tags: man_res.tags })
            }
            if (gui_res.tags) {
                this.setState({ gui_tags: gui_res.tags })
            }
        } catch (e) {
            this.setState({
                error: translate("common.error", "An error occured while fetching data.")
            });
        }
    }


    onSave = async () => {
        try {
            await getBackend2().manufacturing.updateCurrentOrganisation({ tags: this.state.man_tags });
            await getBackend2().common.updateCurrentOrganisation({ tags: this.state.gui_tags });
            this.setState({
                error: null,
                success: translate("common.success_save", "Data saved successfully.")
            });
        } catch (e) {
            this.setState({
                success: null,
                error: translate("common.error_save", "An error occured while saving data.")
            });
        }
    }

    renderError = () => {
        if (this.state.error) {
            return (<ErrorComponent msg={this.state.error} type="error" />);
        }
        return null;
    }

    renderSuccess = () => {
        if (this.state.success) {
            return (<ErrorComponent msg={this.state.success} type="success" />);
        }
        return null;
    }


    onGuiChangeCell = (name: string) => (value: boolean | Object | string) => {
        this.setState({ gui_tags: onChangeUpdateTags(this.state.gui_tags, name, value) });
    }

    onManChangeCell = (name: string) => (value: boolean | Object | string) => {
        this.setState({ man_tags: onChangeUpdateTags(this.state.man_tags, name, value) });
    }

    handleInputChange = (event: Event) => {
        if (event.currentTarget instanceof HTMLInputElement || event.currentTarget instanceof HTMLSelectElement) {
            // read new values
            const target = event.currentTarget;
            let value = target.value;
            const name = target.name;

            this.setState(prev_state => {
                prev_state[name] = value;
                return prev_state;
            });
        };
    };

    render() {
        const data: IGetCurrentOrganisationRes = this.state.org_data || {
            active: false,
            code: "-",
            lang: "-",
            tags: {},
            title: "-",
            uuid: "-"
        };
        const data_fields = [
            [{ label: translate("common.title", "Title"), text: data.title }],
            [{ label: translate("common.code", "Code"), text: data.code }],
            [{ label: translate("common.language", "Language"), text: data.lang }],
            [{ label: translate("common.active", "Active"), text: data.active ? "Yes" : "No" }],
            [{ label: translate("common.uuid", "-UUID"), text: data.uuid }]
        ];
        return (<div className="material-edit-container">
                <EntityEditTemplate onSave={this.onSave} title={this.state.title}>
                    {this.renderSuccess()}
                    {this.renderError()}
                    <div className="mt-3">
                        <h3>Current organisation</h3>
                        <DataFields rows={data_fields}></DataFields>
                    </div>
                    <div className="mt-5">
                        <h3>Manufacturing tags</h3>
                        <ReactTable
                            className="tags-react-table"
                            data={mapData(this.state.man_tags, updateTagsConfig(MAN_ORG_TAGS, {}))}
                            columns={Columns}
                            onChangeCell={this.onManChangeCell}
                            rowStyle={rowStyle}
                            initialState={getInitialState()}
                        />
                    </div>
                    <div className="mt-5">
                        <h3>Common tags</h3>
                        <ReactTable
                            className="tags-react-table"
                            data={mapData(this.state.gui_tags, updateTagsConfig(ORG_TAGS, {}))}
                            columns={Columns}
                            onChangeCell={this.onGuiChangeCell}
                            rowStyle={rowStyle}
                            initialState={getInitialState()}
                        />
                    </div>
                    {this.renderSuccess()}
                    {this.renderError()}
                </EntityEditTemplate>
            </div>
        )
    }
}

export default OrganisationEdit;
