// @flow
import React from "react";
import { connect } from "react-redux"
import { RETRY_LINES_ORDERS } from "./reducers/common";
import { REPORT_LOADING_ACTION_NAME } from "./reducers/report";
import { translate } from "./../../IntlProviderWrapper";

import type { RetryLinesOrdersAction } from "./reducers/common";
import type { ReportLoadingAction } from "./reducers/report";

type Props = {
    reduxDispatch: (args: RetryLinesOrdersAction | ReportLoadingAction) => void
};
type State = {};

class RetryButton extends React.Component<Props, State> {

    onRetry = () => {
        this.props.reduxDispatch({ type: REPORT_LOADING_ACTION_NAME, data: true });
        this.props.reduxDispatch({
            type: RETRY_LINES_ORDERS,
            data: undefined
        });
    }

    render() {
        return <button className="dropdown-item" onClick={this.onRetry}>
            {translate("common.retry", "Retry")}
        </button>
    }

}

export default connect(
    null,
    (dispatch) => ({ reduxDispatch: dispatch })
)(RetryButton);