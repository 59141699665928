// @flow
import * as React from "react";

//$FlowFixMe
import { Suspense } from "react";
import { connect } from "react-redux";
import ReactRouterPropTypes from "react-router-prop-types";
import { FormattedMessage } from "react-intl";
import { translate } from "../IntlProviderWrapper";

import * as Auth from "../../lib/Auth";
import { getBackend } from "../../lib/backend/Backend2";
import * as BusinessLogic from "../../lib/BusinessLogic";
import { weekNumber, getDateOfISOWeek, TIME_RANGES } from "../../lib/Util";
import Authorization from "../Authorization";

import DateTimePicker from "../DateTimePicker";
import { NavigationBarWithDropdown } from "../NavigationBar";
import DashboardStandalone from "../DashboardStandalone";

import MicroplanSummary from "./MicroplanSummary";
import { initMicroplanSummaryFilterProps } from "./MicroplanSummary";
import { MICROPLAN_EXPORT_TYPE } from './ShiftTableStandalone';

import OrdersAndShifts from "./PlanningTable/OrdersAndShifts";
import PlanningTableApp from "./PlanningTable2/PlanningTableApp";
import BatchOperations from "./BatchOperations";
import BatchOperationsArchive from "./BatchOperationsArchive";
import BatchOperationsStatus from "./BatchOperationsStatus";

import InsightsView from "./InsightsView";
import Subcontracting from "./Subcontracting";
import PredictionVsReal from "./PredictionVsReal";
import ManualEntryOrders from "./ManualEntryOrders";

import ExportToXLSXButton from "./ExportToXLSXButton";
import { GanttChartSource } from "./PlanningTable2/reducers/properties";
import { RESET_FILTERS } from "./PlanningTable2/reducers/reducersFilters/filters";

import {
    LINE_GROUP_TAGS, BATCHOP_GROUP_TAGS, PLANNING_MODE, INSIGHT_TYPES, INSIGHT_TAGS,
    LINE_GROUP_TAGS_ACCESS
} from "../../lib/ManufacturingTags.generated";

import OrdersShiftsViewTemplates from "../OrdersShifts/OrdersShiftsViewTemplates";
import OrdersShiftsSaveButton from "../OrdersShifts/OrdersShiftsSaveButton";
import OrdersShiftsRunPlanningButton from "../OrdersShifts/OrdersShiftsRunPlanningButton";
import Loader from "../Loader";
import { LINES_VIEWS_VIEW_TYPES, redirectSelectedView } from "../ViewsCommon";
import { MaterialTranslations } from "../MaterialTranslations"
import { store } from "../../index";
import WeekPicker from "../react/WeekPicker";

import type { ResetFiltersAction } from "./PlanningTable2/reducers/reducersFilters/filters";
import type { MicroplanSummaryFilterProps } from "./MicroplanSummary";
import type { ViewsDropdownOption } from "../ViewsCommon";
import type { ScrapUptimeMatrixLines } from "../manufacturing/WeeklyRealizationCommon"

const VIEW_TYPES = LINES_VIEWS_VIEW_TYPES;

// $FlowFixMe
const OrdersShifts = React.lazy(() => import("../OrdersShifts/OrdersShifts"))

type DashboardViewFilterProps = {
    title?: string,
    title_code?: string,
    dashboard: string,
    line_uuid?: string
};

type DashboardFilterProps = {
    views: DashboardViewFilterProps[],
    selected: number
};

type BatchOperationsHistoryFilterProps = {
    order_id: string,
    start_date?: Date,
    end_date?: Date,
    material_ident?: string
};

type Props = {
    history: ReactRouterPropTypes.history,
    match: ReactRouterPropTypes.match,
    location: ReactRouterPropTypes.location,
    plant_uuid: string,
    line_group_uuid: string,
    show_past_productions: boolean,
    reduxDispatch: (args: ResetFiltersAction) => void,
    translations_loading: boolean
};

type State = {
    views: ViewsDropdownOption[],
    selected_view: string,
    // filters for individual views
    microplan_filter: DashboardFilterProps,
    microplan_summary_filter: MicroplanSummaryFilterProps,
    silo_input: boolean,
    batch_archive: boolean,
    batch_status: boolean,
    batch_history_filter: BatchOperationsHistoryFilterProps,
    show_planning_table_filter: boolean,
    scrap_uptime_matrix: ScrapUptimeMatrixLines,
    material_ident_query: string
};


class LinesView extends React.Component<Props, State> {

    ExportToXLSXButtonRef = {
        current: null
    };

    constructor(props: Props) {
        super(props);
        const show_planning_table_filter = localStorage.getItem("show_planning_table_filter");
        this.state = {
            views: [],
            selected_view: props.match.params.selected_view || VIEW_TYPES.gantt.id,
            microplan_filter: { views: [], selected: 0 },
            microplan_summary_filter: initMicroplanSummaryFilterProps(),
            microplan_produced_filter: initMicroplanSummaryFilterProps(),
            silo_input: false,
            batch_archive: false,
            batch_status: false,
            batch_history_filter: { order_id: "" },
            show_planning_table_filter: show_planning_table_filter === "true",
            material_ident_query: "",
            scrap_uptime_matrix: new Map()
        }

        this.ExportToXLSXButtonRef = React.createRef();
    }

    async componentDidMount() {
        await BusinessLogic.waitForManufacturingAsync();
        await MaterialTranslations.waitForTranslationsAsync(this.props.plant_uuid, this.props.line_group_uuid);
        this.loadViews();
        await this.checkSiloInput();
    }

    async componentDidUpdate(prev_props: Props, prev_state: State) {
        const view_changed = (this.props.match.params.selected_view && this.props.match.params.selected_view !== this.state.selected_view);
        const plant_changed = prev_props.plant_uuid !== this.props.plant_uuid;
        const line_group_changed = prev_props.line_group_uuid !== this.props.line_group_uuid;
        const params_changed = (this.props.match.params.view_params && (prev_props.match.params.view_params !== this.props.match.params.view_params));
        if (plant_changed || line_group_changed || params_changed || view_changed) {
            await BusinessLogic.waitForManufacturingAsync();
            await MaterialTranslations.waitForTranslationsAsync(this.props.plant_uuid, this.props.line_group_uuid);
            this.loadViews();
            await this.checkSiloInput();
        }

    }

    async loadViews() {
        // get list of lines from the plant / line_group
        let line_group = BusinessLogic.getLineGroupForUser(this.props.line_group_uuid);
        if (line_group === null) { return; }
        const line_uuids = line_group ? line_group.line_uuids : [];
        // flag indicating we should reset url
        let reset_url = true;
        // flag indicating user is admin
        const is_admin = Auth.isInRole(Auth.ROLE_ADMIN);
        const is_power_user = Auth.isInRole(Auth.ROLE_POWER_USER);
        const is_demo_user = Auth.isInRole(Auth.ROLE_DEMO_USER);
        const is_planner = Auth.isInRole(Auth.ROLE_MAN_PLANNER);
        const is_people_first_planning = (line_group.tags[LINE_GROUP_TAGS.planning_mode] === PLANNING_MODE.people_first);

        // get dashboards from the backend
        const { dashboards } = await getBackend().dashboards.searchDashboards({
            active: true,
            tags: {
                type: "man"
            }
        });

        let gantt_dashboard: string | null = null;
        let microplan_dashboard: string | null = null;
        let layout_dashboard: string | null = null;
        let line_dashboards = [];

        // go over dashboards and identify relevant ones
        for (const dashboard of dashboards) {
            // get line group gantt and microplan
            if (dashboard.tags.line_group === this.props.line_group_uuid) {
                if (dashboard.tags.subtype === "plant-gantt") {
                    gantt_dashboard = dashboard.uuid;
                } else if (dashboard.tags.subtype === "plant-microplan-main") {
                    microplan_dashboard = dashboard.uuid;
                } else if (dashboard.tags.subtype === "plant-layout") {
                    layout_dashboard = dashboard.uuid;
                }
            }
            // as a backup show plant level gantt or microplan
            if (dashboard.tags.plant === this.props.plant_uuid) {
                if (gantt_dashboard === null && dashboard.tags.subtype === "plant-gantt") {
                    gantt_dashboard = dashboard.uuid;
                } else if (microplan_dashboard === null && dashboard.tags.subtype === "plant-microplan-main") {
                    microplan_dashboard = dashboard.uuid;
                } else if (layout_dashboard === null && dashboard.tags.subtype === "plant-layout") {
                    layout_dashboard = dashboard.uuid;
                }
            }
            // get lines dashboards
            const line_uuid = dashboard.tags.line;
            if (dashboard.tags.subtype === "line" && line_uuids.includes(line_uuid)) {
                const line_weight = BusinessLogic.getLineWeight(line_uuid);
                line_dashboards.push({ uuid: line_uuid, dashboard: dashboard.uuid, title: dashboard.title, line_weight });
            }
        }

        // sort lines by their weights
        line_dashboards.sort((a, b) => (a.line_weight - b.line_weight));

        // create views
        const views: ViewsDropdownOption[] = [];
        let default_view_id: string | null = null;
        // gantt dashboard
        if (gantt_dashboard && line_group.tags && line_group.tags[LINE_GROUP_TAGS.gannt] === "true") {
            views.push({
                id: VIEW_TYPES.gantt.id,
                type: VIEW_TYPES.gantt.id,
                title: VIEW_TYPES.gantt.title,
                title_code: VIEW_TYPES.gantt.title_code,
                dashboard: gantt_dashboard
            });
            default_view_id = VIEW_TYPES.gantt.id;
        }
        // microplan dashboards and planning views
        if (line_group.tags && line_group.tags[LINE_GROUP_TAGS.microplan] === "true") {
            if (microplan_dashboard || line_dashboards.length > 0) {
                // prepare filters
                const microplan_filter = { views: [], selected: 0 };
                // add dashboard for all lines
                if (microplan_dashboard) {
                    microplan_filter.views.push({
                        title: "All lines",
                        title_code: "Manufacturing.LinesView.all_lines",
                        dashboard: microplan_dashboard
                    });
                }
                // add dashboards for individual lines
                for (const line of line_dashboards) {
                    microplan_filter.views.push({
                        title: line.title,
                        dashboard: line.dashboard,
                        line_uuid: line.uuid
                    });
                }
                // check if any line selected in url
                if (this.props.match.params.selected_view &&
                    this.props.match.params.selected_view === VIEW_TYPES.microplan.id &&
                    this.props.match.params.view_params) {

                    const view_param = this.props.match.params.view_params;
                    for (let i = 0; i < microplan_filter.views.length; i++) {
                        if (microplan_filter.views[i].line_uuid &&
                            microplan_filter.views[i].line_uuid === view_param) {

                            microplan_filter.selected = i;
                            break;
                        }
                    }
                    reset_url = false;
                }
                // remembe the filter state
                this.setState({ microplan_filter });
                // prepare view, with the first dashboard as leading
                views.push({
                    id: VIEW_TYPES.microplan.id,
                    type: VIEW_TYPES.microplan.id,
                    title: VIEW_TYPES.microplan.title,
                    title_code: VIEW_TYPES.microplan.title_code,
                    dashboard: microplan_filter.views[0].dashboard
                });
            }
            // we show planing only when we have microplan enabled
            views.push({
                id: VIEW_TYPES.orders_and_shifts.id,
                type: VIEW_TYPES.orders_and_shifts.id,
                title: VIEW_TYPES.orders_and_shifts.title,
                title_code: VIEW_TYPES.orders_and_shifts.title_code
            });
            if ((is_admin || is_power_user || is_demo_user || is_planner) && (line_group.tags[LINE_GROUP_TAGS.planning_board] === "true")) {
                views.push({
                    id: VIEW_TYPES.planning_table.id,
                    type: VIEW_TYPES.planning_table.id,
                    title: VIEW_TYPES.planning_table.title,
                    title_code: VIEW_TYPES.planning_table.title_code,
                });
            }
            if (!is_people_first_planning) {
                views.push({
                    id: VIEW_TYPES.plan_change.id,
                    type: VIEW_TYPES.plan_change.id,
                    title: VIEW_TYPES.plan_change.title,
                    title_code: VIEW_TYPES.plan_change.title_code
                });
                views.push({
                    id: VIEW_TYPES.plan_insights.id,
                    type: VIEW_TYPES.plan_insights.id,
                    title: VIEW_TYPES.plan_insights.title,
                    title_code: VIEW_TYPES.plan_insights.title_code
                });
            }
        }
        // batch operations view
        if (line_group.tags && line_group.tags[LINE_GROUP_TAGS.batch_op] === "true") {
            views.push({
                id: VIEW_TYPES.batch_op.id,
                type: VIEW_TYPES.batch_op.id,
                title: VIEW_TYPES.batch_op.title,
                title_code: VIEW_TYPES.batch_op.title_code
            });
        }
        if (line_group.tags && line_group.tags[LINE_GROUP_TAGS.subcontracting] === "true") {
            views.push({
                id: VIEW_TYPES.subcontracting.id,
                type: VIEW_TYPES.subcontracting.id,
                title: VIEW_TYPES.subcontracting.title,
                title_code: VIEW_TYPES.subcontracting.title_code
            });
        }
        // manual order entry
        if (LINE_GROUP_TAGS_ACCESS.manual_order_entry(line_group.tags)) {
            views.push({
                id: VIEW_TYPES.manual_order_entry.id,
                type: VIEW_TYPES.manual_order_entry.id,
                title: VIEW_TYPES.manual_order_entry.title,
                title_code: VIEW_TYPES.manual_order_entry.title_code
            });
        }
        // layout dasboards
        if (layout_dashboard) {
            views.push({
                id: VIEW_TYPES.layout.id,
                type: VIEW_TYPES.layout.id,
                title: VIEW_TYPES.layout.title,
                title_code: VIEW_TYPES.layout.title_code,
                dashboard: layout_dashboard
            });
        }
        // realization view
        if (line_group.tags && line_group.tags[LINE_GROUP_TAGS.weekly_realization] === "true") {
            views.push({
                id: VIEW_TYPES.realization.id,
                type: VIEW_TYPES.realization.id,
                title: VIEW_TYPES.realization.title,
                title_code: VIEW_TYPES.realization.title_code
            });
            views.push({
                id: VIEW_TYPES.downtimes.id,
                type: VIEW_TYPES.downtimes.id,
                title: VIEW_TYPES.downtimes.title,
                title_code: VIEW_TYPES.downtimes.title_code
            });
            views.push({
                id: VIEW_TYPES.scrap.id,
                type: VIEW_TYPES.scrap.id,
                title: VIEW_TYPES.scrap.title,
                title_code: VIEW_TYPES.scrap.title_code
            });
        }

        // comparison of prediction vs realization for admins
        if ((is_admin || is_power_user || is_demo_user) && !is_people_first_planning) {
            views.push({
                id: VIEW_TYPES.prediction_vs_real.id,
                type: VIEW_TYPES.prediction_vs_real.id,
                title: VIEW_TYPES.prediction_vs_real.title,
                title_code: VIEW_TYPES.prediction_vs_real.title_code
            });
        }

        if (is_people_first_planning) {
            views.push({
                id: VIEW_TYPES.orders_shifts.id,
                type: VIEW_TYPES.orders_shifts.id,
                title: VIEW_TYPES.orders_shifts.title,
                title_code: VIEW_TYPES.orders_shifts.title_code,
            });
            default_view_id = VIEW_TYPES.orders_shifts.id;
        }

        // assing appropriate view
        let selected_view = default_view_id || ((views.length > 0 && views[0].id) ? views[0].id : "");
        const url_view = this.props.match.params.selected_view;
        if (url_view) {
            // we have view selected by the URL, check if even possible
            if (views.find(view => view.id === url_view)) {
                // all is good, mark it selected
                selected_view = url_view;
            }
        } else {
            // no view indicated in url, use default one
        }

        this.setState({ views, selected_view });
        if (reset_url) {
            this.props.history.push({
                pathname: `/manufacturing/lines/${selected_view}`,
                search: this.props.history.location.search
            });
        }
        // reset filters that need reseting
        this.setState({ batch_archive: false });
        this.setState({ batch_status: false });
    }

    handleViewChange = (event: Event) => {
        if (event.currentTarget instanceof HTMLInputElement ||
            event.currentTarget instanceof HTMLSelectElement ||
            event.currentTarget instanceof HTMLButtonElement) {
            // read new value
            let selected_view = event.currentTarget.value;

            // reset filter for some cases
            if (selected_view === VIEW_TYPES.microplan.id) {
                const microplan_filter = {
                    views: this.state.microplan_filter.views,
                    selected: 0
                };
                this.setState({ microplan_filter });
            }

            // find new id
            this.setState({ selected_view });
            redirectSelectedView(selected_view, this.props.history);
        }
    }

    /////////////////////////////////////////
    // Microplan
    handleMicroplanViewChange = (event: Event) => {
        if (event.currentTarget instanceof HTMLInputElement || event.currentTarget instanceof HTMLSelectElement) {
            // read new value
            const selected = parseInt(event.currentTarget.value, 10);
            const microplan_filter = {
                views: this.state.microplan_filter.views,
                selected
            };
            this.setState({ microplan_filter });
            if (microplan_filter.views[selected].line_uuid) {
                const selected_view = this.state.selected_view;
                const line_uuid = microplan_filter.views[selected].line_uuid;
                this.props.history.push(`/manufacturing/lines/${selected_view}/${line_uuid}`);
            }
        }
    }

    handleMicroplanPrintClick = (event: Event) => {
        const el = document.querySelector("#microplan");

        if (el instanceof HTMLElement && event.target instanceof HTMLButtonElement) {
            const e = new CustomEvent("export-html-microplan", { detail: event.target.value });
            el.dispatchEvent(e);
        }
    }

    renderMicroplanFilter() {
        const show_shift_table = BusinessLogic.getLineGroupTagBool(this.props.line_group_uuid,
            LINE_GROUP_TAGS.microplan_show_shift_table, true);
        const show_order_table = BusinessLogic.getLineGroupTagBool(this.props.line_group_uuid,
            LINE_GROUP_TAGS.microplan_show_order_table, true);

        return <li className="list-inline-item">
            <label><FormattedMessage id="Manufacturing.LinesView.view" defaultMessage="View" /></label>
            <div className="form-group">
                <select name="select_view" className="form-control select_top_control"
                    value={this.state.microplan_filter.selected} onChange={this.handleMicroplanViewChange}>
                    {
                        this.state.microplan_filter.views.map((view, i) => (view.title_code) ?
                            <option key={i} value={i}>{translate(view.title_code, view.title)}</option> :
                            <option key={i} value={i}>{view.title}</option>
                        )
                    }
                </select>
            </div>
            <div className="btn-group">
                <button
                    className="btn btn-outline-primary dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    {translate("common.print", "Print")}
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                    {show_order_table && show_shift_table && (
                        <React.Fragment>
                            <button
                                className="dropdown-item"
                                value={MICROPLAN_EXPORT_TYPE.all}
                                onClick={this.handleMicroplanPrintClick}
                            >
                                {translate("common.print_all", "Print all")}
                            </button>
                            <div className="dropdown-divider" />
                        </React.Fragment>
                    )}
                    {show_shift_table && (
                        <button
                            className="dropdown-item"
                            value={MICROPLAN_EXPORT_TYPE.shifts}
                            onClick={this.handleMicroplanPrintClick}
                        >
                            {translate("Manufacturing.LinesView.print_shifts", "Print shifts")}
                        </button>
                    )}
                    {show_order_table && (
                        <button
                            className="dropdown-item"
                            value={MICROPLAN_EXPORT_TYPE.orders}
                            onClick={this.handleMicroplanPrintClick}
                        >
                            {translate("Manufacturing.LinesView.print_work_orders", "Print work-orders")}
                        </button>
                    )}
                </div>
            </div>
        </li>;
    }

    /////////////////////////////////////////
    // Microplan Summary
    handleMicroplanSummaryBreakdownChange = (event: Event) => {
        if (event.currentTarget instanceof HTMLInputElement || event.currentTarget instanceof HTMLSelectElement) {
            // read new breakdown value
            let breakdown_level = event.currentTarget.value;
            // update filter in the state
            const microplan_summary_filter = {
                ...this.state.microplan_summary_filter,
                breakdown_level
            };
            this.setState({ microplan_summary_filter });
        }
    };

    handleProducedScrapDropdownChange = (event: Event) => {
        if (event.currentTarget instanceof HTMLInputElement || event.currentTarget instanceof HTMLSelectElement) {
            // read new breakdown value
            const filter = {
                ...this.state.microplan_summary_filter,
                produced_scrap_downtime: event.currentTarget.value
            };

            this.setState({ microplan_summary_filter: filter })
        }

    };

    handleMicroplanSummaryWeekClick(val: number) {
        // handle year flip
        let dt = getDateOfISOWeek(this.state.microplan_summary_filter.week, this.state.microplan_summary_filter.year);
        dt.setDate(dt.getDate() + val * 7);
        let week = weekNumber(dt);
        let year = dt.getFullYear();
        let month = dt.getMonth();
        // fixing problem, when week starts before first day of the year
        if ((month === 11) && (week === 1)) { year = year + 1; }
        // update state
        const microplan_summary_filter = {
            ...this.state.microplan_summary_filter,
            year,
            week
        };
        this.setState({ microplan_summary_filter });
    }

    onExportWeeklyRealization = () => {
        const event = new Event('export-weekly-realization-for-print');
        const el = document.querySelector("#weekly-realization");

        if (el instanceof HTMLElement) {
            el.dispatchEvent(event);
        }
    }

    onWeekPickerChange = (week: number, year: number) => {
        const filter = {
            ...this.state.microplan_summary_filter,
            week,
            year
        };

        this.setState({ microplan_summary_filter: filter })
    }

    renderMicroplanSummaryFilter() {
        let line_group = BusinessLogic.getLineGroupForUser(this.props.line_group_uuid);

        const show_dropdown =
            (line_group &&
                line_group.tags &&
                (line_group.tags[LINE_GROUP_TAGS.weekly_realization_downtimes_view] == "true" ||
                    line_group.tags[LINE_GROUP_TAGS.weekly_realization_scrap_view] == "true"))

        // Checks to see which views are enabled. If non of the extra views are enabled, then dropdown is hidden.
        return <li className="list-inline-item">

            {
                show_dropdown &&
                <>
                    <label><FormattedMessage id="Manufacturing.MicroplanSummary.produced_scrap_downtime" defaultMessage="Produced, scrap or downtime" /></label>
                    <div className="form-group">
                        <select name="producedscrap" className="form-control select_filter_control"
                            value={this.state.microplan_summary_filter.produced_scrap_downtime}
                            onChange={this.handleProducedScrapDropdownChange}>

                            <FormattedMessage id="common.produced" defaultMessage="Produced">
                                {text => <option key="produced" value="produced">{text}</option>}
                            </FormattedMessage>

                            {line_group && line_group.tags && line_group.tags[LINE_GROUP_TAGS.weekly_realization_scrap_view] == "true" &&
                                <FormattedMessage id="common.scrap" defaultMessage="Scrap">
                                    {text => <option key="scrap" value="scrap">{text}</option>}
                                </FormattedMessage>
                            }
                            {line_group && line_group.tags && line_group.tags[LINE_GROUP_TAGS.weekly_realization_downtimes_view] == "true" &&
                                <FormattedMessage id="common.downtime" defaultMessage="Downtime">
                                    {text => <option key="downtime" value="downtime">{text}</option>}
                                </FormattedMessage>
                            }

                        </select>
                    </div>
                </>
            }

            <label><FormattedMessage id="Manufacturing.MicroplanSummary.breakdown_report" defaultMessage="Breakdown report" /></label>
            <div className="form-group">
                <select name="breakdown_level" className="form-control select_filter_control"
                    value={this.state.microplan_summary_filter.breakdown_level}
                    onChange={this.handleMicroplanSummaryBreakdownChange}>
                    <FormattedMessage id="common.shift" defaultMessage="Shift">
                        {text => <option key="1_shift_bd" value="shift">{text}</option>}
                    </FormattedMessage>
                    <FormattedMessage id="common.day" defaultMessage="Day">
                        {text => <option key="2_day_bd" value="day">{text}</option>}
                    </FormattedMessage>
                    <FormattedMessage id="common.week" defaultMessage="Week">
                        {text => <option key="3_week_bd" value="week">{text}</option>}
                    </FormattedMessage>
                </select>
            </div>
            <label><FormattedMessage id="common.week" defaultMessage="Week" /></label>
            <div className="form-group">
                {this.state.microplan_summary_filter.week && <WeekPicker
                    onWeekPickerChange={this.onWeekPickerChange}
                    filter_week={this.state.microplan_summary_filter.week}
                    filter_year={this.state.microplan_summary_filter.year}
                />}
            </div>
            {
                this.state.microplan_summary_filter.breakdown_level === "shift" &&
                <div className="form-group">
                    <button className="btn btn-primary" style={{ float: "right" }} onClick={this.onExportWeeklyRealization} disabled={this.state.microplan_summary_filter.produced_scrap_downtime !== "produced"}>
                        <span>{translate("ShiftDetails.export_for_print", "Export for print")}</span>
                    </button>
                </div>
            }
        </li>;
    }

    /////////////////////////////////////////
    // Batch Operations
    handleBatchOperationsArchive(status: boolean) {
        this.setState({ batch_archive: status });
    }

    handleBatchOperationsStatus(status: boolean) {
        this.setState({ batch_status: status });
    }

    handleBatchOperationsOrderIdChange(e: Event) {
        if (e.currentTarget instanceof HTMLInputElement) {
            const value = e.currentTarget.value;
            let batch_history_filter = this.state.batch_history_filter;
            batch_history_filter.order_id = value;
            this.setState({ batch_history_filter });
        }
    }

    handleBatchOperationsMaterialChange(e: Event) {
        if (e.currentTarget instanceof HTMLInputElement) {
            const value = e.currentTarget.value;
            let batch_history_filter = this.state.batch_history_filter;
            batch_history_filter.material_ident = value;
            this.setState({ batch_history_filter });
        }
    }

    handleBatchOperationsMaterialQueryChange(e: Event) {
        if (e.currentTarget instanceof HTMLInputElement) {
            const value = e.currentTarget.value;
            this.setState({ material_ident_query: value });
        }
    }

    handleBatchOperationsDateChange(date: Date, name: string) {
        let batch_history_filter = this.state.batch_history_filter;

        let filtered_date = date;

        if (name === "start_time") {
            filtered_date.setUTCHours(0, 0, 0, 0);
            batch_history_filter.start_date = filtered_date;
        } else {
            filtered_date.setUTCHours(23, 59, 59, 99);
            batch_history_filter.end_date = filtered_date;
        }

        this.setState({ batch_history_filter });
    }

    async checkSiloInput() {
        const api = getBackend().batchOperations;
        const res = await api.getGroups({});
        const groups = res.items.filter(x => x.tags[BATCHOP_GROUP_TAGS.line_group] === this.props.line_group_uuid);
        let silo_input = false;
        if (groups.length > 0) {
            const tag = groups[0].tags[BATCHOP_GROUP_TAGS.silo_input];
            silo_input = (tag === "true" || tag === true); // temporarily allow binary value, remove in the future
        }
        this.setState({ silo_input });
    }

    renderBatchOperationsFilter() {
        return <li className="list-inline-item">
            <div className="form-group">
                {this.state.batch_archive ?
                    <div>
                        <div className="form-group">
                            <input type="text" className="form-control search_bar" placeholder={translate("OrderTableProduction.material_id", "Material ID")}
                                name="batchop_materialident_filter" value={this.state.material_ident_query}
                                onChange={(e) => this.handleBatchOperationsMaterialQueryChange(e)}
                                onBlur={(e) => this.handleBatchOperationsMaterialChange(e)} />
                        </div>
                        <div className="form-group">
                            <input type="text" className="form-control search_bar" placeholder={translate("Manufacturing.LinesView.search_order_id", "Search by order id") + " ..."}
                                name="batchop_orderid_filter" value={this.state.batch_history_filter.order_id} onChange={(e) => this.handleBatchOperationsOrderIdChange(e)} />
                        </div>
                        <div className="form-group">
                            <label htmlFor={`batch-operation-history-start-time`}>
                                <FormattedMessage id="common.date_range" defaultMessage="Date Range" />
                            </label>
                            <DateTimePicker
                                inputId={`batch-operation-history-start-time`}
                                className="d-inline-block"
                                inputClassName="form-control-sm"
                                name="start_time"
                                value={this.state.batch_history_filter.start_date}
                                pickerType="date"
                                popupPostion="fixed"
                                timeUnits={[]}
                                onChange={(date) => { this.handleBatchOperationsDateChange(date, "start_time") }}
                            />
                            &nbsp;-&nbsp;
                            <DateTimePicker
                                inputId={`batch-operation-history-end-time`}
                                className="d-inline-block"
                                inputClassName="form-control-sm"
                                name="start_time"
                                minValue={this.state.batch_history_filter.start_date}
                                value={this.state.batch_history_filter.end_date}
                                pickerType="date"
                                popupPostion="fixed"
                                onChange={(date) => { this.handleBatchOperationsDateChange(date, "end_time") }}
                            />
                        </div>
                        <button type="button"
                            className="btn btn-primary"
                            onClick={
                                () => {
                                    this.handleBatchOperationsArchive(false);
                                }
                            }>
                            <FormattedMessage id="common.back" defaultMessage="Back" />
                        </button>
                    </div>
                    :
                    (
                        this.state.batch_status ? (
                            <button type="button"
                                className="btn btn-primary"
                                onClick={
                                    () => {
                                        this.handleBatchOperationsStatus(false);
                                    }
                                }
                            >
                                <FormattedMessage id="common.back" defaultMessage="Back" />
                            </button>
                        ) : this.state.silo_input ? (
                            <div>
                                <button type="button" className="btn btn-primary" onClick={() => this.handleBatchOperationsStatus(true)}>
                                    <FormattedMessage id="common.current_status" defaultMessage="Current status" />
                                </button>&nbsp;
                                <button type="button" className="btn btn-primary" onClick={() => this.handleBatchOperationsArchive(true)}>
                                    <FormattedMessage id="common.history" defaultMessage="History" />
                                </button>
                            </div>
                        ) : (
                            <div>
                                <button type="button" className="btn btn-primary" onClick={() => this.handleBatchOperationsArchive(true)}>
                                    <FormattedMessage id="common.history" defaultMessage="History" />
                                </button>
                            </div>
                        )
                    )
                }
            </div>
        </li>;
    }

    /////////////////////////////////////////
    // Orders and shifts

    handleOrdersAndShiftsSave() {
        this.refs["OrdersAndShifts"].save();
    }

    handleOrdersAndShiftsPlanningSimulate() {
        BusinessLogic.fullSimulation();
    }

    renderOrdersAndShiftsFilter() {
        const is_admin = Auth.isInRole(Auth.ROLE_ADMIN);
        return <li className="list-inline-item">
            <div className="form-group">
                <Authorization.button
                    permission={Auth.PERMISSION_NAMES.OrdersAndShiftsEdit}
                    type="button" className="btn btn-primary"
                    onClick={() => this.handleOrdersAndShiftsSave()}
                >
                    <FormattedMessage id="common.save" defaultMessage="Save" />
                </Authorization.button>
            </div>
            {is_admin && <div className="form-group">
                <button type="button" className="btn btn-primary" onClick={() => this.handleOrdersAndShiftsPlanningSimulate()}>
                    <FormattedMessage id="common.simulation" defaultMessage="Simulation" />
                </button>
            </div>}
        </li>;
    }

    /////////////////////////////////////////
    // Planning table

    getGanttPastWeeks = (): number => {
        return BusinessLogic.getLineGroupTagInt(this.props.line_group_uuid, LINE_GROUP_TAGS.gantt_past_weeks, 1);
    }

    renderSimulationButton() {
        const env_flag = BusinessLogic.getSysFlag("env");
        if (env_flag !== "dev") return null;
        return <div style={{ marginLeft: "10px" }}>
            <button type="button" className="btn btn-primary" onClick={() => this.handleOrdersAndShiftsPlanningSimulate()}>
                <FormattedMessage id="common.simulation" defaultMessage="Simulation" />
            </button>
        </div>
    }


    renderOrdersShiftsFilter() {
        // compose filters
        return <li className="list-inline-item">
            <div className="form-group">
                <span className="week-picker">
                    <OrdersShiftsViewTemplates />
                </span>
            </div>
            <div className="form-group">
                <OrdersShiftsSaveButton />
                <OrdersShiftsRunPlanningButton linegroup_uuid={this.props.line_group_uuid} />
            </div>
        </li>;
    }

    /////////////////////////////////////////
    // Lines View
    renderFilter(view: ViewsDropdownOption) {
        if (view) {
            if (view.type === VIEW_TYPES.microplan.id) {
                return this.renderMicroplanFilter();
            } else if (view.type === VIEW_TYPES.realization.id) {
                return this.renderMicroplanSummaryFilter();
            } else if (view.type === VIEW_TYPES.orders_and_shifts.id) {
                return this.renderOrdersAndShiftsFilter();
            } else if (view.type === VIEW_TYPES.batch_op.id) {
                return this.renderBatchOperationsFilter();
            } else if (view.type === VIEW_TYPES.orders_shifts.id) {
                return this.renderOrdersShiftsFilter();
            } else {
                return <li className="list-inline-item"></li>;
            }
        }
        // nothing valid selected
        return <li className="list-inline-item"></li>;
    }

    renderContent(view: ViewsDropdownOption) {
        if (view) {
            if (view.type === VIEW_TYPES.microplan.id) {
                const dashboard_uuid = this.state.microplan_filter.views[this.state.microplan_filter.selected].dashboard;
                return <DashboardStandalone
                    history={this.props.history}
                    match={this.props.match}
                    uuid={dashboard_uuid}
                    asPublic={false}
                    show_filter_bar={false}
                    source_type={GanttChartSource.microplan}
                />;
            } else if (view.type === VIEW_TYPES.realization.id) {
                const exportToXLSXButtonSetLoadingState = (
                    this.ExportToXLSXButtonRef &&
                    this.ExportToXLSXButtonRef.current &&
                    this.ExportToXLSXButtonRef.current.setLoading
                );

                return (
                    <MicroplanSummary
                        plant_uuid={this.props.plant_uuid}
                        line_group_uuid={this.props.line_group_uuid}
                        filter={this.state.microplan_summary_filter}
                        exportToXLSXButtonSetLoadingState={exportToXLSXButtonSetLoadingState}
                        produced_scrap_downtime={this.state.microplan_summary_filter.produced_scrap_downtime}
                        scrap_uptime_matrix={this.state.scrap_uptime_matrix}
                    />
                )
            } else if (view.type === VIEW_TYPES.orders_and_shifts.id) {
                return <OrdersAndShifts key="orders_and_shifts" history={this.props.history} ref="OrdersAndShifts"
                    plant_uuid={this.props.plant_uuid} line_group_uuid={this.props.line_group_uuid} />;
            } else if (view.type === VIEW_TYPES.planning_table.id) {
                return <PlanningTableApp
                    key="planning_table_2"
                    plant_uuid={this.props.plant_uuid}
                    line_group_uuid={this.props.line_group_uuid}
                />;
            } else if (view.type === VIEW_TYPES.batch_op.id) {
                if (this.state.batch_archive) {
                    return <BatchOperationsArchive history={this.props.history} match={this.props.match}
                        location={this.props.location} showHeader={false} line_group_uuid={this.props.line_group_uuid}
                        filter_order_id={this.state.batch_history_filter.order_id} filter_start_date={this.state.batch_history_filter.start_date}
                        filter_end_date={this.state.batch_history_filter.end_date} filter_material_ident={this.state.batch_history_filter.material_ident} />
                } else if (this.state.batch_status) {
                    return <BatchOperationsStatus history={this.props.history} match={this.props.match}
                        location={this.props.location} showHeader={false} line_group_uuid={this.props.line_group_uuid}
                        filter_order_id={this.state.batch_history_filter.order_id} filter_start_date={this.state.batch_history_filter.start_date}
                        filter_end_date={this.state.batch_history_filter.end_date} filter_material_ident={this.state.batch_history_filter.material_ident} />
                } else {
                    return <BatchOperations history={this.props.history} match={this.props.match}
                        readOnly={false} showHeader={false} line_group_uuid={this.props.line_group_uuid} />
                }
            } else if (view.type === VIEW_TYPES.subcontracting.id) {
                return <Subcontracting plant_uuid={this.props.plant_uuid} line_group_uuid={this.props.line_group_uuid} />;
            } else if (view.type === VIEW_TYPES.manual_order_entry.id) {
                return <ManualEntryOrders plant_uuid={this.props.plant_uuid} line_group_uuid={this.props.line_group_uuid} />;
            } else if (view.type === VIEW_TYPES.plan_change.id) {
                const insight_types = [
                    INSIGHT_TYPES.plan_change_end,
                    INSIGHT_TYPES.plan_change_quantity,
                    INSIGHT_TYPES.plan_change_shifts,
                    INSIGHT_TYPES.plan_change_start
                ];
                return <InsightsView history={this.props.history} match={this.props.match}
                    plant_uuid={this.props.plant_uuid} line_group_uuid={this.props.line_group_uuid}
                    insight_types={insight_types} from_diff={-8 * TIME_RANGES.HOUR} />;
            } else if (view.type === VIEW_TYPES.plan_insights.id) {
                // default insights related to plan data
                const insight_types = [
                    INSIGHT_TYPES.freeze_order_move,
                    INSIGHT_TYPES.operation_constraint_violated
                ];
                if (BusinessLogic.getLineGroupTagBool(this.props.line_group_uuid, LINE_GROUP_TAGS.stock_forecast, true)) {
                    insight_types.push(INSIGHT_TYPES.operation_delay_downstream);
                    insight_types.push(INSIGHT_TYPES.operation_no_input_material);
                }
                if (BusinessLogic.getLineGroupTagBool(this.props.line_group_uuid, LINE_GROUP_TAGS.shift_board, true)) {
                    insight_types.push(INSIGHT_TYPES.no_work_in_shift);
                }
                return <InsightsView history={this.props.history} match={this.props.match}
                    plant_uuid={this.props.plant_uuid} line_group_uuid={this.props.line_group_uuid}
                    insight_types={insight_types} from_diff={-8 * TIME_RANGES.HOUR}
                    filter={(insight) => {
                        if (insight.type === INSIGHT_TYPES.operation_no_input_material && insight.tags[INSIGHT_TAGS.operation_no_input_material_circle] === "internal") {
                            return false;
                        }
                        return true;
                    }} />;
            } else if (view.type === VIEW_TYPES.downtimes.id) {
                const insight_types = [
                    INSIGHT_TYPES.downtime,
                    INSIGHT_TYPES.man_downtime,
                    INSIGHT_TYPES.thresholds_simple
                ];
                return <InsightsView history={this.props.history} match={this.props.match}
                    plant_uuid={this.props.plant_uuid} line_group_uuid={this.props.line_group_uuid}
                    insight_types={insight_types} from_diff={-TIME_RANGES.WEEK} reverse_order={true} />;
            } else if (view.type === VIEW_TYPES.scrap.id) {
                const insight_types = [
                    INSIGHT_TYPES.quantiles_simple
                ];
                return <InsightsView history={this.props.history} match={this.props.match}
                    plant_uuid={this.props.plant_uuid} line_group_uuid={this.props.line_group_uuid}
                    insight_types={insight_types} from_diff={-TIME_RANGES.WEEK} reverse_order={true} />;
            } else if (view.dashboard) {
                let source_type = GanttChartSource.gantt;
                if (view.type === VIEW_TYPES.microplan.id) {
                    source_type = GanttChartSource.microplan;
                }
                return <DashboardStandalone
                    history={this.props.history}
                    match={this.props.match}
                    uuid={view.dashboard}
                    asPublic={false}
                    show_filter_bar={false}
                    source_type={source_type}
                />;
            } else if (view.type === VIEW_TYPES.prediction_vs_real.id) {
                return <PredictionVsReal plant_uuid={this.props.plant_uuid} line_group_uuid={this.props.line_group_uuid} />
            } else if (view.type === VIEW_TYPES.orders_shifts.id) {
                return <Suspense fallback={<Loader />}>
                    <OrdersShifts
                        plant_uuid={this.props.plant_uuid}
                        line_group_uuid={this.props.line_group_uuid}
                    />
                </Suspense>
            } else {
                return <div></div>;
            }
        }
        // nothing valid selected
        return <div></div>;
    }

    renderEmpty() {
        const line_group = BusinessLogic.getLineGroupForUser(this.props.line_group_uuid);

        return line_group ?
            <strong>
                <FormattedMessage id="Manufacturing.LinesView.no_views" defaultMessage="Managing lines currently not supported for" /> "{line_group.title}"
            </strong> : null;
    }

    showPlanningTableFilter = () => {
        this.setState({ show_planning_table_filter: !this.state.show_planning_table_filter });
    }

    onResetFilter = () => {
        this.props.reduxDispatch({ type: RESET_FILTERS, data: undefined });
    }

    getIsLoading(): boolean {
        const translations_loading = store.getState().translations_materials.is_loading;
        // materials views
        const view = this.state.views.find((view) => (view.id === this.state.selected_view));
        if (!view) return translations_loading;
        return MaterialTranslations.getMaterialsViews().includes(view.id) && translations_loading;
    }

    getViewsContentStyle = (view?: ViewsDropdownOption) => {
        if (!view) return {};

        if (this.state.selected_view !== VIEW_TYPES.planning_table.id) return null;

        if (view.type === VIEW_TYPES.planning_table.id) {
            return {
                paddingTop: "-5px"
            }
        }

        if (view.type === VIEW_TYPES.gantt.id) {
            return {
                paddingTop: "47px"
            }
        }

        return {};
    }

    shouldHideFilter = (view?: ViewsDropdownOption) => {
        if (!view) {
            return true;
        }

        const view_types = [
            VIEW_TYPES.microplan.id,
            VIEW_TYPES.realization.id,
            VIEW_TYPES.orders_and_shifts.id,
            VIEW_TYPES.batch_op.id,
            VIEW_TYPES.orders_shifts.id
        ];

        return !view_types.includes(view.type);
    }

    renderFilterContainer = () => {
        const view = this.state.views.find((view) => (view.id === this.state.selected_view));
        const hide_filter_bar = this.shouldHideFilter(view);
        if (hide_filter_bar) {
            return null;
        }
        const className =  view && view.type === VIEW_TYPES.microplan.id ? "use-flex" : ""

        return <div className={"views-filter-bar views_always_top "  + className} key="B">
            <div className="row">
                <div className="filter-bar-row col-lg-12 col-md-12">
                    <ExportToXLSXButton
                        ref={this.ExportToXLSXButtonRef}
                        viewType={view && view.type}
                        filter={this.state.microplan_summary_filter}
                        plant_uuid={this.props.plant_uuid}
                        line_group_uuid={this.props.line_group_uuid}
                    />
                    <div id="simulation-last-refresh"></div>
                    <ul className="list-inline">
                        <li className="list-inline-item">
                            <div style={{ display: "flex" }}>
                                {!view && this.renderEmpty()}
                            </div>
                        </li>
                        {view && this.renderFilter(view)}
                    </ul>
                </div>
            </div>
        </div>
    }

    getViewId = (view?: ViewsDropdownOption): string => {
        if (view) {
            if (view.id) {
                return view.id;
            }
        }
        return "";
    }

    render() {
        const view = this.state.views.find((view) => (view.id === this.state.selected_view));
        const hide_filter = this.shouldHideFilter(view);
        const viewClasses = `views-content ${hide_filter ? "hide-filter" : ""} ${this.getViewId(view)}`;

        return [
            <NavigationBarWithDropdown
                key="A"
                nav="views"
                nav_id="lines"
                location={this.props.location}
                handleViewChange={this.handleViewChange}
                line_group_uuid={this.props.line_group_uuid}
                plant_uuid={this.props.plant_uuid}
            />,
            this.renderFilterContainer(),
            <div className={viewClasses} key="C" style={this.getViewsContentStyle(view)}>{view && this.renderContent(view)}</div>
        ];
    }
}

export default connect(
    null,
    (dispatch) => ({ reduxDispatch: dispatch })
)(LinesView);
