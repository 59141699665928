// @flow
import React from "react";
import { translate } from "../../../IntlProviderWrapper";
import LeftMenuInsightsDiff from "../LeftMenuInsightsDiff";

import type { ReportState } from "../reducers/report";

type InsightsTabHeaderProps = {
    insights_stats: InsightStats
}

export const InsightsTabHeader = (props: InsightsTabHeaderProps) => {
    const { insights_stats } = props;
    const { unsolved, solved }  = insights_stats;

    const issues_label = translate("common.issues", "Issues");
    const solved_label = translate("common.solved", "Solved");
    const unsolved_label = translate("common.unsolved", "Unsolved");
    const title = `${issues_label} (${solved_label.toLowerCase()}: ${solved}, ${unsolved_label.toLowerCase()}: ${unsolved})`;
    return (
        <span title={title}>
            <i className="fas fa-exclamation-circle" />
            <span>{title}</span>
        </span>
    );
}

type Props = {
    report: ReportState,
    updateInsightsStats: (solved: number, unsolved: number) => void
};
type State = {};

export type InsightStats = {
    solved: number,
    unsolved: number
}

export default class InsightsTabContent extends React.Component<Props, State> {

    render() {
        const report = this.props.report;
        return <div className="flex-column h-100" style={{overflow: "auto"}}>
            <LeftMenuInsightsDiff
                original_report_uuid={report.original_report_uuid}
                current_report_uuid={report.current_report_uuid}
                updateInsightsStats={this.props.updateInsightsStats}
            />
        </div>
    }
}