// @flow
import type { PayloadAction } from "../types";

export const SET_STOCK_STATUS_TABLE_FILTERS: "stock_status_table_filters" = "stock_status_table_filters";

export type StockStatusTableFiltersState = {
    material_title?: string,
    stock_location?: string,
    material_external_id?: string
}

export type CompressedStockStatusTableFilters = {
    ssf_mt: $PropertyType<StockStatusTableFiltersState, "material_title">,
    ssf_sl: $PropertyType<StockStatusTableFiltersState, "stock_location">,
    ssf_mei: $PropertyType<StockStatusTableFiltersState, "material_external_id">
}

export const ssft_initial_state: StockStatusTableFiltersState = {
    material_title: "",
    stock_location: "",
    material_external_id: ""
}

export const StockStatusTableFiltersCompressed = {
    material_title: "ssf_mt",
    stock_location: "ssf_sl",
    material_external_id: "ssf_mei"
}

export type SetStockStatusTableFiltersAction = PayloadAction<typeof SET_STOCK_STATUS_TABLE_FILTERS, StockStatusTableFiltersState>;

export const compressStockStatusTableFiltersParams = (data: StockStatusTableFiltersState): CompressedStockStatusTableFilters => {
    return {
        ssf_mt: data.material_title,
        ssf_sl: data.stock_location,
        ssf_mei: data.material_external_id
    }
}

export const diffSSFTFiltersAndURLParams = (selected_ssf: StockStatusTableFiltersState, hash: string): ?StockStatusTableFiltersState => {
    if (!hash) { hash = window.location.hash; }
    const url_params = new URLSearchParams(hash.split("#")[1]);

    const new_filters: $Shape<StockStatusTableFiltersState> = {};

    let has_differences = false;
    const param_mei = url_params.get(StockStatusTableFiltersCompressed.material_external_id);
    if (selected_ssf.material_external_id !== param_mei) {
        has_differences = true;
        new_filters.material_external_id = param_mei;
    } else if (param_mei) {
        new_filters.material_external_id = param_mei;
    }

    const param_sl = url_params.get(StockStatusTableFiltersCompressed.stock_location);
    if (selected_ssf.stock_location !== param_sl) {
        has_differences = true;
        new_filters.stock_location = param_sl;
    } else if (param_sl) {
        new_filters.stock_location = param_sl;
    }

    const param_mt = url_params.get(StockStatusTableFiltersCompressed.material_title);
    if (selected_ssf.material_title !== param_mt) {
        has_differences = true;
        new_filters.material_title = param_mt;
    } else if (param_mt) {
        new_filters.material_title = param_mt;
    }

    if (has_differences) {
        return new_filters;
    }
}


export const stockStatusTableFiltersReducer = (state: StockStatusTableFiltersState, action: SetStockStatusTableFiltersAction) => {
    if (!state) state = { ...ssft_initial_state };

    if (action.type === SET_STOCK_STATUS_TABLE_FILTERS) {
        state = {
            ...ssft_initial_state,
            ...action.data
        };
    }

    return state;
}

