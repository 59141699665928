// @flow
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { translate } from "../IntlProviderWrapper";
import ReactRouterPropTypes from "react-router-prop-types";
import { Modal } from "react-bootstrap";
import * as sf from "../../lib/StockForecast";
import MaterialStockForecastView, { initStockForecastFilterProps } from "./MaterialStockForecastView";
import SpanButton from "../react/SpanButton";

import type { StockForecastFilterProps } from "./MaterialStockForecastView";
import type {
    MaterialForecastType,
    MaterialForecastSubtype,
    MaterialForecastLocation,
    MaterialForecastOnlyAccounted
} from "../../lib/StockForecast";
import type { AggregationTimeWindow } from "./MaterialStockForecast";
import * as BusinessLogic from "../../lib/BusinessLogic";

export type DisplayType = {
    code: string;
    title: string;
}
const getStockForecastLocationTitle = (stock_forecast_filter: StockForecastFilterProps): DisplayType => {
    const empty = { code: "", title: "" };

    if (!stock_forecast_filter.locations) return empty;

    const location = stock_forecast_filter.locations.find(l => l.code === stock_forecast_filter.selected_location);

    if (location) {
        return location;
    }

    return empty;
}

type MaterialStockForecastFilterProps = {
    handleStockForecastSubtypeChange: (e: Event) => void,
    handleStockForecastOnlyAccountedForChange: (e: Event) => void,
    handleStockForecastLocationChange: (e: Event) => void,
    stock_forecast_filter: StockForecastFilterProps
}
const MaterialStockForecastFilters = (props: MaterialStockForecastFilterProps) => {
    const stock_forecast_filter = props.stock_forecast_filter;
    const location = getStockForecastLocationTitle(stock_forecast_filter);

    return <div style={{ display: "flex", float: "right", justifyContent: "flex-start"}}>
        <span className="week-picker" style={{ display: "flex", alignItems: "center", marginRight: "10px" }}>
            <input
                type="checkbox"
                id="checkbox_withdrawn"
                name="enabled"
                checked={stock_forecast_filter.only_accounted_for_initial_stock === "no"}
                onChange={props.handleStockForecastOnlyAccountedForChange}
            />
            <label for="checkbox_withdrawn" style={{ marginLeft: "5px" }}>
                {translate("common.withdrawn_and_unaccounted", "Withdrawn and unaccounted")}
            </label>
        </span>
        <span className="week-picker" style={{ display: "flex", alignItems: "center", marginRight: "10px" }}>
            <input
                type="checkbox"
                id="checkbox_location"
                name="enabled"
                checked={location.code === sf.MATERIAL_FORECAST_LOCATIONS.shopfloor}
                onChange={props.handleStockForecastLocationChange}
            />
            <label for="checkbox_location" style={{ marginLeft: "5px" }}>
                {translate("common.include_plant_stocks", "Include only shopfloor warehouses")}
            </label>
        </span>
        <select
            name="selected_subtype"
            className="form-control select_top_control"
            value={stock_forecast_filter.selected_subtype}
            style={{width: "300px"}}
            onChange={props.handleStockForecastSubtypeChange}>
            {stock_forecast_filter.subtypes &&
                stock_forecast_filter.subtypes.map(t =>
                    <option key={t.code + "_display_subtype"} value={t.code}>{t.title}</option>
                )}
        </select>
    </div>
}

type Props = {
    history: ReactRouterPropTypes.history,
    show: boolean,
    onHide: () => void,
    line_uuid: string,
    line_title: string,
    display_type: MaterialForecastType,
    display_subtype: MaterialForecastSubtype,
    only_accounted_for_initial_stock: MaterialForecastOnlyAccounted,
    display_location: MaterialForecastLocation
};

type SelectedValueLabelDefaultFilter = "order_external_id" | "line" | "selected_display_type";

type State = {
    line_group_uuid: string,
    stock_forecast_filter: StockForecastFilterProps
};

class LineMaterialsModal extends React.Component<Props, State> {

    constructor(props: Props, state: State) {
        super(props);

        const line_group = BusinessLogic.getLineGroupForLine(this.props.line_uuid);
        const line_group_uuid = line_group ? line_group.uuid : "";

        this.state = {
            line_group_uuid,
            stock_forecast_filter: {
                ...initStockForecastFilterProps(line_group_uuid),
                selected_display_type: this.props.display_type,
                only_accounted_for_initial_stock:
                    this.props.only_accounted_for_initial_stock,
                selected_location: this.props.display_location,
                lines: [],
                selected_line_uuid: "",
                title: ""
            }
        };
    }

    componentDidMount() {
        this.loadComponent();
    }

    componentDidUpdate(prev_props: Props) {
        if (
            prev_props.line_uuid !== this.props.line_uuid ||
            prev_props.display_type !== this.props.display_type
        ) {
            this.loadComponent();
        }
    }

    loadComponent() {
        const line_group = BusinessLogic.getLineGroupForLine(this.props.line_uuid);
        const line_group_uuid = line_group ? line_group.uuid : "";
        this.state.stock_forecast_filter.selected_display_type = this.props.display_type;
        this.setState({ line_group_uuid, stock_forecast_filter: { ...this.state.stock_forecast_filter } });
    }

    setDefaultFilter = (type: SelectedValueLabelDefaultFilter) => {
        return () => {
            if (type === "order_external_id") {
                this.setState({
                    stock_forecast_filter: {
                        ...this.state.stock_forecast_filter,
                        order_external_ids: []
                    }
                });
            } else if (type === "line") {
                this.setState({
                    stock_forecast_filter: {
                        ...this.state.stock_forecast_filter,
                        selected_line_uuid: ""
                    }
                });
            } else if (type === "selected_display_type") {
                this.setState({
                    stock_forecast_filter: {
                        ...this.state.stock_forecast_filter,
                        selected_display_type: sf.MATERIAL_FORECAST_TYPES.out
                    }
                });
            }
        }
    }

    onChangeOrderExternalIdForecast = (value: string) => {
        this.setState({
            stock_forecast_filter: {
                ...this.state.stock_forecast_filter,
                order_external_ids: value.split(",")
            }
        });
    }

    handleStockForecastSubtypeChange = (event: Event) => {
        if (event.currentTarget instanceof HTMLInputElement || event.currentTarget instanceof HTMLSelectElement) {
            this.setState({
                stock_forecast_filter: {
                    ...this.state.stock_forecast_filter,
                    // $FlowFixMe
                    selected_subtype: event.currentTarget.value
                }
            });
        }
    }

    renderShiftsToolbox() {
        let selected_aggregate = "shift";
        if (this.state.stock_forecast_filter) {
            selected_aggregate = this.state.stock_forecast_filter.selected_aggregate;
        }

        return <div className="shifts-toolbox"
            style={{marginTop: "10px", justifyContent: "flex-start", display: "flex"}}
        >
            <SpanButton
                title={translate("common.shift", "Shift")}
                is_active={selected_aggregate === "shift"}
                onClick={this.changeSelectedHorizonStockForecast}
                value={"shift"}
            />
            <SpanButton
                title={translate("common.day", "Day")}
                is_active={selected_aggregate === "day"}
                onClick={this.changeSelectedHorizonStockForecast}
                value={"day"}
            />
            <SpanButton
                title={translate("common.week", "Week")}
                is_active={selected_aggregate === "week"}
                onClick={this.changeSelectedHorizonStockForecast}
                value={"week"}
            />
        </div>
    }

    changeSelectedHorizonStockForecast = (value: AggregationTimeWindow) => {
        this.setState({
            stock_forecast_filter: {
                ...this.state.stock_forecast_filter,
                selected_aggregate: value
            }
        });
    }

    handleStockForecastOnlyAccountedForChange = (event: Event) => {
        if (event.currentTarget instanceof HTMLInputElement) {
            const checked = event.currentTarget.checked;
            const selected_option = checked ? "no" : "yes";

            this.setState({
                stock_forecast_filter: {
                    ...this.state.stock_forecast_filter,
                    only_accounted_for_initial_stock: selected_option
                }
            });
        }
    }

    handleStockForecastLocationChange = (event: Event) => {
        if (event.currentTarget instanceof HTMLInputElement) {
            const checked = event.currentTarget.checked;
            // read new value
            const selected_display_location = checked ? sf.MATERIAL_FORECAST_LOCATIONS.shopfloor : sf.MATERIAL_FORECAST_LOCATIONS.plant;
            // reset state
            this.setState({
                stock_forecast_filter: {
                    ...this.state.stock_forecast_filter,
                    selected_location: selected_display_location
                }
            });
        }
    }

    onTitleChange = (event: Event) => {
        if (event.currentTarget instanceof HTMLInputElement) {
            const value = event.currentTarget.value;
            if (this.state.stock_forecast_filter.title !== value) {
                this.setState({
                    stock_forecast_filter: {
                        ...this.state.stock_forecast_filter,
                        title: event.currentTarget.value
                    }
                });
            }
        }
    }

    render() {
        return (
            <Modal bsSize="large" show={this.props.show} onHide={this.props.onHide}>
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id="Manufacturing.StocksView.stock_forecast" defaultMessage="Stock forecast" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div id="line-materials-modal" className="scrolable">
                        <div style={{display: "flex", flexDirection: "column"}}>
                            <MaterialStockForecastFilters
                                stock_forecast_filter={this.state.stock_forecast_filter}
                                handleStockForecastSubtypeChange={this.handleStockForecastSubtypeChange}
                                handleStockForecastOnlyAccountedForChange={this.handleStockForecastOnlyAccountedForChange}
                                handleStockForecastLocationChange={this.handleStockForecastLocationChange}
                            />
                            {this.renderShiftsToolbox()}
                        </div>
                        <MaterialStockForecastView
                            line_group_uuid={this.state.line_group_uuid}
                            line_uuid={this.props.line_uuid}
                            filter={this.state.stock_forecast_filter}
                            setDefaultFilter={this.setDefaultFilter}
                            onChangeOrderExternalId={this.onChangeOrderExternalIdForecast}
                            onTitleChange={this.onTitleChange}
                            hide_display_type_filter={true}
                            hide_lines_filter={true}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={this.props.onHide} className="btn btn-outline-secondary">
                        <FormattedMessage id="common.back" defaultMessage="Back" />
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default LineMaterialsModal;
