// @flow
import { scaleLinear } from "d3-scale";
import * as constants from "../GanttChart/constants";
import { shiftNumber, TIME_RANGES, getDateOfISOWeekShift } from "../../../../lib/Util";
import * as BusinessLogic from "../../../../lib/BusinessLogic";
import { LINE_GROUP_TAGS } from "../../../../lib/ManufacturingTags.generated";
import * as t from "../../../../lib/backend/manufacturing2.generated.types";
import * as rt from "../../../../lib/SimulationReportModels";
import * as r from "../../../../lib/backend/reports.generated.types";

import { LinesOrdersLogic, PropertiesLogic } from "./BusinessLogic";
import {
    UPDATE_REPORT_ACTION_NAME,
    UPDATE_REPORT_ACTION_NAME2,
    SET_RESCHEDULE_ORDER_BUNDLE
} from "./common";

import type { LineOrders } from "./linesOrders";
import type { PayloadAction } from "../../../redux/types";
import type { ValidLineOrders, ValidOrder } from "./common";
import type { UpdateReport2Action, UpdateReportAction } from "./common";

export const UPDATE_PROPERTIES_ACTION_NAME: "update_properties" = "update_properties";
export const SET_MAX_LINE_LABEL_LENGTH: "max_line_label_length" = "max_line_label_length";
export const UPDATE_PLANNING_TABLE_WIDTH: "planning_table_width" = "planning_table_width";
export const RESET_PROPERTIES: "reset_properties" = "reset_properties";
export const UPDATE_PLANNING_TABLE_HEIGHT: "update_planning_table_height" = "update_planning_table_height";
export const UPDATE_STOCK_REQUIREMENTS_HEIGHT: "update_stock_requirements_height" = "update_stock_requirements_height";
export const UPDATE_PLANNING_STOCK_REQUIREMENTS_HEIGHTS: "update_planning_stock_requirements" = "update_planning_stock_requirements";
export const UPDATE_UNSCHEDULED_HEIGHT: "update_unscheduled_height" = "update_unscheduled_height";
export const SET_SHOW_PAST_PRODUCTIONS: "set_show_past_productions" = "set_show_past_productions";
export const UPDATE_SKIP_STOCK: "update_skip_stock" = "update_skip_stock";
export const SKIP_STOCK_REPORT: "skip_stock" = "skip_stock";
export const SHOW_EDIT_COMMENT_MODAL: "show_edit_comment_modal" = "show_edit_comment_modal"

type UpdatePlanningTableHeight = {
    count_non_empty_lines: number,
    num_unscheduled_lines: number
}
type UpdateStockRequirementsHeight = {
    num_stock_requirements: number
}
type UpdatePlanningStockHeights = {
    num_stock_requirements: number,
    count_non_empty_lines: number,
}
type UpdateUnscheduledHeight = {
    num_unscheduled_lines: number
}

type ShiftXPosition = {
    index: number,
    x: number
}
export type ShiftXPositions = Map<number, Map<number, ShiftXPosition>>;

export type SetMaxLineLabelLengthAction = PayloadAction<typeof SET_MAX_LINE_LABEL_LENGTH, number>;
export type UpdatePropertiesActionType = PayloadAction<typeof UPDATE_PROPERTIES_ACTION_NAME, $Shape<PropertiesState>>;
export type UpdatePlanningTableWidthAction = PayloadAction<typeof UPDATE_PLANNING_TABLE_WIDTH, typeof undefined>;
export type UpdatePlanningTableHeightAction = PayloadAction<typeof UPDATE_PLANNING_TABLE_HEIGHT, UpdatePlanningTableHeight>;
export type UpdateUnscheduledHeightAction = PayloadAction<typeof UPDATE_UNSCHEDULED_HEIGHT, UpdateUnscheduledHeight>;
export type ResetProperties = PayloadAction<typeof RESET_PROPERTIES, typeof undefined>;
export type SetShowPastProductions = PayloadAction<typeof SET_SHOW_PAST_PRODUCTIONS, boolean>;
export type SetSkipStockReport = PayloadAction<typeof SKIP_STOCK_REPORT, boolean>;
export type UpdateStockRequirementsHeightAction = PayloadAction<typeof UPDATE_STOCK_REQUIREMENTS_HEIGHT, UpdateStockRequirementsHeight>;
export type UpdatePlanningStockHeightsAction = PayloadAction<typeof UPDATE_PLANNING_STOCK_REQUIREMENTS_HEIGHTS, UpdatePlanningStockHeights>;

export type PropertiesActionsTypes = (
    UpdatePropertiesActionType | SetMaxLineLabelLengthAction |
    UpdatePlanningTableWidthAction | ResetProperties |
    SetShowPastProductions | UpdatePlanningTableHeightAction | SetSkipStockReport |
    UpdateStockRequirementsHeightAction | UpdateUnscheduledHeightAction | UpdatePlanningStockHeightsAction |
    UpdateReport2Action | UpdateReportAction
);

type XScale = any => any;

type GetShiftXPosition = {
    shift_x_positions: ShiftXPositions,
    shift_number: number,
    week: number
}
export const getShiftXPosition = (
    args: GetShiftXPosition
): number => {
    const { shift_x_positions, week, shift_number } = args;
    const temp1 = shift_x_positions.get(shift_number);
    if (temp1) {
        const x_position = temp1.get(week);
        if (x_position) {
            return (isFinite(x_position.x) && (x_position.x != undefined)) ? x_position.x : 0;
        }
        return 0;
    }
    return 0;
}

export const GanttChartSource = {
    gantt: "gantt",
    planning_table: "planning_table",
    planning_table_parallel: "planning_table_parallel",
    microplan: "microplan",
    line_planning: "line_planning",
    tool_changes: "tool_changes",
    gantt_unscheduled: "gantt_unscheduled",
    stock_requirements: "stock_requirements"
}
export type GanttChartSourceTypes = $Keys<typeof GanttChartSource>

export type PropertiesState = {
    line_group_uuid: string | null,
    top_padding: number,
    bar_height: number,
    xscale: ?XScale,
    show_labels: boolean,
    current_shift: number,
    show_past_productions: boolean | null,
    space_between_bars: number,
    week: number | null,
    year: number | null,
    current_date: number | null,
    past_weeks: number,
    width: number,
    has_insight: boolean,
    shift_width: number | null,
    bottom_padding: number,
    space_for_label: number,
    simulation_offset: number,
    start_day: number,
    bars_start: number,
    height: number,
    days: number,
    max_line_label_length: number | null,
    grid_lines_loaded: boolean,
    count_non_empty_lines: number | null,
    num_unscheduled_lines: number,
    num_stock_requirements: number,
    unscheduled_height: number,
    stock_requirements_height: number,
    loaded: boolean,
    gantt_past_weeks: number,
    no_overflow: boolean,
    show_disabled_shifts: boolean,
    show_only_tool_changes: boolean,
    source_type: $Keys<typeof GanttChartSource>,
    show_only_unscheduled: boolean,
    // performance optimization that removes stock key from the input of the report, its regulated via materialstockforecastcontainer
    skip_stock?: boolean,
    stock_requirements_enabled: boolean,
    min_day_width: number,
    num_shifts: number,
    shift_x_positions: ShiftXPositions | null,
    disable_zoom: boolean
}

export const default_configuration = () => {
    const current_date = new Date();
    const shift_number = shiftNumber(current_date);

    return {
        week: shift_number.week,
        year: shift_number.year,
        shift: shift_number.shift
    };
};

export const properties_initial_state: PropertiesState = {
    top_padding: 85,
    bar_height: 30,
    xscale: undefined,
    days: 0,
    start_day: 0,
    past_weeks: 0,
    width: 0,
    show_past_productions: null,
    gantt_past_weeks: 0,
    show_labels: true,
    has_insights: false,
    has_insight: false,
    space_between_bars: 10,
    bars_start: constants.MAX_SPACE_FOR_LABEL,
    space_for_label: constants.MAX_SPACE_FOR_LABEL,
    min_day_width: constants.MIN_DAY_WIDTH,
    current_date: null,
    shift_width: null,
    current_shift: 0,
    week: null,
    year: null,
    height: 0,
    bottom_padding: 0,
    simulation_offset: 0,
    max_line_label_length: null,
    grid_lines_loaded: false,
    count_non_empty_lines: null,
    num_unscheduled_lines: 0,
    loaded: false,
    no_overflow: false,
    show_disabled_shifts: true,
    show_only_tool_changes: false,
    source_type: GanttChartSource.gantt,
    line_group_uuid: null,
    show_only_unscheduled: false,
    unscheduled_height: 0,
    num_stock_requirements: 0,
    stock_requirements_height: 0,
    stock_requirements_enabled: false,
    shift_x_positions: null,
    num_shifts: 0,
    disable_zoom: false
};

type CursorTypes = "move" | "pointer" | "help" | "cell";

type CursorValueArgs = {
    is_rescheduled: boolean,
    is_ctrl_down: boolean,
    source_type: GanttChartSourceTypes
}
export const getCursorValue = (args: CursorValueArgs): CursorTypes => {
    const { is_rescheduled, is_ctrl_down, source_type } = args;

    if (is_ctrl_down) {
        return "help";
    }

    if (PropertiesLogic.isPlanningTable(source_type)) {
        if (is_rescheduled) {
            return "move";
        }
    } else {
        return "cell";
    }

    return "pointer"
}

export const getPlanningTableDivWidth = () => {
    const parentDiv = document.querySelector("#gantt-chart-container");
    let div_width = 0;

    if (parentDiv) {
        div_width = parentDiv.getBoundingClientRect().width;
    }

    return div_width;
}

export const zoomGanttChart = (
    new_day_width: number | null,
    source_type: GanttChartSourceTypes,
    dispatch: (args: UpdatePlanningTableWidthAction) => void
) => {
    if (new_day_width !== null) {
        localStorage.setItem("planning-table-day-width-" + source_type, new_day_width + "");
    }

    dispatch({ type: UPDATE_PLANNING_TABLE_WIDTH, data: undefined });
}

type CalculateGanttWidth = {
    min_day_width: number,
    days: number,
    day_width?: number,
    source_type: GanttChartSourceTypes
}

export const getCustomDayWidth = (source_type: GanttChartSourceTypes): number => {
    const day_width = localStorage.getItem("planning-table-day-width-" + source_type);
    if (day_width) {
        return parseInt(day_width);
    }
    return 0;
}

export const calculateGanttWidth = (params: CalculateGanttWidth): number => {
    const {
        days,
        min_day_width,
        source_type,
        day_width
    } = params;

    let div_width = getPlanningTableDivWidth();
    let day_width_ls = getCustomDayWidth(source_type);

    if (day_width) {
        return Math.max(div_width, day_width * days);
    }
    return Math.max(div_width, Math.max(min_day_width, day_width_ls) * days);
}

export const setShowPastProductions = (source_type: GanttChartSourceTypes, value: boolean) => {
    localStorage.setItem("show-history-button-" + source_type, value.toString())
}

export const getShowPastProductions = (source_type: GanttChartSourceTypes, linegroup_uuid: string): boolean => {
    let checked = false;

    if (PropertiesLogic.isPlanningTable(source_type)) {
        return false;
    }

    if (source_type === GanttChartSource.tool_changes) {
        return false;
    }

    if (source_type === GanttChartSource.microplan) {
        checked = BusinessLogic.getLineGroupTagBool(linegroup_uuid, LINE_GROUP_TAGS.microplan_gantt_show_history, false);
    } else {
        // read default for the line group
        checked = BusinessLogic.getLineGroupTagBool(linegroup_uuid, LINE_GROUP_TAGS.gantt_show_history, true);

        const gantt_past_weeks = BusinessLogic.getLineGroupTagInt(linegroup_uuid, LINE_GROUP_TAGS.gantt_past_weeks, 1);
        if (gantt_past_weeks === 0) {
            return false;
        }

        // override with local user selection if available
        if (localStorage.getItem("show-history-button-" + source_type) !== null) {
            checked = localStorage.getItem("show-history-button-" + source_type) === "true";
        }
    }

    return checked;
}


type CalculateGanttHeightArgs = {
    count_non_empty_lines: number,
    bar_height: number,
    space_between_bars: number,
    top_padding: number,
    bottom_padding: number,
    source_type: GanttChartSourceTypes
};
export const calculateGanttHeight = (args: CalculateGanttHeightArgs) => {
    const {
        count_non_empty_lines,
        bar_height,
        space_between_bars,
        top_padding,
        bottom_padding
    } = args;

    const el = (
        document.querySelector("#pane1") ||
        document.querySelector(`[id^='planning_table_parallel'] #gantt-chart-container`)
    );

    let height = 0;
    const lines_height = (count_non_empty_lines * (bar_height + space_between_bars)) + top_padding + bottom_padding;
    if (el) {
        const rect = el.getBoundingClientRect()
        height = Math.max(lines_height, rect.height);
    } else {
        height = lines_height;
    }

    return height;
}

type CalculateUnscheduledGanttHeight = {
    num_lines: number,
    bar_height: number,
    space_between_bars: number,
    top_padding: number,
    bottom_padding: number,
    source_type: GanttChartSourceTypes
}
export const calculateUnscheduledGanttHeight = (args: CalculateUnscheduledGanttHeight) => {
    const {
        num_lines,
        bar_height,
        space_between_bars
    } = args;
    const el = document.querySelector(`#pane2`);
    const lines_height = (num_lines) * (bar_height + space_between_bars);
    let rect_height = 0;
    if (el) {
        const { height } = el.getBoundingClientRect();
        rect_height = height;
    }

    return Math.max(lines_height, rect_height);
}

export const getStockRequirementStartDate = (date_required: number, start_date_ts: number): number => {
    const date = new Date(date_required);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    const start_date = (date.getTime() - start_date_ts) / TIME_RANGES.HOUR;
    if (start_date < 0) {
        return 0;
    }
    return start_date;
}

type CalculateStockRequirementsGanttHeight = {
    num_stock_requirements: number,
    bar_height: number,
    space_between_bars: number,
    top_padding: number,
    bottom_padding: number,
    source_type: GanttChartSourceTypes
}
export const calculateStockRequirementsGanttHeight = (args: CalculateStockRequirementsGanttHeight) => {
    const {
        num_stock_requirements,
        bar_height,
        space_between_bars
    } = args;
    const el = document.querySelector(`#pane0`);

    const lines_height = (num_stock_requirements + 1) * ((bar_height * 0.5) + (space_between_bars / 2));
    let rect_height = 0;
    if (el) {
        const { height } = el.getBoundingClientRect();
        rect_height = height;
    }

    return Math.max(lines_height, rect_height);
}

export const numStockRequirementsAfterResolution = (stock_requirements: t.IStockRequirementsShifts[], properties: PropertiesState): number => {
    const current_shift = properties.current_shift;
    const week = properties.week;
    const year = properties.year;
    if (!week || !year) {
        return 0;
    }

    const start_date_ts = getDateOfISOWeekShift(current_shift, week, year);

    const resolution_map = new Map < number, number> ();
    let max_num_requirements = 0;

    for (const sr of stock_requirements) {
        const start_date = getStockRequirementStartDate(
            sr.shift_start_time,
            start_date_ts
        );

        let line_index = 0;
        if (resolution_map.has(start_date)) {
            line_index = resolution_map.get(start_date) + 1;
        }

        resolution_map.set(start_date, line_index);
        if (line_index > max_num_requirements) {
            max_num_requirements = line_index;
        }
    }

    return max_num_requirements;
}

type CountNumberOfLinesOnGantt = {
    valid_lines_map: Map<string, ValidLineOrders>,
    line_group_uuid: string,
    report_data: rt.IReportModelEx | null,
    source_type: GanttChartSourceTypes
}
export const countLinesOnGantt = (args: CountNumberOfLinesOnGantt): number => {
    const {
        valid_lines_map,
        line_group_uuid,
        report_data,
        source_type
    } = args;

    if (!report_data) { return 0; }

    if (PropertiesLogic.isPlanningTable(source_type)) {
        // we are on planning board, go over all lines in the report and count the ones that are valid
        // if line has multiple sublines, this will capture, since line id repeats in report data
        let num_lines = report_data.result.orders.filter(l => valid_lines_map.has(l.line)).length;
        // we need to account for extra lines needed to display line group title
        // when the line is from line-group other then current one
        const additional_line_groups = new Set();
        for (const valid_line of valid_lines_map.values()) {
            const line_group = LinesOrdersLogic.getLineGroupForLine(valid_line.line_uuid, line_group_uuid);
            if (line_group && line_group_uuid !== line_group.uuid) {
                additional_line_groups.add(line_group);
            }
        }
        return num_lines + additional_line_groups.size;
    }

    // we are on static gantt chart, we show everything from report
    return report_data.result.orders.length;
}

type CountUnscheduledNumberOfLinesOnGantt = {
    valid_orders_map: Map<string, ValidOrder>,
    unscheduled_orders: LineOrders[],
    source_type: GanttChartSourceTypes
}
export const countUnscheduledLinesOnGantt = (args: CountUnscheduledNumberOfLinesOnGantt): number => {
    const {
        valid_orders_map,
        unscheduled_orders,
        source_type
    } = args;

    if (!PropertiesLogic.isPlanningTable(source_type)) {
        return 0;
    }

    if (valid_orders_map == null) {
        return 0;
    }

    let num_lines = 0;
    for (const unscheduled_line of unscheduled_orders) {
        for (const unscheduled_order of unscheduled_line.orders) {
            if (valid_orders_map.has(unscheduled_order.uuid)) {
                num_lines++;
            }
        }
    }

    return num_lines;
}

type DaysState = {
    min_day_width: number,
    days: number,
    width: number,
    xscale: any,
    shift_width: number | null,
    shift_x_positions: ShiftXPositions | null
}
const setDays = (new_state: PropertiesState, read_width_local_storage: boolean = true): DaysState => {
    const line_group_uuid = new_state.line_group_uuid;
    if (!line_group_uuid) {
        return {
            min_day_width: new_state.min_day_width,
            days: new_state.days,
            width: new_state.width,
            xscale: new_state.xscale,
            shift_width: new_state.shift_width,
            shift_x_positions: new_state.shift_x_positions
        }
    }

    let min_day_width = BusinessLogic.getLineGroupTagInt(
        line_group_uuid,
        LINE_GROUP_TAGS.gantt_min_day_width,
        constants.MIN_DAY_WIDTH
    );

    const days = Math.floor(new_state.num_shifts / constants.SHIFTS_PER_DAY);

    const width = calculateGanttWidth({
        days,
        min_day_width: min_day_width,
        source_type: new_state.source_type
    });
    let range_to = width !== null ? width - new_state.space_for_label : 0;

    const xscale = scaleLinear()
        .domain([0, days * constants.SHIFTS_PER_DAY * constants.SHIFT_H])
        .range([0, range_to]);
    
    const shift_x_positions = updateAllShiftXPositions(
        new_state.shift_x_positions,
        xscale,
        new_state.bars_start
    );
    const shift_width = xscale(constants.SHIFT_H);

    return {
        min_day_width,
        days,
        width,
        xscale,
        shift_width,
        shift_x_positions
    };
}

export const getShiftWidth = (xscale: any) => {
    if (xscale) {
        const w_shift = xscale(constants.SHIFT_H);
        if (Number.isNaN(w_shift)) {
            return 0;
        }
        return w_shift;
    }
    return constants.MIN_DAY_WIDTH / 3;
}

const updateAllShiftXPositions = (shift_x_positions: ShiftXPositions | null, xscale: any, bars_start: number): ShiftXPositions | null => {
    const shift_width = getShiftWidth(xscale);
    if (!shift_x_positions) {
        return null;
    }

    for (const [, weeks] of shift_x_positions.entries()) {
        for (const [, entry] of weeks.entries()) {
            entry.x = (entry.index * shift_width);
        }
    }
    return shift_x_positions;
}

const setShiftXPositions = (
    shifts: r.ISimulationReportOrderLineShiftFeatureItem[],
    xscale: any,
    bars_start: number
): ShiftXPositions => {
    const shift_width = getShiftWidth(xscale);
    let shift_x_positions = new Map();
    for (let i = 0; i < shifts.length; i++) {
        const shift_time = shifts[i].shift_time;
        const { shift_number, week } = shift_time;
        let week_map = shift_x_positions.get(shift_number);
        if (!week_map) {
            week_map = new Map();
        }
        week_map.set(week, {
            index: i,
            x: (shift_width * i)
        });
        shift_x_positions.set(shift_number, week_map)
    }
    return shift_x_positions;
}

export const propertiesReducer = (
    state: PropertiesState,
    action: PropertiesActionsTypes
): PropertiesState => {
    if (!state) {
        state = { ...properties_initial_state };
    }

    let new_state = state;
    if (action.type === UPDATE_PROPERTIES_ACTION_NAME) {
        const {
            count_non_empty_lines,
            start_day,
            bar_height,
            space_between_bars,
            top_padding,
            bottom_padding,
            gantt_past_weeks,
            current_shift,
            past_weeks,
            show_disabled_shifts,
            show_only_tool_changes,
            source_type,
            line_group_uuid,
            show_only_unscheduled,
            num_stock_requirements,
            stock_requirements_enabled,
            num_shifts,
            disable_zoom
        } = action.data;

        if (!line_group_uuid) throw Error("Line group uuid not set in reducers/properties");

        new_state.line_group_uuid = line_group_uuid;
        new_state.num_shifts = num_shifts;

        const config = default_configuration();

        if (source_type !== undefined) {
            new_state.source_type = source_type;
        }
        if (!PropertiesLogic.hasBarsStart(new_state.source_type)) {
            new_state.space_for_label = 0;
            new_state.bars_start = 0;
        } else if (PropertiesLogic.isPlanningTable(new_state.source_type)) {
            new_state.space_for_label = constants.MAX_SPACE_FOR_LABEL;
            new_state.bars_start = constants.MAX_SPACE_FOR_LABEL;
            new_state.show_past_productions = false;
        }

        // set days and related state props only when days is not set
        if (line_group_uuid) {
            const {
                shift_width,
                min_day_width,
                xscale,
                days,
                width,
                shift_x_positions
            } = setDays(new_state, true);
            // should not change often
            new_state.shift_width = shift_width;
            new_state.min_day_width = min_day_width;
            new_state.shift_x_positions = shift_x_positions;
            new_state.xscale = xscale;
            new_state.days = days;
            new_state.width = width;
        }
        if (current_shift !== undefined) {
            new_state.current_shift = current_shift;
        } else {
            new_state.current_shift = config.shift;
        }
        if (past_weeks !== undefined) {
            new_state.past_weeks = past_weeks;
        }
        if (count_non_empty_lines !== undefined) {
            new_state.count_non_empty_lines = count_non_empty_lines;
        }
        if (bar_height !== undefined) {
            new_state.bar_height = bar_height;
        }
        if (space_between_bars !== undefined) {
            new_state.space_between_bars = space_between_bars;
        }
        if (top_padding !== undefined) {
            new_state.top_padding = top_padding;
        }
        if (bottom_padding !== undefined) {
            new_state.bottom_padding = bottom_padding;
        }
        if (start_day !== undefined) {
            new_state.start_day = start_day;
        }
        if (num_stock_requirements !== undefined) {
            new_state.num_stock_requirements = num_stock_requirements;
        }
        if (stock_requirements_enabled !== undefined) {
            new_state.stock_requirements_enabled = stock_requirements_enabled;
        }
        if (new_state.count_non_empty_lines && new_state.bar_height && new_state.space_between_bars) {
            new_state.height = calculateGanttHeight(
                {
                    count_non_empty_lines: new_state.count_non_empty_lines,
                    bar_height: new_state.bar_height,
                    space_between_bars: new_state.space_between_bars,
                    top_padding: new_state.top_padding,
                    bottom_padding: new_state.bottom_padding,
                    source_type: new_state.source_type
                }
            );
        }
        if (new_state.num_stock_requirements && new_state.bar_height && new_state.space_between_bars) {
            new_state.stock_requirements_height = calculateStockRequirementsGanttHeight(
                {
                    num_stock_requirements: new_state.num_stock_requirements,
                    bar_height: new_state.bar_height,
                    space_between_bars: new_state.space_between_bars,
                    top_padding: new_state.top_padding,
                    bottom_padding: new_state.bottom_padding,
                    source_type: new_state.source_type
                }
            );
        }
        if (new_state.week === null) {
            new_state.week = action.data.week || config.week;
        }
        if (new_state.year === null) {
            new_state.year = action.data.year || config.year;
        }
        if (gantt_past_weeks !== undefined) {
            new_state.gantt_past_weeks = gantt_past_weeks;
        }
        if (show_disabled_shifts !== undefined) {
            new_state.show_disabled_shifts = show_disabled_shifts;
        }
        if (show_only_tool_changes !== undefined) {
            new_state.show_only_tool_changes = show_only_tool_changes;
        }
        if (show_only_unscheduled !== undefined) {
            new_state.show_only_unscheduled = show_only_unscheduled;
        }
        if (disable_zoom !== undefined) {
            new_state.disable_zoom = disable_zoom;
        }
        // only after the properties are loaded we draw the gantt chart
        new_state.loaded = true;
        return { ...new_state };
    } else if (action.type === SET_MAX_LINE_LABEL_LENGTH) {
        if (new_state.max_line_label_length === null || new_state.max_line_label_length < action.data) {
            const max_length = action.data + 20; // 20 = padding

            new_state.max_line_label_length = max_length;
            return { ...new_state };
        }
    } else if (action.type === UPDATE_PLANNING_TABLE_WIDTH) {
        const {
            shift_width,
            xscale,
            width,
            min_day_width,
            shift_x_positions
        } = setDays(new_state, false);
        new_state.shift_width = shift_width;
        new_state.shift_x_positions = shift_x_positions;
        new_state.xscale = xscale;
        new_state.width = width;
        new_state.min_day_width = min_day_width;
        return { ...new_state };
    } else if (action.type === RESET_PROPERTIES) {
        if (new_state.line_group_uuid) {
            new_state = { ...properties_initial_state };
        }
    } else if (action.type === UPDATE_PLANNING_TABLE_HEIGHT) {
        const { count_non_empty_lines, num_unscheduled_lines } = action.data;
        if (count_non_empty_lines && new_state.bar_height && new_state.space_between_bars) {
            new_state.height = calculateGanttHeight({
                count_non_empty_lines: count_non_empty_lines,
                bar_height: new_state.bar_height,
                space_between_bars: new_state.space_between_bars,
                top_padding: new_state.top_padding,
                bottom_padding: new_state.bottom_padding,
                source_type: new_state.source_type
            });
            new_state.unscheduled_height = calculateUnscheduledGanttHeight({
                num_lines: num_unscheduled_lines,
                bar_height: new_state.bar_height,
                space_between_bars: new_state.space_between_bars,
                top_padding: new_state.top_padding,
                bottom_padding: new_state.bottom_padding,
                source_type: new_state.source_type
            });
            new_state.count_non_empty_lines = count_non_empty_lines;
            new_state.num_unscheduled_lines = num_unscheduled_lines;
        }
        return { ...new_state };
    } else if (action.type === UPDATE_PLANNING_STOCK_REQUIREMENTS_HEIGHTS) {
        const { num_stock_requirements, count_non_empty_lines } = action.data;
        if (num_stock_requirements && new_state.bar_height && new_state.space_between_bars) {
            new_state.height = calculateGanttHeight({
                count_non_empty_lines: count_non_empty_lines,
                bar_height: new_state.bar_height,
                space_between_bars: new_state.space_between_bars,
                top_padding: new_state.top_padding,
                bottom_padding: new_state.bottom_padding,
                source_type: new_state.source_type
            });
            new_state.stock_requirements_height = calculateStockRequirementsGanttHeight({
                num_stock_requirements: num_stock_requirements,
                bar_height: new_state.bar_height,
                space_between_bars: new_state.space_between_bars,
                top_padding: new_state.top_padding,
                bottom_padding: new_state.bottom_padding,
                source_type: new_state.source_type
            });
            new_state.count_non_empty_lines = count_non_empty_lines;
            new_state.num_stock_requirements = num_stock_requirements;
        }
        return { ...new_state };
    } else if (action.type === UPDATE_UNSCHEDULED_HEIGHT) {
        const { num_unscheduled_lines } = action.data;
        if (new_state.bar_height && new_state.space_between_bars) {
            new_state.unscheduled_height = calculateUnscheduledGanttHeight({
                num_lines: num_unscheduled_lines,
                bar_height: new_state.bar_height,
                space_between_bars: new_state.space_between_bars,
                top_padding: new_state.top_padding,
                bottom_padding: new_state.bottom_padding,
                source_type: new_state.source_type
            });
            new_state.num_unscheduled_lines = num_unscheduled_lines;
        }
        return { ...new_state };
    } else if (action.type === UPDATE_STOCK_REQUIREMENTS_HEIGHT) {
        const { num_stock_requirements } = action.data;
        if (num_stock_requirements && new_state.bar_height && new_state.space_between_bars) {
            new_state.stock_requirements_height = calculateStockRequirementsGanttHeight({
                num_stock_requirements: num_stock_requirements,
                bar_height: new_state.bar_height,
                space_between_bars: new_state.space_between_bars,
                top_padding: new_state.top_padding,
                bottom_padding: new_state.bottom_padding,
                source_type: new_state.source_type
            });
            new_state.num_stock_requirements = num_stock_requirements;
        }
        return { ...new_state };
    } else if (action.type === SET_SHOW_PAST_PRODUCTIONS) {
        // only set on toggle button
        new_state.show_past_productions = action.data;
        return { ...new_state }
    } else if (action.type === SKIP_STOCK_REPORT) {
        if (new_state.skip_stock !== action.data) {
            new_state.skip_stock = action.data;
            return { ...new_state };
        }
    } else if (action.type === UPDATE_REPORT_ACTION_NAME2) {
        const shifts = action.data.quick_report.result.line_shift_features[0].shifts;
        new_state.shift_x_positions = setShiftXPositions(shifts, new_state.xscale, new_state.bars_start);
        return {...new_state };
    } else if (action.type === UPDATE_REPORT_ACTION_NAME) {
        const shifts = action.data.result.line_shift_features[0].shifts;
        new_state.shift_x_positions = setShiftXPositions(shifts, new_state.xscale, new_state.bars_start);
        return {...new_state};
    } else if (action.type === SET_RESCHEDULE_ORDER_BUNDLE) {
        const has_changed = (
            action.data.count_non_empty_lines != null &&
            action.data.num_unscheduled_lines != null
        );
        if (!action.data.is_filter_locked && has_changed) {
            let changed = false;
            if (new_state.count_non_empty_lines !== action.data.count_non_empty_lines) {
                new_state.count_non_empty_lines = action.data.count_non_empty_lines;
                changed = true;
            }
            if (new_state.num_unscheduled_lines !== action.data.num_unscheduled_lines) {
                new_state.num_unscheduled_lines = action.data.num_unscheduled_lines;
                changed = true;
            }
            if (changed) {
                return {...new_state};
            }
        }
    }

    return new_state;
}

export type RenderOrderState = {
    grid_lines_loaded: number
}

const render_order_initial_state: RenderOrderState = {
    grid_lines_loaded: 0
}

export const RENDER_ORDER_SET_GRID_LINES_LOADED: "grid_line_loaded" = "grid_line_loaded";

export type GridLinesLoadedAction = PayloadAction<typeof RENDER_ORDER_SET_GRID_LINES_LOADED, typeof undefined>;

export type RenderOrderActions = GridLinesLoadedAction;


export const renderOrderReducer = (state: RenderOrderState = render_order_initial_state, action: RenderOrderActions) => {
    let new_state = state;

    if (action.type === RENDER_ORDER_SET_GRID_LINES_LOADED) {
        new_state.grid_lines_loaded++;
        new_state = { ...new_state };
    }

    return new_state;
}
