// @flow

import * as React from "react";
import { Modal } from "react-bootstrap";

import { getBackend } from "../../lib/backend/Backend2";
import { translate } from "../IntlProviderWrapper";
import ErrorComponent from "../ErrorComponent";

import type { IOrderModelBase } from "../../lib/backend/manufacturing2.generated.types";

type Props = {
    order: IOrderModelBase | null,
    show: boolean,
    onAccept: Function,
    onCancel: Function
};

type State = {
    uuid: string,
    external_id: string,
    is_loading: boolean,
    error: string
};

class ManualEntryOrderDeleteModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            uuid: "",
            external_id: "",
            is_loading: false,
            error: ""
        };
    }

    componentDidUpdate(prev_props: Props) {
        const { order } = this.props;
        if (order !== null && (prev_props.order === null || prev_props.order.uuid !== order.uuid)) {
            this.setState({
                uuid: order.uuid,
                external_id: order.external_id
            });
        }
    }

    handleAccept = async () => {
        if (!this.state.uuid) {
            this.props.onAccept();
            return;
        }

        this.setState({
            error: "",
            is_loading: true
        });

        try {
            await getBackend().manufacturing.deleteManualEntryOrder({
                uuid: this.state.uuid
            });
            this.props.onAccept();
        } catch (error) {
            this.setState({
                error: error.message
            });
        }

        this.setState({
            is_loading: false
        });
    };

    handleExit = () => {
        this.setState({
            uuid: "",
            external_id: "",
            is_loading: false,
            error: ""
        });
    };

    render() {
        const { show, onCancel } = this.props;

        return (
            <Modal show={show}>
                <Modal.Header>
                    <Modal.Title>{translate("common.delete", "Delete")}</Modal.Title>
                    <button type="button" className="close" onClick={onCancel}>
                        <span aria-hidden="true">×</span>
                        <span className="sr-only">{translate("common.close", "Close")}</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    {translate("common.are_you_sure_delete_order", "Are you sure you want to delete this order?")}{" "}
                    <strong className="font-weignt-500">
                        {this.state.external_id}
                    </strong>
                    {this.state.error && <ErrorComponent className="mb-0" type="error" msg={this.state.error} />}
                </Modal.Body>
                <Modal.Footer>
                    {this.state.is_loading && (
                        <i className="fas fa-spinner fa-spin mr-auto" style={{ fontSize: 16 }} aria-hidden="true" />
                    )}
                    <button
                        className="btn btn-primary"
                        disabled={this.state.is_loading || !this.state.uuid}
                        onClick={this.handleAccept}
                    >
                        {translate("common.yes", "Yes")}
                    </button>
                    <button className="btn btn-outline-secondary" onClick={onCancel} autoFocus>
                        {translate("common.no", "No")}
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default ManualEntryOrderDeleteModal;
