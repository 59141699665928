// @flow
import React from "react";
import { getBackend as getBackend2 } from "../../../lib/backend/Backend2";
import { translate } from "../../IntlProviderWrapper";
import { connect } from "react-redux";

import type { ReduxState } from "./reducers/reducers";

type Props = {
    is_loading: boolean,
    report_uuid: string | null
};
type State = {};

class AbortButton extends React.Component<Props, State> {

    onCancel = () => {
        const backend = getBackend2();
        const api_client = backend.getApiClient();
        api_client.abort();
    }

    render() {
        const is_disabled = this.props.report_uuid === null;
        if (this.props.is_loading) {
            return <button disabled={is_disabled} className="abort-button btn btn-primary" onClick={this.onCancel}>
                <i className="fas fa-spinner fa-spin"></i>
                <span style={{marginLeft: "5px"}}>{translate("common.cancel", "Cancel")}</span>
            </button>
        }
        return null;
    }
}

export default connect(
    (state: ReduxState) => {
        const report = state.gantt_chart_report;
        const parallel_report = state.gantt_chart_parallel_report;
        const report_uuid = report.original_report_uuid;
        return {
            is_loading: report.report_loading || parallel_report.save_to_sap_parallel_loading,
            report_uuid
        }
    },
    null
)(AbortButton);