// @flow
import * as React from "react";
import { FormattedMessage } from "react-intl";
import ReactRouterPropTypes from "react-router-prop-types";

import { getBackend as getBackend2 } from "../../../lib/backend/Backend2";
import * as Auth from "../../../lib/Auth";
import * as t from "../../../lib/backend/manufacturing2.generated.types";
import * as rt from "../../../lib/backend/reports.generated.types";
import * as BusinessLogic from "../../../lib/BusinessLogic";
import {  LINE_GROUP_TAGS } from "../../../lib/ManufacturingTags.generated";
import { getGanttDataSimpleAsync } from "../../../lib/GanttData";
import { splitIntoArrOfArr, shiftNumber, TIME_RANGES, dateFromWeekAndShift, toISODateString, classNames } from "../../../lib/Util";
import { ORDER_TAGS, INSIGHT_TYPES } from "../../../lib/ManufacturingTags.generated";
import { ORDER_TYPE } from "../../../lib/ManufacturingConsts.generated";
import { prepareGanttChart2, prepareGanttChartInsights } from "../../GanttChartUtils";
import { GanttChartSource } from "../PlanningTable2/reducers/properties"

import Loader from "../../Loader";
import ShiftEditorWeek from "./ShiftEditorWeek";
import LineOrderList from "./LineOrderList";
import ShiftEditorModal from "./ShiftEditorModal";
import GanttChartApp from "../PlanningTable2/GanttChartApp";

import type { IEventDataEx } from "../../../lib/backend/reports.generated.types";
import type { OrderObjGui } from "./LineOrderList";


import ErrorComponent from "../../ErrorComponent";

export type IOrderProducedModelEx = t.IOrderProducedModel & {
    has_production?: boolean
}

type Props = {
    history: ReactRouterPropTypes.history,
    line_title: string,
    line_uuid: string,
    filtered_order_uuids?: string[],
    week: number,
    year: number,
    shift: number,
    onLoad: (string) => void,
    compact: boolean,
    shift_edit_weeks: number,
    shift_edit_weeks_collapsed: number,
    insights_map: Map<string, IEventDataEx[]>,
    line_planning_data?: t.ILinePlanningData,
    hide_shift_editor?: boolean
};

type State = {
    error: string,
    line_hash: string,
    shifts: Array<Array<t.IShiftLineRec>>,
    shifts_split: Array<Array<Array<t.IShiftLineRec>>>,
    orders: IOrderProducedModelEx[],
    saved_orders: IOrderProducedModelEx[],
    parent_children_map: Map<string, string[]>,
    orders_loaded: boolean,
    show_shift_editor: boolean,
    hidden_shifts: number;
    quick_reports: Object[],
    quick_insights: IEventDataEx[][],
    quick_report_running: boolean,
    quick_report_status: string | null
};

const LINE_PLANNING_INSIGHT_TYPES = [
    INSIGHT_TYPES.operation_delay_downstream,
    INSIGHT_TYPES.operation_no_input_material,
    INSIGHT_TYPES.tool_setup
];

class LinePlanning extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const state: State = {
            error: "",
            hidden_shifts: props.shift_edit_weeks_collapsed,
            line_hash: "",
            shifts: [],
            orders: [],
            saved_orders: [],
            parent_children_map: new Map(),
            orders_loaded: false,
            show_shift_editor: false,
            shifts_split: [],
            quick_reports: [],
            quick_insights: [],
            quick_report_running: false,
            quick_report_status: null
        };
        this.state = state;
    }

    componentDidMount() {
        this.load();
    }

    reload() {
        this.setState({
            error: "",
            line_hash: "",
            shifts: [],
            orders: [],
            saved_orders: [],
            orders_loaded: false,
            shifts_split: [],
            quick_reports: [],
            quick_insights: [],
            quick_report_running: false,
            quick_report_status: null
        });
        this.load();
    }

    async load() {
        try {
            const year = this.props.year;
            let res = this.props.line_planning_data || await getBackend2().manufacturing.getLinePlanningData({
                line_uuid: this.props.line_uuid,
                year,
                week_from: this.props.week,
                week_count: this.props.shift_edit_weeks
            });
            res.shifts.sort((a, b) => {
                const diff = a.shift_date - b.shift_date;
                return diff !== 0 ? diff : a.shift_number - b.shift_number;
            });

            const line_hash = res.hash;
            const shifts = [];
            const shifts_split = [];

            for (let shift_editor_i = 0; shift_editor_i < this.props.shift_edit_weeks; shift_editor_i++) {
                const { year: future_year, week } = this.getWeekAndYear(shift_editor_i);
                shifts[week] = res.shifts.filter(x => x.week === week && x.year === future_year);
                shifts_split[week] = splitIntoArrOfArr(shifts[week], 3);
            }

            const orders = res.orders
                .sort((order_a, order_b) => order_a.sequence_weight - order_b.sequence_weight);
            // load orders
            // $FlowFixMe : casting causes flow error, but there is no need since only new paramtere is optional
            await this.loadOrders(orders);
            // set rest of the state
            this.setState(
                {
                    line_hash,
                    orders_loaded: true,
                    shifts,
                    shifts_split
                },
                () => { this.props.onLoad(this.props.line_uuid); }
            );
        } catch (err) {
            console.log(err);
        }
    }

    loadOrders(orders: IOrderProducedModelEx[]): Promise<void> {
        // remember children => parent map for mapping insights
        const parent_children_map = new Map();
        for (const order of orders) {
            // check if we have parent
            const parent_order_uuid = order.parent_order_uuid || "";
            if (parent_order_uuid === "") { continue; }
            // if we do, add child insights to it
            const children = parent_children_map.get(parent_order_uuid) || [ ];
            children.push(order.uuid);
            parent_children_map.set(parent_order_uuid, children);
        }
        // filter out parallel orders for normal users
        const is_admin = Auth.isInRole(Auth.ROLE_ADMIN);
        const is_power_user = Auth.isInRole(Auth.ROLE_POWER_USER);
        const is_demo_user = Auth.isInRole(Auth.ROLE_DEMO_USER);

        if (!is_admin && !is_power_user && !is_demo_user) {
            orders = orders.filter(x => x.tags[ORDER_TAGS.parallel_order] !== "true");
        }

        return new Promise((resolve) => {
            this.setState({
                orders,
                saved_orders: orders,
                parent_children_map
            }, resolve);
        });
    }

    canSave = async (): Promise<boolean> => {
        const line_uuid = this.props.line_uuid;

        // check that hash of data is still the same
        const res_hash = await getBackend2().manufacturing.getLineHash({ line_uuid });
        if (res_hash.hash !== this.state.line_hash) {
            this.setState({
                error: this.props.line_title + ": Data on server has changed since last re-load. Please refresh the page."
            });
            return false;
        }
        return true;
    }

    saveOrders = async (): Promise<void> => {
        const line_uuid = this.props.line_uuid;
        if (this.state.orders.length > 0) {
            let items = this.refs["order"].getOrders();
            const orders: t.IOrderUpdateRec[] = [];
            let cntr = 1;
            for (const item of items) {
                if (item.order_type === ORDER_TYPE.chunk) { continue; }
                orders.push({
                    capacity_factor: item.capacity_factor,
                    uuid: item.uuid,
                    process_uuid: item.process_uuid, // currently not needed
                    quantity_total: item.quantity_total,
                    sequence_weight: cntr++,//item.sequence_weight,
                    sim_ignore_earliest_start: item.sim_ignore_earliest_start,
                    skip_sim: !item.enabled,
                    ignore_on_planning_board: item.ignore_on_planning_board
                });
            }

            await getBackend2().manufacturing.updateLineOrders({ line_uuid, orders });
        }
    }

    saveShifts = async (): Promise<void> => {
        const line_uuid = this.props.line_uuid;

        const shifts: Array<t.IShiftUpdateRec> = [];
        for (let i = 0; i < this.props.shift_edit_weeks; i++) {
            const { week } = this.getWeekAndYear(i);
            this.state.shifts[week].forEach(x => {
                shifts.push({ enabled: x.enabled, line_uuid, shift_uuid: x.shift_uuid });
            });
        }
        await getBackend2().manufacturing.updateShifts({ shifts });
    }

    save = async (): Promise<boolean> => {
        try {
            // check if we can still save
            if (await this.canSave()) {
                // we can, save orders
                await this.saveOrders();
                // save shifts
                await this.saveShifts();
                // check if we should execute new simulation on each edit?
                const line_group = BusinessLogic.getLineGroupForLine(this.props.line_uuid);
                const line_group_uuid = line_group !== null ? line_group.uuid : "";
                const microplan_recalc_on_save = BusinessLogic.getLineGroupTagBool(
                    line_group_uuid, LINE_GROUP_TAGS.microplan_recalc_on_save, false);
                // initiate a new simulation
                if (microplan_recalc_on_save) { await BusinessLogic.fullSimulation(); }
                // announce success!
                return true;
            }
        } catch (error) {
            console.log(error);
        }
        // we cannot, announce failure!
        return false;
    }

    getOrders(): IOrderProducedModelEx[] {
        return this.state.orders;
    }

    getSavedOrders(): IOrderProducedModelEx[] {
        return this.state.saved_orders;
    }

    async setOrders(orders: IOrderProducedModelEx[]) {
        await this.loadOrders(orders);
        if (orders.length > 0) {
            await this.refs["order"].updateItems();
        }
    }

    // generate order list for saving together with other edited lines
    getSaveOrders(): t.IPlanOperation[] {
        if (this.state.orders.length === 0) { return []; }
        let items = this.refs["order"].getOrders();
        return items.map(item => ({
            capacity_factor: item.capacity_factor,
            material_title: item.material_title,
            quantity: item.quantity_total,
            sim_ignore_earliest_start: item.sim_ignore_earliest_start,
            skip_sim: !item.enabled,
            uuid: item.uuid
        }));
    }

    getWeekAndYear = (offset: number) => {
        const shift = shiftNumber(dateFromWeekAndShift(this.props.week + offset, this.props.year, 0));
        return { week: shift.week, year: shift.year }
    }

    // gnerate job input for bigger plan simulation
    generateJobItem(): t.IRunQuickSimulationLine {
        // collect order overrides
        let orders_mapped: Array<t.IRunQuickSimulationOrder> = [];
        if (this.state.orders.length > 0) {
            const orders: OrderObjGui[] = this.refs["order"].getEnabledItems()
                .sort((a, b) => a.sequence_weight - b.sequence_weight);

            orders_mapped = orders.map(x => {
                return {
                    capacity_factor: x.capacity_factor,
                    earliest_end: toISODateString(new Date(x.earliest_end_original)),
                    earliest_start: toISODateString(new Date(x.earliest_start_original)),
                    external_id: x.external_id,
                    has_production: x.has_production,
                    production_version: x.production_version || "",
                    sim_ignore_earliest_start: x.sim_ignore_earliest_start,
                    quantity_total: x.quantity_total,
                    uuid: x.uuid
                }
            });
        }
        // collect shift overrides
        const shifts: Array<t.IRunQuickSimulationShift> = [];
        const extractShiftData = ((x: t.IShiftLineRec) => {
            shifts.push({
                enabled: x.enabled,
                shift: x.shift_number,
                week: x.week,
                year: x.year
            });
        });
        for (let shift_editor_i = 0; shift_editor_i < this.props.shift_edit_weeks; shift_editor_i++) {
            const { week } = this.getWeekAndYear(shift_editor_i);
            this.state.shifts[week].forEach(extractShiftData);
        }
        return { uuid: this.props.line_uuid, orders: orders_mapped, shifts };
    }

    // generate job for simulating just this line
    generateJob(): rt.IRunQuickSimulationReq {
        const from_shift = shiftNumber(new Date(Date.now() - 1 * TIME_RANGES.DAY));
        const next_shift = shiftNumber(new Date());
        const lines = [this.generateJobItem()];

        return {
            from_shift: {
                shift: 0,
                week: from_shift.week,
                year: from_shift.year
            },
            next_shift: {
                shift: next_shift.shift,
                week: next_shift.week,
                year: next_shift.year
            },
            predict_shifts: 3 * 7 * 3,      // 3 weeks
            predict_stock_shifts: 3 * 7 * 3,
            stock_plant_insights_num_shifts: 3 * 7 * 3,
            stock_shopfloor_insights_num_shifts: 3 * 7 * 3,
            lines,
            tags: {
                line_uuids: this.props.line_uuid,
                stock_forecast: "true"
            }
        };
    }

    async getSimulation(uuid: string) {
        try {
            const result = await getBackend2().reports.getReportStatus({ id: uuid });
            const status = result.status;
            if (status === "ended") {
                // get report
                const raw_simulation = await getGanttDataSimpleAsync(uuid, [this.props.line_uuid]);
                const insight_types = LINE_PLANNING_INSIGHT_TYPES;

                const simulation = (await prepareGanttChart2({
                    simulation: raw_simulation,
                    line_tags: []
                }));

                const insights = await prepareGanttChartInsights(
                    raw_simulation,
                    insight_types,
                    [this.props.line_uuid],
                    0, // past weeks
                    this.props.shift_edit_weeks * TIME_RANGES.WEEK // shown days
                );

                // remember
                const quick_reports = [simulation.report, ...this.state.quick_reports];
                const quick_insights = [insights, ...this.state.quick_insights];
                this.setState(
                    {
                        quick_report_running: false,
                        quick_report_status: status,
                        quick_reports,
                        quick_insights  },
                    () => {
                        getBackend2().reports.deleteReport({ id: uuid });
                    });
            } else if (status === "errored") {
                this.setState({ quick_report_running: false, quick_report_status: status });
                console.log("Simulation errored: " + result.status_msg);
                getBackend2().reports.deleteReport({ id: uuid });
            } else {
                setTimeout(() => {
                    this.getSimulation(uuid);
                }, 2000);
            }
        } catch (error) {
            console.log(error);
        }
    }

    // simulate just this line
    async quickSimulate() {
        try {
            this.setState({ quick_report_running: true, quick_report_status: null });
            let job = this.generateJob();
            const res = await getBackend2().reports.runQuickSimulation(job);
            this.setState({ quick_report_status: "started" });
            await this.getSimulation(res.uuid);
        } catch (e) {
            console.log(e);
        }
    }

    handleShiftClick(shift: number, day: number, active: boolean, week: number) {
        const index = 3 * day + shift;
        this.setState(prevState => {
            prevState.shifts[week][index].enabled = !active;
            prevState.shifts_split[week] = splitIntoArrOfArr(prevState.shifts[week], 3);
            return prevState;
        });
    }

    handleShiftsClick() {
        this.setState({ show_shift_editor: true });
    }

    renderCompact(shiftEditors: any[]) {
        const hasOrders = (this.state.orders.length > 0);
        return (
            <div className="planning-table line">
                <div className="row">
                    <div className="col-12">
                        <div className="float-right">
                            [<button className="btn btn-link planning-table shift-link" onClick={() => this.handleShiftsClick()}>
                                <FormattedMessage id="common.shifts" defaultMessage="Shifts" />
                            </button>]
                        </div>
                        <div className="active_data">
                            <span className="planning-table line-title">{this.props.line_title}</span>
                        </div>
                    </div>
                    {!hasOrders && <div className="col-12">
                        <FormattedMessage id="Manufacturing.Planning.no_orders" defaultMessage="No orders found." />
                    </div>}
                </div>
                {this.state.error && this.state.error.length > 0 &&
                    <div className="row">
                        <div className="col-12">
                            <ErrorComponent msg={this.state.error} type="warning"/>
                        </div>
                    </div>
                }
                {hasOrders && <div className="row">
                    <div className="col-12">
                        <LineOrderList
                            orders={this.state.orders}
                            ref={"order"}
                            insights_map={this.props.insights_map}
                            parent_children_map={this.state.parent_children_map}
                            compact={false}
                            filtered_order_uuids={this.props.filtered_order_uuids}
                        />
                    </div>
                </div>}
                <ShiftEditorModal
                    onClose={() => this.setState({ show_shift_editor: false })}
                    line_title={this.props.line_title}
                    show={this.state.show_shift_editor}
                    children={shiftEditors}
                />
            </div>
        );
    }

    renderStandalone(shiftEditors: any[]) {
        const hasOrders = (this.state.orders.length > 0);
        const line_group = BusinessLogic.getLineGroupForLine(this.props.line_uuid);
        return (
            <div className="planning-table line">
                <div className="row">
                    {!hasOrders && <div className="col-6">
                        <FormattedMessage id="Manufacturing.Planning.no_orders" defaultMessage="No orders found." />
                    </div>}
                    {hasOrders &&
                    <div className={classNames("col-12", {
                        "col-xl-6": this.props.hide_shift_editor !== true,
                        "col-xl-12": this.props.hide_shift_editor === true
                    })}>
                        <LineOrderList
                            orders={this.state.orders}
                            ref={"order"}
                            insights_map={this.props.insights_map}
                            parent_children_map={this.state.parent_children_map}
                            compact={true}
                            filtered_order_uuids={this.props.filtered_order_uuids}
                        />
                    </div>}
                    {this.props.hide_shift_editor !== true && (
                        <div className={classNames("col-12 col-xl-6")}>
                            {shiftEditors}
                            {this.state.hidden_shifts > 0 && <div>
                                <button className="btn btn-link" onClick={() => { this.setState({ hidden_shifts: 0 }); return false; }}>
                                    <FormattedMessage id="common.show_more" defaultMessage="Show more" />...
                                </button>
                            </div>}
                        </div>
                    )}
                </div>
                    {this.state.quick_report_running && <Loader small={true} />}
                    {this.state.quick_reports.map((quick_report, idx) => {
                        return (<div key={quick_report.uuid} className="quick_sim_gantt" style={{width: "100vw"}}>
                            {line_group && <GanttChartApp
                                report={quick_report}
                                insights={this.state.quick_insights[idx] !== null ? this.state.quick_insights[idx] : []}
                                shifts={quick_report.result.line_shift_features}
                                days={7 * this.props.shift_edit_weeks}
                                current_shift={this.props.shift}
                                past_weeks={0}
                                start_day={Math.floor(quick_report.result.next_shift_time.shift_number / 3)}
                                show_past_productions={false}
                                week={this.props.week}
                                year={this.props.year}
                                simulation_offset={0}
                                simulation_uuid={quick_report.uuid}
                                handleClick={(line_uuid) => alert(line_uuid)}
                                no_overflow={true}
                                top_padding={45}
                                space_for_label={0}
                                show_labels={false}
                                key={quick_report.uuid}
                                insight_types={LINE_PLANNING_INSIGHT_TYPES}
                                line_group_uuid={line_group.uuid}
                                source_type={GanttChartSource.line_planning}
                                show_only_tool_changes={false}
                            />}
                        </div>);
                    })}
                {this.state.error && this.state.error.length > 0 &&
                    <div className="row">
                        <div className="col-12">
                            <ErrorComponent msg={this.state.error} type="warning"/>
                        </div>
                    </div>
                }
            </div>
        );
    }

    // prepare list of datasources
    render() {
        if (this.state && this.state.shifts && this.state.orders_loaded) {
            const shiftEditors = [];
            const visible_shifts = this.props.shift_edit_weeks - this.state.hidden_shifts;
            for (let shift_editor_i = 0; shift_editor_i < visible_shifts; shift_editor_i++) {
                const { week } = this.getWeekAndYear(shift_editor_i);
                shiftEditors.push(
                    <ShiftEditorWeek
                        current_shift={-1}
                        current_week={week}
                        key={this.props.line_uuid + "_shifts_" + shift_editor_i}
                        ref={"shedule" + shift_editor_i}
                        shifts={this.state.shifts_split[week]}
                        show_overrides={true}
                        show_dates={true}
                        handleClick={(day, shift, active, week_arg) => this.handleShiftClick(day, shift, active, week)}
                    />
                );
            }
            return this.props.compact ?
                this.renderCompact(shiftEditors) :
                this.renderStandalone(shiftEditors);
        }
        return <Loader small={true} />;
    };
}

export default LinePlanning;
