// @flow
import * as React from "react";
import * as Auth from "../lib/Auth";
import { translate } from "./IntlProviderWrapper";
import { FormattedMessage } from "react-intl";

import ErrorComponent from "./ErrorComponent";

type Props = {
    message?: string,
    onClose: () => void
};

type State = {
    old_password: string,
    new_password1: string,
    new_password2: string,
    message: string,
    update_ok: boolean
};

class ChangePassword extends React.Component<Props, State> {
    constructor() {
        super();

        this.state = {
            old_password: "",
            new_password1: "",
            new_password2: "",
            message: "",
            update_ok: false
        };
    }

    handleChange = (event: Event) => {
        let target = event.target;
        if (target instanceof HTMLInputElement) {
            // collect changed field name and value
            const field = target.name;
            const value = target.value;
            // update
            this.setState(prev_state => {
                prev_state[field] = value;
                return prev_state;
            });
        }
    }

    handleSubmit = async (event: Event) => {
        event.preventDefault();

        try {
            // send collected parameters through validation
            const message = Auth.validateNewPassword(this.state.old_password,
                this.state.new_password1, this.state.new_password2);
            // report in case we got something
            this.setState({ message });
            // if we got no message, proceed with password change
            if (message === "") {
                await Auth.changePassword(
                    Auth.getLoggedinUser().uuid,
                    this.state.new_password1,
                    this.state.old_password
                );
                this.setState({
                    message: translate("Login.PwdChangeOk", "Password changed successfully"),
                    update_ok: true
                });
            }

        } catch (err) {
            this.setState({ message: err.message });
        }
    }

    handleCancel = () => {
        this.props.onClose();
    }

    render_alert() {
        return (
            <ErrorComponent msg={this.state.message} type="warning" />
        );
    }

    render() {
        return (
            <div className="tab-content text-left">
                <div className="space30"></div>
                <h4>
                    {this.props.message && <span>{ this.props.message }</span>}
                    {!this.props.message && <FormattedMessage id="Settings.change_password" defaultMessage="Change password" />}
                </h4>
                {this.state.message !== "" && this.render_alert()}
                {!this.state.update_ok &&
                    <div className="form-group">
                        <label>
                            <FormattedMessage id="common.current_password" defaultMessage="Current password" />
                        </label>
                        <input name="old_password" type="password" disabled="" placeholder="Current password" className="form-control"
                            value={this.state.old_password} onChange={this.handleChange} />
                    </div>
                }
                {!this.state.update_ok &&
                    <div className="form-group">
                        <label>
                            <FormattedMessage id="common.new_password" defaultMessage="New password" />
                        </label>
                        <input name="new_password1" type="password" disabled="" placeholder="New password" className="form-control"
                            value={this.state.new_password1} onChange={this.handleChange} />
                    </div>
                }
                {!this.state.update_ok &&
                    <div className="form-group">
                        <label>
                            <FormattedMessage id="common.reenter_password" defaultMessage="Retype password" />
                        </label>
                        <input name="new_password2" type="password" disabled="" placeholder="Repeat new password" className="form-control"
                            value={this.state.new_password2} onChange={this.handleChange} />
                    </div>
                }
                <div>
                    { !this.state.update_ok &&
                        <button className="btn_save btn btn-default" onClick={this.handleSubmit}>
                            <FormattedMessage id="common.save" defaultMessage="Save" />
                        </button>
                    }
                    { !this.state.update_ok &&
                        <button className="btn_cancel btn" onClick={this.handleCancel}>
                            <FormattedMessage id="common.cancel" defaultMessage="Cancel" />
                        </button>
                    }
                    { this.state.update_ok &&
                        <button className="btn_cancel btn" onClick={this.handleCancel}>
                            <FormattedMessage id="common.close" defaultMessage="Close" />
                        </button>
                    }
                </div>
            </div>
        );
    }
}

export default ChangePassword;
