// @flow
import * as React from "react";
import { connect } from "react-redux";
import * as BusinessLogic from "../../../../lib/BusinessLogic";
import { INSIGHT_TAGS, INSIGHT_TYPES } from "../../../../lib/ManufacturingTags.generated";
import * as t2 from "../../../../lib/backend/reports.generated.types";
import * as rt from "../../../../lib/SimulationReportModels";
import * as t from "../../../../lib/backend/manufacturing2.generated.types";
import * as constants from "./constants";
import {
    TIME_RANGES,
    ORANGE_COLOR,
    DARK_SALMON_COLOR,
    DARKER_SALMON_COLOR,
    DARKER_DARK_SALMON_COLOR,
    WHITE_COLOR,
    GREY_COLOR,
    LIGHT_BLUE,
    DARK_BLUE,
    getColorPatternForPlannedOrders,
    getDarkerColor,
    dateFromShiftTime
} from "../../../../lib/Util";
import { translate } from "../../../../components/IntlProviderWrapper";
import { ReduxFilters, PropertiesLogic, UserActions, ReportLogic, LinesOrdersLogic } from "../reducers/BusinessLogic";
import { getCursorValue } from "../reducers/properties";
import { onMouseEnterDispatch } from "../Draggable/DraggableElements"
import { TooltipText } from "./Order";
import Tooltip from "../../../Tooltip";

import type { ReduxState } from "../reducers/reducers";
import type { LineOrders } from "../reducers/linesOrders";
import type { ResetFiltersAction } from "../reducers/reducersFilters/filters";
import type { GanttChartSourceTypes, PropertiesState } from "../reducers/properties";
import type { SetAdvancedOrderMenuAction, ResetAdvancedOrderMenu } from "../reducers/planningTable";
import type { SetOrderTableProductionModalAction } from "../reducers/ganttChartStandalone";
import type { InsightsMapped } from "../reducers/insights";
import type { AvailableInsightTypes } from  "../reducers/ganttChartStandalone";
import type { SetSkipStockReport } from "../reducers/properties";
import type { SetLeftMenuSelectedView } from "../reducers/planningTable";
import type { SetHighlightedOrders, SetLastClickedOrderUuid}from "../reducers/planningTable";
import type { SetRescheduleOrderAction, SetRescheduleOrderBundleAction, ValidOrder } from "../reducers/common";

type InsightsRectProps = {
    onInsightClick: (
        order_uuid: string, order_external_id: string, line_uuid: string, process_num: string
    ) => void,
    is_ctrl_down: boolean,
    is_rescheduled: boolean,
    order_uuid: string,
    order_external_id: string,
    line_uuid: string,
    process_num: string,
    source_type: GanttChartSourceTypes,
    x: number,
    y: number,
    width: number,
    height: number,
    tooltip_content: React.Node
};
type InsightsRectState = { };



class InsightsRect extends React.Component<InsightsRectProps, InsightsRectState> {

    render() {
        const is_rescheduled = this.props.is_rescheduled;
        const width = this.props.width;
        const bar_height = this.props.height;
        const line_uuid = this.props.line_uuid;
        let style = {};
        if (PropertiesLogic.isPlanningTable(this.props.source_type)) {
            style = this.props.is_rescheduled ? { pointerEvents: "none" } : { pointerEvents: "unset" };
        }

        return <Tooltip
            key={"insights-" + this.props.line_uuid + "-" + this.props.x}
            content={this.props.tooltip_content}
        >
            <rect
                id="insights-rect"
                x={this.props.x}
                y={this.props.y}
                width={width}
                height={bar_height}
                fill={"url(#patternDiagonal)"}
                style={style}
                cursor={getCursorValue({
                    is_ctrl_down: this.props.is_ctrl_down,
                    is_rescheduled,
                    source_type: this.props.source_type
                })}
                onClick={this.props.onInsightClick(
                    this.props.order_uuid,
                    this.props.order_external_id,
                    line_uuid,
                    this.props.process_num
                )}
                line_uuid={line_uuid}
                source_type={this.props.source_type}
                is_unscheduled={"false"}
            />
        </Tooltip>
    }
}

const ConnectedInsightsRect = connect(
    (state: ReduxState, props: InsightsRectProps) => {
        const is_rescheduled = state.gantt_chart_planning_table.last_clicked_order_uuid === props.order_uuid;
        return { is_rescheduled }
    },
    null
)(InsightsRect)


type InsightsImageProps = {
    insight_severity: string,
    x0: number,
    y0: number,
    w_shift: number,
    bar_height: number,
    is_ctrl_down: boolean,
    is_rescheduled: boolean,
    source_type: GanttChartSourceTypes,
    order_uuid: string,
    order_external_id: string,
    line_uuid: string,
    process_num: string,
    onInsightClick: (
        order_uuid: string, order_external_id: string, line_uuid: string, process_num: string
    ) => void,
    xlinkHref: string,
    x: number,
    y: number,
    ts: string,
    tooltip_content: string
};
type InsightsImageState = {};

class InsightsImage extends React.Component<InsightsImageProps, InsightsImageState> {

    render() {
        const w_shift = this.props.w_shift;
        const bar_height = this.props.bar_height;
        const is_rescheduled = this.props.is_rescheduled;
        const order_uuid = this.props.order_uuid;
        const order_external_id = this.props.order_external_id;
        const line_uuid = this.props.line_uuid;
        const process_num = this.props.process_num;

        return <Tooltip
            key={"downtime-" + this.props.line_uuid + "-" + this.props.x}
            content={this.props.tooltip_content}
        >
            <image
                id="gantt-downtime"
                xlinkHref={this.props.xlinkHref}
                x={this.props.x}
                y={this.props.y}
                width={w_shift}
                height={bar_height}
                ts={this.props.ts}
                cursor={getCursorValue({
                    is_ctrl_down: this.props.is_ctrl_down,
                    is_rescheduled,
                    source_type: this.props.source_type
                })}
                onClick={this.props.onInsightClick(
                    order_uuid,
                    order_external_id,
                    line_uuid,
                    process_num
                )}
                onMouseEnter={() => {onMouseEnterDispatch(order_uuid, { name: "gantt-downtime", order_uuid }) }}
            ></image>
        </Tooltip>
    }
}

const ConnectedInsightsImage = connect(
    (state: ReduxState, props: InsightsImageProps) => {
        const is_rescheduled = state.gantt_chart_planning_table.last_clicked_order_uuid === props.order_uuid;
        return { is_rescheduled }
    },
    null
)(InsightsImage);


const generateColoredPatterns = (color: string) => {
    const pattern_id = getColorPatternForPlannedOrders(color);
    return <pattern id={pattern_id} x={0} y={-2} width={10} height={10} patternUnits="userSpaceOnUse" key={color}>
        <rect x={0} y={0} width={100} height={100} patternUnits="userSpaceOnUse" fill={color} />
        <circle cx={2.5} cy={2.5} r={2.5} fill={getDarkerColor(color)} fillOpacity={2} />
    </pattern>;
}

export const InsightsCommonPatterns = () => {
    return <React.Fragment>
        <pattern id="patternCombinedInsights" width="12" height="14" patternUnits="userSpaceOnUse" patternTransform="rotate(-37)">
            <line x1="0" y1="0" x2="0" y2="15.5" stroke="#FFB68F" strokeWidth="12"></line>
            <line x1="12" y1="0" x2="12" y2="15.5" stroke="#FF453C" strokeWidth="12"></line>
        </pattern>
        <pattern id="patternCombinedPlannedInsights" x="-2.5" y="-4.5" width="20" height="10" patternUnits="userSpaceOnUse" patternTransform="rotate(-37)">
            <rect x="0" y="0" width="10" height="10" fill={ORANGE_COLOR}></rect>
            <circle cx="5" cy="5" r="2.5" fill={DARKER_SALMON_COLOR}></circle>
            <rect x="10" y="0" width="10" height="10" fill={DARK_SALMON_COLOR}></rect>
            <circle cx="15" cy="5" r="2.5" fill={DARKER_DARK_SALMON_COLOR}></circle>
        </pattern>
        <filter id="grayscale">
            <feColorMatrix type="saturate" values="0.20"/>
        </filter>
        <pattern id="patternDiagonal" width="4" height="4" patternUnits="userSpaceOnUse">
            <path fill="none" stroke="#00000080" strokeWidth="1" d="M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2"></path>
        </pattern>
        {
            [
                generateColoredPatterns(ORANGE_COLOR),
                generateColoredPatterns(DARK_SALMON_COLOR),
                generateColoredPatterns(WHITE_COLOR),
                generateColoredPatterns(GREY_COLOR),
                generateColoredPatterns(LIGHT_BLUE),
                generateColoredPatterns(DARK_BLUE)
            ]
        }
    </React.Fragment>
}


function groupedInsightType(insight_type: string): string {
    const insight_mapping = {
        [INSIGHT_TYPES.man_downtime]: INSIGHT_TYPES.downtime,
        [INSIGHT_TYPES.out_of_stock_plant]: INSIGHT_TYPES.out_of_stock_shopfloor
    }

    if (insight_mapping[insight_type] !== undefined) {
        return insight_mapping[insight_type];
    }

    return insight_type;
}

function sameGroupInsights(insights: t.IEventDataEx[], i: number, j: number) {
    const insight_i = insights[i];
    const insight_j = insights[j];
    if (insight_i.tags.line_uuid !== insight_j.tags.line_uuid) { return false; }
    if (insight_i.tags.order_external_id !== insight_j.tags.order_external_id) { return false; }
    if (insight_i.tags.shift_tag !== insight_j.tags.shift_tag) { return false; }
    // merge similar types
    const type_i = groupedInsightType(insight_i.type);
    const type_j = groupedInsightType(insight_j.type);
    if (type_i !== type_j) { return false; }
    return true;
}

type LineOffets = {
    [key: string]: number
};

type Props = {
    is_ctrl_down: boolean,
    properties: PropertiesState,
    insights: t2.IEventDataEx[],
    options: rt.ISimulationReportOrderLineEx[],
    line_indexes: LineOffets,
    line_uuid_indexes: LineOffets,
    valid_orders_map: Map<string, ValidOrder> | null,
    lines_orders: LineOrders[],
    extra_lines: t2.IExtraLines[] | null,
    source_type: GanttChartSourceTypes,
    insights_mapped: InsightsMapped | null,
    reduxDispatch: (args: SetRescheduleOrderAction | SetAdvancedOrderMenuAction |
        ResetAdvancedOrderMenu | ResetFiltersAction |
        SetOrderTableProductionModalAction | SetSkipStockReport | SetLeftMenuSelectedView |
        SetHighlightedOrders | SetLastClickedOrderUuid | SetRescheduleOrderBundleAction
    ) => void,
    filtered_insight_types: AvailableInsightTypes[],
    is_filter_locked: boolean,
    from_shift_time: t2.ISimulationReportShiftTime | null,
    materials: t.IMaterialModel[] 
}


class Insights extends React.Component<Props, any> {

    getIsExtraLine = (line_uuid: string): boolean => {
        if (!this.props.extra_lines || this.props.extra_lines.length === 0) {
            return false;
        }

        return this.props.extra_lines.some(l => l.line === line_uuid)
    }

    onInsightClick = (
        order_uuid: string,
        order_external_id: string,
        line_uuid: string,
        process_num: string
    ) => (e: SyntheticEvent<HTMLElement>) => {
        const currentTarget = e.currentTarget;
        if (currentTarget instanceof Element) {
            const advanced_order_menu = {
                order_uuid,
                click_target: currentTarget,
                // $FlowFixMe
                event: e
            };
            let is_readonly = false;
            const line_order = this.props.lines_orders.find(l => l.line_uuid === line_uuid);
            if (line_order) {
                const order = line_order.orders.find(o => o.uuid === order_uuid);
                if (order) {
                    is_readonly = LinesOrdersLogic.isOrderReadOnly(line_order, order);
                }
            }

            ReduxFilters.last_click_location = "gantt";

            UserActions.onOrderClick({
                order_uuid,
                order_external_id,
                source_type: this.props.source_type,
                is_extra_line: this.getIsExtraLine(line_uuid),
                is_ctrl_down: this.props.is_ctrl_down,
                advanced_order_menu,
                process_num,
                reduxDispatch: this.props.reduxDispatch,
                is_readonly,
                is_filter_locked: this.props.is_filter_locked
            });

            e.stopPropagation();
        }
    }

    getOrder = (insight: t2.IEventDataEx): rt.ISimulationReportOrderEx | null => {
        const line_uuids = ReportLogic.getLineUuidsFromInsight(insight);
        const line_uuid = ReportLogic.getLineUuidFromInsight(insight) || line_uuids && line_uuids[0];
        const order_uuids = ReportLogic.getOrderUuidsFromInsight(insight);
        const order_uuid = ReportLogic.getOrderUuidFromInsight(insight) || order_uuids && order_uuids[0];
        if (!line_uuid || !order_uuid) {
            return null;
        }

        const line = this.props.options.find(l => l.line === line_uuid);
        return line && line.production.find(o => o.order_id === order_uuid) || null;
    }

    getToolExternalId = (insight: t2.IEventDataEx): string => {
        return (insight.extra_data && insight.extra_data.tool_external_id) ||
            (insight.tags && insight.tags.tool_external_id) || "";
    }


    getTooltipProperties = (insight: t2.IEventDataEx, tool_external_id?: string) => {
        return tool_external_id ? [{
            name: translate("common.tool_id", "Tool ID"),
            value: tool_external_id
        }] : []
    }

    getInsights = () => {
        const properties = this.props.properties;
        const from_shift_time = this.props.from_shift_time;
        if (!this.props.insights || !from_shift_time) {
            return null;
        }

        const bars_start = properties.bars_start;
        const xscale = properties.xscale;
        if (!xscale) {
            return null;
        }

        const w_shift = xscale(constants.SHIFT_H);
        const bar_height = properties.bar_height;

        BusinessLogic.updateTsForInsights(this.props.insights);
        BusinessLogic.updateTsForInsightsToShift(this.props.insights);

        // here we draw only insight for tool setup and downtime, rest are drawn in order bars
        const type_order = [
            INSIGHT_TYPES.tool_setup,
            INSIGHT_TYPES.man_downtime
        ].filter(t => this.props.filtered_insight_types.includes(t));
        // handling overlapping alarms
        // sort insights by line, type and timestamp
        let sorted_insights = this.props.insights
            .filter(i => type_order.includes(i.type))
            .sort((a, b) => {
                // sort by lines and then sort by ts
                if (a.tags.order_uuid > b.tags.order_uuid) {
                    return 1;
                } else if (a.tags.order_uuid < b.tags.order_uuid) {
                    return -1;
                } else {
                    // check if different type to sort over
                    const a_type = type_order.indexOf(groupedInsightType(a.type));
                    const b_type = type_order.indexOf(groupedInsightType(b.type));
                    if (a_type !== b_type) { return a_type - b_type; }
                    // all same, just timestamp difference
                    return a.ts - b.ts;
                }
            });

        const Insights = [];

        for (let i = 0; i < sorted_insights.length; i++) {
            let insight = sorted_insights[i];

            const line_uuid = insight.tags.line_uuid;
            const insights_order_uuid = ReportLogic.getOrderUuidFromInsight(insight);

            const insight_order_uuid = insights_order_uuid
            if (this.props.line_indexes[insight_order_uuid] == undefined && insight.type !== INSIGHT_TYPES.man_downtime) {
                // filters are enabled
                continue;
            }

            if (PropertiesLogic.isPlanningTable(this.props.source_type) && (
                this.props.valid_orders_map === null ||
                this.props.valid_orders_map.get(insight_order_uuid) === undefined
            )) {
                continue;
            }
            const from_shift_time_ts = dateFromShiftTime(from_shift_time).getTime();
            const ts = (insight.ts - from_shift_time_ts) / TIME_RANGES.HOUR;
            if (ts < 0) continue;

            const x0 = bars_start + xscale(ts);

            let heightcounter = 0;
            if (insight.type === INSIGHT_TYPES.man_downtime) {
                heightcounter = this.props.line_uuid_indexes[insight.tags[INSIGHT_TAGS.line_uuid]];
            } else {
                heightcounter = this.props.line_indexes[insight_order_uuid];
            }

            if (parseInt(insight.tags.shift_ts, 10) < from_shift_time_ts) { continue; }

            let y0 = (properties.top_padding + heightcounter * (properties.bar_height + properties.space_between_bars));

            const { order_uuid, order_external_id } = insight.tags;
            const order = this.getOrder(insight);
            let process_num = "";
            if (order) {
                process_num = order.process_num.toString();
            }

            const width = xscale((insight.ts_to - insight.ts) / TIME_RANGES.HOUR);
            // draw insight
            if (insight.type === INSIGHT_TYPES.tool_setup) {
                // in the future read this data from simulation
                let insight_tooltips = [];
                let material;
                if (order && this.props.insights_mapped) {
                    insight_tooltips = (this.props.insights_mapped.get(order.order_id) || []).map(
                        insight => BusinessLogic.getInsightTooltipContent(insight));
                    material = this.props.materials.find(
                        material => material.external_id === order.material
                    );
                }

                if (width > 0) {
                    const tool_external_id = this.getToolExternalId(insight);
                    const tool_setup_class = order || tool_external_id
                        ? "order-tooltip-title"
                        : "font-weight-500 mb-0";
                    const tooltip_content = (
                        <React.Fragment>
                            <p className={tool_setup_class}>
                                {translate("common.tool_setup", "Tool setup")}
                            </p>
                            {
                                order && <TooltipText
                                    order={order}
                                    unscheduled_order={null}
                                    insight_tooltips={insight_tooltips}
                                    properties={this.getTooltipProperties(insight, tool_external_id)}
                                    updated_order_uuid={null}
                                    material={material}
                                />
                            }
                            {!order && tool_external_id && (
                                <table className="order-tooltip-properties">
                                    <tbody>
                                        <tr>
                                            <th>{translate("common.tool_id", "Tool ID")}</th>
                                            <td>{this.getToolExternalId(insight)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            )}
                        </React.Fragment>
                    )
                    Insights.push(
                        <ConnectedInsightsRect
                            onInsightClick={this.onInsightClick}
                            is_ctrl_down={this.props.is_ctrl_down}
                            order_uuid={order_uuid}
                            order_external_id={order_external_id}
                            line_uuid={line_uuid}
                            process_num={process_num}
                            source_type={this.props.source_type}
                            x={x0}
                            y={y0}
                            width={width}
                            height={bar_height}
                            tooltip_content={tooltip_content}
                        />
                    );
                }
            } else if (insight.type === INSIGHT_TYPES.man_downtime) {
                // group similar insights into one tooltip
                const insight_tooltips = [BusinessLogic.getInsightTooltipContent(insight)];
                let insight_duration = insight.extra_data.duration;
                if (i < sorted_insights.length) {
                    let j = 1;
                    while ((i + j < sorted_insights.length) && sameGroupInsights(sorted_insights, i, i + j)) {
                        insight_tooltips.push(BusinessLogic.getInsightTooltipContent(sorted_insights[i + j]));
                        insight_duration += sorted_insights[i + j].extra_data.duration;
                        j++;
                    }
                    i = i + (j - 1);
                }

                const tooltip_content = (
                    <ul className="order-tooltip-insights list-unstyled mb-0">
                        {insight_tooltips.map((tooltip, i) => {
                            const first_class = i === 0 ? " border-top-0 pt-0 mt-0" : "";
                            return <li key={i} className={`order-tooltip-section ${first_class}`}>{tooltip}</li>;
                        })}
                    </ul>
                );

                // select severity
                const insight_severity = insight_duration >= 2.0 ? 2 : 1;
                // add to the gantt
                Insights.push(
                    <ConnectedInsightsImage
                        xlinkHref={`/img/gantt_downtime_0${insight_severity}.svg`}
                        x={x0}
                        y={y0 + 1}
                        width={w_shift}
                        height={bar_height}
                        ts={(new Date(insight.ts)).toISOString()}
                        is_ctrl_down={this.props.is_ctrl_down}
                        source_type={this.props.source_type}
                        onInsightClick={this.onInsightClick}
                        onMouseEnter={() => { onMouseEnterDispatch(order_uuid, { name: "gantt-downtime", order_uuid }) }}
                        tooltip_content={tooltip_content}
                    />
                );
            }
        }
        return Insights;
    }

    render() {
        const insights = this.getInsights();

        return <React.Fragment>
            <g id="patterns">
                <defs>
                    <InsightsCommonPatterns />
                </defs>
            </g>
            {insights}
        </React.Fragment>;
    }
}

export default connect(
    (state: ReduxState, props: Props): $Shape<Props> => {
        const show_valid_lines = state.gantt_chart_planning_table.show_valid_lines;
        const properties = state.gantt_chart_properties;
        const extra_lines = state.gantt_chart_report.extra_lines;
        const planning_table = state.gantt_chart_planning_table;
        const insights_mapped = state.gantt_chart_insights.insights_mapped;
        const filtered_insight_types = state.standalone_gantt.insight_types;
        const lines_orders = state.gantt_chart_lines_orders.lines_orders;
        const is_filter_locked = state.gantt_chart_filters.is_filter_locked;
        const report_data = state.gantt_chart_report.report_data;
        let from_shift_time = null;
        if (report_data) {
            from_shift_time = report_data.result.from_shift_time;
        }

        const materials = state.gantt_chart.materials;
        return {
            valid_orders_map: show_valid_lines.valid_orders_map,
            lines_orders,
            source_type: properties.source_type,
            extra_lines,
            is_ctrl_down: planning_table.is_ctrl_down,
            insights_mapped,
            filtered_insight_types,
            is_filter_locked,
            from_shift_time,
            materials
        };
    },
    (dispatch) => ({ reduxDispatch: dispatch })
)(Insights);

