// @flow

import React from "react";
import { IntlProvider, addLocaleData } from "react-intl";
import locale_en from "react-intl/locale-data/en";
import locale_sl from "react-intl/locale-data/sl";
import locale_de from "react-intl/locale-data/de";
import locale_cs from "react-intl/locale-data/cs";

import messages_sl from "../translations/sl.json";
import messages_en from "../translations/en.json";
import messages_de from "../translations/de.json";
import messages_cs from "../translations/cs.json";
import { loadFeatureMatrixAsync, getSysFlag } from "../lib/BusinessLogic";
import { subscribe, MSG_TYPES } from "../lib/PubSub";
import { getUserLang } from "../lib/Auth";

addLocaleData([...locale_en, ...locale_sl, ...locale_de, ...locale_cs]);

let masterLanguage = "en";
let masterMessages = messages_en;
let masterLocale = "en-US";

function setMasterLang(lang: string) {
    switch (lang) {
        case "sl":
            masterLanguage = "sl";
            masterMessages = messages_sl;
            masterLocale = "sl-SI";
            break;
        case "de":
            masterLanguage = "de";
            masterMessages = messages_de;
            masterLocale = "de-DE";
            break;
        case "cs":
            masterLanguage = "cs";
            masterMessages = messages_cs;
            masterLocale = "cs-CZ";
            break;
        default:
            masterLanguage = "en";
            masterMessages = messages_en;
            masterLocale = "en-US";
            break;
    }
    console.log("language: " + masterLanguage);
}

// set language to browser's language without region code
const browserLanguage = navigator.language.split(/[-_]/)[0];

const getBrowserLanguage = (): string | null => {
    if (typeof global.jest !== undefined) return "sl";

    if(["de", "cs", "en", "sl"].includes(browserLanguage)) {
        return browserLanguage;
    }
    return null;
}

// setMasterLang(browserLanguage);
setMasterLang(getBrowserLanguage() || "sl");

const Context: any = React.createContext(); // Context type is available in newer flow
Context.displayName = "Internationalization context";

type Props = {
    children: any
};

type State = {
    locale: string,
    messages: any,
    switchToCs: any,
    switchToDe: any,
    switchToEn: any,
    switchToSl: any
};

class IntlProviderWrapper extends React.Component<Props, State> {

    constructor() {
        super();
        // pass everything in state to avoid creating object inside render method (like explained in the documentation)
        this.state = {
            locale: browserLanguage,
            messages: messages_sl,
            switchToCs: this.switchToCs,
            switchToDe: this.switchToDe,
            switchToEn: this.switchToEn,
            switchToSl: this.switchToSl
        };

        subscribe(MSG_TYPES.user_profile_reloaded, async () => {
            this.setLang();
        });
        subscribe(MSG_TYPES.logout, async () => {
            this.setLang();
        });
    }

    // WARNING: This is never called - WHY?
    async componentDidMount() {
        await loadFeatureMatrixAsync();
        this.setLang();
    }

    setLang() {
        let lang = null;

        let user_lang = getUserLang();
        const local_lang = localStorage.getItem("user-lang");

        if (user_lang) {
            lang = user_lang.toLowerCase().trim();
        } else if (local_lang) {
            lang = local_lang;
        } else {
            lang = getBrowserLanguage() || getSysFlag("lang").toLowerCase().trim();
        }

        console.log("Setting language based on user/server setting: " + lang);
        if (lang === "si" || lang === "sl") {
            this.switchToSl();
        } else if (lang === "de") {
            this.switchToDe();
        } else if (lang === "cs" || lang === "cz") {
            this.switchToCs();
        } else {
            this.switchToEn();
        }

        localStorage.setItem("user-lang", lang);
    }

    switchToEn() {
        setMasterLang("en");
        this.setState({ locale: masterLanguage, messages: masterMessages });
    }

    switchToDe() {
        setMasterLang("de");
        this.setState({ locale: masterLanguage, messages: masterMessages });
    }

    switchToCs() {
        setMasterLang("cs");
        this.setState({ locale: masterLanguage, messages: masterMessages });
    }

    switchToSl() {
        setMasterLang("sl");
        this.setState({ locale: masterLanguage, messages: masterMessages });
    }

    render() {
        const { children } = this.props;
        const { locale, messages } = this.state;
        return (
            <Context.Provider value={this.state}>
                <IntlProvider
                    key={locale}
                    locale={locale}
                    messages={messages}
                    defaultLocale="sl"
                >
                    {children}
                </IntlProvider>
            </Context.Provider>
        );
    }
}

function translate(code: string, default_msg?: string): string {
    return masterMessages[code] || default_msg || "<UNKNOWN>";
}

function getLang() { return masterLanguage; }
function getLocale() { return masterLocale; }

function hasTranslation(code: string): boolean {
    return masterMessages[code] !== undefined;
}

export { IntlProviderWrapper, Context as IntlContext, translate, getLang, getLocale, hasTranslation };
