// @flow

// main imports
import PeopleCreate from "./PeopleCreate";
import ReactRouterPropTypes from "react-router-prop-types";

// backend
import { getBackend as getBackend2 } from "../../lib/backend/Backend2";
import * as t from "../../lib/backend/manufacturing2.generated.types";
import { ILabelValuePair, ILabelValuePairEx } from "../../lib/Util";
import { PEOPLE_TAGS, PEOPLE_TAGS_DEFAULT } from "../../lib/ManufacturingTags.generated";
import { translate } from "../IntlProviderWrapper";
import { parseUrlQueryVariables, shiftNumber } from "../../lib/Util";

// defining types
type Props = {
    match: ReactRouterPropTypes.match,
    history: ReactRouterPropTypes.history,
    location: ReactRouterPropTypes.location
};

type State = {
    uuid: string,
    name: string,
    external_id: string,
    active: boolean,
    adjuster: boolean,
    ted_id: string,
    shift_preference: boolean[],
    componentTitle: string,
    behaviours: any[],
    error: string,
    warning: string,
    success: string,
    editFlag: boolean,
    tags: t.ITags,
    plants: ILabelValuePair[],
    line_groups: ILabelValuePairEx[],
    plants_choice: t.IPlantData[],
    has_enabled_future_shifts: boolean
};

class PeopleEdit extends PeopleCreate {

    constructor(props: Props, state: State) {
        super(props, state);
        this.state.componentTitle = translate("Manufacturing.People.edit_person_details", "Edit Person Details");
        // disables editing of UUID and NAME
        this.setState({editFlag: true });
    }

    /**
     * Loading person data.
     */
    async componentDidMount() {
        try {
            const res = await getBackend2().manufacturing.getPlants({});
            const plants = res.plants;
            this.setState({ plants_choice: plants });
            let plants_hash: Map<string, string> = new Map();

            // create hash table of plants
            plants.forEach(plant => plants_hash.set(plant.uuid, plant.title));

            const { people } = await getBackend2().manufacturing.getPeople({ uuid: this.props.match.params.uuid });
            if (people.length === 0) {
                throw new Error("Person not found: " + this.props.match.params.uuid);
            }
            const person = people[0];

            // compile plants
            let plants_person: ILabelValuePair[] = person.plant_uuids.map(plant_uuid => ({
                value: plant_uuid,
                label: plants_hash.get(plant_uuid) || ""
            }));

            // check if we have expected tags, if not add default values
            if (person.tags[PEOPLE_TAGS.reduced_worktime] === undefined) {
                person.tags[PEOPLE_TAGS.reduced_worktime] = PEOPLE_TAGS_DEFAULT.reduced_worktime;
            }

            const line_groups = this.getLineGroupsForDropdown(plants);
            const line_group = line_groups.find(lg => lg.value === person.ted_id);

            let has_enabled_future_shifts = false;

            if (line_group) {
                const res_lines = await getBackend2().manufacturing.getLines({ line_group_uuids: [line_group.uuid] });
                const lines = res_lines.lines;

                has_enabled_future_shifts = await this.hasEnabledFutureShifts(person.uuid, lines.map(l => l.uuid));
            }

            this.setState({
                uuid: person.uuid,
                name: person.name,
                external_id: person.external_id,
                active: person.active,
                adjuster: person.adjuster,
                plants: plants_person,
                ted_id: person.ted_id,
                shift_preference: person.shift_preference,
                tags: person.tags,
                line_groups,
                has_enabled_future_shifts
            });
        } catch (err) {
            this.setState({ error: err.message });
        }
    }

    async hasEnabledFutureShifts(person_uuid: string, line_uuids: string[]) {
        const from_shift_tag = shiftNumber(new Date()).shift_tag;

        const future_day = new Date();
        future_day.setMonth(future_day.getMonth() + 3);
        const to_shift_tag = shiftNumber(future_day).shift_tag;

        const res = await getBackend2().manufacturing.getPeopleShiftsSimple({
            from_shift: from_shift_tag,
            to_shift: to_shift_tag,
            line_uuids: line_uuids,
            person_uuid: person_uuid
        });

        const people_shifts = res.shifts_people;
        return !!people_shifts.find(el => el.enabled);
    }

    redirectBack() {
        const params = parseUrlQueryVariables(this.props.location.search);
        if (params.back && params.back === "view") {
            this.props.history.push(`/digital-twin/resources/person/view/${this.state.uuid}`);
        } else {
            this.props.history.push(`/digital-twin/resources/people/${this.state.uuid}`);
        }
    }

    async handleSubmit() {
        if (this.isValidForm()) {
            try {
                const plant_uuids = this.state.plants.map(plant => plant.value);
                await getBackend2().manufacturing.updatePerson({
                    active: this.state.active,
                    adjuster: this.state.adjuster,
                    external_id: this.state.external_id,
                    name: this.state.name,
                    plant_uuids,
                    shift_preference: this.state.shift_preference,
                    ted_id: this.state.ted_id,
                    tags: this.state.tags,
                    uuid: this.state.uuid,
                });
                this.redirectBack();
            } catch (err) {
                this.setState({ error: err.message });
            }
        }
    }

    handleCancel() {
        this.redirectBack();
    }

}

export default PeopleEdit;
