// @flow
import {
    RESET_GANTT,
    SET_LOCK_FILTER,
    SET_SELECTED_STOCK_REQUIREMENT,
    SET_RESCHEDULE_ORDER_UUID,
    SET_RESCHEDULE_LINE_UUID,
    SET_RESCHEDULE_ORDER_BUNDLE
} from "../common";
import { TypeFilterValues } from "../../lineOrderList/filters/TypeFilterConsts";
import * as BusinessLogic from "../../../../../lib/BusinessLogic";
import { deepClone } from "../../../../../lib/Util";

import type {
    ResetGanttAction,
    SetLockFilter,
    SetSelectedStockRequirement,
    SetRescheduleLineAction,
    SetRescheduleOrderAction,
    SetRescheduleOrderBundleAction
} from "../common";
import type { PayloadAction } from "../../../../redux/types";

export type SELECTED_FILTER_TYPES = $Keys<typeof TypeFilterValues>;

export const SET_SELECTED_LINE_UUIDS: "set_selected_line_uuids" = "set_selected_line_uuids";
export const SET_SELECTED_MATERIALS: "set_selected_materials" = "set_selected_materials";
export const SET_ORDER_EXTERNAL_IDS: "order_external_ids" = "order_external_ids";
export const SET_START_TIME: "set_start_time" = "set_start_time";
export const SET_INSIGHT_TYPE: "set_insight_type" = "set_insight_type";

export const RESET_FILTERS: "reset_filters" = "reset_filters";
export const SET_SELECTED_FILTER_TYPE: "set_selected_filter_type" = "set_selected_filter_type";
export const SET_SELECTED_ORDER_EXTERNAL_IDS: "set_selected_order_external_ids" = "set_selected_order_external_ids";
export const SET_SELECTED_ORDER_TAG_VALS: "set_selected_order_tag_vals" = "set_selected_order_tag_vals";
export const SET_SELECTED_MATERIAL_TAG_VALS: "set_selected_material_tag_vals" = "set_selected_material_tag_vals";


export type SelectedOrderTagData = {
    selected_order_tag: string,
    selected_order_tag_values: string[]
}

export type SelectedMterialTagData = {
    selected_material_tag: string,
    selected_material_tag_values: string[]
}

export type SetSelectedLineUuidsAction = PayloadAction<typeof SET_SELECTED_LINE_UUIDS, string[]>;
export type SetSelectedMaterialAction = PayloadAction<typeof SET_SELECTED_MATERIALS, string[]>;
export type SetOrderExternalIdsAction = PayloadAction<typeof SET_ORDER_EXTERNAL_IDS, string[]>;
export type SetStartTimeAction = PayloadAction<typeof SET_START_TIME, number | null>;
export type SetInsightTypeAction = PayloadAction<typeof SET_INSIGHT_TYPE, string | null>;
export type ResetFiltersAction = PayloadAction<typeof RESET_FILTERS, typeof undefined>;
export type SetSelectedFilterType = PayloadAction<typeof SET_SELECTED_FILTER_TYPE, SELECTED_FILTER_TYPES | null>;
export type SetSelectedOrderUuids = PayloadAction<typeof SET_SELECTED_ORDER_EXTERNAL_IDS, string[]>;
export type SetSelectedOrderTagValsAction = PayloadAction<typeof SET_SELECTED_ORDER_TAG_VALS, SelectedOrderTagData>;
export type SetSelectedMaterialTagValsAction = PayloadAction<typeof SET_SELECTED_MATERIAL_TAG_VALS, SelectedMterialTagData>;

export type PlanningTableFiltersState = {
    // debugging
    is_dev_env: boolean,

    // healty part
    selected_filter_type: SELECTED_FILTER_TYPES | null,
    is_filter_locked: boolean,
    reschedule_line_uuid: string | null,
    reschedule_order_uuid: string | null,
    selected_line_uuids: string[],
    selected_order_external_ids: string[],
    selected_materials: string[],
    selected_order_tag: string | null,
    selected_order_tag_values: string[],
    selected_material_tag: string | null,
    selected_material_tag_values: string[],
    selected_insight_type: string | null,

    // old part
    order_external_ids: string[],
    order_start_time: number | null
}


export const filters_initial_state: PlanningTableFiltersState = {
    // debugging
    is_dev_env: (BusinessLogic.getSysFlag("env") === "dev"),

    // healty part
    selected_filter_type: null,
    is_filter_locked: false,
    reschedule_line_uuid: null,
    reschedule_order_uuid: null,
    selected_line_uuids: [],
    selected_order_external_ids: [],
    selected_materials: [],
    selected_order_tag: null,
    selected_order_tag_values: [],
    selected_material_tag: null,
    selected_material_tag_values: [],
    selected_insight_type: null,

    // old part
    order_external_ids: [],
    order_start_time: null
};

export type PlanningTableFiltersActionTypes = (
    SetSelectedLineUuidsAction | SetSelectedMaterialAction | SetOrderExternalIdsAction |
    SetStartTimeAction | SetRescheduleLineAction |
    SetInsightTypeAction | ResetFiltersAction | SetRescheduleOrderAction |
    ResetGanttAction | SetLockFilter | SetSelectedFilterType | SetSelectedOrderUuids |
    SetSelectedStockRequirement | SetSelectedOrderTagValsAction | SetRescheduleOrderBundleAction
);

const getInitialState = (state: PlanningTableFiltersState): PlanningTableFiltersState => {
    return deepClone({
        ...filters_initial_state,
        is_filter_locked: state ? state.is_filter_locked : false
    });
}

export const planningTableFiltersReducer = (
    state: PlanningTableFiltersState,
    action: PlanningTableFiltersActionTypes
): PlanningTableFiltersState => {
    if (!state) {
        state = getInitialState(state);
    }
    let new_state = state;

    if (state.is_dev_env) {
        console.log("Redux type dispatch", action.type);
    }

    if (action.type === RESET_GANTT) {
        return getInitialState(state);
    } else if (action.type === RESET_FILTERS) {
        return getInitialState(state);
    } else if (action.type === SET_SELECTED_LINE_UUIDS) {
        const selected_filter_type = new_state.selected_filter_type;
        new_state = getInitialState(state);
        new_state.selected_filter_type = selected_filter_type;
        new_state.selected_line_uuids = action.data;
        new_state.selected_filter_type = TypeFilterValues.line;
        return { ...new_state };
    } else if (action.type === SET_SELECTED_MATERIALS) {
        new_state = getInitialState(state);
        new_state.selected_filter_type = TypeFilterValues.material;
        new_state.selected_materials = action.data;
        return { ...new_state };
    } else if (action.type === SET_RESCHEDULE_LINE_UUID) {
        new_state = getInitialState(state);
        new_state.selected_filter_type = TypeFilterValues.reschedule_line;
        new_state.reschedule_line_uuid = action.data;
        return { ...new_state };
    } else if (action.type === SET_RESCHEDULE_ORDER_UUID) {
        new_state = getInitialState(state);
        new_state.selected_filter_type = TypeFilterValues.reschedule_operation;
        new_state.reschedule_order_uuid = action.data;
        return { ...new_state };
    } else if (action.type === SET_RESCHEDULE_ORDER_BUNDLE) {
        const state = new_state;
        const is_filter_locked = action.data.is_filter_locked;
        if (!is_filter_locked) {
            state.reschedule_order_uuid = action.data.order_uuid;
            state.selected_filter_type = TypeFilterValues.reschedule_operation;
            state.reschedule_line_uuid = null;
        }
        state.is_filter_locked = is_filter_locked;
        if (JSON.stringify(state) !== JSON.stringify(new_state)) {
            return {...state};
        }
        return new_state;
    } else if (action.type === SET_ORDER_EXTERNAL_IDS) {
        new_state = getInitialState(state);
        new_state.order_external_ids = action.data;
        return {...new_state};
    } else if (action.type === SET_START_TIME) {
        new_state.order_start_time = action.data;
        return {...new_state};
    } else if (action.type === SET_INSIGHT_TYPE) {
        new_state.selected_insight_type = action.data;
        return {...new_state};
    } else if (action.type === SET_LOCK_FILTER) {
        return { ...new_state, is_filter_locked: action.data };
    } else if (action.type === SET_SELECTED_FILTER_TYPE) {
        new_state = getInitialState(state);
        return { ...new_state, selected_filter_type: action.data };
    } else if (action.type === SET_SELECTED_ORDER_EXTERNAL_IDS) {
        new_state.selected_filter_type = TypeFilterValues.order;
        return { ...new_state, selected_order_external_ids: action.data };
    } else if (action.type === SET_SELECTED_STOCK_REQUIREMENT) {
        new_state = getInitialState(state);
        return { ...new_state };
    } else if (action.type === SET_SELECTED_ORDER_TAG_VALS) {
        new_state = getInitialState(state);
        new_state.selected_order_tag = action.data.selected_order_tag;
        new_state.selected_order_tag_values = action.data.selected_order_tag_values;
        new_state.selected_filter_type = TypeFilterValues.order_tag_values;
        return { ...new_state };
    } else if (action.type === SET_SELECTED_MATERIAL_TAG_VALS) {
        new_state = getInitialState(state);
        new_state.selected_material_tag = action.data.selected_material_tag;
        new_state.selected_material_tag_values = action.data.selected_material_tag_values;
        new_state.selected_filter_type = TypeFilterValues.material_tag_values;
        return { ...new_state };
    }

    return new_state;
}

