// @flow
import React, { Component } from "react";

import * as BusinessLogic from "../../lib/BusinessLogic";
import { translate } from "../IntlProviderWrapper";
import * as mt from "../../lib/backend/manufacturing2.generated.types";
import * as rt from "../../lib/backend/reports.generated.types";
import { getBackend } from "../../lib/backend/Backend2";
import { TIME_RANGES } from "../../lib/Util";

import KpiBox from "../KpiBox";
import ErrorComponent from "../ErrorComponent";

// defining types
type Props = {
    plant_uuid: string,
    line_group_uuid: string
}

type State = {
    plant_kpis: rt.IKpiResult | null,
    plant: mt.IPlantData | null,
    line_group_kpis: rt.IKpiResult | null,
    line_group: mt.ILineGroupData | null,
    quantiles: number[] | null
}

class PlantStatistics extends Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            plant_kpis: null,
            plant: null,
            line_group_kpis: null,
            line_group: null,
            quantiles: null
        };
    }

    componentDidMount() {
        this.loadKpis();
    }

    componentDidUpdate(prev_props: Props) {
        if (prev_props.plant_uuid !== this.props.plant_uuid ||
            prev_props.line_group_uuid !== this.props.line_group_uuid) {
            this.loadKpis();
        }
    }

    async loadKpis() {
        // find the selected plant and line group
        const plant = BusinessLogic.getPlantsForUser().find(p => p.uuid === this.props.plant_uuid);
        const line_group = BusinessLogic.getLineGroupForUser(this.props.line_group_uuid);

        // load latest
        try {
            const {
                plant_kpis,
                line_group_kpis,
                quantiles
            } = await getBackend().reports.latestKpis({
                plant_uuid: this.props.plant_uuid,
                line_group_uuid: this.props.line_group_uuid
            });

            this.setState({
                plant_kpis,
                plant,
                line_group_kpis,
                line_group,
                quantiles
            });
        } catch (e) {
            console.log("error", e);
        }
    }

    renderDowntime(kpis: rt.IKpiResult) {
        if (kpis === null || !kpis.downtime) { return null; }

        const downtime_days = (kpis.downtime.range.end - kpis.downtime.range.start) / TIME_RANGES.DAY;

        return (
            <div className="col-lg-3 col-md-6 px-2 pb-3">
                <KpiBox
                    label={translate("common.downtimes", "Downtimes")}
                    note={translate("Manufacturing.HomeView.kpi.past_7_days", "past {{DAYS}} days").replace("{{DAYS}}", `${downtime_days}`)}
                    items={[
                        {
                            value: Math.round(kpis.downtime.org_downtime_hours),
                            unit: "h",
                            comment: translate("Manufacturing.HomeView.kpi.organizational", "Organizational")
                        },
                        {
                            value: Math.round(kpis.downtime.tech_downtime_hours),
                            unit: "h",
                            comment: translate("Manufacturing.HomeView.kpi.technical", "Technical")
                        }
                    ]}
                />
            </div>
        );
    }

    renderWorkforce(kpis: rt.IKpiResult) {
        if (kpis === null || !kpis.workforce) { return null; }

        const workforce_days = (kpis.workforce.range.end - kpis.workforce.range.start) / TIME_RANGES.DAY;
        const people = translate("Manufacturing.HomeView.kpi.people", "people");

        return (
            <div className="col-lg-3 col-md-6 px-2 pb-3">
                <KpiBox
                    label={translate("Manufacturing.HomeView.kpi.workforce", "Workforce")}
                    note={translate("Manufacturing.HomeView.kpi.next_7_days", "next {{DAYS}} days").replace("{{DAYS}}", `${workforce_days}`)}
                    items={[
                        {
                            value: Math.round(kpis.workforce.required_hours),
                            unit: `h (${Math.round(kpis.workforce.required_persons * 10.0) / 10.0} ${people})`,
                            comment: translate("Manufacturing.HomeView.kpi.required_people", "Required people")
                        },
                        {
                            value: Math.round(kpis.workforce.scheduled_hours),
                            unit: `h (${Math.round(kpis.workforce.scheduled_persons * 10.0) / 10.0} ${people})`,
                            comment: translate("Manufacturing.HomeView.kpi.scheduled_people", "Scheduled people")
                        }
                    ]}
                />
            </div>
        );
    }

    renderEfficiency(kpis: rt.IKpiResult) {
        if (kpis === null || !kpis.efficiency) { return null; }

        const efficiency_days = (kpis.efficiency.range.end - kpis.efficiency.range.start) / TIME_RANGES.DAY;
        const oee_expected = kpis.efficiency.OEE.length > 2 ? Math.round(100.0 * kpis.efficiency.OEE[1] * 10.0) / 10.0 : 0.0;
        const oee_deviation = kpis.efficiency.OEE.length > 2 ? Math.round(100.0 * Math.abs(kpis.efficiency.OEE[2] - kpis.efficiency.OEE[0]) * 10.0) / 10.0 : 0.0;
        const pee_expected = kpis.efficiency.PEE.length > 2 ? Math.round(100.0 * kpis.efficiency.PEE[1] * 10.0) / 10.0 : 0.0;
        const pee_deviation = kpis.efficiency.PEE.length > 2 ? Math.round(100.0 * Math.abs(kpis.efficiency.PEE[2] - kpis.efficiency.PEE[0]) * 10.0) / 10.0 : 0.0;

        return (
            <div className="col-lg-3 col-md-6 px-2 pb-3">
                <KpiBox
                    label={translate("Manufacturing.HomeView.kpi.equipment_utilization", "Equipment utilization")}
                    note={translate("Manufacturing.HomeView.kpi.next_7_days", "next {{DAYS}} days").replace("{{DAYS}}", `${efficiency_days}`)}
                    items={[
                        {
                            value: oee_expected,
                            unit: `% ± ${oee_deviation}`,
                            comment: "OEE"
                        },
                        {
                            value: pee_expected,
                            unit: `% ± ${pee_deviation}`,
                            comment: "PEE"
                        }
                    ]}
                />
            </div>
        );
    }

    renderDelays(kpis: rt.IKpiResult) {
        if (kpis === null || !kpis.delays) { return null; }

        const delays_days = (kpis.delays.range.end - kpis.delays.range.start) / TIME_RANGES.DAY;
        const delay_hours_expected = kpis.delays.delay_hours.length > 1 ? Math.round(kpis.delays.delay_hours[1] * 10.0) / 10.0 : 0.0;
        const delay_shifts_expected = Math.round(delay_hours_expected / 8.0 * 10.0) / 10.0;

        return (
            <div className="col-lg-3 col-md-6 px-2 pb-3">
                <KpiBox
                    label={translate("Manufacturing.HomeView.kpi.delays", "Delays")}
                    note={translate("Manufacturing.HomeView.kpi.next_7_days", "next {{DAYS}} days").replace("{{DAYS}}", `${delays_days}`)}
                    items={[

                        {
                            value: delay_hours_expected,
                            unit: "h",
                            comment: translate("Manufacturing.HomeView.kpi.delay_hours_expected", "Time required to catch up")
                        },
                        {
                            value: delay_shifts_expected,
                            unit: translate("common.shifts", "shifts"),
                            comment: translate("Manufacturing.HomeView.kpi.delay_hours_expected", "Extra shifts required to catch up")
                        }
                    ]}

                />
            </div>
        );
    }

    renderMaterialCoverage(kpis: rt.IKpiResult) {
        if (kpis === null || !kpis.material_coverage) { return null; }

        const material_coverage_days = (kpis.material_coverage.range.end - kpis.material_coverage.range.start) / TIME_RANGES.DAY;
        const coverage_work_orders_percent = Math.round(100.0 * kpis.material_coverage.coverage_work_orders_percent * 10.0) / 10.0;
        const no_coverage_work_orders = kpis.material_coverage.no_coverage_work_orders;

        return (
            <div className="col-lg-3 col-md-6 px-2 pb-3">
                <KpiBox
                    label={translate("Manufacturing.HomeView.kpi.material_coverage", "Material coverage")}
                    note={translate("Manufacturing.HomeView.kpi.next_7_days", "next {{DAYS}} days").replace("{{DAYS}}", `${material_coverage_days}`)}
                    items={[
                        {
                            value: coverage_work_orders_percent,
                            unit: "%",
                            comment: translate("Manufacturing.HomeView.kpi.coverage_work_orders_percent", "Orders with sufficient material")
                        },
                        {
                            value: no_coverage_work_orders,
                            unit: "",
                            comment: translate("Manufacturing.HomeView.kpi.no_coverage_work_orders", "Number of orders with missing material")
                        }
                    ]}
                />
            </div>
        );
    }

    renderWorkforceSchedule(kpis: rt.IKpiResult) {
        if (kpis === null || !kpis.workforce) { return null; }

        const workforce_days = (kpis.workforce.range.end - kpis.workforce.range.start) / TIME_RANGES.DAY;

        return (
            <div className="col-lg-3 col-md-6 px-2 pb-3">
                <KpiBox
                    label={translate("Header.menu.shiftboard", "Shift-board")}
                    note={translate("Manufacturing.HomeView.kpi.next_7_days", "next {{DAYS}} days").replace("{{DAYS}}", `${workforce_days}`)}
                    items={[
                        {
                            value: Math.round(100.0 * kpis.workforce.required_also_scheduled_percent * 10.0) / 10.0,
                            unit: "%",
                            comment: translate("Manufacturing.HomeView.kpi.required_also_scheduled_percent", "Planned work with scheduled workers")
                        },
                        {
                            value: Math.round(kpis.workforce.scheduled_no_work_shifts),
                            unit: "",
                            comment: translate("Manufacturing.HomeView.kpi.scheduled_no_work_shifts", "Scheduled shifts with no planned work")
                        }
                    ]}
                />
            </div>
        );
    }

    renderToolsetup(kpis: rt.IKpiResult) {
        if (kpis === null || !kpis.toolsetup) { return null; }

        const toolsetup_days = (kpis.workforce.range.end - kpis.workforce.range.start) / TIME_RANGES.DAY;

        return (
            <div className="col-lg-3 col-md-6 px-2 pb-3">
                <KpiBox
                    label={translate("Manufacturing.ToolsView.tool_change", "Tool changes")}
                    note={translate("Manufacturing.HomeView.kpi.next_7_days", "next {{DAYS}} days").replace("{{DAYS}}", `${toolsetup_days}`)}
                    items={[
                        {
                            value: Math.round(kpis.toolsetup.toolsetup_count),
                            unit: "",
                            comment: translate("Manufacturing.HomeView.kpi.toolsetup_count", "Number of tool changes")
                        },
                        {
                            value: Math.round(kpis.toolsetup.toolsetup_hours * 10.0) / 10.0,
                            unit: "h",
                            comment: translate("Manufacturing.HomeView.kpi.toolsetup_hours", "Total tool change-over time")
                        }
                    ]}
                />
            </div>
        );
    }

    renderKpis(kpis: rt.IKpiResult) {
        return (
            <div className="row mx-n2" key="xyz126">
                {this.renderDowntime(kpis)}
                {this.renderWorkforce(kpis)}
                {this.renderEfficiency(kpis)}
                {this.renderDelays(kpis)}
                {this.renderMaterialCoverage(kpis)}
                {this.renderWorkforceSchedule(kpis)}
                {this.renderToolsetup(kpis)}
            </div>
        );
    }

    render() {
        const plant_title = this.state.plant ? this.state.plant.title : "";
        const plant_kpis = this.state.plant_kpis;

        if (plant_kpis === null) {
            return <section id="statistics" key="1">
                <ErrorComponent msg={translate("common.kpis_not_found", "Plant KPIs not found")} type={"error"}/>
            </section>;
        }

        const line_group_title = this.state.line_group ? this.state.line_group.title : "";
        const line_group_kpis = this.state.line_group_kpis;

        return (
            <section id="statistics" key="1">
                <div className="container">
                    <h3>{translate("common.plant", "Plant")}: {plant_title}</h3>
                    <h2>{translate("Manufacturing.HomeView.forecast", "Forecast")}</h2>
                    {plant_kpis && this.renderKpis(plant_kpis)}
                    <h3>{line_group_title}</h3>
                    {line_group_kpis && this.renderKpis(line_group_kpis)}
                </div>
            </section>);
    }
}

export default PlantStatistics;
