// @flow
import * as t from "../../../lib/backend/manufacturing2.generated.types";
import * as rt from "../../../lib/SimulationReportModels";
import { PropertiesLogic } from "./reducers/BusinessLogic";
import { ReportLogic } from "./reducers/BusinessLogic";
import { RESET_PLAN_UUID } from "./reducers/linesOrders";

// ReportActionsTypes, InsightsActionsTypes
import type { LineOrders, ResetPlanUuid, IgnoreOrdersMap } from "./reducers/linesOrders";
import type { ReduxState } from "./reducers/reducers";
import type { PropertiesState } from "./reducers/properties";
import type { SimulationActionsTypes } from "./reducers/BusinessLogic";
import type { ReportParameters } from "./reducers/common";
import type { RecommendPlan } from "./reducers/recommendPlan";

type Dispatch = (args: SimulationActionsTypes | ResetPlanUuid) => void;

type Props = {
    report_version_type: "initial" | "current" | "version",
    lines_orders: LineOrders[],
    unscheduled_orders: LineOrders[],
    properties: PropertiesState,
    plant_uuid: string | null,
    line_group_uuid: string | null,
    report_version_title: string | null,
    is_loading: boolean,
    current_report_uuid: string | null,
    original_report_uuid: string | null,
    extra_lines: t.IExtraLines[] | null,
    recommend_plans: RecommendPlan[],
    recommend_plan_uuid: string | null,
    report_data: rt.IReportModelEx | null,
    ignored_orders: LineOrders[],
    ignore_orders_status: IgnoreOrdersMap | null,
    selected_insight_types: string[]
};

export default class Recalculate {

    reduxDispatch = null;
    store = null;
    prev_props: Props | null = null;

    constructor(reduxDispatch: Dispatch, store: any) {
        this.reduxDispatch = reduxDispatch;
        this.store = store;
        this.store.subscribe(this.handleStoreChange)
    }

    getProps = (): Props | null => {
        if (!this.store) return null;
        const state: ReduxState = this.store.getState();
        const planning_table = state.gantt_chart_planning_table;
        const properties = state.gantt_chart_properties;
        const lines_orders = state.gantt_chart_lines_orders;
        const report = state.gantt_chart_report;
        const recommend_plans = state.gantt_chart_recommend_plans.recommend_plans;
        const insight_types = state.standalone_gantt.insight_types;

        return {
            properties: properties,
            lines_orders: lines_orders.lines_orders,
            report_version_type: planning_table.report_version_type,
            report_version_title: planning_table.report_version_title,
            is_loading:  lines_orders.loading || report.report_loading,
            original_report_uuid: report.original_report_uuid,
            current_report_uuid: report.current_report_uuid,
            extra_lines: report.extra_lines,
            recommend_plan_uuid: lines_orders.plan_uuid,
            recommend_plans,
            report_data: report.report_data,
            unscheduled_orders: lines_orders.unscheduled_orders,
            ignored_orders: lines_orders.ignored_orders,
            ignore_orders_status: lines_orders.ignore_orders_status,
            selected_insight_types: insight_types,
            line_group_uuid: properties.line_group_uuid,
            plant_uuid: localStorage.getItem("last-plant") || null
        }
    }

    handleStoreChange = () => {
        const props = this.getProps();

        if (props && this.prev_props && this.prev_props.lines_orders !== props.lines_orders && props.lines_orders.length > 0) {
            this.prev_props = props;
            // recommend_plan_uuid is used to connect recommend plan line orders and report uuid
            const recommend_plan_uuid = props.recommend_plan_uuid;
            this.handleRecalculate(
                props.lines_orders,
                recommend_plan_uuid,
                props.unscheduled_orders,
                props.ignored_orders
            );
            if (recommend_plan_uuid && this.reduxDispatch) {
                // we need to reset as from this point on if a user
                // makes a change that initiates a generation of new report and we
                // couldn't differentiate between recommend plan and user changed report
                this.reduxDispatch({ type: RESET_PLAN_UUID, data: undefined });
            }
            return;
        }
        if (this.prev_props !== props) {
            this.prev_props = props;
        }
    }

    handleRecalculate = async (
        lines_orders: LineOrders[],
        recommend_plan_uuid: ?string | null,
        unscheduled_orders: LineOrders[],
        ignored_orders: LineOrders[]
    ) => {
        const props = this.getProps();
        const dispatch = this.reduxDispatch;
        if (!props || !dispatch || !props.plant_uuid) {
            return;
        }

        if (!props.properties.line_group_uuid) return;
        let prev_report_uuid = null;
        if (props.original_report_uuid !== props.current_report_uuid && props.current_report_uuid) {
            prev_report_uuid = props.current_report_uuid;
        }
        const line_group_uuid = props.properties.line_group_uuid;

        const unscheduled_lines_map: Map<string, LineOrders> = new Map();
        for (const unscheduled_line of unscheduled_orders) {
            unscheduled_lines_map.set(unscheduled_line.line_uuid, unscheduled_line);
        }

        for (const ignored_line of ignored_orders) {
            const line_uuid = ignored_line.line_uuid;
            const unscheduled_line = unscheduled_lines_map.get(line_uuid);
            if (unscheduled_line !== undefined) {
                unscheduled_lines_map.set(line_uuid, {
                    ...unscheduled_line,
                    orders: [...unscheduled_line.orders, ...ignored_line.orders]
                });
            } else {
                unscheduled_lines_map.set(line_uuid, ignored_line);
            }
        }

        const parameters: ReportParameters = {
            type: props.report_version_type,
            prev_report_uuid,
            recommend_plan_uuid,
            selected_insight_types: props.selected_insight_types,
            config: {
                plant_uuid: props.plant_uuid,
                line_group_uuid: line_group_uuid,
                week: PropertiesLogic.getWeek(props.properties),
                year: PropertiesLogic.getYear(props.properties),
                lines_orders: lines_orders,
                unscheduled_orders: [...unscheduled_lines_map.values()],
                title: props.report_version_title || "",
                project_parallel_orders: false,
                skip_stock: props.properties.skip_stock,
                ignore_orders_status: props.ignore_orders_status
            }
        }

        if (props.extra_lines && props.extra_lines.length > 0) {
            parameters.extra_lines = props.extra_lines;
        }

        ReportLogic.quickSimulationAction(
            parameters,
            dispatch,
            props.report_data
        );
    }

}

