// @flow
// $FlowFixMe
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { niceNumber, isValueAlmostZero } from "../../lib/Util";
import { SET_STOCK_FORECAST_TOOLTIP } from "../redux/reducers/stockForecastTooltipReducer";
import { translate } from "../IntlProviderWrapper";
import Tooltip from "../Tooltip";
import MaterialStockForecastTooltip from "./MaterialStockForecastTooltip";

import * as t from "../../lib/backend/manufacturing2.generated.types";

type Props = {
    current_value: number,
    prev_value: ?number,
    hide_details: boolean,
    hide_arrows: boolean,
    cellProps?: Object,
    getForecast?: Function,
    cell_data: Object,
    forecast_cell_css: string,
    report_id?: string
}

const getArrowImage = (dir: number) => {
    let img_src = "";

    switch (Math.sign(dir)) {
        //case 0: img_src = "/img/empty.svg"; break;
        case 0: img_src = null; break;
        case 1: img_src = "/img/arrow-up-stock.svg"; break;
        case -1: img_src = "/img/arrow-down-stock.svg"; break;
        default: break;
    }

    return img_src;
}

const MaterialStockForecastCell = (props: Props) => {
    const dispatch = useDispatch()
    const {
        current_value,
        cellProps = {},
        hide_arrows,
        getForecast,
        cell_data,
        report_id
    } = props;

    const [forecast, setForecast] = useState<t.IGetMaterialForecastForShiftsRes | null>(null);
    let forecast_cell_css = props.forecast_cell_css || "";

    const prev_value = props.prev_value || 0;
    const target = useRef<HTMLElement | null>(null);
    const isCombined = cell_data.selected_subtype === "combined";
    const has_tooltip = isCombined && getForecast !== undefined && report_id !== undefined;
    const openTooltip = async (e: Event) => {
        e.preventDefault();
        e.stopPropagation();
        if (has_tooltip) {
            dispatch({
                type: SET_STOCK_FORECAST_TOOLTIP,
                data: {
                    show_tooltip: true,
                    cell_data,
                    getForecast,
                    prev_value,
                    target_current: target.current,
                    report_id
                }
            });

            if (forecast === null && has_tooltip && getForecast) {
                const res = await getForecast(cell_data.material_external_id, cell_data.idx, report_id);
                setForecast(res);
            }
        }
    }

    let arrow_img = getArrowImage(hide_arrows ? 0 : Math.round(current_value) - (Math.round(prev_value)));

    let nonCummulative = [
        "produced_by_shift",
        "consumed_by_shift",
        "received_by_shift",
        "required_by_shift"
    ].includes(cell_data.selected_subtype);

    /*
        rules:
            cummulative - hide if current equals previous value
            noncummulative - hide if current equals zero
    */

    if ((nonCummulative && Math.round(current_value) === 0)) {
        return <td title={niceNumber(current_value, 0)} {...cellProps} className={forecast_cell_css}></td>;
    }

    let formatted_current_value = niceNumber(current_value, 0);

    if (!nonCummulative && prev_value === current_value) {
        const td = (
            <td
                {...cellProps}
                title={has_tooltip
                    ? translate("common.click_for_more", "Click for more info")
                    : formatted_current_value}
                className={forecast_cell_css}
                onClick={has_tooltip ? openTooltip : undefined}
            />
        );

        return has_tooltip ? (
            <Tooltip
                trigger="click"
                content_key={forecast === null ? "loading" : "loaded"}
                content={(
                    <MaterialStockForecastTooltip
                        cell_data={cell_data}
                        forecast={forecast}
                        prev_value={prev_value}
                    />
                )}
            >{td}</Tooltip>
        ) : td;
    }
    // Check if current value is so close to zero that it should be shown as '0' 
    if (arrow_img === null && isValueAlmostZero(current_value)) {
        arrow_img = "/img/icon_equal.svg";
        formatted_current_value = 0;
        forecast_cell_css = "text-right material-stock-prediction-cell stock_available";
    }


    if (isValueAlmostZero(current_value)) {
        formatted_current_value = 0;
        forecast_cell_css = "text-right material-stock-prediction-cell stock_available";
    }

    const td = (
        <td
            {...cellProps}
            title={has_tooltip ? translate("common.click_for_more", "Click for more info") : formatted_current_value}
            className={forecast_cell_css}
            onClick={has_tooltip ? openTooltip : undefined}
        >
            <span ref={target}> {formatted_current_value} </span>
            {!hide_arrows && arrow_img && <img src={arrow_img} alt="" />}
        </td>
    );
    return has_tooltip ? (
        <Tooltip
            trigger="click"
            content_key={forecast === null ? "loading" : "loaded"}
            content={(
                <MaterialStockForecastTooltip
                    cell_data={cell_data}
                    forecast={forecast}
                    prev_value={prev_value}
                />
            )}
        >{td}</Tooltip>
    ) : td;
}

export default MaterialStockForecastCell;
