// @flow

export const ORG_TAGS = {
    "name": "ORG_TAGS",
    "type": "tags",
    "comment": "Tags that are assignable to single organization",
    "generate_translations": true,
    "values": [
        {
            "name": "allowed_ip_ranges",
            "type": "string",
            "comment": "Allowed IP ranges in CIDR syntax, separated by ',' - e.g. '1.1.1.0/16,2.2.2.12/32'",
            "title": "Allowed IP ranges"
        },
        {
            "name": "require_twofa",
            "type": "boolean",
            "comment": "If true 2FA is required for all users of the organisation",
            "title": "2FA required"
        },
        {
            "name": "allow_pin_logins",
            "type": "boolean",
            "comment": "Are logins via PIN allowed",
            "title": "PIN allowed"
        },
        {
            "name": "allowed_ip_ranges_for_pin",
            "type": "string",
            "comment": "Allowed IP ranges for PIN logins in CIDR syntax, separated by ',' - e.g. '1.1.1.0/16,2.2.2.12/32'",
            "title": "Allowed IP ranges for PIN"
        }
    ]
};

export const USER_TAGS = {
    "name": "USER_TAGS",
    "type": "tags",
    "comment": "Tags that are assignable to single user",
    "values": [
        {
            "name": "kiosk",
            "type": "boolean",
            "comment": "Flag if this user is kiosk user",
            "title": "Kiosk"
        },
        {
            "name": "kiosk_screen",
            "type": "string",
            "comment": "Name of the page that this kiosk user is redirected to by default",
            "title": "Kiosk screen"
        },
        {
            "name": "twofa",
            "type": "boolean",
            "comment": "Flag if 2fa is enabled for this user",
            "title": "2FA",
            "default": false
        },
        {
            "name": "twofaSecret",
            "type": "string",
            "title": "2FA secret",
            "comment": "2FA secret"
        }
    ]
};

export const APP_TAGS = {
    "name": "APP_TAGS",
    "type": "tags",
    "comment": "Tags that are assignable to single app",
    "values": [
        {
            "name": "is_external",
            "type": "boolean",
            "comment": "Flag if this app is used for external integration"
        }
    ]
};

export const DASHBOARD_TAGS = {
    "name": "DASHBOARD_TAGS",
    "type": "tags",
    "comment": "Tags that are assignable to single dashboard",
    "values": [
        {
            "name": "code",
            "type": "string",
            "comment": "Code of the dashboard"
        },
        {
            "name": "subtype",
            "type": "string",
            "comment": "Subtype of the dashboard"
        },
        {
            "name": "type",
            "type": "string",
            "comment": "Type of the dashboard"
        }
    ]
};
