// @flow

import * as React from "react";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import type { TsObj, TsObjNull } from "../lib/Models";
import * as ta from "../lib/backend/alerts.generated.types";

// one point in graph
type DatapointHc = {
    x: number,
    y: number | null
};

// A band in the graph
type Band = {
    from: number,
    to: number,
    color: string
};

// display info for one event
type EventHc = {
    uuid: string,
    value: number,
    color: string,
    dashStyle: string,
    width: number
};

type State = {
    chartOptions: any
};

type Props = {
    options: {
        id: string,
        height: number,
        name: string,
        type: string,
        y_label: string | null,
        ts: Array<TsObj> | Array<TsObjNull>,
        y_max_val?: number | null,
        x_min_val?: number,
        x_max_val?: number,
        threshold?: number,
        color?: string,
        x_bands?: Array<Band>,
        y_bands?: Array<Band>
    },
    events?: Array<ta.IEventData>
};

class HighchartsLine extends React.Component<Props, State> {

    handleChartClick = () => {
        this.setState(prevState => {
            const chartOptions = Object.assign({}, prevState.chartOptions);
            const chart_types = ["line", "column", "scatter"];
            let chart_type_index = chart_types.indexOf(chartOptions.series[0].type);

            chart_type_index = ++chart_type_index % chart_types.length;

            chartOptions.series[0].type = chart_types[chart_type_index];
            return { chartOptions };
        });
    }

    preparseChartOptions = () => {
        // get datapointes for the series and prepare it for display
        let datapoints: Array<DatapointHc> = [ ];
        for (let value of this.props.options.ts) {
            datapoints.push({x: value.ts, y: value.val});
        }
        let series = {
            name: this.props.options.name,
            type: (this.state) ? this.state.chartOptions.series[0].type : this.props.options.type,
            data: datapoints,
            turboThreshold: 0,
            color: this.props.options.color || "#2186eb"
        };
        // get events passed via properties and store data needed for display
        let events: Array<EventHc> = [];
        if (this.props.events) {
            for (let i = 0; i < this.props.events.length; i++) {
                events.push({
                    uuid: this.props.events[i].uuid,
                    value: this.props.events[i].ts,
                    color: "#ff352b",
                    dashStyle: "longdashdot",
                    width: 2
                });
            }
        }

        Highcharts.setOptions({
            global: {
                useUTC: false
            }
        });

        // initialize chart
        const chart = {
            chart: {
                height: this.props.options.height,
                events: {
                    click: this.handleChartClick
                }
            },
            xAxis: {
                type: "datetime",
                plotLines: events,
                min: this.props.options.x_min_val,
                max: this.props.options.x_max_val,
                plotBands: this.props.options.x_bands
            },
            yAxis: {
                title: { text: this.props.options.y_label },
                max: this.props.options.y_max_val,
                plotBands: this.props.options.y_bands,
                plotLines: [{
                    value: this.props.options.threshold,
                    color: "gray",
                    dashStyle: "dot",
                    width: 2,
                    label: {
                        //text: "Threshold value: " + this.props.options.threshold
                    }
                }]
            },
            legend: { enabled: false },
            series: [ series ],
            title: { text: null },
            subtitle: { text: null }
        };

        this.setState({ chartOptions: chart });
    };

    componentWillMount() {
        this.preparseChartOptions();
    }

    componentDidUpdate(prevProps: Props) {
        if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) {
            this.preparseChartOptions();
        }
    }

    render() {
        return (
            <div>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={this.state.chartOptions}
                />
            </div>
        );
    }

}
export default HighchartsLine;
