// @flow

// main imports
import React, { Component } from "react";
import ReactRouterPropTypes from "react-router-prop-types";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

// models
import type { RegisterUserObj } from "../lib/Models";

// backend
import { getBackend as getBackend2 } from "../lib/backend/Backend2";
import * as Auth from "../lib/Auth";

// import subcomponents
import ErrorComponent from "./ErrorComponent";
import Toggle from "react-toggle";

// defining types
type Props = {
    match: ReactRouterPropTypes.match,
    history: ReactRouterPropTypes.history,
    location: ReactRouterPropTypes.location
};

type State = {
    active: boolean,
    title: string,
    username: string,
    password: string,
    password2: string,
    accept_terms: boolean,
    is_admin: boolean,
    is_power_user: boolean,
    is_demo_user: boolean,
    is_planner: boolean,
    is_plant_manager: boolean,
    is_shopfloor_manager: boolean,
    error: string,
    warning: string,
    providers: any[],
    // currently selected item of a dropdown menu
    selected_item: any,
    external_username: string,
    use_external: boolean
};

const oauthProviders = Auth.getOAuthProviders();

/**
 * Form to create new user.
 */
class UserNew extends Component<Props, State> {
    /**
     * Constructor.
     *
     * @param {Props} props Props object.
     * @param {State} state State object.
     */
    constructor(props: Props, state: State) {
        super(props);
        this.state = {
            active: true,
            title: "",
            username: "",
            password: "",
            password2: "",
            accept_terms: true,
            is_admin: false,
            is_power_user: false,
            is_demo_user: false,
            is_planner: false,
            is_plant_manager: false,
            is_shopfloor_manager: false,
            error: "",
            warning: "",
            providers: [],
            external_username: "",
            use_external: false,
            selected_item: null
        }
    }

    async componentDidMount() {
        try {
            const providers = await Auth.oauth2Providers();
            const items_available = oauthProviders
                .filter(x => providers.indexOf(x.value) >= 0 || x.value === "up");
            if (items_available.length > 0) {
                this.setState({ providers: items_available, selected_item: items_available[0] });
            } else {
                this.setState({ providers: items_available, selected_item: null });
            }
        } catch (err) {
            console.log(err);
        };
    };

    /**
     * Handling change of any input component.
     */
    handleInputChange = (event: Event) => {
        if (event.currentTarget instanceof HTMLInputElement || event.currentTarget instanceof HTMLSelectElement) {
            // read new values
            const target = event.currentTarget;
            let value = target.value;
            const name = target.name;

            // hack for toggle element
            const checkboxes = ["active", "is_admin", "is_power_user", "is_demo_user", "is_planner", "is_plant_manager", "is_shopfloor_manager"];
            if (checkboxes.indexOf(name) >= 0) {
                //$FlowFixMe
                value = target.checked;
            }

            // update new datasource placeholder
            this.setState(prev_state => {
                prev_state[name] = value;
                return prev_state;
            });
        }
    };

    setRole = async (user_uuid: string, role: string): Promise<void> => {
        await getBackend2().users.addRoleForUser({ id: user_uuid, role });
    };

    handleSubmit = async (event: Event) => {
        event.preventDefault();

        try {
            const username = this.state.username ? this.state.username.trim() : this.state.username;
            // send collected parameters through validation
            const user: RegisterUserObj = {
                title: this.state.title,
                username: username,
                password: this.state.password,
                password2: this.state.password2,
                accept_terms: this.state.accept_terms,
                provider: this.state.use_external ? this.state.selected_item.value : null,
                provider_username: this.state.external_username
            }
            if (this.state.use_external) {
                user.password = "lkjLKJ098=)(";
                user.password2 = user.password;
            }
            const message = Auth.validate(user);
            this.setState({ warning: message });

            if (message === "") {
                const user_uuid = await Auth.addUser(user);
                this.setState({ error: "" });
                const promises = [];
                // create roles is parallel
                if (this.state.is_admin) {
                    promises.push(this.setRole(user_uuid, Auth.ROLE_ADMIN));
                }
                if (this.state.is_power_user) {
                    promises.push(this.setRole(user_uuid, Auth.ROLE_POWER_USER));
                }
                if (this.state.is_demo_user) {
                    promises.push(this.setRole(user_uuid, Auth.ROLE_DEMO_USER));
                }
                if (this.state.is_planner) {
                    promises.push(this.setRole(user_uuid, Auth.ROLE_MAN_PLANNER));
                }
                if (this.state.is_plant_manager) {
                    promises.push(this.setRole(user_uuid, Auth.ROLE_MAN_PLANT_MANAGER));
                }
                if (this.state.is_shopfloor_manager) {
                    promises.push(this.setRole(user_uuid, Auth.ROLE_MAN_SHOPFLOOR_MANAGER));
                }
                await Promise.all(promises)
                this.props.history.push("/admin/users");
            }
        } catch (err) {
            this.setState({ error: err.message });
        }
    }

    handleSelectChange(selectedValue: Object) {
        this.setState({ selected_item: selectedValue, use_external: selectedValue.value !== "up" });
    }

    renderExternalLogins() {
        if (this.state.providers.length === 0) {
            return null;
        }

        return [
            <div className="form-group">
                <label>- {this.state.selected_item.field_caption}</label>
                <input name="external_username" type="text" disabled="" className="form-control"
                    placeholder=""
                    value={this.state.external_username}
                    onChange={this.handleInputChange} />
            </div>
        ];
    }

    render() {
        return (
            <div key="ds_new">
                <section key="1">
                    <img src="/img/banner.png" alt="banner" className="img-fluid-header banner_img" />
                </section>
                <section id="profile_edit" className="new_line" key="2">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="white_box">
                                    <div className="tab-content">
                                        <h4>
                                            <FormattedMessage id="User_new.add_new_user" defaultMessage="Add new user" />
                                        </h4>
                                        <ErrorComponent msg={this.state.error} type="error" />
                                        <ErrorComponent msg={this.state.warning} type="warning" />
                                        <div className="form-group">
                                            <label><FormattedMessage id="common.title" defaultMessage="Title" />:</label>
                                            <FormattedMessage id="common.first_and_last_name" defaultMessage="First and last name" >
                                                {text => <input name="title" type="text" disabled="" className="form-control"
                                                    placeholder={text}
                                                    value={this.state.title}
                                                    onChange={this.handleInputChange} />}
                                            </FormattedMessage>
                                        </div>
                                        <div className="form-group">
                                            <label><FormattedMessage id="common.username" defaultMessage="Username" />:</label>
                                            <FormattedMessage id="common.email" defaultMessage="Email address" >
                                                {text => <input name="username" type="text" disabled="" className="form-control"
                                                    autoComplete="new-password"
                                                    placeholder={text}
                                                    value={this.state.username}
                                                    onChange={this.handleInputChange} />}
                                            </FormattedMessage>
                                        </div>
                                        { /* <div className="form-group">
                                            <label><FormattedMessage id="common.authentication" defaultMessage="Authentication" />:</label>
                                            <Select value={this.state.selected_item} options={this.state.providers}
                                                onChange={(event) => { this.handleSelectChange(event) }} />
                                        </div>
                                        */}
                                        {!this.state.use_external && <div className="form-group">
                                            <label><FormattedMessage id="common.password" defaultMessage="Password" />:</label>
                                            <input name="password" type="password" disabled="" className="form-control"
                                                autoComplete="new-password"
                                                value={this.state.password}
                                                onChange={this.handleInputChange} />
                                        </div>
                                        }
                                        {!this.state.use_external && <div className="form-group">
                                            <label><FormattedMessage id="common.reenter_password" defaultMessage="Re-enter password" />:</label>
                                            <input name="password2" type="password" disabled="" className="form-control"
                                                value={this.state.password2}
                                                onChange={this.handleInputChange} />
                                        </div>
                                        }
                                        {this.state.use_external && <div className="form-group">
                                            <label>{this.state.selected_item.field_caption}</label>
                                            <input name="external_username" type="text" disabled="" className="form-control"
                                                placeholder=""
                                                value={this.state.external_username}
                                                onChange={this.handleInputChange} />
                                        </div>
                                        }
                                        <div className="form-group">
                                            <label><FormattedMessage id="role.admin" defaultMessage="Admin" />:</label><br />
                                            <Toggle
                                                name="is_admin"
                                                checked={this.state.is_admin}
                                                onChange={this.handleInputChange} />
                                        </div>
                                        <div className="form-group">
                                            <label><FormattedMessage id="role.power_user" defaultMessage="Power user" />:</label><br />
                                            <Toggle
                                                name="is_power_user"
                                                checked={this.state.is_power_user}
                                                onChange={this.handleInputChange} />
                                        </div>
                                        <div className="form-group">
                                            <label><FormattedMessage id="role.demo_user" defaultMessage="Demo user" />:</label><br />
                                            <Toggle
                                                name="is_demo_user"
                                                checked={this.state.is_demo_user}
                                                onChange={this.handleInputChange} />
                                        </div>
                                        <div className="form-group">
                                            <label><FormattedMessage id="role.planner" defaultMessage="Planner" />:</label><br />
                                            <Toggle
                                                name="is_planner"
                                                checked={this.state.is_planner}
                                                onChange={this.handleInputChange} />
                                        </div>
                                        <div className="form-group">
                                            <label><FormattedMessage id="role.plant_manager" defaultMessage="Plant manager" />:</label><br />
                                            <Toggle
                                                name="is_plant_manager"
                                                checked={this.state.is_plant_manager}
                                                onChange={this.handleInputChange} />
                                        </div>
                                        <div className="form-group">
                                            <label><FormattedMessage id="role.shopfloor_manager" defaultMessage="Shopfloor manager" />:</label><br />
                                            <Toggle
                                                name="is_shopfloor_manager"
                                                checked={this.state.is_shopfloor_manager}
                                                onChange={this.handleInputChange} />
                                        </div>
                                        <div className="button-list order-last ml-auto">
                                            <button className="btn btn-outline-primary" onClick={this.handleSubmit}>
                                                <FormattedMessage id="common.add" defaultMessage="Add" />
                                            </button>
                                            <Link className="btn btn-outline-secondary" to="/users">
                                                <FormattedMessage id="common.back" defaultMessage="Back" />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="space30"></div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default UserNew;
