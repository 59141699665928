// @flow
import * as React from "react";
import Select, { components } from "react-select";

import { translate } from "./IntlProviderWrapper";

import type { InputActionTypes, Theme, ValueType } from "react-select/src/types";
import type { Props } from "react-select/src/Select";
import type { OptionProps } from "react-select/src/components/Option";
import type { MenuProps, MenuListProps } from "react-select/src/components/Menu";
import type { MultiValueProps } from "react-select/src/components/MultiValue";
import type { PlaceholderProps } from "react-select/src/components/Placeholder";

type InputActionMeta = {
    action: InputActionTypes
};

export type SelectSize = "sm" | "lg";

export type MultiSelectProps = Props & {
    enable_filter_selected?: boolean,
    enable_select_all?: boolean,
    size?: SelectSize
};

type MultiSelectPropsInner = MultiSelectProps & {
    setShowSelectedOnly: (show_selected_only: boolean) => void,
    show_selected_only: boolean
};

type MultiSelectState = {
    filter: string,
    show_selected_only: boolean
};

const getSelectTheme = (theme: Theme, size: SelectSize): Theme => {
    return {
        ...theme,
        spacing: {
            ...theme.spacing,
            baseUnit: size === "sm" ? 2 : 4,
            controlHeight: size === "sm" ? 30 : 38
        }
    };
};

const getOptionValues = (select_props: MultiSelectPropsInner): ValueType[] => {
    const option_values: ValueType[] = [];
    for (const option of select_props.options) {
        if (Array.isArray(option.options)) {
            for (const sub_option of option.options) {
                option_values.push(select_props.getOptionValue(sub_option));
            }
        } else {
            const value = select_props.getOptionValue(option);
            if (value) {
                option_values.push(value);
            }
        }
    }

    return option_values;
};

const getSelectedValues = (select_props: MultiSelectPropsInner): ValueType[] => {
    if (select_props.value == null) {
        return [];
    }

    const selected_values: ValueType[] = [];
    if (Array.isArray(select_props.value)) {
        for (const option of select_props.value) {
            selected_values.push(select_props.getOptionValue(option));
        }
    } else {
        selected_values.push(select_props.getOptionValue(select_props.value));
    }

    return selected_values;
};

const SelectCheckboxOption = (props: OptionProps) => (
    <components.Option {...props}>
        <div className="custom-control custom-checkbox">
            <input className="custom-control-input" type="checkbox" checked={props.isSelected} onChange={() => null} />
            <span className="custom-control-label">{props.label}</span>
        </div>
    </components.Option>
);

const SelectMultiValue = ({ children, ...rest }: MultiValueProps) => {
    const select_props: MultiSelectPropsInner = rest.selectProps;
    if (rest.index > 0 || select_props.inputValue !== "") {
        return null;
    }

    const option_values: Set<ValueType> = new Set(getOptionValues(select_props));
    const selected_values = getSelectedValues(select_props);
    let count = 0;
    selected_values.forEach(value => {
        if (option_values.has(value)) {
            count += 1;
        }
    });

    return (
        <components.SingleValue {...rest}>
            {select_props.placeholder} ({count}/{option_values.size})
        </components.SingleValue>
    );
};

const SelectPlaceholder = ({ children, ...rest }: PlaceholderProps) => {
    const base_unit = rest.theme.spacing.baseUnit;
    const select_props: MultiSelectPropsInner = rest.selectProps;
    const option_values = getOptionValues(select_props);
    select_props.styles.placeholder = base => ({
        ...base,
        maxWidth: `calc(100% - ${base_unit * 4}px)`,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
    });
    return (
        <components.Placeholder {...rest} className="w-100">
            {children}
            {select_props.isLoading ? "" : ` (0/${option_values.length})`}
        </components.Placeholder>
    );
};

const SelectMenu = ({ children, ...rest }: MenuProps) => {
    const select_props: MultiSelectPropsInner = rest.selectProps;
    const options = rest.options;
    const base_unit = rest.theme.spacing.baseUnit;
    const num_filtered = options.filter(option => select_props.filterOption(option, select_props.inputValue)).length;
    const is_checked = select_props.value !== null && select_props.value.length === select_props.options.length;
    return (
        <components.Menu {...rest}>
            {!select_props.isLoading &&
                ((select_props.enable_select_all && num_filtered === options.length) ||
                    select_props.enable_filter_selected) && (
                    <div
                        className="react-select__toolbar"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            paddingTop: base_unit * 2,
                            paddingRight: base_unit * 3,
                            paddingBottom: base_unit * 2,
                            paddingLeft: base_unit * 3,
                            borderBottomWidth: 1,
                            borderBottomStyle: "solid",
                            borderBottomColor: rest.theme.colors.neutral10
                        }}
                    >
                        <div className="row mx-n2">
                            {select_props.enable_select_all && (
                                <div className="col-auto px-2">
                                    <div
                                        className="custom-control custom-checkbox"
                                        onClick={() => {
                                            if (is_checked) {
                                                rest.setValue([]);
                                            } else {
                                                rest.setValue(options);
                                            }
                                        }}
                                        tabIndex={-1}
                                    >
                                        <input
                                            className="custom-control-input"
                                            type="checkbox"
                                            checked={is_checked}
                                            onChange={() => null}
                                        />
                                        <label className="custom-control-label d-block">
                                            {translate("common.select_all", "Select all")}
                                        </label>
                                    </div>
                                </div>
                            )}
                            {select_props.enable_filter_selected && (
                                <div className="col-auto px-2">
                                    <div
                                        className="custom-control custom-checkbox"
                                        onClick={() => {
                                            select_props.setShowSelectedOnly(!select_props.show_selected_only);
                                        }}
                                        tabIndex={-1}
                                    >
                                        <input
                                            className="custom-control-input"
                                            type="checkbox"
                                            checked={select_props.show_selected_only}
                                            onChange={() => null}
                                        />
                                        <label className="custom-control-label d-block">
                                            {translate("common.filter_selected", "Filter selected")}
                                        </label>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            {children}
        </components.Menu>
    );
};

const SelectMenuList = ({ children, ...rest }: MenuListProps) => {
    const select_props: MultiSelectPropsInner = rest.selectProps;
    let new_children = children;
    if (select_props.show_selected_only && Array.isArray(children)) {
        new_children = children.filter(child => child.props && child.props.isSelected);
        if (new_children.length === 0) {
            const base_unit = rest.theme.spacing.baseUnit;
            const class_prefix = select_props.classNamePrefix;
            new_children = (
                <div
                    className={`${class_prefix}__menu-notice ${class_prefix}__menu-notice--no-options text-center`}
                    style={{
                        color: rest.theme.colors.neutral40,
                        padding: `${2 * base_unit}px ${3 * base_unit}px`
                    }}
                >
                    {select_props.noOptionsMessage()}
                </div>
            );
        }
    }

    return <components.MenuList {...rest}>{new_children}</components.MenuList>;
};

class MultiSelect extends React.Component<MultiSelectProps, MultiSelectState> {
    constructor(props: MultiSelectProps) {
        super(props);
        this.state = {
            filter: "",
            show_selected_only: false
        };
    }

    setShowSelectedOnly = (show_selected_only: boolean) => {
        this.setState({
            show_selected_only
        });
    };

    handleInputChange = (value: string, { action }: InputActionMeta) => {
        if (action === "input-change") {
            this.setState({
                filter: value
            });
        } else if (action === "menu-close") {
            this.setState({
                filter: ""
            });
        }
    };

    render() {
        const { className, components, size = "lg", ...rest } = this.props;
        return (
            <Select
                className={`react-select react-select--${size} react-select--multi ${className}`}
                classNamePrefix="react-select"
                inputValue={this.state.filter}
                components={{
                    Placeholder: SelectPlaceholder,
                    MultiValue: SelectMultiValue,
                    Option: SelectCheckboxOption,
                    Menu: SelectMenu,
                    MenuList: SelectMenuList,
                    ...(components || {})
                }}
                backspaceRemovesValue={false}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                theme={theme => getSelectTheme(theme, size)}
                onInputChange={this.handleInputChange}
                show_selected_only={this.state.show_selected_only}
                setShowSelectedOnly={this.setShowSelectedOnly}
                {...rest}
                isMulti
            />
        );
    }
}

export default MultiSelect;
