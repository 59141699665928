// @flow

import type {
    DashboardBaseObj, DashboardObj, DashboardSaveObj, DashboardResultObj
} from "./Models";

import axios from "axios";
import { Backend, DashboardBackend } from "./Backend";
import * as Backend2 from "./backend/Backend2";
import * as rest from "./backend/restApi";
import * as restAxios from "./backend/restApiAxios";
import { publish, MSG_TYPES, subscribe } from "./PubSub";

// Helper funtion for constructing URL query string from given object
function toQueryString(obj) {
    obj = obj || {};
    const parts = [];
    for (const i in obj) {
        if (obj.hasOwnProperty(i)) {
            parts.push(encodeURIComponent(i) + "=" + encodeURIComponent(obj[i]));
        }
    }
    return parts.join("&");
}

const {
    // Common instance of axios for setting interceptors and such, but NOT making calls
    restClient,
    // Common REST client object - should be used for making calls
    getInternalAxiosInstance
} = (() => {
    // initialize instances inside this function to hide the implemenattion from outside
    const res = new restAxios.ApiClientAxios();
    const getInternalAxiosInstance = () => res.getAxiosInstance();
    const restClient: rest.ApiClient = res;
    return {
        restClient,
        getInternalAxiosInstance
    };
})();

// Transform dashboard object as returned by the backend to DashboardBaseObj
function toDashboardBaseObj(ds: Object): DashboardBaseObj {
    let dashboard = {
        active: ds.active,
        created_at: new Date(ds.created_at),
        created_by: ds.created_by,
        is_system: ds.is_system,
        org_uuid: ds.org_uuid,
        public: ds.public,
        tags: ds.tags,
        title: ds.title,
        uuid: ds.uuid
    };
    return dashboard;
}

// Transform dashboard object as returned by the backend to DashboardObj
function toDashboardObj(ds: Object): DashboardObj {
    let dashboard = {
        active: ds.active,
        created_at: new Date(ds.created_at),
        created_by: ds.created_by,
        data_def: ds.data_def,
        is_system: ds.is_system,
        layout_def: ds.layout_def,
        org_uuid: ds.org_uuid,
        public: ds.public,
        tags: ds.tags,
        title: ds.title,
        uuid: ds.uuid
    };
    return dashboard;
}

/**
 * Implementing REST manufacturing backend.
 */
export class RestDashboardBackend implements DashboardBackend {

    // type definitions
    parent: Object;

    /**
     * Constructor.
     * @param {class} parent Parent backend object class.
     */
    constructor(parent: Object) {
        this.parent = parent;
    }

    // get dashboard list
    async getDashboardsAsync(): Promise<DashboardBaseObj[]> {
        const result = await restClient.get("/api/v1.0/dashboards");
        return result.data.map(toDashboardBaseObj);
    }

    // get dashboard list
    getDashboards(done: (DashboardBaseObj[]) => void, err: (str: string) => void): void {
        restClient.get("/api/v1.0/dashboards")
            .then(result => {
                done(result.data.map(toDashboardBaseObj));
            })
            .catch(error => {
                this.parent._checkAuthError(error);
                console.log(error);
                err("Error retrieving dashboard list");
            });
    }

    // get record for single dashboard - don't execute it
    async getSingleDashboardAsync(uuid: string): Promise<DashboardObj> {
        const result = await restClient.get("/api/v1.0/dashboards/" + uuid + "/definition")
        return toDashboardObj(result.data);
    }

    // get record for single dashboard - don't execute it
    getSingleDashboard(uuid: string, done: (DashboardObj) => void, err: (str: string) => void): void {
        restClient.get("/api/v1.0/dashboards/" + uuid + "/definition")
            .then(result => {
                done(toDashboardObj(result.data));
            })
            .catch(error => {
                this.parent._checkAuthError(error);
                console.log(error);
                err("Error retrieving dashboard");
            });
    }

    // execute specified dashboard
    executeDashboard(uuid: string, from: number, to: number, params: any, done: (DashboardResultObj) => void, err: (str: string) => void): void {
        params = params || {};
        if (from > 0) {
            params.from = from;
        }
        if (to > 0) {
            params.to = to;
        }
        restClient.get("/api/v1.0/dashboards/" + uuid, params)
            .then(result => {
                done(result.data);
            })
            .catch(error => {
                this.parent._checkAuthError(error);
                console.log(error);
                err("Error executing dashboard");
            });
    }

    // get record for single dashboard - don't execute it
    getSingleDashboardPublic(uuid: string, done: (DashboardObj) => void, err: (str: string) => void): void {
        restClient.get("/api/v1.0/dashboards/" + uuid + "/definition-public")
            .then(result => {
                done(toDashboardObj(result.data));
            })
            .catch(error => {
                this.parent._checkAuthError(error);
                console.log(error);
                err("Error retrieving dashboard");
            });
    }

    // execute specified dashboard
    executeDashboardPublic(uuid: string, from: number, to: number, params: any, done: (DashboardResultObj) => void, err: (str: string) => void): void {
        if (from > 0) {
            params.from = from;
        }
        if (to > 0) {
            params.to = to;
        }
        restClient.get("/api/v1.0/dashboards/" + uuid + "/public", params)
            .then(result => {
                done(result.data);
            })
            .catch(error => {
                this.parent._checkAuthError(error);
                console.log(error);
                err("Error executing dashboard");
            });
    }

    // execute given dashboard definition
    executeDashboardDef(layout_def: any, data_def: any, params: any, done: (DashboardResultObj) => void, err: (str: string) => void): void {
        const url_query = toQueryString(params);
        restClient.post("/api/v1.0/dashboards/draft?" + url_query, { layout_def, data_def })
            .then(result => {
                done(result.data);
            })
            .catch(error => {
                this.parent._checkAuthError(error);
                console.log(error);
                err("Error executing dashboard def");
            });
    }

    // save new dashboard
    insertDashboard(data: DashboardSaveObj, done: (str: string) => void, err: (str: string) => void): void {
        restClient.post("/api/v1.0/dashboards", data)
            .then(result => {
                done(result.data.uuid);
            })
            .catch(error => {
                this.parent._checkAuthError(error);
                console.log(error);
                err("Error storing dashboard");
            });
    }

    // update existing dashboard
    updateDashboard(uuid: string, data: DashboardSaveObj, done: () => void, err: (str: string) => void): void {
        restClient.put("/api/v1.0/dashboards/" + uuid, data)
            .then(result => {
                done();
            })
            .catch(error => {
                this.parent._checkAuthError(error);
                console.log(error);
                err("Error storing dashboard");
            });
    }
}

export class RestBackend implements Backend {
    dash: DashboardBackend;
    replacements_map: Object;

    constructor() {
        this.dash = new RestDashboardBackend(this);
        Backend2.init(restClient);
        // set up handling of 401 requests
        this.getAxiosInstance().interceptors.response.use(undefined, handle401ErrorInterception);
        subscribe(MSG_TYPES.auth_token_refreshed, async (payload: any) => this.initAuth(payload.token));
        subscribe(MSG_TYPES.logout, async () => this.clrAuth());
    }

    getAxiosInstance(): axios.AxiosInstance {
        return getInternalAxiosInstance();
    }

    initAuth(token: string): void {
        restClient.setRequestHeader("Authorization", "Bearer " + token);
        Backend2.getBackend().setRequestHeader("Authorization", "Bearer " + token);
    };

    clrAuth(): void {
        restClient.setRequestHeader("Authorization", null);
        Backend2.getBackend().setRequestHeader("Authorization", null);
    };

    // if we got error with auth, then clear auth
    _checkAuthError(error: any) {
        if (error.response && error.response.status && error.response.status === 401) {
            // our token expired, let's log out on client side as well
            publish(MSG_TYPES.logout_request, {});
            publish(MSG_TYPES.redirect_to_login, {});
        }
    };
};

/** This function handles 401 HTTP errror - unauthorized  */
function handle401ErrorInterception(error: any) {
    const response = error.response;
    if (response) {
        // detect unauthorized and redirect to login page
        if (response.status === 401) {
            if (window.location.pathname !== "/login") {
                publish(MSG_TYPES.logout_request, {});
                publish(MSG_TYPES.redirect_to_login, {});
            }
        }
        if (response.status === 500) {
            return Promise.reject(new Error(error.response.data));
        }
    } else {
        console.log(error);
    }
    return Promise.reject(error);
}

/** This utility method loads file from given url and 
 * opens it in new tab. This has to be done in a separate function in order to inject
 * proper headers to fetch files. Otherwise teh request might get re-routed to wrong gui server.
 */
export async function downloadAndOpenFile(url: string) {
    const response = await fetch(url, { headers: rest.COMMON_HEADERS });
    const blob = await response.blob();
    const _url = window.URL.createObjectURL(blob);
    window.open(_url, "_blank").focus();
};
