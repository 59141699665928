// @flow
import React from "react";
import { connect } from "react-redux";
import { ORDER_TAGS } from "../../../lib/ManufacturingTags.generated";
import EditableTextArea from "../../react/EditableTextArea";
import { translate } from "../../IntlProviderWrapper";
import * as Auth from "../../../lib/Auth";
import { getBackend as getBackend2 } from "../../../lib/backend/Backend2";
import { onSaveComment } from "./PlanningTableEditModal";

import type { IOrderProducedModelEx } from "./reducers/linesOrders";
import type { ReduxState } from "./reducers/reducers";
import type { UpdateLineOrderComment } from "./reducers/linesOrders";

type OrderCommentTextAreaProps = {
    order_uuid: string,
    comment: string,
    order?: IOrderProducedModelEx,
    updated_order_uuid?: string,
    reduxDispatch: (args: UpdateLineOrderComment) => void
};
type OrderCommentTextAreaState = {
    input_comment: string
};

class OrderCommentTextArea extends React.Component<OrderCommentTextAreaProps, OrderCommentTextAreaState> {

    state = {
        input_comment: ""
    }

    componentDidMount() {
        this.load();
    }

    componentDidUpdate(prev_props: OrderCommentTextAreaProps) {
        if (prev_props.comment !== this.props.comment && this.props.comment !== this.state.input_comment ||
            prev_props.order !== this.props.order) {
            this.load();
        }
    }

    load() {
        this.setState({
            input_comment: this.props.comment
        });
    }

    onChange = (value: string) => {
        this.setState({
            input_comment: value
        });
    }

    onSave = async () => {
        const order = this.props.order;
        if (!order) {
            return;
        }
        let order_tags = order.tags;
        order_tags[ORDER_TAGS.comment] = this.state.input_comment;
        await getBackend2().manufacturing.updateOrders({
            orders: [
                { uuid: this.props.order_uuid, tags: order_tags }
            ]
        });
        onSaveComment(
            this.props.order_uuid,
            this.state.input_comment,
            this.props.reduxDispatch
        );
    }

    isDisabled = () => {
        return !Auth.hasPermission(Auth.PERMISSION_NAMES.OrderEdit);
    }

    showSave = () => {
        return this.state.input_comment !== this.props.comment;
    }

    render() {
        const colSpan = this.showSave() ? 4 : 5;
        return <React.Fragment>
            <tr>
                <td colSpan={colSpan} style={{paddingBottom: "5px", paddingLeft: "unset", paddingRight: "unset"}}>
                    <div style={{display: "flex", alignItems: "center"}}>
                        <span style={{ fontWeight: "bold" }}>{translate("common.comment", "Comment")}:&nbsp;</span>
                        <EditableTextArea
                            value={this.state.input_comment}
                            onChange={this.onChange}
                            isDisabled={this.isDisabled()}
                            title={translate("common.comment", "Comment")}
                        />
                    </div>
                </td>
                <td>
                    {this.showSave() && <button id="comment-edit-button" className="btn btn-outline-primary" onClick={this.onSave}>
                        {translate("common.save", "Save")}
                    </button>}
                </td>
            </tr>
        </React.Fragment>
    }
}

export default connect(
    (state: ReduxState, own_props: OrderCommentTextAreaProps) => {
        const lines_orders = state.gantt_chart_lines_orders.lines_orders;
        const unscheduled_lines_orders = state.gantt_chart_lines_orders.unscheduled_orders;

        let order = null;
        for (const line of lines_orders) {
            order = line.orders.find(o => o.uuid === own_props.order_uuid);
            if (order) {
                break;
            }
        }
        if (!order) {
            for (const unscheduled_line of unscheduled_lines_orders) {
                order = unscheduled_line.orders.find(o => o.uuid === own_props.order_uuid);
                if (order) {
                    break;
                }
            }
        }
        return {
            order,
            updated_order_uuid: state.gantt_chart_lines_orders.updated_order_uuid
        };
    },
    (dispatch) => ({ reduxDispatch: dispatch })
)(OrderCommentTextArea);