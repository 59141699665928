// @flow

// main imports
import React, { Component } from "react";
import ReactRouterPropTypes from "react-router-prop-types";
import {translate} from "../IntlProviderWrapper";

import type { IModelBaseInfo } from "../../lib/backend/models.generated.types";

// backend
import { getBackend } from "../../lib/backend/Backend2";
import { waitForManufacturingAsync } from "../../lib/BusinessLogic";
import ChevronListItem from "../react/ChevronListItem";

// import subcomponents

import ModelDetails from "./ModelDetails"
import ErrorComponent from "../ErrorComponent";
// import { translate } from "../IntlProviderWrapper";

import type { ResourceEditFiltersState } from "../redux/reducers/resourceEditFiltersReducer";
import { ResourcesLayout } from "../MainContent";

// defining types
type Props = {
    match: ReactRouterPropTypes.match,
    history: ReactRouterPropTypes.history,
    location: ReactRouterPropTypes.location,
    resource_edit_filters: ResourceEditFiltersState
}

type State = {
    models: IModelBaseInfo[],
    selected: IModelBaseInfo | null,
    material_external_id: string,
    line_external_id: string,
    error: string,
    warning: string
}

type stringOrUndef = string | typeof undefined;
function undefinedIfEmpty(s: stringOrUndef): stringOrUndef {
    if (s == "" || s == null || s == undefined) {
        return undefined;
    }
    return s.trim();
}

/**
 * Displaying production lines list.
 */
class Models extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        const state: State = {
            models: [],
            line_external_id: "",
            material_external_id: "",
            error: "",
            warning: "",
            selected: null
        };
        this.state = state;
    }

    sortHits(hits: IModelBaseInfo[]): IModelBaseInfo[] {
        return hits.sort((a, b) => {
            if (a.material_external_id != b.material_external_id) {
                return a.material_external_id.localeCompare(b.material_external_id);
            }
            if (a.process_num != b.process_num) {
                return a.process_num.localeCompare(b.process_num);
            }
            return a.line_external_id.localeCompare(b.line_external_id);
        });
    }

    /**
     * Load component data from backend.
     */
    async loadComponent() {
        try {
            await waitForManufacturingAsync();
            const backend = getBackend();
            const { plants } = await backend.manufacturing.getPlants({});
            let { hits } = await backend.models.searchModels({
                // line: this.props.line_external_id,
                // material: this.props.material_external_id,
                // process_num: this.props.process_num
                plant: plants[0].external_id
            });
            hits = this.sortHits(hits);

            this.setState({
                models: hits,
                selected: hits[0],
                error: "",
                warning: ""
            });

        } catch (e) {
            this.setState({ error: e });
        }
    }

    /**
     * Mounting data for this component.
     */
    async componentDidMount() {
        await this.loadComponent();
    }

    async componentDidUpdate(prevProps: Props) {
        // if(prevProps.resource_edit_filters.plant_uuid !== this.props.resource_edit_filters.plant_uuid) {
        //     await this.loadComponent();
        // }

        // if (prevProps.resource_edit_filters.lines_title_filter !== this.props.resource_edit_filters.lines_title_filter) {
        //     const { plant_uuid, lines_title_filter } = this.props.resource_edit_filters;

        //     this.setState({
        //         selected: this.selectLine(lines_title_filter, plant_uuid)
        //     });
        // }
    }

    setSelected(selected: IModelBaseInfo) {
        this.setState({ selected });
    }

    async handleInputChange(event: any) {
        if (event.currentTarget instanceof HTMLInputElement || event.currentTarget instanceof HTMLSelectElement) {
            // read new values
            const target = event.currentTarget;
            const value = target.value;
            const name = target.name;

            let material = this.state.material_external_id;
            let line = this.state.line_external_id;

            if (name == "material_filter") {
                material = (value.trim() === "") ? undefined : value.trim();
            }
            if (name == "line_filter") {
                line = (value.trim() === "") ? undefined : value.trim();
            }

            const backend = getBackend();
            let { hits } = await backend.models.searchModels({
                line: undefinedIfEmpty(line),
                material: undefinedIfEmpty(material)
            });
            hits = this.sortHits(hits);

            this.setState({
                models: hits,
                selected: hits[0],
                line_external_id: line || "",
                material_external_id: material || "",
                error: "",
                warning: ""
            });
        }
    }

    /**
     * Rendering JSX for current component.
     */
    render() {
        const model_list_items = this.state.models.map((x, i) => <ChevronListItem
            text={
                <div>
                    <dt>{`[${x.material_external_id}] - ${x.line_external_id}`}<br />
                        <small>{x.process_num}</small></dt>
                </div>
            }
            onClick={() => this.setSelected(x)}
            // is_active={this.state.selected && this.state.selected.uuid === x.uuid}
            is_active={false}
            key={"model_item_" + i}
        />);

        return (<ResourcesLayout>
            <ResourcesLayout.Main>
                <ResourcesLayout.List>
                    <ResourcesLayout.Search>
                        <div className="form-group form-group-stretch">
                            <input type="text" className="form-control search_bar" placeholder={translate("common.material", "Material")}
                                name="material_filter" value={this.state.material_external_id} onChange={e => this.handleInputChange(e)} />
                        </div>
                        <div className="form-group form-group-stretch">
                            <input type="text" className="form-control search_bar" placeholder={translate("common.line", "Line")}
                                name="line_filter" value={this.state.line_external_id} onChange={e => this.handleInputChange(e)} />
                        </div>
                        {/*
                        <div className="form-group">
                            <select name="plant_uuid" className="form-control select_filter_control" value={this.props.resource_edit_filters.plant_uuid} onChange={this.handleInputChange}>
                                <option key={-1} value="">
                                    {translate("Manufacturing.Lines.all_plants", "All Plants")}
                                </option>
                                {
                                    this.state.plants.map((plant, i) => {
                                        return <option key={i} value={plant.uuid}>{plant.title}</option>
                                    })
                                }
                            </select>
                        </div> */}
                    </ResourcesLayout.Search>
                    <ul className="nav nav-tabs">
                        <div className="nav-tabs-parent" ref="nav">
                            {this.state.models.length === 0 && <div className="container">{translate("Models.no_model_found", "No models found")}</div>}
                            {model_list_items}
                        </div>
                    </ul>
                </ResourcesLayout.List>
                <ResourcesLayout.Content>
                    <ErrorComponent msg={this.state.error} type="error" />
                    <ErrorComponent msg={this.state.warning} type="warning" />
                    {this.state.selected &&
                        <ModelDetails
                            line_external_id={this.state.selected.line_external_id}
                            material_external_id={this.state.selected.material_external_id}
                            process_num={this.state.selected.process_num}
                        />
                    }
                </ResourcesLayout.Content>
            </ResourcesLayout.Main>
        </ResourcesLayout>
        )
    }

}

export default Models;
