// @flow
import React from "react";
import { connect } from "react-redux";
import Select from "react-select";

import { RESET_FILTERS } from "../../reducers/reducersFilters/filters";
import { translate } from "../../../../IntlProviderWrapper";

import { ReduxFilters } from "../../reducers/BusinessLogic";

import type { SetRescheduleOrderAction, SetRescheduleOrderBundleAction } from "../../reducers/common";
import type { SetHighlightedOrders, SetLastClickedOrderUuid } from "../../reducers/planningTable";
import type { LineOrders } from "../../reducers/linesOrders";
import type { SelectOptionItem } from "./TypeFilterConsts";
import type { ReduxState } from "../../reducers/reducers";
import type { ResetFiltersAction } from "../../reducers/reducersFilters/filters";

type Props = {
    reduxDispatch: (args: SetRescheduleOrderAction | ResetFiltersAction | SetHighlightedOrders | SetLastClickedOrderUuid | SetRescheduleOrderBundleAction) => void,
    orders: LineOrders[],
    reschedule_order_uuid: string,
    is_filter_locked: boolean
};
type State = {};

class RescheduleOperationFilter extends React.Component<Props, State> {

    onChange = (option: SelectOptionItem) => {
        if (!option) {
            this.props.reduxDispatch({ type: RESET_FILTERS, data: undefined });
        } else {
            ReduxFilters.setRescheduleOrderUuid(this.props.reduxDispatch,
                option.value, "line_order_list", this.props.is_filter_locked);
        }
    }

    getSelectedOptions = (): SelectOptionItem | null => {
        if (!this.props.reschedule_order_uuid) {
            return null;
        }
        for (const line_order of this.props.orders) {
            for (const order of line_order.orders) {
                if (order.uuid === this.props.reschedule_order_uuid) {
                    return {
                        value: this.props.reschedule_order_uuid,
                        label: order.external_id + " - " + order.process_num
                    }
                }
            }
        }
        return null;
    }

    getOptions = (): SelectOptionItem[] => {
        const options = []
        for (const line_order of this.props.orders) {
            for (const order of line_order.orders) {
                options.push({
                    value: order.uuid,
                    label: order.external_id + " - " + order.process_num
                });
            }
        }
        return options;
    }

    render() {
        return (
            <div className="order-filter">
                <Select
                    value={this.getSelectedOptions()}
                    placeholder={translate("common.operation", "Operation")}
                    options={this.getOptions()}
                    onChange={this.onChange}
                    isSearchable={true}
                    isClearable
                />
            </div>
        );
    }
}

export default connect(
    (state: ReduxState) => {
        const orders = state.gantt_chart_lines_orders.lines_orders.concat(
            state.gantt_chart_lines_orders.unscheduled_orders
        );

        return {
            reschedule_order_uuid: state.gantt_chart_filters.reschedule_order_uuid,
            orders,
            is_filter_locked: state.gantt_chart_filters.is_filter_locked
        }
    },
    (dispatch) => ({reduxDispatch: dispatch})
)(RescheduleOperationFilter);