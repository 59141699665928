// @flow

// main imports
import React from "react";
import { Component } from "react";
import { exportToolTransfer } from "./ToolTransferPrint";
import ReactRouterPropTypes from "react-router-prop-types";

type Props = {
    match: ReactRouterPropTypes.match,
    history: ReactRouterPropTypes.history,
    location: ReactRouterPropTypes.location
};
type State = {}
class ToolTransfer extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        const state: State = { };
        this.state = state;
    }

    async componentDidMount() {
        try {
            // event_uuid is event's uuid parameter from URL path
            const { order_external_id, process_num } = this.props.match.params;
            await exportToolTransfer(order_external_id, process_num);
            this.props.history.goBack();
        } catch (err) {
            console.log(err);
        }
    }

    render() {
        return <div />;
    }
}

export default ToolTransfer;
