// @flow
import { RESET_GANTT } from "./common";

import type { PayloadAction } from "../../../redux/types";
import type { IWeekKPIs } from "../../../../lib/backend/reports.generated.types";
import type { ResetGanttAction } from "./common";
import { deepClone } from "../../../../lib/Util";

export const ADD_KPI_ACTION_NAME: "add_kpis" = "add_kpis";

export type AddKpisAction = PayloadAction<typeof ADD_KPI_ACTION_NAME, Kpis>;

export type Kpis = {
    name: string,
    time_ts: number,
    is_initial?: boolean,
    report_uuid: string,
    kpis: IWeekKPIs[]
}

export type KpisState = {
    kpis: Kpis[]
}

export type KpisActions = AddKpisAction | ResetGanttAction;

const kpis_inital_state = {
    kpis: []
}

export const kpisReducer = (state: KpisState, action: KpisActions): KpisState => {
    if (!state) {
        state = deepClone(kpis_inital_state);
    }

    let new_state = state;
    if (action.type === RESET_GANTT) {
        return deepClone(kpis_inital_state);
    } else if (action.type === ADD_KPI_ACTION_NAME) {
        let kpis = new_state.kpis;
        const data = action.data;
        if (data) {
            const report_kpis_exists = kpis.some(
                k => k.report_uuid === data.report_uuid
            );
            if (!report_kpis_exists) {
                kpis.push(action.data);
            }
            return { ...new_state, kpis: [...kpis] };
        }
    }

    return new_state;
}
