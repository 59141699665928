// @flow
import * as React from "react";
import ReactRouterPropTypes from "react-router-prop-types";

import { getBackend } from "../../lib/backend/Backend2";
import InsightsList from "./InsightsList";
import * as t from "../../lib/backend/manufacturing2.generated.types";
import Loader from "../Loader";
// import type { Insight, InsightReport } from "../../lib/Models";
import * as BusinessLogic from "../../lib/BusinessLogic";

import ErrorComponent from "../ErrorComponent";

type Props = {
    history: ReactRouterPropTypes.history,
    match: ReactRouterPropTypes.match,
    plant_uuid: string,
    line_group_uuid: string,
    insight_types: string[],
    from_diff?: number,
    reverse_order?: boolean,
    all_plant?: boolean,
    filter?: (t.IEventDataEx) => boolean,
    from?: number
};

type State = {
    insights_all: t.IEventDataEx[],
    insights_fetched: boolean,
    error: string,
    report1: t.ISimulationReportData | null,
    report2: t.ISimulationReportData | null
};

class InsightsView extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        const state: State = {
            insights_all: [],
            insights_fetched: false,
            error: "",
            report1: null,
            report2: null
        };
        this.state = state;
    }

    componentDidMount() {
        this.reloadInsights();
    }

    async componentDidUpdate(prev_props: Props) {
        if (prev_props.plant_uuid !== this.props.plant_uuid ||
            prev_props.line_group_uuid !== this.props.line_group_uuid ||
            prev_props.insight_types.join() !== this.props.insight_types.join()) {

            await this.reloadInsights();
        }
    }

    async reloadInsights() {
        try {
            await BusinessLogic.waitForManufacturingAsync();
            // prepare search criteria for relevant insights
            let from = this.props.from || (new Date().getTime());
            if (this.props.from_diff) { from += this.props.from_diff; }
            const insight_criteria: t.IGetInsightsReq = {
                from,
                types: this.props.insight_types
            };
            if (this.props.all_plant) {
                // we only search by plant uuid
                insight_criteria.plants = [ this.props.plant_uuid ];
            } else {
                // get all lines for the line group
                let line_group = BusinessLogic.getLineGroupForUser(this.props.line_group_uuid);
                if (line_group === null) { return; }
                const line_uuids = line_group ? line_group.line_uuids : [];
                // add to search criteria
                insight_criteria.lines = line_uuids;
            }
            // retrieve insights
            const res = await getBackend().manufacturing.getInsights(insight_criteria);
            let insights_all = res.insights;
            if (this.props.filter) { insights_all = insights_all.filter(this.props.filter); }
            this.setState({
                insights_all: insights_all,
                insights_fetched: true,
                report1: res.report1,
                report2: res.report2
            });
        } catch (e) {
            this.setState({ error: e.message });
        }
    }

    render() {
        const rep1 = this.state.report1;
        const rep2 = this.state.report2;
        if (this.state.error) {
            return (
                <ErrorComponent msg={this.state.error} type="warning"/>
            );
        } else if (this.state.insights_fetched && rep1 && rep2) {
            return (
                <article className="article">
                    <section id="statistics" className="data_sources">
                        <div className="vertical_tab">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                        <div className="card card-insights">
                                            <InsightsList
                                                history={this.props.history}
                                                insights={this.state.insights_all}
                                                reportid={rep1.uuid}
                                                week={rep1.shift_time.week_number}
                                                year={rep1.shift_time.year}
                                                show_filter={false}
                                                show_only_future={false}
                                                show_links={true}
                                                show_actions={true}
                                                reverse_order={this.props.reverse_order}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </article>
            )
        } else {
            return (
                <article className="article">
                    <section id="statistics" className="data_sources">
                        <Loader />
                    </section>
                </article>
            )
        }
    }
}
export default InsightsView;
