// @flow

import React from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { translate } from "../../IntlProviderWrapper";
import * as BusinessLogic from "../../../lib/BusinessLogic";
import { LINE_GROUP_TAGS } from "../../../lib/ManufacturingTags.generated";
import type { ReduxState } from "./reducers/reducers";

import * as Auth from "../../../lib/Auth";

export const PLANNING_TABLE_DISPLAY_TYPES = {
    gantt: "gantt",
    material_forecast_in: "material_forecast_in",
    material_forecast_out: "material_forecast_out",
    tool_changes: "tool_changes",
    kpis: "kpis",
    material_recommend_forecast_in: "material_recommend_forecast_in"
}

type Props = {
    show_advanced_menu: boolean,
    show_planning_stock_forecast: boolean,
    selected_display_type: string,
    line_group_uuid: string,
    onDisplayTypeChanged: (args: $Keys<typeof PLANNING_TABLE_DISPLAY_TYPES>) => void
};
type State = {
    selected_display_type: string
};

type SelectOptionItem = {
    label: string;
    value: $Keys<typeof PLANNING_TABLE_DISPLAY_TYPES>;
}

class DisplayTypesMenu extends React.Component<Props, State> {

    getSelectedOption = (): ?SelectOptionItem => {
        return this.getSelectOptions().find(o => o.value === this.props.selected_display_type);
    };

    getSelectOptions = (): SelectOptionItem[] => {
        const menu_options = [
            {
                value: PLANNING_TABLE_DISPLAY_TYPES.gantt,
                label: translate("Manufacturing.Planning.gantt")
            },
            {
                value: PLANNING_TABLE_DISPLAY_TYPES.material_forecast_in,
                label: `${translate("Manufacturing.StocksView.stock_forecast")} - ${translate("Manufacturing.MaterialStockForecast.material_in", "Input")}`
            },
            {
                value: PLANNING_TABLE_DISPLAY_TYPES.material_forecast_out,
                label: `${translate("Manufacturing.StocksView.stock_forecast")} - ${translate("Manufacturing.MaterialStockForecast.material_out", "Produced")}`
            },
            {
                value: PLANNING_TABLE_DISPLAY_TYPES.kpis,
                label: translate("common.kpis")
            }
        ];

        // check if user should see details of stock levels used in optimization
        const is_admin = Auth.isInRole(Auth.ROLE_ADMIN);
        const is_power_user = Auth.isInRole(Auth.ROLE_POWER_USER);
        if (this.props.show_planning_stock_forecast && (is_admin || is_power_user)) {
            menu_options.push({
                value: PLANNING_TABLE_DISPLAY_TYPES.material_recommend_forecast_in,
                label: `${translate("Manufacturing.Planning.details_stock_levels", "Details: Stock levels used in optimization")}`
            });
        }

        const show_tool_change = BusinessLogic.getLineGroupTagBool(this.props.line_group_uuid,
            LINE_GROUP_TAGS.planning_table_show_tool_change, false);

        if (show_tool_change) {
            menu_options.push({
                value: PLANNING_TABLE_DISPLAY_TYPES.tool_changes,
                label: translate("Manufacturing.ToolsView.tool_change", "Tool changes")
            });
        }

        return menu_options;
    }

    onChange = (option: SelectOptionItem) => {
        this.props.onDisplayTypeChanged(option.value);
    }

    render() {
        const selected_option = this.getSelectedOption() || this.getSelectOptions()[0];

        return <div id="display-types-menu" style={{width: "200px"}}>
            <Select
                value={selected_option}
                onChange={this.onChange}
                menuPosition="fixed"
                options={this.getSelectOptions()}
                theme={theme => ({
                    ...theme,
                    spacing: {
                        ...theme.spacing,
                        baseUnit: 2,
                        controlHeight: 30
                    }
                })}
                components={{
                    IndicatorSeparator: null
                }}
            />
        </div>
    }
}

export default connect(
    (state: ReduxState, own_props: Props): $Shape<Props> => {
        const recommend_plans = state.gantt_chart_recommend_plans.recommend_plans;
        // check if we ran any recommendations and if we did, check if the last one has a stock report uuid
        const show_planning_stock_forecast = recommend_plans &&
            recommend_plans.length > 0 &&
            recommend_plans[recommend_plans.length - 1].stock_report_uuid !== undefined;

        return {
            show_planning_stock_forecast
        };
    },
    (dispatch) => ({reduxDispatch: dispatch})
)(DisplayTypesMenu);
