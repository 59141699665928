// @flow

import {
    TIME_RANGES,
    dateFromWeekAndShift
} from "../lib/Util";
import { getBackend } from "../lib/backend/Backend2";
import * as t2 from "../lib/SimulationReportModels";
import * as t from "../lib/backend/reports.generated.types";
import { INSIGHT_FILTERS } from "./manufacturing/PlanningTable2/reducers/ganttChartStandalone";
import { LINE_TAGS_ACCESS, INSIGHT_TYPES, INSIGHT_TAGS } from "../lib/ManufacturingTags.generated";
import { ReportLogic } from "./manufacturing/PlanningTable2/reducers/BusinessLogic";

import type { IEventDataEx } from "../lib/backend/reports.generated.types";

type LineWithTags = {
    uuid: string;
    tags: t.ITags;
}

type ResolveConflictingOrders = {
    report_lines: t2.ISimulationReportOrderLineEx[]
}

const calculateNextShiftHour = (simulation: t2.IReportModelEx): number => {
    const from_shift = simulation.result.from_shift_time;
    const next_shift = simulation.result.next_shift_time;

    // check if the same, then no offset
    if (from_shift.year === next_shift.year &&
        from_shift.week === next_shift.week &&
        from_shift.shift_number === next_shift.shift_number) {

        return 0;
    }

    // if not the same, we need to compute two dates and see the difference
    const from_date = dateFromWeekAndShift(from_shift.week, from_shift.year, from_shift.shift_number);
    const next_date = dateFromWeekAndShift(next_shift.week, next_shift.year, next_shift.shift_number);

    return (next_date.getTime() - from_date.getTime()) / TIME_RANGES.HOUR;
}

const resolveConflictingOrders = (
    lines: t2.ISimulationReportOrderLineEx[],
    lines_data: LineWithTags[],
    next_shift_start_hour: number
): ResolveConflictingOrders => {
    // go line by line and sort orders on each line by estimated start
    for (let li = 0; li < lines.length; li++) {
        const line = lines[li];
        line.production.sort((a: t2.ISimulationReportOrderEx, b: t2.ISimulationReportOrderEx): number => {
            return (a.estimated_start[1].val < b.estimated_start[1].val) ? -1
                : (a.estimated_start[1].val > b.estimated_start[1].val) ? 1
                : a.order_external_id.localeCompare(b.order_external_id);
        });
    }
    // make deep copy
    const original_lines: t2.ISimulationReportOrderLineEx[] = JSON.parse(JSON.stringify(lines));

    // prepare map of lines, which are split into sublines
    // and remember number of sublines they have
    const lines_sublines_num_map = new Map<string, number>();
    for (const line of lines_data) {
        const subline_number = LINE_TAGS_ACCESS.sublines_number(line.tags);
        if (subline_number > 1) {
            lines_sublines_num_map.set(line.uuid, subline_number);
        }
    }

    // we ignore overlap if smaller than this
    const epsilon = 0.01;

    // prepare results
    const new_lines: t2.ISimulationReportOrderLineEx[] = [];
    for (const line of original_lines) {
        // if this line is split into sublines, resolve sublines
        if (lines_sublines_num_map.has(line.line)) {
            // prepare placeholders for the new lines
            const sublines = [];
            const num_lines = lines_sublines_num_map.get(line.line) || 1;
            for (let i = 0; i < num_lines; i++) {
                sublines.push({ ...line, production: [] });
            }
            // go over orders and place them in the correct subline
            for (const order of line.production) {
                const order_line_index = order.subline_index || 0;
                // $FlowFixMe
                sublines[order_line_index]?.production.push(order);
            }
            new_lines.push(...sublines);
        } else if (line.production.length === 0) {
            // if this line has no orders, just add it to the new lines
            new_lines.push(line);
        } else {
            // we have line with no predefined sublines and some orders
            // greedy algorithm to resolve conflicts between overlaping orders:
            // 1) sort orders by estimated start
            // 2) go over orders and place each on a subline. we see if this is
            //    possible by checking if the order does not start before the current
            //    order on the subline finishes. if it does, we move to the next subline
            //    and try again. if we reach the end of the sublines, we create a new one

            // array of sublines we need to create so that orders do not overlap
            const sublines = [];
            // for each subline we keep track when the last order finished
            // this is updated as we progress through the orders
            const sublines_last_finish = [];

            // go over orders and place them on sublines
            for (const order of line.production) {
                if (sublines.length === 0) {
                    // first order
                    sublines.push([order]);
                    if (order.estimated_completion[1].val > next_shift_start_hour) {
                        sublines_last_finish.push(order.estimated_completion[1].val);
                    } else {
                        sublines_last_finish.push(0.0);
                    }
                } else {
                    // not first order, find sumline with space to spare
                    let found_subline = false;
                    // if historic order with nothing in the future, just drop it on line 0
                    if (order.estimated_completion[1].val < next_shift_start_hour) {
                        sublines[0].push(order);
                        found_subline = true;
                    }
                    // order has some future, so we need to make sure not to overlap
                    if (!found_subline) {
                        for (let subline_idx = 0; subline_idx < sublines.length; subline_idx++) {
                            const subline = sublines[subline_idx];
                            const subline_last_finish = sublines_last_finish[subline_idx];
                            if (order.estimated_start[1].val >= subline_last_finish - epsilon) {
                                // we have space to spare
                                subline.push(order);
                                sublines_last_finish[subline_idx] = order.estimated_completion[1].val;
                                found_subline = true;
                                break;
                            }
                        }
                    }
                    // check if we need to create a new subline
                    if (!found_subline) {
                        sublines.push([order]);
                        sublines_last_finish.push(order.estimated_completion[1].val);
                    }
                }
            }

            // add sublines to the new lines
            for (const subline of sublines) {
                new_lines.push({ ...line, production: subline });
            }
        }
    }

    return {
        report_lines: new_lines
    };

}

type PrepareGanttChartArgs = {
    simulation: t2.IReportModelEx,
    line_tags: LineWithTags[]
}

type PrepareGanttChart = {
    report: t2.IReportModelEx
}

export async function prepareGanttChart2(args: PrepareGanttChartArgs): Promise<PrepareGanttChart> {
    const {
        simulation,
        line_tags
    } = args;

    // compute when does the next shift strat according to the simulation
    const next_shift_start_hour = calculateNextShiftHour(simulation);
    // check if we have any overlaps on lines
    let resolved = resolveConflictingOrders(
        simulation.result.orders,
        line_tags,
        next_shift_start_hour
    );
    // update the simulation with merged production data
    simulation.result.orders = resolved.report_lines;
    return { report: simulation };
}

export function filterInsightsByType(insight_types: string[], insights: IEventDataEx[]): IEventDataEx[] {
    // if we have filters, go over insights and keep only ones that fall through
    // first filter is on no input material - account for deliveries or not
    if (insight_types.includes(INSIGHT_FILTERS.filter_account_for_deliveries)) {
        // if we account for deliveries only external insights are relevant
        // when we do not account for deliveries then all insights are relelvant (internal and external)
        insights = insights.filter(i => i.type !== INSIGHT_TYPES.operation_no_input_material ||
            i.tags[INSIGHT_TAGS.operation_no_input_material_circle] === "external");
    }
    // second filter is ignore VERP materials
    if (!insight_types.includes(INSIGHT_FILTERS.filter_include_verp)) {
        // If we are here it means that the filter is disabled.
        // That means that we show only the ones that has missing materials, but that materials are not packaging (VERP) materials.
        insights = insights.filter(i =>
            i.type !== INSIGHT_TYPES.operation_no_input_material ||
            i.tags[INSIGHT_TAGS.material_external_type] !== "VERP");
    }
    // check if we can eat into safetey stock
    if (insight_types.includes(INSIGHT_FILTERS.filter_use_safety_stock)) {
        insights = insights.filter(i =>
            i.type !== INSIGHT_TYPES.operation_no_input_material ||
            i.tags[INSIGHT_TAGS.below_safety_stock] === "true");
    }
    return insights;
}

function filterLineInsightsByLine(line_uuids: string[], insights: IEventDataEx[]): IEventDataEx[] {
    const filtered_insights: IEventDataEx[] = [];
    const line_uuid_set = new Set(line_uuids);
    for (const insight of insights) {
        if (insight.tags.line_uuid !== undefined && line_uuid_set.has(insight.tags.line_uuid)) {
            filtered_insights.push(insight);
        } else if (insight.tags.line_uuids !== undefined) {
            const insight_line_uuids = insight.tags.line_uuids.split(",");
            if (insight_line_uuids.some(ilu => line_uuid_set.has(ilu))) {
                filtered_insights.push(insight);
            }
        }
    }
    return filtered_insights;
}

export async function prepareGanttChartInsightsCurrent(
    insight_types: string[],
    line_uuids: string[],
    from: number,
    to: number
): Promise<IEventDataEx[]> {
    let lines = line_uuids && line_uuids.length > 0 ? line_uuids : undefined;
    try {
        // insights we want to show on the gantt
        const ret = await getBackend().manufacturing.getInsights({
            from, types: insight_types, to, lines
        });
        let insights = filterInsightsByType(insight_types, ret.insights);
        return filterLineInsightsByLine(line_uuids, insights);
    } catch (e) {
        console.error(e);
        return [];
    }
}


export async function prepareGanttChartInsights(
    simulation: t2.IReportModelEx,
    insight_types: string[],
    line_uuids: string[],
    gantt_past_weeks: number,
    gantt_number_of_days: number
): Promise<IEventDataEx[]> {
    const week = simulation.result.next_shift_time.week;
    const year = simulation.result.next_shift_time.year;

    // initial time we start plotting the gantt chart -- always from start of the week
    const from = dateFromWeekAndShift(week, year, 0).getTime() - gantt_past_weeks * TIME_RANGES.WEEK;
    const to = from + (gantt_number_of_days + (gantt_past_weeks + 1) * 7) * TIME_RANGES.DAY;

    let insights = [];
    let line_uuids_param = line_uuids && line_uuids.length > 0 ? line_uuids : undefined;
    try {
        // insights we want to show on the gantt
        const ret = await getBackend().reports.getReportInsights({
            from, id: simulation.uuid, insight_types: insight_types, to, line_uuids: line_uuids_param
        });
        insights = ret.insights;
    } catch (e) {
        console.error(e);
    }

    return filterLineInsightsByLine(line_uuids, insights);
}

// get list of insights and return map from order uuid to insight list
export function splitInsightsPerOrder(insights: IEventDataEx[] | null): Map<string, IEventDataEx[]> {
    const insights_map: Map<string, IEventDataEx[]> = new Map();

    // check if any work, if no return empty map
    if (insights === null) { return insights_map; }

    // shortcut function for assing insight to the map
    const addToMap = (order_uuid: string, insight: IEventDataEx) => {
        if (insights_map.has(order_uuid)) {
            // $FlowFixMe
            insights_map.get(order_uuid).push(insight);
        } else {
            insights_map.set(order_uuid, [insight]);
        }
    };

    // go over insights and assign them to the order
    for (const insight of insights) {
        const order_uuid = ReportLogic.getOrderUuidFromInsight(insight);
        const order_uuids = ReportLogic.getOrderUuidsFromInsight(insight);
        if (order_uuid) {
            // we have only one order
            addToMap(order_uuid, insight);
        } else if (order_uuids) {
            // insight accounts for multiple orders
            for (const order_uuid of order_uuids) {
                addToMap(order_uuid, insight);
            }
        }
    }

    return insights_map;
}

export const PLAN_DIFF_TYPES = {
    line_change: "line_change",
    start_diff: "start_diff",
    completion_diff: "completion_diff",
    quantity_diff: "quantity_diff",
    enabled: "enabled",
    disabled: "disabled"
};

export type IPlanDiff = {
    type: string,
    order_uuid: string,
    order_external_id: string,
    material_uuid: string,
    material_title: string,
    // line_change
    org_line?: string,
    new_line?: string,
    // start_diff
    start_diff?: number,
    // completion_diff
    completion_diff?: number,
    // quantity_diff
    org_quantity?: number,
    new_quantity?: number
};

export function getReportDiff(current: t2.IReportModelEx, original: t2.IReportModelEx): IPlanDiff[] {
    // null has not diffs
    if (current === null || original === null) { return []; }

    // go over original report and remember orders
    const original_orders_map = new Map();
    const matched_orders_set = new Set();
    for (const line of original.result.orders) {
        for (const order of line.production) {
            order.line_uuid = line.line;
            original_orders_map.set(order.order_id, order);
            matched_orders_set.add(order.order_id);
        }
    }

    // go over current report and not down differences
    const diff_log: IPlanDiff[] = [];
    // we ignore differences smaller then X hours
    const EPSILON_HOURS = 1;
    for (const line of current.result.orders) {
        for (const order of line.production) {
            const order_params = {
                order_uuid: order.order_id,
                order_external_id: order.order_external_id,
                material_uuid: order.material,
                material_title: order.material_title
            }
            if (original_orders_map.has(order.order_id)) {
                // order is both in original and new report, compare parameters
                const original_order = original_orders_map.get(order.order_id);
                // check if line changed $FlowFixMe
                if (original_order.line_uuid !== line.line) {
                    diff_log.push({
                        type: "line_change",
                        //$FlowFixMe
                        org_line: original_order.line_uuid,
                        new_line: line.line,
                        ...order_params
                    });
                }
                // check if start moved $FlowFixMe
                const start_diff = order.estimated_start[1].val - original_order.estimated_start[1].val;
                // check if end moved $FlowFixMe
                const completion_diff = order.estimated_completion[1].val - original_order.estimated_completion[1].val;
                if (Math.abs(start_diff) > EPSILON_HOURS) {
                    diff_log.push({
                        type: "start_diff",
                        start_diff,
                        ...order_params
                    });
                } else if (Math.abs(completion_diff) > EPSILON_HOURS) {
                    // only show this if no start diff
                    diff_log.push({
                        type: "completion_diff",
                        completion_diff,
                        ...order_params
                    });
                }
                // check if quantity changed $FlowFixMe
                if (original_order.quantity_total !== order.quantity_total) {
                    diff_log.push({
                        type: "quantity_diff",
                        // $FlowFixMe
                        org_quantity: original_order.quantity_total,
                        new_quantity: order.quantity_total,
                        ...order_params
                    });
                }
                // remove from map so we can keep track of what orders are not in new report
                matched_orders_set.delete(order.order_id)
            } else {
                // order is in new report, not in old
                diff_log.push({
                    type: "enabled",
                    ...order_params
                });
            }
        }
    }
    for (const original_order_uuid of matched_orders_set.keys()) {
        const original_order = original_orders_map.get(original_order_uuid);
        diff_log.push({
            type: "disabled",
            // $FlowFixMe
            order_uuid: original_order.order_id,
            // $FlowFixMe
            order_external_id: original_order.order_external_id,
            // $FlowFixMe
            material_uuid: original_order.material,
            // $FlowFixMe
            material_title: original_order.material_title
        });
    }

    return diff_log;
}


export const onScroll = (source: Element | null) => (event: SyntheticEvent<HTMLDivElement>) => {
    let y = null;
    if (source) {
        y = event.currentTarget.scrollTop - 70;
    } else {
        y = window.scrollY - 24;
    }

    if (y < 0) y = 0

    const translate_y = `translate(0, ${y})`;
    const sticky_header_group = document.querySelector("#sticky-header-group");

    if (sticky_header_group) {
        sticky_header_group.setAttribute("transform", translate_y)
    }
}

type HighlightRet = {
    highlighted_date_index: number | null,
    highlighted_date_ts: number | null
}

export function unhighlight(el: HTMLElement) {
    if (el.getAttribute("is-weekend") === "true") {
        // $FlowFixMe
        el.style.fill = "red";
    } else {
        // $FlowFixMe
        el.style.fill = "#616e7c";
    }

    el.style.fontSize = "12px";
    el.style.fontWeight = "400";
    el.removeAttribute("is_highlighted");
}

export function highlightShiftAndTextDate(shift_number: number): HighlightRet | null {
    const highlight = (el) => {
        const is_highlighted = el.getAttribute("is_highlighted");
        if (is_highlighted) {
            unhighlight(el);
        } else {
            // $FlowFixMe
            el.style.fill = "#0A67D2";
            el.style.fontSize = "14px";
            el.style.fontWeight = "800";
            el.setAttribute("is_highlighted", "true");
        }

    }

    const elements = Array.from(document.querySelectorAll("[is_highlighted='true']"));
    for (const el of elements) {
        unhighlight(el);
    }

    const shift_number_el = document.querySelector(`[gridgroup-shift-number='${shift_number}']`);

    if (shift_number_el) {
        highlight(shift_number_el);
        const text_date = shift_number_el.getAttribute("text-date");

        if (text_date) {
            const text_date_el = document.querySelector(`#text-date[text-date="${text_date}"]`);

            if (text_date_el) {
                highlight(text_date_el);
                const text_date_ts = text_date_el.getAttribute("data-text-date-ts");

                return {
                    highlighted_date_index: parseInt(text_date, 10),
                    highlighted_date_ts: text_date_ts ? (new Date(parseInt(text_date_ts, 10))).getTime() : null,
                }
            }
        }
    }
    return null;
}


type Line = {
    line_index?: number,
    line_uuid?: string
}

export function highlightRescheduleLine(line: Line | null) {
    const elements = Array.from(document.querySelectorAll("[is_reschedule_highlighted_line]"));

    for (const el of elements) {
        // $FlowFixMe
        el.style.fill = "#616e7c";
        el.style.fontWeight = "400";
        el.removeAttribute("is_reschedule_highlighted_line");
    }

    if (line) {
        let line_el = null;

        if (line.line_uuid !== undefined) {
            line_el = document.querySelector(`#line-title[line_uuid="${line.line_uuid}"]`);
        } else if (line.line_index !== undefined) {
            line_el = document.querySelector(`#line-title[line_index="${line.line_index}"]`);
        }

        if (line_el) {
            // $FlowFixMe
            line_el.style.fill = "#c8534d";
            line_el.style.fontWeight = "800";
            line_el.setAttribute("is_reschedule_highlighted_line", "true");
        }
    }
}
