// @flow
// $FlowFixMe
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import * as t from "../../lib/backend/manufacturing2.generated.types";
import moment from "moment";
import { FormattedMessage } from "react-intl";

export type IStockRequirementsRow = {
    comment: string,
    date_expected: number,
    date_required: number,
    material_external_id: string,
    transaction_external_id: string,
    material_title: string,
    quantity: number,
    stock_location_title: string,
    vendor_title: string
};

type Props = {
    show: boolean,
    comment: string,
    stock_req: t.IStockRequirements,
    updateComment: (comment: string) => void,
    closeEditCommentModal: () => void,
};

export const StockRequirementsModal = (props: Props) => {


    const [comment, setComment] = useState(props.comment);
    const [showModal, setShowModal] = useState(false);

    const getMaterialInfo = () => {
        const changing_sr = props.stock_req;

        if (!changing_sr) {
            return "";
        }

        return (
            <div style={{ paddingBottom: "30px" }}>
                <div>
                    <span className="font-weight-bold">
                        <FormattedMessage id="Manufacturing.StocksView.date_expected" defaultMessage="Date expected" />:&nbsp;
                    </span>
                    <span>{moment(changing_sr.date_expected).format("YYYY-MM-DD")}</span>
                </div>
                <div>
                    <span className="font-weight-bold">
                        <FormattedMessage id="Manufacturing.StocksView.date_required" defaultMessage="Date required" />:&nbsp;
                    </span>
                    <span>{moment(changing_sr.date_required).format("YYYY-MM-DD")}</span>
                </div>
                <div>
                    <span className="font-weight-bold">
                        <FormattedMessage id="Manufacturing.StocksView.vendor_title" defaultMessage="Vendor title" />:&nbsp;
                    </span>
                    <span>{changing_sr.vendor_title}</span>
                </div>
                <div>
                    <span className="font-weight-bold">
                        <FormattedMessage id="Manufacturing.StocksView.transaction_external_id" defaultMessage="Transaction external id" />:&nbsp;
                    </span>
                    <span>{changing_sr.transaction_external_id}</span>
                </div>
                <div>
                    <span className="font-weight-bold">
                        <FormattedMessage id="Manufacturing.StocksView.material_title" defaultMessage="Material title" />:&nbsp;
                    </span>
                    <span>{changing_sr.material_title}</span>
                </div>
                <div>
                    <span className="font-weight-bold">
                        <FormattedMessage id="Manufacturing.StocksView.material_external_id" defaultMessage="Material external id" />:&nbsp;
                    </span>
                    <span>{changing_sr.material_external_id}</span>
                </div>
                <div>
                    <span className="font-weight-bold">
                        <FormattedMessage id="Manufacturing.StocksView.stock_location_title" defaultMessage="Stock location title" />:&nbsp;
                    </span>
                    <span>{changing_sr.stock_location_title}</span>
                </div>
                <div>
                    <span className="font-weight-bold">
                        <FormattedMessage id="Manufacturing.StocksView.quantity" defaultMessage="Quantity" />:&nbsp;
                    </span>
                    <span>{changing_sr.quantity}</span>
                </div>
            </div>
        );
    }

    useEffect(() => {
        setShowModal(props.show);
    }, [props.show]);

    return (
        <Modal bsSize="medium"
            show={showModal}
        >
            <Modal.Header>
                <Modal.Title>
                    <div className="modal-title">
                        <div className="modal-title-text">
                            {"Edit comment"}
                        </div>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <div>
                        {getMaterialInfo()}
                    </div>

                    <div>
                        <FormattedMessage id="stock_requirements.comment" defaultMessage="Comment" />:
                    </div>
                    <input
                        className="form-control"
                        style={{ width: "100%" }}
                        type="text" value={comment}
                        onChange={(event) => setComment(event.target.value)} />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className={"btn btn-outline-primary"} onClick={() => props.updateComment(comment)}>
                    <FormattedMessage id="common.save" defaultMessage="Save" />
                </button>

                <button className={"btn btn-outline-secondary"} onClick={props.closeEditCommentModal}>
                    <FormattedMessage id="common.back" defaultMessage="Back" />
                </button>


            </Modal.Footer>
        </Modal>
    );
}

export default StockRequirementsModal;
