// @flow
import * as React from "react";
import type {Node} from "react";
import { connect } from 'react-redux';
import ReactRouterPropTypes from "react-router-prop-types";
import { FormattedMessage } from "react-intl";
import { translate } from "../IntlProviderWrapper";

import { getBackend } from "../../lib/Backend";
import * as BusinessLogic from "../../lib/BusinessLogic";
import { TIME_RANGES } from "../../lib/Util";
import * as sf from "../../lib/StockForecast";

import {NavigationBarWithDropdown} from "../NavigationBar";
import DashboardStandalone from "../DashboardStandalone";

import StockStatusView from "./StockStatusView";

import MaterialStockForecastView from "./MaterialStockForecastView";
import { initStockForecastFilterProps } from "./MaterialStockForecastView";
import StockRequirementsView from "./StockRequirementsView";

import KanbanList from "./KanbanList";
import { getBackend as getBackend2 } from "../../lib/backend/Backend2";
import InsightsView from "./InsightsView";
import {
    LINE_GROUP_TAGS,
    LINE_TAGS,
    PLANNING_MODE,
    INSIGHT_TYPES,
    LINE_GROUP_TAGS_ACCESS
} from "../../lib/ManufacturingTags.generated";
import TooltipAdditionalInfo from "./TooltipAdditionalInfo";
import * as Redirect from "../URLHandler/Redirect";
import { STOCKS_VIEWS_VIEW_TYPES, TAGGED_VIEWS, redirectSelectedView } from "../ViewsCommon";
import { MaterialTranslations } from "../MaterialTranslations"
import Loader from "../Loader";
import LongTermStockForecast from "./LongTermStockForecast";

import type { StockStatusFiltersState } from "../redux/reducers/stockStatusFiltersReducer";
import type { StockForecastFilterProps, SelectedValueLabelDefaultFilter, DisplayType } from "./MaterialStockForecastView";
import type { ReduxState } from "../redux/reducers";
import type { AggregationTimeWindow } from "./MaterialStockForecast";

import {
    STOCK_REQUIREMENTS_TYPES
} from "../../lib/ManufacturingConsts.generated"

const VIEW_TYPES = STOCKS_VIEWS_VIEW_TYPES;

type StocksViewParams = {
    id?: string,
    type: string,
    title: string,
    title_code?: string,
    dashboard?: string
};

type DashboardViewFilterProps = {
    title?: string,
    title_code?: string,
    dashboard: string
}

type DashboardFilterProps = {
    views: DashboardViewFilterProps[],
    selected: number
}

type Props = {
    history: ReactRouterPropTypes.history,
    match: ReactRouterPropTypes.match,
    location: ReactRouterPropTypes.location,
    plant_uuid: string,
    line_group_uuid: string,
    stock_status_filters: StockStatusFiltersState,
    translations_loading: boolean
};

type State = {
    views: StocksViewParams[],
    selected_view: string,
    // filters for individual views
    stock_forecast_filter: StockForecastFilterProps,
    kanban_filter: DashboardFilterProps,
    lines: Array<any>
};

type DEFAULT_STOCK_FORECAST_FILTER_TYPE = {
    stock_forecast_produced_scenario: $Shape<StockForecastFilterProps>,
    stock_forecast_produced_required_scenario: $Shape<StockForecastFilterProps>,
    stock_forecast_consumed_scenario: $Shape<StockForecastFilterProps>,
    stock_forecast_consumed_received_scenario: $Shape<StockForecastFilterProps>
}

const DEFAULT_STOCK_FORECAST_FILTER: DEFAULT_STOCK_FORECAST_FILTER_TYPE = {
    stock_forecast_produced_scenario: {
        selected_display_type: sf.MATERIAL_FORECAST_TYPES.out,
        selected_subtype: sf.MATERIAL_FORECAST_SUBTYPES.combined,
        only_accounted_for_initial_stock: "yes",
        selected_location: sf.MATERIAL_FORECAST_LOCATIONS.plant,
        selected_aggregate: "day",
        selected_line_uuid: ""
    },
    stock_forecast_produced_required_scenario: {
        selected_display_type: sf.MATERIAL_FORECAST_TYPES.out,
        selected_subtype: sf.MATERIAL_FORECAST_SUBTYPES.requiredByShift,
        only_accounted_for_initial_stock: "yes",
        selected_location: sf.MATERIAL_FORECAST_LOCATIONS.plant,
        selected_aggregate: "day",
        selected_line_uuid: ""
    },
    stock_forecast_consumed_scenario: {
        selected_display_type: sf.MATERIAL_FORECAST_TYPES.in,
        selected_subtype: sf.MATERIAL_FORECAST_SUBTYPES.combined,
        only_accounted_for_initial_stock: "no",
        selected_location: sf.MATERIAL_FORECAST_LOCATIONS.shopfloor,
        selected_aggregate: "day",
        selected_line_uuid: ""
    },
    stock_forecast_consumed_received_scenario: {
        selected_display_type: sf.MATERIAL_FORECAST_TYPES.in,
        selected_subtype: sf.MATERIAL_FORECAST_SUBTYPES.receivedByShift,
        only_accounted_for_initial_stock: "no",
        selected_location: sf.MATERIAL_FORECAST_LOCATIONS.plant,
        selected_aggregate: "day",
        selected_line_uuid: ""
    }
}

export const MATERIAL_TYPE = {
    in: "in",
    out: "out"
};

const filterAdditionalInfo = (selected_view_id: string, filter: StockForecastFilterProps): Node => {
    if (!filter.display_types) return null;
    const selected_display_type = filter.display_types.find(t => t.code === filter.selected_display_type)

    if (!filter.subtypes) return null;
    const selected_subtype = filter.subtypes.find(t => t.code === filter.selected_subtype);

    if (!filter.only_accounted_for_initial_stocks) return null;
    const only_accounted_for_initial_stock = filter.only_accounted_for_initial_stocks.find(t => t.code === filter.only_accounted_for_initial_stock);

    if (!filter.locations) return null;
    const selected_location = filter.locations.find(t => t.code === filter.selected_location);

    const accounted_for = {
        yes: translate("common.unrestricted_use", "Unrestricted use"),
        no: translate("common.withdrawn_and_unaccounted", "Withdrawn and unaccounted")
    };

    return <div>
        <span>{translate("common.material", "Material")}: {selected_display_type && selected_display_type.title}</span><br/>
        <span>{translate("common.type", "Type")}: {selected_subtype && selected_subtype.title}</span><br/>
        <span>{translate("common.transactions_stock_status", "Transactions for stock status")}: {only_accounted_for_initial_stock && accounted_for[only_accounted_for_initial_stock.code]}</span><br/>
        <span>{translate("common.stock_locations_stock_status", "Stock locations for stock status")}: {selected_location && selected_location.title}</span><br/>
    </div>
}

class StocksView extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        const state: State = {
            views: [],
            selected_view: props.match.params.selected_view || VIEW_TYPES.stock_forecast.id,
            stock_forecast_filter: initStockForecastFilterProps(this.props.line_group_uuid),
            kanban_filter: { views: [], selected: 0 },
            lines: [],
            plant: null
        };
        this.state = state;
    }

    async componentDidMount() {
        await BusinessLogic.waitForManufacturingAsync();
        await MaterialTranslations.waitForTranslationsAsync(this.props.plant_uuid, this.props.line_group_uuid);
        this.loadViews(true);
    }

    async componentDidUpdate(prev_props: Props) {
        const selected_view = this.props.match.params.selected_view;
        const prev_selected_view = prev_props.match.params.selected_view;

        const plant_change = prev_props.plant_uuid !== this.props.plant_uuid;
        const line_group_change = prev_props.line_group_uuid !== this.props.line_group_uuid;
        const view_change = prev_selected_view !== selected_view;

        if (plant_change || line_group_change || view_change) {
            await BusinessLogic.waitForManufacturingAsync();
            await MaterialTranslations.waitForTranslationsAsync(this.props.plant_uuid, this.props.line_group_uuid);
            this.loadViews(plant_change || line_group_change, prev_selected_view);
        }
    }

    getTaggedViews = (): StocksViewParams[] => {
        const views = [];
        const tags = BusinessLogic.getPlantTags(this.props.plant_uuid)
        for (const key of Object.keys(TAGGED_VIEWS)) {
            const show_tagged_view = tags[key] === "true";
            if (show_tagged_view) {
                views.push(TAGGED_VIEWS[key]);
            }
        }

        return views;
    }

    async loadViews(reset_views: boolean, prev_selected_view?: string) {
        // get list of lines from the plant / line_group
        let line_group = BusinessLogic.getLineGroupForUser(this.props.line_group_uuid);

        if (line_group === null) { return; }
        const line_uuids = line_group ? line_group.line_uuids : [];
        const is_people_first_planning = (line_group.tags[LINE_GROUP_TAGS.planning_mode] === PLANNING_MODE.people_first);

        const res = await getBackend2().manufacturing.getLines({
            plant_uuid: this.props.plant_uuid,
            line_group_uuids: [this.props.line_group_uuid]
        });

        if (res.lines && res.lines.length > 0) {
            // filter to keep only lines on which we do stock analysis
            const lines = res.lines.filter(
                line => (
                    line.tags &&
                    (
                        line.tags[LINE_TAGS.skip_stock_analysis] === undefined ||
                        line.tags[LINE_TAGS.skip_stock_analysis] === "false"
                    )
                ));
            // check if we display stock location option, if not we default to plant
            const show_selected_location = BusinessLogic.getLineGroupTagBool(this.props.line_group_uuid,
                LINE_GROUP_TAGS.stock_forecast_show_shopfloor_option, true);
            const selected_location = show_selected_location ?
                this.state.stock_forecast_filter.selected_location : sf.MATERIAL_FORECAST_LOCATIONS.plant;

            this.setState({
                lines: lines,
                stock_forecast_filter: {
                    ...this.state.stock_forecast_filter,
                    selected_location,
                    selected_line_uuid: "",
                    lines
                }
            });
        }

        let views = [];
        if (reset_views) {
            // get dashboards from the backend
            const dashboards = await getBackend().dash.getDashboardsAsync();

            let kanban_dashboards = [];

            // go over dashboards and identify relevant ones
            for (const dashboard of dashboards) {
                if (dashboard.tags.type === "man") {
                    // get kanban dashboards
                    const line_uuid = dashboard.tags.line;
                    if (dashboard.tags.subtype === "kanban" && line_uuids.includes(line_uuid) && dashboard.active) {
                        const line_weight = BusinessLogic.getLineWeight(line_uuid);
                        kanban_dashboards.push({ uuid: `kanban_${line_uuid}`, dashboard: dashboard.uuid, title: dashboard.title, line_weight });
                    }
                }
            }
            // sort lines by their weights
            kanban_dashboards.sort((a, b) => (a.line_weight - b.line_weight));

            if (!is_people_first_planning) {
                views.push({
                    id: VIEW_TYPES.stock_status.id,
                    type: VIEW_TYPES.stock_status.id,
                    title: VIEW_TYPES.stock_status.title,
                    title_code: VIEW_TYPES.stock_status.title_code
                });
            }
            if (
                line_group.tags &&
                line_group.tags[LINE_GROUP_TAGS.stock_forecast] === "true" &&
                !is_people_first_planning
            ) {
                views.push({
                    id: VIEW_TYPES.stock_forecast.id,
                    type: VIEW_TYPES.stock_forecast.id,
                    title: VIEW_TYPES.stock_forecast.title,
                    title_code: VIEW_TYPES.stock_forecast.title_code
                });

                views = [...views, ...this.getTaggedViews()];

                views.push({
                    id: VIEW_TYPES.out_of_stock.id,
                    type: VIEW_TYPES.out_of_stock.id,
                    title: VIEW_TYPES.out_of_stock.title,
                    title_code: VIEW_TYPES.out_of_stock.title_code
                });
            }
            if (
                line_group.tags &&
                line_group.tags[LINE_GROUP_TAGS.long_term_stock_forecast] === "true"
            ) {
                views.push({
                    id: VIEW_TYPES.long_term_stock_forecast.id,
                    type: VIEW_TYPES.long_term_stock_forecast.id,
                    title: VIEW_TYPES.long_term_stock_forecast.title,
                    title_code: VIEW_TYPES.long_term_stock_forecast.title_code
                });
            }
            views.push({
                id: VIEW_TYPES.stock_requirements_inbounds.id,
                type: VIEW_TYPES.stock_requirements_inbounds.id,
                title: VIEW_TYPES.stock_requirements_inbounds.title,
                title_code: VIEW_TYPES.stock_requirements_inbounds.title_code
            });

            views.push({
                id: VIEW_TYPES.stock_requirements_outbounds.id,
                type: VIEW_TYPES.stock_requirements_outbounds.id,
                title: VIEW_TYPES.stock_requirements_outbounds.title,
                title_code: VIEW_TYPES.stock_requirements_outbounds.title_code
            });

            if (
                line_group.tags &&
                line_group.tags[LINE_GROUP_TAGS.stock_forecast] === "true" &&
                !is_people_first_planning
            ) {
                views.push({
                    id: VIEW_TYPES.stock_requirements_delay.id,
                    type: VIEW_TYPES.stock_requirements_delay.id,
                    title: VIEW_TYPES.stock_requirements_delay.title,
                    title_code: VIEW_TYPES.stock_requirements_delay.title_code
                });
            }

            // kanban view
            if (!is_people_first_planning) {
                const kanban_filter = { views: [], selected: 0 };
                kanban_filter.views.push({
                    title: "Edit kanban lines",
                    title_code: "Manufacturing.StocksView.edit_kanban_list",
                    dashboard: VIEW_TYPES.kanban.id
                })
                for (const kanban of kanban_dashboards) {
                    kanban_filter.views.push({
                        title: kanban.title,
                        dashboard: kanban.dashboard
                    });
                }
                this.setState({ kanban_filter });
                views.push({
                    id: VIEW_TYPES.kanban.id,
                    type: VIEW_TYPES.kanban.id,
                    title: VIEW_TYPES.kanban.title,
                    title_code: VIEW_TYPES.kanban.title_code,
                    dashboard: kanban_filter.views[0].dashboard
                });
            }
        }

        // assign appropriate view
        const url_view = this.props.match.params.selected_view;

        let selected_view = "";
        const possible_views = reset_views ? views : this.state.views;
        if (url_view) {
            // we have view selected by the URL, check if even possible
            const tagged_views = this.getTaggedViews();

            if (possible_views.find(view => view.id === url_view) || tagged_views.find(v => v.id === url_view)) {
                // all is good, mark it selected
                selected_view = url_view;
            } else {
                // url_view no longer available, select the top one
                selected_view = (possible_views.length > 0 && possible_views[0].id) ? possible_views[0].id : "";
            }
        } else {
            // no view indicated in url, select the top one
            selected_view = (possible_views.length > 0 && possible_views[0].id) ? possible_views[0].id : "";
        }

        let stock_forecast_filter = this.state.stock_forecast_filter;
        const is_tagged = Object.keys(TAGGED_VIEWS).includes(selected_view);
        const was_tagged = Object.keys(TAGGED_VIEWS).includes(prev_selected_view);

        if (Object.keys(TAGGED_VIEWS).includes(selected_view) && selected_view) {
            stock_forecast_filter = {
                ...initStockForecastFilterProps(this.props.line_group_uuid),
                lines: this.state.stock_forecast_filter.lines,
                ...DEFAULT_STOCK_FORECAST_FILTER[selected_view]
            }
        } else if (was_tagged && !is_tagged) {
            // we should reset stock_forecast_filter if we go from tagged to default
            stock_forecast_filter = {
                ...initStockForecastFilterProps(this.props.line_group_uuid),
                lines: this.state.stock_forecast_filter.lines,
            }
        }

        if (reset_views) {
            this.setState({
                views,
                selected_view,
                stock_forecast_filter
            });
        } else {
            this.setState({
                selected_view,
                stock_forecast_filter
            });
        }

        if (this.props.history.location.pathname.includes("/stocks/") ||
            this.props.history.location.pathname.includes("/stocks#") ||
            this.props.history.location.pathname.endsWith("/stocks")) {
            this.props.history.push({
                pathname: `/manufacturing/stocks/${selected_view}`,
                search: this.props.history.location.search
            });
            if (selected_view === "stock_status") {
                document.title = translate("Manufacturing.StocksView.stock_status", "Stock status") + " | Qlector LEAP";
            }
        }
    }

    handleViewChange = (event: Event) => {
        if (event.currentTarget instanceof HTMLInputElement ||
            event.currentTarget instanceof HTMLSelectElement ||
            event.currentTarget instanceof HTMLButtonElement) {
            // read new value
            const selected_view = event.currentTarget.value;

            // find new id
            this.setState({ selected_view });
            redirectSelectedView(selected_view, this.props.history);
        }
    }

    /////////////////////////////////////////
    // Material Stock Status View
    handleStockStatusDisplayTypeChange = (event: Event) => {
        if (event.currentTarget instanceof HTMLInputElement || event.currentTarget instanceof HTMLSelectElement) {
            // read new value
            const selected_display_type = event.currentTarget.value;

            const ssf = this.props.stock_status_filters;

            Redirect.stockStatusFilters(
                this.props.history,
                this.props.location.pathname,
                {
                    selected_display_type,
                    selected_line_uuid: ssf.selected_line_uuid,
                    order_external_id: ssf.order_external_id
                }
            );

        }
    }

    handleStockStatusDisplayTypeChange = (event: Event) => {
        if (event.currentTarget instanceof HTMLInputElement || event.currentTarget instanceof HTMLSelectElement) {
            const ssf = this.props.stock_status_filters;
            Redirect.stockStatusFilters(
                this.props.history,
                this.props.location.pathname,
                {
                    selected_display_type: event.currentTarget.value === "in" ? "in" : "out",
                    selected_line_uuid: ssf.selected_line_uuid,
                    order_external_id: ssf.order_external_id
                }
            );
        }
    }

    renderStockStatusFilter() {
        const { selected_line_uuid } = this.props.stock_status_filters;

        return (
            <li className="list-inline-item">
                <div className="form-group">
                    <span className="week-picker">
                        <select name="selected_display_type" className={`form-control select_top_control`}
                            value={this.props.stock_status_filters.selected_display_type}
                            onChange={this.handleStockStatusDisplayTypeChange}
                            title={translate("common.tooltip_show_input_produced", "Show input or produced materials")}>
                            {this.state.stock_forecast_filter.display_types &&
                                this.state.stock_forecast_filter.display_types.map(t =>
                                    <option key={t.code + "_display_type"} value={t.code}>{t.title}</option>
                                )}
                        </select>
                    </span>
                </div>
                <div className="form-group">
                    <label>
                        <FormattedMessage id="common.filters" defaultMessage="Filters" />
                    </label>
                    <span className="week-picker">
                        <select name="selected_line" className="form-control select_top_control select_top_max_200px"
                            value={selected_line_uuid || ""}
                            onChange={this.changeSelectedLineUUID}>
                            <option value="">{translate("Manufacturing.LineGroups.all_lines", "All Lines")}</option>
                            {this.state.lines.map((l, i) => <option key={i} value={l.uuid}>{l.title}</option>)}
                        </select>
                    </span>
                </div>
                <div className="form-group">
                    <input type="text" className="form-control search_bar"
                        placeholder={translate("Manufacturing.StockStatusView.search_by_order_external_id", "Filter by work order")}
                        name="order_external_id_filter"
                        value={this.props.stock_status_filters.order_external_id}
                        onChange={this.onChangeOrderExternalId}
                    />
                </div>
            </li>
        )
    }

    /////////////////////////////////////////
    // Material Stock Forecast View
    handleStockForecastDisplayTypeChangeForecast = (value: string) => {
        // read new value
        const selected_display_type = value;
        // make a copy of filter
        const stock_forecast_filter = JSON.parse(JSON.stringify(this.state.stock_forecast_filter));
        // update filter
        stock_forecast_filter.selected_display_type = selected_display_type;
        // reset state
        this.setState({ stock_forecast_filter });
    }

    handleStockForecastSubtypeChange = (event: Event) => {
        if (event.currentTarget instanceof HTMLInputElement || event.currentTarget instanceof HTMLSelectElement) {
            // read new value
            const selected_display_subtype = event.currentTarget.value;
            // make a copy of filter
            const stock_forecast_filter = JSON.parse(JSON.stringify(this.state.stock_forecast_filter));
            // update filter
            stock_forecast_filter.selected_subtype = selected_display_subtype;
            // reset state
            this.setState({ stock_forecast_filter });
        }
    }

    handleStockForecastOnlyAccountedForChange = (event: Event) => {
        if (event.currentTarget instanceof HTMLInputElement) {
            const checked = event.currentTarget.checked;
            // read new value
            const selected_option = checked ? "no" : "yes";
            // make a copy of filter
            const stock_forecast_filter = JSON.parse(JSON.stringify(this.state.stock_forecast_filter));
            // update filter
            stock_forecast_filter.only_accounted_for_initial_stock = selected_option;
            // reset state
            this.setState({ stock_forecast_filter });
        }
    }

    handleStockForecastLocationChange = (event: Event) => {
        if (event.currentTarget instanceof HTMLInputElement) {
            const checked = event.currentTarget.checked;
            // read new value
            const selected_display_location = checked ? sf.MATERIAL_FORECAST_LOCATIONS.shopfloor : sf.MATERIAL_FORECAST_LOCATIONS.plant;
            // make a copy of filter
            const stock_forecast_filter = JSON.parse(JSON.stringify(this.state.stock_forecast_filter));
            // update filter
            stock_forecast_filter.selected_location = selected_display_location;
            // reset state
            this.setState({ stock_forecast_filter });
        }
    }

    handleStockForecastSafetyStockChange = (event: Event) => {
        if (event.currentTarget instanceof HTMLInputElement) {
            const checked = event.currentTarget.checked;
            // read new value
            const safety_stock_can_be_used = checked;
            // make a copy of filter
            const stock_forecast_filter = JSON.parse(JSON.stringify(this.state.stock_forecast_filter));
            // update filter
            stock_forecast_filter.safety_stock_can_be_used = safety_stock_can_be_used;
            // reset state
            this.setState({ stock_forecast_filter });
        }
    }

    changeSelectedHorizon = (event: Event) => {
        if (event.currentTarget instanceof HTMLInputElement || event.currentTarget instanceof HTMLSelectElement) {
            this.setState({
                stock_forecast_filter: {
                    ...this.state.stock_forecast_filter,
                    selected_aggregate: event.currentTarget.value
                }
            });
        }
    }

    getStockForecastLocationTitle = (): DisplayType => {
        const empty = { code: "", title: "" };

        if (!this.state.stock_forecast_filter.locations) return empty;

        const location = this.state.stock_forecast_filter.locations.find(
            l => l.code === this.state.stock_forecast_filter.selected_location
        );
        if (location) {
            return location;
        }

        return empty;
    }

    changeSelectedHorizonStockForecast = (value: AggregationTimeWindow) => {
        this.setState({
            stock_forecast_filter: {
                ...this.state.stock_forecast_filter,
                selected_aggregate: value
            }
        });
    }

    renderStockForecastFilter() {
        const is_hidden = Object.values(TAGGED_VIEWS).find(
            (el: StocksViewParams | mixed) => el && el.id && el.id === this.state.selected_view
        );

        const location = this.getStockForecastLocationTitle();
        const line_group_tags = BusinessLogic.getLineGroupTags(this.props.line_group_uuid);
        const show_selected_location = LINE_GROUP_TAGS_ACCESS.stock_forecast_show_shopfloor_option(line_group_tags);
        const show_withdrawn_and_unaccounted = LINE_GROUP_TAGS_ACCESS.stock_forecast_show_withdrawn_and_unaccounted_option(line_group_tags);

        return <li className="list-inline-item">
            {show_withdrawn_and_unaccounted && <div className="form-group">
                <span className="week-picker" style={{display: "flex", alignItems: "center"}}>
                    <input
                        type="checkbox"
                        id="checkbox_withdrawn"
                        name="enabled"
                        checked={this.state.stock_forecast_filter.only_accounted_for_initial_stock === "no" ? "checked" : null}
                        onChange={this.handleStockForecastOnlyAccountedForChange}
                        title={translate("common.tooltip_withdrawn_unaccounted", "Include stocks which have not been transferred from MES to ERP yet")}
                    />
                    <label
                        htmlFor="checkbox_withdrawn"
                        style={{ marginLeft: "5px", cursor: "pointer" }}
                        title={translate("common.tooltip_withdrawn_unaccounted", "Include stocks which have not been transferred from MES to ERP yet")}
                    >
                        {translate("common.withdrawn_and_unaccounted", "Withdrawn and unaccounted")}
                    </label>
                </span>
            </div>}
            {show_selected_location && <div className="form-group">
                <span className="week-picker" style={{ display: "flex", alignItems: "center" }}>
                    <input
                        type="checkbox"
                        id="checkbox_location"
                        name="enabled"
                        checked={location.code === sf.MATERIAL_FORECAST_LOCATIONS.shopfloor ? "checked" : null}
                        onChange={this.handleStockForecastLocationChange}
                        title={translate("common.tooltip_include_shopfloor_warehouses", "Do not consider stock on non-production stock locations and call-offs.")}
                    />
                    <label
                        htmlFor="checkbox_location"
                        style={{ marginLeft: "5px", cursor: "pointer" }}
                        title={translate("common.tooltip_include_shopfloor_warehouses", "Do not consider stock on non-prodution stock locations and call-offs.")}
                    >
                        {translate("common.include_plant_stocks", "Include only shopfloor warehouses")}
                    </label>
                </span>
            </div>}
            {this.state.stock_forecast_filter.safety_stock_show && <div className="form-group">
                <span className="week-picker" style={{ display: "flex", alignItems: "center" }}>
                    <input
                        type="checkbox"
                        id="checkbox_safety_stock"
                        name="enabled"
                        checked={this.state.stock_forecast_filter.safety_stock_can_be_used ? "checked" : null}
                        onChange={this.handleStockForecastSafetyStockChange}
                        title={translate("common.tooltip_account_for_safety_stock", "Account for safety stock in forcasted stock levels")}
                    />
                    <label
                        htmlFor="checkbox_safety_stock"
                        style={{ marginLeft: "5px", cursor: "pointer" }}
                        title={translate("common.tooltip_account_for_safety_stock", "Account for safety stock in forcasted stock levels")}
                    >
                        {translate("common.include_safety_stocks", "Account for safety stock")}
                    </label>
                </span>
            </div>}
            <div className="form-group">
                <span className="week-picker">
                    <select name="selected_subtype" className="form-control select_top_control" hidden={is_hidden}
                        value={this.state.stock_forecast_filter.selected_subtype}
                        onChange={this.handleStockForecastSubtypeChange}>
                        {this.state.stock_forecast_filter.subtypes &&
                            this.state.stock_forecast_filter.subtypes.map(t =>
                                <option key={t.code + "_display_subtype"} value={t.code}>{t.title}</option>
                            )}
                    </select>
                </span>
            </div>
        </li>;
    }

    /////////////////////////////////////////
    // Stock Status and Material Stock Forecast common
    onChangeOrderExternalId = (event: Event) => {
        const selectedView = this.getSelectedView();

        if (!selectedView) { return; }

        if ((event.currentTarget instanceof HTMLInputElement || event.currentTarget instanceof HTMLSelectElement)) {
            if (selectedView.id === VIEW_TYPES.stock_status.id) {
                const ssf = this.props.stock_status_filters;
                const oei = event.currentTarget.value.split(",")[0];
                Redirect.stockStatusFilters(
                    this.props.history,
                    this.props.location.pathname,
                    {
                        selected_display_type: ssf.selected_display_type,
                        selected_line_uuid: ssf.selected_line_uuid,
                        order_external_id: typeof oei === "string" ? oei.trim() : oei
                    }
                );
            } else if (selectedView.id === VIEW_TYPES.stock_forecast.id) {
                this.setState({
                    stock_forecast_filter: {
                        ...this.state.stock_forecast_filter,
                        order_external_ids: event.currentTarget.value.split(",")
                    }
                });
            }
        }
    }

    /////////////////////////////////////////
    // Stock Status and Material Stock Forecast common
    onChangeOrderExternalIdForecast = (value: string) => {
        this.setState({
            stock_forecast_filter: {
                ...this.state.stock_forecast_filter,
                order_external_ids: value.split(",")
            }
        });
    }

    changeSelectedLineUUID = (event: Event) => {
        const selectedView = this.getSelectedView();

        if (!selectedView) { return; }

        if (event.currentTarget instanceof HTMLInputElement || event.currentTarget instanceof HTMLSelectElement) {
            if (selectedView.id === VIEW_TYPES.stock_status.id) {
                const ssf = this.props.stock_status_filters;
                Redirect.stockStatusFilters(
                    this.props.history,
                    this.props.location.pathname,
                    {
                        selected_display_type: ssf.selected_display_type,
                        order_external_id: ssf.order_external_id,
                        selected_line_uuid: event.currentTarget.value
                    }
                );
            } else if (selectedView.id === VIEW_TYPES.stock_forecast.id) {
                this.setState({
                    stock_forecast_filter: {
                        ...this.state.stock_forecast_filter,
                        selected_line_uuid: event.currentTarget.value
                    }
                });
            }
        }
    }

    changeSelectedLineUUIDForecast = (value: string) => {
        this.setState({
            stock_forecast_filter: {
                ...this.state.stock_forecast_filter,
                selected_line_uuid: value
            }
        });
    }

    /////////////////////////////////////////
    // Kanban
    handleKanbanViewChange = (event: Event) => {
        if (event.currentTarget instanceof HTMLInputElement || event.currentTarget instanceof HTMLSelectElement) {
            // read new value
            const selected = parseInt(event.currentTarget.value, 10);
            const kanban_filter = {
                views: this.state.kanban_filter.views,
                selected
            };
            this.setState({ kanban_filter });
        }
    }

    handleKanbanViewDashboard = (dashboard_uuid: string) => {
        let selected = 0;
        for (let i = 0; i < this.state.kanban_filter.views.length; i++) {
            if (this.state.kanban_filter.views[i].dashboard === dashboard_uuid) {
                selected = i;
                break;
            }
        }
        const kanban_filter = {
            views: this.state.kanban_filter.views,
            selected
        };
        this.setState({ kanban_filter });
    }

    renderKanbanFilter() {
        return <li className="list-inline-item">
            <label><FormattedMessage id="Manufacturing.LinesView.view" defaultMessage="View" /></label>
            <div className="form-group">
                <select name="select_view" className="form-control select_top_control"
                    value={this.state.kanban_filter.selected} onChange={this.handleKanbanViewChange}>
                    {
                        this.state.kanban_filter.views.map((view, i) => (view.title_code) ?
                            <option key={i} value={i}>{translate(view.title_code, view.title)}</option> :
                            <option key={i} value={i}>{view.title}</option>
                        )
                    }
                </select>
            </div>
        </li>;
    }

    /////////////////////////////////////////
    // Stocks View
    renderFilter(view: StocksViewParams) {
        if (view) {
            if (view.type === VIEW_TYPES.stock_forecast.id || Object.keys(TAGGED_VIEWS).includes(view.type)) {
                return this.renderStockForecastFilter();
            } else if (view.type === VIEW_TYPES.kanban.id) {
                return this.renderKanbanFilter();
            } else if (view.type === VIEW_TYPES.stock_status.id) {
                return this.renderStockStatusFilter();
            }
        }
        // nothing valid selected
        return <li className="list-inline-item"></li>;
    }

    setDefaultFilter = (type: SelectedValueLabelDefaultFilter) => {
        return () => {
            if (type === "order_external_id") {
                this.setState({
                    stock_forecast_filter: {
                        ...this.state.stock_forecast_filter,
                        order_external_ids: []
                    }
                });
            } else if (type === "line") {
                this.setState({
                    stock_forecast_filter: {
                        ...this.state.stock_forecast_filter,
                        selected_line_uuid: ""
                    }
                });
            } else if (type === "selected_display_type") {
                this.setState({
                    stock_forecast_filter: {
                        ...this.state.stock_forecast_filter,
                        selected_display_type: sf.MATERIAL_FORECAST_TYPES.out
                    }
                });
            }
        }
    }

    onTitleChange = (event: Event) => {
        if (event.currentTarget instanceof HTMLInputElement) {
            const value = event.currentTarget.value;
            if (this.state.stock_forecast_filter.title !== value) {
                this.setState({
                    stock_forecast_filter: {
                        ...this.state.stock_forecast_filter,
                        title: event.currentTarget.value
                    }
                });
            }
        }
    }

    renderContent(view: StocksViewParams) {
        if (view) {
            if (view.type === VIEW_TYPES.stock_status.id) {
                return <StockStatusView
                    plant_uuid={this.props.plant_uuid}
                    line_group_uuid={this.props.line_group_uuid}
                    filter={this.props.stock_status_filters}
                />
            } else if (view.type === VIEW_TYPES.stock_forecast.id || Object.keys(TAGGED_VIEWS).includes(view.type)) {
                return <MaterialStockForecastView
                    line_group_uuid={this.props.line_group_uuid}
                    filter={this.state.stock_forecast_filter}
                    line_uuid={this.state.stock_forecast_filter.selected_line_uuid}
                    selected_aggregate={this.state.stock_forecast_filter.selected_aggregate}
                    handleStockForecastDisplayTypeChange={this.handleStockForecastDisplayTypeChangeForecast}
                    changeSelectedLineUUID={this.changeSelectedLineUUIDForecast}
                    onChangeOrderExternalId={this.onChangeOrderExternalIdForecast}
                    onTitleChange={this.onTitleChange}
                    setDefaultFilter={this.setDefaultFilter}
                    changeSelectedHorizonStockForecast={this.changeSelectedHorizonStockForecast}

                />
            } else if (view.type === VIEW_TYPES.long_term_stock_forecast.id) {
                return <LongTermStockForecast />
            } else if (view.type === VIEW_TYPES.stock_requirements_inbounds.id) {
                return <StockRequirementsView
                    plant_uuid={this.props.plant_uuid}
                    line_group_uuid={this.props.line_group_uuid}
                    stock_requirements_type={STOCK_REQUIREMENTS_TYPES.received}
                />
            }  else if (view.type === VIEW_TYPES.stock_requirements_outbounds.id) {
                return <StockRequirementsView
                    plant_uuid={this.props.plant_uuid}
                    line_group_uuid={this.props.line_group_uuid}
                    stock_requirements_type={STOCK_REQUIREMENTS_TYPES.required}
                />
            } else if (view.type === VIEW_TYPES.kanban.id) {
                const dashboard_uuid = this.state.kanban_filter.views[this.state.kanban_filter.selected].dashboard;
                return dashboard_uuid === VIEW_TYPES.kanban.id ?
                    <KanbanList history={this.props.history}
                        line_group_uuid={this.props.line_group_uuid}
                        on_view={this.handleKanbanViewDashboard} /> :
                    <DashboardStandalone uuid={dashboard_uuid} asPublic={false}
                        show_filter_bar={false} history={this.props.history} match={this.props.match} />;
            } else if (view.type === VIEW_TYPES.out_of_stock.id) {
                const insight_types = [
                    INSIGHT_TYPES.out_of_stock_plant,
                    INSIGHT_TYPES.out_of_stock_shopfloor
                ];
                return <InsightsView history={this.props.history} match={this.props.match}
                    plant_uuid={this.props.plant_uuid} line_group_uuid={this.props.line_group_uuid}
                    insight_types={insight_types} from_diff={-TIME_RANGES.DAY} />;
            } else if (view.type === VIEW_TYPES.stock_requirements_delay.id) {
                const insight_types = [
                    INSIGHT_TYPES.stock_requirement_delay
                ];
                return <InsightsView history={this.props.history} match={this.props.match}
                    plant_uuid={this.props.plant_uuid} line_group_uuid={this.props.line_group_uuid}
                    insight_types={insight_types} from_diff={-TIME_RANGES.DAY} />;
            } else if (view.dashboard) {
                return <DashboardStandalone uuid={view.dashboard} asPublic={false}
                    show_filter_bar={false} history={this.props.history} match={this.props.match} />;
            } else {
                return <div></div>;
            }
        }
        // nothing valid selected
        return <div></div>;
    }

    getSelectedView = () => {
        return this.state.views.find((view) => (view.id === this.state.selected_view));
    }

    isTaggedView = (view_id: string): boolean => {
        return !!Object.values(TAGGED_VIEWS).find(el => el && el.id && el.id === view_id);
    }

    getViewTitle = (view: StocksViewParams): ?string => {
        if (!view.title_code) return;
        let out_title = translate(view.title_code, view.title);

        if (!view.id) return;
        const is_tagged = this.isTaggedView(view.id);

        if (is_tagged) {
            out_title = "- " + out_title;
        }

        return out_title;
    }

    getIsLoading(): boolean {
        // materials views
        const view = this.state.views.find((view) => (view.id === this.state.selected_view));
        if (!view) return this.props.translations_loading;

        return MaterialTranslations.getMaterialsViews().includes(view.id) && this.props.translations_loading;
    }

    shouldHideFilter = (view?: StocksViewParams) => {
        if (!view) {
            return true;
        }

        const view_types = [
            VIEW_TYPES.stock_forecast.id,
            ...Object.keys(TAGGED_VIEWS),
            VIEW_TYPES.kanban.id,
            VIEW_TYPES.stock_status.id
        ];

        return !view_types.includes(view.type);
    }

    render() {
        const view = this.state.views.find((view) => (view.id === this.state.selected_view));
        const style = {};
        const hide_filter_bar = this.shouldHideFilter(view);

        return (
            <React.Fragment>
                <NavigationBarWithDropdown
                    handleViewChange={this.handleViewChange}
                    key="A"
                    nav="views"
                    nav_id="stocks"
                    location={this.props.location}
                    line_group_uuid={this.props.line_group_uuid}
                    plant_uuid={this.props.plant_uuid}
                />
                {!hide_filter_bar && (
                    <div className="views-filter-bar views_always_top" key="B">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <ul className="list-inline">
                                    <li className="list-inline-item">
                                        <div style={{display: "flex", alignItems: "center"}}>
                                            {
                                                this.isTaggedView(this.state.selected_view) &&
                                                <TooltipAdditionalInfo
                                                    additional_info={filterAdditionalInfo(this.state.selected_view, this.state.stock_forecast_filter)}
                                                />
                                            }
                                        </div>
                                    </li>
                                    {view && this.renderFilter(view)}
                                </ul>
                            </div>
                        </div>
                    </div>
                )}
                <div className={"views-content " + (hide_filter_bar ? "hide-filter" : "")} key="C" style={style}>
                    {this.getIsLoading() === true ?
                        <Loader /> :
                        (view && this.renderContent(view))}
                </div>
            </React.Fragment>
        );
    }
}


export default connect(
    (state: ReduxState) => {
        return {
            stock_status_filters: state.stock_status_filters,
            translations_loading: state.translations_materials.is_loading
        }
    },
    null
)(StocksView);
