// @flow

// main imports
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import ReactRouterPropTypes from "react-router-prop-types";

// perun stuff
import { getBackend } from "../../lib/Backend";
import { getBackend as getBackend2 } from "../../lib/backend/Backend2";
import type { DashboardBaseObj } from "../../lib/Models";
import { LINE_TAGS } from "../../lib/ManufacturingTags.generated";
import type { ILineData } from "../../lib/backend/manufacturing2.generated.types";
import * as Auth from "../../lib/Auth";
import * as BusinessLogic from "../../lib/BusinessLogic";
import { USER_TAGS, DASHBOARD_TAGS } from "../../lib/CommonTags.generated";

import ErrorComponent from "../ErrorComponent";

type Props = {
    history: ReactRouterPropTypes.history,
    line_group_uuid: string,
    on_view?: (string) => void
};

type State = {
    dashboards: Array<DashboardBaseObj>,
    lines: Array<ILineData>,
    line_uuid: string,
    error: string
};

class KanbanList extends Component<Props, State> {

    /**
     * Constructor.
     *
     * @param {Props} props Props object.
     * @param {State} state State object.
     */
    constructor(props: Props, state: State) {
        super();

        this.state = {
            dashboards: [],
            lines: [],
            line_uuid: "",
            error: ""
        };
    }

    async componentDidMount() {
        try {
            await BusinessLogic.waitForManufacturingAsync();
            this.load();
        } catch (err) {
            this.setState({ error: err.message });
        }
    }

    async componentDidUpdate(prev_props: Props) {
        try {
            if (prev_props.line_group_uuid !== this.props.line_group_uuid &&
                this.props.line_group_uuid !== "") {

                await BusinessLogic.waitForManufacturingAsync();
                this.load();
            }
        } catch (err) {
            this.setState({ error: err.message });
        }
    }

    async load() {
        try {
            // get list of line uuids we can show
            const line_group = BusinessLogic.getLineGroupForUser(this.props.line_group_uuid);
            const line_uuids = line_group ? line_group.line_uuids : [];

            getBackend().dash.getDashboards(
                (dashboards) => {
                    this.setState({
                        dashboards: dashboards.filter(d =>
                            (d.tags[DASHBOARD_TAGS.type] === "man") && d.tags[DASHBOARD_TAGS.subtype] === "kanban" && line_uuids.includes(d.tags.line) && d.active)
                    });
                },
                (err) => {
                    this.setState({
                        dashboards: [],
                        error: err
                    });
                }
            );
            // get all lines from the line group
            const res = await getBackend2().manufacturing.getLines({ line_uuids });
            // filter out tool setup and other lines that are not part of planning
            const selected_lines = res.lines.filter(l => l.tags && l.tags[LINE_TAGS.skip_planning] !== "true");
            this.setState({
                lines: selected_lines,
                line_uuid: selected_lines.length > 0 ? selected_lines[0].uuid : ""
            });
        } catch (err) {
            this.setState({ error: err.message });
        }
    }

    handleInputChange = (event: Event) => {
        if (event.currentTarget instanceof HTMLInputElement || event.currentTarget instanceof HTMLSelectElement) {
            // read new values
            const target = event.currentTarget;
            const value = target.value;
            const name = target.name;

            // update new datasource placeholder
            this.setState(prev_state => {
                prev_state[name] = value;
                return prev_state;
            });
        }
    };

    handleSubmit = (event: Event) => {
        if (this.state.line_uuid !== "") {
            this.props.history.push("/digital-twin/resources/lines/" + encodeURI(this.state.line_uuid))
        }
    };

    render() {
        const is_kiosk = Auth.getUserTag(USER_TAGS.kiosk) === "true";
        return (
            <article className="article" key="1">
                <section id="statistics" className="data_sources">
                    <div className="vertical_tab">
                        <div className="container-fluid">
                            <ErrorComponent msg={this.state.error} type="error" />
                            <div className="row">
                                <div className="col-lg-12 col-md-12">
                                    <div className="white_box charts">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th><FormattedMessage id="common.line" defaultMessage="Line" /></th>
                                                    <th><FormattedMessage id="common.links" defaultMessage="Links" /></th>
                                                    {this.props.on_view && <th></th>}
                                                    {!is_kiosk && <th></th>}
                                                    {!is_kiosk && <th></th>}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.dashboards.map((d, idx) => (
                                                    <tr key={idx}>
                                                        <td>{d.title}</td>
                                                        {this.props.on_view && <td>
                                                            <button className="btn btn-outline-secondary"
                                                                onClick={() => { if (this.props.on_view) { this.props.on_view(d.uuid); } }}>
                                                                <FormattedMessage id="common.view" defaultMessage="View" />
                                                            </button>
                                                        </td>}
                                                        {!is_kiosk && <td>
                                                            <Link className="btn btn-outline-secondary" to={"/digital-twin/resources/lines/" + d.tags.line}>
                                                                <FormattedMessage id="common.edit" defaultMessage="Edit" />
                                                            </Link>
                                                        </td>}
                                                        <td>
                                                            <Link className="btn btn-outline-secondary" to={"/dashboard-public/" + d.uuid}>
                                                                <FormattedMessage id="common.screen" defaultMessage="Dashboard" />
                                                            </Link>
                                                        </td>
                                                        {false && !is_kiosk && <td>
                                                            <Link className="btn btn-outline-secondary" to={"/api/v1.0/dashboards/" + d.uuid}>
                                                                Internet Explorer
                                                            </Link>
                                                        </td>}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            {!is_kiosk && <div className="row">
                                <div className="col-lg-12 col-md-12">
                                    <div className="white_box">
                                        <div className="login-pin-container">
                                            <h5><FormattedMessage id="KanbanList.add_line" defaultMessage="Add line" /></h5>
                                            <div className="form-group form-group-sm">
                                                <div className="space20"></div>
                                                <div>
                                                    <select name="line_uuid" className="form-control select_top_control"
                                                        value={this.state.line_uuid} onChange={this.handleInputChange}>
                                                        {this.state.lines.map((line, i) => {
                                                            return <option key={i} value={line.uuid}>{line.title}</option>
                                                        })}
                                                    </select>
                                                </div>
                                                <div className="space10"></div>
                                                <div>
                                                    <div className="text-right">
                                                        <button className="btn btn-outline-secondary" onClick={this.handleSubmit}>
                                                            <FormattedMessage id="common.edit" defaultMessage="Edit" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>
                </section>
            </article>
        )
    }
}

export default KanbanList;