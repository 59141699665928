// @flow

import * as EmailValidator from "email-validator";
import * as PasswordValidator2 from "password-validator";
import VersionCheck from "../components/VersionCheck";

import { getBackend } from "../lib/backend/Backend2";
import { subscribe, MSG_TYPES, publish, publishAndWait } from "./PubSub";
import * as Util from "../lib/Util";

import type { RegisterUserObj, LoggedinUserObj } from "./Models";
import type { IImpersonateRes, ILoginUPRes, IOauth2Step2Res } from "../lib/backend/users.generated.types";

const FIELD_ROLES = "user.roles";
const FIELD_USER_TITLE = "user.title";
const FIELD_USER_JSON = "user.json";
const FIELD_USER_LANG = "user.lang";
const FIELD_ORG_TITLE = "user.org_title";
const FIELD_ORG_UUID = "user.org_uuid";
const FIELD_ORG_CODE = "user.org_code";
const FIELD_ORG_ALLOWS_PIN = "user.org_allows_pin";
const FIELD_USERNAME = "user.username";
const FIELD_USER_UUID = "user.uuid";
const FIELD_USER_PLANT = "user.plant";
const FIELD_USER_PLANTS = "user.plants";
const FIELD_USER_LINE_GROUP = "user.line_group";
const FIELD_USER_LINE_GROUPS = "user.line_groups";
const FIELD_USER_TAGS = "user.tags";
const FIELD_HOMEPAGE_DASHBOARD = "user.homepage_dashboard";
const FIELD_USER_MUST_RESET = "user.must_reset";
const FIELD_USER_MUST_ACTIVATE_TWOFA = "user.must_activate_twofa";
const FIELD_JWT_TOKEN = "jwtToken";
const FIELD_REDIRECT_URL = "login-redirect-url";
const FIELD_LAST_SEEN_VERSION = "user.last-seen-version";
const FIELD_NOTIFIED_VERSION = "user.notified-version";
export const FIELD_USER_TICKETS = "user.tickets";

export const ROLE_ADMIN = "Admin";
export const ROLE_POWER_USER = "PowerUser";
export const ROLE_DEMO_USER = "DemoUser";
export const ROLE_TICKET_USER = "TicketUser";
export const ROLE_MAN_ELECTRICITY_ADMIN = "ElectricityAdmin";
export const ROLE_MAN_ELECTRICITY_USER = "ElectricityUser";
export const ROLE_MAN_PLANNER = "ManPlanner";
export const ROLE_MAN_PLANT_MANAGER = "ManPlantManager";
export const ROLE_MAN_SHOPFLOOR_MANAGER = "ManShopfloorManager";

export interface IOauthProvider {
    value: string;
    label: string;
    field_caption: string;
};
const oauthProviders: IOauthProvider[] = [
    { value: "up", label: "Username and password", field_caption: "" },
    { value: "github", label: "GitHub", field_caption: "Github username" },
    { value: "azure-ad", label: "Azure Active Directory", field_caption: "Username (e.g. jack@supertech.onmicrosoft.com)" },
    { value: "adfs", label: "Active Directory", field_caption: "Username" },
];

export function getOAuthProviders(): IOauthProvider[] {
    return JSON.parse(JSON.stringify(oauthProviders));
}

let PasswordValidator = PasswordValidator2;

// needed for unit tests
if (process.env.NODE_ENV === "test") {
    PasswordValidator = PasswordValidator.default;
}

let password_schema = new PasswordValidator();
password_schema
    .is().min(8)
    .is().max(100)
    .has().uppercase()
    .has().lowercase()
    .has().digits()
    .has().symbols()
    .has().not().spaces();

const pin_schema = new PasswordValidator();
pin_schema
    .is().min(4)
    .is().max(100)
    .has().digits()
    .has().not().letters()
    .has().not().symbols()
    .has().not().spaces();

// handle of interval calls for reloading profile
let profileReloadTriggered: IntervalID | null = null;
// handle of interval calls for refreshing token
let tokenRefreshTriggered: IntervalID | null = null;
// handle of interval calls for checking version
let versionCheckTriggered: IntervalID | null = null;
// last seen version
let last_version: string | null = null;

// validate registration form parameters
export function validate(user: RegisterUserObj): string {
    if (user.title.length < 6) {
        return "Name must be at least 6 characters long";
    }
    if (!EmailValidator.validate(user.username)) {
        return "Invalid email address";
    }
    if (user.password !== user.password2) {
        return "Passwords do not match";
    }
    if (!password_schema.validate(user.password)) {
        return "Password must be at least 8 characters long, contain lowercase, " +
            "uppercase, numeric and symbol characters without spaces.";
    }
    if (!user.accept_terms) {
        return "You must accept Terms and conditions.";
    }
    return "";
}

// validate pin number
export function validatePin(pin: string): string {
    if (!pin_schema.validate(pin)) {
        return "Pin must be at least 4 characters long and contain " +
            "only numeric characters without spaces.";
    }
    return "";
}

export function validateEmail(email: string): boolean {
    return EmailValidator.validate(email);
}

// register new user with backend
export async function register(user: RegisterUserObj): Promise<void> {
    await getBackend().users.register({
        accept_terms: user.accept_terms,
        password: user.password,
        password2: user.password2,
        title: user.title,
        username: user.username
    });
}

// register new user with backend
export async function addUser(user: RegisterUserObj): Promise<string> {
    const res = await getBackend().users.createNewUser({
        password: user.password,
        title: user.title,
        username: user.username
    });
    return res.uuid;
}

// recover password
export async function recoverPassword(email: string): Promise<void> {
    await getBackend().users.preparePwdReset({ username: email })
}

export function validateNewPassword(old_password: string, password1: string, password2: string): string {
    if (old_password.length === 0) {
        return "Missing current password";
    }
    if (password1 !== password2) {
        return "Passwords do not match";
    }
    if (!password_schema.validate(password1)) {
        return "Password must be at least 8 characters long, contain lowercase, " +
            "uppercase, numeric and symbol characters without spaces.";
    }
    return "";
}

// change password
export async function changePassword(user_uuid: string, password: string, password_old: string): Promise<void> {
    await getBackend().users.changePassword({
        id: user_uuid,
        password_new: password,
        password_old
    });
    await reloadUserProfile(true);
}

export async function changePasswordWithCookie(username: string, password: string, cookie: string): Promise<void> {
    await getBackend().users.resetPwdWithCookie({ cookie, password_new: password, username });
    await reloadUserProfile(true);
}

function setNewJwtToken(token: string): void {
    localStorage.setItem(FIELD_JWT_TOKEN, token);
    publish(MSG_TYPES.auth_token_refreshed, { token });
}

async function commonLoginCallback(data: IImpersonateRes | ILoginUPRes | IOauth2Step2Res): Promise<void> {
    // remember token and user parameters
    setNewJwtToken(data.token);
    await Util.setTimeoutAsync(0); // this makes sure above stuff is processed
    localStorage.setItem(FIELD_USER_JSON, ""); // reset current profile so that it is reloaded
    localStorage.setItem(FIELD_USERNAME, data.username);
    localStorage.setItem(FIELD_USER_UUID, data.uuid);
    localStorage.setItem(FIELD_USER_TITLE, data.title);
    localStorage.setItem(FIELD_USER_MUST_RESET, "false");
    localStorage.setItem(FIELD_USER_MUST_ACTIVATE_TWOFA, "false");
    localStorage.setItem(FIELD_USER_LANG, data.lang);
    localStorage.setItem(FIELD_USER_PLANTS, "");
    localStorage.setItem(FIELD_USER_PLANT, "");
    localStorage.setItem(FIELD_USER_LINE_GROUPS, "");
    localStorage.setItem(FIELD_USER_LINE_GROUP, "");

    // get additional data for user
    await reloadUserProfile(true);
    triggerProfileReloadIfNeeded();
    triggerTokenRefreshIfNeeded();
}

export function setLoginRedirectURL(url: string) {
    if (!url.includes("login") && !url.includes("change_password")) {
        localStorage.setItem(FIELD_REDIRECT_URL, url);
    }
}

export function getLoginRedirectURL(): string {
    return localStorage.getItem(FIELD_REDIRECT_URL) || "/";
}

// Reload user profile at startup or after some changes have been made.
export async function reloadUserProfile(force?: boolean): Promise<void> {
    const user_data = await getBackend().users.getCurrentUserData({});

    const user_tickets = await getBackend().ticketing.getUserInbox({});
    const amount_of_tickets = String(user_tickets.inbox_items.length);
    localStorage.setItem(FIELD_USER_TICKETS, amount_of_tickets);
    publish(MSG_TYPES.user_tickets_reloaded, {});

    const current = localStorage.getItem(FIELD_USER_JSON);
    const new_data = JSON.stringify(user_data);
    console.log("Started with user-profile reload ...");
    if (force || current !== new_data) {
        localStorage.setItem(FIELD_USER_JSON, new_data);
        localStorage.setItem(FIELD_USER_LANG, user_data.user_data.lang);
        localStorage.setItem(FIELD_ORG_TITLE, user_data.org.title);
        localStorage.setItem(FIELD_ORG_UUID, user_data.org.uuid);
        localStorage.setItem(FIELD_ORG_CODE, user_data.org.code);
        localStorage.setItem(FIELD_ORG_ALLOWS_PIN, user_data.org.allows_pin ? "true" : "false");
        localStorage.setItem(FIELD_USER_MUST_RESET, user_data.user_data.must_reset ? "true" : "false");
        localStorage.setItem(FIELD_USER_MUST_ACTIVATE_TWOFA, user_data.user_data.must_activate_twofa ? "true" : "false");
        localStorage.setItem(FIELD_ROLES, user_data.roles.join(","));
        if (user_data.plants) {
            localStorage.setItem(FIELD_USER_PLANTS, user_data.plants.join(","));
            localStorage.setItem(FIELD_USER_PLANT, user_data.plants[0]);
        } else {
            localStorage.setItem(FIELD_USER_PLANTS, "");
            localStorage.setItem(FIELD_USER_PLANT, "");
        }
        if (user_data.line_groups) {
            localStorage.setItem(FIELD_USER_LINE_GROUPS, user_data.line_groups.join(","));
            localStorage.setItem(FIELD_USER_LINE_GROUP, user_data.line_groups[0]);
        } else {
            localStorage.setItem(FIELD_USER_LINE_GROUPS, "");
            localStorage.setItem(FIELD_USER_LINE_GROUP, "");
        }
        if (user_data.home_dashboard) {
            localStorage.setItem(FIELD_HOMEPAGE_DASHBOARD, user_data.home_dashboard);
        } else {
            localStorage.removeItem(FIELD_HOMEPAGE_DASHBOARD);
        }
        if (user_data.tags) {
            localStorage.setItem(FIELD_USER_TAGS, JSON.stringify(user_data.tags));
        } else {
            localStorage.setItem(FIELD_USER_TAGS, JSON.stringify({}));
        }
        publish(MSG_TYPES.user_profile_reloaded, {});
    } else {
        console.log("User profile reloaded, but no changes detected.");
    }
}

// impersonate a user
export async function impersonate(user_uuid: string): Promise<void> {
    const data = await getBackend().users.impersonate({ id: user_uuid });
    await logout(false);
    await commonLoginCallback(data);
}

// login the user given the username and password provided
export async function loginUP(username: string, password: string, remember: boolean, otp?: string): Promise<any> {
    const data = await getBackend().users.loginUP({ username, password, remember, otp });

    if (data.otp_required) {
        return true;
    }

    await commonLoginCallback(data);
}

// login the user given the username and password provided
export async function loginPin(pin: string): Promise<void> {
    const data = await getBackend().users.loginUP({ username: pin, password: "", remember: false });
    await commonLoginCallback(data);
}

// logout the user
export async function logout(should_publish: boolean = true): Promise<void> {
    if (tokenRefreshTriggered !== null) {
        clearInterval(tokenRefreshTriggered);
        tokenRefreshTriggered = null;
    }
    if (profileReloadTriggered !== null) {
        clearInterval(profileReloadTriggered);
        profileReloadTriggered = null;
    }
    // cleanup local storage with token and user info
    localStorage.removeItem(FIELD_JWT_TOKEN);
    localStorage.removeItem(FIELD_USER_JSON);
    localStorage.removeItem(FIELD_USERNAME);
    localStorage.removeItem(FIELD_USER_UUID);
    localStorage.removeItem(FIELD_USER_TITLE);
    localStorage.removeItem(FIELD_USER_LANG);
    localStorage.removeItem(FIELD_ORG_TITLE);
    localStorage.removeItem(FIELD_ORG_UUID);
    localStorage.removeItem(FIELD_ORG_CODE);
    localStorage.removeItem(FIELD_ORG_ALLOWS_PIN);
    localStorage.removeItem(FIELD_USER_MUST_RESET);
    localStorage.removeItem(FIELD_USER_MUST_ACTIVATE_TWOFA);
    localStorage.removeItem(FIELD_USER_PLANTS);
    localStorage.removeItem(FIELD_USER_PLANT);
    localStorage.removeItem(FIELD_USER_LINE_GROUPS);
    localStorage.removeItem(FIELD_USER_TAGS);
    localStorage.removeItem(FIELD_USER_LINE_GROUP);
    localStorage.removeItem(FIELD_HOMEPAGE_DASHBOARD);

    if (should_publish) {
        // logout performed, notify listeners
        await publishAndWait(MSG_TYPES.logout, {});
    }
}

// check if we are currently authenticated
export function isAuth(): boolean {
    return (localStorage.getItem(FIELD_JWT_TOKEN) !== null) && (localStorage.getItem(FIELD_USERNAME) || "") !== "";
}

export const PERMISSION_NAMES = {
    ShiftTableEdit: "ShiftTableEdit",
    PlanningTableEdit: "PlanningTableEdit",
    OrdersAndShiftsEdit: "OrdersAndShiftsEdit",
    LineGroupsEdit: "LineGroupsEdit",
    StockLocationEdit: "StockLocationEdit",
    MaterialEdit: "MaterialEdit",
    PeopleEdit: "PeopleEdit",
    PlantEdit: "PlantEdit",
    OrderEdit: "OrderEdit",
    SchedulingConfigEdit: "SchedulingConfigEdit",
    StockForecastFilesEdit: "StockForecastFilesEdit",
    ArchivedManualEntryOrderEdit: "ArchivedManualEntryOrderEdit",
    ManualEntryOrderTimeEdit: "ManualEntryOrderTimeEdit",
    MultipartMaterialAdd: "MultipartMaterialAdd",
    Everyone: "Everyone",
    AdminOnly: "AdminOnly",
    None: "None"
}
const permissions = {
    [PERMISSION_NAMES.ShiftTableEdit]: [ROLE_ADMIN, ROLE_DEMO_USER, ROLE_POWER_USER, ROLE_MAN_PLANNER, ROLE_MAN_SHOPFLOOR_MANAGER],
    [PERMISSION_NAMES.PlanningTableEdit]: [ROLE_ADMIN, ROLE_DEMO_USER, ROLE_POWER_USER, ROLE_MAN_PLANNER],
    [PERMISSION_NAMES.OrdersAndShiftsEdit]: [ROLE_ADMIN, ROLE_DEMO_USER, ROLE_POWER_USER, ROLE_MAN_PLANNER, ROLE_MAN_SHOPFLOOR_MANAGER],
    [PERMISSION_NAMES.LineGroupsEdit]: [ROLE_ADMIN, ROLE_DEMO_USER, ROLE_POWER_USER],
    [PERMISSION_NAMES.StockLocationEdit]: [ROLE_ADMIN, ROLE_DEMO_USER, ROLE_POWER_USER],
    [PERMISSION_NAMES.MaterialEdit]: [ROLE_ADMIN, ROLE_DEMO_USER, ROLE_POWER_USER],
    [PERMISSION_NAMES.PeopleEdit]: [ROLE_ADMIN, ROLE_DEMO_USER, ROLE_POWER_USER, ROLE_MAN_PLANNER, ROLE_MAN_SHOPFLOOR_MANAGER],
    [PERMISSION_NAMES.PlantEdit]: [ROLE_ADMIN, ROLE_DEMO_USER, ROLE_POWER_USER],
    [PERMISSION_NAMES.OrderEdit]: [ROLE_ADMIN, ROLE_DEMO_USER, ROLE_POWER_USER, ROLE_MAN_PLANNER, ROLE_MAN_SHOPFLOOR_MANAGER],
    [PERMISSION_NAMES.SchedulingConfigEdit]: [ROLE_ADMIN, ROLE_POWER_USER],
    [PERMISSION_NAMES.StockForecastFilesEdit]: [ROLE_ADMIN, ROLE_POWER_USER],
    [PERMISSION_NAMES.ArchivedManualEntryOrderEdit]: [ROLE_ADMIN, ROLE_POWER_USER],
    [PERMISSION_NAMES.ManualEntryOrderTimeEdit]: [ROLE_ADMIN, ROLE_POWER_USER, ROLE_MAN_PLANNER],
    [PERMISSION_NAMES.MultipartMaterialAdd]: [ROLE_ADMIN, ROLE_POWER_USER],
    [PERMISSION_NAMES.Everyone]: [ROLE_ADMIN, ROLE_DEMO_USER, ROLE_POWER_USER, ROLE_MAN_PLANNER, ROLE_MAN_SHOPFLOOR_MANAGER],
    [PERMISSION_NAMES.AdminOnly]: [ROLE_ADMIN],
    [PERMISSION_NAMES.None]: []
};

// check if user is in given role
export function hasPermission(permission: string): boolean {
    const req_roles = permissions[permission] || [];
    return isInRoles(req_roles);
}

// check if user is in given role
export function isInRole(role: string): boolean {
    const roles = (localStorage.getItem(FIELD_ROLES) || "")
        .split(",")
        .filter(x => x.length > 0);
    return roles.indexOf(role) >= 0;
}

// check if user is in at leats one of the given roles
export function isInRoles(roles: string[]): boolean {
    for (const role of roles) {
        if (isInRole(role)) {
            return true;
        }
    }
    return false;
}

// gets value of tags for current user
export function getUserTag(name: string): string | null {
    const tags = JSON.parse(localStorage.getItem(FIELD_USER_TAGS) || "{}");
    return tags[name];
}

export function getLoggedinUser(): LoggedinUserObj {
    return {
        home_dashboard: localStorage.getItem(FIELD_HOMEPAGE_DASHBOARD) || null,
        must_reset: localStorage.getItem(FIELD_USER_MUST_RESET) === "true",
        title: localStorage.getItem(FIELD_USER_TITLE) || "",
        username: localStorage.getItem(FIELD_USERNAME) || "",
        uuid: localStorage.getItem(FIELD_USER_UUID) || "",
        must_activate_twofa: localStorage.getItem(FIELD_USER_MUST_ACTIVATE_TWOFA) === "true"
    };
}

export function getOrgData(): { code: string, title: string, uuid: string, allows_pin: boolean } {
    return {
        allows_pin: localStorage.getItem(FIELD_ORG_ALLOWS_PIN) == "true",
        code: localStorage.getItem(FIELD_ORG_CODE) || "",
        title: localStorage.getItem(FIELD_ORG_TITLE) || "",
        uuid: localStorage.getItem(FIELD_ORG_UUID) || ""
    };
}

export function getUserPlants(): string[] {
    const value = localStorage.getItem(FIELD_USER_PLANTS);
    if (value && value !== "" && value !== undefined) {
        return value.split(",");
    } else {
        return [];
    }
}
export function getUserPlant(): string | null {
    const value = localStorage.getItem(FIELD_USER_PLANT);
    if (value && value !== "" && value !== undefined) {
        return value;
    } else {
        return null;
    }
}
export function getUserLineGroups(): string[] {
    const value = localStorage.getItem(FIELD_USER_LINE_GROUPS);
    if (value && value !== "" && value !== undefined) {
        return value.split(",");
    } else {
        return [];
    }
}
export function getUserLineGroup(): string | null {
    const value = localStorage.getItem(FIELD_USER_LINE_GROUP);
    if (value && value !== "" && value !== undefined) {
        return value;
    } else {
        return null;
    }
}
export function getUserLang(): string | null {
    const value = localStorage.getItem(FIELD_USER_LANG);
    if (value && value !== "" && value !== undefined && value !== "undefined") {
        return value;
    } else {
        return null;
    }
}

export function setUserLang(lang: string): void {
    localStorage.setItem(FIELD_USER_LANG, lang);
    publish(MSG_TYPES.user_profile_reloaded, {});
}

export async function oauth2Providers(): Promise<string[]> {
    const res = await getBackend().users.oauth2Providers({});
    return res.providers;
}

export async function oauth2Initiate(provider: string, type: string): Promise<void> {
    const data = await getBackend().users.oauth2Initiate({ provider, type });
    window.location = data.url;
}

export async function oauth2Step2(code: string, state: string): Promise<void> {
    const data = await getBackend().users.oauth2Step2({ code, state });
    await commonLoginCallback(data);
}


const oauth_engine_array: string[] = [];
export async function oauthEngine (state: string): Promise<boolean> {
    if (oauth_engine_array.includes(state)) {
        return false;
    } else {
        oauth_engine_array.push(state);
        return true;
    }
}

/** Simple function that triggers infinite reload of user-profile. */
function triggerProfileReloadIfNeeded() {
    if (profileReloadTriggered === null) {
        profileReloadTriggered = setInterval(() => {
            reloadUserProfile();
        }, 5 * 60 * 1000);
    }
}

/** This function established automatic token refresh in predefined interval. */
function triggerTokenRefreshIfNeeded() {
    if (tokenRefreshTriggered === null) {
        tokenRefreshTriggered = setInterval(async () => {
            try {
                const res = await getBackend().users.refreshToken({});
                setNewJwtToken(res.token);
            } catch (err) {
                console.log(err);
            }
        }, 10 * 60 * 1000);
    }
}

/** This function established automatic version check in predefined interval. */
function triggerVersionCheck() {

    // datetime when version-check last succeded
    let last_success_ts: number = 0;
    // this internal function is called inside a loop
    const singleCheck = async () => {
        try {
            // request static file from server, but inject timestamp into url to make it unique
            // this should avoid caching, which is crucial
            console.log("Version check, last success on ", new Date(last_success_ts).toISOString());
            const response = await fetch("/metadata.json?ts=" + Date.now(), { cache: "no-store" });
            const res = await response.json();
            last_success_ts = Date.now();
            console.log("App metadata", res);
            if (last_version === null) {
                console.log("Setting new version timestamp");
                last_version = res.timestamp;
                setLastSeenVersion(last_version);
            } else if (last_version && res && res.timestamp !== last_version) {
                console.warn("Version MISMATCH");
                console.warn('Clearing cache and hard-reloading...')
                VersionCheck.showVersionModal();
            } else {
                console.log("Version ok");
            }
        } catch (e) {
            // something went wrong
            console.error("Error while checking App metadata");
            console.error(e);
            // if the last successful check was more than 30 minutes ago, hard-reload the whole page
            if (Date.now() - last_success_ts >= 30 * Util.TIME_RANGES.MINUTE) {
                console.error("Reload triggered due to no success in pre-defined time-window");
                VersionCheck.showVersionModal();
            }
        }
    };

    if (versionCheckTriggered === null) {
        singleCheck(); // check immidiatelly
        versionCheckTriggered = setInterval(singleCheck, 1000 * 60 * 3); // then check every 3 minutes
    }
}

export function shouldNotifyNewVersion(): boolean {
    const is_kolektor = (getOrgData().title.indexOf("Kolektor") >= 0);
    if (!is_kolektor) {
        return false;
    }
    const last_seen_version = localStorage.getItem(FIELD_LAST_SEEN_VERSION) || "";
    const notified_version = localStorage.getItem(FIELD_NOTIFIED_VERSION) || "";
    return (last_seen_version !== notified_version);
}

export function markNewVersionNotified(): void {
    const last_seen_version = localStorage.getItem(FIELD_LAST_SEEN_VERSION) || "";
    localStorage.setItem(FIELD_NOTIFIED_VERSION, last_seen_version);
}

function setLastSeenVersion(version: string) {
    localStorage.setItem(FIELD_LAST_SEEN_VERSION, version);
}

// initialize current session
export async function initAuth(): Promise<void> {
    const token = localStorage.getItem(FIELD_JWT_TOKEN);
    let token_valid = false;
    if (token) {
        const res = await getBackend().users.validateToken({ token });
        token_valid = res.valid;
    }
    if (token_valid) {
        // set new token and wait until everyone is ready
        publishAndWait(MSG_TYPES.auth_token_refreshed, { token });
        // now reload profile as logged-in user
        await reloadUserProfile();
        publish(MSG_TYPES.login, {});
        triggerProfileReloadIfNeeded();
        triggerTokenRefreshIfNeeded();
    } else {
        // clear all user-related data
        logout();
    }
    triggerVersionCheck();

    // when someone requests logout, trigger this
    subscribe(MSG_TYPES.logout_request, async () => {
        logout();
    });
}
