// @flow

import React from "react";

// $FlowFixMe
import { ReactComponent as UserCircleIcon } from "@fortawesome/fontawesome-free/svgs/solid/user-circle.svg";
import SvgIcon, { type SvgIconProps } from "../SvgIcon";

const UserCircle = (props: SvgIconProps) => (
    <SvgIcon {...props} size={props.size || "fs"}>
        <UserCircleIcon />
    </SvgIcon>
);

export default UserCircle;
