// @flow
import type { PayloadAction } from "../../redux/types";

export const SET_INBOX_ITEM_VIEW: "inbox" = "inbox";
export const SET_INBOX_VIEW: "inbox_item" = "inbox_item";
export const SET_TICKETS_VIEW: "tickets" = "tickets";
export const SET_WORKFLOWS: "set_workflows" = "set_workflows";
export const SET_WORKFLOW: "set_workflow" = "set_workflow";
export const SET_INCLUDE_CLOSED: "set_include_closed" = "set_include_closed";
export const SET_TICKETS_DATA: "set_tickets_data" = "set_tickets_data";

export const TICKETING_VIEWS = {
    inbox: SET_INBOX_VIEW,
    inbox_item: SET_INBOX_ITEM_VIEW,
    tickets: SET_TICKETS_VIEW
};

export type SelectInboxItemView = PayloadAction<typeof SET_INBOX_ITEM_VIEW, string>;
export type SetInboxView = PayloadAction<typeof SET_INBOX_VIEW, typeof undefined>;
export type SetTicketsView = PayloadAction<typeof SET_TICKETS_VIEW, $Keys<typeof TICKETING_VIEWS> | typeof undefined>;
export type SetWorkflows = PayloadAction<typeof SET_WORKFLOWS, LabelValuePair[]>;
export type SetWorkflow = PayloadAction<typeof SET_WORKFLOW, string>;
export type SetIncludeClosed = PayloadAction<typeof SET_INCLUDE_CLOSED, boolean>;
export type SetTicketsData = PayloadAction<typeof SET_TICKETS_DATA, any>;

export type LabelValuePair = {
    label: string,
    value: string
}
export type TicketingState = {
    include_closed: boolean,
    workflows: LabelValuePair[],
    selected_workflow: string,
    selected_view: $Keys<typeof TICKETING_VIEWS>,
    selected_inbox_item_uuid: string | null,
    prev_view: $Keys<typeof TICKETING_VIEWS> | null,
    tickets: any
}

export const ticketing_initial_state: TicketingState = {
    include_closed: false,
    workflows: [],
    selected_workflow: "",
    prev_view: null,
    selected_view: "inbox_item",
    selected_inbox_item_uuid: null,
    tickets: []
};

export type TicketingActions = SelectInboxItemView | SetInboxView | SetTicketsView | SetWorkflows | SetWorkflow | SetIncludeClosed | SetTicketsData;

const resetViews = (state: TicketingState): TicketingState => {
    state.selected_inbox_item_uuid = null;
    state.prev_view = state.selected_view;
    return { ...state }
}

export const ticketingReducer = (
    state: TicketingState = ticketing_initial_state,
    action: TicketingActions
): TicketingState => {
    let new_state = state;
    if (action.type === SET_INBOX_ITEM_VIEW) {
        new_state = resetViews(new_state);
        new_state.selected_view = action.type;
        new_state.selected_inbox_item_uuid = action.data;
        return { ...new_state };
    } else if (action.type === SET_INBOX_VIEW) {
        new_state = resetViews(new_state);
        new_state.selected_view = action.type;
        return { ...new_state };
    } else if (action.type === SET_INCLUDE_CLOSED) {
        // new_state = resetViews(new_state);
        new_state.include_closed = action.data;
        return { ...new_state };
    } else if (action.type === SET_TICKETS_VIEW) {
        new_state = resetViews(new_state);
        if (action.data) {
            new_state.selected_view = action.data;
        }
        return new_state;
    } else if (action.type === SET_WORKFLOWS) {
        new_state.workflows = action.data;
        return { ...new_state };
    } else if (action.type === SET_WORKFLOW) {
        new_state.selected_workflow = action.data;
        return { ...new_state };
    } else if (action.type === SET_TICKETS_DATA) {
        new_state.tickets = action.data;
        return { ...new_state };
    }

    return new_state;
}
