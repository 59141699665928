// @flow

import { ApiClient, COMMON_HEADERS } from "./restApi";
import buildURL from "axios/lib/helpers/buildURL";

import type { AbortControllerType } from "./restApi";

/** ApiClient that uses axios library underneath */
export class RestFetchApi implements ApiClient {


    headers = {
        "Authorization": "",
        ...COMMON_HEADERS
    }

    // $FlowFixMe
    controllers = new Set<AbortControllerType>();

    post(url: string, body: any, headers: any): Promise<any> {
        // $FlowFixMe
        const controller = new AbortController();
        this.controllers.add(controller);
        return new Promise((resolve, reject) => {
            fetch(buildURL(url), {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    ...COMMON_HEADERS,
                    "Content-Type": "application/json",
                    "Authorization": this.headers["Authorization"]
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
                body: JSON.stringify(body),
                signal: controller.signal
            })
            .then(response => {
                this.controllers.delete(controller);
                return response.json();
            })
            .then(data => resolve({ data }))
            .catch(error => {
                this.controllers.delete(controller);
                return reject(error)
            });
        })
    }

    get(path: string, params?: any) {
        throw Error("Not implemented");
    }

    put(path: string, params: any) {
        throw Error("Not implemented");
    }

    delete(path: string, params: any) {
        throw Error("Not implemented");
    }

    setRequestHeader(key: string, val: string | null) {
        this.headers[key] = val;
    }

    abort(): void {
        for (const controller of this.controllers) {
            controller.abort();
        }
    }
}

