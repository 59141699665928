// @flow
import React from "react";
import { Overlay, Tooltip } from "react-bootstrap";

import type { Node } from "react";

type State = {
    show_tooltip: boolean,
}
type Props = {
    show_tooltip?: boolean,
    show_icon?: boolean,
    additional_info: Node,
    placement?: string,
    node?: Node
}

export default class TooltipAdditionalInfo extends React.Component<Props, State> {

    state = { show_tooltip: false }
    node = null;

    constructor(props: Props) {
        super(props);
        this.node = React.createRef();
    }

    onClick = (e: Event) => {
        e.stopPropagation();
        e.preventDefault();
        this.setState({ show_tooltip: !this.state.show_tooltip });
    }

    closeTooltip = () => this.setState({ show_tooltip: false });

    getNode = (): Node => {
        if (this.props.node) return this.props.node;
        return this.node && this.node.current;
    }

    render() {
        if (!this.props.additional_info) return null;
        const node = this.getNode();

        return <div ref={this.node} style={{marginLeft: "5px", cursor: "pointer"}}>
            {this.props.show_icon !== false && <i className="far fa-question-circle" onClick={this.onClick}> </i>}

            {node && <Overlay
                target={node}
                show={this.state.show_tooltip}
                rootClose
                onHide={this.closeTooltip}
                placement={this.props.placement || "right"}
            >
                <Tooltip id="additional-info-tooltip">
                    {this.props.additional_info}
                </Tooltip>
            </Overlay>}
        </div>
    }
}
