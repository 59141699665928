// @flow

import type { WeeklyReport, RealizationWeekShiftGrid } from "./Models";
import { getBackend } from "./backend/Backend2";
import { getDateOfISOWeek, toISODateString, dateFromWeekAndShift } from "./Util";
import * as BusinessLogic from "./BusinessLogic";
import { INSIGHT_TYPES } from "./ManufacturingTags.generated";
import * as t from "./backend/manufacturing2.generated.types";

type WeeklyRealizationReturn = {
    data: WeeklyReport[],
    next_shift_number: number,
    error?: string,
    warning?: string
}

export async function loadRealization(week: number, year: number, plant_uuid: string,
    line_uuids: string[], order_breakdown: boolean, sim_report_add_to_cache: boolean): Promise<WeeklyRealizationReturn> {
    // get reports
    const report = await _loadReport(week, year, plant_uuid, line_uuids, order_breakdown);
    const latest = await _loadLatestPrediction(week, year, line_uuids, order_breakdown, sim_report_add_to_cache);
    // merge reports
    return {
        data: mergeSimulationWithData(week, latest.next_shift_number, report.data, latest.data, undefined, BusinessLogic.getLineWeight),
        next_shift_number: latest.next_shift_number,
        error: _mergeStatus("error", report, latest),
        warning: _mergeStatus("warning", report, latest)
    };
}

export async function loadRealizationWithPlan(week: number, year: number, plant_uuid: string,
    line_uuids: string[], order_breakdown: boolean, sim_report_add_to_cache: boolean): Promise<WeeklyRealizationReturn> {

    // get reports
    const report = await _loadReport(week, year, plant_uuid, line_uuids, order_breakdown);
    const latest = await _loadLatestPrediction(week, year, line_uuids, order_breakdown, sim_report_add_to_cache);
    const past = await _loadPastPrediction(week, year, line_uuids, order_breakdown, sim_report_add_to_cache);
    // merge reports
    return {
        data: mergeSimulationWithData(week, latest.next_shift_number, report.data, latest.data, past.data, BusinessLogic.getLineWeight),
        next_shift_number: latest.next_shift_number,
        error: _mergeStatus("error", report, latest, past),
        warning: _mergeStatus("warning", report, latest, past)
    };
}

export const loadInsights = async (line_uuids: string[], week: number, year: number): Promise<t.IEventDataEx[][]> => {
    const start_time = dateFromWeekAndShift(week, year, 0).getTime();
    const end_time = dateFromWeekAndShift(week, year, 21).getTime();
    const res_insights = await getBackend().manufacturing.getInsights({
        from: start_time, to: end_time,
        types: [INSIGHT_TYPES.man_downtime],
        lines: line_uuids
    });
    const insights_data = res_insights.insights;
    // filter out insights that start at the end of range or finish at start of range
    const insights_list = insights_data.filter(insight => {
        return insight.ts_to > start_time && end_time > insight.ts;
    })
    // assing to shifts for faster look-up when rendering
    const insights_shifts: t.IEventDataEx[][] = [];
    for (let shift_number = 0; shift_number < 21; shift_number++) { insights_shifts.push([]); }

    for (const insight of insights_list) {
        if (insight.tags.shift !== undefined) {
            const shift_number = parseInt(insight.tags.shift, 10);
            if (0 <= shift_number && shift_number < 21) {
                insights_shifts[shift_number].push(insight);
            }
        }
    }

    return insights_shifts;
}

export function realizationEmptyGrid(): RealizationWeekShiftGrid {
    return [
        [[], [], []],
        [[], [], []],
        [[], [], []],
        [[], [], []],
        [[], [], []],
        [[], [], []],
        [[], [], []]
    ];
}

export function realizationGrid(line_uuid: string, data: WeeklyReport[]): RealizationWeekShiftGrid {
    const grid = realizationEmptyGrid();
    for (const shift of data) {
        if (shift.line_uuid === line_uuid) {
            const day = Math.floor(shift.shift_number / 3);
            const day_shift = shift.shift_number % 3;
            if (0 <= day && day < 7 && 0 <= day_shift && day_shift < 3) {
                grid[day][day_shift].push(shift);
            }
        }
    }
    return grid;
}

async function _loadReport(week: number, year: number, plant_uuid: string,
    line_uuids: string[], order_breakdown: boolean): Promise<WeeklyRealizationReturn> {

    const res = await getBackend().manufacturing.weeklyProduced({
        week, year, plant_uuid, order_breakdown, line_uuids
    });

    // filter on selected lines and
    // add parameters to make it comparable to predictions
    const data: WeeklyReport[] = res.items
        .map((x): WeeklyReport => ({
            line_title: x.line_title,
            line_uuid: x.line_uuid,
            material_external_id: x.material_external_id,
            material_title: x.material_title,
            measurement_unit: x.measurement_unit,
            norm: x.norm,
            shift_number: x.shift_number,
            sum: x.sum,
            line_weight: x.line_weight,
            order_external_id: x.order_external_id,
            order_uuid: x.order_uuid,
            plan: 0,
            shift_time: {
                year: year,
                week: week,
                shift_number: x.shift_number,
                shift_date: ""
            },
            status: x.status,
            worker: x.worker
        }));

    if (data.length === 0) {
        return { data: [], next_shift_number: 0, warning: "Manufacturing.Microplan.no_data" };
    } else {
        return { data, next_shift_number: 0 };
    }
}

// We always look at the latest simulation report for the missing data.
async function _loadLatestPrediction(
    week: number, year: number, line_uuids: string[], order_breakdown: boolean,
    sim_report_add_to_cache?: boolean
): Promise<WeeklyRealizationReturn> {
    try {
        const res = await getBackend().reports.searchReports({ num_reports: 1 });
        if (res.reports && res.reports.length > 0) {
            const report_uuid = res.reports[0].uuid;
            return await _loadPrediction(line_uuids, report_uuid, week, year, order_breakdown, sim_report_add_to_cache);
        } else {
            return { data: [], next_shift_number: 0, warning: "Manufacturing.Microplan.no_past_plan" }; // TODO
        }
    } catch (e) {
        console.log(e);
        return { data: [], next_shift_number: 0, error: e.message };
    }
}

async function _loadPastPrediction(
    week: number, year: number, line_uuids: string[], order_breakdown: boolean,
    sim_report_add_to_cache?: boolean
): Promise<WeeklyRealizationReturn> {

    try {
        // we get the first prediction report from the first day of the week (i.e. Monday at midnight)
        const week_start_date = getDateOfISOWeek(week, year);
        const week_start_date_str: string = toISODateString(week_start_date);
        const res = await getBackend().reports.searchReports({ date: week_start_date_str });
        if (res.reports && res.reports.length > 0) {
            const report_uuid = res.reports[0].uuid;
            return await _loadPrediction(line_uuids, report_uuid, week, year, order_breakdown, sim_report_add_to_cache);
        } else {
            return { data: [], next_shift_number: 0, warning: "Manufacturing.Microplan.no_past_plan" };
        }
    } catch (e) {
        console.log(e);
        return { data: [], next_shift_number: 0, error: e.message };
    }
}

async function _loadPrediction(line_uuids: string[], report_uuid: string,
    week: number, year: number, order_breakdown: boolean, sim_report_add_to_cache?: boolean): Promise<WeeklyRealizationReturn> {

    // retrieve report
    const { report } = await getBackend().reports.getReportWeeklyRealization({
        id: report_uuid, line_uuids: line_uuids.join(","), order_breakdown, sim_report_add_to_cache, week
    });

    // extract realization by shift
    const data: WeeklyReport[] = report.result
        .map((x): WeeklyReport => ({
            line_title: x.line_title,
            line_uuid: x.line_uuid,
            material_external_id: x.material_external_id,
            material_title: x.material_title,
            measurement_unit: x.measurement_unit,
            norm: x.norm,
            shift_number: x.shift_number,
            sum: x.sum,
            line_weight: 0,
            order_external_id: x.order_external_id,
            order_uuid: x.order_uuid,
            plan: x.plan,
            shift_time: x.shift_time,
            status: x.status,
            worker: []
        }));

    // extract when predictions start
    const next_shift_time = report.input.data.next_shift_time;
    let next_shift_number = 0;
    if (next_shift_time.year < year) {
        next_shift_number = 0;
    } else if (next_shift_time.year > year) {
        next_shift_number = 21;
    } else {
        next_shift_number =
            (next_shift_time.week_number < week) ? 0 : // prediction started one of previous weeks, all this week is prediction
                (next_shift_time.week_number > week) ? 21 : // prediction starts one of next weeks, all is realization
                    next_shift_time.shift_number;
    }
    return { data, next_shift_number };
}

export function mergeSimulationWithData(
    week: number,
    next_shift_number: number,
    production_data: WeeklyReport[],
    latest_prediction: WeeklyReport[],
    past_prediction?: WeeklyReport[],
    getLineWeight: (string) => number
): WeeklyReport[] {

    // we start with realised data, we add line weights
    let merged_data = production_data
        .map(x => { x.line_weight = getLineWeight(x.line_uuid); return x });

    // prefilter predictions and add line weights
    latest_prediction = latest_prediction
        .filter(shift => (shift.shift_number >= next_shift_number))
        .map(x => { x.line_weight = getLineWeight(x.line_uuid); return x });
    // also past predictions as plan, if we have them
    if (past_prediction) {
        past_prediction = past_prediction
            .map(x => { x.line_weight = getLineWeight(x.line_uuid); return x });
    }

    // sort so we can do merge
    let compFun = (x: WeeklyReport, y: WeeklyReport): number => {
        const x_line_weight = x.line_weight ? x.line_weight : 0;
        const y_line_weight = y.line_weight ? y.line_weight : 0;
        if (x_line_weight < y_line_weight) { return -1; }
        else if ((x_line_weight === y_line_weight) && x.line_uuid < y.line_uuid) { return -1; }
        else if ((x_line_weight === y_line_weight) && (x.line_uuid === y.line_uuid) &&
            (x.material_external_id < y.material_external_id)) { return -1; }
        else if ((x_line_weight === y_line_weight) && (x.line_uuid === y.line_uuid) &&
            (x.material_external_id === y.material_external_id) &&
            (x.shift_number < y.shift_number)) { return -1; }
        else if ((x_line_weight === y_line_weight) && (x.line_uuid === y.line_uuid) &&
            (x.material_external_id === y.material_external_id) &&
            (x.shift_number === y.shift_number)) { return 0; }
        return 1;
    };
    merged_data.sort(compFun);
    latest_prediction.sort(compFun);
    if (past_prediction) { past_prediction.sort(compFun); }

    // merge realised with latest predictions
    let merged_i = 0, pred_i = 0;
    let data = [];
    while ((merged_i < merged_data.length) && (pred_i < latest_prediction.length)) {
        const md = merged_data[merged_i];
        const lp = latest_prediction[pred_i];
        const diff = compFun(md, lp);
        if (diff === 0) {
            data.push(md);
            md.plan = lp.sum; // store prediction into plan
            merged_i++;
            pred_i++;
        } else if (diff < 0) {
            data.push(md);
            merged_i++;
        } else {
            if (lp.shift_number == next_shift_number) {
                // store prediction into plan
                lp.plan = lp.sum;
                lp.sum = 0;
            }
            data.push(lp);
            pred_i++;
        }
    }
    while (merged_i < merged_data.length) {
        data.push(merged_data[merged_i]);
        merged_i++;
    }
    while (pred_i < latest_prediction.length) {
        data.push(latest_prediction[pred_i]);
        pred_i++;
    }

    if (past_prediction) {
        // merge with past predictions, to get the planed value
        merged_i = 0; pred_i = 0;
        merged_data = data;
        data = [];
        while ((merged_i < merged_data.length) && (pred_i < past_prediction.length)) {
            const diff = compFun(merged_data[merged_i], past_prediction[pred_i]);
            if (diff === 0) {
                let record = merged_data[merged_i];
                record.plan = past_prediction[pred_i].sum;
                data.push(record);
                merged_i++; pred_i++;
            } else if (diff < 0) {
                merged_data[merged_i].plan = 0;
                data.push(merged_data[merged_i]);
                merged_i++;
            } else {
                let record = past_prediction[pred_i];
                record.plan = record.sum;
                record.sum = 0;
                data.push(record);
                pred_i++;
            }
        }
        while (merged_i < merged_data.length) {
            data.push(merged_data[merged_i]);
            merged_i++;
        }
        while (pred_i < past_prediction.length) {
            let record = past_prediction[pred_i];
            record.plan = record.sum;
            record.sum = 0;
            data.push(record);
            pred_i++;
        }
    }

    return data;
}

function _mergeStatus(key: string, r1: WeeklyRealizationReturn, r2: WeeklyRealizationReturn, r3?: WeeklyRealizationReturn) {
    if (r1[key]) { return r1[key]; }
    if (r2[key]) { return r2[key]; }
    if (r3 && r3[key]) { return r3[key]; }
    return "";
}
