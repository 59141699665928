// @flow

// main imports
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { toSloDateTimeString, stringCompare } from "../../lib/Util";

// backend
import { getBackend } from "../../lib/backend/Backend2";
import * as t from "../../lib/backend/batch_operations.generated.types";
import * as Auth from "../../lib/Auth";
import { BATCHOP_GROUP_TAGS } from "../../lib/ManufacturingTags.generated";

// import subcomponents
import BatchOperationModal from "./BatchOperationModal";
import BatchOperationModalManual from "./BatchOperationModalManual";
import ConfirmationModal from "../ConfirmationModal";
import ErrorComponent from "../ErrorComponent";
import { translate } from "../IntlProviderWrapper";


type Props = {
    batchOpGroupUuid?: string,
    line_group_uuid?: string,
    readOnly: boolean,
    showHistoryLink?: boolean,
    showHeader: boolean
}

type State = {
    halb: boolean,
    manual: boolean,
    silo_input: boolean,
    group: t.IBatchOperationGroup | null,
    groups: t.IBatchOperationGroup[],
    locations: t.IBatchOperationLocationStatus[],
    selected: t.IBatchOperationLocationStatus | null,
    pendingOperation: t.IBatchOperation | null,
    showOperationModal: boolean,
    error: string,
    modalError: string,
    time: Date
}

class BatchOperations extends Component<Props, State> {
    intervalId: IntervalID;

    constructor(props: Props) {
        super(props);
        const state: State = {
            halb: false,
            line_external_id: null,
            manual: false,
            silo_input: false,
            group: null,
            groups: [],
            locations: [],
            selected: null,
            showOperationModal: false,
            pendingOperation: null,
            error: "",
            modalError: "",
            time: new Date()
        };
        this.state = state;
    }

    componentDidMount() {
        this.loadComponent();
        this.intervalId = setInterval(() => {
            if (!this.state.showOperationModal) {
                this.setState({
                    time: new Date()
                });
            }
        }, 1000 * 20);
    }

    componentDidUpdate(prev_props: Props) {
        if ((prev_props.line_group_uuid !== this.props.line_group_uuid) &&
            this.props.line_group_uuid !== "") {
            this.loadComponent();
        }
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    async loadComponent() {
        try {
            const allowed_lines_groups = Auth.getUserLineGroups();
            const api = getBackend().batchOperations;
            const res = await api.getGroups({});
            const groups = res.items
                .filter(x => allowed_lines_groups.indexOf(x.tags[BATCHOP_GROUP_TAGS.line_group]) >= 0);
            if (groups.length === 0) {
                this.setState({ error: "No location defined or allowed for current user." });
                return;
            }
            let group = groups[0];
            if (this.props.batchOpGroupUuid && this.props.batchOpGroupUuid.length > 0) {
                const hits = groups.filter(x => x.uuid === this.props.batchOpGroupUuid);
                if (hits.length > 0) {
                    group = hits[0];
                }
            }
            if (this.props.line_group_uuid && this.props.line_group_uuid.length > 0) {
                const hits = groups.filter(x => (x.tags && x.tags[BATCHOP_GROUP_TAGS.line_group] === this.props.line_group_uuid));
                if (hits.length > 0) {
                    group = hits[0];
                }

            }

            // temporarily allow both string and boolean value for tags
            const manual = (
                group.tags[BATCHOP_GROUP_TAGS.no_orders] !== undefined &&
                (group.tags[BATCHOP_GROUP_TAGS.no_orders] === true || group.tags[BATCHOP_GROUP_TAGS.no_orders] === "true")
            );
            const silo_input = (
                group.tags[BATCHOP_GROUP_TAGS.silo_input] !== undefined &&
                (group.tags[BATCHOP_GROUP_TAGS.silo_input] === true || group.tags[BATCHOP_GROUP_TAGS.silo_input] === "true")
            );
            const halb = (
                group.tags[BATCHOP_GROUP_TAGS.halb] !== undefined &&
                (group.tags[BATCHOP_GROUP_TAGS.halb] === true || group.tags[BATCHOP_GROUP_TAGS.halb] === "true")
            );

            this.setState({
                halb,
                manual,
                silo_input,
                groups,
                group,
                error: ""
            }, async () => {
                await this.refreshStatus();
            });
        } catch (err) {
            this.setState({ error: err.message });
        }
    }

    async refreshStatus() {
        const group = this.state.group;
        if (group !== null) {
            const api = getBackend().batchOperations;
            const status = await api.getCurrentStatus({ group_id: group.uuid });
            const locations = status.locations
                .filter(location => location.disabled !== true)
                .sort((a, b) => stringCompare(a.name, b.name));

            this.setState({ locations });
        }
    }

    async handleEdit(location: t.IBatchOperationLocationStatus) {
        this.setState({ selected: location, showOperationModal: true });
    }

    handleCancel() {
        this.setState({ showOperationModal: false });
    }

    async handleSave(location: t.IBatchOperationLocationStatus) {
        try {
            const op = location.operation;
            if (op === undefined) {
                return;
            }
            const api = getBackend().batchOperations;

            if (op.uuid === "") {
                await api.insert({
                    data: {
                        duration: op.recipe_duration,
                        end_time: op.end_time,
                        location_uuid: location.uuid,
                        orders: op.orders,
                        recipe: op.recipe_title,
                        start_time: op.start_time,
                        uuid: op.uuid
                    }
                });
            } else {
                await api.update({
                    data: {
                        duration: op.recipe_duration,
                        end_time: op.end_time,
                        location_uuid: location.uuid,
                        orders: op.orders,
                        recipe: op.recipe_title,
                        start_time: op.start_time,
                        uuid: op.uuid
                    },
                    uuid: op.uuid
                });
            }
            await this.refreshStatus();
            this.setState({
                showOperationModal: false,
                modalError: "",
                time: new Date()
            });
        } catch (err) {
            this.setState({ modalError: err.message });
        }
    }

    handleFinish(location: t.IBatchOperationLocationStatus) {
        const op = location.operation;
        if (op === undefined) {
            return;
        }
        this.setState({
            pendingOperation: {
                duration: op.recipe_duration,
                end_time: op.end_time,
                location_uuid: location.uuid,
                orders: op.orders,
                recipe: op.recipe_title,
                start_time: op.start_time,
                uuid: op.uuid
            }
        });
    }

    async handleConfirmFinish() {
        const operation = this.state.pendingOperation;
        if (operation === null) {
            return;
        }
        try {
            const api = getBackend().batchOperations;
            await api.archive({ uuid: operation.uuid });
            this.setState({
                showOperationModal: false,
                pendingOperation: null,
                error: ""
            });
            await this.loadComponent();
        } catch (err) {
            this.setState({
                showOperationModal: false,
                pendingOperation: null,
                error: err.message
            });
        }
    }

    handleCancelFinish() {
        this.setState({ pendingOperation: null });
    }

    handleGroupChange(event: Event) {
        if (event.currentTarget instanceof HTMLInputElement || event.currentTarget instanceof HTMLSelectElement) {
            // read new values
            const target = event.currentTarget;
            let value = target.value;
            const name = target.name;
            if (name === "selected_group") {
                let target_group = this.state.groups.find(x => x.uuid === value);
                if (target_group) {
                    this.setState({ group: target_group }, () => { this.refreshStatus(); });
                }
            }
        }
    }

    formatDuration(duration: number): string {
        const hours = Math.floor(duration / 3600000);
        const minutes = Math.ceil((duration - hours * 3600000) / 60000);
        // const seconds = Math.round((duration - hours * 3600000 - minutes * 60000) / 1000);
        let timeTokens = [];

        if (hours) {
            timeTokens.push(`${hours} h`);
        }

        if (minutes) {
            timeTokens.push(`${minutes} min`);
        }

        // if (!hours && !minutes) {
        //     timeTokens.push(`${seconds} s`);
        // }

        return timeTokens ? timeTokens.join(" ") : "0";
    }

    render() {
        const select_items = this.state.groups.map(x => ({ value: x.uuid, name: x.name, key: "k_" + x.uuid }));
        const selected_group_uuid = (this.state.group ? this.state.group.uuid : "");
        const selected = this.state.selected;

        return (
            <article>
                {!this.props.readOnly && selected !== null && this.state.manual && (
                    <BatchOperationModalManual
                        halb={this.state.halb}
                        silo_input={this.state.silo_input}
                        key={selected.uuid + Date.now().toString()}
                        show={this.state.showOperationModal}
                        location={selected}
                        error={this.state.modalError}
                        onCancel={() => { this.handleCancel() }}
                        onSave={obj => { this.handleSave(obj) }}
                    />
                )}
                {!this.props.readOnly && selected !== null && !this.state.manual && (
                    <BatchOperationModal
                        halb={this.state.halb}
                        silo_input={this.state.silo_input}
                        key={selected.uuid + Date.now().toString()}
                        show={this.state.showOperationModal}
                        location={selected}
                        error={this.state.modalError}
                        onCancel={() => { this.handleCancel() }}
                        onSave={obj => { this.handleSave(obj) }}
                    />
                )}
                {!this.props.readOnly && <ConfirmationModal
                    show={this.state.pendingOperation !== null} size="small"
                    title={translate("common.confirmation", "Confirmation")}
                    onAccept={() => { this.handleConfirmFinish() }}
                    onCancel={() => { this.handleCancelFinish() }}
                >
                    <FormattedMessage id="Manufacturing.BatchOp.ConfirmFinalizeOperation" defaultMessage="Are you sure you want to finalize the operation?" />
                </ConfirmationModal>}
                <section id="statistics" className="data_sources">
                    {this.props.showHeader && <div className="filter_bar">
                        <div className="container-fluid">
                            <div className="row align-items-center mx-n2">
                                <div className="col-sm flex-grow-0 flex-basis-auto w-sm-auto px-2 mr-sm-auto">
                                    <h2>
                                        {(this.state.group && this.state.group.name) || (
                                            <FormattedMessage id="Header.menu.batch_operations" defaultMessage="Batch operations" />
                                        )}
                                    </h2>
                                </div>
                                {selected_group_uuid && (
                                    <div className="col-sm flex-grow-0 flex-basis-auto w-sm-auto px-2">
                                        <select name="selected_group" className="form-control select_filter_control"
                                            value={selected_group_uuid} onChange={(e) => this.handleGroupChange(e)}>
                                            {
                                                select_items.map(x => {
                                                    return <option key={x.key} value={x.value}>{x.name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                )}
                                {this.props.showHistoryLink && (
                                    <div className="col-sm flex-grow-0 flex-basis-auto w-sm-auto px-2">
                                        <Link className="blue_link" to={"/kiosk/batch_operations_archive/" + selected_group_uuid}>
                                            <FormattedMessage id="common.history" defaultMessage="History" /> &#187;
                                        </Link>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>}
                    <div className="vertical_tab">
                        <div className="container-fluid">
                            <ErrorComponent type="error" msg={this.state.error} />
                            <div className="row">
                                {this.state.locations.map((location: t.IBatchOperationLocationStatus) => {
                                    let progress = 0;
                                    let remainingTime = 0;

                                    const loc_op = location.operation;
                                    if (loc_op !== undefined) {
                                        const operationTime = loc_op.end_time - loc_op.start_time;
                                        const elapsedTime = Math.min(this.state.time.getTime() - loc_op.start_time, operationTime);
                                        remainingTime = operationTime - elapsedTime;
                                        if (operationTime > 0) {
                                            progress = Math.floor(elapsedTime / operationTime * 100);
                                        } else {
                                            progress = 100;
                                        }
                                    }

                                    if (loc_op !== undefined && loc_op.uuid) {
                                        return <div className="col-xl-6 my-3" key={location.uuid}>
                                            <div className={`batch-operation card h-100${progress === 100 ? " finished" : ""}`}>
                                                <div className="card-header">
                                                    <div className="d-sm-flex justify-content-between align-items-center mx-n2">
                                                        <h3 className="card-title px-2 mb-2 mb-sm-0">{location.name} / <small>{loc_op.recipe_title}</small></h3>
                                                        <div className="remaining-time px-2">
                                                            {remainingTime > 0 ? (
                                                                <React.Fragment>
                                                                    <small>{translate("common.until_finished", "Until finished")}:</small> <strong className="font-weight-medium">
                                                                        <time>{this.formatDuration(remainingTime)}</time>
                                                                    </strong>
                                                                </React.Fragment>
                                                            ) : (
                                                                <strong className="text-uppercase font-weight-medium">{translate("common.finished", "Finished")}</strong>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <div className="duration row">
                                                        <div className="col">
                                                            <div className="d-sm-flex justify-content-between mb-1">
                                                                <div>
                                                                    <FormattedMessage id="common.start_noun" defaultMessage="Start" />: <strong className="font-weight-medium">
                                                                        {loc_op.start_time && toSloDateTimeString(new Date(loc_op.start_time))}
                                                                    </strong>
                                                                </div>
                                                                <div>
                                                                    <FormattedMessage id="common.end" defaultMessage="End" />: <strong className="font-weight-medium">
                                                                        {loc_op.end_time && toSloDateTimeString(new Date(loc_op.end_time))}
                                                                    </strong>
                                                                </div>
                                                            </div>
                                                            <div className="progress">
                                                                <div
                                                                    className="progress-bar"
                                                                    role="progressbar"
                                                                    aria-valuenow={progress}
                                                                    aria-valuemin="0"
                                                                    aria-valuemax="100"
                                                                    style={{ width: `${progress}%` }}
                                                                ></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div test-id="orders-values" className="orders row">
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th><FormattedMessage id="Manufacturing.BatchOp.WorkOrder" defaultMessage="Work Order" /></th>
                                                                    <th><FormattedMessage id="Manufacturing.BatchOp.Ident" defaultMessage="Ident" /></th>
                                                                    <th><FormattedMessage id="Manufacturing.BatchOp.Title" defaultMessage="Title" /></th>
                                                                    <th><FormattedMessage id="Manufacturing.BatchOp.Quantity" defaultMessage="Quantity" /></th>
                                                                    <th><FormattedMessage id="Manufacturing.BatchOp.Package" defaultMessage="Pallet" /></th>
                                                                    <th><FormattedMessage id="Manufacturing.BatchOp.Comment" defaultMessage="Comment" /></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {loc_op.orders.map((order, i) => (
                                                                    <tr key={i} className={this.state.silo_input && i === loc_op.orders.length - 1 ? "selected_input_fill" : ""} >
                                                                        <td test-id="order-num-value">{order.order_num}</td>
                                                                        <td test-id="order-name-value">{order.name}</td>
                                                                        <td test-id="order-material_title-value">{order.material_title}</td>
                                                                        <td test-id="order-quantity-value">{order.quantity}</td>
                                                                        <td test-id="order-batch-value">{order.batch}</td>
                                                                        <td test-id="order-comment-value">{order.comment}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className="card-footer d-flex align-items-center justify-content-end">
                                                    {!this.props.readOnly && <button test-id="location-edit-button" className="btn btn-outline-primary" onClick={() => { this.handleEdit(location) }}>
                                                        <FormattedMessage id="common.edit" defaultMessage="Edit" />
                                                    </button>}
                                                    {!this.props.readOnly && <button className="btn btn-primary" onClick={() => { this.handleFinish(location) }}>
                                                        <FormattedMessage id="common.finalize" defaultMessage="Finalize" />
                                                    </button>}
                                                </div>
                                            </div>
                                        </div>;
                                    } else {
                                        return (
                                            <div className="col-xl-6 my-3" key={location.uuid}>
                                                <button className="batch-operation btn btn-primary w-100 h-100"
                                                    onClick={() => { this.handleEdit(location) }}>
                                                    <strong>{location.name}</strong>
                                                    {!this.props.readOnly && <FormattedMessage id="common.start_verb" defaultMessage="Start" />}
                                                </button>
                                            </div>
                                        );
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                </section>
            </article>
        )
    }
}

export default BatchOperations;
