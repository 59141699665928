// @flow

/************************************************************************************/
/* WARNING: This file is generated automatically. Don't edit it by hand             */
/************************************************************************************/

/** Possible job statuses */
export const JOB_STATUS = {
    /** Job has been created */
    created: "created",
    /** Job has ended successfully */
    ended: "ended",
    /** Job has been put into processing queue */
    enqueued: "enqueued",
    /** Job has ended with error */
    errored: "errored",
    /** The processing of the job has started */
    started: "started"
};
export type JobStatusEnum =
    "created" |
    "ended" |
    "enqueued" |
    "errored" |
    "started";
