// @flow
import { GanttChartSource } from "./reducers/properties";
import { getBackend as getBackend2 } from "../../../lib/backend/Backend2";
import { getOrders } from "./Draggable/DraggableElements";
import { getConflictResolutionLineIndex } from "./reducers/common";

import type { MoveOrderGanttActionData } from "./reducers/common";

export const MOVE_ORDER_TIME_LABELS = {
    now: "now",
    specific_time: "specific_time",
    after_seleted_order: "after_seleted_order",
    same_time_as_selected_order: "same_time_as_selected_order"
};

type MoveOrderModalData = {
    selected_line_uuid: string,
    current_order_uuid: string,
    new_earliest_start: number,
    current_order_material_external_id: string,
    current_order_process_num: string,
    is_unscheduled_order: boolean,
    current_line_uuid: string
}

type GetProcessesRet = {
    process_num?: string,
    process_uuid?: string
}

export class MoveOrderModalHelper {
    // should offer the same functionalities a drag and drop does
    // functionalities:
    // 1. Move order to specific time
    // 2. Move order to specific index
    // 3. Move order to specific line
    // 4. Move order to specific subline
    // 5. Move order to unscheduled
    // 6. Move order from unscheduled to scheduled

    static getProcesses = async (
        selected_line_uuid: string,
        current_order_material_external_id: string,
        current_order_process_num: string
    ): GetProcessesRet => {
        let process_uuid = undefined;
        let process_num = undefined;

        if (selected_line_uuid) {
            const res = await getBackend2().manufacturing.getProcesses({
                line_uuid: selected_line_uuid,
                material_external_id: current_order_material_external_id,
                process_num: current_order_process_num,
                only_bottleneck: false
            });

            if (res.processes.length > 0) {
                process_uuid = res.processes[0].uuid;
                process_num = res.processes[0].process_num;
            }
        }

        return { process_num, process_uuid };
    }

    static moveOrderToSpecificTime = async (args: MoveOrderModalData): Promise<MoveOrderGanttActionData | null> => {
        const {
            selected_line_uuid,
            current_order_uuid,
            current_order_material_external_id,
            current_order_process_num,
            is_unscheduled_order,
            new_earliest_start,
            current_line_uuid
        } = args;

        const line_selector = `#pane1 #gantt-chart-line-group[line-uuid='${selected_line_uuid}']`;
        const line_gantt = document.querySelector(line_selector);
        if (!line_gantt) {
            return null;
        }

        const line_index = parseInt(line_gantt.getAttribute("line-index"));
        const new_order_index = getOrders(
            line_index,
            current_order_uuid,
            new_earliest_start,
            GanttChartSource.planning_table_parallel
        ).length;

        const available_lines = await getBackend2().manufacturing.getOrderLines({ uuid: current_order_uuid });
        const selected_line = available_lines.lines.find(line => line.uuid === selected_line_uuid) || { prod_ver_ext_id: "" };

        const {
            process_num,
            process_uuid
        } = await MoveOrderModalHelper.getProcesses(
            selected_line_uuid,
            current_order_material_external_id,
            current_order_process_num
        );

        return {
            production_version: selected_line.prod_ver_ext_id || "",
            process_uuid: process_uuid,
            process_num: process_num,
            override_earliest_start: new_earliest_start,
            original_line_uuid: current_line_uuid,
            order_uuid: current_order_uuid,
            new_order_index: new_order_index,
            new_line_uuid: selected_line_uuid,
            line_index: getConflictResolutionLineIndex(selected_line_uuid, line_index),
            is_unscheduled_order,
            earliest_start: new_earliest_start,
            is_unscheduled_order
        }
    }
}