// @flow
import React from "react";
import { connect } from "react-redux";

import GanttChart from "./GanttChart/GanttChart";
import Controls from "./Controls";
import ShowGanttOrderDataOnClick from "./EventListeners/ShowGanttOrderDataOnClick"

import * as rt from "../../../lib/SimulationReportModels";
import * as t from "../../../lib/backend/manufacturing2.generated.types";

import { UPDATE_PROPERTIES_ACTION_NAME, GanttChartSource } from "./reducers/properties"
import { UPDATE_INSIGHTS_ACTION_NAME } from "./reducers/insights";
import { PropertiesLogic } from "./reducers/BusinessLogic";
import { REPORT_LOADING_ACTION_NAME } from "./reducers/report";
import { UPDATE_REPORT_ACTION_NAME } from "./reducers/common";
import { UPDATE_ORDERS } from "./reducers/ganttChart";
import { onSaveComment } from "./PlanningTableEditModal";
import { ORDER_TAGS } from "../../../lib/ManufacturingTags.generated";
import { getBackend } from "../../../lib/backend/Backend2";
import { UPDATE_MATERIALS } from "./reducers/ganttChart";

import type { ReduxState } from "./reducers/reducers";
import type {
    PropertiesState,
    UpdatePropertiesActionType,
    ResetProperties,
    GanttChartSourceTypes
} from "./reducers/properties";
import type { UpdateReportAction } from "./reducers/common";
import type { ReportLoadingAction } from "./reducers/report";
import type { UpdateInsightsAction } from "./reducers/insights";
import type { UpdateLineOrderComment } from "./reducers/linesOrders";
import type { UpdateMaterialsAction } from "./reducers/ganttChart";
import type { ResetGanttAction } from "./reducers/common";
import type { UpdateOrdersAction } from "./reducers/ganttChart";
import type { IOrderModelBase } from "../../../lib/backend/manufacturing2.generated.types";

type ReduxDispatch = (
    UpdatePropertiesActionType | UpdateReportAction | UpdateInsightsAction | ReportLoadingAction |
    ResetGanttAction | ResetProperties | UpdateLineOrderComment | UpdateMaterialsAction | UpdateOrdersAction
);

export type GanttChartContainerProps = {
    handleClick: Function,
    // options: rt.ISimulationReportOrderLineEx[],
    // shifts: t2.ISimulationReportOrderLineShiftFeature[],
    insights: Array<t.IEventDataEx>,
    insight_types: string[],
    report: rt.IReportModelEx,
    show_past_productions: boolean,
    current_shift?: number,
    past_weeks: number,
    start_day: number,
    days: number,
    week?: number,
    year: number,
    simulation_uuid: string,
    space_for_label?: number,
    show_labels?: boolean,
    top_padding?: number,
    bar_height?: number,
    space_between_bars?: number,
    no_overflow?: boolean,
    show_only_tool_changes: boolean,
    show_past_productions: boolean,
    line_group_uuid: string,
    loading: boolean,
    source_type: GanttChartSourceTypes,
    show_only_unscheduled?: boolean,
    disable_zoom?: boolean,
    onOrderChange?: (order: t.IOrderModelBase) => void
};

type GanttChartContainerConnectedProps = GanttChartContainerProps & {
    reduxDispatch: (args: ReduxDispatch) => void,
}
type State = {};


class GanttChartContainer extends React.Component<GanttChartContainerConnectedProps, State> {

    async componentDidMount() {
        this.updateReport();
        this.updateProperties();
        this.updateInsights();
        this.loadMaterials();
        this.loadOrders();
    }

    async componentDidUpdate(prev_props: GanttChartContainerConnectedProps) {
        if (
            prev_props.current_shift !== this.props.current_shift ||
            prev_props.past_weeks !== this.props.past_weeks ||
            prev_props.start_day !== this.props.start_day ||
            prev_props.week !== this.props.week ||
            prev_props.year !== this.props.year ||
            prev_props.space_for_label !== this.props.space_for_label ||
            prev_props.top_padding !== this.props.top_padding ||
            prev_props.bar_height !== this.props.bar_height ||
            prev_props.space_between_bars !== this.props.space_between_bars ||
            prev_props.no_overflow !== this.props.no_overflow ||
            prev_props.show_only_tool_changes !== this.props.show_only_tool_changes ||
            prev_props.source_type !== this.props.source_type
        ) {
            this.updateProperties();
        }

        if (prev_props.report !== this.props.report) {
            this.updateReport();
            this.loadMaterials();
            this.loadOrders();
        }

        if (prev_props.insights !== this.props.insights) {
            this.updateInsights();
        }
    }

    updateReport = () => {
        this.props.reduxDispatch({ type: UPDATE_REPORT_ACTION_NAME, data: this.props.report });
        this.props.reduxDispatch({ type: REPORT_LOADING_ACTION_NAME, data: false });
    }

    updateProperties = () => {
        const properties: $Shape<PropertiesState> = {
            current_shift: this.props.current_shift,
            gantt_past_weeks: this.props.past_weeks,
            week: this.props.week,
            year: this.props.year,
            space_for_label: this.props.space_for_label,
            show_labels: this.props.show_labels,
            top_padding: this.props.top_padding,
            bar_height: this.props.bar_height,
            space_between_bars: this.props.space_between_bars,
            no_overflow: this.props.no_overflow,
            past_weeks: this.props.past_weeks,
            show_only_tool_changes: this.props.show_only_tool_changes,
            count_non_empty_lines: this.props.report.result.orders.length,
            source_type: this.props.source_type ? this.props.source_type : GanttChartSource.gantt,
            line_group_uuid: this.props.line_group_uuid,
            show_past_productions: this.props.past_weeks > 0 ? this.props.show_past_productions : false,
            show_only_unscheduled: this.props.show_only_unscheduled,
            num_shifts: this.props.report.result.line_shift_features[0].shifts.length,
            disable_zoom: this.props.disable_zoom
        }

        properties.start_day = PropertiesLogic.getStartDay(
            properties,
            Math.floor(this.props.report.result.next_shift_time.shift_number / 3)
        );

        this.props.reduxDispatch({ type: UPDATE_PROPERTIES_ACTION_NAME, data: properties });
    }

    updateInsights = () => {
        this.props.reduxDispatch({
            type: UPDATE_INSIGHTS_ACTION_NAME,
            data: {
                insights: this.props.insights,
                insight_types: this.props.insight_types
            }
        });
    }

    loadMaterials = async () => {
        const lines_orders = this.props.report.result.orders;
        const material_external_ids: Set<string> = new Set();
        for (const line_orders of lines_orders) {
            for (const order of line_orders.production) {
                material_external_ids.add(order.material);
            }
        }

        try {
            const res_materials = await getBackend().manufacturing.searchMaterialsSimple({
                external_ids: [...material_external_ids]
            });
            this.props.reduxDispatch({ type: UPDATE_MATERIALS, data: res_materials.data });
        } catch (error) {
            console.error(error);
        }
    }

    loadOrders = async () => {
        const lines_orders = this.props.report.result.orders;
        const uuids: string[] = [];
        for (const line_orders of lines_orders) {
            for (const order of line_orders.production) {
                uuids.push(order.order_id);
            }
        }

        try {
            const { operations } = await getBackend().manufacturing.getOrdersSimple({ uuids });
            this.props.reduxDispatch({ type: UPDATE_ORDERS, data: operations });
        } catch (error) {
            console.error(error);
        }
    };

    onOrderChange = (order?: IOrderModelBase) => {
        if (!order) {
            return;
        }
        const comment = order.tags[ORDER_TAGS.comment];
        onSaveComment(
            order.uuid,
            comment,
            this.props.reduxDispatch
        );
        if (this.props.onOrderChange) {
            this.props.onOrderChange(order);
        }
    }

    render() {
        return <Controls>
            <ShowGanttOrderDataOnClick onOrderChange={this.onOrderChange} />
            <GanttChart
                key={"gantt-chart-container"}
                line_group_uuid={this.props.line_group_uuid}
                show_planning_table_filter={false} // filter is only used on planning table page
            />
        </Controls>
    }
}

export default connect(
    (state: ReduxState) => {
        const insight_types = state.standalone_gantt.insight_types;
        return { insight_types }
    },
    (dispatch: ReduxDispatch) => ({ reduxDispatch: dispatch })
)(GanttChartContainer);
