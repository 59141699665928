// @flow

// main imports
import React, { Component } from "react";

import { getBackend } from "../../lib/backend/Backend2";
import * as sf from "../../lib/StockForecast";
import * as t from "../../lib/backend/manufacturing2.generated.types";

import { translate } from "../IntlProviderWrapper";

import MaterialStockForecast from "./MaterialStockForecast";
import * as BusinessLogic from "../../lib/BusinessLogic";
import {
    LINE_GROUP_TAGS, LINE_GROUP_TAGS_ACCESS
} from "../../lib/ManufacturingTags.generated";
import Loader from "../Loader";
import type { ILineData } from "../../lib/backend/manufacturing2.generated.types";

import { getMaxDaysForHorizon } from "./StocksViewCommon";
import { dateFromDayShift, shiftNumber } from "../../lib/Util";

import type { AggregationTimeWindow } from "./MaterialStockForecast";

// navigation filter
export type DisplayType = {
    code: string;
    title: string;
}

export type StockForecastFilterProps = {
    display_types?: DisplayType[],
    selected_display_type: sf.MaterialForecastType,
    subtypes?: DisplayType[],
    selected_subtype: sf.MaterialForecastSubtype,
    locations?: DisplayType[],
    title: string,
    only_accounted_for_initial_stocks?: DisplayType[],
    only_accounted_for_initial_stock: sf.MaterialForecastOnlyAccounted,
    safety_stock_show: boolean,
    safety_stock_can_be_used: boolean,
    selected_location: sf.MaterialForecastLocation,
    selected_line_uuid: string,
    lines: ILineData[],
    order_external_ids?: string[],
    selected_aggregate?: string
}

export type SelectedValueLabelDefaultFilter = "order_external_id" | "line" | "selected_display_type";


export function initStockForecastFilterProps(line_group_uuid: string): StockForecastFilterProps {
    // check if shopfloor toggle is set to true by default
    const shopfloor_default = BusinessLogic.getLineGroupTagBool(line_group_uuid,
        LINE_GROUP_TAGS.stock_forecast_shopfloor_option_default, true);
    // check if we show safety stock details
    const safety_stock_show = BusinessLogic.getLineGroupTagBool(line_group_uuid,
        LINE_GROUP_TAGS.stock_forecast_show_safety_stock, false);
    const withdrawn_and_unaccounted = 
        LINE_GROUP_TAGS_ACCESS.stock_forecast_withdrawn_and_unaccounted_default(BusinessLogic.getLineGroupTags(line_group_uuid));

    return {
        display_types: [
            {
                code: sf.MATERIAL_FORECAST_TYPES.out,
                title: translate("Manufacturing.MaterialStockForecast.material_out")
            },
            {
                code: sf.MATERIAL_FORECAST_TYPES.in,
                title: translate("Manufacturing.MaterialStockForecast.material_in")
            }
        ],
        selected_display_type: sf.MATERIAL_FORECAST_TYPES.out,
        subtypes: [
            {
                code: sf.MATERIAL_FORECAST_SUBTYPES.combined,
                title: translate("Manufacturing.MaterialStockForecast.combined")
            },
            {
                code: sf.MATERIAL_FORECAST_SUBTYPES.consumed,
                title: translate("Manufacturing.MaterialStockForecast.consumed_cumulative")
            },
            {
                code: sf.MATERIAL_FORECAST_SUBTYPES.consumedByShift,
                title: translate("Manufacturing.MaterialStockForecast.consumed_by_shift")
            },
            {
                code: sf.MATERIAL_FORECAST_SUBTYPES.produced,
                title: translate("Manufacturing.MaterialStockForecast.produced_cumulative")
            },
            {
                code: sf.MATERIAL_FORECAST_SUBTYPES.producedByShift,
                title: translate("Manufacturing.MaterialStockForecast.produced_by_shift")
            },
            {
                code: sf.MATERIAL_FORECAST_SUBTYPES.receivedByShift,
                title: translate("Manufacturing.MaterialStockForecast.received_by_shift")
            },
            {
                code: sf.MATERIAL_FORECAST_SUBTYPES.required,
                title: translate("Manufacturing.MaterialStockForecast.required_cumulative")
            },
            {
                code: sf.MATERIAL_FORECAST_SUBTYPES.requiredByShift,
                title: translate("Manufacturing.MaterialStockForecast.required_by_shift")
            }
        ],
        selected_subtype: sf.MATERIAL_FORECAST_SUBTYPES.combined,
        locations: [
            {
                code: sf.MATERIAL_FORECAST_LOCATIONS.shopfloor,
                title: translate("Manufacturing.MaterialStockForecast.shopfloor")
            },
            {
                code: sf.MATERIAL_FORECAST_LOCATIONS.plant,
                title: translate("Manufacturing.MaterialStockForecast.plant")
            }
        ],
        only_accounted_for_initial_stocks: [
            {
                code: "yes",
                title: translate("common.yes", "Yes")
            },
            {
                code: "no",
                title: translate("common.no", "No")
            }
        ],
        only_accounted_for_initial_stock: withdrawn_and_unaccounted
            ? sf.MATERIAL_FORECAST_ONLY_ACCOUNTED.no
            : sf.MATERIAL_FORECAST_ONLY_ACCOUNTED.yes,
        safety_stock_show,
        safety_stock_can_be_used: false,
        selected_location: shopfloor_default ?
            sf.MATERIAL_FORECAST_LOCATIONS.shopfloor : sf.MATERIAL_FORECAST_LOCATIONS.plant,
        selected_line_uuid: "",
        selected_aggregate: "shift",
        lines: [],
        order_external_ids: [],
        title: ""
    };
}

type Props = {
    line_group_uuid: string,
    line_uuid?: string,
    filter: StockForecastFilterProps,
    handleStockForecastDisplayTypeChange?: (value: string) => void,
    changeSelectedLineUUID?: (value: string) => void,
    onChangeOrderExternalId?: (value: string) => void,
    setDefaultFilter?: (type: SelectedValueLabelDefaultFilter) => () => void,
    changeSelectedHorizonStockForecast?: (value: AggregationTimeWindow) => void,
    onTitleChange?: (e: Event) => void,
    hide_display_type_filter?: boolean,
    hide_lines_filter?: boolean
}

type State = {
    shift_headers: sf.ShiftHeader[],
    show_affiliated_stock: boolean,
    materials_changing: t.IMaterialForecast[],
    materials_nonchanging: t.IMaterialForecast[],
    materials_zero: t.IMaterialForecast[],
    error: string,
    loading: boolean,
    stock_forecast_params: Object,
    forecasts: {
        materials_changing: t.IMaterialForecast[],
        materials_nonchanging: t.IMaterialForecast[],
        materials_zero: t.IMaterialForecast[]
    },
    report_id: string
}

class MaterialStockForecastView extends Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            shift_headers: [],
            show_affiliated_stock: false,
            materials_changing: [],
            materials_nonchanging: [],
            materials_zero: [],
            error: "",
            loading: true,
            stock_forecast_params: {},
            forecasts: {
                materials_changing: [],
                materials_nonchanging: [],
                materials_zero: []
            },
            report_id: ""
        };
    }

    componentDidMount() {
        this.loadComponent();
    }

    componentDidUpdate(prev_props: Props) {
        const order_external_id_changed =
            (prev_props.filter.order_external_ids !== this.props.filter.order_external_ids) &&
            ((prev_props.filter.order_external_ids !== undefined && prev_props.filter.order_external_ids.length > 0)
            || (this.props.filter.order_external_ids !== undefined && this.props.filter.order_external_ids.length > 0));

        if (
            (
                prev_props.line_group_uuid !== this.props.line_group_uuid ||
                prev_props.line_uuid !== this.props.line_uuid ||
                prev_props.filter.selected_display_type !== this.props.filter.selected_display_type ||
                prev_props.filter.selected_subtype !== this.props.filter.selected_subtype ||
                prev_props.filter.only_accounted_for_initial_stock !== this.props.filter.only_accounted_for_initial_stock ||
                prev_props.filter.selected_location !== this.props.filter.selected_location ||
                order_external_id_changed ||
                prev_props.filter.selected_aggregate !== this.props.filter.selected_aggregate
            ) &&
            this.props.line_group_uuid !== ""
        ) {
            this.loadComponent();
        }
    }

    getForecast = async (custom_params: Object = {}, subtype: ?sf.MaterialForecastSubtype = null) => {
        let { line_group_uuid, line_uuid } = this.props;
        let {
            selected_display_type,
            selected_subtype,
            only_accounted_for_initial_stock,
            selected_location,
            order_external_ids,
            selected_aggregate
        } = this.props.filter;

        let material_external_id = undefined;

        if (custom_params.material_external_id) {
            material_external_id = custom_params.material_external_id;
        }

        if (custom_params.selected_subtype) {
            selected_subtype = custom_params.selected_subtype;
        }

        if (custom_params.selected_display_type) {
            selected_display_type = custom_params.selected_display_type;
        }

        return await sf.getMaterialForecast(
            line_group_uuid,
            selected_display_type,
            selected_subtype,
            only_accounted_for_initial_stock,
            selected_location,
            getMaxDaysForHorizon(line_group_uuid, selected_aggregate),
            line_uuid,
            custom_params.report_id,
            order_external_ids,
            material_external_id
        );
    }

    getMaterialForecastForShifts = async (material_external_id: string, index: number, report_id: string) => {
        const { line_group_uuid, line_uuid } = this.props;
        const {
            selected_aggregate,
            selected_display_type,
            only_accounted_for_initial_stock,
            selected_location,
            order_external_ids
        } = this.props.filter;
        let subtypes = ["produced_by_shift", "consumed_by_shift"]

        if (selected_location !== "shopfloor") {
            subtypes = [...subtypes, "received_by_shift", "required_by_shift"];
        }

        let start_index = index;
        if (selected_aggregate !== "shift") {
            let shift_header = this.state.shift_headers[start_index];
            while (start_index > 0 && (
                selected_aggregate === "day" && shift_header.shift > 0 ||
                selected_aggregate === "week" && (shift_header.day_of_week > 0 || shift_header.shift > 0)
            )) {
                start_index -= 1;
                shift_header = this.state.shift_headers[start_index];
            }
        }

        const start_header = this.state.shift_headers[start_index];
        const start_shift = shiftNumber(dateFromDayShift(new Date(start_header.date), start_header.shift));
        const end_header = this.state.shift_headers[index];
        const end_shift = shiftNumber(dateFromDayShift(new Date(end_header.date), end_header.shift));
        const res = await getBackend().manufacturing.getMaterialForecastForShifts({
            group_uuid: line_group_uuid,
            line_uuid,
            report_id,
            type: selected_display_type,
            subtypes,
            only_accounted_for_initial_stock,
            location: selected_location,
            order_external_ids,
            material_external_id,
            start_shift: start_shift.shift_tag,
            end_shift: end_shift.shift_tag
        });

        return res;
    }

    shiftAggregationFix = (shift_headers: any) => {
        // if one of the last three headers has a different date, then we cut it out
        // otherwise day aggregation cannot be calculated
        if (this.props.filter.selected_aggregate === "day" && shift_headers.length > 3) {
            const headers = shift_headers.slice(shift_headers.length - 3, shift_headers.length);

            let lastValidDateIdx = null;

            for (let i = 0; i < headers.length - 2; i++) {
                if (headers[i] !== headers[i + 1]) {
                    lastValidDateIdx = i;
                    break;
                }
            }

            if (lastValidDateIdx !== null) {
                shift_headers = shift_headers.slice(0, (shift_headers.length - 3 + lastValidDateIdx));
            }
        }

        return shift_headers;
    }

    async loadComponent() {
        try {
            if (this.props.line_group_uuid !== null) {
                this.setState({ loading: true });
                const res = await this.getForecast();
                const res_forecast = await this.getForecast({selected_subtype: "combined"});

                // currently show_affiliated_stock is set if at least one
                // affiliated_stock is nonzero. Alternative: control this by
                // a tag and bring it from backend
                const show_affiliated_stock =
                    res.materials_changing.some(x => x.affiliated_stock > 0) ||
                    res.materials_nonchanging.some(x => x.affiliated_stock > 0) ||
                    res.materials_zero.some(x => x.affiliated_stock > 0);
                this.setState({
                    shift_headers: this.shiftAggregationFix(res.shift_headers),
                    show_affiliated_stock,
                    materials_changing: res.materials_changing,
                    materials_nonchanging: res.materials_nonchanging,
                    materials_zero: res.materials_zero,
                    forecasts: {
                        materials_changing: res_forecast.materials_changing,
                        materials_nonchanging: res_forecast.materials_nonchanging,
                        materials_zero: res_forecast.materials_zero
                    },
                    report_id: res.report_id
                });
            }
        } catch (err) {
            this.setState({ error: err.message });
        }
        this.setState({loading: false});
    }

    render() {
        return (
            <article className="article">
                <section id="statistics" className="data_sources">
                    <div className="vertical_tab">
                        <div className="container-fluid" style={{width: "fit-content"}}>
                            <div className="row" style={{display: "block", textAlign: "center"}}>
                                <div className="co-md-12 text-center">
                                    {
                                        this.state.loading ?
                                        <Loader/> :
                                        (this.state.report_id && <MaterialStockForecast
                                            shift_headers={this.state.shift_headers}
                                            show_affiliated_stock={this.state.show_affiliated_stock}
                                            safety_stock_show={this.props.filter.safety_stock_show}
                                            safety_stock_account_for={!this.props.filter.safety_stock_can_be_used}
                                            materials_changing={this.state.materials_changing}
                                            materials_nonchanging={this.state.materials_nonchanging}
                                            materials_zero={this.state.materials_zero}
                                            selected_subtype={this.props.filter.selected_subtype}
                                            selected_aggregate={this.props.filter.selected_aggregate || "shift"}
                                            selected_display_type={this.props.filter.selected_display_type}
                                            only_accounted_for_initial_stock={this.props.filter.only_accounted_for_initial_stock}
                                            selected_location={this.props.filter.selected_location}
                                            forecasts_materials_changing={this.state.forecasts.materials_changing}
                                            forecasts_materials_nonchanging={this.state.forecasts.materials_nonchanging}
                                            forecasts_materials_zero={this.state.forecasts.materials_zero}
                                            getForecast={this.getMaterialForecastForShifts}
                                            stock_forecast_filter={this.props.filter}
                                            handleStockForecastDisplayTypeChange={this.props.handleStockForecastDisplayTypeChange}
                                            changeSelectedLineUUID={this.props.changeSelectedLineUUID}
                                            onChangeOrderExternalId={this.props.onChangeOrderExternalId}
                                            setDefaultFilter={this.props.setDefaultFilter}
                                            onTitleChange={this.props.onTitleChange}
                                            changeSelectedHorizonStockForecast={this.props.changeSelectedHorizonStockForecast}
                                            hide_display_type_filter={this.props.hide_display_type_filter}
                                            hide_lines_filter={this.props.hide_lines_filter}
                                            report_id={this.state.report_id}
                                        />)
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </article >
        );
    }
}

export default MaterialStockForecastView;
