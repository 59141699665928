// @flow

/************************************************************************************/
/* WARNING: This file is generated automatically. Don't edit it by hand             */
/************************************************************************************/

import * as api from "./restApi.js";
import * as t from "./audit.generated.types.js";

function enc(s: any): string { return encodeURIComponent("" + s); }

export interface IAudit {
    /** Gets audit tables */
    getAuditTable(req: t.IGetAuditTableReq): Promise<t.IGetAuditTableRes>;

    /** Gets all tables that are auditable */
    getAllAuditTables(req: t.IGetAllAuditTablesReq): Promise<t.IGetAllAuditTablesRes>;

}

export class Audit implements IAudit {

    client: api.ApiClient;

    constructor(client: api.ApiClient) { this.client = client; }

    /** Gets audit tables */
    async getAuditTable(req: t.IGetAuditTableReq): Promise<t.IGetAuditTableRes> {
        const reqx: any = req;
        const url = `/api/v1.0/audit/getTable/${enc(req.table_name)}`;
        delete reqx.table_name;
        return (await this.client.get(url, reqx)).data;
    }

    /** Gets all tables that are auditable */
    async getAllAuditTables(req: t.IGetAllAuditTablesReq): Promise<t.IGetAllAuditTablesRes> {
        const reqx: any = req;
        const url = `/api/v1.0/audit/getAllAuditTables`;
        return (await this.client.get(url, reqx)).data;
    }

}
