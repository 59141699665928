// @flow

/************************************************************************************/
/* WARNING: This file is generated automatically. Don't edit it by hand             */
/************************************************************************************/

import * as api from "./restApi.js";
import * as t from "./models.generated.types.js";

function enc(s: any): string { return encodeURIComponent("" + s); }

export interface IModels {
    /** Runs model for given input parameters */
    evaluateModel(req: t.IEvaluateModelReq): Promise<t.IEvaluateModelRes>;

    /** Gets details for single model */
    getSingleModel(req: t.IGetSingleModelReq): Promise<t.IGetSingleModelRes>;

    /** Gets input data for single model */
    getSingleModelInputData(req: t.IGetSingleModelInputDataReq): Promise<t.IGetSingleModelInputDataRes>;

    /** Gets models for user's org */
    searchModels(req: t.ISearchModelsReq): Promise<t.ISearchModelsRes>;

}

export class Models implements IModels {

    client: api.ApiClient;

    constructor(client: api.ApiClient) { this.client = client; }

    /** Runs model for given input parameters */
    async evaluateModel(req: t.IEvaluateModelReq): Promise<t.IEvaluateModelRes> {
        const reqx: any = req;
        const url = `/api/v1.0/models/evaluate`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Gets details for single model */
    async getSingleModel(req: t.IGetSingleModelReq): Promise<t.IGetSingleModelRes> {
        const reqx: any = req;
        const url = `/api/v1.0/models/details`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Gets input data for single model */
    async getSingleModelInputData(req: t.IGetSingleModelInputDataReq): Promise<t.IGetSingleModelInputDataRes> {
        const reqx: any = req;
        const url = `/api/v1.0/models/input-data`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Gets models for user's org */
    async searchModels(req: t.ISearchModelsReq): Promise<t.ISearchModelsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/models`;
        return (await this.client.get(url, reqx)).data;
    }

}
