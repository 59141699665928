// @flow

import React from "react";
import {
    rowStyle,
    mapData,
    Columns,
    updateTagsConfig,
    getInitialState
} from "../react/ReactTableTags";
import type { AdditionalConfig } from "../react/ReactTableTags";
import ReactTable from "../react/ReactTable";
import { LINE_GROUP_TAGS } from "../../lib/ManufacturingTagsEditor.generated"

import { FormattedMessage } from "react-intl";

import { getLinkForUserDocumentation, UserDocumentationLinksEnum } from "../../lib/Util";
import { getLang } from "../IntlProviderWrapper";

const additional_config: AdditionalConfig = {
    day_shift_assignment_format: {
        validate: (value) => {
            return false;
        }
    },
    gantt_number_of_days: {
        validate: (value) => {
            return false;
        }
    },
    gantt_past_weeks: {
        validate: (value) => {
            return false;
        }
    },
    gantt_min_day_width: {
        validate: (value) => {
            return false;
        }
    },
    microplan_weeks: {
        validate: (value) => {
            return false;
        }
    },
    microplan_gantt_number_of_days: {
        validate: (value) => {
            return false;
        }
    },
}

type Props = {
    tags: Object,
    onChangeCell: Function
}

const LineGroupEditTags = (props: Props) => {
    return <div>
        <a href={getLinkForUserDocumentation(UserDocumentationLinksEnum.line_groups, getLang())}
            target="_blank"
            style={{ position: 'relative', float: 'right', paddingTop: '30px' }}>
            <img src={"/img/question_light.svg"} style={{ paddingRight: "3px", paddingBottom: "2px" }} />
            <FormattedMessage id="Header.menu.user_documentation" defaultMessage="User documentation" />
            <img src={"/img/arrow_up_right_corner.svg"} style={{ position: "relative", width: "15px", height: "15px", paddingLeft: "3px", paddingBottom: "4px", bottom: "3px" }} />
        </a>
        <ReactTable
            className="tags-react-table"
            data={mapData(props.tags, updateTagsConfig(LINE_GROUP_TAGS, additional_config))}
            columns={Columns}
            onChangeCell={props.onChangeCell}
            rowStyle={rowStyle}
            initialState={getInitialState()}
        />
    </div>
}

export default LineGroupEditTags;
