// @flow
import * as React from "react";
import { Modal } from "react-bootstrap";
import ErrorComponent from "../../ErrorComponent";

import { translate } from "../../IntlProviderWrapper";

type SaveConfigurationModalProps = {
    show: boolean,
    // name?: string,
    isUnique: (name: string) => boolean,
    onAccept: (name: string) => Promise<void>,
    onCancel: () => void
};

type SaveConfigurationModalState = {
    name: string,
    warning: string
};

class SaveConfigurationModal extends React.Component<SaveConfigurationModalProps, SaveConfigurationModalState> {
    constructor(props: SaveConfigurationModalProps) {
        super(props);
        this.state = {
            name: "",
            warning: ""
        };
    }

    validateName = (name: string) => {
        if (this.props.isUnique(name)) {
            this.setState({
                warning: translate(
                    "Manufacturing.Planning.configuration_already_exists",
                    "Configuration already exists. Are you sure you want to override it?"
                )
            });
        }
    };

    handleSubmit = (event: SyntheticEvent<HTMLFormElement>) => {
        event.preventDefault();
        const form_data = new FormData(event.currentTarget);
        const name = form_data.get("name");
        if (name && typeof name === "string") {
            this.setState({
                warning: ""
            });
            this.props.onAccept(name);
        }
    };

    handleNameChange = (event: SyntheticEvent<HTMLInputElement>) => {
        const name = event.currentTarget.value;
        this.setState({
            name,
            warning: ""
        });
        this.validateName(name);
    };

    handleExit = () => {
        this.setState({
            name: "",
            warning: ""
        });
    };

    render() {
        return (
            <Modal show={this.props.show} onExited={this.handleExit}>
                <Modal.Header>
                    <Modal.Title>
                        {translate("Manufacturing.Planning.enter_configuration_name", "Enter configuration name")}
                    </Modal.Title>
                    <button type="button" className="close" onClick={this.props.onCancel}>
                        <span aria-hidden="true">×</span>
                        <span className="sr-only">{translate("common.close", "Close")}</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <form id="configuration-name-form" onSubmit={this.handleSubmit}>
                        <div className="form-group mb-0">
                            <label className="sr-only" htmlFor="configuration-name-input">
                                {translate("Manufacturing.Planning.configuration_name", "Configuration name")}
                            </label>
                            <input
                                id="configuration-name-input"
                                className="form-control"
                                type="text"
                                name="name"
                                value={this.state.name}
                                onChange={this.handleNameChange}
                                autoFocus
                            />
                        </div>
                        {this.state.warning && (
                            <ErrorComponent
                                className="mb-0"
                                type="warning"
                                msg={this.state.warning}
                            />
                        )}
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn btn-primary"
                        type="submit"
                        form="configuration-name-form"
                        disabled={!this.state.name}
                    >
                        {translate("common.ok", "OK")}
                    </button>
                    <button className="btn btn-outline-secondary" onClick={this.props.onCancel}>
                        {translate("common.cancel", "cancel")}
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default SaveConfigurationModal;
