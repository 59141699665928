// @flow

// React
import React, { Component } from "react";

// Models
import * as t from "../../lib/backend/manufacturing2.generated.types";
import ChevronListItem from "../react/ChevronListItem";


type Props = {
    filter: string,
    selected: t.IPlantData | null,
    plants: t.IPlantData[],
    handlePlantClick: any
}

/**
 * Plants list component.
 */
class PlantsList extends Component<Props> {
    /**
     * Handle `click` events for the plant item.
     */
    handlePlantClick(plant: t.IPlantData, i: number) {
        // Ignore clicks for currently selected plant.
        if (plant === this.props.selected) {
            return;
        }

        // Handle parent's click events.
        this.props.handlePlantClick(plant);
    }

    /**
     * Plant list filter.
     *
     * @param {string} needle Search term.
     * @param {PlantObj} plant Plant object to match search term against.
     *
     * @return {boolean} `true` if plant matches given search term, `false` otherwise.
     */
    includes(needle: string, plant: any) {
        needle = needle.toLowerCase();
        if (needle.length === 0) return true;
        if (plant.title.toLowerCase().indexOf(needle) > -1) return true;
        if (plant.external_id.toLowerCase().indexOf(needle) > -1) return true;
        if (plant.uuid.toLowerCase().indexOf(needle) > -1) return true;
        // search tag keys and values
        let tagsSearch = false;
        if (plant.tags !== null) {
            Object.keys(plant.tags).forEach((el, i) => {
                // comparing values
                if (String(plant.tags[el]).toLowerCase().indexOf(needle) > -1) {
                    tagsSearch = true;
                }
                // comparing keys
                if (el.toLowerCase().indexOf(needle) > -1) {
                    tagsSearch = true;
                }
            });
        }
        if (tagsSearch === true) return true;
        return false;
    }

    /**
     * Render component.
     */
    render() {
        const plantsList = this.props.plants.map((plant, i) => {
            let is_active = false;

            if (this.props.selected === plant) {
                is_active = true;
            }

            if (!this.includes(this.props.filter, plant)) return null;

            return (
                <ChevronListItem
                    text={plant.title}
                    onClick={() => this.handlePlantClick(plant, i)}
                    is_active={is_active}
                />
            )
        })

        return (
            <ul className="nav nav-tabs">
                <div className="nav-tabs-parent" ref="nav">
                    {plantsList}
                </div>
            </ul>
        )
    }
}

export default PlantsList;
