// @flow
import * as React from "react";

import { FormattedMessage } from "react-intl";
import ReactRouterPropTypes from "react-router-prop-types";

import { LINE_GROUP_TAGS, PLANNING_MODE, INSIGHT_TYPES } from "../../lib/ManufacturingTags.generated";
import * as BusinessLogic from "../../lib/BusinessLogic";
import { shiftNumber } from "../../lib/Util";
import { getBackend } from "../../lib/backend/Backend2";
import { NavigationBarWithDropdown } from "../NavigationBar";
import DashboardStandalone from "../DashboardStandalone";
import { TOOLS_VIEW_TYPES, redirectSelectedView } from "../ViewsCommon";
import InsightsViewToolSetup from "./InsightsViewToolSetup";
import InsightsView from "./InsightsView";
import { GanttChartSource } from "./PlanningTable2/reducers/properties";
import * as Auth from "../../lib/Auth";
import ToolManagement from "./ToolManagement";

const VIEW_TYPES = TOOLS_VIEW_TYPES;

type ToolsViewParams = {
    id?: string,
    type: string,
    title?: string,
    title_code?: string,
    dashboard_uuid?: string
};

type Props = {
    history: ReactRouterPropTypes.history,
    match: ReactRouterPropTypes.match,
    location: ReactRouterPropTypes.location,
    plant_uuid: string,
    line_group_uuid: string
};

type State = {
    views: ToolsViewParams[],
    selected_view: string
    // filters for individual views
};

class ToolsView extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            views: [],
            selected_view: VIEW_TYPES.tool_change.id
        }
    }

    async componentDidMount() {
        await BusinessLogic.waitForManufacturingAsync();
        this.loadViews();
    }

    async componentDidUpdate(prev_props: Props) {
        if (prev_props.plant_uuid !== this.props.plant_uuid ||
            prev_props.line_group_uuid !== this.props.line_group_uuid ||
            (this.props.match.params.selected_view && this.props.match.params.selected_view !== this.state.selected_view)) {

            await BusinessLogic.waitForManufacturingAsync();
            this.loadViews();
        }
    }

    async loadViews() {
        let line_group = BusinessLogic.getLineGroupForUser(this.props.line_group_uuid);
        if (line_group === null) { return; }

        const is_people_first_planning = (line_group.tags[LINE_GROUP_TAGS.planning_mode] === PLANNING_MODE.people_first);
        // create views
        const views: ToolsViewParams[] = [];
        if (!is_people_first_planning) {
            const is_ticketing_user = Auth.isInRole(Auth.ROLE_TICKET_USER);
            if (!is_ticketing_user) {
                views.push({
                    id: VIEW_TYPES.tool_change.id,
                    type: VIEW_TYPES.tool_change.id,
                    title: VIEW_TYPES.tool_change.title,
                    title_code: VIEW_TYPES.tool_change.title_code
                });
            } else {
                views.push({
                    id: VIEW_TYPES.tool_change_new.id,
                    type: VIEW_TYPES.tool_change_new.id,
                    title: VIEW_TYPES.tool_change_new.title,
                    title_code: VIEW_TYPES.tool_change_new.title_code
                });
            }

            // get line-group tool-change gantt
            const res1 = await getBackend().dashboards.searchDashboards({
                active: true,
                tags: {
                    line_group: this.props.line_group_uuid,
                    type: "man",
                    subtype: "plant-gantt-tool-setup"
                }
            });
            // as a backup show plant-level tool-change gantt
            const res2 = await getBackend().dashboards.searchDashboards({
                active: true,
                tags: {
                    plant: this.props.plant_uuid,
                    type: "man",
                    subtype: "plant-gantt-tool-setup"
                }
            });

            // go over dashboards and identify relevant ones
            const dashboards_toolsetup = [...res1.dashboards, ...res2.dashboards];
            if (dashboards_toolsetup.length > 0) {
                const gantt_dashboard = dashboards_toolsetup[0];
                views.push({
                    id: VIEW_TYPES.gantt_tools.id,
                    type: VIEW_TYPES.gantt_tools.id,
                    title: VIEW_TYPES.gantt_tools.title,
                    title_code: VIEW_TYPES.gantt_tools.title_code,
                    dashboard_uuid: gantt_dashboard.uuid
                });
            }

            views.push({
                id: VIEW_TYPES.tool_management.id,
                type: VIEW_TYPES.tool_management.id,
                title: VIEW_TYPES.tool_management.title,
                title_code: VIEW_TYPES.tool_management.title_code
            });
        }

        // assing appropriate view
        const url_view = this.props.match.params.selected_view;
        let selected_view = "";
        if (url_view) {
            // we have view selected by the URL, check if even possible
            if (views.find(view => view.id === url_view)) {
                // all is good, mark it selected
                selected_view = url_view;
            } else {
                // url_view no longer available, select the top one
                selected_view = (views.length > 0 && views[0].id) ? views[0].id : "";
            }
        } else {
            // no view indicated in url, select the top one
            selected_view = (views.length > 0 && views[0].id) ? views[0].id : "";
        }
        this.setState({
            views,
            selected_view
        });
        this.props.history.push(`/manufacturing/tools/${selected_view}`);
    }

    handleViewChange = (event: Event) => {
        if (event.currentTarget instanceof HTMLInputElement ||
            event.currentTarget instanceof HTMLSelectElement ||
            event.currentTarget instanceof HTMLButtonElement) {
            // read new value
            const selected_view = event.currentTarget.value;
            // find new id
            this.setState({ selected_view });
            redirectSelectedView(selected_view, this.props.history);
        }
    }

    /////////////////////////////////////////
    // Tool Change
    renderToolChangeFilter() {
        return <li className="list-inline-item">
            <button className="btn btn-primary" style={{ float: "right" }} onClick={this.onDownloadHTML}>
                <span><FormattedMessage id="ShiftDetails.export_for_print" defaultMessage="Export for print" /></span>
            </button>
        </li>;
    }

    /////////////////////////////////////////
    // Tools View
    renderFilter(view: ToolsViewParams) {
        if (view) {
            if (view.type === VIEW_TYPES.tool_change.id) {
                return this.renderToolChangeFilter();
            }
        }
        // nothing valid selected
        return <li className="list-inline-item"></li>;
    }

    renderContent(view: ToolsViewParams) {
        if (view) {
            if (view.type === VIEW_TYPES.tool_change.id) {
                const insight_types = [
                    INSIGHT_TYPES.tool_setup,
                    INSIGHT_TYPES.tool_overlap
                ];
                const shift_start = shiftNumber(new Date());
                const diff = (new Date()).getTime() - shift_start.ts_start;
                return <InsightsView
                    history={this.props.history}
                    match={this.props.match}
                    plant_uuid={this.props.plant_uuid}
                    line_group_uuid={this.props.line_group_uuid}
                    insight_types={insight_types}
                    from={(new Date().getTime()) - diff}
                />;
            } else if (view.type === VIEW_TYPES.tool_change_new.id) {
                const shift_start = shiftNumber(new Date()).ts_start;
                const diff = (new Date()).getTime() - shift_start;
                return <InsightsViewToolSetup
                    history={this.props.history}
                    match={this.props.match}
                    plant_uuid={this.props.plant_uuid}
                    line_group_uuid={this.props.line_group_uuid}
                    from={(new Date().getTime()) - diff}
                />;
            } else if (view.type === VIEW_TYPES.tool_management.id) {
                return (
                    <ToolManagement plant_uuid={this.props.plant_uuid} line_group_uuid={this.props.line_group_uuid} />
                )
            } else if (view.dashboard_uuid) {
                return <DashboardStandalone
                    history={this.props.history}
                    match={this.props.match}
                    uuid={view.dashboard_uuid}
                    asPublic={false}
                    show_filter_bar={false}
                    source_type={GanttChartSource.tool_changes}
                />;
            } else {
                return <div></div>;
            }
        }
        // nothing valid selected
        return <div></div>;
    }

    onDownloadHTML = () => {
        const event = new Event('export-html-insights');
        const el = document.querySelector("#insights-list");

        if (el instanceof HTMLElement) {
            el.dispatchEvent(event);
        }
    }

    getViewsContentStyle = (view?: ToolsViewParams) => {
        if (!view) return {};

        if (view.type === TOOLS_VIEW_TYPES.gantt_tools.id) {
            return {
                paddingTop: "75px"
            }
        }

        return {};
    }

    shouldHideFilter = (view?: ToolsViewParams): boolean => {
        return view != undefined && [
            TOOLS_VIEW_TYPES.gantt_tools.id,
            TOOLS_VIEW_TYPES.tool_change_new.id,
            TOOLS_VIEW_TYPES.tool_management.id
        ].includes(view.type);
    }

    renderContainerFilter = (view?: ToolsViewParams) => {
        const hide_filter_bar = this.shouldHideFilter(view);
        if (hide_filter_bar) {
            return null;
        }

        return <div className="views-filter-bar views_always_top" key="B">
            <div className="row">
                <div className="col-lg-12 col-md-12">
                    <ul className="list-inline">
                        <li className="list-inline-item">
                            {/*<select name="select_view" className="form-control select_top_control"
                                value={this.state.selected_view} onChange={this.handleViewChange}>
                                {
                                    this.state.views.map((view, i) => (view.title_code) ?
                                        <option key={i} value={view.id}>{translate(view.title_code, view.title)}</option> :
                                        <option key={i} value={view.id}>{view.title}</option>
                                    )
                                }
                            </select>*/}
                        </li>
                        {view && this.renderFilter(view)}
                    </ul>
                </div>
            </div>
        </div>
    }

    getViewId = (view?: ToolsViewParams): string => {
        if (view) {
            if (view.id) {
                return view.id;
            }
        }
        return "";
    }

    render() {
        const view = this.state.views.find((view) => (view.id === this.state.selected_view));
        if (!view) {
            return null;
        }
        const hide_filter = this.shouldHideFilter(view);
        const viewClasses = `views-content ${hide_filter ? "hide-filter" : ""} ${this.getViewId(view)}`;
        return [
            <NavigationBarWithDropdown
                handleViewChange={this.handleViewChange}
                key="A"
                nav="views"
                nav_id="tools"
                location={this.props.location}
                line_group_uuid={this.props.line_group_uuid}
                plant_uuid={this.props.plant_uuid}
            />,
            this.renderContainerFilter(view),
            <div className={viewClasses} key="C" style={this.getViewsContentStyle(view)}>{view && this.renderContent(view)}</div>
        ];
    }
}

export default ToolsView;
