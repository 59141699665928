// @flow

import React from "react";

const Check = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="10.638" height="8" viewBox="0 0 10.638 8">
        <path id="Path_919" data-name="Path 919" d="M9.584,13.9l5.636-5.636a.895.895,0,0,1,1.253,1.27L10.211,15.8a.9.9,0,0,1-1.253,0L6.274,13.114a.895.895,0,0,1,1.253-1.27L9.584,13.9Z" transform="translate(-6.055 -8.054)" fill="#0967d2" />
    </svg>

}

export default Check;
