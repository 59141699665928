// @flow

// main imports
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

// models
import type { ILineData, IPlantData, ILineGroupData } from "../../lib/backend/manufacturing2.generated.types";

// backend
import { getBackend } from "../../lib/backend/Backend2";
import * as Auth from "../../lib/Auth";
import { forceManufacturingCacheRefresh } from "../../lib/BusinessLogic";

// import subcomponents
import LineGroupsModal from "./LineGroupsModal";
import { translate } from "../IntlProviderWrapper";
import ConfirmationModal from "../ConfirmationModal";

// defining types
type SelectedObj = {
    label: string,
    value: string
}

type HashBooleanObj = {
    [key: string]: boolean
}

type Props = {
    plants: IPlantData[],
    selected: ILineGroupData,
    lines: ILineData[],
    handleToDelete: any,
    loadLineGroups: any
}

type State = {
    selected: ILineGroupData,
    selected_plant: SelectedObj,
    plant_lines: ILineData[],
    line_group_lines: HashBooleanObj,
    show_modal: boolean,
    show_delete_modal: boolean
}

const EDIT_LINK = "/digital-twin/resources/line_group/edit/";
const MATRIX_LINK = "/digital-twin/resources/line_group/matrix/";

/**
 * Lines details component.
 */
class LineGroupsDetails extends Component<Props, State> {

    /////////////////////////////////////////
    // SUBCOMPONENT METHODS (MODAL)

    /**
     * Function triggered within add line model, which identifies the
     * selected plant on select change and remembers it. It also trigger the
     * updating of available lines for that plant.
     *
     * @param {SelectedObj} value Select-object-plant (value, label).
     */
    handleModalPlantChange(value: SelectedObj) {
        let lines = this.props.lines.filter(x => x.plant_uuid === value.value);
        const allLines = translate("Manufacturing.LineGroups.all_lines", "All lines");
        lines.unshift({
            org_title: allLines,
            org_uuid: "all",
            plant_title: allLines,
            plant_external_id: "test",
            title: allLines,
            uuid: "all",
            external_id: "all",
            external_id2: null,
            plant_uuid: "all",
            weight: 10,
            tags: {}
        });

        this.setState({
            selected_plant: value,
            plant_lines: lines
        });
    }

    /**
     * Adding/deleting lines to line group.
     *
     * @param {string[]} lines Array of lines.
     */
    async handleModalSaveClick(line_uuids: string[]) {
        await getBackend().manufacturing.setLinesForLinegroup({
            line_uuids, linegroup_uuid: this.props.selected.uuid
        });
        await forceManufacturingCacheRefresh();
        this.props.loadLineGroups(); // reload page
        this.setState({ show_modal: false });
    }

    /**
     * Method triggered when Add/Update/Edit button is clicked.
     * It opens lines modal and selects new line if `line_uuid` is provided.
     *
     * @param {string} [plant_uuid] Plant UUID.
     */
    handleModalShowClick() {
        this.setState({ show_modal: true });
    }

    /**
     * Method triggered when modal close button (or modal background overlay) is clicked.
     * It closes the modal.
     */
    handleModalCloseClick() {
        this.setState({ show_modal: false });
    }


    /////////////////////////////////////////
    // MAIN COMPONENT METHODS

    constructor(props: Props, state: State) {
        super(props, state);

        let lines = props.lines.filter(x => x.plant_uuid === props.plants[0].uuid);
        const allLines = translate("Manufacturing.LineGroups.all_lines", "All lines");
        lines.unshift({
            org_title: allLines,
            org_uuid: "all",
            plant_title: allLines,
            plant_external_id: "test",
            title: allLines,
            uuid: "all",
            external_id: "all",
            external_id2: null,
            plant_uuid: "all",
            weight: 10,
            tags: {}
        });

        this.state = {
            selected: {
                uuid: "",
                title: "",
                org_uuid: "",
                external_id: "",
                line_uuids: [],
                plant_uuids: [],
                tags: {},
                weight: 0
            },
            selected_plant: {
                value: props.plants[0].uuid,
                label: props.plants[0].title
            },
            line_group_lines: {},
            plant_lines: lines,
            show_modal: false,
            show_delete_modal: false
        }
    }

    /**
     * Handle delete button clicks.
     */
    handleDelete = () => {
        this.setState({ show_delete_modal: true });
    }

    handleDeleteCancel = () => {
        this.setState({ show_delete_modal: false });
    };

    handleDeleteConfirm = () => {
        if (this.props.selected) {
            this.props.handleToDelete(this.props.selected.uuid);
        }

        this.setState({ show_delete_modal: false });
    };

    /**
     * Render component.
     */
    render() {
        if (this.props.selected === null) {
            return null;
        }
        const can_edit = Auth.hasPermission(Auth.PERMISSION_NAMES.LineGroupsEdit)
        const edit_link = `${EDIT_LINK}${this.props.selected.uuid}`;
        const matrix_link = `${MATRIX_LINK}${this.props.selected.uuid}`;

        let tags = "/";
        if ((this.props.selected.tags !== undefined) && (this.props.selected.tags !== null)) {
            // generate tags
            tags = Object.keys(this.props.selected.tags)
                .sort((a, b) => a.localeCompare(b))
                .map((el, i) => {
                    const val = this.props.selected.tags[el];
                    let style = "badge";
                    if (val === "true") {
                        style += " badge-success";
                    } else if (val === "false") {
                        style += " badge-danger";
                    } else {
                        style += " badge-secondary";
                    }
                    return (<span className={style} style={{ marginRight: 3 }} key={i}>{el + ": " + val}</span>);
                });
        }

        // match line_uuids of selected object with lines
        const matched_lines = this.props.lines.filter(x => this.props.selected.line_uuids.includes(x.uuid));

        // find distinct plants, related to this line group
        const matched_plants_non_filtered: IPlantData[] = matched_lines.map(x => {
            const plant_external_id = x.plant_external_id === undefined ? "" : x.plant_external_id;
            const plant_title = x.plant_title === undefined ? "" : x.plant_title;
            const item: IPlantData = {
                uuid: x.plant_uuid,
                external_id: plant_external_id,
                title: plant_title,
                org_uuid: "", // not correct; not needed here,
                weight: 0
            };
            return item;
        });

        let matched_plants = [];

        Array.from(new Set(matched_plants_non_filtered.map(x => x.uuid)))
            .forEach(uuid => {
                const matched_plant = matched_plants_non_filtered.find(x => x.uuid === uuid);
                if (matched_plant !== undefined) {

                    const item = {
                        uuid: uuid,
                        external_id: matched_plant.external_id,
                        title: matched_plant.title,
                        org_uuid: "" // not correct; not needed here
                    };
                    matched_plants.push(item);
                }
            });

        //const updateButton = null;
        const updateButton = (<button className="btn btn-outline-primary ml-auto" onClick={() => this.handleModalShowClick()}>
            <FormattedMessage id="common.edit" defaultMessage="Edit" />
        </button>);

        const plants = this.props.plants.map((el, i) => {
            return {
                label: el.title,
                value: el.uuid
            }
        });

        const selected_plant = this.state.selected_plant;

        return (
            <div className="tab-content">
                <div className="tab-pane active tab-pane-text">
                    <div className="d-flex">
                        {can_edit && <div className="button-list order-last ml-auto">
                            <Link className="btn btn-outline-primary" to={edit_link}>
                                <FormattedMessage id="common.edit" defaultMessage="Edit" />
                            </Link>
                            {/* <button className="btn btn-outline-secondary" onClick={this.handleDelete}>
                                <FormattedMessage id="common.delete" defaultMessage="Delete" />
                            </button> */}
                            <Link className="btn btn-outline-secondary" to={matrix_link}>
                                <FormattedMessage id="common.matrix" defaultMessage="Matrix" />
                            </Link>
                            <ConfirmationModal
                                title={translate("common.delete", "Delete")}
                                show={this.state.show_delete_modal}
                                onAccept={this.handleDeleteConfirm}
                                onCancel={this.handleDeleteCancel}
                            >
                                {translate("common.are_you_sure_delete_linegroup", "Are you sure you want to delete this line-group?")}{" "}
                                <strong className="font-weignt-500">{this.props.selected.title}</strong>
                            </ConfirmationModal>
                        </div>}

                        <div className="order-first">
                            <h5 className="pane-title">
                                <em><FormattedMessage id="common.linegroup" defaultMessage="Line-group" /></em> {this.props.selected.title}</h5>
                            <dl className="property-list">
                                <div>
                                    <dt><FormattedMessage id="common.external_id" defaultMessage="External ID" /></dt>
                                    <dd>
                                        <div>
                                            {this.props.selected.external_id}
                                        </div>
                                    </dd>
                                </div>
                                <div>
                                    <dt><FormattedMessage id="common.weight" defaultMessage="Weight" /></dt>
                                    <dd>
                                        <div>
                                            {this.props.selected.weight}
                                        </div>
                                    </dd>
                                </div>
                                <div>
                                    <dt><FormattedMessage id="common.tags" defaultMessage="Tags" /></dt>
                                    <dd>{tags}</dd>
                                </div>
                                <div>
                                    <dt className="light"><FormattedMessage id="common.uuid" defaultMessage="UUID" /></dt>
                                    <dd className="light"> <div>{this.props.selected.uuid}</div></dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                        <h2>
                            <FormattedMessage id="Manufacturing.LineGroups.included_lines" defaultMessage="Included lines" />
                        </h2>
                        <LineGroupsModal
                            show={this.state.show_modal}
                            line_group={this.props.selected}
                            lines={this.state.plant_lines}
                            selected_plant={selected_plant}
                            plants={plants}
                            handleModalPlantChange={(selectedValue) => this.handleModalPlantChange(selectedValue)}
                            handleModalSaveClick={(lineCompetenceLevels) => this.handleModalSaveClick(lineCompetenceLevels)}
                            handleModalCloseClick={() => this.handleModalCloseClick()}
                        />
                    </div>
                    <div className="card-deck">
                        <div className="card">
                            <div className="card-header text-uppercase bg-transparent">
                                <FormattedMessage id="common.lines" defaultMessage="Lines" /> (
                                {matched_lines.length}
                                )
                                {can_edit && <div className="float-right">
                                    {updateButton}
                                </div>}
                            </div>
                            <div className="list-group list-group-flush">
                                {matched_lines.map((line) => {
                                    return (
                                        <Link className="list-group-item list-group-item-action" to={`/digital-twin/resources/lines/${line.uuid}`} key={line.uuid}>
                                            {`[${line.external_id}] - ` + line.title}
                                        </Link>
                                    )
                                })}
                            </div>
                            <div className="space15"></div>
                        </div>
                        <div className="card">
                            <div className="card-header text-uppercase bg-transparent">
                                <FormattedMessage id="Manufacturing.LineGroups.linked_plants" defaultMessage="Linked plants" />
                            </div>
                            <div className="list-group list-group-flush">
                                {matched_plants.map((plant) => {
                                    return (
                                        <Link className="list-group-item list-group-item-action" to={`/digital-twin/resources/plants/${plant.uuid}`} key={plant.uuid}>{plant.title}</Link>
                                    )
                                })}
                            </div>
                            <div className="space15"></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default LineGroupsDetails;
