// @flow

import * as ta from "./backend/alerts.generated.types";
import * as td from "./backend/datasources.generated.types";
import { INSIGHT_TYPES } from "./ManufacturingTags.generated";

export type ITags = {
    [key: string]: string
};

export type RegisterUserObj = {
    title: string,
    username: string,
    password: string,
    password2: string,
    accept_terms: boolean,
    provider: string | null,
    provider_username: string,
};

export type LoginUserObj = {
    username: string,
    password: string,
    remember: boolean,
    otp: string
};

export type LoggedinUserObj = {
    must_reset: boolean,
    title: string,
    username: string,
    uuid: string,
    home_dashboard: string | null,
    must_activate_twofa: boolean
};

export type TsObj = {
    ts: number,
    val: number
};

export type TsObjNull = {
    ts: number,
    val: number | null
};

export type TsRangeObj = {
    ts: number,
    val: number,
    low: number,
    high: number
};

export type TsAggrObj = {
    ts: number,
    min: number,
    max: number,
    cnt: number,
    avg: number
};

export type TSeriesObj = {
    uuid: string,
    code: string,
    title: string,
    org_uuid: string,
    datasource_uuid: string | null,
    analysis_uuid: string | null,
    active: boolean,
    tags: any
};

export type SpikeAnalysisParamObj = {
    alert_below_cdf: number,
    alert_above_cdf: number,
    init_min_vals: number,
    eps: number
};

export type SelectedDatasourceObj = {
    datasource: td.IDatasourceExRec | null,
    analyses: td.IAnalysisModelEx[],
    analysis_data: td.IAnalysisData[],
    recent_stats: td.IDatasourceStats | null,
    recent_events_stats: ta.IGetStatsRes | null,
    extra_data: any,
    events: Array<ta.IEventData>
};

// Manufacturing meta-data store

/**
 /**
 * Stock location object.
 */
export type StockLocationObj = {
    uuid: string,
    org_uuid: string,
    external_id: string,
    tags: ITags,
    title: string,
    plant_uuid: string
}

/**
 * Material object.
 */
export type MaterialObj = {
    external_id: string,
    stock_locations?: StockLocationObj[],
    org_uuid: string,
    title: string,
    uuid: string
}

/**
 * Order object - simple class for holding parameters of quick simulation.
 */
export type OrderObjSimParam = {
    earliest_end: string,
    earliest_start: string,
    external_id: string,
    quantity_total: number,
    sim_ignore_earliest_start: boolean
}

/**
 * Shift object - simple class for holding parameters of quick simulation.
 */
export type ShiftObjSimParam = {
    enabled: boolean,
    shift_date: string,
    shift_number: number,
    week_number: number,
    year: number
}

export type ShiftBaseModel = {
    year: number,
    week: number,
    shift_number: number,
    shift_date: string
}

/**
 * Shift number.
 */
export type ShiftNumber = {
    shift: number,
    day_number: number,
    week: number,
    year: number,
    day: string,
    shift_day: number
}

/**
 * Shift object.
 */
export type ShiftObj = {
    enabled: boolean,
    line_uuid: string,
    org_uuid: string,
    shift_number: number,
    shift_uuid: string,
    week: number,
    year: number,
    predicted_in_shift: any,
    produced_in_shift: any,
    events_count: number,
    shift_time: ShiftBaseModel
}

/**
 * Shift data.
 */
export type ShiftNumberData = {
    day: string,
    day_number: number,
    shift: number,
    shift_day: number,
    shift_tag: string,
    ts_end: number;
    ts_start: number;
    week: number,
    year: number
}

export type DashboardSaveObj = {
    active: boolean,
    data_def: any,
    layout_def: any,
    public: boolean,
    tags: any,
    title: string
}

export type DashboardBaseObj = {
    active: boolean,
    created_at: Date,
    created_by: string,
    is_system: boolean,
    org_uuid: string,
    public: boolean,
    tags: any,
    title: string,
    uuid: string
}

export type DashboardObj = {
    active: boolean,
    created_at: Date,
    created_by: string,
    data_def: any,
    is_system: boolean,
    layout_def: any,
    org_uuid: string,
    public: boolean,
    tags: any,
    title: string,
    uuid: string
}

export type DashboardResultIndicatorObjTs = {
    key: string,
    type: string,
    val: number,
    status: string
}
export type DashboardResultIndicatorObjLine = {
    key: string,
    type: string,
    title: string,
    status: string,
    product: string,
    person: string,
    scrap: number,
    parts_total: number,
    parts_target: number,
    downtime: number
}

export type DashboardResultIndicatorObj = DashboardResultIndicatorObjTs | DashboardResultIndicatorObjLine;

export type DashboardResultTimeseriesObj = {
    name: string,
    data: Array<any>
}

export type DashboardResultDataObj = {
    from: number,
    to: number,
    alert_uuids: Array<string>,
    timeseries: Array<DashboardResultTimeseriesObj>,
    indicators: Array<DashboardResultIndicatorObj>
}

export type TimeseriesSearchParams = {
    uuid?: string,
    code?: string,
    title?: string,
    datasource_uuid?: string,
    tags?: any
}

export type DashboardResultLayoutObj = {
    auto_reload?: boolean
}

export type DashboardResultObj = {
    layout: DashboardResultLayoutObj,
    data: DashboardResultDataObj
}

export type StockMaterialModel = {
    uuid: string,
    external_id: string,
    title: string
}

export type StockStockShiftValuesModel = number[]

export type StockStockShiftModel = StockStockShiftValuesModel[]

export type BatchJobStockModel = {
    materials: StockMaterialModel[],
    quantiles: number[],
    shifts: ShiftBaseModel[],
    stock_shift_consumed: StockStockShiftModel[],
    stock_shift_produced: StockStockShiftModel[]
}

export type BatchJobResultModel = {
    from_shift_time: string,
    insights: Object[],
    line_shift_features: Object[],
    next_shift_time: Object,
    orders: Object[],
    stock: BatchJobStockModel
}

export type BatchJobModel = {
    created_at: string,
    created_by: string,
    input: any,
    org_uuid: string,
    result: BatchJobResultModel,
    title: string,
    uuid: string
}

export type WeeklyReportWorker = {
    external_id: string,
    name: string
}

export type WeeklyReportNorm = {
    capacity_factor: number;
    norm: number;
    norm_base_units: number;
    norm_break_time_hours: number;
    norm_machine_time: number;

}

export type WeeklyReport = {
    line_uuid: string,
    line_title: string,
    line_weight?: number,
    material_external_id: string,
    material_title: string,
    measurement_unit: string,
    norm: WeeklyReportNorm,
    order_external_id?: string,
    order_uuid?: string,
    sum: number,
    plan?: number,
    shift_number: number,
    shift_time?: ShiftBaseModel,
    status?: number,
    worker: WeeklyReportWorker[]
}

export type RealizationShiftGrid = WeeklyReport[];
export type RealizationDayShiftGrid = RealizationShiftGrid[];
export type RealizationWeekShiftGrid = RealizationDayShiftGrid[];

export type KanbanMaterial = {
    material_title: string,
    material_uuid: string,
    max_stock: number,
    min_stock: number,
    stock_locations: string[]
}

export type KanbanLine = {
    dashboard_uuid?: string,
    enabled: boolean,
    line_title: string,
    line_uuid: string,
    materials: KanbanMaterial[]
}

/////////////////////////////////////////////////////////////////////

export const EVENT_TYPES = {
    man_downtime: "man.downtime",
    quantiles_simple: "quantiles.simple",
    signal_edge: "signal.edge",
    thresholds_simple: "thresholds.simple",
    downtime: "downtime"
}

export const INSIGHT_TYPES_CSS = {
    [INSIGHT_TYPES.downtime]: "insight-downtime",
    [INSIGHT_TYPES.freeze_order_move]: "plan",
    [INSIGHT_TYPES.man_downtime]: "insight-downtime",
    [INSIGHT_TYPES.no_person]: "people",
    [INSIGHT_TYPES.no_shift]: "people",
    [INSIGHT_TYPES.no_work_in_shift]: "work",
    [INSIGHT_TYPES.operation_delay_downstream]: "plan",
    [INSIGHT_TYPES.operation_no_input_material]: "plan",
    [INSIGHT_TYPES.operation_constraint_violated]: "plan",
    [INSIGHT_TYPES.out_of_stock_shopfloor]: "plan",
    [INSIGHT_TYPES.out_of_stock_plant]: "plan",
    [INSIGHT_TYPES.plan_change_end]: "plan",
    [INSIGHT_TYPES.plan_change_quantity]: "plan",
    [INSIGHT_TYPES.plan_change_shifts]: "plan",
    [INSIGHT_TYPES.plan_change_start]: "plan",
    [INSIGHT_TYPES.quantiles_simple]: "simple",
    [INSIGHT_TYPES.stock_requirement_delay]: "plan",
    [INSIGHT_TYPES.thresholds_simple]: "simple",
    [INSIGHT_TYPES.tool_setup]: "people"
};

export const INSIGHT_ACTIONS = {
    disable_shift: "disble_shift",
    enable_shift: "enable_shift",
    add_person: "add_person",
    remove_person: "remove_person",
    add_remove_person: "add_remove_person",
    increase_quantity: "increase_quantity",
    decrease_quantity: "decrease_quantity",
    change_work_order_dates: "change_work_order_dates",
    enable_24_7: "enable_24_7",
    change_work_order_sequence: "change_work_order_sequence",
    check_sap_for_shortage: "check_sap_for_shortage",
    replan_in_sap: "replan_in_sap",
    print_tool_transfer: "print_tool_transfer"
}

// Defines for which insight types we want to show Actions button
export const INSIGHT_ACTIONS_ARRAY = [
    INSIGHT_TYPES.freeze_order_move,
    INSIGHT_TYPES.no_person,
    INSIGHT_TYPES.no_shift,
    INSIGHT_TYPES.out_of_stock_shopfloor,
    INSIGHT_TYPES.out_of_stock_plant,
    INSIGHT_TYPES.tool_setup
]

export const JOB_TYPES = {
    electricity_consumption: "electricity_consumption",
    kpi_calculation: "kpi_calculation",
    manufacturing_checks: "manufacturing_checks",
    plan_import: "plan_import",
    plan_import_internal: "plan_import_internal",
    plan_table_export: "plan_table_export",
    production_planning: "production_planning",
    production_planning_wrapper: "production_planning_wrapper",
    production_simulation: "production_simulation",
    production_version_change: "production_version_change",
    rewire_parallel_materials: "rewire_parallel_materials",
    stock_requirements_simple: "stock_requirements_simple",
    update_unconfirmed_stock: "update_unconfirmed_stock"
};
