// @flow

import React from "react";

import moment from "moment";
import HighchartsReact from "highcharts-react-official";
import Highcharts, { type Point } from "highcharts";

import { translate } from "./IntlProviderWrapper";
import { getDecimalSeparator, getThousandsSeparator } from "../lib/Util";
import { type DataItem } from "./DemandForecasting";
import DemandForecastingTable, { renderRealNumber, type TableRow } from "./DemandForecastingTable";
import DemandForecastingHistory from "./DemandForecastingHistory";

type Props = {
    rows: TableRow[],
    data: DataItem[],
    showDetailsColumns: boolean
};

type State = {
    chart_options: any
};

const expandTimeSeries = (data: Point[]): Point[] => {
    const expanded_data: Point[] = [];

    data.forEach((point, i) => {
        expanded_data.push(point);

        if (i < data.length - 1) {
            const current_month = moment
                .unix(point.x / 1000)
                .startOf("month")
                .add(1, "months");
            const max_month = moment.unix(data[i + 1].x / 1000).startOf("month");
            while (current_month.isBefore(max_month)) {
                expanded_data.push({
                    x: new Date(current_month.format("YYYY-MM")).getTime(),
                    y: null
                });
                current_month.add(1, "months");
            }
        }
    });

    return expanded_data;
};

Highcharts.setOptions({
    lang: {
        months: [...Array(12).keys()].map(month => translate(`common.month${month}`)),
        shortMonths: [...Array(12).keys()].map(month => translate(`common.month${month}`).slice(0, 3)),
        weekdays: [...Array(7).keys()].map(week => translate(`common.weekday${week}`)),
        thousandsSep: getThousandsSeparator(),
        decimalPoint: getDecimalSeparator()
    }
});

class DemandFortecastingDetails extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            chart_options: this.getChartOptions()
        };
    }

    componentDidUpdate(prev_props: Props) {
        const prev_plant = prev_props.data[0] && prev_props.data[0].properties.plant;
        const prev_material = prev_props.data[0] && prev_props.data[0].properties.material;
        const plant = this.props.data[0] && this.props.data[0].properties.plant;
        const material = this.props.data[0] && this.props.data[0].properties.material;

        if (prev_plant !== plant || prev_material !== material) {
            this.setState({
                chart_options: this.getChartOptions()
            });
        }
    }

    getChartOptions = () => {
        const chart_options = [];
        for (const data_item of this.props.data) {
            const series = [];
            const { properties, model, data } = data_item;
            const { planned_independent_requirements, sales_orders, sales_orders_changes, PIR_SO_max } = data;

            series.push({
                name: translate("DemandForecasting.sales_orders", "Sales orders"),
                data: expandTimeSeries(
                    sales_orders.ts.map((ts, i) => ({
                        x: new Date(ts).getTime(),
                        y: sales_orders.vals[i]
                    }))
                )
            });

            series.push({
                name: translate("DemandForecasting.max_pir_so", "max(PIR, SO)"),
                data: expandTimeSeries(
                    PIR_SO_max.ts.map((ts, i) => ({
                        x: new Date(ts).getTime(),
                        y: PIR_SO_max.vals[i]
                    }))
                )
            });

            series.push({
                name: translate("DemandForecasting.sales_orders_changes", "Sales orders changes"),
                data: expandTimeSeries(
                    sales_orders_changes.ts.map((ts, i) => ({
                        x: new Date(ts).getTime(),
                        y: sales_orders_changes.vals[i]
                    }))
                ),
                visible: false
            });

            series.push({
                name: translate("DemandForecasting.pir", "PIR"),
                data: expandTimeSeries(
                    planned_independent_requirements.ts.map((ts, i) => ({
                        x: new Date(ts).getTime(),
                        y: planned_independent_requirements.vals[i]
                    }))
                ),
                visible: false
            });

            const title_props = [
                `${translate("common.plant", "Plant")}: ${properties.plant}`,
                `${translate("common.material", "Material")}: ${properties.material}`,
                `${translate("common.horizon", "Horizon")}: ${properties.horizon}`
            ];

            const subtitle_props = [
                `${translate("DemandForecasting.correction_coefficient", "Corr. coef.")}: ${renderRealNumber({
                    value: model.coef
                })}`,
                `${translate("DemandForecasting.mae_original", "MAE (orig.)")}: ${renderRealNumber({
                    value: model.MAE_original
                })}`,
                `${translate("DemandForecasting.mae_new", "MAE (new)")}: ${renderRealNumber({
                    value: model.MAE_new
                })}`
            ];

            chart_options.push({
                title: {
                    text: title_props.join(", ")
                },
                subtitle: {
                    text: subtitle_props.join(", ")
                },
                colors: ["#5e8aee", "#f86a6a", "#a368fc", "#2dcca7"],
                time: {
                    useUTC: true
                },
                chart: {
                    type: "line"
                },
                xAxis: {
                    type: "datetime",
                    title: {
                        text: translate("common.date", "Date")
                    }
                },
                yAxis: {
                    title: {
                        text: translate("common.quantity", "Quantity")
                    }
                },
                series: series
            });
        }

        return chart_options;
    };

    render() {
        return (
            <div className="df-details row mx-n2">
                <div className="col-12 px-2">
                    <div className="white_box">
                        <DemandForecastingTable
                            rows={this.props.rows}
                            showDetailsColumns={this.props.showDetailsColumns}
                            variant="details"
                        />
                    </div>
                    <div className="white_box">
                        <DemandForecastingHistory data={this.props.data} />
                    </div>
                </div>
                <div className="col-12 px-2">
                    <div className="white_box">
                        {this.state.chart_options.map((opts, i) => (
                            <HighchartsReact
                                key={i}
                                containerProps={{
                                    className: i > 0 ? "mt-4" : ""
                                }}
                                highcharts={Highcharts}
                                options={opts}
                            />
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

export default DemandFortecastingDetails;
