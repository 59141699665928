// @flow

// React
import React, { Component } from "react";
import ChevronListItem from "../react/ChevronListItem";

// Models
import * as t from "../../lib/backend/manufacturing2.generated.types";


type Props = {
    filter: string,
    plantFilter: string,
    selected: t.IStockLocation | null,
    items: t.IStockLocation[],
    handleItemClick: any
}

/**
 * Plants list component.
 */
class StockLocationsList extends Component<Props> {
    /**
     * Handle `click` events for the plant item.
     */
    handleItemClick(item: t.IStockLocation, i: number) {
        // Ignore clicks for currently selected plant.
        if (item === this.props.selected) {
            return;
        }

        // Handle parent's click events.
        this.props.handleItemClick(item);
    }

    /**
     * Plant list filter.
     *
     * @param {string} needle Search term.
     * @param {IStockLocation} plant Plant object to match search term against.
     *
     * @return {boolean} `true` if plant matches given search term, `false` otherwise.
     */
    includes(needle: string, item: any) {
        needle = needle.toLowerCase();
        if (needle.length === 0) return true;
        if (item.title.toLowerCase().indexOf(needle) > -1) return true;
        if (item.external_id.toLowerCase().indexOf(needle) > -1) return true;
        if (item.uuid.toLowerCase().indexOf(needle) > -1) return true;
        return false;
    }

    /**
     * Render component.
     */
    render() {
        const itemsList = this.props.items.map((item, i) => {
            let is_active = this.props.selected === item;

            if (this.props.plantFilter && item.plant_uuid !== this.props.plantFilter) return null;
            if (!this.includes(this.props.filter, item)) return null;

            return <ChevronListItem
                text={
                    <div className="clearfix">
                        <span>{item.title}</span>
                    </div>
                }
                onClick={() => this.handleItemClick(item, i)}
                is_active={is_active}
            />;
        })

        return (
            <ul className="nav nav-tabs">
                <div className="nav-tabs-parent" ref="nav">
                    {itemsList}
                </div>
            </ul>
        )
    }
}

export default StockLocationsList;
