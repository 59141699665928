// @flow
import * as React from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import Authorization from "../../Authorization";
import { translate } from "../../IntlProviderWrapper";
import { classNames, niceNumber, parseFloatSmart } from "../../../lib/Util";
import { PERMISSION_NAMES } from "../../../lib/Auth";
import { REPORT_LOADING_ACTION_NAME } from "./reducers/report";
import {
    UPDATE_LINE_ORDER_ACTION_NAME,
    SET_UNSCHEDULED_ORDER_UUID,
    SNAP_TO_EARLIEST_END,
    SNAP_TO_EARLIEST_START,
    IGNORE_ORDERS
} from "./reducers/linesOrders";
import { SHOW_UNSCHEDULED_ORDERS, SET_ADVANCED_ORDER_MENU, SET_LEFT_MENU_SELECTED_VIEW } from "./reducers/planningTable";
import { SET_ORDER_TABLE_PRODUCTION_MODAL } from "./reducers/ganttChartStandalone";
import { calculateReportOrderEpochs, getPreviousOperationEpochs, LinesOrdersLogic, ReduxFilters } from "./reducers/BusinessLogic";
import * as t from "../../../lib/backend/manufacturing2.generated.types";
import * as rt from "../../../lib/backend/reports.generated.types";
import * as t2 from "../../../lib/SimulationReportModels";
import * as srt from "../../../lib/SimulationReportModels";
import MoveOrderModal from "./MoveOrderModal";
import DraggableModal from "../../DraggableModal";
import { SHOW_EDIT_COMMENT_MODAL } from "./reducers/properties";
import {
    LINE_GROUP_TAGS_ACCESS,
    LINE_TAGS_ACCESS,
    ORDERS_WITH_EDITABLE_QUANTITY,
    ORDER_TAGS,
    ORDER_TAGS_ACCESS,
    PLANT_TAGS_ACCESS
} from "../../../lib/ManufacturingTags.generated";
import { getBackend } from "../../../lib/backend/Backend2";
import { ORDER_TYPE } from "../../../lib/ManufacturingConsts.generated";
import ConfirmationModal from "../../ConfirmationModal";
import { OperationsTable } from "./LeftMenu/IgnoredOrders";
import { getLineGroupTags, getPlantTags } from "../../../lib/BusinessLogic";
import ErrorComponent from "../../ErrorComponent";
import LinkOperationsModal from "./LinkOperationsModal";
import LinkOrdersModal, { LINK_ORDERS_MODE } from "./LinkOrdersModal";
import { LinesOrdersMaps } from "./reducers/Mappers";

import type { ReportLoadingAction } from "./reducers/report";
import type {
    IOrderProducedModelEx,
    UpdateLineOrderAction,
    SetUnscheduledOrderUuidAction,
    LineOrders,
    SnapToEarliestEndAction,
    SnapToEarliestStartAction,
    IgnoreOrdersAction,
    IgnoreOrdersMap
} from "./reducers/linesOrders";
import type { ReduxState } from "./reducers/reducers";
import type { ReportParameters, SetRescheduleOrderBundleAction } from "./reducers/common";
import type { SetRescheduleOrderAction } from "./reducers/common";
import type {
    ShowUnscheduledOrdersAction,
    AdvancedOrderMenuState,
    SetLastClickedOrderUuid
} from "./reducers/planningTable";

import ChunkingModal from "./ChunkingModal";

import type { SetAdvancedOrderMenuAction } from "./reducers/planningTable";
import type { SetOrderTableProductionModalAction } from "./reducers/ganttChartStandalone";
import type { SetLeftMenuSelectedView } from "./reducers/planningTable";
import type { SetHighlightedOrders } from "./reducers/planningTable";
import type { ResetFiltersAction } from "./reducers/planningTable";
import type { ShowEditCommentModal } from "./reducers/planningTable";
import type { LinkOrdersMode } from "./LinkOrdersModal";

const ORDER_ACTIONS = {
    edit_params: "edit_params",
    fix_line: "fix_line",
    freeze_workorder: "freeze_workorder",
    ignore_operation: "ignore_operation",
    ignore_order: "ignore_order",
    ignore_parent_order: "ignore_parent_order",
    move_order: "move_order",
    schedule: "schedule",
    set_unscheduled: "set_unscheduled",
    show_details: "show_details",
    show_edit_modal: "show_edit_modal",
    snap_to_end: "snap_to_end",
    snap_to_start: "snap_to_start",
    unfix_line: "unfix_line",
    unfreeze_workorder: "unfreeze_workorder",
    link_operation: "link_operation",
    link_order: "link_order",
    link_parent_order: "link_parent_order",
    chunk_order: "chunk_order"
}

type Props = {
    order: IOrderProducedModelEx,
    report_order: t2.ISimulationReportOrderEx | null,
    line_uuid: string,
    line_group_uuid: string,
    reduxDispatch: (
        args: (
            UpdateLineOrderAction | SetUnscheduledOrderUuidAction | SetRescheduleOrderAction |
            ShowUnscheduledOrdersAction | SetAdvancedOrderMenuAction |
            SetOrderTableProductionModalAction | SnapToEarliestStartAction |
            SnapToEarliestEndAction | SetLeftMenuSelectedView |
            SetHighlightedOrders | ResetFiltersAction | ShowEditCommentModal |
            SetRescheduleOrderAction | SetLastClickedOrderUuid | IgnoreOrdersAction |
            SetRescheduleOrderBundleAction | ReportLoadingAction
        )
    ) => void,
    advanced_order_menu: AdvancedOrderMenuState,
    parameters: ReportParameters,
    next_shift_time: rt.ISimulationReportShiftTime,
    order_line: LineOrders | null,
    report_data: srt.IReportModelEx,
    is_unscheduled: boolean,
    order_index: number,
    is_readonly: boolean,
    is_filter_locked: boolean,
    is_linked_operation: boolean,
    ignore_orders_status: IgnoreOrdersMap | null
};

type State = {
    order: IOrderProducedModelEx | null,
    show_modal: boolean,
    show_move_modal: boolean,
    is_schedule: boolean,
    ignore_operations: t.IOrderModelBase[] | null,
    show_warning: boolean,
    show_link_operations_modal: boolean,
    link_orders_mode: LinkOrdersMode | null,
    show_chunking_modal: boolean
};

type SelectOptionItem = {
    value: string,
    label: string
}

const isParallelOrder = (order: IOrderProducedModelEx | t.IOrderModelBase): boolean => {
    return (
        ORDER_TAGS_ACCESS.parallel_order(order.tags) ||
        ORDER_TAGS_ACCESS.parallel_parent(order.tags)
    );
}

class OrderAdvancedMenu extends React.Component<Props, State> {

    state = {
        order: null,
        show_modal: false,
        show_move_modal: false,
        is_schedule: false,
        ignore_operations: null,
        show_warning: false,
        show_link_operations_modal: false,
        link_orders_mode: null,
        show_chunking_modal: false
    }

    clickListener;

    componentDidMount() {
        if (this.props.order) {
            this.setState({ order: this.props.order, show_move_modal: false, is_schedule: false })
        }

        if (!this.clickListener) {
            this.clickListener = this.onClickListener(this);
        }

        window.addEventListener("click", this.clickListener);
    }

    componentDidUpdate(prev_props: Props) {
        if (prev_props.order !== this.props.order) {
            this.setState({ order: this.props.order, show_move_modal: false, is_schedule: false });
        }
    }

    componentWillUnmount() {
        if (this.clickListener) {
            window.removeEventListener("click", this.clickListener);
        }
    }

    onClickListener = (self) => (event: Event) => {
        if (!self.props.advanced_order_menu.click_target) return;
        event.stopPropagation();
        event.preventDefault();

        // $FlowFixMe
        if (event.target instanceof HTMLElement || event.target instanceof SVGElement) {
            const target: HTMLElement = event.target;

            if (target) {
                let should_close = (
                    !target.closest("#advanced-order-menu") &&
                    !target.closest("#planning-advanced-order-modal-container") &&
                    !target.closest(".order-table-production-modal") &&
                    !target.closest("[id^='react-select']")
                );

                if (should_close) {
                    this.closeMenu(true);
                }
            }
        }
    }

    getOrder = (): IOrderProducedModelEx | null=> {
        return this.state.order;
    }

    getAdvancedMenuOrderUuid = () => {
        return this.props.advanced_order_menu.order_uuid;
    }

    handleChange = (event: Event) => {
        const order = this.getOrder();
        if (!order) return;

        let target = event.target;

        if (!(target instanceof HTMLSelectElement || target instanceof HTMLInputElement)) return;

        const name = target.name;

        if (name === "capacity_factor") {
            let capacity_factor = order.capacity_factor;
            if (typeof order.override_capacity_factor === "number") {
                capacity_factor = order.override_capacity_factor;
            }

            order.override_capacity_factor = parseFloatSmart(target.value, capacity_factor) / 100;
        }

        if (name === "quantity") {
            order.quantity_total = target.value ? parseFloat(target.value) : 0;
        }

        this.setState({ order })
    }

    onSave = () => {
        this.setState({ show_modal: false });
        this.closeMenu();
        const order = this.getOrder();

        if (!order) return;

        this.props.reduxDispatch({ type: REPORT_LOADING_ACTION_NAME, data: true });
        this.props.reduxDispatch({
            type: UPDATE_LINE_ORDER_ACTION_NAME,
            data: {
                line_uuid: this.props.line_uuid,
                order_uuid: order.uuid,
                order
            }
        })
    }

    onClose = (e: Event) => {
        if (e) {
            e.stopPropagation();
        }
        this.setState({ show_modal: false });
        this.closeMenu();
    }

    getMoveOrder = () => {
        if (!this.props.is_readonly) {
            return {
                value: ORDER_ACTIONS.move_order,
                label: this.props.is_unscheduled
                    ? translate("common.change_time", "Change time")
                    : translate("common.move", "Move")
            }
        }
        return null;
    }

    getEditParams = () => {
        if (!this.props.is_readonly) {
            return {
                value: ORDER_ACTIONS.edit_params,
                label: translate("common.edit_params", "Edit parameters")
            };
        }
        return null;
    }

    getSnapToStart = () => {
        if (!this.props.is_readonly) {
            return {
                value: ORDER_ACTIONS.snap_to_start,
                label: translate("common.snap_to_start", "Sets the start to the beginning of the previous operation")
            };
        }
        return null;
    }

    getSnapToEnd = () => {
        if (!this.props.is_readonly) {
            return {
                value: ORDER_ACTIONS.snap_to_end,
                label: translate("common.snap_to_end", "Sets the start at the end of the previous operation")
            };
        }

        return null;
    }

    getSetAsUnscheduled = () => {
        if (!this.props.is_readonly) {
            return {
                value: ORDER_ACTIONS.set_unscheduled,
                label: translate("common.set_as_unscheduled", "Set as unscheduled")
            };
        }
        return null;
    }

    getEditComment = () => {
        if (!this.props.is_readonly) {
            return {
                value: ORDER_ACTIONS.show_edit_modal,
                label: translate("common.edit_comment", "Edit comment")
            };
        }
        return null;
    }

    getIgnoreOptions = (order: IOrderProducedModelEx) => {
        const options = [
            {
                value: ORDER_ACTIONS.ignore_operation,
                label: translate("common.ignore_operation", "Ignore operation")
            }
        ];

        const is_parallel_order = isParallelOrder(order)
        const plant_uuid = localStorage.getItem("last-plant");
        const plant_tags = plant_uuid ? getPlantTags(plant_uuid) : {};
        if (!is_parallel_order && PLANT_TAGS_ACCESS.planning_table_show_ignore_order(plant_tags)) {
            options.push({
                value: ORDER_ACTIONS.ignore_order,
                label: translate("common.ignore_order", "Ignore order")
            });
        }

        if (
            !is_parallel_order &&
            PLANT_TAGS_ACCESS.planning_table_show_ignore_parent_order(plant_tags) &&
            ORDER_TAGS_ACCESS.external_parent_order_id(order.tags) !== ""
        ) {
            options.push({
                value: ORDER_ACTIONS.ignore_parent_order,
                label: translate("common.ignore_parent_order", "Ignore parent order")
            });
        }

        return options;
    };

    getLinkOptions = (order: IOrderProducedModelEx) => {
        const { order_line } = this.props;
        const line_group_tags = getLineGroupTags(this.props.line_group_uuid);
        const rework_orders = LinesOrdersMaps.base_uuid_rework_orders.get(order.uuid) || [];
        const is_link_allowed = rework_orders.length === 0 &&
            LINE_GROUP_TAGS_ACCESS.planning_table_show_link_operation(line_group_tags) &&
            order_line && !LINE_TAGS_ACCESS.planning_autopilot(order_line.tags);
        if (!is_link_allowed) {
            return [];
        }

        const options = [
            {
                label: this.props.is_linked_operation
                    ? translate("common.edit_link", "Edit link")
                    : translate("common.link_operation", "Link operation"),
                value: ORDER_ACTIONS.link_operation
            },
            {
                label: translate("common.link_order", "Link order"),
                value: ORDER_ACTIONS.link_order
            }
        ];

        if (ORDER_TAGS_ACCESS.external_parent_order_id(order.tags) !== "") {
            options.push({
                label: translate("common.link_parent_order", "Link parent order"),
                value: ORDER_ACTIONS.link_parent_order
            });
        }

        return options;
    };

    getChunkOrder = () => {
        const plant_uuid = localStorage.getItem("last-plant");
        const plant_tags = plant_uuid ? getPlantTags(plant_uuid) : {};
        if (!PLANT_TAGS_ACCESS.new_feature_planning_table_chunking(plant_tags)) {
            return null;
        }
        return {
            label: translate("common.chunk_order", "Chunk order"),
            value: ORDER_ACTIONS.chunk_order
        };
    };

    getOptions = () => {
        const order = this.getOrder();
        if (order === null) {
            return [];
        }

        if (this.props.is_linked_operation) {
            return [
                {
                    value: ORDER_ACTIONS.show_details,
                    label: translate("common.tooltip_show_details", "Edit parameters")
                },
                ...this.getIgnoreOptions(order),
                ...this.getLinkOptions(order)
            ];
        }

        if (this.props.is_unscheduled) {
            return [
                {
                    value: ORDER_ACTIONS.schedule,
                    label: translate("common.schedule_verb", "Schedule")
                },
                this.getMoveOrder(),
                {
                    value: ORDER_ACTIONS.show_details,
                    label: translate("common.tooltip_show_details", "Edit parameters")
                },
                this.getEditComment(),
                ...this.getIgnoreOptions(order),
                ...this.getLinkOptions(order)
            ];
        }

        const options = [
            this.getMoveOrder(),
            {
                value: ORDER_ACTIONS.show_details,
                label: translate("common.tooltip_show_details", "Edit parameters")
            },
            this.getEditParams(),
            this.getChunkOrder(),
            this.getEditComment(),
            this.getSetAsUnscheduled(),
            this.getSnapToStart(),
            this.getSnapToEnd(),
            ...this.getIgnoreOptions(order),
            ...this.getLinkOptions(order)
        ].filter(el => el);

        if (!this.props.is_readonly) {
            if (order.tags.insight_freeze_order === "true") {
                options.push({
                    value: ORDER_ACTIONS.unfreeze_workorder,
                    label: translate("Manufacturing.Planning.param_unfreeze_work_order", "Unfreeze workorder")
                });
            } else {
                options.push({
                    value: ORDER_ACTIONS.freeze_workorder,
                    label: translate("Manufacturing.Planning.param_freeze_work_order", "Freeze workorder")
                });
            }

            if (order.tags.fix_line !== undefined) {
                options.push({
                    value: ORDER_ACTIONS.unfix_line,
                    label: translate("Manufacturing.Planning.param_unfix_line", "Unfix line")
                });
            } else {
                options.push({
                    value: ORDER_ACTIONS.fix_line,
                    label: translate("Manufacturing.Planning.param_fix_line", "Fix line")
                });
            }
        }

        return options;
    }

    handleSelectChange = async (selected: SelectOptionItem) => {
        const order = this.getOrder();
        if (!order) return;

        const ignore_orders_status = this.props.ignore_orders_status || new Map();
        if (selected.value === ORDER_ACTIONS.edit_params) {
            this.setState({ show_modal: true });
        } else if (selected.value === ORDER_ACTIONS.set_unscheduled) {
            this.props.reduxDispatch({
                type: SET_UNSCHEDULED_ORDER_UUID,
                data: { line_uuid: this.props.line_uuid, order_uuid: order.uuid }
            });
            this.props.reduxDispatch({
                type: SET_LEFT_MENU_SELECTED_VIEW,
                data: "orders"
            })
            this.props.reduxDispatch({ type: SHOW_UNSCHEDULED_ORDERS, data: true });
            await ReduxFilters.setRescheduleOrderUuid(this.props.reduxDispatch,
                order.uuid, "line_order_list", this.props.is_filter_locked);
        } else if (selected.value === ORDER_ACTIONS.show_edit_modal) {
            this.props.reduxDispatch({
                type: SHOW_EDIT_COMMENT_MODAL,
                data: order.uuid
            });
        } else if (selected.value === ORDER_ACTIONS.show_details) {
            this.props.reduxDispatch({
                type: SET_ORDER_TABLE_PRODUCTION_MODAL,
                data: {
                    show_modal: true,
                    order_external_id: order.external_id,
                    order_uuid: order.uuid,
                    order_process_num: order.process_num
                }
            });
        } else if (selected.value === ORDER_ACTIONS.freeze_workorder) {
            if (!this.props.report_order) {
                console.error("Could not find report order...");
                this.closeMenu();
                return;
            }

            order.tags.insight_freeze_order = "true";
            const {
                initial_epoch_start,
                initial_epoch_end
            } = calculateReportOrderEpochs(this.props.report_order, this.props.next_shift_time, 1)

            if (initial_epoch_start !== null && initial_epoch_end !== null) {
                order.tags.freeze_time_start = initial_epoch_start.toString();
                order.tags.freeze_time_end = initial_epoch_end.toString();
                this.props.reduxDispatch({
                    type: UPDATE_LINE_ORDER_ACTION_NAME,
                    data: {
                        line_uuid: this.props.line_uuid,
                        order_uuid: order.uuid,
                        order
                    }
                });
            }
        } else if (selected.value === ORDER_ACTIONS.unfreeze_workorder) {
            order.tags.insight_freeze_order = "false";
            this.props.reduxDispatch({
                type: UPDATE_LINE_ORDER_ACTION_NAME,
                data: {
                    line_uuid: this.props.line_uuid,
                    order_uuid: order.uuid,
                    order
                }
            });
        } else if (selected.value === ORDER_ACTIONS.fix_line) {
            order.tags.fix_line = this.props.line_uuid;
            this.props.reduxDispatch({
                type: UPDATE_LINE_ORDER_ACTION_NAME,
                data: {
                    line_uuid: this.props.line_uuid,
                    order_uuid: order.uuid,
                    order
                }
            });
        } else if (selected.value === ORDER_ACTIONS.unfix_line) {
            delete order.tags.fix_line;
            this.props.reduxDispatch({
                type: UPDATE_LINE_ORDER_ACTION_NAME,
                data: {
                    line_uuid: this.props.line_uuid,
                    order_uuid: order.uuid,
                    order
                }
            });
        } else if (selected.value === ORDER_ACTIONS.snap_to_start) {
            const times = getPreviousOperationEpochs(
                this.props.report_data,
                this.props.order.external_id,
                parseFloat(this.props.order.process_num)
            );

            if (times && times.initial_epoch_start) {
                this.props.reduxDispatch({
                    type: SNAP_TO_EARLIEST_START,
                    data: {
                        order_uuid: this.props.order.uuid,
                        time_start: times.initial_epoch_start
                    }
                });
            }
        } else if (selected.value === ORDER_ACTIONS.snap_to_end) {
            const times = getPreviousOperationEpochs(
                this.props.report_data,
                this.props.order.external_id,
                parseFloat(this.props.order.process_num)
            );
            if (times && times.initial_epoch_end) {
                this.props.reduxDispatch({
                    type: SNAP_TO_EARLIEST_END,
                    data: {
                        order_uuid: this.props.order.uuid,
                        time_end: times.initial_epoch_end
                    }
                });
            }
        } else if (selected.value === ORDER_ACTIONS.move_order) {
            this.setState({ show_move_modal: true });
        } else if (selected.value === ORDER_ACTIONS.schedule) {
            this.setState({ show_move_modal: true, is_schedule: true });
        } else if (selected.value === ORDER_ACTIONS.ignore_operation) {
            this.props.reduxDispatch({
                type: IGNORE_ORDERS,
                data: {
                    order_uuids: [order.uuid]
                }
            });
        } else if (selected.value === ORDER_ACTIONS.ignore_order) {
            const { operations } = await getBackend().manufacturing.getOrdersSimple({
                order_external_id: order.external_id,
                order_types: [ORDER_TYPE.full, ORDER_TYPE.plan]
            });

            if (operations.some(o => isParallelOrder(o))) {
                this.setState({
                    show_warning: true
                });
                return;
            }

            const ignore_operations = operations.filter(
                order => ignore_orders_status.get(order.uuid) !== true
            );
            this.setState({ ignore_operations });
        } else if (selected.value === ORDER_ACTIONS.ignore_parent_order) {
            const external_parent_order_id = ORDER_TAGS_ACCESS.external_parent_order_id(order.tags);
            if (external_parent_order_id !== "") {
                const { operations } = await getBackend().manufacturing.getOrdersSimple({
                    tags_obj: {
                        [ORDER_TAGS.external_parent_order_id]: external_parent_order_id
                    },
                    order_types: [ORDER_TYPE.full, ORDER_TYPE.plan]
                });
                if (operations.some(o => isParallelOrder(o))) {
                    this.setState({
                        show_warning: true
                    });
                    return;
                }

                const ignore_operations = operations.filter(order => ignore_orders_status.get(order.uuid) !== true);
                this.setState({ ignore_operations });
            }
        } else if (selected.value === ORDER_ACTIONS.link_operation) {
            this.setState({
                show_link_operations_modal: true
            });
        } else if (selected.value === ORDER_ACTIONS.link_order) {
            this.setState({
                link_orders_mode: LINK_ORDERS_MODE.order
            });
        } else if (selected.value === ORDER_ACTIONS.link_parent_order) {
            this.setState({
                link_orders_mode: LINK_ORDERS_MODE.parent_order
            });
        } else if (selected.value === ORDER_ACTIONS.chunk_order) {
            this.setState({ show_chunking_modal: true });
        }

        this.closeMenu();
    }

    closeMenu = (should_unhiglight_order?: boolean) => {
        this.props.reduxDispatch({
            type: SET_ADVANCED_ORDER_MENU,
            data: {
                order_uuid: should_unhiglight_order ? null : this.getAdvancedMenuOrderUuid(),
                click_target: null,
                event: null
            }
        });
    }

    onOpenMenu = (e: Event) => {
        const advanced_menu_order_uuid = this.getAdvancedMenuOrderUuid();
        if (advanced_menu_order_uuid) {
            this.closeMenu();
        }

        e.preventDefault();
        e.stopPropagation();
    }

    onCloseMoveModal = () => {
        this.setState({ show_move_modal: false, is_schedule: false });
        this.props.reduxDispatch({
            type: SET_ADVANCED_ORDER_MENU,
            data: {
                order_uuid: null,
                click_target: null,
                event: null
            }
        });
    }

    handleAcceptIgnoreOperations = async () => {
        const { ignore_operations } = this.state;
        if (ignore_operations !== null && ignore_operations.length > 0) {
            this.props.reduxDispatch({
                type: IGNORE_ORDERS,
                data: {
                    order_uuids: ignore_operations.map(o => o.uuid)
                }
            });
        }

        this.setState({
            ignore_operations: null
        });
    };

    handleCancelIgnoreOperations = () => {
        this.setState({
            ignore_operations: null
        });
    };

    handleWarningClose = () => {
        this.setState({
            show_warning: false
        });
    };

    handleLinkOperationsClose = () => {
        this.setState({
            link_orders_mode: null,
            show_link_operations_modal: false
        });
    };

    handleChunkingModalClose = () => this.setState({ show_chunking_modal: false });

    renderIgnoreConfirmationModal = () => {
        const { ignore_operations } = this.state;
        const has_ignore_operations = ignore_operations !== null && ignore_operations.length > 0;
        return (
            <ConfirmationModal
                class_name="modal-dialog-scrollable confirmation-modal-ignore-operations"
                show={ignore_operations !== null}
                title={translate("common.ignore_operations", "Ignore operations")}
                onAccept={
                    has_ignore_operations
                        ? this.handleAcceptIgnoreOperations
                        : this.handleCancelIgnoreOperations
                }
                onCancel={has_ignore_operations ? this.handleCancelIgnoreOperations : undefined}
                accept_label={has_ignore_operations ? undefined : translate("common.ok", "OK")}
            >
                <p
                    className={classNames({
                        "mb-0": !has_ignore_operations
                    })}
                >
                    {has_ignore_operations
                        ? translate(
                            "common.are_you_sure_ignore_operations",
                            "Are you sure you want to ignore the following operations?"
                        )
                        : translate(
                            "common.no_operation_found",
                            "No operation found"
                        )}
                </p>
                {has_ignore_operations && <OperationsTable operations={ignore_operations || []} />}
            </ConfirmationModal>
        );
    };

    renderWarningModal = () => {
        return (
            <ConfirmationModal
                show={this.state.show_warning}
                title={translate("common.unsupported_action", "Unsupported action")}
                accept_label={translate("common.ok", "OK")}
                onAccept={this.handleWarningClose}
                onClose={this.handleWarningClose}
            >
                <ErrorComponent
                    className="my-0"
                    type="warning"
                    msg={translate(
                        "common.action_not_supported_on_parallel_operations",
                        "This action is currently not supported, because one of the operations is part of a parallel order."
                    )}
                />
            </ConfirmationModal>
        );
    };

    renderLinkOperationsModal = () => {
        return (
            <LinkOperationsModal
                rework_operation={this.state.order}
                show={this.state.show_link_operations_modal}
                onClose={this.handleLinkOperationsClose}
            />
        );
    };

    renderLinkOrdersModal = () => {
        return (
            <LinkOrdersModal
                rework_operation={this.state.order}
                link_mode={this.state.link_orders_mode}
                onClose={this.handleLinkOperationsClose}
            />
        );
    };

    renderChunkOrderModal = () => {
        return (
            <ChunkingModal
            order_uuid={this.props.order !== null ? this.props.order.uuid : ""}
                show={this.state.show_chunking_modal}
                onClose={this.handleChunkingModalClose}
            />
        );
    };

    onKeyPress = (e: KeyboardEvent) => {
        if (e.key === "Enter") {
            this.onSave();
        }
    }

    render() {
        const order = this.getOrder();
        if (!order) return null;

        let capacity_factor = order.capacity_factor;
        if (typeof order.override_capacity_factor === 'number') {
            capacity_factor = order.override_capacity_factor;
        }
        let container_style = {};
        const event = this.props.advanced_order_menu.event;

        if (event && event.clientX && event.clientY) {
            container_style = {
                top: event.clientY,
                left: event.clientX,
                position: "absolute"
            }
        }

        let quantity_edit_disabled = false;
        if (order.tags) {
            quantity_edit_disabled = ORDER_TAGS_ACCESS.parallel_parent(order.tags);
            const line_group_tags = getLineGroupTags(this.props.line_group_uuid);
            const orders_with_editable_quantity = LINE_GROUP_TAGS_ACCESS.planning_table_orders_with_editable_quantity(line_group_tags);
            if (orders_with_editable_quantity === ORDERS_WITH_EDITABLE_QUANTITY.no_orders) {
                quantity_edit_disabled = true;
            } else if (
                orders_with_editable_quantity === ORDERS_WITH_EDITABLE_QUANTITY.planning_orders &&
                order.order_type !== ORDER_TYPE.plan
            ) {
                quantity_edit_disabled = true;
            }
            if (LinesOrdersMaps.chunk_orders.has(order.uuid)) {
                quantity_edit_disabled = true;
            }
        }

        return <div>
            {this.props.advanced_order_menu.click_target && <Select
                id="advanced-order-menu"
                options={this.getOptions().filter(el => el)}
                onChange={this.handleSelectChange}
                menuPlacement={"auto"}
                onValueClick={(e) => { e.stopPropagation(); }}
                styles={{
                    menu: (style) => ({ ...style, minWidth: "200px", zIndex: 10000 }),
                    container: (style) => ({ ...style, ...container_style, height: "0px", zIndex: "998 !important" })
                }}
                menuIsOpen={!!this.props.advanced_order_menu.click_target}
                menuShouldScrollIntoView={true}
            />}
            {
                <MoveOrderModal
                    order={order}
                    show_modal={this.state.show_move_modal}
                    line_uuid={this.props.line_uuid}
                    is_unscheduled={this.props.is_unscheduled}
                    order_index={this.props.order_index}
                    onClose={this.onCloseMoveModal}
                    is_schedule={this.state.is_schedule}
                />
            }
            <DraggableModal>
                <Modal show={this.state.show_modal} id="planning-advanced-order-modal-container" onKeyPress={this.onKeyPress}>
                    <Modal.Header>
                        <Modal.Title>
                            {translate("common.edit_params", "Edit parameters")}
                        </Modal.Title>
                        <button type="button" className="close" onClick={this.onClose}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="planning-advanced-order-modal">
                            <div className="input">
                                <span className="label">{translate("Manufacturing.Planning.number_of_capacities", "Capacities[%]")}:</span>
                                <div className="value">
                                    <Authorization.input
                                        id={"capacity_input"}
                                        disabled={order.skip_sim}
                                        permission={PERMISSION_NAMES.OrdersAndShiftsEdit}
                                        className={"form-control " + ((order.override_capacity_factor !== null) ? " color-blue-important" : "")}
                                        type={"text"}
                                        name={"capacity_factor"}
                                        onChange={this.handleChange}
                                        value={niceNumber(100 * capacity_factor, 0)}
                                    />
                                </div>
                            </div>
                            <div className="input">
                                <span className="label">{translate("common.quantity", "Quantity")}:</span>
                                <div className="value">
                                    <Authorization.input
                                        id={"quantity"}
                                        permission={PERMISSION_NAMES.OrdersAndShiftsEdit}
                                        className={"form-control"}
                                        type={"text"}
                                        name={"quantity"}
                                        onChange={this.handleChange}
                                        disabled={quantity_edit_disabled}
                                        value={order.quantity_total}
                                    />
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-primary" onClick={this.onSave}>
                            {translate("common.accept", "Accept")}
                        </button>
                    </Modal.Footer>
                </Modal>
            </DraggableModal>
            {this.renderIgnoreConfirmationModal()}
            {this.renderWarningModal()}
            {this.renderLinkOperationsModal()}
            {this.renderLinkOrdersModal()}
            {this.renderChunkOrderModal()}
        </div>
    }
}

export default connect(
    (state: ReduxState, own_props: Props) => {
        const planning_table = state.gantt_chart_planning_table;
        const filters = state.gantt_chart_filters;
        const gantt_lines_orders = state.gantt_chart_lines_orders;
        const advanced_order_menu = planning_table.advanced_order_menu;
        const report_data = state.gantt_chart_report.report_data;
        const lines_orders = gantt_lines_orders.lines_orders;
        const unscheduled_orders = gantt_lines_orders.unscheduled_orders;

        let is_unscheduled = false;
        let next_shift_time = null;

        let order = null;
        let line_uuid = null;
        let order_uuid = null;
        let order_line = null;
        let order_index = null;

        let is_readonly = false;
        let is_linked_operation = false;
        if (lines_orders) {
            const line = lines_orders.find(l => l.orders.some(o => o.uuid === advanced_order_menu.order_uuid));
            if (line) {
                line_uuid = line.line_uuid;
                order_index = line.orders.findIndex(o => o.uuid === advanced_order_menu.order_uuid);
                order = line.orders[order_index];
                if (order) {
                    order_uuid = order.uuid;
                    order_line = line;
                    is_readonly = LinesOrdersLogic.isOrderReadOnly(line, order);
                    is_linked_operation = order.tags[ORDER_TAGS.linked_operation];
                }
            }
        }

        if (!order && unscheduled_orders) {
            const unscheduled_line = unscheduled_orders.find(l => l.orders.some(o => o.uuid === advanced_order_menu.order_uuid));
            if (unscheduled_line) {
                line_uuid = unscheduled_line.line_uuid;
                order = unscheduled_line.orders.find(o => o.uuid === advanced_order_menu.order_uuid);
                if (order) {
                    order_uuid = order.uuid;
                    order_line = unscheduled_line;
                    is_unscheduled = true;
                    is_linked_operation = order.tags[ORDER_TAGS.linked_operation];
                }
            }
        }

        let report_order = null;
        if (report_data && order_uuid && line_uuid) {
            next_shift_time = report_data.result.next_shift_time;
            const line = report_data.result.orders.find(l => l.production.some(op => op.order_id === order_uuid));
            if (line) {
                report_order = line.production.find(o => o.order_id === order_uuid);
            }
        }

        const line_group_uuid = state.gantt_chart_properties.line_group_uuid;

        return {
            order,
            line_uuid,
            line_group_uuid,
            advanced_order_menu,
            next_shift_time,
            report_order,
            order_line,
            report_data,
            is_unscheduled,
            order_index,
            is_readonly,
            is_filter_locked: filters.is_filter_locked,
            ignore_orders_status: gantt_lines_orders.ignore_orders_status,
            is_linked_operation,

        }
    },
    (dispatch) => ({ reduxDispatch: dispatch })
)(OrderAdvancedMenu);
