// @flow
import React from "react";
import EditOrderModal from "../EditOrderModal";
import { connect } from "react-redux";
import { SHOW_EDIT_COMMENT_MODAL } from "./reducers/planningTable";
import * as t from "../../../lib/backend/manufacturing2.generated.types";
import { UPDATE_LINE_ORDER_COMMENT } from "./reducers/linesOrders";
import { ORDER_TAGS } from "../../../lib/ManufacturingTags.generated";

import type { UpdateLineOrderComment } from "./reducers/linesOrders";
import type { ReduxState } from "./reducers/reducers";
import type { ShowEditCommentModal } from "./reducers/planningTable";

export const onSaveComment = (order_uuid: string, comment: string, reduxDispatch: (args: UpdateLineOrderComment) => void) => {
    reduxDispatch({
        type: UPDATE_LINE_ORDER_COMMENT,
        data: { order_uuid, comment }
    });
}


type Props = {
    reduxDispatch: (args: ShowEditCommentModal | UpdateLineOrderComment) => void,
    show_edit_comment_modal_order_uuid: string
};
type State = {};


class PlanningTableEditModal extends React.Component<Props, State> {

    onCloseEditModal = () => {
        this.props.reduxDispatch({
            type: SHOW_EDIT_COMMENT_MODAL,
            data: null
        });
    }

    onSave = (order: t.IOrderModelBase | null) => {
        if (!order) {
            return;
        }
        const comment = order.tags[ORDER_TAGS.comment];
        onSaveComment(
            this.props.show_edit_comment_modal_order_uuid,
            comment,
            this.props.reduxDispatch
        );
        this.props.reduxDispatch({
            type: SHOW_EDIT_COMMENT_MODAL,
            data: null
        });
    }

    render() {
        return <EditOrderModal
            show_modal={!!this.props.show_edit_comment_modal_order_uuid}
            onCloseModal={this.onCloseEditModal}
            onSave={this.onSave}
            close_on_save={false}
            order_uuid={this.props.show_edit_comment_modal_order_uuid}
        />;
    }
}

export default connect(
    (state: ReduxState, own_props: Props) => {
        const planning_table = state.gantt_chart_planning_table;
        return {
            show_edit_comment_modal_order_uuid: planning_table.show_edit_comment_modal_order_uuid
        }
    },
    (dispatch) => ({reduxDispatch: dispatch})
)(PlanningTableEditModal)
