// @flow

export const TYPE_FILTER_RESCHEDULE_LINE: "reschedule_line" = "reschedule_line";
export const TYPE_FILTER_RESCHEDULE_OPERATION: "reschedule_operation" = "reschedule_operation";
export const TYPE_FILTER_LINE: "line" = "line";
export const TYPE_FILTER_MATERIAL: "material" = "material";
export const TYPE_FILTER_MATERIAL_TAG_VALUES: "material_tag_values" = "material_tag_values";
export const TYPE_FILTER_ORDER: "order" = "order";
export const TYPE_FILTER_ORDER_TAG_VALUES: "order_tag_values" = "order_tag_values";
export const TYPE_FILTER_ORDER_INSIGHT_TYPE: "order_insight_type" = "order_insight_type";
export const TYPE_FILTER_REWORK_ORDERS: "filter_rework_orders" = "filter_rework_orders";

export const TypeFilterValues = {
    [TYPE_FILTER_RESCHEDULE_LINE]: TYPE_FILTER_RESCHEDULE_LINE,
    [TYPE_FILTER_RESCHEDULE_OPERATION]: TYPE_FILTER_RESCHEDULE_OPERATION,
    [TYPE_FILTER_LINE]: TYPE_FILTER_LINE,
    [TYPE_FILTER_MATERIAL]: TYPE_FILTER_MATERIAL,
    [TYPE_FILTER_MATERIAL_TAG_VALUES]: TYPE_FILTER_MATERIAL_TAG_VALUES,
    [TYPE_FILTER_ORDER]: TYPE_FILTER_ORDER,
    [TYPE_FILTER_ORDER_TAG_VALUES]: TYPE_FILTER_ORDER_TAG_VALUES,
    [TYPE_FILTER_ORDER_INSIGHT_TYPE]: TYPE_FILTER_ORDER_INSIGHT_TYPE,
    [TYPE_FILTER_REWORK_ORDERS]: TYPE_FILTER_REWORK_ORDERS

}

export type SelectOptionItem = {
    label: string;
    value: string;
}