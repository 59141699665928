// @flow
// $FlowFixMe
import React, { useState } from 'react';
import ReactRouterPropTypes from "react-router-prop-types";

import {
    dateFromDayShift,
    shiftNumber,
    fromShiftTag,
    niceDate,
    TIME_RANGES
} from "../../lib/Util";

import type {
    ShiftNumberData
} from "../../lib/Models";

import {
    updateTsForInsights
} from "../../lib/BusinessLogic";

import { INSIGHT_TYPES } from "../../lib/ManufacturingTags.generated";
import { INSIGHT_TYPES_CSS, INSIGHT_ACTIONS_ARRAY, INSIGHT_ACTIONS } from "../../lib/Models";
import {
    filterInsightsByStateCriteria,
    getDateClass,
    highlightCurrShift
} from './InsightsCommon.jsx'
import * as t from "../../lib/backend/manufacturing2.generated.types";
import InsightActionsModal from './InsightsActionsModal.jsx';
import ErrorComponent from "../ErrorComponent";
import { translate, getLang } from "../IntlProviderWrapper";

type IUuidSelected = {
    [key: string]: boolean
};

type Props = {
    insights: t.IEventDataEx[],
    selected_line_uuids: IUuidSelected,
    selected_insight_types: IUuidSelected,
    show_only_future: boolean,
    history: ReactRouterPropTypes.history,
    showActions: boolean,
    show_links: boolean,
    reportid: string,
    reverse_order?: boolean,
    is_export?: boolean,
    num_days?: number,
    allowed_actions?: $Keys<typeof INSIGHT_ACTIONS>[],
    onActionSelect?: (action: t.IInsightAction, insight: t.IEventDataEx) => void
}

const newWorkingDay = (shift_obj: ShiftNumberData, ts: number, prev_ts: number, i: number, reverse_order: boolean) => {
    const prev_shift_obj = shiftNumber(new Date(prev_ts));
    const dateClass = getDateClass(ts);

    if (reverse_order) {
        if ((i === 0) ||
            (prev_shift_obj.ts_start - shift_obj.ts_start > TIME_RANGES.DAY) ||
            (prev_shift_obj.shift_day < shift_obj.shift_day)) {

            const shift_start_date = fromShiftTag(shift_obj.shift_tag);
            const shift_start_obj = shiftNumber(shift_start_date);

            return <tr key={i + "_added"} className={`dayTitle ${dateClass}`}>
                <td colSpan={4}>
                    <strong>
                        {translate("common.week", "Week")}: {shift_obj.week}
                    </strong>, {niceDate(shift_start_date)}, {translate("common.weekday" + shift_start_obj.day_number, "Day")}
                </td>
            </tr>;
        } else {
            return null;
        }
    } else {
        if ((i === 0) ||
            (shift_obj.ts_start - prev_shift_obj.ts_start > TIME_RANGES.DAY) ||
            (shift_obj.shift_day < prev_shift_obj.shift_day)) {

            const shift_start_date = fromShiftTag(shift_obj.shift_tag);
            const shift_start_obj = shiftNumber(shift_start_date);

            return <tr key={i + "_added"} className={`dayTitle ${dateClass}`}>
                <td colSpan={4}>
                    <strong>
                        {translate("common.week", "Week")}: {shift_obj.week}
                    </strong>, {niceDate(shift_start_date)}, {translate("common.weekday" + shift_start_obj.day_number, "Day")}
                </td>
            </tr>;
        } else {
            return null;
        }
    }
}

const translatedInsightContent = (insight: t.IEventDataEx) => {
    const user_lang = getLang();
    let title = insight.title;
    let description = insight.description;
    if (insight.extra_data.title_lang && insight.extra_data.title_lang[user_lang]) {
        title = insight.extra_data.title_lang[user_lang];
        description = insight.extra_data.description_lang[user_lang];
    }

    let comment = "";
    if (typeof insight.extra_data.user_comment === "string") {
        comment = insight.extra_data.user_comment.trim();
        if (comment.length > 0) {
            comment = <React.Fragment> - <span className="text-muted">{insight.extra_data.user_comment}</span></React.Fragment>
        }
    }

    return (
        <div className="insight-description">
            <span>
                <b> {title} </b> - {description} {comment}
            </span>
        </div>
    );
}

const Insights = (props: Props) => {
    let {
        insights,
        selected_line_uuids,
        selected_insight_types,
        show_only_future,
        history,
        show_links,
        reportid,
        is_export,
        num_days,
        allowed_actions,
        onActionSelect
    } = props;

    const [only_future_insights: boolean, setOnlyFutureInsights: func] = useState(true);

    if (insights.length === 0) {
        return <tr>
            <td colSpan="4" style={{ "textAlign": "center" }}>
                <ErrorComponent msg={translate("common.no_insights_available", "No insights available")} type="warning" />
            </td>
        </tr>;
    }

    let prev_ts = 0;

    insights = insights.filter(insight => filterInsightsByStateCriteria(insight, selected_line_uuids, selected_insight_types));
    // detect situation when insight's ts belongs to different shift than its content
    updateTsForInsights(insights);

    if (show_only_future && only_future_insights) {
        let firstShiftStart = dateFromDayShift(new Date(), 0).getTime();
        insights = insights.filter(insight => insight.ts >= firstShiftStart);
    }

    if (num_days && num_days > 0) {
        const max_date = new Date();
        max_date.setDate(max_date.getDate() + num_days);
        max_date.setHours(0, 0, 0, 0);
        const max_ts = max_date.getTime();
        insights = insights.filter(insight => insight.ts < max_ts);
    }

    // ok, now sort by "corrected" ts
    insights = insights.sort((a, b) => (props.reverse_order === true ? b.ts - a.ts : a.ts - b.ts));
    let rows = insights.map((insight: t.IEventDataEx, i: number) => {
        if (!insight.title) {
            return null;
        }
        const shift_obj = shiftNumber(new Date(insight.ts));
        const shift_str = `${shift_obj.shift_day + 1}`;
        const added = newWorkingDay(shift_obj, insight.ts, prev_ts, i, props.reverse_order === true);

        const rowClass = [
            getDateClass(insight.ts),
            highlightCurrShift(shift_obj)
        ].join(" ").trim();
        prev_ts = insight.ts;
        let line_title = insight.extra_data.line_title || insight.tags.line_title || insight.tags.line_titles || "";
        let line_uuid = insight.tags.line_uuid || "";
        let url = "/manufacturing/lines/microplan/" + line_uuid;
        // Insight url exceptions
        if (insight.type === INSIGHT_TYPES.quantiles_simple || insight.type === INSIGHT_TYPES.thresholds_simple) {
            url = "/datasources/" + (insight.datasource_uuid || "");
        }
        if (insight.type === INSIGHT_TYPES.no_person || insight.type === INSIGHT_TYPES.no_shift) {
            url = "/manufacturing/people/shifts";
        }

        let shouldShowActions = props.showActions !== false && INSIGHT_ACTIONS_ARRAY.includes(insight.type);

        return [
            added,
            <tr className={rowClass} key={i}>
                <td onClick={() => { if (show_links) { history.push(url) } }}>
                    <strong>
                        {shift_str} {translate("common.shift", "Shift")}
                    </strong>
                </td>
                <td onClick={() => { if (show_links) { history.push(url) } }}>
                    {line_title}
                </td>
                <td
                    title={new Date(insight.ts).toString() + "-" + new Date(insight.ts_to).toString()}
                    colSpan={shouldShowActions ? "1" : "2"}
                    className={INSIGHT_TYPES_CSS[insight.type]}
                    onClick={() => { if (props.show_links) { history.push(url) } }}
                >
                    <div>
                        {translatedInsightContent(insight)}
                    </div>
                </td>
                {
                    !is_export && shouldShowActions &&
                    <InsightActionsModal
                        insight={insight}
                        shift_str={shift_str}
                        line_title={line_title}
                        reportid={reportid}
                        history={history}
                        allowed_actions={allowed_actions}
                        onActionSelect={onActionSelect}
                    />
                }
            </tr>
        ];
    });

    // show more/less row at the beginning of the table
    if (show_only_future) {
        if (only_future_insights) {
            rows.unshift([
                <tr className="toggle-historical" key={rows.length}>
                    <td className="text-center pl-0 pr-0" colSpan={4}>
                        <button className="btn btn-lg btn-outline-primary w-100" onClick={() => setOnlyFutureInsights(false)}>
                            {translate("insights.show_historical", "Show historical insights...")}
                        </button>
                    </td>
                </tr>
            ]);
        } else {
            rows.unshift([
                <tr className="toggle-historical" key={rows.length}>
                    <td className="text-center pl-0 pr-0" colSpan={4}>
                        <button className="btn btn-lg btn-outline-primary w-100" onClick={() => setOnlyFutureInsights(true)}>
                            {translate("insights.hide_historical", "Hide historical insights...")}
                        </button>
                    </td>
                </tr>
            ]);
        }
    }

    return rows;
}

Insights.defaultProps = {
    showActions: true
}

export default Insights;
