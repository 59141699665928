// @flow
import * as React from "react";
import ReactTable from "../../ReactTable";
type Props = {};
type State = {};

const columns = [
    {
        accessor: "el_name",
        Header: "",
        filterInputPlaceholder: "Element name",
        disableFilters: false
    },
    {
        accessor: "focus",
        Header: "Focus element",
        disableFilters: true,
        Cell: ({ value }) => {
            return <button className="btn btn-primary" onClick={() => window.focusElement(value)}>Focus</button>
        }
    },
    {
        accessor: "ts",
        Header: "",
        disableFilters: true,
    }
];

const getHTMLElDescription = (el) => {
    return el.tagName + "." + el.className + "#" + el.id + "|name=" + el.name;
}

type MutationsRow = {
    original_ts: number,
    ts: string,
    el_name: string,
    focus: React.Node
}

export default class MutationsView extends React.Component<Props, State> {

    getNumOfMutations = (): Map<number, any> => {
        if (window.num_mutations) {
            return window.num_mutations;
        }
        return new Map();
    }

    getTableData = () => {
        let data: MutationsRow[] = []
        for (const [ts, mutations] of this.getNumOfMutations().entries()) {
            for (const mutation of mutations) {
                data.push({
                    original_ts: ts,
                    ts: new Date(ts).toLocaleString(),
                    el_name: getHTMLElDescription(mutation.target),
                    focus: mutation.target
                });
            }
        }

        data.sort((a: MutationsRow, b: MutationsRow) => a.original_ts - b.original_ts);

        let diff_sec = 0;
        if (data.length > 1) {
            diff_sec = (data[data.length - 1].original_ts - data[0].original_ts) / 1000;
        }

        localStorage.setItem("mutations-view-last-measurement", diff_sec.toString());

        return {
            data,
            diff_sec
        };
    }

    render() {
        const {
            data,
            diff_sec
         } = this.getTableData();
        return <div className="num-renders-view">
            <div>Mutation time in seconds: {diff_sec}s</div>
            <ReactTable
                data={data}
                columns={columns}
            />
        </div>;
    }
}