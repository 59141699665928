// @flow

// main imports
import React, { Component } from "react";
import ReactRouterPropTypes from "react-router-prop-types";
import { FormattedMessage } from "react-intl";

// models
import type { ICreateGroupReq, IUpdateGroupReq } from "../../lib/backend/batch_operations.generated.types";
import type { ILineGroupData } from "../../lib/backend/manufacturing2.generated.types";
// Note: line_group tag is handled independently
import { BATCHOP_GROUP_TAGS } from "../../lib/ManufacturingTags.generated";
import * as BusinessLogic from "../../lib/BusinessLogic";

// subcomponents
import ErrorComponent from "../ErrorComponent";
import Toggle from "react-toggle";
import Select from "react-select";
import { translate } from "../IntlProviderWrapper";

// backend
import { getBackend } from "../../lib/backend/Backend2";
// import { Columns, getInitialState, mapData, updateTagsConfig } from "../react/ReactTableTags";
import { Columns, getInitialState, mapData, onChangeUpdateTags, rowStyle, updateTagsConfig } from "../react/ReactTableTags";
import ReactTable from "../react/ReactTable";

import { BATCHOP_GROUP_TAGS as MAN_BATCHOP_GROUP_TAGS } from "../../lib/ManufacturingTagsEditor.generated";

import { getLinkForUserDocumentation, UserDocumentationLinksEnum } from "../../lib/Util";

import { getLang } from "../IntlProviderWrapper";

// type definitions
type ITagDisplay = {
    name: string,
    id: string
}

type ISelectOption = {
    value: string,
    label: string
}

type Props = {
    match: ReactRouterPropTypes.match,
    history: ReactRouterPropTypes.history,
    location: ReactRouterPropTypes.location
}

export type State = {
    uuid: string,
    tags: any,
    title: string,
    line_group: ISelectOption,
    line_groups: ILineGroupData[],
    message: string,
    componentTitle: string,
    error: string | null,
    warning: string
}

/**
 * Form to create new line.
 */
export class BatchopGroupCreate extends Component<Props, State> {

    /**
     * Constructor.
     *
     * @param {Props} props Props object.
     * @param {State} state State object.
     */
    constructor(props: Props) {
        super(props);
        const state: State = {
            line_group: { value: "", label: "" },
            line_groups: [],
            uuid: "",
            title: "",
            tags: {},
            message: "",
            componentTitle: translate("Manufacturing.BatchopGroups.add_new_batchop_group", "Add New Batchop Group"),
            error: "",
            warning: ""
        };
        this.state = state;
    }

    /**
     * Loading component data (plants).
     */
    async componentDidMount() {
        const { line_groups } = await getBackend().manufacturing.getLineGroups({});
        this.setState({ line_groups });
    }

    /**
     * Basic form validation.
     */
    validateShallow() {
        return (this.state.title.length > 0);
    }

    /**
     * Handling change of any input component.
     */
    handleInputChange = (event: Event) => {
        if (event.currentTarget instanceof HTMLInputElement || event.currentTarget instanceof HTMLSelectElement) {
            // read new values
            const target = event.currentTarget;
            let value = target.value;
            const name = target.name;

            // hack for toggle element
            if (Object.keys(BATCHOP_GROUP_TAGS).includes(name)) {
                //$FlowFixMe
                value = target.checked;
            }

            // update new datasource placeholder
            this.setState(prev_state => {
                prev_state[name] = value;
                return prev_state;
            });

            // handle line group
            if (name === "line_group") {
                let tags = this.state.tags || {};
                tags.line_group = value;
                this.setState({ tags: tags });
            }

            // handle tags
            if (Object.keys(BATCHOP_GROUP_TAGS).includes(name)) {
                let tags = this.state.tags || {};
                tags[name] = value.toString();
                this.setState({ tags: tags });
            }
        }
    };

    /**
     * Handling multi-select change.
     */
    handleMultiSelectChange = (data: ISelectOption) => {
        this.setState({ line_group: data });
        let tags = this.state.tags || {};
        tags.line_group = data.value;
    };

    /**
     * Handling submission of the form. Basic checks and saves with error handling.
     */
    handleSubmit = async () => {
        this.setState({ error: null });
        try {
            if (this.validateShallow()) {
                let uuid = this.props.match.params.uuid;

                // insert a new object or update
                if (this.state.uuid === "") {
                    let lg: ICreateGroupReq = {
                        title: this.state.title,
                        tags: this.state.tags,
                    }
                    const response = await getBackend().batchOperations.createGroup(lg);
                    uuid = response.uuid;
                } else {
                    // update current object
                    let lg: IUpdateGroupReq = {
                        group_id: this.state.uuid,
                        title: this.state.title,
                        tags: this.state.tags
                    }
                    await getBackend().batchOperations.updateGroup(lg);
                    // force refresh cache to get new batchop group tags
                    await BusinessLogic.forceManufacturingCacheRefresh();
                }

                this.props.history.push(`/digital-twin/resources/batchop_groups/${uuid}`);
            } else {
                this.setState({
                    warning: translate("Manufacturing.LineGroups.cannot_submit_missing_values", "Cannot submit a form with missing values")
                });
            }
        } catch (err) {
            this.setState({ error: err.message });
        }
    }

    /**
     * Renders tag.
     * @param {ITagDisplay} tag
     */
    renderTagField(tag: ITagDisplay) {
        return <div className="form-group" key={tag.id}>
            <label>{tag.name}</label><br />
            <Toggle
                name={tag.id}
                checked={this.state[tag.id]}
                onChange={this.handleInputChange} />
        </div>
    }

    onGuiChangeCell = (name: string) => (value: boolean | Object | string) => {
        this.setState({ tags: onChangeUpdateTags(this.state.tags, name, value) });
    }

    renderReactTable() {
        return (
            <div>
                <a href={getLinkForUserDocumentation(UserDocumentationLinksEnum.batchop_groups, getLang())}
                    target="_blank"
                    style={{ position: 'relative', float: 'right', paddingTop: '30px' }}>
                    <img src={"/img/question_light.svg"} style={{ paddingRight: "3px", paddingBottom: "2px" }} />
                    <FormattedMessage id="Header.menu.user_documentation" defaultMessage="User documentation" />
                    <img src={"/img/arrow_up_right_corner.svg"} style={{ position: "relative", width: "15px", height: "15px", paddingLeft: "3px", paddingBottom: "4px", bottom: "3px" }} />
                </a>

                <ReactTable
                    className="tags-react-table"
                    data={mapData(this.state.tags, updateTagsConfig(MAN_BATCHOP_GROUP_TAGS, {}))}
                    columns={Columns}
                    onChangeCell={this.onGuiChangeCell}
                    rowStyle={rowStyle}
                    initialState={getInitialState()}
                />
            </div>
        )
    }

    /**
     * Rendering JSX for current component.
     */
    render() {
        // tags
        const tags = [
            /* { name: translate("Manufacturing.LineGroups.Tags.gannt", "Show on gannt"), id: LINE_GROUP_TAGS.gannt }, */
        ];

        // handle line group options for select component
        let line_group_options: ISelectOption[] = this.state.line_groups.map(lg => {
            return { value: lg.uuid, label: `${lg.title}` };
        });

        return (
            <div key="ds_new">
                <section key="1">
                    <img src="/img/banner.png" alt="banner" className="img-fluid-header banner_img" />
                </section>
                <section id="profile_edit" className="new_line" key="2">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="white_box">
                                    <div className="tab-content">
                                        <h4>{this.state.componentTitle}</h4>
                                        <ErrorComponent msg={this.state.error} type="error" />
                                        <ErrorComponent msg={this.state.warning} type="warning" />
                                        <div className="form-group">
                                            <label>
                                                <FormattedMessage id="common.title" defaultMessage="Title" />:
                                            </label>
                                            <input name="title" type="text" disabled="" className="form-control"
                                                value={this.state.title}
                                                onChange={this.handleInputChange} />
                                        </div>
                                        <div className="form-group">
                                            <label>
                                                <FormattedMessage id="common.linegroup" defaultMessage="Line group" />:
                                            </label>
                                            {/*
                                            <input name="line_group" type="text" disabled="" className="form-control"
                                                value={this.state.line_group}
                                                onChange={this.handleInputChange} />
                                            */}
                                            <Select name="line_group"
                                                value={this.state.line_group}
                                                options={line_group_options}
                                                onChange={this.handleMultiSelectChange} />
                                        </div>
                                        {this.renderReactTable()}
                                        {tags.map((tag) => this.renderTagField(tag))}
                                        <div className="button-list order-last ml-auto">
                                            <button className="btn btn-outline-primary" onClick={this.handleSubmit}>
                                                <FormattedMessage id="common.save" defaultMessage="Save" />
                                            </button>
                                            <button className="btn btn-outline-secondary" onClick={() => this.props.history.goBack()}>
                                                <FormattedMessage id="common.back" defaultMessage="Back" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="space30"></div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}
