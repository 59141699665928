// @flow
import * as React from "react";

type Props = {
    children: React.Node
};
type State = {};


type LeftMenuProps = { children: React.Node };
type ContentProps = { children: React.Node };

export default class MainContent extends React.Component<Props, State> {

    render() {
        return <div className="grid-main-content">
            {this.props.children}
        </div>
    }
}

type HeaderProps = {
    title: React.Node,
    buttons?: React.Node
}
type ResourcesLayoutProps = {
    children: React.Node;
};
type ResourcesLayoutState = {};
type ResourcesMainProps = {
    children: React.Node;
};
type SearchProps = {
    children: React.Node;
};

export class ResourcesLayout extends React.Component<ResourcesLayoutProps, ResourcesLayoutState> {

    static Header = (props: HeaderProps) => {
        return <div className="header">
            <div className="title">
                {props.title}
            </div>
            <div className="buttons">
                {props.buttons}
            </div>
        </div>
    }

    static List = (props: LeftMenuProps) => {
        return <div {...props} className="resources-layout-list">
            {props.children}
        </div>
    }

    static Content = (props: ContentProps) => {
        return <div {...props} className="resources-layout-content">
            {props.children}
        </div>
    }

    static Main = (props: ResourcesMainProps) => {
        return <div className="resources-layout-main">
            {props.children}
        </div>
    }

    static Search = (props: SearchProps) => {
        return <div {...props} className="search">
            {props.children}
        </div>
    }

    render() {
        return <div className="resources-layout">
            {this.props.children}
        </div>
    }
}
