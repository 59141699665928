// @flow

import * as React from "react";
import { TIME_RANGES } from "../lib/Util";

type TimeProgressType = "success" | "info" | "warning" | "danger";

type Props = {
    duration: number,
    active: boolean,
    striped?: boolean,
    type?: TimeProgressType,
    style?: any
};

type State = {
    progress: number
};

class TimeProgress extends React.Component<Props, State> {
    timer: IntervalID;
    static defaultProps: Props = {
        active: true,
        duration: 0,
        striped: false
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            progress: 0
        };
    }

    componentDidMount() {
        if (this.props.active) {
            this.start();
        }
    }

    componentDidUpdate(prev_props: Props) {
        if (this.props.active !== prev_props.active) {
            if (this.props.active) {
                this.start();
            } else {
                this.stop();
            }
        }
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    start = () => {
        this.timer = setInterval(() => {
            this.setState(prev_state => {
                return {
                    progress: prev_state.progress + 1000
                };
            });
        }, 1000);
    };

    stop = () => {
        clearInterval(this.timer);
    };

    render() {
        const progress = Math.min(
            this.props.active ? 99 : 100,
            Math.round((this.state.progress / this.props.duration) * 100)
        );
        const hours = Math.floor(this.state.progress / TIME_RANGES.HOUR);
        const minutes = Math.floor((this.state.progress % TIME_RANGES.HOUR) / TIME_RANGES.MINUTE);
        const seconds = Math.floor((this.state.progress % TIME_RANGES.MINUTE) / TIME_RANGES.SECOND);
        const type_class = this.props.type ? `bg-${this.props.type}` : "";
        const striped_class = this.props.striped ? "progress-bar-striped" : "";
        return (
            <div className="time-progress d-flex align-items-center" style={{ ...(this.props.style || {}) }}>
                <div className="progress flex-grow-1 h-100">
                    <div
                        className={`progress-bar ${striped_class} ${type_class}`}
                        role="progressbar"
                        aria-valuenow={progress}
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{ width: `${progress}%` }}
                    >
                        {progress}%
                    </div>
                </div>
                <div className="ml-3">
                    {[
                        hours.toString().padStart(2, "0"),
                        minutes.toString().padStart(2, "0"),
                        seconds.toString().padStart(2, "0")
                    ].join(":")}
                </div>
            </div>
        );
    }
}

export default TimeProgress;
