// @flow
import { parseUrlQueryVariables } from "../lib/Util";
import { resolveAka } from "../lib/Aka";

import * as React from "react";
import { Redirect } from "react-router-dom";
import ReactRouterPropTypes from "react-router-prop-types";

type Props = {
    location: ReactRouterPropTypes.location
};

type State = {
    location?: string;
    error?: string;
};

class Aka extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        const params = parseUrlQueryVariables(this.props.location.search);
        resolveAka(
            params,
            (url) => { this.setState({ location: url}); },
            (err) => { this.setState({ error: err }); },
        );
    }

    renderRedirect = () => {
        if (this.state.location) {
            return <Redirect to={ this.state.location } />
        } else if (this.state.error) {
            return <div>{this.state.error}</div>;
        } else {
            return (<div className="loader_box"><div className="loader">Loading...</div></div>);
        }
    }

    render() {
        return <div>{this.renderRedirect()}</div>;
    }
}


export default Aka;
