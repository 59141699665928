// @flow
import * as React from "react";
import { translate } from "../IntlProviderWrapper";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import Toggle from "react-toggle";
import { getBackend as getBackend2 } from "../../lib/backend/Backend2";
import {
    SET_INBOX_VIEW, SET_TICKETS_VIEW, TICKETING_VIEWS,
    SET_WORKFLOW, SET_WORKFLOWS, SET_TICKETS_DATA, SET_INBOX_ITEM_VIEW, SET_INCLUDE_CLOSED
} from "./reducers/ticketing";

import { reducers } from "./reducers/reducers";
import type {
    SetInboxView, LabelValuePair, SetWorkflow, SetWorkflows, SetTicketsView, SetTicketsData, SetIncludeClosed
} from "./reducers/ticketing";
import type { ReduxState } from "./reducers/reducers";
import { combineReducers, createStore } from 'redux';
import { classNames } from "../../lib/Util";

type Props = {
    prev_view: $Keys<typeof TICKETING_VIEWS> | null,
    selected_view: $Keys<typeof TICKETING_VIEWS> | null,
    include_closed: boolean,
    workflows: LabelValuePair[],
    selected_workflow: string,
    reduxDispatch: (args: SetInboxView | SetWorkflow | SetWorkflows | SetTicketsView | SetTicketsData | SetIncludeClosed) => void,
    children: React.Node,
    is_kiosk: boolean
};
type State = {};

class TicketingNavigation extends React.Component<Props, State> {

    store = null;

    constructor(props: any) {
        super(props);
        this.store = createStore(combineReducers(reducers));
    }

    componentDidMount() {
        this.load();
    }
    componentDidUpdate(prev_props: Props) {
        if (prev_props.selected_workflow != this.props.selected_workflow) {
            this.load();
        }
    }
    async load() {
        if (this.props.workflows.length == 0) {
            const { workflows } = await getBackend2().ticketing.getWorkflows({});
            const data = workflows
                .map(x => ({ label: x.title, value: x.name }))
                .sort((a, b) => a.label.localeCompare(b.label));
            this.props.reduxDispatch({ type: SET_WORKFLOWS, data });
            if (data.length > 0) {
                const val: string = data[0].value;
                this.props.reduxDispatch({ type: SET_WORKFLOW, data: val });
            }
        }
    }

    goBack = () => {
        const prev_view = this.props.prev_view;
        if (prev_view) {
            this.props.reduxDispatch({ type: SET_TICKETS_VIEW, data: prev_view });
        }
    }

    goTickets = () => {
        this.props.reduxDispatch({ type: SET_TICKETS_VIEW, data: "tickets" });

    }

    goInbox = () => {
        this.props.reduxDispatch({ type: SET_INBOX_VIEW, data: undefined });
    }


    refreshData = async () => {

        const selected_workflow = this.props.selected_workflow;

        const res = await getBackend2().ticketing.searchTickets({
            include_closed: this.props.include_closed,
            include_errored: true,
            workflow_name: selected_workflow
        });
        const tickets = res.hits.sort((a, b) => a.assigned_on - b.assigned_on);
        if (this.store !== null) {
            this.store.getState().ticketing.tickets = tickets;
        }
        this.props.reduxDispatch({ type: SET_TICKETS_DATA, data: tickets });
    }

    handleChangeDropdown = async (value: string) => {
        await this.props.reduxDispatch({ type: SET_WORKFLOW, data: value });
        await this.refreshData();
    }

    async handleCheckboxChange() {
        const new_val: boolean = !this.props.include_closed;
        await this.props.reduxDispatch({ type: SET_INCLUDE_CLOSED, data: new_val });
        await this.refreshData();
    }

    render() {
        const hide_back_on = [SET_TICKETS_VIEW, SET_INBOX_VIEW, SET_INBOX_ITEM_VIEW];
        const show_back = !hide_back_on.includes(this.props.selected_view);

        if (this.props.is_kiosk) {
            return null;
        }

        return <div className="navigation">
            <div className="navigation-header" style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    {show_back && <button className="btn btn-primary" onClick={this.goBack}>
                        {translate("common.back", "Back")}
                    </button>}
                    {this.props.selected_view == "tickets" && <select className="form-control"
                        value={this.props.selected_workflow}
                        style={{ minWidth: "250px" }}
                        onChange={(option) => { this.handleChangeDropdown(option.target.value) }}
                        options={this.props.workflows} >
                        {this.props.workflows.map(m => {
                            return <option value={m.value} key={"key-option-" + m.value}>{m.label}</option>
                        })}
                    </select>}
                    {this.props.selected_view == "tickets" && <div style={{ whiteSpace: "nowrap" }}>
                        <label style={{ top: "5px", paddingLeft: "8px", paddingRight: "5px", marginBottom: "0", verticalAlign: "middle" }}>
                            <FormattedMessage id="Ticketing.include_closed" defaultMessage="Include closed" />
                        </label>
                        <Toggle
                            style={{ verticalAlign: "middle" }}
                            isSelected={this.props.include_closed}
                            onChange={ () => {this.handleCheckboxChange()}} />
                    </div>}
                    {this.props.selected_view == "tickets" &&
                        <button className="btn btn-primary" onClick={() => this.refreshData()} style={{ marginLeft: "15px", minWidth: "120px" }}>
                            <div style={{ display: "flex" }}>
                                <img src="/img/refresh-white.svg" style={{ width: "20px", height: "20px", paddingTop: "3px" }} />
                                <div style={{ paddingTop: "2px", paddingLeft: "3px", paddingRight: "5px" }}>
                                    <FormattedMessage id="common.refresh" defaultMessage="Refresh" />
                                </div>
                            </div>
                        </button>}
                </div>
                {(this.props.selected_view !== SET_INBOX_ITEM_VIEW && !show_back) &&
                    <div className="router-buttons">
                        <button className={classNames("btn btn-primary", { selected: this.props.selected_view === TICKETING_VIEWS.tickets })} onClick={this.goTickets}>
                            <FormattedMessage id="Ticketing.browse_tickets" defaultMessage="Browse tickets" />
                        </button>
                        <button className={classNames("btn btn-primary ml-1", { selected: this.props.selected_view === TICKETING_VIEWS.inbox })} onClick={this.goInbox}>
                            <FormattedMessage id="Ticketing.inbox" defaultMessage="Inbox" />
                        </button>
                    </div>}
            </div>
        </div >
    }
}

export default connect(
    (state: ReduxState) => {
        const selected_view = state.ticketing.selected_view;
        const prev_view = state.ticketing.prev_view;
        const workflows = state.ticketing.workflows;
        const selected_workflow = state.ticketing.selected_workflow;
        const include_closed = state.ticketing.include_closed;

        return { include_closed, selected_view, prev_view, workflows, selected_workflow }
    },
    (dispatch) => ({ reduxDispatch: dispatch })
)(TicketingNavigation);
