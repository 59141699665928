// @flow

// main imports
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

// models
import type { IBatchOperationLocation, IBatchOperationGroup } from "../../lib/backend/batch_operations.generated.types";
import type { ILineGroupData } from "../../lib/backend/manufacturing2.generated.types";
import * as Auth from "../../lib/Auth";
import { BATCHOP_GROUP_TAGS } from "../../lib/ManufacturingTags.generated";

// backend
import { getBackend } from "../../lib/backend/Backend2";

// import subcomponents
// import BatchopGroupsModal from "./BatchopGroupsModal";
import { translate } from "../IntlProviderWrapper";

type Props = {
    selected: IBatchOperationGroup,
    batchop_locations: IBatchOperationLocation[],
    line_groups: ILineGroupData[],
    editLink: string,
    handleToDelete: any,
    loadBatchopGroups: any
}

type State = {
    show_modal: boolean
}

/**
 * Lines details component.
 */
class BatchopGroupsDetails extends Component<Props, State> {

    /////////////////////////////////////////
    // SUBCOMPONENT METHODS (MODAL)

    /**
     * Adding/deleting lines to line group.
     *
     * @param {string[]} lines Array of lines.
     */
    async handleModalSaveClick(line_uuids: string[]) {
        await getBackend().manufacturing.setLinesForLinegroup({
            line_uuids, linegroup_uuid: this.props.selected.uuid
        });
        this.props.loadBatchopGroups(); // reload page
        this.setState({ show_modal: false });
    }

    /**
     * Method triggered when Add/Update/Edit button is clicked.
     * It opens lines modal and selects new line if `line_uuid` is provided.
     *
     * @param {string} [plant_uuid] Plant UUID.
     */
    handleModalShowClick() {
        this.setState({ show_modal: true });
    }

    /**
     * Method triggered when modal close button (or modal background overlay) is clicked.
     * It closes the modal.
     */
    handleModalCloseClick() {
        this.setState({ show_modal: false });
    }


    /////////////////////////////////////////
    // MAIN COMPONENT METHODS

    constructor(props: Props, state: State) {
        super(props, state);

        this.state = {
            selected: {
                uuid: "",
                name: "",
                org_uuid: "",
                external_id: "",
                line_uuids: [],
                plant_uuids: [],
                tags: {},
                weight: 0
            },
            line_group_lines: {},
            // plant_lines: lines,
            show_modal: false
        }
    }

    /**
     * Handle delete button clicks.
     */
    handleDelete = () => {
        if (this.props.selected) {
            this.props.handleToDelete(this.props.selected.uuid);
        }
    }

    /**
     * Render component.
     */
    render() {
        if (this.props.selected === null) {
            return null;
        }
        const can_edit = Auth.isInRole(Auth.ROLE_ADMIN) || Auth.isInRole(Auth.ROLE_POWER_USER)|| Auth.isInRole(Auth.ROLE_DEMO_USER);
        let editLink = `${this.props.editLink}${this.props.selected.uuid}`;

        // extract line group
        let line_group = "/";

        // filter tags
        let renderTags = {};
        const tagsToRemove = [ 'line_group' ];
        if ((this.props.selected.tags !== undefined) && (this.props.selected.tags !== null)) {
            Object.keys(this.props.selected.tags).forEach((el) => {
                if (!tagsToRemove.includes(el)) {
                    renderTags[el] = this.props.selected.tags[el];
                }
            });

            // extract line group
            if (this.props.selected.tags[BATCHOP_GROUP_TAGS.line_group] !== undefined) {
                const line_group_uuid = this.props.selected.tags[BATCHOP_GROUP_TAGS.line_group];
                // extract line group title
                const lg_titles = this.props.line_groups.filter((x) => x.uuid === line_group_uuid);
                let line_group_title = "not found in the list";
                if (lg_titles.length > 0) {
                    line_group_title = lg_titles[0].title;
                    line_group = <Link to={`/digital-twin/resources/line_groups/${line_group_uuid}`}>{line_group_title}</Link>
                } else {
                    line_group = translate("Manufacturing.BatchopGroups.line_group_not_found") + " (" + line_group_uuid + ")";
                }
            }

        };

        // generate tags
        let tags = Object.keys(renderTags).map((el, i) => {
            const val = renderTags[el];
            let style = "badge";
            if (val === "true") {
                style += " badge-success";
            } else if (val === "false") {
                style += " badge-danger";
            } else {
                style += " badge-secondary";
            }
            return (<span className={style} style={{ marginRight: 3 }} key={i}>{el + ": " + val}</span>);
        });

        if (tags.length === 0) tags = "/";

        // extract locations
        const matched_locations = this.props.batchop_locations;

        const updateButton = null;
        /*
        // TODO - modal to edit membership of a group within the group (now only possible in locations tab)
        const updateButton = (<button className="btn btn-outline-primary ml-auto" onClick={() => this.handleModalShowClick()}>
            <FormattedMessage id="common.edit" defaultMessage="Edit" />
        </button>);
        */

        return (
            <div className="tab-content">
                <div className="tab-pane active tab-pane-text">
                    <div className="d-flex">
                        {can_edit && <div className="button-list order-last ml-auto">
                            <Link className="btn btn-outline-primary" to={editLink}>
                                <FormattedMessage id="common.edit" defaultMessage="Edit" />
                            </Link>
                            <button className="btn btn-outline-secondary" onClick={this.handleDelete}>
                                <FormattedMessage id="common.delete" defaultMessage="Delete" />
                            </button>
                        </div>}

                        <div className="order-first">
                            <h5 className="pane-title">
                                <em><FormattedMessage id="common.batchop_group" defaultMessage="Batch operations group" /></em> {this.props.selected.name}</h5>
                            <dl className="property-list">
                                <div>
                                    <dt><FormattedMessage id="common.linegroup" defaultMessage="Line group" /></dt>
                                    <dd><div>{line_group}</div></dd>
                                </div>
                                <div>
                                    <dt><FormattedMessage id="common.tags" defaultMessage="Tags" /></dt>
                                    <dd>{tags}</dd>
                                </div>
                                <div>
                                    <dt className="light"><FormattedMessage id="common.uuid" defaultMessage="UUID" /></dt>
                                    <dd className="light"><div>{this.props.selected.uuid}</div></dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                        <h2>
                            <FormattedMessage id="Manufacturing.BatchopGroups.included_locations" defaultMessage="Included locations" />
                        </h2>
                        {/*
                        // TODO - modal (see above)
                        <BatchopGroupsModal
                            show={this.state.show_modal}
                            line_group={this.props.selected}
                            lines={this.state.plant_lines}
                            selected_plant={selected_plant}
                            plants={plants}
                            handleModalPlantChange={(selectedValue) => this.handleModalPlantChange(selectedValue)}
                            handleModalSaveClick={(lineCompetenceLevels) => this.handleModalSaveClick(lineCompetenceLevels)}
                            handleModalCloseClick={() => this.handleModalCloseClick()}
                        />
                        */}
                    </div>
                    <div className="card-deck">
                        <div className="card">
                            <div className="card-header text-uppercase bg-transparent">
                                <FormattedMessage id="common.batchop_locations" defaultMessage="Locations" />
                                <div className="float-right">
                                    {updateButton}
                                </div>
                            </div>
                            <div className="list-group list-group-flush">
                                {matched_locations.map((location) => {
                                    return (
                                        <Link className="list-group-item list-group-item-action" to={`/digital-twin/resources/batchop_locations/${location.uuid}`} key={location.uuid}>{location.name}</Link>
                                    )
                                })}
                            </div>
                            <div className="space15"></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default BatchopGroupsDetails;
