// @flow
import React from "react";
import * as Auth from "../lib/Auth";
import { Link } from "react-router-dom";
import Toggle from "react-toggle";
import {default as ReactSelect} from "react-select";

type Props = {
    permission: string | boolean,
    className?: string,
    disabled?: boolean
};

export function Authorization(OriginalComponent: any) {
    return class extends React.Component<Props, any> {  
        canEdit = (permission: string | boolean) => {
            if (typeof permission === "string") {
                return Auth.hasPermission(permission)
            }

            return permission;
        }

        render() {
            const can_edit = this.canEdit(this.props.permission);
            const name = OriginalComponent.displayName;

            if (name === "Link") {
                const className = this.props.className
                if (can_edit) {
                    return <OriginalComponent {...this.props} className={className && className.replace(" disabled", "")}/>
                } else if (className && !className.includes(" disabled")) {
                    return <OriginalComponent {...this.props} className={className && className + " disabled"} />
                }
            }

            if (["Button", "Select", "Input", "ToggleWrapper"].includes(name)) {
                if (can_edit) {
                    const disabled = this.props.disabled;
                    return <OriginalComponent {...this.props} disabled={disabled === undefined ? false : disabled} />
                } else {
                    return <OriginalComponent {...this.props} disabled={true}/>
                }
            }

            if (name === "AuthReactSelect") {
                return <OriginalComponent {...this.props} isDisabled={this.props.disabled || !can_edit} />
            }
            // return original component with additional props
            return <OriginalComponent {...this.props} />
      }
   }
}

const Button = (props) => {
  return <button {...props}>{props.children}</button>;
}

const Select = (props) => {
    return <select {...props}>{props.children}</select>;
}

const Input = (props) => {
    return <input {...props}>{props.children}</input>;
}

const ToggleWrapper = (props) => {
    return <Toggle {...props}>{props.children}</Toggle>;
}

const AuthReactSelect = (props) => {
    return <ReactSelect {...props}>{props.children}</ReactSelect>;
}

Link.displayName = "Link";
Authorization.Link = Authorization(Link);
Button.displayName = "Button";
Authorization.button = Authorization(Button);
Select.displayName = "Select";
Authorization.select = Authorization(Select);
ToggleWrapper.displayName = "ToggleWrapper";
Authorization.Toggle = Authorization(ToggleWrapper);
Input.displayName = "Input";
Authorization.input = Authorization(Input);
AuthReactSelect.displayName = "AuthReactSelect";
Authorization.Select = Authorization(AuthReactSelect);

export default Authorization;
