// @flow
import * as React from "react";
import * as BusinessLogic from "../../lib/BusinessLogic";
import * as Auth from "../../lib/Auth";
import { getAnonymization } from "../../lib/Anonymization";
import { translate } from "../IntlProviderWrapper";
import { USER_TAGS } from "../../lib/CommonTags.generated";
import { LeftMenuDropdown, LeftMenuIconOption } from "./LeftMenuOption";
import type { LeftMenuIconOptionProps } from "./LeftMenuOption";

type Props = {
    history: any
};
type State = {};


export default class MainLeftMenu extends React.Component<Props, State> {

    getEnvClassName = () => {
        const env_flag = BusinessLogic.getSysFlag("env");
        if (!getAnonymization()) {
            switch (env_flag) {
                case "dev": return "env-dev";
                case "beta": return "env-beta";
                case "alpha": return "env-alpha";
                default: break;
            }
        }
        return "";
    }

    getDigitalTwinLeftMenuOptions = () => {
        const is_kiosk = Auth.getUserTag(USER_TAGS.kiosk) === "true";
        const is_admin = !is_kiosk && Auth.isInRole(Auth.ROLE_ADMIN);
        const is_power_user = Auth.isInRole(Auth.ROLE_POWER_USER);
        const is_demo_user = Auth.isInRole(Auth.ROLE_DEMO_USER);
        const show_manufacturing = BusinessLogic.displayFeature("manufacturing");
        // is the user in electricity role
        const is_electricity = !is_kiosk &&
            (Auth.isInRole(Auth.ROLE_MAN_ELECTRICITY_USER) ||
                Auth.isInRole(Auth.ROLE_MAN_ELECTRICITY_ADMIN));
        // check if kolektor org, since we only show batchop-groups and batchop-locations
        const { code } = Auth.getOrgData();

        const menu_items = [];
        if (show_manufacturing) {
            const options: LeftMenuIconOptionProps[] = [];
            options.push({
                Label: translate("Header.menu.plants", "Plants"),
                to: "/digital-twin/resources/plants"
            });
            options.push({
                Label: translate("Header.menu.linegroups", "Line-groups"),
                to: "/digital-twin/resources/line_groups"
            });
            options.push({
                Label: translate("Header.menu.lines", "Lines"),
                to: "/digital-twin/resources/lines"
            });
            if (code === "kolektor") {
                options.push({
                    Label: translate("Header.menu.batchop_groups", "Batchop-groups"),
                    to: "/digital-twin/resources/batchop_groups/"
                });
                options.push({
                    Label: translate("Header.menu.batchop_locations", "Batchop-locations"),
                    to: "/digital-twin/resources/batchop_locations"
                });
            }
            options.push({
                Label: translate("Header.menu.stock_locations", "Stock-locations"),
                to: "/digital-twin/resources/stock_locations"
            });
            options.push({
                Label: translate("Header.menu.materials", "Materials"),
                to: "/digital-twin/resources/materials"
            });
            options.push({
                Label: translate("Header.menu.processes", "Processes"),
                to: "/digital-twin/resources/processes"
            });
            options.push({
                Label: translate("Header.menu.people", "People"),
                to: "/digital-twin/resources/people"
            });

            if (is_admin) {
                options.push({
                    Label: translate("Header.menu.organisation", "Organisation"),
                    to: "/digital-twin/resources/organisation"
                });
            }

            menu_items.push(
                <LeftMenuDropdown
                    title={translate("Header.menu.resources", "Master data")}
                    to="/digital-twin/resources"
                    options={options}
                    history={this.props.history}
                    key={"resources"}
                />
            );
        }

        if (is_admin && show_manufacturing) {
            const options_models: LeftMenuIconOptionProps[] = [];
            if ((is_admin || is_power_user || is_demo_user) && show_manufacturing) {
                options_models.push({
                    Label: translate("Header.menu.manufacturing_eval", "Evaluation"),
                    to: "/digital-twin/models/eval"
                });
            }
            options_models.push({
                // Icon: <i class="fas fa-chart-pie"></i>,
                Label: translate("Header.menu.models", "Models"),
                to: "/digital-twin/models/search"
            });
            menu_items.push(
                <LeftMenuDropdown
                    title={translate("Header.menu.models", "Models")}
                    to="/digital-twin/models"
                    options={options_models}
                    history={this.props.history}
                    url_prefix="/digital-twin/models"
                    default_url="/digital-twin/models/search"
                    fa_icon="fa-chart-pie"
                    key={"models_parent"}
                />
            );
        }

        if (is_electricity) {
            menu_items.push(
                <LeftMenuIconOption
                    Icon={<i class="fas fa-lightbulb"></i>}
                    Label={translate("Header.menu.electricity", "Electricity")}
                    to={"/digital-twin/electricity"}
                />
            );
        }

        return <React.Fragment>
            {menu_items}
        </React.Fragment>
    }

    getAdminLeftMenuOptions = () => {
        const is_kiosk = Auth.getUserTag(USER_TAGS.kiosk) === "true";
        // is the user in admin role
        const is_admin = !is_kiosk && Auth.isInRole(Auth.ROLE_ADMIN);
        const show_manufacturing = BusinessLogic.displayFeature("manufacturing");
        return <React.Fragment>
            <LeftMenuIconOption
                Icon={<i class="fas fa-home"></i>}
                Label={translate("Header.menu.statistics", "Statistics")}
                to={"/admin"}
            />
            <LeftMenuIconOption
                Icon={<i className="fas fa-users" />}
                Label={translate("common.users", "Users")}
                to={"/admin/users"}
            />
            {is_admin && <LeftMenuIconOption
                Icon={<i class="fas fa-terminal"></i>}
                Label={translate("Header.menu.shell", "Shell")}
                to={"/admin/admin_shell"}
            />}
            <LeftMenuIconOption
                Icon={<i class="fab fa-blogger"></i>}
                Label={translate("Header.menu.custom_files", "Custom files")}
                to={"/admin/blob_storage_items"}
            />
            {is_admin && <LeftMenuIconOption
                Icon={<i class="fas fa-server"></i>}
                Label={translate("Header.menu.background_jobs", "Background jobs")}
                to={"/admin/background_jobs"}
            />}
            {is_admin && <LeftMenuIconOption
                Icon={<i class="fas fa-clipboard-list"></i>}
                Label={translate("Header.menu.audit", "Audit")}
                to={"/admin/audit"}
            />}
            {is_admin && <LeftMenuIconOption
                Icon={<i class="fas fa-tachometer-alt"></i>}
                Label={translate("Header.menu.dashboards", "Dashboards")}
                to={"/admin/dashboards"}
            />}
            {is_admin && <LeftMenuIconOption
                Icon={<i class="fas fa-calendar-week"></i>}
                Label={translate("Header.menu.events", "Events")}
                to={"/admin/events"}
            />}
            {is_admin && <LeftMenuIconOption
                Icon={<i class="fas fa-server"></i>}
                Label={translate("Header.menu.datasources", "Datasources")}
                to={"/admin/datasources"}
            />}
            {is_admin && show_manufacturing && <LeftMenuIconOption
                Icon={<i class="fas fa-cog"></i>}
                Label={translate("Header.menu.manufacturing_settings", "Manufacturing settings")}
                to={"/admin/settings"}
            />}
            {is_admin && <LeftMenuIconOption
                Icon={<i class="fas fa-th-large"></i>}
                Label={translate("Header.menu.apps", "Apps")}
                to={"/admin/apps"}
            />}
        </React.Fragment>
    }

    getLeftMenuOptions() {
        if (window.location.pathname.includes("/digital-twin")) {
            return this.getDigitalTwinLeftMenuOptions();
        }
        return this.getAdminLeftMenuOptions();
    }

    render() {
        let className = "grid-left-menu " + this.getEnvClassName();
        return <div className={className}>
            {this.getLeftMenuOptions()}
        </div>
    }
}
