// @flow

// main imports
import React from "react";
import ReactRouterPropTypes from "react-router-prop-types";


// import subcomponents
import BatchOperations from "./BatchOperations";

type Props = {
    match: ReactRouterPropTypes.match
}

function BatchOperationsStandalone(props: Props) {
    const group_uuid = props.match.params.group_uuid || "";
    return <BatchOperations
        readOnly={false} showHistoryLink={true} showHeader={true}
        batchOpGroupUuid={group_uuid} />;
}

export default BatchOperationsStandalone;
