// @flow
import * as t from "../../../../lib/backend/manufacturing2.generated.types";
import * as t2 from "../../../../lib/SimulationReportModels";

import type { ITags } from "../../../../lib/Models";
import type { IExtraLines } from "../../../../lib/backend/manufacturing2.generated.types";
import type { LineOrders, IgnoreOrdersMap } from "./linesOrders";
import type { PayloadAction } from "../../../redux/types";
import { deepClone } from "../../../../lib/Util";

export const RESET_GANTT: "reset_gantt" = "reset_gantt";
export const RESET_DRAGGED_ORDER: "reset_dragged_order" = "reset_dragged_order";
export const SET_ALL_AS_UNSCHEDULED: "set_all_as_unscheduled" = "set_all_as_unscheduled";
export const SET_LOCK_FILTER: "set_lock_filter" = "set_lock_filter";
export const SET_SELECTED_STOCK_REQUIREMENT: "selected_stock_requirement" = "selected_stock_requirement";
export const SET_RESCHEDULE_ORDER_UUID: "reschedule_order_uuid" = "reschedule_order_uuid";
export const SET_RESCHEDULE_LINE_UUID: "reschedule_order_line_uuid" = "reschedule_order_line_uuid";
export const SET_RESCHEDULE_ORDER_BUNDLE: "set_reschedule_order_bundle" = "set_reschedule_order_bundle";
export const MOVE_ORDER_GANTT_ACTION_NAME: "move_order_gantt" = "move_order_gantt";
export const RETRY_LINES_ORDERS: "retry_lines_orders" = "retry_lines_orders";

export type ValidOrder = {
    uuid: string,
    external_id: string,
    material_external_id: string,
    earliest_start: number,
    is_translucent: boolean
}

export type ValidLineOrders = {
    line_title: string;
    line_uuid: string;
    line_external_id: string,
    tags: ITags,
    is_extra: boolean,
    orders: ValidOrder[]
}

export type SetShowValidLinesState = {
    valid_lines: ValidLineOrders[] | null,
    // line objects here do not include orders
    valid_lines_map: Map<string, ValidLineOrders> | null,
    valid_orders_map: Map<string, ValidOrder> | null
};

type RescheduleBundle = {
    show_valid_lines: SetShowValidLinesState | null,
    order_uuid: string,
    count_non_empty_lines: number | null,
    num_unscheduled_lines: number | null,
    is_filter_locked: boolean
}
export type SetAllAsUnscheduledAction = PayloadAction<typeof SET_ALL_AS_UNSCHEDULED, typeof undefined>;
export type ResetGanttAction = PayloadAction<typeof RESET_GANTT, typeof undefined>;
export type ResetDraggedOrder = PayloadAction<typeof RESET_DRAGGED_ORDER, typeof undefined>;
export type SetLockFilter = PayloadAction<typeof SET_LOCK_FILTER, boolean>;
export type SetSelectedStockRequirement = PayloadAction<typeof SET_SELECTED_STOCK_REQUIREMENT, string>;
export type SetRescheduleLineAction = PayloadAction<typeof SET_RESCHEDULE_LINE_UUID, string | null>;
export type SetRescheduleOrderAction = PayloadAction<typeof SET_RESCHEDULE_ORDER_UUID, string | null>;
export type SetRescheduleOrderBundleAction = PayloadAction<typeof SET_RESCHEDULE_ORDER_BUNDLE, RescheduleBundle>;
export type MoveOrderGanttAction = PayloadAction<typeof MOVE_ORDER_GANTT_ACTION_NAME, MoveOrderGanttActionData>;
export type RetryLinesOrdersAction = PayloadAction<typeof RETRY_LINES_ORDERS, typeof undefined>;

export const SIMULATION_TYPES = {
    full: "full"
}

export type QuickSimArgs = {
    plant_uuid: string,
    line_group_uuid: string,
    title?: string,
    lines_orders: LineOrders[],
    unscheduled_orders: LineOrders[],
    week: number,
    year: number,
    project_parallel_orders: boolean,
    simulation_type?: string,
    skip_stock?: boolean,
    ignore_orders_status?: IgnoreOrdersMap | null
}

export type OrderUuidLineIndexEntry = {
    order_uuid: string,
    line_index: number
}

export type MoveOrderGanttActionData = {
    original_line_uuid: string,
    new_line_uuid: string,
    new_order_index: number,
    order_uuid: string,
    earliest_start?: number,
    is_unscheduled_order: boolean,
    production_version?: string,
    process_uuid?: string,
    process_num?: string,
    override_earliest_start?: number,
    line_index?: number
}

export type ReportParameters = {
    type: string,
    prev_report_uuid: string | null,
    extra_lines?: IExtraLines[],
    config: QuickSimArgs,
    recommend_plan_uuid: ?string | null,
    selected_insight_types: string[]
}

export type IOrderProducedModelEx = t.IOrderProducedModel & {
    has_production?: boolean,
    unscheduled_highlighted?: boolean,
    unscheduled_selected?: boolean,
    failed_scheduling?: boolean,
    estimated_duration?: number
}

export type UpdateLineOrderActionData = {
    order_uuid: string,
    line_uuid: string,
    order: IOrderProducedModelEx
}

export const getConflictResolutionLineIndex = (line_uuid: string, line_index: number) => {
    const lines = document.querySelectorAll(`g[id='gantt-chart-line-group'][line-uuid='${line_uuid}']`);
    let i = 0;
    for (const line of lines) {
        const gantt_line_index = parseInt(line.getAttribute("line-index"));
        if (gantt_line_index === line_index) {
            return i;
        }
        i++;
    }
    return 0;
}

export const cloneReportParameters = (parameters: ReportParameters): ReportParameters => {
    const new_params = deepClone(parameters);
    if (parameters.config.ignore_orders_status != null) {
        new_params.config.ignore_orders_status = new Map(parameters.config.ignore_orders_status);
    }

    return new_params;
}

export const LeftMenuTab = {
    orders: "orders",
    insights: "insights",
    stock_requirements: "stock_requirements"
}

export const UPDATE_REPORT_ACTION_NAME: "update_report" = "update_report";
export const UPDATE_REPORT_ACTION_NAME2: "update_report2" = "update_report2";

// action types
export type UpdateReport2 = {
    quick_report: t2.IReportModelEx,
    extra_lines: IExtraLines[] | null
}

export type UpdateReport2Action = PayloadAction<typeof UPDATE_REPORT_ACTION_NAME2, UpdateReport2>;
export type UpdateReportAction = PayloadAction<typeof UPDATE_REPORT_ACTION_NAME, t2.IReportModelEx>;