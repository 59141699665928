// @flow

// main imports
import React, { Component } from "react";
import ReactRouterPropTypes from "react-router-prop-types";
import { FormattedMessage } from "react-intl";
import { Modal } from "react-bootstrap";

// subcomponents
import ErrorComponent from "../ErrorComponent";
import { translate } from "../IntlProviderWrapper";

// backend
import { getBackend } from "../../lib/backend/Backend2";
import * as t from "../../lib/backend/manufacturing2.generated.types";
import * as mt from "../../lib/ManufacturingTags.generated";

export type Props = {
    match: ReactRouterPropTypes.match,
    history: ReactRouterPropTypes.history,
    location: ReactRouterPropTypes.location
}

export type State = {
    // new material params
    uuid: string,
    external_id: string,
    title: string,
    child_materials: t.IMaterialModel[],
    // page display params
    error: string,
    warning: string,
    // add new material modal view
    add_material_show: boolean,
    add_material_external_id: string,
    add_material: t.IMaterialModel | null,
    add_material_error: string
}

/**
 * Form to create new line.
 */
export class MaterialParallelCreate extends Component<Props, State> {

    is_edit = false;

    /**
     * Constructor.
     *
     * @param {Props} props Props object.
     * @param {State} state State object.
     */
    constructor(props: Props) {
        super(props);
        const state: State = {
            uuid: "",
            external_id: "",
            title: "",
            child_materials: [],
            error: "",
            warning: "",
            add_material_show: false,
            add_material_external_id: "",
            add_material: null,
            add_material_error: ""
        };
        this.state = state;
    }

    async componentDidMount() {
        // nothing to do here
    }

    validateShallow() {
        const all_child_materials_count = this.state.child_materials.length;
        const unique_child_materials_count = (new Set(this.state.child_materials.map(cm => cm.uuid))).size;
        return (
            this.state.title.length > 0 &&
            this.state.external_id.length > 0 &&
            all_child_materials_count > 1 &&
            all_child_materials_count === unique_child_materials_count
        );
    }

    handleInputChange = (event: Event) => {
        if (event.currentTarget instanceof HTMLInputElement || event.currentTarget instanceof HTMLSelectElement) {
            // read new values
            const target = event.currentTarget;
            let value = target.value;
            const name = target.name;

            this.setState(prev_state => {
                prev_state[name] = value;
                return prev_state;
            });
        }
    };

    handleSubmit = async () => {
        try {
            if (this.validateShallow()) {
                const child_material_uuids = this.state.child_materials.map(x => x.uuid);
                await getBackend().manufacturing.parallelMaterialCreate({
                    parallel_materials: [{
                        child_material_uuids,
                        external_id: this.state.external_id,
                        title: this.state.title
                    }]
                });
                this.props.history.goBack();
            } else {
                this.setState({
                    warning: translate(
                        "Manufacturing.Materials.cannot_submit_missing_values",
                        "Cannot submit a form with missing values."
                    )
                });
            }
        } catch (err) {
            this.setState({ error: err.message });
        }
    }

    handleCancel() {
        this.props.history.goBack();
    }

    addMaterialShow = () => {
        this.setState({
            add_material_show: true,
            add_material_external_id: "",
            add_material: null,
            add_material_error: ""
        });
    }

    addMaterialHide = () => {
        this.setState({ add_material_show: false });
    }

    addMaterialSearch = async () => {
        const { data } = await getBackend().manufacturing.searchMaterials({
            external_id: this.state.add_material_external_id
        });
        if (data.length === 0) {
            this.setState({
                add_material_error: `${translate("Manufacturing.Materials.unknown_material", "Unknown material")}: '${this.state.add_material_external_id}'`
            });
        } else if (data.length === 1) {
            this.setState(data[0].tags[mt.MATERIAL_TAGS.parallel] === "true" ? {
                add_material_error: `${translate("tag_groups.MATERIAL_TAGS.values.title.parallel", "Parallel material")}: '${this.state.add_material_external_id}'`
            } : {
                add_material: data[0],
                add_material_error: ""
            });
        } else {
            this.setState({ add_material_error: `More then one material with external id '${this.state.add_material_external_id}'` });
        }
    }

    addMaterialSave = () => {
        this.setState(prev_state => {
            if (this.state.add_material !== null) {
                prev_state.child_materials.push(this.state.add_material);
            }
            prev_state.add_material_show = false;
            return prev_state;
        });
    }

    delChildMaterial = (material_uuid: string) => {
        this.setState(prev_state => {
            prev_state.child_materials = prev_state.child_materials.filter(cm => (cm.uuid !== material_uuid));
            return prev_state
        });
    }

    renderAddMaterial() {
        return (
            <Modal show={this.state.add_material_show} onHide={this.addMaterialHide} dialogClassName="modal-dialog-scrollable">
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id="Manufacturing.Materials.add_material" defaultMessage="Add material" />
                    </Modal.Title>
                    <button type="button" className="close" onClick={this.addMaterialHide}>
                        <span aria-hidden="true">×</span>
                        <span className="sr-only"><FormattedMessage id="common.close" defaultMessage="Close" /></span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    {this.state.add_material_error && <ErrorComponent className="mt-0" msg={this.state.add_material_error} type="warning" />}
                    <div className="form-group">
                        <label>
                            <FormattedMessage id="common.external_id" defaultMessage="External ID" />:
                        </label>
                        <input name="add_material_external_id" type="text" disabled="" className="form-control"
                            value={this.state.add_material_external_id} onChange={this.handleInputChange} />
                    </div>
                    <div className="button-list order-last ml-auto">
                        <button className="btn" onClick={this.addMaterialSearch}>
                            <FormattedMessage id="common.search" defaultMessage="Search" />
                        </button>
                    </div>
                    {this.state.add_material !== null && (
                    <table className="table">
                        <tbody>
                            <tr>
                                <td>{this.state.add_material.external_id}</td>
                                <td>{this.state.add_material.title}</td>
                            </tr>
                        </tbody>
                    </table>
                    )}
                </Modal.Body>
                <Modal.Footer className="text-center">
                    <button className="btn btn-outline-primary"
                        disabled={(this.state.add_material === null)} onClick={this.addMaterialSave}>
                        <FormattedMessage id="common.add" defaultMessage="Add" />
                    </button>
                    <button className="btn btn-outline-secondary"
                        disabled={false} onClick={this.addMaterialHide}>
                        <FormattedMessage id="common.close" defaultMessage="Close" />
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }

    render() {
        return (
            <div key="ds_new">
                <section key="1">
                    <img src="/img/banner.png" alt="banner" className="img-fluid-header banner_img" />
                </section>
                <section id="profile_edit" className="new_line" key="2">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="white_box">
                                    <div className="tab-content">
                                        {this.renderAddMaterial()}
                                        <h4>{
                                            this.is_edit ?
                                                translate("Manufacturing.Materials.edit_parallel_material", "Edit multipart material") :
                                                translate("Manufacturing.Materials.add_new_parallel_material", "Add new multipart material")
                                        }</h4>
                                        <ErrorComponent msg={this.state.error} type="error" />
                                        <ErrorComponent msg={this.state.warning} type="warning" />
                                        <div className="form-group">
                                            <label>
                                                <FormattedMessage id="common.title" defaultMessage="Title" />:
                                            </label>
                                            <input name="title" type="text" disabled="" className="form-control"
                                                value={this.state.title}
                                                onChange={this.handleInputChange} />
                                        </div>
                                        <div className="form-group">
                                            <label>
                                                <FormattedMessage id="common.external_id" defaultMessage="External ID" />:
                                            </label>
                                            <input name="external_id" type="text" disabled="" className="form-control"
                                                value={this.state.external_id}
                                                onChange={this.handleInputChange} />
                                        </div>
                                        <hr/>

                                        <h4><FormattedMessage id="common.materials" defaultMessage="Materials" /></h4>
                                        <table className="table">
                                            <tbody>
                                                {this.state.child_materials.map((cm, i) => (
                                                    <tr key={i}>
                                                        <td>{cm.external_id}</td>
                                                        <td>{cm.title}</td>
                                                        <td><button className="btn" onClick={() => { this.delChildMaterial(cm.uuid); }}>-</button></td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>

                                        <button className="btn" onClick={this.addMaterialShow}>+</button>
                                        <hr/>

                                        <div className="button-list order-last ml-auto">
                                            <button className="btn btn-outline-primary" onClick={() => this.handleSubmit()}>
                                                <FormattedMessage id="common.save" defaultMessage="Save" />
                                            </button>
                                            <button className="btn btn-outline-secondary" onClick={() => this.handleCancel()}>
                                                <FormattedMessage id="common.back" defaultMessage="Back" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="space30"></div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default MaterialParallelCreate;
