// @flow

import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import Select from "react-select";

import { translate } from "../IntlProviderWrapper";
import { getBackend } from "../../lib/backend/Backend2";
import * as t from "../../lib/backend/manufacturing2.generated.types";
import * as Bl from "../../lib/BusinessLogic";

import { IGNORE_COMPETENCE_FLAG } from "./ShiftAssignmentDetails";
import { LINE_TAGS } from "../../lib/ManufacturingTags.generated";

export type SingleCompetence = {
    title: string,
    uuid: string,
    group_uuid: string,
    level: number,
    line_group_title: string,
    weight: number
}
export type SingleCompetenceGroup = {
    title: string,
    uuid: string
}

export type Props = {
    show: boolean,
    lines: t.ILineData[],
    person_uuid: string,
    plant_uuids: string[],
    handleModalCloseClick: (boolean) => void
};

type State = {
    competence_titles: string[],
    competences: SingleCompetence[],
    competences_original: SingleCompetence[],
    groups: SingleCompetenceGroup[],
    selected_group: SingleCompetenceGroup,
    selected_competences: SingleCompetence[],
    error: string,
    is_saving: boolean
};

/** Utility function to determine group that should be selected by default */
function determineDefaultGroup(competences: SingleCompetence[]): string | null {
    // try to be smart when selecting default group - use one from existing competences
    let existing_group_uuids = competences
        .filter(x => x.level > 0)
        .map(x => x.group_uuid);
    if (existing_group_uuids.length > 0) {
        return existing_group_uuids[0];
    }
    return null;
}

/**
 * Display editable list of competences.
 */
class PeopleCompetenceModal extends Component<Props, State> {

    constructor(props: Props) {
        super(props);

        let groups: SingleCompetenceGroup[] = Bl.getPlantsForUser()
            .map((x): SingleCompetenceGroup => ({ title: x.title, uuid: x.uuid }));

        this.state = {
            competences: [],
            competences_original: [],
            competence_titles: [
                translate("Manufacturing.People.competence_level.0", "None"),
                translate("Manufacturing.People.competence_level.1", "Learning"),
                translate("Manufacturing.People.competence_level.2", "Capable"),
                translate("Manufacturing.People.competence_level.3", "Independent")
            ],
            groups,
            selected_competences: [],
            selected_group: groups[0],
            error: "",
            is_saving: false
        };
    }

    async componentDidMount() {
        if (this.props.show) {
            await this.reloadData();


            this.setSelectedGroup(this.state.selected_group.uuid);
        }
    }

    async componentDidUpdate(prevProps: Props) {
        if (prevProps.person_uuid !== this.props.person_uuid || prevProps.show !== this.props.show) {
            await this.reloadData();

            this.setSelectedGroup(this.state.selected_group.uuid);
        }
    }

    async reloadData() {
        const person_uuid = this.props.person_uuid;

        const line_to_line_group_map = new Map<string, t.ILineGroupData>();
        const user_line_groups = Bl.getLineGroupsForUser();
        for (const line_group of user_line_groups) {
            for (const lu of line_group.line_uuids) {
                line_to_line_group_map.set(lu, line_group);
            }
        }

        // set initial competences to all lines to 0
        const competences = this.props.lines
            .filter(line => !IGNORE_COMPETENCE_FLAG.includes(line.tags[LINE_TAGS.type]))
            .filter(line => line_to_line_group_map.has(line.uuid))
            .map((line): SingleCompetence => {
                const line_group = line_to_line_group_map.get(line.uuid);
                // $FlowFixMe
                const line_group_title = line_group.title;
                // $FlowFixMe
                const line_group_weight = line_group.weight;
                return {
                    group_uuid: line.plant_uuid,
                    level: -1, // -1 indicates None but also not-set-in-backend
                    title: line.title,
                    uuid: line.uuid,
                    line_group_title,
                    weight: line_group_weight * 1000000 + line.weight
                };
            })
            .sort((l1, l2) => l1.weight - l2.weight);

        // now get data from server to add into initial levels
        const data = await getBackend().manufacturing.getCompetence({ person_uuid });
        for (const competence of data.competences) {
            for (const c of competences) {
                if (c.uuid === competence.line_uuid) {
                    c.level = competence.level;
                    continue;
                }
            }
        };
        const competences_original = JSON.parse(JSON.stringify(competences));

        competences.forEach(x => { x.level = Math.max(x.level, 0); });
        let selected_group_uuid = determineDefaultGroup(competences);
        let selected_group = this.state.selected_group;
        if (selected_group_uuid !== null) {
            selected_group = this.state.groups.filter(x => x.uuid === selected_group_uuid)[0];
            selected_group_uuid = selected_group.uuid;
        }
        const selected_competences = competences.filter(x => x.group_uuid === selected_group_uuid);
        this.setState({
            competences, competences_original, selected_competences, selected_group,
            is_saving: false, error: ""
        });
    }

    handleCompetenceClick(item_uuid: string, level: number) {
        this.setState(prevState => {
            prevState.competences.forEach(item => {
                if (item.uuid === item_uuid) {
                    item.level = level;
                }
            });
            prevState.selected_competences.forEach(item => {
                if (item.uuid === item_uuid) {
                    item.level = level;
                }
            });
            return prevState;
        });
    }

    setSelectedGroup(group_uuid: string) {
        const hits = this.state.groups.filter(x => x.uuid === group_uuid);
        let selected_group = this.state.groups[0];
        if (hits.length > 0) {
            selected_group = hits[0];
        }
        this.setState({
            selected_group,
            selected_competences: this.state.competences
                .filter(x => x.group_uuid === selected_group.uuid)
        });
    }

    async save() {
        this.setState({ is_saving: true });
        try {
            const api = getBackend().manufacturing;
            for (const new_comp of this.state.competences) {
                const old = this.state.competences_original.filter(x => x.uuid === new_comp.uuid);
                if (old.length > 0) {
                    // already stored in backed
                    if (new_comp.level === 0 && old[0].level < 0) {
                        // do nothing - not set in backend and still is None
                    } else if (new_comp.level === 0) {
                        // remove from backend
                        await api.deleteCompetence({
                            line_uuid: new_comp.uuid,
                            person_uuid: this.props.person_uuid
                        });
                    } else if (new_comp.level !== old[0].level) {
                        // update in backend
                        await api.upsertCompetence({
                            level: new_comp.level,
                            line_uuid: new_comp.uuid,
                            person_uuid: this.props.person_uuid
                        });
                    }
                } else {
                    if (new_comp.level > 0) {
                        // add to backend
                        await api.upsertCompetence({
                            level: new_comp.level,
                            line_uuid: new_comp.uuid,
                            person_uuid: this.props.person_uuid
                        });
                    }
                }
            }
            this.setState({ is_saving: false });
            this.props.handleModalCloseClick(true);
        } catch (e) {
            this.setState({ error: e.message, is_saving: false });
        }
    }

    render() {
        const { handleModalCloseClick } = this.props;
        const select_options = this.state.groups.map(x => ({ value: x.uuid, label: x.title }));
        const select_current = select_options.filter(x => x.value === this.state.selected_group.uuid)[0];
        return (
            <Modal
                show={this.props.show}
                bsSize="large"
                dialogClassName="modal-dialog-scrollable"
                onHide={handleModalCloseClick}
            >
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id="Manufacturing.People.edit_competences" defaultMessage="Edit competences" />
                    </Modal.Title>
                    <button type="button" className="close" onClick={handleModalCloseClick}>
                        <span aria-hidden="true">×</span>
                        <span className="sr-only"><FormattedMessage id="common.close" defaultMessage="Close" /></span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <Select
                        classNamePrefix="react-select"
                        options={select_options}
                        value={select_current}
                        onChange={(event) => { this.setSelectedGroup(event.value) }}
                        placeholder="Select group"
                        menuPortalTarget={document.body}
                        menuPosition="fixed"
                        styles={{
                            menuPortal: base => ({
                                ...base,
                                zIndex: 9999
                            })
                        }}
                    />
                    <ul className="list-group list-group-flush list-group-edit-competences">
                        {this.state.selected_competences.map((p, i) => (
                            <li className="list-group-item d-flex align-items-center flex-wrap border-dashed" key={p.uuid}>
                                <span>{p.title} ({p.line_group_title})</span>
                                <span className="badge-list ml-auto">
                                    {this.state.competence_titles.map((c, j) => {
                                        const level = j;
                                        const isLevelSelected = (p.level === level);
                                        return (
                                            <button className={"btn badge badge-competence" + (isLevelSelected ? ` badge-competence-${level}` : "")}
                                                onClick={() => { this.handleCompetenceClick(p.uuid, level) }} key={level}>{c}
                                            </button>
                                        );
                                    })}
                                </span>
                            </li>
                        ))}
                    </ul>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary" disabled={this.state.is_saving}
                        onClick={() => { this.save(); }}>
                        <FormattedMessage id="common.save" defaultMessage="Save" />
                    </button>
                    <button className="btn btn-outline-secondary"
                        onClick={() => { handleModalCloseClick(false); }} >
                        <FormattedMessage id="common.cancel" defaultMessage="Cancel" />
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default PeopleCompetenceModal;

