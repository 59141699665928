// @flow
import * as React from "react";
import Select, { components } from "react-select";
import type { Cell, Row, FilterProps } from "react-table";
import moment from "moment";
import * as XLSX from "xlsx";

import { translate, getLang } from "../IntlProviderWrapper";
import { getBackend } from "../../lib/backend/Backend2";
import { niceDate, niceNumber } from "../../lib/Util";
import * as Auth from "../../lib/Auth";
import Authorization from "../Authorization";
import Loader from "../Loader";
import VirtualTable, { type VirtualTableColumn } from "../react/VirtualTable";
import Tooltip from "../Tooltip";
import BlobStorageItemsModal from "../BlobStorageItemsModal";
import * as t from "../../lib/backend/manufacturing2.generated.types";

type Settings = {
    groups?: string[],
    horizon?: string,
    no_coverage_time?: string,
    plant?: string,
    requirement_types?: string[],
    time_granularity?: string,
    vendors?: string[]
};

type MaterialCell = {
    title: string,
    external_id: string,
    stock: number,
    groups: string[]
};

type DataCell = {
    requirements: number,
    deliveries: number,
    stock: number,
    data: number[]
};

type TableRow = {
    material: MaterialCell,
    [time: string]: DataCell
};

type SelectOption = {
    label: React.Node,
    value: string
};

type SelectAction = {
    action: string
};

type Props = {};

type State = {
    selected_plant: SelectOption | null,
    selected_requirement_types: SelectOption[] | null,
    selected_vendors: SelectOption[] | null,
    selected_groups: SelectOption[] | null,
    selected_no_coverage_time: SelectOption | null,
    selected_horizon: SelectOption | null,
    selected_time_granularity: string,
    stock_forecast: t.ILongTermStockForecast | null,
    material_filter: string,
    vendor_filter: string,
    type_filter: string,
    rows: TableRow[] | null,
    columns: VirtualTableColumn<TableRow>[] | null,
    show_data_modal: boolean
};

const MAX_HORIZON = 60;

const SETTINGS_KEY = "long-term-stock-forecast-settings";

const TIME_GRANULARITY = {
    week: "week",
    month: "month"
};

const SelectCheckboxOption = props => (
    <components.Option {...props}>
        <div className="custom-control custom-checkbox">
            <input className="custom-control-input" type="checkbox" checked={props.isSelected} onChange={() => null} />
            <span className="custom-control-label">{props.label}</span>
        </div>
    </components.Option>
);

const SelectMultiValue = ({ children, ...props }) => {
    if (props.index > 0 || props.selectProps.inputValue !== "") {
        return null;
    }

    const option_values = props.selectProps.options.map(option => option.value);
    const selected_values = props.selectProps.value.map(option => option.value);
    let count = 0;
    selected_values.forEach(value => {
        if (option_values.includes(value)) {
            count += 1;
        }
    });

    return (
        <components.SingleValue {...props}>
            {props.selectProps.placeholder} ({count})
        </components.SingleValue>
    );
};

const SelectPlaceholder = ({ children, ...rest }) => (
    <components.Placeholder {...rest}>
        {children}
        {rest.selectProps.isLoading ? "" : " (0)"}
    </components.Placeholder>
);

const SelectMenu = ({ children, ...rest }) => {
    const base_unit = rest.theme.spacing.baseUnit;
    const is_checked = rest.selectProps.value.length === rest.selectProps.options.length;
    return (
        <components.Menu {...rest}>
            {!rest.isLoading && (
                <div
                    style={{
                        paddingTop: base_unit * 2,
                        paddingRight: base_unit * 3,
                        paddingBottom: base_unit * 2,
                        paddingLeft: base_unit * 3,
                        borderBottomWidth: 1,
                        borderBottomStyle: "solid",
                        borderBottomColor: rest.theme.colors.neutral10
                    }}
                >
                    <div
                        className="custom-control custom-checkbox"
                        onClick={() => {
                            if (is_checked) {
                                rest.setValue([]);
                            } else {
                                rest.setValue(rest.options);
                            }
                        }}
                    >
                        <input
                            className="custom-control-input"
                            type="checkbox"
                            checked={is_checked}
                            onChange={() => null}
                        />
                        <label className="custom-control-label">{translate("common.select_all", "Select all")}</label>
                    </div>
                </div>
            )}
            {children}
        </components.Menu>
    );
};

function filterMaterial(rows: Row<TableRow>[], column_ids: string[], filter_value: string): Row<TableRow>[] {
    return rows.filter(row => {
        const { title, external_id } = row.values.material;
        const value = filter_value.toLowerCase();
        return title.toLowerCase().indexOf(value) >= 0 || external_id.toLowerCase().indexOf(value) >= 0;
    });
}

function getSelectOption(value: string, options: SelectOption[]): SelectOption | null {
    return options.find(option => option.value === value) || null;
}

function getSelectOptions(values: string[], options: SelectOption[]): SelectOption[] | null {
    const selected_options = [];
    for (const value of values) {
        const option = getSelectOption(value, options);
        if (option) {
            selected_options.push(option);
        }
    }

    return selected_options;
}

function getSettings(): Settings {
    let settings = {};
    try {
        const settings_value = localStorage.getItem(SETTINGS_KEY);
        if (settings_value != null) {
            settings = JSON.parse(settings_value);
        }
    } catch (error) {
        // Parsing error
    }

    return settings;
}

function isOptionLabelGreater(a: SelectOption, b: SelectOption) {
    if (typeof a.label !== "string" || typeof b.label !== "string") {
        return 0;
    }

    if (a.label > b.label) {
        return 1;
    } else if (a.label < b.label) {
        return -1;
    }

    return 0;
}

function isOptionValueGreater(a: SelectOption, b: SelectOption) {
    if (a.value > b.value) {
        return 1;
    } else if (a.value < b.value) {
        return -1;
    }

    return 0;
}

function getStockRequirementTypeTitle(type: t.IStockRequirementType): string {
    const lang = getLang();
    const title =
        type.titles.find(title => title.lang_iso === lang) ||
        type.titles.find(title => title.lang_iso === "en") ||
        type.titles[0];

    return title !== undefined ? title.title : "";
}

function getStockRequirementTypeShortTitle(type: t.IStockRequirementType): string {
    const lang = getLang();
    const short_title =
        type.short_titles.find(title => title.lang_iso === lang) ||
        type.short_titles.find(title => title.lang_iso === "en") ||
        type.short_titles[0];

    return short_title !== undefined ? short_title.title : "";
}

function getSelectTheme(theme) {
    return {
        ...theme,
        spacing: {
            ...theme.spacing,
            baseUnit: 2,
            controlHeight: 30
        }
    };
}

async function getFiles(): Promise<string[]> {
    const { items } = await getBackend().manufacturing.getLongTermStockForecastFiles({});
    return items.map(item => item.key);
}

async function getFile(key: string): Promise<string> {
    const res = await getBackend().manufacturing.getLongTermStockForecastFile({
        key
    });

    return res.content;
}

async function setFile(key: string, content: string) {
    await getBackend().manufacturing.setLongTermStockForecastFile({
        key,
        content
    });
}

async function deleteFile(key: string) {
    await getBackend().manufacturing.deleteLongTermStockForecastFile({
        key
    });
}

function formatFileKey(key: string): string {
    return `stock_forecast_materials_${key}.csv`;
}

function cleanFileKey(item: string) {
    const match = item.match(/^stock_forecast_materials_(.+)\.csv$/i);
    if (match) {
        return match[1];
    }

    return item.replace(/\.csv$/i, "");
}

function validateFileContent(content: string): number[] {
    const invalid_lines = [];
    const lines = content.split("\n");
    for (let i = 0; i < lines.length; i++) {
        const line = lines[i].trim();
        if (line === "") {
            continue;
        }

        const columns = line.split(/\s*,\s*/);
        if (columns.length !== 3 || columns.some(column => !/^[a-z0-9]+$/i.test(column))) {
            invalid_lines.push(i);
        }
    }

    return invalid_lines;
}

class LongTermStockForecast extends React.Component<Props, State> {
    material_filter_timeout: TimeoutID;
    table_ref: { current: HTMLDivElement | null };

    constructor(props: Props) {
        super(props);
        this.state = {
            selected_plant: null,
            selected_requirement_types: null,
            selected_vendors: null,
            selected_groups: null,
            selected_no_coverage_time: null,
            selected_horizon: null,
            selected_time_granularity: TIME_GRANULARITY.week,
            stock_forecast: null,
            material_filter: "",
            vendor_filter: "",
            type_filter: "",
            rows: null,
            columns: null,
            show_data_modal: false
        };
        this.table_ref = React.createRef();
    }

    async componentDidMount() {
        try {
            const { stock_forecast } = await getBackend().manufacturing.getLongTermStockForecast({});
            this.setState({ stock_forecast }, () => {
                this.loadSettings(this.prepareData);
            });
        } catch (error) {
            console.log(error);
        }
    }

    storeSettings = () => {
        if (!localStorage) {
            return;
        }

        const {
            selected_time_granularity,
            selected_plant,
            selected_horizon,
            selected_no_coverage_time,
            selected_requirement_types,
            selected_vendors,
            selected_groups
        } = this.state;
        const settings = {
            time_granularity: selected_time_granularity,
            plant: selected_plant && selected_plant.value,
            horizon: selected_horizon && selected_horizon.value,
            no_coverage_time: selected_no_coverage_time && selected_no_coverage_time.value,
            requirement_types: selected_requirement_types && selected_requirement_types.map(type => type.value),
            vendors: selected_vendors && selected_vendors.map(vendor => vendor.value),
            groups: selected_groups && selected_groups.map(group => group.value)
        };
        localStorage.setItem(SETTINGS_KEY, JSON.stringify(settings));
    };

    loadSettings = (callback?: Function) => {
        const settings = getSettings();

        const selected_time_granularity = settings.time_granularity || TIME_GRANULARITY.week;

        const plant_options = this.getPlantOptions();
        const selected_plant =
            (settings.plant && getSelectOption(settings.plant, plant_options)) || plant_options[0] || null;

        const horizon_options = this.getHorizonOptions();
        const selected_horizon =
            (settings.horizon && getSelectOption(settings.horizon, horizon_options)) ||
            horizon_options[11] ||
            horizon_options[0] ||
            null;

        const no_coverage_time_options = this.getNoCoverageTimeOptions(selected_time_granularity);
        const selected_no_coverage_time =
            (settings.no_coverage_time && getSelectOption(settings.no_coverage_time, no_coverage_time_options)) || null;

        const requirement_type_options = this.getRequirementTypeOptions();
        const selected_requirement_types =
            (Array.isArray(settings.requirement_types) &&
                getSelectOptions(settings.requirement_types, requirement_type_options)) ||
            requirement_type_options;

        const vendor_options = this.getVendorOptions();
        const selected_vendors =
            (Array.isArray(settings.vendors) && getSelectOptions(settings.vendors, vendor_options)) || vendor_options;

        const group_options = this.getGroupOptions();
        const selected_groups =
            (Array.isArray(settings.groups) && getSelectOptions(settings.groups, group_options)) || group_options;

        this.setState(
            prev_state => ({
                ...prev_state,
                selected_time_granularity,
                selected_plant,
                selected_no_coverage_time,
                selected_horizon,
                selected_requirement_types,
                selected_vendors,
                selected_groups
            }),
            callback
        );
    };

    mapColumns = (column_names: string[]): VirtualTableColumn<TableRow>[] => {
        const columns: VirtualTableColumn<TableRow>[] = [
            {
                Header: "",
                accessor: "material",
                Cell: this.renderMaterial,
                Filter: this.renderMaterialFilter,
                filter: filterMaterial,
                fix: true,
                width: 542
            }
        ];

        for (const time_label of column_names) {
            columns.push({
                Header: this.renderHeader(time_label),
                accessor: time_label,
                disableFilters: true,
                Cell: this.renderData
            });
        }

        return columns;
    };

    setEmptyTable = () => {
        this.setState({
            columns: this.mapColumns([]),
            rows: []
        });
    };

    prepareData = () => {
        const {
            selected_plant,
            selected_groups,
            selected_requirement_types,
            selected_vendors,
            selected_horizon,
            stock_forecast
        } = this.state;
        const plant =
            stock_forecast &&
            stock_forecast.plants &&
            selected_plant &&
            stock_forecast.plants.find(p => p.uuid === selected_plant.value);

        if (
            !selected_plant ||
            !selected_groups ||
            !selected_requirement_types ||
            !selected_vendors ||
            !selected_horizon ||
            !stock_forecast ||
            !plant
        ) {
            this.setEmptyTable();
            return;
        }

        const { materials, groups, stock, data, vendors, stock_requirement_types } = stock_forecast;
        const material_uuid_map: Map<number, string> = new Map();
        materials.forEach(material => {
            material_uuid_map.set(material.id, material.uuid);
        });

        const material_groups_map: Map<string, Set<string>> = new Map();
        groups.forEach(material_plant_groups => {
            if (material_plant_groups.plant_uuid !== plant.uuid) {
                return;
            }

            const material_groups = new Set([
                ...(material_groups_map.get(material_plant_groups.material_uuid) || new Set()),
                ...material_plant_groups.groups
            ]);
            material_groups_map.set(material_plant_groups.material_uuid, material_groups);
        });

        const selected_groups_map: Map<string, boolean> = new Map();
        selected_groups.forEach(g => {
            selected_groups_map.set(g.value, true);
        });
        const filtered_data = data.filter(d => {
            if (d.pid !== plant.id) {
                return false;
            }

            const material_uuid = material_uuid_map.get(d.mid);
            if (material_uuid === undefined) {
                return false;
            }

            const material_groups = material_groups_map.get(material_uuid);
            if (material_groups === undefined) {
                return false;
            }

            return [...material_groups].some(g => !!selected_groups_map.get(g));
        });

        let rows = [];
        const time_labels = this.getTimeLabels();
        const material_row_index_map: Map<number, number> = new Map();

        // Initialize data cells
        const material_ids = [...new Set(filtered_data.map(d => d.mid))];
        let row_index = 0;
        for (const material of materials) {
            if (!material_ids.includes(material.id)) {
                continue;
            }

            material_row_index_map.set(material.id, row_index);

            const material_uuid = material_uuid_map.get(material.id);
            const material_groups = material_uuid && material_groups_map.get(material_uuid);
            const row = {
                material: {
                    title: material.title,
                    external_id: material.external_id,
                    stock: 0,
                    groups: material_groups ? [...material_groups] : []
                }
            };

            for (const time_label of time_labels) {
                row[time_label] = {
                    requirements: 0,
                    deliveries: 0,
                    stock: 0,
                    data: []
                };
            }

            rows.push(row);
            row_index += 1;
        }

        const vendor_uuids_map: Map<number, string> = new Map();
        vendors.forEach(vendor => {
            vendor_uuids_map.set(vendor.id, vendor.uuid);
        });

        const selected_vendors_map: Map<string, boolean> = new Map();
        selected_vendors.forEach(vendor => {
            selected_vendors_map.set(vendor.value, true);
        });

        const requirement_type_codes_map: Map<number, string> = new Map();
        stock_requirement_types.forEach(type => {
            requirement_type_codes_map.set(type.id, type.external_code);
        });

        const selected_requirement_types_map: Map<string, boolean> = new Map();
        selected_requirement_types.forEach(type => {
            selected_requirement_types_map.set(type.value, true);
        });

        const plant_id = plant.id;
        // Initialize stock by materials
        for (const stock_item of stock) {
            const material_index = material_row_index_map.get(stock_item.material_id);

            if (material_index === undefined || stock_item.plant_id !== plant_id) {
                continue;
            }

            rows[material_index].material.stock += stock_item.quantity;
        }

        // Initialize deliveries and requirements by materials and time
        filtered_data.forEach((data_item, i) => {
            if (data_item[this.state.selected_time_granularity] === undefined) {
                data_item[this.state.selected_time_granularity] = this.getTimeLabel(data_item.d);
            }

            let time_label = data_item[this.state.selected_time_granularity];
            if (time_label < time_labels[0]) {
                time_label = time_labels[0];
            }

            const material_index = material_row_index_map.get(data_item.mid);
            const vendor_uuid = vendor_uuids_map.get(data_item.vid);
            const requirement_type_code = requirement_type_codes_map.get(data_item.cid);
            if (
                material_index === undefined ||
                rows[material_index][time_label] === undefined ||
                data_item.pid !== plant_id ||
                vendor_uuid === undefined ||
                (!selected_vendors_map.get(vendor_uuid) && data_item.q > 0) ||
                requirement_type_code === undefined ||
                !selected_requirement_types_map.get(requirement_type_code)
            ) {
                return;
            }

            if (data_item.q > 0) {
                rows[material_index][time_label].deliveries += data_item.q;
            } else {
                rows[material_index][time_label].requirements += Math.abs(data_item.q);
            }

            rows[material_index][time_label].data.push(i);
        });

        // Calculate stock by materials and time
        for (const row of rows) {
            let { stock } = row.material;
            for (const time_label of time_labels) {
                const data = row[time_label];
                if (!data) {
                    continue;
                }

                stock += data.deliveries;
                stock -= data.requirements;
                data.stock = stock;
            }
        }

        if (this.state.selected_no_coverage_time !== null) {
            const max_time_label = this.state.selected_no_coverage_time.value;
            const selected_time_labels = [];
            for (const time_label of time_labels) {
                if (time_label > max_time_label) {
                    break;
                }

                selected_time_labels.push(time_label);
            }

            rows = rows.filter(row => {
                for (const time_label of selected_time_labels) {
                    const data = row[time_label];
                    if (data && data.stock < 0) {
                        return true;
                    }
                }

                return false;
            });
        }

        const columns = this.mapColumns(
            this.state.selected_horizon ? this.getTimeLabels(Number(this.state.selected_horizon.value)) : time_labels
        );

        this.setState({
            columns,
            rows
        });
    };

    getTimeLabel = (epoch: number): string => {
        return moment(epoch).format(
            this.state.selected_time_granularity === TIME_GRANULARITY.month ? "YYYY-MM" : "GGGG-WW"
        );
    };

    getTimeLabels = (horizon?: number, time_granularity?: string): string[] => {
        const granularity = time_granularity || this.state.selected_time_granularity;
        const labels = [];
        const current_moment = moment();
        const max_moment = moment()
            .add(Number(horizon || MAX_HORIZON) - 1, "months")
            .endOf("month");

        if (granularity === TIME_GRANULARITY.month) {
            while (current_moment.isBefore(max_moment)) {
                labels.push(current_moment.format("YYYY-MM"));
                current_moment.add(1, "months");
            }
        } else if (granularity === TIME_GRANULARITY.week) {
            const max_year = max_moment.isoWeekYear();
            const max_week = max_moment.isoWeek();
            const current_year = current_moment.isoWeekYear();
            const current_week = current_moment.isoWeek();
            let year = current_year;
            while (year <= max_year) {
                const max_year_week = year === max_year ? max_week : moment([year]).isoWeeksInYear();
                let week = year === current_year ? current_week : 1;
                while (week <= max_year_week) {
                    labels.push(`${year}-${week.toString().padStart(2, "0")}`);
                    week += 1;
                }

                year += 1;
            }
        }

        return labels;
    };

    getPlantOptions = (): SelectOption[] => {
        const { stock_forecast } = this.state;
        if (stock_forecast === null) {
            return [];
        }

        return stock_forecast.plants.map((plant: t.ILongTermStockForecastPlant) => ({
            label: `${plant.title} (${plant.external_id})`,
            value: plant.uuid
        }));
    };

    getRequirementTypeOptions = (filter?: boolean): SelectOption[] => {
        const { stock_forecast, selected_plant } = this.state;
        if (stock_forecast === null) {
            return [];
        }

        const allowed_codes = [];
        if (filter) {
            const plant = selected_plant && stock_forecast.plants.find(p => p.uuid === selected_plant.value);
            if (plant) {
                const type_codes_map: Map<number, string> = new Map();
                stock_forecast.stock_requirement_types.forEach(type => {
                    type_codes_map.set(type.id, type.external_code);
                });

                for (const data_item of stock_forecast.data) {
                    if (data_item.pid !== plant.id) {
                        continue;
                    }

                    const code = type_codes_map.get(data_item.cid);
                    if (code !== undefined && !allowed_codes.includes(code)) {
                        allowed_codes.push(code);
                    }
                }
            }
        }

        return stock_forecast.stock_requirement_types
            .map((type: t.IStockRequirementType) => {
                const short_title = getStockRequirementTypeShortTitle(type).trim();
                const external_code = (type.external_code || "").trim();
                let label = getStockRequirementTypeTitle(type).trim();

                if (short_title !== "" && external_code !== "") {
                    label += ` (${short_title} - ${external_code})`;
                } else if (short_title !== "") {
                    label += ` (${short_title})`;
                } else if (external_code !== "") {
                    label += ` (${external_code})`;
                }

                return {
                    label: label.trim() || "-",
                    value: type.external_code
                };
            })
            .filter(type => !filter || allowed_codes.includes(type.value))
            .sort(isOptionValueGreater);
    };

    getVendorOptions = (filter?: boolean): SelectOption[] => {
        const { stock_forecast, selected_plant } = this.state;
        if (stock_forecast === null) {
            return [];
        }

        const allowed_uuids = [];
        if (filter) {
            const plant = selected_plant && stock_forecast.plants.find(p => p.uuid === selected_plant.value);
            if (plant) {
                const vendor_uuids_map: Map<number, string> = new Map();
                stock_forecast.vendors.forEach(vendor => {
                    vendor_uuids_map.set(vendor.id, vendor.uuid);
                });

                for (const data_item of stock_forecast.data) {
                    if (data_item.pid !== plant.id) {
                        continue;
                    }

                    const uuid = vendor_uuids_map.get(data_item.vid);
                    if (uuid !== undefined && !allowed_uuids.includes(uuid)) {
                        allowed_uuids.push(uuid);
                    }
                }
            }
        }

        return stock_forecast.vendors
            .map((vendor: t.ILongTermStockForecastVendor) => {
                let label = vendor.title;
                if (vendor.external_id !== "") {
                    const vendor_external_id = vendor.external_id.replace(/^0+/, "");
                    label = label !== "" ? `${label} (${vendor_external_id})` : `(${vendor_external_id})`;
                }

                return {
                    label: label || "-",
                    value: vendor.uuid
                };
            })
            .filter(vendor => !filter || allowed_uuids.includes(vendor.value))
            .sort(isOptionLabelGreater);
    };

    getGroupOptions = (): SelectOption[] => {
        const { stock_forecast } = this.state;
        if (stock_forecast === null) {
            return [];
        }

        let groups = [];
        stock_forecast.groups.forEach(g => {
            groups = [...groups, ...g.groups];
        });

        return [...new Set(groups)]
            .map(group => ({
                label: group,
                value: group
            }))
            .sort(isOptionValueGreater);
    };

    getHorizonOptions = (): SelectOption[] => {
        const max_month = 12;
        return [...Array(max_month).keys(), 24, 36, 48, 60].map(key => {
            const months = key < max_month ? key + 1 : key;
            return {
                label: `${months} ${translate("Calendar.month", "Month").toLocaleLowerCase()}`,
                value: `${months}`
            };
        });
    };

    getNoCoverageTimeOptions = (time_granularity?: string): SelectOption[] => {
        const granularity = time_granularity || this.state.selected_time_granularity;
        const time_labels = this.getTimeLabels(0, time_granularity);
        return time_labels.map(value => {
            let [year, period] = value.split("-");
            if (granularity === TIME_GRANULARITY.month) {
                const month = Number(period);
                if (month >= 1 && month <= 12) {
                    period = translate(`common.month${month - 1}`).slice(0, 3);
                }
            } else {
                period = `${translate("common.week", "Week")} ${period},`;
            }
            return {
                label: `${period} ${year}`,
                value
            };
        });
    };

    getCellProps = (cell: Cell) => {
        const { value } = cell;
        const classes = [];

        if (value.requirements !== undefined) {
            classes.push("data-cell");

            const { requirements, deliveries, stock } = value;
            if (stock > 0) {
                classes.push("stock-pos");
            } else if (stock < 0) {
                classes.push("stock-neg");
            } else {
                classes.push("stock-0");
            }

            const diff = deliveries - requirements;
            if (diff > 0) {
                classes.push("diff-pos");
            } else if (diff < 0) {
                classes.push("diff-neg");
            } else {
                classes.push("diff-0");
            }
        }

        if (value.title !== undefined) {
            classes.push("material");
        }

        return {
            className: classes.join(" ")
        };
    };

    getContainerPosition = () => {
        const nav = document.querySelector(".navigation-bar");
        return {
            position: "fixed",
            top: nav ? nav.offsetTop + nav.offsetHeight : 0,
            right: 0,
            bottom: 0,
            left: 0
        };
    };

    resetTableScroll = (left: boolean = true, top: boolean = true) => {
        if (this.table_ref.current) {
            if (left) {
                this.table_ref.current.scrollLeft = 0;
            }

            if (top) {
                this.table_ref.current.scrollTop = 0;
            }
        }
    };

    handleSettingsChange = () => {
        this.storeSettings();
        this.prepareData();
    };

    handlePlantChange = (option: SelectOption) => {
        this.resetTableScroll(false, true);
        this.setState({ selected_plant: option }, this.handleSettingsChange);
    };

    handleRequirementTypesChange = (options: SelectOption[]) => {
        this.setState({ selected_requirement_types: options }, this.handleSettingsChange);
    };

    handleVendorsChange = (options: SelectOption[]) => {
        this.setState({ selected_vendors: options }, this.handleSettingsChange);
    };

    handleGroupsChange = (options: SelectOption[]) => {
        this.resetTableScroll(false, true);
        this.setState({ selected_groups: options }, this.handleSettingsChange);
    };

    handleHorizonChange = (option: SelectOption) => {
        this.resetTableScroll(true, false);
        this.setState({ selected_horizon: option }, this.handleSettingsChange);
    };

    handleNoCoverageTimeChange = (option: SelectOption) => {
        this.resetTableScroll(false, true);
        this.setState({ selected_no_coverage_time: option }, this.handleSettingsChange);
    };

    handleTimeGranularityChange = (event: SyntheticEvent<HTMLInputElement>) => {
        this.resetTableScroll(true, false);
        this.setState(
            {
                selected_time_granularity: event.currentTarget.value,
                selected_no_coverage_time: null
            },
            this.handleSettingsChange
        );
    };

    handleVendorInputChange = (value: string, { action }: SelectAction) => {
        if (action === "input-change") {
            this.setState({
                vendor_filter: value
            });
        } else if (action === "menu-close") {
            this.setState({
                vendor_filter: ""
            });
        }
    };

    handleTypeInputChange = (value: string, { action }: SelectAction) => {
        if (action === "input-change") {
            this.setState({
                type_filter: value
            });
        } else if (action === "menu-close") {
            this.setState({
                type_filter: ""
            });
        }
    };

    handleDataButtonClick = () => {
        this.setState({
            show_data_modal: true
        });
    };

    handleDataModalHide = () => {
        this.setState({
            show_data_modal: false
        });
    };

    handleExport = () => {
        const { columns, rows } = this.state;
        if (columns === null || rows === null) {
            return;
        }

        const headers = [];

        for (const { accessor: key } of columns) {
            if (key === "material") {
                headers.push(
                    translate("OrderTableProduction.material_external_id", "Material ID"),
                    translate("OrderTableProduction.material_title", "Material title"),
                    translate("common.stock", "Stock"),
                    ""
                );
            } else {
                let [year, period] = key.split("-");
                if (this.state.selected_time_granularity === TIME_GRANULARITY.month) {
                    const month = Number(period);
                    if (month >= 1 && month <= 12) {
                        period = translate(`common.month${month - 1}`).slice(0, 3);
                    }
                } else {
                    period = `${translate("common.week", "Week")} ${period}`;
                }

                headers.push(`${period}, ${year}`);
            }
        }

        const data = [headers];

        for (const row of rows) {
            const requirements = [];
            const deliveries = [];
            const stock = [];

            for (const column of columns) {
                const key = column.accessor;

                if (key === "material") {
                    const material_cell = row.material;
                    requirements.push(
                        material_cell.external_id,
                        material_cell.title,
                        material_cell.stock,
                        translate("common.requirements", "Requirements")
                    );
                    deliveries.push(
                        material_cell.external_id,
                        material_cell.title,
                        material_cell.stock,
                        translate("common.deliveries", "Deliveries")
                    );
                    stock.push(
                        material_cell.external_id,
                        material_cell.title,
                        material_cell.stock,
                        translate("common.stock", "Stock")
                    );
                } else {
                    const data_cell = row[key];
                    requirements.push(data_cell.requirements);
                    deliveries.push(data_cell.deliveries);
                    stock.push(data_cell.stock);
                }
            }

            data.push(requirements);
            data.push(deliveries);
            data.push(stock);
        }

        const wb = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet(data);
        const merge = [];

        for (let i = 0; i < rows.length; i++) {
            merge.push(
                { s: { r: i * 3 + 1, c: 0 }, e: { r: i * 3 + 3, c: 0 } },
                { s: { r: i * 3 + 1, c: 1 }, e: { r: i * 3 + 3, c: 1 } },
                { s: { r: i * 3 + 1, c: 2 }, e: { r: i * 3 + 3, c: 2 } }
            );
        }

        worksheet["!merges"] = merge;
        XLSX.utils.book_append_sheet(
            wb,
            worksheet,
            translate("Manufacturing.StocksView.long_term_stock_forecast", "Long term stock forecast")
        );
        XLSX.writeFile(wb, "long-term-stock-forecast.xlsx");
    };

    renderHeader = (label: string): React.Node => {
        let [year, period] = label.split("-");
        let date;
        if (this.state.selected_time_granularity === TIME_GRANULARITY.month) {
            date = year;
            const month = Number(period);
            if (month >= 1 && month <= 12) {
                period = translate(`common.month${month - 1}`).slice(0, 3);
            }
        } else {
            const m = moment(`${year}-${period}`, "GGGG-WW");
            const reg_exp = new RegExp(`(${year}|${Number(year) + 1})\-?`);
            const from = niceDate(m.startOf("isoWeek").toDate()).replace(reg_exp, "");
            const to = niceDate(m.endOf("isoWeek").toDate()).replace(reg_exp, "");
            date = (
                <React.Fragment>
                    <div>{year}</div>
                    <div className="text-truncate text-muted" style={{ fontSize: 11 }}>
                        {from} - {to}
                    </div>
                </React.Fragment>
            );
            period = (
                <React.Fragment>
                    {translate("common.week", "Week")} {period}
                </React.Fragment>
            );
        }

        return (
            <div className={`data-header text-right ${this.state.selected_time_granularity}`}>
                <div className="text-muted">{date}</div>
                <div>{period}</div>
            </div>
        );
    };

    renderMaterial = ({ value }: Cell) => (
        <Tooltip
            content={
                <div className="ltsf-tooltip mx-n2">
                    <p className="title px-2 pb-2 mb-0">{value.title}</p>
                    <table className="mx-2 mt-2">
                        <tbody>
                            <tr>
                                <th className="font-weight-500">{translate("common.external_id", "External ID")}</th>
                                <td className="pl-2">{value.external_id}</td>
                            </tr>
                            <tr>
                                <th className="font-weight-500">{translate("common.stock", "Stock")}</th>
                                <td className="pl-2">{niceNumber(value.stock, 0)}</td>
                            </tr>
                            <tr>
                                <th className="font-weight-500">{translate("common.groups", "Groups")}</th>
                                <td className="pl-2">{value.groups.join(", ")}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            }
        >
            <div className="d-flex align-items-center">
                <div className="d-flex flex-column title flex-grow-1">
                    <div className="d-flex align-items-center text-truncate_">
                        <span className="text-truncate">{value.title}</span>
                        {value.groups.length > 1 && <i className="fas fa-users ml-2" />}
                    </div>
                    <div className="text-truncate text-muted">{value.external_id}</div>
                </div>
                <ul className="stock-data list-unstyled">
                    <li className="text-muted">{translate("common.requirements", "Requirements")}</li>
                    <li className="text-muted">{translate("common.deliveries", "Deliveries")}</li>
                    <li className="d-flex justify-content-between">
                        <span className="text-muted">{translate("common.stock", "Stock")}</span>
                        <span className="text-truncate pl-3 text-right">{niceNumber(value.stock, 0)}</span>
                    </li>
                </ul>
            </div>
        </Tooltip>
    );

    renderMaterialFilter = (props: FilterProps) => {
        const {
            column: { setFilter },
            filteredRows,
            initialRows
        } = props;

        return (
            <div className="form-group mb-0">
                <input
                    className="form-control"
                    value={this.state.material_filter}
                    onChange={event => {
                        const target = event.currentTarget;
                        this.setState({
                            material_filter: target.value
                        });

                        clearTimeout(this.material_filter_timeout);
                        this.material_filter_timeout = setTimeout(() => {
                            target.blur();
                            this.resetTableScroll(false, true);
                            setFilter(this.state.material_filter);
                            target.focus({ preventScroll: true });
                        }, 250);
                    }}
                    placeholder={translate("common.material", "Material")}
                    style={{
                        height: 30
                    }}
                />

                <small className="form-text text-muted mt-1 mb-n2">
                    {translate("common.materials", "Materials")}: {filteredRows.length} / {initialRows.length}
                </small>
            </div>
        );
    };

    renderTimeGranularityOptions = () => {
        const options = {
            [TIME_GRANULARITY.week]: translate("common.week", "Week"),
            [TIME_GRANULARITY.month]: translate("Calendar.month", "Month")
        };

        return (
            <div className="btn-group btn-group-toggle">
                {Object.keys(options).map(option => {
                    const is_checked = option === this.state.selected_time_granularity;
                    return (
                        <label key={option} className={`btn btn-primary${is_checked ? " active" : ""}`}>
                            <input
                                type="radio"
                                name="time_granularity"
                                value={option}
                                checked={is_checked}
                                onChange={this.handleTimeGranularityChange}
                            />
                            {options[option]}
                        </label>
                    );
                })}
            </div>
        );
    };

    renderDataTooltip = (data: number[], title?: string) => {
        const { stock_forecast } = this.state;
        if (stock_forecast === null) {
            return null;
        }

        return (
            data.length > 0 && (
                <div className="ltsf-tooltip mx-n2">
                    {title && <p className="title px-2 pb-2 mb-0">{title}</p>}
                    <table className="table data-items mb-0">
                        <thead>
                            <tr>
                                <th>{translate("common.date", "Date")}</th>
                                <th className="text-right">{translate("common.quantity", "Quantity")}</th>
                                <th>{translate("common.type", "Type")}</th>
                                <th>{translate("common.supplier", "Supplier")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data
                                .sort((a, b) => stock_forecast.data[a].d - stock_forecast.data[b].d)
                                .map(i => {
                                    const data_item = stock_forecast.data[i];
                                    const type = stock_forecast.stock_requirement_types.find(
                                        type => type.id === data_item.cid
                                    );
                                    const vendor = stock_forecast.vendors.find(vendor => vendor.id === data_item.vid);

                                    let type_label = type ? getStockRequirementTypeShortTitle(type).trim() : "";
                                    const type_external_code = type ? type.external_code.trim() : "";
                                    if (type_external_code !== "") {
                                        type_label += ` (${type_external_code})`;
                                    }

                                    let vendor_label = vendor ? vendor.title : "";
                                    const vendor_external_id = vendor ? vendor.external_id.replace(/^0+/, "") : "";
                                    if (vendor_external_id !== "") {
                                        vendor_label += ` (${vendor_external_id})`;
                                    }

                                    return (
                                        <tr key={i}>
                                            <td>{niceDate(new Date(data_item.d))}</td>
                                            <td className="text-right">
                                                <b className="font-weight-500">{niceNumber(data_item.q, 0)}</b>
                                            </td>
                                            <td>{type_label.trim() || "-"}</td>
                                            <td>{vendor_label.trim() || "-"}</td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </table>
                </div>
            )
        );
    };

    renderData = ({ value }: Cell) => {
        const { stock_forecast } = this.state;
        if (stock_forecast === null) {
            return null;
        }

        const requirements = <li>{niceNumber(value.requirements, 0)}</li>;
        const requirements_tooltip = this.renderDataTooltip(
            value.data.filter(d => stock_forecast.data[d].q < 0),
            translate("common.requirements", "Requirements")
        );
        const deliveries = <li>{niceNumber(value.deliveries, 0)}</li>;
        const deliveries_tooltip = this.renderDataTooltip(
            value.data.filter(d => stock_forecast.data[d].q > 0),
            translate("common.deliveries", "Deliveries")
        );
        const stock = <li>{niceNumber(value.stock, 0)}</li>;
        const stock_tooltip = this.renderDataTooltip(value.data);

        return (
            <ul className="stock-data list-unstyled text-right">
                {requirements_tooltip ? <Tooltip content={requirements_tooltip}>{requirements}</Tooltip> : requirements}
                {deliveries_tooltip ? <Tooltip content={deliveries_tooltip}>{deliveries}</Tooltip> : deliveries}
                {stock_tooltip ? <Tooltip content={stock_tooltip}>{stock}</Tooltip> : stock}
            </ul>
        );
    };

    render() {
        const is_loading = this.state.stock_forecast === null;
        const plant_options = this.getPlantOptions();
        const selected_plant_option = this.state.selected_plant;
        const requirement_type_options = this.getRequirementTypeOptions(true);
        const selected_requirement_type_options = this.state.selected_requirement_types || [];
        const vendor_options = this.getVendorOptions(true);
        const selected_vendor_options = this.state.selected_vendors || [];
        const group_options = this.getGroupOptions();
        const selected_group_options = this.state.selected_groups || [];
        const horizon_options = this.getHorizonOptions();
        const selected_horizon_option = this.state.selected_horizon;
        const no_coverage_time_options = this.getNoCoverageTimeOptions();
        const selected_no_coverage_time_option = this.state.selected_no_coverage_time;

        return (
            <article className="article position-fixed" style={this.getContainerPosition()}>
                <section id="statistics" className="data_sources h-100 pb-0">
                    <div className="filter_bar views-filter-bar">
                        <div className="row align-items-center">
                            <div className="col col-auto ml-auto">
                                <div className="form-row">
                                    <div className="col-auto">
                                        <div className="form-group mb-0" style={{ width: 250 }}>
                                            <Select
                                                placeholder={translate("common.plants", "Plants")}
                                                value={selected_plant_option}
                                                options={plant_options}
                                                components={{
                                                    IndicatorSeparator: null
                                                }}
                                                onChange={this.handlePlantChange}
                                                isLoading={is_loading}
                                                isClearable={false}
                                                theme={getSelectTheme}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <div className="form-group mb-0" style={{ width: 250 }}>
                                            <Select
                                                placeholder={translate("common.suppliers", "Suppliers")}
                                                value={selected_vendor_options}
                                                inputValue={this.state.vendor_filter}
                                                options={vendor_options}
                                                components={{
                                                    Placeholder: SelectPlaceholder,
                                                    MultiValue: SelectMultiValue,
                                                    Option: SelectCheckboxOption,
                                                    IndicatorSeparator: null,
                                                    Menu: SelectMenu
                                                }}
                                                onChange={this.handleVendorsChange}
                                                onInputChange={this.handleVendorInputChange}
                                                isLoading={is_loading}
                                                closeMenuOnSelect={false}
                                                hideSelectedOptions={false}
                                                isClearable={false}
                                                theme={getSelectTheme}
                                                isMulti
                                            />
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <div className="form-group mb-0" style={{ width: 200 }}>
                                            <Select
                                                placeholder={translate("common.requirement_types", "Requirement types")}
                                                value={selected_requirement_type_options}
                                                inputValue={this.state.type_filter}
                                                options={requirement_type_options}
                                                components={{
                                                    Placeholder: SelectPlaceholder,
                                                    MultiValue: SelectMultiValue,
                                                    Option: SelectCheckboxOption,
                                                    IndicatorSeparator: null,
                                                    Menu: SelectMenu
                                                }}
                                                onChange={this.handleRequirementTypesChange}
                                                onInputChange={this.handleTypeInputChange}
                                                isLoading={is_loading}
                                                closeMenuOnSelect={false}
                                                hideSelectedOptions={false}
                                                isClearable={false}
                                                theme={getSelectTheme}
                                                isMulti
                                            />
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <div className="form-group mb-0" style={{ width: 130 }}>
                                            <Select
                                                placeholder={translate("common.groups", "Groups")}
                                                value={selected_group_options}
                                                inputValue={this.state.type_filter}
                                                options={group_options}
                                                components={{
                                                    Placeholder: SelectPlaceholder,
                                                    MultiValue: SelectMultiValue,
                                                    Option: SelectCheckboxOption,
                                                    IndicatorSeparator: null,
                                                    Menu: SelectMenu
                                                }}
                                                onChange={this.handleGroupsChange}
                                                onInputChange={this.handleTypeInputChange}
                                                isLoading={is_loading}
                                                closeMenuOnSelect={false}
                                                hideSelectedOptions={false}
                                                isClearable={false}
                                                theme={getSelectTheme}
                                                isMulti
                                            />
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <div className="form-group mb-0" style={{ width: 170 }}>
                                            <Select
                                                placeholder={translate("common.no_coverage", "No coverage")}
                                                value={selected_no_coverage_time_option}
                                                options={no_coverage_time_options}
                                                components={{
                                                    IndicatorSeparator: null
                                                }}
                                                onChange={this.handleNoCoverageTimeChange}
                                                isLoading={is_loading}
                                                isClearable={true}
                                                theme={getSelectTheme}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <div className="form-group mb-0" style={{ width: 130 }}>
                                            <Select
                                                placeholder={translate("common.horizon", "Horizon")}
                                                value={selected_horizon_option}
                                                options={horizon_options}
                                                components={{
                                                    IndicatorSeparator: null
                                                }}
                                                onChange={this.handleHorizonChange}
                                                isLoading={is_loading}
                                                isClearable={false}
                                                theme={getSelectTheme}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-auto d-flex align-items-center">
                                        <div className="form-group mb-0">{this.renderTimeGranularityOptions()}</div>
                                    </div>
                                    <div className="col-auto d-flex align-items-center">
                                        <Authorization.button
                                            className="btn btn-short btn-outline-secondary px-2"
                                            title={translate("common.files", "Files")}
                                            style={{ width: 30 }}
                                            permission={Auth.PERMISSION_NAMES.StockForecastFilesEdit}
                                            onClick={this.handleDataButtonClick}
                                        >
                                            <i className="fas fa-file-csv align-middle" />
                                        </Authorization.button>
                                        <BlobStorageItemsModal
                                            show={this.state.show_data_modal}
                                            getItems={getFiles}
                                            getItem={getFile}
                                            setItem={setFile}
                                            deleteItem={deleteFile}
                                            formatItem={formatFileKey}
                                            cleanItem={cleanFileKey}
                                            validateContent={validateFileContent}
                                            onHide={this.handleDataModalHide}
                                        />
                                    </div>
                                    <div className="col-auto d-flex align-items-center">
                                        <button
                                            className="btn btn-short btn-outline-secondary px-2"
                                            title={translate("common.export_xlsx", "Export to Excel")}
                                            style={{ width: 30 }}
                                            onClick={this.handleExport}
                                        >
                                            <i className="fas fa-file-export align-middle" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="h-100" style={{ paddingTop: 52 }}>
                        {this.state.rows === null || this.state.columns === null ? (
                            <Loader />
                        ) : (
                            <VirtualTable
                                columns={this.state.columns}
                                data={this.state.rows}
                                getCellProps={this.getCellProps}
                                class_name="ltsf-table w-100 h-100 mb-0"
                                head_height={64}
                                row_height={66}
                                fix_head
                            />
                        )}
                    </div>
                </section>
            </article>
        );
    }
}

export default LongTermStockForecast;
