// @flow

import React from "react";
import SvgIcon, { type SvgIconProps } from "../SvgIcon";

const ProductionGuiding = (props: SvgIconProps) => (
    <SvgIcon {...props}>
        <svg viewBox="0 0 26 26">
            <g>
                <path d="M11.41,11.03c0,3-2.22,4.85-5.8,4.85H2.53v3.93H0V6.19h5.61C9.19,6.19,11.41,8.02,11.41,11.03z M8.86,11.03 c0-1.71-1.15-2.71-3.37-2.71H2.53v5.41h2.96C7.71,13.74,8.86,12.75,8.86,11.03z" />
                <path d="M23.43,12.84h2.39v5.43c-1.42,1.15-3.41,1.73-5.33,1.73c-4.22,0-7.34-2.94-7.34-7.01 c0-4.07,3.11-7.01,7.38-7.01c2.28,0,4.18,0.76,5.47,2.22l-1.6,1.56c-1.07-1.07-2.3-1.56-3.76-1.56c-2.9,0-4.94,1.97-4.94,4.79 c0,2.76,2.04,4.79,4.92,4.79c0.97,0,1.93-0.19,2.8-0.7V12.84z" />
            </g>
        </svg>
    </SvgIcon>
);

export default ProductionGuiding;
