// @flow
import React from "react";
import { connect } from "react-redux";

import { ResizeLeftMenu } from "../reducers/BusinessLogic";
import ErrorHandler from "../ErrorHandler";
import OrderInsightsDiff from "../OrderInsightsDiff";
import Tabs from "../../../react/Tabs";

import StockRequirementsTabContent, { StockRequirementsTabHeader } from "./StockRequirements";
import InsightsTabContent, { InsightsTabHeader } from "./Insights";
import OrdersTabContent, { OrdersTabHeader } from "./Orders"
import IgnoredOrdersTabContent, { IgnoredOrdersTabHeader } from "./IgnoredOrders"
import { LeftMenuViews } from "./common";
import { LINE_GROUP_TAGS_ACCESS } from "../../../../lib/ManufacturingTags.generated";
import { getLineGroupTags } from "../../../../lib/BusinessLogic";

import type { TabConfig } from "../../../react/Tabs";
import type {
    PropertiesState,
    UpdatePropertiesActionType,
    ResetProperties
} from "../reducers/properties";
import type { SetRescheduleOrderAction } from "../reducers/common";
import type { SetTranslationsMaterials } from "../../../redux/reducers/translationsMaterialsReducer";
import type { ReportState, ResetReportAction } from "../reducers/report";
import type {
    LoadingAction, LineOrders, UpdateLinesOrdersAction,
    ResetLinesOrdersAction, SetUnscheduledOrdersAction
} from "../reducers/linesOrders";
import type { ResetValidLinesAction, SetErrorAction } from "../reducers/planningTable";
import type { ReduxState } from "../reducers/reducers";
import type { ResetGanttAction } from "../reducers/common";
import type { LeftMenuViewsType } from "./common";


type Props = {
    plant_uuid: string,
    line_group_uuid: string,
    report: ReportState,
    lines_orders: LineOrders[],
    lines_orders_loading: boolean,
    width: number,
    height: number,
    reduxDispatch: (
        args: (
            LoadingAction | UpdateLinesOrdersAction | ResetLinesOrdersAction |
            ResetReportAction | SetUnscheduledOrdersAction | ResetValidLinesAction |
            ResetGanttAction | UpdatePropertiesActionType | ResetProperties |
            SetTranslationsMaterials | SetRescheduleOrderAction | SetErrorAction
        )
    ) => any,
    unscheduled_orders: LineOrders[],
    show_unscheduled_orders: boolean,
    error: string | null,
    show_planning_table_filter: boolean,
    properties: PropertiesState,
    zoom_enabled: boolean,
    enforce_orders_view_change: LeftMenuViewsType,
    show_stock_requirements: boolean
};
export type InsightStats = {
    solved: number,
    unsolved: number
}
type State = {
    insight_stats: InsightStats,
    show_modal_line_title: string | null
};

class LeftMenu extends React.Component<Props, State> {

    state = {
        show_modal_line_title: null,
        insight_stats: {
            solved: 0,
            unsolved: 0
        }
    }

    getHasUnscheduledOrders = (): boolean => {
        const orders = [];
        this.props.unscheduled_orders.forEach(lo => orders.push(...lo.orders));
        return orders.length > 0;
    }

    onClickInfo = (show_modal_line_title: string) => (e: Event) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ show_modal_line_title });
    }

    updateInsightsStats = (solved: number, unsolved: number) => {
        this.setState({ insight_stats: { solved, unsolved } });
    }

    onCloseModal = () => {
        this.setState({
            show_modal_line_title: null
        });
    }

    getTabsData = (): TabConfig<LeftMenuViewsType>[] => {
        const data = [
            {
                title: <OrdersTabHeader />,
                id: LeftMenuViews.orders,
                content: <OrdersTabContent
                    show_planning_table_filter={this.props.show_planning_table_filter}
                    line_group_uuid={this.props.line_group_uuid}
                    plant_uuid={this.props.plant_uuid}
                    lines_orders_loading={this.props.lines_orders_loading}
                    lines_orders={this.props.lines_orders}
                    onClickInfo={this.onClickInfo}
                />
            },
            {
                title: <IgnoredOrdersTabHeader />,
                id: LeftMenuViews.ignored_orders,
                content: <IgnoredOrdersTabContent lines_orders_loading={this.props.lines_orders_loading} />
            }
        ];

        const line_group_tags = this.props.properties.line_group_uuid !== null
            ? getLineGroupTags(this.props.properties.line_group_uuid)
            : {};
        const show_issues_tab = LINE_GROUP_TAGS_ACCESS.planning_table_show_issues_tab(line_group_tags);
        if (show_issues_tab) {
            data.push({
                title: <InsightsTabHeader insights_stats={this.state.insight_stats} />,
                id: LeftMenuViews.insights,
                content: <InsightsTabContent
                    report={this.props.report}
                    updateInsightsStats={this.updateInsightsStats}
                />
            });
        }

        if (this.props.show_stock_requirements) {
            data.splice(2, 0, {
                title: <StockRequirementsTabHeader />,
                id: LeftMenuViews.stock_requirements,
                content: <StockRequirementsTabContent
                    report={this.props.report}
                    show_planning_table_filter={this.props.show_planning_table_filter}
                    line_group_uuid={this.props.line_group_uuid}
                    lines_orders={this.props.lines_orders}
                />
            })
        }
        return data;
    }

    render() {
        const show_left_menu = this.props.show_planning_table_filter;

        return <div
            id="left-content"
            className="planning-table-sidebar2 flex-column"
            style={{ display: show_left_menu ? "flex" : "none", width: ResizeLeftMenu.getWidth() }}
        >
            <ErrorHandler />
            <Tabs
                data={this.getTabsData()}
                active_tab={this.props.enforce_orders_view_change}
            />
            <OrderInsightsDiff show_order_line_title={this.state.show_modal_line_title} onCloseModal={this.onCloseModal} />
        </div>
    }
}

export default connect(
    (state: ReduxState) => {
        const report = state.gantt_chart_report;
        const planning_table = state.gantt_chart_planning_table;
        const lines_orders = state.gantt_chart_lines_orders.lines_orders;
        const loading = state.gantt_chart_lines_orders.loading;
        const properties = state.gantt_chart_properties;
        const controls = state.gantt_chart_controls;
        return {
            report,
            lines_orders_loading: loading,
            lines_orders,
            unscheduled_orders: state.gantt_chart_lines_orders.unscheduled_orders,
            error: planning_table.error,
            properties,
            zoom_enabled: controls.zoom_enabled,
            enforce_orders_view_change: planning_table.selected_left_menu_view,
            show_stock_requirements: planning_table.show_stock_requirements
        };
    },
    (dispatch) => ({reduxDispatch: dispatch})
)(LeftMenu);
