// @flow
// $FlowFixMe
import "../../../../styles/line_order_list.scss"
import * as React from "react";
import * as linesOrdersTypes from "./../reducers/linesOrders";
import * as t from "../../../../lib/backend/manufacturing2.generated.types";
import Order from "./Order";
import ShiftEditorModal from "./../ShiftEditorModal";
import ShiftEditorWeek from "./../ShiftEditorWeek";
import { connect } from "react-redux";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { translate } from "../../../IntlProviderWrapper";
import { highlightRescheduleLine } from "../../../GanttChartUtils";
import { UPDATE_SHIFTS_ACTION_NAME } from "./../reducers/linesOrders";
import { SET_RESCHEDULE_LINE_UUID } from "../reducers/common";
import { RESET_FILTERS } from "../reducers/reducersFilters/filters";
import { shiftNumber, dateFromWeekAndShift, splitIntoArrOfArr, deepClone } from "../../../../lib/Util";
import { PropertiesLogic, ReduxFilters } from "../reducers/BusinessLogic";
import { PLANNING_EXPLANATION_TYPE } from "../../../../lib/ManufacturingConsts.generated";
import { TypeFilterValues } from "../lineOrderList/filters/TypeFilterConsts";

import type { ReportLoadingAction } from "../reducers/report";
import type { SetRescheduleOrderBundleAction } from "../reducers/common";
import type { SetHighlightedOrders } from "./../reducers/planningTable";
import type { OrderObjGui } from "./Order";
import type { ReduxState } from "../reducers/reducers";
import type { LineOrders } from "./../reducers/linesOrders";
import type { ResetValidLinesAction } from "./../reducers/planningTable";
import type {
    IOrderProducedModelEx,
    MoveLineOrderAction,
    UpdateLineOrderAction,
    UpdateLineOrdersAction
} from "./../reducers/linesOrders";
import type { SetRescheduleLineAction, SetRescheduleOrderAction } from "../reducers/common";
import type {
    ResetFiltersAction,
    SetLastClickedOrderUuid
} from "./../reducers/planningTable";
import { SHIFTS_IN_WEEK } from "../GanttChart/constants";
import { GanttChartSource } from "../reducers/properties";
import { REPORT_LOADING_ACTION_NAME } from "../reducers/report";

type ShiftsForLineProps = {
    line_uuid: string,
    line_title: string,
    line_group_uuid: string,
    week: number,
    year: number,
    shifts: t.IShiftLineRec[],
    reduxDispatch: any => any
}

type ShiftsForLineState = {
    show_shift_editor: boolean,
    shifts: t.IShiftLineRec[],
    shifts_split: Array<Array<Array<t.IShiftLineRec>>>
}

class ShiftsForLine extends React.Component<ShiftsForLineProps, ShiftsForLineState> {

    state = {
        show_shift_editor: false,
        shifts_split: [],
        shifts: []
    }

    componentDidMount() {
        const shifts = deepClone(this.props.shifts);
        this.setState({ shifts }, () => this.reloadShifts());
    }

    componentDidUpdate(prev_props: ShiftsForLineProps) {
        if (prev_props.shifts !== this.props.shifts) {
            const shifts = deepClone(this.props.shifts);
            this.setState({ shifts }, () => this.reloadShifts());
        }
    }

    getShiftEditWeek = () => {
        return PropertiesLogic.getNumShiftsFromDayTag(
            GanttChartSource.planning_table,
            this.props.line_group_uuid
        ) / SHIFTS_IN_WEEK;
    }

    reloadShifts = () => {
        const shift_edit_weeks = this.getShiftEditWeek();
        const shifts_split = [];

        for (let shift_editor_i = 0; shift_editor_i < shift_edit_weeks; shift_editor_i++) {
            const { year: future_year, week } = this.getWeekAndYear(shift_editor_i);
            const shift_week = this.state.shifts.filter(x => x.week === week && x.year === future_year);
            shifts_split[week] = splitIntoArrOfArr(shift_week, 3);
        }

        this.setState({ shifts_split })
    }

    openModal = () => {
        this.setState({ show_shift_editor: true })
    }

    getWeekAndYear = (offset: number, week: number | null = null) => {
        const shift = shiftNumber(dateFromWeekAndShift((week || this.props.week) + offset, this.props.year, 0));
        return { week: shift.week, year: shift.year }
    }

    handleShiftClick = (shift: number, day: number, active: boolean, week: number | null, year: number) => {
        const index = 3 * day + shift;

        const shifts = this.state.shifts.filter(s => s.week === week && s.year === year);
        const new_shift = shifts[index];
        const original_index = this.state.shifts.findIndex(s => s === new_shift);
        new_shift.enabled = !active;

        const state_shifts = this.state.shifts
        state_shifts[original_index] = new_shift;

        this.setState({ shifts: [...this.state.shifts] }, () => this.reloadShifts());
    }

    getShiftEditors = () => {
        const shiftEditors = [];
        const shift_edit_weeks = this.getShiftEditWeek();
        for (let shift_editor_i = 0; shift_editor_i < shift_edit_weeks; shift_editor_i++) {
            const { week } = this.getWeekAndYear(shift_editor_i);
            shiftEditors.push(
                <ShiftEditorWeek
                    current_shift={-1}
                    current_week={week}
                    key={this.props.line_uuid + "_shifts_" + shift_editor_i}
                    ref={"shedule" + shift_editor_i}
                    shifts={this.state.shifts_split[week]}
                    show_overrides={true}
                    show_dates={true}
                    handleClick={(shift, day, active, week_arg, year) => this.handleShiftClick(shift, day, active, week, year)}
                />);
        }

        return shiftEditors;
    }

    onCloseModal = (e: Event) => {
        const shifts = deepClone(this.props.shifts);
        this.setState({ shifts, show_shift_editor: false }, () => this.reloadShifts());

        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
    }

    onSaveModal = (e: Event) => {
        this.setState({ show_shift_editor: false });

        if (this.state.shifts !== this.props.shifts) {
            this.props.reduxDispatch({
                type: UPDATE_SHIFTS_ACTION_NAME,
                data: {
                    line_uuid: this.props.line_uuid,
                    shifts: this.state.shifts
                }
            });
        }

        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
    }

    render() {
        const shift_editors = this.getShiftEditors();

        return (
            <button className="dropdown-item" onClick={this.openModal}>
                {translate("common.shifts", "Shifts")}
                <ShiftEditorModal
                    onClose={this.onCloseModal}
                    onSave={this.onSaveModal}
                    line_title={this.props.line_title}
                    show={this.state.show_shift_editor}
                >
                    {shift_editors}
                </ShiftEditorModal>
            </button>
        );
    }
}

const ConnectedShiftsForLine = connect(
    (state: ReduxState, own_props: ShiftsForLineProps) => {
        const lines_orders = state.gantt_chart_lines_orders.lines_orders;
        const line_orders = lines_orders.find(lo => lo.line_uuid === own_props.line_uuid);

        let shifts = [];

        if (line_orders) {
            shifts = line_orders.shifts;
        }

        return { shifts }
    },
    (dispatch) => ({reduxDispatch: dispatch})
)(ShiftsForLine)

type RescheduleLineProps = {
    line_uuid: string,
    lines_orders: LineOrders[],
    is_enabled_reschedule: boolean,
    reduxDispatch: (
        args: (
            SetRescheduleLineAction | ResetValidLinesAction |
            SetHighlightedOrders | ResetFiltersAction
        )
    ) => void,
    original_report_created_at: number
};

type RescheduleLineState = { }


class RescheduleLine extends React.Component<RescheduleLineProps, RescheduleLineState> {

    componentDidUpdate(prev_props: RescheduleLineProps) {
        // unhighlight lines
        if (prev_props.is_enabled_reschedule !== this.props.is_enabled_reschedule && this.props.is_enabled_reschedule === false) {
            highlightRescheduleLine(null);
        }
    }

    rescheduleLine = async () => {
        if (this.props.is_enabled_reschedule) {
            this.props.reduxDispatch({ type: RESET_FILTERS, data: undefined });
        } else {
            this.props.reduxDispatch({ type: SET_RESCHEDULE_LINE_UUID, data: this.props.line_uuid });
        }
    }

    render() {
        return (
            <button className="dropdown-item" onClick={this.rescheduleLine}>
                {this.props.is_enabled_reschedule ? translate("common.cancel", "Cancel") : translate("common.reschedule", "Reschedule")}
            </button>
        );
    }
}

const ConnectedRescheduleLine = connect(
    (state: ReduxState, own_props: RescheduleLineProps) => {
        const lines_orders = state.gantt_chart_lines_orders.lines_orders;
        const filters = state.gantt_chart_filters;
        const is_enabled_reschedule =
            filters.selected_filter_type === TypeFilterValues.reschedule_line &&
            filters.reschedule_line_uuid === own_props.line_uuid;
        return {
            lines_orders,
            is_enabled_reschedule,
            original_report_created_at: state.gantt_chart_report.original_report_created_at
        }
    },
    (dispatch) => ({reduxDispatch: dispatch})
)(RescheduleLine);


type SortableElementProps = {
    value: OrderObjGui,
    sortIndex: number,
    line_uuid: string,
    capacity_factor: number,
    sim_ignore_earliest_start: number,
    enabled_edit: boolean,
    plant_uuid: string,
    draw_split_line: boolean
};

let SortableItem = SortableElement((args: SortableElementProps) => {
    const {
        value,
        sortIndex,
        line_uuid,
        capacity_factor,
        sim_ignore_earliest_start,
        enabled_edit,
        plant_uuid,
        draw_split_line
    } = args;

    const order = <Order
        order_uuid={value.uuid}
        sortIndex={sortIndex}
        line_uuid={line_uuid}
        capacity_factor={capacity_factor}
        sim_ignore_earliest_start={sim_ignore_earliest_start}
        enabled={value.enabled}
        enabled_edit={enabled_edit}
        plant_uuid={plant_uuid}
    />;

    return draw_split_line ?
        <React.Fragment>
            <tr><td colSpan={5} style={{backgroundColor: "#ecebeb"}}></td></tr>
            {order}
        </React.Fragment>
        : order;
});

/*
table.planning-table__sortable-list .sortable-element__moveorder {
  max-width: 20px;
}

table.planning-table__sortable-list .sortable-element__advanced-menu {
  max-width: 20px;
}

table.planning-table__sortable-list .sortable-element__toolbar {
  max-width: 20px;
}
*/
///////////////////////////////////////////////////
// Production plan order list
export const SortableList = SortableContainer(({
    plant_uuid,
    line_uuid,
    items,
    enabled_edit,
    recommend_split_order_uuid
}) => {
    return <table className="planning-table__sortable-list">
        <thead>
            <tr>
                <th className="sortable-element__advanced-menu"></th>
                <th className="sortable-element__workorder">{translate("OrderTableProduction.external_id", "Order ID")}</th>
                <th className="sortable-element__material">{translate("common.id", "ID")} - {translate("common.material", "Material")}</th>
                <th className="sortable-element__unit">{translate("common.quantity", "Quantity")}</th>
                <th className="sortable-element__moveorder"></th>
            </tr>
        </thead>
        <tbody>
            {
                items.map((value, index) => {
                    return (<SortableItem
                            key={`index-${index}-${value.uuid}`}
                            index={index}
                            value={value}
                            sortIndex={index}
                            line_uuid={line_uuid}
                            capacity_factor={value.capacity_factor}
                            sim_ignore_earliest_start={value.sim_ignore_earliest_start}
                            enabled_edit={enabled_edit}
                            plant_uuid={plant_uuid}
                            draw_split_line={recommend_split_order_uuid !== "" && recommend_split_order_uuid === value.uuid}
                        />);
                })
            }
        </tbody>
    </table>
});

///////////////////////////////////////////////////
// Production plan order table widget
type Props = {
    line_uuid: string,
    line_group_uuid: string,
    line_orders: LineOrders | null,
    reduxDispatch: (
        args: SetHighlightedOrders | UpdateLineOrderAction | UpdateLineOrdersAction |
        MoveLineOrderAction | SetRescheduleOrderAction | ResetFiltersAction | SetLastClickedOrderUuid |
        SetRescheduleOrderBundleAction | ReportLoadingAction
    ) => void,
    week: number,
    year: number,
    show_line: boolean,
    enabled_edit: boolean,
    plant_uuid: string,
    is_filter_locked: boolean,
    recommend_split_order_uuid: string,
    onClickInfo: (line_title: string) => (e: Event) => void,
};

type State = {
    focused: boolean
};

class LineOrderList extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            focused: false
        };
    }

    getOrders = (): IOrderProducedModelEx[] => {
        const { line_orders } = this.props;
        return (line_orders && line_orders.orders) || [];
    }

    onSortEnd = async ({ oldIndex, newIndex }: Object) => {
        if (oldIndex === newIndex) return;
        const orders = this.getOrders();

        await ReduxFilters.setRescheduleOrderUuid(
            this.props.reduxDispatch,
            orders[oldIndex].uuid,
            "line_order_list",
            this.props.is_filter_locked
        );
        
        this.props.reduxDispatch({
            type: REPORT_LOADING_ACTION_NAME,
            data: true
        });

        this.props.reduxDispatch({
            type: linesOrdersTypes.MOVE_LINE_ORDER_ACTION_NAME,
            data: {
                line_uuid: this.props.line_uuid,
                old_index: oldIndex,
                new_index: newIndex
            }
        });
    };

    onMakeAll247 = () => {
        const { line_orders } = this.props;
        if (!line_orders) {
            return;
        }

        const orders = this.getOrders();
        for (const order of orders) {
            order.sim_ignore_earliest_start = true;
        }

        this.props.reduxDispatch({
            type: linesOrdersTypes.UPDATE_LINE_ORDERS_ACTION_NAME,
            data: {
                ...line_orders,
                orders
            }
        });
    }

    handleHeaderFocus = () => {
        this.setState({ focused: true });
    }

    handleHeaderBlur = () => {
        this.setState({ focused: false });
    }

    /////////////////////////////////////////
    // Render component
    render() {
        if (!this.props.show_line) { return null; }

        const orders = this.getOrders();
        const has_orders = orders.length > 0;
        const { line_orders } = this.props;
        const line_title = line_orders && line_orders.line_title || "";
        return (
            <div className="planning-table__row" style={{ caretColor: "transparent" }}>
                <div
                    className="planning-table__row-title pt-2 mt-n2"
                    style={{ zIndex: this.state.focused ? 2 : 1, position: "sticky", top: "0px", backgroundColor: "white"}}
                >
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>
                            {line_title}
                            <span className="info-icon ml-2" onClick={this.props.onClickInfo(line_title)}>
                                <i className="fas fa-info"></i>
                            </span>
                        </span>
                        <div>
                            <div
                                className="dropdown"
                                onFocus={this.handleHeaderFocus}
                                onBlur={this.handleHeaderBlur}
                            >
                                <button
                                    className="btn btn-short btn-flat-light px-1 py-0"
                                    data-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <i className="fas fa-ellipsis-h" />
                                </button>
                                <div
                                    className="dropdown-menu dropdown-menu-right"
                                    style={{ opacity: this.state.focused ? 1 : 0}}
                                >
                                    <ConnectedShiftsForLine
                                        line_uuid={this.props.line_uuid}
                                        line_title={line_title}
                                        line_group_uuid={this.props.line_group_uuid}
                                        week={this.props.week}
                                        year={this.props.year}
                                    />
                                    <ConnectedRescheduleLine line_uuid={this.props.line_uuid} />
                                    <button className="dropdown-item" onClick={this.onMakeAll247}>
                                        {translate("Manufacturing.Planning.make_all_24_7", "Make all 24/7")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className="my-2" />
                </div>
                {
                    !has_orders && <div className="planning-table__no-orders">
                        {translate("Manufacturing.Planning.no_orders", "No orders found.")}
                    </div>
                }
                {
                    has_orders && <SortableList
                        plant_uuid={this.props.plant_uuid}
                        line_uuid={this.props.line_uuid}
                        items={orders}
                        enabled_edit={this.props.enabled_edit}
                        recommend_split_order_uuid={this.props.recommend_split_order_uuid}
                        onSortEnd={this.onSortEnd}
                        useDragHandle={true}
                    />
                }
            </div>
        );
    }
}

export default connect(
    (state: ReduxState, ownProps: Props): $Shape<Props> => {
        const line_orders = state.gantt_chart_lines_orders.lines_orders.find(lo => lo.line_uuid === ownProps.line_uuid);

        // check if this lines passed the filter
        const valid_lines_map = state.gantt_chart_planning_table.show_valid_lines.valid_lines_map;
        const show_line = valid_lines_map ? valid_lines_map.has(ownProps.line_uuid) : true;

        const is_filter_locked = state.gantt_chart_filters.is_filter_locked;

        // find first order without explanation since it marks
        let recommend_split_order_uuid = "";
        const explanations = state.gantt_chart_planning_table.recommend_explanations;
        if (line_orders && explanations && explanations.length > 0) {
            const valid_types = [
                PLANNING_EXPLANATION_TYPE.frozen,
                PLANNING_EXPLANATION_TYPE.scheduled
            ];
            for (const order of line_orders.orders) {
                if (recommend_split_order_uuid === "") {
                    // we are still searching for first break
                    if (explanations.findIndex(e => e.operation_uuid === order.uuid && valid_types.includes(e.type)) === -1) {
                        recommend_split_order_uuid = order.uuid;
                    }
                } else {
                    // we found first break, check if we have another explanation
                    // as that means we did some significant changes since last planning
                    if (explanations.findIndex(e => e.operation_uuid === order.uuid) !== -1) {
                        recommend_split_order_uuid = "";
                        break;
                    }
                }
            }
        }

        return {
            line_orders: line_orders || null,
            show_line,
            is_filter_locked,
            recommend_split_order_uuid
        };
    },
    (dispatch) => ({reduxDispatch: dispatch})
)(LineOrderList);
