// @flow

// main imports
import React from "react";
import ReactRouterPropTypes from "react-router-prop-types";


// import subcomponents
import BatchOperationsArchive from "./BatchOperationsArchive";

type Props = {
    match: ReactRouterPropTypes.match
}

function BatchOperationsArchiveStandalone(props: Props) {
    return <BatchOperationsArchive match={props.match} showHeader={true} />;
}

export default BatchOperationsArchiveStandalone;
