// @flow

import * as React from "react";
import { Link } from "react-router-dom";
import ReactRouterPropTypes from "react-router-prop-types";
import { ResourcesLayout } from "./MainContent";
import { getBackend } from "../lib/Backend";
import { toISODateTimeString, stringCompare } from "../lib/Util";

import DashboardList from "./DashboardList";
import Dashboard from "./dashboard/Dashboard";

import type { DashboardBaseObj, DashboardObj } from "../lib/Models";

type State = {
    dashboards: Array<DashboardBaseObj>,
    selected: DashboardObj | null,
    selected_data: any | null,
    selected_layout: any | null,
    error: string | null
};

type Props = {
    history: ReactRouterPropTypes.history
};

class Dashboards extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            dashboards: [],
            selected: null,
            selected_data: null,
            selected_layout: null,
            error: null
        };
    }

    // load list of dashboards from the backend
    loadDashboards = () => {
        getBackend().dash.getDashboards(
            (dashboards) => {
                dashboards = dashboards.sort((a, b) => stringCompare(a.title, b.title));
                this.setState({ dashboards: dashboards });
                if (dashboards.length > 0) {
                    this.handleDashboardClick(dashboards[0]);
                }
            },
            (error) => { this.setState({ dashboards: [ ] });}
        );
    };

    // handle click on the dashboard in the list:
    // retrieve details and mark it as selected
    handleDashboardClick(dashboard: DashboardBaseObj) {
        getBackend().dash.getSingleDashboard(
            dashboard.uuid,
            (selected) => {
                this.setState({ selected });
                getBackend().dash.executeDashboard(dashboard.uuid, -1, -1, {}, (res) => {
                    this.setState({ selected_data: res.data, selected_layout: res.layout });
                },
                (err) => {
                    this.setState({ error: err });
                });
            },
            (err) => {
                this.setState({ error: err });
            }
        );
    }

    renderTags(tags: any) {
        let tags_components = [];
        Object.keys(tags).forEach((tag) => {
            tags_components.push(<span key={tag}> {tag}: </span>)
            tags_components.push(<span key={tag+tags[tag]} className="badge badge-info">{tags[tag]}</span>)
        })
        return tags_components;
    }

    componentDidMount() {
        this.loadDashboards();
    }

    showReportDetails() {
        const selected = this.state.selected;
        if (selected != null) {
            return (
                <div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="row">
                                <div className="col-12">
                                    <div className="active_data">
                                        <h5>{selected.title}</h5>
                                    </div>
                                </div>
                            </div>

                            <div className="white_box charts">
                                <div className="row">
                                    <div className="col-lg-5 col-md-5">
                                        <p className="data_source_text"><span>UUID: </span> {selected.uuid} </p>
                                        <p className="data_source_text">
                                            <span>System: </span>
                                            {selected.is_system ?
                                                (<span className="badge badge-warning">system</span>) :
                                                (<span className="badge badge-light">not system</span>)} &nbsp;
                                            <span>Active: </span>
                                            {selected.active ?
                                                (<span className="badge badge-success">active</span>) :
                                                (<span className="badge badge-light">inactive</span>)} &nbsp;
                                            <span>Public: </span>
                                            {selected.public ?
                                                (<span className="badge badge-info">public</span>) :
                                                (<span className="badge badge-light">private</span>)}
                                        </p>
                                    </div>
                                    <div className="col-lg-5 col-md-5">
                                        <p className="data_source_text"><span>Created at: </span>
                                            {toISODateTimeString(selected.created_at)}
                                        </p>
                                        <p className="data_source_text"><span>Created by: </span>
                                            {selected.created_by}
                                        </p>
                                    </div>
                                    <div className="col-lg-2 col-md-2">
                                        <div>
                                            <Link
                                                to={"/dashboard/" + selected.uuid}
                                                >Preview</Link>
                                        </div>
                                        {selected.public && (
                                            <div>
                                                <Link to={"/dashboard-public/" + selected.uuid} >
                                                    Public link
                                                </Link>
                                            </div>)}
                                        {selected.public && (
                                            <div>
                                                <Link to={"/api/v1.0/dashboards/" + selected.uuid + "/public-image"} >
                                                    Pre-rendered link
                                                </Link>
                                            </div>)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {Object.keys(selected.tags).length !== 0 &&
                        <div className="white_box charts">
                            <div className="row">
                                <div className="col-12">
                                    <p className="data_source_text">
                                        {this.renderTags(selected.tags)}
                                    </p>
                                </div>
                            </div>
                        </div>
                    }

                    {selected.active && <div className="row">
                        <div className="col-12">
                            <div className="active_data">
                                <h3 className="table_header">Quick view</h3>
                            </div>
                        </div>
                    </div>}

                    {selected.active && <div className="charts">
                        <Dashboard
                            dash_data={this.state.selected_data}
                            dash_definition={this.state.selected_layout}
                            range_change={null} show_filter_bar={true}
                            history={this.props.history}
                        />
                    </div>}
                </div>
            );
        }
    }

    render() {
        return (
            <ResourcesLayout id="admin-dashboards">
                <ResourcesLayout.Header
                    title={
                        <h2>Dashboards ({this.state.dashboards.length})</h2>
                    }
                    buttons={
                        <React.Fragment>
                            {
                                this.state.selected &&
                                <Link
                                    to={"/admin/dashboard_edit/" + this.state.selected.uuid}
                                    className="btn_toolbar btn_edit">
                                    Edit
                                </Link>
                            }
                            {
                                this.state.selected &&
                                <Link
                                    to={"/admin/dashboard_edit/" + this.state.selected.uuid + "?clone=true"}
                                    className="btn_toolbar btn_edit">
                                    Clone
                                </Link>
                            }
                            {
                                this.state.selected &&
                                <Link
                                    to={"/dashboard/" + this.state.selected.uuid}
                                    className="btn_toolbar btn_preview">Preview</Link>
                            }
                            <Link to="/admin/dashboard_new" className="btn_toolbar btn_add_data">Add new</Link>
                        </React.Fragment>
                    }
                />
                <ResourcesLayout.Main>
                    <ResourcesLayout.List>
                        {this.state.selected && <DashboardList
                            dashboards={this.state.dashboards}
                            selected={this.state.selected}
                            handleDashboardClick={(dashboard) => { this.handleDashboardClick(dashboard); }} /> }
                    </ResourcesLayout.List>
                    <ResourcesLayout.Content>
                        {
                            this.state.selected_layout && this.state.selected_data &&
                            (this.showReportDetails())
                        }
                    </ResourcesLayout.Content>
                </ResourcesLayout.Main>
            </ResourcesLayout>
        );
    }
};

export default Dashboards;
