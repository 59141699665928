// @flow

// main imports
import React, { Component } from "react";
import { getBackend as getBackend2 } from "../../lib/backend/Backend2";
import { downloadAndOpenFile } from "../../lib/Backend.rest";
import * as t from "../../lib/backend/common.generated.types";
import { waitForJob, fullSimulation, fullInsights, calculateKpis } from "../../lib/BusinessLogic";

import ErrorComponent from "../ErrorComponent";

// defining types
type Props = {}
type State = {
    addMissingDatasourcesEnabled: boolean,
    addMissingDatasourcesTitle: string,
    anonymizeEnabled: boolean,
    anonymizeTitle: string,
    logFiles: t.ILogFileItem[],
    message: string,
    optimizeJobStorageEnabled: boolean,
    optimizeJobStorageTitle: string,
    purgeUnconfirmedStockRequirementsEnabled: boolean,
    purgeUnconfirmedStockRequirementsTitle: string,
    refreshDashboardsEnabled: boolean,
    refreshDashboardsTitle: string,
    refreshDeleteDanglingReportsEnabled: boolean,
    refreshDeleteDanglingReportsTitle: string,
    refreshDeleteOldReportsEnabled: boolean,
    refreshDeleteOldReportsTitle: string,
    runRecalculateInsightsEnabled: boolean,
    runRecalculateInsightsTitle: string,
    runRecomputeKPIsEnabled: boolean,
    runRecomputeKPIsTitle: string,
    runUnconfirmedStockEnabled: boolean,
    runUnconfirmedStockTitle: string,
    runUpdateProducedAvailableEnabled: boolean,
    runUpdateProducedAvailableTitle: string,
    runSimulationEnabled: boolean,
    runSimulationTitle: string,
    runChecksCtrl: any,
    runChecksEnabled: boolean,
    runPwdPolicyEnabled: boolean,
    runPwdPolicyTitle: string,
    showLogFilesEnabled: boolean,
    showLogFilesTitle: string
}

/**
 * Displaying actions that administrator can perform.
 */
class ManufacturingAdmin extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        const state: State = {
            addMissingDatasourcesEnabled: true,
            addMissingDatasourcesTitle: "",
            anonymizeEnabled: true,
            anonymizeTitle: "",
            optimizeJobStorageEnabled: true,
            optimizeJobStorageTitle: "",
            refreshDashboardsEnabled: true,
            refreshDashboardsTitle: "",
            refreshDeleteDanglingReportsEnabled: true,
            refreshDeleteDanglingReportsTitle: "",
            refreshDeleteOldReportsEnabled: true,
            refreshDeleteOldReportsTitle: "",
            purgeUnconfirmedStockRequirementsEnabled: true,
            purgeUnconfirmedStockRequirementsTitle: "",
            showLogFilesTitle: "",
            message: "",
            logFiles: [],
            showLogFilesEnabled: true,
            runChecksEnabled: true,
            runChecksCtrl: (<span></span>),
            runPwdPolicyEnabled: true,
            runPwdPolicyTitle: "",
            runRecalculateInsightsEnabled: true,
            runRecalculateInsightsTitle: "",
            runRecomputeKPIsEnabled: true,
            runRecomputeKPIsTitle: "",
            runUnconfirmedStockEnabled: true,
            runUnconfirmedStockTitle: "",
            runUpdateProducedAvailableEnabled: true,
            runUpdateProducedAvailableTitle: "",
            runSimulationEnabled: true,
            runSimulationTitle: ""
        };
        this.state = state;
    }

    /**
     * Handler for clicking the "Apply password policy" button.
     */
    async handleApplyPwdPolicy() {
        this.setState({
            runPwdPolicyEnabled: false,
            runPwdPolicyTitle: "Applying passwor dpolicy..."
        });
        try {
            await getBackend2().users.applyPasswordPolicy({});
            this.setState({
                runPwdPolicyEnabled: true,
                runPwdPolicyTitle: "done"
            });
        } catch (err) {
            this.setState({
                runPwdPolicyEnabled: true,
                runPwdPolicyTitle: "Error: " + err.message
            });
        }
    }

    /**
     * Handler for clicking the "Show log files" button.
     */
    async handleLoadLogFiles() {
        this.setState({
            logFiles: [],
            showLogFilesEnabled: false,
            showLogFilesTitle: "Loading log files..."
        });
        try {
            const res = await getBackend2().common.getLogFiles({});
            this.setState({
                showLogFilesEnabled: false,
                logFiles: res.files,
                showLogFilesTitle: ""
            });
        } catch (err) {
            this.setState({
                logFiles: [],
                showLogFilesEnabled: true,
                showLogFilesTitle: "Error: " + err.message
            });
        }
    }

    /**
     * Handler for clicking the "Optimize" button.
     */
    async handleOptimizeJobStorage() {
        this.setState({
            optimizeJobStorageEnabled: false,
            optimizeJobStorageTitle: "Waiting for server to finish..."
        });
        try {
            await getBackend2().common.optimizeJobFileStorage({});
            this.setState({
                optimizeJobStorageEnabled: true,
                optimizeJobStorageTitle: "Done."
            });
        } catch (err) {
            this.setState({
                optimizeJobStorageEnabled: true,
                optimizeJobStorageTitle: "Error: " + err.message
            });
        }
    }

    /**
     * Handler for clicking the "Add missing datasources" button.
     */
    async handleAddMissingDatasources() {
        this.setState({
            addMissingDatasourcesEnabled: false,
            addMissingDatasourcesTitle: "Waiting for server to finish..."
        });
        try {
            const res = await getBackend2().manufacturing.populateDatasources({});
            this.setState({
                addMissingDatasourcesEnabled: true,
                addMissingDatasourcesTitle: "Done, added " + res.count + " new dashboards."
            });
        } catch (err) {
            this.setState({
                addMissingDatasourcesEnabled: true,
                addMissingDatasourcesTitle: "Error: " + err.message
            });
        }
    }

    /**
     * Handler for clicking the "Refresh dashboards" button.
     */
    async handleRefreshDashboards() {
        this.setState({
            refreshDashboardsEnabled: false,
            refreshDashboardsTitle: "Waiting for server to finish..."
        });
        try {
            const res = await getBackend2().manufacturing.populateDashboards({});
            this.setState({
                refreshDashboardsEnabled: true,
                refreshDashboardsTitle: "Done, added " + res.count + " new dashboards."
            });
        } catch (err) {
            this.setState({
                refreshDashboardsEnabled: true,
                refreshDashboardsTitle: "Error: " + err.message
            });
        }
    }

    /**
     * Handler for clicking the "Run checks" button.
     */
    async handleRunChecks() {
        this.setState({
            runChecksEnabled: false,
            runChecksCtrl: (<span>Waiting for server to finish...</span>)
        });
        try {
            const { token } = await getBackend2().manufacturing.performChecks({ only_failed_checks: false });
            const data: any = await waitForJob(getBackend2(), token, true, true);
            let all_passed = data.results.filter(test => test.passed).length;
            let res = [
                (<div key={"check_title"} className="space10">Done, ran {data.all_checks} check(s), {all_passed} passed.</div>)
            ];
            data.results.forEach((test, i) => {
                const cssClass = "space10 check-result " + (test.passed ? "check-result-ok" : "check-result-error");
                res.push(<div key={"check_index_" + i} className={cssClass}>
                    <div className="bold">
                        <span> {test.title} </span>
                    </div>
                    <div className="light">
                        <span> {test.description} </span>
                    </div>
                </div>);
            });
            this.setState({
                runChecksEnabled: true,
                runChecksCtrl: res
            });
        } catch (err) {
            this.setState({
                runChecksEnabled: true,
                runChecksCtrl: (<span>Error: {err}</span>)
            });
        }
    }

    async handleDownloadModel(type: "planning" | "batch") {
        try {
            const token = await getBackend2().reports.getModelFileToken({ type });
            downloadAndOpenFile("/direct-file/" + token.token);
        } catch (e) {
            this.setState({ message: e.message });
        }
    }

    async handleUpdatePlanningModel() {
        await getBackend2().manufacturing.updatePlanningModel({});
    }

    async handleDeleteDanglingReports(perform_delete: boolean) {
        this.setState({
            refreshDeleteDanglingReportsEnabled: false,
            refreshDeleteDanglingReportsTitle: "Waiting for server to finish..."
        });
        try {
            const res = await getBackend2().reports.cleanDanglingReportFiles({ perform_delete });

            this.setState({
                refreshDeleteDanglingReportsEnabled: true,
                refreshDeleteDanglingReportsTitle: perform_delete
                    ? `Done, deleted ${res.deleted_files_count}, remaining ${res.remaining_files_count}.`
                    : `Finished with check, ${res.deleted_files_count} for deletion, ${res.remaining_files_count} to remain.`
            });
        } catch (err) {
            this.setState({
                refreshDeleteDanglingReportsEnabled: true,
                refreshDeleteDanglingReportsTitle: "Error: " + err.message
            });
        }
    }

    async handlePurgeStockRequirements() {
        this.setState({
            purgeUnconfirmedStockRequirementsEnabled: false,
            purgeUnconfirmedStockRequirementsTitle: "Waiting for server to finish..."
        });
        try {
            await getBackend2().manufacturing.runPurgeUnconfirmedStockRequirements({});
            this.setState({
                purgeUnconfirmedStockRequirementsEnabled: true,
                purgeUnconfirmedStockRequirementsTitle: `Done.`
            });
        } catch (err) {
            this.setState({
                purgeUnconfirmedStockRequirementsEnabled: true,
                purgeUnconfirmedStockRequirementsTitle: "Error: " + err.message
            });
        }
    }

    async handleDeleteOldReports(perform_delete: boolean) {
        this.setState({
            refreshDeleteOldReportsEnabled: false,
            refreshDeleteOldReportsTitle: "Waiting for server to finish..."
        });
        try {
            const res = await getBackend2().reports.deleteReports({ perform_delete });
            this.setState({
                refreshDeleteOldReportsEnabled: true,
                refreshDeleteOldReportsTitle: perform_delete
                    ? `Done, deleted ${res.deleted_count} reports.`
                    : `Finished with check, ${res.deleted_count} for deletion.`
            });
        } catch (err) {
            this.setState({
                refreshDeleteOldReportsEnabled: true,
                refreshDeleteOldReportsTitle: "Error: " + err.message
            });
        }
    }

    async handleRunSimulation() {
        this.setState({
            runSimulationEnabled: false,
            runSimulationTitle: "Waiting for server to finish..."
        });
        try {
            await fullSimulation();
            this.setState({
                runSimulationEnabled: true,
                runSimulationTitle: `Simulation triggered.`
            });
        } catch (err) {
            this.setState({
                runSimulationEnabled: true,
                runSimulationTitle: "Error: " + err.message
            });
        }
    }

    async handleRecomputeInsights() {
        this.setState({
            runRecalculateInsightsEnabled: false,
            runRecalculateInsightsTitle: "Waiting for server to finish..."
        });
        try {
            await fullInsights();
            this.setState({
                runRecalculateInsightsEnabled: true,
                runRecalculateInsightsTitle: `Recompute of insights finished.`
            });
        } catch (err) {
            this.setState({
                runRecalculateInsightsEnabled: true,
                runRecalculateInsightsTitle: "Error: " + err.message
            });
        }
    }

    async handleRecomputeKPIs() {
        this.setState({
            runRecomputeKPIsEnabled: false,
            runRecomputeKPIsTitle: "Waiting for server to finish..."
        });
        try {
            await calculateKpis();
            this.setState({
                runRecomputeKPIsEnabled: true,
                runRecomputeKPIsTitle: `Recompute of KPIs triggered.`
            });
        } catch (err) {
            this.setState({
                runRecomputeKPIsEnabled: true,
                runRecomputeKPIsTitle: "Error: " + err.message
            });
        }
    }

    async handleUnconfirmedStock() {
        this.setState({
            runUnconfirmedStockEnabled: false,
            runUnconfirmedStockTitle: "Waiting for server to finish..."
        });
        try {
            await getBackend2().manufacturing.updateUnconfirmedStock({});
            this.setState({
                runUnconfirmedStockEnabled: true,
                runUnconfirmedStockTitle: `Unconfirmed stock triggered.`
            });
        } catch (err) {
            this.setState({
                runUnconfirmedStockEnabled: true,
                runUnconfirmedStockTitle: "Error: " + err.message
            });
        }
    }

    async handleUpdateProducedAvailable() {
        this.setState({
            runUpdateProducedAvailableEnabled: false,
            runUpdateProducedAvailableTitle: "Waiting for server to finish..."
        });
        try {
            await getBackend2().manufacturing.updateProducedAvailableIn({});
            this.setState({
                runUpdateProducedAvailableEnabled: true,
                runUpdateProducedAvailableTitle: `Update produced available triggered.`
            });
        } catch (err) {
            this.setState({
                runUpdateProducedAvailableEnabled: true,
                runUpdateProducedAvailableTitle: "Error: " + err.message
            });
        }
    }

    renderError = () => {
        if (this.state.message) {
            return <ErrorComponent msg={this.state.message} type="error" />;
        }
        return null;
    }

    /**
     * Rendering JSX for current component.
     */
    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="white_box space10i">
                            <h2>Add missing datasources</h2>
                            <div>
                                Inspects manufacturing database and generates
                                all missing datasources in streaming component.
                            </div>
                            <div>
                                {
                                    this.state.addMissingDatasourcesEnabled &&
                                    (<button className="btn" onClick={() => this.handleAddMissingDatasources()}>
                                        Add all missing datasources
                                    </button>)
                                }
                                <div>
                                    {this.state.addMissingDatasourcesTitle}
                                </div>
                            </div>
                        </div>
                        <div className="white_box space10i">
                            <h2>Add missing dashboards</h2>
                            <div>
                                Inspects manufacturing database and re-generates
                                all manufacturing dashboards. Line dashboards will be
                                generated if at least one order for that line exists.
                            </div>
                            <div>
                                {
                                    this.state.refreshDashboardsEnabled &&
                                    (<button className="btn" onClick={() => this.handleRefreshDashboards()}>
                                        Refresh dashboards
                                    </button>)
                                }
                                <div>
                                    {this.state.refreshDashboardsTitle}
                                </div>
                            </div>
                        </div>
                        <div className="white_box space10i">
                            <h2>Delete dangling report files</h2>
                            <div>
                                Triggers clean-up operation on simulation-report file on server that
                                have no associated job record for them and are thus useless.
                            </div>
                            <div>
                                {
                                    this.state.refreshDeleteDanglingReportsEnabled &&
                                    (<button className="btn" onClick={() => this.handleDeleteDanglingReports(false)}>
                                        Check files
                                    </button>)
                                }
                                {
                                    this.state.refreshDeleteDanglingReportsEnabled &&
                                    (<button className="btn btn_margin_left" onClick={() => this.handleDeleteDanglingReports(true)}>
                                        Delete files
                                    </button>)
                                }
                                <div>
                                    {this.state.refreshDeleteDanglingReportsTitle}
                                </div>
                            </div>
                        </div>
                        <div className="white_box space10i">
                            <h2>Delete old report files</h2>
                            <div>
                                Triggers clean-up operation on simulation-reports that should be
                                deleted according to retention policy.
                            </div>
                            <div>
                                {
                                    this.state.refreshDeleteOldReportsEnabled &&
                                    (<button className="btn" onClick={() => this.handleDeleteOldReports(false)}>
                                        Check old reports
                                    </button>)
                                }
                                {
                                    this.state.refreshDeleteOldReportsEnabled &&
                                    (<button className="btn btn_margin_left" onClick={() => this.handleDeleteOldReports(true)}>
                                        Delete old reports
                                    </button>)
                                }
                                <div>
                                    {this.state.refreshDeleteOldReportsTitle}
                                </div>
                            </div>
                        </div>
                        <div className="white_box space10i">
                            <h2>Delete dangling stock-requirement records</h2>
                            <div>
                                Triggers clean-up operation for stock-requirement records
                                that have not been confirmed and are older than 24 hours.
                            </div>
                            <div>
                                {
                                    this.state.purgeUnconfirmedStockRequirementsEnabled &&
                                    (<button className="btn btn_margin_left" onClick={() => this.handlePurgeStockRequirements()}>
                                        Delete files
                                    </button>)
                                }
                                <div>
                                    {this.state.purgeUnconfirmedStockRequirementsTitle}
                                </div>
                            </div>
                        </div>
                        <div className="white_box space10i">
                            <h2>Apply password policy</h2>
                            <div>
                                Triggers application of password policy - this will mark users with tag that forces them to change password if it expired.
                            </div>
                            <div>
                                {
                                    this.state.runPwdPolicyEnabled &&
                                    (<button className="btn" onClick={() => this.handleApplyPwdPolicy()}>
                                        Apply password policy
                                    </button>)
                                }
                                <div>
                                    {this.state.runPwdPolicyTitle}
                                </div>
                            </div>
                        </div>
                        <div className="white_box space10i">
                            <h2>Optimize job-file storage</h2>
                            <div>
                                Triggers optimization of file-system location where job results are stored.
                            </div>
                            <div>
                                {
                                    this.state.optimizeJobStorageEnabled &&
                                    (<button className="btn" onClick={() => this.handleOptimizeJobStorage()}>
                                        Optimize
                                    </button>)
                                }
                                <div>
                                    {this.state.optimizeJobStorageTitle}
                                </div>
                            </div>
                        </div>
                        <div className="white_box space10i">
                            <h2>Log files</h2>
                            <div>
                                Shows log files.
                            </div>
                            <div>
                                {
                                    this.state.showLogFilesEnabled &&
                                    (<button className="btn" onClick={() => this.handleLoadLogFiles()}>
                                        Show log files
                                    </button>)
                                }
                                <div>
                                    {this.state.showLogFilesTitle}
                                </div>
                                {this.state.logFiles.map(x => <div>
                                    <a
                                        href="#"
                                        onClick={() => { downloadAndOpenFile("/direct-file/" + x.token); }}
                                    > {x.name}</a>
                                </div>)
                                }
                            </div>
                        </div>
                        <div className="white_box space10i">
                            <h2>Run checks</h2>
                            <div>
                                Runs internal checks that monitor manufacturing module execution and status.
                            </div>
                            <div>
                                <div>
                                    {
                                        this.state.runChecksEnabled &&
                                        (<button className="btn" onClick={() => this.handleRunChecks()}>
                                            Run checks
                                        </button>)
                                    }
                                </div>
                                <div>
                                    {this.state.runChecksCtrl}
                                </div>
                            </div>
                        </div>
                        <div className="white_box space10i">
                            <h2>Trigger processings</h2>
                            <div>
                                Runs background processings.
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div>
                                            {
                                                this.state.runSimulationEnabled &&
                                                (<button className="btn" onClick={() => this.handleRunSimulation()}>
                                                    Run simulation
                                                </button>)
                                            }
                                        </div>
                                        <div>
                                            {this.state.runSimulationTitle}
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div>
                                            {
                                                this.state.runRecalculateInsightsEnabled &&
                                                (<button className="btn" onClick={() => this.handleRecomputeInsights()}>
                                                    Run insight recomputation
                                                </button>)
                                            }
                                        </div>
                                        <div>
                                            {this.state.runRecalculateInsightsTitle}
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div>
                                            {
                                                this.state.runRecomputeKPIsEnabled &&
                                                (<button className="btn" onClick={() => this.handleRecomputeKPIs()}>
                                                    Run KPIs recomputation
                                                </button>)
                                            }
                                        </div>
                                        <div>
                                            {this.state.runRecomputeKPIsTitle}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3">
                                        <div>
                                            {
                                                this.state.runUnconfirmedStockEnabled &&
                                                (<button className="btn" onClick={() => this.handleUnconfirmedStock()}>
                                                    Run unconfirmed stock
                                                </button>)
                                            }
                                        </div>
                                        <div>
                                            {this.state.runUnconfirmedStockTitle}
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div>
                                            {
                                                this.state.runUpdateProducedAvailableEnabled &&
                                                (<button className="btn" onClick={() => this.handleUpdateProducedAvailable()}>
                                                    Run update produced available
                                                </button>)
                                            }
                                        </div>
                                        <div>
                                            {this.state.runUpdateProducedAvailableTitle}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.renderError()}
                        <div className="white_box space10i">
                            <h2>Batch and Planning models</h2>
                            <div>
                                <button className="btn" onClick={() => this.handleDownloadModel("planning")}>
                                    Download planning model
                                </button>
                                <button className="btn ml-2" onClick={() => this.handleDownloadModel("batch")}>
                                    Download batch model
                                </button>
                                <button className="btn ml-2" onClick={() => this.handleUpdatePlanningModel()}>
                                    Update planning model
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ManufacturingAdmin;
