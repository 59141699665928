// @flow
import type { PayloadAction } from "../types";

export const SET_RESOURCE_EDIT_FILTERS: "resource_edit_filters" = "resource_edit_filters";

// ResourceEditFilters
export type ResourceEditFiltersState = {
    plant_uuid: string,
    line_group_uuid: string,
    produced_consumed: "produced" | "consumed",
    title_filter: string,
    lines_title_filter: string,
    stock_locations_title_filter: string
}

export const ref_initial_state: ResourceEditFiltersState = {
    plant_uuid: "",
    line_group_uuid: "",
    produced_consumed: "produced",
    title_filter: "",
    lines_title_filter: "",
    stock_locations_title_filter: ""
}

export type CompressedResourceEditFilters = {
    ref_pu: $PropertyType<ResourceEditFiltersState, "plant_uuid">,
    ref_lgu: $PropertyType<ResourceEditFiltersState, "line_group_uuid">,
    ref_pc: $PropertyType<ResourceEditFiltersState, "produced_consumed">,
    ref_tf: $PropertyType<ResourceEditFiltersState, "title_filter">,
    ref_ltf: $PropertyType<ResourceEditFiltersState, "lines_title_filter">,
    ref_sltf: $PropertyType<ResourceEditFiltersState, "stock_locations_title_filter">
}

export const ResourceEditFiltersCompressed = {
    plant_uuid: "ref_pu",
    line_group_uuid: "ref_lgu",
    produced_consumed: "ref_pc",
    title_filter: "ref_tf",
    lines_title_filter: "ref_ltf",
    stock_locations_title_filter: "ref_sltf"
}

export type SetResourceEditFiltersAction = PayloadAction<typeof SET_RESOURCE_EDIT_FILTERS, ResourceEditFiltersState>;

export const compressResourceEditFiltersParams = (data: ResourceEditFiltersState): CompressedResourceEditFilters => {
    return {
        ref_pu: data.plant_uuid,
        ref_lgu: data.line_group_uuid,
        ref_pc: data.produced_consumed,
        ref_tf: data.title_filter,
        ref_ltf: data.lines_title_filter,
        ref_sltf: data.stock_locations_title_filter
    }
}

const getParam = (url_params: Object, param: string) => {
    return url_params.get(ResourceEditFiltersCompressed[param]) || ref_initial_state[param];
}

export const diffREFFiltersAndURLParams = (selected_ref: ResourceEditFiltersState, hash: string): ?ResourceEditFiltersState => {
    if (!hash) { hash = window.location.hash; }
    const url_params = new URLSearchParams(hash.split("#")[1]);

    const new_filters: $Shape<ResourceEditFiltersState> = {};

    let has_differences = false;
    const param_pu = getParam(url_params, "plant_uuid");
    if (selected_ref.plant_uuid !== param_pu) {
        has_differences = true;
        new_filters.plant_uuid = param_pu;
    } else if (param_pu) {
        new_filters.plant_uuid = param_pu;
    }
    
    const param_lgu = getParam(url_params, "line_group_uuid");
    if (selected_ref.line_group_uuid !== param_lgu) {
        has_differences = true;
        new_filters.line_group_uuid = param_lgu;
    } else if (param_lgu) {
        new_filters.line_group_uuid = param_lgu;
    }
    
    const param_pc = getParam(url_params, "produced_consumed");
    if (selected_ref.produced_consumed !== param_pc) {
        has_differences = true;
        new_filters.produced_consumed = param_pc;
    } else if (param_pc) {
        new_filters.produced_consumed = param_pc;
    }

    const param_tf = getParam(url_params, "title_filter");
    if (selected_ref.title_filter !== param_tf) {
        has_differences = true;
        new_filters.title_filter = param_tf;
    } else if (param_tf) {
        new_filters.title_filter = param_tf;
    }

    const param_ltf = getParam(url_params, "lines_title_filter");
    if (selected_ref.lines_title_filter !== param_ltf) {
        has_differences = true;
        new_filters.lines_title_filter = param_ltf;
    } else if (param_ltf) {
        new_filters.lines_title_filter = param_ltf;
    }

    const param_sltf = getParam(url_params, "stock_locations_title_filter");
    if (selected_ref.stock_locations_title_filter !== param_sltf) {
        has_differences = true;
        new_filters.stock_locations_title_filter = param_sltf;
    } else if (param_sltf) {
        new_filters.stock_locations_title_filter = param_sltf;
    }

    if (has_differences) {
        return new_filters;
    }
}

export const resourceEditFiltersReducer = (state: ResourceEditFiltersState, action: SetResourceEditFiltersAction) => {
    if (!state) state = { ...ref_initial_state };

    if (action.type === SET_RESOURCE_EDIT_FILTERS) {
        state = {
            ...ref_initial_state,
            ...action.data
        };
    }

    return state;
}
