// @flow

/************************************************************************************/
/* WARNING: This file is generated automatically. Don't edit it by hand             */
/************************************************************************************/

import * as api from "./restApi.js";
import * as t from "./manufacturing2.generated.types.js";

function enc(s: any): string { return encodeURIComponent("" + s); }

export interface IManufacturing2 {
    /** Given plant external id and interval (two timestamps) update accounted_for='t' for all productions in that range */
    updateAccountedProductions(req: t.IUpdateAccountedProductionsReq): Promise<t.IUpdateAccountedProductionsRes>;

    /** Updates unconfirmed stock table */
    updateUnconfirmedStock(req: t.IUpdateUnconfirmedStockReq): Promise<t.IUpdateUnconfirmedStockRes>;

    /** Updates unconfirmed stock table */
    updateUnconfirmedStockAsync(req: t.IUpdateUnconfirmedStockAsyncReq): Promise<t.IUpdateUnconfirmedStockAsyncRes>;

    /** Gets details behind unconfirmed stock value */
    getUnconfirmedStockDetails(req: t.IGetUnconfirmedStockDetailsReq): Promise<t.IGetUnconfirmedStockDetailsRes>;

    /** Sets line-group permission for given user */
    setLineGroupsForUser(req: t.ISetLineGroupsForUserReq): Promise<t.ISetLineGroupsForUserRes>;

    /** Get stock levels for materials consumed and produced by lines in the line-group */
    getStockStatus(req: t.IGetStockStatusReq): Promise<t.IGetStockStatusRes>;

    /** Get stock levels for materials */
    getStockStatusForMaterials(req: t.IGetStockStatusForMaterialsReq): Promise<t.IGetStockStatusForMaterialsRes>;

    /** Get stock levels for materials consumed and produced by lines in the line-group */
    getStockRequirements(req: t.IGetStockRequirementsReq): Promise<t.IGetStockRequirementsRes>;

    /** Get stock requirements for planning table */
    getStockRequirementsShifts(req: t.IGetStockRequirementsShiftsReq): Promise<t.IGetStockRequirementsShiftsRes>;

    /** Get stock requirements for planning table */
    getSingleStockRequirementsShifts(req: t.IGetSingleStockRequirementsShiftsReq): Promise<t.IGetSingleStockRequirementsShiftsRes>;

    /** Update comment for specfic stock requirement */
    updateStockRequirementsComment(req: t.IUpdateStockRequirementsCommentReq): Promise<t.IUpdateStockRequirementsCommentRes>;

    /** Gets stock-forecast for materials that are processed by the given group */
    getMaterialForecast(req: t.IGetMaterialForecastReq): Promise<t.IGetMaterialForecastRes>;

    /** Gets material stock-forecast for given shifts */
    getMaterialForecastForShifts(req: t.IGetMaterialForecastForShiftsReq): Promise<t.IGetMaterialForecastForShiftsRes>;

    /** Adds a line to the line-group */
    setLinesForLinegroup(req: t.ISetLinesForLinegroupReq): Promise<t.ISetLinesForLinegroupRes>;

    /** Delete the line-group */
    deleteLineGroup(req: t.IDeleteLineGroupReq): Promise<t.IDeleteLineGroupRes>;

    /** Update the line-group */
    updateLineGroup(req: t.IUpdateLineGroupReq): Promise<t.IUpdateLineGroupRes>;

    /** Returns all line-groups for current org */
    getLineGroups(req: t.IGetLineGroupsReq): Promise<t.IGetLineGroupsRes>;

    /** Create a new line-group for current org */
    createLineGroup(req: t.ICreateLineGroupReq): Promise<t.ICreateLineGroupRes>;

    /** Get line-material-process matrix for the materials produced in the line group */
    getLineGroupMatrix(req: t.IGetLineGroupMatrixReq): Promise<t.IGetLineGroupMatrixRes>;

    /** Update which cells in line-mprocess-process matrix can be used by automatic planning */
    setLineGroupMatrix(req: t.ISetLineGroupMatrixReq): Promise<t.ISetLineGroupMatrixRes>;

    /** Update existing plant new plant */
    updatePlant(req: t.IUpdatePlantReq): Promise<t.IUpdatePlantRes>;

    /** Update existing organisation */
    updateCurrentOrganisation(req: t.IUpdateCurrentOrganisationReq): Promise<t.IUpdateCurrentOrganisationRes>;

    /** Fetches organisation tags */
    getCurrentOrganisation(req: t.IGetCurrentOrganisationReq): Promise<t.IGetCurrentOrganisationRes>;

    /** Gets all plants for user's org */
    getPlants(req: t.IGetPlantsReq): Promise<t.IGetPlantsRes>;

    /** Insert new plant */
    insertPlant(req: t.IInsertPlantReq): Promise<t.IInsertPlantRes>;

    /** Gets all tags of a plant */
    getPlantTags(req: t.IGetPlantTagsReq): Promise<t.IGetPlantTagsRes>;

    /** Gets hash for given line */
    getLineHash(req: t.IGetLineHashReq): Promise<t.IGetLineHashRes>;

    /** Gets shifts for multiple lines */
    getLineShiftsEx(req: t.IGetLineShiftsExReq): Promise<t.IGetLineShiftsExRes>;

    /** Gets shifts for given line */
    getLineShifts(req: t.IGetLineShiftsReq): Promise<t.IGetLineShiftsRes>;

    /** Upserts shifts for given line */
    updateLineShifts(req: t.IUpdateLineShiftsReq): Promise<t.IUpdateLineShiftsRes>;

    /** Updates orders for given line */
    updateLineOrders(req: t.IUpdateLineOrdersReq): Promise<t.IUpdateLineOrdersRes>;

    /** Deletes all orders for given line */
    deleteLineOrders(req: t.IDeleteLineOrdersReq): Promise<t.IDeleteLineOrdersRes>;

    /** Gets planning-data for given line */
    getLinePlanningData(req: t.IGetLinePlanningDataReq): Promise<t.IGetLinePlanningDataRes>;

    /** Gets planning-data for given lines */
    getPlanningData(req: t.IGetPlanningDataReq): Promise<t.IGetPlanningDataRes>;

    /** Get list of open orders that can be produced on the line */
    getLinePossibleOrders(req: t.IGetLinePossibleOrdersReq): Promise<t.IGetLinePossibleOrdersRes>;

    /** Updates given line */
    updateLine(req: t.IUpdateLineReq): Promise<t.IUpdateLineRes>;

    /** Deletes given line */
    deleteLine(req: t.IDeleteLineReq): Promise<t.IDeleteLineRes>;

    /** Gets all lines for user's org */
    getLines(req: t.IGetLinesReq): Promise<t.IGetLinesRes>;

    /** Inserts new line */
    insertLine(req: t.IInsertLineReq): Promise<t.IInsertLineRes>;

    /** Disable order bom inputs */
    disableBomInputs(req: t.IDisableBomInputsReq): Promise<t.IDisableBomInputsRes>;

    /** Updates given shifts */
    updateShifts(req: t.IUpdateShiftsReq): Promise<t.IUpdateShiftsRes>;

    /** Get stock levels for materials consumed and produced by lines in the line-group */
    getSubcontractingDetails(req: t.IGetSubcontractingDetailsReq): Promise<t.IGetSubcontractingDetailsRes>;

    /** Get stock levels for materials consumed and produced by lines in the line-group */
    getSubcontractingQuantities(req: t.IGetSubcontractingQuantitiesReq): Promise<t.IGetSubcontractingQuantitiesRes>;

    /** Triggers population of missing dashboards */
    populateDashboards(req: t.IPopulateDashboardsReq): Promise<t.IPopulateDashboardsRes>;

    /** Triggers population of missing datasources */
    populateDatasources(req: t.IPopulateDatasourcesReq): Promise<t.IPopulateDatasourcesRes>;

    /** Gets manufacturing statistics for user's org */
    getStats(req: t.IGetStatsReq): Promise<t.IGetStatsRes>;

    /** Gets shifts timetable with people */
    getPeopleShiftsTable(req: t.IGetPeopleShiftsTableReq): Promise<t.IGetPeopleShiftsTableRes>;

    /** Gets shifts timetable with people */
    getPeopleShiftsSimple(req: t.IGetPeopleShiftsSimpleReq): Promise<t.IGetPeopleShiftsSimpleRes>;

    /** Gets insight types */
    getInsightTypes(req: t.IGetInsightTypesReq): Promise<t.IGetInsightTypesRes>;

    /** Gets insights with specified criteria */
    getInsights(req: t.IGetInsightsReq): Promise<t.IGetInsightsRes>;

    /** Triggers re-calculation of insights */
    recalculateInsights(req: t.IRecalculateInsightsReq): Promise<t.IRecalculateInsightsRes>;

    /** Retrieves actions for given insight */
    getInsightActions(req: t.IGetInsightActionsReq): Promise<t.IGetInsightActionsRes>;

    /** Gets the first version of permanent insight */
    getPermanentInsightFirstVersion(req: t.IGetPermanentInsightFirstVersionReq): Promise<t.IGetPermanentInsightFirstVersionRes>;

    /** Sets tags of permanent insight */
    setPermanentInsightTags(req: t.ISetPermanentInsightTagsReq): Promise<t.ISetPermanentInsightTagsRes>;

    /** Gets permanent insight */
    getPermanentInsight(req: t.IGetPermanentInsightReq): Promise<t.IGetPermanentInsightRes>;

    /** Creates permanent insight */
    createPermanentInsight(req: t.ICreatePermanentInsightReq): Promise<t.ICreatePermanentInsightRes>;

    /** Searches permanent insight by given criteria */
    searchPermanentInsights(req: t.ISearchPermanentInsightsReq): Promise<t.ISearchPermanentInsightsRes>;

    /** Retrieve current downtimes happening in the given plant */
    getCurrentDowntimes(req: t.IGetCurrentDowntimesReq): Promise<t.IGetCurrentDowntimesRes>;

    /** Updates an order */
    updateOrder(req: t.IUpdateOrderReq): Promise<t.IUpdateOrderRes>;

    /** Deletes an order */
    deleteOrder(req: t.IDeleteOrderReq): Promise<t.IDeleteOrderRes>;

    /** Return list of lines on which the order can be executed */
    getOrderLines(req: t.IGetOrderLinesReq): Promise<t.IGetOrderLinesRes>;

    /** Return list of lines on which the order can be executed */
    getOperationRelatedLines(req: t.IGetOperationRelatedLinesReq): Promise<t.IGetOperationRelatedLinesRes>;

    /** Updates orders */
    updateOrders(req: t.IUpdateOrdersReq): Promise<t.IUpdateOrdersRes>;

    /** Heal inconsistent chunks state */
    healChunks(req: t.IHealChunksReq): Promise<t.IHealChunksRes>;

    /** Closes given orders */
    closeOrders(req: t.ICloseOrdersReq): Promise<t.ICloseOrdersRes>;

    /** Gets orders that need to be enriched */
    getOrdersForStockMonitoring(req: t.IGetOrdersForStockMonitoringReq): Promise<t.IGetOrdersForStockMonitoringRes>;

    /** Get input materials */
    getInputMaterials(req: t.IGetInputMaterialsReq): Promise<t.IGetInputMaterialsRes>;

    /** Gets orders */
    searchOrders(req: t.ISearchOrdersReq): Promise<t.ISearchOrdersRes>;

    /** Get order */
    getOrdersSimple(req: t.IGetOrdersSimpleReq): Promise<t.IGetOrdersSimpleRes>;

    /** Get production quantities for the orders */
    getOrdersProduced(req: t.IGetOrdersProducedReq): Promise<t.IGetOrdersProducedRes>;

    /** Get order */
    getOrdersTools(req: t.IGetOrdersToolsReq): Promise<t.IGetOrdersToolsRes>;

    /** Stores updated shift-assignments for people */
    storePeopleShiftAssignments(req: t.IStorePeopleShiftAssignmentsReq): Promise<t.IStorePeopleShiftAssignmentsRes>;

    /** Gets people information */
    getPeople(req: t.IGetPeopleReq): Promise<t.IGetPeopleRes>;

    /** Creates new person */
    createPerson(req: t.ICreatePersonReq): Promise<t.ICreatePersonRes>;

    /** Updates existing person */
    updatePerson(req: t.IUpdatePersonReq): Promise<t.IUpdatePersonRes>;

    /** Gets competences */
    getCompetence(req: t.IGetCompetenceReq): Promise<t.IGetCompetenceRes>;

    /** Upserts specific competence */
    upsertCompetence(req: t.IUpsertCompetenceReq): Promise<t.IUpsertCompetenceRes>;

    /** Deletes specific competence */
    deleteCompetence(req: t.IDeleteCompetenceReq): Promise<t.IDeleteCompetenceRes>;

    /** Gets process information */
    getProcesses(req: t.IGetProcessesReq): Promise<t.IGetProcessesRes>;

    /** Upsert process information */
    upsertProcess(req: t.IUpsertProcessReq): Promise<t.IUpsertProcessRes>;

    /** Deletes process */
    deleteProcess(req: t.IDeleteProcessReq): Promise<t.IDeleteProcessRes>;

    /** Disables processes: sets flag disabled = true in qldb_man.processes table */
    disableProcesses(req: t.IDisableProcessesReq): Promise<t.IDisableProcessesRes>;

    /** Gets anonymization mapping */
    getAnonymizationData(req: t.IGetAnonymizationDataReq): Promise<t.IGetAnonymizationDataRes>;

    /** Trigger computation of full simulation */
    runSimulation(req: t.IRunSimulationReq): Promise<t.IRunSimulationRes>;

    /** Executes retrieval of stock requirements for one material+plant */
    runStockRequirementsSimple(req: t.IRunStockRequirementsSimpleReq): Promise<t.IRunStockRequirementsSimpleRes>;

    /** Executes purging of unconfirmed stock requirements for current org */
    runPurgeUnconfirmedStockRequirements(req: t.IRunPurgeUnconfirmedStockRequirementsReq): Promise<t.IRunPurgeUnconfirmedStockRequirementsRes>;

    /** Creates a plan for given linegroup and applies it */
    runLinegroupPlanningComplete(req: t.IRunLinegroupPlanningCompleteReq): Promise<t.IRunLinegroupPlanningCompleteRes>;

    /** Retrieves planning log for given linegroup */
    getLinegroupPlanningLog(req: t.IGetLinegroupPlanningLogReq): Promise<t.IGetLinegroupPlanningLogRes>;

    /** Creates a plan for given linegroup based on needs computed in the last simulation */
    runLinegroupPlanning(req: t.IRunLinegroupPlanningReq): Promise<t.IRunLinegroupPlanningRes>;

    /** Get planning job status */
    getPlanStatus(req: t.IGetPlanStatusReq): Promise<t.IGetPlanStatusRes>;

    /** Get planning job result */
    getPlanResult(req: t.IGetPlanResultReq): Promise<t.IGetPlanResultRes>;

    /** Detect inconsistent production versions */
    detectInconsistingProdVer(req: t.IDetectInconsistingProdVerReq): Promise<t.IDetectInconsistingProdVerRes>;

    /** Triggers execution of manufacturing checks */
    performChecks(req: t.IPerformChecksReq): Promise<t.IPerformChecksRes>;

    /** Update produced_available_in column for open operations */
    updateProducedAvailableIn(req: t.IUpdateProducedAvailableInReq): Promise<t.IUpdateProducedAvailableInRes>;

    /** Updates tags that control operation stock triggering */
    configureStockTriggering(req: t.IConfigureStockTriggeringReq): Promise<t.IConfigureStockTriggeringRes>;

    /** Create new orders and processes for parallel materials */
    rewireParallelMaterials(req: t.IRewireParallelMaterialsReq): Promise<t.IRewireParallelMaterialsRes>;

    /** Create new orders and processes for parallel materials */
    rewireParallelMaterialsAsync(req: t.IRewireParallelMaterialsAsyncReq): Promise<t.IRewireParallelMaterialsAsyncRes>;

    /** Returns weekly-production report */
    weeklyProduced(req: t.IWeeklyProducedReq): Promise<t.IWeeklyProducedRes>;

    /** DEPRECATED. Delete after 2023-08-01. Creates productions for chunk orders that have completed */
    backflushProductions(req: t.IBackflushProductionsReq): Promise<t.IBackflushProductionsRes>;

    /** Gets materials for a stock location */
    getStockLocationMaterials(req: t.IGetStockLocationMaterialsReq): Promise<t.IGetStockLocationMaterialsRes>;

    /** Set stock level for material at the given stock location */
    updateStockStatus(req: t.IUpdateStockStatusReq): Promise<t.IUpdateStockStatusRes>;

    /** Gets stock locations */
    getStockLocations(req: t.IGetStockLocationsReq): Promise<t.IGetStockLocationsRes>;

    /** Create new stock location */
    createStockLocation(req: t.ICreateStockLocationReq): Promise<t.ICreateStockLocationRes>;

    /** Check if any plant is missing system stock locations and creates them */
    createMissingSystemStockLocations(req: t.ICreateMissingSystemStockLocationsReq): Promise<t.ICreateMissingSystemStockLocationsRes>;

    /** Update the stock location */
    updateStockLocation(req: t.IUpdateStockLocationReq): Promise<t.IUpdateStockLocationRes>;

    /** Gets production for specified line */
    getProduced(req: t.IGetProducedReq): Promise<t.IGetProducedRes>;

    /** Gets productions */
    getProducedAll(req: t.IGetProducedAllReq): Promise<t.IGetProducedAllRes>;

    /** Gets production sums for external operation id */
    productionSum(req: t.IProductionSumReq): Promise<t.IProductionSumRes>;

    /** Gets productions for each external id */
    externalIdProductions(req: t.IExternalIdProductionsReq): Promise<t.IExternalIdProductionsRes>;

    /** Gets kanban data for line */
    getLineKanban(req: t.IGetLineKanbanReq): Promise<t.IGetLineKanbanRes>;

    /** Updates kanban data for line */
    updateLineKanban(req: t.IUpdateLineKanbanReq): Promise<t.IUpdateLineKanbanRes>;

    /** Get production version for given material */
    getProductionVersionForMaterialPlant(req: t.IGetProductionVersionForMaterialPlantReq): Promise<t.IGetProductionVersionForMaterialPlantRes>;

    /** Set production version for given order */
    setProductionVersionForOrder(req: t.ISetProductionVersionForOrderReq): Promise<t.ISetProductionVersionForOrderRes>;

    /** Get compatible production versions for orders */
    getCompatibleProdVers(req: t.IGetCompatibleProdVersReq): Promise<t.IGetCompatibleProdVersRes>;

    /** Get operation-line matrix */
    getOperationLineMatrix(req: t.IGetOperationLineMatrixReq): Promise<t.IGetOperationLineMatrixRes>;

    /** Gets materials whose stock should be monitored */
    getMaterialsForStockMonitoring(req: t.IGetMaterialsForStockMonitoringReq): Promise<t.IGetMaterialsForStockMonitoringRes>;

    /** Gets materials translations */
    getMaterialsTranslations(req: t.IGetMaterialsTranslationsReq): Promise<t.IGetMaterialsTranslationsRes>;

    /** Gets pairs for parent-shadow materials */
    getShadowPairs(req: t.IGetShadowPairsReq): Promise<t.IGetShadowPairsRes>;

    /** Gets materials data via simple query */
    searchMaterialsSimple(req: t.ISearchMaterialsSimpleReq): Promise<t.ISearchMaterialsSimpleRes>;

    /** Gets materials data */
    searchMaterials(req: t.ISearchMaterialsReq): Promise<t.ISearchMaterialsRes>;

    /** Insert new material */
    insertMaterial(req: t.IInsertMaterialReq): Promise<t.IInsertMaterialRes>;

    /** Gets all materials data */
    searchMaterialsAll(req: t.ISearchMaterialsAllReq): Promise<t.ISearchMaterialsAllRes>;

    /** Update material */
    updateMaterial(req: t.IUpdateMaterialReq): Promise<t.IUpdateMaterialRes>;

    /** Update material plant */
    updateMaterialPlant(req: t.IUpdateMaterialPlantReq): Promise<t.IUpdateMaterialPlantRes>;

    /** Update material's tag by input material external ID */
    updateMaterialTagByInputMaterial(req: t.IUpdateMaterialTagByInputMaterialReq): Promise<t.IUpdateMaterialTagByInputMaterialRes>;

    /** Get material's plant level tags */
    getMaterialPlantTags(req: t.IGetMaterialPlantTagsReq): Promise<t.IGetMaterialPlantTagsRes>;

    /** Get stock quantity that is in the process of being produced (has ongoing chunk orders) for the material */
    getStockBeingProduced(req: t.IGetStockBeingProducedReq): Promise<t.IGetStockBeingProducedRes>;

    /** Get list of all parallel materials */
    parallelMaterialsGet(req: t.IParallelMaterialsGetReq): Promise<t.IParallelMaterialsGetRes>;

    /** Get parallel material details */
    parallelMaterialGet(req: t.IParallelMaterialGetReq): Promise<t.IParallelMaterialGetRes>;

    /** Create new parallel material */
    parallelMaterialCreate(req: t.IParallelMaterialCreateReq): Promise<t.IParallelMaterialCreateRes>;

    /** Update parallel material */
    parallelMaterialUpdate(req: t.IParallelMaterialUpdateReq): Promise<t.IParallelMaterialUpdateRes>;

    /** Infer parallel material following SAP methodology */
    parallelMaterialInferSap(req: t.IParallelMaterialInferSapReq): Promise<t.IParallelMaterialInferSapRes>;

    /** Imports simple plan from external system(s) */
    importPlan(req: t.IImportPlanReq): Promise<t.IImportPlanRes>;

    /** Imports processes from plant import */
    importProcesses(req: t.IImportProcessesReq): Promise<t.IImportProcessesRes>;

    /** Imports simple plan from external system(s) */
    importPlanJob(req: t.IImportPlanJobReq): Promise<t.IImportPlanJobRes>;

    /** Imports SAP plan from external system, starts job internally */
    importPlanJobSap(req: t.IImportPlanJobSapReq): Promise<t.IImportPlanJobSapRes>;

    /** Triggers import plan on demand */
    runImportPlanOnDemand(req: t.IRunImportPlanOnDemandReq): Promise<t.IRunImportPlanOnDemandRes>;

    /** Retrieves import ondemand result */
    getImportPlanOnDemandResult(req: t.IGetImportPlanOnDemandResultReq): Promise<t.IGetImportPlanOnDemandResultRes>;

    /** Imports workplace schedule */
    importWorkSchedule(req: t.IImportWorkScheduleReq): Promise<t.IImportWorkScheduleRes>;

    /** Get tools with criteria */
    toolsGet(req: t.IToolsGetReq): Promise<t.IToolsGetRes>;

    /** Upsert single tool */
    toolsUpsert(req: t.IToolsUpsertReq): Promise<t.IToolsUpsertRes>;

    /** Get statistics */
    getBillingStatistics(req: t.IGetBillingStatisticsReq): Promise<t.IGetBillingStatisticsRes>;

    /** Get long-term stock forecast */
    getLongTermStockForecast(req: t.IGetLongTermStockForecastReq): Promise<t.IGetLongTermStockForecastRes>;

    /** Get list of long-term stock forecast related blob storage items */
    getLongTermStockForecastFiles(req: t.IGetLongTermStockForecastFilesReq): Promise<t.IGetLongTermStockForecastFilesRes>;

    /** Returns content of given long-term stock forecast related blob storage item as string or null if it is not found */
    getLongTermStockForecastFile(req: t.IGetLongTermStockForecastFileReq): Promise<t.IGetLongTermStockForecastFileRes>;

    /** Updates/creates long-term stock forecast related blob storage item */
    setLongTermStockForecastFile(req: t.ISetLongTermStockForecastFileReq): Promise<t.ISetLongTermStockForecastFileRes>;

    /** Deletes long-term stock forecast related blob storage item */
    deleteLongTermStockForecastFile(req: t.IDeleteLongTermStockForecastFileReq): Promise<t.IDeleteLongTermStockForecastFileRes>;

    /** Returns a file from blob storage that contains conditional capacity codes that can be executed in parallel */
    getConditionalCapacityConstraintsFile(req: t.IGetConditionalCapacityConstraintsFileReq): Promise<t.IGetConditionalCapacityConstraintsFileRes>;

    /** Returns a file from blob storage that contains line group codes which defines set of lines where some order can be produced */
    getLineGroupsFile(req: t.IGetLineGroupsFileReq): Promise<t.IGetLineGroupsFileRes>;

    /** Returns a file from blob storage that contains mappings from stock_location_code to line_external_id */
    getSubcontractorMappingsFile(req: t.IGetSubcontractorMappingsFileReq): Promise<t.IGetSubcontractorMappingsFileRes>;

    /** Get list of scheduling configurations for given line group */
    getSchedulingConfigurations(req: t.IGetSchedulingConfigurationsReq): Promise<t.IGetSchedulingConfigurationsRes>;

    /** Update given scheduling configuration */
    updateSchedulingConfiguration(req: t.IUpdateSchedulingConfigurationReq): Promise<t.IUpdateSchedulingConfigurationRes>;

    /** Delete given scheduling configuration */
    deleteSchedulingConfiguration(req: t.IDeleteSchedulingConfigurationReq): Promise<t.IDeleteSchedulingConfigurationRes>;

    /** Insert new manual-entry order */
    insertManualEntryOrder(req: t.IInsertManualEntryOrderReq): Promise<t.IInsertManualEntryOrderRes>;

    /** Update manual-entry order */
    updateManualEntryOrder(req: t.IUpdateManualEntryOrderReq): Promise<t.IUpdateManualEntryOrderRes>;

    /** Delete manual-entry order */
    deleteManualEntryOrder(req: t.IDeleteManualEntryOrderReq): Promise<t.IDeleteManualEntryOrderRes>;

    /** Insert new chunk-orders */
    insertChunkOrders(req: t.IInsertChunkOrdersReq): Promise<t.IInsertChunkOrdersRes>;

    /** Update archived manual-entry order */
    updateArchivedManualEntryOrder(req: t.IUpdateArchivedManualEntryOrderReq): Promise<t.IUpdateArchivedManualEntryOrderRes>;

    /** Get list of archived manual-entry orders */
    getArchivedManualEntryOrders(req: t.IGetArchivedManualEntryOrdersReq): Promise<t.IGetArchivedManualEntryOrdersRes>;

    /** Get count of archived manual-entry orders */
    getArchivedManualEntryOrdersCount(req: t.IGetArchivedManualEntryOrdersCountReq): Promise<t.IGetArchivedManualEntryOrdersCountRes>;

    /** Dump open orders */
    getOrdersDump(req: t.IGetOrdersDumpReq): Promise<t.IGetOrdersDumpRes>;

    /** Get list of plan table export jobs */
    getPlanTableExportJobs(req: t.IGetPlanTableExportJobsReq): Promise<t.IGetPlanTableExportJobsRes>;

    /** Get plan export/import time estimate */
    getPlanExportImportTime(req: t.IGetPlanExportImportTimeReq): Promise<t.IGetPlanExportImportTimeRes>;

    /** Start new job for calculating electricity consumption */
    newElectricityReport(req: t.INewElectricityReportReq): Promise<t.INewElectricityReportRes>;

    /** undefined */
    latestElectricityReport(req: t.ILatestElectricityReportReq): Promise<t.ILatestElectricityReportRes>;

    /** Get stock locations for material */
    getStockLocationsForMaterial(req: t.IGetStockLocationsForMaterialReq): Promise<t.IGetStockLocationsForMaterialRes>;

    /** Updates simulation model and injects tool setup matrix into model */
    updateModelToolSetupMatrix(req: t.IUpdateModelToolSetupMatrixReq): Promise<t.IUpdateModelToolSetupMatrixRes>;

    /** Updates planning model based on the latest simulation model */
    updatePlanningModel(req: t.IUpdatePlanningModelReq): Promise<t.IUpdatePlanningModelRes>;

    /** Fetches normatives for orders by date or id */
    getNormativesForModeling(req: t.IGetNormativesForModelingReq): Promise<t.IGetNormativesForModelingRes>;

    /** Fetches materials plant tags */
    getTagsForPlantMaterials(req: t.IGetTagsForPlantMaterialsReq): Promise<t.IGetTagsForPlantMaterialsRes>;

}

export class Manufacturing2 implements IManufacturing2 {

    client: api.ApiClient;

    constructor(client: api.ApiClient) { this.client = client; }

    /** Given plant external id and interval (two timestamps) update accounted_for='t' for all productions in that range */
    async updateAccountedProductions(req: t.IUpdateAccountedProductionsReq): Promise<t.IUpdateAccountedProductionsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/update-accounted-productions`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Updates unconfirmed stock table */
    async updateUnconfirmedStock(req: t.IUpdateUnconfirmedStockReq): Promise<t.IUpdateUnconfirmedStockRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/update-unconfirmed-stock`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Updates unconfirmed stock table */
    async updateUnconfirmedStockAsync(req: t.IUpdateUnconfirmedStockAsyncReq): Promise<t.IUpdateUnconfirmedStockAsyncRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/update-unconfirmed-stock-async`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Gets details behind unconfirmed stock value */
    async getUnconfirmedStockDetails(req: t.IGetUnconfirmedStockDetailsReq): Promise<t.IGetUnconfirmedStockDetailsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/unconfirmed-stock-details`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Sets line-group permission for given user */
    async setLineGroupsForUser(req: t.ISetLineGroupsForUserReq): Promise<t.ISetLineGroupsForUserRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/line-groups/set-for-user`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Get stock levels for materials consumed and produced by lines in the line-group */
    async getStockStatus(req: t.IGetStockStatusReq): Promise<t.IGetStockStatusRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/line-groups/${enc(req.group_uuid)}/stock-status`;
        delete reqx.group_uuid;
        return (await this.client.get(url, reqx)).data;
    }

    /** Get stock levels for materials */
    async getStockStatusForMaterials(req: t.IGetStockStatusForMaterialsReq): Promise<t.IGetStockStatusForMaterialsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/stock-status-for-materials`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Get stock levels for materials consumed and produced by lines in the line-group */
    async getStockRequirements(req: t.IGetStockRequirementsReq): Promise<t.IGetStockRequirementsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/line-groups/${enc(req.line_group_uuid)}/stock-requirements`;
        delete reqx.line_group_uuid;
        return (await this.client.get(url, reqx)).data;
    }

    /** Get stock requirements for planning table */
    async getStockRequirementsShifts(req: t.IGetStockRequirementsShiftsReq): Promise<t.IGetStockRequirementsShiftsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/stock-requirements-shifts`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Get stock requirements for planning table */
    async getSingleStockRequirementsShifts(req: t.IGetSingleStockRequirementsShiftsReq): Promise<t.IGetSingleStockRequirementsShiftsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/single-stock-requirements-shifts`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Update comment for specfic stock requirement */
    async updateStockRequirementsComment(req: t.IUpdateStockRequirementsCommentReq): Promise<t.IUpdateStockRequirementsCommentRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/stock-requirements/updateComment`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Gets stock-forecast for materials that are processed by the given group */
    async getMaterialForecast(req: t.IGetMaterialForecastReq): Promise<t.IGetMaterialForecastRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/line-groups/${enc(req.group_uuid)}/material-stock-forecast`;
        delete reqx.group_uuid;
        return (await this.client.get(url, reqx)).data;
    }

    /** Gets material stock-forecast for given shifts */
    async getMaterialForecastForShifts(req: t.IGetMaterialForecastForShiftsReq): Promise<t.IGetMaterialForecastForShiftsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/line-groups/${enc(req.group_uuid)}/shifts-material-stock-forecast`;
        delete reqx.group_uuid;
        return (await this.client.post(url, reqx)).data;
    }

    /** Adds a line to the line-group */
    async setLinesForLinegroup(req: t.ISetLinesForLinegroupReq): Promise<t.ISetLinesForLinegroupRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/line-groups/${enc(req.linegroup_uuid)}/lines`;
        delete reqx.linegroup_uuid;
        return (await this.client.post(url, reqx)).data;
    }

    /** Delete the line-group */
    async deleteLineGroup(req: t.IDeleteLineGroupReq): Promise<t.IDeleteLineGroupRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/line-groups/${enc(req.linegroup_uuid)}`;
        delete reqx.linegroup_uuid;
        return (await this.client.delete(url, reqx)).data;
    }

    /** Update the line-group */
    async updateLineGroup(req: t.IUpdateLineGroupReq): Promise<t.IUpdateLineGroupRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/line-groups/${enc(req.linegroup_uuid)}`;
        delete reqx.linegroup_uuid;
        return (await this.client.put(url, reqx)).data;
    }

    /** Returns all line-groups for current org */
    async getLineGroups(req: t.IGetLineGroupsReq): Promise<t.IGetLineGroupsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/line-groups`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Create a new line-group for current org */
    async createLineGroup(req: t.ICreateLineGroupReq): Promise<t.ICreateLineGroupRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/line-groups`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Get line-material-process matrix for the materials produced in the line group */
    async getLineGroupMatrix(req: t.IGetLineGroupMatrixReq): Promise<t.IGetLineGroupMatrixRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/line-groups/${enc(req.linegroup_uuid)}/matrix`;
        delete reqx.linegroup_uuid;
        return (await this.client.get(url, reqx)).data;
    }

    /** Update which cells in line-mprocess-process matrix can be used by automatic planning */
    async setLineGroupMatrix(req: t.ISetLineGroupMatrixReq): Promise<t.ISetLineGroupMatrixRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/line-groups/${enc(req.linegroup_uuid)}/matrix`;
        delete reqx.linegroup_uuid;
        return (await this.client.post(url, reqx)).data;
    }

    /** Update existing plant new plant */
    async updatePlant(req: t.IUpdatePlantReq): Promise<t.IUpdatePlantRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/plants/${enc(req.uuid)}`;
        delete reqx.uuid;
        return (await this.client.put(url, reqx)).data;
    }

    /** Update existing organisation */
    async updateCurrentOrganisation(req: t.IUpdateCurrentOrganisationReq): Promise<t.IUpdateCurrentOrganisationRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/org/current`;
        return (await this.client.put(url, reqx)).data;
    }

    /** Fetches organisation tags */
    async getCurrentOrganisation(req: t.IGetCurrentOrganisationReq): Promise<t.IGetCurrentOrganisationRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/org/current`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Gets all plants for user's org */
    async getPlants(req: t.IGetPlantsReq): Promise<t.IGetPlantsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/plants`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Insert new plant */
    async insertPlant(req: t.IInsertPlantReq): Promise<t.IInsertPlantRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/plants`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Gets all tags of a plant */
    async getPlantTags(req: t.IGetPlantTagsReq): Promise<t.IGetPlantTagsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/plant-tags`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Gets hash for given line */
    async getLineHash(req: t.IGetLineHashReq): Promise<t.IGetLineHashRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/lines/${enc(req.line_uuid)}/hash`;
        delete reqx.line_uuid;
        return (await this.client.get(url, reqx)).data;
    }

    /** Gets shifts for multiple lines */
    async getLineShiftsEx(req: t.IGetLineShiftsExReq): Promise<t.IGetLineShiftsExRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/lines/shifts-ex`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Gets shifts for given line */
    async getLineShifts(req: t.IGetLineShiftsReq): Promise<t.IGetLineShiftsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/lines/${enc(req.line_uuid)}/shifts`;
        delete reqx.line_uuid;
        return (await this.client.get(url, reqx)).data;
    }

    /** Upserts shifts for given line */
    async updateLineShifts(req: t.IUpdateLineShiftsReq): Promise<t.IUpdateLineShiftsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/lines/${enc(req.line_uuid)}/shifts`;
        delete reqx.line_uuid;
        return (await this.client.post(url, reqx)).data;
    }

    /** Updates orders for given line */
    async updateLineOrders(req: t.IUpdateLineOrdersReq): Promise<t.IUpdateLineOrdersRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/lines/${enc(req.line_uuid)}/orders`;
        delete reqx.line_uuid;
        return (await this.client.post(url, reqx)).data;
    }

    /** Deletes all orders for given line */
    async deleteLineOrders(req: t.IDeleteLineOrdersReq): Promise<t.IDeleteLineOrdersRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/lines/${enc(req.line_uuid)}/orders`;
        delete reqx.line_uuid;
        return (await this.client.delete(url, reqx)).data;
    }

    /** Gets planning-data for given line */
    async getLinePlanningData(req: t.IGetLinePlanningDataReq): Promise<t.IGetLinePlanningDataRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/lines/${enc(req.line_uuid)}/planning_data`;
        delete reqx.line_uuid;
        return (await this.client.get(url, reqx)).data;
    }

    /** Gets planning-data for given lines */
    async getPlanningData(req: t.IGetPlanningDataReq): Promise<t.IGetPlanningDataRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/lines/planning_data`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Get list of open orders that can be produced on the line */
    async getLinePossibleOrders(req: t.IGetLinePossibleOrdersReq): Promise<t.IGetLinePossibleOrdersRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/lines/${enc(req.line_uuid)}/possible_orders`;
        delete reqx.line_uuid;
        return (await this.client.get(url, reqx)).data;
    }

    /** Updates given line */
    async updateLine(req: t.IUpdateLineReq): Promise<t.IUpdateLineRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/lines/${enc(req.line_uuid)}`;
        delete reqx.line_uuid;
        return (await this.client.put(url, reqx)).data;
    }

    /** Deletes given line */
    async deleteLine(req: t.IDeleteLineReq): Promise<t.IDeleteLineRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/lines/${enc(req.line_uuid)}`;
        delete reqx.line_uuid;
        return (await this.client.delete(url, reqx)).data;
    }

    /** Gets all lines for user's org */
    async getLines(req: t.IGetLinesReq): Promise<t.IGetLinesRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/lines`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Inserts new line */
    async insertLine(req: t.IInsertLineReq): Promise<t.IInsertLineRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/lines`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Disable order bom inputs */
    async disableBomInputs(req: t.IDisableBomInputsReq): Promise<t.IDisableBomInputsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/disableBomInputs`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Updates given shifts */
    async updateShifts(req: t.IUpdateShiftsReq): Promise<t.IUpdateShiftsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/shifts`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Get stock levels for materials consumed and produced by lines in the line-group */
    async getSubcontractingDetails(req: t.IGetSubcontractingDetailsReq): Promise<t.IGetSubcontractingDetailsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/subcontracting-details/${enc(req.external_operation_id)}`;
        delete reqx.external_operation_id;
        return (await this.client.get(url, reqx)).data;
    }

    /** Get stock levels for materials consumed and produced by lines in the line-group */
    async getSubcontractingQuantities(req: t.IGetSubcontractingQuantitiesReq): Promise<t.IGetSubcontractingQuantitiesRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/subcontracting-details-quantities/`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Triggers population of missing dashboards */
    async populateDashboards(req: t.IPopulateDashboardsReq): Promise<t.IPopulateDashboardsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/admin/populate-dashboards`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Triggers population of missing datasources */
    async populateDatasources(req: t.IPopulateDatasourcesReq): Promise<t.IPopulateDatasourcesRes> {
        const reqx: any = req;
        const url = `/api/v1.0/admin/populate-datasources`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Gets manufacturing statistics for user's org */
    async getStats(req: t.IGetStatsReq): Promise<t.IGetStatsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/stats`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Gets shifts timetable with people */
    async getPeopleShiftsTable(req: t.IGetPeopleShiftsTableReq): Promise<t.IGetPeopleShiftsTableRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/people-shifts/table`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Gets shifts timetable with people */
    async getPeopleShiftsSimple(req: t.IGetPeopleShiftsSimpleReq): Promise<t.IGetPeopleShiftsSimpleRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/people-shifts/simple`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Gets insight types */
    async getInsightTypes(req: t.IGetInsightTypesReq): Promise<t.IGetInsightTypesRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/insights/types`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Gets insights with specified criteria */
    async getInsights(req: t.IGetInsightsReq): Promise<t.IGetInsightsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/insights`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Triggers re-calculation of insights */
    async recalculateInsights(req: t.IRecalculateInsightsReq): Promise<t.IRecalculateInsightsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/insights/recompute`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Retrieves actions for given insight */
    async getInsightActions(req: t.IGetInsightActionsReq): Promise<t.IGetInsightActionsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/insights/actions`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Gets the first version of permanent insight */
    async getPermanentInsightFirstVersion(req: t.IGetPermanentInsightFirstVersionReq): Promise<t.IGetPermanentInsightFirstVersionRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/permanent-insights/${enc(req.uuid)}/first`;
        delete reqx.uuid;
        return (await this.client.get(url, reqx)).data;
    }

    /** Sets tags of permanent insight */
    async setPermanentInsightTags(req: t.ISetPermanentInsightTagsReq): Promise<t.ISetPermanentInsightTagsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/permanent-insights/${enc(req.uuid)}/set-tags`;
        delete reqx.uuid;
        return (await this.client.post(url, reqx)).data;
    }

    /** Gets permanent insight */
    async getPermanentInsight(req: t.IGetPermanentInsightReq): Promise<t.IGetPermanentInsightRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/permanent-insights/${enc(req.uuid)}`;
        delete reqx.uuid;
        return (await this.client.get(url, reqx)).data;
    }

    /** Creates permanent insight */
    async createPermanentInsight(req: t.ICreatePermanentInsightReq): Promise<t.ICreatePermanentInsightRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/permanent-insights/create`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Searches permanent insight by given criteria */
    async searchPermanentInsights(req: t.ISearchPermanentInsightsReq): Promise<t.ISearchPermanentInsightsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/permanent-insights`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Retrieve current downtimes happening in the given plant */
    async getCurrentDowntimes(req: t.IGetCurrentDowntimesReq): Promise<t.IGetCurrentDowntimesRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/insights/current_downtimes`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Updates an order */
    async updateOrder(req: t.IUpdateOrderReq): Promise<t.IUpdateOrderRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/order/${enc(req.uuid)}`;
        delete reqx.uuid;
        return (await this.client.post(url, reqx)).data;
    }

    /** Deletes an order */
    async deleteOrder(req: t.IDeleteOrderReq): Promise<t.IDeleteOrderRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/order/${enc(req.uuid)}`;
        delete reqx.uuid;
        return (await this.client.delete(url, reqx)).data;
    }

    /** Return list of lines on which the order can be executed */
    async getOrderLines(req: t.IGetOrderLinesReq): Promise<t.IGetOrderLinesRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/order/${enc(req.uuid)}/lines`;
        delete reqx.uuid;
        return (await this.client.get(url, reqx)).data;
    }

    /** Return list of lines on which the order can be executed */
    async getOperationRelatedLines(req: t.IGetOperationRelatedLinesReq): Promise<t.IGetOperationRelatedLinesRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/order/${enc(req.uuid)}/operation_related_lines`;
        delete reqx.uuid;
        return (await this.client.get(url, reqx)).data;
    }

    /** Updates orders */
    async updateOrders(req: t.IUpdateOrdersReq): Promise<t.IUpdateOrdersRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/update_orders`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Heal inconsistent chunks state */
    async healChunks(req: t.IHealChunksReq): Promise<t.IHealChunksRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/heal_chunks`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Closes given orders */
    async closeOrders(req: t.ICloseOrdersReq): Promise<t.ICloseOrdersRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/close_orders`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Gets orders that need to be enriched */
    async getOrdersForStockMonitoring(req: t.IGetOrdersForStockMonitoringReq): Promise<t.IGetOrdersForStockMonitoringRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/orders/for-stock-monitoring`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Get input materials */
    async getInputMaterials(req: t.IGetInputMaterialsReq): Promise<t.IGetInputMaterialsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/orders/input-materials`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Gets orders */
    async searchOrders(req: t.ISearchOrdersReq): Promise<t.ISearchOrdersRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/orders`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Get order */
    async getOrdersSimple(req: t.IGetOrdersSimpleReq): Promise<t.IGetOrdersSimpleRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/orders/simple`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Get production quantities for the orders */
    async getOrdersProduced(req: t.IGetOrdersProducedReq): Promise<t.IGetOrdersProducedRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/orders/produced`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Get order */
    async getOrdersTools(req: t.IGetOrdersToolsReq): Promise<t.IGetOrdersToolsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/orders/tools`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Stores updated shift-assignments for people */
    async storePeopleShiftAssignments(req: t.IStorePeopleShiftAssignmentsReq): Promise<t.IStorePeopleShiftAssignmentsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/people-shifts`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Gets people information */
    async getPeople(req: t.IGetPeopleReq): Promise<t.IGetPeopleRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/people`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Creates new person */
    async createPerson(req: t.ICreatePersonReq): Promise<t.ICreatePersonRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/people`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Updates existing person */
    async updatePerson(req: t.IUpdatePersonReq): Promise<t.IUpdatePersonRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/people`;
        return (await this.client.put(url, reqx)).data;
    }

    /** Gets competences */
    async getCompetence(req: t.IGetCompetenceReq): Promise<t.IGetCompetenceRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/competences`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Upserts specific competence */
    async upsertCompetence(req: t.IUpsertCompetenceReq): Promise<t.IUpsertCompetenceRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/competences`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Deletes specific competence */
    async deleteCompetence(req: t.IDeleteCompetenceReq): Promise<t.IDeleteCompetenceRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/competences`;
        return (await this.client.delete(url, reqx)).data;
    }

    /** Gets process information */
    async getProcesses(req: t.IGetProcessesReq): Promise<t.IGetProcessesRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/process`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Upsert process information */
    async upsertProcess(req: t.IUpsertProcessReq): Promise<t.IUpsertProcessRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/process`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Deletes process */
    async deleteProcess(req: t.IDeleteProcessReq): Promise<t.IDeleteProcessRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/process`;
        return (await this.client.delete(url, reqx)).data;
    }

    /** Disables processes: sets flag disabled = true in qldb_man.processes table */
    async disableProcesses(req: t.IDisableProcessesReq): Promise<t.IDisableProcessesRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/processes`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Gets anonymization mapping */
    async getAnonymizationData(req: t.IGetAnonymizationDataReq): Promise<t.IGetAnonymizationDataRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/anonymization-data`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Trigger computation of full simulation */
    async runSimulation(req: t.IRunSimulationReq): Promise<t.IRunSimulationRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/simulation`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Executes retrieval of stock requirements for one material+plant */
    async runStockRequirementsSimple(req: t.IRunStockRequirementsSimpleReq): Promise<t.IRunStockRequirementsSimpleRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/stock-requirements-simple`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Executes purging of unconfirmed stock requirements for current org */
    async runPurgeUnconfirmedStockRequirements(req: t.IRunPurgeUnconfirmedStockRequirementsReq): Promise<t.IRunPurgeUnconfirmedStockRequirementsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/stock-requirements-purge`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Creates a plan for given linegroup and applies it */
    async runLinegroupPlanningComplete(req: t.IRunLinegroupPlanningCompleteReq): Promise<t.IRunLinegroupPlanningCompleteRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/planning/line-group/${enc(req.linegroup_uuid)}/complete`;
        delete reqx.linegroup_uuid;
        return (await this.client.post(url, reqx)).data;
    }

    /** Retrieves planning log for given linegroup */
    async getLinegroupPlanningLog(req: t.IGetLinegroupPlanningLogReq): Promise<t.IGetLinegroupPlanningLogRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/planning/line-group/${enc(req.linegroup_uuid)}/log`;
        delete reqx.linegroup_uuid;
        return (await this.client.get(url, reqx)).data;
    }

    /** Creates a plan for given linegroup based on needs computed in the last simulation */
    async runLinegroupPlanning(req: t.IRunLinegroupPlanningReq): Promise<t.IRunLinegroupPlanningRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/planning/line-group/${enc(req.linegroup_uuid)}`;
        delete reqx.linegroup_uuid;
        return (await this.client.post(url, reqx)).data;
    }

    /** Get planning job status */
    async getPlanStatus(req: t.IGetPlanStatusReq): Promise<t.IGetPlanStatusRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/planning/status/${enc(req.plan_uuid)}`;
        delete reqx.plan_uuid;
        return (await this.client.get(url, reqx)).data;
    }

    /** Get planning job result */
    async getPlanResult(req: t.IGetPlanResultReq): Promise<t.IGetPlanResultRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/planning/result/${enc(req.plan_uuid)}`;
        delete reqx.plan_uuid;
        return (await this.client.get(url, reqx)).data;
    }

    /** Detect inconsistent production versions */
    async detectInconsistingProdVer(req: t.IDetectInconsistingProdVerReq): Promise<t.IDetectInconsistingProdVerRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/detectInconsistingProdVer`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Triggers execution of manufacturing checks */
    async performChecks(req: t.IPerformChecksReq): Promise<t.IPerformChecksRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/checks`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Update produced_available_in column for open operations */
    async updateProducedAvailableIn(req: t.IUpdateProducedAvailableInReq): Promise<t.IUpdateProducedAvailableInRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/update-produced-available-in`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Updates tags that control operation stock triggering */
    async configureStockTriggering(req: t.IConfigureStockTriggeringReq): Promise<t.IConfigureStockTriggeringRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/configure-stock-triggering`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Create new orders and processes for parallel materials */
    async rewireParallelMaterials(req: t.IRewireParallelMaterialsReq): Promise<t.IRewireParallelMaterialsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/rewire-parallel-materials`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Create new orders and processes for parallel materials */
    async rewireParallelMaterialsAsync(req: t.IRewireParallelMaterialsAsyncReq): Promise<t.IRewireParallelMaterialsAsyncRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/rewire-parallel-materials-async`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Returns weekly-production report */
    async weeklyProduced(req: t.IWeeklyProducedReq): Promise<t.IWeeklyProducedRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/weekly_produced`;
        return (await this.client.get(url, reqx)).data;
    }

    /** DEPRECATED. Delete after 2023-08-01. Creates productions for chunk orders that have completed */
    async backflushProductions(req: t.IBackflushProductionsReq): Promise<t.IBackflushProductionsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/backflush-productions`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Gets materials for a stock location */
    async getStockLocationMaterials(req: t.IGetStockLocationMaterialsReq): Promise<t.IGetStockLocationMaterialsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/stock-locations/${enc(req.uuid)}/materials`;
        delete reqx.uuid;
        return (await this.client.get(url, reqx)).data;
    }

    /** Set stock level for material at the given stock location */
    async updateStockStatus(req: t.IUpdateStockStatusReq): Promise<t.IUpdateStockStatusRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/stock-locations/${enc(req.location_uuid)}/${enc(req.material_uuid)}/status`;
        delete reqx.location_uuid;
        delete reqx.material_uuid;
        return (await this.client.put(url, reqx)).data;
    }

    /** Gets stock locations */
    async getStockLocations(req: t.IGetStockLocationsReq): Promise<t.IGetStockLocationsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/stock-locations`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Create new stock location */
    async createStockLocation(req: t.ICreateStockLocationReq): Promise<t.ICreateStockLocationRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/stock-locations`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Check if any plant is missing system stock locations and creates them */
    async createMissingSystemStockLocations(req: t.ICreateMissingSystemStockLocationsReq): Promise<t.ICreateMissingSystemStockLocationsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/create-system-stock-locations`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Update the stock location */
    async updateStockLocation(req: t.IUpdateStockLocationReq): Promise<t.IUpdateStockLocationRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/stock-locations/${enc(req.uuid)}`;
        delete reqx.uuid;
        return (await this.client.put(url, reqx)).data;
    }

    /** Gets production for specified line */
    async getProduced(req: t.IGetProducedReq): Promise<t.IGetProducedRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/produced/${enc(req.uuid)}`;
        delete reqx.uuid;
        return (await this.client.get(url, reqx)).data;
    }

    /** Gets productions */
    async getProducedAll(req: t.IGetProducedAllReq): Promise<t.IGetProducedAllRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/produced/`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Gets production sums for external operation id */
    async productionSum(req: t.IProductionSumReq): Promise<t.IProductionSumRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/production-sum`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Gets productions for each external id */
    async externalIdProductions(req: t.IExternalIdProductionsReq): Promise<t.IExternalIdProductionsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/external-id-productions`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Gets kanban data for line */
    async getLineKanban(req: t.IGetLineKanbanReq): Promise<t.IGetLineKanbanRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/kanban/${enc(req.uuid)}`;
        delete reqx.uuid;
        return (await this.client.get(url, reqx)).data;
    }

    /** Updates kanban data for line */
    async updateLineKanban(req: t.IUpdateLineKanbanReq): Promise<t.IUpdateLineKanbanRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/kanban/${enc(req.uuid)}`;
        delete reqx.uuid;
        return (await this.client.post(url, reqx)).data;
    }

    /** Get production version for given material */
    async getProductionVersionForMaterialPlant(req: t.IGetProductionVersionForMaterialPlantReq): Promise<t.IGetProductionVersionForMaterialPlantRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/production-version/get-for-material`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Set production version for given order */
    async setProductionVersionForOrder(req: t.ISetProductionVersionForOrderReq): Promise<t.ISetProductionVersionForOrderRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/production-version/set-for-order`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Get compatible production versions for orders */
    async getCompatibleProdVers(req: t.IGetCompatibleProdVersReq): Promise<t.IGetCompatibleProdVersRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/production-version/compatible-versions`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Get operation-line matrix */
    async getOperationLineMatrix(req: t.IGetOperationLineMatrixReq): Promise<t.IGetOperationLineMatrixRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/production-version/get-operation-line-matrix`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Gets materials whose stock should be monitored */
    async getMaterialsForStockMonitoring(req: t.IGetMaterialsForStockMonitoringReq): Promise<t.IGetMaterialsForStockMonitoringRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/materials/for-stock-monitoring`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Gets materials translations */
    async getMaterialsTranslations(req: t.IGetMaterialsTranslationsReq): Promise<t.IGetMaterialsTranslationsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/materials/translations`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Gets pairs for parent-shadow materials */
    async getShadowPairs(req: t.IGetShadowPairsReq): Promise<t.IGetShadowPairsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/materials/shadow-pairs`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Gets materials data via simple query */
    async searchMaterialsSimple(req: t.ISearchMaterialsSimpleReq): Promise<t.ISearchMaterialsSimpleRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/materials-simple`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Gets materials data */
    async searchMaterials(req: t.ISearchMaterialsReq): Promise<t.ISearchMaterialsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/materials`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Insert new material */
    async insertMaterial(req: t.IInsertMaterialReq): Promise<t.IInsertMaterialRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/materials`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Gets all materials data */
    async searchMaterialsAll(req: t.ISearchMaterialsAllReq): Promise<t.ISearchMaterialsAllRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/materials-all`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Update material */
    async updateMaterial(req: t.IUpdateMaterialReq): Promise<t.IUpdateMaterialRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/material/${enc(req.uuid)}`;
        delete reqx.uuid;
        return (await this.client.put(url, reqx)).data;
    }

    /** Update material plant */
    async updateMaterialPlant(req: t.IUpdateMaterialPlantReq): Promise<t.IUpdateMaterialPlantRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/material_plant/${enc(req.material_uuid)}/${enc(req.plant_uuid)}`;
        delete reqx.material_uuid;
        delete reqx.plant_uuid;
        return (await this.client.put(url, reqx)).data;
    }

    /** Update material's tag by input material external ID */
    async updateMaterialTagByInputMaterial(req: t.IUpdateMaterialTagByInputMaterialReq): Promise<t.IUpdateMaterialTagByInputMaterialRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/material/${enc(req.input_material_external_id)}/tag`;
        delete reqx.input_material_external_id;
        return (await this.client.put(url, reqx)).data;
    }

    /** Get material's plant level tags */
    async getMaterialPlantTags(req: t.IGetMaterialPlantTagsReq): Promise<t.IGetMaterialPlantTagsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/material/${enc(req.uuid)}/plant_tags`;
        delete reqx.uuid;
        return (await this.client.get(url, reqx)).data;
    }

    /** Get stock quantity that is in the process of being produced (has ongoing chunk orders) for the material */
    async getStockBeingProduced(req: t.IGetStockBeingProducedReq): Promise<t.IGetStockBeingProducedRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/material/${enc(req.uuid)}/stock-being-produced`;
        delete reqx.uuid;
        return (await this.client.get(url, reqx)).data;
    }

    /** Get list of all parallel materials */
    async parallelMaterialsGet(req: t.IParallelMaterialsGetReq): Promise<t.IParallelMaterialsGetRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/parallel-materials/`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Get parallel material details */
    async parallelMaterialGet(req: t.IParallelMaterialGetReq): Promise<t.IParallelMaterialGetRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/parallel-material/${enc(req.uuid)}`;
        delete reqx.uuid;
        return (await this.client.get(url, reqx)).data;
    }

    /** Create new parallel material */
    async parallelMaterialCreate(req: t.IParallelMaterialCreateReq): Promise<t.IParallelMaterialCreateRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/parallel-material/create`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Update parallel material */
    async parallelMaterialUpdate(req: t.IParallelMaterialUpdateReq): Promise<t.IParallelMaterialUpdateRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/parallel-material/update`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Infer parallel material following SAP methodology */
    async parallelMaterialInferSap(req: t.IParallelMaterialInferSapReq): Promise<t.IParallelMaterialInferSapRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/parallel-material/infer`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Imports simple plan from external system(s) */
    async importPlan(req: t.IImportPlanReq): Promise<t.IImportPlanRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/plan/import`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Imports processes from plant import */
    async importProcesses(req: t.IImportProcessesReq): Promise<t.IImportProcessesRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/import_processes`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Imports simple plan from external system(s) */
    async importPlanJob(req: t.IImportPlanJobReq): Promise<t.IImportPlanJobRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/plan/importJob`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Imports SAP plan from external system, starts job internally */
    async importPlanJobSap(req: t.IImportPlanJobSapReq): Promise<t.IImportPlanJobSapRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/plan/import-job-sap`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Triggers import plan on demand */
    async runImportPlanOnDemand(req: t.IRunImportPlanOnDemandReq): Promise<t.IRunImportPlanOnDemandRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/plan/import_on_demand`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Retrieves import ondemand result */
    async getImportPlanOnDemandResult(req: t.IGetImportPlanOnDemandResultReq): Promise<t.IGetImportPlanOnDemandResultRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/plan/import_on_demand_result`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Imports workplace schedule */
    async importWorkSchedule(req: t.IImportWorkScheduleReq): Promise<t.IImportWorkScheduleRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/plan/import_work_schedule`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Get tools with criteria */
    async toolsGet(req: t.IToolsGetReq): Promise<t.IToolsGetRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/tools`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Upsert single tool */
    async toolsUpsert(req: t.IToolsUpsertReq): Promise<t.IToolsUpsertRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/tools`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Get statistics */
    async getBillingStatistics(req: t.IGetBillingStatisticsReq): Promise<t.IGetBillingStatisticsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/billing-statistics`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Get long-term stock forecast */
    async getLongTermStockForecast(req: t.IGetLongTermStockForecastReq): Promise<t.IGetLongTermStockForecastRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/long-term-stock-forecast`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Get list of long-term stock forecast related blob storage items */
    async getLongTermStockForecastFiles(req: t.IGetLongTermStockForecastFilesReq): Promise<t.IGetLongTermStockForecastFilesRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/long-term-stock-forecast/files`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Returns content of given long-term stock forecast related blob storage item as string or null if it is not found */
    async getLongTermStockForecastFile(req: t.IGetLongTermStockForecastFileReq): Promise<t.IGetLongTermStockForecastFileRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/long-term-stock-forecast/file`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Updates/creates long-term stock forecast related blob storage item */
    async setLongTermStockForecastFile(req: t.ISetLongTermStockForecastFileReq): Promise<t.ISetLongTermStockForecastFileRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/long-term-stock-forecast/file`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Deletes long-term stock forecast related blob storage item */
    async deleteLongTermStockForecastFile(req: t.IDeleteLongTermStockForecastFileReq): Promise<t.IDeleteLongTermStockForecastFileRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/long-term-stock-forecast/file`;
        return (await this.client.delete(url, reqx)).data;
    }

    /** Returns a file from blob storage that contains conditional capacity codes that can be executed in parallel */
    async getConditionalCapacityConstraintsFile(req: t.IGetConditionalCapacityConstraintsFileReq): Promise<t.IGetConditionalCapacityConstraintsFileRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/conditional-capacity-constraints/file`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Returns a file from blob storage that contains line group codes which defines set of lines where some order can be produced */
    async getLineGroupsFile(req: t.IGetLineGroupsFileReq): Promise<t.IGetLineGroupsFileRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/line-groups/file`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Returns a file from blob storage that contains mappings from stock_location_code to line_external_id */
    async getSubcontractorMappingsFile(req: t.IGetSubcontractorMappingsFileReq): Promise<t.IGetSubcontractorMappingsFileRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/subcontractor-mappings/file`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Get list of scheduling configurations for given line group */
    async getSchedulingConfigurations(req: t.IGetSchedulingConfigurationsReq): Promise<t.IGetSchedulingConfigurationsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/scheduling-configurations`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Update given scheduling configuration */
    async updateSchedulingConfiguration(req: t.IUpdateSchedulingConfigurationReq): Promise<t.IUpdateSchedulingConfigurationRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/scheduling-configurations`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Delete given scheduling configuration */
    async deleteSchedulingConfiguration(req: t.IDeleteSchedulingConfigurationReq): Promise<t.IDeleteSchedulingConfigurationRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/scheduling-configurations`;
        return (await this.client.delete(url, reqx)).data;
    }

    /** Insert new manual-entry order */
    async insertManualEntryOrder(req: t.IInsertManualEntryOrderReq): Promise<t.IInsertManualEntryOrderRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/manual-entry-order`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Update manual-entry order */
    async updateManualEntryOrder(req: t.IUpdateManualEntryOrderReq): Promise<t.IUpdateManualEntryOrderRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/manual-entry-order`;
        return (await this.client.put(url, reqx)).data;
    }

    /** Delete manual-entry order */
    async deleteManualEntryOrder(req: t.IDeleteManualEntryOrderReq): Promise<t.IDeleteManualEntryOrderRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/manual-entry-order`;
        return (await this.client.delete(url, reqx)).data;
    }

    /** Insert new chunk-orders */
    async insertChunkOrders(req: t.IInsertChunkOrdersReq): Promise<t.IInsertChunkOrdersRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/insert-chunk-orders`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Update archived manual-entry order */
    async updateArchivedManualEntryOrder(req: t.IUpdateArchivedManualEntryOrderReq): Promise<t.IUpdateArchivedManualEntryOrderRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/order/${enc(req.uuid)}/manual-entry-archive`;
        delete reqx.uuid;
        return (await this.client.put(url, reqx)).data;
    }

    /** Get list of archived manual-entry orders */
    async getArchivedManualEntryOrders(req: t.IGetArchivedManualEntryOrdersReq): Promise<t.IGetArchivedManualEntryOrdersRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/orders/manual-entry-archive`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Get count of archived manual-entry orders */
    async getArchivedManualEntryOrdersCount(req: t.IGetArchivedManualEntryOrdersCountReq): Promise<t.IGetArchivedManualEntryOrdersCountRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/orders/manual-entry-archive-count`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Dump open orders */
    async getOrdersDump(req: t.IGetOrdersDumpReq): Promise<t.IGetOrdersDumpRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/orders/dump`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Get list of plan table export jobs */
    async getPlanTableExportJobs(req: t.IGetPlanTableExportJobsReq): Promise<t.IGetPlanTableExportJobsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/plan-table-export-job`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Get plan export/import time estimate */
    async getPlanExportImportTime(req: t.IGetPlanExportImportTimeReq): Promise<t.IGetPlanExportImportTimeRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/plan-export-import-time`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Start new job for calculating electricity consumption */
    async newElectricityReport(req: t.INewElectricityReportReq): Promise<t.INewElectricityReportRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/electricity/new`;
        return (await this.client.post(url, reqx)).data;
    }

    /** undefined */
    async latestElectricityReport(req: t.ILatestElectricityReportReq): Promise<t.ILatestElectricityReportRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/electricity/latest`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Get stock locations for material */
    async getStockLocationsForMaterial(req: t.IGetStockLocationsForMaterialReq): Promise<t.IGetStockLocationsForMaterialRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/kanban/getStockLocationForMaterial/${enc(req.material_external_id)}`;
        delete reqx.material_external_id;
        return (await this.client.get(url, reqx)).data;
    }

    /** Updates simulation model and injects tool setup matrix into model */
    async updateModelToolSetupMatrix(req: t.IUpdateModelToolSetupMatrixReq): Promise<t.IUpdateModelToolSetupMatrixRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/tool-setup-matrix`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Updates planning model based on the latest simulation model */
    async updatePlanningModel(req: t.IUpdatePlanningModelReq): Promise<t.IUpdatePlanningModelRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/planning-model`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Fetches normatives for orders by date or id */
    async getNormativesForModeling(req: t.IGetNormativesForModelingReq): Promise<t.IGetNormativesForModelingRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/get-normatives-for-modeling`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Fetches materials plant tags */
    async getTagsForPlantMaterials(req: t.IGetTagsForPlantMaterialsReq): Promise<t.IGetTagsForPlantMaterialsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/manufacturing/get-tags-for-plant-materials`;
        return (await this.client.get(url, reqx)).data;
    }

}
