// @flow

/************************************************************************************/
/* WARNING: This file is generated automatically. Don't edit it by hand             */
/************************************************************************************/

import * as api from "./restApi.js";
import * as t from "./common.generated.types.js";

function enc(s: any): string { return encodeURIComponent("" + s); }

export interface ICommon {
    /** Gets features and their status (enabled/disabled) */
    getFeaturesMatrix(req: t.IGetFeaturesMatrixReq): Promise<t.IGetFeaturesMatrixRes>;

    /** Gets indication if PIN login is allowed for any organization from caller's location */
    canPinLoginFromCurrentLocation(req: t.ICanPinLoginFromCurrentLocationReq): Promise<t.ICanPinLoginFromCurrentLocationRes>;

    /** Gets status of job */
    getJobStatus(req: t.IGetJobStatusReq): Promise<t.IGetJobStatusRes>;

    /** Gets detailed info of job */
    getJobInfo(req: t.IGetJobInfoReq): Promise<t.IGetJobInfoRes>;

    /** Downloads result of job */
    getJobResultRaw(req: any, id: string): Promise<any>;

    /** Gets result of job */
    getJobResultRaw2(req: t.IGetJobResultRaw2Req): Promise<t.IGetJobResultRaw2Res>;

    /** Deletes data about specified job */
    deleteJob(req: t.IDeleteJobReq): Promise<t.IDeleteJobRes>;

    /** Updates data about specified job */
    updateJob(req: t.IUpdateJobReq): Promise<t.IUpdateJobRes>;

    /** Enqueues pending jobs for this org and returns the data */
    enqueuePendingJobs(req: t.IEnqueuePendingJobsReq): Promise<t.IEnqueuePendingJobsRes>;

    /** Performs job monitoring */
    performJobsMonitoring(req: t.IPerformJobsMonitoringReq): Promise<t.IPerformJobsMonitoringRes>;

    /** Searches for jobs that meet search criteria */
    searchJobs(req: t.ISearchJobsReq): Promise<t.ISearchJobsRes>;

    /** Creates new job in the background */
    createJob(req: t.ICreateJobReq): Promise<t.ICreateJobRes>;

    /** Fetches list of registered orgs */
    getOrgs(req: t.IGetOrgsReq): Promise<t.IGetOrgsRes>;

    /** Creates new org */
    createOrg(req: t.ICreateOrgReq): Promise<t.ICreateOrgRes>;

    /** Update existing organisation */
    updateCurrentOrganisation(req: t.IUpdateCurrentOrganisationReq): Promise<t.IUpdateCurrentOrganisationRes>;

    /** Fetches organisation tags */
    getCurrentOrganisation(req: t.IGetCurrentOrganisationReq): Promise<t.IGetCurrentOrganisationRes>;

    /** Fetches data from key-value store */
    getKeyValue(req: t.IGetKeyValueReq): Promise<t.IGetKeyValueRes>;

    /** Updates/inserts data inside key-value store */
    setKeyValue(req: t.ISetKeyValueReq): Promise<t.ISetKeyValueRes>;

    /** Deletes data inside key-value store */
    deleteKeyValue(req: t.IDeleteKeyValueReq): Promise<t.IDeleteKeyValueRes>;

    /** Fetches all data from key-value store that starts with given prefix */
    searchKeyValues(req: t.ISearchKeyValuesReq): Promise<t.ISearchKeyValuesRes>;

    /** Fetches settings for error collection */
    getErrorCollectorSettings(req: t.IGetErrorCollectorSettingsReq): Promise<t.IGetErrorCollectorSettingsRes>;

    /** Triggers optimization of job-file-storage */
    optimizeJobFileStorage(req: t.IOptimizeJobFileStorageReq): Promise<t.IOptimizeJobFileStorageRes>;

    /** Triggers optimization of all storages */
    cleanupStorageDaily(req: t.ICleanupStorageDailyReq): Promise<t.ICleanupStorageDailyRes>;

    /** Sends telemetry points */
    sendTelemetryPoints(req: t.ISendTelemetryPointsReq): Promise<t.ISendTelemetryPointsRes>;

    /** Returns content of given file as string or null if it is not found */
    getBlobStorageItemAsStringOrNull(req: t.IGetBlobStorageItemAsStringOrNullReq): Promise<t.IGetBlobStorageItemAsStringOrNullRes>;

    /** Gets additional info of given file */
    getBlobStorageAdditionalInfo(req: t.IGetBlobStorageAdditionalInfoReq): Promise<t.IGetBlobStorageAdditionalInfoRes>;

    /** Registers file token */
    getBlobStorageFileToken(req: t.IGetBlobStorageFileTokenReq): Promise<t.IGetBlobStorageFileTokenRes>;

    /** Returns content of given file as string */
    getBlobStorageItemAsString(req: t.IGetBlobStorageItemAsStringReq): Promise<t.IGetBlobStorageItemAsStringRes>;

    /** Updates/inserts new blob item as string */
    setBlobStorageItemAsString(req: t.ISetBlobStorageItemAsStringReq): Promise<t.ISetBlobStorageItemAsStringRes>;

    /** Returns the list of all BLOB-storage items */
    getBlobStorageItemList(req: t.IGetBlobStorageItemListReq): Promise<t.IGetBlobStorageItemListRes>;

    /** Returns the list of all available log files. */
    getLogFiles(req: t.IGetLogFilesReq): Promise<t.IGetLogFilesRes>;

    /** Initialize connection */
    initializeMultipartConnection(req: t.IInitializeMultipartConnectionReq): Promise<t.IInitializeMultipartConnectionRes>;

    /** Gets chunks of file */
    setMultipartChunks(req: t.ISetMultipartChunksReq): Promise<t.ISetMultipartChunksRes>;

    /** Ends connection */
    endMultipartConnection(req: t.IEndMultipartConnectionReq): Promise<t.IEndMultipartConnectionRes>;

    /** Aborts file upload */
    abortMultipartUpload(req: t.IAbortMultipartUploadReq): Promise<t.IAbortMultipartUploadRes>;

    /** Admin shell */
    adminShell(req: t.IAdminShellReq): Promise<t.IAdminShellRes>;

    /** Create a new UUID */
    getUuid(req: t.IGetUuidReq): Promise<t.IGetUuidRes>;

}

export class Common implements ICommon {

    client: api.ApiClient;

    constructor(client: api.ApiClient) { this.client = client; }

    /** Gets features and their status (enabled/disabled) */
    async getFeaturesMatrix(req: t.IGetFeaturesMatrixReq): Promise<t.IGetFeaturesMatrixRes> {
        const reqx: any = req;
        const url = `/api/v1.0/features`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Gets indication if PIN login is allowed for any organization from caller's location */
    async canPinLoginFromCurrentLocation(req: t.ICanPinLoginFromCurrentLocationReq): Promise<t.ICanPinLoginFromCurrentLocationRes> {
        const reqx: any = req;
        const url = `/api/v1.0/can-login-via-pin`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Gets status of job */
    async getJobStatus(req: t.IGetJobStatusReq): Promise<t.IGetJobStatusRes> {
        const reqx: any = req;
        const url = `/api/v1.0/jobs/${enc(req.id)}/status`;
        delete reqx.id;
        return (await this.client.get(url, reqx)).data;
    }

    /** Gets detailed info of job */
    async getJobInfo(req: t.IGetJobInfoReq): Promise<t.IGetJobInfoRes> {
        const reqx: any = req;
        const url = `/api/v1.0/jobs/${enc(req.id)}/info`;
        delete reqx.id;
        return (await this.client.get(url, reqx)).data;
    }

    /** Downloads result of job */
    async getJobResultRaw(req: any, id: string): Promise<any> {
        const reqx: any = req;
        const url = `/api/v1.0/jobs/${enc(id)}/download-result`;
        delete reqx.id;
        return (await this.client.get(url, reqx)).data;
    }

    /** Gets result of job */
    async getJobResultRaw2(req: t.IGetJobResultRaw2Req): Promise<t.IGetJobResultRaw2Res> {
        const reqx: any = req;
        const url = `/api/v1.0/jobs/${enc(req.id)}/download-result-2`;
        delete reqx.id;
        return (await this.client.get(url, reqx)).data;
    }

    /** Deletes data about specified job */
    async deleteJob(req: t.IDeleteJobReq): Promise<t.IDeleteJobRes> {
        const reqx: any = req;
        const url = `/api/v1.0/jobs/${enc(req.id)}`;
        delete reqx.id;
        return (await this.client.delete(url, reqx)).data;
    }

    /** Updates data about specified job */
    async updateJob(req: t.IUpdateJobReq): Promise<t.IUpdateJobRes> {
        const reqx: any = req;
        const url = `/api/v1.0/jobs/${enc(req.id)}`;
        delete reqx.id;
        return (await this.client.put(url, reqx)).data;
    }

    /** Enqueues pending jobs for this org and returns the data */
    async enqueuePendingJobs(req: t.IEnqueuePendingJobsReq): Promise<t.IEnqueuePendingJobsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/jobs/enqueue-pending`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Performs job monitoring */
    async performJobsMonitoring(req: t.IPerformJobsMonitoringReq): Promise<t.IPerformJobsMonitoringRes> {
        const reqx: any = req;
        const url = `/api/v1.0/jobs/perform-monitoring`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Searches for jobs that meet search criteria */
    async searchJobs(req: t.ISearchJobsReq): Promise<t.ISearchJobsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/jobs`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Creates new job in the background */
    async createJob(req: t.ICreateJobReq): Promise<t.ICreateJobRes> {
        const reqx: any = req;
        const url = `/api/v1.0/jobs`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Fetches list of registered orgs */
    async getOrgs(req: t.IGetOrgsReq): Promise<t.IGetOrgsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/orgs`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Creates new org */
    async createOrg(req: t.ICreateOrgReq): Promise<t.ICreateOrgRes> {
        const reqx: any = req;
        const url = `/api/v1.0/orgs`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Update existing organisation */
    async updateCurrentOrganisation(req: t.IUpdateCurrentOrganisationReq): Promise<t.IUpdateCurrentOrganisationRes> {
        const reqx: any = req;
        const url = `/api/v1.0/org/current`;
        return (await this.client.put(url, reqx)).data;
    }

    /** Fetches organisation tags */
    async getCurrentOrganisation(req: t.IGetCurrentOrganisationReq): Promise<t.IGetCurrentOrganisationRes> {
        const reqx: any = req;
        const url = `/api/v1.0/org/current`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Fetches data from key-value store */
    async getKeyValue(req: t.IGetKeyValueReq): Promise<t.IGetKeyValueRes> {
        const reqx: any = req;
        const url = `/api/v1.0/key-value/${enc(req.key)}`;
        delete reqx.key;
        return (await this.client.get(url, reqx)).data;
    }

    /** Updates/inserts data inside key-value store */
    async setKeyValue(req: t.ISetKeyValueReq): Promise<t.ISetKeyValueRes> {
        const reqx: any = req;
        const url = `/api/v1.0/key-value/${enc(req.key)}`;
        delete reqx.key;
        return (await this.client.post(url, reqx)).data;
    }

    /** Deletes data inside key-value store */
    async deleteKeyValue(req: t.IDeleteKeyValueReq): Promise<t.IDeleteKeyValueRes> {
        const reqx: any = req;
        const url = `/api/v1.0/key-value/${enc(req.key)}`;
        delete reqx.key;
        return (await this.client.delete(url, reqx)).data;
    }

    /** Fetches all data from key-value store that starts with given prefix */
    async searchKeyValues(req: t.ISearchKeyValuesReq): Promise<t.ISearchKeyValuesRes> {
        const reqx: any = req;
        const url = `/api/v1.0/key-value`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Fetches settings for error collection */
    async getErrorCollectorSettings(req: t.IGetErrorCollectorSettingsReq): Promise<t.IGetErrorCollectorSettingsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/error-collector-settings`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Triggers optimization of job-file-storage */
    async optimizeJobFileStorage(req: t.IOptimizeJobFileStorageReq): Promise<t.IOptimizeJobFileStorageRes> {
        const reqx: any = req;
        const url = `/api/v1.0/job-file-storage/optimize`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Triggers optimization of all storages */
    async cleanupStorageDaily(req: t.ICleanupStorageDailyReq): Promise<t.ICleanupStorageDailyRes> {
        const reqx: any = req;
        const url = `/api/v1.0/storage/daily-cleanup`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Sends telemetry points */
    async sendTelemetryPoints(req: t.ISendTelemetryPointsReq): Promise<t.ISendTelemetryPointsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/storage/telemetry/submit`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Returns content of given file as string or null if it is not found */
    async getBlobStorageItemAsStringOrNull(req: t.IGetBlobStorageItemAsStringOrNullReq): Promise<t.IGetBlobStorageItemAsStringOrNullRes> {
        const reqx: any = req;
        const url = `/api/v1.0/blob-storage/text-or-null`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Gets additional info of given file */
    async getBlobStorageAdditionalInfo(req: t.IGetBlobStorageAdditionalInfoReq): Promise<t.IGetBlobStorageAdditionalInfoRes> {
        const reqx: any = req;
        const url = `/api/v1.0/blob-storage/getAdditionalInfo`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Registers file token */
    async getBlobStorageFileToken(req: t.IGetBlobStorageFileTokenReq): Promise<t.IGetBlobStorageFileTokenRes> {
        const reqx: any = req;
        const url = `/api/v1.0/blob-storage/registerFiles`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Returns content of given file as string */
    async getBlobStorageItemAsString(req: t.IGetBlobStorageItemAsStringReq): Promise<t.IGetBlobStorageItemAsStringRes> {
        const reqx: any = req;
        const url = `/api/v1.0/blob-storage/text`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Updates/inserts new blob item as string */
    async setBlobStorageItemAsString(req: t.ISetBlobStorageItemAsStringReq): Promise<t.ISetBlobStorageItemAsStringRes> {
        const reqx: any = req;
        const url = `/api/v1.0/blob-storage/text`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Returns the list of all BLOB-storage items */
    async getBlobStorageItemList(req: t.IGetBlobStorageItemListReq): Promise<t.IGetBlobStorageItemListRes> {
        const reqx: any = req;
        const url = `/api/v1.0/blob-storage/list`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Returns the list of all available log files. */
    async getLogFiles(req: t.IGetLogFilesReq): Promise<t.IGetLogFilesRes> {
        const reqx: any = req;
        const url = `/api/v1.0/log-files/list`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Initialize connection */
    async initializeMultipartConnection(req: t.IInitializeMultipartConnectionReq): Promise<t.IInitializeMultipartConnectionRes> {
        const reqx: any = req;
        const url = `/api/v1.0/upload-multipart/init`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Gets chunks of file */
    async setMultipartChunks(req: t.ISetMultipartChunksReq): Promise<t.ISetMultipartChunksRes> {
        const reqx: any = req;
        const url = `/api/v1.0/upload-multipart/chunks`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Ends connection */
    async endMultipartConnection(req: t.IEndMultipartConnectionReq): Promise<t.IEndMultipartConnectionRes> {
        const reqx: any = req;
        const url = `/api/v1.0/upload-multipart/end`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Aborts file upload */
    async abortMultipartUpload(req: t.IAbortMultipartUploadReq): Promise<t.IAbortMultipartUploadRes> {
        const reqx: any = req;
        const url = `/api/v1.0/upload-multipart/abort`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Admin shell */
    async adminShell(req: t.IAdminShellReq): Promise<t.IAdminShellRes> {
        const reqx: any = req;
        const url = `/api/v1.0/shell`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Create a new UUID */
    async getUuid(req: t.IGetUuidReq): Promise<t.IGetUuidRes> {
        const reqx: any = req;
        const url = `/api/v1.0/uuid/new`;
        return (await this.client.get(url, reqx)).data;
    }

}
