// @flow

import * as React from "react";
import ReactRouterPropTypes from "react-router-prop-types";

import { getBackend } from "../lib/Backend";
import type { DashboardObj } from "../lib/Models";
import { parseUrlQueryVariables } from "../lib/Util";
import Dashboard from "./dashboard/Dashboard";

import type { GanttChartSourceTypes } from "./manufacturing/PlanningTable2/reducers/properties";

type State = {
    selected: DashboardObj | null,
    selected_data: any | null,
    selected_layout: any | null,
    error: string | null,
    uuid: string | null,
    from: number,
    to: number,
    params: any
};

type Props = {
    history: ReactRouterPropTypes.history,
    match: ReactRouterPropTypes.match,
    uuid: string,
    asPublic: boolean,
    show_filter_bar: boolean,
    source_type?: GanttChartSourceTypes
};

function getIntervalLength(range: string): number {
    range = range.toLowerCase();
    const matches = /^([0-9]+)([hdwmy])$/gi.exec(range);
    if (!matches) {
        throw new Error("Invalid interval definition: " + range);
    }
    let a = +matches[1];
    const b = matches[2];
    switch (b) {
        //case "s": a *= 1000; break;
        //case "m": a *= 60 * 1000; break;
        case "h": a *= 60 * 60 * 1000; break;
        case "d": a *= 24 * 60 * 60 * 1000; break;
        case "w": a *= 7 * 24 * 60 * 60 * 1000; break;
        case "m": a *= 30 * 24 * 60 * 60 * 1000; break;
        case "y": a *= 365 * 24 * 60 * 60 * 1000; break;
        default: throw new Error("Unsupported range: " + range);
    }
    return a;
}

class DashboardStandalone extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            selected: null,
            selected_data: null,
            selected_layout: null,
            error: null,
            uuid: null,
            from: -1,
            to: -1,
            params: {}
        };
    }

    // load dashboard from the backend
    loadDashboard = () => {
        const uuid = this.props.uuid ? this.props.uuid : this.props.match.params.uuid;
        this.setState({ uuid: uuid });

        if (this.props.asPublic) {
            getBackend().dash.getSingleDashboardPublic(uuid, (selected) => {
                if (uuid !== this.state.uuid) {
                    return;
                }
                this.setState({ selected });
                getBackend().dash.executeDashboardPublic(
                    uuid, this.state.from, this.state.to, this.state.params,
                    (res) => {
                        if (uuid !== this.state.uuid) {
                            return;
                        }
                        this.setState({ selected_data: res.data, selected_layout: res.layout });
                    },
                    (err) => {
                        if (uuid !== this.state.uuid) {
                            return;
                        }
                        this.setState({ error: err });
                    }
                );
            },
            (err) => {
                if (uuid !== this.state.uuid) {
                    return;
                }
                this.setState({ error: err });
            });
        } else {
            getBackend().dash.getSingleDashboard(uuid, (selected) => {
                if (uuid !== this.state.uuid) {
                    return;
                }
                this.setState({ selected });
                getBackend().dash.executeDashboard(
                    uuid, this.state.from, this.state.to, this.state.params,
                    (res) => {
                        if (uuid !== this.state.uuid) {
                            return;
                        }
                        this.setState({ selected_data: res.data, selected_layout: res.layout });
                    },
                    (err) => {
                        if (uuid !== this.state.uuid) {
                            return;
                        }
                        this.setState({ error: err });
                    }
                );
            },
            (err) => {
                if (uuid !== this.state.uuid) {
                    return;
                }
                this.setState({ error: err });
            });
        }
    };

    componentDidMount() {
        const params = parseUrlQueryVariables();
        this.setState({ params });
    }

    componentDidUpdate(prevProps: Props) {
        const uuid = this.props.uuid ? this.props.uuid : this.props.match.params.uuid;
        if (uuid !== this.state.uuid) {
            this.loadDashboard();
        }
    }

    setRange = (range: string) => {
        const len = getIntervalLength(range);
        const now = Date.now();
        this.setState({ from: now - len, to: now });
        this.loadDashboard();
    };

    render() {
        let rangechange = (
            !this.state.selected_layout || this.state.selected_layout.no_range_selector
                ? null
                : (range) => this.setRange(range));

        return (<Dashboard
            dash_data={this.state.selected_data}
            dash_definition={this.state.selected_layout}
            range_change={rangechange}
            show_filter_bar={this.props.show_filter_bar}
            source_type={this.props.source_type}
            history={this.props.history}
        />);
    }
};

export default DashboardStandalone;
