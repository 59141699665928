// @flow
import * as React from "react";
import { mapStatusToStyle } from "./DashboardUtils";
import { niceNumber } from "../../lib/Util";

type Props = {
    label: string,
    value: number,
    unit?: string,
    indicator_type?: string,
    indicator_style: string,
    dec_places?: number,
    value_display_type?: string,
    onClick: any
};
type State = { };

class Indicator extends React.Component<Props, State> {
    constructor() {
        super();
        this.state = {};
    }

    render() {
        // figure out style class
        const style = "dsh_indicator_container " + this.props.indicator_style;
        let value = this.props.value;
        let unit = this.props.unit || "";
        if (this.props.value_display_type === "percent") {
            value *= 100;
            unit = "% " + unit;
        }

        let val = "" + value;
        if (this.props.dec_places !== undefined) {
            val = niceNumber(value, this.props.dec_places);
        }
        // create element
        if (this.props.indicator_type === "compact") {
            // compact type
            return (
                <div className={style} onClick={() => this.props.onClick() }>
                    <div className="dsh_indicator_content">
                        <h1>{this.props.label}</h1>
                        <h2>
                            {val}{unit}
                        </h2>
                    </div>
                </div>
            );
        } else if (this.props.indicator_type === "tiny") {
            return (
                <div className={style} onClick={() => this.props.onClick() }>
                    <div className="dsh_indicator_content">
                        <h3>{this.props.label}</h3>
                        <h5>
                            {val}{unit}
                        </h5>
                    </div>
                </div>
            );
        } else {
            // normal type
            return (
                <div className={style} onClick={() => this.props.onClick() }>
                    <div className="dsh_indicator_content">
                        {this.props.label}: {val}{unit}
                    </div>
                </div>
            );
        }
    }
}

export function createIndicator(data: any, widget: any) {
    const hits = data.indicators.filter(x => x.key === widget.key);
    if (hits.length === 0) {
        return (<div className={"dsh_indicator_container " + mapStatusToStyle("error")}> Indicator not found {widget.key} </div>);
    }
    const hit = hits[0];
    hit.type = hit.type || "ts";
    const url = widget.url;
    if (hit.type === "ts" || hit.type === "aggr") {
        let indicator_type = widget.indicator_type || "normal";
        let value_display_type = widget.value_display_type || "normal";
        let dec_places = widget.dec_places;
        let value = +hit.val;
        let indicator_style = mapStatusToStyle(hit.status);
        return (<Indicator label={widget.label} value={value} indicator_type={indicator_type}
            indicator_style={indicator_style} unit={widget.unit} dec_places={dec_places}
            value_display_type={value_display_type} onClick={() => { if (url) { window.location = url; } } }
        />);
    } else if (hit.type === "line") {
        const gui_status = hit.status === "on" ? "ok" : (hit.status === "downtime" ? "error" : "inactive");
        const style = "dsh_indicator_container dsh_indicator_container_glued " + mapStatusToStyle(gui_status);
        const status_scrap_val = (hit.scrap >= 0.05 ? "error" : (hit.scrap >= 0.01 ? "warning" : "ok"));
        const status_scrap = "dsh_indicator_bullet " + mapStatusToStyle(status_scrap_val, "dsh_bullet_style_");
        const status_downtime_val = (hit.downtime >= 2 ? "error" : (hit.downtime >= 1 ? "warning" : "ok"));
        const status_downtime = "dsh_indicator_bullet " + mapStatusToStyle(status_downtime_val, "dsh_bullet_style_");
        const status_workorder = "dsh_indicator_bullet " + mapStatusToStyle(hit.workorder_is_late ? "error" : "ok", "dsh_bullet_style_");
        let parts_text = niceNumber(hit.parts_produced, 0) + " / " + niceNumber(hit.parts_total, 0);
        if (hit.has_live_data) {
            parts_text =
                niceNumber(hit.parts_current, 0) + " / " +
                niceNumber(hit.parts_produced, 0) + " / " +
                niceNumber(hit.parts_total, 0);
        }
        const style_outter = (url ? "dsh_indicator_pointer" : "");
        return (<div className={style_outter} onClick={() => { if (url) { window.location = url; } } }>
            <div className={style}>
                <div className="dsh_indicator_content">
                    <h2>{hit.title}</h2>
                    <h3>{hit.product}</h3>
                    <h3>{hit.person}</h3>
                </div>
            </div>
            <div className="dsh_indicator_body white_box white_box_glued charts">
                <p> <span className={status_workorder}></span> Parts: {parts_text} </p>
                <p> <span className={status_downtime}></span> Downtime: {niceNumber(hit.downtime, 2)} h </p>
                <p> <span className={status_scrap}></span> Scrap: {niceNumber(hit.scrap * 100, 1)} % </p>
            </div>
        </div>);
    } else {
        return (<div className={"dsh_indicator_container " + mapStatusToStyle("error")}>
            <div className="dsh_indicator_content">
                <h4>
                    mismatch - indicators other than simple timeseries (type 'ts') and manufacturing line (type 'line') are not supported on classic dashboard type
                    - {hit.type}
                </h4>
            </div>
        </div>);
    }
}

export default Indicator;
