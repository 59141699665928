// @flow
import * as React from "react";
import { connect } from "react-redux";

import Authorization from "../../../Authorization";
import * as Auth from "../../../../lib/Auth";
import {
    translate,
    getLang
} from "../../../IntlProviderWrapper";

import { getBackend as getBackend2 } from "../../../../lib/backend/Backend2";
import {
    getPlantTags,
    getLineGroupTagInt
} from "../../../../lib/BusinessLogic";

import {
    PLANNING_TABLE_SAVE_TO_LEAP_MODE,
    PLANT_TAGS_ACCESS,
    LINE_TAGS,
    ORDER_TAGS,
    ORDER_TAGS_ACCESS,
    PARALLEL_ORDER_COPY_TAGS,
} from "../../../../lib/ManufacturingTags.generated";
import {
    ORDER_TYPE,
    PLAN_IMPORT_TYPE
} from "../../../../lib/ManufacturingConsts.generated";
import { JOB_STATUS } from "../../../../lib/CommonConsts.generated";

import OrderChangesModal from "./OrderChangesModal";
import {
    SET_TRANSLATIONS_MATERIALS
} from "../../../redux/reducers/translationsMaterialsReducer";
import {
    ReportLogic,
    LinesOrdersLogic
} from "../reducers/BusinessLogic";
import { SAVE_TO_SAP_PARALLEL_REPORT_LOADING } from "../reducers/parallelReport";
import { CLEAR_IGNORE_ORDERS_STATUS, CLEAR_EXTRA_LINES_OPERATION_LINKS } from "../reducers/linesOrders";
import { SelectedOrdersConsumer } from "./SelectedOrdersContext";
import { LinesOrdersMaps } from "../reducers/Mappers";

import type{
    IShiftLineRec,
    IShiftUpdateRec,
    IOrderProducedModel,
    IEventDataEx,
    IRunImportPlanOnDemandReq,
    IExtraLines,
    IUpdateOrderType,
    IInsertChunkOrderType
} from "../../../../lib/backend/manufacturing2.generated.types";
import type {
    IGetJobStatusRes,
    ITags
} from "../../../../lib/backend/common.generated.types";
import type {
    IRunPlanTableExportReq,
    IReportExportOrderChange,
    IReportExportOperationChange,
    IRunPlanTableExportResultOutput,
    IReportExportExtraLinesOrderChange
} from "../../../../lib/backend/reports.generated.types";
import type {
    LineOrders,
    IgnoreOrdersMap,
    ClearIgnoreOrdersStatusAction,
    ClearExtraLinesOperationLinksAction,
    OperationLink
} from "../reducers/linesOrders";
import type { ReduxState } from "../reducers/reducers";
import type { ReportParameters } from "../reducers/common";
import type {
    SaveToSapParallelReportLoading,
    RemoveReportToDelete
} from "../reducers/parallelReport";
import type { SetTranslationsMaterials } from "../../../redux/reducers/translationsMaterialsReducer";
import type { Warning } from "../reducers/BusinessLogic";
import type { SelectedOrdersProviderValue } from "./SelectedOrdersContext";
import type { PropertiesState } from "../reducers/properties";

const getLineUuids = (lines_orders: LineOrders[] | null): string[] => {
    if (!lines_orders) return [];
    return lines_orders.map(l => l.line_uuid);
}

export const getChangedShifts = (
    prev_props_lines_orders: LineOrders[] | null,
    props_lines_orders: LineOrders[] | null,
    state_shifts: IShiftLineRec[]
) => {

    const getKey = (line_uuid: string, shift_uuid: string) => {
        return line_uuid + "_" + shift_uuid;
    }

    const previous_shifts_map = new Map<string, IShiftLineRec>(); // used to compare if data has changed
    const new_shifts_map = new Map<string, IShiftLineRec>(); // has new data
    const state_shifts_map = new Map<string, IShiftLineRec>();

    if (prev_props_lines_orders) {
        for (const prev_line_orders of prev_props_lines_orders)  {
            for (const shift of prev_line_orders.shifts) {
                const key = getKey(shift.line_uuid, shift.shift_uuid);
                previous_shifts_map.set(key, shift);
            }
        }
    }

    if (props_lines_orders) {
        for (const curr_line_orders of props_lines_orders)  {
            for (const new_shift of curr_line_orders.shifts) {
                const key = getKey(new_shift.line_uuid, new_shift.shift_uuid);
                new_shifts_map.set(key, new_shift);
            }
        }
    }

    for (const shift of state_shifts) {
        const key = getKey(shift.line_uuid, shift.shift_uuid);
        state_shifts_map.set(key, shift);
    }

    let has_changed = false;
    for (const [key, new_shift] of new_shifts_map.entries()) {
        const previous_shift = previous_shifts_map.get(key);
        if (previous_shift) {
            if (new_shift.enabled !== previous_shift.enabled) {
                has_changed = true;
                state_shifts_map.set(key, new_shift);
            }
        }
    }

    if (has_changed) {
        return [...state_shifts_map.values()];
    }

    return state_shifts;
}

export const saveShifts = async (changed_shifts: IShiftLineRec[]): Promise<string | boolean> => {
    const save_shifts: Array<IShiftUpdateRec> = []

    if (changed_shifts.length > 0) {
        for (const shift of changed_shifts) {
            save_shifts.push({
                enabled: shift.enabled,
                line_uuid: shift.line_uuid,
                shift_uuid: shift.shift_uuid
            });
        }

        try {
            await getBackend2().manufacturing.updateShifts({ shifts: save_shifts });
        } catch (e) {
            return e.message;
        }
    }

    return true;
}

function mapParents(sim_ignore_earliest_start: boolean) {
    const orders = [];
    // go over parents and prepare their update in the database
    for (const [, value] of LinesOrdersMaps.parents_of_chunks.entries()) {
        const order = value;
        const order_tags = order.tags;
        order_tags[ORDER_TAGS.split_chunk_parent] = "true";
        const new_data: IUpdateOrderType = {
            sim_ignore_earliest_start,
            tags: order_tags,
            uuid: order.uuid,
            skip_sim_internal: true
        }
        orders.push(new_data);
    }
    return orders;
}

function mapLinesOrdersToInput(
    lines_orders: LineOrders[],
    sim_ignore_earliest_start: boolean,
    save_skip_sim: boolean,
    full_save: boolean,
    skip_sim: boolean,
    order_changes_map: Map<string, IReportExportOrderChange>,
    operation_changes_map: Map<string, IReportExportOperationChange>
): IUpdateOrderType[] {
    const orders: IUpdateOrderType[] = [];
    for (const line_order of lines_orders) {
        // set is_on_multiple_machine_line flag
        // for all orders on the line that has tag multiple_machines_child
        const is_on_multiple_machine_line = (line_order.tags[LINE_TAGS.multiple_machines_child] === "true");

        for (const order of line_order.orders) {
            const order_tags = order.tags;
            const new_data: IUpdateOrderType = {
                override_capacity_factor: order.override_capacity_factor,
                sim_ignore_earliest_start,
                tags: order_tags,
                uuid: order.uuid
            }
            // always save process_uuid of orders on multiple machine lines
            if (is_on_multiple_machine_line) {
                new_data.process_uuid = order.process_uuid;
            }

            if (save_skip_sim) {
                new_data.skip_sim = skip_sim || order.skip_sim;
            }

            // for split chunks we are responsible for saving all the changes
            const is_split_operation_chunk = LinesOrdersLogic.isChunkOrder(order);

            if (is_split_operation_chunk && new_data.tags !== undefined) {
                new_data.tags[ORDER_TAGS.split_chunk_index] = "0";
            }

            if (full_save || is_split_operation_chunk) {
                const op_changes = operation_changes_map.get(order.uuid);
                // get time changes to the operation
                if (op_changes !== undefined) {
                    new_data.earliest_end = op_changes.final_epoch_end;
                    new_data.earliest_start = op_changes.final_epoch_start;
                    new_data.time_start = op_changes.final_epoch_start;
                    new_data.sequence_weight = op_changes.final_epoch_start;
                    // in case we have a chunk, also update operation quantity
                    if (order.order_type === ORDER_TYPE.chunk && op_changes.final_chunk_total_quantity !== null) {
                        new_data.quantity_total = op_changes.final_chunk_total_quantity;
                    }
                }
                // check if there are order level changes
                const order_changes = order_changes_map.get(order.external_id);
                if (order_changes !== undefined) {
                    if (order.order_type !== ORDER_TYPE.chunk && order_changes.final_quantity !== null) {
                        new_data.quantity_total = order_changes.final_quantity;
                    }
                }
                // update process uuid in case order moved between lines
                new_data.process_uuid = order.process_uuid;
                new_data.skip_sim = skip_sim || order.skip_sim;
                // we save absolute times
                new_data.sim_ignore_earliest_start = false;
            }

            orders.push(new_data);
        }
    }
    return orders;
}

const updateOrdersDB = async (
    orders: IUpdateOrderType[],
    onSuccess: () => void,
    onError: (e: Error) => void,
    is_scheduling_owner: boolean
) => {
    try {
        const parent_orders = [];
        for (const order of orders) {
            if (order.tags) {
                const order_tags = order.tags;
                if (ORDER_TAGS_ACCESS.parallel_parent(order.tags)) {
                    parent_orders.push(order);
                }
                if (is_scheduling_owner) {
                    order_tags[ORDER_TAGS.scheduling_owner] = "true";
                }
            }
        }

        if (parent_orders.length > 0) {
            // fetch child orders
            const res = await getBackend2().manufacturing.getOrdersSimple({
                parent_order_uuids: parent_orders.map(po => po.uuid)
            });
            // filter out parent orders
            const new_orders = orders;
            for (const operation of res.operations) {
                const parent_order = orders.find(uo => uo.uuid === operation.parent_order_uuid)
                if (parent_order) {
                    const tags = operation.tags;
                    if (is_scheduling_owner) {
                        tags[ORDER_TAGS.scheduling_owner] = "true";
                    }
                    const parent_tags = parent_order.tags;
                    if (parent_tags) {
                        tags[ORDER_TAGS.freeze_time_end] = parent_tags[ORDER_TAGS.freeze_time_end];
                        tags[ORDER_TAGS.freeze_time_start] = parent_tags[ORDER_TAGS.freeze_time_start];
                        tags[ORDER_TAGS.insight_freeze_order] = parent_tags[ORDER_TAGS.insight_freeze_order];
                        tags[ORDER_TAGS.comment] = parent_tags[ORDER_TAGS.comment];
                        tags[ORDER_TAGS.fix_line] = parent_tags[ORDER_TAGS.fix_line];
                        tags[ORDER_TAGS.subline_index] = parent_tags[ORDER_TAGS.subline_index];
                        tags[ORDER_TAGS.linked_operation] = parent_tags[ORDER_TAGS.linked_operation];
                        // copy tags from parent order to children
                        for (const tag of Object.keys(PARALLEL_ORDER_COPY_TAGS)) {
                            tags[tag] = parent_tags[tag];
                        }
                    }

                    new_orders.push({
                        uuid: operation.uuid,
                        override_capacity_factor: parent_order.override_capacity_factor,
                        skip_sim: parent_order.skip_sim,
                        tags
                    });
                }
            }
            orders = new_orders;
        }

        if (orders.length > 0) {
            try {
                await getBackend2().manufacturing.updateOrders({ orders });
                onSuccess();
            } catch (e) {
                console.error(e);
                onError(e);
            }
        }
    } catch (err) {
        console.log("Error in save unscheduled: " + err.message);
    }
}

 const getExtraLinesInput = async (
    lines_orders: LineOrders[],
    unscheduled_orders: LineOrders[],
    ignored_orders: LineOrders[],
    ignore_orders_status: IgnoreOrdersMap | null,
    extra_lines_operation_links: OperationLink[],
    extra_lines_order_changes: IReportExportExtraLinesOrderChange[] | null,
    save_skip_sim: boolean
): Promise<IUpdateOrderType[]> => {
    const orders_map: Map<string, IUpdateOrderType> = new Map();
    if (extra_lines_order_changes !== null && save_skip_sim) {
        for (const order_change of extra_lines_order_changes) {
            if (order_change.op_status_change === "deallocate") {
                orders_map.set(order_change.order_uuid, {
                    uuid: order_change.order_uuid,
                    skip_sim: true
                });
            } else if (order_change.op_status_change === "dispatch") {
                orders_map.set(order_change.order_uuid, {
                    uuid: order_change.order_uuid,
                    skip_sim: false
                });
            }
        }
    }

    const extra_ignore_orders_status: IgnoreOrdersMap = ignore_orders_status !== null
        ? new Map(ignore_orders_status)
        : new Map();
    for (const line of [...lines_orders, ...unscheduled_orders, ...ignored_orders]) {
        for (const order of line.orders) {
            extra_ignore_orders_status.delete(order.uuid);
        }
    }

    try {
        const uuids = [...new Set([
            ...extra_ignore_orders_status.keys(),
            ...extra_lines_operation_links.map(operation_link => operation_link[0])
        ])];
        if (uuids.length > 0) {
            const { operations } = await getBackend2().manufacturing.getOrdersSimple({ uuids });
            const operation_links_map = new Map(extra_lines_operation_links);
            for (const operation of operations) {
                const update_order: IUpdateOrderType = orders_map.get(operation.uuid) || {
                    uuid: operation.uuid
                };
                const tags: ITags = { ...operation.tags, ...(update_order.tags || {}) };
                const ignore_status = extra_ignore_orders_status.get(operation.uuid);
                if (ignore_status !== undefined) {
                    const is_ignored = ignore_status === true;
                    update_order.skip_sim = save_skip_sim && is_ignored || operation.skip_sim;
                    tags[ORDER_TAGS.ignore_on_planning_board] = is_ignored ? "true" : "false";
                }

                const linked_operation = operation_links_map.get(operation.uuid);
                if (linked_operation !== undefined) {
                    if (linked_operation !== null) {
                        tags[ORDER_TAGS.linked_operation] = linked_operation;
                    } else {
                        delete tags[ORDER_TAGS.linked_operation];
                    }
                }

                orders_map.set(operation.uuid, {
                    ...update_order,
                    tags
                });
            }
        }
    } catch (error) {
        console.error(error);
    }

    return [...orders_map.values()];
};

const insertNewChunksIntoDB = async (
    line_orders: LineOrders[]
) => {
    const order_to_insert: IInsertChunkOrderType[] = [];

    for (const line of line_orders) {
        for (const order of line.orders) {
            // save only chunks that do not have tag yet indicating they are chunk (added when saving)
            if (LinesOrdersLogic.isChunkOrder(order) &&
                order.tags[ORDER_TAGS.split_chunk_index] == null &&
                order.parent_order_uuid) {

                order_to_insert.push({
                    chunk_order_uuid: order.uuid,
                    generated_by: order.generated_by,
                    parent_order_uuid: order.parent_order_uuid
                });
            }
        }
    }

    if (order_to_insert.length > 0) {
        await getBackend2().manufacturing.insertChunkOrders({ chunks: order_to_insert });
    }
}

type Props = {
    plant_uuid: string,
    plant_external_id: string,
    line_group_uuid: string,
    parallel_current_report_uuid: string | null,
    lines_orders: LineOrders[] | null,
    saveShifts: () => Promise<void>,
    parameters: ReportParameters,
    reduxDispatch: (
        args: RemoveReportToDelete
            | SetTranslationsMaterials
            | SaveToSapParallelReportLoading
            | ClearIgnoreOrdersStatusAction
            | ClearExtraLinesOperationLinksAction
    ) => void,
    parallel_original_report_uuid: string,
    extra_lines: IExtraLines[],
    unscheduled_orders: LineOrders[],
    ignored_orders: LineOrders[],
    ignore_orders_status: IgnoreOrdersMap | null,
    extra_lines_operation_links: OperationLink[],
    insights: IEventDataEx[] | null,
    filtered_orders: string[],
    properties: PropertiesState
}

type State = {
    show_modal: boolean,
    orders: IOrderProducedModel[],
    save_report: IRunPlanTableExportResultOutput | null,
    order_changes: IReportExportOrderChange[],
    extra_lines_order_changes: IReportExportExtraLinesOrderChange[],
    changed_shifts: IShiftLineRec[],
    parallel_current_report_uuid: string | null,
    last_current_parameters: ReportParameters | null,
    is_loading: boolean,
    error: string,
    warnings: Warning[],
    import_job_status: IGetJobStatusRes | null
};

class SaveToSap extends React.Component<Props, State> {

    state = {
        show_modal: false,
        save_report: null,
        order_changes: [],
        extra_lines_order_changes: [],
        orders: [],
        order_export_result: null,
        changed_shifts: [],
        parallel_current_report_uuid: null,
        last_current_parameters: null,
        is_loading: false,
        error: "",
        import_job_status: null,
        warnings: []
    }

    componentDidUpdate(prev_props: Props) {
        const prev_lines_orders = prev_props.lines_orders;
        const lines_orders = this.props.lines_orders;
        if (prev_lines_orders !== lines_orders && prev_lines_orders && lines_orders) {
            this.refresh(prev_lines_orders, lines_orders);
        }
    }

    refresh = (prev_lines_orders: LineOrders[], lines_orders: LineOrders[]) => {
        const changed_shifts = getChangedShifts(
            prev_lines_orders,
            lines_orders,
            this.state.changed_shifts
        );

        if (this.state.changed_shifts !== changed_shifts) {
            this.setState({ changed_shifts });
        }
    }

    onCloseModal = () => {
        this.setState({
            show_modal: false,
            save_report: null,
            order_changes: [],
            extra_lines_order_changes: [],
            import_job_status: null
        });
    }

    saveShifts = async () => {
        return saveShifts(this.state.changed_shifts);
    }

    getImportPlanOndemandData = (import_type: string): IRunImportPlanOnDemandReq => {
        const order_ids = new Set();
        const plan_order_ids = new Set();

        for (const line of this.props.lines_orders || []) {
            if (this.props.extra_lines.some(l => l.line === line.line_uuid)) continue;
            for (const order of line.orders) {
                if (order.order_type === ORDER_TYPE.plan) {
                    plan_order_ids.add(order.external_id)
                } else {
                    order_ids.add(order.external_id);
                }
            }
        }

        for (const unscheduled_line of this.props.unscheduled_orders) {
            for (const unscheduled_order of unscheduled_line.orders) {
                if (unscheduled_order.order_type === ORDER_TYPE.plan) {
                    plan_order_ids.add(unscheduled_order.external_id);
                } else {
                    order_ids.add(unscheduled_order.external_id);
                }
            }
        }

        if (import_type == PLAN_IMPORT_TYPE.lists) {
            return {
                order_ids: [...order_ids],
                plan_order_ids: [...plan_order_ids],
                import_type: PLAN_IMPORT_TYPE.lists,
                plant_external_id: this.props.plant_external_id
            }
        } else if (import_type == PLAN_IMPORT_TYPE.plant) {
            const plan_import_weeks = Math.floor((
                getLineGroupTagInt(this.props.line_group_uuid, "planning_table_horizon_days", 7 * 3)) / 7);
            return {
                import_type: PLAN_IMPORT_TYPE.plant,
                plan_import_weeks,
                plant_external_id: this.props.plant_external_id
            }
        } else {
            throw new Error("Unsupported import_type: " + import_type);
        }
    }

    getImportPlanStatus = async (job_uuid: string): Promise<string | boolean> => {
        try {
            const res = await getBackend2().common.getJobStatus({ id: job_uuid });
            this.setState({ import_job_status: res });
            const status = res.status;
            if (status === JOB_STATUS.ended || status === JOB_STATUS.errored) {
                const res2 = await getBackend2().manufacturing.getImportPlanOnDemandResult({ job_uuid });
                if (status === JOB_STATUS.errored) {
                    return res2.result;
                } else {
                    return true;
                }
            } else {
                await new Promise((resolve) => setTimeout(resolve, 1500));
                return await this.getImportPlanStatus(job_uuid);
            }
        } catch (e) {
            return e.message;
        }
    }

    importPlan = async (import_type: string): Promise<boolean | string> => {
        try {
            const data = this.getImportPlanOndemandData(import_type);
            const res = await getBackend2().manufacturing.runImportPlanOnDemand(data);
            return await this.getImportPlanStatus(res.job_uuid);
        } catch (e) {
            return e.message;
        }
    }

    saveToSap = async (order_uuids: string[]): Promise<boolean | string> => {
        const original_report_uuid = this.props.parallel_original_report_uuid;
        const line_uuids = getLineUuids(this.props.lines_orders);
        const plant_uuid = this.props.plant_uuid;
        if (original_report_uuid != undefined && line_uuids != undefined && plant_uuid != null) {
            const plant_tags = getPlantTags(plant_uuid);
            // check if we do full save (including times and lines) or light save (just tags and potentialy scheduled status)
            const save_to_leap_mode = PLANT_TAGS_ACCESS.planning_table_save_to_leap_mode(plant_tags ?? {});
            const is_full_save = (save_to_leap_mode == PLANNING_TABLE_SAVE_TO_LEAP_MODE.full);
            // this tag defines if we have externally controlled skip_sim (not stored in ERP)
            const save_skip_sim = !PLANT_TAGS_ACCESS.plan_import_scheduled_statuses(plant_tags ?? {});
            // prepare params for runPlanTableExport
            const params: IRunPlanTableExportReq = {
                initial_report_uuid: original_report_uuid,
                final_report_uuid: this.state.parallel_current_report_uuid || original_report_uuid,
                line_uuids,
                order_changes: this.state.order_changes,
                extra_lines_order_changes: this.state.extra_lines_order_changes,
                orders_to_update: []
            }

            // prepare map from order_uuid to changes in case
            // we do full save or if we have any chunked orders
            const order_changes_map: Map<string, IReportExportOrderChange> = new Map();
            const operation_changes_map: Map<string, IReportExportOperationChange> = new Map();
            for (const order of this.state.order_changes) {
                order_changes_map.set(order.order_external_id, order);
                for (const op_change of order.op_changes) {
                    operation_changes_map.set(op_change.order_uuid, op_change);
                }
            }

            // split order_uuids into orders from planned lines and from extra lines
            const order_uuid_set: Set<string> = new Set(order_uuids);
            const filter_order_uuids_set: Set<string> = new Set();
            for (const order of this.state.order_changes) {
                for (const op_change of order.op_changes) {
                    filter_order_uuids_set.add(op_change.order_uuid);
                }
            }
            const filter_extra_lines_order_uuids_set: Set<string> = new Set();
            for (const order of this.state.extra_lines_order_changes) {
                filter_extra_lines_order_uuids_set.add(order.order_uuid);
            }
            params.filter_order_uuids = order_uuids.filter(uuid => filter_order_uuids_set.has(uuid));
            params.filter_extra_lines_order_uuids = order_uuids.filter(uuid => filter_extra_lines_order_uuids_set.has(uuid));

            try {
                // save to LEAP
                let orders = this.state.orders;
                // prepare list of schedueld orders selected to save
                const lines_orders = this.props.lines_orders || [];
                const scheduled_orders = LinesOrdersLogic.filterLinesOrdersByOrderUuids(lines_orders, order_uuids);
                // prepare list of unscheduled orders selected to save
                const unscheduled__line_orders = this.props.unscheduled_orders || [];
                const unscheduled_orders = LinesOrdersLogic.filterLinesOrdersByOrderUuids(unscheduled__line_orders, order_uuids);

                // sim_ignore_earliest_start is set to false when saving planning board
                // it's true only during editing for easy snapping with drag and drop
                const sim_ignore_earliest_start = false;

                // prepare and insert new chunks to into the database
                await insertNewChunksIntoDB(scheduled_orders);

                // prepare database inputs for scheduled orders (skip_sim = false)
                const scheduled_input = mapLinesOrdersToInput(scheduled_orders, sim_ignore_earliest_start,
                    save_skip_sim, is_full_save, false, order_changes_map, operation_changes_map);
                // prepare database inputs for unscheduled orders (skip_sim = true)
                const unscheduled_input = mapLinesOrdersToInput(unscheduled_orders, sim_ignore_earliest_start,
                    save_skip_sim, is_full_save, true, order_changes_map, operation_changes_map);
                // prepare database inputs for ignored orders (skip_sim = true)
                const ignored_input = mapLinesOrdersToInput(this.props.ignored_orders, sim_ignore_earliest_start,
                    save_skip_sim, is_full_save, true, order_changes_map, operation_changes_map);
                // prepare database inputs to update parent orders
                const parent_input = mapParents(sim_ignore_earliest_start);

                // filter extra lines order changes by order_uuids
                const extra_lines_order_changes = this.state.extra_lines_order_changes.filter(order_change => order_uuid_set.has(order_change.order_uuid));
                // map extra lines order changes to input
                const extra_lines_input = await getExtraLinesInput(scheduled_orders, unscheduled_orders, this.props.ignored_orders,
                    this.props.ignore_orders_status, this.props.extra_lines_operation_links, extra_lines_order_changes, save_skip_sim);

                // save to LEAP
                const orders_to_update = [
                    ...scheduled_input,
                    ...unscheduled_input,
                    ...ignored_input,
                    ...extra_lines_input,
                    ...parent_input
                ]
                params.orders_to_update = orders_to_update;
                await updateOrdersDB(
                    orders_to_update,
                    () => {
                        this.props.reduxDispatch({ type: CLEAR_IGNORE_ORDERS_STATUS, data: undefined });
                        this.props.reduxDispatch({ type: CLEAR_EXTRA_LINES_OPERATION_LINKS, data: undefined });
                    },
                    (e) => { throw Error(e.message) },
                    true
                );

                // update orders with fresh database data
                orders = await this.getOrders(this.state.order_changes, this.state.extra_lines_order_changes);

                // save to ERP
                const res = await getBackend2().reports.runPlanTableExport(params);
                const result = await ReportLogic.waitForExport(res.job_uuid);
                const save_report = result.output;

                this.setState({ save_report, orders });
            } catch(e) {
                const client_side_error = `Error while saving to SAP for line group ${this.props.line_group_uuid}: ` + e.message
                await getBackend2().tracking.logClientsideError(
                    {
                        message: client_side_error,
                        module: "save_to_sap_planning_table"
                    }
                );
                return e.message;
            }
        }

        return true;
    }

    getOrders = async (
        order_changes: IReportExportOrderChange[],
        extra_lines_order_changes: IReportExportExtraLinesOrderChange[]
    ): Promise<IOrderProducedModel[]> => {
        let order_uuids = new Set();
        for (const order_change of order_changes) {
            for (const op_change of order_change.op_changes) {
                order_uuids.add(op_change.order_uuid);
            }
        }

        for (const order_change of extra_lines_order_changes) {
            order_uuids.add(order_change.order_uuid);
        }

        const res2 = await getBackend2().manufacturing.searchOrders({
            include_skip_sim_internal: true,
            order_uuids: [...order_uuids]
        });

        return res2.orders;
    }

    filterOrderChanges = (order_changes: IReportExportOrderChange[]): IReportExportOrderChange[] => {
        const filtered_order_uuids = new Set(this.props.filtered_orders);
        const filtered_order_changes: IReportExportOrderChange[] = [];
        for (const order_change of order_changes) {
            const op_changes = [];
            for (const op of order_change.op_changes) {
                if (!filtered_order_uuids.has(op.order_uuid)) {
                    op_changes.push(op);
                }
            }

            filtered_order_changes.push({
                ...order_change,
                op_changes
            });
        }

        return filtered_order_changes;
    };

    filterExtraLinesOrderChanges = (
        order_changes: IReportExportExtraLinesOrderChange[]
    ): IReportExportExtraLinesOrderChange[] => {
        const filtered_order_uuids = new Set(this.props.filtered_orders);
        return order_changes.filter(order_change => !filtered_order_uuids.has(order_change.order_uuid));
    }

    onOpen = async () => {
        this.setState({
            show_modal: true,
            is_loading: true,
            error: ""
        });

        const parallel_original_report_uuid = this.props.parallel_original_report_uuid;
        this.props.reduxDispatch({ type: SAVE_TO_SAP_PARALLEL_REPORT_LOADING, data: true });
        let parallel_current_report_uuid = this.state.parallel_current_report_uuid;

        const parameters = this.props.parameters;
        const ret = await ReportLogic.generateParallelReportUuidWarning(parameters);

        let warnings = [];
        if (ret) {
            parallel_current_report_uuid = ret.report_uuid;
            warnings = ret.warnings;
        }
        this.setState({ parallel_current_report_uuid, last_current_parameters: parameters, warnings });

        this.props.reduxDispatch({ type: SAVE_TO_SAP_PARALLEL_REPORT_LOADING, data: false });
        const line_uuids = getLineUuids(this.props.lines_orders);

        if (parallel_original_report_uuid != undefined &&
            parallel_current_report_uuid != undefined &&
            line_uuids != undefined
        ) {

            try {
                const res = await getBackend2().reports.getPlanTableExport({
                    initial_report_uuid: parallel_original_report_uuid,
                    final_report_uuid: parallel_current_report_uuid,
                    line_uuids,
                    language_code: getLang()
                });

                if (res.report_export.translations) {
                    this.props.reduxDispatch({
                        type: SET_TRANSLATIONS_MATERIALS,
                        data: res.report_export.translations.materials
                    });
                }

                const order_changes = this.filterOrderChanges(res.report_export.order_changes);
                const extra_lines_order_changes = this.filterExtraLinesOrderChanges(res.report_export.extra_lines_order_changes);
                const orders = await this.getOrders(order_changes, extra_lines_order_changes);

                if (order_changes.length > 0) {
                    this.setState({
                        order_changes,
                        extra_lines_order_changes,
                        orders: orders,
                        is_loading: false
                    });
                }
            } catch (err) {
                this.setState({
                    is_loading: false,
                    error: `${translate("SaveToSap.error_in_plan_table_export", "Error in plan table export")}: ${err.message}`
                });
                const client_side_error = `Error while opening plan table export ${this.props.line_group_uuid}: ` + err.message
                await getBackend2().tracking.logClientsideError(
                    {
                        message: client_side_error,
                        module: "plan_table_export"
                    }
                );
            }
        } else {
            this.setState({
                is_loading: false,
                error: translate("SaveToSap.cannot_export_plan_table", "Line uuids, initial or final reports uuids not set - cannot export plan table")
            });
            return;
        }
    }

    getIsDisabled = () => {
        return !this.props.parallel_original_report_uuid;
    }

    render() {
        if (this.getIsDisabled()) {
            return <button className="dropdown-item" disabled>
                <i className="fas fa-spinner fa-spin"></i>  {translate("common.synchronize_and_refresh", "Synchronize and refresh")}
            </button>
        };

        return (
            <React.Fragment>
                <Authorization.button
                    permission={Auth.PERMISSION_NAMES.PlanningTableEdit}
                    type="button"
                    className="dropdown-item"
                    onClick={this.onOpen}
                    disabled={!this.props.parallel_original_report_uuid}
                >
                    {translate("common.synchronize_and_refresh", "Synchronize and refresh")}
                </Authorization.button>
                <SelectedOrdersConsumer>
                    {(selected_order_context?: SelectedOrdersProviderValue | null) => (
                        <OrderChangesModal
                            plant_uuid={this.props.plant_uuid}
                            line_group_uuid={this.props.line_group_uuid}
                            raw_order_changes={this.state.order_changes}
                            extra_lines_order_changes={this.state.extra_lines_order_changes}
                            orders={this.state.orders}
                            save_report={this.state.save_report}
                            show_modal={this.state.show_modal}
                            onCloseModal={this.onCloseModal}
                            saveShifts={this.state.changed_shifts.length ? this.saveShifts : undefined}
                            importPlan={this.importPlan}
                            saveToSap={this.saveToSap}
                            changed_shifts={this.state.changed_shifts}
                            is_loading={this.state.is_loading}
                            error={this.state.error}
                            import_job_status={this.state.import_job_status}
                            insights={this.props.insights}
                            warnings={this.state.warnings}
                            lines_orders={this.props.lines_orders}
                            unscheduled_orders={this.props.unscheduled_orders}
                            extra_lines={this.props.extra_lines}
                            selected_order_uuids={selected_order_context != null ? [...selected_order_context.state.selected_order_uuids] : []}
                            selectedOrdersDispatch={selected_order_context != null ? selected_order_context.dispatch : undefined}
                        />
                    )}
                </SelectedOrdersConsumer>
            </React.Fragment>
        )
    }
}

export default connect(
    (state: ReduxState) => {
        const parallel_report = state.gantt_chart_parallel_report;
        const properties = state.gantt_chart_properties;

        return {
            lines_orders: state.gantt_chart_lines_orders.lines_orders,
            parameters: parallel_report.parameters,
            parallel_original_report_uuid: parallel_report.parallel_original_report_uuid,
            extra_lines: state.gantt_chart_report.extra_lines,
            unscheduled_orders: state.gantt_chart_lines_orders.unscheduled_orders,
            ignored_orders: state.gantt_chart_lines_orders.ignored_orders,
            ignore_orders_status: state.gantt_chart_lines_orders.ignore_orders_status,
            extra_lines_operation_links: state.gantt_chart_lines_orders.extra_lines_operation_links,
            insights: state.gantt_chart_insights.insights,
            filtered_orders: state.gantt_chart_lines_orders.filtered_orders,
            properties
        }
    },
    (dispatch) => ({reduxDispatch: dispatch})
)(SaveToSap);
