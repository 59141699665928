// @flow

declare class AbortSignal extends EventTarget {
    +aborted: boolean;
    onabort: EventHandler;
}

declare class AbortController {
    +signal: AbortSignal;
    abort: () => void;
}

export type AbortControllerType = typeof AbortController;

/** REST API abstraction */
export interface ApiClient {
    get(path: string, params?: any): Promise<any>;
    post(path: string, params: any): Promise<any>;
    put(path: string, params: any): Promise<any>;
    delete(path: string, params: any): Promise<any>;
    abort(): void | typeof undefined;
    setRequestHeader(key: string, val: string | null): void;
}

export const COMMON_HEADERS = {
    "Cache-Control": "no-cache",
    "X-Server-Select": "frontend",
}
