// @flow
import * as React from "react";

type Props = {
    line_group_uuid: string,
    title: string,
    line_offset: number,
    external_id: string
};
type State = {};

export default class LineGroupLabel extends React.Component<Props, State> {
    render() {
        return <g name="line-group-label">
            <text
                stroke={3}
                textAnchor="left"
                fontFamily="Arial"
                style={{
                    fill: "#616e7c",
                    cursor: "pointer",
                    fontSize: "14px",
                    fontWeight: "700"
                }}
                x={0}
                y={this.props.line_offset + 20}
                data-line-group-uuid={this.props.line_group_uuid}
            >
                {this.props.title}
                <title>{this.props.external_id}</title>
            </text>
        </g>
    }
}
