// @flow

import * as React from "react";
// $FlowFixMe
import { useRef } from "react";

export type SvgIconSize = $Keys<typeof ICON_SIZE> | number;

export type SvgIconProps = {
    children?: React.Node,
    class_name?: string,
    size?: SvgIconSize,
    width?: number,
    height?: number,
    style?: any
};

const ICON_SIZE = {
    xs: 10,
    sm: 16,
    md: 20,
    lg: 24,
    xl: 32,
    fs: 14 // Default font size
};

function getFontSize(element: HTMLElement) {
    const style = getComputedStyle(element, "").getPropertyValue("font-size");
    return parseFloat(style);
}

function getSize(size: SvgIconSize, element: HTMLElement | null): number | typeof undefined {
    if (typeof size === "number") {
        return size;
    }

    if (size === "fs") {
        return element === null ? ICON_SIZE[size] : getFontSize(element);
    }

    return ICON_SIZE[size];
}

const SvgIcon = ({ children, class_name, size, width, height, style }: SvgIconProps) => {
    const svg_ref = useRef<HTMLElement | null>(null);

    let icon_size;
    if (size !== undefined) {
        icon_size = getSize(size, svg_ref.current);
    }

    const svg_props: any = {
        ref: ref => {
            svg_ref.current = ref;
        },
        xmlns: "http://www.w3.org/2000/svg",
        className: `svg-icon${class_name ? ` ${class_name}` : ""}`,
        fill: "currentColor",
        role: "presentation",
        "aria-hidden": true
    };

    const icon_width = width || icon_size;
    if (icon_width !== undefined) {
        svg_props.width = icon_width;
    }

    const icon_height = height || icon_size;
    if (icon_height !== undefined) {
        svg_props.height = icon_height;
    }

    if (style !== undefined) {
        svg_props.style = style;
    }

    return (
        <React.Fragment>
            {React.Children.map(children, child => {
                if (child && typeof child === "object") {
                    return React.cloneElement(child, svg_props);
                }

                return child;
            })}
        </React.Fragment>
    );
};

export default SvgIcon;
