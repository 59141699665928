// @flow

// main imports
import React, { Component } from "react";
import ReactRouterPropTypes from "react-router-prop-types";
import { FormattedMessage } from "react-intl";

// models
import type { ICreateLineGroupReq, IUpdateLineGroupReq } from "../../lib/backend/manufacturing2.generated.types";
import { LINE_GROUP_TAGS } from "../../lib/ManufacturingTags.generated";
import * as BusinessLogic from "../../lib/BusinessLogic";
import * as Auth from "../../lib/Auth";
import LineGroupEditTags from "./LineGroupEditTags";
import { onChangeUpdateTags } from "../react/ReactTableTags";

// subcomponents
import ErrorComponent from "../ErrorComponent";
import Toggle from "react-toggle";
import { translate } from "../IntlProviderWrapper";

// backend
import { getBackend } from "../../lib/backend/Backend2";

// type definitions
type ITagDisplay = {
    name: string,
    id: string
}

type Props = {
    match: ReactRouterPropTypes.match,
    history: ReactRouterPropTypes.history,
    location: ReactRouterPropTypes.location
}

export type State = {
    external_id: string,
    gannt: boolean,
    microplan: boolean,
    shift_board: boolean,
    stock_forecast: boolean,
    uuid: string,
    title: string,
    weight: number,
    tags: any,
    message: string,
    componentTitle: string,
    error: string | null,
    warning: string
}


/**
 * Form to create new line.
 */
export class LineGroupCreate extends Component<Props, State> {

    is_edit = false;

    /**
     * Constructor.
     *
     * @param {Props} props Props object.
     * @param {State} state State object.
     */
    constructor(props: Props, state: State) {
        super(props);
        this.state = {
            external_id: "",
            gannt: false,
            hide_in_navigation: false,
            microplan: false,
            shift_board: false,
            stock_forecast: false,
            batch_op: false,
            subcontracting: false,
            weekly_realization: false,
            uuid: "",
            title: "",
            weight: 0,
            tags: {
                gannt: "false",
                hide_in_navigation: "false",
                microplan: "false",
                shift_board: "false",
                stock_forecast: "false",
                batch_op: "false",
                subcontracting: "false",
                weekly_realization: "false"
            },
            message: "",
            componentTitle: translate("Manufacturing.LineGroups.add_new_line_group", "Add New Line Group"),
            error: "",
            warning: ""
        }
    }

    /**
     * Loading component data (plants).
     */
    async componentDidMount() {
        // do nothing
    }

    /**
     * Basic form validation.
     */
    validateShallow() {
        return (this.state.title.length > 0 && this.state.external_id.length > 0);
    }

    /**
     * Handling change of any input component.
     */
    handleInputChange = (event: Event) => {
        if (event.currentTarget instanceof HTMLInputElement || event.currentTarget instanceof HTMLSelectElement) {
            // read new values
            const target = event.currentTarget;
            let value = target.value;
            const name = target.name;

            // hack for toggle element
            if (Object.keys(LINE_GROUP_TAGS).includes(name)) {
                //$FlowFixMe
                value = target.checked;
            }

            // update new datasource placeholder
            this.setState(prev_state => {
                prev_state[name] = value;
                return prev_state;
            });

            // handle line group
            if (name === "line_group") {
                let tags = this.state.tags || {};
                tags.line_group = value;
                this.setState({ tags: tags });
            }

            // handle tags
            if (Object.keys(LINE_GROUP_TAGS).includes(name)) {
                let tags = this.state.tags || {};
                tags[name] = value.toString();
                this.setState({ tags: tags });
            }
        }
    };

    /**
     * Handling submission of the form. Basic checks and saves with error handling.
     */
    handleSubmit = async () => {
        this.setState({ error: null });
        try {
            if (this.validateShallow()) {
                // insert a new object or update
                if (this.state.uuid === "") {
                    let lg: ICreateLineGroupReq = {
                        external_id: this.state.external_id,
                        title: this.state.title,
                        tags: this.state.tags,
                        weight: this.state.weight
                    }
                    const new_line_group = await getBackend().manufacturing.createLineGroup(lg);
                    // force refresh cache to get new line group tags
                    await Auth.reloadUserProfile();
                    await BusinessLogic.forceManufacturingCacheRefresh();
                    this.props.history.push("/digital-twin/resources/line_groups/" + new_line_group.uuid);
                } else {
                    // update current object
                    let lg: IUpdateLineGroupReq = {
                        linegroup_uuid: this.state.uuid,
                        external_id: this.state.external_id,
                        title: this.state.title,
                        tags: this.state.tags,
                        weight: this.state.weight
                    }
                    await getBackend().manufacturing.updateLineGroup(lg);
                    // force refresh cache to get new line group tags
                    await BusinessLogic.forceManufacturingCacheRefresh();
                    this.props.history.goBack();
                }
            } else {
                this.setState({
                    warning: translate("Manufacturing.LineGroups.cannot_submit_missing_values", "Cannot submit a form with missing values")
                });
            }
        } catch (err) {
            this.setState({ error: err.message });
        }
    }

    /**
     * Renders tag.
     * @param {ITagDisplay} tag
     */
    renderTagField(tag: ITagDisplay) {
        return <div className="form-group" key={tag.id}>
            <label>{tag.name}</label><br />
            <Toggle
                name={tag.id}
                checked={this.state[tag.id]}
                onChange={this.handleInputChange} />
        </div>
    }

    onChangeCell = (name: string) => (value: boolean) => {
        this.setState({ tags: onChangeUpdateTags(this.state.tags, name, value) });
    }

    /**
     * Rendering JSX for current component.
     */
    render() {
        return (
            <div key="ds_new">
                <section key="1">
                    <img src="/img/banner.png" alt="banner" className="img-fluid-header banner_img" />
                </section>
                <section id="profile_edit" className="new_line" key="2">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="white_box">
                                        <div className="tab-content">
                                            <h4>{this.state.componentTitle}</h4>
                                            <ErrorComponent msg={this.state.error} type="error" />
                                            <ErrorComponent msg={this.state.warning} type="warning" />
                                            <div className="form-group">
                                                <label>
                                                    <FormattedMessage id="common.title" defaultMessage="Title" />:
                                                </label>
                                                <input name="title" type="text" disabled="" className="form-control"
                                                    value={this.state.title}
                                                    onChange={this.handleInputChange} />
                                            </div>
                                            <div className="form-group">
                                                <label>
                                                    <FormattedMessage id="common.external_id" defaultMessage="External ID" />:
                                                </label>
                                                <input
                                                    name="external_id"
                                                    type="text"
                                                    disabled={this.is_edit ? true : false}
                                                    className="form-control"
                                                    value={this.state.external_id}
                                                    onChange={this.handleInputChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>
                                                    <FormattedMessage id="common.weight" defaultMessage="Weight" />:
                                                </label>
                                                <input name="weight" type="text" disabled="" className="form-control"
                                                    value={this.state.weight}
                                                    onChange={this.handleInputChange} />
                                            </div>
                                        </div>
                                        <div style={{margin: "0 auto", maxWidth: "800px"}}>
                                            <LineGroupEditTags
                                                tags={this.state.tags}
                                                onChangeCell={this.onChangeCell}
                                            />
                                            <div className="button-list order-last ml-auto">
                                                <button className="btn btn-outline-primary" onClick={this.handleSubmit}>
                                                    <FormattedMessage id="common.save" defaultMessage="Save" />
                                                </button>
                                                <button className="btn btn-outline-secondary" onClick={() => this.props.history.goBack()}>
                                                    <FormattedMessage id="common.back" defaultMessage="Back" />
                                                </button>
                                            </div>
                                        </div>
                                </div>
                                <div className="space30"></div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}
