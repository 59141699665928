// @flow
import { RESET_GANTT } from "./common";
import { deepClone } from "../../../../lib/Util";

import type { IOrderProducedModelEx } from "./linesOrders";
import type { PayloadAction } from "../../../redux/types"
import type { ResetGanttAction } from "./common";
import type { FilterLinesOrders } from "../../../../lib/GanttData";

export type Alert = {
    message?: string,
    created_at: number,
    unread: boolean,
    filtered_lines?: FilterLinesOrders[],
    past_orders?: IOrderProducedModelEx[]
}

export type AlertsState = {
    alerts: Alert[],
    num_unread_alerts: number
}

export const ADD_ALERT: "add_alert" = "add_alert";
export const SET_NUM_UNREAD_ALERTS: "set_num_unread_alerts" = "set_num_unread_alerts";
export const SET_FILTERED_OUT_ORDERS: "set_filtered_out_orders" = "set_filtered_out_orders";
export const SET_PAST_ORDERS_ALERTS: "set_past_orders_alerts" = "set_past_orders_alerts";

export type AddAlertType = PayloadAction<typeof ADD_ALERT, Alert[]>;
export type SetNumUnreadAlerts = PayloadAction<typeof SET_NUM_UNREAD_ALERTS, number>;
export type SetFilteredOutAction = PayloadAction<typeof SET_FILTERED_OUT_ORDERS, FilterLinesOrders[]>;
export type SetPastOrdersAlertsAction = PayloadAction<typeof SET_PAST_ORDERS_ALERTS, IOrderProducedModelEx[]>;

export type AlertsActions = AddAlertType | ResetGanttAction | SetNumUnreadAlerts | SetFilteredOutAction | SetPastOrdersAlertsAction;

const initial_state: AlertsState = {
    alerts: [],
    num_unread_alerts: 0
}

export const alertsReducer = (state: AlertsState, action: AlertsActions) => {
    if (!state) state = deepClone(initial_state);
    let new_state = state;
    if (action.type === RESET_GANTT) {
        return deepClone(initial_state);
    } else if (action.type === ADD_ALERT) {
        let num_unread_alerts = action.data.length;
        const alerts: Alert[] = new_state.alerts.concat(action.data);
        return {
            alerts,
            num_unread_alerts: new_state.num_unread_alerts + num_unread_alerts,
            ...new_state
        };
    } else if (action.type === SET_NUM_UNREAD_ALERTS) {
        new_state.num_unread_alerts = 0;
        for (const alert of new_state.alerts) {
            alert.unread = false;
        }
        return deepClone(new_state);
    } else if (action.type === SET_FILTERED_OUT_ORDERS) {
        // we only report on filters for lines on planning data
        const filtered_lines = action.data.filter((line) => !line.is_extra_line);
        if (filtered_lines.length > 0) {
            new_state.num_unread_alerts++;
            new_state.alerts.push({
                created_at: Date.now(),
                unread: true,
                filtered_lines
            });
        }
        return { ...new_state };
    } else if (action.type === SET_PAST_ORDERS_ALERTS) {
        const past_orders = action.data;
        if (past_orders.length > 0) {
            new_state.alerts.push({
                created_at: Date.now(),
                unread: true,
                past_orders
            })
        }
    }

    return new_state;
}