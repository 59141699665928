// @flow

import React, { Component } from "react";

import { translate } from "../IntlProviderWrapper";

type Props = {
    level: number,
    title: string
};

type State = {};

/**
 * Display single row that represents person's competence in certain area (e.g. line, process, tool etc.).
 */
class PeopleCompetence extends Component<Props, State> {
    render() {
        // extracting competence label from level
        let competence_label = translate("Manufacturing.People.competence_level.0", "None");
        if (this.props.level === 1) {
            competence_label = translate("Manufacturing.People.competence_level.1", "Learning");
        } else if (this.props.level === 2) {
            competence_label = translate("Manufacturing.People.competence_level.2", "Capable");
        } else if (this.props.level === 3) {
            competence_label = translate("Manufacturing.People.competence_level.3", "Independent");
        }

        return (
            <li className="list-group-item d-flex align-items-center">
                <span>{this.props.title}</span>
                <span className={"badge badge-competence badge-competence-" + this.props.level}>{competence_label}</span>
            </li>
        )
    }
}

export default PeopleCompetence;