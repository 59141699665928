// @flow

import React, { Component } from "react";
import { getBackend as getBackend2 } from "../lib/backend/Backend2";
import { FormattedMessage } from "react-intl";
import * as Auth from "../lib/Auth";
import { translate } from "./IntlProviderWrapper";
import ReactRouterPropTypes from "react-router-prop-types";

import {
    ROLE_ADMIN,
    ROLE_POWER_USER,
    ROLE_DEMO_USER,
    ROLE_TICKET_USER,
    ROLE_MAN_ELECTRICITY_ADMIN,
    ROLE_MAN_ELECTRICITY_USER,
    ROLE_MAN_PLANNER,
    ROLE_MAN_PLANT_MANAGER,
    ROLE_MAN_SHOPFLOOR_MANAGER,
    getLoggedinUser, impersonate
} from "../lib/Auth";

import { USER_TAGS } from "../lib/CommonTags.generated";
import { niceDateTime } from "../lib/Util";

// import subcomponents
import ErrorComponent from "./ErrorComponent";
import Toggle from "react-toggle";
import UserEditLineGroups from "./User_EditLineGroups";
import UserEditPin from "./User_EditPin";
import UserEditPassword from "./User_EditPassword";
import UserEditData from "./User_EditUserData";
import Select from "react-select";
import { Button } from "react-bootstrap";

import * as t from "./../lib/backend/users.generated.types";
import * as t2 from "./../lib/backend/manufacturing2.generated.types";

import { Tabs, Tab } from 'react-bootstrap';
import UserLogins from "./UserLogins";

// defining types
type Props = {
    match: ReactRouterPropTypes.match,
    history: ReactRouterPropTypes.history,
    location: ReactRouterPropTypes.location
}

type State = {
    user_data: any,
    error: string,
    warning: string,
    edit_permissions_for_user: boolean | null,
    edit_pin_for_user: boolean | null,
    edit_password_for_user: boolean | null,
    edit_data_for_user: boolean | null,
    kiosk_screen: string,
    user_has_pin: boolean,
    org_allows_pin: boolean,
    plants: any,
    user_line_groups: any
};

type UserRowObj = {
    active: boolean,
    uuid: string,
    title: string,
    username: string,
    must_activate_twofa: boolean,
    must_activate_twofa_date: number,
    must_reset: boolean,
    is_admin: boolean,
    is_power_user: boolean,
    is_demo_user: boolean,
    is_planner: boolean,
    is_plant_manager: boolean,
    is_shopfloor_manager: boolean,
    is_ticket_user: boolean,
    is_electricity_admin: boolean,
    is_electricity_user: boolean,
    is_kiosk: boolean,
    tags: any
}


class UserInfo extends Component<Props, State> {

    constructor(props: Props) {
        super(props);

        const state: State = {
            user_data: null,
            error: "",
            warning: "",
            kiosk_screen: "",
            edit_permissions_for_user: null,
            edit_pin_for_user: null,
            edit_password_for_user: null,
            user_has_pin: false,
            edit_data_for_user: false,
            user_line_groups: [],
            plants: [],
            org_allows_pin: false
        };
        this.state = state;

        this.getUserInfo();
    }


    redirectBackToUsers() {
        this.props.history.goBack();
    }

    async getUserInfo() {
        try {
            const { user_uuid } = this.props.match.params;
            const res = await getBackend2().users.getUsers({});

            // TODO - this is a bit of a hack, but it works for now
            // we should probably have a getSingleUser() method in the backend
            // that returns a single user object
            const users = res.users !== undefined && res.users || [];
            let hit: t.IGetUsersResItem | null = null;
            for (let i = 0; i < users.length; i++) {
                if (users[i].uuid === user_uuid) {
                    hit = users[i];
                    break;
                }
            }

            let res_has_pin = await getBackend2().users.hasPin({ uuid: user_uuid });

            const res_groups_for_user = await getBackend2().manufacturing.getLineGroups({ user_uuid: user_uuid });
            const res_plants = await getBackend2().manufacturing.getPlants({});

            if (hit === undefined || hit === null) {
                throw new Error("User not found");
            } else {
                const userObj = {
                    active: hit.active,
                    must_reset: hit.must_reset,
                    is_admin: hit.roles.indexOf(ROLE_ADMIN) >= 0,
                    is_kiosk: hit.tags[USER_TAGS.kiosk] === "true",
                    is_power_user: hit.roles.indexOf(ROLE_POWER_USER) >= 0,
                    is_demo_user: hit.roles.indexOf(ROLE_DEMO_USER) >= 0,
                    is_ticket_user: hit.roles.indexOf(ROLE_TICKET_USER) >= 0,
                    is_electricity_admin: hit.roles.indexOf(ROLE_MAN_ELECTRICITY_ADMIN) >= 0,
                    is_electricity_user: hit.roles.indexOf(ROLE_MAN_ELECTRICITY_USER) >= 0,
                    is_planner: hit.roles.indexOf(ROLE_MAN_PLANNER) >= 0,
                    is_plant_manager: hit.roles.indexOf(ROLE_MAN_PLANT_MANAGER) >= 0,
                    is_shopfloor_manager: hit.roles.indexOf(ROLE_MAN_SHOPFLOOR_MANAGER) >= 0,
                    title: hit.title,
                    username: hit.username,
                    uuid: hit.uuid,
                    tags: hit.tags,
                    must_activate_twofa: hit.must_activate_twofa,
                    must_activate_twofa_date: hit.must_activate_twofa_date,
                    kiosk_screen: ""
                }

                this.setState({
                    user_data: userObj,
                    user_has_pin: res_has_pin.has_pin,
                    user_line_groups: res_groups_for_user.line_groups,
                    plants: res_plants.plants,
                    org_allows_pin: Auth.getOrgData().allows_pin
                });
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    /**
     * Mounting data for this component.
     */
    async componentDidMount() {
        await this.getUserInfo();
        await this.fetchDataForKiosk();
    }

    /** User panel */
    /** Handling "Impersonate" click. */
    handleImpersonate = async (user_uuid: string) => {
        try {
            await impersonate(user_uuid);
            // reload whole page, including header etc.
            this.props.history.push("/");
        } catch (err) {
            this.setState({ error: err.message });
        }
    };

    userSetActive = async (uuid: string, active: boolean) => {
        const inverted_active = active === true ? false : true;

        try {
            await getBackend2().users.userSetActive({ id: uuid, active: inverted_active });
            this.getUserInfo();
            if (this.state.error) this.setState({ error: "" });
        } catch (err) {
            this.setState({
                error: translate("common.user_active_error", "Could not activate/deactivate user")
            });
        }
    }

    // password
    userSetMustReset = async () => {
        try {
            const { uuid } = this.state.user_data;
            const must_reset = this.state.user_data.must_reset === true ? false : true;

            await getBackend2().users.setMustReset({ uuid: uuid, must_reset });
            this.getUserInfo();
            if (this.state.error) this.setState({ error: "" });
        } catch (err) {
            this.setState({
                error: translate("common.user_password", "Could not reseta password for user")
            });
        }
    }

    // 2FA
    userSetMustActivateTwoFA = async () => {
        try {
            await getBackend2().users.enable2FAAdmin({ uuid: this.state.user_data.uuid });
            this.getUserInfo();
        } catch (err) {
            this.setState({
                error: translate("common.user_activate_twofa_error", "Could not activate/deactivate 2FA for user")
            });
        }
    }

    deactivate2FA = async () => {
        try {
            await getBackend2().users.disable2FAAdmin({ uuid: this.state.user_data.uuid });
            this.getUserInfo();
        } catch (err) {
            this.setState({
                error: translate("common.user_disable2fa_error", "Could not disable 2FA for user")
            });
        }
    }

    userSetAbandonTwoFA = async () => {
        try {
            await getBackend2().users.abandon2FAAdmin({ uuid: this.state.user_data.uuid });
            this.getUserInfo();
        } catch (err) {
            this.setState({
                error: translate("common.common.twofa_abandon", "Abandon 2FA")
            });
        }
    }

    userSetExtendTwoFA = async () => {
        try {
            await getBackend2().users.extend2FAAdmin({ uuid: this.state.user_data.uuid });
            this.getUserInfo();
        } catch (err) {
            this.setState({
                error: translate("common.common.twofa_extend", "Extend 2FA")
            });
        }
    }

    showBadgesForRoles() {
        const {
            is_admin,
            is_kiosk,
            is_power_user,
            is_demo_user,
            is_ticket_user,
            is_electricity_admin,
            is_electricity_user,
            is_planner,
            is_plant_manager,
            is_shopfloor_manager
        } = this.state.user_data;

        const badge_style = {
            marginRight: "3px",
            backgroundColor: "#2186eb",
            fontSize: "1rem",
            marginBottom: "5px"
        };

        return (
            <div>
                {is_admin && <span className="badge badge-success" style={badge_style}>
                    <FormattedMessage id="role.admin" />
                </span>}
                {is_kiosk && <span className="badge badge-success" style={badge_style}>
                    <FormattedMessage id="common.kiosk_user" defaultMessage="Kiosk user" />
                </span>}
                {is_power_user && <span className="badge badge-success" style={badge_style}>
                    <FormattedMessage id="role.power_user" />
                </span>}
                {is_demo_user && <span className="badge badge-success" style={badge_style}>
                    <FormattedMessage id="role.demo_user" />
                </span>}
                {is_ticket_user && <span className="badge badge-success" style={badge_style}>
                    <FormattedMessage id="role.ticket_user" />
                </span>}
                {is_electricity_admin && <span className="badge badge-success" style={badge_style}>
                    <FormattedMessage id="role.electricity_admin" />
                </span>}
                {is_electricity_user && <span className="badge badge-success" style={badge_style}>
                    <FormattedMessage id="role.electricity_user" />
                </span>}
                {is_planner && <span className="badge badge-success" style={badge_style}>
                    <FormattedMessage id="role.planner" />
                </span>}
                {is_plant_manager && <span className="badge badge-success" style={badge_style}>
                    <FormattedMessage id="role.plant_manager" />
                </span>}
                {is_shopfloor_manager && <span className="badge badge-success" style={badge_style}>
                    <FormattedMessage id="role.shopfloor_manager" />
                </span>}
            </div>
        );
    }

    showUserInfo(is_admin: boolean) {
        if (this.state.user_data) {
            const roles_style = {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                paddingBottom: "7px",
                paddingRight: "5px",
                fontSize: "1rem"
            };

            return (
                <div style={{ background: "#fff", borderRadius: "5px", border: "2px solid #edeff2", boxShadow: "5px 5px 10px 2px rgba(237,239,242,.8)" }}>
                    <div style={{ padding: "20px 10px 10px 20px" }}>
                        <div>
                            <FormattedMessage id="common.user" /> &nbsp;
                            <div style={{ fontSize: "1.7rem", marginBottom: "10px", fontWeight: "500" }}>
                                {this.state.user_data.title}
                            </div>
                        </div>
                        <div>
                            <FormattedMessage id="common.username" />: &nbsp;
                            {this.state.user_data.username}
                        </div>

                        <div style={{ "color": "gray", paddingBottom: "15px" }}>
                            <FormattedMessage id="common.uuid" />: &nbsp;
                            {this.state.user_data.uuid}
                        </div>

                        <div style={{ paddingBottom: "25px", display: "flex", fontSize: "1rem" }}>
                            <FormattedMessage id="Settings.roles" />: &nbsp;
                            {this.showBadgesForRoles()}
                        </div>

                        <div style={roles_style}>
                            <div>
                                <FormattedMessage id="common.status" />: &nbsp;
                                {this.state.user_data.active ?
                                    <span className="badge badge-success" style={{
                                        marginRight: "3px",
                                        fontSize: "1rem"
                                    }}>
                                        <FormattedMessage id="common.active" defaultMessage="Active" />
                                    </span> :
                                    <span className="badge badge-secondary" style={{
                                        marginRight: "3px",
                                        fontSize: "1rem"
                                    }}>
                                        <FormattedMessage id="common.inactive" defaultMessage="Inactive" />
                                    </span>}
                            </div>
                            <Toggle
                                checked={this.state.user_data.active}
                                onChange={() => this.userSetActive(this.state.user_data.uuid, this.state.user_data.active)}
                            />
                        </div>

                        <div style={{ paddingBottom: "5px" }}>
                            <div>
                                {this.state.user_data !== null &&
                                    this.state.user_data.must_activate_twofa &&
                                    <ErrorComponent
                                        type="info"
                                        msg={translate("common.twofa_must_activate") + niceDateTime(new Date(this.state.user_data.must_activate_twofa_date))}
                                    />
                                }
                            </div>
                            <div>
                                {this.state.user_data !== null &&
                                    this.state.user_data.must_reset &&
                                    <ErrorComponent
                                        type="info"
                                        msg={translate("common.password_reset")}
                                    />
                                }
                            </div>
                        </div>
                    </div>

                    <div style={{ borderTop: "1px solid #edeff2", background: "#f5f7fa", paddingLeft: "10px" }}>
                        {is_admin &&
                            <Button
                                className="btn btn-primary"
                                style={{
                                    padding: "10px",
                                    margin: "10px 10px 10px 0px"
                                }}
                                onClick={() => this.handleImpersonate(this.state.user_data.uuid)}
                            >
                                <i className="fas fa-user align-middle" style={{ paddingRight: "5px" }} />
                                <FormattedMessage id="common.impersonate" defaultMessage="Impersonate" />
                            </Button>

                        }
                        <Button
                            className="btn btn-outline-primary"
                            style={{
                                padding: "10px",
                                margin: "10px 10px 10px 0px"
                            }}
                            onClick={() => this.setState({ edit_data_for_user: true })}
                        >
                            <i className="fas fa-edit align-middle" style={{ paddingRight: "5px" }} />
                            <FormattedMessage id="common.edit" defaultMessage="Edit data" />
                        </Button>
                    </div>
                </div>
            );
        }
    }

    /** Handle role toggling (checkbox clicks) */
    handleRoleToggle = async (user: UserRowObj, role: string) => {
        try {
            let new_value = false;
            switch (role) {
                case ROLE_ADMIN: new_value = !user.is_admin; break;
                case ROLE_POWER_USER: new_value = !user.is_power_user; break;
                case ROLE_DEMO_USER: new_value = !user.is_demo_user; break;
                case ROLE_TICKET_USER: new_value = !user.is_ticket_user; break;
                case ROLE_MAN_ELECTRICITY_ADMIN: new_value = !user.is_electricity_admin; break;
                case ROLE_MAN_ELECTRICITY_USER: new_value = !user.is_electricity_user; break;
                case ROLE_MAN_PLANNER: new_value = !user.is_planner; break;
                case ROLE_MAN_PLANT_MANAGER: new_value = !user.is_plant_manager; break;
                case ROLE_MAN_SHOPFLOOR_MANAGER: new_value = !user.is_shopfloor_manager; break;
                default: break;
            }

            if (role === ROLE_ADMIN && new_value === false) {
                // check if we are trying to deactivate our own admin role
                const user_data = getLoggedinUser();
                if (user_data.username === user.username) {
                    this.setState({ warning: "You cannot deactivate your admin role." });
                    return;
                }
            }

            if (role === ROLE_POWER_USER && new_value === false) {
                // check if we are trying to deactivate our own power_user role
                const user_data = getLoggedinUser();
                if (user_data.username === user.username) {
                    this.setState({ warning: "You cannot deactivate your power-user role." });
                    return;
                }
            }

            if (role === ROLE_DEMO_USER && new_value === false) {
                // check if we are trying to deactivate our own demo_user role
                const user_data = getLoggedinUser();
                if (user_data.username === user.username) {
                    this.setState({ warning: "You cannot deactivate your demo-user role." });
                    return;
                }
            }

            if (role === ROLE_TICKET_USER && new_value === false) {
                // check if we are trying to deactivate our own ticket_user role
                const user_data = getLoggedinUser();
                if (user_data.username === user.username) {
                    this.setState({ warning: "You cannot deactivate your ticket role." });
                    return;
                }
            }


            if (new_value === false) {
                await getBackend2().users.removeRoleForUser({ id: user.uuid, role });
            } else {
                await getBackend2().users.addRoleForUser({ id: user.uuid, role });
            }
            this.getUserInfo();
        } catch (err) {
            this.setState({ error: err.message });
        }
    }

    /** Handle role toggling (checkbox clicks) */
    handleKioskToggle = async (user: UserRowObj, new_value: boolean) => {
        await getBackend2().users.setUserTag(
            { id: this.state.user_data.uuid, name: USER_TAGS.kiosk, value: new_value === true ? "false" : "true" }
        );
        this.getUserInfo();

    }

    showRoles(is_admin: boolean) {

        if (this.state.user_data === undefined || this.state.user_data === null) {
            return null;
        }

        const roles_style = {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            fontSize: "1rem",
            padding: "5px 20px 5px 20px"
        };

        const roles_header_style = {
            ...roles_style,
            fontWeight: "800",
            fontSize: "1.2rem",
            borderBottom: "1px solid #ccc",
            marginBottom: "10px",
            paddingTop: "10px"
        }

        const roles_last_element_style = {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            fontSize: "1rem",
            padding: "5px 20px 20px 20px"
        };

        return (
            <div >
                <div style={roles_header_style}>
                    <FormattedMessage id="Settings.roles" defaultMessage="Role" />
                    <FormattedMessage id="common.active" defaultMessage="Active" />
                </div>

                {is_admin &&
                    <div style={roles_style}>
                        <FormattedMessage id="role.admin" defaultMessage="Admin" />
                        <Toggle
                            checked={this.state.user_data.is_admin}
                            onChange={() => { this.handleRoleToggle(this.state.user_data, ROLE_ADMIN); }}
                        />
                    </div>
                }

                <div style={roles_style}>
                    <FormattedMessage id="role.power_user" defaultMessage="Power-user" />
                    <Toggle
                        checked={this.state.user_data.is_power_user}
                        onChange={() => { this.handleRoleToggle(this.state.user_data, ROLE_POWER_USER); }}
                    />
                </div>

                <div style={roles_style}>
                    <FormattedMessage id="role.demo_user" defaultMessage="Demo-user" />
                    <Toggle
                        checked={this.state.user_data.is_demo_user}
                        onChange={() => { this.handleRoleToggle(this.state.user_data, ROLE_DEMO_USER); }}
                    />
                </div>

                <div style={roles_style}>
                    <FormattedMessage id="role.ticket_user" defaultMessage="Ticketing" />
                    <Toggle
                        checked={this.state.user_data.is_ticket_user}
                        onChange={() => { this.handleRoleToggle(this.state.user_data, ROLE_TICKET_USER); }}
                    />
                </div>

                <div style={roles_style}>
                    <FormattedMessage id="role.electricity_admin" defaultMessage="Electricity admin" />
                    <Toggle
                        checked={this.state.user_data.is_electricity_admin}
                        onChange={() => { this.handleRoleToggle(this.state.user_data, ROLE_MAN_ELECTRICITY_ADMIN); }}
                    />
                </div>

                <div style={roles_style}>
                    <FormattedMessage id="role.electricity_user" defaultMessage="Electricity user" />
                    <Toggle
                        checked={this.state.user_data.is_electricity_user}
                        onChange={() => { this.handleRoleToggle(this.state.user_data, ROLE_MAN_ELECTRICITY_USER); }}
                    />
                </div>

                <div style={roles_style}>
                    <FormattedMessage id="role.planner" defaultMessage="Planner" />
                    <Toggle
                        checked={this.state.user_data.is_planner}
                        onChange={() => { this.handleRoleToggle(this.state.user_data, ROLE_MAN_PLANNER); }}
                    />
                </div>

                <div style={roles_style}>
                    <FormattedMessage id="role.plant_manager" defaultMessage="Plant manager" />
                    <Toggle
                        checked={this.state.user_data.is_plant_manager}
                        onChange={() => { this.handleRoleToggle(this.state.user_data, ROLE_MAN_PLANT_MANAGER); }}
                    />
                </div>
                <div style={roles_style}>
                    <FormattedMessage id="role.shopfloor_manager" defaultMessage="Shopfloor manager" />
                    <Toggle
                        checked={this.state.user_data.is_shopfloor_manager}
                        onChange={() => { this.handleRoleToggle(this.state.user_data, ROLE_MAN_SHOPFLOOR_MANAGER); }}
                    />
                </div>
                <div style={roles_last_element_style}>
                    <FormattedMessage id="common.kiosk_user" defaultMessage="Kiosk user" />
                    <Toggle
                        checked={this.state.user_data.is_kiosk}
                        onChange={() => { this.handleKioskToggle(this.state.user_data, this.state.user_data.is_kiosk); }}
                    />

                </div>
                {this.state.user_data.is_kiosk && this.state.kiosk_screen === "" &&
                    <ErrorComponent
                        msg={translate("common.set_kiosk_screen", "Set kiosk screen")}
                        type="info"
                    />
                }
            </div>
        );
    }

    showLinegroups() {

        const plant_map: Map<string, t2.IPlantData> = new Map();
        this.state.plants && this.state.plants.forEach(p => plant_map.set(p.uuid, p));

        const active_lingroups = [];

        for (let i = 0; i < this.state.user_line_groups.length; i++) {
            let lg = this.state.user_line_groups[i];

            // prepend plant(s) name(s) to line-group title
            let titles = lg.plant_uuids.map(p => {
                const plant = plant_map.get(p);
                return (plant ? plant.title : "");
            });
            const linegroup_title = `[${titles.sort().join(", ")}] ${lg.title}`;

            active_lingroups.push(
                <div key={i} style={{ paddingLeft: "20px", paddingBottom: "5px", paddingTop: "3px" }}>
                    {linegroup_title}
                </div>
            );
        }

        const roles_style = {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            fontWeight: "800",
            fontSize: "1.2rem",
            borderBottom: "1px solid #ccc",
            padding: "10px 10px 10px 20px",
            marginBottom: "20px"
        }

        return (
            <div>
                <div style={roles_style}>
                    <FormattedMessage id="common.linegroups" defaultMessage="Linegroups" />
                    <button
                        className="btn btn-primary"
                        onClick={() => this.setState({ edit_permissions_for_user: true })}>
                        <FormattedMessage id="common.show_linegroups" defaultMessage="Linegroups" />
                    </button>
                </div>
                <div>
                    {active_lingroups}
                </div>
            </div>
        );
    }

    async fetchDataForKiosk() {
        if (this.state.user_data !== null &&
            this.state.user_data.uuid === "") {
            return;
        }
        try {
            const userUuid = this.props.match.params.user_uuid;
            if (userUuid !== null) {
                const res_user_data = await getBackend2().users.getSingleUser({ id: userUuid });
                const kiosk_screen = res_user_data.tags[USER_TAGS.kiosk_screen] !== undefined ?
                    res_user_data.tags[USER_TAGS.kiosk_screen] : "";
                this.setState({
                    kiosk_screen
                });

            } else {
                this.setState({ kiosk_screen: "" });
            }
        } catch (err) {
            console.log(err);
            this.setState({ error: err.message });
        }
    }

    getKioskScreenOptions() {
        const options = [
            { value: "batch_operations", label: "batch_operations" },
            { value: "kanban", label: "kanban" },
            { value: "manual_entry_order", label: "manual_entry_order" },
            { value: "ticketing", label: "ticketing" }
        ];

        return options;
    }

    async handleSaveKioskScreen(kiosk_screen_value: string) {
        if (this.state.user_data !== null && this.state.user_data.uuid === "") {
            return;
        }

        const options = this.getKioskScreenOptions();

        if (!options.find(o => o.value === kiosk_screen_value)) {
            return;
        }

        try {
            const userUuid = this.props.match.params.user_uuid;
            if (userUuid !== null) {
                await getBackend2().users.setUserTag({
                    id: userUuid,
                    name: USER_TAGS.kiosk_screen,
                    value: kiosk_screen_value
                })
                this.setState({ error: "" });

                await this.fetchDataForKiosk();
            }

        } catch (err) {
            this.setState({ error: err.message });
        }
    }

    renderSelectKioskScreen() {

        const options = this.getKioskScreenOptions();

        return (
            <div style={{ width: "300px" }}>
                <Select
                    value={options.find(option => option.value === this.state.kiosk_screen)}
                    onChange={(option: any) => this.handleSaveKioskScreen(option.value)}
                    options={options}
                    placeholder={translate("common.select_kiosk_screen", "Select kiosk screen")}
                />
            </div>
        );
    }

    async unsetPIN() {
        await getBackend2().users.unsetPin({ uuid: this.state.user_data.uuid });
        await this.getUserInfo();
    }

    async onSetPinClose() {
        this.setState({ edit_pin_for_user: null });
        await this.getUserInfo();
    }

    showAuthentication() {

        const roles_style = {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingTop: "30px",
            paddingRight: "20px",
            paddingLeft: "20px",
            fontSize: "1rem",
            borderBottom: "1px solid #ccc",
            paddingBottom: "10px",
        };

        const button_style = {
            width: "250px",
            marginLeft: "10px"
        }

        return (
            <div style={{ padding: "10px", display: "flex", justifyContent: "flex-start", flexDirection: "column", paddingBottom: "20px" }}>
                <div>
                    <div style={roles_style}>
                        <FormattedMessage id="common.password" defaultMessage="Password" />
                        <div>
                            <button
                                className="btn btn-outline-primary"
                                style={button_style}
                                onClick={() => this.userSetMustReset()}>

                                {this.state.user_data && this.state.user_data.must_reset ?
                                    <FormattedMessage id="common.deactivate_must_reset" defaultMessage="Deactivate 'reset password'" /> :
                                    <FormattedMessage id="common.activate_must_reset" defaultMessage="Activate 'Reset password'" />}
                            </button>

                            <button
                                className="btn btn-outline-primary"
                                style={button_style}
                                onClick={() => this.setState({ edit_password_for_user: true })}>
                                <FormattedMessage id="common.set_password" defaultMessage="Set password" />
                            </button>
                        </div>

                    </div>
                </div>
                <div>
                    <div style={roles_style}>
                        <FormattedMessage id="common.pin" defaultMessage="PIN" />
                        <div>
                            <button
                                disabled={!this.state.org_allows_pin}
                                className="btn btn-outline-primary"
                                style={button_style}
                                onClick={() => this.setState({ edit_pin_for_user: true })}>
                                {this.state.user_has_pin === true ?
                                    <FormattedMessage id="common.change_pin" defaultMessage="Change PIN" /> :
                                    <FormattedMessage id="common.set_pin" defaultMessage="Set PIN" />
                                }
                            </button>

                            {this.state.user_has_pin === true &&
                                <button
                                    disabled={!this.state.org_allows_pin}
                                    className="btn btn-outline-primary"
                                    style={button_style}
                                    onClick={() => this.unsetPIN()}>
                                    <FormattedMessage id="common.unset_pin" defaultMessage="Unset PIN" />
                                </button>
                            }
                        </div>
                    </div>
                </div>

                <div>
                    <div style={roles_style}>
                        <FormattedMessage id="common.twofa" defaultMessage="2FA" />
                        <div>
                            {this.state.user_data !== null &&
                                this.state.user_data.tags.twofa === 'true' ?
                                <button
                                    className="btn btn-outline-primary"
                                    style={button_style}
                                    onClick={() => this.deactivate2FA()}>
                                    <FormattedMessage id="common.twofa_deactivate" defaultMessage="Deactivate 2FA" />
                                </button>
                                :
                                <div>
                                    {this.state.user_data &&
                                        this.state.user_data.must_activate_twofa ?
                                        <div>
                                            <button
                                                className="btn btn-outline-primary"
                                                style={button_style}
                                                onClick={() => this.userSetAbandonTwoFA()}>
                                                <FormattedMessage id="common.twofa_abandon" defaultMessage="Abandon 2FA" />
                                            </button>
                                            <button
                                                className="btn btn-outline-primary"
                                                style={button_style}
                                                onClick={() => this.userSetExtendTwoFA()}>
                                                <FormattedMessage id="common.twofa_extend" defaultMessage="Extend 2FA" />
                                            </button>
                                        </div>
                                        :
                                        <button
                                            className="btn btn-outline-primary"
                                            style={button_style}
                                            onClick={() => this.userSetMustActivateTwoFA()}>
                                            <FormattedMessage id="common.twofa_activate" defaultMessage="Activate 2FA" />
                                        </button>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>

                {this.state.user_data && this.state.user_data.is_kiosk &&
                    <div>
                        <div style={roles_style}>
                            <FormattedMessage id="common.kiosk_screen" defaultMessage="Kiosk screen" />
                            <div>
                                {this.renderSelectKioskScreen()}
                            </div>
                        </div>
                    </div>
                }

            </div >
        );
    }

    showLogins() {
        return <UserLogins user_uuid={this.state.user_data && this.state.user_data.uuid} />;

    }

    async closeUserEditData() {
        this.setState({ edit_data_for_user: false });
        this.getUserInfo();
    }

    async closeUserEditLineGroups() {
        this.setState({ edit_permissions_for_user: null })
        await this.getUserInfo();
    }

    render() {

        // check if user is in kiosk mode
        const is_kiosk = Auth.getUserTag("kiosk") === "true";
        // is the user in admin role
        const is_admin = !is_kiosk && Auth.isInRole(Auth.ROLE_ADMIN);

        return (
            <div>
                <div style={{ padding: "10px 15px" }}>
                    <button
                        className="btn btn-primary"
                        onClick={() => this.redirectBackToUsers()}>
                        <FormattedMessage id="common.back" defaultMessage="Back" />
                    </button>
                </div>
                <div className="card-container" style={{ padding: "0 15px 15px" }}>
                    <div style={{ width: "50rem", marginBottom: "30px", maxWidth: "100%" }}>
                        {this.showUserInfo(is_admin)}
                    </div>

                    <div className="modal-tabs2__tabs" style={{ background: "#fff", borderRadius: "5px", width: "50rem", maxWidth: "100%" }}>
                        <Tabs id="modal-tabs2" defaultActiveKey={"user_roles"}>
                            <Tab tabClassName="tab_class_view" bsStyle="tabs" eventKey="user_roles" title={translate("Settings.roles")} key={"idx1"}>
                                {this.showRoles(is_admin)}
                            </Tab>
                            <Tab tabClassName="tab_class_view" bsStyle="tabs" eventKey="user_linegroups" title={translate("common.linegroups")} key={"idx2"}>
                                {this.showLinegroups()}
                            </Tab>
                            <Tab tabClassName="tab_class_view" bsStyle="tabs" eventKey="user_authentication" title={translate("common.authentication")} key={"idx3"}>
                                {this.showAuthentication()}
                            </Tab>
                            <Tab tabClassName="tab_class_view" bsStyle="tabs" eventKey="user_logins" title={translate("common.logins")} key={"idx4"}>
                                {this.showLogins()}
                            </Tab>
                        </Tabs>
                    </div>

                    <UserEditLineGroups
                        onClose={() => this.closeUserEditLineGroups()}
                        show={this.state.edit_permissions_for_user !== null}
                        userUuid={this.state.user_data && this.state.user_data.uuid && this.state.user_data.uuid}
                    />
                    <UserEditPin
                        onClose={() => this.onSetPinClose()}
                        show={this.state.edit_pin_for_user !== null}
                        userUuid={this.state.user_data && this.state.user_data.uuid && this.state.user_data.uuid}
                    />
                    <UserEditPassword
                        onClose={() => this.setState({ edit_password_for_user: null })}
                        show={this.state.edit_password_for_user !== null}
                        userUuid={this.state.user_data && this.state.user_data.uuid && this.state.user_data.uuid}
                    />
                    <UserEditData
                        onClose={() => this.closeUserEditData()}
                        show={this.state.edit_data_for_user === true}
                        userUuid={this.state.user_data && this.state.user_data.uuid && this.state.user_data.uuid}
                        title={this.state.user_data && this.state.user_data.title && this.state.user_data.title}
                        username={this.state.user_data && this.state.user_data.username && this.state.user_data.username}
                    />

                    {this.state.error && <ErrorComponent msg={this.state.error} type="error" />}
                    {this.state.warning && <ErrorComponent msg={this.state.warning} type="warning" />}

                </div>
            </div>
        );
    }

}


export default UserInfo;