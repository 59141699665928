// @flow
import * as t2 from "../../../../lib/SimulationReportModels";

import type { PropertiesState } from "../reducers/properties";

export const order_uuid_line_offset_map = new Map<string, number>();

export let properties: PropertiesState | null = null;

export class ScrollHandler {

    static addLineOffsetForScrollEvents(
        line_offset: number,
        orders: t2.ISimulationReportOrderEx[],
        in_properties: PropertiesState
    ) {
        for (const order of orders) {
            order_uuid_line_offset_map.set(order.order_id, line_offset);
        }
        properties = in_properties;
    }

    static scrollToOrder(order_id: string) {
        const el = document.querySelector(`g[name='order'][order-uuid='${order_id}']`);
        if (el) {
            // $FlowFixMe
            el.scrollIntoView({ block: "center", inline: "center" });
        } else {
            const line_offset = order_uuid_line_offset_map.get(order_id) || 0;

            const pane1 = document.querySelector("#pane1");
            if (pane1) {
                const { height } = pane1.getBoundingClientRect();
                pane1.scrollTop = line_offset - (height / 2);
            }
        }
    }
}