// @flow
import React from "react";
import { connect } from "react-redux";

import { translate } from "../../../IntlProviderWrapper";
import TranslatedMaterialTitle from "../../../TranslatedMaterialTitle";
import {
    LINE_GROUP_TAGS_ACCESS,
    ORDER_TAGS,
    ORDER_TAGS_ACCESS,
    PLANT_TAGS_ACCESS
} from "../../../../lib/ManufacturingTags.generated";
import { classNames, niceNumber } from "../../../../lib/Util";
import Loader from "../../../Loader";
import { UNIGNORE_ORDERS } from "../reducers/linesOrders";
import * as t from "../../../../lib/backend/manufacturing2.generated.types";
import { ORDER_TYPE } from "../../../../lib/ManufacturingConsts.generated";
import { getBackend } from "../../../../lib/backend/Backend2";
import ConfirmationModal from "../../../ConfirmationModal";
import { getLineGroupTags, getPlantTags } from "../../../../lib/BusinessLogic";
import ErrorComponent from "../../../ErrorComponent";
import LinkOperationsModal from "../LinkOperationsModal";
import LinkOrdersModal, { LINK_ORDERS_MODE } from "../LinkOrdersModal";

import type { ReduxState } from "../reducers/reducers";
import type { LineOrders, IOrderProducedModelEx, UnignoreOrdersAction, IgnoreOrdersMap } from "../reducers/linesOrders";
import type { LinkOrdersMode } from "../LinkOrdersModal";

// $FlowFixMe
import "../../../../styles/ignored_orders.scss";

const ORDER_ACTIONS = {
    move_operation_to_unscheduled: "move_operation_to_unscheduled",
    move_order_to_unscheduled: "move_order_to_unscheduled",
    move_parent_order_to_unscheduled: "move_parent_order_to_unscheduled",
    link_operation: "link_operation",
    link_order: "link_order",
    link_parent_order: "link_parent_order"
};

export type OperationsTableProps = {
    operations: t.IOrderModelBase[]
};

export const OperationsTable = ({ operations }: OperationsTableProps) => {
    return (
        <table className="operations-table table table-bordered mb-0">
            <thead>
                <tr>
                    <th>{translate("OrderTableProduction.order_id", "Order ID")}</th>
                    <th>{translate("OrderTableProduction.process_num", "Process number")}</th>
                    <th>{translate("common.operation", "Operation")}</th>
                </tr>
            </thead>
            <tbody>
                {operations.map(op => (
                    <tr key={op.uuid}>
                        <td>{op.external_id}</td>
                        <td>{op.process_num}</td>
                        <td>{op.title}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

type Props = {
    line_group_uuid: string,
    ignored_orders: LineOrders[],
    ignore_orders_status: IgnoreOrdersMap | null,
    lines_orders_loading: boolean,
    reduxDispatch: (args: UnignoreOrdersAction) => void
};

type State = {
    unignore_operations: t.IOrderModelBase[] | null,
    show_warning: boolean,
    link_orders_mode: LinkOrdersMode | null,
    rework_operation: IOrderProducedModelEx | null
};

export const IgnoredOrdersTabHeader = () => {
    const title = translate("common.ignored_operations", "Ignored operations");
    return (
        <span title={title}>
            <i className="fas fa-eye-slash" />
            <span>{title}</span>
        </span>
    );
};

const isParallelOrder = (order: IOrderProducedModelEx | t.IOrderModelBase): boolean => {
    return ORDER_TAGS_ACCESS.parallel_order(order.tags) || ORDER_TAGS_ACCESS.parallel_parent(order.tags);
};

class IgnoredOrdersTabContent extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const state: State = {
            unignore_operations: null,
            show_warning: false,
            link_orders_mode: null,
            rework_operation: null
        };
        this.state = state;
    }

    getOrder = (uuid: string): IOrderProducedModelEx | null => {
        for (const line of this.props.ignored_orders) {
            for (const order of line.orders) {
                if (order.uuid === uuid) {
                    return order;
                }
            }
        }

        return null;
    };

    getOrders = (): IOrderProducedModelEx[] => {
        const orders: IOrderProducedModelEx[] = [];
        for (const line of this.props.ignored_orders) {
            orders.push(...line.orders);
        }

        return orders;
    };

    handleOrderAction = async (event: SyntheticMouseEvent<HTMLButtonElement>) => {
        const target = event.currentTarget;
        const ignore_orders_status = this.props.ignore_orders_status || new Map();
        if (target.name === ORDER_ACTIONS.move_operation_to_unscheduled) {
            this.props.reduxDispatch({
                type: UNIGNORE_ORDERS,
                data: {
                    order_uuids: [target.value]
                }
            });
        } else if (target.name === ORDER_ACTIONS.move_order_to_unscheduled) {
            let operations: t.IOrderModelBase[] = [];
            const order = this.getOrder(target.value);
            if (order !== null) {
                const res = await getBackend().manufacturing.getOrdersSimple({
                    order_external_id: order.external_id,
                    order_types: [ORDER_TYPE.full, ORDER_TYPE.plan]
                });
                operations = res.operations;
            }

            if (operations.some(o => isParallelOrder(o))) {
                this.setState({
                    show_warning: true
                });
                return;
            }

            const unignore_operations = operations.filter(order => ignore_orders_status.get(order.uuid) !== false);
            this.setState({ unignore_operations });
        } else if (target.name === ORDER_ACTIONS.move_parent_order_to_unscheduled) {
            let operations: t.IOrderModelBase[] = [];
            const order = this.getOrder(target.value);
            if (order !== null) {
                const external_parent_order_id = ORDER_TAGS_ACCESS.external_parent_order_id(order.tags);
                if (external_parent_order_id !== "") {
                    const res = await getBackend().manufacturing.getOrdersSimple({
                        tags_obj: {
                            [ORDER_TAGS.external_parent_order_id]: external_parent_order_id
                        },
                        order_types: [ORDER_TYPE.full, ORDER_TYPE.plan]
                    });
                    operations = res.operations;
                }
            }

            if (operations.some(o => isParallelOrder(o))) {
                this.setState({
                    show_warning: true
                });
                return;
            }

            const unignore_operations = operations.filter(order => ignore_orders_status.get(order.uuid) !== false);
            this.setState({ unignore_operations });
        } else if (target.name === ORDER_ACTIONS.link_operation) {
            this.setState({
                link_orders_mode: null,
                rework_operation: this.getOrder(target.value)
            });
        } else if (target.name === ORDER_ACTIONS.link_order) {
            this.setState({
                link_orders_mode: LINK_ORDERS_MODE.order,
                rework_operation: this.getOrder(target.value)
            });
        } else if (target.name === ORDER_ACTIONS.link_parent_order) {
            this.setState({
                link_orders_mode: LINK_ORDERS_MODE.parent_order,
                rework_operation: this.getOrder(target.value)
            });
        }
    };

    handleAcceptUnignoreOperations = async () => {
        const { unignore_operations } = this.state;
        if (unignore_operations !== null && unignore_operations.length > 0) {
            this.props.reduxDispatch({
                type: UNIGNORE_ORDERS,
                data: {
                    order_uuids: unignore_operations.map(o => o.uuid)
                }
            });
        }

        this.setState({
            unignore_operations: null
        });
    };

    handleCancelUnignoreOperations = () => {
        this.setState({
            unignore_operations: null
        });
    };

    handleWarningModalClose = () => {
        this.setState({
            show_warning: false
        });
    };

    handleLinkModalClose = () => {
        this.setState({
            link_orders_mode: null,
            rework_operation: null
        });
    };

    renderUnignoreConfirmationModal = () => {
        const { unignore_operations } = this.state;
        const has_unignore_operations = unignore_operations !== null && unignore_operations.length > 0;
        return (
            <ConfirmationModal
                class_name="modal-dialog-scrollable confirmation-modal-ignore-operations"
                show={unignore_operations !== null}
                title={translate("common.move_to_unscheduled", "Move to unscheduled")}
                onAccept={
                    has_unignore_operations ? this.handleAcceptUnignoreOperations : this.handleCancelUnignoreOperations
                }
                onCancel={has_unignore_operations ? this.handleCancelUnignoreOperations : undefined}
                accept_label={has_unignore_operations ? undefined : translate("common.ok", "OK")}
            >
                <p
                    className={classNames({
                        "mb-0": !has_unignore_operations
                    })}
                >
                    {has_unignore_operations
                        ? translate(
                              "common.are_you_sure_move_operations_to_unscheduled",
                              "Are you sure you want to move the following operations to unscheduled?"
                          )
                        : translate("common.no_operation_found", "No operation found")}
                </p>
                {has_unignore_operations && <OperationsTable operations={unignore_operations || []} />}
            </ConfirmationModal>
        );
    };

    renderOrders = () => {
        const orders = this.getOrders();
        if (orders.length === 0) {
            return (
                <tr>
                    <td colSpan={3}>
                        <div className="planning-table__no-orders">{translate("common.no_data", "No data")}</div>
                    </td>
                </tr>
            );
        }

        const plant_uuid = localStorage.getItem("last-plant");
        const plant_tags = plant_uuid ? getPlantTags(plant_uuid) : {};
        const line_group_tags = getLineGroupTags(this.props.line_group_uuid);
        return orders.map(order => {
            const external_parent_order_id = ORDER_TAGS_ACCESS.external_parent_order_id(order.tags);
            const digits = order.measurement_unit === "KG" ? 3 : 0;
            const quantity_total = niceNumber(order.quantity_total, digits);
            const is_parallel_order = isParallelOrder(order);
            return (
                <tr key={order.uuid}>
                    <td className="sortable-element__workorder">
                        <strong>
                            {order.external_id} {external_parent_order_id ? `(P${external_parent_order_id})` : ""} /{" "}
                            {order.process_num}
                        </strong>
                    </td>
                    <td className="sortable-element__material">
                        <strong>{order.material_external_id}</strong> -{" "}
                        {TranslatedMaterialTitle({
                            material_external_id: order.material_external_id,
                            material_title: order.material_title
                        })}
                    </td>
                    <td className="sortable-element__unit">
                        {quantity_total} {order.measurement_unit}
                    </td>
                    <td className="sortable-element__moveorder">
                        <div className="dropdown">
                            <button
                                className="btn btn-short btn-flat-light px-1 py-0"
                                data-toggle="dropdown"
                                aria-expanded="false"
                            >
                                <i className="far fa-edit" />
                            </button>
                            <div className="dropdown-menu dropdown-menu-right">
                                <button
                                    className="dropdown-item"
                                    name={ORDER_ACTIONS.move_operation_to_unscheduled}
                                    value={order.uuid}
                                    onClick={this.handleOrderAction}
                                >
                                    {translate("common.move_operation_to_unscheduled", "Move operation to unscheduled")}
                                </button>
                                {!is_parallel_order &&
                                    PLANT_TAGS_ACCESS.planning_table_show_ignore_order(plant_tags) && (
                                        <button
                                            className="dropdown-item"
                                            name={ORDER_ACTIONS.move_order_to_unscheduled}
                                            value={order.uuid}
                                            onClick={this.handleOrderAction}
                                        >
                                            {translate("common.move_order_to_unscheduled", "Move order to unscheduled")}
                                        </button>
                                    )}
                                {!is_parallel_order &&
                                    PLANT_TAGS_ACCESS.planning_table_show_ignore_parent_order(plant_tags) &&
                                    external_parent_order_id !== "" && (
                                        <button
                                            className="dropdown-item"
                                            name={ORDER_ACTIONS.move_parent_order_to_unscheduled}
                                            value={order.uuid}
                                            onClick={this.handleOrderAction}
                                        >
                                            {translate(
                                                "common.move_parent_order_to_unscheduled",
                                                "Move parent order to unscheduled"
                                            )}
                                        </button>
                                    )}
                                {LINE_GROUP_TAGS_ACCESS.planning_table_show_link_operation(line_group_tags) && (
                                    <React.Fragment>
                                        <button
                                            className="dropdown-item"
                                            name={ORDER_ACTIONS.link_operation}
                                            value={order.uuid}
                                            onClick={this.handleOrderAction}
                                        >
                                            {translate("common.link_operation", "Link operation")}
                                        </button>
                                        <button
                                            className="dropdown-item"
                                            name={ORDER_ACTIONS.link_order}
                                            value={order.uuid}
                                            onClick={this.handleOrderAction}
                                        >
                                            {translate("common.link_order", "Link order")}
                                        </button>
                                        {ORDER_TAGS_ACCESS.external_parent_order_id(order.tags) !== "" && (
                                            <button
                                                className="dropdown-item"
                                                name={ORDER_ACTIONS.link_parent_order}
                                                value={order.uuid}
                                                onClick={this.handleOrderAction}
                                            >
                                                {translate("common.link_parent_order", "Link parent order")}
                                            </button>
                                        )}
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                    </td>
                </tr>
            );
        });
    };

    renderOrdersTable = () => {
        if (this.props.lines_orders_loading) {
            return (
                <div style={{ height: "calc(100vh - 370px)" }}>
                    <Loader small={true} />
                </div>
            );
        }

        return (
            <div className="ignored-orders planning-table__row mt-0">
                <table className="planning-table__sortable-list">
                    <thead>
                        <tr>
                            <th className="sortable-element__workorder">
                                {translate("common.operation", "Operation")}
                            </th>
                            <th className="sortable-element__material">
                                {translate("common.id", "ID")} - {translate("common.material", "Material")}
                            </th>
                            <th className="sortable-element__unit">{translate("common.quantity", "Quantity")}</th>
                            <th className="sortable-element__moveorder" />
                        </tr>
                    </thead>
                    <tbody>{this.renderOrders()}</tbody>
                </table>
            </div>
        );
    };

    renderWarningModal = () => {
        return (
            <ConfirmationModal
                show={this.state.show_warning}
                title={translate("common.unsupported_action", "Unsupported action")}
                accept_label={translate("common.ok", "OK")}
                onAccept={this.handleWarningModalClose}
                onClose={this.handleWarningModalClose}
            >
                <ErrorComponent
                    className="my-0"
                    type="warning"
                    msg={translate(
                        "common.action_not_supported_on_parallel_operations",
                        "This action is currently not supported, because one of the operations is part of a parallel order."
                    )}
                />
            </ConfirmationModal>
        );
    };

    renderLinkOperationsModal = () => {
        return (
            <LinkOperationsModal
                rework_operation={this.state.rework_operation}
                show={this.state.rework_operation !== null && this.state.link_orders_mode === null}
                onClose={this.handleLinkModalClose}
            />
        );
    };

    renderLinkOrdersModal = () => {
        return (
            <LinkOrdersModal
                rework_operation={this.state.rework_operation}
                link_mode={this.state.link_orders_mode}
                onClose={this.handleLinkModalClose}
            />
        );
    };

    render() {
        return (
            <div className="orders flex-column h-100">
                {this.renderOrdersTable()}
                {this.renderUnignoreConfirmationModal()}
                {this.renderWarningModal()}
                {this.renderLinkOperationsModal()}
                {this.renderLinkOrdersModal()}
            </div>
        );
    }
}

export default connect(
    (state: ReduxState, own_props: Props) => {
        return {
            line_group_uuid: state.gantt_chart_properties.line_group_uuid,
            ignored_orders: state.gantt_chart_lines_orders.ignored_orders,
            ignore_orders_status: state.gantt_chart_lines_orders.ignore_orders_status
        };
    },
    dispatch => ({ reduxDispatch: dispatch })
)(IgnoredOrdersTabContent);
