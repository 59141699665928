// @flow
import * as React from "react";
import { Modal } from "react-bootstrap";
import moment from "moment";

import { translate } from "../IntlProviderWrapper";
import ErrorComponent from "../ErrorComponent";
import DateTimePicker from "../DateTimePicker";
import { TIME_RANGES } from "../../lib/Util";

import type { IOrderModelBase } from "../../lib/backend/manufacturing2.generated.types";

export type FormData = {
    time_start: number,
    time_end: number
};

type Props = {
    orders: IOrderModelBase[],
    show: boolean,
    onAccept: (time_start: number, time_end: number) => void,
    onCancel: () => void
};

type State = {
    time_start: number,
    time_end: number,
    warning: string
};

class ManualEntryOrderExportExcelModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const state: State = {
            time_start: 0,
            time_end: 0,
            warning: ""
        };
        this.state = state;
    }

    getSelectionCount = (): number => {
        const { time_start, time_end } = this.state;
        let count = 0;
        for (const order of this.props.orders) {
            if (order.time_end !== undefined && order.time_end >= time_start && order.time_end <= time_end) {
                count++;
            }
        }

        return count;
    };

    evaluateSelection = () => {
        if (this.getSelectionCount() === 0) {
            this.setState({
                warning: translate("common.no_orders_in_selected_range", "No orders in selected range")
            });
        } else {
            this.setState({
                warning: ""
            });
        }
    };

    handleSubmit = async (event: SyntheticEvent<HTMLFormElement>) => {
        event.preventDefault();

        const { time_start, time_end } = this.state;
        this.props.onAccept(time_start, time_end);
        this.props.onCancel();
    };


    /**
     * If end time is not set, set it to the current time + 1 month
     * If end time is set, then leave it as it is. 
     */
    handleStartTimeChange = (date: Date) => {
        const time_start = moment(date).startOf("day").toDate().getTime();
        const time_end = moment(
            this.state.time_end === 0 ? time_start + (TIME_RANGES.DAY * 31) : this.state.time_end
        ).endOf("day").toDate().getTime();
        this.setState({
            time_start,
            time_end
        }, this.evaluateSelection);
    };

    handleEndTimeChange = (date: Date) => {
        const time_end = moment(date).endOf("day").toDate().getTime();
        this.setState({
            time_end
        }, this.evaluateSelection);
    };

    handleExit = () => {
        this.setState({
            time_start: 0,
            time_end: 0
        });
    };

    render() {
        const { time_start, time_end } = this.state;
        return (
            <Modal show={this.props.show} onExited={this.handleExit}>
                <Modal.Header>
                    <Modal.Title>
                        {translate("Manufacturing.MicroplanSummary.export_to_xlsx", "Export To XLSX")}
                    </Modal.Title>
                    <button type="button" className="close" onClick={this.props.onCancel}>
                        <span aria-hidden="true">×</span>
                        <span className="sr-only">{translate("common.close", "Close")}</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <form id="manual-entry-line-input-form" onSubmit={this.handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="manual-entry-line-input-time-start">
                                {translate("common.start_noun", "Start")}
                            </label>
                            <DateTimePicker
                                inputId="manual-entry-line-input-time-start"
                                name="time_start"
                                value={this.state.time_start ? new Date(this.state.time_start) : undefined}
                                placeholder={`${translate("common.select", "Select")}...`}
                                onChange={this.handleStartTimeChange}
                                popupPostion="fixed"
                            />
                        </div>
                        <div className="form-group mb-0">
                            <label htmlFor="manual-entry-line-input-time-end">
                                {translate("common.end", "End")}
                            </label>
                            <DateTimePicker
                                inputId="manual-entry-line-input-time-end"
                                name="time_end"
                                value={this.state.time_end ? new Date(this.state.time_end) : undefined}
                                minValue={
                                    this.state.time_start ? new Date(this.state.time_start) : undefined
                                }
                                placeholder={`${translate("common.select", "Select")}...`}
                                onChange={this.handleEndTimeChange}
                                popupPostion="fixed"
                            />
                        </div>
                        {this.state.warning && (
                            <ErrorComponent className="mb-0" type="warning" msg={this.state.warning} />
                        )}
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn btn-primary"
                        type="submit"
                        form="manual-entry-line-input-form"
                        disabled={time_start === 0 || time_end === 0 || this.getSelectionCount() === 0}
                    >
                        {translate("common.save", "Save")}
                    </button>
                    <button className="btn btn-outline-secondary" onClick={this.props.onCancel}>
                        {translate("common.cancel", "cancel")}
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default ManualEntryOrderExportExcelModal;
