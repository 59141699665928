// @flow

import { getBackend } from "./backend/Backend2";
import * as t from "./backend/manufacturing2.generated.types";

import { fromShiftTag, shiftNumber, stringCompare } from "./Util";

export const MATERIAL_FORECAST_TYPES = {
    in: "in",
    out: "out"
};

export type MaterialForecastType = "in" | "out";

export const MATERIAL_FORECAST_ONLY_ACCOUNTED = {
    yes: "yes",
    no: "no"
};

export type MaterialForecastOnlyAccounted = "yes" | "no";


export const MATERIAL_FORECAST_SUBTYPES = {
    combined: "combined",
    consumed: "consumed",
    consumedByShift: "consumed_by_shift",
    produced: "produced",
    producedByShift: "produced_by_shift",
    receivedByShift: "received_by_shift",
    requiredByShift: "required_by_shift",
    required: "required"
};

export type MaterialForecastSubtype = "combined" | "consumed" | "consumed_by_shift" |
    "produced" | "produced_by_shift" | "received_by_shift" | "required_by_shift";

export const MATERIAL_FORECAST_LOCATIONS = {
    shopfloor: "shopfloor",
    plant: "plant"
};

export type MaterialForecastLocation = "shopfloor" | "plant";

export type ShiftHeader = {
    date: string,
    day_of_week: number,
    shift: number
};

export type MaterialForecast = {
    shift_headers: ShiftHeader[],
    materials_changing: t.IMaterialForecast[],
    materials_nonchanging: t.IMaterialForecast[],
    materials_zero: t.IMaterialForecast[],
    report_id: string
};

export async function getMaterialForecast(
    group_uuid: string,
    type: MaterialForecastType,
    subtype: MaterialForecastSubtype,
    only_accounted_for_initial_stock: MaterialForecastOnlyAccounted,
    location: MaterialForecastLocation,
    max_days: number,
    line_uuid?: string,
    report_id?: string,
    order_external_ids?: string[],
    material_external_id?: string,
    include_unscheduled_stock?: boolean
): Promise<MaterialForecast> {

    // prepare parameters for backend call
    let forecast_params: t.IGetMaterialForecastReq = {
        group_uuid,
        type,
        subtype,
        only_accounted_for_initial_stock,
        location,
        order_external_ids,
        material_external_id,
        include_unscheduled_stock
    };
    if (line_uuid !== undefined) { forecast_params.line_uuid = line_uuid; }
    if (report_id !== undefined) { forecast_params.report_id = report_id; }

    // get forecast data from the backend
    const status = await getBackend().manufacturing.getMaterialForecast(forecast_params);

    // prepare shift header
    const shift_headers: ShiftHeader[] = [];
    const total_shifts = 3 * max_days;
    if (status.items.length > 0) {
        // take the first non-empty forecasts
        const row = status.items.find(x => x.forecasts && x.forecasts.length > 0);
        // no forecasts
        if (row && row.forecasts) {
            for (const item of row.forecasts) {
                const d = fromShiftTag(item.shift_tag);
                const sn = shiftNumber(d);
                if (shift_headers.length >= total_shifts) { break; }
                shift_headers.push({
                    date: item.shift_date,
                    day_of_week: sn.day_number,
                    shift: sn.shift_day % 3
                })
            }
        }
    }

    // show only stock inputs that are nonzero and change at some point in the next max_days days (total_shifts shifts)
    const materials_all = status.items
        .filter(item => item.forecasts && item.forecasts.length > 0);

    let materials_changing: t.IMaterialForecast[] = [];
    let materials_nonchanging: t.IMaterialForecast[] = [];
    let materials_zero: t.IMaterialForecast[] = [];
    materials_all.forEach(item => {
        const fcasts = item.forecasts.slice(0, total_shifts);
        if (fcasts.some(x => x.change)) {
            materials_changing.push(item);
        } else if (item.current_stock > 0) {
            materials_nonchanging.push(item);
        } else {
            materials_zero.push(item);
        }
    });

    // sort materials by title
    const sorting_helper = (a, b) => stringCompare(a.material_title, b.material_title);
    materials_changing = materials_changing.sort(sorting_helper);
    materials_nonchanging = materials_nonchanging.sort(sorting_helper);
    materials_zero = materials_zero.sort(sorting_helper);

    return {
        shift_headers,
        materials_changing,
        materials_nonchanging,
        materials_zero,
        report_id: status.report_id || ""
    };
}
