// @flow
//$FlowFixMe
import React, { useEffect, useState, useRef, Suspense } from "react";
import WeeklyRealizationShift from "./WeeklyRealizationShift";
import ProductionModal from "./ProductionModal";
import type {
    ProductionModalParams,
    MicroplanSummaryFilterProps
} from "./WeeklyRealizationCommon";
import Loader from "../Loader";
import { loadRealizationWithPlan, loadInsights } from "../../lib/WeeklyRealization";
import * as t from "../../lib/backend/manufacturing2.generated.types";
import type { WeeklyReport } from "../../lib/Models";
import { translate } from "../IntlProviderWrapper";
import * as BusinessLogic from "../../lib/BusinessLogic";
import { renderToString } from "react-dom/server";
import { exportWeeklyRealizationHTML } from "./WeeklyRealizationExport";
import { getBackend } from "../../lib/backend/Backend2";

import type { ScrapUptimeMatrixLines } from "../manufacturing/WeeklyRealizationCommon"

// $FlowFixMe
const ExportToXLSX = React.lazy(() => import("./ExportToXLSX"));

type State = {
    showProductionModal: boolean,
    productionModalParams?: ProductionModalParams,
    error: string,
    warning: string,
    loaded: boolean,
    merged_data: WeeklyReport[],
    insights: t.IEventDataEx[][]
}

type Props = {
    plant_uuid: string,
    line_group_uuid: string,
    filter: MicroplanSummaryFilterProps,
    exportToXLSXButtonSetLoadingState: Function,
    breakdown_level: string,
    is_export: boolean,
    is_print?: boolean,
    state?: State,
    scrap_uptime_matrix?: ScrapUptimeMatrixLines
}

const initialState: State = {
    showProductionModal: false,
    productionModalParams: undefined,
    loaded: false,
    merged_data: [],
    warning: "",
    error: "",
    insights: [[]],
    in_progress: false
};


export const loadWeeklyRealization = async (week: number, year: number, plant_uuid: string,
    line_group_uuid: string, onLoadData: Function, sim_report_add_to_cache: boolean) => {

    const line_group = BusinessLogic.getLineGroupForUser(line_group_uuid);
    const line_uuids = line_group ? line_group.line_uuids : [];

    if (line_uuids.length === 0) {
        return onLoadData({ error: translate("common.error", "Error"), in_progress: false, loaded: true });
    }

    onLoadData({
        in_progress: true,
        loaded: false,
        merged_data: [],
        insights: [],
        warning: "",
        error: ""
    });

    try {
        // go to the backend and get realization
        const result = await loadRealizationWithPlan(week, year, plant_uuid, line_uuids, false, sim_report_add_to_cache);

        // render any errors or warnings
        if (result.error) {
            onLoadData({ error: result.error, in_progress: false });
        } else if (result.warning) {
            onLoadData({ warning: translate(result.warning, result.warning), in_progress: false });
        }

        // get downtime insights
        const insights = await loadInsights(line_uuids, week, year);

        const lines = await getBackend().manufacturing.getLines({
            line_uuids: line_uuids
        });

        const remove_lines_uuid = lines.lines
            .filter(el => el.tags.skip_weekly_realization === "true")
            .map(el => el.uuid);

        if (remove_lines_uuid.length > 0) {
            result.data = result.data.filter(el => !remove_lines_uuid.includes(el.line_uuid));
        }

        onLoadData({
            merged_data: result.data,
            insights,
            loaded: true,
            in_progress: false
        });

        return result.data;
    } catch (err) {
        onLoadData({ error: err, merged_data: [], loaded: true, in_progress: false });
    }
}

const exportWeeklyRealization = (props: Props, state: State) => () => {
    exportWeeklyRealizationHTML(renderToString(<WeeklyRealization {...props} is_print={true} state={state} />));
}

const WeeklyRealization = (props: Props) => {

    const [state: State, setState] = useState(initialState);

    const ref = useRef(null);
    const onLoadData = (data) => {
        setState(prevState => { return { ...prevState, ...data } })
    };
    const onShowProductionModal = (data) => setState(prevState => { return { ...prevState, ...data } });

    // used for printing
    if (props.state && JSON.stringify(props.state) !== JSON.stringify(state)) {
        setState({ ...props.state })
    }

    // cache simulation reports when preparing weekly realization report
    const sim_report_add_to_cache = true;
    useEffect(() => {
        loadWeeklyRealization(props.filter.week, props.filter.year, props.plant_uuid, props.line_group_uuid, onLoadData, sim_report_add_to_cache)
    }, []);

    useEffect(() => {
        loadWeeklyRealization(props.filter.week, props.filter.year, props.plant_uuid, props.line_group_uuid, onLoadData, sim_report_add_to_cache)
    }, [props.filter.week, props.filter.year, props.plant_uuid, props.line_group_uuid]);

    useEffect(() => {
        if (state.loaded) {
            ref.current.removeEventListener("export-weekly-realization-for-print", exportWeeklyRealization(props, state));
            ref.current.addEventListener("export-weekly-realization-for-print", exportWeeklyRealization(props, state));
        }
    }, [state.loaded]);

    if (!state.loaded) {
        return <Loader />
    }

    const ExportComp = <ExportToXLSX
        exportToXLSXButtonSetLoadingState={props.exportToXLSXButtonSetLoadingState}
        loading={!state.loaded}
    />;

    return (
        <div id="weekly-realization" ref={ref}>
            <ProductionModal
                show={state.show}
                data={state.productionModalParams}
                onShowProductionModal={onShowProductionModal}
            />

            <WeeklyRealizationShift
                is_print={props.is_print}
                is_export={false}
                insights={state.insights}
                onShowProductionModal={onShowProductionModal}
                merged_data={state.merged_data}
                filter={props.filter}
                line_group_uuid={props.line_group_uuid}
                scrap_uptime_matrix={props.scrap_uptime_matrix}
            />

            {props.is_print ? ExportComp : <Suspense fallback={<Loader />}> {ExportComp} </Suspense>}


        </div>
    )
}

export default WeeklyRealization;
