// @flow

// main imports
import React, { Component } from "react";
import { connect } from "react-redux";
import ReactRouterPropTypes from "react-router-prop-types";
import { FormattedMessage } from "react-intl";

// models
import type { ILineData, ILineGroupData, IPlantData } from "../../lib/backend/manufacturing2.generated.types";
// backend
import { getBackend } from "../../lib/backend/Backend2";
import {
    waitForManufacturingAsync, getPlantsForUser, getLineGroupsForUser, filterLinesForUser,
    forceManufacturingCacheRefresh
} from "../../lib/BusinessLogic";
import * as Auth from "../../lib/Auth";
import * as Redirect from "../URLHandler/Redirect";

// import subcomponents
import LineGroupsList from "./LineGroupsList"
import LineGroupsDetails from "./LineGroupsDetails"
import ErrorComponent from "../ErrorComponent";
import { translate } from "../IntlProviderWrapper";
import Authorization from "../Authorization";

import type { ResourceEditFiltersState } from "../redux/reducers/resourceEditFiltersReducer";
import { ResourcesLayout } from "../MainContent";

// defining types
type Props = {
    match: ReactRouterPropTypes.match,
    history: ReactRouterPropTypes.history,
    location: ReactRouterPropTypes.location,
    resource_edit_filters: ResourceEditFiltersState
}

type State = {
    line_groups: ILineGroupData[],
    lines: ILineData[],
    plants: IPlantData[],
    plant_uuid: string,
    plant_uuid_to_title: Map<string, string>,
    error: string,
    warning: string,
    selected: ILineGroupData | null,
    title_filter: string
}

/**
 * Displaying production lines list.
 */
class LineGroupsComponent extends Component<Props, State> {
    linegroupsListRef: { current: null | LineGroupsList };

    /**
     * Constructor.
     * @param {Props} props Props object.
     * @param {State} state State object.
     */
    constructor(props: Props, state: State) {
        super(props);
        this.state = {
            line_groups: [],
            lines: [],
            plants: [],
            plant_uuid: "",
            plant_uuid_to_title: new Map(),
            error: "",
            warning: "",
            selected: null,
            title_filter: ""
        };
        this.linegroupsListRef = React.createRef();
    }

    /**
     * Load component data from backend.
     */
    async loadComponent() {
        try {
            await waitForManufacturingAsync();
            const plants = getPlantsForUser();
            const line_groups = getLineGroupsForUser();
            let lines = filterLinesForUser(
                (await getBackend().manufacturing.getLines({})).lines, true
            );
            let selected = null;

            if (this.props.resource_edit_filters.plant_uuid) {
                lines = lines.filter(el => el.plant_uuid === this.props.resource_edit_filters.plant_uuid);
            }

            // collect plant weights
            const plant_weights: Map<string, number> = new Map();
            const plant_uuid_to_title: Map<string, string> = new Map();
            plants.forEach(plant => {
                plant_weights.set(plant.uuid, plant.weight);
                plant_uuid_to_title.set(plant.uuid, plant.title);
            });
            const linegroup_plant_weights: Map<string, number> = new Map();
            line_groups.forEach(x => {
                if (x.plant_uuids.length > 0) {
                    const plant_uuid = x.plant_uuids[0];
                    if (plant_weights.has(plant_uuid)) {
                        const plant_weight = plant_weights.get(plant_uuid);
                        if (typeof(plant_weight) !== "undefined") {
                            linegroup_plant_weights.set(x.uuid, plant_weight);
                        }
                    }
                }
            });
            // sort linegroups by plant_weight and weight
            line_groups.sort((a, b) => {
                // set plant weight from Map if it is defined
                let a_plant_weight = Number.MAX_SAFE_INTEGER;
                if (linegroup_plant_weights.has(a.uuid)) {
                    a_plant_weight = linegroup_plant_weights.get(a.uuid);
                    if (typeof(a_plant_weight) === "undefined") {
                        a_plant_weight = Number.MAX_SAFE_INTEGER;
                    }
                }
                // set plant weight from Map if it is defined
                let b_plant_weight = Number.MAX_SAFE_INTEGER;
                if (linegroup_plant_weights.has(b.uuid)) {
                    b_plant_weight = linegroup_plant_weights.get(b.uuid);
                    if (typeof(b_plant_weight) === "undefined") {
                        b_plant_weight = Number.MAX_SAFE_INTEGER;
                    }
                }
                if (a_plant_weight > b_plant_weight) {
                    return 1;
                }
                if (a_plant_weight < b_plant_weight) {
                    return -1;
                }
                if (a.weight > b.weight) {
                    return 1;
                }
                if (a.weight < b.weight) {
                    return -1;
                }
                return 1;
            });
            // Select initial linegroup.
            if (this.props.match.params.uuid) {
                let filtered = line_groups.filter(line_group => line_group.uuid === this.props.match.params.uuid);
                if (filtered.length) {
                    selected = filtered[0];
                }
            } else if (line_groups.length) {
                selected = line_groups[0];
            }

            this.setState({
                line_groups,
                lines: lines,
                plants,
                plant_uuid_to_title,
                error: "",
                warning: "",
                selected
            })

        } catch (e) {
            this.setState({ error: e });
        }

    }

    /**
     * Load line groups.
     */
    async loadLineGroups() {
        // Get available linegroups.
        const s = this.state.selected;
        if (s !== null) {
            await this.loadComponent();
        }
    }

    /**
     * Mounting data for this component.
     */
    async componentDidMount() {
        await this.loadComponent();
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.resource_edit_filters.plant_uuid !== this.props.resource_edit_filters.plant_uuid) {
            await this.loadComponent();
        }
    }
    /**
     * Deleting a selected line.
     *
     * @param {string} uuid Line universal unique identifier.
     */
    async handleToDelete(uuid: string) {
        try {
            await getBackend().manufacturing.deleteLineGroup({ linegroup_uuid: uuid });
            await forceManufacturingCacheRefresh();
            this.handleToUpdate();
        } catch (e) {
            this.handleToError(e);
        };
    }

    /**
     * Select the first line that matches all the given criteria.
     * @param {string} contentFilter Line search term.
     * @param {string} plantUuid Plant UUID.
     * @param {ILineGroupData[]} [lines] List of lines to select from.
     */
    selectLineGroup(contentFilter: string, plantUuid: string, line_groups: ILineGroupData[] = this.state.line_groups): ILineGroupData | null {
        for (let line_group of line_groups) {
            const visible = this.linegroupsListRef.current && this.linegroupsListRef.current.includes(contentFilter, line_group);
            if ((line_group.plant_uuids.includes(plantUuid) || !plantUuid) && visible) {
                return line_group;
            }
        }
        return null;
    }

    /**
     * Handling change in any input component.
     */
    handleInputChange = (event: Event) => {
        if (event.currentTarget instanceof HTMLInputElement || event.currentTarget instanceof HTMLSelectElement) {
            // read new values
            const target = event.currentTarget;
            const value = target.value;
            const name = target.name;

            if (name !== "plant_uuid") {
                // update new datasource placeholder
                this.setState(prev_state => {
                    prev_state[name] = value;
                    return prev_state;
                });
            }

            let titleFilter = this.state.title_filter;
            let plantUuid = this.props.resource_edit_filters.plant_uuid;

            if (name === "plant_uuid") {
                plantUuid = value;
                const line_group = this.state.line_groups.filter(lg => lg.plant_uuids.includes(plantUuid));
                const line_group_uuid = line_group.length > 0 ? line_group[0].uuid : "";

                Redirect.resourceEditFilters(
                    this.props.history,
                    "/digital-twin/resources/line_groups/" + line_group_uuid,
                    {
                        ...this.props.resource_edit_filters,
                        plant_uuid: plantUuid
                    }
                );
            } else if (name === "title_filter") {
                titleFilter = value;
            }

            this.setState({
                selected: this.selectLineGroup(titleFilter, plantUuid)
            });
        }
    };

    /**
     * Handle `click` events for line items.
     */
    handleLineGroupClick(line_group: ILineGroupData) {
        this.setState({ selected: line_group });

        // Update URL.
        this.props.history.push(`/digital-twin/resources/line_groups/${line_group.uuid}`);
    }

    /**
     * Handle, that can be called by the children, to update the data.
     */
    handleToUpdate = () => {
        this.loadComponent();
    }

    /**
     * Handling error from subordinate component.
     */
    handleToError = (str: string) => {
        this.setState({ error: str });
    }

    /**
     * Rendering JSX for current component.
     */
    render() {
        return (
            <ResourcesLayout>
                <ResourcesLayout.Main>
                    <ResourcesLayout.List>
                        <ResourcesLayout.Search>
                            <div className="form-group form-group-stretch">
                                <input type="text" className="form-control search_bar" placeholder={translate("common.search", "Search") + " ..."}
                                    name="title_filter" value={this.state.title_filter} onChange={this.handleInputChange} />
                            </div>
                            <div className="form-group">
                                <select name="plant_uuid" className="form-control select_filter_control" value={this.props.resource_edit_filters.plant_uuid} onChange={this.handleInputChange}>
                                    <option key={-1} value="">
                                        {translate("Manufacturing.LineGroups.all_plants", "All Plants")}
                                    </option>
                                    {
                                        this.state.plants.map((plant, i) => {
                                            return <option key={i} value={plant.uuid}>{plant.title}</option>
                                        })
                                    }
                                </select>
                            </div>
                            <div className="form-group">
                                <Authorization.Link
                                    to="/digital-twin/resources/line_group/create"
                                    className="btn btn-inv-primary btn-icon btn-icon-add"
                                    title={translate("Manufacturing.LineGroups.add_new_line_group", "Add new line group")}
                                    permission={Auth.PERMISSION_NAMES.LineGroupsEdit}
                                >
                                    <span className="sr-only">
                                        <FormattedMessage id="Manufacturing.LineGroups.add_new_line_group" defaultMessage="Add new line group" />
                                    </span>
                                </Authorization.Link>
                            </div>
                        </ResourcesLayout.Search>
                        <LineGroupsList
                            ref={this.linegroupsListRef}
                            line_groups={this.state.line_groups}
                            lines={this.state.lines}
                            selected={this.state.selected}
                            filter={this.state.title_filter}
                            plantFilter={this.props.resource_edit_filters.plant_uuid}
                            plant_uuid_to_title={this.state.plant_uuid_to_title}
                            handleItemClick={(item) => { this.handleLineGroupClick(item) }} />
                    </ResourcesLayout.List>
                    <ResourcesLayout.Content>
                        <ErrorComponent msg={this.state.error} type="error" />
                        <ErrorComponent msg={this.state.warning} type="warning" />
                        {this.state.selected &&
                            <LineGroupsDetails
                                selected={this.state.selected}
                                lines={this.state.lines}
                                plants={this.state.plants}
                                handleToDelete={(uuid) => this.handleToDelete(uuid)}
                                loadLineGroups={async () => this.loadLineGroups()}
                            />
                        }
                    </ResourcesLayout.Content>
                </ResourcesLayout.Main>
            </ResourcesLayout>
        )
    }

}

export default connect(
    state => {
        return { resource_edit_filters: state.resource_edit_filters }
    },
    null
)(LineGroupsComponent);
