// @flow
import * as React from "react";
import { translate } from "../IntlProviderWrapper";
import { FormattedMessage } from "react-intl";
import * as tt from "../../lib/backend/ticketing.generated.types";
import { niceDateTime, transpose } from "../../lib/Util";
import { getBackend } from "../../lib/backend/Backend2";
import ReactTable from "../react/ReactTable";
import DataFields from "../react/DataFields";
import ActionButtons from "../react/ActionButtons";
import type { ActionButtonProps } from "../react/ActionButtons";
import type { CellArgs } from "./TicketList";
import type { TicketState } from "./CommonTicketTypes";
import type { Cell } from "../react/DataFields";
import * as Auth from "../../lib/Auth";
import Toggle from "react-toggle";
import SimpleTable from "../react/SimpleTable";

type TicketHistoryProps = {
    history: tt.ITicketHistoryItem[]
}

const DateCell = ({ cell }: CellArgs): React.Node => {
    return <span>{niceDateTime(new Date(cell.value))}</span>
}
const TypeIconCell = ({ cell }: CellArgs): React.Node => {
    let icon = "fa-check-circle";
    switch (cell.value) {
        case "step_change": icon = "fa-flag"; break;
        case "var_change": icon = "fa-list-ol"; break;
        case "assignee_change": icon = "fa-user"; break;
        case "error": icon = "fa-exclamation-circle ticketing-fa-icon-error"; break;
    }
    return <React.Fragment>
        <i className={"fas " + icon + " ticketing-fa-icon vertical-align-middle"}></i> {cell.value}
    </React.Fragment>;
}

const getColumns = () => {
    return [
        {
            Header: translate("Ticketing.created_on", "Created on"),
            accessor: "created_at",
            disableFilters: true,
            Cell: DateCell
        },
        {
            Header: translate("common.type", "Type"),
            accessor: "type",
            disableFilters: true,
            Cell: TypeIconCell
        },
        {
            Header: translate("Ticketing.created_by", "Created by"),
            accessor: "created_by",
            disableFilters: true
        },
        {
            Header: translate("common.description", "Description"),
            accessor: "description",
            disableFilters: true
        }
    ]
}


const TicketHistory = (props: TicketHistoryProps) => {
    return <div className="ticket-details-table">
        <span className="value">
            <ReactTable
                columns={getColumns()}
                data={props.history}
            />
        </span>
    </div>
}

type Props = {
    requestRefreshCallback: () => void | () => Promise<void> | null,
    ticket: TicketState,
    show_tabs?: boolean,
    show_actions: boolean,
    hide_tabs: boolean
};

type State = {
    attachments: tt.ITicketAttachment[] | null,
    tab_key: TabKey,
    type_filter: string[]
};

type TabKey = "history" | "variables" | "attachments";

export class TicketDetailsDiv extends React.Component<Props, State> {
    state: State = {
        attachments: null,
        tab_key: "history",
        type_filter: [
            "error",
            // "var_change",
            // "title_change",
            "step_change",
            "assignee_change"]
    }

    async componentDidMount() {
        await this.loadAttachments();
    }
    async componentDidUpdate(prevProps: Props) {
        if (prevProps.ticket.uuid !== this.props.ticket.uuid) {
            this.setState({ attachments: null });
            await this.loadAttachments();
        }
    }

    onTabSelect(tab_key: TabKey) {
        this.setState({ tab_key, type_filter: [] });
    }

    async loadAttachments() {
        const res = await getBackend().ticketing.getTicketAttachments({ uuid: this.props.ticket.uuid });
        this.setState({ attachments: res.attachments });
    }

    getHistory() {
        if (this.props.ticket.history === null) {
            return [];
        }
        return this.props.ticket.history.filter((item) => {
            if (this.state.type_filter.includes(item.type)) {
                return item;
            }
        });
    }

    onChange(field: string, value: string, checked: boolean) {
        if (field === "type_filter") {

            const prev_value = this.state.type_filter;

            if (value === "status_change") {
                if (checked) {
                    prev_value.push("step_change");
                    prev_value.push("assignee_change");
                    prev_value.push("error");
                } else {
                    let index = prev_value.indexOf("step_change");
                    if (index > -1) {
                        prev_value.splice(index, 1);
                    }
                    index = prev_value.indexOf("assignee_change");
                    if (index > -1) {
                        prev_value.splice(index, 1);
                    }
                    index = prev_value.indexOf("error");
                    if (index > -1) {
                        prev_value.splice(index, 1);
                    }
                }
                this.setState({ type_filter: prev_value });
            } else if (value === "data_change") {
                if (checked) {
                    prev_value.push("var_change");
                    prev_value.push("title_change");
                } else {
                    let index = prev_value.indexOf("var_change");
                    if (index > -1) {
                        prev_value.splice(index, 1);
                    }
                    index = prev_value.indexOf("title_change");
                    if (index > -1) {
                        prev_value.splice(index, 1);
                    }
                }
                this.setState({ type_filter: prev_value });
            }

        }
    }

    renderAttachments() {
        if ((this.state.attachments || []).length == 0) {
            return translate("common.no_attachments", "No attachments");
        }
        const header: string[] = [translate("common.name", "Name"), translate("common.type", "Type")];
        const data: string[][] = (this.state.attachments || []).map(x => [x.title, x.type]);
        return <SimpleTable header={header} data={data} />;
    }

    renderHistory() {
        return <TicketHistory
            history={this.getHistory()}
            type_filter={this.state.type_filter}
            onChange={(field, value, checked) => this.onChange(field, value, checked)}
        />;
    }

    renderVariables() {
        if (this.props.ticket.variables === null) {
            return null;
        }
        const data_fields: Cell[][] = this.props.ticket.variables.map(x => [{
            label: x.name,
            text: x.value
        }]);
        return <DataFields rows={data_fields} />;
    }

    onChangeTab(tab_key: TabKey) {
        this.setState({ tab_key });
    }

    onStatusChange(e: any) {
        this.onChange("type_filter", "status_change", e.target.checked);
    }

    onDataChange(e: any) {
        this.onChange("type_filter", "data_change", e.target.checked);
    }

    renderFilters(props: Props) {
        if (this.state.tab_key === "history") {
            return (
                <div className="ticketing-filter">
                    <div className="ticketing-filter-body" style={{ position: "relative", paddingTop: "10px", paddingLeft: "8px", paddingRight: "3px" }}>
                        <div style={{ display: "flex" }} className="ticketing-filter-item" onChange={(e) => this.onChange("type_filter", e.target.value, e.target.checked)}>
                            <div style={{ position: "relative", display: "flex" }}>
                                <div style={{ position: "relative", top: "5px", paddingLeft: "8px", paddingRight: "5px" }}>
                                    <FormattedMessage id="Ticketing.status_changes" defaultMessage="Status changes" />
                                </div>
                                <Toggle
                                    value="assignee_change"
                                    onChange={(e) => this.onStatusChange(e)}
                                    checked={this.state.type_filter.includes("error") && this.state.type_filter.includes("step_change") && this.state.type_filter.includes("assignee_change")}
                                />
                            </div>

                            <div style={{ position: "relative", display: "flex" }}>
                                <div style={{ position: "relative", top: "5px", paddingLeft: "8px", paddingRight: "5px" }}>
                                    <FormattedMessage id="Ticketing.data_changes" defaultMessage="Data changes" />
                                </div>
                                <Toggle
                                    onChange={(e) => this.onDataChange(e)}
                                    checked={this.state.type_filter.includes("var_change") && this.state.type_filter.includes("title_change")}
                                />
                            </div>

                        </div>
                    </div>

                </div >
            );
        }

    }
    renderTabs() {
        return <ul className="nav nav-tabs pt-2 pr-2 pl-2" role="tablist">
            <li className="nav-item" role="presentation">
                <button
                    className={`nav-link ${this.state.tab_key === "history" ? "active" : ""}`}
                    onClick={() => this.onChangeTab("history")}
                >
                    <FormattedMessage id="Ticketing.history" defaultMessage="History" />
                </button>
            </li>
            <li className="nav-item" role="presentation">
                <button
                    className={`nav-link ${this.state.tab_key === "variables" ? "active" : ""}`}
                    onClick={() => this.onChangeTab("variables")}
                >
                    <FormattedMessage id="Ticketing.data" defaultMessage="Variables" />
                </button>
            </li>
            <li className="nav-item" role="presentation">
                <button
                    className={`nav-link ${this.state.tab_key === "attachments" ? "active" : ""}`}
                    onClick={() => this.onChangeTab("attachments")}
                >
                    <FormattedMessage id="common.attachments" defaultMessage="Attachments" />
                </button>
            </li>
        </ul>
    }

    renderTabContent() {
        if (this.state.tab_key === "attachments") {
            return this.renderAttachments();
        }
        if (this.state.tab_key === "variables") {
            return this.renderVariables();
        }
        return this.renderHistory();
    }

    render() {
        const ticket = this.props.ticket;
        const is_admin = Auth.isInRole(Auth.ROLE_ADMIN);
        const is_power_user = Auth.isInRole(Auth.ROLE_POWER_USER);
        const is_admin_or_power_user = is_admin || is_power_user;

        // arrange in 4 cols when tabs are shown
        let data_fields: Cell[][] = [
            [
                { label: translate("Ticketing.ticket", "Ticket"), text: ticket.title },
                { label: translate("Ticketing.assignee", "Assignee"), text: ticket.assigned_to },
                {
                    label: translate("Ticketing.closed", "Closed"),
                    text: ticket.is_closed ? "Yes" : "No",
                    bgcolor: ticket.is_closed ? "green" : undefined,
                    color: ticket.is_closed ? "white" : undefined
                },
                { label: translate("Ticketing.workflow", "Workflow"), text: ticket.workflow_title }
            ],
            [
                { label: translate("Ticketing.step", "Step"), text: ticket.step },
                { label: translate("Ticketing.assigned_on", "Assigned on"), text: niceDateTime(new Date(ticket.assigned_on)) },
                {
                    label: translate("Ticketing.error", "Error"),
                    text: ticket.is_error ? "Yes" : "No",
                    bgcolor: ticket.is_error ? "red" : undefined,
                    color: ticket.is_error ? "white" : undefined
                },
                { label: translate("Ticketing.workflow_version", "Workflow version"), text: ticket.workflow_version }
            ]
        ];
        if (this.props.hide_tabs) {
            // arrange in 1 col when tabs are not shown
            // $FlowFixMe
            data_fields = (transpose(data_fields)).flat().map(x => [x]);
        } else if (is_admin) {
            // show uuid to admins, used for debugging
            data_fields.push([
                { label: "Uuid", text: ticket.uuid },
            ]);
        }

        const actions: ActionButtonProps[] = [];
        const commonCallHandler = (promise: Promise<any>) => {
            promise
                .then(() => {
                    alert(translate("Ticketing.data_successfully_updated", "Data successfully updated"));
                    this.props.requestRefreshCallback();
                })
                .catch((e) => {
                    alert(translate("Ticketing.error_occured", "Error occured") + ": " + e.message);
                })
        }
        if (this.props.show_actions && this.props.requestRefreshCallback != null) {

            if (is_admin_or_power_user) {
                const reassignTicketCallback = () => {
                    const username = prompt(translate("Ticketing.enter_new_assignee", "Please enter username of the new asignee"));
                    if (username == null) {
                        return;
                    }
                    commonCallHandler(getBackend().ticketing.reassignTicket({ uuid: ticket.uuid, username }));
                }
                actions.push({ title: translate("Ticketing.reassign", "Assign to another"), onClick: reassignTicketCallback })
            }
            if (is_admin_or_power_user && !ticket.is_closed) {
                const closeTicketCallback = () => {
                    const comment = prompt(translate("Ticketing.enter_comment", "Please enter comment"));
                    if (comment == null) {
                        return;
                    }
                    commonCallHandler(getBackend().ticketing.closeTicket({ uuid: ticket.uuid, comment }));
                }
                actions.push({ title: translate("Ticketing.close_manually", "Close manually"), onClick: closeTicketCallback })
            }
            if (ticket.is_error) {
                const removeErrorCallback = () => {
                    const comment = prompt(translate("Ticketing.enter_comment", "Please enter comment"));
                    if (comment == null) {
                        return;
                    }
                    commonCallHandler(getBackend().ticketing.setTicketErrorFlag({ uuid: ticket.uuid, error_flag: false, comment }));
                }
                actions.push({ title: translate("Ticketing.error_flag_unset", "Remove error flag"), onClick: removeErrorCallback })
            } else {
                const setErrorCallback = () => {
                    const comment = prompt(translate("Ticketing.enter_comment", "Please enter comment"));
                    if (comment == null) {
                        return;
                    }
                    commonCallHandler(getBackend().ticketing.setTicketErrorFlag({ uuid: ticket.uuid, error_flag: true, comment }));
                }
                actions.push({ title: translate("Ticketing.error_flag_set", "Set error flag"), onClick: setErrorCallback })
            }
        }
        return <div className="ticket-details">
            <DataFields rows={data_fields} label_above_value={true} />
            <br />
            {
                !this.props.hide_tabs && this.props.show_actions && <ActionButtons buttons={actions} />
            }
            {
                !this.props.hide_tabs && <React.Fragment>
                    <div>
                        {this.renderTabs()}
                    </div>
                    <div>
                        {this.renderFilters(this.props)}
                        <div style={{ borderBottom: "1px solid black", padding: "4px" }}>

                        </div>
                    </div>
                    <div>
                        {this.renderTabContent()}
                    </div>
                </React.Fragment>
            }
        </div>
    }
}