// @flow
import type { PayloadAction } from "../../../redux/types";

export const UPDATE_DRAG: "update_drag" = "update_drag";
export const DRAGGING_ORDER: "dragging_order" = "dragging_order";
export const TRANSLATE_CURSOR: "translate_cursor" = "translate_cursor";
export const IS_INSIDE_ORDER: "is_inside_order" = "is_inside_order";
export const RESET_DRAGGED_ORDER: "reset_dragged_order" = "reset_dragged_order";
export const SNAP_TO_SHIFT: "snap_to_shift" = "snap_to_shift";

const SHIFT: "shift" = "shift";
const ORDER: "order" = "order";

export type ELEMENT_TYPES = typeof SHIFT | typeof ORDER;

export type TranslatePoint = {
    translate_x: number,
    translate_y: number
}

export type DraggingOrder = {
    translate_x: number,
    translate_y: number,
    original_line_uuid: string,
    new_line_uuid: string,
    new_order_index: number,
    order_uuid: string,
    earliest_start: number,
    is_unscheduled_order: boolean,
    production_version: string,
    line_uuid: string,
    order_index: number,
    line_index: number,
    override_earliest_start?: number
}

export type IsInsideOrderAction = PayloadAction<typeof IS_INSIDE_ORDER, boolean>;
export type DraggingOrderAction = PayloadAction<typeof DRAGGING_ORDER, DraggingOrder | null>;
export type TranslateCursorAction = PayloadAction<typeof TRANSLATE_CURSOR, TranslatePoint>;
export type ResetDraggedOrderAction = PayloadAction<typeof RESET_DRAGGED_ORDER, typeof undefined>;
export type SnapToShiftAction = PayloadAction<typeof SNAP_TO_SHIFT, boolean>;

export type Actions = DraggingOrderAction | IsInsideOrderAction | TranslateCursorAction |
    ResetDraggedOrderAction | SnapToShiftAction;

export type DraggableContextState = {
    translate_cursor: TranslatePoint | null,
    is_inside_order: boolean,
    dragging_order: DraggingOrder | null,
    snap_to_shift: boolean
}

export const getInitialState = (): DraggableContextState => {
    return {
        translate_cursor: null,
        is_inside_order: false,
        dragging_order: null,
        snap_to_shift: true
    };
};

export function draggableReducer(state: DraggableContextState, action: Actions): DraggableContextState {
    let new_state = state;

    if (action.type === IS_INSIDE_ORDER) {
        new_state = {
            ...new_state,
            is_inside_order: action.data
        };
    } if (action.type === DRAGGING_ORDER) {
        if (action.data) {
            new_state = {
                ...new_state,
                dragging_order: {
                    ...(new_state.dragging_order || {}),
                    ...action.data
                }
            }
        } else {
            new_state = {
                ...new_state,
                dragging_order: null
            };
        }
    } else if (action.type === TRANSLATE_CURSOR) {
        new_state = {
            ...new_state,
            translate_cursor: action.data
        };
    } else if (action.type === RESET_DRAGGED_ORDER) {
        new_state = {
            ...getInitialState(),
            snap_to_shift: new_state.snap_to_shift
        };
    } else if (action.type === SNAP_TO_SHIFT) {
        new_state = {
            ...new_state,
            snap_to_shift: action.data
        };
    }

    return new_state;
}
