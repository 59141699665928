// @flow
import { getTranslationMaterialFromStore } from "./redux/reducers/translationsMaterialsReducer"
import { store } from "../index";

type Args = {
    material_external_id: string,
    material_title: string
}
const TranslatedMaterialTitle = ({ material_external_id, material_title }: Args): string => {
    const material_translation = getTranslationMaterialFromStore(
        store.getState(), { external_id: material_external_id }
    );
    return material_translation || material_title;
}

export default TranslatedMaterialTitle;


