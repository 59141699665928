// @flow
import * as React from "react";

// $FlowFixMe
import "../styles/marked_text.scss";

type Props = {
    text: string,
    search?: string
};

const MarkedText = ({ text, search }: Props) => {
    if (search === undefined || search.length === 0) {
        return text;
    }

    const index = text.toLowerCase().indexOf(search.toLowerCase());
    if (index < 0) {
        return text;
    }

    const mark_start = index;
    const mark_end = index + search.length;
    return (
        <React.Fragment>
            {text.slice(0, mark_start)}
            <mark className="marked-text">{text.slice(mark_start, mark_end)}</mark>
            {text.slice(mark_end)}
        </React.Fragment>
    );
};

export default MarkedText;
