// @flow
import * as React from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";

import { translate } from "../../IntlProviderWrapper";
import ErrorComponent from "../../ErrorComponent";
import Loader from "../../Loader";
import { LinesOrdersMaps } from "./reducers/Mappers";
import { ORDER_TAGS_ACCESS } from "../../../lib/ManufacturingTags.generated";
import { ORDER_STATUS } from "../../../lib/ManufacturingConsts.generated";
import ReactTable from "../../react/ReactTable";
import { getBackend } from "../../../lib/backend/Backend2";
import LinkOperationsModal from "./LinkOperationsModal";
import DraggableModal from "../../DraggableModal";
import { OperationInfo } from "./LinkOrdersModal";

import type { Cell, Column, ColumnInstance, FilterProps, Row } from "react-table";
import type { IOrderProducedModelEx } from "./reducers/linesOrders";
import type { IOrderModelBase } from "../../../lib/backend/manufacturing2.generated.types";

// $FlowFixMe
import "../../../styles/operation_links_modal.scss";

const LINK_FILTERS = {
    open_operations_with_links: "open_operations_with_links",
    open_operations_with_bad_links: "open_operations_with_bad_links",
};

const LINK_COLUMNS = {
    rework_operation: "rework_operation",
    base_operation: "base_operation",
    actions: "actions"
};

type LinkRow = {
    rework_operation: IOrderProducedModelEx,
    base_operation: IOrderProducedModelEx | IOrderModelBase | null,
    actions: IOrderProducedModelEx
};

type SelectOption = {
    value: string,
    label: string
};

type Props = {
    show: boolean,
    onClose: () => void
};

type State = {
    error: string,
    is_loading: boolean,
    selected_filter: string,
    selected_operation: IOrderProducedModelEx | null,
    rework_order_id_filter: string,
    base_order_id_filter: string,
    external_operations: Map<string, IOrderModelBase>
};

const OrderCell = ({ value }: Cell<LinkRow, IOrderProducedModelEx | IOrderModelBase | null>) => {
    return value !== null ? (
        <span>
            <strong>{value.external_id}</strong> - <em>{value.process_num}</em> - {value.title}{" "}
            {LinesOrdersMaps.all_orders_map.get(value.uuid) === undefined && <OperationInfo operation={value} />}
        </span>
    ) : (
        "-"
    );
};

const filterOperation = (rows: Row<LinkRow>[], columnId: string, filterValue: string): Row<LinkRow>[] => {
    return rows.filter(row => {
        const operation: IOrderProducedModelEx | IOrderModelBase | null = row.values[columnId];
        if (operation === null) {
            if (filterValue === "") {
                return true;
            }

            return false;
        }

        const filter = filterValue.toLowerCase();
        return (
            operation.external_id.toLowerCase().indexOf(filter) >= 0 ||
            (operation.title !== undefined && operation.title.toLowerCase().indexOf(filter) >= 0) ||
            operation.process_num.indexOf(filter) >= 0
        );
    });
};

const compareOperations = (a: IOrderProducedModelEx, b: IOrderProducedModelEx) => {
    if (a.external_id === b.external_id) {
        return a.process_num.localeCompare(b.process_num);
    }

    return a.external_id.localeCompare(b.external_id);
};

class OperationLinksModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const state: State = {
            error: "",
            is_loading: false,
            selected_filter: LINK_FILTERS.open_operations_with_links,
            selected_operation: null,
            rework_order_id_filter: "",
            base_order_id_filter: "",
            external_operations: new Map()
        };
        this.state = state;
    }

    async componentDidMount() {
        if (this.props.show) {
            await this.loadExtraLinesOrders();
        }
    }

    async componentDidUpdate(prev_props: Props) {
        if (this.props.show && !prev_props.show) {
            await this.loadExtraLinesOrders();
        }
    }

    loadExtraLinesOrders = async () => {
        const external_operation_uuids = [];
        for (const operation of LinesOrdersMaps.all_orders_map.values()) {
            const linked_operation = ORDER_TAGS_ACCESS.linked_operation(operation.tags);
            if (linked_operation !== "" && LinesOrdersMaps.all_orders_map.get(linked_operation) === undefined) {
                external_operation_uuids.push(linked_operation);
            }
        }

        if (external_operation_uuids.length > 0) {
            this.setState({ is_loading: true });
            try {
                const { operations } = await getBackend().manufacturing.getOrdersSimple({
                    uuids: external_operation_uuids
                });
                this.setState({
                    external_operations: new Map(operations.map(operation => [operation.uuid, operation]))
                });
            } catch (error) {
                this.setState({ error: error.message });
            }

            this.setState({ is_loading: false });
        }
    };

    getFilterOption = (filter: string): SelectOption => {
        const translations = {
            [LINK_FILTERS.open_operations_with_links]: translate(
                "common.open_operations_with_link",
                "Open operations with link"
            ),
            [LINK_FILTERS.open_operations_with_bad_links]: translate(
                "common.open_operations_with_bad_link",
                "Open operations with bad link"
            )
        };
        return {
            value: filter,
            label: translations[filter]
        };
    };

    getFilterOptions = (): SelectOption[] => {
        return Object.keys(LINK_FILTERS).map(this.getFilterOption);
    };

    getOperations = (filter: string): IOrderProducedModelEx[] => {
        const operations = [...LinesOrdersMaps.all_orders_map.values()].filter(operation => {
            const linked_operation = ORDER_TAGS_ACCESS.linked_operation(operation.tags);
            let base_operation = LinesOrdersMaps.all_orders_map.get(linked_operation);
            if (base_operation === undefined) {
                base_operation = this.state.external_operations.get(linked_operation);
            }

            const has_bad_link = base_operation !== undefined && base_operation.status === ORDER_STATUS.closed;
            if (filter === LINK_FILTERS.open_operations_with_links) {
                return operation.status === ORDER_STATUS.open && linked_operation !== "";
            } else if (filter === LINK_FILTERS.open_operations_with_bad_links) {
                return operation.status === ORDER_STATUS.open &&  has_bad_link;
            }

            return false;
        });
        operations.sort(compareOperations);
        return operations;
    };

    getColumns = (): Column<LinkRow>[] => [
        {
            accessor: LINK_COLUMNS.rework_operation,
            Header: "",
            filterInputPlaceholder: translate("common.trailing_operation", "Trailing operation"),
            disableFilters: false,
            Filter: this.renderTextFilter,
            filter: filterOperation,
            Cell: OrderCell
        },
        {
            accessor: LINK_COLUMNS.base_operation,
            Header: "",
            filterInputPlaceholder: translate("common.leading_operation", "Leading operation"),
            disableFilters: false,
            Filter: this.renderTextFilter,
            filter: filterOperation,
            Cell: OrderCell
        },
        {
            accessor: LINK_COLUMNS.actions,
            Header: "",
            disableFilters: true,
            Cell: this.renderActions
        }
    ];

    getRows = (): LinkRow[] => {
        const rework_operations = this.getOperations(this.state.selected_filter);
        return rework_operations.map(rework_operation => {
            const linked_operation = ORDER_TAGS_ACCESS.linked_operation(rework_operation.tags);
            let base_operation = LinesOrdersMaps.all_orders_map.get(linked_operation);
            if (base_operation === undefined) {
                base_operation = this.state.external_operations.get(linked_operation);
            }

            return {
                [LINK_COLUMNS.rework_operation]: rework_operation,
                [LINK_COLUMNS.base_operation]: base_operation || null,
                [LINK_COLUMNS.actions]: rework_operation
            };
        });
    };

    handleFilterChange = (filter: SelectOption) => {
        this.setState({ selected_filter: filter.value });
    };

    handleTextFilterChange = (event: SyntheticEvent<HTMLInputElement>, column: ColumnInstance<LinkRow>) => {
        const value = event.currentTarget.value;
        const id = column.id;
        column.setFilter(value);

        if (id === LINK_COLUMNS.rework_operation) {
            this.setState({ rework_order_id_filter: value });
        } else if (id === LINK_COLUMNS.base_operation) {
            this.setState({ base_order_id_filter: value });
        }
    };

    handleLinkEditClick = (operation: IOrderProducedModelEx) => {
        this.setState({ selected_operation: operation });
    };

    handleLinkEditClose = () => {
        this.setState({ selected_operation: null });
    };

    handleClose = () => {
        this.setState({
            error: ""
        });
        this.props.onClose();
    };

    renderTextFilter = ({ column }: FilterProps<LinkRow>) => {
        const label = column.filterInputPlaceholder;
        const value = column.filterValue !== undefined ? column.filterValue : "";
        const filter = (
            <input
                className="form-control column-filter"
                value={value}
                onChange={event => this.handleTextFilterChange(event, column)}
                placeholder={label}
                title={label}
            />
        );

        return filter;
    };

    renderActions = ({ value }: Cell<LinkRow, IOrderProducedModelEx>) => {
        return (
            <button className="btn btn-short btn-outline-secondary" onClick={() => this.handleLinkEditClick(value)}>
                <i className="fas fa-edit align-middle" />
            </button>
        );
    };

    renderStatus = () => {
        const status = [];
        if (this.state.error !== "") {
            status.push(<ErrorComponent key="error" type="error" msg={this.state.error} />);
        }

        return status.length > 0 && <div className="modal-statusbar">{status}</div>;
    };

    renderToolbar = () => {
        return (
            <div className="modal-toolbar">
                <Select
                    className="filter-select"
                    name="order"
                    placeholder={`${translate("common.select", "Select")}...`}
                    menuPosition="fixed"
                    value={this.getFilterOption(this.state.selected_filter)}
                    options={this.getFilterOptions()}
                    onChange={this.handleFilterChange}
                    autoFocus
                />
            </div>
        );
    };

    render() {
        return (
            <React.Fragment>
                <DraggableModal>
                    <Modal
                        dialogClassName="operation-links-modal modal-dialog-scrollable"
                        show={this.props.show}
                        animation={false}
                    >
                        <Modal.Header>
                            <Modal.Title>
                                <span>
                                    {translate("common.linked_operations_overview", "Linked operations overview")}
                                </span>
                            </Modal.Title>
                            <button type="button" className="close" onClick={this.handleClose}>
                                <span aria-hidden="true">×</span>
                                <span className="sr-only">{translate("common.close", "Close")}</span>
                            </button>
                        </Modal.Header>
                        {this.renderStatus()}
                        {this.renderToolbar()}
                        <Modal.Body className="p-0">
                            {this.state.is_loading ? (
                                <Loader small />
                            ) : (
                                <div className="row align-items-stretch h-100 m-0">
                                    <ReactTable
                                        columns={this.getColumns()}
                                        data={this.getRows()}
                                        initialState={{
                                            pageSize: 25,
                                            filters: [
                                                {
                                                    id: LINK_COLUMNS.rework_operation,
                                                    value: this.state.rework_order_id_filter
                                                },
                                                {
                                                    id: LINK_COLUMNS.base_operation,
                                                    value: this.state.base_order_id_filter
                                                }
                                            ]
                                        }}
                                        Pagination
                                    />
                                </div>
                            )}
                        </Modal.Body>
                    </Modal>
                </DraggableModal>
                <LinkOperationsModal
                    rework_operation={this.state.selected_operation}
                    show={this.state.selected_operation !== null}
                    onClose={this.handleLinkEditClose}
                />
            </React.Fragment>
        );
    }
}

export default OperationLinksModal;
