// @flow

// main imports
import React, { Component } from "react";
import ReactRouterPropTypes from "react-router-prop-types";
import { FormattedMessage } from "react-intl";

// models
import { translate } from "../IntlProviderWrapper";
import * as t from "../../lib/backend/manufacturing2.generated.types";

// subcomponents
import ErrorComponent from "../ErrorComponent";

// backend
import { getLineWeight } from "../../lib/BusinessLogic";
import { getBackend } from "../../lib/backend/Backend2";

type Props = {
    match: ReactRouterPropTypes.match,
    history: ReactRouterPropTypes.history,
    location: ReactRouterPropTypes.location
}

type State = {
    linegroup_uuid: string,
    linegroup_title: string,
    lines: t.ILineGroupMatrixLine[],
    processes: t.IProcessData[],
    error: string,
    warning: string
}

/**
 * Form to manage production matrix for materials and lines in the line group
 */
class LineGroupMatrix extends Component<Props, State> {

    constructor(props: Props) {

        super(props);
        this.state = {
            linegroup_uuid: "",
            linegroup_title: "",
            lines: [],
            processes: [],
            error: "",
            warning: ""
        }
    }

    async componentDidMount() {
        const { uuid } = this.props.match.params;
        const { line_groups } = await getBackend().manufacturing.getLineGroups({});
        const line_group = line_groups.filter(x => x.uuid === uuid)[0];
        const line_uuids = [];

        if (line_group !== undefined) {
            const { lines } = await getBackend().manufacturing.getLineGroupMatrix({ linegroup_uuid: uuid });

            if (lines.length == 0) {
                this.setState({ error: translate("Manufacturing.Lines.no_lines_in_linegroup", "No lines in that linegroup") + ": " + uuid })
                return null;
            }

            lines.sort((l1, l2) => (getLineWeight(l1.line_uuid) - getLineWeight(l2.line_uuid)));

            for (const line of lines) {
                line_uuids.push(line.line_uuid);
            }

            const { processes } = await getBackend().manufacturing.getProcesses({
                only_bottleneck: false,
                line_uuids
            });

            this.setState({
                linegroup_uuid: uuid,
                linegroup_title: line_group.title,
                lines,
                processes
            });
        } else {
            this.setState({ error: `Unknown line-group '${uuid}'` });
        }
    }

    handleEnabled = (event: Event, line_idx: number, material_idx: number, process_idx: number) => {
        const target = event.target;
        if (target instanceof HTMLInputElement) {
            if (target.type === "checkbox") {
                let lines = this.state.lines;
                lines[line_idx].materials[material_idx].processes[process_idx].use_in_planning = target.checked;

                if (target.checked) {
                    lines[line_idx].materials[material_idx].processes[process_idx].manual_use_in_planning = target.checked;
                }

                this.setState({ lines });
            }
        }
    }

    handleManualEnabled = (event: Event, line_idx: number, material_idx: number, process_idx: number) => {
        const target = event.target;
        if (target instanceof HTMLInputElement) {
            if (target.type === "checkbox") {
                let lines = this.state.lines;
                lines[line_idx].materials[material_idx].processes[process_idx].manual_use_in_planning = target.checked;

                if (!target.checked) {
                    lines[line_idx].materials[material_idx].processes[process_idx].use_in_planning = target.checked;
                }

                this.setState({ lines });
            }
        }
    }

    handleSubmit = () => {
        // assamble list of processes to add and remove from the matrix
        const enable_process_uuids = [];
        const disable_process_uuids = [];
        const enable_manual_process_uuids = [];
        const disable_manual_process_uuids = [];
        let lines = this.state.lines;
        for (const line of lines) {
            for (const material of line.materials) {
                for (const process of material.processes) {
                    if (process.use_in_planning) {
                        enable_process_uuids.push(process.process_uuid);
                    } else {
                        disable_process_uuids.push(process.process_uuid);
                    }

                    if (process.manual_use_in_planning) {
                        enable_manual_process_uuids.push(process.process_uuid);
                    } else {
                        disable_manual_process_uuids.push(process.process_uuid);
                    }
                }
            }
        }
        // submit lists to the backend
        getBackend().manufacturing.setLineGroupMatrix({
            disable_process_uuids,
            enable_process_uuids,
            disable_manual_process_uuids,
            enable_manual_process_uuids,
            linegroup_uuid: this.state.linegroup_uuid
        }).then(() => {
            // success, go back to linegroup details page
            this.props.history.push("/digital-twin/resources/line_groups/" + encodeURI(this.state.linegroup_uuid))
        }).catch((err) => {
            // error, report and stay here
            this.setState({ error: `${err}` });
        });
    }

    handleSelectMax = () => {
        let lines = this.state.lines;
        for (const line of lines) {
            for (const material of line.materials) {
                for (const process of material.processes) {
                    if (process.is_most_common) {
                        process.use_in_planning = true;
                        process.manual_use_in_planning = true;
                    }
                }
            }
        }
        this.setState({ lines });
    }

    handleSelectAll = () => {
        let lines = this.state.lines;
        for (const line of lines) {
            for (const material of line.materials) {
                for (const process of material.processes) {
                    process.use_in_planning = true;
                    process.manual_use_in_planning = true;
                }
            }
        }
        this.setState({ lines });
    }

    handleCancel = () => {
        this.props.history.push("/digital-twin/resources/line_groups/" + encodeURI(this.state.linegroup_uuid))
    }

    renderProcesses(line_idx: number, material_idx: number, processes: t.ILineGroupMatrixProcess[]) {
        processes.sort((p1, p2) => (p2.quantity_sum - p1.quantity_sum));
        const result = [];
        for (let i = 0; i < processes.length; i++) {
            const process = this.state.processes.find(p => p.uuid === processes[i].process_uuid);
            const row_classes: string[] = [];

            if (processes[i].is_most_common) {
                row_classes.push("process_table_max");
            }

            if (process && !process.tags.manual_use_in_planning && !process.tags.use_in_planning) {
                row_classes.push("process_table_new");
            }

            result.push(<tr key={`p${line_idx}_${material_idx}_${i}`} className={row_classes.join(" ")}>
                <td>&nbsp;</td>
                <td>
                    {processes[i].process_uuid && (
                        <label className="mb-0" title={translate("Manufacturing.LineGroups.matrix.use_in_planning", "Process can be used as alternative in the automatic planning process")}>
                            <i className="fas fa-magic align-middle" />
                            <input type="checkbox" name="enabled" className="align-middle ml-1" checked={processes[i].use_in_planning}
                                onChange={(event) => { this.handleEnabled(event, line_idx, material_idx, i); }} />
                        </label>
                    )}
                    {processes[i].process_uuid && (
                        <label className="mb-0 ml-2" title={translate("Manufacturing.LineGroups.matrix.manual_use_in_planning", "Process can be used in manual mode on planning table")}>
                            <i className="fas fa-hand-paper align-middle" />
                            <input type="checkbox" name="enabled" className="align-middle ml-1" checked={processes[i].manual_use_in_planning}
                                onChange={(event) => { this.handleManualEnabled(event, line_idx, material_idx, i); }} />
                        </label>
                    )}
                    {(processes[i].process_uuid === null) && "MISSING"}
                </td>
                <td>{processes[i].process_num}</td>
                <td>{processes[i].process_title}</td>
                <td>{Math.round(processes[i].share_quantity * 100)}%</td>
            </tr>);
        }
        return result;
    }

    renderMaterials(line_idx: number, materials: t.ILineGroupMatrixMaterial[]) {
        materials.sort((m1, m2) => m1.material_title.localeCompare(m2.material_title));
        const result = [];
        for (let i = 0; i < materials.length; i++) {
            result.push(<tr key={`m${i}`}>
                <td colSpan={2} className="pad0">{materials[i].material_external_id}</td>
                <td colSpan={3} className="pad0">{materials[i].material_title}</td>
            </tr>);
            result.push(...this.renderProcesses(line_idx, i, materials[i].processes));
        }
        return result;
    }

    renderLines() {
        return this.state.lines.map((line, i) => {
            return [
                <div key={i}>
                    <b>{line.line_title}</b><br />

                    <table className="table process-table">
                        <tbody>
                            {this.renderMaterials(i, line.materials)}
                        </tbody>
                    </table>
                </div>
            ];
        });
    }

    render() {
        return (
            <div key="ds_new">
                <section key="1">
                    <img src="/img/banner.png" alt="banner" className="img-fluid-header banner_img" />
                </section>
                <section id="profile_edit" className="new_line" key="2">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="white_box">
                                    <div className="tab-content tab-content-wide">
                                        <h5>{this.state.linegroup_title}</h5>

                                        {this.state.lines && this.renderLines()}

                                        <ErrorComponent msg={this.state.error} type="error" />
                                        <ErrorComponent msg={this.state.warning} type="warning" />

                                        <div className="button-list order-last ml-auto">
                                            {(this.state.lines.length > 0) &&
                                                <button className="btn btn-outline-primary" onClick={this.handleSubmit}>
                                                    <FormattedMessage id="common.save" defaultMessage="Save" />
                                                </button>}
                                            <button className="btn btn-outline-secondary" onClick={this.handleSelectMax}>
                                                <FormattedMessage id="Manufacturing.LineGroups.matrix.select_max" defaultMessage="Select most common" />
                                            </button>
                                            <button className="btn btn-outline-secondary" onClick={this.handleSelectAll}>
                                                <FormattedMessage id="Manufacturing.LineGroups.matrix.select_all" defaultMessage="Select all" />
                                            </button>
                                            <button className="btn btn-outline-secondary" onClick={this.handleCancel}>
                                                <FormattedMessage id="common.back" defaultMessage="Back" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="space30"></div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default LineGroupMatrix;