// @flow
import React from "react";
import { LeftMenuOrdersDividerEventHandler } from "../reducers/BusinessLogic";
import { enableUserSelect, disableUserSelect } from "../reducers/BusinessLogic";

type Props = {};
type State = {
    is_divider_selected: boolean
};

export default class LeftMenuOrdersDivider extends React.Component<Props, State> {

    mouse_move_event = null;
    mouse_up_event = null;

    state = {
        is_divider_selected: false
    }

    componentDidMount() {
        this.mouse_move_event = window.addEventListener("mousemove", (e) => this.onMouseMove(e));
        this.mouse_up_event = window.addEventListener("mouseup", (e) => this.onMouseUp(e));
    }

    componentWillUnmount() {
        window.removeEventListener("mousemove", this.mouse_move_event);
        window.removeEventListener("mousemove", this.mouse_up_event);
    }

    onMouseUp = (e: Event) => {
        if (this.state.is_divider_selected) {
            this.setState({
                is_divider_selected: false
            });
            enableUserSelect();
        }
    }

    onMouseMove = (e: Event) => {
        if (this.state.is_divider_selected) {
            // $FlowFixMe
            LeftMenuOrdersDividerEventHandler.onResize(e.y);
        }
    }

    onMouseDown = (e: Event) => {
        disableUserSelect();
        this.setState({ is_divider_selected: true });
    }

    render() {
        return <div className="left-menu-orders-divider" onMouseDown={this.onMouseDown}><i /></div>
    }
}