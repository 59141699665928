// @flow
// $FlowFixMe
import React, { useState, useEffect } from "react";
import { getBackend as getBackend2 } from "../lib/backend/Backend2";
import { translate } from "./IntlProviderWrapper";
import { niceDateTime, createDurationString } from "../lib/Util";

type InitialState = {
    data: Array<Object>
}

const initialState: InitialState = {
    data: []
}

const fetchData = async () => {
    return getBackend2().common.searchKeyValues({ prefix: "module-status" });
}

const KeyStatus = () => {
    const [state, setState] = useState(initialState);

    useEffect(() => {
        fetchData().then(res => {
            setState({ data: res.hits || [] });
        });
    }, []);

    return (
        <React.Fragment>
            <h3 className="table_header" key="xyz125">
                {translate("common.module_status", "Module status")}
            </h3>
            <div className="key-status table_content width-full">
                <table id="datatable-responsive" className="table table-timeline dt-responsive dataTable no-footer dtr-inline" cellSpacing="0" width="100%">
                    <thead>
                        <tr>
                            <th className="text-center">
                                {translate("common.module", "Module")}
                            </th>
                            <th className="text-center">
                                {translate("common.status", "Status")}
                            </th>
                            <th className="text-center">
                                {translate("common.time", "Time")}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            state.data.length > 0 ?
                            state.data.map((el,i) => {
                                const {module, status, ts_epoch} = el.data;

                                return (<tr key={i}>
                                    <td>
                                        <p className="data_source_text text-center">
                                            <span>{module}</span>
                                        </p>
                                    </td>
                                    <td>
                                        <p className="data_source_text text-center">
                                            <span>{status}</span>
                                        </p>
                                    </td>
                                    <td>
                                        <p className="data_source_text text-center">
                                            <span>{niceDateTime(new Date(ts_epoch))} ({ createDurationString(Date.now() - ts_epoch) } ago)</span>
                                        </p>
                                    </td>
                                </tr>)
                            }) :
                            <td className={"text-center"} colSpan={4}>No data available</td>
                        }
                    </tbody>
                </table>
        </div>
        </React.Fragment>
    )
}

export default KeyStatus;
