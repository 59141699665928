// @flow

// React
import React, { Component } from "react";
import ChevronListItem from "../react/ChevronListItem";

// Models
import type { IBatchOperationLocation, IBatchOperationGroup } from "../../lib/backend/batch_operations.generated.types";

type Props = {
    filter: string,
    groupFilter: string,
    batchop_groups: IBatchOperationGroup[],
    selected: IBatchOperationLocation | null,
    locations: IBatchOperationLocation[],
    handleLocationClick: any
}

/**
 * Batcho operations locations list component.
 */
class BatchopLocationsList extends Component<Props> {
    /**
     * Handle `click` events for the line item.
     */
    handleLocationClick(location: IBatchOperationLocation, i: number) {
        // Ignore clicks for currently selected location.
        if (location === this.props.selected) {
            return;
        }

        // Handle parent's click events.
        this.props.handleLocationClick(location);
    }

    /**
     * Location list filter.
     *
     * @param {string} needle Search term.
     * @param {IBatchOperationLocation} location Line object to match search term against.
     *
     * @return {boolean} `true` if line matches given search term, `false` otherwise.
     */
    includes(needle: string, group: any) {
        needle = needle.toLowerCase();
        if (needle.length === 0) return true;
        if (group.name.toLowerCase().indexOf(needle) > -1) return true;

        // search tag keys and values
        let tagsSearch = false;
        if (group.tags !== null) {
            Object.keys(group.tags).forEach((el, i) => {
                // comparing values
                if (String(group.tags[el]).toLowerCase().indexOf(needle) > -1) {
                    tagsSearch = true;
                }
                // comparing keys
                if (el.toLowerCase().indexOf(needle) > -1) {
                    tagsSearch = true;
                }
            });
        };
        if (tagsSearch === true) return true;
        return false;
    }

    /**
     * Render component.
     */
    render() {
        const locationsList = this.props.locations.map((location, i) => {
            let is_active = this.props.selected === location;

            if (this.props.groupFilter && location.group_uuid !== this.props.groupFilter) return null;
            if (!this.includes(this.props.filter, location)) return null;
            const group = this.props.batchop_groups.filter(x => x.uuid === location.group_uuid);
            const group_name = (group.length > 0 ? group[0].name: "-");
            return <ChevronListItem
                text={<div>[{group_name}] {location.name}</div>}
                onClick={() => this.handleLocationClick(location, i)}
                is_active={is_active}
            />
        })

        return (
            <ul className="nav nav-tabs">
                <div className="nav-tabs-parent" ref="nav">
                    {locationsList}
                </div>
            </ul>
        )
    }
}

export default BatchopLocationsList;
