// @flow
import React from "react";
import { Modal } from "react-bootstrap";
import type { Cell, Row } from "react-table";

import { translate } from "../../IntlProviderWrapper";
import { getBackend } from "../../../lib/backend/Backend2";
import { niceDateTime, createPlainTextAndDownload, createCsvAndDownload } from "../../../lib/Util";
import ErrorComponent from "../../ErrorComponent";
import Loader from "../../Loader";
import { type ReactTableColumn } from "../../react/ReactTable";

import * as ct from "../../../lib/backend/common.generated.types";
import * as mt from "../../../lib/backend/manufacturing2.generated.types";
import Tooltip from "../../Tooltip";
import VirtualTable from "../../react/VirtualTable";

const TABS = {
    messages: "messages",
    summary: "summary"
};

type SummaryRow = {
    order_id: string,
    material_id: string,
    material_title: string,
    errors: string[],
    warnings: string[]
};

type Props = {
    show: boolean,
    onHide: () => void
};

type State = {
    error: string,
    filter: string,
    items: mt.IPlanTableExportJob[],
    orders: mt.IOrderModelBase[],
    is_loading: boolean,
    selected_key: string,
    selected_item: ct.IGetJobInfoRes | null,
    selected_tab: $Keys<typeof TABS>
};

function sortIssues(row_a: Row, row_b: Row, column_id: string) {
    const a = row_a.values[column_id].length;
    const b = row_b.values[column_id].length;
    return a === b ? 0 : a > b ? 1 : -1;
}

class LogsModal extends React.Component<Props, State> {
    table_ref: { current: HTMLDivElement | null };

    constructor(props: Props) {
        super(props);
        const state: State = {
            error: "",
            filter: "",
            items: [],
            orders: [],
            is_loading: true,
            selected_key: "",
            selected_item: null,
            selected_tab: TABS.summary
        };
        this.state = state;
        this.table_ref = React.createRef();
    }

    async componentDidMount() {
        try {
            const { jobs: items } = await getBackend().manufacturing.getPlanTableExportJobs({});
            this.setState({ items });

            if (items.length > 0) {
                this.loadItem(items[0].uuid);
            }
        } catch (err) {
            this.setState({ error: `${err}` });
        }

        this.setState({ is_loading: false });
    }

    loadItem = async (key: string) => {
        try {
            const selected_item = await getBackend().common.getJobInfo({ id: key });
            const { operations } = await getBackend().manufacturing.getOrdersSimple({
                uuids: selected_item.input.filter_order_uuids,
                line_uuids: selected_item.input.line_uuids
            });
            this.setState({
                orders: operations,
                selected_key: key,
                selected_item
            });
        } catch (err) {
            this.setState({ error: `${err}` });
        }
    };

    getMessages = (): string[] | null => {
        if (
            !this.state.selected_item ||
            !this.state.selected_item.result ||
            !this.state.selected_item.result.output ||
            !this.state.selected_item.result.output.Messages
        ) {
            return null;
        }

        return this.state.selected_item.result.output.Messages;
    };

    getMessagesSize = (): number => {
        const messages = this.getMessages();
        if (messages === null) {
            return 0;
        }

        return messages.reduce((size, message) => size + message.length, 0);
    };

    getOrderForMessage = (line: string): mt.IOrderModelBase | null => {
        for (const order of this.state.orders) {
            if (line.indexOf(order.external_id) >= 0) {
                return order;
            }
        }

        return null;
    };

    getSummaryColumns = (): ReactTableColumn<SummaryRow>[] => {
        return [
            {
                Header: translate("common.order", "Order"),
                accessor: "order_id",
                Cell: this.renderText,
                disableFilters: true,
                width: "20%",
                minWidth: 150
            },
            {
                Header: translate("common.material", "Material"),
                accessor: "material_id",
                Cell: this.renderText,
                disableFilters: true,
                width: "20%",
                minWidth: 150
            },
            {
                Header: translate("OrderTableProduction.material_title", "Material title"),
                accessor: "material_title",
                Cell: this.renderText,
                disableFilters: true,
                width: "40%",
                minWidth: 300
            },
            {
                Header: translate("common.errors", "Errors"),
                accessor: "errors",
                Cell: this.renderIssues,
                disableFilters: true,
                enableSorting: true,
                sortType: sortIssues,
                width: "10%",
                minWidth: 100
            },
            {
                Header: translate("common.warnings", "Warnings"),
                accessor: "warnings",
                Cell: this.renderIssues,
                disableFilters: true,
                enableSorting: true,
                sortType: sortIssues,
                width: "10%",
                minWidth: 100
            }
        ];
    };

    getSummaryRows = (): SummaryRow[] => {
        const summary_map: Map<string, { errors: Set<string>, warnings: Set<string> }> = new Map();
        this.state.orders.forEach(order => {
            summary_map.set(order.external_id, {
                errors: new Set(),
                warnings: new Set()
            });
        });

        const messages = this.getMessages() || [];
        messages.forEach(message => {
            const is_error = /^[E ]/.test(message) && !/^(E CY:327|E C\/:008)/.test(message);
            const is_warning = /^W/.test(message);

            if (is_error || is_warning) {
                const order = this.getOrderForMessage(message);
                if (order === null) {
                    return;
                }

                const order_summary = summary_map.get(order.external_id);
                if (order_summary === undefined) {
                    return;
                }

                if (is_error) {
                    order_summary.errors.add(message);
                    return;
                }

                if (is_warning) {
                    order_summary.warnings.add(message);
                }
            }
        });

        const rows = this.state.orders.map(order => {
            const order_summary = summary_map.get(order.external_id);

            return {
                order_id: order.external_id,
                material_id: order.material_external_id,
                material_title: order.material_title,
                errors: order_summary ? [...order_summary.errors] : [],
                warnings: order_summary ? [...order_summary.warnings] : []
            };
        });

        return rows;
    };

    resetTableScroll = (left: boolean = true, top: boolean = true) => {
        if (this.table_ref.current) {
            if (left) {
                this.table_ref.current.scrollLeft = 0;
            }

            if (top) {
                this.table_ref.current.scrollTop = 0;
            }
        }
    };

    handleFilterChange = (event: SyntheticEvent<HTMLInputElement>) => {
        this.setState({
            filter: event.currentTarget.value
        });
    };

    handleItemSelect = async (key: string) => {
        if (key === this.state.selected_key) {
            return;
        }

        this.resetTableScroll(false, true);
        await this.loadItem(key);
    };

    handleDownload = () => {
        if (this.state.selected_tab === TABS.summary) {
            const columns = this.getSummaryColumns();
            const rows = this.getSummaryRows();
            const data: string[][] = [columns.map(column => (typeof column.Header === "string" ? column.Header : ""))];
            rows.forEach(row => {
                const row_data = [];
                columns.forEach(column => {
                    const row_value = row[column.accessor];
                    const value = Array.isArray(row_value) ? row_value.length : row_value;
                    row_data.push(`${value}`);
                });
                data.push(row_data);
            });
            createCsvAndDownload(data, `log_${this.state.selected_tab}_${new Date().getTime()}.csv`);
        } else {
            const messages = this.getMessages();
            if (messages !== null) {
                const content = messages.join("");
                createPlainTextAndDownload(content, `log_${this.state.selected_tab}_${new Date().getTime()}.txt`);
            }
        }
    };

    renderStatus = () => {
        const status = [];
        if (this.state.error !== "") {
            status.push(
                <ErrorComponent
                    key="error"
                    className="my-0 border-left-0 border-right-0 rounded-0"
                    type="error"
                    msg={this.state.error}
                />
            );
        }

        if (this.state.items.length === 0) {
            status.push(
                <ErrorComponent
                    key="empty"
                    className="my-0 border-left-0 border-right-0 rounded-0"
                    type="no-data"
                    msg={translate("common.no_data", "No data available")}
                />
            );
        }

        return status.length > 0 && <Modal.Header className="d-block p-0">{status}</Modal.Header>;
    };

    renderItems = () => {
        return (
            <React.Fragment>
                <Modal.Header className="py-2 border border-top-0 border-left-0 rounded-0 bg-white">
                    <input
                        className="form-control search_bar form-control-sm w-100"
                        type="text"
                        name="search"
                        placeholder={translate("common.search", "Search")}
                        value={this.state.filter}
                        disabled={this.state.items.length === 0}
                        onChange={this.handleFilterChange}
                    />
                </Modal.Header>
                <div className="list-group list-group-actions h-100 overflow-auto">
                    {this.state.items.map((item, i) => {
                        const is_active = item.uuid === this.state.selected_key;

                        if (
                            this.state.filter !== "" &&
                            item.username.toLowerCase().indexOf(this.state.filter.toLowerCase()) < 0
                        ) {
                            return null;
                        }

                        return (
                            <button
                                key={i}
                                className={`list-group-item rounded-0 list-group-item-action${
                                    is_active ? " active" : ""
                                }`}
                                aria-current={is_active ? "true" : "false"}
                                onClick={() => {
                                    this.handleItemSelect(item.uuid);
                                }}
                            >
                                <span className="d-block text-truncate w-100">{item.username}</span>
                                <span className="d-block text-truncate w-100">
                                    {translate("OrderTableProduction.time_end", "End time")}:{" "}
                                    {item.ended_at > 0 ? niceDateTime(new Date(item.ended_at)) : "/"}
                                </span>
                            </button>
                        );
                    })}
                </div>
            </React.Fragment>
        );
    };

    renderItemContent = () => {
        const messages = this.getMessages();
        if (messages === null) {
            return (
                <ErrorComponent
                    className="mx-3 my-3"
                    type="no-data"
                    msg={translate("common.no_data", "No data available")}
                />
            );
        }

        const tabs = [
            {
                name: TABS.summary,
                title: translate("common.summary", "Summary"),
                content: (
                    <div className="w-100 h-100">
                        <VirtualTable
                            ref={ref => {
                                this.table_ref.current = ref;
                            }}
                            class_name="logs-summary-table w-100 h-100 mb-0"
                            columns={this.getSummaryColumns()}
                            data={this.getSummaryRows()}
                            head_height={43}
                            fix_head
                        />
                    </div>
                )
            },
            {
                name: TABS.messages,
                title: translate("common.messages", "Messages"),
                content:
                    this.getMessagesSize() > 1000000 ? (
                        <ErrorComponent
                            className="m-2"
                            type="warning"
                            msg={translate(
                                "common.content_too_large_to_display",
                                "Content is is too large to be displayed"
                            )}
                        />
                    ) : (
                        <pre className="p-2 mb-0 w-100 h-100">{messages.join("")}</pre>
                    )
            }
        ];

        return (
            <React.Fragment>
                <ul className="nav nav-tabs pt-2 pr-2 pl-2" role="tablist">
                    {tabs.map(tab => (
                        <li key={tab.name} className="nav-item" role="presentation">
                            <button
                                className={`nav-link ${this.state.selected_tab === tab.name ? "active" : ""}`}
                                id={`${tab.name}-tab`}
                                role="tab"
                                aria-controls={`${tab.name}-panel`}
                                aria-selected={this.state.selected_tab === tab.name ? "true" : "false"}
                                onClick={() => {
                                    this.setState({ selected_tab: tab.name });
                                }}
                            >
                                {tab.title}
                            </button>
                        </li>
                    ))}
                </ul>
                <div className="tab-content d-flex align-items-stretch h-100 overflow-hidden">
                    {tabs.map(tab => (
                        <div
                            key={tab.name}
                            className={`tab-pane w-100 ${this.state.selected_tab === tab.name ? "active" : ""}`}
                            id={`${tab.name}-panel`}
                            role="tabpanel"
                            aria-labelledby={`${tab.name}-tab`}
                        >
                            {tab.content}
                        </div>
                    ))}
                </div>
                <Modal.Footer className="py-2 bg-white">
                    <button className="btn btn-primary ml-auto mr-0" onClick={this.handleDownload}>
                        {this.state.selected_tab === TABS.summary
                            ? translate("common.export_csv", "Export CSV")
                            : translate("common.export", "Export")}
                    </button>
                </Modal.Footer>
            </React.Fragment>
        );
    };

    renderText = ({ value }: Cell) => (
        <span className="text-truncate" title={value}>
            {value}
        </span>
    );

    renderIssues = ({ value }: Cell) => (
        <Tooltip content={value.length > 0 ? <pre className="mb-0">{value.join("")}</pre> : null}>
            <div>{value.length}</div>
        </Tooltip>
    );

    render() {
        return (
            <Modal dialogClassName="logs-modal modal-dialog-scrollable modal-max h-100" show={this.props.show}>
                <Modal.Header>
                    <Modal.Title>
                        <span>{translate("common.logs", "Logs")}</span>
                    </Modal.Title>
                    <button type="button" className="close" onClick={this.props.onHide}>
                        <span aria-hidden="true">×</span>
                        <span className="sr-only">{translate("common.close", "Close")}</span>
                    </button>
                </Modal.Header>
                {this.renderStatus()}
                <Modal.Body className="p-0 overflow-hidden">
                    {this.state.is_loading ? (
                        <Loader />
                    ) : this.state.items.length > 0 ? (
                        <div className="row align-items-stretch h-100 m-0">
                            <div className="d-flex flex-column col-sm-6 col-md-5 col-lg-4 col-xl-3 h-100 p-0">
                                {this.renderItems()}
                            </div>
                            <div className="d-flex flex-column col-sm-6 col-md-7 col-lg-8 col-xl-9 h-100 p-0">
                                {this.renderItemContent()}
                            </div>
                        </div>
                    ) : null}
                </Modal.Body>
            </Modal>
        );
    }
}

export default LogsModal;
