// @flow

// main imports
import React, { Component } from "react";
import ReactRouterPropTypes from "react-router-prop-types";
import { FormattedMessage } from "react-intl";

// models
import { LINE_TAGS, LINE_TYPES } from "../../lib/ManufacturingTags.generated";
import * as BusinessLogic from "../../lib/BusinessLogic";

// subcomponents
import ErrorComponent from "../ErrorComponent";
import Toggle from "react-toggle";
import { translate } from "../IntlProviderWrapper";

// backend
import { getBackend } from "../../lib/backend/Backend2";
import * as t from "../../lib/backend/manufacturing2.generated.types";
import { stringCompare } from "../../lib/Util";
import LineEditTags from "./LineEditTags";
import { onChangeUpdateTags } from "../react/ReactTableTags";
import Loader from "../Loader";

// type definitions
type ITagDisplay = {
    name: string,
    id: string
}

type Props = {
    match: ReactRouterPropTypes.match,
    history: ReactRouterPropTypes.history,
    location: ReactRouterPropTypes.location
}

export type State = {
    bottleneck: boolean,
    external_id: string,
    uuid: string,
    title: string,
    plant_uuid: string,
    skip_planning: boolean,
    skip_shift_board: boolean,
    skip_stock_analysis: boolean,
    tags: any,
    type: string,
    plants: t.IPlantData[],
    weight: number,
    message: string,
    componentTitle: string,
    error: string,
    warning: string,
    loading: boolean
}

/**
 * Form to create new line.
 */
export class LineCreate extends Component<Props, State> {

    /**
     * Constructor.
     *
     * @param {Props} props Props object.
     * @param {State} state State object.
     */
    constructor(props: Props) {
        super(props);
        const state: State = {
            bottleneck: false,
            external_id: "",
            uuid: "",
            title: "",
            plant_uuid: "",
            skip_planning: false,
            skip_shift_board: false,
            skip_stock_analysis: false,
            tags: {},
            type: LINE_TYPES.ordinary,
            plants: [],
            weight: 10.0,
            message: "",
            componentTitle: translate("Manufacturing.Lines.add_new_line", "Add New Line"),
            error: "",
            warning: "",
            loading: false
        };
        this.state = state;
    }

    /**
     * Loading component data (plants).
     */
    async componentDidMount() {
        try {
            const { plants } = await getBackend().manufacturing.getPlants({});
            this.setState({
                plants: plants
            });
        } catch (e) {
            this.setState({ error: e });
        }
    }

    /**
     * Basic form validation.
     */
    validateShallow() {
        return (this.state.title.length > 0 && this.state.external_id.length > 0 && this.state.plant_uuid.length > 0);
    }

    /**
     * Handling change of any input component.
     */
    handleInputChange = (event: Event) => {
        if (event.currentTarget instanceof HTMLInputElement || event.currentTarget instanceof HTMLSelectElement) {
            // read new values
            const target = event.currentTarget;
            let value = target.value;
            const name = target.name;

            const is_tag = (Object.keys(LINE_TAGS).includes(name) && name !== LINE_TAGS.type);
            // hack for toggle element
            if (is_tag) {
                //$FlowFixMe
                value = target.checked;
            }

            // handle line group and tags
            if (is_tag) {
                let tags = this.state.tags || {};
                tags[name] = value.toString();
                this.setState(prev_state => {
                    prev_state[name] = value;
                    prev_state.tags = tags;
                    return prev_state;
                });
            } else {
                this.setState(prev_state => {
                    prev_state[name] = value;
                    return prev_state;
                });
            }
        }
    };

    /**
     * Handling submission of the form. Basic checks and saves with error handling.
     */
    handleSubmit = async () => {
        this.setState({ loading: true });
        try {
            if (this.validateShallow()) {
                const tags = this.state.tags;
                tags[LINE_TAGS.type] = this.state.type;
                let back_line_uuid = this.state.uuid;
                if (this.state.uuid === "") {
                    const { uuid } = await getBackend().manufacturing.insertLine({
                        external_id: this.state.external_id,
                        plant_uuid: this.state.plant_uuid,
                        tags,
                        title: this.state.title,
                        weight: this.state.weight
                    });
                    back_line_uuid = uuid;
                } else {
                    // update current object
                    await getBackend().manufacturing.updateLine({
                        external_id: this.state.external_id,
                        line_uuid: this.state.uuid,
                        tags,
                        title: this.state.title,
                        weight: this.state.weight
                    });
                }
                await BusinessLogic.forceManufacturingCacheRefresh();
                this.props.history.push(`/digital-twin/resources/lines/${back_line_uuid}?plant_uuid=${this.state.plant_uuid}`);
            } else {
                this.setState({
                    warning: translate("Manufacturing.Lines.cannot_submit_missing_values", "Cannot submit a form with missing values.")
                });
                window.scrollTo(0, 0);
            }
        } catch (err) {
            this.setState({ error: err.message })
        }
        this.setState({ loading: false });
    }

    /**
     * Renders tag.
     * @param {ITagDisplay} tag
     */
    renderTagField(tag: ITagDisplay) {
        return <div className="form-group" key={tag.id}>
            <label>{tag.name}</label><br />
            <Toggle
                name={tag.id}
                checked={this.state[tag.id]}
                onChange={this.handleInputChange} />
        </div>;
    }

    /**
     * Renders tag.
     * @param {ITagDisplay} tag
     */
    renderTypeField() {
        const items = Object.keys(LINE_TYPES).map((type_i, i) => {
            return {
                key: i,
                value: type_i,
                title: translate("Manufacturing.Lines.Types." + type_i, type_i)
            }
        })
            .sort((a, b) => stringCompare(a.title, b.title));
        return <div className="form-group">
            <label>
                <FormattedMessage id="common.type" defaultMessage="Type" />:
            </label>
            <select name="type" className="form-control" value={this.state.type} onChange={this.handleInputChange}>
                {
                    items.map(item => {
                        return <option key={item.key} value={item.value}>{item.title}</option>
                    })
                }
            </select>
        </div>;
    }

    onChangeCell = (name: string) => (value: boolean) => {
        this.setState({ tags: onChangeUpdateTags(this.state.tags, name, value) });
    }

    /**
     * Rendering JSX for current component.
     */
    render() {
        return (
            <div key="ds_new">
                <section key="1">
                    <img src="/img/banner.png" alt="banner" className="img-fluid-header banner_img" />
                </section>
                <section id="profile_edit" className="line-create new_line" key="2">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="white_box">
                                    {
                                        this.state.loading ? <div className="tab-content">
                                            <FormattedMessage
                                                id="common.add_line_loading"
                                                defaultMessage="Adding new line to the knowledge graph. Initialisation may take several minutes..."
                                            />
                                            <Loader small={true} />
                                        </div> :
                                        <div className="tab-content">
                                            <h4>{this.state.componentTitle}</h4>
                                            <ErrorComponent msg={this.state.error} type="error" />
                                            <ErrorComponent msg={this.state.warning} type="warning" />
                                            <div className="form-group">
                                                <label>
                                                    <FormattedMessage id="common.title" defaultMessage="Title" />:
                                                </label>
                                                <input name="title" type="text" disabled="" className="form-control"
                                                    value={this.state.title}
                                                    onChange={this.handleInputChange} />
                                            </div>
                                            <div className="form-group">
                                                <label>
                                                    <FormattedMessage id="common.external_id" defaultMessage="External ID" />:
                                                </label>
                                                <input name="external_id" type="text" disabled="" className="form-control"
                                                    value={this.state.external_id}
                                                    onChange={this.handleInputChange} />
                                            </div>
                                            {this.state.uuid === "" &&
                                            <div className="form-group">
                                                <label>
                                                    <FormattedMessage id="common.plant" defaultMessage="Plant" />:
                                                </label>
                                                <select name="plant_uuid" className="form-control" value={this.state.plant_uuid} onChange={this.handleInputChange}>
                                                    <option value="">{translate("common.select", "Select")}...</option>
                                                    {
                                                        this.state.plants.map((plant, i) => {
                                                            return <option key={i} value={plant.uuid}>{plant.title}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>}
                                            <div className="form-group">
                                                <label>
                                                    <FormattedMessage id="common.weight" defaultMessage="Weight" />:
                                                </label>
                                                <input name="weight" type="text" disabled="" className="form-control"
                                                    value={this.state.weight}
                                                    onChange={this.handleInputChange} />
                                            </div>
                                            {this.renderTypeField()}

                                            <LineEditTags
                                                tags={this.state.tags}
                                                onChangeCell={this.onChangeCell}
                                            />

                                            <div className="button-list order-last ml-auto">
                                                <button className="btn btn-outline-primary" onClick={this.handleSubmit}>
                                                    <FormattedMessage id="common.save" defaultMessage="Save" />
                                                </button>
                                                <button className="btn btn-outline-secondary" onClick={() => this.props.history.goBack()}>
                                                    <FormattedMessage id="common.back" defaultMessage="Back" />
                                                </button>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="space30"></div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}
