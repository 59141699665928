// @flow
import React from "react";
import Select from "react-select";
import Toggle from "react-toggle";

import * as tt from "../../lib/backend/ticketing.generated.types";

type Props = {
    field: tt.IUserStepField,
    type?: string,
    value: string,
    onChange: (field_name: string, value: string, alsoSave?: boolean) => void,
    onSave: () => Promise<void>,
    is_kiosk: boolean
}
type State = {};

export default class UserStepField extends React.Component<Props, State> {

    onClear(field_name: string): void {
        this.props.onChange(field_name, "");
    }

    renderCheckbox() {
        const field = this.props.field;

        return (
            <div style={{ paddingTop: "10px" }}>
                <div>
                    <label forhtml={field.name}>
                        {field.title} : &nbsp;
                    </label>
                </div>
                <div className="ticket-field" >
                    <div>

                        <Toggle
                            name={field.name}
                            onChange={(e) => this.props.onChange(field.name, e.target.checked)}
                            className="vertical-align-middle"
                            checked={this.props.value}
                            id={field.name}
                        />

                    </div>
                </div>
            </div>
        );
    }

    handleDropdownChange(field: tt.IUserStepField, e: any): void {
        if (e && Object.keys(e).indexOf("value") >= 0 && e.value !== undefined) {
            this.props.onChange(field.name, String(e.value));
        }
    }

    renderDropdown() {
        const field = this.props.field;

        const options = [];
        if (!field || !field.items || field.items.length === 0) {
            return null;
        }
        for (let i = 0; i < field.items.length; i++) {
            const option = field.items[i];
            options.push({
                label: option.text,
                value: option.value
            });
        }

        return (
            <div style={{ paddingTop: "10px" }}>
                <div>
                    <label forhtml={field.name}>
                        {field.title}
                    </label>:
                </div>
                <Select
                    id={field.name}
                    options={options}
                    onChange={(e) => this.handleDropdownChange(field, e)}
                    placeholder={field.title}
                    onClear={() => this.onClear(field.name)}
                    isClearable
                />
            </div>
        );
    }

    renderText() {
        const field = this.props.field;
        return (
            <div style={{ paddingTop: "10px" }}>
                <div>
                    <label forhtml={field.name}>
                        {field.title} : &nbsp;
                    </label>
                </div>
                <div className="ticket-field" >
                    <input
                        style={{ width: "100%" }}
                        type="text"
                        className="form-control"
                        name={field.name}
                        value={this.props.value}
                        onChange={(e) => this.props.onChange(field.name, e.target.value)}
                        onClear={() => this.onClear(field.name)}
                    />
                    &nbsp;
                    <i className="fas fa-times-circle" onClick={() => this.onClear(field.name)}></i>
                </div>
            </div>
        );
    }

    renderNumber() {
        const field = this.props.field;
        return (
            <div style={{ paddingTop: "10px" }}>
                <div>
                    <label forhtml={field.name}>
                        {field.title} : &nbsp;
                    </label>
                </div>
                <div className="ticket-field" >
                    <input
                        style={{ width: "100%" }}
                        type="number"
                        className="form-control"
                        name={field.name}
                        value={this.props.value}
                        onChange={(e) => this.props.onChange(field.name, e.target.value)}
                        onClear={() => this.onClear(field.name)}
                    />
                    &nbsp;
                    <i className="fas fa-times-circle" onClick={() => this.onClear(field.name)}></i>
                </div>
            </div>
        );
    }

    changeAndSave(value: string, event: Event) {
        this.props.onChange("approval_status", value, true);
    }

    renderButtons() {
        const field = this.props.field;
        const buttons = [];

        if (!field || !field.items || field.items.length === 0) {
            return null;
        }

        for (let i = 0; i < field.items.length; i++) {
            const button = field.items[i];

            const button_style = this.props.is_kiosk ? 
                "btn btn-primary user-step-field-button-kiosk" : "btn btn-primary user-step-field-button";



            buttons.push(
                <button
                    key={i}
                    className={button_style}
                    style={{ backgroundColor: button.bgcolor }}
                    onClick={(e) => this.changeAndSave(button.value, e)}
                    value={button.value}
                >
                    {button.text}
                </button>
            );
        }

        const button_position = this.props.is_kiosk ?
            { paddingTop: "10px", display: "flex", flexDirection: "column", justifyContent: "flex-end" } :
            { paddingTop: "10px", display: "flex", justifyContent: "flex-end" };

        return (
            <div className="ticket-field"
                style={button_position} >
                {buttons}
            </div>
        );
    }

    render() {
        const { type } = this.props;

        switch (type) {
            case "checkbox":
                return this.renderCheckbox();
            case "dropdown":
                return this.renderDropdown();
            case "text":
                return this.renderText();
            case "number":
                return this.renderNumber();
            case "buttons":
                return this.renderButtons();
            default:
                console.error("Invalid field type for user step field", type);
                return null;
        }

    }
}
