// @flow
import React from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { RESET_FILTERS } from "../../reducers/reducersFilters/filters";
import { SET_RESCHEDULE_LINE_UUID } from "../../reducers/common";
import { translate } from "../../../../IntlProviderWrapper";

import type { SetRescheduleLineAction } from "../../reducers/common";
import type { ResetFiltersAction } from "../../reducers/reducersFilters/filters";
import type { LineOrders } from "../../reducers/linesOrders";
import type { SelectOptionItem } from "./TypeFilterConsts";
import type { ReduxState } from "../../reducers/reducers";

type Props = {
    reduxDispatch: (args: SetRescheduleLineAction | ResetFiltersAction) => void,
    lines_orders: LineOrders[],
    reschedule_line_uuid: string,
    original_report_created_at: number
};
type State = {};

class RescheduleLineFilter extends React.Component<Props, State> {

    onChange = async (option: SelectOptionItem) => {
        if (!option) {
            this.props.reduxDispatch({ type: RESET_FILTERS, data: undefined });
        } else {
            this.props.reduxDispatch({ type: SET_RESCHEDULE_LINE_UUID, data: option.value });
        }
    }

    getSelectedOptions = (): SelectOptionItem | null => {
        if (!this.props.reschedule_line_uuid) {
            return null;
        }
        for (const line_order of this.props.lines_orders) {
            if (line_order.line_uuid === this.props.reschedule_line_uuid) {
                return {
                    value: line_order.line_uuid,
                    label: line_order.line_title
                }
            }
        }
        return null;
    }

    getOptions = (): SelectOptionItem[] => {
        const options = []
        for (const line_order of this.props.lines_orders) {
            options.push({
                value: line_order.line_uuid,
                label: line_order.line_title
            });
        }
        return options;
    }

    render() {
        return (
            <div className="order-filter">
                <Select
                    value={this.getSelectedOptions()}
                    placeholder={translate("common.reschedule_line", "Reschedule line")}
                    options={this.getOptions()}
                    onChange={this.onChange}
                    isSearchable={true}
                    isClearable
                />
            </div>
        );
    }
}

export default connect(
    (state: ReduxState) => {
        const filters = state.gantt_chart_filters;
        const lines_orders = state.gantt_chart_lines_orders.lines_orders;
        const report = state.gantt_chart_report;
        return {
            reschedule_line_uuid: filters.reschedule_line_uuid,
            lines_orders,
            original_report_created_at: report.original_report_created_at
        }
    },
    (dispatch) => ({reduxDispatch: dispatch})
)(RescheduleLineFilter);