// @flow
import React from "react";
import { translate } from "../../../IntlProviderWrapper";
import StockRequirementsList from "../StockRequirementsList"

import type { ReportState } from "../reducers/report";
import type { LineOrders } from "../reducers/linesOrders";

export const StockRequirementsTabHeader = () => {
    const title = translate("Manufacturing.StocksView.stock_requirements", "Stock requirements");
    return (
        <span title={title}>
            <img src="/img/box-open-full-solid.svg"/>
            <span>{title}</span>
        </span>
    );
}

type Props = {
    report: ReportState,
    show_planning_table_filter: boolean,
    line_group_uuid: string,
    lines_orders: LineOrders[]
};
type State = {};

export default class StockRequirementsTabContent extends React.Component<Props, State> {

    render() {
        return <StockRequirementsList
            report={this.props.report.report_data}
            current_report_uuid={
                this.props.report && this.props.report.report_data && this.props.report.report_data.uuid
            }
            show_planning_table_filter={this.props.show_planning_table_filter}
            line_group_uuid={this.props.line_group_uuid}
            original_report_uuid={this.props.report.original_report_uuid}
            lines_orders={this.props.lines_orders}
        />
    }
}