// @flow

import React from "react";

// $FlowFixMe
import { ReactComponent as ChevronCircleDownIcon } from "@fortawesome/fontawesome-free/svgs/solid/chevron-circle-down.svg";
import SvgIcon, { type SvgIconProps } from "../SvgIcon";

const ChevronCircleDown = (props: SvgIconProps) => (
    <SvgIcon {...props} size={props.size || "fs"}>
        <ChevronCircleDownIcon />
    </SvgIcon>
);

export default ChevronCircleDown;
