// @flow

// main imports
import React, { Component } from "react";
import ReactRouterPropTypes from "react-router-prop-types";
import { FormattedMessage } from "react-intl";

// models
import { BATCHOP_LOCATION_TAGS } from "../../lib/ManufacturingTags.generated";
import * as BusinessLogic from "../../lib/BusinessLogic";
import type { IBatchOperationGroup } from "../../lib/backend/batch_operations.generated.types";

// subcomponents
import ErrorComponent from "../ErrorComponent";
import Toggle from "react-toggle";
import { translate } from "../IntlProviderWrapper";

// backend
import { getBackend } from "../../lib/backend/Backend2";

import { Columns, getInitialState, mapData, onChangeUpdateTags, rowStyle, updateTagsConfig } from "../react/ReactTableTags";
import { BATCHOP_LOCATION_TAGS as MAN_BATCHOP_LOCATION_TAGS } from "../../lib/ManufacturingTagsEditor.generated";

// type definitions
import type { ITags } from "../../lib/Models";
import ReactTable from "../react/ReactTable";

import { getLinkForUserDocumentation, UserDocumentationLinksEnum } from "../../lib/Util";

import { getLang } from "../IntlProviderWrapper";

type ITagDisplay = {
    name: string,
    id: string
}

type Props = {
    match: ReactRouterPropTypes.match,
    history: ReactRouterPropTypes.history,
    location: ReactRouterPropTypes.location
}

export type State = {
    uuid: string,
    title: string,
    group_uuid: string,
    tags: ITags,
    groups: IBatchOperationGroup[],
    size: number,
    message: string,
    componentTitle: string,
    error: string,
    warning: string
}

/**
 * Form to create new line.
 */
export class BatchopLocationCreate extends Component<Props, State> {

    /**
     * Constructor.
     *
     * @param {Props} props Props object.
     * @param {State} state State object.
     */
    constructor(props: Props, state: State) {
        super(props);
        this.state = {
            uuid: "",
            title: "",
            group_uuid: "",
            size: 0,
            tags: {},
            groups: [],
            message: "",
            componentTitle: translate("Manufacturing.BatchopLocations.add_new_location", "Add New Location"),
            error: "",
            warning: ""
        }
    }

    /**
     * Loading component data (plants).
     */
    async componentDidMount() {
        try {
            const groups = (await getBackend().batchOperations.getGroups({})).items;

            this.setState({
                groups,
                group_uuid: groups && groups[0] ? groups[0].uuid : ""
            });
        } catch (e) {
            this.setState({ error: e });
        }
    }

    /**
     * Basic form validation.
     */
    validateShallow() {
        return (this.state.title.length > 0);
    }

    /**
     * Handling change of any input component.
     */
    handleInputChange = (event: Event) => {
        if (event.currentTarget instanceof HTMLInputElement || event.currentTarget instanceof HTMLSelectElement) {
            // read new values
            const target = event.currentTarget;
            let value = target.value;
            const name = target.name;

            // hack for toggle element
            if (Object.keys(BATCHOP_LOCATION_TAGS).includes(name)) {
                //$FlowFixMe
                value = target.checked;
            }

            // update new datasource placeholder
            this.setState(prev_state => {
                prev_state[name] = value;
                return prev_state;
            });

            // handle line group and tags
            if (name === "line_group") {
                let tags = this.state.tags || {};
                tags.line_group = value.toString();
                this.setState({ tags: tags });
            } else if (Object.keys(BATCHOP_LOCATION_TAGS).includes(name)) {
                let tags = this.state.tags || {};
                tags[name] = value.toString();
                this.setState({ tags: tags });
            }
        }
    };

    /**
     * Handling submission of the form. Basic checks and saves with error handling.
     */
    handleSubmit = async () => {
        try {
            if (this.validateShallow()) {
                let uuid = this.props.match.params.uuid;

                if (this.state.uuid === "") {
                    const response = await getBackend().batchOperations.createLocation({
                        disabled: false,
                        group_uuid: this.state.group_uuid,
                        tags: this.state.tags,
                        title: this.state.title,
                        size: this.state.size
                    });
                    uuid = response.uuid;
                } else {
                    // update current object
                    await getBackend().batchOperations.updateLocation({
                        disabled: false,
                        uuid: this.state.uuid,
                        group_uuid: this.state.group_uuid,
                        tags: this.state.tags,
                        title: this.state.title,
                        size: this.state.size
                    });
                }
                await BusinessLogic.forceManufacturingCacheRefresh();
                this.props.history.push(`/digital-twin/resources/batchop_locations/${uuid}`);
            } else {
                this.setState({
                    warning: translate("Manufacturing.Lines.cannot_submit_missing_values", "Cannot submit a form with missing values.")
                });
            }
        } catch (err) {
            this.setState({ error: err.message })
        }
    }

    onGuiChangeCell = (name: string) => (value: boolean | Object | string) => {
        this.setState({ tags: onChangeUpdateTags(this.state.tags, name, value) });
    }

    renderReactTable() {
        return (
            <div>
                <a href={getLinkForUserDocumentation(UserDocumentationLinksEnum.batchop_locations, getLang())}
                    target="_blank"
                    style={{ position: 'relative', float: 'right', paddingTop: '30px' }}>
                    <img src={"/img/question_light.svg"} style={{ paddingRight: "3px", paddingBottom: "2px" }} />
                    <FormattedMessage id="Header.menu.user_documentation" defaultMessage="User documentation" />
                    <img src={"/img/arrow_up_right_corner.svg"} style={{ position: "relative", width: "15px", height: "15px", paddingLeft: "3px", paddingBottom: "4px", bottom: "3px" }} />
                </a>

                <ReactTable
                    className="tags-react-table"
                    data={mapData(this.state.tags, updateTagsConfig(MAN_BATCHOP_LOCATION_TAGS, {}))}
                    columns={Columns}
                    onChangeCell={this.onGuiChangeCell}
                    rowStyle={rowStyle}
                    initialState={getInitialState()}
                />
            </div>
        )
    }

    /**
     * Renders tag.
     * @param {ITagDisplay} tag
     */
    renderTagField(tag: ITagDisplay) {
        return <div className="form-group" key={tag.id}>
            <label>{tag.name}</label><br />
            <Toggle
                name={tag.id}
                checked={this.state[tag.id]}
                onChange={this.handleInputChange} />
        </div>
    }

    /**
     * Rendering JSX for current component.
     */
    render() {

        // tags
        const tags = [
            /* { name: translate("Manufacturing.Lines.Tags.aging", "Aging"), id: BATCHOP_LOCATION_TAGS.aging }, */
        ];

        return (
            <div key="ds_new">
                <section key="1">
                    <img src="/img/banner.png" alt="banner" className="img-fluid-header banner_img" />
                </section>
                <section className="new_line" key="2">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="white_box">
                                    <div className="tab-content">
                                        <h4>{this.state.componentTitle}</h4>
                                        <ErrorComponent msg={this.state.error} type="error" />
                                        <ErrorComponent msg={this.state.warning} type="warning" />
                                        <div className="form-group">
                                            <label>
                                                <FormattedMessage id="common.title" defaultMessage="Title" />:
                                            </label>
                                            <input name="title" type="text" disabled="" className="form-control"
                                                value={this.state.title}
                                                onChange={this.handleInputChange} />
                                        </div>
                                        <div className="form-group">
                                            <label>
                                                <FormattedMessage id="common.batchop_group" defaultMessage="Batch Operations Group" />:
                                            </label>
                                            <select name="group_uuid" className="form-control" value={this.state.group_uuid} onChange={this.handleInputChange}>
                                                {
                                                    this.state.groups.map((g, i) => {
                                                        return <option key={i} value={g.uuid}>{g.name}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label>
                                                <FormattedMessage id="common.size" defaultMessage="Size" />:
                                            </label>
                                            <input name="size" type="text" disabled="" className="form-control"
                                                value={this.state.size}
                                                onChange={this.handleInputChange} />
                                        </div>
                                        {this.renderReactTable()}
                                        {tags.map((tag) => this.renderTagField(tag))}
                                        <div className="button-list order-last ml-auto">
                                            <button className="btn btn-outline-primary" onClick={this.handleSubmit}>
                                                <FormattedMessage id="common.save" defaultMessage="Save" />
                                            </button>
                                            <button className="btn btn-outline-secondary" onClick={() => this.props.history.goBack()}>
                                                <FormattedMessage id="common.back" defaultMessage="Back" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="space30"></div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}
