// @flow

import * as t from "./backend/reports.generated.types";

export type ISimulationReportOrderEx = {
    // original fields
    batches: t.ISimulationReportOrderBatch[],
    bom_inputs: t.ISimulationReportOrderBomInputs[],
    earliest_end: string,
    earliest_start: string,
    estimated_completion: t.ISimulationReportOrderQuantileData[],
    estimated_duration: t.ISimulationReportOrderQuantileData[],
    estimated_start: t.ISimulationReportOrderQuantileData[],
    estimated_tool_setup_end: t.ISimulationReportOrderQuantileData[],
    initial_earliest_end: string,
    initial_earliest_start: string,
    material: string,
    material_title: string,
    measurement_unit: string,
    model_intercept: number,
    model_normative_fallback: boolean,
    model_slope: number,
    operation_external_id: string,
    order_external_id: string,
    order_id: string,
    order_status: string,
    order_type: string,
    person_per_shift: number,
    process: string,
    process_num: number,
    process_title: string,
    quantity_produced: number,
    quantity_produced_fixed: number,
    quantity_produced_next: number,
    quantity_total: number,
    sim_ignore_earliest_start: boolean,
    subline_index?: number,
    tool_setup: boolean,
    failed_scheduling: boolean,
    // extended fields
    material_color_counter: number,
    order_color_counter: number,
    line_uuid?: string,
    freeze_time_end: number,
    freeze_time_start: number,
    insight_freeze_order: boolean
};

export type ISimulationReportOrderLineEx = {
    line: string,
    line_title: string,
    plant: string,
    plant_title: string,
    is_disabled?: boolean,
    production: ISimulationReportOrderEx[]
};

export type ISimulationReportEx = {
    from_shift_time: t.ISimulationReportShiftTime,
    insights: t.ISimulationReportInsight[],
    line_shift_features: t.ISimulationReportOrderLineShiftFeature[],
    next_shift_time: t.ISimulationReportShiftTime,
    orders: ISimulationReportOrderLineEx[],
    stock: t.ISimulationReportStock[],
    unscheduled: t.ISimulationReportUnscheduledOrder[],
    version: number
};

export type IReportModelEx = {
    created_at: number,
    created_by: string,
    org_uuid: string,
    tags: t.ITags,
    title: string,
    uuid: string,
    input: any,
    result: ISimulationReportEx,
    translations: t.ITranslations
};
