// @flow

// main imports
import React, { Component } from "react";
import { connect } from "react-redux";
import ReactRouterPropTypes from "react-router-prop-types";
import ErrorComponent from "../ErrorComponent";

// backend
import { getBackend } from "../../lib/backend/Backend2";
import * as t from "../../lib/backend/manufacturing2.generated.types";
import {
    waitForManufacturingAsync, getPlantsForUser
} from "../../lib/BusinessLogic";

// import subcomponents
import StockLocationsList from "./StockLocationsList";
import StockLocationsDetails from "./StockLocationsDetails";
import { translate } from "../IntlProviderWrapper";
import * as Redirect from "../URLHandler/Redirect";
import { ResourcesLayout } from "../MainContent";

import type { ResourceEditFiltersState } from "../redux/reducers/resourceEditFiltersReducer";

// defining types
type Props = {
    match: ReactRouterPropTypes.match,
    history: ReactRouterPropTypes.history,
    location: ReactRouterPropTypes.location,
    resource_edit_filters: ResourceEditFiltersState
}

type State = {
    items: t.IStockLocation[],
    materials: t.IStockLocationMaterial[],
    plants: t.IPlantData[],
    plant_uuid: string,
    error: string,
    warning: string,
    selected: t.IStockLocation | null,
    title_filter: string,
    plant_filter: string
}

/**
 * Displaying production plants list.
 */
class StockLocations extends Component<Props, State> {
    itemsListRef: { current: null | StockLocationsList };

    /**
     * Constructor.
     * @param {Props} props Props object.
     * @param {State} state State object.
     */
    constructor(props: Props, state: State) {
        super(props);
        this.state = {
            items: [],
            materials: [],
            plants: [],
            plant_uuid: "",
            error: "",
            warning: "",
            selected: null,
            title_filter: "",
            plant_filter: ""
        };
        this.itemsListRef = React.createRef();
    }

    /**
     * Actual loading data from backend.
     */
    async loadComponent() {
        try {
            await waitForManufacturingAsync();
            const plants = getPlantsForUser();
            const plant_uuids = plants.map(x => x.uuid);
            const res = await getBackend().manufacturing.getStockLocations({});
            const items = res.locations
                .filter(x => plant_uuids.indexOf(x.plant_uuid) >= 0)
                .sort((x1, x2) => x1.external_id.localeCompare(x2.external_id));
            let selected = null;
            let materials = [];

            // Select initial plant.
            if (this.props.match.params.uuid) {
                let filtered = items.filter(item => item.uuid === this.props.match.params.uuid);
                if (filtered.length) {
                    selected = filtered[0];
                }
            } else if (items.length) {
                selected = items[0];
            }

            if (selected) {
                materials = (await getBackend().manufacturing.getStockLocationMaterials({ uuid: selected.uuid })).materials;
            }

            this.setState({
                items: items,
                materials: materials,
                plants: plants,
                selected: selected,
                error: "",
                warning: ""
            });
        } catch (err) {
            this.setState({ error: err.message });
        }
    }

    /**
     * Mounting data for this component.
     */
    componentDidMount() {
        this.loadComponent();
    }

    async componentDidUpdate(prev_props: Props) {
        if (prev_props.resource_edit_filters.plant_uuid !== this.props.resource_edit_filters.plant_uuid || 
            prev_props.resource_edit_filters.stock_locations_title_filter !== this.props.resource_edit_filters.stock_locations_title_filter) {
            await this.loadComponent();
        }
    }

    /**
     * Select the first line that matches all the given criteria.
     * @param {string} contentFilter Line search term.
     * @param {string} plantUuid Plant UUID.
     * @param {t.IStockLocation[]} [items] List of items to select from.
     */
    selectItem(contentFilter: string, plantUuid: string, items: t.IStockLocation[] = this.state.items): t.IStockLocation | null {
        for (let item of items) {
            const visible = this.itemsListRef.current && this.itemsListRef.current.includes(contentFilter, item);

            if ((plantUuid === item.plant_uuid || plantUuid === "") && visible) {
                return item;
            }
        }

        return null;
    }

    /**
     * Handling change in any input component.
     */
    handleInputChange = async (event: Event) => {
        try {
            if (event.currentTarget instanceof HTMLInputElement || event.currentTarget instanceof HTMLSelectElement) {
                // read new values
                const target = event.currentTarget;
                const value = target.value;
                const name = target.name;

                // update new datasource placeholder
                this.setState(prev_state => {
                    prev_state[name] = value;
                    return prev_state;
                });

                let selected = null;

                const { plant_uuid, stock_locations_title_filter } = this.props.resource_edit_filters;

                if (name === "plant_uuid") {
                    const new_plant_uuid = value;
                    selected = this.selectItem(stock_locations_title_filter, new_plant_uuid);
                    Redirect.resourceEditFilters(
                        this.props.history,
                        "/digital-twin/resources/stock_locations/" + (selected ? selected.uuid : ""),
                        {
                            ...this.props.resource_edit_filters,
                            plant_uuid: new_plant_uuid
                        }
                    );
                } else if (name === "title_filter") {
                    const new_title_filter = value;
                    selected = this.selectItem(new_title_filter, plant_uuid);
                    Redirect.resourceEditFilters(
                        this.props.history,
                        "/digital-twin/resources/stock_locations/" + (selected ? selected.uuid : ""),
                        {
                            ...this.props.resource_edit_filters,
                            stock_locations_title_filter: new_title_filter
                        }
                    );
                }

                let materials = [];

                if (selected !== null) {
                    materials = (await getBackend().manufacturing.getStockLocationMaterials({ uuid: selected.uuid })).materials;
                }

                this.setState({
                    selected: selected,
                    materials: materials
                });
            }
        } catch (err) {
            this.setState({ error: err.message });
        }
    };

    /**
     * Handle `click` events for plant items.
     */
    async handleItemClick(item: t.IStockLocation) {
        try {
            // load materials
            const { materials } = await getBackend().manufacturing.getStockLocationMaterials({ uuid: item.uuid });
            this.setState({
                materials,
                selected: item
            });

            // Update URL.
            this.props.history.push(`/digital-twin/resources/stock_locations/${item.uuid}`);
        } catch (err) {
            this.setState({ error: err.message });
        }
    }

    /**
     * Handling error from subordinate component.
     */
    handleToError = (str: string) => {
        this.setState({ error: str });
    }

    /**
     * Rendering JSX for current component.
     */
    render() {
        return (
            <ResourcesLayout>
                <ResourcesLayout.Main>
                    <ResourcesLayout.List>
                        <ResourcesLayout.Search>
                            <div className="form-group form-group-stretch">
                                <input type="text" className="form-control search_bar" placeholder={translate("common.search", "Search") + " ..."}
                                    name="title_filter" value={this.props.resource_edit_filters.stock_locations_title_filter} onChange={this.handleInputChange} />
                            </div>
                            <div className="form-group">
                                <select name="plant_uuid" className="form-control select_filter_control"
                                        value={this.props.resource_edit_filters.plant_uuid}
                                        onChange={this.handleInputChange}
                                >
                                    <option key={-1} value="">
                                        {translate("Manufacturing.Lines.all_plants", "All Plants")}
                                    </option>
                                    {
                                        this.state.plants.map((plant, i) => {
                                            return <option key={i} value={plant.uuid}>{plant.title}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </ResourcesLayout.Search>
                        <StockLocationsList
                            ref={this.itemsListRef}
                            items={this.state.items}
                            selected={this.state.selected}
                            filter={this.props.resource_edit_filters.stock_locations_title_filter}
                            plantFilter={this.props.resource_edit_filters.plant_uuid}
                            handleItemClick={(item) => { this.handleItemClick(item) }} />
                    </ResourcesLayout.List>
                    <ResourcesLayout.Content>
                        <ErrorComponent msg={this.state.error} type="error" />
                        <ErrorComponent msg={this.state.warning} type="warning" />
                        <StockLocationsDetails
                            plantFilter={this.state.selected ? this.state.selected.uuid : ""}
                            selected={this.state.selected}
                            materials={this.state.materials}
                        />
                    </ResourcesLayout.Content>
                </ResourcesLayout.Main>
            </ResourcesLayout>
        )
    }
}

export default connect(
    state => {
        return { resource_edit_filters: state.resource_edit_filters }
    },
    null
)(StockLocations);
