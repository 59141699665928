// @flow

import React from "react";

// $FlowFixMe
import { ReactComponent as ChevronCircleUpIcon } from "@fortawesome/fontawesome-free/svgs/solid/chevron-circle-up.svg";
import SvgIcon, { type SvgIconProps } from "../SvgIcon";

const ChevronCircleUp = (props: SvgIconProps) => (
    <SvgIcon {...props} size={props.size || "fs"}>
        <ChevronCircleUpIcon />
    </SvgIcon>
);

export default ChevronCircleUp;
