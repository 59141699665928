// @flow
import React from "react";
import { connect } from "react-redux";
import { translate } from "../../IntlProviderWrapper";
import Order from "./lineOrderList/Order";
import { sortUnscheduled } from "./reducers/linesOrders";

import type { ReduxState } from "./reducers/reducers";
import type { LineOrders, IOrderProducedModelEx } from "./reducers/linesOrders";
import type { PlanningTableFiltersState } from "./reducers/reducersFilters/filters";

type Props = {
    show_unscheduled_orders: boolean,
    unscheduled_orders: LineOrders[],
    filters: PlanningTableFiltersState,
    selected_materials: string[]
};

type State = {};

class UnscheduledOrders extends React.Component<Props, State> {

    getAllUnscheduledOrders = (): IOrderProducedModelEx[] => {
        const orders = [];
        for (const line of this.props.unscheduled_orders) {
            orders.push(...line.orders);
        }
        return sortUnscheduled(orders);
    }

    renderOrder = (order: IOrderProducedModelEx) => {
        return (<Order
            key={order.uuid}
            order_uuid={order.uuid}
            capacity_factor={order.capacity_factor}
            sim_ignore_earliest_start={order.sim_ignore_earliest_start}
            sortIndex={0}
            enabled={!order.skip_sim}
            line_uuid={order.line_uuid}
            enabled_edit={false}
            is_unscheduled={true}
        />);
    }

    renderUnscheduledOrders = () => {
        const unscheduled_orders = this.getAllUnscheduledOrders();
        if (unscheduled_orders.length > 0) {
            return unscheduled_orders.map(this.renderOrder)
        }

        return <tr>
            <td colSpan={3}>
                <div className="planning-table__no-orders">
                {translate("common.no_data", "No data")}
            </div>
            </td>
        </tr>
    }

    render() {
        return <div className="planning-table__row mt-0" style={{ caretColor: "transparent", overflow: "auto"}}>
            <div className="planning-table__row-title"></div>
            <table className="planning-table__sortable-list">
                <thead>
                    <tr>
                        <th className="sortable-element__workorder">{translate("OrderTableProduction.external_id", "Order ID")}</th>
                        <th className="sortable-element__material">{translate("common.id", "ID")} - {translate("common.material", "Material")}</th>
                        <th className="sortable-element__unit">{translate("common.quantity", "Quantity")}</th>
                    </tr>
                </thead>
                <tbody>
                    {this.renderUnscheduledOrders()}
                </tbody>
            </table>
        </div>
    }
}

export default connect(
    (state: ReduxState, own_props: Props) => {
        const planning_table = state.gantt_chart_planning_table;
        const show_unscheduled_orders = planning_table.show_unscheduled_orders;
        const lines_orders = state.gantt_chart_lines_orders;
        const selected_materials = state.gantt_chart_filters.selected_materials;
        const filters = state.gantt_chart_filters;
        return {
            show_unscheduled_orders,
            unscheduled_orders: lines_orders.unscheduled_orders,
            selected_materials,
            filters
        }
    },
    null
)(UnscheduledOrders)
