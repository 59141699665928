// @flow

import * as React from "react";
import { Link } from "react-router-dom";
import ReactRouterPropTypes from "react-router-prop-types";
import { FormattedMessage } from "react-intl";

import { getBackend } from "../lib/backend/Backend2";
import * as td from "../lib/backend/datasources.generated.types";

import type { SelectedDatasourceObj } from "../lib/Models";

import DatasourceList from "./DatasourceList";
import DatasourceDetails, { getDatasourceDetails } from "./DatasourceDetails";
import Loader from "./Loader";
import { ResourcesLayout } from "./MainContent";

type State = {
    datasources: Array<td.IDatasourceRec>,
    datasources_filtered: Array<td.IDatasourceRec>,
    loading: boolean,
    show_more_indicator: boolean,
    title_filter: string,
    selected: SelectedDatasourceObj | null,
    error: string | null
};

type Props = {
    match: ReactRouterPropTypes.match,
    history: ReactRouterPropTypes.history,
    location: ReactRouterPropTypes.location
};

const MAX_DISPLAYED_DATASOURCES = 100;

class Datasources extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            datasources: [],
            datasources_filtered: [],
            loading: true,
            show_more_indicator: false,
            selected: null,
            title_filter: "",
            error: null
        };
    }

    // load list of datasources from the backend
    loadDatasources = async () => {
        try {
            // handle is datasource-uuid parameter
            const { handle } = this.props.match.params;
            const res = await getBackend().datasources.searchDatasources({});
            const datasources = res.items;
            const datasources_filtered = datasources.slice(0, MAX_DISPLAYED_DATASOURCES);
            const show_more_indicator = (datasources_filtered.length === MAX_DISPLAYED_DATASOURCES);
            this.setState({ datasources, datasources_filtered, show_more_indicator, loading: false });
            if (handle) {
                // try to find and select the searched datasource
                // from the list of latest N returned datasources
                for (let i = 0; i < datasources.length; i++) {
                    if (datasources[i].uuid === handle) {
                        await this.handleDatasourceClick(datasources[i]);
                        break;
                    }
                }
            } else {
                // if no parameter is given select the first item
                // select first datasource
                if (datasources.length > 0) {
                    await this.handleDatasourceClick(datasources[0]);
                }
            }
        } catch (err) {
            this.setState({
                datasources: [],
                datasources_filtered: [],
                show_more_indicator: false,
                error: err.message
            });
        }
    };

    // handle click on the datasource in the list:
    // retrieve details and mark it as selected
    async handleDatasourceClick(datasource: td.IDatasourceRec) {
        const selected = await getDatasourceDetails(datasource.uuid);
        this.setState({ selected });
        // update url with each click
        let location: string = this.props.location.pathname;
        // if parameter is already given, change only the parameter in the path
        if (location.indexOf('datasources/') !== -1) {
            this.props.history.push(datasource.uuid);
        }
        // if parameter is not given, add 'datasources'
        else {
            this.props.history.push('datasources/' + datasource.uuid);
        }
    }

    handleInputChange = (event: Event) => {
        if (event.currentTarget instanceof HTMLInputElement || event.currentTarget instanceof HTMLSelectElement) {
            // read new values
            const target = event.currentTarget;
            // only use left trim
            const value = target.value.toLowerCase().replace(/^\s+/g, '');
            //const name = target.name;

            // update new datasource placeholder
            this.setState(prev_state => {
                prev_state.title_filter = value;
                return prev_state;
            });

            // reload datasources
            let matches = this.state.datasources
                .filter(x =>
                    value === "" ||
                    x.title.toLowerCase().indexOf(value) >= 0 ||
                    x.code.toLowerCase().indexOf(value) >= 0
                )
                .slice(0, MAX_DISPLAYED_DATASOURCES);
            const show_more_indicator = (matches.length === MAX_DISPLAYED_DATASOURCES);
            this.setState({
                datasources_filtered: matches,
                show_more_indicator
            });
        }
    };

    componentDidMount() {
        this.loadDatasources();
    }

    render() {
        return (
            <ResourcesLayout id="admin-datasources">
                <ResourcesLayout.Header
                    title={
                        <h2>
                            <FormattedMessage id="Header.menu.datasources" defaultMessage="Datasources" /> ({this.state.datasources.length})
                        </h2>
                    }
                    buttons={
                        <Link to="/admin/datasource_new" className="btn_toolbar btn_add_data">Add New</Link>
                    }
                />
                {this.state.loading && <div style={{width: "100%", textAlign: "center"}}><Loader /></div>}
                {
                    this.state.selected &&
                    <ResourcesLayout.Main>
                        <ResourcesLayout.List>
                            <div className="sub-filter-bar sub-filter-bar-datasources flex-column">
                                <input type="text" className="form-control search_bar" placeholder="Filter by title"
                                    name="title_filter" value={this.state.title_filter} onChange={this.handleInputChange} />
                                {this.state.show_more_indicator && (
                                    <small className="form-text text-muted">
                                        More results available, showing the first {MAX_DISPLAYED_DATASOURCES}.
                                    </small>
                                )}
                            </div>
                            <DatasourceList
                                datasources={this.state.datasources_filtered}
                                selected={this.state.selected}
                                handleDatasourceClick={(datasource) => { this.handleDatasourceClick(datasource); }} />
                        </ResourcesLayout.List>
                        <ResourcesLayout.Content>
                            <DatasourceDetails
                                selected={this.state.selected}
                                error={this.state.error} />
                        </ResourcesLayout.Content>
                    </ResourcesLayout.Main>
                }
            </ResourcesLayout>
        );
    }
};

export default Datasources;
