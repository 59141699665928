// @flow
import axios from "axios";
import { setLoginRedirectURL, getLoggedinUser } from "./Auth";
import { getDeviceUUID, encodeUrlQuery } from "./Util";

const TRACKING_URL = "/api/v1.0/tracking";

function pushTrackingInfo(keyvalues: Object) {
    keyvalues = enrichWithClientParams(keyvalues);
    axios.post(TRACKING_URL, keyvalues)
        .catch(error => {
            console.log("We got an error when sending tracking info ...", error);
        });
}

/** Use this function to create GET url that will be tracked, such as pixel tracking */
export function trackingPixel(keyvalues: Object) {
    keyvalues = enrichWithClientParams(keyvalues);
    const query = encodeUrlQuery(keyvalues);
    return `${TRACKING_URL}?${query}`;
}

function enrichWithClientParams(keyvalues: Object) {
    if (!keyvalues) {
        keyvalues = {};
    }
    keyvalues["device_id"] = getDeviceUUID();
    keyvalues["rand"] = Math.random();
    keyvalues["height"] = window.screen.height;
    keyvalues["width"] = window.screen.width;
    const user = getLoggedinUser();
    if (user && user.uuid && user.uuid.length > 0) {
        keyvalues["user_uuid"] = user.uuid;
    }
    return keyvalues;
}

/** This request interceptor check if we're running in read-only mode (e.g. anonymization is enabled) */
function trackingInterceptor(request: axios.AxiosRequest) {
    if (request.url !== TRACKING_URL) {
        // currently we're not tracking API calls
        // pushTrackingInfo({ url: request.url, referer: window.location.href });
    }
    return request;
}

/** This function tracks current URL from the browser. Additionally, it stores it for possible redirection */
export function registerCurrentURL() {
    const currentURL = `${window.location.pathname}${window.location.search}`;
    if (currentURL !== "/login" && currentURL !== "/change_password" && currentURL !== "/activate_twofa") {
        setLoginRedirectURL(currentURL);
    }
    pushTrackingInfo({ url: currentURL, referer: window.location.href });
}

/** This method initializes tracking framework */
export function initTracking(axiosInstance: axios.AxiosInstance) {
    axiosInstance.interceptors.request.use(trackingInterceptor);
}
