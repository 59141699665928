// @flow
import * as tr from "../../../lib/backend/reports.generated.types";

import type { ReduxState } from "../reducers";
import type { PayloadAction } from "../types";

export const SET_TRANSLATIONS_MATERIALS: "set_translations_materials" = "set_translations_materials";
export const SET_TRANSLATIONS_LOADING: "set_translations_loading" = "set_translations_loading";

type UuidFromExternalIdMap = {
    [key: string]: string
}

type ITranslationMaterialMap = {
    [uuid: string]: tr.ITranslationMaterial
 }

export type TranslationsMaterialsState = {
    translations: ITranslationMaterialMap,
    uuid_from_external_id: UuidFromExternalIdMap,
    is_loading: boolean
}

export type SetTranslationsMaterials = PayloadAction<typeof SET_TRANSLATIONS_MATERIALS, tr.ITranslationMaterial[]>;
export type SetTranslationsMaterialsLoading = PayloadAction<typeof SET_TRANSLATIONS_LOADING, boolean>;
export type TranslationsMaterialsActions = SetTranslationsMaterials | SetTranslationsMaterialsLoading;

const initial_state: TranslationsMaterialsState = {
    translations: {},
    uuid_from_external_id: {},
    is_loading: true
}

type Params = {
    external_id?: string;
    uuid?: string;
}

export const getTranslationMaterialFromStore = (state: ReduxState, params: Params): ?string => {
    const translations = state.translations_materials.translations;
    const uuid_from_external_id = state.translations_materials.uuid_from_external_id;
    const { external_id, uuid: _uuid } = params;

    let translation_material;
    let uuid;

    if (!_uuid && external_id) {
        uuid = uuid_from_external_id[external_id];
    }

    if (uuid) {
        translation_material = translations[uuid].title;
    }

    return translation_material
}


const mapData = (translation_materials: tr.ITranslationMaterial[]): $Shape<TranslationsMaterialsState> => {
    const uuid_from_external_id = {};
    const translations = {};

    for (const translation of translation_materials) {
        translations[translation.uuid] = translation;
        uuid_from_external_id[translation.external_id] = translation.uuid;
    }

    return {
        translations,
        uuid_from_external_id
    }
}

export const translationsMaterialsReducer = (state: TranslationsMaterialsState, action: TranslationsMaterialsActions) => {
    if (!state) state = initial_state;

    if (action.type === SET_TRANSLATIONS_MATERIALS) {
        const new_state = mapData(action.data);
        state.translations = { ...state.translations, ...new_state.translations };
        state.uuid_from_external_id = { ...state.uuid_from_external_id, ...new_state.uuid_from_external_id };
        state = { ...state};
    } else if (action.type === SET_TRANSLATIONS_LOADING) {
        return {...state, is_loading: action.data};
    }

    return state;
}
