// @flow

// main imports
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

type Props = {
    show: boolean,
    children: any,
    line_title: string,
    onClose: Function
}

type State = {
}

class ShiftEditorModal extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
        // nothing
    }

    async componentDidUpdate(prevProps: Props) {
        if (prevProps.show !== this.props.show) {
        }
    }

    createState(props: Props): State {
        return {};
    }

    render() {
        const { show, onClose } = this.props;
        return (
            <Modal
                show={show}
                className="batch-operation-modal"
                dialogClassName="modal-dialog-scrollable"
                onHide={onClose}
            >
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id="common.shifts" defaultMessage="Shifts" /> - {this.props.line_title}
                    </Modal.Title>
                    <button type="button" className="close" onClick={onClose}>
                        <span aria-hidden="true">×</span>
                        <span className="sr-only"><FormattedMessage id="common.close" defaultMessage="Close" /></span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    {this.props.children}
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-outline-secondary"
                        onClick={onClose}>
                        <FormattedMessage id="common.close" defaultMessage="Close" />
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default ShiftEditorModal;
