// @flow

import React from "react";
import Select from "react-select";
import { renderToString } from "react-dom/server";
import { Modal } from "react-bootstrap";

import WeeklyRealizationShift from "./WeeklyRealizationShift";
import Loader from "../Loader";
import { IntlProviderWrapper, translate } from "../IntlProviderWrapper";
import { publish, MSG_TYPES } from "../../lib/PubSub";
import { LINES_VIEWS_VIEW_TYPES } from '../ViewsCommon';
import { dateFromWeekAndShift, weekNumber, yearNumber, getDateRangeStrForWeek } from "../../lib/Util";
import { loadWeeklyRealization } from "./WeeklyRealization";
import { MaterialsDay, MaterialsWeek } from "./WeeklyRealization/WeeklyRealizationElements";

import type { MicroplanSummaryFilterProps } from "./MicroplanSummary";
import type { Sheet } from "./ExportToXLSX";

type Filter = {
    week: number,
    year: number
}
type SelectOptionItem = {
    label: string;
    short_label: string;
    value: Filter;
}

type Props = {
    viewType: ?string,
    filter: MicroplanSummaryFilterProps,
    line_group_uuid: string,
    plant_uuid: string
}

type State = {
    loading: boolean,
    show_modal: boolean,
    options: SelectOptionItem[],
    selected: SelectOptionItem[],
    loading_modal: boolean
}

const subtractWeeks = (week: number, year: number, subtract_weeks: number): Filter[] => {
    const weeks = [];
    weeks.push({ week, year });

    while (subtract_weeks > 0) {
        const date = dateFromWeekAndShift(week - 1, year, 0);
        weeks.push({
            week: weekNumber(date),
            year: yearNumber(date)
        });
        week--;
        subtract_weeks--;
    }

    return weeks;
}

export default class ExportToXLSXButton extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            show_modal: false,
            options: [],
            selected: [],
            loading_modal: false
        }
    }

    componentDidMount() {
        this.load();
    }
    componentDidUpdate(prev_props: Props, prev_state: State) {
        if (prev_props.filter !== this.props.filter) {
            this.load();
        }

        if (prev_state.show_modal !== this.state.show_modal && this.state.show_modal === true) {
            this.setState({selected: []})
        }

    }

    mapWeeksToOptions = (weeks: Filter[]): SelectOptionItem[] => {
        return weeks.map((w: Filter): SelectOptionItem => {
            return {
                label: getDateRangeStrForWeek(w.week, w.year) + " " + translate("common.week", "Week") + " " + w.week,
                short_label: getDateRangeStrForWeek(w.week, w.year),
                value: w
            }
        })
    }

    mapSelectedToSheets = async (selected: SelectOptionItem[]): Promise<Sheet[]> => {
        const sheets = [];

        for (const option of selected) {
            const week = option.value.week;
            const year = option.value.year;
            const filter = {
                week: week,
                year: year,
                breakdown_level: this.props.filter.breakdown_level,
                produced_scrap_downtime: this.props.filter.produced_scrap_downtime
            }

            // do not cache simulation reports when preparing weekly realization report export
            const sim_report_add_to_cache = false;
            const merged_data = await loadWeeklyRealization(
                week, year, this.props.plant_uuid, this.props.line_group_uuid, () => {}, sim_report_add_to_cache
            );
            if (merged_data) {
                let html_table = null;
                if (filter.breakdown_level === "day") {
                    html_table = renderToString(
                        <IntlProviderWrapper>
                            <MaterialsDay
                                merged_data={merged_data}
                                is_export={true}
                                filter={this.props.filter}
                                show_line_day_sum={false}
                                line_group_uuid={this.props.line_group_uuid}
                                produced_scrap_downtime={this.props.filter.produced_scrap_downtime}
                            />
                        </IntlProviderWrapper>
                    );
                } else if (filter.breakdown_level === "week") {
                    html_table = renderToString(
                        <IntlProviderWrapper>
                            <MaterialsWeek
                                merged_data={merged_data}
                                is_export={true}
                                filter={this.props.filter}
                                line_group_uuid={this.props.line_group_uuid}
                                produced_scrap_downtime={this.props.filter.produced_scrap_downtime}
                            />
                        </IntlProviderWrapper>
                    );
                } else {
                    html_table = renderToString(
                        <IntlProviderWrapper>
                            <WeeklyRealizationShift
                                is_export={true}
                                insights={[]}
                                onShowProductionModal={() => {}}
                                merged_data={merged_data}
                                filter={filter}
                                line_group_uuid={this.props.line_group_uuid}
                            />
                        </IntlProviderWrapper>
                    );
                }

                sheets.push({
                    html_table: html_table,
                    week_range: option.short_label
                })
            }
        }

        return sheets;
    }

    load() {
        const weeks = subtractWeeks(this.props.filter.week, this.props.filter.year, 8);

        this.setState({
            options: this.mapWeeksToOptions(weeks)
        })
    }

    setLoading = (value: boolean) => {
        this.setState({ loading: value })
    }

    onClose = () => {
        this.setState({ show_modal: false });
    }

    onOpen = () => {
        this.setState({ show_modal: true })
    };

    onExport = async () => {
        this.setState({ loading_modal: true });

        const selected_sheets = await this.mapSelectedToSheets(this.state.selected);

        this.setState({ loading_modal: false, show_modal: false });

        publish(MSG_TYPES.weekly_realization_export_to_xlsx, selected_sheets);
    }

    onChange = async (selected: SelectOptionItem[]) => {
        this.setState({ selected: selected })
    }

    render() {
        if (this.props.viewType && this.props.viewType !== LINES_VIEWS_VIEW_TYPES.realization.id) {
            return null;
        }

        return (<React.Fragment>
            <button
                disabled={this.state.loading || this.props.filter.produced_scrap_downtime !== "produced"}
                className="btn btn-primary"
                style={{float: "right"}}
                onClick={this.onOpen}
            >
                <span>
                    {translate("Manufacturing.MicroplanSummary.export_to_xlsx", "Export To XLSX")}
                </span>
            </button>
            <Modal show={this.state.show_modal}>
                <Modal.Body>
                    {this.state.loading_modal ?
                        <div style={{marginTop: "-40px"}}>
                            <Loader small={true}/>
                        </div> :
                        <Select
                            value={this.state.selected}
                            options={this.state.options}
                            onChange={this.onChange}
                            isMulti
                    />}
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary" onClick={this.onExport}>
                        {translate("Manufacturing.MicroplanSummary.export_to_xlsx", "Export To XLSX")}
                    </button>
                    <button className="btn btn-primary" onClick={this.onClose}>
                        {translate("common.close", "Close")}
                    </button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>);
    }
}

