// @flow
import { ordersReducer } from "../OrdersShifts/reducers";
import { persistentFiltersReducer } from "./reducers/persistentFiltersReducer";
import { stockForecastTooltipReducer } from "./reducers/stockForecastTooltipReducer";
import { stockStatusTableFiltersReducer } from "./reducers/stockStatusTableFiltersReducer";
import { stockStatusFiltersReducer } from "./reducers/stockStatusFiltersReducer";
import { resourceEditFiltersReducer } from "./reducers/resourceEditFiltersReducer";
import { translationsMaterialsReducer } from "./reducers/translationsMaterialsReducer";

import type { StockStatusTableFiltersState } from "./reducers/stockStatusTableFiltersReducer";
import type { StockForecastTooltipState, StockForecastTooltipActions } from "./reducers/stockForecastTooltipReducer";
import type { SubcontractingModalState } from "./types";
import type { Order } from "../OrdersShifts/reducers";
import type { PersistentFiltersState } from "../redux/reducers/persistentFiltersReducer";
import type { TranslationsMaterialsState } from "./reducers/translationsMaterialsReducer";

const initialStockStatusSubcontractingModal: SubcontractingModalState = {
    show: false,
    external_operation_id: "",
    quantity_total: ""
}

const initialState = {
    subcontracting_modal: initialStockStatusSubcontractingModal
}

const subcontractingModalReducer = (state = initialState.subcontracting_modal, action) => {
    let new_state = state;

    // $FlowFixMe
    switch (action.type) {
        case "set_subcontracting_modal":
            // $FlowFixMe
            new_state = { ...state, ...action.data }
            break;
        case "reset_subcontracting_modal":
            new_state = initialState.subcontracting_modal;
            break;
        default:
            break;
    }

    return new_state;
}

export type ActionReduxState = {
    // TODO - type safe subcontracting modal
    subcontracting_modal: any => any,
    stock_forecast_tooltip: (state: StockForecastTooltipState, action: StockForecastTooltipActions) => StockForecastTooltipState,
    orders: (any) => Order[]
}

export type ReduxState = {
    subcontracting_modal: any, // TODO
    stock_status_filters: StockStatusTableFiltersState,
    persistent_filters: PersistentFiltersState,
    stock_forecast_tooltip: StockForecastTooltipState,
    translations_materials: TranslationsMaterialsState,
    orders: Order[],
    ticketing: any, // TODO
}

export const reducers: ActionReduxState = {
    stock_status_filters: stockStatusFiltersReducer,
    resource_edit_filters: resourceEditFiltersReducer,
    stock_status_table_filters: stockStatusTableFiltersReducer,
    persistent_filters: persistentFiltersReducer,
    stock_forecast_tooltip: stockForecastTooltipReducer,
    subcontracting_modal: subcontractingModalReducer,
    translations_materials: translationsMaterialsReducer,
    // $FlowFixMe - TODO
    orders: ordersReducer
};

