// @flow
import React from "react";
import { getBackend } from "../../lib/backend/Backend2";

type Props = {

};

type CmdHistoryRec = {
    id: number,
    prompt: string,
    response: string[]
};

type State = {
    history: CmdHistoryRec[],
    cmd_text: string,
    command_history_idx: number,
    emitted_text: string
};

const PROMPT_CHAR = ">";
const PRE_ID = "admin-shell-pre";
const INPUT_ID = "admin-shell-input";

export class AdminShell extends React.Component<Props, State> {

    element: HTMLElement | null;

    constructor() {
        super();
        this.element = null;
        const state: State = {
            history: [],
            cmd_text: "",
            command_history_idx: -1,
            emitted_text: ""
        };
        this.state = state;
    }

    componentDidMount() {
        this.focusOnPrompt();
    }
    componentDidUpdate() {
        this.focusOnPrompt();
    }
    focusOnPrompt() {
        let element: any = document.getElementById(INPUT_ID);
        if (element) {
            element.focus();
            element.scrollIntoView();
        }
    }
    moveCursorToEnd() {
        let element: any = document.getElementById(INPUT_ID);
        if (element) {
            const end = element.value.length;
            element.setSelectionRange(end, end);
            element.focus();
        }
    }

    appendCmdHistory(cmd: string, response: string[]): number {
        const tmp = this.state.history;
        const id = tmp.length;
        tmp.push({ id, prompt: cmd, response: response });
        this.setState({ history: tmp, command_history_idx: tmp.length, cmd_text: "" });
        return id;
    }

    async onEnterClick() {
        const cmd_text = this.state.cmd_text.trim();
        if (cmd_text.length > 0) {
            const id = this.appendCmdHistory(cmd_text, ["Executing..."]);

            try {
                const res = await getBackend().common.adminShell({ command_line: cmd_text });

                // inject result to history
                const new_history = [...this.state.history];
                new_history.filter(x => x.id == id).forEach(x => x.response = res.result);
                this.setState({ history: new_history });
            } catch (e) {
                // inject result to history
                const new_history = [...this.state.history];
                new_history.filter(x => x.id == id).forEach(x => x.response = ["Error: " + e]);
                this.setState({ history: new_history });
            }
        } else {
            this.appendCmdHistory("", []);
        }
    }

    onChange(event: any): void {
        this.setState({ cmd_text: event.target.value });
    }
    handleKeyDown(event: any): boolean {
        const keyCode = event.keyCode;
        let idx = this.state.command_history_idx;
        let cmd_text = "";
        switch (keyCode) {
            case 13: // Enter
                this.onEnterClick();
                break;
            case 38: // arrow up
                while (idx > 0) {
                    idx = idx - 1;
                    if (this.state.history[idx].prompt.trim().length != 0){
                        break;
                    }
                }
                if (idx >= 0 && idx < this.state.history.length) {
                    cmd_text = this.state.history[idx].prompt;
                }
                this.setState(
                    { command_history_idx: idx, cmd_text },
                    () => this.moveCursorToEnd()
                );
                break;
            case 40: // arrow down
                while (idx < this.state.history.length - 1) {
                    idx = idx + 1;
                    if (this.state.history[idx].prompt.trim().length != 0){
                        break;
                    }
                }
                if (idx >= 0 && idx < this.state.history.length) {
                    cmd_text = this.state.history[idx].prompt;
                }
                this.setState(
                    { command_history_idx: idx, cmd_text },
                    () => this.moveCursorToEnd()
                );
                break;
            default:
                return true;
        }
        return false;
    };

    createCommand(command: CmdHistoryRec, index: number): any {
        const { prompt, response } = command;
        const key = "_" + index;

        return (
            <div key={key}>
                <span className="p-terminal-prompt">{PROMPT_CHAR}&nbsp;</span>
                <span className="p-terminal-command">{prompt}</span>
                <div className="p-terminal-response">{response.join("\n")}</div>
            </div>
        );
    };

    createContent(): any {
        const content = this.state.history.map((x, i) => this.createCommand(x, i));
        return <div className="p-terminal-content" onClick={() => this.focusOnPrompt()}>{content}</div>;
    };

    createPromptContainer(): any {
        return (
            <div className="p-terminal-prompt-container" id={PRE_ID}>
                <span className="p-terminal-prompt">{PROMPT_CHAR}&nbsp;</span>
                <input
                    id={INPUT_ID} autoFocus type="text" value={this.state.cmd_text}
                    className="p-terminal-input" autoComplete="off" onChange={(e) => this.onChange(e)}
                    onKeyDown={(e) => this.handleKeyDown(e)} />
            </div>
        );
    };

    render(): any {

        const content = this.createContent();
        const prompt = this.createPromptContainer();

        return (
            <div className="p-terminal">
                {content}
                {prompt}
            </div>
        );
    }
}
