// @flow

import React from "react";
import { connect } from "react-redux";
import { translate } from "../../../IntlProviderWrapper";
import * as constants from "./constants";
import { dateFromShiftTime, mydayjs, TIME_RANGES, toISODateString } from "../../../../lib/Util";
import * as BusinessLogic from "../../../../lib/BusinessLogic";
import { getShiftWidth, getShiftXPosition } from "../reducers/properties";
import { PropertiesLogic } from "../reducers/BusinessLogic";
import * as t2 from "../../../../lib/backend/reports.generated.types";

import type { ReportState } from "../reducers/report";
import type { ReduxState } from "../reducers/reducers";
import type { ShiftXPositions } from "../reducers/properties";
import type { GanttChartSourceTypes } from "../reducers/properties";
import type { PropertiesState, GridLinesLoadedAction } from "../reducers/properties";

const getDateText = (date_iter: Date, width: number) => {
    const splits = toISODateString(date_iter).split("-");
    if (width > 1020) {
        return splits[2] + "." + splits[1];
    } else if (width > 530) {
        return splits[2] + ".";
    } else {
        return "";
    }
}

type GridLinesProps = {
    gantt_chart_properties: PropertiesState,
    properties_loaded: boolean,
    source_type: GanttChartSourceTypes,
    reduxDispatch: (args: GridLinesLoadedAction) => void,
    shifts: t2.ISimulationReportOrderLineShiftFeatureItem[],
    shift_x_positions: ShiftXPositions | null
}
type GridLinesState = {};

class GridLines extends React.Component<GridLinesProps, GridLinesState> {
    
    getSourceType = () => {
        return this.props.source_type || this.props.gantt_chart_properties.source_type;
    }

    getShifts = () => {
        return this.props.shifts || [];
    }

    renderLines() {
        const properties = this.props.gantt_chart_properties;
        const top_padding = PropertiesLogic.getTopPadding(properties, this.props.source_type);
        const shift_x_positions = this.props.shift_x_positions;
        if (!properties.xscale || !shift_x_positions) {
            return null;
        }

        const Lines = [];

        for (let i = 0; i < this.props.shifts.length; i++) {
            const shift = this.props.shifts[i];
            const shift_time = shift.shift_time;
            const x = properties.bars_start + getShiftXPosition(
                {
                    shift_x_positions,
                    shift_number: shift_time.shift_number,
                    week: shift_time.week
                }
            );

            let dash_style = "5,5";
            let stroke_width = 1;
            let stroke_color = "#d9d9d9";

            if (shift.shift_time.shift_number % constants.SHIFTS_PER_DAY === 0) {
                stroke_width = 1;
                dash_style = "50, 0";
            }
            if (Number.isNaN(x)) {
                continue;
            }
            Lines.push((
                <line
                    x1={x}
                    y1={top_padding}
                    x2={x}
                    y2={PropertiesLogic.getHeight(properties, this.getSourceType())}
                    stroke_width={stroke_width}
                    stroke_color={stroke_color}
                    style={{
                        strokeWidth: stroke_width,
                        stroke: stroke_color,
                        strokeDasharray: dash_style,
                        fill: "none"
                    }}
                    fill="none"
                    gridline-shift-index={i}
                    key={i}
                    shift-number={i}
                ></line>
            ));
        }

        return Lines;
    }

    render() {
        return this.renderLines();
    }
}

export const ConnectedGridLines = connect(
    null,
    (dispatch) => ({ reduxDispatch: dispatch })
)(GridLines);

type Props = {
    gantt_chart_properties: PropertiesState,
    source_type: GanttChartSourceTypes,
    shifts: t2.ISimulationReportOrderLineShiftFeatureItem[],
    shift_x_positions: ShiftXPositions | null,
    report?: ReportState
}

type State = {};

export class GridGroups extends React.Component<Props, State> {

    getShifts = () => {
        return this.props.shifts || [];
    }

    getSourceType = (): GanttChartSourceTypes => {
        return this.props.source_type || this.props.gantt_chart_properties.source_type;
    }

    getNextTextDate = (start_index: number): ?string => {
        const shifts = this.getShifts();
        for (let i = 0; i < shifts.length; i++) {
            if ((i - 1) % constants.SHIFTS_PER_DAY === 0) {
                return `${Math.floor((i - 1) / constants.SHIFTS_PER_DAY)}`;
            }
        }
    }

    renderGridGroups = () => {
        const properties = this.props.gantt_chart_properties;
        const shift_x_positions = this.props.shift_x_positions;
        if (!shift_x_positions) {
            return null;
        }
        const shifts = this.getShifts();
        const shift_width = getShiftWidth(properties.xscale);
        const Lines = [];
        // grid groups enables sticky header
        const GridGroups = [];
        const top_padding = PropertiesLogic.getTopPadding(properties, this.props.source_type);
        for (let i = 0; i < shifts.length; i++) {
            const shift = shifts[i];
            const shift_date = new Date(shift.shift_time.shift_date);
            const { shift_number, week } = shift.shift_time;
            const x = properties.bars_start + getShiftXPosition(
                {
                    shift_x_positions,
                    shift_number,
                    week
                }
            );
            const is_weekend = (shift_date.getDay() === 6) || (shift_date.getDay() === 0);
            const GridGroup = [];
            // change font color for Saturday and Sunday
            if (is_weekend) {
                GridGroup.push(
                    <g key={"gg" + i}>
                        <rect
                            x={x}
                            y={top_padding}
                            width={shift_width}
                            height={PropertiesLogic.getHeight(properties, this.getSourceType())}
                            data-shift-time={JSON.stringify(shift.shift_time)}
                            style={{ cursor: "pointer", fill: "red", fillOpacity: "0.1" }}
                        ></rect>
                    </g>
                );
            }

            GridGroups.push(GridGroup);
        }

        return [Lines, GridGroups];
    }

    render() {
        return this.renderGridGroups();
    }
}


class GridTextDatesComponent extends React.Component<Props, State> {

    getShifts = () => {
        return this.props.shifts || [];
    }

    getSourceType = (): GanttChartSourceTypes => {
        return this.props.source_type || this.props.gantt_chart_properties.source_type;
    }


    renderGridTextDates = () => {
        const shifts = this.getShifts();
        const properties = this.props.gantt_chart_properties;
        const width = PropertiesLogic.getWidth(properties);
        const shift_x_positions = this.props.shift_x_positions;
        if (!shift_x_positions) {
            return null;
        }
        // grid groups enables sticky header
        const GridGroups = [];
        const top_padding = PropertiesLogic.getTopPadding(properties, this.props.source_type);

        for (let i = 0; i < shifts.length; i++) {
            const GridGroup = [];
            const shift = shifts[i];
            const shift_time = shift.shift_time;
            const { week, shift_number } = shift_time;
            const x_position = properties.bars_start + getShiftXPosition({
                shift_x_positions,
                shift_number,
                week
            });
            const shift_date = new Date(shift_time.shift_date);
            const shift_width = getShiftWidth(properties.xscale);
            // change font color for Saturday and Sunday
            const is_weekend = (shift_date.getDay() === 6) || (shift_date.getDay()  === 0);
            let font_color = "#616e7c";
            if (is_weekend) {
                font_color = "red";
            }
            GridGroup.push(
                <text
                    gridgroup-shift-number={`${i}`}
                    x={x_position + (shift_width / 2)}
                    y={top_padding - 7}
                    is-weekend={is_weekend.toString()}
                    textAnchor="middle"
                    fontFamily="Arial"
                    style={{
                        cursor: "pointer",
                        fontSize: "12px",
                        fill: font_color
                    }}
                    key={"t" + i}
                    data-shift-in-week={shift_time.shift_number}
                    data-week={week}
                >
                    {shift_time.shift_number % 3 + 1}
                    <title>{`${i} ${translate("common.shift_lc", "shift")}`}</title>
                </text>
            )

            if ((shift_time.shift_number) % constants.SHIFTS_PER_DAY === 1) {
                GridGroup.push(
                    <g key={"x" + i}>
                        <text
                            id={"text-date"}
                            is-weekend={is_weekend.toString()}
                            x={x_position + (shift_width / 2)}
                            y={top_padding - 25}
                            textAnchor="middle"
                            fontFamily="Arial"
                            style={{
                                fontSize: "12px",
                                fill: font_color,
                                cursor: "pointer"
                            }}
                        >
                            {getDateText(shift_date, width)}
                            <title>{`${i + 1} ${translate("common.shift_lc", "shift")}`}</title>
                        </text>
                    </g>
                );
            }
            
            let day_text = "";
            let full_day_text = "";

            if ((shift_time.shift_number) % constants.SHIFTS_PER_DAY === 1) {
                day_text = mydayjs(shift_date.getDay(), 1);
                // draw full day only for the first shift of the day
                full_day_text = mydayjs(shift_date.getDay(), 10)
            }

            GridGroup.push(
                <g key={"v" + i}>
                    <text
                        id="day-of-week"
                        x={x_position + (shift_width / 2)}
                        y={top_padding - 45}
                        textAnchor={"middle"}
                        fontFamily={"Arial"}
                        fontSize={"12px"}
                        fill={font_color}
                        cursor={"pointer"}
                    >
                        {day_text}
                        <title>
                            {full_day_text}
                        </title>
                    </text>
                </g>
            );

            // plot week name above thursday first shift
            if ((shift_date.getDay() === 4) && (shift_time.shift_number % constants.SHIFTS_PER_DAY === 1)) {
                GridGroups.push(
                    <text
                        id="week-number"
                        x={x_position}
                        y={top_padding - 65}
                        textAnchor={"middle"}
                        fontFamily={"Arial"}
                        fill={font_color}
                        style={{ cursor: "pointer" }}
                        key={"wn" + i}
                    >
                        {shift_time.week}/{shift_time.year}
                    </text>
                );
            }

            GridGroups.push(GridGroup);
        }

        return [GridGroups];
    }

    render() {
        return this.renderGridTextDates();
    }
}

export const GridTextDates = connect(
    (state: ReduxState) => {
        return {
            shift_x_positions: state.gantt_chart_properties.shift_x_positions
        }
    },
    (dispatch) => ({ reduxDispatch: dispatch })
)(GridTextDatesComponent);

export default GridTextDatesComponent;

export class CurrentShiftDashedLine extends React.Component<Props, State> {

    getShifts = () => {
        return this.props.shifts || [];
    }

    getSourceType = () => {
        return this.props.source_type || this.props.gantt_chart_properties.source_type;
    }

    renderCurrentShiftDashedLine() {
        const properties = this.props.gantt_chart_properties;
        const xscale = properties.xscale;
        const report = this.props.report;
        if (!xscale || !report || !report.report_data) {
            return null;
        }
        const report_data = report.report_data;
        const is_dev_env = BusinessLogic.getSysFlag("env") === "dev";
        if (!properties.show_past_productions && !is_dev_env) {
            return null;
        }
        const { bars_start, height } = properties;

        const top_padding = PropertiesLogic.getTopPadding(properties, this.props.source_type);
        let from_shift_time = 0;
        let current_shift_time_ts = 0;
        if (report) {
            from_shift_time = dateFromShiftTime(report_data.result.from_shift_time).getTime();
            current_shift_time_ts = dateFromShiftTime(report_data.result.next_shift_time).getTime();
        }
        const x1 = bars_start + xscale((current_shift_time_ts - from_shift_time) / TIME_RANGES.HOUR);
        const y1 = top_padding - 45;
        const x2 = x1;
        const y2 = height;

        if (x1 < bars_start) return null;
        if (Number.isNaN(x1)) return null;

        // add vertical line for current shift
        return <line
            x1={x1}
            y1={y1}
            x2={x2}
            y2={y2}
            strokeWidth={2}
            strokeDasharray={"10,5"}
            stroke={"#ef4e4e"}
            fill={"none"}
            id="current-shift-dashed"
        > </line>;

    }
    render() {
        return this.renderCurrentShiftDashedLine();
    }
}
