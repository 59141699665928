// @flow

// main imports
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import * as BusinessLogic from "../../lib/BusinessLogic";

// models
import * as t from "../../lib/backend/manufacturing2.generated.types";
import { PEOPLE_TAGS } from "../../lib/ManufacturingTags.generated";
import ChevronListItem from "../react/ChevronListItem";


/**
 * Search method within the people list.
 *
 * @param {string} needle Needle that we are looking for.
 * @param {any} obj Object where we are looking for the needle.
 *
 * @return {boolean} Returning true if needle was found, false otherwise.
 */
export const includes = (needle: string, obj: t.IPersonData) => {
    needle = needle.toLowerCase();
    if (needle.length === 0) return true;
    if (obj.name.toLowerCase().indexOf(needle) > -1) return true;
    if (obj.external_id.indexOf(needle) > -1) return true;
    return false;
}


// import subcomponents

// defining types
type Props = {
    filter: string,
    selected: t.IPersonData | null,
    people: t.IPersonData[],
    handlePeopleClick: (t.IPersonData) => void,
    narrow: boolean,
    activeFilter: boolean,
    plantFilter: string,
    line_group_uuid: string,
    showInactive: boolean
}

type State = {}

/**
 * Displaying production lines list.
 */
class PeopleList extends Component<Props, State> {
    /**
     * Handler for clicking the item.
     */
    handlePeopleClick(person: t.IPersonData, i: number) {
        // Ignore clicks for currently selected person.
        if (person === this.props.selected) {
            return;
        }

        // handle click in parent
        this.props.handlePeopleClick(person);

        // remove active show from all links
        // let nav = this.refs.nav;
        // for (let c of nav.childNodes) {
        //     c.childNodes[0].className = "nav-link";
        // }
    }

    /**
     * Rendering JSX for current component.
     */
    render() {
        // filter by line group first
        // line group filter is disabled if user is using text search filter
        const line_group = BusinessLogic.getLineGroupForUser(this.props.line_group_uuid);
        const line_group_people = this.props.people.filter(person =>
            this.props.filter !== "" || !line_group || (person.ted_id === line_group.external_id)
        );

        // generate people list and highlight the selected person
        let person_selected = (this.props.selected !== null);
        const peopleList = line_group_people.map((person, i) => {
            let is_active = this.props.selected === person;

            if (!includes(this.props.filter, person)) return null;
            if ((this.props.activeFilter === true) && (!this.props.showInactive && person.active === false)) { return null; }
            if (this.props.plantFilter !== "all") {
                if (!person.plant_uuids.includes(this.props.plantFilter)) return null;
            }

            // shift icons
            let shifts = "";
            if (person.shift_preference !== undefined) {
                shifts = person.shift_preference.map((val, i) => {
                    let shift = i + 1;
                    if (val === true) {
                        return (
                            <span key={i} className={"badge badge-shift badge-shift-" + shift}>{shift}</span>
                        );
                    } else {
                        return null;
                    }
                });
            }

            // make sure at least the first person is selected if non else is
            if (!person_selected) {
                this.handlePeopleClick(person, i);
                person_selected = true;
            }

            let isAdjuster = person.adjuster;
            let isActive = person.active;
            let personWorks12h = person.tags[PEOPLE_TAGS.shift_board_works_12h_shifts] === "true";
            let personClass = personWorks12h ? "person-12h-shift" : "";

            return <ChevronListItem
                text={<React.Fragment>
                        <div>
                            <dt><span className={personClass}>{person.name}</span>&nbsp;
                                <span className="badge-list">
                                    {isAdjuster ? (
                                        <span className="badge badge-primary"><FormattedMessage id="common.adjuster" defaultMessage="Adjuster" /></span>
                                    ) : null}
                                    {!isActive ? (
                                        <span className="badge badge-secondary"><FormattedMessage id="common.inactive" defaultMessage="inactive" /></span>
                                    ) : null}
                                </span>
                            </dt>
                            <dd className="pull-left">
                                <span className="light">{person.external_id}</span>
                            </dd>
                        </div>
                        <dd className="pull-right badge-list">{shifts}</dd>
                    </React.Fragment>
                }
                onClick={() => this.handlePeopleClick(person, i)}
                is_active={is_active}
            />
        })

        let navClass = "nav nav-tabs";
        if (this.props.narrow === true) {
            navClass = "nav nav-tabs nav-narrow";
        }

        return (
            <ul className={navClass}>
                <div className="nav-tabs-parent" ref="nav">
                    {peopleList}
                </div>
            </ul>
        )
    }
}

export default PeopleList;
