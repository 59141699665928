// @flow

import * as React from "react";
import { connect } from "react-redux";
import { niceNumber, niceDateTime, niceNumberEstimate } from "../../../../lib/Util";
import { translate } from "../../../IntlProviderWrapper";
import MaterialTitle from "./MaterialTitle";
import Tooltip from "../../../Tooltip";
import * as rt from "../../../../lib/SimulationReportModels";
import DraggableOrder from "./DraggableOrder";
import TranslatedMaterialTitle from "../../../TranslatedMaterialTitle";
import { DraggableRect } from "../Draggable/DraggableElements";
import { getCursorValue } from "../reducers/properties";
import { INSIGHT_TYPES, MATERIAL_TAGS_ACCESS, ORDER_TAGS } from "../../../../lib/ManufacturingTags.generated";
import { PropertiesLogic } from "../reducers/BusinessLogic";
import * as r from "../../../../lib/backend/reports.generated.types";
import { getBackend } from "../../../../lib/backend/Backend2";
import OrderDisabledShifts from "./OrderDisabledShifts";

import type { IsInsideOrderAction, TranslateCursorAction } from "../Draggable/reducers";
import type { PropertiesState, GanttChartSourceTypes } from "../reducers/properties";
import type { DraggedOrderState } from "../reducers/draggedOrder";
import type { MaterialTitleProps } from "./MaterialTitle";
import type { ReduxState } from "../reducers/reducers";
import type { IOrderProducedModelEx } from "../reducers/linesOrders";
import type { OrderTypeEnum } from "../../../../lib/ManufacturingConsts.generated";
import type { IMaterialModel } from "../../../../lib/backend/manufacturing2.generated.types";
import PerformanceAnalysis from "../../../react/performance/PerformanceAnalysis";
import { ValidLinesOrdersHelper } from "../reducers/ValidLinesOrdersHelper";
import ProcessNumRect from "./ProcessNumRect";

export type OrderRectangleAttributes = {
    x: number,
    y: number,
    rx: number,
    ry: number,
    width: number,
    height: number,
    order: rt.ISimulationReportOrderEx,
    unscheduled_order?: IOrderProducedModelEx,
    fill: string,
    insight_tooltips: React.Node[],
    insight_hash: string,
    has_insight: boolean,
    order_uuid: string,
    line_uuid: string,
    source_type: GanttChartSourceTypes,
    is_extra_line: boolean,
    is_filter_highlighted: boolean,
    production_version: string,
    history_x: number | null,
    fill_opacity?: number
}

export type OrderRectangleProps = OrderRectangleAttributes & {
    tooltip_text: React.Node,
    unscheduled_highlighted?: boolean,
    is_highlighted: boolean,
    highlighted_type?: string,
    is_highlighted_move_order: boolean,
    is_ctrl_down: boolean,
    is_freeze_selected_dropdown: boolean,
    has_frozen_insight: boolean,
    is_partially_scheduled_selected_dropdown: Boolean,
    has_partially_scheduled_insight: boolean,
    insight_freeze_order: boolean,
    is_inside_order: boolean,
    earliest_end: number,
    shift_number: number,
    contextDispatch: (args: IsInsideOrderAction | TranslateCursorAction) => void,
    order_index: number,
    line_index: number,
    earliest_start: number,
    is_unscheduled: boolean,
    is_rescheduled: boolean,
    is_extra_line: boolean,
    disabled_shifts: r.ISimulationReportOrderLineShiftFeatureItem[],
    material_title: string,
    reschedule_line_uuid: string
};

type TooltipOrderData = {
    order_id: string,
    external_id: string,
    material_external_id: string,
    material_title: string,
    quantity_total: number,
    quantity_produced: number,
    measurement_unit: string,
    process_num: string,
    process_title: string,
    failed_scheduling: boolean,
    insight_freeze_order: boolean,
    freeze_time_end: number
}

const getUnscheduledOrderData = (order: IOrderProducedModelEx): TooltipOrderData => {
    return {
        external_id: order.external_id,
        material_external_id: order.material_external_id,
        material_title: order.material_title,
        quantity_total: order.quantity_total,
        measurement_unit: order.measurement_unit,
        quantity_produced: order.quantity_produced,
        process_num: order.process_num,
        process_title: order.title || "",
        failed_scheduling: false,
        insight_freeze_order: false,
        freeze_time_end: 0,
        order_id: order.uuid
    };
}

const getSimulationOrderData = (order: rt.ISimulationReportOrderEx): TooltipOrderData => {
    return {
        external_id: order.order_external_id,
        material_external_id: order.material,
        material_title: order.material_title,
        quantity_total: order.quantity_total,
        measurement_unit: order.measurement_unit,
        quantity_produced: order.quantity_produced,
        process_num: order.process_num + "",
        process_title: order.process_title,
        failed_scheduling: order.failed_scheduling,
        insight_freeze_order: order.insight_freeze_order,
        freeze_time_end: order.freeze_time_end,
        order_id: order.order_id
    };
}

type TooltipOrderCommentProps = {
    order_uuid: string,
    updated_order_uuid: string | null
};
type TooltipOrderCommentState = {
    comment: string
};

class TooltipOrderCommentValue extends React.Component<TooltipOrderCommentProps, TooltipOrderCommentState> {

    state = {
        comment: ""
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        const res = await getBackend().manufacturing.searchOrders({
            include_skip_sim_internal: true,
            order_uuids: [this.props.order_uuid]
        });

        if (res.orders && res.orders.length > 0) {
            this.setState({
                comment: res.orders[0].tags[ORDER_TAGS.comment]
            });
        }
    }

    render() {
        if (!this.state.comment) {
            return null;
        }
        return <tr>
            <th>{translate("common.comment", "Comment")}</th>
            <td>
                {this.state.comment}
            </td>
        </tr>
    }
}

type Properties = { name: string, value: string };
type TooltipTextProps = {
    order?: rt.ISimulationReportOrderEx | null,
    unscheduled_order?: IOrderProducedModelEx | null,
    insight_tooltips: React.Node[],
    properties: Properties[],
    updated_order_uuid: string | null,
    material?: IMaterialModel
}

export const TooltipText = (props: TooltipTextProps): React.Node => {
    const {
        order,
        unscheduled_order = null,
        insight_tooltips = [],
        properties = [],
        updated_order_uuid,
        material
    } = props;
    let order_data: TooltipOrderData | null = null;

    if (unscheduled_order) {
        order_data = getUnscheduledOrderData(unscheduled_order);
    } else if (order) {
        order_data = getSimulationOrderData(order);
    } else {
        return "";
    }

    let material_external_id = order_data.material_external_id;
    if (
        material !== undefined &&
        MATERIAL_TAGS_ACCESS.make_to_order(material.tags) &&
        MATERIAL_TAGS_ACCESS.external_parent_material_id(material.tags)
    ) {
        material_external_id = MATERIAL_TAGS_ACCESS.external_parent_material_id(material.tags);
    }

    return (
        <React.Fragment>
            {order_data.failed_scheduling && (
                <p className="order-tooltip-title">
                    {translate("common.failed_scheduling", "Failed scheduling")}
                </p>
            )}
            {<table className="order-tooltip-properties">
                <tbody>
                    {properties.map((property, i) => (
                        <tr key={i}>
                            <th>{property.name}</th>
                            <td>{property.value}</td>
                        </tr>
                    ))}
                    <tr>
                        <th>{translate("Manufacturing.Planning.ident", "Ident")}</th>
                        <td>{material_external_id}</td>
                    </tr>
                    <tr>
                        <th>{translate("common.process", "Process")}</th>
                        <td>{order_data.process_num} - {order_data.process_title}</td>
                    </tr>
                    <tr>
                        <th>{translate("common.order", "Order")}</th>
                        <td>{order_data.external_id}</td>
                    </tr>
                    <tr>
                        <th>{translate("common.material", "Material")}</th>
                        <td>{TranslatedMaterialTitle({
                            material_external_id: order_data.material_external_id,
                            material_title: order_data.material_title
                        })}</td>
                    </tr>
                    <tr>
                        <th>{translate("common.quantity", "Quantity")}</th>
                        <td>
                            {niceNumber(order_data.quantity_produced, 0)}/{niceNumber(order_data.quantity_total, 0)}
                            {" "}
                            {order_data.measurement_unit}
                        </td>
                    </tr>
                    {order != null && unscheduled_order === null && (
                            <tr>
                                <th>{translate("common.produced_per_hour", "Produced per hour")}</th>
                                <td>{niceNumberEstimate(1 / order.model_slope)} {order.measurement_unit} ({
                                    order.model_normative_fallback
                                        ? translate("common.norm", "Norm")
                                        : translate("common.model", "Model")
                                })</td>
                            </tr>
                    )}
                    <TooltipOrderCommentValue order_uuid={order_data.order_id} updated_order_uuid={updated_order_uuid} />
                </tbody>
            </table>}
            {unscheduled_order && (
                <p className="order-tooltip-section font-weight-500 mb-0">
                    {translate("OrderTableProduction.earliest_start", "Earliest start")}
                    {": "}
                    {niceDateTime(new Date(unscheduled_order.earliest_start))}
                </p>
            )}
            {order_data.insight_freeze_order && (
                <p className="order-tooltip-section font-weight-500 mb-0">
                    {translate("common.order_is_frozen", "End of order is frozen at ")}
                    {" "}
                    {niceDateTime(new Date(order_data.freeze_time_end))}
                </p>
            )}
            {insight_tooltips.length > 0 && (
                <ul className="order-tooltip-insights list-unstyled mb-0">
                    {insight_tooltips.map((tooltip, i) => <li key={i} className="order-tooltip-section">{tooltip}</li>)}
                </ul>
            )}
        </React.Fragment>
    );
}

class OrderRectangle extends React.Component<OrderRectangleProps, any> {

    getStrokeAttributes = () => {
        let stroke = "#616e7c";
        if (!this.props.is_rescheduled && this.props.is_highlighted) {
            if (this.props.highlighted_type === "input") {
                stroke = "#f5a623";
            } else if (this.props.highlighted_type === "order") {
                stroke = "#2186eb";
            } else if (this.props.highlighted_type === "output") {
                stroke = "#e13131";
            } else {
                stroke = "#e13131";
            }
        }

        let fill = this.props.fill
        let stroke_width = (
            this.props.is_rescheduled ||
            this.props.is_highlighted ||
            this.props.is_filter_highlighted ||
            this.props.is_highlighted_move_order
        ) ? 5 : 1
        let stroke_dash_array = undefined;

        if (this.props.insight_freeze_order && this.props.is_freeze_selected_dropdown) {
            stroke = (this.props.has_frozen_insight && this.props.is_freeze_selected_dropdown) ? "red" : "blue";
            stroke_width = (this.props.is_rescheduled || this.props.is_highlighted) ? 6 : 3;
            stroke_dash_array = "5 11";
        }

        if (this.props.has_partially_scheduled_insight && this.props.is_partially_scheduled_selected_dropdown) {
            stroke_width = (this.props.is_rescheduled || this.props.is_highlighted) ? 6 : 4;
            stroke_dash_array = "5 5";
        }

        // if the order is highlighted, we already handled color at the start of the function
        if (this.props.is_unscheduled && !this.props.is_highlighted) {
            stroke = "#FD972B"
        }

        if (this.props.is_highlighted_move_order) {
            stroke = "#2186eb";
        }

        if (this.props.is_rescheduled) {
            stroke = "black";
        }

        return {
            stroke,
            fill,
            strokeWidth: stroke_width,
            strokeDasharray: stroke_dash_array
        }
    }

    splitIfHistory = () => {
        const history_x =  this.props.history_x;
        if (PropertiesLogic.isPlanningTable(this.props.source_type) || !history_x) {
            return {
                nonghost_width: null,
                ghost_width: this.props.width
            }
        }
        let ghost_width = history_x - this.props.x;
        let nonghost_width = (this.props.width - ghost_width)
        return {
            nonghost_width,
            ghost_width: ghost_width
        }
    }

    getPreferedStrokeSide = () => {
        if (this.props.reschedule_line_uuid) {
            const order_uuids = ValidLinesOrdersHelper.getPreferredOperationUuids(this.props.reschedule_line_uuid);
            if (order_uuids && order_uuids.includes(this.props.order_uuid)) {
                const { ghost_width: width } = this.splitIfHistory();
                const stroke_width = (this.props.height / 2.5);
                const y = this.props.y + this.props.height - (stroke_width / 2);
                const x1 = this.props.x;
                const y1 = y;
                const x2 = this.props.x + width;
                const y2 = y;
                return <line x1={x1} y1={y1} x2={x2} y2={y2} stroke="#FFD700" strokeWidth={stroke_width}></line>;
            }
        }
        return null;
    }

    getOpacity(nonghost_width: number | null) {
        if (this.props.fill_opacity) {
            return this.props.fill_opacity
        }

        if (this.props.is_unscheduled) {
            return 0.01;
        }

        return nonghost_width !== null ? 0.2 : 0.8;
    }

    render() {
        const cursor = getCursorValue({
            is_ctrl_down: this.props.is_ctrl_down,
            is_rescheduled: this.props.is_rescheduled,
            source_type: this.props.source_type
        });

        if (this.props.width < 0) {
            return null;
        }

        const {
            ghost_width: width,
            nonghost_width
        } = this.splitIfHistory()
        const stroke_attributes = this.getStrokeAttributes();
        return <g name="order-rectangle">
            <Tooltip key={this.props.order_uuid + "-" + this.props.is_rescheduled.toString() + "-" + this.props.line_uuid} content={this.props.tooltip_text}>
                <DraggableRect
                    key={this.props.order_uuid + "-" + this.props.is_rescheduled.toString() + "-" + this.props.line_uuid}
                    name="order-rect"
                    cursor={cursor}
                    order-uuid={this.props.order_uuid}
                    x={this.props.x}
                    y={this.props.y}
                    rx={0}
                    ry={0}
                    fillOpacity={this.getOpacity(nonghost_width)}
                    width={width}
                    height={this.props.height}
                    line-uuid={this.props.line_uuid}
                    order-uuid={this.props.order_uuid}
                    order_uuid={this.props.order_uuid}
                    earliest_end={this.props.earliest_end}
                    earliest-start={this.props.earliest_start}
                    line_uuid={this.props.line_uuid}
                    order_index={this.props.order_index}
                    shift_number={this.props.shift_number}
                    line_index={this.props.line_index}
                    source_type={this.props.source_type}
                    is_unscheduled={this.props.is_unscheduled}
                    production_version={this.props.production_version}
                    is_extra_line={this.props.is_extra_line}
                    stroke={stroke_attributes.stroke}
                    fill={stroke_attributes.fill}
                    strokeWidth={stroke_attributes.strokeWidth}
                    strokeDasharray={stroke_attributes.strokeDasharray}
                />
                {this.getPreferedStrokeSide()}
                {nonghost_width && <rect
                        id="ghost-rect"
                        {...this.getStrokeAttributes()}
                        {...this.props}
                        fillOpacity={1}
                        x={this.props.history_x}
                        width={nonghost_width}
                    />
                }
                <OrderDisabledShifts
                    order_uuid={this.props.order_uuid}
                    disabled_shifts={this.props.disabled_shifts}
                    order_rect_stroke={stroke_attributes.strokeWidth}
                    y={this.props.y}
                    material_title={this.props.material_title}
                />
            </Tooltip>
        </g>
    }
}


export const ConnectedOrderRectangle = connect(
    (state: ReduxState, own_props: OrderRectangleProps) => {
        const highlighted_orders = state.gantt_chart_planning_table.highlighted_orders;
        const planning_table = state.gantt_chart_planning_table;
        const insights_mapped = state.gantt_chart_insights.insights_mapped;
        const reschedule_line_uuid = state.gantt_chart_filters.reschedule_line_uuid;

        const filtered_insight_types = state.standalone_gantt.insight_types;
        const is_freeze_selected_dropdown = filtered_insight_types.includes(INSIGHT_TYPES.freeze_order_move);
        const is_partially_scheduled_selected_dropdown = filtered_insight_types.includes(INSIGHT_TYPES.partially_scheduled);

        const insights = insights_mapped == null ? [] : insights_mapped.get(own_props.order_uuid) || [];
        const has_frozen_insight = insights.some(i => i.type === INSIGHT_TYPES.freeze_order_move);
        const has_partially_scheduled_insight = insights.some(i => i.type === INSIGHT_TYPES.partially_scheduled);

        let is_highlighted = false;
        let highlighted_type = undefined;
        if (highlighted_orders.highlighted_orders_map != null) {
            const highlighted_order = highlighted_orders.highlighted_orders_map.get(own_props.order_uuid);
            if (highlighted_order != null) {
                is_highlighted = highlighted_order.is_highlighted;
                highlighted_type = highlighted_order.type;
            }
        }

        const is_highlighted_move_order = planning_table.highlighted_move_order_uuid === own_props.order_uuid;
        return {
            is_rescheduled: planning_table.last_clicked_order_uuid === own_props.order_uuid,
            is_ctrl_down: planning_table.is_ctrl_down,
            is_highlighted,
            highlighted_type,
            is_highlighted_move_order,
            is_freeze_selected_dropdown,
            has_frozen_insight,
            is_partially_scheduled_selected_dropdown,
            has_partially_scheduled_insight,
            reschedule_line_uuid
        }
    },
    (dispatch) => ({reduxDispatch: dispatch})
)(OrderRectangle);

type OrderProps = {
    dragged_order: DraggedOrderState,
    order_uuid: string,
    updated_order_uuid: string,
    line_uuid: string,
    order_index: number,
    line_index: number,
    shift_number: number,
    xscale: any => any,
    line_number: number,
    current_shift_number: number,
    order_rectangle_attributes: OrderRectangleProps,
    material_title_attributes: MaterialTitleProps,
    properties: PropertiesState,
    order_color: string,
    material_title: string,
    handleOrderClick: (e: Event) => any,
    reduxDispatch: any => any,
    is_visible: boolean,
    rescheduling_enabled: boolean,
    highlighted_shift_number: number,
    highlighted_line_index: number,
    last_shift_number: number,
    is_rescheduled: boolean,
    is_ctrl_down: boolean,
    is_parallel_line: boolean,
    source_type: GanttChartSourceTypes,
    is_extra_line: boolean,
    sim_ignore_earliest_start: boolean,
    order_process_num: string,
    material_external_id: string,
    order_type: OrderTypeEnum,
    show_edit_comment: boolean,
    line_order?: IOrderProducedModelEx,
    insight_hash: string,
    disabled_shifts: r.ISimulationReportOrderLineShiftFeatureItem[],
    material?: IMaterialModel,
    is_linked_operation: boolean
}

type OrderState = {
    comment: string
}

class Order extends React.Component<OrderProps, OrderState> {

    performance_key = null;

    state = {
        comment: ""
    }

    constructor(props: OrderProps) {
        super(props);
        this.performance_key = "order-" + props.order_uuid;
    }

    componentDidMount() {
        this.load();
    }

    componentDidUpdate(prev_props: OrderProps) {
        PerformanceAnalysis.componentDidUpdate(this.performance_key, prev_props, this.props, Date.now());
        if (prev_props.show_edit_comment !== this.props.show_edit_comment ||
            prev_props.line_order && prev_props.line_order.tags[ORDER_TAGS.comment] !== this.state.comment) {
            this.load();
        }
    }

    load() {
        if (this.props.line_order) {
            this.setState({
                comment: this.props.line_order.tags[ORDER_TAGS.comment]
            });
        }
    }

    // shouldComponentUpdate(nextProps: OrderProps, nextState: OrderState) {
    //     const old_ora = this.props.order_rectangle_attributes;
    //     const new_ora = nextProps.order_rectangle_attributes;
    //     const position_diff = Math.abs(old_ora.x - new_ora.x) + Math.abs(old_ora.y - new_ora.y) +
    //         Math.abs(old_ora.width - new_ora.width) + Math.abs(old_ora.height - new_ora.height);

    //     const is_position_diff = position_diff > 0.001;
    //     const is_color_diff = old_ora.fill !== new_ora.fill;
    //     const is_insight_diff = this.props.insight_hash !== nextProps.insight_hash;
    //     const is_comment_diff = this.state.comment !== nextState.comment;
    //     const is_other_diff =
    //         this.props.is_rescheduled !== nextProps.is_rescheduled ||
    //         this.props.source_type !== nextProps.source_type ||
    //         this.props.is_ctrl_down !== nextProps.is_ctrl_down;

    //     const should_update = is_position_diff || is_color_diff || is_insight_diff || is_comment_diff || is_other_diff;
    //     return should_update;
    // }

    render() {
        PerformanceAnalysis.render(this.performance_key, Date.now());
        const {
            order,
            insight_tooltips,
            unscheduled_order
        } = this.props.order_rectangle_attributes;

        const tooltip_text = <TooltipText
            order={order}
            unscheduled_order={unscheduled_order}
            insight_tooltips={insight_tooltips}
            properties={[]}
            updated_order_uuid={this.props.updated_order_uuid}
            material={this.props.material}
        />
        const is_rescheduled = this.props.is_rescheduled;
        const is_ctrl_down = this.props.is_ctrl_down;

        return <DraggableOrder
            key={"order_uuid_" + this.props.order_uuid + " " + this.props.order_index}
            order_external_id={order.order_external_id}
            order_uuid={this.props.order_uuid}
            order_type={this.props.order_type}
            line_index={this.props.line_index}
            order_index={this.props.order_index}
            order_color={this.props.order_color}
            line_uuid={this.props.line_uuid}
            shift_number={this.props.shift_number}
            last_shift_number={this.props.last_shift_number}
            x={this.props.order_rectangle_attributes.x}
            y={this.props.order_rectangle_attributes.y}
            width={this.props.order_rectangle_attributes.width}
            height={this.props.order_rectangle_attributes.height}
            production_version={this.props.order_rectangle_attributes.production_version}
            is_parallel_line={this.props.is_parallel_line}
            order_process_num={this.props.order_process_num}
            opacity={1}
            earliest_start={(new Date(order.earliest_start)).getTime()}
            earliest_end={this.props.order_rectangle_attributes.earliest_end}
            earliest-end={this.props.order_rectangle_attributes.earliest_end}
            source_type={this.props.source_type}
            is_extra_line={this.props.is_extra_line}
            sim_ignore_earliest_start={this.props.sim_ignore_earliest_start}
            material_external_id={this.props.material_external_id}
            is_rescheduled={is_rescheduled}
            performance_key={this.performance_key}
            is_linked_operation={this.props.is_linked_operation}
        >
            <g>
                <ConnectedOrderRectangle
                    key={this.props.order_uuid}
                    {...this.props.order_rectangle_attributes}
                    tooltip_text={tooltip_text}
                    material_title={this.props.material_title_attributes.material_title}
                    disabled_shifts={this.props.disabled_shifts}
                    order_uuid={this.props.order_uuid}
                />
                <MaterialTitle
                    key={"order-material-title-" + this.props.order_uuid}
                    {...this.props.material_title_attributes}
                    tooltip_text={tooltip_text}
                    is_rescheduled={is_rescheduled}
                    is_ctrl_down={is_ctrl_down}
                />
                <ProcessNumRect
                    process_num={this.props.order_process_num}
                    width={this.props.order_rectangle_attributes.width}
                    height={this.props.order_rectangle_attributes.height}
                    x={this.props.order_rectangle_attributes.x}
                    y={this.props.order_rectangle_attributes.y}
                    order_external_id={order.order_external_id}
                    order_uuid={this.props.order_uuid}
                />
            </g>
        </DraggableOrder>
    }
}

export default connect(
    (state: ReduxState, own_props: OrderProps) => {
        const planning_table = state.gantt_chart_planning_table;
        const lines_orders = state.gantt_chart_lines_orders.lines_orders;
        let line_order;
        for (const line of lines_orders) {
            line_order = line.orders.find(o => o.uuid === own_props.order_uuid);
            if (line_order) {
                break;
            }
        }

        const updated_order_uuid = state.gantt_chart_lines_orders.updated_order_uuid;
        const last_clicked_order_uuid = state.gantt_chart_planning_table.last_clicked_order_uuid;
        const material = state.gantt_chart.materials.find(
            material => material.external_id === own_props.material_title_attributes.material_external_id
        );

        return {
            line_order,
            is_rescheduled: last_clicked_order_uuid === own_props.order_uuid,
            is_ctrl_down: planning_table.is_ctrl_down,
            source_type: state.gantt_chart_properties.source_type,
            show_edit_comment: planning_table.show_edit_comment_modal_order_uuid === own_props.order_uuid,
            updated_order_uuid,
            material
        };
    },
    (dispatch) => ({ reduxDispatch: dispatch })
)(Order);
