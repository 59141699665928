// @flow
import * as React from "react";

type Props = {
    label: string
};
type State = { };

class Title extends React.Component<Props, State> {
    constructor() {
        super();
        this.state = { label: "Title" };
    }

    render() {
        return (
            <h1> {this.props.label} </h1>
        );
    }
}

export default Title;
