// @flow

// main imports
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

// models
import { loginPin } from "../lib/Auth";
import ErrorComponent from "./ErrorComponent";

type Props = {
    show: boolean,
    onSuccess: Function,
    onCancel: Function
}

type State = {
    error: string | null,
    pin: string
}

class LoginPinModal extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const state: State = {
            error: null,
            pin: ""
        };
        this.state = state;
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.show !== this.props.show) {
            this.setState({ pin: "" });
        }
    }

    keyPressed(e: any): void {
        if (e.key >= "0" && e.key <= "9") {
            this.handleNewNumber(+e.key);
        } else if (e.key === "Enter") {
            this.handleSubmit();
        } else {
            console.log(`Unknown key pressed: "${JSON.stringify(e.key)}"`)
        }
    }

    async handleSubmit() {
        try {
            this.setState({ error: null });
            await loginPin(this.state.pin);
            this.props.onSuccess();
        } catch (e) {
            this.setState({ error: e.message });
        }
    }

    handleDeleteOneNumber() {
        this.setState({ pin: this.state.pin.slice(0, -1) });
    }

    handleNewNumber(num: number) {
        this.setState((prevState) => {
            prevState.pin += "" + num;
            return prevState;
        });
    }

    handleClose() {
        this.setState({ pin: "" }, () => { this.props.onCancel(); })
    }

    render() {
        const {
            show,
            onCancel
        } = this.props;

        const button_width = Math.min(window.innerWidth / 3, 400);

        let modal_body_style;

        if (window.innerHeight > 900) {
            modal_body_style = { paddingBottom: "100px" }
        }

        return (
            <Modal
                show={show}
                bsSize="large"
                dialogClassName="modal-dialog-scrollable"
                onHide={onCancel}
            >
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id="LoginPin.Title" defaultMessage="Vstop s PIN številko" />
                    </Modal.Title>
                    <button type="button" className="close" onClick={() => this.handleClose()}>
                        <span aria-hidden="true">×</span>
                        <span className="sr-only"><FormattedMessage id="common.close" defaultMessage="Close" /></span>
                    </button>
                </Modal.Header>
                <Modal.Body style={modal_body_style}>
                    <div className="login-pin-container">
                        <ErrorComponent msg={this.state.error} type="error" onlyDiv={true} />
                        <input
                            type="password"
                            value={this.state.pin}
                            autoFocus
                            onChange={() => { }} // due to react warning
                            onKeyPress={e => this.keyPressed(e)}
                            style={{ fontSize: "3rem", textAlign: "center" }}
                        />
                    </div>
                    <center>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                            <div style={{ width: button_width }}>
                                <button className="btn login-pin-tab-button" onClick={() => this.handleNewNumber(1)}>
                                    1
                                </button>
                            </div>
                            <div style={{ width: button_width }}>
                                <button className="btn login-pin-tab-button" onClick={() => this.handleNewNumber(2)}>
                                    2
                                </button>
                            </div>
                            <div style={{ width: button_width }}>
                                <button className="btn login-pin-tab-button" onClick={() => this.handleNewNumber(3)}>
                                    3
                                </button>
                            </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                            <div style={{ width: button_width }}>
                                <button className="btn login-pin-tab-button" onClick={() => this.handleNewNumber(4)}>
                                    4
                                </button>
                            </div>
                            <div style={{ width: button_width }}>
                                <button className="btn login-pin-tab-button" onClick={() => this.handleNewNumber(5)}>
                                    5
                                </button>
                            </div>
                            <div style={{ width: button_width }}>
                                <button className="btn login-pin-tab-button" onClick={() => this.handleNewNumber(6)}>
                                    6
                                </button>
                            </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                            <div style={{ width: button_width }}>
                                <button className="btn login-pin-tab-button" onClick={() => this.handleNewNumber(7)}>
                                    7
                                </button>
                            </div>
                            <div style={{ width: button_width }}>
                                <button className="btn login-pin-tab-button" onClick={() => this.handleNewNumber(8)}>
                                    8
                                </button>
                            </div>
                            <div style={{ width: button_width }}>
                                <button className="btn login-pin-tab-button" onClick={() => this.handleNewNumber(9)}>
                                    9
                                </button>
                            </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                            <div style={{ width: button_width }}>
                                <button className="btn login-pin-tab-button" onClick={() => this.handleDeleteOneNumber()}>
                                    <i className="fa fa-backspace" />
                                </button>
                            </div>
                            <div style={{ width: button_width }}>
                                <button className="btn login-pin-tab-button" onClick={() => this.handleNewNumber(0)}>
                                    0
                                </button>
                            </div>
                            <div style={{ width: button_width }}>
                                <button className="btn login-pin-tab-button" onClick={() => this.handleSubmit()}>
                                    <i className="fa fa-check" />
                                </button>
                            </div>
                        </div>
                    </center>
                </Modal.Body>
            </Modal >
        );
    }
}

export default LoginPinModal;
