// @flow

/************************************************************************************/
/* WARNING: This file is generated automatically. Don't edit it by hand             */
/************************************************************************************/

import * as api from "./restApi.js";
import * as t from "./users.generated.types.js";

function enc(s: any): string { return encodeURIComponent("" + s); }

export interface IUsers {
    /** Refreshes JWT token for current user */
    refreshToken(req: t.IRefreshTokenReq): Promise<t.IRefreshTokenRes>;

    /** Gets data for current user */
    getCurrentUserData(req: t.IGetCurrentUserDataReq): Promise<t.IGetCurrentUserDataRes>;

    /** Gets users statistics */
    getUserStats(req: t.IGetUserStatsReq): Promise<t.IGetUserStatsRes>;

    /** Gets CSV export for all users of this organization */
    getUsersExport(req: t.IGetUsersExportReq): Promise<t.IGetUsersExportRes>;

    /** Gets all users for this organization */
    getUsers(req: t.IGetUsersReq): Promise<t.IGetUsersRes>;

    /** Creates new JWT token that is assigned to another user */
    impersonate(req: t.IImpersonateReq): Promise<t.IImpersonateRes>;

    /** Sets flag that forces users to reset the password at next login */
    setMustReset(req: t.ISetMustResetReq): Promise<t.ISetMustResetRes>;

    /** Sets PIN data for given user */
    setPinForUser(req: t.ISetPinForUserReq): Promise<t.ISetPinForUserRes>;

    /** Sets password for given user */
    setPasswordForUser(req: t.ISetPasswordForUserReq): Promise<t.ISetPasswordForUserRes>;

    /** Get data about certain user */
    getSingleUser(req: t.IGetSingleUserReq): Promise<t.IGetSingleUserRes>;

    /** Adds given role to specified user */
    addRoleForUser(req: t.IAddRoleForUserReq): Promise<t.IAddRoleForUserRes>;

    /** Removes given role from specified user */
    removeRoleForUser(req: t.IRemoveRoleForUserReq): Promise<t.IRemoveRoleForUserRes>;

    /** Sets user to active or deactivated */
    userSetActive(req: t.IUserSetActiveReq): Promise<t.IUserSetActiveRes>;

    /** Changes password for current user */
    changePassword(req: t.IChangePasswordReq): Promise<t.IChangePasswordRes>;

    /** Adds new user */
    createNewUser(req: t.ICreateNewUserReq): Promise<t.ICreateNewUserRes>;

    /** Add new user with specific login provider */
    createNewUserWithProvider(req: t.ICreateNewUserWithProviderReq): Promise<t.ICreateNewUserWithProviderRes>;

    /** Set notification-settings for given user */
    setUserNotifications(req: t.ISetUserNotificationsReq): Promise<t.ISetUserNotificationsRes>;

    /** Set homepage for given user */
    setUserHomeDashboard(req: t.ISetUserHomeDashboardReq): Promise<t.ISetUserHomeDashboardRes>;

    /** Set language for current user */
    setUserLang(req: t.ISetUserLangReq): Promise<t.ISetUserLangRes>;

    /** Sets tag for given user */
    setUserTag(req: t.ISetUserTagReq): Promise<t.ISetUserTagRes>;

    /** Registers new user */
    register(req: t.IRegisterReq): Promise<t.IRegisterRes>;

    /** Initiates password-reset for given user */
    preparePwdReset(req: t.IPreparePwdResetReq): Promise<t.IPreparePwdResetRes>;

    /** Resets password for given user */
    resetPwdWithCookie(req: t.IResetPwdWithCookieReq): Promise<t.IResetPwdWithCookieRes>;

    /** Log in user with given credentials */
    loginUP(req: t.ILoginUPReq): Promise<t.ILoginUPRes>;

    /** Generate key for 2fa */
    generateKey2fa(req: t.IGenerateKey2faReq): Promise<t.IGenerateKey2faRes>;

    /** Verify OTP */
    verifyOTP(req: t.IVerifyOTPReq): Promise<t.IVerifyOTPRes>;

    /** Disable 2FA */
    disable2FA(req: t.IDisable2FAReq): Promise<t.IDisable2FARes>;

    /** Disable 2FA for user */
    disable2FAAdmin(req: t.IDisable2FAAdminReq): Promise<t.IDisable2FAAdminRes>;

    /** Enable 2FA for user */
    enable2FAAdmin(req: t.IEnable2FAAdminReq): Promise<t.IEnable2FAAdminRes>;

    /** Abandon 2FA for user */
    abandon2FAAdmin(req: t.IAbandon2FAAdminReq): Promise<t.IAbandon2FAAdminRes>;

    /** Extend 2FA for user */
    extend2FAAdmin(req: t.IExtend2FAAdminReq): Promise<t.IExtend2FAAdminRes>;

    /** Retrieves a list of available OAuth2 providers */
    oauth2Providers(req: t.IOauth2ProvidersReq): Promise<t.IOauth2ProvidersRes>;

    /** Initiates OAuth2 login sequence */
    oauth2Initiate(req: t.IOauth2InitiateReq): Promise<t.IOauth2InitiateRes>;

    /** Performs step 2 of OAuth2 login sequence */
    oauth2Step2(req: t.IOauth2Step2Req): Promise<t.IOauth2Step2Res>;

    /** Validates given JWT token */
    validateToken(req: t.IValidateTokenReq): Promise<t.IValidateTokenRes>;

    /** Applies password policy check */
    applyPasswordPolicy(req: t.IApplyPasswordPolicyReq): Promise<t.IApplyPasswordPolicyRes>;

    /** Retrieves if this user has set PIN */
    hasPin(req: t.IHasPinReq): Promise<t.IHasPinRes>;

    /** removes PIN for specified user */
    unsetPin(req: t.IUnsetPinReq): Promise<t.IUnsetPinRes>;

    /** Changes data of user */
    userUpdate(req: t.IUserUpdateReq): Promise<t.IUserUpdateRes>;

    /** Retrieves list of logins for current user */
    getUserLogins(req: t.IGetUserLoginsReq): Promise<t.IGetUserLoginsRes>;

    /** Adds new login for current user */
    addUserLogin(req: t.IAddUserLoginReq): Promise<t.IAddUserLoginRes>;

    /** Updates login for current user */
    updateUserLogin(req: t.IUpdateUserLoginReq): Promise<t.IUpdateUserLoginRes>;

    /** Deletes login for current user */
    deleteUserLogin(req: t.IDeleteUserLoginReq): Promise<t.IDeleteUserLoginRes>;

}

export class Users implements IUsers {

    client: api.ApiClient;

    constructor(client: api.ApiClient) { this.client = client; }

    /** Refreshes JWT token for current user */
    async refreshToken(req: t.IRefreshTokenReq): Promise<t.IRefreshTokenRes> {
        const reqx: any = req;
        const url = `/api/v1.0/users/me/refresh-jwt-token`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Gets data for current user */
    async getCurrentUserData(req: t.IGetCurrentUserDataReq): Promise<t.IGetCurrentUserDataRes> {
        const reqx: any = req;
        const url = `/api/v1.0/users/me`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Gets users statistics */
    async getUserStats(req: t.IGetUserStatsReq): Promise<t.IGetUserStatsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/users/stats`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Gets CSV export for all users of this organization */
    async getUsersExport(req: t.IGetUsersExportReq): Promise<t.IGetUsersExportRes> {
        const reqx: any = req;
        const url = `/api/v1.0/users/export`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Gets all users for this organization */
    async getUsers(req: t.IGetUsersReq): Promise<t.IGetUsersRes> {
        const reqx: any = req;
        const url = `/api/v1.0/users`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Creates new JWT token that is assigned to another user */
    async impersonate(req: t.IImpersonateReq): Promise<t.IImpersonateRes> {
        const reqx: any = req;
        const url = `/api/v1.0/users/${enc(req.id)}/impersonate`;
        delete reqx.id;
        return (await this.client.post(url, reqx)).data;
    }

    /** Sets flag that forces users to reset the password at next login */
    async setMustReset(req: t.ISetMustResetReq): Promise<t.ISetMustResetRes> {
        const reqx: any = req;
        const url = `/api/v1.0/users/${enc(req.uuid)}/must-reset`;
        delete reqx.uuid;
        return (await this.client.post(url, reqx)).data;
    }

    /** Sets PIN data for given user */
    async setPinForUser(req: t.ISetPinForUserReq): Promise<t.ISetPinForUserRes> {
        const reqx: any = req;
        const url = `/api/v1.0/users/${enc(req.uuid)}/set-pin`;
        delete reqx.uuid;
        return (await this.client.post(url, reqx)).data;
    }

    /** Sets password for given user */
    async setPasswordForUser(req: t.ISetPasswordForUserReq): Promise<t.ISetPasswordForUserRes> {
        const reqx: any = req;
        const url = `/api/v1.0/users/${enc(req.uuid)}/set-password`;
        delete reqx.uuid;
        return (await this.client.post(url, reqx)).data;
    }

    /** Get data about certain user */
    async getSingleUser(req: t.IGetSingleUserReq): Promise<t.IGetSingleUserRes> {
        const reqx: any = req;
        const url = `/api/v1.0/users/${enc(req.id)}`;
        delete reqx.id;
        return (await this.client.get(url, reqx)).data;
    }

    /** Adds given role to specified user */
    async addRoleForUser(req: t.IAddRoleForUserReq): Promise<t.IAddRoleForUserRes> {
        const reqx: any = req;
        const url = `/api/v1.0/users/${enc(req.id)}/add-role`;
        delete reqx.id;
        return (await this.client.post(url, reqx)).data;
    }

    /** Removes given role from specified user */
    async removeRoleForUser(req: t.IRemoveRoleForUserReq): Promise<t.IRemoveRoleForUserRes> {
        const reqx: any = req;
        const url = `/api/v1.0/users/${enc(req.id)}/remove-role`;
        delete reqx.id;
        return (await this.client.post(url, reqx)).data;
    }

    /** Sets user to active or deactivated */
    async userSetActive(req: t.IUserSetActiveReq): Promise<t.IUserSetActiveRes> {
        const reqx: any = req;
        const url = `/api/v1.0/users/${enc(req.id)}/activate`;
        delete reqx.id;
        return (await this.client.post(url, reqx)).data;
    }

    /** Changes password for current user */
    async changePassword(req: t.IChangePasswordReq): Promise<t.IChangePasswordRes> {
        const reqx: any = req;
        const url = `/api/v1.0/users/${enc(req.id)}/password/change`;
        delete reqx.id;
        return (await this.client.post(url, reqx)).data;
    }

    /** Adds new user */
    async createNewUser(req: t.ICreateNewUserReq): Promise<t.ICreateNewUserRes> {
        const reqx: any = req;
        const url = `/api/v1.0/users/add-new`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Add new user with specific login provider */
    async createNewUserWithProvider(req: t.ICreateNewUserWithProviderReq): Promise<t.ICreateNewUserWithProviderRes> {
        const reqx: any = req;
        const url = `/api/v1.0/users/add-new-with-provider`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Set notification-settings for given user */
    async setUserNotifications(req: t.ISetUserNotificationsReq): Promise<t.ISetUserNotificationsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/users/${enc(req.id)}/notifications`;
        delete reqx.id;
        return (await this.client.post(url, reqx)).data;
    }

    /** Set homepage for given user */
    async setUserHomeDashboard(req: t.ISetUserHomeDashboardReq): Promise<t.ISetUserHomeDashboardRes> {
        const reqx: any = req;
        const url = `/api/v1.0/users/${enc(req.id)}/home-dashboard`;
        delete reqx.id;
        return (await this.client.post(url, reqx)).data;
    }

    /** Set language for current user */
    async setUserLang(req: t.ISetUserLangReq): Promise<t.ISetUserLangRes> {
        const reqx: any = req;
        const url = `/api/v1.0/users/lang`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Sets tag for given user */
    async setUserTag(req: t.ISetUserTagReq): Promise<t.ISetUserTagRes> {
        const reqx: any = req;
        const url = `/api/v1.0/users/${enc(req.id)}/tags`;
        delete reqx.id;
        return (await this.client.post(url, reqx)).data;
    }

    /** Registers new user */
    async register(req: t.IRegisterReq): Promise<t.IRegisterRes> {
        const reqx: any = req;
        const url = `/api/v1.0/users/register`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Initiates password-reset for given user */
    async preparePwdReset(req: t.IPreparePwdResetReq): Promise<t.IPreparePwdResetRes> {
        const reqx: any = req;
        const url = `/api/v1.0/users/prepare-pwd-reset`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Resets password for given user */
    async resetPwdWithCookie(req: t.IResetPwdWithCookieReq): Promise<t.IResetPwdWithCookieRes> {
        const reqx: any = req;
        const url = `/api/v1.0/users/pwd-reset`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Log in user with given credentials */
    async loginUP(req: t.ILoginUPReq): Promise<t.ILoginUPRes> {
        const reqx: any = req;
        const url = `/api/v1.0/users/login`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Generate key for 2fa */
    async generateKey2fa(req: t.IGenerateKey2faReq): Promise<t.IGenerateKey2faRes> {
        const reqx: any = req;
        const url = `/api/v1.0/users/generate-key-2fa`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Verify OTP */
    async verifyOTP(req: t.IVerifyOTPReq): Promise<t.IVerifyOTPRes> {
        const reqx: any = req;
        const url = `/api/v1.0/users/verifyOTP`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Disable 2FA */
    async disable2FA(req: t.IDisable2FAReq): Promise<t.IDisable2FARes> {
        const reqx: any = req;
        const url = `/api/v1.0/users/disable2FA`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Disable 2FA for user */
    async disable2FAAdmin(req: t.IDisable2FAAdminReq): Promise<t.IDisable2FAAdminRes> {
        const reqx: any = req;
        const url = `/api/v1.0/users/disable2FAAdmin`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Enable 2FA for user */
    async enable2FAAdmin(req: t.IEnable2FAAdminReq): Promise<t.IEnable2FAAdminRes> {
        const reqx: any = req;
        const url = `/api/v1.0/users/enable2FAAdmin`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Abandon 2FA for user */
    async abandon2FAAdmin(req: t.IAbandon2FAAdminReq): Promise<t.IAbandon2FAAdminRes> {
        const reqx: any = req;
        const url = `/api/v1.0/users/abandon2FAAdmin`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Extend 2FA for user */
    async extend2FAAdmin(req: t.IExtend2FAAdminReq): Promise<t.IExtend2FAAdminRes> {
        const reqx: any = req;
        const url = `/api/v1.0/users/extend2FAAdmin`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Retrieves a list of available OAuth2 providers */
    async oauth2Providers(req: t.IOauth2ProvidersReq): Promise<t.IOauth2ProvidersRes> {
        const reqx: any = req;
        const url = `/api/v1.0/users/oauth2/providers`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Initiates OAuth2 login sequence */
    async oauth2Initiate(req: t.IOauth2InitiateReq): Promise<t.IOauth2InitiateRes> {
        const reqx: any = req;
        const url = `/api/v1.0/users/oauth2/initiate`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Performs step 2 of OAuth2 login sequence */
    async oauth2Step2(req: t.IOauth2Step2Req): Promise<t.IOauth2Step2Res> {
        const reqx: any = req;
        const url = `/api/v1.0/users/oauth2/step2`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Validates given JWT token */
    async validateToken(req: t.IValidateTokenReq): Promise<t.IValidateTokenRes> {
        const reqx: any = req;
        const url = `/api/v1.0/users/validate-token`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Applies password policy check */
    async applyPasswordPolicy(req: t.IApplyPasswordPolicyReq): Promise<t.IApplyPasswordPolicyRes> {
        const reqx: any = req;
        const url = `/api/v1.0/users/apply-pwd-policy`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Retrieves if this user has set PIN */
    async hasPin(req: t.IHasPinReq): Promise<t.IHasPinRes> {
        const reqx: any = req;
        const url = `/api/v1.0/users/${enc(req.uuid)}/hasPin`;
        delete reqx.uuid;
        return (await this.client.get(url, reqx)).data;
    }

    /** removes PIN for specified user */
    async unsetPin(req: t.IUnsetPinReq): Promise<t.IUnsetPinRes> {
        const reqx: any = req;
        const url = `/api/v1.0/users/${enc(req.uuid)}/unsetPin`;
        delete reqx.uuid;
        return (await this.client.get(url, reqx)).data;
    }

    /** Changes data of user */
    async userUpdate(req: t.IUserUpdateReq): Promise<t.IUserUpdateRes> {
        const reqx: any = req;
        const url = `/api/v1.0/users/userUpdate`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Retrieves list of logins for current user */
    async getUserLogins(req: t.IGetUserLoginsReq): Promise<t.IGetUserLoginsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/users/logins/${enc(req.uuid)}`;
        delete reqx.uuid;
        return (await this.client.get(url, reqx)).data;
    }

    /** Adds new login for current user */
    async addUserLogin(req: t.IAddUserLoginReq): Promise<t.IAddUserLoginRes> {
        const reqx: any = req;
        const url = `/api/v1.0/users/logins/add`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Updates login for current user */
    async updateUserLogin(req: t.IUpdateUserLoginReq): Promise<t.IUpdateUserLoginRes> {
        const reqx: any = req;
        const url = `/api/v1.0/users/logins/update`;
        return (await this.client.put(url, reqx)).data;
    }

    /** Deletes login for current user */
    async deleteUserLogin(req: t.IDeleteUserLoginReq): Promise<t.IDeleteUserLoginRes> {
        const reqx: any = req;
        const url = `/api/v1.0/users/logins/delete`;
        return (await this.client.delete(url, reqx)).data;
    }

}
