// @flow
import * as React from "react";
import { classNames } from "../../lib/Util";
// $FlowFixMe
import "../../styles/react/tabs.scss";

export type TabConfig<T> = {
    id: T | string,
    title: React.Node | string,
    content: React.Node | string
}
type TabHeaderProps = {
    title: any,
    id: string,
    is_active: boolean,
    onChangeActiveTab: (id: string) => (e: Event) => void
};
type TabHeaderState = {};

class TabHeader extends React.Component<TabHeaderProps, TabHeaderState> {

    render() {
        return <div
            className={classNames("ql-tab", { active: this.props.is_active })}
            onClick={this.props.onChangeActiveTab(this.props.id)}
        >
            {this.props.title}
        </div>
    }
}

type TabContentProps = {
    content: React.Node | string,
    is_visible: boolean
};
type TabContentState = {};

class TabContent extends React.Component<TabContentProps, TabContentState> {
    render() {
        return <div className={classNames("content", { visible: this.props.is_visible })}>
            {this.props.content}
        </div>
    }
}

type Props = {
    data: TabConfig<any>[],
    active_tab: string,
    onChangeActiveTab?: (id: string) => (e: Event) => void
};
type State = {
    active_tab: string | null
};

export default class Tabs extends React.Component<Props, State> {

    state = {
        active_tab: null
    };

    componentDidUpdate(prev_props: Props) {
        if (this.props.data !== prev_props.data) {
            const tab_exists = this.props.data.some(d => d.id === this.state.active_tab);
            if (!tab_exists) {
                this.setState({
                    active_tab: this.props.data[0].id
                });
            }
        }
        if (prev_props.active_tab !== this.props.active_tab) {
            this.setState({
                active_tab: this.props.active_tab
            });
        }
    }

    componentDidMount() {
        this.load();
    }

    load = () => {
        const tabs_data = this.getTabsData();
        if (!this.state.active_tab && tabs_data) {
            this.setState({
                active_tab: tabs_data[0].id
            })
        }
    }

    getActiveTab = (): string | null => {
        return this.state.active_tab;
    }

    getTabsData = (): TabConfig<any>[] => {
        return this.props.data;
    }

    onChangeActiveTab = (id: string) => (e: Event) => {
        this.setState({ active_tab: id });
        if (this.props.onChangeActiveTab) {
            this.props.onChangeActiveTab(id);
        }
    }

    render() {
        const tabs_data = this.getTabsData();
        const active_tab = this.getActiveTab();

        return <div className="ql-tabs" style={{marginTop: "10px"}} key={active_tab}>
            <div className="ql-tabs-header">
                {tabs_data.map((t, idx) => <TabHeader onChangeActiveTab={this.onChangeActiveTab} title={t.title} is_active={t.id === active_tab} id={t.id} key={idx}/>)}
            </div>
            <div className="ql-tabs-content-relative">
                {tabs_data.map((t, idx) => <TabContent content={t.content} is_visible={t.id === active_tab} key={idx}/>)}
            </div>
        </div>
    }
}
