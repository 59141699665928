// @flow
import * as React from "react";
import { connect } from "react-redux";
import UnscheduledLines from "./UnscheduledLine";
import { ConnectedGridLines, GridGroups, CurrentShiftDashedLine } from "./GanttChartGrid";
import Loader from "../../../Loader";
import { PropertiesLogic } from "../reducers/BusinessLogic";
import ReportLoader from "./ReportLoader";
import * as t2 from "../../../../lib/backend/reports.generated.types";

import type { ShiftXPositions } from "../reducers/properties";
import type { UpdatePropertiesActionType, PropertiesState, GanttChartSourceTypes, UpdatePlanningTableWidthAction } from "../reducers/properties";
import type { ReportState } from "../reducers/report";
import type { ReduxState } from "../reducers/reducers";
import type { LineOrders } from "../reducers/linesOrders";
import type { ResetGanttAction } from "../reducers/common";
import type { SetIsCtrlDown } from "../reducers/planningTable";

const getReportCreatedAt = (report: ReportState) => {
    if (!report || !report.report_data) return null;
    return report.report_data.created_at;
}

type GanttChartProps = {
    properties: PropertiesState,
    line_group_uuid: string,
    reduxDispatch: (args: UpdatePropertiesActionType |
        ResetGanttAction | UpdatePlanningTableWidthAction | SetIsCtrlDown
    ) => any,
    show_planning_table_filter: boolean,
    start_day: number | null,
    created_at: number | null,
    num_lines: number | null,
    unscheduled_orders: LineOrders[],
    planning_weeks: number,
    report_loading: boolean,
    lines_orders_loading: boolean,
    source_type: GanttChartSourceTypes,
    show_extra_lines: boolean,
    show_only_unscheduled?: boolean,
    report: ReportState,
    shifts: t2.ISimulationReportOrderLineShiftFeature[],
    shift_x_positions: ShiftXPositions | null,
    is_ctrl_down: boolean
}

class GanttChart extends React.Component<GanttChartProps, any> {

    state = {
        properties_loaded: false
    }

    phantomNode = null;

    constructor(props) {
        super(props);
        this.phantomNode = React.createRef();
    }

    resizeListener = null;

    componentDidMount = () => {
        // calculate width and height
        if (!this.resizeListener) {
            this.resizeListener = this.updateDimensions(this);
        }

        window.addEventListener("resize", this.resizeListener);
    }


    componentWillUnmount() {
        this.removeListeners();
    }

    removeListeners = () => {
        window.removeEventListener("resize", this.resizeListener);
    }

    updateDimensions = (self) => () => {
        return null;
    }

    getIsPropertiesLoaded = () => {
        return this.props.properties.loaded;
    }

    getSvgAttributes() {
        let svg_attributes = {};
        const width = this.props.properties.width;
        const height = PropertiesLogic.getHeight(this.props.properties, this.props.source_type);

        if (width || height) {
            svg_attributes = {
                width: width,
                height: height
            }
        }

        return svg_attributes;
    }

    getLoader = () => {
        if (getReportCreatedAt(this.props.report)) {
            const properties = this.props.properties;
            if (properties) {
                const is_gantt_loading = this.props.report_loading;

                if (is_gantt_loading) {
                    const pane = document.querySelector("#pane2");
                    let rect = null;
                    if (pane) {
                        rect = pane.getBoundingClientRect();
                        const style = {
                            backgroundColor: "white",
                            opacity: "0.3",
                            position: "absolute",
                            width: rect.width,
                            height: rect.height
                        }
                        return <div style={style}></div>;
                    }
                }
                return null;
            }
        }

        return <div> <Loader small /> </div>;
    }

    render() {
        const svg_attributes = this.getSvgAttributes();
        const class_name = `${this.props.properties.no_overflow ? "" : "chart-wrapper"}`;
        const shifts = this.props.shifts ? this.props.shifts[0].shifts : [];
        return <div id={this.props.source_type}
            style={{ zIndex: 1, opacity: 1, paddingTop: "5px" }}
            className={class_name}
        >
            <ReportLoader pane_id={"pane2"} />
            <svg id="gantt-chart-unscheduled" {...svg_attributes} >
                <g id="grid">
                    <text ref={this.phantomNode} fontSize={"14px"} fontFamily={"Arial"} fontWeight={"400"} opcaity={0} />
                    <ConnectedGridLines
                        gantt_chart_properties={this.props.properties}
                        source_type={this.props.source_type}
                        shifts={shifts}
                        shift_x_positions={this.props.shift_x_positions}
                    />
                    <GridGroups
                        gantt_chart_properties={this.props.properties}
                        source_type={this.props.source_type}
                        shifts={shifts}
                        shift_x_positions={this.props.shift_x_positions}
                    />
                    <UnscheduledLines source_type={this.props.source_type} />
                    <CurrentShiftDashedLine
                        gantt_chart_properties={this.props.properties}
                        source_type={this.props.source_type}
                        shifts={shifts}
                        shift_x_positions={this.props.shift_x_positions}
                    />
                </g>
            </svg>
        </div>
    }
}

export default connect(
    (state: ReduxState, own_props: $Shape<GanttChartProps>) => {
        const loading: boolean = state.gantt_chart_lines_orders.loading;
        const properties = state.gantt_chart_properties;
        const planning_table = state.gantt_chart_planning_table;
        const report = state.gantt_chart_report.report_data;
        const is_ctrl_down = state.gantt_chart_planning_table.is_ctrl_down;

        let shifts = [];
        if (report) {
            shifts = report.result.line_shift_features;
        }
        return {
            is_ctrl_down,
            report_loading: state.gantt_chart_report.report_loading,
            lines_orders_loading: loading,
            report: state.gantt_chart_report,
            created_at: getReportCreatedAt(state.gantt_chart_report),
            properties,
            unscheduled_orders: state.gantt_chart_lines_orders.unscheduled_orders,
            show_extra_lines: planning_table.show_extra_lines,
            shifts,
            shift_x_positions: properties.shift_x_positions
        }
    },
    (dispatch) => ({ reduxDispatch: dispatch })
)(GanttChart);
