// @flow

import React, { Component } from "react";
import type { Node } from "react";
import { Modal } from "react-bootstrap";
import { translate } from "./IntlProviderWrapper";

// $FlowFixMe
import "../styles/confirmation_modal.scss";

export type ModalSizes = "sm" | "small" | "md" | "medium" | "lg" | "large";

type Props = {
    show: boolean,
    title: string,
    size?: ModalSizes,
    animation?: boolean,
    class_name?: string,
    accept_label?: string,
    cancel_label?: string,
    children?: Node,
    onAccept?: Function,
    onCancel?: Function,
    onClose?: Function
};

class ConfirmationModal extends Component<Props> {
    render() {
        const {
            title,
            size,
            show,
            animation = false,
            class_name,
            accept_label,
            cancel_label,
            onAccept,
            onCancel,
            onClose
        } = this.props;
        const handleClose = onClose || onCancel;
        return (
            <Modal
                dialogClassName={class_name || ""}
                show={show}
                bsSize={["sm", "small", "lg", "large"].includes(size) ? size : undefined}
                animation={animation}
            >
                <Modal.Header>
                    <Modal.Title>{title}</Modal.Title>
                    {handleClose !== undefined && (
                        <button type="button" className="close" onClick={handleClose}>
                            <span aria-hidden="true">×</span>
                            <span className="sr-only">{translate("common.close", "Close")}</span>
                        </button>
                    )}
                </Modal.Header>
                <Modal.Body>{this.props.children}</Modal.Body>
                {(onAccept !== undefined || onCancel !== undefined) && (
                    <Modal.Footer>
                        {onAccept !== undefined && (
                            <button className="btn btn-primary" onClick={onAccept} autoFocus={onCancel === undefined}>
                                {accept_label !== undefined ? accept_label : translate("common.yes", "Yes")}
                            </button>
                        )}
                        {onCancel !== undefined && (
                            <button className="btn btn-outline-secondary" onClick={onCancel} autoFocus>
                                {cancel_label !== undefined ? cancel_label : translate("common.no", "No")}
                            </button>
                        )}
                    </Modal.Footer>
                )}
            </Modal>
        );
    }
}

export default ConfirmationModal;
