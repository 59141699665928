// @flow

import * as React from "react";
import { createPortal } from "react-dom";

export type TooltipTrigger = "click" | "hover";

export type TooltipTargetDataset = {
    isTooltipActive: string,
    title: string
};

export type TooltipProviderStateUpdate = {
    show?: boolean,
    content?: React.Node,
    class_name?: string,
    x?: number,
    y?: number,
    offset_x?: number,
    offset_y?: number,
    style?: any,
    interactive?: boolean,
    trigger?: TooltipTrigger
};

export type TooltipProviderProps = {
    children: React.Node
};

export type TooltipProviderState = {
    show: boolean,
    enable: boolean,
    content: React.Node,
    class_name: string,
    x: number,
    y: number,
    offset_x: number,
    offset_y: number,
    position_x: number,
    position_y: number,
    width: number,
    height: number,
    style: any,
    is_focused: boolean,
    is_mouse_over: boolean,
    is_mouse_over_content: boolean,
    interactive: boolean,
    target: Element | null,
    trigger: TooltipTrigger,
    updateState: (ref: Element | null, state: TooltipProviderStateUpdate) => void
};

const default_state: TooltipProviderState = {
    show: false,
    enable: true,
    content: null,
    class_name: "",
    x: 0,
    y: 0,
    offset_x: 0,
    offset_y: 0,
    position_x: 0,
    position_y: 0,
    width: 0,
    height: 0,
    style: {},
    is_focused: false,
    is_mouse_over: false,
    is_mouse_over_content: false,
    interactive: false,
    target: null,
    trigger: "hover",
    updateState: () => {}
};

export const TooltipContext = React.createContext<TooltipProviderState>(default_state);

class TooltipProvider extends React.Component<TooltipProviderProps, TooltipProviderState> {
    tooltip_ref: HTMLElement | null = null;
    content_ref: HTMLElement | null = null;

    constructor(props: TooltipProviderProps) {
        super(props);
        this.state = {
            ...default_state,
            updateState: this.updateState
        };
    }

    componentDidMount() {
        window.addEventListener("tooltip-disable", this.disableTooltip);
        window.addEventListener("tooltip-enable", this.enableTooltip);
    }

    componentWillUnmount() {
        window.removeEventListener("tooltip-disable", this.disableTooltip);
        window.removeEventListener("tooltip-enable", this.enableTooltip);
    }

    disableTooltip = () => {
        this.setState({
            ...default_state,
            enable: false,
            updateState: this.updateState
        });
    };

    enableTooltip = () => {
        this.setState({ enable: true });
    };

    updateState = (ref: Element | null, state: TooltipProviderStateUpdate) => {
        this.setState(
            prev_state => {
                const new_state = {
                    ...prev_state,
                    ...state,
                    target: ref
                };

                if ((this.state.interactive && this.state.is_mouse_over) || this.state.is_focused) {
                    new_state.show = true;
                }

                if (!new_state.content) {
                    new_state.show = false;
                }

                if (
                    (!new_state.show && prev_state.target !== null) ||
                    (new_state.show && prev_state.target !== null && prev_state.target !== ref)
                ) {
                    this.setTargetAttributes(false, prev_state.target);
                }

                return new_state;
            },
            () => {
                if (!this.optimizePosition(this.measureFullSizeTooltip())) {
                    this.optimizePosition(this.measureTooltip(), true);
                }

                this.updateTarget();
            }
        );
    };

    updateTarget = () => {
        const target = this.state.target;
        if (target === null) {
            return;
        }

        this.setTargetAttributes(this.state.show);
    };

    setTargetAttributes = (show: boolean, target: Element | null = null) => {
        const tooltip_target = target || this.state.target;
        if (tooltip_target === null) {
            return;
        }
        // $FlowFixMe
        const dataset: TooltipTargetDataset = tooltip_target.dataset;
        if (!dataset) {
            return;
        }

        if (show) {
            dataset.isTooltipActive = "true";
            // $FlowFixMe
            if (tooltip_target.title) {
                // $FlowFixMe
                dataset.title = tooltip_target.title;
                tooltip_target.removeAttribute("title");
            }
        } else {
            delete dataset.isTooltipActive;
            if (dataset.title) {
                // $FlowFixMe
                tooltip_target.title = dataset.title;
                delete dataset.title;
            }
        }
    };

    getViewportWidth = (): number => {
        return document.body ? document.body.offsetWidth : window.innerWidth;
    };

    getViewportHeight = (): number => {
        return document.body ? document.body.offsetHeight : window.innerHeight;
    };

    getMaxWidth = (): number => {
        const viewport_width = this.getViewportWidth();

        if (this.content_ref === null) {
            return viewport_width;
        }

        const max_width = parseFloat(getComputedStyle(this.content_ref).maxWidth);
        if (Number.isNaN(max_width)) {
            return viewport_width;
        }

        return Math.min(max_width, viewport_width);
    };

    getMaxHeight = (): number => {
        const viewport_height = this.getViewportHeight();

        if (this.content_ref === null) {
            return viewport_height;
        }

        const max_height = parseFloat(getComputedStyle(this.content_ref).maxHeight);
        if (Number.isNaN(max_height)) {
            return viewport_height;
        }

        return Math.min(max_height, viewport_height);
    };

    getTooltipRect = (): ClientRect | null => {
        return this.content_ref !== null ? this.content_ref.getBoundingClientRect() : null;
    };

    updateMaxSize = () => {
        const target = this.content_ref;
        if (!target) {
            return;
        }

        target.style.maxWidth = `${this.getMaxWidth()}px`;
        target.style.maxHeight = `${this.getMaxHeight()}px`;
    };

    measureTooltip = (): ClientRect | null => {
        this.updateMaxSize();
        return this.getTooltipRect();
    };

    measureFullSizeTooltip = (): ClientRect | null => {
        const target = this.tooltip_ref;
        if (!target) {
            return null;
        }

        const { top, left } = target.style;
        target.style.top = "0";
        target.style.left = "0";
        this.updateMaxSize();
        const rect = this.getTooltipRect();
        target.style.top = top;
        target.style.left = left;

        return rect;
    };

    optimizePosition = (rect: ClientRect | null, force: boolean = false): boolean => {
        if (!rect) {
            return false;
        }

        const { x, y, offset_x, offset_y } = this.state;
        const { width, height } = rect;
        const viewport_width = this.getViewportWidth();
        const viewport_height = this.getViewportHeight();
        const diff_x = viewport_width - (x + offset_x + width);
        const diff_y = viewport_height - (y + offset_y + height);
        const opt_x = Math.max(0, viewport_width - width);
        const opt_y = Math.max(0, viewport_height - height);
        let position_x = x + offset_x;
        let position_y = y + offset_y;

        if (diff_x < 0 && diff_y >= 0) {
            position_x = opt_x;
        } else if (diff_y < 0 && diff_x >= 0) {
            position_y = opt_y;
        } else if (diff_x < 0 && diff_y < 0) {
            const min_x = x - width - offset_x + 1;
            const min_y = y - height - offset_y + 1;

            if (min_x < 0 && min_y < 0) {
                if (!force) {
                    return false;
                }

                position_y = opt_y;
            } else {
                position_x = opt_x;
                position_y = opt_y;

                if (min_x >= 0) {
                    position_x = min_x;
                }

                if (min_y >= 0) {
                    position_y = min_y;
                }
            }
        }

        this.setState(
            {
                position_x,
                position_y,
                width,
                height
            },
            this.updateTarget
        );

        return true;
    };

    getTooltipStyle = () => {
        const { x, y, position_x, position_y, width, height, is_mouse_over_content, interactive, trigger } = this.state;
        const style = {
            top: position_y,
            left: position_x,
            paddingTop: 0,
            paddingRight: 0,
            paddingBottom: 0,
            paddingLeft: 0
        };

        if (interactive && trigger === "hover" && !is_mouse_over_content) {
            const offset = 2;
            if (position_x > x) {
                style.paddingLeft = position_x - x - offset;
                style.left = x + offset;
            } else if (position_x < x - width) {
                style.paddingRight = x - (position_x + width) - offset + 1;
            }

            if (position_y > y) {
                style.paddingTop = position_y - y - offset;
                style.top = y + offset;
            } else if (position_y < y - height) {
                style.paddingBottom = y - (position_y + height) - offset + 1;
            }
        }

        return {
            ...this.state.style,
            position: "fixed",
            ...style
        };
    };

    handleMouseOver = () => {
        if (!this.state.interactive || this.state.trigger !== "hover") {
            return;
        }

        this.setState(
            {
                is_mouse_over: true,
                show: true
            },
            this.updateTarget
        );
    };

    handleMouseOut = () => {
        if (!this.state.interactive || this.state.trigger !== "hover") {
            return;
        }

        this.setState(
            {
                is_mouse_over: false,
                show: false
            },
            this.updateTarget
        );
    };

    handleFocus = () => {
        this.setState(
            {
                is_focused: true,
                show: true
            },
            this.updateTarget
        );
    };

    handleBlur = () => {
        this.setState(
            {
                is_focused: false,
                show: false
            },
            this.updateTarget
        );
    };

    handleContentMouseOver = () => {
        if (!this.state.interactive) {
            return;
        }

        this.setState(
            {
                is_mouse_over_content: true
            },
            this.updateTarget
        );
    };

    handleContentMouseOut = () => {
        this.setState(
            {
                is_mouse_over_content: false
            },
            this.updateTarget
        );
    };

    render() {
        const body = document.body;
        if (body === null) {
            return null;
        }

        return (
            <TooltipContext.Provider value={this.state}>
                {this.props.children}
                {this.state.show &&
                    this.state.enable &&
                    createPortal(
                        <div
                            role="tooltip"
                            id="custom-tooltip"
                            ref={ref => {
                                this.tooltip_ref = ref;
                            }}
                            className={`custom-tooltip ${this.state.class_name}`}
                            style={this.getTooltipStyle()}
                            onFocus={this.handleFocus}
                            onBlur={this.handleBlur}
                            onMouseEnter={this.handleMouseOver}
                            onMouseLeave={this.handleMouseOut}
                        >
                            <div
                                className="custom-tooltip-content"
                                ref={ref => {
                                    this.content_ref = ref;
                                }}
                                tabIndex={this.state.trigger === "click" ? 0 : undefined}
                                onMouseEnter={this.handleContentMouseOver}
                                onMouseLeave={this.handleContentMouseOut}
                            >
                                {this.state.content}
                            </div>
                        </div>,
                        body
                    )}
            </TooltipContext.Provider>
        );
    }
}

export default TooltipProvider;
