// @flow
import * as React from 'react'
// $FlowFixMe
import { useReducer } from "react";
import { draggableReducer } from "./reducers";
import { getInitialState } from "./reducers";

import type { DraggableContextState, Actions } from "./reducers"

const DraggableContext = React.createContext()

export type DraggableProviderContext = {
    state: DraggableContextState,
    dispatch: (args: Actions) => void
}

type Props = {
    children: any
}

function DraggableProvider({ children }: Props) {
    const [state, dispatch] = useReducer(draggableReducer, getInitialState());
    const value = { state, dispatch };
    return <DraggableContext.Provider value={value}>
        {children}
    </DraggableContext.Provider>
}

const DraggableConsumer = DraggableContext.Consumer;


export { DraggableProvider, DraggableConsumer };
