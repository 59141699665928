// @flow

import * as React from "react"
import ReactRouterPropTypes from "react-router-prop-types";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { translate } from "./IntlProviderWrapper";
import { navs, TAGGED_VIEWS } from "./ViewsCommon";
import * as BusinessLogic from "../lib/BusinessLogic";
import { PLANT_TAGS_ACCESS } from "../lib/ManufacturingTags.generated";

import {
    getHomeViews,
    getStocksViews,
    getLinesViews,
    getPeopleViews,
    getToolsView
} from "./ViewsCommon";
import { Overlay, Tooltip } from "react-bootstrap";
import ChevronCircleDownIcon from "./react/Icon/ChevronCircleDown";
import ChevronCircleUpIcon from "./react/Icon/ChevronCircleUp";
import LevelUpAltIcon from "./react/Icon/LevelUpAlt";

import type { ITags } from "../lib/Models";
import type { ViewsDropdownOption, NAV_IDS} from "./ViewsCommon";

export default class NavigationBar extends React.Component<Props> {
    render() {
        const nav = navs()[this.props.nav];

        return (
            <nav className="navigation-bar navigation_always_top">
                <ul className="nav">
                    {nav.map(navItem => (
                        <li className="nav-item" key={navItem.path}>
                            <Link className={"nav-link" + (this.props.location.pathname.lastIndexOf(navItem.path, 0) === 0 ? " active" : "")} to={navItem.path}>
                                <FormattedMessage id={navItem.intl} defaultMessage={navItem.text} />
                            </Link>
                        </li>
                    ))}
                </ul>
            </nav>
        );
    }
}

type Props = {
    location: ReactRouterPropTypes.location,
    nav: string,
    nav_id?: NAV_IDS | null,
    show_dropdown?: boolean,
    onShowDropdown?: (node: HTMLElement) => void,
    target_dropdown?: any,
    handleViewChange?: (event: Event) => void,
    plant_uuid?: string
};

export class NavigationBarDropdowns extends React.Component<Props> {

    dropdownRefs = [];

    constructor(props: Props) {
        super(props);
    }

    _hideOpenDropdowns = () => {
        const dropdown_open = document.querySelector(".dropdown-menu.show");
        if (dropdown_open) {
            dropdown_open.classList.remove("show");
        }
    }

    onClick = (e: Event) => {
        this._hideOpenDropdowns();
        if (!this.props.nav_id) return;
        e.preventDefault();
        e.stopPropagation();
        const ref = this.dropdownRefs.find(el => el === e.currentTarget);
        if (ref && this.props.onShowDropdown) {
            this.props.onShowDropdown(ref);
        }
    }

    getChevronDirection = (nav_id: NAV_IDS) => {
        if (this.props.show_dropdown &&
            this.props.target_dropdown &&
            this.props.show_dropdown &&
            this.props.target_dropdown.getAttribute("data-nav-id") === nav_id) {
            return "up";
        }

        return "down";
    }

    render() {
        const nav = navs(this.props.nav_id)[this.props.nav];
        let enable_home = false;
        if (this.props.plant_uuid) {
            const plant_tags = BusinessLogic.getPlantTags(this.props.plant_uuid);
            enable_home = PLANT_TAGS_ACCESS.new_feature_home_button(plant_tags);
        }

        return (
            <nav className="navigation-bar navigation_always_top">
                <ul className="nav">
                    {nav.map((navItem, idx) => {
                        const is_active = this.props.location.pathname.lastIndexOf(navItem.path, 0) === 0;
                        const chevron_direction = this.getChevronDirection(navItem.nav_id);
                        const button_class = `btn btn-link nav-link d-flex align-items-center justify-content-center${is_active ? " active" : ""}`;
                        if (navItem.nav_id === "home" && !enable_home) {
                            return null;
                        }
                        return this.props.nav_id && (
                            <li key={navItem.path} className={`nav-item nav-item-${navItem.nav_id}`}>
                                {navItem.default ? (
                                    <button
                                        className={button_class}
                                        value={navItem.default}
                                        onClick={this.props.handleViewChange}
                                    >
                                        <span className={navItem.icon ? "visibility-hidden" : ""} style={{ visibility: navItem.icon ? "hidden" : "visible" }}>
                                            {translate(navItem.intl, navItem.text)}
                                        </span>
                                        {navItem.icon && <navItem.icon class_name="position-absolute" size="md" />}
                                    </button>
                                ) : (
                                    <button
                                        className={button_class}
                                        ref={el => !this.dropdownRefs.includes(el) && this.dropdownRefs.push(el)}
                                        onClick={this.onClick}
                                        data-index={idx}
                                        data-nav-id={navItem.nav_id}
                                        id={this.props.nav_id && `${this.props.nav_id}-view-dropdown`}
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        <span className="px-2 mx-auto">{translate(navItem.intl, navItem.text)}</span>
                                        {this.props.nav_id && (
                                            chevron_direction === "down" ? <ChevronCircleDownIcon /> : <ChevronCircleUpIcon />
                                        )}
                                    </button>
                                )}
                            </li>
                        )
                    })}
                </ul>
            </nav>
        );
    }
}

type ViewsDropdownProps = {
    handleViewChange: (event: Event) => void,
    nav: string,
    nav_id: NAV_IDS | null,
    onCloseDropdown: (event: Event) => void,
    show_dropdown: boolean,
    target_dropdown: HTMLElement | null,
    line_group_uuid: string,
    plant_uuid: string,
    plant_tags?: ITags
}

type ViewsDropdownState = {
    views: ViewsDropdownOption[],
    mouse_inside: boolean,
    loading: boolean
};

class ViewsDropdown extends React.Component<ViewsDropdownProps, ViewsDropdownState> {

    state = {
        views: [],
        mouse_inside: false,
        loading: false
    }

    click_listener = null;

    constructor(props) {
        super(props);
        this.click_listener = window.addEventListener("click", (e) => this._hideCurrentDropdown(e), {capture: true})
    }

    _hideCurrentDropdown = (e: Event) => {
        if (!this.state.mouse_inside) {
            this.props.onCloseDropdown(e);
        }
    }

    componentWillUnmount() {
        window.removeEventListener("click", this.click_listener);
    }

    componentDidMount = async () => {
        if (this.props.line_group_uuid) {
            this.setState({ loading: true })
            const views = await this.getViews(
                this.props.line_group_uuid,
                this.getNavId()
            );
            this.setState({ views, loading: false })
        }
    }

    views = new Map<string, ViewsDropdownOption[]>();

    getViews = async (line_group_uuid: string, nav_id: NAV_IDS | null | string): Promise<ViewsDropdownOption[]> => {
        if (nav_id) {
            let views = this.views.get(line_group_uuid + "-" + nav_id);
            if (!views || views.length === 0) {
                views = await this.loadViews(this.getNavId());
                this.views.set(line_group_uuid + "-" + nav_id, views);
            }
            return views;
        }
        return [];
    }

    componentDidUpdate = async (prev_props: ViewsDropdownProps) => {
        if (this.props.line_group_uuid && prev_props.nav_id !== this.props.nav_id ||
            prev_props.target_dropdown !== this.props.target_dropdown ||
            prev_props.line_group_uuid !== this.props.line_group_uuid ||
            prev_props.plant_uuid !== this.props.plant_uuid
        ) {
            this.setState({ views: [], loading: true })
            const views = await this.getViews(
                this.props.line_group_uuid,
                this.getNavId()
            );
            const nav_id = this.getNavId();

            if (nav_id) {
                const selected_view = localStorage.getItem(`${nav_id}-view-dropdown-selected`);
                const has_view = views.find(v => v.id === selected_view);
                if (!has_view) {
                    localStorage.removeItem(`${nav_id}-view-dropdown-selected`);
                }
            }

            this.setState({ views, loading: false })
        }
    }

    onClick = (nav_id, view_id: ?string) => (e: Event) => {
        if (!nav_id) return;
        e.preventDefault();
        e.stopPropagation();
        if (view_id) {
            localStorage.setItem(`${nav_id}-view-dropdown-selected`, view_id);
        }
        this.props.handleViewChange(e);
        this.props.onCloseDropdown(e);
    }

    loadViews = async (nav_id): Promise<ViewsDropdownOption[]> => {
        if (nav_id === "home") {
            return getHomeViews(this.props.line_group_uuid, this.props.plant_uuid);
        }
        if (nav_id === "lines") {
            return await getLinesViews(this.props.line_group_uuid, this.props.plant_uuid);
        }
        if (nav_id === "stocks") {
            return getStocksViews(this.props.line_group_uuid, this.props.plant_uuid);
        }
        if (nav_id === "people") {
            return getPeopleViews(this.props.line_group_uuid);
        }
        if (nav_id === "tools") {
            return getToolsView(this.props.line_group_uuid, this.props.plant_uuid)
        }

        return [];
    }

    getNavId = () => {
        const nav_id = (
            this.props.target_dropdown && this.props.target_dropdown.getAttribute &&
            this.props.target_dropdown.getAttribute("data-nav-id")
        );

        return nav_id || this.props.nav_id;
    }

    renderLoaderOrNoData = () => {
        if (this.state.loading) {
            return <div  style={{textAlign: "center", width: "100%"}}><i className="fas fa-spinner fa-spin"></i></div>;
        }
        if (this.state.views.length === 0) {
            return <div style={{textAlign: "center", width: "100%"}}>{translate("common.no_data", "No data available")}</div>;
        }
        return null;
    }

    renderDropdown = () => {
        const nav_id = this.getNavId();
        const views = this.state.views;
        return (
            <div className="navigation-views-dropdown">
                {views && views.map(view => {
                    let subcategory_icon = null;
                    if (Object.keys(TAGGED_VIEWS).includes(view.id)) {
                        subcategory_icon = <LevelUpAltIcon class_name="mr-3" style={{ transform: "rotate(90deg)" }} />;
                    }
                    return (
                        <button
                            key={view.id}
                            className="dropdown-item d-flex align-items-center"
                            id={view.id}
                            value={view.id}
                            onClick={this.onClick(nav_id, view.id)}
                        >
                            {subcategory_icon}
                            {translate(view.title_code || view.title, view.title)}
                        </button>
                    );
                })}
                {this.renderLoaderOrNoData()}
            </div>
        );
    }

    getMinWidth = () => {
        const el = (
            this.props.target_dropdown ?
            this.props.target_dropdown.closest(".nav-item") :
            document.querySelector(".navigation-bar .nav-item")
        );

        if (el) {
            return el.getBoundingClientRect().width + "px";
        }
        return "250px";
    }

    onMouseEnter = () => {
        this.setState({mouse_inside: true})
    }

    onMouseLeave = () => {
        this.setState({mouse_inside: false})
    }

    render() {
        if (!this.props.show_dropdown) return null;

        return <React.Fragment>
            <Overlay
                rootClose
                target={this.props.target_dropdown}
                show={this.props.show_dropdown}
                onHide={this.props.onCloseDropdown}
                container={this.props.target_dropdown && this.props.target_dropdown.parentElement}
                placement="bottom"
            >
                <Tooltip
                    onMouseEnter={this.onMouseEnter}
                    onMouseLeave={this.onMouseLeave}
                    id="navigation-bar-dropdown-tooltip"
                    className="tooltip-dropdown navigation-bar-dropdown"
                    style={{ minWidth: this.getMinWidth() }}
                >
                    {this.renderDropdown()}
                </Tooltip>
            </Overlay>
        </React.Fragment>
    }
}

type NavigationBarWithDropdownProps = {
    handleViewChange: (event: Event) => void,
    location: ReactRouterPropTypes.location,
    nav: string,
    nav_id: NAV_IDS | null,
    line_group_uuid: string,
    plant_uuid: string,
    layout_dashboard?: string,
    gantt_dashboard?: string
}

type NavigationBarWithDropdownState = {
    show_dropdown: boolean,
    target_dropdown: any
}

export class NavigationBarWithDropdown extends React.Component<NavigationBarWithDropdownProps, NavigationBarWithDropdownState> {

    state = {
        show_dropdown: false,
        target_dropdown: null
    }

    onShowDropdown = (node: HTMLElement) => {
        this.setState({ show_dropdown: true, target_dropdown: node });
    }

    onCloseDropdown = () => {
        this.setState({ show_dropdown: false });
    }

    render() {
        if (localStorage.getItem("grid-layout") === "true") {
            return null;
        }

        return <div>
            <ViewsDropdown
                handleViewChange={this.props.handleViewChange}
                nav_id={this.props.nav_id}
                show_dropdown={this.state.show_dropdown}
                target_dropdown={this.state.target_dropdown}
                onCloseDropdown={this.onCloseDropdown}
                nav={this.props.nav}
                line_group_uuid={this.props.line_group_uuid}
                plant_uuid={this.props.plant_uuid}
            />
            <NavigationBarDropdowns
                handleViewChange={this.props.handleViewChange}
                onShowDropdown={this.onShowDropdown}
                show_dropdown={this.state.show_dropdown}
                target_dropdown={this.state.target_dropdown}
                location={this.props.location}
                nav={this.props.nav}
                nav_id={this.props.nav_id}
                plant_uuid={this.props.plant_uuid}
            />
        </div>
    }
}
