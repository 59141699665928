// @flow
import React from "react";

type SimpleTableProps = {
    header: string[] | null,
    data: string[][],
    footer?: string[]
};

const SimpleTable = (props: SimpleTableProps) => {
    return <table className="table">
        {props.header && <thead>
            <tr>
                {props.header.map((x, i) => <th key={i}>{x}</th>)}
            </tr>
        </thead>}
        <tbody>
            {props.data.map((row, i) => <tr key={i}>
                {row.map((cell, j) => <td key={j}>{cell}</td>)}
            </tr>)}
        </tbody>
        {props.footer && <tfoot>
            <tr>
                {props.footer.map((x, i) => <th key={i}>{x}</th>)}
            </tr>
        </tfoot>}
    </table>;
}

export default SimpleTable;