// @flow
import * as React from "react";
import { connect } from "react-redux";
import { translate } from "../../IntlProviderWrapper";
import ReactTable from "../../react/ReactTable";
import Loader from "../../Loader";

import type { IWeekKPIs } from "../../../lib/backend/reports.generated.types";
import type { ReactTableColumn } from "../../react/ReactTable";
import type { AddKpisAction } from "./reducers/kpis";

type KpiProps = {
    base_kpis: IWeekKPIs[],
    kpis: IWeekKPIs[]
}

function getKpi(base_kpis: IWeekKPIs[], idx: number, kpis: IWeekKPIs, name: string, direction: number) {
    const base_kpi = base_kpis ? (base_kpis[idx] ? base_kpis[idx][name] : 0) : 0;
    const kpi = kpis ? kpis[name] : 0;
    const perc = (base_kpi !== 0) ? `${Math.round(1000 * kpi / base_kpi)/ 10}` : "-";

    const cell_style =
        (direction * (kpi - base_kpi) > 0) ? "stock_available_lite" :
        (kpi === base_kpi) ? "stock_empty" : "stock_missing_lite";

    return {
        cell_style: cell_style,
        cell_element: <div><b>{kpi}</b> / {base_kpi} ({perc}%)</div>
    }
}

function getKpiRelative(base_kpis: IWeekKPIs[], idx: number, kpis: IWeekKPIs, name1: string, name2: string, direction: number) {
    const base_kpi1 = base_kpis ? (base_kpis[idx] ? base_kpis[idx][name1] : 0) : 0;
    const base_kpi2 = base_kpis ? (base_kpis[idx] ? base_kpis[idx][name2] : 0) : 0;
    const kpi1 = kpis ? kpis[name1] : 0;
    const kpi2 = kpis ? kpis[name2] : 0;

    const base_kpi = (base_kpi1 !== 0) ? Math.round(1000 * base_kpi2 / base_kpi1) / 10 : 0;
    const kpi = (kpi1 !== 0) ? Math.round(1000 * kpi2 / kpi1) / 10 : 0;

    const cell_style =
        (direction * (kpi - base_kpi) > 0) ? "stock_available_lite" :
        (kpi === base_kpi) ? "stock_empty" : "stock_missing_lite";

    return {
        cell_style: cell_style,
        cell_element: <div><b>{kpi}</b> / {base_kpi}</div>
    }
}

function getKpiNorm(base_kpis: IWeekKPIs[], idx: number, kpis: IWeekKPIs, name: string, norm: number, direction: number) {
    const base_kpi = Math.round(10 * (base_kpis ? (base_kpis[idx] ? base_kpis[idx][name] : 0) : 0) / norm) / 10;
    const kpi = Math.round(10 * (kpis ? kpis[name] : 0) / norm) / 10;
    const perc = (base_kpi !== 0) ? `${Math.round(1000 * kpi / base_kpi)/ 10}` : "-";

    const cell_style =
        (direction * (kpi - base_kpi) > 0) ? "stock_available_lite" :
        (kpi === base_kpi) ? "stock_empty" : "stock_missing_lite";

    return {
        cell_style: cell_style,
        cell_element: <div><b>{kpi}</b> / {base_kpi} ({perc}%) </div>
    }
}


type KpiTableCellValue = {
    cell_style?: string,
    cell_element: React.Node
}
type KpiTableColumns = {
    week: KpiTableCellValue,
    tool_changes_count: KpiTableCellValue,
    tool_changes_hours: KpiTableCellValue,
    no_input_materials_operations: KpiTableCellValue,
    delay_operations: KpiTableCellValue,
    availability: KpiTableCellValue,
    workers: KpiTableCellValue
};
type KpiTableCellArgs = {
    cell: any
}

const KpiTableCell = ({cell}: KpiTableCellArgs): React.Node => {
    return <td className={cell.value.cell_style}>
        {cell.value.cell_element}
    </td>
}

const getTableColumns = (): ReactTableColumn<KpiTableColumns>[] => {
    return [
        {
            Header: translate("common.week", "Week"),
            accessor: "week",
            disableFilters: true,
            Cell: KpiTableCell,
            override_default_td_cell: true
        },
        {
            Header: translate("Manufacturing.Planning.KPIs.tool_changes_count", "Tool setups [no.]"),
            accessor: "tool_changes_count",
            disableFilters: true,
            Cell: KpiTableCell,
            override_default_td_cell: true

        },
        {
            Header: translate("Manufacturing.Planning.KPIs.tool_changes_hours", "Tool setups [h]"),
            accessor: "tool_changes_hours",
            disableFilters: true,
            Cell: KpiTableCell,
            override_default_td_cell: true
        },
        {
            Header: translate("Manufacturing.Planning.KPIs.out_of_stock", "Orders with no material [no.]"),
            accessor: "no_input_materials_operations",
            disableFilters: true,
            Cell: KpiTableCell,
            override_default_td_cell: true
        },
        {
            Header: translate("Manufacturing.Planning.KPIs.out_of_stock_delay", "Orders with delay [no.]"),
            accessor: "delay_operations",
            disableFilters: true,
            Cell: KpiTableCell,
            override_default_td_cell: true
        },
        {
            Header: translate("Manufacturing.Planning.KPIs.availability", "Availability"),
            accessor: "availability",
            disableFilters: true,
            Cell: KpiTableCell,
            override_default_td_cell: true
        }
    ]
};

const mapData = (base_kpis: IWeekKPIs[], kpis: IWeekKPIs[]) => {
    return kpis.map((kpi, i) => {
        return {
            week: { cell_element: <span> {kpi.week != -1 ? kpi.week : <strong>{translate("common.total", "Total")}</strong> } </span> },
            tool_changes_count: getKpi(base_kpis, i, kpi, "tool_changes_count", -1),
            tool_changes_hours: getKpi(base_kpis, i, kpi, "tool_changes_hours", -1),
            no_input_materials_operations: getKpi(base_kpis, i, kpi, "no_input_materials_operations", -1),
            delay_operations: getKpi(base_kpis, i, kpi, "delay_operations", -1),
            availability: getKpiRelative(base_kpis, i, kpi, "available_hours", "used_hours", 1),
            workers: getKpiNorm(base_kpis, i, kpi, "used_hours", 37.5, -1)
        }
    });
}

const KpiTable = (props: KpiProps) => {
    const data = mapData(props.base_kpis, props.kpis);
    const columns = getTableColumns();

    return <ReactTable data={data} columns={columns} isLoading={false} />;
}

type Props = {
    planning_weeks: number,
    original_report_uuid: string,
    report_uuid: string,
    is_loading: boolean,
    base_kpis: IWeekKPIs[],
    base_kpi_sum: IWeekKPIs,
    kpis: IWeekKPIs[],
    kpi_sum: IWeekKPIs,
    line_uuids: string[],
    reduxDispatch: (args: AddKpisAction) => void
};

type State = {};

class KPIs extends React.Component<Props, State> {

    render() {
        if (this.props.is_loading) {
            return <Loader small={true}></Loader>;
        }
        const base_kpis = this.props.base_kpis;
        const kpis = this.props.kpis;

        if (base_kpis && base_kpis.length > 0 && kpis && kpis.length > 0) {
            return <div style={{ padding: "10px" }}>
                <KpiTable
                    base_kpis={[...base_kpis, this.props.base_kpi_sum]}
                    kpis={[...kpis, this.props.kpi_sum]}
                />
            </div>
        }

        return null;
    }
}

export default connect(
    null,
    (dispatch) => ({reduxDispatch: dispatch})
)(KPIs);
