// @flow
import React from "react";
import ReactRouterPropTypes from "react-router-prop-types";
import ReactTable from "../react/ReactTable";
import { FormattedMessage } from "react-intl";
import {
    rowStyle,
    mapData,
    Columns,
    updateTagsConfig,
    onChangeUpdateTags,
    getInitialState
} from "../react/ReactTableTags";
import EntityEditTemplate from "../EntityEditTemplate";
import { PLANT_TAGS } from "../../lib/ManufacturingTagsEditor.generated"
import { getBackend as getBackend2 } from "../../lib/backend/Backend2";
import { translate } from "../IntlProviderWrapper";
import type { IUpdatePlantReq } from "../../lib/backend/manufacturing2.generated.types";
import type { ITags } from "../../lib/Models";

import ErrorComponent from "../ErrorComponent";

import { getLang } from "../IntlProviderWrapper";

import { getLinkForUserDocumentation, UserDocumentationLinksEnum } from "../../lib/Util";

import Loader from "../Loader";

type Props = {
    match: ReactRouterPropTypes.match,
    history: ReactRouterPropTypes.history,
    location: ReactRouterPropTypes.location
};

type State = {
    tags: ITags,
    title: string,
    error: string | null,
    weight: number,
    loading: boolean
};

class PlantEdit extends React.Component<Props, State> {

    state = {
        error: null,
        tags: {},
        title: "",
        weight: 0,
        loading: false
    };

    componentDidMount() {
        this.load();
    }

    getPlantUuid = () => {
        return this.props.match.params.uuid;
    }

    load = async () => {
        try {
            this.setState({ error: null });
            const res = await getBackend2().manufacturing.getPlants({});
            if (res.plants.length > 0) {
                const plant = res.plants.find(p => p.uuid === this.getPlantUuid());
                if (plant) {
                    this.setState({ title: plant.title, tags: plant.tags, weight: plant.weight });
                }
            }
        } catch (e) {
            this.setState({
                error: translate("common.error", "An error occured while fetching data.")
            });
        }
    }

    onChangeCell = (name: string) => (value: boolean | Object | string) => {
        this.setState({ tags: onChangeUpdateTags(this.state.tags, name, value) });
    }

    renderError = () => {
        if (this.state.error) {
            return (
                <div>
                    <ErrorComponent msg={this.state.error} type="error" />
                    <button
                        className="btn btn-outline-primary"
                        onClick={() => this.onBack()}
                    >
                        <FormattedMessage id="common.ok" defaultMessage="Ok" />
                    </button>
                </div>
            );
        }
        return null;
    }

    onSave = async () => {
        try {
            const uuid = this.getPlantUuid();
            let params: IUpdatePlantReq = {
                uuid: uuid,
                tags: this.state.tags,
                weight: this.state.weight
            };
            this.setState({ loading: true })

            const { error } = await getBackend2().manufacturing.updatePlant(params);

            if (error !== undefined) {
                await this.setState({
                    error: translate("common.error_save", "An error occured while saving data.") + " (" + error + ")",
                });
            } else {
                this.props.history.push(`/digital-twin/resources/plants/${uuid}`);
            }

        } catch (e) {
            this.setState({
                error: translate("common.error_save", "An error occured while saving data.")
            });
        }
    }

    onBack = () => {
        const uuid = this.getPlantUuid();
        this.props.history.push(`/digital-twin/resources/plants/${uuid}`);
    }

    /**
     * Handling change of weight input component.
     */
    handleInputChange = (event: Event) => {
        if (event.currentTarget instanceof HTMLInputElement || event.currentTarget instanceof HTMLSelectElement) {
            // read new values
            const target = event.currentTarget;
            let value = target.value;
            const name = target.name;

            this.setState(prev_state => {
                prev_state[name] = value;
                return prev_state;
            });
        }
    };

    render() {
        if (this.state.loading === true) {
            return (
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="white_box">
                                <div className="tab-content">
                                    {this.state.error ? this.renderError() :
                                        <div>
                                            <FormattedMessage
                                                id="common.add_line_loading"
                                                defaultMessage="Adding new line to the knowledge graph. Initialisation may take several minutes..."
                                            />
                                            <Loader small={true} />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="material-edit-container">
                    <EntityEditTemplate onSave={this.onSave} onBack={this.onBack} title={this.state.title}>
                        {this.renderError()}
                        <div className="form-group">
                            <label>
                                <FormattedMessage id="common.weight" defaultMessage="Weight" />:
                            </label>
                            <input name="weight" type="text" disabled="" className="form-control"
                                value={this.state.weight}
                                onChange={this.handleInputChange} />
                        </div>
                        <a href={getLinkForUserDocumentation(UserDocumentationLinksEnum.plants, getLang())}
                            target="_blank"
                            style={{ position: 'relative', float: 'right', paddingTop: '30px' }}>
                            <img src={"/img/question_light.svg"} style={{ paddingRight: "3px", paddingBottom: "2px" }} />
                            <FormattedMessage id="Header.menu.user_documentation" defaultMessage="User documentation" />
                            <img src={"/img/arrow_up_right_corner.svg"} style={{ position: "relative", width: "15px", height: "15px", paddingLeft: "3px", paddingBottom: "4px", bottom: "3px" }} />
                        </a>
                        <ReactTable
                            className="tags-react-table"
                            data={mapData(this.state.tags, updateTagsConfig(PLANT_TAGS, {}))}
                            columns={Columns}
                            onChangeCell={this.onChangeCell}
                            rowStyle={rowStyle}
                            initialState={getInitialState()}
                        />
                    </EntityEditTemplate>
                </div>
            )
        }

    }
}


export default PlantEdit;

