// @flow

// main imports
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import ErrorComponent from "./ErrorComponent";

// backend
import { getBackend } from "../lib/backend/Backend2";

type Props = {
    show: boolean,
    userUuid: string | null,
    title: string,
    username: string,
    onClose: Function
};

type State = {
    error: string | null,
    title: string,
    username: string
};

/**  Form to edit user's title and username. */
class UserEditData extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        const state: State = {
            error: null,
            title: this.props.title,
            username: this.props.username
        }
        this.state = state;
    }

    async handleSave() {
        try {
            const userUuid = this.props.userUuid;
            if (userUuid !== null) {

                const title = this.state.title === null ? this.props.title : this.state.title;
                const username = this.state.username === null ? this.props.username : this.state.username;

                await getBackend().users.userUpdate({
                    user_uuid: userUuid,
                    title,
                    username,
                });

                this.props.onClose();
            }
        } catch (err) {
            this.setState({ error: err.message });
        }
    }

    onClose() {
        this.props.onClose();
    }

    render() {
        if (this.props.title === undefined || this.props.username === undefined) {
            return null;
        }

        const { show, onClose } = this.props;
        return (
            <Modal
                show={show}
                backdrop="static"
                dialogClassName="modal-dialog-scrollable"
                onHide={onClose}
            >
                <Modal.Header>
                    <Modal.Title>
                        {this.props.title} - <FormattedMessage id="common.edit" defaultMessage="Edit data" />
                    </Modal.Title>
                    <button type="button" className="close" onClick={() => this.onClose()}>
                        <span aria-hidden="true">×</span>
                        <span className="sr-only"><FormattedMessage id="common.close" defaultMessage="Close" /></span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    {this.state.error && <ErrorComponent msg={this.state.error} type="error" />}

                    <div className="form-group">
                        <label><FormattedMessage id="common.title" defaultMessage="Title" /></label>
                        <input name="title" type="text" className="form-control"
                            autoComplete="off"
                            placeholder={this.props.title}
                            value={this.state.title === null ? this.props.title : this.state.title}
                            onChange={e => this.setState({ title: e.target.value })} />
                    </div>

                    <div className="form-group">
                        <label><FormattedMessage id="common.username" defaultMessage="Username" /></label>
                        <input name="username" type="text" className="form-control"
                            autoComplete="off"
                            placeholder={this.props.username}
                            value={this.state.username === null ? this.props.username : this.state.username}
                            onChange={e => this.setState({ username: e.target.value })} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary"
                        onClick={() => this.handleSave()}>
                        <FormattedMessage id="common.save" defaultMessage="Save" />
                    </button>
                    <button className="btn btn-outline-secondary"
                        onClick={() => this.onClose()}>
                        <FormattedMessage id="common.cancel" defaultMessage="Cancel" />
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default UserEditData;
