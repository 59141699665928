// @flow

// main imports
import React, { Component } from "react";
import Select from "react-select";
import { FormattedMessage } from "react-intl";

// backend
import { getBackend } from "../../lib/backend/Backend2";
import * as t from "../../lib/backend/manufacturing2.generated.types";
import {
    waitForManufacturingAsync
} from "../../lib/BusinessLogic";
import { ORDER_TYPE } from "../../lib/ManufacturingConsts.generated";

// import subcomponents
import ErrorComponent from "../ErrorComponent";
import { translate } from "../IntlProviderWrapper";
import { ORDER_TAGS } from "../../lib/ManufacturingTags.generated";

type SelectOptionItem = {
    label: string;
    value: string;
}

// defining types
type Props = {
    order_uuid: string
}

type State = {
    error: string,
    message: string,
    operation?: t.IOrderModelBase,
    production_versions: t.IProductionVersion[],
    saving: boolean,
    selected_production_version: string | null
}

/**
 * Give option to change production version on order(operation)
 */
class ProductionVersionChange extends Component<Props, State> {

    /**
     * Constructor.
     * @param {Props} props Props object.
     */
    constructor(props: Props) {
        super(props);
        const state: State = {
            error: "",
            message: "",
            operation: undefined,
            production_versions: [],
            saving: false,
            selected_production_version: null
        };
        this.state = state;
    }

    /**
     * Actual loading of data from backend.
     */
    async loadComponent() {
        try {
            const api = getBackend().manufacturing;
            await waitForManufacturingAsync();

            const { operations } = await api.getOrdersSimple({ uuids: [this.props.order_uuid] });
            const operation = operations[0];
            if (operation == undefined) {
                this.setState({
                    error: "Operation not found"
                });
            } else if (operation.order_type == ORDER_TYPE.plan) {
                // changing production version is only supported for plan orders
                const { production_versions } = await api.getProductionVersionForMaterialPlant({
                    material_uuid: operation.material_uuid,
                    plant_uuid: operation.plant_uuid
                });

                const selected_production_version = operation.tags[ORDER_TAGS.production_version] || null;
                this.setState({
                    error: "",
                    operation,
                    production_versions,
                    selected_production_version
                });
            } else {
                const selected_production_version = operation.tags[ORDER_TAGS.production_version] || null;
                this.setState({
                    error: "",
                    operation,
                    production_versions: [],
                    selected_production_version
                });
            }
        } catch (err) {
            this.setState({ error: err.message });
        }
    }

    /**
     * Mounting data for this component.
     */
    componentDidMount() {
        this.loadComponent();
    }

    handleInputChange = (newValue: string) => {
        return newValue ? newValue.trim() : newValue;
    };
    handleProdVerChange = (selected_item: SelectOptionItem) => {
        if (!selected_item) {
            this.setState({ selected_production_version: null });
        } else if (selected_item.value) {
            this.setState({ selected_production_version: selected_item.value });
        }
    }
    getOptions = (): SelectOptionItem[] => {
        return this.state.production_versions.map<SelectOptionItem>(x => ({
            label: `[${x.prodver_external_id}] ${x.title}`,
            value: x.prodver_external_id
        }));
    }
    handleSave = async () => {
        try {
            if (this.state.selected_production_version == null || this.state.operation == null) {
                this.setState({
                    saving: false,
                    message: "",
                    error: "Internal GUI error"
                });
                return;
            }
            this.setState({
                error: "",
                saving: true,
                message: translate("common.saving_data", "Saving data") + "..."
            });
            await getBackend().manufacturing.setProductionVersionForOrder({
                order_uuid: (this.state.operation ? this.state.operation.uuid : "-"),
                production_version_external_id: this.state.selected_production_version ?? "ERROR"
            });
            this.setState({
                saving: false,
                message: translate("common.data_saved_successfully", "Saving data")
            });
        } catch (err) {
            this.setState({
                saving: false,
                message: "",
                error: err.message
            });
        }
    }

    /**
     * Rendering JSX for current component.
     */
    render() {
        if (this.state.production_versions.length < 2) {
            return null;
        }
        const options = this.getOptions();
        const value = options.find(x => x.value == this.state.selected_production_version);
        return (
            <div>
                <h4>
                    <FormattedMessage id="Manufacturing.ProductionVersionChange.title" defaultMessage="Production version change" />
                </h4>
                <div className="p-1">
                    <Select
                        menuPosition="fixed"
                        onInputChange={this.handleInputChange}
                        value={value}
                        placeholder={translate("Manufacturing.ProductionVersionChange.placeholder", "Select new production version")}
                        options={options}
                        onChange={this.handleProdVerChange}
                        isClearable
                    />
                </div>
                <div className="p-1">
                    <ErrorComponent msg={this.state.error} type="error" />
                    <ErrorComponent msg={this.state.message} type="info" />
                    <button onClick={this.handleSave} disabled={this.state.saving || !this.state.selected_production_version}
                        className="btn btn-outline-primary">
                        <FormattedMessage id="common.save" defaultMessage="Save" />
                    </button>
                </div>

            </div>
        );
    }
}

export default ProductionVersionChange;