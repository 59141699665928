// @flow
import * as React from "react";
// $FlowFixMe
import "./left_menu_option.scss";
import { Link } from "react-router-dom";
import { DropdownChevronListItem } from "../react/ChevronListItem";
import * as BusinessLogic from "../../lib/BusinessLogic";

export type LeftMenuIconOptionProps = {
    Icon?: React.Node,
    Label: React.Node,
    to: string
};

const getIsActive = (to: string): boolean => {
    let active = window.location.pathname.includes(to);
    if (to === "/admin") {
        active = window.location.pathname === "/admin";
    }
    return active;
}

export const LeftMenuIconOption = (props: LeftMenuIconOptionProps) => {
    const active = getIsActive(props.to);
    const sys_flag = BusinessLogic.getSysFlag("env");
    return <Link
        data-sys-flag={sys_flag}
        to={props.to}
        className={`left-menu-icon-option ${active ? "active" : ""}`}
    >
        {props.Icon ? <div className="icon">
            {props.Icon}
        </div> : <span style={{marginLeft: "25px"}}>-</span>}
        <div className="label">
            {props.Label}
        </div>
    </Link>
}


type LeftMenuDropdownProps = {
    title: string,
    options: LeftMenuIconOptionProps[],
    to: string,
    url_prefix?: string,
    default_url?: string,
    fa_icon?: string,
    history: any
};
type LeftMenuDropdownState = {
    is_open: boolean
};

const dropdown_references = new Set<Object>();

export class LeftMenuDropdown extends React.Component<LeftMenuDropdownProps, LeftMenuDropdownState> {

    state = {
        is_open: false
    }

    node = null;

    componentDidUpdate() {
        const is_on_active_path = window.location.pathname.includes(this.props.url_prefix || "/resources");
        if (this.state.is_open && !is_on_active_path) {
            this.setState({
                is_open: false
            });
        } else if (!this.state.is_open && is_on_active_path) {
            this.setState({
                is_open: true
            });
        }
    }
    componentDidMount() {
        this.node = React.createRef();
        dropdown_references.add(this);
    }

    componentWillUnmount() {
        if (dropdown_references.has(this)) {
            dropdown_references.delete(this)
        }
    }

    onClose = () => {
        this.setState({ is_open: false })
    }

    onClick = () => {
        dropdown_references.forEach(self => {
            if (self.state.is_open) {
                self.onClose();
            }
        });
        if (this.state.is_open) {
            this.setState({ is_open: false });
        } else {
            this.props.history.push(this.props.default_url || "/digital-twin/resources/plants");
        }
    }

    getOptions = () => {
        return this.props.options.map((o: LeftMenuIconOptionProps) => {
            const Label = o.Label;
            const to = o.to;
            const is_active = getIsActive(to);
            return <DropdownChevronListItem
                icon={o.Icon}
                className="left-menu-chevron-item"
                text={Label}
                is_active={is_active}
                onClick={() => this.props.history.push(to)}
            />
        })
    }

    render() {
        const active = getIsActive(this.props.to);
        const sys_flag = BusinessLogic.getSysFlag("env");
        let className = `left-menu-dropdown ${active ? "active" : ""}`
        if (this.state.is_open) {
            className += " open"
        }

        return <div
            className={className}
            ref={this.node}
        >
            <div
                className={"left-menu-icon-option"}
                data-sys-flag={sys_flag}
                onClick={this.onClick}
            >
                <div classNam="icon">
                    <i class={"icon fas " + (this.props.fa_icon || "fa-edit")}></i>
                </div>
                <div className="label">
                    <span>{this.props.title}</span>
                    <i className="fas fa-caret-down"></i>
                </div>
            </div>
            {this.state.is_open && <div className="options">
                {this.getOptions()}
            </div>}
        </div>
    }
}
