// @flow
import React from "react";
import { Badge } from "../../../react/Icon/Badge";
import { connect } from "react-redux";

import type { ReduxState } from "../reducers/reducers";

export const DARK_RED = "#bb1e1d";

type Props = {
    num_unread_alerts: number
};
type State = {};

class BadgeNumberAlerts extends React.Component<Props, State> {

    render() {
        if (this.props.num_unread_alerts <= 0) {
            return <div style={{ marginLeft: "5px" }}>
                <i className="fas fa-chevron-down" style={{ fontSize: "10px" }}></i>
            </div>
        }

        const position = {
            position: "absolute",
            color: "white",
            fontSize: "11px",
            left: "4px",
            top: "1px"
        }
        const style = {
            color: DARK_RED,
            justifyContent: "center",
            marginLeft: "12px",
            position: "relative"
        };

        return <div style={{ position: "relative", marginRight: "3px", top: "-5px", display: "flex"}}>
            <Badge
                position={position}
                style={style}
                num_alerts={this.props.num_unread_alerts}
            />
            <div style={{marginLeft: "5px", fontSize: "6px", position: "absolute", top: "60%"}}>
                <i className="fas fa-chevron-down"></i>
            </div>
        </div>
    }
}

export default connect(
    (state: ReduxState) => {
        return {
            num_unread_alerts: state.alerts.num_unread_alerts
        }
    },
    null
)(BadgeNumberAlerts);
