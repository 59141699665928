// @flow
import type { PayloadAction } from "../../../redux/types";
import * as t from "../../../../lib/backend/manufacturing2.generated.types";
import { INSIGHT_TYPES } from "../../../../lib/ManufacturingTags.generated";
import { RESET_GANTT } from "./common";
import { ReportLogic } from "./BusinessLogic";
import { filterInsightsByType } from "../../../GanttChartUtils";
import { SET_INSIGHTS_ACTIONS_TYPES } from "./ganttChartStandalone";

import type { SetInsightTypesActions } from "./ganttChartStandalone";
import type { ResetGanttAction } from "./common";
import { deepClone } from "../../../../lib/Util";

export type InsightsMapped = Map<string, t.IEventDataEx[]>;
// InsightsMappedStockRequirements - where string key is stock requirement uuid
export type InsightsMapStockRequirements = Map<string, t.IEventDataEx>;

const prepareOrderInsightMap = (insights: t.IEventDataEx[]): InsightsMapped => {
    const order_insight_map: InsightsMapped = new Map();

    for(const insight of insights) {
        if (insight.type === INSIGHT_TYPES.tool_setup) { continue; }
        if (insight.type === INSIGHT_TYPES.downtime) { continue; }
        if (insight.type === INSIGHT_TYPES.man_downtime) { continue; }

        const insight_order_uuid = ReportLogic.getOrderUuidFromInsight(insight);
        const insight_order_uuids = ReportLogic.getOrderUuidsFromInsight(insight);
        if (insight_order_uuid != undefined) {
            const order_uuid = insight_order_uuid;
            const order_insights = order_insight_map.get(order_uuid) || [];
            order_insights.push(insight);
            order_insight_map.set(order_uuid, order_insights);
        } else if (insight_order_uuids != undefined) {
            // make sure we do not have duplicates
            const order_uuids_set = new Set(insight_order_uuids);
            for (const order_uuid of order_uuids_set) {
                const new_insight = deepClone(insight);
                const order_insights = order_insight_map.get(order_uuid) || [];
                order_insights.push(new_insight);
                order_insight_map.set(order_uuid, order_insights);
            }
        }
    }

    return order_insight_map;
}

const prepareStockRequirementsMap = (insights: t.IEventDataEx[]): InsightsMapStockRequirements => {
    const stock_requirement_insight_map: InsightsMapStockRequirements = new Map();

    for(const insight of insights) {
        if (insight.extra_data && insight.extra_data.delayed_requirements) {
            for (const requirement of insight.extra_data.delayed_requirements) {
                stock_requirement_insight_map.set(requirement.required_uuid, insight);
            }
        }
    }

    return stock_requirement_insight_map;
}

export const UPDATE_INSIGHTS_ACTION_NAME: "update_insights" = "update_insights";
export const UPDATE_CURRENT_DOWNTIMES_ACTION_NAME: "update_current_downtimes" = "update_current_downtimes";

type OrderLineMap = {
    line_title: string,
    line_uuid: string,
}
type UpdateInsightsActionName = {
    insights: t.IEventDataEx[],
    insight_types: string[],
}
export type OrderLineDiffMap = Map<string, OrderLineMap>; // order_uuid, OrderLineMap
export type UpdateInsightsAction = PayloadAction<typeof UPDATE_INSIGHTS_ACTION_NAME, UpdateInsightsActionName>;
export type UpdateCurrentDowntimesAction = PayloadAction<typeof UPDATE_CURRENT_DOWNTIMES_ACTION_NAME, t.ICurrentDowntime[]>;
export type InsightsActionsTypes = UpdateInsightsAction | UpdateCurrentDowntimesAction | ResetGanttAction | SetInsightTypesActions;


export type InsightsState = {
    insights: t.IEventDataEx[] | null,
    full_insights: t.IEventDataEx[] | null,
    insights_mapped: InsightsMapped | null,
    insights_map_stock_requirements: InsightsMapStockRequirements | null,
    original_insights: t.IEventDataEx[] | null,
    current_downtimes: t.ICurrentDowntime[]
}

const insights_initial_state: InsightsState = {
    insights: null,
    insights_mapped: null,
    insights_map_stock_requirements: null,
    original_insights: null,
    full_insights: null,
    insights_diff: null,
    current_downtimes: [ ]
}

export const insightsReducer = (
    state: InsightsState,
    action: InsightsActionsTypes
): InsightsState => {
    if (!state) {
        state = deepClone(insights_initial_state);
    }

    if (action.type === UPDATE_INSIGHTS_ACTION_NAME) {
        const { insights, insight_types } = action.data;
        // TODO - here we need to use the insight_types from standalone_gantt.insight_types
        const new_insights = filterInsightsByType(insight_types, insights);
        state.full_insights = insights;
        state.insights = new_insights;
        state.insights_mapped = prepareOrderInsightMap(new_insights);
        state.insights_map_stock_requirements = prepareStockRequirementsMap(new_insights);
        if (!state.original_insights) {
            state.original_insights = insights;
        }
        return { ...state };
    } else if (action.type === UPDATE_CURRENT_DOWNTIMES_ACTION_NAME) {
        state.current_downtimes = action.data;
        return { ...state };
    } else if (action.type === RESET_GANTT) {
        return deepClone(insights_initial_state);
    } else if (action.type === SET_INSIGHTS_ACTIONS_TYPES) {
        const insight_types = action.data;
        const full_insights = state.full_insights;
        if (full_insights) {
            // TODO - here we need to use the insight_types from standalone_gantt.insight_types
            const new_insights = filterInsightsByType(insight_types, full_insights);
            state.insights_mapped = prepareOrderInsightMap(new_insights);
            state.insights_map_stock_requirements = prepareStockRequirementsMap(new_insights);
            return { ...state, insights: new_insights }
        }
    }

    return state;
}

