// @flow
import * as React from "react";
import { getBackend as getBackend2 } from "../lib/backend/Backend2";
import * as t from "../lib/backend/datasources.generated.types";
import { stringCompare } from "../lib/Util";

type Props = {
    height: string | null
};

type State = {
    hits: Array<t.ITSeriesData>,
    title_filter: string,
    err_msg: string | null
};

class TimeSeriesSearch extends React.Component<Props, State> {
    handleChange: any;
    handleCheck: any;
    getInitialState: any;

    constructor(props: Props) {
        super(props);
        this.state = {
            hits: [],
            title_filter: "",
            err_msg: null
        };
    }

    componentDidMount() { }

    loadHits = async () => {
        try {
            this.setState({ err_msg: null, hits: [] });
            const res = await getBackend2().datasources.searchTSeries({ title: this.state.title_filter });
            const hits = res.tseries.sort((a, b) => stringCompare(a.title, b.title));
            this.setState({ hits });
        } catch (err) {
            console.log(err);
            this.setState({ err_msg: err.message });
        }
    };

    handleInputChange = (event: Event) => {
        if (event.currentTarget instanceof HTMLInputElement) {
            const target = event.currentTarget;
            const value = target.value;
            this.setState({ title_filter: value });
        }
    };

    handleSearch = (event: Event) => {
        this.loadHits();
    };

    renderHits = () => {
        return this.state.hits.map(x => (
            <li className="space10 nav-item" key={x.uuid}>
                <div className="bold"> {x.title} </div>
                <div className="light">{x.code}</div>
                <div className="light">{x.uuid}</div>
            </li>
        ));
    };

    render() {
        const style = { height: "", overflow: "scroll" };
        if (this.props.height !== null) {
            style.height = this.props.height;
        }
        return (
            <div className="white_box my-apps" style={style}>
                <h4>Timeseries search</h4>
                <div>
                    <input type="text" className="form-control search_bar width100p" placeholder="Search ..."
                        name="title_filter" value={this.state.title_filter} onChange={this.handleInputChange}
                        onBlur={this.handleSearch} />
                </div>
                {
                    this.state.hits.length === 0 &&
                    <div className="space10">No data</div>
                }
                {
                    this.state.hits.length > 0 &&
                    <ul className="space10 nav nav-tabs">
                        <div className="nav-tabs-parent" ref="nav">
                            {this.renderHits()}
                        </div>
                    </ul>
                }
            </div>
        );
    };
}

export default TimeSeriesSearch;
