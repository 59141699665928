// @flow

// main imports
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import ErrorComponent from "./ErrorComponent";

// backend
import { getBackend } from "../lib/backend/Backend2";
import * as t2 from "../lib/backend/users.generated.types";
import { validatePin } from "../lib/Auth";

type Props = {
    show: boolean,
    userUuid: string | null,
    onClose: Function
};

type State = {
    error: string | null,
    pin1: string,
    userInfo: t2.IGetSingleUserRes | null
};

/**  Form to edit user's pin. */
class UserEditPin extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        const state: State = {
            error: null,
            pin1: "",
            userInfo: null
        }
        this.state = state;
    }

    async componentDidMount() {
        await this.fetchData();
    }

    async componentDidUpdate(prevProps: Props) {
        if (prevProps.userUuid !== this.props.userUuid) {
            await this.fetchData();
        }
    }

    async fetchData() {
        try {
            const userUuid = this.props.userUuid;
            if (userUuid !== null) {
                const res_user_data = await getBackend().users.getSingleUser({ id: userUuid });
                this.setState({ userInfo: res_user_data });
            } else {
                this.setState({ userInfo: null });
                return;
            }
        } catch (err) {
            console.log(err);
            this.setState({ error: err.message });
        }
    }

    async handleSave() {
        try {
            const userUuid = this.props.userUuid;
            if (userUuid !== null) {
                const err = validatePin(this.state.pin1);
                if (err !== "") {
                    throw new Error(err);
                };

                await getBackend().users.setPinForUser({ pin: this.state.pin1, uuid: userUuid });
                this.setState({ error: "", pin1: "" });
            }
            this.props.onClose();
        } catch (err) {
            this.setState({ error: err.message });
        }
    }

    handleInputChange(event: Event) {
        if (event.currentTarget instanceof HTMLInputElement || event.currentTarget instanceof HTMLSelectElement) {
            // read new values
            const target = event.currentTarget;
            let value = target.value;
            this.setState({ pin1: value });
        }
    }

    render() {
        const { show, onClose } = this.props;
        return (
            <Modal
                show={show}
                backdrop="static"
                dialogClassName="modal-dialog-scrollable"
                onHide={onClose}
            >
                <Modal.Header>
                    <Modal.Title>
                        {this.state.userInfo && this.state.userInfo.title} - <FormattedMessage id="common.pin" defaultMessage="PIN" />
                    </Modal.Title>
                    <button type="button" className="close" onClick={onClose}>
                        <span aria-hidden="true">×</span>
                        <span className="sr-only"><FormattedMessage id="common.close" defaultMessage="Close" /></span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    {this.state.error && <ErrorComponent msg={this.state.error} type="error" />}
                    <div className="form-group">
                        <label>
                            <FormattedMessage id="common.pin" defaultMessage="PIN" />
                        </label>
                        <input name="pin1" type="text" disabled="" className="form-control"
                            placeholder=""
                            value={this.state.pin1}
                            onChange={e => this.handleInputChange(e)} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary"
                        onClick={() => this.handleSave()}
                        disabled={false}>
                        <FormattedMessage id="common.save" defaultMessage="Save" />
                    </button>
                    <button className="btn btn-outline-secondary"
                        onClick={onClose}>
                        <FormattedMessage id="common.cancel" defaultMessage="Cancel" />
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default UserEditPin;
