// @flow

// load subcomponents
import { MaterialParallelCreate } from "./MaterialParallel.create";

// backend
import { getBackend } from "../../lib/backend/Backend2";

/**
 * Form to create new line.
 */
export class MaterialParallelEdit extends MaterialParallelCreate {

    is_edit = true;

    async componentDidMount() {
        // get parallel material details
        const uuid = this.props.match.params.uuid;
        const { data } = await getBackend().manufacturing.parallelMaterialGet({ uuid });

        this.setState({
            uuid,
            external_id: data.external_id,
            title: data.title,
            child_materials: data.child_materials
        });
    }

    handleSubmit = async () => {
        try {
            await getBackend().manufacturing.parallelMaterialUpdate({
                child_material_uuids: this.state.child_materials.map(cm => cm.uuid),
                external_id: this.state.external_id,
                title: this.state.title,
                uuid: this.state.uuid
            });
            this.props.history.goBack();
        } catch (err) {
            this.setState({ error: err.message });
        }
    }
}

export default MaterialParallelEdit;
