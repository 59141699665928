// @flow

// main imports
import React, { Component } from "react";
import ReactRouterPropTypes from "react-router-prop-types";
import Toggle from "react-toggle";

// backend
import { stringCompare, createCsvAndDownload } from "../../lib/Util";
import { getBackend } from "../../lib/backend/Backend2";
import * as t from "../../lib/backend/manufacturing2.generated.types";
import {
    waitForManufacturingAsync, getPlantsForUser, filterLinesForUser
} from "../../lib/BusinessLogic";
import * as Auth from "../../lib/Auth";

// import subcomponents
import ErrorComponent from "../ErrorComponent";
import PeopleList from "./PeopleList";
import { includes } from "./PeopleList";
import PeopleDetails from "./PeopleDetails";
import { Link } from "react-router-dom";
import { translate } from "../IntlProviderWrapper";
import { ResourcesLayout } from "../MainContent";

// defining types
type Props = {
    match: ReactRouterPropTypes.match,
    history: ReactRouterPropTypes.history,
    location: ReactRouterPropTypes.location
}

type State = {
    people: t.IPersonData[],
    lines: t.ILineData[],
    plants: t.IPlantData[],
    plant_uuid: string;
    error: string,
    warning: string,
    selected: t.IPersonData | null,
    name_filter: string,
    active: boolean,
    show_inactive: boolean
}

function hasAnyIntersection(list1: string[], list2: string[]): boolean {
    for (const s1 of list1) {
        if (list2.indexOf(s1) >= 0) {
            return true;
        }
    }
    return false;
}

/**
 * Displaying people list.
 */
class People extends Component<Props, State> {

    /**
     * Constructor.
     * @param {Props} props Props object.
     */
    constructor(props: Props) {
        super(props);
        const state: State = {
            people: [],
            lines: [],
            plants: [],
            plant_uuid: "all",
            error: "",
            warning: "",
            selected: null,
            name_filter: "",
            active: true,
            show_inactive: false
        };
        this.state = state;
    }

    /**
     * Actual loading of data from backend.
     */
    async loadComponent() {
        try {
            // get available plants
            await waitForManufacturingAsync();
            const plants = getPlantsForUser();
            const plant_uuids = plants.map(x => x.uuid);
            const lines = filterLinesForUser(
                (await getBackend().manufacturing.getLines({})).lines, true
            );
            const res_people = await getBackend().manufacturing.getPeople({});
            const people = res_people.people
                .filter(person => hasAnyIntersection(plant_uuids, person.plant_uuids))
                .sort((a, b) => stringCompare(a.name, b.name));

            // make the first active person as selected
            let selected = this.selectPerson(this.state.name_filter, this.state.plant_uuid, people);

            let selected_uuid = "";
            // if a person is selected in URI, use that one
            if (this.props.match !== undefined) selected_uuid = this.props.match.params.uuid;
            people.forEach((p, i) => {
                if (p.uuid === selected_uuid) {
                    selected = p;
                }
            });

            this.setState({ plants, lines, people, selected, error: "", warning: "" });

        } catch (err) {
            this.setState({ error: err.message });
        }
    }

    /**
     * Select the first active person that matches all the given criteria.
     * @param {string} contentFilter Person search term.
     * @param {string} plantUuid Plant UUID.
     * @param {t.IPersonData[]} [people] List of people to select from.
     */
    selectPerson(contentFilter: string, plantUuid: string, people: t.IPersonData[] = this.state.people): t.IPersonData | null {
        for (let person of people) {
            if (includes(contentFilter, person) &&
                person.active &&
                (person.plant_uuids.includes(plantUuid) || plantUuid === "all")
            ) {
                return person;
            }
        }

        return null;
    }

    /**
     * Handling any change in input components.
     */
    handleInputChange = (event: Event) => {
        if (event.currentTarget instanceof HTMLInputElement || event.currentTarget instanceof HTMLSelectElement) {
            // read new values
            const target = event.currentTarget;
            let value = target.value;
            const name = target.name;
            let nameFilter = this.state.name_filter;
            let plantUuid = this.state.plant_uuid;

            // hack for toggle element
            if (name === "active") {
                //$FlowFixMe
                value = target.checked;
            } else if (name === "name_filter") {
                nameFilter = value;
            } else if (name === "plant_uuid") {
                plantUuid = value;
            }

            // update who is selected in case previosu one no longer visible
            const selected = this.selectPerson(nameFilter, plantUuid);

            // update new datasource placeholder
            this.setState({
                [name]: value,
                selected
            });
        }
    };

    /**
     * Handling click on a particular person. Remembering the selected person.
     *
     * @param {t.IPersonData} process
     */
    handlePeopleClick(person: t.IPersonData) {
        this.setState({ selected: person });

        // update url with each click
        let location: string = this.props.location.pathname;
        if (location.indexOf("/digital-twin/resources/people/") !== -1) {
            // if parameter is already given, change only the parameter in the path
            this.props.history.push(person.uuid);
        } else {
            // if parameter is not given, add "process"
            this.props.history.push("/digital-twin/resources/people/" + person.uuid);
        }
    }

    /**
     * Mounting data for this component.
     */
    componentDidMount() {
        this.loadComponent();
    }

    /**
     * Handling error from subordinate component.
     */
    handleToError = (str: string) => {
        this.setState({ error: str });
    }

    /**
     * Handling production line submit event.
     */
    handleSubmit = (e: Event) => {
        e.preventDefault();
    }

    onShowInActive = (e: Event) => {
        this.setState({ show_inactive: !this.state.show_inactive });
    }

    getActiveInactiveTooltipMsg = (): string => {
        if (!this.state.show_inactive) {
            return translate("Manufacturing.People.show_inactive", "Show Inactive");
        }

        return translate("Manufacturing.People.hide_inactive", "Hide Inactive");
    }

    onPrint = () => {
        // collect all tags
        const tag_names_set: Set<string> = new Set();
        this.state.people.forEach(x => Object.keys(x.tags).forEach(tag => tag_names_set.add(tag)));
        const tag_names: string[] = [...tag_names_set.keys()];

        // create header row
        const header = ["Name", "Active", "External ID", ...tag_names];
        const data = this.state.people
            .map(person => {
                const tag_values = tag_names
                    .map(tag => (person.tags[tag] !== undefined ? person.tags[tag] : ""));
                return [person.name, (person.active ? "Active" : "Inactive"), person.external_id, ...tag_values]
            });
        const csv = [header, ...data];
        createCsvAndDownload(csv);
    }

    /**
     * Rendering JSX for current component.
     */
    render() {
        const can_edit = Auth.isInRoles([Auth.ROLE_ADMIN, Auth.ROLE_POWER_USER, Auth.ROLE_DEMO_USER, Auth.ROLE_MAN_PLANNER, Auth.ROLE_MAN_SHOPFLOOR_MANAGER]);
        return (
                <ResourcesLayout>
                    <ResourcesLayout.Main>
                        <ResourcesLayout.List>
                            <ResourcesLayout.Search>
                                <div className="form-group form-group-stretch">
                                    <input type="text" className="form-control search_bar" placeholder={translate("common.search", "Search") + " ..."}
                                        name="name_filter" value={this.state.name_filter} onChange={this.handleInputChange} />
                                </div>
                                <div className="form-group">
                                    <select name="plant_uuid" className="form-control select_filter_control" value={this.state.plant_uuid} onChange={this.handleInputChange}>
                                        <option key={-1} value="all">
                                            {translate("Manufacturing.People.all_plants", "All plants")}
                                        </option>
                                        {
                                            this.state.plants.map((plant, i) => {
                                                return <option key={i} value={plant.uuid}>{plant.title}</option>
                                            })
                                        }
                                    </select>
                                </div>
                                {can_edit && <div className="form-group">
                                    <Link to="/digital-twin/resources/person/create" className="btn btn-inv-primary btn-icon btn-icon-add"
                                        title={translate("Manufacturing.People.add_new_person", "Add new person")}>
                                        <span className="sr-only">Add New</span>
                                    </Link>
                                </div>}
                                <div className="form-group">
                                    <div style={{ display: "flex", flexDirection: "column", fontSize: "8px", marginTop: "10px" }} title={this.getActiveInactiveTooltipMsg()}>
                                        <Toggle
                                            checked={this.state.show_inactive}
                                            onChange={this.onShowInActive}
                                            className="vertical-align-middle"
                                        />
                                        <span style={{ textAlign: "center" }}>{this.getActiveInactiveTooltipMsg()}</span>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div style={{ display: "flex", flexDirection: "column", fontSize: "8px" }} title={""}>
                                        <button
                                            type="button"
                                            className={"btn btn-short btn-secondary"}
                                            title={translate("common.export_csv", "Export CSV")}
                                            onClick={() => this.onPrint()}
                                        >
                                            <i className="fas fa-file-csv" />
                                        </button>
                                    </div>
                                </div>
                            </ResourcesLayout.Search>
                            {/*
                            <div className="form-group">
                                <div style={{ display: "flex", flexDirection: "column", fontSize: "8px" }} title={""}>
                                    <span className="ql-icon ql-icon-ellipsis" onClick={() => this.onPrint()}>
                                    </span>
                                </div>
                            </div>
                            */}
                        <PeopleList
                            narrow={false}
                            people={this.state.people}
                            selected={this.state.selected}
                            filter={this.state.name_filter}
                            activeFilter={this.state.active}
                            plantFilter={this.state.plant_uuid}
                            showInactive={this.state.show_inactive}
                            handlePeopleClick={(person) => { this.handlePeopleClick(person); }}
                            line_group_uuid=""
                        />
                    </ResourcesLayout.List>
                    <ResourcesLayout.Content>
                        <ErrorComponent msg={this.state.error} type="error" />
                        <ErrorComponent msg={this.state.warning} type="warning" />
                        {this.state.selected !== null && <PeopleDetails
                            lines={this.state.lines}
                            plants={this.state.plants}
                            selected={this.state.selected}
                        />}
                    </ResourcesLayout.Content>
                </ResourcesLayout.Main>
            </ResourcesLayout>
        )

    }
}

export default People;
