// @flow

import React from "react";
import { getNumber, getProducedRatio, prettyWorkerName, getEmptyProductionMatrix } from "./WeeklyRealizationCommon";
import type { ProductionModalParams, MicroplanSummaryFilterProps, ProductionMatrixObj } from "./WeeklyRealizationCommon";
import * as BusinessLogic from "../../lib/BusinessLogic";
import type { WeeklyReport } from "../../lib/Models";
import { dateFromWeekAndShift, myday, weekNumber } from "../../lib/Util";
import { translate } from "../IntlProviderWrapper";
import * as t from "../../lib/backend/manufacturing2.generated.types";

import type { ScrapUptimeMatrixLines } from "../manufacturing/WeeklyRealizationCommon"

type Props = {
    line_group_uuid: string,
    filter: MicroplanSummaryFilterProps,
    merged_data: WeeklyReport[],
    insights: t.IEventDataEx[][],
    onShowProductionModal: Function,
    is_export: boolean,
    is_print?: boolean,
    filter: MicroplanSummaryFilterProps,
    scrap_uptime_matrix?: ScrapUptimeMatrixLines
}

type RowsProps = {
    days: string[],
    scrap_uptime_matrix?: ScrapUptimeMatrixLines,
    insights: t.IEventDataEx[][]
} & Props;

type RowProps = {
    i: number,
    l: number,
    line_title: string,
    line_uuid: string,
    material_title: string,
    material_external_id: string,
    production_matrix: ProductionMatrixObj[],
    material_norm: t.IWeeklyProducedNormRec | null,
    is_export: boolean,
    filter: MicroplanSummaryFilterProps,
    onShowProductionModal: Function,
    uniqueKey: string,
    show_workers: boolean,
    is_print?: boolean,
    scrap_uptime_matrix?: ScrapUptimeMatrixLines,
    insights: t.IEventDataEx[][]
};

const getHowManyProductsDuringDownTime = (production_matrix) => {
    let sum_of_potencially_created = 0;

    for (const shift of production_matrix) {
        if (shift.potencially_created && shift.potencially_created > 0) {
            sum_of_potencially_created += shift.potencially_created;
        }
    }

    if (sum_of_potencially_created == 0) {
        return "";
    }
    return sum_of_potencially_created;
}

const getDownTimePerMaterial = (propsFromComponent, material_external_id: string, line_title: string) => {
    /** This functions returns how much downtime is per material on specific line. */
    /** It is shown in a column in Scrap / shift, where i show downtime / all_working_hours */
    /** It is shown in a column downtime / shift */

    let up_time = 0;
    let down_time = 0;

    if (!propsFromComponent.scrap_uptime_matrix || propsFromComponent.scrap_uptime_matrix === undefined || propsFromComponent.scrap_uptime_matrix === "") {
        return ""
    }

    const lines = propsFromComponent.scrap_uptime_matrix;
    if (lines === null || lines === undefined) {
        return "";
    }
    const specific_line = lines.get(propsFromComponent.line_uuid);
    if (!specific_line) {
        return "";
    }
    const working_shifts = specific_line.get("workingShifts");
    if (!working_shifts) {
        return "";
    }

    // get all working hours of this line
    for (let i = 0; i < 21; i++) {
        if (working_shifts[i].enabled) {
            up_time += 8;
        }
    }

    // get all down_times of this line
    if (propsFromComponent.material_external_id == material_external_id) {
        for (const insight of propsFromComponent.insights) {
            for (const insigh of insight) {
                if (insigh.tags.line_title == line_title && working_shifts[Number(insigh.tags.shift)].enabled) {
                    down_time += insigh.extra_data.duration;
                }
            }
        }
    }
    if (down_time == 0) {
        return "";
    }

    return getNumber(down_time, 2, false) + "h/" + getNumber(up_time, 2, false) + "h (" + (getNumber((down_time / up_time) * 100, 2, false)) + "%)";
}

const getScrapValues = (scrap_uptime_matrix: ScrapUptimeMatrixLines, line_uuid: string, material_external_id: string, shift_number: number) => {
    /**
     * For specific line, for specific material, for specific shift_number
     * returns sum of all scrap items
     */

    let sum_of_all_scrap = 0;

    if (scrap_uptime_matrix === "") {
        return 0;
    }

    const lines = scrap_uptime_matrix;

    if (lines === undefined || lines === null) {
        return 0;
    }

    const specific_line = lines.get(line_uuid);

    if (specific_line === undefined || specific_line === null) {
        return 0;
    }

    let material = specific_line.get("materials");
    if (material === undefined || material === null) {
        return 0;
    }

    let specific_material = material.get(material_external_id);
    if (!specific_material) {
        return 0;
    }

    for (const spe_mat of specific_material) {
        if (spe_mat.shift_number == shift_number) {
            sum_of_all_scrap += spe_mat.scrap;

        }
    }
    return Number(sum_of_all_scrap);

}

const getAllDowntimesPerShiftForAllMaterials = (scrap_uptime_matrix, insights) => {

    if (scrap_uptime_matrix === "" || !scrap_uptime_matrix) {
        return "";
    }

    const lines = scrap_uptime_matrix;
    if (lines === undefined || lines === null) {
        return "";
    }

    let shift_downtime = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    for (const shift of insights) {
        for (const insight of shift) {

            const specific_line = lines.get(insight.tags.line_uuid);
            if (specific_line === undefined || specific_line === null) {
                return "";
            }
            const working_shifts = specific_line.get("workingShifts");

            if (!working_shifts || !insight.tags || !insight.tags.shift) {
                return "";
            }

            if (working_shifts[insight.tags.shift].enabled) {
                shift_downtime[Number(insight.tags.shift)] += Number(insight.extra_data.duration);

            }
        }
    }

    return shift_downtime;
}

const getDownTime = (insights: t.IEventDataEx[][], scrap_uptime_matrix: ScrapUptimeMatrixLines, line_uuid: string, material_external_id: string, shift_number: number) => {

    if (!insights) {
        return 0;
    }

    if (scrap_uptime_matrix === "" || !scrap_uptime_matrix) {
        return 0;
    }

    const lines = scrap_uptime_matrix;
    if (lines === undefined || lines === null) {
        return 0;
    }

    const specific_line = lines.get(line_uuid);
    if (specific_line === undefined || specific_line === null) {
        return 0;
    }

    const working_shifts = specific_line.get("workingShifts");
    if (!working_shifts || working_shifts.length === 0) {
        return 0;
    }

    let i = 0;
    for (const working_shift of working_shifts) {
        if (i == shift_number) {

            if (!working_shift.enabled) {
                return 0;
            }
        }
        i++;
    }

    let sum_of_downtime = 0;

    for (const insight of insights[shift_number]) {
        if (insight.tags.line_uuid === line_uuid) {
            if (insight.tags.material_external_id === material_external_id) {
                sum_of_downtime += insight.extra_data.duration;
            }
        }
    }

    if (sum_of_downtime > 0) {
        return sum_of_downtime;
    }

    // If you havent returned anything yet, it means that cell is empty
    // Here we will check if there is some insight even tho in merged_data there is no record

    let list_of_insights = [];


    if (!insights) {
        return 0;
    }

    for (const insight_shift of insights) {

        if (!insight_shift || insight_shift === undefined) {
            return 0;
        }

        for (const insight of insight_shift) {
            //const  = insight_shift[insight_index];
            if (!insight || insight === undefined || insight.tags === undefined) {
                return 0;
            }

            if (insight.tags.line_uuid === line_uuid) {
                if (insight.tags.material_external_id === material_external_id) {
                    if (Number(insight.tags.shift) === Number(shift_number)) {
                        list_of_insights.push(insight);
                    }
                }
            }
        }
    }

    let suma = 0;

    for (let i = 0; i < list_of_insights.length; i++) {
        suma += list_of_insights[i].extra_data.duration;
    }

    return Number(suma);

}

const CellConfiguration = (name: string, is_export: ?boolean = false) => {
    let top;
    if (name.includes("header_")) {
        const el = document.querySelector(".views-filter-bar") || document.querySelector(".navigation-bar");
        if (el) {
            const rect = el.getBoundingClientRect();

            top = rect.top + rect.height;
        }
    }

    return {
        shifts_sum: {
            colSpan: 5,
            style: { textAlign: "center" }
        },
        shifts_sum_scrap: {
            colSpan: 3,
            style: { textAlign: "center" }
        },
        shifts_sum_downtime: {
            colSpan: 3,
            style: { textAlign: "center" }
        },
        shift_sum: {
            colSpan: 2,
            className: "text-center"
        },
        insight_render_val: {
            colSpan: 1,
            className: "text-center"
        },
        worker: {
            className: "text-center"
        },
        total_plan_export: {
            rowSpan: 4,
            className: "text-center"
        },
        total_ratio_export: {
            rowSpan: 4,
            className: "text-center"
        },
        total_prediction_export: {
            rowSpan: 4,
            className: "text-center"
        },
        total_plan_and_ratio: {
            rowSpan: 4,
            className: "text-center"
        },
        line_title: {
            rowSpan: 4,
            width: 100,
            className: "reduce-row-width text-center",
            style: { whiteSpace: "normal" }
        },
        product: {
            rowSpan: 4,
            className: "reduce-row-width text-center",
            style: { whiteSpace: "normal" }
        },
        material_norm: {
            rowSpan: 4,
            className: "reduce-row-width text-center",
            style: { whiteSpace: "normal" }
        },
        total_sum: {
            rowSpan: 4,
            className: "text-center"
        },
        const_1: {
            className: "text-center"
        },
        const_2: {
            className: "text-center"
        },
        const_3: {
            className: "text-center"
        },
        total: {
            className: "text-center"
        },
        weekly_realisation: {
            colSpan: 3,
            rowSpan: 3,
            className: "text-center"
        },
        weekly_realisation_scrap: {
            colSpan: 2,
            rowSpan: 3,
            className: "text-center"
        },
        weekly_realisation_downtime: {
            colSpan: 2,
            rowSpan: 3,
            className: "text-center"
        },
        weekly_planned: {
            colSpan: 1,
            rowSpan: 3,
            className: "text-center"
        },
        shift_done_sum_1: {
            colSpan: 2,
            className: "text-center"
        },
        weekly_done: {
            rowSpan: 4,
            className: "text-center"
        },
        weekly_potencially_created: {
            rowSpan: 4,
            className: "text-center"
        },
        shift_done_sum_2: {
            colSpan: 2,
            className: "text-center"
        },
        shift_done_sum_3: {
            colSpan: 2,
            className: "text-center"
        },
        header_line: {
            colSpan: 1,
            className: "text-center sticky",
            style: { width: "7%", top }
        },
        header_product: {
            colSpan: 1,
            className: "text-center sticky",
            style: { width: "7%", top }
        },
        header_norm: {
            colSpan: 1,
            className: "text-center sticky",
            style: { width: "7%", top }
        },
        header_planned: {
            colSpan: 1,
            className: "text-center sticky",
            style: { width: "7%", top }
        },
        header_shift: {
            className: "text-center sticky",
            style: { width: "6%", top },
            colSpan: 1
        },
        header_day: {
            className: "text-center sticky",
            colSpan: 2,
            style: { top }
        },
        header_total: {
            colSpan: !is_export ? 1 : 2,
            className: "sticky",
            style: { width: "7%", top }
        }
    }[name];
}

const Cell = (props) => {
    const cellConfig = CellConfiguration(props.name);

    return (
        <td {...cellConfig} {...props}>
            {props.children}
        </td>
    )
}

const Row = (props: RowProps) => {
    const { filter, onShowProductionModal } = props;
    let {
        uniqueKey, i, l, line_title, line_uuid, material_title, material_external_id,
        production_matrix, material_norm, is_export, show_workers, is_print, insights, scrap_uptime_matrix
    } = props;

    if (i !== 0) {
        line_title = "";
    };

    let tr_class = "";

    if (l > 1) {
        if (i === 0 || i < l) {
            tr_class = "tr-top";
        } else {
            tr_class = "tr-sum";
        }
    }

    // compute total statistics
    let total_sum = 0, total_plan = 0, total_prediction = 0, total_sum_scrap = 0;

    for (let production of production_matrix) {

        total_sum += production.val;
        total_plan += production.plan;
        total_prediction += production.prediction;
        if (production.scrap !== undefined && production.scrap !== null && production.scrap !== "") {
            total_sum_scrap += production.scrap;
        }


    }

    const total_ratio = getProducedRatio(total_sum, total_plan, is_export);
    const current_week = weekNumber(new Date());

    // prepare realization table cells
    const production_cells = production_matrix.map((el, i) => {
        const tooltip = `${myday(Math.floor(i / 3))}, ${(i % 3 + 1)} ${translate("common.shift_lc", "shift")}`;

        // flag planned productions (i.e. non-zero predictions)
        const flag_class_name = (el.status === "prediction" && el.prediction > 0) ? `tr-realization-flag-1-yellow` : "";
        const status_class_name = `td-weekly-realization-${el.status}`;
        const class_name = `${flag_class_name} ${status_class_name}${show_workers ? "" : " text-center"}`;

        // get times for production details
        let val = (el.val > 0) ? getNumber(el.val, 0, is_export) : "";
        let scrap = (el.scrap > 0) ? getNumber(el.scrap, 0, is_export) : "";

        let downtime = 0;
        if (props.filter.produced_scrap_downtime !== 'produced' && scrap_uptime_matrix !== undefined) {
            downtime = getDownTime(insights, scrap_uptime_matrix, line_uuid, material_external_id, i);
        }
        el.downtime = downtime;
        downtime = Number(downtime) > 0 ? getNumber(downtime, 2, false) + "h" : "";


        let worker = null;
        let worker_full_name = "";
        if ((el.val > 0 || el.plan > 0) && (el.status !== "prediction")) {
            worker_full_name = prettyWorkerName(el.worker, false);
            worker = prettyWorkerName(el.worker).join(", ");
        }

        // check if we have insights
        let insight_render = null;

        if (el.insights.length > 0) {
            const insight_tooltips = [];
            for (const insight of el.insights) {
                insight_tooltips.push(BusinessLogic.getInsightTooltipText(insight));
            }
            const hostname = window.location.host;
            const protocol = window.location.protocol;

            insight_render = <div className="image-alert" style={{ width: "5px", height: "100%" }}>
                <img src={`${protocol}//${hostname}/img/warning.svg`} alt="Insights"
                    title={insight_tooltips.join("\n")} />
            </div>
        }

        const productionModalData: ProductionModalParams = {
            line_title,
            line_uuid,
            material_external_id,
            start_time: dateFromWeekAndShift(filter.week, filter.year, i).getTime(),
            end_time: dateFromWeekAndShift(filter.week, filter.year, i + 1).getTime()
        };

        const hasWorker = worker !== "-" && worker;

        const cellProps = {
            is_export,
            title: tooltip,
            className: `text-center ${class_name}`,
            onClick: () => onShowProductionModal({ productionModalParams: productionModalData, show: true }),
            colSpan: show_workers ? 1 : 2
        };

        let workerCell = <td></td>;

        if (!is_export && hasWorker) {
            workerCell = (
                <Cell
                    name="worker"
                    {...cellProps}
                    style={{ overflow: 'hidden' }}
                >
                    <div style={{ resize: "both" }} title={worker_full_name}>
                        {worker}
                    </div>
                </Cell>
            );
        }


        //  show scrap or produced value in the Cell
        return <React.Fragment key={uniqueKey + "|" + i + "|c"}>
            <Cell
                name="insight_render_val"
                {...cellProps}
                style={{ overflow: 'hidden' }}
            >
                <div style={{ resize: "horizontal", whiteSpace: "nowrap", display: "flex", justifyContent: "space-between" }}>
                    {insight_render}
                    &nbsp;
                    <div style={{ textAlign: "center" }}>
                        <span>
                            {props.filter.produced_scrap_downtime === "produced" ? val
                                : props.filter.produced_scrap_downtime === "scrap" ? scrap
                                    : props.filter.produced_scrap_downtime === "downtime" ? downtime
                                        : ""}
                        </span>
                    </div>
                </div>
            </Cell>
            {show_workers && workerCell}
        </React.Fragment>
    });

    // break down by shift
    const production_cells_shifts = [[], [], []];
    const production_cells_shifts_sums = [];

    const scrap_cells_shifts = [[], [], []];
    const scrap_cells_shifts_sums = [];

    const downtime_cells_shifts = [[], [], []];
    const downtime_cells_shifts_sums = [];

    //let sum_of_all_potencially_created = 0;


    // for every column get values and sum at the end
    for (let i = 0; i < production_cells.length; i++) {

        if (props.filter.produced_scrap_downtime === 'produced') {
            production_cells_shifts[i % 3].push(production_cells[i]);
        } else if (props.filter.produced_scrap_downtime === 'scrap') {
            scrap_cells_shifts[i % 3].push(production_cells[i]);
        } else if (props.filter.produced_scrap_downtime === 'downtime') {
            downtime_cells_shifts[i % 3].push(production_cells[i]);
        }

        if (i % 3 === 0) {
            let sum = 0;

            if (props.filter.produced_scrap_downtime === 'produced') {
                for (let j = 0; j < 3; j++) {
                    if (production_matrix[i + j].val === undefined || production_matrix[i + j].val === NaN) {
                        production_matrix[i + j].val = 0;
                    }
                    sum += production_matrix[i + j].val;
                }
            } else if (props.filter.produced_scrap_downtime === 'scrap') {

                for (let j = 0; j < 3; j++) {
                    if (production_matrix[i + j].scrap === undefined || production_matrix[i + j].scrap === NaN) {
                        production_matrix[i + j].scrap = 0;
                    }
                    sum += production_matrix[i + j].scrap
                }
            } else if (props.filter.produced_scrap_downtime === 'downtime') {
                for (let j = 0; j < 3; j++) {
                    if (production_matrix[i + j].downtime === undefined || production_matrix[i + j].downtime === NaN) {
                        production_matrix[i + j].downtime = 0;
                    }
                    sum += production_matrix[i + j].downtime;
                }
            }

            if (sum == 0 || sum === NaN) {
                sum = "";
            }

            const element = (
                <React.Fragment key={uniqueKey + "|" + i + "|sum"}>
                    <td className="text-center" colSpan={show_workers ? 1 : 2}>
                        {
                            props.filter.produced_scrap_downtime === 'downtime' ?
                                getNumber(sum, 2, is_export) + (String(getNumber(sum, 2, is_export)) != "" && "h" || "")
                                :
                                getNumber(sum, 0, is_export)
                        }
                    </td>
                    {show_workers && <td></td>}
                </React.Fragment>
            );

            if (props.filter.produced_scrap_downtime === 'produced') {
                production_cells_shifts_sums.push(element);
            } else if (props.filter.produced_scrap_downtime === 'scrap') {
                scrap_cells_shifts_sums.push(element);
            } else if (props.filter.produced_scrap_downtime === 'downtime') {
                downtime_cells_shifts_sums.push(element);
            }
        }
    }


    let TotalPredictionCell = null;

    if (filter.week >= current_week) {
        TotalPredictionCell = <React.Fragment>
            <br /> {getNumber(total_prediction, 0, is_export)}
        </React.Fragment>
    }

    let potencially_created_per_material = getHowManyProductsDuringDownTime(production_matrix);

    //sum_of_all_potencially_created +=  potencially_created_per_material;

    return (
        <React.Fragment>
            <tr className={tr_class}>
                <Cell data-t="s" name="line_title">
                    {
                        is_print ? line_title :
                            <a href={"/manufacturing/lines/microplan/" + line_uuid + window.location.hash} style={{ whiteSpace: "normal" }}>
                                {line_title}
                            </a>
                    }
                </Cell>
                <Cell data-t="s" name="product">
                    <div style={{ resize: "both" }}>
                        {translate("Manufacturing.Planning.ident", "Ident")}: {material_external_id}
                        <br></br>
                        {material_title}
                    </div>
                </Cell>
                {props.filter.produced_scrap_downtime === "produced" &&
                    <Cell name="material_norm">
                        {
                            props.filter.produced_scrap_downtime === "produced" ?
                                material_norm !== null && material_norm.norm > 0 ? getNumber(material_norm.norm, 0, is_export) : "" :
                                props.filter.produced_scrap_downtime === "scrap" ?
                                    getDownTimePerMaterial(props, material_external_id, line_title) : ""
                        }
                    </Cell>
                }
                {props.filter.produced_scrap_downtime === "produced" &&
                    <Cell name="total_plan_and_ratio">
                        {props.filter.produced_scrap_downtime === "produced" ?
                            getNumber(total_plan, 0, is_export) :
                            props.filter.produced_scrap_downtime === "scrap" ?
                                "" : "" //getNumber(potencially_created_per_material, 0, false) : ""
                        }
                    </Cell>
                }

                <Cell name="const_1"> 1 </Cell>
                {
                    props.filter.produced_scrap_downtime === "produced" ?
                        production_cells_shifts[0] :
                        props.filter.produced_scrap_downtime === "scrap" ?
                            scrap_cells_shifts[0] :
                            props.filter.produced_scrap_downtime === "downtime" ?
                                downtime_cells_shifts[0]
                                : ""
                }
                {
                    is_export ?
                        <React.Fragment>
                            <Cell name="total_sum">{getNumber(total_sum, 0, is_export)}</Cell>
                            <Cell name="total_ratio_export">{getNumber(total_ratio, null, is_export)}</Cell>
                            <Cell name="total_prediction_export">{getNumber(total_prediction, 0, is_export)}</Cell>
                        </React.Fragment> :
                        <Cell name="total_sum"> {
                            props.filter.produced_scrap_downtime === 'produced' ?
                                total_sum > 0 ? getNumber(total_sum, 0, is_export) + " (" + getNumber((total_sum / total_plan) * 100, 2) + "%)" : "" :
                                props.filter.produced_scrap_downtime === 'scrap' ?
                                    total_sum_scrap == 0 ? "" : getNumber(total_sum_scrap, 0, is_export) + "/" + total_sum +
                                        " (" + getNumber((total_sum_scrap / total_sum) * 100, 2, is_export) + "%)" :
                                    props.filter.produced_scrap_downtime === 'downtime' ?
                                        getDownTimePerMaterial(props, material_external_id, line_title)
                                        : ""
                        }
                            {!props.filter.produced_scrap_downtime === 'scrap' && "(" + total_ratio + ")"}
                            {!props.filter.produced_scrap_downtime === 'scrap' && TotalPredictionCell}
                        </Cell>
                }
                {
                    props.filter.produced_scrap_downtime === "downtime" && false &&
                    <Cell name="total_sum"> {

                        getNumber(potencially_created_per_material, 0, false)
                    }
                    </Cell>
                }
            </tr>
            <tr className={tr_class} >
                <Cell name="const_2">2</Cell>
                {
                    props.filter.produced_scrap_downtime === 'produced' ?
                        production_cells_shifts[1] :
                        props.filter.produced_scrap_downtime === 'scrap' ?
                            scrap_cells_shifts[1] :
                            props.filter.produced_scrap_downtime === 'downtime' ?
                                downtime_cells_shifts[1]
                                : ""
                }

            </tr>
            <tr className={tr_class}>
                <Cell name="const_3">3</Cell>
                {
                    props.filter.produced_scrap_downtime === 'produced' ?
                        production_cells_shifts[2] :
                        props.filter.produced_scrap_downtime === 'scrap' ?
                            scrap_cells_shifts[2] :
                            props.filter.produced_scrap_downtime === 'downtime' ?
                                downtime_cells_shifts[2]
                                : ""
                }
            </tr>
            <tr className="materials-shift-sums">
                <Cell name="total">{translate("common.total", "Total")}</Cell>
                {
                    props.filter.produced_scrap_downtime === 'produced' ?
                        production_cells_shifts_sums :
                        props.filter.produced_scrap_downtime === 'scrap' ?
                            scrap_cells_shifts_sums :
                            props.filter.produced_scrap_downtime === 'downtime' ?
                                downtime_cells_shifts_sums
                                : ""
                }
            </tr>
        </React.Fragment >
    )
}

// merge simulation data with SQL data
const Rows = (props: RowsProps) => {
    const { merged_data, filter, onShowProductionModal, is_export, days, insights, is_print } = props;

    // get flag from the line group to see if we should render workers next to productions
    const show_workers = BusinessLogic.getLineGroupTagBool(props.line_group_uuid, "weekly_realization_show_workers", true);

    // we assume that rows are grouped by lines/materials from the SQL call in the backend
    const lines_materials_items: Map<string, Map<string, WeeklyReport[]>> = new Map();
    for (const item of merged_data) {
        let line_materials_items = lines_materials_items.get(item.line_uuid);
        if (line_materials_items === undefined) {
            line_materials_items = new Map();
            lines_materials_items.set(item.line_uuid, line_materials_items);
        }

        let line_material_items = line_materials_items.get(item.material_external_id);
        if (line_material_items === undefined) {
            line_material_items = [];
            line_materials_items.set(item.material_external_id, line_material_items);
        }

        line_material_items.push(item);
    }

    // map numeric status to text status
    const status_labels = ["confirmed", "unconfirmed", "prediction"];
    let shift_done = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let shift_scrap = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    let shift_downtime = getAllDowntimesPerShiftForAllMaterials(props.scrap_uptime_matrix, insights);

    let weekly_downtime = 0;
    for (let i = 0; i < 21; i++) {
        weekly_downtime += shift_downtime[i];
    }

    let weekly_done = 0;
    let weekly_scrap = 0;
    let weekly_planned = 0;
    let weekly_potencially_created = 0;

    let maxProductCellWidth = 20;

    const mat = [];
    lines_materials_items.forEach((line_materials_items, line_uuid) => {
        let line_title = "";

        // This are materials on specific line
        let i = 0;
        line_materials_items.forEach((line_material_items, material_external_id) => {
            // and here we go for each material in that specific line

            if (material_external_id.length > maxProductCellWidth) {
                maxProductCellWidth = material_external_id.length;
            }

            // make a copy of the object (otherwise it stays the same)
            const material_production: ProductionMatrixObj[] = getEmptyProductionMatrix(21);
            let material_title = "";

            // get normative for the material if we have it
            const material_norm = (line_material_items.length > 0) ? line_material_items[0].norm :
                { capacity_factor: 0.0, norm: 0.0, norm_base_units: 0.0, norm_break_time_hours: 0.0, norm_machine_time: 0.0 }

            line_material_items.forEach((material_line) => {
                line_title = material_line.line_title;
                material_title = material_line.material_title;
                if (material_line.shift_time && material_line.shift_time.shift_number !== undefined) {
                    const shift_number = material_line.shift_time.shift_number;
                    material_production[shift_number].val = (material_line.status !== undefined && material_line.status < 2) ? material_line.sum : 0;
                    material_production[shift_number].scrap = props.scrap_uptime_matrix === undefined ? 0 : getScrapValues(props.scrap_uptime_matrix, material_line.line_uuid, line_material_items[0].material_external_id, shift_number);
                    material_production[shift_number].plan = material_line.plan ? material_line.plan : 0;
                    material_production[shift_number].downtime = (props.scrap_uptime_matrix === undefined || insights === undefined) ? 0 : getDownTime(insights, props.scrap_uptime_matrix, material_line.line_uuid, material_line.material_external_id, shift_number);

                    if (material_line.plan !== undefined) {
                        material_production[shift_number].potencially_created = Number(Number(material_production[shift_number].downtime) * (Number(material_line.plan) / 8));
                    }

                    material_production[shift_number].duration = material_line.worker && material_line.worker.length !== 0 ? 8 : 0;
                    material_production[shift_number].prediction = (material_line.status !== undefined && material_line.status === 2) ? material_line.sum : 0;
                    material_production[shift_number].status = status_labels[material_line.status !== undefined ? material_line.status : 0];
                    material_production[shift_number].worker = material_production[shift_number].worker.concat(material_line.worker);

                    // get total sums
                    shift_done[shift_number] += material_production[shift_number].val;
                    shift_scrap[shift_number] += Number(material_production[shift_number].scrap);

                    weekly_done += material_production[shift_number].val;
                    weekly_scrap += Number(material_production[shift_number].scrap);

                    weekly_potencially_created += Number(material_production[shift_number].potencially_created);

                    weekly_planned += material_production[shift_number].plan;
                }
            });

            // append insights to material_production
            for (const [shift_number, shift_insights] of insights.entries()) {
                for (const insight of shift_insights) {
                    if(line_uuid === insight.tags.line_uuid) {
                        if (insight.tags.material_external_id === undefined ||
                            insight.tags.material_external_id === material_external_id) {
                            material_production[shift_number].insights.push(insight);
                        }
                    }
                }
            }

            mat.push(
                <Row
                    is_print={is_print}
                    key={line_uuid + "|" + material_external_id}
                    uniqueKey={line_uuid + "|" + material_external_id}
                    i={i}
                    l={line_materials_items.size}
                    line_title={line_title}
                    line_uuid={line_uuid}
                    material_title={material_title}
                    material_external_id={material_external_id}
                    production_matrix={material_production}
                    material_norm={material_norm}
                    is_export={is_export}
                    filter={filter}
                    onShowProductionModal={onShowProductionModal}
                    show_workers={show_workers}
                    insights={props.insights}
                    scrap_uptime_matrix={props.scrap_uptime_matrix}
                />
            );

            i += 1;
        });
    });

    const headerProductEl = document.querySelector("th[name=header_product]");

    if (headerProductEl) {
        headerProductEl.style.width = (maxProductCellWidth * 8) + "px";
    }

    // This is the sum up at the of the page.
    return <React.Fragment>
        {mat}

        <tr className="tr-sum">
            {
                props.filter.produced_scrap_downtime === "produced" ?
                    <Cell name="weekly_realisation">{translate("Header.menu.weekly_realization", "Weekly realization")}</Cell>
                    : props.filter.produced_scrap_downtime === "scrap" ?
                        <Cell name="weekly_realisation_scrap">{translate("Header.menu.weekly_realization", "Weekly realization")}</Cell>
                        : props.filter.produced_scrap_downtime === "downtime" ?
                            <Cell name="weekly_realisation_downtime">{translate("Header.menu.weekly_realization", "Weekly realization")}</Cell>
                            : null
            }
            {props.filter.produced_scrap_downtime === "produced" ?
                <Cell name="weekly_planned">
                    {getNumber(weekly_planned, 0, is_export)}
                </Cell> : null
            }
            <Cell name="const_1">1</Cell>
            {days.map((_, i) =>
                <Cell name="shift_done_sum_1" key={`sum_1_${i}`}> {
                    props.filter.produced_scrap_downtime === "produced" ? getNumber(shift_done[i * 3], 0, is_export) || 0 :
                        props.filter.produced_scrap_downtime === "scrap" ? getNumber(shift_scrap[i * 3], 0, is_export) :
                            props.filter.produced_scrap_downtime === "downtime" ? getNumber(shift_downtime[i * 3], 2, is_export) + "h" :
                                ""}
                </Cell>)
            }
            <Cell name="weekly_done">
                {props.filter.produced_scrap_downtime === "produced" ? getNumber(weekly_done, 0, is_export) || 0 :
                    props.filter.produced_scrap_downtime === "scrap" ? getNumber(weekly_scrap, 0, is_export) :
                        props.filter.produced_scrap_downtime === "downtime" ? getNumber(weekly_downtime, 2, is_export) + "h" :
                            ""}
            </Cell>
            {
                props.filter.produced_scrap_downtime === "downtime" && false &&
                <Cell name="weekly_potencially_created">
                    {getNumber(weekly_potencially_created, 0, false)}
                </Cell>
            }
        </tr>
        <tr className="tr-sum">
            <Cell name="const_2">2</Cell>
            {days.map((_, i) => <Cell name="shift_done_sum_2" key={`sum_2_${i}`}> {
                props.filter.produced_scrap_downtime === "produced" ? getNumber(shift_done[i * 3 + 1], 0, is_export) || 0 :
                    props.filter.produced_scrap_downtime === "scrap" ? getNumber(shift_scrap[i * 3 + 1], 0, is_export) :
                        props.filter.produced_scrap_downtime === "downtime" ? getNumber(shift_downtime[i * 3 + 1], 2, is_export) + "h" :
                            ""}
            </Cell>)}
        </tr>
        <tr className="tr-sum">
            <Cell name="const_3">3</Cell>
            {days.map((_, i) => (<Cell name="shift_done_sum_3" key={`sum_3_${i}`}> {
                props.filter.produced_scrap_downtime === "produced" ? getNumber(shift_done[i * 3 + 2], 0, is_export) || 0 :
                    props.filter.produced_scrap_downtime === "scrap" ? getNumber(shift_scrap[i * 3 + 2], 0, is_export) :
                        props.filter.produced_scrap_downtime === "downtime" ? getNumber(shift_downtime[i * 3 + 2], 2, is_export) + "h" :
                            ""}
            </Cell>))}
        </tr>
        <tr className="tr-sum">
            {
                props.filter.produced_scrap_downtime === "produced" ?
                    <Cell name="shifts_sum">{translate("common.shift_sum", "Shifts sum")}</Cell>
                    : props.filter.produced_scrap_downtime === "scrap" ?
                        <Cell name="shifts_sum_scrap">{translate("common.shift_sum", "Shifts sum")}</Cell>
                        : props.filter.produced_scrap_downtime === "downtime" ?
                            <Cell name="shifts_sum_downtime">{translate("common.shift_sum", "Shifts sum")}</Cell>
                            : null
            }

            {
                days.map((_, i) => (
                    <Cell name="shift_done_sum_3" key={`sum_3_${i}`}>
                        {
                            props.filter.produced_scrap_downtime === "produced" ? getNumber(shift_done[i * 3] + shift_done[i * 3 + 1] + shift_done[i * 3 + 2], 0, is_export) :
                                props.filter.produced_scrap_downtime === "scrap" ? getNumber(shift_scrap[i * 3] + shift_scrap[i * 3 + 1] + shift_scrap[i * 3 + 2], 0, is_export) :
                                    props.filter.produced_scrap_downtime === "downtime" ? getNumber(shift_downtime[i * 3] + shift_downtime[i * 3 + 1] + shift_downtime[i * 3 + 2], 2, is_export) + "h" :
                                        ""}
                    </Cell>
                ))
            }
        </tr>
    </React.Fragment>
}

const WeeklyRealizationShift = (props: Props) => {
    const headerLineProps = CellConfiguration("header_line");
    const headerProductProps = CellConfiguration("header_product");
    const headerNormProps = CellConfiguration("header_norm");
    const headerPlannedProps = CellConfiguration("header_planned");
    const headerShiftProps = CellConfiguration("header_shift");
    const headerDayProps = CellConfiguration("header_day");
    const headerTotalProps = CellConfiguration("header_total", props.is_export);

    const days = Array(7).fill(0).map((el, i) => myday(i, 3));
    const show_predicted = props.filter.week >= weekNumber(new Date());

    const PredictedColumnHeader = <span style={{ wordBreak: "break-all", fontSize: "10px" }}>
        <br />{translate("common.predicted2", "and predicted")}
    </span>;


    return <div className="white_box charts" style={{ maxWidth: "unset", minWidth: "fit-content" }}>
        <table id="microplan-summary-shift-table" className="table sticky" style={{ tableLayout: "relative", width: "100%" }}>
            <colgroup>
                <col span={headerLineProps.colSpan} className={"breakdown-border-thick"}></col>
                <col span={headerProductProps.colSpan} className={"breakdown-border-thick"}></col>
                <col span={headerNormProps.colSpan} className={"breakdown-border-thick"}></col>
                <col span={headerPlannedProps.colSpan} className={"breakdown-border-thick"}></col>
                <col span={headerShiftProps.colSpan} className={"breakdown-border breakdown-total"}></col>
                <col span={headerDayProps.colSpan} className={"breakdown-border"}></col>
                <col span={headerDayProps.colSpan} className={"breakdown-border"}></col>
                <col span={headerDayProps.colSpan} className={"breakdown-border"}></col>
                <col span={headerDayProps.colSpan} className={"breakdown-border"}></col>
                <col span={headerDayProps.colSpan} className={"breakdown-border"}></col>
                <col span={headerDayProps.colSpan} className={"breakdown-border breakdown-weekend"}></col>
                <col span={headerDayProps.colSpan} className={"breakdown-weekend"}></col>
                <col span={headerTotalProps.colSpan} className={"breakdown-border-thick-left breakdown-border breakdown-total"}></col>
                {props.is_export ? <col span={1} className={"breakdown-border-thick-left breakdown-border breakdown-total"}></col> : null}
            </colgroup>
            <thead>
                <tr>
                    <th {...headerLineProps}>{translate("common.line", "Line")}</th>
                    <th name="header_product" {...headerProductProps}>{translate("Manufacturing.MicroplanSummary.product", "Product")}</th>
                    {props.filter.produced_scrap_downtime === 'produced' &&
                        <th {...headerNormProps}>
                            {
                                props.filter.produced_scrap_downtime === 'produced' ?
                                    translate("common.norm", "Norm") : ""
                                /*
                                : props.filter.produced_scrap_downtime === 'scrap' ?
                                    translate("common.downtime", "Downtime") + " / " + translate("common.uptime", "Uptime")
                                    : ""
                                    */
                            }
                            <br />
                            {props.filter.produced_scrap_downtime === 'produced' && " / " + translate("common.shift", "Shift")}
                        </th>
                    }
                    {props.filter.produced_scrap_downtime === 'produced' &&
                        <th {...headerNormProps} className="text-center">
                            {props.filter.produced_scrap_downtime === 'scrap' ? translate("common.could_be_done", "Could be done")
                                : translate("common.planned_plural", "Planned")}<br />
                            {props.filter.produced_scrap_downtime === 'produced' && " / " + translate("common.week", "Week")}
                        </th>
                    }

                    <th {...headerShiftProps}>{translate("common.shift", "Shift")}</th>
                    {days.map((day, i) => {
                        return <th name={`header_day_${i}`} {...headerDayProps} className="text-center" key={`header_${i}`}>{day}</th>;
                    })}
                    <th {...headerTotalProps} className="text-center">
                        {translate("common.total", "Total")}
                        {show_predicted && !props.is_export && PredictedColumnHeader}
                    </th>
                    {props.filter.produced_scrap_downtime === "downtime" && false &&
                        <th {...headerTotalProps} className="text-center">
                            {translate("common.could_be_done", "Could be done")}
                            {show_predicted && !props.is_export && PredictedColumnHeader}
                        </th>
                    }
                    {props.is_export && show_predicted ? <th>{PredictedColumnHeader}</th> : null}
                </tr>
            </thead>
            <tbody>
                <Rows
                    is_print={props.is_print}
                    key={"rows"}
                    days={days}
                    merged_data={props.merged_data}
                    filter={props.filter}
                    insights={props.insights}
                    is_export={props.is_export}
                    onShowProductionModal={props.onShowProductionModal}
                    line_group_uuid={props.line_group_uuid}
                    scrap_uptime_matrix={props.scrap_uptime_matrix}
                />
            </tbody>
        </table>
    </div>
}

export default WeeklyRealizationShift;
