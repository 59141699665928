// @flow

import React from 'react';
import {Tabs, Tab} from 'react-bootstrap';
import type {Node} from 'react';

type TabComponent = {
    title: string,
    element: Node
}

type Props = {
    tabs: Array<TabComponent>,
    details1?: Node,
    details2?: Node
}

const ModalTabs = (props: Props) => {
    if (!props.tabs) return null;

    return (
        <div className="modal-tabs">
            <div className="modal-tabs__details1">
                {props.details1}
            </div>
            <hr />
            <div className="modal-tabs__details2">
                {props.details2}
            </div>
            <div className="modal-tabs__tabs">
                <Tabs id="modal-tabs" defaultActiveKey={"operations0"}>
                    {props.tabs.map((tab, idx) => {
                        const eventKey         = `operations${idx}`;
                        const {title, element} = tab;

                        return (
                            <Tab bsStyle="tabs" eventKey={eventKey} title={title} key={idx}>
                                {element}
                            </Tab>
                        );
                    })}
                </Tabs>
            </div>
        </div>
    );
}

export default ModalTabs;