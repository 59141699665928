// @flow
import { niceNumber, createHtmlAndDownload } from "../../lib/Util";
import { ORDER_STATUS } from "../../lib/ManufacturingConsts.generated";
import type { IGetOrdersSimpleReq } from "../../lib/backend/manufacturing2.generated.types";
import { getBackend as getBackend2 } from "../../lib/backend/Backend2";

export async function exportToolTransfer(order_external_id: string, process_num: string): Promise<void> {

    // get order data from db using order_external_id
    const params: IGetOrdersSimpleReq = {
        order_external_id,
        process_num,
        status: ORDER_STATUS.open
    };
    let orders = await getBackend2().manufacturing.getOrdersSimple(params);

    // get properties from order->operation data
    const plant_title: string = orders.operations[0].line.plant_title;
    const plant_uuid: string = orders.operations[0].line.plant_uuid;
    const material_uuid: string = orders.operations[0].material_uuid;
    const current_time: string = (new Date()).toLocaleString();
    let material_external_id: string = orders.operations[0].material_external_id;
    let material_title: string = orders.operations[0].material_title;
    const person_capacity: string = orders.operations[0].norm_machine_time != 0 ?
        niceNumber((orders.operations[0].norm_person_time/orders.operations[0].norm_machine_time)*100, 0) : "";
    const line_external_id: string = orders.operations[0].line.external_id
    const line_title: string = orders.operations[0].line.title;
    const process_title: string = orders.operations[0].title;

    // get tool_external_id from orders tag
    const tool_external_id: string = orders.operations[0].tags && orders.operations[0].tags.production_tool_external_id ?
        orders.operations[0].tags.production_tool_external_id : "";

    // get old_tool_external id from tag of the material
    let old_tool_external_id: string = "";
    let tool_uuid = "";
    if (tool_external_id != "") {
        const tools = await getBackend2().manufacturing.searchMaterials({ external_id: tool_external_id });
        old_tool_external_id = tools.data.length > 0 && tools.data[0].tags.old_material_no ? tools.data[0].tags.old_material_no : "";
        tool_uuid = tools.data.length > 0 ? tools.data[0].uuid : "";
    }

    // get old_material_external_id from tag of material
    const materials = await getBackend2().manufacturing.searchMaterials({ external_id: material_external_id });
    const old_material_external_id: string = materials.data.length > 0 && materials.data[0].tags.old_material_no ? materials.data[0].tags.old_material_no : "";
    const earliest_start: string = new Date(orders.operations[0].earliest_start).toLocaleDateString() || "";
    const earliest_end: string = new Date(orders.operations[0].earliest_end).toLocaleDateString() || "";
    const quantity_total: string = orders.operations[0].quantity_total.toString();

    // get takt_time from material_plant tags
    const material_plant_tags = await getBackend2().manufacturing.getMaterialPlantTags({ plant_uuid, uuid: material_uuid });
    const takt_time = (material_plant_tags.data && material_plant_tags.data.length > 0 && material_plant_tags.data[0].tags.takt_time)
        ? material_plant_tags.data[0].tags.takt_time : "";

    // get storage_bin from material_plant tags for tools
    const tool_plant_tags = await getBackend2().manufacturing.getMaterialPlantTags({ plant_uuid, uuid: tool_uuid });
    const storage_bin = (tool_plant_tags.data && tool_plant_tags.data.length > 0 && tool_plant_tags.data[0].tags.storage_bin)
        ? tool_plant_tags.data[0].tags.storage_bin : "";

    // check if this is a child of a parallel order
    // if yes parent_order_external_id will be different from empty string
    // material_title and material_external_id are set to parent material
    let parent_order_external_id: string = "";
    let combined_orders_external_ids = "";
    let combined_material_external_ids = "";
    if (orders.operations[0].parent_order_uuid && orders.operations[0].parent_order_uuid != "") {
        const parent_order_uuid: string = orders.operations[0].parent_order_uuid;
        const parent_order = await getBackend2().manufacturing.getOrdersSimple({ uuids: [ parent_order_uuid ] });
        if (parent_order.operations && parent_order.operations.length > 0) {
            parent_order_external_id = parent_order.operations[0].external_id;
            material_title = parent_order.operations[0].material_title;
            material_external_id = parent_order.operations[0].material_external_id;
        }
        const child_orders = await getBackend2().manufacturing.getOrdersSimple({ parent_order_uuid });
        for (const child_order of child_orders.operations) {
            combined_orders_external_ids += child_order.external_id + " ";
            combined_material_external_ids += child_order.material_external_id + " ";
        }
    }

    // get bom for the order or parent order
    const order_for_bom_external_id = parent_order_external_id != "" ? parent_order_external_id : order_external_id;
    const bom = await getBackend2().manufacturing.getInputMaterials({ order_external_id: order_for_bom_external_id });
    const input_materials = bom.input_materials;

    // set bom input materials html
    let input_materials_html = "";
    for (const material of input_materials) {
        const row = `
            <tr>
                <td>${material.input_material_external_id}</td>
                <td>${material.input_material_title}</td>
                <td>${niceNumber(Math.abs(material.factor * parseFloat(quantity_total)), 3)}</td>
                <td>${material.input_measurement_unit}</td>
            </tr>
        `;
        input_materials_html += row;
    }
    const html = `
        <html>
            <head>
                <title>Prenosnica</title>
                <meta charSet="utf-8" />
                <style>
                    body {
                        font-family: "Arial", "Helvetica", "sans-serif";
                    }
                    body, table, p, div {
                        font-size: 12px;
                    }
                    body { -webkit-print-color-adjust: exact; }
                    h2 {
                        text-align:center;
                    }
                    table {
                        border-collapse: collapse;
                    }
                    .border_left {
                        border-left: 1.6px solid black;
                    }
                    .border_right {
                        border-right: 1.6px solid black;
                    }
                    .td_border_bottom {
                        border-bottom: 1.6px solid black;
                    }
                    tr.border_bottom td {
                        border-bottom: 1.6px solid black;
                    }
                    tr.border_top td {
                        border-top: 1.6px solid black;
                    }
                    td {
                        padding-top:6px;
                        padding-bottom:6px;
                        padding-right:6px;
                        padding-left:6px;
                    }
                    td:first-child {
                        padding-left:6px;
                        <padding-right:6px></padding-right:6px>;
                    }
                </style>
            </head>
            <body onload="window.print()">
                <h2>PRENOSNICA ORODIJ</h2>
                <table style="width: 100%;">
                    <tbody>
                        <tr class="border_bottom">
                            <td>${plant_title}</td>
                            <td><br></td>
                            <td><br></td>
                            <td>Datum: ${current_time}</td>
                        </tr>
                        <tr class="border_bottom">
                            <td>Izdelek: ${material_external_id}</td>
                            <td><b>${material_title}</b></td>
                            <td>Koda izdelka: ${old_material_external_id}</td>
                            <td>Stroj: ${line_external_id}</td>
                        </tr>
                        <tr class="border_bottom">
                            <td>Koda orodja: ${old_tool_external_id}</td>
                            <td>Ident orodja: ${tool_external_id}</td>
                            <td>Sklad. lok.: ${storage_bin}</td>
                            <td>Kos. or.: ${takt_time}</td>
                        </tr>
                        <tr class="border_bottom">
                            <td>Začetek: ${earliest_start}</td>
                            <td>Delovni nalog: ${order_external_id}</td>
                            <td>Količina: ${quantity_total}</td>
                            <td>Zasedenost delavca: ${person_capacity}%</td>
                        </tr>
                        <tr class="border_bottom">
                            <td>Sestavljeni ident: ${combined_material_external_ids}</td>
                            <td><br></td>
                            <td>Sestavljen delavni nalog: ${combined_orders_external_ids}</td>
                            <td><br></td>
                        </tr>
                        <tr class="border_bottom">
                            <td>Skladiščnik:</td>
                            <td>Orodje prevzel:</td>
                            <td>Končano:</td>
                            <td>Orodje vrnil:</td>
                        </tr>
                    </tbody>
                </table>
                <br /> <br /> <br />
                <table style="width: 100%;">
                    <tbody>
                        <tr class="border_top border_bottom">
                            <td style="width: 26.8673%;">OCENA STANJA ORODJA</td>
                            <td style="width: 3%;"><br></td>
                            <td style="width: 53.6332%; text-align: center;" class="border_left" colspan="3">KONTROLA ZADNJIH KOSOV</td>
                            <td style="width: 16.4194%;" rowspan="3" class="border_left">Orodje predano v skladišče:</td>
                        </tr>
                        <tr class="border_bottom">
                            <td style="width: 20%;"><br></td>
                            <td style="width: 3%;"><br></td>
                            <td style="width: 17%;" class="border_left">Kakovost:</td>
                            <td style="width: 40%;">Podpis kontrolorja:</td>
                            <td style="width: 20%;">Datum:</td>
                        </tr>
                        <tr>
                            <td style="width: 26.8673%;">Za skladiščenje</td>
                            <td style="width: 3%;">□</td>
                            <td style="width: 53.6332%;" colspan="3" class="td_border_bottom border_left"><br></td>
                        </tr>
                        <tr>
                            <td style="width: 26.8673%;">Za manjše popravilo</td>
                            <td style="width: 3%;">□</td>
                            <td colspan="3" style="width: 53.6332%;" class="border_left">OPOMBE - OPIS OKVARE</td>
                            <td style="width: 16.4194%;"><br></td>
                        </tr>
                        <tr>
                            <td style="width: 26.8673%;">Za večje popravilo</td>
                            <td style="width: 3%;">□</td>
                            <td colspan="3" style="width: 53.6332%;" class="border_left"><br></td>
                            <td style="width: 16.4194%;"><br></td>
                        </tr>
                        <tr class="border_bottom">
                            <td style="width: 26.8673%;">Iztrošeno</td>
                            <td style="width: 3%;">□</td>
                            <td colspan="3" style="width: 53.6332%;" class="border_left"><br></td>
                            <td style="width: 16.4194%;"><br></td>
                        </tr>
                    </tbody>
                </table>

                <h2>DELOVNI LIST S SEZNAMOM MATERIALA</h2>
                <table style="width: 100%;">
                    <tbody>
                        <tr class="border_bottom">
                            <td>${plant_title}</td>
                            <td><br></td>
                            <td><br></td>
                            <td style="width: 30%;">Datum: ${current_time}</td>
                        </tr>
                        <tr class="border_bottom">
                            <td>Delovni nalog: ${order_external_id}</td>
                            <td>Količina: ${quantity_total}</td>
                            <td><br /></td>
                            <td>Planiran konec: ${earliest_end}</td>
                        </tr>
                        <tr class="border_bottom">
                            <td>Izdelek: ${material_external_id}</td>
                            <td><b>${material_title}</b></td>
                            <td><br></td>
                            <td>Koda izdelka: ${old_material_external_id}</td>
                        </tr>
                        <tr class="border_bottom">
                            <td>Koda orodja: ${old_tool_external_id}</td>
                            <td>Ident orodja: ${tool_external_id}</td>
                            <td>Sklad. lok.: ${storage_bin}</td>
                            <td>Kos. or.: ${takt_time}</td>
                        </tr>
                    </tbody>
                </table>
                <table style="width: 100%;">
                    <tbody>
                        <tr class="border_bottom">
                            <td class="border_left">Stroj</td>
                            <td class="border_left">Naziv operacije</td>
                            <td class="border_left">Začetek</td>
                            <td style="width: 20%;" class="border_left border_right">Zaključek</td>
                        </tr>
                        <tr class="border_bottom">
                            <td style="width: 35%;" class="border_left">${line_external_id} ${line_title}</td>
                            <td style="width: 45%;" class="border_left">${process_title}</td>
                            <td style="width: 10%;" class="border_left">${earliest_start}</td>
                            <td style="width: 10%;" class="border_left border_right">${earliest_end}</td>
                        </tr>
                    </tbody>
                </table>
                <p>MATERIALI:</p>
                <table style="width: 70%;">
                    <tbody>
                        <tr class="border_bottom">
                            <td>Ident</td>
                            <td>Naziv</td>
                            <td>Količina</td>
                            <td>EM</td>
                        </tr>
                        ${input_materials_html}
                    </tbody>
                </table>
            </body>
        </html>
    `;

    createHtmlAndDownload(html, "prenosnica.html");
}
