// @flow
// $FlowFixMe
import React, { useEffect, useState } from "react";
import ModalTabs from "../react/ModalTabs/ModalTabs";
import * as BusinessLogic from "../../lib/BusinessLogic";
import * as t from "../../lib/backend/manufacturing2.generated.types";
import * as r from "../../lib/backend/reports.generated.types";
import { Modal, Table } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import * as Auth from "../../lib/Auth";
import { getBackend } from "../../lib/backend/Backend2";
import { translate } from "../IntlProviderWrapper";
import { toSloDateTimeString, niceDate, niceNumber, niceNumberEstimate, niceDateTime } from "../../lib/Util";
import { ORDER_TYPE, ORDER_GENERATED_BY } from "../../lib/ManufacturingConsts.generated";
import ParallelMD04 from './ParallelMD04';
import TranslatedMaterialTitle from '../TranslatedMaterialTitle';
import Barcode from "react-barcode";
import { ORDER_TAGS, PLANT_TAGS } from "../../lib/ManufacturingTags.generated"
import Authorization from "../Authorization";
import EditOrderModal from "./EditOrderModal";
import SimpleTable from "../react/SimpleTable";
import { cleanExternalId } from "./OrderTableProduction";

import type { Node } from "react";

// $FlowFixMe
import "../../styles/order_table_production_modal.scss";

type IOrdersModelBaseExtended = t.IOrderModelBase & {
    quantity_produced?: number,
    quantity_scrap?: number
}

type IViewOrderBomInputExtended = t.IViewOrderBomInput & {
    process_num: string,
    input_quantity: number
}

const operationDescriptionFields: Array<$Keys<$Shape<IOrdersModelBaseExtended>>> = [
    "external_id",
    "external_operation_id",
    "material_external_id",
    "quantity_total",
    "status"
];

const operationDataFields: Array<$Keys<$Shape<IOrdersModelBaseExtended>>> = [
    "title",
    "external_id",
    "external_operation_id",
    "process_num",
    "quantity_total",
    "output_stock",
    "status",
    "norm_base_units",
    "norm_break_time_hours",
    "norm_clearing_time",
    "norm_machine_time",
    "norm_person_time",
    "norm_tool_setup_time",
    "created_at",
    "capacity_factor",
    "initial_earliest_start",
    "initial_earliest_end",
    "earliest_start",
    "earliest_end",
    "time_start",
    "time_end",
    "override_capacity_factor",
    "wait_time_hours",
    "produced_available_in_hours",
    "plant_uuid",
    "process_uuid",
    "sequence_weight",
    "sim_ignore_earliest_start",
    "skip_sim",
    "uuid",
    "quantity_scrap",
    "quantity_produced"
];

const bomInputDataFields: Array<$Keys<$Shape<IViewOrderBomInputExtended>>> = [
    "process_num",
    "input_material_external_id",
    "input_material_title",
    "factor",
    "input_quantity",
    "input_measurement_unit",
    "input_withdrawn_quantity",
    "stock_location_external_id",
    "backflush",
];

const Translations = {
    "title": ["OrderTableProduction.title", "Operation title"],
    "external_id": ["OrderTableProduction.external_id", "Order ID"],
    "external_operation_id": ["OrderTableProduction.external_operation_id", "Operation ID"],
    "process_num": ["OrderTableProduction.process_num", "Process number"],
    "earliest_start": ["OrderTableProduction.earliest_start", "ERP start"],
    "earliest_end": ["OrderTableProduction.earliest_end", "ERP completion"],
    "quantity_total": ["OrderTableProduction.quantity_total", "Operation total quantity"],
    "status": ["OrderTableProduction.status", "Operation status"],
    "norm_base_units": ["OrderTableProduction.norm_base_units", "Norm base"],
    "norm_break_time_hours": ["OrderTableProduction.norm_break_time_hours", "Break time"],
    "norm_clearing_time": ["OrderTableProduction.norm_clearing_time", "Clearing time"],
    "norm_machine_time": ["OrderTableProduction.norm_machine_time", "Norm (machine)"],
    "norm_person_time": ["OrderTableProduction.norm_person_time", "Norm (person)"],
    "norm_tool_setup_time": ["OrderTableProduction.norm_tool_setup_time", "Tool setup time"],
    "created_at": ["OrderTableProduction.created_at", "Created at"],
    "capacity_factor": ["OrderTableProduction.capacity_factor", "Capacity factor"],
    "initial_earliest_end": ["OrderTableProduction.initial_earliest_end", "Initial earliest end time"],
    "initial_earliest_start": ["OrderTableProduction.initial_earliest_start", "Initial earliest start time"],
    "output_stock": ["OrderTableProduction.output_stock", "Outputs to stock"],
    "wait_time_hours": ["OrderTableProduction.wait_time_hours", "End of operation wait time"],
    "produced_available_in_hours": ["OrderTableProduction.produced_available_in_hours", "Produced available in hours"],
    "override_capacity_factor": ["OrderTableProduction.override_capacity_factor", "Override capacity factor"],
    "plant_uuid": ["OrderTableProduction.plant_uuid", "Plant UUID"],
    "process_uuid": ["OrderTableProduction.process_uuid", "Process UUID"],
    "sequence_weight": ["OrderTableProduction.sequence_weight", "Sequence weight"],
    "sim_ignore_earliest_start": ["OrderTableProduction.sim_ignore_earliest_start", "Ignore earliest start"],
    "skip_sim": ["OrderTableProduction.skip_sim", "Skip simulation"],
    "uuid": ["OrderTableProduction.uuid", "UUID"],
    "bom_version": ["OrderTableProduction.bom_version", "BOM version"],
    "generated_by": ["OrderTableProduction.generated_by", "Generated by"],
    "id": ["OrderTableProduction.id", "ID"],
    "minimal_overlap_hours": ["OrderTableProduction.minimal_overlap_hours", "Minimal overlap hours"],
    "minimal_overlap_quantity": ["OrderTableProduction.minimal_overlap_quantity", "Minimal overlap quantity"],
    "order_type": ["OrderTableProduction.order_type", "Order type"],
    "pipeline_production": ["OrderTableProduction.pipeline_production", "Pipeline production"],
    "required_overlap": ["OrderTableProduction.required_overlap", "Required overlap"],
    "time_end": ["OrderTableProduction.time_end", "End time"],
    "time_start": ["OrderTableProduction.time_start", "Start time"],
    "line": {
        "external_id": ["OrderTableProduction.line_external_id", "Line ID"],
        "uuid": ["OrderTableProduction.line_uuid", "Line UUID"],
        "title": ["common.line", "Line"],
    },
    "material_external_id": ["OrderTableProduction.material_external_id", "Material ID"],
    "material_title": ["OrderTableProduction.material_title", "Material title"],
    "material_uuid": ["OrderTableProduction.material_uuid", "Material UUID"],
    "skip_sim_internal": ["OrderTableProduction.skip_sim", "Skip sim"],
    "parent_order_uuid": ["OrderTableProduction.parent_order_uuid", "Parent operation"],
    "measurement_unit": ["OrderTableProduction.measurement_unit", "Measurement unit"],
    "tags": ["common.tags", "Tags"],
    "quantity_scrap": ["common.scrap", "Scrap"],
    "quantity_produced": ["OrderTableProduction.quantity_produced", "Quantity produced"],
    // bom inputs
    "input_quantity": ["OrderTableProduction.input_quantity", "Input quantity"],
    "factor": ["OrderTableProduction.factor", "Factor"],
    "input_material_external_id": ["OrderTableProduction.material_external_id", "Material ID"],
    "input_material_title": ["OrderTableProduction.material_title", "Material title"],
    "input_measurement_unit": ["OrderTableProduction.measurement_unit", "Measurement unit"],
    "input_withdrawn_quantity": ["OrderTableProduction.withdrawn_quantity", "Withdrawn quantity"],
    "stock_location_external_id": ["OrderTableProduction.stock_location_external_id", "Stock location ID"],
    "backflush": ["OrderTableProduction.backflush", "Backflush"]
}

const getTags = (tags: t.ITags): Node => {
    return (
        <div className="order-table-production-modal__tags">
            {Object.keys(tags).map(key => {
                const value = tags[key];
                const classes = ["badge"];
                if (value === "true") {
                    classes.push("badge-success");
                } else if (value === "false") {
                    classes.push("badge-danger");
                } else {
                    classes.push("badge-secondary");
                }

                return (
                    <span key={key} className={classes.join(" ")}>
                        {`${key}: ${value}`}
                    </span>
                );
            })}
        </div>
    );
}

const getBooleanValue = (value: boolean): string => {
    return value ? translate("common.yes", "Yes") : translate("common.no", "No");
}

const getValue = (operation: $Shape<t.IOrderModelBase>, field: string): string => {
    // chunk orders are ordered right below their full parents and as such do not need
    // to display process number, to make it more obvious they are "children"
    if (operation.order_type === ORDER_TYPE.chunk && field === "process_num") { return ""; }

    if (operation[field] === undefined || operation[field] === null) { return "N/A"; }

    if (typeof operation[field] === "string" || typeof operation[field] === "number") {
        const value = new Date(operation[field]);

        if (["created_at"].includes(field)) {
            return toSloDateTimeString(value);
        }

        if (["created_at", "earliest_start", "earliest_end", "initial_earliest_end", "initial_earliest_start"].includes(field)) {
            return niceDate(value);
        }

        if (["time_start", "time_end"].includes(field)) {
            return niceDateTime(value);
        }
    }

    // chunks generated by manual entry subcontractor do not have produced and scrap numbers
    if (operation.generated_by === ORDER_GENERATED_BY.manual_entry_subcontractor) {
        if ([ "quantity_produced", "quantity_scrap" ].includes(field)) {
            return "";
        }
    }

    const number_fields = [
        "produced_available_in_hours",
        "norm_break_time_hours",
        "norm_machine_time",
        "norm_person_time",
        "quantity_scrap",
        "quantity_produced",
        "quantity_total",
        "wait_time_hours"
    ];
    if (number_fields.includes(field)) {
        return niceNumber(operation[field], 3);
    }

    if (field === "output_stock" && operation.produced_available_in_hours > 0) {
        return `${getBooleanValue(operation[field])} (+${niceNumber(operation.produced_available_in_hours, 2)}h)`;
    }

    if (typeof operation[field] === "boolean") {
        return getBooleanValue(operation[field]);
    }

    const material_external_id = operation["material_external_id"];
    const material_title = operation["material_title"];
    if (field === "material_title") {
        return TranslatedMaterialTitle({ material_external_id, material_title });
    }

    if (field === "status") {
        return translate(`OrderShifts.order_${operation[field]}`, `${operation[field]}`);
    }

    return operation[field].toString();
}

const getValueBom = (operation: $Shape<IViewOrderBomInputExtended>, field: string): string => {
    const number_fields = [
        "input_quantity",
        "input_withdrawn_quantity"
    ];
    if (number_fields.includes(field)) {
        return niceNumber(operation[field], 2);
    }

    const small_number_fields = [
        "factor"
    ];
    if (small_number_fields.includes(field)) {
        return niceNumberEstimate(operation[field], 0.05);
    }

    if (typeof operation[field] === "boolean") {
        return getBooleanValue(operation[field]);
    }

    return operation[field].toString();
}

type MD04ButtonProps = {
    parent_order_uuid: string,
    material_title: string,
    material_external_id: string,
    onSelectedMaterialChange: (material_external_id: string) => void
}
type MD04ButtonState = {
    show_modal: boolean,
    plant_external_id: string
}

class MD04Button extends React.Component<MD04ButtonProps, MD04ButtonState> {

    state = {
        show_modal: false,
        plant_external_id: ""
    }

    componentDidMount() {
        this.load();
    }

    load() {
        const plant_uuid = this.getPlantUuid();
        const plants = BusinessLogic.getPlantsForUser();
        if (plants) {
            const plant = plants.find(p => p.uuid === plant_uuid)
            if (!plant) return;

            this.setState({ plant_external_id: plant.external_id });
        }
    }

    getPlantUuid = () => {
        return localStorage.getItem("last-plant");
    }

    onCloseModal = () => this.setState({ show_modal: false });

    onShowModal = () => this.setState({ show_modal: true });

    render() {
        const plant_uuid = this.getPlantUuid();
        if (!plant_uuid) return null;

        return <React.Fragment>
            <button
                className="btn btn-primary ml-2"
                onClick={this.onShowModal}
                title={translate("common.tooltip_planningtable_requirements", "Show Stock/Requirements situation")}
                style={{ float: "right" }}
            >
                {translate("common.requirements", "Requirements")}
            </button>
            <ParallelMD04
                onCloseModal={this.onCloseModal}
                plant_uuid={plant_uuid}
                show_modal={this.state.show_modal}
                material_external_id={this.props.material_external_id}
                plant_external_id={this.state.plant_external_id}
                material_title={this.props.material_title}
                parent_order_uuid={this.props.parent_order_uuid}
                onSelectedMaterialChange={this.props.onSelectedMaterialChange}
            />
        </React.Fragment>
    }
}


type OperationDetailsProps = {
    operations: Array<IOrdersModelBaseExtended>,
    selected_order_uuid: string,
    show_columns?: Array<$Keys<$Shape<IOrdersModelBaseExtended>>>
}

const OperationDetails = (props: OperationDetailsProps) => {
    if (!props.operations) return null;

    const show_columns = props.show_columns;
    let show_selected_columns = false;
    let operationDataFields2 = operationDataFields;
    if (show_columns && show_columns.length > 0) {
        show_selected_columns = true;
        operationDataFields2 = show_columns;
    }

    const getColumnName = (field: string): string => {
        if (field === "skip_sim" && show_selected_columns) {
            return translate("common.scheduled_operation_abbr", "Scheduled operation");
        }

        return Translations[field] && translate(Translations[field][0], Translations[field][1]);
    };

    const getColumnValue = (operation: $Shape<t.IOrderModelBase>, field: string): string => {
        if (field === "skip_sim" && show_selected_columns) {
            return getBooleanValue(!operation[field]);
        }

        return getValue(operation, field);
    };

    return (
        <Table className="operation-details__table">
            <thead>
                <tr>
                    {operationDataFields2.map((field, idx) => <th key={idx}>{getColumnName(field)}</th>)}
                    {<th key={operationDataFields2.length}>{Translations.line && translate(Translations.line.title[0], Translations.line.title[1])}</th>}
                    {<th key={operationDataFields2.length + 1}>{Translations.line && translate(Translations.line.external_id[0], Translations.line.external_id[1])}</th>}
                    {<th key={2}>{translate("edit.comment", "Comment")}</th>}
                </tr>
            </thead>
            <tbody>
                {
                    props.operations.map((operation, idx) => {
                        return (
                            <tr key={idx}
                                uuid={operation.uuid}
                                order_type={operation.order_type}
                                external_id={operation.external_id}
                                process_num={operation.process_num}
                                // selected operation is hightlighted and chunks have different style
                                className={operation.uuid === props.selected_order_uuid ? "tr-sum" :
                                    operation.order_type === ORDER_TYPE.chunk ? "tr-light" : ""}>
                                {operationDataFields2.map((field, idx) => <td key={idx}>{getColumnValue(operation, field)}</td>)}
                                {<td key={"b"}>{operation.line.title}</td>}
                                {<td key={"c"}>{operation.line.external_id}</td>}
                                {<td key={"d"}>{operation.tags.comment}</td>}
                            </tr>
                        )
                    })
                }
            </tbody>
        </Table>
    )
}


type BomInputDetailsProps = {
    bom_inputs: Array<IViewOrderBomInputExtended>,
    show_columns?: Array<$Keys<$Shape<IViewOrderBomInputExtended>>>
}

const BomInputDetails = (props: BomInputDetailsProps) => {
    if (!props.bom_inputs) return null;

    const show_columns = props.show_columns;
    let bomInputDataFields2 = bomInputDataFields;
    if (show_columns && show_columns.length > 0) {
        bomInputDataFields2 = show_columns;
    }

    return (
        <Table className="operation-details__table">
            <thead>
                <tr>
                    {bomInputDataFields2.map((field, idx) => <th key={idx}>{Translations[field] && translate(Translations[field][0], Translations[field][1])}</th>)}
                </tr>
            </thead>
            <tbody>
                {
                    props.bom_inputs.map((bom_input, idx) => {
                        return (
                            <tr key={idx}>
                                {bomInputDataFields2.map((field, idx) => <td key={idx}>{getValueBom(bom_input, field)}</td>)}
                            </tr>
                        )
                    })
                }
            </tbody>
        </Table>
    )
}

type BarcodeFieldProps = {
    operation_external_id: string,
    plant_uuid: string
}

const BarcodeField = (props: BarcodeFieldProps) => {
    const plant = BusinessLogic.getPlantsForUser().find(p => p.uuid === props.plant_uuid);
    if (!plant) {
        return null;
    }

    let show_barcode = false;

    if (plant.tags) {
        show_barcode = plant.tags[PLANT_TAGS.show_barcode] === "true";
    }

    return <div>
        {show_barcode && <Barcode format="CODE39" value={"CL" + cleanExternalId(props.operation_external_id)} />}
    </div>
}

type DescriptionProps = {
    operation: $Shape<t.IOrderModelBase>,
    barcode_operation: $Shape<t.IOrderModelBase>,
    override_description_fields?: any,
    hide_barcode_operation: boolean
}
const Description = (props: DescriptionProps) => {
    const { operation, override_description_fields, barcode_operation } = props;

    if (!operation) return null;

    let operationDescriptionFields2 = operationDescriptionFields;
    if (override_description_fields && override_description_fields.length > 0) {
        operationDescriptionFields2 = override_description_fields;
    }

    const comment = operation.tags.comment;
    return (<React.Fragment>
        <div className="order-table-production-modal__description" uuid={operation.uuid} order_type={operation.order_type}>
            {operationDescriptionFields2.map((field, idx) => {
                const name = Translations[field] && translate(Translations[field][0], Translations[field][1]);

                return (
                    <div className="single-description" key={idx}>
                        <span className="title">{name}</span>
                        <span>{getValue(operation, field)}</span>
                        <br />
                    </div>
                );
            })}
            {comment && <div className="single-description">
                <span className="title">{translate("common.comment", "Comment")}</span>
                <span>{comment}</span>
                <br />
            </div>}
            {!override_description_fields && <React.Fragment>
                <div className="single-description">
                    <span className="title">{translate(Translations.line.title[0], Translations.line.title[1])}</span>
                    <span>{operation.line.title}</span>
                    <br />
                </div>
                <div className="single-description">
                    <span className="title">{translate(Translations.line.uuid[0], Translations.line.uuid[1])}</span>
                    <span>{operation.line.uuid}</span>
                    <br />
                </div>
            </React.Fragment>}
        </div>
        <div style={{ textAlign: "center" }}>
            {
                !props.hide_barcode_operation && barcode_operation &&
                <BarcodeField
                    operation_external_id={barcode_operation.external_operation_id}
                    plant_uuid={barcode_operation.plant_uuid}
                />
            }
        </div>
    </React.Fragment>
    )
}

/************************************************************************************************ */

type OrderProductionsProps = {
    order_uuid: string,
    show_technical_details: boolean

};
type OrderProductionsState = {
    data: t.IProducedModel[]
};
const initialOrderProductionsState: OrderProductionsState = {
    data: []
};
const OrderProductions = (props: OrderProductionsProps) => {
    const [state, setState] = useState(initialOrderProductionsState);

    useEffect(async () => {
        const res = await getBackend().manufacturing.getProducedAll({
                end: Date.now(),
                start: 0,
                order_uuid: props.order_uuid
            });
        setState({ data: res.data.sort((a, b) => a.ts - b.ts) });
    }, [props.order_uuid]);

    const data = state.data.map(x => {
        const row = [
            niceDateTime(new Date(x.ts)),
            niceDateTime(new Date(x.available_in_ts)),
            niceNumber(x.quantity_produced, 0),
            niceNumber(x.quantity_scrap, 0),
            x.status,
            x.stock_accounted_for ? translate("common.yes", "Yes") : translate("common.no", "No"),
            x.line_title
        ];
        if (props.show_technical_details) {
            row.push(x.uuid);
        }
        return row;
    });
    const sum_produced = state.data.reduce((prev, x) => prev += x.quantity_produced, 0);
    const sum_scrap = state.data.reduce((prev, x) => prev += x.quantity_scrap, 0);

    const columns = [
        {
            header: translate("OrderTableProduction.produced_at", "Produced at"),
            footer: translate("common.sum", "Sum")
        },
        {
            header: translate("OrderTableProduction.available_at", "Available at"),
            footer: ""
        },
        {
            header: translate("OrderTableProduction.quantity_produced", "Quantity produced"),
            footer: niceNumber(sum_produced, 0)
        },
        {
            header: translate("OrderTableProduction.quantity_scrap", "Scrap"),
            footer: niceNumber(sum_scrap, 0)
        },
        {
            header: translate("common.status", "Status"),
            footer: ""
        },
        {
            header: translate("OrderTableProduction.stock_accounted_for", "Stock accounted for"),
            footer: ""
        },
        {
            header: translate("common.line", "Line"),
            footer: ""
        },
    ];

    if (props.show_technical_details) {
        columns.push({
            header: translate("common.uuid", "UUID"),
            footer: ""
        });
    }

    return <div className="order-table-production-modal__productions">
        <SimpleTable
            data={data}
            header={columns.map(c => c.header)}
            footer={columns.map(c => c.footer)}
        />
    </div>;
}

/************************************************************************************************ */

type OrderDetailsProps = {
    order_uuid: string
};

type OrderDetailsState = {
    data: r.ISimulationReportOrder | null
};
const initialOrderDetailsState: OrderDetailsState = {
    has_data: false,
    data: null,
    operation: null,
    prods: []
};
const OrderDetails = (props: OrderDetailsProps) => {
    const [state, setState] = useState(initialOrderDetailsState);

    const refresh = async () => {
        const { operations } = await getBackend().manufacturing.getOrdersSimple({ uuids: [props.order_uuid] });
        if (operations.length == 0) {
            setState({ data: [] });
            return;
        }
        const operation = operations[0];
        setState({ operation });
    };
    return <div>
        <div className="m-2">
            <button className="btn" onClick={refresh}>Refresh</button>
        </div>
        <h2>Order</h2>
        <pre>
            {JSON.stringify(state.operation, null, "  ")}
        </pre>
    </div>;
}

/************************************************************************************************ */

type OrderInsightsProps = {
    order_uuid: string
};

type OrderInsightsState = {
    insights: t.IEventDataEx[] | null
};
const initialOrderInsightsState: OrderInsightsState = {
    has_data: false,
    data: null,
    insights: null,
    operation: null,
    prods: []
};
const OrderInsights = (props: OrderInsightsProps) => {
    const [state, setState] = useState(initialOrderInsightsState);

    const refresh = async () => {
        const { insights } = await getBackend().manufacturing.getInsights({
            operations: [props.order_uuid]
        });
        setState({ insights});
    };
    return <div>
        <div className="m-2">
            <button className="btn" onClick={refresh}>Refresh</button>
        </div>
        <Table className="operation-details__table">
            <thead>
                <tr>
                    <th>Time</th>
                    <th>Title</th>
                    <th>Description</th>
                    <th>Type</th>
                </tr>
            </thead>
            <tbody>
                {state.insights && state.insights.map((x, index) => {
                    return <tr key={index}>
                        <td>{niceDateTime(x.ts)}</td>
                        <td>{x.title}</td>
                        <td>{x.description}</td>
                        <td>{x.type}</td>
                    </tr>;
                })}
            </tbody>
        </Table>
    </div>;
}

/************************************************************************************************ */

type OrderSimulationProps = {
    order_uuid: string,
    report_uuid?: string
};

type OrderSimulationState = {
    data: r.ISimulationReportOrder | null,
    prods: string[][]
};
const initialOrderSimulationState: OrderSimulationState = {
    data: null,
    prods: []
};
const OrderSimulation = (props: OrderSimulationProps) => {
    const [state, setState] = useState(initialOrderSimulationState);
    const { order_uuid, report_uuid } = props;

    const refresh = async () => {
        const { operations } = await getBackend().manufacturing.getOrdersSimple({ uuids: [order_uuid] });
        if (operations.length == 0) {
            setState({ data: [], prods: [] });
            return;
        }
        const operation = operations[0];
        const report_res = report_uuid
            ? await getBackend().reports.getReport({ id: report_uuid, line_uuids: operation.line.uuid })
            : await getBackend().reports.getLatestReport({ line_uuids: operation.line.uuid });

        const sim_line = report_res.report.result.orders[0];
        if (sim_line == undefined) {
            setState({ data: [], prods: [] });
            return;
        }
        const sim_order = sim_line.production.filter(x => x.order_id == order_uuid)[0];
        if (sim_order == undefined) {
            setState({ data: [], prods: [] });
            return;
        }
        const prods: string[][] = [];
        for (const shift of report_res.report.result.line_shift_features[0].shifts) {
            for (const prod of shift.produced.filter(x => x.order_id == order_uuid)) {
                prods.push([
                    shift.shift_time.shift_date,
                    "" + shift.shift_time.year,
                    "" + shift.shift_time.week,
                    "" + shift.shift_time.shift_number,
                    "" + prod.quantity_predicted.map(x => x.val).join(" / "),
                    "" + prod.tool_setup.toString()
                ]);
            }
        }
        setState({ data: sim_order, prods });
    };
    const prods_header = ["date", "year", "week", "shift", "quantity", "tool_setup"];
    return <div>
        <div className="m-2">
            <button className="btn" onClick={refresh}>Refresh</button>
        </div>
        <h2>Order from simulation</h2>
        <pre>
            {JSON.stringify(state.data, null, "  ")}
        </pre>
        <h2>Predicted productions</h2>
        <SimpleTable data={state.prods} header={prods_header} />
    </div>;
}

/************************************************************************************************ */

type OperationTagsProps = {
    operations: IOrdersModelBaseExtended[],
};

const OperationTags = (props: OperationTagsProps) => (
    <Table className="operation-details__table">
        <thead>
            <tr>
                <th>{translate(Translations.uuid[0], Translations.uuid[1])}</th>
                <th>{translate(Translations.process_num[0], Translations.process_num[1])}</th>
                <th>{translate(Translations.tags[0], Translations.tags[1])}</th>
            </tr>
        </thead>
        <tbody>
            {props.operations.map((operation, idx) => (
                <tr key={operation.uuid}>
                    <td>{operation.uuid}</td>
                    <td>{operation.process_num}</td>
                    <td>{getTags(operation.tags)}</td>
                </tr>
            ))}
        </tbody>
    </Table>
);

/************************************************************************************************ */

type OrderTableProductionModalProps = {
    selected_order_uuid: string,
    selected_order_external_id: string,
    process_num: string,
    show: boolean,
    onShowOrHideOrderModal: Function,
    show_columns?: Array<$Keys<$Shape<IOrdersModelBaseExtended>>>,
    bom_inputs_show_columns?: Array<$Keys<$Shape<IViewOrderBomInputExtended>>>,
    override_description_fields?: string[],
    onOrderSave?: (order: t.IOrderModelBase) => void,
    show_edit_button?: boolean,
    report_uuid?: string
}

type OrderTableProductionModalState = {
    operations: IOrdersModelBaseExtended[],
    bom_inputs: IViewOrderBomInputExtended[],
    insights: t.IEventDataEx[],
    selected_order_uuid: string,
    override_description_fields: string[],
    material_external_id: string | null,
    material_title: string | null,
    show_edit_modal: boolean,
    show_edit_button: boolean,
    show_technical_details: boolean
}

class OrderTableProductionModal extends React.Component<OrderTableProductionModalProps, OrderTableProductionModalState> {

    state = {
        operations: [],
        bom_inputs: [],
        insights: [],
        selected_order_uuid: "",
        selected_order_external_id: "",
        override_description_fields: [],
        material_external_id: null,
        material_title: null,
        show_edit_modal: false,
        show_edit_button: true,
        show_technical_details: false
    }

    componentDidMount() {
        if (this.props.selected_order_uuid) {
            this.load();
        }
    }

    componentDidUpdate(prev_props: OrderTableProductionModalProps) {
        if (prev_props.selected_order_uuid !== this.props.selected_order_uuid ||
            prev_props.selected_order_external_id !== this.props.selected_order_external_id ||
            prev_props.process_num !== this.props.process_num) {
            this.load();
        }
    }

    componentWillUnmount() {
        this.setState({
            operations: [],
            bom_inputs: [],
            selected_order_uuid: "",
            override_description_fields: [],
            material_external_id: null,
            material_title: null
        });
    }

    load = async () => {
        /*
            Order modal is used on
            - gantt chart (micorplan, gantt, microplan edit quick sim),
            - planning table (lines orders list and gantt chart)
            - microplan (eye icon)
            Different scenarios:
            - user selects chunk order, should show parent order and show it on top
            - user selects parent of chunk order, should show parent order
            - user selects parent parallel order - should show parent order and disable edit
            - user selects child of parallel order - should show child parallel order
        */
        const order_types = Object.keys(ORDER_TYPE).filter(
            type => ![ORDER_TYPE.chunk].includes(type)
        );

        let res_order = await getBackend().manufacturing.getOrdersSimple({
            uuids: [this.props.selected_order_uuid],
            order_types: order_types
        });

        let order = res_order.operations[0];
        if (!order) {
            let res_order = await getBackend().manufacturing.getOrdersSimple({
                order_external_id: this.props.selected_order_external_id,
                process_num: this.props.process_num,
                order_types: order_types
            });
            order = res_order.operations[0];
        }
        if (!order) {
            console.log("Could not find order OrderTableProductionModa external id: ", this.props.selected_order_external_id);
            return;
        }

        let show_edit_button = (
            order.order_type !== ORDER_TYPE.parallel ||
            order.order_type === ORDER_TYPE.parallel && order.tags[ORDER_TAGS.parallel_order] === "true"
        );

        let selected_order_uuid = order.uuid;
        const res = await getBackend().manufacturing.getOrdersSimple({
            order_external_id: order.external_id
        });

        const order_uuids = res.operations.map(o => o.uuid);
        const { orders_produced } = await getBackend().manufacturing.getOrdersProduced({ order_uuids });

        let state_orders: IOrdersModelBaseExtended[] = [];

        for (const operation of res.operations) {
            const produced_order = orders_produced.find(o => o.order_uuid === operation.uuid);
            let ext = {};

            if (produced_order) {
                ext = {
                    quantity_produced: produced_order.quantity_produced,
                    quantity_scrap: produced_order.quantity_scrap
                }
            } else {
                ext = {
                    quantity_produced: 0.0,
                    quantity_scrap: 0.0
                }
            }

            state_orders.push({ ...operation, ...ext });
        }
        state_orders.sort((o1, o2) => (o1.process_num === o2.process_num ?
            (o1.order_type === ORDER_TYPE.chunk ? 1 : -1) : // when more numbers equal, show non-chunk ones first
            parseFloat(o1.process_num) - parseFloat(o2.process_num)));

        // get BOM for operations
        const res_bom = await getBackend().manufacturing.getInputMaterials({
            order_external_id: order.external_id
        });

        const bom_inputs: IViewOrderBomInputExtended[] = [];
        for (const bom_input of res_bom.input_materials) {
            // no chunk BOMs
            const bom_input_order = state_orders.find(so =>
                (so.uuid === bom_input.order_uuid && so.order_type !== ORDER_TYPE.chunk));
            if (bom_input_order) {
                bom_inputs.push({
                    ...bom_input,
                    process_num: bom_input_order.process_num,
                    input_quantity: bom_input_order.quantity_total * bom_input.factor
                });
            }
        }
        bom_inputs.sort((bi1, bi2) => (parseFloat(bi1.process_num) - parseFloat(bi2.process_num)));

        const operation = state_orders[0];
        state_orders = await this.updateChunkOrders(state_orders);
        this.setState({
            operations: state_orders,
            bom_inputs,
            selected_order_uuid,
            material_external_id: operation.material_external_id,
            show_edit_button
        });
    }

    updateChunkOrders = async (orders: IOrdersModelBaseExtended[]): Promise<IOrdersModelBaseExtended[]> => {
        const parent_order_uuids = new Set<string>();
        for (const order of orders) {
            if (order.parent_order_uuid) {
                parent_order_uuids.add(order.parent_order_uuid);
            }
        }
        if (parent_order_uuids) {
            const res = await getBackend().manufacturing.getOrdersSimple({
                uuids: [...parent_order_uuids]
            });
            const parent_orders = res.operations;
            const parent_uuids_comments = new Map<string, string>();
            for (const parent_order of parent_orders) {
                const comment = parent_order.tags[ORDER_TAGS.comment];
                if (comment) {
                    parent_uuids_comments.set(parent_order.uuid, comment);
                }
            }
            // update chunk orders
            for (const order of orders) {
                if (order.order_type === ORDER_TYPE.chunk && order.parent_order_uuid) {
                    const parent_comment = parent_uuids_comments.get(order.parent_order_uuid);
                    if (parent_comment) {
                        order.tags[ORDER_TAGS.comment] = parent_comment;
                    }
                }
            }
        }
        return orders;
    }

    onSelectedMaterialChange = (material_external_id: string) => {
        this.setState({ material_external_id });
    }

    getIsParallel = (): boolean => {
        return this.state.operations.some(o => o.tags.parallel_order === "true" || o.tags.parallel_parent === "true");
    }

    getOperation = (): ?IOrdersModelBaseExtended => {
        return (
            this.state.operations.find(o => o.uuid === this.state.selected_order_uuid)
        );
    }

    onCloseEditModal = () => {
        this.setState({ show_edit_modal: false });
        this.load();
    }

    onShowEditModal = () => {
        this.setState({ show_edit_modal: true })
    }

    onShowTechnicalDetails = () => {
        this.setState(prev_state => ({
            ...prev_state,
            show_technical_details: !prev_state.show_technical_details
        }))
    }

    showEditButton = () => {
        // we can either entirely hide edit button before mounting the component
        // or we can choose to hide it based on the order selected
        if (this.props.show_edit_button === false || this.state.show_edit_button === false) {
            return false;
        }
        return true;
    }

    getSelectedOrderUuid = () => {
        return this.state.selected_order_uuid;
    }

    render() {
        if (this.state.operations.length === 0) return null;

        const selected_order_uuid = this.getSelectedOrderUuid();
        const is_admin = Auth.isInRole(Auth.ROLE_ADMIN);
        const operation = this.getOperation();
        const is_parallel = this.getIsParallel();

        const tabs = [
            {
                title: translate("OrderTableProduction.operations", "Operations"),
                element: <OperationDetails
                    operations={this.state.operations}
                    selected_order_uuid={selected_order_uuid}
                    show_columns={!this.state.show_technical_details ? this.props.show_columns : []} />
            },
            {
                title: translate("OrderTableProduction.bom", "BOM"),
                element: <BomInputDetails
                    bom_inputs={this.state.bom_inputs}
                    show_columns={!this.state.show_technical_details ? this.props.bom_inputs_show_columns : []} />
            },
            {
                title: translate("common.productions", "Productions"),
                element: <OrderProductions order_uuid={selected_order_uuid} show_technical_details={this.state.show_technical_details} />
            }
        ];
        if (is_admin) {
            tabs.push({
                title: translate("common.details", "Details"),
                element: <OrderDetails order_uuid={selected_order_uuid} />
            });
            tabs.push({
                title: translate("common.insights", "Insights"),
                element: <OrderInsights order_uuid={selected_order_uuid} />
            });
            tabs.push({
                title: translate("common.simulation", "Simulation"),
                element: <OrderSimulation report_uuid={this.props.report_uuid} order_uuid={selected_order_uuid} />
            });
            tabs.push({
                title: translate("common.tags", "Tags"),
                element: <OperationTags operations={this.state.operations} />
            });
        }
        return <React.Fragment>
            {this.showEditButton() && <EditOrderModal
                show_modal={this.state.show_edit_modal}
                onCloseModal={this.onCloseEditModal}
                order_uuid={this.state.selected_order_uuid}
                onSave={this.props.onOrderSave}
            />}
            <Modal className="order-table-production-modal"
                show={this.props.show}
                bsSize={"large"}
                onHide={() => this.props.onShowOrHideOrderModal(false, selected_order_uuid)}
            >
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id="OrderTableProduction.order_details" defaultMessage="Order Details" />
                    </Modal.Title>

                    <button type="button" className="close" onClick={() => this.props.onShowOrHideOrderModal(false, selected_order_uuid)}>
                        <span>×</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    {this.state.operations && this.state.bom_inputs && <ModalTabs
                        details1={<Description
                            operation={operation}
                            barcode_operation={operation}
                            override_description_fields={!this.state.show_technical_details ? this.props.override_description_fields : []}
                            hide_barcode_operation={is_parallel}
                        />}
                        tabs={tabs}
                    />}

                </Modal.Body>
                <Modal.Footer>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                        <div>
                            {this.showEditButton() && <Authorization.button
                                permission={Auth.PERMISSION_NAMES.OrderEdit}
                                className="btn btn-primary"
                                onClick={this.onShowEditModal}
                                style={{ float: "right" }}
                            >
                                {translate("common.edit_comment", "Edit comment")}
                            </Authorization.button>}
                        </div>
                        <div>
                            <button className="btn btn-primary ml-2" onClick={this.onShowTechnicalDetails}>
                                {this.state.show_technical_details
                                    ? translate("OrderTableProduction.hide_technical_details", "Hide technical details")
                                    : translate("OrderTableProduction.technical_details", "Technical details")}
                            </button>
                            {this.props.override_description_fields && !this.state.show_technical_details && operation && <MD04Button
                                parent_order_uuid={operation.uuid}
                                material_external_id={operation.material_external_id}
                                material_title={TranslatedMaterialTitle({
                                    material_external_id: operation.material_external_id,
                                    material_title: operation.material_title
                                })}
                                onSelectedMaterialChange={this.onSelectedMaterialChange}
                            />}
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    }
}

export default OrderTableProductionModal;
