// @flow
import * as React from "react";
import ReactDOM from "react-dom";
import Tooltip from "../../../Tooltip";
import { getCursorValue } from "../reducers/properties";
import TranslatedMaterialTitle from "../../../TranslatedMaterialTitle";

import type { GanttChartSourceTypes } from "../reducers/properties";

type TitlePhantomProps = {
    onPhantomLoaded: (computed_material_title: string, width: number) => void,
    material_title: string,
    width: number,
    x: number,
    y: number,
    should_truncate_title?: boolean,
    material_external_id: string
}

class TranslatedTitlePhantom extends React.Component<TitlePhantomProps, any> {

    ref = null;

    constructor(props: TitlePhantomProps) {
        super(props);
        this.ref = React.createRef();
        this.state = {
            computed_material_title: props.material_title
        }
    }

    getRef = () => {
        return this.ref || React.createRef();
    }

    componentDidMount() {
        this.computeMaterialTitle();
    }

    componentDidUpdate() {
        this.computeMaterialTitle();
    }

    unmountPhantom = (title: string, text_width: number) => {
        const node = this.ref;
        if (!node || !node.current) return;
        this.props.onPhantomLoaded(title, text_width);
        ReactDOM.unmountComponentAtNode(node.current);
        return;
    }

    getMaterialTitle = () => {
        return TranslatedMaterialTitle({
            material_title: this.props.material_title,
            material_external_id: this.props.material_external_id
        });
    }

    computeMaterialTitle = () => {
        const node = this.getRef();
        const current_material_title = this.getMaterialTitle();
        if (!node || !node.current || !current_material_title || !node.current.getComputedTextLength) return;
        const text_width = node.current.getComputedTextLength();
        let material_title = this.state.computed_material_title;
        const text_shrink_step = 5;
        if (this.props.should_truncate_title !== false) {
            while (material_title.length > 0) {
                material_title = material_title + ((material_title.length < current_material_title.length) ? "..." : "");
                if (text_width > this.props.width - 10) {
                    material_title = material_title.length > text_shrink_step ? material_title.slice(0, material_title.length - text_shrink_step) : "";
                    if (material_title !== this.state.computed_material_title) {
                        this.setState({ computed_material_title: material_title });
                    }
                    return;
                } else {
                    break;
                }
            }

            if (0 < material_title.length && material_title.length < current_material_title.length && !material_title.includes("...")) {
                material_title += "...";
            }
        }

        this.unmountPhantom(material_title, text_width);
    }

    render() {
        return (
            <text x={this.props.x} y={this.props.y} ref={this.ref} fontSize="12px" fontWeight="400" opacity={0} style={{ cursor: "pointer" }}>
                {this.state.computed_material_title || this.getMaterialTitle()}
            </text>
        )
    }
}


export type StockRequirementsRectsTitleProps = {
    material_title: string,
    material_external_id: string,
    width: number,
    x: number,
    y: number,
    should_truncate_title?: boolean,
    tooltip_text?: React.Node,
    source_type: GanttChartSourceTypes,
    is_ctrl_down: boolean,
    show_stock_requirements: boolean
}

type StockRequirementsRectsTitleState = {
    computed_material_title: string | null,
    width: number | null
}

export default class StockRequirementsRectsTitle extends React.Component<StockRequirementsRectsTitleProps, StockRequirementsRectsTitleState> {

    node = null;

    constructor(props: StockRequirementsRectsTitleProps) {
        super(props);
        this.node = React.createRef();
    }

    state: StockRequirementsRectsTitleState = {
        computed_material_title: null,
        width: null
    }

    componentDidUpdate(prev_props: StockRequirementsRectsTitleProps, prev_state: StockRequirementsRectsTitleState) {
        if (prev_props.width !== this.props.width || prev_props.show_stock_requirements !== this.props.show_stock_requirements) {
            this.setState({ computed_material_title: null, width: null });
        }
    }

    onPhantomLoaded = (title: string, text_width: number) => {
        this.setState({ computed_material_title: title, width: text_width });
    }

    renderMaterialTitle = () => {
        const width = this.state.width;
        if (this.state.computed_material_title && width) {
            const cursor = getCursorValue({
                is_ctrl_down: this.props.is_ctrl_down,
                is_rescheduled: false,
                source_type: this.props.source_type
            });

            return <React.Fragment>
                <Tooltip content={this.props.tooltip_text}>
                    <text
                        fontSize="12px"
                        fontWeight="400"
                        x={this.props.x}
                        y={this.props.y}
                        style={{ cursor }}
                        material-external-id={this.props.material_external_id}
                        source_type={this.props.source_type}
                    >
                        {this.state.computed_material_title}
                    </text>
                </Tooltip>
            </React.Fragment>
        }
    }

    render() {
        return (
            <g id="material-title">
                {this.state.computed_material_title === null && <TranslatedTitlePhantom
                    key={this.props.material_external_id}
                    ref={this.node}
                    onPhantomLoaded={this.onPhantomLoaded}
                    width={this.props.width}
                    x={this.props.x}
                    y={this.props.y}
                    should_truncate_title={this.props.should_truncate_title}
                    material_external_id={this.props.material_external_id}
                    material_title={this.props.material_title}
                />}
                {this.renderMaterialTitle()}
            </g>
        )
    }
}
