// @flow

/************************************************************************************/
/* WARNING: This file is generated automatically. Don't edit it by hand             */
/************************************************************************************/

import * as api from "./restApi.js";
import * as t from "./dashboards2.generated.types.js";

function enc(s: any): string { return encodeURIComponent("" + s); }

export interface IDashboards2 {
    /** Retrieves basic info about dashboards that meet given criteria */
    searchDashboards(req: t.ISearchDashboardsReq): Promise<t.ISearchDashboardsRes>;

}

export class Dashboards2 implements IDashboards2 {

    client: api.ApiClient;

    constructor(client: api.ApiClient) { this.client = client; }

    /** Retrieves basic info about dashboards that meet given criteria */
    async searchDashboards(req: t.ISearchDashboardsReq): Promise<t.ISearchDashboardsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/dashboards2/search`;
        return (await this.client.post(url, reqx)).data;
    }

}
