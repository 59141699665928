// @flow
import * as React from "react";
import { connect } from "react-redux";
import * as t from "../../../../lib/backend/manufacturing2.generated.types";
import {
    ConnectedGridLines,
    GridGroups,
    CurrentShiftDashedLine
} from "./GanttChartGrid";
import { PropertiesLogic, ReduxFilters } from "../reducers/BusinessLogic";
import StockRequirementsRect from "./StockRequirementsRect";
import { UPDATE_PROPERTIES_ACTION_NAME, getStockRequirementStartDate } from "../reducers/properties";
import { getDateOfISOWeekShift } from "../../../../lib/Util";
import ReportLoader from "./ReportLoader";
import * as t2 from "../../../../lib/backend/reports.generated.types";

import type {
    UpdatePropertiesActionType,
    PropertiesState,
    GanttChartSourceTypes,
    UpdatePlanningTableWidthAction
} from "../reducers/properties";

import type { ShiftXPositions } from "../reducers/properties";
import type { ReportState } from "../reducers/report";
import type { ReduxState } from "../reducers/reducers";
import type { ResetGanttAction } from "../reducers/common";
import type { StockRequirement } from "../reducers/stockRequirements";
import type { PlanningTableFiltersState } from "../reducers/reducersFilters/filters";
import type { StockRequirementsFiltersState } from "../reducers/reducersFilters/stockRequirementsFilters";
import type { InsightsMapStockRequirements } from "../reducers/insights";
import type { LineOrders } from "../reducers/linesOrders";

type GanttChartContentProps = {
    stock_requirements: StockRequirement[],
    is_ctrl_down: boolean,
    selected_stock_requirement: StockRequirement,
    properties: PropertiesState,
    insights: t.IEventDataEx[],
    filtered_stock_requirements_materials: string[],
    lines_orders: LineOrders[],
    unscheduled_orders: LineOrders[],
    orders_filters: PlanningTableFiltersState,
    stock_requirements_filters: StockRequirementsFiltersState,
    insights_map_stock_requirements: InsightsMapStockRequirements
};
type GanttChartContentState = { };


class GanttChartContent extends React.Component<GanttChartContentProps, GanttChartContentState> {

    phantomNode = null;

    state: GanttChartContentState = { }


    renderStockRequirements() {
        const current_shift = this.props.properties.current_shift;
        const week = this.props.properties.week;
        const year = this.props.properties.year;

        if (!week || !year) {
            return null;
        }

        const start_date_ts = getDateOfISOWeekShift(current_shift, week, year);
        const stock_requirements_arr = [];

        const insights_map = this.props.insights_map_stock_requirements;
        const resolution_map = new Map<number, number>();
        const stock_requirements = ReduxFilters.filterStockRequirements(
            this.props.lines_orders,
            this.props.unscheduled_orders,
            this.props.stock_requirements,
            this.props.orders_filters,
            this.props.stock_requirements_filters,
            this.props.insights_map_stock_requirements
        );
        // if the same material exists in the past then we don't show it
        for (const sr of stock_requirements) {
            const start_date = getStockRequirementStartDate(
                sr.shift_start_time,
                start_date_ts
            );

            let line_index = 0;
            if (resolution_map.has(start_date)) {
                line_index = resolution_map.get(start_date) + 1;
            }

            resolution_map.set(start_date, line_index);

            stock_requirements_arr.push(<StockRequirementsRect
                key={sr.uuid}
                line_index={line_index}
                stock_requirement={sr}
                uuid={sr.uuid}
                has_insight={insights_map.has(sr.uuid)}
                is_ctrl_down={this.props.is_ctrl_down}
                properties={this.props.properties}
            />);
        }
        return stock_requirements_arr;
    }

    renderContent = () => {
        return <React.Fragment>
            <linearGradient id="stock-requirements-gradient" x1="0" x2="1" y1="0" y2="0">
                <stop offset="0%" stop-color="#A2E6CD" />
                <stop offset="90%" stop-color="#CEF5E6" />
                <stop offset="100%" stop-color="#E6F5EF" />
            </linearGradient>
            <linearGradient id="stock-requirements-gradient-insight" x1="0" x2="1" y1="0" y2="0">
                <stop offset="0%" stop-color="#f78782" />
                <stop offset="90%" stop-color="#fae4e3" />
                <stop offset="100%" stop-color="#faf3f2" />
            </linearGradient>
            {this.renderStockRequirements()}
        </React.Fragment>;
    }

    render() {
        return <React.Fragment>
            {this.renderContent()}
        </React.Fragment>
    }
}
const ConnectedGanttChartContent = connect(
    (state: ReduxState) => {
        const stock_requirements = state.gantt_chart_stock_requirements.stock_requirements;
        const planning_table = state.gantt_chart_planning_table;
        const orders_filters = state.gantt_chart_filters;
        const lines_orders = state.gantt_chart_lines_orders.lines_orders;
        const unscheduled_orders = state.gantt_chart_lines_orders.unscheduled_orders;
        const stock_requirements_filters = state.gantt_chart_stock_requirements_filter;
        const insights_map_stock_requirements = state.gantt_chart_insights.insights_map_stock_requirements;

        return {
            lines_orders,
            unscheduled_orders,
            orders_filters,
            stock_requirements_filters,
            properties: state.gantt_chart_properties,
            is_ctrl_down: planning_table.is_ctrl_down,
            stock_requirements,
            insights: state.gantt_chart_insights.insights,
            insights_map_stock_requirements
        }
    },
    null
)(GanttChartContent);


const getReportCreatedAt = (report: ReportState) => {
    if (!report || !report.report_data) return null;
    return report.report_data.created_at;
}

type GanttChartProps = {
    properties: PropertiesState,
    line_group_uuid: string,
    reduxDispatch: (args: UpdatePropertiesActionType | ResetGanttAction | UpdatePlanningTableWidthAction) => any,
    start_day: number | null,
    planning_weeks: number,
    source_type: GanttChartSourceTypes,
    report: ReportState,
    report_loading: boolean,
    show_stock_requirements: boolean,
    shifts: t2.ISimulationReportOrderLineShiftFeature[],
    shift_x_positions: ShiftXPositions | null
}

class StockRequirementsGanttChart extends React.Component<GanttChartProps, any> {

    state = {
        properties_loaded: false
    }

    phantomNode = null;

    constructor(props) {
        super(props);
        this.phantomNode = React.createRef();
    }

    resizeListener = null;

    componentDidMount = () => {
        // calculate width and height
        if (!this.resizeListener) {
            this.resizeListener = this.updateDimensions(this);
        }

        window.addEventListener("resize", this.resizeListener);

        const properties = {
            source_type: this.props.source_type,
            line_group_uuid: this.props.line_group_uuid
        };
        this.props.reduxDispatch({
            type: UPDATE_PROPERTIES_ACTION_NAME,
            data: properties
        });
    }


    componentWillUnmount() {
        this.removeListeners();
    }

    removeListeners = () => {
        window.removeEventListener("resize", this.resizeListener);
    }

    updateDimensions = (self) => () => {
        return null;
    }

    getIsPropertiesLoaded = () => {
        return this.props.properties.loaded;
    }

    getSvgAttributes() {
        let svg_attributes = {};
        const width = this.props.properties.width;

        const height = PropertiesLogic.getHeight(this.props.properties, this.props.source_type);

        if (width || height) {
            svg_attributes = {
                width: width,
                height: height
            }
        }

        return svg_attributes;
    }

    getOpacity = () => {
        return this.props.show_stock_requirements ? 1 : 0;
    }

    render() {
        if (!this.props.show_stock_requirements) {
            return null;
        }

        const svg_attributes = this.getSvgAttributes();
        const class_name = `${this.props.properties.no_overflow ? "" : "chart-wrapper"}`;
        const shifts = this.props.shifts ? this.props.shifts[0].shifts : [];
        return <div id={this.props.source_type} style={{ zIndex: 1, opacity: this.getOpacity(), paddingTop: "5px", userSelect: "none" }} className={class_name}>
            <ReportLoader pane_id={"pane0"}/>
            <svg id="gantt-chart-stock-requirements" {...svg_attributes}>
                <g id="grid">
                    <rect height="100%" width={this.props.properties.max_line_label_length} fill="white" display-outside="block" />
                    <text ref={this.phantomNode} fontSize={"14px"} fontFamily={"Arial"} fontWeight={"400"} opcaity={0} />
                    <ConnectedGridLines
                        gantt_chart_properties={this.props.properties}
                        source_type={this.props.source_type}
                        shifts={shifts}
                        shift_x_positions={this.props.shift_x_positions}
                    />
                    <GridGroups
                        gantt_chart_properties={this.props.properties}
                        source_type={this.props.source_type}
                        shifts={shifts}
                        shift_x_positions={this.props.shift_x_positions}
                    />
                    <CurrentShiftDashedLine
                        gantt_chart_properties={this.props.properties}
                        source_type={this.props.source_type}
                        shifts={shifts}
                        shift_x_positions={this.props.shift_x_positions}
                    />
                    <ConnectedGanttChartContent />
                </g>
            </svg>
        </div>
    }
}

export default connect(
    (state: ReduxState, own_props: $Shape<GanttChartProps>) => {
        const loading: boolean = state.gantt_chart_lines_orders.loading;
        const properties = state.gantt_chart_properties;
        const planning_table = state.gantt_chart_planning_table;
        const report = state.gantt_chart_report.report_data;
        const shift_x_positions = properties.shift_x_positions;
        let shifts = [];
        if (report) {
            shifts = report.result.line_shift_features;
        }
        return {
            report_loading: state.gantt_chart_report.report_loading,
            lines_orders_loading: loading,
            report: state.gantt_chart_report,
            created_at: getReportCreatedAt(state.gantt_chart_report),
            properties,
            show_stock_requirements: planning_table.show_stock_requirements,
            shifts,
            shift_x_positions
        }
    },
    (dispatch) => ({ reduxDispatch: dispatch })
)(StockRequirementsGanttChart);
