// @flow

import React from "react";
import MultiSelect from "../../../../MultiSelect";
import { translate } from "../../../../IntlProviderWrapper";
import TranslatedMaterialTitle from "../../../../TranslatedMaterialTitle";
import { connect } from "react-redux";
import { SET_SELECTED_MATERIALS, RESET_FILTERS } from "../../reducers/reducersFilters/filters"

import type { ReduxState } from "../../reducers/reducers";
import type { LineOrders } from "../../reducers/linesOrders";
import type { SetSelectedMaterialAction, ResetFiltersAction } from "../../reducers/reducersFilters/filters";

export type SelectOptionItem = {
    label: string;
    value: string;
}

type Props = {
    orders: LineOrders[],
    selected_materials: string[],
    handleMaterialChange: (args: SelectOptionItem[]) => void,
    reduxDispatch: (args: SetSelectedMaterialAction | ResetFiltersAction) => void,
    onChange: (value: string[]) => void,
    filter_material_external_ids: string[]
}

type State = { }

const replaceSpaces = (text: string = "") => {
    return text.split(" ").filter(v => v.trim()).join(" ");
}

const filterOption = (
    candidate: { label: string; value: string; data: any },
    input: string
  ) => {
    if (input) {
        return (
            (replaceSpaces(candidate.label).toLowerCase().includes(replaceSpaces(input).toLowerCase())) ||
            candidate.value === (input || "").trim()
        );
    }
    return true;
}


class MaterialFilter extends React.Component<Props, State> {

    mapLineOrdersToOptions = (line_orders: LineOrders[], filter_material_external_ids?: string[] = []) => {
        const materials = [];
        const material_external_ids = new Set();
        for (const line_order of line_orders) {
            for (const order of line_order.orders) {
                if (filter_material_external_ids.length > 0 && !filter_material_external_ids.includes(order.material_external_id)) {
                    continue;
                }
                if (!material_external_ids.has(order.material_external_id)) {
                    materials.push({
                        value: order.material_external_id,
                        label: order.material_external_id + " - " + TranslatedMaterialTitle({
                            material_external_id: order.material_external_id,
                            material_title: order.material_title
                        })
                    });
                    material_external_ids.add(order.material_external_id);
                }
            }
        }
        return materials;
    }

    handleMaterialChange = (selected_materials?: SelectOptionItem[]) => {
        if (!selected_materials) {
            this.props.reduxDispatch({ type: RESET_FILTERS, data: undefined });
        } else {
            this.props.reduxDispatch({ type: SET_SELECTED_MATERIALS, data: selected_materials.map(m => m.value) });
        }
    }

    getSelectedMaterials = () => {
        const options = this.mapLineOrdersToOptions(this.props.orders, this.props.filter_material_external_ids);
        return options.filter(o => this.props.selected_materials.includes(o.value));
    }

    getOptions = () => {
        return this.mapLineOrdersToOptions(this.props.orders, this.props.filter_material_external_ids);
    }

    render() {
        return <div className="material-filter">
            <MultiSelect
                key="material-filter"
                value={this.getSelectedMaterials()}
                placeholder={translate("common.materials", "Materials")}
                options={this.getOptions()}
                filterOption={filterOption}
                menuPosition="fixed"
                onChange={this.handleMaterialChange}
                enable_filter_selected
            />
        </div>
    }
}

export default connect(
    (state: ReduxState) => {
        const selected_materials = state.gantt_chart_filters.selected_materials;
        const orders = [
            ...state.gantt_chart_lines_orders.lines_orders,
            ...state.gantt_chart_lines_orders.unscheduled_orders
        ];
        return {
            orders,
            selected_materials
        };
    },
    (dispatch) => ({reduxDispatch: dispatch})
)(MaterialFilter);