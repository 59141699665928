// @flow

/************************************************************************************/
/* WARNING: This file is generated automatically. Don't edit it by hand             */
/************************************************************************************/

/** Flags that determine the method for determining the norm in re-wire-ing */
export const REWIRE_PARALLEL_NORM_LOGIC = {
    /** Pick normative of the principal child */
    first: "first",
    /** Sum normatives */
    sum: "sum"
};
export type RewireParallelNormLogicEnum =
    "first" |
    "sum";

/** Status of production as reported by the MES system */
export const PRODUCTION_STATUSES = {
    /** This production is confirmed */
    confirmed: "confirmed",
    /** This production should be deleted */
    deleted: "deleted",
    /** This production is not confirmed */
    unconfirmed: "unconfirmed"
};
export type ProductionStatusesEnum =
    "confirmed" |
    "deleted" |
    "unconfirmed";

/** Used in API to define if we care about input, produced or both materials */
export const STOCK_DIRECTION = {
    in: "in",
    in_and_out: "in_and_out",
    out: "out"
};
export type StockDirectionEnum =
    "in" |
    "in_and_out" |
    "out";

/** Types of stock-requirements */
export const STOCK_REQUIREMENTS_TYPES = {
    received: "received",
    required: "required"
};
export type StockRequirementsTypesEnum =
    "received" |
    "required";

/** MES Types */
export const MES = {
    proses: "proses",
    sinapro: "sinapro"
};
export type MesEnum =
    "proses" |
    "sinapro";

/** General classification of downtime types. Real downtime time, reported by the MES system, should be mapped into one of these categories. */
export const DOWNTIME_TYPES = {
    holidays: "holidays",
    machine_maintenance: "machine_maintenance",
    mechanical_failure: "mechanical_failure",
    no_material: "no_material",
    no_work: "no_work",
    no_worker: "no_worker",
    other: "other",
    planned_stop: "planned_stop",
    power_failure: "power_failure",
    self_maintenance: "self_maintenance",
    setup_during_process: "setup_during_process",
    setup_of_machine: "setup_of_machine",
    tool_failure: "tool_failure",
    tool_setup: "tool_setup"
};
export type DowntimeTypesEnum =
    "holidays" |
    "machine_maintenance" |
    "mechanical_failure" |
    "no_material" |
    "no_work" |
    "no_worker" |
    "other" |
    "planned_stop" |
    "power_failure" |
    "self_maintenance" |
    "setup_during_process" |
    "setup_of_machine" |
    "tool_failure" |
    "tool_setup";

/** Different levels of insight priorities */
export const INSIGHT_PRIORITIES = {
    high: "high",
    low: "low",
    medium: "medium",
    none: "none"
};
export type InsightPrioritiesEnum =
    "high" |
    "low" |
    "medium" |
    "none";

/** Different types of manufacturing orders */
export const ORDER_TYPE = {
    chunk: "chunk",
    full: "full",
    parallel: "parallel",
    plan: "plan"
};
export type OrderTypeEnum =
    "chunk" |
    "full" |
    "parallel" |
    "plan";

/** Different statuses of manufacturing orders */
export const ORDER_STATUS = {
    closed: "closed",
    closed_overflow: "closed_overflow",
    closed_underflow: "closed_underflow",
    open: "open"
};
export type OrderStatusEnum =
    "closed" |
    "closed_overflow" |
    "closed_underflow" |
    "open";

/** Different possible sources of how manufacturing orders are generated */
export const ORDER_GENERATED_BY = {
    batch_operations: "batch_operations",
    manual_entry_subcontractor: "manual_entry_subcontractor",
    parallel_materials: "parallel_materials",
    planning_board_split: "planning_board_split",
    sap: "sap",
    sap_subcontracted_materials: "sap_subcontracted_materials"
};
export type OrderGeneratedByEnum =
    "batch_operations" |
    "manual_entry_subcontractor" |
    "parallel_materials" |
    "planning_board_split" |
    "sap" |
    "sap_subcontracted_materials";

/** Flag how Sundays last shift should be handled */
export const WORK_SCHEDULE_SUNDAY_LOGIC = {
    nop: "nop",
    swap: "swap"
};
export type WorkScheduleSundayLogicEnum =
    "nop" |
    "swap";

/** How is stock triggering ... ?  */
export const STOCK_TRIGGERING_OUTSTANDING = {
    current_stock: "current_stock",
    nop: "nop",
    produced_minus_consumed: "produced_minus_consumed"
};
export type StockTriggeringOutstandingEnum =
    "current_stock" |
    "nop" |
    "produced_minus_consumed";

/** How is stock triggering ... ?  */
export const STOCK_TRIGGERING_DELAY_SOURCE = {
    fixed_plus_norm_machine_time: "fixed_plus_norm_machine_time",
    norm_machine_time: "norm_machine_time",
    norm_tool_setup_time: "norm_tool_setup_time",
    time_end_time_start: "time_end_time_start"
};
export type StockTriggeringDelaySourceEnum =
    "fixed_plus_norm_machine_time" |
    "norm_machine_time" |
    "norm_tool_setup_time" |
    "time_end_time_start";

/** How is stock triggering ... ?  */
export const STOCK_TRIGGERING_TYPE = {
    ageing: "ageing",
    atp_ageing: "atp_ageing",
    subcontracting: "subcontracting"
};
export type StockTriggeringTypeEnum =
    "ageing" |
    "atp_ageing" |
    "subcontracting";

/** Possible types of resources that can be included into planning */
export const PLANNING_RESOURCE_TYPES = {
    line: "line",
    person: "person",
    tool: "tool"
};
export type PlanningResourceTypesEnum =
    "line" |
    "person" |
    "tool";

/** How planning handles material tracking */
export const PLANNING_MATERIAL_TRACKING = {
    exact: "exact",
    order: "order"
};
export type PlanningMaterialTrackingEnum =
    "exact" |
    "order";

/** Scheduling strategy */
export const PLANNING_SCHEDULE_STRATEGY = {
    /** Beam search for best scheduling of operations. */
    BFS: "BFS",
    /** Keep same resource and order of orders, but take into account constraints to determine time */
    rectify: "rectify"
};
export type PlanningScheduleStrategyEnum =
    "BFS" |
    "rectify";

/** How is search time range matched to insight time range */
export const INSIGHT_RANGE_SEARCH_TYPE = {
    /** from <= insight.ts_to <= to */
    ends_in: "ends_in",
    /** insights.ts <= from <= to <=insights.ts_to */
    includes_range: "includes_range",
    /** intersect([from,to], [insights.ts,insights.ts_to]) is non-zero */
    overlaps_with_range: "overlaps_with_range",
    /** from <= insight.ts <= to */
    starts_in: "starts_in"
};
export type InsightRangeSearchTypeEnum =
    "ends_in" |
    "includes_range" |
    "overlaps_with_range" |
    "starts_in";

/** Type of constraint */
export const PLANNING_CONSTRAINT_TYPE = {
    /** Not predefined, by only available for the operation so must be included */
    adhoc: "adhoc",
    /** Currently selected for the order but not same as any of the available production versions. */
    current: "current",
    /** Defined by production version or selected in matrix for the line group */
    production_version: "production_version"
};
export type PlanningConstraintTypeEnum =
    "adhoc" |
    "current" |
    "production_version";

/** Types of explanations informing why an operation was or was not scheduled */
export const PLANNING_EXPLANATION_TYPE = {
    /** operation was already fixed in the input */
    frozen: "frozen",
    /** missing input material for the operation */
    missing_input_material: "missing_input_material",
    /** so far no automatic planning was ran on the planning board */
    no_automatic_planning: "no_automatic_planning",
    /** no resource available to schedule the operation */
    no_resource_available: "no_resource_available",
    /** operation was not part of automatic planning process */
    not_planned: "not_planned",
    /** search did not reach this operation */
    not_reached: "not_reached",
    /** previous operation not scheduled */
    previous_operation_not_scheduled: "previous_operation_not_scheduled",
    /** we found a slot and input materials for the operation */
    scheduled: "scheduled"
};
export type PlanningExplanationTypeEnum =
    "frozen" |
    "missing_input_material" |
    "no_automatic_planning" |
    "no_resource_available" |
    "not_planned" |
    "not_reached" |
    "previous_operation_not_scheduled" |
    "scheduled";

/** Warnings created as part of scheduling process */
export const PLANNING_WARNING_TYPE = {
    /** operation was frozen but logic for placing it on predefined place failed */
    frozen: "frozen",
    /** no warning */
    none: "none",
    /** available production versions for scheduling the operations do not match matrix constraints */
    prod_ver_mismatch: "prod_ver_mismatch",
    /** rectify logic could not place operation on the schedule */
    rectify_unscheduled: "rectify_unscheduled"
};
export type PlanningWarningTypeEnum =
    "frozen" |
    "none" |
    "prod_ver_mismatch" |
    "rectify_unscheduled";

/** Type of plan import */
export const PLAN_IMPORT_TYPE = {
    /** Explicit lists of planned order ids and order ids */
    lists: "lists",
    /** All planned and work orders for a given material list */
    material: "material",
    /** All planned and work orders for a given plant */
    plant: "plant",
    /** All planned and work orders for a given workplace list */
    workplace: "workplace"
};
export type PlanImportTypeEnum =
    "lists" |
    "material" |
    "plant" |
    "workplace";

/** Type of model used to describe material-process-resource */
export const PLANNING_MODEL_ITEM_TYPE = {
    /** Production function is approximated with step function */
    batch: "batch",
    /** Production function is approximated with linear function */
    continuous: "continuous"
};
export type PlanningModelItemTypeEnum =
    "batch" |
    "continuous";

/** Values stored in local storage on GUI */
export const GUI_LOCAL_STORAGE = {
    /** In DEV env keep same hash for quick sim on the planning board to speedup loading */
    fixed_quick_sim_cache_hash: "fixed_quick_sim_cache_hash"
};
export type GuiLocalStorageEnum =
    "fixed_quick_sim_cache_hash";

/** Cache hash values for quick simulationm when in dev fixed moded */
export const QUICK_SIM_FIXED_CACHE_HASH = {
    hopsasa: "hopsasa",
    tralala: "tralala"
};
export type QuickSimFixedCacheHashEnum =
    "hopsasa" |
    "tralala";

/** Indicates on which level KPIs where computed */
export const KPI_LEVEL = {
    line: "line",
    line_group: "line_group",
    plant: "plant"
};
export type KpiLevelEnum =
    "line" |
    "line_group" |
    "plant";
