// @flow

/************************************************************************************/
/* WARNING: This file is generated automatically. Don't edit it by hand             */
/************************************************************************************/

/** Unscheduled sort direction */
export const UNSCHEDULED_SORT_DIRECTION = {
    /** Sort by earliest start and group by process num */
    group_by_process_num: "group_by_process_num",
    /** Sort by earliest start */
    sort_by_earliest_start: "sort_by_earliest_start"
};
export type UnscheduledSortDirectionEnum =
    "group_by_process_num" |
    "sort_by_earliest_start";

/** Planning table save to Leap mode */
export const PLANNING_TABLE_SAVE_TO_LEAP_MODE = {
    /** Override all data, dates, sequence number, tags, capacity etc */
    full: "full",
    /** Only override capacity and tags */
    light: "light"
};
export type PlanningTableSaveToLeapModeEnum =
    "full" |
    "light";

/** Tool setup calculation type */
export const TOOL_SETUP_CALC_TYPES = {
    /** always use tool setup, the duration is stored on operation */
    always: "always",
    /** when codes differ use tool setup duration stored on operation */
    code_and_operation_fixed: "code_and_operation_fixed",
    /** matrix based (specifies duration in hours for transitions between codes */
    matrix: "matrix"
};
export type ToolSetupCalcTypesEnum =
    "always" |
    "code_and_operation_fixed" |
    "matrix";

/** Tool setup code type */
export const TOOL_SETUP_CODE_TYPES = {
    /** explicit code on operation */
    explicit: "explicit",
    /** material external id */
    material: "material",
    /** material external id + process number */
    material_process: "material_process"
};
export type ToolSetupCodeTypesEnum =
    "explicit" |
    "material" |
    "material_process";

/** Types of computing available column for stock outputing operations */
export const LINE_STOCK_AVAILABLE_TYPES = {
    /** Delay is provided as constant specified in a separate tag on the line */
    constant: "constant",
    /** Zero delay between produced and avilable */
    immediately: "immediately",
    /** Delay is computed from normative of the operation using norma base quantity */
    norm_base: "norm_base",
    /** Delay is computed from normative of the operation using total quantity */
    norm_total: "norm_total",
    /** Delay is computed by taking the fixed duration of the operation */
    time_end_time_start: "time_end_time_start"
};
export type LineStockAvailableTypesEnum =
    "constant" |
    "immediately" |
    "norm_base" |
    "norm_total" |
    "time_end_time_start";

/** Types of stock requirement dates */
export const STOCK_REQUIREMENTS_DATE_TYPES = {
    /** Date expected */
    date_expected: "date_expected",
    /** Date required */
    date_required: "date_required"
};
export type StockRequirementsDateTypesEnum =
    "date_expected" |
    "date_required";

/** Different types of manufacturing lines */
export const LINE_TYPES = {
    /** Special type of production - aging and similar batch operations. */
    aging: "aging",
    /** Type of production line that involves adding parts and components by workers. */
    assembly: "assembly",
    /** Virtual line for assigning cameraman. */
    cameraman: "cameraman",
    /** Virtual line for assigning quality control. */
    control: "control",
    /** Virtual line for assigning line-group lead. */
    line_group_lead: "line_group_lead",
    /** Manufacturing line used only for evidenting orders, no work needs to be scheduled or planned. */
    non_work: "non_work",
    /** Normal manufacturing line that processes orders. */
    ordinary: "ordinary",
    /** Packaging operations. */
    packaging: "packaging",
    /** Virtual line representing pool of people. */
    pool: "pool",
    /** Virtual line for assigning shift lead. */
    shift_lead: "shift_lead",
    /** Virtual line for assigning sick leave. */
    sick_leave: "sick_leave",
    /** Virtual line for denoting subcontraction. */
    subcontraction: "subcontraction",
    /** Virtual line for assigning tool-setup team. */
    tool_setup: "tool_setup"
};
export type LineTypesEnum =
    "aging" |
    "assembly" |
    "cameraman" |
    "control" |
    "line_group_lead" |
    "non_work" |
    "ordinary" |
    "packaging" |
    "pool" |
    "shift_lead" |
    "sick_leave" |
    "subcontraction" |
    "tool_setup";

/** Tags that are assignable to single line */
export const LINE_TAGS = {
    /** Flag if this line is bottleneck */
    bottleneck: "bottleneck",
    /** 21 characters long numerical string, each number represents shift of the week */
    calendar_line: "calendar_line",
    /** How many work orders can be executed in parallel on the line. This parameters is used as constraint by automatic planning. When >1, simulation allows for parallel execution of work orders starting with `time_start` and regardles of capacity. Default is `1`. */
    capacity_factor: "capacity_factor",
    /** If `true` then conditional capacity constraints will be used in the simulation that will restrict which operations can overlap based on their codes. Default is `false` */
    conditional_capacity_constraints: "conditional_capacity_constraints",
    /** Strategy for copying shift assignments from previous week. */
    copy_week_strategy: "copy_week_strategy",
    /** Profit center for computing eletricity cost of material */
    electricity_profit_center: "electricity_profit_center",
    /** ERP defines start and end time for operation that is used in simulation. */
    erp_date_determination: "erp_date_determination",
    /** Chunk orders support on gantt chart */
    gantt_chunk_orders: "gantt_chunk_orders",
    /** Parameter to control if the predictions on this line will use normatives insted of historical data models */
    ignore_prediction_model: "ignore_prediction_model",
    /** Parameter that controls if insights of the type 'no_person' should be skipped for this line. Default is 'false'. */
    insights_skip_no_person: "insights_skip_no_person",
    /** Parameter that controls if insights of the type 'no_shift' should be skipped for this line. Default is 'false'. */
    insights_skip_no_shift: "insights_skip_no_shift",
    /** Parameter that controls if insights of the type 'no_work_in_shift' should be skipped for this line. Default is 'false'. */
    insights_skip_no_work_in_shift: "insights_skip_no_work_in_shift",
    /** Parameter that controls if insights of the types 'plan_change_quantity', 'plan_change_shifts', 'plan_change_start' and 'plan_change_end' should be skipped for this line. Default is 'false'. */
    insights_skip_plan_change: "insights_skip_plan_change",
    /** When true, line is included in KPI OEE calculations */
    kpi_oee_calculation: "kpi_oee_calculation",
    /** When true, line is included in stock tracking KPIs */
    kpi_stock_tracking: "kpi_stock_tracking",
    /** When true, line is included in workforce capacity KPIs */
    kpi_workforce: "kpi_workforce",
    /** External ID of the line, which is also handled by worker scheduled on this line. */
    kpi_workforce_shared_line: "kpi_workforce_shared_line",
    /** External id of machine if available */
    machine_external_id: "machine_external_id",
    /** Indicates if line supports manual entry of chunk orders. Default is `false`. */
    manual_entry: "manual_entry",
    /** Overlaping lines */
    max_num_overlaping_lines: "max_num_overlaping_lines",
    /** Flag if this line is a child line based on its machine_external_id */
    multiple_machines_child: "multiple_machines_child",
    /** external_id of default multiple machine child line */
    multiple_machines_default_child: "multiple_machines_default_child",
    /** Flag if this is a parent line, supporting multiple machine child lines */
    multiple_machines_parent: "multiple_machines_parent",
    /** External id of multiple machine parent line */
    multiple_machines_parent_line_external_id: "multiple_machines_parent_line_external_id",
    /** If orders can overlap on this line. If 'true', automatic planning will use `capacity_factor` tag. Default is 'false'. */
    overlapping_orders_line: "overlapping_orders_line",
    /** If true operations on this line can output to stock, if false they cannot. Default true. */
    plan_import_output_stock: "plan_import_output_stock",
    /** Set `batch=true`` to all processes on this line during plan import. The flag `batch=true`` is needed on processes in order to simulate them as batches. */
    plan_import_set_batch: "plan_import_set_batch",
    /** Simulation automatically schedules operations on this line according to other operations from same order */
    planning_autopilot: "planning_autopilot",
    /** Parameter defines direction of rotation when copying previous week's schedule when using `shift_preference_rotate` and `four_shift_rotation` strategies. Option `true` means 321 shift rotation. Option `false` means 123 shift rotation. */
    rotate_opposite_direction: "rotate_opposite_direction",
    /** Parameter that controls if orders can be automatically closed when productions reach total order quantity. Default is 'false' and allows such automatic closing. */
    skip_autoclose_orders: "skip_autoclose_orders",
    /** Flag if planning should be skipped for line */
    skip_planning: "skip_planning",
    /** Flag if shift assignments should be skipped for line */
    skip_shift_board: "skip_shift_board",
    /** Flag if stock analysis should be skipped for line */
    skip_stock_analysis: "skip_stock_analysis",
    /** Flag that excludes line from weekly realization */
    skip_weekly_realization: "skip_weekly_realization",
    /** When `stock_available_type` is set to `constant` this tag defines the delay in hours */
    stock_available_delay_hours: "stock_available_delay_hours",
    /** Defines how availabe column is computed for operations preceeding operations on this line. */
    stock_available_type: "stock_available_type",
    /** If defined, the line is treated as having sublines. Each subline is displayed separately on the planning board. Default is 1, interpreted as a standard line (without multiple sublines). */
    sublines_number: "sublines_number",
    /** Type of calculation for tool setup, for possible values see TOOL_SETUP_CALC_TYPES */
    tool_setup_calc_type: "tool_setup_calc_type",
    /** Determines how the codes used by tool-setup calculation are computed, for possible values see TOOL_SETUP_CODE_TYPES */
    tool_setup_code_type: "tool_setup_code_type",
    /** Type of this line, for possible values see LINE_TYPES */
    type: "type",
    /** When `true` operations on line are only used for BOM and are not terminated or simulated otherwise. Their BOM is attached to the first previous operation not on use_only_for_bom_in_simulation line. In case no such operation is available, then it is used on the first next such operation. */
    use_only_for_bom_in_simulation: "use_only_for_bom_in_simulation"
};

/** Tags that are assignable to single line */
export const LINE_TAGS_ACCESS = {
    /** Flag if this line is bottleneck */
    bottleneck: (tags: any): boolean => (defVal(tags[LINE_TAGS.bottleneck], "false")) == "true",
    /** 21 characters long numerical string, each number represents shift of the week */
    calendar_line: (tags: any): string => defVal(tags[LINE_TAGS.calendar_line], ""),
    /** How many work orders can be executed in parallel on the line. This parameters is used as constraint by automatic planning. When >1, simulation allows for parallel execution of work orders starting with `time_start` and regardles of capacity. Default is `1`. */
    capacity_factor: (tags: any): number => parseFloat(defVal(tags[LINE_TAGS.capacity_factor], "1")),
    /** If `true` then conditional capacity constraints will be used in the simulation that will restrict which operations can overlap based on their codes. Default is `false` */
    conditional_capacity_constraints: (tags: any): boolean => (defVal(tags[LINE_TAGS.conditional_capacity_constraints], "false")) == "true",
    /** Strategy for copying shift assignments from previous week. */
    copy_week_strategy: (tags: any): string => defVal(tags[LINE_TAGS.copy_week_strategy], ""),
    /** Profit center for computing eletricity cost of material */
    electricity_profit_center: (tags: any): string => defVal(tags[LINE_TAGS.electricity_profit_center], ""),
    /** ERP defines start and end time for operation that is used in simulation. */
    erp_date_determination: (tags: any): boolean => (defVal(tags[LINE_TAGS.erp_date_determination], "false")) == "true",
    /** Chunk orders support on gantt chart */
    gantt_chunk_orders: (tags: any): boolean => (defVal(tags[LINE_TAGS.gantt_chunk_orders], "false")) == "true",
    /** Parameter to control if the predictions on this line will use normatives insted of historical data models */
    ignore_prediction_model: (tags: any): boolean => (defVal(tags[LINE_TAGS.ignore_prediction_model], "false")) == "true",
    /** Parameter that controls if insights of the type 'no_person' should be skipped for this line. Default is 'false'. */
    insights_skip_no_person: (tags: any): boolean => (defVal(tags[LINE_TAGS.insights_skip_no_person], "false")) == "true",
    /** Parameter that controls if insights of the type 'no_shift' should be skipped for this line. Default is 'false'. */
    insights_skip_no_shift: (tags: any): boolean => (defVal(tags[LINE_TAGS.insights_skip_no_shift], "false")) == "true",
    /** Parameter that controls if insights of the type 'no_work_in_shift' should be skipped for this line. Default is 'false'. */
    insights_skip_no_work_in_shift: (tags: any): boolean => (defVal(tags[LINE_TAGS.insights_skip_no_work_in_shift], "false")) == "true",
    /** Parameter that controls if insights of the types 'plan_change_quantity', 'plan_change_shifts', 'plan_change_start' and 'plan_change_end' should be skipped for this line. Default is 'false'. */
    insights_skip_plan_change: (tags: any): boolean => (defVal(tags[LINE_TAGS.insights_skip_plan_change], "false")) == "true",
    /** When true, line is included in KPI OEE calculations */
    kpi_oee_calculation: (tags: any): boolean => (defVal(tags[LINE_TAGS.kpi_oee_calculation], "false")) == "true",
    /** When true, line is included in stock tracking KPIs */
    kpi_stock_tracking: (tags: any): boolean => (defVal(tags[LINE_TAGS.kpi_stock_tracking], "false")) == "true",
    /** When true, line is included in workforce capacity KPIs */
    kpi_workforce: (tags: any): boolean => (defVal(tags[LINE_TAGS.kpi_workforce], "false")) == "true",
    /** External ID of the line, which is also handled by worker scheduled on this line. */
    kpi_workforce_shared_line: (tags: any): string => defVal(tags[LINE_TAGS.kpi_workforce_shared_line], ""),
    /** External id of machine if available */
    machine_external_id: (tags: any): string => defVal(tags[LINE_TAGS.machine_external_id], ""),
    /** Indicates if line supports manual entry of chunk orders. Default is `false`. */
    manual_entry: (tags: any): boolean => (defVal(tags[LINE_TAGS.manual_entry], "false")) == "true",
    /** Overlaping lines */
    max_num_overlaping_lines: (tags: any): number => parseFloat(defVal(tags[LINE_TAGS.max_num_overlaping_lines], "-1")),
    /** Flag if this line is a child line based on its machine_external_id */
    multiple_machines_child: (tags: any): boolean => (defVal(tags[LINE_TAGS.multiple_machines_child], "false")) == "true",
    /** external_id of default multiple machine child line */
    multiple_machines_default_child: (tags: any): string => defVal(tags[LINE_TAGS.multiple_machines_default_child], ""),
    /** Flag if this is a parent line, supporting multiple machine child lines */
    multiple_machines_parent: (tags: any): boolean => (defVal(tags[LINE_TAGS.multiple_machines_parent], "false")) == "true",
    /** External id of multiple machine parent line */
    multiple_machines_parent_line_external_id: (tags: any): string => defVal(tags[LINE_TAGS.multiple_machines_parent_line_external_id], ""),
    /** If orders can overlap on this line. If 'true', automatic planning will use `capacity_factor` tag. Default is 'false'. */
    overlapping_orders_line: (tags: any): boolean => (defVal(tags[LINE_TAGS.overlapping_orders_line], "false")) == "true",
    /** If true operations on this line can output to stock, if false they cannot. Default true. */
    plan_import_output_stock: (tags: any): boolean => (defVal(tags[LINE_TAGS.plan_import_output_stock], "false")) == "true",
    /** Set `batch=true`` to all processes on this line during plan import. The flag `batch=true`` is needed on processes in order to simulate them as batches. */
    plan_import_set_batch: (tags: any): boolean => (defVal(tags[LINE_TAGS.plan_import_set_batch], "false")) == "true",
    /** Simulation automatically schedules operations on this line according to other operations from same order */
    planning_autopilot: (tags: any): boolean => (defVal(tags[LINE_TAGS.planning_autopilot], "false")) == "true",
    /** Parameter defines direction of rotation when copying previous week's schedule when using `shift_preference_rotate` and `four_shift_rotation` strategies. Option `true` means 321 shift rotation. Option `false` means 123 shift rotation. */
    rotate_opposite_direction: (tags: any): boolean => (defVal(tags[LINE_TAGS.rotate_opposite_direction], "false")) == "true",
    /** Parameter that controls if orders can be automatically closed when productions reach total order quantity. Default is 'false' and allows such automatic closing. */
    skip_autoclose_orders: (tags: any): boolean => (defVal(tags[LINE_TAGS.skip_autoclose_orders], "false")) == "true",
    /** Flag if planning should be skipped for line */
    skip_planning: (tags: any): boolean => (defVal(tags[LINE_TAGS.skip_planning], "false")) == "true",
    /** Flag if shift assignments should be skipped for line */
    skip_shift_board: (tags: any): boolean => (defVal(tags[LINE_TAGS.skip_shift_board], "false")) == "true",
    /** Flag if stock analysis should be skipped for line */
    skip_stock_analysis: (tags: any): boolean => (defVal(tags[LINE_TAGS.skip_stock_analysis], "false")) == "true",
    /** Flag that excludes line from weekly realization */
    skip_weekly_realization: (tags: any): boolean => (defVal(tags[LINE_TAGS.skip_weekly_realization], "false")) == "true",
    /** When `stock_available_type` is set to `constant` this tag defines the delay in hours */
    stock_available_delay_hours: (tags: any): number => parseFloat(defVal(tags[LINE_TAGS.stock_available_delay_hours], "-1")),
    /** Defines how availabe column is computed for operations preceeding operations on this line. */
    stock_available_type: (tags: any): string => defVal(tags[LINE_TAGS.stock_available_type], LINE_STOCK_AVAILABLE_TYPES.immediately),
    /** If defined, the line is treated as having sublines. Each subline is displayed separately on the planning board. Default is 1, interpreted as a standard line (without multiple sublines). */
    sublines_number: (tags: any): number => parseFloat(defVal(tags[LINE_TAGS.sublines_number], "1")),
    /** Type of calculation for tool setup, for possible values see TOOL_SETUP_CALC_TYPES */
    tool_setup_calc_type: (tags: any): string => defVal(tags[LINE_TAGS.tool_setup_calc_type], ""),
    /** Determines how the codes used by tool-setup calculation are computed, for possible values see TOOL_SETUP_CODE_TYPES */
    tool_setup_code_type: (tags: any): string => defVal(tags[LINE_TAGS.tool_setup_code_type], ""),
    /** Type of this line, for possible values see LINE_TYPES */
    type: (tags: any): string => defVal(tags[LINE_TAGS.type], ""),
    /** When `true` operations on line are only used for BOM and are not terminated or simulated otherwise. Their BOM is attached to the first previous operation not on use_only_for_bom_in_simulation line. In case no such operation is available, then it is used on the first next such operation. */
    use_only_for_bom_in_simulation: (tags: any): boolean => (defVal(tags[LINE_TAGS.use_only_for_bom_in_simulation], "false")) == "true"
};

export const LINE_GROUP_TAGS = {
    /** Does linegroup supports automatic scheduling on the planning board? */
    automatic_scheduling: "automatic_scheduling",
    /** Flag indicating that this line-group represents batch operations, e.g. aging furnaces. */
    batch_op: "batch_op",
    /** Strategy for copying shift assignments from previous week, for all lines in this linegroup. */
    copy_week_strategy: "copy_week_strategy",
    /** Parameter which defines what shifts are exported in the daily export. Option `123` means export day's morning afternoon and night shifts. `231` means export day's afternoon and night shifts and following day's morning shift. */
    day_shift_assignment_format: "day_shift_assignment_format",
    /** If true, extract conditional_capacity_constraint insights, which checks compatibility of overlaping operations */
    extract_conditional_capacity_constraint_insights: "extract_conditional_capacity_constraint_insights",
    /** Deprecated. Flag if this line-group uses gantt-chart. */
    gannt: "gannt",
    /** Optional parameter which defines minimal number of pixles one day should take in gantt chart. Default is `40`. */
    gantt_min_day_width: "gantt_min_day_width",
    /** Optional parameter, which defines how many days are shown on the Gantt chart. Default value is `28`. */
    gantt_number_of_days: "gantt_number_of_days",
    /** Optional parameter, which defines how many past weeks are shown on the Gantt chart. Default value is given by the simulation `1`. */
    gantt_past_weeks: "gantt_past_weeks",
    /** Indicates if empty lines should be shown on Gantt chart */
    gantt_show_empty_lines: "gantt_show_empty_lines",
    /** Defines if 'Show history' option in gantt chart is enabled by default. Default value is `true`. */
    gantt_show_history: "gantt_show_history",
    /** Parameter which indicates that this line-group uses pool for people assignments. */
    has_pool: "has_pool",
    /** If true hides this line from GUI navigation */
    hide_in_navigation: "hide_in_navigation",
    /** Tolerance for creating downstream delays. Default values is 0.0001. */
    insight_downstream_tolerance: "insight_downstream_tolerance",
    /** Indicates if long-term stock forecast should be shown in stocks view */
    long_term_stock_forecast: "long_term_stock_forecast",
    /** Indicates if manual order entry page should be shown in plan view. Default is `false`. */
    manual_order_entry: "manual_order_entry",
    /** Indicator that this line-group uses microplan for planning */
    microplan: "microplan",
    /** Optional parameter, which defines how many days are shown on the Gantt chart showing a single line. Default value is `28`. */
    microplan_gantt_number_of_days: "microplan_gantt_number_of_days",
    /** Optional parameter, which defines if single line gantt shows history. Default is `true` */
    microplan_gantt_show_history: "microplan_gantt_show_history",
    /** Run new full simulation in the background on plan changes in microplan. Default is `false` */
    microplan_recalc_on_save: "microplan_recalc_on_save",
    /** Refresh report on microplan page (in minutes) */
    microplan_refresh_minutes: "microplan_refresh_minutes",
    /** Indicates if alternative unit should be shown on microplan */
    microplan_show_alternative_unit: "microplan_show_alternative_unit",
    /** Optional parameter, which defines if order table is shown. Default is `true` */
    microplan_show_order_table: "microplan_show_order_table",
    /** Indicates if expected completion should be shown in work orders table on microplan (only if `microplan_show_order_table_shifts` is disabled) */
    microplan_show_order_table_expected_completion: "microplan_show_order_table_expected_completion",
    /** Indicates if expected start should be shown in work orders table on microplan (only if `microplan_show_order_table_shifts` is disabled) */
    microplan_show_order_table_expected_start: "microplan_show_order_table_expected_start",
    /** Indicates if external operation id should be shown in work orders table on microplan */
    microplan_show_order_table_external_operation_id: "microplan_show_order_table_external_operation_id",
    /** Indicates if material should be shown in work orders table on microplan */
    microplan_show_order_table_material: "microplan_show_order_table_material",
    /** Indicates if material comment should be shown in work orders table on microplan */
    microplan_show_order_table_material_comment: "microplan_show_order_table_material_comment",
    /** Indicates if operation should be shown in work orders table on microplan */
    microplan_show_order_table_operation: "microplan_show_order_table_operation",
    /** Indicates if operation comment should be shown in work orders table on microplan */
    microplan_show_order_table_operation_comment: "microplan_show_order_table_operation_comment",
    /** Indicates if order ID should be shown in work orders table on microplan */
    microplan_show_order_table_order_id: "microplan_show_order_table_order_id",
    /** Indicates if quantity should be shown in work orders table on microplan */
    microplan_show_order_table_quantity: "microplan_show_order_table_quantity",
    /** Optional parameter, which defines if order table details individual shifts. Default is `false` */
    microplan_show_order_table_shifts: "microplan_show_order_table_shifts",
    /** Indicates if time to complete should be shown in work orders table on microplan */
    microplan_show_order_table_time_to_complete: "microplan_show_order_table_time_to_complete",
    /** Indicates if unscheduled orders should be shown in work orders table on microplan */
    microplan_show_order_table_unscheduled_orders: "microplan_show_order_table_unscheduled_orders",
    /** Show ERP dates in microplan order list. Default is `true` */
    microplan_show_sap_dates: "microplan_show_sap_dates",
    /** Optional parameter, which defines if shift table is shown. Default is `false` */
    microplan_show_shift_table: "microplan_show_shift_table",
    /** Storage locations that should be displayed on microplan stock forecast views. */
    microplan_stock_forecast_location: "microplan_stock_forecast_location",
    /** Optional parameter, which defines which weeks are shown on the microplan week-table view. Default value is `[-1,0,1,2]` */
    microplan_weeks: "microplan_weeks",
    /** Parameter defines how many shifts in the future 'No worker' insights should be generated for pooled lines. It can take up to 10 minutes for the changes to be visible. */
    no_worker_horizon_shifts: "no_worker_horizon_shifts",
    /** Sets skip_sim flag for operations that are not schedulled in external system */
    plan_import_scheduled_statuses_skip_sim: "plan_import_scheduled_statuses_skip_sim",
    /** Enable planning board */
    planning_board: "planning_board",
    /** Can planned order be broken into smaller chunks. Default is `false`. */
    planning_break_orders: "planning_break_orders",
    /** Complete input materials must be available at the start of the operation (no overlapping). Default is `true` */
    planning_complete_materials_at_start: "planning_complete_materials_at_start",
    /** Tolerance allowed when tracking materials exactly. Example, 90% tolerance equals value 0.9. Default is 1.0 (no tolerance). */
    planning_exact_tolerance: "planning_exact_tolerance",
    /** Meta-optimizations of enabled shifts to be followed in cron-initiated automatic scheduling */
    planning_meta_optimization: "planning_meta_optimization",
    /** Indicates if meta-optimization parameter should be shown in scheduling options. Default is `false`. */
    planning_meta_optimization_param: "planning_meta_optimization_param",
    /** Mode of automatic planning - by default 'lines_first'. */
    planning_mode: "planning_mode",
    /** Allow operations within the order to overlap. Default is `false` */
    planning_order_operations_overlap: "planning_order_operations_overlap",
    /** Number of shifts we assume, when defined it overrides existing enabled shifts. Value 0 means enable shifts with at least one person scheduled to work. */
    planning_override_shifts: "planning_override_shifts",
    /** Ramp-up factor for planning - 'warm-up' penalty. Default is 1. */
    planning_ramp_up_factor: "planning_ramp_up_factor",
    /** Increase of penalty for material-change when setup-code is different. Default is 2. */
    planning_setup_code_change_factor: "planning_setup_code_change_factor",
    /** Should needs penalty be smoothed. Default is `true`. */
    planning_smooth_needs: "planning_smooth_needs",
    /** Minimal length of chunks in hours. Default is 8. */
    planning_split_order_min_hours: "planning_split_order_min_hours",
    /** Plan only orders with earliest start before 'planning_table_horizon_days' from now */
    planning_table_horizon_days: "planning_table_horizon_days",
    /** Speeds up planning table when dealing with many orders */
    planning_table_lazy_rendering: "planning_table_lazy_rendering",
    /** Determines for which orders users can edit quantity on planning table. Default is `all_orders`. */
    planning_table_orders_with_editable_quantity: "planning_table_orders_with_editable_quantity",
    /** Enable planning board save to erp button */
    planning_table_save_to_erp: "planning_table_save_to_erp",
    /** Number of weeks used for recalculating simulation when saving to ERP */
    planning_table_save_to_erp_weeks: "planning_table_save_to_erp_weeks",
    /** Indicates if issues tab should be shown on planning table. Default is `false`. */
    planning_table_show_issues_tab: "planning_table_show_issues_tab",
    /** Indicates if 'link operation' action should be available on planning table. Default is `false`. */
    planning_table_show_link_operation: "planning_table_show_link_operation",
    /** Indicates if tool changes should be shown on planning table */
    planning_table_show_tool_change: "planning_table_show_tool_change",
    /** When saving to ERP, move unscheduled orders to the end of planning horizon or leave them as is (default false) */
    planning_table_unscheduled_move: "planning_table_unscheduled_move",
    /** Number of weeks displayed on the planning board */
    planning_table_weeks: "planning_table_weeks",
    /** The time-horizon extra buffer to account for the time orders starting in time horizont need to finish. Should correspond to maximal order lenght. Default is 7. */
    planning_time_horizont_buffer: "planning_time_horizont_buffer",
    /** The time-horizon extra days beyond time horizont for including needs in the planning process. Default is 7. */
    planning_time_horizont_needs: "planning_time_horizont_needs",
    /** The time-horizon for planning. Default is 42 (two weeks). */
    planning_time_horizont_shifts: "planning_time_horizont_shifts",
    /** Choose unit for horizon on automatic scheduling on planning table */
    planning_time_horizont_unit: "planning_time_horizont_unit",
    /** Tool setup penalty share in BFS score. This is multiplied with the tool setup criteria score. Default is 4. */
    planning_tool_setup_factor: "planning_tool_setup_factor",
    /** Should planning account for customer priority. Default is `false` */
    planning_track_customer_priorty: "planning_track_customer_priorty",
    /** Should input-materials be tracked duringh planning. Default is `false`. */
    planning_track_input_material: "planning_track_input_material",
    /** Should planning account for value of material on stock. Default is `false`. */
    planning_track_stock_levels: "planning_track_stock_levels",
    /** Use material codes in planning to compute changover times. Default is `false` */
    planning_use_material_codes: "planning_use_material_codes",
    /** Default factor for weighting planned work duration in planning. Default is 0. */
    planning_work_duration_factor: "planning_work_duration_factor",
    /** Print shift-board only for lineswith assigned worker for the line-group */
    print_only_assigned_lines: "print_only_assigned_lines",
    /** Parameter defines direction of rotation when copying previous week when using `shift_preference_rotate` strategy. Option `true` means 321 shift rotation. Option `false` means 123 shift rotation. */
    rotate_opposite_direction: "rotate_opposite_direction",
    /** Indicates if all operations of the same order shuld be (un)selected when single operation is (un)selected in save dialog on planning table. Default is `false`. */
    save_all_operations_to_erp: "save_all_operations_to_erp",
    /** Indicates if all operations on the same initial/final line shuld be (un)selected when single operation is (un)selected in save dialog on planning table. Default is `false`. */
    save_whole_lines_to_erp: "save_whole_lines_to_erp",
    /** Parameter which groups the exported of the daily schedule by people instead of grouping by lines */
    shift_assignment_group_by_people: "shift_assignment_group_by_people",
    /** Indicator that this line-group uses shift-board for assinments of people to lines */
    shift_board: "shift_board",
    /** Forecasting enables warehouses to keep track of sales and anticipated demand, so they can ensure that the available stock is adequate to meet customer needs. */
    stock_forecast: "stock_forecast",
    /** The number of days that defines the time window for displaying stock forecast in 'day' view. Value must be between 1 and 28. Default is 14. */
    stock_forecast_display_horizon_day: "stock_forecast_display_horizon_day",
    /** The number of days that defines the time window for displaying stock forecast in 'shift' view. Value must be between 1 and 28. Default is 7. */
    stock_forecast_display_horizon_shift: "stock_forecast_display_horizon_shift",
    /** The number of days that defines the time window for displaying stock forecast in 'week' view. Value must be between 1 and 28. Default is 28. */
    stock_forecast_display_horizon_week: "stock_forecast_display_horizon_week",
    /** Default state of the shop-floor only toggle. Default is `true` */
    stock_forecast_shopfloor_option_default: "stock_forecast_shopfloor_option_default",
    /** Show safety stock levels on stock forecast page. Default is `false`. */
    stock_forecast_show_safety_stock: "stock_forecast_show_safety_stock",
    /** Shall we show the option to toggle between shop-floor only and overall inventory level on stock forecast page. Default is `true` */
    stock_forecast_show_shopfloor_option: "stock_forecast_show_shopfloor_option",
    /** Indicates if 'Withdrawn and unaccounted' option should be shown on stock forecast page. Default is `true`. */
    stock_forecast_show_withdrawn_and_unaccounted_option: "stock_forecast_show_withdrawn_and_unaccounted_option",
    /** Default state of the 'Withdrawn and unaccounted' option checkbox for stock forecast. Default is `false`. */
    stock_forecast_withdrawn_and_unaccounted_default: "stock_forecast_withdrawn_and_unaccounted_default",
    /** Determines which quantity is displayed in basic view on stock status page. Default is `erp_quantity`. */
    stock_status_basic_view_quantity_column: "stock_status_basic_view_quantity_column",
    /** Indicator that this line-group uses subcontracting */
    subcontracting: "subcontracting",
    /** If status of an operation stays unscheduled, do not highlight it as changed operation on Synchronize and refresh dialog box. Changed operations are marked white, while unchanged operations are marked grey. For example if a user schedules an operation to a line, but moves it back to unscheduled in the same iteration, the operation will be marked as unchanged. Default is `false`. */
    unscheduled_operations_marked_unchanged: "unscheduled_operations_marked_unchanged",
    /** Indicator that this line-group uses weekly realization */
    weekly_realization: "weekly_realization",
    /** Indicator to show downtimes in dropdown */
    weekly_realization_downtimes_view: "weekly_realization_downtimes_view",
    /** Indicator to show scrap in dropdown */
    weekly_realization_scrap_view: "weekly_realization_scrap_view",
    /** Optional parameter, which defines if we show workers in the weekly realization view. Default value is `true`. */
    weekly_realization_show_workers: "weekly_realization_show_workers"
};

export const LINE_GROUP_TAGS_ACCESS = {
    /** Does linegroup supports automatic scheduling on the planning board? */
    automatic_scheduling: (tags: any): boolean => (defVal(tags[LINE_GROUP_TAGS.automatic_scheduling], "false")) == "true",
    /** Flag indicating that this line-group represents batch operations, e.g. aging furnaces. */
    batch_op: (tags: any): boolean => (defVal(tags[LINE_GROUP_TAGS.batch_op], "false")) == "true",
    /** Strategy for copying shift assignments from previous week, for all lines in this linegroup. */
    copy_week_strategy: (tags: any): string => defVal(tags[LINE_GROUP_TAGS.copy_week_strategy], ""),
    /** Parameter which defines what shifts are exported in the daily export. Option `123` means export day's morning afternoon and night shifts. `231` means export day's afternoon and night shifts and following day's morning shift. */
    day_shift_assignment_format: (tags: any): string => defVal(tags[LINE_GROUP_TAGS.day_shift_assignment_format], ""),
    /** If true, extract conditional_capacity_constraint insights, which checks compatibility of overlaping operations */
    extract_conditional_capacity_constraint_insights: (tags: any): boolean => (defVal(tags[LINE_GROUP_TAGS.extract_conditional_capacity_constraint_insights], "false")) == "true",
    /** Deprecated. Flag if this line-group uses gantt-chart. */
    gannt: (tags: any): boolean => (defVal(tags[LINE_GROUP_TAGS.gannt], "false")) == "true",
    /** Optional parameter which defines minimal number of pixles one day should take in gantt chart. Default is `40`. */
    gantt_min_day_width: (tags: any): number => parseFloat(defVal(tags[LINE_GROUP_TAGS.gantt_min_day_width], "40")),
    /** Optional parameter, which defines how many days are shown on the Gantt chart. Default value is `28`. */
    gantt_number_of_days: (tags: any): number => parseFloat(defVal(tags[LINE_GROUP_TAGS.gantt_number_of_days], "28")),
    /** Optional parameter, which defines how many past weeks are shown on the Gantt chart. Default value is given by the simulation `1`. */
    gantt_past_weeks: (tags: any): number => parseFloat(defVal(tags[LINE_GROUP_TAGS.gantt_past_weeks], "1")),
    /** Indicates if empty lines should be shown on Gantt chart */
    gantt_show_empty_lines: (tags: any): boolean => (defVal(tags[LINE_GROUP_TAGS.gantt_show_empty_lines], "false")) == "true",
    /** Defines if 'Show history' option in gantt chart is enabled by default. Default value is `true`. */
    gantt_show_history: (tags: any): boolean => (defVal(tags[LINE_GROUP_TAGS.gantt_show_history], "false")) == "true",
    /** Parameter which indicates that this line-group uses pool for people assignments. */
    has_pool: (tags: any): boolean => (defVal(tags[LINE_GROUP_TAGS.has_pool], "false")) == "true",
    /** If true hides this line from GUI navigation */
    hide_in_navigation: (tags: any): boolean => (defVal(tags[LINE_GROUP_TAGS.hide_in_navigation], "false")) == "true",
    /** Tolerance for creating downstream delays. Default values is 0.0001. */
    insight_downstream_tolerance: (tags: any): number => parseFloat(defVal(tags[LINE_GROUP_TAGS.insight_downstream_tolerance], "0.0001")),
    /** Indicates if long-term stock forecast should be shown in stocks view */
    long_term_stock_forecast: (tags: any): boolean => (defVal(tags[LINE_GROUP_TAGS.long_term_stock_forecast], "false")) == "true",
    /** Indicates if manual order entry page should be shown in plan view. Default is `false`. */
    manual_order_entry: (tags: any): boolean => (defVal(tags[LINE_GROUP_TAGS.manual_order_entry], "false")) == "true",
    /** Indicator that this line-group uses microplan for planning */
    microplan: (tags: any): boolean => (defVal(tags[LINE_GROUP_TAGS.microplan], "false")) == "true",
    /** Optional parameter, which defines how many days are shown on the Gantt chart showing a single line. Default value is `28`. */
    microplan_gantt_number_of_days: (tags: any): number => parseFloat(defVal(tags[LINE_GROUP_TAGS.microplan_gantt_number_of_days], "28")),
    /** Optional parameter, which defines if single line gantt shows history. Default is `true` */
    microplan_gantt_show_history: (tags: any): boolean => (defVal(tags[LINE_GROUP_TAGS.microplan_gantt_show_history], "true")) == "true",
    /** Run new full simulation in the background on plan changes in microplan. Default is `false` */
    microplan_recalc_on_save: (tags: any): boolean => (defVal(tags[LINE_GROUP_TAGS.microplan_recalc_on_save], "false")) == "true",
    /** Refresh report on microplan page (in minutes) */
    microplan_refresh_minutes: (tags: any): number => parseFloat(defVal(tags[LINE_GROUP_TAGS.microplan_refresh_minutes], "-1")),
    /** Indicates if alternative unit should be shown on microplan */
    microplan_show_alternative_unit: (tags: any): boolean => (defVal(tags[LINE_GROUP_TAGS.microplan_show_alternative_unit], "false")) == "true",
    /** Optional parameter, which defines if order table is shown. Default is `true` */
    microplan_show_order_table: (tags: any): boolean => (defVal(tags[LINE_GROUP_TAGS.microplan_show_order_table], "true")) == "true",
    /** Indicates if expected completion should be shown in work orders table on microplan (only if `microplan_show_order_table_shifts` is disabled) */
    microplan_show_order_table_expected_completion: (tags: any): boolean => (defVal(tags[LINE_GROUP_TAGS.microplan_show_order_table_expected_completion], "true")) == "true",
    /** Indicates if expected start should be shown in work orders table on microplan (only if `microplan_show_order_table_shifts` is disabled) */
    microplan_show_order_table_expected_start: (tags: any): boolean => (defVal(tags[LINE_GROUP_TAGS.microplan_show_order_table_expected_start], "true")) == "true",
    /** Indicates if external operation id should be shown in work orders table on microplan */
    microplan_show_order_table_external_operation_id: (tags: any): boolean => (defVal(tags[LINE_GROUP_TAGS.microplan_show_order_table_external_operation_id], "false")) == "true",
    /** Indicates if material should be shown in work orders table on microplan */
    microplan_show_order_table_material: (tags: any): boolean => (defVal(tags[LINE_GROUP_TAGS.microplan_show_order_table_material], "true")) == "true",
    /** Indicates if material comment should be shown in work orders table on microplan */
    microplan_show_order_table_material_comment: (tags: any): boolean => (defVal(tags[LINE_GROUP_TAGS.microplan_show_order_table_material_comment], "false")) == "true",
    /** Indicates if operation should be shown in work orders table on microplan */
    microplan_show_order_table_operation: (tags: any): boolean => (defVal(tags[LINE_GROUP_TAGS.microplan_show_order_table_operation], "true")) == "true",
    /** Indicates if operation comment should be shown in work orders table on microplan */
    microplan_show_order_table_operation_comment: (tags: any): boolean => (defVal(tags[LINE_GROUP_TAGS.microplan_show_order_table_operation_comment], "false")) == "true",
    /** Indicates if order ID should be shown in work orders table on microplan */
    microplan_show_order_table_order_id: (tags: any): boolean => (defVal(tags[LINE_GROUP_TAGS.microplan_show_order_table_order_id], "true")) == "true",
    /** Indicates if quantity should be shown in work orders table on microplan */
    microplan_show_order_table_quantity: (tags: any): boolean => (defVal(tags[LINE_GROUP_TAGS.microplan_show_order_table_quantity], "true")) == "true",
    /** Optional parameter, which defines if order table details individual shifts. Default is `false` */
    microplan_show_order_table_shifts: (tags: any): boolean => (defVal(tags[LINE_GROUP_TAGS.microplan_show_order_table_shifts], "false")) == "true",
    /** Indicates if time to complete should be shown in work orders table on microplan */
    microplan_show_order_table_time_to_complete: (tags: any): boolean => (defVal(tags[LINE_GROUP_TAGS.microplan_show_order_table_time_to_complete], "true")) == "true",
    /** Indicates if unscheduled orders should be shown in work orders table on microplan */
    microplan_show_order_table_unscheduled_orders: (tags: any): boolean => (defVal(tags[LINE_GROUP_TAGS.microplan_show_order_table_unscheduled_orders], "false")) == "true",
    /** Show ERP dates in microplan order list. Default is `true` */
    microplan_show_sap_dates: (tags: any): boolean => (defVal(tags[LINE_GROUP_TAGS.microplan_show_sap_dates], "true")) == "true",
    /** Optional parameter, which defines if shift table is shown. Default is `false` */
    microplan_show_shift_table: (tags: any): boolean => (defVal(tags[LINE_GROUP_TAGS.microplan_show_shift_table], "false")) == "true",
    /** Storage locations that should be displayed on microplan stock forecast views. */
    microplan_stock_forecast_location: (tags: any): string => defVal(tags[LINE_GROUP_TAGS.microplan_stock_forecast_location], ""),
    /** Optional parameter, which defines which weeks are shown on the microplan week-table view. Default value is `[-1,0,1,2]` */
    microplan_weeks: (tags: any): string => defVal(tags[LINE_GROUP_TAGS.microplan_weeks], ""),
    /** Parameter defines how many shifts in the future 'No worker' insights should be generated for pooled lines. It can take up to 10 minutes for the changes to be visible. */
    no_worker_horizon_shifts: (tags: any): number => parseFloat(defVal(tags[LINE_GROUP_TAGS.no_worker_horizon_shifts], "3")),
    /** Sets skip_sim flag for operations that are not schedulled in external system */
    plan_import_scheduled_statuses_skip_sim: (tags: any): boolean => (defVal(tags[LINE_GROUP_TAGS.plan_import_scheduled_statuses_skip_sim], "false")) == "true",
    /** Enable planning board */
    planning_board: (tags: any): boolean => (defVal(tags[LINE_GROUP_TAGS.planning_board], "false")) == "true",
    /** Can planned order be broken into smaller chunks. Default is `false`. */
    planning_break_orders: (tags: any): boolean => (defVal(tags[LINE_GROUP_TAGS.planning_break_orders], "false")) == "true",
    /** Complete input materials must be available at the start of the operation (no overlapping). Default is `true` */
    planning_complete_materials_at_start: (tags: any): boolean => (defVal(tags[LINE_GROUP_TAGS.planning_complete_materials_at_start], "true")) == "true",
    /** Tolerance allowed when tracking materials exactly. Example, 90% tolerance equals value 0.9. Default is 1.0 (no tolerance). */
    planning_exact_tolerance: (tags: any): number => parseFloat(defVal(tags[LINE_GROUP_TAGS.planning_exact_tolerance], "1")),
    /** Meta-optimizations of enabled shifts to be followed in cron-initiated automatic scheduling */
    planning_meta_optimization: (tags: any): string => defVal(tags[LINE_GROUP_TAGS.planning_meta_optimization], ""),
    /** Indicates if meta-optimization parameter should be shown in scheduling options. Default is `false`. */
    planning_meta_optimization_param: (tags: any): boolean => (defVal(tags[LINE_GROUP_TAGS.planning_meta_optimization_param], "false")) == "true",
    /** Mode of automatic planning - by default 'lines_first'. */
    planning_mode: (tags: any): string => defVal(tags[LINE_GROUP_TAGS.planning_mode], PLANNING_MODE.lines_first),
    /** Allow operations within the order to overlap. Default is `false` */
    planning_order_operations_overlap: (tags: any): boolean => (defVal(tags[LINE_GROUP_TAGS.planning_order_operations_overlap], "false")) == "true",
    /** Number of shifts we assume, when defined it overrides existing enabled shifts. Value 0 means enable shifts with at least one person scheduled to work. */
    planning_override_shifts: (tags: any): number => parseFloat(defVal(tags[LINE_GROUP_TAGS.planning_override_shifts], "-1")),
    /** Ramp-up factor for planning - 'warm-up' penalty. Default is 1. */
    planning_ramp_up_factor: (tags: any): number => parseFloat(defVal(tags[LINE_GROUP_TAGS.planning_ramp_up_factor], "1")),
    /** Increase of penalty for material-change when setup-code is different. Default is 2. */
    planning_setup_code_change_factor: (tags: any): number => parseFloat(defVal(tags[LINE_GROUP_TAGS.planning_setup_code_change_factor], "2")),
    /** Should needs penalty be smoothed. Default is `true`. */
    planning_smooth_needs: (tags: any): boolean => (defVal(tags[LINE_GROUP_TAGS.planning_smooth_needs], "true")) == "true",
    /** Minimal length of chunks in hours. Default is 8. */
    planning_split_order_min_hours: (tags: any): number => parseFloat(defVal(tags[LINE_GROUP_TAGS.planning_split_order_min_hours], "8")),
    /** Plan only orders with earliest start before 'planning_table_horizon_days' from now */
    planning_table_horizon_days: (tags: any): number => parseFloat(defVal(tags[LINE_GROUP_TAGS.planning_table_horizon_days], "21")),
    /** Speeds up planning table when dealing with many orders */
    planning_table_lazy_rendering: (tags: any): boolean => (defVal(tags[LINE_GROUP_TAGS.planning_table_lazy_rendering], "false")) == "true",
    /** Determines for which orders users can edit quantity on planning table. Default is `all_orders`. */
    planning_table_orders_with_editable_quantity: (tags: any): string => defVal(tags[LINE_GROUP_TAGS.planning_table_orders_with_editable_quantity], ORDERS_WITH_EDITABLE_QUANTITY.all_orders),
    /** Enable planning board save to erp button */
    planning_table_save_to_erp: (tags: any): boolean => (defVal(tags[LINE_GROUP_TAGS.planning_table_save_to_erp], "false")) == "true",
    /** Number of weeks used for recalculating simulation when saving to ERP */
    planning_table_save_to_erp_weeks: (tags: any): number => parseFloat(defVal(tags[LINE_GROUP_TAGS.planning_table_save_to_erp_weeks], "16")),
    /** Indicates if issues tab should be shown on planning table. Default is `false`. */
    planning_table_show_issues_tab: (tags: any): boolean => (defVal(tags[LINE_GROUP_TAGS.planning_table_show_issues_tab], "false")) == "true",
    /** Indicates if 'link operation' action should be available on planning table. Default is `false`. */
    planning_table_show_link_operation: (tags: any): boolean => (defVal(tags[LINE_GROUP_TAGS.planning_table_show_link_operation], "false")) == "true",
    /** Indicates if tool changes should be shown on planning table */
    planning_table_show_tool_change: (tags: any): boolean => (defVal(tags[LINE_GROUP_TAGS.planning_table_show_tool_change], "false")) == "true",
    /** When saving to ERP, move unscheduled orders to the end of planning horizon or leave them as is (default false) */
    planning_table_unscheduled_move: (tags: any): boolean => (defVal(tags[LINE_GROUP_TAGS.planning_table_unscheduled_move], "false")) == "true",
    /** Number of weeks displayed on the planning board */
    planning_table_weeks: (tags: any): number => parseFloat(defVal(tags[LINE_GROUP_TAGS.planning_table_weeks], "4")),
    /** The time-horizon extra buffer to account for the time orders starting in time horizont need to finish. Should correspond to maximal order lenght. Default is 7. */
    planning_time_horizont_buffer: (tags: any): number => parseFloat(defVal(tags[LINE_GROUP_TAGS.planning_time_horizont_buffer], "7")),
    /** The time-horizon extra days beyond time horizont for including needs in the planning process. Default is 7. */
    planning_time_horizont_needs: (tags: any): number => parseFloat(defVal(tags[LINE_GROUP_TAGS.planning_time_horizont_needs], "7")),
    /** The time-horizon for planning. Default is 42 (two weeks). */
    planning_time_horizont_shifts: (tags: any): number => parseFloat(defVal(tags[LINE_GROUP_TAGS.planning_time_horizont_shifts], "42")),
    /** Choose unit for horizon on automatic scheduling on planning table */
    planning_time_horizont_unit: (tags: any): string => defVal(tags[LINE_GROUP_TAGS.planning_time_horizont_unit], ""),
    /** Tool setup penalty share in BFS score. This is multiplied with the tool setup criteria score. Default is 4. */
    planning_tool_setup_factor: (tags: any): number => parseFloat(defVal(tags[LINE_GROUP_TAGS.planning_tool_setup_factor], "4")),
    /** Should planning account for customer priority. Default is `false` */
    planning_track_customer_priorty: (tags: any): boolean => (defVal(tags[LINE_GROUP_TAGS.planning_track_customer_priorty], "false")) == "true",
    /** Should input-materials be tracked duringh planning. Default is `false`. */
    planning_track_input_material: (tags: any): boolean => (defVal(tags[LINE_GROUP_TAGS.planning_track_input_material], "false")) == "true",
    /** Should planning account for value of material on stock. Default is `false`. */
    planning_track_stock_levels: (tags: any): boolean => (defVal(tags[LINE_GROUP_TAGS.planning_track_stock_levels], "false")) == "true",
    /** Use material codes in planning to compute changover times. Default is `false` */
    planning_use_material_codes: (tags: any): boolean => (defVal(tags[LINE_GROUP_TAGS.planning_use_material_codes], "false")) == "true",
    /** Default factor for weighting planned work duration in planning. Default is 0. */
    planning_work_duration_factor: (tags: any): number => parseFloat(defVal(tags[LINE_GROUP_TAGS.planning_work_duration_factor], "0")),
    /** Print shift-board only for lineswith assigned worker for the line-group */
    print_only_assigned_lines: (tags: any): boolean => (defVal(tags[LINE_GROUP_TAGS.print_only_assigned_lines], "false")) == "true",
    /** Parameter defines direction of rotation when copying previous week when using `shift_preference_rotate` strategy. Option `true` means 321 shift rotation. Option `false` means 123 shift rotation. */
    rotate_opposite_direction: (tags: any): boolean => (defVal(tags[LINE_GROUP_TAGS.rotate_opposite_direction], "false")) == "true",
    /** Indicates if all operations of the same order shuld be (un)selected when single operation is (un)selected in save dialog on planning table. Default is `false`. */
    save_all_operations_to_erp: (tags: any): boolean => (defVal(tags[LINE_GROUP_TAGS.save_all_operations_to_erp], "false")) == "true",
    /** Indicates if all operations on the same initial/final line shuld be (un)selected when single operation is (un)selected in save dialog on planning table. Default is `false`. */
    save_whole_lines_to_erp: (tags: any): boolean => (defVal(tags[LINE_GROUP_TAGS.save_whole_lines_to_erp], "false")) == "true",
    /** Parameter which groups the exported of the daily schedule by people instead of grouping by lines */
    shift_assignment_group_by_people: (tags: any): boolean => (defVal(tags[LINE_GROUP_TAGS.shift_assignment_group_by_people], "false")) == "true",
    /** Indicator that this line-group uses shift-board for assinments of people to lines */
    shift_board: (tags: any): boolean => (defVal(tags[LINE_GROUP_TAGS.shift_board], "false")) == "true",
    /** Forecasting enables warehouses to keep track of sales and anticipated demand, so they can ensure that the available stock is adequate to meet customer needs. */
    stock_forecast: (tags: any): boolean => (defVal(tags[LINE_GROUP_TAGS.stock_forecast], "false")) == "true",
    /** The number of days that defines the time window for displaying stock forecast in 'day' view. Value must be between 1 and 28. Default is 14. */
    stock_forecast_display_horizon_day: (tags: any): number => parseFloat(defVal(tags[LINE_GROUP_TAGS.stock_forecast_display_horizon_day], "14")),
    /** The number of days that defines the time window for displaying stock forecast in 'shift' view. Value must be between 1 and 28. Default is 7. */
    stock_forecast_display_horizon_shift: (tags: any): number => parseFloat(defVal(tags[LINE_GROUP_TAGS.stock_forecast_display_horizon_shift], "7")),
    /** The number of days that defines the time window for displaying stock forecast in 'week' view. Value must be between 1 and 28. Default is 28. */
    stock_forecast_display_horizon_week: (tags: any): number => parseFloat(defVal(tags[LINE_GROUP_TAGS.stock_forecast_display_horizon_week], "28")),
    /** Default state of the shop-floor only toggle. Default is `true` */
    stock_forecast_shopfloor_option_default: (tags: any): boolean => (defVal(tags[LINE_GROUP_TAGS.stock_forecast_shopfloor_option_default], "true")) == "true",
    /** Show safety stock levels on stock forecast page. Default is `false`. */
    stock_forecast_show_safety_stock: (tags: any): boolean => (defVal(tags[LINE_GROUP_TAGS.stock_forecast_show_safety_stock], "false")) == "true",
    /** Shall we show the option to toggle between shop-floor only and overall inventory level on stock forecast page. Default is `true` */
    stock_forecast_show_shopfloor_option: (tags: any): boolean => (defVal(tags[LINE_GROUP_TAGS.stock_forecast_show_shopfloor_option], "true")) == "true",
    /** Indicates if 'Withdrawn and unaccounted' option should be shown on stock forecast page. Default is `true`. */
    stock_forecast_show_withdrawn_and_unaccounted_option: (tags: any): boolean => (defVal(tags[LINE_GROUP_TAGS.stock_forecast_show_withdrawn_and_unaccounted_option], "true")) == "true",
    /** Default state of the 'Withdrawn and unaccounted' option checkbox for stock forecast. Default is `false`. */
    stock_forecast_withdrawn_and_unaccounted_default: (tags: any): boolean => (defVal(tags[LINE_GROUP_TAGS.stock_forecast_withdrawn_and_unaccounted_default], "false")) == "true",
    /** Determines which quantity is displayed in basic view on stock status page. Default is `erp_quantity`. */
    stock_status_basic_view_quantity_column: (tags: any): string => defVal(tags[LINE_GROUP_TAGS.stock_status_basic_view_quantity_column], STOCK_STATUS_QUANTITY_TYPE.erp_quantity),
    /** Indicator that this line-group uses subcontracting */
    subcontracting: (tags: any): boolean => (defVal(tags[LINE_GROUP_TAGS.subcontracting], "false")) == "true",
    /** If status of an operation stays unscheduled, do not highlight it as changed operation on Synchronize and refresh dialog box. Changed operations are marked white, while unchanged operations are marked grey. For example if a user schedules an operation to a line, but moves it back to unscheduled in the same iteration, the operation will be marked as unchanged. Default is `false`. */
    unscheduled_operations_marked_unchanged: (tags: any): boolean => (defVal(tags[LINE_GROUP_TAGS.unscheduled_operations_marked_unchanged], "false")) == "true",
    /** Indicator that this line-group uses weekly realization */
    weekly_realization: (tags: any): boolean => (defVal(tags[LINE_GROUP_TAGS.weekly_realization], "false")) == "true",
    /** Indicator to show downtimes in dropdown */
    weekly_realization_downtimes_view: (tags: any): boolean => (defVal(tags[LINE_GROUP_TAGS.weekly_realization_downtimes_view], "false")) == "true",
    /** Indicator to show scrap in dropdown */
    weekly_realization_scrap_view: (tags: any): boolean => (defVal(tags[LINE_GROUP_TAGS.weekly_realization_scrap_view], "false")) == "true",
    /** Optional parameter, which defines if we show workers in the weekly realization view. Default value is `true`. */
    weekly_realization_show_workers: (tags: any): boolean => (defVal(tags[LINE_GROUP_TAGS.weekly_realization_show_workers], "false")) == "true"
};

/** Modes of planning approach */
export const PLANNING_MODE = {
    /** Plan work on lines and ignore people assignments */
    lines_first: "lines_first",
    /** Plan work according to people pool-assignments and include lines in second step. */
    people_first: "people_first"
};
export type PlanningModeEnum =
    "lines_first" |
    "people_first";

/** Horizon unit on automatic scheduling on planning table */
export const PLAN_HORIZON_UNIT_AUTOMATIC_SCHEDULING = {
    /** Use shifts unit on automatic scheduling on planning table */
    shift_unit: "shift_unit",
    /** Use weeks unit on automatic scheduling on planning table */
    week_unit: "week_unit"
};
export type PlanHorizonUnitAutomaticSchedulingEnum =
    "shift_unit" |
    "week_unit";

/** Meta-optimizations of enabled shifts supported by planning */
export const PLANNING_META_OPTIMIZATION = {
    /** Find optimal plan given the needs and criteria using at most maximal number of resources per shift, provided in the input model. */
    max_resources: "max_resources",
    /** No meta optimization. */
    none: "none",
    /** Find optimal plan given the needs and criteria while also keeping stock low. This is done by finding optimal plan utilizing all available resources and then greedily removing resources from the plan that do not affect the delay score of the plan. This is repeated until no more resources can be removed. */
    stock: "stock",
    /** Find optimal plan that tries to ensure all available resources are utilized as much as possible from the start. This is done by keeping a time front (e.g. start with initial 24 hours) and make sure we schedule everything that can be scheduled there before moving the front forward */
    time_front: "time_front"
};
export type PlanningMetaOptimizationEnum =
    "max_resources" |
    "none" |
    "stock" |
    "time_front";

/** Strategy for copying shift assignments from previous week. */
export const COPY_WEEKLY_ASSIGNMENTS_STRATEGY = {
    /** Simple copy of the assignments */
    copy: "copy",
    /** Continuous shift rotation with 4 groups (6-days-work + 2-days-rest) */
    four_shift_rotation: "four_shift_rotation",
    /** Smart rotation based on shift preference */
    shift_preference_rotate: "shift_preference_rotate",
    /** Simple rotation */
    simple_rotate: "simple_rotate"
};
export type CopyWeeklyAssignmentsStrategyEnum =
    "copy" |
    "four_shift_rotation" |
    "shift_preference_rotate" |
    "simple_rotate";

/** Order's priority, will be used for scheduling.From highest (A) to lowest (E). Medium (C) is the default. */
export const ORDER_PRIORITIES = {
    /** Highest priority */
    A: "A",
    /** High priority */
    B: "B",
    /** Medium priority */
    C: "C",
    /** Low priority */
    D: "D",
    /** Lowest priority */
    E: "E"
};
export type OrderPrioritiesEnum =
    "A" |
    "B" |
    "C" |
    "D" |
    "E";

/** For which orders users can edit quantity */
export const ORDERS_WITH_EDITABLE_QUANTITY = {
    all_orders: "all_orders",
    no_orders: "no_orders",
    planning_orders: "planning_orders"
};
export type OrdersWithEditableQuantityEnum =
    "all_orders" |
    "no_orders" |
    "planning_orders";

/** Stock status quantity type */
export const STOCK_STATUS_QUANTITY_TYPE = {
    /** Available quantity */
    available_quantity: "available_quantity",
    /** ERP quantity */
    erp_quantity: "erp_quantity"
};
export type StockStatusQuantityTypeEnum =
    "available_quantity" |
    "erp_quantity";

/** Tags for decorating batch-operation group. */
export const BATCHOP_GROUP_TAGS = {
    /** Optional external id of group, if provided. */
    external_id: "external_id",
    /** If this flag is set to true, ident of input material with type HALB is used for batch-operations. */
    halb: "halb",
    /** UUID of the line-group that this batch-operation group maps to. */
    line_group: "line_group",
    /** Flag for setting batch operations in mode that works without work orders. */
    no_orders: "no_orders",
    /** Flag for setting batch operations silo input mode. This influences current status of batch page. */
    silo_input: "silo_input"
};

/** Tags for decorating batch-operation group. */
export const BATCHOP_GROUP_TAGS_ACCESS = {
    /** Optional external id of group, if provided. */
    external_id: (tags: any): string => defVal(tags[BATCHOP_GROUP_TAGS.external_id], ""),
    /** If this flag is set to true, ident of input material with type HALB is used for batch-operations. */
    halb: (tags: any): string => defVal(tags[BATCHOP_GROUP_TAGS.halb], ""),
    /** UUID of the line-group that this batch-operation group maps to. */
    line_group: (tags: any): string => defVal(tags[BATCHOP_GROUP_TAGS.line_group], ""),
    /** Flag for setting batch operations in mode that works without work orders. */
    no_orders: (tags: any): boolean => (defVal(tags[BATCHOP_GROUP_TAGS.no_orders], "false")) == "true",
    /** Flag for setting batch operations silo input mode. This influences current status of batch page. */
    silo_input: (tags: any): boolean => (defVal(tags[BATCHOP_GROUP_TAGS.silo_input], "false")) == "true"
};

/** Tags for decorating batch-operation location. */
export const BATCHOP_LOCATION_TAGS = {
    /** UUID of the line that this location maps to - used for retrieving work-orders. */
    line: "line"
};

/** Tags for decorating batch-operation location. */
export const BATCHOP_LOCATION_TAGS_ACCESS = {
    /** UUID of the line that this location maps to - used for retrieving work-orders. */
    line: (tags: any): string => defVal(tags[BATCHOP_LOCATION_TAGS.line], "")
};

/** Tags for decorating single plant. */
export const PLANT_TAGS = {
    /** 21 characters long numerical string, each number represents shift of the week */
    calendar_plant: "calendar_plant",
    /** Company ID when several companies belong to the same organization */
    company_id: "company_id",
    /** Contact person for the plant visible on 'Help' menu */
    contact_person: "contact_person",
    /** Contact person's e-mail address used for 'mailto' link on contact person's menu item */
    contact_person_email: "contact_person_email",
    /** When using 'Save to Leap', do not override existing open orders with new orders data from plan */
    do_not_override_existing_opened_orders: "do_not_override_existing_opened_orders",
    /** Enable logic for matching operations on import to be simulated and scheduled together. */
    enable_produce_together_with_next_operation: "enable_produce_together_with_next_operation",
    /** Enable printing of the tool transfer from the list of tool changes */
    enable_tool_transfer_print: "enable_tool_transfer_print",
    /** External type of order which have fixed line */
    fix_line_external_order_type: "fix_line_external_order_type",
    /** Indicates whether the plant has work-centers with multiple machines. when turned on, machine_assignment task provides machine assignment data for the plant */
    has_multiple_machines: "has_multiple_machines",
    /** Ignore materials with the tags in the plant. For example: OROD, VERP, LEIH */
    ignore_material_tags: "ignore_material_tags",
    /** Indicates if 'tools' should be imported with materials */
    import_production_tools: "import_production_tools",
    /** Controls if we want to compute freeze-order-change insights */
    insight_freeze_order: "insight_freeze_order",
    /** Horizon in number of shifts for which we compute no-work insights for lines in the plant */
    insight_no_work_shifts: "insight_no_work_shifts",
    /** If true indicates that the plant is using make to order production strategy. */
    make_to_order: "make_to_order",
    /** Indicates if material's setup code should be displayed in work-orders table on microplan */
    microplan_show_setup_code: "microplan_show_setup_code",
    /** Shows new navigation layout with home button */
    new_feature_home_button: "new_feature_home_button",
    /** Enable chunking of orders on planning table */
    new_feature_planning_table_chunking: "new_feature_planning_table_chunking",
    /** Use normatives (instead of simulation models) to estimate duration of parallel orders. Default: false. */
    norm_fallback_parallel_order: "norm_fallback_parallel_order",
    /** Threshold for duration below which the duration falls back to normative */
    norm_fallback_threshold_h: "norm_fallback_threshold_h",
    /** Convert operation unit of measure to plan unit of measure. Modifies normative values. (default false) */
    plan_import_convert_operation_units: "plan_import_convert_operation_units",
    /** Plan import will retrieve quantites delivered for each active order of the given plant */
    plan_import_delivered_quantities: "plan_import_delivered_quantities",
    /** Indicate how many days is the starting date for importing plan */
    plan_import_from_days: "plan_import_from_days",
    /** Defines for how many weeks in the future the plan will be imported */
    plan_import_horizon: "plan_import_horizon",
    /** Sets skip_sim flag for new orders. This means new orders will be unscheduled */
    plan_import_new_orders_skip_sim: "plan_import_new_orders_skip_sim",
    /** Comma separated list of operation output stock keys used for deciding if an operation outputs to stock. Used when importing plan. */
    plan_import_operation_output_stock_keys: "plan_import_operation_output_stock_keys",
    /** Type of post-processing output stock flag when importing plan. Value `nop` means no posprocessing, value `external_resolved` uses output_stock that comes through import, but ensures that exactly one operation has output stock true for each work order. Value `rules` corresponds to ignoring external output_stock and determine which operation outputs to stock based on plant and line tags. Default `external_resolved` */
    plan_import_output_stock_postprocess: "plan_import_output_stock_postprocess",
    /** Flag for importing planned orders for the plant */
    plan_import_planned_orders: "plan_import_planned_orders",
    /** Plan import will retrieve scheduled statuses for all imported operations */
    plan_import_scheduled_statuses: "plan_import_scheduled_statuses",
    /** Indicates language for ERP plan data import */
    plan_sap_lang: "plan_sap_lang",
    /** Differentiate operations with the flag system_status_released=false on the planning board. Default value is `false`. */
    planning_table_display_system_status_released: "planning_table_display_system_status_released",
    /** Ignores horizon for work orders on planning table */
    planning_table_ignore_horizon_work_orders: "planning_table_ignore_horizon_work_orders",
    /** Show parallel orders on planning table */
    planning_table_parallel: "planning_table_parallel",
    /** Use production versions on planning table */
    planning_table_production_versions: "planning_table_production_versions",
    /** Which data can be saved to server from planning table */
    planning_table_save_to_leap_mode: "planning_table_save_to_leap_mode",
    /** Indicates if 'Ignore order' action should be available in advanced order menu on planning table. Default value is `false`. */
    planning_table_show_ignore_order: "planning_table_show_ignore_order",
    /** Indicates if 'Ignore parent order' action should be available in advanced order menu on planning table. Default value is `false`. */
    planning_table_show_ignore_parent_order: "planning_table_show_ignore_parent_order",
    /** If `true` operation snaps to the beginning of the shift under cursor when dragging by default and precise mode (without snapping) is activated by holding down SHIFT key. If `false` precise mode is the default and snapping is activated by holding down SHIFT key. Default value is `true`. */
    planning_table_snap_to_shift: "planning_table_snap_to_shift",
    /** Sort unscheduled options */
    planning_table_sort_direction: "planning_table_sort_direction",
    /** Types of rework order */
    rework_order_external_types: "rework_order_external_types",
    /** Indicates that the plant supports 12h shifts */
    shift_board_support_12h_shifts: "shift_board_support_12h_shifts",
    /** Show ID order barcode in order modal dialog */
    show_barcode: "show_barcode",
    /** Show transaction_external_id on stock requirements */
    show_transaction_external_id: "show_transaction_external_id",
    /** Set sim_ignore_earliest_start (24/7) for orders in the plant */
    sim_ignore_earliest_start: "sim_ignore_earliest_start",
    /** Handles stock changes in current shift that were transfered to ERP */
    simulation_current_shift_stock_in_erp: "simulation_current_shift_stock_in_erp",
    /** Skip fallback to normative in cases when model seems too optimistic */
    skip_too_optimistic_fallback_to_normative: "skip_too_optimistic_fallback_to_normative",
    /** Stock forecast: input materials delivery */
    stock_forecast_consumed_received_scenario: "stock_forecast_consumed_received_scenario",
    /** Stock forecast: input materials */
    stock_forecast_consumed_scenario: "stock_forecast_consumed_scenario",
    /** Stock forecast: output materials requirements */
    stock_forecast_produced_required_scenario: "stock_forecast_produced_required_scenario",
    /** Stock forecast: output materials */
    stock_forecast_produced_scenario: "stock_forecast_produced_scenario",
    /** Which stock requirement date to use when calculating stock requirements for this material (default is 'date_required') */
    stock_requirements_date_use: "stock_requirements_date_use",
    /** Types of stock requirements to import for the plant */
    stock_requirements_import_types: "stock_requirements_import_types",
    /** Type of calculation for tool setup, for possible values see TOOL_SETUP_CALC_TYPES */
    tool_setup_calc_type: "tool_setup_calc_type",
    /** Determines how the codes used by tool setup calculation are computed, for possible values see TOOL_SETUP_CODE_TYPES */
    tool_setup_code_type: "tool_setup_code_type",
    /** Sets stock location for tools in the plant */
    tools_stock_location: "tools_stock_location",
    /** Offset of start-shift in week. Default is 0. */
    week_start_shift_offset: "week_start_shift_offset",
    /** Controls mapping from workday hours to enabled shifts (what shift comes first) */
    work_schedule_preferred_shifts: "work_schedule_preferred_shifts",
    /** Controls swapping Friday last shift with Sunday last shift */
    work_schedule_sunday_logic: "work_schedule_sunday_logic",
    /** Waiting period (defined in minutes) when writing to ERP */
    write_to_erp_timeout: "write_to_erp_timeout"
};

/** Tags for decorating single plant. */
export const PLANT_TAGS_ACCESS = {
    /** 21 characters long numerical string, each number represents shift of the week */
    calendar_plant: (tags: any): string => defVal(tags[PLANT_TAGS.calendar_plant], "111111111111111111111"),
    /** Company ID when several companies belong to the same organization */
    company_id: (tags: any): string => defVal(tags[PLANT_TAGS.company_id], ""),
    /** Contact person for the plant visible on 'Help' menu */
    contact_person: (tags: any): string => defVal(tags[PLANT_TAGS.contact_person], ""),
    /** Contact person's e-mail address used for 'mailto' link on contact person's menu item */
    contact_person_email: (tags: any): string => defVal(tags[PLANT_TAGS.contact_person_email], ""),
    /** When using 'Save to Leap', do not override existing open orders with new orders data from plan */
    do_not_override_existing_opened_orders: (tags: any): boolean => (defVal(tags[PLANT_TAGS.do_not_override_existing_opened_orders], "false")) == "true",
    /** Enable logic for matching operations on import to be simulated and scheduled together. */
    enable_produce_together_with_next_operation: (tags: any): boolean => (defVal(tags[PLANT_TAGS.enable_produce_together_with_next_operation], "false")) == "true",
    /** Enable printing of the tool transfer from the list of tool changes */
    enable_tool_transfer_print: (tags: any): boolean => (defVal(tags[PLANT_TAGS.enable_tool_transfer_print], "false")) == "true",
    /** External type of order which have fixed line */
    fix_line_external_order_type: (tags: any): string => defVal(tags[PLANT_TAGS.fix_line_external_order_type], ""),
    /** Indicates whether the plant has work-centers with multiple machines. when turned on, machine_assignment task provides machine assignment data for the plant */
    has_multiple_machines: (tags: any): boolean => (defVal(tags[PLANT_TAGS.has_multiple_machines], "false")) == "true",
    /** Ignore materials with the tags in the plant. For example: OROD, VERP, LEIH */
    ignore_material_tags: (tags: any): string => defVal(tags[PLANT_TAGS.ignore_material_tags], ""),
    /** Indicates if 'tools' should be imported with materials */
    import_production_tools: (tags: any): boolean => (defVal(tags[PLANT_TAGS.import_production_tools], "false")) == "true",
    /** Controls if we want to compute freeze-order-change insights */
    insight_freeze_order: (tags: any): boolean => (defVal(tags[PLANT_TAGS.insight_freeze_order], "false")) == "true",
    /** Horizon in number of shifts for which we compute no-work insights for lines in the plant */
    insight_no_work_shifts: (tags: any): number => parseFloat(defVal(tags[PLANT_TAGS.insight_no_work_shifts], "21")),
    /** If true indicates that the plant is using make to order production strategy. */
    make_to_order: (tags: any): boolean => (defVal(tags[PLANT_TAGS.make_to_order], "false")) == "true",
    /** Indicates if material's setup code should be displayed in work-orders table on microplan */
    microplan_show_setup_code: (tags: any): boolean => (defVal(tags[PLANT_TAGS.microplan_show_setup_code], "false")) == "true",
    /** Shows new navigation layout with home button */
    new_feature_home_button: (tags: any): boolean => (defVal(tags[PLANT_TAGS.new_feature_home_button], "false")) == "true",
    /** Enable chunking of orders on planning table */
    new_feature_planning_table_chunking: (tags: any): boolean => (defVal(tags[PLANT_TAGS.new_feature_planning_table_chunking], "false")) == "true",
    /** Use normatives (instead of simulation models) to estimate duration of parallel orders. Default: false. */
    norm_fallback_parallel_order: (tags: any): boolean => (defVal(tags[PLANT_TAGS.norm_fallback_parallel_order], "false")) == "true",
    /** Threshold for duration below which the duration falls back to normative */
    norm_fallback_threshold_h: (tags: any): number => parseFloat(defVal(tags[PLANT_TAGS.norm_fallback_threshold_h], "8")),
    /** Convert operation unit of measure to plan unit of measure. Modifies normative values. (default false) */
    plan_import_convert_operation_units: (tags: any): boolean => (defVal(tags[PLANT_TAGS.plan_import_convert_operation_units], "false")) == "true",
    /** Plan import will retrieve quantites delivered for each active order of the given plant */
    plan_import_delivered_quantities: (tags: any): boolean => (defVal(tags[PLANT_TAGS.plan_import_delivered_quantities], "false")) == "true",
    /** Indicate how many days is the starting date for importing plan */
    plan_import_from_days: (tags: any): number => parseFloat(defVal(tags[PLANT_TAGS.plan_import_from_days], "10")),
    /** Defines for how many weeks in the future the plan will be imported */
    plan_import_horizon: (tags: any): number => parseFloat(defVal(tags[PLANT_TAGS.plan_import_horizon], "8")),
    /** Sets skip_sim flag for new orders. This means new orders will be unscheduled */
    plan_import_new_orders_skip_sim: (tags: any): boolean => (defVal(tags[PLANT_TAGS.plan_import_new_orders_skip_sim], "false")) == "true",
    /** Comma separated list of operation output stock keys used for deciding if an operation outputs to stock. Used when importing plan. */
    plan_import_operation_output_stock_keys: (tags: any): string => defVal(tags[PLANT_TAGS.plan_import_operation_output_stock_keys], ""),
    /** Type of post-processing output stock flag when importing plan. Value `nop` means no posprocessing, value `external_resolved` uses output_stock that comes through import, but ensures that exactly one operation has output stock true for each work order. Value `rules` corresponds to ignoring external output_stock and determine which operation outputs to stock based on plant and line tags. Default `external_resolved` */
    plan_import_output_stock_postprocess: (tags: any): string => defVal(tags[PLANT_TAGS.plan_import_output_stock_postprocess], ""),
    /** Flag for importing planned orders for the plant */
    plan_import_planned_orders: (tags: any): boolean => (defVal(tags[PLANT_TAGS.plan_import_planned_orders], "false")) == "true",
    /** Plan import will retrieve scheduled statuses for all imported operations */
    plan_import_scheduled_statuses: (tags: any): boolean => (defVal(tags[PLANT_TAGS.plan_import_scheduled_statuses], "false")) == "true",
    /** Indicates language for ERP plan data import */
    plan_sap_lang: (tags: any): string => defVal(tags[PLANT_TAGS.plan_sap_lang], "SL"),
    /** Differentiate operations with the flag system_status_released=false on the planning board. Default value is `false`. */
    planning_table_display_system_status_released: (tags: any): boolean => (defVal(tags[PLANT_TAGS.planning_table_display_system_status_released], "false")) == "true",
    /** Ignores horizon for work orders on planning table */
    planning_table_ignore_horizon_work_orders: (tags: any): boolean => (defVal(tags[PLANT_TAGS.planning_table_ignore_horizon_work_orders], "false")) == "true",
    /** Show parallel orders on planning table */
    planning_table_parallel: (tags: any): boolean => (defVal(tags[PLANT_TAGS.planning_table_parallel], "false")) == "true",
    /** Use production versions on planning table */
    planning_table_production_versions: (tags: any): boolean => (defVal(tags[PLANT_TAGS.planning_table_production_versions], "false")) == "true",
    /** Which data can be saved to server from planning table */
    planning_table_save_to_leap_mode: (tags: any): string => defVal(tags[PLANT_TAGS.planning_table_save_to_leap_mode], PLANNING_TABLE_SAVE_TO_LEAP_MODE.light),
    /** Indicates if 'Ignore order' action should be available in advanced order menu on planning table. Default value is `false`. */
    planning_table_show_ignore_order: (tags: any): boolean => (defVal(tags[PLANT_TAGS.planning_table_show_ignore_order], "false")) == "true",
    /** Indicates if 'Ignore parent order' action should be available in advanced order menu on planning table. Default value is `false`. */
    planning_table_show_ignore_parent_order: (tags: any): boolean => (defVal(tags[PLANT_TAGS.planning_table_show_ignore_parent_order], "false")) == "true",
    /** If `true` operation snaps to the beginning of the shift under cursor when dragging by default and precise mode (without snapping) is activated by holding down SHIFT key. If `false` precise mode is the default and snapping is activated by holding down SHIFT key. Default value is `true`. */
    planning_table_snap_to_shift: (tags: any): boolean => (defVal(tags[PLANT_TAGS.planning_table_snap_to_shift], "true")) == "true",
    /** Sort unscheduled options */
    planning_table_sort_direction: (tags: any): string => defVal(tags[PLANT_TAGS.planning_table_sort_direction], ""),
    /** Types of rework order */
    rework_order_external_types: (tags: any): string => defVal(tags[PLANT_TAGS.rework_order_external_types], ""),
    /** Indicates that the plant supports 12h shifts */
    shift_board_support_12h_shifts: (tags: any): boolean => (defVal(tags[PLANT_TAGS.shift_board_support_12h_shifts], "false")) == "true",
    /** Show ID order barcode in order modal dialog */
    show_barcode: (tags: any): boolean => (defVal(tags[PLANT_TAGS.show_barcode], "false")) == "true",
    /** Show transaction_external_id on stock requirements */
    show_transaction_external_id: (tags: any): boolean => (defVal(tags[PLANT_TAGS.show_transaction_external_id], "false")) == "true",
    /** Set sim_ignore_earliest_start (24/7) for orders in the plant */
    sim_ignore_earliest_start: (tags: any): boolean => (defVal(tags[PLANT_TAGS.sim_ignore_earliest_start], "false")) == "true",
    /** Handles stock changes in current shift that were transfered to ERP */
    simulation_current_shift_stock_in_erp: (tags: any): boolean => (defVal(tags[PLANT_TAGS.simulation_current_shift_stock_in_erp], "false")) == "true",
    /** Skip fallback to normative in cases when model seems too optimistic */
    skip_too_optimistic_fallback_to_normative: (tags: any): boolean => (defVal(tags[PLANT_TAGS.skip_too_optimistic_fallback_to_normative], "false")) == "true",
    /** Stock forecast: input materials delivery */
    stock_forecast_consumed_received_scenario: (tags: any): boolean => (defVal(tags[PLANT_TAGS.stock_forecast_consumed_received_scenario], "false")) == "true",
    /** Stock forecast: input materials */
    stock_forecast_consumed_scenario: (tags: any): boolean => (defVal(tags[PLANT_TAGS.stock_forecast_consumed_scenario], "false")) == "true",
    /** Stock forecast: output materials requirements */
    stock_forecast_produced_required_scenario: (tags: any): boolean => (defVal(tags[PLANT_TAGS.stock_forecast_produced_required_scenario], "false")) == "true",
    /** Stock forecast: output materials */
    stock_forecast_produced_scenario: (tags: any): boolean => (defVal(tags[PLANT_TAGS.stock_forecast_produced_scenario], "false")) == "true",
    /** Which stock requirement date to use when calculating stock requirements for this material (default is 'date_required') */
    stock_requirements_date_use: (tags: any): string => defVal(tags[PLANT_TAGS.stock_requirements_date_use], STOCK_REQUIREMENTS_DATE_TYPES.date_required),
    /** Types of stock requirements to import for the plant */
    stock_requirements_import_types: (tags: any): string => defVal(tags[PLANT_TAGS.stock_requirements_import_types], ""),
    /** Type of calculation for tool setup, for possible values see TOOL_SETUP_CALC_TYPES */
    tool_setup_calc_type: (tags: any): string => defVal(tags[PLANT_TAGS.tool_setup_calc_type], ""),
    /** Determines how the codes used by tool setup calculation are computed, for possible values see TOOL_SETUP_CODE_TYPES */
    tool_setup_code_type: (tags: any): string => defVal(tags[PLANT_TAGS.tool_setup_code_type], ""),
    /** Sets stock location for tools in the plant */
    tools_stock_location: (tags: any): string => defVal(tags[PLANT_TAGS.tools_stock_location], ""),
    /** Offset of start-shift in week. Default is 0. */
    week_start_shift_offset: (tags: any): number => parseFloat(defVal(tags[PLANT_TAGS.week_start_shift_offset], "0")),
    /** Controls mapping from workday hours to enabled shifts (what shift comes first) */
    work_schedule_preferred_shifts: (tags: any): string => defVal(tags[PLANT_TAGS.work_schedule_preferred_shifts], ""),
    /** Controls swapping Friday last shift with Sunday last shift */
    work_schedule_sunday_logic: (tags: any): string => defVal(tags[PLANT_TAGS.work_schedule_sunday_logic], ""),
    /** Waiting period (defined in minutes) when writing to ERP */
    write_to_erp_timeout: (tags: any): number => parseFloat(defVal(tags[PLANT_TAGS.write_to_erp_timeout], "2"))
};

/** Tags for decorating single person. */
export const PEOPLE_TAGS = {
    /** List of complexities that is allowed for this person */
    allowed_complexities: "allowed_complexities",
    /** Number of hours per shift that this person is allowed to work. */
    max_h_per_shift: "max_h_per_shift",
    /** Number of shifts per week that this person is allowed to work. */
    reduced_worktime: "reduced_worktime",
    /** Indicates that the person is working in 12h shifts */
    shift_board_works_12h_shifts: "shift_board_works_12h_shifts"
};

/** Tags for decorating single person. */
export const PEOPLE_TAGS_ACCESS = {
    /** List of complexities that is allowed for this person */
    allowed_complexities: (tags: any): string => defVal(tags[PEOPLE_TAGS.allowed_complexities], ""),
    /** Number of hours per shift that this person is allowed to work. */
    max_h_per_shift: (tags: any): number => parseFloat(defVal(tags[PEOPLE_TAGS.max_h_per_shift], "8")),
    /** Number of shifts per week that this person is allowed to work. */
    reduced_worktime: (tags: any): string => defVal(tags[PEOPLE_TAGS.reduced_worktime], "5"),
    /** Indicates that the person is working in 12h shifts */
    shift_board_works_12h_shifts: (tags: any): boolean => (defVal(tags[PEOPLE_TAGS.shift_board_works_12h_shifts], "false")) == "true"
};

/** Default tag values for decorating single person. */
export const PEOPLE_TAGS_DEFAULT = {
    reduced_worktime: "5"
};

/** Tags for decorating single workorder. */
export const ORDER_TAGS = {
    /** External id of bom that was used. */
    bom_external_id: "bom_external_id",
    /** External id of bom usage. */
    bom_usage: "bom_usage",
    /** Custom comment for order */
    comment: "comment",
    /** Operations with the same code can be executed simultaneously */
    conditional_capacity_constraint_code: "conditional_capacity_constraint_code",
    /** quantity yielded by operation */
    confirmed_yield: "confirmed_yield",
    /** quantity delivered to stock by workorder */
    delivered_quantity: "delivered_quantity",
    /** ERP defines start and end time for operation that is used in simulation. */
    erp_date_determination: "erp_date_determination",
    /** External parent order ID */
    external_parent_order_id: "external_parent_order_id",
    /** External parent order position */
    external_parent_order_position: "external_parent_order_position",
    /** Order is fixed on the line with value being line's UUID */
    fix_line: "fix_line",
    /** End time of the frozen order in miliseconds */
    freeze_time_end: "freeze_time_end",
    /** Start time of the frozen order in miliseconds */
    freeze_time_start: "freeze_time_start",
    /** If true, order will not be shown on planning board */
    ignore_on_planning_board: "ignore_on_planning_board",
    /** If this value is set to `true`, automatic planning will not move the order */
    insight_freeze_order: "insight_freeze_order",
    /** UUID of linked operation. */
    linked_operation: "linked_operation",
    /** Indicates if order was edited by user. Default is `false`. */
    manual_entry_order_edited: "manual_entry_order_edited",
    /** Indicates if required date of order was edited by user. Default is `false`. */
    manual_entry_order_required_deadline_edited: "manual_entry_order_required_deadline_edited",
    /** Indicates if time end of order was edited by user. Default is `false`. */
    manual_entry_order_time_end_edited: "manual_entry_order_time_end_edited",
    /** Conversion factor between order and operation measurement units */
    operation_unit_conversion_factor: "operation_unit_conversion_factor",
    /** If true, it means that the operation was rewired (bom inputs and material changed) */
    operation_was_rewired: "operation_was_rewired",
    /** Order external type */
    order_external_type: "order_external_type",
    /** Flag if this operation's output_stock was modified by triggered production logic */
    output_stock_modified_by_triggered_production: "output_stock_modified_by_triggered_production",
    /** Flag if this is a parallel order, so there is a sibling somewhere */
    parallel_order: "parallel_order",
    /** Flag if this is a parallel-parent order, so there are child orders somewhere */
    parallel_parent: "parallel_parent",
    /** UUID of the order from which parallel order derived quantity. This order will be used when selecting the model. */
    parallel_principal_child: "parallel_principal_child",
    /** Order's priority, will be used for scheduling. From highest (A) to lowest (E). Medium (C) is the default. */
    priority: "priority",
    /** Indicator that operation should be scheduled to start and finish together with the following uperation and have duration equal to larger of current and following operation. */
    produce_together_with_next_operation: "produce_together_with_next_operation",
    /** Number of milliseconds for the duration of producting the triggered production. */
    produce_trigger_delay_ms: "produce_trigger_delay_ms",
    /** UUID of operation that whose stock will be triggered. */
    produce_trigger_op: "produce_trigger_op",
    /** Type of handling of outstanding productions that should trigger. */
    produce_trigger_outstanding: "produce_trigger_outstanding",
    /** Number of workdays in a week used when computing triggering delay */
    produce_trigger_workdays: "produce_trigger_workdays",
    /** External id of the production tool on order (part_number field from ERP) */
    production_tool_external_id: "production_tool_external_id",
    /** Title of the production tool on order. */
    production_tool_title: "production_tool_title",
    /** External id of production version. */
    production_version: "production_version",
    /** Imported all productions regardless of time window for fetching productions */
    productions_initial_import: "productions_initial_import",
    /** Required deadline for manual-entry order */
    required_deadline: "required_deadline",
    /** External id of routing. */
    routing_external_id: "routing_external_id",
    /** External id of routing group. */
    routing_group_external_id: "routing_group_external_id",
    /** External id of routing item. */
    routing_item_external_id: "routing_item_external_id",
    /** The operation is scheduled on the planning board */
    scheduled: "scheduled",
    /** the operation dates are determined by LEAP and sent to ERP */
    scheduling_owner: "scheduling_owner",
    /** Factor for conversion of quantity when shadow-ing. Parent operation's output quantity should be multiplied by this factor to get this operation's output quantity. */
    shadow_factor: "shadow_factor",
    /** External id of operation that this operation is shadow-ing. */
    shadow_of: "shadow_of",
    /** This is the chunk order (produced by split) and this is it's index */
    split_chunk_index: "split_chunk_index",
    /** Flag if this is the chunk-parent order (produced by split), so there are child-chunk orders somewhere */
    split_chunk_parent: "split_chunk_parent",
    /** Zero based index of subline to which the operation is assigned. Used with combination of sublines_number line tag. Default is 0. */
    subline_index: "subline_index",
    /** Superior order external id */
    superior_order_external_id: "superior_order_external_id",
    /** Released production order refers to an order that has been created and released for production execution. Default is `true`. */
    system_status_released: "system_status_released",
    /** External id of task list type (routing type). */
    task_list_type: "task_list_type",
    /** Used when determining if tool setup is needed */
    tool_setup_code: "tool_setup_code",
    /** Measurement unit used for reporting productions */
    total_quantity_measurement_unit: "total_quantity_measurement_unit",
    /** If true, unconfirmed production logic will ignore productions of this order */
    unconfirmed_stock_ignore_production: "unconfirmed_stock_ignore_production"
};

/** Tags for decorating single workorder. */
export const ORDER_TAGS_ACCESS = {
    /** External id of bom that was used. */
    bom_external_id: (tags: any): string => defVal(tags[ORDER_TAGS.bom_external_id], ""),
    /** External id of bom usage. */
    bom_usage: (tags: any): string => defVal(tags[ORDER_TAGS.bom_usage], ""),
    /** Custom comment for order */
    comment: (tags: any): string => defVal(tags[ORDER_TAGS.comment], ""),
    /** Operations with the same code can be executed simultaneously */
    conditional_capacity_constraint_code: (tags: any): string => defVal(tags[ORDER_TAGS.conditional_capacity_constraint_code], ""),
    /** quantity yielded by operation */
    confirmed_yield: (tags: any): number => parseFloat(defVal(tags[ORDER_TAGS.confirmed_yield], "-1")),
    /** quantity delivered to stock by workorder */
    delivered_quantity: (tags: any): number => parseFloat(defVal(tags[ORDER_TAGS.delivered_quantity], "-1")),
    /** ERP defines start and end time for operation that is used in simulation. */
    erp_date_determination: (tags: any): boolean => (defVal(tags[ORDER_TAGS.erp_date_determination], "false")) == "true",
    /** External parent order ID */
    external_parent_order_id: (tags: any): string => defVal(tags[ORDER_TAGS.external_parent_order_id], ""),
    /** External parent order position */
    external_parent_order_position: (tags: any): string => defVal(tags[ORDER_TAGS.external_parent_order_position], ""),
    /** Order is fixed on the line with value being line's UUID */
    fix_line: (tags: any): string => defVal(tags[ORDER_TAGS.fix_line], ""),
    /** End time of the frozen order in miliseconds */
    freeze_time_end: (tags: any): string => defVal(tags[ORDER_TAGS.freeze_time_end], ""),
    /** Start time of the frozen order in miliseconds */
    freeze_time_start: (tags: any): string => defVal(tags[ORDER_TAGS.freeze_time_start], ""),
    /** If true, order will not be shown on planning board */
    ignore_on_planning_board: (tags: any): boolean => (defVal(tags[ORDER_TAGS.ignore_on_planning_board], "false")) == "true",
    /** If this value is set to `true`, automatic planning will not move the order */
    insight_freeze_order: (tags: any): boolean => (defVal(tags[ORDER_TAGS.insight_freeze_order], "false")) == "true",
    /** UUID of linked operation. */
    linked_operation: (tags: any): string => defVal(tags[ORDER_TAGS.linked_operation], ""),
    /** Indicates if order was edited by user. Default is `false`. */
    manual_entry_order_edited: (tags: any): boolean => (defVal(tags[ORDER_TAGS.manual_entry_order_edited], "false")) == "true",
    /** Indicates if required date of order was edited by user. Default is `false`. */
    manual_entry_order_required_deadline_edited: (tags: any): boolean => (defVal(tags[ORDER_TAGS.manual_entry_order_required_deadline_edited], "false")) == "true",
    /** Indicates if time end of order was edited by user. Default is `false`. */
    manual_entry_order_time_end_edited: (tags: any): boolean => (defVal(tags[ORDER_TAGS.manual_entry_order_time_end_edited], "false")) == "true",
    /** Conversion factor between order and operation measurement units */
    operation_unit_conversion_factor: (tags: any): number => parseFloat(defVal(tags[ORDER_TAGS.operation_unit_conversion_factor], "-1")),
    /** If true, it means that the operation was rewired (bom inputs and material changed) */
    operation_was_rewired: (tags: any): boolean => (defVal(tags[ORDER_TAGS.operation_was_rewired], "false")) == "true",
    /** Order external type */
    order_external_type: (tags: any): string => defVal(tags[ORDER_TAGS.order_external_type], ""),
    /** Flag if this operation's output_stock was modified by triggered production logic */
    output_stock_modified_by_triggered_production: (tags: any): boolean => (defVal(tags[ORDER_TAGS.output_stock_modified_by_triggered_production], "false")) == "true",
    /** Flag if this is a parallel order, so there is a sibling somewhere */
    parallel_order: (tags: any): boolean => (defVal(tags[ORDER_TAGS.parallel_order], "false")) == "true",
    /** Flag if this is a parallel-parent order, so there are child orders somewhere */
    parallel_parent: (tags: any): boolean => (defVal(tags[ORDER_TAGS.parallel_parent], "false")) == "true",
    /** UUID of the order from which parallel order derived quantity. This order will be used when selecting the model. */
    parallel_principal_child: (tags: any): string => defVal(tags[ORDER_TAGS.parallel_principal_child], ""),
    /** Order's priority, will be used for scheduling. From highest (A) to lowest (E). Medium (C) is the default. */
    priority: (tags: any): string => defVal(tags[ORDER_TAGS.priority], ORDER_PRIORITIES.C),
    /** Indicator that operation should be scheduled to start and finish together with the following uperation and have duration equal to larger of current and following operation. */
    produce_together_with_next_operation: (tags: any): boolean => (defVal(tags[ORDER_TAGS.produce_together_with_next_operation], "false")) == "true",
    /** Number of milliseconds for the duration of producting the triggered production. */
    produce_trigger_delay_ms: (tags: any): number => parseFloat(defVal(tags[ORDER_TAGS.produce_trigger_delay_ms], "-1")),
    /** UUID of operation that whose stock will be triggered. */
    produce_trigger_op: (tags: any): string => defVal(tags[ORDER_TAGS.produce_trigger_op], ""),
    /** Type of handling of outstanding productions that should trigger. */
    produce_trigger_outstanding: (tags: any): string => defVal(tags[ORDER_TAGS.produce_trigger_outstanding], ""),
    /** Number of workdays in a week used when computing triggering delay */
    produce_trigger_workdays: (tags: any): number => parseFloat(defVal(tags[ORDER_TAGS.produce_trigger_workdays], "-1")),
    /** External id of the production tool on order (part_number field from ERP) */
    production_tool_external_id: (tags: any): string => defVal(tags[ORDER_TAGS.production_tool_external_id], ""),
    /** Title of the production tool on order. */
    production_tool_title: (tags: any): string => defVal(tags[ORDER_TAGS.production_tool_title], ""),
    /** External id of production version. */
    production_version: (tags: any): string => defVal(tags[ORDER_TAGS.production_version], ""),
    /** Imported all productions regardless of time window for fetching productions */
    productions_initial_import: (tags: any): boolean => (defVal(tags[ORDER_TAGS.productions_initial_import], "false")) == "true",
    /** Required deadline for manual-entry order */
    required_deadline: (tags: any): number => parseFloat(defVal(tags[ORDER_TAGS.required_deadline], "0")),
    /** External id of routing. */
    routing_external_id: (tags: any): string => defVal(tags[ORDER_TAGS.routing_external_id], ""),
    /** External id of routing group. */
    routing_group_external_id: (tags: any): string => defVal(tags[ORDER_TAGS.routing_group_external_id], ""),
    /** External id of routing item. */
    routing_item_external_id: (tags: any): string => defVal(tags[ORDER_TAGS.routing_item_external_id], ""),
    /** The operation is scheduled on the planning board */
    scheduled: (tags: any): boolean => (defVal(tags[ORDER_TAGS.scheduled], "false")) == "true",
    /** the operation dates are determined by LEAP and sent to ERP */
    scheduling_owner: (tags: any): boolean => (defVal(tags[ORDER_TAGS.scheduling_owner], "false")) == "true",
    /** Factor for conversion of quantity when shadow-ing. Parent operation's output quantity should be multiplied by this factor to get this operation's output quantity. */
    shadow_factor: (tags: any): number => parseFloat(defVal(tags[ORDER_TAGS.shadow_factor], "-1")),
    /** External id of operation that this operation is shadow-ing. */
    shadow_of: (tags: any): string => defVal(tags[ORDER_TAGS.shadow_of], ""),
    /** This is the chunk order (produced by split) and this is it's index */
    split_chunk_index: (tags: any): number => parseFloat(defVal(tags[ORDER_TAGS.split_chunk_index], "-1")),
    /** Flag if this is the chunk-parent order (produced by split), so there are child-chunk orders somewhere */
    split_chunk_parent: (tags: any): boolean => (defVal(tags[ORDER_TAGS.split_chunk_parent], "false")) == "true",
    /** Zero based index of subline to which the operation is assigned. Used with combination of sublines_number line tag. Default is 0. */
    subline_index: (tags: any): number => parseFloat(defVal(tags[ORDER_TAGS.subline_index], "0")),
    /** Superior order external id */
    superior_order_external_id: (tags: any): string => defVal(tags[ORDER_TAGS.superior_order_external_id], ""),
    /** Released production order refers to an order that has been created and released for production execution. Default is `true`. */
    system_status_released: (tags: any): boolean => (defVal(tags[ORDER_TAGS.system_status_released], "true")) == "true",
    /** External id of task list type (routing type). */
    task_list_type: (tags: any): string => defVal(tags[ORDER_TAGS.task_list_type], ""),
    /** Used when determining if tool setup is needed */
    tool_setup_code: (tags: any): string => defVal(tags[ORDER_TAGS.tool_setup_code], ""),
    /** Measurement unit used for reporting productions */
    total_quantity_measurement_unit: (tags: any): string => defVal(tags[ORDER_TAGS.total_quantity_measurement_unit], ""),
    /** If true, unconfirmed production logic will ignore productions of this order */
    unconfirmed_stock_ignore_production: (tags: any): boolean => (defVal(tags[ORDER_TAGS.unconfirmed_stock_ignore_production], "false")) == "true"
};

/** Order tags that should be copied to parallel orders from principal child */
export const PARALLEL_ORDER_COPY_TAGS = {
    comment: "comment",
    fix_line: "fix_line",
    freeze_time_end: "freeze_time_end",
    freeze_time_start: "freeze_time_start",
    ignore_on_planning_board: "ignore_on_planning_board",
    insight_freeze_order: "insight_freeze_order",
    subline_index: "subline_index"
};
export type ParallelOrderCopyTagsEnum =
    "comment" |
    "fix_line" |
    "freeze_time_end" |
    "freeze_time_start" |
    "ignore_on_planning_board" |
    "insight_freeze_order" |
    "subline_index";

/** Tags for decorating single stock-requirement. */
export const STOCK_REQUIREMENT_TAGS = {
    /** Stock-requirements's priority, will be used for scheduling. From highest (A) to lowest (E). Medium (C) is the default. */
    priority: "priority"
};

/** Tags for decorating single stock-requirement. */
export const STOCK_REQUIREMENT_TAGS_ACCESS = {
    /** Stock-requirements's priority, will be used for scheduling. From highest (A) to lowest (E). Medium (C) is the default. */
    priority: (tags: any): string => defVal(tags[STOCK_REQUIREMENT_TAGS.priority], "")
};

/** Tags for decorating single stock-location. */
export const STOCK_LOCATION_TAGS = {
    /** If true indicates that the storage location is owned by another plant and only affiliated with plant. */
    affiliated_site: "affiliated_site",
    /** Flag if this stock-location is derived from another one. */
    derived: "derived",
    /** If true indicates that the storage location is a system stock location used for tracking withdrawn and consumed on make to order orders. Default value is `false`. */
    make_to_order: "make_to_order",
    /** If true indicates that the storage location is available on the shopfloor. */
    production_site: "production_site",
    /** If true indicates that the storage location is used for returned items. */
    returns: "returns",
    /** Flag if this stock-location represents safety stock. */
    safety_stock: "safety_stock",
    /** If true indicates that the storage location can be used by other plants in shared consumption mode. Default value is `false`. */
    shared_consumption: "shared_consumption",
    /** If true indicates new timeseries will not be created for this stock location. */
    skip_timeseries: "skip_timeseries",
    /** If true indicates that the storage location is not available on the shopfloor but can be retrieved with some time cost when needed. */
    storage_site: "storage_site",
    /** If true indicates that the storage location is used for material withdrawals. This is used for stock_requirements. Default value is `false`. */
    withdrawal: "withdrawal",
    /** If true indicates that the storage location is a system stock location used for tracking withdrawn and consumed on work orders. Default value is `false`. */
    work_orders: "work_orders",
    /** If true indicates that the storage location is a system stock location used for tracking quantity yielded on operation that outputs to stock but is not delivered to stock yet. Default value is `false`. */
    yield_not_delivered: "yield_not_delivered"
};

/** Tags for decorating single stock-location. */
export const STOCK_LOCATION_TAGS_ACCESS = {
    /** If true indicates that the storage location is owned by another plant and only affiliated with plant. */
    affiliated_site: (tags: any): boolean => (defVal(tags[STOCK_LOCATION_TAGS.affiliated_site], "false")) == "true",
    /** Flag if this stock-location is derived from another one. */
    derived: (tags: any): boolean => (defVal(tags[STOCK_LOCATION_TAGS.derived], "false")) == "true",
    /** If true indicates that the storage location is a system stock location used for tracking withdrawn and consumed on make to order orders. Default value is `false`. */
    make_to_order: (tags: any): boolean => (defVal(tags[STOCK_LOCATION_TAGS.make_to_order], "false")) == "true",
    /** If true indicates that the storage location is available on the shopfloor. */
    production_site: (tags: any): boolean => (defVal(tags[STOCK_LOCATION_TAGS.production_site], "false")) == "true",
    /** If true indicates that the storage location is used for returned items. */
    returns: (tags: any): boolean => (defVal(tags[STOCK_LOCATION_TAGS.returns], "false")) == "true",
    /** Flag if this stock-location represents safety stock. */
    safety_stock: (tags: any): boolean => (defVal(tags[STOCK_LOCATION_TAGS.safety_stock], "false")) == "true",
    /** If true indicates that the storage location can be used by other plants in shared consumption mode. Default value is `false`. */
    shared_consumption: (tags: any): boolean => (defVal(tags[STOCK_LOCATION_TAGS.shared_consumption], "false")) == "true",
    /** If true indicates new timeseries will not be created for this stock location. */
    skip_timeseries: (tags: any): boolean => (defVal(tags[STOCK_LOCATION_TAGS.skip_timeseries], "false")) == "true",
    /** If true indicates that the storage location is not available on the shopfloor but can be retrieved with some time cost when needed. */
    storage_site: (tags: any): boolean => (defVal(tags[STOCK_LOCATION_TAGS.storage_site], "false")) == "true",
    /** If true indicates that the storage location is used for material withdrawals. This is used for stock_requirements. Default value is `false`. */
    withdrawal: (tags: any): boolean => (defVal(tags[STOCK_LOCATION_TAGS.withdrawal], "false")) == "true",
    /** If true indicates that the storage location is a system stock location used for tracking withdrawn and consumed on work orders. Default value is `false`. */
    work_orders: (tags: any): boolean => (defVal(tags[STOCK_LOCATION_TAGS.work_orders], "false")) == "true",
    /** If true indicates that the storage location is a system stock location used for tracking quantity yielded on operation that outputs to stock but is not delivered to stock yet. Default value is `false`. */
    yield_not_delivered: (tags: any): boolean => (defVal(tags[STOCK_LOCATION_TAGS.yield_not_delivered], "false")) == "true"
};

/** Tags for individual material. For additional tags, specific to ERP, see extra documentation at the bottom. */
export const MATERIAL_TAGS = {
    /** Background color for setup code */
    background_color: "background_color",
    /** Comment for material */
    comment: "comment",
    /** A string indicating material complexity */
    complexity: "complexity",
    /** External parent material ID */
    external_parent_material_id: "external_parent_material_id",
    /** External parent order ID */
    external_parent_order_id: "external_parent_order_id",
    /** External type is FERT */
    finished_product: "finished_product",
    /** Font color for setup code */
    font_color: "font_color",
    /** External type is HALB */
    half_product: "half_product",
    /** Flag indicating this material should be closely coupled with specific order (make to order). Default is `false` */
    make_to_order: "make_to_order",
    /** Material client */
    material_client: "material_client",
    /** Material client + material project */
    material_client_project: "material_client_project",
    /** Material product type */
    material_product_type: "material_product_type",
    /** Material project */
    material_project: "material_project",
    /** Indicates that this material is currently being produced by a subcontractor. Default is `false` */
    material_subcontracted: "material_subcontracted",
    /** Material type */
    material_type: "material_type",
    /** Alternative name for material */
    old_material_no: "old_material_no",
    /** Flag indicating this material is meta-material placeholder for parallel materials. */
    parallel: "parallel",
    /** A string that encodes the external procurement type code. By default the type is not known. */
    procurement_code_external: "procurement_code_external",
    /** External ID of the material the tool is set to. */
    production_resource_set_to: "production_resource_set_to",
    /** Tool status */
    production_resource_status: "production_resource_status",
    /** External type is ROH */
    raw_material: "raw_material",
    /** Code indicating setup id for this material. Will be used by planning together with a switch-matrix to indicate complexity of switching between certain materials. */
    setup_code: "setup_code",
    /** Always skip input tracking for this material in planning. Default is `false` */
    skip_input_tracking: "skip_input_tracking",
    /** Ignore this material when scanning orders. Default is `false` */
    skip_planning: "skip_planning",
    /** Ignore this material when doing stock forecasting and alerting. Default is `false` */
    skip_stock_analysis: "skip_stock_analysis",
    /** A string that encodes the external type code. By default the type is not known. */
    type_external: "type_external",
    /** A string that encodes the external source that defined the type code (SAP for example). By default the source is unknown. An example is `SAP`. */
    type_source: "type_source"
};

/** Tags for individual material. For additional tags, specific to ERP, see extra documentation at the bottom. */
export const MATERIAL_TAGS_ACCESS = {
    /** Background color for setup code */
    background_color: (tags: any): string => defVal(tags[MATERIAL_TAGS.background_color], ""),
    /** Comment for material */
    comment: (tags: any): string => defVal(tags[MATERIAL_TAGS.comment], ""),
    /** A string indicating material complexity */
    complexity: (tags: any): string => defVal(tags[MATERIAL_TAGS.complexity], ""),
    /** External parent material ID */
    external_parent_material_id: (tags: any): string => defVal(tags[MATERIAL_TAGS.external_parent_material_id], ""),
    /** External parent order ID */
    external_parent_order_id: (tags: any): string => defVal(tags[MATERIAL_TAGS.external_parent_order_id], ""),
    /** External type is FERT */
    finished_product: (tags: any): boolean => (defVal(tags[MATERIAL_TAGS.finished_product], "false")) == "true",
    /** Font color for setup code */
    font_color: (tags: any): string => defVal(tags[MATERIAL_TAGS.font_color], ""),
    /** External type is HALB */
    half_product: (tags: any): boolean => (defVal(tags[MATERIAL_TAGS.half_product], "false")) == "true",
    /** Flag indicating this material should be closely coupled with specific order (make to order). Default is `false` */
    make_to_order: (tags: any): boolean => (defVal(tags[MATERIAL_TAGS.make_to_order], "false")) == "true",
    /** Material client */
    material_client: (tags: any): string => defVal(tags[MATERIAL_TAGS.material_client], ""),
    /** Material client + material project */
    material_client_project: (tags: any): string => defVal(tags[MATERIAL_TAGS.material_client_project], ""),
    /** Material product type */
    material_product_type: (tags: any): string => defVal(tags[MATERIAL_TAGS.material_product_type], ""),
    /** Material project */
    material_project: (tags: any): string => defVal(tags[MATERIAL_TAGS.material_project], ""),
    /** Indicates that this material is currently being produced by a subcontractor. Default is `false` */
    material_subcontracted: (tags: any): boolean => (defVal(tags[MATERIAL_TAGS.material_subcontracted], "false")) == "true",
    /** Material type */
    material_type: (tags: any): string => defVal(tags[MATERIAL_TAGS.material_type], ""),
    /** Alternative name for material */
    old_material_no: (tags: any): string => defVal(tags[MATERIAL_TAGS.old_material_no], ""),
    /** Flag indicating this material is meta-material placeholder for parallel materials. */
    parallel: (tags: any): boolean => (defVal(tags[MATERIAL_TAGS.parallel], "false")) == "true",
    /** A string that encodes the external procurement type code. By default the type is not known. */
    procurement_code_external: (tags: any): string => defVal(tags[MATERIAL_TAGS.procurement_code_external], ""),
    /** External ID of the material the tool is set to. */
    production_resource_set_to: (tags: any): string => defVal(tags[MATERIAL_TAGS.production_resource_set_to], ""),
    /** Tool status */
    production_resource_status: (tags: any): string => defVal(tags[MATERIAL_TAGS.production_resource_status], ""),
    /** External type is ROH */
    raw_material: (tags: any): boolean => (defVal(tags[MATERIAL_TAGS.raw_material], "false")) == "true",
    /** Code indicating setup id for this material. Will be used by planning together with a switch-matrix to indicate complexity of switching between certain materials. */
    setup_code: (tags: any): string => defVal(tags[MATERIAL_TAGS.setup_code], ""),
    /** Always skip input tracking for this material in planning. Default is `false` */
    skip_input_tracking: (tags: any): boolean => (defVal(tags[MATERIAL_TAGS.skip_input_tracking], "false")) == "true",
    /** Ignore this material when scanning orders. Default is `false` */
    skip_planning: (tags: any): boolean => (defVal(tags[MATERIAL_TAGS.skip_planning], "false")) == "true",
    /** Ignore this material when doing stock forecasting and alerting. Default is `false` */
    skip_stock_analysis: (tags: any): boolean => (defVal(tags[MATERIAL_TAGS.skip_stock_analysis], "false")) == "true",
    /** A string that encodes the external type code. By default the type is not known. */
    type_external: (tags: any): string => defVal(tags[MATERIAL_TAGS.type_external], ""),
    /** A string that encodes the external source that defined the type code (SAP for example). By default the source is unknown. An example is `SAP`. */
    type_source: (tags: any): string => defVal(tags[MATERIAL_TAGS.type_source], "")
};

/** Tags for material+plant combination. */
export const MATERIAL_PLANT_TAGS = {
    /** If true it indicates that the material is a bulk material. By default `false`. */
    bulk: "bulk",
    /** The uuid from the plant that this material consumes from */
    consumes_from_plant: "consumes_from_plant",
    /** Currency */
    currency: "currency",
    /** ISO code of currency */
    currency_iso: "currency_iso",
    /** If true it indicates that the material is a dummy (phantom) material. By default `false`. */
    dummy: "dummy",
    /** Indicating that subcontracting is supplying this material. Default is `false` */
    material_subcontracted: "material_subcontracted",
    /** Specify delivery time for chunk orders in hours. */
    material_subcontracting_lead_time: "material_subcontracting_lead_time",
    /** Moving average price/periodic unit price */
    moving_pr: "moving_pr",
    /** Price unit */
    price_unit: "price_unit",
    /** A string that encodes the procurement type code */
    procurement_code: "procurement_code",
    /** Standard price */
    std_price: "std_price",
    /** Stock-requirements import-types to exclude for the material. E.g: AR,BA,BE,VE */
    stock_requirements_import_exclude_types: "stock_requirements_import_exclude_types",
    /** Stock-requirements import types to include for the material. E.g: AR,BA,BE,VE */
    stock_requirements_import_include_types: "stock_requirements_import_include_types",
    /** If true it indicates that the material is a subcontractor material. By default `false`. */
    subcontractor: "subcontractor"
};

/** Tags for material+plant combination. */
export const MATERIAL_PLANT_TAGS_ACCESS = {
    /** If true it indicates that the material is a bulk material. By default `false`. */
    bulk: (tags: any): boolean => (defVal(tags[MATERIAL_PLANT_TAGS.bulk], "false")) == "true",
    /** The uuid from the plant that this material consumes from */
    consumes_from_plant: (tags: any): string => defVal(tags[MATERIAL_PLANT_TAGS.consumes_from_plant], ""),
    /** Currency */
    currency: (tags: any): string => defVal(tags[MATERIAL_PLANT_TAGS.currency], ""),
    /** ISO code of currency */
    currency_iso: (tags: any): string => defVal(tags[MATERIAL_PLANT_TAGS.currency_iso], ""),
    /** If true it indicates that the material is a dummy (phantom) material. By default `false`. */
    dummy: (tags: any): boolean => (defVal(tags[MATERIAL_PLANT_TAGS.dummy], "false")) == "true",
    /** Indicating that subcontracting is supplying this material. Default is `false` */
    material_subcontracted: (tags: any): boolean => (defVal(tags[MATERIAL_PLANT_TAGS.material_subcontracted], "false")) == "true",
    /** Specify delivery time for chunk orders in hours. */
    material_subcontracting_lead_time: (tags: any): number => parseFloat(defVal(tags[MATERIAL_PLANT_TAGS.material_subcontracting_lead_time], "-1")),
    /** Moving average price/periodic unit price */
    moving_pr: (tags: any): string => defVal(tags[MATERIAL_PLANT_TAGS.moving_pr], ""),
    /** Price unit */
    price_unit: (tags: any): string => defVal(tags[MATERIAL_PLANT_TAGS.price_unit], ""),
    /** A string that encodes the procurement type code */
    procurement_code: (tags: any): string => defVal(tags[MATERIAL_PLANT_TAGS.procurement_code], ""),
    /** Standard price */
    std_price: (tags: any): string => defVal(tags[MATERIAL_PLANT_TAGS.std_price], ""),
    /** Stock-requirements import-types to exclude for the material. E.g: AR,BA,BE,VE */
    stock_requirements_import_exclude_types: (tags: any): string => defVal(tags[MATERIAL_PLANT_TAGS.stock_requirements_import_exclude_types], ""),
    /** Stock-requirements import types to include for the material. E.g: AR,BA,BE,VE */
    stock_requirements_import_include_types: (tags: any): string => defVal(tags[MATERIAL_PLANT_TAGS.stock_requirements_import_include_types], ""),
    /** If true it indicates that the material is a subcontractor material. By default `false`. */
    subcontractor: (tags: any): boolean => (defVal(tags[MATERIAL_PLANT_TAGS.subcontractor], "false")) == "true"
};

/** Tags that are assignable to single organization */
export const ORG_TAGS = {
    /** Flag if LEAP users should be automatically created from people list, when requested. Default is 'false' */
    autocreate_users_from_people: "autocreate_users_from_people",
    /** Enable syncing of changes on extra lines on planning board for the case when changes on the planned lines affect scheduling status or timing of orders of extra lines (e.g. autopilot). */
    enable_sync_extra_line_order_changes: "enable_sync_extra_line_order_changes",
    /** Comma separated list of external_types of materials that should be ignored in planning. Default is empty string. */
    ignored_materials: "ignored_materials",
    /** Indicates if chunk orders on manual-entry lines are automatically imported. Default is `false`. */
    manual_order_entry_automatic_import: "manual_order_entry_automatic_import",
    /** Flag to enable disabling operation realized quantity compensation, which makes realization within order decrease monotonicaly over operations. */
    operation_quantity_compensating: "operation_quantity_compensating",
    /** Maximal allowed age (in hours) of plan data that we allow. Default is 1.5. */
    plan_check_allowed_delay: "plan_check_allowed_delay",
    /** Skip all manufacturing checks */
    skip_check_all: "skip_check_all",
    /** Skip downtime checks */
    skip_check_downtimes: "skip_check_downtimes",
    /** Skip plan checks */
    skip_check_plan: "skip_check_plan",
    /** Skip production checks */
    skip_check_production: "skip_check_production",
    /** Skip report checks */
    skip_check_report: "skip_check_report",
    /** Skip stock checks */
    skip_check_stock: "skip_check_stock",
    /** Skip stock requirements checks */
    skip_check_stock_requirements: "skip_check_stock_requirements",
    /** Skip preparations of insights for this org */
    skip_insights: "skip_insights",
    /** Maximal allowed age (in hours) of stock data that we allow. Default is 25. */
    stock_check_allowed_delay: "stock_check_allowed_delay",
    /** Maximal allowed age (in hours) of stock requirements data that we allow. Default is 25. */
    stock_requirements_check_allowed_delay: "stock_requirements_check_allowed_delay",
    /** Flag if recently closed orders should be used in simulation. Default is 'false' */
    use_recently_closed: "use_recently_closed",
    /** Use sim2 algorithm */
    use_sim2: "use_sim2"
};

/** Tags that are assignable to single organization */
export const ORG_TAGS_ACCESS = {
    /** Flag if LEAP users should be automatically created from people list, when requested. Default is 'false' */
    autocreate_users_from_people: (tags: any): boolean => (defVal(tags[ORG_TAGS.autocreate_users_from_people], "false")) == "true",
    /** Enable syncing of changes on extra lines on planning board for the case when changes on the planned lines affect scheduling status or timing of orders of extra lines (e.g. autopilot). */
    enable_sync_extra_line_order_changes: (tags: any): boolean => (defVal(tags[ORG_TAGS.enable_sync_extra_line_order_changes], "false")) == "true",
    /** Comma separated list of external_types of materials that should be ignored in planning. Default is empty string. */
    ignored_materials: (tags: any): string => defVal(tags[ORG_TAGS.ignored_materials], ""),
    /** Indicates if chunk orders on manual-entry lines are automatically imported. Default is `false`. */
    manual_order_entry_automatic_import: (tags: any): boolean => (defVal(tags[ORG_TAGS.manual_order_entry_automatic_import], "false")) == "true",
    /** Flag to enable disabling operation realized quantity compensation, which makes realization within order decrease monotonicaly over operations. */
    operation_quantity_compensating: (tags: any): boolean => (defVal(tags[ORG_TAGS.operation_quantity_compensating], "true")) == "true",
    /** Maximal allowed age (in hours) of plan data that we allow. Default is 1.5. */
    plan_check_allowed_delay: (tags: any): number => parseFloat(defVal(tags[ORG_TAGS.plan_check_allowed_delay], "1.5")),
    /** Skip all manufacturing checks */
    skip_check_all: (tags: any): boolean => (defVal(tags[ORG_TAGS.skip_check_all], "false")) == "true",
    /** Skip downtime checks */
    skip_check_downtimes: (tags: any): boolean => (defVal(tags[ORG_TAGS.skip_check_downtimes], "false")) == "true",
    /** Skip plan checks */
    skip_check_plan: (tags: any): boolean => (defVal(tags[ORG_TAGS.skip_check_plan], "false")) == "true",
    /** Skip production checks */
    skip_check_production: (tags: any): boolean => (defVal(tags[ORG_TAGS.skip_check_production], "false")) == "true",
    /** Skip report checks */
    skip_check_report: (tags: any): boolean => (defVal(tags[ORG_TAGS.skip_check_report], "false")) == "true",
    /** Skip stock checks */
    skip_check_stock: (tags: any): boolean => (defVal(tags[ORG_TAGS.skip_check_stock], "false")) == "true",
    /** Skip stock requirements checks */
    skip_check_stock_requirements: (tags: any): boolean => (defVal(tags[ORG_TAGS.skip_check_stock_requirements], "false")) == "true",
    /** Skip preparations of insights for this org */
    skip_insights: (tags: any): boolean => (defVal(tags[ORG_TAGS.skip_insights], "false")) == "true",
    /** Maximal allowed age (in hours) of stock data that we allow. Default is 25. */
    stock_check_allowed_delay: (tags: any): number => parseFloat(defVal(tags[ORG_TAGS.stock_check_allowed_delay], "25")),
    /** Maximal allowed age (in hours) of stock requirements data that we allow. Default is 25. */
    stock_requirements_check_allowed_delay: (tags: any): number => parseFloat(defVal(tags[ORG_TAGS.stock_requirements_check_allowed_delay], "25")),
    /** Flag if recently closed orders should be used in simulation. Default is 'false' */
    use_recently_closed: (tags: any): boolean => (defVal(tags[ORG_TAGS.use_recently_closed], "false")) == "true",
    /** Use sim2 algorithm */
    use_sim2: (tags: any): boolean => (defVal(tags[ORG_TAGS.use_sim2], "false")) == "true"
};

/** Tags that are assignable to single sensor */
export const SENSOR_TAGS = {
    /** External id of line that this sensor is associated with */
    line_external_id: "line_external_id"
};

/** Tags that are assignable to single sensor */
export const SENSOR_TAGS_ACCESS = {
    /** External id of line that this sensor is associated with */
    line_external_id: (tags: any): string => defVal(tags[SENSOR_TAGS.line_external_id], "")
};

/** Tags that are assignable to single tool */
export const TOOL_TAGS = {
    /** Flag that denotes if this tool is active */
    active: "active",
    /** Optional indication which material is produced by this tool, if applicable. */
    material_external_id: "material_external_id"
};

/** Tags that are assignable to single tool */
export const TOOL_TAGS_ACCESS = {
    /** Flag that denotes if this tool is active */
    active: (tags: any): boolean => (defVal(tags[TOOL_TAGS.active], "false")) == "true",
    /** Optional indication which material is produced by this tool, if applicable. */
    material_external_id: (tags: any): string => defVal(tags[TOOL_TAGS.material_external_id], "")
};

/** Standard insight types. Some insights are just forwarded events */
export const INSIGHT_TYPES = {
    /** Incompatible parallel operations */
    conditional_capacity_violated: "conditional_capacity_violated",
    /** Detected downtimes */
    downtime: "downtime",
    /** Detected downtimes via IoT sensors */
    downtime_iot: "downtime_iot",
    /** Frozen order end date early or late */
    freeze_order_move: "freeze_order_move",
    /** Downtimes reported from external system */
    man_downtime: "man_downtime",
    /** Workcenter with expected work and no worker assigned */
    no_person: "no_person",
    /** Worker assigned to workcenter where shift is not enabled */
    no_shift: "no_shift",
    /** Workcenter has enabled shift but no work order is planned for that shift */
    no_work_in_shift: "no_work_in_shift",
    /** Work order consecutive operations executed in incorrect order. */
    operation_constraint_violated: "operation_constraint_violated",
    /** Work order operation is producting material who's stock will be negative during operation */
    operation_delay_downstream: "operation_delay_downstream",
    /** Work order operation does not have sufficient input material on stock for at least one material */
    operation_no_input_material: "operation_no_input_material",
    /** Earliest shift when material stock level on the whole plant becomes negative */
    out_of_stock_plant: "out_of_stock_plant",
    /** Earliest shift when material stock level for the shopfloor locations becomes negative */
    out_of_stock_shopfloor: "out_of_stock_shopfloor",
    /** Order has part of operations scheduled and part unscheduled */
    partially_scheduled: "partially_scheduled",
    /** Expected finish for order changed */
    plan_change_end: "plan_change_end",
    /** Order quantity changed */
    plan_change_quantity: "plan_change_quantity",
    /** Shift for workcenter enabled or disabled */
    plan_change_shifts: "plan_change_shifts",
    /** Expected start for order changed */
    plan_change_start: "plan_change_start",
    /** Metric in outlier quantil */
    quantiles_simple: "quantiles_simple",
    /** Required quantity of material will not be on on stock when stock requirement is due */
    stock_requirement_delay: "stock_requirement_delay",
    /** Metrico outsid the thresholds */
    thresholds_simple: "thresholds_simple",
    /** Same tool used at the same time by multiple orders */
    tool_overlap: "tool_overlap",
    /** Tool setup predicted to happen */
    tool_setup: "tool_setup"
};
export type InsightTypesEnum =
    "conditional_capacity_violated" |
    "downtime" |
    "downtime_iot" |
    "freeze_order_move" |
    "man_downtime" |
    "no_person" |
    "no_shift" |
    "no_work_in_shift" |
    "operation_constraint_violated" |
    "operation_delay_downstream" |
    "operation_no_input_material" |
    "out_of_stock_plant" |
    "out_of_stock_shopfloor" |
    "partially_scheduled" |
    "plan_change_end" |
    "plan_change_quantity" |
    "plan_change_shifts" |
    "plan_change_start" |
    "quantiles_simple" |
    "stock_requirement_delay" |
    "thresholds_simple" |
    "tool_overlap" |
    "tool_setup";

/** Tags that are assignable to single insight */
export const INSIGHT_TAGS = {
    /** UUID of the analysis that produced this event (insight) */
    analysis_uuid: "analysis_uuid",
    /** No input material insight still holds even if we allow orders to use safety stock */
    below_safety_stock: "below_safety_stock",
    /** UUID of the datasource that contains data that this event (insight) is based on */
    datasource_uuid: "datasource_uuid",
    /** Hash of this insight. Used for de-duplication and updates. */
    hash: "hash",
    /** UUID of the associated line */
    line_uuid: "line_uuid",
    /** Associated line uuids, concatenated with ',' */
    line_uuids: "line_uuids",
    /** External id of the associated material */
    material_external_id: "material_external_id",
    /** External type of the associated material */
    material_external_type: "material_external_type",
    /** Title of the associated material */
    material_title: "material_title",
    /** UUID of the associated material */
    material_uuid: "material_uuid",
    /** Associated material uuids, concatenated with ',' */
    material_uuids: "material_uuids",
    /** True when insight already started and is still ongoing */
    ongoing: "ongoing",
    /** From what circle is the input material shortage, more possible */
    operation_no_input_material_circle: "operation_no_input_material_circle",
    /** External id of the associated work-order */
    order_external_id: "order_external_id",
    /** Operation process number */
    order_process_num: "order_process_num",
    /** UUID of the associated work-order */
    order_uuid: "order_uuid",
    /** UUIDs of the associated work-orders, concatenated with ',' */
    order_uuids: "order_uuids",
    /** External ID of associated plant */
    plant_external_id: "plant_external_id",
    /** Title of the associated plant */
    plant_title: "plant_title",
    /** UUID of the associated plant */
    plant_uuid: "plant_uuid",
    /** Tag of the shift to which this insight points */
    shift_tag: "shift_tag",
    /** UUIDs (separated by ',') of tickets that are associated with this insight */
    ticket_uuids: "ticket_uuids",
    /** Prefix for description translations */
    translated_description: "translated_description",
    /** Prefix for title translations */
    translated_title: "translated_title"
};

/** Tags that are assignable to single insight */
export const INSIGHT_TAGS_ACCESS = {
    /** UUID of the analysis that produced this event (insight) */
    analysis_uuid: (tags: any): string => defVal(tags[INSIGHT_TAGS.analysis_uuid], ""),
    /** No input material insight still holds even if we allow orders to use safety stock */
    below_safety_stock: (tags: any): boolean => (defVal(tags[INSIGHT_TAGS.below_safety_stock], "false")) == "true",
    /** UUID of the datasource that contains data that this event (insight) is based on */
    datasource_uuid: (tags: any): string => defVal(tags[INSIGHT_TAGS.datasource_uuid], ""),
    /** Hash of this insight. Used for de-duplication and updates. */
    hash: (tags: any): string => defVal(tags[INSIGHT_TAGS.hash], ""),
    /** UUID of the associated line */
    line_uuid: (tags: any): string => defVal(tags[INSIGHT_TAGS.line_uuid], ""),
    /** Associated line uuids, concatenated with ',' */
    line_uuids: (tags: any): string => defVal(tags[INSIGHT_TAGS.line_uuids], ""),
    /** External id of the associated material */
    material_external_id: (tags: any): string => defVal(tags[INSIGHT_TAGS.material_external_id], ""),
    /** External type of the associated material */
    material_external_type: (tags: any): string => defVal(tags[INSIGHT_TAGS.material_external_type], ""),
    /** Title of the associated material */
    material_title: (tags: any): string => defVal(tags[INSIGHT_TAGS.material_title], ""),
    /** UUID of the associated material */
    material_uuid: (tags: any): string => defVal(tags[INSIGHT_TAGS.material_uuid], ""),
    /** Associated material uuids, concatenated with ',' */
    material_uuids: (tags: any): string => defVal(tags[INSIGHT_TAGS.material_uuids], ""),
    /** True when insight already started and is still ongoing */
    ongoing: (tags: any): boolean => (defVal(tags[INSIGHT_TAGS.ongoing], "false")) == "true",
    /** From what circle is the input material shortage, more possible */
    operation_no_input_material_circle: (tags: any): string => defVal(tags[INSIGHT_TAGS.operation_no_input_material_circle], ""),
    /** External id of the associated work-order */
    order_external_id: (tags: any): string => defVal(tags[INSIGHT_TAGS.order_external_id], ""),
    /** Operation process number */
    order_process_num: (tags: any): string => defVal(tags[INSIGHT_TAGS.order_process_num], ""),
    /** UUID of the associated work-order */
    order_uuid: (tags: any): string => defVal(tags[INSIGHT_TAGS.order_uuid], ""),
    /** UUIDs of the associated work-orders, concatenated with ',' */
    order_uuids: (tags: any): string => defVal(tags[INSIGHT_TAGS.order_uuids], ""),
    /** External ID of associated plant */
    plant_external_id: (tags: any): string => defVal(tags[INSIGHT_TAGS.plant_external_id], ""),
    /** Title of the associated plant */
    plant_title: (tags: any): string => defVal(tags[INSIGHT_TAGS.plant_title], ""),
    /** UUID of the associated plant */
    plant_uuid: (tags: any): string => defVal(tags[INSIGHT_TAGS.plant_uuid], ""),
    /** Tag of the shift to which this insight points */
    shift_tag: (tags: any): string => defVal(tags[INSIGHT_TAGS.shift_tag], ""),
    /** UUIDs (separated by ',') of tickets that are associated with this insight */
    ticket_uuids: (tags: any): string => defVal(tags[INSIGHT_TAGS.ticket_uuids], ""),
    /** Prefix for description translations */
    translated_description: (tags: any): string => defVal(tags[INSIGHT_TAGS.translated_description], ""),
    /** Prefix for title translations */
    translated_title: (tags: any): string => defVal(tags[INSIGHT_TAGS.translated_title], "")
};

/** Tags that are assignable to a production */
export const PRODUCTION_TAGS = {
    /** Delivery document ID, for productions based on receiving materials from subcontractor */
    subcontractor_delivery_document_id: "subcontractor_delivery_document_id"
};

/** Tags that are assignable to a production */
export const PRODUCTION_TAGS_ACCESS = {
    /** Delivery document ID, for productions based on receiving materials from subcontractor */
    subcontractor_delivery_document_id: (tags: any): string => defVal(tags[PRODUCTION_TAGS.subcontractor_delivery_document_id], "")
};

/** Tags that are assignable to a process */
export const PROCESS_TAGS = {
    /** Process can be used in manual mode on planning table */
    manual_use_in_planning: "manual_use_in_planning",
    /** The line related to the process is preferred */
    preferred: "preferred",
    /** These production versions are applicable (comma separated production version external ids) */
    production_versions: "production_versions",
    /** This process can be used as alternative in the automatic planning process */
    use_in_planning: "use_in_planning"
};

/** Tags that are assignable to a process */
export const PROCESS_TAGS_ACCESS = {
    /** Process can be used in manual mode on planning table */
    manual_use_in_planning: (tags: any): boolean => (defVal(tags[PROCESS_TAGS.manual_use_in_planning], "false")) == "true",
    /** The line related to the process is preferred */
    preferred: (tags: any): boolean => (defVal(tags[PROCESS_TAGS.preferred], "false")) == "true",
    /** These production versions are applicable (comma separated production version external ids) */
    production_versions: (tags: any): string => defVal(tags[PROCESS_TAGS.production_versions], ""),
    /** This process can be used as alternative in the automatic planning process */
    use_in_planning: (tags: any): boolean => (defVal(tags[PROCESS_TAGS.use_in_planning], "false")) == "true"
};

function defVal(a: string, b: string): string { return (a == undefined ? b : a); }
