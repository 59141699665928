// @flow
import React from "react";

import * as tt from "../../lib/backend/ticketing.generated.types";
import UserStepField from "./UserStepField";
import { translate } from "../IntlProviderWrapper";
import { getBackend as getBackend2 } from "../../lib/backend/Backend2";
import ErrorComponent from "../ErrorComponent";
import SpanButton from "../react/SpanButton";
import TicketDetailsModal from "./TicketDetailsModal";
import { setTimeoutAsync } from "../../lib/Util";

import { SET_INBOX_VIEW } from "./reducers/ticketing";
import type { TicketInboxData } from "./CommonTicketTypes.jsx";
import { FormattedMessage } from "react-intl";

import * as Auth from "../../lib/Auth";

type Props = {
    ticket_uuid: string,
    reduxDispatch?: (args: any) => void,
    is_kiosk: boolean,
    close?: () => void,
    wait_for?: tt.IWaitForIndication | null,
    is_open?: boolean,
}
type State = {
    error: string,
    show_modal: boolean,
    field_values: tt.IFieldValuePair[],
    successfull_save: boolean,
    ticket: TicketInboxData,
    text_to_show: string
};

class UserStep extends React.Component<Props, State> {

    constructor() {
        super();
        const state: State = {
            error: "",
            field_values: [],
            show_modal: false,
            successfull_save: false,
            ticket: {
                user_step: null,
                title: "",
                workflow_title: ""
            },
            text_to_show: ""

        };

        this.state = state;
    }

    async componentDidMount() {

        const username = Auth.getLoggedinUser().username;

        if (this.props.ticket_uuid !== undefined) {

            let ticket = await getBackend2().ticketing.getTicketBasicInfo({ uuid: this.props.ticket_uuid });

            if (ticket !== undefined && this.props.wait_for !== undefined &&
                this.props.wait_for !== null && this.props.wait_for.step_name !== undefined) {

                const message_to_show = this.props.wait_for.message || "";
                this.setState({ text_to_show: message_to_show });

                let requests_already_sent = 0;
                let number_of_attempts = 0;

                if (this.props && this.props.wait_for && this.props.wait_for.timeout_msec) {
                    number_of_attempts = Math.floor(this.props.wait_for.timeout_msec / 2000);
                }

                while (requests_already_sent < number_of_attempts) {

                    if (this.props.is_open === false) {
                        break;
                    }

                    if (this.props.wait_for && this.props.wait_for.step_name === ticket.step) {

                        if (ticket.assigned_to === username) {

                            const ticket_user_step = await getBackend2().ticketing.getUserStepInfo({ uuid: this.props.ticket_uuid });

                            ticket.title = "";
                            ticket.workflow_title = "";

                            ticket.user_step = ticket_user_step;
                            this.setState({ ticket: ticket });
                            break;

                        } else {
                            this.props.close && this.props.close();
                            break;
                        }
                    }

                    await setTimeoutAsync(2000);

                    ticket = await getBackend2().ticketing.getTicketBasicInfo({ uuid: this.props.ticket_uuid });
                    requests_already_sent++;

                }

                // timeout
                if (requests_already_sent === number_of_attempts) {
                    this.props.close && this.props.close();
                }

            } else {

                if (ticket.assigned_to === username) {

                    const ticket_user_step = await getBackend2().ticketing.getUserStepInfo({ uuid: this.props.ticket_uuid });

                    ticket.title = "";
                    ticket.workflow_title = "";

                    ticket.user_step = ticket_user_step;
                    this.setState({ ticket: ticket });
                }
            }
        }
    }

    async onSave(dont_move_to_next_step?: boolean) {

        const ticket_uuid = this.props && this.props.ticket_uuid;
        let res = null;
        try {
            res = await getBackend2().ticketing.closeUserStep({
                uuid: ticket_uuid,
                field_values: this.state.field_values,
                dont_move_to_next_step: dont_move_to_next_step || false
            });
        } catch (e) {
            this.setState({
                error: e.message
            });
        }
        if (!res) {
            return;
        }
        if (res.success) {
            this.setState({
                successfull_save: true,
                error: ""
            });

            if (this.props.reduxDispatch !== undefined) {
                // switch to inbox view
                setTimeout(() => {
                    this.props.reduxDispatch && this.props.reduxDispatch({ type: SET_INBOX_VIEW, data: undefined });
                }, 2000);
            } else {
                if (this.props.close !== undefined) {
                    // wait 2 seconds and then close the modal
                    setTimeout(() => {
                        this.props.close && this.props.close();
                    }, 2000);
                }
            }
        }
    }

    getUpdatedFieldValues(name: string, value: string): tt.IFieldValuePair[] {

        const field_values = this.state.field_values || [];
        const index = field_values.findIndex(fv => fv.name === name);
        if (index >= 0) {
            const field_value = field_values[index]
            field_value.value = value;
            field_values[index] = field_value;
        } else {
            field_values.push({
                name: name,
                value: value
            });
        }
        return [...field_values];
    }

    onChange(field_name: string, value: string, alsoSave: boolean = false): void {
        const field_values = this.getUpdatedFieldValues(field_name, value);
        if (alsoSave) {
            this.setState({ field_values }, () => this.onSave());
        } else {
            this.setState({ field_values });
        }
    }

    renderSuccessfullSave(): any {
        if (this.state.error) {
            return <ErrorComponent key={"error"} msg={this.state.error} type="error" />
        }
        if (!this.state.successfull_save) {
            return null;
        }
        const msg = (
            translate("common.success_save", "Data saved successfuly") + ". " +
            translate("common.redirected_shortly", "You will be redirected shortly.,,,")
        );
        return <ErrorComponent key={"success"} msg={msg} type="success" />
    }

    getUserStepsFields(): any {

        const ticket = this.state.ticket;

        if (!ticket || !ticket.user_step) {
            if (this.state.text_to_show !== "") {
                return (<div>
                    {this.state.text_to_show}
                </div>);
            } else {
                return (<div>
                    <FormattedMessage id="Ticketing.no_need" />
                </div>);
            }

        }

        const user_steps = [];
        for (let i = 0; i < ticket.user_step.fields.length; i++) {
            const user_step_field = ticket.user_step.fields[i];

            let field_value = this.state.field_values.find(fv => fv.name === user_step_field.name);

            if (field_value) {
                field_value = field_value.value;
            } else {
                field_value = user_step_field.def_value;
            }

            user_steps.push(
                <UserStepField
                    key={i}
                    field={user_step_field}
                    type={user_step_field.type}
                    value={field_value || ""}
                    onChange={(f, v, s) => this.onChange(f, v, s)}
                    onSave={() => this.onSave()}
                    is_kiosk={this.props.is_kiosk}
                />
            );
        }
        return user_steps;
    }

    renderNextButtonIfNeeded(): any {
        if (!this.state.ticket || !this.state.ticket.user_step) {
            return null;
        }
        const user_step = this.state.ticket.user_step;
        const skip_render = user_step.fields.some(x => x.type == "buttons");
        if (skip_render) {
            return null;
        }

        const button_position = this.props.is_kiosk ? "ticket-field pt-4 user-step-buttons-centered" : "ticket-field pt-4 user-step-buttons";

        const button_style = this.props.is_kiosk ? "btn btn-primary user-step-field-button-kiosk" : "btn btn-primary user-step-field-button";
        const button2_style = this.props.is_kiosk ? "btn user-step-field-button-kiosk" : "btn user-step-field-button";

        return <div className={button_position}>
            <button
                className={button_style}
                style={{ marginBottom: "10px", marginTop: "10px" }}
                onClick={() => this.onSave()}
            >
                {user_step.button_title || translate("common.save", "Save")}
            </button>
            <button
                className={button2_style}
                style={{ marginBottom: "10px", marginTop: "10px" }}
                onClick={() => this.onSave(true)}
            >
                {translate("common.save_data_only", "Save data only")}
            </button>
        </div>;
    }

    renderBackButton(): any {

        if (!this.state.ticket || !this.state.ticket.user_step || !this.props.reduxDispatch) {
            return null;
        }

        let style_buttons = this.props.is_kiosk ? "btn user-step-field-button-kiosk" : "btn user-step-field-button";
        let button_position = this.props.is_kiosk ? "ticket-field user-step-buttons-centered" : "ticket-field user-step-buttons";

        return (
            <div
                className={button_position}
            >
                <button
                    className={style_buttons}
                    onClick={() => this.props.reduxDispatch && this.props.reduxDispatch({ type: SET_INBOX_VIEW, data: undefined })}
                >
                    <FormattedMessage id="common.back" defaultMessage="back" />
                </button>
            </div>
        );

    }

    getUserStepTitle(): any {

        const title = (this.state.ticket &&
            this.state.ticket.user_step &&
            this.state.ticket.user_step.title) || "";

        return (
            <div className={this.props.is_kiosk ? "user-step-title-big" : "user-step-title-small"}>
                {title}
            </div>);
    }

    getUserStepText(): any {

        const text = (this.state.ticket &&
            this.state.ticket.user_step &&
            this.state.ticket.user_step.text) || "";

        return (
            <div className={this.props.is_kiosk ? "user-step-subtitle-big" : "user-step-subtitle-small"}>
                {text}
            </div>);
    }

    onOpenModal(): void {
        this.setState({ show_modal: true });
    }

    onCloseModal(): void {
        this.setState({ show_modal: false });
    }

    renderModal(): any {
        return <TicketDetailsModal
            show_ticket_details_uuid={this.state.show_modal ? this.props.ticket_uuid : null}
            onClose={() => this.onCloseModal()}
            requestRefreshCallback={() => null}
        />
    }

    render(): any {

        const ticket = this.state.ticket;
        if (ticket.user_step === undefined) {
            return null;
        }

        return (
            <div>
                <div className="ticket">
                    {this.getUserStepTitle()}
                    <div>
                        {!this.props.is_kiosk && this.state.ticket && this.state.ticket.user_step !== null &&
                            <div className="pull-right p-3 d-none d-md-block">
                                <SpanButton
                                    onClick={() => this.onOpenModal()}
                                    is_active={false}
                                    toggle_active_status={false}
                                    title={translate("common.details", "Details")}
                                    tooltip={translate("ToolSetup.ticket_details_tooltip", "Show details of this ticket (history, data, etc)")}
                                    value={""}
                                ></SpanButton>

                                {this.state.show_modal}
                            </div>
                        }
                        {this.getUserStepText()}
                    </div>
                    <div>
                        <div style={{ paddingTop: "30px" }}>
                            {this.getUserStepsFields()}
                        </div>
                        {this.renderNextButtonIfNeeded()}
                        {this.renderSuccessfullSave()}
                        {this.renderModal()}
                    </div>

                </div>
                {this.renderBackButton()}
            </div>
        );
    }
}
export default UserStep;
