// @flow
import * as React from "react";
import { select, event } from "d3-selection";
import { drag } from "d3-drag";
import { connect } from "react-redux";
import ReactDOM from "react-dom";
import * as t from "../../../../lib/backend/reports.generated.types";
import { LinesOrdersLogic, ReduxFilters, UserActions, DraggableLogic} from "../reducers/BusinessLogic";
import { DraggableConsumer } from "../Draggable/DraggableContext";
import { DraggableContextHelper } from "../Draggable/DraggableContextHelper";
import { DRAGGING_ORDER, IS_INSIDE_ORDER } from "../Draggable/reducers";
import {
    SET_UNSCHEDULED_ORDER_UUID
} from "../reducers/linesOrders";
import { GanttChartSource, SKIP_STOCK_REPORT } from "../reducers/properties";
import {
    MOVE_ORDER_GANTT_ACTION_NAME,
    RESET_DRAGGED_ORDER,
    getConflictResolutionLineIndex
} from '../reducers/common';
import { getBackend as getBackend2 } from "../../../../lib/backend/Backend2";
import { ORDER_TYPE } from "../../../../lib/ManufacturingConsts.generated";
import { niceDateTime } from "../../../../lib/Util";

import type { OrderTypeEnum } from "../../../../lib/ManufacturingConsts.generated";
import type { SetLeftMenuSelectedView } from "../reducers/planningTable";
import type { DraggingOrder } from "../Draggable/reducers";
import type { DraggableProviderContext } from "../Draggable/DraggableContext";
import type { PropertiesState, GanttChartSourceTypes } from "../reducers/properties";
import type { ReduxState } from "../reducers/reducers";
import type { LineOrders } from "../reducers/linesOrders";
import type { MoveOrderGanttAction } from "../reducers/common";
import type { CursorProps } from "./Cursor";
import type { DraggingOrderAction, ResetDraggedOrderAction } from "../Draggable/reducers";
import PerformanceAnalysis from "../../../react/performance/PerformanceAnalysis";

type DraggableOrderProps = {
    order_uuid: string,
    order_external_id: string,
    line_uuid: string,
    order_index: number,
    line_index: number,
    current_shift_number: number,
    properties: PropertiesState,
    order_color: string,
    handleOrderClick: (e: Event) => any,
    reduxDispatch: (args: any | MoveOrderGanttAction | SetLeftMenuSelectedView) => any,
    is_visible: boolean,
    valid_lines: LineOrders[] | null,
    cursor: CursorProps,
    highlighted_shift_number: number,
    is_unscheduled: boolean,
    opacity: number,
    children: React.Node,
    is_rescheduled: boolean,
    last_shift_number: number | null,
    report_created_at: ?number,
    is_extra_line: boolean,
    extra_lines: t.IExtraLines[],
    is_ctrl_down: boolean,
    dragging_order: DraggingOrder,
    is_inside_order: boolean,
    snap_to_shift: boolean,
    x: number,
    y: number,
    width: number,
    height: number,
    is_parallel_line: boolean,
    earliest_start: number,
    earliest_end: number,
    source_type: GanttChartSourceTypes,
    is_parallel_line: boolean,
    reset_at: number | null,
    is_report_loading: boolean,
    contextDispatch: (args: DraggingOrderAction | ResetDraggedOrderAction) => void,
    value: DraggableProviderContext,
    sim_ignore_earliest_start: boolean,
    order_process_num: string,
    show_parent_chunk_relationship_uuids: string[] | null,
    order_type: OrderTypeEnum,
    is_readonly: boolean,
    is_filter_locked: boolean,
    is_linked_operation: boolean,
    performance_key: string
}

type OrderState = {
    is_shift_down: boolean
};

class DraggableOrder extends React.PureComponent<DraggableOrderProps, OrderState> {
    node = null;
    mouse_event_listener = null;
    gantt_chart_pane = null;
    unscheduled_pane = null;
    date_time_ref = null;

    constructor(props: DraggableOrderProps) {
        super(props);
        const state = {
            is_shift_down: false
        };
        this.state = state;
        this.node = React.createRef();
        this.date_time_ref = React.createRef();
    }

    componentDidMount() {
        this.load();
    }

    componentDidUpdate(prev_props: DraggableOrderProps, prev_state: OrderState) {
        if (prev_props.dragging_order !== this.props.dragging_order) {
            this.load();
        }

        if (prev_props.is_rescheduled === false &&
            this.props.is_rescheduled === true &&
            !this.props.is_report_loading &&
            ReduxFilters.last_click_location !== "gantt") {

                if (this.node && this.node.current) {
                setTimeout(() =>  this.node && this.node.current && this.node.current.scrollIntoView({ block: "center", inline: "center" }), 500);
            }
        }

    }

    load() {
        if (this.node && this.node.current && this.getIsPlanningTable()) {
            const node = this.node.current;
            const is_readonly = this.props.is_readonly;
            if (is_readonly || this.props.is_linked_operation) {
                return;
            }
            select(node).call(this.onDrag(this));
        }
    }

    getIsExtraLine = () => {
        if (!this.props.extra_lines || this.props.extra_lines.length === 0) {
            return false;
        }

        return this.props.extra_lines.some(l => l.line === this.props.line_uuid)
    }

    onClick = (e: MouseEvent) => {
        const currentTarget = e.currentTarget;
        if (currentTarget instanceof Element) {
            const advanced_order_menu = {
                order_uuid: this.props.order_uuid,
                click_target: currentTarget,
                event: e
            };

            ReduxFilters.last_click_location = "gantt";
            UserActions.onOrderClick({
                order_uuid: this.props.order_uuid,
                order_external_id: this.props.order_external_id,
                source_type: this.props.source_type,
                is_extra_line: this.getIsExtraLine(),
                is_ctrl_down: this.props.is_ctrl_down,
                advanced_order_menu,
                process_num: this.props.order_process_num,
                reduxDispatch: this.props.reduxDispatch,
                is_readonly: this.props.is_readonly,
                is_filter_locked: this.props.is_filter_locked,
                is_linked_operation: this.props.is_linked_operation
            });
        }

        e.stopPropagation();
    }

    hasChanged = () => {
        const dragging_order = this.props.dragging_order;

        if (!dragging_order) {
            return false;
        }
        if (dragging_order.new_line_uuid === undefined && dragging_order.new_order_index === undefined) {
            return false;
        }

        const earliest_start_changed = dragging_order.earliest_start && dragging_order.earliest_start !== this.props.earliest_start;
        if (dragging_order.new_order_index !== this.props.order_index ||
            dragging_order.new_line_uuid !== this.props.line_uuid ||
            earliest_start_changed) {
            return true;
        }
        const sim_ignore_earliest_start_changed = !dragging_order.earliest_start && this.props.earliest_start;
        if (sim_ignore_earliest_start_changed) {
            return true;
        }
        return false;
    }

    drag_started_ts = null;
    MOUSE_SENSITIVITY = 400;

    resetDraggedOrder = () => {
        this.drag_started_ts = null;
        DraggableLogic.changeMouseCursor(null);
        this.props.contextDispatch({ type: RESET_DRAGGED_ORDER, data: undefined });
    }

    insideUnscheduledOrder = (target: HTMLElement) => {
        const is_unscheduled = (target && (
            (target.getAttribute && target.getAttribute("id") === "data-is-unscheduled") ||
            (target.closest && target.closest("#data-is-unscheduled"))
        ));
        return is_unscheduled;
    }

    isInsideUnscheduledZone = (target: HTMLElement) => {
        return (target && (
            (target.getAttribute && target.getAttribute("id") === "gantt-chart-unscheduled") ||
            (target.closest && target.closest("#gantt-chart-unscheduled"))
        ))
    }

    isHighlighted = () => {
        return this.props.is_rescheduled;
    }

    onDrag = (self: any) => {
        function dragStarted() {
            window.dispatchEvent(new Event("tooltip-disable"));
        }

        async function dragEnded() {
            if (self.props.is_linked_operation) {
                return;
            }
            if (self.draggable_context_helper) {
                self.draggable_context_helper.removeListeners();
                self.draggable_context_helper = null;
            }
            this.gantt_chart_pane = null;
            this.unscheduled_pane = null;
            window.dispatchEvent(new Event("tooltip-enable"));
            const is_highlighted = self.isHighlighted();
            if (self.isInsideUnscheduledZone(event.sourceEvent.target)) {
                const line_uuid = self.props.line_uuid;
                const order_uuid = self.props.order_uuid;
                self.props.reduxDispatch({
                    type: SET_UNSCHEDULED_ORDER_UUID,
                    data: { line_uuid: line_uuid, order_uuid: order_uuid }
                });
                self.resetDraggedOrder();
                return;
            }

            if (!self.drag_started_ts) {
                self.drag_started_ts = (new Date()).getTime();
            }
            const current_ts = (new Date()).getTime();
            // some systems mouse sensitivity intreprets clicks as drags
            if ((current_ts - self.drag_started_ts) < self.MOUSE_SENSITIVITY) {
                self.resetDraggedOrder();
                return;
            }
            if (self.getIsExtraLine() || !self.props.is_inside_order || !is_highlighted) {
                self.resetDraggedOrder();
                return;
            }

            if (self.hasChanged()) {
                const dragging_order: DraggingOrder = self.props.dragging_order;
                let process_uuid = null;
                let process_num = null;
                const new_line_uuid = dragging_order.new_line_uuid;
                if (self.props.line_uuid !== new_line_uuid || self.props.is_unscheduled) {
                    const res = await getBackend2().manufacturing.getProcesses({
                        line_uuid: new_line_uuid,
                        material_external_id: self.props.material_external_id,
                        process_num: self.props.order_process_num,
                        only_bottleneck: false
                    });

                    if (res.processes.length > 0) {
                        process_uuid = res.processes[0].uuid;
                        process_num = res.processes[0].process_num;
                    }
                }

                const line_index = getConflictResolutionLineIndex(new_line_uuid, dragging_order.line_index);

                const data = {
                    new_line_uuid: new_line_uuid,
                    new_order_index: dragging_order.new_order_index,
                    earliest_start: dragging_order.earliest_start,
                    production_version: self.props.production_version,
                    original_line_uuid: self.props.line_uuid,
                    order_uuid: self.props.order_uuid,
                    is_unscheduled_order: self.props.is_unscheduled,
                    line_index,
                    process_uuid,
                    process_num,
                    override_earliest_start: dragging_order.override_earliest_start
                };
                if (self.props.properties.skip_stock !== true) {
                    self.props.reduxDispatch({ type: SKIP_STOCK_REPORT, data: true });
                }
                self.props.reduxDispatch({ type: MOVE_ORDER_GANTT_ACTION_NAME, data });
            }
            self.resetDraggedOrder();
            PerformanceAnalysis.clearPerformance();
        }

        function dragged() {
            if (self.props.is_linked_operation) {
                return;
            }
            if (!self.draggable_context_helper) {
                self.draggable_context_helper = new DraggableContextHelper(
                    self.props.order_uuid,
                    self.props.contextDispatch
                );
                self.draggable_context_helper.addListeners();
            }
            if (!this.gantt_chart_pane) {
                this.gantt_chart_pane = document.querySelector("#pane1");
            }
            if (!this.unscheduled_pane) {
                this.unscheduled_pane = document.querySelector("#gantt_unscheduled");
            }
            let is_highlighted = self.isHighlighted();

            if (!self.drag_started_ts) {
                self.drag_started_ts = (new Date()).getTime();
            }

            const current_ts = (new Date()).getTime();
            // some systems mouse sensitivity intreprets clicks as drags
            if ((current_ts - self.drag_started_ts) < self.MOUSE_SENSITIVITY) {
                return;
            }
            if (!is_highlighted || self.getIsExtraLine()) {
                return;
            }

            if (self.state.is_shift_down !== event.sourceEvent.shiftKey) {
                self.setState({ is_shift_down: event.sourceEvent.shiftKey });
            }

            if (this.gantt_chart_pane) {
                const mouse_inside_zone = this.gantt_chart_pane.matches(":hover");
                if (!mouse_inside_zone) {
                    const inside_unscheduled = this.unscheduled_pane.matches(":hover");
                    if (inside_unscheduled) {
                        DraggableLogic.changeMouseCursor("copy");
                    } else {
                        DraggableLogic.changeMouseCursor("not-allowed");
                    }
                    return self.props.contextDispatch({ type: IS_INSIDE_ORDER, data: false });
                }
            } else {
                if (event.sourceEvent.target.closest("#gantt-chart-unscheduled")) {
                    return self.props.contextDispatch({ type: IS_INSIDE_ORDER, data: false });
                }
            }

            const target = event.sourceEvent.target;
            if (target instanceof Element && target.dataset.id === "enabled-shifts") {
                target.dispatchEvent(new MouseEvent("drag", event.sourceEvent));
            }

            if (!self.props.dragging_order || self.props.dragging_order && self.props.dragging_order.order_uuid !== self.props.order_uuid) {
                self.props.contextDispatch({
                    type: DRAGGING_ORDER,
                    data: {
                        order_uuid: self.props.order_uuid,
                        line_uuid: self.props.line_uuid,
                        line_index: self.props.line_index,
                        order_index: self.props.order_index,
                        is_unscheduled: self.props.is_unscheduled,
                        order_uuid: self.props.order_uuid
                    }
                });
            }
        }

        return drag()
            .on("drag", dragged)
            .on("start", dragStarted)
            .on("end", dragEnded)
            .filter(() => this.props.is_rescheduled);
    }

    getTransform = () => {
        const dragging_order = this.props.dragging_order;
        if (dragging_order && dragging_order.order_uuid === this.props.order_uuid) {
            const current_gantt_chart = document.querySelector("#pane1");
            if (!current_gantt_chart) {
                return undefined;
            }
            let { translate_x, translate_y } = dragging_order;
            if (translate_x !== undefined && translate_y !== undefined) {
                translate_x = this.props.x - (translate_x);
                translate_y = this.props.y - (translate_y);
                return `translate(${-translate_x}, ${-translate_y})`;
            }
        }
        return undefined;
    }

    getTransformYAbs = () => {
        const dragging_order = this.props.dragging_order;
        if (dragging_order && dragging_order.order_uuid === this.props.order_uuid) {
            return Math.abs(this.props.y - dragging_order.translate_y);
        }
        return 0;
    }

    getOpacity = () => {
        if (this.props.show_parent_chunk_relationship_uuids) {
            if (this.props.show_parent_chunk_relationship_uuids.includes(this.props.order_uuid)) {
                return 1;
            } else {
                return 0.7;
            }
        } else if (this.props.order_type === ORDER_TYPE.chunk) {
            return 0.7;
        }

        if (this.getIsExtraLine()) {
            return 0.7;
        }
        return this.props.opacity;
    }

    getIsPlanningTable = () => {
        return (
            this.props.source_type === GanttChartSource.planning_table ||
            this.props.source_type === GanttChartSource.planning_table_parallel ||
            this.props.source_type === GanttChartSource.gantt_unscheduled
        );
    }

    renderDateTime = () => {
        const { dragging_order } = this.props;
        const precise_mode = this.props.snap_to_shift && this.state.is_shift_down ||
            !this.props.snap_to_shift && !this.state.is_shift_down;
        const base = 6;
        let width = 0;
        let height = 0;
        if (this.date_time_ref && this.date_time_ref.current) {
            const bbox = this.date_time_ref.current.getBBox();
            width = bbox.width;
            height = bbox.height;
        }

        const rect_width = width + 2 * base;
        const rect_height = height + base;
        return dragging_order && dragging_order.earliest_start && precise_mode && (
            <g visibility={this.date_time_ref && this.date_time_ref.current ? "visible" : "hidden"}>
                <rect
                    x={this.props.x}
                    y={this.props.y - rect_height - base}
                    width={rect_width}
                    height={rect_height}
                    rx={5}
                    fill="#000"
                    fillOpacity={0.5}
                />
                <text
                    ref={this.date_time_ref}
                    x={this.props.x + base}
                    y={this.props.y - rect_height + (height - base) / 2}
                    fill="#fff"
                    fontSize={12}
                    dominantBaseline="central"
                >
                    {niceDateTime(dragging_order.earliest_start)}
                </text>
            </g>
        );
    };

    renderOrder = (transform?: string) => {
        return <g
            key={transform}
            name={"order"}
            draggable={"true"}
            ref={this.node}
            order-uuid={this.props.order_uuid}
            line-index={this.props.line_index}
            order-index={this.props.order_index}
            order-color={this.props.order_color}
            line-uuid={this.props.line_uuid}
            data-last-shift={this.props.last_shift_number}
            earliest-end={this.props.earliest_end}
            earliest_start={this.props.earliest_start}
            is-parallel-line={this.props.is_parallel_line.toString()}
            opacity={this.getOpacity()}
            onClick={this.onClick}
            is-extra-line={this.getIsExtraLine().toString()}
            data-is-unscheduled={this.props.is_unscheduled}
            transform={transform}
            performance-key={this.props.performance_key}
            is_readonly={this.props.is_readonly.toString()}
        >
            {this.renderDateTime()}
            {this.props.children}
        </g>
    }

    render() {
        const is_readonly = this.props.is_readonly;

        const transform = this.getTransform();
        if (transform && !is_readonly) {
            const el = document.querySelector("g#detached-draggable-order");
            if (el) {
                const current_gantt_chart = document.querySelector("#pane1");
                if (current_gantt_chart) {
                    const portal = ReactDOM.createPortal(this.renderOrder(transform), el);

                    const gantt_chart_wrapper = document.querySelector("#pane1");
                    if (gantt_chart_wrapper) {
                        const { top, left, width, height } = gantt_chart_wrapper.getBoundingClientRect();
                        el.style.top = top + "px";
                        el.style.left = left + "px";
                        el.style.width = width + "px";
                        el.style.height = height + "px";
                    }
                    return portal;
                }
            }
        }
        return this.renderOrder();
    }
}

const DraggableOrderContext = (props) => {
    if (props.is_extra_line) {
        return <DraggableOrder
            key={props.order_uuid}
            {...props}
        >
            {props.children}
        </DraggableOrder>
    }

    return <DraggableConsumer>
        {(value: ?DraggableProviderContext) => {
            if (!value) {
                return null;
            }

            let dragging_state = value.state.dragging_order;
            let dragging_order = dragging_state && props.order_uuid === dragging_state.order_uuid ? dragging_state : null;
            return <DraggableOrder
                key={props.order_uuid}
                contextDispatch={value.dispatch}
                dragging_order={dragging_order}
                is_inside_order={value.state.is_inside_order}
                snap_to_shift={value.state.snap_to_shift}
                {...props}
            >
                {props.children}
            </DraggableOrder>
        }}
    </DraggableConsumer>
}

export default connect(
    (state: ReduxState, own_props: DraggableOrderProps) => {
        const properties = state.gantt_chart_properties;
        const planning_table = state.gantt_chart_planning_table;
        const filters = state.gantt_chart_filters;
        const report_data = state.gantt_chart_report.report_data;
        const extra_lines = state.gantt_chart_report.extra_lines;
        const dragged_order = state.gantt_chart_dragged_order;
        const lines_orders = state.gantt_chart_lines_orders.lines_orders;

        let is_readonly = false;
        const line_orders = lines_orders.find(l => own_props.line_uuid);
        if (line_orders) {
            const order = line_orders.orders.find(o => o.uuid === own_props.order_uuid);
            if (order) {
                is_readonly = LinesOrdersLogic.isOrderReadOnly(line_orders, order);
            }
        }

        let report_created_at = 0;

        if (report_data) {
            report_created_at = report_data.created_at;
        }

        let cursor = null;

        if (planning_table.cursor && planning_table.cursor.order_uuid === own_props.order_uuid &&
            planning_table.cursor.is_unscheduled === own_props.is_unscheduled) {
            cursor = planning_table.cursor;
        }

        const is_filter_locked = filters.is_filter_locked;

        return {
            properties,
            cursor,
            valid_lines: planning_table.show_valid_lines.valid_lines,
            report_created_at,
            extra_lines,
            is_rescheduled: planning_table.last_clicked_order_uuid === own_props.order_uuid,
            is_ctrl_down: planning_table.is_ctrl_down,
            reset_at: dragged_order.reset_at,
            is_report_loading: state.gantt_chart_report.report_loading,
            show_parent_chunk_relationship_uuids: planning_table.show_parent_chunk_relationship_uuids,
            is_readonly,
            is_filter_locked
        };
    },
    (dispatch) => ({ reduxDispatch: dispatch })
)(DraggableOrderContext);
