// @flow
// $FlowFixMe
import React, {useState, useEffect} from "react";

type Props = {
    title: string,
    tooltip?: string,
    value: any,
    onClick?: (value: any) => void,
    hide_title?: string,
    is_active: boolean,
    toggle_active_status?: boolean
}

const SpanButton = (props: Props) => {
    const [is_active, setActive] = useState(false);
    const onClick = (e: Event) => {
        if (props.toggle_active_status !== false){
            setActive(!is_active);
        }
        const on_click = props.onClick;
        if (on_click != undefined) {
            on_click(props.value);
        }
    }

    useEffect(() => {
        setActive(props.is_active);
    }, [props.is_active])
    
    const className = `span-button ` + (is_active ? "active" : "");
    let title = props.title;

    if (props.hide_title && props.is_active === false) {
        title = props.hide_title;
    }

    return <span className={className} onClick={onClick} title={props.tooltip}>
        {title}
    </span>
}

export default SpanButton;
