// @flow
import React from "react";
import SpanButton from "./SpanButton";

export type ActionButtonProps = {
    title: string,
    onClick?: (e?: Event) => Promise<void> | void
}

function createActionButton(props: ActionButtonProps, index: number) {
    const on_click = (e: Event) => {
        if (props.onClick) {
            props.onClick(e);
        }
    };

    return <SpanButton
        is_active={false}
        title={props.title}
        toggle_active_status={false}
        onClick={on_click}
        value={props.title}
        key={"actb" + index}
    />;
}


type Props = {
    buttons: ActionButtonProps[]
}

const ActionButtons = (props: Props) => {
    return <div className="action-button-bar">
        {props.buttons.map((x, i) => createActionButton(x, i))}
    </div>;
}

export default ActionButtons;
