// @flow

import * as React from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { FormattedMessage } from "react-intl";

import { getEventSparklineData, renderEventRow } from "./EventDetails";
import { getBackend as getBackend2 } from "../lib/backend/Backend2";
import * as t from "../lib/backend/datasources.generated.types";
import * as ta from "../lib/backend/alerts.generated.types";
import { toISODateTimeString } from "../lib/Util";

type Props = {
    events: Array<ta.IEventData>
};

type State = {
    datasources: { [string]: t.IDatasourceExRec },
    sparkline_data: { [string]: any }
};

// Prepares a list of events to be displayed
class EventTable extends React.Component<Props, State> {

    constructor() {
        super();
        this.state = {
            datasources: {},
            sparkline_data: {}
        };
    }

    handleRowClick(uuid: string) {
        window.open('/events/' + uuid, "_self")
    }

    retrieveEventSparklines() {
        // retrieve visualization data for each event
        for (let event of this.props.events) {
            getEventSparklineData(event, (sparkline) => {
                this.setState(prev_state => {
                    if (sparkline) {
                        prev_state.sparkline_data[event.uuid] = sparkline;
                    }
                    return prev_state;
                });
            });
        }
    }

    componentDidMount() {
        this.retrieveEventSparklines();
    }

    async componentDidUpdate(prevProps: Props) {
        try {
            if (this.props.events.length !== prevProps.events.length) {
                // retrieve datasource details for each event
                const ds_uuids = this.props.events.map(x => x.analysis ? x.analysis.tags.datasource_uuid : "");
                const unique = [...new Set(ds_uuids.filter(x => x.length > 0))];
                for (let datasource_uuid of unique) {
                    const res = await getBackend2().datasources.getSingleDatasource({ id: datasource_uuid });
                    this.setState(prev_state => {
                        const data = res.data;
                        if (data) {
                            prev_state.datasources[datasource_uuid] = data;
                        }
                        return prev_state;
                    });
                }
                this.retrieveEventSparklines();
            }
        } catch (e) {
            console.log(e);
        }
    }

    render() {
        let root = this;
        // prepare list of events
        let rows = [];
        if (this.props.events) {
            const datasources = this.state.datasources;
            const sparkline_data = this.state.sparkline_data;
            rows = this.props.events.map(function (event, i) {
                const event_data = renderEventRow(event, sparkline_data[event.uuid]);
                const event_datasource = event.datasource || datasources[event.datasource_uuid || "UNKNOWN"];
                return (
                    <tr key={i} onClick={() => { root.handleRowClick(event.uuid) }}>
                        <td>
                            <p className="data_source_text">
                                <Moment fromNow>{new Date(event.ts)}</Moment><br />
                                <span className="light">{toISODateTimeString(new Date(event.ts))}</span>
                            </p>
                        </td>
                        <td>
                            <p className="data_source_text">
                                {event_datasource && event_datasource.title}
                            </p>
                            {event_data.extra_data}
                        </td>
                        <td>
                            {event_data.sparkline}
                            <Link to={"/events/" + event.uuid} className="read_link">Read more</Link>
                        </td>
                    </tr>
                );
            });
        }

        if (rows.length > 0) {
            return (
                <div className="table_content width-full">
                    <table id="datatable-responsive" className="table table-timeline dt-responsive dataTable no-footer dtr-inline" cellSpacing="0" width="100%">
                        <thead>
                            <tr>
                                <th>
                                    <FormattedMessage id="common.time" defaultMessage="Time" />
                                </th>
                                <th>
                                    <FormattedMessage id="common.description" defaultMessage="Description" />
                                </th>
                                <th className="width25p">
                                    <FormattedMessage id="common.preview" defaultMessage="Preview" />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows}
                        </tbody>
                    </table>
                </div>
            );
        } else {
            return <div className="px-5">No events</div>;
        }
    }
}

export default EventTable;
