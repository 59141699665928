// @flow

import * as React from "react";
import * as ta from "../lib/backend/alerts.generated.types";
import { renderEventListRowRich } from "./EventDetails";

type Props = {
    events: Array<ta.IEventData>
};

type State = { };

// Prepares a list of events to be displayed on event page as a scrollable side bar on the left
class EventListStandalone extends React.Component<Props, State> {

    handleRowClick(uuid: string) {
        window.open('/events/' + uuid, "_self")
    }

    // prepare list of events
    render () {
        // render list of events to HTML
        const eventList = this.props.events.map((event, i) => {
            // check if event is selected
            let list_data = renderEventListRowRich(event, i);
            return (
                <li className="nav-item" key={i}>
                    <a ref={"a" + i} className="nav-link" data-toggle="tab" onClick={() => {this.handleRowClick(event.uuid)}}>
                        {list_data.extra_data}
                    </a>
                </li>
            );
        });
        // pack inside list group and return
        return (
            <div className="white_box alerts-box">
                {eventList.length ? (
                    <ul className="nav">
                        {eventList}
                    </ul>
                ) : (
                    <div>No events</div>
                )}
            </div>
        );
    }
}

export default EventListStandalone;
