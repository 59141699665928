// @flow

// main imports
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import Authorization from "../Authorization";

// models
import { toSloDateTimeString, renderTag, stringCompare } from "../../lib/Util";
import * as t from "../../lib/backend/manufacturing2.generated.types";
import * as Auth from "../../lib/Auth";

// defining types
type Props = {
    plantFilter: string,
    selected: t.IStockLocation | null,
    materials: t.IStockLocationMaterial[]
}

/**
 * Plants details component.
 */
class StockLocationsDetails extends Component<Props> {

    /**
     * Render component.
     */
    render() {
        if (this.props.selected === null) {
            return null;
        }

        // prepare materials
        let materials = this.props.materials
            .sort((a,b) => {
                const s_cmp = stringCompare(a.material_title, b.material_title)
                const stock_a = a.quantity;
                const stock_b = b.quantity;
                if (stock_a !== 0 && stock_b !== 0 || stock_a === 0 && stock_b === 0) {
                    return s_cmp
                }
                if (stock_a === 0 && stock_b !== 0) {
                    return 1;
                }
                if (stock_a !== 0 && stock_b === 0) {
                    return -1;
                }
                return 0;
            });

        let tags = "/";

        if (this.props.selected && this.props.selected.tags !== undefined) {
            const selected_tags = (this.props.selected && this.props.selected.tags !== undefined) ?
                this.props.selected.tags : { };
            // generate tags
            tags = Object.keys(selected_tags)
                .sort((a, b) => a.localeCompare(b))
                .map((el, i) => renderTag(i, el, selected_tags[el]));
        }

        const can_edit = Auth.isInRole(Auth.ROLE_ADMIN) || Auth.isInRole(Auth.ROLE_POWER_USER) || Auth.isInRole(Auth.ROLE_DEMO_USER);

        return (
            <div className="tab-content">
                <div className="tab-pane active tab-pane-text">
                    <div className="d-flex">
                        {can_edit && <div className="button-list order-last ml-auto">
                            <Authorization.Link
                                permission={Auth.PERMISSION_NAMES.StockLocationEdit}
                                className="btn btn-outline-primary"
                                // $FlowFixMe - todo before return check if selected is null
                                to={`/digital-twin/resources/stock_location/edit/${this.props.selected.uuid}`}
                            >
                                <FormattedMessage id="common.edit" defaultMessage="Edit" />
                            </Authorization.Link>
                        </div>}
                        <div className="order-first">
                            <h5 className="pane-title">
                                <em><FormattedMessage id="common.stock_location" defaultMessage="Stock location" /></em>
                                {/* $FlowFixMe -- before return we are checking if selected is null */ }
                                {this.props.selected.title}
                            </h5>
                            <dl className="property-list">
                                <div>
                                    <dt><FormattedMessage id="common.external_id" defaultMessage="External ID" /></dt>
                                    {/* $FlowFixMe -- before return we are checking if selected is null */ }
                                    <dd><div>{this.props.selected.external_id}</div></dd>
                                </div>
                                <div>
                                    <dt><FormattedMessage id="common.tags" defaultMessage="Tags" /></dt>
                                    <dd>{tags}</dd>
                                </div>
                                <div>
                                    <dt className="light"><FormattedMessage id="common.uuid" defaultMessage="UUID" /></dt>
                                    {/* $FlowFixMe -- before return we are checking if selected is null */ }
                                    <dd className="light"><div>{this.props.selected.uuid}</div></dd>
                                </div>
                            </dl>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-header text-uppercase bg-transparent">
                            <FormattedMessage id="common.materials" defaultMessage="Materials" />
                        </div>
                        <div className="space15"></div>
                        <div>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th><FormattedMessage id="common.material" defaultMessage="Material" /></th>
                                        <th><FormattedMessage id="common.external_id" defaultMessage="External ID" /></th>
                                        <th style={{textAlign: "right"}}><FormattedMessage id="common.quantity" defaultMessage="Qty" /></th>
                                        <th style={{textAlign: "right"}}><FormattedMessage id="common.last_update" defaultMessage="Last update" /></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {materials.map((item) => {
                                        return (
                                            <tr key={item.material_uuid}>
                                                <td></td>
                                                <td>{item.material_title}</td>
                                                <td>{item.material_external_id}</td>
                                                <td style={{textAlign: "right"}}>{item.quantity}</td>
                                                <td style={{textAlign: "right"}}>{toSloDateTimeString(new Date(item.ts_update))}</td>
                                                <td></td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div className="space15"></div>
                    </div>

                </div>
            </div>
        )
    }
}

export default StockLocationsDetails;
