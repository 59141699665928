// @flow

import * as React from "react";
import { FormattedMessage } from "react-intl";
import { translate } from "./IntlProviderWrapper";
import moment, { type Moment } from "moment";


export type CalendarType = "date" | "time" | "date-time";
export type TimeUnit = "hour" | "minute" | "second";

type Props = {
    date?: Date | null,
    minDate?: Date | null,
    maxDate?: Date | null,
    onChange?: ((Date) => void) | null,
    onAccept?: ((Date) => void) | null,
    onSetNow?: ((Date) => void) | null,
    showMonthControls: boolean,
    showYearControls: boolean,
    showNowButton: boolean,
    showOKButton: boolean,
    timeUnits: TimeUnit[],
    type: CalendarType,
    onClear?: (e: Event) => any,
    showOnClearButton?: boolean,
    closeOnSelect?: boolean
}

type State = {
    date: Date | null,
    focusDate: Date,
    showTime: boolean
}

/** Calendar instance counter */
let lastId: number = 0;

class Calendar extends React.Component<Props, State> {
    static defaultProps: Props = {
        showMonthControls: true,
        showYearControls: true,
        showNowButton: true,
        showOKButton: true,
        type: "date-time",
        timeUnits: ["hour", "minute", "second"]
    };

    /** Reference to focused buttons */
    focusRefs: {
        "date": HTMLButtonElement | null,
        "hour": HTMLButtonElement | null,
        "minute": HTMLButtonElement | null,
        "second": HTMLButtonElement | null,
    }

    updateTimeView: boolean;

    /** Name of the date/time unit that changed */
    dirtyUnit: string | null;

    /** Instance ID */
    id: number;


    constructor(props: Props, state: State) {
        super(props);

        const { date } = props;

        this.state = {
            date: date || null,
            focusDate: date || this.toMinMax(new Date()),
            showTime: this.props.type === "time",
        };
        this.id = ++lastId;
        this.updateTimeView = false;
        this.dirtyUnit = null;
        this.focusRefs = {
            "date": null,
            "hour": null,
            "minute": null,
            "second": null,
        };
    }

    componentDidUpdate() {
        const ref = this.dirtyUnit && this.focusRefs[this.dirtyUnit];

        if (ref) {
            ref.focus();
            this.dirtyUnit = null;
        }

        if (this.updateTimeView) {
            for (let unit of this.props.timeUnits) {
                const timeRef = this.focusRefs[unit];
                if (timeRef) {
                    timeRef.scrollIntoView();
                }
            }
            this.updateTimeView = false;
        }
    }

    setDate(date: Date, callback?: () => void) {
        this.setState({
            date,
            focusDate: date
        }, callback);

        if (this.state.date && moment(date).isSame(moment(this.state.date))) {
            return;
        }

        this.updateTimeView = true;
        this.handleDateChange(date);
    }

    setFocusDate(focusDate: Date, focus: boolean = true) {
        if (focus) {
            let dirtyUnit = null;

            if (this.state.showTime) {
                // Check if and what time unit changed.
                const focusDateObj = moment(focusDate).toObject();
                const currentFocusDateObj = moment(this.state.focusDate).toObject();

                for (let unit of this.props.timeUnits) {
                    const key = `${unit}s`;

                    if (focusDateObj[key] !== currentFocusDateObj[key]) {
                        dirtyUnit = unit;
                        break;
                    }
                }
            } else {
                dirtyUnit = "date";
            }

            if (dirtyUnit) {
                this.dirtyUnit = dirtyUnit;
            }
        }

        this.setState({
            focusDate
        });
    }

    setPrevious(unit: string, focus: boolean = true) {
        let previous = moment(this.state.focusDate);
        let subtract = true;

        switch (unit) {
            case "hour":
                if (previous.hours() === 0) {
                    previous.hours(23);
                    subtract = false;
                }
                break;
            case "minute":
                if (previous.minutes() === 0) {
                    previous.minutes(59);
                    subtract = false;
                }
                break;
            case "second":
                if (previous.seconds() === 0) {
                    previous.seconds(59);
                    subtract = false;
                }
                break;
            default:
                break;
        }

        if (subtract) {
            previous.subtract(1, `${unit}s`);
        }

        this.setFocusDate(this.toMinMax(previous.toDate()), focus);
    }

    setNext(unit: string, focus: boolean = true) {
        let next = moment(this.state.focusDate);
        let add = true;

        switch (unit) {
            case "hour":
                if (next.hours() === 23) {
                    next.hours(0);
                    add = false;
                }
                break;
            case "minute":
                if (next.minutes() === 59) {
                    next.minutes(0);
                    add = false;
                }
                break;
            case "second":
                if (next.seconds() === 59) {
                    next.seconds(0);
                    add = false;
                }
                break;
            default:
                break;
        }

        if (add) {
            next.add(1, `${unit}s`);
        }

        this.setFocusDate(this.toMinMax(next.toDate()), focus);
    }

    setStart(unit: string, focus: boolean = true) {
        let start = moment(this.state.focusDate);

        switch (unit) {
            case "month":
                start.month(0);
                break;
            case "date":
                start.date(1);
                break;
            case "hour":
                start.hours(0);
                break;
            case "minute":
                start.minutes(0);
                break;
            case "second":
                start.seconds(0);
                break;
            default:
                break;
        }

        this.setFocusDate(this.toMinMax(start.toDate()), focus);
    }

    setEnd(unit: string, focus: boolean = true) {
        let end = moment(this.state.focusDate);

        switch (unit) {
            case "month":
                end.month(11);
                break;
            case "date":
                end.date(end.daysInMonth());
                break;
            case "hour":
                end.hours(23);
                break;
            case "minute":
                end.minutes(59);
                break;
            case "second":
                end.seconds(59);
                break;
            default:
                break;
        }

        this.setFocusDate(this.toMinMax(end.toDate()), focus);
    }

    generateDateGrid() {
        const { focusDate } = this.state;
        const daysInLastMonth = moment(focusDate).subtract(1, "months").daysInMonth();
        const daysInMonth = moment(focusDate).daysInMonth();
        const startWeekday = (moment(focusDate).startOf("month").day() + 6) % 7;
        const weekLength = 7;
        const days = [
            ...Array.from(
                { length: startWeekday },
                (_, i) => moment(focusDate).subtract(1, "months").date(daysInLastMonth - startWeekday + i + 1)
            ),
            ...Array.from(
                { length: daysInMonth },
                (_, i) => moment(focusDate).date(i + 1)
            ),
            ...Array.from(
                { length: (14) },
                (_, i) => moment(focusDate).add(1, "months").date(i + 1)
            )
        ].slice(0, 42);

        let daysGrid: Moment[] = [];

        for (let i = 0; i < days.length; i += weekLength) {
            daysGrid.push(days.slice(i, i + weekLength));
        }

        return daysGrid;
    }

    generateTimeColumns() {
        const { focusDate } = this.state;
        const timeColumns = {
            hour: [...Array.from({ length: 24 }, (_, i) => moment(focusDate).hour(i))],
            minute: [...Array.from({ length: 60 }, (_, i) => moment(focusDate).minute(i))],
            second: [...Array.from({ length: 60 }, (_, i) => moment(focusDate).second(i))],
        };

        return this.props.timeUnits.map(unit => timeColumns[unit]);
    }

    toggleTime() {
        this.setState((state) => {
            state.showTime = !state.showTime;

            if (state.showTime) {
                this.updateTimeView = true;
            }

            return state;
        });
    }

    toMinMax(date: Date) {
        let newDate = date;

        if (this.props.minDate && moment(date).isBefore(this.props.minDate)) {
            newDate = this.props.minDate;
        }

        if (this.props.maxDate && moment(date).isAfter(this.props.maxDate)) {
            newDate = this.props.maxDate;
        }

        return newDate;
    }

    handleDateKeyDown(event: SyntheticKeyboardEvent<HTMLElement>) {
        const keyCode = event.keyCode;
        const shift = event.shiftKey;

        switch (keyCode) {
            case 33: // Page Up
                shift ? this.setPrevious("year") : this.setPrevious("month");
                break;

            case 34: // Page Down
                shift ? this.setNext("year") : this.setNext("month");
                break;

            case 35: // End
                shift ? this.setEnd("month") : this.setEnd("date");
                break;

            case 36: // Home
                shift ? this.setStart("month") : this.setStart("date");
                break;

            case 37: // Left
                this.setPrevious("day");
                break;

            case 38: // Up
                this.setPrevious("week");
                break;

            case 39: // Right
                this.setNext("day");
                break;

            case 40: // Down
                this.setNext("week");
                break;

            default:
                break;
        }
    }

    handleTimeKeyDown(event: SyntheticKeyboardEvent<HTMLElement>) {
        if (!(event.target instanceof HTMLElement)) {
            return;
        }

        const { timeUnits } = this.props;
        const { focusDate } = this.state;
        const keyCode = event.keyCode;
        const target = event.target;
        const col = Number(target.getAttribute("data-col"));

        switch (keyCode) {
            case 13: // Enter
            case 32: // Space
                this.setDate(focusDate);
                event.preventDefault();
                break;

            case 35: // End
                this.setEnd(timeUnits[col]);
                event.preventDefault();
                break;

            case 36: // Home
                this.setStart(timeUnits[col]);
                event.preventDefault();
                break;

            case 37: // Left
                const previousRef = this.focusRefs[timeUnits[(col + 2) % timeUnits.length]];
                if (previousRef) {
                    previousRef.focus();
                }

                event.preventDefault();
                break;

            case 38: // Up
                this.setPrevious(timeUnits[col]);
                event.preventDefault();
                break;

            case 39: // Right
                const nextRef = this.focusRefs[timeUnits[(col + 1) % timeUnits.length]];
                if (nextRef) {
                    nextRef.focus();
                }

                event.preventDefault();
                break;

            case 40: // Down
                this.setNext(timeUnits[col]);
                event.preventDefault();
                break;

            default:
                break;
        }
    }

    handleDateChange(date: Date) {
        if (this.props.closeOnSelect && this.props.onAccept) {
            this.props.onAccept(date);
        } else if (this.props.onChange) {
            this.props.onChange(date);
        }
    }

    handleSetNow() {
        this.setDate(new Date(), () => {
            if (this.props.onSetNow && this.state.date) {
                this.props.onSetNow(this.state.date);
            }
        });
    }

    handleAccept() {
        if (this.props.onAccept && this.state.date) {
            this.props.onAccept(this.state.date);
        }
    }

    showOKButtonFunc = () => {
        if (this.props.closeOnSelect) {
            return false;
        }
        return this.props.onAccept;
    }

    render() {
        const { timeUnits } = this.props;
        const {
            date: selectedDate,
            focusDate
        } = this.state;

        return (
            <div className="calendar">
                {this.props.type === "date-time" && (
                    <div className="header">
                        <button
                            className="btn btn-outline-primary w-100"
                            type="button"
                            onClick={() => this.toggleTime()}
                        >
                            <span>{this.state.showTime ? (
                                <React.Fragment>
                                    <FormattedMessage id="common.date" defaultMessage="Date" />: {<time>
                                        {translate(`common.month${moment(focusDate).month()}`)} {moment(focusDate).date()}, {moment(focusDate).year()}
                                    </time>}
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <FormattedMessage id="common.time" defaultMessage="Time" />: <time>{moment(selectedDate).format("HH:mm:ss")}</time>
                                </React.Fragment>
                            )}</span>
                        </button>
                    </div>
                )}
                <div className={`body${this.state.showTime ? ' show-time' : ''}`}>
                    <div className="date">
                        <div className="date-header">
                            <div className="date-controls">
                                {this.props.showYearControls && (
                                    <button
                                        className="prev-year btn btn-primary mr-1"
                                        type="button"
                                        onClick={() => this.setPrevious("year", true)}
                                        disabled={this.props.minDate && moment(this.state.focusDate).subtract(1, "years").endOf("year").isBefore(this.props.minDate)}
                                        title={translate("Calendar.previous_year", "Previous year")}
                                    >
                                        <span className="sr-only">{translate("Calendar.previous_year", "Previous year")}</span>
                                    </button>
                                )}
                                {this.props.showMonthControls && (
                                    <button
                                        className="prev-month btn btn-primary"
                                        type="button"
                                        onClick={() => this.setPrevious("month", true)}
                                        disabled={this.props.minDate && moment(this.state.focusDate).subtract(1, "months").endOf("month").isBefore(this.props.minDate)}
                                        title={translate("Calendar.previous_month", "Previous month")}
                                    >
                                        <span className="sr-only">{translate("Calendar.previous_month", "Previous month")}</span>
                                    </button>
                                )}
                            </div>
                            <div className="date-title" role="heading">
                                <strong>
                                    {this.props.showMonthControls && this.props.showYearControls
                                        ? translate(`common.month${moment(focusDate).month()}`).slice(0, 3)
                                        : translate(`common.month${moment(focusDate).month()}`)} {moment(focusDate).year()}
                                </strong>
                            </div>
                            <div className="date-controls">
                                {this.props.showMonthControls && (
                                    <button
                                        className="next-month btn btn-primary"
                                        type="button"
                                        onClick={() => this.setNext("month", true)}
                                        disabled={this.props.maxDate && moment(this.state.focusDate).add(1, "months").startOf("month").isAfter(this.props.maxDate)}
                                        title={translate("Calendar.next_month", "Next month")}
                                    >
                                        <span className="sr-only">{translate("Calendar.next_month", "Next month")}</span>
                                    </button>
                                )}
                                {this.props.showYearControls && (
                                    <button
                                        className="next-year btn btn-primary ml-1"
                                        type="button"
                                        onClick={() => this.setNext("year", true)}
                                        disabled={this.props.maxDate && moment(this.state.focusDate).add(1, "years").startOf("year").isAfter(this.props.maxDate)}
                                        title={translate("Calendar.next_year", "Next year")}
                                    >
                                        <span className="sr-only">{translate("Calendar.next_year", "Next year")}</span>
                                    </button>
                                )}
                            </div>
                        </div>
                        <table
                            className="date-grid"
                            id="grid"
                            onKeyDown={(e) => this.handleDateKeyDown(e)}
                            role="grid"
                            aria-label={translate("Calendar.month", "Month")}
                        >
                            <thead>
                                <tr>
                                    <th scope="col">
                                        <abbr title={translate("common.weekday0", "Monday")}>
                                            {translate("common.weekday0", "Monday").slice(0, 2)}
                                        </abbr>
                                    </th>
                                    <th scope="col">
                                        <abbr title={translate("common.weekday1", "Tuesday")}>
                                            {translate("common.weekday1", "Tuesday").slice(0, 2)}
                                        </abbr>
                                    </th>
                                    <th scope="col">
                                        <abbr title={translate("common.weekday2", "Wednesday")}>
                                            {translate("common.weekday2", "Wednesday").slice(0, 2)}
                                        </abbr>
                                    </th>
                                    <th scope="col">
                                        <abbr title={translate("common.weekday3", "Thursday")}>
                                            {translate("common.weekday3", "Thursday").slice(0, 2)}
                                        </abbr>
                                    </th>
                                    <th scope="col">
                                        <abbr title={translate("common.weekday4", "Friday")}>
                                            {translate("common.weekday4", "Friday").slice(0, 2)}
                                        </abbr>
                                    </th>
                                    <th scope="col">
                                        <abbr title={translate("common.weekday5", "Saturday")}>
                                            {translate("common.weekday5", "Saturday").slice(0, 2)}
                                        </abbr>
                                    </th>
                                    <th scope="col">
                                        <abbr title={translate("common.weekday6", "Sunday")}>
                                            {translate("common.weekday6", "Sunday").slice(0, 2)}
                                        </abbr>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.generateDateGrid().map((week, i) => (
                                    <tr className="week" key={`week-${i}`}>
                                        {week.map((date, j) => {
                                            const dateStart = moment(date).startOf("date");
                                            const focusStart = moment(focusDate).startOf("date");
                                            const selectedStart = selectedDate && moment(selectedDate).startOf("date");
                                            const focus = focusStart.isSame(dateStart);
                                            const focusClass = focus ? " focus" : "";
                                            const selected = selectedStart && selectedStart.isSame(dateStart);
                                            const selectedClass = selected ? " selected" : "";
                                            const tabIndex = focus ? 0 : -1;
                                            const disabled = (this.props.minDate && dateStart.isBefore(moment(this.props.minDate).startOf("date")))
                                                || (this.props.maxDate && dateStart.isAfter(moment(this.props.maxDate).startOf("date")));
                                            const selectedMoment = selectedDate && moment(selectedDate);
                                            let newDate = moment({
                                                years: date.year(),
                                                months: date.month(),
                                                date: date.date(),
                                                hours: (selectedMoment || date).hour(),
                                                minutes: (selectedMoment || date).minute(),
                                                seconds: (selectedMoment || date).second(),
                                                milliseconds: (selectedMoment || date).millisecond()
                                            });

                                            if (!disabled) {
                                                // Limit new date to [minDate, maxDate].
                                                newDate = moment(this.toMinMax(newDate.toDate()));
                                            }

                                            const is_diff_month = newDate.month() !== focusStart.month();

                                            return (
                                                <td className="day-cell" role="gridcell" key={`day-cell-${j}`}>
                                                    <button
                                                        className={`btn unit-button${focusClass}${selectedClass} ${is_diff_month ? "diff-month" : ""}`}
                                                        type="button"
                                                        ref={button => focus && (this.focusRefs["date"] = button)}
                                                        onClick={() => this.setDate(newDate.toDate())}
                                                        tabIndex={tabIndex}
                                                        disabled={disabled}
                                                    >
                                                        {newDate.date()}
                                                    </button>
                                                </td>
                                            );
                                        })}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    {this.state.showTime && (
                        <div className="time">
                            <div className="time-columns" onKeyDown={(e) => {this.handleTimeKeyDown(e)}}>
                                {this.generateTimeColumns().map((col, i) => (
                                    <div className="time-column" key={`time-unit-${i}`}>
                                        <div id={`calendar-${timeUnits[i]}-col-${this.id}`}>{translate(`Calendar.${timeUnits[i]}s`)}</div>
                                        <ul aria-labelledby={`calendar-${timeUnits[i]}-col-${this.id}`}>
                                            {col.map((item, j) => {
                                                const itemObj = item.toObject();
                                                const itemValue = itemObj[`${timeUnits[i]}s`];
                                                const focusValue = moment(focusDate).toObject()[`${timeUnits[i]}s`];
                                                const focus = focusValue === itemValue;
                                                const focusClass = focus ? " focus" : "";
                                                const selectedValue = moment(selectedDate).toObject()[`${timeUnits[i]}s`];
                                                const selected = selectedValue === itemValue;
                                                const selectedClass = selected ? " selected" : "";
                                                const tabIndex = focus ? 0 : -1;
                                                const disabled = (this.props.minDate && item.isBefore(moment(this.props.minDate).startOf(timeUnits[i])))
                                                    || (this.props.maxDate && item.isAfter(moment(this.props.maxDate).endOf(timeUnits[i])));

                                                const selectedMoment = selectedDate && moment(selectedDate);
                                                let newItem = moment({
                                                    years: (selectedMoment || item).year(),
                                                    months: (selectedMoment || item).month(),
                                                    date: (selectedMoment || item).date(),
                                                    hours: item.hour(),
                                                    minutes: item.minute(),
                                                    seconds: item.second(),
                                                    milliseconds: item.millisecond()
                                                });

                                                if (!disabled) {
                                                    // Limit date to [minDate, maxDate].
                                                    newItem = moment(this.toMinMax(newItem.toDate()));
                                                }

                                                return (
                                                    <li key={`time-item-${j}`}>
                                                        <button
                                                            className={`btn unit-button${focusClass}${selectedClass}`}
                                                            type="button"
                                                            ref={button => focus && (this.focusRefs[timeUnits[i]] = button)}
                                                            onClick={() => this.setDate(newItem.toDate())}
                                                            tabIndex={tabIndex}
                                                            disabled={disabled}
                                                            data-col={i}
                                                        >
                                                            {`0${j}`.slice(-2)}
                                                        </button>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
                {(this.props.onSetNow || this.props.onAccept) && (
                    <div className="footer">
                        <div>
                            {this.props.onSetNow && (
                                <button
                                    className="btn btn-outline-primary"
                                    type="button"
                                    onClick={() => {this.handleSetNow()}}
                                >
                                    <FormattedMessage id="Calendar.now" defaultMessage="Now" />
                                </button>
                            )}
                        </div>
                        <div>
                            {this.props.showOnClearButton && (
                                <button
                                    className="btn btn-primary ml-auto"
                                    style={{marginRight: "5px"}}
                                    type="button"
                                    onClick={this.props.onClear}
                                >
                                    <FormattedMessage id="common.reset" defaultMessage="Reset" />
                                </button>
                            )}
                            {this.showOKButtonFunc() && (
                                <button
                                    className="btn btn-primary ml-auto"
                                    type="button"
                                    disabled={!this.state.date}
                                    onClick={() => {this.handleAccept()}}
                                >
                                    <FormattedMessage id="common.ok" defaultMessage="OK" />
                                </button>
                            )}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default Calendar;
