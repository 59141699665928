// @flow

import React from "react";
import ReactRouterPropTypes from "react-router-prop-types";
import Select from "react-select";
import Toggle from "react-toggle";

import { niceDate } from "../lib/Util";
import { getBackend } from "../lib/backend/Backend2";
import { translate } from "./IntlProviderWrapper";
import { MaterialTranslations } from "./MaterialTranslations";
import TranslatedMaterialTitle from "./TranslatedMaterialTitle";
import DemandForecastingDetails from "./DemandForecastingDetails";
import DemandForecastingTable, { type TableRow } from "./DemandForecastingTable";

export type Properties = {
    material: string,
    plant: string,
    horizon: number,
    logist: string | null
};

export type ModelInfo = {
    name: string
};

export type Model = {
    info: ModelInfo,
    coef: number,
    coef_80: number,
    MAE_original: number,
    MAE_new: number,
    count: number,
    better_pred_cnt: number,
    better_pred_perc: number
};

export type TimeSeries = {
    ts: string[],
    vals: number[]
};

export type Data = {
    planned_independent_requirements: TimeSeries,
    sales_orders_changes: TimeSeries,
    sales_orders: TimeSeries,
    PIR_SO_max: TimeSeries
};

export type DataItem = {
    properties: Properties,
    model: Model,
    data: Data
};

export type DemandForecast = {
    last_update: number,
    data: DataItem[]
};

type Plant = {
    id: string,
    title: string
};

type Material = {
    id: string,
    title: string
};

type SelectOption = {
    label: string,
    value: string
};

type Props = {
    match: ReactRouterPropTypes.match,
    history: ReactRouterPropTypes.history,
    location: ReactRouterPropTypes.location
};

type State = {
    data: DataItem[] | null,
    rows: TableRow[] | null,
    show_details_columns: boolean,
    details_rows: TableRow[] | null,
    last_update: number,
    plants: Plant[] | null,
    selected_plant: string,
    materials: Material[] | null,
    selected_material: string,
    show_all: boolean
};

export const formatTimestamp = (timestamp: number): string => {
    const date = new Date(timestamp);
    return `${date.getFullYear()}-${`${date.getMonth() + 1}`.padStart(2, "0")}`;
};

export const formatOptionLabel = ({ value, label }: SelectOption) => (
    <React.Fragment>
        {value && <span className="align-baseline mr-2">{value}</span>}
        {value ? <strong className="align-baseline">{label}</strong> : <span>{label}</span>}
    </React.Fragment>
);

export const getDfUrl = (plant: string, material: string): string => {
    let params = "";

    if (plant) {
        params += `/${plant}`;
    }

    if (material) {
        params += `/${material}`;
    }

    return `/demand-forecasting${params}`;
};

class DemandForecasting extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            data: null,
            rows: null,
            show_details_columns: false,
            details_rows: null,
            last_update: 0,
            plants: null,
            selected_plant: "",
            materials: null,
            selected_material: "",
            show_all: false
        };
    }

    async componentDidMount() {
        const response = await getBackend().common.getBlobStorageItemAsStringOrNull({
            key: "df.json"
        });
        this.prepareData(response.content);
    }

    componentDidUpdate(prev_props: Props) {
        if (
            prev_props.match.params.material_external_id !== this.props.match.params.material_external_id &&
            this.props.match.params.material_external_id !== this.state.selected_material
        ) {
            this.initSelection(this.props.match.params.plant_external_id, this.props.match.params.material_external_id);
            return;
        }

        if (
            prev_props.match.params.plant_external_id !== this.props.match.params.plant_external_id &&
            this.props.match.params.plant_external_id !== this.state.selected_plant
        ) {
            this.initSelection(this.props.match.params.plant_external_id);
        }
    }

    prepareData = async (response_content: string | null) => {
        if (response_content === null) {
            this.setState({
                rows: []
            });
            return;
        }

        const forecast: DemandForecast = JSON.parse(response_content);
        this.setState({
            data: forecast.data,
            last_update: forecast.last_update * 1000
        });

        const plant_ids = new Set<string>();
        const material_ids = new Set<string>();
        let rows: TableRow[] = [];

        forecast.data.forEach((item, i) => {
            const { properties, model, data } = item;
            const row_base: TableRow = {
                index: i,
                plant_id: properties.plant,
                plant: properties.plant,
                material_id: properties.material,
                material: properties.material,
                horizon: properties.horizon,
                date: "",
                person: properties.logist || "",
                pir: null,
                sales_orders: null,
                pir_so_max: null,
                correction_coef: model.coef,
                correction: null,
                mae_original: model.MAE_original,
                mae_new: model.MAE_new,
                mae_diff: model.MAE_new - model.MAE_original,
                coef_80: model.coef_80,
                count: model.count,
                better_pred_cnt: model.better_pred_cnt,
                better_pred_perc: model.better_pred_perc
            };
            const mapped_rows = this.mapData(data, row_base);
            rows = [...rows, ...mapped_rows];

            if (mapped_rows.length) {
                plant_ids.add(properties.plant);
                material_ids.add(properties.material);
            }
        });

        const plants_response = await getBackend().manufacturing.getPlants({});
        const plants_map = {};
        plants_response.plants.forEach(plant => {
            plants_map[plant.external_id] = plant;
        });
        const plants = [...plant_ids].map(id => ({
            id,
            title: plants_map[id] ? plants_map[id].title : ""
        }));
        const materials_response = await getBackend().manufacturing.searchMaterialsSimple({
            external_ids: [...material_ids]
        });

        await MaterialTranslations.loadTranslations(materials_response.data.map(material => material.uuid));

        rows = rows.map(row => {
            const translated_material = TranslatedMaterialTitle({
                material_external_id: row.material,
                material_title: row.material
            });

            if (translated_material !== row.material) {
                row.material = `${row.material} - ${translated_material}`;
            }

            if (plants_map[row.plant]) {
                row.plant = `${row.plant} - ${plants_map[row.plant].title}`;
            }

            return row;
        });

        this.setState(
            {
                rows,
                plants
            },
            () => {
                const selected_plant = this.props.match.params.plant_external_id || "";
                const selected_material = this.props.match.params.material_external_id || "";
                this.initSelection(selected_plant, selected_material);
            }
        );
    };

    mapData = (data: Data, row_base: TableRow): TableRow[] => {
        const ts_map = {};
        const last_update_horizon = new Date(this.state.last_update);
        last_update_horizon.setMonth(last_update_horizon.getMonth() + row_base.horizon);
        const horizon_ts = formatTimestamp(last_update_horizon.getTime());

        data.planned_independent_requirements.ts.forEach((ts, i) => {
            if (ts === horizon_ts) {
                ts_map[ts] = {
                    date: ts,
                    pir: data.planned_independent_requirements.vals[i],
                    sales_orders: null,
                    pir_so_max: null,
                    correction: null
                };
            }
        });

        data.sales_orders.ts.forEach((ts, i) => {
            if (ts === horizon_ts) {
                if (ts_map[ts]) {
                    ts_map[ts].sales_orders = data.sales_orders.vals[i];
                } else {
                    ts_map[ts] = {
                        date: ts,
                        pir: null,
                        sales_orders: data.sales_orders.vals[i],
                        pir_so_max: null,
                        correction: null
                    };
                }
            }
        });

        Object.keys(ts_map).forEach(ts => {
            if (ts_map[ts].pir !== null || ts_map[ts].sales_orders !== null) {
                ts_map[ts].pir_so_max = Math.max(ts_map[ts].pir, ts_map[ts].sales_orders);
                if (row_base.correction_coef !== null) {
                    ts_map[ts].correction = ts_map[ts].pir_so_max * row_base.correction_coef;
                }
            }
        });

        return Object.values(ts_map).map(row_ts => ({ ...row_base, ...row_ts }));
    };

    mapMaterials = (material_ids: string[]): Material[] => {
        return [...material_ids].map(id => {
            const translated_material = TranslatedMaterialTitle({
                material_external_id: id,
                material_title: id
            });

            return {
                id,
                title: translated_material !== id ? translated_material : ""
            };
        });
    };

    setUrlParams = (plant: string, material: string) => {
        this.props.history.push(getDfUrl(plant, material));
    };

    initSelection = (plant_id: string, material_id?: string, set_url_params?: boolean) => {
        if (this.state.rows === null) {
            return;
        }

        if (!plant_id) {
            this.setState({
                details_rows: null,
                selected_plant: "",
                selected_material: "",
                materials: null
            });
            this.setUrlParams("", "");

            return;
        }

        if (plant_id !== this.state.selected_plant) {
            const selected_plant = plant_id;
            const material_ids = [
                ...new Set(
                    this.state.rows.reduce((prev_materials: string[], row) => {
                        const current_materials = [...prev_materials];
                        if (row.plant_id === selected_plant) {
                            current_materials.push(row.material_id);
                        }

                        return current_materials;
                    }, [])
                )
            ];
            const selected_material = material_id || material_ids[0];
            const details_rows =
                this.state.rows &&
                this.state.rows.filter(row => row.plant_id === selected_plant && row.material_id === selected_material);

            this.setState({
                details_rows,
                selected_plant,
                selected_material,
                materials: this.mapMaterials(material_ids)
            });

            if (set_url_params) {
                this.setUrlParams(selected_plant, selected_material);
            }

            return;
        }

        if (material_id && material_id !== this.state.selected_material) {
            const selected_material = material_id;
            const details_rows =
                this.state.selected_plant && selected_material && this.state.rows
                    ? this.state.rows.filter(
                          row => row.plant_id === this.state.selected_plant && row.material_id === selected_material
                      )
                    : null;

            this.setState({
                details_rows,
                selected_material
            });

            if (set_url_params) {
                this.setUrlParams(this.state.selected_plant, selected_material);
            }
        }
    };

    handlePlantChange = (option: SelectOption) => {
        this.initSelection(option.value, "", true);
    };

    handleMaterialChange = (option: SelectOption) => {
        this.initSelection(this.state.selected_plant, option.value, true);
    };

    handleShowDetailsColumnsToggle = () => {
        this.setState(prev_state => {
            prev_state.show_details_columns = !prev_state.show_details_columns;
            return prev_state;
        });
    };

    handleShowAllButtonClick = () => {
        this.setState(state => {
            state.show_all = !state.show_all;
            return state;
        });
    };

    render() {
        const details_indices = this.state.details_rows ? this.state.details_rows.map(row => row.index) : [];
        const all_palnts_option = { label: translate("DemandForecasting.all_plants", "All plants"), value: "" };
        const plant_options = this.state.plants
            ? [
                  all_palnts_option,
                  ...this.state.plants.map(plant => ({
                      label: plant.title,
                      value: plant.id
                  }))
              ]
            : [];
        const selected_plant_option = plant_options.find(option => option.value === this.state.selected_plant) || null;
        const material_options = this.state.materials
            ? this.state.materials.map(material => ({
                  label: material.title,
                  value: material.id
              }))
            : [];
        const selected_material_option =
            material_options.find(option => option.value === this.state.selected_material) || null;
        const max_rows = 100;

        return (
            <article className="article" key="1">
                <section id="statistics" className="data_sources">
                    <div className="filter_bar views-filter-bar">
                        <div className="row align-items-center">
                            <div className="col col-auto">
                                <h2 className="my-0">{translate("DemandForecasting.title", "Demand forecasting")}</h2>
                                <small className="text-muted d-block">
                                    {translate("DemandForecasting.last_update", "Last update")}:{" "}
                                    {this.state.last_update > 0 ? niceDate(new Date(this.state.last_update)) : "…"}
                                </small>
                            </div>
                            <div className="col col-auto ml-auto">
                                <div className="form-row">
                                    <div className="col-auto">
                                        <div className="form-group mb-0" style={{ width: 250 }}>
                                            <Select
                                                placeholder={translate("common.plant", "Plant")}
                                                value={selected_plant_option}
                                                options={plant_options}
                                                onChange={this.handlePlantChange}
                                                formatOptionLabel={formatOptionLabel}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <div className="form-group mb-0" style={{ width: 250 }}>
                                            <Select
                                                placeholder={translate("common.material", "Material")}
                                                value={selected_material_option}
                                                options={material_options}
                                                isDisabled={!this.state.selected_plant}
                                                onChange={this.handleMaterialChange}
                                                formatOptionLabel={formatOptionLabel}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-auto d-flex align-items-center">
                                        <label className="mb-0">
                                            <Toggle
                                                className="vertical-align-middle mr-2"
                                                checked={this.state.show_details_columns}
                                                onChange={this.handleShowDetailsColumnsToggle}
                                            />
                                            <span>{translate("common.details", "Details")}</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="vertical_tab" style={{ paddingTop: 76 }}>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col">
                                    {this.state.details_rows ? (
                                        <DemandForecastingDetails
                                            rows={this.state.details_rows}
                                            data={
                                                this.state.data
                                                    ? this.state.data.filter((data_item, i) =>
                                                          details_indices.includes(i)
                                                      )
                                                    : []
                                            }
                                            showDetailsColumns={this.state.show_details_columns}
                                        />
                                    ) : (
                                        <div className="white_box">
                                            <DemandForecastingTable
                                                rows={this.state.rows}
                                                showDetailsColumns={this.state.show_details_columns}
                                                variant="overview"
                                                maxRows={this.state.show_all ? undefined : max_rows}
                                            />
                                            {this.state.rows && this.state.rows.length > max_rows && (
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <button className="btn" onClick={this.handleShowAllButtonClick}>
                                                        {this.state.show_all
                                                            ? translate("DemandForecasting.show_less", "Show less")
                                                            : translate("DemandForecasting.show_all", "Show all")}
                                                    </button>
                                                    {!this.state.show_all && (
                                                        <span className="text-muted mr-2">
                                                            {max_rows} / {this.state.rows.length}{" "}
                                                            {translate("DemandForecasting.rows", "rows")}
                                                        </span>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </article>
        );
    }
}

export default DemandForecasting;
