// @flow

import React from "react";
import MultiSelect from "../../../../MultiSelect";
import { translate } from "../../../../IntlProviderWrapper";
import { SET_SELECTED_ORDER_EXTERNAL_IDS, RESET_FILTERS } from "../../reducers/reducersFilters/filters";
import { connect } from "react-redux";

import type { SetSelectedOrderUuids, ResetFiltersAction } from "../../reducers/reducersFilters/filters";
import type { LineOrders } from "../../reducers/linesOrders";
import type { ReduxState } from "../../reducers/reducers";

export type SelectOptionItem = {
    label: string;
    value: string;
}

type Props = {
    onChange: (args: SelectOptionItem) => void,
    reduxDispatch: (args: SetSelectedOrderUuids | ResetFiltersAction) => void,
    unscheduled_orders: LineOrders[],
    lines_orders: LineOrders[],
    selected_order_external_ids: string[]
}

type State = {
    selected_order: SelectOptionItem | null
};

const replaceSpaces = (text: string = "") => {
    return text.split(" ").filter(v => v.trim()).join(" ");
}

const filterOption = (
    candidate: { label: string; value: string; data: any },
    input: string
  ) => {
    if (input) {
        return (
            (replaceSpaces(candidate.label).toLowerCase().includes(replaceSpaces(input).toLowerCase())) ||
            candidate.value === (input || "").trim()
        );
    }
    return true;
}

const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
};
const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
  };
const formatGroupLabel = (data: GroupedOption) => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );

type GroupedOption = {
    label: string,
    options: SelectOptionItem[]
}

// TODO - when reschedule order or line is enabled then we need to reset the filter
class OrderFilter extends React.Component<Props, State> {

    mapLineOrdersToOptions = (): SelectOptionItem[] => {
        const unscheduled_orders = this.props.unscheduled_orders;
        const lines_orders = this.props.lines_orders;
        if (!lines_orders && !unscheduled_orders) { return []; }

        const order_external_id_set: Set<string> = new Set();
        for (const line_order of [...lines_orders, ...unscheduled_orders]) {
            for (const order of line_order.orders) {
                order_external_id_set.add(order.external_id);
            }
        }

        const options: SelectOptionItem[] = [];
        for (const order_external_id of order_external_id_set) {
            options.push({
                value: order_external_id,
                label: order_external_id
            });
        }
        options.sort((a, b) => a.value.localeCompare(b.value));

        return options;
    }

    getOptions = (): SelectOptionItem[] => {
        return this.mapLineOrdersToOptions();
    }

    getSelectedOptions = (order_uuids: string[] | null): SelectOptionItem[] => {
        if (!order_uuids) {
            return [];
        }

        const options = this.getOptions();
        const selected_options = [];

        for (const option of options) {
            if (order_uuids.includes(option.value)) {
                selected_options.push(option);
            }
        }

        return selected_options;
    }

    onChange = (options?: SelectOptionItem[]) => {
        if (!options) {
            this.props.reduxDispatch({ type: RESET_FILTERS, data: undefined });
        } else {
            this.props.reduxDispatch({ type: SET_SELECTED_ORDER_EXTERNAL_IDS, data: options.map(o => o.value) });
        }
    }

    render() {
        return <div className="order-filter">
            <MultiSelect
                value={this.getSelectedOptions(this.props.selected_order_external_ids)}
                placeholder={translate("Header.menu.workorders", "Work-orders")}
                options={this.getOptions()}
                filterOption={filterOption}
                formatGroupLabel={formatGroupLabel}
                onChange={this.onChange}
                enable_filter_selected
            />
        </div>
    }
}

export default connect(
    (state: ReduxState) => {
        const selected_order_external_ids = state.gantt_chart_filters.selected_order_external_ids;
        const lines_orders = state.gantt_chart_lines_orders.lines_orders;
        const unscheduled_orders = state.gantt_chart_lines_orders.unscheduled_orders;

        return {
            selected_order_external_ids,
            lines_orders,
            unscheduled_orders
        };
    },
    (dispatch) => ({ reduxDispatch: dispatch })
)(OrderFilter);