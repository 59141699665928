// @flow
import * as React from "react";
import Redirect from "react-router-dom/Redirect";

import { PLANT_TAGS_ACCESS } from "../lib/ManufacturingTags.generated";
import { getBackend } from "../lib/backend/Backend2";

type Props = { };
type State = {
    redirect_to: string
};

class Home extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const state: State = {
            redirect_to: ""
        };
        this.state = state;
    }

    async componentDidMount() {
        const plant_uuid = localStorage.getItem("last-plant") || "";
        const { plants } = await getBackend().manufacturing.getPlants({});
        const plant = plants.find(p => p.uuid === plant_uuid);
        let redirect_to = "/manufacturing/lines";
        if (plant !== undefined && PLANT_TAGS_ACCESS.new_feature_home_button(plant.tags)) {
            redirect_to = "/manufacturing/home";
        }

        this.setState({ redirect_to });
    }

    render() {
        if (this.state.redirect_to === "") {
            return null;
        }

        return (<Redirect to={this.state.redirect_to} />);
    }
}

export default Home;
