// @flow
// $FlowFixMe
import React, { useReducer } from 'react';
import { FormattedMessage } from "react-intl";

import {
    dateFromDayShift,
    niceDate
} from "../../lib/Util";

import { Modal } from "react-bootstrap";
import Loader from "../Loader";
import { getBackend as getBackend2 } from "../../lib/backend/Backend2";
import * as t from "../../lib/backend/manufacturing2.generated.types";
import ReactRouterPropTypes from "react-router-prop-types";
import { INSIGHT_ACTIONS } from "../../lib/Models";

type ActionModalProps = {
    history: ReactRouterPropTypes.history,
    insight: t.IEventDataEx,
    line_title: string,
    reportid: string,
    shift_str: string,
    allowed_actions?: $Keys<typeof INSIGHT_ACTIONS>[],
    onActionSelect?: (action: t.IInsightAction, insight: t.IEventDataEx) => void
}

type ActionsModalState = {
    insight_actions: Array<t.IInsightAction>,
    actions_error: boolean,
    loading_actions: boolean,
    insight_actions_uuid: string
}

const initialState: ActionsModalState = {
    actions_error: false,
    loading_actions: false,
    insight_actions: [],
    insight_actions_uuid: "",
};

const actionsModalReducer = (state: ActionsModalState, action): ActionsModalState => {
    switch(action.type) {
        case "loading_insight_actions":
            return {
                ...state,
                actions_error: false,
                loading_actions: true,
                insight_actions_uuid: action.data
            }
        case "loaded_insight_actions":
            return {
                ...state,
                loading_actions: false,
                insight_actions: action.data
            }
        case "error_loading_insight_actions":
            return {
                ...state,
                insight_actions: [],
                actions_error: true,
                loading_actions: false
            }
        case "hide_actions_modal":
            return {
                ...state,
                insight_actions_uuid: ""
            }
        default:
            return state;
    }
}

const showActionsModal = async (dispatch: any, insight: Object, reportid: string) => {
    try {
        dispatch({ type: 'loading_insight_actions',  data: insight.uuid });
        const res = await getBackend2().manufacturing.getInsightActions({ insight, report_id: reportid });
        dispatch({ type: 'loaded_insight_actions', data: res.actions });
    } catch (e) {
        dispatch({ type: 'error_loading_insight_actions' });
    }
}

const Actions = ({actions, history, insight, onActionSelect}) => {
    let actions_list = actions.map((action) => {
        return (
            <button
                onClick={() => {
                    if (onActionSelect) {
                        onActionSelect(action, insight);
                        return;
                    }

                    history.push(action.url);
                }}
                className="list-group-item list-group-item-action"
                key={action.code}
            >
                {action.description}
            </button>
        );
    });

    return actions_list;
}

const ActionsModal = (props: ActionModalProps) => {
    let {
        insight,
        shift_str,
        line_title,
        reportid,
        history,
        allowed_actions,
        onActionSelect
    } = props;

    const [state, dispatch] = useReducer(actionsModalReducer, initialState);

    const {
        insight_actions: all_insight_actions,
        actions_error,
        loading_actions,
        insight_actions_uuid
    } = state;

    let firstShiftStart = dateFromDayShift(new Date(), 0).getTime();

    // Show actions only for future insights
    if (insight.ts < firstShiftStart) {
        return null;
    }

    const insight_actions = allowed_actions
        ? all_insight_actions.filter(action => allowed_actions.includes(action.code))
        : all_insight_actions;

    return (
        <td>
            <button className="btn btn-flat-primary" onClick={() => {showActionsModal(dispatch, insight, reportid)}}>
                <FormattedMessage id="common.actions" defaultMessage="Actions" />
            </button>
            <Modal
                show={insight_actions_uuid === insight.uuid}
                bsSize="large"
                dialogClassName="modal-dialog-scrollable"
                onHide={() => { dispatch({type: 'hide_actions_modal'}); }}
            >
                <Modal.Header>
                    <Modal.Title>{line_title}: {insight.title}<br />{niceDate(new Date(insight.ts))}, {shift_str} Shift</Modal.Title>
                    <button type="button" className="close" onClick={() => { dispatch({type: 'hide_actions_modal'}); }}>
                        <span aria-hidden="true">×</span>
                        <span className="sr-only"><FormattedMessage id="common.close" defaultMessage="Close" /></span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <p>{insight.description}</p>
                    <h5 className="h6 font-weight-normal">
                        <FormattedMessage id="insights.suggested_actions" defaultMessage="Suggested actions" />:
                    </h5>
                    <div className="list-group">
                        {loading_actions && <Loader small={true} />}
                        {!loading_actions && insight_actions.length === 0 && !actions_error &&
                            <p className="p-3 mx-auto">
                                <FormattedMessage id="insights.no_actions" defaultMessage="No actions available" />
                            </p>
                        }
                        {actions_error &&
                            <p className="p-3 mx-auto">
                                <FormattedMessage id="insights.actions_error" defaultMessage="Error retrieving actions" />
                            </p>
                        }
                        {!loading_actions && (
                            <Actions
                                actions={insight_actions}
                                history={history}
                                insight={insight}
                                onActionSelect={onActionSelect}
                            />
                        )}
                    </div>
                </Modal.Body>
            </Modal>
        </td>
    );
}

export default ActionsModal;
