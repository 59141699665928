// @flow

// main imports
import React, { Component } from "react";

// models

// backend

// import subcomponents
import { Modal, Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

// defining types
type Props = {
    show: boolean,
    handleClick: any,
    handleCancel: any,
    initialMessage: string
};

type State = {
    message: string
};

class ShiftAssignmentDetailsExportDayModal extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            message: props.initialMessage || ""
        };
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.initialMessage !== this.props.initialMessage) {
            this.setState({ message: this.props.initialMessage });
        }
    }

    handleClose() {
        // trigger generation of the report
        this.props.handleClick(this.state.message);
    }

    handleCancel() {
        this.props.handleCancel();
    }

    handleChange = (event: Event) => {
        let target = event.target;
        if (target instanceof HTMLTextAreaElement) {
            // collect changed field name and value
            const field = target.name;
            const value = target.value;

            // update
            this.setState(prev_state => {
                prev_state[field] = value;
                return prev_state;
            });
        }
        event.stopPropagation();
    }

    render() {
        const show = this.props.show;
        return (
            <Modal
                show={show}
                onHide={() => this.handleClose()}>
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id="ShiftDetails.export_modal_title" defaultMessage="Remarks for printing" />
                    </Modal.Title>
                    <button type="button" className="close" onClick={() => this.handleCancel()}>
                        <span aria-hidden="true">×</span>
                        <span className="sr-only"><FormattedMessage id="common.close" defaultMessage="Close" /></span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <FormattedMessage
                        id="ShiftDetails.export_modal_text"
                        defaultMessage="Enter a remark for printing, the field can be left alone too:" />
                    <textarea
                        className="form-control"
                        style={{ height: 200 }}
                        value={this.state.message}
                        name="message"
                        onChange={this.handleChange} />
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-primary" onClick={() => this.handleClose()}>
                        <FormattedMessage id="common.ok" defaultMessage="OK" />
                    </Button>
                    <Button className="btn" onClick={() => this.handleCancel()}>
                    <FormattedMessage id="common.cancel" defaultMessage="Cancel" />
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    };
}

export default ShiftAssignmentDetailsExportDayModal;
