// @flow

import axios from "axios";
import { replaceDirtyString } from "./Util";
import { subscribe, MSG_TYPES } from "./PubSub";

import { getBackend } from "./backend/Backend2";
import type { IGetAnonymizationDataRes } from "./backend/manufacturing2.generated.types";

const STORAGE_ITEM_ANON_FLAG = "anonymize";
let anon_data: IGetAnonymizationDataRes | null = null;

/** Internal class that performs anonymization */
class Anonymizer {

    replacements_map: Object;

    constructor(replacements_map: Object) {
        this.replacements_map = replacements_map;
    }

    performReplacementsRec(data: Object) {
        if (data === undefined) {
            return data;
        }
        if (this.replacements_map !== undefined) {
            Object.keys(data).forEach(key => {
                const value_to_replace = data[key];
                if (value_to_replace !== null && typeof value_to_replace === 'object') {
                    return this.performReplacementsRec(value_to_replace);
                }
                if (typeof value_to_replace === 'string' && !key.includes("uuid")) {
                    replaceDirtyString("IoT: ", "", key, data, this.replacements_map);
                    replaceDirtyString("Linija ", "", key, data, this.replacements_map);
                    replaceDirtyString("Linija ", " - IoT - Good parts", key, data, this.replacements_map);
                    replaceDirtyString("Linija ", " - IoT - Good parts - downtime", key, data, this.replacements_map);
                    replaceDirtyString("Linija ", " - IoT - Good parts - signal_edge", key, data, this.replacements_map);
                    replaceDirtyString("Linija ", " - Tehnološki zastoj - Okvara orodja", key, data, this.replacements_map);
                    replaceDirtyString("Linija ", " - Tehnološki zastoj - Nastavljanje med procesom", key, data, this.replacements_map);
                    replaceDirtyString("Start - Downtime - Linija ", " - IoT - Good parts", key, data, this.replacements_map);
                    replaceDirtyString("", " - IoT - Good parts", key, data, this.replacements_map);
                    replaceDirtyString("", " - Organizacijski zastoj", key, data, this.replacements_map);
                    replaceDirtyString("", " - Organizacijski zastoj", key, data, this.replacements_map);
                    replaceDirtyString("", " - Gantt Chart", key, data, this.replacements_map);
                    replaceDirtyString("", " - Kapaciteta", key, data, this.replacements_map);
                    replaceDirtyString("", " - Microplan", key, data, this.replacements_map);
                    replaceDirtyString("", " - Tloris", key, data, this.replacements_map);
                    replaceDirtyString("", " - Hitrost", key, data, this.replacements_map);
                    replaceDirtyString("", " - Izmet", key, data, this.replacements_map);
                    replaceDirtyString("High value - ", " - Organizacijski zastoj", key, data, this.replacements_map);
                    replaceDirtyString("High value - ", " - Hitrost", key, data, this.replacements_map);
                    replaceDirtyString("High value - Linija ", " - Tehnološki zastoj - Nastavljanje med procesom", key, data, this.replacements_map);
                    replaceDirtyString("High value - Linija ", " - Organizacijski zastoj", key, data, this.replacements_map);
                    replaceDirtyString("High value - Izdelek ", " - Izmet", key, data, this.replacements_map);
                    replaceDirtyString("High value - Izdelek ", "", key, data, this.replacements_map);
                    replaceDirtyString("High value of Linija ", " - Tehnološki zastoj - Mehanska okvara", key, data, this.replacements_map);
                    replaceDirtyString("High value of Linija ", " - Organizacijski zastoj", key, data, this.replacements_map);
                    replaceDirtyString("Izdelek ", " - Kapaciteta", key, data, this.replacements_map);
                    replaceDirtyString("Izdelek ", " - Hitrost", key, data, this.replacements_map);
                    replaceDirtyString("Izdelek ", " - Izmet", key, data, this.replacements_map);
                    replaceDirtyString("Izdelek ", "", key, data, this.replacements_map);
                    replaceDirtyString("Low value - ", " - Hitrost", key, data, this.replacements_map);
                    replaceDirtyString("Low value - ", "", key, data, this.replacements_map);
                    replaceDirtyString("Low value - Izdelek ", "", key, data, this.replacements_map);
                    replaceDirtyString("Low value - Izdelek ", " - Hitrost", key, data, this.replacements_map);
                    replaceDirtyString("/aka?type=man_line&external_id=", "", key, data, this.replacements_map);
                    if (this.replacements_map[data[key]] !== undefined) {
                        data[key] = this.replacements_map[data[key]];
                    }
                }
            });
        }
        return data;
    }

    performReplacements(data: Object): Object {
        if (this.replacements_map) {
            data = this.performReplacementsRec(data);
        }
        return data;
    }
}

/** Singleton with anonymization mapping */
let anonymizer: Anonymizer | null = null;

/** Initializes anonymization framework, but doesn't activate it. */
export async function initAnonymization(axiosInstance: axios.AxiosInstance): Promise<void> {
    axiosInstance.interceptors.request.use(readOnlyInterceptor);
    axiosInstance.interceptors.response.use(handleSuccessInterception);

    subscribe(MSG_TYPES.logout, async () => {
        // clear anonymization flag
        await setAnonymization(false);
    });
}

export async function setAnonymization(anonymize: boolean): Promise<void> {
    if (anonymize) {
        try {
            console.log("Loading anonymization data from server...");
            if (!anon_data) {
                anon_data = await getBackend().manufacturing.getAnonymizationData({});
            }
            console.log("Loading data into anonymizer object...");
            anonymizer = new Anonymizer(anon_data.mapping);
            localStorage.setItem(STORAGE_ITEM_ANON_FLAG, anonymize.toString());
            console.log("Anonymizer object loaded.");
        } catch (err) {
            console.error("We got an error when retrieving anonymization values");
            console.error(err);
        }
    } else {
        anonymizer = null;
        localStorage.setItem(STORAGE_ITEM_ANON_FLAG, anonymize.toString());
    }
};

export function getAnonymization(): boolean {
    const anonymize = localStorage.getItem(STORAGE_ITEM_ANON_FLAG);
    if (anonymize === undefined) {
        return false;
    }
    return (anonymize === "true");
};

/** This request interceptor checks if we're running in read-only mode (e.g. anonymization is enabled) */
function readOnlyInterceptor(request: axios.AxiosRequest) {
    const readOnlyMode = getAnonymization();
    if (readOnlyMode) {
        const prefix = "/api/v1.0";
        // TODO fix this temporary solution!
        // readOnlyInterceptor has full access!
        const whitelistedVerbs = ["GET", "DELETE", "POST"];
        const whitelistedPaths = [
            "/manufacturing/competences", "/manufacturing/insights", "/manufacturing/checks",
            "/manufacturing/materials", "/manufacturing/lines",
            "/manufacturing/plants", "/manufacturing/orders", "/manufacturing/people",
            "/reports/new",
            "/events", "/apps", "/users/login"];

        const isWhitelistedVerb = whitelistedVerbs.includes(request.method.toUpperCase());
        const path = "/" + request.url.replace(prefix, "").replace(/^\/|\/$/g, '');
        const isException = (request.method.toUpperCase() === "POST") && whitelistedPaths.includes(path);
        if (isWhitelistedVerb || isException) {
            return request;
        } else {
            console.log("Anonymization path not whitelisted: ", path);
        }
        return false;
    }
    return request;
}

/** This interceptor applies anonymization if needed */
function handleSuccessInterception(response: axios.AxiosResponse) {
    if (getAnonymization()) {
        if (anonymizer) {
            console.log("Performing anonymization.");
            response.data = anonymizer.performReplacements(response.data);
        } else {
            console.warn("Cannot perform anonymization, anonymizer not initialized.");
        }
    }
    return response;
}
