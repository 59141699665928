// @flow
// $FlowFixMe
import React, {useState, useEffect} from "react";
import { Modal } from "react-bootstrap";
import { translate } from "../IntlProviderWrapper";
import { getBackend } from "../../lib/backend/Backend2";
import ReactTable from "../react/ReactTable";
import type { ReactTableColumn } from "../react/ReactTable";
import { niceNumber, niceDateTime } from "../../lib/Util";

type State = {
    show: boolean,
    data: Array<mixed>
}

type Props = {
    show: boolean,
    material_uuid: string,
    location_uuid: string,
    closeModal: Function
};

type IUnconfirmedStockDetails = {
    material_external_id: string,
    operation_external_id: string,
    plant_external_id: string,
    quantity: string,
    quantity_type: string,
    type: string,
    date: string,
    uuid: string
};

const getTableColumns = (): ReactTableColumn<IUnconfirmedStockDetails>[] => {
    return [
        {
            "Header": translate("Manufacturing.StocksView.material_external_id", "Ident"),
            "accessor": "material_external_id",
            disableFilters: true
        },
        {
            "Header": translate("OrderTableProduction.operation_id", "ID operation"),
            "accessor": "operation_external_id",
            disableFilters: true
        },
        {
            "Header": translate("common.plant", "Plant"),
            "accessor": "plant_external_id",
            disableFilters: true
        },
        {
            "Header": translate("common.quantity", "Quantity"),
            "accessor": "quantity",
            disableFilters: true
        },
        {
            "Header": translate("common.quantity_type", "Quantity Type"),
            "accessor": "quantity_type",
            disableFilters: true
        },
        {
            "Header": translate("common.type", "Type"),
            "accessor": "type",
            disableFilters: true
        },
        {
            "Header": translate("common.time", "Time"),
            "accessor": "date",
            disableFilters: true
        },
        {
            "Header": translate("common.uuid", "UUID"),
            "accessor": "uuid",
            disableFilters: true
        }
    ];
};

const fetchData = async (material_uuid: string, location_uuid: string) => {
    const res = await getBackend()
        .manufacturing.getUnconfirmedStockDetails(
            {
                material_uuid: material_uuid,
                location_uuid: location_uuid
            }
        );

    return res;
}

const initialState: State = {
    show: false,
    data: []
}

const StockStatusModal = (props: Props) => {
    const [state, setState] = useState(initialState);
    const show_modal = props.show && props.material_uuid && props.location_uuid;

    const columns = getTableColumns();

    useEffect(() => {
        if (show_modal) {
            fetchData(props.material_uuid, props.location_uuid).then(res => {
                let raw_data = [];
                let data = [];
                if (res.extra_data && res.extra_data.details) {
                    raw_data = res.extra_data.details;
                    raw_data.sort((x,y) => (x.date < y.date) ? -1 : 1);
                }
                data = raw_data.map(x => {
                    return {
                        material_external_id: x.material_external_id,
                        operation_external_id: x.operation_external_id,
                        plant_external_id: x.plant_external_id,
                        quantity: niceNumber(x.quantity, 0),
                        quantity_type: x.quantity_type,
                        type: x.type,
                        date: x.date ? niceDateTime(new Date(x.date)) : "-",
                        uuid: x.uuid
                    };
                });

                setState({ data });
            });
        }
    }, [props.material_uuid, props.location_uuid])

    return (
        <Modal
            id="stock-status-modal"
            show={show_modal}
            bsSize="large"
            dialogClassName="modal-dialog-scrollable"
            onHide={props.closeModal}
        >
            <Modal.Header>
                <Modal.Title>{translate("common.details", "Details")}</Modal.Title>
                <button type="button" className="close" onClick={props.closeModal}>
                    <span aria-hidden="true">×</span>
                    <span className="sr-only">{translate("common.close","Close")}</span>
                </button>
            </Modal.Header>
            <Modal.Body>
                <ReactTable
                    data={state.data}
                    columns={columns}
                />
            </Modal.Body>
            <Modal.Footer>
                <button className="btn_register btn-primary btn btn-default btn-block"
                    disabled={false} onClick={props.closeModal}>
                    {translate("common.ok", "Ok")}
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default StockStatusModal;
