// @flow
// main imports

// $FlowFixMe
import { useMemo, useEffect } from "react";
import * as React from "react";
import { useTable, useFilters, usePagination, useSortBy, type TableState } from "react-table";
import Select from "react-select";
import { translate } from "../IntlProviderWrapper";
import Loader from "../Loader";
import DateTimePicker from "../DateTimePicker";
import ErrorComponent from "../ErrorComponent";

export type ReactTableColumn<T> = {
    Header: string | React.Node | () => React.Node,
    accessor: $Keys<T>,
    textAlign?: "left" | "right" | "center",
    filterInputPlaceholder?: string,
    disableFilters?: boolean,
    enableSorting?: boolean,
    Cell?: any => any | null,
    title?: string
}

type Filter = {
    filterValue?: string,
    setCustomFilter: (value: any, setFilter: (value: any) => void) => void,
}

type Filters = {
    [key: string]: Filter
}

type Props<T> = {
    data: T[],
    columns: ReactTableColumn<T>[],
    onCellClick?: Function,
    hiddenColumns?: string[],
    isLoading?: boolean,
    onChangeCell?: Function,
    className?: string,
    rowStyle?: Function,
    rowClassName?: Function,
    filters?: Filters,
    ExpandableRow?: any,
    LastRow?: any,
    isSticky?: boolean,
    table_id?: string,
    small_loader?: boolean,
    initialState?: $Shape<TableState>,
    maxRows?: number,
    filter_type?: "date",
    getCellProps?: Function,
    Pagination?: React.ComponentType<PaginationProps> | boolean,
    error_msg?: string
}

type FilterInputProps = {
    filters: any,
    column: any,
    initial_value: string,
    setFilter: (value: string) => void
};
type FilterInputState = {
    original_value: string
};

class FilterInput extends React.Component<FilterInputProps, FilterInputState> {
    constructor(props) {
        super(props);
        this.state = {
            original_value: props.initial_value || ""
        }
    }

    componentDidUpdate(prev_props) {
        const filter_value = this.props.column.filterValue || "";
        const { original_value } = this.state;
        if (filter_value !== prev_props.column.filterValue && filter_value !== original_value) {
            this.setState({
                original_value: filter_value
            });
        }
    }

    onChange = (e: Event) => {
        const column = this.props.column;
        const filters = this.props.filters;

        if (e.target instanceof HTMLInputElement) {
            const target_value = e.target.value;
            this.setState({ original_value: target_value });

            const value = target_value.trim();
            if (filters[column.id] && filters[column.id].setCustomFilter) {
                filters[column.id].setCustomFilter(value, this.props.setFilter);
            }

            this.props.setFilter(value) // Set undefined to remove the filter entirely
        }
    }

    render() {
        // this component manages trimmed spaces
        const column = this.props.column;
        const filters = this.props.filters;
        const filterValue = filters[column.id] && filters[column.id].filterValue || "";

        // original_value is set via initial_value which can be used to open modal dialog with preapplied filters
        // (eg order id) to show only specific orders
        // filterValue is used so that outer components using ReactTable can control the filter value via Redux or some other way

        return <input
            value={this.state.original_value || filterValue}
            className="form-control column-filter"
            onChange={this.onChange}
            title={column.filterInputPlaceholder}
            placeholder={column.filterInputPlaceholder || translate("common.search", "Search")}
        />
    }
}

type DateFilterProps = {
    column: any,
    filters: any,
    setFilter: (column_id: string, value: any) => void
}

type DateFilterState = {};

export class DateFilter extends React.Component<DateFilterProps, DateFilterState> {
    onChange = (date: Date) => {
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        this.props.setFilter(this.props.column.id, date.getTime() + "");
    }

    getFilterValue = (): Date | null => {
        return this.props.column.filterValue;
    }

    onClear = () => {
        this.props.setFilter(this.props.column.id, undefined);
    }

    render() {
        const value = this.getFilterValue();

        return <DateTimePicker
            inputClassName="column-filter"
            name="start_time"
            pickerType="date"
            value={value ? new Date(parseFloat(value)) : undefined}
            popupPostion="fixed"
            title={this.props.column.filterInputPlaceholder}
            placeholder={this.props.column.filterInputPlaceholder || translate("common.date", "Date")}
            onClear={this.onClear}
            onChange={this.onChange}
        />
    }
}

const DefaultColumnFilter = ({column, filters, initialState }) => {
    let { setFilter } = column;

    useEffect(() => {
        if (column && filters[column.id] && filters[column.id].filterValue) {
            setFilter(filters[column.id].filterValue);
        }
    }, []);

    const initial_filter = initialState.filters && initialState.filters.find(filter => filter.id === column.id);

    return (
        <FilterInput
            key={column.id}
            column={column}
            filters={filters}
            initial_value={initial_filter && initial_filter.value || ""}
            setFilter={setFilter}
        />
    )
}

const generateSortingIndicator = (column, show_unsorted = false) => {
    if (!column.isSorted) {
        return show_unsorted ? <i className="fas fa-arrows-alt-v" /> : null;
    }

    return !column.isSortedDesc
        ? <i className="fas fa-long-arrow-alt-down" />
        : <i className="fas fa-long-arrow-alt-up" />;
}

const getTableStyle = () => {
    return {
        position: "relative",
        borderCollapse: "collapse"
    }
}

const before_element_on_page = {
    "/manufacturing/stocks/stock_status": ".views-filter-bar",
    "/manufacturing/lines/subcontracting": ".views-filter-bar",
    "/manufacturing/people/worker_assignments": ".views-filter-bar",
    "/manufacturing/lines/orders_shifts": ".views-filter-bar",
    "/manufacturing/stocks/stock_requirements": ".navigation-bar"
}

const getStickyTh = () => {
    let el = null;
    for (const [location, selector] of Object.entries(before_element_on_page)) {
        if (window.location.href.includes(location)) {
            // $FlowFixMe
            el = document.querySelector(selector);
        }
    }

    const style = {
        background: "white",
        position: "sticky",
        top: ""
    };

    const is_modal = document.querySelector("body.modal-open");
    if (is_modal) {
        style.top = "0px";
    } else if (el && !is_modal) {
        style.top = el.offsetHeight + el.offsetTop + "px";
    }

    return style;
}

export type PaginationProps = {
    num_rows: number,
    canPreviousPage: boolean,
    canNextPage: boolean,
    pageOptions: number[],
    pageCount: number,
    gotoPage: (page: number) => void,
    nextPage: () => void,
    previousPage: () => void,
    setPageSize: (page_size: number) => void,
    onPageChange?: (page_index: number) => void,
    onPageSizeChange?: (page_size: number) => void,
    pageIndex: number,
    pageSize: number,
    pageSizes: number[],
    className?: string
};

export const DefaultPagination = ({
    num_rows,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    onPageChange = () => {},
    onPageSizeChange = () => {},
    pageIndex,
    pageSize = 25,
    pageSizes,
    className = ""
}: PaginationProps) => {
    const row_start = num_rows > 0 ? pageIndex * pageSize + 1 : 0;
    const row_end = Math.min((pageIndex + 1) * pageSize, num_rows);
    return (
        <div className={`react-table-pagination ${className}`}>
            <span className="mx-2">
                <button
                    className="btn btn-flat-light btn-short"
                    onClick={() => { gotoPage(0); onPageChange(0) }} disabled={!canPreviousPage}
                >
                    <i className="fas fa-angle-double-left" />
                </button>
                <button
                    className="btn btn-flat-light btn-short ml-1"
                    onClick={() => { previousPage(); onPageChange(pageIndex - 1); }}
                    disabled={!canPreviousPage}
                >
                    <i className="fas fa-angle-left" />
                </button>
                <button
                    className="btn btn-flat-light btn-short ml-1"
                    onClick={() => { nextPage(); onPageChange(pageIndex + 1); }}
                    disabled={!canNextPage}
                >
                    <i className="fas fa-angle-right" />
                </button>
                <button
                    className="btn btn-flat-light btn-short ml-1"
                    onClick={() => { gotoPage(pageCount - 1); onPageChange(pageCount - 1); }}
                    disabled={!canNextPage}
                >
                    <i className="fas fa-angle-double-right" />
                </button>
            </span>
            {pageCount > 1 && (
                <span className="mx-2">
                    <span className="mr-2">{translate("common.page", "Page")}:</span>
                    <Select
                        className="d-inline-block"
                        menuPlacement="auto"
                        menuPosition="fixed"
                        components={{
                            IndicatorSeparator: null
                        }}
                        options={pageOptions.map(i => ({
                            label: i + 1,
                            value: i
                        }))}
                        value={{
                            label: pageIndex + 1,
                            value: pageIndex
                        }}
                        theme={theme => ({
                            ...theme,
                            spacing: {
                                ...theme.spacing,
                                baseUnit: 2,
                                controlHeight: 30
                            }
                        })}
                        styles={{
                            control: base => ({ ...base, minWidth: 70 })
                        }}
                        onChange={option => { gotoPage(option.value); onPageChange(option.value); }}
                    />
                </span>
            )}
            <span className="mx-2">
                <span className="mr-2">{translate("common.rows_per_page", "Rows per page")}:</span>
                <Select
                    className="d-inline-block"
                    menuPlacement="auto"
                    menuPosition="fixed"
                    components={{
                        IndicatorSeparator: null
                    }}
                    options={pageSizes.map(size => ({
                        label: size,
                        value: size
                    }))}
                    value={{
                        label: pageSize,
                        value: pageSize
                    }}
                    theme={theme => ({
                        ...theme,
                        spacing: {
                            ...theme.spacing,
                            baseUnit: 2,
                            controlHeight: 30
                        }
                    })}
                    styles={{
                        control: base => ({ ...base, minWidth: 70 })
                    }}
                    onChange={option => {
                        setPageSize(option.value);
                        onPageSizeChange(option.value);
                    }}
                />
            </span>
            <span className="mx-2">
                {row_start} - {row_end}
                {" "}
                {translate("common.of", "of")}
                {" "}
                {num_rows}
            </span>
        </div>
    );
};

function ReactTable<T,>(props: Props<T>) {
    const {
        data,
        columns,
        initialState,
        onCellClick = (cell) => cell,
        hiddenColumns,
        filters = {},
        onChangeCell = null,
        rowStyle = (a: any) => {},
        rowClassName = (a: any) => null,
        ExpandableRow = null,
        table_id = "",
        LastRow = null,
        maxRows,
        getCellProps = (cell: any) => ({}),
        Pagination = null
    } = props;

    let isSticky = props.isSticky || true;

    if (props.className && props.className.includes("tags-react-table")) {
        isSticky = false;
    }

    const defaultColumn = useMemo(() => ({
        Filter: (props) => {
            return <DefaultColumnFilter filters={filters} {...props} />
        }
    }), [filters]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows: all_rows,
        prepareRow,
        page: page_rows,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState,
            defaultColumn,
            NoDataComponent: () => "No data",
            noDataText: (<span>no data</span>)
        },
        useFilters,
        useSortBy,
        usePagination
    );

    if (props.isLoading) {
        return <Loader small={props.small_loader} />
    }

    const rows = Pagination ? page_rows : all_rows;
    const pagination_props = {
        num_rows: all_rows.length,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        pageIndex,
        pageSize,
        pageSizes: [10, 25, 50, 100]
    };
    const numHeaders = headerGroups && headerGroups[0].headers && headerGroups[0].headers.length;
    const className = `table react-table ${props.className || ""}`;
    const tableStyle = isSticky ? getTableStyle() : {};

    return (
        <React.Fragment>
            <table id={table_id} className={className} {...getTableProps()} style={tableStyle}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => {
                                if (hiddenColumns && hiddenColumns.includes(column.id)) return null;

                                let sorting_props = {}
                                if (column.enableSorting) {
                                    sorting_props = column.getSortByToggleProps();
                                }

                                const { onClick, style, ...header_props } = column.getHeaderProps(sorting_props);
                                const th_style = {
                                    ...(style || {}),
                                    ...(isSticky ? getStickyTh() : {})
                                };

                                if (!column.canFilter && onClick) {
                                    header_props.onClick = onClick;
                                }

                                if (column.title !== undefined) {
                                    header_props.title = column.title;
                                } else if (column.enableSorting) {
                                    header_props.title = column.canFilter ? "" : translate("common.toggle_sorting", "Toggle sorting");
                                }

                                if (th_style.cursor && column.canFilter) {
                                    th_style.cursor = "default";
                                }

                                return (
                                    <th {...header_props} name={column.id} style={th_style}>
                                        {column.render("Header")}
                                        {column.enableSorting && !column.canFilter && (
                                            <React.Fragment>
                                                {" "}
                                                {generateSortingIndicator(column)}
                                            </React.Fragment>
                                        )}
                                        {/* Render the columns filter UI */}
                                        {column.canFilter && (
                                            <div className="d-flex align-items-center">
                                                {column.filter_type === "date" ? column.render("DateFilter", filters || {}) : column.render("Filter", { filters })}
                                                {column.enableSorting && (
                                                    <span
                                                        className="p-2"
                                                        onClick={onClick}
                                                        style={{ cursor: "pointer" }}
                                                        title={translate("common.toggle_sorting", "Toggle sorting")}
                                                    >
                                                        {generateSortingIndicator(column, true)}
                                                    </span>
                                                )}
                                            </div>
                                        )}
                                    </th>
                                )
                            })}
                        </tr>
                    ))}
                </thead>
                {
                    rows.length === 0 || data.length === 0 ?
                    <tbody><tr><td colSpan={numHeaders}><ErrorComponent type="no-data" msg={props.error_msg || translate("common.no_data", "No data")} /></td></tr></tbody> :
                    <tbody {...getTableBodyProps()}>
                        {rows.slice(0, maxRows ? maxRows : undefined).map((row, i) => {
                            prepareRow(row);
                            let style = rowStyle(row);
                            let className = rowClassName(row);
                            return (
                                <React.Fragment key={"rf_rt" + i}>
                                    <tr {...row.getRowProps()} className={className} style={{ ...style }} key={"rt" + i}>
                                        {row.cells.map((cell, j) => {
                                            if (hiddenColumns && hiddenColumns.includes(cell.column.id)) return null;
                                            let className = null;
                                            const column = columns[j];
                                            if (column.textAlign !== undefined) {
                                                className = (column.textAlign === "right" ? "right" : (column.textAlign === "center" ? "center" : null))
                                            }

                                            if (column.override_default_td_cell) {
                                                return cell.render("Cell", { onChangeCell, cell })
                                            }

                                            return (
                                                <td
                                                    {...cell.getCellProps([
                                                        {
                                                            style: {
                                                                maxWidth: "200px",
                                                                wordWrap: "break-word"
                                                            },
                                                            className
                                                        },
                                                        getCellProps(cell)
                                                    ])}
                                                    name={cell.column.id}
                                                    onClick={() => onCellClick && onCellClick(cell)}
                                                    key={`rtc_${i}_${j}`}
                                                >
                                                    {cell.render("Cell", { onChangeCell })}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                    {ExpandableRow && <ExpandableRow row={row} />}
                                </React.Fragment>
                            );
                        })}
                        {LastRow && <LastRow rows={rows} />}
                    </tbody>
                }
            </table>
            {Pagination === true
                ? <DefaultPagination {...pagination_props} />
                : Pagination && <Pagination {...pagination_props} />}
        </React.Fragment>
    )
}


export default ReactTable;
