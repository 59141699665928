// @flow
import * as React from "react";

import { getBackend as getBackend2 } from "../lib/backend/Backend2";
import * as t from "../lib/backend/manufacturing2.generated.types";

type Props = {};

type State = {
    error: string | null,
    data: t.IGetBillingStatisticsRecords[] | null,
};

class Statistics extends React.Component<Props, State> {

    constructor() {
        super();
        const initialState: State = {
            data: null,
            error: null,
        };
        this.state = initialState;
    }

    componentDidMount() {
        this.loadStandardData();
    }

    async loadStandardData() {
        try {
            const res = await getBackend2().manufacturing.getBillingStatistics({});
            this.setState({ data: res.data });
        } catch(error) {
            this.setState({ error: error });
        }

    }

    
    
    render() {
        return (
            <React.Fragment>
                <h3 className="table_header" key="xyz125">
                    Statistics
                </h3>
                <div className="key-status table_content width-full">
                    <table id="datatable-responsive" className="table table-timeline dt-responsive dataTable no-footer dtr-inline" cellSpacing="0" width="100%">
                        <thead>
                            <tr>
                                <th className="text-center">
                                    Type
                                </th>
                                <th className="text-center">
                                    #
                                </th>
                                <th className="text-center">
                                    Open orders
                                </th>
                                <th className="text-center" colspan="4">
                                    Production
                                </th>
                                <th className="text-center" colspan="4">
                                    People
                                </th>
                            </tr>
                            <tr>
                                <th className="text-center"></th>
                                <th className="text-center"></th>
                                <th className="text-center"></th>
                                <th className="text-center">Current</th>
                                <th className="text-center">-1</th>
                                <th className="text-center">-2</th>
                                <th className="text-center">-3</th>
                                <th className="text-center">+1</th>
                                <th className="text-center">Current</th>
                                <th className="text-center">-1</th>
                                <th className="text-center">-2</th>
                                <th className="text-center">-3</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.data && this.state.data.map((v) => {
                                    return (
                                        <tr>
                                            <td>
                                                <p className="data_source_text text-center">
                                                    <span>{v.type}</span>
                                                </p>
                                            </td>
                                            <td>
                                                <p className="data_source_text text-center">
                                                    <span>{v.count}</span>
                                                </p>
                                            </td>
                                            <td>
                                                <p className="data_source_text text-center">
                                                    <span>{v.open_orders}</span>
                                                </p>
                                            </td>
                                                {
                                                    v.sum_types && v.sum_types.length > 0 ? 
                                                    v.sum_types.map((e, i) =>
                                                            {
                                                                return (
                                                                    <>
                                                                        <td>
                                                                            <p className="data_source_text text-center">
                                                                                <span>{
                                                                                    e.month === i && e.sum
                                                                                    }</span>
                                                                            </p>
                                                                        </td>
                                                                    </>
                                                                )
                                                            }
                                                        )
                                                    :
                                                    <>
                                                        <td>
                                                            <p className="data_source_text text-center">
                                                                <span>/</span>
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p className="data_source_text text-center">
                                                                <span>/</span>
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p className="data_source_text text-center">
                                                                <span>/</span>
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p className="data_source_text text-center">
                                                                <span>/</span>
                                                            </p>
                                                        </td>
                                                    </>
                                                }
                                                {
                                                    v.people && v.people.length > 0 ? 
                                                    v.people.map((e, i) =>
                                                            {
                                                                console.log(e.week, i);
                                                                return (
                                                                    <>
                                                                        <td>
                                                                            <p className="data_source_text text-center">
                                                                                <span>{
                                                                                    e.count}</span>
                                                                            </p>
                                                                        </td>
                                                                    </>
                                                                )
                                                            }
                                                        )
                                                    :
                                                    <>
                                                        <td>
                                                            <p className="data_source_text text-center">
                                                                <span>/</span>
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p className="data_source_text text-center">
                                                                <span>/</span>
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p className="data_source_text text-center">
                                                                <span>/</span>
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p className="data_source_text text-center">
                                                                <span>/</span>
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p className="data_source_text text-center">
                                                                <span>/</span>
                                                            </p>
                                                        </td>
                                                    </>
                                                }
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </React.Fragment>
        );
    }
}

export default Statistics;
