// @flow
import * as t from "../../../../lib/backend/manufacturing2.generated.types";
import { ORDER_TAGS_ACCESS, PLANT_TAGS_ACCESS } from "../../../../lib/ManufacturingTags.generated";
import * as BusinessLogic from "../../../../lib/BusinessLogic";
import { getBackend as getBackend2 } from "../../../../lib/backend/Backend2";
import { arrayIntersection } from "../../../../lib/Util";
import { LinesOrdersMaps } from "./Mappers";

import type {
    LineOrders
} from "./linesOrders";

const insertIntoMap = (map: Map<string, string[]>, key: string, value: string): Map<string, string[]> => {
    const arr = map.get(key);
    if (arr) {
        arr.push(value);
    } else {
        map.set(key, [value]);
    }
    return map;
}

const getValidLineUuids = (
    operation_uuid: string,
    operations_line_uuids_map: Map<string, string[]> | null
): string[] | null => {
    let line_uuids = null;
    if (operations_line_uuids_map !== null) {
        line_uuids = operations_line_uuids_map.get(operation_uuid) || null;
        if (line_uuids !== null) {
            // Get intersection with valid lines of all trailing operations
            const rework_operations = LinesOrdersMaps.base_uuid_rework_orders.get(operation_uuid) || [];
            const rework_operation_uuids = rework_operations.map(o => o.uuid);
            for (const rework_uuid of rework_operation_uuids) {
                const rework_line_uuids = operations_line_uuids_map.get(rework_uuid);
                if (rework_line_uuids !== undefined) {
                    line_uuids = arrayIntersection(line_uuids, rework_line_uuids);
                }
            }
        }
    }

    return line_uuids;
};

const getValidOperationUuids = (
    line_uuid: string,
    operations_line_uuids_map: Map<string, string[]> | null,
    lines_operations_uuids_map: Map<string, string[]> | null
): string[] | null => {
    let operation_uuids = null;
    if (lines_operations_uuids_map !== null) {
        operation_uuids = lines_operations_uuids_map.get(line_uuid) || null;
        if (operation_uuids !== null) {
            const new_operation_uuids = [];
            for (const operation_uuid of operation_uuids) {
                const base_operation = LinesOrdersMaps.rework_uuid_base_order.get(operation_uuid);
                if (base_operation !== undefined) {
                    // Trailing operation is not valid
                    continue;
                }

                const rework_operations = LinesOrdersMaps.base_uuid_rework_orders.get(operation_uuid);
                if (rework_operations !== undefined) {
                    // Leading operation is valid only if intersection with valid lines of all
                    // trailing operations includes current line
                    const valid_line_uuids = getValidLineUuids(operation_uuid, operations_line_uuids_map);
                    if (valid_line_uuids !== null && valid_line_uuids.includes(line_uuid)) {
                        new_operation_uuids.push(operation_uuid);
                    }
                } else {
                    // If operation is nor leading nor trailing, it is valid
                    new_operation_uuids.push(operation_uuid);
                }
            }

            operation_uuids = new_operation_uuids;
        }
    }

    return operation_uuids;
};

export class ValidLinesOrdersHelper {

    static lines_order_uuids_map: Map<string, string[]> | null = null;
    static orders_line_uuids_map: Map<string, string[]> | null = null;
    static prefered_lines_order_uuids_map: Map<string, string[]> | null = null;
    static prefered_orders_line_uuids_map: Map<string, string[]> | null = null;

    static getOperationsFromLineOrders = (lines_orders: LineOrders[]) => {
        const operations = [];
        for (const line_order of lines_orders) {
            for (const order of line_order.orders) {
                const operation = {
                    op_uuid: order.uuid,
                    order_external_id: order.external_id,
                    fixed_line_uuid: ORDER_TAGS_ACCESS.fix_line(order.tags) || undefined
                }
                operations.push(operation);
            }
        }
        return operations;
    }

    static fillLinesAndOrdersMaps = async (
        lines_orders: LineOrders[],
        unscheduled_orders: LineOrders[],
        ignored_orders: LineOrders[]
    ) => {
        const plant_uuid = localStorage.getItem("last-plant");
        if (!plant_uuid) {
            return;
        }
        const plant_tags = BusinessLogic.getPlantTags(plant_uuid);

        const operations = [
            ...ValidLinesOrdersHelper.getOperationsFromLineOrders(lines_orders),
            ...ValidLinesOrdersHelper.getOperationsFromLineOrders(unscheduled_orders),
            ...ValidLinesOrdersHelper.getOperationsFromLineOrders(ignored_orders)
        ];

        const constraints = {
            fix_unplanned_ops: false,
            ignore_operation_fix_line_constraint: true,
            planned_ord_same_bom: true,
            planned_ord_same_op_structure: true,
            prod_ord_same_bom: true,
            prod_ord_same_op_structure: true,
            prod_ord_can_change_line: false
        }
        const params: t.IGetCompatibleProductionVersionsParams = {
            operations: operations,
            use_production_versions: PLANT_TAGS_ACCESS.planning_table_production_versions(plant_tags),
            constraints
        }

        // $FlowFixMe
        const res = await getBackend2().manufacturing.getOperationLineMatrix(params);

        let lines_order_uuids_map = new Map<string, string[]>();
        let orders_line_uuids_map = new Map<string, string[]>();
        let prefered_lines_order_uuids_map = new Map <string, string[]> ();
        let prefered_orders_line_uuids_map = new Map <string, string[]> ();
        for (const row of res.operation_lines) {
            orders_line_uuids_map.set(row.operation_uuid, row.lines.map(l => l.line_uuid));
            for (const line_row of row.lines) {
                lines_order_uuids_map = insertIntoMap(lines_order_uuids_map, line_row.line_uuid, row.operation_uuid);
                const is_prefered = line_row.preferred;
                if (is_prefered) {
                    prefered_lines_order_uuids_map = insertIntoMap(prefered_lines_order_uuids_map, line_row.line_uuid, row.operation_uuid);
                    prefered_orders_line_uuids_map = insertIntoMap(prefered_orders_line_uuids_map, row.operation_uuid, line_row.line_uuid);
                }
            }
        }

        ValidLinesOrdersHelper.lines_order_uuids_map = lines_order_uuids_map;
        ValidLinesOrdersHelper.orders_line_uuids_map = orders_line_uuids_map;
        ValidLinesOrdersHelper.prefered_lines_order_uuids_map = prefered_lines_order_uuids_map;
        ValidLinesOrdersHelper.prefered_orders_line_uuids_map = prefered_orders_line_uuids_map;
    }

    static getLineUuids = (operation_uuid: string): string[] | null => {
        return getValidLineUuids(operation_uuid, ValidLinesOrdersHelper.orders_line_uuids_map);
    };

    static getOperationUuids = (line_uuid: string): string[] | null => {
        return getValidOperationUuids(
            line_uuid,
            ValidLinesOrdersHelper.orders_line_uuids_map,
            ValidLinesOrdersHelper.lines_order_uuids_map
        );
    };

    static getPreferredLineUuids = (operation_uuid: string): string[] | null => {
        return getValidLineUuids(operation_uuid, ValidLinesOrdersHelper.prefered_orders_line_uuids_map);
    };

    static getPreferredOperationUuids = (line_uuid: string): string[] | null => {
        return getValidOperationUuids(
            line_uuid,
            ValidLinesOrdersHelper.prefered_orders_line_uuids_map,
            ValidLinesOrdersHelper.prefered_lines_order_uuids_map
        );
    };
}
