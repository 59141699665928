// @flow

import { ApiClient, COMMON_HEADERS } from "./restApi";
import { RestFetchApi } from "./restFetchApi";
import axios from "axios";

/** ApiClient that uses axios library underneath */
export class ApiClientAxios implements ApiClient {

    axiosInstance: axios.AxiosInstance;
    fetchInstance: ApiClient;

    constructor() {
        this.axiosInstance = axios.create({
            headers: COMMON_HEADERS
        });
        this.fetchInstance = new RestFetchApi();
    }

    get(url: string, params?: any): Promise<any> {
        return this.axiosInstance.get(url, { params });
    }

    post(url: string, body: any): Promise<any> {
        if (url.startsWith("/api/v1.0/reports/quick-simulation-sync")) {
            return this.fetchInstance.post(
                url,
                body
            );
        }
        return this.axiosInstance.post(url, body);
    }

    put(url: string, body: any): Promise<any> {
        return this.axiosInstance.put(url, body);
    }

    delete(url: string, params?: any): Promise<any> {
        return this.axiosInstance.delete(url, { params });
    }

    setRequestHeader(key: string, val: string | null): void {
        this.axiosInstance.defaults.headers.common[key] = val;
        this.fetchInstance.setRequestHeader(key, val);
    }

    /** Allow access to underlaying instance for setting interceptors */
    getAxiosInstance(): axios.AxiosInstance {
        return this.axiosInstance;
    }

    abort(): void {
        if (this.fetchInstance) {
            this.fetchInstance.abort();
        }
    }
}

