// @flow

import * as React from "react";

type Props = {
    children?: React.Node
};

type State = {
    x: number,
    y: number,
    top: number,
    left: number
};

function getEventHandler(element: React.Element<Object>, handlerName: string, handler: (...args: any) => void) {
    const existingHandler = element.props && element.props[handlerName];

    if (typeof existingHandler !== "function") {
        return handler;
    }

    return (...args: any) => {
        existingHandler(...args);
        handler(...args);
    };
}

class DraggableModal extends React.Component<Props, State> {
    container_ref: HTMLElement;
    dialog_ref: HTMLElement;
    modal_ref: HTMLElement;

    constructor(props: Props) {
        super(props);
        this.state = {
            x: 0,
            y: 0,
            top: 0,
            left: 0
        };
    }

    initialize = (target: HTMLElement) => {
        const modal_ref = target.closest(".modal");
        if (!modal_ref || !(modal_ref instanceof HTMLElement)) {
            return;
        }

        this.modal_ref = modal_ref;

        const container_ref = modal_ref.parentNode;
        if (container_ref instanceof HTMLElement) {
            this.container_ref = container_ref;
        }

        const dialog_ref = modal_ref.children[0];
        if (dialog_ref instanceof HTMLElement) {
            this.dialog_ref = dialog_ref;
        }

        const dialog_rect = this.dialog_ref.getBoundingClientRect();
        this.container_ref.classList.add("is-initialized");
        this.dialog_ref.style.top = `${dialog_rect.top}px`;
        this.dialog_ref.style.left = `${dialog_rect.left}px`;
        this.setState({
            top: dialog_rect.top,
            left: dialog_rect.left
        });
    };

    handleMouseDown = (event: SyntheticMouseEvent<HTMLDivElement>) => {
        const target = event.target;
        if (!(target instanceof HTMLElement)) {
            return;
        }

        const header = target.closest(".modal-header");
        if (!(header instanceof HTMLElement) || !header.classList.contains("modal-header")) {
            return;
        }

        this.initialize(header);

        this.container_ref.addEventListener("mousemove", this.handleMouseMove);
        this.container_ref.addEventListener("mouseup", this.handleMouseUp);
        this.setState({
            x: event.clientX,
            y: event.clientY
        });
    };

    handleMouseMove = (event: MouseEvent) => {
        const diff_x = event.clientX - this.state.x;
        const diff_y = event.clientY - this.state.y;
        const top = this.state.top + diff_y;
        const left = this.state.left + diff_x;
        this.dialog_ref.style.top = `${top}px`;
        this.dialog_ref.style.left = `${left}px`;
        this.setState({
            y: event.clientY,
            x: event.clientX,
            top,
            left
        });
    };

    handleMouseUp = (event: MouseEvent) => {
        this.container_ref.removeEventListener("mousemove", this.handleMouseMove);
        this.container_ref.removeEventListener("mouseup", this.handleMouseUp);
    };

    render() {
        const { children } = this.props;

        if (!children) {
            return null;
        }

        return (
            <React.Fragment>
                {React.Children.map(children, (child, i) => {
                    if (React.isValidElement(child)) {
                        return React.cloneElement(child, {
                            ref: ref => {
                                setTimeout(() => {
                                    if (ref !== null && ref._modal && ref._modal.modalNode) {
                                        const modal_node: HTMLElement = ref._modal.modalNode;
                                        modal_node.classList.add("draggable-modal");
                                    }
                                }, 0);
                            },
                            onMouseDown: getEventHandler(child, "onMouseDown", this.handleMouseDown)
                        });
                    }

                    return child;
                })}
            </React.Fragment>
        );
    }
}

export default DraggableModal;
