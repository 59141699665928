// @flow
import * as React from "react";

type Props = {
    label: string,
    size: string
};
type State = { };

class Label extends React.Component<Props, State> {
    constructor() {
        super();
        this.state = { label: "Label" };
    }

    render() {
        if (this.props.size) {
            if (this.props.size === "tiny") {
                return (<span className="label label-tiny">{this.props.label}</span>);
            } else if (this.props.size === "small") {
                return (<span className="label label-small">{this.props.label}</span>);
            } else if (this.props.size === "big") {
                return (<span className="label label-big">{this.props.label}</span>);
            }
        }
        return (<span className="label">{this.props.label}</span>);
    }
}

export default Label;
