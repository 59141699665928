// @flow
import React from "react";
// $FlowFixMe
import "../../styles/react/editable_text_area.scss"

type Props = {
    value: string,
    title: string,
    isDisabled: boolean,
    onChange: (value: string) => void
};

type State = {};

export default class EditableTextArea extends React.Component<Props, State> {
    onChange = (event: SyntheticEvent<HTMLTextAreaElement>) => {
        const content = event.currentTarget.value;
        this.props.onChange(content);
    };

    render() {
        return <div className="editable-text-area">
            <textarea
                disabled={this.props.isDisabled ? true : undefined}
                className="form-control"
                value={this.props.value}
                onChange={this.onChange}
                title={this.props.title}
                style={{ caretColor: "black", color: "black" }}
            />
        </div>
    }
}

