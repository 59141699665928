// @flow
import React from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import DraggableModal from "../../DraggableModal";
import { translate } from "../../IntlProviderWrapper";
import ReactTable from "../../react/ReactTable";
import { LinesOrdersMaps } from "./reducers/Mappers";
import { UPDATE_CHUNKED_ORDERS } from "./reducers/linesOrders";
import { classNames } from "../../../lib/Util";
import * as t from "../../../lib/backend/manufacturing2.generated.types";

import type { Cell } from "react-table";
import type { LineOrders } from "./reducers/linesOrders";
import type { ReduxState } from "./reducers/reducers";
import type { UpdateChunkedOrderAction } from "./reducers/linesOrders";

// $FlowFixMe
import "./ChunkingModal.scss";
import { ORDER_TAGS } from "../../../lib/ManufacturingTags.generated";
import { getBackend } from "../../../lib/backend/Backend2";
import { UserActions } from "./reducers/BusinessLogic";
import ErrorComponent from "../../ErrorComponent";

type InputProps = {
    idx: number,
    cell: Cell<any>,
    value: String,
    onChangeCell: (idx: number) => (e: Event) => void
}
const Input = (props: InputProps) => {
    const {
        cell,
        value,
        onChangeCell
    } = props;
    const idx  = cell.row.values.idx;
    return <input
        key={"quantity-total-" + idx}
        id={"quantity-total-" + idx}
        className={classNames("form-control")}
        type="number"
        min={cell.row.values.quantity_produced}
        value={value}
        onChange={(e) => onChangeCell(idx)(e)}
    />
}

const getParentOrderColumns = () => {
    return [
        {
            Header: translate("OrderTableProduction.external_id", "Order ID"),
            accessor: "external_id",
            disableFilters: true
        },
        {
            Header: translate("common.process_num2", "Op."),
            accessor: "process_num",
            disableFilters: true
        },
        {
            Header: translate("common.produced", "Produced"),
            accessor: "quantity_produced",
            disableFilters: true
        },
        {
            Header: translate("common.open", "Open"),
            accessor: "quantity_open",
            disableFilters: true
        },
        {
            Header: translate("common.total", "Total"),
            accessor: "quantity_total",
            disableFilters: true
        },
    ]
}

const getChunkOrdersColumns = () => {
    return [
        {
            Header: translate("common.id", "ID"),
            accessor: "idx",
            disableFilters: true
        },
        {
            Header: translate("common.produced", "Produced"),
            accessor: "quantity_produced",
            disableFilters: true
        },
        {
            Header: translate("common.open", "Open"),
            accessor: "quantity_open",
            disableFilters: true,
            Cell: Input
        },
        {
            Header: translate("common.line", "Line"),
            accessor: "line_title",
            disableFilters: true
        },
        {
            Header: "",
            accessor: "disable_remove",
            disableFilters: true,
            Cell: ({ value, onChangeCell, cell }) => {
                const disable_remove = cell.row.values.disable_remove;

                return <button disabled={disable_remove} className="btn btn-primary">
                    {translate("common.remove", "Remove")}
                </button>
            }
        },
        {
            Header: "uuid",
            accessor: "uuid",
            disableFilters: true
        },
    ]
}

type ChunkOrderState = $Shape<t.IOrderProducedModel & {
    idx: number,
    has_error: boolean,
    remove?: void,
    quantity_open: number
}>
type Props = {
    lines_orders: LineOrders,
    show: boolean,
    onClose: () => void,
    order_uuid: string,
    split_orders: t.ILinePlanningSplitOrder[],
    reduxDispatch: (args: UpdateChunkedOrderAction) => void
};
type State = {
    split_orders_chunks: ChunkOrderState[],
    removable_chunks: number[]
};

class ChunkingModal extends React.Component<Props, State> {
    state = {
        split_orders_chunks: [],
        removable_chunks: []
    }

    componentDidMount() {
        this.load();
    }

    componentDidUpdate(prev_props: Props) {
        if (prev_props.show !== this.props.show && this.props.show) {
            this.load();
        }
    }

    async load() {
        let parent_order_uuid = this.getOrderUuid();
        const splits = this.props.split_orders.filter(c => c.parent.uuid === parent_order_uuid)[0];
        if (splits) {
            this.setState({
                split_orders_chunks: splits.chunks.map((c, idx) => ({
                    ...c,
                    idx,
                    has_error: false,
                    quantity_open: c.quantity_total - c.quantity_produced
                })),
            })
        } else {
            const parent_order = this.getParentOrder();
            if (!parent_order) {
                return;
            }
            // compute the chunk quantities, if large numbers, make sure we split roundly, else just split in half
            const quantity_to_split = parent_order.quantity_total - parent_order.quantity_produced;
            const chunk_quantities = quantity_to_split> 2 ? [
                Math.round(quantity_to_split / 2),
                quantity_to_split - Math.round(quantity_to_split / 2)
            ] : [
                quantity_to_split / 2,
                quantity_to_split / 2
            ];
            const split_orders_chunks = [];
            for (let i = 0; i < 2; i++ ) {
                const { uuid } = await getBackend().common.getUuid({});
                split_orders_chunks.push({
                    idx: i,
                    uuid,
                    external_id: parent_order.external_id,
                    process_num: parent_order.process_num,
                    quantity_produced: 0,
                    quantity_total: chunk_quantities[i],
                    quantity_open: chunk_quantities[i], // because quantity_produed = 0 quantity_open = quantity_total
                    line_uuid: parent_order.line_uuid,
                    parent_order_uuid: parent_order.uuid,
                    has_error: false
                })
            }
            this.setState({
                split_orders_chunks
            });
        }

    }

    getLineTitle = (line_uuid: string) => {
        return this.props.lines_orders.line_title;
    }

    getParentOrderRow = () => {
        const parent_order = this.getParentOrder();
        if (!parent_order) {
            return [];
        }
        const chunk_orders = this.state.split_orders_chunks;
        const sum = chunk_orders.reduce((acc, c) => acc + c.quantity_produced, 0);
        const produced = parent_order.quantity_produced + sum
        const quantity_open = parent_order.quantity_total - produced;
        return [{
            external_id: parent_order.external_id,
            process_num: parent_order.process_num,
            quantity_produced: produced,
            quantity_total: parent_order.quantity_total,
            quantity_open,
        }]
    }

    getParentOrder = () => {
        const parent = LinesOrdersMaps.parents_of_chunks.get(this.getOrderUuid());
        const order = LinesOrdersMaps.all_orders_map.get(this.getOrderUuid());
        // a parent order is either an existing parent order or a fresh order without chunks
        return parent || order;
    }

    onClickRemove = (uuid: string) => {
        const parent_order = this.getParentOrder();
        if (!parent_order) return;
        const chunk_orders = this.state.split_orders_chunks;
        const index = chunk_orders.findIndex(c => c.uuid === uuid);
        chunk_orders.splice(index, 1);
        this.setState({ split_orders_chunks: [...chunk_orders]});
    }

    onClickPlus = async () => {
        const parent_order = this.getParentOrder();
        if (!parent_order) return;
        const chunk_orders = this.state.split_orders_chunks;
        const sum = chunk_orders.reduce((acc, c) => acc + c.quantity_open, 0);
        const idx = Math.max(0, chunk_orders.length) + 1;
        const { uuid } = await getBackend().common.getUuid({});
        const parent_quantity_open = parent_order.quantity_total - parent_order.quantity_produced;
        const quantity_total = idx > 0 ? Math.max(parent_quantity_open - sum, 0) : parent_quantity_open;
        chunk_orders.push({
            idx,
            uuid,
            external_id: parent_order.external_id,
            process_num: parent_order.process_num,
            quantity_produced: 0,
            quantity_total,
            quantity_open: quantity_total,  // because quantity_produced is 0
            line_uuid: parent_order.line_uuid,
            parent_order_uuid: parent_order.uuid,
            has_error: false
        });

        this.setState({
            split_orders_chunks: chunk_orders,
            removable_chunks: [...this.state.removable_chunks, idx]
        });
    }

    checkSum = () => {
        const parent_order = this.getParentOrder();
        if (!parent_order) return { is_sum_ok: false, missing: 0};
        const chunk_orders = this.state.split_orders_chunks;
        const sum = chunk_orders.reduce((acc, c) => acc + c.quantity_open, 0);
        const quantity_produced = parent_order.quantity_produced +
            chunk_orders.reduce((acc, c) => acc + c.quantity_produced, 0);
        return {
            is_sum_ok: sum === (parent_order.quantity_total - quantity_produced),
            missing: parent_order.quantity_total - quantity_produced - sum
        }
    }

    onChangeCell = (idx: number) => (e: SyntheticEvent<HTMLInputElement>) => {
        const chunk_order = this.state.split_orders_chunks.find(c => c.idx === idx);
        if (chunk_order) {
            chunk_order.quantity_open = parseInt(e.currentTarget.value);
            chunk_order.quantity_total = chunk_order.quantity_open + chunk_order.quantity_produced;
            this.setState({ split_orders_chunks: [...this.state.split_orders_chunks] })
        }
    }

    onCellClick = (cell: Cell<any>) => {
        const is_remove = cell.column.id === "disable_remove";
        const uuid = cell.row.values.uuid;
        if (is_remove) {
            this.onClickRemove(uuid);
        }

    }

    getOrderUuid = (): string => {
        const chunk_order = (
            LinesOrdersMaps.all_orders_map.get(this.props.order_uuid)
        )
        if (chunk_order && chunk_order.parent_order_uuid) {
            return chunk_order.parent_order_uuid;
        }
        return this.props.order_uuid;
    }

    onClickOk = () => {
        const has_error = this.hasError();
        if (has_error) {
            return;
        }

        const chunked_orders = this.state.split_orders_chunks.map(c => ({
            uuid: c.uuid,
            parent_order_uuid: c.parent_order_uuid,
            quantity_total: c.quantity_total,
            line_uuid: c.line_uuid,
            quantity_produced: c.quantity_produced,
            external_id: c.external_id,
            process_num: c.process_num
        }));

        const parent_order = this.getParentOrder();

        if (parent_order) {
            this.props.reduxDispatch({
                type: UPDATE_CHUNKED_ORDERS,
                data: {
                    parent_order_uuid: parent_order.uuid || "",
                    chunked_orders
                }
            })
            if (chunked_orders.length === 0) {
                // $FlowFixMe
                UserActions.onOrderClick({
                    order_uuid: parent_order.uuid,
                    order_external_id: parent_order.external_id,
                    source_type: "planning_table_parallel",
                    is_extra_line: false,
                    is_ctrl_down: false,
                    advanced_order_menu: null,
                    process_num: parent_order.process_num,
                    reduxDispatch: this.props.reduxDispatch,
                    is_readonly: false,
                    is_filter_locked: false
                });
            }
        }
        this.props.onClose();
    }

    hasError = () => {
        if (this.state.split_orders_chunks.length === 0) {
            return false;
        }
        return !this.checkSum().is_sum_ok || this.state.split_orders_chunks.some(c => c.quantity_total < c.quantity_produced);
    }

    getError = () => {
        const has_error = this.hasError();
        if (has_error) {
            const msg = <React.Fragment>
                <p>
                    {translate("chunking_modal.error", "Some chunk orders have incorrect quantities.")}
                    {" " + translate("common.deviation", "Deviation") + ": " + this.checkSum().missing} <br/>
                </p>
            </React.Fragment>

            return <ErrorComponent
                msg={msg}
                type="warning"
            />
        }
        return null;
    }

    getChunkData = () => {
        return this.state.split_orders_chunks.map(c => {
            const order = LinesOrdersMaps.all_orders_map.get(c.uuid);
            return {
                ...c,
                line_title: this.getLineTitle(c.line_uuid),
                disable_remove: order && order.tags[ORDER_TAGS.split_chunk_index] // it is in our db
            }
        })
    }

    render() {
        const has_error = this.hasError();
        return <DraggableModal>
            <Modal
                show={this.props.show}
            >
                <Modal.Header>
                    <Modal.Title>
                        {translate("common.chunk_orders", "Chunk orders")}
                    </Modal.Title>
                    <button type="button" className="close" onClick={this.props.onClose}>
                        <span aria-hidden="true">×</span>
                        <span className="sr-only">{translate("common.close", "Close")}</span>
                    </button>
                </Modal.Header>
                <Modal.Body id="chunking-modal">
                    <ReactTable
                        data={this.getParentOrderRow()}
                        columns={getParentOrderColumns()}
                    />
                    <ReactTable
                        data={this.getChunkData()}
                        columns={getChunkOrdersColumns()}
                        onChangeCell={this.onChangeCell}
                        onCellClick={this.onCellClick}
                        hiddenColumns={["uuid"]}
                    />
                    {this.getError()}
                   <button className="btn btn-primary" onClick={this.onClickPlus} style={{width: "100%"}}>
                        +
                    </button>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn btn-primary"
                        onClick={this.onClickOk}
                        disabled={has_error}
                    >
                        {translate("common.ok", "OK")}
                    </button>
                </Modal.Footer>
            </Modal>
        </DraggableModal>
    }
}

export default connect(
    (state: ReduxState) => {
        const split_orders = state.gantt_chart_lines_orders.split_orders;
        const lines_orders = state.gantt_chart_lines_orders.lines_orders;

        return {
            split_orders,
            lines_orders
        }
    },
    (dispatch) => ({reduxDispatch: dispatch})
)(ChunkingModal)

