// @flow
import * as React from "react";
import Toggle from "react-toggle";
import Select from "react-select";
import { Modal, Tabs, Tab, FormGroup, ControlLabel} from "react-bootstrap";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { RecommendPlanLogic } from "./reducers/BusinessLogic";
import { SET_RECOMMEND_PLAN_MODAL, SET_RECOMMEND_WARNINGS } from "./reducers/planningTable";
import * as BusinessLogic from "../../../lib/BusinessLogic";
import { VERSION_TYPE } from "./reducers/BusinessLogic";
import {
    LINE_GROUP_TAGS,
    LINE_GROUP_TAGS_ACCESS,
    ORDER_TAGS,
    PLAN_HORIZON_UNIT_AUTOMATIC_SCHEDULING,
    PLANNING_META_OPTIMIZATION
} from "../../../lib/ManufacturingTags.generated";
import {
    ORDER_TYPE,
    PLANNING_SCHEDULE_STRATEGY
} from "../../../lib/ManufacturingConsts.generated";
import { isNumber, SHIFT_CONSTS, dateFromWeekAndShift, addHours } from "../../../lib/Util";
import ErrorComponent from "../../ErrorComponent";
import { translate } from "../../IntlProviderWrapper";
import { LinesOrdersLogic } from "./reducers/BusinessLogic";
import { getBackend } from "../../../lib/backend/Backend2";
import ConfirmationModal from "../../ConfirmationModal";
import SaveConfigurationModal from "./SaveConfigurationModal";
import Authorization from "../../Authorization";
import * as Auth from "../../../lib/Auth";

import * as t from "../../../lib/backend/manufacturing2.generated.types";
import * as t2 from "../../../lib/SimulationReportModels";
import type { RecommendPlanState } from "./reducers/planningTable";
import type { LineOrders, IOrderProducedModelEx } from "./reducers/linesOrders";
import type { RunRecommendPlanParameters } from "./reducers/BusinessLogic";
import type { ReduxState } from "./reducers/reducers";
import type { ReportState } from "./reducers/report";
import type { PropertiesState } from "./reducers/properties";

export const PLANNING_MODEL_PARAMS_DEFAULT = {
    can_change_workorder_line: true
};

export const PLANNING_PARAMS_DEFAULT = {
    beam_size: 3
};

type SelectOption = {
    label: string,
    value: string
};

type Props = {
    plant_uuid: string,
    line_group_uuid: string,
    report_version_type: typeof VERSION_TYPE,
    report_version_title: string,
    recommend_plan: RecommendPlanState,
    lines_orders: LineOrders[],
    unscheduled_orders: LineOrders[],
    selected_line_uuids: string[],
    reduxDispatch: any => void,
    properties: PropertiesState,
    report_loading: boolean,
    report: ReportState,
    error: string | null,
    original_lines_orders: LineOrders[],
    original_unscheduled_orders: LineOrders[],
    warnings: t.IPlanWarning[]
};

export type RecommendPlanParamsAndCriteria = {
    recommend_param_beam_size: number,
    recommend_param_break_orders: boolean,
    recommend_param_can_change_workorder_line: boolean,
    recommend_param_track_input_material: boolean,
    recommend_param_use_productions_of_unscheduled_operations: boolean,
    recommend_param_use_requirements_from_unscheduled_operations: boolean,
    recommend_param_use_safety_stock: boolean,
    recommend_param_customer_priority: boolean,
    recommend_param_include_plan_orders: boolean,
    recommend_param_include_unscheduled_orders: boolean,
    recommend_param_freeze_work_orders: boolean,
    recommend_param_freeze_shifts: number,
    recommend_param_time_horizont_shifts: number,
    recommend_param_time_horizont_weeks?: number,
    recommend_param_unscheduled_offset_shifts: number,
    recommend_param_meta_optimization: string,
    recommend_param_override_shifts?: number,
    recommend_param_setup_code_change_factor: number,
    recommend_param_work_duration_factor: number,
    recommend_criteria_delays: number,
    recommend_criteria_tool_changes: number,
    recommend_criteria_intermediate_stock: number,
    recommend_strategy: string
}

type State = {
    configuration: string,
    configurations: t.ISchedulingConfiguration[],
    recommend_plan_parameters: RecommendPlanParamsAndCriteria,
    show_delete_modal: boolean,
    show_file_name_modal: boolean,
    selected_tab: string
};

const getStrategyPrefix = (strategy: string) => strategy === PLANNING_SCHEDULE_STRATEGY.rectify ? "rectify" : "recommend";

const getRecommendPlanInitialState = (line_group_uuid: string, strategy: string): RecommendPlanParamsAndCriteria => {
    const line_group_tags = BusinessLogic.getLineGroupTags(line_group_uuid);
    return {
        recommend_param_beam_size: PLANNING_PARAMS_DEFAULT.beam_size,
        recommend_param_break_orders: false,
        recommend_param_can_change_workorder_line: PLANNING_MODEL_PARAMS_DEFAULT.can_change_workorder_line,
        recommend_param_track_input_material: BusinessLogic.getLineGroupTagBool(line_group_uuid, LINE_GROUP_TAGS.planning_track_input_material, true),
        recommend_param_use_productions_of_unscheduled_operations: true,
        recommend_param_use_requirements_from_unscheduled_operations: true,
        recommend_param_use_safety_stock: true,
        recommend_param_customer_priority: false,
        recommend_param_include_plan_orders: true,
        recommend_param_include_unscheduled_orders: true,
        recommend_param_freeze_work_orders: true,
        recommend_param_freeze_shifts: 0,
        recommend_param_time_horizont_shifts: 2 * 21,
        recommend_param_unscheduled_offset_shifts: 2 * 21,
        recommend_param_meta_optimization: PLANNING_META_OPTIMIZATION.none,
        recommend_param_override_shifts: undefined,
        recommend_param_setup_code_change_factor: LINE_GROUP_TAGS_ACCESS.planning_setup_code_change_factor(line_group_tags),
        recommend_param_work_duration_factor: LINE_GROUP_TAGS_ACCESS.planning_work_duration_factor(line_group_tags),
        recommend_criteria_delays: 3,
        recommend_criteria_tool_changes: 2,
        recommend_criteria_intermediate_stock: 0,
        recommend_strategy: PLANNING_SCHEDULE_STRATEGY.BFS
    }
}

const getRecommendPlanState = (line_group_uuid: string, strategy: string): RecommendPlanParamsAndCriteria => {
    let recommend_plan_state = localStorage.getItem(`${getStrategyPrefix(strategy)}-plan-state`)
    if (recommend_plan_state) {
        recommend_plan_state = JSON.parse(recommend_plan_state);
        if (recommend_plan_state["recommend_param_time_horizont_shifts"] == undefined) {
            recommend_plan_state["recommend_param_time_horizont_shifts"] = (recommend_plan_state["recommend_param_time_horizont_weeks"] || 2) * 21;
        }

        const meta_optimization_param = BusinessLogic.getLineGroupTagBool(line_group_uuid,
            LINE_GROUP_TAGS.planning_meta_optimization_param, false);
        if (!meta_optimization_param) {
            recommend_plan_state["recommend_param_meta_optimization"] = PLANNING_META_OPTIMIZATION.none;
            recommend_plan_state["recommend_param_override_shifts"] = undefined;
        } else if (recommend_plan_state.recommend_param_meta_optimization === PLANNING_META_OPTIMIZATION.none) {
            recommend_plan_state["recommend_param_override_shifts"] = undefined;
        } else if (recommend_plan_state.recommend_param_meta_optimization === PLANNING_META_OPTIMIZATION.max_resources) {
            recommend_plan_state["recommend_param_override_shifts"] = 0;
        } else {
            recommend_plan_state["recommend_param_override_shifts"] = Math.max(
                1, Math.min(4, recommend_plan_state.recommend_param_override_shifts || 1)
            );
        }

        return {
            ...getRecommendPlanInitialState(line_group_uuid, strategy),
            ...recommend_plan_state
        };
    }

    return getRecommendPlanInitialState(line_group_uuid, strategy);
}

const isWeekUnit = (line_group_uuid: string): boolean => {
    return BusinessLogic.getLineGroupTagStr(
        line_group_uuid,
        LINE_GROUP_TAGS.planning_time_horizont_unit,
        PLAN_HORIZON_UNIT_AUTOMATIC_SCHEDULING.week_unit
    ) === PLAN_HORIZON_UNIT_AUTOMATIC_SCHEDULING.week_unit;
}

const setRecommendPlanState = (line_group_uuid: string, strategy: string, key: string, value?: boolean | number | string) => {
    const recommend_plan_state = getRecommendPlanState(line_group_uuid, strategy);

    const is_week_unit = isWeekUnit(line_group_uuid);
    if (is_week_unit && key === "recommend_param_time_horizont_shifts" && typeof value === "number") {
        value = value * 21;
    }
    recommend_plan_state[key] = value;
    localStorage.setItem(`${getStrategyPrefix(strategy)}-plan-state`, JSON.stringify(recommend_plan_state));
}

const setRecommendPlanInitialState = (line_group_uuid: string, strategy: string, recommend_plan_state: RecommendPlanParamsAndCriteria) => {
    localStorage.setItem(`${getStrategyPrefix(strategy)}-plan-state`, JSON.stringify(recommend_plan_state));
}

const getRecommendPlanParamsValue = (line_group_uuid: string, strategy: string, key: string) => {
    const recommend_plan_state = getRecommendPlanState(line_group_uuid, strategy);
    return recommend_plan_state[key];
}

const getConfigurationName = (strategy: string): string => {
    return localStorage.getItem(`${getStrategyPrefix(strategy)}-configuration-name`) || "";
};

const setConfigurationName = (strategy: string, name: string) => {
    localStorage.setItem(`${getStrategyPrefix(strategy)}-configuration-name`, name);
};

class RecommendPlan extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            configuration: "",
            configurations: [],
            recommend_plan_parameters: getRecommendPlanState(props.line_group_uuid, PLANNING_SCHEDULE_STRATEGY.BFS),
            show_delete_modal: false,
            show_file_name_modal: false,
            selected_tab: "parameters"
        }
    }

    async componentDidMount() {
        await this.loadConfigurations();
    }

    async componentDidUpdate(prev_props: Props) {
        if (prev_props.line_group_uuid !== this.props.line_group_uuid) {
            this.setState({
                recommend_plan_parameters: getRecommendPlanState(
                    this.props.line_group_uuid,
                    this.state.recommend_plan_parameters.recommend_strategy
                )
            });
            await this.loadConfigurations();
        }
    }

    getShowModal = (): boolean => {
        return this.props.recommend_plan.show_recommend_param_dialog;
    }

    getRecommendIsRunning = (): boolean => {
        return this.props.recommend_plan.recommend_running;
    }

    hideConfigurationModal = () => {
        this.props.reduxDispatch({ type: SET_RECOMMEND_PLAN_MODAL, data: false });
        this.props.reduxDispatch({ type: SET_RECOMMEND_WARNINGS, data: [] });
    }

    showConfigurationModal = () => {
        this.props.reduxDispatch({ type: SET_RECOMMEND_PLAN_MODAL, data: true });
    };

    showRecommendModal = (config?: t.ISchedulingConfiguration, autorun?: boolean) => {
        if (config) {
            this.setStateFromConfiguration(config, () => {
                this.showConfigurationModal();
                if (autorun) {
                    this.recommendPlan();
                }
            });

            return;
        }

        const strategy = PLANNING_SCHEDULE_STRATEGY.BFS;
        setRecommendPlanState(this.props.line_group_uuid, strategy, "recommend_strategy", strategy);
        this.resetConfiguration(strategy);
        this.setState({
            recommend_plan_parameters: getRecommendPlanState(this.props.line_group_uuid, strategy)
        }, () => {
            this.showConfigurationModal();
            if (autorun) {
                this.recommendPlan();
            }
        });
    };

    showRectifyModal = (config?: t.ISchedulingConfiguration, autorun?: boolean) => {
        if (config) {
            this.setStateFromConfiguration(config, () => {
                this.showConfigurationModal();
                if (autorun) {
                    this.rectifyPlan();
                }
            });

            return;
        }

        const strategy = PLANNING_SCHEDULE_STRATEGY.rectify;
        setRecommendPlanState(this.props.line_group_uuid, strategy, "recommend_strategy", strategy);
        this.resetConfiguration(strategy);
        this.setState({
            recommend_plan_parameters: getRecommendPlanState(this.props.line_group_uuid, strategy)
        }, () => {
            this.showConfigurationModal();
            if (autorun) {
                this.rectifyPlan();
            }
        });
    };

    handleCriteriaChange = (criteria: string, selected_value: any) => {
        const strategy = this.state.recommend_plan_parameters.recommend_strategy;
        setRecommendPlanState(this.props.line_group_uuid, strategy, criteria, selected_value.value);
        this.resetConfiguration();
        this.setState({
            recommend_plan_parameters: getRecommendPlanState(this.props.line_group_uuid, strategy)
        });
    }

    handleMetaOptimizationChange = (selected_value: any) => {
        const strategy = this.state.recommend_plan_parameters.recommend_strategy;
        const meta_optimization = selected_value.value;
        setRecommendPlanState(this.props.line_group_uuid, strategy, "recommend_param_meta_optimization", meta_optimization);

        const recommend_plan_parameters = getRecommendPlanState(this.props.line_group_uuid, strategy);
        const override_shifts = recommend_plan_parameters.recommend_param_override_shifts;

        setRecommendPlanState(this.props.line_group_uuid, strategy, "recommend_param_override_shifts", override_shifts);
        this.resetConfiguration();
        this.setState({ recommend_plan_parameters });
    }

    handleRecommendParamToggle = (event: Event) => {
        const strategy = this.state.recommend_plan_parameters.recommend_strategy;
        let target = event.target;
        if (target instanceof HTMLInputElement) {
            switch (target.id) {
                case "recommend_param_break_orders":
                    const recommend_param_break_orders = !getRecommendPlanParamsValue(
                        this.props.line_group_uuid,
                        strategy,
                        "recommend_param_break_orders"
                    );
                    setRecommendPlanState(
                        this.props.line_group_uuid,
                        strategy,
                        "recommend_param_break_orders",
                        recommend_param_break_orders
                    );
                    break;
                case "recommend_param_track_input_material":
                    const recommend_param_track_input_material = !getRecommendPlanParamsValue(
                        this.props.line_group_uuid,
                        strategy,
                        "recommend_param_track_input_material"
                    );
                    setRecommendPlanState(
                        this.props.line_group_uuid,
                        strategy,
                        "recommend_param_track_input_material",
                        recommend_param_track_input_material
                    );
                    break;
                case "recommend_param_use_productions_of_unscheduled_operations":
                    const recommend_param_use_productions_of_unscheduled_operations = !getRecommendPlanParamsValue(
                        this.props.line_group_uuid,
                        strategy,
                        "recommend_param_use_productions_of_unscheduled_operations"
                    );
                    setRecommendPlanState(
                        this.props.line_group_uuid,
                        strategy,
                        "recommend_param_use_productions_of_unscheduled_operations",
                        recommend_param_use_productions_of_unscheduled_operations
                    );
                    break;
                case "recommend_param_use_requirements_from_unscheduled_operations":
                    const recommend_param_use_requirements_from_unscheduled_operations = !getRecommendPlanParamsValue(
                        this.props.line_group_uuid,
                        strategy,
                        "recommend_param_use_requirements_from_unscheduled_operations"
                    );
                    setRecommendPlanState(
                        this.props.line_group_uuid,
                        strategy,
                        "recommend_param_use_requirements_from_unscheduled_operations",
                        recommend_param_use_requirements_from_unscheduled_operations
                    );
                    break;
                case "recommend_param_use_safety_stock":
                    const recommend_param_use_safety_stock = !getRecommendPlanParamsValue(
                        this.props.line_group_uuid,
                        strategy,
                        "recommend_param_use_safety_stock"
                    );
                    setRecommendPlanState(
                        this.props.line_group_uuid,
                        strategy,
                        "recommend_param_use_safety_stock",
                        recommend_param_use_safety_stock
                    );
                    break;
                case "recommend_param_customer_priority":
                    const recommend_param_customer_priority = !getRecommendPlanParamsValue(
                        this.props.line_group_uuid,
                        strategy,
                        "recommend_param_customer_priority"
                    );
                    setRecommendPlanState(
                        this.props.line_group_uuid,
                        strategy,
                        "recommend_param_customer_priority",
                        recommend_param_customer_priority
                    );
                    break;
                case "recommend_param_include_plan_orders":
                    const recommend_param_include_plan_orders = !getRecommendPlanParamsValue(
                        this.props.line_group_uuid,
                        strategy,
                        "recommend_param_include_plan_orders"
                    );
                    setRecommendPlanState(
                        this.props.line_group_uuid,
                        strategy,
                        "recommend_param_include_plan_orders",
                        recommend_param_include_plan_orders
                    );
                    break;
                case "recommend_param_include_unscheduled_orders":
                    const recommend_param_include_unscheduled_orders = !getRecommendPlanParamsValue(
                        this.props.line_group_uuid,
                        strategy,
                        "recommend_param_include_unscheduled_orders"
                    );
                    setRecommendPlanState(
                        this.props.line_group_uuid,
                        strategy,
                        "recommend_param_include_unscheduled_orders",
                        recommend_param_include_unscheduled_orders
                    );
                    break;
                case "recommend_param_freeze_work_orders":
                    const recommend_param_freeze_work_orders = !getRecommendPlanParamsValue(
                        this.props.line_group_uuid,
                        strategy,
                        "recommend_param_freeze_work_orders"
                    );
                    setRecommendPlanState(
                        this.props.line_group_uuid,
                        strategy,
                        "recommend_param_freeze_work_orders",
                        recommend_param_freeze_work_orders
                    );
                    break;
                case "recommend_param_can_change_workorder_line":
                    const recommend_param_can_change_workorder_line = !getRecommendPlanParamsValue(
                        this.props.line_group_uuid,
                        strategy,
                        "recommend_param_can_change_workorder_line"
                    );
                    setRecommendPlanState(
                        this.props.line_group_uuid,
                        strategy,
                        "recommend_param_can_change_workorder_line",
                        recommend_param_can_change_workorder_line
                    );
                    break;
                default:
                    console.log("unknown toggle " + target.id);
            }

            this.resetConfiguration();
            this.setState({
                recommend_plan_parameters: getRecommendPlanState(this.props.line_group_uuid, strategy)
            });
        }
    }

    handleParamInput = (param_name: string, min_val: number, event: Event, max_value?: number) => {
        let target = event.target;
        if (!(target instanceof HTMLSelectElement || target instanceof HTMLInputElement)) return;
        // check if we can parse
        const value = parseInt(target.value, 10);
        if (!isNumber(value)) { return; }
        // check if non-negative
        if (value < min_val) { return; }
        if (max_value !== undefined && value > max_value) { return; }
        // all fine!
        const strategy = this.state.recommend_plan_parameters.recommend_strategy;
        setRecommendPlanState(this.props.line_group_uuid, strategy, param_name, value);
        this.resetConfiguration();
        this.setState({
            recommend_plan_parameters: getRecommendPlanState(this.props.line_group_uuid, strategy)
        });
    }

    // function for checking if we have an overlap with existing orders
    is_overlap = (
        freeze_orders: t.IRunLinegroupPlanningFreezeOrder[],
        line_uuid: string, _start_time: number, _end_time: number
    ) => {
        // we ignore difference within one minute
        const timestamp_epsilon = 60 * 1000;
        let start_time = _start_time;
        let end_time = _end_time;

        for (const freeze_order of freeze_orders) {
            // check if same line
            if (freeze_order.line_uuid !== line_uuid) { continue; }

            // if order subsumes the frozen other, then we cannot do much since
            // orders should be significantly longer then the epsilon
            if (start_time <= freeze_order.start_time && freeze_order.end_time <= end_time) {
                return { overlap_order_uuid: freeze_order.uuid, start_time, end_time };
            }
            // check if order starts within some other order
            if (freeze_order.start_time < start_time && start_time < freeze_order.end_time) {
                // check if within epsilon
                if ((freeze_order.end_time - start_time) < timestamp_epsilon) {
                    // we are good, just fix start time to the end
                    start_time = freeze_order.end_time;
                } else {
                    // overlap to large
                    return { overlap_order_uuid: freeze_order.uuid, start_time, end_time };
                }
            }
            // check if order ends within some other order
            if (freeze_order.start_time < end_time && end_time < freeze_order.end_time) {
                // check if within epsilon
                if ((end_time - freeze_order.start_time) < timestamp_epsilon) {
                    // we are good, just fix start time to the end
                    end_time = freeze_order.start_time;
                } else {
                    // overlap to large
                    return { overlap_order_uuid: freeze_order.uuid, start_time, end_time };
                }
            }
        }

        return {
            overlap_order_uuid: undefined,
            start_time: Math.ceil(start_time / 60000.0) * 60000.0, // round up to a minute
            end_time: Math.floor(end_time / 60000.0) * 60000.0 // rond down to am inute
        };
    }

    collectOrderQuantityProduced = (): Map<string, number> => {
        const order_uuid_to_quantity_produced = new Map<string, number>();

        // make sure we have report
        if (this.props.report_loading === true) {
            console.log("Report loading");
            return order_uuid_to_quantity_produced;
        }
        if (this.props.report.report_data === null) {
            console.log("Report NULL");
            return order_uuid_to_quantity_produced;
        }

        // get report
        const report: t2.IReportModelEx = this.props.report.report_data;
        // go over all orders in report and remember their quantity produced
        const orders = report.result.orders;
        for (const line of orders) {
            for (const order of line.production) {
                order_uuid_to_quantity_produced.set(order.order_id, order.quantity_produced);
            }
        }
        // go over unscheduled orders and collect produced numbers for them as well
        if (report.result.unscheduled) {
            for (const order of report.result.unscheduled) {
                order_uuid_to_quantity_produced.set(order.order_uuid, order.quantity_produced);
            }
        }

        return order_uuid_to_quantity_produced;
    }

    prepareFreezeOrders = (): t.IRunLinegroupPlanningFreezeOrder[] => {
        const recommend_plan_parameters = this.state.recommend_plan_parameters;

        // make sure we have report
        if (this.props.report_loading === true) {
            console.log("Report loading");
            return [];
        }
        if (this.props.report.report_data === null) {
            console.log("Report NULL");
            return [];
        }
        const report: t2.IReportModelEx = this.props.report.report_data;
        // time shortcuts
        const next_shift_date = dateFromWeekAndShift(
            report.result.next_shift_time.week,
            report.result.next_shift_time.year,
            report.result.next_shift_time.shift_number);
        const max_hours_start = recommend_plan_parameters.recommend_param_freeze_shifts * SHIFT_CONSTS.SHIFT_DURATION_HOURS;
        // first explicitly collect orders based on tags
        const freeze_orders: t.IRunLinegroupPlanningFreezeOrder[] = [];
        const work_order_types = [ ORDER_TYPE.full, ORDER_TYPE.parallel ];
        const frozen_order_set: Set<string> = new Set();
        for (const line of this.props.lines_orders) {
            for (const order of line.orders) {
                if (order.tags[ORDER_TAGS.insight_freeze_order] === "true") {
                    // make sure we have both values
                    if (order.tags[ORDER_TAGS.freeze_time_start] === undefined ||
                        order.tags[ORDER_TAGS.freeze_time_end] === undefined) {
                        console.log(`Frozen order ${order.uuid} missing required tags for start and end`);
                        continue;
                    }
                    // we have frozen order!
                    const { overlap_order_uuid, start_time, end_time } = this.is_overlap(
                        freeze_orders, line.line_uuid,
                        parseFloat(order.tags[ORDER_TAGS.freeze_time_start]),
                        parseFloat(order.tags[ORDER_TAGS.freeze_time_end])
                    );
                    // report if we have overlap
                    if (overlap_order_uuid) {
                        console.log(`Frozen order ${order.uuid} overlaps with existing frozen order ${overlap_order_uuid}`);
                    } else {
                        if (!frozen_order_set.has(order.uuid)) {
                            // create order
                            freeze_orders.push({
                                end_time,
                                start_time,
                                uuid: order.uuid,
                                line_uuid: line.line_uuid
                            });
                            frozen_order_set.add(order.uuid);
                        }
                    }
                }
            }
        }

        // we prepare list of lines we are editing on the planning board so we can skip rest
        // of the lines in the report (report contains also all realted lines and we do not
        // need to include orders from there for frerezing)
        const planning_board_lines_set = new Set(this.props.lines_orders.map(lo => lo.line_uuid));

        // figure out which ordes are in the freeze zones and extract ther start and end timestamps
        const orders = report.result.orders;
        for (const line of orders) {
            // skip if not part of planning board
            if (!planning_board_lines_set.has(line.line)) { continue; }
            // check if this line is selected
            if (this.props.selected_line_uuids.length > 0 && !this.props.selected_line_uuids.includes(line.line)) {
                continue;
            }
            // collect orders with productions in the first freeze_shifts shifts
            let found_one_order = false;
            for (const order of line.production) {
                // check if order falls under any freeze condition
                const order_freeze_shifts = (order.estimated_start[1].val < max_hours_start);
                const order_freeze_type = (recommend_plan_parameters.recommend_param_freeze_work_orders === true) && work_order_types.includes(order.order_type);
                if (order_freeze_shifts || order_freeze_type) {
                    const { overlap_order_uuid, start_time, end_time } = this.is_overlap(
                        freeze_orders, line.line,
                        addHours(next_shift_date, order.estimated_start[1].val).getTime(),
                        addHours(next_shift_date, order.estimated_completion[1].val).getTime()
                    );
                    if (overlap_order_uuid) {
                        console.log(`Frozen order ${order.order_id} overlaps with existing frozen order ${overlap_order_uuid}`);
                    } else {
                        if (!frozen_order_set.has(order.order_id)) {
                            // create order
                            freeze_orders.push({
                                end_time,
                                start_time,
                                uuid: order.order_id,
                                line_uuid: line.line
                            });
                            frozen_order_set.add(order.order_id);
                            // mark we found at least one
                            found_one_order = true;
                        }
                    }
                }
            }
            // handle special case where no order within range but we still want to fix the ongoing orders
            if (recommend_plan_parameters.recommend_param_freeze_shifts >= 0 && found_one_order === false &&
                line.production.length > 0 && line.production[0].tool_setup === false &&
                line.production[0].estimated_start[1].val <= max_hours_start) {

                const order = line.production[0];
                const { overlap_order_uuid, start_time, end_time } = this.is_overlap(
                    freeze_orders, line.line,
                    addHours(next_shift_date, order.estimated_start[1].val).getTime(),
                    addHours(next_shift_date, order.estimated_completion[1].val).getTime()
                );
                if (overlap_order_uuid) {
                    console.log(`Frozen order ${order.order_id} overlaps with existing frozen order ${overlap_order_uuid}`);
                } else {
                    if (!frozen_order_set.has(order.order_id)) {
                        // create order
                        freeze_orders.push({
                            end_time,
                            start_time,
                            uuid: line.production[0].order_id,
                            line_uuid: line.line
                        });
                        // mark we found at least one
                        found_one_order = true;
                    }
                }
            }
        }
        return freeze_orders;
    }

    recommendPlan = () =>  {
        const lines_orders = this.props.lines_orders;
        const unscheduled_orders = this.props.unscheduled_orders;
        const original_lines_orders = this.props.original_lines_orders;
        const original_unscheduled_orders = this.props.original_unscheduled_orders;
        const recommend_plan_parameters = this.state.recommend_plan_parameters;
        const parameters: RunRecommendPlanParameters = {
            line_group_uuid: this.props.line_group_uuid,
            line_uuids: this.props.selected_line_uuids,
            state: {
                recommend_param_beam_size: recommend_plan_parameters.recommend_param_beam_size,
                recommend_param_break_orders: recommend_plan_parameters.recommend_param_break_orders,
                recommend_param_can_change_workorder_line: recommend_plan_parameters.recommend_param_can_change_workorder_line,
                recommend_param_track_input_material: recommend_plan_parameters.recommend_param_track_input_material,
                recommend_param_use_productions_of_unscheduled_operations: recommend_plan_parameters.recommend_param_use_productions_of_unscheduled_operations,
                recommend_param_use_requirements_from_unscheduled_operations: recommend_plan_parameters.recommend_param_use_requirements_from_unscheduled_operations,
                recommend_param_use_safety_stock: recommend_plan_parameters.recommend_param_use_safety_stock,
                recommend_param_customer_priority: recommend_plan_parameters.recommend_param_customer_priority,
                recommend_param_include_plan_orders: recommend_plan_parameters.recommend_param_include_plan_orders,
                recommend_param_include_unscheduled_orders: recommend_plan_parameters.recommend_param_include_unscheduled_orders,
                recommend_param_freeze_work_orders: recommend_plan_parameters.recommend_param_freeze_work_orders,
                recommend_param_freeze_shifts: recommend_plan_parameters.recommend_param_freeze_shifts,
                recommend_param_time_horizont_shifts: recommend_plan_parameters.recommend_param_time_horizont_shifts,
                recommend_param_unscheduled_offset_shifts: recommend_plan_parameters.recommend_param_time_horizont_shifts,
                recommend_param_meta_optimization: recommend_plan_parameters.recommend_param_meta_optimization,
                recommend_param_override_shifts: recommend_plan_parameters.recommend_param_override_shifts,
                recommend_param_setup_code_change_factor: recommend_plan_parameters.recommend_param_setup_code_change_factor,
                recommend_param_work_duration_factor: recommend_plan_parameters.recommend_param_work_duration_factor,
                recommend_criteria_delays: recommend_plan_parameters.recommend_criteria_delays,
                recommend_criteria_tool_changes: recommend_plan_parameters.recommend_criteria_tool_changes,
                recommend_criteria_intermediate_stock: recommend_plan_parameters.recommend_criteria_intermediate_stock,
                recommend_strategy: recommend_plan_parameters.recommend_strategy
            },
            lines_orders,
            unscheduled_orders,
            planning_weeks: Math.floor(this.props.properties.days / 7),
            freeze_orders: this.prepareFreezeOrders(),
            order_quanity_produced_map: this.collectOrderQuantityProduced(),
            rearrange_orders_map: LinesOrdersLogic.rearrangeOrdersMap(original_lines_orders || [], original_unscheduled_orders || []),
            original_lines_orders
        }

        RecommendPlanLogic.load(parameters, this.props.reduxDispatch);
    }

    collectOrderTimeStart = (): Map<string, number> => {
        const order_uuid_to_time_start_map = new Map<string, number>();

        // make sure we have report
        if (this.props.report_loading === true) {
            console.log("Report loading");
            return order_uuid_to_time_start_map;
        }
        if (this.props.report.report_data === null) {
            console.log("Report NULL");
            return order_uuid_to_time_start_map;
        }

        // get report
        const report: t2.IReportModelEx = this.props.report.report_data;
        // time shortcuts
        const next_shift_date = dateFromWeekAndShift(
            report.result.next_shift_time.week,
            report.result.next_shift_time.year,
            report.result.next_shift_time.shift_number);
        // go over all orders in report and remember their time start
        const orders = report.result.orders;
        for (const line of orders) {
            for (const order of line.production) {
                const time_start = addHours(next_shift_date, order.estimated_start[1].val).getTime();
                order_uuid_to_time_start_map.set(order.order_id, time_start);
            }
        }

        return order_uuid_to_time_start_map;
    }

    rectifyPlan = () => {
        const lines_orders = this.props.lines_orders;
        const unscheduled_orders = this.props.unscheduled_orders;
        const original_lines_orders = this.props.original_lines_orders;
        const original_unscheduled_orders = this.props.original_unscheduled_orders;
        const recommend_plan_parameters = this.state.recommend_plan_parameters;
        const planning_weeks = BusinessLogic.getLineGroupTagInt(
            this.props.line_group_uuid, LINE_GROUP_TAGS.planning_table_weeks, 4
        );

        const parameters: RunRecommendPlanParameters = {
            line_group_uuid: this.props.line_group_uuid,
            line_uuids: this.props.selected_line_uuids,
            state: {
                recommend_param_beam_size: PLANNING_PARAMS_DEFAULT.beam_size,
                recommend_param_break_orders: false,
                recommend_param_can_change_workorder_line: false,
                recommend_param_track_input_material: false,
                recommend_param_use_productions_of_unscheduled_operations: false,
                recommend_param_use_requirements_from_unscheduled_operations: false,
                recommend_param_use_safety_stock: false,
                recommend_param_customer_priority: false,
                recommend_param_include_plan_orders: recommend_plan_parameters.recommend_param_include_plan_orders,
                recommend_param_include_unscheduled_orders: false,
                recommend_param_freeze_work_orders: false,
                recommend_param_freeze_shifts: -1,
                recommend_param_time_horizont_shifts: planning_weeks * 21,
                recommend_param_unscheduled_offset_shifts: recommend_plan_parameters.recommend_param_time_horizont_shifts,
                recommend_param_meta_optimization: PLANNING_META_OPTIMIZATION.none,
                recommend_param_override_shifts: undefined,
                recommend_param_setup_code_change_factor: BusinessLogic.getLineGroupTagInt(this.props.line_group_uuid, LINE_GROUP_TAGS.planning_setup_code_change_factor, 2),
                recommend_param_work_duration_factor: 0,
                recommend_criteria_delays: 1,
                recommend_criteria_tool_changes: 1,
                recommend_criteria_intermediate_stock: 1,
                recommend_strategy: PLANNING_SCHEDULE_STRATEGY.rectify
            },
            lines_orders,
            unscheduled_orders,
            planning_weeks,
            freeze_orders: [],
            order_quanity_produced_map: this.collectOrderQuantityProduced(),
            order_time_start_map: this.collectOrderTimeStart(),
            rearrange_orders_map: LinesOrdersLogic.rearrangeOrdersMap(original_lines_orders || [], original_unscheduled_orders || []),
            original_lines_orders
        }

        RecommendPlanLogic.load(parameters, this.props.reduxDispatch);
    }

    getIsDisabled = () => {
        return !BusinessLogic.getLineGroupTagBool(this.props.line_group_uuid, LINE_GROUP_TAGS.automatic_scheduling, false)
    }

    onSetDefault = () => {
        const strategy = this.state.recommend_plan_parameters.recommend_strategy;
        const line_group_uuid = this.props.line_group_uuid;
        const initial_state = getRecommendPlanInitialState(line_group_uuid, strategy);
        if (this.state.recommend_plan_parameters.recommend_strategy === PLANNING_SCHEDULE_STRATEGY.rectify) {
            initial_state.recommend_strategy = PLANNING_SCHEDULE_STRATEGY.rectify;
        }

        setRecommendPlanInitialState(line_group_uuid, strategy, initial_state);
        this.resetConfiguration(initial_state.recommend_strategy);
        this.setState({
            recommend_plan_parameters: initial_state
        });
    }

    getPlanHorizontMsg = () => {
        const automatic_scheduling_unit = BusinessLogic.getLineGroupTagStr(
            this.props.line_group_uuid,
            LINE_GROUP_TAGS.planning_time_horizont_unit,
            PLAN_HORIZON_UNIT_AUTOMATIC_SCHEDULING.week_unit
        );
        let unit_msg = translate("common.shift", "Shift");
        if (automatic_scheduling_unit === PLAN_HORIZON_UNIT_AUTOMATIC_SCHEDULING.week_unit) {
            unit_msg = translate("common.week", "Week");
        }
        return translate("Manufacturing.Planning.param_plan_horizont_without_unit", "Plan horizont") + " (" + unit_msg + ")";
    }

    mapWarnings = (warnings: t.IPlanWarning[]) => {
        const warning_descriptions = {
            frozen: translate(
                "Manufacturing.Planning.warning_frozen",
                "Operation was frozen but logic for placing it on predefined place failed"
            ),
            rectify_unscheduled: translate(
                "Manufacturing.Planning.warning_rectify_unscheduled",
                "Rectify logic could not place operation on the schedule"
            ),
            prod_ver_mismatch: translate(
                "Manufacturing.Planning.warning_prod_ver_mismatch",
                "Available production versions for scheduling the operations do not match matrix constraints"
            )
        };
        const order_map: Map<string, IOrderProducedModelEx> = new Map();

        for (const line of this.props.lines_orders) {
            for (const order of line.orders) {
                order_map.set(order.uuid, order);
            }
        }

        for (const line of this.props.unscheduled_orders) {
            for (const order of line.orders) {
                order_map.set(order.uuid, order);
            }
        }

        return warnings.map(w => {
            const order = order_map.get(w.operation_uuid);
            const explanation_text = w.type === "rectify_unscheduled" ?
                RecommendPlanLogic.getExplanationText(w.explanation) : null;
            return {
                external_id: order && order.external_id,
                proces_num: order && order.process_num,
                description: warning_descriptions[w.type],
                explanation_text
            }
        });
    }

    loadConfigurations = async () => {
        const { configs } = await getBackend().manufacturing.getSchedulingConfigurations({
            line_group_uuid: this.props.line_group_uuid
        });
        this.setState({
            configurations: configs.sort((a, b) => a.name.localeCompare(b.name))
        });
    }

    updateConfiguration = async (config: t.ISchedulingConfiguration) => {
        await getBackend().manufacturing.updateSchedulingConfiguration({ config });
    };

    deleteConfiguration = async (name: string) => {
        await getBackend().manufacturing.deleteSchedulingConfiguration({
            line_group_uuid: this.props.line_group_uuid,
            strategy: this.state.recommend_plan_parameters.recommend_strategy,
            name
        });
    };

    getConfiguration = (name: string, strategy?: string): t.ISchedulingConfiguration | void => {
        const scheduling_strategy = strategy || this.state.recommend_plan_parameters.recommend_strategy;
        return this.state.configurations.find(
            config => config.name === name && config.strategy === scheduling_strategy
        );
    };

    setConfiguration = (name: string, strategy?: string) => {
        const scheduling_strategy = strategy || this.state.recommend_plan_parameters.recommend_strategy;
        const config = this.getConfiguration(name, scheduling_strategy);
        if (config !== undefined) {
            this.setStateFromConfiguration(config);
        } else {
            this.resetConfiguration(scheduling_strategy);
        }
    };

    resetConfiguration = (strategy?: string) => {
        const scheduling_strategy = strategy || this.state.recommend_plan_parameters.recommend_strategy;
        this.setState({
            configuration: ""
        });
        setConfigurationName(scheduling_strategy, "");
    };

    getConfigurationsOptions = () => {
        return [
            {
                label: translate("Manufacturing.Planning.custom_configuration", "Custom configuration"),
                value: ""
            },
            ...this.state.configurations
            .filter(config => this.state.recommend_plan_parameters.recommend_strategy === PLANNING_SCHEDULE_STRATEGY.rectify
                ? config.strategy === PLANNING_SCHEDULE_STRATEGY.rectify
                : config.strategy !== PLANNING_SCHEDULE_STRATEGY.rectify)
            .map(config => ({
                label: config.name,
                value: config.name,
            }))
        ];
    };

    getSelectedConfigurationOption = () => {
        const options = this.getConfigurationsOptions();
        return options.find(config => config.value === this.state.configuration) || options[0];
    };

    hasUniqueName = (name: string) => {
        return this.state.configurations.find(
            config => config.strategy === this.state.recommend_plan_parameters.recommend_strategy && config.name === name
        ) !== undefined;
    };

    getConfigurationFromState = (name: string): t.ISchedulingConfiguration => {
        const recommend_plan_parameters = this.state.recommend_plan_parameters;
        const config: t.ISchedulingConfiguration = {
            name,
            line_group_uuid: this.props.line_group_uuid,
            strategy: recommend_plan_parameters.recommend_strategy,
            params: {
                include_plan_orders: recommend_plan_parameters.recommend_param_include_plan_orders,
                time_horizont_shifts: recommend_plan_parameters.recommend_param_time_horizont_shifts,
                unscheduled_offset_shifts: recommend_plan_parameters.recommend_param_unscheduled_offset_shifts
            },
            criteria: {}
        };

        if (recommend_plan_parameters.recommend_strategy !== PLANNING_SCHEDULE_STRATEGY.rectify) {
            config.params.beam_size = recommend_plan_parameters.recommend_param_beam_size;
            config.params.break_orders = recommend_plan_parameters.recommend_param_break_orders;
            config.params.can_change_workorder_line = recommend_plan_parameters.recommend_param_can_change_workorder_line;
            config.params.customer_priority = recommend_plan_parameters.recommend_param_customer_priority;
            config.params.freeze_shifts = recommend_plan_parameters.recommend_param_freeze_shifts;
            config.params.freeze_work_orders = recommend_plan_parameters.recommend_param_freeze_work_orders;
            config.params.include_unscheduled_orders = recommend_plan_parameters.recommend_param_include_unscheduled_orders;
            config.params.meta_optimization = recommend_plan_parameters.recommend_param_meta_optimization;
            config.params.override_shifts = recommend_plan_parameters.recommend_param_override_shifts;
            config.params.setup_code_change_factor = recommend_plan_parameters.recommend_param_setup_code_change_factor;
            config.params.track_input_material = recommend_plan_parameters.recommend_param_track_input_material;
            config.params.use_productions_of_unscheduled_operations = recommend_plan_parameters.recommend_param_use_productions_of_unscheduled_operations;
            config.params.use_requirements_from_unscheduled_operations = recommend_plan_parameters.recommend_param_use_requirements_from_unscheduled_operations;
            config.params.use_safety_stock = recommend_plan_parameters.recommend_param_use_safety_stock;
            config.params.work_duration_factor = recommend_plan_parameters.recommend_param_work_duration_factor;
            config.criteria.delays = recommend_plan_parameters.recommend_criteria_delays;
            config.criteria.intermediate_stock = recommend_plan_parameters.recommend_criteria_intermediate_stock;
            config.criteria.tool_changes = recommend_plan_parameters.recommend_criteria_tool_changes;
        }

        return config;
    };

    setStateFromConfiguration = (config: t.ISchedulingConfiguration, callback = () => {}) => {
        const recommend_plan_parameters = getRecommendPlanState(this.props.line_group_uuid, config.strategy);
        recommend_plan_parameters.recommend_strategy = config.strategy;
        recommend_plan_parameters.recommend_param_include_plan_orders = config.params.include_plan_orders;
        recommend_plan_parameters.recommend_param_time_horizont_shifts = config.params.time_horizont_shifts;
        recommend_plan_parameters.recommend_param_unscheduled_offset_shifts = config.params.unscheduled_offset_shifts;

        if (config.strategy !== PLANNING_SCHEDULE_STRATEGY.rectify) {
            if (config.params.beam_size !== undefined) {
                recommend_plan_parameters.recommend_param_beam_size = config.params.beam_size;
            }
            if (config.params.break_orders !== undefined) {
                recommend_plan_parameters.recommend_param_break_orders = config.params.break_orders;
            }

            if (config.params.can_change_workorder_line !== undefined) {
                recommend_plan_parameters.recommend_param_can_change_workorder_line = config.params.can_change_workorder_line;
            }

            if (config.params.customer_priority !== undefined) {
                recommend_plan_parameters.recommend_param_customer_priority = config.params.customer_priority;
            }

            if (config.params.freeze_shifts !== undefined) {
                recommend_plan_parameters.recommend_param_freeze_shifts = config.params.freeze_shifts;
            }

            if (config.params.freeze_work_orders !== undefined) {
                recommend_plan_parameters.recommend_param_freeze_work_orders = config.params.freeze_work_orders;
            }

            if (config.params.include_unscheduled_orders !== undefined) {
                recommend_plan_parameters.recommend_param_include_unscheduled_orders = config.params.include_unscheduled_orders;
            }

            if (config.params.meta_optimization !== undefined) {
                recommend_plan_parameters.recommend_param_meta_optimization = config.params.meta_optimization;
            }

            if (config.params.override_shifts !== undefined) {
                recommend_plan_parameters.recommend_param_override_shifts = config.params.override_shifts;
            }

            if (config.params.setup_code_change_factor !== undefined) {
                recommend_plan_parameters.recommend_param_setup_code_change_factor = config.params.setup_code_change_factor;
            }

            if (config.params.track_input_material !== undefined) {
                recommend_plan_parameters.recommend_param_track_input_material = config.params.track_input_material;
            }

            if (config.params.use_productions_of_unscheduled_operations !== undefined) {
                recommend_plan_parameters.recommend_param_use_productions_of_unscheduled_operations = config.params.use_productions_of_unscheduled_operations;
            }

            if (config.params.use_requirements_from_unscheduled_operations !== undefined) {
                recommend_plan_parameters.recommend_param_use_requirements_from_unscheduled_operations = config.params.use_requirements_from_unscheduled_operations;
            }

            if (config.params.use_safety_stock !== undefined) {
                recommend_plan_parameters.recommend_param_use_safety_stock = config.params.use_safety_stock;
            }

            if (config.params.work_duration_factor !== undefined) {
                recommend_plan_parameters.recommend_param_work_duration_factor = config.params.work_duration_factor;
            }

            if (config.criteria.delays !== undefined) {
                recommend_plan_parameters.recommend_criteria_delays = config.criteria.delays;
            }

            if (config.criteria.intermediate_stock !== undefined) {
                recommend_plan_parameters.recommend_criteria_intermediate_stock = config.criteria.intermediate_stock;
            }

            if (config.criteria.tool_changes !== undefined) {
                recommend_plan_parameters.recommend_criteria_tool_changes = config.criteria.tool_changes;
            }
        }

        setRecommendPlanInitialState(this.props.line_group_uuid, config.strategy, recommend_plan_parameters);
        setConfigurationName(config.strategy, config.name);
        this.setState({
            configuration: config.name,
            recommend_plan_parameters: getRecommendPlanState(this.props.line_group_uuid, config.strategy)
        }, callback);
    };

    handleConfigurationChange = (option: SelectOption) => {
        this.setConfiguration(option.value);
    };

    handleConfigurationSave = (event: SyntheticMouseEvent<HTMLButtonElement>) => {
        this.setState({
            show_file_name_modal: true
        });
    };

    handleConfigurationSaveConfirm = async (name: string) => {
        this.setState({
            show_file_name_modal: false
        });
        await this.updateConfiguration(this.getConfigurationFromState(name));
        await this.loadConfigurations();
        this.setConfiguration(name);
    };

    handleConfigurationSaveCancel = () => {
        this.setState({
            show_file_name_modal: false
        });
    };

    handleConfigurationDelete = (event: SyntheticMouseEvent<HTMLButtonElement>) => {
        this.setState({
            show_delete_modal: true
        });
    };

    handleConfigurationDeleteConfirm = async () => {
        this.setState({
            show_delete_modal: false
        });
        await this.deleteConfiguration(this.state.configuration);
        await this.loadConfigurations();
        this.resetConfiguration();
    };

    handleConfigurationDeleteCancel = () => {
        this.setState({
            show_delete_modal: false
        });
    };

    handleTabSelect = (selected_tab: string) => {
        this.setState({
            selected_tab
        });
    };

    render() {
        const show_break_orders = BusinessLogic.getLineGroupTagBool(this.props.line_group_uuid, LINE_GROUP_TAGS.planning_break_orders, false);
        const show_customer_priority = BusinessLogic.getLineGroupTagBool(this.props.line_group_uuid, LINE_GROUP_TAGS.planning_track_customer_priorty, false);
        const show_track_stock_levels = BusinessLogic.getLineGroupTagBool(this.props.line_group_uuid, LINE_GROUP_TAGS.planning_track_stock_levels, false);
        const show_meta_optimization = BusinessLogic.getLineGroupTagBool(this.props.line_group_uuid, LINE_GROUP_TAGS.planning_meta_optimization_param, false);
        const recommend_plan_parameters = this.state.recommend_plan_parameters;
        const time_horizont_shifts = recommend_plan_parameters.recommend_param_time_horizont_shifts;
        const selected_configuration = this.getSelectedConfigurationOption();
        const strategy = recommend_plan_parameters.recommend_strategy;
        const is_recommend = strategy !== PLANNING_SCHEDULE_STRATEGY.rectify;
        const recommend_configs = this.state.configurations.filter(config => config.strategy !== PLANNING_SCHEDULE_STRATEGY.rectify);
        const rectify_configs = this.state.configurations.filter(config => config.strategy === PLANNING_SCHEDULE_STRATEGY.rectify);
        const show_warnings = this.props.warnings.length > 0;
        const selected_tab = this.state.selected_tab ? this.state.selected_tab : "parameters";

        return <div>
            <div className="dropdown">
                <button
                    type="button"
                    className={"btn btn-icon btn-icon-magic dropdown-toggle " + (this.getRecommendIsRunning() ? "btn-danger" : "btn-primary")}
                    disabled={this.getIsDisabled()}
                    data-toggle="dropdown"
                    aria-expanded="false"
                >
                    <span className="sr-only">{translate("common.schedule", "Schedule")}</span>
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                    <button
                        className="dropdown-item"
                        onClick={() => {
                            const strategy = PLANNING_SCHEDULE_STRATEGY.BFS;
                            this.showRecommendModal(
                                this.getConfiguration(getConfigurationName(strategy), strategy)
                            );
                        }}
                    >
                        {translate("Manufacturing.Planning.recommend", "Recommend")}...
                    </button>
                    <button
                        className="dropdown-item"
                        onClick={() => {
                            const strategy = PLANNING_SCHEDULE_STRATEGY.rectify;
                            this.showRectifyModal(
                                this.getConfiguration(getConfigurationName(strategy), strategy)
                            );
                        }}
                    >
                        {translate("Manufacturing.Planning.rectify", "Rectify")}...
                    </button>
                    {recommend_configs.length > 0 && (
                        <React.Fragment>
                            <div className="dropdown-divider" />
                            <p className="dropdown-header px-3">
                                {translate("Manufacturing.Planning.recommend", "Recommend")}
                            </p>
                            {recommend_configs.map(config => (
                                <button
                                    key={`${config.strategy}-${config.name}`}
                                    className="dropdown-item"
                                    onClick={() => {
                                        this.showRecommendModal(config, true);
                                    }}
                                >
                                    {config.name}
                                </button>
                            ))}
                        </React.Fragment>
                    )}
                    {rectify_configs.length > 0 && (
                        <React.Fragment>
                            <div className="dropdown-divider" />
                            <p className="dropdown-header px-3">
                                {translate("Manufacturing.Planning.rectify", "Rectify")}
                            </p>
                            {rectify_configs.map(config => (
                                <button
                                    key={`${config.strategy}-${config.name}`}
                                    className="dropdown-item"
                                    onClick={() => {
                                        this.showRectifyModal(config, true);
                                    }}
                                >
                                    {config.name}
                                </button>
                            ))}
                        </React.Fragment>
                    )}
                </div>
            </div>

            <Modal show={this.getShowModal()} onHide={this.hideConfigurationModal}>
                <Modal.Header>
                    <Modal.Title>
                        {strategy === PLANNING_SCHEDULE_STRATEGY.rectify
                            ? translate("Manufacturing.Planning.rectify", "Rectify")
                            : translate("Manufacturing.Planning.recommend", "Recommend")}
                        {this.state.configuration && ` - ${this.state.configuration}`}
                    </Modal.Title>
                </Modal.Header>
                {!this.props.recommend_plan.recommend_running && !show_warnings && (
                    <Modal.Header className="py-2 border border-top-0 border-left-0 rounded-0 bg-white">
                        <Select
                            className="w-100"
                            value={selected_configuration}
                            options={this.getConfigurationsOptions()}
                            theme={theme => ({
                                ...theme,
                                spacing: {
                                    ...theme.spacing,
                                    baseUnit: 2,
                                    controlHeight: 30
                                }
                            })}
                            components={{
                                IndicatorSeparator: null
                            }}
                            onChange={value => this.handleConfigurationChange(value)}
                            // isClearable
                        />
                        <Authorization.button
                            permission={Auth.PERMISSION_NAMES.SchedulingConfigEdit}
                            className="btn btn-short btn-outline-secondary px-2 ml-2"
                            title={translate("common.save", "Save")}
                            style={{ width: 30 }}
                            disabled={this.state.configuration !== ""}
                            onClick={this.handleConfigurationSave}
                        >
                            <i className="fas fa-save align-middle" />
                        </Authorization.button>
                        <Authorization.button
                            permission={Auth.PERMISSION_NAMES.SchedulingConfigEdit}
                            className="btn btn-short btn-outline-secondary px-2 ml-2"
                            title={translate("common.delete", "Delete")}
                            style={{ width: 30 }}
                            disabled={this.state.configuration === ""}
                            onClick={this.handleConfigurationDelete}
                        >
                            <i className="fas fa-trash align-middle" />
                        </Authorization.button>
                    </Modal.Header>
                )}
                {this.props.recommend_plan.recommend_running ? (
                    <Modal.Body>
                        <h3 className="text-center">
                            <FormattedMessage id="Manufacturing.Planning.calculating" defaultMessage="Calculating" />...
                        </h3>
                    </Modal.Body>
                ) : show_warnings ? (
                    <Modal.Body className="py-3">
                        <h3 className="h5 mb-2">{translate("common.warnings", "Warnings")}</h3>
                        <div className="recommend-warnings alert alert-warning p-0 mb-0">
                            <div className="overflow-auto">
                                <table className="table mb-0">
                                    <thead>
                                        <tr>
                                            <th>{translate("common.external_id", "External ID")}</th>
                                            <th>{translate("common.process_num", "Process number")}</th>
                                            <th>{translate("common.warning", "Warning")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.mapWarnings(this.props.warnings).map(warning => (
                                            <tr>
                                                <td>{warning.external_id}</td>
                                                <td>{warning.proces_num}</td>
                                                <td>
                                                    {warning.description}. {warning.explanation_text}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Modal.Body>
                ) : (
                    <Modal.Body className="py-3">
                        <Tabs
                            activeKey={selected_tab}
                            onSelect={this.handleTabSelect}
                            id="recommend-params-tabs"
                            animation={false}
                        >
                            <Tab eventKey="parameters" title={translate("Manufacturing.Planning.parameters", "Parameters")} tabClassName="nav-item">
                                <div className="tab-content py-3">
                                    {is_recommend && show_break_orders && (
                                        <FormGroup className="d-flex align-items-center justify-content-between mb-2">
                                            <ControlLabel className="mb-0" htmlFor="recommend_param_break_orders">
                                                <FormattedMessage
                                                    id="Manufacturing.Planning.param_break_orders"
                                                    defaultMessage="Break order in multuple parts"
                                                />
                                            </ControlLabel>
                                            <Toggle
                                                id="recommend_param_break_orders"
                                                checked={recommend_plan_parameters.recommend_param_break_orders}
                                                onChange={this.handleRecommendParamToggle}
                                            />
                                        </FormGroup>
                                    )}
                                    {is_recommend && (
                                        <FormGroup className="d-flex align-items-center justify-content-between mb-2">
                                            <ControlLabel className="mb-0" htmlFor="recommend_param_track_input_material">
                                                <FormattedMessage
                                                    id="Manufacturing.Planning.param_track_input_material"
                                                    defaultMessage="Track input materials"
                                                />
                                            </ControlLabel>
                                            <Toggle
                                                id="recommend_param_track_input_material"
                                                checked={recommend_plan_parameters.recommend_param_track_input_material}
                                                onChange={this.handleRecommendParamToggle}
                                            />
                                        </FormGroup>
                                    )}
                                    {is_recommend && (
                                        <FormGroup className="d-flex align-items-center justify-content-between mb-2">
                                            <ControlLabel className="mb-0" htmlFor="recommend_param_use_productions_of_unscheduled_operations">
                                                <FormattedMessage
                                                    id="Manufacturing.Planning.param_use_productions_of_unscheduled_operations"
                                                    defaultMessage="Track input materials"
                                                />
                                            </ControlLabel>
                                            <Toggle
                                                id="recommend_param_use_productions_of_unscheduled_operations"
                                                checked={recommend_plan_parameters.recommend_param_use_productions_of_unscheduled_operations}
                                                onChange={this.handleRecommendParamToggle}
                                            />
                                        </FormGroup>
                                    )}
                                    {is_recommend && (
                                        <FormGroup className="d-flex align-items-center justify-content-between mb-2">
                                            <ControlLabel className="mb-0" htmlFor="recommend_param_use_requirements_from_unscheduled_operations">
                                                <FormattedMessage
                                                    id="Manufacturing.Planning.param_use_requirements_from_unscheduled_operations"
                                                    defaultMessage="Track input materials"
                                                />
                                            </ControlLabel>
                                            <Toggle
                                                id="recommend_param_use_requirements_from_unscheduled_operations"
                                                checked={recommend_plan_parameters.recommend_param_use_requirements_from_unscheduled_operations}
                                                onChange={this.handleRecommendParamToggle}
                                            />
                                        </FormGroup>
                                    )}
                                    {is_recommend && (
                                        <FormGroup className="d-flex align-items-center justify-content-between mb-2">
                                            <ControlLabel className="mb-0" htmlFor="recommend_param_use_safety_stock">
                                                <FormattedMessage
                                                    id="common.include_safety_stocks"
                                                    defaultMessage="Allow usage of safety stock"
                                                />
                                            </ControlLabel>
                                            <Toggle
                                                id="recommend_param_use_safety_stock"
                                                checked={recommend_plan_parameters.recommend_param_use_safety_stock}
                                                onChange={this.handleRecommendParamToggle}
                                            />
                                        </FormGroup>
                                    )}
                                    <FormGroup className="d-flex align-items-center justify-content-between mb-2">
                                        <ControlLabel className="mb-0" htmlFor="recommend_param_include_plan_orders">
                                            <FormattedMessage
                                                id="Manufacturing.Planning.param_plan_orders"
                                                defaultMessage="Include plan orders"
                                            />
                                        </ControlLabel>
                                        <Toggle
                                            id="recommend_param_include_plan_orders"
                                            checked={recommend_plan_parameters.recommend_param_include_plan_orders}
                                            onChange={this.handleRecommendParamToggle}
                                        />
                                    </FormGroup>
                                    {is_recommend && (
                                        <FormGroup className="d-flex align-items-center justify-content-between mb-2">
                                            <ControlLabel className="mb-0" htmlFor="recommend_param_include_unscheduled_orders">
                                                <FormattedMessage
                                                    id="Manufacturing.Planning.param_include_unscheduled_orders"
                                                    defaultMessage="Include unscheduled orders"
                                                />
                                            </ControlLabel>
                                            <Toggle
                                                id="recommend_param_include_unscheduled_orders"
                                                checked={recommend_plan_parameters.recommend_param_include_unscheduled_orders}
                                                onChange={this.handleRecommendParamToggle}
                                            />
                                        </FormGroup>
                                    )}
                                    {is_recommend && (
                                        <FormGroup className="d-flex align-items-center justify-content-between mb-2">
                                            <ControlLabel className="mb-0" htmlFor="recommend_param_freeze_work_orders">
                                                <FormattedMessage
                                                    id="Manufacturing.Planning.param_freeze_work_orders"
                                                    defaultMessage="Freez work orders"
                                                />
                                            </ControlLabel>
                                            <Toggle
                                                id="recommend_param_freeze_work_orders"
                                                checked={recommend_plan_parameters.recommend_param_freeze_work_orders}
                                                onChange={this.handleRecommendParamToggle}
                                            />
                                        </FormGroup>
                                    )}
                                    {is_recommend && show_customer_priority && (
                                        <FormGroup className="d-flex align-items-center justify-content-between mb-2">
                                            <ControlLabel className="mb-0" htmlFor="recommend_param_customer_priority">
                                                <FormattedMessage
                                                    id="Manufacturing.Planning.param_customer_priority"
                                                    defaultMessage="Account for customer priority"
                                                />
                                            </ControlLabel>
                                            <Toggle
                                                id="recommend_param_customer_priority"
                                                checked={recommend_plan_parameters.recommend_param_customer_priority}
                                                onChange={this.handleRecommendParamToggle}
                                            />
                                        </FormGroup>
                                    )}
                                    {is_recommend && (
                                        <FormGroup className="d-flex align-items-center justify-content-between mb-2">
                                            <ControlLabel className="mb-0" htmlFor="recommend_param_freeze_shifts">
                                                <FormattedMessage
                                                    id="Manufacturing.Planning.param_freeze_shifts"
                                                    defaultMessage="Freeze shifts"
                                                />
                                            </ControlLabel>
                                            <input
                                                id="recommend_param_freeze_shifts"
                                                className="form-control form-control-sm"
                                                style={{ width: 80, height: 30 }}
                                                type="number"
                                                value={recommend_plan_parameters.recommend_param_freeze_shifts}
                                                onChange={event => this.handleParamInput("recommend_param_freeze_shifts", -1, event)}
                                            />
                                        </FormGroup>
                                    )}
                                    <FormGroup className="d-flex align-items-center justify-content-between mb-2">
                                        <ControlLabel className="mb-0" htmlFor="recommend_param_plan_horizont">
                                            {this.getPlanHorizontMsg()}
                                        </ControlLabel>
                                        <input
                                            id="recommend_param_plan_horizont"
                                            className="form-control form-control-sm"
                                            style={{ width: 80, height: 30 }}
                                            type="number"
                                            value={isWeekUnit(this.props.line_group_uuid) ? Math.round(time_horizont_shifts / 21) : time_horizont_shifts}
                                            onChange={event => this.handleParamInput("recommend_param_time_horizont_shifts", 1, event)}
                                        />
                                    </FormGroup>
                                    {is_recommend && (
                                        <React.Fragment>
                                            <h3 className="h5 mt-3 mb-2">
                                                <FormattedMessage id="Manufacturing.Planning.criteria" defaultMessage="Criteria" />
                                            </h3>
                                            <FormGroup className="d-flex align-items-center justify-content-between mb-2">
                                                <ControlLabel className="mb-0" htmlFor="recommend_criteria_delays">
                                                    <FormattedMessage
                                                        id="Manufacturing.Planning.criteria_delays"
                                                        defaultMessage="Delays"
                                                    />
                                                </ControlLabel>
                                                <Select
                                                    inputId="recommend_criteria_delays"
                                                    styles={{
                                                        control: (provided) => ({ ...provided, width: 80 }),
                                                        valueContainer: (provided) => ({ ...provided, paddingLeft: 8 })
                                                    }}
                                                    theme={theme => ({
                                                        ...theme,
                                                        spacing: {
                                                            ...theme.spacing,
                                                            baseUnit: 2,
                                                            controlHeight: 30
                                                        }
                                                    })}
                                                    components={{
                                                        IndicatorSeparator: null
                                                    }}
                                                    value={{
                                                        label: `${recommend_plan_parameters.recommend_criteria_delays}`,
                                                        value: recommend_plan_parameters.recommend_criteria_delays
                                                    }}
                                                    options={[0, 1, 2, 3, 4, 5].map(v => ({ label: `${v}`, value: v }))}
                                                    onChange={value => this.handleCriteriaChange("recommend_criteria_delays", value)}
                                                />
                                            </FormGroup>
                                            <FormGroup className="d-flex align-items-center justify-content-between mb-2">
                                                <ControlLabel className="mb-0" htmlFor="recommend_criteria_tool_changes">
                                                    <FormattedMessage
                                                        id="Manufacturing.Planning.criteria_tool_changes"
                                                        defaultMessage="Tool changes"
                                                    />
                                                </ControlLabel>
                                                <Select
                                                    inputId="recommend_criteria_tool_changes"
                                                    styles={{
                                                        control: (provided) => ({ ...provided, width: 80 }),
                                                        valueContainer: (provided) => ({ ...provided, paddingLeft: 8 })
                                                    }}
                                                    theme={theme => ({
                                                        ...theme,
                                                        spacing: {
                                                            ...theme.spacing,
                                                            baseUnit: 2,
                                                            controlHeight: 30
                                                        }
                                                    })}
                                                    components={{
                                                        IndicatorSeparator: null
                                                    }}
                                                    value={{
                                                        label: `${recommend_plan_parameters.recommend_criteria_tool_changes}`,
                                                        value: recommend_plan_parameters.recommend_criteria_tool_changes
                                                    }}
                                                    options={[0, 1, 2, 3, 4, 5].map(v => ({ label: `${v}`, value: v }))}
                                                    onChange={value => this.handleCriteriaChange("recommend_criteria_tool_changes", value)}
                                                />
                                            </FormGroup>
                                            {show_track_stock_levels && (
                                                <FormGroup className="d-flex align-items-center justify-content-between mb-2">
                                                    <ControlLabel className="mb-0" htmlFor="recommend_criteria_intermediate_stock">
                                                        <FormattedMessage
                                                            id="Manufacturing.Planning.criteria_intermediate_stock"
                                                            defaultMessage="Value of intermediate stock"
                                                        />
                                                    </ControlLabel>
                                                    <Select
                                                        inputId="recommend_criteria_intermediate_stock"
                                                        styles={{
                                                            control: (provided) => ({ ...provided, width: 80 }),
                                                            valueContainer: (provided) => ({ ...provided, paddingLeft: 8 })
                                                        }}
                                                        theme={theme => ({
                                                            ...theme,
                                                            spacing: {
                                                                ...theme.spacing,
                                                                baseUnit: 2,
                                                                controlHeight: 30
                                                            }
                                                        })}
                                                        components={{
                                                            IndicatorSeparator: null
                                                        }}
                                                        value={{
                                                            label: `${recommend_plan_parameters.recommend_criteria_intermediate_stock}`,
                                                            value: recommend_plan_parameters.recommend_criteria_intermediate_stock
                                                        }}
                                                        options={[0, 1, 2, 3, 4, 5].map(v => ({ label: `${v}`, value: v }))}
                                                        onChange={value => this.handleCriteriaChange("recommend_criteria_intermediate_stock", value)}
                                                    />
                                                </FormGroup>
                                            )}
                                        </React.Fragment>
                                    )}
                                </div>
                            </Tab>
                            <Tab eventKey="advanced" title={translate("Manufacturing.Planning.advanced_criteria", "Advanced criteria")} tabClassName="nav-item">
                                <div className="tab-content py-3">
                                    {is_recommend && (
                                        <FormGroup className="d-flex align-items-center justify-content-between mb-2">
                                            <ControlLabel className="mb-0" htmlFor="recommend_param_beam_size"
                                                    title={translate("Manufacturing.Planning.help.beam_size", "Size of search-beam in plannnig. Default is 3.")}>
                                                {translate("Manufacturing.Planning.beam_size", "Size of search-beam")}
                                            </ControlLabel>
                                            <input
                                                id="recommend_param_beam_size"
                                                className="form-control form-control-sm"
                                                style={{ width: 80, height: 30 }}
                                                type="number"
                                                value={recommend_plan_parameters.recommend_param_beam_size}
                                                onChange={event => this.handleParamInput("recommend_param_beam_size", 1, event)}
                                            />
                                        </FormGroup>
                                    )}
                                    {is_recommend && (
                                        <FormGroup className="d-flex align-items-center justify-content-between mb-2">
                                            <ControlLabel className="mb-0" htmlFor="recommend_param_setup_code_change_factor">
                                                {translate("Manufacturing.Planning.param_setup_code_change_factor", "Setup code change factor")}
                                            </ControlLabel>
                                            <input
                                                id="recommend_param_setup_code_change_factor"
                                                className="form-control form-control-sm"
                                                style={{ width: 80, height: 30 }}
                                                type="number"
                                                value={recommend_plan_parameters.recommend_param_setup_code_change_factor}
                                                onChange={event => this.handleParamInput("recommend_param_setup_code_change_factor", 0, event)}
                                            />
                                        </FormGroup>
                                    )}
                                    {is_recommend && (
                                        <FormGroup className="d-flex align-items-center justify-content-between mb-2">
                                            <ControlLabel className="mb-0" htmlFor="recommend_param_work_duration_factor">
                                                {translate("Manufacturing.Planning.work_duration_weight", "Work duration weight")}
                                            </ControlLabel>
                                            <input
                                                id="recommend_param_work_duration_factor"
                                                className="form-control form-control-sm"
                                                style={{ width: 80, height: 30 }}
                                                type="number"
                                                value={recommend_plan_parameters.recommend_param_work_duration_factor}
                                                onChange={event => this.handleParamInput("recommend_param_work_duration_factor", 0, event)}
                                                />
                                        </FormGroup>
                                    )}
                                    {is_recommend && (
                                        <FormGroup className="d-flex align-items-center justify-content-between mb-2">
                                            <ControlLabel className="mb-0" htmlFor="recommend_param_can_change_workorder_line"
                                                    title={translate("Manufacturing.Planning.help.can_change_workorder_line", "Size of search-beam in plannnig. Default is 3.")}>
                                                <FormattedMessage
                                                    id="Manufacturing.Planning.can_change_workorder_line"
                                                    defaultMessage="Can workorder change line"
                                                />
                                            </ControlLabel>
                                            <Toggle
                                                id="recommend_param_can_change_workorder_line"
                                                checked={recommend_plan_parameters.recommend_param_can_change_workorder_line}
                                                onChange={this.handleRecommendParamToggle}
                                            />
                                        </FormGroup>
                                    )}

                                    {is_recommend && show_meta_optimization && (
                                        <React.Fragment>
                                            <FormGroup className="d-flex align-items-center justify-content-between mb-2">
                                                <ControlLabel className="mb-0" htmlFor="recommend_param_meta_optimization">
                                                    <FormattedMessage
                                                        id="Manufacturing.Planning.param_meta_optimization"
                                                        defaultMessage="Meta optimization"
                                                    />
                                                </ControlLabel>
                                                <Select
                                                    inputId="recommend_param_meta_optimization"
                                                    styles={{
                                                        control: (provided) => ({ ...provided, width: 140 })
                                                    }}
                                                    theme={theme => ({
                                                        ...theme,
                                                        spacing: {
                                                            ...theme.spacing,
                                                            baseUnit: 2,
                                                            controlHeight: 30
                                                        }
                                                    })}
                                                    components={{
                                                        IndicatorSeparator: null
                                                    }}
                                                    value={{
                                                        label: `${recommend_plan_parameters.recommend_param_meta_optimization}`,
                                                        value: recommend_plan_parameters.recommend_param_meta_optimization
                                                    }}
                                                    options={Object.keys(PLANNING_META_OPTIMIZATION)
                                                        .map(v => ({ label: `${v}`, value: v }))}
                                                    onChange={value => this.handleMetaOptimizationChange(value)} />
                                            </FormGroup>
                                            {![
                                                PLANNING_META_OPTIMIZATION.none,
                                                PLANNING_META_OPTIMIZATION.max_resources
                                            ].includes(recommend_plan_parameters.recommend_param_meta_optimization) && (
                                                <FormGroup className="d-flex align-items-center justify-content-between mb-2">
                                                    <ControlLabel className="mb-0" htmlFor="recommend_param_override_shifts">
                                                        {translate("Manufacturing.Planning.param_override_shifts", "Override shifts")}
                                                    </ControlLabel>
                                                    <input
                                                        id="recommend_param_override_shifts"
                                                        className="form-control form-control-sm"
                                                        style={{ width: 80, height: 30 }}
                                                        type="number"
                                                        value={recommend_plan_parameters.recommend_param_override_shifts}
                                                        onChange={event => this.handleParamInput("recommend_param_override_shifts", 1, event, 4)}
                                                    />
                                                </FormGroup>
                                            )}
                                        </React.Fragment>
                                    )}
                                </div>
                            </Tab>
                        </Tabs>
                        {this.props.error && (
                            <ErrorComponent
                                className="mt-4 mb-0"
                                msg={<span title={this.props.error}>
                                    {translate("common.simulation_error", "Please try again. An error occured while processing simulation data.")}
                                </span>}
                                type="warning"
                                onlyDiv={true}
                                is_closable={true}
                            />
                        )}
                    </Modal.Body>
                )}
                <Modal.Footer>
                    {!show_warnings && (
                        <div>
                            <button
                                className="btn btn-outline-primary"
                                onClick={this.onSetDefault}
                                disabled={this.getRecommendIsRunning()}
                            >
                                <FormattedMessage id="common.reset" defaultMessage="Reset" />
                            </button>
                        </div>
                    )}
                    <div className="ml-auto">
                        {!show_warnings && strategy !== PLANNING_SCHEDULE_STRATEGY.rectify && (
                            <button
                                onClick={this.recommendPlan}
                                className="btn btn-outline-primary"
                                disabled={this.getRecommendIsRunning()}
                            >
                                <FormattedMessage id="Manufacturing.Planning.recommend" defaultMessage="Recommend" />
                            </button>
                        )}
                        {!show_warnings && strategy === PLANNING_SCHEDULE_STRATEGY.rectify && (
                            <button
                                style={{marginLeft: "5px"}}
                                onClick={this.rectifyPlan}
                                className="btn btn-outline-primary"
                                disabled={this.getRecommendIsRunning()}
                            >
                                <FormattedMessage id="Manufacturing.Planning.rectify" defaultMessage="Rectify" />
                            </button>
                        )}
                        <button
                            style={{marginLeft: "5px"}}
                            onClick={this.hideConfigurationModal}
                            className="btn btn-outline-primary"
                            disabled={this.getRecommendIsRunning()}
                        >
                            <FormattedMessage id="common.close" defaultMessage="Close" />
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
            <SaveConfigurationModal
                show={this.state.show_file_name_modal}
                isUnique={this.hasUniqueName}
                onAccept={this.handleConfigurationSaveConfirm}
                onCancel={this.handleConfigurationSaveCancel}
            />
            <ConfirmationModal
                title={translate("Manufacturing.Planning.delete_configuration", "Delete configuration")}
                show={this.state.show_delete_modal}
                onAccept={this.handleConfigurationDeleteConfirm}
                onCancel={this.handleConfigurationDeleteCancel}
            >
                {translate("Manufacturing.Planning.are_you_sure_delete_config", "Are you sure you want to delete this configuration?")}
                {" "}
                <strong>{selected_configuration.label}</strong>
            </ConfirmationModal>
        </div>
    }
}

export default connect(
    (state: ReduxState) => {
        const planning_table = state.gantt_chart_planning_table;
        const recommend_plan = planning_table.recommend_plan;
        const lines_orders = state.gantt_chart_lines_orders.lines_orders;
        const unscheduled_orders = state.gantt_chart_lines_orders.unscheduled_orders;
        const selected_line_uuids = state.gantt_chart_filters.selected_line_uuids;
        const report_loading: boolean = state.gantt_chart_lines_orders.loading;
        const report = state.gantt_chart_report;
        const original_lines_orders = state.gantt_chart_lines_orders.original_lines_orders;
        const original_unscheduled_orders = state.gantt_chart_lines_orders.original_unscheduled_orders;
        const properties = state.gantt_chart_properties;

        return {
            recommend_plan,
            lines_orders,
            unscheduled_orders,
            selected_line_uuids,
            report_version_type: planning_table.report_version_type,
            report_version_title: planning_table.report_version_title,
            properties,
            report_loading,
            report,
            error: planning_table.error,
            original_lines_orders,
            original_unscheduled_orders,
            warnings: planning_table.recommend_warnings
        };
    },
    (dispatch) => ({ reduxDispatch: dispatch })
)(RecommendPlan);
