// @flow

// main imports
import React, { Component } from "react";
import ReactRouterPropTypes from "react-router-prop-types";

// backend
import { getBackend as getBackend2 } from "../../lib/backend/Backend2";
import * as t from "../../lib/backend/manufacturing2.generated.types";
import * as BusinessLogic from "../../lib/BusinessLogic";

// import subcomponents
import ErrorComponent from "../ErrorComponent";
import PeopleDetails from "./PeopleDetails";

// defining types
type Props = {
    match: ReactRouterPropTypes.match,
    history: ReactRouterPropTypes.history,
    location: ReactRouterPropTypes.location
}

type State = {
    lines: t.ILineData[],
    plants: t.IPlantData[],
    selected: t.IPersonData | null,
    error: string
}

class PeopleDetailsStandalone extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        const state: State = {
            lines: [],
            plants: [],
            selected: null,
            error: ""
        }
        this.state = state;
    }

    componentDidMount() {
        this.loadComponent();
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.match.params.uuid !== this.props.match.params.uuid) {
            this.loadComponent();
        }
    }

    async loadComponent() {
        try {
            // get selected person details
            const res_people = await getBackend2().manufacturing
                .getPeople({ uuid: this.props.match.params.uuid });
            let people = res_people.people;

            // check if we got a valid person
            if (people.length === 0) {
                this.setState({ lines: [], plants: [], selected: null, error: "Invalid person UUID" });
            }
            // make the first active person as selected
            let selected = people[0];

            // get plants of selected person for which user has permission
            const plants = BusinessLogic.getPlantsForUser().filter(p => (selected.plant_uuids.includes(p.uuid)));
            // we are good, get extra details needed to render people detail component
            const lines = [ ];
            for (const plant of plants) {
                const res_lines = await getBackend2().manufacturing.getLines({ plant_uuid: plant.uuid });
                BusinessLogic.filterLinesForUser(res_lines.lines, true)
                    .sort((a, b) => a.weight - b.weight)
                    .forEach(line => lines.push(line));
            }

            this.setState({ plants, lines, selected, error: "" });
        } catch (err) {
            this.setState({ lines: [], plants: [], selected: null, error: err.message });
        }
    }

    render() {
        return (
            <div key="ds_new">
                <section key="1">
                    <img src="/img/banner.png" alt="banner" className="img-fluid-header banner_img" />
                </section>
                <section id="profile_edit" className="new_data_source" key="2">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="white_box">
                                    <div className="tab-content">
                                        <ErrorComponent msg={this.state.error} type="error"/>
                                        {this.state.selected !== null && <PeopleDetails
                                            selected={this.state.selected} plants={this.state.plants}
                                            lines={this.state.lines} standalone={true} />}
                                    </div>
                                </div>
                                <div className="space30"></div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default PeopleDetailsStandalone;
