// @flow

// main imports
import React from "react";
import { shiftNumber, fromShiftTag, createHtmlAndDownload } from "../../lib/Util";
import * as Auth from "../../lib/Auth";
import { translate } from "../IntlProviderWrapper";
import { getBackend as getBackend2 } from "../../lib/backend/Backend2";
import * as t from "../../lib/backend/manufacturing2.generated.types";
import { renderToString } from "react-dom/server";
import { getLineTitleCssClass } from "../../lib/ShiftAssignment";

function generateExportShiftRowNew(
    line: t.IShiftPersonLineRec, shift_index: number,
    showToolChange: boolean, uniquePersonName: Function,
    personWorks12Hours: Function
) {
    const shifts = line.shifts;

    const res = [];
    shifts.forEach((shift, i) => {
        if (i % 3 !== shift_index) {
            return;
        }
        let classTD = "colorEmpty";

        // enabled or disabled shift
        if (shift && !shift.enabled) {
            classTD += " shift-disabled";
        }

        classTD += " td-shift-name ";

        // include persons
        const persons: string[] = [];
        const works_12h_map: Map<string, boolean> = new Map();
        if (shift && shift.persons && shift.persons.length) {
            classTD += " shift-person";
            shift.persons.forEach(p => {
                const uniq_person_name = uniquePersonName(p.uuid);
                const works_12h = personWorks12Hours(p.uuid);
                works_12h_map.set(uniq_person_name, works_12h);
                persons.push(uniq_person_name);
            });
        }

        // include tool setup
        const tool_changes = [];
        if (showToolChange) {
            shift.tool_changes.forEach((tc, idxToolChange) => {
                tool_changes.push(<div key={`id-${i}-${idxToolChange}-1`}>{tc.material_ext_id + " (Order: " + tc.order_external_id + ") "}</div>);
                tool_changes.push(<div key={`id-${i}-${idxToolChange}-1`}>{tc.title}</div>);
            });
        }

        if (shift_index === 0) {
            classTD += " line-row-td";
        } else {
            classTD += " non-line-row-td";
        }

        res.push(
            <td key={i} className={classTD}>
                <div>
                    {
                        persons.sort().map((person, k) =>
                            <div key={`id-${i}-${k}`}>
                                <span className={works_12h_map.has(person) && works_12h_map.get(person) ? "person-12h-shift" : ""}>
                                    {person}
                                </span>
                            </div>
                        )
                    }
                    {tool_changes.length > 0 &&
                        <div className="tool-change-box">
                            {tool_changes}
                        </div>
                    }
                </div>
            </td>
        );
    });
    return res;
}

function generateExportShiftTableNew(
    table_data: t.IShiftPersonLineRec[],
    showToolChange: boolean, uniquePersonName: Function,
    personWorks12Hours: Function
) {
    let daysRow = [];

    let linesTable = table_data.map((line, i) => {
        let line_name = line.line_title;
        let shifts = line.shifts;

        if (!daysRow.length) {
            shifts.forEach((shift, shift_idx) => {
                let shiftDate = fromShiftTag(shift.shift_tag);
                let shiftObj = shiftNumber(shiftDate);
                let day = shiftObj.day.slice(0, 3);
                let date = shiftDate.getDate() + ". " + (shiftDate.getMonth() + 1) + ".";

                let css = "th-wrapper shift-table-days text-center shift-header-td";
                if (shiftObj.day_number === 6) {
                    css += " table-header-sunday";
                }
                if (shiftObj.day_number === 5) {
                    css += " table-header-saturday";
                }
                if (shift_idx === shifts.length - 1 || shiftDate.getDate() !== fromShiftTag(shifts[shift_idx + 1].shift_tag).getDate()) {
                    daysRow.push(
                        <th key={shift_idx} className={css}><div>{day} - {date}</div></th>
                    );
                }
            })
        }
        const lineTitleCss = getLineTitleCssClass(line.line_tags);
        return (
            <React.Fragment key={"fragment_" + i}>
                <tr key={i} className="line-row">
                    <td rowSpan="3" className={lineTitleCss}>
                        <div>{line_name}</div>
                    </td>
                    <td className="shift-num"> 1 </td>
                    {generateExportShiftRowNew(line, 0, showToolChange, uniquePersonName, personWorks12Hours)}
                </tr>
                <tr key={i}>
                    <td className="shift-num"> 2 </td>
                    {generateExportShiftRowNew(line, 1, showToolChange, uniquePersonName, personWorks12Hours)}
                </tr>
                <tr key={i}>
                    <td className="shift-num"> 3 </td>
                    {generateExportShiftRowNew(line, 2, showToolChange, uniquePersonName, personWorks12Hours)}
                </tr>
            </React.Fragment>
        );
    });

    return (
        <div className="card-body overflow-auto" >
            <div className="chart-v-frame h-100">
                <div className="chart-h-frame h-100">
                    <div className="chart-wrapper h-100">
                        <table className="table table-shifts table-bordered table-shifts-export full-width" id="table-shifts">
                            <thead>
                                <tr className="line-row">
                                    <th scope="col" className="th-wrapper shift-table-days text-center width20px"><div></div></th>
                                    <th className="shift-num"><div></div></th>
                                    {daysRow}
                                </tr>
                            </thead>
                            <tbody>
                                {linesTable}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

function escapeHtml(html: string): string {
    const text = document.createTextNode(html);
    const p = document.createElement('p');
    p.appendChild(text);
    return p.innerHTML;
}

export async function exportWeekToHtml(
    year: number, week: number,
    includePrevSunday: boolean, showToolChange: boolean,
    line_uuids: string[], uniquePersonName: Function,
    personWorks12Hours: Function,
    message: string = "",
    is_weekend_print: ?boolean = false
): Promise<void> {


    // export from to paramters
    let from_shift_tag = "";

    if (is_weekend_print) {
        from_shift_tag = (year * 10000 + week * 100 + 12) + "";
    } else {
        if (includePrevSunday) {
            from_shift_tag = (year * 10000 + (week - 1) * 100 + 18) + "";
        } else {
            from_shift_tag = (year * 10000 + (week) * 100 + 0) + "";
        }
    }

    let to_shift_tag = (year * 10000 + week * 100 + 21) + "";

    if (is_weekend_print) {
        to_shift_tag = (year * 10000 + week * 100 + 24) + "";
    }

    const data = await getBackend2().manufacturing.getPeopleShiftsTable({
        from_shift: from_shift_tag,
        to_shift: to_shift_tag,
        include_tool_changes: true,
        include_work_orders: false,
        line_uuids
    });
    const results = data.lines.sort((a, b) => a.weight - b.weight);
    const tableShifts = renderToString(generateExportShiftTableNew(results, showToolChange, uniquePersonName, personWorks12Hours));
    const org_code = Auth.getOrgData().code;
    let logo = "";
    try {
        const resp = (await getBackend2().getApiClient().get(`/dynamic/logo/${org_code}/base64`)).data;
        logo = `<img src="data:${resp.mime};base64,${resp.data}" style="width: 100px; padding: 3px;"/>`
    } catch (e) {
        logo = "";
    }

    let commentRow = "";
    if (message !== "") {
        const remark = translate("common.remark", "Opomba");
        const html = escapeHtml(message).replace(/\n/gi, "<br/>");
        commentRow = `<div class="comment">${remark}:<br/> ${html} </div>`;
    }

    const html = `
        <html>
            <head>
                <title>${translate("common.timetable")}: ${week} / ${year}</title>
                <meta charSet="utf-8" />
                <style>
                    body {
                        font-family: "Arial", "Helvetica", "sans-serif";
                    }
                    body, table, p, div {
                        font-size: 12px;
                    }
                    table {
                        border-collapse: collapse;
                        border: 1px solid black;
                    }
                    td:empty {
                        background-color: rgb(220, 220, 220);
                    }

                    th, td {
                        border: 1px solid lightgray;
                        border-bottom: 0px solid lightgray;
                        padding: 3px;
                        text-align: center;
                    }
                    .non-line-row-td {
                        /* border: 1px solid lightgray; */
                    }

                    tr.line-row>th, tr.line-row>td, .line-row-td {
                        border-top: 1px solid black;
                    }

                    tr>.shift-num {
                        font-weight: bold;
                        border-right: 2px solid black;
                        border-left: 2px solid black;
                    }

                    .full-width { width: 100% }

                    .table-shifts-export .tool-change-box {
                        border: solid 3px rgb(50,50,50);
                        padding: 3px;
                        border-radius: 5px;
                        margin-top: 5px;
                        background-color: rgb(50,50,50);
                        color: rgb(255,255,255);
                    }

                    .table-shifts-export .shift-header-td {
                        min-width: 30px;
                    }

                    .table-header-sunday {
                        background-color: darkred;
                        color: white;
                    }
                    .table-header-saturday {
                        background-color: darkorange;
                        color: white;
                    }
                    .shift-disabled {
                        background-color: rgb(237,237,237) !important;
                    }
                    .line-linegroup-lead,
                    .line-shift-lead {
                        color: white;
                        background-color: darkorange;
                    }
                    .line-cameraman,
                    .line-control,
                    .line-packaging,
                    .line-sick-leave,
                    .line-tool-setup {
                        color: white;
                        background-color: darkolivegreen;
                    }

                    .header-table {
                        border: 0px;
                        width: 100%;
                        text-align: left;
                    }

                    .header-table td,
                    .header-table tr {
                        border: 0px !important;
                    }
                    .comment {
                        font-size: 1.3em;
                        font-weight: bold;
                        text-align: left;
                        border: 1px solid black;
                        padding: 15px;
                        margin-bottom: 5px;
                    }
                    .person-12h-shift {
                        background-color: yellow;
                    }
                    @media print { body { -webkit-print-color-adjust: exact; } }
                </style>
            </head>
            <body onload="window.print()">
            <table class="header-table">
                <tr>
                    <td style="width: 105px;">
                        ${logo}
                    </td>
                    <td>
                        <h1>${translate("common.timetable")}: ${week} / ${year}</h1>
                    </td>
                </tr>
            </table>
            ${commentRow}
            ${tableShifts}
            </body>
        </html>`;

    createHtmlAndDownload(html, "timetable-" + week + ".html");
}
