// @flow

// main imports
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import Toggle from "react-toggle";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";

import ErrorComponent from "../ErrorComponent";
import { getBackend } from "../../lib/backend/Backend2";
import * as u from "../../lib/Util";
import * as t from "../../lib/backend/manufacturing2.generated.types";
import { getLineTitleCssClass } from "../../lib/ShiftAssignment";
import * as bl from "../../lib/BusinessLogic";
import { exportWeekToHtml } from "./ShiftAssignmentDetailsExportWeek";
import { exportDayToHtml } from "./ShiftAssignmentDetailsExportDay";
import ShiftAssignmentDetailsExportDayModal from "./ShiftAssignmentDetailsExportDayModal";
import { translate, hasTranslation } from "../IntlProviderWrapper";
import { publish, MSG_TYPES } from "../../lib/PubSub";
import Loader from "../Loader";
import ShiftAssignmentPoolEdit from "./ShiftAssignmentPoolEdit";
import type { PoolEditModalType } from "./ShiftAssignmentPoolEdit";
import { niceNumber, shiftTag, positiveModulo } from "../../lib/Util";
import {
    LINE_TAGS, LINE_TYPES, PLANT_TAGS, LINE_GROUP_TAGS, PLANNING_MODE, COPY_WEEKLY_ASSIGNMENTS_STRATEGY
} from "../../lib/ManufacturingTags.generated";

const STORAGE_PREFIX = "people-shift-daily-export";

// list of line types for which we do not show no_competence indicator
export const IGNORE_COMPETENCE_FLAG = [
    LINE_TYPES.sick_leave,
    LINE_TYPES.shift_lead,
    LINE_TYPES.pool
];

// defining types
type CanPersonWorkInShiftFunction = (string, number) => boolean;
type CanPersonWorkOnLineFunction = (string, string) => boolean;
type GetUniquePersonNameFunction = (string) => string;
type GetIsPersonAdjusterFunction = (string) => boolean;
type HandleShiftsSavedFunction = () => void;
type PersonMaxNumberShifts = (string) => number;
type PersonWorks12Hours = (string) => boolean;

type ShiftStatistics = {
    scheduled_lines: number,
    active_lines: number
};

type Props = {
    selectedPerson: t.IPersonData | null,
    year: number,
    week: number,
    plant: string,
    line_group_uuid: string,
    uniquePersonName: GetUniquePersonNameFunction,
    isPersonAdjuster: GetIsPersonAdjusterFunction,
    handlePersonAddedToSchedule: Function,
    canPersonWorkOnLine: CanPersonWorkOnLineFunction,
    canPersonWorkInShift: CanPersonWorkInShiftFunction,
    personMaxNumberShifts: PersonMaxNumberShifts,
    personAllowedShifts: bl.PersonAllowedShifts,
    handleShiftsSaved: HandleShiftsSavedFunction,
    personWorks12Hours: PersonWorks12Hours
}

type State = {
    loading: boolean,
    delete_mode: boolean,
    uses_pool: boolean,
    print_only_assigned_lines: boolean,
    competences: t.ICompetenceData[],
    lines: t.IShiftPersonLineRec[], // all lines in TED
    lines_non_pooled: t.IShiftPersonLineRec[], // non-pooled lines (these ones are displayed in initial screen)
    lines_pooled: t.IShiftPersonLineRec[], // pooled lines (these ones are NOT displayed in initial screen)
    pool_line: t.IShiftPersonLineRec | null, // pool
    lines_previous_week: t.IShiftPersonLineRec[],
    plant_data: t.IPlantData | null,
    i: number,
    j: number,
    key: string | null,
    error: string;
    current_selected_process: any,
    current_selected_line: any,
    available_shifts: any[],
    people_shifts: any[],
    hash: string,
    week: number,
    year: number,
    show_tool_change: boolean,
    show_work_orders_in_print: boolean,
    insights: Array<any>,
    show_export_day_modal: boolean,
    show_export_week_modal: boolean,
    include_prev_sunday: boolean,
    daily_message: string,
    export_day_of_week: number,
    unsaved_changes: boolean,
    worker_hours: Map<string, number>,
    show_settings_editor: boolean,
    show_worker_alert: boolean,
    showNX: boolean,
    showC: boolean,
    showS: boolean,
    showR: boolean,
    showPoolEditor: boolean,
    poolEditorShift: number,
    print_whole_week: boolean,
}

let bullet_key_counter = 0;
function createBullet(type: string, text: string, tooltip: string | null, is_wide?: boolean) {
    const css = is_wide ? " badge-wide" : "";
    const key = "bullet" + bullet_key_counter++;
    if (type === "error") {
        return <span key={key} className={"badge badge-pill badge-danger" + css} title={tooltip}>{text}</span>;
    }
    if (type === "warning") {
        return <span key={key} className={"badge badge-pill badge-warn" + css} title={tooltip}>{text}</span>;
    }
    return <span key={key} className={"badge badge-pill badge-info" + css} title={tooltip}>{text}</span>;
}

const defaultToggleValues = {
    showC: false,
    showNX: false,
    showR: true,
    showS: true,
    showToolChange: true,
    showWorkOrderPrint: true
}

type toggleNamesCompetences = "showC" | "showNX" | "showR" | "showS";
type toggleNames = "showC" | "showNX" | "showR" | "showS" | "showWorkOrderPrint" | "showToolChange";

const getToggleLocalStorage = (type: toggleNames): boolean => {
    const value = localStorage.getItem(`shift-assignment-details-${type}`);

    if (value === undefined || value === null) return defaultToggleValues[type];

    return value === "true";
}

const setToggleLocalStorage = (type: toggleNames, value: boolean) => {
    localStorage.setItem(`shift-assignment-details-${type}`, value.toString());
}

/////////////////////////////////////////
// MAIN COMPONENT

/**
 * Displaying people details and manage competences.
 */
class ShiftAssignmentDetails extends Component<Props, State> {

    constructor(props: Props) {
        super(props);

        const state: State = {
            delete_mode: false,
            loading: true,
            uses_pool: false,
            print_only_assigned_lines: false,
            selected: null,
            competences: [],
            current_selected_process: null,
            current_selected_line: null,
            daily_message: "",
            error: "",
            i: -1,
            j: -1,
            key: null,
            week: this.props.week,
            year: this.props.year,
            hash: "",
            show_tool_change: getToggleLocalStorage("showToolChange"),
            show_work_orders_in_print: getToggleLocalStorage("showWorkOrderPrint"),
            unsaved_changes: false,
            lines: [],
            lines_non_pooled: [],
            lines_pooled: [],
            pool_line: null,
            lines_previous_week: [],
            plant_data: null,
            available_shifts: [],
            people_shifts: [],
            insights: [],
            show_export_day_modal: false,
            show_export_week_modal: false,
            include_prev_sunday: false,
            export_day_of_week: 0,
            worker_hours: new Map(),
            show_settings_editor: false,
            show_worker_alert: false,
            showNX: getToggleLocalStorage("showNX"),
            showC: getToggleLocalStorage("showC"),
            showS: getToggleLocalStorage("showS"),
            showR: getToggleLocalStorage("showR"),
            showPoolEditor: false,
            poolEditorShift: -1,
            print_whole_week: false
        }

        this.state = state;
    }

    async loadLinesData(line_uuids: string[], year: number, week: number): Promise<t.IGetPeopleShiftsTableRes> {
        const from_shift_tag = shiftTag(year, week, 0);
        const to_shift_tag = shiftTag(year, week, 21);
        const data = await getBackend().manufacturing.getPeopleShiftsTable({
            from_shift: from_shift_tag,
            to_shift: to_shift_tag,
            include_tool_changes: true,
            include_work_orders: false,
            line_uuids
        });
        data.lines = data.lines.sort((a, b) => a.weight - b.weight);
        data.lines.forEach(line => {
            if (line.line_tags[LINE_TAGS.type] === undefined) {
                line.line_tags[LINE_TAGS.type] = LINE_TYPES.ordinary;
            }
        });
        return data;
    }

    async copyPreviousWeek() {
        const line_uuids = this.state.lines.map(x => x.line_uuid);
        // calculate previous week tags
        const year = this.state.year;
        const week = this.state.week;
        const from_date = u.dateFromWeekAndShift(week - 1, year, 0);
        const prev_week = u.shiftNumber(from_date);
        const rotate_direction_linegroup = bl.getLineGroupTagBool(
            this.props.line_group_uuid,
            LINE_GROUP_TAGS.rotate_opposite_direction,
            false
        );
        const copy_strategy_linegroup = bl.getLineGroupTagStr(
            this.props.line_group_uuid,
            LINE_GROUP_TAGS.copy_week_strategy,
            COPY_WEEKLY_ASSIGNMENTS_STRATEGY.shift_preference_rotate // default
        );

        // load data for previous week
        const prev_week_data = await this.loadLinesData(line_uuids, prev_week.year, prev_week.week);

        // update shift tags and week indicators
        prev_week_data.lines.forEach(line => {
            line.shifts.forEach(shift => {
                const from_date = u.dateFromWeekAndShift(week, year, shift.shift_number);
                shift.shift_tag = u.shiftNumber(from_date).shift_tag;
                shift.week = week;
            });
        });

        this.setState(prevState => {
            const week_start_shift_offset = this.getWeekStartShiftOffset();
            for (const line of prevState.lines_non_pooled) {
                const line_last_week = prev_week_data.lines.filter(x => x.line_uuid === line.line_uuid)[0];
                if (!line_last_week) {
                    continue;
                }
                // resolve copy-strategy for line, use line-group setting as fallback
                const copy_strategy_line = line.line_tags[LINE_TAGS.copy_week_strategy] || copy_strategy_linegroup;
                // the same with rotate_direction
                const rotate_direction_line_tag = line.line_tags[LINE_TAGS.rotate_opposite_direction];
                const rotate_direction_line = (
                    rotate_direction_line_tag === "true" ||
                    (rotate_direction_line_tag === undefined && rotate_direction_linegroup)
                );
                bl.copyShiftAssignmentsBetweenWeeks(
                    line_last_week, line, copy_strategy_line, this.props.personAllowedShifts,
                    week_start_shift_offset, rotate_direction_line
                );
            }
            return { lines: prevState.lines, unsaved_changes: true };
        });
    }

    getLineGroupData() {
        return bl.getLineGroupsForUser()
            .filter(x => x.uuid === this.props.line_group_uuid)[0];
    }

    async componentDidMount() {
        try {
            document.addEventListener("keydown", (e: Event) => this.keyPressed(e));
            document.addEventListener("keyup", (e: Event) => this.keyUp(e));
            // beware - not all lines in line-group have shift-board enabled (via tags)
            // so the result from the server might be lacking some lines that are present in this linegroup
            const lgd = this.getLineGroupData();
            const line_uuids = lgd.line_uuids;
            const uses_pool = (lgd.tags[LINE_GROUP_TAGS.has_pool] === "true");
            const print_only_assigned_lines = (lgd.tags[LINE_GROUP_TAGS.print_only_assigned_lines] === "true");
            const year = this.state.year;
            const week = this.state.week;
            const data = await this.loadLinesData(line_uuids, year, week);
            let lines_non_pooled = data.lines;
            if (uses_pool) {
                lines_non_pooled = lines_non_pooled
                    .filter(x => x.line_tags[LINE_TAGS.type] !== LINE_TYPES.ordinary);
            }
            const lines_pooled = data.lines.filter(line => lines_non_pooled.indexOf(line) < 0);
            const pool_lines = data.lines.filter(x => x.line_tags[LINE_TAGS.type] === LINE_TYPES.pool);
            const pool_line = pool_lines.length > 0 ? pool_lines[0] : null;
            const worker_hours: Map<string, number> = new Map();
            data.required_persons.forEach(x => worker_hours.set(x.shift_tag, x.required_persons));
            const plants = bl.getPlantsForUser().filter(x => x.uuid === this.props.plant);
            const plant_data = plants[0];
            this.setState({
                loading: false,
                uses_pool,
                print_only_assigned_lines,
                lines: data.lines,
                lines_non_pooled,
                lines_pooled,
                pool_line,
                plant_data,
                hash: data.hash,
                worker_hours: worker_hours
            }, async () => {
                const data_prev = await this.loadLinesData(line_uuids, year, week - 1);
                this.setState({
                    lines_previous_week: data_prev.lines
                });
            });
        } catch (err) {
            this.setState({ loading: false, error: err.message });
        }
    }

    /**
     * Handling any change in checkbox components.
     */
    handleCheckboxChange = (event: Event) => {
        if (event.currentTarget instanceof HTMLInputElement || event.currentTarget instanceof HTMLSelectElement) {
            // read new values
            const target = event.currentTarget;
            const name = target.name;

            if (name === "show_tool_change") {
                if (target instanceof HTMLInputElement) {
                    const show_tool_change = target.checked;
                    this.setState({ show_tool_change });
                    setToggleLocalStorage("showToolChange", show_tool_change);
                }
            } else if (name === "show_work_orders_in_print") {
                if (target instanceof HTMLInputElement) {
                    const show_work_orders_in_print = target.checked;
                    this.setState({ show_work_orders_in_print });
                    setToggleLocalStorage("showWorkOrderPrint", show_work_orders_in_print);
                }
            }
        }
    }

    async exportToHtmlWeekend(): Promise<void> {
        if (this.state.unsaved_changes) {
            alert(translate("Shifts.please_save", "Shifts changed, please save the data."));
            return;
        }

        this.setState({ show_export_week_modal: true, daily_message: "", print_whole_week: false });
    }

    exportToHtmlWeek(include_prev_sunday: boolean): void {
        if (this.state.unsaved_changes) {
            alert(translate("Shifts.please_save", "Shifts changed, please save the data."));
            return;
        }
        this.setState({
            show_export_week_modal: true,
            daily_message: "",
            print_whole_week: true,
            include_prev_sunday
        });
    }

    async startExportToHTMLWeekend(message: string) {
        let lines = this.state.lines_non_pooled;
        this.setState({ show_export_week_modal: false });
        exportWeekToHtml(
            this.state.year,
            this.state.week,
            this.state.include_prev_sunday,
            this.state.show_tool_change,
            lines.map(x => x.line_uuid),
            this.props.uniquePersonName,
            this.props.personWorks12Hours,
            message,
            !this.state.print_whole_week
        );
    }

    async startExportToHtmlDay(day_of_week: number): Promise<void> {
        if (this.state.unsaved_changes) {
            alert(translate("Shifts.please_save", "Shifts changed, please save the data."));
            return;
        }
        // load comment from server
        const year = this.state.year;
        const week = this.state.week;
        const key = `${STORAGE_PREFIX}.${year}.${week}.${day_of_week}.${this.props.line_group_uuid}`;
        const data = await getBackend().common.getKeyValue({ key });
        let daily_message = "";
        if (data.data) {
            daily_message = data.data.message || "";
        }
        this.setState({ show_export_day_modal: true, export_day_of_week: day_of_week, daily_message });
    }

    async exportToHtmlDay(message: string): Promise<void> {
        this.setState({ error: "" });
        try {
            this.setState({ show_export_day_modal: false });
            const day_of_week = this.state.export_day_of_week;
            const year = this.state.year;
            const week = this.state.week;
            const plant_data = bl.getPlantsForUser().filter(x => x.uuid === this.props.plant)[0];
            const mode = bl.getLineGroupTagStr(this.props.line_group_uuid, LINE_GROUP_TAGS.day_shift_assignment_format, "123");
            const show_tool_change = this.state.show_tool_change;
            const show_work_orders = this.state.show_work_orders_in_print;
            // skip pool line for daily export
            const lines = this.state.lines.filter(x => x.line_tags[LINE_TAGS.type] !== LINE_TYPES.pool);
            const group_by_people = bl.getLineGroupTagBool(this.props.line_group_uuid, LINE_GROUP_TAGS.shift_assignment_group_by_people, false);
            const print_only_assigned_lines = this.state.print_only_assigned_lines;
            await exportDayToHtml(
                year, week, day_of_week,
                plant_data.title, this.getLineGroupData().title,
                show_tool_change, show_work_orders,
                lines.map(x => x.line_uuid), message, mode, group_by_people, print_only_assigned_lines,
                this.props.personWorks12Hours
            );

            // save comment to server
            const key = `${STORAGE_PREFIX}.${year}.${week}.${day_of_week}.${this.props.line_group_uuid}`;
            const data = { message };
            await getBackend().common.setKeyValue({ key, data });
            this.setState({ daily_message: message });
        } catch (err) {
            this.setState({ error: err.message });
        }
    }

    async saveChanges() {
        publish(MSG_TYPES.modal_loader, { open: true });

        this.setState({ error: "" });

        try {
            const req: t.IStorePeopleShiftAssignmentsReq = {
                hash: this.state.hash,
                lines: this.state.lines.map((x): t.IShiftPersonLineStoreRec => ({
                    line_uuid: x.line_uuid,
                    shifts: x.shifts.map((shift): t.IShiftPersonLineStoreRecItem => ({
                        person_uuids: shift.persons.map(person => person.uuid),
                        shift_tag: shift.shift_tag
                    }))
                })),
                week: this.props.week,
                year: this.props.year
            };
            const res = await getBackend().manufacturing.storePeopleShiftAssignments(req);
            this.setState({ hash: res.hash, unsaved_changes: false });
            this.props.handleShiftsSaved();
            publish(MSG_TYPES.modal_loader, { open: false });
        } catch (err) {
            this.setState({ error: err.message });
            publish(MSG_TYPES.modal_loader, { open: false });
        }
    }

    mouseOver(i: number, j: number): void {
        if (this.state.i !== i || this.state.j !== j) {
            this.setState({ i, j });
        }
    }

    clearShift(i: number, j: number): void {
        this.setState(
            prevState => {
                const lines = this.state.uses_pool ? prevState.lines_non_pooled : prevState.lines;
                const line_tags = lines[i].line_tags;
                if (this.state.uses_pool && line_tags.type === LINE_TYPES.pool) {
                    lines[i].shifts[j].persons = [];
                    const lines_pooled = prevState.lines_pooled;
                    for (const line of lines_pooled) {
                        line.shifts[j].persons = [];
                    }
                    return { lines, lines_pooled, unsaved_changes: true };
                } else {
                    lines[i].shifts[j].persons = [];
                    return { lines, unsaved_changes: true };
                }
            },
            () => {
                this.props.handlePersonAddedToSchedule();
            });
    }

    mouseDown(i: number, j: number): void {
        if (this.state.delete_mode) {
            this.clearShift(i, j);
            return;
        }
        if (this.props.selectedPerson === null) {
            return;
        }
        const person_uuid = this.props.selectedPerson.uuid;
        const person_name = this.props.selectedPerson.name;
        let show_worker_alert = false;
        const personAddToOrRemoveFromList = (shift: t.IShiftPersonLineRecItem, line_tags: t.ITags) => {
            if (shift.persons.some(x => x.uuid === person_uuid)) {
                // show alert if user wants to remove person from the pool and is already asigned to lines
                if (this.state.uses_pool && line_tags.type === LINE_TYPES.pool) {
                    const assigned_workers: Set<string>[] = this.findAssignedWorkers();
                    if (assigned_workers[shift.shift_number].has(person_uuid)) {
                        show_worker_alert = true;
                        return;
                    }
                }
                // remove person from list
                shift.persons = shift.persons.filter(x => x.uuid !== person_uuid);
            } else {
                // add person to list
                shift.persons.push({ name: person_name, uuid: person_uuid });
                shift.persons = shift.persons.sort((a, b) => u.stringCompare(a.name, b.name));
            }
        };
        this.setState(
            prevState => {
                const lines = this.state.uses_pool ? prevState.lines_non_pooled : prevState.lines;
                const line_tags = lines[i].line_tags;
                if (prevState.key === "Control") {
                    j = j % 3;
                    for (let k = 0; k < 5; k++) {
                        const shift = lines[i].shifts[3 * k + j];
                        personAddToOrRemoveFromList(shift, line_tags);
                    }
                } else {
                    const shift = lines[i].shifts[j];
                    personAddToOrRemoveFromList(shift, line_tags);
                }
                return this.state.uses_pool
                    ? { lines_non_pooled: lines, unsaved_changes: true, show_worker_alert }
                    : { lines, unsaved_changes: true };
            },
            () => {
                this.props.handlePersonAddedToSchedule();
            });
    }

    keyPressed(e: any): void {
        this.setState(prevState => {
            // if modal is shown, exit
            if (prevState.show_export_day_modal === true) return;

            // initial positions
            let j = prevState.j;
            let i = prevState.i;
            if (i === -1 && j === -1) {
                return;
            }
            const lines = prevState.lines;
            if (prevState.key !== e.key) {
                if (e.key === "ArrowRight") {
                    j++;
                    if (j > 20) {
                        j = 20;
                    }
                } else if (e.key === "ArrowLeft") {
                    j--;
                    if (j < 0) {
                        j = 0;
                    }
                } else if (e.key === "ArrowDown") {
                    i++;
                    if (i > lines.length - 1) {
                        i = lines.length - 1;
                    }
                } else if (e.key === "ArrowUp") {
                    i--;
                    if (i < 0) {
                        i = 0;
                    }
                } else if (e.key === "Enter") {
                    this.mouseDown(i, j);
                } else if (e.key === "Delete") {
                    lines[i].shifts[j].persons = [];
                }

                return { i, j, key: e.key, lines };
            }
        });
    }

    keyUp(e: any): void {
        if (this.state.key !== null) {
            this.setState({ key: null });
        }
    }

    handleClickRow(i: number): void {
        this.setState(prevState => {
            const line = prevState.lines[i];
            line.shifts.forEach(shift => {
                shift.enabled = !shift.enabled;
            });
            return { i, lines: prevState.lines, unsaved_changes: true };
        });
    }

    // if line is type tool_setup, check if person is competent adjuster
    isPersonCompetentAdjuster(line: t.IShiftPersonLineRec, person: t.IShiftPersonLineRecPerson): boolean {
        if (line.line_tags[LINE_TAGS.type] === LINE_TYPES.tool_setup) {
            return this.props.isPersonAdjuster(person.uuid);
        } else {
            return false;
        }
    }

    generateShiftTable(week: number, year: number, lines: t.IShiftPersonLineRec[], worker_hours: Map<string, number>) {
        if (this.state.loading) {
            return <Loader />;
        }

        let monday = u.getDateOfISOWeek(week, year);
        let daysRow = u.dayStrings().map((day, i) => {
            // add a day in the next line
            const date = monday.getDate() + ". " + (monday.getMonth() + 1) + ".";
            // add a day
            monday = new Date(monday.getTime() + 1 * u.TIME_RANGES.DAY);

            let css = "th-wrapper shift-table-days text-center width20px cursor-pointer date td-right-border";
            if (i === 6) {
                css += " shift-table-header-sunday";
            }
            if (i === 5) {
                css += " shift-table-header-saturday";
            }
            return (
                <th
                    key={i} className={css} colSpan="3" scope="col"
                    onClick={() => this.startExportToHtmlDay(i)}>
                    {day} - {date}
                </th>
            );
        });

        let shifts = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];

        let shiftsHeader = shifts.map((shift, i) => {
            let types = ["1", "2", "3"];
            let type = types[i % 3];
            const css = "th-wrapper shift-table-days shift text-center width20px" + (i % 3 === 2 ? " td-right-border" : "");
            return (
                <th key={i} className={css}>
                    {type}
                </th>
            )
        });

        let neededWorkersRow = shifts.map((shift, i) => {
            const shift_tag = shiftTag(year, week, shift);
            const hours = worker_hours.get(shift_tag) !== undefined ? worker_hours.get(shift_tag) : 0;
            // $FlowFixMe
            const value = worker_hours.has(shift_tag) ? niceNumber(Math.ceil(hours / 8), 0) : "-";
            const css = "th-wrapper shift-table-days required_workers text-center width20px" + (i % 3 === 2 ? " td-right-border" : "");
            return (
                <th key={"required_worker_" + shift + "_" + i} className={css}>{value}</th>
            )
        });

        // for each day+person combination count the number of shifts and lines
        // if person is assigned to more than 1 shift per day, draw special indicator
        const counterPersonDay: Map<string, number> = new Map();
        const tagForDay = (shift: t.IShiftPersonLineRecItem) =>
            shift.shift_tag.substr(0, 6) + Math.floor(shift.shift_number / 3);
        for (const line of lines) {
            for (const shift of line.shifts) {
                const dayTag = tagForDay(shift); // YYYYWWD
                for (const person of shift.persons) {
                    const key = `${person.uuid}.${dayTag}`;
                    if (!counterPersonDay.has(key)) {
                        counterPersonDay.set(key, 0);
                    }
                    counterPersonDay.set(key, counterPersonDay.get(key) + 1);
                }
            }
        }

        const overworked_people: Set<string> = this.findOverworkedIndicators();
        const assigned_workers: Set<string>[] = this.findAssignedWorkers();
        const order_text = translate("common.order", "Order");
        const change_to_text = translate("ShiftDetails.change_to", "Change to");

        const show_required_persons_indicators = (bl.getLineGroupTagStr(this.props.line_group_uuid, LINE_GROUP_TAGS.planning_mode, PLANNING_MODE.lines_first) === PLANNING_MODE.lines_first);
        let has_pool_line = false;
        let linesTable = lines.map((line, i) => {
            const line_is_pool = (line.line_tags[LINE_TAGS.type] === LINE_TYPES.pool);
            has_pool_line = has_pool_line || line_is_pool;
            const line_is_ordinary = (line.line_tags[LINE_TAGS.type] === LINE_TYPES.ordinary);
            let shiftsRow = shifts.map((shift, j) => {
                const shiftData = line.shifts[j];
                let classTD = "colorEmpty";
                let titleTd = "";
                if ((i === this.state.i) && (j === this.state.j)) {
                    classTD = "shift-selected";
                }

                if (this.state.key === "Control") {
                    if ((i === this.state.i) && (j % 3 === this.state.j % 3) && (j < 15)) {
                        classTD = "shift-selected";
                    }
                }

                if (shiftData.persons.length > 0) {
                    classTD += " shift-person";
                }

                // disable shift for ordinary lines only
                // for past shifts: disable only based on enabled status from db
                // for current and future shifts there are two styles
                //  - disabled line
                //  - unallocated line (enabled but no work scheduled)
                if (line_is_ordinary) {
                    if (shiftData.worker_hours === null) {
                        // past shift
                        if (!shiftData.enabled) {
                            classTD += " shift-disabled";
                        }
                    } else if (shiftData.worker_hours === 0) {
                        // current or future
                        if (shiftData.enabled) {
                            classTD += " shift-unallocated";
                        } else {
                            classTD += " shift-disabled";
                        }
                    }
                }

                if (this.state.show_tool_change && shiftData.tool_changes.length > 0) {
                    classTD += " tool_setup";
                    titleTd += shiftData.tool_changes
                        .map(i => `${change_to_text}: ${i.material_ext_id}, ${i.title} (${order_text} ${i.order_external_id})`)
                        .join("\n");
                }

                classTD += " text-center td-shift-name td-wrapper";
                let persons = shiftData.persons
                    .map(person => {
                        let classTD = "";
                        const dayTag = tagForDay(shiftData);
                        const keyPersonShift = `${person.uuid}.${shiftData.shift_tag}.${i}`;
                        const keyPersonDay = `${person.uuid}.${dayTag}`;
                        let indicators = [];
                        const dayShiftsForThisPerson = counterPersonDay.get(keyPersonDay) || 0;
                        if (dayShiftsForThisPerson > 1 && this.state.showNX) {
                            indicators.push(createBullet(
                                "warning",
                                dayShiftsForThisPerson + "x",
                                translate("ShiftDetails.IconText.shiftNx", "Assigned to multiple lines within the same day")
                            ));
                        }
                        if (!this.props.canPersonWorkInShift(person.uuid, shiftData.shift_number % 3) && this.state.showS) {
                            indicators.push(createBullet(
                                "error",
                                translate("ShiftDetails.Icon.shift", "S"),
                                translate("ShiftDetails.IconText.shift", "Invalid shift")
                            ));
                        }
                        if (overworked_people.has(person.uuid) && this.state.showR) {
                            indicators.push(createBullet(
                                "error",
                                translate("ShiftDetails.Icon.reduced_workshift", "R"),
                                translate("ShiftDetails.IconText.reduced_workshift", "Assigned to too many shifts")
                            ));
                        }

                        // unassigned workers in pool
                        if (line_is_pool && !assigned_workers[shift].has(person.uuid)) {
                            classTD += " pool-edit-person-not-assigned";
                        }

                        if (!this.props.canPersonWorkOnLine(person.uuid, line.line_uuid) &&
                            !IGNORE_COMPETENCE_FLAG.includes(line.line_tags[LINE_TAGS.type]) &&
                            !this.isPersonCompetentAdjuster(line, person) &&
                            this.state.showC) {

                            indicators.push(createBullet(
                                "info",
                                translate("ShiftDetails.Icon.competence", "C"),
                                translate("ShiftDetails.IconText.competence", "Unqualified for the line")
                            ));
                        }
                        // check if person works 12h shifts
                        if (this.props.personWorks12Hours(person.uuid)) {
                            classTD += " person-12h-shift";
                        }
                        return {
                            classTD,
                            key: keyPersonShift,
                            indicators,
                            name: this.props.uniquePersonName(person.uuid)
                        };
                    })
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map(x => <div key={x.key} className={x.classTD}> {x.indicators} {x.name} </div>);

                const person_uuid = (this.props.selectedPerson ? this.props.selectedPerson.uuid : null);
                if (person_uuid && shiftData.persons.some(x => x.uuid === person_uuid)) {
                    classTD += " shift-person-selected";
                }

                return (
                    <td key={j}
                        onMouseOver={() => this.mouseOver(i, j)}
                        onClick={() => this.mouseDown(i, j)}
                        className={classTD}
                        title={titleTd}>
                        {/* <div className="clear-button" onClick={() => this.clearShift(i, j)}>X</div> */}
                        {/* Manage wrapping of the text inside a fixed width cell // TODO */}
                        <div>
                            <span>
                                {persons}
                            </span>
                        </div>
                    </td>
                );
            });

            const lineTitleCss = getLineTitleCssClass(line.line_tags);

            return (
                <tr key={i}>
                    <td className="td-wrapper width20px text-center"
                        onMouseDown={() => { this.handleClickRow(i); }}>
                        <div className={lineTitleCss}>{line.line_title}</div>
                    </td>
                    {shiftsRow}
                </tr>
            );
        });

        // in case we have a pool, add extra line at the bottom
        if (has_pool_line && show_required_persons_indicators) {
            // prepare shift statistics
            const shift_stats: ShiftStatistics[] = this.prepareShiftStatistics();
            linesTable.push(
                <tr key={linesTable.length}>
                    <td className="td-wrapper width20px text-center"></td>
                    {shifts.map(shift => (
                        <td key={shift} className="colorEmpty">
                            <button className="btn btn-secondary" onClick={() => this.setState({ showPoolEditor: true, poolEditorShift: shift })}>
                                <FormattedMessage id="ShiftDetails.schedule" defaultMessage="Schedule" /><br />
                                {shift_stats[shift].scheduled_lines} / {shift_stats[shift].active_lines}
                            </button>
                        </td>
                    ))}
                </tr>
            );
        }

        return (
            <table className="table table-shifts table-bordered" id="table-shifts">
                <thead>
                    <tr>
                        <th scope="col" className="th-wrapper shift-table-days date text-center width20px">{translate("common.day", "Day")}</th>
                        {daysRow}
                    </tr>
                    <tr>
                        <th scope="col" className="th-wrapper shift-table-days shift text-center width20px">{translate("common.shift", "Shift")}</th>
                        {shiftsHeader}
                    </tr>
                    {show_required_persons_indicators && <tr>
                        <th scope="col" className="th-wrapper shift-table-days required_workers text-center width20px">{translate("Shifts.estimated_number_of_people", "Predvideno potrebno število ljudi")}</th>
                        {neededWorkersRow}
                    </tr>}
                </thead>
                <tbody>
                    {linesTable}
                </tbody>
            </table>
        );
    }

    // For each shift find assigned workers
    findAssignedWorkers(): Set<string>[] {
        const assigned_workers = [];
        for (let s = 0; s < 21; s++) {
            assigned_workers.push(new Set());
        }
        for (const line of this.state.lines) {
            if (line.line_tags[LINE_TAGS.type] === LINE_TYPES.ordinary) {
                for (const shift of line.shifts) {
                    for (const person of shift.persons) {
                        assigned_workers[shift.shift_number].add(person.uuid);
                    }
                }
            }
        }
        return assigned_workers;
    }

    // For each shift count the number of active lines and scheduled lines
    prepareShiftStatistics(): ShiftStatistics[] {
        const shift_stats = [];
        for (let s = 0; s < 21; s++) {
            shift_stats.push({
                active_lines: 0,
                scheduled_lines: 0
            });
        }
        for (const line of this.state.lines) {
            for (const shift of line.shifts) {
                if (line.line_tags[LINE_TAGS.type] === LINE_TYPES.ordinary) {
                    if (shift.enabled) {
                        shift_stats[shift.shift_number].active_lines += 1;
                        if (shift.persons.length > 0) {
                            shift_stats[shift.shift_number].scheduled_lines += 1;
                        }
                    }
                }
            }
        }
        return shift_stats;
    }

    /** Extracts week-start-shift offset from plant's tags */
    getWeekStartShiftOffset(): number {
        const pd = this.state.plant_data;
        if (!pd || !pd.tags || !pd.tags[PLANT_TAGS.week_start_shift_offset]) {
            return 0;
        }
        return parseInt(pd.tags[PLANT_TAGS.week_start_shift_offset], 10);
    }

    /** For each person, count the number of shifts she is planned to work */
    findOverworkedIndicators(): Set<string> {
        const lines: t.IShiftPersonLineRec[] = this.state.lines;
        const lines_previous_week: t.IShiftPersonLineRec[] = this.state.lines_previous_week;
        const lines_combined = lines.concat(lines_previous_week);

        // determine start and end shifts to use for calculation
        const week_start_shift_offset = this.getWeekStartShiftOffset();

        const SHIFTS_IN_WEEK = 7 * 3; // days_in_week * shifts_in_day
        const year = this.state.year;
        // Changes week number considering week_start_shift_offset
        // Example #1: this.state.week = 41 and week_start_shift_offset = 1 ==> week = 41
        // Example #2: this.state.week = 41 and week_start_shift_offset = -1 ==> week = 40
        // Example #3: this.state.week = 41 and week_start_shift_offset = 22 ==> week = 42
        const week = Math.floor(this.state.week + (week_start_shift_offset / SHIFTS_IN_WEEK));
        // Generates shiftTag based on year, week and week_start_shift_offset
        // Example #1: year = 2020, week = 41, week_start_shift_offset = 0 ==> shiftTag = "20204100"
        // Example #2: year = 2020, week = 41, week_start_shift_offset = 1 ==> shiftTag = "20204101"
        // Example #3: year = 2020, week = 41, week_start_shift_offset = -1 ==> shiftTag = "20204020"
        const min_tag = shiftTag(year, week, positiveModulo(week_start_shift_offset, SHIFTS_IN_WEEK));
        const max_tag = shiftTag(year, week + 1, positiveModulo(week_start_shift_offset, SHIFTS_IN_WEEK));

        const counter_person_shifts: Map<string, number[]> = new Map();
        for (const line of lines_combined) {
            for (const shift of line.shifts) {
                // filter if shift should not be included into calculation
                if (shift.shift_tag < min_tag || shift.shift_tag >= max_tag) {
                    continue;
                }
                for (const person of shift.persons) {
                    const person_shifts = counter_person_shifts.get(person.uuid);
                    if (person_shifts !== undefined) {
                        person_shifts.push(shift.shift_number);
                    } else {
                        counter_person_shifts.set(person.uuid, [shift.shift_number]);
                    }
                }
            }
        }
        const overworked_people: Set<string> = new Set();
        for (const [person_uuid, shifts] of counter_person_shifts.entries()) {
            const person_shifts = (new Set(shifts)).size;
            const person_max_shifts = this.props.personMaxNumberShifts(person_uuid);
            if (person_max_shifts < person_shifts) {
                overworked_people.add(person_uuid);
            }
        }
        return overworked_people;
    }

    renderTags(tags: t.ITags) {
        const tag_items = [];
        for (const tag in tags) {
            const label =
                // first try if we have native translation
                hasTranslation(`Manufacturing.People.${tag}`) ?
                    translate(`Manufacturing.People.${tag}`) :
                    // if not, try tag friendly title
                    hasTranslation(`tag_groups.PEOPLE_TAGS.values.title.${tag.trim()}`) ?
                        translate(`tag_groups.PEOPLE_TAGS.values.title.${tag.trim()}`) :
                        // no luck, show tag
                        tag;
            const value = tags[tag];
            tag_items.push(<dt>{label}</dt>, <dd>{value}</dd>);
        }
        return tag_items;
    }

    onShowCompetences = (key: toggleNamesCompetences) => {
        return () => {
            const value = this.state[key];
            setToggleLocalStorage(key, !value);
            this.setState({ [key]: !value });
        }
    }

    renderWorkerAlert() {
        const close = (() => this.setState({ show_worker_alert: false }));
        return (
            <Modal show={this.state.show_worker_alert} onHide={close}>
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id="common.warning" defaultMessage="Warning" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <FormattedMessage id="ShiftDetails.unable_to_remove_worker" defaultMessage="Unable to remove worker" />
                    </div>
                    <div>
                        <FormattedMessage id="ShiftDetails.remove_worker_from_lines" defaultMessage="" />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={close} className="btn btn-outline-primary">
                        <FormattedMessage id="common.ok" defaultMessage="Close" />
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }

    renderDisplaySettingsModal() {
        const close = (() => this.setState({ show_settings_editor: false }));
        return (
            <Modal show={this.state.show_settings_editor} onHide={close}>
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id="ShiftDetails.view_settings" defaultMessage="View settings" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <label>
                            <FormattedMessage id="common.show" defaultMessage="Show" /> {translate("ShiftDetails.Icon.competence", "C")}
                            : <FormattedMessage id="ShiftDetails.IconText.competence" defaultMessage="Person assigned to a line that he is unqualified for" />
                        </label>
                        <br />
                        <Toggle name="toggle_show_c" checked={this.state.showC} onChange={this.onShowCompetences("showC")} />
                    </div>
                    <div className="form-group">
                        <label>
                            <FormattedMessage id="common.show" defaultMessage="Show" /> Nx
                            : <FormattedMessage id="ShiftDetails.IconText.shiftNx" defaultMessage="Person assigned to multiple (N) shifts within the same day" />
                        </label>
                        <br />
                        <Toggle name="toggle_show_nx" checked={this.state.showNX} onChange={this.onShowCompetences("showNX")} />
                    </div>
                    <div className="form-group">
                        <label>
                            <FormattedMessage id="common.show" defaultMessage="Show" /> {translate("ShiftDetails.Icon.shift", "S")}
                            : <FormattedMessage id="ShiftDetails.IconText.shift" defaultMessage="Person assigned to invalid shift" />
                        </label>
                        <br />
                        <Toggle name="toggle_show_s" checked={this.state.showS} onChange={this.onShowCompetences("showS")} />
                    </div>
                    <div className="form-group">
                        <label>
                            <FormattedMessage id="common.show" defaultMessage="Show" /> {translate("ShiftDetails.Icon.reduced_workshift", "R")}
                            : <FormattedMessage id="ShiftDetails.IconText.reduced_workshift" defaultMessage="Assigned to too many shifts" />
                        </label>
                        <br />
                        <Toggle name="toggle_show_r" checked={this.state.showR} onChange={this.onShowCompetences("showR")} />
                    </div>
                    <div className="form-group">
                        <label>
                            <FormattedMessage id="common.show_tool_change" defaultMessage="Show tool change" />
                        </label>
                        <br />
                        <Toggle name="show_tool_change" checked={this.state.show_tool_change} onChange={this.handleCheckboxChange} />
                    </div>
                    <h2>{translate("ShiftDetails.print_daily_settings")}</h2>
                    <div className="form-group">
                        <label>
                            <FormattedMessage id="ShiftDetails.print_daily_show_work_orders" defaultMessage="Display work-orders" />
                        </label>
                        <br />
                        <Toggle name="show_work_orders_in_print" checked={this.state.show_work_orders_in_print} onChange={this.handleCheckboxChange} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={close} className="btn btn-outline-primary">
                        <FormattedMessage id="common.close" defaultMessage="Close" />
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }

    handlePoolEditorResult(res: PoolEditModalType) {
        if (res.hasConfirmed) {
            this.setState(
                prevState => {
                    const lines = prevState.lines;
                    // override line assignments for lines
                    for (const line_assign of res.assignments) {
                        for (const line of lines) {
                            if (line.line_uuid !== line_assign.line_uuid) {
                                continue;
                            }
                            line.shifts[res.shift_idx].persons = line_assign.persons;
                        }
                    }
                    return {
                        lines,
                        showPoolEditor: false,
                        hash: res.hash
                    };
                },
                () => {
                    this.props.handlePersonAddedToSchedule();
                    // save changes everytime modal pool editor is closed
                    this.saveChanges();
                });
        } else {
            this.setState({ showPoolEditor: false });
        }
    }

    handleClickShiftAssignmentDetailsExportDayModal = (message: string) => {
        if (this.state.show_export_day_modal) {
            this.exportToHtmlDay(message)
        } else {
            this.startExportToHTMLWeekend(message);
        }
    }

    /**
     * Rendering JSX for current component.
     */
    render() {
        // list of available lines
        let lines = [];
        if (this.state.lines !== undefined) {
            for (let i = 0; i < this.state.lines.length; i++) {
                let line = this.state.lines[i];
                lines.push({ label: line.line_title, value: line.line_uuid });
            }
        }

        // only show form if a person is selected and not null
        if (
            !this.props.selectedPerson ||
            (this.props.selectedPerson.uuid === "") ||
            (this.props.selectedPerson.uuid === undefined)
        ) {
            return null;
        }
        // extract selected person data
        const selectedPerson = this.props.selectedPerson;
        const selected_name = selectedPerson.name;
        const selected_external_id = selectedPerson.external_id;
        const isAdjuster = selectedPerson.adjuster;
        let shift_preference = selectedPerson.shift_preference;

        let shifts = "--";
        if (shift_preference !== undefined) {
            let enabled_shifts = 0;
            shifts = shift_preference.map((val, i) => {
                let shift = i + 1;
                if (val === true) {
                    enabled_shifts += 1;
                    return (
                        <span key={i} className={"badge badge-shift badge-shift-" + shift}>{shift}</span>
                    );
                } else {
                    return null;
                }
            });
            if (enabled_shifts === 0) {
                shifts = "--";
            }
        }

        let shiftTable = null;
        if (this.state.lines) {
            shiftTable = this.generateShiftTable(this.state.week, this.state.year, this.state.lines_non_pooled, this.state.worker_hours);
        }
        // render the whole component
        return [
            <div id="shift-assignments-details">
                <div className="tab-content">
                    <div className="tab-pane active d-flex flex-column" id="home">
                        <div className="d-flex">
                            <div className="button-list order-last ml-auto text-right max-width-50perc">
                                <dl className="property-list shift-legend">
                                    {(this.state.showC || this.state.showNX || this.state.showS || this.state.showR) && <React.Fragment><dt>{translate("common.legend", "Legend")}:</dt><dd></dd></React.Fragment>}
                                    {
                                        this.state.showC &&
                                        <React.Fragment>
                                            <dt>{createBullet("info", translate("ShiftDetails.Icon.competence", "C"), null, true)}</dt>
                                            <dd><FormattedMessage id="ShiftDetails.IconText.competence" defaultMessage="Person assigned to a line that he is unqualified for" /></dd>
                                        </React.Fragment>
                                    }
                                    {
                                        this.state.showNX &&
                                        <React.Fragment>
                                            <dt>{createBullet("warning", "Nx", null, true)}</dt>
                                            <dd><FormattedMessage id="ShiftDetails.IconText.shiftNx" defaultMessage="Person assigned to multiple (N) shifts within the same day" /></dd>
                                        </React.Fragment>
                                    }
                                    {(this.state.showC && this.state.showNX && this.state.showS && this.state.showR)}
                                    {
                                        this.state.showS &&
                                        <React.Fragment>
                                            <dt>{createBullet("error", translate("ShiftDetails.Icon.shift", "S"), null, true)}</dt>
                                            <dd><FormattedMessage id="ShiftDetails.IconText.shift" defaultMessage="Person assigned to invalid shift" /></dd>
                                        </React.Fragment>
                                    }
                                    {
                                        this.state.showR &&
                                        <React.Fragment>
                                            <dt>{createBullet("error", translate("ShiftDetails.Icon.reduced_workshift", "R"), null, true)}</dt>
                                            <dd><FormattedMessage id="ShiftDetails.IconText.reduced_workshift" defaultMessage="Assigned to too many shifts" /></dd>
                                        </React.Fragment>
                                    }
                                    <button className="btn btn-link" onClick={() => { this.setState({ show_settings_editor: true }); return false; }}>
                                        <FormattedMessage id="Header.menu.settings" defaultMessage="Settings" />...
                                    </button>
                                    {this.renderDisplaySettingsModal()}
                                    {this.renderWorkerAlert()}
                                </dl>
                                <div>
                                    {this.state.delete_mode && <div className="d-inline shift-board-alert mr-2 mb-2">
                                        <FormattedMessage id="Shifts.deletion_enabled" defaultMessage="Deletion enabled" />
                                    </div>}
                                    <div className="d-inline mr-2">
                                        <input type="checkbox"
                                            checked={this.state.delete_mode}
                                            onChange={() => { this.setState({ delete_mode: !this.state.delete_mode }) }} />
                                    </div>
                                    <FormattedMessage id="Shifts.deletion" defaultMessage="Deletion" />
                                </div>
                                {this.state.unsaved_changes && <div>
                                    <div className="shift-board-alert mb-0">
                                        <FormattedMessage id="Shifts.please_save" defaultMessage="SHIFTS CHANGED - PLEASE SAVE" />
                                    </div>
                                </div>}
                            </div>
                            <div className="order-first">
                                <h5 className="pane-title">
                                    {selected_name}
                                    <Link to={`/digital-twin/resources/person/view/${selectedPerson.uuid}`} >
                                        <img src="/img/edit.svg" alt="edit" className="edit-icon" />
                                    </Link>
                                </h5>
                                <dl className="property-list shift-details">
                                    <dt><FormattedMessage id="common.id" defaultMessage="ID" /></dt>
                                    <dd>{selected_external_id}</dd>
                                    {isAdjuster &&
                                        <dd>
                                            <span className="badge-list">
                                                <span className="badge badge-primary">
                                                    <FormattedMessage id="common.adjuster" defaultMessage="Adjuster" />
                                                </span>
                                            </span>
                                        </dd>
                                    }
                                    <dt><FormattedMessage id="common.shifts" defaultMessage="Shifts" /></dt>
                                    <dd><span className="badge-list">{shifts}</span></dd>
                                    {this.renderTags(selectedPerson.tags)}
                                </dl>
                            </div>
                        </div>
                    </div>
                </div>
            </div>,
            <div id="shift-assignments-main">
                {(this.state.error.length > 0) && <div><ErrorComponent msg={this.state.error} type="error" /></div>}
                <div className="tab-content">
                    <div className="tab-pane active d-flex flex-column" id="home">
                        {shiftTable}
                        <ShiftAssignmentDetailsExportDayModal
                            show={this.state.show_export_day_modal || this.state.show_export_week_modal}
                            printOnlyAssignedWorkplaces={this.state.print_only_assigned_lines}
                            initialMessage={this.state.daily_message}
                            handleClick={this.handleClickShiftAssignmentDetailsExportDayModal}
                            handleCancel={() => this.setState({ show_export_day_modal: false, show_export_week_modal: false })}
                        />
                    </div>
                </div>
            </div>,
            this.state.uses_pool && this.state.pool_line && <ShiftAssignmentPoolEdit
                week={this.props.week} year={this.props.year}
                show={this.state.showPoolEditor}
                lines={this.state.lines}
                lines_pooled={this.state.lines_pooled}
                pool_line={this.state.pool_line}
                shift_idx={this.state.poolEditorShift}
                uniquePersonName={this.props.uniquePersonName}
                hash={this.state.hash}
                onClose={(res) => this.handlePoolEditorResult(res)}
            />
        ];
    }
}

export default ShiftAssignmentDetails;
