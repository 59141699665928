// @flow
import React from "react";
import { connect } from "react-redux";
import UserStep from "./UserStep";

import { getBackend as getBackend2 } from "../../lib/backend/Backend2";
import TicketDetailsModal from "./TicketDetailsModal";

import type { ReduxState } from "../redux/reducers";
import type { TicketInboxData } from "./CommonTicketTypes";
type Props = {
    ticket_uuid: string,
    reduxDispatch: (args: any) => void,
    is_kiosk: boolean,
};
type State = {
    ticket: TicketInboxData,
    error: string,
    title: string | null,
    successfull_save: boolean,
    show_modal: boolean,
    user_step: string | null
};

class InboxItem extends React.Component<Props, State> {

    state: State = {
        ticket: {
            user_step: null,
            title: "",
            workflow_title: ""
        },
        error: "",
        show_modal: false,
        title: "",
        user_step: null,
        successfull_save: false
    }

    componentDidMount() {
        this.load();
    }

    componentDidUpdate(prev_props: Props) {
        if (prev_props.ticket_uuid !== this.props.ticket_uuid) {
            this.load();
        }
    }

    componentWillUnmount() {
        this.setState({ user_step: null, title: null, show_modal: false });
    }

    async load() {
        const ticket_uuid = this.props.ticket_uuid;
        if (!ticket_uuid) {
            return null;
        }

        const res_ticket = await getBackend2().ticketing.getTicketBasicInfo({
            uuid: ticket_uuid
        });

        let res_user_step = null
        try {
            res_user_step = await getBackend2().ticketing.getUserStepInfo({
                uuid: ticket_uuid
            });
        } catch (e) {
            console.error(e);
        }

        this.setState({
            ticket: {
                ...res_ticket,
                user_step: res_user_step
            }
        });
    }

    onOpenModal = () => {
        this.setState({ show_modal: true });
    }

    onCloseModal = () => {
        this.setState({ show_modal: false });
    }

    renderModal = () => {
        return <TicketDetailsModal
            show_ticket_details_uuid={this.state.show_modal ? this.props.ticket_uuid : null}
            onClose={this.onCloseModal}
            requestRefreshCallback={() => null}
        />
    }

    render() {
        const ticket = this.state.ticket;
        if (!ticket) {
            return null;
        }

        return (
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 col-md-2 col-lg-3">
                        &nbsp;
                    </div>
                    <div class="col-sm-12 col-md-8 col-lg-6">
                        <UserStep
                            ticket_uuid={this.props.ticket_uuid}
                            reduxDispatch={this.props.reduxDispatch}
                            is_kiosk={this.props.is_kiosk}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    (state: ReduxState) => {
        return {
            ticket_uuid: state.ticketing.selected_inbox_item_uuid
        }
    },
    (dispatch) => ({ reduxDispatch: dispatch })
)(InboxItem);
