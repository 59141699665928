// @flow

//$FlowFixMe
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";

import * as t from "../../lib/backend/manufacturing2.generated.types";
import { getBackend as getBackend2 } from "../../lib/backend/Backend2";
import { getNumber } from "./WeeklyRealizationCommon";
import type { ProductionModalParams } from "./WeeklyRealizationCommon";
import { FormattedMessage } from "react-intl";
import { toISODateTimeString } from "../../lib/Util";
import { INSIGHT_TYPES } from "../../lib/ManufacturingTags.generated";
import * as BusinessLogic from "../../lib/BusinessLogic";

// Visibility controlled from parent component
type Props = {
    show: boolean,
    data: ProductionModalParams,
    onShowProductionModal: Function,
}

type State = {
    production_details_line_title: string,
    production_details_line_uuid: string,
    production_details_start: string,
    production_details_end: string,
    production_details_data: [],
    production_details_insights: []
}

const initialState = {
    production_details_line_title: "",
    production_details_line_uuid: "",
    production_details_start: "",
    production_details_end: "",
    production_details_data: [],
    production_details_insights: []
};

const loadProductionDetails = async (params: ProductionModalParams, setState: Function, props: Props) => {
    const { line_title, line_uuid, material_external_id, start_time, end_time } = params;

    try {
        // reset what we have
        setState({
            production_details_line_title: line_title,
            production_details_line_uuid: line_uuid,
            production_details_start: toISODateTimeString(new Date(start_time)),
            production_details_end: toISODateTimeString(new Date(end_time)),
            production_details_data: [],
            production_details_insights: []
        });

        // get new production realization data for selected time range
        const res_produced = await getBackend2().manufacturing.getProduced({
            end: end_time, start: start_time, uuid: line_uuid
        });

        const production_details_data = res_produced.data.filter(x => x.material_external_id === material_external_id);

        setState({ production_details_data, show: true });

        // load insights to show downtimes and comments
        const insight_criteria: t.IGetInsightsReq = {
            from: start_time,
            to: end_time,
            types: [INSIGHT_TYPES.man_downtime],
            lines: [line_uuid]
        }
        const res_insights = await getBackend2().manufacturing.getInsights(insight_criteria);
        const insights_data = res_insights.insights;
        // filter out insights that start at the end of range or finish at start of range
        const insights = insights_data.filter(insight => {
            return insight.ts_to > start_time && end_time > insight.ts;
        })
        // add to the state
        setState({ production_details_insights: insights });

    } catch (e) {
        setState({ error: e.message });
    }
};

const ProductionModal = (props: Props) => {
    const [ state: State, setStateOrig ] = useState(initialState);
    const setState = (data) => setStateOrig(prevState => {return {...prevState, ...data}});

    useEffect(() => {
        if (props.show) loadProductionDetails(props.data, setState, props);
    }, [props]);

    return (
        <Modal bsSize="large"
            show={props.show}
            onHide={() => props.onShowProductionModal({show: false})}>
            <Modal.Header>
                <Modal.Title>
                    <dl className="property-list">
                        <dd>
                            {state.production_details_line_title}
                        </dd>
                    </dl>
                    <dl>
                        <dd className="small">
                            {state.production_details_start} - {state.production_details_end}
                        </dd>
                    </dl>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4><FormattedMessage id="common.production" defaultMessage="Production" /></h4>

                <table className="table">
                    <colgroup>
                        <col className={"breakdown-border"}></col>
                        <col className={"breakdown-border"}></col>
                        <col className={"breakdown-border"}></col>
                        <col className={"breakdown-border"}></col>
                        <col className={"breakdown-border"}></col>
                    </colgroup>
                    <thead>
                        <tr>
                            <th><FormattedMessage id="common.material" defaultMessage="Material" /></th>
                            <th><FormattedMessage id="common.worker" defaultMessage="Worker" /></th>
                            <th><FormattedMessage id="common.time" defaultMessage="Time" /></th>
                            <th><FormattedMessage id="common.total" defaultMessage="Total" /></th>
                            <th><FormattedMessage id="common.scrap_parts" defaultMessage="Scrap" /></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            state.production_details_data.map((production, i) => (
                                <tr key={"production" + i}>
                                    <td>{production.material_title}</td>
                                    <td>{production.worker_id}</td>
                                    <td>
                                        <FormattedMessage id="Manufacturing.Planning.from" defaultMessage="From" /><span>: </span>
                                        {toISODateTimeString(new Date(production.shift_start_time))}
                                        <br />
                                        <FormattedMessage id="Manufacturing.Planning.until" defaultMessage="Until" /><span>: </span>
                                        {toISODateTimeString(new Date(production.shift_end_time))}
                                    </td>
                                    <td>{production.quantity_produced}</td>
                                    <td>{production.quantity_scrap}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>

                <h4><FormattedMessage id="common.downtimes" defaultMessage="Downtimes" /></h4>

                <table className="table">
                    <colgroup>
                        <col className={"breakdown-border"}></col>
                        <col className={"breakdown-border"}></col>
                        <col className={"breakdown-border"}></col>
                        <col className={"breakdown-border"}></col>
                        <col className={"breakdown-border"}></col>
                    </colgroup>
                    <thead>
                        <tr>
                            <th><FormattedMessage id="common.time" defaultMessage="Time" /></th>
                            <th><FormattedMessage id="common.duration" defaultMessage="Duration" /></th>
                            <th><FormattedMessage id="common.worker" defaultMessage="Worker" /></th>
                            <th><FormattedMessage id="common.downtime" defaultMessage="Downtime" /></th>
                            <th><FormattedMessage id="common.comment" defaultMessage="Comment" /></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            state.production_details_insights.map((insight, i) => (
                                <tr key={"insight" + i}>
                                    <td>
                                        <FormattedMessage id="Manufacturing.Planning.from" defaultMessage="From" /><span>: </span>
                                        {toISODateTimeString(new Date(insight.ts))}
                                        <br />
                                        <FormattedMessage id="Manufacturing.Planning.until" defaultMessage="Until" /><span>: </span>
                                        {toISODateTimeString(new Date(insight.ts_to))}
                                    </td>
                                    <td>{getNumber(insight.extra_data.duration, 2)}h</td>
                                    <td>{(insight.tags.person_external_id !== "null") ? insight.tags.person_external_id : "-"}</td>
                                    <td>{BusinessLogic.getInsightDowntimeTitle(insight)}</td>
                                    <td>{insight.extra_data.user_comment}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>

            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-primary" onClick={() => props.onShowProductionModal({show: false})}>
                    <FormattedMessage id="common.back" defaultMessage="Back" />
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default ProductionModal;
