// @flow

/************************************************************************************/
/* WARNING: This file is generated automatically. Don't edit it by hand             */
/************************************************************************************/

import * as api from "./restApi.js";
import * as t from "./reports.generated.types.js";

function enc(s: any): string { return encodeURIComponent("" + s); }

export interface IReports {
    /** Triggers calculation of new report */
    createNewReport(req: t.ICreateNewReportReq): Promise<t.ICreateNewReportRes>;

    /** Retrieves compressed report */
    getCompressedReport(req: t.IGetCompressedReportReq): Promise<t.IGetCompressedReportRes>;

    /** Retrieves status report */
    getReportStatus(req: t.IGetReportStatusReq): Promise<t.IGetReportStatusRes>;

    /** Returns a plan export (operation + start/end times). OBSOLETE */
    getPlanExport(req: t.IGetPlanExportReq): Promise<t.IGetPlanExportRes>;

    /** Schedules a job that returns a plan table export (operation + start/end times) based on two reports (initial and final) */
    getPlanTableExportJob(req: t.IGetPlanTableExportJobReq): Promise<t.IGetPlanTableExportJobRes>;

    /** Returns a plan table export (operation + start/end times) based on two reports (initial and final) */
    getPlanTableExport(req: t.IGetPlanTableExportReq): Promise<t.IGetPlanTableExportRes>;

    /** Executes new export of plan table changes to external system */
    runPlanTableExport(req: t.IRunPlanTableExportReq): Promise<t.IRunPlanTableExportRes>;

    /** Retrieves weekly realization data from report */
    getReportWeeklyRealization(req: t.IGetReportWeeklyRealizationReq): Promise<t.IGetReportWeeklyRealizationRes>;

    /** Computes and returns insights for the given report */
    getReportInsights(req: t.IGetReportInsightsReq): Promise<t.IGetReportInsightsRes>;

    /** Computes KPIs for the plan in the given report */
    getReportKPIs(req: t.IGetReportKPIsReq): Promise<t.IGetReportKPIsRes>;

    /** Retrieves latest report */
    getLatestReport(req: t.IGetLatestReportReq): Promise<t.IGetLatestReportRes>;

    /** Retrieves first shift of the latest report */
    getLatestReportShift(req: t.IGetLatestReportShiftReq): Promise<t.IGetLatestReportShiftRes>;

    /** Performs vacuum-cleaning of dangling reports file */
    cleanDanglingReportFiles(req: t.ICleanDanglingReportFilesReq): Promise<t.ICleanDanglingReportFilesRes>;

    /** Deletes reports according to retention-policy */
    deleteReports(req: t.IDeleteReportsReq): Promise<t.IDeleteReportsRes>;

    /** Evaluate predictions for selected shift against predictions from selected report */
    evalReports(req: t.IEvalReportsReq): Promise<t.IEvalReportsRes>;

    /** Retrieve diffrence between prediction vs realization for given lines */
    predVsReal(req: t.IPredVsRealReq): Promise<t.IPredVsRealRes>;

    /** Registers file token */
    getModelFileToken(req: t.IGetModelFileTokenReq): Promise<t.IGetModelFileTokenRes>;

    /** Retrieves specified report */
    getReport(req: t.IGetReportReq): Promise<t.IGetReportRes>;

    /** Upserts single report */
    upsertReport(req: t.IUpsertReportReq): Promise<t.IUpsertReportRes>;

    /** Deletes single report */
    deleteReport(req: t.IDeleteReportReq): Promise<t.IDeleteReportRes>;

    /** Searches reports */
    searchReports(req: t.ISearchReportsReq): Promise<t.ISearchReportsRes>;

    /** Executes new quick simulation */
    runQuickSimulation(req: t.IRunQuickSimulationReq): Promise<t.IRunQuickSimulationRes>;

    /** Executes new quick simulation in single response */
    runQuickSimulationSync(req: t.IRunQuickSimulationSyncReq): Promise<t.IRunQuickSimulationSyncRes>;

    /** Calculate KPI report for all plants in the org */
    calcKpis(req: t.ICalcKpisReq): Promise<t.ICalcKpisRes>;

    /** Retrieve the latest KPI report for the selected plant */
    latestKpis(req: t.ILatestKpisReq): Promise<t.ILatestKpisRes>;

}

export class Reports implements IReports {

    client: api.ApiClient;

    constructor(client: api.ApiClient) { this.client = client; }

    /** Triggers calculation of new report */
    async createNewReport(req: t.ICreateNewReportReq): Promise<t.ICreateNewReportRes> {
        const reqx: any = req;
        const url = `/api/v1.0/reports/new`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Retrieves compressed report */
    async getCompressedReport(req: t.IGetCompressedReportReq): Promise<t.IGetCompressedReportRes> {
        const reqx: any = req;
        const url = `/api/v1.0/reports/${enc(req.id)}/compressed`;
        delete reqx.id;
        return (await this.client.get(url, reqx)).data;
    }

    /** Retrieves status report */
    async getReportStatus(req: t.IGetReportStatusReq): Promise<t.IGetReportStatusRes> {
        const reqx: any = req;
        const url = `/api/v1.0/reports/${enc(req.id)}/status`;
        delete reqx.id;
        return (await this.client.get(url, reqx)).data;
    }

    /** Returns a plan export (operation + start/end times). OBSOLETE */
    async getPlanExport(req: t.IGetPlanExportReq): Promise<t.IGetPlanExportRes> {
        const reqx: any = req;
        const url = `/api/v1.0/reports/${enc(req.id)}/plan-export`;
        delete reqx.id;
        return (await this.client.get(url, reqx)).data;
    }

    /** Schedules a job that returns a plan table export (operation + start/end times) based on two reports (initial and final) */
    async getPlanTableExportJob(req: t.IGetPlanTableExportJobReq): Promise<t.IGetPlanTableExportJobRes> {
        const reqx: any = req;
        const url = `/api/v1.0/reports/plan-table-export-job`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Returns a plan table export (operation + start/end times) based on two reports (initial and final) */
    async getPlanTableExport(req: t.IGetPlanTableExportReq): Promise<t.IGetPlanTableExportRes> {
        const reqx: any = req;
        const url = `/api/v1.0/reports/plan-table-export`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Executes new export of plan table changes to external system */
    async runPlanTableExport(req: t.IRunPlanTableExportReq): Promise<t.IRunPlanTableExportRes> {
        const reqx: any = req;
        const url = `/api/v1.0/reports/run-plan-table-export`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Retrieves weekly realization data from report */
    async getReportWeeklyRealization(req: t.IGetReportWeeklyRealizationReq): Promise<t.IGetReportWeeklyRealizationRes> {
        const reqx: any = req;
        const url = `/api/v1.0/reports/${enc(req.id)}/weekly_realization`;
        delete reqx.id;
        return (await this.client.get(url, reqx)).data;
    }

    /** Computes and returns insights for the given report */
    async getReportInsights(req: t.IGetReportInsightsReq): Promise<t.IGetReportInsightsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/reports/${enc(req.id)}/insights`;
        delete reqx.id;
        return (await this.client.get(url, reqx)).data;
    }

    /** Computes KPIs for the plan in the given report */
    async getReportKPIs(req: t.IGetReportKPIsReq): Promise<t.IGetReportKPIsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/reports/${enc(req.id)}/kpis`;
        delete reqx.id;
        return (await this.client.get(url, reqx)).data;
    }

    /** Retrieves latest report */
    async getLatestReport(req: t.IGetLatestReportReq): Promise<t.IGetLatestReportRes> {
        const reqx: any = req;
        const url = `/api/v1.0/reports/latest`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Retrieves first shift of the latest report */
    async getLatestReportShift(req: t.IGetLatestReportShiftReq): Promise<t.IGetLatestReportShiftRes> {
        const reqx: any = req;
        const url = `/api/v1.0/reports/latest-shift`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Performs vacuum-cleaning of dangling reports file */
    async cleanDanglingReportFiles(req: t.ICleanDanglingReportFilesReq): Promise<t.ICleanDanglingReportFilesRes> {
        const reqx: any = req;
        const url = `/api/v1.0/reports/vacuum-clean`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Deletes reports according to retention-policy */
    async deleteReports(req: t.IDeleteReportsReq): Promise<t.IDeleteReportsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/reports/apply-retention-policy`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Evaluate predictions for selected shift against predictions from selected report */
    async evalReports(req: t.IEvalReportsReq): Promise<t.IEvalReportsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/reports/eval`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Retrieve diffrence between prediction vs realization for given lines */
    async predVsReal(req: t.IPredVsRealReq): Promise<t.IPredVsRealRes> {
        const reqx: any = req;
        const url = `/api/v1.0/reports/pred_vs_real`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Registers file token */
    async getModelFileToken(req: t.IGetModelFileTokenReq): Promise<t.IGetModelFileTokenRes> {
        const reqx: any = req;
        const url = `/api/v1.0/reports/download-model-file`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Retrieves specified report */
    async getReport(req: t.IGetReportReq): Promise<t.IGetReportRes> {
        const reqx: any = req;
        const url = `/api/v1.0/reports/${enc(req.id)}`;
        delete reqx.id;
        return (await this.client.get(url, reqx)).data;
    }

    /** Upserts single report */
    async upsertReport(req: t.IUpsertReportReq): Promise<t.IUpsertReportRes> {
        const reqx: any = req;
        const url = `/api/v1.0/reports/${enc(req.id)}`;
        delete reqx.id;
        return (await this.client.put(url, reqx)).data;
    }

    /** Deletes single report */
    async deleteReport(req: t.IDeleteReportReq): Promise<t.IDeleteReportRes> {
        const reqx: any = req;
        const url = `/api/v1.0/reports/${enc(req.id)}`;
        delete reqx.id;
        return (await this.client.delete(url, reqx)).data;
    }

    /** Searches reports */
    async searchReports(req: t.ISearchReportsReq): Promise<t.ISearchReportsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/reports`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Executes new quick simulation */
    async runQuickSimulation(req: t.IRunQuickSimulationReq): Promise<t.IRunQuickSimulationRes> {
        const reqx: any = req;
        const url = `/api/v1.0/reports/quick-simulation`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Executes new quick simulation in single response */
    async runQuickSimulationSync(req: t.IRunQuickSimulationSyncReq): Promise<t.IRunQuickSimulationSyncRes> {
        const reqx: any = req;
        const url = `/api/v1.0/reports/quick-simulation-sync`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Calculate KPI report for all plants in the org */
    async calcKpis(req: t.ICalcKpisReq): Promise<t.ICalcKpisRes> {
        const reqx: any = req;
        const url = `/api/v1.0/reports/kpis/calculate`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Retrieve the latest KPI report for the selected plant */
    async latestKpis(req: t.ILatestKpisReq): Promise<t.ILatestKpisRes> {
        const reqx: any = req;
        const url = `/api/v1.0/reports/kpis/latest/${enc(req.plant_uuid)}`;
        delete reqx.plant_uuid;
        return (await this.client.get(url, reqx)).data;
    }

}
