// @flow

import * as React from "react";
import Toggle from "react-toggle";

import ReactTable, { DefaultPagination as Pagination } from "../react/ReactTable";
import {
    LINE_TAGS_ACCESS,
    MATERIAL_TAGS_ACCESS,
    ORDER_TAGS_ACCESS,
    ORG_TAGS_ACCESS
} from "../../lib/ManufacturingTags.generated";
import { ORDER_STATUS, ORDER_TYPE } from "../../lib/ManufacturingConsts.generated";
import { classNames, niceDateTime, niceNumber, createXlsxAndDownload } from "../../lib/Util";
import { getBackend } from "../../lib/backend/Backend2";
import { translate } from "../IntlProviderWrapper";
import Authorization from "../Authorization";
import ManualEntryOrderDeleteModal from "./ManualEntryOrderDeleteModal";
import ManualEntryOrderExportExcelModal from "./ManualEntryOrderExportExcelModal";
import ManualEntryOrderInputModal, { getEndTime } from "./ManualEntryOrderInputModal";
import MultiSelect from "../MultiSelect";
import DateTimePicker from "../DateTimePicker";
import ErrorComponent from "../ErrorComponent";
import * as Auth from "../../lib/Auth";
import * as t from "../../lib/backend/manufacturing2.generated.types";

import type { Cell, ColumnInstance, Row, FilterProps, TableInstance } from "react-table";

import { USER_TAGS } from "../../lib/CommonTags.generated";

type OrderBy =
    | "order"
    | "material"
    | "material_title"
    | "quantity"
    | "line"
    | "comment"
    | "time_start"
    | "time_end"
    | null;

type Line = {
    id: string,
    title: string
};

type TableRow = {
    order: string,
    material: string,
    material_title: string,
    quantity: number,
    line: Line,
    comment: string,
    time_start: number,
    time_end: number,
    required_deadline: number,
    order_actions: t.IOrderModelBase
};

type Props = {
    plant_uuid?: string,
    line_group_uuid: string,
    is_kiosk?: boolean,
};

type State = {
    lines: t.ILineData[],
    orders: t.IOrderModelBase[],
    materials: t.IMaterialModel[],
    rows: TableRow[],
    selected_order: t.IOrderModelBase | null,
    is_loading: boolean,
    is_loading_table: boolean,
    show_input_modal: boolean,
    show_delete_modal: boolean,
    show_export_excel_modal: boolean,
    order_filter: string,
    material_filter: string,
    material_title_filter: string,
    comment_filter: string,
    lines_filter: string[],
    order_by: OrderBy,
    is_ordered_desc: boolean,
    is_archive: boolean,
    page_index: number,
    page_size: number,
    num_rows: number,
    automatic_import: boolean,
    error: string
};

const ORDER_COLUMNS = {
    order: "order",
    material: "material",
    material_title: "material_title",
    quantity: "quantity",
    line: "line",
    comment: "comment",
    time_start: "time_start",
    time_end: "time_end",
    required_deadline: "required_deadline",
    order_actions: "order_actions"
};

const filterLine = (rows: Row<TableRow>[], column_ids: string[], filter_value: string[]): Row<TableRow>[] => {
    return rows.filter(row => filter_value.includes(row.values.line.id));
};

const LineCell = ({ value }: Cell<TableRow, number>) => (
    <div>
        <span>{value.title}</span> <span className="text-muted">({value.id})</span>
    </div>
);

const NumberCell = ({ value }: Cell<TableRow, number>) => niceNumber(value, 0);

class ManualEntryOrders extends React.Component<Props, State> {
    filter_timeout: TimeoutID;
    toggle_sort_by_time_start: Function;
    toggle_sort_by_time_end: Function;

    constructor(props: Props) {
        super(props);
        const state: State = {
            lines: [],
            orders: [],
            materials: [],
            is_loading: false,
            is_loading_table: false,
            rows: [],
            selected_order: null,
            show_input_modal: false,
            show_delete_modal: false,
            show_export_excel_modal: false,
            order_filter: "",
            material_filter: "",
            material_title_filter: "",
            comment_filter: "",
            order_by: null,
            is_ordered_desc: false,
            lines_filter: [],
            is_archive: false,
            page_index: 0,
            page_size: 25,
            num_rows: 0,
            automatic_import: false,
            error: ""
        };
        this.state = state;
    }

    async componentDidMount() {
        this.setState({ is_loading: true });
        try {
            const { tags } = await getBackend().manufacturing.getCurrentOrganisation({});
            this.setState({
                automatic_import: ORG_TAGS_ACCESS.manual_order_entry_automatic_import(tags)
            });
        } catch (err) {
            this.setState({ error: `${err}` });
        }

        this.loadOrders(true);
    }

    async componentDidUpdate(prev_props: Props) {
        if (
            prev_props.plant_uuid !== this.props.plant_uuid ||
            prev_props.line_group_uuid !== this.props.line_group_uuid
        ) {
            this.loadOrders(true);
        }
    }

    getLines = async (): Promise<t.ILineData[]> => {
        const { plant_uuid, line_group_uuid } = this.props;
        try {

            let line_group_uuids: string[];

            if (Auth.getUserTag(USER_TAGS.kiosk) === "true") {
                line_group_uuids = Auth.getUserLineGroups();
            } else {
                line_group_uuids = [line_group_uuid];
            }

            const res = await getBackend().manufacturing.getLines({
                plant_uuid,
                line_group_uuids
            });
            return res.lines.filter(line => LINE_TAGS_ACCESS.manual_entry(line.tags));
        } catch (err) {
            this.setState({ error: `${err}` });
        }

        return [];
    };

    getMaterials = async (external_ids: string[]): Promise<t.IMaterialModel[]> => {
        try {
            const { data } = await getBackend().manufacturing.searchMaterialsSimple({
                external_ids
            });
            return data;
        } catch (err) {
            this.setState({ error: `${err}` });
        }

        return [];
    };

    loadOpenOrders = async (init_lines?: boolean = false, show_table_loader?: boolean = false) => {
        this.setState({ error: "", is_loading: !show_table_loader, is_loading_table: show_table_loader });
        let { lines, lines_filter } = this.state;
        if (init_lines) {
            lines = await this.getLines();
            lines_filter = lines.map(line => line.external_id);
        }

        let orders = [];
        if (lines.length > 0) {
            try {
                const res = await getBackend().manufacturing.getOrdersSimple({
                    line_uuids: lines.map(line => line.uuid),
                    order_types: [ORDER_TYPE.chunk],
                    status: ORDER_STATUS.open
                });
                orders = res.operations.sort((a, b) => b.created_at - a.created_at);
            } catch (err) {
                this.setState({ error: `${err}` });
            }
        }

        const materials = await this.getMaterials([...new Set(orders.map(order => order.material_external_id))]);
        this.setState({
            lines,
            lines_filter,
            orders,
            materials,
            is_loading: false,
            is_loading_table: false
        });
    };

    loadClosedOrders = async (
        init_lines?: boolean = false,
        init_pagination?: boolean = false,
        show_table_loader?: boolean = false
    ) => {
        this.setState({
            error: "",
            is_loading: !show_table_loader,
            is_loading_table: show_table_loader
        });
        let { lines, lines_filter } = this.state;
        if (init_lines) {
            lines = await this.getLines();
            lines_filter = lines.map(line => line.external_id);
        }

        const lines_map: Map<string, string> = new Map();
        for (const line of lines) {
            lines_map.set(line.external_id, line.uuid);
        }

        const line_uuids = [];
        for (const line_id of lines_filter) {
            const line_uuid = lines_map.get(line_id);
            if (line_uuid) {
                line_uuids.push(line_uuid);
            }
        }

        let num_rows = this.state.num_rows;
        if (init_pagination) {
            try {
                const res = await getBackend().manufacturing.getArchivedManualEntryOrdersCount({
                    line_uuids,
                    external_id: this.state.order_filter,
                    material_external_id: this.state.material_filter,
                    material_title: this.state.material_title_filter,
                    comment: this.state.comment_filter
                });
                num_rows = res.count;
            } catch (err) {
                this.setState({ error: `${err}` });
            }
        }

        let orders = [];
        if (lines.length > 0) {
            try {
                const res = await getBackend().manufacturing.getArchivedManualEntryOrders({
                    line_uuids,
                    external_id: this.state.order_filter,
                    material_external_id: this.state.material_filter,
                    material_title: this.state.material_title_filter,
                    comment: this.state.comment_filter,
                    limit: this.state.page_size,
                    offset: this.state.page_index * this.state.page_size,
                    order_by: this.mapOrderBy(this.state.order_by) || undefined,
                    order_direction:
                        this.state.order_by !== null ? (this.state.is_ordered_desc ? "desc" : "asc") : undefined
                });
                orders = res.orders;
            } catch (err) {
                this.setState({ error: `${err}` });
            }
        }

        const materials = await this.getMaterials([...new Set(orders.map(order => order.material_external_id))]);
        this.setState({
            lines,
            lines_filter,
            orders,
            num_rows,
            materials,
            is_loading: false,
            is_loading_table: false
        });
    };

    loadOrders = async (init?: boolean = false) => {
        if (this.state.is_archive) {
            await this.loadClosedOrders(init, init);
        } else {
            await this.loadOpenOrders(init);
        }
    };

    getColumns = () => {
        return [
            {
                Header: "",
                accessor: ORDER_COLUMNS.order,
                Filter: this.renderTextFilter,
                enableSorting: true
            },
            {
                Header: "",
                accessor: ORDER_COLUMNS.material,
                Filter: this.renderTextFilter,
                enableSorting: true
            },
            {
                Header: "",
                accessor: ORDER_COLUMNS.material_title,
                Filter: this.renderTextFilter,
                enableSorting: true
            },
            {
                Header: this.renderSortHeader,
                accessor: ORDER_COLUMNS.quantity,
                Cell: NumberCell,
                disableFilters: true,
                enableSorting: true
            },
            {
                Header: "",
                accessor: ORDER_COLUMNS.line,
                title: translate("common.lines", "Lines"),
                filterInputPlaceholder: translate("common.lines", "Lines"),
                Cell: LineCell,
                Filter: this.renderLineFilter,
                filter: filterLine,
                enableSorting: true
            },
            {
                Header: "",
                accessor: ORDER_COLUMNS.comment,
                Filter: this.renderTextFilter,
                enableSorting: true
            },
            {
                Header: this.renderSortHeader,
                accessor: ORDER_COLUMNS.time_start,
                Cell: this.renderDateData,
                disableFilters: true,
                enableSorting: true
            },
            {
                Header: this.renderSortHeader,
                accessor: ORDER_COLUMNS.time_end,
                Cell: this.renderDateData,
                disableFilters: true,
                enableSorting: true
            },
            {
                Header: translate("common.required_deadline", "Required deadline"),
                accessor: ORDER_COLUMNS.required_deadline,
                Cell: this.renderDateData,
                disableFilters: true,
                enableSorting: false
            },
            {
                Header: this.renderOrderActionsHeader,
                accessor: ORDER_COLUMNS.order_actions,
                disableFilters: true,
                Cell: this.renderOrderActionsData
            }
        ];
    };

    getRows = (): TableRow[] => {
        const materials_map: Map<string, t.IMaterialModel> = new Map();
        for (const material of this.state.materials) {
            materials_map.set(material.external_id, material);
        }

        return this.state.orders.map(order => {
            let material_external_id = order.material_external_id;
            const material = materials_map.get(material_external_id);
            if (
                material !== undefined &&
                MATERIAL_TAGS_ACCESS.make_to_order(material.tags) &&
                MATERIAL_TAGS_ACCESS.external_parent_material_id(material.tags)
            ) {
                material_external_id = MATERIAL_TAGS_ACCESS.external_parent_material_id(material.tags);
            }

            return {
                order: order.external_id,
                material: material_external_id,
                material_title: order.material_title,
                quantity: order.quantity_total,
                line: {
                    id: order.line.external_id,
                    title: order.line.title
                },
                comment: ORDER_TAGS_ACCESS.comment(order.tags) || "",
                time_start: order.time_start || order.earliest_start,
                time_end: order.time_end || order.earliest_end,
                required_deadline: ORDER_TAGS_ACCESS.required_deadline(order.tags) || order.time_end || order.earliest_end,
                order_actions: order
            };
        });
    };

    mapOrderBy = (key: OrderBy): string | null => {
        const order_by_map = {
            [ORDER_COLUMNS.order]: "order_external_id",
            [ORDER_COLUMNS.material]: "material_external_id",
            [ORDER_COLUMNS.material_title]: "material_title",
            [ORDER_COLUMNS.quantity]: "quantity",
            [ORDER_COLUMNS.line]: "line_title",
            [ORDER_COLUMNS.comment]: "comment",
            [ORDER_COLUMNS.time_start]: "time_start",
            [ORDER_COLUMNS.time_end]: "time_end"
        };

        if (key !== null && order_by_map[key] !== undefined) {
            return order_by_map[key];
        }

        return key;
    };

    gotoPage = (page: number) => {
        this.setState({ page_index: page }, () => {
            this.loadClosedOrders(false, false, true);
        });
    };

    nextPage = () => {
        this.setState(
            ({ page_index, ...state }) => ({
                ...state,
                page_index: page_index + 1
            }),
            () => {
                this.loadClosedOrders(false, false, true);
            }
        );
    };

    previousPage = () => {
        this.setState(
            ({ page_index, ...state }) => ({
                ...state,
                page_index: page_index - 1
            }),
            () => {
                this.loadClosedOrders(false, false, true);
            }
        );
    };

    setPageSize = (page_size: number) => {
        this.setState(
            {
                page_size: page_size,
                page_index: 0
            },
            () => {
                this.loadClosedOrders(false, false, true);
            }
        );
    };

    getCellProps = (cell: Cell<TableRow>) => {
        let class_name = "";
        const order = this.state.orders[cell.row.index];
        if (order !== undefined) {
            if (
                cell.column.id === "time_end" &&
                !ORDER_TAGS_ACCESS.manual_entry_order_time_end_edited(order.tags)
            ) {
                class_name = "not-edited";
            } else if (
                cell.column.id === "required_deadline"  &&
                !ORDER_TAGS_ACCESS.manual_entry_order_required_deadline_edited(order.tags)
            ) {
                class_name = "not-edited";
            }
        }

        return {
            className: class_name
        };
    };

    getToggleSortBy = (column: ColumnInstance<TableRow>) => {
        const id: OrderBy = column.id;
        if (!this.state.is_archive) {
            return () => {
                let { order_by, is_ordered_desc } = this.state;
                if (column.isSorted === false && column.isSortedDesc === undefined) {
                    order_by = id;
                    is_ordered_desc = false;
                } else if (column.isSorted === true && column.isSortedDesc === false) {
                    order_by = id;
                    is_ordered_desc = true;
                } else {
                    order_by = null;
                    is_ordered_desc = false;
                }

                this.setState({ order_by, is_ordered_desc });
            };
        }

        return () => {
            let { order_by, is_ordered_desc } = this.state;
            if (id !== order_by) {
                order_by = id;
                is_ordered_desc = false;
            } else if (!is_ordered_desc) {
                is_ordered_desc = true;
            } else {
                order_by = null;
                is_ordered_desc = false;
            }

            this.setState({ order_by, is_ordered_desc }, () => {
                this.loadClosedOrders(false, false, true);
            });
        };
    };

    handleAddOrder = () => {
        this.setState({
            selected_order: null,
            show_input_modal: true
        });
    };

    handleEditOrder = (order: t.IOrderModelBase) => {
        this.setState({
            selected_order: order,
            show_input_modal: true
        });
    };

    handleInputModalAccept = () => {
        this.setState({
            selected_order: null,
            show_input_modal: false
        });
        if (this.state.is_archive) {
            this.loadClosedOrders(false, true, true);
        } else {
            this.loadOpenOrders(false, true);
        }
    };

    handleInputModalCancel = () => {
        this.setState({
            selected_order: null,
            show_input_modal: false
        });
    };

    handleDeleteOrder = (order: t.IOrderModelBase) => {
        this.setState({
            selected_order: order,
            show_delete_modal: true
        });
    };

    handleDeleteModalAccept = () => {
        this.setState({
            selected_order: null,
            show_delete_modal: false
        });
        if (this.state.is_archive) {
            this.loadClosedOrders(false, true, true);
        } else {
            this.loadOpenOrders(false, true);
        }
    };

    handleDeleteModalCancel = () => {
        this.setState({
            selected_order: null,
            show_delete_modal: false
        });
    };

    handleArchiveToggle = async () => {
        this.setState(
            ({ is_archive, ...prev_state }) => ({
                ...prev_state,
                page_index: 0,
                is_archive: !is_archive
            }),
            () => {
                this.loadOrders(true);
            }
        );
    };

    handlePageChange = (page_index: number) => {
        this.setState({ page_index });
    };

    handlePageSizeChange = (page_size: number) => {
        this.setState({ page_size, page_index: 0 });
    };

    handleTextFilterChange = (event: SyntheticEvent<HTMLInputElement>, column: ColumnInstance<TableRow>) => {
        const value = event.currentTarget.value;
        const id = column.id;
        const setFilter: (value: string) => void = column.setFilter;
        const state: $Shape<State> = {
            page_index: 0
        };

        if (id === ORDER_COLUMNS.order) {
            state.order_filter = value;
        } else if (id === ORDER_COLUMNS.material) {
            state.material_filter = value;
        } else if (id === ORDER_COLUMNS.material_title) {
            state.material_title_filter = value;
        } else if (id === ORDER_COLUMNS.comment) {
            state.comment_filter = value;
        }

        this.setState(state, () => {
            if (this.state.is_archive) {
                clearTimeout(this.filter_timeout);
                this.filter_timeout = setTimeout(() => {
                    this.loadClosedOrders(false, true, true);
                }, 250);
            } else {
                setFilter(value);
            }
        });
    };

    renderOrderActionsHeader = () => {
        return this.state.is_loading_table ? (
            <div className="text-right">
                <i className="fas fa-spinner fa-spin fa-lg" aria-hidden="true" />
            </div>
        ) : (
            <div className="text-right">
                {!this.state.is_archive && !this.state.automatic_import && (
                    <button
                        className="btn btn-short btn-outline-primary px-2"
                        style={{ width: 30 }}
                        title={translate("common.add", "Add")}
                        onClick={this.handleAddOrder}
                    >
                        <i className="fas fa-plus align-middle" />
                    </button>
                )}
            </div>
        );
    };

    renderSortHeader = ({ column }: TableInstance<TableRow>) => {
        const id: OrderBy = column.id;
        let header = "";
        if (id === ORDER_COLUMNS.quantity) {
            header = translate("common.quantity", "Quantity");
        } else if (id === ORDER_COLUMNS.time_start) {
            header = translate("common.start_noun", "Start");
        } else if (id === ORDER_COLUMNS.time_end) {
            header = translate("common.confirmed_end", "Confirmed end");
        }

        column.toggleSortBy = this.getToggleSortBy(column);

        if (!this.state.is_archive) {
            return header;
        }

        column.sortType = () => undefined;

        return header;
    };

    renderTextFilter = ({ column }: FilterProps<TableRow>) => {
        const id = column.id;
        let label = "";
        let value = "";

        if (id === ORDER_COLUMNS.order) {
            label = translate("common.order", "Order");
            value = this.state.order_filter;
        } else if (id === ORDER_COLUMNS.material) {
            label = translate("common.material", "Material");
            value = this.state.material_filter;
        } else if (id === ORDER_COLUMNS.material_title) {
            label = translate("OrderTableProduction.material_title", "Material title");
            value = this.state.material_title_filter;
        } else if (id === ORDER_COLUMNS.comment) {
            label = translate("common.comment", "Comment");
            value = this.state.comment_filter;
        }

        if (label === "") {
            return null;
        }

        const filter = (
            <input
                className="form-control column-filter"
                value={value}
                onChange={event => this.handleTextFilterChange(event, column)}
                placeholder={label}
                title={label}
            />
        );

        column.toggleSortBy = this.getToggleSortBy(column);

        if (!this.state.is_archive) {
            return filter;
        }

        column.sortType = () => undefined;

        return filter;
    };

    renderLineFilter = ({ column }: FilterProps<TableRow>) => {
        const setFilter: (value: string) => void = column.setFilter;
        const lines = this.state.lines.map(line => ({
            label: line.title,
            value: line.external_id
        }));
        const filter = (
            <MultiSelect
                className="w-100"
                name="line"
                placeholder={translate("common.lines", "Lines")}
                value={lines.filter(line => this.state.lines_filter.includes(line.value))}
                options={lines}
                styles={{
                    container: base => ({
                        ...base,
                        minWidth: 150
                    }),
                    control: base => ({
                        ...base,
                        minHeight: 40
                    })
                }}
                onChange={options => {
                    const lines_filter = options.map(option => option.value);
                    this.setState(
                        {
                            lines_filter,
                            page_index: 0
                        },
                        () => {
                            if (this.state.is_archive) {
                                this.loadClosedOrders(false, true, true);
                            } else {
                                setFilter(lines_filter);
                            }
                        }
                    );
                }}
                isClearable={false}
                enable_select_all
            />
        );

        column.toggleSortBy = this.getToggleSortBy(column);

        if (!this.state.is_archive) {
            return filter;
        }

        column.sortType = () => undefined;

        return filter;
    };

    renderOrderActionsData = ({ value }: Cell<TableRow, t.IOrderModelBase>) => {

        let permission;

        // if its kiosk user, then give him rights to edit
        if (Auth.getUserTag(USER_TAGS.kiosk) === "true") {
            permission = true;
        } else {
            permission = this.state.is_archive
                ? Auth.PERMISSION_NAMES.ArchivedManualEntryOrderEdit
                : Auth.PERMISSION_NAMES.Everyone
        }
        return (
            <div className="text-right">
                <Authorization.button
                    className="btn btn-short btn-outline-secondary px-2"
                    title={translate("common.edit", "Edit")}
                    permission={permission}
                    style={{ width: 30 }}
                    onClick={() => {
                        this.handleEditOrder(value);
                    }}
                    disabled={this.state.is_loading_table}
                >
                    <i className="fas fa-edit align-middle" />
                </Authorization.button>
                {!this.state.automatic_import && (
                    <button
                        className="btn btn-short btn-outline-secondary px-2 ml-2"
                        title={translate("common.delete", "Delete")}
                        style={{ width: 30 }}
                        onClick={() => {
                            this.handleDeleteOrder(value);
                        }}
                        disabled={this.state.is_loading_table}
                    >
                        <i className="fas fa-trash align-middle" />
                    </button>
                )}
            </div>
        );
    };

    handleOrderTimeChange = async (column: ColumnInstance<TableRow>, row: Row<TableRow>, date: Date) => {
        const order: t.IOrderModelBase = row.values.order_actions;
        const uuid = order.uuid;
        const quantity = row.values.quantity;
        const comment = row.values.comment;
        const line_external_id = row.values.line.id;
        let edited_time_end;
        let edited_required_deadline;
        let time_start = row.values.time_start;
        if (column.id === ORDER_COLUMNS.time_start) {
            time_start = date.getTime();
        }

        let time_end = row.values.time_end;
        if (column.id === ORDER_COLUMNS.time_end) {
            time_end =  date.getTime();
            edited_time_end = true;
        } else if (column.id === ORDER_COLUMNS.time_start) {
            time_end = getEndTime(order, time_start);
        }

        let required_deadline = row.values.required_deadline;
        if (column.id === ORDER_COLUMNS.required_deadline) {
            required_deadline =  date.getTime();
            edited_required_deadline = true;
        }

        try {
            this.setState({ error: "" });
            if (this.state.is_archive) {
                await getBackend().manufacturing.updateArchivedManualEntryOrder({
                    uuid,
                    quantity,
                    time_start,
                    time_end,
                    required_deadline,
                    edited_time_end,
                    edited_required_deadline
                });
                this.loadClosedOrders(false, false, true);
            } else {
                await getBackend().manufacturing.updateManualEntryOrder({
                    uuid,
                    quantity,
                    comment,
                    time_start,
                    time_end,
                    required_deadline,
                    edited_time_end,
                    edited_required_deadline,
                    line_external_id
                });
                this.loadOpenOrders(false, true);
            }
        } catch (err) {
            this.setState({ error: `${err}` });
        }
    };

    renderDateData = ({ column, row, value }: Cell<TableRow, number>) => {
        const is_kiosk_user = Auth.getUserTag(USER_TAGS.kiosk) === "true";
        const has_permission = Auth.hasPermission(Auth.PERMISSION_NAMES.ManualEntryOrderTimeEdit);
        let is_disabled = false;
        if (column.id === ORDER_COLUMNS.time_end) {
            is_disabled = !(has_permission || is_kiosk_user);
        } else if (column.id === ORDER_COLUMNS.required_deadline) {
            is_disabled = !has_permission;
        }

        if ([ORDER_COLUMNS.time_end, ORDER_COLUMNS.required_deadline].includes(column.id) || !this.state.automatic_import) {
            return (
                <DateTimePicker
                    inputId={`anual-entry-line-input-${column.id}`}
                    value={new Date(value)}
                    minValue={column.id === ORDER_COLUMNS.time_end ? new Date(row.values.time_start) : undefined}
                    placeholder={`${translate("common.select", "Select")}...`}
                    onChange={date => {
                        this.handleOrderTimeChange(column, row, date);
                    }}
                    popupPostion="fixed"
                    popupAlignment="end"
                    disabled={is_disabled}
                />
            );
        }

        return niceDateTime(new Date(value));
    };

    getSummmaryAmount = (rows: any) => {

        const total = rows.reduce((sum, row) => {
            return sum + row.values.quantity;
        }, 0);

        return total;
    }

    showExportExcelModal = () => {
        this.setState({ show_export_excel_modal: true });
    };

    getDataForExcel = (time_start: number, time_end: number) => {

        const data = [];

        for (let i = 0; i < this.state.orders.length; i++) {
            const order = this.state.orders[i];
            if (order.time_end !== undefined && order.time_end >= time_start && order.time_end <= time_end) {

                const required_deadline = ORDER_TAGS_ACCESS.required_deadline(order.tags) || 0;

                data.push({
                    "Order": order.external_id,
                    "Material": order.material_external_id.split("_")[0],
                    "Material title": order.material_title,
                    "Quantity": order.quantity_total,
                    "Line title": order.line.title,
                    "Comment": order.tags.comment || "",
                    "Time start": niceDateTime(new Date(order.time_start || 0)),
                    "Time end": niceDateTime(new Date(order.time_end || 0)),
                    "Required deadline": required_deadline !== 0 ? niceDateTime(new Date(required_deadline)) : "",
                    "Status": order.status === ORDER_STATUS.open ? "Open" : "Closed"
                });
            }
        }
        if (data.length > 0) {
            createXlsxAndDownload(JSON.stringify(data), "orders");
        }



    };

    render() {
        const { selected_order } = this.state;
        const page_count = Math.ceil(this.state.num_rows / this.state.page_size);
        const page_options = [...Array(page_count).keys()];

        let manual_entry_lines_table = "manual-entry-lines-table";

        if (this.props.is_kiosk) {
            manual_entry_lines_table += " manual-entry-lines-table-kiosk";
        }

        return (
            <article className="article">
                <section id="statistics" className="data_sources h-100 pb-0">
                    <div className="filter_bar views-filter-bar">
                        <div className="row align-items-center">
                            <div className="col col-auto ml-auto">
                                <div className="form-row">
                                    <div className="col-auto">
                                        <button className="btn btn-primary btn-sm" onClick={() => { this.showExportExcelModal() }}>
                                            <i className="fas fa-file-excel" />
                                            <span className="ml-2">{translate("Manufacturing.MicroplanSummary.export_to_xlsx", "Export To XLSX")}</span>
                                        </button>
                                    </div>
                                    <div className="col-auto">
                                        <label className="mb-0">
                                            <span>{translate("common.history", "History")}</span>
                                            <Toggle
                                                className="vertical-align-middle ml-2"
                                                checked={this.state.is_archive}
                                                onChange={this.handleArchiveToggle}
                                            />
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid pb-3" style={{ paddingTop: 68 }}>
                        {this.state.error !== "" && (
                            <ErrorComponent className="mt-0" type="error" msg={this.state.error} />
                        )}
                        <div className="white_box mb-0">
                            <ReactTable
                                key={this.state.is_archive ? "archive" : "open"}
                                className={classNames(manual_entry_lines_table, {
                                    "is-loading": this.state.is_loading_table
                                })}
                                data={this.getRows()}
                                columns={this.getColumns()}
                                isLoading={this.state.is_loading}
                                initialState={{
                                    pageSize: this.state.page_size,
                                    pageIndex: this.state.page_index,
                                    filters: [
                                        {
                                            id: ORDER_COLUMNS.order,
                                            value: this.state.is_archive ? "" : this.state.order_filter
                                        },
                                        {
                                            id: ORDER_COLUMNS.material,
                                            value: this.state.is_archive ? "" : this.state.material_filter
                                        },
                                        {
                                            id: ORDER_COLUMNS.material_title,
                                            value: this.state.is_archive ? "" : this.state.material_title_filter
                                        },
                                        {
                                            id: ORDER_COLUMNS.comment,
                                            value: this.state.is_archive ? "" : this.state.comment_filter
                                        },
                                        {
                                            id: ORDER_COLUMNS.line,
                                            value: this.state.is_archive
                                                ? this.state.lines.map(line => line.external_id)
                                                : this.state.lines_filter
                                        }
                                    ],
                                    sortBy:
                                        this.state.order_by !== null
                                            ? [
                                                {
                                                    id: this.state.order_by,
                                                    desc: this.state.is_ordered_desc
                                                }
                                            ]
                                            : []
                                }}
                                Pagination={
                                    this.state.is_archive
                                        ? false
                                        : props => (
                                            <React.Fragment>
                                                <Pagination
                                                    {...props}
                                                    onPageChange={this.handlePageChange}
                                                    onPageSizeChange={this.handlePageSizeChange}
                                                    pageSizes={[10, 25, 50, 100, 500]}
                                                />
                                            </React.Fragment>
                                        )
                                }
                                LastRow={(rows) => {
                                    return (
                                        <tr style={{ borderTop: "2px solid black" }}>
                                            <td colSpan={3} />
                                            <td style={{ textAlign: "right" }}>
                                                {translate("common.total_sum", "Total sum")}
                                                :&nbsp;{this.getSummmaryAmount(rows.rows)}
                                            </td>
                                            <td colSpan={6} />
                                        </tr>
                                    );
                                }}
                                getCellProps={this.getCellProps}
                            />
                            {this.state.is_archive && !this.state.is_loading && (
                                <Pagination
                                    num_rows={this.state.num_rows}
                                    canPreviousPage={this.state.page_index > 0}
                                    canNextPage={this.state.page_index < page_count - 1}
                                    pageOptions={page_options}
                                    pageCount={page_count}
                                    gotoPage={this.gotoPage}
                                    nextPage={this.nextPage}
                                    previousPage={this.previousPage}
                                    setPageSize={this.setPageSize}
                                    pageIndex={this.state.page_index}
                                    pageSize={this.state.page_size}
                                    pageSizes={[10, 25, 50, 100, 500]}
                                />

                            )}
                            <ManualEntryOrderInputModal
                                order={this.state.show_input_modal ? selected_order : null}
                                show={this.state.show_input_modal}
                                line_uuids={this.state.lines.map(line => line.uuid)}
                                is_archive={this.state.is_archive}
                                automatic_import={this.state.automatic_import}
                                onAccept={this.handleInputModalAccept}
                                onCancel={this.handleInputModalCancel}
                            />
                            <ManualEntryOrderDeleteModal
                                order={this.state.show_delete_modal ? selected_order : null}
                                show={this.state.show_delete_modal}
                                onAccept={this.handleDeleteModalAccept}
                                onCancel={this.handleDeleteModalCancel}
                            />
                            <ManualEntryOrderExportExcelModal
                                orders={this.state.orders}
                                show={this.state.show_export_excel_modal}
                                onCancel={() => this.setState({ show_export_excel_modal: false })}
                                onAccept={this.getDataForExcel}
                            />
                        </div>
                    </div>
                </section>
            </article>
        );
    }
}

export default ManualEntryOrders;
