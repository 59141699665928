// @flow
import * as React from "react";
import { dayStrings, toSloDateString } from "../../../lib/Util";
import * as t from "../../../lib/backend/manufacturing2.generated.types";
import Check from "../../react/Icon/Check";
import Cross from "../../react/Icon/Cross";

type Props = {
    shifts: Array<Array<t.IShiftLineRec>>,
    handleClick: Function,
    current_week: number,
    current_shift: number,
    show_dates?: boolean,
    show_overrides?: boolean
};

type State = {
    days: Array<string>,
    width: number
};

class ShiftEditorWeek extends React.Component<Props, State> {
    constructor() {
        super();
        this.state = { days: dayStrings(true), width: window.innerWidth - 70 };
    }

    componentDidMount() {
        window.addEventListener("resize", () => this.updateDimensions());
        this.updateDimensions();
    }

    updateDimensions() {
        let width = window.innerWidth - 70;
        this.setState({ width: width });
    }

    getCheckboxes = (class_name: string) => {
        return (
            <React.Fragment>
                {class_name.includes("colorEmpty") ? <Check /> : null}
                {class_name.includes("shift-not-selected") ? <Cross /> : null}
            </React.Fragment>
        )
    }

    getColumns(shifts: Array<Array<t.IShiftLineRec>>, shift: number): React.Node {
        const row = shifts.map((shift_obj: Array<t.IShiftLineRec>, weekday_idx: number) => {
            let color = "shift-not-selected";
            if (shift_obj[shift]) {
                if (shift_obj[shift].enabled) {
                    color = "colorEmpty";
                }
                if (this.props.show_overrides) {
                    if (shift_obj[shift].user_override) {
                        color += " userOverride";
                    }
                }
            }

            let class_name = color;
            if ("shift_time" in shift_obj[shift]) {
                const shift_time = shift_obj[shift];
                if (shift_time.shift_number === this.props.current_shift) {
                    class_name += " selected-cell";
                }
                if (shift_time.week < this.props.current_week ||
                    (shift_time.week === this.props.current_week && shift_time.shift_number < this.props.current_shift)) {
                    class_name += " faded-cell";
                }
            }
            const tooltip = toSloDateString(new Date(shift_obj[shift].shift_date));

            if (class_name.includes("colorEmpty")) {
                class_name += " selected-box";
            }

            if (class_name.includes("shift-not-selected")) {
                class_name += " unselected-box"
            }

            if (weekday_idx === 0) {
                return (
                    [
                        <td key={-1}>{shift + 1}</td>,
                        <td title={tooltip} key={weekday_idx} onClick={() => { this.props.handleClick(shift, weekday_idx, shift_obj[shift].enabled) }}
                            className={class_name}
                        >
                            {this.getCheckboxes(class_name)}
                        </td>
                    ]
                );
            } else {
                return (
                    <td title={tooltip} key={weekday_idx} onClick={() => { this.props.handleClick(shift, weekday_idx, shift_obj[shift].enabled) }}
                        className={class_name}
                    >
                        {this.getCheckboxes(class_name)}
                    </td>
                );
            }
        });
        return row;
    }

    getHeader(shifts: Array<Array<t.IShiftLineRec>>, shift: number): React.Node {
        const row = shifts.map((shift_obj: Array<t.IShiftLineRec>, i: number) => {
            let day = this.state.days[i];
            if (this.state.width < 1600) {
                day = day.substr(0, 2);
            }
            if (this.state.width < 1400) {
                day = day.substr(0, 1);
            }
            if (i === 0) {
                return (
                    [<th key={-1} scope={"col"}>{""}</th>,
                    <th key={i} scope={"col"}>{day}</th>]
                );
            } else {
                return (
                    <th key={i} scope={"col"}>{day}</th>
                );
            }
        });
        return row;
    }

    getHeaderDates(shifts: Array<Array<t.IShiftLineRec>>, shift: number): React.Node {
        const row = shifts.map((shift_obj: Array<t.IShiftLineRec>, i: number) => {
            const text = toSloDateString(new Date(shift_obj[shift].shift_date)).slice(0, 6);
            if (i === 0) {
                return (
                    [<th key={-1} scope={"col"}>{""}</th>,
                    <th key={i} className="" scope={"col"}>{text}</th>]
                );
            } else {
                return (
                    <th key={i} className="" scope={"col"}>{text}</th>
                );
            }
        });
        return row;
    }

    // prepare list of datasources
    render() {
        if (this.props.shifts) {
            // render list of datasources to HTML
            let shifts = this.props.shifts;
            const header = this.getHeader(shifts, 0);
            let header_dates = null;
            if (this.props.show_dates) {
                header_dates = this.getHeaderDates(shifts, 0);
            }
            const rows1 = this.getColumns(shifts, 0);
            const rows2 = this.getColumns(shifts, 1);
            const rows3 = this.getColumns(shifts, 2);

            return (
                <table className="table shift-table">
                    <thead className={this.props.show_dates && "has-dates"}>
                        <tr>
                            {header}
                        </tr>
                        {this.props.show_dates && (
                            <tr>
                                {header_dates}
                            </tr>)
                        }
                    </thead>
                    <tbody>
                        <tr>
                            {rows1}
                        </tr>
                        <tr>
                            {rows2}
                        </tr>
                        <tr>
                            {rows3}
                        </tr>
                    </tbody>
                </table>
            );
        }
        else {
            return (<div />)
        }
    }
}

export default ShiftEditorWeek;
