
// @flow
import * as React from "react";
import type { ManufacturingData } from "../../dashboard/Dashboard";

type Props = {
    title: string,
    uuid: string,
    compact: boolean,
    manufacturing: ManufacturingData
};
type State = {};

class LineLayout extends React.Component<Props, State> {
    constructor() {
        super();
        this.state = {};
    }

    render() {
        const widget_class_name = `dsh_man_line ${this.props.compact ? "dsh_man_line_short" : "dsh_man_line_tall"}`;
        // data not yet loaded
        if (this.props.manufacturing === null) {
            return (
                <div className={widget_class_name}>
                    <div className="dsh_man_line_title">{this.props.title}</div>
                </div>
            );
        }

        // get latest downtime for the line
        const line_insights = this.props.manufacturing.insights.filter(
            insight => insight.tags.line_uuid === this.props.uuid);
        // get current work orders
        const orders = [];
        const line_orders = this.props.manufacturing.report.result.orders.find(o => (o.line === this.props.uuid));
        if (line_orders !== undefined && line_orders.production.length > 0) {
            const first_start = line_orders.production[0].estimated_start[0].val;
            if (true || first_start === 0) {
                for (const order of line_orders.production) {
                    if (order.estimated_start[0].val > first_start) { break; }
                    orders.push({
                        material: order.material_title.slice(0, 20) + (order.material_title.length > 0 ? "..." : ""),
                        produced: Math.round(order.quantity_produced / order.quantity_total * 100)
                    });
                }
            }
        }

        return (<div className={widget_class_name}>
            <div className="dsh_man_line_title">{this.props.title}</div>
            {line_insights.length > 0 &&
                <div className="dsh_man_line_dowtimes">
                    {line_insights[0].extra_data.title}
                </div>}
            {!this.props.compact && orders.map((o, idx) => (
                <div className="dsh_man_line_order" key={idx}>
                    {o.material} <b>{o.produced}%</b>
                </div>
            ))}
        </div>);
    }
}

export default LineLayout;
