// @flow

// react
import * as BusinessLogic from "../lib/BusinessLogic";
import * as React from "react";
import ReactRouterPropTypes from "react-router-prop-types";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import VersionCheck from "./VersionCheck";

// backend
import * as Auth from "../lib/Auth";
import { PLANT_TAGS_ACCESS } from "../lib/ManufacturingTags.generated";
import { USER_TAGS } from "../lib/CommonTags.generated";
import { getAnonymization } from "../lib/Anonymization";
import { translate } from "./IntlProviderWrapper";

import {
    MANUFACTURING_PREFIX,
    MANUFACTURING_SKIP_BACK,
    ADMIN_PREFIX,
    DIGITAL_TWIN_PREFIX
} from "./App";
import TicketingHeaderIcon from "./ticketing/TicketingHeaderIcon";

import AppsIcon from "./react/Icon/Apps";
import ArrowCircleLeftIcon from "./react/Icon/ArrowCircleLeft";
import ChevronDownIcon from "./react/Icon/ChevronDown";
import CogIcon from "./react/Icon/Cog";
import LeapLogoIcon from "./react/Icon/LeapLogo";
import PowerOffIcon from "./react/Icon/PowerOff";
import ProductionGuidingIcon from "./react/Icon/ProductionGuiding";
import UserCircleIcon from "./react/Icon/UserCircle";
import UserIcon from "./react/Icon/User";

import type { IPlantData, ILineGroupData } from "../lib/backend/manufacturing2.generated.types";
import type { SvgIconProps } from "./react/SvgIcon";

// defining types
type Props = {
    history: ReactRouterPropTypes.history,
    location: ReactRouterPropTypes.location,
    fixed: boolean,
    show: boolean,
    username: string,
    env_flag: string,
    show_manufacturing: boolean,
    // manufacturing
    plants: IPlantData[],
    plant_uuid: string,
    set_plant_uuid: (string) => void,
    line_groups: ILineGroupData[],
    line_group_uuid: string,
    set_line_group_uuid: (string) => void
};

type State = {
    show_version_notification: boolean,
    error: string,
    user_title: string
};

type LeapModule = {
    id: string,
    title: string,
    home_path: string,
    path: string,
    icon: React.ComponentType<SvgIconProps>,
    enabled: boolean
};

const env_flag = BusinessLogic.getSysFlag("env");

export const getLeapModules = (): LeapModule[] => {
    // check if user is in kiosk mode
    const is_kiosk = Auth.getUserTag(USER_TAGS.kiosk) === "true";
    // is the user in admin role
    const is_admin = !is_kiosk && Auth.isInRole(Auth.ROLE_ADMIN);
    // is the user in power-user role
    const is_power_user = !is_kiosk && Auth.isInRole(Auth.ROLE_POWER_USER);
    // is the user in power-user role
    const is_demo_user = !is_kiosk && Auth.isInRole(Auth.ROLE_DEMO_USER);

    const admin = [];
    if (is_admin || is_power_user || is_demo_user) {
        admin.push({
            id: "administration",
            title: "System Administration",
            path: ADMIN_PREFIX,
            home_path: ADMIN_PREFIX,
            icon: () => <span style={{fontSize: "16px", marginRight: "18px"}}>SA</span>, // TODO
            enabled: true
        });
    }

    return [
        {
            id: "pg",
            title: "Production Guiding",
            path: MANUFACTURING_PREFIX,
            home_path: MANUFACTURING_PREFIX,
            icon: ProductionGuidingIcon,
            enabled: true
        },
        {
            id: "digital_twin",
            title: "Digital Twin",
            path: DIGITAL_TWIN_PREFIX,
            home_path: DIGITAL_TWIN_PREFIX + "/resources/plants",
            icon: () => <span style={{fontSize: "16px", marginRight: "18px"}}>DT</span>,
            enabled: true
        },
    ].concat(admin);
}

export const getActiveLeapModule = (location: ReactRouterPropTypes.location): LeapModule | void => {
    const leap_modules = getLeapModules();
    const current_location = location.toLowerCase();
    if (current_location !== undefined) {
        return leap_modules.find(lm => current_location.startsWith(lm.path));
    }
};

/**
 * Component that displays the header with appropriate menu.
 */
class Header extends React.Component<Props, State> {
    constructor() {
        super();

        this.state = {
            show_version_notification: false,
            error: "",
            user_title: ""
        };
    }

    componentDidMount() {
        // get user info
        const user_data = Auth.getLoggedinUser();
        this.setState({
            user_title: user_data.title,
            show_version_notification: Auth.shouldNotifyNewVersion()
        });
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.username !== this.props.username) {
            const user_data = Auth.getLoggedinUser();
            this.setState({ user_title: user_data.title });
        }
    }

    handleLogout = (event: Event) => {
        Auth.logout();
        this.props.history.push("/");
    }

    // return correct style class for navbar top-level button based on the path
    getHighlightClass(active: boolean) {
        return active ? " active" : "";
    };

    renderManufacturingPlant() {
        // get selected plant title
        let selected_plant = this.props.plants.find(plant => (plant.uuid === this.props.plant_uuid));
        const plant_title = (selected_plant === undefined) ? "" : selected_plant.title;
        // render menu
        return (
            <li className="nav-item dropdown mx-1" key="plant_selector">
                <button className="btn btn-link nav-link dropdown-toggle d-flex align-items-center" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false" id="dropdownMenuManufacturingPlant">
                    {plant_title}
                    <ChevronDownIcon size="xs" />
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuManufacturingPlant" style={{ maxHeight: "calc(100vh - 50px)", overflowY: "auto" }}>
                    {this.props.plants.map(plant =>
                        <button test-id={`plant_${plant.title.replace(" ", "-").toLowerCase()}`} className="dropdown-item" key={`plant_${plant.uuid}`}
                            onClick={() => (this.props.set_plant_uuid(plant.uuid))}>{plant.title}</button>
                    )}
                </div>
            </li>
        );
    }

    renderManufacturingLineGroup() {
        // get selected line group title
        let selected_line_group = this.props.line_groups.find(line_group => (line_group.uuid === this.props.line_group_uuid));
        let line_group_title = (selected_line_group === undefined) ? "" : selected_line_group.title;
        if (line_group_title === "") { line_group_title = "--"; }
        // render menu
        return (
            <li className="nav-item dropdown mx-1" key="line_group_selector">
                <button className="btn btn-link nav-link dropdown-toggle d-flex align-items-center" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false" id="dropdownMenuManufacturingLineGroup">
                    {line_group_title} <ChevronDownIcon size="xs" />
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuManufacturingLineGroup" style={{ maxHeight: "calc(100vh - 50px)", overflowY: "auto" }}>
                    {this.props.line_groups.map(line_group =>
                        <button test-id={`line_group_${line_group.title.toLowerCase()}`} className="dropdown-item" key={`line_group_${line_group.uuid}`}
                            onClick={() => (this.props.set_line_group_uuid(line_group.uuid))}>{line_group.title}</button>
                    )}
                </div>
            </li>
        );
    }

    renderManufacturing(path: string) {
        // only show plant/line group selectors when showing manufacturing views
        const show_manufacturing_selectors =
            path.startsWith(MANUFACTURING_PREFIX + "/") &&
            !MANUFACTURING_SKIP_BACK.includes(path);
        return show_manufacturing_selectors ?
            [this.renderManufacturingPlant(), this.renderManufacturingLineGroup()] : (
                <li className="nav-item mx-1">
                    <Link className="btn btn-link nav-link d-flex align-items-center" to="/manufacturing">
                        <ArrowCircleLeftIcon class_name="mr-2" />
                        <FormattedMessage id="Header.menu.production_guiding" defaultMessage="Production guiding" />
                    </Link>
                </li>
            );
    }

    closeDropdown = () => {
        const el = document.querySelector("#header-menu");
        if (el) {
            el.classList.remove("show");
            [...el.children].forEach(ch => {
                if (ch.hasAttribute("aria-expanded")) {
                    ch.setAttribute("aria-expanded", "false");
                } else {
                    ch.classList.remove("show");
                }
            });
        }
    }

    getUserDocsUrl(): string {
        return "https://docs.qlector.com/aka.html?target=user_docs&lang=" + (Auth.getUserLang() ?? "en");
    }

    redirectToDocsPage(): void {
        window.location = this.getUserDocsUrl();
    }

    getLatestChangesUrl() {
        return "https://docs.qlector.com/aka.html?target=latest_changes&lang=" + (Auth.getUserLang() ?? "en");
    }

    getClassName = (): string => {
        const classes = ["app-header"];
        if (this.props.fixed) {
            classes.push("header_always_top");
        }

        return classes.join(" ");
    };

    getContainerClassName = (): string => {
        const classes = ["app-header-container"];
        const active_leap_module = getActiveLeapModule(this.props.location);
        if (active_leap_module !== undefined) {
            classes.push(`module-leap-${active_leap_module.id}`)
        }

        if (!getAnonymization()) {
            // only apply color for header when anonymization is not active
            switch (this.props.env_flag) {
                case "dev": classes.push("env-dev"); break;
                case "beta": classes.push("env-beta"); break;
                case "alpha": classes.push("env-alpha"); break;
                default: break;
            }
        }
        if (localStorage.getItem("grid-layout") === "true") {
            classes.push("grid-header");
        }
        return classes.join(" ");
    };

    redirectToLatestChangesPage(): void {
        window.location = this.getLatestChangesUrl();
    }

    getHelpDeskUrl() {
        return "https://qlector.atlassian.net/servicedesk/customer/portal/9";
    }

    renderLeapModuleNav = () => {
        const leap_modules = getLeapModules();
        const active_module = getActiveLeapModule(this.props.location);

        // if its kiosk_user, we don't want to show the leap modules (9 dots)
        if(Auth.getUserTag(USER_TAGS.kiosk) === "true") {
            return <div style={{paddingLeft: "20px"}}></div>;
        }

        return (
            <div className="leap-module-nav dropdown mr-3">
                <button
                    className="btn d-flex align-items-center justify-content-center"
                    type="button"
                    id="leap-module-nav-button"
                    data-toggle="dropdown"
                    aria-expanded="false"
                >
                    <AppsIcon size={18} />
                </button>
                <div className="dropdown-menu" aria-labelledby="leap-module-nav-button">
                    {leap_modules.map(lm => {
                        if (!lm.enabled) {
                            return null;
                        }

                        const Icon = lm.icon;
                        const is_active = active_module !== undefined && lm.path === active_module.path;
                        return (
                            <Link key={lm.id} className={`dropdown-item${is_active ? " active" : ""}`} to={lm.home_path}>
                                <Icon class_name="vertical-align-middle mr-3" height={20} />
                                <span className="vertical-align-middle">{lm.title}</span>
                            </Link>
                        );
                    })}
                </div>
            </div>
        );
    };

    renderBrand = () => {
        const active_module = getActiveLeapModule(this.props.location);

        const plant_uuid = this.props.plant_uuid;
        const plant_tags = BusinessLogic.getPlantTags(plant_uuid);
        const enable_home = PLANT_TAGS_ACCESS.new_feature_home_button(plant_tags);
        const redirect_url = enable_home ? "/manufacturing/home" : "/manufacturing/lines/gantt";

        return (
            <div className="leap-brand d-flex align-items-center mr-auto">
                <Link to={redirect_url} className="navbar-brand">
                    <LeapLogoIcon class_name="d-block" />
                </Link>
                {active_module !== undefined && (
                    <span className="leap-module-icon ml-3" title={active_module.title}>
                        <active_module.icon height={26} />
                    </span>
                )}
            </div>
        );
    };

    /**
     * Renders the component.
     */
    render() {
        if (!this.props.show) {
            return null;
        }

        // get current path so we can highlight correct header button
        let path = this.props.location;
        // check if user is in kiosk mode
        const is_kiosk = Auth.getUserTag(USER_TAGS.kiosk) === "true";
        // showing manufacturing modules
        const show_manufacturing = !is_kiosk && this.props.show_manufacturing;
        // is the user in ticket-user role
        const is_ticketing = !is_kiosk && Auth.isInRole(Auth.ROLE_TICKET_USER);

        // should we fix the header to the top or keep it floating
        const is_fixed_header = this.props.fixed;

        const org = Auth.getOrgData();
        const is_kolektor = (org.code === "kolektor");
        const support_email = is_kolektor ? "support.kg@qlector.com" : "support@qlector.com";
        const plant = this.props.plants.find(plant => plant.uuid === this.props.plant_uuid);
        const contact_item = plant && plant.tags && plant.tags.contact_person && (
            <React.Fragment>
                <div className="dropdown-divider" />
                <a className="dropdown-item" href={plant.tags.contact_person_email && `mailto:${plant.tags.contact_person_email}`} target="_blank">
                    <FormattedMessage id="Header.menu.contact" defaultMessage="Contact" />: {plant.tags.contact_person}
                </a>
            </React.Fragment>
        );

        return (
            <div className={this.getContainerClassName()}>
                <header className={this.getClassName()}>
                    <div className="container-fluid h-100 pl-0">
                        <nav className="navbar navbar-expand-md h-100">
                            {this.renderLeapModuleNav()}
                            {this.renderBrand()}
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav">
                                    {show_manufacturing && this.renderManufacturing(path)}
                                </ul>
                                <ul className="navbar-nav ml-auto">
                                    <li className="nav-item dropdown mx-1">
                                        <button className="btn btn-link nav-link dropdown-toggle d-flex align-items-center" id="dropdownMenuHelp" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <FormattedMessage id="common.help" defaultMessage="Help" />
                                            <ChevronDownIcon size="xs" />
                                        </button>
                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuHelp">
                                            <a className="dropdown-item" href={`mailto:${support_email}`}>
                                                <FormattedMessage id="Header.menu.send_message" defaultMessage="Send message" />
                                            </a>
                                            <a className="dropdown-item" href={this.getLatestChangesUrl()} target="_blank">
                                                <FormattedMessage id="Header.menu.documentation_latest_changes" defaultMessage="Latest changes" />
                                            </a>
                                            <a className="dropdown-item" href={this.getUserDocsUrl()} target="_blank">
                                                <FormattedMessage id="Header.menu.user_documentation" defaultMessage="User documentation" />
                                            </a>
                                            <a className="dropdown-item" href={this.getHelpDeskUrl()} target="_blank">
                                                <FormattedMessage id="Header.menu.help_desk" defaultMessage="Help desk" />
                                            </a>
                                            {contact_item}
                                        </div>
                                    </li>
                                    {is_ticketing && <li className="nav-item">
                                        <TicketingHeaderIcon />
                                    </li>}
                                    <li className="nav-item dropdown user-account-menu ml-2 mr-0">
                                        <button
                                            className=" btn btn-link nav-link dropdown-toggle d-flex align-items-center"
                                            id="dropdownMenuUserAccount"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            <UserIcon size={18} />
                                            <span className="sr-only">{translate("common.user_account", "User account")}</span>
                                        </button>
                                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuUserAccount">
                                            <Link className="dropdown-item d-flex align-items-center" to="/settings">
                                                <UserCircleIcon class_name="mr-3" />
                                                {this.state.user_title}
                                            </Link>
                                            <Link className="dropdown-item d-flex align-items-center" to="/settings">
                                                <CogIcon class_name="mr-3" />
                                                <FormattedMessage id="Header.menu.settings" defaultMessage="Settings" />
                                            </Link>
                                            <div className="dropdown-divider" />
                                            <button className="dropdown-item d-flex align-items-center" onClick={this.handleLogout}>
                                                <PowerOffIcon class_name="mr-3" />
                                                <FormattedMessage id="Header.menu.logout" defaultMessage="Log out" />
                                            </button>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="menu-org-logo">
                                <img src={`/dynamic/logo/${org.code}?anon=${getAnonymization() ? "true" : "false"}`} height="40" alt="customer_logo" />
                            </div>
                            {env_flag === "dev" && window.location.href.length > 2000 && (
                                <div className="nav-item">
                                    <img width="30" height="40" src="/img/warning.svg" alt="URL is too long" title="URL is too long" />
                                </div>
                            )}
                        </nav>
                    </div>
                </header>
                {is_fixed_header &&
                    <div className="fixed_header_filler"></div>
                }
                <VersionCheck />
            </div>
        );
    }
}

export default Header;
