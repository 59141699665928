// @flow
// $FlowFixMe
import { forwardRef, useState, useRef, useMemo, useEffect } from "react";
import * as React from "react";
import { useTable, useFilters, useSortBy } from "react-table";
import type { Cell, Column, FilterProps, TableOptions, ColumnInstance } from "react-table";
import { translate } from "../IntlProviderWrapper";

const COLUMN_WIDTH = 100;
const ROW_HEIGHT = 50;

export type VirtualTableColumn<T> = {
    class_name?: string,
    enableSorting?: boolean,
    fix?: boolean,
    title?: string
} & Column<T>;

function generateSortingIndicator(column, show_unsorted = false) {
    if (!column.isSorted) {
        return show_unsorted ? <i className="fas fa-arrows-alt-v" /> : null;
    }

    return !column.isSortedDesc ? (
        <i className="fas fa-long-arrow-alt-down" />
    ) : (
        <i className="fas fa-long-arrow-alt-up" />
    );
}

function DefaultColumnFilter<T>(props: FilterProps<T>) {
    const {
        column: { filterValue, setFilter }
    } = props;

    function handleChange(event: SyntheticEvent<HTMLInputElement>) {
        setFilter(event.currentTarget.value || undefined);
    }

    return (
        <input
            className="form-control"
            type="text"
            value={filterValue || ""}
            onChange={handleChange}
            placeholder={translate("common.search", "Search")}
        />
    );
}

type Props<T> = {
    columns: VirtualTableColumn<T>[],
    data: T[],
    width?: number,
    height?: number,
    column_width?: number,
    head_height?: number,
    row_height?: number,
    fix_head?: boolean,
    scroll_timeout?: number,
    rows_buffer?: number,
    columns_buffer?: number,
    class_name?: string,
    getCellProps?: Cell => any
} & TableOptions<T>;

type State = {
    scroll_top: number,
    scroll_left: number,
    table_rect: ClientRect | null,
    table_client_width: number
};

function VirtualTable<T>(props: Props<T>, ref) {
    const {
        columns,
        data,
        width,
        height,
        column_width = COLUMN_WIDTH,
        head_height = props.row_height || ROW_HEIGHT,
        row_height = ROW_HEIGHT,
        fix_head,
        scroll_timeout,
        rows_buffer = 0,
        columns_buffer = 0,
        class_name,
        initialState,
        getCellProps
    } = props;
    const [{ scroll_top, scroll_left, table_rect, table_client_width }, setState] = useState<State>({
        scroll_top: 0,
        scroll_left: 0,
        table_rect: null,
        table_client_width: 0
    });
    const table_ref = useRef(null);
    const scroll_timer = useRef(null);
    const animation_frame = useRef(null);
    const default_column = useMemo(
        () => ({
            Filter: DefaultColumnFilter
        }),
        []
    );
    const table = useTable(
        {
            columns,
            data,
            initialState,
            defaultColumn: default_column
        },
        useFilters,
        useSortBy
    );

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    function getWidth() {
        if (width !== undefined) {
            return width;
        }

        if (table_rect !== null) {
            return table_rect.width;
        }

        return 0;
    }

    function getHeight() {
        if (height !== undefined) {
            return height;
        }

        if (table_rect) {
            return table_rect.height;
        }

        return 0;
    }

    function getColumnWidth(column: ColumnInstance): number {
        let w = column_width;
        if (typeof column.width === "number") {
            w = column.width;
        } else if (typeof column.width === "string") {
            w = parseFloat(column.width);
            if (column.width.trim().endsWith("%")) {
                w = w / 100 * table_client_width;
            }
        }

        if (column.minWidth !== undefined && w < column.minWidth) {
            w = column.minWidth;
        }

        if (column.maxWidth !== undefined && w > column.maxWidth) {
            w = column.maxWidth;
        }

        return w;
    }

    function getTotalWidth(): number {
        let total_width = 0;
        table.columns.forEach(column => {
            total_width += getColumnWidth(column);
        });
        return total_width;
    }

    function getTotalHeight(): number {
        return head_height + row_height * table.rows.length;
    }

    function getCellBounds(): [number, number, number, number] {
        const end_width = scroll_left + getWidth();
        const row_count = table.rows.length;

        const start_row = Math.max(0, Math.floor((scroll_top - head_height) / row_height) - rows_buffer);
        const end_row = Math.min(
            Math.max(0, Math.ceil((scroll_top - head_height + getHeight()) / row_height) - 1) + rows_buffer,
            row_count - 1
        );

        const column_count = table.columns.length;
        let start_column = -1;
        let end_column = column_count - 1;

        let column_offset = 0;
        for (let i = 0; i < column_count; i++) {
            const column = table.columns[i];
            column_offset += getColumnWidth(column);

            if (start_column < 0 && column_offset > scroll_left) {
                start_column = i;
            }

            if (column_offset > end_width) {
                end_column = i;
                break;
            }
        }

        start_column = Math.max(0, start_column - columns_buffer);
        end_column = Math.min(column_count - 1, end_column + columns_buffer);

        return [start_column, start_row, end_column, end_row];
    }

    function handleResize() {
        if (table_ref.current !== null) {
            setState(prev_state => ({
                ...prev_state,
                table_rect: table_ref.current.getBoundingClientRect(),
                table_client_width: table_ref.current.clientWidth
            }));
        }
    }

    function handleScroll(event: SyntheticEvent<HTMLDivElement>) {
        const target_scroll_top = event.currentTarget.scrollTop;
        const target_scroll_left = event.currentTarget.scrollLeft;

        if (scroll_timeout !== undefined) {
            clearTimeout(scroll_timer.current);
            scroll_timer.current = setTimeout(() => {
                setState(prev_state => ({
                    ...prev_state,
                    scroll_top: target_scroll_top,
                    scroll_left: target_scroll_left
                }));
            }, scroll_timeout);
        } else {
            cancelAnimationFrame(animation_frame.current);
            animation_frame.current = requestAnimationFrame(() => {
                setState(prev_state => ({
                    ...prev_state,
                    scroll_top: target_scroll_top,
                    scroll_left: target_scroll_left
                }));
            });
        }
    }

    function renderTable() {
        const [start_column, start_row, end_column, end_row] = getCellBounds();
        const offset_left = table.columns.slice(0, start_column).reduce((offset, column) => {
            if (column.fix) {
                return offset;
            }

            return offset + getColumnWidth(column);
        }, 0);

        const thead = [];
        const thead_offset = {
            top: fix_head ? scroll_top : 0,
            left: offset_left
        };
        if (scroll_top < head_height || fix_head) {
            const cells = [];
            for (let j = 0; j < table.columns.length; j++) {
                const column = table.columns[j];
                if (!column.fix && (j < start_column || j > end_column)) {
                    continue;
                }

                const column_width = getColumnWidth(column);
                const header_props = column.getHeaderProps(column.enableSorting ? column.getSortByToggleProps() : {});

                if (column.title !== undefined) {
                    header_props.title = column.title;
                } else if (column.enableSorting) {
                    header_props.title = column.canFilter ? "" : translate("common.toggle_sorting", "Toggle sorting");
                }

                cells.push(
                    <div
                        {...header_props}
                        className={`virtual-table-th virtual-table-column-${column.id}${
                            column.class_name ? ` ${column.class_name}` : ""
                        }`}
                        style={{
                            ...header_props.style,
                            transform: column.fix ? `translateX(${scroll_left - thead_offset.left}px)` : "none",
                            width: column_width,
                            height: head_height
                        }}
                    >
                        <div>
                            {column.render("Header")}
                            {column.canFilter ? column.render("Filter") : null}
                            {column.enableSorting && !column.canFilter && (
                                <React.Fragment> {generateSortingIndicator(column)}</React.Fragment>
                            )}
                        </div>
                    </div>
                );
            }

            const header_group_props = table.headerGroups[0].getHeaderGroupProps();
            thead.push(
                <div
                    {...header_group_props}
                    className="virtual-table-tr"
                >
                    {cells}
                </div>
            );
        }

        const tbody = [];
        const tbody_offset = {
            top: head_height + row_height * start_row,
            left: offset_left
        };

        for (let i = start_row; i <= end_row; i++) {
            const row = table.rows[i];
            table.prepareRow(row);

            const cells = [];
            for (let j = 0; j < table.columns.length; j++) {
                const column = table.columns[j];
                if (!column.fix && (j < start_column || j > end_column)) {
                    continue;
                }

                const column_width = getColumnWidth(column);
                const cell = row.cells[j];
                const custom_cell_props = getCellProps ? getCellProps(cell) : {};
                const cell_props = cell.getCellProps();
                cells.push(
                    <div
                        {...cell_props}
                        {...custom_cell_props}
                        className={`virtual-table-td virtual-table-column-${column.id}${custom_cell_props.className ? ` ${custom_cell_props.className}` : ""}${
                            column.fix ? " is-fixed" : ""
                        }`}
                        style={{
                            transform: column.fix ? `translateX(${scroll_left - tbody_offset.left}px)` : "none",
                            width: column_width,
                            height: row_height,
                            ...custom_cell_props.style
                        }}
                    >
                        {cell.render("Cell")}
                    </div>
                );
            }

            const row_props = row.getRowProps();
            tbody.push(
                <div
                    {...row_props}
                    className="virtual-table-tr"
                >
                    {cells}
                </div>
            );
        }

        const table_props = table.getTableProps();
        const table_body_props = table.getTableBodyProps();

        return (
            <div
                {...table_props}
                className={`virtual-table-container${table_props.className ? ` ${table_props.className}` : ""}`}
                style={{
                    ...table_props.style,
                    position: "relative",
                    width: getTotalWidth(),
                    height: getTotalHeight()
                }}
            >
                <div
                    className={`virtual-table-thead${fix_head ? " is-fixed" : ""}`}
                    style={{
                        position: "absolute",
                        zIndex: 1,
                        transform: `translate(${thead_offset.left}px, ${thead_offset.top}px)`
                    }}
                >
                    {thead}
                </div>
                <div
                    {...table_body_props}
                    className={`virtual-table-tbody${
                        table_body_props.className ? ` ${table_body_props.className}` : ""
                    }`}
                    style={{
                        ...table_body_props.style,
                        position: "absolute",
                        transform: `translate(${tbody_offset.left}px, ${tbody_offset.top}px)`
                    }}
                >
                    {tbody}
                </div>
            </div>
        );
    }

    return (
        <div
            ref={tr => {
                if (typeof ref === "function") {
                    ref(tr);
                } else {
                    ref = tr;
                }

                table_ref.current = tr;

                if (tr === null) {
                    return;
                }

                const new_table_rect = tr.getBoundingClientRect();
                const new_table_client_width = tr.clientWidth;
                if (
                    table_client_width !== new_table_client_width ||
                    JSON.stringify(table_rect) !== JSON.stringify(new_table_rect)
                ) {
                    setState(prev_state => ({
                        ...prev_state,
                        table_rect: new_table_rect,
                        table_client_width: new_table_client_width
                    }));
                }
            }}
            className={`virtual-table${class_name ? ` ${class_name}` : ""}`}
            style={{ width: getWidth(), height: getHeight(), overflow: "auto" }}
            onScroll={handleScroll}
        >
            {renderTable()}
        </div>
    );
}

export default forwardRef(VirtualTable);
