// @flow
import React from "react";
import Select from "react-select";
import { connect } from "react-redux";
import { translate } from "../../../../IntlProviderWrapper";
import OrderFilter from "./OrderFilter";
import MaterialFilter from "./MaterialFilter";
import LineFilter from "../LineFilter";
import RescheduleOperationFilter from "./RescheduleOperationFilter";
import RescheduleLineFilter from "./RescheduleLineFilter";
import InsightsTypeFilter from "./InsightsTypeFilter";
import { TypeFilterValues } from "./TypeFilterConsts";
import { SET_SELECTED_FILTER_TYPE, RESET_FILTERS } from "../../reducers/reducersFilters/filters";
import MaterialTagFilter from "./MaterialTagFilter";
import OrderTagFilter from "./OrderTagFilter";
import { getBackend } from "../../../../../lib/backend/Backend2";
import { getPlantsForUser } from "../../../../../lib/BusinessLogic";
import ReworkOrderFilter from "./ReworkOrderFilter";

import type { SetLockFilter } from "../../reducers/common";
import type { LineOrders } from "../../reducers/linesOrders";
import type { SetSelectedFilterType, ResetFiltersAction } from "../../reducers/reducersFilters/filters";
import type { ReduxState } from "../../reducers/reducers";
import type { SELECTED_FILTER_TYPES } from "../../reducers/reducersFilters/filters";
import { SET_LOCK_FILTER } from "../../reducers/common";

const FILTER_TABS = {
    orders: "orders"
};

const FILTER_ENTITIES = {
    material: "material",
    order: "order"
};

const FILTER_MATCH = {
    exact: "exact"
};

type FilterSettings = {
    tab: $Keys<typeof FILTER_TABS>,
    entity: $Keys<typeof FILTER_ENTITIES>,
    field: string[],
    match: $Keys<typeof FILTER_MATCH>,
    title: string,
    placeholder: string
};

type SelectOptionItem = {
    label: string,
    value: SELECTED_FILTER_TYPES | number
};

type Props = {
    selected_filter_type: SELECTED_FILTER_TYPES,
    selected_line_uuids: string[],
    order_external_ids: string[],
    selected_stock_requirement: string,
    lines_orders: LineOrders[],
    line_group_uuid: string,
    is_filter_locked: boolean,
    reduxDispatch: (args: SetSelectedFilterType | ResetFiltersAction | SetLockFilter) => void
};

type State = {
    filter_settings: FilterSettings[],
    selected_filter: number,
    is_loading: boolean
};

const parseSettings = (content: string, plant_external_id?: string): FilterSettings[] => {
    const filter_settings: FilterSettings[] = [];
    if (plant_external_id === undefined) {
        return filter_settings;
    }

    try {
        const settings_format_error = "Invalid filter settings format";
        const raw_settings = JSON.parse(content);
        if (!Array.isArray(raw_settings)) {
            console.error(settings_format_error);
            return filter_settings;
        }

        const plant_settings = raw_settings.find(
            rs => typeof rs === "object" && rs.plant_external_id === plant_external_id
        );
        if (plant_settings === undefined) {
            return filter_settings;
        }

        if (!Array.isArray(plant_settings.filters)) {
            console.error(settings_format_error);
            return filter_settings;
        }

        for (const settings of plant_settings.filters) {
            if (typeof settings !== "object") {
                console.error(settings_format_error, settings);
                continue;
            }

            const tab = settings.tab;
            if (!Object.keys(FILTER_TABS).includes(tab)) {
                console.error("Invalid filter `tab` value", tab);
                continue;
            }

            const entity = settings.entity;
            if (!Object.keys(FILTER_ENTITIES).includes(entity)) {
                console.error("Invalid filter `entity` value", entity);
                continue;
            }

            const match = settings.match;
            if (!Object.keys(FILTER_MATCH).includes(match)) {
                console.error("Invalid filter `match` value", match);
                continue;
            }

            const field = settings.field;
            if (!Array.isArray(field) || field.some(f => typeof f !== "string")) {
                console.error("Invalid filter `filed` format", field);
                continue;
            }

            const title = settings.title;
            if (typeof title !== "string") {
                console.error("Invalid filter `title` format", title);
                continue;
            }

            const placeholder = settings.placeholder;
            if (typeof placeholder !== "string") {
                console.error("Invalid filter `placeholder` format", title);
                continue;
            }

            filter_settings.push({
                tab,
                entity,
                field,
                match,
                title,
                placeholder
            });
        }
    } catch (error) {
        console.error("Planning table filters settings:", error);
    }

    return filter_settings;
};

const parseTagField = (tag_field: string): string => {
    return tag_field.replace(/^tags\./i, "");
}

class TypeFilter extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const state: State = {
            filter_settings: [],
            selected_filter: -1,
            is_loading: false
        };
        this.state = state;
    }

    async componentDidMount() {
        await this.loadFilterSettings();
    }

    componentDidUpdate(prev_props: Props) {
        const tag_filter_types = [
            TypeFilterValues.material_tag_values,
            TypeFilterValues.order_tag_values
        ]
        if (tag_filter_types.includes(prev_props.selected_filter_type) &&
            !tag_filter_types.includes(this.props.selected_filter_type)) {
            this.setState({ selected_filter: -1 });
        }
    }

    loadFilterSettings = async () => {
        const settings_file = "planning_table_filters.json";
        this.setState({
            is_loading: true
        });

        try {
            const { content } = await getBackend().common.getBlobStorageItemAsStringOrNull({
                key: settings_file
            });
            if (content !== null) {
                const plant_uuid = localStorage.getItem("last-plant");
                const plant = getPlantsForUser().find(p => p.uuid === plant_uuid);
                const filter_settings = parseSettings(content, plant && plant.external_id);
                this.setState({ filter_settings });
            }
        } catch (error) {
            console.error(`${settings_file}:`, error);
        }

        this.setState({
            is_loading: false
        });
    }

    getTypeOptions = () => {
        const options = [
            {
                value: TypeFilterValues.reschedule_line,
                label: translate("common.reschedule_on_line", "Reschedule on line")
            },
            {
                value: TypeFilterValues.reschedule_operation,
                label: translate("common.reschedule_operation", "Reschedule operation")
            },
            {
                value: TypeFilterValues.material,
                label: translate("common.materials", "Materials")
            },
            {
                value: TypeFilterValues.line,
                label: translate("common.lines", "Lines")
            },
            {
                value: TypeFilterValues.order,
                label: translate("common.order", "Order")
            },
            {
                value: TypeFilterValues.order_insight_type,
                label: translate("common.insight_types", "Insight types")
            },
            {
                value: TypeFilterValues.filter_rework_orders,
                label: translate("common.rework_orders", "Rework orders")
            }
        ];

        for (let i = 0; i < this.state.filter_settings.length; i++) {
            const settings = this.state.filter_settings[i];
            options.push({
                value: i,
                label: settings.title
            });
        }

        return options;
    }

    onChangeFilterType = (option?: SelectOptionItem) => {
        let selected_filter = -1;
        if (!option) {
            this.props.reduxDispatch({ type: RESET_FILTERS, data: undefined });
        } else {
            const { value } = option;
            if (typeof value === "number") {
                const settings = this.state.filter_settings[value];
                const entity = settings && settings.entity;
                let filter_type = null;
                if (entity === FILTER_ENTITIES.material) {
                    filter_type = TypeFilterValues.material_tag_values;
                } else if (entity === FILTER_ENTITIES.order) {
                    filter_type = TypeFilterValues.order_tag_values;
                }

                if (filter_type !== null) {
                    selected_filter = value;
                    this.props.reduxDispatch({ type: SET_SELECTED_FILTER_TYPE, data: filter_type });
                }
            } else {
                if (value === TypeFilterValues.filter_rework_orders) {
                    this.props.reduxDispatch({ type: SET_LOCK_FILTER, data: true });
                }
                this.props.reduxDispatch({ type: SET_SELECTED_FILTER_TYPE, data: value });
            }
        }

        this.setState({ selected_filter });
    }

    renderType = () => {
        const value = this.state.selected_filter >= 0
            ? this.getTypeOptions().find(o => o.value === this.state.selected_filter)
            : this.getTypeOptions().find(o => o.value === this.props.selected_filter_type);

        return <Select
            value={value || null}
            placeholder={translate("common.select_filter_type", "Select type")}
            options={this.getTypeOptions()}
            onChange={this.onChangeFilterType}
            isSearchable={true}
            isLoading={this.state.is_loading}
            isClearable
        />
    }

    renderOrdersFilter = () => {
        return <OrderFilter />;
    }

    renderMaterialsFilters = () => {
        return <MaterialFilter />;
    }

    renderLineFilter = () => {
        return  <LineFilter />;
    }

    renderRescheduleOperationFilter = () => {
        return <RescheduleOperationFilter />;
    }

    renderRescheduleLineFilter = () => {
        return <RescheduleLineFilter />;
    }

    renderOrderTagFilter = () => {
        const settings = this.state.filter_settings[this.state.selected_filter];
        if (!settings) {
            return <Select isDisabled />;
        }

        return <OrderTagFilter
            order_tag={parseTagField(settings.field[0])}
            palceholder={settings.placeholder}
        />;
    }

    renderMaterialTagFilter = () => {
        const settings = this.state.filter_settings[this.state.selected_filter];
        if (!settings) {
            return <Select isDisabled />;
        }

        return <MaterialTagFilter
            material_tag={parseTagField(settings.field[0])}
            palceholder={settings.placeholder}
        />;
    }

    renderOrderInsightTypeFilter = () => {
        return <InsightsTypeFilter />;
    }

    renderReworkOrdersFilter = () => {
        return <ReworkOrderFilter />;
    }

    renderValueOptions = () => {
        if (this.props.selected_filter_type === TypeFilterValues.line) {
            return this.renderLineFilter();
        }
        if (this.props.selected_filter_type === TypeFilterValues.order) {
            return this.renderOrdersFilter();
        }
        if (this.props.selected_filter_type === TypeFilterValues.material) {
            return this.renderMaterialsFilters();
        }
        if (this.props.selected_filter_type === TypeFilterValues.reschedule_operation) {
            return this.renderRescheduleOperationFilter();
        }
        if (this.props.selected_filter_type === TypeFilterValues.reschedule_line) {
            return this.renderRescheduleLineFilter();
        }
        if (this.props.selected_filter_type === TypeFilterValues.order_tag_values) {
            return this.renderOrderTagFilter();
        }
        if (this.props.selected_filter_type === TypeFilterValues.material_tag_values) {
            return this.renderMaterialTagFilter();
        }
        if (this.props.selected_filter_type === TypeFilterValues.order_insight_type) {
            return this.renderOrderInsightTypeFilter();
        }
        if (this.props.selected_filter_type === TypeFilterValues.filter_rework_orders) {
            return this.renderReworkOrdersFilter();
        }
        return <Select isDisabled />
    }

    render() {
        return <div className="ql-type-filter">
            <div className="type-dropdown">
                {this.renderType()}
            </div>
            <div className="value-dropdown">
                {this.renderValueOptions()}
            </div>
        </div>
    }
}

export default connect(
    (state: ReduxState) => {
        const selected_filter_type = state.gantt_chart_filters.selected_filter_type;
        const lines_orders = state.gantt_chart_lines_orders.lines_orders;
        const selected_line_uuids = state.gantt_chart_filters.selected_line_uuids;
        const order_external_ids = state.gantt_chart_filters.order_external_ids;
        const selected_stock_requirement = state.gantt_chart_stock_requirements_filter.selected_stock_requirement;

        return {
            selected_filter_type,
            lines_orders,
            selected_line_uuids,
            order_external_ids,
            selected_stock_requirement
        }
    },
    (dispatch) => ({reduxDispatch: dispatch})
)(TypeFilter)