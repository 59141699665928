// @flow
import type { PayloadAction } from "../../../redux/types";

export const ZOOM_ENABLED: "zoom_enabled" = "zoom_enabled";

export type ControlsZoomInEnabledAction = PayloadAction<typeof ZOOM_ENABLED, boolean>;

export type ControlsState = {
    zoom_enabled: boolean
}

export const controls_initial_state: ControlsState = {
    zoom_enabled: false
};

export type ControlsActions = ControlsZoomInEnabledAction;

export const controlsReducer = (
    state: ControlsState = controls_initial_state,
    action: ControlsActions
): ControlsState => {
    let new_state = state;

    if (action.type === ZOOM_ENABLED) {
        new_state.zoom_enabled = action.data;
        return {...new_state};
    }

    return new_state;
}

