// @flow

// main imports
import React from "react";
import { fromShiftTag, createHtmlAndDownload } from "../../lib/Util";
import * as Auth from "../../lib/Auth";
import { translate } from "../IntlProviderWrapper";
import { getBackend as getBackend2 } from "../../lib/backend/Backend2";
import * as t from "../../lib/backend/manufacturing2.generated.types";
import { renderToString } from "react-dom/server";
import { getLineTitleCssClass } from "../../lib/ShiftAssignment";

function generateExportShiftRowNew(
    line: t.IShiftPersonLineRec, shift_index: number,
    show_tool_change: boolean, show_work_orders: boolean, personWorks12Hours: Function
) {
    const shifts = line.shifts;

    const res = [];
    shifts.forEach((shift, i) => {
        if (i % 3 !== shift_index) {
            return;
        }
        let classTD = "";

        // enabled or disabled shift
        if (shift && !shift.enabled) {
            classTD += " shift-disabled";
        }

        // include persons
        const persons: string[] = [];
        const works_12h_map: Map<string, boolean> = new Map();
        if (shift && shift.persons && shift.persons.length) {
            shift.persons.forEach(p => {
                const works_12h = personWorks12Hours(p.uuid);
                works_12h_map.set(p.name, works_12h);
                persons.push(p.name);
            });
        }

        // include tool setup
        const tool_changes = [];
        const order_text = translate("common.order");
        shift.tool_changes.forEach((tc, idxToolChange) => {
            tool_changes.push(<div key={`id-${i}-${idxToolChange}-1`}>{`${tc.material_ext_id} (${order_text}: ${tc.order_external_id}) `}<br /> {tc.title}</div>);
        });

        // include work-orders
        const work_orders = [];
        const order_text_short = translate("common.order_short");
        shift.work_orders.forEach((wo, idxWorkOrder) => {
            work_orders.push(<div className={"work-order " + (wo.tool_setup ? "work-order-toolsetup" : "")} key={`idwo-${i}-${idxWorkOrder}-1`}>{wo.material_ext_id}<br /> {wo.title} <br />{`(${order_text_short} ${wo.order_external_id})`} </div>);
        });

        res.push(
            <td colSpan={1} key={i} className={"shift-person" + classTD}>
                <div>
                    {
                        persons.sort().map(
                            (person, k) =>
                                <div key={`id-${i}-${k}`}>
                                    <span className={works_12h_map.has(person) && works_12h_map.get(person) ? "person-12h-shift" : ""}>
                                        {person}
                                    </span>
                                </div>
                        )
                    }
                </div>
            </td>
        );

        if (show_work_orders) {
            res.push(
                <td colSpan={1} key={201010 + i} className={classTD}>
                    <div>
                        {work_orders.length > 0 &&
                            <div className="work-order-section">
                                {work_orders}
                            </div>
                        }
                    </div>
                </td>
            );
        } else if (show_tool_change) {
            res.push(
                <td colSpan={1} key={101010 + i} className={classTD}>
                    <div>
                        {tool_changes.length > 0 &&
                            <div className="tool-change">
                                {tool_changes}
                            </div>
                        }
                    </div>
                </td>
            );
        }
    });
    return res;
}

function arrayToString(arr) {
    let lines = "";
    for (const a of arr) {
        lines += a.line + ", ";
    }
    return lines.substr(0, lines.length-2);
}

function groupedByPeopleColumns(shifts, tool_changes, show_tool_change) {
    if (shifts.length) {
        return show_tool_change ? [
            <td className={"shift-person"} colSpan={show_tool_change ? 1 : 2}>{arrayToString(shifts)}</td>,
            <td colSpan={1} key={101010 + 1}>
                {tool_changes.length > 0 ? tool_changes: " "}
            </td>
        ] : <td className={"shift-person"} colSpan={show_tool_change ? 1 : 2}>{arrayToString(shifts)}</td>
    } else {
        return show_tool_change ? [
            <td className={"shift-person"} colSpan={show_tool_change ? 1 : 2}> </td>,
            <td> </td>
        ] : <td className={"shift-person"} colSpan={show_tool_change ? 1 : 2}> </td>
    }
}

function generateExportGroupedByPeople(
    table_data: t.IShiftPersonLineRec[],
    show_tool_change: boolean, mode: string,
    personWorks12Hours: (string) => boolean
) {
    let headerRow1 = [];
    let headerRow2 = [];

    let all_shifts = [];
    let tc_line_shift = new Map();
    // person_shift_line triples
    let person_shift_line = [];
    for (const line of table_data) {
        // extract person_shift_line triples
        all_shifts = [];
        let tc_line = new Map();
        for (const s of line.shifts) {
            // enabled or disabled shift
            all_shifts.push(s.shift_tag);
            const order_text = translate("common.order");
            const tcs = [];
            s.tool_changes.forEach((tc, idxToolChange) => {
                tcs.push(
                    <div key={`id-${s.shift_number}-${idxToolChange}-1`} className="tool-change">
                        {`${tc.material_ext_id} (${order_text}: ${tc.order_external_id}) `}<br /> {tc.title}
                    </div>
                );
            });
            tc_line.set(s.shift_tag, tcs);
            for (const p of s.persons) {
                person_shift_line.push({
                    name: p.name,
                    shift_tag: s.shift_tag,
                    shift: s.shift_number,
                    shift_day: s.shift_number%3,
                    line: line.line_title,
                    enabled: s.enabled,
                    uuid: p.uuid
                });
            }
        }
        tc_line_shift.set(line.line_title, tc_line);
        // generate header row 1 and 2
        let shifts = line.shifts;
        if (!headerRow2.length) {
            shifts.forEach((shift, shift_idx) => {
                let css = "th-wrapper shift-table-days text-center shift-header-td";
                headerRow1.push(
                    mode === "231" ?
                        <th colSpan={2} key={shift_idx} className={css}><div>{translate("common.shift") + " " + parseInt(((shift_idx + 1) % 3) + 1, 10)}</div></th>
                        :
                        <th colSpan={2} key={shift_idx} className={css}><div>{translate("common.shift") + " " + (shift_idx + 1)}</div></th>
                );
                headerRow2.push(
                    <th colSpan={show_tool_change ? 1 : 2}  key={"" + shift_idx} className={css}>{translate("common.schedule")}</th>
                );
                if (show_tool_change) {
                    headerRow2.push(
                        <th colSpan={1} key={"433_" + shift_idx} className={css}>{translate("common.change")}</th>
                    );
                }
            })
        }
    }

    // sort person, shift, line triples by
    // shift (1, 2, 3) and then by person name
    person_shift_line.sort((a, b) => {
        if (a.shift_day < b.shift_day) return -1;
        if (a.shift_day > b.shift_day) return 1;
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 1;
    });

    // for each person,shift pair give list of tool changes
    const tc_person_shift = new Map();
    person_shift_line.forEach( psl => {
        if (tc_line_shift.has(psl.line)) {
            const tc_line = tc_line_shift.get(psl.line);
            if (tc_line !== undefined && tc_line.has(psl.shift_tag)) {
                if (!(tc_person_shift.has(psl.name))) {
                    let tc_shifts = new Map();
                    tc_person_shift.set(psl.name, tc_shifts);
                }
                const tc_person = tc_person_shift.get(psl.name);
                if (tc_person !== undefined && !(tc_person.has(psl.shift_tag))) {
                    const tcs = tc_line.get(psl.shift_tag);
                    tc_person.set(psl.shift_tag, tcs);
                } else if (tc_person !== undefined) {
                    const prev_line_changes = tc_person.get(psl.shift_tag);
                    const this_line_changes = tc_line.get(psl.shift_tag);
                    if (prev_line_changes !== undefined && this_line_changes !== undefined) {
                        tc_person.set(psl.shift_tag, prev_line_changes.concat(this_line_changes));
                    }
                } else {

                }
            }
        }
    });

    // group person_shift_lines triples by people
    const people = new Map();
    const works_12h_map: Map<string, boolean> = new Map();
    for (const p of person_shift_line) {
        if (people.has(p.name)) {
            const element = people.get(p.name);
            if (element !== undefined) {
                element.push({ shift: p.shift, shift_tag: p.shift_tag, line: p, enabled: p.enabled });
                people.set(p.name, element);
            }
        } else {
            const works_12h = personWorks12Hours(p.uuid);
            works_12h_map.set(p.name, works_12h);
            const element = [{ shift: p.shift, shift_tag: p.shift_tag, line: p, enabled: p.enabled }];
            people.set(p.name, element);
        }
    }

    // generate final data:
    // list of lines for each person,shift pair
    let person_shift = new Map();
    for (const person_key of people.keys()) {
        let shifts = new Map();
        for (let i=0; i<all_shifts.length; i++) {
            const shift = { shifts: [], enabled: true };
            shifts.set(all_shifts[i], shift);
        }
        const all_shifts_of_a_person = people.get(person_key);
        if (all_shifts_of_a_person === undefined) {
            continue;
        }
        for (const ps of all_shifts_of_a_person) {
            const shift = shifts.get(ps.shift_tag);
            if (shift !== undefined) {
                shift.shifts.push(ps.line);
                shift.enabled = ps.enabled;
                shifts.set(ps.shift_tag, shift);
            }
        }
        person_shift.set(person_key, shifts);
    }

    // generate table body html
    const table_body = [...person_shift].map((person_shift_key_value_pair, person_index) => {
        const person_key = person_shift_key_value_pair[0];
        const person_shift_values = person_shift_key_value_pair[1];
        const tool_changes_shifts = tc_person_shift.get(person_key);
        const shifts1 = [...person_shift_values].map((shift_key_value_pair, shift_index) => {
            if (shift_index % 3 === 0) {
                const tool_changes_changes = tool_changes_shifts !== undefined ? tool_changes_shifts.get(shift_key_value_pair[0]) : [];
                return groupedByPeopleColumns(shift_key_value_pair[1].shifts, tool_changes_changes || [], show_tool_change);
            } else {
                return null;
            }
        });
        const shifts2 = [...person_shift_values].map((shift_key_value_pair, shift_index) => {
            if (shift_index % 3 === 1) {
                const tool_changes_changes = tool_changes_shifts !== undefined ? tool_changes_shifts.get(shift_key_value_pair[0]) : [];
                return groupedByPeopleColumns(shift_key_value_pair[1].shifts, tool_changes_changes || [], show_tool_change);
            } else {
                return null;
            }
        });
        const shifts3 = [...person_shift_values].map((shift_key_value_pair, shift_index) => {
            if (shift_index % 3 === 2) {
                const tool_changes_changes = tool_changes_shifts !== undefined ? tool_changes_shifts.get(shift_key_value_pair[0]) : [];
                return groupedByPeopleColumns(shift_key_value_pair[1].shifts, tool_changes_changes || [], show_tool_change);
            } else {
                return null;
            }
        });
        const works_12h = works_12h_map.has(person_key) && works_12h_map.get(person_key) ? true : false;
        return [
            <tr key={person_index} className="line-row">
                <td><div><span className={works_12h ? "person-12h-shift" : ""}>{person_key}</span></div></td>{shifts1}{shifts2}{shifts3}
            </tr>
        ];
    });

    return (
        <div className="card-body overflow-auto" >
            <div className="chart-v-frame h-100">
                <div className="chart-h-frame h-100">
                    <div className="chart-wrapper h-100">
                        <table className="table table-shifts table-bordered table-shifts-export full-width" id="table-shifts">
                            <thead>
                                <tr className="line-row">
                                    <th><div></div></th>
                                    {headerRow1}
                                </tr>
                                <tr className="line-row">
                                    <th><div></div></th>
                                    {headerRow2}
                                </tr>
                            </thead>
                            <tbody>
                                {table_body}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

function generateExportShiftTableNew(
    table_data: t.IShiftPersonLineRec[], show_tool_change: boolean, show_work_order: boolean, mode: string,
    print_only_assigned_lines: boolean, personWorks12Hours: (string) => boolean
) {
    let headerRow1 = [];
    let headerRow2 = [];

    let linesTable = table_data.map((line, i) => {
        let line_name = line.line_title;
        let shifts = line.shifts;

        if (!headerRow2.length) {
            shifts.forEach((shift, shift_idx) => {
                let css = "th-wrapper shift-table-days text-center shift-header-td";
                const col_span = 1 + (show_tool_change || show_work_order ? 1 : 0);
                headerRow1.push(
                    mode === "231" ?
                        <th colSpan={col_span} key={shift_idx} className={css}><div>{translate("common.shift") + " " + parseInt(((shift_idx + 1) % 3) + 1, 10)}</div></th>
                        :
                        <th colSpan={col_span} key={shift_idx} className={css}><div>{translate("common.shift") + " " + (shift_idx + 1)}</div></th>
                );
                headerRow2.push(
                    <th colSpan={1} key={"" + shift_idx} className={css}>{translate("common.schedule")}</th>
                );
                if (show_tool_change || show_work_order) {
                    const title = (show_work_order
                        ? translate("common.orders")
                        : translate("common.tool_setup")
                    );
                    headerRow2.push(
                        <th colSpan={1} key={"433_" + shift_idx} className={css}>{title}</th>
                    );
                }
            })
        }
        const lineTitleCss = getLineTitleCssClass(line.line_tags);
        let skip_row = true;
        line.shifts.forEach(shift => {
            if (shift && shift.persons && shift.persons.length) {
                skip_row = false;
            }
        });
        if (!skip_row || !print_only_assigned_lines) {
            return (
                <React.Fragment>
                    <tr key={i} className="line-row">
                        <td className={lineTitleCss}>
                            <div>{line_name}</div>
                        </td>
                        {generateExportShiftRowNew(line, 0, show_tool_change, show_work_order, personWorks12Hours)}
                        {generateExportShiftRowNew(line, 1, show_tool_change, show_work_order, personWorks12Hours)}
                        {generateExportShiftRowNew(line, 2, show_tool_change, show_work_order, personWorks12Hours)}
                    </tr>
                </React.Fragment>
            );
        } else {
            return null;
        }
    });

    return (
        <div className="card-body overflow-auto" >
            <div className="chart-v-frame h-100">
                <div className="chart-h-frame h-100">
                    <div className="chart-wrapper h-100">
                        <table className="table full-width table-shifts table-bordered table-shifts-export" id="table-shifts">
                            <thead>
                                <tr className="line-row">
                                    <th><div></div></th>
                                    {headerRow1}
                                </tr>
                                <tr className="line-row">
                                    <th><div></div></th>
                                    {headerRow2}
                                </tr>
                            </thead>
                            <tbody>
                                {linesTable}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

function escapeHtml(html: string): string {
    const text = document.createTextNode(html);
    const p = document.createElement('p');
    p.appendChild(text);
    return p.innerHTML;
}

export async function exportDayToHtml(
    year: number, week: number, day_of_week: number,
    plant_title: string, linegroup_title: string,
    show_tool_change: boolean,
    show_work_orders: boolean,
    line_uuids: string[], msg: string, mode: string,
    group_by_people: boolean, print_only_assigned_lines: boolean,
    personWorks12Hours: (string) => boolean
): Promise<void> {

    // export from to parameters
    let from_shift_tag = "" + (year * 10000 + (week) * 100 + 3 * day_of_week);
    let to_shift_tag = "" + (year * 10000 + week * 100 + 3 * (day_of_week + 1));

    if (mode === "231") {
        from_shift_tag = "" + (year * 10000 + (week) * 100 + 3 * day_of_week + 1);
        to_shift_tag = "" + (year * 10000 + week * 100 + 3 * (day_of_week + 1) + 1);
    }

    const data = await getBackend2().manufacturing.getPeopleShiftsTable({
        from_shift: from_shift_tag,
        to_shift: to_shift_tag,
        include_tool_changes: true,
        include_work_orders: true,
        line_uuids
    });
    const results = data.lines.sort((a, b) => a.weight - b.weight);
    const tableShifts = group_by_people
        ? renderToString(generateExportGroupedByPeople(results, show_tool_change, mode, personWorks12Hours))
        : renderToString(generateExportShiftTableNew(results, show_tool_change, show_work_orders, mode, print_only_assigned_lines, personWorks12Hours));

    const shiftDate = fromShiftTag(from_shift_tag);
    const date = shiftDate.getDate() + ". " + (shiftDate.getMonth() + 1) + ". " + year;

    let commentRow = "";
    if (msg !== "") {
        const remark = translate("common.remark", "Opomba");
        const html = escapeHtml(msg).replace(/\n/gi, "<br/>");
        commentRow = `<div class="comment">${remark}:<br/> ${html} </div>`;
    }
    const org_code = Auth.getOrgData().code;
    let logo = "";
    try {
        const resp = (await getBackend2().getApiClient().get(`/dynamic/logo/${org_code}/base64`)).data;
        logo = `<img src="data:${resp.mime};base64,${resp.data}" style="width: 100px; padding: 3px;"/>`
    } catch (e) {
        logo = "";
    }

    const html = `
        <html>
            <head>
                <title>${translate("common.timetable")}: ${date} ${plant_title} - ${linegroup_title}</title>
                <meta charSet="utf-8" />
                <style>
                body {
                    font-family: "Arial", "Helvetica", "sans-serif";
                }

                .full-width { width: 100% }

                body,
                table,
                p,
                div {
                    font-size: 12px;
                }

                table {
                    border-collapse: collapse;
                    border: 1px solid black;
                }

                td:empty {
                    background-color: rgb(220, 220, 220);
                }

                th,
                td {
                    border: 1px solid lightgray;
                    border-bottom: 0px solid lightgray;
                    padding: 3px;
                    text-align: center;
                }
                th {
                    border: 1px solid black;
                }
                tr td:first-child {
                    border-left: 1px solid black;
                }
                tr td:nth-child(2n+1) {
                    border-right: 1px solid black;
                }
                tr th:nth-child(2n+1) {
                    border-right: 1px solid black;
                }
                tr.border-bottom>td, tr.border-bottom>th {
                    border-bottom: 1px solid black;
                }
                .tool-change {
                    font-weight: bold;
                }

                .shift-disabled {
                    background-color: rgb(237, 237, 237) !important;
                }

                .line-linegroup-lead,
                .line-shift-lead {
                    color: white;
                    background-color: darkorange;
                }

                .line-cameraman,
                .line-control,
                .line-packaging,
                .line-sick-leave,
                .line-tool-setup {
                    color: white;
                    background-color: darkolivegreen;
                }

                .header-table {
                    border: 0px;
                    width: 100%;
                    text-align: left;
                }

                .header-table td,
                .header-table tr {
                    border: 0px !important;
                }
                .comment {
                    font-size: 1.3em;
                    font-weight: bold;
                    text-align: left;
                    border: 1px solid black;
                    padding: 15px;
                    margin-bottom: 5px;
                }
                .person-12h-shift {
                    background-color: yellow;
                }
                .work-order {
                    border: 1px solid rgb(204,204,204);
                    padding: 1px;
                    border-radius: 5px;
                }
                .work-order-toolsetup {
                    background-color: rgb(100,100,100);
                    color: white;
                }
                @media print {
                    body {
                        -webkit-print-color-adjust: exact;
                    }
                }
                </style>
            </head>
            <body onload="window.print()">
                <table class="header-table">
                    <tr>
                        <td style="width: 105px;">
                            ${logo}
                        </td>
                        <td>
                            <h3>${translate("common.timetable")}: ${date}</h3>
                        </td>
                        <td>
                            <h3>${plant_title} - ${linegroup_title}</h3>
                        </td>
                    </tr>
                </table>
                ${commentRow}
                ${tableShifts}
            </body>
        </html>`;

    createHtmlAndDownload(html, "timetable-" + week + ".html");
}
