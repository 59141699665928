// @flow

import React from "react";
import { FormattedMessage } from "react-intl";
import { Modal } from "react-bootstrap";
import { translate } from "./IntlProviderWrapper";
import ErrorComponent from "./ErrorComponent";
import { getBackend } from "../lib/backend/Backend2";
import { downloadAndOpenFile } from "../lib/Backend.rest";
import { ResourcesLayout } from "./MainContent";

type ItemListProps = {
    keys: string[],
    selected_key: string,
    handleClick: (string) => void
}
type ItemListState = {};


type SaveChangesModalProps = {
    show: boolean,
    onSave: any => any,
    onCancel: any => any
}
const SaveChangesModal = (props: SaveChangesModalProps) => {
    return <Modal show={props.show} >
        <Modal.Header>
            <Modal.Title>{translate("common.save", "Save")}</Modal.Title>
            <button type="button" className="close" onClick={props.onCancel}>
                <span aria-hidden="true">×</span>
                <span className="sr-only">
                    <FormattedMessage id="common.close" defaultMessage="Close" />
                </span>
            </button>
        </Modal.Header>
        <Modal.Body>
            {translate("common.resource_edit_modal", "Do you wish to save your changes?")}
        </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-primary" onClick={props.onSave}>
                <FormattedMessage id="common.yes" defaultMessage="Yes" />
            </button>
            <button className="btn btn-outline-secondary" onClick={props.onCancel}>
                <FormattedMessage id="common.no" defaultMessage="No" />
            </button>
        </Modal.Footer>
    </Modal>;
}

class ItemList extends React.Component<ItemListProps, ItemListState> {

    render() {
        const props = this.props;
        const list = props.keys.map((item, i) => {
            const css = "nav-link " + (item === props.selected_key ? "active show" : "");
            return <li className="nav-item" key={i} onClick={() => props.handleClick(item)}>
                <a itemRef={"a" + i} className={css} data-toggle="tab">
                    {item}
                </a>
            </li>;
        });
        return (
            <ul className="nav nav-tabs">
                <div className="nav-tabs-parent" ref="nav">
                    {list}
                </div>
            </ul>
        );
    }
}

/** Utility function for getting the list of blob-storage items */
async function getItems(): Promise<string[]> {
    const x = await getBackend().common.getBlobStorageItemList({});
    return x.items.map(x => x.key);
}


type Props = {};

type State = {
    content: string,
    error: string,
    items: string[],
    selected_key: string,
    show_changes_modal: boolean,
    original_content: string,
    new_selected_key: ?string,
    show_download_button: boolean
};

class BlobStorageItems extends React.Component<Props, State> {
    inputOpenFileRef: { current: null | HTMLInputElement };
    // batchopGroupsListRef: { current: null | BatchopGroupsList };
    constructor(props: Props) {
        super(props);

        const state: State = {
            content: "",
            original_content: "",
            error: "",
            items: [],
            selected_key: "",
            new_selected_key: "",
            show_changes_modal: false,
            show_download_button: false
        };
        this.state = state;
        this.inputOpenFileRef = React.createRef();
    }

    async showKey(key: string): Promise<void> {
        try {

            const additional_info = await getBackend().common.getBlobStorageAdditionalInfo({ key });
            const size = additional_info.size;

            this.setState({ error: "", selected_key: key });
            if ((size && (size / (1024 * 1024)) < 1) || !size) {
                const res = await getBackend().common.getBlobStorageItemAsString({ key });
                this.setState({ content: res.content, original_content: res.content, show_download_button: false });
            } else {
                this.setState({ content: "", original_content: "", show_download_button: true });
            }

        } catch (err) {
            this.setState({ error: "" + err });
            console.log(err);
        }
    };

    async componentDidMount() {
        try {
            const items = await getItems();
            this.setState({ items });
            if (items.length > 0) {
                await this.showKey(items[0]);
            }
        } catch (err) {
            this.setState({ error: "" + err, items: [], content: "", selected_key: "", original_content: "" });
            console.log(err);
        }
    };

    onCreateNew = async () => {
        this.setState({ error: "" });
        let file_name = (prompt("Enter name for the new file", "") || "").trim();
        if (file_name.length == 0) {
            this.setState({ error: "Name cannot be empty" });
            return;
        }
        if (this.state.items.indexOf(file_name) >= 0) {
            this.setState({ error: "Name already exists" });
            return;
        }
        await getBackend().common.setBlobStorageItemAsString({
            content: "",
            key: file_name
        });
        const items = await getItems();
        this.setState({ items }, () => {
            this.showKey(file_name);
        });
    }

    onUpload = async () => {
        if (this.inputOpenFileRef.current) {
            this.inputOpenFileRef.current.click()
        }
    }

    // $FlowFixMe
    setSelectedFile = async (file: File) => {
        const file_name = file.name;
        await getBackend().common.setBlobStorageItemAsString({
            content: await file.text(),
            key: file_name
        });

        alert("File was successfully uploaded");

        const items = await getItems();
        this.setState({ items }, () => {
            this.showKey(file_name);
        });
    }

    onSave = async () => {
        const new_selected_key = this.state.new_selected_key;

        try {
            this.setState({ error: "" });
            await getBackend().common.setBlobStorageItemAsString({
                content: this.state.content,
                key: this.state.selected_key
            });

            this.setState({
                show_changes_modal: false,
                new_selected_key: null,
                original_content: this.state.content
            });

            this.showKey(this.state.selected_key);
        } catch (err) {
            this.setState({ error: "" + err, show_changes_modal: false, new_selected_key: null });
            console.log(err);
        }

        if (new_selected_key) {
            this.showKey(new_selected_key);
        }
    }

    onReset = () => {
        this.showKey(this.state.selected_key)
    }

    onChange = (e: Event) => {
        let target = e.target;
        if (target instanceof HTMLTextAreaElement) {
            this.setState({ content: target.value });
        }
    }

    onCancelSaveChangesModal = () => {
        if (this.state.new_selected_key) {
            this.setState({
                show_changes_modal: false,
                selected_key: this.state.new_selected_key,
                new_selected_key: null
            }, () => {
                this.showKey(this.state.selected_key);
            });
        } else {
            this.setState({ show_changes_modal: false });
        }
    }

    onClickItemList = (new_key: string) => {
        const should_show_changes_modal = (
            new_key !== this.state.selected_key &&
            this.state.original_content !== this.state.content &&
            !this.state.show_changes_modal
        );

        if (should_show_changes_modal) {
            this.setState({ show_changes_modal: true, new_selected_key: new_key });
        } else {
            this.showKey(new_key);
        }
    }

    getTokenAndRedirect = async () => {
        const token = await getBackend().common.getBlobStorageFileToken({ fname: this.state.selected_key });
        downloadAndOpenFile("/direct-file/" + token.token);
    }

    renderContent() {
        if (!this.state.show_download_button) {
            return(
                <textarea
                    rows={35}
                    onChange={this.onChange}
                    value={this.state.content}
                    style={{ width: "100%" }}
                ></textarea>
            )
        } else {
            return(
                <div className="mt-4" style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
                    <FormattedMessage id="common.unable_to_preview_try_downloading" defaultMessage="Unable to preview file. Try downloading instead" />
                    <button onClick={() => this.getTokenAndRedirect()} className="btn ml-3 mt-4">
                        <FormattedMessage id="common.download" defaultMessage="Download" />
                    </button>
                </div>
            )
        }
    }

    render() {
        return (
            <React.Fragment>
                <SaveChangesModal
                    show={this.state.show_changes_modal}
                    onSave={this.onSave}
                    onCancel={this.onCancelSaveChangesModal}
                />

                <ResourcesLayout>
                    <ResourcesLayout.Header
                        title={
                            <h2>
                                <FormattedMessage id="Header.menu.custom_files" defaultMessage="Custom files" />
                            </h2>
                        }
                        buttons={
                            <React.Fragment>
                                <button disabled={this.state.original_content === this.state.content} onClick={() => this.setState({ show_changes_modal: true })} className="btn ml-3">
                                    {translate("common.save", "Save")}
                                </button>
                                <button disabled={this.state.original_content === this.state.content} onClick={this.onReset} className="btn ml-3">
                                    {translate("common.reset", "Reset")}
                                </button>
                                <button onClick={() => this.onCreateNew()} className="btn ml-3">
                                    {translate("common.new", "New")}
                                </button>
                                <input
                                    ref={this.inputOpenFileRef}
                                    type="file"
                                    style={{ display: "none" }}
                                    onChange={(e) => this.setSelectedFile(e.target.files[0])}
                                />
                                <button onClick={this.onUpload} className="btn ml-3">
                                    {translate("common.upload", "Upload")}
                                </button>
                            </React.Fragment>
                        }
                    />
                    <ResourcesLayout.Main>
                        <ResourcesLayout.List>
                            <ItemList
                                keys={this.state.items}
                                selected_key={this.state.selected_key}
                                handleClick={this.onClickItemList}
                            />
                        </ResourcesLayout.List>
                        <ResourcesLayout.Content>
                            <ErrorComponent
                                type="error"
                                msg={this.state.error} />
                            {this.renderContent()}
                        </ResourcesLayout.Content>
                    </ResourcesLayout.Main>
                </ResourcesLayout>
            </React.Fragment>
        );
    }
}
export default BlobStorageItems;
