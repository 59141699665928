// @flow
import * as React from "react";
import { connect } from "react-redux";
import UnscheduledOrder from "./UnscheduledOrder";
import * as constants from "./constants";
import { dateFromWeekShift, orderColor } from "../../../../lib/Util";
import { ConnectedLineLabel } from "./Line";
import { translate } from "../../../IntlProviderWrapper";
import { countUnscheduledOrders } from "../reducers/UnscheduledOrdersHelper";
import TranslatedMaterialTitle from "../../../TranslatedMaterialTitle";
import { sortUnscheduled } from "../reducers/linesOrders";
import { ORDER_TYPE } from "../../../../lib/ManufacturingConsts.generated";
import * as t2 from "../../../../lib/SimulationReportModels";
import * as rt from "../../../../lib/backend/reports.generated.types";
import { ORDER_TAGS } from "../../../../lib/ManufacturingTags.generated";
import ReworkOrderLinks from "./ReworkOrderLinks";


import type { LineOrders, IOrderProducedModelEx } from "../reducers/linesOrders";
import type { ValidOrder } from "../reducers/common";
import type { ReduxState } from "../reducers/reducers";
import type { ShiftProps } from "./Shifts.jsx";
import type { MaterialTitleProps } from "./MaterialTitle";
import type { OrderRectangleProps } from "./Order";
import type { PropertiesState, GanttChartSourceTypes } from "../reducers/properties";
import type { ReworkParams } from "./ReworkOrderLinks";

export const prepareLinkPositions = (
    map1: Map<string, ReworkParams>,
    map2: Map<string, ReworkParams>,
    adjustmentY: number
): Map<any, any> => {
    const map = new Map();
    for (const [key, value] of map1) {
        // y is currently the position of the order, adjustment should ajdust the link horizontally
        value.y += adjustmentY;
        value.x += value.width / 2;
        map.set(key, value);
    }
    for (const [key, value] of map2) {
        value.y += adjustmentY;
        value.x += value.width / 2;
        map.set(key, value);
    }
    return map;
}

type Props = {
    unscheduled_orders: LineOrders[],
    lines_orders: LineOrders[],
    show_unscheduled_orders: boolean,
    valid_orders_map: Map<string, ValidOrder> | null,
    properties: PropertiesState,
    source_type: GanttChartSourceTypes,
    report_data: t2.IReportModelEx | null
};

type State = {};

class UnscheduledLines extends React.Component<Props, State> {

    getHoursForTimestamp = (value: number) => {
        return ((((value) / 1000) / 60) / 60);
    }

    calculateY0 = (line_counter: number) => {
        const { bar_height, space_between_bars } = this.props.properties;
        return (line_counter * (bar_height + space_between_bars));
    }

    getProcessNum = (order: IOrderProducedModelEx) => {
        if (order.process_num) {
            return " - " + order.process_num;
        }
        return "";
    }

    updateDuration = (unscheduled_orders: IOrderProducedModelEx[]) => {
        const report_orders = new Map<string, rt.ISimulationReportUnscheduledOrder>();
        const report_data = this.props.report_data;
        if (!report_data) {
            return unscheduled_orders;
        }

        for (const order of report_data.result.unscheduled) {
            report_orders.set(order.order_uuid, order);
        }
        for (const unscheduled_order of unscheduled_orders) {
            const report_order = report_orders.get(unscheduled_order.uuid);
            if (report_order) {
                unscheduled_order.estimated_duration = report_order.estimated_duration[1].val;
            }
        }

        return unscheduled_orders;
    }

    renderReworkOrders = (base_order_positions: Map<string, ReworkParams>) => {
        const properties = this.props.properties;
        const xscale = properties.xscale;

        let unscheduled_orders = [];
        for (const unscheduled_line of this.props.unscheduled_orders) {
            unscheduled_orders = unscheduled_orders.concat(unscheduled_line.orders);
        }
        unscheduled_orders = sortUnscheduled(unscheduled_orders)
        unscheduled_orders = this.updateDuration(unscheduled_orders);

        const Lines = [];
        const rework_orders = [];
        const rework_orders_positions = new Map<string, ReworkParams>();
        const rework_orders_links = new Map<string, ReworkParams[]>();

        for (let i = 0; i < unscheduled_orders.length; i++) {
            const order = unscheduled_orders[i];
            if (!order.tags[ORDER_TAGS.linked_operation]) {
                continue;
            }
            rework_orders.push(order);
            const line_uuid = order.line_uuid;
            const order_uuid = order.uuid;
            const estimated_duration = order.estimated_duration;

            if (!xscale) {
                console.error("Xscale not set");
                return null;
            }

            const base_order = base_order_positions.get(order.tags[ORDER_TAGS.linked_operation]);
            if (!base_order) {
                continue;
            }

            let x0 = base_order.x + base_order.width;
            let y0 = base_order.y;
            let w = xscale(estimated_duration || constants.SHIFT_H * 3);
            const line_index = base_order.line_index;

            const rework_order_positions = rework_orders_links.get(order.tags[ORDER_TAGS.linked_operation]) || [];
            if (rework_orders_links.has(order.tags[ORDER_TAGS.linked_operation])) {
                if (rework_order_positions.length > 0) {
                    const last_rework = rework_order_positions[rework_order_positions.length - 1];
                    x0 = last_rework.x + last_rework.width;
                }
            }
            const rework_order_position = {
                x: x0,
                y: y0,
                width: w,
                line_index,
                order_uuid: order.uuid
            }
            rework_orders_positions.set(order.uuid, rework_order_position);
            rework_order_positions.push(rework_order_position);
            rework_orders_links.set(order.tags[ORDER_TAGS.linked_operation], rework_order_positions);

            // middle of an order in pixels
            let x1_2 = x0;

            const has_insight: boolean = false;

            const order_color = orderColor({
                order_type: order.order_type,
                has_insight
            });

            const insight_tooltips = [];

            const order_rectangle_attributes: $Shape<OrderRectangleProps> = {
                x: x0,
                y: y0,
                rx: 0,
                ry: 0,
                width: w,
                height: properties.bar_height,
                fill: order_color,
                unscheduled_order: order,
                has_insight,
                insight_tooltips,
                line_uuid: order.line_uuid,
                earliest_start: order.earliest_start,
                earliest_end: order.earliest_end,
                order_uuid,
                is_unscheduled: true,
                production_version: order.tags[ORDER_TAGS.production_version]
            };

            const material_title_attributes: $Shape<MaterialTitleProps> = {
                x: x1_2 + (w / 2),
                y: y0 + properties.bar_height / 2 + 5,
                material_title: TranslatedMaterialTitle({
                    material_title: order.material_title,
                    material_external_id: order.material_external_id
                }),
                width: w,
                order_uuid,
                should_truncate_title: true,
                material_external_id: order.material_external_id,
                source_type: this.props.source_type
            };

            Lines.push(
                <g id="lines-region" key={"guo-" + order_uuid}>
                    <UnscheduledOrder
                        key={"uo-" + order_uuid}
                        order_id={order_uuid}
                        order_uuid={order_uuid}
                        order_process_num={order.process_num}
                        shift_number={-1}
                        line_index={line_index}
                        line_uuid={line_uuid}
                        order_type={order.order_type}
                        shifts_attributes={[]}
                        material_title_attributes={material_title_attributes}
                        order_rectangle_attributes={order_rectangle_attributes}
                        source_type={this.props.source_type}
                        unscheduled_order={order}
                        earliest_start={order.earliest_start}
                        is_linked_operation={!!order.tags[ORDER_TAGS.linked_operation]}
                    />
                </g>
            );
        }

        return <g>
            {Lines}
            <ReworkOrderLinks
                rework_orders={rework_orders}
                all_orders_map={prepareLinkPositions(base_order_positions, rework_orders_positions, properties.bar_height)}
            />
        </g>
    }

    renderLines = () => {
        const properties = this.props.properties;
        const xscale = properties.xscale;
        const current_shift = properties.current_shift;
        const shift_offset = (properties.start_day * constants.SHIFTS_PER_DAY) + (current_shift % constants.SHIFTS_PER_DAY);

        let unscheduled_orders = [];
        for (const unscheduled_line of this.props.unscheduled_orders) {
            unscheduled_orders = unscheduled_orders.concat(unscheduled_line.orders);
        }
        unscheduled_orders = sortUnscheduled(unscheduled_orders)
        unscheduled_orders = this.updateDuration(unscheduled_orders);

        const valid_orders_map: Map<string, ValidOrder> = this.props.valid_orders_map || new Map();

        let line_counter = 0;
        let line_index = 0;
        const Lines = [];
        const LineLabels = [];
        const order_positions_map = new Map<string, ReworkParams>();

        for (let i = 0; i < unscheduled_orders.length; i++) {
            const order = unscheduled_orders[i];

            if (order.tags[ORDER_TAGS.linked_operation] || !valid_orders_map.has(order.uuid)) {
                continue;
            }
            let estimated_duration = order.estimated_duration;
            if (!estimated_duration || !(estimated_duration > 0)) {
                estimated_duration = this.props.properties.shift_width || 0;
            }
            const line_uuid = order.line_uuid;
            const bars_start = this.props.properties.bars_start;
            const order_uuid = order.uuid;

            line_index = line_counter;

            let y0 = this.calculateY0(line_counter);

            line_counter++;

            if (!xscale) {
                console.error("Xscale not set");
                return null;
            }

            // start of the order in hours
            let time_start = (
                this.getHoursForTimestamp(order.earliest_start) -
                this.getHoursForTimestamp(dateFromWeekShift(new Date(), current_shift).getTime()) +
                (((current_shift || 0) % constants.SHIFTS_PER_DAY) * constants.SHIFT_H)
            );
            if (time_start < 0) {
                time_start = (current_shift % constants.SHIFTS_PER_DAY) * constants.SHIFT_H || 0;
            }

            // beginning of the order in pixels
            let x0 = bars_start + xscale(time_start);
            // start of the order at the first beginning of shift
            // it is the same as time_start if time_start is at the
            // beginning of a shift
            let time_start_zero = time_start;
            // update the beginning of the order in pixels
            let x0_shifts = bars_start + xscale(time_start);
            // length of an order in pixels
            let w = xscale(estimated_duration);
            // length of one shift in pixels
            let w_shift = xscale(constants.SHIFT_H);

            if (w === 0) {
                w = xscale(constants.SHIFT_H * 3);
            }

            // ending of an order in hours
            let time_end = time_start + estimated_duration;
            // middle of an order in pixels
            let x1_2 = x0;

            // roundnes of rectangles
            let rx = 0;

            const has_insight: boolean = false;

            const order_color = orderColor({
                order_type: order.order_type,
                has_insight
            });

            const insight_tooltips = [];

            const order_rectangle_attributes: $Shape<OrderRectangleProps> = {
                x: x0,
                y: y0,
                rx: 0,
                ry: 0,
                width: w,
                height: properties.bar_height,
                fill: order_color,
                unscheduled_order: order,
                has_insight,
                insight_tooltips,
                line_uuid: order.line_uuid,
                earliest_start: order.earliest_start,
                earliest_end: order.earliest_end,
                order_uuid,
                is_unscheduled: true,
                production_version: order.tags[ORDER_TAGS.production_version]
            };

            // iterate all shifts of order and draw a rect for each shift
            let shift_counter1 = 0;
            const shifts_attributes = [];

            while (x0_shifts + (shift_counter1 * w_shift) < w + x0 - w_shift && time_start_zero + (constants.SHIFT_H * shift_counter1) < time_end) {
                // index of the shift
                const shift_index = Math.floor((time_start / constants.SHIFT_H) + shift_counter1) + shift_offset;

                // color of an order rect
                let color = orderColor({
                    order_type: order.order_type,
                    i,
                    has_insight
                });
                // check if the shift is enabled
                let future_shift = false;

                const shift_x = x0_shifts + (shift_counter1 * w_shift);
                const shift_y = y0;

                // deterime onpacity of shift
                let rect_opacity = future_shift ? 0.8 : 0.1;

                const shift_attribute: $Shape<ShiftProps> = {
                    shift_number: shift_index,
                    line_index,
                    x: shift_x,
                    y: shift_y,
                    rx: rx,
                    ry: 0,
                    width: w_shift,
                    height: properties.bar_height,
                    fill: color,
                    fill_opacity: rect_opacity,
                    last_shift: false,
                    order_uuid: order_uuid,
                    line_uuid: line_uuid,
                    order_index: i,
                    source_type: this.props.source_type
                };

                shifts_attributes.push(shift_attribute);
                shift_counter1 += 1;
            }

            if (shifts_attributes.length > 0) {
                shifts_attributes[shifts_attributes.length - 1].last_shift = true;
            }

            const material_title_attributes: $Shape<MaterialTitleProps> = {
                x: x1_2 + (w / 2),
                y: y0 + properties.bar_height / 2 + 5,
                material_title: TranslatedMaterialTitle({
                    material_title: order.material_title,
                    material_external_id: order.material_external_id
                }),
                width: w,
                order_uuid,
                should_truncate_title: true,
                material_external_id: order.material_external_id,
                source_type: this.props.source_type
            };

            Lines.push(
                <g id="lines-region" key={"guo-" + order_uuid}>
                    <UnscheduledOrder
                        key={"uo-" + order_uuid}
                        order_id={order_uuid}
                        order_uuid={order_uuid}
                        order_process_num={order.process_num}
                        shift_number={shifts_attributes[0] && shifts_attributes[0].shift_number}
                        line_index={line_index}
                        line_uuid={line_uuid}
                        order_type={order.order_type}
                        shifts_attributes={shifts_attributes}
                        material_title_attributes={material_title_attributes}
                        order_rectangle_attributes={order_rectangle_attributes}
                        source_type={this.props.source_type}
                        unscheduled_order={order}
                        earliest_start={order.earliest_start}
                        is_linked_operation={!!order.tags[ORDER_TAGS.linked_operation]}
                    />
                </g>
            );

            const order_title = translate("common.order", "Order") + " " + order.external_id + this.getProcessNum(order);

            LineLabels.push(
                <ConnectedLineLabel
                    key={"cll" + order.uuid}
                    line_uuid={line_uuid}
                    line_title={order_title}
                    properties={this.props.properties}
                    order_uuid={order.uuid}
                    is_workorder={order.order_type !== ORDER_TYPE.plan}
                    line_index={line_index}
                    line_offset={y0}
                    is_unscheduled={true}
                />
            );
            order_positions_map.set(
                order.uuid,
                {
                    x: x0,
                    y: y0,
                    width: w,
                    line_index: line_index,
                    order_uuid: order.uuid
                }
            );
        }

        return <g>
            {Lines}
            <g id="line-labels-group-unscheduled" style={{ willChange: "transform" }}>
                <rect height="100%" width={this.props.properties.max_line_label_length} fill="white" display-outside="block" />
                {LineLabels}
            </g>
            {this.renderReworkOrders(order_positions_map)}
        </g>;
    }

    render() {
        if (!this.props.show_unscheduled_orders || countUnscheduledOrders(this.props.unscheduled_orders) === 0) {
            return null;
        }

        return (<React.Fragment>
            {this.renderLines()}
        </React.Fragment>);
    }
}

export default connect(
    (state: ReduxState, own_props: Props) => {
        const lines_orders = state.gantt_chart_lines_orders;
        const planning_table = state.gantt_chart_planning_table;
        const report_data = state.gantt_chart_report.report_data;

        return {
            report_data,
            unscheduled_orders: lines_orders.unscheduled_orders,
            properties: state.gantt_chart_properties,
            show_unscheduled_orders: planning_table.show_unscheduled_orders,
            valid_orders_map: planning_table.show_valid_lines.valid_orders_map,
            lines_orders: lines_orders.lines_orders,
        }
    },
    null
)(UnscheduledLines);
