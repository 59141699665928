// @flow

import React from "react";
import moment from "moment";

import { translate } from "./IntlProviderWrapper";
import Loader from "./Loader";
import ReactTable, { type ReactTableColumn } from "./react/ReactTable";
import { type DataItem, type TimeSeries } from "./DemandForecasting";
import { DateColumnFilter, filterDate, renderRealNumber, renderIntegerNumber } from "./DemandForecastingTable";

export type TableTsRow = {
    date: string,
    creation_date: string,
    horizon: number,
    sales_orders: number | null,
    pir_so_max: number | null,
    sales_orders_changes: number | null,
    pir: number | null
};

export type ExpTimeSeries = {
    ts: string[],
    vals: (number | null)[]
};

type Props = {
    data: DataItem[]
};

type State = {
    columns: ReactTableColumn<TableTsRow>[],
    rows: TableTsRow[] | null
};

const expandTimeSeries = (series: TimeSeries, ts_min: string, ts_max: string): ExpTimeSeries => {
    const expanded_ts: ExpTimeSeries = {
        ts: [],
        vals: []
    };

    const ts = [...series.ts];
    const vals = [...series.vals];
    const fill_value = null;

    if (!ts.length || ts_min < ts[0]) {
        ts.unshift(ts_min);
        vals.unshift(fill_value);
    }

    if (!ts.length || ts_max > ts[ts.length - 1]) {
        ts.push(ts_max);
        vals.push(fill_value);
    }

    ts.forEach((ts_i, i) => {
        expanded_ts.ts.push(ts_i);
        expanded_ts.vals.push(vals[i]);

        if (i < ts.length - 1) {
            const current_month = moment(ts_i).startOf("month").add(1, "months");
            const max_month = moment(ts[i + 1]).startOf("month");
            while (current_month.isBefore(max_month)) {
                expanded_ts.ts.push(current_month.format("YYYY-MM"));
                expanded_ts.vals.push(fill_value);
                current_month.add(1, "months");
            }
        }
    });

    return expanded_ts;
};

class DemandForecastingHistory extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            columns: this.getColumns(),
            rows: null
        };
    }

    componentDidMount() {
        this.mapData();
    }

    componentDidUpdate(prev_props: Props) {
        const prev_plant = prev_props.data[0] && prev_props.data[0].properties.plant;
        const prev_material = prev_props.data[0] && prev_props.data[0].properties.material;
        const plant = this.props.data[0] && this.props.data[0].properties.plant;
        const material = this.props.data[0] && this.props.data[0].properties.material;

        if (prev_plant !== plant || prev_material !== material) {
            this.mapData();
        }
    }

    getColumns = () => [
        {
            Header: "",
            title: translate("common.date", "Date"),
            accessor: "date",
            filterInputPlaceholder: translate("common.date", "Date"),
            Filter: DateColumnFilter,
            filter: filterDate
        },
        {
            Header: "",
            title: translate("DemandForecasting.creation_date", "Creation date"),
            accessor: "creation_date",
            filterInputPlaceholder: translate("DemandForecasting.creation_date", "Creation date"),
            Filter: DateColumnFilter,
            filter: filterDate
        },
        {
            Header: "",
            title: translate("common.horizon", "Horizon"),
            accessor: "horizon",
            filterInputPlaceholder: translate("common.horizon", "Horizon"),
            textAlign: "right",
            Cell: renderIntegerNumber
        },
        {
            Header: translate("DemandForecasting.sales_orders", "Sales orders"),
            title: translate("DemandForecasting.sales_orders", "Sales orders"),
            accessor: "sales_orders",
            disableFilters: true,
            textAlign: "right",
            Cell: renderRealNumber
        },
        {
            Header: translate("DemandForecasting.sales_orders_changes", "Sales orders changes"),
            title: translate("DemandForecasting.sales_orders_changes", "Sales orders changes"),
            accessor: "sales_orders_changes",
            disableFilters: true,
            textAlign: "right",
            Cell: renderRealNumber
        },
        {
            Header: translate("DemandForecasting.pir", "PIR"),
            title: translate("DemandForecasting.pir", "PIR"),
            accessor: "pir",
            disableFilters: true,
            textAlign: "right",
            Cell: renderRealNumber
        },
        {
            Header: translate("DemandForecasting.max_pir_so", "max(PIR, SO)"),
            title: translate("DemandForecasting.max_pir_so", "max(PIR, SO)"),
            accessor: "pir_so_max",
            disableFilters: true,
            textAlign: "right",
            Cell: renderRealNumber
        }
    ];

    mapData = () => {
        const rows: TableTsRow[] = [];
        const row_key_map = {
            sales_orders: "sales_orders",
            PIR_SO_max: "pir_so_max",
            sales_orders_changes: "sales_orders_changes",
            planned_independent_requirements: "pir"
        };
        this.props.data.forEach(item => {
            let ts_bounds = [];
            Object.keys(item.data).forEach(key => {
                ts_bounds.push(item.data[key].ts[0]);
                ts_bounds.push(item.data[key].ts[item.data[key].ts.length - 1]);
            });
            ts_bounds = ts_bounds.filter(ts => !!ts);
            ts_bounds.sort();

            if (ts_bounds.length < 2) {
                return;
            }

            const ts_min = ts_bounds[0];
            const ts_max = ts_bounds[ts_bounds.length - 1];
            const series = {};
            let ts;
            Object.keys(item.data).forEach(key => {
                series[key] = expandTimeSeries(item.data[key], ts_min, ts_max);

                if (!ts) {
                    ts = series[key].ts;
                }
            });

            if (!ts) {
                return;
            }

            ts.forEach((ts_i, i) => {
                const row: TableTsRow = {
                    date: ts_i,
                    creation_date: moment(ts_i).subtract(item.properties.horizon, "months").format("YYYY-MM"),
                    horizon: item.properties.horizon,
                    sales_orders: null,
                    pir_so_max: null,
                    sales_orders_changes: null,
                    pir: null
                };
                Object.keys(series).forEach(key => {
                    row[row_key_map[key]] = series[key].vals[i];
                });
                rows.push(row);
            });
        });

        rows.sort((a, b) => {
            if (b.date < a.date) return -1;
            if (b.date > a.date) return 1;
            return a.horizon - b.horizon;
        });

        this.setState({
            rows
        });
    };

    render() {
        if (this.state.rows === null) {
            return <Loader />;
        }

        return <ReactTable className="df-table df-table-ts" columns={this.state.columns} data={this.state.rows} />;
    }
}

export default DemandForecastingHistory;
