// @flow
import * as React from "react";

import { getBackend } from "../lib/backend/Backend2";
import { classNames } from "../lib/Util";
import { translate } from "./IntlProviderWrapper";
import ErrorComponent from "./ErrorComponent";
import ConfirmationModal from "./ConfirmationModal";
import UserLoginsInputModal from "./UserLoginsInputModal";

import type { IUserLogin } from "../lib/backend/users.generated.types";

// $FlowFixMe
import "../styles/user_logins.scss";

export const LOGIN_PROVIDERS = [
    // {
    //     id: "github",
    //     title: "GitHub",
    //     edit: true,
    //     delete: true
    // },
    {
        id: "$sys:email",
        title: translate("common.email", "Email"),
        edit: true,
        delete: true
    },
    {
        id: "$sys:external_id_erp",
        title: translate("common.personel_id", "Personel ID"),
        edit: true,
        delete: true
    },
    {
        id: "azure-ad",
        title: "Azure AD",
        edit: true,
        delete: true
    },
    {
        id: "github",
        title: "GitHub",
        edit: true,
        delete: true
    },
    {
        id: "adfs",
        title: "ADFS",
        edit: true,
        delete: true
    }
    // {
    //     id: "$sys:pin",
    //     title: "PIN",
    //     edit: true,
    //     delete: true
    // }
];

export type LoginProvider = {
    id: string,
    title: string,
    edit: boolean,
    delete: boolean
};

type Props = {
    user_uuid: string
};

type State = {
    error: string,
    logins: IUserLogin[],
    is_deleting: boolean,
    show_delete_modal: boolean,
    show_input_modal: boolean,
    selected_provider: string | null
};

const filterLogins = (logins: IUserLogin[]): IUserLogin[] => {
    const all_providers: Set<string> = new Set(LOGIN_PROVIDERS.map(provider => provider.id));
    return logins.filter(login => all_providers.has(login.provider));
};

const countAvailableProviders = (logins: IUserLogin[]): number => {
    const existing_providers: Set<string> = new Set(logins.map(login => login.provider));
    let count = 0;
    for (const provider of LOGIN_PROVIDERS) {
        if (!existing_providers.has(provider.id)) {
            count++;
        }
    }

    return count;
};

class UserLogins extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const state: State = {
            error: "",
            logins: [],
            is_deleting: false,
            selected_provider: null,
            show_delete_modal: false,
            show_input_modal: false
        };
        this.state = state;
    }

    async componentDidUpdate(prev_props: Props) {
        if (this.props.user_uuid !== prev_props.user_uuid && this.props.user_uuid !== "") {
            await this.loadLogins();
        }
    }

    loadLogins = async () => {
        try {
            const res = await getBackend().users.getUserLogins({uuid: this.props.user_uuid});
            this.setState({ logins: res.logins });
        } catch (error) {
            this.setState({ error: error.message });
        }
    };

    handleAdd = () => {
        this.setState({ show_input_modal: true });
    };

    handleEdit = (provider: string) => {
        this.setState({
            show_input_modal: true,
            selected_provider: provider
        });
    };

    handleDelete = (provider: string) => {
        this.setState({
            show_delete_modal: true,
            selected_provider: provider
        });
    };

    handleInputModalAccept = async () => {
        this.setState({
            show_input_modal: false,
            selected_provider: null
        });
        await this.loadLogins();
    };

    handleInputModalCancel = () => {
        this.setState({
            show_input_modal: false,
            selected_provider: null
        });
    };

    handleDeleteModalAccept = async () => {
        this.setState({ show_delete_modal: false });
        const { selected_provider } = this.state;
        if (selected_provider === null) {
            return;
        }

        this.setState({ is_deleting: true });
        try {
            await getBackend().users.deleteUserLogin({
                uuid: this.props.user_uuid,
                provider: selected_provider
            });
            await this.loadLogins();
        } catch (error) {
            this.setState({ error: error.message });
        }

        this.setState({
            is_deleting: false,
            selected_provider: null
        });
    };

    handleDeleteModalCancel = () => {
        this.setState({
            show_delete_modal: false,
            selected_provider: null
        });
    };

    
    render() {
        const { logins, is_deleting, selected_provider } = this.state;
        const providers_map: Map<string, LoginProvider> = new Map(
            LOGIN_PROVIDERS.map(provider => [provider.id, provider])
        );
        const selected_login_provider = selected_provider !== null ? providers_map.get(selected_provider) : null;
        return (
            <div className="user-logins">
                <table className="table user-logins-table">
                    <thead>
                        <tr>
                            <th>{translate("common.provider", "Provider")}</th>
                            <th>{translate("common.key", "Key")}</th>
                            <th className="user-logins-actions">
                                <button
                                    className="btn btn-primary"
                                    title={translate("common.add", "Add")}
                                    disabled={countAvailableProviders(logins) === 0}
                                    onClick={this.handleAdd}
                                >
                                    <i className="fas fa-plus" />
                                </button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {filterLogins(this.state.logins).map(login => {
                            const is_scheduled_for_delete = is_deleting && login.provider === selected_provider;
                            const provider = providers_map.get(login.provider);
                            if (provider === undefined) {
                                return null;
                            }
                            return (
                                <tr key={provider.id} className={classNames({ "is-deleting": is_scheduled_for_delete })}>
                                    <td>{provider.title}</td>
                                    <td>{login.provider_key}</td>
                                    <td className="user-logins-actions">
                                        <button
                                            className="btn btn-outline-secondary"
                                            title={translate("common.edit", "Edit")}
                                            disabled={is_scheduled_for_delete || !provider.edit}
                                            onClick={() => {
                                                this.handleEdit(login.provider);
                                            }}
                                        >
                                            <i className="fas fa-edit" />
                                        </button>
                                        <button
                                            className="btn btn-outline-secondary"
                                            title={translate("common.delete", "Delete")}
                                            onClick={() => {
                                                this.handleDelete(login.provider);
                                            }}
                                            disabled={is_scheduled_for_delete || !provider.delete}
                                        >
                                            <i className="fas fa-trash" />
                                        </button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                {this.state.error && <ErrorComponent type="error" msg={this.state.error} />}
                <UserLoginsInputModal
                    show={this.state.show_input_modal}
                    provider={this.state.selected_provider}
                    logins={this.state.logins}
                    onAccept={this.handleInputModalAccept}
                    onCancel={this.handleInputModalCancel}
                    user_uuid={this.props.user_uuid}
                />
                <ConfirmationModal
                    show={this.state.show_delete_modal}
                    title={
                        translate("common.delete", "Delete") +
                        (selected_login_provider != null ? ` - ${selected_login_provider.title}` : "")
                    }
                    onAccept={this.handleDeleteModalAccept}
                    onCancel={this.handleDeleteModalCancel}
                >
                    {translate(
                        "common.are_you_sure_delete_login_provider",
                        "Are you sure you want to delete settings for selected provider?"
                    )}
                </ConfirmationModal>
            </div>
        );
    }
}

export default UserLogins;
