// @flow

// React
import React, { Component } from "react";

// Models
import * as t from "../../lib/backend/manufacturing2.generated.types";
import ChevronListItem from "../react/ChevronListItem";
import { translate } from "../IntlProviderWrapper";
type Props = {
    materials: t.IMaterialModel[],
    parallel_materials: t.IParallelMaterialModel[],
    selected: t.IMaterialModel | null,
    parallel_selected: t.IParallelMaterialModel | null,
    filter: string,
    handleMaterialClick: any
}

/**
 * Materials list component.
 */
class MaterialsList extends Component<Props> {
    /**
     * Handle `click` events for material item.
     */
    handleMaterialClick(material: t.IMaterialModel) {
        // Ignore clicks for currently selected material.
        if (material === this.props.selected) {
            return;
        }

        // Handle parent's click events.
        this.props.handleMaterialClick(material);
    }

    handleParallelMaterialClick(material: t.IParallelMaterialModel) {
        // Ignore clicks for currently selected material.
        if (material === this.props.parallel_selected) {
            return;
        }

        // Handle parent's click events.
        this.props.handleMaterialClick(material);
    }

    checkFilterForMaterial(material: t.IMaterialModel, filter: string) {

        filter = filter.toUpperCase();

        if (filter.length == 0) { return true; }

        if (material.uuid.toUpperCase().includes(filter)) { return true; }
        if (material.title.toUpperCase().includes(filter)) { return true; }
        if (material.external_id.toUpperCase().includes(filter)) { return true; }

        return false;
    }

    checkFilterForParallelMaterial(material: t.IParallelMaterialModel, filter: string) {

        filter = filter.toUpperCase();

        if (filter.length == 0) { return true; }

        if (material.uuid.toUpperCase().includes(filter)) { return true; }
        if (material.title.toUpperCase().includes(filter)) { return true; }
        if (material.external_id.toUpperCase().includes(filter)) { return true; }

        if (material && material.child_materials && material.child_materials.length > 0) {
            for (const child_material of material.child_materials) {
                // Do we want to include uuid of child_material or not? If so uncomment next line
                // if (child_material.uuid.toUpperCase().includes(filter)) { return true; }
                if (child_material.title.toUpperCase().includes(filter)) { return true; }
                if (child_material.external_id.toUpperCase().includes(filter)) { return true; }
            }
        }

        return false;
    }

    /**
     * Render component.
     */
    render() {

        let materials_list = null;
        let parallel_materials_list = null;

        if (this.props.materials && this.props.materials.length > 0) {
            const materials = this.props.materials
            materials_list = materials
                .map((material, i) => {
                    let is_active = this.props.selected === material;
                    if (!this.checkFilterForMaterial(material, this.props.filter)) {
                        return null;
                    }
                    return <ChevronListItem
                        text={material.title === "" ? "[" + material.external_id  + "] " + translate("common.no_title") : material.title}
                        onClick={() => this.handleMaterialClick(material)}
                        is_active={is_active}
                    />
                });
        } else if (this.props.parallel_materials && this.props.parallel_materials.length > 0) {
            const parallel_materials = this.props.parallel_materials;
            parallel_materials_list = parallel_materials
                .map((material, i) => {
                    let is_active = this.props.parallel_selected === material;

                    if (!this.checkFilterForParallelMaterial(material, this.props.filter)) {
                        return null;
                    }

                    return <ChevronListItem
                        text={material.title}
                        onClick={() => this.handleParallelMaterialClick(material)}
                        is_active={is_active}
                    />
                });
        }

        return (
            <ul className="nav nav-tabs">
                <div className="nav-tabs-parent" ref="nav">
                    {this.props.parallel_materials.length == 0 ? materials_list : parallel_materials_list}
                </div>
            </ul>
        )
    }
}

export default MaterialsList;
