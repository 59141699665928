// @flow
import React from "react";

import type { PropertiesState } from "../reducers/properties";

type Props = {
    properties: PropertiesState,
    line_offset: number
};
type State = {};

export default class DisabledLines extends React.Component<Props, State> {

    render() {
        const properties = this.props.properties;

        return <g id="disabled-line" style={{pointerEvents: "none"}}>
            <rect
                x={properties.bars_start}
                y={this.props.line_offset}
                width={properties.width}
                height={properties.bar_height}
                fill="lightgrey"
                opacity={0.5}
                style={{pointerEvents: "none"}}
            >
            </rect>
        </g>
    }
}