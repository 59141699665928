// @flow
import * as React from "react";
import Moment from "react-moment";
import { connect } from "react-redux";
import ReactRouterPropTypes from "react-router-prop-types";
import { LINE_GROUP_TAGS_ACCESS } from "../../lib/ManufacturingTags.generated";
import * as t from "../../lib/backend/manufacturing2.generated.types";
import * as t2 from "../../lib/SimulationReportModels";
import * as BusinessLogic from "../../lib/BusinessLogic";
import { getGanttDataSimpleAsync } from "../../lib/GanttData";
import {
    niceDateTime, TIME_RANGES, dateFromWeekAndShift
} from "../../lib/Util";
import { LINE_GROUP_TAGS } from "../../lib/ManufacturingTags.generated";
import {
    prepareGanttChart2,
    prepareGanttChartInsightsCurrent
} from "../GanttChartUtils";

import GanttChartApp from "./PlanningTable2/GanttChartApp";

import {
    getShowPastProductions,
    GanttChartSource,
    setShowPastProductions
} from "./PlanningTable2/reducers/properties";
import { getSelectedInsightTypes } from "./PlanningTable2/reducers/ganttChartStandalone"
import { SET_TRANSLATIONS_MATERIALS } from "../redux/reducers/translationsMaterialsReducer";
import Loader from "../Loader";
import { translate } from "../IntlProviderWrapper";

import type { GanttChartSourceTypes } from "./PlanningTable2/reducers/properties";
import type { AvailableInsightTypes } from "./PlanningTable2/reducers/ganttChartStandalone";
import type { SetTranslationsMaterials } from "../redux/reducers/translationsMaterialsReducer";

type Props = {
    history: ReactRouterPropTypes.history,
    data_def?: any,
    source_type: GanttChartSourceTypes,
    reduxDispatch: (args: SetTranslationsMaterials) => void,
    onOrderChange?: (order: t.IOrderModelBase) => void
};

type State = {
    show_line_titles: boolean,
    show_only_tool_changes: boolean,
    simulation: t2.IReportModelEx | null,
    insights: Array<t.IEventDataEx> | null,
    gantt_past_weeks: number,
    gantt_number_of_days: number,
    microplan_gantt_number_of_days: number,
    microplan_gantt_show_history: boolean,
    produced: Array<t.IProducedModel>,
    loading: boolean,
    show_past_productions: boolean,
    insight_types: AvailableInsightTypes[],
    disable_zoom: boolean
};

const GANTT_PAST_WEEKS = 1;

class GantchartStandalone extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        const state: State = {
            show_line_titles: false,
            show_only_tool_changes: false,
            simulation: null,
            insights: [],
            gantt_past_weeks: 1,
            gantt_number_of_days: 28,
            microplan_gantt_number_of_days: 28,
            microplan_gantt_show_history: true,
            produced: [],
            loading: true,
            show_past_productions: false,
            insight_types: [],
            disable_zoom: false
        };

        this.state = state;
    }

    loadGantt = async () => {
        const insight_types = getSelectedInsightTypes();

        // if only data_def is present
        const data_def = this.props.data_def;

        let line_group_uuid = null;
        if (data_def && data_def.filters && data_def.filters.line_groups && data_def.filters.line_groups.length > 0) {
            line_group_uuid = data_def.filters.line_groups[0];
        }

        // don't load if show_past_productions is not properly set
        let show_past_productions = false;
        if (line_group_uuid) {
            show_past_productions = getShowPastProductions(this.getSourceType(), line_group_uuid);
            setShowPastProductions(this.getSourceType(), show_past_productions);
        }

        this.setState({ loading: true });

        if (data_def) {
            try {

                // retrieve simulation data
                const lines = data_def && data_def.filters && data_def.filters.lines;
                if (!line_group_uuid) {
                    throw new Error("Line group not found");
                }
                const line_group_tags = BusinessLogic.getLineGroupTags(line_group_uuid);
                let days = LINE_GROUP_TAGS_ACCESS.gantt_number_of_days(line_group_tags);
                let history_days;
                if (show_past_productions && this.props.source_type === GanttChartSource.gantt) {
                    history_days = (LINE_GROUP_TAGS_ACCESS.gantt_past_weeks(line_group_tags) * 7);
                }
                if (this.props.source_type === GanttChartSource.microplan) {
                    if (LINE_GROUP_TAGS_ACCESS.microplan_gantt_show_history(line_group_tags)) {
                        history_days = (LINE_GROUP_TAGS_ACCESS.gantt_past_weeks(line_group_tags) * 7);
                    }
                    days = LINE_GROUP_TAGS_ACCESS.microplan_gantt_number_of_days(line_group_tags);
                }
                const raw_simulation = await getGanttDataSimpleAsync(
                    null,
                    lines,
                    days * 3,
                    history_days
                );
                if (raw_simulation.translations) {
                    this.props.reduxDispatch({
                        type: SET_TRANSLATIONS_MATERIALS,
                        data: raw_simulation.translations.materials
                    });
                }
                const show_only_tool_changes = data_def && data_def.show_only_tool_changes === "true";
                // retrieve scope parameters with default if not present
                let gantt_past_weeks = GANTT_PAST_WEEKS;
                let gantt_number_of_days = this.state.gantt_number_of_days;
                let microplan_gantt_number_of_days = this.state.microplan_gantt_number_of_days;

                if (data_def && data_def.filters && data_def.filters.line_groups && data_def.filters.line_groups.length > 0) {
                    line_group_uuid = data_def.filters.line_groups[0];
                    if (this.props.source_type === GanttChartSource.gantt) {
                        gantt_past_weeks = BusinessLogic.getLineGroupTagInt(
                            line_group_uuid, LINE_GROUP_TAGS.gantt_past_weeks, gantt_past_weeks);
                        gantt_number_of_days = BusinessLogic.getLineGroupTagInt(
                            line_group_uuid, LINE_GROUP_TAGS.gantt_number_of_days, gantt_number_of_days);
                    } else if (this.props.source_type === GanttChartSource.microplan) {
                        gantt_past_weeks = BusinessLogic.getLineGroupTagInt(
                            line_group_uuid, LINE_GROUP_TAGS.gantt_past_weeks, gantt_past_weeks);
                        gantt_number_of_days = BusinessLogic.getLineGroupTagInt(
                            line_group_uuid, LINE_GROUP_TAGS.microplan_gantt_number_of_days, microplan_gantt_number_of_days);
                    }
                }
                // if show_line_titles is set to true in data_definition
                // line titles will be displayed even in the case gantt has only one line
                const show_line_titles = (data_def && data_def.show_line_titles &&
                    data_def.show_line_titles === "true") ? true : false;
                // figure out if we need to show history
                let show_history = show_past_productions;
                if (gantt_past_weeks === 0) {
                    show_history = false;
                }

                // no past weeks if no history
                gantt_past_weeks = show_history ? gantt_past_weeks : 0;
                const simulation = await prepareGanttChart2({
                    simulation: raw_simulation,
                    line_tags: []
                });

                let disable_zoom = false;
                if (data_def && data_def.disable_zoom !== undefined) {
                    disable_zoom = data_def.disable_zoom === "true";
                }

                // remember
                this.setState({
                    show_line_titles,
                    show_only_tool_changes,
                    simulation: simulation.report,
                    gantt_past_weeks,
                    gantt_number_of_days,
                    show_past_productions,
                    insight_types,
                    disable_zoom
                }, async () => {
                    this.loadInsights();
                });
            } catch (e) {
                console.log(e);
            }
        }

        this.setState({ loading: false });
    }

    async componentDidMount() {
        await this.loadGantt();
    }

    handleGantClick(line_uuid: string) {
        this.props.history.push(`/manufacturing/lines/microplan/${line_uuid}`);
    }

    renderReportParams(report: t2.IReportModelEx) {
        const d = new Date(report.created_at);
        return <p className="gantt-footnote">
            {niceDateTime(d)} - <Moment fromNow>{d}</Moment> - {report.uuid}
        </p>;
    }

    getSourceType(): GanttChartSourceTypes {
        if (this.props.source_type) {
            return this.props.source_type;
        }

        const data_def = this.props.data_def;
        const show_line_titles = (data_def && data_def.show_line_titles && data_def.show_line_titles === "true") ? true : false;
        let source_type = GanttChartSource.gantt;
        if (!show_line_titles) {
            source_type = GanttChartSource.microplan;
        }
        return source_type;
    }

    loadInsights = async () => {
        const raw_simulation = this.state.simulation;
        const insight_types = this.state.insight_types;
        const gantt_past_weeks = this.state.gantt_past_weeks;
        const gantt_number_of_days = this.state.gantt_number_of_days;
        const data_def = this.props.data_def;
        const lines = data_def && data_def.filters && data_def.filters.lines;
        if (raw_simulation && lines) {
            const week = raw_simulation.result.next_shift_time.week;
            const year = raw_simulation.result.next_shift_time.year;

            // initial time we start plotting the gantt chart -- always from start of the week
            const from = dateFromWeekAndShift(week, year, 0).getTime() - gantt_past_weeks * TIME_RANGES.WEEK;
            const to = from + (gantt_number_of_days + (gantt_past_weeks + 1) * 7) * TIME_RANGES.DAY;
            const insights = await prepareGanttChartInsightsCurrent(
                insight_types || [], lines, from, to
            );
            this.setState({ insights, insight_types });
        }
    }

    onInsightTypesChange = async () => {
        const insight_types = getSelectedInsightTypes();
        this.setState({ insight_types }, () => {
            this.loadInsights();
        });
    }

    onShowPastProductionsChange = async () => {
        await this.loadGantt();
    }

    render() {
        // Wait for gantt data
        const sim = this.state.simulation;
        const insights = this.state.insights || [];

        // check how many lines are we visualizing
        const data_def = this.props.data_def;
        let line_group_uuid = null;

        // Draw entire gantt
        if (data_def && data_def.filters && data_def.filters.line_groups && data_def.filters.line_groups.length > 0) {
            line_group_uuid = data_def.filters.line_groups[0];
        }

        if (!sim) {
            return <div className="white_box charts" id={sim && sim.uuid}>
                <Loader small />
            </div>
        }

        if (!line_group_uuid) {
            return <div className="white_box charts" id={sim && sim.uuid}>
                {translate("common.error", "An error occured while fetching data")}
            </div>
        }

        return <div className="white_box charts" id={sim && sim.uuid}>
            <GanttChartApp
                report={sim}
                current_shift={sim.result.next_shift_time.shift_number}
                week={sim.result.next_shift_time.week}
                year={sim.result.next_shift_time.year}
                simulation_uuid={sim && sim.uuid}
                loading={this.state.loading}
                line_group_uuid={line_group_uuid}
                insights={insights}
                insight_types={this.state.insight_types}
                past_weeks={this.state.gantt_past_weeks}
                days={this.state.gantt_number_of_days}
                show_past_productions={this.state.show_past_productions}
                show_only_tool_changes={this.state.show_only_tool_changes}
                handleClick={(line_uuid) => this.handleGantClick(line_uuid)}
                source_type={this.props.source_type}
                gantt_chart_standalone_callbacks={{
                    onInsightTypesChange: this.onInsightTypesChange,
                    onShowPastProductionsChange: this.onShowPastProductionsChange
                }}
                onOrderChange={this.props.onOrderChange}
                disable_zoom={this.state.disable_zoom}
            />
            {sim && this.renderReportParams(sim)}
        </div>
    }
}

export default connect(
    null,
    (dispatch) => ({reduxDispatch: dispatch})
)(GantchartStandalone);
