// @flow
import * as React from "react";
import ReactRouterPropTypes from "react-router-prop-types";
import { getBackend } from "../../lib/backend/Backend2";
import * as ta from "../../lib/backend/alerts.generated.types";
import * as tr from "../../lib/backend/reports.generated.types";
import * as tm from "../../lib/backend/manufacturing2.generated.types";
import * as mc from "../../lib/ManufacturingConsts.generated";
import Loader from "../Loader";
import { createOverlayDashboard } from "./DashboardOverlay";
import { CreateClassicDashboard } from "./DashboardClassic";
import { isAuth } from "../../lib/Auth";

import type { GanttChartSourceTypes } from "../manufacturing/PlanningTable2/reducers/properties";

type Props = {
    history: ReactRouterPropTypes.history,
    dash_definition: any,
    dash_data: any,
    range_change: any | null,
    show_filter_bar: boolean,
    source_type?: GanttChartSourceTypes
};

export type ManufacturingData = {
    insights: tm.IEventDataEx[],
    report: tr.IReportModel
}

type State = {
    events: Array<ta.IEventData>,
    manufacturing: ManufacturingData | null
};

class Dashboard extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            events: [],
            manufacturing: null
        };
    }

    componentDidMount() {
        this.loadEvents();
        this.loadManufacturing();
    }

    async loadEvents() {
        if (!isAuth()) {
            // Current user is not logged in (e.g. public dashboard) - don't try to load events
            return;
        }
        try {
            if (this.props.dash_data && this.props.dash_data.event_uuids && this.props.dash_data.event_uuids.length > 0) {
                const res = await getBackend().alerts.searchEvents({
                    uuids: this.props.dash_data.event_uuids.join(",")
                });
                const events = res.data;
                this.setState({ events });
            }
        } catch (err) {
            console.log(err);
        }
    }

    async loadManufacturing() {
        // check if we have manufacturing reports and insights to retrieve
        if (this.props.dash_data && this.props.dash_data.external && this.props.dash_data.external.manufacturing) {
            const line_uuids: string[] = this.props.dash_data.external.manufacturing.line_uuids;
            // load insights
            const now = new Date().getTime();
            const insight_types: string[] = this.props.dash_data.external.manufacturing.insight_types || [];
            const { insights } = await getBackend().manufacturing.getInsights({
                types: insight_types,
                lines: line_uuids,
                from: now,
                to: now,
                range_search_type: mc.INSIGHT_RANGE_SEARCH_TYPE.includes_range
            });
            // load latest report
            const { report } = await getBackend().reports.getLatestReport({ line_uuids: line_uuids.join(",") });
            // update state
            this.setState({ manufacturing: { insights, report } });
        }
    }

    componentDidUpdate(prevProps: Props) {
        // check if properties changed so that events need to be reloaded
        let do_load = false;
        if (!prevProps.dash_data) {
            do_load = true;
        } else if (!this.props.dash_data || !this.props.dash_data.event_uuids) {
            do_load = false;
        } else if (JSON.stringify(this.props.dash_data.event_uuids) !== JSON.stringify(prevProps.dash_data.event_uuids)) {
            do_load = true;
        }
        if (do_load) {
            this.loadEvents();
            this.loadManufacturing();
        }
    }

    render() {
        const data = {
            ...this.props.dash_data,
            ...this.state,
            source_type: this.props.source_type
        };

        try {
            if (!this.props.dash_definition || !this.props.dash_data) {
                return (<Loader />);
            }
            if (this.props.dash_definition.type === "overlay") {
                return createOverlayDashboard(this.props.dash_definition, data);
            } else {
                return <CreateClassicDashboard
                    root={this.props.dash_definition}
                    data={data}
                    range_change={this.props.range_change}
                    show_filter_bar={this.props.show_filter_bar}
                    history={this.props.history}
                />
            }
        } catch (err) {
            console.log(err);
            return "Error rendering";
        }
    }
}

export default Dashboard;
