// @flow
import React from "react";
import * as tt from "../../lib/backend/ticketing.generated.types";
import WorkerInbox from "./WorkerInbox"
import { getBackend as getBackend2 } from "../../lib/backend/Backend2";
import Loader from "../Loader";

type Props = {};
type State = {
    tickets: tt.ITicketShortInfo[],
    loading: boolean
};

const getTickets = async (): Promise<tt.ITicketShortInfo[]> => {
    const res = await getBackend2().ticketing.getUserInbox({});
    return res.inbox_items;
}

export default class WorkerInboxContainer extends React.Component<Props, State> {
    state = {
        tickets: [],
        loading: true
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        const tickets = await getTickets();
        this.setState({ tickets, loading: false });
    }

    render() {
        if (this.state.loading) {
            return <Loader small />
        }
        return <WorkerInbox tickets={this.state.tickets} />;
    }
}


