// @flow
import * as React from "react";
import { connect } from "react-redux";
import { UPDATE_PLANNING_TABLE_HEIGHT, UPDATE_PLANNING_STOCK_REQUIREMENTS_HEIGHTS } from "./reducers/properties";
import { ScrollHandler } from "./EventListeners/ScrollHandler";

import type {
    UpdatePlanningTableHeightAction,
    UpdateStockRequirementsHeightAction,
    UpdatePlanningStockHeightsAction
} from "./reducers/properties";
import type {
    ReduxState
} from "./reducers/reducers"

type DividerControlProps = {
    onMouseDown: (e: Event) => void,
    pane: string
};
type DividerControlState = {
    is_dragging: boolean,
    margin_top: number
}

class DividerControl extends React.Component<DividerControlProps, DividerControlState> {
    state = {
        is_dragging: false,
        margin_top: 0
    }

    myRef = null;

    constructor(props: DividerControlProps) {
        super(props);
        this.myRef = React.createRef();
    }

    componentDidMount() {
        this.calculateMargin();
    }

    calculateMargin = () => {
        const current_el = this.myRef && this.myRef.current;
        if (current_el) {
            const rect = current_el.getBoundingClientRect();
            this.setState({
                margin_top: ((rect.height / 2))
            });
        }
    }

    render() {
        return <div
            ref={this.myRef}
            className="divider-control"
            onMouseDown={this.props.onMouseDown}
            style={{
                marginTop: -this.state.margin_top + "px",
                width: "100%",
                height: this.state.margin_top + "px"
            }}
        >
            <div className="circle1" style={{zIndex: 997}}>
                <div className="caret-container">
                    <i className="fas fa-caret-up" ></i>
                    <i className="fas fa-caret-down"></i>
                </div>
            </div>
        </div>
    }
}

type GanttPanesProps = {
    gantt0: React.Node,
    gantt1: React.Node,
    gantt2: React.Node,
    count_non_empty_lines: number | null,
    num_unscheduled_lines: number,
    num_stock_requirements: number,
    show_stock_requirements: boolean,
    max_line_label_length: number,
    bars_start: number,
    order_uuid: string,
    reduxDispatch: (args: UpdatePlanningTableHeightAction | UpdateStockRequirementsHeightAction | UpdatePlanningStockHeightsAction) => void
}

const DRAGGING_UPPER_CIRCLE = "upper";
const DRAGGING_LOWER_CIRCLE = "lower";

type DRAGGING_CIRCLE = typeof DRAGGING_LOWER_CIRCLE | typeof DRAGGING_UPPER_CIRCLE;

type GanttPanesState = {
    clientY0: number,
    clientY: number,
    pane0_height: number,
    pane1_height: number,
    pane2_height: number,
    pane0_1_ratio: number,
    pane1_2_ratio: number,
    dragging_circle: DRAGGING_CIRCLE | null
}

export class GanttPanes extends React.Component<GanttPanesProps, GanttPanesState> {
    ref: HTMLDivElement | null = null;
    state = {
        clientY0: 0,
        clientY: 0,
        pane0_height: 0,
        pane1_height: 0,
        pane2_height: 0,
        pane0_1_ratio: 0,
        pane1_2_ratio: 0,
        dragging_circle: null
    }

    mouse_up_listener = null;
    resize_observer = null;

    componentDidMount() {
        this.mouse_up_listener = () => this.onMouseUp();
        window.addEventListener("mouseup", this.mouse_up_listener);

        if (this.ref !== null) {
            this.ref.addEventListener("gantt-change", this.onGanttChange);
        }

        // $FlowFixMe
        this.resize_observer = new ResizeObserver(() => {
            this.updateGanttChartsHeights(false);
        });
        this.resize_observer.observe(document.body);
        const heights = localStorage.getItem("gantt_panes_heights");
        if (heights) {
            const new_state = JSON.parse(heights);
            this.setState(new_state);
        }
    }

    componentDidUpdate(prev_props: GanttPanesProps) {
        if (prev_props.show_stock_requirements !== this.props.show_stock_requirements) {
            this.setState({
                dragging_circle: null
            });
            this.updateGanttChartsHeights(false);
        }

        if (prev_props.order_uuid !== this.props.order_uuid) {
            ScrollHandler.scrollToOrder(this.props.order_uuid);
        }

    }

    componentWillUnmount() {
        window.removeEventListener("mouseup", this.mouse_up_listener);
        if (this.resize_observer) {
            this.resize_observer.disconnect();
        }

        if (this.ref !== null) {
            this.ref.removeEventListener("gantt-change", this.onGanttChange);
        }
    }

    onGanttChange = (event: Event) => {
        const pane1 = document.querySelector("#pane1");
        if (pane1) {
            this.translateLineLabelsGroups(pane1.scrollLeft);
        }
    }

    update_height_timeout = null;

    onMouseMoveUpper = (e: Event) => {
        let movementY = 0;
        // $FlowFixMe
        if (e.movementY) {
            movementY = e.movementY;
        }

        if (movementY) {
            const new_state = {
                pane0_height: this.state.pane0_height + movementY,
                pane1_height: this.state.pane1_height - movementY,
            };
            this.setState(new_state);
            localStorage.setItem("gantt_panes_heights", JSON.stringify(new_state));
            const is_stock_requirement = true;
            this.updateGanttChartsHeights(is_stock_requirement);
        }
        e.preventDefault();
        e.stopPropagation();
    }

    onMouseMoveLower = (e: Event) => {
        let movementY = 0;
        // $FlowFixMe
        if (e.movementY) {
            movementY = e.movementY;
        }

        if (movementY) {
            const new_state = {
                pane1_height: this.state.pane1_height + movementY,
                pane2_height: this.state.pane2_height - movementY
            }
            this.setState(new_state);
            localStorage.setItem("gantt_panes_heights", JSON.stringify(new_state));

            this.updateGanttChartsHeights(false);
        }
        e.preventDefault();
        e.stopPropagation();
    }

    updateGanttChartsHeights = (is_stock_requirement: boolean) => {
        const count_non_empty_lines = this.props.count_non_empty_lines;
        const num_unscheduled_lines = this.props.num_unscheduled_lines;
        if (count_non_empty_lines) {
            if (this.update_height_timeout) {
                clearTimeout(this.update_height_timeout);
            }
            this.update_height_timeout = setTimeout(
                () => {
                    if (!is_stock_requirement) {
                        this.props.reduxDispatch({
                            type: UPDATE_PLANNING_TABLE_HEIGHT,
                            data: {
                                count_non_empty_lines,
                                num_unscheduled_lines
                            }
                        });
                    } else {
                        this.props.reduxDispatch({
                            type: UPDATE_PLANNING_STOCK_REQUIREMENTS_HEIGHTS,
                            data: {
                                count_non_empty_lines,
                                num_stock_requirements: this.props.num_stock_requirements
                            }
                        });
                    }
                    this.update_height_timeout = null;
                },
                150
            );
        }
    }

    onMouseDown = (dragging_circle: DRAGGING_CIRCLE) => (e: Event) => {
        this.setState({ dragging_circle });
    }

    onMouseUp = () => {
        this.setState({ dragging_circle: null });
    }

    getViewHeight = () => {
        if (this.props.show_stock_requirements) {
            return "33vh";
        }
        return "50vh";
    }

    getHeightCorrection = () => {
        if (this.props.show_stock_requirements) {
            return "55px";
        }
        return "85px";
    }

    getPane0Height = () => {
        return `calc(calc(${this.getViewHeight()} - ${this.getHeightCorrection()}) + ${this.state.pane0_height}px)`;
    }

    getPane1Height = () => {
        return `calc(calc(${this.getViewHeight()} - ${this.getHeightCorrection()} + ${this.state.pane1_height}px)`;
    }

    getPane2Height = () => {
        return `calc(calc(${this.getViewHeight()} - ${this.getHeightCorrection()} + ${this.state.pane2_height}px)`;
    }

    last_scroll_x = null;
    last_scroll_y = null;

    getElementScrollLeft = (element: any): number => {
        const scroll_x = element.scrollLeft !== undefined
            ? element.scrollLeft
            : element.scrollX;
        return Math.max(0, scroll_x);
    }

    translateLineLabelsGroups = (x: number) => {
        const line_labels_group = document.querySelector("#line-labels-group");
        const style_filter = x + this.props.max_line_label_length > this.props.bars_start
            ? "drop-shadow(2px 0px 2px rgba(0, 0, 0, 0.16)"
            : "";
        if (line_labels_group) {
            line_labels_group.setAttribute("transform", `translate(${x}, 0)`);
            line_labels_group.style.filter = style_filter;
        }

        const line_labels_group_unscheduled = document.querySelector("#line-labels-group-unscheduled");
        if (line_labels_group_unscheduled) {
            line_labels_group_unscheduled.setAttribute("transform", `translate(${x}, 0)`);
            line_labels_group_unscheduled.style.filter = style_filter;
        }
    };

    onScroll = (event: any) => {
        event.stopPropagation();
        event.preventDefault();

        if (!event) return;

        const header_shifts_group = document.querySelector("#pane1 #grid-groups-header-shifts");

        const correction = 0;
        const scroll_top = event.currentTarget.scrollTop !== undefined ? event.currentTarget.scrollTop : (event.currentTarget.scrollY - correction);

        let y = scroll_top;
        if (y < 0) y = 0;

        const translate_y = `translate(0, ${y})`;

        const pane1 = document.querySelector("#pane1");
        if (pane1 && header_shifts_group && this.last_scroll_y !== pane1.scrollTop) {
            this.last_scroll_y = pane1.scrollTop;
            header_shifts_group.setAttribute("transform", translate_y);
            header_shifts_group.style.filter = y > 0 ? "drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.16)" : "";
        }

        const x = this.getElementScrollLeft(event.currentTarget);

        if (this.last_scroll_x === x) {
            return;
        }

        this.last_scroll_x = x;

        const pane0 = document.querySelector("#pane0");
        if (pane0) {
            pane0.scrollLeft = x;
        }

        const pane2 = document.querySelector("#pane2 .scroll-container");
        if (pane2) {
            pane2.scrollLeft = x;
        }

        this.translateLineLabelsGroups(x);
    }

    onScrollUnscheduled = (event: any) => {
        const x = this.getElementScrollLeft(event.currentTarget);

        if (this.last_scroll_x === x) {
            return;
        }

        this.last_scroll_x = x;

        const pane0 = document.querySelector("#pane0");
        if (pane0) {
            pane0.scrollLeft = x;
        }

        const pane1 = document.querySelector("#pane1");
        if (pane1) {
            pane1.scrollLeft = x;
        }

        this.translateLineLabelsGroups(x);
    }

    onMouseMove = (e: Event) => {
        if (this.state.dragging_circle === DRAGGING_UPPER_CIRCLE) {
            this.onMouseMoveUpper(e);
        } else if (this.state.dragging_circle === DRAGGING_LOWER_CIRCLE) {
            this.onMouseMoveLower(e);
        }
    }

    onScrollStockRequirement = (event: any) => {
        const x = this.getElementScrollLeft(event.currentTarget);

        if (this.last_scroll_x === x) {
            return;
        }

        this.last_scroll_x = x;

        const pane1 = document.querySelector("#pane1");
        if (pane1) {
            pane1.scrollLeft = x;
        }

        const pane2 = document.querySelector("#pane2 .scroll-container");
        if (pane2) {
            pane2.scrollLeft = x;
        }

        this.translateLineLabelsGroups(x);
    }

    renderUpper = () => {
        const display = this.props.show_stock_requirements ? "unset" : "none";
        return <div style={{ display }}>
            <div
                id="pane0"
                className="pane1"
                style={{ height: this.getPane0Height(), overflowY: "auto" }}
                onScroll={this.onScrollStockRequirement}
            >
                {this.props.gantt0}
            </div>
            <DividerControl onMouseDown={this.onMouseDown(DRAGGING_UPPER_CIRCLE)} pane="pane0" />
        </div>

    }

    render() {
        return (
            <div className="App" onMouseMove={this.onMouseMove} ref={el => { this.ref = el; }}>
                {this.renderUpper()}
                <div>
                    <div id="pane1" className="pane1" style={{
                        height: this.getPane1Height(), overflowY: "auto", userSelect: "none"
                    }} onScroll={this.onScroll}>
                        {this.props.gantt1}
                    </div>
                    <DividerControl pane="pane1" onMouseDown={this.onMouseDown(DRAGGING_LOWER_CIRCLE)} />
                </div>
                <div id="pane2" className="pane2"
                    style={{ height: this.getPane2Height(), overflowY: "hidden", overflowX: "auto", backgroundColor: "white" }}
                >
                    <div className="scroll-container"
                        style={{ height: "100%", width: "100%", overflowY: "auto", overflowX: "auto" }}
                        onScroll={this.onScrollUnscheduled}
                    >
                        {this.props.gantt2}
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    (state: ReduxState) => {
        return {
            show_stock_requirements: state.gantt_chart_planning_table.show_stock_requirements,
            max_line_label_length: state.gantt_chart_properties.max_line_label_length,
            bars_start: state.gantt_chart_properties.bars_start,
            order_uuid: state.gantt_chart_filters.reschedule_order_uuid
        }
    },
    (dispatch) => ({reduxDispatch: dispatch})
)(GanttPanes);
