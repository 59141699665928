// @flow

import * as React from "react";
import Calendar, { type CalendarType, type TimeUnit } from "./Calendar";
import Popup, { type PopupPosition, type PopupPlacement, type PopupAlignment } from "./Popup";
import { toSloDateTimeString, toSloDateString, classNames } from "../lib/Util";
import { Modal } from "react-bootstrap";

type Props = {
    // autoFocus?: boolean,
    disabled: boolean,
    inputId?: string,
    maxValue?: Date,
    minValue?: Date,
    name?: string,
    onChange?: (date: Date) => void,
    openOnFocus: boolean,
    pickerType: CalendarType,
    display: string,
    popupAlignment: PopupAlignment,
    popupPlacement: PopupPlacement,
    popupPostion: PopupPosition,
    value?: Date,
    showMonthControls: boolean,
    showYearControls: boolean,
    showNowButton: boolean,
    showOKButton: boolean,
    timeUnits: TimeUnit[],
    use_modal?: boolean,
    title: string,
    placeholder?: string,
    className?: string,
    inputClassName?: string,
    closeOnSelect?: boolean,
    onClear?: (e?: Event) => any
}

type State = {
    isPopupOpen: boolean,
}

class DateTimePicker extends React.Component<Props, State> {
    static defaultProps: Props = {
        disabled: false,
        openOnFocus: false,
        pickerType: "date-time",
        display: "block",
        popupAlignment: "start",
        popupPlacement: "bottom",
        popupPostion: "absolute",
        showMonthControls: true,
        showYearControls: false,
        showNowButton: true,
        showOKButton: true,
        timeUnits: ["hour", "minute", "second"],
        use_modal: false,
        title: ""
        // value: null
    };
    input: HTMLInputElement | null;
    focusElement: HTMLElement | null;
    blurTimeoutId: TimeoutID;

    constructor(props: Props, state: State) {
        super(props);
        this.state = {
            isPopupOpen: false,
        };
    }

    componentWillUnmount() {
        clearTimeout(this.blurTimeoutId);
    }

    showPopup() {
        this.setState({
            isPopupOpen: true
        });
    }

    hidePopup = () => {
        this.setState({
            isPopupOpen: false
        });
        this.focusElement = null;
    }

    togglePopup() {
        this.setState((state) => ({
            isPopupOpen: !state.isPopupOpen
        }));
    }

    focusAndHide() {
        if (this.input) {
            this.input.focus();
        }

        this.hidePopup();
    }

    handleChange(date: Date) {
        if (this.props.onChange) {
            this.props.onChange(date)
        }
    }

    handleAccept(date: Date) {
        this.handleChange(date);
        this.focusAndHide();
    }

    onClear = (e: Event) => {
        if (this.props.onClear !== undefined) {
            this.props.onClear();
            this.hidePopup();
        }
    }

    handleFocus(event: SyntheticFocusEvent<HTMLElement>) {
        if (this.props.disabled) {
            return;
        }

        clearTimeout(this.blurTimeoutId);

        if (this.props.openOnFocus && !this.focusElement) {
            this.showPopup();
        }

        if (event.target instanceof HTMLElement) {
            this.focusElement = event.target;
        }
    }

    handleBlur(event: SyntheticFocusEvent<HTMLElement>) {
        if (this.props.disabled || this.props.use_modal) {
            return;
        }

        this.blurTimeoutId = setTimeout(() => {
            this.hidePopup();
        });
    }

    handlePointerDown(event: SyntheticMouseEvent<HTMLElement>) {
        if (this.props.disabled) {
            return;
        }

        if (this.focusElement) {
            setTimeout(() => {
                clearTimeout(this.blurTimeoutId);
            });
        }

        if (event.target === this.input) {
            this.togglePopup();
        }
    }

    handlePointerUp(event: SyntheticMouseEvent<HTMLElement>) {
        if (this.props.disabled) {
            return;
        }

        if (this.focusElement) {
            this.focusElement.focus();
        }
    }

    handleKeyDown(event: SyntheticKeyboardEvent<HTMLElement>) {
        if (this.props.disabled) {
            return;
        }

        const keyCode = event.keyCode;

        switch (keyCode) {
            case 27: // Esc
                if (this.state.isPopupOpen) {
                    event.stopPropagation();
                    event.nativeEvent.stopImmediatePropagation();
                    this.focusAndHide();
                }

                break;

            case 32: // Space
                if (event.target === this.input) {
                    this.togglePopup();
                }

                break;

            default:
                break;
        }
    }

    formatDateTimeString(value: Date) {
        if (this.props.pickerType === "date") {
            return toSloDateString(value);
        }
        return toSloDateTimeString(value);
    }

    renderCalendar() {
        return <Calendar
            type={this.props.pickerType}
            date={this.props.value}
            minDate={this.props.minValue}
            maxDate={this.props.maxValue}
            onAccept={this.props.showOKButton ? (date) => {this.handleAccept(date)} : null}
            onSetNow={this.props.showNowButton ? (date) => {this.handleAccept(date)} : null}
            onChange={!this.props.showOKButton ? (date) => {this.handleChange(date)} : null}
            showMonthControls={this.props.showMonthControls}
            showYearControls={this.props.showYearControls}
            timeUnits={this.props.timeUnits}
            showOnClearButton={this.props.onClear !== undefined}
            onClear={this.onClear}
            closeOnSelect={this.props.closeOnSelect}
        />
    }

    renderPopup() {
        if (this.props.use_modal) return null;

        if (this.input !== null) {
            return <Popup
                isOpen={this.state.isPopupOpen}
                // $FlowFixMe
                target={this.input}
                alignment={this.props.popupAlignment}
                placement={this.props.popupPlacement}
                position={this.props.popupPostion}
            >
                {this.renderCalendar()}
            </Popup>
        }

        return null;
    }

    renderModal = () => {
        if (!this.props.use_modal) return null;

        return <Modal show={this.state.isPopupOpen}>
            <Modal.Header>
                {this.props.title && <Modal.Title>{this.props.title}</Modal.Title>}
                <button type="button" className="close" onClick={this.hidePopup}>
                    <span aria-hidden="true">×</span>
                </button>
            </Modal.Header>
            <Modal.Body>
                {this.renderCalendar()}
            </Modal.Body>
        </Modal>;
    }

    render() {
        const className = "date-time-picker";
        const inputClassName = "form-control";

        return (
            <div
                className={classNames(className, `${className}-${this.props.pickerType}`, {
                    [this.props.className || ""]: this.props.className !== undefined
                })}
                onFocus={(e) => {this.handleFocus(e)}}
                onBlur={(e) => {this.handleBlur(e)}}
                onKeyDown={(e) => {this.handleKeyDown(e)}}
                onPointerDown={(e) => {this.handlePointerDown(e)}}
                onPointerUp={(e) => {this.handlePointerUp(e)}}
            >
                <input
                    id={this.props.inputId}
                    className={classNames(inputClassName, {
                        [this.props.inputClassName || ""]: this.props.inputClassName !== undefined
                    })}
                    type="text"
                    name={this.props.name}
                    value={this.props.value ? this.formatDateTimeString(this.props.value) : ""}
                    disabled={this.props.disabled}
                    title={this.props.title}
                    placeholder={this.props.placeholder}
                    readOnly={true}
                    ref={input => (this.input = input)}
                />
                {this.input && this.renderModal()}
                {this.input && this.renderPopup()}
            </div>
        );
    }
}

export default DateTimePicker;
