// @flow

import React from "react";
import { connect } from "react-redux";

import type { ReduxState } from "../reducers/reducers";
import type { StandaloneGanttState } from "../reducers/ganttChartStandalone";

export type GanttChartStandaloneCallbacksType = {
    onInsightTypesChange: () => Promise<void>,
    onShowPastProductionsChange: () => Promise<void>
}

type Props = GanttChartStandaloneCallbacksType & {
    insight_types: StandaloneGanttState,
    show_past_productions: boolean
};
type State = {};

class GanttChartStandaloneCallbacks extends React.Component<Props, State> {
    componentDidUpdate(prev_props) {
        if (prev_props.insight_types !== this.props.insight_types && this.props.onInsightTypesChange) {
            this.props.onInsightTypesChange();
        }
        // we trigger redraw if show past production toggle changes
        // SPECIAL CASE when null:
        // in case previous value was null, than we are just opening the gantt chart
        // and when we open we also read default or last value and store it in redux
        // in such case we do not trigger redraw.
        if (prev_props.show_past_productions !== null &&
            prev_props.show_past_productions !== this.props.show_past_productions &&
            this.props.onShowPastProductionsChange) {

            this.props.onShowPastProductionsChange();
        }
    }

    render() {
        return null;
    }
}

export default connect(
    (state: ReduxState) => {
        return {
            insight_types: state.standalone_gantt.insight_types,
            show_past_productions: state.gantt_chart_properties.show_past_productions
        }
    },
    (dispatch) => ({reduxDispatch: dispatch})
)(GanttChartStandaloneCallbacks);
