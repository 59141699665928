// @flow

import React, { Component } from "react";
import { getBackend as getBackend2 } from "../../lib/backend/Backend2";
import type { IOrderProducedModel } from "../../lib/backend/manufacturing2.generated.types";
import { niceNumber, niceDate } from "../../lib/Util";
import { LINE_TAGS, LINE_TYPES } from "../../lib/ManufacturingTags.generated";
import { translate } from "../IntlProviderWrapper";
import ReactTable from "../react/ReactTable";
import SubcontractingModal from "./SubcontractingModal";
import type { SubcontractingModalState } from "../redux/types";
import { connect } from "react-redux";

type Props = {
    show_subcontracting_modal: SubcontractingModalState,
    plant_uuid: string,
    line_group_uuid: string,
    reduxDispatch: Function
}

type IOrderProducedModelExt = IOrderProducedModel & {
    time_start: number,
    time_end: number,
    quantity_sent: ?number,
    quantity_received: ?number,
}

type State = {
    orders: $Shape<IOrderProducedModelExt>[],
    isLoading: boolean
}

const detailsCell = ({onModalOpen}) => (props) => {
    const { external_operation_id, quantity_total } = props.cell.row.values;
    return <div style={{whiteSpace: "nowrap"}}>
        <span>{props.cell.value}</span>
        <img
            style={{width: "20px", marginLeft: "10px"}}
            src="/img/eye_grey.svg"
            alt="edit"
            onClick={onModalOpen(external_operation_id, quantity_total)}
            title={translate("common.tooltip_show_details", "Show details")}
        />
    </div>
}

const getTableColumns = ({onModalOpen}) => {
    return [
        {
            "Header": "",
            "accessor": "material_external_id",
            "filterInputPlaceholder": translate("Manufacturing.Planning.ident", "Ident")
        },
        {
            "Header": "",
            "accessor": "material_title",
            "filterInputPlaceholder": translate("common.title", "Title")
        },
        {
            "Header": "",
            "accessor": "title",
            "filterInputPlaceholder": translate("Subcontracting.subcontractor", "Subcontractor")
        },
        {
            "Header": "",
            "accessor": "external_operation_id",
            "filterInputPlaceholder": translate("Subcontracting.purchase_order", "Purchase order")
        },
        {
            "Header": translate("common.first_transaction", "First transaction"),
            "accessor": "time_start",
            disableFilters: true
        },
        {
            "Header": translate("common.last_transaction", "Last transaction"),
            "accessor": "time_end",
            disableFilters: true
        },
        {
            "Header": translate("common.sent", "Sent"),
            "accessor": "quantity_sent",
            disableFilters: true
        },
        {
            "Header": translate("common.received", "Received"),
            "accessor": "quantity_received",
            disableFilters: true
        },
        {
            "Header": translate("common.quantity", "Quantity"),
            "accessor": "quantity_total",
            disableFilters: true,
            Cell: detailsCell({ onModalOpen })
        }
    ]
}

const getTableData = (order_obj) => {
    let {
        quantity_sent,
        quantity_received,
        quantity_total
    } = order_obj;

    return {
        "material_external_id": order_obj.material_external_id,
        "material_title": order_obj.material_title,
        "title": order_obj.title,
        "external_operation_id": order_obj.external_operation_id,
        "time_start": order_obj.time_start ? niceDate(new Date(order_obj.time_start)) : "/",
        "time_end": order_obj.time_end ? niceDate(new Date(order_obj.time_end)) : "/",
        "quantity_sent": niceNumber(quantity_sent || 0, 0),
        "quantity_received": niceNumber(quantity_received || 0, 0),
        "quantity_total": niceNumber(quantity_total, 0)
    }
}

class Subcontracting extends Component<Props, State> {

    state = {
        orders: [],
        isLoading: false
    };

    componentDidMount() {
        this.reloadOrders();
    }

    componentDidUpdate(prev_props: Props) {
        if (prev_props.plant_uuid !== this.props.plant_uuid ||
            prev_props.line_group_uuid !== this.props.line_group_uuid) {
            this.reloadOrders();
        }
    }

    async reloadOrders() {
        this.setState({isLoading: true})
        // find line with type == subcontracting
        const lines_all = await getBackend2().manufacturing.getLines({ plant_uuid: this.props.plant_uuid });
        for (const line of lines_all.lines) {
            if (("tags" in line) && ("type" in line.tags) && (line.tags[LINE_TAGS.type] === LINE_TYPES.subcontraction)) {
                // line_uuid is subcontracting line
                const line_uuid = line.uuid;
                // load orders for subcontracting line
                // this loads only open parents (whose delivery is not completed yet)
                const orders = await getBackend2().manufacturing.searchOrders({ line_uuid, order_types: ["full"], parent_order_uuid: "null", status: "open" });
                const external_operation_ids = orders.orders.map(el => el.external_operation_id);

                const orders_quantities = await getBackend2().manufacturing.getSubcontractingQuantities({external_operation_ids});

                const new_orders = [];
                for (const order of orders.orders) {
                    const quantity_received = orders_quantities.orders[order.external_operation_id].quantity_received || 0;
                    const quantity_sent = orders_quantities.orders[order.external_operation_id].quantity_sent || 0;

                    const {
                        time_start,
                        time_end
                    } = orders_quantities.orders[order.external_operation_id];

                    if (order.quantity_total < quantity_received) {
                        continue;
                    }

                    new_orders.push({
                        title: order.title,
                        material_title: order.material_title,
                        material_external_id: order.material_external_id,
                        external_operation_id: order.external_operation_id,
                        quantity_total: order.quantity_total,
                        quantity_sent: quantity_sent,
                        quantity_received: quantity_received,
                        time_start,
                        time_end
                    })
                }

                this.setState({ orders: new_orders.sort((a, b) => (a.time_end < b.time_end) ? 1 : -1)});
                break;
            }
        }

        this.setState({isLoading: false});
    }

    onModalClose = () => {
        this.props.reduxDispatch({
            type: "set_subcontracting_modal",
            data: {
                show: false,
                external_operation_id: null,
                quantity_total: null
            }
        });
    }

    onModalOpen = (external_operation_id: string, quantity_total: string) => () => {
        this.props.reduxDispatch({
            type: "set_subcontracting_modal",
            data: {
                show: true,
                external_operation_id,
                quantity_total
            }
        });
    }

    render() {
        let data = [];
        const columns = getTableColumns({onModalOpen: this.onModalOpen});

        if (this.state.orders && this.state.orders.length > 0) {
            data = this.state.orders.filter(o => o.quantity_produced !== o.quantity_total)
                                    .map(getTableData);
        }

        return (
            <React.Fragment>
                <SubcontractingModal />

                <article className="article">
                    <section id="statistics" className="data_sources">
                        <div className="vertical_tab">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="white_box charts subcontracting">
                                                    <ReactTable
                                                        data={data}
                                                        columns={columns}
                                                        isLoading={this.state.isLoading}
                                                        isSticky={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </article>
            </React.Fragment>
        )
    }
}

export default connect(null, dispatch => ({reduxDispatch: dispatch}))(Subcontracting);
