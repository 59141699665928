// @flow
import React from "react";
import { connect } from "react-redux";
import * as constants from "./constants";
import * as t from "../../../../lib/backend/reports.generated.types";
import { DraggableShiftRect } from "../Draggable/DraggableElements";
import * as rt from "../../../../lib/SimulationReportModels";
import { PropertiesLogic } from "../reducers/BusinessLogic";
import { dateFromWeekAndShift } from "../../../../lib/Util";
import { GanttChartSource, getShiftXPosition } from "../reducers/properties";

import type { ReduxState } from "../reducers/reducers";
import type { PropertiesState, GanttChartSourceTypes, ShiftXPositions} from "../reducers/properties";

type Props = {
    shifts: t.ISimulationReportOrderLineShiftFeatureItem[],
    properties: PropertiesState,
    grid_lines_loaded: boolean,
    line_offset: number,
    source_type: GanttChartSourceTypes,
    earliest_start: number,
    shift_number: number,
    line: rt.ISimulationReportOrderLineEx,
    line_index: number,
    source_type: GanttChartSourceTypes,
    is_extra_line: boolean,
    highlighted_order_uuids: string[],
    last_clicked_order_uuid: string,
    shift_x_positions: ShiftXPositions,
    reschedule_order_uuid: string,
    bars_start: number
};
type State = {};

class EnabledShifts extends React.Component<Props, State> {

    getSourceType = () => {
        return this.props.source_type || this.props.properties.source_type;
    }

    shouldRenderDraggableShifts = () => {
        const source_type = this.getSourceType();
        return (
            !this.props.is_extra_line && source_type !== GanttChartSource.stock_requirements &&
            this.props.last_clicked_order_uuid && PropertiesLogic.isPlanningTable(this.getSourceType())
        )
    }

    getFirstShift = (
        shift_time: $Shape<t.ISimulationReportShiftTime>,
        width: number,
        height: number,
        source_type: GanttChartSourceTypes
    ) => {
        if (!shift_time) {
            return null;
        }
        const earliest_start = dateFromWeekAndShift(shift_time.week, shift_time.year, shift_time.shift_number).getTime();
        const el = document.querySelector(`[gridline-shift-index]`);
        const margin_area = width / 4;
        if (el) {
            const x = parseFloat(el.getAttribute("x1"));
            const y = this.props.line_offset;
            return <DraggableShiftRect
                key={"first-shift-" + y}
                id={"first-shift"}
                x={x - margin_area}
                y={y}
                width={margin_area}
                height={height}
                shift_number={shift_time.shift_number}
                opacity={0.0}
                line_uuid={this.props.line.line}
                line_index={this.props.line_index}
                earliest_start={earliest_start}
                earliest_end={earliest_start}
                source_type={source_type}
                is_unscheduled={false}
                is_extra_line={this.props.is_extra_line ? this.props.is_extra_line.toString() : undefined}
                is_first_shift={true}
            ></DraggableShiftRect>
        }
    }

    renderEnabledShifts = () => {
        if (!this.shouldRenderDraggableShifts()) {
            return null;
        }
        const source_type = this.getSourceType();
        const EnabledShiftsList = [];
        const { bar_height, xscale } = this.props.properties;
        if (!xscale) return null;
        const w_shift = xscale(constants.SHIFT_H);
        const shifts = this.props.shifts
        if(shifts[0]) {
            const shift_time = shifts[0].shift_time;
            EnabledShiftsList.push(this.getFirstShift(
                {
                    week: shift_time.week,
                    year: shift_time.year,
                    shift_number: shift_time.shift_number
                },
                w_shift,
                bar_height,
                source_type
            ));
        }

        for (let i = 0; i < shifts.length; i++) {
            const shift = shifts[i];
            if (!shift.enabled) {
                continue;
            }
            const shift_time = shift.shift_time;
            const x = this.props.bars_start + getShiftXPosition(
                {
                    shift_x_positions: this.props.shift_x_positions,
                    shift_number: shift_time.shift_number,
                    week: shift_time.week,
                }
            );
            // WARP : temporary hack, need to fix
            if (x === undefined) { continue; }
            const y = this.props.line_offset;
            const width = w_shift;
            const height = bar_height;

            const earliest_start = dateFromWeekAndShift(
                shift.shift_time.week, shift.shift_time.year, shift.shift_time.shift_number
            ).getTime();

            EnabledShiftsList.push(
                <DraggableShiftRect
                    key={i}
                    data-id="enabled-shifts"
                    x={x}
                    y={y}
                    width={width}
                    height={height}
                    shift_number={shift.shift_time.shift_number}
                    opacity={0.0}
                    line_uuid={this.props.line.line}
                    line_index={this.props.line_index}
                    earliest_start={earliest_start}
                    earliest_end={earliest_start}
                    source_type={source_type}
                    is_unscheduled={false}
                    is_extra_line={this.props.is_extra_line ? this.props.is_extra_line.toString() : undefined}
                    data-shift-date={shift_time.shift_date}
                    data-shift-number={shift_time.shift_number}
                    data-shift-week={shift_time.week}
                    data-shift-year={shift_time.year}
                ></DraggableShiftRect>
            );

        }

        return EnabledShiftsList;
    }

    render() {
        if (!this.props.shift_x_positions) {
            return null;
        }

        return <g id="enabled-shifts">
            {this.renderEnabledShifts()}
        </g>
    }
}

export default connect(
    (state: ReduxState) => {
        return {
            shift_x_positions: state.gantt_chart_properties.shift_x_positions,
            source_type: state.gantt_chart_properties.source_type,
            last_clicked_order_uuid: state.gantt_chart_planning_table.last_clicked_order_uuid,
            highlighted_order_uuids: state.gantt_chart_planning_table.highlighted_orders,
            reschedule_order_uuid: state.gantt_chart_filters.reschedule_order_uuid,
            bars_start: state.gantt_chart_properties.bars_start
        }
    },
    (dispatch) => ({ reduxDispatch: dispatch })
)(EnabledShifts);
