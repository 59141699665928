// @flow

import * as React from "react";
import { FormattedMessage } from "react-intl";
import ErrorComponent from "./ErrorComponent";
import { getBackend } from "../lib/backend/Backend2";
import { Button } from "react-bootstrap";
import { createCsvAndDownload } from "../lib/Util";
import Select from "react-select";


import { translate } from "../components/IntlProviderWrapper";

import Toggle from "react-toggle";

type Props = {};

type State = {
    dropdownOptions: string[],
    headers: string[],
    values: any[],
    selectedTable: string,
    filter: string,
    expandedColumns: string[],
    apiResponse: any,
    error: string,
    warning: string,
    showDiff: boolean
};

class Audit extends React.Component<Props, State> {

    state = {
        dropdownOptions: [],
        headers: [],
        values: [],
        selectedTable: "",
        filter: "",
        expandedColumns: [],
        apiResponse: "",
        error: "",
        warning: "",
        showDiff: false
    };

    constructor(props: Props) {
        super(props);
        this.getData();
        this.getAllAuditTables();
    }

    getOptionsForDropdown = () => {
        let options = [];

        const { dropdownOptions } = this.state;

        if (dropdownOptions && dropdownOptions.length > 0) {
            dropdownOptions.forEach(option => {
                options.push({
                    label: <FormattedMessage id={option} defaultMessage={option} />,
                    value: option,
                    isHidden: true,
                });
            });
        }
        return options;
    }

    async getAllAuditTables(): Promise<void> {
        const res = await getBackend().audit.getAllAuditTables({});

        if (res) {
            this.setState({ dropdownOptions: res.tables });
        }
    }

    getHeaders = () => {
        const { values } = this.state;

        if (values && values.length > 0) {
            const headers = Object.keys(values[0]);
            this.setState({ headers });
        }
    }

    getHeaderValues = () => {
        const { headers } = this.state;

        const header_values = headers.map(header => (
            <th key={header}>
                <FormattedMessage id={"table.header" + header} defaultMessage={header} />
            </th>
        ));

        return (
            <thead>
                <tr key="headerValues">
                    {header_values}
                </tr>
            </thead>
        );
    }

    async getData() {

        const res = await getBackend().audit.getAuditTable({
            table_name: this.state.selectedTable,
            filter: this.state.filter
        });

        if (res) {
            this.setState({ values: res.tables, apiResponse: res.tables });
        }

        this.getHeaders();
        this.getTableData();
    }

    exportJson = () => {

        var data_string = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(this.state.apiResponse));
        var download_anchor_node = document.createElement('a');
        download_anchor_node.setAttribute("href", data_string);
        download_anchor_node.setAttribute("download", "audit_" + this.state.selectedTable + ".json");
        //document.body.appendChild(downloadAnchorNode); // required for firefox
        download_anchor_node.click();
        download_anchor_node.remove();
    }

    exportCsv = () => {

        let data: any[][] = [];

        data.push(this.state.headers);

        if (this.state.values && this.state.values.length !== 0) {

            for (const row_key of this.state.values.keys()) {

                const row = this.state.values[row_key];

                let content_table = [];

                for (const column_key in row) {
                    const column_value = row[column_key];

                    if (typeof column_value === 'object') {

                        let object_values = [];
                        object_values.push("{")

                        for (const object_key in column_value) {

                            const object_value = column_value[object_key];
                            const x = object_key + "=" + object_value;

                            object_values.push(x);
                        }

                        object_values.push("}")
                        content_table.push(object_values);

                    } else {
                        content_table.push(column_value);
                    }
                }

                data.push(content_table);
            }
            createCsvAndDownload(data, "audit_" + this.state.selectedTable + ".csv");
        }
    }

    handleChangeDropdownOption(e: any) {
        let { value } = e;
        this.setState({ selectedTable: value });
    }

    handleChangeFilterValue(e: any) {
        let value = e.target.value;
        this.setState({ filter: value });
    }

    handleOnClickToExpand(column_key: string, row_index: number) {

        let key = String(column_key) + String(row_index);

        if (this.state.expandedColumns.includes(key)) {
            this.setState({
                expandedColumns: this.state.expandedColumns.filter(function (key_from_array) {
                    return key_from_array !== key
                })
            });
        } else {
            let expanded_columns = this.state.expandedColumns;
            expanded_columns.push(key);
            this.setState({
                expandedColumns: expanded_columns
            });
        }
    }

    getTableData() {

        // this is for the table return
        let full_table = [];
        let easy_to_show_values = ['string', 'number', 'boolean'];

        if (this.state.values === undefined || this.state.values === null || this.state.values.length === 0) {
            return [];
        }

        const n = this.state.values.length;

        for (let row_index = 0; row_index < n; row_index++) {

            const row = this.state.values[row_index];
            const prev_row = this.state.values[row_index + 1] ? this.state.values[row_index + 1] : null;

            let table_row = [];

            for (const column_key in row) {
                const column_value = row[column_key];
                const prev_column_value = prev_row ? prev_row[column_key] : null;

                const key = String(column_key) + String(row_index);

                let show_diff = false;

                if (prev_row !== null && this.state.showDiff && column_value !== prev_column_value) {
                    show_diff = true;
                }

                if (column_value === null) {
                    // First check if cell value is NULL
                    table_row.push(
                        <td
                            key={key}
                            style={{ color: "#00ABF0", backgroundColor: show_diff ? "#64e3a1" : null }}>
                            NULL
                        </td>
                    );

                } else if (easy_to_show_values.includes(typeof (column_value))) {
                    // Then check if cell value is easy_to_show_values (number, string, boolean)

                    let is_expanded = String(column_value).length > 40 && this.state.expandedColumns.includes(key);
                    let cell_style = {
                        maxWidth: is_expanded ? "800px" : "300px",
                        overflow: is_expanded ? "show" : "hidden",
                        wordWrap: is_expanded ? "break-word" : undefined,
                        whiteSpace: is_expanded ? "normal" : "nowrap",
                        textOverflow: is_expanded ? undefined : "ellipsis",
                        backgroundColor: show_diff ? "#64e3a1" : null
                    };

                    table_row.push(
                        <td
                            key={key}
                            onClick={() => this.handleOnClickToExpand(column_key, row_index)}
                            style={cell_style}
                        >
                            {column_value}
                        </td>
                    );
                } else {
                    // this is for json object (tags/extra_data)
                    let tags_for_table = [];

                    const tag_keys = Object.keys(column_value);
                    const is_expanded = this.state.expandedColumns.includes(key);

                    // if its not expanded, just show first 5 elements
                    const sorted_keys = is_expanded ? tag_keys : tag_keys.splice(0, 5);

                    // go through all tags and add them to the table
                    for (const tag_key of sorted_keys) {
                        let tag_value = column_value[tag_key];
                        let prev_tag_value = prev_row ? prev_row[column_key][tag_key] : null;

                        const is_diff = (prev_row !== null && this.state.showDiff && tag_value !== prev_tag_value);

                        tags_for_table.push(
                            <div
                                key={tag_key + row_index}
                                style={{
                                    display: "flex",
                                    backgroundColor: is_diff ? "#64e3a1" : null
                                }}>
                                {String(tag_key) + " = " + String(tag_value)}
                            </div>
                        );
                    }

                    // if button for more/less is needed
                    if (Object.keys(column_value).length > 5) {
                        if (!this.state.expandedColumns.includes(key)) {
                            tags_for_table.push(
                                <button
                                    key={row_index + "more_button"}
                                    test-id="location-edit-button" className="btn btn-outline-primary"
                                    onClick={() => this.handleOnClickToExpand(column_key, row_index)}>
                                    <FormattedMessage id="common.more" defaultMessage="More..." />
                                </button>
                            );
                        } else {
                            tags_for_table.push(
                                <button
                                    key={row_index + "less_button"}
                                    test-id="location-edit-button" className="btn btn-outline-primary"
                                    onClick={() => this.handleOnClickToExpand(column_key, row_index)}>
                                    <FormattedMessage id="common.less" defaultMessage="Less..." />
                                </button>
                            );
                        }
                    }

                    table_row.push(
                        <td key={key}>
                            {tags_for_table}
                        </td>
                    );
                }
            }
            full_table.push(
                <tr key={"contentTable" + row_index} >
                    {table_row}
                </tr>
            );
        }

        return (
            <tbody>
                {full_table}
            </tbody>
        );

    }


    handleClickShowDiff = () => {
        this.setState({ showDiff: !this.state.showDiff });
    }

    auditHeader = () => {
        return (
            <div className="filter_bar">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <ul className="list-inline">
                                <li className="list-inline-item">
                                    <h2>
                                        <FormattedMessage id="Header.menu.audit" defaultMessage="Audit" />
                                    </h2>
                                </li>
                                <li className="list-inline-item">
                                    <div className="container-fluid">

                                        <div className="form-group" style={{ width: "500px" }}>
                                            <Select
                                                options={this.getOptionsForDropdown()}
                                                onChange={(e) => this.handleChangeDropdownOption(e)}
                                            />
                                        </div>

                                        <div className="form-group">
                                            <FormattedMessage id="Users.filter_tooptip" defaultMessage="Filter by title or user" >
                                                {text => <input type="text" className="form-control search_bar"
                                                    placeholder={text}
                                                    name="title_filter" value={this.state.filter} onChange={(e) => this.handleChangeFilterValue(e)} />}
                                            </FormattedMessage>
                                        </div>

                                        <div className="form-group" >
                                            <Button className="btn_toolbar btn_preview" onClick={() => this.getData()}>
                                                <FormattedMessage id="common.get_data" defaultMessage="Get data" />
                                            </Button>
                                        </div>

                                        <div className="form-group" >
                                            <label>
                                                <FormattedMessage id="common.show_diff" defaultMessage="Show diff" />
                                            </label>
                                            <Toggle name="common.show_diff" defaultChecked={this.state.showDiff}
                                                onChange={() => this.handleClickShowDiff()} className="vertical-align-middle" />
                                        </div>

                                        <div className="form-group" >
                                            <Button className="btn_toolbar btn_export" onClick={() => this.exportCsv()}>
                                                <FormattedMessage id="common.export_csv" defaultMessage="Export CSV" />
                                            </Button>
                                        </div>

                                        <div className="form-group" >
                                            <Button className="btn_toolbar btn_export" onClick={() => this.exportJson()}>
                                                <FormattedMessage id="common.export_json" defaultMessage="Export JSON" />
                                            </Button>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {

        let showTable = this.state && this.state.values && this.state.values.length > 0;

        return (
            <div>
                <div>
                    <article id="admin-audit" className="lines_article">
                        <section id="statistics" className="data_sources">
                            {this.auditHeader()}
                            <div className="container-fluid">
                                {showTable ?
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="white_box charts">
                                                <ErrorComponent msg={this.state.error} type="error" />
                                                <ErrorComponent msg={this.state.warning} type="warning" />
                                                <div className="table_content" style={{ overflowX: "unset" }}>
                                                    <table id="datatable-responsive" className="table dt-responsive nowrap" cellSpacing="0" width="100%">
                                                        {this.getHeaderValues()}
                                                        {this.getTableData()}
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <ErrorComponent msg={translate("common.no_data", "No data")} type="no-data" />
                                }
                            </div>
                        </section>
                    </article>
                </div>
            </div>
        );
    }
}

export default Audit;
