// @flow
import * as React from "react";
import { Sparklines, SparklinesLine } from "react-sparklines";
import ReactRouterPropTypes from "react-router-prop-types";
import { Link } from "react-router-dom";

import { createIndicator } from "./Indicator";
import { createBulletGraph } from "./BulletGraph";
import Panel from "./Panel";
import Band from "./Band";
import Content from "./Content";
import Title from "./Title";
import Subtitle from "./Subtitle";
import Label from "./Label";
import type { ManufacturingData } from "./Dashboard";

import HighchartsLine from "../HighchartsLine";
import HighchartsLineAreaRange from "../HighchartsLineAreaRange";
import EventTable from "../EventTable";
import GantchartStandalone from "../manufacturing/GantchartStandalone";
import BatchOperations from "../manufacturing/BatchOperations";
import IotStandalone from "../manufacturing/IotStandalone";
import ShiftTableStandalone from "../manufacturing/ShiftTableStandalone";
import { toISODateTimeString, hashCode } from "../../lib/Util";
import EventListStandalone from "../EventListStandalone";
import LineLayout from "../manufacturing/dashboard/LineLayout";
import ToolSetup from "../manufacturing/dashboard/ToolSetup";

let cntr = 0;

function createTimeseries(data: any, widget: any) {
    const ts = data.timeseries.filter(x => x.name === widget.timeseries);
    if (ts.length === 0) {
        return (<div className="white_box charts no-data">No data available</div>);
    }
    const height = widget.height || 200;
    const y_label = widget.y_label || null;
    const y_max = widget.y_max || null;
    const type = widget.plot_type || "line";
    // extract data for aggregated or normal time series
    let ts_data = [];
    if (widget.aggr) {
        let aggr_type = "avg";
        if (widget.aggr !== "avg_min_max") {
            aggr_type = widget.aggr;
        }
        ts_data = ts[0].data.map(x => {
            let val = x[aggr_type];
            if (widget.aggr === "sum") {
                val = x.cnt * x.avg;
            }
            if (widget.aggr === "avg_min_max") {
                return { val: val, low: x.min, high: x.max, ts: x.ts }
            }
            return {val: val, ts: x.ts}
        });
    } else {
        ts_data = ts[0].data;
    }
    const input_ts_data = {
        id: "extra_data_input_" + cntr++,
        height: height,
        name: "raw input",
        y_label: y_label,
        y_max_val: y_max,
        x_min_val: data.from,
        x_max_val: data.to,
        type: "line",
        ts: ts_data
    };
    if (type === "bar" || type === "column") {
        input_ts_data.type = "column";
    } else if (type === "scatter" || type === "bullet") {
        input_ts_data.type = "scatter";
    } else {
        // use line chart
    }
    if (widget.aggr === "avg_min_max") {
        return (
            <div className="dsh_chart_container">
                <HighchartsLineAreaRange key={input_ts_data.id} options={input_ts_data} />
            </div>
        );
    }
    return (
        <div className="dsh_chart_container">
            <HighchartsLine key={input_ts_data.id}
                options={
                    // input_ts_data is of a different type in avg_min_max (TsRangeObj)
                    // in other cases (TsObj). Flow assigns type in HighChartsLineArea and misses
                    // the program flow; could this be solved better?
                    // $FlowFixMe
                    input_ts_data
                } />
        </div>
    );
}

function createSparkline(data: any, widget: any) {
    const ts = data.timeseries.filter(x => x.name === widget.timeseries);
    if (ts.length === 0) {
        return (<div>No data</div>);
    }
    const sparkline_data = ts[0].data.map(x => x.val);
    return (<Sparklines data={sparkline_data} key={(sparkline_data || []).length} min={0} height={30}>
        <SparklinesLine color="#e39b00" />
    </Sparklines>);
}

type CreateShiftTableProps = {
    data_def: any,
    data: any,
    widget: any,
    history: ReactRouterPropTypes.history
}
const CreateShiftTable = (props: CreateShiftTableProps) => {
    const { data_def, widget, history, data } = props;

    const show_title = widget && widget.show_title;
    const show_line_gantt = widget && widget.show_line_gantt;

    // if data_def is undefined all lines will be displayed (no filtering)
    return (<ShiftTableStandalone history={history} show_title={show_title}
        data_def={data_def} show_line_gantt={show_line_gantt} data={data} />);
}

type CreateWidgetProps = {
    widget: any,
    data: any,
    history: ReactRouterPropTypes.history
}

const CreateWidget = (props: CreateWidgetProps) => {
    const {
        widget,
        data,
        history
    } = props;

    if (widget.type === "indicator") {
        return createIndicator(data, widget);
    }
    if (widget.type === "bullet-graph") {
        return createBulletGraph(data, widget);
    }
    if (widget.type === "title") {
        return (<Title label={widget.label} />);
    }
    if (widget.type === "subtitle") {
        return (<Subtitle label={widget.label} />);
    }
    if (widget.type === "label") {
        return (<Label label={widget.label} size={widget.size} />);
    }
    if (widget.type === "events") {
        if (data.events) {
            return (<EventTable events={data.events} />);
        }
        return (<div>No events</div>);
    }
    if (widget.type === "event-list") {
        return (<EventListStandalone events={data.events} />);
    }
    if (widget.type === "manufacturing-gantt") {
        let data_def = data && data.external && data.external["manufacturing-gantt"];
        const hashKey = hashCode(JSON.stringify(data_def || ""));
        // if data_def is undefined entire gantt will be generated (no filtering)
        return <GantchartStandalone
            key={hashKey}
            data_def={data_def}
            history={history}
            source_type={data.source_type}
        />
    }
    if (widget.type === "manufacturing-batch-operations") {
        let data_def = data && data.external && data.external["manufacturing-batch-operations"];
        let batchop_group_uuid = data_def.batchop_group_uuid;
        return (<BatchOperations key={"manufacturing-batch-operations"}
            batchOpGroupUuid={batchop_group_uuid} readOnly={true}
            showHistoryLink={false} showHeader={false}
        />);
    }
    if (widget.type === "iot") {
        const hashKey = hashCode(JSON.stringify(data || ""));
        return (<IotStandalone key={hashKey} data_def={data} widget={widget}/>);
    }
    if (widget.type === "shift-table") {
        let data_def = data && data.external && data.external["shift-table"];
        const hashKey = hashCode(JSON.stringify(data_def || "") + JSON.stringify(widget || ""));
        return <CreateShiftTable data_def={data_def} key={hashKey} widget={widget} history={history} data={data} />;
    }
    if (widget.type === "timeseries") {
        return createTimeseries(data, widget);
    }
    if (widget.type === "sparkline") {
        return createSparkline(data, widget);
    }
    if (widget.type === "container") {
        const bands = widget.bands.map(
            (x, i) => <CreateBand band={x} data={data} ii={i} history={history} key={`band_${i}`} />
        );
        return <Content panels={bands} />;
    }
    if (widget.type === "manufacturing-line-layout") {
        const hashKey: string = widget.uuid;
        const title: string = widget.title;
        const uuid: string = widget.uuid;
        const compact: boolean = widget.compact === true;
        const manufacturing: ManufacturingData = data && data.manufacturing;

        return (<LineLayout
            key={hashKey}
            title={title}
            uuid={uuid}
            compact={compact}
            manufacturing={manufacturing}
        />);
    }
    if (widget.type === "manufacturing-tool-setup") {
        let data_def = data && data.external && data.external["manufacturing-tool-setup"];
        return (<ToolSetup
            history={history}
            data_def={data_def}
            />);
    }
    throw new Error("Unknown widget type");
}

type CreatePanelProps = {
    panel: any,
    data: any,
    i: any,
    history: ReactRouterPropTypes.history
}
const CreatePanel = ({ panel, data, i, history }: CreatePanelProps) => {
    const widgets_def = panel.widgets || [];
    const widgets = widgets_def.map(x => <CreateWidget widget={x}  data={data} history={history} />);
    return (
        <Panel key={i} panels={widgets} colSpan={panel.col_span || 1} />
    );
}

type CreateBandProps = {
    band: any,
    data: any,
    ii: any,
    history?: ReactRouterPropTypes.history
}
const CreateBand = (props: CreateBandProps) => {
    const {
        band,
        data,
        ii,
        history
    } = props;

    const panels_def = band.panels || [];
    const panels = panels_def.map(
        (x, i) => <CreatePanel panel={x} data={data} i={i} history={history} key={`band_${ii}_${i}`} />
    );

    return (
        <Band key={ii} panels={panels} />
    );
}

type CreateClassicDashboardProps = {
    root: any,
    data: any,
    range_change: any,
    show_filter_bar: boolean,
    history: ReactRouterPropTypes.history
}
export const CreateClassicDashboard = (props: CreateClassicDashboardProps) => {
    const {
        root,
        data,
        range_change,
        show_filter_bar,
        history
    } = props;

    if (data.external && data.external.refresh_time) {
        setTimeout(() => {
            window.location.reload();
        }, data.external.refresh_time * 1000);
    }

    const title = root.title;
    const ts_from = new Date(data.from);
    const ts_to = new Date(data.to);
    const bands = root.bands.map(
        (x, i) => <CreateBand band={x} data={data} ii={i} history={history} key={i} />
    );

    let time_ranges = [];
    if (range_change) {
        time_ranges = ["6H", "1D", "3D", "1W", "2W", "1M", "3M", "1Y"]
            .map(x => (
                <span className="time_range_item" tabIndex="0" key={x} onClick={() => range_change(x)}>{x}</span>
            ));
    }
    return (
        // class "ql-dash-finalized" is used to determine when the dashboard rendering has been finalized
        // this is important for png and pdf rendering of reports
        <div className="data_sources full-height ql-dash-finalized" id="statistics">
            { show_filter_bar && <div className="filter_bar dashboard-header">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <ul className="list-inline">
                                <li className="list-inline-item">
                                    <Link to="/" className="navbar-brand">
                                        <img src="/img/leap-logo.svg" alt="qlector" className="logo" />
                                    </Link>
                                </li>
                                <li className="list-inline-item">
                                    <h2>{title}</h2>
                                </li>
                                <li className="list-inline-item">
                                    <ul className="navbar-nav flex-row ml-md-auto d-none d-md-flex">
                                        {range_change && <li>{time_ranges}</li>}
                                        <li className="time_range dashboard-time-range">
                                            {range_change && toISODateTimeString(ts_from)}
                                            {range_change && <span>&ndash;</span>}
                                            {toISODateTimeString(ts_to)}
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div> }
            <Content panels={bands} />
        </div>
    )
}
