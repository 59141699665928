// @flow

// main imports
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import ReactRouterPropTypes from "react-router-prop-types";

import { stringCompare } from "../../lib/Util";

// backend
import * as B2 from "../../lib/backend/Backend2";
import * as t from "../../lib/backend/batch_operations.generated.types";
import * as Auth from "../../lib/Auth";

import ErrorComponent from "../ErrorComponent";

import { BATCHOP_GROUP_TAGS } from "../../lib/ManufacturingTags.generated";

import Loader from "../Loader";

type Props = {
    match: ReactRouterPropTypes.match,
    line_group_uuid?: string,
    showHeader: boolean,
}
type MaterialStatus = {
    ident: string;
    title: string;
    quantity: number;
}

type State = {
    group: t.IBatchOperationGroup | null,
    groups: t.IBatchOperationGroup[],
    materials: MaterialStatus[],
    operations: t.IBatchOperationArchiveInfo[],
    error: string,
    loading: boolean
}


class BatchOperationsStatus extends Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            group: null,
            groups: [],
            materials: [],
            operations: [],
            error: "",
            loading: true
        };
    }



    componentDidMount() {
        this.loadComponent();
    }

    async loadComponent() {
        try {
            const allowed_lines_groups = Auth.getUserLineGroups();
            const api = B2.getBackend().batchOperations;
            const res = await api.getGroups({});
            let groups = res.items
                .filter(x => allowed_lines_groups.indexOf(x.tags[BATCHOP_GROUP_TAGS.line_group]) >= 0);
            // filter down
            if (this.props.match && this.props.match.params.group_uuid) {
                groups = groups.filter(x => x.uuid === this.props.match.params.group_uuid);
            } else if (this.props.line_group_uuid) {
                groups = groups.filter(x => x.tags && x.tags[BATCHOP_GROUP_TAGS.line_group] === this.props.line_group_uuid);
            } else {
                this.setState({ loading: false })
                return;
            }
            // show
            if (groups.length === 0) {
                this.setState({ error: "No location defined or allowed for current user.", loading: false });
                return;
            }
            const group = groups[0];
            this.setState({ groups, group }, async () => {
                await this.refreshStatus();
            });
        } catch (err) {
            this.setState({ error: err.message, loading: false });
        }
    }

    async refreshStatus() {
        const group = this.state.group;
        if (group !== null) {
            const api = B2.getBackend().batchOperations;
            const status = await api.getCurrentStatus({ group_id: group.uuid });
            const current_time = new Date().getTime();
            const locations = status.locations
                .filter(location => location.disabled !== true && location.operation !== null && (location.operation ? location.operation.end_time : 0) <= current_time)
                .sort((a, b) => stringCompare(a.name, b.name));
            const silo_input = group.tags[BATCHOP_GROUP_TAGS.silo_input] && group.tags[BATCHOP_GROUP_TAGS.silo_input] === true ? true : false;
            const map = new Map();
            if (silo_input) {
                // if batchop_group has flag silo_input
                // only the quantity of the last material is always
                for (let location of locations) {
                    const location_operation = location.operation;
                    if (location_operation === undefined) {
                        continue;
                    }
                    const order = location_operation.orders[location_operation.orders.length - 1];
                    const stored = map.get(order.name);
                    const prev = stored && stored !== undefined ? stored.quantity : 0;
                    map.set(order.name, {
                        ident: order.name,
                        title: order.material_title,
                        quantity: order.quantity + prev
                    });
                }
            } else {
                // if batchop_group is doesnt have silo input
                // sum all entries in the furnace
                for (let location of locations) {
                    const location_operation = location.operation;
                    if (location_operation === undefined) {
                        continue;
                    }
                    for (const order of location_operation.orders) {
                        const stored = map.get(order.name);
                        const prev = stored && stored !== undefined ? stored.quantity : 0;
                        map.set(order.name, {
                            ident: order.name,
                            title: order.material_title,
                            quantity: order.quantity + prev
                        });
                    }
                }
            }
            const materials: MaterialStatus[] = [];
            for (const ident of map.keys()) {
                const stored = map.get(ident);
                if (stored) {
                    materials.push(stored);
                }
            }
            this.setState({ materials, loading: false });
        }
    }

    renderTable(materials: MaterialStatus[]) {
        return (
            <table className="table">
                <thead>
                    <tr>
                        <th>
                            <FormattedMessage id="Manufacturing.BatchOp.Ident" defaultMessage="Ident" />
                        </th>
                        <th>
                            <FormattedMessage id="Manufacturing.BatchOp.Title" defaultMessage="Title" />
                        </th>
                        <th>
                            <FormattedMessage id="Manufacturing.BatchOp.Quantity" defaultMessage="Quantity" />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {materials.map((material, i) => (
                        <tr key={i}>
                            <td>{material.ident}</td>
                            <td>{material.title}</td>
                            <td>{material.quantity}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    }


    render() {
        if (this.state.loading) {
            return <Loader />;
        }

        return (
            <article className="article">
                <section id="statistics" className="data_sources">
                    {this.props.showHeader && <div className="filter_bar">
                        <div className="container-fluid">
                            <div className="row align-items-center mx-n2">
                                <div className="col-sm flex-grow-0 flex-basis-auto w-sm-auto px-2 mr-sm-auto">
                                    <h2>
                                        {this.state.group && this.state.group.name}
                                    </h2>
                                </div>
                                <div className="col-sm flex-grow-0 flex-basis-auto w-sm-auto px-2">
                                    <Link className="blue_link" to="/kiosk/batch_operations">
                                        &#171; <FormattedMessage id="common.back" defaultMessage="Back" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>}
                    <div className="vertical_tab">
                        <div className="container-fluid">
                            {this.props.showHeader && <div className="row">
                                <div className="col-lg-12 col-md-12">
                                    <br /><br /><br /><br />
                                </div>
                            </div>}

                            <ErrorComponent type="error" msg={this.state.error} />

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="white_box charts">
                                        <div className="chart-wrapper">
                                            {this.renderTable(this.state.materials)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </article>
        )
    }
}

export default BatchOperationsStatus;
