// @flow
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Modal } from "react-bootstrap";
import ReactRouterPropTypes from "react-router-prop-types";

import { shiftNumber } from "../../lib/Util";
import { translate } from "../IntlProviderWrapper";

import LinePlanning from "./PlanningTable/LinePlanning";
import ProductionVersionChange from "./ProductionVersionChange";
import ErrorComponent from "../ErrorComponent";

type Props = {
    history: ReactRouterPropTypes.history,
    show: boolean,
    onHide: () => void,
    line_uuid: string,
    line_title: string,
    filtered_order_uuids?: string[],
};

type State = {
    year: number,
    week: number,
    shift: number,
    saving: boolean,
    quick_sim: boolean,
    error: string
};

class LinePlanningModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        // get current week and year
        const shift = shiftNumber(new Date());
        this.state = {
            year: shift.year,
            week: shift.week,
            shift: shift.shift,
            saving: false,
            quick_sim: false,
            error: ""
        }
    }

    handleSave = () => {
        this.save();
    };

    async save() {
        // first indicate we are in process of saving
        this.setState({ saving: true });
        // tell schedule to save
        const ok = await this.refs["line_schedule"].save();
        // we are done saving
        this.setState({ saving: false });
        if (ok) {
            // if all ok, hide
            this.props.onHide();
        } else {
            // if use, just report
            this.setState({ error: translate("LineSchedule.save_error", "Error saving changes") })
        }
    }

    handleQuickSim = () => {
        this.quickSim();
    };

    async quickSim() {
        // first indicate we are in process of simulation
        this.setState({ quick_sim: true });
        // tell schedule to simulation
        await this.refs["line_schedule"].quickSimulate();
        // we are done simulating
        this.setState({ quick_sim: false });
    }

    // prepare list of datasources
    render() {
        const hide_shift_editor = this.props.filtered_order_uuids !== undefined;
        return (
            <Modal
                bsSize="large"
                show={this.props.show}
                onHide={this.props.onHide}
                dialogClassName="line-planning-modal modal-dialog-scrollable"
            >
                <Modal.Header>
                    <Modal.Title>
                        {translate("LineScheduleModal.edit_schedule", "Edit schedule for line") + ": " + this.props.line_title}
                    </Modal.Title>
                    <button type="button" className="close" onClick={this.props.onHide}>
                        <span aria-hidden="true">×</span>
                        <span className="sr-only">
                            {translate("common.close", "Close")}
                        </span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <ErrorComponent msg={this.state.error} type="error" />
                    <LinePlanning
                        history={this.props.history}
                        ref="line_schedule"
                        line_uuid={this.props.line_uuid}
                        line_title={this.props.line_title}
                        filtered_order_uuids={this.props.filtered_order_uuids}
                        year={this.state.year}
                        week={this.state.week}
                        shift={this.state.shift}
                        onLoad={() => { }}
                        compact={false}
                        shift_edit_weeks={6}
                        shift_edit_weeks_collapsed={3}
                        insights_map={new Map()}
                        hide_shift_editor={hide_shift_editor}
                    />
                    {this.props.filtered_order_uuids && this.props.filtered_order_uuids.length == 1 && <ProductionVersionChange
                        order_uuid={this.props.filtered_order_uuids[0]}
                    />}
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={this.handleSave} disabled={this.state.saving} className="btn btn-outline-primary">
                        <FormattedMessage id="common.save" defaultMessage="Save" />
                    </button>
                    <button onClick={this.handleQuickSim} disabled={this.state.quick_sim} className="btn btn-outline-secondary">
                        <FormattedMessage id="Manufacturing.Planning.quick_calc" defaultMessage="Quick calculation" />
                    </button>
                    <button onClick={this.props.onHide} disabled={this.state.saving} className="btn btn-outline-secondary">
                        <FormattedMessage id="common.back" defaultMessage="Back" />
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default LinePlanningModal;
