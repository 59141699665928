// @flow

import * as t from "./backend/manufacturing2.generated.types";
import { translate } from "../components/IntlProviderWrapper";
import { INSIGHT_TYPES } from "./ManufacturingTags.generated";

import {
    ROLE_ADMIN, ROLE_MAN_PLANNER, ROLE_MAN_PLANT_MANAGER,
    ROLE_MAN_SHOPFLOOR_MANAGER, ROLE_POWER_USER, ROLE_DEMO_USER,
    isInRole
} from "./Auth";
import { createHtmlAndDownload } from "./Util";

export function storeInsightsShowCheckboxesOption(show: boolean): void {
    localStorage.setItem("insights-selection-showcheckboxes", JSON.stringify(show));
}

export function getInsightsShowCheckboxesOption(): boolean {
    const show = localStorage.getItem("insights-selection-showcheckboxes");
    if (show === undefined || show === null) {
        return false;
    }
    return JSON.parse(show);
}

export function storeInsightsSelectionLineUUIDs(line_uuids: Object): void {
    localStorage.setItem("insights-selection-lineuuids", JSON.stringify(line_uuids));
}

export function storeInsightsSelectionEventTypes(event_types: Object): void {
    localStorage.setItem("insights-selection-eventtypes", JSON.stringify(event_types));
}

export function setInsightSelectionLineUUIDsWIP(status: boolean) {
    localStorage.setItem("insights-selection-lineuuids-wip", status.toString());
}

export function setInsightsSelectionEventTypesWIP(status: boolean) {
    localStorage.setItem("insights-selection-eventtypes-wip", status.toString());
}

export function getInsightsSelectionWIP() {
    const eventtypesWIP = localStorage.getItem("insights-selection-eventtypes-wip");
    const lineuuidsWIP = localStorage.getItem("insights-selection-lineuuids-wip");
    if (eventtypesWIP && lineuuidsWIP) {
        return JSON.parse(eventtypesWIP) || JSON.parse(lineuuidsWIP);
    }
    return true;
}

function cleanEmptyKeys(ids: any) {
    if (ids) {
        return Object.keys(ids).reduce((obj, key) => {
            if (key !== "") {
                obj[key] = ids[key];
            }
            return obj;
        }, {});
    }
    return {};
}

export function getInsightSelectionLineUUIDs() {
    const selection = localStorage.getItem("insights-selection-lineuuids");
    if (selection === undefined || selection === null) {
        return {};
    }
    return cleanEmptyKeys(JSON.parse(selection));
}

export function getInsightSelectionEventTypes() {
    const selection = localStorage.getItem("insights-selection-eventtypes");
    if (selection === undefined || selection === null) {
        return {};
    }
    return cleanEmptyKeys(JSON.parse(selection));
}

export function insightTypeToName(insight_type: string): string {
    const array1 = insight_type.split("_");
    const name1 = array1.join(" ");
    const array2 = name1.split(".");
    const name2 = array2.join(" ").replace(/  +/g, ' ');
    return name2.charAt(0).toUpperCase() + name2.slice(1);
}


export function getDefaultInsightTypesForUser(): string[] {

    if (isInRole(ROLE_ADMIN) || isInRole(ROLE_POWER_USER) || isInRole(ROLE_DEMO_USER)) {
        return [
            INSIGHT_TYPES.freeze_order_move,
            INSIGHT_TYPES.no_person,
            INSIGHT_TYPES.no_shift,
            INSIGHT_TYPES.no_work_in_shift,
            INSIGHT_TYPES.out_of_stock_shopfloor,
            INSIGHT_TYPES.out_of_stock_plant,
            INSIGHT_TYPES.quantiles_simple,
            INSIGHT_TYPES.thresholds_simple,
        ];
    }

    if (isInRole(ROLE_MAN_PLANNER) || isInRole(ROLE_MAN_PLANT_MANAGER)) {
        return [
            INSIGHT_TYPES.freeze_order_move,
            INSIGHT_TYPES.man_downtime,
            INSIGHT_TYPES.no_person,
            INSIGHT_TYPES.no_shift,
            INSIGHT_TYPES.no_work_in_shift,
            INSIGHT_TYPES.out_of_stock_shopfloor,
            INSIGHT_TYPES.out_of_stock_plant
        ];
    }

    if (isInRole(ROLE_MAN_SHOPFLOOR_MANAGER)) {
        return [
            INSIGHT_TYPES.man_downtime,
            INSIGHT_TYPES.no_person,
            INSIGHT_TYPES.no_shift,
            INSIGHT_TYPES.no_work_in_shift,
            INSIGHT_TYPES.out_of_stock_shopfloor,
            INSIGHT_TYPES.quantiles_simple,
            INSIGHT_TYPES.thresholds_simple,
            INSIGHT_TYPES.tool_setup
        ];
    }

    // default
    return [
        INSIGHT_TYPES.freeze_order_move,
        INSIGHT_TYPES.no_person,
        INSIGHT_TYPES.no_shift,
        INSIGHT_TYPES.no_work_in_shift,
        INSIGHT_TYPES.out_of_stock_shopfloor,
        INSIGHT_TYPES.out_of_stock_plant,
        INSIGHT_TYPES.quantiles_simple,
        INSIGHT_TYPES.thresholds_simple
    ];
}

export type InsightDayShiftGrid = {
    "1": t.IEventDataEx[],
    "2": t.IEventDataEx[],
    "3": t.IEventDataEx[]
}

export type InsightWeekShiftGrid = {
    "1": InsightDayShiftGrid,
    "2": InsightDayShiftGrid,
    "3": InsightDayShiftGrid,
    "4": InsightDayShiftGrid,
    "5": InsightDayShiftGrid,
    "6": InsightDayShiftGrid,
    "7": InsightDayShiftGrid
}

export function getEmptyWeekShiftGrid(): InsightWeekShiftGrid {
    return {
        '1': { '1': [], '2': [], '3': [] },
        '2': { '1': [], '2': [], '3': [] },
        '3': { '1': [], '2': [], '3': [] },
        '4': { '1': [], '2': [], '3': [] },
        '5': { '1': [], '2': [], '3': [] },
        '6': { '1': [], '2': [], '3': [] },
        '7': { '1': [], '2': [], '3': [] }
    };
}


export function exportInsightsHTML(html_string: string): void {
    const html = `
        <html>
            <head>
                <title>${translate("Header.menu.insights")}</title>
                <meta charSet="utf-8" />
                <style>
                    body {
                        font-family: "Arial", "Helvetica", "sans-serif";
                    }

                    body, table, p, div {
                        font-size: 12px;
                    }

                    table {
                        border-collapse: collapse;
                        border: 1px solid black;
                        width: 100%
                    }

                    th, td {
                        border: 1px solid lightgray;
                        border-bottom: 0px solid lightgray;
                        padding: 13px;
                        text-align: center;
                    }

                    .dayTitle {
                        background-color: rgb(115, 145, 171);
                    }

                    .dayTitle td {
                        color: white;
                    }

                    @media print { body { -webkit-print-color-adjust: exact; } }
                </style>
            </head>
            <body onload="window.print()">
                ${html_string}
            </body>
        </html>
    `;

    createHtmlAndDownload(html, "insights.html");
}
