// @flow
import React from "react";
import MultiSelect from "../../../MultiSelect";
import { connect } from "react-redux";
import { SET_LOCK_FILTER } from "../reducers/common";
import { SET_SELECTED_LINE_UUIDS } from "../reducers/reducersFilters/filters";
import { translate } from "../../../IntlProviderWrapper";

import type { SetLockFilter } from "../reducers/common";
import type { LineOrders } from "../reducers/linesOrders";
import type { ReduxState } from "../reducers/reducers";
import type { SetRescheduleLineAction } from "../reducers/common";
import type { SetSelectedLineUuidsAction } from "../reducers/reducersFilters/filters";

export const LOCKED_LINE_ID = "locked-line-icon";

type LockLineProps = {
    is_filter_locked: boolean,
    reduxDispatch: (args: SetLockFilter) => void
}

type LockLineState = {};

class LockLine extends React.Component<LockLineProps, LockLineState> {

    onClick = () => {
        this.props.reduxDispatch({
            type: SET_LOCK_FILTER,
            data: !this.props.is_filter_locked
        });
    }

    render() {
        const style = { cursor: "pointer" };

        return <span id={LOCKED_LINE_ID} data-enabled={this.props.is_filter_locked} style={{ cursor: "pointer", marginLeft: "2px" }} onClick={this.onClick}>
            {
                this.props.is_filter_locked ?
                <img style={style} onClick={this.onClick} src="/img/lock-solid.svg" width={20} height={20} /> :
                <img style={style} onClick={this.onClick} src="/img/unlock-solid.svg" width={20} height={20} />
            }
        </span>
    }
}

export const ConnectedLockLine = connect(
    (state: ReduxState) => {
        const filters = state.gantt_chart_filters;
        return {
            is_filter_locked: filters.is_filter_locked
        }
    },
    (dispatch) => ({ reduxDispatch: dispatch })
)(LockLine);

type SelectOptionItem = {
    label: string;
    value: string;
}

type Props = {
    lines_orders: LineOrders[],
    selected_line_uuids: string[],
    reduxDispatch: (args: SetRescheduleLineAction | SetSelectedLineUuidsAction) => void,
    is_disabled: string
}

type State = {};

class LineOrderListFilter extends React.Component<Props, State> {
    state = { }

    getOptions = () => {
        return this.props.lines_orders.map(this.mapLineOrdersToOptions);
    }

    handleLineChange = (selected?: SelectOptionItem[]) => {
        if (!selected) {
            this.props.reduxDispatch({ type: SET_SELECTED_LINE_UUIDS, data: [] });
        } else {
            this.props.reduxDispatch({ type: SET_SELECTED_LINE_UUIDS, data: selected.map(s => s.value) });
        }
    }

    getSelectedLines = (): ?SelectOptionItem[] => {
        const options = this.props.lines_orders.map(this.mapLineOrdersToOptions);
        return options.filter(o => this.props.selected_line_uuids.includes(o.value)) || undefined;
    }

    mapLineOrdersToOptions = (line_orders: LineOrders) => {
        return {
            value: line_orders.line_uuid,
            label: line_orders.line_title
        }
    }

    render() {
        const selected_lines = this.getSelectedLines();
        const options = this.getOptions();
        return <div className="line-filter">
            <MultiSelect
                key="line-filter"
                value={selected_lines}
                placeholder={translate("common.lines", "Lines")}
                options={options}
                onChange={this.handleLineChange}
                isDisabled={this.props.is_disabled}
                enable_filter_selected
            />
        </div>
    }
}

export default connect(
    (state: ReduxState) => {
        const lines_orders = state.gantt_chart_lines_orders.lines_orders;
        const selected_line_uuids = state.gantt_chart_filters.selected_line_uuids;

        return {
            lines_orders,
            selected_line_uuids
        }
    },
    (dispatch) => ({reduxDispatch: dispatch})
)(LineOrderListFilter);
