// @flow

// main imports
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

import { getBackend } from "../../lib/backend/Backend2";
import * as t from "../../lib/backend/manufacturing2.generated.types";

import { niceNumber, parseFloatSmart, stringCompare } from "../../lib/Util"
import ErrorComponent from "../ErrorComponent";
import StockStatusViewTable from "./StockStatusViewTable";
import type { StockStatusFilterProps } from "./StocksViewCommon";
import { withRouter } from "react-router-dom";
import { translate } from "../IntlProviderWrapper";

type Props = {
    plant_uuid: string,
    line_group_uuid: string,
    filter: StockStatusFilterProps,
    location: Object,
    history: Object
}

type State = {
    materials: t.IStockStatus[],
    // modal edit view
    show_edit_modal: boolean,
    edit_material_uuid: string,
    edit_material_title: string,
    edit_location_uuid: string,
    edit_location_title: string,
    edit_material_quantity_complete_only: number,
    edit_material_quantity_complete_only_str: string,
    edit_material_quantity_being_produced: number,
    edit_material_quantity_being_produced_str: string,
    edit_material_quantity_total: number,
    edit_material_quantity_total_str: string,
    edit_saving: boolean,
    include_zero_quantity: boolean,
    // error reporting
    error: string,
    isLoading: boolean
}

class StockStatusView extends Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            materials: [],
            show_edit_modal: false,
            edit_material_uuid: "",
            edit_material_title: "",
            edit_location_uuid: "",
            edit_location_title: "",
            edit_material_quantity_complete_only: 0,
            edit_material_quantity_complete_only_str: "",
            edit_material_quantity_being_produced: 0,
            edit_material_quantity_being_produced_str: "",
            edit_material_quantity_total: 0,
            edit_material_quantity_total_str: "",
            edit_saving: false,
            include_zero_quantity: false,
            error: "",
            isLoading: true
        };
    }

    componentDidMount() {
        this.loadComponent();
    }

    componentDidUpdate(prev_props: Props) {
        if ((
                prev_props.plant_uuid !== this.props.plant_uuid ||
                prev_props.line_group_uuid !== this.props.line_group_uuid ||
                prev_props.filter.selected_line_uuid !== this.props.filter.selected_line_uuid || 
                prev_props.filter.order_external_id !== this.props.filter.order_external_id ||
                prev_props.filter.selected_display_type !== this.props.filter.selected_display_type
            ) && (this.props.plant_uuid !== "")  && (this.props.line_group_uuid !== "")) {

            this.loadComponent();
        }
    }

    async loadComponent() {
        this.setState({ isLoading: true });

        try {
            if (this.props.plant_uuid !== null && this.props.line_group_uuid !== null) {
                const additionalParams = {};

                if (this.props.filter.selected_line_uuid === "") {
                    additionalParams.line_uuids = [];
                } else if (this.props.filter.selected_line_uuid) {
                    additionalParams.line_uuids = [this.props.filter.selected_line_uuid];
                }

                const order_external_ids: string[] = [];
                const order_external_id: string = this.props.filter.order_external_id;

                if (order_external_id && typeof order_external_id === "string")  {
                    order_external_ids.push(order_external_id);
                    additionalParams.order_external_ids = order_external_ids;
                }

                const { materials } = await getBackend().manufacturing.getStockStatus({
                    direction: this.props.filter.selected_display_type,
                    plant_uuid: this.props.plant_uuid,
                    group_uuid: this.props.line_group_uuid,
                    include_zero_quantity: this.state.include_zero_quantity,
                    ...additionalParams
                });

                // sort by material titl.then(() => {
                materials.sort((a,b) => {
                    const s_cmp = stringCompare(a.material_title, b.material_title)
                    const stock_a = a.quantity + a.quantity_confirmed + a.quantity_unconfirmed;
                    const stock_b = b.quantity + b.quantity_confirmed + b.quantity_unconfirmed;
                    if (stock_a !== 0 && stock_b !== 0 || stock_a === 0 && stock_b === 0) {
                        return s_cmp
                    }
                    if (stock_a === 0 && stock_b !== 0) {
                        return 1;
                    }
                    if (stock_a !== 0 && stock_b === 0) {
                        return -1;
                    }
                    return 0;
                });
                // show only materials with non-zero stock or
                // derived materials where stock can be edited
                this.setState({ materials, isLoading: false });
            }
        } catch (err) {
            this.setState({ error: err.message, isLoading: false });
        }
    }

    showEditModal = async (material_uuid: string) => {
        const material = this.state.materials.find(m => m.material_uuid === material_uuid);
        const plant_uuid = this.props.plant_uuid;
        const quantity_being_produced = (await getBackend().manufacturing.getStockBeingProduced({
            uuid: material_uuid,
            plant_uuid
        })).quantity_being_produced;
        if (material !== undefined) {
            const quantity_complete_only = material.quantity + material.quantity_confirmed + material.quantity_unconfirmed;
            this.setState({
                show_edit_modal: true,
                edit_material_uuid: material.material_uuid,
                edit_material_title: material.material_title,
                edit_location_uuid: material.location_uuid,
                edit_location_title: material.location_title,
                edit_material_quantity_being_produced: quantity_being_produced,
                edit_material_quantity_being_produced_str: niceNumber(quantity_being_produced, 0),
                edit_material_quantity_complete_only: quantity_complete_only,
                edit_material_quantity_complete_only_str: niceNumber(quantity_complete_only, 0),
                edit_material_quantity_total: quantity_complete_only + quantity_being_produced,
                edit_material_quantity_total_str: niceNumber(quantity_complete_only + quantity_being_produced, 0)
            });
        }
    }

    saveEditModal = () => {
        this.setState({edit_saving: true },
            () => {
                getBackend().manufacturing.updateStockStatus({
                    location_uuid: this.state.edit_location_uuid,
                    material_uuid: this.state.edit_material_uuid,
                    quantity: this.state.edit_material_quantity_complete_only
                }).then(() => {
                    this.setState({
                        edit_saving: false,
                        show_edit_modal: false
                    });
                    this.loadComponent();
                });
            }
        );
    }

    hideEditModal = () => {
        this.setState({ show_edit_modal: false });
    }

    handleQuantityTotalChange = (event: Event) => {
        if (event.currentTarget instanceof HTMLInputElement) {
            let value = event.currentTarget.value;
            // set total, compute complete_only
            const total = parseFloatSmart(value, this.state.edit_material_quantity_total);
            const being_produced = this.state.edit_material_quantity_being_produced;
            const complete_only = total - being_produced;
            this.setState({
                edit_material_quantity_complete_only: complete_only,
                edit_material_quantity_complete_only_str: niceNumber(complete_only, 0),
                edit_material_quantity_total: total,
                edit_material_quantity_total_str: niceNumber(total, 0)
            });
        }
    };

    handleQuantityCompleteOnlyChange = (event: Event) => {
        if (event.currentTarget instanceof HTMLInputElement) {
            let value = event.currentTarget.value;
            // set complete_only, compute total
            const complete_only = parseFloatSmart(value, this.state.edit_material_quantity_complete_only);
            const being_produced = this.state.edit_material_quantity_being_produced;
            const total = complete_only + being_produced;
            this.setState({
                edit_material_quantity_complete_only: complete_only,
                edit_material_quantity_complete_only_str: niceNumber(complete_only, 0),
                edit_material_quantity_total: total,
                edit_material_quantity_total_str: niceNumber(total, 0)
            });
        }
    };

    toggleZeroQuantity = () => {
        this.setState({
            include_zero_quantity: !this.state.include_zero_quantity
        }, () => {
            this.loadComponent();
        });
    }

    renderEditModal() {
        return (
            <Modal bsSize="large" show={this.state.show_edit_modal} onHide={this.hideEditModal}>
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id="common.stock" defaultMessage="Stock" />: {this.state.edit_material_title}<br/>
                        <FormattedMessage id="common.stock_location" defaultMessage="Stock location" />: {this.state.edit_location_title}<br/>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <label>
                            <FormattedMessage id="common.quantity_being_produced" defaultMessage="Quantity being produced" />:
                        </label>
                        <input name="title" type="text" disabled="true" className="form-control"
                            value={this.state.edit_material_quantity_being_produced_str} />
                    </div>
                    <div className="form-group">
                        <label>
                            <FormattedMessage id="common.quantity_complete" defaultMessage="Quantity complete only" />:
                        </label>
                        <input name="title" type="text" disabled="" className="form-control"
                            value={this.state.edit_material_quantity_complete_only_str} onChange={this.handleQuantityCompleteOnlyChange} onKeyDown={this.handleQuantityCompleteOnlyChange}/>
                    </div>
                    <hr />
                    <div className="form-group">
                        <label>
                            <FormattedMessage id="common.quantity_complete_and_being_produced" defaultMessage="Quantity complete and being produced" />:
                        </label>
                        <input name="title" type="text" disabled="" className="form-control"
                            value={this.state.edit_material_quantity_total_str} onChange={this.handleQuantityTotalChange} onKeyDown={this.handleQuantityTotalChange}/>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={this.saveEditModal} disabled={this.state.edit_saving} className="btn btn-outline-primary">
                        <FormattedMessage id="common.save" defaultMessage="Save" />
                    </button>
                    <button onClick={this.hideEditModal} disabled={this.state.edit_saving} className="btn btn-outline-secondary">
                        <FormattedMessage id="common.back" defaultMessage="Back" />
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }

    render() {
        return (
            <article className="article">
                <section id="statistics" className="data_sources">
                    <div className="vertical_tab">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12 col-md-12">
                                    {this.renderEditModal()}
                                    <ErrorComponent className="mt-0" msg={this.state.error} type="error" />
                                    <ErrorComponent
                                        className="mt-0"
                                        msg={translate(
                                            "common.stock_status_only_for_open_orders",
                                            "Stock status is shown only for materials with open orders."
                                        )}
                                        type="info"
                                    />
                                    {this.props.filter.selected_display_type === "out" &&
                                        <StockStatusViewTable
                                            isLoading={this.state.isLoading}
                                            type={"out"}
                                            materials={this.state.materials}
                                            showEditModal={this.showEditModal}
                                            plant_uuid={this.props.plant_uuid}
                                            line_group_uuid={this.props.line_group_uuid}
                                            history={this.props.history}
                                            location={this.props.location}
                                            toggleZeroQuantity={this.toggleZeroQuantity}
                                            include_zero_quantity={this.state.include_zero_quantity}
                                        />
                                    }
                                    {this.props.filter.selected_display_type === "in" &&
                                        <StockStatusViewTable
                                            isLoading={this.state.isLoading}
                                            type={"in"}
                                            materials={this.state.materials}
                                            showEditModal={this.showEditModal}
                                            plant_uuid={this.props.plant_uuid}
                                            line_group_uuid={this.props.line_group_uuid}
                                            history={this.props.history}
                                            location={this.props.location}
                                            toggleZeroQuantity={this.toggleZeroQuantity}
                                            include_zero_quantity={this.state.include_zero_quantity}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </article >
        );
    }
}

export default withRouter(StockStatusView);
