// @flow

// main imports
import ReactRouterPropTypes from "react-router-prop-types";

// models

// load subcomponents
import { BatchopLocationCreate } from "./BatchopLocation.create";

// backend
import { getBackend } from "../../lib/backend/Backend2";
import { translate } from "../IntlProviderWrapper";

// type definitions
import type { State } from "./BatchopLocation.create";
import type { ITags } from "../../lib/Models";
type Props = {
    match: ReactRouterPropTypes.match,
    history: ReactRouterPropTypes.history,
    location: ReactRouterPropTypes.location
}

/**
 * This objects extends line create. It only changes the way the data is loaded.
 */
class BatchopLocationEdit extends BatchopLocationCreate {
    /**
     * Constructor.
     *
     * @param {Props} props Props object.
     * @param {State} state State object.
     */
    constructor(props: Props, state: State) {
        super(props, state);
        // TODO: can this be ommited, since it is used in super?
        this.state = {
            uuid: "",
            title: "",
            group_uuid: "",
            size: 0,
            tags: {},
            groups: [],
            message: "",
            componentTitle: translate("Manufacturing.BatchopLocations.add_new_location", "Add New Location"),
            error: "",
            warning: ""
        }
    }

    /**
     * Loading the component (plants and lines data).
     */
    async componentDidMount() {
        try {
            const groups = (await getBackend().batchOperations.getGroups({})).items;

            this.setState({
                groups: groups,
                group_uuid: groups[0].uuid
            });

            // loading data for the line
            const { uuid } = this.props.match.params;
            let locations = (await getBackend().batchOperations.getLocations({})).items
                .filter((x) => x.uuid === uuid);
            const data = locations[0];

            let tags: ITags = {
                // no tags
            };

            if (data.tags != null) {
                Object.keys(tags).forEach((el) => {
                    if ((data.tags) && (data.tags.hasOwnProperty(el))) {
                        tags[el] = data.tags[el];
                    }
                })
            }

            this.setState({
                uuid: data.uuid,
                title: data.name,
                group_uuid: data.group_uuid,
                tags: data.tags,
                size: data.size
            })
        } catch (e) {
            this.setState({ error: e });
        }
    }
}

export default BatchopLocationEdit;