// @flow
import { RESET_GANTT } from "./common";
import { RESET_DRAGGED_ORDER } from "./common";

import type { ResetDraggedOrder } from "./common";
import type { PayloadAction } from "../../../redux/types";
import type { ResetGanttAction } from "./common";
import { deepClone } from "../../../../lib/Util";

export const UPDATE_DRAGGED_ORDER_ACTION_NAME: "update_dragged_order" = "update_dragged_order";

export type DraggedOrderState = {
    order_dx: number,
    order_dy: number,
    initial_x0: number,
    initial_y0: number,
    translate_x: number | null,
    translate_y: number | null,
    original_order_index: number | null,
    original_line_index: number | null,
    original_line_uuid: string | null,
    current_shift_number: number | null,
    current_line_index: number | null,
    original_shift_number: number | null,
    order_uuid: string | null,
    is_unscheduled: boolean,
    reset_at?: number | null
}

export type UpdateDraggedOrderAction = PayloadAction<typeof UPDATE_DRAGGED_ORDER_ACTION_NAME, DraggedOrderState>;

export const dragged_order_initial_state: DraggedOrderState = {
    order_dx: 0,
    order_dy: 0,
    initial_x0: 0,
    initial_y0: 0,
    original_order_index: null,
    original_line_index: null,
    original_line_uuid: null,
    original_shift_number: null,
    current_shift_number: null,
    current_line_index: null,
    translate_x: null,
    translate_y: null,
    order_uuid: null,
    is_unscheduled: false,
    reset_at: null
}

export type DraggedOrderActions = UpdateDraggedOrderAction | ResetGanttAction | ResetDraggedOrder;

type GanttCharttContentBoundingBox = {
    min_x: number,
    min_y: number,
    max_y: number
}

export const getGanttChartContentBoundingBox = (): GanttCharttContentBoundingBox => {
    const gantt_area = "#pane1 ";
    let min_x = 0;
    let min_y = 0;
    let max_y = 0;

    const error_margin = 50;
    const upper_line = document.querySelector(gantt_area + "#lines-region #upper");
    if (upper_line) {
        min_y = parseInt(upper_line.getAttribute("y1")) - error_margin;
    }

    const lower_lines = document.querySelectorAll(gantt_area + "#lines-region #lower");
    if (lower_lines && lower_lines.length > 0) {
        max_y = parseInt(lower_lines[lower_lines.length - 1].getAttribute("y1"));
    }

    const first_shift_el = document.querySelector(gantt_area + "line[gridline-shift-index]");
    if (first_shift_el) {
        min_x = parseInt(first_shift_el.getAttribute("x1")) - error_margin;
    }

    return { min_x, min_y, max_y };
}

export const draggedOrderReducer = (
    state: DraggedOrderState,
    action: DraggedOrderActions
): DraggedOrderState => {
    if (!state) {
        state = deepClone(dragged_order_initial_state);
    }
    let new_state = state;

    if (action.type === RESET_GANTT) {
        new_state = deepClone(dragged_order_initial_state);
    } else if (action.type === UPDATE_DRAGGED_ORDER_ACTION_NAME) {
        new_state = {...new_state, ...action.data};
    } else if (action.type === RESET_DRAGGED_ORDER) {
        new_state = deepClone(dragged_order_initial_state);
        new_state.reset_at = (new Date()).getTime();
        new_state = { ...new_state };
    }

    return new_state;
}
