// @flow
import * as React from "react";
import { FormattedMessage } from "react-intl";
import ReactRouterPropTypes from "react-router-prop-types";
import { Modal } from "react-bootstrap";

import { dateFromDayShift, shiftNumber } from "../../lib/Util";

import { getBackend as getBackend2 } from "../../lib/backend/Backend2";
import * as t from "../../lib/backend/manufacturing2.generated.types";

import InsightsList from "./InsightsList";
import ErrorComponent from "../ErrorComponent";
import Loader from "../Loader";
import * as BusinessLogic from "../../lib/BusinessLogic";
import { LINE_GROUP_TAGS, INSIGHT_TYPES } from "../../lib/ManufacturingTags.generated";

type Props = {
    history: ReactRouterPropTypes.history,
    show: boolean,
    onHide: () => void,
    line_uuid: string,
    line_title: string
};

type State = {
    insights: t.IEventDataEx[],
    report1: t.ISimulationReportData | null,
    insights_loaded: boolean,
    error: string
};

class LineInsightListModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        // get current week and year
        this.state = {
            insights: [],
            report1: null,
            insights_loaded: false,
            error: ""
        }
    }

    componentDidMount() {
        if (this.props.show && !this.state.insights_loaded) {
            this.loadInsights();
        }
    }

    componentDidUpdate(prevProps: Props) {
        // check if props changed to "Show" modal dialog and (data not loaded or line changed)
        if (((this.props.show !== prevProps.show) && this.props.show) &&
            (!this.state.insights_loaded || prevProps.line_uuid !== this.props.line_uuid)) {

            this.setState(
                { insights_loaded: false },
                () => { this.loadInsights(); });
        }
    }

    async loadInsights() {
        try {
            // default insights related to having a plan
            const insight_types: string[] = [
                INSIGHT_TYPES.freeze_order_move,
                INSIGHT_TYPES.operation_constraint_violated,
                INSIGHT_TYPES.tool_setup
            ];
            // $FlowFixMe
            const line_group_uuid = BusinessLogic.getLineGroupForLine(this.props.line_uuid).uuid;
            // insights that require stock forecast
            if (BusinessLogic.getLineGroupTagBool(line_group_uuid, LINE_GROUP_TAGS.stock_forecast, true)) {
                insight_types.push(INSIGHT_TYPES.operation_delay_downstream);
                insight_types.push(INSIGHT_TYPES.operation_no_input_material);
                insight_types.push(INSIGHT_TYPES.out_of_stock_shopfloor);
                insight_types.push(INSIGHT_TYPES.out_of_stock_shopfloor);
            }
            // insights that require people schedule
            if (BusinessLogic.getLineGroupTagBool(line_group_uuid, LINE_GROUP_TAGS.shift_board, true)) {
                insight_types.push(INSIGHT_TYPES.no_person);
                insight_types.push(INSIGHT_TYPES.no_shift);
                insight_types.push(INSIGHT_TYPES.no_work_in_shift);
            }
            // prepare request
            const current_date = new Date();
            const current_shift_day = shiftNumber(current_date).shift_day;
            const start_of_current_shift = dateFromDayShift(current_date, current_shift_day);
            const insight_criteria: t.IGetInsightsReq = {
                from: (start_of_current_shift.getTime()),
                types: insight_types,
                lines: [this.props.line_uuid]
            }
            const res = await getBackend2().manufacturing.getInsights(insight_criteria);
            this.setState({
                insights: res.insights,
                report1: res.report1,
                insights_loaded: true
            });
        } catch (err) {
            this.setState({
                insights_loaded: true,
                error: err.message
            });
        }
    }

    renderInsights() {
        const rep = this.state.report1;
        if (rep !== null && this.state.insights.length > 0) {
            return <InsightsList
                history={this.props.history}
                insights={this.state.insights}
                reportid={rep.uuid}
                week={rep.shift_time.week_number}
                year={rep.shift_time.year}
                show_filter={false}
                show_only_future={false}
                show_links={false}
                show_actions={true}
                />;
        } else {
            return <FormattedMessage
                id="Manufacturing.LineInsightListModal.no_insights"
                defaultMessage="No insights available for this line" />
        }
    }

    // prepare list of datasources
    render() {
        return (
            <Modal bsSize="large" show={this.props.show} onHide={this.props.onHide}>
                <Modal.Body>
                    <ErrorComponent msg={this.state.error} type="error" />
                    {this.state.insights_loaded ? this.renderInsights() : (<Loader />)}
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={this.props.onHide} className="btn btn-outline-secondary">
                        <FormattedMessage id="common.back" defaultMessage="Back" />
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default LineInsightListModal;
