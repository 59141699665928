// @flow
import * as React from "react";
import { FormattedMessage } from "react-intl";

import { getBackend as getBackend2 } from "../lib/backend/Backend2";
import * as t from "../lib/backend/manufacturing2.generated.types";

import KpiBox from "./KpiBox";
import Loader from "./Loader";
import KeyStatus from "./KeyStatus";
import Statistics from "./Statistics";
import { PERMISSION_NAMES } from "../lib/Auth";
import * as Auth from "../lib/Auth";
import { translate } from "./IntlProviderWrapper";

type Props = {};

type State = {
    error: string | null,
    loadingSettings: boolean,
    manStats: t.IGetStatsRes | null,
    renderStatistics: boolean
};

class SysStatus extends React.Component<Props, State> {

    constructor() {
        super();
        const initialState: State = {
            error: null,
            loadingSettings: true,
            manStats: null,
            renderStatistics: false
        };
        this.state = initialState;
    }

    componentDidMount() {
        this.loadStandardData();
    }

    loadStandardData() {
        getBackend2().manufacturing.getStats({})
            .then(man_stats => {
                this.setState({ manStats: man_stats });
                this.setState({ loadingSettings: false });
            })
            .catch(error => {
                this.setState({ error: error });
                this.setState({ loadingSettings: false });
            });
    }

    renderStatistics() {
        if(!this.state.renderStatistics) {
            return <button type="button" className="btn btn-primary mt-5" onClick={() => this.setState({renderStatistics: true})}>Open billing statistics</button>
        } else {
            return (
                <Statistics />
            );
        }
    }

    renderIndicatorsMan() {
        const stats = this.state.manStats
        if (!stats) {
            return <Loader small={true}></Loader>;
        }
        return [
            <h3 className="table_header" key="xyz125">
                <FormattedMessage id="common.manufacturing" defaultMessage="Manufacturing" />
            </h3>,
            <div className="row" key="xyz126">
                <div className="col-lg-3 col-md-6">
                    <KpiBox
                        label={translate("common.plants", "Plants")}
                        items={[{value: stats.plants_cnt, unit: ""}]}/>
                </div>
                <div className="col-lg-3 col-md-6">
                    <KpiBox
                        label={translate("common.linegroups", "Line-groups")}
                        items={[{value: stats.linegroups_cnt, unit: ""}]}/>
                </div>
                <div className="col-lg-3 col-md-6">
                    <KpiBox
                        label={translate("common.lines", "Lines")}
                        items={[{value: stats.lines_cnt, unit: ""}]}/>
                </div>
                <div className="col-lg-3 col-md-6">
                    <KpiBox
                        label={translate("common.production", "Production")}
                        items={[{value: stats.productions_24h_cnt, unit: ""}]}/>
                </div>
            </div>,
            <div className="row space20" key="xyz127">
                <div className="col-lg-3 col-md-6">
                    <KpiBox
                        label={translate("common.people", "People")}
                        items={[{value: stats.people_cnt, unit: ""}]}/>
                </div>
                <div className="col-lg-3 col-md-6">
                    <KpiBox
                        label={translate("common.materials", "Materials")}
                        items={[{value: stats.material_cnt, unit: ""}]}/>
                </div>
                <div className="col-lg-3 col-md-6">
                    <KpiBox
                        label={translate("common.processes", "Processes")}
                        items={[{value: stats.processes_cnt, unit: ""}]}/>
                </div>
                <div className="col-lg-3 col-md-6">
                    <KpiBox
                        label={translate("common.productions", "Productions")}
                        items={[{value: stats.productions_cnt, unit: ""}]}/>
                </div>
            </div>
        ];
    };

    renderStatus() {
        return <KeyStatus />
    }

    render() {
        if (this.state.error) {
            return (<div>{this.state.error}</div>);
        }
        if (this.state.loadingSettings) {
            return <Loader small={true}></Loader>;
        } else {
            return (
                <section id="statistics" key="1">
                    <div className="container">
                        {this.renderIndicatorsMan()}
                        {this.renderStatus()}
                        {Auth.hasPermission(PERMISSION_NAMES.AdminOnly) && this.renderStatistics()}
                    </div>
                </section>
            );
        }
    }
}

export default SysStatus;
