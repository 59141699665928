// @flow
import * as React from "react";
import { connect } from "react-redux";
import type { ReduxState } from "../reducers/reducers";
// $FlowFixMe
import { Modal } from "react-bootstrap";
import type { Filters, TableInstance } from "react-table";
import moment from "moment";
import ReactTable from "../../../react/ReactTable";
import { DateFilter } from "../../../react/ReactTable";
import { translate } from "../../../IntlProviderWrapper";
import { niceDate, niceNumber, niceDateTime } from "../../../../lib/Util";
import * as BusinessLogic from "../../../../lib/BusinessLogic";
import * as t from "../../../../lib/backend/manufacturing2.generated.types";
import { INSIGHT_TYPES, LINE_GROUP_TAGS_ACCESS } from "../../../../lib/ManufacturingTags.generated";
import {
    SelectedOrdersConsumer,
    ADD_ORDER,
    ADD_ORDERS,
    SET_SELECTED_ORDERS,
    RESET_SELECTED_ORDERS,
    SET_DISABLE_CHECKBOXES,
    SET_DISABLED_ORDERS
} from "./SelectedOrdersContext";
import { renderToString } from "react-dom/server";
import { PLAN_IMPORT_TYPE, ORDER_TYPE } from "../../../../lib/ManufacturingConsts.generated";
import ChecklistProgress from "../../../ChecklistProgress";
import { JOB_STATUS } from "../../../../lib/CommonConsts.generated";
import { ReportLogic } from "../reducers/BusinessLogic";
import { getBackend } from "../../../../lib/backend/Backend2";
import Tooltip from "../../../Tooltip";
import ErrorComponent from "../../../ErrorComponent";
import TimeProgress from "../../../TimeProgress";
import ConfirmationModal from "../../../ConfirmationModal";

import type { SelectedOrdersProviderValue, Actions } from "./SelectedOrdersContext";
import type { IGetJobStatusRes } from "../../../../lib/backend/common.generated.types";
import type {
    IReportExportOrderChange,
    IReportExportExtraLinesOrderChange,
    IRunPlanTableExportResultOutput
} from "../../../../lib/backend/reports.generated.types";
import type { OrderChangeEntry } from "./common";
import type { ReactTableColumn } from "../../../react/ReactTable";
import type { LineOrders } from "../reducers/linesOrders";
import type { Warning } from "../reducers/BusinessLogic";

type SyncAction = "sync_all" | "sync_selected" | "retry";
type SyncOperation = "save_shifts" | "save_to_sap" | "import_plan";

const STORAGE_KEY_SKIP_RETRY = "planning-table-skip-retry";
const STORAGE_KEY_REFRESH_WHEN_COMPLETE = "planning-table-refresh-when-complete";

const OP_STATUS_DEALLOCATE = "deallocate";

// columns
export const PRODUCTION_VERSION_COLUMN: "production_version" = "production_version";

type OrderChangesModalProps = {
    plant_uuid?: string,
    line_group_uuid: string,
    raw_order_changes: IReportExportOrderChange[],
    extra_lines_order_changes?: IReportExportExtraLinesOrderChange[],
    show_modal: boolean,
    save_report: IRunPlanTableExportResultOutput | null,
    saveShifts?: () => Promise<boolean | string>,
    saveToSap?: (filter_order_uuids: string[]) => Promise<boolean | string>,
    importPlan?: (import_type: string) => Promise<boolean | string>,
    onCloseModal: () => any,
    filter_order_external_id?: string | null,
    filter_line_title?: string | null,
    initial_filters?: Filters,
    disabled_order_external_ids?: string[],
    hidden_columns?: string[],
    modal_title?: string,
    orders?: t.IOrderProducedModel[],
    changed_shifts?: t.IShiftLineRec[],
    is_loading?: boolean,
    error?: string,
    import_job_status?: IGetJobStatusRes | null,
    insights?: t.IEventDataEx[] | null,
    warnings: Warning[],
    lines_orders?: LineOrders[] | null,
    unscheduled_orders?: LineOrders[],
    extra_lines?: t.IExtraLines[],
    selected_order_uuids?: string[],
    selectedOrdersDispatch?: (args: Actions) => void,
    current_linegroup_uuid: string
};

type OrderChangesModalState = {
    filter_failed: boolean,
    order_changes: OrderChangeEntry[] | null,
    order_uuids: ?string[],
    failed_order_uuids?: string[] | null,
    disabled_order_uuids: string[],
    filter_order_external_id: string | null,
    filter_line_title: string | null,
    action?: SyncAction,
    progress: number[] | null,
    sync_operations: SyncOperation[] | null,
    errors: string[],
    show_sync_modal: boolean,
    show_refresh_modal: boolean,
    num_changed_shifts: number,
    incorrect_op_order_uuids: string[] | null,
    plan_export_time: number,
    plan_import_time: number,
    skip_retry: boolean,
    refresh_when_complete: boolean,
};

type StatusCellArgs = {
    cell: any,
    data: OrderChangeEntry[],
    value: boolean
}

type ChangeValue = {
    changed: boolean,
    value: React.Node | string
}

const StatusCell = ({ cell, value }: StatusCellArgs): React.Node => {
    if (value === undefined) return "/";
    const {
        version_ok,
        quantity_ok,
        user_lock_name,
        user_lock_error,
        time_ok,
        workplace_ok,
        operation_external_id_ok
    } = cell.row.values;

    const title = `version_ok: ${version_ok}\nquantity_ok: ${quantity_ok}\ntime_ok: ${time_ok}\nworkplace_ok: ${workplace_ok}\noperation_external_id_ok: ${operation_external_id_ok ?? ""}\nuser_lock_error: ${user_lock_error}\nuser_lock_name: ${user_lock_name}`;

    return <React.Fragment>
        {value === true ?
            <i style={{ color: "#078270" }} className="fas fa-check-circle"></i> :
            <i style={{ cursor: "help", color: "#fd756d" }} title={title} className="fas fa-times-circle"></i>
        }
    </React.Fragment>
}

const selectOperations = (
    entries: OrderChangeEntry[],
    order_external_id: string | null,
    line_external_ids: string[] | null
): string[] => {
    let order_uuids = [];
    if (order_external_id !== null && line_external_ids === null) {
        for (const entry of entries) {
            if (entry.external_id === order_external_id) {
                order_uuids.push(entry.order_uuid);
            }
        }
    } else if (order_external_id === null && line_external_ids !== null) {
        const line_ids_set: Set<string> = new Set(line_external_ids);
        for (const entry of entries) {
            if (
                (entry.initial_line_external_id !== null && line_ids_set.has(entry.initial_line_external_id)) ||
                (entry.final_line_external_id !== null && line_ids_set.has(entry.final_line_external_id))
            ) {
                order_uuids.push(entry.order_uuid);
            }
        }
    } else if (order_external_id !== null && line_external_ids !== null) {
        const operation_uuids: Set<string> = new Set();
        const order_ids_set: Set<string> = new Set([order_external_id]);
        const line_ids_set: Set<string> = new Set(line_external_ids);
        let prev_order_ids_size = 0;
        let prev_line_ids_size = 0;
        while (prev_order_ids_size !== order_ids_set.size || prev_line_ids_size !== line_ids_set.size) {
            prev_order_ids_size = order_ids_set.size;
            prev_line_ids_size = line_ids_set.size;
            for (const entry of entries) {
                if (
                    order_ids_set.has(entry.external_id) ||
                    (entry.initial_line_external_id !== null && line_ids_set.has(entry.initial_line_external_id)) ||
                    (entry.final_line_external_id !== null && line_ids_set.has(entry.final_line_external_id))
                ) {
                    operation_uuids.add(entry.order_uuid);
                    order_ids_set.add(entry.external_id);
                    if (entry.initial_line_external_id !== null) {
                        line_ids_set.add(entry.initial_line_external_id);
                    }

                    if (entry.final_line_external_id !== null) {
                        line_ids_set.add(entry.final_line_external_id);
                    }
                }
            }
        }

        order_uuids = [...operation_uuids];
    }

    return order_uuids;
};

const SaveCheckbox = ({ cell, data }: StatusCellArgs) => {
    const {
        order_uuid
    } = cell.row.values;
    const line_group = localStorage.getItem("last-linegroup") || "";
    const line_group_tags = BusinessLogic.getLineGroupTags(line_group);
    const select_whole_orders = LINE_GROUP_TAGS_ACCESS.save_all_operations_to_erp(line_group_tags);
    const select_whole_lines = LINE_GROUP_TAGS_ACCESS.save_whole_lines_to_erp(line_group_tags);
    return <SelectedOrdersConsumer>
        {(value: ?SelectedOrdersProviderValue) => {
            if (value) {
                let checked = value.state.selected_order_uuids.includes(order_uuid);
                const is_failed_disabled = value.state.disable_order_uuids.includes(order_uuid);
                if (is_failed_disabled) {
                    return <i className="fas fa-times" style={{ color: "#d55353" }}></i>
                }

                return <input
                    key={order_uuid + "-" + checked.toString()}
                    type="checkbox"
                    id="save-checkbox"
                    name="checkbox_order_uuid"
                    className={is_failed_disabled ? "failed-disabled" : ""}
                    data-order-uuid={order_uuid}
                    disabled={value && value.state.disable_checkboxes || value.state.disable_order_uuids.includes(order_uuid)}
                    checked={checked}
                    onChange={() => {
                        if (value != undefined) {
                            if (select_whole_orders || select_whole_lines) {
                                const order_external_id = select_whole_orders
                                    ? cell.row.values.external_id
                                    : null;
                                const line_external_ids = select_whole_lines
                                    ? [cell.row.values.final_line_external_id, cell.row.values.initial_line_external_id].filter(id => id !== null)
                                    : null;
                                const order_uuids = selectOperations(data, order_external_id, line_external_ids);
                                value.dispatch({ type: ADD_ORDERS, data: order_uuids });
                            } else {
                                value.dispatch({ type: ADD_ORDER, data: order_uuid });
                            }
                        }
                    }}
                />
            }
        }}
    </SelectedOrdersConsumer>
}

const SaveAllVisibleOrders = (props: { table: TableInstance }) => {
    return <SelectedOrdersConsumer>
        {(value: ?SelectedOrdersProviderValue) => {
            const onAddOrClearSelectedOrders = (is_clear: boolean) => () => {
                if (!value) return;
                let visible_orders_external_ids_el = document.querySelectorAll(
                    "[name='checkbox_order_uuid']"
                );
                const order_uuids = [];
                if (visible_orders_external_ids_el) {
                    for (const el of Array.from(visible_orders_external_ids_el)) {
                        const order_uuid = el.getAttribute("data-order-uuid");
                        if (order_uuid) {
                            order_uuids.push(order_uuid)
                        }
                    }
                }

                let selected_orders = [];
                if (is_clear) {
                    for (const order_uuid of value.state.selected_order_uuids) {
                        if (order_uuids.includes(order_uuid)) continue;
                        selected_orders.push(order_uuid);
                    }
                } else {
                    selected_orders = [...value.state.selected_order_uuids, ...order_uuids];
                }

                value.dispatch({
                    type: SET_SELECTED_ORDERS,
                    data: [...new Set(selected_orders)]
                });
            }

            return <div style={{ display: "flex" }}>
                <span
                    className="btn btn-primary"
                    style={{ minWidth: "unset", width: "30px", marginRight: "5px" }}
                    disabled={value && value.state.disable_checkboxes}
                    title={translate("common.deselect_all", "Deselect all")}
                    onClick={onAddOrClearSelectedOrders(true)}
                >
                    <i className="fas fa-times"></i>
                </span>
                <span
                    className="btn btn-primary"
                    style={{ minWidth: "unset", width: "30px", marginRight: "5px" }}
                    disabled={value && value.state.disable_checkboxes}
                    title={translate("common.select", "Select")}
                    onClick={onAddOrClearSelectedOrders(false)}
                >
                    <i className="fas fa-check"></i>
                </span>
                <span
                    className="btn btn-secondary"
                    style={{ minWidth: "unset", width: "auto" }}
                    title={translate("common.clear_filters", "Clear filters")}
                    onClick={() => { props.table.setAllFilters([]) }}
                >
                    <i className="icon icon-filter-circle-xmark-solid"></i>
                </span>
            </div>
        }}
    </SelectedOrdersConsumer>
}

const HTMLFilterCell = ({ cell, value }: StatusCellArgs) => {
    if (value instanceof Object) {
        return value;
    }
    return <span dangerouslySetInnerHTML={{ __html: value }}></span>
}


const FilterProductionVersion = (rows, id, filterValue) => {
    return rows.filter(row => {
        const prod_ver_el = row.values["production_version"];
        if (prod_ver_el instanceof Object) {
            return (
                (prod_ver_el.props.initial_str || "").includes(filterValue) ||
                (prod_ver_el.props.final_str || "").includes(filterValue) ||
                filterValue === "!"
            );
        }
        return prod_ver_el ? prod_ver_el.includes(filterValue) : false;
    });
}

function filterLesserThan(rows, id, filterValue) {
    const max_value = moment(Number(filterValue)).endOf("day").toDate().getTime();
    return rows.filter(row => {
        const rowValue = row.values[`${id[0]}_ts`];
        return rowValue <= max_value;
    });
}

type InitialOrdersFunc = (has_orders: boolean) => ReactTableColumn<OrderChangeEntry>[];

export const getInitialOrderColumns: InitialOrdersFunc = (has_orders: boolean) => {
    const columns = [
        {
            Header: (table) => <SaveAllVisibleOrders table={table} />,
            accessor: "save_checkbox",
            disableFilters: true,
            Cell: SaveCheckbox
        },
        {
            Header: "",
            accessor: "external_id",
            disableFilters: false,
            filterInputPlaceholder: translate("OrderTableProduction.external_id", "Order ID"),
            Cell: HTMLFilterCell
        },
        {
            Header: translate("common.status", "Status"),
            accessor: "op_status_change",
            disableFilters: true
        },
        {
            Header: translate("common.quantity", "Quantity"),
            accessor: "quantity",
            disableFilters: true
        },
        {
            Header: "",
            filterInputPlaceholder: translate("OrderTableProduction.line_external_id", "Line ID"),
            accessor: "line_external_id",
            Cell: HTMLFilterCell
        },
        {
            Header: "",
            accessor: PRODUCTION_VERSION_COLUMN,
            disableFilters: false,
            filter: FilterProductionVersion,
            filterInputPlaceholder: translate("common.production_version", "Production version"),
            Cell: HTMLFilterCell
        },
        {
            Header: "",
            filterInputPlaceholder: translate("common.line", "Line"),
            accessor: "line_title",
            Cell: HTMLFilterCell
        },
        {
            Header: "",
            filterInputPlaceholder: translate("common.line", "Line"),
            accessor: "initial_line_title"
        },
        {
            Header: "",
            filterInputPlaceholder: translate("common.line", "Line"),
            accessor: "final_line_title"
        },
        {
            Header: translate("OrderTableProduction.line_external_id", "Line ID"),
            accessor: "final_line_external_id",
            disableFilters: true
        },
        {
            Header: translate("OrderTableProduction.line_external_id", "Line ID"),
            accessor: "initial_line_external_id",
            disableFilters: true
        },
        {
            Header: translate("OrderTableProduction.process_num", "Process number"),
            accessor: "process_num",
            disableFilters: true
        },
        {
            Header: "",
            filterInputPlaceholder: translate("common.material", "Material"),
            accessor: "material_title"
        },
        {
            Header: translate("Manufacturing.Planning.number_of_capacities", "Capacities[%]"),
            accessor: "capacity_factor",
            disableFilters: true
        },
        {
            Header: "",
            accessor: "epoch_start",
            filter_type: "date",
            filter: filterLesserThan,
            filterInputPlaceholder: translate("common.start_verb", "Start"),
            DateFilter: DateFilter,
            disableFilters: false
        },
        {
            Header: "",
            accessor: "epoch_end",
            filter_type: "date",
            filter: filterLesserThan,
            filterInputPlaceholder: translate("common.end", "End"),
            DateFilter: DateFilter,
            disableFilters: false
        },
        {
            Header: "has_changes",
            accessor: "has_changes",
            disableFilters: true
        },
        {
            Header: "version_ok",
            accessor: "version_ok",
            disableFilters: true
        },
        {
            Header: "quantity_ok",
            accessor: "quantity_ok",
            disableFilters: true
        },
        {
            Header: "user_lock_error",
            accessor: "user_lock_error",
            disableFilters: true
        },
        {
            Header: "time_ok",
            accessor: "time_ok",
            disableFilters: true
        },
        {
            Header: "workplace_ok",
            accessor: "workplace_ok",
            disableFilters: true
        },
        {
            Header: "operation_external_id_ok",
            accessor: "operation_external_id_ok",
            disableFilters: true
        },
        {
            Header: "user_lock_name",
            accessor: "user_lock_name",
            disableFilters: true
        },
        {
            Header: "stats",
            accessor: "stats",
            disableFilters: true
        },
        {
            Header: "order_uuid",
            accessor: "order_uuid",
            disableFilters: true
        },
        {
            Header: "epoch_end_ts",
            accessor: "epoch_end_ts"
        },
        {
            Header: "epoch_start_ts",
            accessor: "epoch_start_ts"
        }
    ];

    if (has_orders) {
        columns.push({
            Header: "",
            filterInputPlaceholder: translate("common.maintained", "Maintained"),
            accessor: "scheduling_owner",
            disableFilters: false
        });
    }

    columns.push({
        Header: translate("common.status", "Status"),
        accessor: "status",
        Cell: StatusCell,
        disableFilters: true
    });

    return columns;

}

const cellEl = (initial_str: string, final_str: string, use_inner_html: boolean = false) => {
    const el = <React.Fragment>
        <span>{initial_str}</span>
        <span style={{ margin: "3px" }}>/</span>
        <div className="planning-table-changes-highlighted">
            <span>{final_str || "N/A"}</span>
        </div>
    </React.Fragment>;

    if (use_inner_html) {
        return renderToString(el);
    }

    return el;
}

const warningCellEl = (initial_str: string, final_str: string, use_inner_html: boolean = false, warning: Warning) => {
    return <div initial_str={initial_str} final_str={final_str}>
        <Tooltip content={<div>{warning.warning}</div>} >
            <div>
                {
                    initial_str === final_str ?
                        final_str :
                        cellEl(initial_str, final_str, use_inner_html)
                }
                <i className="fas fa-exclamation-circle" style={{ marginLeft: "5px" }}></i>
            </div>
        </Tooltip>
    </div>
}

const rowStyle = (row: any) => {
    let style = {};

    let cell = row.cells.find(cell => cell.column.id === "final_line_external_id");
    if (cell && cell.value === null) {
        style["backgroundColor"] = "#ff8e8e21"
    }

    let cell2 = row.cells.find(cell => cell.column.id === "initial_line_external_id");
    if (cell2 && cell2.value === null && cell.value !== null) {
        style["backgroundColor"] = "#e5f5ff"
    }

    let cell3 = row.cells.find(cell => cell.column.id === "has_changes");
    if (cell3 && cell3.value === false) {
        style["backgroundColor"] = "#d2d2d25e";
    } else {
        style["backgroundColor"] = "unset";
    }

    const save_checkbox_cell = row.cells.find(cell => cell.column.id === "save_checkbox");
    if (save_checkbox_cell !== undefined && save_checkbox_cell.value === "extra") {
        style.backgroundColor = "rgb(247, 237, 208, 0.5)";
    }

    return style;
}

type OrderChangesModalSyncButtonProps = {
    disabled_order_uuids: string[],
    failed_order_uuids?: string[] | null,
    line_group_uuid: string,
    value: ?SelectedOrdersProviderValue,
    disabled_order_uuids: string[],
    className?: string,
    disabled: boolean,
    action: SyncAction,
    onClick: (action: SyncAction, order_external_ids?: string[] | null) => () => Promise<void>
};

type OrderChangesModalSyncButtonState = {};

class OrderChangesModalSyncButton extends React.Component<OrderChangesModalSyncButtonProps, OrderChangesModalSyncButtonState> {

    componentDidMount() {
        if (this.props.action !== "sync_all") {
            return;
        }

        this.load();
    }

    componentDidUpdate(prev_props: OrderChangesModalSyncButtonProps) {
        if (this.props.action !== "sync_all") {
            return;
        }

        if (prev_props.disabled_order_uuids !== this.props.disabled_order_uuids) {
            this.load();
        }

        // after save we need to disable the checkboxes
        if (prev_props.failed_order_uuids !== this.props.failed_order_uuids && this.props.failed_order_uuids &&
            this.props.value && this.props.value.state.disable_checkboxes === false) {
            this.props.value.dispatch({ type: SET_DISABLE_CHECKBOXES, data: true });
        }
    }

    load() {
        if (this.props.value && this.props.disabled_order_uuids) {
            this.props.value.dispatch({ type: SET_DISABLED_ORDERS, data: this.props.disabled_order_uuids });
        }
    }

    componentWillUnmount() {
        if (this.props.action !== "sync_all") {
            return;
        }

        // reset selected_order_uuids on unmount
        if (this.props.value) {
            this.props.value.dispatch({ type: RESET_SELECTED_ORDERS, data: undefined });
        }
    }
    render() {
        const line_group_tags = BusinessLogic.getLineGroupTags(this.props.line_group_uuid);
        const enable_save_to_sap = LINE_GROUP_TAGS_ACCESS.planning_table_save_to_erp(line_group_tags);

        // if filter order uuids is not empty, means the save has already been initiated
        // and the failed_order_uuids variable contains the orders that need to be retried
        // if filter order uuids is empty, mean sthe save has not been initiated and
        // the selected_order_uuids become relevant

        const is_retry = this.props.failed_order_uuids;
        const value = this.props.value;

        let order_uuids = this.props.failed_order_uuids;
        let selected_order_uuids;
        if (!is_retry && value) {
            order_uuids = value.state.selected_order_uuids;
            selected_order_uuids = value.state.selected_order_uuids;
        }

        const is_disabled = (
            this.props.disabled ||
            !enable_save_to_sap ||
            BusinessLogic.isQlectorUserOnProd() ||
            is_retry && this.props.failed_order_uuids && this.props.failed_order_uuids.length === 0 ||
            !is_retry && selected_order_uuids && selected_order_uuids.length === 0
        );

        let label;

        switch (this.props.action) {
            case "sync_all":
                label = translate("common.save_selected_and_synchronize_all", "Save selected and synchronize all");
                break;
            case "sync_selected":
                label = translate("common.synchronize_selected", "Synchronize selected");
                break;
            default:
                label = translate("common.retry", "Retry");
                break;
        }

        return (
            <button
                className={`btn btn-primary ${this.props.className || ""}`}
                onClick={this.props.onClick(this.props.action, order_uuids)}
                disabled={is_disabled}
            >
                {label}
            </button>
        );
    }
}

type SyncModalProps = {
    show: boolean,
    action: SyncAction,
    orders?: t.IOrderProducedModel[],
    incorrect_op_order_uuids: string[] | null,
    onAccept: () => Promise<void>,
    onClose: () => void
};

const SyncModal = ({
    show,
    action,
    orders,
    incorrect_op_order_uuids,
    onAccept,
    onClose
}: SyncModalProps) => {
    let incorrect_order_operations = null;

    if (orders && incorrect_op_order_uuids) {
        incorrect_order_operations = [];
        for (const order of orders) {
            if (incorrect_op_order_uuids.includes(order.uuid)) {
                incorrect_order_operations.push(order);
            }
        }
    }

    const external_id_tr = translate("OrderTableProduction.external_id", "Order ID");
    const process_number_tr = translate("OrderTableProduction.process_num", "Process number");
    return (
        <Modal dialogClassName="modal-dialog-scrollable" show={show}>
            <Modal.Header>
                <Modal.Title>
                    <span>{action === "sync_all"
                        ? translate("common.save_selected_and_synchronize_all", "Save selected and synchronize all")
                        : translate("common.synchronize_selected", "Synchronize selected")}</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {translate("common.are_you_sure", "This action will overwrite your current changes. Are you sure you want to continue?")}
                {incorrect_order_operations &&
                    <ErrorComponent type="warning" msg={translate("OrderTableProduction.operations_are_in_incorrect_order", "The following operations are in incorrect order") + ":"}>
                        <ul className="pl-3 mb-0">
                            {incorrect_order_operations.map(order => (
                                <li>
                                    {`${external_id_tr}: ${order.external_id}, ${process_number_tr}: ${order.process_num}`}
                                </li>
                            ))}
                        </ul>
                    </ErrorComponent>
                }
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-primary" onClick={onAccept} autoFocus>
                    {translate("common.yes", "Yes")}
                </button>
                <button className="btn btn-outline-secondary" onClick={onClose}>
                    {translate("common.no", "No")}
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export class OrderChangesModal extends React.Component<OrderChangesModalProps, OrderChangesModalState> {

    constructor(props: OrderChangesModalProps) {
        super(props);

        const skip_retry = localStorage.getItem(STORAGE_KEY_SKIP_RETRY) === "true";
        const refresh_when_complete = localStorage.getItem(STORAGE_KEY_REFRESH_WHEN_COMPLETE) === "true";
        const state: OrderChangesModalState = {
            filter_failed: false,
            order_changes: null,
            order_uuids: null,
            failed_order_uuids: null,
            disabled_order_uuids: [],
            filter_order_external_id: null,
            filter_line_title: null,
            progress: null,
            sync_operations: null,
            errors: [],
            show_sync_modal: false,
            show_refresh_modal: false,
            num_changed_shifts: 0,
            incorrect_op_order_uuids: null,
            plan_export_time: 0,
            plan_import_time: 0,
            skip_retry,
            refresh_when_complete
        };
        this.state = state;
    }

    componentDidMount() {
        if (this.props.raw_order_changes && this.props.raw_order_changes.length > 0) {
            this.updateOrderChanges();
        }
    }

    componentDidUpdate(prev_props: OrderChangesModalProps) {

        if (prev_props.save_report !== this.props.save_report && this.props.save_report ||
            prev_props.raw_order_changes !== this.props.raw_order_changes && this.props.raw_order_changes) {
            this.updateOrderChanges();
        }
    }

    getChangedDate = (initial: string | null, final: string | null): ChangeValue => {
        const initial_date = initial ? niceDate(new Date(initial)) : "N/A";

        if (initial === final) {
            return {
                changed: false,
                value: initial_date
            };
        }

        const date = final ? niceDate(new Date(final)) : "N/A";

        return {
            changed: true,
            value: cellEl(initial_date, date)
        };
    }

    getChangedDateTime = (initial: number | null, final: number | null): ChangeValue => {
        const trimSeconds = (date_str) => date_str.substring(0, date_str.lastIndexOf(":"))

        const initial_out = initial ? trimSeconds(niceDateTime(new Date(initial))) : "N/A";
        const final_out = final ? trimSeconds(niceDateTime(new Date(final))) : "N/A";

        if (initial_out === final_out) {
            return {
                changed: false,
                value: initial_out
            }
        }

        return {
            changed: true,
            value: cellEl(initial_out, final_out)
        }
    }

    getChangedBoolean = (initial: boolean | null, final: boolean | null): ChangeValue => {
        const booleanToText = (b: boolean | null) => {
            return b ? translate("common.yes", "Yes") : translate("common.no", "No");
        }

        const initial_out = booleanToText(initial);
        const final_out = booleanToText(final);

        if (initial === final) {
            return {
                changed: false,
                value: initial_out
            }
        }

        return {
            changed: true,
            value: cellEl(initial_out, final_out)
        };
    }

    getChangedNumber = (initial: number | null, final: number | null): ChangeValue => {
        const initial_out = initial !== null ? niceNumber(initial, 2) : "N/A";
        const final_out = final !== null ? niceNumber(final, 2) : "N/A";

        if (initial_out === final_out) {
            return {
                changed: false,
                value: initial_out
            }
        }

        return {
            changed: true,
            value: cellEl(initial_out, final_out)
        }
    }

    getCapacities = (initial: number | null, final: number | null): ChangeValue => {
        let initial_str = "N/A";
        if (initial) {
            initial_str = niceNumber(100 * initial, 0);
        }

        let final_str = "N/A";
        if (final) {
            final_str = niceNumber(100 * final, 0);
        }

        if (initial === final) {
            return {
                changed: false,
                value: initial_str
            };
        }

        return {
            changed: true,
            value: cellEl(initial_str, final_str)
        };
    }

    getChangedText = (initial: string | null, final: string | null, use_inner_html: boolean = false, warning?: Warning): ChangeValue => {
        initial = initial ? initial : "N/A";
        final = final ? final : "N/A";

        if (initial === final) {
            return {
                changed: false,
                value: warning ? warningCellEl(initial, final, false, warning) : initial
            };
        }

        return {
            changed: true,
            value: warning ? warningCellEl(initial, final, false, warning) : cellEl(initial, final, use_inner_html)
        };
    }

    updateOrderChanges = () => {
        let orders = [];
        let failed_order_uuids = null;
        const disabled_order_uuids = [];
        const unscheduled_operations_marked_unchanged = LINE_GROUP_TAGS_ACCESS.unscheduled_operations_marked_unchanged(
            BusinessLogic.getLineGroupTags(this.props.current_linegroup_uuid|| "")
        );

        // raw_order_changes contains changed data from planning table
        for (const order of this.props.raw_order_changes) {
            for (const o of order.op_changes) {
                const produced_order = this.props.orders ? this.props.orders.find(or => or.uuid === o.order_uuid) : null;
                const is_disabled = this.props.disabled_order_external_ids ? this.props.disabled_order_external_ids.includes(order.order_external_id) : false;
                const epoch_end_ts = o.final_epoch_end || o.initial_epoch_end;
                const epoch_start_ts = o.final_epoch_start || o.initial_epoch_start;

                let status_change = this.getChangedText(o.op_status_initial, o.op_status_change, false);
                let quantity = (o.order_type === ORDER_TYPE.chunk) ?
                    this.getChangedNumber(o.initial_chunk_total_quantity, o.final_chunk_total_quantity) :
                    this.getChangedNumber(order.initial_quantity, order.final_quantity);
                let line_external_id = this.getChangedText(o.initial_line_external_id, o.final_line_external_id, true);
                let capacities = this.getCapacities(o.initial_capacity_factor, o.final_capacity_factor);
                let epoch_start = this.getChangedDateTime(o.initial_epoch_start, o.final_epoch_start);
                let epoch_end = this.getChangedDateTime(o.initial_epoch_end, o.final_epoch_end);
                let line_title = this.getChangedText(o.initial_line_title, o.final_line_title, true);
                const warning = this.props.warnings.find(
                    w => w.order_uuid === o.order_uuid && w.field === PRODUCTION_VERSION_COLUMN
                );
                let production_version = this.getChangedText(
                    order.initial_production_version,
                    order.final_production_version,
                    true,
                    warning
                );
                let scheduling_owner = translate("common.no", "No");

                if (produced_order) {
                    const scheduling_owner_value = produced_order.tags.scheduling_owner === "true";
                    scheduling_owner = this.getChangedBoolean(scheduling_owner_value, scheduling_owner_value).value;
                }

                if (is_disabled) {
                    quantity = this.getChangedNumber(order.final_quantity, order.final_quantity);
                    line_external_id = this.getChangedText(o.final_line_external_id, o.final_line_external_id, true);
                    capacities = this.getCapacities(o.final_capacity_factor, o.final_capacity_factor);
                    epoch_start = this.getChangedDateTime(o.final_epoch_start, o.final_epoch_start);
                    epoch_end = this.getChangedDateTime(o.final_epoch_end, o.final_epoch_end);
                    line_title = this.getChangedText(o.final_line_title, o.final_line_title, true);
                    const warning = this.props.warnings.find(
                        w => w.order_uuid === o.order_uuid && w.field === PRODUCTION_VERSION_COLUMN
                    );
                    production_version = this.getChangedText(
                        order.final_production_version,
                        order.final_production_version,
                        true,
                        warning
                    );
                }

                const has_changes =
                    !is_disabled && (
                        // if unscheduled_operations_marked_unchanged linegroup tag is true
                        // check if intitial status = final status = deallocate
                        // in this case the opeartion should not be marked as changed
                        !unscheduled_operations_marked_unchanged || (o.op_status_initial != OP_STATUS_DEALLOCATE || o.op_status_change != OP_STATUS_DEALLOCATE)
                    ) && (
                        quantity.changed || line_external_id.changed || capacities.changed || epoch_start.changed ||
                        epoch_end.changed || production_version.changed || status_change.changed
                    );

                let sap_order = {};
                let status_export_synced;

                if (this.props.save_report) {
                    // compare orders from planning table with orders that we recieve from sap
                    const order_change_report = this.props.save_report.order_change_reports.find(
                        ocr => ocr.order === order.order_external_id
                    );

                    if (order_change_report) {
                        const change = order_change_report.op_change_reports.find(
                            ocr => ocr.order === order.order_external_id && ocr.process_num === o.process_num
                        ) || {};

                        sap_order = {
                            version_ok: order_change_report.version_ok,
                            quantity_ok: order_change_report.quantity_ok,
                            user_lock_error: order_change_report.user_lock_error,
                            user_lock_name: order_change_report.user_lock_name,
                            ...change
                        };

                        status_export_synced = (
                            order_change_report.version_ok &&
                            order_change_report.quantity_ok &&
                            !order_change_report.user_lock_error &&
                            change.time_ok &&
                            change.workplace_ok &&
                            (change.operation_external_id_ok ?? true)
                        );
                    }
                }

                // a combination of changed orders from planning table and orders data from sap
                orders.push({
                    save_checkbox: undefined, // controlled by consumer
                    external_id: order.order_external_id,
                    quantity: quantity.value,
                    op_status_change: status_change.value,
                    line_external_id: line_external_id.value,
                    line_title: line_title.value,
                    final_line_external_id: o.final_line_external_id,
                    initial_line_external_id: o.initial_line_external_id,
                    material_title: o.material_title,
                    process_num: o.process_num,
                    capacity_factor: capacities.value,
                    epoch_start: epoch_start.value,
                    epoch_end: epoch_end.value,
                    has_changes: has_changes,
                    status: status_export_synced,
                    order_uuid: o.order_uuid,
                    failed_scheduling: o.failed_scheduling,
                    production_version: production_version.value,
                    epoch_end_ts,
                    epoch_start_ts,
                    scheduling_owner,
                    ...sap_order
                });

                if (o.failed_scheduling) {
                    disabled_order_uuids.push(o.order_uuid);
                }

                if (status_export_synced !== undefined && failed_order_uuids === null) {
                    failed_order_uuids = [];
                }

                if (status_export_synced === false && failed_order_uuids && !failed_order_uuids.includes(o.order_uuid)) {
                    failed_order_uuids.push(o.order_uuid);
                }
            }
        }

        orders.sort((a, b) => {
            if (a.has_changes === b.has_changes) return 0;
            if (a.has_changes) return -1;
            return 1;
        });

        this.setState({
            order_changes: orders,
            failed_order_uuids,
            disabled_order_uuids
        }, () => {
            this.updateExtraLineOrderChanges();
        });
    }

    updateExtraLineOrderChanges = async () => {
        const extra_lines_order_changes = this.props.extra_lines_order_changes || [];
        let failed_order_uuids = this.state.failed_order_uuids != null ? new Set(this.state.failed_order_uuids) : null;
        const disabled_order_uuids = new Set(this.state.disabled_order_uuids);
        const extra_order_changes: OrderChangeEntry[] = [];
        for (const order of extra_lines_order_changes) {
            const is_disabled = this.props.disabled_order_external_ids
                ? this.props.disabled_order_external_ids.includes(order.order_external_id)
                : false;
            const quantity = this.getChangedNumber(order.quantity, order.quantity);
            const line_external_id = this.getChangedText(order.line_external_id, order.line_external_id, true);
            const line_title = this.getChangedText(order.line_title, order.line_title, true);
            const capacities = this.getCapacities(null, null);
            const epoch_end_ts = order.final_epoch_end || order.initial_epoch_end;
            const epoch_start_ts = order.final_epoch_start || order.initial_epoch_start;
            let epoch_start = this.getChangedDateTime(order.initial_epoch_start, order.final_epoch_start);
            let epoch_end = this.getChangedDateTime(order.initial_epoch_end, order.final_epoch_end);
            const warning = this.props.warnings.find(
                w => w.order_uuid === order.order_uuid && w.field === PRODUCTION_VERSION_COLUMN
            );
            const production_version = this.getChangedText(null, null, true, warning);
            const produced_order = this.props.orders ? this.props.orders.find(o => o.uuid === order.order_uuid) : null;
            let scheduling_owner = translate("common.no", "No");
            if (produced_order) {
                const scheduling_owner_value = produced_order.tags.scheduling_owner === "true";
                scheduling_owner = this.getChangedBoolean(scheduling_owner_value, scheduling_owner_value).value;
            }

            const has_changes = !is_disabled && (
                quantity.changed ||
                line_external_id.changed || capacities.changed || epoch_start.changed || epoch_end.changed ||
                production_version.changed
            );

            let sap_order = {};
            let status_export_synced;

            if (this.props.save_report) {
                // compare orders from planning table with orders that we recieve from sap
                const order_change_report = this.props.save_report.order_change_reports.find(
                    ocr => ocr.order === order.order_external_id
                );

                if (order_change_report) {
                    const change = order_change_report.op_change_reports.find(
                        ocr => ocr.order === order.order_external_id && ocr.process_num === order.process_num
                    ) || {};

                    sap_order = {
                        version_ok: order_change_report.version_ok,
                        quantity_ok: order_change_report.quantity_ok,
                        user_lock_error: order_change_report.user_lock_error,
                        user_lock_name: order_change_report.user_lock_name,
                        ...change
                    };

                    status_export_synced = (
                        order_change_report.version_ok &&
                        order_change_report.quantity_ok &&
                        !order_change_report.user_lock_error &&
                        change.time_ok &&
                        change.workplace_ok &&
                        (change.operation_external_id_ok ?? true)
                    );
                }
            }

            if (is_disabled) {
                epoch_start = this.getChangedDateTime(order.final_epoch_start, order.final_epoch_start);
                epoch_end = this.getChangedDateTime(order.final_epoch_end, order.final_epoch_end);
            }

            extra_order_changes.push({
                save_checkbox: "extra",
                external_id: order.order_external_id,
                quantity: quantity.value,
                op_status_change: order.op_status_change,
                line_external_id: line_external_id.value,
                line_title: line_title.value,
                final_line_external_id: order.line_external_id,
                initial_line_external_id: order.line_external_id,
                material_title: order.material_title,
                process_num: order.process_num,
                capacity_factor: capacities.value,
                epoch_start: epoch_start.value,
                epoch_end: epoch_end.value,
                has_changes,
                status: status_export_synced,
                order_uuid: order.order_uuid,
                failed_scheduling: false, // TODO: missing value?
                production_version: production_version.value,
                epoch_end_ts,
                epoch_start_ts,
                scheduling_owner,
                ...sap_order
            });

            // TODO: Add to disabled order uuids.
            // if (order.failed_scheduling) {
            //     disabled_order_uuids.add(order.order_uuid);
            // }

            if (status_export_synced !== undefined && failed_order_uuids === null) {
                failed_order_uuids = new Set();
            }

            if (status_export_synced === false && failed_order_uuids !== null) {
                failed_order_uuids.add(order.order_uuid);
            }
        }

        extra_order_changes.sort((a, b) => {
            if (a.has_changes === b.has_changes) return 0;
            if (a.has_changes) return -1;
            return 1;
        });

        const order_changes = this.state.order_changes !== null ? this.state.order_changes : [];
        this.setState({
            order_changes: [...order_changes, ...extra_order_changes],
            disabled_order_uuids: [...disabled_order_uuids],
            failed_order_uuids: failed_order_uuids !== null ? [...failed_order_uuids] : null
        });
    }

    getStatsEl = (order_changes: OrderChangeEntry[], failed_order_uuids?: string[] | null, selected_order_uuids: ?string[]): React.Node => {
        if (!failed_order_uuids) return null;
        const num_orders = selected_order_uuids ? selected_order_uuids.length : order_changes.length;
        return (
            <span>
                ({num_orders - failed_order_uuids.length} / {num_orders}) {translate("common.successfully_transfered", "Successfully transfered")}
            </span>
        )
    }

    getIncorrectOperationOrderUuids = (order_uuids: string[] | null): string[] | null => {
        const { insights } = this.props;
        let uuids = new Set();

        if (order_uuids && insights) {
            for (const insight of insights) {
                if (insight.type !== INSIGHT_TYPES.operation_constraint_violated ||
                    insight.extra_data.diff_end_hours !== undefined) {
                    continue;
                }

                let order_uuid = ReportLogic.getOrderUuidFromInsight(insight) ||
                    (ReportLogic.getOrderUuidsFromInsight(insight) || [])[0];
                if (order_uuid && order_uuids.includes(order_uuid)) {
                    uuids.add(order_uuid);
                }
            }
        }

        return uuids.size > 0 ? [...uuids] : null;
    }

    onCloseModal = (is_success?: boolean) => {
        if (is_success) {
            this.setState({
                show_refresh_modal: true
            });
            return;
        }

        this.setState({
            progress: null,
            sync_operations: null,
            errors: []
        }, () => {
            this.props.onCloseModal();
        });
    }

    onSyncButtonClick = (action: SyncAction, order_uuids: ?string[]) => async () => {
        const progress = null;
        const sync_operations: SyncOperation[] = [];
        let incorrect_op_order_uuids = null;

        if (this.props.saveShifts) {
            sync_operations.push("save_shifts");
        }

        if (this.props.saveToSap) {
            sync_operations.push("save_to_sap");
            incorrect_op_order_uuids = this.getIncorrectOperationOrderUuids(order_uuids || null);
        }

        if (this.props.importPlan) {
            sync_operations.push("import_plan");
        }

        this.setState({
            action,
            progress,
            sync_operations,
            order_uuids,
            incorrect_op_order_uuids,
            num_changed_shifts: this.props.changed_shifts ? this.props.changed_shifts.length : 0,
            show_sync_modal: true
        });
    }

    onSyncModalAccept = async () => {
        this.setState({
            show_sync_modal: false
        }, () => {
            this.runSync();
        });
    }

    onSyncModalClose = () => {
        this.setState({
            show_sync_modal: false
        });
    }

    onRetryButtonClick = (action: SyncAction, order_uuids: ?string[]) => async () => {
        this.setState({
            order_uuids
        }, () => {
            this.runSync();
        });
    }

    setProgress = (i: number, response?: boolean | string) => {
        this.setState(prev_state => {
            const progress = prev_state.progress ? [...prev_state.progress] : [];

            if (response === undefined) {
                progress[i] = 0;
                return {
                    ...prev_state,
                    progress
                }
            }

            const fail_state = this.state.skip_retry ? 2 : -1;
            progress[i] = response === true ? 1 : fail_state;

            const errors = [...prev_state.errors];

            if (typeof response === "string") {
                errors.push(response);
            }

            return {
                ...prev_state,
                progress,
                errors
            }
        });
    }

    runSync = async () => {
        this.setState({
            show_sync_modal: false,
            errors: []
        });

        const {
            order_uuids,
            progress,
            sync_operations
        } = this.state;

        if (!sync_operations) {
            return;
        }

        const num_export_orders = order_uuids != undefined ? order_uuids.length : 0;
        const plant = BusinessLogic.getPlantsForUser().find(p => p.uuid === this.props.plant_uuid);
        const res = await getBackend().manufacturing.getPlanExportImportTime({
            num_export_orders,
            plant_external_id: plant ? plant.external_id : "",
        });
        this.setState({
            plan_export_time: res.export,
            plan_import_time: res.import
        });

        const { saveShifts, saveToSap, importPlan } = this.props;

        let response;
        let step = sync_operations.indexOf("save_shifts");
        if (step > -1 && saveShifts && (!progress || !progress[step] || progress[step] <= 0)) {
            this.setProgress(step);
            response = await saveShifts();
            this.setProgress(step, response);

            if (response !== true && !this.state.skip_retry) {
                return;
            }
        }

        step = sync_operations.indexOf("save_to_sap");
        if (step > -1 && saveToSap && (!progress || !progress[step] || progress[step] <= 0)) {
            this.setProgress(step);

            if (order_uuids && order_uuids.length > 0) {
                response = await saveToSap(order_uuids);
            }

            if (this.state.failed_order_uuids && this.state.failed_order_uuids.length) {
                response = false;
            }

            this.setProgress(step, response);

            if (response !== true && !this.state.skip_retry) {
                return;
            }
        }

        step = sync_operations.indexOf("import_plan");
        if (step > -1 && importPlan && (!progress || !progress[step] || progress[step] <= 0)) {
            const import_type = this.state.action === "sync_selected"
                ? PLAN_IMPORT_TYPE.lists
                : PLAN_IMPORT_TYPE.plant;
            this.setProgress(step);
            response = await importPlan(import_type);
            this.setProgress(step, response);
        }

        if (this.isSuccess() && this.state.refresh_when_complete) {
            window.location.reload();
        }
    }

    onSkipButtonClick = async () => {
        this.setState(prev_state => {
            if (!prev_state.progress) {
                return prev_state;
            }

            const progress = [...prev_state.progress];
            const first_error = progress.indexOf(-1);

            if (first_error > -1) {
                progress[first_error] = 2;
                return {
                    ...prev_state,
                    progress
                }
            }

            return prev_state;
        }, () => {
            this.runSync();
        });

    }

    onFilterFailedOrders = () => {
        this.setState({ filter_failed: true })
    }

    onUndoFilterFailedOrders = () => {
        this.setState({ filter_failed: false })
    }

    getFilterFailedButton = () => {
        if (!this.state.filter_failed) {
            return <button className="btn btn-primary" onClick={this.onFilterFailedOrders}>
                {translate("common.filter_failed", "Filter failed")}
            </button>
        }

        return <button className="btn btn-primary" onClick={this.onUndoFilterFailedOrders}>
            {translate("common.undo", "Undo")}
        </button>
    }

    getSaveReportInfo(order_changes: OrderChangeEntry[], failed_order_uuids?: string[] | null, selected_order_uuids: ?string[]) {
        const save_report = this.props.save_report;
        if (save_report === null) return null;
        const statsEl = this.getStatsEl(order_changes, failed_order_uuids, selected_order_uuids);
        return <div className="form-control overflow-auto mb-3" style={{ height: "auto", maxHeight: 200 }}>
            {statsEl && <p>{statsEl}</p>}
            <p>status: {save_report.status}</p>
            <p>return_code: {save_report.ReturnCode}</p>
            <div>message: {save_report.Messages.map(m => <p>{m}</p>)}</div>
        </div>
    }

    getRetryWarning = () => {
        if (this.state.failed_order_uuids && this.state.failed_order_uuids.length === 0 ||
            this.state.failed_order_uuids === null || !this.isSyncOperationFailing("save_to_sap")) {
            return null;
        }
        const message = translate("common.retry_orders", "Some orders were not saved, retry to try again.")
        return (<ErrorComponent msg={message} type="warning" />);
    }

    getHiddenColumns() {
        const hidden_columns_extra = this.props.hidden_columns || [];

        return [
            "final_line_external_id", "initial_line_external_id", "has_changes",
            "version_ok", "quantity_ok", "user_lock_error", "time_ok", "workplace_ok", "operation_external_id_ok", "epoch_end_ts",
            "epoch_start_ts", "user_lock_name", "stats", "initial_line_title", "final_line_title", "order_uuid",
            ...hidden_columns_extra
        ]
    }

    onRefresh = () => {
        window.location.reload();
    }

    onRefreshModalClose = () => {
        this.setState({ show_refresh_modal: false });
    };

    handleRefreshWhenCompleteChange = (event: SyntheticEvent<HTMLInputElement>) => {
        const refresh_when_complete: boolean = event.currentTarget.checked;
        this.setState({
            refresh_when_complete
        });
        localStorage.setItem(
            STORAGE_KEY_REFRESH_WHEN_COMPLETE,
            refresh_when_complete.toString()
        );
    };

    handleSkipRetryChange = (event: SyntheticEvent<HTMLInputElement>) => {
        const skip_retry: boolean = event.currentTarget.checked;
        this.setState({
            skip_retry
        });
        localStorage.setItem(
            STORAGE_KEY_SKIP_RETRY,
            skip_retry.toString()
        );
    };

    countOrders = (operation_uuids: string[]): number => {
        if (!this.state.order_changes) {
            return -1;
        }

        const orders = new Set();
        for (const uuid of operation_uuids) {
            const operation = this.state.order_changes.find(o => o.order_uuid === uuid);
            if (operation) {
                orders.add(operation.external_id);
            }
        }

        return orders.size;
    }

    countImportOrders = (): {
        orders: number,
        plan_orders: number,
        operations: number,
        plan_operations: number
    } => {
        const lines_orders = this.props.lines_orders || [];
        const unscheduled_orders = this.props.unscheduled_orders || [];
        const extra_lines = this.props.extra_lines || [];
        const extra_lines_map: Map<string, boolean> = new Map();
        for (const extra_line of extra_lines) {
            extra_lines_map.set(extra_line.line, true);
        }

        const orders = new Set();
        const plan_orders = new Set();
        const operations = new Set();
        const plan_operations = new Set();

        for (const line of lines_orders) {
            if (extra_lines_map.get(line.line_uuid)) {
                continue;
            }

            for (const order of line.orders) {
                if (order.order_type === ORDER_TYPE.plan) {
                    plan_orders.add(order.external_id);
                    plan_operations.add(order.uuid);
                } else {
                    orders.add(order.external_id);
                    operations.add(order.uuid);
                }
            }
        }

        for (const unscheduled_line of unscheduled_orders) {
            for (const order of unscheduled_line.orders) {
                if (order.order_type === ORDER_TYPE.plan) {
                    plan_orders.add(order.external_id);
                    plan_operations.add(order.uuid);
                } else {
                    orders.add(order.external_id);
                    operations.add(order.uuid);
                }
            }
        }

        return {
            orders: orders.size,
            plan_orders: plan_orders.size,
            operations: operations.size,
            plan_operations: plan_operations.size
        };
    }

    isSyncOperationFailed = (sync_operation: SyncOperation): boolean => {
        const { sync_operations, progress } = this.state;
        if (sync_operations === null || progress === null) {
            return false;
        }

        const index = sync_operations.indexOf(sync_operation);
        return index >= 0 ? progress[index] < 0 || progress[index] > 1 : false;
    }

    isSyncOperationFailing = (sync_operation: SyncOperation): boolean => {
        const { sync_operations, progress } = this.state;
        if (sync_operations === null || progress === null) {
            return false;
        }

        const index = sync_operations.indexOf(sync_operation);
        return index >= 0 && progress[index] === -1;
    }

    isSuccess = () => {
        const {
            progress,
            sync_operations
        } = this.state;
        return progress !== null && sync_operations !== null && progress.filter(n => n > 0).length === sync_operations.length;
    }

    renderExtraLinesSaveWarning = () => {
        const { extra_lines_order_changes } = this.props;
        const selected_order_uuids = new Set(this.props.selected_order_uuids || []);
        if (extra_lines_order_changes === undefined || selected_order_uuids.size === 0) {
            return null;
        }

        const extra_lines_map: Map<string, string> = new Map();
        for (const order_change of extra_lines_order_changes) {
            if (selected_order_uuids.has(order_change.order_uuid)) {
                extra_lines_map.set(order_change.line_external_id, order_change.line_title);
            }
        }

        if (extra_lines_map.size === 0) {
            return null;
        }

        const warning = (
            <div className="w-100">
                <p className="mb-0">{translate("common.saving_operations_from_extra_lines", "Operations from the following extra lines are selected for saving")}:</p>
                <ul className="pl-3 mb-0 overflow-auto" style={{ maxHeight: 115 }}>
                    {[...extra_lines_map.entries()].map(([id, title]) => (
                        <li key={id}>{id} - {title}</li>
                    ))}
                </ul>
            </div>
        );

        return <ErrorComponent msg={warning} type="warning" />
    }

    renderProgress = (progress: number[], value: ?SelectedOrdersProviderValue) => {
        if (!this.state.sync_operations) {
            return null;
        }

        const progress_items = [];
        const {
            failed_order_uuids,
            num_changed_shifts
        } = this.state;
        this.state.sync_operations.forEach((sync_op: SyncOperation, i) => {
            const tr = {
                num_changes: translate("common.number_of_changes", "No. changes"),
                num_operations: translate("common.number_of_operations", "No. operations"),
                num_orders: translate("common.number_of_orders", "No. orders"),
                num_plan_orders: translate("common.number_of_plan_orders", "No. plan orders"),
                num_plan_operations: translate("common.number_of_plan_operations", "No. plan operations"),
                total_operations: translate("common.total_operations", "Total operations"),
                total_work_orders: translate("common.total_work_orders", "Total work orders"),
                num_successful_operations: translate("common.number_of_successful_operations", "No. successful operations"),
                num_failed_operations: translate("common.number_of_failed_operations", "No. failed operations")
            };
            if (sync_op === "save_shifts") {
                const counters_str = ` (${tr.num_changes}: ${num_changed_shifts})`;
                progress_items.push(`${translate("common.save_shifts", "Save shifts")}${counters_str}`);
            } else if (sync_op === "save_to_sap") {
                const num_operations = value ? value.state.selected_order_uuids.length : 0;
                const num_orders = value ? this.countOrders(value.state.selected_order_uuids) : 0;
                const num_failed = this.isSyncOperationFailed("save_to_sap") && !failed_order_uuids
                    ? num_operations
                    : failed_order_uuids && failed_order_uuids.length || 0;
                const num_successful = num_operations - num_failed;
                const counters = [];
                if (progress[i] !== undefined && (progress[i] !== 0 || num_failed > 0)) {
                    counters.push(
                        `${tr.total_work_orders}: ${num_orders}`,
                        `${tr.total_operations}: ${num_operations}`,
                        `${tr.num_successful_operations}: ${num_successful}`,
                        `${tr.num_failed_operations}: ${num_failed}`
                    );
                } else {
                    counters.push(
                        `${tr.num_orders}: ${num_orders}`,
                        `${tr.num_operations}: ${num_operations}`
                    );
                }

                const counters_str = ` (${counters.join(", ")})`;
                let progress_item = `${translate("common.save_selected_operations_to_sap", "Save selected operations to SAP")}${counters_str}`;
                if (progress[i] === 0 && this.state.plan_export_time > 0) {
                    progress_item = (
                        <React.Fragment>
                            {progress_item}
                            <div className="pl-1 ml-4 my-1">
                                <TimeProgress duration={this.state.plan_export_time} active striped />
                            </div>
                        </React.Fragment>
                    );
                }

                progress_items.push(progress_item);
            } else if (sync_op === "import_plan") {
                let progress_item = "";
                if (this.state.action === "sync_all") {
                    progress_item = translate("common.import_plant_orders_from_sap", "Import plant orders from SAP");
                } else {
                    const counter = this.countImportOrders();
                    const counters = [
                        `${tr.num_orders}: ${counter.orders}`,
                        `${tr.num_operations}: ${counter.operations}`,
                        `${tr.num_plan_orders}: ${counter.plan_orders}`,
                        `${tr.num_plan_operations}: ${counter.plan_operations}`
                    ];
                    const counters_str = ` (${counters.join(", ")})`;
                    progress_item = `${translate("common.import_shopfloor_orders_from_sap", "Import shopfloor orders from SAP")}${counters_str}`
                }

                if (progress[i] === 0 && this.state.plan_import_time > 0) {
                    progress_item = (
                        <React.Fragment>
                            {progress_item}
                            <div className="pl-1 ml-4 mt-1">
                                <TimeProgress duration={this.state.plan_import_time} active striped />
                            </div>
                        </React.Fragment>
                    );
                }

                progress_items.push(progress_item);
            }
        });

        return (
            <ChecklistProgress
                className="mb-3 ml-auto"
                items={progress_items}
                progress={progress}
            />
        )
    }

    renderErrors = () => {
        const errors = [...this.state.errors];

        if (this.props.error && errors.indexOf(this.props.error) < 0) {
            errors.push(this.props.error);
        }

        if (!errors.length) {
            return null;
        }

        return (
            <React.Fragment>
                {errors.map((error, i) => (
                    <ErrorComponent msg={error} type="error" />
                ))}
            </React.Fragment>
        );
    }

    renderImportJobStatus = () => {
        const { import_job_status } = this.props;

        if (!import_job_status || import_job_status.status_msg === "UNKNOWN") {
            return null;
        }

        const alert_type = import_job_status.status === JOB_STATUS.errored ? "error" : "info";

        return this.props.import_job_status && (
            <ErrorComponent className="mt-0" type={alert_type} msg={import_job_status.status_msg} />
        );
    }

    render() {
        const {
            order_changes,
            failed_order_uuids,
            disabled_order_uuids,
            progress,
            sync_operations
        } = this.state;

        if (!this.props.show_modal) {
            return null;
        }

        const in_progress = progress !== null && sync_operations !== null && (progress.length < sync_operations.length || progress.indexOf(0) > -1);
        const is_retry = progress !== null && progress.indexOf(-1) > -1;
        const is_success = this.isSuccess();

        return <Modal id="order-changes-modal" show={this.props.show_modal} bsSize="large" dialogClassName="modal-dialog-scrollable order-changes-modal">
            <Modal.Header>
                <Modal.Title>{this.props.modal_title || translate("common.save", "Save")}</Modal.Title>
                <button type="button" className="close" onClick={() => { this.onCloseModal(is_success); }}>
                    <span aria-hidden="true">×</span>
                    <span className="sr-only">
                        {translate("common.close", "Close")}
                    </span>
                </button>
            </Modal.Header>
            <Modal.Body className="px-2 py-0">
                {<ReactTable
                    className="save-to-sap-react-table"
                    data={order_changes && this.state.filter_failed === true ? order_changes.filter(oc => oc.status === false) : order_changes || []}
                    columns={getInitialOrderColumns(!!this.props.orders)}
                    hiddenColumns={this.getHiddenColumns()}
                    rowStyle={rowStyle}
                    isLoading={this.props.is_loading || !order_changes}
                    isSticky={true}
                    initialState={{
                        filters: this.props.initial_filters || []
                    }}
                />}
            </Modal.Body>
            <Modal.Footer>
                <SelectedOrdersConsumer>
                    {(value: ?SelectedOrdersProviderValue) => (
                        <div className="w-100">
                            {order_changes && order_changes.length > 0 && value && <p>
                                {translate("common.number_of_selected_orders_abbr", "No. selected orders")}: {this.countOrders(value.state.selected_order_uuids)}
                                <br />
                                {translate("common.number_of_selected_operations_abbr", "No. selected operations")}: {value.state.selected_order_uuids.length}
                            </p>}
                            {this.renderExtraLinesSaveWarning()}
                            {progress && this.renderProgress(progress, value)}
                            {this.renderImportJobStatus()}
                            {this.renderErrors()}
                            {order_changes && this.getSaveReportInfo(order_changes, failed_order_uuids, value && value.state.selected_order_uuids)}
                            {this.getRetryWarning()}
                            <div className="order-changes-modal-footer-buttons">
                                {this.props.saveToSap && (
                                    <div className="d-flex align-content-start">
                                        <div>
                                            {is_retry && (
                                                <React.Fragment>
                                                    <OrderChangesModalSyncButton
                                                        className="mr-2"
                                                        action="retry"
                                                        line_group_uuid={this.props.line_group_uuid}
                                                        failed_order_uuids={failed_order_uuids}
                                                        disabled_order_uuids={disabled_order_uuids}
                                                        value={value}
                                                        disabled={false}
                                                        onClick={this.onRetryButtonClick}
                                                    />
                                                    <button className="btn btn-primary mr-2" onClick={this.onSkipButtonClick}>
                                                        {translate("common.skip", "Skip")}
                                                    </button>
                                                </React.Fragment>
                                            )}
                                            {is_success && !this.state.refresh_when_complete && (
                                                <button className="btn btn-primary mr-2" onClick={this.onRefresh}>
                                                    {translate("common.refresh", "Refresh")}
                                                </button>
                                            )}
                                        </div>
                                        <div className="ml-auto">
                                            <div className="d-flex">
                                                <OrderChangesModalSyncButton
                                                    className="ml-auto mr-2"
                                                    action="sync_selected"
                                                    line_group_uuid={this.props.line_group_uuid}
                                                    failed_order_uuids={failed_order_uuids}
                                                    disabled_order_uuids={disabled_order_uuids}
                                                    value={value}
                                                    disabled={in_progress || is_success}
                                                    onClick={this.onSyncButtonClick}
                                                />
                                                <OrderChangesModalSyncButton
                                                    action="sync_all"
                                                    line_group_uuid={this.props.line_group_uuid}
                                                    failed_order_uuids={failed_order_uuids}
                                                    disabled_order_uuids={disabled_order_uuids}
                                                    value={value}
                                                    disabled={in_progress || is_success}
                                                    onClick={this.onSyncButtonClick}
                                                />
                                            </div>
                                            <div className="d-flex mt-1">
                                                <div className="form-check d-inline-block">
                                                    <input
                                                        id="skip-retry-checkbox"
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={this.state.skip_retry}
                                                        onChange={this.handleSkipRetryChange}
                                                    />
                                                    <label className="mb-0" htmlFor="skip-retry-checkbox">
                                                        {translate("common.skip_retry", "Skip retry")}
                                                    </label>
                                                </div>
                                                <div className="form-check d-inline-block ml-2">
                                                    <input
                                                        id="refresh-when-complete-checkbox"
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={this.state.refresh_when_complete}
                                                        onChange={this.handleRefreshWhenCompleteChange}
                                                    />
                                                    <label className="mb-0" htmlFor="refresh-when-complete-checkbox">
                                                        {translate("common.refresh_when_complete", "Refresh when complete")}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </SelectedOrdersConsumer>
            </Modal.Footer>
            {this.state.action && (
                <SyncModal
                    show={this.state.show_sync_modal}
                    action={this.state.action}
                    orders={this.props.orders}
                    incorrect_op_order_uuids={this.state.incorrect_op_order_uuids}
                    onAccept={this.onSyncModalAccept}
                    onClose={this.onSyncModalClose}
                />
            )}
            <ConfirmationModal
                show={this.state.show_refresh_modal}
                title={translate("common.refresh", "Refresh")}
                accept_label={translate("common.ok", "OK")}
                cancel_label={translate("common.cancel", "Cancel")}
                onAccept={this.onRefresh}
                onCancel={this.onRefreshModalClose}
            >
                {translate(
                    "common.orders_successfully_synchronized_refresh_page",
                    "Orders have been successfully synchronized. Page needs to be refreshed."
                )}
            </ConfirmationModal>
        </Modal>
    }
}

export default connect(
    (state: ReduxState) => {
        return {
            current_linegroup_uuid: state.gantt_chart_properties.line_group_uuid
        }
    },
    (dispatch) => ({reduxDispatch: dispatch})
)(OrderChangesModal);
