// @flow

import * as React from "react";
import ReactRouterPropTypes from "react-router-prop-types";
import { FormattedMessage } from "react-intl";

import { getBackend } from "../lib/backend/Backend2";
import * as t from "../lib/backend/alerts.generated.types";

import EventList from "./EventList";
import EventDetails, { getEventDetails } from "./EventDetails";
import { ResourcesLayout } from "./MainContent";

type State = {
    events: Array<t.IEventData>,
    selected: Object | null
};

type Props = {
    match: ReactRouterPropTypes.match,
    history: ReactRouterPropTypes.history,
    location: ReactRouterPropTypes.location
};

class Events extends React.Component<Props, State> {
    constructor() {
        super()

        this.state = {
            events: [],
            selected: null
        };
    }

    async componentDidMount() {
        try {
            // event_uuid is event's uuid parameter from URL path
            const { event_uuid } = this.props.match.params;

            const res = await getBackend().alerts.searchEvents({ limit: 200 });
            const events = res.data;
            this.setState({ events });

            if (event_uuid) {
                // try to find and select the searched event
                // from the list of latest N returned events
                let found = false;
                for (let i = 0; i < events.length; i++) {
                    if (events[i].uuid === event_uuid) {
                        found = true;
                        this.handleEventClick(events[i]);
                    }
                }
                // if the event from parameter is not among latest N events
                // fetch the searched event separatly and add
                // it will be displayed at the end of the list
                if (!(found)) {
                    const res2 = await getBackend().alerts.getSingleEvent({ id: event_uuid });
                    const event = res2.data
                    this.setState(prevState => ({
                        events: [...prevState.events, event]
                    }));
                    this.handleEventClick(event);
                }
            } else {
                // if no parameter is given select the first item
                if (events.length > 0) {
                    this.handleEventClick(events[0]);
                }
            }
        } catch (err) {
            this.setState({ events: [] });
            console.log(err);
        }
    }

    handleEventClick(event: t.IEventData) {
        getEventDetails(event, (selected) => {
            this.setState({ selected });
        });
        // update url with each click
        let location: string = this.props.location.pathname;
        if (location.indexOf('events/') !== -1) {
            // if parameter is already given, change only the parameter in the path
            this.props.history.push(event.uuid);
        } else {
            // if parameter is not given, add 'events'
            this.props.history.push('events/' + event.uuid);
        }
    }

    render() {
        return (
            [
                <ResourcesLayout>
                    <ResourcesLayout.Header
                        title={
                            <ul className="list-inline">
                                <li className="list-inline-item">
                                    <h2>
                                        <FormattedMessage id="Header.menu.events" defaultMessage="Events" />
                                    </h2>
                                </li>
                                <li className="list-inline-item">
                                </li>
                            </ul>
                        }
                    />
                    <ResourcesLayout.Main>
                        <ResourcesLayout.List>
                            <EventList
                                events={this.state.events}
                                selected={this.state.selected}
                                handleEventClick={(event) => { this.handleEventClick(event); }} />
                        </ResourcesLayout.List>
                        <ResourcesLayout.Content>
                            {this.state.selected && <EventDetails selected={this.state.selected} />}
                        </ResourcesLayout.Content>
                    </ResourcesLayout.Main>
                </ResourcesLayout>
            ]
        );
    }
}
export default Events;
