// @flow

import React from "react";
// $FlowFixMe
import { useMemo, useRef, useState } from "react";
import { Link, type LinkProps } from "react-router-dom";
import Select from "react-select";
import { Cell, type Row, type TableInstance } from "react-table";
import moment from "moment";

import { niceNumber } from "../lib/Util";
import { translate } from "./IntlProviderWrapper";
import Loader from "./Loader";
import ReactTable, { type ReactTableColumn } from "./react/ReactTable";
import { getDfUrl } from "./DemandForecasting";

export type TableRow = {
    index: number,
    plant_id: string,
    plant: string,
    material_id: string,
    material: string,
    horizon: number,
    date: string,
    person: string,
    pir: number | null,
    sales_orders: number | null,
    pir_so_max: number | null,
    correction_coef: number | null,
    correction: number | null,
    mae_original: number | null,
    mae_new: number | null,
    mae_diff: number | null,
    coef_80: number | null,
    count: number | null,
    better_pred_cnt: number | null,
    better_pred_perc: number | null
};

type Props = {
    rows: TableRow[] | null,
    showDetailsColumns: boolean,
    variant: "overview" | "details",
    maxRows?: number,
    onRowSelect?: (cell: Cell) => void
};

type State = {
    columns: ReactTableColumn<TableRow>[]
};

export const formatNumber = (value: number | null, decimals?: number) =>
    typeof value === "number" ? niceNumber(value, decimals || 0) : "-";
export const renderRealNumber = ({ value }: Cell) => formatNumber(value, 3);
export const renderIntegerNumber = ({ value }: Cell) => formatNumber(value);
export const renderString = ({ value }: Cell) => value || "-";

export const getCellRenderer = (renderer?: (cell: Cell) => any, link_props?: LinkProps = {}) => {
    return (cell: Cell) => {
        const plant_id = cell.row.values.plant_id;
        const material_id = cell.row.values.material_id;
        const { className, ...other } = link_props;
        return (
            <Link
                className={`cell-wrapper${className ? ` ${className}` : ""}`}
                to={getDfUrl(plant_id, material_id)}
                {...other}
            >
                {renderer ? renderer(cell) : `${cell.value}`}
            </Link>
        );
    };
};

export const renderCorrection = (cell: Cell) => {
    const mae_diff = cell.row.values.mae_new - cell.row.values.mae_original;
    const bg_class = mae_diff < 0 ? "low" : mae_diff > 0 ? "high" : "average";
    const correction_coef = cell.row.values.correction_coef;
    const pointer_class = correction_coef < 1 ? "down" : correction_coef > 1 ? "up" : "right";

    return getCellRenderer(renderIntegerNumber, { className: `bg-${bg_class} pointer-${pointer_class}` })(cell);
};

export const renderBetterPredPerc = (cell: Cell) => {
    return cell.value !== null
        ? `${niceNumber(cell.value, 0)}% (${niceNumber(cell.row.values.better_pred_cnt, 0)}/${niceNumber(
              cell.row.values.count,
              0
          )})`
        : "-";
};

export const sortNumberOrNull = (row_a: Row, row_b: Row, column_id: string): number => {
    const a: number | null = row_a.values[column_id];
    const b: number | null = row_b.values[column_id];

    if (a !== null && b !== null) {
        return a === b ? 0 : a > b ? 1 : -1;
    }

    if (a !== null && b !== null) {
        return 1;
    }

    if (a === null && b !== null) {
        return -1;
    }

    return 0;
};

export const sortColumns = (columns: ReactTableColumn<TableRow>[], ids: string[]): ReactTableColumn<TableRow>[] => {
    const columns_map = {};
    columns.forEach(column => {
        columns_map[column.accessor] = column;
    });

    const new_columns = [];
    ids.forEach(id => {
        if (columns_map[id]) {
            new_columns.push(columns_map[id]);
        }
    });

    return new_columns;
};

export const DateColumnFilter = ({
    column: { filterValue = ["", "", ""], preFilteredRows, setFilter, id, filterInputPlaceholder }
}: TableInstance) => {
    const [dropdown_open, setDropdownOpen] = useState(false);
    const [date_filter, setDateFilter] = useState("");
    const [min_date, setMinDate] = useState("");
    const [max_date, setMaxDate] = useState("");
    const parent_ref = useRef<HTMLElement | null>(null);
    const focus_ref = useRef();
    const focus_timeout = useRef();

    const [min, max] = useMemo(() => {
        let min = preFilteredRows.length ? preFilteredRows[0].values[id] : "";
        let max = preFilteredRows.length ? preFilteredRows[0].values[id] : "";
        preFilteredRows.forEach(row => {
            if (row.values[id] && (!min || row.values[id] < min)) {
                min = row.values[id];
            }

            if (row.values[id] && (!max || row.values[id] > max)) {
                max = row.values[id];
            }
        });
        return [min, max];
    }, [id, preFilteredRows]);
    const range = useMemo(() => {
        if (!min || !max) {
            return [];
        }

        const date_range = [min];
        const max_moment = moment(max);
        let current_moment = moment(min).startOf("month").add(1, "months");
        while (current_moment.isBefore(max_moment)) {
            date_range.push(current_moment.format("YYYY-MM"));
            current_moment.add(1, "months");
        }

        return date_range;
    }, [min, max]);

    const min_date_option = min_date
        ? {
              label: min_date,
              value: min_date
          }
        : null;
    const min_date_options = useMemo(() => {
        return range
            .filter(date => (max_date && date <= max_date) || !max_date)
            .map(date => ({
                label: date,
                value: date
            }));
    });

    const max_date_option = max_date
        ? {
              label: max_date,
              value: max_date
          }
        : null;
    const max_date_options = useMemo(() => {
        return range
            .filter(date => (min_date && date >= min_date) || !min_date)
            .map(date => ({
                label: date,
                value: date
            }));
    });

    const toggleDropdown = (open: boolean) => {
        const parent = parent_ref.current;

        if (parent) {
            if (open) {
                parent.classList.add("focused");
            } else {
                parent.classList.remove("focused");
            }
        }

        setDropdownOpen(open);
    }

    const handleDateFilterChange = (event: SyntheticEvent<HTMLInputElement>) => {
        setDateFilter(event.currentTarget.value);
        setFilter([event.currentTarget.value.trim(), filterValue[1], filterValue[2]]);
    };

    const handleDateFilterKeyPress = (event: SyntheticKeyboardEvent<HTMLInputElement>) => {
        switch (event.keyCode) {
            case 27:
                toggleDropdown(false);
                break;
            case 40:
                toggleDropdown(true);
                break;
            default:
                break;
        }

        if (event.keyCode === 27) {
            toggleDropdown(false);
        }
    };

    const handleMinDateChange = option => {
        const value = option ? option.value : "";
        setMinDate(value);
        setFilter([filterValue[0], value, filterValue[2]]);
    };

    const handleMaxDateChange = option => {
        const value = option ? option.value : "";
        setMaxDate(value);
        setFilter([filterValue[0], filterValue[1], value]);
    };

    const handleControlFocus = (event: SyntheticEvent<HTMLElement>) => {
        if (event.target instanceof HTMLElement) {
            focus_ref.current = event.target;

            if (!parent_ref.current) {
                parent_ref.current = event.target.closest("th");
            }
        }

        clearTimeout(focus_timeout.current);
        toggleDropdown(true);
    };

    const handleControlBlur = () => {
        focus_timeout.current = setTimeout(() => {
            toggleDropdown(false);
        }, 0);
    };

    const handleDropdownClick = (event: SyntheticMouseEvent<HTMLDivElement>) => {
        if (focus_ref.current && event.currentTarget === event.target) {
            clearTimeout(focus_timeout.current);
            setTimeout(() => {
                focus_ref.current.focus();
            }, 0);
        }
    };

    return (
        <div className="position-relative">
            <input
                className="form-control column-filter"
                placeholder={filterInputPlaceholder}
                value={date_filter}
                onChange={handleDateFilterChange}
                onFocus={handleControlFocus}
                onBlur={handleControlBlur}
                onKeyDown={handleDateFilterKeyPress}
                aria-haspopup="true"
                aria-expanded={dropdown_open}
            />
            {dropdown_open && (
                <div
                    className="dropdown-menu d-block px-2 mt-1 shadow-sm"
                    onFocus={handleControlFocus}
                    onBlur={handleControlBlur}
                    onMouseDown={handleDropdownClick}
                >
                    <Select
                        className="mb-2"
                        placeholder={translate("common.from", "From")}
                        value={min_date_option}
                        options={min_date_options}
                        isClearable={true}
                        onChange={handleMinDateChange}
                    />
                    <Select
                        placeholder={translate("common.to", "To")}
                        value={max_date_option}
                        options={max_date_options}
                        isClearable={true}
                        onChange={handleMaxDateChange}
                    />
                </div>
            )}
        </div>
    );
};

export const filterDate = (rows: Row[], columnId: string, filterValue: [string, string, string]) => {
    return rows.filter(row => {
        const row_value = row.values[columnId];
        if (row_value === undefined) {
            return true;
        }

        const filter_condition = row_value.toLowerCase().includes(filterValue[0].toLowerCase());
        const min_condition = (filterValue[1] && row_value >= filterValue[1]) || !filterValue[1];
        const max_condition = (filterValue[2] && row_value <= filterValue[2]) || !filterValue[2];
        return filter_condition && min_condition && max_condition;
    });
};

class DemandForecastingTable extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            columns: this.mapColumns()
        };
    }

    mapColumns = () => {
        const is_details_variant = this.props.variant === "details";
        let columns = [
            {
                Header: "",
                accessor: "plant_id"
            },
            {
                Header: "",
                title: translate("common.plant", "Plant"),
                accessor: "plant",
                filterInputPlaceholder: translate("common.plant", "Plant"),
                Cell: getCellRenderer()
            },
            {
                Header: "",
                accessor: "material_id"
            },
            {
                Header: "",
                title: translate("common.material", "Material"),
                accessor: "material",
                filterInputPlaceholder: translate("common.material", "Material"),
                Cell: getCellRenderer()
            },
            {
                Header: is_details_variant ? translate("common.horizon", "Horizon") : "",
                title: translate("common.horizon", "Horizon"),
                accessor: "horizon",
                filterInputPlaceholder: translate("common.horizon", "Horizon"),
                textAlign: "right",
                disableFilters: is_details_variant,
                enableSorting: is_details_variant,
                sortType: sortNumberOrNull,
                Cell: getCellRenderer(renderIntegerNumber)
            },
            {
                Header: is_details_variant ? translate("common.date", "Date") : "",
                title: translate("common.date", "Date"),
                accessor: "date",
                filterInputPlaceholder: translate("common.date", "Date"),
                disableFilters: is_details_variant,
                enableSorting: is_details_variant,
                Cell: getCellRenderer(),
                Filter: DateColumnFilter,
                filter: filterDate
            },
            {
                Header: "",
                title: translate("common.person", "Person"),
                accessor: "person",
                filterInputPlaceholder: translate("common.person", "Person"),
                Cell: getCellRenderer(renderString)
            },
            {
                Header: translate("DemandForecasting.pir", "PIR"),
                title: translate("DemandForecasting.pir", "PIR"),
                accessor: "pir",
                disableFilters: true,
                enableSorting: true,
                sortType: sortNumberOrNull,
                textAlign: "right",
                Cell: getCellRenderer(renderRealNumber)
            },
            {
                Header: translate("DemandForecasting.sales_orders", "Sales orders"),
                title: translate("DemandForecasting.sales_orders", "Sales orders"),
                accessor: "sales_orders",
                disableFilters: true,
                enableSorting: true,
                sortType: sortNumberOrNull,
                textAlign: "right",
                Cell: getCellRenderer(renderRealNumber)
            },
            {
                Header: translate("DemandForecasting.max_pir_so", "max(PIR, SO)"),
                title: translate("DemandForecasting.max_pir_so", "max(PIR, SO)"),
                accessor: "pir_so_max",
                disableFilters: true,
                enableSorting: true,
                sortType: sortNumberOrNull,
                textAlign: "right",
                Cell: getCellRenderer(renderRealNumber)
            },
            {
                Header: translate("DemandForecasting.correction", "Correction"),
                title: translate("DemandForecasting.correction", "Correction"),
                accessor: "correction",
                disableFilters: true,
                enableSorting: true,
                sortType: sortNumberOrNull,
                textAlign: "right",
                Cell: renderCorrection
            },
            {
                Header: translate("DemandForecasting.correction_coefficient", "Corr. coef."),
                title: translate("DemandForecasting.correction_coefficient", "Corr. coef."),
                accessor: "correction_coef",
                disableFilters: true,
                enableSorting: true,
                sortType: sortNumberOrNull,
                textAlign: "right",
                Cell: getCellRenderer(renderRealNumber)
            },
            {
                Header: translate("DemandForecasting.mae_original", "MAE (orig.)"),
                title: translate("DemandForecasting.mae_original", "MAE (orig.)"),
                accessor: "mae_original",
                disableFilters: true,
                enableSorting: true,
                sortType: sortNumberOrNull,
                textAlign: "right",
                Cell: getCellRenderer(renderIntegerNumber)
            },
            {
                Header: translate("DemandForecasting.mae_new", "MAE (new)"),
                title: translate("DemandForecasting.mae_new", "MAE (new)"),
                accessor: "mae_new",
                disableFilters: true,
                enableSorting: true,
                sortType: sortNumberOrNull,
                textAlign: "right",
                Cell: getCellRenderer(renderIntegerNumber)
            },
            {
                Header: translate("DemandForecasting.mae_diff", "MAE (diff.)"),
                title: translate("DemandForecasting.mae_diff", "MAE (diff.)"),
                accessor: "mae_diff",
                disableFilters: true,
                enableSorting: true,
                sortType: sortNumberOrNull,
                textAlign: "right",
                Cell: getCellRenderer(renderIntegerNumber)
            },
            {
                Header: translate("DemandForecasting.coefficient_80", "Coef. 80"),
                title: translate("DemandForecasting.coefficient_80", "Coef. 80"),
                accessor: "coef_80",
                disableFilters: true,
                enableSorting: true,
                sortType: sortNumberOrNull,
                textAlign: "right",
                Cell: getCellRenderer(renderRealNumber)
            },
            {
                Header: "",
                accessor: "count"
            },
            {
                Header: "",
                accessor: "better_pred_cnt"
            },
            {
                Header: translate("DemandForecasting.improved_perc", "Improved perc."),
                title: translate("DemandForecasting.improved_perc", "Improved perc."),
                accessor: "better_pred_perc",
                disableFilters: true,
                enableSorting: true,
                sortType: sortNumberOrNull,
                Cell: getCellRenderer(renderBetterPredPerc)
            }
        ];

        let selected_columns = null;
        let sort = false;

        if (is_details_variant) {
            selected_columns = [
                "horizon",
                "date",
                "pir",
                "sales_orders",
                "pir_so_max",
                "correction_coef",
                "correction",
                "mae_original",
                "mae_new",
                "mae_diff",
                "coef_80",
                "count",
                "better_pred_cnt",
                "better_pred_perc"
            ];
        }

        if (selected_columns !== null) {
            columns = columns.filter(column => selected_columns && selected_columns.includes(column.accessor));
        }

        if (sort && selected_columns !== null) {
            columns = sortColumns(columns, selected_columns);
        }

        return columns;
    };

    render() {
        if (this.props.rows === null) {
            return <Loader />;
        }

        let hidden_columns = ["plant_id", "material_id", "count", "better_pred_cnt"];

        if (!this.props.showDetailsColumns) {
            hidden_columns = [
                ...hidden_columns,
                "correction_coef",
                "coef_80",
                "mae_original",
                "mae_new",
                "mae_diff",
                "better_pred_perc"
            ];
        }

        return (
            <ReactTable
                className={`df-table${this.props.variant === "details" ? " df-table-details" : ""}`}
                columns={this.state.columns}
                hiddenColumns={hidden_columns}
                data={this.props.rows}
                maxRows={this.props.maxRows}
            />
        );
    }
}

export default DemandForecastingTable;
