// @flow
import * as React from "react";

type Props = {
    panels: Array<Object>
};
type State = {};

class Band extends React.Component<Props, State> {
    constructor() {
        super();
        this.state = {};
    }

    render() {
        const cols = this.props.panels
            .map(panel => (panel.props.colSpan || 1))
            .reduce((prev, curr) => prev + curr, 0);
        let panels = this.props.panels.map((panel, i) => {
            const col_span = (panel.props.colSpan || 1);
            const width_indicator = (col_span === 1 ? "col-md" : "col-md-" + Math.floor(12 * col_span / cols));
            return (
                <div key={i} className={width_indicator}>
                    {panel}
                </div>
            )
        });
        return (<div className="dsh_band row">{panels}</div>);
    }
}

export default Band;
