// @flow

/************************************************************************************/
/* WARNING: This file is generated automatically. Don't edit it by hand             */
/************************************************************************************/

import * as api from "./restApi.js";
import * as t from "./tracking.generated.types.js";

function enc(s: any): string { return encodeURIComponent("" + s); }

export interface ITracking {
    /** Creates tracking event */
    createTrackingEvent(req: any): Promise<any>;

    /** Retrieves tracking event */
    getTrackingEvent(req: any): Promise<any>;

    /** Logs performance on backend */
    logPerformance(req: t.ILogPerformanceReq): Promise<t.ILogPerformanceRes>;

    /** Logs a problem from client-side */
    logClientsideError(req: t.ILogClientsideErrorReq): Promise<t.ILogClientsideErrorRes>;

}

export class Tracking implements ITracking {

    client: api.ApiClient;

    constructor(client: api.ApiClient) { this.client = client; }

    /** Creates tracking event */
    async createTrackingEvent(req: any): Promise<any> {
        const reqx: any = req;
        const url = `/api/v1.0/tracking`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Retrieves tracking event */
    async getTrackingEvent(req: any): Promise<any> {
        const reqx: any = req;
        const url = `/api/v1.0/tracking`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Logs performance on backend */
    async logPerformance(req: t.ILogPerformanceReq): Promise<t.ILogPerformanceRes> {
        const reqx: any = req;
        const url = `/api/v1.0/performance_logging`;
        return (await this.client.post(url, reqx)).data;
    }

    /** Logs a problem from client-side */
    async logClientsideError(req: t.ILogClientsideErrorReq): Promise<t.ILogClientsideErrorRes> {
        const reqx: any = req;
        const url = `/api/v1.0/report-clientside-error`;
        return (await this.client.post(url, reqx)).data;
    }

}
