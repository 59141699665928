// @flow

import React from "react";

const Cross = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8">
        <path id="Path_930" data-name="Path 930" d="M12.7,11.471l2.5,2.5a.882.882,0,0,1-1.244,1.244l-2.5-2.5-2.5,2.5a.882.882,0,1,1-1.244-1.244l2.5-2.5-2.5-2.5A.882.882,0,0,1,8.962,7.732l2.5,2.5,2.5-2.5A.882.882,0,0,1,15.2,8.975Z" transform="translate(-7.41 -7.52)" fill="#7b8794" />
    </svg>
}

export default Cross;
