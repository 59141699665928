// @flow

import type {
    DashboardBaseObj, DashboardObj, DashboardResultObj, DashboardSaveObj
} from "./Models";

export interface DashboardBackend {

    // get dashboard list
    getDashboardsAsync(): Promise<DashboardBaseObj[]>;

    // get dashboard list
    getDashboards(done: (DashboardBaseObj[]) => void, err: (str: string) => void): void;

    // get record for single dashboard - don't execute it
    getSingleDashboardAsync(uuid: string): Promise<DashboardObj>;

    // get record for single dashboard - don't execute it
    getSingleDashboard(uuid: string, done: (DashboardObj) => void, err: (str: string) => void): void;

    // execute specified dashboard
    executeDashboard(uuid: string, from: number, to: number, params: any, done: (DashboardResultObj) => void, err: (str: string) => void): void;

    // get record for single public dashboard - don't execute it
    getSingleDashboardPublic(uuid: string, done: (DashboardObj) => void, err: (str: string) => void): void;

    // execute specified public dashboard
    executeDashboardPublic(uuid: string, from: number, to: number, params: any, done: (DashboardResultObj) => void, err: (str: string) => void): void;

    // execute given dashboard definition
    executeDashboardDef(layout_def: any, data_def: any, params: any, done: (DashboardResultObj) => void, err: (str: string) => void): void;

    // save new dashboard
    insertDashboard(data: DashboardSaveObj, done: (str: string) => void, err: (str: string) => void): void;

    // update existing dashboard
    updateDashboard(uuid: string, data: DashboardSaveObj, done: () => void, err: (str: string) => void): void;
}

export interface Backend {

    dash: DashboardBackend;
}

let backend = null;

export function getBackend(): Backend {
    if (backend != null) {
        return backend;
    } else {
        throw new Error("Missing backend singleton");
    }
}

export function setBackend(_backend: Backend): void {
    backend = _backend;
}
