// @flow

import React, { Component } from "react";
import { Tabs, Tab } from "react-bootstrap";

import { getBackend } from "../../lib/backend/Backend2";
import * as tm from "../../lib/backend/models.generated.types";
import * as t from "../../lib/backend/manufacturing2.generated.types";
import { niceNumber, parseFloatSmart } from "../../lib/Util";
import DataFields from "../react/DataFields";
import type { Cell } from "../react/DataFields";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsExporting from 'highcharts/modules/exporting';
import OrderListSimple from "./OrderListSimple";
HighchartsExporting(Highcharts);

// defining types
type Props = {
    material_external_id: string,
    line_external_id: string,
    process_num: string
}

type TabKey = "normative" | "latest" | "numerical" | "distributions" | "prod_func";
type State = {
    model_info: tm.IModelInfo | null,
    material: t.IMaterialModel | null,
    input_data: tm.IGetSingleModelInputDataRes,
    latest_orders: t.IOrderModelBase[],
    last_order_slope: number,
    calc_data_q: number,
    calc_data_t: number,
    calc_data_t_norm: number,
    tab_key: TabKey
}

/**
 * Lines details component.
 */
class ModelDetails extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        const state: State = {
            model_info: null,
            material: null,
            input_data: { data: [] },
            latest_orders: [],
            last_order_slope: -1,
            calc_data_q: 0,
            calc_data_t: 0,
            calc_data_t_norm: -1,
            tab_key: "normative"
        };
        this.state = state;
    }

    async componentDidMount() {
        await this.loadModelInfo();
    }

    async loadModelInfo() {
        const backend = getBackend();
        const { data } = await backend.manufacturing.searchMaterialsSimple({
            external_id: this.props.material_external_id
        });
        if (data.length === 0) {
            return;
        }
        const material = data[0];

        const model_info = await backend.models.getSingleModel({
            line: this.props.line_external_id,
            material: this.props.material_external_id,
            process_num: this.props.process_num
        });

        const input_data = await backend.models.getSingleModelInputData({
            line: this.props.line_external_id,
            material: this.props.material_external_id,
            process_num: this.props.process_num
        });

        const { operations } = await backend.manufacturing.getOrdersSimple({
            line_external_ids: [this.props.line_external_id],
            material_uuids: [material.uuid],
            process_num: this.props.process_num,
            status: "closed"
        });

        this.setState({
            input_data,
            material,
            model_info,
            latest_orders: operations.sort((a, b) => b.earliest_end - a.earliest_end)
        });
    }

    async componentDidUpdate(prevProps: Props) {
        // check if selected line changed
        if (
            this.props.line_external_id !== prevProps.line_external_id ||
            this.props.material_external_id !== prevProps.material_external_id ||
            this.props.process_num !== prevProps.process_num
        ) {
            await this.loadModelInfo();
        }
    }

    async onTabSelect(tab_key: TabKey): Promise<void> {
        // TODO lazy loading

        this.setState({ tab_key });
    };

    onQuantityChange(val: string): void {
        this.setState({ calc_data_q: parseFloatSmart(val, 0) }, () => {
            const q = this.state.calc_data_q;
            const slope = (this.state.model_info !== null ? this.state.model_info.slope : 0);
            const intercept = (this.state.model_info !== null ? this.state.model_info.intercept : 0);
            const t = q * slope + intercept;
            const tn = q * this.state.last_order_slope; // if not set, it is negative
            this.setState({ calc_data_t: t, calc_data_t_norm: tn });
        })
    }

    /**
     * Render component.
     */
    render() {
        const model_info = this.state.model_info;
        const material = this.state.material;
        if (model_info === null || material === null) {
            return "Loading..."; // TODO: better loading
        }
        const cells_main: Cell[][] = [
            [{
                label: "Material",
                text: this.props.material_external_id
            },
            {
                label: "Material title",
                text: material.title
            },
            {
                label: "Line",
                text: this.props.line_external_id
            },
            {
                label: "Process_num",
                text: this.props.process_num
            }]
        ];
        const cells_numerics: Cell[][] = [
            [{
                label: "Slope",
                text: "" + model_info.slope
            }],
            [{
                label: "Intercept",
                text: "" + model_info.intercept
            }],
            [{
                label: "Number of residuals",
                text: "" + model_info.residual_count
            }],
            [{
                label: "Time for 1000 units",
                text: niceNumber(model_info.prediction_1000units, 2)
            }],
            [{
                label: "Units in 1h",
                text: niceNumber(model_info.prediction_1h, 2)
            }]
        ];

        const last_order = this.state.latest_orders[0];
        let last_order_slope = last_order != undefined ? last_order.norm_machine_time / last_order.norm_base_units : -1;
        if (isNaN(last_order_slope)) {
            last_order_slope = -1;
        }
        if (last_order_slope != this.state.last_order_slope) {
            this.setState({ last_order_slope });
        }

        const input_data = this.state.input_data;
        const max_x = Math.max(...input_data.data.map(rec => rec.x));
        const normative_series_name = "Normative";
        const chart_options = {
            chart: {
                width: 500, // TODO make responsive
                height: 300,
            },
            title: {
                text: `Model vs historical data`,
            },
            yAxis: {
                title: {
                    text: 'Values',
                },
            },
            series: [
                {
                    name: "Model",
                    type: "line",
                    color: "#0f0",
                    marker: {
                        symbol: 'circle'
                    },
                    data: [[0, model_info.intercept], [max_x, max_x * model_info.slope + model_info.intercept]]
                },
                {
                    name: normative_series_name,
                    type: "line",
                    color: "#00f",
                    marker: {
                        symbol: 'circle'
                    },
                    data: [[0, 0], [max_x, max_x * last_order_slope]]
                },
                {
                    name: "Data",
                    type: "scatter",
                    color: "#aaa",
                    marker: {
                        symbol: 'circle'
                    },
                    data: input_data.data.map(rec => [rec.x, rec.y])
                }
            ]
        };
        if (last_order_slope < 0) {
            chart_options.series = chart_options.series.filter(x => x.name != normative_series_name); // don't show normative series, since we don't have data
        }

        return <div className="container">
            <div className="row">
                <div className="col-12"
                    style={{ padding: "20px", fontSize: "1.5em", backgroundColor: "#eee" }}>
                    <DataFields rows={cells_main} />
                </div>
            </div>
            <div>
                <Tabs
                    className="background-job-tabs mt-3"
                    bsStyle="pills"
                    animation={false}
                    activeKey={this.state.tab_key}
                    onSelect={e => this.onTabSelect(e)}
                    id="production-planning-tabs"
                >
                    <Tab eventKey="normative" title="Normative comparison" tabClassName="nav-item">
                        {last_order_slope > 0 &&<table className="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>Normative</th>
                                    <th>Model</th>
                                    <th>Diff</th>
                                    <th>Diff %</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{last_order_slope}</td>
                                    <td>{model_info.slope}</td>
                                    <td>{model_info.slope - last_order_slope}</td>
                                    <td>{niceNumber(100 * (model_info.slope - last_order_slope) / last_order_slope, 3)}</td>
                                </tr>
                            </tbody>
                        </table>}
                        {last_order_slope < 0 && <div>Normative not found</div>}
                    </Tab>
                    <Tab eventKey="numerical" title="Numerical properties" tabClassName="nav-item">
                        <DataFields rows={cells_numerics} />
                    </Tab>
                    <Tab eventKey="latest" title="Latest orders" tabClassName="nav-item">
                        <OrderListSimple orders={this.state.latest_orders} />
                    </Tab>
                    <Tab eventKey="prod_func" title="Production function" tabClassName="nav-item">
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={chart_options}
                        />
                        <div className="mt-3">
                            <b>Quick model evaluation</b>
                        </div>
                        <div className="input-group">
                            <label>
                                Quantity
                            </label>
                        </div>
                        <div className="input-group">
                            <input
                                type="text"
                                className="form-control form-control-sm h-auto"
                                placeholder=""
                                value={this.state.calc_data_q}
                                onChange={e => this.onQuantityChange(e.target.value)}
                            />
                        </div>
                        {!isNaN(this.state.calc_data_t) && <div className="input-group">
                            Estimate: {niceNumber(this.state.calc_data_t, 2)} h
                        </div>}
                        {!isNaN(this.state.calc_data_t_norm) && this.state.last_order_slope > 0 && <div className="input-group">
                            Normative: {niceNumber(this.state.calc_data_t_norm, 2)} h
                        </div>}
                    </Tab>
                </Tabs>

            </div>
        </div >;
    }
}

export default ModelDetails;
