// @flow
import * as React from "react";

type Props = {
    //panels: Array<React.Component>
    panels: Array<Object>,
    colSpan: number
};
type State = { };

class Panel extends React.Component<Props, State> {
    constructor() {
        super();
        this.state = { };
    }

    render() {
        let panels = this.props.panels.map((panel, i) => (
            <div key={i}> {panel} </div>
        ));
        return (<div className="dsh_panel">{panels}</div>);
    }
}

export default Panel;
