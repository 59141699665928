// @flow

// main imports
import React, { Component } from "react";

// models
import type { DashboardObj, DashboardBaseObj } from "../lib/Models";

// import subcomponents

// defining types
type Props = {
    selected: DashboardObj,
    dashboards: DashboardBaseObj[],
    handleDashboardClick: any
}

type State = {}

/**
 * Displaying dashboards list.
 */
class DashboardList extends Component<Props, State> {
    /**
     * Handler for clicking the item.
     */
    handleDashboardClick(dashboard: DashboardBaseObj, i: number) {
        // handle click in parent
        this.props.handleDashboardClick(dashboard);

        // remove active show from all links
        let nav = this.refs.nav;
        for (let c of nav.childNodes) {
            c.childNodes[0].className = "nav-link";
        }
    }

    /** Search method within the dashboards list. */
    includes(needle: string, obj: any) {
        if (!needle) return true;
        needle = needle.toLowerCase();
        if (needle.length === 0) return true;
        if ("bottleneck".indexOf(needle) > -1) {
            if (obj.bottleneck === true) return true;
        }
        if (obj.title.toLowerCase().indexOf(needle) > -1) return true;
        if (obj.material_title.toLowerCase().indexOf(needle) > -1) return true;
        if (obj.material_uuid.toLowerCase().indexOf(needle) > -1) return true;
        return false;
    }

    /**
     * Rendering JSX for current component.
     */
    render() {
        const dashboardList = this.props.dashboards.map((dashboard, i) => {
            let active_show = "nav-link";

            if (this.props.selected.uuid === dashboard.uuid) {
                active_show += " active show";
            }

            return (
                <li className="nav-item" key={i} onClick={() => this.handleDashboardClick(dashboard, i)}>
                    <a ref={"a" + i } className={ active_show } data-toggle="tab">
                        <span className="bold">{ dashboard.title }</span>&nbsp;
                        {dashboard.is_system ? (<span className="badge badge-warning">system</span>) : null}&nbsp;
                        {dashboard.active ? (<span className="badge badge-success">active</span>) : null}&nbsp;
                        {dashboard.public ? (<span className="badge badge-info">public</span>) : null}
                    </a>
                </li>
            )
        })

        return (
            <ul className="nav nav-tabs">
                <div className="nav-tabs-parent" ref="nav">
                    {dashboardList}
                </div>
            </ul>
        )
    }
}

export default DashboardList;
