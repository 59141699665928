// @flow
import type { PayloadAction } from "../types";

export const SET_STOCK_STATUS_FILTERS: "stock_status_filters" = "stock_status_filters";

export type StockStatusFiltersState = {
    selected_display_type: "in" | "out",
    selected_line_uuid: string,
    order_external_id: string
}

export type CompressedStockStatusFilters = {
    ssf_sdt: $PropertyType<StockStatusFiltersState, "selected_display_type">,
    ssf_slu: $PropertyType<StockStatusFiltersState, "selected_line_uuid">,
    ssf_oei: $PropertyType<StockStatusFiltersState, "order_external_id">
}

export const ssf_initial_state: StockStatusFiltersState = {
    selected_display_type: "out",
    selected_line_uuid: "",
    order_external_id: ""
}

export const StockStatusFiltersCompressed = {
    selected_display_type: "ssf_sdt",
    selected_line_uuid: "ssf_slu",
    order_external_id: "ssf_oei"
}

export type SetStockStatusFiltersAction = PayloadAction<typeof SET_STOCK_STATUS_FILTERS, StockStatusFiltersState>;

export const compressStockStatusFiltersParams = (data: StockStatusFiltersState): CompressedStockStatusFilters => {
    return {
        ssf_sdt: data.selected_display_type,
        ssf_slu: data.selected_line_uuid,
        ssf_oei: data.order_external_id
    }
}

const getParam = (url_params: any, param: string) => {
    return url_params.get(StockStatusFiltersCompressed[param]) || ssf_initial_state[param];
}

export const diffSSFFiltersAndURLParams = (selected_ssf: StockStatusFiltersState, hash: string): ?StockStatusFiltersState => {
    if (!hash) { hash = window.location.hash; }
    const url_params = new URLSearchParams(hash.split("#")[1]);

    const new_filters: $Shape<StockStatusFiltersState> = {};
    let has_differences = false;
    const param_sdt = getParam(url_params, "selected_display_type");
    const sdt = param_sdt === "in" ? "in" : "out";
    if (selected_ssf.selected_display_type !== sdt) {
        has_differences = true;
        new_filters.selected_display_type = sdt;
    } else if (sdt) {
        new_filters.selected_display_type = sdt;
    }

    const param_slu = getParam(url_params, "selected_line_uuid");
    if (selected_ssf.selected_line_uuid !== param_slu) {
        has_differences = true;
        new_filters.selected_line_uuid = param_slu;
    } else if (sdt) {
        new_filters.selected_line_uuid = param_slu;
    }

    const param_oei = getParam(url_params, "order_external_id");
    if (selected_ssf.order_external_id !== param_oei) {
        has_differences = true;
        new_filters.order_external_id = param_oei;
    } else if (param_oei) {
        new_filters.order_external_id = param_oei;
    }

    if (has_differences) {
        return new_filters;
    }
}

export const stockStatusFiltersReducer = (state: StockStatusFiltersState, action: SetStockStatusFiltersAction) => {
    if (!state) state = { ...ssf_initial_state };

    if (action.type === SET_STOCK_STATUS_FILTERS) {
        state = {
            ...ssf_initial_state,
            ...action.data
        };
    }

    return state;
}
