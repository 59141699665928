// @flow

import * as React from "react";

import { translate } from "../IntlProviderWrapper";
import ErrorComponent from "../ErrorComponent";

type Props = {};
type State = {};

class KioskUndefined extends React.Component<Props, State> {

    constructor() {
        super();
    }

    render() {

        return (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                <ErrorComponent
                    type="error"
                    msg={translate("common.kiosk_screen_error", "Kiosk screen error")}
                />
            </div>
        );
    }
}

export default KioskUndefined;
