// @flow

import * as React from "react";
import { Link } from "react-router-dom";
import ReactRouterPropTypes from "react-router-prop-types";

import { getBackend as getBackend2 } from "../lib/backend/Backend2";
import * as t from "../lib/backend/datasources.generated.types";
import { convertToNumbersOrNull } from "../lib/Util";
import ErrorComponent from "./ErrorComponent";

type State = {
    // parameters for creating new analysis
    update_analysis_params: t.IUpdateAnalysisReq,
    // if new analysis was submitted
    submitted: boolean,
    // error messages
    message: string,
    analysis_data: t.IAnalysisModelEx | null
};

type Props = {
    match: ReactRouterPropTypes.match,
    history: ReactRouterPropTypes.history,
    location: ReactRouterPropTypes.location
}

const ParameterTitles = {
    alert_above_cdf: "Alert above CDF",
    alert_below_cdf: "Alert below CDF",
    eps: "Eps parameter of quantiles",
    init_min_vals: "Minimal data points",
    threshold_low: "Low threshold",
    threshold_high: "High threshold",
    min_outage_duration: "Minimum outage duration (in ms)",
    data_delay: "Data delay (in ms)"
};

function getCaption(key: string) {
    return ParameterTitles[key] || key;
}

class AnalysisEdit extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        const state: State = {
            update_analysis_params: {
                id: "",
                params: {}
            },
            submitted: false,
            message: "",
            analysis_data: null
        };
        this.state = state;
    }

    // check all the parameters entered are valid
    validateShallow(): boolean {
        //return true
        const params = this.state.update_analysis_params;
        if (!params || !params.id) {
            return false;
        }
        return (Object.keys(params).length > 0) && (params.id.length > 0);
    };

    validateDeep(): boolean {
        // check if min_outage_duration is not null
        const params = this.state.update_analysis_params.params;
        if (params === null || params === undefined) {
            this.setState({ message: "Parameters not defined" });
            return false;
        }
        if (params.hasOwnProperty("min_outage_duration") && !params.min_outage_duration) {
            this.setState({ message: "Parameter min_outage_duration should not be null" });
            return false;
        }
        // check if data_delay is not null
        if (params.hasOwnProperty("data_delay") && !params.data_delay) {
            this.setState({ message: "Parameter data_delay should not be null" });
            return false;
        }
        this.setState({ message: "" });
        return true;
    };

    handleInputChange = (event: Event) => {
        if (event.currentTarget instanceof HTMLInputElement) {
            // read new values
            const target = event.currentTarget;
            const value = target.value;
            const name = target.name;

            // update new datasource placeholder
            this.setState(state => {
                state.update_analysis_params.params = state.update_analysis_params.params || {};
                state.update_analysis_params.params[name] = value;
                return state;
            });
        }
    };

    handleSubmit = async (event: Event) => {
        if (this.validateDeep()) {
            try {
                await getBackend2().datasources.updateAnalysis(
                    convertToNumbersOrNull(this.state.update_analysis_params)
                );
                this.setState({ submitted: true });
            } catch (err) {
                this.setState({ message: err.message });
            }
        }
    };

    showParametersList(items: any) {
        let params = (items === "") ? [] : Object.keys(items.params);
        params = params.filter(x => x !== "type");
        return (
            <div className="form-group">
                <h4>Type: {items.params.type}</h4>
                <br />
                {params.map((key) => (
                    <div className="form-group row" key={key}>
                        <div className="col-md-4">
                            <label className="col-form-label">{getCaption(key)}</label>
                        </div>
                        <div className="col-md">
                            {Number.isNaN(Number(items.params[key])) &&
                                <input type="text" className="form-control"
                                    value={items.params[key]}
                                    name={key}
                                    disabled={key === "type" || key === "eps" || key === "init_min_vals"}
                                    onChange={(event) => { this.handleInputChange(event) }}
                                    placeholder={items.params[key]} />}
                            {!Number.isNaN(Number(items.params[key])) &&
                                <input type="number" className="form-control"
                                    value={items.params[key]}
                                    name={key}
                                    disabled={key === "type" || key === "eps" || key === "init_min_vals"}
                                    onChange={(event) => { this.handleInputChange(event) }}
                                    placeholder={items.params[key]} />}
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    renderMessage() {
        if (this.state.message) {
            return (
                <ErrorComponent msg={this.state.message} type="warning" />
            );
        }
    };

    renderSuccessMessage() {
        if (this.state.submitted) {
            return (
                <ErrorComponent msg={"Successfuly updated parameters of analysis."} type="success" />
            )
        }
    }

    async componentDidMount() {
        try {
            const res = await getBackend2().datasources.getSingleAnalysis({ id: this.props.match.params.an_uuid });
            this.setState({
                update_analysis_params: {
                    id: res.data.uuid,
                    params: res.data.analysis.params,
                    tags: res.data.analysis.tags
                },
                analysis_data: res.data
            });
        } catch (err) {
            this.setState({ message: err.message });
        }
    }

    render() {
        return (
            <div key="ds_new">
                <section key="1">
                    <img src="../img/banner.png" alt="banner" className="img-fluid-header banner_img" />
                </section>
                <section id="profile_edit" className="new_data_source" key="2">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="white_box">
                                    <div className="tab-content">
                                        {this.state.message && this.renderMessage()}
                                        <h3>Edit Analysis Parameters</h3>
                                        {this.showParametersList(this.state.update_analysis_params)}
                                        {this.state.submitted && !this.state.message && this.renderSuccessMessage()}
                                        <button className="btn_save" onClick={this.handleSubmit}
                                            disabled={!this.validateShallow()}>Save</button>
                                        <br />
                                        {this.state.submitted && this.state.analysis_data && this.state.analysis_data.datasources.length > 0 &&
                                            <Link className="blue_link text-center"
                                                to={"/datasources/" + this.state.analysis_data.datasources[0]}>Back to datasource &#187;</Link>
                                        }
                                    </div>
                                </div>
                                <div className="space30"></div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
};

export default AnalysisEdit;
