// @flow
import * as React from "react";
import { FormattedMessage } from "react-intl";
import * as t from "../lib/backend/users.generated.types";
import * as Auth from "../lib/Auth";
import TwoFA from "./TwoFA";
import { USER_TAGS_ACCESS } from "../lib/CommonTags.generated";
import { getBackend as getBackend2 } from "../lib/backend/Backend2";

type Props = {
    history: Array<mixed>
};

type State = {
    redirect_url: string,
    show_success: boolean,
    user_info?: t.IGetCurrentUserDataRes,
};

class TwoFAStandalone extends React.Component<Props, State> {
    constructor() {
        super();
        this.state = {
            redirect_url: Auth.getLoginRedirectURL(),
            show_success: false
        };
    }

    async componentDidMount () {
        const user_info = await getBackend2().users.getCurrentUserData({});
        this.setState({user_info})
    }

    onClose() {
        const redirectURL = Auth.getLoginRedirectURL();
        this.props.history.push(redirectURL);
    }

    onSave() {
        this.setState({show_success: true});
    }

    render() {
        if (
            this.state.user_info && this.state.user_info.tags &&
            !this.state.show_success && !USER_TAGS_ACCESS.twofa(this.state.user_info.tags)
        ) {
            return (
                <section id="register" key="1">
                    <div className="caption_div">
                        <div className="form_box">
                            <TwoFA showQRCode={true} twofaEnable={true} onClose={() => this.onClose()} onSave={() => this.onSave()} />
                        </div>
                    </div>
                </section>
            );
        } else {
            return (
                <section id="register" key="1">
                    <div className="caption_div">
                        <div className="form_box">
                            <div>
                                <FormattedMessage id="common.close" defaultMessage="Close" />
                            </div>
                            <button className="btn_cancel btn" onClick={() => this.onClose()}>
                                <FormattedMessage id="common.close" defaultMessage="Close" />
                            </button>
                        </div>
                    </div>
                </section>
            );
        }
    }
};

export default TwoFAStandalone;
