// @flow

import React from "react";

type Props = { value: number };

const ShiftNumber = ({ value }: Props) => {

    return (
        <div className={"worker-assignments-table-shift_" + value}> <span>{value}</span> </div>
    )
};

export default ShiftNumber;
