// @flow

import React from "react";
import SvgIcon, { type SvgIconProps } from "../SvgIcon";

const User = (props: SvgIconProps) => (
    <SvgIcon {...props}>
        <svg viewBox="0 0 17 17">
            <path d="m 12.45,10.027209 h -8 c -1.8,0 -3.5,1 -4.3,2.5 0.7,1.1 1.7,2.1 2.8,2.8 4.6,3 10.8,1.8 13.9,-2.8 -0.9,-1.5 -2.6,-2.5 -4.4,-2.5 z" />
            <circle r="4" cy="4.0272083" cx="8.4499998" />
        </svg>
    </SvgIcon>
);

export default User;
