// @flow

import React from "react";

// $FlowFixMe
import { ReactComponent as ChevronDownIcon } from "@fortawesome/fontawesome-free/svgs/solid/chevron-down.svg";
import SvgIcon, { type SvgIconProps } from "../SvgIcon";

const ChevronDown = (props: SvgIconProps) => (
    <SvgIcon {...props} size={props.size || "fs"}>
        <ChevronDownIcon />
    </SvgIcon>
);

export default ChevronDown;
