// @flow
import React from "react";
import { DraggableConsumer } from "./Draggable/DraggableContext";

import type { DraggableProviderContext } from "./Draggable/DraggableContext";

type Props = {
    is_dragging: boolean,
    bar_height: number
};
type State = {};

const SCROLL_THRESHOLD = 30;

class AutomaticScrollEvent extends React.Component<Props, State> {

    event_listener = null;

    constructor(props: Props) {
        super(props);
        this.event_listener = (e) => this.onMouseMoveScrollPane(e);
        window.addEventListener("mousemove", this.event_listener, { capture: true });
    }

    componentWillUnmount() {
        if (this.event_listener) {
            window.removeEventListener("mousemove", this.event_listener);
        }
    }

    onMouseMoveScrollPane = (e: MouseEvent) => {
        if (!this.props.is_dragging) {
            return;
        }

        const pane1 = document.querySelector("#pane1");
        const gridgroup_header = document.querySelector("#grid-groups-header-shifts");

        if (pane1 && gridgroup_header) {
            const rect = pane1.getBoundingClientRect();
            const gridgroup_header_height = gridgroup_header.getBoundingClientRect().height;
            const line_label = document.querySelector("#line-labels-group-white-rect");
            let line_label_width = 0;
            const client_x = e.clientX;
            const client_y = e.clientY;

            const rect_x = rect.left;
            const rect_top = rect.top;

            if (line_label) {
                const rect = line_label.getBoundingClientRect();
                line_label_width = rect.width;
            }
            if ((client_x) > (rect_x + rect.width - SCROLL_THRESHOLD)) {
                pane1.scrollLeft += SCROLL_THRESHOLD * 2;
            } else if (client_x > (rect_x + line_label_width) && client_x < (rect_x + line_label_width + SCROLL_THRESHOLD)) {
                pane1.scrollLeft -= SCROLL_THRESHOLD * 2;
            }

            if (client_y < (rect_top + gridgroup_header_height + SCROLL_THRESHOLD)) {
                pane1.scrollTop -= SCROLL_THRESHOLD / 2;
            } else if (client_y > (rect_top + rect.height - SCROLL_THRESHOLD)) {
                pane1.scrollTop += SCROLL_THRESHOLD / 2;
            }
        }
    }

    render() {
        return null
    }
}

type AutomaticScrollProps = {
    bar_height: number
};

export const AutomaticScroll = (props: AutomaticScrollProps) => {
    return <DraggableConsumer>
        {(value: ?DraggableProviderContext) => {
            if (!value) {
                return null;
            }
            return <AutomaticScrollEvent is_dragging={!!value.state.dragging_order} bar_height={props.bar_height}/>;
        }}
    </DraggableConsumer>;
}
