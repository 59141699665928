// @flow

import * as React from "react";

type Props = {
    items: React.Node[],
    progress: number[],
    className?: string
};

const ChecklistProgress = ({ items, progress, className = "", ...other }: Props) => (
    <ul className={`checklist-progress list-unstyled ${className}`} {...other}>
        {items.map((item, i) => {
            let item_class = "waiting";
            let icon = <i className="far fa-square" aria-hidden="true" />;

            if (progress[i] !== undefined) {
                if (progress[i] === 0) {
                    item_class = "in-progress";
                    icon = <i className="fas fa-spinner fa-spin" aria-hidden="true" />;
                } else if (progress[i] > 0) {
                    item_class = `success success-${progress[i]}`;
                    icon = <i className="fas fa-check-square" aria-hidden="true" />;
                } else if (progress[i] < 0) {
                    item_class = `error error-${-progress[i]}`;
                    icon = <i className="fas fa-minus-square" aria-hidden="true" />;
                }
            }

            return (
                <li key={i} className={item_class}>
                    {icon} {item}
                </li>
            );
        })}
    </ul>
);

export default ChecklistProgress;
