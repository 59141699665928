// @flow

import React from "react";
import Select from "react-select";
import { SET_SELECTED_ORDER_TAG_VALS, RESET_FILTERS } from "../../reducers/reducersFilters/filters";
import { connect } from "react-redux";

import type { SetSelectedOrderTagValsAction, ResetFiltersAction } from "../../reducers/reducersFilters/filters";
import type { LineOrders } from "../../reducers/linesOrders";
import type { ReduxState } from "../../reducers/reducers";

export type SelectOptionItem = {
    label: string;
    value: string;
}

type Props = {
    orders: LineOrders[],
    order_tag: string,
    palceholder: string,
    reduxDispatch: (args: SetSelectedOrderTagValsAction | ResetFiltersAction) => void,
    selected_order_tag_values: string[]
}

type State = { };

const replaceSpaces = (text: string = "") => {
    return text.split(" ").filter(v => v.trim()).join(" ");
}

const filterOption = (
    candidate: { label: string; value: string; data: any },
    input: string
  ) => {
    if (input) {
        return (
            (replaceSpaces(candidate.label).toLowerCase().includes(replaceSpaces(input).toLowerCase())) ||
            candidate.value === (input || "").trim()
        );
    }
    return true;
}

class OrderTagFilter extends React.Component<Props, State> {
    componentDidMount() {
        const data = {
            selected_order_tag: this.props.order_tag,
            selected_order_tag_values: []
        };
        this.props.reduxDispatch({ type: SET_SELECTED_ORDER_TAG_VALS, data });
    }

    mapLineOrdersToOptions = (lines_orders: LineOrders[]): SelectOptionItem[] => {
        if (!lines_orders) { return []; }

        const order_tag_value_set = new Set();
        const options: SelectOptionItem[] = [];

        for (const line_order of lines_orders) {
            for (const order of line_order.orders) {
                const order_tag_value = order.tags[this.props.order_tag];
                if (order_tag_value && !order_tag_value_set.has(order_tag_value)) {
                    options.push({
                        label: order_tag_value,
                        value: order_tag_value
                    });
                    order_tag_value_set.add(order_tag_value);
                }
            }
        }
        return options;
    }

    getOptions = (): SelectOptionItem[] => {
        return this.mapLineOrdersToOptions(this.props.orders);
    }

    getSelectedOptions = (ids: string[] | null) => {
        if (!ids) {
            return null;
        }
        return this.getOptions().filter(el => ids && ids.includes(el.value)) || null;
    }

    onChange = (option?: SelectOptionItem) => {
        if (!option) {
            this.props.reduxDispatch({ type: RESET_FILTERS, data: undefined });
        } else {
            const data = {
                selected_order_tag: this.props.order_tag,
                selected_order_tag_values: [option.value]
            };
            this.props.reduxDispatch({ type: SET_SELECTED_ORDER_TAG_VALS, data });
        }
    }

    render() {
        return <div className="order-filter">
            <Select
                value={this.getSelectedOptions(this.props.selected_order_tag_values)}
                placeholder={this.props.palceholder}
                options={this.getOptions()}
                onChange={this.onChange}
                isSearchable={true}
                isClearable
                filterOption={filterOption}
            />
        </div>
    }
}

export default connect(
    (state: ReduxState, props: Props) => {
        const orders = [
            ...state.gantt_chart_lines_orders.lines_orders,
            ...state.gantt_chart_lines_orders.unscheduled_orders
        ];
        const selected_order_tag_values = state.gantt_chart_filters.selected_order_tag_values;

        return {
            selected_order_tag_values,
            orders
        };
    },
    (dispatch) => ({ reduxDispatch: dispatch })
)(OrderTagFilter);