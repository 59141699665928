// @flow

// main imports
import React, { Component } from "react";
import ReactRouterPropTypes from "react-router-prop-types";

// backend
import { getBackend } from "../../lib/backend/Backend2";
import {
    waitForManufacturingAsync, getLineGroupsForUser, filterLinesForUser
} from "../../lib/BusinessLogic";
import * as t from "../../lib/backend/manufacturing2.generated.types";

// import subcomponents
import PlantsList from "./PlantsList";
import PlantsDetails from "./PlantsDetails";
import ErrorComponent from "../ErrorComponent";
import { translate } from "../IntlProviderWrapper";
import { ResourcesLayout } from "../MainContent";


// defining types
type Props = {
    match: ReactRouterPropTypes.match,
    history: ReactRouterPropTypes.history,
    location: ReactRouterPropTypes.location
}

type State = {
    plants: t.IPlantData[],
    line_groups: t.ILineGroupData[],
    lines: t.ILineData[],
    selected: t.IPlantData | null,
    error: string,
    warning: string,
    title_filter: string
}

/**
 * Displaying production plants list.
 */
class Plants extends Component<Props, State> {
    plantsListRef: { current: null | PlantsList };

    /**
     * Constructor.
     * @param {Props} props Props object.
     * @param {State} state State object.
     */
    constructor(props: Props, state: State) {
        super(props);
        this.state = {
            plants: [],
            line_groups: [],
            lines: [],
            selected: null,
            error: "",
            warning: "",
            title_filter: ""
        };
        this.plantsListRef = React.createRef();
    }

    /**
     * Actual loading data from backend.
     */
    async loadComponent() {
        try {
            // Get available plants.
            await waitForManufacturingAsync();
            const res = await getBackend().manufacturing.getPlants({});
            const plants = res.plants;
            const line_groups = getLineGroupsForUser();
            const lines = filterLinesForUser(
                (await getBackend().manufacturing.getLines({})).lines, true
            );
            let selected = null;

            // Select initial plant.
            if (this.props.match.params.uuid) {
                let filtered = plants.filter(plant => plant.uuid === this.props.match.params.uuid);
                if (filtered.length > 0) {
                    selected = filtered[0];
                }
            } else if (plants.length > 0) {
                selected = plants[0];
            }

            this.setState({
                error: "",
                line_groups,
                lines,
                plants,
                selected,
                warning: ""
            });
        } catch (err) {
            this.setState({ error: err.message });
        }
    }

    /**
     * Mounting data for this component.
     */
    componentDidMount() {
        this.loadComponent();
    }

    /**
     * Select the first plant that matches all the given criteria.
     * @param {string} contentFilter Plant search term.
     * @param {t.IPlantData[]} [plants] List of plants to select from.
     */
    selectPlant(contentFilter: string, plants: t.IPlantData[] = this.state.plants): t.IPlantData | null {
        for (let plant of plants) {
            if (this.plantsListRef.current && this.plantsListRef.current.includes(contentFilter, plant)) {
                return plant;
            }
        }
        return null;
    }

    /**
     * Handling change in any input component.
     */
    handleInputChange = (event: Event) => {
        if (event.currentTarget instanceof HTMLInputElement) {
            // read new values
            const target = event.currentTarget;
            const value = target.value;
            const name = target.name;

            // update new datasource placeholder
            this.setState(prev_state => {
                prev_state[name] = value;
                prev_state.selected = this.selectPlant(value);
                return prev_state;
            });
        }
    };

    /**
     * Handle `click` events for plant items.
     */
    handlePlantClick(plant: t.IPlantData) {
        this.setState({ selected: plant });

        // Update URL.
        this.props.history.push(`/digital-twin/resources/plants/${plant.uuid}`);
    }

    /**
     * Handling error from subordinate component.
     */
    handleToError = (str: string) => {
        this.setState({ error: str });
    }

    /**
     * Rendering JSX for current component.
     */
    render() {
        return (
            <ResourcesLayout>
                <ResourcesLayout.Main>
                    <ResourcesLayout.List>
                        <ResourcesLayout.Search>
                            <div className="form-group form-group-stretch">
                                <input type="text" className="form-control search_bar" placeholder={translate("common.search", "Search") + " ..."}
                                    name="title_filter" value={this.state.title_filter} onChange={this.handleInputChange} />
                            </div>
                        </ResourcesLayout.Search>
                        <PlantsList
                            ref={this.plantsListRef}
                            plants={this.state.plants}
                            selected={this.state.selected}
                            filter={this.state.title_filter}
                            handlePlantClick={(plant) => { this.handlePlantClick(plant) }} />
                    </ResourcesLayout.List>
                    <ResourcesLayout.Content>
                        <ErrorComponent msg={this.state.error} type="error" />
                        <ErrorComponent msg={this.state.warning} type="warning" />
                        <PlantsDetails
                            plantFilter={this.state.selected ? this.state.selected.uuid : ""}
                            selected={this.state.selected}
                            line_groups={this.state.line_groups}
                            lines={this.state.lines}
                        />
                    </ResourcesLayout.Content>
                </ResourcesLayout.Main>
            </ResourcesLayout>
        )
    }
}

export default Plants;
