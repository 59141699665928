// @flow

// main imports
import { Component } from "react";
import * as React from "react";
import SpanButton from "../react/SpanButton";

import { dayString } from "../../lib/Util";
import * as sf from "../../lib/StockForecast";
import * as t from "../../lib/backend/manufacturing2.generated.types";
import MaterialStockForecastHeader from "./MaterialStockForecastHeader";
import MaterialStockForecastBody from "./MaterialStockForecastBody";
import MaterialStockForecastNoMaterials from "./MaterialStockForecastNoMaterials";
import { translate } from "../IntlProviderWrapper";
import MaterialInfoModal from "./MaterialInfoModal";

import type { StockForecastFilterProps, SelectedValueLabelDefaultFilter } from "./MaterialStockForecastView"

export type AggregationTimeWindow = "shift" | "day" | "week";

type Props = {
    shift_headers: sf.ShiftHeader[],
    show_affiliated_stock: boolean,
    safety_stock_show: boolean,
    safety_stock_account_for: boolean,
    materials_changing: t.IMaterialForecast[],
    materials_nonchanging: t.IMaterialForecast[],
    materials_zero: t.IMaterialForecast[],
    forecasts_materials_changing: t.IMaterialForecast[],
    forecasts_materials_nonchanging: t.IMaterialForecast[],
    forecasts_materials_zero: t.IMaterialForecast[],
    selected_subtype: sf.MaterialForecastSubtype,
    selected_aggregate?: string,
    selected_display_type?: string,
    only_accounted_for_initial_stock: string,
    selected_location?: string,
    no_overflow?: boolean,
    getForecast?: Function,
    // redesign
    handleStockForecastDisplayTypeChange?: (value: string) => void,
    changeSelectedLineUUID?: (value: string) => void,
    onChangeOrderExternalId?: (value: string) => void,
    onTitleChange?: (e: Event) => void,
    stock_forecast_filter?: StockForecastFilterProps,
    setDefaultFilter?: (type: SelectedValueLabelDefaultFilter) => () => void,
    changeSelectedHorizonStockForecast?: (value: AggregationTimeWindow) => void,
    hide_display_type_filter?: boolean,
    hide_lines_filter?: boolean,
    show_aggregation_dropdown?: boolean,
    report_id?: string
}

type DayHeader = {
    date: string,
    day: string,
    col_width: number
}

type State = {
    show_material_info_modal: boolean,
    selected_material_external_id: string,
    day_headers: DayHeader[],
    hide_nonchanging: boolean,
    hide_zero: boolean,
    show_selector: boolean,
    selected_aggregate: boolean,
    hide_details: boolean,
    inner_width: number
}

const getStickyOffsetTop = () => {
    if (window.location.href.includes("/manufacturing/stocks/stock_forecast")) {
        const el = document.querySelector(".views-filter-bar") || document.querySelector(".navigation-bar");
        if (el) {
            return (el.offsetTop + el.offsetHeight);
        }
    }

    return 0;
}

export const translateAggregationTimeWindow = (value: string): string => {
    if (value === "shift") {
        return translate("common.shift", "Shift");
    } else if (value === "day") {
        return translate("common.day", "Day");
    } else if (value === "week") {
        return translate("common.week", "Week");
    }

    return "";
}

type SelectedValueLabelProps = {
    setDefaultFilter?: () => void,
    label: string,
    value: string | null,
    hide_clear?: boolean
}

const SelectedValueLabel = (props: SelectedValueLabelProps): React.Node | null => {
    const { label, value, hide_clear } = props;

    if (!value) {
        return null;
    }

    return <div className="stock-forecast-toolbox-selected-value">
        <span>{label}</span>: <span>{value}</span>
        {
            (hide_clear === false || hide_clear === undefined) &&
            <i className="fas fa-times" style={{cursor: "pointer", marginLeft: "5px"}} onClick={props.setDefaultFilter}></i>
        }
    </div>
}

class MaterialStockForecast extends Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            show_material_info_modal: false,
            selected_material_external_id: "",
            day_headers: [],
            hide_nonchanging: true,
            hide_zero: true,
            show_selector: true,
            hide_details: true,
            selected_aggregate: true,
            inner_width: window.innerWidth
        };
    }

    componentDidMount() {
        this.calcDayHeader();
    }

    componentDidUpdate(prev_props: Props) {
        if ((prev_props.shift_headers.length !== this.props.shift_headers.length) ||
            (prev_props.shift_headers.length > 0 && this.props.shift_headers.length > 0 && prev_props.shift_headers[0].date !== this.props.shift_headers[0].date)) {
            this.calcDayHeader();
        }
    }

    calcDayHeader() {
        const day_headers = [];
        for (const shift_header of this.props.shift_headers) {
            if (day_headers.length === 0) {
                // first shift we have
                day_headers.push({
                    date: shift_header.date,
                    day: dayString(shift_header.day_of_week),
                    col_width: 1
                });
            } else if (day_headers[day_headers.length - 1].date === shift_header.date) {
                // shift continues for the day
                day_headers[day_headers.length - 1].col_width = day_headers[day_headers.length - 1].col_width + 1;
            } else {
                // new shift new day
                day_headers.push({
                    date: shift_header.date,
                    day: dayString(shift_header.day_of_week),
                    col_width: 1
                });
            }
        }
        this.setState({ day_headers });
    }

    toggleNonChangingCollapse = (value: boolean) => {
        this.setState({ hide_nonchanging: !value });
    }

    toggleZeroCollapse = (value: boolean) => {
        this.setState({ hide_zero: !value });
    }

    getStockForecastFilterTitle = () => {
        if (this.props.stock_forecast_filter && this.props.stock_forecast_filter.title) {
            return this.props.stock_forecast_filter.title.toLowerCase()
        }

        return "";
    }

    renderTable(
        materials: t.IMaterialForecast[],
        forecasts_materials_changing: t.IMaterialForecast[],
        forecasts_materials_nonchanging: t.IMaterialForecast[],
        forecasts_materials_zero: t.IMaterialForecast[]
    ) {
        const top = getStickyOffsetTop();

        let className = `table dt-responsive nowrap table-shifts table-bordered table-unset-width material-stock-forecast-table`;
        if (!window.location.href.includes("/manufacturing/lines/microplan")) {
            className += " sticky";
        }

        // if we do not show safety stock, we always account for it
        const safety_stock_account_for = this.props.safety_stock_show ?
            this.props.safety_stock_account_for : true;

        return (
            <table className={className}>
                <MaterialStockForecastHeader
                    day_headers={this.state.day_headers}
                    shift_headers={this.props.shift_headers}
                    show_affiliated_stock={this.props.show_affiliated_stock}
                    safety_stock_show={this.props.safety_stock_show}
                    onTitleChange={this.props.onTitleChange}
                    selected_aggregate={this.props.selected_aggregate}
                    stickyOffsetTop={top}
                    // changing stock forecast filter
                    handleStockForecastDisplayTypeChange={this.props.handleStockForecastDisplayTypeChange}
                    changeSelectedLineUUID={this.props.changeSelectedLineUUID}
                    onChangeOrderExternalId={this.props.onChangeOrderExternalId}
                    stock_forecast_filter={this.props.stock_forecast_filter}
                    show_toolbox={this.shouldRenderToolbox()}
                    hide_lines_filter={this.props.hide_lines_filter}
                    hide_display_type_filter={this.props.hide_display_type_filter}
                    changeSelectedHorizonStockForecast={
                        this.props.show_aggregation_dropdown ? this.props.changeSelectedHorizonStockForecast : undefined
                    }
                />

                {materials.length > 0 ? <MaterialStockForecastBody
                    filter_title={this.getStockForecastFilterTitle()}
                    hide_details={this.state.hide_details}
                    shift_headers={this.props.shift_headers}
                    show_affiliated_stock={this.props.show_affiliated_stock}
                    safety_stock_show={this.props.safety_stock_show}
                    safety_stock_account_for={safety_stock_account_for}
                    day_headers={this.state.day_headers}
                    selected_subtype={this.props.selected_subtype}
                    selected_aggregate={this.props.selected_aggregate}
                    materials={materials}
                    // getForecast is used in tooltip when a cell is clicked in combined subtype
                    getForecast={this.props.getForecast}
                    selected_display_type={this.props.selected_display_type}
                    only_accounted_for_initial_stock={this.props.only_accounted_for_initial_stock}
                    selected_location={this.props.selected_location}
                    // forecasts is used to color the non-combined subtype cells the same color as in combined subtype
                    forecasts_materials_changing={forecasts_materials_changing}
                    forecasts_materials_nonchanging={forecasts_materials_nonchanging}
                    forecasts_materials_zero={forecasts_materials_zero}
                    report_id={this.props.report_id}
                    showMaterialInfoModal={(material_external_id: string) => this.setState({selected_material_external_id: material_external_id, show_material_info_modal: true})}
                /> : <tbody><MaterialStockForecastNoMaterials /></tbody>}

            </table>
        )
    }

    hasMaterials = () => {
        return this.props.materials_changing.length === 0 || this.props.materials_nonchanging.length === 0;
    }

    shouldRenderToolbox = (): boolean => {
        return !!this.props.setDefaultFilter;
    }

    renderSelectedToolboxFilters = () => {
        const filter = this.props.stock_forecast_filter;
        if (!filter) return null;

        const order_external_id = (filter.order_external_ids && filter.order_external_ids.length > 0 ? filter.order_external_ids.join(",") : null);

        const selected_line_uuid = filter.selected_line_uuid;

        const selected_line = (
            filter.lines.length > 0 ?
            filter.lines.find(el => el.uuid === selected_line_uuid) :
            null
        )

        const selected_display_type = (
            filter.display_types ?
            filter.display_types.find(el => el.code === filter.selected_display_type) :
            null
        );

        if (!this.shouldRenderToolbox()) return null;

        return <div style={{display: "flex", marginBottom: "10px"}}>
            {!this.props.hide_display_type_filter && <SelectedValueLabel
                label={translate("Manufacturing.StocksView.material_title", "Material")}
                value={selected_display_type ? selected_display_type.title : null}
                hide_clear={!!selected_display_type && selected_display_type.code === sf.MATERIAL_FORECAST_TYPES.out}
                setDefaultFilter={this.props.setDefaultFilter ? this.props.setDefaultFilter("selected_display_type") : undefined}
            />}
            {!this.props.hide_lines_filter && <SelectedValueLabel
                label={translate("common.line", "Line")}
                value={selected_line ? selected_line.title : null}
                setDefaultFilter={this.props.setDefaultFilter ? this.props.setDefaultFilter("line") : undefined}
            />}
            <SelectedValueLabel
                label={translate("common.order", "Order")}
                value={order_external_id}
                setDefaultFilter={this.props.setDefaultFilter ? this.props.setDefaultFilter("order_external_id") : undefined}
            />
        </div>
    }
    renderShiftsToolbox() {
        if (!this.props.changeSelectedHorizonStockForecast || this.props.show_aggregation_dropdown) return null;

        let selected_aggregate = "shift";
        if (this.props.stock_forecast_filter) {
            selected_aggregate = this.props.stock_forecast_filter.selected_aggregate;
        }

        return <div className="shifts-toolbox">
            <SpanButton
                title={translate("common.shift", "Shift")}
                is_active={selected_aggregate === "shift"}
                onClick={this.props.changeSelectedHorizonStockForecast}
                value={"shift"}
            />
            <SpanButton
                title={translate("common.day", "Day")}
                is_active={selected_aggregate === "day"}
                onClick={this.props.changeSelectedHorizonStockForecast}
                value={"day"}
            />
            <SpanButton
                title={translate("common.week", "Week")}
                is_active={selected_aggregate === "week"}
                onClick={this.props.changeSelectedHorizonStockForecast}
                value={"week"}
            />
        </div>
    }

    getFiltersWidth = () => {
        const views_filters = document.querySelector(".views-filter-bar") || document.querySelector(".navigation-bar");
        if (views_filters) {
            return views_filters.getBoundingClientRect().width + "px";
        }
        return "100%";
    }

    renderHideNonChanging() {
        const hide_title = translate(
            "Manufacturing.MaterialStockForecast.show_nonchanging_stock",
            "Show materials with no changes in stock"
        ) + " (" + (this.props.forecasts_materials_nonchanging.length) + ")"

        const title = translate(
            "Manufacturing.MaterialStockForecast.hide_nonchanging_stock",
            "Hide materials with no changes in stock"
        );

        return <SpanButton
            title={title}
            hide_title={hide_title}
            value={this.state.hide_nonchanging}
            is_active={this.state.hide_nonchanging === false}
            onClick={this.toggleNonChangingCollapse}
        />
    }


    renderShowZeroStock() {
        const hide_title = translate(
            "Manufacturing.MaterialStockForecast.show_zero_stocks",
            "Show materials with empty stock"
        ) + " (" + this.props.materials_zero.length + ")";

        const title = translate(
            "Manufacturing.MaterialStockForecast.hide_zero_stocks",
            "Hide materials with empty stock"
        );

        return <SpanButton
            hide_title={hide_title}
            title={title}
            value={this.state.hide_zero}
            onClick={this.toggleZeroCollapse}
            is_active={this.state.hide_zero === false}
        />
    }

    render() {
        const class_name = this.props.no_overflow ? "chart-wrapper-no-overflow" : "white_box charts";
        const show_toolbox = this.props.stock_forecast_filter && (
            this.shouldRenderToolbox() || (
                this.props.changeSelectedHorizonStockForecast && !this.props.show_aggregation_dropdown
            )
        );
        return (
            <React.Fragment>
                {show_toolbox && (
                    <div className="material-stock-forecast-toolbox" style={{ width: this.getFiltersWidth() }}>
                        {this.renderSelectedToolboxFilters()}
                        {this.renderShiftsToolbox()}
                    </div>
                )}
                <div className={class_name}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        {this.renderTable(
                            this.props.materials_changing,
                            this.props.forecasts_materials_changing,
                            this.props.forecasts_materials_nonchanging,
                            this.props.forecasts_materials_zero
                        )}

                        <div>
                            <div style={{ display: "flex", justifyContent: "flex-start", paddingBottom: "10px" }}>
                                {this.renderHideNonChanging()}
                                {this.renderShowZeroStock()}
                            </div>

                            {!this.state.hide_nonchanging && this.renderTable(
                                    this.props.materials_nonchanging,
                                    this.props.forecasts_materials_changing,
                                    this.props.forecasts_materials_nonchanging,
                                    this.props.forecasts_materials_zero
                            )}
                        </div>

                        <div>
                            {!this.state.hide_zero &&
                                this.renderTable(
                                    this.props.materials_zero,
                                    this.props.forecasts_materials_changing,
                                    this.props.forecasts_materials_nonchanging,
                                    this.props.forecasts_materials_zero
                                )
                            }
                        </div>
                    </div>
                </div>
                {
                    <MaterialInfoModal
                        show={this.state.show_material_info_modal}
                        material_external_id={this.state.selected_material_external_id}
                        onShowOrHideOrderModal={() => this.setState({ show_material_info_modal: false })}
                    />
                }
            </React.Fragment>
        );
    }
}

export default MaterialStockForecast;
