// @flow
import * as React from "react";
import { connect } from "react-redux";
import { DraggableConsumer } from "../Draggable/DraggableContext";
import { GanttChartSource } from "../reducers/properties";

import type { DraggableProviderContext } from "../Draggable/DraggableContext";
import type { TranslatePoint, DraggingOrder } from "../Draggable/reducers";
import type { HighlightShiftLineAction } from "../reducers/planningTable";
import type { LineOrders } from "../reducers/linesOrders";
import type { DraggedOrderState } from "../reducers/draggedOrder";
import type { PropertiesState, GanttChartSourceTypes } from "../reducers/properties";
import type { ReduxState }  from "../reducers/reducers";

type State = {
    visible: boolean
}

export type CursorProps = {
    dragged_order: DraggedOrderState,
    properties: PropertiesState,
    cursor: State,
    valid_lines: LineOrders[] | null,
    reduxDispatch: (args: HighlightShiftLineAction) => void,
    translate_cursor: TranslatePoint,
    dragging_order: DraggingOrder,
    source_type: GanttChartSourceTypes
}


class Cursor extends React.Component<CursorProps, State> {
    state: State = {
        visible: false
    }

    node = null;

    constructor(props) {
        super(props);
        this.node = React.createRef();
    }

    getTransform() {
        if (this.props.translate_cursor) {
            const translate_y = this.props.translate_cursor.translate_y;
            let translate_x = this.props.translate_cursor.translate_x;
            if (this.props.properties.shift_width) {
                translate_x = translate_x - this.props.properties.shift_width / 2;
            }
            return `translate(${translate_x}, ${translate_y})`;
        }
        return "";
    }
    
    getIsPlanningTable = () => {
        return (
            this.props.properties.source_type === GanttChartSource.planning_table ||
            this.props.properties.source_type === GanttChartSource.planning_table_parallel
        )
    }

    render() {
        if (!this.getIsPlanningTable()) {
            return null;
        }

        if (this.props.dragging_order && !this.props.dragging_order.order_uuid || !this.props.dragging_order) {
            return null;
        }

        const width = this.props.properties.shift_width;
        const height = this.props.properties.bar_height;

        return (
            <image
                ref={this.node}
                id="cursor"
                transform={this.getTransform()}
                style={{pointerEvents: "none"}}
                xlinkHref={"/img/cursor.svg"}
                x={0}
                y={0}
                width={width}
                height={height}
                opacity={this.props.translate_cursor !== null ? 1 : 0}
            />
        )
    }
}

const CursorContext = (props: any) => {
    return <DraggableConsumer>
        {(value: ?DraggableProviderContext) => {
            if (!value) {
                return null;
            }

            return <Cursor
                translate_cursor={value.state.translate_cursor}
                dragging_order={value.state.dragging_order}
                {...props}
            >
                {props.children}
            </Cursor>
        }}
    </DraggableConsumer>
}

export default connect(
    (state: ReduxState) => {
        return {
            properties: state.gantt_chart_properties
        }
    },
    (dispatch) => ({reduxDispatch: dispatch})
)(CursorContext);
