// @flow

import React from "react";
import { Modal } from "react-bootstrap";

import Toggle from "react-toggle";
import { niceDate, niceNumber } from "../../../../lib/Util";
import * as t from "../../../../lib/backend/manufacturing2.generated.types";
import ReactTable from "../../../react/ReactTable";
import { translate, getLang } from "../../../IntlProviderWrapper";
import { getBackend as getBackend2 } from "../../../../lib/backend/Backend2";

import type { ReactTableColumn } from "../../../react/ReactTable";


type IStockRequirementsExtended = {
    [key: $Keys<t.IStockRequirementsExt>]: string
}

const getColumns = (hide_columns: boolean): ReactTableColumn<IStockRequirementsExtended>[] => {
    const additionalColumns: ReactTableColumn<$Shape<IStockRequirementsExtended>>[] = [];

    if (!hide_columns) {
        additionalColumns.push({
            "Header": translate("Manufacturing.LinesView.scrap", "Scrap"),
            "accessor": "scrap_quantity",
            disableFilters: true
        });

        additionalColumns.push({
            "Header": translate("Manufacturing.StocksView.date_expected", "Date expected"),
            "accessor": "date_expected",
            disableFilters: true
        });

        additionalColumns.push({
            "Header": translate("common.tag", "Tag"),
            "accessor": "flag",
            disableFilters: true
        });

        additionalColumns.push({
            "Header": translate("common.reschedule", "Reschedule"),
            "accessor": "reschedule_date",
            disableFilters: true
        });

        additionalColumns.push({
            "Header": translate("common.plant", "Plant"),
            "accessor": "plant_external_id",
            disableFilters: true
        });

        additionalColumns.push({
            "Header": translate("Manufacturing.StocksView.vendor_title", "Vendor"),
            "accessor": "vendor_external_id",
            disableFilters: true
        });

        additionalColumns.push({
            "Header": translate("common.location_external_id", "ID stock location"),
            "accessor": "location_external_id",
            disableFilters: true
        });

        additionalColumns.push({
            "Header": translate("common.reservation_order_id", "ID reservation"),
            "accessor": "reservation_order_id",
            disableFilters: true
        });

        additionalColumns.push({
            "Header": translate("common.external_id", "External id"),
            "accessor": "external_code",
            disableFilters: true
        });

        additionalColumns.push({
            "Header": translate("common.exception_message_number", "Exception message number"),
            "accessor": "exception_message_number",
            disableFilters: true
        });

        additionalColumns.push({
            "Header": "",
            "accessor": "vendor_title",
            "filterInputPlaceholder": translate("Manufacturing.StocksView.vendor_title", "Vendor")
        });
    }

    return [
        {
            "Header": translate("common.date", "Date"),
            "accessor": "date_required",
            disableFilters: true
        },
        {
            "Header": translate("common.mrp_element", "MRP element"),
            "accessor": "external_code_localized",
            disableFilters: true
        },
        {
            "Header": translate("common.mrp_data", "MRP element data"),
            "accessor": "transaction_external_id",
            disableFilters: true
        },
        {
            "Header": translate("Manufacturing.StocksView.quantity", "Quantity"),
            "accessor": "quantity",
            disableFilters: true
        },
        {
            "Header": translate("common.quantity_complete", "Quantity available"),
            "accessor": "available_quantity",
            disableFilters: true
        },
        {
            "Header": translate("Manufacturing.StockLocation.Tags.storage_site", "Storage"),
            "accessor": "storage_external_id",
            disableFilters: true
        },
        {
            "Header": translate("common.customer_external_id", "Customer ID"),
            "accessor": "customer_external_id",
            disableFilters: true
        },
        {
            "Header": "",
            "accessor": "customer_title",
            "filterInputPlaceholder": translate("common.customer_title", "Customer title")
        }
    ].concat(additionalColumns)
}

const renderDate = (date: string) => {
    let date_expected = new Date(date);

    if (isNaN(date_expected.getMonth())) {
        date_expected = "-"
    } else {
        date_expected = niceDate(date_expected);
    }

    return date_expected;
}

export type MaterialMD04Data = {
    material_external_id: string,
    material_title: string
}
type MaterialMD04ModalProps = {
    show_modal: boolean,
    onCloseModal: () => void,
    material_external_id: string,
    plant_external_id: string,
    material_title: string,
    materials?: MaterialMD04Data[],
    onSelectedMaterialChange: (value: string) => void
}
type MaterialMD04ModalState = {
    stock_requirements: t.IStockRequirementsExt[],
    is_loading: boolean,
    hide_columns: boolean
}

export class MD04Modal extends React.Component<MaterialMD04ModalProps, MaterialMD04ModalState> {

    state = {
        stock_requirements: [],
        is_loading: true,
        hide_columns: true
    }

    componentDidMount() {
        if (this.props.show_modal) {
            this.load()
        }
    }

    componentDidUpdate(prev_props: MaterialMD04ModalProps) {
        const modal_opened = this.props.show_modal === true && prev_props.show_modal !== this.props.show_modal;
        const materials_changed = prev_props.material_external_id && prev_props.material_external_id !== this.props.material_external_id;
        if (modal_opened || materials_changed) {
            this.load();
        }


    }

    mapData = (sr: IStockRequirementsExtended) => {
        return {
            flag: sr.flag,
            quantity: niceNumber(parseInt(sr.quantity), 2),
            vendor_title: sr.vendor_title,
            date_expected: renderDate(sr.date_expected),
            date_required: renderDate(sr.date_required),
            external_code: sr.external_code,
            customer_title: sr.customer_title,
            scrap_quantity: niceNumber(parseInt(sr.scrap_quantity), 2),
            reschedule_date: renderDate(sr.reschedule_date),
            plant_external_id: sr.plant_external_id,
            available_quantity: niceNumber(parseInt(sr.available_quantity), 0),
            vendor_external_id: sr.vendor_external_id,
            storage_external_id: sr.storage_external_id,
            customer_external_id: sr.customer_external_id,
            location_external_id: sr.location_external_id,
            material_external_id: sr.material_external_id,
            reservation_order_id: sr.reservation_order_id,
            external_code_localized: sr.external_code_localized,
            transaction_external_id: sr.transaction_external_id,
            exception_message_number: sr.exception_message_number
        }
    }

    load = async () => {
        this.setState({ is_loading: true, stock_requirements: []});
        const language_code = getLang().toUpperCase();
        try {
            const res = await getBackend2().manufacturing.runStockRequirementsSimple({
                language_code,
                material_external_id: this.props.material_external_id,
                plant_external_id: this.props.plant_external_id
            });
            const data = res.result.output.map(this.mapData);

            this.setState({ stock_requirements: data, is_loading: false });
        } catch(e) {
            console.error(e);
        }

    }

    toggleHideColumns = () => {
        this.setState({ hide_columns: !this.state.hide_columns})
    }


    onChangeDropdown = (e: Event) => {
        if (e.currentTarget instanceof HTMLSelectElement) {
            const value = e.currentTarget.value;
            this.props.onSelectedMaterialChange(value);
        }
    }

    getMaterialsDropdown = () => {
        if (this.props.onSelectedMaterialChange && this.props.materials && this.props.materials.length > 1) {
            return <div className="form-group">
                <select className="form-control"
                onChange={this.onChangeDropdown}
                value={this.props.material_external_id}
                style={{width: "fit-content", paddingRight: "20px", float: "right"}}
            >
                    {this.props.materials.map(m => {
                        return <option value={m.material_external_id}>{m.material_title}</option>
                    })}
                </select>
            </div>
        }
    }
    render() {
        return (
            <Modal bsSize={"large"} show={this.props.show_modal} onHide={this.props.onCloseModal}>
                <Modal.Header>
                    <div id="material-md04-header" style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                        <div className="stock-table-header__text centered"
                            style={{ marginRight: "5px" }}
                        >
                            <div style={{marginRight: "5px"}}>
                                <span>{translate("common.details", "Details")}</span>
                            </div>
                            <div>
                                <Toggle checked={!this.state.hide_columns} onChange={this.toggleHideColumns}></Toggle>
                            </div>
                        </div>
                        <div className="centered">
                            {this.props.plant_external_id} - {this.props.material_title} - {this.props.material_external_id}
                        </div>
                        <div className="centered">
                            <button type="button" className="close" onClick={this.props.onCloseModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body
                    id="md04-stock-requirements-table"
                    style={{overflow: "auto", maxHeight: "80vh", paddingTop: "unset", minHeight: "500px"}}
                >
                    <div>
                        {this.getMaterialsDropdown()}
                    </div>
                    <ReactTable
                        data={this.state.stock_requirements}
                        columns={getColumns(this.state.hide_columns)}
                        isLoading={this.state.is_loading}
                        small_loader={true}
                    />
                </Modal.Body>
            </Modal>
        )
    }
}
