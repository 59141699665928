// @flow

// main imports
import React, { Component } from "react";
import { connect } from "react-redux";
import ReactRouterPropTypes from "react-router-prop-types";
import { FormattedMessage } from "react-intl";

// models
import type { IUpdateStockLocationReq } from "../../lib/backend/manufacturing2.generated.types";
import { STOCK_LOCATION_TAGS } from "../../lib/ManufacturingTags.generated";
import { STOCK_LOCATION_TAGS as STOCK_LOCATION_TAGS2 } from "../../lib/ManufacturingTagsEditor.generated"
import * as BusinessLogic from "../../lib/BusinessLogic";
import type { ITags } from "../../lib/Models";

// subcomponents
import ErrorComponent from "../ErrorComponent";
import Toggle from "react-toggle";
import { translate } from "../IntlProviderWrapper";

// backend
import { getBackend } from "../../lib/backend/Backend2";
import ReactTable from "../react/ReactTable";
import {
    mapData,
    Columns,
    updateTagsConfig,
    onChangeUpdateTags,
    getInitialState
} from "../react/ReactTableTags";

import type { ResourceEditFiltersState } from "../redux/reducers/resourceEditFiltersReducer";

import { getLang } from "../IntlProviderWrapper";
import { getLinkForUserDocumentation, UserDocumentationLinksEnum } from "../../lib/Util";

// type definitions
type ITagDisplay = {
    name: string,
    id: string
}

type Props = {
    match: ReactRouterPropTypes.match,
    history: ReactRouterPropTypes.history,
    location: ReactRouterPropTypes.location,
    resource_edit_filters: ResourceEditFiltersState
}

export type State = {
    uuid: string,
    external_id: string,
    title: string,
    production_site: boolean,
    storage_site: boolean,
    tags: ITags,
    error: string | null,
    warning: string
}

/**
 * Form to create new line.
 */
export class StockLocationEdit extends Component<Props, State> {

    /**
     * Constructor.
     *
     * @param {Props} props Props object.
     * @param {State} state State object.
     */
    constructor(props: Props, state: State) {
        super(props);
        this.state = {
            uuid: "",
            external_id: "",
            title: "",
            production_site: false,
            storage_site: false,
            tags: {
                production_site: "false",
                storage_site: "false",
            },
            error: "",
            warning: ""
        }
    }

    /**
     * Loading component data (plants).
     */
    async componentDidMount() {
        // load stock location data
        const { uuid } = this.props.match.params;

        const { locations } = await getBackend().manufacturing.getStockLocations({});
        const stock_location = locations.filter(sl => sl.uuid === uuid);

        if (stock_location.length === 0) {
            this.setState({ error: `Invalid stock location ${uuid}` });
        } else {
            this.setState({
                uuid,
                external_id: stock_location[0].external_id,
                title: stock_location[0].title,
                production_site: stock_location[0].tags[STOCK_LOCATION_TAGS.production_site] === "true",
                storage_site: stock_location[0].tags[STOCK_LOCATION_TAGS.storage_site] === "true",
                tags: stock_location[0].tags
            });
        }
    }

    /**
     * Basic form validation.
     */
    validateShallow() {
        return (this.state.title.length > 0 && this.state.external_id.length > 0);
    }

    /**
     * Handling change of any input component.
     */
    handleInputChange = (event: Event) => {
        if (event.currentTarget instanceof HTMLInputElement || event.currentTarget instanceof HTMLSelectElement) {
            // read new values
            const target = event.currentTarget;
            let value = target.value;
            const name = target.name;

            // hack for toggle element
            if (Object.keys(STOCK_LOCATION_TAGS).includes(name)) {
                //$FlowFixMe
                value = target.checked;
            }

            // update new datasource placeholder
            this.setState(prev_state => {
                prev_state[name] = value;
                return prev_state;
            });

            // handle tags
            if (Object.keys(STOCK_LOCATION_TAGS).includes(name)) {
                let tags = this.state.tags || {};
                tags[name] = value.toString();
                this.setState({ tags: tags });
            }
        }
    };

    /**
     * Handling submission of the form. Basic checks and saves with error handling.
     */
    handleSubmit = async () => {
        this.setState({ error: null });

        try {
            if (this.validateShallow()) {
                // update current object
                let stock_location: IUpdateStockLocationReq = {
                    uuid: this.state.uuid,
                    external_id: this.state.external_id,
                    title: this.state.title,
                    tags: this.state.tags
                }
                await getBackend().manufacturing.updateStockLocation(stock_location);
                // force refresh cache to get new line group tags
                await BusinessLogic.forceManufacturingCacheRefresh();
                this.props.history.push("/digital-twin/resources/stock_locations/" + this.state.uuid);
            } else {
                this.setState({
                    warning: translate("Manufacturing.StockLocations.cannot_submit_missing_values", "Cannot submit a form with missing values")
                });
            }
        } catch (err) {
            this.setState({ error: err.message });
        }
    }

    /**
     * Renders tag.
     * @param {ITagDisplay} tag
     */
    renderTagField(tag: ITagDisplay) {
        return <div className="form-group" key={tag.id}>
            <label>{tag.name}</label><br />
            <Toggle
                name={tag.id}
                checked={this.state[tag.id]}
                onChange={this.handleInputChange} />
        </div>
    }

    onChangeCell = (name: string) => (value: boolean) => {
        this.setState({ tags: onChangeUpdateTags(this.state.tags, name, value) });
    }

    rowStyle = (row: any) => {
        let style = {};

        let mandatoryCell = row.cells.filter(cell => cell.column.id === "mandatory");

        if (mandatoryCell.length > 0) {
            mandatoryCell = mandatoryCell[0];

            if (mandatoryCell.column.id === "mandatory") {
                style["backgroundColor"] = "white";
            }

            if (mandatoryCell.value.value !== true && mandatoryCell.row.values.tag &&
                mandatoryCell.row.values.tag.value === undefined) {
                style["backgroundColor"] = "#eaeaea";
            }
        }

        return style;
    }

    getEditableTag = (element: any) => {
        return (!element.hasOwnProperty("editable") || (element.hasOwnProperty("editable") && element.editable !== false));
    }

    getFilteredStockLocationTags = () => {
        let STOCK_LOCATION_TAGS2_COPY = { ...STOCK_LOCATION_TAGS2 }
        const editable_values = STOCK_LOCATION_TAGS2_COPY["values"].filter(element => this.getEditableTag(element));
        STOCK_LOCATION_TAGS2_COPY["values"] = editable_values;
        return STOCK_LOCATION_TAGS2_COPY;
    }

    /**
     * Rendering JSX for current component.
     */
    render() {
        return (
            <div key="ds_new">
                <section key="1">
                    <img src="/img/banner.png" alt="banner" className="img-fluid-header banner_img" />
                </section>
                <section id="profile_edit" className="new_line" key="2">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="white_box">
                                    <div className="tab-content" style={{ maxWidth: "880px" }}>
                                        <h4><FormattedMessage id="Manufacturing.StockLocations.edit_stock_location" defaultMessage="Edit stock location" /></h4>
                                        <ErrorComponent msg={this.state.error} type="error" />
                                        <ErrorComponent msg={this.state.warning} type="warning" />

                                        <div className="form-group">
                                            <label>
                                                <FormattedMessage id="common.title" defaultMessage="Title" />:
                                            </label>
                                            <input name="title" type="text" disabled="" className="form-control"
                                                value={this.state.title}
                                                onChange={this.handleInputChange} />
                                        </div>

                                        <div className="form-group">
                                            <label>
                                                <FormattedMessage id="common.external_id" defaultMessage="External ID" />:
                                            </label>
                                            <input name="external_id" type="text" disabled="" className="form-control"
                                                value={this.state.external_id}
                                                onChange={this.handleInputChange} />
                                        </div>
                                        <a href={getLinkForUserDocumentation(UserDocumentationLinksEnum.stock_locations, getLang())}
                                            target="_blank"
                                            style={{ position: 'relative', float: 'right', paddingTop: '30px' }}>
                                            <img src={"/img/question_light.svg"} style={{ paddingRight: "3px", paddingBottom: "2px" }} />
                                            <FormattedMessage id="Header.menu.user_documentation" defaultMessage="User documentation" />
                                            <img src={"/img/arrow_up_right_corner.svg"} style={{ position: "relative", width: "15px", height: "15px", paddingLeft: "3px", paddingBottom: "4px", bottom: "3px" }} />
                                        </a>
                                        <ReactTable
                                            className="tags-react-table"
                                            data={mapData(this.state.tags, updateTagsConfig(this.getFilteredStockLocationTags(), {}))}
                                            columns={Columns}
                                            onChangeCell={this.onChangeCell}
                                            rowStyle={this.rowStyle}
                                            initialState={getInitialState()}
                                        />

                                        <br />
                                        <br />

                                        <div className="button-list order-last ml-auto">
                                            <button className="btn btn-outline-primary" onClick={this.handleSubmit}>
                                                <FormattedMessage id="common.save" defaultMessage="Save" />
                                            </button>
                                            <button className="btn btn-outline-secondary" onClick={() => this.props.history.goBack()}>
                                                <FormattedMessage id="common.back" defaultMessage="Back" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="space30"></div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default connect(
    state => {
        return { resource_edit_filters: state.resource_edit_filters }
    },
    null
)(StockLocationEdit);
