// @flow
import * as React from "react";
// $FlowFixMe
import { useReducer } from "react";
import { arrayDiff, deepClone } from "../../../../lib/Util";

import type { PayloadAction } from "../../../redux/types";

const SelectedOrdersContext = React.createContext()

export const ADD_ORDER: "add_order" = "add_order";
export const ADD_ORDERS: "add_orders" = "add_orders";
export const SET_DISABLE_CHECKBOXES: "disable_checkboxes" = "disable_checkboxes";
export const RESET_SELECTED_ORDERS: "reset_selected_orders" = "reset_selected_orders";
export const SET_SELECTED_ORDERS: "set_selected_orders" = "set_selected_orders";
export const SET_DISABLED_ORDERS: "set_disable_orders" = "set_disable_orders";

type AddOrderAction = PayloadAction<typeof ADD_ORDER, string>;
type AddOrdersAction = PayloadAction<typeof ADD_ORDERS, string[]>;
type SetDisableAllCheckboxes = PayloadAction<typeof SET_DISABLE_CHECKBOXES, boolean>;
type ResetSelectedOrders = PayloadAction<typeof RESET_SELECTED_ORDERS, typeof undefined>;
type SetSelectedOrders = PayloadAction<typeof SET_SELECTED_ORDERS, string[]>;
type SetDisabledOrders = PayloadAction<typeof SET_DISABLED_ORDERS, string[]>;
export type Actions = AddOrderAction | AddOrdersAction | SetDisableAllCheckboxes | ResetSelectedOrders | SetSelectedOrders | SetDisabledOrders;

type State = {
    selected_order_uuids: string[],
    disable_order_uuids: string[],
    disable_checkboxes: boolean,
    automatic_selection: boolean | null
}

export type SelectedOrdersProviderValue = {
    state: State,
    dispatch: (args: Actions) => void
}

const initial_state: State = {
    selected_order_uuids: [],
    disable_order_uuids: [],
    disable_checkboxes: false,
    automatic_selection: null
}

function savedOrdersReducer(state: State, action: Actions): State {
    if (action.type === ADD_ORDER) {
        const index = state.selected_order_uuids.indexOf(action.data)
        if (index >= 0) {
            state.selected_order_uuids.splice(index, 1);
        } else {
            if (state.disable_order_uuids.includes(action.data)) {
                return state;
            }
            state.selected_order_uuids.push(action.data);
        }
        state.selected_order_uuids = [...state.selected_order_uuids];

        return deepClone({...state, selected_order_uuids: state.selected_order_uuids});
    } else if (action.type === ADD_ORDERS) {
        const selected_order_uuids = arrayDiff<string>(state.selected_order_uuids, action.data);
        if (selected_order_uuids.length < state.selected_order_uuids.length) {
            state.selected_order_uuids = selected_order_uuids;
        } else {
            state.selected_order_uuids = [...selected_order_uuids, ...action.data];
        }

        return { ...state };
    } else if (action.type === SET_DISABLED_ORDERS) {
        state.disable_order_uuids = action.data;
        return { ...state };
    } else if (action.type === SET_SELECTED_ORDERS) {
        state.selected_order_uuids = action.data;
        return { ...state };
    } else if (action.type === SET_DISABLE_CHECKBOXES) {
        state.disable_checkboxes = action.data;
        return { ...state };
    } else if (action.type === RESET_SELECTED_ORDERS) {
        return {
            selected_order_uuids: [],
            disable_order_uuids: [],
            disable_checkboxes: false,
            automatic_selection: state.automatic_selection
        };
    }

    return state;
}

type Props = {
    children: any
}

function SelectedOrdersProvider({children}: Props) {
    const [state, dispatch] = useReducer(savedOrdersReducer, initial_state);
    const value = { state, dispatch };
    return <SelectedOrdersContext.Provider value={value}>{children}</SelectedOrdersContext.Provider>
}

const SelectedOrdersConsumer = SelectedOrdersContext.Consumer;

export { SelectedOrdersProvider, SelectedOrdersConsumer };
