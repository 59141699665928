// @flow

// main imports
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import * as Auth from "../../lib/Auth";
import Authorization from "../Authorization";

// models
import * as t from "../../lib/backend/manufacturing2.generated.types";
import * as BusinessLogic from "../../lib/BusinessLogic";
import { renderTag } from "../../lib/Util";
import { getBackend } from "../../lib/backend/Backend2";

import type { FilterLineGroup } from "./Materials"
import type { IOrderModelBase, IStockLocation } from "../../lib/backend/manufacturing2.generated.types";
import { translate } from "../IntlProviderWrapper";
import ErrorComponent from "../ErrorComponent";

// defining types
type Props = {
    selected: t.IMaterialModel | null,
    selected_line_group: FilterLineGroup[] | null,
    selected_plant_tags: t.IMaterialPlantModel[] | null,
    parallel_selected: t.IParallelMaterialModel | null
}

type State = {
    open_orders: IOrderModelBase[],
    stock_locations: IStockLocation[],
    error: string | null
}
/**
 * Materials details component.
 */
class MaterialsDetails extends Component<Props, State> {

    state = {
        open_orders: [],
        stock_locations: [],
        error: null
    }

    componentDidMount() {
        this.load();
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.selected !== this.props.selected) {
            this.load();
        }
    }

    async load() {
        if (!this.props.selected) {
            return null;
        }
        const {
            uuid: material_uuid,
            external_id: material_external_id
        } = this.props.selected;
        try {
            const res = await getBackend().manufacturing.getOrdersSimple({
                material_uuids: [material_uuid],
                status: "open"
            });

            const stock_res = await getBackend().manufacturing.getStockLocationsForMaterial({ material_external_id });

            this.setState({
                open_orders: res.operations,
                stock_locations: stock_res.stock_locations,
                error: null
            });
        } catch(err) {
            this.setState({
                error: err.message
            });
        }
    }
    /**
     * Render component.
     */
    render() {
        const { selected, parallel_selected } = this.props;

        if (selected === null) {
            return null;
        }

        // render material tags
        let tags = "/";
        if (selected.tags !== undefined || (parallel_selected !== null && parallel_selected.tags !== undefined)) {
            const selected_tags = parallel_selected !== null ? parallel_selected.tags : selected.tags;
            // generate tags
            tags = Object.keys(selected_tags)
                .sort((a, b) => a.localeCompare(b))
                .map((el, i) => renderTag(i, el, selected_tags[el]));
        }

        // prepare plant names
        const plants = BusinessLogic.getPlantsForUser();
        const plant_name_map: Map<string, t.IPlantData> = new Map();
        for (const plant of plants) {
            plant_name_map.set(plant.uuid, plant);
        }

        return (
            <div className="tab-content">
                <ErrorComponent msg={this.state.error} type={'error'} />
                <div className="tab-pane active tab-pane-text">
                    <div className="d-flex">
                        <div className="button-list order-last ml-auto" style={{marginLeft: "auto"}}>
                            <Authorization.Link
                                className="btn btn-outline-primary"
                                to={`/digital-twin/resources/material/edit/${selected.uuid}`}
                                permission={Auth.PERMISSION_NAMES.MaterialEdit}
                            >
                                <FormattedMessage id="common.edit" defaultMessage="Edit" />
                            </Authorization.Link>
                        </div>

                        {(parallel_selected !== null) && <div className="button-list order-last">
                            <Authorization.Link
                                className="btn btn-outline-primary"
                                to={`/digital-twin/resources/material_parallel/edit/${parallel_selected.uuid}`}
                                permission={Auth.PERMISSION_NAMES.MaterialEdit}
                            >
                                <FormattedMessage id="common.edit_parallel" defaultMessage="Edit parallel materials" />
                            </Authorization.Link>
                        </div>}
                        <div className="order-first">
                            <h5 className="pane-title"><em><FormattedMessage id="common.material" defaultMessage="Material" /></em> {selected.title}</h5>
                            <dl className="property-list">
                                <div>
                                    <dt><FormattedMessage id="common.external_id" defaultMessage="External ID" /></dt>
                                    <dd><div>{selected.external_id}</div></dd>
                                </div>
                                <div>
                                    <dt><FormattedMessage id="common.tags" defaultMessage="Tags" /></dt>
                                    <dd>{tags}</dd>
                                </div>
                                <div>
                                    <dt className="light"><FormattedMessage id="common.uuid" defaultMessage="UUID" /></dt>
                                    <dd className="light"><div>{selected.uuid}</div></dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                    <div className="card-deck">
                        {(parallel_selected !== null) && <div className="card">
                            <div className="card-header text-uppercase bg-transparent">
                                <FormattedMessage id="Manufacturing.Materials.parallel_materials" defaultMessage="Multipart materials" />
                            </div>
                            <div className="card-body">
                                <dl className="property-list">
                                    {parallel_selected.child_materials.map((cm, i) => (
                                        <div key={i}>
                                            <dt>{cm.external_id}</dt>
                                            <dd>{cm.title}</dd>
                                        </div>
                                    ))}
                                </dl>
                            </div>
                        </div>}
                    </div><br/>
                    {(this.props.selected_plant_tags !== null && this.props.selected_plant_tags.length > 0) && <div className="card-deck">
                        <div className="card">
                            <div className="card-header text-uppercase bg-transparent">
                                <FormattedMessage id="common.tags" defaultMessage="Tags" />
                            </div>
                            <div className="card-body">
                                <dl className="property-list">
                                    <div>
                                        {this.props.selected_plant_tags
                                            .map((spt, i) => {
                                                const plant = plant_name_map.get(spt.plant_uuid);
                                                if (plant !== undefined) {
                                                    return (
                                                        <React.Fragment>
                                                            <div key={i} style={{width: "100%", height: "30px", marginBottom: "5px"}}>
                                                                <dt>{plant.external_id}</dt>
                                                                <dt>{plant.title}</dt>
                                                                <dd>{Object.keys(spt.tags).map((el, i) => renderTag(i, el, spt.tags[el]))}</dd>
                                                                <Authorization.Link
                                                                    className="btn btn-outline-primary"
                                                                    style={{float: "right"}}
                                                                    to={`/digital-twin/resources/material_plant/edit/${selected.uuid}/${spt.plant_uuid}`}
                                                                    permission={Auth.PERMISSION_NAMES.MaterialEdit}
                                                                >
                                                                    <FormattedMessage id="common.edit" defaultMessage="Edit" />
                                                                </Authorization.Link>
                                                            </div>
                                                        </React.Fragment>
                                                    );
                                                }
                                                return null;
                                            })
                                        }
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </div>}<br/>
                    <div className="card-deck">
                        {(this.state.open_orders && this.state.open_orders.length > 0) && <div className="card">
                            <div className="card-header text-uppercase bg-transparent">
                                {translate('MaterialInfoModal.open_orders', 'Open orders')}
                            </div>
                            <div className="card-body">
                                <dl className="property-list">
                                    {this.state.open_orders.map((cm, i) => (
                                        <div key={i}>
                                            <dt>{cm.external_id}</dt>
                                            <dd>{cm.title}</dd>
                                        </div>
                                    ))}
                                </dl>
                            </div>
                        </div>}
                    </div>
                    <div className="card-deck">
                        {(this.state.stock_locations && this.state.stock_locations.length > 0) && <div className="card">
                            <div className="card-header text-uppercase bg-transparent">
                                {translate('Header.menu.stock_locations', 'Stock Locations')}
                            </div>
                            <div className="card-body" style={{maxHeight: "40vh", overflow: "scroll"}}>
                                <dl className="property-list">
                                    {this.state.stock_locations.map((cm, i) => (
                                        <div key={i}>
                                            <dt>{cm.external_id}</dt>
                                            <dd>{cm.title}</dd>
                                        </div>
                                    ))}
                                </dl>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        )
    }
}

export default MaterialsDetails;
