// @flow
import * as React from "react";
import { translate } from "../IntlProviderWrapper";

import type {
    WeeklyReport,
    RealizationDayShiftGrid,
    RealizationWeekShiftGrid
} from "../../lib/Models";
import type { InsightWeekShiftGrid } from "../../lib/Insights";
import * as t2 from "../../lib/SimulationReportModels";
import { INSIGHT_TYPES } from "../../lib/ManufacturingTags.generated";
import {
    niceNumber, orderColorCSS, dayStrings, getDateOfISOWeek,
    toISODateString, addHours, niceShortDate
} from "../../lib/Util";
import {
    getInsightTooltipText,
    getInsightTooltipTextToolSetup,
    getOrderModelParamsTooltip
} from "../../lib/BusinessLogic";

type Props = {
    shifts: RealizationWeekShiftGrid,
    orders: Map<string, t2.ISimulationReportOrderEx>,
    insights: InsightWeekShiftGrid,
    week: number,
    year: number,
    current_week: number,
    current_shift: number,
    show_dates?: boolean,
    handleClick: Function
};

type State = {
    days: string[],
    dates: string[]
};

class ShiftTableProduction extends React.Component<Props, State> {
    constructor() {
        super();

        const width = window.innerWidth - 70;
        this.state = {
            days: this.getDays(width),
            dates: []
        };
    }

    resizeListener = null;

    componentDidMount() {
        if (!this.resizeListener) {
            this.resizeListener = () => this.updateDimensions();
        }
        window.addEventListener("resize", this.resizeListener);
        this.updateDimensions();

        const dates = [ ];
        let start_date = getDateOfISOWeek(this.props.week, this.props.year);
        for (let day_of_week = 0; day_of_week < 7; day_of_week++) {
            dates.push(toISODateString(start_date).slice(5));
            start_date = addHours(start_date, 24);
        }
        this.setState({ dates });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resizeListener);
        this.resizeListener = null;
    }

    updateDimensions() {
        const width = window.innerWidth - 70;
        this.setState({ days: this.getDays(width) });
    }

    getDays(width: number) {
        return dayStrings(true)
            .map(day => {
                return width < 1400 ? day.substr(0, 1) :
                    width < 1600 ? day.substr(0, 2) :
                    day;
            });
    }

    renderHeader() {
        return (
            <tr>
                <th key={-1} scope="col"></th>
                { this.state.days.map((day, i) => (<th key={i} scope="col">{day}</th>)) }
            </tr>
        );
    }

    renderHeaderDates() {
        return this.props.show_dates ?
            (<tr>
                <th key={-1} scope="col"></th>
                {this.state.dates.map((date, i) => (<th key={i} scope="col">{niceShortDate(date)}</th>)) }
            </tr>) : null;
    }

    renderSpans(shifts: WeeklyReport[], insight_toolchange: string[], icon: any, long_tooltip: boolean, is_current_shift?: boolean) {
        let spans_cell_style = "";
        const spans = shifts.map((shift, i) => {
            let span_style = "shift-color-no-work";
            let span_tooltip = `-${shift.plan ?? "undefined"}`;
            if (shift.order_uuid) {
                const span_order = this.props.orders.get(shift.order_uuid);
                if (span_order) {
                    if (spans_cell_style === "") { spans_cell_style = orderColorCSS(span_order.order_type, span_order.order_color_counter); }
                    span_style = "shift-color" + (span_order.material_color_counter % 3);
                    span_tooltip = `${translate("common.material", "Material")}: ${span_order.material_title} (${span_order.material})\n`;
                    if (long_tooltip) { span_tooltip += `${translate("common.order", "Order")}: ${span_order.order_external_id}, `; }
                    span_tooltip += `${translate("common.quantity", "Quantity")}: ${niceNumber(shift.sum, 0)}`;
                    if (long_tooltip && shift.status && shift.status > 1) {
                        // we have prediction, get model parameters
                        span_tooltip += "\n\n" + getOrderModelParamsTooltip(span_order);
                    }
                } else {
                    // production for closed order, check if we have something to refer to
                    let material_color_counter = -1;
                    for (const order of this.props.orders.values()) {
                        if (order.material === shift.material_external_id) {
                            material_color_counter = order.material_color_counter % 3;
                            break;
                        }
                    }
                    // prepare styles
                    spans_cell_style = "order_work_done";
                    span_style = (material_color_counter === -1) ? "shift-color-done" : `shift-color${material_color_counter}`;
                    // prepare tooltip
                    span_tooltip = `${translate("common.material", "Material")}: ${shift.material_title} (${shift.material_external_id})\n`;
                    if (long_tooltip && shift.order_external_id) {
                        const order_external_id = shift.order_external_id || "";
                        span_tooltip += `${translate("common.order", "Order")}: ${order_external_id}, `;
                    }
                }
            }
            if (insight_toolchange.length > 0) { span_tooltip += `\n\n${insight_toolchange.join("\n")}`; }
            if (shift.status && shift.status > 1) { span_style += " font-italic"; }
            const text = is_current_shift && (shift.status == 0) ?
                `${niceNumber(shift.sum, 0)} / ${niceNumber(shift.plan ?? 0, 0)}` :
                niceNumber(shift.sum, 0);
            return (<span key={i} className={span_style} title={span_tooltip}>{icon} <span>{text}</span></span>);
        });

        return { spans_cell_style, spans };
    }

    renderShiftCell(row_n: number, col_n: number) {
        // check if we have insights
        const insight_tooltips = [ ];
        const insight_toolchange = [ ];
        const shift_insights = this.props.insights[(col_n + 1)+""][(row_n + 1)+""];
        const shift_past = (this.props.week < this.props.current_week) ||
            ((this.props.week === this.props.current_week) && ((col_n * 3 + row_n) < this.props.current_shift));
        for (const insight of shift_insights) {
            // ignore all except downtimes for the past
            if (shift_past && !([INSIGHT_TYPES.downtime, INSIGHT_TYPES.man_downtime].includes(insight.type))) {
                continue;
            }
            // get tooltip
            if (insight.type === INSIGHT_TYPES.tool_setup) {
                insight_toolchange.push(getInsightTooltipTextToolSetup(insight));
            } else {
                insight_tooltips.push(getInsightTooltipText(insight));
            }
        }
        // generate insight icon if needed
        const icon = (insight_tooltips.length > 0) ?
            <img src="/img/warning.svg" alt="Insights" title={insight_tooltips.join("\n")} className={"microplan-warning-events-image"} />
            : null;
        const shift_current = (this.props.week === this.props.current_week) && (this.props.current_shift === (col_n * 3 + row_n));
        // generate productions
        const { spans_cell_style, spans } = this.renderSpans(this.props.shifts[col_n][row_n], insight_toolchange, icon, true, shift_current);
        // empty span for no production
        if (spans.length === 0 && icon) { spans.push(<span key={0}>{icon}</span>); }
        let cell_style = spans_cell_style === "" ? "shift-not-selected" : spans_cell_style;
        // check if current shift
        if (shift_current) { cell_style += " selected-cell"; }
        // check if we have to indicate tool change
        if (insight_toolchange.length > 0) { cell_style += " tool_setup"; }
        // generate the table cell
        return (<td className={cell_style} title={insight_toolchange.join("\n")}><div>{spans}</div></td>);
    }

    renderShiftRow(row_n: number) {
        return (
            <tr>
                <td>{row_n + 1}</td>
                {this.renderShiftCell(row_n, 0)}
                {this.renderShiftCell(row_n, 1)}
                {this.renderShiftCell(row_n, 2)}
                {this.renderShiftCell(row_n, 3)}
                {this.renderShiftCell(row_n, 4)}
                {this.renderShiftCell(row_n, 5)}
                {this.renderShiftCell(row_n, 6)}
            </tr>
        )
    }

    calcShiftSum(day_shifts: RealizationDayShiftGrid): WeeklyReport[] {
        const day_sums = [ ];
        for (const shifts of day_shifts) {
            for (const shift of shifts) {
                // check if DN already covered?
                const day_sum = day_sums.find(ds => ds.material_external_id === shift.material_external_id);
                if (day_sum) {
                    // add to existing
                    day_sum.sum += shift.sum;
                    day_sum.plan += shift.plan;
                } else {
                    // clone as new
                    day_sums.push(JSON.parse(JSON.stringify(shift)));
                }
            }
        }
        return day_sums;
    }

    renderSumCell(col_n: number) {
        const day_shifts = this.calcShiftSum(this.props.shifts[col_n]);
        const { spans } = this.renderSpans(day_shifts, [], null, false);
        return (<td><div>{spans}</div></td>);
    }

    renderSumRow() {
        return (
            <tr>
                <td>{translate("common.total", "Total")}</td>
                {this.renderSumCell(0)}
                {this.renderSumCell(1)}
                {this.renderSumCell(2)}
                {this.renderSumCell(3)}
                {this.renderSumCell(4)}
                {this.renderSumCell(5)}
                {this.renderSumCell(6)}
            </tr>
        )
    }

    render () {
        return (
            <table className="table shift-table">
                <thead className={ this.props.show_dates && "has-dates" }>
                    {this.renderHeader()}
                    {this.renderHeaderDates()}
                </thead>
                <tbody>
                    {this.renderShiftRow(0)}
                    {this.renderShiftRow(1)}
                    {this.renderShiftRow(2)}
                    {this.renderSumRow()}
                </tbody>
            </table>
        );
    }
}

export default ShiftTableProduction;
