// @flow

import React from "react";
import { Modal } from "react-bootstrap";
import { translate } from "../IntlProviderWrapper";
import { ORDER_TAGS } from "./../../lib/ManufacturingTags.generated";
import { getBackend as getBackend2 } from "./../../lib/backend/Backend2";
import * as t from "./../../lib/backend/manufacturing2.generated.types";

type Props = {
    order_uuid: string,
    onCloseModal: () => void,
    show_modal: boolean,
    close_on_save?: boolean,
    onSave?: (order: t.IOrderModelBase) => void,
}
type State = {
    order: t.IOrderModelBase | null
};

export default class EditOrderModal extends React.Component<Props, State> {
    
    state = {
        order: null
    }
 
    componentDidMount() {
        if (this.props.order_uuid) {
            this.load(this.props.order_uuid);
        }
    }
  
    componentDidUpdate(prev_props: Props) {
        if (prev_props.order_uuid !== this.props.order_uuid && this.props.order_uuid) {
            this.load(this.props.order_uuid);
        }
    }

    onClose = () => {
        this.state.order = null;
        this.props.onCloseModal()
    }
  
    load = async (order_uuid: string) => {
        const res = await getBackend2().manufacturing.getOrdersSimple({
            uuids: [order_uuid]
        });

        if (res.operations.length > 0) {
            const order = res.operations[0];
            this.setState({ order });
        }
    }

    handleChange = (event: Event) => {
        let target = event.target;
        if (target instanceof HTMLTextAreaElement) {
            // collect changed field name and value
            const value = target.value;
            const order = this.state.order;
            if (order) {
                order.tags = {
                    ...order.tags,
                    comment: value
                }
                // update
                this.setState({ order: { ...order }});
            }  
        }
    }

    onSave = async () => {
        const order = this.state.order;
        if (!order) {
            return;
        }

        await getBackend2().manufacturing.updateOrders({
            orders: [
                { uuid: order.uuid, tags: order.tags }
            ]
        });

        if (this.props.onSave) {
            this.props.onSave(order);
            this.state.order = null;
        }
        if (this.props.close_on_save !== false) {
            this.onClose();
        }
    }
    
    getComment = (): string => {
        const order = this.state.order;
        if (order) {
            return order.tags[ORDER_TAGS.comment];
        }
        return "";
    }
 
    render() {
        return <Modal
            show={this.props.show_modal}
            dialogClassName="modal-dialog-scrollable"
            onHide={this.onClose}
        >
            <Modal.Header>
                <div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                    {translate("common.edit", "Edit")}
                    <button type="button" className="close" onClick={this.onClose}>
                        <span aria-hidden="true">×</span>     
                    </button>
                </div>
            </Modal.Header>
            <Modal.Body>
                <label>{translate("common.add_comment", "Add comment")}:</label>
                <textarea
                    className="form-control"
                    style={{ height: 200 }}
                    value={this.getComment()}
                    name="message"
                    onChange={this.handleChange}
                />
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-primary" onClick={this.onSave}>
                    <span aria-hidden="true">{translate("common.save", "Save")}</span>     
                </button>
            </Modal.Footer>
        </Modal>
    }
}
