// @flow

import * as React from "react";

import { TooltipContext, type TooltipTrigger, type TooltipProviderState } from "./TooltipProvider";

type Props = {
    content_key?: string,
    content?: React.Node,
    children?: React.Node,
    class_name: string,
    offset_x: number,
    offset_y: number,
    delay: number,
    style: any,
    interactive: boolean,
    trigger: TooltipTrigger
};

const DATA_HAS_TOOLTIP_ATTR = "data-has-tooltip";

function hasTooltip(element: Element) {
    return element.getAttribute(DATA_HAS_TOOLTIP_ATTR) === "true";
}

function shouldProcessMouseEvent(event: SyntheticMouseEvent<Element>) {
    const { target, currentTarget } = event;
    if (target instanceof Element && (target === currentTarget || !hasTooltip(target))) {
        return true;
    }

    return false;
}

function getEventHandler<T>(element: React.Element<Object>, handlerName: string, handler: (event: T) => void) {
    const existingHandler = element.props && element.props[handlerName];

    if (typeof existingHandler !== "function") {
        return handler;
    }

    // Merge event handlers
    return (event: T) => {
        existingHandler(event);
        handler(event);
    };
}

function getTabIndex(element: React.Element<Object>) {
    return (element.props && element.props.tabIndex) || "0";
}

class Tooltip extends React.Component<Props> {
    static contextType = TooltipContext;
    static defaultProps: Props = {
        class_name: "",
        offset_x: 10,
        offset_y: 15,
        delay: 500,
        style: {},
        interactive: true,
        trigger: "hover"
    };
    context: TooltipProviderState;

    show_timeout: TimeoutID;
    ref: Element | null = null;

    constructor(props: Props) {
        super(props);
    }

    componentWillUnmount() {
        clearTimeout(this.show_timeout);
    }

    componentDidUpdate(prev_props: Props) {
        if (this.props.content_key !== prev_props.content_key) {
            this.context.updateState(this.ref, {
                class_name: this.props.class_name,
                offset_x: this.props.offset_x,
                offset_y: this.props.offset_y,
                style: this.props.style,
                content: this.props.content
            });
        }
    }

    updateRef = (element: Element) => {
        if (this.ref === null) {
            this.ref = element;
        }
    };

    handleMouseOver = (event: SyntheticMouseEvent<Element>) => {
        this.updateRef(event.currentTarget);
        if (!shouldProcessMouseEvent(event) || this.props.trigger !== "hover") {
            return;
        }

        clearTimeout(this.show_timeout);
        const { delay, ...other_props } = this.props;
        this.context.updateState(this.ref, {
            ...other_props,
            show: false
        });
        this.show_timeout = setTimeout(() => {
            this.context.updateState(this.ref, {
                show: true,
                x: event.clientX,
                y: event.clientY
            });
        }, delay);
    };

    handleMouseOut = (event: SyntheticMouseEvent<Element>) => {
        this.updateRef(event.currentTarget);
        if (!shouldProcessMouseEvent(event) || this.props.trigger !== "hover") {
            return;
        }

        clearTimeout(this.show_timeout);
        this.show_timeout = setTimeout(() => {
            this.context.updateState(this.ref, {
                show: false
            });
        }, 0);
    };

    handleMouseMove = (event: SyntheticMouseEvent<Element>) => {
        this.updateRef(event.currentTarget);
        if (!shouldProcessMouseEvent(event) || this.props.trigger !== "hover") {
            return;
        }

        clearTimeout(this.show_timeout);
        this.show_timeout = setTimeout(() => {
            if (!this.show_timeout) {
                return;
            }

            this.context.updateState(this.ref, {
                show: true,
                x: event.clientX,
                y: event.clientY
            });
        }, this.props.delay);
    };

    handleMouseDown = (event: SyntheticMouseEvent<Element>) => {
        this.updateRef(event.currentTarget);
        if (!shouldProcessMouseEvent(event)) {
            return;
        }

        if (this.props.trigger === "hover") {
            clearTimeout(this.show_timeout);
            this.context.updateState(this.ref, {
                show: false
            });
        }
    };

    handleClick = (event: SyntheticMouseEvent<Element>) => {
        this.updateRef(event.currentTarget);
        if (!shouldProcessMouseEvent(event)) {
            return;
        }

        if (this.props.trigger === "click") {
            clearTimeout(this.show_timeout);
            this.context.updateState(this.ref, {
                ...this.props,
                show: true,
                x: event.clientX,
                y: event.clientY
            });
        }
    };

    handleBlur = (event: SyntheticEvent<Element>) => {
        this.updateRef(event.currentTarget);
        if (this.props.trigger !== "click") {
            return;
        }

        clearTimeout(this.show_timeout);
        this.show_timeout = setTimeout(() => {
            this.context.updateState(this.ref, {
                show: false
            });
        }, 0);
    };

    handleKeyDown = (event: SyntheticKeyboardEvent<Element>) => {
        this.updateRef(event.currentTarget);
        if (this.props.trigger !== "click") {
            return;
        }

        if (event.keyCode === 27) {
            this.context.updateState(this.ref, {
                show: false
            });
        }
    };

    render() {
        const { children } = this.props;

        if (!children) {
            return null;
        }

        return (
            <React.Fragment>
                {React.Children.map(children, child => {
                    if (child && typeof child === "object") {
                        return React.cloneElement(child, {
                            onMouseOver: getEventHandler(child, "onMouseOver", this.handleMouseOver),
                            onMouseOut: getEventHandler(child, "onMouseOut", this.handleMouseOut),
                            onMouseMove: getEventHandler(child, "onMouseMove", this.handleMouseMove),
                            onMouseDown: getEventHandler(child, "onMouseDown", this.handleMouseDown),
                            onClick: getEventHandler(child, "onClick", this.handleClick),
                            onBlur: getEventHandler(child, "onBlur", this.handleBlur),
                            onKeyDown: getEventHandler(child, "onKeyDown", this.handleKeyDown),
                            role: this.props.trigger === "click" ? "button" : undefined,
                            tabIndex: this.props.trigger === "click" ? getTabIndex(child) : undefined,
                            [DATA_HAS_TOOLTIP_ATTR]: "true"
                        });
                    }

                    return child;
                })}
            </React.Fragment>
        );
    }
}

export default Tooltip;
