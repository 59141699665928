// @flow
import React from "react";
import { ORDER_TAGS_ACCESS } from "../../../../lib/ManufacturingTags.generated";
import { translate } from "../../../IntlProviderWrapper";

import type { IOrderProducedModelEx } from "../reducers/linesOrders";

export type ReworkParams = {
    x: number,
    y: number,
    width: number,
    line_index?: number,
    order_uuid: string
}

type ReworkOrderLinkProps = {
    base_params: ReworkParams,
    rework_params: ReworkParams
};
type ReworkOrderLinkState = {
    mouse_inside: boolean
};

const RECT_WIDTH = 10;
class ReworkOrderLink extends React.Component<ReworkOrderLinkProps, ReworkOrderLinkState> {

    state = {
        mouse_inside: false
    }

    onMouseEnter = () => {
        this.setState({ mouse_inside: true });
    }

    onMouseLeave = () => {
        this.setState({ mouse_inside: false });
    }

    render() {
        const base_params = this.props.base_params;
        const rework_params = this.props.rework_params;

        let link_color = "red";
        const adjustment = (RECT_WIDTH / 2);
        const y_adjustment = -2;
        return <g
            id="rework-order-link"
            onMouseEnter={this.onMouseEnter}
            onMouseLeave={this.onMouseLeave}
            height={"30"}
            style={{ cursor: "pointer" }}
        >
            <title>{translate("common.linked_operation", "Linked operation")}</title>
            <rect
                x={base_params.x}
                y={base_params.y - adjustment + y_adjustment}
                width={RECT_WIDTH}
                height={RECT_WIDTH}
                style={{
                    fill: link_color
                }}
            />
            <rect
                x={rework_params.x}
                y={rework_params.y - adjustment + y_adjustment}
                width={RECT_WIDTH}
                height={RECT_WIDTH}
                style={{
                    fill: link_color
                }}
            />
            <line
                x1={base_params.x + adjustment}
                y1={base_params.y + adjustment + y_adjustment}
                x2={rework_params.x + adjustment}
                y2={rework_params.y + adjustment + y_adjustment}
                style={{
                    stroke: link_color
                }}
                strokeWidth={2}
            />
        </g>
    }
}

type Props = {
    all_orders_map: Map<string, ReworkParams>,
    rework_orders: IOrderProducedModelEx[]
};
type State = {};

export default class ReworkOrderLinks extends React.Component<Props, State> {

    renderOrderLinks() {
        const Links = [];
        for (const rework_order of this.props.rework_orders) {
            const rework_uuid = rework_order.uuid;
            const rework_params = this.props.all_orders_map.get(rework_uuid);
            const base_uuid = ORDER_TAGS_ACCESS.linked_operation(rework_order.tags);
            const base_params = this.props.all_orders_map.get(base_uuid);
            if (!base_params || !rework_params) {
                continue;
            }
            Links.push(
                <ReworkOrderLink
                    base_params={base_params}
                    rework_params={rework_params}
                />
            )
        }

        return Links;
    }

    render() {
        return <g id="rework-order-links">
            {this.renderOrderLinks()}
        </g>
    }
}


