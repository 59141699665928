// @flow
import * as React from "react";

export type Cell = {
    label: string;
    text: string | null;
    colspan?: number;
    bgcolor?: string;
    color?: string;
}
type Props = {
    label_above_value?: boolean;
    rows: Cell[][];
};
type State = {};

class DataFields extends React.Component<Props, State> {

    constructor() {
        super();
        this.state = {};
    }

    render() {
        const rows = this.props.rows.map((row, j) => {
            const cells = row.map((cell: Cell, i: number) => {
                const col_span = this.props.label_above_value == true
                    ? cell.colspan
                    : 2 * (+cell.colspan || 1) - 1;
                const style: any = {};
                if (cell.color != undefined) {
                    style["color"] = cell.color;
                }
                if (cell.bgcolor != undefined) {
                    style["background-color"] = cell.bgcolor;
                }
                if (this.props.label_above_value == true) {
                    return <td key={"txt" + i} className="data-fields-cell2" colSpan={col_span}>
                        <div className="data-fields-key2">{cell.label}</div>
                        <div className="data-fields-val2" style={style}>{cell.text}</div>
                    </td>
                } else {
                    return [
                        <th key={"lbl"+i} className="data-fields-key">{cell.label}</th>,
                        <td key={"txt" + i} className="data-fields-val" colSpan={col_span} style={style}>{cell.text}</td>
                    ];
                }
            });
            return <tr key={"df-row-" + j}>{cells}</tr>
        });
        return (<table className="data-fields-tab">
            <tbody>{rows}</tbody>
        </table>);
    }
}

export default DataFields;
