// @flow

import * as React from "react";

type Props = {
    position: string
};

const footer = (props: Props) => {
    const year = (new Date()).getFullYear();

    // let `float` be default position
    let position = props.position;
    if (position === undefined) {
        position = "float";
    }
    return (
        <footer className={"footer_" + position}>
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        {year} &copy; Qlector
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default footer;
