// @flow

import * as api_axios from "./restApiAxios";
import * as rest from "./restApi";

import { BatchOperations, IBatchOperations } from "./batch_operations.generated.api";
import { Users, IUsers } from "./users.generated.api";
import { Manufacturing2, IManufacturing2 } from "./manufacturing2.generated.api";
import { Datasources, IDatasources } from "./datasources.generated.api";
import { Alerts, IAlerts } from "./alerts.generated.api";
import { Apps, IApps } from "./apps.generated.api";
import { Common, ICommon } from "./common.generated.api";
import { IReports, Reports } from "./reports.generated.api";
import { ITracking, Tracking } from "./tracking.generated.api";
import { IDashboards2, Dashboards2 } from "./dashboards2.generated.api";
import { IAudit, Audit } from "./audit.generated.api";
import { ITicketing, Ticketing } from "./ticketing.generated.api";
import { IModels, Models } from "./models.generated.api";

export interface IBackend {
    alerts: IAlerts;
    apps: IApps;
    audit: IAudit;
    batchOperations: IBatchOperations;
    common: ICommon;
    datasources: IDatasources;
    users: IUsers;
    manufacturing: IManufacturing2;
    reports: IReports;
    models: IModels;
    dashboards: IDashboards2;
    tracking: ITracking;
    ticketing: ITicketing;
    setRequestHeader(key: string, val: string | null): void;
    getApiClient(): rest.ApiClient;
}

class Backend implements IBackend {

    apiClient: rest.ApiClient;
    alerts: IAlerts;
    apps: IApps;
    audit: IAudit;
    batchOperations: IBatchOperations;
    common: ICommon;
    datasources: IDatasources;
    users: IUsers;
    manufacturing: IManufacturing2;
    reports: IReports;
    tracking: ITracking;
    dashboards: IDashboards2;
    ticketing: ITicketing;
    models: IModels

    constructor(apiClient: rest.ApiClient) {
        this.apiClient = apiClient;
        this.alerts = new Alerts(this.apiClient);
        this.apps = new Apps(this.apiClient);
        this.audit = new Audit(this.apiClient);
        this.batchOperations = new BatchOperations(this.apiClient);
        this.common = new Common(this.apiClient);
        this.datasources = new Datasources(this.apiClient);
        this.users = new Users(this.apiClient);
        this.manufacturing = new Manufacturing2(this.apiClient);
        this.reports = new Reports(this.apiClient);
        this.tracking = new Tracking(this.apiClient);
        this.dashboards = new Dashboards2(this.apiClient);
        this.ticketing = new Ticketing(this.apiClient);
        this.models = new Models(this.apiClient);
    }

    setRequestHeader(key: string, val: string | null): void {
        this.apiClient.setRequestHeader(key, val);
    }

    getApiClient(): rest.ApiClient {
        return this.apiClient;
    }
}

let backend = new Backend(new api_axios.ApiClientAxios());

export function init(apiClient: rest.ApiClient) {
    backend = new Backend(apiClient);
}

export function getBackend(): IBackend {
    return backend;
}
