// @flow
import * as t from "../../../../lib/backend/manufacturing2.generated.types";
import * as rt from "../../../../lib/backend/reports.generated.types";
import * as t2 from "../../../../lib/SimulationReportModels";
import * as Auth from "../../../../lib/Auth";
import * as BusinessLogic from "../../../../lib/BusinessLogic";
import { getBackend as getBackend2 } from "../../../../lib/backend/Backend2";
import {
    shiftNumber,
    TIME_RANGES,
    dateFromWeekAndShift,
    toISODateString,
    uuidv4,
    SHIFT_CONSTS,
    getDateOfISOWeekShift,
    deepClone
} from "../../../../lib/Util"
import { addMaterialColors, getOrdersWithZeroDuration } from "../../../../lib/GanttData";
import {
    LINE_TAGS,
    INSIGHT_TYPES,
    LINE_GROUP_TAGS,
    LINE_GROUP_TAGS_ACCESS,
    ORDER_TAGS,
    PLANNING_META_OPTIMIZATION,
    PLANT_TAGS_ACCESS
} from "../../../../lib/ManufacturingTags.generated";
import { LinesOrdersMaps } from "./Mappers";
import { prepareGanttChart2, prepareGanttChartInsights } from "../../../GanttChartUtils";
import { translate, getLang } from "../../../IntlProviderWrapper"
import { SET_TRANSLATIONS_MATERIALS } from "../../../redux/reducers/translationsMaterialsReducer";
import { store } from "../../../../index";
import { JOB_STATUS } from "../../../../lib/CommonConsts.generated";
import {
    GUI_LOCAL_STORAGE,
    QUICK_SIM_FIXED_CACHE_HASH,
    PLANNING_EXPLANATION_TYPE
} from "../../../../lib/ManufacturingConsts.generated";
import { PRODUCTION_VERSION_COLUMN } from "../SaveToSap/OrderChangesModal";
import { LOCKED_LINE_ID } from "../lineOrderList/LineFilter"
// reducers
import { REPORT_LOADING_ACTION_NAME } from "./report";
import {
    UPDATE_REPORT_ACTION_NAME2,
    SET_RESCHEDULE_ORDER_BUNDLE,
    cloneReportParameters
} from "./common";
import { countLinesOnGantt, countUnscheduledLinesOnGantt } from "./properties";
import { UPDATE_PARALLEL_REPORT_PARAMETERS } from "./parallelReport";
import { GanttChartSource, SKIP_STOCK_REPORT, zoomGanttChart } from "./properties";

import { UPDATE_CURRENT_DOWNTIMES_ACTION_NAME, UPDATE_INSIGHTS_ACTION_NAME } from "./insights";
import {
    LINE_ORDERS_LOADING_ACTION_NAME,
    UPDATE_LINES_ORDERS_ACTION_NAME,
    SET_IGNORED_ORDERS_ACTION_NAME,
    SET_UNSCHEDULED_ORDERS_ACTION_NAME,
    SET_FILTERED_OUT_ORDERS,
    UPDATE_ALL_ORDERS_RECOMMEND_PLAN,
    SET_CHUNKED_ORDERS
} from "./linesOrders";
import { UPDATE_MATERIALS } from "./ganttChart";
import {
    SET_RECOMMEND_PLAN_RUNNING,
    SET_RECOMMEND_PLAN_LINE_PLANS,
    SET_REPORT_TYPE_TITLE_ACTION_NAME,
    SET_ERROR,
    SET_ADVANCED_ORDER_MENU,
    SET_RECOMMEND_EXPLANATIONS,
    SET_LEFT_MENU_SELECTED_VIEW,
    SET_HIGHLIGHTED_ORDERS,
    SET_RECOMMEND_WARNINGS,
    SET_LAST_CLICKED_ORDER_UUID
} from "./planningTable"
import { RESET_FILTERS, SET_SELECTED_LINE_UUIDS } from "./reducersFilters/filters";
import {
    ORDER_TYPE,
    PLANNING_RESOURCE_TYPES,
    ORDER_GENERATED_BY
} from "../../../../lib/ManufacturingConsts.generated";
import { UPDATE_RECOMMEND_PLAN, UPDATE_REPORT_UUID } from "./recommendPlan";
import { SIMULATION_TYPES, SET_SELECTED_STOCK_REQUIREMENT } from "./common";
import { SET_ORDER_TABLE_PRODUCTION_MODAL } from "./ganttChartStandalone";
import { SET_STOCK_REQ_FILTERS_MATERIALS } from "./reducersFilters/stockRequirementsFilters";
import { TypeFilterValues } from "../lineOrderList/filters/TypeFilterConsts";
import { SET_MOUSE_CURSOR } from "../MouseCursor";

import type { IOrderProducedModel } from "../../../../lib/backend/manufacturing2.generated.types";
import type { ReduxState } from "./reducers";
import type { FilterLinesOrders } from "../../../../lib/GanttData";
import type { CursorKeys } from "../MouseCursor";
import type { SetStockReqFilterMaterials } from "./reducersFilters/stockRequirementsFilters";
import type {
    ResetDraggedOrder,
    SetRescheduleOrderAction
} from "./common";
import type {
    SetRecommendPlanLinePlans,
    SetRecommendPlanRunningAction,
    SetReportTypeTitleAction,
    SetErrorAction,
    SetAdvancedOrderMenuAction,
    ResetAdvancedOrderMenu,
    AdvancedOrderMenuState,
    SetRecommendExplanationsAction,
    SetHighlightedOrders,
    SetRecommendWarningsAction,
    SetLastClickedOrderUuid,
    SetLeftMenuSelectedView
} from "./planningTable";
import type {
    ReportLoadingAction,
    AddExtraLinesAction
} from "./report";
import type { UpdateParallelReportAction, ParallelReportLoading, UpdateParallelReportParameters } from "./parallelReport";
import type { UpdateInsightsAction, UpdateCurrentDowntimesAction } from "./insights";
import type {
    LineOrders,
    IOrderProducedModelEx,
    LoadingAction,
    UpdateLinesOrdersAction,
    SetIgnoredOrdersAction,
    SetUnscheduledOrdersAction,
    SetFilteredOutAction,
    OrderHighlights,
    UpdateLinesOrdersPlanAction,
    LoadVersionAction,
    UpdateAllOrdersRecommendPlanAction
} from "./linesOrders";
import type { UpdateMaterialsAction } from "./ganttChart";
import type { RecommendPlanParamsAndCriteria } from "../RecommendPlan";
import type {
    ResetGanttAction,
    QuickSimArgs,
    ReportParameters,
    SetSelectedStockRequirement,
    UpdateReportAction,
    UpdateReport2Action,
    SetRescheduleOrderBundleAction,
    ValidOrder,
    ValidLineOrders
} from "./common";
import type { ILineData, IPlanLine, IPlanExplanation } from "../../../../lib/backend/manufacturing2.generated.types";
import type {
    PlanningTableFiltersState,
    ResetFiltersAction,
    SetSelectedLineUuidsAction,
} from "./reducersFilters/filters";
import type {
    PropertiesState,
    GanttChartSourceTypes,
    SetSkipStockReport
} from "./properties";

import { SHIFTS_IN_WEEK, SHIFTS_PER_DAY } from "../GanttChart/constants";
import { ValidLinesOrdersHelper } from "./ValidLinesOrdersHelper";
import { SET_PAST_ORDERS_ALERTS } from "./alertsReducer";

import type { SetTranslationsMaterials } from "../../../redux/reducers/translationsMaterialsReducer";
import type { SetOrderTableProductionModalAction } from "./ganttChartStandalone";
import type { UpdateReportRecommendPlanAction, UpdateRecommendPlanAction } from "./recommendPlan";
import type { StockRequirement, UpdateStockRequirements } from "./stockRequirements";
import type { UpdateStockRequirementsHeightAction, UpdatePlanningTableWidthAction } from "./properties";
import type { StockRequirementsFiltersState } from "./reducersFilters/stockRequirementsFilters";
import type { InsightsMapStockRequirements } from "./insights";
import type { ChunkedOrder, ChunkedOrdersAction } from "./linesOrders";
import type { SetPastOrdersAlertsAction } from "./alertsReducer";

export const VERSION_TYPE = {
    initial: "initial",
    current: "current",
    version: "version"
}

export type RunRecommendPlanParameters = {
    line_group_uuid: string,
    line_uuids: string[],
    lines_orders: LineOrders[],
    unscheduled_orders: LineOrders[],
    state: RecommendPlanParamsAndCriteria,
    planning_weeks: number,
    freeze_orders: t.IRunLinegroupPlanningFreezeOrder[],
    order_quanity_produced_map: Map<string, number>,
    order_time_start_map?: Map<string, number>,
    rearrange_orders_map: RearrangeOrdersMap,
    original_lines_orders: LineOrders[]
}

type ReportRes = {
    quick_report: t2.IReportModelEx | null,
    insights: t.IEventDataEx[] | null,
    error?: string | null,
    extra_lines: t.IExtraLines[] | null,
    filtered_orders: FilterLinesOrders[],
    translations?: t.ITranslations,
    cache_hash?: CacheParams
}

export type FilterValidLinesOrders = {
    valid_lines: ValidLineOrders[],
    valid_orders: ValidOrder[],
}

export type LineOrdersLoadActions = LoadingAction | UpdateLinesOrdersAction | SetIgnoredOrdersAction |
    SetUnscheduledOrdersAction | SetTranslationsMaterials | UpdateMaterialsAction | ChunkedOrdersAction | SetPastOrdersAlertsAction

export class PropertiesLogic {

    static getNumShiftsFromDayTag = (
        source_type: GanttChartSourceTypes,
        line_group_uuid: string,
        is_parallel: boolean = false
    ): number => {
        if (is_parallel) {
            return BusinessLogic.getLineGroupTagInt(
                line_group_uuid,
                LINE_GROUP_TAGS.planning_table_save_to_erp_weeks,
                16
            ) * SHIFTS_IN_WEEK;
        }
        const tags = BusinessLogic.getLineGroupTags(line_group_uuid);
        if (PropertiesLogic.isPlanningTable(source_type)) {
            return LINE_GROUP_TAGS_ACCESS.planning_table_weeks(tags) * SHIFTS_PER_DAY * 7;
        }
        if (source_type === GanttChartSource.microplan) {
            return LINE_GROUP_TAGS_ACCESS.microplan_gantt_number_of_days(tags) * SHIFTS_PER_DAY;
        }
        if (source_type === GanttChartSource.line_planning) {
            return LINE_GROUP_TAGS_ACCESS.microplan_gantt_number_of_days(tags) * SHIFTS_PER_DAY;
        }

        return LINE_GROUP_TAGS_ACCESS.gantt_number_of_days(tags) * SHIFTS_PER_DAY;
    }

    static getTopPadding = (properties: PropertiesState, source_type: GanttChartSourceTypes) => {
        if (source_type === GanttChartSource.stock_requirements ||
            source_type === GanttChartSource.gantt_unscheduled) {
                return 0;
        }
        return properties.top_padding;
    }

    static hasBarsStart = (source_type: GanttChartSourceTypes) => {
        return !(
            source_type === GanttChartSource.stock_requirements ||
            source_type === GanttChartSource.microplan
        );
    }

    static isPlanningTable = (source_type: GanttChartSourceTypes) => {
        return source_type === GanttChartSource.planning_table_parallel || source_type === GanttChartSource.planning_table;
    }

    static getCurrentShift = (properties: PropertiesState): number => {
        if (properties.current_shift === null) {
            console.error("PropertiesLogic: current shift is not set")
        }
        return properties.current_shift || 0;
    }

    static getStartDay = (properties: PropertiesState, default_value: number = 0): number => {
        if (properties.start_day === null) {
            console.error("PropertiesLogic: Start day is not set")
        }
        return properties.start_day || default_value;
    }

    static getWidth = (properties: PropertiesState): number => {
        if (properties.width === null) {
            console.error("PropertiesLogic: Width is not set");
        }
        return properties.width || 0;
    }

    static getShiftWidth = (properties: PropertiesState): number => {
        if (properties.shift_width === null) {
            console.error("PropertiesLogic:  Shift width is not set");
        }
        return properties.shift_width || 0;
    }

    static getYear = (properties: PropertiesState): number => {
        if (properties.year === null) {
            console.error("PropertiesLogic:  Week is not set");
        }
        return properties.year || 0;
    }

    static getWeek = (properties: PropertiesState): number => {
        if (properties.week === null) {
            console.error("PropertiesLogic:  Week is not set")
        }
        return properties.week || 0;
    }

    static getHeight = (properties: PropertiesState, source_type: GanttChartSourceTypes) => {
        if (source_type === GanttChartSource.gantt_unscheduled) {
            return properties.unscheduled_height;
        }
        if (source_type === GanttChartSource.stock_requirements) {
            return properties.stock_requirements_height;
        }

        return properties.height;
    }

    static getNumLines = (properties: PropertiesState, source_type: GanttChartSourceTypes) => {
        if (source_type === GanttChartSource.gantt_unscheduled) {
            return properties.num_unscheduled_lines;
        }
        return properties.count_non_empty_lines;
    }
}

export class ReportCache {

    static parallel_report_cache_hash = null;
    static report_cache_hash = null;

    static isFixedCacheHash = () => {
        // special case for development mode
        const is_dev_env = BusinessLogic.getSysFlag("env") === "dev";
        // if in dev mode, check if we have fixed cache hash
        return is_dev_env && (localStorage.getItem(GUI_LOCAL_STORAGE.fixed_quick_sim_cache_hash) === "true");
    }

    static removeCacheHash = () => {
        ReportCache.parallel_report_cache_hash = null;
        ReportCache.report_cache_hash = null;
    }

    static setCacheHash = (project_parallel_orders: boolean): string => {
        // if not fixed, generate uuid
        const hash = this.isFixedCacheHash() ?
            (project_parallel_orders ? QUICK_SIM_FIXED_CACHE_HASH.tralala : QUICK_SIM_FIXED_CACHE_HASH.hopsasa) :
            uuidv4();

        if (project_parallel_orders) {
            ReportCache.parallel_report_cache_hash = hash;
        } else {
            ReportCache.report_cache_hash = hash;
        }

        return hash;
    }

    static getCacheHash = (project_parallel_orders: boolean): CacheParams => {
        let cache_hash = null;
        if (project_parallel_orders) {
            cache_hash = ReportCache.parallel_report_cache_hash;
        } else {
            cache_hash = ReportCache.report_cache_hash;
        }

        if (cache_hash) {
            return { cache_hash, cache_reset: false };
        }

        return { cache_hash: this.setCacheHash(project_parallel_orders), cache_reset: true };
    }
}

type CacheParams = {
    cache_hash: string,
    cache_reset: boolean
}

export type SimulationActionsTypes = (
    UpdateReportAction | UpdateInsightsAction | ReportLoadingAction | ResetGanttAction |
    SetErrorAction | AddExtraLinesAction | SetFilteredOutAction | UpdateParallelReportAction |
    ParallelReportLoading | UpdateParallelReportParameters | SetTranslationsMaterials | UpdateReport2Action |
    UpdateReportRecommendPlanAction | ResetDraggedOrder | LoadVersionAction | UpdatePlanningTableWidthAction
)

export const INSIGHT_DIFF_TYPE = {
    solved: "solved",
    same: "same",
    new: "new"
}

export type InsightDiff = {
    diff_type: string,
    current_insight?: rt.IEventDataEx,
    original_insight?: rt.IEventDataEx
}

type UpdateParametersRet = {
    report_parameters: ReportParameters,
    noproductionversion_order_uuids: string[]
}

type GenerateParallelReportRet = {
    warnings: Warning[],
    report_uuid: string
}

export type Warning = {
    order_uuid: string,
    field: typeof PRODUCTION_VERSION_COLUMN,
    warning: string
}

type IGetCompatibleProductionVersionsOp = {
    op_uuid: string;
    order_external_id: string;
    fixed_line_uuid?: string;
}


export class ReportLogic {

    /** Utility method for waiting on job */
    static waitForExport = async (uuid: string): Promise<any> => {
        const CHECK_INTERVAL = 3 * TIME_RANGES.SECOND;
        const MAX_WAIT_TIME = 60 * TIME_RANGES.MINUTE;

        return new Promise((resolve, reject) => {
            let counter = 0;
            const loopCall = async (): Promise<void> => {
                try {
                    const res = await getBackend2().common.getJobStatus({ id: uuid });
                    if (!res) {
                        reject(new Error("Job with given uuid not found"));
                        return;
                    }
                    if (res.status === JOB_STATUS.errored) {
                        reject(new Error("Job failed with error: " + res.status_msg));
                        return;
                    }
                    if (res.status === JOB_STATUS.ended) {
                        const res2 = await getBackend2().common.getJobInfo({ id: uuid });
                        resolve(res2.result);
                        return;
                    }

                    // check if we have been waiting for too long
                    counter++;
                    if (counter * CHECK_INTERVAL >= MAX_WAIT_TIME) {
                        reject(new Error("Job time-out: " + uuid));
                        return;
                    }

                    setTimeout(loopCall.bind(this), CHECK_INTERVAL);
                } catch (err) {
                    console.error(err);
                    reject(err);
                    return;
                }
            };
            setTimeout(loopCall.bind(this), CHECK_INTERVAL);
        });
    }

    static updateParametersProductionVersions = async (parameters: ReportParameters): Promise<UpdateParametersRet> => {
        const prod_ver_operations: IGetCompatibleProductionVersionsOp[] = [];

        const line_uuids = [];
        const order_external_id_uuid_map = new Map < string, string>;
        const order_refs_map = new Map < string, IOrderProducedModelEx>;

        for (const line_orders of parameters.config.lines_orders) {
            line_uuids.push(line_orders.line_uuid);
            for (const order of line_orders.orders) {
                order_external_id_uuid_map.set(order.external_id, order.uuid);
                order_refs_map.set(order.uuid, order);
                prod_ver_operations.push({
                    op_uuid: order.uuid,
                    order_external_id: order.external_id,
                    fixed_line_uuid: line_orders.line_uuid
                });
            }
        }

        const prod_ver_params = {
            constraints: {
                fix_unplanned_ops: true,
                ignore_operation_fix_line_constraint: false,
                planned_line_uuids: undefined,
                planned_ord_same_bom: true,
                planned_ord_same_op_structure: true,
                prod_ord_same_bom: true,
                prod_ord_same_op_structure: true,
                prod_ord_can_change_line: false
            },
            operations: prod_ver_operations
        };

        const res = await getBackend2().manufacturing.getCompatibleProdVers({
            params: prod_ver_params
        });

        const noproductionversion_order_uuids = new Set();

        for (const order_prod_ver of res.orders_prod_vers) {
            const order_uuid = order_external_id_uuid_map.get(order_prod_ver.order_external_id);
            if (order_uuid) {
                const order = order_refs_map.get(order_uuid);
                if (order && order_prod_ver.production_versions.length > 0) {
                    if (order.tags[ORDER_TAGS.production_version] !== order_prod_ver.production_versions[0].prod_ver_external_id) {
                        console.log("Different prod version for order: ",
                            order.external_id,
                            order.tags[ORDER_TAGS.production_version],
                            order_prod_ver.production_versions[0].prod_ver_external_id
                        );
                    }
                    order.tags[ORDER_TAGS.production_version] = order_prod_ver.production_versions[0].prod_ver_external_id;
                } else {
                    noproductionversion_order_uuids.add(order_uuid);
                    // console.error("Unable to find order prod ver for order!", order);
                }
            } else {
                console.error("Unable to find order uuid for calculated prod vers!", order_uuid);
            }
        }
        return {
            report_parameters: parameters,
            noproductionversion_order_uuids: [...noproductionversion_order_uuids]
        };
    }

    static generateWarnings = (noversion: string[]): Warning[] => {
        const order_warnings = [];
        return order_warnings.concat(
            noversion.map(uuid => {
                return {
                    order_uuid: uuid,
                    field: PRODUCTION_VERSION_COLUMN,
                    warning: translate("common.no_production_version", "Could not find production version")
                }
            })
        );
    }

    static generateParallelReportUuidWarning = async (parameters: ReportParameters): Promise<?GenerateParallelReportRet> => {
        if (!parameters || !parameters.config) return;

        const {
            report_parameters,
            noproductionversion_order_uuids
        } = await ReportLogic.updateParametersProductionVersions(cloneReportParameters(parameters));

        const warnings = ReportLogic.generateWarnings(noproductionversion_order_uuids);
        parameters = report_parameters;
        parameters.config.project_parallel_orders = true;
        const quick_sim = await ReportLogic.quickSimulation(parameters);

        if (quick_sim && quick_sim.quick_report) {
            return {
                warnings,
                report_uuid: quick_sim.quick_report.uuid
            }
        }

        if (quick_sim.error) {
            throw Error(quick_sim.error);
        }

    }

    static getEnabledItems = (orders: IOrderProducedModelEx[]) => {
        return orders.filter(l => !l.skip_sim);
    }

    static mergeReports = (quick_report: t2.IReportModelEx, full_report: t2.IReportModelEx): t2.IReportModelEx => {
        const line_shift_features_map = new Map<string, rt.ISimulationReportOrderLineShiftFeature>();
        for (const line of quick_report.result.line_shift_features) {
            line_shift_features_map.set(line.line, line);
        }

        const new_line_shift_features = [];
        for (let shift_line of full_report.result.line_shift_features) {
            const new_line = line_shift_features_map.get(shift_line.line);
            if (new_line) {
                new_line_shift_features.push(new_line);
            } else {
                new_line_shift_features.push(shift_line);
            }
        }
        quick_report.result.line_shift_features = new_line_shift_features;

        const lines_map = new Map<string, t2.ISimulationReportOrderLineEx[]>();
        for (const line of quick_report.result.orders) {
            const new_lines = lines_map.get(line.line);
            if (new_lines) {
                lines_map.set(line.line, [...new_lines]);
            } else {
                lines_map.set(line.line, [line]);
            }

        }

        let sim_lines = [];
        const already_inserted = new Set();
        for (let line of full_report.result.orders) {
            if (already_inserted.has(line.line)) {
                continue;
            }
            const new_line = lines_map.get(line.line);
            if (new_line) {
                sim_lines = sim_lines.concat(new_line);
            } else {
                sim_lines = sim_lines.concat([line]);
            }
            already_inserted.add(line.line);
        }
        quick_report.result.orders = sim_lines;

        return quick_report;
    }

    static quickSimulationAction = async (
        parameters: ReportParameters,
        dispatch: (arg: SimulationActionsTypes) => void,
        full_report?: t2.IReportModelEx | null
    ) => {

        try {
            // first generate parallel report uuid, to enable saving of the plan
            ReportLoadingEventHandler.showLoading(true);
            dispatch({ type: REPORT_LOADING_ACTION_NAME, data: true });
            dispatch({ type: UPDATE_PARALLEL_REPORT_PARAMETERS, data: parameters });
            const quick_sim = await ReportLogic.quickSimulation(parameters, full_report);
            ReportLoadingEventHandler.showLoading(false);
            const error = quick_sim.error || null;

            if (error) {
                dispatch({ type: REPORT_LOADING_ACTION_NAME, data: false });
                dispatch({ type: SET_ERROR, data: error });
                console.error(error);
            }

            if (quick_sim && quick_sim.quick_report && quick_sim.insights) {
                if (quick_sim.translations && quick_sim.translations.materials) {
                    store.dispatch({type: SET_TRANSLATIONS_MATERIALS, data: quick_sim.translations.materials });
                }
                let quick_report: t2.IReportModelEx = quick_sim.quick_report;
                const insights: t.IEventDataEx[] = quick_sim.insights;

                dispatch({
                    type: UPDATE_INSIGHTS_ACTION_NAME,
                    data: {
                        insights,
                        insight_types: parameters.selected_insight_types
                    }
                });

                dispatch({
                    type: UPDATE_REPORT_ACTION_NAME2,
                    data: {
                        quick_report,
                        extra_lines: quick_sim.extra_lines
                    }
                });

                if (quick_sim.filtered_orders.length > 0) {
                    dispatch({ type: SET_FILTERED_OUT_ORDERS, data: quick_sim.filtered_orders })
                } else {
                }

                if (parameters.recommend_plan_uuid) {
                    dispatch({ type: UPDATE_REPORT_UUID, data: {
                        plan_uuid: parameters.recommend_plan_uuid,
                        report_uuid: quick_report.uuid
                    }});
                }

                zoomGanttChart(null, GanttChartSource.planning_table, dispatch);

                dispatch({ type: SET_ERROR, data: error });
            } else {
                /* TODO - revert currently not yet supported...
                dispatch({ type: RESET_DRAGGED_ORDER, data: undefined });
                if (parameters.prev_report_uuid) {
                    dispatch({ type: LOAD_VERSION, data: parameters.prev_report_uuid });
                } else {
                    dispatch({ type: SET_ERROR, data: error });
                }*/
                dispatch({ type: SET_ERROR, data: error });
            }
            dispatch({ type: REPORT_LOADING_ACTION_NAME, data: false });
        } catch (e) {
            console.error("quickSimulationAction: ", e);
            dispatch({ type: REPORT_LOADING_ACTION_NAME, data: false });
            dispatch({ type: SET_ERROR, data: e });
        }
    };

    static quickSimulation = async (
        parameters: ReportParameters,
        full_report?: t2.IReportModelEx | null
    ): Promise<ReportRes> => {
        const { config } = parameters;
        const {
            plant_uuid,
            line_group_uuid,
            title,
            lines_orders,
            project_parallel_orders,
            unscheduled_orders,
            skip_stock,
            ignore_orders_status
        } = config;

        const additional_tags = {};
        if (config.simulation_type) {
            additional_tags.simulation_type = config.simulation_type;
        }

        let quick_report = null;
        let error = null;
        let insights = null;
        let extra_lines = null;
        let filtered_orders = [];
        let translations;
        let cache_hash;
        let isClose = false;
        let unloadEventListener = () => {
            isClose = true;
        }
        window.addEventListener("beforeunload", unloadEventListener);
        try {
            // prepare lines we want to include in the quick simulation
            const line_uuid_set: Set<string> = new Set();
            const lines: rt.IRunQuickSimulationLine[] = [];

            lines_orders.forEach((line_order, i) => {
                line_uuid_set.add(line_order.line_uuid);
                lines.push(ReportLogic.generateJobItem(line_order, config, true));
            });

            if (lines.length === 0) {
                return { quick_report, error, insights, extra_lines: null, filtered_orders }
            }

            // prepare unscheduled orders so we can accuractely estimate extra lines
            const unscheduled_lines: rt.IRunQuickSimulationLine[] = [];

            for (const unscheduled_line_orders of unscheduled_orders) {
                line_uuid_set.add(unscheduled_line_orders.line_uuid);
                unscheduled_lines.push(ReportLogic.generateJobItem(unscheduled_line_orders, config, false));
            }

            // prepare simulation parameters
            const current_shift = shiftNumber(new Date());
            const planning_shifts = PropertiesLogic.getNumShiftsFromDayTag(
                GanttChartSource.planning_table,
                line_group_uuid,
                project_parallel_orders
            );

            cache_hash = { ...ReportCache.getCacheHash(project_parallel_orders)};

            // if we are projecting parallel orders we are doing either initial or
            // final simulation that is used to generate plan export diff
            // we include split orders in the input for completeness
            const split_orders: rt.IRunQuickSimulationSplitOrder[] = [];
            if (project_parallel_orders) {
                const split_orders_map = new Map<string, rt.IRunQuickSimulationSplitOrder>();
                lines_orders.forEach(lo => {
                    for (const order of lo.orders) {
                        // check if chunk and if we have parent order
                        let parent_order = null;
                        if (LinesOrdersLogic.isChunkOrder(order)) {
                            if (!parent_order) {
                                const parent_order_uuid = order.parent_order_uuid;
                                if (parent_order_uuid) {
                                    const parent = LinesOrdersMaps.parents_of_chunks.get(
                                        parent_order_uuid
                                    );
                                    if (parent) {
                                        parent_order = ReportLogic.generateSplitOrderParent(parent);
                                    }
                                }
                            }
                        }
                        if (parent_order) {
                            const split_order = split_orders_map.get(parent_order.order_uuid);
                            if (split_order !== undefined) {
                                split_order.chunks.push(ReportLogic.generateSplitOrderChunk(order));
                            } else {
                                split_orders_map.set(parent_order.order_uuid, {
                                    parent: parent_order,
                                    chunks: [ReportLogic.generateSplitOrderChunk(order)]
                                });
                            }
                        }
                    }
                });
                split_orders.push(...split_orders_map.values());
            }

            const quick_simulation_req: rt.IRunQuickSimulationReq = {
                from_shift: {
                    shift: 0,
                    week: current_shift.week,
                    year: current_shift.year
                },
                next_shift: {
                    shift: current_shift.shift,
                    week: current_shift.week,
                    year: current_shift.year
                },
                predict_shifts: planning_shifts,
                predict_stock_shifts: planning_shifts,
                stock_plant_insights_num_shifts: planning_shifts,
                stock_shopfloor_insights_num_shifts: planning_shifts,
                lines: lines.map(line => ({ ...line, shifts: line.shifts.slice(0, planning_shifts) })),
                unscheduled_lines: unscheduled_lines.map(line => ({ ...line, shifts: [] })),
                tags: {
                    line_uuids: [...line_uuid_set].join(","),
                    plant_uuid: plant_uuid,
                    line_group_uuid: line_group_uuid,
                    stock_forecast: "true",
                    ...additional_tags
                },
                project_parallel_orders: project_parallel_orders || false,
                ...cache_hash,
                strip_shifts: planning_shifts,
                skip_stock,
                split_orders
            };

            if (parameters.extra_lines && parameters.extra_lines.length > 0) {
                quick_simulation_req.extra_lines = parameters.extra_lines.map(el => ({line: el.line}));
            }

            // check if we have a named simulation for what-if
            if (title != undefined) {
                quick_simulation_req.tags.title = title;
                quick_simulation_req.tags.what_if = "true";
                quick_simulation_req.tags.user_uuid = Auth.getLoggedinUser().uuid
            }

            quick_simulation_req.tags.client_started_ts = Date.now() + "";

            if (ignore_orders_status != null) {
                const external_ignore_orders_status = new Map(ignore_orders_status);
                for (const line of [...lines_orders, ...unscheduled_orders]) {
                    for (const order of line.orders) {
                        external_ignore_orders_status.delete(order.uuid);
                    }
                }

                quick_simulation_req.extra_line_ignore_orders = [
                    ...external_ignore_orders_status.entries()
                ].map(item => ({
                    uuid: item[0],
                    ignore: item[1]
                }));
            }

            // execute the quick simulation
            const qr = await ReportLogic.loadReport(quick_simulation_req, parameters, full_report);
            if (additional_tags.simulation_type === SIMULATION_TYPES.full) {
                await getBackend2().manufacturing.recalculateInsights({});
            }
            quick_report = qr.quick_report;
            insights = qr.insights;
            error = qr.error;
            extra_lines = qr.extra_lines;
            filtered_orders = qr.filtered_orders;
            translations = qr.translations;
        } catch (err) {
            error = "quickSimulation: " + err.message;
            const client_side_error = `Quick sim error from line group ${parameters.config.line_group_uuid}: ` + err.message
            if (!isClose) {
                await getBackend2().tracking.logClientsideError(
                    {
                        message: client_side_error,
                        module: "quick_simulation",
                        severity: err.message.indexOf("Failed to fetch") >= 0 ? "medium" : "high"
                    }
                );
            }
        }
        window.removeEventListener("beforeunload", unloadEventListener);

        return {
            quick_report,
            error,
            insights,
            extra_lines,
            filtered_orders,
            translations,
            cache_hash
        }
    }

    static canceled_report_req: Map<string, boolean> = new Map();

    static showWarningMsg = () => {
        const e = new CustomEvent(
            "error-handler-event",
            {
                detail: {
                    msg: translate("common.simulation_return", "Simulation is taking longer than usual, please wait..."),
                    onCancel: () => { }
                }
            }
        );

        const el = document.querySelector("#error-handler");
        if (el) {
            el.dispatchEvent(e);
        }
    }

    static hideWarningMsg = () => {
        const e = new CustomEvent(
            "error-handler-event",
            {
                detail: {
                    msg: "",
                    onCancel: () => { }
                }
            }
        );

        const el = document.querySelector("#error-handler");
        if (el) {
            el.dispatchEvent(e);
        }
    }

    /** Utility method for waiting on job */
    static waitForReport = async (uuid: string, project_parallel_orders: boolean): Promise<string | Error | null> => {
        const CHECK_INTERVAL = 3 * TIME_RANGES.SECOND;
        const MAX_WAIT_TIME = 60 * TIME_RANGES.MINUTE;
        if (!project_parallel_orders) {
            ReportLogic.showWarningMsg();
        }
        return new Promise((resolve, reject) => {
            let counter = 0;
            const loopCall = async (): Promise<void> => {
                try {
                    let res = await getBackend2().reports.getReportStatus({ id: uuid });
                    if (!res) {
                        ReportLogic.canceled_report_req.set(uuid, true);
                        ReportLogic.hideWarningMsg()
                        reject(new Error("Job with given uuid not found"));
                        return;
                    }
                    if (res.status === JOB_STATUS.errored) {
                        ReportLogic.canceled_report_req.set(uuid, true);
                        ReportLogic.hideWarningMsg()
                        reject(new Error("Job failed with error: " + res.status_msg));
                        return;
                    }
                    if (res.status === JOB_STATUS.ended) {
                        ReportLogic.canceled_report_req.set(uuid, true);
                        ReportLogic.hideWarningMsg()
                        resolve(res.status);
                        return;
                    }

                    // check if we have been waiting for too long
                    counter++;
                    if (counter * CHECK_INTERVAL >= MAX_WAIT_TIME) {
                        ReportLogic.canceled_report_req.set(uuid, true);
                        ReportLogic.hideWarningMsg()
                        reject(new Error("Job time-out: " + uuid));
                        return;
                    }

                    if (ReportLogic.canceled_report_req.get(uuid) === true) {
                        return;
                    }
                    setTimeout(loopCall.bind(this), CHECK_INTERVAL);
                } catch (err) {
                    ReportLogic.canceled_report_req.set(uuid, true);
                    ReportLogic.hideWarningMsg()
                    console.error(err);
                    reject(err);
                }
            };
            setTimeout(loopCall.bind(this), CHECK_INTERVAL);
        });
    }

    static async getQuickSimulation(req_parameters: rt.IRunQuickSimulationReq, job_uuid: string): Promise<rt.IGetReportRes | null> {
        const status = await ReportLogic.waitForReport(job_uuid, !!req_parameters.project_parallel_orders)
        if (status === JOB_STATUS.ended) {
            const line_uuids = req_parameters.lines.map(l => l.uuid);
            const report_res = await getBackend2().reports.getReport({
                id: job_uuid,
                line_uuids: line_uuids.join(",")
            });
            return report_res;
        }
        return null;
    }

    static cached_override_extra_lines: t.IExtraLines[] | null = null;

    static getInsightTypes = () => {
        return [
            INSIGHT_TYPES.tool_setup,
            INSIGHT_TYPES.tool_overlap,
            INSIGHT_TYPES.operation_no_input_material,
            INSIGHT_TYPES.operation_delay_downstream,
            INSIGHT_TYPES.operation_constraint_violated,
            INSIGHT_TYPES.freeze_order_move,
            INSIGHT_TYPES.partially_scheduled,
            INSIGHT_TYPES.conditional_capacity_violated
        ]; // always fetch all insight_types
    }

    static loadReport = async (
        req_parameters: rt.IRunQuickSimulationReq,
        parameters: ReportParameters,
        full_report?: t2.IReportModelEx | null
    ): Promise<ReportRes> => {
        const { config } = parameters;
        const { lines_orders } = config
        let days = PropertiesLogic.getNumShiftsFromDayTag(
            GanttChartSource.planning_table,
            config.line_group_uuid
        ) / SHIFTS_PER_DAY;
        // get report
        const line_uuids = lines_orders.map(x => x.line_uuid);
        req_parameters.language_code = getLang();
        req_parameters.dont_filter_extra_lines = true;

        if (!req_parameters.project_parallel_orders &&
            req_parameters.cache_reset === false &&
            ReportLogic.cached_override_extra_lines) {
            req_parameters.override_extra_lines = ReportLogic.cached_override_extra_lines;
        }
        let simulation_res = await getBackend2().reports.runQuickSimulationSync(req_parameters);
        let simulation_report = null;
        let error = "";
        let extra_lines = [];

        if (simulation_res.was_timeout && simulation_res.job_uuid) {
            simulation_res = await ReportLogic.getQuickSimulation(req_parameters, simulation_res.job_uuid);
            if (simulation_res) {
                // $FlowFixMe
                simulation_report = simulation_res.report;
                if (simulation_report) {
                    const report_input_data = simulation_report.input.data;
                    const extra_line_uuids = report_input_data.extra_line_uuids
                    extra_lines = report_input_data.lines.filter(l => extra_line_uuids.includes(l.line));
                }
            }
        } else {
            // $FlowFixMe
            simulation_report = simulation_res.report;
            extra_lines = simulation_res.extra_lines;
        }

        if (!req_parameters.project_parallel_orders && req_parameters.cache_reset === true) {
            ReportLogic.cached_override_extra_lines = extra_lines;
        }

        if (!simulation_report) {
            return {
                quick_report: null,
                insights: null,
                extra_lines: null,
                filtered_orders: [],
                error
            }
        }

        if (req_parameters.cache_hash && req_parameters.cache_reset === false && full_report) {
            // cache_reset === false represents drag and drop so the report is not full
            // we should merge full report and drag and drop report before updating state
            simulation_report = ReportLogic.mergeReports(simulation_report, full_report);
        }

        const raw_simulation = addMaterialColors(simulation_report);

        let extra_line_uuids = [];
        if (extra_lines) {
            extra_line_uuids = extra_lines.map(el => el.line);
        }

        const filtered_orders = getOrdersWithZeroDuration(simulation_report, extra_line_uuids);

        try {
            const line_tags = lines_orders.map(line => ({ uuid: line.line_uuid, tags: line.tags }));
            const data = await Promise.all([
                prepareGanttChart2({
                    simulation: raw_simulation,
                    line_tags
                }),
                prepareGanttChartInsights(
                    raw_simulation,
                    ReportLogic.getInsightTypes(),
                    [...line_uuids, ...extra_line_uuids],
                    0, // past weeks
                    days // shown days
                )
            ]);

            return {
                quick_report: data[0].report,
                insights: data[1],
                error: null,
                extra_lines: extra_lines || null,
                filtered_orders,
                translations: simulation_report.translations
            };
        } catch(e) {
            return {
                quick_report: null,
                insights: null,
                error: e,
                extra_lines: extra_lines || null,
                filtered_orders
            }
        }
    }

    static getWeekAndYear = (offset: number, week: number, year: number) => {
        const shift = shiftNumber(dateFromWeekAndShift(week + offset, year, 0));
        return { week: shift.week, year: shift.year }
    }

    static reportTimeout = null;

    static generateJobItemOrder = (x: IOrderProducedModelEx): t.IRunQuickSimulationOrder => {
        const freeze_time_start = x.tags[ORDER_TAGS.freeze_time_start];
        const freeze_time_end = x.tags[ORDER_TAGS.freeze_time_end];
        const subline_index = x.tags[ORDER_TAGS.subline_index];
        const parent_order_uuid = x.parent_order_uuid || "";

        return {
            capacity_factor: x.override_capacity_factor || x.capacity_factor,
            earliest_end: toISODateString(new Date(x.earliest_end)),
            earliest_start: toISODateString(new Date(x.earliest_start)),
            external_id: x.external_id,
            sim_ignore_earliest_start: x.sim_ignore_earliest_start,
            production_version: x.tags[ORDER_TAGS.production_version] || "",
            quantity_total: x.quantity_total,
            time_start: x.time_start,
            uuid: x.uuid,
            freeze_time_start: freeze_time_start ? parseFloat(x.tags[ORDER_TAGS.freeze_time_start]) : freeze_time_start,
            freeze_time_end: freeze_time_end ? parseFloat(x.tags[ORDER_TAGS.freeze_time_end]) : freeze_time_end,
            insight_freeze_order: x.tags[ORDER_TAGS.insight_freeze_order] === "true",
            subline_index: subline_index != undefined ? parseFloat(subline_index) : undefined,
            parent_order_uuid
        }
    }

    static generateSplitOrderParent = (order: IOrderProducedModel): rt.IRunQuickSimulationSplitOrderParent => {
        return {
            capacity_factor: order.override_capacity_factor || order.capacity_factor,
            line_uuid: order.line_uuid,
            material_title: order.material_title,
            material_uuid: order.material_uuid,
            measurement_unit: order.measurement_unit,
            operation_external_id: order.external_operation_id,
            order_external_id: order.external_id,
            order_type: order.order_type,
            order_uuid: order.uuid,
            process_num: order.process_num,
            quantity_total: order.quantity_total
        };
    }

    static generateSplitOrderChunk = (order: IOrderProducedModelEx): rt.IRunQuickSimulationSplitOrderChunk => {
        return {
            order_uuid: order.uuid,
            order_external_id: order.external_id,
            quantity_total: order.quantity_total
        };
    }

    // gnerate job input for bigger plan simulation
    static generateJobItem = (line_orders: LineOrders, config: QuickSimArgs, filter_skip_sim: boolean): t.IRunQuickSimulationLine => {
        // collect order overrides
        let orders_mapped: Array<t.IRunQuickSimulationOrder> = [];

        if (line_orders.orders.length > 0) {
            const orders: IOrderProducedModelEx[] = filter_skip_sim ?
                ReportLogic.getEnabledItems(line_orders.orders) : line_orders.orders;
            orders.sort((a, b) => a.sequence_weight - b.sequence_weight);

            orders_mapped = orders.map(ReportLogic.generateJobItemOrder);
        }

        // collect shift overrides
        const extractShiftData = (x: t.IShiftLineRec) => {
            return {
                enabled: x.enabled,
                shift: x.shift_number,
                week: x.week,
                year: x.year
            }
        };

        return {
            uuid: line_orders.line_uuid,
            orders: orders_mapped,
            shifts: line_orders.shifts.map(extractShiftData)
        };
    }

    static getLineUuidFromInsight = (insight: rt.IEventDataEx): string | null => {
        const line_uuid = insight.tags.line_uuid || insight.extra_data.line_uuid || null;
        return line_uuid;
    }

    static getLineUuidsFromInsight = (insight: rt.IEventDataEx): string[] | null => {
        return insight.tags.line_uuids ? insight.tags.line_uuids.split(",") : null;
    }

    static getOrderUuidFromInsight = (insight: rt.IEventDataEx): string => {
        const order_uuid = insight.tags.order_uuid || insight.extra_data.order_uuid;
        return order_uuid;
    }

    static getOrderUuidsFromInsight = (insight: rt.IEventDataEx): string[] | null => {
        return insight.tags.order_uuids ? insight.tags.order_uuids.split(",") : null;
    }

    static getOrderExternalIdFromInsight = (insight: rt.IEventDataEx): string => {
        const order_external_id = insight.tags.order_external_id || insight.extra_data.order_external_id;
        return order_external_id;
    }

    static insightsDiff = (original_insights: rt.IEventDataEx[], current_insights: rt.IEventDataEx[]): InsightDiff[] => {
        const original_hash_map: Map<string, rt.IEventDataEx> = new Map();
        for (const original_insight of original_insights) {
            original_hash_map.set(original_insight.tags.hash || "", original_insight);
        }
        const current_hash_map: Map<string, rt.IEventDataEx> = new Map();
        for (const current_insight of current_insights) {
            current_hash_map.set(current_insight.tags.hash || "", current_insight);
        }

        const allowed_insights = [
            INSIGHT_TYPES.operation_delay_downstream,
            INSIGHT_TYPES.operation_no_input_material,
            INSIGHT_TYPES.operation_constraint_violated,
            INSIGHT_TYPES.freeze_order_move
        ];
        // go over and find differences on insights
        const insight_diffs: InsightDiff[] = [];
        for (const original_insight of original_insights) {
            const original_hash = original_insight.tags.hash || "";
            // bad hash, nothing to compare
            if (original_hash === "" || !allowed_insights.includes(original_insight.type)) { continue; }
            // check if we have it in new set as well
            if (!current_hash_map.has(original_hash)) {
                // this insight no longer active, report success!
                insight_diffs.push({
                    diff_type: INSIGHT_DIFF_TYPE.solved,
                    original_insight
                });
            }
        }
        for (const current_insight of current_insights) {
            const current_hash = current_insight.tags.hash || "";
            // bad hash, nothing to compare
            if (current_hash === "" || !allowed_insights.includes(current_insight.type)) { continue; }
            // check if we have it in new set as well
            if (!original_hash_map.has(current_hash)) {
                // this insight is new, report we created it
                insight_diffs.push({
                    diff_type: INSIGHT_DIFF_TYPE.new,
                    current_insight
                });
            } else {
                // this isnight is both old and new
                insight_diffs.push({
                    diff_type: INSIGHT_DIFF_TYPE.same,
                    original_insight: original_hash_map.get(current_hash),
                    current_insight
                });
            }
        }

        return insight_diffs;
    }

    static getCurrentDowntimes = async (
        report_data: t2.IReportModelEx,
        dispatch: (arg: UpdateCurrentDowntimesAction) => void
    ): Promise<void> => {
        // prepare list of lines we care about
        const line_uuid_set: Set<string> = new Set();
        if (report_data.input.data.extra_line_uuids) {
            for (const line_uuid of report_data.input.data.extra_line_uuids) {
                line_uuid_set.add(line_uuid);
            }
        }
        if (report_data.input.data.lines) {
            for (const line of report_data.input.data.lines) {
                line_uuid_set.add(line.line);
            }
        }
        for (const line of report_data.result.line_shift_features) {
            line_uuid_set.add(line.line);
        }
        for (const line of report_data.result.orders) {
            line_uuid_set.add(line.line);
        }
        const line_uuids = [...line_uuid_set];
        // retrieve downtimes
        const { current_downtimes } = await getBackend2().manufacturing.getCurrentDowntimes({ line_uuids });
        // store downtimes to insights state
        dispatch({ type: UPDATE_CURRENT_DOWNTIMES_ACTION_NAME, data: current_downtimes });
    }
}


export type LinesOrdersParameters = {
    line_group_uuid: string,
    year: number,
    week: number,
    shift_edit_weeks: number
}

export type ExtraLinesOrdersParameters = LinesOrdersParameters & {
    extra_lines: string[]
}

type RearrangeOrdersValue = {
    line_uuid: string,
    earliest_start: number
}

export type RearrangeOrdersMap = Map<string, RearrangeOrdersValue>;

export class LinesOrdersLogic {

    static isChunkOrder = (order: IOrderProducedModelEx): boolean => {
        return (
            order.order_type === ORDER_TYPE.chunk &&
            order.generated_by === ORDER_GENERATED_BY.planning_board_split &&
            !!order.parent_order_uuid
        )
    }

    static mapLineOrderToChunk = (order: IOrderProducedModelEx, parent_order_uuid: string): ChunkedOrder => {
        return {
            uuid: order.uuid,
            external_id: order.external_id,
            process_num: order.process_num,
            quantity_produced: order.quantity_produced,
            quantity_total: order.quantity_total,
            line_uuid: order.line_uuid,
            parent_order_uuid
        }
    }

    static isOrderReadOnly = (line: LineOrders, order: IOrderProducedModelEx) => {
        if (order.tags[ORDER_TAGS.linked_operation]) {
            return true;
        }
        const show_chunks = line.tags[LINE_TAGS.gantt_chunk_orders] === "true";;
        if (show_chunks) {
            return !!order.parent_order_uuid;
        }
        return false;
    }

    static rearrangeOrdersMap = (
        lines_orders: LineOrders[],
        unscheduled_orders: LineOrders[]
    ): RearrangeOrdersMap => {
        const rearrange_orders_map = new Map<string, RearrangeOrdersValue>();

        for (const line_orders of lines_orders) {
            for (const order of line_orders.orders) {
                rearrange_orders_map.set(
                    order.uuid,
                    {
                        line_uuid: line_orders.line_uuid,
                        earliest_start: order.earliest_start
                    }
                );
            }
        }

        for (const unscheduled_line of unscheduled_orders) {
            for (const unscheduled_order of unscheduled_line.orders) {
                rearrange_orders_map.set(
                    unscheduled_order.uuid,
                    {
                        line_uuid: unscheduled_line.line_uuid,
                        earliest_start: unscheduled_order.earliest_start
                    }
                )
            }
        }

        return rearrange_orders_map;
    }

    static getLineGroupForLine = (line_uuid: string, line_group_uuid: string): t.ILineGroupData | null => {
        // line_group_uuid attribute is the prefered line group in case the function returns multiple line groups
        const line_groups = BusinessLogic.getLineGroupsForLine(line_uuid, line_group_uuid);
        // a line can have multiple line groups on gantt we choose the first line group
        if (line_groups.length > 0) {
            return line_groups[0];
        }
        return null
    }

    static isOverlaping = (line_orders: LineOrders): boolean => {
        return line_orders.tags[LINE_TAGS.overlapping_orders_line] === "true";
    }

    static findOrder = (lines_orders: LineOrders[], order_uuid: string): null | IOrderProducedModelEx => {
        for (const line of lines_orders) {
            const line_order = line.orders.find(o => o.uuid === order_uuid);
            if (line_order) {
                return line_order;
            }
        }
        return null;
    }

    static findOrderIndexLinesOrders = (lines_orders: LineOrders[], order_uuid: string): number => {
        for (const line_order of lines_orders) {
            const has_order = line_order.orders.some(o => o.uuid === order_uuid);
            if (has_order) {
                return line_order.orders.findIndex(o => o.uuid === order_uuid);
            }
        }
        return -1;
    }

    static filterLinesOrdersByOrderUuids(in_lines: LineOrders[], order_uuids: string[]): LineOrders[] {
        const lines_orders = [];
        for (const line_orders of in_lines) {
            const orders = [];
            for (const order of line_orders.orders) {
                if (order_uuids.includes(order.uuid)) {
                    orders.push(order);
                }
            }
            if (orders.length > 0) {
                lines_orders.push({
                    ...line_orders,
                    orders: orders
                })
            }
        }
        return lines_orders;
    }

    static filterLinesOrdersByOrderExternalIds(in_lines: LineOrders[], external_ids: string[]): LineOrders[] {
        const lines_orders = [];
        for (const line_orders of in_lines) {
            const orders = [];
            for (const order of line_orders.orders) {
                if (external_ids.includes(order.external_id)) {
                    orders.push(order);
                }
            }
            if (orders.length > 0) {
                lines_orders.push({
                    ...line_orders,
                    orders: orders
                })
            }
        }
        return lines_orders;
    }

    static filterLines(line_group_uuid: string, lines: ILineData[]): ILineData[] {
        const automatic_scheduling = BusinessLogic.getLineGroupTagBool(
            line_group_uuid, LINE_GROUP_TAGS.automatic_scheduling, false)

        const res: ILineData[] = [];
        for (const line of lines) {
            // skip lines not part of planning
            if (line.tags[LINE_TAGS.skip_planning] === "true") { continue; }
            // we only plan bottlenecks, rest is automatic through simulation
            if (automatic_scheduling && line.tags[LINE_TAGS.bottleneck] !== "true") { continue; }
            // we can include the line in the planning board
            res.push(line);
        }
        return res;
    }

    static mapILineDataLinesOrders(lines: ILineData[]): $Shape<LineOrders>[] {
        return lines.map((line: ILineData, i: number) => {
            return {
                line_title: line.title,
                line_uuid: line.uuid,
                line_index: i,
                line_external_id: line.external_id
            }
        });
    }

    static loadMaterials = async (lines_orders: LineOrders[], dispatch: (args: LineOrdersLoadActions) => void) => {
        const material_external_ids: Set<string> = new Set();
        for (const line_orders of lines_orders) {
            for (const order of line_orders.orders) {
                material_external_ids.add(order.material_external_id);
            }
        }

        const res_materials = await getBackend2().manufacturing.searchMaterialsSimple({
            external_ids: [...material_external_ids]
        });
        dispatch({ type: UPDATE_MATERIALS, data: res_materials.data });
    }

    static load = async (parameters: LinesOrdersParameters, dispatch: (args: LineOrdersLoadActions | SetErrorAction) => void) => {
        const {
            line_group_uuid,
            year,
            week,
            shift_edit_weeks
        } = parameters;

        dispatch({ type: SET_ERROR, data: null });
        dispatch({ type: LINE_ORDERS_LOADING_ACTION_NAME, data: true });

        // orders outside this interval will not be fetched
        const planning_horizon_days = BusinessLogic.getLineGroupTagInt(
            line_group_uuid, LINE_GROUP_TAGS.planning_table_horizon_days, 21);

        const plant_uuid = localStorage.getItem("last-plant");
        const plant_tags = plant_uuid ? BusinessLogic.getPlantTags(plant_uuid) : {};
        const ignore_horizon_work_orders =  PLANT_TAGS_ACCESS.planning_table_ignore_horizon_work_orders(plant_tags);
        const res_lines = await getBackend2().manufacturing.getLines({ line_group_uuids: [line_group_uuid] });
        const lines_orders = LinesOrdersLogic.mapILineDataLinesOrders(
            LinesOrdersLogic.filterLines(line_group_uuid, res_lines.lines));

        let res_planning_data = null
        try {
            res_planning_data = await getBackend2().manufacturing.getPlanningData({
                calculate_hash: false,
                line_uuids: lines_orders.map(x => x.line_uuid),
                planning_horizon_days,
                year,
                week_from: week,
                week_count: shift_edit_weeks,
                language_code: getLang(),
                is_planning_table: true,
                ignore_horizon_work_orders
            });
        } catch(e) {
            dispatch({ type: SET_ERROR, data: e.message });
            if (e.message.includes("line group contains split operations for chunks")) {
                await getBackend2().tracking.logClientsideError(
                    {
                        message: `line group ${line_group_uuid} contains split operations for chunks`,
                        module: "quick_simulation",
                        severity: "high"
                    }
                );
            }
        }

        if (!res_planning_data) {
            return
        }

        const unscheduled_lines: LineOrders[] = [];
        const ignored_lines: LineOrders[] = [];
        let past_orders: IOrderProducedModelEx[] = [];

        for (const line of lines_orders) {
            // find line in the server results
            const res_idx = res_planning_data.lines.findIndex(x => (x.line_uuid === line.line_uuid));
            // if nothing from the server side, skip
            if (res_idx === -1) { continue; }
            // get server response for line
            const res = res_planning_data.lines[res_idx];

            // $FlowFixMe - todo fix mapping - filter out only needed values
            let orders: LineOrders = res.orders
                .sort((order_a, order_b) => order_a.sequence_weight - order_b.sequence_weight);

            const unscheduled_orders = [];

            orders = orders.filter(o => {
                if (o.skip_sim === true) {
                    unscheduled_orders.push(o);
                    return false;
                }
                return true;
            })

            const filtered_past_orders = orders.filter(o => o.earliest_start < (new Date()).getTime())
            for (const order of filtered_past_orders) {
                order.line_title = line.line_title;
                past_orders.push(order)
            }

            line.line_hash = res.hash;
            line.orders = orders;
            line.shifts = res.shifts.sort((a, b) => {
                const diff = a.shift_date - b.shift_date;
                return diff !== 0 ? diff : a.shift_number - b.shift_number;
            });
            line.tags = res.tags;

            if (unscheduled_orders.length > 0) {
                const unscheduled_line = { ...line };
                unscheduled_line.orders = unscheduled_orders;
                unscheduled_lines.push(unscheduled_line);
            }

            const ignored_orders = res_planning_data.ignored_orders.filter(o => o.line_uuid === line.line_uuid);
            if (ignored_orders.length > 0) {
                const ignored_line = { ...line };
                ignored_line.orders = ignored_orders;
                ignored_lines.push(ignored_line);
            }
        }

        if (unscheduled_lines.length > 0) {
            dispatch({ type: SET_UNSCHEDULED_ORDERS_ACTION_NAME, data: unscheduled_lines });
        }

        if (ignored_lines.length > 0) {
            dispatch({ type: SET_IGNORED_ORDERS_ACTION_NAME, data: ignored_lines });
        }

        if (past_orders.length > 0) {
            dispatch({ type: SET_PAST_ORDERS_ALERTS, data: past_orders });
        }

        dispatch({ type: UPDATE_LINES_ORDERS_ACTION_NAME, data: lines_orders });

        dispatch({
            type: SET_CHUNKED_ORDERS,
            data: { split_orders: res_planning_data.split_orders }
        })

        await ValidLinesOrdersHelper.fillLinesAndOrdersMaps(
            lines_orders,
            unscheduled_lines,
            ignored_lines
        );
        await LinesOrdersLogic.loadMaterials([...lines_orders, ...unscheduled_lines, ...ignored_lines], dispatch);

        dispatch({ type: LINE_ORDERS_LOADING_ACTION_NAME, data: false });
        store.dispatch({ type: SET_TRANSLATIONS_MATERIALS, data: res_planning_data.translations.materials });
    }

    static getStockRequirementStartDate = (date_required: number, start_date_ts: number) => {
        const date = new Date(date_required);
        date.setHours(0);
        const start_date = (date.getTime() - start_date_ts) / TIME_RANGES.HOUR;
        if (start_date < 0) {
            return 0;
        }
        return start_date;
    }

    static getStockRequirements = async (
        plant_uuid: string,
        line_uuids: string[],
        properties: PropertiesState,
        should_filter: boolean = false
    ): Promise<t.IStockRequirementsShifts[]> => {
        const line_group_uuid = properties.line_group_uuid;
        if (!line_group_uuid) {
            return [];
        }
        const days = PropertiesLogic.getNumShiftsFromDayTag(properties.source_type, line_group_uuid) / SHIFTS_PER_DAY;
        const current_time = (new Date()).getTime();
        const res = await getBackend2().manufacturing.getStockRequirementsShifts({
            plant_uuid,
            line_uuids,
            max_shift_start_time: current_time + (days * TIME_RANGES.DAY)
        });

        if (!should_filter) {
            return res.stock_requirements;
        }

        const stock_requirements_set = new Set();
        const current_shift = properties.current_shift;
        const week = properties.week;
        const year = properties.year;
        if (!week || !year) {
            return [];
        }
        const start_date_ts = getDateOfISOWeekShift(current_shift, week, year);
        const filtered_stock_requirements = [];
        for (const sr of res.stock_requirements) {
            const start_date = LinesOrdersLogic.getStockRequirementStartDate(sr.shift_start_time, start_date_ts);
            const key = start_date + "_" + sr.material_external_id;
            if (stock_requirements_set.has(key)) {
                continue;
            }
            stock_requirements_set.add(key);
            filtered_stock_requirements.push(sr);
        }
        return filtered_stock_requirements;
    }

    static getAllLinesForLineGroup = async (linegroup_uuid: string): Promise<LineOrders[]> => {
        const res = await getBackend2().manufacturing.getLines({line_group_uuids: [linegroup_uuid]});
        return res;
    }

    static addOrderToLinesOrders = (lines_orders: LineOrders[], order: IOrderProducedModelEx, index: number): LineOrders[] => {
        const line = lines_orders.find(l => l.line_uuid === order.line_uuid);
        if (line) {
            line.orders.splice(index, 0, order);
            return lines_orders;
        }
        return lines_orders;
    }
}


type RecommendPlanDispatch =
    SetRecommendPlanRunningAction |
    SetRecommendPlanLinePlans |
    UpdateLinesOrdersAction |
    SetReportTypeTitleAction |
    SetRecommendExplanationsAction |
    UpdateLinesOrdersPlanAction |
    UpdateRecommendPlanAction |
    SetUnscheduledOrdersAction;

type RecommendPlanActionsLoad = RecommendPlanDispatch | SetErrorAction | UpdateAllOrdersRecommendPlanAction | SetSkipStockReport | SetRecommendWarningsAction;

export class RecommendPlanLogic {

    static load = async (
        parameters: RunRecommendPlanParameters,
        dispatch: (args: RecommendPlanActionsLoad
    ) => void) => {
        dispatch({ type: SET_ERROR, data: null });
        dispatch({ type: SET_RECOMMEND_PLAN_RUNNING, data: true });

        const {
            state,
            line_group_uuid,
            line_uuids,
            order_quanity_produced_map,
            order_time_start_map
        } = parameters;

        // in case we are not optimizing whole line group, we need to send to the
        // backend current state of the plan for the line group as is on the board.
        // backend does it's own quick simulation, so we only need to send data
        // required by quick simulation for the orders on lines not currently covered
        const lines_orders: t.IRunLinegroupPlanningSimulationLineOrders[] = [];
        for (const line_orders of parameters.lines_orders) {
            // if planning line, just include what we want to schedule, else include all
            const include_orders = (line_uuids.includes(line_orders.line_uuid) || line_uuids.length === 0) ?
                line_orders.orders.filter(lo => {
                    if (lo.order_type === ORDER_TYPE.plan) {
                        return parameters.state.recommend_param_include_plan_orders;
                    }
                    return true;
                }) : line_orders.orders;

            lines_orders.push({
                uuid: line_orders.line_uuid,
                orders: include_orders.map(lo => ({
                    capacity_factor: lo.override_capacity_factor || lo.capacity_factor,
                    earliest_end: toISODateString(new Date(lo.earliest_end)),
                    earliest_start: toISODateString(new Date(lo.earliest_start)),
                    external_id: lo.external_id,
                    fix_line_uuid: lo.tags[ORDER_TAGS.fix_line],
                    production_version: lo.tags[ORDER_TAGS.production_version] || "",
                    quantity_produced: order_quanity_produced_map.get(lo.uuid),
                    quantity_total: lo.quantity_total,
                    sim_ignore_earliest_start: lo.sim_ignore_earliest_start,
                    uuid: lo.uuid,
                    min_start_time: order_time_start_map ? order_time_start_map.get(lo.uuid) : undefined
                })),
                shifts: line_orders.shifts.map(s => ({
                    enabled: s.enabled,
                    shift: s.shift_number,
                    week: s.week,
                    year: s.year,
                    uuid: s.shift_uuid
                }))
            });
        }

        // if we send back also unscheduled orders, collect them in an array
        const unscheduled_orders: t.IRunLinegroupPlanningSimulationOrder[] = [];
        if (parameters.state.recommend_param_include_unscheduled_orders) {
            for (const line_orders of parameters.unscheduled_orders) {
                for (const lo of line_orders.orders) {
                    // skip plan orders if not selected in settings
                    if (lo.order_type === ORDER_TYPE.plan &&
                        !parameters.state.recommend_param_include_plan_orders) {
                        continue;
                    }
                    // we are good, remember the order
                    unscheduled_orders.push({
                        capacity_factor: lo.override_capacity_factor || lo.capacity_factor,
                        earliest_end: toISODateString(new Date(lo.earliest_end)),
                        earliest_start: toISODateString(new Date(lo.earliest_start)),
                        external_id: lo.external_id,
                        production_version: lo.tags[ORDER_TAGS.production_version] || "",
                        quantity_produced: order_quanity_produced_map.get(lo.uuid),
                        quantity_total: lo.quantity_total,
                        sim_ignore_earliest_start: lo.sim_ignore_earliest_start,
                        uuid: lo.uuid,
                    });
                }
            }
        }

        try {

            // we want to use same cache as quick sim
            const cache_hash = ReportCache.getCacheHash(false).cache_hash;

            // ask for recommendation
            const { plan_uuid } = await getBackend2().manufacturing
                .runLinegroupPlanning({
                    // criteria scores must be between 0.0 and 1.0, so we normalize accordingly
                    criteria: {
                        delays: state.recommend_criteria_delays / 5.0,
                        intermediate_stock: state.recommend_criteria_intermediate_stock / 5.0,
                        tool_changes: state.recommend_criteria_tool_changes / 5.0
                    },
                    linegroup_uuid: line_group_uuid,
                    params: {
                        beam_size: state.recommend_param_beam_size,
                        break_orders: state.recommend_param_break_orders,
                        cache_hash,
                        can_change_workorder_line: state.recommend_param_can_change_workorder_line,
                        customer_priority: state.recommend_param_customer_priority,
                        freeze_orders: parameters.freeze_orders,
                        number_of_fixed_shifts: state.recommend_param_freeze_shifts,
                        time_horizont_shifts: state.recommend_param_time_horizont_shifts,
                        include_plan_orders: state.recommend_param_include_plan_orders,
                        line_uuids: line_uuids.length > 0 ? line_uuids : undefined,
                        lines_orders: lines_orders,
                        unscheduled_orders: unscheduled_orders,
                        track_input_material: state.recommend_param_track_input_material,
                        use_productions_of_unscheduled_operations: state.recommend_param_use_productions_of_unscheduled_operations,
                        use_requirements_from_unscheduled_operations: state.recommend_param_use_requirements_from_unscheduled_operations,
                        use_safety_stock: state.recommend_param_use_safety_stock,
                        strategy: state.recommend_strategy,
                        meta_optimization: state.recommend_param_meta_optimization,
                        override_shifts: state.recommend_param_override_shifts,
                        setup_code_change_factor: state.recommend_param_setup_code_change_factor,
                        work_duration_factor: state.recommend_param_work_duration_factor
                    }
                });

            RecommendPlanLogic.getRecommendPlan(plan_uuid, parameters, dispatch);
        } catch(e) {
            console.log("Recommend plan error: ", e.message);
            dispatch({ type: SET_ERROR, data: e.message });
            dispatch({ type: SET_RECOMMEND_PLAN_RUNNING, data: false });
        }
    }

    static rearrangeOrders = async (
        line_group_uuid: string,
        lines_orders: LineOrders[], // existing scheduled plan
        unscheduled_orders: LineOrders[], // existing unscheduled plan, should be subset of lines in lines_orders
        line_plans: IPlanLine[], // new plan, should be subset of lines in lines_orders
        rearrange_orders_map: RearrangeOrdersMap,
        planning_horizon_offset_msec: number
    ): Promise<{
        lines_orders: LineOrders[], // new scheduled plan
        unscheduled_orders: LineOrders[] // new unscheduled plan
    }> => {
        console.log("rearrange_orders_map:", rearrange_orders_map);
        // assert on the inputs
        if (lines_orders.map(y => y.orders.map(x => x.skip_sim).some(z => z)).some(z => z)) {
            console.error("rearrangeOrders some operations in lines_orders have skip_sim true");
        }
        if (!unscheduled_orders.map(y => y.orders.map(x => x.skip_sim).every(z => z)).every(z => z)) {
            console.error("rearrangeOrders not all operations in unscheduled_orders have skip_sim true");
        }
        const input_line_order_uuids = deepClone(
            lines_orders.map(y => y.orders.map(x => x.uuid)).reduce((z,w) => z.concat(w), []));
        const input_unscheduled_order_uuids = deepClone(
            unscheduled_orders.map(y => y.orders.map(x => x.uuid)).reduce((z,w) => z.concat(w), []));
        if (input_line_order_uuids.length != [...new Set(input_line_order_uuids)].length) {
            console.error("rearrangeOrders lines_orders has duplicate uuids");
        }
        if (input_unscheduled_order_uuids.length != [...new Set(input_unscheduled_order_uuids)].length) {
            console.error("rearrangeOrders unscheduled_orders has duplicate uuids");
        }
        if (input_line_order_uuids.length + input_unscheduled_order_uuids.length !=
            [...new Set(input_line_order_uuids), ...new Set(input_unscheduled_order_uuids)].length
        ) {
            console.error("rearrangeOrders lines_orders and unscheduled_orders have common uuids");
        }

        // map of all orders, uuid => object
        const all_orders_map = new Map();
        // existing line for all orders
        const all_order_line_uuids_map = new Map();
        // set of all lines in lines_orders
        const todo_lines_set = new Set();
        // load scheduled orders
        for (const line_orders of lines_orders) {
            const line_uuid = line_orders.line_uuid;
            todo_lines_set.add(line_uuid);
            for (const order of line_orders.orders) {
                all_orders_map.set(order.uuid, order);
                all_order_line_uuids_map.set(order.uuid, line_uuid);
            }
        }
        // load unscheduled orders, we group them under fake line uuid
        const unscheduled_line_uuid = "UNSCHEDULED_LINE_UUID";
        for (const line_orders of unscheduled_orders) {
            for (const order of line_orders.orders) {
                all_orders_map.set(order.uuid, order);
                all_order_line_uuids_map.set(order.uuid, unscheduled_line_uuid);
            }
            // make sure we have this line in the line_orders array
            if (!todo_lines_set.has(line_orders.line_uuid)) {
                console.error(`Line ${line_orders.line_uuid} included among unscheduled_orders but not in lines_orders.`);
            }
        }

        // placeholder for new plan
        const new_lines_orders: LineOrders[] = deepClone(lines_orders);
        // set of uuids of orders that were scheduled
        const done_orders = new Set();

        // prepare new lines_orders from line_plans
        for (const line_plan of line_plans) {
            // shortcut
            const line_uuid = line_plan.uuid;

            // first add scheduled orders
            const line_orders: IOrderProducedModelEx[] = [];
            for (const planned_order of line_plan.operations) {
                if (!all_orders_map.has(planned_order.uuid)) {
                    console.error("Missing order", line_plan.title, planned_order);
                    continue;
                }
                // make a deep copy
                const new_order = deepClone(all_orders_map.get(planned_order.uuid));
                // for planned orders we never ignore earliest start
                new_order.sim_ignore_earliest_start = false;
                // assign planned timestamp to the order
                new_order.earliest_start = planned_order.start_timestamp;
                new_order.time_start = planned_order.start_timestamp;
                // everything scheduled is not skip sim
                new_order.skip_sim = false;
                // if we change the line we need to change production version
                const old_order_line_uuid = all_order_line_uuids_map.get(planned_order.uuid) || "?";
                if (line_uuid !== old_order_line_uuid) {
                    // report the move
                    console.log(`Moving order ${new_order.external_id}/${new_order.order_type}  from ${old_order_line_uuid} to ${line_plan.uuid}`);
                    // get new production version
                    if (planned_order.prod_ver && planned_order.prod_ver.length > 0) {
                        console.log(`Changign production version ${new_order.tags[ORDER_TAGS.production_version]} => ${planned_order.prod_ver}`);
                        new_order.tags[ORDER_TAGS.production_version] = planned_order.prod_ver;
                    }
                } else {
                    // if no line move, report none-the-less
                    console.log(`Order ${new_order.external_id}/${new_order.order_type} kept on line ${line_plan.title}`);
                }
                // add newly modified order to the new plan
                line_orders.push(new_order);
                // mark order as planned
                done_orders.add(planned_order.uuid);
            }
            // find position of the line and update the orders array
            const line_index = lines_orders.findIndex(lo => lo.line_uuid === line_uuid);
            // make sure we found something
            if (line_index !== -1) {
                // assing orders to line
                new_lines_orders[line_index].orders = [...line_orders];
                // mark line as done
                todo_lines_set.delete(line_uuid);
            } else {
                // we do not have this line in lines_orders, write error!
                console.error(`Missing line ${line_uuid} in lines_orders`);
            }
        }

        // clear orders on any line not included in line_plans
        for (const line_uuid of todo_lines_set) {
            const line_index = lines_orders.findIndex(lo => lo.line_uuid === line_uuid);
            if (line_index !== -1) {
                // clear orders list on this line
                new_lines_orders[line_index].orders = [];
            } else {
                // should not happen since we fill todo lines from lines orders
            }
        }

        // go over unscheduled orders and keep only ones not scheduled
        const new_unscheduled_orders: LineOrders[] = [];
        const line_uuid_idx_map = new Map<string, number>();
        // go over lines and see if we have any unscheduled orders for them
        for (const line_orders of lines_orders) {
            const line_uuid = line_orders.line_uuid;
            // remember map from uuid to position in array
            line_uuid_idx_map.set(line_uuid, new_unscheduled_orders.length);
            // create empty line orders
            new_unscheduled_orders.push({
                ...line_orders,
                orders: []
            });
        }

        // go over orders and collect unscheduled
        const unscheduled_order_uuid_set = new Set<string>();
        for (const line_orders of lines_orders) {
            // check if we have any existing scheduled orders that should go to unscheduled
            for (const line_order of line_orders.orders) {
                if (!done_orders.has(line_order.uuid)) {
                    unscheduled_order_uuid_set.add(line_order.uuid);
                }
            }
        }
        const previously_unscheduled_uuid_set = new Set<string>();
        for (const unscheduled_line of unscheduled_orders) {
            for (const unscheduled_order of unscheduled_line.orders) {
                if (!done_orders.has(unscheduled_order.uuid)) {
                    unscheduled_order_uuid_set.add(unscheduled_order.uuid);
                    // we keep track of orders that were beforee unscheduled
                    // just so we can report corretly to the console.log
                    previously_unscheduled_uuid_set.add(unscheduled_order.uuid);
                }
            }
        }

        // go over all unscheduled orders and reset them to their original
        // line and make sure that earliest start is after last scheduled order on that line
        for (const unscheduled_order_uuid of unscheduled_order_uuid_set) {
            // make copy of the order
            const new_order = deepClone(all_orders_map.get(unscheduled_order_uuid));
            // mark it not used in simulation
            new_order.skip_sim = true;
            // get early data for the order
            const order_init_state = rearrange_orders_map.get(new_order.uuid);
            // find line to which we should place the order
            const line_uuid = order_init_state ? order_init_state.line_uuid : "";
            const line_index = line_uuid_idx_map.get(line_uuid);
            // make sure we have this line
            if (line_index === undefined || line_index === -1) {
                console.error(`Order ${new_order.external_id}/${new_order.uuid}/${line_uuid} not included in init state data`);
                continue;
            }

            const lg = BusinessLogic.getLineGroupForUser(line_group_uuid);
            const lg_tags = lg ? lg.tags : {};
            const planning_table_unscheduled_move =
                LINE_GROUP_TAGS_ACCESS[LINE_GROUP_TAGS.planning_table_unscheduled_move](lg_tags);
            if (planning_table_unscheduled_move) {
                // update earliest start so it doesn't start before planning horizon
                const planning_horizon = new Date(Date.now() + planning_horizon_offset_msec);
                planning_horizon.setDate(planning_horizon.getDate() + 1); // offset by one day just in case
                planning_horizon.setHours(0);
                planning_horizon.setMinutes(0);
                planning_horizon.setSeconds(0);
                const order_earliest_start = order_init_state ? order_init_state.earliest_start : 0;

                const order_duration = Math.abs(new_order.earliest_end - new_order.earliest_start);
                new_order.earliest_start = Math.max(planning_horizon.getTime(), order_earliest_start);
                new_order.earliest_end = new_order.earliest_start + order_duration;
                new_order.time_start = Math.max(planning_horizon.getTime(), order_earliest_start);
            }
            // we found order not yet scheduled for the line
            new_unscheduled_orders[line_index].orders.push(new_order);
            // marked as taken care of
            done_orders.add(new_order.uuid);
            // report
            if (previously_unscheduled_uuid_set.has(unscheduled_order_uuid)) {
                console.log(`Order ${new_order.external_id}/${new_order.order_type} kept on ${unscheduled_line_uuid}`);
            } else if (planning_table_unscheduled_move) {
                console.log(`Order ${new_order.external_id}/${new_order.order_type} moved to ${unscheduled_line_uuid} with offset ${new Date(new_order.time_start).toISOString()}`);
            } else {
                console.log(`Order ${new_order.external_id}/${new_order.order_type} moved to ${unscheduled_line_uuid}`);
            }
        }

        // check if any order missed
        for (const all_order of all_orders_map.values()) {
            if (!done_orders.has(all_order.uuid)) {
                console.error(`Order ${all_order.external_id}/${all_order.uuid} not part of new plan!`);
            }
        }

        // Assert outputs
        // if some line of scheduled ops has some skip_sim=true, this is a problem
        if (new_lines_orders.map(y => y.orders.map(x => x.skip_sim).some(z => z)).some(z => z)) {
            console.error("rearrangeOrders some operations in new_lines_orders have skip_sim true");
        }
        // if not all unscheduled lines have all skip_sim=true, this is a problem
        if (!new_unscheduled_orders.map(y => y.orders.map(x => x.skip_sim).every(z => z)).every(z => z)) {
            console.error("rearrangeOrders not all operations in new_unscheduled_orders have skip_sim true");
        }
        const output_line_order_uuids = deepClone(
            new_lines_orders.map(x => x.orders.map(x => x.uuid)).reduce((z,w) => z.concat(w), []));
        const output_unscheduled_order_uuids = deepClone(
            new_unscheduled_orders.map(x => x.orders.map(x => x.uuid)).reduce((z,w) => z.concat(w), []));
        if (output_line_order_uuids.length != [...new Set(output_line_order_uuids)].length) {
            console.error("rearrangeOrders result_lines_orders has duplicate uuids");
        }
        if (output_unscheduled_order_uuids.length != [...new Set(output_unscheduled_order_uuids)].length) {
            console.error("rearrangeOrders new_unscheduled_orders has duplicate uuids");
        }
        if (output_line_order_uuids.length + output_unscheduled_order_uuids.length !=
            [...new Set(output_line_order_uuids), ...new Set(output_unscheduled_order_uuids)].length
        ) {
            console.error("rearrangeOrders result_lines_orders and new_unscheduled_orders have common uuids");
        }
        if (
            input_line_order_uuids.length +  input_unscheduled_order_uuids.length !=
            output_line_order_uuids.length + output_unscheduled_order_uuids.length
        ) {
            console.error("rearrangeOrders input and output operation uuids sets missmatched");
            // Look for what's missing in the output
            const output_line_order_uuid_set = new Set(output_line_order_uuids);
            const output_unscheduled_order_uuid_set = new Set(output_unscheduled_order_uuids);
            for (const uuid of input_line_order_uuids) {
                if (!output_line_order_uuid_set.has(uuid) && !output_unscheduled_order_uuid_set.has(uuid)) {
                    const order = all_orders_map.get(uuid);
                    if (order != undefined) {
                        console.log(`rearrangeOrders Scheduled input order ${order.external_id}/${order.order_type} is missing in output`);
                    }
                }
            }
            for (const uuid of input_unscheduled_order_uuids) {
                if (!output_line_order_uuid_set.has(uuid) && !output_unscheduled_order_uuid_set.has(uuid)) {
                    const order = all_orders_map.get(uuid);
                    if (order != undefined) {
                        console.log(`rearrangeOrders Unscheduled input order ${order.external_id}/${order.order_type} is missing in output`);
                    }
                }
            }
        }
        console.log(`rearrangeOrders INPUT: sched (${input_line_order_uuids.length}) + ` +
            `unsched (${input_unscheduled_order_uuids.length}) = ` +
            (input_line_order_uuids.length +  input_unscheduled_order_uuids.length));
        console.log(`rearrangeOrders OUTPUT: sched (${output_line_order_uuids.length}) + ` +
            `unsched (${output_unscheduled_order_uuids.length}) = ` +
            (output_line_order_uuids.length +  output_unscheduled_order_uuids.length));

        const input_line_order_uuid_set = new Set(input_line_order_uuids);
        const output_line_order_uuid_set = new Set(output_line_order_uuids);
        const new_order_uuids = output_line_order_uuids.filter(uuid => !input_line_order_uuid_set.has(uuid));
        if (new_order_uuids.length > 0) { console.log({new_order_uuids }); }
        const missing_order_uuids = input_line_order_uuids.filter(uuid => !output_line_order_uuid_set.has(uuid));
        if (missing_order_uuids.length > 0) { console.log({missing_order_uuids }); }

        return {
            lines_orders: new_lines_orders,
            unscheduled_orders: new_unscheduled_orders
        };
    }

    static disableShifts(lines_orders: LineOrders[], line_plans: IPlanLine[], schedule: t.IPlanSchedule[]) {
        const line_shift_map: Map<string, Map<string, t.IShiftLineRec>> = new Map();
        for (const { line_uuid, shifts } of lines_orders) {
            const shift_map: Map<string, t.IShiftLineRec> = new Map();
            for (const shift of shifts) {
                shift_map.set(shift.shift_uuid, shift);
            }

            line_shift_map.set(line_uuid, shift_map);
        }

        const line_uuids = line_plans.map(line_plan => line_plan.uuid);
        for (const schedule_shift of schedule) {
            const enable_line_uuids = schedule_shift.resources
                .filter(resource => resource.type === PLANNING_RESOURCE_TYPES.line)
                .map(resource => resource.uuid);
            for (const line_uuid of line_uuids) {
                const enabled = enable_line_uuids.includes(line_uuid);
                const shift_map = line_shift_map.get(line_uuid);
                if (shift_map !== undefined) {
                    const shift = shift_map.get(schedule_shift.uuid);
                    if (shift !== undefined) {
                        shift.enabled = enabled;
                    }
                }
            }
        }
    }

    static recommendPlanTimeout = null;

    static async getRecommendPlan(
        plan_uuid: string,
        parameters: RunRecommendPlanParameters,
        dispatch: (args: RecommendPlanActionsLoad) => void
    ) {
        try {
            const backend = getBackend2().manufacturing;
            const { status } = await backend.getPlanStatus({ plan_uuid });
            if (status.status === "ended") {
                const res = await backend.getPlanResult({ plan_uuid, delete_plan: false });
                const line_plans: IPlanLine[] = res.line_plans;
                if (line_plans) {
                    const recommend_plan_state = {
                        show_recommend_param_dialog: res.warnings.length > 0,
                        recommend_running: false,
                        line_plans
                    };
                    dispatch({ type: SET_RECOMMEND_PLAN_LINE_PLANS, data: recommend_plan_state });
                }

                dispatch({
                    type: SET_RECOMMEND_WARNINGS,
                    data: res.warnings
                });

                const explanations: IPlanExplanation[] = res.explanations;
                if (explanations) {
                    dispatch({ type: SET_RECOMMEND_EXPLANATIONS, data: explanations });
                }

                const uscheduled_offset_timestamp =
                    parameters.state.recommend_param_unscheduled_offset_shifts *
                    SHIFT_CONSTS.SHIFT_DURATION_HOURS * TIME_RANGES.HOUR;

                const {
                    lines_orders,
                    unscheduled_orders
                } = await RecommendPlanLogic.rearrangeOrders(
                    parameters.line_group_uuid,
                    parameters.lines_orders,
                    parameters.unscheduled_orders,
                    line_plans,
                    parameters.rearrange_orders_map,
                    uscheduled_offset_timestamp
                );

                const schedule = res.schedule;
                if ([PLANNING_META_OPTIMIZATION.stock, PLANNING_META_OPTIMIZATION.max_resources].includes(parameters.state.recommend_param_meta_optimization) && schedule.length > 0) {
                    RecommendPlanLogic.disableShifts(lines_orders, line_plans, schedule);
                }

                dispatch({
                    type: SET_REPORT_TYPE_TITLE_ACTION_NAME,
                    data: {
                        report_version_type: "version",
                        report_version_title: translate("Manufacturing.Planning.recommendation", "Recommendation")
                    }
                });

                dispatch({ type: SKIP_STOCK_REPORT, data: false });

                dispatch({
                    type: UPDATE_ALL_ORDERS_RECOMMEND_PLAN,
                    data: {
                        unscheduled_orders,
                        lines_orders,
                        plan_uuid,
                        selected_line_uuids: parameters.line_uuids
                    }
                });

                dispatch({
                    type: UPDATE_RECOMMEND_PLAN,
                    data: {
                        plan_uuid,
                        parameters,
                        plan_created_ts: (new Date()).getTime(),
                        stock_report_uuid: res.stock_report_uuid,
                    }
                });
            } else if (status.status === "errored") {
                console.log("planning errored: " + status.status_msg);
                dispatch({ type: SET_ERROR, data: status.status_msg });
                dispatch({ type: SET_RECOMMEND_PLAN_RUNNING, data: false });
                dispatch({ type: SET_RECOMMEND_WARNINGS, data: [] });
            } else {
                if (RecommendPlanLogic.recommendPlanTimeout) {
                    clearTimeout(RecommendPlanLogic.recommendPlanTimeout);
                }
                RecommendPlanLogic.recommendPlanTimeout = setTimeout(() => {
                    RecommendPlanLogic.getRecommendPlan(plan_uuid, parameters, dispatch);
                }, 250);
            }
        } catch (error) {
            console.log("Recommend plan error: ", error.message);
            if (RecommendPlanLogic.recommendPlanTimeout) {
                clearTimeout(RecommendPlanLogic.recommendPlanTimeout);
            }
            dispatch({ type: SET_ERROR, data: error.message });
            dispatch({ type: SET_RECOMMEND_PLAN_RUNNING, data: false });
            dispatch({ type: SET_RECOMMEND_WARNINGS, data: [] });
        }
    }

    static getExplanationText = (explanation: t.IPlanExplanation): string | null => {
        // if no automatic planning yet, no explanation yet
        if (explanation.type === PLANNING_EXPLANATION_TYPE.no_automatic_planning) {
            return null;
        }

        let text = translate(`Manufacturing.Planning.Explanation.${explanation.type}`, explanation.type);
        if (explanation.type === PLANNING_EXPLANATION_TYPE.scheduled && explanation.extra_data.offset_material_external_id) {
            text += ` ${translate("Manufacturing.Planning.Explanation.offset_material", "Material responsible for the time offset:")}`;
            text += ` ${explanation.extra_data.offset_material_title} (${explanation.extra_data.offset_material_external_id}).`;
        } else if (explanation.type === PLANNING_EXPLANATION_TYPE.scheduled && explanation.extra_data.offset_previous_operation === true) {
            text += ` ${translate("Manufacturing.Planning.Explanation.offset_operation", "Time offset due to previous operation.")}`;
        } else if (explanation.type === PLANNING_EXPLANATION_TYPE.missing_input_material && explanation.extra_data.material_external_id) {
            text += ` ${explanation.extra_data.material_title} (${explanation.extra_data.material_external_id}).`;
        }

        return text;
    }
}

type SetRescheduleOrderActions = (
    SetRescheduleOrderAction | SetHighlightedOrders | ResetFiltersAction |
    SetLastClickedOrderUuid | SetRescheduleOrderAction
);

export class ReduxFilters {

    static planning_table_store = null;
    static last_click_location: "gantt" | "line_order_list" | null = null;

    static isDroppableLineUuid = (line_uuid: string, order_uuid: string, is_unscheduled: boolean): boolean => {
        if (order_uuid && !is_unscheduled) {
            const line_uuids = ValidLinesOrdersHelper.getLineUuids(order_uuid);
            if (line_uuids) {
                return line_uuids.includes(line_uuid);
            } else {
                // might be a chunk order
                const order = LinesOrdersMaps.all_orders_map.get(order_uuid);
                if (order && order.parent_order_uuid) {
                    const parent_line_uuids = ValidLinesOrdersHelper.getLineUuids(order.parent_order_uuid);
                    if (parent_line_uuids) {
                        return parent_line_uuids.includes(line_uuid);
                    }
                }
            }
        }
        return false;
    }

    static onUnmount() {
        ReduxFilters.last_click_location = null;
        DraggableLogic.changeMouseCursor(null);
    }

    static isLineLocked = () => {
        return document.querySelector(`#${LOCKED_LINE_ID}[data-enabled="true"]`);
    }

    static setSelectedOrderUuid = async (
        reduxDispatch: (args: SetRescheduleOrderActions) => void,
        order_uuid: string,
        click_location: "gantt" | "line_order_list"
    ) => {
        ReduxFilters.last_click_location = click_location;
        reduxDispatch({ type: SET_LAST_CLICKED_ORDER_UUID, data: order_uuid });
    }

    static setHighlightedOrders = async (
        order_uuid: string
    ): Promise<Map<string, OrderHighlights>> => {
        const related_lines = await ReduxFilters.getRelatedLines(order_uuid);
        // collect orders which should be highlighted as related
        const highlighted_orders_map: Map<string, OrderHighlights> = new Map();
        for (const input_line of related_lines.input_lines) {
            input_line.orders.forEach(o => highlighted_orders_map.set(o.order_uuid, { is_highlighted: true, type: "input" }));
        }
        for (const order_line of related_lines.order_lines) {
            order_line.orders.forEach(o => highlighted_orders_map.set(o.order_uuid, { is_highlighted: true, type: "order" }));
        }
        for (const output_line of related_lines.output_lines) {
            output_line.orders.forEach(o => highlighted_orders_map.set(o.order_uuid, { is_highlighted: true, type: "output" }));
        }
        return highlighted_orders_map;
    }

    static updateHeights = (
        valid_lines_map: Map<string, ValidLineOrders>,
        valid_orders_map: Map<string, ValidOrder>,
        line_group_uuid: string,
        report_data: t2.IReportModelEx,
        unscheduled_orders: LineOrders[]
    ) => {
        const count_non_empty_lines = countLinesOnGantt({
            valid_lines_map,
            line_group_uuid: line_group_uuid,
            report_data: report_data,
            source_type: GanttChartSource.planning_table_parallel
        });
        const num_unscheduled_lines = countUnscheduledLinesOnGantt({
            valid_orders_map,
            unscheduled_orders: unscheduled_orders,
            source_type: GanttChartSource.planning_table_parallel
        });
        return { count_non_empty_lines, num_unscheduled_lines }
    }

    static setRescheduleOrderUuid = async (
        reduxDispatch: (args: SetRescheduleOrderBundleAction) => void,
        order_uuid: string,
        click_location: "gantt" | "line_order_list",
        is_filter_locked: boolean
    ) => {
        ReduxFilters.last_click_location = click_location;
        const highlighted_orders_map = await ReduxFilters.setHighlightedOrders(order_uuid);
        const store = ReduxFilters.planning_table_store;
        if (!store) {
            console.log("No store found");
            return;
        }

        const state: ReduxState = store.getState();
        const lines_orders = state.gantt_chart_lines_orders.lines_orders;
        const unscheduled_orders = state.gantt_chart_lines_orders.unscheduled_orders;
        const report = state.gantt_chart_report;
        const report_data = report.report_data;
        const original_report_created_at = report.original_report_created_at;
        if (!report_data || !original_report_created_at) {
            return;
        }
        const report_lines = report_data.result.orders
        const properties = state.gantt_chart_properties;
        const filters = state.gantt_chart_filters;
        const materials = state.gantt_chart.materials;
        const insights_mapped = state.gantt_chart_insights.insights_mapped;
        if (!insights_mapped) {
            return null;
        }
        const line_group_uuid = properties.line_group_uuid;
        if (!line_group_uuid) {
            return;
        }
        filters.is_filter_locked = is_filter_locked;
        if (!is_filter_locked) {
            filters.reschedule_order_uuid = order_uuid;
            filters.selected_filter_type = TypeFilterValues.reschedule_operation;
            // compute what can be shown
            const { valid_lines, valid_orders } = await ReduxFilters.applyFilters(
                lines_orders,
                filters,
                unscheduled_orders,
                materials,
                insights_mapped,
                report_lines,
                original_report_created_at
            );
            // prepare maps for quick access
            const valid_lines_map = new Map<string, ValidLineOrders>();
            for (const valid_line of valid_lines) {
                valid_lines_map.set(valid_line.line_uuid, valid_line);
            }
            const valid_orders_map = new Map<string, ValidOrder>();
            for (const valid_order of valid_orders) {
                valid_orders_map.set(valid_order.uuid, valid_order);
            }
            const show_valid_lines = {
                valid_lines,
                valid_lines_map,
                valid_orders_map
            };
            const { count_non_empty_lines, num_unscheduled_lines } = ReduxFilters.updateHeights(
                valid_lines_map,
                valid_orders_map,
                line_group_uuid,
                report_data,
                unscheduled_orders
            );
            // add to state
            reduxDispatch({
                type: SET_RESCHEDULE_ORDER_BUNDLE,
                data: {
                    show_valid_lines,
                    order_uuid,
                    count_non_empty_lines,
                    num_unscheduled_lines,
                    is_filter_locked,
                    highlighted_orders: {highlighted_orders_map}
                }
            });
        } else {
            // add to state
            reduxDispatch({
                type: SET_RESCHEDULE_ORDER_BUNDLE,
                data: {
                    show_valid_lines: null,
                    order_uuid,
                    count_non_empty_lines: null,
                    num_unscheduled_lines: null,
                    is_filter_locked,
                    highlighted_orders: { highlighted_orders_map }
                }
            });
        }
    }

    static isValidOrder = (valid_lines: ValidLineOrders[] | null, order_uuid: string | null): boolean => {
        // fetching valid_lines straight from store otherwise if we pass through props
        // it might result in performance issues on gantt chart
        if (valid_lines === null) return true;

        for (const line_order of valid_lines) {
            for (const o of line_order.orders) {
                if (o.uuid === order_uuid) {
                    return true;
                }
            }
        }
        return false;
    }

    static getRescheduleLineOrderUuids = async (reschedule_line_uuid: string, report_created_at: number): Promise<string[]> => {
        try {
            const res = await getBackend2().manufacturing.getLinePossibleOrders({
                line_uuid: reschedule_line_uuid,
                report_created_at
            });
            return res.order_uuids;
        } catch(e) {
            console.error(e);
        }
        return [];
    }

    static getRescheduleOrderLineUuids = async (reschedule_order_uuid: string): Promise<string[]> => {
        try {
            const res = await getBackend2().manufacturing.getOrderLines({ uuid: reschedule_order_uuid });
            return res.lines.map(l => l.uuid);
        } catch (e) {
            console.error(e);
        }
        return [];
    }

    static applyNoFilter = (
        lines_orders: LineOrders[],
        unscheduled_orders: LineOrders[]
    ): FilterValidLinesOrders => {

        // list of lines, that pass filter
        // - keeps only lines that are selected in filter
        // - keeps all orders for each line but highlight according to the filter
        const valid_lines: ValidLineOrders[] = [];
        const valid_orders: ValidOrder[] = [];

        // return all planned lines and all unscheduled orders
        for (const line_orders of [...lines_orders, ...unscheduled_orders]) {
            // take all orders
            for (const order of line_orders.orders) {
                valid_orders.push({
                    uuid: order.uuid,
                    external_id: order.external_id,
                    material_external_id: order.material_external_id,
                    earliest_start: order.earliest_start,
                    is_translucent: false
                });
            }
            // package into valid line
            valid_lines.push({
                line_title: line_orders.line_title,
                line_uuid: line_orders.line_uuid,
                line_external_id: line_orders.line_external_id,
                tags: line_orders.tags,
                is_extra: false,
                orders: []
            });
        }

        return { valid_lines, valid_orders };
    }

    static applyRescheduleLineFilter = async (
        lines_orders: LineOrders[],
        unscheduled_orders: LineOrders[],
        original_report_created_at: number,
        reschedule_line_uuid: string
    ): Promise<FilterValidLinesOrders> => {

        // list of lines, that pass filter
        // - keeps only lines that are selected in filter
        // - keeps all orders for each line but highlight according to the filter
        const valid_lines: ValidLineOrders[] = [];
        const valid_orders: ValidOrder[] = [];

        //  - Find all operations that can be scheduled on the line.
        //  - Select all lines on which these operations are currently scheduled
        //    and selected them among valid lines. Operations on these other lines
        //    that cannot be scheduled on the selected line, are grayed out on Gantt.
        //    LoL list show all orders for valid lines. Grayed out orders are 1:1
        //    with grayed out operations on Gantt.
        //  - Unscheduled operations are filtered out to only show on Gantt operations
        //    which can be scheduled on the selected line. LoL list show all orders and
        //    unscheduled orders that cannot be moved to the selected line are grayed out.

        // 1. Find all operations that can be scheduled on the line.
        // We need to pass the timestamp of when we loaded the planning board in order
        // to compensate for orders that were open then and closed now in the database.
        // Function returns both scheduled and unscheduled orders.
        const valid_order_uuids = ValidLinesOrdersHelper.getOperationUuids(reschedule_line_uuid);
        // we make a set for fast lookup
        const valid_order_uuids_set = new Set(valid_order_uuids);

        // 2. Select all lines on which these operations are currently scheduled
        for (const line_orders of lines_orders) {
            // check if at least one order on the line is valid
            let has_valid_order = false;
            for (const scheduled_order of line_orders.orders) {
                if (valid_order_uuids_set.has(scheduled_order.uuid)) {
                    has_valid_order = true;
                    break;
                }
            }
            // if yes, add the line to the list of valid lines
            if (has_valid_order) {
                // map orders to valid orders
                for (const order of line_orders.orders) {
                    valid_orders.push({
                        uuid: order.uuid,
                        external_id: order.external_id,
                        material_external_id: order.material_external_id,
                        earliest_start: order.earliest_start,
                        is_translucent: !valid_order_uuids_set.has(order.uuid)
                    });
                }
                // package into valid line
                valid_lines.push({
                    line_title: line_orders.line_title,
                    line_uuid: line_orders.line_uuid,
                    line_external_id: line_orders.line_external_id,
                    tags: line_orders.tags,
                    is_extra: false,
                    orders: []
                });
            } else if (line_orders.line_uuid === reschedule_line_uuid) {
                // line we are filtering for has no scheduled orders
                // however we must still show it
                valid_lines.push({
                    line_title: line_orders.line_title,
                    line_uuid: line_orders.line_uuid,
                    line_external_id: line_orders.line_external_id,
                    tags: line_orders.tags,
                    is_extra: false,
                    orders: []
                });
            }
        }

        // 3. Unscheduled operations are filtered out to only show on Gantt operations
        for (const unscheduled_line_orders of unscheduled_orders) {
            // filter orders
            for (const unscheduled_order of unscheduled_line_orders.orders) {
                if (valid_order_uuids_set.has(unscheduled_order.uuid)) {
                    valid_orders.push({
                        uuid: unscheduled_order.uuid,
                        external_id: unscheduled_order.external_id,
                        material_external_id: unscheduled_order.material_external_id,
                        earliest_start: unscheduled_order.earliest_start,
                        is_translucent: false
                    });
                }
            }
        }

        return { valid_lines, valid_orders };
    }

    static applyRescheduleOrderFilter = async (
        lines_orders: LineOrders[],
        unscheduled_orders: LineOrders[],
        report_lines: t2.ISimulationReportOrderLineEx[],
        reschedule_order_uuid: string
    ): Promise<FilterValidLinesOrders> => {
        // list of lines, that pass filter
        // - keeps only lines that are selected in filter
        // - keeps all orders for each line but highlight according to the filter
        const valid_lines: ValidLineOrders[] = [];
        const valid_orders: ValidOrder[] = [];

        // - Find all lines on which the operation can be scheduled
        // - Select all lines on which the operation can be scheduled as valid lines
        // - On selected lines, keep other operations as grayed out operations (translucent)
        // - Add extra related lines with as read-only with connected operations highlighted
        // 1. Find all valid lines for the operation
        let reschedule_order_uuid_mapped = reschedule_order_uuid;

        // check if operatin is already in the matrix
        let line_uuids = ValidLinesOrdersHelper.getLineUuids(reschedule_order_uuid_mapped);
        if (line_uuids === null) {
            // if the operation is not in the matrix, check if it is a chunk order
            if (LinesOrdersMaps.chunk_orders.has(reschedule_order_uuid)) {
                const chunk_order = LinesOrdersMaps.chunk_orders.get(reschedule_order_uuid);
                if (chunk_order && chunk_order.parent_order_uuid) {
                    // if the chunk order has a parent, use the parent uuid to get the line_uuids
                    reschedule_order_uuid_mapped = chunk_order.parent_order_uuid;
                    line_uuids = ValidLinesOrdersHelper.getLineUuids(reschedule_order_uuid_mapped);
                }
            }
        }

        // we make a set for fast lookup
        const line_uuids_set = new Set(line_uuids);
        // make sure that if the order is scheduled its current line is included
        for (const line_orders of lines_orders) {
            let found_selected_order_in_line = false;
            for (const order of line_orders.orders) {
                if (order.uuid === reschedule_order_uuid_mapped) {
                    line_uuids_set.add(line_orders.line_uuid);
                    found_selected_order_in_line = true;
                    break;
                }
            }
            if (found_selected_order_in_line) {
                break;
            }
        }

        // 2. Select all lines on which the operation can be scheduled as valid lines
        // make sure that at least the current line of the order appears
        let found_selected_order = false;
        for (const line_orders of lines_orders) {
            if (line_uuids_set.has(line_orders.line_uuid)) {
                // valid line, add it to the list
                valid_lines.push({
                    line_title: line_orders.line_title,
                    line_uuid: line_orders.line_uuid,
                    line_external_id: line_orders.line_external_id,
                    tags: line_orders.tags,
                    is_extra: false,
                    orders: []
                });
                // go over orders and add them to the list as translucent
                for (const order of line_orders.orders) {
                    valid_orders.push({
                        uuid: order.uuid,
                        external_id: order.external_id,
                        material_external_id: order.material_external_id,
                        earliest_start: order.earliest_start,
                        is_translucent: order.uuid !== reschedule_order_uuid_mapped
                    });
                    // check if we found the selected order
                    if (order.uuid === reschedule_order_uuid_mapped) {
                        found_selected_order = true;
                    }
                }
            }
        }

        // 3. if we did not find the selected order, check if we have it among unscheduled orders
        if (!found_selected_order) {
            for (const unscheduled_line_orders of unscheduled_orders) {
                for (const unscheduled_order of unscheduled_line_orders.orders) {
                    if (unscheduled_order.uuid === reschedule_order_uuid_mapped) {
                        // we found the order, add it to the list
                        found_selected_order = true;
                        valid_orders.push({
                            uuid: unscheduled_order.uuid,
                            external_id: unscheduled_order.external_id,
                            material_external_id: unscheduled_order.material_external_id,
                            earliest_start: unscheduled_order.earliest_start,
                            is_translucent: false
                        });
                        break;
                    }
                }
                if (found_selected_order) { break; }
            }
        }

        // 4. collect related lines

        // (`|| ""` is due to Flow, if condition already makes sure reschedule_order_uuid is defined)
        const related_lines = await ReduxFilters.getRelatedLines(reschedule_order_uuid_mapped || "");
        const all_related_lines = [
            ...related_lines.input_lines,
            ...related_lines.output_lines,
            ...related_lines.order_lines
        ];

        // lines that come from the backend might be different to the current position on the planning board
        // we need to find the current position for each order and only show those lines
        // we also show connected unscheduled orders
        const related_order_set: Set<string> = new Set();
        for (const related_line of all_related_lines) {
            for (const order of related_line.orders) {
                related_order_set.add(order.order_uuid);
            }
        }

        // we go over all lines returned by report to see if we have any related lines from other line-groups
        for (const report_line of report_lines) {
            // if the line is already included, no need to bother
            if (line_uuids_set.has(report_line.line)) { continue; }
            // if the line isn not yet included, check if it has at least one related order
            let found_related_order = false;
            for (const order of report_line.production) {
                if (related_order_set.has(order.order_id)) {
                    found_related_order = true;
                    break;
                }
            }
            if (!found_related_order) { continue; }
            // if the line has at least one related order, add it to the list
            valid_lines.push({
                line_title: report_line.line_title,
                line_uuid: report_line.line,
                line_external_id: "",
                tags: {},
                is_extra: true,
                orders: []
            });

            // since line is not yet included, we need to remember also orders
            for (const order of report_line.production) {
                valid_orders.push({
                    uuid: order.order_id,
                    external_id: order.order_external_id,
                    material_external_id: "",
                    earliest_start: 0,
                    is_translucent: true
                });
            }
        }

        // check if also any unscheduled order should be included
        for (const unscheduled_line_orders of unscheduled_orders) {
            for (const unscheduled_order of unscheduled_line_orders.orders) {
                if (related_order_set.has(unscheduled_order.uuid)) {
                    // we found the order, add it to the list
                    found_selected_order = true;
                    valid_orders.push({
                        uuid: unscheduled_order.uuid,
                        external_id: unscheduled_order.external_id,
                        material_external_id: unscheduled_order.material_external_id,
                        earliest_start: unscheduled_order.earliest_start,
                        is_translucent: false
                    });
                }
            }
        }

        return { valid_lines, valid_orders };
    }

    static applyMaterialFilter = async (
        lines_orders: LineOrders[],
        unscheduled_orders: LineOrders[],
        selected_materials: string[]
    ): Promise<FilterValidLinesOrders> => {

        // list of lines, that pass filter
        // - keeps all orders for each line but highlight according to the filter
        const valid_lines: ValidLineOrders[] = [];
        const valid_orders: ValidOrder[] = [];

        for (const line_orders of lines_orders) {
            // map orders to valid orders
            for (const order of line_orders.orders) {
                valid_orders.push({
                    uuid: order.uuid,
                    external_id: order.external_id,
                    material_external_id: order.material_external_id,
                    earliest_start: order.earliest_start,
                    is_translucent: !selected_materials.includes(order.material_external_id)
                });
            }
            // package into valid line
            valid_lines.push({
                line_title: line_orders.line_title,
                line_uuid: line_orders.line_uuid,
                line_external_id: line_orders.line_external_id,
                tags: line_orders.tags,
                is_extra: false,
                orders: []
            });
        }

        // keep all unscheduled operations producting the material
        for (const line_orders of unscheduled_orders) {
            // take all orders
            for (const order of line_orders.orders) {
                if (selected_materials.includes(order.material_external_id)) {
                    valid_orders.push({
                        uuid: order.uuid,
                        external_id: order.external_id,
                        material_external_id: order.material_external_id,
                        earliest_start: order.earliest_start,
                        is_translucent: false
                    });
                }
            }
        }

        return { valid_lines, valid_orders };
    }

    static applyLineFilter = async (
        lines_orders: LineOrders[],
        unscheduled_orders: LineOrders[] = [],
        selecte_line_uuids: string[]
    ): Promise<FilterValidLinesOrders> => {

        // list of lines, that pass filter
        // - keeps only lines that are selected in filter
        // - keep all unscheduled orders
        const valid_lines: ValidLineOrders[] = [];
        const valid_orders: ValidOrder[] = [];

        for (const line_orders of lines_orders) {
            if (selecte_line_uuids.includes(line_orders.line_uuid)) {
                // map orders to valid orders
                for (const order of line_orders.orders) {
                    valid_orders.push({
                        uuid: order.uuid,
                        external_id: order.external_id,
                        material_external_id: order.material_external_id,
                        earliest_start: order.earliest_start,
                        is_translucent: false
                    });
                }
                // package into valid line
                valid_lines.push({
                    line_title: line_orders.line_title,
                    line_uuid: line_orders.line_uuid,
                    line_external_id: line_orders.line_external_id,
                    tags: line_orders.tags,
                    is_extra: false,
                    orders: []
                });
            }
        }

        // keep all unscheduled operations producting the material
        for (const line_orders of unscheduled_orders) {
            // take all orders
            for (const order of line_orders.orders) {
                valid_orders.push({
                    uuid: order.uuid,
                    external_id: order.external_id,
                    material_external_id: order.material_external_id,
                    earliest_start: order.earliest_start,
                    is_translucent: false
                });
            }
        }

        return { valid_lines, valid_orders };
    }

    static applyOrderFilter = async (
        lines_orders: LineOrders[],
        unscheduled_orders: LineOrders[],
        selected_order_external_ids: string[]
    ): Promise<FilterValidLinesOrders> => {

        // list of lines, that pass filter
        // - keep all lines
        // - keeps all orders for each line but highlight according to the filter
        const valid_lines: ValidLineOrders[] = [];
        const valid_orders: ValidOrder[] = [];

        for (const line_orders of lines_orders) {
            // map orders to valid orders
            for (const order of line_orders.orders) {
                valid_orders.push({
                    uuid: order.uuid,
                    external_id: order.external_id,
                    material_external_id: order.material_external_id,
                    earliest_start: order.earliest_start,
                    is_translucent: !selected_order_external_ids.includes(order.external_id)
                });
            }
            // package into valid line
            valid_lines.push({
                line_title: line_orders.line_title,
                line_uuid: line_orders.line_uuid,
                line_external_id: line_orders.line_external_id,
                tags: line_orders.tags,
                is_extra: false,
                orders: []
            });
        }

        // keep all unscheduled operations producting the material
        for (const line_orders of unscheduled_orders) {
            // take all orders
            for (const order of line_orders.orders) {
                if (selected_order_external_ids.includes(order.external_id)) {
                    valid_orders.push({
                        uuid: order.uuid,
                        external_id: order.external_id,
                        material_external_id: order.material_external_id,
                        earliest_start: order.earliest_start,
                        is_translucent: false
                    });
                }
            }
        }

        return { valid_lines, valid_orders };
    }

    static applyOrderTagFilter = async (
        lines_orders: LineOrders[],
        unscheduled_orders: LineOrders[],
        selected_order_tag: string,
        selected_order_tag_values: string[]
    ): Promise<FilterValidLinesOrders> => {

        // list of lines, that pass filter
        // - keep all lines
        // - keeps all orders for each line but highlight according to the filter
        const valid_lines: ValidLineOrders[] = [];
        const valid_orders: ValidOrder[] = [];

        for (const line_orders of lines_orders) {
            // map orders to valid orders
            for (const order of line_orders.orders) {
                valid_orders.push({
                    uuid: order.uuid,
                    external_id: order.external_id,
                    material_external_id: order.material_external_id,
                    earliest_start: order.earliest_start,
                    is_translucent: !selected_order_tag_values.includes(order.tags[selected_order_tag])
                });
            }
            // package into valid line
            valid_lines.push({
                line_title: line_orders.line_title,
                line_uuid: line_orders.line_uuid,
                line_external_id: line_orders.line_external_id,
                tags: line_orders.tags,
                is_extra: false,
                orders: []
            });
        }

        // keep all unscheduled operations producting the material
        for (const line_orders of unscheduled_orders) {
            // take all orders
            for (const order of line_orders.orders) {
                if (selected_order_tag_values.includes(order.tags[selected_order_tag])) {
                    valid_orders.push({
                        uuid: order.uuid,
                        external_id: order.external_id,
                        material_external_id: order.material_external_id,
                        earliest_start: order.earliest_start,
                        is_translucent: false
                    });
                }
            }
        }

        return { valid_lines, valid_orders };
    }

    static applyMaterialTagFilter = async (
        lines_orders: LineOrders[],
        unscheduled_orders: LineOrders[],
        selected_material_tag: string,
        selected_material_tag_values: string[],
        materials: t.IMaterialModel[]
    ): Promise<FilterValidLinesOrders> => {
        // list of lines, that pass filter
        // - keep all lines
        // - keeps all orders for each line but highlight according to the filter
        const valid_lines: ValidLineOrders[] = [];
        const valid_orders: ValidOrder[] = [];
        const materials_map: Map<string, t.IMaterialModel> = new Map(materials.map(m => [m.uuid, m]));
        for (const line_orders of lines_orders) {
            // map orders to valid orders
            for (const order of line_orders.orders) {
                const material = materials_map.get(order.material_uuid);
                const is_translucent = material == undefined ||
                    !selected_material_tag_values.includes(material.tags[selected_material_tag]);
                valid_orders.push({
                    uuid: order.uuid,
                    external_id: order.external_id,
                    material_external_id: order.material_external_id,
                    earliest_start: order.earliest_start,
                    is_translucent
                });
            }
            // package into valid line
            valid_lines.push({
                line_title: line_orders.line_title,
                line_uuid: line_orders.line_uuid,
                line_external_id: line_orders.line_external_id,
                tags: line_orders.tags,
                is_extra: false,
                orders: []
            });
        }

        // keep all unscheduled operations producing the material
        for (const line_orders of unscheduled_orders) {
            // take all orders
            for (const order of line_orders.orders) {
                const material = materials_map && materials_map.get(order.material_uuid);
                const is_valid = material != undefined &&
                    selected_material_tag_values.includes(material.tags[selected_material_tag]);
                if (is_valid) {
                    valid_orders.push({
                        uuid: order.uuid,
                        external_id: order.external_id,
                        material_external_id: order.material_external_id,
                        earliest_start: order.earliest_start,
                        is_translucent: false
                    });
                }
            }
        }

        return { valid_lines, valid_orders };
    }

    static applyInsightTypeFilter = async (
        lines_orders: LineOrders[],
        unscheduled_orders: LineOrders[],
        insights_mapped: Map<string, t.IEventDataEx[]>,
        selected_insight_type: string
    ): Promise<FilterValidLinesOrders> => {

        // list of lines, that pass filter
        // - keep all lines
        // - keeps all orders for each line but highlight according to the filter
        const valid_lines: ValidLineOrders[] = [];
        const valid_orders: ValidOrder[] = [];

        for (const line_orders of lines_orders) {
            // map orders to valid orders
            for (const order of line_orders.orders) {
                const insights = insights_mapped.get(order.uuid) || [];
                valid_orders.push({
                    uuid: order.uuid,
                    external_id: order.external_id,
                    material_external_id: order.material_external_id,
                    earliest_start: order.earliest_start,
                    is_translucent: !insights.some(i => i.type === selected_insight_type)
                });
            }
            // package into valid line
            valid_lines.push({
                line_title: line_orders.line_title,
                line_uuid: line_orders.line_uuid,
                line_external_id: line_orders.line_external_id,
                tags: line_orders.tags,
                is_extra: false,
                orders: []
            });
        }

        // keep all unscheduled operations producting the material
        for (const line_orders of unscheduled_orders) {
            // take all orders
            for (const order of line_orders.orders) {
                const insights = insights_mapped.get(order.uuid) || [];
                if (insights.some(i => i.type === selected_insight_type)) {
                    valid_orders.push({
                        uuid: order.uuid,
                        external_id: order.external_id,
                        material_external_id: order.material_external_id,
                        earliest_start: order.earliest_start,
                        is_translucent: false
                    });
                }
            }
        }

        return { valid_lines, valid_orders };
    }

    static applyFilters = async (
        lines_orders: LineOrders[],
        filters: PlanningTableFiltersState,
        unscheduled_orders: LineOrders[],
        materials: t.IMaterialModel[],
        insights_mapped: Map<string, t.IEventDataEx[]>,
        report_lines: t2.ISimulationReportOrderLineEx[],
        original_report_created_at: number
    ): Promise<FilterValidLinesOrders> => {
        if (BusinessLogic.getSysFlag("env") === "dev") {
           console.log("Applying filters:", filters);
        }
        if (filters.selected_filter_type === TypeFilterValues.reschedule_line && filters.reschedule_line_uuid !== null) {
            // RESCHEDULE ON LINE
            return await ReduxFilters.applyRescheduleLineFilter(
                lines_orders,
                unscheduled_orders,
                original_report_created_at,
                filters.reschedule_line_uuid
            );
        } else if (filters.selected_filter_type === TypeFilterValues.reschedule_operation && filters.reschedule_order_uuid !== null) {
            // RESCHEDULE OPERATION
            return await ReduxFilters.applyRescheduleOrderFilter(
                lines_orders,
                unscheduled_orders,
                report_lines,
                filters.reschedule_order_uuid
            );
        } else if (filters.selected_filter_type === TypeFilterValues.material && filters.selected_materials.length > 0) {
            // MATERIAL FILTER
            return await ReduxFilters.applyMaterialFilter(
                lines_orders,
                unscheduled_orders,
                filters.selected_materials
            );
        } else if (filters.selected_filter_type === TypeFilterValues.line && filters.selected_line_uuids.length > 0) {
            // LINE FILTER
            return await ReduxFilters.applyLineFilter(
                lines_orders,
                unscheduled_orders,
                filters.selected_line_uuids
            );
        } else if (filters.selected_filter_type === TypeFilterValues.order && filters.selected_order_external_ids.length > 0) {
            // OPERATION FILTER
            return await ReduxFilters.applyOrderFilter(
                lines_orders,
                unscheduled_orders,
                filters.selected_order_external_ids
            );
        } else if (filters.selected_filter_type === TypeFilterValues.order_tag_values &&
                filters.selected_order_tag !== null && filters.selected_order_tag_values.length > 0) {
            // ORDER TAG FILTER
            return await ReduxFilters.applyOrderTagFilter(
                lines_orders,
                unscheduled_orders,
                filters.selected_order_tag,
                filters.selected_order_tag_values
            );
        } else if (filters.selected_filter_type === TypeFilterValues.material_tag_values &&
                filters.selected_material_tag !== null && filters.selected_material_tag_values.length > 0) {
            // MATERIAL TAG FILTER
            return await ReduxFilters.applyMaterialTagFilter(
                lines_orders,
                unscheduled_orders,
                filters.selected_material_tag,
                filters.selected_material_tag_values,
                materials
            );
        } else if (filters.selected_filter_type === TypeFilterValues.order_insight_type && filters.selected_insight_type !== null) {
            // INSIGHT TYPE FILTER
            return await ReduxFilters.applyInsightTypeFilter(
                lines_orders,
                unscheduled_orders,
                insights_mapped,
                filters.selected_insight_type
            );
        }

        // NO FILTER
        return ReduxFilters.applyNoFilter(lines_orders, unscheduled_orders);
    }

    static related_lines_cache = new Map<string, t.IRelatedLinesForOrder>;

    static getRelatedLines = async (order_uuid: string): Promise<t.IRelatedLinesForOrder> => {
        let order_uuid_mapped = order_uuid;
        // check if the operation is not in the related lines map
        if (!ReduxFilters.related_lines_cache.has(order_uuid_mapped)) {
            // check if the opeartion is a chunk operation
            if (LinesOrdersMaps.chunk_orders.has(order_uuid)) {
                const chunk_order = LinesOrdersMaps.chunk_orders.get(order_uuid);
                if (chunk_order && chunk_order.parent_order_uuid) {
                    // set order_uuid to chunk parent uuid
                    order_uuid_mapped = chunk_order.parent_order_uuid;
                }
            }
        }
        let related_lines = ReduxFilters.related_lines_cache.get(order_uuid_mapped);

        if (!related_lines) {
            const res = await getBackend2().manufacturing.getOperationRelatedLines({
                uuid: order_uuid_mapped
            });
            related_lines = res.related_lines;
            ReduxFilters.related_lines_cache.set(order_uuid_mapped, related_lines);
        }

        return related_lines;
    }

    static filterStockRequirements = (
        lines_orders: LineOrders[],
        unscheduled_orders: LineOrders[],
        stock_requirements: StockRequirement[],
        orders_filters: PlanningTableFiltersState,
        stock_requirements_filters: StockRequirementsFiltersState,
        insights_map_stock_requirements: InsightsMapStockRequirements
    ): StockRequirement[] => {
        let filtered_materials = [];
        const reschedule_order_uuid = orders_filters.reschedule_order_uuid;

        if (reschedule_order_uuid) {
            const order = (
                LinesOrdersLogic.findOrder(lines_orders, reschedule_order_uuid) ||
                LinesOrdersLogic.findOrder(unscheduled_orders, reschedule_order_uuid)
            );

            if (order) {
                filtered_materials = [order.material_external_id];
            }
        } else if (orders_filters.selected_materials.length > 0) {
            filtered_materials = orders_filters.selected_materials;
        }

        if (stock_requirements_filters.has_insights_filter) {
            stock_requirements = stock_requirements.filter(sr => insights_map_stock_requirements.has(sr.uuid));
        }

        if (filtered_materials.length === 0) {
            return stock_requirements;
        }

        return stock_requirements.filter(sr => filtered_materials.includes(sr.material_external_id));
    }
}

export const reduxRecommendPlan = RecommendPlanLogic.load;

type OnOrderClickArgs = {
    order_uuid: string,
    order_external_id: string,
    source_type: GanttChartSourceTypes,
    is_extra_line: boolean,
    is_ctrl_down: boolean,
    advanced_order_menu: AdvancedOrderMenuState,
    process_num: string,
    reduxDispatch: (args: SetAdvancedOrderMenuAction | SetRescheduleOrderAction |
        ResetAdvancedOrderMenu | ResetFiltersAction | SetOrderTableProductionModalAction |
        SetSkipStockReport | SetLeftMenuSelectedView | SetHighlightedOrders | SetLastClickedOrderUuid |
        SetRescheduleOrderBundleAction /* | UpdateChunkedOrderAction */
    ) => void,
    is_readonly: boolean,
    is_filter_locked: boolean,
}

export type StockRequirementActions = (
    SetSelectedStockRequirement |
    UpdateStockRequirements | UpdateStockRequirementsHeightAction |
    SetSelectedLineUuidsAction | SetHighlightedOrders |
    SetRescheduleOrderAction | SetLeftMenuSelectedView | ReportLoadingAction |
    SetStockReqFilterMaterials | ResetFiltersAction
)

export class UserActions {

    static onStockRequirementClick = async (
        uuid: string,
        material_external_id: string,
        lines_orders: LineOrders[],
        reduxDispatch: (args: StockRequirementActions) => void,
        same_selected: boolean
    ) => {
        if (same_selected) {
            reduxDispatch({ type: REPORT_LOADING_ACTION_NAME, data: true });
            // when resetting filters rendering all the elements takes some time
            // with timeout the user will get whiteout sooner
            setTimeout(() => {
                if (!ReduxFilters.isLineLocked()) {
                    reduxDispatch({ type: RESET_FILTERS, data: undefined });
                }
                reduxDispatch({ type: REPORT_LOADING_ACTION_NAME, data: false });
            }, 5);
            return;
        }
        reduxDispatch({ type: REPORT_LOADING_ACTION_NAME, data: true });
        reduxDispatch({ type: SET_SELECTED_STOCK_REQUIREMENT, data: uuid });
        reduxDispatch({ type: SET_STOCK_REQ_FILTERS_MATERIALS, data: [material_external_id] });
        reduxDispatch({ type: SET_LEFT_MENU_SELECTED_VIEW, data: "stock_requirements" });

        const rect = document.querySelector(`[name='stock-requirements-rect'][data-uuid='${uuid}']`);

        if (rect) {
            setTimeout(() => {
                // $FlowFixMe
                rect.scrollIntoView({ block: "center", inline: "center" });
            }, 500);
        }

        // filter gantt chart lines
        // filter unscheduled orders
        // highlight orders that have insights based on the stock requirement id
        const plant_uuid = localStorage.getItem("last-plant");
        if (!plant_uuid) {
            return null;
        }

        getBackend2().manufacturing.getSingleStockRequirementsShifts({
            plant_uuid,
            stock_requirement_uuid: uuid
        }).then((res) => {
            const line_uuids = new Set();
            for (const sr of res.stock_requirements) {
                for (const line_uuid of sr.line_uuids || []) {
                    line_uuids.add(line_uuid);
                }
            }

            const highlighted_orders_map: Map<string, OrderHighlights> = new Map();
            for (const line of lines_orders) {
                for (const order of line.orders) {
                    if (order.material_external_id === material_external_id) {
                        highlighted_orders_map.set(order.uuid, { is_highlighted: true });
                    }
                }
            }


            reduxDispatch({ type: SET_HIGHLIGHTED_ORDERS, data: { highlighted_orders_map } });
            if (!ReduxFilters.isLineLocked()) {
                reduxDispatch({ type: SET_SELECTED_LINE_UUIDS, data: [...line_uuids] });
            }
            reduxDispatch({ type: REPORT_LOADING_ACTION_NAME, data: false });
        });
    }

    static onOrderClick = async (args: OnOrderClickArgs) => {
        const {
            order_uuid,
            order_external_id,
            source_type,
            is_extra_line,
            is_ctrl_down,
            advanced_order_menu,
            process_num,
            reduxDispatch,
            is_readonly,
            is_filter_locked
        } = args;

        const is_planning_table = (
            source_type === GanttChartSource.gantt_unscheduled ||
            source_type === GanttChartSource.planning_table ||
            source_type === GanttChartSource.planning_table_parallel
        );

        const is_linked_operation = LinesOrdersMaps.rework_uuid_base_order.has(order_uuid);
        if ((!is_planning_table || is_extra_line || is_readonly) && !is_linked_operation) {
            reduxDispatch({
                type: SET_ORDER_TABLE_PRODUCTION_MODAL,
                data: {
                    show_modal: true,
                    order_external_id,
                    order_uuid,
                    order_process_num: process_num
                }
            });
        } else if (is_ctrl_down) {
            reduxDispatch({ type: SET_ADVANCED_ORDER_MENU, data: advanced_order_menu });
        } else {
            await ReduxFilters.setRescheduleOrderUuid(reduxDispatch, order_uuid, "gantt", is_filter_locked);
        }
    }
}

export class DraggableLogic {

    static querySelector = (source_type: string, selector: string) => {
        return document.querySelector(`#${source_type} ${selector}`);
    }

    static querySelectorAll = (source_type: string, selector: string) => {
        return document.querySelectorAll(`#${source_type} ${selector}`);
    }

    static changeMouseCursor = (cursor: CursorKeys | null) => {
        const new_event = new CustomEvent(SET_MOUSE_CURSOR, { detail: { key: cursor } });
        document.dispatchEvent(new_event);
    }
}

const robust_get_quantile = (arr: rt.ISimulationReportOrderQuantileData[], idx: number): number | null => {
    if (arr == undefined || arr.length == 0) { return null; }
    if (arr.length <= idx) { return arr[0].val; }
    return arr[idx].val;
};

export type ReportOrderEpochs = {
    initial_epoch_start: number | null,
    initial_epoch_end: number | null
}

export const calculateReportOrderEpochs = (
    order: t2.ISimulationReportOrderEx,
    intial_shift: rt.ISimulationReportShiftTime,
    quantile_idx: number
): ReportOrderEpochs => {
    const initial_epoch_offset = dateFromWeekAndShift(intial_shift.week, intial_shift.year, intial_shift.shift_number).getTime();
    const initial_hours_start = robust_get_quantile(order.estimated_start, quantile_idx);
    let initial_epoch_start = null;
    if (initial_hours_start !== null) {
        initial_epoch_start = initial_hours_start * TIME_RANGES.HOUR + initial_epoch_offset;
    }

    let initial_epoch_end = null;
    const initial_hours_end = robust_get_quantile(order.estimated_completion, quantile_idx);
    if (initial_hours_end !== null) {
        initial_epoch_end = initial_hours_end * TIME_RANGES.HOUR + initial_epoch_offset;
    }
    return { initial_epoch_start, initial_epoch_end };
}

export const getPreviousOperationEpochs = (
    report_data: t2.IReportModelEx,
    order_external_id: string,
    max_process_num: number
): ReportOrderEpochs | null => {
    let max_order = null;
    for (const line of report_data.result.orders) {
        for (const order of line.production) {
            const order_process_num = parseFloat(order.process_num);
            if (order.order_external_id === order_external_id) {
                if (order_process_num < max_process_num) {
                    if (!max_order) {
                        max_order = order;
                        continue;
                    } else if (order_process_num > parseFloat(max_order.process_num)) {
                        max_order = order;
                    }
                }
            }
        }
        if (max_order) {
            return calculateReportOrderEpochs(max_order, report_data.result.next_shift_time, 1);
        }
    }
    return null;
}

export const disableUserSelect = () => {
    const els = document.querySelectorAll("#scheduled-orders,#unscheduled-orders");
    for (const el of els) {
        if (el && !el.classList.contains("disable-user-events")) {
            el.classList.add("disable-user-events")
        }
    }
}

export const enableUserSelect = () => {
    const els = document.querySelectorAll("#scheduled-orders,#unscheduled-orders");
    for (const el of els) {
        if (el && el.classList.contains("disable-user-events")) {
            el.classList.remove("disable-user-events")
        }
    }
}

export class ResizeLeftMenu {

    static RESIZE_EVENT_NAME: "resize_left_menu" = "resize_left_menu";
    static listeners = [];

    static onUnmount = () => {
        for (const l of ResizeLeftMenu.listeners) {
            window.removeEventListener(ResizeLeftMenu.RESIZE_EVENT_NAME, l);
        }
    }

    static getWidth = (): number => {
        const width = localStorage.getItem(ResizeLeftMenu.RESIZE_EVENT_NAME);
        if (!width) {
            return 450;
        }
        return parseInt(width);
    }

    static onResize = (new_width: number) => {
        if (new_width > 450) {
            localStorage.setItem(ResizeLeftMenu.RESIZE_EVENT_NAME, new_width.toString());
            const event = new CustomEvent(ResizeLeftMenu.RESIZE_EVENT_NAME);
            window.dispatchEvent(event);
        }
    }

    static listen = (cb: () => void) => {
        ResizeLeftMenu.listeners.push(cb);
        window.addEventListener(ResizeLeftMenu.RESIZE_EVENT_NAME, cb);
    }
}

export class LeftMenuOrdersDividerEventHandler {

    static RESIZE_EVENT_NAME: "resize_orders_lol" = "resize_orders_lol";
    static listeners = [];

    static onUnmount = () => {
        for (const l of LeftMenuOrdersDividerEventHandler.listeners) {
            window.removeEventListener(LeftMenuOrdersDividerEventHandler.RESIZE_EVENT_NAME, l);
        }
    }

    static getHeight = (): number => {
        const height = localStorage.getItem(LeftMenuOrdersDividerEventHandler.RESIZE_EVENT_NAME);
        if (!height) {
            return 0;
        }
        return parseInt(height);
    }

    static onResize = (new_height: number) => {
        new_height = new_height - 285;
        const el = document.querySelector("#left-menu-orders");
        if (el) {
            const rect = el.getBoundingClientRect();
            if (new_height > 10 && new_height < rect.height) {
                localStorage.setItem(LeftMenuOrdersDividerEventHandler.RESIZE_EVENT_NAME, new_height.toString());
                const event = new CustomEvent(LeftMenuOrdersDividerEventHandler.RESIZE_EVENT_NAME);
                window.dispatchEvent(event);
            }
        }
    }

    static listen = (cb: () => void) => {
        LeftMenuOrdersDividerEventHandler.listeners.push(cb);
        window.addEventListener(LeftMenuOrdersDividerEventHandler.RESIZE_EVENT_NAME, cb);
    }
}


export class ReportLoadingEventHandler {

    static EVENT: "report-loading-event" = "report-loading-event";

    static showLoading = (show: boolean) => {
        const e = new CustomEvent(
            ReportLoadingEventHandler.EVENT,
            {
                detail: {
                    loading: show
                }
            }
        );
        window.dispatchEvent(e);
    }

}
