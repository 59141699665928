// @flow

// main imports

// load subcomponents
import { BatchopGroupCreate } from "./BatchopGroup.create";

// backend
import { getBackend } from "../../lib/backend/Backend2";
import { BATCHOP_GROUP_TAGS } from "../../lib/ManufacturingTags.generated";

export type ITagsBoolean = {
    [key: string]: boolean
};

/**
 * This objects extends line create. It only changes the way the data is loaded.
 */
class BatchopGroupEdit extends BatchopGroupCreate {

    /**
     * Loading the component (plants and lines data).
     */
    async componentDidMount() {
        // loading data for the line
        // linegroup_uuid is uuid parameter from URL path
        const { uuid } = this.props.match.params;
        const batchop_group = (await getBackend().batchOperations.getGroups({})).items
            .filter(x => x.uuid === uuid)[0];
        const x = (await getBackend().manufacturing.getLineGroups({}));

        const line_groups = x.line_groups;

        if (batchop_group !== null) {
            let tags = this.state.tags;
            let tag_state: ITagsBoolean = {};

            //overwrite with existing tags
            if (batchop_group.tags != null) {
                Object.keys(tags).forEach((el) => {
                    if ((batchop_group.tags) && (batchop_group.tags.hasOwnProperty(el))) {
                        // tags are strings, button states are boolean
                        tags[el] = batchop_group.tags[el];
                        tag_state[el] = (batchop_group.tags[el] === "true");
                    }
                })
            }

            // extract line group
            const line_group_value = batchop_group.tags[BATCHOP_GROUP_TAGS.line_group];
            tags.line_group = line_group_value;
            let line_group_label = "";
            if (line_groups.length > 0) {
                const line_group_arr = line_groups.filter((x) => x.uuid === line_group_value);
                if (line_group_arr.length > 0) {
                    line_group_label = line_group_arr[0].title;
                }
            }
            const line_group = {
                label: line_group_label,
                value: line_group_value
            }

            // merging two objects into a single state
            this.setState({
                ...{
                    uuid: batchop_group.uuid,
                    title: batchop_group.name,
                    line_group: line_group,
                    line_groups: line_groups,
                    tags: batchop_group.tags
                },
                ...tag_state
            })
        }
    }
}

export default BatchopGroupEdit;
