// @flow
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { translate } from "../../IntlProviderWrapper";
import { getBackend as getBackend2 } from "../../../lib/backend/Backend2";
import * as BusinessLogic from "../../../lib/BusinessLogic";
import type { WeeklyReportWorker, WeeklyReport } from "../../../lib/Models";
import { weekNumber, myday, niceNumber, dateFromWeekAndShift, toISODateTimeString } from "../../../lib/Util";
import * as t from "../../../lib/backend/manufacturing2.generated.types";
import { INSIGHT_TYPES } from "../../../lib/ManufacturingTags.generated";
import { getDateOfISOWeek } from "../../../lib/Util"
import { loadInsights } from "../../../lib/WeeklyRealization"


import type { ScrapUptimeMatrixLines } from "../../manufacturing/WeeklyRealizationCommon"

const getNumber = (number: string | number, decimal: ?number, is_export: ?boolean = false): number | string => {
    // to use niceNumber inside getNumber please use the decimal param
    if (number === "-" && is_export) {
        return "0";
    }

    if (is_export) {
        return number;
    }

    return (decimal || decimal === 0) && typeof number !== "string" ? niceNumber(number, decimal) : number;
}

const getProducedRatio = (produced: number, planned: number, is_export: boolean = false): string => {
    return (planned > 0) ? (getNumber(100.0 * (produced / planned), 1, is_export) + "%") : "-"
}

const getShowPredicted = (filter: MicroplanSummaryFilterProps) => {
    return filter.week >= weekNumber(new Date());
}

const getStickyOffsetTop = () => {
    let top;
    const el = document.querySelector(".views-filter-bar") || document.querySelector(".navigation-bar");
    if (el) {
        const rect = el.getBoundingClientRect();
        top = rect.top + rect.height;
    }
    return top;
}

const prettyWorkerName = (worker: WeeklyReportWorker[]): string[] => {
    // we have no workers
    if (worker.length === 0) { return ["-"]; }

    const surnames = worker.map(w => {
        if (w.name !== undefined && w.name !== null && w.name.length > 0) {
            // get surname (first part of name)
            return w.name.split(" ")[0];
        } else {
            // unknown worker, return external id
            return translate("common.reg_number_short", "RN") + " " + w.external_id;
        }
    });
    const unique_surnames = [...new Set(surnames)];
    return unique_surnames;
}

// navigation filter
type MicroplanSummaryFilterProps = {
    year: number,
    week: number,
    breakdown_level: string,
    produced_scrap_downtime: string
}

type MaterialsDayProps = {
    merged_data: WeeklyReport[],
    is_export: boolean,
    filter: MicroplanSummaryFilterProps,
    line_group_uuid: string,
    show_line_day_sum: boolean,
    scrap_uptime_matrix?: ScrapUptimeMatrixLines,
    produced_scrap_downtime: string
};
type MaterialsDayState = {
    mat: React.Node[],
    show_predicted: boolean,
    is_export: boolean,
    weekly_planned: number,
    weekly_done: number,
    daily_done: number,
    days: any[],
    insights: t.IEventDataEx[][],
    start_time: number, 
    end_time: number,
    line_uuids: string[],
    scrap_uptime_matrix: ScrapUptimeMatrixLines
};

type ProductionMatrixObj = {
    val: number,
    plan: number,
    prediction: number,
    status: string,
    worker: WeeklyReportWorker[],
    insights: t.IEventDataEx[],
    scrap: number,
    downtime: number,
    line_change: boolean
}

export class MaterialsDay extends React.Component<MaterialsDayProps, MaterialsDayState> {

    state = {
        show_predicted: false,
        is_export: false,
        weekly_planned: 0,
        weekly_done: 0,
        daily_done: 0,
        days: [],
        mat: [],
        insights: [],
        start_time: 0,
        end_time: 0,
        line_uuids: [],
        scrap_uptime_matrix: new Map<string, any>
    }


    componentDidMount() {
        this.load();
    }

    async load() {

        let start_time = getDateOfISOWeek(this.props.filter.week, this.props.filter.year)
        let end_time = getDateOfISOWeek(this.props.filter.week, this.props.filter.year);

        end_time.setDate(start_time.getDate() + 7);

        const line_group = BusinessLogic.getLineGroupForUser(this.props.line_group_uuid);
        let line_uuids = line_group ? line_group.line_uuids : [];

        this.setState({ start_time, end_time, line_uuids });

        const insights = await loadInsights(line_uuids, this.props.filter.week, this.props.filter.year);
        this.setState({ insights, scrap_uptime_matrix: this.props.scrap_uptime_matrix });

    }

    getEmptyProductionMatrix(slots: number): ProductionMatrixObj[] {
        const result = [];
        for (let slot = 0; slot < slots; slot++) {
            result.push({
                val: 0,
                plan: 0,
                prediction: 0,
                status: "",
                worker: [],
                insights: [],
                scrap: 0,
                downtime: 0,
                line_change: false
            });
        }
        return result;
    }

    getDowntime(line_uuid: string, material_external_id: string = "", day_n: number) {

        if (this.props.produced_scrap_downtime === "produced") {
            return 0;
        }

        const insights = this.state.insights;
        if (!insights || insights === undefined) {
            return 0;
        }

        const matrix = this.props.scrap_uptime_matrix;
        if (!matrix) {
            return 0;
        }

        const lines = matrix;
        if (!lines) {
            return 0;
        }

        const specific_line = lines.get(line_uuid);
        if (!specific_line) {
            return 0;
        }

        const working_shifts = specific_line.get("workingShifts");
        if (!working_shifts) {
            return 0;
        }

        let downtime_hours = 0;

        for (const insight_shift of insights) {
            if(!insight_shift || insight_shift === undefined) {
                return 0;
            }
            for (const element of insight_shift) {
                //const element = insight_shift[index];


                if (element.tags.line_uuid === line_uuid &&
                    (element.tags.material_external_id === material_external_id || material_external_id === "") &&
                    Math.floor(Number(element.tags.shift) / 3) == Number(day_n) &&
                    working_shifts[Number(element.tags.shift)].enabled
                ) {
                    downtime_hours += element.extra_data.duration;
                }
            }
        }
        return downtime_hours;
    }

    getScrapValue(line_uuid: string, material_external_id: string, day_n: number) {

        if (this.props.filter.produced_scrap_downtime !== "produced") {
            if (!this.props.scrap_uptime_matrix || material_external_id === "") {
                return 0;
            }

            const lines = this.props.scrap_uptime_matrix;
            if (!lines) {
                return 0;
            }

            const specific_line = lines.get(line_uuid)
            if (!specific_line) {
                return 0;
            }
            const materials = specific_line.get("materials");
            if (!materials) {
                return 0;
            }

            const specific_material = materials.get(material_external_id);
            let sum_scrap = 0;

            for (const shift in specific_material) {
                let day = Math.floor(specific_material[shift].shift_number / 3);
                if (day === day_n) {
                    sum_scrap += specific_material[shift].scrap;
                }
            }

            return sum_scrap;
        }

    }

    prepareData() {
        const is_export = this.props.is_export;
        const filter = this.props.filter;

        // merge simulation data with SQL data
        let merged_data = this.props.merged_data;
        // aggregte production numbers to weekly level
        let day_data = [];
        // get flag from the line group to see if we should render workers next to productions
        const show_workers = BusinessLogic.getLineGroupTagBool(this.props.line_group_uuid, "weekly_realization_show_workers", true);
        const show_predicted = getShowPredicted(this.props.filter);

        if (merged_data.length > 0) {
            let day_production = {}
            // reduce to one element per (line, material)
            for (const production of merged_data) {
                // get day of the week

                const day_n = Math.floor(production.shift_number / 3);
                // check if we reached a (line, material) pair
                if ((production.line_uuid !== day_production.line_uuid) ||
                    (production.material_external_id !== day_production.material_external_id) ||
                    (day_n !== day_production.day_n)) {

                    // remember current aggregate
                    if (day_production.line_uuid) {
                        day_data.push(day_production);
                    }


                    // check if we have line change
                    const line_change = production.line_uuid !== day_production.line_uuid;

                    // create a new one
                    day_production = {
                        line_change: line_change,
                        line_uuid: production.line_uuid,
                        line_title: production.line_title,
                        material_external_id: production.material_external_id,
                        material_title: production.material_title,
                        measurement_unit: production.measurement_unit,
                        norm: production.norm,
                        day_n: day_n,
                        produced: [0, 0, 0],
                        status: production.status,
                        plan: 0,
                        worker: [...production.worker],
                        scrap: 0,
                        downtime: 0,
                        uptime: 0
                    };
                }

                if (production.line_uuid === day_production.line_uuid) {
                    day_production.worker.push(...production.worker);
                }

                // add to aggregate
                day_production.produced[production.status !== undefined ? production.status : 0] += production.sum;
                // we keep the most realistic status
                if (day_production.status !== undefined && production.status !== undefined) {
                    day_production.status = Math.min(day_production.status, production.status);
                }
                // daily plun is a sum of shift plans
                day_production.plan += production.plan;
            }
            day_data.push(day_production);
        }

        // get list of day names
        // $FlowFixMe
        const days = Array(7).fill(0).map((el, i) => { return myday(i, 3) });
        // map numeric status to text status
        const status_labels = ["confirmed", "unconfirmed", "prediction"];

        // we assume that rows are grouped by lines/materials from the SQL call in the backend
        const distinctLines = [...new Set(day_data.map(x => x.line_uuid))];
        // create map of line_uuid to line_title
        const line_title_map = new Map();
        for (const x of day_data) {
            line_title_map.set(x.line_uuid, x.line_title);
        }
        let daily_done = [0, 0, 0, 0, 0, 0, 0];
        let weekly_done = 0, weekly_planned = 0;

        // for scrap products
        let daily_scrap = [0, 0, 0, 0, 0, 0, 0];
        let weekly_scrap = 0;

        // for scrap products
        let daily_downtime = [0, 0, 0, 0, 0, 0, 0];
        let weekly_downtime = 0;

        // $FlowFixMe
        const mat = distinctLines.map((line: string, i: number): React.Node[] => {
            const line_title = line_title_map.get(line) || "";
            let filteredByLine = day_data.filter(x => x.line_uuid === line);

            const distinctMaterials = [...new Set(filteredByLine.map(x => x.material_title))];
            const distinctMaterialIds = [...new Set(filteredByLine.map(x => x.material_external_id))];

            let pieces = [];
            let linesJSX = [];
            distinctMaterialIds.forEach((material_external_id, i) => {
                // make a copy of the object (otherwise it stays the same)
                let material_production: ProductionMatrixObj[] = this.getEmptyProductionMatrix(7);
                let filteredByMaterial = filteredByLine.filter(x => x.material_external_id === material_external_id);

                const material_norm = (filteredByMaterial.length > 0) ? filteredByMaterial[0].norm :
                    { capacity_factor: 0.0, norm: 0.0, norm_base_units: 0.0, norm_break_time_hours: 0.0, norm_machine_time: 0.0 };

                filteredByMaterial.forEach((material_line) => {
                    if (material_line.day_n !== undefined) {
                        const line_sum = material_line.produced[0] + material_line.produced[1];
                        const line_status = material_line.status !== undefined ? material_line.status : 0;

                        material_production[material_line.day_n].val = line_sum;
                        material_production[material_line.day_n].scrap = Number(this.getScrapValue(material_line.line_uuid, material_line.material_external_id, Number(material_line.day_n))) || 0;
                        material_production[material_line.day_n].downtime = this.getDowntime(material_line.line_uuid, material_line.material_external_id, material_line.day_n);
                        material_production[material_line.day_n].plan = material_line.plan;
                        material_production[material_line.day_n].prediction = material_line.produced[2];
                        material_production[material_line.day_n].status = status_labels[line_status];
                        material_production[material_line.day_n].line_change = material_line.line_change;

                        if (material_line.worker.length > 0) {
                            material_production[material_line.day_n].worker.push(...material_line.worker);
                        }

                        // get total sums
                        daily_done[material_line.day_n] += material_production[material_line.day_n].val;
                        daily_scrap[material_line.day_n] += material_production[material_line.day_n].scrap;
                        daily_downtime[material_line.day_n] += material_production[material_line.day_n].downtime;

                        weekly_done += material_production[material_line.day_n].val;
                        weekly_scrap += material_production[material_line.day_n].scrap;
                        weekly_downtime += material_production[material_line.day_n].downtime;
                        weekly_planned += material_production[material_line.day_n].plan;
                    }
                });
                pieces.push(material_production);
                linesJSX.push(
                    <LineMaterialDetail
                        i={i}
                        l={distinctMaterialIds.length}
                        line_title={line_title}
                        line_uuid={line}
                        material_title={distinctMaterials[i]}
                        material_external_id={material_external_id}
                        material_production={material_production}
                        material_norm={material_norm}
                        is_export={is_export}
                        show_workers={show_workers}
                        filter={filter}
                        scrap_uptime_matrix={this.props.scrap_uptime_matrix}
                        insights={this.state.insights}
                    />
                );
            });

            if (this.props.show_line_day_sum && (distinctMaterialIds.length > 1)) {
                // create sum for all materials by line
                let sum: ProductionMatrixObj[] = this.getEmptyProductionMatrix(7);
                for (let i = 0; i < 7; i++) {
                    for (let j = 0; j < distinctMaterialIds.length; j++) {
                        sum[i].val += pieces[j][i].val;
                        sum[i].plan += pieces[j][i].plan;
                        if (pieces[j][i].status !== "confirmed" && pieces[j][i].status !== "") {
                            sum[i].status = pieces[j][i].status;
                        }
                    }
                    // get total sums
                    weekly_done += sum[i].val;
                    weekly_planned += sum[i].plan;
                }
                linesJSX.push(
                    <LineMaterialDetail
                        i={distinctMaterialIds.length}
                        l={distinctMaterialIds.length}
                        line_title={line_title}
                        line_uuid={line}
                        material_title={"SUM"}
                        material_external_id={""}
                        material_production={sum}
                        material_norm={null}
                        is_export={is_export}
                        show_workers={show_workers}
                        scrap_uptime_matrix={this.props.scrap_uptime_matrix}
                        insights={this.state.insights}
                        filter={filter}
                    />
                );
            }
            return linesJSX;
        });

        return {
            mat,
            show_predicted,
            is_export,
            weekly_planned,
            weekly_done,
            daily_done,
            weekly_downtime,
            daily_downtime,
            weekly_scrap,
            daily_scrap,
            days
        }
    }

    render() {
        const {
            mat,
            show_predicted,
            //weekly_planned,
            weekly_done,
            daily_done,
            weekly_downtime,
            daily_downtime,
            weekly_scrap,
            daily_scrap,
            days
        } = this.prepareData();

        const is_export = this.props.is_export;

        const stickyStyle = {
            top: getStickyOffsetTop()
        };

        const PredictedColumnHeader = <span style={{ wordBreak: "break-all", fontSize: "10px", ...stickyStyle }}>
            <br />{translate("common.predicted2", "and predicted")}
        </span>;

        return (
            <div className="white_box charts">
                <table className="table">
                    <colgroup>
                        <col className={"breakdown-border"}></col>
                        <col className={"breakdown-border"}></col>
                        <col className={"breakdown-border breakdown-border-thick"}></col>
                        {
                            this.props.filter.breakdown_level !== "day" && this.props.filter.produced_scrap_downtime !== "scrap" &&
                            <col className={"breakdown-border breakdown-border-thick"}></col>
                        }
                        {
                            this.props.filter.breakdown_level !== "day" && this.props.filter.produced_scrap_downtime !== "scrap" &&
                            <col className={"breakdown-border breakdown-border-thick"}></col>
                        }
                        <col span="2" className={"breakdown-border"}></col>
                        <col span="2" className={"breakdown-border"}></col>
                        <col span="2" className={"breakdown-border"}></col>
                        <col span="2" className={"breakdown-border"}></col>
                        <col span="2" className={"breakdown-border"}></col>
                        <col span="2" className={"breakdown-border breakdown-weekend"}></col>
                        <col span="2" className={"breakdown-weekend"}></col>
                        <col className={"breakdown-border-thick-left breakdown-border breakdown-total"}></col>
                        <col className={"breakdown-border breakdown-total"}></col>
                        <col className={"breakdown-border breakdown-total"}></col>
                    </colgroup>
                    <thead>
                        <tr>
                            <th style={stickyStyle}>
                                <FormattedMessage id="common.line" defaultMessage="Line" />
                            </th>
                            <th style={stickyStyle}>Id</th>
                            <th style={stickyStyle}><FormattedMessage id="common.material" defaultMessage="material" /></th>
                            {this.props.filter.breakdown_level !== "day" && this.props.filter.produced_scrap_downtime !== "scrap" &&
                                <th style={stickyStyle} className="text-center">
                                    <FormattedMessage id="common.norm" defaultMessage="Norm" /><br />
                                    / <FormattedMessage id="common.shift" defaultMessage="Shift" />
                                </th>
                            }
                            {this.props.filter.breakdown_level !== "day" && this.props.filter.produced_scrap_downtime !== "scrap" &&
                                <th style={stickyStyle} className="text-center">
                                    <FormattedMessage id="common.planned_plural" defaultMessage="Planned" /><br />
                                    / <FormattedMessage id="common.week" defaultMessage="Week" />
                                </th>
                            }

                            {days.map((day, i) => {
                                return <th colSpan={2} style={stickyStyle} className="text-center" key={i}>{day}</th>;
                            })}
                            <th style={stickyStyle} colSpan={is_export ? 2 : 1} className="text-center">
                                <FormattedMessage id="common.total" defaultMessage="Total" />
                                {
                                    !is_export && show_predicted && <span style={{ wordBreak: "break-all", fontSize: "10px" }}>
                                        <br />{translate("common.predicted2", "and predicted")}
                                    </span>
                                }
                            </th>
                            {is_export && show_predicted ? <th style={stickyStyle}>{PredictedColumnHeader}</th> : null}
                        </tr>
                    </thead>
                    <tbody>
                        {mat}
                        {
                            this.props.filter.produced_scrap_downtime === "produced" ?
                                <tr className="tr-sum">
                                    <td colSpan={is_export ? 3 : 3} className="text-center">
                                        <FormattedMessage id="Manufacturing.MicroplanSummary.weekly_realisation" defaultMessage="Weekly realisation" />
                                    </td>
                                    {daily_done.map((x, i) => (<td colSpan={2} className="text-center" key={`sum_${i}`}>{getNumber(x, 0, is_export)}</td>))}
                                    <td className="text-center">{getNumber(weekly_done, 0, is_export)}</td>
                                </tr>
                                :
                                this.props.filter.produced_scrap_downtime === "scrap" ?
                                    <tr className="tr-sum">
                                        <td colSpan={is_export ? 3 : 3} className="text-center">
                                            <FormattedMessage id="Header.menu.weekly_realization" defaultMessage="Weekly scrap" />
                                        </td>
                                        {daily_scrap.map((x, i) => (<td colSpan={2} className="text-center" key={`sum_${i}`}>{getNumber(x, 0, is_export)}</td>))}
                                        <td className="text-center">{getNumber(weekly_scrap, 2, is_export)}</td>
                                    </tr>
                                    :
                                    this.props.filter.produced_scrap_downtime === "downtime" ?
                                        <tr className="tr-sum">
                                            <td colSpan={is_export ? 3 : 3} className="text-center">
                                                <FormattedMessage id="Header.menu.weekly_realization" defaultMessage="Weekly downtime" />
                                            </td>
                                            {daily_downtime.map((x, i) => (<td colSpan={2} className="text-center" key={`sum_${i}`}>{getNumber(x, 2, is_export) + "h"}</td>))}
                                            <td className="text-center">{getNumber(weekly_downtime, 2, is_export) + "h"}</td>
                                        </tr>
                                        : ""
                        }
                    </tbody>
                </table>
            </div>
        )
    }
}


type LineMaterialDetailProps = {
    i: number;
    l: number;
    line_title: string;
    line_uuid: string;
    material_title: string;
    material_external_id: string;
    material_production: ProductionMatrixObj[];
    material_norm: t.IWeeklyProducedNormRec | null;
    is_export: boolean;
    show_workers: boolean;
    filter: MicroplanSummaryFilterProps;
    scrap_uptime_matrix?: ScrapUptimeMatrixLines;
    insights: t.IEventDataEx[][];
};

type LineMaterialDetailState = {
    production_details_line_title: string,
    production_details_line_uuid: string,
    production_details_start: string,
    production_details_end: string,
    production_details_data: t.IProducedModel[],
    production_details_insights: t.IEventDataEx[],
    error: string,
    show_production_details: boolean
};

export class LineMaterialDetail extends React.Component<LineMaterialDetailProps, LineMaterialDetailState> {

    getShowPredicted = () => {
        return this.props.filter.week >= weekNumber(new Date());
    }

    showProductionDetails = () => {
        this.setState({ show_production_details: true });
    };

    async loadProductionDetails(line_title: string, line_uuid: string, material_external_id: string, start_time: number, end_time: number) {
        try {
            // reset what we have
            this.setState({
                production_details_line_title: line_title,
                production_details_line_uuid: line_uuid,
                production_details_start: toISODateTimeString(new Date(start_time)),
                production_details_end: toISODateTimeString(new Date(end_time)),
                production_details_data: [],
                production_details_insights: []
            });
            // get new production realization data for selected time range
            const res_produced = await getBackend2().manufacturing.getProduced({
                end: end_time, start: start_time, uuid: line_uuid
            });
            const production_details_data = res_produced.data
                .filter(x => x.material_external_id === material_external_id);
            this.setState({ production_details_data });
            this.showProductionDetails();
            // load insights to show downtimes and comments
            const insight_criteria: t.IGetInsightsReq = {
                from: start_time,
                to: end_time,
                types: [INSIGHT_TYPES.man_downtime],
                lines: [line_uuid]
            }
            const res_insights = await getBackend2().manufacturing.getInsights(insight_criteria);
            const insights_data = res_insights.insights;
            // filter out insights that start at the end of range or finish at start of range
            const insights = insights_data.filter(insight => {
                return insight.ts_to > start_time && end_time > insight.ts;
            })
            // add to the state
            this.setState({ production_details_insights: insights });

        } catch (e) {
            this.setState({ error: e.message });
        }
    };

    getDowntimeVsAllTime = (insights: t.IEventDataEx[][], scrap_uptime_matrix: ScrapUptimeMatrixLines, line_uuid: string, material_external_id: string) => {
        /** This functions calculates uptime for specific line. */
        /** It returns value for field on downtime / day in last (sum) column */

        const lines = scrap_uptime_matrix;
        if (!lines) {
            return "";
        }

        const specific_line = lines.get(line_uuid);
        if (!specific_line) {
            return "";
        }

        const working_shifts = specific_line.get("workingShifts");
        if (!working_shifts) {
            return "";
        }

        let sum_uptime = 0;

        for (const shift of working_shifts) {
            if (shift.enabled === true) {
                sum_uptime += 8;
            }
        }

        let downtime_hours = 0;

        if(!insights || insights === undefined) {
            return 0;
        }

        for (const insight of insights) {
            if(!insight || insight === undefined) {
                return 0;
            }
            for (const element of insight) {
                if (element.tags.line_uuid === line_uuid &&
                    element.tags.material_external_id == material_external_id &&
                    working_shifts[Number(element.tags.shift)].enabled
                ) {
                    downtime_hours += Number(element.extra_data.duration);
                }
            }
        }

        if (downtime_hours == 0) {
            return "";
        }

        return (getNumber(downtime_hours, 2, false) + "h/" + sum_uptime + "h (" + getNumber((downtime_hours / sum_uptime) * 100, 2, false) + "%)");
    }

    getDownTimeForCell = (insights: t.IEventDataEx[][], scrap_uptime_matrix: ScrapUptimeMatrixLines, line_uuid: string, material_external_id: string, day: number) => {

        const lines = scrap_uptime_matrix;
        if (!lines) {
            return "";
        }

        const specific_line = lines.get(line_uuid);
        if (!specific_line) {
            return "";
        }

        const working_shifts = specific_line.get("workingShifts");
        if (!working_shifts) {
            return "";
        }

        if(!insights || insights === undefined || insights === null) {
            return "";
        }


        let downtime_hours = 0;

        for (const insight of insights) {
            if(!insight || insight === undefined) {
                return 0;
            }
            for (const element of insight) {
                //const element = insight[index];
                if (element.tags.line_uuid === line_uuid &&
                    element.tags.material_external_id === material_external_id &&
                    Math.floor(Number(element.tags.shift) / 3) == Number(day) &&
                    working_shifts[Number(element.tags.shift)].enabled
                ) {
                    downtime_hours += element.extra_data.duration;
                }
            }
        }
        if (downtime_hours === 0) {
            return "";
        }
        return getNumber(downtime_hours, 2, false) + "h";
    }

    sumPerDay = (insights: t.IEventDataEx[][], matrix: ScrapUptimeMatrixLines, line_uuid: string, line_title: string) => {

        let lines_for_return = [];


        lines_for_return.push(
            <td style={{ fontWeight: "800", backgroundColor: "lightgray" }}> {line_title} </td>);
        lines_for_return.push(
            <td style={{ fontWeight: "800", backgroundColor: "lightgray" }}> </td>);
        lines_for_return.push(
            <td style={{ fontWeight: "800", backgroundColor: "lightgray" }}> </td>);

        if (!insights) {
            return lines_for_return;
        }

        if (!matrix) {
            return lines_for_return;
        }

        const lines = matrix;
        if (!lines) {
            return lines_for_return;
        }

        const specific_line = lines.get(line_uuid);
        if (!specific_line) {
            return lines_for_return;
        }

        const working_shifts = specific_line.get("workingShifts");
        if (!working_shifts) {
            return lines_for_return;
        }

        let sum_uptime = 0;


        for (const shift of working_shifts) {
            if (shift.enabled) {
                sum_uptime += 8;
            }
        }

        let sum_downtime_hours = 0;

        for (let day_n = 0; day_n < 7; day_n++) {

            let downtime_hours = 0;
            for (const insight of insights) {
                if(!insight || insight === undefined) {
                    return 0;
                }
                for (const element of insight) {
                    //const  = insight[index];
                    if (element.tags.line_uuid === line_uuid &&
                        Math.floor(Number(element.tags.shift) / 3) == Number(day_n) &&
                        working_shifts[Number(element.tags.shift)].enabled
                    ) {
                        downtime_hours += element.extra_data.duration;
                        sum_downtime_hours += element.extra_data.duration;
                    }
                }
            }

            if (downtime_hours > 0) {
                lines_for_return.push(
                    <td style={{ fontWeight: "800", backgroundColor: "lightgray" }}>
                        <center>
                            {getNumber(downtime_hours, 2) + "h"}
                        </center>
                    </td>
                )
            } else {
                lines_for_return.push(
                    <td style={{ fontWeight: "800", backgroundColor: "lightgray" }}> </td>);
            }

            lines_for_return.push(
                <td style={{ fontWeight: "800", backgroundColor: "lightgray" }}> </td>);
        }

        if (sum_downtime_hours > 0) {
            lines_for_return.push(
                <td style={{ fontWeight: "800", backgroundColor: "lightgray" }}>
                    {getNumber(sum_downtime_hours, 2) + "h" + "/" + getNumber(sum_uptime, 2) + "h" + " (" + getProducedRatio(sum_downtime_hours, sum_uptime) + ")"}
                </td>
            )
        } else {
            lines_for_return.push(<td>{""}</td>)
        }

        return lines_for_return;

    }

    renderLineMaterialDetail(
        i: number,
        l: number,
        line_title: string,
        line_uuid: string,
        material_title: string,
        material_external_id: string,
        production_matrix: ProductionMatrixObj[],
        material_norm: t.IWeeklyProducedNormRec | null,
        is_export: boolean = false,
        show_workers: boolean = true
    ) {
        if (i !== 0) {
            line_title = "";
        };
        let tr_class = "";

        if (l > 1) {
            if (i === 0) {
                tr_class = "tr-top";
            } else if (i < l) {
                tr_class = "tr-top";
            } else {
                tr_class = "tr-sum";
            }
        }
        const show_predicted = getShowPredicted(this.props.filter);
        // compute total statistics
        let total_sum = 0, total_plan = 0, total_predicted = 0, total_scrap = 0, total_downtime = 0;

        for (let production of production_matrix) {
            total_sum += production.val;
            total_plan += production.plan;
            total_predicted += production.prediction;

            const scrap_value = Number(production.scrap);
            if (scrap_value) {
                total_scrap += scrap_value;
            }

            const downtime_value = Number(production.downtime);
            if (downtime_value) {
                total_downtime += downtime_value;
            }
        }

        const total_ratio = this.props.filter.produced_scrap_downtime === "produced" ?
            getProducedRatio(total_sum, total_plan, is_export)
            : getProducedRatio(total_scrap, total_sum, is_export);

        // prepare realization table cells
        const production_cells = production_matrix.map((el, i) => {
            const tooltip = (this.props.filter.breakdown_level === "shift") ?
                `${myday(Math.floor(i / 3))}, ${(i % 3 + 1)} ${translate("common.shift_lc", "shift")}` :
                `${myday(Math.floor(i))}`

            // flag planned productions (i.e. non-zero predictions)
            const flag_class_name = (el.status === "prediction" && el.prediction > 0) ? `tr-realization-flag-1-yellow` : "";
            const status_class_name = `td-weekly-realization-${el.status}`;
            const class_name = `${flag_class_name} ${status_class_name}`;

            // get times for production details
            let val = (el.val > 0) ? getNumber(el.val, 0, is_export) : "";
            let scrap = (el.scrap > 0) ? getNumber(el.scrap, 0, is_export) : "";
            let downtime = (el.downtime > 0) ? getNumber(el.downtime, 2, is_export) + "h" : "";

            let worker = null;

            if ((el.val > 0 || el.plan > 0) && (el.status !== "prediction")) {
                worker = prettyWorkerName(el.worker).join(", ");
            }

            let start_time = 0;
            let end_time = 0;

            if (this.props.filter.breakdown_level === "shift") {
                start_time = dateFromWeekAndShift(this.props.filter.week, this.props.filter.year, i).getTime();
                end_time = dateFromWeekAndShift(this.props.filter.week, this.props.filter.year, i + 1).getTime();
            } else {
                start_time = dateFromWeekAndShift(this.props.filter.week, this.props.filter.year, i * 3).getTime();
                end_time = dateFromWeekAndShift(this.props.filter.week, this.props.filter.year, i * 3 + 3).getTime();
            }

            // check if we have insights
            let insight_render = null;
            if (el.insights.length > 0) {
                const insight_tooltips = [];
                for (const insight of el.insights) {
                    insight_tooltips.push(BusinessLogic.getInsightTooltipText(insight));
                }

                insight_render = <img src="/img/warning.svg" alt="Insights"
                    title={insight_tooltips.join("\n")}
                    className={"microplan-warning-events-image"} />
            }

            /*
                Daniel said that there should be always 2 boxes
                Box[0] == Amount of something
                Box[1] == Workers

                If there is no workers its better to see amount aligned with other amounts

            const hasWorker = worker !== "-" && worker;

            if (false && !hasWorker || !show_workers) {
                return <React.Fragment>
                    <td
                        colspan={is_export ? 1 : 2}
                        title={tooltip}
                        style={{ textAlign: "center" }}
                        className={class_name}
                        key={i}
                        onClick={() => { this.loadProductionDetails(line_title, line_uuid, material_external_id, start_time, end_time); }}>
                        {insight_render}
                        {this.props.filter.produced_scrap_downtime === "produced" ? val :
                            this.props.filter.produced_scrap_downtime === "scrap" ? scrap :
                                this.props.filter.produced_scrap_downtime === "downtime" ? downtime :
                                    ""}
                    </td>
                    {is_export ? <td></td> : null}
                </React.Fragment>
            }
            */

            // This is cell for amount | worker
            return <React.Fragment>
                <td
                    colspan={1}
                    title={tooltip}
                    style={{ textAlign: "center" }}
                    className={class_name}
                    key={i}
                    onClick={() => { this.loadProductionDetails(line_title, line_uuid, material_external_id, start_time, end_time); }}>
                    {insight_render}
                    {this.props.filter.produced_scrap_downtime === "produced" ? val :
                        this.props.filter.produced_scrap_downtime === "scrap" ? scrap :
                            this.props.filter.produced_scrap_downtime === "downtime" ? (this.props.insights && this.props.insights !== undefined && this.props.scrap_uptime_matrix) ? 
                            this.getDownTimeForCell(this.props.insights, this.props.scrap_uptime_matrix, line_uuid, material_external_id, i) : "" : ""}
                </td>
                <td
                    data-t="s"
                    title={tooltip}
                    style={{ textAlign: "center" }}
                    className={class_name}
                    key={i}
                    onClick={() => { this.loadProductionDetails(line_title, line_uuid, material_external_id, start_time, end_time); }}>
                    {this.props.filter.produced_scrap_downtime === "scrap" && Number(scrap) > 0 ? worker :
                        this.props.filter.produced_scrap_downtime === "downtime" && downtime ? worker :
                            this.props.filter.produced_scrap_downtime === "produced" ? worker : ""}
                </td>
            </React.Fragment>
        });

        // if we have day view we have one row, if we have shift view we have three rows
        if (this.props.filter.breakdown_level === "shift") {
            // break down by shift
            const production_cells_shifts = [[], [], []];
            const production_cells_shifts_sums = [];

            for (let i = 0; i < production_cells.length; i++) {
                production_cells_shifts[i % 3].push(production_cells[i]);
                if (i % 3 === 0) {
                    let sum = production_matrix[i].val + production_matrix[i + 1].val + production_matrix[i + 2].val;

                    production_cells_shifts_sums.push(
                        <React.Fragment>
                            <td className="text-center">{getNumber(sum, 0, is_export)}</td>
                            <td></td>
                        </React.Fragment>
                    );
                }
            }

            let plannedRatioEl = null;

            if (is_export) {
                plannedRatioEl = (
                    <React.Fragment>
                        <td rowSpan="4" className={`text-center`}>{getNumber(total_plan, 0, is_export)}</td>
                        <td rowSpan="4" className={`text-center`}>{getNumber(total_ratio, null, is_export)}</td>
                    </React.Fragment>
                )
            } else {
                plannedRatioEl = <React.Fragment>
                    <td rowSpan="4" className={`text-center`}>{getNumber(total_plan, 0, is_export)} ({total_ratio})</td>;
                </React.Fragment>
            }

            return (
                <React.Fragment>
                    <tr className={tr_class} key={i + "s1"}>
                        <td rowSpan="4" className={`reduce-row-width`}>{line_title}</td>
                        <td rowSpan="4" className={`reduce-row-width`}>{material_external_id}</td>
                        <td rowSpan="4" className={`reduce-row-width`}>{material_title}</td>
                        <td rowSpan="4" className={`reduce-row-width`} style={{ textAlign: "center" }}>
                            {material_norm !== null && material_norm.norm > 0 ?
                                getNumber(material_norm.norm, 0, is_export) : ""}
                        </td>
                        {plannedRatioEl}
                        <td style={{ textAlign: "center" }}>1</td>
                        {production_cells_shifts[0]}
                        <td rowSpan="4" className={`text-center`}>{getNumber(total_sum, 0, is_export)}</td>
                    </tr>
                    <tr className={tr_class} key={i + "s2"}>
                        <td style={{ textAlign: "center" }}>2</td>
                        {production_cells_shifts[1]}
                    </tr>
                    <tr className={tr_class} key={i + "s3"}>
                        <td style={{ textAlign: "center" }}>3</td>
                        {production_cells_shifts[2]}
                    </tr>
                    <tr className="materials-shift-sums">
                        <td style={{ textAlign: "center" }}>{translate("common.total", "Total")}</td>
                        {production_cells_shifts_sums}
                    </tr>
                </React.Fragment>
            )
        } else {
            let totalRatioEl = null;

            if (is_export) {
                totalRatioEl = (
                    <React.Fragment>
                        <td className={`text-center`}>
                            {getNumber(this.props.filter.produced_scrap_downtime === "scrap" ? total_scrap > 0 ? total_scrap : "" : total_sum, 0, is_export)}
                        </td>
                        <td className={`text-center`}>{getNumber(total_ratio, null, is_export)}</td>
                        {show_predicted && <td className={`text-center`}>{getNumber(total_predicted, 0, is_export)}</td>}
                    </React.Fragment>
                )
            } else {
                // "Skupaj" part
                totalRatioEl = <React.Fragment>
                    <td className={`text-center`}>
                        {getNumber(
                            this.props.filter.produced_scrap_downtime === "produced" ? total_sum :
                                this.props.filter.produced_scrap_downtime === "scrap" && total_scrap > 0 ? total_scrap :
                                    this.props.filter.produced_scrap_downtime === "downtime" && total_downtime > 0 ? "" :
                                        "", 2, false)}

                        {this.props.filter.produced_scrap_downtime === "downtime" && 
                            (this.props.scrap_uptime_matrix  && this.props.scrap_uptime_matrix !== undefined) ? this.getDowntimeVsAllTime(this.props.insights, this.props.scrap_uptime_matrix, line_uuid, material_external_id) : ""}
                        <br />
                        {show_predicted && getNumber(total_predicted, 0, is_export)}
                    </td>
                </React.Fragment>
            }

            // one row since no shifts
            return (
                <React.Fragment>
                    {
                        line_title && this.props.filter.breakdown_level === "day" && this.props.filter.produced_scrap_downtime === "downtime" &&
                        <tr className={tr_class} >
                            {line_title && (this.props.insights && this.props.insights !== undefined && this.props.scrap_uptime_matrix && this.props.scrap_uptime_matrix !== undefined) && 
                                this.sumPerDay(this.props.insights, this.props.scrap_uptime_matrix, line_uuid, line_title)}
                        </tr>
                    }

                    <tr className={tr_class} key={i}>
                        <td data-t="s" className={`reduce-row-width`}>{
                            this.props.filter.produced_scrap_downtime === "downtime" &&
                                this.props.filter.breakdown_level === "day" ?
                                " "
                                : line_title}
                        </td>
                        <td data-t="s" className={`reduce-row-width`}>{material_external_id}</td>
                        <td data-t="s" className={`reduce-row-width`}>{material_title}</td>

                        {this.props.filter.produced_scrap_downtime !== "scrap" && this.props.filter.breakdown_level !== "day" &&
                            <td className={`reduce-row-width text-center`}>
                                {material_norm !== null && material_norm.norm > 0 ?
                                    getNumber(material_norm.norm, 0, is_export) : ""}
                            </td>

                        }
                        {this.props.filter.produced_scrap_downtime !== "scrap" && this.props.filter.breakdown_level !== "day" &&
                            <td className={`text-center`}>{getNumber(total_plan, 0, is_export)}</td>
                        }
                        {production_cells}
                        {totalRatioEl}
                    </tr>
                </React.Fragment>
            )
        }
    }

    render() {
        const {
            i,
            l,
            line_title,
            line_uuid,
            material_title,
            material_external_id,
            material_production,
            material_norm,
            is_export,
            show_workers
        } = this.props;

        return this.renderLineMaterialDetail(
            i,
            l,
            line_title,
            line_uuid,
            material_title,
            material_external_id,
            material_production,
            material_norm,
            is_export,
            show_workers
        )
    }
}


type MaterialsWeekProps = {
    is_export: boolean;
    merged_data: WeeklyReport[],
    filter: MicroplanSummaryFilterProps,
    line_group_uuid: string,
    scrap_uptime_matrix?: ScrapUptimeMatrixLines
};
type MaterialsWeekState = {
    production_details_line_title: string,
    production_details_line_uuid: string,
    production_details_start: string,
    production_details_end: string,
    production_details_data: t.IProducedModel[],
    production_details_insights: t.IEventDataEx[],
    show_production_details: boolean,
    error: string,
    insights: t.IEventDataEx[][],
    start_time: Date,
    end_time: Date,
    line_uuids: Array<string>,
    scrap_uptime_matrix: ScrapUptimeMatrixLines
};

export class MaterialsWeek extends React.Component<MaterialsWeekProps, MaterialsWeekState> {

    state = {
        production_details_line_title: "",
        production_details_line_uuid: "",
        production_details_start: "",
        production_details_end: "",
        production_details_data: [],
        production_details_insights: [],
        show_production_details: false,
        error: "",
        insights: [],
        start_time: new Date(),
        end_time: new Date(),
        line_uuids: [],
        scrap_uptime_matrix: new Map<string, any>
    };

    componentDidMount() {
        this.load();
    }

    async load() {

        let start_time = getDateOfISOWeek(this.props.filter.week, this.props.filter.year)
        let end_time = getDateOfISOWeek(this.props.filter.week, this.props.filter.year);

        end_time.setDate(start_time.getDate() + 7);

        const line_group = BusinessLogic.getLineGroupForUser(this.props.line_group_uuid);
        let line_uuids = line_group ? line_group.line_uuids : [];

        this.setState({ start_time, end_time, line_uuids });

        const insights = await loadInsights(line_uuids, this.props.filter.week, this.props.filter.year);
        this.setState({ insights, scrap_uptime_matrix: this.props.scrap_uptime_matrix });
    }

    showProductionDetails = () => {
        this.setState({ show_production_details: true });
    };

    async loadProductionDetails(line_title: string, line_uuid: string, material_external_id: string, start_time: number, end_time: number) {
        try {
            // reset what we have
            this.setState({
                production_details_line_title: line_title,
                production_details_line_uuid: line_uuid,
                production_details_start: toISODateTimeString(new Date(start_time)),
                production_details_end: toISODateTimeString(new Date(end_time)),
                production_details_data: [],
                production_details_insights: []
            });
            // get new production realization data for selected time range
            const res_produced = await getBackend2().manufacturing.getProduced({
                end: end_time, start: start_time, uuid: line_uuid
            });
            const production_details_data = res_produced.data
                .filter(x => x.material_external_id === material_external_id);
            this.setState({
                production_details_data,
                show_production_details: true
            });
            // load insights to show downtimes and comments
            const insight_criteria: t.IGetInsightsReq = {
                from: start_time,
                to: end_time,
                types: [INSIGHT_TYPES.man_downtime],
                lines: [line_uuid]
            }
            const res_insights = await getBackend2().manufacturing.getInsights(insight_criteria);

            const insights_data = res_insights.insights;
            // filter out insights that start at the end of range or finish at start of range
            const insights = insights_data.filter(insight => {
                return insight.ts_to > start_time && end_time > insight.ts;
            })
            // add to the state
            this.setState({ production_details_insights: insights });

        } catch (e) {
            this.setState({ error: e.message });
        }
    };

    getDowntimeVsAllTime = (line_uuid: string, material_external_id: string = "") => {

        /** downtime / week column */
        const insights = this.state.insights;
        if (!insights) {
            return (
                <td className="text-right td-weekly-realization-confirmed">
                    {null}
                </td>
            );
        }

        let sum_uptime = 0;

        const lines = this.props.scrap_uptime_matrix;
        if (lines === null || lines === undefined) {
            return "";
        }
        const specific_line = lines.get(line_uuid);
        if (!specific_line) {
            return "";
        }
        const shifts = specific_line.get("workingShifts");
        if (!shifts) {
            return "";
        }

        if (shifts) {
            for (const shift of shifts) {
                if (shift.enabled === true) {
                    sum_uptime += 8;
                }
            }
        }

        let sum_downtime = 0;

        for (const shift of insights) {
            for (const insight of shift) {
                if (insight.tags.line_uuid == line_uuid &&
                    (insight.tags.material_external_id == material_external_id || material_external_id === "") &&
                    shifts[insight.tags.shift].enabled
                ) {
                    sum_downtime += insight.extra_data.duration
                }
            }
        }
        if (sum_downtime === 0) {
            return (
                <td className="text-right td-weekly-realization-confirmed">
                    {null}
                </td>
            );
        }

        if (!material_external_id || material_external_id === undefined) {
            return (
                <td className="text-right td-weekly-realization-confirmed" style={{ fontWeight: "800" }}>
                    {getNumber(sum_downtime, 2, false) + "h/" + sum_uptime + "h (" + getNumber((sum_downtime / sum_uptime) * 100, 2, false) + "%)"}
                </td>
            );
        }

        return (
            <td className="text-right td-weekly-realization-confirmed">
                {getNumber(sum_downtime, 2, false) + "h/" + sum_uptime + "h (" + getNumber((sum_downtime / sum_uptime) * 100, 2, false) + "%)"}
            </td>
        );
    }

    getScrapVsAll = (scrap: number, produced: number) => {
        if (scrap === 0) {
            return (
                <td className="text-right td-weekly-realization-confirmed">
                    {null}
                </td>
            );
        }

        return (
            <td className="text-right td-weekly-realization-confirmed">
                {scrap + "/" + produced + "(" + getNumber((scrap / (produced + scrap)) * 100, 2, false) + "%)"}
            </td>
        );
    }

    renderMaterialsWeek() {
        const is_export = this.props.is_export;
        // merge simulation data with SQL data
        let merged_data = this.props.merged_data;
        const show_predicted = getShowPredicted(this.props.filter);

        // aggregate production numbers to weekly level
        let week_data = []
        if (merged_data.length > 0) {
            let week_production = {}
            // reduce to one element per (line, material)
            for (const production of merged_data) {
                // check if we reached a (line, material) pair
                if ((production.line_uuid !== week_production.line_uuid) ||
                    (production.material_external_id !== week_production.material_external_id)) {
                    // remember current aggregate
                    if (week_production.line_uuid) {
                        week_data.push(week_production);
                    }
                    // check if we have line change
                    const line_change = production.line_uuid !== week_production.line_uuid;
                    // create a new one
                    week_production = {
                        line_change: line_change,
                        line_uuid: production.line_uuid,
                        line_title: production.line_title,
                        material_external_id: production.material_external_id,
                        material_title: production.material_title,
                        measurement_unit: production.measurement_unit,
                        produced: [0, 0, 0],
                        plan: 0,
                        scrap: 0,
                        downtime: 0,
                        uptime: 0
                    }
                }
                // add to aggregate
                week_production.produced[production.status ? production.status : 0] += production.sum;
                week_production.plan += production.plan;

                if (this.props.filter.produced_scrap_downtime === "scrap") {

                    if (!this.props.scrap_uptime_matrix) {
                        continue;
                    }

                    const lines = this.props.scrap_uptime_matrix;
                    if (!lines) {
                        continue;
                    }

                    const specific_line = lines.get(production.line_uuid)
                    if (!specific_line) {
                        continue;
                    }

                    const materials = specific_line.get("materials");
                    if (!materials) {
                        continue;
                    }

                    const specific_material = materials && materials.get(production.material_external_id);
                    let sum = 0;
                    if (specific_material) {
                        for (const shift of specific_material) {
                            sum += shift.scrap;
                        }
                    }
                    week_production.scrap = sum;
                }

                if (this.props.filter.produced_scrap_downtime === "downtime") {

                    if (!this.props.scrap_uptime_matrix) {
                        return "";
                    }
                    const lines = this.props.scrap_uptime_matrix;
                    if (lines === null || lines === undefined) {
                        return "";
                    }
                    const specific_line = lines.get(production.line_uuid);
                    if (!specific_line) {
                        return "";
                    }
                    const shifts = specific_line.get("workingShifts");
                    if (!shifts) {
                        return "";
                    }

                    if (this.state.insights.length > 0) {

                        console.log("this.state.insights");
                        console.log(this.props.scrap_uptime_matrix);
                        let sum = 0;
                        for (let i = 0; i < 21; i++) {
                            for (const insight of this.state.insights[i]) {


                                if (insight.tags.line_uuid === production.line_uuid &&
                                    insight.tags.material_external_id === production.material_external_id &&
                                    shifts[insight.tags.shift].enabled
                                ) {
                                    sum += insight.extra_data.duration;
                                }
                            }
                        }
                        week_production.downtime = sum;
                    }
                }
            }
            week_data.push(week_production);
        }

        // collect statistics for weekly plan vs. realization
        let weekly_done = 0, weekly_planned = 0, weekly_diff = 0;
        let weekly_scrap = 0, weekly_downtime = 0;

        // render individual material
        const mat = week_data.map((el, i) => {
            // plan deviation
            const past_produced = el.produced[0] + el.produced[1];
            const past_predicted = el.produced[2];
            const past_ratio = getProducedRatio(past_produced, el.plan, is_export);

            // count in the weekly plan vs real
            weekly_done += past_produced;
            weekly_planned += el.plan;
            weekly_diff += Math.abs(el.plan - past_produced);

            weekly_scrap += el.scrap;
            weekly_downtime += el.downtime;

            // compute production details time range
            const start_time = dateFromWeekAndShift(this.props.filter.week, this.props.filter.year, 0).getTime();
            const end_time = dateFromWeekAndShift(this.props.filter.week, this.props.filter.year, 21).getTime();

            let plannedEl = null;

            if (is_export) {
                plannedEl = (
                    <React.Fragment>
                        <td className={"text-right td-weekly-realization-planned"}>{getNumber(el.plan, 0, is_export)}</td>
                        <td className={"text-right td-weekly-realization-planned"}>{getNumber(past_ratio, null, is_export)}</td>
                        {show_predicted && <td className={`text-center`}>{getNumber(past_predicted, 0, is_export)}</td>}
                    </React.Fragment>
                );
            } else {
                plannedEl = (
                    <td className={"text-right td-weekly-realization-planned"}>
                        {getNumber(el.plan, 0)} ({past_ratio})
                        <br />
                        {show_predicted && getNumber(past_predicted, 0, is_export)}
                    </td>
                );
            }

            let diff = Math.abs(el.plan - (el.produced[0] + el.produced[1]));

            weekly_diff += diff;

            if (this.props.filter.produced_scrap_downtime === "produced") {
                return (
                    <tr key={i} onClick={() => { this.loadProductionDetails(el.line_title, el.line_uuid, el.material_external_id, start_time, end_time); }}>
                        <td data-t="s">{el.line_change ? el.line_title : ""}</td>
                        <td data-t="s">{el.material_external_id}</td>
                        <td data-t="s">{el.material_title}</td>
                        <td className="text-right td-weekly-realization-confirmed">{getNumber(el.produced[0], 0, is_export)}</td>
                        <td className="text-right td-weekly-realization-unconfirmed">{getNumber(el.produced[1], 0, is_export)}</td>
                        <td className={"text-right td-weekly-realization-planned"}>{getNumber(past_produced, 0, is_export)}</td>
                        {plannedEl}
                        <td data-t="s">{el.measurement_unit}</td>
                    <td data-t="s">{getNumber(diff, 0) + " (" + ((el.plan === 0 && diff === 0) ? "0" : getNumber((diff / el.plan) * 100, 2)) + "%)"}</td>
                    </tr>);
            } else if (this.props.filter.produced_scrap_downtime === "scrap") {
                return (
                    <tr key={i} onClick={() => { this.loadProductionDetails(el.line_title, el.line_uuid, el.material_external_id, start_time, end_time); }}>
                        <td>{el.line_change ? el.line_title : ""}</td>
                        <td>{el.material_external_id}</td>
                        <td>{el.material_title}</td>
                        <td className="text-right td-weekly-realization-confirmed">{getNumber(el.scrap, 0, is_export)}</td>
                        {this.getScrapVsAll(el.scrap, el.produced[0])}
                        <td>{el.scrap > 0 ? el.measurement_unit : null}</td>
                    </tr>);

            } else if (this.props.filter.produced_scrap_downtime === "downtime") {
                return (
                    <>
                        {el.line_change &&
                            <tr style={{ background: "lightgray"  }}>
                                <td>{el.line_change ? el.line_title : ""}</td>
                                <td></td>
                                <td></td>

                                {this.getDowntimeVsAllTime(el.line_uuid)}
                            </tr>
                        }
                        <tr key={i} onClick={() => { this.loadProductionDetails(el.line_title, el.line_uuid, el.material_external_id, start_time, end_time); }}>
                            <td></td>
                            <td>{el.material_external_id}</td>
                            <td>{el.material_title}</td>
                            {this.getDowntimeVsAllTime(el.line_uuid, el.material_external_id)}
                        </tr>
                    </>

                );
            } else {
                return null;
            }
        });
        const weekly_plan_vs_real = weekly_planned > 0 ? 100.0 * (1 - weekly_diff / weekly_planned) : 0;

        let weeklySumEl = null;

        if (is_export) {
            weeklySumEl = (
                <React.Fragment>
                    <td className="text-right">{weekly_planned}</td>
                    <td className="text-right">{weekly_plan_vs_real}%</td>
                </React.Fragment>
            );
        } else {
            weeklySumEl = (
                <td className="text-right">{getNumber(weekly_planned, 0)} ({getNumber(weekly_plan_vs_real, 2)} %)</td>
            );
        }

        const top = getStickyOffsetTop()
        const stickyStyle = { top };

        const PredictedTableHeader = <th className="text-right" style={stickyStyle}>
            <span style={{ wordBreak: "break-all", fontSize: "10px" }}>
                <br />{translate("common.predicted", "Predicted")}
            </span>
        </th>;

        return <div className="white_box charts">
            <table className="table">
                <thead>
                    <tr>
                        <th style={stickyStyle}><FormattedMessage id="common.line" defaultMessage="Line" /></th>
                        <th style={stickyStyle}>Id</th>
                        <th style={stickyStyle}><FormattedMessage id="common.material" defaultMessage="Material" /></th>

                        {this.props.filter.produced_scrap_downtime === "produced" &&
                            <th style={stickyStyle} className="text-right"><FormattedMessage id="common.confirmed_plural" defaultMessage="Confirmed" /></th>
                        }
                        {this.props.filter.produced_scrap_downtime === "scrap" &&
                            <th style={stickyStyle} className="text-right"><FormattedMessage id="common.scrap" defaultMessage="Scrap" /></th>
                        }
                        {this.props.filter.produced_scrap_downtime === "downtime" &&
                            <th style={stickyStyle} className="text-right"><FormattedMessage id="common.downtime" defaultMessage="Downtime" /></th>
                        }


                        {this.props.filter.produced_scrap_downtime === "produced" &&
                            <th style={stickyStyle} className="text-right"><FormattedMessage id="common.unconfirmed_plural" defaultMessage="Unconfirmed" /></th>
                        }
                        {this.props.filter.produced_scrap_downtime === "produced" &&
                            <th style={stickyStyle} className="text-right"><FormattedMessage id="common.total" defaultMessage="Total" /></th>
                        }


                        {this.props.filter.produced_scrap_downtime === "produced" &&
                            <th style={stickyStyle} colSpan={is_export ? "2" : "1"} className="text-right">
                                <FormattedMessage id="common.planned_plural" defaultMessage="Planned" />
                                {
                                    !is_export && show_predicted && <span style={{ wordBreak: "break-all", fontSize: "10px" }}>
                                        <br />{translate("common.predicted2", "and predicted")}
                                    </span>
                                }
                            </th>
                        }
                        {this.props.filter.produced_scrap_downtime === "scrap" &&
                            <th style={stickyStyle} colSpan={is_export ? "2" : "1"} className="text-right">
                                <FormattedMessage id="common.total" defaultMessage="Total" />
                                {
                                    !is_export && show_predicted && <span style={{ wordBreak: "break-all", fontSize: "10px" }}>
                                        <br />{translate("common.predicted2", "and predicted")}
                                    </span>
                                }
                            </th>
                        }

                        {is_export && show_predicted ? PredictedTableHeader : null}
                        {(this.props.filter.produced_scrap_downtime === "produced" ||
                            this.props.filter.produced_scrap_downtime === "scrap") &&
                            <th style={stickyStyle}><FormattedMessage id="common.unit" defaultMessage="Unit" /></th>
                        }

                        {(this.props.filter.produced_scrap_downtime === "produced") &&
                            <th style={stickyStyle}><FormattedMessage id="common.difference" defaultMessage="Difference" /></th>
                        }

                    </tr>
                </thead>
                <tbody>
                    {mat}

                    {
                        this.props.filter.produced_scrap_downtime === "produced" ?
                            <tr className="tr-sum">
                                <td colSpan={is_export ? 3 : 5} className="text-right">
                                    <FormattedMessage id="Manufacturing.MicroplanSummary.weekly_realisation" defaultMessage="Weekly realisation" />
                                </td>
                                <td colSpan={is_export ? 3 : 1} className="text-right">{getNumber(weekly_done, 0, is_export)}</td>
                                {weeklySumEl}
                                <td></td>
                                <td data-t="s">{getNumber(weekly_diff, 0) + " / " + getNumber(weekly_planned, 0) + " (" + getNumber((weekly_diff / weekly_planned) * 100, 2) + "%)"}</td>
                            </tr>
                            : this.props.filter.produced_scrap_downtime === "scrap" ?
                                <tr className="tr-sum">
                                    <td colSpan={is_export ? 3 : 3} className="text-center">
                                        <FormattedMessage id="Manufacturing.MicroplanSummary.weekly_scrap" defaultMessage="Weekly scrap" />
                                    </td>
                                    <td colSpan={is_export ? 3 : 1} className="text-right">{getNumber(weekly_scrap, 0, is_export)}</td>

                                    <td colSpan={is_export ? 3 : 1} className="text-right">
                                        {getNumber(weekly_scrap, 2) + "/" + getNumber(weekly_scrap + weekly_done, 2) + "(" + getNumber(((weekly_scrap * 100) / (weekly_scrap + weekly_done)), 2, is_export) + "%)"}
                                    </td>
                                    <td></td>
                                </tr>

                                : this.props.filter.produced_scrap_downtime === "downtime" ?
                                    <tr className="tr-sum">
                                        <td colSpan={is_export ? 3 : 3} className="text-center">
                                            <FormattedMessage id="common.total" defaultMessage="Weekly downtime" />
                                        </td>
                                        <td colSpan={is_export ? 3 : 1} className="text-right">{getNumber(weekly_downtime, 2, is_export) + "h"}</td>

                                        <td></td>
                                    </tr>
                                    : ""
                    }
                </tbody>
            </table>
        </div>;
    }

    render() {
        return this.renderMaterialsWeek();
    }
}
