// @flow

import * as React from "react";
import { Button, FormControl } from "react-bootstrap";
import Toggle from "react-toggle";
import ReactRouterPropTypes from "react-router-prop-types";

import AceEditor from "react-ace";

import { getBackend } from "../lib/Backend";
import type { DashboardSaveObj } from "../lib/Models";

import Dashboard from "./dashboard/Dashboard";
import TimeSeriesSearch from "./TimeSeriesSearch";

import ErrorComponent from "./ErrorComponent";

import "brace/mode/java";
import "brace/theme/github";

type State = {
    title: string,
    json_tags: any,
    json_data: string,
    json_layout: string,
    active: boolean,
    public: boolean,
    rendered_data: any,
    rendered_data_def: any,
    rendered_layout: any,
    rendered_error: string | null,
    error: string | null,
    message: string | null,
    uuid: string | null
};

type Props = {
    location: ReactRouterPropTypes.location,
    history: ReactRouterPropTypes.history,
    match: ReactRouterPropTypes.match
};

const empty_dash_layout = `{
    "title": "Example title",
    "no_range_selector": false,
    "type": "classic",
    "bands": [
        {
            "panels": [
                { "widgets": [{ "type": "label", "label": "Example title" }] }
            ]
        },
        {
            "panels": [
                { "widgets": [{ "type": "indicator", "indicator_type": "compact", "label": "KPI 1", "key": "kpi1", "unit": "%" }] }
            ]
        },
        {
            "panels": [
                { "widgets": [{ "type": "timeseries", "timeseries": "ts1", "height": 200, "plot_type": "line" }] }
            ]
        },
        {
            "panels": [{ "widgets": [{ "type": "alerts" }] }]
        }
    ]
}`;

const empty_dash_data = `{
    "time_range": "24h",
    "timeseries": [{ "name": "ts1", "code": "87698769876" }],
    "alerts": { "tags": { "tag1": "val1" } },
    "indicators": [
        { "key": "kpi1", "ts_code": "456345643", "warn": ">=0.7", "error": ">0.9" }
    ]
}`;

const empty_overlay_dash_layout = `{
    "title": "Example title",
    "type": "overlay",
    "background": "data:image/svg+xml;base64,<BASE64_ENCODED_SVG>",
    "indicators": [
        { "x": 550, "y": 160, "title": "Linija 1", "key": "kpi_1" },
        { "x": 550, "y": 230, "title": "Linija 2", "key": "kpi_2" },
        { "x": 550, "y": 310, "title": "Linija 3", "key": "kpi_3" },
        { "x": 550, "y": 400, "title": "Linija 4-1", "key": "kpi_4-1" },
        { "x": 450, "y": 400, "title": "Linija 4-2", "key": "kpi_4-2" },
        { "x": 420, "y": 160, "title": "Linija 5", "key": "kpi_5" },
        { "x": 420, "y": 230, "title": "Linija 6", "key": "kpi_6" },
        { "x": 420, "y": 310, "title": "Linija 7", "key": "kpi_7" },
        { "x": 370, "y": 60, "title": "Linija 8", "key": "kpi_8" },
        { "x": 280, "y": 110, "title": "Linija 9", "key": "kpi_9" },
        { "x": 280, "y": 160, "title": "Linija 10", "key": "kpi_10" },
        { "x": 280, "y": 230, "title": "Linija 11", "key": "kpi_11" },
        { "x": 280, "y": 310, "title": "Linija 12", "key": "kpi_12" },
        { "x": 280, "y": 400, "title": "Linija 13", "key": "kpi_13" },
        { "x": 200, "y": 60, "title": "Linija 14-3", "key": "kpi_14-3" },
        { "x": 150, "y": 160, "title": "Linija 15", "key": "kpi_15" },
        { "x": 150, "y": 230, "title": "Linija 16", "key": "kpi_16" },
        { "x": 150, "y": 310, "title": "Linija 17", "key": "kpi_17" },
        { "x": 10, "y": 230, "title": "Linija 20", "key": "kpi_20" },
        { "x": 10, "y": 310, "title": "Linija 21", "key": "kpi_21" },
        { "x": 800, "y": 200, "title": "Ekstruzija", "key": "kpi_extrusion" }
    ]
}`;

const empty_overlay_dash_data = `{
    "time_range": "24h",
    "timeseries": [{ "name": "ts1", "code": "87698769876" }],
    "alerts": { "tags": { "tag1": "val1" } },
    "indicators": [
        { "key": "kpi_1", "ts_code": "456345643", "warn": ">=0.7", "error": ">0.9" },
        { "key": "kpi_2", "ts_code": "456345643", "warn": ">=0.7", "error": ">0.9" },
        { "key": "kpi_3", "ts_code": "456345643", "warn": ">=0.7", "error": ">0.9" },
        { "key": "kpi_4-1", "ts_code": "456345643", "warn": ">=0.7", "error": ">0.9" },
        { "key": "kpi_4-2", "ts_code": "456345643", "warn": ">=0.7", "error": ">0.9" },
        { "key": "kpi_5", "ts_code": "456345643", "warn": ">=0.7", "error": ">0.9" },
        { "key": "kpi_6", "ts_code": "456345643", "warn": ">=0.7", "error": ">0.9" },
        { "key": "kpi_7", "ts_code": "456345643", "warn": ">=0.7", "error": ">0.9" },
        { "key": "kpi_8", "ts_code": "456345643", "warn": ">=0.7", "error": ">0.9" },
        { "key": "kpi_9", "ts_code": "456345643", "warn": ">=0.7", "error": ">0.9" },
        { "key": "kpi_10", "ts_code": "456345643", "warn": ">=0.7", "error": ">0.9" },
        { "key": "kpi_11", "ts_code": "456345643", "warn": ">=0.7", "error": ">0.9" },
        { "key": "kpi_12", "ts_code": "456345643", "warn": ">=0.7", "error": ">0.9" },
        { "key": "kpi_13", "ts_code": "456345643", "warn": ">=0.7", "error": ">0.9" },
        { "key": "kpi_14-3", "ts_code": "456345643", "warn": ">=0.7", "error": ">0.9" },
        { "key": "kpi_15", "ts_code": "456345643", "warn": ">=0.7", "error": ">0.9" },
        { "key": "kpi_16", "ts_code": "456345643", "warn": ">=0.7", "error": ">0.9" },
        { "key": "kpi_17", "ts_code": "456345643", "warn": ">=0.7", "error": ">0.9" },
        { "key": "kpi_20", "ts_code": "456345643", "warn": ">=0.7", "error": ">0.9" },
        { "key": "kpi_21", "ts_code": "456345643", "warn": ">=0.7", "error": ">0.9" },
        { "key": "kpi_extrusion", "ts_code": "456345643", "warn": ">=0.7", "error": ">0.9" }
    ]
}`;

class DashboardEdit extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            json_data: "",
            json_layout: "",
            json_tags: "",
            title: "",
            active: true,
            public: false,
            rendered_data: null,
            rendered_data_def: null,
            rendered_layout: null,
            rendered_error: null,
            error: null,
            message: null,
            uuid: null
        };
    }

    // load dashboard from the backend
    loadDashboard = () => {
        const { uuid } = this.props.match.params;
        const search = new URLSearchParams(this.props.location.search);
        if (uuid != null) {
            // load existing dashboard
            getBackend().dash.getSingleDashboard(uuid, dashboard => {
                // if caller requested a clone then forget the received uuid
                const shouldClone = (search.get("clone") === "true");
                this.setState({
                    json_layout: JSON.stringify(dashboard.layout_def, null, "    "),
                    json_data: JSON.stringify(dashboard.data_def, null, "    "),
                    json_tags: JSON.stringify(dashboard.tags, null, "    "),
                    title: dashboard.title,
                    active: dashboard.active,
                    public: dashboard.public,
                    uuid: shouldClone ? null : uuid
                });
            },
            (err) => { this.setState({ error: err }); });
        } else {
            // show default new dashboard settings
            this.setDefaultDefinition(false);
        }
    };

    setDefaultDefinition = (useClassic: boolean) => {
        // show default new dashboard settings
        this.setState({
            active: true,
            public: false,
            json_layout: useClassic ? empty_dash_layout : empty_overlay_dash_layout,
            json_data: useClassic ? empty_dash_data : empty_overlay_dash_data,
            title: "New dashboard"
        });
    }

    componentDidMount = () => {
        this.loadDashboard();
    }

    runPreview = () => {
        let json_layout = null;
        let json_data = null;
        try {
            json_layout = JSON.parse(this.state.json_layout);
        } catch (error) {
            this.setState({ rendered_error: "Error in layout JSON:\n" + error.message });
            return;
        }
        try {
            json_data = JSON.parse(this.state.json_data);
        } catch (error) {
            this.setState({ rendered_error: "Error in data JSON:\n" + error.message });
            return;
        }
        this.setState({ rendered_error: null });
        getBackend().dash.executeDashboardDef(
            json_layout, json_data, {},
            (res) => {
                this.setState({ rendered_data: res.data, rendered_layout: res.layout });
            },
            (err) => { this.setState({ rendered_error: err }); }
        );
    }

    cancel = () => {
        window.open('/admin/dashboards', '_self');
    }

    save = () => {
        this.setState({ error: null });
        let layout_def = null;
        try {
            layout_def = JSON.parse(this.state.json_layout);
        } catch (e) {
            this.setState({ error: "Layout: " + e.message });
            return;
        }
        let data_def = null;
        try {
            data_def = JSON.parse(this.state.json_data);
        } catch (e) {
            this.setState({ error: "Data: " + e.message });
            return;
        }
        let tags_def = null;
        try {
            tags_def = JSON.parse(this.state.json_tags);
        } catch (e) {
            this.setState({ error: "Tags: " + e.message });
            return;
        }

        const data: DashboardSaveObj = {
            title: this.state.title,
            layout_def: layout_def,
            data_def: data_def,
            active: this.state.active,
            public: this.state.public,
            tags: tags_def
        };

        if (this.state.uuid != null) {
            getBackend().dash.updateDashboard(this.state.uuid || "", data,
                () => {
                    this.setState({ message: "Dashboard saved." });
                },
                (err) => { this.setState({ error: err }); });
        } else {
            getBackend().dash.insertDashboard(data,
                (uuid) => {
                    // redirect to this page, but in edit mode
                    window.location = window.location + "/" + uuid;
                },
                (err) => { this.setState({ error: err }); });
        }
    }

    handleTitleChange = (event: Event) => {
        if (event.currentTarget instanceof HTMLInputElement) {
            const target = event.currentTarget;
            const value = target.value;
            this.setState({ title: value });
        }
    };
    handleToggleActive = (event: Event) => {
        const target = event.currentTarget;
        this.setState({
            //$FlowFixMe
            active: (target.checked === true)
        });
    };
    handleTogglePublic = (event: Event) => {
        const target = event.currentTarget;
        this.setState({
            //$FlowFixMe
            public: (target.checked === true)
        });
    };

    renderHeader = () => {
        return (
            <div className="row">
                <div className="col-lg-12 col-md-12">
                    <div className="white_box charts">
                        <div>
                            Uuid: {this.state.uuid}
                        </div>
                        <div className="row">
                            <div className="col-lg-10 col-md-10">
                                Title
                                <FormControl
                                    onChange={(ev => { this.handleTitleChange(ev); })}
                                    value={this.state.title}
                                    className="form-control" type="text"
                                    disabled={false}
                                    placeholder="Enter dashboard title" />
                            </div>
                            <div className="col-lg-1 col-md-1">
                                Active
                                <Toggle checked={this.state.active} onChange={this.handleToggleActive} />
                            </div>
                            <div className="col-lg-1 col-md-1">
                                Public
                                <Toggle checked={this.state.public} onChange={this.handleTogglePublic} />
                            </div>
                        </div>
                        <div>
                            <Button className="btn_save" onClick={() => { this.save(); }}>Save</Button>
                            <Button className="btn_cancel btn_margin_left" onClick={() => { this.cancel(); }}>Cancel</Button>
                            <div className="dropdown manage_drop filter_drop">
                            <a className="dropdown-toggle" id="dropdown_manage" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <img src="/img/manage.png" alt="manage" /> Tools
                            </a>
                            <div className="dropdown-menu" aria-labelledby="dropdown_manage">
                                <div className="buttons">
                                    <a onClick={event => { this.setDefaultDefinition(true); }}>Set layout - classic</a>
                                </div>
                                <div className="buttons">
                                    <a onClick={event => { this.setDefaultDefinition(false); }}>Set layout - overlay</a>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>);
    }

    updateLayout = (code: string) => {
        this.setState({ json_layout: code });
    }
    updateData = (code: string) => {
        this.setState({ json_data: code });
    }
    updateTags = (code: string) => {
        this.setState({ json_tags: code });
    }

    renderEditors = () => {
        return (
            <React.Fragment>
                <div className="row width100p space30">
                    <div className="col-md-8">
                        <h3>Layout definition</h3>
                        <div>
                            <AceEditor mode="json" theme="github"
                                onChange={(code) => { this.updateLayout(code); }}
                                name="UNIQUE_ID_OF_DIV" editorProps={{ $blockScrolling: true }}
                                value={this.state.json_layout}
                                width={"100%"} height={"500px"}
                            />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <h3>Tags</h3>
                        <div>
                            <AceEditor mode="json" theme="github"
                                onChange={(code) => { this.updateTags(code); }}
                                name="UNIQUE_ID_OF_DIV" editorProps={{ $blockScrolling: true }}
                                value={this.state.json_tags}
                                width={"100%"} height={"500px"}
                            />
                        </div>
                    </div>
                </div>
                <div className="row width100p space30">
                    <div className="col-md-8">
                        <h3>Data definition</h3>
                        <div>
                            <AceEditor mode="json" theme="github"
                                    onChange={(code) => { this.updateData(code); }}
                                    name="UNIQUE_ID_OF_DIV2"
                                    editorProps={{ $blockScrolling: true }}
                                    value={this.state.json_data}
                                    width={"100%"} height={"500px"}
                            />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <h3>Tools</h3>
                        <TimeSeriesSearch height={"500px"} />
                    </div>
                </div>
            </React.Fragment>
        );
    }

    renderPreview = () => {
        if (this.state.rendered_error) {
            return (<ErrorComponent msg={this.state.rendered_error} type="error" />);
        }
        if (this.state.rendered_data){
            return (
                <div className="charts space30">
                    <Dashboard
                        dash_data={this.state.rendered_data}
                        dash_definition={this.state.rendered_layout}
                        range_change={null}
                        show_filter_bar={true}
                        history={this.props.history}
                    />
                </div>);
            }
            return null;
        }
    renderError = () => {
        if (!this.state.error) {
            return null;
        }
        return (<ErrorComponent msg={this.state.error} type="error" />);
    }
    renderMessage = () => {
        if (!this.state.message) {
            return null;
        }
        return (<ErrorComponent msg={this.state.message} type="info" />);
    }

    render() {
        return (
            <div className="margin-side-15px">
                {this.renderHeader()}
                {this.renderError()}
                {this.renderMessage()}
                {this.renderEditors()}
                <div>
                    <Button className="btn_save" type="submit" onClick={() => { this.runPreview(); }}>Preview</Button>
                </div>
                {this.renderPreview()}
            </div>
        );
    }
};

export default DashboardEdit;
