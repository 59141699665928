// @flow
import { Modal } from "react-bootstrap";

// $FlowFixMe
import React, { useState, useEffect } from "react";
import ReactTable from "../react/ReactTable";
import { getBackend } from "../../lib/backend/Backend2";
import { translate } from "../IntlProviderWrapper";
import Loader from "../Loader";
import { niceDate, niceNumber } from "../../lib/Util";
import { useSelector, useDispatch } from "react-redux";
import type { SubcontractingModalState } from "../redux/types";


const ordersColumns = [
    {
        "Header": translate("common.material", "Material"),
        "accessor": "material_title",
        disableFilters: true
    },
    {
        "Header": translate("common.material_external_id", "Ident"),
        "accessor": "material_external_id",
        disableFilters: true
    },
    {
        "Header": translate("common.quantity", "Quantity"),
        "accessor": "quantity_total",
        disableFilters: true
    },
    {
        "Header": translate("common.date", "Date"),
        "accessor": "time_start",
        disableFilters: true
    }
]

const producedColumns = [
    {
        "Header": translate("Manufacturing.StocksView.material_title", "Material"),
        "accessor": "material_title",
        disableFilters: true
    },
    {
        "Header": translate("common.material_external_id", "Ident"),
        "accessor": "material_external_id",
        disableFilters: true
    },
    {
        "Header": translate("common.quantity", "Quantity"),
        "accessor": "quantity_produced",
        disableFilters: true
    },
    {
        "Header": translate("common.delivery_document", "Delivery document"),
        "accessor": "delivery_document_id",
        disableFilters: true
    },
    {
        "Header": translate("common.date", "Date"),
        "accessor": "shift_start_time",
        disableFilters: true
    }
];


const fetchData = async (external_operation_id: string) => {
    return await getBackend().manufacturing.getSubcontractingDetails({ external_operation_id: external_operation_id });
}

const mapOrdersData = (order: any) => {
    return {
        ...order,
        quantity_total: niceNumber(order.quantity_total, 0),
        time_start: niceDate(new Date(order.time_start)),
        time_end: niceDate(new Date(order.time_end))
    }
}

const mapProducedData = (produced: any) => {
    return {
        ...produced,
        quantity_produced: niceNumber(produced.quantity_produced, 0),
        shift_start_time: niceDate(new Date(produced.shift_start_time)),
        shift_end_time: niceDate(new Date(produced.shift_end_time))
    }
}

const initialState = {
    orders: [],
    produced: [],
    parents: [],
    loading: false
}

const SubcontractingModal = (props: any) => {
    const [state, setState] = useState(initialState);
    const subcontracting_modal = useSelector((state): SubcontractingModalState => state.subcontracting_modal);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!subcontracting_modal.show) return;

        setState({...initialState, loading: true });

        if (subcontracting_modal.external_operation_id) {
            fetchData(subcontracting_modal.external_operation_id)
                .then((res) => {
                    const orders = res.orders
                        .filter(el => !el.external_id.includes("_parent"))
                        .sort((a, b) => a.time_start < b.time_start ? 1 : -1);

                    const produced = res.produced.sort((a, b) => a.shift_start_time < b.shift_start_time ? 1 : -1);

                    setState({
                        orders: orders,
                        produced: produced,
                        parents: res.orders.filter(el => el.external_id.includes("_parent")),
                        loading: false
                    });
                })
                .catch(() => setState({ ...state, loading: false }))
        }

    }, [subcontracting_modal.external_operation_id]);

    const onModalClose = () => dispatch({type: "reset_subcontracting_modal"});

    return <Modal
        show={subcontracting_modal.show}
        className={"subcontracting-details"}
    >
        <Modal.Header>
            <Modal.Title>
                {translate("common.purchase_order", "Purchase order")}: {subcontracting_modal.external_operation_id}
                <br/>
                {translate("common.total_sum", "Total sum")}: {subcontracting_modal.quantity_total}
            </Modal.Title>
            <button type="button" className="close" onClick={onModalClose}>
                <span aria-hidden="true">×</span>
                <span className="sr-only">
                    {translate("common.close", "Close")}
                </span>
            </button>
        </Modal.Header>
        <Modal.Body>
            {
                state.loading ?
                <Loader /> :
                <React.Fragment>
                    <div>
                        <h5>{translate("common.sent_subcontractor", "Sent to subcontractor")}</h5>
                        <br />
                        <ReactTable
                            data={state.orders.map(mapOrdersData)}
                            columns={ordersColumns}
                        />
                        <br />
                    </div>
                    <div style={{ marginTop: "34px"}}>
                        <h5>{translate("common.received_subcontractor", "Received from subcontractor")}</h5>
                        <br/>
                        <ReactTable
                            data={state.produced.map(mapProducedData)}
                            columns={producedColumns}
                        />
                    </div>
                </React.Fragment>
            }
        </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-primary" onClick={onModalClose}>
                {translate("common.close", "Close")}
            </button>
        </Modal.Footer>
    </Modal>
}

export default SubcontractingModal;
