// @flow

import React from "react";

// $FlowFixMe
import { ReactComponent as ArrowCircleLeftIcon } from "@fortawesome/fontawesome-free/svgs/solid/arrow-circle-left.svg";
import SvgIcon, { type SvgIconProps } from "../SvgIcon";

const ArrowCircleLeft = (props: SvgIconProps) => (
    <SvgIcon {...props} size={props.size || "fs"}>
        <ArrowCircleLeftIcon />
    </SvgIcon>
);

export default ArrowCircleLeft;
