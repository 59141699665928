// @flow
import * as React from "react";
import { connect } from "react-redux";
import * as t from "../../../lib/backend/manufacturing2.generated.types";
import { InsightsDescriptionDiffs } from "./lineOrderList/Order"
import { ReportLogic, INSIGHT_DIFF_TYPE, LinesOrdersLogic, ReduxFilters } from "./reducers/BusinessLogic";
import OrderInsightsDiff from "./OrderInsightsDiff";
import Loader from "../../Loader";
import { translate } from "../../IntlProviderWrapper";
import { intersect } from "../../../lib/Util";
import { getBackend } from "../../../lib/backend/Backend2";
import ErrorComponent from "../../ErrorComponent";

import type { SetRescheduleOrderAction, SetRescheduleOrderBundleAction } from "./reducers/common";
import type { SetShowValidLinesAction, SetHighlightedOrders } from "./reducers/planningTable";
import type { LineOrders, SetUnscheduledOrdersAction } from "./reducers/linesOrders";
import type { ReduxState } from "./reducers/reducers";
import type { InsightDiff } from "./reducers/BusinessLogic";
import type { UpdatePlanningTableHeightAction } from "./reducers/properties";
import type { ResetFiltersAction } from "./reducers/planningTable";
import type { SetLastClickedOrderUuid } from "./reducers/planningTable";

type Props = {
    // own
    original_report_uuid: string,
    current_report_uuid: string,
    updateInsightsStats: (solved: number, unsolved: number) => void,
    // redux
    insights: t.IEventDataEx[],
    original_insights: t.IEventDataEx[],
    lines_orders: LineOrders[],
    line_group_uuid: string,
    unscheduled_orders: LineOrders[],
    is_report_loading: boolean,
    is_filter_locked: boolean,
    selected_order_uuid: string,
    reduxDispatch: (args: SetUnscheduledOrdersAction | SetRescheduleOrderAction |
        SetShowValidLinesAction | UpdatePlanningTableHeightAction |
        SetHighlightedOrders | ResetFiltersAction | SetLastClickedOrderUuid |
        SetRescheduleOrderBundleAction
    ) => void
}

type State = {
    insights_diff_map: Map<string, InsightDiff[]>, // Map<order_uuid, insight_diff>,
    show_modal_order_external_id: string | null,
    loading: boolean,
    title: string
}


export const insightsMap = (original_insights: ?t.IEventDataEx[], new_insights: ?t.IEventDataEx[]): ?Map<string, InsightDiff[]> => {
    if (!original_insights || !new_insights) {
        return;
    }

    const insights_diff = ReportLogic.insightsDiff(original_insights, new_insights);
    const insights_diff_map = new Map<string, InsightDiff[]>();

    for (const insight_diff of insights_diff) {
        const insight = insight_diff.current_insight || insight_diff.original_insight;
        if (!insight || insight_diff.diff_type === INSIGHT_DIFF_TYPE.same) {
            continue;
        }

        if (insight.tags.line_uuid || insight.tags.line_uuids) {
            const line_uuids = insight.tags.line_uuid ? [insight.tags.line_uuid] : insight.tags.line_uuids.split(",");
            for (const line_uuid of line_uuids) {
                const existing_insights_diff = insights_diff_map.get(line_uuid) || [];
                insights_diff_map.set(line_uuid, [...existing_insights_diff, insight_diff]);
            }
        }
    }

    return insights_diff_map;
}

export const filterInsightsDiffMap = (
    insights_diff_map: ?Map<string, Array<InsightDiff>>,
    order_uuids: Set<string>,
    allowed_material_external_ids: Set<string>
): Map<string, InsightDiff[]> => {
    const filtered_insights_diff_map = new Map<string, InsightDiff[]>();
    if (!insights_diff_map) return new Map();

    for (const [line_uuid, insight_diffs] of insights_diff_map.entries()) {
        const filtered_insight_diffs = [];
        for (const insight_diff of insight_diffs) {
            const insight = insight_diff.current_insight || insight_diff.original_insight;
            if (insight) {
                const material_external_ids = insight.extra_data.materials ? insight.extra_data.materials.map(m => m.material_external_id) : [];
                const has_material_in_materials = (
                    material_external_ids.length > 0 &&
                    intersect(material_external_ids, [...allowed_material_external_ids]).length > 0
                );
                const has_material = allowed_material_external_ids.has(insight.extra_data.material_external_id);

                if (!has_material_in_materials && !has_material) {
                    const insight_order_uuids = ReportLogic.getOrderUuidsFromInsight(insight);
                    const order_uuid = ReportLogic.getOrderUuidFromInsight(insight);
                    const has_order = (
                        (insight_order_uuids && intersect(insight_order_uuids, [...order_uuids]).length > 0) ||
                        (order_uuids.has(order_uuid))
                    );

                    if (!has_order) {
                        continue;
                    }
                }
            }
            filtered_insight_diffs.push(insight_diff);
        }

        if (filtered_insight_diffs.length > 0) {
            filtered_insights_diff_map.set(line_uuid, filtered_insight_diffs);
        }
    }

    return filtered_insights_diff_map;
}

class LeftMenuInsightsDiff extends React.Component<Props, State> {

    state = {
        insights_diff_map: new Map<string, InsightDiff[]>(),
        loading: false,
        show_modal_order_external_id: null,
        title: ""
    }

    componentDidMount() {
        this.setState({ insights_diff_map: new Map() });
        this.load();
    }

    async componentDidUpdate(prev_props: Props, prev_state: State) {
        if (prev_props.is_report_loading === false && this.props.is_report_loading) {
            this.setState({
                insights_diff_map: new Map(),
                loading: true,
                show_modal_order_external_id: null
            });
        }

        if (prev_props.current_report_uuid !== this.props.current_report_uuid) {
            // every time report changes, insights have also changed
            await this.load();
        }

        if (prev_state.insights_diff_map !== this.state.insights_diff_map) {
            this.updateInsightStats()
        }
    }

    updateInsightStats() {
        let solved = 0;
        let unsolved = 0;

        for (const [line_uuid, insights_diffs] of this.state.insights_diff_map.entries()) {
            const line_group = LinesOrdersLogic.getLineGroupForLine(line_uuid, this.props.line_group_uuid)
            if (!line_group) {
                continue;
            }
            for (const insight_diff of insights_diffs) {
                if (insight_diff.diff_type === "solved") {
                    solved++;
                }
                if (insight_diff.diff_type === "new") {
                    unsolved++;
                }
            }
        }
        this.props.updateInsightsStats(solved, unsolved);
    }

    getReportChanged = () => {
        return (
            this.props.original_report_uuid && this.props.current_report_uuid &&
            this.props.original_report_uuid !== this.props.current_report_uuid
        );
    }

    prepareFilterData = async (): Promise<void> => {
        const insights_diff_map = insightsMap(
            this.props.original_insights,
            this.props.insights
        );
        const order_uuids = new Set();
        const allowed_material_external_ids = new Set();

        for (const line of this.props.lines_orders) {
            for (const order of line.orders) {
                if (!order.failed_scheduling) {
                    allowed_material_external_ids.add(order.material_external_id);
                    order_uuids.add(order.uuid);
                }
            }
        }

        for (const line of this.props.unscheduled_orders) {
            for (const order of line.orders) {
                if (!order.failed_scheduling) {
                    allowed_material_external_ids.add(order.material_external_id);
                    order_uuids.add(order.uuid);
                }
            }
        }

        const res = await getBackend().manufacturing.getInputMaterials({
            order_uuids: [...order_uuids]
        });

        for (const input_material of res.input_materials) {
            allowed_material_external_ids.add(input_material.input_material_external_id);
        }

        const filtered_insights_diff_map = filterInsightsDiffMap(
            insights_diff_map,
            order_uuids,
            allowed_material_external_ids
        );

        if (filtered_insights_diff_map) {
            this.setState({ insights_diff_map: filtered_insights_diff_map })
        }
    }

    async load() {
        if (!this.props.lines_orders ||
            this.props.lines_orders.length === 0 ||
            !this.props.insights ||
            !this.props.insights.length === 0) return;

        const report_changed = this.getReportChanged();
        if (!report_changed) {
            this.setState({ loading: false });
            return;
        }

        this.setState({
            loading: false,
            insights_diff_map: new Map<string, InsightDiff[]>()
        });

        this.prepareFilterData();
        this.setState({ loading: false })
    }

    onClickInfo = (show_modal_order_external_id: string) => (e: Event) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ show_modal_order_external_id });
    }

    ifOrderExists = (order_uuid: string): boolean => {
        const orders = this.props.lines_orders.concat(this.props.unscheduled_orders);
        const index = LinesOrdersLogic.findOrderIndexLinesOrders(orders, order_uuid);
        if (index === -1) {
            return false;
        }
        return true;
    }

    onClickOrder = (order_uuid: string, line_uuids: string[]) => async (e: Event) => {
        // check if we actually have this order
        if (!this.ifOrderExists(order_uuid)) {
            this.setState({
                title: translate("common.order_cannot_be_seen", "Order cannot be seen")
            });
            return;
        }
        // we do, remove the title and select the order
        this.setState({ title: "" });
        await ReduxFilters.setRescheduleOrderUuid(this.props.reduxDispatch,
            order_uuid, "line_order_list", this.props.is_filter_locked);
    }

    renderInsightsDiffs = (order_insights: InsightDiff[]) => {
        const insights = new Map<string, t.IEventDataEx[]>();

        for (const order_insight of order_insights) {
            const current_insight = order_insight.current_insight || order_insight.original_insight;
            if (current_insight && order_insight.diff_type !== INSIGHT_DIFF_TYPE.same) {
                const existing_insights = insights.get(order_insight.diff_type) || [];
                insights.set(order_insight.diff_type, [...existing_insights, current_insight]);
            }
        }

        return <InsightsDescriptionDiffs
            title={this.state.title}
            insights={insights}
            onClickInfo={this.onClickInfo}
            onClickOrder={this.onClickOrder}
            selected_order_uuid={this.props.selected_order_uuid}
        />
    }


    renderLineGroup = () => {
        if (!this.state.insights_diff_map || this.state.insights_diff_map.size === 0) {
            return [];
        }

        const elements = new Map<string, InsightDiff[]>(); // line_group_uuid

        for (const [line_uuid, insights] of this.state.insights_diff_map.entries()) {
            const line_group = LinesOrdersLogic.getLineGroupForLine(line_uuid, this.props.line_group_uuid);
            if (!line_group) {
                console.log("Could not find line group for line: ", line_uuid);
                continue;
            }
            const existing_insights = elements.get(line_group.title) || [];
            elements.set(line_group.title, [...existing_insights, ...insights])
        }
        const ret_elements = [];
        const line_group_titles = [...elements.keys()].sort();
        for (const line_group_title of line_group_titles) {
            const insights = elements.get(line_group_title);
            if (!insights) continue;
            ret_elements.push(
                <div className="planning-table__row" key={line_group_title}>
                    <div className="planning-table__row-title">
                        {line_group_title}
                    </div>
                    <hr style={{marginTop: "unset", marginBottom: "unset"}} />
                    <div>
                        {this.renderInsightsDiffs(insights)}
                    </div>
                </div>
            );
        }

        return ret_elements;
    }

    getOrderUuids = (): string[] => {
        const order_uuids = [];
        for (const [, insights_diffs] of this.state.insights_diff_map.entries()) {
            for (const insight_diff of insights_diffs) {
                const insight = insight_diff.current_insight || insight_diff.original_insight;
                if (insight) {
                    const order_uuid = ReportLogic.getOrderUuidFromInsight(insight);
                    order_uuids.push(order_uuid);
                }
            }
        }
        return order_uuids;
    }

    getOrderExternalIds = (): string[] => {
        const order_external_ids = [];
        for (const [, insights_diffs] of this.state.insights_diff_map.entries()) {
            for (const insight_diff of insights_diffs) {
                const insight = insight_diff.current_insight || insight_diff.original_insight;
                if (insight) {
                    order_external_ids.push(insight.tags.order_external_id);
                }
            }
        }
        return order_external_ids;
    }

    onCloseModal = () => {
        this.setState({
            show_modal_order_external_id: null
        });
    }

    renderNoData = () => {
        return <div style={{marginLeft: "15px", marginRight: "15px"}}>
            <ErrorComponent msg={translate("common.no_relevant_insights", "No relevant issues found")} type="no-data" />
        </div>
    }

    render() {
        if (this.props.original_report_uuid === this.props.current_report_uuid) {
            return this.renderNoData();
        }

        if (this.state.loading) {
            return <Loader />
        }

        const lines_insights = this.renderLineGroup();
        if (lines_insights.length === 0) {
            return this.renderNoData();
        }

        return <React.Fragment>
            {lines_insights}
            {/* MODAL DIALOG */}
            <OrderInsightsDiff
                show_order_external_id={this.state.show_modal_order_external_id}
                onCloseModal={this.onCloseModal}
                filter_order_uuids={this.getOrderUuids()}
            />
        </React.Fragment>;
    }
}


export default connect(
    (state: ReduxState) => {
        const insights_state = state.gantt_chart_insights;
        const lines_orders = state.gantt_chart_lines_orders.lines_orders;
        const unscheduled_orders = state.gantt_chart_lines_orders.unscheduled_orders;
        const is_report_loading = state.gantt_chart_report.report_loading;
        const line_group_uuid = state.gantt_chart_properties.line_group_uuid;
        const is_filter_locked = state.gantt_chart_filters.is_filter_locked;
        const selected_order_uuid = state.gantt_chart_planning_table.last_clicked_order_uuid;

        return {
            insights: insights_state.insights,
            original_insights: insights_state.original_insights,
            lines_orders,
            line_group_uuid,
            unscheduled_orders,
            is_report_loading,
            is_filter_locked,
            selected_order_uuid
        }
    },
    (dispatch) => ({reduxDispatch: dispatch, reduxDispatchHighlight: dispatch})
)(LeftMenuInsightsDiff);
