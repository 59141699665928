// @flow
import * as React from "react";
import { connect } from "react-redux";
import Toolbar from "./Toolbar";
import { UPDATE_PLANNING_TABLE_WIDTH } from "./reducers/properties";
import { GanttChartSource, zoomGanttChart, getCustomDayWidth } from "./reducers/properties";
import { SET_IS_CTRL_DOWN } from "./reducers/planningTable";
import { PLANNING_TABLE_DISPLAY_TYPES } from "./DisplayTypesMenu";
import { DraggableLogic, PropertiesLogic, ResizeLeftMenu } from "./reducers/BusinessLogic";
import { AutomaticScroll } from "./AutomaticScroll";

import type { ReduxState } from "./reducers/reducers";
import type { UpdatePlanningTableWidthAction } from "./reducers/properties";
import type { SetIsCtrlDown } from "./reducers/planningTable";
import type { GanttChartSourceTypes } from "./reducers/properties";

type Props = {
    show_planning_table_filter: boolean,
    width: number | null,
    selected_display_type: $Keys<typeof PLANNING_TABLE_DISPLAY_TYPES>,
    reduxDispatch: (args: UpdatePlanningTableWidthAction | SetIsCtrlDown) => void,
    children: React.Node,
    source_type: GanttChartSourceTypes,
    is_ctrl_down: boolean,
    height: number,
    min_day_width: number,
    bar_height: number,
    disable_zoom: boolean
};

type State = {};


class Controls extends React.Component<Props, State> {

    key_down_event_listener = null;
    key_up_event_listener = null;
    wheel_event_listener = null;
    scroll_event_listener = null;

    min_width = null;
    max_width = null;
    controls_ref = null;
    scrollDiff = 0;

    constructor(props) {
        super(props);
        this.controls_ref = React.createRef();
    }

    componentDidMount() {
        if (PropertiesLogic.isPlanningTable(this.props.source_type)) { return; }
        // we cannot use react onKeyDown beacuse this requires the div to be in focus (tabIndex)
        if (!this.key_down_event_listener) {
            this.key_down_event_listener = (e) => this.onKeyDown(e);
        }
        window.addEventListener("keydown", this.key_down_event_listener);

        if (!this.key_up_event_listener) {
            this.key_up_event_listener = (e) => this.onKeyUp(e);
        }
        window.addEventListener("keyup", this.key_up_event_listener);

        if (!this.wheel_event_listener) {
            this.wheel_event_listener = (e) => this.onWheel(e);
        }
        window.addEventListener("wheel", this.wheel_event_listener, { passive: false });

        this.addScrollEventListener();

        if (this.controls_ref && this.controls_ref.current) {
            this.min_width = this.controls_ref.current.getBoundingClientRect().width;
            this.max_width = this.min_width * 10;
        }

        this.setScrollDiff();
    }

    componentDidUpdate(prev_props: Props) {
        if (prev_props.show_planning_table_filter !== this.props.show_planning_table_filter &&
            this.controls_ref && this.controls_ref.current) {
            this.min_width = this.controls_ref.current.getBoundingClientRect().width;
            this.max_width = this.min_width * 10;
            this.onReset();
        }

        if (prev_props.source_type !== this.props.source_type) {
            this.addScrollEventListener();
        }

        if (prev_props.height !== this.props.height) {
            const el = DraggableLogic.querySelector(this.props.source_type, "#gantt-chart-container")
            if (el) {
                el.dispatchEvent(new Event('scroll'));
            }
        }

        this.setScrollDiff();
    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.key_down_event_listener);
        window.removeEventListener("keyup", this.key_up_event_listener);
        window.removeEventListener("wheel", this.wheel_event_listener);
        if (this.scroll_event_listener) {
            window.removeEventListener("scroll", this.scroll_event_listener)
        }
    }

    addScrollEventListener = () => {
        if (!PropertiesLogic.isPlanningTable(this.props.source_type) && !this.scroll_event_listener) {
            // on planning table scroll is done inside main div in render (onScroll)
            // on gantt chart the scroll is done on the window
            if (!this.scroll_event_listener) {
                this.scroll_event_listener = (e) => this.onScroll(e);
            }

            window.addEventListener("scroll", this.scroll_event_listener, { passive: false });
        }
    }

    setScrollDiff = () => {
        const source_type = this.props.source_type;
        const el1 = DraggableLogic.querySelector(source_type, "#gantt-chart-container");
        const el2 = (
            document.querySelector(".planning-table-app-menu") ||
            document.querySelector(".views-filter-bar") ||
            document.querySelector(".navigation-bar")
        );

        if (el1 && el2) {
            const rect1 = el1.getBoundingClientRect();
            const rect2 = el2.getBoundingClientRect();
            this.scrollDiff = (rect1.top) - (rect2.top + rect2.height) + window.scrollY;
        }
    }

    onKeyUp = (e: SyntheticKeyboardEvent<HTMLDivElement>) => {
        if (this.props.is_ctrl_down) {
            this.props.reduxDispatch({ type: SET_IS_CTRL_DOWN, data: false });
        }
    }

    onKeyDown = (e: SyntheticKeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Meta" || e.key === "Control") {
            if (!this.props.is_ctrl_down) {
                this.props.reduxDispatch({ type: SET_IS_CTRL_DOWN, data: true });
            }
            e.preventDefault();
            e.stopPropagation();
        }
    }

    onMouseLeave = () => {
        if (this.props.is_ctrl_down) {
            this.props.reduxDispatch({ type: SET_IS_CTRL_DOWN, data: false });
        }
    }

    zoom_gantt_timeout = null;

    onWheel = (e: SyntheticWheelEvent<HTMLDivElement>) => {
        if (this.props.disable_zoom) {
            return false;
        }

        if (!this.props.is_ctrl_down || PropertiesLogic.isPlanningTable(this.props.source_type)) {
            e.stopPropagation();
            return null;
        }

        e.stopPropagation();
        e.preventDefault();

        const ZOOM_STEP = this.props.min_day_width / 5;
        const width = this.props.width;
        const custom_day_width = getCustomDayWidth(this.props.source_type);

        if (e.deltaY > 0) {
            if (this.min_width && width && (width - ZOOM_STEP) > this.min_width) {
                if (this.zoom_gantt_timeout) {
                    clearTimeout(this.zoom_gantt_timeout)
                }
                this.zoom_gantt_timeout = setTimeout(() => {
                    zoomGanttChart(
                        custom_day_width - ZOOM_STEP,
                        this.props.source_type,
                        this.props.reduxDispatch
                    );
                }, 200);
            }
        } else {
            if (this.min_width !== null && width && (width + ZOOM_STEP) < this.max_width) {
                if (this.zoom_gantt_timeout) {
                    clearTimeout(this.zoom_gantt_timeout);
                }
                this.zoom_gantt_timeout = setTimeout(() => {
                    zoomGanttChart(
                        custom_day_width + ZOOM_STEP,
                        this.props.source_type,
                        this.props.reduxDispatch
                    );
                }, 200);
            }
        }
    }

    onScroll = (event: SyntheticEvent<any>) => {
        let source_type = this.props.source_type;
        if (source_type === GanttChartSource.microplan) {
            return;
        }

        let header_shifts_group = DraggableLogic.querySelector(source_type, "#grid-groups-header-shifts");
        let line_labels_group = DraggableLogic.querySelector(source_type, "#line-labels-group");
        let toolbar_icon = DraggableLogic.querySelector(source_type, "#toolbar-button-container");

        if (this.props.is_ctrl_down) {
            event.stopPropagation();
            event.preventDefault();
            return null;
        }
        if (!event.currentTarget) return;

        const correction = 0;
        const scroll_top = event.currentTarget.scrollTop !== undefined ? event.currentTarget.scrollTop : (event.currentTarget.scrollY - correction);

        let y = scroll_top - this.scrollDiff;
        if (y < 0) y = 0;

        const translate_y = `translate(0, ${y})`;

        if (header_shifts_group) {
            header_shifts_group.setAttribute("transform", translate_y)
        }

        const scroll_x = event.currentTarget.scrollLeft !== undefined ? event.currentTarget.scrollLeft : event.currentTarget.scrollX;
        let x = scroll_x - 10;

        if (x < 0) x = 0;

        const translate_x = `translate(${x}, 0)`;

        if (line_labels_group) {
            line_labels_group.setAttribute("transform", translate_x);
        }

        if (toolbar_icon && !PropertiesLogic.isPlanningTable(this.props.source_type)) {
            toolbar_icon.style.setProperty("transform", `translate(${x}px, ${y}px)`);
        }
    }

    onReset = () => {
        if (this.min_width !== null) {
            this.props.reduxDispatch({
                type: UPDATE_PLANNING_TABLE_WIDTH,
                data: this.min_width
            });
        }
    }

    dynamic_props = {};

    getDynamicProps = () => {
        if (!this.dynamic_props.onScroll) {
            this.dynamic_props.onScroll = this.onScroll;
        }
        return this.dynamic_props;
    }

    getControlsContainerStyle = () => ({
        top: "unset",
        left: this.props.show_planning_table_filter ? ResizeLeftMenu.getWidth() : 0,
        width: this.props.show_planning_table_filter ? `calc(100% - ${ResizeLeftMenu.getWidth()}px)` : '100%',
        height: PropertiesLogic.isPlanningTable(this.props.source_type) ? "calc(100vh - 169px)" : "unset"
    });

    getGanttChartContainerStyle = () => {
        const style = {
            left: this.props.show_planning_table_filter ? ResizeLeftMenu.getWidth() : 0,
            paddingTop: PropertiesLogic.isPlanningTable(this.props.source_type) ? 0 : 15,
            position: undefined,
            width: undefined
        }

        if (this.props.source_type === GanttChartSource.tool_changes) {
            style.paddingTop = 0;
        }
        if (this.props.source_type === GanttChartSource.line_planning) {
            style.position = "unset";
        }

        return style;
    }

    getShowToolbar = () => {
        return (
            !this.props.disable_zoom && (
                this.props.selected_display_type === PLANNING_TABLE_DISPLAY_TYPES.gantt ||
                this.props.source_type === GanttChartSource.gantt ||
                this.props.source_type == GanttChartSource.microplan ||
                this.props.source_type == GanttChartSource.line_planning
            )
        );
    }

    getSourceType = () => {
        return this.props.source_type;
    }

    render() {
        const classes = PropertiesLogic.isPlanningTable(this.props.source_type) ? "" : "overflow-hidden";
        return (
            <div
                onMouseLeave={this.onMouseLeave}
                id={this.getSourceType()}
                className={`position-relative ${classes}`}
                style={this.getControlsContainerStyle()}
            >
                <AutomaticScroll bar_height={this.props.bar_height} />
                {this.getShowToolbar() && <Toolbar />}
                <div
                    id="gantt-chart-container"
                    className="planning-table-main2"
                    ref={this.controls_ref}
                    style={this.getGanttChartContainerStyle()}
                    {...this.getDynamicProps()}
                >
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default connect(
    (state: ReduxState) => {
        const is_ctrl_down = state.gantt_chart_planning_table.is_ctrl_down;
        const properties = state.gantt_chart_properties;

        return {
            width: properties.width,
            height: properties.height,
            source_type: properties.source_type,
            min_day_width: properties.min_day_width,
            bar_height: properties.bar_height,
            disable_zoom: properties.disable_zoom,
            is_ctrl_down,
        }
    },
    (dispatch) => ({ reduxDispatch: dispatch })
)(Controls)
