// @flow
import * as React from "react";
import ReactRouterPropTypes from "react-router-prop-types";
import { getLang } from "../../IntlProviderWrapper";
import { getBackend as getBackend2 } from "../../../lib/backend/Backend2";
import { shiftNumber } from "../../../lib/Util";
import * as t from "../../../lib/backend/manufacturing2.generated.types";
import * as BusinessLogic from "../../../lib/BusinessLogic";
import { LINE_TAGS } from "../../../lib/ManufacturingTags.generated";
import { SET_TRANSLATIONS_MATERIALS } from "../../redux/reducers/translationsMaterialsReducer";
import { store } from "../../../index";
import Loader from "../../Loader";
import LinePlanning from "./LinePlanning";

import type { ILineData } from "../../../lib/backend/manufacturing2.generated.types";

type Props = {
    history: ReactRouterPropTypes.history,
    plant_uuid: string,
    line_group_uuid: string
};

type LinesPlanningData = {
    [key: string]: t.ILinePlanningData
}

type State = {
    error: string,
    // display parameters
    lines: Array<ILineData>,
    lines_all: Array<ILineData>,
    week: number,
    year: number,
    shift: number,
    // number of loaded lines
    line_load_count: number,
    lines_planning_data: LinesPlanningData,
    loading: boolean
};

class OrdersAndShifts extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        // current week
        const current_date = new Date();
        const shift_number = shiftNumber(current_date);
        const current_year = shift_number.year;
        const current_week = shift_number.week;
        const current_shift = shift_number.shift;

        // initialize state
        const state: State = {
            error: "",
            lines: [],
            lines_all: [],
            week: current_week,
            year: current_year,
            shift: current_shift,
            line_load_count: 0,
            lines_planning_data: {},
            loading: false
        };
        this.state = state;
    }

    lines_loaded = {};

    async componentDidMount() {
        try {
            const res = await getBackend2().manufacturing.getLines({});
            const lines_all = res.lines
                .filter(line => line.tags && line.tags[LINE_TAGS.skip_planning] !== "true");
            this.setState(
                { lines_all },
                () => {
                    this.refreshLines();
                }
            );
        } catch (error) {
            console.log(error);
            this.setState({ error: error.message });
        }
    }

    componentDidUpdate(prev_props: Props) {
        if (prev_props.plant_uuid !== this.props.plant_uuid ||
            prev_props.line_group_uuid !== this.props.line_group_uuid) {
            this.refreshLines(true);
        }
    }

    refreshLines = async (hard_reset: boolean = false) => {
        this.lines_loaded = {};
        if (hard_reset) {
            this.setState({ lines: [] });
        }
        const line_group = BusinessLogic.getLineGroupForUser(this.props.line_group_uuid);
        const line_uuids = line_group ? line_group.line_uuids : [];
        const lines = this.state.lines_all
            .filter(x => line_uuids.some(uuid => x.uuid === uuid))
            .filter(line => line.tags && line.tags[LINE_TAGS.skip_planning] !== "true");

        const res_planning_data = await getBackend2().manufacturing.getPlanningData({
            calculate_hash: true,
            line_uuids: lines.map(l => l.uuid),
            week_from: this.state.week,
            year: this.state.year,
            week_count: 4,
            language_code: getLang()
        });
        if (res_planning_data.translations) {
            store.dispatch({
                type: SET_TRANSLATIONS_MATERIALS,
                data: res_planning_data.translations.materials
            });
        }
        const lines_planning_data = {};

        for (const line_planning_data of res_planning_data.lines) {
            lines_planning_data[line_planning_data.line_uuid] = line_planning_data;
        }

        this.setState({
            lines: lines,
            line_load_count: 0,
            lines_planning_data
        });
    }

    lineLoaded = (line_uuid: string) => {
        this.lines_loaded[line_uuid] = true;

        if (Object.keys(this.lines_loaded).length === this.state.lines.length) {
            this.setState({ line_load_count: Object.keys(this.lines_loaded).length });
        }
    }

    async save() {
        this.setState({ loading: true });

        // first check if we can actually save or if something has changed
        let lines_not_nok = 0;
        let save_tasks: Array<Promise<void>> = this.state.lines
            .map(async (line, i) => {
                const ok = await this.refs["child_" + i].save();
                if (!ok) { lines_not_nok++; }
            });

        await Promise.all(save_tasks);
        // check if all lines returned success
        if (lines_not_nok > 0) {
            // inform the user
            this.setState({ error: "Data for some lines could not be saved." });
            return;
        }
        // reload the page
        await this.refreshLines();
        this.setState({ line_load_count: 0, loading: false });
    }

    renderLines() {
        // still loading
        if (!this.state.lines || this.state.lines.length === 0) { return <Loader />; }

        // prepare order => insight map for showing insights next to orders
        let lines_components = this.state.lines
            .map((line, i) => {
                return [
                    <div key={`a${i}`} className={i > 0 ? "planning-table-line-title" : ""}><h5>{line.title}</h5></div>,
                    <div className="nav-tabs-parent bettween-lines-border" key={"line" + i + line.uuid}>
                        {this.state.loading && <Loader small={true} />}
                        <LinePlanning
                            history={this.props.history}
                            line_title={line.title}
                            line_uuid={line.uuid}
                            ref={"child_" + i}
                            onLoad={this.lineLoaded}
                            week={this.state.week}
                            year={this.state.year}
                            shift={this.state.shift}
                            compact={false}
                            shift_edit_weeks={2}
                            shift_edit_weeks_collapsed={0}
                            insights_map={new Map()}
                            line_planning_data={this.state.lines_planning_data[line.uuid]}
                        />
                    </div>
                ];
            });

        return <ul className="nav nav-tabs">
            {lines_components}
        </ul>;
    }

    render() {
        if (this.state && this.state.lines) {
            return (
                <article className="article">
                    <section className="planning-table">
                        <div className="planning-table-simple">
                            {this.renderLines()}
                        </div>
                    </section>
                </article>
            );
        } else {
            return <Loader />;
        }
    };
}

export default OrdersAndShifts;
