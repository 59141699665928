// @flow

export function mapStatusToStyle(status_string: string, prefix?: string): string {
    let res = prefix || "dsh_indicator_style_";
    switch (status_string) {
        case "ok": res += 3; break;
        case "normal": res += 3; break;
        case "": res += 3; break;
        case "warn": res += 6; break;
        case "warning": res += 6; break;
        case "info": res += 2; break;
        case "error": res += 5; break;
        case "danger": res += 5; break;
        case "inactive": res += 7; break;
        default: res += status_string; break;
    }
    return res;
}