// @flow
import React from "react";
import { Modal, Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

import { getBackend } from "../../lib/backend/Backend2";
import { waitForJob } from "../../lib/BusinessLogic";
import { publish, MSG_TYPES } from "../../lib/PubSub";
import { PLANNING_META_OPTIMIZATION } from "../../lib/ManufacturingTags.generated";
import { LINE_GROUP_TAGS, LINE_GROUP_TAGS_ACCESS } from "../../lib/ManufacturingTags.generated";

import { translate } from "../IntlProviderWrapper";

type Props = {
    linegroup_uuid: string
};

type State = {
    planning_error: string,
    planning_in_progress: boolean,
    planning_show_error: boolean
};

class OrdersShiftsRunPlanningButton extends React.Component<Props, State> {

    state: State = {
        planning_in_progress: false,
        planning_error: "",
        planning_show_error: false
    };

    componentDidUpdate(prevProps: Props) {
        if (prevProps.linegroup_uuid !== this.props.linegroup_uuid) {
            this.setState({ planning_in_progress: false });
        }
    }

    handleRefresh = async () => {
        await publish(MSG_TYPES.reload_shift_orders, {});
    }

    handleExport = async () => {
        await publish(MSG_TYPES.export_people_planning_table, {});
    }

    handleRunPlanning = async (number_of_fixed_shifts: number) => {
        this.setState({ planning_in_progress: true });
        try {
            // get line-group tags, which can contain custom parameters for planning
            const { line_groups } = (await getBackend().manufacturing.getLineGroups({}));
            const lg = line_groups.filter(x => x.uuid === this.props.linegroup_uuid)[0];
            const lg_tags = ((lg !== undefined) ? lg.tags : {});
            const beam_size = 3; // old code - should go through planning board
            const break_orders = (lg_tags[LINE_GROUP_TAGS.planning_break_orders] || "false") === "true";
            const ramp_up_factor = parseFloat(lg_tags[LINE_GROUP_TAGS.planning_ramp_up_factor] || "2.0");
            const setup_code_change_factor = parseFloat(lg_tags[LINE_GROUP_TAGS.planning_setup_code_change_factor] || "2.0");
            const smooth_needs = (lg_tags[LINE_GROUP_TAGS.planning_smooth_needs] || "true") === "true";
            const split_order_min_hours = parseInt(lg_tags[LINE_GROUP_TAGS.planning_split_order_min_hours] || "8");
            const time_horizont_shifts = LINE_GROUP_TAGS_ACCESS.planning_time_horizont_shifts(lg_tags);
            const track_input_material = (lg_tags[LINE_GROUP_TAGS.planning_track_input_material] || "false") === "true";

            const res_planning = await getBackend().manufacturing.runLinegroupPlanningComplete({
                criteria: {
                    delays: 1,
                    intermediate_stock: 0,
                    tool_changes: 0
                },
                linegroup_uuid: this.props.linegroup_uuid,
                params: {
                    beam_size,
                    break_orders,
                    meta_optimization: PLANNING_META_OPTIMIZATION.none,
                    number_of_fixed_shifts,
                    ramp_up_factor,
                    setup_code_change_factor,
                    smooth_needs,
                    split_order_min_hours,
                    time_horizont_shifts,
                    track_input_material
                },
            });
            try {
                const token = res_planning.job_token;
                await waitForJob(getBackend(), token);
                await publish(MSG_TYPES.reload_shift_orders, {});
            } catch (err) {
                this.setState({ planning_show_error: true, planning_error: err.message });
            }
        } finally {
            this.setState({ planning_in_progress: false });
        }
    }

    render() {
        return (
            <React.Fragment>
                {!this.state.planning_in_progress && <button
                    type="button"
                    className={"btn btn-icon btn-icon-magic btn-primary"}
                    style={{ marginLeft: "10px" }}
                    title={translate("Manufacturing.OrdersShifts.magic_wand_title_fixed", "Run planning with fixed current shift")}
                    onClick={() => this.handleRunPlanning(0 /* 1 */)}
                />}
                {false && !this.state.planning_in_progress && <button
                    type="button"
                    className={"btn btn-icon btn-icon-magic btn-secondary"}
                    style={{ marginLeft: "10px" }}
                    title={translate("Manufacturing.OrdersShifts.magic_wand_title_no_fixed", "Run planning, including current shift")}
                    onClick={() => this.handleRunPlanning(0)}
                />}
                {this.state.planning_in_progress && <div className="spinner">
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                </div>
                }
                <button
                    type="button"
                    className={"btn btn-icon btn-icon-refresh btn-primary"}
                    style={{ marginLeft: "10px" }}
                    onClick={() => this.handleRefresh()}
                    title={translate("common.refresh", "Refresh")}
                />
                <button
                    type="button"
                    className={"btn btn-primary"}
                    style={{ marginLeft: "10px" }}
                    onClick={() => this.handleExport()}
                >
                    {translate("common.export_xlsx", "Export to XLSX")}
                </button>
                {this.state.planning_show_error && <Modal.Dialog>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <FormattedMessage id="Manufacturing.OrdersShifts.planning_error_title" defaultMessage="Planning error" />
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p><FormattedMessage id="Manufacturing.OrdersShifts.planning_error_intro" defaultMessage="An error occured during planning" />:</p>
                        <p>{this.state.planning_error}</p>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.setState({ planning_show_error: false })}>
                        <FormattedMessage id="common.close" defaultMessage="Close" />
                        </Button>
                    </Modal.Footer>
                </Modal.Dialog>
                }
            </React.Fragment>
        )
    }
}

export default OrdersShiftsRunPlanningButton;
