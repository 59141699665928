// @flow

// main imports
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import ReactRouterPropTypes from "react-router-prop-types";

import { toSloDateTimeString, toSloDateString, stringCompare } from "../../lib/Util";
import { translate } from "../IntlProviderWrapper";

// backend
import * as B2 from "../../lib/backend/Backend2";
import * as t from "../../lib/backend/batch_operations.generated.types";
import * as Auth from "../../lib/Auth";

import ErrorComponent from "../ErrorComponent";
import { EditButton } from './BatchOperationsArchiveItemModal';
import { BATCHOP_GROUP_TAGS } from "../../lib/ManufacturingTags.generated";
import Loader from "../Loader";


type Props = {
    match: ReactRouterPropTypes.match,
    line_group_uuid?: string,
    showHeader: boolean,
    filter_order_id?: string,
    filter_start_date?: Date,
    filter_end_date?: Date,
    filter_material_ident?: string
}

type IPagination = {
    offset: number,
    limit: number
}

type State = {
    group: t.IBatchOperationGroup | null,
    groups: t.IBatchOperationGroup[],
    operations: t.IBatchOperationArchiveInfo[],
    error: string,
    loading: boolean,
    count: number | null,
    pagination: IPagination,
    loading_more: boolean
}


class BatchOperationsArchive extends Component<Props, State> {

    page_count = 200;

    constructor(props: Props) {
        super(props);

        this.state = {
            group: null,
            groups: [],
            operations: [],
            filter_order_id: "",
            filter_start_date: "",
            filter_end_date: "",
            filter_material_ident: "",
            error: "",
            loading: true,
            newModalDataProps: {
                show: false,
                group_id: props.match.params.group_uuid,
                item_id: -1, // item_id is gathered when user clicks edit button
                user_id: null,
                ts_delivered: null,
                person_id: null
            },
            pagination: {
                limit: this.page_count,
                offset: 0
            },
            count: null,
            loading_more: false
        };

    }

    componentDidMount() {
        this.loadComponent();
    }

    componentDidUpdate(prev_props: Props) {
        const has_filters_changed = (
            prev_props.filter_order_id !== this.props.filter_order_id ||
            prev_props.filter_start_date !== this.props.filter_start_date ||
            prev_props.filter_end_date !== this.props.filter_end_date ||
            prev_props.filter_material_ident !== this.props.filter_material_ident
        );

        if (has_filters_changed) {
            const pagination = this.state.pagination;
            pagination.offset = 0;
            this.setState({ pagination: { ...pagination } });
        }

        if (prev_props.filter_start_date !== this.props.filter_start_date ||
            prev_props.filter_end_date !== this.props.filter_end_date ||
            prev_props.filter_material_ident !== this.props.filter_material_ident) {
            this.loadComponent();
        }
    }

    async loadComponent() {
        try {
            const allowed_lines_groups = Auth.getUserLineGroups();
            const api = B2.getBackend().batchOperations;
            const res = await api.getGroups({});
            let groups = res.items
                .filter(x => allowed_lines_groups.indexOf(x.tags[BATCHOP_GROUP_TAGS.line_group]) >= 0);
            // filter down
            if (this.props.match && this.props.match.params.group_uuid) {
                groups = groups.filter(x => x.uuid === this.props.match.params.group_uuid);
            } else if (this.props.line_group_uuid) {
                groups = groups.filter(x => x.tags && x.tags[BATCHOP_GROUP_TAGS.line_group] === this.props.line_group_uuid);
            } else {
                this.setState({ loading: false })
                return;
            }
            // show
            if (groups.length === 0) {
                this.setState({ error: "No location defined or allowed for current user.", loading: false });
                return;
            }
            const group = groups[0];
            this.setState({ groups, group }, async () => {
                await this.refreshStatus();
            });
        } catch (err) {
            this.setState({ error: err.message, loading: false });
        }
    }

    async refreshStatus(next_page: boolean = false) {
        if (!this.state.loading_more) {
            this.setState({ loading: true });
        }

        const group = this.state.group;
        if (group !== null) {
            const api = B2.getBackend().batchOperations;
            const req = {
                group_id: group.uuid,
                ts_start: undefined,
                ts_end: undefined,
                material_ident: undefined
            };

            if (this.props.filter_material_ident) {
                req.material_ident = this.props.filter_material_ident;
            }

            if (this.props.filter_start_date) {
                req.ts_start = this.props.filter_start_date.getTime();
            }

            if (this.props.filter_end_date) {
                req.ts_end = this.props.filter_end_date.getTime();
            }

            const req_archive = {
                ...req,
                limit: this.state.pagination.limit,
                offset: this.state.pagination.offset
            }

            const res_count = await api.getArchiveCount(req);
            const res = await api.getArchive(req_archive);

            let operations = res.items;

            if (next_page) {
                operations = [...this.state.operations, ...operations];
            }

            // sort descending
            this.setState({
                operations: operations.sort((a, b) => b.start_time - a.start_time),
                loading: false,
                loading_more: false,
                count: parseInt(res_count.count, 10)
            });
        }
    }

    loadMore = () => {
        this.setState({
            pagination: {
                offset: this.state.pagination.offset + this.page_count,
                limit: this.page_count
            },
            loading_more: true
        }, () => this.refreshStatus(true))
    }

    renderDetailTable(operation: t.IBatchOperationArchiveInfo) {

        const orders = operation.orders.sort((a, b) => stringCompare(a.order_num, b.order_num));
        return (
            <table className="table">
                <thead>
                    <tr>
                        <th>
                            <FormattedMessage id="Manufacturing.BatchOp.WorkOrder" defaultMessage="Work Order" />
                        </th>
                        <th>
                            <FormattedMessage id="Manufacturing.BatchOp.Ident" defaultMessage="Ident" />
                        </th>
                        <th>
                            <FormattedMessage id="Manufacturing.BatchOp.Title" defaultMessage="Title" />
                        </th>
                        <th>
                            <FormattedMessage id="Manufacturing.BatchOp.Quantity" defaultMessage="Quantity" />
                        </th>
                        <th>
                            <FormattedMessage id="Manufacturing.BatchOp.Package" defaultMessage="Pallet" />
                        </th>
                        <th>
                            <FormattedMessage id="Manufacturing.BatchOp.Comment" defaultMessage="Comment" />
                        </th>
                        <th>
                            <FormattedMessage id="Manufacturing.BatchOperationsArchiveItemModal.person_id" defaultMessage="Person ID" />
                        </th>
                        <th>
                            <FormattedMessage id="Manufacturing.BatchOperationsArchiveItemModal.date_delivered" defaultMessage="Date Delivered" />
                        </th>
                        <th>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {orders.map((order, i) => (
                        <tr key={i}>
                            <td>{order.order_num}</td>
                            <td>{order.name}</td>
                            <td>{order.material_title}</td>
                            <td>{order.quantity}</td>
                            <td>{order.batch}</td>
                            <td>{order.comment}</td>
                            <td>{order.person_id}</td>
                            <td>{order.ts_delivered ? toSloDateString(new Date(order.ts_delivered)) : null}</td>
                            <td>
                                {this.props.line_group_uuid && <EditButton
                                    order={order}
                                    line_group_uuid={this.props.line_group_uuid}
                                    reload={() => this.loadComponent()}
                                />}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    }


    isNotFiltered(o: t.IBatchOperationArchiveInfo) {
        let not_filtered = true;

        // filter by order_num
        if (this.props.filter_order_id && this.props.filter_order_id.length > 0) {
            let searches = o.orders.map((order) => {
                const haystack = order.order_num.toString();
                // $FlowFixMe -- flow has problems with the check in the if above
                if (haystack.indexOf(this.props.filter_order_id) !== -1) {
                    return true;
                }
                return false;
            });
            const found = searches.indexOf(true) !== -1;
            if (!found) {
                not_filtered = false;
            }
        }

        const o_start_time = new Date(o.start_time);
        const o_end_time = new Date(o.end_time);

        let filter_start_time = this.props.filter_start_date ? this.props.filter_start_date : new Date(0);
        let filter_end_time = this.props.filter_end_date ? this.props.filter_end_date : new Date(2099, 11, 31);

        if (!(
            ((filter_start_time < o_start_time) && (filter_end_time > o_start_time)) ||
            ((filter_start_time < o_end_time) && (filter_end_time > o_end_time))
        )) {
            not_filtered = false;
        }

        return not_filtered;
    };

    renderBatchopHistoryTable() {
        return (
            <React.Fragment>
                <table className="table dt-responsive nowrap">
                    <thead>
                        <tr>
                            <th>Lokacija</th>
                            <th><FormattedMessage id="common.start_noun" defaultMessage="Start" /> </th>
                            <th><FormattedMessage id="common.end" defaultMessage="End" /></th>
                            <th>Trajanje [h]</th>
                            <th>Program</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.operations.map((operation: t.IBatchOperationArchiveInfo) => {
                            if (!this.isNotFiltered(operation)) return null;
                            return [
                                <tr key={operation.uuid}>
                                    <td>{operation.location_title}</td>
                                    <td>{toSloDateTimeString(new Date(operation.start_time))}</td>
                                    <td>{toSloDateTimeString(new Date(operation.end_time))}</td>
                                    <td>{operation.duration}</td>
                                    <td>{operation.recipe}</td>
                                </tr>,
                                <tr key={operation.uuid + "_2"}>
                                    <td> &nbsp;</td>
                                    <td className="card-body" colSpan="5">
                                        {this.renderDetailTable(operation)}
                                    </td>
                                </tr>
                            ];
                        })}
                    </tbody>
                </table>
            </React.Fragment>
        );
    }

    renderLoadMoreButton() {
        if (this.state.operations.length === this.state.count) return null;

        return <div style={{ textAlign: "center", marginTop: "10px", marginBottom: "5px" }}>
            {<button className="btn btn-primary" onClick={this.loadMore}>
                {this.state.loading_more ? translate("common.loading", "Loading...") : translate("common.load_more", "Load more")}
            </button>}
        </div>
    }

    render() {
        if (this.state.loading) {
            return <Loader />;
        }

        if (this.state.operations.length === 0) {
            return <article className="article">
                <section id="statistics" className="data_sources">
                    <div className="container-fluid">
                        <ErrorComponent msg={translate("common.no_data", "No data")} type="no-data" />
                    </div>
                </section>
            </article>
        }

        return (
            <article className="article">
                <section id="statistics" className="data_sources">
                    {this.props.showHeader && <div className="filter_bar">
                        <div className="container-fluid">
                            <div className="row align-items-center mx-n2">
                                <div className="col-sm flex-grow-0 flex-basis-auto w-sm-auto px-2 mr-sm-auto">
                                    <h2>
                                        {this.state.group && this.state.group.name}
                                    </h2>
                                </div>
                                <div className="col-sm flex-grow-0 flex-basis-auto w-sm-auto px-2">
                                    <Link className="blue_link" to="/kiosk/batch_operations">
                                        &#171; <FormattedMessage id="common.back" defaultMessage="Back" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>}
                    <div className="vertical_tab">
                        <div className="container-fluid">
                            {this.props.showHeader && <div className="row">
                                <div className="col-lg-12 col-md-12">
                                    <br /><br /><br /><br />
                                </div>
                            </div>}

                            <ErrorComponent type="error" msg={this.state.error} />

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="white_box charts">
                                        <div className="chart-wrapper">
                                            {this.renderBatchopHistoryTable()}
                                            {this.renderLoadMoreButton()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </article>
        )
    }
}

export default BatchOperationsArchive;
