// @flow
import * as React from "react";
import ReactTable from "../../ReactTable";

type Props = {};
type State = {};

const columns = [
    {
        accessor: "component",
        Header: "",
        filterInputPlaceholder: "Component name",
        disableFilters: false
    },
    {
        accessor: "ts",
        Header: "Timestamp",
        disableFilters: true
    },
    {
        accessor: "prop_key",
        Header: "",
        filterInputPlaceholder: "Prop key",
        disableFilters: false
    },
    {
        accessor: "render_time",
        Header: "Render time (seconds)",
        disableFilters: true
    }
];

type RendersViewRow = {
    ts: string,
    original_ts: number,
    prop_key: string,
    component: React.Node,
    render_time: number
}

export default class NumRendersView extends React.Component<Props, State> {

    getUpdatePerformance(): Map<string, any> {
        if (window.componentdidupdate_data) {
            return window.componentdidupdate_data;
        }
        return new Map();
    }

    getRenderPerformance(): Map<string, any> {
        if (window.render_performance_data) {
            return window.render_performance_data;
        }
        return new Map();
    }

    getTableData = (): any => {
        let data = [];
        let render_time_sum = 0;
        for (const [component, updates] of this.getUpdatePerformance().entries()) {
            const render_data = this.getRenderPerformance().get(component);
            let idx = 0;
            for (const prop_entries of updates) {
                for (const prop_entry of prop_entries) {
                    const { ts, prop_key } = prop_entry;
    
                    let render_time = 0;
                    if (render_data) {
                        const render_ts = render_data[idx];
                        if (render_ts) {
                            render_time = (ts - render_ts);
                        }
                    }
                    data.push({
                        original_ts: ts,
                        ts: new Date(ts).toLocaleString(),
                        prop_key,
                        component,
                        render_time: (render_time / 1000)
                    });
                    render_time_sum += render_time / 1000;
                }
                idx++;
            }
        }

        data.sort((a: RendersViewRow, b: RendersViewRow) => a.original_ts - b.original_ts);

        let diff_sec = 0;
        if (data.length > 1) {
            diff_sec = (data[data.length - 1].original_ts - data[0].original_ts) / 1000;
        }
        localStorage.setItem("renders-view-last-measurement", diff_sec.toString());
        return {
            data,
            diff_sec: render_time_sum
        };
    }

    render() {
        const {
            data,
            diff_sec
        } = this.getTableData();
        return <div className="num-renders-view">
            <div>Render time in seconds: {diff_sec}s</div>
            <ReactTable
                data={data}
                columns={columns}
            />
        </div>;
    }
}