// @flow
//import React from "react";
import * as React from "react";
import { withRouter } from 'react-router'
import ReactRouterPropTypes from "react-router-prop-types";
import { translate } from "./IntlProviderWrapper";

type Props = {
    children: any,
    history: ReactRouterPropTypes.history,
    onSave: Function,
    onBack: Function,
    title: string | React.Node
}

type State = {};

class EntityEditTemplate extends React.Component<Props, State> {
    render() {
        return (
            <div key="ds_new">
                <section key="1">
                    <img src="/img/banner.png" alt="banner" className="img-fluid-header banner_img" />
                </section>
                <section id="profile_edit" className="new_line" key="2">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="white_box">
                                    <div className="container" style={{maxWidth: "700px"}}>
                                        <div style={{ fontSize: "20px", marginBottom: "15px" }}><span>{this.props.title}</span></div>
                                        {this.props.children}
                                        <div className="button-list order-last ml-auto">
                                            {this.props.onSave && <button className="btn btn-outline-primary" onClick={this.props.onSave}>
                                                {translate("common.save", "Save")}
                                            </button>}
                                            {
                                                this.props.onBack &&
                                                <button className="btn btn-outline-secondary" onClick={this.props.onBack}>
                                                    {translate("common.back", "Back")}
                                                </button>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="space30"></div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default withRouter(EntityEditTemplate);
