// @flow
import React from "react";

import { fromShiftTag, niceNumber, shiftNumber } from "../../lib/Util";
import { translate } from "../IntlProviderWrapper";
import * as t from "../../lib/backend/manufacturing2.generated.types";

function renderStock(stock: number, mark_approx_zero: boolean = false) {
    if (stock === 0) {
        return "0";
    }

    const stock_output = niceNumber(stock, 0);
    if (!mark_approx_zero) {
        return stock_output;
    }

    const rounded_stock = Math.round(stock);
    const is_approx_zero = rounded_stock === 0 && stock !== 0;
    return is_approx_zero ? "≈ 0" : stock_output;
}

function renderStockTitle(stock: number) {
    return stock !== 0 ? niceNumber(stock, 7) : "";
}

class Mapper {
    data_map = new Map();
    current_stock = 0;

    constructor(data) {
        for (const item of data) {
            this.data_map.set(item.subtype, item);
        }

        const produced_by_shift = this.data_map.get("produced_by_shift");
        if (produced_by_shift !== undefined) {
            this.current_stock = produced_by_shift.current_stock || 0;
        }
    }

    getData = (subtype: string, absolute = false) => {
        const forecast_data = this.data_map.get(subtype);
        if (forecast_data === undefined || forecast_data.forecasts.length === 0) {
            return 0;
        }

        const stock = forecast_data.forecasts.reduce((a, b) => a + b.stock, 0);
        return absolute ? Math.abs(stock) : stock;
    };
}

type Props = {
    cell_data: Object,
    forecast: t.IGetMaterialForecastForShiftsRes | null,
    prev_value?: number
};

const MaterialStockForecastTooltip = (props: Props) => {
    const { cell_data, forecast, prev_value } = props;

    if (forecast === null) {
        return <div>{translate("common.loading", "Loading...")}</div>;
    }

    if (forecast.items.length === 0 || cell_data.selected_subtype !== "combined") {
        return null;
    }

    const mapper = new Mapper(forecast.items);

    let current_stock = 0;
    if (prev_value) {
        current_stock = prev_value;
    } else {
        current_stock = mapper.current_stock;
    }

    let produced = mapper.getData("produced_by_shift");
    let consumed = mapper.getData("consumed_by_shift");
    let received = mapper.getData("received_by_shift", true);
    let required = mapper.getData("required_by_shift");

    let result = current_stock + produced + consumed + received + required;

    if (cell_data.selected_location === "shopfloor") {
        result = current_stock + produced + consumed;
    }

    const produced_text = renderStock(produced);
    const consumed_text = renderStock(-consumed);
    const received_text = renderStock(received);
    const required_text = renderStock(Math.abs(required));

    let formula = `(${translate("common.prev_cumulative", "PrevCumulative")})` +
        ` + (${translate("common.produced", "Produced")})` +
        ` - (${translate("common.consumed", "Consumed")})`;
    let calculation = `${renderStock(current_stock)}` +
        ` + ${produced_text}` +
        ` - ${consumed !== 0 && (-consumed) < 0 ? `(${consumed_text})` : consumed_text}`;

    // we do not show required/received for shopfloor
    if (cell_data.selected_location !== "shopfloor") {
        formula += ` + (${translate("common.received", "Received")})` +
            ` - (${translate("common.required", "Required")})`;
        calculation += ` + ${received_text}` +
            ` - ${required_text}`;
    }

    return (
        <div className="msf-tooltip mx-n2">
            <p className="material-title font-weight-500 px-2 pb-2 mb-0">
                {cell_data.material_title} ({cell_data.material_external_id})
            </p>
            <table className="summary m-2">
                <tbody>
                    <tr>
                        <th className="font-weight-500">{translate("common.produced", "Produced")}</th>
                        <td className="pl-2">{produced_text}</td>
                    </tr>
                    <tr>
                        <th className="font-weight-500">{translate("common.consumed", "Consumed")}</th>
                        <td className="pl-2">{consumed_text}</td>
                    </tr>
                    {cell_data.selected_location !== "shopfloor" && (
                        <React.Fragment>
                            <tr>
                                <th className="font-weight-500">{translate("common.received", "Received")}</th>
                                <td className="pl-2">{received_text}</td>
                            </tr>
                            <tr>
                                <th className="font-weight-500">{translate("common.required", "Required")}</th>
                                <td className="pl-2">{required_text}</td>
                            </tr>
                        </React.Fragment>
                    )}
                    <tr>
                        <th className="font-weight-500">{translate("common.formula", "Formula")}</th>
                        <td className="pl-2">
                            {formula}
                        </td>
                    </tr>
                    <tr>
                        <th className="font-weight-500">{translate("common.calculated", "Calculated")}</th>
                        <td className="pl-2">
                            {calculation} = {renderStock(result)}
                        </td>
                    </tr>
                </tbody>
            </table>
            {forecast.lines_orders.length > 0 && (
                <div className="lines-orders">
                    {forecast.lines_orders.map((line_orders, i) => {
                        let produced = 0;
                        let consumed = 0;
                        return (
                            <React.Fragment key={i}>
                                <p className="line-title font-weight-500 p-2 mb-0">{line_orders.line_title}</p>
                                <table className="table line-orders mb-0">
                                    <thead>
                                        <tr>
                                            <th>{translate("common.order", "Order")}</th>
                                            <th className="text-right">
                                                {translate("common.process_num", "Process number")}
                                            </th>
                                            <th>{translate("common.date", "Date")}</th>
                                            <th className="text-right">{translate("common.shift", "Shift")}</th>
                                            <th className="text-right">{translate("common.produced", "Produced")}</th>
                                            <th className="text-right">{translate("common.consumed", "Consumed")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {line_orders.orders.map(order => {
                                            if (order.produced !== 0) {
                                                produced += order.produced;
                                            } else if (order.consumed !== 0) {
                                                consumed += order.consumed;
                                            }

                                            const shift_date = fromShiftTag(order.shift_tag);
                                            const sn = shiftNumber(shift_date);
                                            return (
                                                <tr key={order.uuid}>
                                                    <td>{order.external_id}</td>
                                                    <td className="text-right">{order.process_num}</td>
                                                    <td className="text-nowrap">
                                                        {shift_date.toISOString().slice(0, 10)}
                                                    </td>
                                                    <td className="text-right">{sn.shift_day + 1}</td>
                                                    <td
                                                        className="text-right"
                                                        title={renderStockTitle(order.produced)}
                                                    >
                                                        {renderStock(order.produced, true)}
                                                    </td>
                                                    <td
                                                        className="text-right"
                                                        title={renderStockTitle(order.consumed)}
                                                    >
                                                        {renderStock(order.consumed, true)}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                        <tr>
                                            <td className="font-weight-500" colSpan={4}>
                                                {translate("common.total", "Total")}
                                            </td>
                                            <td
                                                className="text-right font-weight-500"
                                                title={renderStockTitle(produced)}
                                            >
                                                {renderStock(produced, true)}
                                            </td>
                                            <td
                                                className="text-right font-weight-500"
                                                title={renderStockTitle(consumed)}
                                            >
                                                {renderStock(consumed, true)}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </React.Fragment>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default MaterialStockForecastTooltip;
