// @flow
import React from "react";
import LineOrderList from "../lineOrderList/LineOrderList";
import Loader from "../../../Loader";
import Filters from "../Filters";
import { translate } from "../../../IntlProviderWrapper";
import { shiftNumber } from "../../../../lib/Util";
import UnscheduledOrders from "../UnscheduledOrders";
import LeftMenuOrdersDivider from "./LeftMenuOrdersDivider";
import { LeftMenuOrdersDividerEventHandler } from "../reducers/BusinessLogic";

import type { LineOrders } from "../reducers/linesOrders";

export const default_configuration = (() => {
    const current_date = new Date();
    const shift_number = shiftNumber(current_date);

    return {
        week: shift_number.week,
        year: shift_number.year,
        shift: shift_number.shift
    };
})();

export const OrdersTabHeader = () => {
    const title = translate("OrderTableProduction.operations", "Operations");
    return (
        <span title={title}>
            <i className="fas fa-file-alt" />
            <span>{title}</span>
        </span>
    );
}

type OrderTabContentProps = {
    show_planning_table_filter: boolean,
    line_group_uuid: string,
    plant_uuid: string,
    lines_orders_loading: boolean,
    lines_orders: LineOrders[],
    onClickInfo: (title: string) => (e: Event) => void
};
type OrderTabContentState = {
    orders_height: number | null
};

export default class OrdersTabContent extends React.Component<OrderTabContentProps, OrderTabContentState> {

    state = {
        orders_height: null
    }

    componentDidMount() {
        this.load()
    }

    load() {
        LeftMenuOrdersDividerEventHandler.listen(() => {
            this.setState({
                orders_height: LeftMenuOrdersDividerEventHandler.getHeight()
            });
        });
    }

    componentWillUnmount() {
        LeftMenuOrdersDividerEventHandler.onUnmount();
    }

    renderLines() {
        // still loading
        if (this.props.lines_orders_loading) {
            return <div style={{height: "calc(100vh - 370px)"}}>
                <Loader small={true}/>
            </div>
        }

        let lines_components = this.props.lines_orders
            .map((line: LineOrders, i) => {
                return (<li key={"line" + i + line.line_uuid}>
                    <LineOrderList
                        line_uuid={line.line_uuid}
                        line_group_uuid={this.props.line_group_uuid}
                        compact={true}
                        parent_children_map={[]}
                        insights_map={null}
                        week={default_configuration.week}
                        plant_uuid={this.props.plant_uuid}
                        year={default_configuration.year}
                        onClickInfo={this.props.onClickInfo}
                    />
                </li>)
            });

        const height = LeftMenuOrdersDividerEventHandler.getHeight();

        return <ul id="left-menu-orders" className="list-unstyled flex-grow overflow-auto mb-0"
            style={{ display: "flex", flexDirection: "column", height: "calc(100vh - 290px)" }}
        >
            <div id="scheduled-orders" style={{ height: height > 0 ? height + "px" : "50%", overflow: "auto" } }>
                {lines_components}
            </div>
            <LeftMenuOrdersDivider />
            <div id="unscheduled-orders" style={{ height: height > 0 ? `calc(100% - ${height}px)` : "50%", overflow: "auto"}}>
                <UnscheduledOrders />
            </div>
        </ul>
    }

    render() {
        return <div className="orders flex-column h-100">
            <Filters
                show_planning_table_filter={this.props.show_planning_table_filter}
                line_group_uuid={this.props.line_group_uuid}
            />
            {
                this.renderLines()
            }
        </div>;
    }
}