// @flow

import * as Sentry from '@sentry/browser';
import { getBackend } from "./backend/Backend2";

// initialize error collector by getting settings from backend
export async function initErrorCollector(): Promise<void> {
    const res = await getBackend().common.getErrorCollectorSettings({});
    if (res.type === "sentry") {
        // inject additional sentry tags if provided
        Sentry.init({ dsn: res.url, ...(res.tags || {}) });
    }
}
