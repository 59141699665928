// @flow
import React from "react";
import ReactDOM from "react-dom";
import Barcode from "react-barcode";
import { INSIGHT_TYPES } from "./ManufacturingTags.generated";
import { getLang, getLocale, translate } from "../components/IntlProviderWrapper";
import * as r from "./backend/reports.generated.types";

import type { IEventDataEx } from "../lib/backend/reports.generated.types";
import type { ShiftNumberData } from "./Models";
import type { AvailableInsightTypes } from "../components/manufacturing/PlanningTable2/reducers/ganttChartStandalone";

import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";

export interface INameValuePair {
    name: string;
    value: string;
}

export interface ILabelValuePair {
    label: string;
    value: string;
}

export interface ILabelValuePairEx extends ILabelValuePair {
    uuid: string
}

export function getValuesAsArray(obj: any): any[] {
    const res: any[] = [];
    for (const val of Object.values(obj)) {
        res.push(val);
    }
    return res;
}

export function transpose<T>(matrix: T[][]): T[][] {
    for (var i = 0; i < matrix.length; i++) {
        for (var j = 0; j < i; j++) {
            // $FlowFixMe
            [matrix[i][j], matrix[j][i]] = [matrix[j][i], matrix[i][j]];
        }
    }
    return matrix;
}

export function update_key(hashmap: Object, key: any, value: any) {
    if (hashmap !== undefined) {
        hashmap[key] = value;
    }
    return hashmap;
}

export function mergeObjects(oldObject: Object, newObject: any) {
    if (oldObject !== undefined && newObject !== undefined) {
        Object.keys(newObject).forEach(key => {
            oldObject[key] = newObject[key];
        });
    }
    return oldObject;
}

export function uniqueValues(arr: any[]): any[] {
    const unique: any[] = arr.filter((x, i, a) => a.indexOf(x) === i);
    return unique;
}
export function uniqueValuesT<T>(arr: T[]): T[] {
    const unique: T[] = arr.filter((x, i, a) => a.indexOf(x) === i);
    return unique;
}

/** Utility function to create a map from given array, using a mapping function to create key from the item
 *
 * @param data Array to be transformed into map
 * @param map Mapping function that extracts key for map entirely from array-item itself
 */
export function createMap<TA, TB>(data: TA[], map: (x: TA) => TB): Map<TB, TA> {
    const res: Map<TB, TA> = new Map();
    for (const x of data) {
        const key = map(x);
        res.set(key, x);
    }
    return res;
}

export function stripTimePart(d: Date): Date {
    const res = new Date(d.getTime());
    res.setMilliseconds(0);
    res.setSeconds(0);
    res.setMinutes(0);
    res.setHours(0);
    return res;
}

export function uuidv4(): string {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx"
        .replace(/[xy]/g, c => {
            const r = Math.random() * 16 | 0, v = (c === 'x' ? r : ((r & 0x3) | 0x8));
            return v.toString(16);
        });
}

export function ensureDeviceUUID() {
    if (!localStorage.getItem("device_uuid")) {
        localStorage.setItem("device_uuid", uuidv4());
    }
}

export function getDeviceUUID(): string {
    const uuid = localStorage.getItem("device_uuid"); // always set!
    if (uuid) {
        return uuid;
    }
    return "fake-uuid";
}

export function duplicate(array: Array<mixed>, num_copies: number): Array<mixed> {
    let res = [];
    for (let i = 0; i < num_copies; i++) {
        res.push(...array);
    }
    return res;
}

export const ORANGE_COLOR = "#FFB68F";
export const DARK_SALMON_COLOR = "#f78782";
export const WHITE_COLOR = "#ffffff";
export const GREY_COLOR = "#9aa5b1"
export const LIGHT_BLUE = "#178bf0";
export const DARK_BLUE = "#47a3f3";

export const DARKER_SALMON_COLOR = "#ff6b1c";
export const DARKER_DARK_SALMON_COLOR = "#f12d22";
export const DARKER_GREY_COLOR = "#647282";
export const DARKER_LIGHT_BLUE = "#0a5699";
export const DARKER_DARK_BLUE = "#0d71c9"
export const DARKER_WHITE_COLOR = "#cccccc";

export const getColorPatternForPlannedOrders = (color: string): string => {
    if (color === ORANGE_COLOR) return "circlePatternSalmon";
    if (color === DARK_SALMON_COLOR) return "circlePatternDarkSalmon";
    if (color === WHITE_COLOR) return "circlePatternWhite";
    if (color === GREY_COLOR) return "circlePatternGrey";
    if (color === LIGHT_BLUE) return "circlePatternLightBlue";
    if (color === DARK_BLUE) return "circlePatternDarkBlue";
    return color;
}

export const getDarkerColor = (color: string): string => {
    if (color === ORANGE_COLOR) return DARKER_SALMON_COLOR;
    if (color === DARK_SALMON_COLOR) return DARKER_DARK_SALMON_COLOR;
    if (color === LIGHT_BLUE) return DARKER_LIGHT_BLUE;
    if (color === DARK_BLUE) return DARKER_DARK_BLUE;
    if (color === WHITE_COLOR) return DARKER_WHITE_COLOR;
    if (color === GREY_COLOR) return DARKER_GREY_COLOR;
    return color;
}

type OrderColorArgs = {
    order_type: string,
    has_insight: boolean,
    failed_scheduling?: boolean,
    filtered_insight_types?: AvailableInsightTypes[],
    insights?: IEventDataEx[],
    is_unreleased?: boolean
}

export function orderColor(args: OrderColorArgs): string {
    const {
        order_type,
        has_insight,
        failed_scheduling,
        filtered_insight_types = [],
        insights = [],
        is_unreleased = false
    } = args;

    if (failed_scheduling) {
        return "white";
    }
    const is_planned = order_type === "plan";
    const show_pattern = is_planned || is_unreleased;

    // check if we have any worthy insight
    if (has_insight && insights.length > 0) {
        const insight_types = insights
            .map(insight => insight.type)
            .filter(insight_type => filtered_insight_types.includes(insight_type));

        const is_orange =
            insight_types.includes(INSIGHT_TYPES.operation_no_input_material) ||
            insight_types.includes(INSIGHT_TYPES.tool_overlap);
        const is_salmon =
            insight_types.includes(INSIGHT_TYPES.operation_delay_downstream) ||
            insight_types.includes(INSIGHT_TYPES.operation_constraint_violated) ||
            insight_types.includes(INSIGHT_TYPES.conditional_capacity_violated);

        if (is_orange && is_salmon) {
            return show_pattern ? "url(#patternCombinedPlannedInsights)" : "url(#patternCombinedInsights)";
        } else if (is_orange) {
            return show_pattern ? `url(#${getColorPatternForPlannedOrders(ORANGE_COLOR)})` : ORANGE_COLOR;
        } else if (is_salmon) {
            return show_pattern ? `url(#${getColorPatternForPlannedOrders(DARK_SALMON_COLOR)})` : DARK_SALMON_COLOR;
        }
    }

    // default is blue
    return show_pattern ? `url(#${getColorPatternForPlannedOrders(DARK_BLUE)})` : DARK_BLUE;
}

export function orderColorCSS(order_type: string, i: number): string {
    return (order_type === "plan") ?
        ["order_plan_color1", "order_plan_color2"][i % 2] :
        ["order_work_color1", "order_work_color2"][i % 2];
}

let colors = ["#47a3f3", "#5e8aee", "#54d1db"];

export function mycolor(i: number) {
    return colors[i % colors.length];
}

export function mycolorcss(i: number) {
    return "color" + (i % colors.length);
}

export function myday(i: number, length: number = 10) {
    return dayString(i).substr(0, length);
}

export function mydayjs(i: number, length: number = 10) {
    // because 0 is Sunday
    const index = [6, 0, 1, 2, 3, 4, 5][i];
    return myday(index, length)
}

export const SHIFT_CONSTS = {
    FIRST_SHIFT_STARTING_HOUR: 6,
    SHIFT_DURATION_HOURS: 8,
    SHIFT_START_HOURS: [6, 14, 22],
    SELECT_OPTIONS: [0, 1, 2]
}

/** This function that returns start times of shift as strings "HH:MM:SS" */
export function getShiftStartHoursAsStrings(): string[] {
    return SHIFT_CONSTS.SHIFT_START_HOURS
        .map(x => (100 + x).toString(10).slice(1) + ":00:00");
}

export function isNumber(xx: number): boolean {
    // First: Check typeof and make sure it returns number
    if (typeof xx !== "number") { return false; }
    // Second: Check for NaN, as NaN is a number to typeof.
    if (xx !== Number(xx)) { return false; }
    // Third: Check for Infinity and -Infinity.
    if (xx === Infinity || xx === !Infinity) { return false; }
    // all tests are go!
    return true;
}

export function isStringNumber(x: string): boolean {
    const xx = parseFloat(x);
    return isNumber(xx);
}

function escapeRegExp(string: string) {
    // https://codereview.stackexchange.com/questions/153691/escape-user-input-for-use-in-js-regex
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

export function parseFloatSmart(stringValue: string, def: number): number {
    // https://stackoverflow.com/questions/11665884/how-can-i-parse-a-string-with-a-comma-thousand-separator-to-a-number

    const minuses = ["−", "-"];
    let isNegative = false;

    if (stringValue && stringValue.length > 0 && minuses.includes(stringValue.charAt(0))) {
        isNegative = true;
        stringValue = stringValue.substring(1);
    }

    if (stringValue === null) {
        return def;
    }
    const locale = getLocale();
    var parts = Number(1111.11).toLocaleString(locale).replace(/\d+/g, "").split("");

    if (parts.length === 1) {
        parts.unshift("");
    }
    const digitSep = escapeRegExp(parts[0].replace(/\s/g, " ")); // if space is used as separator, normalize to 1 space
    const decimalSep = escapeRegExp(parts[1]);
    const removeDigitSep = String(stringValue).replace(new RegExp(digitSep, "g"), "");
    const replaceDecimalSep = removeDigitSep.replace(decimalSep, ".");
    const num = Number(replaceDecimalSep);

    if ((num !== 0 && !num) || isNaN(num) || !isFinite(num)) {
        return def;
    } else {
        return isNegative ? -num : num;
    }
}

export function niceNumber(x: number, decimals?: number): string {
    return new Intl.NumberFormat(getLang(), { maximumFractionDigits: decimals }).format(x);
}

export function niceNumberUnit(x: number, unit: string): number {
    if (unit === "KOS" || unit === "piece") {
        return Number(Math.round(x));
    }
    // round to 3 decimals
    return Number(Math.round(Number(x + "e3")) + "e-3");
}

export function niceNumberEstimate(x: number, precision: number = 0.1, max_decimals: number = 7): string {
    let decimals = 0;

    if (x !== 0) {
        let rounded_x = Math.round(x);
        let diff = Math.abs((x - rounded_x) / x);
        while (decimals < max_decimals && diff > precision) {
            decimals += 1;
            const factor = Math.pow(10, decimals);
            rounded_x = Math.round((x + Number.EPSILON) * factor) / factor;
            diff = Math.abs((x - rounded_x) / x);
        }
    }

    return niceNumber(x, decimals);
}

export function niceDate(date: Date): string {
    if (getLang() === "sl") {
        return toSloDateString(date);
    }

    return toISODateString(date);
}
export function niceTime(date: Date): string {
    return toISOTimeString(date);
}

export function niceShortDate(date: string): string {
    // 11-24 -> 24.11. and vice versa
    let month, day;

    if (date.includes("-")) {
        [month, day] = date.split("-");
    } else if (date.includes(".")) {
        [month, day] = date.split(".");
    }

    if (month && day) {
        if (getLang() === "sl") {
            return `${day}.${month}.`;
        } else {
            return `${month}-${day}`;
        }
    }

    return date;
}

export function niceDateTime(ddate: Date | number): string {
    const date = new Date(ddate);
    if (getLang() === "sl") {
        return toSloDateTimeString(date);
    }

    return toISODateTimeString(date);
}

export function getDecimalSeparator() {
    return window.Intl.NumberFormat(getLocale())
        .formatToParts(1.1)
        .find(part => part.type === "decimal").value;
}

export function getThousandsSeparator() {
    return window.Intl.NumberFormat(getLocale())
        .formatToParts(1000)
        .find(part => part.type === "group").value;
}

/** This function should be used from sorting lists of strings that are displayed to user,
 * since default sort ignores locale (e.g. 'Č' is treated as 'C' etc)
 */
export function stringCompare(a: string, b: string) {
    return a.localeCompare(b, getLang());
}

export function toISODateTimeString(date: Date): string {
    //let tzo = -date.getTimezoneOffset();
    //let dif = tzo >= 0 ? '+' : '-';
    let pad = function (num) {
        var norm = Math.floor(Math.abs(num));
        return (norm < 10 ? '0' : '') + norm;
    };
    let date_str = date.getFullYear() +
        '-' + pad(date.getMonth() + 1) +
        '-' + pad(date.getDate()) +
        'T' + pad(date.getHours()) +
        ':' + pad(date.getMinutes()) +
        ':' + pad(date.getSeconds()) +
        /*dif + pad(tzo / 60) +
        ':' + pad(tzo % 60)*/ "";
    return date_str.replace('T', ' ');
}

export function getDateOfISOWeek(week: number, year: number): Date {
    const simple = new Date(year, 0, 1 + (week - 1) * 7);
    const dow = simple.getDay();
    const ISOweekStart = simple;
    if (dow <= 4) {
        ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    } else {
        ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    }
    return ISOweekStart;
}

/* shift ranges from 0 to 20 (7 days, 3 shifts per day) */
// Returns start of the day for the given shift
export function getDateOfISOWeekShift(shift: number, week: number, year: number): Date {
    var days = Math.floor(shift / 3);
    var date = getDateOfISOWeek(week, year);
    date.setDate(date.getDate() + days);
    return date;
}

// Returns start of the shift
export function dateFromWeekAndShift(week: number, year: number, shift: number): Date {
    const out = getDateOfISOWeek(week, year);
    out.setHours((shift * SHIFT_CONSTS.SHIFT_DURATION_HOURS) + SHIFT_CONSTS.FIRST_SHIFT_STARTING_HOUR, 0, 0);
    return out;
}

export function addHours(date: Date, hours: number): Date {
    let out = new Date();
    out.setTime(date.getTime() + (hours * 60 * 60 * 1000));
    return out;
}

export function subtractHours(date: Date, hours: number): Date {
    let out = new Date();
    out.setTime(date.getTime() - (hours * 60 * 60 * 1000));
    return out;
}

export function getDateRangeStrForWeek(week: number, year: number): string {
    let start_date = getDateOfISOWeek(week, year);
    let end_date = new Date(start_date);
    end_date.setDate(start_date.getDate() + 6);
    return niceDate(start_date) + " - " + niceDate(end_date);
}

export function negativeToZero(value: number): number {
    return Math.max(0, value);
}

export function toISODateString(date: Date): string {
    let datetime = toISODateTimeString(date);
    return datetime.split(' ')[0];
}

export function toSloDateString(date: Date): string {
    let datetime = toISODateTimeString(date);
    let date_string = datetime.split(' ')[0];
    return date_string.split('-')[2] + "." + date_string.split('-')[1] + "." + date_string.split('-')[0];
}

export function toSloDateTimeString(date: Date): string {
    let datetime = toISODateTimeString(date);
    let date_string = datetime.split(' ')[0];
    return date_string.split('-')[2] + "." + date_string.split('-')[1] + "." + date_string.split('-')[0] + " " + datetime.split(' ')[1];
}

export function toISOTimeString(date: Date): string {
    let datetime = toISODateTimeString(date);
    return datetime.split(' ')[1];
}

export function lowHigh(extreme: string, lang: string): string {
    let out: string = "high";
    if (lang === "eng") {
        if (extreme === "low") {
            out = "low";
        }
    }
    return out;
}

export function lowerHigher(extreme: string, lang: string): string {
    let out: string = "unknown";
    switch (lang) {
        case "eng":
            switch (extreme) {
                case "low":
                    out = "below";
                    break;
                case "high":
                    out = "above";
                    break;
                default:
                    break;
            }
            break;
        default:
            switch (extreme) {
                case "low":
                    out = "below";
                    break;
                case "high":
                    out = "above";
                    break;
                default:
                    break;
            }
            break;
    }
    return out;
}

export function capitalize(word: string): string {
    return word.charAt(0).toUpperCase() + word.slice(1);
}

export function splitIntoArrOfArr<T>(arr: Array<T>, splitsize: number): Array<Array<T>> {
    let parentarr = [];
    let childarr = [];
    for (let i = 0; i < arr.length; i++) {
        childarr.push(arr[i]);
        if (childarr.length >= splitsize) {
            parentarr.push(childarr);
            childarr = [];
        }
    }
    if (childarr.length > 0) {
        parentarr.push(childarr);
    }
    return parentarr;
}

export function executeInParallel(tasks: Array<(d: () => void) => void>, done: () => void) {
    if (tasks.length === 0) {
        return done();
    }
    let calls_completed = 0;
    tasks.forEach(task => {
        task(() => {
            calls_completed++;
            if (calls_completed === tasks.length) {
                done();
            }
        });
    });
}

export function getMonday(date: Date, hour: number = 0,
    minute: number = 0, second: number = 0,
    millisecond: number = 0) {
    const day = date.getDay() || 7;
    const newdate = new Date(date.getTime());
    newdate.setDate(date.getDate() - day + 1);
    newdate.setHours(hour);
    newdate.setMinutes(minute);
    newdate.setSeconds(second)
    newdate.setMilliseconds(millisecond);
    return newdate;
}

export function dictToArray(dict: Object) {
    let array = [];
    for (let key in dict) {
        array.push(dict[key]);
    }
    return array;
}

export function addDicts(dict1: Object, dict2: Object) {
    let out = {};
    for (let key in dict1) {
        if (dict1[key].val !== 0) {
            out[key] = JSON.parse(JSON.stringify(dict1[key]));
            out[key].val = 0;
        }
    }
    for (let key in dict2) {
        if (dict2[key].val !== 0) {
            out[key] = JSON.parse(JSON.stringify(dict2[key]));
            out[key].val = 0;
        }
    }
    for (let key in out) {
        if (key in dict1) {
            if (dict1[key].material === out[key].material) {
                out[key].val += dict1[key].val;
            }
        }
        if (key in dict2) {
            if (dict2[key].material === out[key].material) {
                out[key].val += dict2[key].val;
            }
        }
    }
    return out;
}

export function removeZerosFromArray(array: Array<any>) {
    let out = [];
    for (let i = 0; i < array.length; i++) {
        if (array[i] !== 0) {
            out.push(array[i]);
        }
    }
    return out;
}

export function removeExtraZerosFromArray(array: Array<any>) {
    let out = [];
    for (let i = 0; i < array.length; i++) {
        if (array[i] !== 0) {
            out.push(array[i]);
        }
    }
    if (array.indexOf(0) !== -1 && out.length === 0) {
        out.push(0);
    }
    return out;
}

export function arrayToString(array: Array<any>) {
    return JSON.stringify(array);
}

export function arrayToValString(array: Array<any>, val_attr: string) {
    let out = "";
    for (let i = 0; i < array.length; i++) {
        out += array[i][val_attr] + ", ";
    }
    return out.substr(0, out.length - 2);
}

export function groupSumArray(array: Array<any>, sum_att: string, group_att: string) {
    let dict = {};
    for (let i = 0; i < array.length; i++) {
        let att_val = array[i][group_att];
        let val_val = array[i][sum_att];
        if (att_val in dict) {
            dict[att_val][sum_att] += val_val;
        }
        else {
            dict[att_val] = JSON.parse(JSON.stringify(array[i]));
        }
    }
    let out = [];
    for (let key in dict) {
        let obj = dict[key];
        out.push(obj);
    }
    return out;
}

export function yearNumber(input_date: Date) {
    const date = new Date(input_date)
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
    return date.getFullYear();
}

export function weekYear(date: Date) {
    return yearNumber(date);
}

export function weekNumber(dt: Date) {
    let tdt = new Date(dt.valueOf());
    let dayn = (dt.getDay() + 6) % 7;
    tdt.setDate(tdt.getDate() - dayn + 3);
    let firstThursday = tdt.valueOf();
    tdt.setMonth(0, 1);
    if (tdt.getDay() !== 4) {
        tdt.setMonth(0, 1 + ((4 - tdt.getDay()) + 7) % 7);
    }
    return 1 + Math.ceil((firstThursday - tdt) / 604800000);
}

//TODO Select based on the client's language settings
export function dayString(dayn: number): string {
    switch (dayn % 7) {
        case 0:
            return translate("common.weekday0", "Monday");
        case 1:
            return translate("common.weekday1", "Tuesday");
        case 2:
            return translate("common.weekday2", "Wednesday");
        case 3:
            return translate("common.weekday3", "Thursday");
        case 4:
            return translate("common.weekday4", "Friday");
        case 5:
            return translate("common.weekday5", "Saturday");
        case 6:
            return translate("common.weekday6", "Sunday");
        default:
            return "";
    }
}
export function dayStrings(shortNames?: boolean): string[] {
    let res: string[] = [];
    for (let i = 0; i < 7; i++) {
        res.push(dayString(i));
    }
    if (shortNames) {
        res = res.map(x => x.slice(0, 3));
    }
    return res;
}

export function fromShiftTag(shift_tag: string): Date {
    const year = +shift_tag.slice(0, 4);
    const week = +shift_tag.slice(4, 6);
    const shift = +shift_tag.slice(6);
    return dateFromWeekAndShift(week, year, shift);
}

export function shiftTag(year: number, week: number, shift_number: number) {
    return "" + (year * 10000 + week * 100 + shift_number);
}

export function dateFromShiftTime(shift: r.ISimulationReportShiftTime): Date {
    return dateFromWeekAndShift(
        shift.week,
        shift.year,
        shift.shift_number
    );
}

export function shiftNumber(ddate: Date | number): ShiftNumberData {
    // WARNING: this code should be the same as on the backend
    // Edit code there, perform tests and only then copy it here
    const date = new Date(ddate);
    let dayn = (date.getDay() + 6) % 7;
    const firstshift = 3 * dayn;
    const hour = date.getHours();
    let currentshift = -1;
    if (hour < SHIFT_CONSTS.SHIFT_START_HOURS[0]) {
        dayn--;
        currentshift = firstshift - 1;
    } else if (hour >= SHIFT_CONSTS.SHIFT_START_HOURS[0] && hour < SHIFT_CONSTS.SHIFT_START_HOURS[1]) {
        currentshift = firstshift;
    } else if (hour >= SHIFT_CONSTS.SHIFT_START_HOURS[1] && hour < SHIFT_CONSTS.SHIFT_START_HOURS[2]) {
        currentshift = firstshift + 1;
    } else {
        currentshift = firstshift + 2;
    }
    const newdate = new Date(date.getTime());
    if (currentshift === -1) {
        currentshift = 20;
        newdate.setDate(date.getDate() - 1);
        dayn = (newdate.getDay() + 6) % 7;
    }
    const week = weekNumber(newdate);
    const year = weekYear(newdate);
    const shift_day = currentshift % 3;
    const ts_start = dateFromWeekAndShift(week, year, currentshift).getTime();
    let ts_end = ts_start + SHIFT_CONSTS.SHIFT_DURATION_HOURS * TIME_RANGES.HOUR;
    const ts_end_d = new Date(ts_end);
    const ts_end_hour = ts_end_d.getHours();
    if (SHIFT_CONSTS.SHIFT_START_HOURS.indexOf(ts_end_hour) < 0) {
        // anomaly - clock was moved, adjust
        const eps = SHIFT_CONSTS.SHIFT_DURATION_HOURS / 2;
        for (const h of SHIFT_CONSTS.SHIFT_START_HOURS) {
            if (Math.abs(h - ts_end_hour) < eps) {
                // ok, this is the closes hour when shifts will switch
                ts_end_d.setHours(h);
                ts_end = ts_end_d.getTime();
            }
        }
    }
    const day = dayString(dayn);
    return {
        day,
        day_number: dayn,
        shift: currentshift,
        shift_day,
        shift_tag: shiftTag(year, week, currentshift),
        ts_end,
        ts_start,
        week,
        year
    };
}

// [0, N] => [0, 2]
export function weekToDayShift(shift_week: number): number {
    return shift_week % 3;
}

// shift is from 0, N
export function dateFromWeekShift(date: Date, shift: number): Date {
    let out = new Date(date.getTime());
    let shift_day = weekToDayShift(shift);
    if (shift_day === 0) {
        out.setHours(SHIFT_CONSTS.SHIFT_START_HOURS[0], 0, 0, 0);
    } else if (shift_day === 1) {
        out.setHours(SHIFT_CONSTS.SHIFT_START_HOURS[1], 0, 0, 0);
    } else if (shift_day === 2) {
        out.setHours(SHIFT_CONSTS.SHIFT_START_HOURS[2], 0, 0, 0);
    }
    return out;
}

// shift is from 0 to 2
export function dateFromDayShift(date: Date, shift: number): Date {
    let out = new Date(date.getTime());
    if (shift === 0) {
        out.setHours(SHIFT_CONSTS.SHIFT_START_HOURS[0], 0, 0, 0);
    } else if (shift === 1) {
        out.setHours(SHIFT_CONSTS.SHIFT_START_HOURS[1], 0, 0, 0);
    } else if (shift === 2) {
        out.setHours(SHIFT_CONSTS.SHIFT_START_HOURS[2], 0, 0, 0);
    }
    return out;
}

export function isEmpty(obj: Object) {
    for (var prop in obj) {
        if (obj.hasOwnProperty(prop))
            return false;
    }
    return JSON.stringify(obj) === JSON.stringify({});
}

export let shift_labels = ["", "1. Mo", "2. Mo", "3. Mo", "1. Tu", "2. Tu", "3. Tu", "1. We", "2. We", "3. We",
    "1. Th", "2. Th", "3. Th", "1. Fr", "2. Fr", "3. Fr", "1. Sa", "2. Sa", "3. Sa", "1. Su", "2. Su",
    "3. Su", "1. Mo", "2. Mo", "3. Mo", "1. Tu", "2. Tu", "3. Tu", "1. We", "2. We", "3. We", "1. Th",
    "2. Th", "3. Th", "1. Fr", "2. Fr", "3. Fr", "1. Sa", "2. Sa", "3. Sa", "1. Su", "2. Su", "3. Su",
    "1. Mo", "2. Mo", "3. Mo", "1. Tu", "2. Tu", "3. Tu", "1. We", "2. We", "3. We",
    "1. Th", "2. Th", "3. Th", "1. Fr", "2. Fr", "3. Fr", "1. Sa", "2. Sa", "3. Sa", "1. Su", "2. Su",
    "3. Su", "1. Mo", "2. Mo", "3. Mo", "1. Tu", "2. Tu", "3. Tu", "1. We", "2. We", "3. We", "1. Th",
    "2. Th", "3. Th", "1. Fr", "2. Fr", "3. Fr", "1. Sa", "2. Sa", "3. Sa", "1. Su", "2. Su", "3. Su"];

export let ranges: Array<number> = [
    60 * 60 * 1000, // hour
    24 * 60 * 60 * 1000, // day
    7 * 24 * 60 * 60 * 1000, // week
    31 * 24 * 60 * 60 * 1000, // month
    100 * 24 * 60 * 60 * 1000, // quarter
    365 * 24 * 60 * 60 * 1000, // year
    5 * 365 * 24 * 60 * 60 * 1000 // 5 years
];

export const TIME_RANGES = {
    DAY: 24 * 60 * 60 * 1000,
    HOUR: 60 * 60 * 1000,
    MINUTE: 60 * 1000,
    SECOND: 1000,
    WEEK: 7 * 24 * 60 * 60 * 1000
};

/////////////////////////////////////////////////////////////////////////////////////
// Simple parsing of url query params

export function parseUrlQueryVariables(url?: string): Object {
    url = url || window.location.search;
    const hashStart = url.indexOf('#');
    if (hashStart !== -1) {
        url = url.slice(0, hashStart);
    }
    const qStart = url.indexOf('?');
    if (qStart !== -1) {
        url = url.slice(qStart + 1);
    }
    if (url === "") {
        return {};
    }

    const vars = url.split('&');
    const res = {};
    for (let i = 0; i < vars.length; i++) {
        // split only by first occurance of '='
        let [key, ...val] = vars[i].split('=');
        val = val.join('=');
        res[decodeURIComponent(key)] = decodeURIComponent(val);
    }
    return res;
}

export function encodeUrlQuery(params: any): string {
    const res = [];
    for (const key of Object.keys(params)) {
        res.push(encodeURIComponent(key) + "=" + encodeURIComponent(params[key]))
    }
    return res.join("&");
}

export function convertToNumbersOrNull(new_analysis_params: Object): Object {
    let params = new_analysis_params.params
    for (let key in params) {
        let value = params[key]

        // if input form was left empty, replace with null,
        // so that parameter will be deleted from the db
        if (value === "") {
            params[key] = null;
            continue;
        }

        // try to convert to numbers
        params[key] = (Number.isNaN(Number(value))) ? value : Number(value);
    }
    return new_analysis_params
}

export function getURLHashParamsMap() {
    const hashparams = decodeURI(window.location.hash).replace("#", "");
    const hashmap = {};
    if (hashparams) {
        hashparams.split("&").forEach(element => {
            const keyvalue = element.split("=");
            hashmap[keyvalue[0]] = keyvalue[1].split(",");
        });
    }
    return hashmap;
}

export function buildUrlHash(hashparams: any) {
    let urlhash = "";
    Object.keys(hashparams).forEach(key => {
        if (hashparams[key].length > 0) {
            urlhash = `${urlhash}&${key}=${hashparams[key].join()}`
        }
    });
    return urlhash.substr(1);
}

export function updateURLHash(hashparams: any) {
    const urlhash = buildUrlHash(hashparams);
    if (window.location.hash !== urlhash) {
        const hashed = "#" + urlhash;
        if (window.history.pushState) {
            window.history.pushState(null, null, hashed);
        } else {
            window.location.hash = hashed;
        }
    }
}

export function hashCode(key: string) {
    let hash = 0, i, chr;
    if (key.length === 0) return hash;
    for (i = 0; i < key.length; i++) {
        chr = key.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
}

////////////////////////////////////////////////////////////////////////////////

const thresholds = {
    ss: 44,         // a few seconds to seconds
    s: 45,         // seconds to minute
    m: 45,         // minutes to hour
    h: 22,         // hours to day
    d: 26,         // days to month
    M: 11          // months to year
};

export function createDurationString(duration_in_msec: number, exact?: boolean) {
    if (!exact) {
        const seconds = Math.round(duration_in_msec / 1000);
        const minutes = Math.round(seconds / 60);
        const hours = Math.round(minutes / 60);
        const days = Math.round(hours / 24);
        const months = Math.round(days / 30);
        const years = Math.round(months / 365);

        const a =
            (seconds <= thresholds.ss && ["s", seconds, "sec"]) ||
            (seconds < thresholds.s && ["ss", seconds, "sec"]) ||
            (minutes <= 1 && ["m", 1, "min"]) ||
            (minutes < thresholds.m && ["mm", minutes, "min"]) ||
            (hours <= 1 && ["h", 1, "h"]) ||
            (hours < thresholds.h && ["hh", hours, "h"]) ||
            (days <= 1 && ["d", 1, "day"]) ||
            (days < thresholds.d && ["dd", days, "days"]) ||
            (months <= 1 && ["M", 1, "month"]) ||
            (months < thresholds.M && ["MM", months, "months"]) ||
            (years <= 1 && ["y", 1, "year"]) ||
            ["yy", years, "years"];
        return a[1] + " " + a[2];
    } else {
        let years = Math.floor(duration_in_msec / (365 * TIME_RANGES.DAY));
        duration_in_msec -= years * (365 * TIME_RANGES.DAY);
        let months = Math.floor(duration_in_msec / (30 * TIME_RANGES.DAY));
        duration_in_msec -= months * (30 * TIME_RANGES.DAY);
        let days = Math.floor(duration_in_msec / (TIME_RANGES.DAY));
        duration_in_msec -= days * (TIME_RANGES.DAY);
        let hours = Math.floor(duration_in_msec / (TIME_RANGES.HOUR));
        duration_in_msec -= hours * (TIME_RANGES.HOUR);
        let minutes = Math.floor(duration_in_msec / (TIME_RANGES.MINUTE));
        duration_in_msec -= minutes * (TIME_RANGES.MINUTE);
        let seconds = Math.floor(duration_in_msec / (TIME_RANGES.SECOND));
        duration_in_msec -= seconds * (TIME_RANGES.SECOND);
        let msec = duration_in_msec;

        const helper: any = { years, months, days, hours, minutes, seconds, msec };
        const res: string[] = [];
        for (const key of Object.keys(helper)) {
            if (helper[key] != 0) {
                res.push("" + helper[key]);
                res.push(key);
            }
        }
        return res.join(" ");
    }
}

export function replaceDirtyString(start: string, end: string, key: string, data: Object, replacements_map: Object) {
    const value = data[key];
    if ((start !== "" && value.includes(start)) || (end !== "" && value.includes(end))) {
        const entry = value.replace(start, "").replace(end, "");
        if (replacements_map[entry] !== undefined) {
            const replacement = value.replace(entry, replacements_map[entry]);
            data[key] = replacement;
        }
    }
}

/** This simple function is await-able wrapper for setTimeout function */
export function setTimeoutAsync(delay: number): Promise<void> {
    return new Promise(resolve => { setTimeout(resolve, delay); });
}

export function getBrowser(): string {
    let browser;
    const ua: any = navigator.userAgent.match(/(opera|chrome|safari|firefox|msie)\/?\s*(\.?\d+(\.\d+)*)/i);
    if (navigator.userAgent.match(/Edge/i) || navigator.userAgent.match(/Trident.*rv[ :]*11\./i)) {
        browser = "msie";
    } else {
        if (ua) {
            browser = ua[1].toLowerCase();
        } else {
            browser = "chrome";
        }
    }
    return browser;
}

export function isDocumentElement(el: Element) {
    return [document.documentElement, document.body, window].indexOf(el) > -1;
}

export function getScrollTop(el: Element): number {
    if (isDocumentElement(el)) {
        return window.pageYOffset;
    }
    return el.scrollTop;
}

export function getScrollLeft(el: Element): number {
    if (isDocumentElement(el)) {
        return window.pageXOffset;
    }
    return el.scrollLeft;
}

export function getScrollParent(element: Element): Element {
    let style = getComputedStyle(element);
    const excludeStaticParent = style.position === "absolute";
    const overflowRx = /(auto|scroll)/;
    const docEl = ((document.documentElement: any): Element);

    if (style.position === "fixed") {
        return docEl;
    }

    for (let parent = element; (parent = parent.parentElement);) {
        style = getComputedStyle(parent);
        if (excludeStaticParent && style.position === "static") {
            continue;
        }
        if (overflowRx.test(style.overflow + style.overflowY + style.overflowX)) {
            return parent;
        }
    }

    return docEl;
}

export const debounce = (callback: Function, time: number) => {
    let interval: ?TimeoutID = null;

    return () => {
        if (interval) {
            clearTimeout(interval);
        }

        interval = setTimeout(() => {
            interval = null;

            // eslint-disable-next-line
            callback(arguments);
        }, time);
    };
}

type PathnameSearch = {
    pathname: string,
    search: string,
    pushHistory?: boolean
}

export const getNewPathnameAndSearchParams = (prevProps: Object, newProps: Object, currentSearchParam: string): PathnameSearch => {
    const reactRouterHistoryPushCommandInvoked = (
        newProps.location.pathname !== prevProps.location.pathname ||
        newProps.location.search !== prevProps.location.search
    );

    if (reactRouterHistoryPushCommandInvoked) {
        const hasNewUrlSearchParams = newProps.location.search.length > 0 && newProps.location.search !== currentSearchParam;

        if (hasNewUrlSearchParams) {
            currentSearchParam = newProps.location.search;
        }

        const overrideEmptySearch = currentSearchParam.length > 0 && newProps.location.search.length === 0;

        // we only push history when our new search params are empty and current search params not empty
        if (overrideEmptySearch) {
            return {
                pathname: newProps.location.pathname,
                search: currentSearchParam,
                pushHistory: true
            };
        }
    }

    return {
        pathname: newProps.location.pathname,
        search: currentSearchParam,
        pushHistory: false
    }
}

export const renderTag = (idx: any, key: string, val: string) => {
    let style = "badge";
    if (val === "true") {
        style += " badge-success";
    } else if (val === "false") {
        style += " badge-danger";
    } else {
        style += " badge-secondary";
    }
    return (<span className={style} style={{ marginRight: 3 }} key={idx}>{`${key}: ${val}`}</span>);
}


export const intersect = (a: Array<any>, b: Array<any>): Array<any> => {
    return a.filter(Set.prototype.has, new Set(b));
}

// only positive modulo (Example: mod(-1, 3) ==> 2)
export const positiveModulo = (n: number, m: number) => {
    return ((n % m) + m) % m;
}

////////////////////////////////////////////////////
/** This function is needed for exporting xlsx file */
function string2ArrayBuffer(s: string) {
    let buf: any = new ArrayBuffer(s.length);
    let view = new Uint8Array(buf);
    for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
};

/**
 * Function gets stringified JSON and returns it as a downloadable file.
 * File name doesnt have to has .xlsx extension.
 *
*/
export function createXlsxAndDownload(data_as_json: string, file_name: string) {

    /**
     * data_as_json = [
     *  { "name": "John", "age": 30, "city": "New York" },
     *  { "name": "Peter", "age": 40, "city": "Boston" }
     * ]
     */

    if (!file_name.endsWith(".xlsx")) {
        file_name += ".xlsx";
    }

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(JSON.parse(data_as_json));
    XLSX.utils.book_append_sheet(workbook, worksheet, file_name);
    FileSaver.saveAs(
        new Blob(
            [string2ArrayBuffer(
                XLSX.write(workbook, { bookType: "xlsx", type: 'binary' }))],
            { type: 'application/octet-stream' }),
        `${file_name}`
    );
}

export function createCsvAndDownload(data_cells: string[][], file_name?: string) {
    file_name = file_name || "export.csv";
    const csv = data_cells
        .map(row => row.join(","))
        .join("\n");

    const link = document.createElement("a");
    link.setAttribute("href", "data:text/csv;charset=utf8,\uFEFF" + encodeURI(csv));
    link.setAttribute("download", file_name);
    // $FlowFixMe
    document.body.appendChild(link); // Required for FF
    link.click();
}

export function createPlainTextAndDownload(content: string, file_name: string) {
    file_name = file_name || "export.txt";
    const link = document.createElement("a");
    link.setAttribute("href", "data:text/plain;charset=utf8,\uFEFF" + encodeURI(content));
    link.setAttribute("download", file_name);
    // $FlowFixMe
    document.body.appendChild(link); // Required for FF
    link.click();
}

export function createHtmlAndDownload(content: string, file_name?: string) {
    const object_url = URL.createObjectURL(new Blob([content], { type: "text/html" }));
    const link = document.createElement("a");
    link.setAttribute("href", object_url);
    link.setAttribute("download", file_name || "print.html");
    if (document.body) {
        document.body.appendChild(link); // Required for FF
    }

    link.click();
    URL.revokeObjectURL(object_url);
}

export function createBinaryAndDownload(content_base64: string, file_name: string) {
    file_name = file_name || "output.gz";
    const link = document.createElement("a");
    link.setAttribute("href", "data:application/zip;base64," + content_base64);
    link.setAttribute("download", file_name);
    // $FlowFixMe
    document.body.appendChild(link); // Required for FF
    link.click();
}

export type BarcodeFormat = | "CODE39" | "CODE128" | "EAN13" | "ITF14" | "MSI" | "pharmacode" | "codabar" | "upc";

export type BarcodeProps = {
    value: string,
    width?: number,
    height?: number,
    format?: BarcodeFormat,
    displayValue?: boolean,
    fontOptions?: string,
    font?: string,
    textAlign?: string,
    textPosition?: string,
    textMargin?: number,
    fontSize?: number,
    background?: string,
    lineColor?: string,
    margin?: number,
    marginTop?: number,
    marginBottom?: number,
    marginLeft?: number,
    marginRight?: number,
};

export const exportBarcode = (props: BarcodeProps, cb: (svg: string) => void) => {
    const root = document.createElement("div");
    root.setAttribute("style", "display: none");

    if (!document.body) {
        cb("");
        return;
    }

    document.body.appendChild(root);
    ReactDOM.render(
        <Barcode {...props} />,
        root,
        () => {
            cb(root.innerHTML);
            root.remove();
        }
    );
}

export const hexToRgb = (color: string): string => {
    const clean_color = color.trim();

    // HEX long format.
    let match = clean_color.match(/^#([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})?$/i);
    if (match) {
        const r = parseInt(match[1], 16);
        const g = parseInt(match[2], 16);
        const b = parseInt(match[3], 16);
        return `rgba(${r}, ${g}, ${b})`;
    }

    // HEX short format.
    match = clean_color.match(/^#([0-9a-f]{1})([0-9a-f]{1})([0-9a-f]{1})$/i);
    if (match) {
        const r = parseInt(`${match[1]}${match[1]}`, 16);
        const g = parseInt(`${match[2]}${match[2]}`, 16);
        const b = parseInt(`${match[3]}${match[3]}`, 16);
        return `rgba(${r}, ${g}, ${b})`;
    }

    return color;
}

export function classNames(...args: any) {
    const classes = [];
    for (const arg of args) {
        const argType = typeof arg;
        if (argType === "string" || argType === "number") {
            classes.push(arg);
        } else if (Array.isArray(arg)) {
            if (arg.length) {
                const inner = classNames(...arg);
                if (inner) {
                    classes.push(inner);
                }
            }
        } else if (argType === "object") {
            Object.keys(arg).forEach(key => {
                if (arg[key]) {
                    classes.push(key);
                }
            });
        } else {
            classes.push(arg.toString());
        }
    }

    return classes.join(" ");
}

export const UserDocumentationLinksEnum = {
    plants: 'plants',
    lines: 'lines',
    line_groups: 'line-groups',
    materials: 'materials',
    people: 'people',
    batchop_locations: 'batchop-locations',
    stock_locations: 'stock-locations',
    batchop_groups: 'batchop-groups'
}

export const isValueAlmostZero = (value: number): boolean => {
    /**
     * Returns true if the value is almost zero.
     * This function is used on stockForecast to avoid showing negative zero.
     */

    let isAlmostZero = false;

    if (value > 0.01 || value < -0.01) {
        isAlmostZero = false;
    }

    if (parseFloat(value).toFixed(8) === "0.00000000" || parseFloat(value).toFixed(8) === "-0.00000000") {
        isAlmostZero = true;
    }

    return isAlmostZero;
}

export const getLinkForUserDocumentation = (key: string, language: string): string => {

    /**
     * This is a temporary solution to get the correct link for the user documentation.
     * User documentation can be presented in right language, when they will be available.
     * I already have the language code, but it is not implemented yet.
     *
     *  console.log("language", language);
     */

    switch (key) {

        case "plants":
            return "https://docs.qlector.com/_pages/en/user_docs/05_manage.html#plants";

        case "line-groups":
            return "https://docs.qlector.com/_pages/en/user_docs/05_manage.html#line-groups";

        case "lines":
            return "https://docs.qlector.com/_pages/en/user_docs/05_manage.html#lines";

        case "materials":
            return "https://docs.qlector.com/_pages/en/user_docs/05_manage.html#materials";

        case "people":
            return "https://docs.qlector.com/_pages/en/user_docs/05_manage.html#people";

        case "batchop-locations":
            return "https://docs.qlector.com/_pages/en/user_docs/05_manage.html#batchop-locations";

        case "stock-locations":
            return "https://docs.qlector.com/_pages/en/user_docs/05_manage.html#stock-locations";

        case "batchop-groups":
            return "https://docs.qlector.com/_pages/en/user_docs/05_manage.html#batchop-groups";

        default:
            return "https://docs.qlector.com";

    }
}

export function arrayDiff<T>(a: T[], b: T[]): T[] {
    const set_b: Set<T> = new Set(b);
    const diff: T[] = [];
    for (const ai of a) {
        if (!set_b.has(ai)) {
            diff.push(ai);
        }
    }

    return diff;
}

export function arrayIntersection<T>(a: T[], b: T[]): T[] {
    const set_b: Set<T> = new Set(b);
    const intersection: Set<T> = new Set();
    for (const ai of a) {
        if (set_b.has(ai)) {
            intersection.add(ai);
        }
    }

    return [...intersection];
}

export const deepClone = (obj: any): any => {
    try {
        return window.structuredClone(obj);
    } catch(e) {
        console.error("structuredClone error inside Utils.deepClone: ", e.message);
        return JSON.parse(JSON.stringify(obj));
    }
}
