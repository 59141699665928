// @flow
// $FlowFixMe
import "../../../../styles/line_order_list.scss";
import * as React from "react";
import { connect } from "react-redux";

import Authorization from "../../../Authorization";
import { SortableHandle } from "react-sortable-hoc";
import * as t from "../../../../lib/backend/manufacturing2.generated.types";
import { translate } from "../../../IntlProviderWrapper";
import { niceDate, niceNumber, parseFloatSmart } from "../../../../lib/Util";
import { PERMISSION_NAMES } from "../../../../lib/Auth";
import { getLineGroupTagBool, getLineTitle } from "../../../../lib/BusinessLogic";
import { UPDATE_LINE_ORDER_ACTION_NAME } from "../reducers/linesOrders";
import {
    LinesOrdersLogic,
    ReduxFilters,
    ReportLogic,
    RecommendPlanLogic
} from "../reducers/BusinessLogic";
import TranslatedMaterialTitle from "../../../TranslatedMaterialTitle";
import { INSIGHT_DIFF_TYPE } from "../reducers/BusinessLogic";
import { LINE_GROUP_TAGS, INSIGHT_TYPES, ORDER_TAGS } from "../../../../lib/ManufacturingTags.generated";
import { PLANNING_EXPLANATION_TYPE } from "../../../../lib/ManufacturingConsts.generated";
import { SET_ADVANCED_ORDER_MENU, SET_HIGHLIGHTED_MOVE_ORDER } from "../reducers/planningTable";
import ConnectedOrderCommentTextArea from "../OrderCommentTextArea";
import { ScrollHandler } from "../EventListeners/ScrollHandler";

import type { PlanningTableFiltersState } from "../reducers/reducersFilters/filters";
import type { PlanningTableState } from "../reducers/planningTable";
import type { IOrderProducedModelEx } from "../reducers/linesOrders";
import type { ReduxState } from "../reducers/reducers";
import type { SetAdvancedOrderMenuAction } from "../reducers/planningTable";

type InsightDescriptionExtraData = {
    extra_data: any
}

const InsightDescriptionOpNoInputMaterial = (props: InsightDescriptionExtraData) => {
    const line_title = getLineTitle(props.extra_data.line_uuids[0]);

    return <React.Fragment>
            <span>
                {translate("Manufacturing.Planning.insight_operation_no_input_material", "Order not covered with material")} (
                    {line_title}, {props.extra_data.order_external_id}
                )
            </span>
            <table style={{width: "100%"}}>
                <tbody>
                    <tr>
                        <td>{translate("common.date", "Date")}</td>
                        <td>{translate("common.material", "Material")}</td>
                        <td>{translate("common.quantity", "Quantity")}</td>
                        <td>{translate("common.missing", "Missing")}</td>
                    </tr>
                    {props.extra_data.materials.map((m, idx) => (
                        <tr key={idx}>
                            <td>{niceDate(new Date(m.shift_time.shift_date))}</td>
                            <td>{m.material_title} ({m.material_external_id})</td>
                            <td>{Math.round(m.required_quantity)}</td>
                            <td>{Math.round(m.missing_quantity_external)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </React.Fragment>;
}

const InsightDescriptionOpDelayDownstream = (props: InsightDescriptionExtraData) => {
    const title = (props.extra_data.delayed_operations.length > 0 && props.extra_data.delayed_requirements.length > 0) ?
        `${translate("common.orders", "Orders")} / ${translate("common.requirements", "Requirements")}` :
        props.extra_data.delayed_operations.length > 0 ? translate("common.orders", "Orders") :
        translate("common.requirements", "Requirements");

    return <React.Fragment>
            <span>
                {translate("Manufacturing.Planning.insight_operation_delay_downstream", "Order will cause delay")}
            </span>
            <br/>
            <span>
                {props.extra_data.material_external_id} - {props.extra_data.material_title}
            </span>
            <table style={{width: "100%"}}>
                <tbody>
                    <tr>
                        <td>{translate("common.date", "Date")}</td>
                        <td>{title}</td>
                        <td>{translate("common.quantity", "Quantity")}</td>
                        <td>{translate("common.missing", "Missing")}</td>
                    </tr>
                    {props.extra_data.delayed_operations.map((o, idx) => (
                        <tr key={`o${idx}`}>
                            <td>{niceDate(new Date(o.shift_time.shift_date))}</td>
                            <td>{o.order_external_id}</td>
                            <td>{Math.round(o.required_quantity)}</td>
                            <td>{Math.round(o.missing_quantity)}</td>
                        </tr>
                    ))}
                    {props.extra_data.delayed_requirements.map((r, idx) => (
                        <tr key={`r${idx}`}>
                            <td>{niceDate(new Date(r.shift_time.shift_date))}</td>
                            <td>{r.required_external_id}</td>
                            <td>{Math.round(r.required_quantity)}</td>
                            <td>{Math.round(r.missing_quantity)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </React.Fragment>;
}

type InsightsDescriptionPropsDiffs = {
    title: string,
    insights: Map<string, t.IEventDataEx[]>,
    onClickInfo: (order_external_id: string) => (e: Event) => void,
    selected_order_uuid: string,
    onClickOrder: (order_uuid: string, line_uuids: string[]) => (e: Event) => Promise<void>
}

export const InsightsDescriptionDiffs = (props: InsightsDescriptionPropsDiffs) => {
    const renderInsight = (diff_type: string, insight: t.IEventDataEx, idx: number) => {
        const style = { };

        const is_solved = diff_type === INSIGHT_DIFF_TYPE.solved;
        if (insight.type === INSIGHT_TYPES.tool_setup) {
            style.backgroundColor = "#ecebeb"
        }

        if (insight.type === INSIGHT_TYPES.freeze_order_move) {
            style.backgroundColor = "#c9dcf9"
        }

        if (is_solved) {
            style.backgroundColor = "#70ad4721"
        }

        let line_uuids = insight.tags.line_uuids ? insight.tags.line_uuids.split(",") : [];
        const order_uuid = ReportLogic.getOrderUuidFromInsight(insight);
        const order_extra_id = ReportLogic.getOrderExternalIdFromInsight(insight);

        if (props.selected_order_uuid === order_uuid) {
            style.border = "1px solid black";
        }

        const insight_order_uuid = ReportLogic.getOrderUuidFromInsight(insight);
        return <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }} onClick={props.onClickOrder(order_uuid, line_uuids)} key={idx}>
            <div style={{ display: "flex", alignItems: "stretch", cursor: "pointer", width: "100%"}}>
                <div order-uuid={insight_order_uuid} className="planning-table-insight-description" style={style} key={insight.uuid}>
                    {(insight.type === INSIGHT_TYPES.tool_setup) ?
                        <span>{insight.tags.line_title} - {insight.title}</span> :
                    (insight.type === INSIGHT_TYPES.operation_no_input_material) ?
                        <InsightDescriptionOpNoInputMaterial extra_data={insight.extra_data} /> :
                    (insight.type === INSIGHT_TYPES.operation_delay_downstream) ?
                        <InsightDescriptionOpDelayDownstream extra_data={insight.extra_data} /> :
                        <span>{insight.tags.line_title} - {insight.title}</span>}
                </div>
                <div style={{paddingLeft: "5px", display: "flex", flexDirection: "column", justifyContent: "space-evenly"}}>
                    <span className="info-icon" onClick={props.onClickInfo(order_extra_id)}><i className="fas fa-info"></i></span>
                    {
                        is_solved ?
                        <i className="fas fa-check" style={{fontSize: "15px", color: "#70AD47"}}></i> :
                        <i className="fas fa-times" style={{fontSize: "20px", color: "#FF0000"}}></i>
                    }
                    <div style={{height: "15px"}}></div>
                </div>
            </div>
        </div>
    }

    const insights_elements = [];
    for (const [diff_type, values] of props.insights.entries()) {
        insights_elements.push(values.map((i, idx) => renderInsight(diff_type, i, idx)));
    }

    return <div className="insights-descriptions-container" style={{ marginTop: "unset" }} title={props.title}>
        {insights_elements}
    </div>
}

type InsightsDescriptionProps = {
    insights: t.IEventDataEx[]
}

export const InsightsDescription = (props: InsightsDescriptionProps) => {
    return <div className="insights-descriptions-container">
        {
            props.insights.map((i, idx) => {
                const style = { backgroundColor: "#f9c9c9" };
                if (i.type === INSIGHT_TYPES.tool_setup) {
                    style.backgroundColor = "#ecebeb"
                    return <React.Fragment key={idx}>
                            <div className="planning-table-insight-description" style={style} key={idx}>
                                <span><b>{i.title}</b></span>
                            </div>
                            {idx !== props.insights.length - 1 && <hr style={{marginTop: "unset", marginBottom: "unset"}} />}
                        </React.Fragment>;
                } else if (i.type === INSIGHT_TYPES.operation_no_input_material) {
                    style.backgroundColor = "#ffb68f"
                    return <React.Fragment key={idx}>
                            <div className="planning-table-insight-description" style={style} key={idx}>
                                <InsightDescriptionOpNoInputMaterial extra_data={i.extra_data} />
                            </div>
                            {idx !== props.insights.length - 1 && <hr style={{marginTop: "unset", marginBottom: "unset"}} />}
                        </React.Fragment>;
                } else if (i.type === INSIGHT_TYPES.operation_delay_downstream) {
                    return <React.Fragment key={idx}>
                            <div className="planning-table-insight-description" style={style} key={idx}>
                                <InsightDescriptionOpDelayDownstream extra_data={i.extra_data} />
                            </div>
                            {idx !== props.insights.length - 1 && <hr style={{marginTop: "unset", marginBottom: "unset"}} />}
                        </React.Fragment>;
                } else if (i.type === INSIGHT_TYPES.freeze_order_move) {
                    style.backgroundColor = "#c9dcf9";
                } else if (i.type === INSIGHT_TYPES.partially_scheduled) {
                    style.backgroundColor = "#dd8fff";
                }

                return <React.Fragment key={idx}>
                        <div className="planning-table-insight-description" style={style} key={idx}>
                            <span><b>{i.title}</b> {i.description}</span>
                        </div>
                        {idx !== props.insights.length - 1 && <hr style={{marginTop: "unset", marginBottom: "unset"}} />}
                    </React.Fragment>;
            })
        }
    </div>
}

type ExplanationDescriptionProps = {
    explanation: t.IPlanExplanation
}

export const ExplanationDescription = (props: ExplanationDescriptionProps) => {
    const { explanation } = props;
    const text = RecommendPlanLogic.getExplanationText(explanation);

    return (text == null) ? null : (
        <div className="planning-table-insight-description" style={{ backgroundColor: "#f7f7f7" }}>
            <span title={JSON.stringify(explanation.extra_data)}>{text}</span>
        </div>
    );
}

export type OrderObjGui = {
    capacity_factor: number,
    capacity_factor_str: string,
    capacity_overriden: boolean,
    earliest_end: string,
    earliest_start: string,
    enabled: boolean,
    skip_sim: boolean,
    external_id: string,
    has_production?: boolean,
    initial_earliest_end: string,
    initial_earliest_start: string,
    material_external_id: string,
    material_title: string,
    measurement_unit: string,
    order_type: string,
    process_uuid: string,
    quantity_total: number,
    quantity_total_str: string,
    sequence_weight: number,
    sim_ignore_earliest_start: boolean,
    uuid: string,
    process_num: string
}

const getSelectValues = (sim_ignore_earliest_start: ?boolean, earliest_start: string) => {
    return [
        {
            value: true,
            label: "24 / 7"
        },
        {
            value: false,
            label: earliest_start
        }
    ];
}


type EditButtonProps = {
    order_uuid: string,
    reduxDispatch: (args: SetAdvancedOrderMenuAction) => void
}
type EditButtonState = {};

class EditButton extends React.Component<EditButtonProps, EditButtonState> {

    onClick = (e: MouseEvent) => {
        e.stopPropagation();
        const currentTarget = e.currentTarget;
        if (currentTarget instanceof Element) {
            this.props.reduxDispatch({
                type: SET_ADVANCED_ORDER_MENU,
                data: {
                    order_uuid: this.props.order_uuid,
                    click_target: currentTarget,
                    event: e
                }
            })
        }
    }

    render() {
        return <div>
            <i className="far fa-edit" onClick={this.onClick}></i>
        </div>
    }
}

const ConnectedEditButton = connect(
    null,
    (dispatch) => ({reduxDispatch: dispatch})
)(EditButton);

type OrderDetailsProps = {
    row_class: string,
    index: number,
    selected_order: OrderObjGui,
    handleChange: Function,
    handleSelectChange: Function,
    line_uuid: string,
    insights: t.IEventDataEx[],
    explanation?: t.IPlanExplanation,
    line_group_uuid: string,
    is_unscheduled: boolean,
    is_readonly?: boolean
}
/*
    Please read - to enable edit on a new editable component, please add a new condition inside onDragOrder
*/
const OrderDetailsCell = (props: OrderDetailsProps) => {
    const { handleSelectChange, selected_order, insights, explanation, line_group_uuid } = props;

    const select_values = getSelectValues(selected_order.sim_ignore_earliest_start, selected_order.earliest_start);
    const resolved_explanation = explanation ? explanation : {
        operation_uuid : selected_order.uuid,
        type: PLANNING_EXPLANATION_TYPE.not_planned,
        possible_prod_vers: [],
        extra_data: { }
    };
    const show_explanation = getLineGroupTagBool(line_group_uuid, LINE_GROUP_TAGS.automatic_scheduling, false);
    return <div className="editable-cell" onClick={(event) => event.stopPropagation()}>
        {props.is_unscheduled && <div className="editable-cell__from">
            {translate("OrderTableProduction.earliest_start", "ERP start")} : {selected_order.earliest_start}
        </div>}
        {!props.is_unscheduled && <div className="editable-cell__from">
            <span className="label">{translate("Manufacturing.Planning.from", "From")}:</span> <br />
            <div className="value">
                <Authorization.Select
                    permission={PERMISSION_NAMES.OrdersAndShiftsEdit}
                    name="sim_ignore_earliest_start"
                    value={select_values.find(el => el.value === selected_order.sim_ignore_earliest_start)}
                    onChange={(object) => { handleSelectChange(object); }}
                    options={getSelectValues(selected_order.sim_ignore_earliest_start, selected_order.earliest_start)}
                    inputId={"earliest_start_select"}
                    disabled={props.is_readonly === true}
                />
            </div>
        </div>}
        <InsightsDescription insights={insights} />
        {show_explanation && <ExplanationDescription explanation={resolved_explanation} />}
    </div>
}

const SortHandle = SortableHandle(() => {
    return <div id="bars-icon-container" style={{width: "20px", cursor: "pointer", userSelect: "none"}}>
        <i
            id="bars-icon"
            className="fas fa-sort"
            title={translate("common.tooltip_planning_table_moveorder", "Move order")}
        ></i>
    </div>
});

type Props = {
    sortIndex: number,
    handleChange: any => any,
    order_uuid: string,
    order: IOrderProducedModelEx,
    line_uuid: string,
    reduxDispatch: any => any,
    enabled_edit?: boolean,
    is_unscheduled: boolean,
    show_unscheduled_orders: boolean,
    insights: t.IEventDataEx[],
    is_rescheduled: boolean,
    plant_uuid: string,
    explanation?: t.IPlanExplanation,
    line_group_uuid: string,
    is_readonly?: boolean,
    filters: PlanningTableFiltersState,
    planning_table: PlanningTableState,
    is_translucent: boolean,
    is_filter_locked: boolean,
    quantity_total: number
}

type State = {
    order?: OrderObjGui
};

type FilterInsightsArgs = {
    include?: string[],
    exclude?: string[],
    insights: t.IEventDataEx[]
}

class Order extends React.Component<Props, State> {

    myRef;

    constructor(props: Props) {
        super(props);
        this.state = { };
        this.myRef = React.createRef();
    }

    highlightTimeout: TimeoutID;

    componentDidMount() {
        this.updateOrder();
    }

    componentDidUpdate(prev_props: Props) {
        if (prev_props.quantity_total !== this.props.quantity_total) {
            this.updateOrder();
            return;
        }
        if (prev_props.is_rescheduled !== this.props.is_rescheduled) {
            const is_locked = ReduxFilters.isLineLocked();
            const ref = this.myRef.current;
            if (ref && is_locked && this.props.is_rescheduled) {
                setTimeout(
                    () => {
                        ref.scrollIntoView({ block: "center" })
                    },
                    200
                )
                return;
            }
        }

        if (prev_props.is_rescheduled !== this.props.is_rescheduled && this.props.is_rescheduled) {
            if (this.myRef.current) {
                this.myRef.current.scrollIntoView({ block: "center" })
                return;
            }
        }

        if (prev_props.order != undefined && this.props.order != undefined) {
            if (prev_props.order.uuid !== this.props.order.uuid) {
                this.updateOrder();
            }
        } else if (prev_props.order != undefined || this.props.order != undefined) {
            this.updateOrder();
        }
    }

    updateOrder = () => {
        if (this.props.order != undefined) {
            const order = this.createOrderObjGui(this.props.order);
            this.setState({ order });
        } else {
            this.setState({ order: undefined });
        }
    }

    onClick = async (e: Event) => {
        e.preventDefault();
        e.stopPropagation();

        if (!this.props.is_rescheduled) {
            ReduxFilters.setRescheduleOrderUuid(this.props.reduxDispatch,
                this.props.order.uuid, "line_order_list", this.props.is_filter_locked);
        }
        ScrollHandler.scrollToOrder(this.props.order.uuid);
    }

    handleSelectChange = (earliest_start: Object) => {
        const order = this.props.order;
        const sim_ignore_earliest_start = earliest_start.value;

        if (order.sim_ignore_earliest_start !== sim_ignore_earliest_start) {
            order.sim_ignore_earliest_start = sim_ignore_earliest_start;

            this.props.reduxDispatch({
                type: UPDATE_LINE_ORDER_ACTION_NAME,
                data: {
                    line_uuid: this.props.line_uuid,
                    order_uuid: this.props.order_uuid,
                    order
                }
            });
        }
    }

    handleChange = (uuid: string, event: Event, earliest_start: Object) => {
        const order = this.props.order;

        let target = event.target;

        if (!(target instanceof HTMLSelectElement || target instanceof HTMLInputElement)) return;

        const name = target.name;

        if (name === "capacity_factor") {
            order.capacity_factor = parseFloatSmart(target.value, order.capacity_factor) / 100;
        }
        if (name === "quantity") {
            order.quantity_total = parseFloat(target.value);
        }
        if (target instanceof HTMLInputElement && name === "skip_sim") {
            order.skip_sim = target.checked;
        }

        if (order.capacity_factor !== target.value) {
            this.props.reduxDispatch({
                type: UPDATE_LINE_ORDER_ACTION_NAME,
                data: {
                    line_uuid: this.props.line_uuid,
                    order_uuid: uuid,
                    order
                }
            });
        }
    }

    createOrderObjGui(input: IOrderProducedModelEx): OrderObjGui {
        const digits = input.measurement_unit === "KG" ? 3 : 0;

        return {
            capacity_factor: input.override_capacity_factor || input.capacity_factor,
            capacity_factor_str: niceNumber(100 * (input.override_capacity_factor || input.capacity_factor), 0),
            capacity_overriden: (input.override_capacity_factor !== null),
            earliest_end: niceDate(new Date(input.earliest_end)),
            earliest_start: niceDate(new Date(input.earliest_start)),
            enabled: !input.skip_sim,
            skip_sim: input.skip_sim,
            external_id: input.external_id,
            has_production: input.has_production,
            initial_earliest_end: niceDate(new Date(input.initial_earliest_end)),
            initial_earliest_start: niceDate(new Date(input.initial_earliest_start)),
            material_external_id: input.material_external_id,
            material_title: input.material_title,
            measurement_unit: input.measurement_unit && input.measurement_unit.trim().toUpperCase(),
            order_type: input.order_type,
            process_uuid: input.process_uuid,
            quantity_total: input.quantity_total,
            quantity_total_str: niceNumber(input.quantity_total, digits),
            sequence_weight: input.sequence_weight,
            sim_ignore_earliest_start: input.sim_ignore_earliest_start,
            uuid: input.uuid,
            process_num: input.process_num
        };
    }

    getIsEditable = () => {
        return this.props.enabled_edit && !this.props.show_unscheduled_orders;
    }

    filterInsights = (args: FilterInsightsArgs): t.IEventDataEx[] => {
        const { include, exclude, insights } = args;

        if (include && include.length > 0) {
            return insights.filter(i => include.includes(i.type))
        }
        if (exclude && exclude.length > 0) {
            return insights.filter(i => !exclude.includes(i.type));
        }

        return insights;
    }

    getFrozen = (order: IOrderProducedModelEx) => {
        if (!order) {
            return null;
        }
        const is_frozen = order.tags[ORDER_TAGS.insight_freeze_order] === "true";

        if (is_frozen) {
            return <i className="fas fa-lock" style={{marginRight: "2px"}}></i>;
        }

        return null;
    }

    getFixedLine = (order: IOrderProducedModelEx) => {
        if (!order) {
            return null;
        }

        const is_fixed_line = order.tags[ORDER_TAGS.fix_line] !== undefined;

        if (is_fixed_line) {
            return <i className="fas fa-thumbtack" style={{marginRight: "2px"}}></i>;
        }

        return null;
    }

    onClickHighlight = (e: Event) => {
        ScrollHandler.scrollToOrder(this.props.order.uuid);
        this.props.reduxDispatch({
            type: SET_HIGHLIGHTED_MOVE_ORDER,
            data: this.props.order.uuid
        })
        e.preventDefault();
        e.stopPropagation();
    }

    render() {
        // unscheduled orders that jump to lines orders are no longer existent
        const order = this.state.order;
        const external_id = order ? order.external_id : "";
        const material_external_id = order ? order.material_external_id : "";
        const quantity_total_str = order ? order.quantity_total_str : "";
        const measurement_unit = order ? order.measurement_unit : "";
        const enabled = order ? order.enabled: false;
        const process_num = order ? order.process_num: 0;
        const material_title = order ? order.material_title : "";
        const order_uuid = order ? order.uuid : "";
        const comment = this.props.order ? this.props.order.tags[ORDER_TAGS.comment] : "";
        const parent_external_id = this.props.order ? this.props.order.tags[ORDER_TAGS.external_parent_order_id] : "";

        const { sortIndex } = this.props;

        const row_class = !enabled ? "table-inactive" : "";

        const other_insights = this.filterInsights({ exclude: ["tool_setup"], insights: this.props.insights });

        let backgroundColor = other_insights.length > 0 ? "#FEF3F2" : "unset"
        let border = {};
        if (ReduxFilters.isLineLocked() && this.props.is_rescheduled) {
            if (comment) {
                border = {
                    borderBottom: "none",
                    borderTop: "1px solid black",
                    borderLeft: "1px solid black",
                    borderRight: "1px solid black",
                };
            }
        }

        const has_tool_setup_insights = this.filterInsights({include: ["tool_setup"], insights: this.props.insights}).length > 0;
        const className = this.props.is_rescheduled ? "line-order-list-selected-order-border" : "";
        const materialStyle = {};
        if (this.props.is_unscheduled) {
            materialStyle.width = "100%";
        }

        const is_readonly = this.props.is_readonly;

        let opacity = this.props.is_translucent ? 0.4 : 1;

        return (
            <React.Fragment>
                <tr id="order-row" className={className} ref={this.myRef}
                    key={`item-${sortIndex}-x`} style={{backgroundColor, opacity, cursor: "pointer", ...border}} onClick={this.onClick}>
                    {!this.props.is_unscheduled && <td className="sortable-element__advanced-menu"></td>}
                    <td className="sortable-element__workorder">
                        <div id="lol-order" style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                            <i id="bullseye" className="fas fa-bullseye" onClick={this.onClickHighlight} style={{marginRight: "5px"}}></i>
                            <strong style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                {this.getFixedLine(this.props.order)} {this.getFrozen(this.props.order)} {external_id} { parent_external_id ? `(P${parent_external_id})` : ""} / {process_num}
                            </strong>
                        </div>
                    </td>
                    <td style={materialStyle} className="sortable-element__material">
                        <strong>{material_external_id}</strong> -
                        <div style={{minWidth: "inherit"}}>
                            {TranslatedMaterialTitle({material_external_id, material_title: material_title})}
                        </div>
                    </td>
                    <td className="sortable-element__unit">
                        {quantity_total_str} {measurement_unit}
                    </td>
                     <td className="sortable-element__moveorder">
                        <div style={{minWidth: "40px", display: "flex", justifyContent: "center"}}>
                            {!this.props.is_unscheduled && <div style={{width: "20px"}}>
                                {has_tool_setup_insights && <i
                                    className="fas fa-tools"
                                    style={{marginRight: "5px", marginLeft: "5px"}}
                                    title={translate("common.tooltip_tool_change", "Tool change")}>
                                </i>}
                            </div>}
                            {(!this.props.is_unscheduled && !is_readonly) && <SortHandle />}
                            {!is_readonly && <ConnectedEditButton order_uuid={order_uuid} />}
                        </div>
                    </td>
                </tr>
                {comment ? <ConnectedOrderCommentTextArea comment={comment} order_uuid={order_uuid} /> : null}
                {this.props.is_rescheduled && order && <tr id="order-row-detailed" className={className} key={`item-${sortIndex}-y`} style={{backgroundColor}} onClick={this.onClick}>
                    <td colSpan="5">
                        <OrderDetailsCell
                            key={"editable-cell-" + sortIndex}
                            row_class={row_class}
                            index={sortIndex}
                            selected_order={order}
                            handleSelectChange={this.handleSelectChange}
                            handleChange={this.handleChange}
                            line_uuid={this.props.line_uuid}
                            insights={this.props.insights}
                            explanation={this.props.explanation}
                            line_group_uuid={this.props.line_group_uuid}
                            is_unscheduled={this.props.is_unscheduled}
                            is_readonly={is_readonly}
                        />
                    </td>
                </tr>}
            </React.Fragment>
        )
    }
}

// $FlowFixMe
Order.defaultProps = {
    enabled_edit: true
}

const ConnectedOrder = connect(
    (state: ReduxState, own_props: Props) => {
        const planning_table = state.gantt_chart_planning_table;
        const insights_mapped = state.gantt_chart_insights.insights_mapped;
        const filters = state.gantt_chart_filters;
        const lines_orders = state.gantt_chart_lines_orders.lines_orders;
        const unscheduled_lines_orders = state.gantt_chart_lines_orders.unscheduled_orders;
        const line_group_uuid = state.gantt_chart_properties.line_group_uuid;
        let is_readonly = false;

        let order;

        if (own_props.is_unscheduled) {
            for (const line_orders of unscheduled_lines_orders) {
                order = line_orders.orders.find(o => o.uuid === own_props.order_uuid);
                if (order) break;
            }
        } else {
            const line = lines_orders.find(lo => lo.line_uuid === own_props.line_uuid);
            if (line) {
                order = line.orders.find(o => o.uuid === own_props.order_uuid);
                if (order) {
                    is_readonly = LinesOrdersLogic.isOrderReadOnly(line, order);
                }
            }
        }

        let order_insights = [];

        if (insights_mapped !== null) {
            order_insights = insights_mapped.get(own_props.order_uuid) || [];
            order_insights.sort((i1, i2) =>
                (i1.type === INSIGHT_TYPES.tool_setup ? -1 :
                i2.type === INSIGHT_TYPES.tool_setup ? 1 :
                i1.ts - i2.ts));
        }

        const explanation = state.gantt_chart_planning_table.recommend_explanations.length === 0 ?
            {
                operation_uuid : (order ? order.uuid : ""),
                type: PLANNING_EXPLANATION_TYPE.no_automatic_planning,
                extra_data: { }
            } : state.gantt_chart_planning_table.recommend_explanations.find(
                e => e.operation_uuid === (order ? order.uuid : ""));

        const is_rescheduled = own_props.order_uuid === planning_table.last_clicked_order_uuid

        let is_translucent = false;
        if (planning_table.show_valid_lines.valid_orders_map !== null) {
            const valid_order = planning_table.show_valid_lines.valid_orders_map.get(own_props.order_uuid);
            if (own_props.is_unscheduled) {
                is_translucent = valid_order === undefined;
            } else if (valid_order) {
                is_translucent = valid_order.is_translucent;
            }
        }

        const is_filter_locked = filters.is_filter_locked;

        return {
            should_scroll: false, // TODO
            order,
            insights: order_insights,
            show_unscheduled_orders: planning_table.show_unscheduled_orders,
            is_rescheduled: is_rescheduled,
            explanation,
            line_group_uuid,
            is_readonly,
            filters,
            planning_table,
            updated_order_uuid: state.gantt_chart_lines_orders.updated_order_uuid,
            is_translucent,
            is_filter_locked,
            quantity_total: order ? order.quantity_total : 0
        }
    },
    (dispatch) => ({ reduxDispatch: dispatch })
)(Order);

export default ConnectedOrder;
