// @flow
import React from "react";
import ReactRouterPropTypes from "react-router-prop-types";
import ReactTable from "../react/ReactTable";
import { FormattedMessage } from "react-intl";

import {
    rowStyle,
    mapData,
    mapUnknownData,
    Columns,
    onChangeUpdateTags,
    getInitialState
} from "../react/ReactTableTags";
import EntityEditTemplate from "../EntityEditTemplate";
import { MATERIAL_TAGS } from "../../lib/ManufacturingTagsEditor.generated";
import { getBackend as getBackend2 } from "../../lib/backend/Backend2";
import { translate } from "../IntlProviderWrapper";
import type { IUpdateMaterialReq } from "../../lib/backend/manufacturing2.generated.types";
import type { ITags } from "../../lib/Models";

import ErrorComponent from "../ErrorComponent";

import { getLinkForUserDocumentation, UserDocumentationLinksEnum } from "../../lib/Util";
import { getLang } from "../IntlProviderWrapper";

type Props = {
    match: ReactRouterPropTypes.match,
    history: ReactRouterPropTypes.history,
    location: ReactRouterPropTypes.location
};

type State = {
    tags: ITags,
    title: string,
    error: string | null
};

class MaterialEdit extends React.Component<Props, State> {

    state = {
        error: null,
        tags: {},
        title: ""
    };

    componentDidMount() {
        this.load();
    }

    getMaterialUuid = () => {
        return this.props.match.params.uuid;
    }

    load = async () => {
        try {
            this.setState({ error: null });
            const materials = await getBackend2().manufacturing.searchMaterials({
                material_uuid: this.getMaterialUuid()
            });

            if (materials.data.length > 1) throw Error();

            const material_data = materials.data[0];

            this.setState({
                title: material_data.title,
                tags: material_data.tags
            });
        } catch (e) {
            this.setState({
                error: translate("common.error", "An error occured while fetching data.")
            });
        }
    }

    onChangeCell = (name: string) => (value: boolean) => {
        this.setState({ tags: onChangeUpdateTags(this.state.tags, name, value) });
    }

    renderError = () => {
        if (this.state.error) {
            return (<ErrorComponent msg={this.state.error} type="error" />);
        }
        return null;
    }

    onBack = () => {
        this.props.history.goBack();
    }

    onSave = async () => {
        try {
            const uuid = this.getMaterialUuid();

            if (uuid) {
                let material_params: IUpdateMaterialReq = {
                    uuid,
                    tags: this.state.tags
                };

                await getBackend2().manufacturing.updateMaterial(material_params);
                this.props.history.goBack();
            }
        } catch (e) {
            console.log(e);
            this.setState({
                error: translate("common.error_save", "An error occured while saving data.")
            });
        }
    }

    render() {
        const data_std = mapData(this.state.tags, MATERIAL_TAGS);
        const data_nonstd = mapUnknownData(this.state.tags, MATERIAL_TAGS);
        const show_nonstd = (data_nonstd.length > 0);
        return (
            <div className="material-edit-container">
                <EntityEditTemplate onSave={this.onSave} title={this.state.title} onBack={this.onBack}>
                    {this.renderError()}
                    <h4><FormattedMessage id="common.standard_data" defaultMessage="Standard data" /></h4>
                    <a href={getLinkForUserDocumentation(UserDocumentationLinksEnum.materials, getLang())}
                        target="_blank"
                        style={{ position: 'relative', float: 'right', paddingTop: '30px' }}>
                        <img src={"/img/question_light.svg"} style={{ paddingRight: "3px", paddingBottom: "2px" }} />
                        <FormattedMessage id="Header.menu.user_documentation" defaultMessage="User documentation" />
                        <img src={"/img/arrow_up_right_corner.svg"} style={{ position: "relative", width: "15px", height: "15px", paddingLeft: "3px", paddingBottom: "4px", bottom: "3px" }} />
                    </a>
                    <ReactTable
                        className="tags-react-table"
                        data={data_std}
                        onChangeCell={this.onChangeCell}
                        rowStyle={rowStyle}
                        columns={Columns}
                        initialState={getInitialState()}
                    />
                    {show_nonstd && <h4><FormattedMessage id="common.nonstandard_data" defaultMessage="Non-standard data" /></h4>}
                    {show_nonstd && <ReactTable
                        className="tags-react-table"
                        data={data_nonstd}
                        onChangeCell={this.onChangeCell}
                        rowStyle={rowStyle}
                        columns={Columns}
                        initialState={getInitialState()}
                    />}
                </EntityEditTemplate>
            </div>
        )
    }
}


export default MaterialEdit;
