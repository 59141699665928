// @flow
// $FlowFixMe
import React, { useState, useEffect } from "react";
import { isNumber } from "../../lib/Util";
import { translate } from "../IntlProviderWrapper";
import type { ITags } from "../../lib/Models";
import type { ReactTableColumn } from "./ReactTable";
import { PERMISSION_NAMES } from "../../lib/Auth";
import Authorization from "../Authorization";

type TAGS_TYPES = "boolean" | "string" | "number" | "enum";
type TAG_VALUES = boolean | string | number;

type EnumTagOption = {
    name: string
}
type TagData = {
    name: string,
    title?: string,
    type: TAGS_TYPES,
    comment?: string,
    value?: TAG_VALUES,
    validate?: any => void,
    options?: EnumTagOption[],
    editable?: boolean
}
type TagsData = {
    title: string,
    comment: string,
    key: string,
    tag: TagData,
    default: TagData
}
export type TagConfig = {
    name: string,
    title: string,
    comment: string,
    type: TAGS_TYPES,
    value?: TAG_VALUES,
    validate?: any => any,
    values?: EnumTagOption[],
    editable?: boolean
}

type TagConfigs = {
    name: "LINE_GROUP_TAGS" | "STOCK_LOCATION_TAGS" | "MATERIAL_TAGS" | "LINE_TAGS" | "PEOPLE_TAGS",
    values: TagConfig[]
}

type onChangeType = {
    onChange: any => void
}
type NumberCellState = {
    error?: string,
    value: TAG_VALUES
}

type TagsConfig = {
    values: TagData[]
}

export type AdditionalConfig = {
    [key: string]: $Shape<TagConfig>
}

type ButtonSetDefaultType = {
    type: string,
    value: ?TAG_VALUES,
    editable: ?boolean,
    name: string
}

export const getInitialState = () => ({
    sortBy: [{
        id: 'key'
    }]
});

const getPermissions = (value: ?TAG_VALUES, editable: ?boolean) => {
    let permissions = PERMISSION_NAMES.Everyone;

    if (editable === false) {
        permissions = PERMISSION_NAMES.AdminOnly;
    }

    return permissions;
}

const ButtonSetDefault = ({ type, onChange, value, editable, name }: onChangeType & ButtonSetDefaultType) => {
    const is_default_set = value === undefined;
    let permissions = getPermissions(value, editable);

    if (is_default_set) {
        permissions = PERMISSION_NAMES.None;
    }

    return <div style={{ display: "flex", flexDirection: "column", width: "fit-content", marginTop: "15px"}}>
        <Authorization.button
            permission={permissions}
            className={"btn btn-primary"}
            onClick={() => onChange(undefined)}>
            {translate("common.set_default", "Set default")}
        </Authorization.button>
        <span style={{ height: "10px", marginTop: "5px" }}>{is_default_set && translate("common.default_is_set", "Default is set") }</span>
    </div>
}

const BooleanCell = ({ value, name, onChange, editable }: onChangeType & TagData) => {
    if (!value) {
        value = undefined;
    } else {
        if (typeof value === "string") value = value === "true";
    }

    return <Authorization.Toggle
        checked={value}
        permission={getPermissions(value, editable)}
        onChange={(event: Event) => {
            if (event.currentTarget instanceof HTMLInputElement) {
                onChange(event.currentTarget.checked);
            }
        }}
    />;
}

const NumberCell = (props: onChangeType & TagData) => {
    const { value, onChange, validate, editable } = props;
    const [state, setState]: [NumberCellState, any] = useState({ error: null, value: value });

    useEffect(() => {
        setState((prevState: NumberCellState) => ({...prevState, value}))
    }, [props]);

    const validateNumber = (e: Event) => {
        if (!(e.currentTarget instanceof HTMLInputElement)) return;

        const target_value = e.currentTarget.value;
        const newValue = Number(target_value && /\d/.test(target_value) ? target_value : 0);

        let error = null;

        if (!isNumber(newValue)) {
            error = translate("common.error_number", "Invalid number");
        }

        if (validate && !error) {
            error = validate(newValue);
        }

        if (error) {
            return setState((prevState: NumberCellState) => ({ ...prevState, error }));
        }

        setState({ error: null, value: newValue });

        return onChange(newValue);
    }

    const validateIntermediateNumber = (e: Event) => {
        if (!(e.currentTarget instanceof HTMLInputElement)) return;

        const new_value = e.currentTarget.value;
        const number_reg_ex = /^\-?\d*\.?\d*$/;
        let error = null;

        if (!number_reg_ex.test(e.currentTarget.value)) {
            error = translate("common.error_number", "Invalid number");
        }

        if (error) {
            return setState((prev_state: NumberCellState) => ({ ...prev_state, error }));
        }

        setState({ error: null, value: new_value });

        return onChange(new_value);
    }

    return <React.Fragment>
        { state.error ? <span> {state.error} </span> : null }
        <Authorization.input
            permission={getPermissions(value, editable)}
            type="text"
            className="form-control"
            value={state.value}
            onChange={validateIntermediateNumber}
            onBlur={validateNumber}
        />
    </React.Fragment>
}


type StringCellState = {
    value: string,
    error?: string
}

const StringCell = (props: onChangeType & TagData) => {
    const { onChange, validate, editable, value } = props;
    const [state, setState]: [StringCellState, any] = useState({ value: undefined });

    useEffect(() => {
        setState((prevState: StringCellState) => ({...prevState, value: props.value}))
    }, [props]);

    const onInputChange = (event: Event) => {
        if (!(event.currentTarget instanceof HTMLInputElement)) return;
        const newValue = event.currentTarget.value;

        if (validate) {
            const error = validate(newValue);
            if (error) {
                return setState((prevState) => ({ ...prevState, error }));
            }
        }

        setState((prevState: StringCellState) => {
            onChange(newValue);

            return { ...prevState, value: newValue }
        });
    }

    return (
        <Authorization.input
            permission={getPermissions(value, editable)}
            type="text"
            className="form-control"
            value={state.value}
            onChange={onInputChange}
        />
    );
}

type reactTableTagsCellType = {
    cell: {
        value: TagData
    },
    onChangeCell: any => any
}

type SelectCellProps = {
    value: string | null,
    name: string,
    onChange: any => any,
    validate?: any => boolean,
    options: EnumTagOption[],
    editable?: boolean
}

const SelectCell = (props: SelectCellProps) => {
    const { value, name, editable } = props;

    const options = props.options.map(v => ({label: v.name, value: v.name}));
    let option = null;

    if (value) {
        option = {label: value, value};
    }

    return <Authorization.Select
        permission={getPermissions(value, editable)}
        name={name}
        value={option}
        options={options}
        onChange={props.onChange}
    />
}

export const reactTableButtonDefaultCell = (props: reactTableTagsCellType) => {
    const { type, value, name, editable }: TagData = props.cell.value;

    return <ButtonSetDefault
        onChange={props.onChangeCell(name)}
        type={type}
        value={value}
        editable={editable}
        name={name}
    />
}

type EditButtonType = {
    value?: TAG_VALUES,
    name: string,
    type: string,
    editable?: boolean,
    options?: EnumTagOption[]
}

const EditButton = (props: onChangeType & EditButtonType) => {
    const { value, editable, onChange, type, options } = props;

    let non_default_value = undefined;

    switch(type) {
        case "boolean":
            non_default_value = false;
            break;
        case "number":
            non_default_value = 0;
            break;
        case "string":
            non_default_value = "";
            break;
        case "enum":
            if (options) {
                non_default_value = options[0].name;
            }
            break;
        default:
            break;
    }

    return <Authorization.button
        permission={getPermissions(value, editable)}
        className={"btn btn-primary"}
        onClick={() => onChange(non_default_value)}>
        {translate("common.edit", "Edit")}
    </Authorization.button>
}

export const reactTableTagsCell = (props: reactTableTagsCellType) => {
    const { type, value, name, validate, options, editable }: TagData  = props.cell.value;

    let component = null;

    if (value === undefined) {
        return <EditButton
            type={type}
            name={name}
            value={value}
            options={options}
            editable={editable}
            onChange={props.onChangeCell(name)}
        />
    }

    if (type === "boolean") {
        component = <BooleanCell
            type={type}
            value={value}
            onChange={props.onChangeCell(name)}
            name={name}
            validate={validate}
            editable={editable}
        />
    }

    if (type === "number") {
        component = <NumberCell
            type={type}
            value={value}
            onChange={props.onChangeCell(name)}
            name={name}
            validate={validate}
            editable={editable}
        />
    }

    if (type === "string") {
        component = <StringCell
            type={type}
            value={value}
            onChange={props.onChangeCell(name)}
            name={name}
            validate={validate}
            editable={editable}
        />
    }

    if (type === "enum") {
        component = <SelectCell
            name={name}
            value={(value && value.toString()) || null}
            options={options || []}
            onChange={props.onChangeCell(name)}
            editable={editable}
        />
    }

    return component;
}

export const updateTagsConfig = (tags_config: TagsConfig, additional_config: AdditionalConfig): any  => {
    tags_config.values = tags_config.values.map(
        el => {
            if (additional_config[el.name]) {
                return {
                    ...el,
                    ...additional_config[el.name]
                }
            }

            return el;
        }
    );

    return tags_config;
}

export const rowStyle = (row: any) => {
    let style = {};

    let mandatoryCell = row.cells.filter(cell => cell.column.id === "mandatory");

    if (mandatoryCell.length > 0) {
        mandatoryCell = mandatoryCell[0];

        if (mandatoryCell.column.id === "mandatory") {
            style["backgroundColor"] = "white";
        }

        if (mandatoryCell.value.value !== true && mandatoryCell.row.values.tag &&
            mandatoryCell.row.values.tag.value === undefined) {
            style["backgroundColor"] = "#eaeaea";
        }
    }

    return style;
}

export const Columns: ReactTableColumn<TagsData>[] = [
    {
        Header: "",
        accessor: "key",
        disableFilters: true
    },
    {
        Header: "",
        accessor: "title",
        disableFilters: true
    },
    {
        Header: "",
        accessor: "comment",
        disableFilters: true
    },
    {
        Header: "",
        accessor: "tag",
        disableFilters: true,
        Cell: reactTableTagsCell
    },
    {
        Header: "",
        accessor: "default",
        disableFilters: true,
        Cell: reactTableButtonDefaultCell
    }
];

export const mapData = (tags_data: ITags, tags_config: TagConfigs): TagsData[] => {
    const data: TagsData[] = [];

    for (const tag of tags_config.values) {
        let value = undefined;
        const tag_name = tag.name;

        if (tags_data[tag_name] !== undefined) {
            value = tags_data[tag_name];
        }

        const group_name = tags_config.name;
        const comment = translate(`tag_groups.${group_name}.values.comment.${tag_name}`, `Missing`);
        const title = translate(`tag_groups.${group_name}.values.title.${tag_name}`, `Missing`);

        const tagObj: TagData = {
            name: tag_name,
            type: tag.type,
            value: value,
            validate: tag.validate,
            options: tag.values,
            editable: tag.editable
        };
        data.push({
            comment: comment,
            title: title,
            key: tag_name,
            tag: tagObj,
            default: tagObj
        });
    }
    return data;
}

/** Given configuration of known tags, collect the configuration for the unknown ones and 
 * treat them as strings.
 */
export const mapUnknownData = (tags_data: ITags, tags_config: TagConfigs): TagsData[] => {
    const known_tags = tags_config.values.map(x => x.name);
    const data: TagsData[] = [];
    for (const tag_name of Object.keys(tags_data)) {
        if (known_tags.indexOf(tag_name) >= 0) {
            // this is a known tag, just skip it
            continue;
        }
        const comment = "";
        const title = tag_name.replace(/_/gi, " ");
        const tagObj: TagData = {
            name: tag_name,
            type: "string",
            value: tags_data[tag_name],
            editable: true
        };

        data.push({
            comment: comment,
            title: title,
            key: tag_name,
            tag: tagObj,
            default: tagObj
        });
    }
    return data;
}

type ReactSelectType = { label: string, value: any };

type ValueTypes = TAG_VALUES | ReactSelectType | ReactSelectType[];

export const onChangeUpdateTags = (tags: ITags, name: string, value: ?ValueTypes) => {
    const new_tags = { ...tags };

    if (value === undefined) {
        delete new_tags[name];
    } else if (typeof value === "object") {
        // select option
        // $FlowFixMe
        if (value.length > 0) {
            // $FlowFixMe
            new_tags[name] = value[0].value;
        } else {
            // $FlowFixMe
            new_tags[name] = value.value;
        }
    } else {
        new_tags[name] = value;
    }

    return new_tags;
}
