// @flow
import React from "react";
import ModalTabs from "../react/ModalTabs/ModalTabs";
import * as t from "../../lib/backend/manufacturing2.generated.types";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
// import * as Auth from "../../lib/Auth";
import { getBackend } from "../../lib/backend/Backend2";
import { translate } from "../IntlProviderWrapper";
import { ORDER_STATUS } from "../../lib/ManufacturingConsts.generated";
import SimpleTable from "../react/SimpleTable";
import { niceNumber } from "../../lib/Util";

/************************************************************************************************ */

type OrdersProps = {
    operations: t.IOrderModelBase[]
};

const Orders = (props: OrdersProps) => {

    const header = [
        translate("common.order", "Order"),
        translate("common.process_num", "Process number"),
        translate("common.quantity", "Quantity"),
        translate("common.line", "Line")
    ];
    const data: string[][] = props.operations.map(x => [
        x.external_id,
        x.process_num,
        niceNumber(x.quantity_total, 0),
        x.line.external_id + " " + x.line.title
    ]);

    return <SimpleTable data={data} header={header} />;
}

/************************************************************************************************ */

type MaterialInfoModalProps = {
    material_external_id: string,
    show: boolean,
    onShowOrHideOrderModal: Function
}

type MaterialInfoModalState = {
    material_data: t.IMaterialModel | null,
    operations_open: t.IOrderModelBase[],
    operations_closed: t.IOrderModelBase[]
}

class MaterialInfoModal extends React.Component<MaterialInfoModalProps, MaterialInfoModalState> {

    state: MaterialInfoModalState = {
        material_data: null,
        operations_open: [],
        operations_closed: []
    }

    componentDidMount() {
        // if (this.props.material_uuid != this.state.material_uuid) {
        //     this.load();
        // }
    }

    componentDidUpdate(prev_props: MaterialInfoModalProps) {
        if (prev_props.material_external_id !== this.props.material_external_id) {
            this.load();
        }
    }

    componentWillUnmount() {
        this.setState({
            material_data: null
        });
    }

    load = async () => {

        const res = await getBackend().manufacturing.searchMaterialsSimple({
            external_id: this.props.material_external_id
        });
        const material_data = res.data[0] ?? null;
        this.setState({ material_data });
        let operations_open: t.IOrderModelBase[] = [];
        let operations_closed: t.IOrderModelBase[] = [];
        if (material_data != null) {
            const res2 = await getBackend().manufacturing.getOrdersSimple({
                material_uuids: [material_data.uuid],
                status: ORDER_STATUS.open
            });
            const res3 = await getBackend().manufacturing.getOrdersSimple({
                material_uuids: [material_data.uuid],
                status: ORDER_STATUS.closed
            });
            operations_open = res2.operations
                .sort((a, b) => a.earliest_end - b.earliest_end);
            operations_closed = res3.operations
                .sort((a, b) => b.earliest_end - a.earliest_end)
                .slice(0, 20);
            this.setState({ operations_open, operations_closed });
        } else {
            this.setState({ operations_open: [], operations_closed: [] });
        }
    }


    render() {
        if (this.state.material_data == null) return null;

        // const is_admin = Auth.isInRole(Auth.ROLE_ADMIN);

        const tabs = [
            {
                title: translate("MaterialInfoModal.open_orders", "Open orders"),
                element: <div>
                    <Orders operations={this.state.operations_open} />
                </div>
            },
            {
                title: translate("MaterialInfoModal.latest_closed_orders", "Latest closed orders"),
                element: <div>
                    <Orders operations={this.state.operations_closed} />
                </div>
            }
        ];
        const title = (this.state.material_data ? this.state.material_data.title : "");
        return <React.Fragment>
            <Modal className="order-table-production-modal"
                show={this.props.show}
                bsSize={"large"}
                onHide={() => this.props.onShowOrHideOrderModal()}
            >
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id="common.material" defaultMessage="Material" />
                    </Modal.Title>

                    <button type="button" className="close" onClick={() => this.props.onShowOrHideOrderModal()}>
                        <span>×</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    {this.state.material_data && <ModalTabs
                        details1={<div><h1>
                            {translate("common.material", "Material")} {title} {this.props.material_external_id}
                        </h1></div>}
                        tabs={tabs}
                    />}

                </Modal.Body>
                <Modal.Footer>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                        <div>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    }
}

export default MaterialInfoModal;
