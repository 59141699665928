// @flow
import { DraggableLogic, ReduxFilters } from "../reducers/BusinessLogic";
import { IS_INSIDE_ORDER, TRANSLATE_CURSOR, DRAGGING_ORDER } from "./reducers";

import type {
    DraggingOrderAction,
    ResetDraggedOrderAction,
    IsInsideOrderAction,
    TranslateCursorAction
} from "./reducers";

type Dispatch = (args: DraggingOrderAction |
    ResetDraggedOrderAction |
    IsInsideOrderAction |
    TranslateCursorAction
) => void;

type InsideOrderEventDetail = {
    line_uuid: string,
    is_unscheduled: boolean,
    x: number,
    y: number,
    width: number,
    is_extra_line: boolean,
    line_index: number,
    earliest_end: number,
    source_type: string,
    production_version: string,
    inside_order_uuid: string
}

const disableDrop = (is_inside_order: boolean, dispatch: Dispatch) => {
    if (is_inside_order) {
        dispatch({ type: IS_INSIDE_ORDER, data: false });
    }
    DraggableLogic.changeMouseCursor("not-allowed");
}

const enableDrop = (is_inside_order: boolean, dispatch: Dispatch) => {
    if (is_inside_order) {
        dispatch({ type: IS_INSIDE_ORDER, data: true });
    }
    DraggableLogic.changeMouseCursor("move");
}

export const getOrders = (line_index?: number, current_order_uuid: string | null, earliest_end?: number, source_type: string): HTMLElement[] => {
    if (line_index === undefined || current_order_uuid === null || !earliest_end) {
        return [];
    }

    // first check if the order lies on a parallel line, if so we ignore line index and use line uuid
    const el = DraggableLogic.querySelector(source_type, `#pane1 g[name="line-label"][line-index="${line_index}"]`);
    if (el && current_order_uuid) {
        const line_uuid = el.getAttribute("line-uuid");
        if (line_uuid) {
            const orders = Array.from(
                document.querySelectorAll(`#pane1 g[name="order"][line-uuid="${line_uuid}"]`)
            );
            const filtered_orders = [];
            for (const order of orders) {
                const order_uuid_el = order.closest("[order-uuid]") || (order.getAttribute("order-uuid") && order);
                if (order_uuid_el && order_uuid_el.getAttribute("order-uuid") === current_order_uuid) {
                    continue;
                }
                const order_earliest_end = parseFloat(order.getAttribute("earliest-end"));
                if (order_earliest_end <= earliest_end) {
                    filtered_orders.push(order);
                }
            }

            return filtered_orders;
        }
    }
    return [];
}

export const INSIDE_ORDER_EVENT: "inside_order_event" = "inside_order_event";

export class DraggableContextHelper {

    dragging_order_uuid: string | null = null;
    dispatch: Dispatch | null = null;
    inside_order_listener = null;
    inside_shift_listener = null;

    constructor(dragging_order_uuid: string, dispatch: Dispatch) {
        this.dragging_order_uuid = dragging_order_uuid;
        this.dispatch = dispatch;
    }

    addListeners = () => {
        this.inside_order_listener = (e: CustomEvent) => this.onInsideDraggableOrder(e.detail);
        window.addEventListener(INSIDE_ORDER_EVENT, this.inside_order_listener);
    }

    removeListeners = () => {
        window.removeEventListener(INSIDE_ORDER_EVENT, this.inside_order_listener);
    }

    onInsideDraggableOrder = (event_detail: InsideOrderEventDetail) => {
        const {
            line_uuid,
            is_unscheduled,
            x,
            y,
            width,
            is_extra_line,
            line_index,
            earliest_end,
            source_type,
            production_version,
            inside_order_uuid
        } = event_detail;

        const dragging_order_uuid = this.dragging_order_uuid;
        const dispatch = this.dispatch;
        if (!dragging_order_uuid || !dispatch) {
            return;
        }

        const is_inside_order = true;
        const is_droppable = ReduxFilters.isDroppableLineUuid(
            line_uuid,
            dragging_order_uuid,
            is_unscheduled
        );

        if (!is_droppable) {
            disableDrop(is_inside_order, dispatch);
            return;
        }
        enableDrop(is_inside_order, dispatch);

        if (is_extra_line) {
            disableDrop(is_inside_order, dispatch);
            return;
        }

        const translate_x = x + width;

        enableDrop(is_inside_order, dispatch);

        dispatch({
            type: TRANSLATE_CURSOR,
            data: {
                translate_x,
                translate_y: y
            }
        });

        const new_order_index = getOrders(
            line_index,
            this.dragging_order_uuid,
            earliest_end,
            source_type
        ).length;

        // $FlowFixMe
        this.dispatch({
            type: DRAGGING_ORDER,
            data: {
                translate_x,
                translate_y: y,
                new_line_uuid: line_uuid,
                new_order_index,
                line_index: line_index,
                earliest_start: earliest_end,
                is_unscheduled_order: is_unscheduled,
                production_version: production_version,
                override_earliest_start: earliest_end,
                inside_order_uuid
            }
        });
    }
}


export class DraggableEventHandler {

    static dispatchInsideOrderEvent = (event_detail: InsideOrderEventDetail) => {
        const event = new CustomEvent(INSIDE_ORDER_EVENT, { detail: event_detail });
        window.dispatchEvent(event);
    }
}