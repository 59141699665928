// @flow
import * as React from "react";
import { Button, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import { Link } from "react-router-dom";

import type { LoginUserObj } from "../lib/Models";
import * as Auth from "../lib/Auth";
import { getBackend } from "../lib/backend/Backend2";
import LoginPinModal from "./LoginPinModal";
import { translate } from "./IntlProviderWrapper";
import * as BusinessLogic from "../lib/BusinessLogic";
import { FormattedMessage } from "react-intl";
import ErrorComponent from "./ErrorComponent";

type Props = {
    history: Array<mixed>
};

type State = {
    user: LoginUserObj,
    showPinModal: boolean,
    showPinButton: boolean,
    showRegisterLink: boolean,
    message: string,
    providers: string[],
    showOtp: boolean
};

const env_flag = BusinessLogic.getSysFlag("env");

class Login extends React.Component<Props, State> {
    constructor() {
        super();
        this.state = {
            user: {
                username: "",
                password: "",
                remember: false,
                otp: ""
            },
            showRegisterLink: false,
            showPinButton: false,
            showPinModal: false,
            message: "",
            providers: [],
            showOtp: false
        };
    }

    async componentDidMount() {
        try {
            await BusinessLogic.loadFeatureMatrixAsync();
            const showRegisterLink = false; // getSysFlag("integration").toLowerCase().indexOf("kolektor") < 0;
            const pin = await getBackend().common.canPinLoginFromCurrentLocation({});
            this.setState({ showPinButton: pin.pin_login_allowed, showRegisterLink });
            await this.getOauthProviders();
        } catch (e) {
            console.error(e);
        }
    };

    validateForm() {
        return this.state.user.username.length > 0 && this.state.user.password.length > 0;
    }

    handleChange = (event: Event) => {
        let target = event.target;
        if (target instanceof HTMLInputElement) {
            const field = target.id;
            const value = target.type === "checkbox" ?
                target.checked : target.value;
            // update
            this.setState(prev_state => {
                prev_state.user[field] = value;
                return prev_state;
            });
        }
    };

    redirectAfterSuccess = () => {
        const redirectURL = Auth.getLoginRedirectURL();
        this.props.history.push(redirectURL);
    }

    handleSubmit = async (event: Event) => {
        event.preventDefault();
        try {
            const u = this.state.user;
            const res = await Auth.loginUP(u.username, u.password.trim(), u.remember, u.otp);
            this.setState({ message: "" });
            if (res) {
                this.setState({ showOtp: true })
            } else {
                if (Auth.getLoggedinUser().must_reset) {
                    this.props.history.push("/change_password");
                } else if (Auth.getLoggedinUser().must_activate_twofa) {
                    this.props.history.push("/activate_twofa");
                } else {
                    this.redirectAfterSuccess();
                }
            }

        } catch (err) {
            console.log(err);
            this.setState({ message: err.message });
        }
    };

    renderAlert() {
        return (
            <div className="row">
                <div className="col-12">
                    <ErrorComponent msg={this.state.message} type="warning" />
                </div>
            </div>
        );
    }

    getOauthProviders = async () => {
        try {
            const providers = await Auth.oauth2Providers();
            this.setState({ providers });
        } catch (err) {
            this.setState({ message: err.message });
        }
    }

    loginOauth2 = async (provider: string) => {
        try {
            await Auth.oauth2Initiate(provider, "login");
        } catch (err) {
            this.setState({ message: err.message });
        }
    }

    renderOauthProvider = (type: string, title: string, img?: string) => {
        if (img !== undefined) {
            const img_url = `/img/${img}.svg`;
            return <button type="button" className="btn btn-default"> <img src={img_url} alt={title} onClick={() => { this.loginOauth2(type); }} /> </button>;
        }
        return <Button block className="btn_register btn-primary" key={type} onClick={() => { this.loginOauth2(type); }}>{title}</Button>
    }

    renderOauthProviders = () => {
        if (this.state.providers.length === 0) {
            return null;
        }
        const res = [];
        if (this.state.providers.indexOf("github") >= 0) {
            res.push(this.renderOauthProvider("github", "GitHub"));
        }
        if (this.state.providers.indexOf("azure-ad") >= 0) {
            res.push(this.renderOauthProvider("azure-ad", "Microsoft Azure Active Directory", "oauth_provider_azure"));
        }
        if (this.state.providers.indexOf("adfs") >= 0) {
            res.push(this.renderOauthProvider("adfs", "Active Directory"));
        }
        return <React.Fragment>
            <hr key="hr_pin" />
            <div className="text-center">
                {res}
            </div>
        </React.Fragment>;
    }

    renderPinEntry = () => {
        if (!this.state.showPinButton) {
            return null;
        }
        return <React.Fragment>
            <hr key="hr_external_id" />
            <LoginPinModal
                onCancel={() => { this.setState({ showPinModal: false }); }}
                show={this.state.showPinModal}
                onSuccess={() => { this.setState({ showPinModal: false }); this.redirectAfterSuccess(); }}
            />
            <Button block style={{ width: "auto" }} className="btn_register" key="btnPin" onClick={() => { this.setState({ showPinModal: true }) }}>
                {translate("common.enter_with_pin", "Enter with PIN number")}
            </Button>
        </React.Fragment>;
    }

    renderInputFields = () => {
        if (!this.state.showOtp) {
            return <>
                {this.state.message !== "" && this.renderAlert()}
                <FormGroup controlId="username" bsSize="large" className="form-label-group">
                    <FormControl autoFocus type="text" placeholder="Your email address"
                        value={this.state.user.username} onChange={this.handleChange} />
                    <ControlLabel>
                        {translate("common.email", "E-mail address")}
                    </ControlLabel>
                </FormGroup>
                <FormGroup controlId="password" bsSize="large" className="form-label-group">
                    <FormControl type="password" placeholder="Your password"
                        value={this.state.user.password} onChange={this.handleChange} />
                    <ControlLabel>
                        {translate("common.password", "Password")}
                    </ControlLabel>
                </FormGroup>
                <div className="row">
                    <div className="col-6">
                        <FormGroup controlId="remember" bsClass="custom-control custom-checkbox checkbox">
                            <FormControl type="checkbox" checked={this.state.user.remember}
                                onChange={this.handleChange} bsClass="custom-control-input" />
                            <label className="custom-control-label" htmlFor="remember">
                                {translate("common.remember_me", "Remember me")}
                            </label>
                        </FormGroup>
                    </div>
                    <div className="col-6">
                        {
                            env_flag !== "dev" &&
                            <Link to="/recover_password" className="blue_link text-right">
                                {translate("common.forgot_password", "Forgot your password?")}
                            </Link>
                        }
                    </div>
                </div>
                <Button block
                    className="btn_register btn-primary"
                    disabled={!this.validateForm()}
                    type="submit">
                    {translate("common.sign_in", "Sign In")}
                </Button>
                {this.state.showRegisterLink && <Link to="/register" className="blue_link text-center">
                    {translate("common.create_account", "Create an account")}
                </Link>}
                {this.renderPinEntry()}
                {this.renderOauthProviders()}
            </>
        } else {
            return (
                <>
                    {this.state.message !== "" && this.renderAlert()}
                    <div>
                        <FormattedMessage id="common.twofa_success_text" defaultMessage="Type generated OTP (One Time Passowrd) into the input field." />
                    </div>
                    <FormGroup controlId="otp" bsSize="large" className="form-label-group">
                        <FormControl autoFocus type="text" placeholder="Your otp"
                            value={this.state.user.otp} onChange={this.handleChange} />
                        <ControlLabel>
                            {translate("common.otp", "OTP")}
                        </ControlLabel>
                    </FormGroup>
                    <Button block
                        className="btn_register btn-primary"
                        disabled={!this.validateForm()}
                        type="submit">
                        {translate("common.sign_in", "Sign In")}
                    </Button>
                </>
            )
        }
    }

    render() {
        return (
            <section id="register" key="1">
                <div className="caption_div">
                    <form className="form_box" onSubmit={this.handleSubmit}>
                        <h3>{translate("common.sign_in", "Sign In")}</h3>
                        {this.renderInputFields()}
                    </form>
                </div>
            </section>
        );
    }
};

export default Login;
