// @flow

import React from "react";

// $FlowFixMe
import { ReactComponent as ChevronRightIcon } from "@fortawesome/fontawesome-free/svgs/solid/chevron-right.svg";
import SvgIcon, { type SvgIconProps } from "../SvgIcon";

const ChevronDown = (props: SvgIconProps) => (
    <SvgIcon {...props} size={props.size || "fs"}>
        <ChevronRightIcon />
    </SvgIcon>
);

export default ChevronDown;
