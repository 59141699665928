// @flow

import { LINE_TAGS, LINE_TYPES } from "./ManufacturingTags.generated";

export function getLineTitleCssClass(line_tags: any): string {
    line_tags = line_tags || {};
    let lineTitleCss = "";
    if (line_tags[LINE_TAGS.type] === LINE_TYPES.line_group_lead) {
        lineTitleCss += " line-linegroup-lead";
    }
    if (line_tags[LINE_TAGS.type] === LINE_TYPES.sick_leave) {
        lineTitleCss += " line-sick-leave";
    }
    if (line_tags[LINE_TAGS.type] === LINE_TYPES.cameraman) {
        lineTitleCss += " line-cameraman";
    }
    if (line_tags[LINE_TAGS.type] === LINE_TYPES.control) {
        lineTitleCss += " line-control";
    }
    if (line_tags[LINE_TAGS.skip_planning] === "true") {
        lineTitleCss += " line-no-planning";
    }
    if (line_tags[LINE_TAGS.type] === LINE_TYPES.packaging) {
        lineTitleCss += " line-packaging";
    }
    if (line_tags[LINE_TAGS.type] === LINE_TYPES.shift_lead) {
        lineTitleCss += " line-shift-lead";
    }
    if (line_tags[LINE_TAGS.type] === LINE_TYPES.tool_setup) {
        lineTitleCss += " line-tool-setup";
    }
    if (line_tags[LINE_TAGS.type] === LINE_TYPES.pool) {
        lineTitleCss += " line-tool-pool";
    }
    return lineTitleCss;
}
