// @flow

import * as React from "react";
import { Sparklines, SparklinesLine } from "react-sparklines";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { niceNumber, toISODateTimeString, lowerHigher, lowHigh, capitalize, createDurationString } from "../lib/Util";

import { getBackend as getBackend2 } from "../lib/backend/Backend2";
import * as t from "../lib/backend/datasources.generated.types";
import * as ta from "../lib/backend/alerts.generated.types";
import { EVENT_TYPES } from "../lib/Models";
import { createAkaUrl } from "../lib/Aka";

import HighchartsLine from "./HighchartsLine";
import ErrorComponent from "./ErrorComponent";

const RANGE = 60 * 60 * 1000;

type RenderResult = {
    type: string,
    name: string,
    ctrl: Object | null
};
type Props = {
    selected: Object
};

type State = {
    line_uuid: string | null;
    line_title: string | null;
    plant_title: string | null;
    error: string | null;
};

// function that retreives sparkline for an event
export function getEventSparklineData(event: ta.IEventData, done: (Object[]) => void) {
    let out = [];
    if (event.type === EVENT_TYPES.quantiles_simple && event.version === 1) {
        getTimeseriesDataSmartForTSeries(event.ts, event.extra_data.tseries_uuid, RANGE, 1, (res) => {
            let { event, timeseries } = res;
            if (event) {
                return done(out);
            }
            for (let datapoint of timeseries) {
                out.push(datapoint.val);
            }
            done(out);
        });
    } else if (event.type === EVENT_TYPES.thresholds_simple && event.version === 1) {
        getTimeseriesDataSmartForTSeries(event.ts, event.extra_data.tseries_uuid, RANGE, 1, (res) => {
            let { event, timeseries } = res;
            if (event) {
                return done(out);
            }
            for (let datapoint of timeseries) {
                out.push(datapoint.val);
            }
            done(out);
        });
    } else if (event.type === EVENT_TYPES.signal_edge && event.version === 1) {
        getTimeseriesDataSmartForTSeries(event.ts, event.analysis ? event.analysis.output_ts_id : "UNKNOWN", RANGE, 1, (res) => {
            let { event, timeseries } = res;
            if (event) {
                return done(out);
            }
            for (let datapoint of timeseries) {
                out.push(datapoint.val);
            }
            done(out);
        });
    } else if (event.type === EVENT_TYPES.downtime && event.version === 1) {
        getTimeseriesDataSmartForTSeries(event.ts, event.extra_data.tseries_uuid, RANGE, 1, (res) => {
            let { event, timeseries } = res;
            if (event) {
                return done(out);
            }
            for (let datapoint of timeseries) {
                out.push(datapoint.val);
            }
            done(out);
        });
    } else {
        // nothing else to do
        done(out);
    }
}

// smart expansion of time-series range to show some actual data points
async function getTimeseriesDataSmartForTSeries(ts: number, tseries_uuid: string, current_range: number, stage: number, done: (Object) => void) {
    try {
        const res = await getBackend2().datasources.getSingleTSeriesData({
            id: tseries_uuid,
            from: ts - current_range,
            to: ts + current_range
        });
        const timeseries = res.data;
        if (timeseries.length < 20 && stage < 5) {
            await getTimeseriesDataSmartForTSeries(ts, tseries_uuid, 10 * current_range, stage + 1, done);
        } else {
            done({ timeseries });
        }
    } catch (error) {
        done({ error });
    }
}

// smart expansion of time-series range to show some actual data points
async function getTimeseriesDataSmartForEvent(event: ta.IEventData, datasource: t.IDatasourceExRec, current_range: number, stage: number, done: (Object) => void) {
    try {
        const res = await getBackend2().datasources.getSingleTSeriesData({
            id: event.extra_data.tseries_uuid,
            from: event.ts - current_range,
            to: event.ts + current_range
        });
        const timeseries = res.data;
        if (timeseries.length < 20 && stage < 5) {
            await getTimeseriesDataSmartForEvent(event, datasource, 10 * current_range, stage + 1, done);
        } else {
            done({ event: event, datasource, timeseries });
        }
    } catch (error) {
        done({ event: event, datasource, error });
    }
}

// function that retrieves any additioanl data needed to display event
export async function getEventDetails(event: ta.IEventData, done: (Object) => void) {
    // first get datasource info
    try {
        const res = await getBackend2().datasources.getSingleDatasource({ id: event.datasource_uuid || "UNKNOWN" });
        const datasource = res.data;
        if (datasource) {
            // check what kind of event we have and retrieve extra info
            // from the extra_data if needed
            if (event.type === EVENT_TYPES.quantiles_simple && event.version === 1) {
                await getTimeseriesDataSmartForEvent(event, datasource, RANGE, 1, done);
            } else if (event.type === EVENT_TYPES.thresholds_simple && event.version === 1) {
                await getTimeseriesDataSmartForEvent(event, datasource, RANGE, 1, done);
            } else if (event.type === EVENT_TYPES.signal_edge && event.version === 1) {
                await getTimeseriesDataSmartForEvent(event, datasource, RANGE, 1, done);
            } else if (event.type === EVENT_TYPES.downtime && event.version === 1) {
                await getTimeseriesDataSmartForEvent(event, datasource, RANGE, 1, done);
            } else {
                // nothing else to do
                done({ event: event, datasource });
            }
        } else {
            done({ event: event, error: "Datasource not found." });
        }
    } catch (e) {
        done({ event: event, error: e.message });
    }
}

// function that prepares input for the given event into event table
export function renderEventRow(event: ta.IEventData, sparkline_data: any) {
    // placeholder for results
    let res = {
        type: "",
        extra_data: <span />,
        sparkline: <span />
    };
    // go over different event types
    if (event.type === EVENT_TYPES.quantiles_simple && event.version === 1) {
        const ed = event.extra_data;
        // res.type = capitalize(lowHigh(ed.extreme_type, "eng")) + " value of " + event.datasource.title;
        res.type = "Spike";
        res.extra_data = (
            <p className="data_source_text">
                <span>Observed value of </span>{niceNumber(ed.value, 2)} ({niceNumber(100 * ed.cdf, 1)} percentile)
                <span> was </span> {lowerHigher(ed.extreme_type, "eng")} <span> the threshold of </span>
                {niceNumber(ed.threshold_value, 2)} ({niceNumber(100 * ed.threshold_cdf, 1)} percentile)
            </p>
        );
        res.sparkline = (
            <Sparklines data={sparkline_data} key={(sparkline_data || []).length}>
                <SparklinesLine color="#174291" />
            </Sparklines>
        );
    }
    if (event.type === EVENT_TYPES.thresholds_simple && event.version === 1) {
        const ed = event.extra_data;
        // res.type = capitalize(lowHigh(ed.extreme_type)) + " value of " + event.datasource.title;
        res.type = "Thresholds";
        res.extra_data = (
            <p className="data_source_text">
                <span>Observed value of </span>{niceNumber(ed.value, 2)}
                <span> was </span> {lowerHigher(ed.extreme_type, "eng")} <span> the threshold of </span>
                {niceNumber(ed.threshold_value, 2)}
            </p>
        );
        res.sparkline = (
            <Sparklines data={sparkline_data} key={(sparkline_data || []).length} height={40}>
                <SparklinesLine color="#174291" />
            </Sparklines>
        );
    }
    if (event.type === EVENT_TYPES.signal_edge && event.version === 1) {
        const ed = event.extra_data;
        // res.type = capitalize(lowHigh(ed.extreme_type)) + " value of " + event.datasource.title;
        res.type = "Signal edge";
        res.extra_data = (
            <p className="data_source_text">
                {capitalize(ed.extreme_type)}  <span> of downtime</span>
            </p>
        );
        res.sparkline = (
            <Sparklines data={sparkline_data} key={(sparkline_data || []).length}>
                <SparklinesLine color="#174291" />
            </Sparklines>
        );
    }
    if (event.type === EVENT_TYPES.downtime && event.version === 1) {
        const ed = event.extra_data;
        // res.type = capitalize(lowHigh(ed.extreme_type)) + " value of " + event.datasource.title;
        res.type = "Downtime";
        res.extra_data = (
            <p className="data_source_text">
                {capitalize(ed.extreme_type)}  <span> of downtime</span>
            </p>
        );
        res.sparkline = (
            <Sparklines data={sparkline_data} key={(sparkline_data || []).length}>
                <SparklinesLine color="#174291" />
            </Sparklines>
        );
    }
    if (event.type === EVENT_TYPES.man_downtime && event.version === 1) {
        const ed = event.extra_data;
        const tags = event.tags;
        let desc = "";
        if (tags.plant_title || tags.plant_external_id) {
            desc += " - " + (tags.plant_title || tags.plant_external_id);
        }
        if (tags.line_title || tags.line_external_id) {
            desc += " - " + (tags.line_title || tags.line_external_id);
        }

        const span = createDurationString(event.ts_to - event.ts);
        res.type = `Downtime`;
        res.extra_data = (
            <p className="data_source_text">
                Downtime - {span} - {ed.title} {desc}
            </p>
        );
        res.sparkline = null;
    }
    return res;
}

// function that prepares input for the given event into event list
export function renderEventListRow(event: ta.IEventData, counter: number) {
    // placeholder for results
    let res = {
        type: "",
        desc: "",
        extra_data: <span />
    };
    // go over different event types
    if (event.type === EVENT_TYPES.man_downtime && event.version === 1) {
        const tags = event.tags;
        res.desc = event.description || "";
        if (tags.plant_title || tags.plant_external_id) {
            res.desc += " - " + (tags.plant_title || tags.plant_external_id || "");
        }
        if (tags.line_title || tags.line_external_id) {
            res.desc += " - " + (tags.line_title || tags.line_external_id || "");
        }
    }
    res.type = event.title || event.type;
    res.desc = event.description || event.type + " event occured"

    res.extra_data = (
        <div>
            <span className="bold">{res.type} </span><br />
            <span className="light">{res.desc} </span><br />
            <Moment fromNow>{new Date(event.ts)}</Moment> &bull;
            <span className="light"> {toISODateTimeString(new Date(event.ts))}</span> &bull;
            <span> {event.analysis && event.analysis.params.type}</span>
        </div>
    );
    return res;
}

// function that prepares input for the given event into event list
export function renderEventListRowRich(event: ta.IEventData, counter: number) {
    // placeholder for results
    let res = {
        title: "",
        type: "",
        description: <span />,
        extra_data: <span />,
        icon: <span />
    };
    let span = null;
    let show_to = false;
    if (event.ts !== event.ts_to) {
        span = createDurationString(event.ts_to - event.ts);
        show_to = true;
    }
    const datasource_title = event.datasource ? event.datasource.title : "UNKNOWN";

    res.title = capitalize(lowHigh(event.extra_data.extreme_type, "eng")) + " value of " + datasource_title;

    if (event.type === EVENT_TYPES.quantiles_simple && event.version === 1) {
        const ed = event.extra_data;
        res.type = "Spike";
        res.description = (
            <p className="data_source_text">
                <span>Observed value of </span>{niceNumber(ed.value, 2)} ({niceNumber(100 * ed.cdf, 1)} percentile)
                <span> was </span> {lowerHigher(ed.extreme_type, "eng")} <span> the threshold of </span>
                {niceNumber(ed.threshold_value, 2)} ({niceNumber(100 * ed.threshold_cdf, 1)} percentile)
            </p>
        );
    }
    if (event.type === EVENT_TYPES.thresholds_simple && event.version === 1) {
        const ed = event.extra_data;
        res.type = "Thresholds";
        res.description = (
            <p className="data_source_text">
                <span>Observed value of </span>{niceNumber(ed.value, 2)}
                <span> was </span> {lowerHigher(ed.extreme_type, "eng")} <span> the threshold of </span>
                {niceNumber(ed.threshold_value, 2)}
            </p>
        );
    }
    if (event.type === EVENT_TYPES.signal_edge && event.version === 1) {
        const ed = event.extra_data;
        res.type = "Signal edge";
        res.description = (
            <p className="data_source_text">
                {capitalize(ed.extreme_type)}  <span> of downtime</span>
            </p>
        );
    }
    if (event.type === EVENT_TYPES.downtime && event.version === 1) {
        const ed = event.extra_data;
        res.type = "Downtime";
        res.title = "Automatic detected downtime event";
        res.description = (
            <p className="data_source_text">
                {capitalize(ed.extreme_type)}  <span> of downtime</span>
                <span> &bull; {datasource_title} </span>
            </p>
        );
    }
    if (event.type === EVENT_TYPES.man_downtime && event.version === 1) {
        const ed = event.extra_data;
        res.type = "Downtime";
        res.title = "Reported downtime";
        res.description = (
            <p className="data_source_text">
                <span> Duration: {span} </span>
                <span> &bull; {ed.title} </span>
            </p>
        );
    }

    if (event.extra_data && event.extra_data.extreme_type === "high") {
        res.icon = "high"
    }
    if (event.extra_data && event.extra_data.extreme_type === "low") {
        res.icon = "low"
    }

    res.extra_data = (
        <div>
            <div className="row">
                <div className="col">
                    <h5 className={res.icon}>{res.title}</h5>
                    {res.description}
                </div>
            </div>
            <div className="date-time d-flex justify-content-between">
                <div className="bold">
                    {toISODateTimeString(new Date(event.ts))}
                    {show_to && " - " + toISODateTimeString(new Date(event.ts_to))}
                </div>
                <div className="bold"><Moment fromNow>{new Date(event.ts)}</Moment></div>
            </div>
        </div>
    );
    return res;
}

// Prepares a list of events to be displayed on event page as a scrollable side bar on the le
class EventDetails extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            error: null,
            line_title: "-",
            line_uuid: "-",
            plant_title: "-"
        };
    }

    async componentDidUpdate() {
        if (this.props.selected && this.props.selected.event.type === EVENT_TYPES.man_downtime) {
            const target = this.props.selected.event.tags.line_uuid;
            try {
                if (this.state.line_uuid !== target) {
                    const res = await getBackend2().manufacturing.getLines({ uuid: target });
                    if (res.lines.length === 0) {
                        throw new Error("Unknown line: " + target);
                    }
                    const line = res.lines[0];
                    this.setState({
                        line_title: line.title,
                        line_uuid: target,
                        plant_title: line.plant_title
                    });
                }
            } catch (err) {
                this.setState({
                    line_title: "-",
                    line_uuid: target,
                    plant_title: "-",
                    error: err.message
                });
            }
        }
    }

    render_error() {
        return (<ErrorComponent msg={this.props.selected.error} type="warning" />);
    }

    renderSpike(event: ta.IEventData): RenderResult {
        const timeseries = this.props.selected.timeseries;
        let res: RenderResult = {
            type: "",
            name: "",
            ctrl: null
        };
        if (event.type === EVENT_TYPES.quantiles_simple && event.version === 1) {
            const ed = event.extra_data;
            const datasource = this.props.selected.datasource;
            res.name = capitalize(lowHigh(ed.extreme_type, "eng")) + " value of " + datasource.title;
            res.type = "Spike";
            const datasource_title = (event.datasource ? event.datasource.title : "UNKNOWN");
            /*
              Example:
                {
                  cdf: 0.99989,
                  value: 3566,
                  threshold_cdf: 0.995,
                  threshold_value: 3100,
                  extreme_type: "high",
                  tseries_uuid: "1324984738b2b1798a098d987789e"
                }
            */
            const yBands = [
                {
                    from: (ed.threshold_value < ed.value ? -10e100 : ed.threshold_value),
                    to: (ed.threshold_value < ed.value ? ed.threshold_value : 10e100),
                    color: "#00ff0055"
                }
            ];
            res.ctrl = (
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className="white_box charts">
                            <div className="row">
                                <div className="col-lg-12 col-md-12">
                                    <h6>Observed value of "{datasource_title}" {niceNumber(ed.value, 3)} ({niceNumber(100 * ed.cdf, 1)} percentile)
                                        was {lowerHigher(ed.extreme_type, "eng")} the threshold of {niceNumber(ed.threshold_value, 3)} ({niceNumber(100 * ed.threshold_cdf, 1)} percentile)</h6>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <p className="data_source_text"><span>Observed value:</span> {niceNumber(ed.value, 3)} ({niceNumber(100 * ed.cdf, 3)} percentile) </p>
                                    <p className="data_source_text"><span>Extreme type:</span> {ed.extreme_type}</p>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <p className="data_source_text"><span>Threshold value:</span> {niceNumber(ed.threshold_value, 3)} ({niceNumber(100 * ed.threshold_cdf, 3)} percentile)</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-12">
                                    <HighchartsLine key={event.uuid}
                                        options={{
                                            id: "linechart", type: "line",
                                            name: "count", height: 200,
                                            title: "Event details", subtitle: "Event explanation visualization",
                                            y_label: "count", xAxis: "data", ts: timeseries, threshold: event.extra_data.threshold_value,
                                            y_bands: yBands
                                        }}
                                        events={[event]} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return res;
    }

    renderThresholds(event: ta.IEventData): RenderResult {
        const timeseries = this.props.selected.timeseries;
        let res: RenderResult = {
            type: "",
            name: "",
            ctrl: null
        };
        if (event.type === EVENT_TYPES.thresholds_simple && event.version === 1) {
            const ed = event.extra_data;
            const datasource = this.props.selected.datasource;
            res.name = capitalize(lowHigh(ed.extreme_type, "eng")) + " value of " + datasource.title;
            res.type = "Thresholds";
            const datasource_title = (event.datasource ? event.datasource.title : "UNKNOWN");
            /*
              Example:
                {
                  value: 3566,
                  threshold_value: 3100,
                  extreme_type: "high",
                  tseries_uuid: "1324984738b2b1798a098d987789e"
                }
            */
            const yBands = [
                {
                    from: (ed.threshold_value < ed.value ? -10e100 : ed.threshold_value),
                    to: (ed.threshold_value < ed.value ? ed.threshold_value : 10e100),
                    color: "#00ff0055"
                }
            ];
            res.ctrl = (
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className="white_box charts">
                            <div className="row">
                                <div className="col-lg-12 col-md-12">
                                    <h6>Observed value of "{datasource_title}" {niceNumber(ed.value, 2)}
                                        &nbsp;was {lowerHigher(ed.extreme_type, "eng")} the threshold of {niceNumber(ed.threshold_value, 2)}</h6>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <p className="data_source_text"><span>Observed value:</span> {niceNumber(ed.value, 2)} </p>
                                    <p className="data_source_text"><span>Extreme type:</span> {ed.extreme_type}</p>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <p className="data_source_text"><span>Threshold value:</span> {niceNumber(ed.threshold_value, 2)}</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-12">
                                    <HighchartsLine key={event.uuid}
                                        options={{
                                            id: "linechart", type: "line",
                                            name: "count", height: 200,
                                            title: "Event details", subtitle: "Event explanation visualization",
                                            y_label: "count", xAxis: "data", ts: timeseries, threshold: ed.threshold_value,
                                            y_bands: yBands
                                        }}
                                        events={[event]} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return res;
    }

    renderSignalEdge(event: ta.IEventData): RenderResult {
        const timeseries = this.props.selected.timeseries;
        let res: RenderResult = {
            type: "",
            name: "",
            ctrl: null
        };
        if (event.type === EVENT_TYPES.signal_edge && event.version === 1) {
            const ed = event.extra_data;
            const datasource = this.props.selected.datasource;
            res.name = capitalize(ed.extreme_type) + " of downtime for " + datasource.title;
            res.type = "Signal edge";
            const datasource_title = (event.datasource ? event.datasource.title : "UNKNOWN");

            res.ctrl = (
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className="white_box charts">
                            <div className="row">
                                <div className="col-lg-12 col-md-12">
                                    <h6> Detected {ed.extreme_type} downtime for datasource: "{datasource_title}"</h6>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <p className="data_source_text"><span>Observed value:</span> {niceNumber(ed.value, 2)} </p>
                                    <p className="data_source_text"><span>Extreme type:</span> {ed.extreme_type}</p>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <p className="data_source_text"><span>Timestamp: </span> {toISODateTimeString(new Date(event.ts))}</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-12">
                                    <HighchartsLine key={event.uuid}
                                        options={{
                                            id: "linechart", type: "scatter",
                                            name: "count", height: 200,
                                            title: "Event details", subtitle: "Event explanation visualization",
                                            y_label: "count", xAxis: "data", ts: timeseries, threshold: event.extra_data.threshold_value
                                        }}
                                        events={[event]} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return res;
    }

    renderDowntime(event: ta.IEventData): RenderResult {
        const timeseries = this.props.selected.timeseries;
        let res: RenderResult = {
            type: "",
            name: "",
            ctrl: null
        };
        if (event.type === EVENT_TYPES.downtime && event.version === 1) {
            const ed = event.extra_data;
            const datasource = this.props.selected.datasource;
            res.name = capitalize(ed.extreme_type) + " of downtime for " + datasource.title;
            res.type = "Signal edge";

            res.ctrl = (
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className="white_box charts">
                            <div className="row">
                                <div className="col-lg-12 col-md-12">
                                    <h6> Detected {ed.extreme_type} downtime for datasource: "{event.datasource ? event.datasource.title : "UNKNOWN"}"</h6>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <p className="data_source_text"><span>Extreme type:</span> {ed.extreme_type}</p>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <p className="data_source_text"><span>Timestamp: </span> {toISODateTimeString(new Date(event.ts))}</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-12">
                                    <HighchartsLine key={event.uuid}
                                        options={{
                                            id: "linechart", type: "scatter",
                                            name: "data", height: 200,
                                            title: "Event details", subtitle: "Event explanation visualization",
                                            y_label: "count", xAxis: "data", ts: timeseries, threshold: event.extra_data.threshold_value
                                        }}
                                        events={[event]} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return res;
    }

    renderManDowntime(event: ta.IEventData): RenderResult {
        let res: RenderResult = {
            type: event.type,
            name: "Manufacturing downtime",
            ctrl: <div />
        };
        if (event.type === EVENT_TYPES.man_downtime && event.version === 1) {
            const ed = event.extra_data;
            const tg = event.tags;
            res.ctrl = (
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className="white_box charts">
                            <div className="row">
                                <div className="col-lg-12 col-md-12">
                                    <h6> Detected manufacturing downtime  "{ed.title}"</h6>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <p className="data_source_text"><span>Duration:</span> {ed.duration} h </p>
                                    <p className="data_source_text"><span>Shift:</span> {ed.shift_id} </p>
                                    <p className="data_source_text"><span>Order:</span> {tg.order_external_id} </p>
                                    <p className="data_source_text"><span>User comment:</span> {ed.user_comment} </p>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <p className="data_source_text"><span>Plant:</span> {this.state.plant_title} </p>
                                    <p className="data_source_text"><span>Line:</span> {this.state.line_title} </p>
                                    <p className="data_source_text"><span>Timestamp: </span> {toISODateTimeString(new Date(event.ts))}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return res;
    }

    renderExtraData() {
        // placeholder for results
        let res: RenderResult = {
            type: "",
            name: "",
            ctrl: <div />
        };
        const event = this.props.selected.event;
        const datasource = this.props.selected.datasource;
        // if there are tags present that can be used to redirect to another page, create a link
        let more_url: string | null = null;
        if (event.tags.module === "man" && event.tags.entity_type && event.tags.entity_external_id) {
            more_url = createAkaUrl({
                type: "man_" + event.tags.entity_type,
                external_id: event.tags.entity_external_id,
            });
        }
        if (event.type === EVENT_TYPES.quantiles_simple) {
            res = this.renderSpike(event);
        } else if (event.type === EVENT_TYPES.thresholds_simple) {
            res = this.renderThresholds(event);
        } else if (event.type === EVENT_TYPES.signal_edge) {
            res = this.renderSignalEdge(event);
        } else if (event.type === EVENT_TYPES.downtime) {
            res = this.renderDowntime(event);
        } else if (event.type === EVENT_TYPES.man_downtime) {
            res = this.renderManDowntime(event);
        } else {
            res = {
                type: event.type,
                name: event.type,
                ctrl: null
            };
        }

        let ts_section = (
            <div className="data_source_text">
                <span>Occured at: </span> {toISODateTimeString(new Date(event.ts))}
            </div>);
        if (event.ts !== event.ts_to) {
            ts_section = (
                <div className="data_source_text">
                    <span>Occured at: </span> {toISODateTimeString(new Date(event.ts))} <span> until </span> {toISODateTimeString(new Date(event.ts_to))}
                </div>);
        }
        return (
            <div>
                <div className="row">
                    <div className="col-12">
                        <div className="active_data">
                            <h5>{res.name}</h5>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className="white_box charts">
                            <div className="row">
                                <div className="col-lg-6 col-md-6">
                                    <p className="data_source_text"><span>Type:</span> {res.type} </p>
                                    <p className="data_source_text"><span>Data source title:</span> <Link to={"/datasources/" + datasource.uuid}>{datasource.title}</Link></p>
                                    <p className="data_source_text"><span>Data source code:</span> <Link to={"/datasources/" + datasource.uuid}>{datasource.code}</Link></p>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    {ts_section}
                                    <p className="data_source_text"><span>Detected at:</span> {toISODateTimeString(new Date(event.ts_created))} </p>
                                    {
                                        more_url &&
                                        <p className="data_source_text"><Link to={more_url}>Show more...</Link></p>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {res.ctrl}
            </div>
        );
    }

    // prepare list of events
    render() {
        if (this.props.selected) {
            return (
                <div className="tab-content">
                    <div className="tab-pane active" id="home">
                        {this.renderExtraData()}
                    </div>
                </div>
            );
        }
        return <div />;
    }
}

export default EventDetails;
