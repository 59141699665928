// @flow
import React from "react";
import RendersView from "./views/RendersView";
import MutationsView from "./views/MutationsView";
import PerformanceAnalysis from "./PerformanceAnalysis";
import MeasurementsView from "./views/MeasurementsView";

// $FlowFixMe
import "./performance.scss";

export let winRef = null;

const left_menu_options = [
    {
        view_id: "num_renders",
        title: "Number of Renders",
        Component: RendersView
    },
    {
        view_id: "num_mutations",
        title: "Number of Mutations",
        Component: MutationsView
    },
    {
        view_id: "measurements_view",
        title: "Measurements",
        Component: MeasurementsView
    }
];

type LeftMenuProps = {
    onChangeView: (view_id: string) => (e: Event) => void,
    selected_view: string,
    renderToolbar: () => any
};

const LeftMenuOptions = (props: LeftMenuProps) => {
    const mapped_left_menu = left_menu_options.map((option) => {
        const is_selected = option.view_id === props.selected_view;
        return <div
            key={option.view_id}
            className="left-menu-option"
            data-selected={is_selected}
            onClick={props.onChangeView(option.view_id)}
        >
            {option.title}
        </div>
    })
    return [
        props.renderToolbar(),
        ...mapped_left_menu
    ]
        
}

type Props = {};
type State = {
    selected_view: string,
    is_observing: boolean,
    refresh_count: number
};

export default class PlanningTablePerformance extends React.Component<Props, State> {

    state = {
        selected_view: left_menu_options[0].view_id,
        is_observing: false,
        refresh_count: 0
    }

    ref = null;

    constructor(props: Props) {
        super(props);
        this.ref = React.createRef();
    }

    onChangeView = (view_id: string) => (e: Event) => {
        this.setState({ selected_view: view_id });
    }

    onRefresh = () => {
        this.setState({ refresh_count: this.state.refresh_count + 1 })
    }
    
    onClear = () => {
        window.clearPerformance()
        this.onRefresh();
    }

    onSaveMeasurement = () => {
        let measurement_name = prompt("Please enter measurement name:", "Drag and drop");
        let measurement_s = -1;
        if (this.state.selected_view === "num_renders") {
            measurement_s = localStorage.getItem("renders-view-last-measurement")
        } else if (this.state.selected_view === "num_mutations") {
            measurement_s = localStorage.getItem("mutations-view-last-measurement")
        }
        if (measurement_s === -1) {
            return;
        }
        const measurement = {
            name: measurement_name,
            view: this.state.selected_view,
            time_s: measurement_s
        }
        let exists_data = [];
        const exists = localStorage.getItem("performance-measurement-saved");
        if (exists) {
            exists_data = JSON.parse(exists);
        }
        exists_data.push(measurement);
        localStorage.setItem("performance-measurement-saved", JSON.stringify(exists_data));
    }

    renderToolbar = () => {
        return <div
            className="left-menu-option"
            style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}
        >
            <div className="performance-title">
                <button className="btn btn-primary" onClick={this.onRefresh}>Refresh</button>
            </div>
            <div className="performance-title">
                <button className="btn btn-primary" onClick={this.onClear}>Clear</button>
            </div>
            <div className="performance-title">
                <button className="btn btn-primary" onClick={this.onSaveMeasurement}>Save Measurement</button>
            </div>
        </div>
    }

    render() {
        const view = left_menu_options.find((option) => option.view_id === this.state.selected_view);

        return <div
            ref={this.ref}
            show={true}
            className="performance-"
            bsSize="large"
        >
            <div className="performance-content">
                <div className="performance-left-menu">
                    <LeftMenuOptions
                        onChangeView={this.onChangeView}
                        renderToolbar={this.renderToolbar}
                        selected_view={this.state.selected_view}
                    />
                </div>
                <div className="performance-main-view">
                    {view ? <view.Component/> : null}
                </div>
            </div>
        </div>
    }
}

export const windows = [];

const focusElement = (el: HTMLElement) => {
    el.focus();
    el.scrollIntoView();
    el.classList.add("performance-highlight")
}

export const PerformanceAnalysisButton = () => {
    if (!PerformanceAnalysis.hasPermissions()) {
        return null;
    }

    const onShowPerformance = () => {
        const winOpen = window.open;
        window.open = function(...args) {
            var win = winOpen.apply(this, arguments);
            windows[0] = win;
            win.clearPerformance = PerformanceAnalysis.clearPerformance;
            win.focusElement = focusElement;
            return win;
        };
        PerformanceAnalysis.startObserving();
        window.open('/planning-table-performance');
    }



    return <button className="btn btn-success" onClick={onShowPerformance} style={{fontWeight: 300}}>
        Performance analysis
    </button>
}