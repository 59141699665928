// @flow
import * as React from "react";
import { connect } from "react-redux";
import type { ReduxState } from "./reducers/reducers";
import type { UpdateCurrentDowntimesAction } from "./reducers/insights";
import * as t from "../../../lib/backend/manufacturing2.generated.types";
import * as t2 from "../../../lib/SimulationReportModels";
import { ReportLogic } from "./reducers/BusinessLogic";

type Props = {
    reduxDispatch: (args: UpdateCurrentDowntimesAction) => void,
    report_uuid: string | null,
    report_data: t2.IReportModelEx | null,
    current_downtimes: t.ICurrentDowntime[]
};
type State = {};

class CurrentDowntimes extends React.Component<Props, State> {

    // here we store the timer for regular update of current downtimes
    timer = null;

    componentDidMount() {
        this.timer = setInterval(() => { this.loadCurrentDowntimes(); }, 60000);
    }

    componentDidUpdate = (prev_props: Props) => {
        // if no report yet, do nothing
        if (this.props.report_data === null) { return; }
        // check if anything changed
        if (this.props.report_uuid !== prev_props.report_uuid) {
            ReportLogic.getCurrentDowntimes(
                this.props.report_data,
                this.props.reduxDispatch);
        }
    }

    componentWillUnmount() {
        // clear the timer
        if (this.timer !== null) {
            clearInterval(this.timer);
        }
    }

    loadCurrentDowntimes = () => {
        // make sure we have something to load
        if (this.props.report_data === null) { return; }
        // we do, refresh
        ReportLogic.getCurrentDowntimes(
            this.props.report_data,
            this.props.reduxDispatch);
    }

    render() {
        return (<span></span>);
    }
}

export default connect(
    (state: ReduxState) => {
        const report_uuid = state.gantt_chart_report.original_report_uuid;
        const report_data = state.gantt_chart_report.report_data
        const current_downtimes = state.gantt_chart_insights.current_downtimes;
        return {
            report_uuid,
            report_data,
            current_downtimes
        }
    },
    (dispatch) => ({ reduxDispatch: dispatch })
)(CurrentDowntimes);
