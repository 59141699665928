// @flow

// main imports
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import DraggableModal from "../../DraggableModal";

type Props = {
    show: boolean,
    children: any,
    line_title: string,
    onClose: (e: Event) => void,
    onSave: (e: Event) => void
}

type State = {
}

class ShiftEditorModal extends Component<Props, State> {

    render() {
        const { show } = this.props;

        return (
            <DraggableModal>
                <Modal
                    show={show}
                    className="batch-operation-modal"
                    dialogClassName="modal-dialog-scrollable"
                    onHide={this.props.onClose}
                >
                    <Modal.Header>
                        <Modal.Title>
                            <FormattedMessage id="common.shifts" defaultMessage="Shifts" /> - {this.props.line_title}
                        </Modal.Title>
                        <button type="button" className="close" onClick={this.props.onClose}>
                            <span aria-hidden="true">×</span>
                            <span className="sr-only"><FormattedMessage id="common.close" defaultMessage="Close" /></span>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        {this.props.children}
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-outline-secondary"
                            onClick={this.props.onSave}>
                            <FormattedMessage id="common.accept" defaultMessage="Accept" />
                        </button>
                    </Modal.Footer>
                </Modal>
            </DraggableModal>
        );
    }
}

export default ShiftEditorModal;
