// @flow

import React from "react";
import { connect } from "react-redux";
import * as rt from "../../../lib/SimulationReportModels";
import { SET_SHOW_VALID_LINES } from "./reducers/planningTable";
import { ReduxFilters } from "./reducers/BusinessLogic";
import { countLinesOnGantt, countUnscheduledLinesOnGantt, UPDATE_PLANNING_TABLE_HEIGHT } from "./reducers/properties";
import TypeFilter from "./lineOrderList/filters/TypeFilter";
import {
    SET_SELECTED_MATERIALS
} from "./reducers/reducersFilters/filters";
import { ConnectedLockLine } from "./lineOrderList/LineFilter";

import type { SetRescheduleOrderAction, SetRescheduleLineAction } from "./reducers/common";
import type { LineOrders } from "./reducers/linesOrders";
import type { UpdatePlanningTableHeightAction } from "./reducers/properties";
import type { ResetFiltersAction, PlanningTableFiltersState, SetSelectedMaterialAction } from "./reducers/reducersFilters/filters";
import type { ReduxState } from "./reducers/reducers";
import type { SetShowValidLinesAction, SetHighlightedOrders } from "./reducers/planningTable";
import type { PropertiesState } from "./reducers/properties";
import type { SelectOptionItem } from "./lineOrderList/filters/OrderFilter";
import type { InsightsMapped } from "./reducers/insights";
import type { ValidLineOrders, ValidOrder } from "./reducers/common";
import type { IMaterialModel } from "../../../lib/backend/manufacturing2.generated.types";

type Props = {
    filters: PlanningTableFiltersState,
    lines_orders: LineOrders[],
    properties: PropertiesState,
    insights_mapped: InsightsMapped,
    hideFiltersModal: any => any,
    reduxDispatch: (
        args: (
            SetSelectedMaterialAction | SetShowValidLinesAction |
            UpdatePlanningTableHeightAction | SetRescheduleLineAction |
            SetRescheduleOrderAction | ResetFiltersAction | SetHighlightedOrders
        )
    ) => void,
    show_planning_table_filter: boolean,
    line_group_uuid: string,
    unscheduled_orders: LineOrders[],
    reschedule_line_uuid: string,
    reschedule_order_uuid: string,
    report_data: rt.IReportModelEx,
    show_only_unscheduled: boolean,
    original_report_created_at: number | null,
    materials: IMaterialModel[]
};

type State = {};

class Filters extends React.PureComponent<Props, State> {

    componentDidUpdate = async (prev_props: Props) => {
        // we do not need to apply filters in case when locked mode is on and reschedule order or reschedule line is clicked
        // because the orders will not change
        if (prev_props.filters.reschedule_order_uuid !== this.props.filters.reschedule_order_uuid && this.props.filters.reschedule_order_uuid ||
            prev_props.filters.is_filter_locked !== this.props.filters.is_filter_locked && this.props.filters.is_filter_locked) {
            //we already apply filters in the reschedule mode
            return;
        }
        if (prev_props.filters !== this.props.filters ||
            prev_props.show_only_unscheduled !== this.props.show_only_unscheduled ||
            prev_props.report_data !== this.props.report_data) {
            return await this.applyFilters();
        }
    }

    updateHeights = (
        valid_lines_map: Map<string, ValidLineOrders>,
        valid_orders_map: Map<string, ValidOrder>
    ) => {
        const count_non_empty_lines = countLinesOnGantt({
            valid_lines_map,
            line_group_uuid: this.props.line_group_uuid,
            report_data: this.props.report_data,
            source_type: this.props.properties.source_type
        });
        const num_unscheduled_lines = countUnscheduledLinesOnGantt({
            valid_orders_map,
            unscheduled_orders: this.props.unscheduled_orders,
            source_type: this.props.properties.source_type
        });
        this.props.reduxDispatch({
            type: UPDATE_PLANNING_TABLE_HEIGHT,
            data: { count_non_empty_lines, num_unscheduled_lines }
        });
    }

    applyFilters = async () => {
        const report_data = this.props.report_data ? this.props.report_data.result.orders : []
        const original_report_created_at = this.props.original_report_created_at;
        if (!original_report_created_at) { return; }

        // compute what can be shown
        const { valid_lines, valid_orders } = await ReduxFilters.applyFilters(
            this.props.lines_orders,
            this.props.filters,
            this.props.unscheduled_orders,
            this.props.materials,
            this.props.insights_mapped,
            report_data,
            original_report_created_at
        );
        // prepare maps for quick access
        const valid_lines_map = new Map<string, ValidLineOrders>();
        for (const valid_line of valid_lines) {
            valid_lines_map.set(valid_line.line_uuid, valid_line);
        }
        const valid_orders_map = new Map<string, ValidOrder>();
        for (const valid_order of valid_orders) {
            valid_orders_map.set(valid_order.uuid, valid_order);
        }
        const show_valid_lines = {
            valid_lines,
            valid_lines_map,
            valid_orders_map
        };
        // recompte heights
        this.updateHeights(valid_lines_map, valid_orders_map);
        // add to state
        this.props.reduxDispatch({ type: SET_SHOW_VALID_LINES, data: show_valid_lines });
    }

    handleMaterialChange = (selected_materials: SelectOptionItem[]) => {
        const material_external_ids = selected_materials.map(l => l.value);
        this.props.reduxDispatch({ type: SET_SELECTED_MATERIALS, data: material_external_ids });
    }

    render() {
        if (!this.props.show_planning_table_filter) return null;

        return (
            <div className="planning-table-filters p-3"
                style={{position: "sticky", top: "0px", backgroundColor: "white", zIndex: 2 }}
            >
                <div className="form-row">
                    <div style={{display: "flex", width: "100%", alignItems: "center" }}>
                        <TypeFilter />
                        <ConnectedLockLine />
                    </div>
                </div>
            </div>
        )
    }
}

const ConnectedFilters = connect(
    (state: ReduxState) => {
        const filters = state.gantt_chart_filters;
        const lines_orders = state.gantt_chart_lines_orders.lines_orders;
        const unscheduled_orders = state.gantt_chart_lines_orders.unscheduled_orders;
        const insights_mapped = state.gantt_chart_insights.insights_mapped ?
            state.gantt_chart_insights.insights_mapped : new Map();
        const properties = state.gantt_chart_properties;
        const report = state.gantt_chart_report;
        const materials = state.gantt_chart.materials;

        return {
            filters,
            lines_orders,
            unscheduled_orders,
            insights_mapped,
            properties,
            materials,
            report_data: report.report_data,
            show_only_unscheduled: properties.show_only_unscheduled,
            reschedule_order_uuid: filters.reschedule_order_uuid,
            original_report_created_at: report.original_report_created_at
        };
    },
    (dispatch) => ({ reduxDispatch: dispatch })
)(Filters);

export default ConnectedFilters;
