// @flow

import React from "react";
import SvgIcon, { type SvgIconProps } from "../SvgIcon";

const Apps = (props: SvgIconProps) => (
    <SvgIcon {...props}>
        <svg viewBox="0 0 18 18">
            <g transform="translate(-13 -20)">
                <path
                    d="M12,7a2,2,0,1,1,2-2A2,2,0,0,1,12,7Zm0,7a2,2,0,1,1,2-2A2,2,0,0,1,12,14Zm0,7a2,2,0,1,1,2-2A2,2,0,0,1,12,21Z"
                    transform="translate(3 17)"
                />
                <path
                    data-name="icon-dots-vertical"
                    d="M12,7a2,2,0,1,1,2-2A2,2,0,0,1,12,7Zm0,7a2,2,0,1,1,2-2A2,2,0,0,1,12,14Zm0,7a2,2,0,1,1,2-2A2,2,0,0,1,12,21Z"
                    transform="translate(10 17)"
                />
                <path
                    data-name="icon-dots-vertical"
                    d="M12,7a2,2,0,1,1,2-2A2,2,0,0,1,12,7Zm0,7a2,2,0,1,1,2-2A2,2,0,0,1,12,14Zm0,7a2,2,0,1,1,2-2A2,2,0,0,1,12,21Z"
                    transform="translate(17 17)"
                />
            </g>
        </svg>
    </SvgIcon>
);

export default Apps;
