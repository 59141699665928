// @flow


import { ticketingReducer } from "./ticketing";

import type { TicketingState, TicketingActions } from "./ticketing";

export type ActionReduxState = {
    ticketing: (state: TicketingState, action: TicketingActions) => TicketingState
}

export type ReduxState = {
    ticketing: TicketingState
}

export const reducers: ActionReduxState = {
    ticketing: ticketingReducer
};

