// @flow

// main imports
import React, { Component } from "react";
import ChevronListItem from "../react/ChevronListItem";
// models
import * as t from "../../lib/backend/manufacturing2.generated.types";

// import subcomponents
import { FormattedMessage } from "react-intl";

// defining types
type Props = {
    filter: string,
    selected: t.IProcessData | null,
    processes: t.IProcessData[],
    handleProcessClick: any
}

type State = {}

/**
 * Displaying production lines list.
 */
class ProcessesList extends Component<Props, State> {
    /**
     * Handler for clicking the item.
     */
    handleProcessClick(process: t.IProcessData, i: number) {
        // Ignore clicks for currently selected process.
        if (process === this.props.selected) {
            return;
        }

        // handle click in parent
        this.props.handleProcessClick(process);
    }

    /**
     * Search method within the processes list.
     *
     * @param {string} needle Needle that we are looking for.
     * @param {t.IProcessData} obj Object where we are looking for the needle.
     *
     * @return {boolean} Returning true if needle was found, false otherwise.
     */
    includes(needle: string, obj: t.IProcessData) {
        needle = needle.toLowerCase();
        if (needle.length === 0) return true;
        if ("bottleneck".indexOf(needle) > -1) {
            if (obj.bottleneck === true) return true;
        }
        if (obj.title.toLowerCase().indexOf(needle) > -1) return true;
        if (obj.material_title.toLowerCase().indexOf(needle) > -1) return true;
        if (obj.material_uuid.toLowerCase().indexOf(needle) > -1) return true;
        return false;
    }

    /**
     * Rendering JSX for current component.
     */
    render() {
        const processesList = this.props.processes.map((process, i) => {
            let is_active = this.props.selected === process;

            let isBottleNeck = process.bottleneck;

            if (!this.includes(this.props.filter, process)) return null;
            return <ChevronListItem
                text={<React.Fragment>
                    <div className="title">
                        <dt>{process.title}</dt>
                        <dd>{process.material_title}</dd>
                    </div>
                    {isBottleNeck &&
                        <div className="badge badge-danger"><FormattedMessage id="Manufacturing.Processes.bottleneck" defaultMessage="bottleneck" /></div>
                    }
                </React.Fragment>}
                onClick={() => this.handleProcessClick(process, i)}
                is_active={is_active}
            />
        })

        return (
            <ul className="nav nav-tabs">
                <div className="nav-tabs-parent" ref="nav">
                    {processesList}
                </div>
            </ul>
        )
    }
}

export default ProcessesList;
