// @flow
import React from "react";
import { withRouter } from 'react-router'

import { subscribe, MSG_TYPES, unsubscribe } from "../../../src/lib/PubSub";
import { FIELD_USER_TICKETS } from "../../lib/Auth";

import ReactRouterPropTypes from "react-router-prop-types";

type Props = {
    history: ReactRouterPropTypes.history
};
type State = {
    amount_of_tickets: number,
    subcribe_id: string
};

class TicketingHeaderIcon extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const subcribe_id = subscribe(MSG_TYPES.user_tickets_reloaded, () => this.load());
        this.state = {
            amount_of_tickets: 0,
            subcribe_id: subcribe_id
        };
    }

    componentDidMount() {
        this.load();
    }

    componentWillUnmount() {
        unsubscribe(this.state.subcribe_id);
    }

    async load() {
        const tickets = Number(localStorage.getItem(FIELD_USER_TICKETS));
        this.setState({ amount_of_tickets: tickets });
    }

    onClick(e: MouseEvent) {
        this.props.history.push("/ticketing");
    }

    render() {
        const style = {
            display: "flex",
            alignItems: "center",
            color: "#cbd2d9",
            fontSize: "18px",
            cursor: "pointer",
            height: "100%",
            paddingRight: "8px"
        }
        return <div style={style} onClick={(e) => this.onClick(e)}>
            <i className="fas fa-inbox"></i>
            {this.state.amount_of_tickets > 0 &&
                <div style={{
                    width: "17px",
                    height: "17px",
                    borderRadius: "25px",
                    border: "1px solid white",
                    background: "red",
                    position: "absolute",
                    marginLeft: "16px",
                    marginBottom: "15px",
                    fontSize: "10px",
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}>{this.state.amount_of_tickets}</div>
            }
        </div >
    }
}

export default withRouter(TicketingHeaderIcon);

