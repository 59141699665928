// @flow

// main imports
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

// models

// backend
import { waitForManufacturingAsync } from "../../lib/BusinessLogic";
import { getBackend } from "../../lib/backend/Backend2";
import * as t from "../../lib/backend/manufacturing2.generated.types";
import { niceNumber, renderTag } from "../../lib/Util";
import * as Auth from "../../lib/Auth";

// defining types
type Props = {
    selected: t.ILineData,
    editLink: string,
    kanbanLink: string,
    handleToDelete: any
}

type State = {
    lineGroups: t.ILineGroupData[],
    kanban: t.IKanbanLine
}

/**
 * Lines details component.
 */
class LinesDetails extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        const state: State = {
            lineGroups: [],
            kanban: {
                enabled: false,
                line_title: props.selected.title,
                line_uuid: props.selected.uuid,
                materials: []
            }
        };
        this.state = state;
    }

    componentDidMount() {
        // update manufacturing meta-data
        this.loadLineGroups();
        // get kanban data for selected line
        this.loadKanban();
    }

    async componentDidUpdate(prevProps: Props) {
        // do nothing if not selected
        if (!this.props.selected) { return; }
        // check if selected line changed
        if (!prevProps.selected || this.props.selected.uuid !== prevProps.selected.uuid) {
            // update manufacturing meta-data
            this.loadLineGroups();
            // get kanban data for selected line
            await this.loadKanban();
        }
    }

    async loadLineGroups() {
        // update manufacturing meta-data
        await waitForManufacturingAsync();
        // get line groups for selected line
        let { line_groups } = await getBackend().manufacturing.getLineGroups({});
        let lineGroups = line_groups.filter(x => x.line_uuids.indexOf(this.props.selected.uuid) >= 0);
        this.setState({ lineGroups });
    }

    async loadKanban() {
        try {
            const res = await getBackend().manufacturing.getLineKanban({ uuid: this.props.selected.uuid });
            this.setState({ kanban: res.data });
        } catch (err) {
            console.log(err);
        }
    }

    /**
     * Handle delete button clicks.
     */
    handleDelete = () => {
        if (this.props.selected) {
            this.props.handleToDelete(this.props.selected.uuid);
        }
    }

    renderMaterials() {
        return (
            this.state.kanban.materials.map((material, i) => {
                return (
                    <tr key={i}>
                        <td>{material.material_title}</td>
                        <td>{niceNumber(material.min_stock, 0)}</td>
                        <td>{niceNumber(material.max_stock, 0)}</td>
                    </tr>
                )
            })
        );
    }

    renderDashboardLinks() {
        return (this.state.kanban && this.state.kanban.dashboard_uuid) ? (
            <div className="button-list order-last ml-auto">
                <Link className="btn btn-outline-secondary" to={"/dashboard-public/" + this.state.kanban.dashboard_uuid}>
                    <FormattedMessage id="common.screen" defaultMessage="Screen" />
                </Link>
                {false && <Link className="btn btn-outline-secondary" to={"/api/v1.0/dashboards/" + this.state.kanban.dashboard_uuid + "/public-image"}>
                    Internet Explorer
                </Link>}
            </div>
        ) : "";
    }

    /**
     * Render component.
     */
    render() {
        if (this.props.selected === null) {
            return null;
        }
        const can_edit = Auth.isInRole(Auth.ROLE_ADMIN) || Auth.isInRole(Auth.ROLE_POWER_USER) || Auth.isInRole(Auth.ROLE_DEMO_USER);
        const can_edit_kanban = can_edit || Auth.isInRole(Auth.ROLE_MAN_SHOPFLOOR_MANAGER) || Auth.isInRole(Auth.ROLE_MAN_PLANNER);

        const editLink = `${this.props.editLink}${this.props.selected.uuid}`;
        const kanbanLink = `${this.props.kanbanLink}${this.props.selected.uuid}`;
        let tags = "/";

        if (this.props.selected.tags !== undefined) {
            // generate tags
            tags = Object.keys(this.props.selected.tags)
                .sort((a, b) => a.localeCompare(b))
                .map((el, i) => renderTag(i, el, this.props.selected.tags[el]));
        }

        return (
            <div className="tab-content">
                <div className="tab-pane active tab-pane-text">
                    <div className="d-flex">
                        {can_edit && <div className="button-list order-last ml-auto">
                            <Link className="btn btn-outline-primary" to={editLink}>
                                <FormattedMessage id="common.edit" defaultMessage="Edit" />
                            </Link>
                            {/* <button className="btn btn-outline-secondary" onClick={this.handleDelete}>
                                <FormattedMessage id="common.delete" defaultMessage="Delete" />
                            </button> */}
                        </div>}

                        <div className="order-first">
                            <h5 className="pane-title">
                                <em><FormattedMessage id="common.line" defaultMessage="Line" /></em>
                                {this.props.selected.title}
                            </h5>
                        </div>
                    </div>

                    <dl className="property-list">
                        <div>
                            <dt><FormattedMessage id="common.external_id" defaultMessage="External ID" /></dt>
                            <dd>
                                <div>
                                    {this.props.selected.external_id}
                                </div>
                            </dd>
                        </div>
                        <div>
                            <dt><FormattedMessage id="common.weight" defaultMessage="Weight" /></dt>
                            <dd>
                                <div>
                                    {this.props.selected.weight}
                                </div>
                            </dd>
                        </div>
                        <div>
                            <dt><FormattedMessage id="common.tags" defaultMessage="Tags" /></dt>
                            <dd>{tags}</dd>
                        </div>
                        <div>
                            <dt className="light"><FormattedMessage id="common.uuid" defaultMessage="UUID" /></dt>
                            <dd className="light">
                                <div>
                                    {this.props.selected.uuid}
                                </div>
                            </dd>
                        </div>
                    </dl>

                    <div className="card-deck">
                        <div className="card">
                            <div className="card-header text-uppercase bg-transparent">
                                <FormattedMessage id="common.plants" defaultMessage="Plants" />
                            </div>
                            <div className="card-body">
                                <dl className="property-list">
                                    <div>
                                        <dt><FormattedMessage id="common.plant" defaultMessage="Plant" /></dt>
                                        <dd>{this.props.selected.plant_title}</dd>
                                    </div>
                                    <div>
                                        <dt><FormattedMessage id="common.external_id" defaultMessage="External ID" /></dt>
                                        <dd>{this.props.selected.plant_external_id}</dd>
                                    </div>
                                    <div>
                                        <dt className="light">UUID</dt>
                                        <dd className="light">{this.props.selected.plant_uuid}</dd>
                                    </div>
                                </dl>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header text-uppercase bg-transparent">
                                <FormattedMessage id="common.linegroups" defaultMessage="Line-groups" />
                            </div>
                            <div className="card-body">
                                <dl className="property-list">
                                    {this.state.lineGroups.map(group => (
                                        <div key={group.uuid}>
                                            <dt><FormattedMessage id="common.linegroup" defaultMessage="Line-group" /></dt>
                                            <dd>{group.title}</dd>
                                        </div>
                                    ))}
                                </dl>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex">
                        {can_edit_kanban && <div className="button-list order-last ml-auto" style={{ marginTop: "15px" }}>
                            <Link className="btn btn-outline-primary" to={kanbanLink}>
                                <FormattedMessage id="common.edit" defaultMessage="Edit" />
                            </Link>
                        </div>}
                        <div className="order-first width100p" style={{ marginTop: "15px" }}>
                            <h5 className="pane-title">eKanBan</h5>
                        </div>
                    </div>
                    <div className="order-first width100p">
                        <div className="white_box charts">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th><FormattedMessage id="common.material" defaultMessage="Material" /></th>
                                        <th>Min</th>
                                        <th>Max</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.renderMaterials()}
                                </tbody>
                            </table>

                            {this.renderDashboardLinks()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default LinesDetails;
