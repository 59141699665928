// @flow
import React from "react";
import { connect } from "react-redux";
import Select, { components } from "react-select";
import * as t from "../../../lib/backend/manufacturing2.generated.types";
import { translate } from "../../IntlProviderWrapper";
import { getBackend as getBackend2 } from "../../../lib/backend/Backend2";
import { AVAILABLE_TYPES, INSIGHT_FILTERS } from "./reducers/ganttChartStandalone";
import { SET_INSIGHTS_ACTIONS_TYPES } from "./reducers/ganttChartStandalone";
import { INSIGHT_TYPES, LINE_GROUP_TAGS } from "../../../lib/ManufacturingTags.generated";
import * as BusinessLogic from "../../../lib/BusinessLogic";

import type { ReduxState } from "./reducers/reducers";
import type { SetInsightTypesActions, AvailableInsightTypes } from "./reducers/ganttChartStandalone";

type Props = {
    insight_types: AvailableInsightTypes[],
    hide_insight_types: AvailableInsightTypes[],
    line_group_uuid: string,
    reduxDispatch: (args: SetInsightTypesActions) => void
};
type State = {
    insight_types_translated: t.IInsightType[]
};

const SelectStyles = {
    option: (provided, state) => ({
        ...provided,
        backgroundColor: "unset",
        ":active": "unset"
    }),
    indicatorSeparator: state => ({
        display: 'none',
    }),
    container: (style) => ({ ...style, minWidth: 300 })
};

const MultiValue = ({ index, getValue }) => !index && translate("common.show_insights", "Show insights") + ` (${getValue().length})`;

const Option = (props) => {
    let style: any = {
        justifyContent: "flex-end",
        width: "15px",
        height: "25px",
        border: "1px #e8e8e8 solid",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundImage: "none",
        backgroundColor: "none"
    };

    const orange_insight_types = [
        INSIGHT_TYPES.operation_no_input_material,
        INSIGHT_TYPES.tool_overlap,
        INSIGHT_FILTERS.filter_account_for_deliveries,
        INSIGHT_FILTERS.filter_include_verp,
        INSIGHT_FILTERS.filter_use_safety_stock
    ];

    if (props.value === INSIGHT_TYPES.man_downtime) {
        style["backgroundImage"] = "url(../../img/gantt_downtime_01.svg)";
    } else if (props.value === INSIGHT_TYPES.tool_setup) {
        style["backgroundImage"] = "url(../../img/tool-setup-icon.png)";
    } else if (orange_insight_types.includes(props.value)) {
        style["backgroundColor"] = "#FFB68F";
    } else if (props.value === INSIGHT_TYPES.operation_delay_downstream) {
        style["backgroundColor"] = "#F99F9B";
    } else if (props.value === INSIGHT_TYPES.operation_constraint_violated) {
        style["backgroundColor"] = "#F99F9B";
    } else if (props.value === INSIGHT_TYPES.freeze_order_move) {
        style["border-style"] = "dotted";
        style["border-color"] = "#0000FF";
        style["border-width"] = "medium";
    } else if (props.value === INSIGHT_TYPES.conditional_capacity_violated) {
        style["backgroundColor"] = "#F99F9B";
    } else if (props.value === INSIGHT_TYPES.partially_scheduled) {
        style["border-style"] = "dotted";
        style["border-color"] = "#616e7c";
        style["border-width"] = "medium";
    }

    return (
        <div>
            <components.Option {...props}>
                <div style={{ display: "flex" }}>
                    <div style={{ display: "flex", alignItems: "center", width: "100%"}}>
                        <input
                            type="checkbox"
                            checked={props.isSelected}
                            onChange={e => null}
                        />{" "}
                        <label style={{marginLeft: "5px"}}>{props.label}</label>
                    </div>
                    <div key={props.value} style={style}></div>
                </div>
            </components.Option>
        </div>
    );
};

type SelectOptionItem = {
    value: AvailableInsightTypes,
    label: string
}

class InsightsTypesSelect extends React.Component<Props, State> {

    state = {
        insight_types_translated: []
    }

    async componentDidMount() {
        const res = await getBackend2().manufacturing.getInsightTypes({});
        this.setState({ insight_types_translated: res.insight_types });
    }

    onChange = (selected: SelectOptionItem[]) => {
        this.props.reduxDispatch({ type: SET_INSIGHTS_ACTIONS_TYPES, data: selected.map(s => s.value) });
    }

    mapOptions = () => {
        const show_conditional_capacity_constraint_insights = BusinessLogic.getLineGroupTagBool(
            this.props.line_group_uuid, LINE_GROUP_TAGS.extract_conditional_capacity_constraint_insights, false);
        if (this.state.insight_types_translated.length > 0) {
            let available_types = AVAILABLE_TYPES;

            if (this.props.hide_insight_types) {
                available_types = AVAILABLE_TYPES.filter(type => !this.props.hide_insight_types.includes(type));
            }

            if (!show_conditional_capacity_constraint_insights) {
                available_types = available_types.filter(type => type != INSIGHT_TYPES.conditional_capacity_violated);
            }

            return available_types.map(type => {
                const label = this.state.insight_types_translated.find(el => el.code === type)
                return {
                    label: label ? label.title : translate(`Manufacturing.InsightTypesSelect.${type}`, type),
                    value: type
                }
            });
        }
        return [];
    }

    getValues = () => {
        return this.mapOptions().filter(o => this.props.insight_types.includes(o.value));
    }

    render() {
        return <div id="insight-types-select">
            <Select
                closeMenuOnSelect={false}
                isMulti
                components={{ Option, MultiValue }}
                hideSelectedOptions={false}
                isClearable={false}
                options={this.mapOptions()}
                backspaceRemovesValue={false}
                onChange={this.onChange}
                styles={SelectStyles}
                value={this.getValues()}
                placeholder={translate("common.show_insights", "Show insights")}
                theme={theme => ({
                    ...theme,
                    spacing: {
                        ...theme.spacing,
                        baseUnit: 2,
                        controlHeight: 30
                    }
                })}
            />
        </div>
    }
}

export default connect(
    (state: ReduxState) => {
        return {
            insight_types: state.standalone_gantt.insight_types,
            line_group_uuid: state.gantt_chart_properties.line_group_uuid
        }
    },
    (dispatch) => ({reduxDispatch: dispatch})
)(InsightsTypesSelect)
