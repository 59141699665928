// @flow

import * as React from "react";
import * as Highcharts from "highcharts";
import HC_more from "highcharts/highcharts-more";
import type { TsRangeObj } from "../lib/Models";

// module is needed for AreaRange type
HC_more(Highcharts)

// one point in graph
type DatapointHc = {
    x: number,
    y: number
};

// one point in graph
type MinMaxDatapointHc = {
    x: number,
    low: number,
    high: number
};

type State = { };

type Props = {
    options: {
        id: string,
        height: number,
        name: string,
        type: string,
        y_label: string | null,
        ts: Array<TsRangeObj>,
        y_max_val?: number | null,
        x_min_val?: number,
        x_max_val?: number,
        threshold?: number,
        color?: string
    }
};

class HighchartsLineAreaRange extends React.Component<Props, State> {
    constructor () {
        super();
        this.state = { ts: [] };
    }

    componentDidMount() {
        // get datapointes for the series and prepare it for display
        let datapoints: Array<DatapointHc> = [ ];
        let minmaxpoints: Array<MinMaxDatapointHc> = [ ];
        for (let value of this.props.options.ts) {
            datapoints.push({x: value.ts, y: value.val});
            minmaxpoints.push({x: value.ts, low: value.low, high: value.high });
        }
        const series = {
            name: this.props.options.name,
            type: this.props.options.type,
            data: datapoints,
            turboThreshold: 0,
            color: this.props.options.color || "#2186eb"
        };

        const range = {
            name: 'Range',
            type: 'arearange',
            data: minmaxpoints,
            turboThreshold: 0,
            color: this.props.options.color || "#2186eb",
            lineWidth: 0,
            linkedTo: ':previous',
            fillOpacity: 0.3,
            zIndex: 0,
            marker: {
                enabled: false
            }
        }

        Highcharts.setOptions({
            global: {
                useUTC: false
            }
        });

        // initialize chart
        const options = {
            chart: {
                height: this.props.options.height,
            },
            xAxis: {
                type: "datetime",
                min: this.props.options.x_min_val,
                max: this.props.options.x_max_val
            },
            yAxis: {
                title: { text: this.props.options.y_label },
                max: this.props.options.y_max_val,
                plotLines: [{
                    value: this.props.options.threshold,
                    color: "gray",
                    dashStyle: "dot",
                    width: 2,
                    label: {
                        //text: "Threshold value: " + this.props.options.threshold
                    }
                }]
            },
            legend: { enabled: false },
            series: [ series, range ],
            title: { text: null },
            subtitle: { text: null }
        };
        Highcharts.chart(this.props.options.id, options);
    }

    render() {
        return (
            <div id={this.props.options.id}></div>
        );
    }

}
export default HighchartsLineAreaRange;
