// @flow
import React from "react";
import { connect } from "react-redux";

import type { ReduxState } from "./reducers/reducers";

type Props = { loading: boolean };
type State = {};

class PlanningTableSaveSpinner extends React.Component<Props, State> {
    render() {
        if (this.props.loading) {
            return <i className="fas fa-spinner fa-spin"></i>;
        }
        return null;
    }
}

export default connect(
    (state: ReduxState) => {
        const parallel_report = state.gantt_chart_parallel_report;
        return {
            loading: parallel_report.parallel_report_loading
        }
    },
    null
)(PlanningTableSaveSpinner);
