// @flow

import * as React from "react";

import OrderTableProductionModal from "./OrderTableProductionModal";

import * as t from "../../lib/backend/manufacturing2.generated.types";
import { niceNumber, niceDateTime } from "../../lib/Util";

type State = {
    selected_order: t.IOrderModelBase | null;
    show_modal: boolean
};

type Props = {
    orders: t.IOrderModelBase[]
}


class OrderListSimple extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        const state: State = {
            selected_order: null,
            show_modal: false
        };
        this.state = state;
    }

    render() {
        return (
            <React.Fragment>
                {this.state.selected_order != null && <OrderTableProductionModal
                        show={this.state.show_modal}
                        selected_order_uuid={this.state.selected_order.uuid}
                        selected_order_external_id={this.state.selected_order.external_id}
                        process_num={this.state.selected_order.process_num}
                        onShowOrHideOrderModal={() => {
                            this.setState({ show_modal: !this.state.show_modal });
                        }}
                        onOrderSave={() => {}}
                        show_columns={[
                            "process_num",
                            "title",
                            "quantity_produced",
                            "quantity_scrap",
                            "quantity_total",
                            "output_stock",
                            "status",
                            "skip_sim"
                        ]}
                    />}
                <table className="table table-striped table-bordered">
                    <thead>
                        <tr>
                            <th>Order</th>
                            <th>Operation</th>
                            <th>Start</th>
                            <th>End</th>
                            <th>Quantity</th>
                            <th>Normative</th>
                            <th>Line</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.orders.map((order, i) => (
                            <tr key={i}>
                                <td>
                                    <a href="#" onClick={() => { this.setState({ selected_order: order, show_modal: true }); }}>
                                        {order.external_id}
                                    </a>
                                </td>
                                <td>{order.process_num}</td>
                                <td>{niceDateTime(order.time_start || order.earliest_end)}</td>
                                <td>{niceDateTime(order.time_end || order.earliest_end)}</td>
                                <td>{niceNumber(order.quantity_total, 2)}</td>
                                <td>{order.norm_base_units > 0 ? order.norm_machine_time / order.norm_base_units : ""}</td>
                                <td>{order.line.external_id}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </React.Fragment>
        );
    }
};

export default OrderListSimple;