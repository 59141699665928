// @flow
import * as React from "react";
import { translate, getLang } from "../components/IntlProviderWrapper";

import { getBackend, IBackend } from "./backend/Backend2";
import * as man_t from "./backend/manufacturing2.generated.types";
import * as t2 from "./SimulationReportModels";
import { getUserPlants, getUserLineGroups, getLoggedinUser } from "./Auth";
import {
    shiftNumber, fromShiftTag, toISOTimeString, toISODateString,
    niceNumber, positiveModulo, TIME_RANGES
} from "./Util";
import { JOB_STATUS } from "./CommonConsts.generated";
import { COPY_WEEKLY_ASSIGNMENTS_STRATEGY, INSIGHT_TYPES } from "./ManufacturingTags.generated";
import { subscribe, MSG_TYPES, publish } from "./PubSub";
import * as Auth from "./Auth";

const FIELD_FEATURE_MATRIX_SET = "feature_matrix_set";
const FIELD_FEATURES_MANUFACTURING = "features-manufacturing";
const FIELD_FEATURES_BASIC = "features-basic";
const FIELD_SYS_FLAG_ENV = "sys-flags-env";
const FIELD_SYS_FLAG_LANG = "sys-flags-lang";
const FIELD_SYS_FLAG_INTEGRATION = "sys-flags-integration";

//  Should be called on app start (initial page load)
export function clearFeatureMatrix() {
    localStorage.setItem(FIELD_FEATURE_MATRIX_SET, "false");
}

export function getInsightDowntimeTitle(insight: man_t.IEventDataEx): string {
    const user_lang = getLang();
    if (insight.extra_data.title_enriched_lang && insight.extra_data.title_enriched_lang[user_lang]) {
        return insight.extra_data.title_lang[user_lang];
    }
    return insight.extra_data.title;
}

export function getInsightTooltipTextDowntime(insight: man_t.IEventDataEx): string {
    const d = toISOTimeString(new Date(insight.ts));
    const dn = insight.extra_data.duration || (insight.ts - insight.ts_to) / TIME_RANGES.HOUR;
    const n = niceNumber(dn, 1);
    const comment = (insight.extra_data.user_comment ? "- " + insight.extra_data.user_comment : "");
    const user_lang = getLang();
    let title = insight.extra_data.title;
    if (insight.extra_data.title_enriched_lang && insight.extra_data.title_enriched_lang[user_lang]) {
        title = insight.extra_data.title_enriched_lang[user_lang];
    }
    return `${title} @${d} - ${n}h ${comment}`;
}

export function getInsightTooltipTextToolSetup(insight: man_t.IEventDataEx): string {
    return `${translate("common.tool_setup", "Tool setup")}: ${insight.extra_data.material_title} (${insight.extra_data.material})`;
}

export function getInsightTooltipTextToolOverlap(insight: man_t.IEventDataEx): string {
    return `${insight.title}\n${insight.description}`;
}

export function getInsightTooltipTextNoWorkInShift(insight: man_t.IEventDataEx): string {
    return `${insight.title}: ${insight.description}`;
}

export function getInsightTooltipTextOperationDelayDownstream(insight: man_t.IEventDataEx): string {
    return `${insight.title}\n${insight.description}`;
}

export function getInsightTooltipTextOperationNoInputMaterial(insight: man_t.IEventDataEx): string {
    return `${insight.title}\n${insight.description}`;
}

export function getInsightTooltipTextOperationConstraintViolated(insight: man_t.IEventDataEx): string {
    return `${insight.title}. ${insight.description}`;
}

export function getInsightTooltipTextOutOfStock(insight: man_t.IEventDataEx): string {
    return `${insight.title}\n${insight.description}`;
}

export function getConditionalCapacityViolated(insight: man_t.IEventDataEx): string {
    return `${insight.title}\n${insight.description}`;
}

export function getInsightTooltipText(insight: man_t.IEventDataEx): string {
    if (insight.type === INSIGHT_TYPES.downtime) {
        return getInsightTooltipTextDowntime(insight);
    }
    if (insight.type === INSIGHT_TYPES.man_downtime) {
        return getInsightTooltipTextDowntime(insight);
    }
    if (insight.type === INSIGHT_TYPES.tool_setup) {
        return getInsightTooltipTextToolSetup(insight);
    }
    if (insight.type === INSIGHT_TYPES.tool_overlap) {
        return getInsightTooltipTextToolOverlap(insight);
    }
    if (insight.type === INSIGHT_TYPES.no_work_in_shift) {
        return getInsightTooltipTextNoWorkInShift(insight);
    }
    if (insight.type === INSIGHT_TYPES.operation_delay_downstream) {
        return getInsightTooltipTextOperationDelayDownstream(insight);
    }
    if (insight.type === INSIGHT_TYPES.operation_no_input_material) {
        return getInsightTooltipTextOperationNoInputMaterial(insight);
    }
    if (insight.type === INSIGHT_TYPES.operation_constraint_violated) {
        return getInsightTooltipTextOperationConstraintViolated(insight);
    }
    if (insight.type === INSIGHT_TYPES.out_of_stock_plant) {
        return getInsightTooltipTextOutOfStock(insight);
    }
    if (insight.type === INSIGHT_TYPES.conditional_capacity_violated) {
        return getConditionalCapacityViolated(insight);
    }
    return "";
}

export function isProdEnv(env_flag: string): boolean {
    switch (env_flag) {
        case "dev": return false;
        case "beta": return false;
        case "alpha": return false;
        default: return true;
    }
}

export function isQlectorUserOnProd(): boolean {
    const is_prod = isProdEnv(getSysFlag("env"));
    const user = getLoggedinUser();
    return is_prod && user.username.endsWith("qlector.com");
}

export function getInsightTooltipContent(insight: man_t.IEventDataEx): React.Node {
    switch (insight.type) {
        case INSIGHT_TYPES.downtime:
        case INSIGHT_TYPES.man_downtime:
            const d = toISOTimeString(new Date(insight.ts));
            const n = niceNumber(insight.extra_data.duration, 1);
            const comment = (insight.extra_data.user_comment ? "- " + insight.extra_data.user_comment : "");
            const user_lang = getLang();
            let title = insight.extra_data.title;
            if (insight.extra_data.title_enriched_lang && insight.extra_data.title_enriched_lang[user_lang]) {
                title = insight.extra_data.title_enriched_lang[user_lang];
            }
            return (
                <React.Fragment>
                    <strong>{title}</strong> {d} - {n}h {comment}
                </React.Fragment>
            );
        case INSIGHT_TYPES.tool_setup:
            return (
                <React.Fragment>
                    <strong>{translate("common.tool_setup", "Tool setup")}</strong>:
                    {" "}
                    {insight.extra_data.material_title}
                    {" "}
                    ({insight.extra_data.material})
                </React.Fragment>
            );
        case INSIGHT_TYPES.tool_overlap:
        case INSIGHT_TYPES.operation_delay_downstream:
        case INSIGHT_TYPES.operation_no_input_material:
        case INSIGHT_TYPES.out_of_stock_plant:
            return (
                <React.Fragment>
                    <strong>{insight.title}</strong>
                    <br />
                    {insight.description}
                </React.Fragment>
            );
        case INSIGHT_TYPES.no_work_in_shift:
            return (
                <React.Fragment>
                    <strong>{insight.title}</strong>: {insight.description}
                </React.Fragment>
            );
        case INSIGHT_TYPES.operation_constraint_violated:
            return (
                <React.Fragment>
                    <strong>{insight.title}</strong>. {insight.description}
                </React.Fragment>
            );
        case INSIGHT_TYPES.conditional_capacity_violated:
            return (
                <React.Fragment>
                    <strong>{insight.title}</strong>. {insight.description}
                </React.Fragment>
            );
        default:
            return null;
    }
}

export function getOrderModelParamsTooltip(order: t2.ISimulationReportOrderEx): string {
    if (order.model_normative_fallback) {
        return translate("ShiftTableProduction.based_on_normative", "Prediction based on normative");
    } else {
        return translate("ShiftTableProduction.based_on_history", "Prediction based on history") + "\n" +
            `${translate("ShiftTableProduction.hours_remaining", "hoursRemaining")} ` +
            `= ${niceNumber(order.model_slope, 4)} ${translate("ShiftTableProduction.parts_remaining", "partsRemaining")} ` +
            `+ (${translate("ShiftTableProduction.parts_remaining", "partsRemaining")} / ` +
            `${translate("ShiftTableProduction.parts_total", "partsTotal")}) x ${niceNumber(order.model_intercept, 4)}`;
    }
}

export async function initBusinessLogic() {
    try {
        await loadFeatureMatrixAsync();
    } finally {
        await initManufacturingCache();
    }
}

export async function loadFeatureMatrixAsync() {
    if (
        (localStorage.getItem(FIELD_FEATURE_MATRIX_SET) === null) ||
        (localStorage.getItem(FIELD_FEATURE_MATRIX_SET) === false) ||
        (localStorage.getItem(FIELD_FEATURE_MATRIX_SET) === "false")
    ) {
        const response = await getBackend().common.getFeaturesMatrix({});
        localStorage.setItem(FIELD_FEATURE_MATRIX_SET, "true");
        localStorage.setItem(FIELD_FEATURES_BASIC, response.basic ? "true" : "false");
        localStorage.setItem(FIELD_FEATURES_MANUFACTURING, response.manufacturing ? "true" : "false");
        const sys_flags = response.sys_flags;
        localStorage.setItem(FIELD_SYS_FLAG_ENV, (sys_flags.env || "dev").toLowerCase());
        localStorage.setItem(FIELD_SYS_FLAG_LANG, sys_flags.lang || "en");
        localStorage.setItem(FIELD_SYS_FLAG_INTEGRATION, sys_flags.integration || "");
    }
}

export function displayFeature(feature: string): boolean {
    if (
        (localStorage.getItem(FIELD_FEATURE_MATRIX_SET) === null) ||
        (localStorage.getItem(FIELD_FEATURE_MATRIX_SET) === false) ||
        (localStorage.getItem(FIELD_FEATURE_MATRIX_SET) === "false")
    ) {
        console.log("displayFeature() - Features matrix not initialized yet!");
        return false;
    }
    if (feature === "manufacturing") {
        return localStorage.getItem(FIELD_FEATURES_MANUFACTURING) === "true";
    }
    if (feature === "basic") {
        return true;
    }
    return false;
}


export function getSysFlag(sysFlag: string): string {
    if (
        (localStorage.getItem(FIELD_FEATURE_MATRIX_SET) === null) ||
        (localStorage.getItem(FIELD_FEATURE_MATRIX_SET) === false) ||
        (localStorage.getItem(FIELD_FEATURE_MATRIX_SET) === "false")
    ) {
        console.log("getSysFlag() - Features matrix not initialized yet!");
        return "";
    }
    if (sysFlag === "env") {
        return localStorage.getItem(FIELD_SYS_FLAG_ENV) || "";
    }
    if (sysFlag === "lang") {
        return localStorage.getItem(FIELD_SYS_FLAG_LANG) || "";
    }
    if (sysFlag === "integration") {
        return localStorage.getItem(FIELD_SYS_FLAG_INTEGRATION) || "";
    }
    return "";
}

/** Detect situation when insight's ts belongs to different shift than its content. Change ts to appropriate value */
export function updateTsForInsights(insights: Array<man_t.IEventDataEx>): void {
    insights.forEach(insight => {
        const shiftTag1 = shiftNumber(new Date(insight.ts)).shift_tag;
        if (insight.tags.shift_tag && insight.tags.shift_ts && shiftTag1 !== insight.tags.shift_tag) {
            // tag indicates that this insight "belongs" to another shift - change ts to achieve correct ordering
            insight.ts = +insight.tags.shift_ts;
        }
    });
}

/** Override timestamps for insights with "start-of-shift" timestamps */
export function updateTsForInsightsToShift(insights: Array<man_t.IEventDataEx>): void {
    insights.forEach(insight => {
        if (insight.tags.shift_tag) {
            const d = fromShiftTag(insight.tags.shift_tag);
            insight.extra_data.$ts = d.getTime();
        } else {
            insight.extra_data.$ts = insight.ts;
        }
    });
}

//////////////////////////////////////////////////////////////////////////////////////////////////

// array of plant objects
let cache_plants: man_t.IPlantData[] = [];
// array of line groups
let cache_line_groups: man_t.ILineGroupData[] = [];
// map of linegroups: <linegroup_uuid, linegroup_object>
let cache_line_groups_map: Map<string, man_t.ILineGroupData> = new Map();
// map of line weights: <line_uuid, weight>
// weight is computed based on plant wieght, linegroup_weight and line weight
let cache_line_weights_map: Map<string, number> = new Map();
// map of line titles: <line_uuid, title>
let cache_line_titles_map: Map<string, string> = new Map();
let pending_callbacks = []; // empty array indicates cache is still pending

/** Initializes manufacturing cache */
async function initManufacturingCache() {
    subscribe(MSG_TYPES.user_profile_reloaded, async () => {
        await cacheRefresh();
        const dt = new Date().toISOString();
        console.log(dt, "Automatic BL cache refresh done!");
    });
    await cacheRefresh();
}

/** Call this function before using other business logic methods */
function waitForManufacturing(done: (any => void)) {
    if (!pending_callbacks) {
        // array is null, cache has been initialized
        done();
        return;
    }
    pending_callbacks.push(done);
}

/** Call this Promise-based function before using other business logic methods */
export function waitForManufacturingAsync(): Promise<void> {
    return new Promise((resolve, reject) => {
        try {
            waitForManufacturing(resolve);
        } catch (err) {
            reject(err);
        }
    });
}

/** Forces all cached data to be re-loaded, e.g. after editing. */
export async function forceManufacturingCacheRefresh(): Promise<void> {
    await cacheRefresh();
}

/** Refreshes BL cache */

async function cacheRefresh(): Promise<void> {
    if (getLoggedinUser().username === "") {
        // user not logged in - clear all caches, don't go to server
        cache_plants = [];
        cache_line_groups = [];
        cache_line_groups_map = new Map();
        cache_line_weights_map = new Map();
        cache_line_titles_map = new Map();
        return;
    }
    const existing_data = JSON.stringify([cache_plants, cache_line_groups]);
    console.log("Refreshing BL cache...");

    // get plants
    const res_plants = await getBackend().manufacturing.getPlants({});
    const allowed_plants = getUserPlants();
    // filter only allowed plants for the user
    cache_plants = res_plants.plants.filter(x => allowed_plants.indexOf(x.uuid) >= 0);
    // filter plants by weight
    cache_plants.sort((a, b) => (a.weight > b.weight) ? 1 : -1);

    // get linegroups
    const res_linegroups = await getBackend().manufacturing.getLineGroups({});
    const allowed_line_groups = getUserLineGroups();
    // filter only allowed linegroups for the user
    cache_line_groups = res_linegroups.line_groups.filter(x => allowed_line_groups.indexOf(x.uuid) >= 0);
    // sort linegroups by weight
    cache_line_groups.sort((a, b) => (a.weight > b.weight) ? 1 : -1);
    // clear
    cache_line_groups_map.clear();
    // set linegroup_uuid, linegroup_object map
    cache_line_groups.forEach(x => {
        cache_line_groups_map.set(x.uuid, x);
    });

    // get lines
    const res_lines = await getBackend().manufacturing.getLines({});
    cache_line_weights_map.clear();
    cache_line_titles_map.clear();
    for (const x of res_lines.lines) {
        cache_line_weights_map.set(x.uuid, x.weight);
        cache_line_titles_map.set(x.uuid, x.title);
    }

    console.log("BL cache refreshed.");
    const tmp_pending_callbacks = pending_callbacks || [];
    pending_callbacks = null;
    for (const cb of tmp_pending_callbacks) {
        cb();
    }
    const new_data = JSON.stringify([cache_plants, cache_line_groups]);
    if (new_data !== existing_data) {
        publish(MSG_TYPES.manufacturing_data_updated, {});
    } else {
        console.log("BL cache refreshed, but no changes detected.");
    }
}

// returns only line groups include plant_uuid among plants
// the user must have access to line groups and plant
export function getLineGroupsForUserPlant(plant_uuid: string): man_t.ILineGroupData[] {
    if (!cache_plants.some(x => x.uuid === plant_uuid)) {
        return [];
    } else {
        const res = cache_line_groups.filter(lg =>
            lg.plant_uuids.length > 0 &&
            lg.plant_uuids.includes(plant_uuid)
        );
        return JSON.parse(JSON.stringify(res));
    }
}

// returns a single line group if the user has access to it (or null otherwise)
export function getLineGroupForUser(line_group_uuid: string): man_t.ILineGroupData | null {
    const hit = cache_line_groups_map.get(line_group_uuid);
    return (hit == undefined) ? null: JSON.parse(JSON.stringify(hit));
}

// returns all line groups that the user has access to
export function getLineGroupsForUser(): man_t.ILineGroupData[] {
    return JSON.parse(JSON.stringify(cache_line_groups));
}

export function getLineGroupForLine(line_uuid: string): man_t.ILineGroupData | null {
    for (const line_group of cache_line_groups) {
        for (const lu of line_group.line_uuids) {
            if (lu === line_uuid) {
                return JSON.parse(JSON.stringify(line_group));
            }
        }
    }
    return null;
}

export function getLineGroupsForLine(line_uuid: string, prefered_line_group_uuid: string): man_t.ILineGroupData[] {
    const line_groups = new Map < string, man_t.ILineGroupData> ();
    for (const line_group of cache_line_groups) {
        for (const lu of line_group.line_uuids) {
            if (lu === line_uuid) {
                line_groups.set(line_group.uuid, JSON.parse(JSON.stringify(line_group)));
            }
        }
    }

    const prefered_line_group = line_groups.get(prefered_line_group_uuid);
    if (prefered_line_group) {
        return [prefered_line_group];
    }

    return [...line_groups.values()];
}

// returns all plants that the user has access to
export function getPlantsForUser(): man_t.IPlantData[] {
    return JSON.parse(JSON.stringify(cache_plants));
}

// filters an array of line-group objects
export function filterLineGroupsForUser(line_groups: any[]): any[] {
    return line_groups
        .filter(x => cache_line_groups.some(y => y.uuid === x));
}

// filters an array of line objects
export function filterLinesForUser(lines: any[], skip_if_admin?: boolean): any[] {
    if (skip_if_admin && (Auth.isInRole(Auth.ROLE_ADMIN) || Auth.isInRole(Auth.ROLE_POWER_USER) || Auth.isInRole(Auth.ROLE_DEMO_USER))) {
        return lines;
    }
    return lines
        .filter(x => cache_line_groups.some(y => y.line_uuids.indexOf(x.uuid) >= 0))
        .filter(x => cache_plants.some(y => y.uuid === x.plant_uuid));
}

// filters and array of plant objects
export function filterPlantsForUser(plants: any[]): any[] {
    return plants
        .filter(x => cache_plants.some(y => y.uuid === x.uuid));
}

// get weight for given line
export function getLineWeight(line_uuid: string): number {
    if (cache_line_weights_map.has(line_uuid)) {
        const weight = cache_line_weights_map.get(line_uuid);
        return weight !== undefined ? weight : -1;
    }
    return -2;
}

// get title for given line
export function getLineTitle(line_uuid: string): string {
    if (cache_line_titles_map.has(line_uuid)) {
        const title = cache_line_titles_map.get(line_uuid);
        return title !== undefined ? title : "-";
    }
    return "";
}

export function getPlantTags(plant_uuid: string): man_t.ITags {
    const plant = getPlantsForUser().find(p => p.uuid === plant_uuid);
    return plant && plant.tags ? plant.tags : {};
}

export function getPlantTagBool(plant_uuid: string, key: string, default_val: boolean): boolean {
    const plant = getPlantsForUser().find(p => p.uuid === plant_uuid);
    return (plant && plant.tags && plant.tags[key] !== undefined) ? plant.tags[key] === "true" : default_val;
}

export function getPlantTagStr(plant_uuid: string, key: string, default_val: string): string {
    const plant = getPlantsForUser().find(p => p.uuid === plant_uuid);
    return (plant && plant.tags && plant.tags[key] !== undefined) ? plant.tags[key] : default_val;
}

// returns tag value if exists, else returns given default
export function getLineGroupTagStr(line_group_uuid: string, key: string, default_val: string): string {
    const line_group = getLineGroupForUser(line_group_uuid);
    return (line_group !== null && line_group.tags[key] !== undefined) ?
        line_group.tags[key] : default_val;
}

export function getLineGroupTagBool(line_group_uuid: string, key: string, default_val: boolean): boolean {
    const line_group = getLineGroupForUser(line_group_uuid);
    return (line_group !== null && line_group.tags[key] !== undefined) ?
        line_group.tags[key] === "true" : default_val;
}

// returns tag value if exists, else returns given default
export function getLineGroupTagInt(line_group_uuid: string, key: string, default_val: number): number {
    const line_group = getLineGroupForUser(line_group_uuid);
    return (line_group !== null && line_group.tags[key] !== undefined) ?
        parseInt(line_group.tags[key], 10) : default_val;
}

// returns tag value if exists, else returns given default
export function getLineGroupTagJson<T>(line_group_uuid: string, key: string, default_val: T): T {
    const line_group = getLineGroupForUser(line_group_uuid);
    return (line_group !== null && line_group.tags[key] !== undefined) ?
        (JSON.parse(line_group.tags[key]): T) : default_val;
}

export function getLineGroupTags(line_group_uuid: string) {
    const line_group = getLineGroupForUser(line_group_uuid);
    return line_group !== null ? line_group.tags : {};
}

/** Available strategies for copying assignments between weeks */
export const ShiftCopyingStrategyFreeze = Object.freeze(COPY_WEEKLY_ASSIGNMENTS_STRATEGY);
export type ShiftCopyingStrategy = $Values<typeof ShiftCopyingStrategyFreeze>;

/** Callback for determinig allowed shift for person */
export type PersonAllowedShifts = (string) => boolean[];

/** Smart copying of assignments between weeks */
export function copyShiftAssignmentsBetweenWeeks(
    prevWeek: man_t.IShiftPersonLineRec,
    currWeek: man_t.IShiftPersonLineRec,
    strategy: ShiftCopyingStrategy,
    personAllowedShifts: PersonAllowedShifts,
    week_start_shift_offset: number,
    rotate_opposite_direction?: boolean = false
) {
    // non-matching dimensions, don't do anything
    if (currWeek.shifts.length !== prevWeek.shifts.length) {
        return;
    }
    if (strategy === COPY_WEEKLY_ASSIGNMENTS_STRATEGY.copy) {
        for (let i = 0; i < currWeek.shifts.length; i++) {
            currWeek.shifts[i].persons = prevWeek.shifts[i].persons;
        }
    } else if (strategy === COPY_WEEKLY_ASSIGNMENTS_STRATEGY.shift_preference_rotate) {
        // smart rotation based on shift preference

        const psh = prevWeek.shifts;
        const csh = currWeek.shifts;
        // first reset all assignments in the new week
        for (const item of csh) {
            item.persons = [];
        }

        const shifts_per_day = 3;
        const rotate_direction = (rotate_opposite_direction) ? -1 : 1;

        // go through each person in and rotate her independently
        // determine the day to use as role-model - among Mon, Tue and Wed chose the one with the most assigned people
        const getAssignedPeopleForDay = (day: number) => {
            return psh[shifts_per_day * day + 0].persons.length +
                psh[shifts_per_day * day + 1].persons.length +
                psh[shifts_per_day * day + 2].persons.length;
        }
        const people_per_day = [
            getAssignedPeopleForDay(0),
            getAssignedPeopleForDay(1),
            getAssignedPeopleForDay(2)
        ];
        const highest_day = people_per_day.indexOf(Math.max(...people_per_day)); // get index of day with max value
        const anchor_shift = highest_day * shifts_per_day;
        for (let shift_of_day = anchor_shift; shift_of_day < anchor_shift + shifts_per_day; shift_of_day++) {
            for (const person of psh[shift_of_day].persons) {
                const allowed_shifts = personAllowedShifts(person.uuid);
                if (allowed_shifts.every(x => x === false)) {
                    // this person has no allowed shift, just skip her
                    continue;
                }
                // now determine the shift for this week by shifting previous-week's shift
                // and take into account allowed shifts
                let shift_this_week = positiveModulo(shift_of_day + rotate_direction, shifts_per_day);
                while (!allowed_shifts[shift_this_week]) {
                    shift_this_week += rotate_direction;
                    shift_this_week = positiveModulo(shift_this_week, shifts_per_day);
                }

                // also calculate shift for the next week (current + 1)
                let shift_next_week = positiveModulo(shift_this_week + rotate_direction, shifts_per_day);
                while (!allowed_shifts[shift_next_week]) {
                    shift_next_week += rotate_direction;
                    shift_next_week = positiveModulo(shift_next_week, shifts_per_day);
                }

                // assign this person to calculated shift in the current week
                for (let j = 0; j < 5; j++) {
                    const shift = csh[shifts_per_day * j + shift_this_week];
                    if (j === 4 && shift_this_week === 2 && week_start_shift_offset == -1) {
                        // don't add to friday night shift
                    } else {
                        shift.persons.push(person);
                    }
                }

                // if next week this person works in shift 2 (night shift), add her to this week's sunday's night shift
                if (shift_next_week === 2 && week_start_shift_offset == -1) {
                    const shift = csh[csh.length - 1];
                    shift.persons.push(person);
                }
            }
        }
    } else if (strategy === COPY_WEEKLY_ASSIGNMENTS_STRATEGY.four_shift_rotation) {

        const psh = prevWeek.shifts;
        // find the day in the week when the first shift changed from Monday's assignments
        const shift_0 = psh[0].persons.map(x => x.uuid).join(",");
        let day_of_switch = 0;
        while (++day_of_switch < 7) {
            const shift_x = psh[3 * day_of_switch].persons.map(x => x.uuid).join(",");
            if (shift_x !== shift_0) {
                break;
            }
        }
        // if we reached the day 7 this means that there is no data at all
        if (day_of_switch === 7) {
            day_of_switch = 1;
        }
        const groups: string[][] = [[], [], [], []];
        if (rotate_opposite_direction) {
            // on that day group 0 wasn't working, but the other groups were assigned like this:
            // 1. shift = group1
            // 2. shift = group2
            // 3. shift = group3
            groups[0] = psh[0].persons.map(x => x.uuid);
            groups[1] = psh[3 * day_of_switch].persons.map(x => x.uuid);
            groups[2] = psh[3 * day_of_switch + 1].persons.map(x => x.uuid);
            groups[3] = psh[3 * day_of_switch + 2].persons.map(x => x.uuid);
        } else {
            // on that day group 0 wasn't working, but the other groups were assigned like this:
            // 1. shift = group1
            // 2. shift = group3
            // 3. shift = group2
            groups[0] = psh[0].persons.map(x => x.uuid);
            groups[1] = psh[3 * day_of_switch].persons.map(x => x.uuid);
            groups[2] = psh[3 * day_of_switch + 2].persons.map(x => x.uuid);
            groups[3] = psh[3 * day_of_switch + 1].persons.map(x => x.uuid);
        }

        // collect people data
        const map_uuid2person: Map<string, man_t.IShiftPersonLineRecPerson> = new Map();
        psh[0].persons.forEach(person => map_uuid2person.set(person.uuid, person));
        psh[3 * day_of_switch].persons.forEach(person => map_uuid2person.set(person.uuid, person));
        psh[3 * day_of_switch + 1].persons.forEach(person => map_uuid2person.set(person.uuid, person));
        psh[3 * day_of_switch + 2].persons.forEach(person => map_uuid2person.set(person.uuid, person));

        // prepare template for shift-assignment-sequence
        // -1 means rest
        const shift_x: number[] = [];
        for (let ii = 0; ii <= 2; ii++) {
            let i = (rotate_opposite_direction ? 2 - ii : ii);
            // 6-day work in same shift
            for (let j = 0; j < 6; j++) {
                shift_x.push(i);
            }
            // 2-day rest
            shift_x.push(-1);
            shift_x.push(-1);
        }
        const indexes: number[] = [];
        if (rotate_opposite_direction) {
            let index0 = (3 * 8 - 2 - day_of_switch) + 7 + 3 * 8;
            let index1 = index0 - 6;
            let index2 = index1 - 6;
            let index3 = index2 - 6;
            indexes.push(index0 % shift_x.length);
            indexes.push(index1 % shift_x.length);
            indexes.push(index2 % shift_x.length);
            indexes.push(index3 % shift_x.length);
        } else {
            let index0 = (1 * 8 - 2 - day_of_switch) + 7 + 3 * 8;
            let index1 = index0 - 6;
            let index2 = index1 - 6;
            let index3 = index2 - 6;
            indexes.push(index0 % shift_x.length);
            indexes.push(index1 % shift_x.length);
            indexes.push(index2 % shift_x.length);
            indexes.push(index3 % shift_x.length);
        }
        const csh = currWeek.shifts;
        csh.forEach(shift => {
            // reset persons for each shift
            shift.persons = [];
        });
        // loop over all days of the week
        for (let day = 0; day < 7; day++) {
            // for each group
            for (let group = 0; group < indexes.length; group++) {
                // determine the shift of the day that this group should work in on current day
                const index = indexes[group] % shift_x.length;
                const shift = shift_x[index];
                if (shift >= 0) {
                    for (const person of groups[group]) {
                        const shift_obj = csh[3 * day + shift];
                        const person_obj = map_uuid2person.get(person);
                        if (person_obj !== undefined) {
                            shift_obj.persons.push(person_obj);
                        }
                    }
                }
                indexes[group] = (indexes[group] + 1) % shift_x.length;
            }
        }

    } else {
        // COPY_WEEKLY_ASSIGNMENTS_STRATEGY.simple_rotate
        // smart rotation based on enabled shifts

        // check monday to determine the rotation type
        const psh = prevWeek.shifts;
        const csh = currWeek.shifts;
        const day_count = Math.floor(csh.length / 3);
        const sh0 = psh[0].persons.length > 0;
        const sh1 = psh[1].persons.length > 0;
        const sh2 = psh[2].persons.length > 0;
        if (sh0 && sh1 && sh2) {
            // three shift day -> permute 3 workers (shift 3 -> shift 2, shift 2 -> shift 1, shift 1 -> shift 3)
            for (let i = 0; i < day_count; i++) {
                if (rotate_opposite_direction) {
                    csh[3 * i + 0].persons = psh[3 * i + 1].persons;
                    csh[3 * i + 1].persons = psh[3 * i + 2].persons;
                    csh[3 * i + 2].persons = psh[3 * i + 0].persons;
                } else {
                    csh[3 * i + 0].persons = psh[3 * i + 2].persons;
                    csh[3 * i + 1].persons = psh[3 * i + 0].persons;
                    csh[3 * i + 2].persons = psh[3 * i + 1].persons;
                }
                if (week_start_shift_offset != 0 && i === day_count - 1 && csh[3 * i + 2].enabled) {
                    if (rotate_opposite_direction) {
                        // last shift on Sunday should be the same as the first shift of the week
                        csh[3 * i + 2].persons = csh[0].persons;
                    } else {
                        // last shift on Sunday should be the same as the second shift of the week
                        csh[3 * i + 2].persons = csh[1].persons;
                    }
                }
            }
        } else if (sh0 && sh1 && !sh2) {
            // two shift day -> switch workers
            for (let i = 0; i < day_count; i++) {
                csh[3 * i + 0].persons = psh[3 * i + 1].persons;
                csh[3 * i + 1].persons = psh[3 * i + 0].persons;
                csh[3 * i + 2].persons = psh[3 * i + 2].persons;
            }
        } else if (sh0 && !sh1 && !sh2) {
            // one shift day -> copy
            for (let i = 0; i < csh.length; i++) {
                csh[i].persons = psh[i].persons;
            }
        } else {
            // some strange thing, just copy
            for (let i = 0; i < csh.length; i++) {
                csh[i].persons = psh[i].persons;
            }
        }
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////

export async function fullSimulation(): Promise<void> {
    /// current week
    const current_date = new Date();
    const shift_number = shiftNumber(current_date);
    const current_year = shift_number.year;
    const current_week = shift_number.week;
    const current_shift = shift_number.shift;
    const current_shift_date = toISODateString(current_date);

    try {
        // create new simulation
        await getBackend().reports.createNewReport({
            title: "Complete simulation",
            input: {
                version: 0,
                data: {
                    next_shift_time: {
                        shift_date: current_shift_date,
                        shift_number: current_shift,
                        week_number: current_week,
                        year: current_year
                    },
                    analysis_configuration: {
                        max_end_times_sample: 500,
                        quantiles: [0.1, 0.5, 0.9],
                        predict_shifts: 21 * 20
                    }
                }
            },
            tags: { simulation_type: "full" }
        });
    } catch (e) {
        console.log(e);
    }
}

export async function fullInsights(): Promise<void> {
    try {
        await getBackend().manufacturing.recalculateInsights({});
    } catch (e) {
        console.log(e);
    }
}

export async function calculateKpis(): Promise<void> {
    try {
        await getBackend().reports.calcKpis({});
    } catch (e) {
        console.log(e);
    }
}

export async function getAllLinesForLineGroup(linegroup_uuid: string): Promise<man_t.IGetLinesRes> {
    const res_lines = await getBackend().manufacturing.getLines({line_group_uuids: [linegroup_uuid]});
    return res_lines;
}

////////////////////////////////////////////////////////////////////////////////////////////////////

/** Utility method for waiting on job */
export async function waitForJob(
    backend: IBackend, token: string, autodelete: boolean = true, return_results: boolean = false
): Promise<any> {
    const CHECK_INTERVAL = 3 * TIME_RANGES.SECOND;
    const MAX_WAIT_TIME = 1000 * TIME_RANGES.MINUTE;
    return new Promise((resolve, reject) => {
        let counter = 0;
        const loopCall = async (): Promise<void> => {
            try {
                const res = await backend.common.getJobStatus({ id: token });
                if (!res) {
                    reject(new Error("Job with given uuid not found"));
                    return;
                }
                if (res.status === JOB_STATUS.errored) {
                    if (autodelete) {
                        await backend.common.deleteJob({ id: token });
                    }
                    reject(new Error("Job failed with error: " + res.status_msg));
                    return;
                }
                if (res.status === JOB_STATUS.ended) {
                    let final_result: any = undefined;
                    if (return_results) {
                        final_result = (await backend.common.getJobInfo({ id: token })).result;
                    }
                    if (autodelete) {
                        await backend.common.deleteJob({ id: token });
                    }
                    resolve(final_result);
                    return;
                }
                // check if we have been waiting for too long
                counter++;
                if (counter * CHECK_INTERVAL >= MAX_WAIT_TIME) {
                    reject(new Error("Job time-out: " + token));
                    return;
                }
                // make another loop
                setTimeout(loopCall, CHECK_INTERVAL);
            } catch (err) {
                reject(err);
            }
        };
        setTimeout(loopCall, CHECK_INTERVAL);
    });
}

