// @flow
import * as React from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";

import { getBackend } from "../lib/backend/Backend2";
import { translate } from "./IntlProviderWrapper";
import ErrorComponent from "./ErrorComponent";
import { LOGIN_PROVIDERS } from "./UserLogins";

import type { LoginProvider } from "./UserLogins";
import type { IUserLogin } from "../lib/backend/users.generated.types";

// $FlowFixMe
import "../styles/user_logins_input_modal.scss";

type UserLoginsInputModalProps = {
    show: boolean,
    provider: string | null,
    logins: IUserLogin[],
    onAccept: () => Promise<void>,
    onCancel: () => void,
    user_uuid: string
};

type UserLoginsInputModalState = {
    error: string,
    is_submitting: boolean,
    provider_key: string,
    selected_provider: string | null
};

class UserLoginsInputModal extends React.Component<UserLoginsInputModalProps, UserLoginsInputModalState> {
    constructor(props: UserLoginsInputModalProps) {
        super(props);
        const state: UserLoginsInputModalState = {
            error: "",
            is_submitting: false,
            provider_key: "",
            selected_provider: null
        };
        this.state = state;
    }

    componentDidUpdate(prevProps: UserLoginsInputModalProps) {
        if (this.props.show && !prevProps.show) {
            const { provider, logins } = this.props;
            if (provider !== null) {
                const login = logins.find(login => login.provider === provider);
                if (login !== undefined) {
                    this.setState({
                        provider_key: login.provider_key,
                        selected_provider: login.provider
                    });
                }
            }
        }
    }

    handleSubmit = async (event: SyntheticEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.setState({
            error: "",
            is_submitting: true
        });

        const { provider } = this.props;
        const { provider_key, selected_provider } = this.state;
        try {
            if (provider !== null) {
                await getBackend().users.updateUserLogin({
                    uuid: this.props.user_uuid,
                    provider,
                    provider_key
                });
            } else if (selected_provider !== null) {
                await getBackend().users.addUserLogin({
                    uuid: this.props.user_uuid,
                    provider: selected_provider,
                    provider_key
                });
            }

            this.props.onAccept();
            this.handleClose();
        } catch (error) {
            this.setState({
                error: error.message
            });
        }

        this.setState({ is_submitting: false });
    };

    getLoginProviders = (): LoginProvider[] => {
        const logins: Set<string> = new Set(this.props.logins.map(login => login.provider));
        return LOGIN_PROVIDERS.filter(provider => !logins.has(provider.id));
    };

    getSelectedLoginProvider = (): LoginProvider | null => {
        const login_provider = LOGIN_PROVIDERS.find(p => p.id === this.state.selected_provider);
        return login_provider !== undefined ? login_provider : null;
    };

    validate = () => {
        const { provider, logins } = this.props;
        const { provider_key, selected_provider } = this.state;
        if (provider !== null) {
            const login = logins.find(login => login.provider === provider);
            return provider_key !== "" && login !== undefined && login.provider_key !== provider_key;
        }

        return selected_provider !== null && provider_key !== "";
    };

    handleLoginProviderChange = (option: LoginProvider) => {
        this.setState({ selected_provider: option.id });
    };

    handleLoginKeyChange = (event: SyntheticEvent<HTMLInputElement>) => {
        this.setState({ provider_key: event.currentTarget.value });
    };

    handleClose = () => {
        this.setState({
            error: "",
            provider_key: "",
            selected_provider: null
        });
        this.props.onCancel();
    };

    render() {
        const is_add_mode = this.props.provider === null;
        const provider = this.getSelectedLoginProvider();
        return (
            <Modal className="user-logins-input-modal" show={this.props.show} animation={false}>
                <Modal.Header>
                    <Modal.Title>
                        {is_add_mode ? translate("common.add", "Add") : translate("common.edit", "Edit")}
                    </Modal.Title>
                    <button
                        type="button"
                        className="close"
                        title={translate("common.close", "Close")}
                        onClick={this.handleClose}
                    >
                        <span aria-hidden="true">×</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <form id="user-logins-input-modal-form" onSubmit={this.handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="user-logins-input-modal-provider">
                                {translate("common.provider", "Provider")}
                            </label>
                            {is_add_mode ? (
                                <Select
                                    inputId="user-logins-input-modal-provider"
                                    placeholder={`${translate("common.select", "Select")}...`}
                                    value={provider}
                                    options={this.getLoginProviders()}
                                    getOptionLabel={(provider: LoginProvider) => provider.title}
                                    getOptionValue={(provider: LoginProvider) => provider.id}
                                    onChange={this.handleLoginProviderChange}
                                    autoFocus
                                />
                            ) : (
                                <input
                                    className="form-control"
                                    id="user-logins-input-modal-provider"
                                    defaultValue={provider !== null ? provider.title : ""}
                                    type="text"
                                    disabled
                                />
                            )}
                        </div>
                        <div className="form-group">
                            <label htmlFor="user-logins-input-modal-key">{translate("common.key", "Key")}</label>
                            <input
                                className="form-control"
                                id="user-logins-input-modal-key"
                                type="text"
                                value={this.state.provider_key}
                                onChange={this.handleLoginKeyChange}
                                autoFocus={!is_add_mode}
                            />
                        </div>
                        {this.state.error && <ErrorComponent type="error" msg={this.state.error} />}
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    {this.state.is_submitting && <i className="fas fa-spinner fa-spin" aria-hidden="true" />}
                    <button
                        className="btn btn-primary"
                        type="submit"
                        form="user-logins-input-modal-form"
                        disabled={!this.validate() || this.state.is_submitting}
                    >
                        {is_add_mode ? translate("common.add", "Add") : translate("common.save", "Save")}
                    </button>
                    <button className="btn btn-outline-secondary" onClick={this.handleClose}>
                        {translate("common.cancel", "cancel")}
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default UserLoginsInputModal;
