// @flow

// React
import React, { Component } from "react";
import ChevronListItem from "../react/ChevronListItem";

// Models
import type { IBatchOperationGroup } from "../../lib/backend/batch_operations.generated.types";

type Props = {
    filter: string,
    selected: IBatchOperationGroup | null,
    batchop_groups: IBatchOperationGroup[],
    handleItemClick: any
}

/**
 * LineGroups list component.
 */
class BatchopGroupsList extends Component<Props> {
    /**
     * Handle `click` events for the line item.
     */
    handleItemClick(group: IBatchOperationGroup, i: number) {
        // Ignore clicks for currently selected line.
        if (group === this.props.selected) {
            return;
        }
        // Handle parent's click events.
        this.props.handleItemClick(group);
    }

    /**
     * Batchop groups list filter.
     *
     * @param {string} needle Search term.
     * @param {IBatchOperationGroup} group Line object to match search term against.
     *
     * @return {boolean} `true` if line matches given search term, `false` otherwise.
     */
    includes(needle: string, batchop_group: IBatchOperationGroup) {
        needle = needle.toLowerCase();
        if (needle.length === 0) return true;
        if (batchop_group.name.toLowerCase().indexOf(needle) > -1) return true;

        // search tag keys and values
        return false;
    }

    /**
     * Render component.
     */
    render() {
        const batchopGroupsList = this.props.batchop_groups.map((batchop_group, i) => {
            let is_active = this.props.selected === batchop_group;

            if (!this.includes(this.props.filter, batchop_group)) return null;

            return <ChevronListItem
                text={batchop_group.name}
                onClick={() => this.handleItemClick(batchop_group, i)}
                is_active={is_active}
            />
        })

        return (
            <ul className="nav nav-tabs">
                <div className="nav-tabs-parent" ref="nav">
                    {batchopGroupsList}
                </div>
            </ul>
        )
    }
}

export default BatchopGroupsList;
