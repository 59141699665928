// @flow

import * as React from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

import { getBackend as getBackend2 } from "../lib/backend/Backend2";
import * as t from "../lib/backend/datasources.generated.types";

import ErrorComponent from "./ErrorComponent";


type State = {
    // parameters for creating new datasource
    new_datasource_params: t.IInsertDatasourceReq,
    // created datasource data
    new_datasource?: t.IInsertDatasourceRes,
    // error messages
    message: string,
    // item, selected in the dropbox
    selected_item: any
};

type Props = {};

class DatasourceNew extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        const state: State = {
            new_datasource_params: {
                code: "",
                title: "",
                type: "timeseries"
            },
            message: "",
            selected_item: null
        };
        this.state = state;
    }

    // check all the parameters entered are valid
    validateShallow(): boolean {
        // check we have title;
        return (this.state.new_datasource_params.title.length > 0) &&
            (this.state.new_datasource_params.code.length > 0);
    };

    validateDeep(): boolean {
        if (this.state.new_datasource_params.title.length < 5) {
            this.setState({ message: "Datasource name must contain at least 5 characters" });
            return false;
        }
        if (this.state.new_datasource_params.code.length < 5) {
            this.setState({ message: "Datasource code must contain at least 5 characters" });
            return false;
        }
        if (!(/^[a-zA-Z0-9_]+$/.test(this.state.new_datasource_params.code))) {
            this.setState({ message: "Datasource code can only contain alphanumeric characters or '_'" });
            return false;
        }
        this.setState({ message: "" });
        return true;
    };

    handleInputChange = (event: Event) => {
        if (event.currentTarget instanceof HTMLInputElement) {
            // read new values
            const target = event.currentTarget;
            const value = target.value;
            const name = target.name;
            // update new datasource placeholder
            this.setState(prev_state => {
                prev_state.new_datasource_params[name] = value;
                return prev_state;
            });
        }
    };

    handleFormReset = () => {
        this.setState(prev_state => {
            prev_state.new_datasource_params.code = "";
            prev_state.new_datasource_params.title = "";
            prev_state.new_datasource_params.type = "timeseries";
            prev_state.new_datasource = undefined;
            return prev_state;
        });
    };

    handleSubmit = async (event: Event) => {
        try {
            if (this.validateDeep()) {
                const params = JSON.parse(JSON.stringify(this.state.new_datasource_params));
                params.type = this.state.selected_item.value;
                const res1 = await getBackend2().datasources.insertDatasource({
                    code: this.state.new_datasource_params.code,
                    title: this.state.new_datasource_params.title,
                    type: this.state.new_datasource_params.type
                })
                this.setState({ new_datasource: res1 });
            }
        } catch (error) {
            this.setState({ message: error });
        }
    };

    handleSelectChange(selectedValue: Object) {
        this.setState({ selected_item: selectedValue });
    }

    renderMessage() {

        return (<ErrorComponent msg={this.state.message} type="warning" />);
    };

    render() {

        if (!this.state.new_datasource) {
            // return form where we enter code and title
            let datasource_types = [
                { value: "timeseries", label: "Timeseries" },
                { value: "events", label: "Events" }
            ];
            return (
                <div key="ds_new">
                    <section key="1">
                        <img src="img/banner.png" alt="banner" className="img-fluid-header banner_img" />
                    </section>
                    <section id="profile_edit" className="new_data_source" key="2">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="white_box">
                                        <div className="tab-content">
                                            {this.state.message && this.renderMessage()}
                                            <h4>Add New Datasource</h4>
                                            <div className="form-group">
                                                <label>Title:</label>
                                                <input name="title" type="text" disabled="" className="form-control"
                                                    value={this.state.new_datasource_params.title}
                                                    onChange={this.handleInputChange} />
                                            </div>
                                            <div className="form-group">
                                                <label>Code:</label>
                                                <input name="code" type="text" disabled="" className="form-control"
                                                    value={this.state.new_datasource_params.code}
                                                    onChange={this.handleInputChange} />
                                            </div>

                                            <div className="form-group">
                                                <label>Type:</label>
                                                <Select value={this.state.selected_item} options={datasource_types}
                                                    onChange={(event) => { this.handleSelectChange(event) }} />
                                            </div>
                                            <button className="btn_save"
                                                onClick={this.handleSubmit}
                                                disabled={!this.validateShallow()}>Save</button>
                                        </div>
                                    </div>
                                    <div className="space30"></div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            );
        } else {
            const new_datasource_params = this.state.new_datasource_params;
            const new_datasource = this.state.new_datasource;
            // just display the datasource
            return (
                <div key="ds_created">
                    <section key="1">
                        <img src="img/banner.png" alt="banner" className="img-fluid banner_img" />
                    </section>
                    <section id="profile_edit" className="new_data_source" key="2">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="white_box">
                                        <div className="tab-content">
                                            <h4>Add New Datasource</h4>
                                            <div className="form-group">
                                                <label>Title:</label>
                                                <input name="title" type="text" disabled="" className="form-control"
                                                    value={new_datasource_params.title}
                                                    readOnly />
                                            </div>
                                            <div className="form-group">
                                                <label>Code:</label>
                                                <input name="code" type="text" disabled="" className="form-control"
                                                    value={new_datasource_params.code}
                                                    readOnly />
                                            </div>
                                            <div className="form-group">
                                                <label>Type:</label>
                                                <input name="code" type="text" disabled="" className="form-control"
                                                    value={new_datasource_params.type}
                                                    readOnly />
                                            </div>

                                            <ErrorComponent msg={"New datasource was successfully generated."} type="success" />
                                            <div className="form-group">
                                                <label>UUID:</label>
                                                <input name="title" type="text" disabled="" className="form-control"
                                                    value={new_datasource.uuid}
                                                    readOnly />
                                            </div>
                                            <br />
                                            <Link className="blue_link text-center" to={"/analysis_new/" + new_datasource.uuid}>
                                                Add analysis to this datasource&#187;
                                            </Link>
                                            <br />
                                            <Link className="blue_link text-center" to="/datasources">
                                                Back to datasources list &#187;
                                            </Link>
                                            <button className="btn_save btn_wide" onClick={this.handleFormReset}>
                                                Create another datasource
                                            </button>
                                        </div>
                                    </div>
                                    <div className="space30"></div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            );
        }
    }
};

export default DatasourceNew;
