// @flow

/************************************************************************************/
/* WARNING: This file is generated automatically. Don't edit it by hand             */
/************************************************************************************/

import * as api from "./restApi.js";
import * as t from "./apps.generated.types.js";

function enc(s: any): string { return encodeURIComponent("" + s); }

export interface IApps {
    /** regenerates API key (e.g. password) for given app */
    regenerateApiKey(req: t.IRegenerateApiKeyReq): Promise<t.IRegenerateApiKeyRes>;

    /** Activates given app */
    setActive(req: t.ISetActiveReq): Promise<t.ISetActiveRes>;

    /** Deactivates given app */
    setInactive(req: t.ISetInactiveReq): Promise<t.ISetInactiveRes>;

    /** Gets details for single app */
    getSingleApp(req: t.IGetSingleAppReq): Promise<t.IGetSingleAppRes>;

    /** Gets apps for user's org */
    searchApps(req: t.ISearchAppsReq): Promise<t.ISearchAppsRes>;

    /** Creates new app for user's org */
    createNewApp(req: t.ICreateNewAppReq): Promise<t.ICreateNewAppRes>;

}

export class Apps implements IApps {

    client: api.ApiClient;

    constructor(client: api.ApiClient) { this.client = client; }

    /** regenerates API key (e.g. password) for given app */
    async regenerateApiKey(req: t.IRegenerateApiKeyReq): Promise<t.IRegenerateApiKeyRes> {
        const reqx: any = req;
        const url = `/api/v1.0/apps/${enc(req.id)}/regenerate_api_key`;
        delete reqx.id;
        return (await this.client.post(url, reqx)).data;
    }

    /** Activates given app */
    async setActive(req: t.ISetActiveReq): Promise<t.ISetActiveRes> {
        const reqx: any = req;
        const url = `/api/v1.0/apps/${enc(req.id)}/activate`;
        delete reqx.id;
        return (await this.client.post(url, reqx)).data;
    }

    /** Deactivates given app */
    async setInactive(req: t.ISetInactiveReq): Promise<t.ISetInactiveRes> {
        const reqx: any = req;
        const url = `/api/v1.0/apps/${enc(req.id)}/deactivate`;
        delete reqx.id;
        return (await this.client.post(url, reqx)).data;
    }

    /** Gets details for single app */
    async getSingleApp(req: t.IGetSingleAppReq): Promise<t.IGetSingleAppRes> {
        const reqx: any = req;
        const url = `/api/v1.0/apps/${enc(req.id)}`;
        delete reqx.id;
        return (await this.client.get(url, reqx)).data;
    }

    /** Gets apps for user's org */
    async searchApps(req: t.ISearchAppsReq): Promise<t.ISearchAppsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/apps`;
        return (await this.client.get(url, reqx)).data;
    }

    /** Creates new app for user's org */
    async createNewApp(req: t.ICreateNewAppReq): Promise<t.ICreateNewAppRes> {
        const reqx: any = req;
        const url = `/api/v1.0/apps`;
        return (await this.client.post(url, reqx)).data;
    }

}
