// @flow
import {
    SET_SELECTED_STOCK_REQUIREMENT,
    SET_RESCHEDULE_LINE_UUID,
    SET_RESCHEDULE_ORDER_UUID
} from "../common";

import type { SetSelectedStockRequirement } from "../common";
import type { PayloadAction } from "../../../../redux/types";
import { deepClone } from "../../../../../lib/Util";

export const SET_STOCK_REQ_FILTERS_MATERIALS: "set_sr_filters_materials" = "set_sr_filters_materials";
export const RESET_FILTERS: "reset_filters" = "reset_filters";
export const HAS_INSIGHTS_FILTER: "has_insights_filter" = "has_insights_filter";

export type SetStockReqFilterMaterials = PayloadAction<typeof SET_STOCK_REQ_FILTERS_MATERIALS, string[]>;
export type ResetFiltersAction = PayloadAction<typeof RESET_FILTERS, typeof undefined>;
export type SetHasInsightsFilter = PayloadAction<typeof HAS_INSIGHTS_FILTER, boolean>;

export type StockRequirementsFiltersState = {
    stock_requirements_materials: string[],
    selected_stock_requirement: string | null,
    has_insights_filter: boolean
}

export const stock_requirements_initial_state: StockRequirementsFiltersState = {
    stock_requirements_materials: [],
    selected_stock_requirement: null,
    has_insights_filter: false
};

export type StockRequirementsFiltersActions = (
    SetStockReqFilterMaterials | ResetFiltersAction | SetSelectedStockRequirement
);

const STOCK_REQUIREMENT_UNSELECT_ACTIONS = [
    SET_RESCHEDULE_ORDER_UUID,
    SET_RESCHEDULE_LINE_UUID
];

export const stockRequirementsFiltersReducer = (
    state: StockRequirementsFiltersState,
    action: StockRequirementsFiltersActions
): StockRequirementsFiltersState => {
    if (!state) {
        state = deepClone(stock_requirements_initial_state);
    }
    let new_state = state;

    if (action.type === SET_STOCK_REQ_FILTERS_MATERIALS) {
        new_state = {
            ...new_state,
            stock_requirements_materials: action.data
        }
    } else if (action.type === RESET_FILTERS) {
        new_state = deepClone(stock_requirements_initial_state);
    } else if (action.type === SET_SELECTED_STOCK_REQUIREMENT) {
        new_state = {
            ...new_state,
            selected_stock_requirement: action.data
        };
    } else if (action.type === HAS_INSIGHTS_FILTER) {
        new_state = {
            ...new_state,
            has_insights_filter: action.data
        }
    } else if (STOCK_REQUIREMENT_UNSELECT_ACTIONS.includes(action.type)) {
        new_state = {
            ...new_state,
            selected_stock_requirement: null
        }
    }

    return new_state;
}

