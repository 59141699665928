// @flow

//import axios from "axios";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Provider } from 'react-redux';

import { initAnonymization } from "./lib/Anonymization";
import { initTracking } from "./lib/Tracking";
import { initAuth } from "./lib/Auth";

import "./index.css";
import URLHandler from "./components/URLHandler";
import { IntlProviderWrapper } from "./components/IntlProviderWrapper";
import registerServiceWorker from "./registerServiceWorker";
import TooltipProvider from "./components/TooltipProvider";

// initialize backend
import { combineReducers, createStore } from 'redux';
import { setBackend } from "./lib/Backend";
// eslint-disable-next-line
import { RestBackend } from "./lib/Backend.rest";
import { initErrorCollector } from "./lib/ErrorCollector";
import { clearFeatureMatrix, initBusinessLogic } from "./lib/BusinessLogic";
import { ensureDeviceUUID, getBrowser } from "./lib/Util";
import { subscribe, MSG_TYPES } from "./lib/PubSub";
import { reducers } from "./components/redux/reducers";
import { initPerformanceLogging } from "./lib/PerformanceLogging";

export const store = createStore(combineReducers(reducers));

const REACT_VERSION = React.version;
const BROWSER = getBrowser();


async function main() {
    // initialization of main components
    ensureDeviceUUID();
    clearFeatureMatrix();
    const backend = new RestBackend();
    setBackend(backend);
    // setBackend(new MockBackend());
    initTracking(backend.getAxiosInstance());
    await initErrorCollector();
    await initAuth();
    await initAnonymization(backend.getAxiosInstance());
    await initBusinessLogic();
    await initPerformanceLogging();

    subscribe(MSG_TYPES.redirect_to_login, async () => {
        if (window.location.pathname !== "/login") {
            window.location = "/login";
        }
    });


    // initialize document
    let root = document.getElementById('root');
    console.log("React Version: " + REACT_VERSION);
    console.log("Browser: " + BROWSER);

    if (root) {
        if (BROWSER !== "msie") {
            ReactDOM.render(
                (
                    <IntlProviderWrapper>
                        <Provider store={store}>
                            <TooltipProvider>
                                <Router>
                                    <Route path='/' component={URLHandler} />
                                </Router>
                            </TooltipProvider>
                        </Provider>
                    </IntlProviderWrapper>
                ),
                root
            );
        } else {
            ReactDOM.render(
                (
                    <IntlProviderWrapper>
                        <div className="center">
                            <h3>
                                <FormattedMessage id="index.no_msie" defaultMessage="Internet Explorer is not supported. Please use Chrome." />
                            </h3>
                        </div>
                    </IntlProviderWrapper>
                ),
                root
            );
        }
    }
    registerServiceWorker();
}

main()
    .catch(err => console.log);
