// @flow

/************************************************************************************/
/* WARNING: This file is generated automatically. Don't edit it by hand             */
/************************************************************************************/

/** Tags that are assignable to single organization */
export const ORG_TAGS = {
    /** Are logins via PIN allowed */
    allow_pin_logins: "allow_pin_logins",
    /** Allowed IP ranges in CIDR syntax, separated by ',' - e.g. '1.1.1.0/16,2.2.2.12/32' */
    allowed_ip_ranges: "allowed_ip_ranges",
    /** Allowed IP ranges for PIN logins in CIDR syntax, separated by ',' - e.g. '1.1.1.0/16,2.2.2.12/32' */
    allowed_ip_ranges_for_pin: "allowed_ip_ranges_for_pin",
    /** If true 2FA is required for all users of the organisation */
    require_twofa: "require_twofa"
};

/** Tags that are assignable to single organization */
export const ORG_TAGS_ACCESS = {
    /** Are logins via PIN allowed */
    allow_pin_logins: (tags: any): boolean => (defVal(tags[ORG_TAGS.allow_pin_logins], "false")) == "true",
    /** Allowed IP ranges in CIDR syntax, separated by ',' - e.g. '1.1.1.0/16,2.2.2.12/32' */
    allowed_ip_ranges: (tags: any): string => defVal(tags[ORG_TAGS.allowed_ip_ranges], ""),
    /** Allowed IP ranges for PIN logins in CIDR syntax, separated by ',' - e.g. '1.1.1.0/16,2.2.2.12/32' */
    allowed_ip_ranges_for_pin: (tags: any): string => defVal(tags[ORG_TAGS.allowed_ip_ranges_for_pin], ""),
    /** If true 2FA is required for all users of the organisation */
    require_twofa: (tags: any): boolean => (defVal(tags[ORG_TAGS.require_twofa], "false")) == "true"
};

/** Tags that are assignable to single user */
export const USER_TAGS = {
    /** Flag if this user is kiosk user */
    kiosk: "kiosk",
    /** Name of the page that this kiosk user is redirected to by default */
    kiosk_screen: "kiosk_screen",
    /** Flag if 2fa is enabled for this user */
    twofa: "twofa",
    /** 2FA secret */
    twofaSecret: "twofaSecret"
};

/** Tags that are assignable to single user */
export const USER_TAGS_ACCESS = {
    /** Flag if this user is kiosk user */
    kiosk: (tags: any): boolean => (defVal(tags[USER_TAGS.kiosk], "false")) == "true",
    /** Name of the page that this kiosk user is redirected to by default */
    kiosk_screen: (tags: any): string => defVal(tags[USER_TAGS.kiosk_screen], ""),
    /** Flag if 2fa is enabled for this user */
    twofa: (tags: any): boolean => (defVal(tags[USER_TAGS.twofa], "false")) == "true",
    /** 2FA secret */
    twofaSecret: (tags: any): string => defVal(tags[USER_TAGS.twofaSecret], "")
};

/** Tags that are assignable to single app */
export const APP_TAGS = {
    /** Flag if this app is used for external integration */
    is_external: "is_external"
};

/** Tags that are assignable to single app */
export const APP_TAGS_ACCESS = {
    /** Flag if this app is used for external integration */
    is_external: (tags: any): boolean => (defVal(tags[APP_TAGS.is_external], "false")) == "true"
};

/** Tags that are assignable to single dashboard */
export const DASHBOARD_TAGS = {
    /** Code of the dashboard */
    code: "code",
    /** Subtype of the dashboard */
    subtype: "subtype",
    /** Type of the dashboard */
    type: "type"
};

/** Tags that are assignable to single dashboard */
export const DASHBOARD_TAGS_ACCESS = {
    /** Code of the dashboard */
    code: (tags: any): string => defVal(tags[DASHBOARD_TAGS.code], ""),
    /** Subtype of the dashboard */
    subtype: (tags: any): string => defVal(tags[DASHBOARD_TAGS.subtype], ""),
    /** Type of the dashboard */
    type: (tags: any): string => defVal(tags[DASHBOARD_TAGS.type], "")
};

function defVal(a: string, b: string): string { return (a == undefined ? b : a); }
