// @flow
import React from "react";
import * as BusinessLogic from "../../lib/BusinessLogic";
import { getBackend as getBackend2 } from "../../lib/backend/Backend2";
import { MD04Modal } from "./PlanningTable2/lineOrderList/MaterialMD04";

import type { MaterialMD04Data } from "./PlanningTable2/lineOrderList/MaterialMD04";

type Props = {
    parent_order_uuid: string,
    plant_uuid: string,
    show_modal: boolean,
    material_external_id: string | null,
    material_title: string | null,
    onCloseModal: () => void
};
type State = {
    materials: MaterialMD04Data[],
    material_external_id: string | null,
    material_title: string | null,
    plant_external_id: string | null,
    show_modal: boolean
};

export default class ParallelMD04 extends React.Component<Props, State> {
    state = {
        plant_external_id: null,
        material_external_id: null,
        material_title: null,
        materials: [],
        show_modal: false
    }

    componentDidUpdate(prev_props: Props) {
        const modal_opened = prev_props.show_modal !== this.props.show_modal && this.props.show_modal === true;
        if (modal_opened) {
            this.load(this.props.show_modal);
        }

        if (prev_props.show_modal === true && this.props.show_modal === false) {
            this.setState({ show_modal: false })
        }
    }

    async load(show_modal: boolean) {
        const order_uuid = this.props.parent_order_uuid;
        const plants = BusinessLogic.getPlantsForUser();

        if (plants && order_uuid) {
            const plant = plants.find(p => p.uuid === this.props.plant_uuid);
            if (!plant) return;

            const res = await getBackend2().manufacturing.getOrdersSimple({
                parent_order_uuid: this.props.parent_order_uuid
            });

            let material_external_id = this.props.material_external_id;
            let material_title = this.props.material_title;

            let materials = [];
            if (res.operations.length > 0) {
                materials = res.operations.map(o => ({
                    material_external_id: o.material_external_id,
                    material_title: o.material_title
                }));
                if (materials.length > 0) {
                    material_external_id = materials[0].material_external_id;
                    material_title = materials[0].material_title;
                }
            }

            this.setState({
                plant_external_id: plant.external_id,
                material_external_id,
                material_title,
                materials,
                show_modal
            });
        }
    }

    onSelectedMaterialChange = (selected_material_external_id: string) => {
        const material = this.state.materials.find(
            m => m.material_external_id === selected_material_external_id
        );
        if (material) {
            this.setState({
                material_external_id: material.material_external_id,
                material_title: material.material_title
            });
        }
    }

    render() {
        const material_external_id = this.state.material_external_id || this.props.material_external_id;
        const plant_external_id = this.state.plant_external_id;
        const material_title = this.state.material_title || this.props.material_title;

        return <React.Fragment>
            {material_external_id && plant_external_id && material_title && <MD04Modal
                onCloseModal={this.props.onCloseModal}
                show_modal={this.state.show_modal || false}
                material_external_id={material_external_id}
                plant_external_id={plant_external_id}
                material_title={material_title}
                materials={this.state.materials}
                onSelectedMaterialChange={this.onSelectedMaterialChange}
            />}
        </React.Fragment>
    }
}