// @flow
import {
    RESET_GANTT,
    SET_LOCK_FILTER,
    SET_RESCHEDULE_LINE_UUID,
    SET_RESCHEDULE_ORDER_BUNDLE,
    SET_RESCHEDULE_ORDER_UUID
} from "./common";

import type { PayloadAction } from "../../../redux/types";
import type { IPlanLine, IPlanExplanation, IPlanWarning } from "../../../../lib/backend/manufacturing2.generated.types";
import type { OrderHighlights } from "./linesOrders";
import type { ResetGanttAction, SetLockFilter, SetRescheduleLineAction, SetRescheduleOrderAction } from "./common";
import type { LeftMenuViewsType } from "../LeftMenu/common";
import type { SetShowValidLinesState } from "./common";
import { deepClone } from "../../../../lib/Util";

export const LEFT_MENU_VIEW_STOCK_REQUIREMENTS: "stock_requirements" = "stock_requirements";
export const SET_SHOW_VALID_LINES: "set_show_valid_lines" = "set_show_valid_lines";
export const RESET_VALID_LINES: "reset_valid_lines" = "reset_valid_lines";
export const SET_RECOMMEND_PLAN_RUNNING: "recommend_plan_running" = "recommend_plan_running";
export const SET_RECOMMEND_PLAN_LINE_PLANS: "line_plans" = "line_plans";
export const SET_RECOMMEND_PLAN_MODAL: "recomend_plan_modal" = "recomend_plan_modal";
export const SET_REPORT_TYPE_TITLE_ACTION_NAME: "report_version_type" = "report_version_type";
export const HIGHLIGHT_SHIFT_LINE_ACTION_NAME: "highlight_shift_line" = "highlight_shift_line";
export const SHOW_UNSCHEDULED_ORDERS: "show_unscheduled_orders" = "show_unscheduled_orders";
export const SET_ADVANCED_ORDER_MENU: "advanced_order_menu" = "advanced_order_menu";
export const RESET_ADVANCED_ORDER_MENU: "reset_advanced_order_menu" = "reset_advanced_order_menu";
export const SET_ERROR: "error" = "error";
export const RESET_CURSOR: "reset_cursor" = "reset_cursor";
export const SET_SHOW_EXTRA_LINES: "set_show_extra_lines" = "set_show_extra_lines";
export const SET_HIGHLIGHTED_MOVE_ORDER: "set_highlighted_move_order" = "set_highlighted_move_order";
export const SET_IS_CTRL_DOWN: "set_ctrl_down" = "set_ctrl_down";
export const SET_RECOMMEND_EXPLANATIONS: "set_recommend_explanations" = "set_recommend_explanations";
export const RESET_DRAGGED_ORDER: "reset_dragged_order" = "reset_dragged_order";
export const SET_LEFT_MENU_SELECTED_VIEW: "left_menu_selected_view" = "left_menu_selected_view";
export const SET_SHOW_STOCK_REQUIREMENTS: "set_show_stock_requirements" = "set_show_stock_requirements";
export const RESET_FILTERS: "reset_filters" = "reset_filters";
export const SET_RECOMMEND_WARNINGS: "set_recommend_warnings" = "set_recommend_warnings";
export const SET_HIGHLIGHTED_ORDERS: "set_highlighted_order" = "set_highlighted_order";
export const SHOW_PARENT_CHUNK_RELATIONSHIP: "show_parent_chunk_relationship" = "show_parent_chunk_relationship";
export const SHOW_EDIT_COMMENT_MODAL: "show_edit_comment_modal" = "show_edit_comment_modal";
export const SET_LAST_CLICKED_ORDER_UUID: "set_last_clicked_order_uuid" = "set_last_clicked_order_uuid";


export type SetReportTypeTitleActionData = {
    report_version_type: "initial" | "version" | "current",
    report_version_title: string
}

export type RecommendPlanState = {
    show_recommend_param_dialog: boolean,
    recommend_running: boolean,
    line_plans: IPlanLine[]
}

export type HighlightShiftLineActionData = {
    order_uuid: string | null,
    highlighted_shift_number: number | null,
    highlighted_line_index: number | null,
    highlighted_date_index: number | null,
    highlighted_date_ts: number | null,
    is_unscheduled?: boolean
}

export type AdvancedOrderMenuState = {
    order_uuid: string | null,
    click_target: Element | typeof undefined | null,
    event: MouseEvent | null
}

const advanced_order_menu_initial_state = {
    order_uuid: null,
    click_target: null,
    event: null
}

export type HighlightedOrders = {
    highlighted_orders_map: Map<string, OrderHighlights> | null
}

export type ResetFiltersAction = PayloadAction<typeof RESET_FILTERS, typeof undefined>;
export type SetShowValidLinesAction = PayloadAction<typeof SET_SHOW_VALID_LINES, SetShowValidLinesState>;
export type ResetValidLinesAction = PayloadAction<typeof RESET_VALID_LINES, typeof undefined>;
export type SetRecommendPlanRunningAction = PayloadAction<typeof SET_RECOMMEND_PLAN_RUNNING, boolean>;
export type SetRecommendPlanLinePlans = PayloadAction<typeof SET_RECOMMEND_PLAN_LINE_PLANS, RecommendPlanState>;
export type SetRecommendPlanModalAction = PayloadAction<typeof SET_RECOMMEND_PLAN_MODAL, boolean>;
export type SetReportTypeTitleAction = PayloadAction<typeof SET_REPORT_TYPE_TITLE_ACTION_NAME, SetReportTypeTitleActionData>;
export type HighlightShiftLineAction = PayloadAction<typeof HIGHLIGHT_SHIFT_LINE_ACTION_NAME, HighlightShiftLineActionData>;
export type ShowUnscheduledOrdersAction = PayloadAction<typeof SHOW_UNSCHEDULED_ORDERS, boolean>;
export type SetErrorAction = PayloadAction<typeof SET_ERROR, string | null>;
export type SetAdvancedOrderMenuAction = PayloadAction<typeof SET_ADVANCED_ORDER_MENU, AdvancedOrderMenuState>;
export type ResetAdvancedOrderMenu = PayloadAction<typeof RESET_ADVANCED_ORDER_MENU, typeof undefined>;
export type CursorState = HighlightShiftLineActionData;
export type ResetCursorAction = PayloadAction<typeof RESET_CURSOR, typeof undefined>;
export type SetShowExtraLines = PayloadAction<typeof SET_SHOW_EXTRA_LINES, boolean>;
export type SetHighlightedOrders = PayloadAction<typeof SET_HIGHLIGHTED_ORDERS, HighlightedOrders>;
export type SetIsCtrlDown = PayloadAction<typeof SET_IS_CTRL_DOWN, boolean>;
export type SetRecommendExplanationsAction = PayloadAction<typeof SET_RECOMMEND_EXPLANATIONS, IPlanExplanation[]>;
export type SetLeftMenuSelectedView = PayloadAction<typeof SET_LEFT_MENU_SELECTED_VIEW, LeftMenuViewsType>;
export type SetShowStockRequirements = PayloadAction<typeof SET_SHOW_STOCK_REQUIREMENTS, boolean>;
export type ResetDraggedOrder = PayloadAction<typeof RESET_DRAGGED_ORDER, typeof undefined>;
export type SetRecommendWarningsAction = PayloadAction<typeof SET_RECOMMEND_WARNINGS, IPlanWarning[]>;
export type SetHighlightedMoveOrder = PayloadAction<typeof SET_HIGHLIGHTED_MOVE_ORDER, string | null>;
export type ShowParentChunkRelationship = PayloadAction<typeof SHOW_PARENT_CHUNK_RELATIONSHIP, string[] | null>;
export type ShowEditCommentModal = PayloadAction<typeof SHOW_EDIT_COMMENT_MODAL, string | null>;
export type SetLastClickedOrderUuid = PayloadAction<typeof SET_LAST_CLICKED_ORDER_UUID, string | null>;

export type PlanningTableState = {
    show_valid_lines: SetShowValidLinesState,
    recommend_plan: RecommendPlanState,
    recommend_explanations: IPlanExplanation[],
    recommend_warnings: IPlanWarning[],
    report_version_type: "initial" | "current" | "version",
    report_version_title: string | null,
    cursor: CursorState | null,
    max_line_label_length: number,
    show_unscheduled_orders: boolean,
    error: string | null,
    advanced_order_menu: AdvancedOrderMenuState,
    show_extra_lines: boolean,
    highlighted_orders: HighlightedOrders,
    is_ctrl_down: boolean,
    refresh_at: number | null,
    selected_left_menu_view: LeftMenuViewsType | null,
    show_stock_requirements: boolean,
    highlighted_move_order_uuid: string | null,
    show_parent_chunk_relationship_uuids: string[] | null,
    show_edit_comment_modal_order_uuid: string | null,
    last_clicked_order_uuid: string | null
};

const recommend_plan_initial_state: RecommendPlanState = {
    show_recommend_param_dialog: false,
    recommend_running: false,
    line_plans: []
}

const show_valid_lines_initial_state: SetShowValidLinesState = {
    valid_lines: null,
    valid_lines_map: null,
    valid_orders_map: null
}

const highlighted_orders_initial_state: HighlightedOrders = {
    highlighted_orders_map: null
}

export const planning_table_initial_state: PlanningTableState = {
    should_scroll: false,
    show_valid_lines: show_valid_lines_initial_state,
    recommend_plan: recommend_plan_initial_state,
    recommend_explanations: [],
    recommend_warnings: [],
    report_version_type: "initial",
    report_version_title: null,
    cursor: null,
    show_unscheduled_orders: true,
    max_line_label_length: 0,
    error: null,
    advanced_order_menu: { ...advanced_order_menu_initial_state },
    show_extra_lines: true,
    highlighted_orders: highlighted_orders_initial_state,
    is_ctrl_down: false,
    advanced_menu_target: null,
    refresh_at: null,
    selected_left_menu_view: null,
    show_stock_requirements: false,
    highlighted_move_order_uuid: null,
    show_parent_chunk_relationship_uuids: null,
    show_edit_comment_modal_order_uuid: null,
    last_clicked_order_uuid: null
};

export type PlanningTableActionsTypes = (
    SetShowValidLinesAction | SetRecommendPlanRunningAction |
    SetRecommendPlanLinePlans | SetReportTypeTitleAction | SetRecommendPlanModalAction |
    HighlightShiftLineAction | ShowUnscheduledOrdersAction | ResetValidLinesAction | SetErrorAction |
    SetAdvancedOrderMenuAction | ResetAdvancedOrderMenu | ResetCursorAction | ResetGanttAction |
    SetShowExtraLines | SetHighlightedOrders | SetIsCtrlDown | SetRecommendExplanationsAction |
    SetShowStockRequirements | ResetFiltersAction | SetLeftMenuSelectedView | ResetDraggedOrder |
    SetRecommendWarningsAction | SetHighlightedMoveOrder | ShowParentChunkRelationship | ShowEditCommentModal |
    SetLastClickedOrderUuid | SetLockFilter | SetRescheduleLineAction | SetRescheduleOrderAction
);

const LS_SHOW_STOCK_REQUIREMENTS = "planning_table_show_stock_requirements";

const getInitialState = (): PlanningTableState => {
    const initial_state = deepClone(planning_table_initial_state);
    initial_state.show_stock_requirements = localStorage.getItem(LS_SHOW_STOCK_REQUIREMENTS) === "true";
    return initial_state;
}

export const planningTableReducer = (
    state: PlanningTableState,
    action: PlanningTableActionsTypes
): PlanningTableState => {
    if (!state) {
        state = getInitialState();
    }
    let new_state = state;

    if (action.type === RESET_GANTT) {
        return getInitialState();
    } else if (action.type === SET_SHOW_VALID_LINES) {
        new_state.show_valid_lines = action.data;
        new_state = { ...new_state };
    } else if (action.type === RESET_VALID_LINES) {
        new_state.show_valid_lines = show_valid_lines_initial_state
        new_state = {...new_state};
    } else if (action.type === SET_RECOMMEND_PLAN_RUNNING) {
        const recommend_plan_state = new_state.recommend_plan;
        recommend_plan_state.recommend_running = action.data;
        new_state.recommend_plan = { ...recommend_plan_state };
        new_state = { ...new_state };
    } else if (action.type === SET_RECOMMEND_PLAN_LINE_PLANS) {
        const recommend_plan_state = new_state.recommend_plan;
        recommend_plan_state.line_plans = action.data.line_plans;
        recommend_plan_state.show_recommend_param_dialog = action.data.show_recommend_param_dialog;
        recommend_plan_state.recommend_running = action.data.recommend_running;
        new_state.recommend_plan = {...recommend_plan_state};
        new_state = {...new_state};
    } else if (action.type === SET_RECOMMEND_PLAN_MODAL) {
        const recommend_plan_state = new_state.recommend_plan;
        recommend_plan_state.show_recommend_param_dialog = action.data;
        new_state.recommend_plan = { ...recommend_plan_state };
        new_state = { ...new_state };
    } else if (action.type === SET_REPORT_TYPE_TITLE_ACTION_NAME) {
        new_state.report_version_type = action.data.report_version_type;
        new_state.report_version_title = action.data.report_version_title;
        new_state = { ...new_state };
    } else if (action.type === HIGHLIGHT_SHIFT_LINE_ACTION_NAME) {
        let cursor = new_state.cursor;
        cursor = !new_state.cursor ? action.data : { ...cursor, ...action.data };
        new_state = {...new_state, cursor};
    } else if (action.type === SHOW_UNSCHEDULED_ORDERS) {
        new_state.show_unscheduled_orders = action.data;
        return { ...new_state };
    } else if (action.type === SET_ERROR) {
        new_state.error = action.data;
        return { ...new_state };
    } else if (action.type === SET_ADVANCED_ORDER_MENU) {
        new_state.advanced_order_menu = action.data;
        return { ...new_state };
    } else if (action.type === RESET_CURSOR) {
        new_state.cursor = null;
        return { ...new_state };
    } else if (action.type === SET_SHOW_EXTRA_LINES) {
        new_state.show_extra_lines = action.data;
        return { ...new_state };
    } else if (action.type === SET_HIGHLIGHTED_ORDERS) {
        new_state.highlighted_orders = action.data;
        return { ...new_state };
    } else if (action.type === SET_IS_CTRL_DOWN) {
        new_state.is_ctrl_down = action.data;
        return { ...new_state };
    } else if (action.type === RESET_ADVANCED_ORDER_MENU) {
        new_state.advanced_order_menu = {...advanced_order_menu_initial_state};
        return { ...new_state };
    } else if (action.type === SET_RECOMMEND_EXPLANATIONS) {
        new_state.recommend_explanations = action.data;
        return { ...new_state }
    } else if (action.type === RESET_DRAGGED_ORDER) {
        new_state.cursor = null;
        return { ...new_state };
    } else if (action.type === SET_LEFT_MENU_SELECTED_VIEW) {
        new_state.selected_left_menu_view = action.data;
        return { ...new_state };
    } else if (action.type === SET_SHOW_STOCK_REQUIREMENTS) {
        localStorage.setItem(LS_SHOW_STOCK_REQUIREMENTS, action.data.toString());
        new_state.show_stock_requirements = action.data;
        if (new_state.show_stock_requirements) {
            new_state.selected_left_menu_view = LEFT_MENU_VIEW_STOCK_REQUIREMENTS;
        }
        return {...new_state};
    } else if (action.type === RESET_FILTERS) {
        new_state.highlighted_orders.highlighted_orders_map = null;
        new_state.last_clicked_order_uuid = null;
        new_state.highlighted_move_order_uuid = null;
        return {...new_state};
    } else if (action.type === SET_RECOMMEND_WARNINGS) {
        new_state.recommend_warnings = action.data;
    } else if (action.type === SET_HIGHLIGHTED_MOVE_ORDER) {
        new_state.highlighted_move_order_uuid = action.data;
        return { ...new_state };
    } else if (action.type === SHOW_PARENT_CHUNK_RELATIONSHIP) {
        new_state.show_parent_chunk_relationship_uuids = action.data;
        return { ...new_state };
    } else if (action.type === SHOW_EDIT_COMMENT_MODAL) {
        new_state.show_edit_comment_modal_order_uuid = action.data;
        return { ...new_state };
    } else if (action.type === SET_LAST_CLICKED_ORDER_UUID) {
        new_state.last_clicked_order_uuid = action.data;
        return { ...new_state };
    } else if (action.type === SET_LOCK_FILTER) {
        if (!action.data) {
            // TODO
            new_state.highlighted_orders.highlighted_orders_map = null;
            new_state.last_clicked_order_uuid = null;
        }
        return { ...new_state };
    } else if (action.type === SET_RESCHEDULE_LINE_UUID ) {
        new_state.highlighted_move_order_uuid = null;
        return { ...new_state }
    } else if (action.type === SET_RESCHEDULE_ORDER_UUID) {
        new_state.highlighted_move_order_uuid = null;
        new_state.last_clicked_order_uuid = action.data;
        return { ...new_state }
    } else if (action.type === SET_RESCHEDULE_ORDER_BUNDLE) {
        new_state.last_clicked_order_uuid = action.data.order_uuid;
        if (action.data.show_valid_lines != null) {
            new_state.show_valid_lines = action.data.show_valid_lines;
        }
        new_state.selected_left_menu_view = "orders";
        new_state.highlighted_orders = action.data.highlighted_orders;
        new_state = { ...new_state };
        return new_state;
    }

    return new_state;
}

