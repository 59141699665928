// @flow

// main imports
import React from "react";
import ReactRouterPropTypes from "react-router-prop-types";

// backend
import * as Auth from "../../lib/Auth";
import * as BusinessLogic from "../../lib/BusinessLogic";

// import subcomponents
import KanbanList from "./KanbanList";
import ErrorComponent from "../ErrorComponent";

type Props = {
    history: ReactRouterPropTypes.history
}

function KanbanStandalone(props: Props) {
    // get line_group uuid, since this is for kiosk use we asume only one linegroup is selected
    const user = Auth.getLoggedinUser();
    const line_group_uuid = BusinessLogic.getLineGroupsForUser();
    if (line_group_uuid.length === 0) {
        return <ErrorComponent
            msg={`Line-group not selected for Kisok user ${user.username}`}
            type="error" />
    } else if (line_group_uuid.length > 1) {
        return <ErrorComponent
            msg={`Only one line-group can be selected for Kisok user ${user.username}`}
            type="error" />
    }
    return <div className="space15">
        <KanbanList
            history={props.history}
            line_group_uuid={line_group_uuid[0].uuid} />;
    </div>;
}

export default KanbanStandalone;
