// @flow

import React from 'react';
import { subscribe, MSG_TYPES } from "../lib/PubSub";
import { FormattedMessage } from "react-intl";
import Popup from "reactjs-popup";

type Props = {};
type State = {
    open: boolean
};

export default class ModalLoader extends React.Component<Props, State> {

    constructor(props: Props, state: State) {
        super(props)
        this.state = {
            open: false
        }
    }
    
    componentDidMount() {
        subscribe(MSG_TYPES.modal_loader, async (state) => {
            this.setState({
                ...this.state,
                ...state
            });
        });
    }

    closeModal = () => {
        this.setState({open: false})
    }

    render() {
        let customStyle = {
            position: "relative",
            background: "rgb(0, 0, 0, 0.4)",
            width: "30%",
            margin: "auto",
            padding: "20px",
            borderWidth: "1px",
            borderColor: "#009fe3",
            borderStyle: "solid"
        }

        return (
            <div>
                <Popup contentStyle={customStyle} open={this.state.open} closeOnDocumentClick={false} onClose={this.closeModal}>
                    <div>
                        <div style={{ height: "120px" }}>
                            <div className="loader"><FormattedMessage id="common.loading" defaultMessage="Loading" />...</div>
                        </div>
                        <h5 align="center" style={{ color: "white" }}><FormattedMessage id="common.in_progress" defaultMessage="In progress" />...</h5>
                    </div>
                </Popup>
            </div>
        )
    }
}
