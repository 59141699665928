// @flow
import React from "react";

import { translate } from "../IntlProviderWrapper";
import { publish, MSG_TYPES } from "../../lib/PubSub";

type Props = {
}

export type TemplateValues = {
    show_filters: boolean,
    show_closed: boolean,
    show_finished: boolean,
    show_unfinished: boolean,
    show_normal: boolean,
    show_subcontracted: boolean,
    show_nonwork: boolean,
    show_only_new: boolean,
    show_only_skip_sim: boolean,
    show_only_changed: boolean
}

export type Template = {
    code: string,
    title: string,
    values: TemplateValues
}

export function getTemplates(): Template[] {
    return [
        {
            code: "open-normal",
            title: translate("OrderShifts.view.open-normal"),
            values: {
                show_filters: false,
                show_closed: false,
                show_finished: true,
                show_unfinished: true,
                show_normal: true,
                show_subcontracted: false,
                show_nonwork: false,
                show_only_new: false,
                show_only_skip_sim: false,
                show_only_changed: false
            }
        },
        {
            code: "only-new",
            title: translate("OrderShifts.view.only-new"),
            values: {
                show_filters: false,
                show_closed: false,
                show_finished: true,
                show_unfinished: true,
                show_normal: true,
                show_subcontracted: false,
                show_nonwork: false,
                show_only_new: true,
                show_only_skip_sim: false,
                show_only_changed: false
            }
        },
        {
            code: "only-changed",
            title: translate("OrderShifts.view.only-changed"),
            values: {
                show_filters: false,
                show_closed: false,
                show_finished: true,
                show_unfinished: true,
                show_normal: true,
                show_subcontracted: false,
                show_nonwork: false,
                show_only_new: false,
                show_only_skip_sim: false,
                show_only_changed: true
            }
        },
        {
            code: "only-skip-sim",
            title: translate("OrderShifts.view.only-skip-sim"),
            values: {
                show_filters: false,
                show_closed: false,
                show_finished: true,
                show_unfinished: true,
                show_normal: true,
                show_subcontracted: false,
                show_nonwork: false,
                show_only_new: false,
                show_only_skip_sim: true,
                show_only_changed: false
            }
        },
        {
            code: "subcontr",
            title: translate("OrderShifts.view.subcontr"),
            values: {
                show_filters: false,
                show_closed: false,
                show_finished: true,
                show_unfinished: true,
                show_normal: false,
                show_subcontracted: true,
                show_nonwork: false,
                show_only_new: false,
                show_only_skip_sim: false,
                show_only_changed: false
            }
        },
        {
            code: "manual",
            title: translate("OrderShifts.view.manual"),
            values: {
                show_filters: true,
                show_closed: false,
                show_finished: true,
                show_unfinished: true,
                show_normal: true,
                show_subcontracted: false,
                show_nonwork: false,
                show_only_new: false,
                show_only_skip_sim: false,
                show_only_changed: false
            }
        }
    ];
}

type State = {
    templates: Template[],
    selected_template: Template | null
};

class OrdersShiftsViewTemplates extends React.PureComponent<Props, State> {

    state: State = {
        selected_template: null,
        templates: getTemplates()
    };

    async componentDidMount() {
        this.setState(
            { selected_template: this.state.templates[0] },
            () => {
                publish(
                    MSG_TYPES.change_orders_shifts_view_template,
                    this.state.templates[0].values
                );
            });
    }

    handleTemplateChange = (event: Event) => {
        if (event.currentTarget instanceof HTMLInputElement || event.currentTarget instanceof HTMLSelectElement) {
            const selected_template_code = event.currentTarget.value;
            const hits = this.state.templates.filter(x => x.code === selected_template_code);
            if (hits.length > 0) {
                const selected_template = hits[0];
                const values = selected_template.values;
                this.setState({ selected_template });
                publish(
                    MSG_TYPES.change_orders_shifts_view_template,
                    values
                );
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <select name="selected_view" className="form-control select_top_control"
                    value={this.state.selected_template ? this.state.selected_template.code : this.state.templates[0].code}
                    onChange={this.handleTemplateChange}>
                    {this.state.templates.map(t =>
                        <option key={t.code + "_filter_template"} value={t.code}>{t.title}</option>
                    )}
                </select>
            </React.Fragment>
        )
    }
}

export default OrdersShiftsViewTemplates;
