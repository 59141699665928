// @flow
import type { ITags } from "../../lib/Models";
import { niceNumber, fromShiftTag } from "../../lib/Util";
import type { IOrderModelBase } from "../../lib/backend/manufacturing2.generated.types";

// data types
export type BaseOrder = {
    uuid: $ElementType<IOrderModelBase, "uuid">,
    order_external_id: $ElementType<IOrderModelBase, "external_id">,
    material_external_id: $ElementType<IOrderModelBase, "material_external_id">,
    quantity_total: $ElementType<IOrderModelBase, "quantity_total">,
    skip_sim: $ElementType<IOrderModelBase, "skip_sim">,
    earliest_end: $ElementType<IOrderModelBase, "earliest_end">,
    earliest_start: $ElementType<IOrderModelBase, "earliest_start">,
    time_start: ?number,
    time_end: ?number,
    tags: ITags,
    status: string
}

export type Order = BaseOrder & {
    expanded: boolean,
    changed: boolean,
    chunk_orders: BaseOrder[],
    is_nonwork: boolean,
    is_subcontracted: boolean,
    original_quantity: number,
    quantity_manual: number,
    quantity_stock: number,
    quantity_safety_stock: number,
    is_late: boolean,
    is_open: boolean,
    is_new: boolean,
    has_quantity_total_changed: boolean,
    has_order_changed: boolean,
    nice_original_quantity: string,
    nice_quantity_manual: string,
    nice_quantity_stock: string,
    nice_quantity_safety_stock: string,
    nice_quantity_total: string
}

export type State = {
    orders: Order[]
}

const initialState: State = {
    orders: []
}

type TAG_COLUMNS = "remark" | "original_quantity" | "start_with_shift" | "priority" | "stock" | "security_stock" | "manual_quantity";

const TAGS_COLUMNS: Array<TAG_COLUMNS> = ["remark", "original_quantity", "start_with_shift", "priority", "stock", "security_stock", "manual_quantity"];

// action names
export const SET_EXPANDED_ACTION_NAME: "set_expanded" = "set_expanded";
type SET_EXPANDED_ACTION_NAME_TYPE = typeof SET_EXPANDED_ACTION_NAME;
export const UPDATE_QUANTITY_TOTAL_ACTION_NAME: "update_quantity_total" = "update_quantity_total";
type UPDATE_QUANTITY_TOTAL_TYPE = typeof UPDATE_QUANTITY_TOTAL_ACTION_NAME;
export const UPDATE_REMARK_ACTION_NAME: "update_remark" = "update_remark";
type UPDATE_REMARK_TYPE = typeof UPDATE_REMARK_ACTION_NAME;
export const UPDATE_START_WITH_SHIFT_ACTION_NAME: "update_start_with_shift" = "update_start_with_shift";
type UPDATE_START_WITH_SHIFT_TYPE = typeof UPDATE_START_WITH_SHIFT_ACTION_NAME;
export const UPDATE_EARLIEST_END_ACTION_NAME: "update_earliest_end" = "update_earliest_end";
type UPDATE_EARLIEST_END_TYPE = typeof UPDATE_EARLIEST_END_ACTION_NAME;
export const UPDATE_ORDERS_ACTION_NAME: "update_orders" = "update_orders";
type UPDATE_ORDERS_TYPE = typeof UPDATE_ORDERS_ACTION_NAME;
export const UPDATE_SKIP_SIM_ACTION_NAME: "update_skip_sim" = "update_skip_sim";
type UPDATE_SKIP_SIM_TYPE = typeof UPDATE_SKIP_SIM_ACTION_NAME;
export const UPDATE_PRIORITY_ACTION_NAME: "update_priority" = "update_priority";
type UPDATE_PRIORITY_TYPE = typeof UPDATE_PRIORITY_ACTION_NAME;

// actions
type UUID = { order_uuid: string };

type SetExpandedActionData = {
    order_uuid: string;
    expanded: boolean
}
export type SetExpandedAction = {
    type: SET_EXPANDED_ACTION_NAME_TYPE,
    data: SetExpandedActionData
}

type UpdateQuantityTotalActionData = UUID & {
    value: number
}
export type UpdateQuantityTotalAction = {
    type: UPDATE_QUANTITY_TOTAL_TYPE,
    data: UpdateQuantityTotalActionData
}

type UpdateRemarkActionData = UUID & {
    value: string
}
export type UpdateRemarkAction = {
    type: UPDATE_REMARK_TYPE,
    data: UpdateRemarkActionData
}

type UpdateStartWithShiftActionData = UUID & {
    value: string | null
}
export type UpdateStartWithShiftAction = {
    type: UPDATE_START_WITH_SHIFT_TYPE,
    data: UpdateStartWithShiftActionData
}

type UpdateEarliestEndActionData = UUID & {
    value: number
}
export type UpdateEarliestEndAction = {
    type: UPDATE_EARLIEST_END_TYPE,
    data: UpdateEarliestEndActionData
}

type UpdateOrdersActionData = {
    orders: Order[]
}
export type UpdateOrdersAction = {
    type: UPDATE_ORDERS_TYPE,
    data: UpdateOrdersActionData
}
type UpdateSkipSimActionData = UUID & {
    value: boolean
}
export type UpdateSkipSimAction = {
    type: UPDATE_SKIP_SIM_TYPE,
    data: UpdateSkipSimActionData
}

type UpdatePriorityActionData = UUID & {
    value: string
}
export type UpdatePriorityAction = {
    type: UPDATE_PRIORITY_TYPE,
    data: UpdatePriorityActionData
}

type OrdersAction = (
    UpdateOrdersAction | UpdateQuantityTotalAction | UpdateEarliestEndAction |
    UpdateStartWithShiftAction | UpdateRemarkAction | UpdateSkipSimAction | UpdatePriorityAction |
    SetExpandedAction
);

export const selectors = {
    getOrder: (orders: Order[], order_uuid: string): ?Order => {
        return orders.find(o => o.uuid === order_uuid);
    },
    setValueByColumnId: (order: Order, column_id: string, cell_value: any): Order => {
        const is_tag = TAGS_COLUMNS.includes(column_id);
        if (is_tag) {
            order.tags[column_id] = cell_value;
        } else {
            order[column_id] = cell_value;
        }
        return order;
    },
    hasKey: (order: Order, column_id: string): boolean => {
        if (!order) {
            return false;
        }
        const is_tag = TAGS_COLUMNS.includes(column_id);
        if (is_tag && column_id in order.tags) {
            return true;
        }
        return column_id in order;
    }
}

export const ordersReducer = (state: Order[] = initialState.orders, action: OrdersAction): Order[] => {
    let new_state = state;

    if (action.type === UPDATE_ORDERS_ACTION_NAME) {
        new_state = action.data.orders;
    } else if (action.type === SET_EXPANDED_ACTION_NAME) {
        const { order_uuid, expanded } = action.data;
        const order = state.find(o => o.uuid === order_uuid);
        if (order !== undefined) {
            order.expanded = expanded;
        }
        new_state = [...new_state];
    } else if (action.type === UPDATE_QUANTITY_TOTAL_ACTION_NAME) {
        const { order_uuid, value } = action.data;
        const order = state.find(o => o.uuid === order_uuid);
        if (order !== undefined) {
            order.quantity_manual = value;
            order.tags.manual_quantity = "" + value;
            order.nice_quantity_manual = niceNumber(value, 0);
            order.changed = true;
        }
        new_state = [...new_state];
    } else if (action.type === UPDATE_REMARK_ACTION_NAME) {
        const { order_uuid, value } = action.data;
        const order = state.find(o => o.uuid === order_uuid);
        if (order !== undefined) {
            order.tags.remark = value;
            order.changed = true;
        }
        new_state = [...new_state];
    } else if (action.type === UPDATE_EARLIEST_END_ACTION_NAME) {
        const { order_uuid, value } = action.data;
        const order = state.find(o => o.uuid === order_uuid);
        if (order !== undefined) {
            if (order.tags.original_ts === undefined) {
                const earliest_end: string = order.earliest_end.toString();
                order.tags.original_ts = earliest_end;
            }
            order.earliest_end = value;
            order.changed = true;
        }
        new_state = [...new_state];
    } else if (action.type === UPDATE_START_WITH_SHIFT_ACTION_NAME) {
        const { order_uuid, value } = action.data;
        const order = state.find(o => o.uuid === order_uuid);
        if (order !== undefined) {
            if (value !== null) {
                order.tags.start_with_shift = value;
                order.earliest_start = fromShiftTag(value).getTime();
            } else {
                delete order.tags.start_with_shift;
                order.earliest_start = 0;
            }
            order.changed = true;
        }
        new_state = [...new_state];
    } else if (action.type === UPDATE_SKIP_SIM_ACTION_NAME) {
        const { order_uuid, value } = action.data;
        const order = state.find(o => o.uuid === order_uuid);
        if (order !== undefined) {
            order.skip_sim = value;
            order.changed = true;
        }
        new_state = [...new_state];
    } else if (action.type === UPDATE_PRIORITY_ACTION_NAME) {
        const { order_uuid, value } = action.data;
        const order = state.find(o => o.uuid === order_uuid);
        if (order !== undefined) {
            order.tags.priority = value;
            order.changed = true;
        }
        new_state = [...new_state];
    }

    return new_state;
}
