// @flow
import type { PayloadAction } from "../types";


export const SET_PERSISTENT_FILTERS_ACTION: "set_persistent_filters" = "set_persistent_filters";

export type PersistentFiltersState = {
    plant_uuid: string,
    line_group_uuid: string
}

export type CompressedPersistentFiltersState = {
    pf_pu: $PropertyType<PersistentFiltersState, "plant_uuid">,
    pf_lgu: $PropertyType<PersistentFiltersState, "line_group_uuid">
}

export const persistent_filters_initial_state: PersistentFiltersState = {
    plant_uuid: "",
    line_group_uuid: ""
}

export const PersistentFiltersCompressed = {
    plant_uuid: "pf_pu",
    line_group_uuid: "pf_lgu"
}

export type SetPersistentFiltersAction = PayloadAction<typeof SET_PERSISTENT_FILTERS_ACTION, PersistentFiltersState>;

export const compressPersistentFiltersParams = (data: PersistentFiltersState): CompressedPersistentFiltersState => {
    return {
        pf_pu: data.plant_uuid,
        pf_lgu: data.line_group_uuid
    }
}

export const diffPersistentFiltersAndURLParams = (selected_persistent_filters: PersistentFiltersState, hash: string): ?PersistentFiltersState => {
    if (!hash) { hash = window.location.hash; }
    const url_params = new URLSearchParams(hash.split("#")[1]);

    const new_filters: $Shape<PersistentFiltersState> = {};

    let has_differences = false;
    const pf_lgu = url_params.get(PersistentFiltersCompressed.line_group_uuid);
    if (selected_persistent_filters.line_group_uuid !== pf_lgu) {
        has_differences = true;
        new_filters.line_group_uuid = pf_lgu;
        localStorage.setItem("last-linegroup", pf_lgu);
    } else if (pf_lgu) {
        new_filters.line_group_uuid = pf_lgu;
    }

    const pf_pu = url_params.get(PersistentFiltersCompressed.plant_uuid);
    if (selected_persistent_filters.plant_uuid !== pf_pu) {
        has_differences = true;
        new_filters.plant_uuid = pf_pu;
        localStorage.setItem("last-plant", pf_pu);
    } else if (pf_pu) {
        new_filters.plant_uuid = pf_pu;
    }

    if (has_differences) {
        return new_filters;
    }
}


export const persistentFiltersReducer = (state: PersistentFiltersState, action: SetPersistentFiltersAction) => {
    if (!state) state = persistent_filters_initial_state;

    if (action.type === SET_PERSISTENT_FILTERS_ACTION) {
        state = action.data;
    }

    return state;
}
