// @flow
import { translate } from "../IntlProviderWrapper";
import { createHtmlAndDownload } from "../../lib/Util";

export function workerAssignmentsExport(html_string: string): void {
    const html = `
        <html>
            <head>
                <title>${translate("common.worker_assignments", "Worker Assignments")}</title>
                <meta charSet="utf-8" />
                <style>
                    body {
                        font-family: "Arial", "Helvetica", "sans-serif";
                    }

                    body, table, p, div {
                        font-size: 8px;
                    }

                    table {
                        table-layout: auto !important;
                        border-collapse: collapse;
                        border: 1px solid black;
                        width: 100%;
                        page-break-inside: avoid;
                    }

                    th, td {
                        border: 1px solid lightgray;
                        padding: 5px;
                        text-align: center;
                        min-width: fit-content !important;
                    }

                    .dayTitle {
                        background-color: rgb(115, 145, 171);
                    }

                    .dayTitle td {
                        color: white;
                    }

                    .tr-realization-flag-1-yellow { background-color: rgb(239,241,131);}
                    .materials-shift-sums { background-color: rgb(214,250,218); }
                    .breakdown-border { border-right: 1px solid rgb(230,230,230); }
                    .breakdown-border-thick { border-right: 2px solid rgb(230,230,230);}
                    .breakdown-weekend { background-color: rgb(250,219,182); }
                    .breakdown-total { background-color: rgb(241,243,246); }
                    .microplan-warning-events-image { position: absolute; height: 35px;}

                    .worker-assignments-table .worker-assignments-shift-indicator {
                        display: inline-block;
                        vertical-align: top;
                        margin-right: 20px;
                    }
                    .worker-assignments-table td {
                        text-align: left!important;
                    }
                    .worker-assignments-table .worker-assignments-materials {
                        display: inline-block;
                        text-align: left!important;
                    }
                    .worker-assignments-table .worker-assignments-cell {
                        vertical-align: top;
                        text-align: left!important;
                    }
                    .text-left {
                        text-align: left!important;
                    }

                    body { -webkit-print-color-adjust: exact; }

                    table tbody tr td:after {
                        content : "" ;
                        display : block ;
                    }

                    td[name=weekly_realisation], td[name=weekly_planned], tr-sum:last {
                        border-bottom: 1px black rgb(230,230,230) !important;
                    }

                    .worker-assignments-table-shift_1,
                    .worker-assignments-table-shift_2,
                    .worker-assignments-table-shift_3 {
                        border-radius: 5px;
                        padding: 3px;
                        width: 20px;
                        text-align: center;
                        display: inline;
                        align-self: center;
                    }
                    
                    .worker-assignments-table-shift_1 {
                        background-color: #7accff;
                    }
                    .worker-assignments-table-shift_2 {
                        background-color: #0C66D1;
                    }
                    .worker-assignments-table-shift_3 {
                        background-color: #616E7C;
                    }
                    
                    .worker-assignments-table-shift_1 span,
                    .worker-assignments-table-shift_2 span,
                    .worker-assignments-table-shift_3 span {
                        color: white;
                        font-weight: bold;
                    }
                    
                </style>
            </head>
            <body onload="window.print()">
                ${html_string}
            </body>
        </html>
    `;

    createHtmlAndDownload(html, "worker_assignments.html");
}
