// @flow
import React from "react";

type Props = {
    is_closed: boolean,
    is_error: boolean,
    status: string,
    color?: string,
    bgcolor?: string,
};
type State = {};

export default class TicketStatus extends React.Component<Props, State> {
    render() {
        const status = this.props.status;
        const color = this.props.color || "white";
        const bgcolor = this.props.bgcolor || "green";
        return <React.Fragment>
            <div className="ticket-status d-inline-block">
                <div className={this.props.status === "" ? "circle" : "title"} style={{ backgroundColor: bgcolor, color }}>
                    <span>{status}</span>
                </div>
            </div>
            {this.props.is_closed && <i className="fas fa-check-circle ticketing-fa-icon ticketing-fa-icon-success vertical-align-middle"></i>}
            {this.props.is_error && <i className="fas fa-exclamation-circle ticketing-fa-icon ticketing-fa-icon-error vertical-align-middle"></i>}
        </React.Fragment>;
    }
}
