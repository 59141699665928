// @flow

// main imports
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import Select from "react-select";

// models
import type { ILineData, ILineGroupData } from "../../lib/backend/manufacturing2.generated.types";
import { translate } from "../IntlProviderWrapper";

// backend

// import subcomponents

// defining types
type SelectedObj = {
    label: string,
    value: string
}

type Props = {
    show: boolean,
    line_group: ILineGroupData,
    lines: ILineData[],
    selected_plant: SelectedObj,
    plants: SelectedObj[],
    handleModalPlantChange: any,
    handleModalSaveClick: any,
    handleModalCloseClick: any
};

type State = {
    line_uuids: string[],
    line_group_uuid: string
};

/**
 * Displaying production lines list.
 */
class LineGroupsModal extends Component<Props, State> {

    constructor(props: Props, state: State) {
        super(props, state);
        this.state = {
            line_uuids: [],
            line_group_uuid: ""
        }
    }

    /**
     * Updates state according to the new props.
     *
     * @param {Props} props Next props.
     * @param {State} state Previous state.
     */
    static getDerivedStateFromProps(props: Props, state: State) {
        if (props.line_group.uuid !== state.line_group_uuid) {
            return {
                line_uuids: JSON.parse(JSON.stringify(props.line_group.line_uuids)),
                line_group_uuid: props.line_group.uuid
            };
        }
        return null;
    }


    /**
     * Check if line_grops lines selection changed for any process of the selected line group.
     *
     * @return {boolean} `true` if selection changed, `false` otherwise.
     */
    selectionChanged(): boolean {
        // compare this.state.line_uuids and this.props.line_group.line_uuids
        const c_orig = JSON.stringify(this.props.line_group.line_uuids.sort());
        const c_new = JSON.stringify(this.state.line_uuids.sort());
        return c_orig !== c_new;
    }

    isInLineGroup(c: string): boolean {
        // are all lines in the plant included in line group?
        if (c === "all") {
            const l = this.props.lines.filter(x => this.state.line_uuids.includes(x.uuid));
            if (l.length === this.props.lines.length - 1) return true;
            return false;
        }
        return this.state.line_uuids.includes(c);
    }

    handleLineClick(value: string): void {
        let line_uuids = this.state.line_uuids;

        if (value === "all") {
            if (this.isInLineGroup(value)) {
                // remove all the records
                this.props.lines.forEach((line, i) => {
                    if (line.uuid !== "all") {
                        line_uuids = line_uuids.filter(x => x !== line.uuid)
                    }
                });
            } else {
                // add all the records
                this.props.lines.forEach((line, i) => {
                    if (line.uuid !== "all") {
                        if (!line_uuids.includes(line.uuid)) {
                            line_uuids.push(line.uuid);
                        }
                    }
                });
            }
        } else {
            // remove or add line_uuid
            if (line_uuids.includes(value)) {
                line_uuids = line_uuids.filter(x => x !== value);
            } else {
                line_uuids.push(value);
            }
        }

        this.setState({ line_uuids })
    }

    render() {
        const {
            plants,
            lines,
            selected_plant,
            handleModalPlantChange,
            handleModalSaveClick,
            handleModalCloseClick
        } = this.props;

        const buttonDisabled = !this.selectionChanged();

        return (
            <Modal
                show={this.props.show}
                dialogClassName="modal-dialog-scrollable"
                onHide={handleModalCloseClick}
            >
                <Modal.Header>
                    <Modal.Title>
                    <FormattedMessage id="Manufacturing.LineGroups.edit_lines" defaultMessage="Edit lines in line-group" />
                    </Modal.Title>
                    <button type="button" className="close" onClick={handleModalCloseClick}>
                        <span aria-hidden="true">×</span>
                        <span className="sr-only"><FormattedMessage id="common.close" defaultMessage="Close" /></span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    {/* plants */}
                    <Select
                        classNamePrefix="react-select"
                        options={plants}
                        value={selected_plant}
                        onChange={(event) => { handleModalPlantChange(event) }}
                        placeholder="Select line"
                        menuPortalTarget={document.body}
                        menuPosition="fixed"
                        styles={{
                            menuPortal: base => ({
                                ...base,
                                zIndex: 9999
                            })
                        }}
                    />
                    {/* buttons */}
                    {selected_plant && lines.length > 1 &&
                        <ul className="list-group list-group-flush list-group-edit-competences">
                            {lines.map((p, i) => {
                                const isSelected = this.isInLineGroup(p.uuid);
                                const btnText = isSelected ?
                                    translate("common.remove", "Remove") :
                                    translate("common.add", "Add");
                                return (<li className="list-group-item d-flex align-items-center flex-wrap" key={"includeLine-" + i}>
                                    <span>{p.title}</span>
                                    <span className="badge-list ml-auto">
                                        <button className={"btn badge badge-competence" + (isSelected ? ` badge-competence-1` : "")}
                                            onClick={() => { this.handleLineClick(p.uuid) }}>{btnText}
                                        </button>
                                    </span>
                                </li>);
                            })}
                        </ul>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary"
                        onClick={() => { handleModalSaveClick(this.state.line_uuids) }}
                        disabled={buttonDisabled}>
                        <FormattedMessage id="common.save" defaultMessage="Save" />
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default LineGroupsModal;
