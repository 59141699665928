// @flow
import * as React from "react";

import { classNames } from "../lib/Util";

// $FlowFixMe
import "../styles/tree_view.scss";

export type TreeRow = {
    [key: string]: any
};

export type TreeColumn<T: TreeRow> = {
    id: $Keys<T>,
    Header: React.Node,
    Cell?: (value: $Values<T>) => React.Node
};

export type TreeNode<T: TreeRow> = {
    row: TreeRow,
    children: TreeNode<T>[]
};

type Props<T: TreeRow> = {
    class_name?: string,
    nodes: TreeNode<T>[],
    columns: TreeColumn<T>[]
};

const getClassName = (value: string): string => {
    return value.replace(/_/, "-");
};

class TreeView<T: TreeRow> extends React.Component<Props<T>> {
    renderNodes<T: TreeRow>(nodes: TreeNode<T>[], columns: TreeColumn<T>[], depth: number) {
        return (
            <ul className="tree-view-nodes" data-depth={depth}>
                {nodes.map((node, i) => (
                    <li key={i}>
                        <dl>
                            {columns.map((column, j) => {
                                const class_name = getClassName(column.id);
                                const value =
                                    column.Cell !== undefined ? column.Cell(node.row[column.id]) : node.row[column.id];
                                return j === 0 ? (
                                    <dt key={j} className={`column-${class_name}`}>
                                        {value}
                                    </dt>
                                ) : (
                                    <dd key={j} className={`column-${class_name}`}>
                                        {value}
                                    </dd>
                                );
                            })}
                        </dl>
                        {this.renderNodes<T>(node.children, columns, depth + 1)}
                    </li>
                ))}
            </ul>
        );
    }

    render() {
        const { class_name, nodes, columns } = this.props;
        return (
            <div className={classNames("tree-view", class_name || "")}>
                <div className="tree-view-content">
                    {(columns.length > 1 || (columns[0] !== undefined && columns[0].Header)) && (
                        <ul className="tree-view-header">
                            {columns.map((column, i) => (
                                <li key={i} className={`column-${getClassName(column.id)}`}>
                                    {column.Header}
                                </li>
                            ))}
                        </ul>
                    )}
                    {this.renderNodes<T>(nodes, columns, 0)}
                </div>
            </div>
        );
    }
}

export default TreeView;
