// @flow

// main imports
import React, { Component } from "react";

// libraries
import * as mathjs from "mathjs";

// backend
import { getBackend } from "../../lib/backend/Backend2";
import * as BusinessLogic from "../../lib/BusinessLogic";
import * as Util from "../../lib/Util";
import * as t from "../../lib/Models";
import * as man_t from "../../lib/backend/manufacturing2.generated.types";
import * as report_t from "../../lib/backend/reports.generated.types";
import ErrorComponent from "../ErrorComponent";
import Loader from "../Loader"
import { FormattedMessage } from "react-intl";

type EvalUnit = {
    plant: man_t.IPlantData,
    linegroup: man_t.ILineGroupData
}

type EvalResult = {
    unit_idx: number;
    shift_number: t.ShiftNumberData;
    material_eval: report_t.IEvalReportMaterial;
}

const OFFSET = 24; // how many hours before shift we check predictions
const SHIFTS = 21; // how many shifts do we check for evaluation

// defining types
type Props = { }

type State = {
    units: EvalUnit[],
    result: EvalResult[],
    message: string
}

class Evaluation extends Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            units: [],
            result: [],
            message: ""
        };
    }

    async componentDidMount() {
        // get list of all evaluation units - (plant, linegroup) pairs
        const units: EvalUnit[] = [];
        const plants = BusinessLogic.getPlantsForUser();
        if (plants.length === 0) { this.setState({ message: "No plants!" }); return; }
        for (const plant of plants) {
            const linegroups = BusinessLogic.getLineGroupsForUserPlant(plant.uuid);
            for (const linegroup of linegroups) {
                units.push({ plant, linegroup });
            }
        }
        if (units.length === 0) { this.setState({ message: "No line-groups!" }); return; }

        // figure out last report time
        const latest = await getBackend().reports.searchReports({ num_reports: 1 });
        if (latest.reports.length === 0) { this.setState({ message: `No reports!` }); return; }
        // we start with shift before shift of the latest report, for which
        // already have both predictions and realization in the database
        let shift_date = new Date(latest.reports[0].created_at - 8 * Util.TIME_RANGES.HOUR);
        // placeholder for storing results of individual shifts
        const result: EvalResult[] = [ ];
        // go back in time and get evaluation for each shift
        for (let shift = 0; shift < SHIFTS; shift++) {
            this.setState({ message: `${shift + 1} / ${SHIFTS}`});
            // get shift from current timestamp
            const shift_number = Util.shiftNumber(shift_date);
            // move date to shift start
            shift_date = Util.fromShiftTag(shift_number.shift_tag);
            // get nearest report uuid
            const date = new Date(shift_date.getTime() - OFFSET * Util.TIME_RANGES.HOUR).toISOString();
            const { reports } = await getBackend().reports.searchReports({ date });
            if (reports.length === 0) { this.setState({ message: `No reports before ${date}!` }); return; }
            // get evaluation for the shift for each eval unit
            for (let unit_idx = 0; unit_idx < units.length; unit_idx++) {
                const unit = units[unit_idx];
                const { material_eval } = await getBackend().reports.evalReports({
                    plant_uuid: unit.plant.uuid,
                    linegroup_uuid: unit.linegroup.uuid,
                    report_uuid: reports[0].uuid,
                    year: shift_number.year,
                    week: shift_number.week,
                    shift_number: shift_number.shift
                });
                // remeber evaluation
                result.push({
                    unit_idx,
                    shift_number,
                    material_eval
                });
                // move back in time
                shift_date = new Date(shift_date.getTime() - 8 * Util.TIME_RANGES.HOUR);
                // remember
                this.setState({
                    units,
                    result
                });
            }
        }
        this.setState({ message: "" });
    }

    renderMaterialEvalRowTotal(unit_idx: number, unit: EvalUnit, shifts: EvalResult[]) {
        if (shifts.length === 0) { return null; }
        // ingore nonzero realizations
        const material_accuracy = [];
        for (const shift of shifts) {
            for (const x of shift.material_eval.prediction) {
                if (x.real > 0) { material_accuracy.push(100 * x.pred / x.real); }
            }
        }
        // compute average pretty string, if no date use dash
        let accuracy_str = "-"
        if (material_accuracy.length > 0) {
            const quantiles = mathjs.quantileSeq(material_accuracy, [0.2, 0.5, 0.8]);
            accuracy_str = `${Util.niceNumber(quantiles[1], 0)} ± ${Util.niceNumber((quantiles[2] - quantiles[1]) / 2, 0)}`;
        }
        // report
        return (
            <tr key={unit_idx}>
                <td>{unit.plant.title}</td>
                <td>{unit.linegroup.title}</td>
                <td className="strong">{accuracy_str}</td>
            </tr>
        );
    }

    renderMaterialEval() {
        return (
            <table className="table">
                <thead>
                    <tr>
                        <th><FormattedMessage id="common.plant" defaultMessage="Plant" /></th>
                        <th><FormattedMessage id="common.linegroup" defaultMessage="Line-group" /></th>
                        <th><FormattedMessage id="common.accuracy" defaultMessage="Accuracy" /></th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.units.map((unit, unit_idx) => {
                        const unit_shifts = this.state.result.filter(r => (r.unit_idx === unit_idx));
                        return this.renderMaterialEvalRowTotal(unit_idx, unit, unit_shifts);
                    })}
                </tbody>
            </table>
        );
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="white_box space10i">
                            <ErrorComponent msg={this.state.message} type="info" />

                            <h3><FormattedMessage id="Header.menu.manufacturing_eval" defaultMessage="Evaluation" /></h3>

                            { this.state.result.length === 0 && this.state.message === "" && <Loader />}
                            { this.state.result.length > 0 && this.renderMaterialEval()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Evaluation;
