// @flow
import { RESET_GANTT } from "./common";

import type { PayloadAction } from "../../../redux/types";
import type { ReportParameters, ResetGanttAction } from "./common";
import { deepClone } from "../../../../lib/Util";

export const UPDATE_PARALLEL_REPORT_ACTION_NAME: "update_parallel_report" = "update_parallel_report";
export const PARALLEL_REPORT_LOADING_ACTION_NAME: "parallel_loading" = "parallel_loading";
export const UPDATE_PARALLEL_REPORT_PARAMETERS: "update_parallel_report_parameters" = "update_parallel_report_parameters";
export const ADD_REPORT_TO_DELETE: "add_report_to_delete" = "add_report_to_delete";
export const REMOVE_REPORT_TO_DELETE: "remove_report_to_delete" = "remove_report_to_delete";
export const UNSET_REPORT_TO_DELETE: "unset_reports_to_delete" = "unset_reports_to_delete";
export const SAVE_TO_SAP_PARALLEL_REPORT_LOADING: "save_to_sap_parallel_report_loading" = "save_to_sap_parallel_report_loading";


// action types
export type UpdateParallelReportAction = PayloadAction<typeof UPDATE_PARALLEL_REPORT_ACTION_NAME, string>;
export type ParallelReportLoading = PayloadAction<typeof PARALLEL_REPORT_LOADING_ACTION_NAME, boolean>;
export type UpdateParallelReportParameters = PayloadAction<typeof UPDATE_PARALLEL_REPORT_PARAMETERS, ReportParameters>;
export type AddReportToDelete = PayloadAction<typeof ADD_REPORT_TO_DELETE, string>;
export type RemoveReportToDelete = PayloadAction<typeof REMOVE_REPORT_TO_DELETE, string>;
export type UnsetReportToDelete = PayloadAction<typeof UNSET_REPORT_TO_DELETE, typeof undefined>;
export type SaveToSapParallelReportLoading = PayloadAction<typeof SAVE_TO_SAP_PARALLEL_REPORT_LOADING, boolean>;
export type ReportsToDelete = {
    [key: string]: boolean
}

export type ParallelReportState = {
    parallel_report_loading: boolean,
    parallel_original_report_uuid: string | null,
    parameters: ReportParameters | null,
    reports_to_delete: ReportsToDelete,
    save_to_sap_parallel_loading: boolean
}

export const report_initial_state: ParallelReportState = {
    parallel_original_report_uuid: null,
    parallel_report_loading: false,
    parameters: null,
    reports_to_delete: {},
    save_to_sap_parallel_loading: false
}

export type ParallelReportActionsTypes = (
    UpdateParallelReportAction |  ParallelReportLoading | ResetGanttAction |
    RemoveReportToDelete | AddReportToDelete | UpdateParallelReportParameters | UnsetReportToDelete |
    SaveToSapParallelReportLoading
);

export const parallelReportReducer = (state: ParallelReportState, action: ParallelReportActionsTypes) => {
    if (!state) state = deepClone(report_initial_state)
    let new_state = state;

    if (action.type === RESET_GANTT) {
        return deepClone(report_initial_state);
    } if (action.type === UPDATE_PARALLEL_REPORT_ACTION_NAME) {
        if (new_state.parallel_original_report_uuid === null) {
            new_state.parallel_original_report_uuid = action.data;
        }

        new_state = { ...new_state };
    } else if (action.type === PARALLEL_REPORT_LOADING_ACTION_NAME) {
        new_state.parallel_report_loading = action.data;
        new_state = { ...new_state };
    } else if (action.type === UPDATE_PARALLEL_REPORT_PARAMETERS) {
        new_state.parameters = action.data;
        new_state = {...new_state};
    } else if (action.type === ADD_REPORT_TO_DELETE) {
        new_state.reports_to_delete[action.data] = true;
        new_state = { ...new_state };
    } else if (action.type === REMOVE_REPORT_TO_DELETE) {
        delete new_state.reports_to_delete[action.data];
        new_state = { ...new_state };
    } else if (action.type === UNSET_REPORT_TO_DELETE) {
        new_state.reports_to_delete = {};
        new_state = { ...new_state };
    } else if (action.type === SAVE_TO_SAP_PARALLEL_REPORT_LOADING) {
        new_state.save_to_sap_parallel_loading = action.data;
        new_state= { ...new_state };
    }

    return new_state;
}
