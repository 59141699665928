// @flow
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router'

import * as BusinessLogic from "../../../lib/BusinessLogic";
import GanttChart from "./GanttChart/GanttChart";
import Controls from "./Controls";
import LeftMenu from "./LeftMenu/LeftMenu";
import ShowGanttOrderDataOnClick from "./EventListeners/ShowGanttOrderDataOnClick"
import OrderAdvancedMenu from "./OrderAdvancedMenu";
import GanttPanes from "./GanttPanes";
import Loader from "../../Loader";
import {
    INSIGHT_TYPES,
    LINE_GROUP_TAGS,
    LINE_GROUP_TAGS_ACCESS,
    ORDER_TAGS,
    PLANT_TAGS_ACCESS
} from "../../../lib/ManufacturingTags.generated";
import { INSIGHT_ACTIONS } from "../../../lib/Models";
import { GanttChartSource, RESET_PROPERTIES, UPDATE_PROPERTIES_ACTION_NAME, default_configuration} from "./reducers/properties";
import { RESET_GANTT } from "./reducers/common";
import { LinesOrdersLogic, ReportCache, ReportLogic } from "./reducers/BusinessLogic";
import { PLANNING_TABLE_DISPLAY_TYPES } from "./DisplayTypesMenu";
import * as mt from "../../../lib/backend/manufacturing2.generated.types";
import KpisContainer from "./KpisContainer";
import InsightsList from "../InsightsList";
import { exportToolTransfer } from "../ToolTransferPrint";
import StockRequirementsGanttChart from "./GanttChart/StockRequirementsGanttChart";
import ResizeVerticalDivider from "./ResizeVerticalDivider";
import { ResizeLeftMenu } from "./reducers/BusinessLogic";
import PlanningTableEditModal, { onSaveComment } from "./PlanningTableEditModal";

import type { IOrderModelBase } from "../../../lib/backend/manufacturing2.generated.types";
import type { UpdateLineOrderComment } from "./reducers/linesOrders"
import type {
    PropertiesState,
    UpdatePropertiesActionType,
    ResetProperties
} from "./reducers/properties";
import type { IReportModelEx } from "../../../lib/SimulationReportModels";
import type { ReportState, ResetReportAction } from "./reducers/report";
import type {
    LineOrders,
    ResetLinesOrdersAction,
    SetUnscheduledOrdersAction
} from "./reducers/linesOrders";
import UnscheduledGanttChart from "./GanttChart/UnscheduledGanttChart";
import { translate } from "../../IntlProviderWrapper";
import MaterialStockForecastContainer from "./MaterialStockForecastContainer";
import { SNAP_TO_SHIFT } from "./Draggable/reducers";

import type { LineOrdersLoadActions } from "./reducers/BusinessLogic";
import type { SetErrorAction } from "./reducers/planningTable";
import type { LinesOrdersParameters } from "./reducers/BusinessLogic";
import type { ResetValidLinesAction } from "./reducers/planningTable";
import type { ReduxState } from "./reducers/reducers";
import type { ResetGanttAction, ValidLineOrders } from "./reducers/common";
import type { GanttChartSourceTypes } from "./reducers/properties";
import type { SnapToShiftAction } from "./Draggable/reducers";

export const VERSION_TYPE = {
    initial: "initial",
    current: "current",
    version: "version"
}

const InsightsListWithRouter = withRouter(InsightsList);

type Props = {
    plant_uuid: string,
    line_group_uuid: string,
    report: ReportState,
    stock_report_uuid?: string,
    lines_orders: LineOrders[],
    lines_orders_loading: boolean,
    report_loading: boolean,
    width: number,
    height: number,
    reduxDispatch: (
        args: (
            ResetLinesOrdersAction | ResetReportAction | SetUnscheduledOrdersAction |
            ResetValidLinesAction | ResetGanttAction | UpdatePropertiesActionType |
            ResetProperties | UpdateLineOrderComment | SetErrorAction | LineOrdersLoadActions
        )
    ) => any,
    show_unscheduled_orders: boolean,
    unscheduled_orders: LineOrders[],
    valid_lines: ValidLineOrders[] | null,
    error: string | null,
    show_planning_table_filter: boolean,
    selected_display_type: $Keys<typeof PLANNING_TABLE_DISPLAY_TYPES>,
    properties: PropertiesState,
    zoom_enabled: boolean,
    planning_weeks: number,
    insights: mt.IEventDataEx[] | null,
    report_data: IReportModelEx,
    show_stock_requirements: boolean,
    source_type: GanttChartSourceTypes,
    draggableDispatch: (args: SnapToShiftAction) => void
};

type State = {
    error: string,
    quick_report: IReportModelEx | null,
    left_menu_width: number | null
};

class PlanningTable extends React.Component<Props, State> {

    state = {
        error: "",
        lines: [],
        quick_report: null,
        left_menu_width: null
    }

    phantomNode = null;
    node = null;
    planningTableMainNode = null;

    constructor(props: Props) {
        super(props);
        ReportCache.removeCacheHash();
        this.phantomNode = React.createRef();
        this.node = React.createRef();
        this.planningTableMainNode = React.createRef();
    }

    getAutomaticScheduling() {
        return BusinessLogic.getLineGroupTagBool(this.props.line_group_uuid, LINE_GROUP_TAGS.automatic_scheduling, false);
    }

    getShiftEditWeeks(line_group_uuid: string) {
        // we need to load maximum of ERP Save weeks horizon and planning table weeks horizon
        const tags = BusinessLogic.getLineGroupTags(line_group_uuid);
        return Math.max(
            LINE_GROUP_TAGS_ACCESS.planning_table_save_to_erp_weeks(tags),
            LINE_GROUP_TAGS_ACCESS.planning_table_weeks(tags)
        );
    }

    componentDidMount() {
        const config = default_configuration();
        const parameters: LinesOrdersParameters = {
            line_group_uuid: this.props.line_group_uuid,
            week: config.week,
            year: config.year,
            shift_edit_weeks: this.getShiftEditWeeks(this.props.line_group_uuid)
        };

        LinesOrdersLogic.load(parameters, this.props.reduxDispatch);

        this.props.reduxDispatch({
            type: UPDATE_PROPERTIES_ACTION_NAME,
            data: {
                line_group_uuid: this.props.line_group_uuid,
                source_type: GanttChartSource.planning_table_parallel
            }
        });

        ResizeLeftMenu.listen(() => {
            const w = ResizeLeftMenu.getWidth();
            this.setState({
                left_menu_width: w
            })
        });

        const plant_tags = BusinessLogic.getPlantTags(this.props.plant_uuid);
        this.props.draggableDispatch({
            type: SNAP_TO_SHIFT,
            data: PLANT_TAGS_ACCESS.planning_table_snap_to_shift(plant_tags)
        });
    }

    componentDidUpdate(prev_props: Props) {
        if (prev_props.show_planning_table_filter !== this.props.show_planning_table_filter ||
            prev_props.unscheduled_orders !== this.props.unscheduled_orders ||
            prev_props.show_stock_requirements !== this.props.show_stock_requirements) {
            window.dispatchEvent(new Event('resize'));
        }

        if (prev_props.valid_lines !== this.props.valid_lines) {
            if (this.planningTableMainNode && this.planningTableMainNode.current && this.planningTableMainNode.current.scrollTop) {
                this.planningTableMainNode.current.scrollTop = 0;
            }
        }

        const lg_or_plant_change = (prev_props.line_group_uuid !== this.props.line_group_uuid || prev_props.plant_uuid !== this.props.plant_uuid);
        if (lg_or_plant_change) {
            this.props.reduxDispatch({type: UPDATE_PROPERTIES_ACTION_NAME, data: {
                source_type: GanttChartSource.planning_table_parallel,
                line_group_uuid: this.props.line_group_uuid
            }});
            this.props.reduxDispatch({ type: RESET_GANTT, data: undefined });
        }

        if (lg_or_plant_change && !this.props.lines_orders_loading) {
            ReportCache.removeCacheHash();

            const config = default_configuration();
            const parameters: LinesOrdersParameters = {
                line_group_uuid: this.props.line_group_uuid,
                week: config.week,
                year: config.year,
                shift_edit_weeks: this.getShiftEditWeeks(this.props.line_group_uuid)
            };

            LinesOrdersLogic.load(parameters, this.props.reduxDispatch);
        }
    }

    componentWillUnmount() {
        this.props.reduxDispatch({ type: RESET_GANTT, data: undefined });
        this.props.reduxDispatch({ type: RESET_PROPERTIES, data: undefined });
    }

    onDownloadHTML = () => {
        const event = new Event("export-html-insights");
        const el = document.querySelector("#insights-list");

        if (el instanceof HTMLElement) {
            el.dispatchEvent(event);
        }
    }

    onActionSelect = (action: mt.IInsightAction, insight: mt.IEventDataEx) => {
        if (action.code === INSIGHT_ACTIONS.print_tool_transfer) {
            const order_external_id = ReportLogic.getOrderExternalIdFromInsight(insight);
            const { process_num } = insight.extra_data;
            if (!order_external_id || !process_num) {
                return;
            }

            exportToolTransfer(order_external_id, process_num);
        }
    }

    getShowStockRequirements = () => {
        return this.props.show_stock_requirements;
    }

    renderGantt() {
        const report_data = this.props.report.report_data;
        if (!report_data) {
            return <Loader />;
        }

        return <React.Fragment>
            <GanttPanes
                num_unscheduled_lines={this.props.properties.num_unscheduled_lines}
                count_non_empty_lines={this.props.properties.count_non_empty_lines}
                num_stock_requirements={this.props.properties.num_stock_requirements}
                show_stock_requirements={this.props.show_stock_requirements}
                gantt0={
                    <StockRequirementsGanttChart
                        key={"stock-requirements-" + this.props.show_stock_requirements.toString()}
                        source_type={GanttChartSource.stock_requirements}
                        line_group_uuid={this.props.line_group_uuid}
                    />
                }
                gantt1={
                    <GanttChart
                        key={"planning-table1-" + this.props.show_stock_requirements.toString()}
                        line_group_uuid={this.props.line_group_uuid}
                        show_planning_table_filter={this.props.show_planning_table_filter}
                        source_type={GanttChartSource.planning_table_parallel}
                    />
                }
                gantt2={
                    <UnscheduledGanttChart
                        key={"unscheduled-planning-table-" + this.props.show_stock_requirements.toString() }
                        show_stock_requirements={this.props.show_stock_requirements}
                        days={this.props.properties.days}
                        handleClick={() => {console.log("Clicked...")}}
                        insights={this.props.insights}
                        past_weeks={this.props.properties.past_weeks}
                        report={null}
                        show_only_tool_changes={this.props.properties.show_only_tool_changes}
                        show_past_productions={false}
                        simulation_offset={this.props.properties.simulation_offset}
                        simulation_uuid={"gantt-chart-app-uuid"}
                        year={this.props.properties.year || (new Date()).getFullYear()}
                        line_group_uuid={this.props.line_group_uuid}
                        show_planning_table_filter={this.props.show_planning_table_filter}
                        source_type={GanttChartSource.gantt_unscheduled}
                        show_only_unscheduled={true}
                    />
                }
            />
        </React.Fragment>
    }

    renderToolChanges = () => {
        const { report } = this.props;
        const { report_data } = report;
        if (!report || !report_data) {
            return <Loader />;
        }

        const { result } = report_data;
        const insights = (this.props.insights || []).filter(insight => insight.type === INSIGHT_TYPES.tool_setup);
        return (
            <React.Fragment>
                <div className="d-flex justify-content-end mb-3">
                    <button className="btn btn-primary" onClick={this.onDownloadHTML}>
                        <span>{translate("ShiftDetails.export_for_print", "Export for print")}</span>
                    </button>
                </div>
                <InsightsListWithRouter
                    insights={insights}
                    reportid={report.current_report_uuid || report.original_report_uuid}
                    week={result.from_shift_time.week}
                    year={result.from_shift_time.year}
                    show_filter={false}
                    show_only_future={false}
                    show_links={false}
                    show_actions={false}
                    reverse_order={false}
                    allowed_actions={[INSIGHT_ACTIONS.print_tool_transfer]}
                    onActionSelect={this.onActionSelect}
                />
            </React.Fragment>
        );
    }

    getSvgAttributes() {
        let svg_attributes = {};
        const { width, height } = this.props.properties;

        if (width || height) {
            svg_attributes = {
                width: width,
                height: height
            }
        }

        return svg_attributes;
    }

    renderMaterialForecast = () => {
        const { report, stock_report_uuid } = this.props;
        const report_uuid = (this.props.selected_display_type === PLANNING_TABLE_DISPLAY_TYPES.material_recommend_forecast_in) ?
            stock_report_uuid : (report.current_report_uuid || report.original_report_uuid)

        return <MaterialStockForecastContainer
            report_uuid={report_uuid}
            line_group_uuid={this.props.line_group_uuid}
            planning_weeks={this.props.planning_weeks}
            selected_display_type={this.props.selected_display_type}
        />
    }

    onOrderChange = (order: IOrderModelBase) => {
        const comment = order.tags[ORDER_TAGS.comment];
        onSaveComment(
            order.uuid,
            comment,
            this.props.reduxDispatch
        );
    }

    render() {
        const is_gantt_loading = this.props.report_loading || this.props.lines_orders_loading;
        const material_forecast_display_types = [
            PLANNING_TABLE_DISPLAY_TYPES.material_forecast_out,
            PLANNING_TABLE_DISPLAY_TYPES.material_forecast_in,
            PLANNING_TABLE_DISPLAY_TYPES.material_recommend_forecast_in,
        ];
        return (
            <section id="planning-table2" className="planning-table">
                <article className="article">
                    <PlanningTableEditModal />
                    <OrderAdvancedMenu
                        source_type={this.props.source_type}
                    />
                    <LeftMenu
                        show_planning_table_filter={this.props.show_planning_table_filter}
                        line_group_uuid={this.props.line_group_uuid}
                        plant_uuid={this.props.plant_uuid}
                        left_menu_width={this.state.left_menu_width}
                    />
                    <ResizeVerticalDivider
                        show={this.props.show_planning_table_filter}
                    />
                    <Controls
                        show_planning_table_filter={this.props.show_planning_table_filter}
                        selected_display_type={this.props.selected_display_type}
                        left_menu_width={this.state.left_menu_width}
                    >
                        <div>
                            {
                                this.props.selected_display_type === PLANNING_TABLE_DISPLAY_TYPES.kpis &&
                                <div>
                                    <KpisContainer
                                        line_group_uuid={this.props.line_group_uuid}
                                        is_loading={is_gantt_loading}
                                    />
                                </div>
                            }
                            {
                                this.props.selected_display_type === PLANNING_TABLE_DISPLAY_TYPES.gantt &&
                                this.renderGantt()
                            }
                            {
                                this.props.selected_display_type === PLANNING_TABLE_DISPLAY_TYPES.tool_changes &&
                                this.renderToolChanges()
                            }
                            {
                                material_forecast_display_types.includes(this.props.selected_display_type) &&
                                this.renderMaterialForecast()
                            }
                        </div>
                    </Controls>
                    <ShowGanttOrderDataOnClick onOrderChange={this.onOrderChange} />
                </article>
            </section>
        );
    };
}

export default connect(
    (state: ReduxState, own_props: Props): $Shape<Props> => {
        const report: ReportState = state.gantt_chart_report;
        const planning_table = state.gantt_chart_planning_table;
        const lines_orders: LineOrders[] = state.gantt_chart_lines_orders.lines_orders;
        const loading: boolean = state.gantt_chart_lines_orders.loading;
        const properties = state.gantt_chart_properties;
        const controls = state.gantt_chart_controls;
        const insights = state.gantt_chart_insights.insights;
        const show_stock_requirements = state.gantt_chart_planning_table.show_stock_requirements;
        const recommend_plans = state.gantt_chart_recommend_plans.recommend_plans;

        const stock_report_uuid = (recommend_plans && recommend_plans.length > 0) ?
            recommend_plans[recommend_plans.length - 1].stock_report_uuid : undefined;
        const planning_weeks = properties.days > 0 ? Math.ceil(properties.days / 7) : 1;

        return {
            report,
            stock_report_uuid: (stock_report_uuid !== "") ? stock_report_uuid : undefined,
            report_loading: report.report_loading,
            lines_orders_loading: loading,
            lines_orders,
            show_unscheduled_orders: planning_table.show_unscheduled_orders,
            unscheduled_orders: state.gantt_chart_lines_orders.unscheduled_orders,
            valid_lines: planning_table.show_valid_lines.valid_lines,
            error: planning_table.error,
            planning_weeks,
            properties,
            zoom_enabled: controls.zoom_enabled,
            insights,
            show_stock_requirements
        };
    },
    (dispatch) => ({reduxDispatch: dispatch})
)(PlanningTable);

