// @flow

// main imports
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

import Loader from "../Loader";
import { niceNumber } from "../../lib/Util";

// subcomponents
import ErrorComponent from "../ErrorComponent";
import DateTimePicker from "../DateTimePicker";

// models;
import * as t from "../../lib/backend/batch_operations.generated.types";
import type { IBatchOperationOrder } from "../../lib/backend/batch_operations.generated.types";
import { ORDER_STATUS } from "../../lib/ManufacturingConsts.generated";
import { getBackend } from "../../lib/backend/Backend2";
import { translate } from "../IntlProviderWrapper";
import { USER_TAGS } from "../../lib/CommonTags.generated";
import * as Auth from "../../lib/Auth";

type Props = {
    halb: boolean,
    show: boolean,
    silo_input: boolean,
    location: t.IBatchOperationLocationStatus,
    error: string,
    onSave: Function,
    onCancel: Function
}

/** Internal class that represents GUI state - input, enriched with GUI-specific fields */
type OrderModel = IBatchOperationOrder & {
    isValid: boolean;
    isUnknownWO: boolean;
    isIncompatibleRecipe: boolean;
}

function transformOrder(input: IBatchOperationOrder): OrderModel {
    return {
        isValid: true,
        isUnknownWO: false,
        isIncompatibleRecipe: false,
        order_num: input.order_num,
        material_title: input.material_title,
        name: input.name,
        quantity: input.quantity,
        batch: input.batch,
        comment: input.comment,
        person_id: input.person_id,
        ts_delivered: input.ts_delivered,
    };
}
function transformOrderBack(input: OrderModel): IBatchOperationOrder {
    return {
        order_num: input.order_num,
        material_title: input.material_title,
        name: input.name,
        quantity: input.quantity,
        batch: input.batch,
        comment: input.comment,
        person_id: input.person_id,
        ts_delivered: input.ts_delivered
    };
}


type Recipe = {
    title: string,
    duration: number
}

type State = {
    error: string,
    is_insert: boolean,
    operation: t.IBatchOperationInfo,
    start_time: Date,
    end_time: Date,
    orders: OrderModel[],
    recipe_selected: Recipe,
    work_order_candidates: t.IWorkOrderSuggestion[],
    loading: boolean
}

const emptyOrder: OrderModel = {
    isValid: true,
    isIncompatibleRecipe: false,
    isUnknownWO: false,
    order_num: "",
    material_title: "",
    name: "",
    quantity: 0,
    batch: "",
    comment: "",
    id: 0
};

/** Instance counter */
let lastId: number = 0;

class BatchOperationModal extends Component<Props, State> {

    id: number;

    constructor(props: Props) {
        super(props);
        this.state = this.createState(props);
        this.id = ++lastId;
    }

    async componentDidMount() {
        await this.loadOrderCandidates();
        await this.loadPrograms();
        this.setState({ loading: false });
        if (this.props.location.operation) {
            this.setState({
                recipe_selected: {
                    duration: this.props.location.operation.recipe_duration,
                    title: this.props.location.operation.recipe_title
                }
            });
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.show !== this.props.show || prevProps.location.uuid !== this.props.location.uuid) {
            const state = this.createState(this.props);
            this.setState(state, async () => {
                await this.loadOrderCandidates();
            });
        }
    }

    async loadOrderCandidates() {
        if (this.props.show) {
            const work_order_candidates = await this.getOrderCandidates();
            this.setState({ work_order_candidates });
        }
    }

    async loadPrograms() {
        if (this.props.show) {
            const all_orders = this.state.work_order_candidates;
            const active_orders = this.state.orders;
            let map = new Map();
            for (const order of active_orders) {
                map.set(order.order_num, order.name);
            }
            // select recipe with max duration
            let max_duration_recipe: Recipe = { title: "missing recipe", duration: 0 };
            for (const order of all_orders) {
                if (map.has(order.external_id)) {
                    const recipe: Recipe = { title: order.title, duration: order.norm_machine_time };
                    if (recipe.duration > max_duration_recipe.duration) {
                        max_duration_recipe = recipe;
                    }
                }
            }
            this.setState({ recipe_selected: max_duration_recipe });
        }
    }

    async getOrderCandidates() {
        const res = await getBackend().batchOperations.getWorkOrderSuggestions({ location_id: this.props.location.uuid });
        return res && res.work_orders.sort((a, b) => a.earliest_start - b.earliest_start);
    }

    createState(props: Props): State {
        // copy input data so that it is not overwritten until Save is pressed
        let operation = props.location ? props.location.operation : null;
        const is_insert = (operation === null);
        if (!operation) {
            // create new operation
            operation = {
                end_time: Date.now() + 0 * 60 * 60 * 1000,
                orders: [],
                recipe_duration: 0,
                recipe_title: "",
                start_time: Date.now(),
                uuid: "" // empty uuid means new, unsaved operation
            };
        }
        const {
            start_time,
            end_time,
            orders
        } = operation;

        const newOrders: OrderModel[] = [];
        if (orders.length === 0) {
            // Initialize empty orders - add 1 line
            for (let i = 0; i < 1; i++) {
                newOrders.push(transformOrder(emptyOrder));
            }
        } else {
            // Copy existing orders
            for (let order of orders) {
                newOrders.push(transformOrder(order));
            }
        }

        const res: State = {
            error: "",
            is_insert,
            operation,
            start_time: new Date(start_time),
            end_time: new Date(end_time),
            orders: newOrders,
            recipe_selected: { duration: 0, title: "init_recipe" },
            work_order_candidates: [],
            loading: true
        };
        return res;
    }

    hasValidOrders = (orders: t.IBatchOperationOrder[]) => {
        for (const order of orders) {
            if (!order.order_num) {
                return false;
            }
        }
        return true;
    }

    handleSubmit = (event: Event) => {
        event.preventDefault();
        const locationOrig = this.props.location;
        const {
            is_insert,
            recipe_selected,
            start_time,
            orders,
            operation
        } = this.state;

        if (is_insert) {
            if (orders.some(x => x.quantity <= 0)) {
                this.setState({
                    error: translate("Manufacturing.BatchOp.QuantityZeroOrNegative", "Quantity cannot be zero or negative")
                });
                return false;
            }
            operation.orders = orders.filter(x => x.quantity > 0).map(transformOrderBack);
        } else {
            if (orders.some(x => x.quantity < 0)) {
                this.setState({
                    error: translate("Manufacturing.BatchOp.QuantityNegative", "Quantity cannot be negative")
                });
                return false;
            }
            operation.orders = orders.filter(x => x.quantity >= 0).map(transformOrderBack);
        }

        if (!this.hasValidOrders(operation.orders)) {
            this.setState({
                error: translate("Manufacturing.Lines.cannot_submit_missing_values", "Cannot submit a form with missing values"),
                loading: false
            });
            return false;
        }

        this.setState({ error: "", loading: true });

        const location: t.IBatchOperationLocationStatus = {
            disabled: locationOrig.disabled,
            uuid: locationOrig.uuid,
            name: locationOrig.name,
            size: locationOrig.size,
            group_uuid: locationOrig.group_uuid,
            operation: {
                recipe_duration: recipe_selected.duration,
                recipe_title: recipe_selected.title,
                start_time: start_time.getTime(),
                end_time: start_time.getTime() + this.state.recipe_selected.duration * 1000 * 60 * 60,
                orders: operation.orders,
                uuid: operation.uuid
            }
        };
        this.props.onSave(location);
    }

    handleAdd(event: Event) {
        event.preventDefault();
        this.addEmptyOrder();
    }

    addEmptyOrder() {
        const { orders } = this.state;
        orders.push(JSON.parse(JSON.stringify(emptyOrder)));
        this.setState({ orders });
    }

    handleDateChange(date: Date, name: string) {
        if (name === "start_time") {
            this.setState(state => ({
                start_time: date,
                end_time: new Date(date.getTime() + state.recipe_selected.duration * 1000 * 60 * 60)
            }));
        } else {
            this.setState({
                end_time: date
            });
        }
    }

    async handleWorkOrderChange(event: Event) {
        const { currentTarget: input } = event;
        if (input instanceof HTMLInputElement) {
            const value = input.value;
            const match = input.name.match(/(\w+)\[(\d+)\]/);
            if (match) {
                const idx = Number(match[2]);
                const hits = this.state.work_order_candidates.filter(x => x.external_id === value);
                this.setState((state) => {
                    const order = state.orders[idx];
                    order.order_num = value;
                    order.isValid = hits.length > 0;
                    if (!order.isValid) {
                        order.isUnknownWO = true;
                    }
                    return state;
                }, async () => {
                    const order = this.state.orders[idx];
                    if (order.isValid) {
                        await this.injectSuggestion(order, hits[0]);
                    }
                });

            } else {
                this.setState((state) => {
                    state[input.name] = value;
                    return state;
                });
            }
        }
    }

    handleInputChange(event: Event) {
        const { currentTarget: input } = event;
        if (input instanceof HTMLInputElement) {
            const match = input.name.match(/(\w+)\[(\d+)\]/);
            if (match) {
                const idx = Number(match[2]);
                this.setState((state) => {
                    state.orders[idx][match[1]] = input.value;
                    return state;
                });
            } else {
                this.setState((state) => {
                    state[input.name] = input.value;
                    return state;
                });
            }
        }
    }

    checkQuantity(event: Event) {
        let map = new Map();
        for (const order of this.state.work_order_candidates) {
            map.set(order.external_id, order.quantity_total);
        }
        const { currentTarget: input } = event;
        if (input instanceof HTMLInputElement) {
            const match = input.name.match(/(\w+)\[(\d+)\]/);
            if (match) {
                const idx = Number(match[2]);
                const quantity = parseFloat(input.value);
                const order_external_id = this.state.orders[idx].order_num;
                let prev_sum = 0;
                this.state.orders.forEach((order, index) => {
                    if (order.order_num === order_external_id && index !== idx) {
                        prev_sum += parseFloat(order.quantity);
                    }
                });
                if (!map.has(order_external_id)) {
                    return;
                }
                const quantity_total = map.get(order_external_id);
                if (quantity_total !== undefined && quantity + prev_sum > quantity_total) {
                    this.setState({
                        error: translate("Manufacturing.BatchOp.QuantityLargerThanTotal", "Quantity is greater than total order quantity")
                    });
                } else {
                    this.setState({
                        error: ""
                    });
                }
            }
        }
    }

    handleRecipeChange(event: Event) {
        const target = event.target;
        if (target instanceof HTMLSelectElement) {
            const recipe: Recipe = JSON.parse(target.value);
            const end_time = new Date(this.state.start_time.getTime() + recipe.duration * 1000 * 60 * 60);
            this.setState({ end_time, recipe_selected: recipe });
        }
    }

    async injectSuggestion(order: OrderModel, sugg: t.IWorkOrderSuggestion) {
        order.order_num = sugg.external_id;
        order.name = sugg.material_external_id;
        order.isValid = true;
        order.isIncompatibleRecipe = false;
        order.isUnknownWO = false;
        if (this.props.halb) {
            const input_material = await this.getHalb(order.order_num);
            order.comment = translate("Manufacturing.BatchOp.InputMaterial", "Input material") + ": " + input_material;
            this.forceUpdate();
        }
        this.setState({
            end_time: new Date(this.state.start_time.getTime() + this.state.recipe_selected.duration * 1000 * 60 * 60)
        });
        await this.loadPrograms();
    }

    async suggestWorkOrder(sugg: t.IWorkOrderSuggestion) {
        let found = false;
        let orders = this.state.orders;
        for (const order of orders) {
            if (order.order_num === "") {
                await this.injectSuggestion(order, sugg);
                found = true;
                break;
            }
        }
        if (!found) {
            orders.push(JSON.parse(JSON.stringify(emptyOrder)));
            const order = orders[orders.length - 1];
            await this.injectSuggestion(order, sugg);
        }
        this.setState({ orders });
    }

    removeOrder(event: Event, idx: number) {
        event.preventDefault();
        const orders = this.state.orders.slice(0, idx)
            .concat(this.state.orders.slice(idx + 1));
        this.setState({ orders }, async () => {
            await this.loadPrograms();
        });
    }

    getOrderTitle(order_num: string) {
        for (const order of this.state.work_order_candidates) {
            if (order.external_id === order_num) {
                return order.material_title;
            }
        }
        return "-";
    }

    inputValue(event: Event) {
        const { currentTarget: input } = event;
        if (input instanceof HTMLInputElement) {
            return input.value;
        } else {
            return "";
        }
    }

    // get input material of type HALB
    async getHalb(order_external_id: string) {
        if (order_external_id === "") {
            return "";
        }
        const res = await getBackend().manufacturing.getInputMaterials({
            order_external_id: order_external_id
        });
        if (res.input_materials.length === 0) {
            return "";
        }
        const halbs = res.input_materials.filter(material => material.input_material_tags.type_external === "HALB");
        if (halbs.length === 0) {
            return "";
        }
        return halbs[0].input_material_external_id + " " + halbs[0].input_material_title;
    }

    onKeyPress = (e: KeyboardEvent) => {
        if (e.charCode === 13) {
            this.handleSubmit(e);
        }
    }

    render() {
        const {
            show,
            location,
            onCancel
        } = this.props;


        const suggestions = this.state.work_order_candidates &&
            this.state.work_order_candidates.filter(x => x.status === ORDER_STATUS.open).sort(function (a, b) { return a.earliest_start - b.earliest_start });

        const is_kiosk = Auth.getUserTag(USER_TAGS.kiosk) === "true";

        return (
            <Modal
                show={show}
                bsSize="large"
                className="batch-operation-modal"
                dialogClassName="modal-dialog-scrollable"
                onHide={onCancel}
                onKeyPress={this.onKeyPress}
            >
                <Modal.Header>
                    <Modal.Title>{location && location.name}</Modal.Title>
                    <button type="button" className="close" onClick={onCancel}>
                        <span aria-hidden="true">×</span>
                        <span className="sr-only"><FormattedMessage id="common.close" defaultMessage="Close" /></span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    {
                        this.state.loading ? <Loader /> :
                            <form id="batch-operation-form" onSubmit={this.handleSubmit}>
                                <ErrorComponent msg={this.props.error} type="error" />
                                <ErrorComponent msg={this.state.error} type="error" />
                                <div className="form-row">
                                    <div className="col-sm-4 form-group">
                                        <label htmlFor={`batch-operation-modal-recipe-${this.id}`}>Program</label>
                                        {this.state.recipe_selected.duration > 0 && <div> {this.state.recipe_selected.title} ({niceNumber(this.state.recipe_selected.duration, 2)} h) </div>}
                                        {this.state.recipe_selected.duration <= 0 && <div>-</div>}
                                    </div>
                                    <div className="col-sm-4 form-group">
                                        <label htmlFor={`batch-operation-modal-start-time-${this.id}`}>
                                            <FormattedMessage id="common.start_noun" defaultMessage="Start" />
                                        </label>
                                        <DateTimePicker
                                            inputId={`batch-operation-modal-start-time-${this.id}`}
                                            name="start_time"
                                            value={this.state.start_time}
                                            pickerType="date-time"
                                            popupPostion="fixed"
                                            onChange={(date) => { this.handleDateChange(date, "start_time") }}
                                            disabled={is_kiosk}
                                        />
                                    </div>
                                    <div className="col-sm-4 form-group">
                                        <label htmlFor={`batch-operation-modal-end-time-${this.id}`}>
                                            <FormattedMessage id="common.end" defaultMessage="End" />
                                        </label>
                                        <DateTimePicker
                                            inputId={`batch-operation-modal-end-time-${this.id}`}
                                            name="end_time"
                                            value={this.state.end_time}
                                            minValue={this.state.start_time}
                                            pickerType="date-time"
                                            popupPostion="fixed"
                                            onChange={(date) => { this.handleDateChange(date, "end_time") }}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div className="order-suggestions btn-group ">
                                    {suggestions ? suggestions.map(sugg => (
                                        <button
                                            type="button"
                                            className="btn btn-short btn-outline-primary"
                                            onClick={async () => await this.suggestWorkOrder(sugg)}
                                            key={"sugg_" + sugg.external_id}
                                        >
                                            {sugg.external_id} </button>
                                    )) : null}
                                </div>
                                <div className="form-row order-header">
                                    <div className="col form-group mb-0">
                                        <label htmlFor="">
                                            <FormattedMessage id="Manufacturing.BatchOp.WorkOrder" defaultMessage="DN" />
                                        </label>
                                    </div>
                                    <div className="col form-group mb-0">
                                        <label htmlFor="">
                                            <FormattedMessage id="Manufacturing.BatchOp.Ident" defaultMessage="Ident" />
                                        </label>
                                    </div>
                                    <div className="col form-group mb-0">
                                        <label htmlFor="">
                                            <FormattedMessage id="Manufacturing.BatchOp.Title" defaultMessage="Naziv" />
                                        </label>
                                    </div>
                                    <div className="col form-group mb-0">
                                        <label htmlFor="">
                                            <FormattedMessage id="Manufacturing.BatchOp.Quantity" defaultMessage="Količina" />
                                        </label>
                                    </div>
                                    <div className="col form-group mb-0">
                                        <label htmlFor="">
                                            <FormattedMessage id="Manufacturing.BatchOp.Package" defaultMessage="Paleta" />
                                        </label>
                                    </div>
                                    <div className="col form-group mb-0">
                                        <label htmlFor="">
                                            <FormattedMessage id="Manufacturing.BatchOp.Comment" defaultMessage="Komentar" />
                                        </label>
                                    </div>
                                </div>
                                {this.state.orders.map((order, i) => {
                                    const lastStr = i === this.state.orders.length - 1 ? "-last" : "";
                                    const input_class = this.props.silo_input && i === this.state.orders.length - 1 ?
                                        "form-control selected_input" : "form-control";
                                    return (
                                        // TODO re-enable warning when clients are ready
                                        <div className={(order.isUnknownWO ? "alert-warning" : ""/*(order.isIncompatibleRecipe ? "alert-danger" : "")*/)} key={"batchop_orders_child_" + i}>
                                            <div className={"form-row order"} key={i}>
                                                <div className="col form-group">
                                                    <div className="">
                                                        <input
                                                            test-id={`recipe-num-input${lastStr}`}
                                                            className={`${input_class}${!order.isValid ? " is-uncertain" : ""}`}
                                                            type="text"
                                                            name={`order_num[${i}]`}
                                                            value={this.state.orders[i].order_num}
                                                            onChange={
                                                                async event => {
                                                                    await this.handleWorkOrderChange(event);
                                                                    if (this.props.halb) {
                                                                        const order_external_id = this.inputValue(event);
                                                                        const input_material = await this.getHalb(order_external_id);
                                                                        order.comment = "Vhodni material: " + input_material;
                                                                    }
                                                                }
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col form-group">
                                                    <input test-id={`recipe-name-input${lastStr}`} className={input_class} type="text" name={`name[${i}]`} value={this.state.orders[i].name} onChange={event => { this.handleInputChange(event) }} />
                                                </div>
                                                <div className="col">
                                                    {this.getOrderTitle(order.order_num)}
                                                </div>
                                                <div className="col form-group">
                                                    <input test-id={`recipe-quantity-input${lastStr}`} className={input_class} type="number" name={`quantity[${i}]`} value={this.state.orders[i].quantity} min="0" onChange={event => { this.handleInputChange(event); this.checkQuantity(event); }} />
                                                </div>
                                                <div className="col form-group">
                                                    <input test-id={`recipe-batch-input${lastStr}`} className={input_class} type="text" name={`batch[${i}]`} value={this.state.orders[i].batch} onChange={event => { this.handleInputChange(event) }} />
                                                </div>
                                                <div className="col form-group">
                                                    <input test-id={`recipe-comment-input${lastStr}`} className={input_class} type="text" name={`comment[${i}]`} value={this.state.orders[i].comment} onChange={event => { this.handleInputChange(event) }} />
                                                </div>
                                                <div className="col form-group flex-grow-0">
                                                    <button className="btn btn-outline-secondary btn-icon btn-icon-remove" onClick={event => this.removeOrder(event, i)} disabled={this.state.orders.length === 1}>
                                                        <span className="sr-only">Remove</span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div>
                                                {!order.isValid && order.isUnknownWO && (
                                                    <FormattedMessage id="Manufacturing.BatchOp.UnknownWO" defaultMessage="Neznan DN" />
                                                )}
                                                {// TODO re-enable warning when clients are ready
                                        /*{!order.isValid && order.isIncompatibleRecipe && (
                                        <span>
                                            <FormattedMessage id="Manufacturing.BatchOp.RecipeMismatch" defaultMessage="Recipe mismatch" />: {order.recipeTitle}
                                        </span>
                                        )}*/}
                                            </div>
                                        </div>
                                    )
                                })}
                                <div className="pull-right">
                                    <button test-id="recipe-add-button" className="btn btn-primary btn-icon btn-icon-add" onClick={event => { this.handleAdd(event) }}>
                                        <span className="sr-only"><FormattedMessage id="common.add" defaultMessage="Add" /></span>
                                    </button>
                                </div>
                            </form>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary"
                        test-id="recipe-save-button"
                        type="submit"
                        form="batch-operation-form"
                        disabled={this.state.loading}
                    >
                        <FormattedMessage id="common.save" defaultMessage="Save" />
                    </button>
                    {
                        !this.state.loading &&
                        <button className="btn btn-outline-secondary"
                            onClick={onCancel}>
                            <FormattedMessage id="common.cancel" defaultMessage="Cancel" />
                        </button>
                    }
                </Modal.Footer>
            </Modal>
        );
    }
}

export default BatchOperationModal;
