// @flow

// main imports
import React, { Component } from "react";
import ReactRouterPropTypes from "react-router-prop-types";
import { FormattedMessage } from "react-intl";
import { translate } from "./IntlProviderWrapper";

// backend
import * as Auth from "../lib/Auth";
import { getBackend as getBackend2 } from "../lib/backend/Backend2";
import {
    ROLE_ADMIN,
    ROLE_POWER_USER,
    ROLE_DEMO_USER,
    ROLE_TICKET_USER,
    ROLE_MAN_ELECTRICITY_ADMIN,
    ROLE_MAN_ELECTRICITY_USER,
    ROLE_MAN_PLANNER,
    ROLE_MAN_PLANT_MANAGER,
    ROLE_MAN_SHOPFLOOR_MANAGER,
    getLoggedinUser
} from "../lib/Auth";
import { USER_TAGS, USER_TAGS_ACCESS } from "../lib/CommonTags.generated";
import { stringCompare, createCsvAndDownload, niceDateTime } from "../lib/Util";

// import subcomponents
import ErrorComponent from "./ErrorComponent";
import { Link } from "react-router-dom";
import Toggle from "react-toggle";
import { Button } from "react-bootstrap";

import Authorization from "../components/Authorization";
import { PERMISSION_NAMES } from "../lib/Auth";

type UserRowObj = {
    active: boolean,
    uuid: string,
    title: string,
    username: string,
    must_activate_twofa: boolean,
    must_activate_twofa_date: number,
    must_reset: boolean,
    is_admin: boolean,
    is_power_user: boolean,
    is_demo_user: boolean,
    is_planner: boolean,
    is_plant_manager: boolean,
    is_shopfloor_manager: boolean,
    is_ticket_user: boolean,
    is_electricity_admin: boolean,
    is_electricity_user: boolean,
    is_kiosk: boolean,
    tags: any
}

// defining types
type Props = {
    match: ReactRouterPropTypes.match,
    history: ReactRouterPropTypes.history,
    location: ReactRouterPropTypes.location
}

type State = {
    users: UserRowObj[],
    title_filter: string,
    only_active: boolean,
    error: string,
    warning: string
}

/**
 * Displaying user list.
 */
class UsersComponent extends Component<Props, State> {

    /**
     * Constructor.
     * @param {Props} props Props object.
     */
    constructor(props: Props) {
        super(props);
        const state: State = {
            users: [],
            title_filter: "",
            only_active: false,
            error: "",
            warning: ""
        };
        this.state = state;
    }

    /**
     * Actual loading data from backend.
     */
    async reloadData() {
        try {
            const res = await getBackend2().users.getUsers({});
            const users_res = res.users;
            const users: UserRowObj[] = [];
            users_res.forEach(x => {
                users.push({
                    active: x.active,
                    must_reset: x.must_reset,
                    is_admin: x.roles.indexOf(ROLE_ADMIN) >= 0,
                    is_kiosk: x.tags[USER_TAGS.kiosk] === "true",
                    is_power_user: x.roles.indexOf(ROLE_POWER_USER) >= 0,
                    is_demo_user: x.roles.indexOf(ROLE_DEMO_USER) >= 0,
                    is_ticket_user: x.roles.indexOf(ROLE_TICKET_USER) >= 0,
                    is_electricity_admin: x.roles.indexOf(ROLE_MAN_ELECTRICITY_ADMIN) >= 0,
                    is_electricity_user: x.roles.indexOf(ROLE_MAN_ELECTRICITY_USER) >= 0,
                    is_planner: x.roles.indexOf(ROLE_MAN_PLANNER) >= 0,
                    is_plant_manager: x.roles.indexOf(ROLE_MAN_PLANT_MANAGER) >= 0,
                    is_shopfloor_manager: x.roles.indexOf(ROLE_MAN_SHOPFLOOR_MANAGER) >= 0,
                    title: x.title,
                    username: x.username,
                    uuid: x.uuid,
                    tags: x.tags,
                    must_activate_twofa: x.must_activate_twofa,
                    must_activate_twofa_date: x.must_activate_twofa_date
                });
            });
            users.sort((a, b) => stringCompare(a.username, b.username));
            this.setState({ users: users })
        } catch (err) {
            this.setState({ error: err });
        };
    }

    /**
     * Actual loading data from backend.
     */
    loadComponent() {
        this.reloadData();
    }

    /**
     * Mounting data for this component.
     */
    componentDidMount() {
        this.loadComponent();
    }

    /**
     * Handling error from subordinate component.
     */
    handleToError = (str: string) => {
        this.setState({ error: str });
    }

    /** Handle role toggling (checkbox clicks) */
    handleKioskToggle = (user: UserRowObj) => {
        const new_value = !user.is_kiosk;
        const user_uuid = user.uuid;
        this.setState(prevState => {
            prevState.users.forEach(x => {
                if (x.uuid === user_uuid) {
                    x.is_kiosk = new_value;
                }
            })
            return prevState;
        },
            async () => {
                await getBackend2().users.setUserTag(
                    { id: user_uuid, name: USER_TAGS.kiosk, value: new_value ? "true" : "false" }
                );
            });
    }

    /** Handle role toggling (checkbox clicks) */
    handleRoleToggle = async (user: UserRowObj, role: string) => {
        try {
            let new_value = false;
            switch (role) {
                case ROLE_ADMIN: new_value = !user.is_admin; break;
                case ROLE_POWER_USER: new_value = !user.is_power_user; break;
                case ROLE_DEMO_USER: new_value = !user.is_demo_user; break;
                case ROLE_TICKET_USER: new_value = !user.is_ticket_user; break;
                case ROLE_MAN_ELECTRICITY_ADMIN: new_value = !user.is_electricity_admin; break;
                case ROLE_MAN_ELECTRICITY_USER: new_value = !user.is_electricity_user; break;
                case ROLE_MAN_PLANNER: new_value = !user.is_planner; break;
                case ROLE_MAN_PLANT_MANAGER: new_value = !user.is_plant_manager; break;
                case ROLE_MAN_SHOPFLOOR_MANAGER: new_value = !user.is_shopfloor_manager; break;
                default: break;
            }

            if (role === ROLE_ADMIN && new_value === false) {
                // check if we are trying to deactivate our own admin role
                const user_data = getLoggedinUser();
                if (user_data.username === user.username) {
                    this.setState({ warning: "You cannot deactivate your admin role." });
                    return;
                }
            }

            if (role === ROLE_POWER_USER && new_value === false) {
                // check if we are trying to deactivate our own power_user role
                const user_data = getLoggedinUser();
                if (user_data.username === user.username) {
                    this.setState({ warning: "You cannot deactivate your power-user role." });
                    return;
                }
            }

            if (role === ROLE_DEMO_USER && new_value === false) {
                // check if we are trying to deactivate our own demo_user role
                const user_data = getLoggedinUser();
                if (user_data.username === user.username) {
                    this.setState({ warning: "You cannot deactivate your demo-user role." });
                    return;
                }
            }

            if (role === ROLE_TICKET_USER && new_value === false) {
                // check if we are trying to deactivate our own ticket_user role
                const user_data = getLoggedinUser();
                if (user_data.username === user.username) {
                    this.setState({ warning: "You cannot deactivate your ticket role." });
                    return;
                }
            }


            if (new_value === false) {
                await getBackend2().users.removeRoleForUser({ id: user.uuid, role });
            } else {
                await getBackend2().users.addRoleForUser({ id: user.uuid, role });
            }
            this.reloadData();
        } catch (err) {
            this.setState({ error: err.message });
        }
    }

    /**
     * Handling change of any input component.
     */
    handleInputChange = (event: Event) => {
        if (event.currentTarget instanceof HTMLInputElement || event.currentTarget instanceof HTMLSelectElement) {
            // read new values
            const target = event.currentTarget;
            let value = target.value;
            const name = target.name;

            // hack for toggle element
            if (name === "only_active") {
                //$FlowFixMe
                value = target.checked;
            }

            // update new datasource placeholder
            this.setState(prev_state => {
                prev_state[name] = value;
                return prev_state;
            });
        }
    };

    userSetActive = async (uuid: string, active: boolean) => {
        try {
            await getBackend2().users.userSetActive({ id: uuid, active });
            this.reloadData();
            if (this.state.error) this.setState({ error: "" });
        } catch (err) {
            this.setState({
                error: translate("common.user_active_error", "Could not activate/deactivate user")
            });
        }
    }

    exportUsers = async () => {
        try {
            this.setState({ error: "" });
            const { csv } = await getBackend2().users.getUsersExport({});
            createCsvAndDownload(csv, "users.csv");
        } catch (err) {
            this.setState({
                error: err.message
            });
        }
    }

    showUserInfo = (uuid: string) => {
        this.props.history.push("/admin/user_info/" + uuid);
    }

    /**
     * Search method within the users list.
     *
     * @param {string} needle Needle that we are looking for.
     * @param {UserRowObj} obj Object where we are looking for the needle.
     *
     * @return {boolean} Returning true if needle was found, false otherwise.
     */
    includes(needle: string, obj: UserRowObj) {
        needle = needle.toLowerCase();
        if (needle.length === 0) return true;
        if (obj.title.toLowerCase().indexOf(needle) > -1) return true;
        if (obj.username.toLowerCase().indexOf(needle) > -1) return true;
        return false;
    }

    /**
     * Rendering JSX for current component.
     */
    render() {
        // check if user is in kiosk mode
        const is_kiosk = Auth.getUserTag("kiosk") === "true";
        // is the user in admin role
        const is_admin = !is_kiosk && Auth.isInRole(Auth.ROLE_ADMIN);

        // do we show the table?
        let showTable = this.state.users.length > 0;

        // filter the users by title/username
        let filtered_users = this.state.users
            .filter((user) => this.includes(this.state.title_filter, user));
        // filter by active fielld
        if (this.state.only_active) {
            filtered_users = filtered_users.filter(user => user.active);
        }

        return (
            <article id="admin-users" className="lines_article">
                <section id="statistics" className="data_sources">
                    <div className="filter_bar">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12">
                                    <ul className="list-inline">
                                        <li className="list-inline-item">
                                            <h2>
                                                <FormattedMessage id="common.users" defaultMessage="Users" />
                                            </h2>
                                        </li>
                                        <li className="list-inline-item">
                                            <div className="container-fluid">
                                                <div className="form-group">
                                                    <FormattedMessage id="Users.filter_tooptip" defaultMessage="Filter by title or user" >
                                                        {text => <input type="text" className="form-control search_bar"
                                                            placeholder={text}
                                                            name="title_filter" value={this.state.title_filter} onChange={this.handleInputChange} />}
                                                    </FormattedMessage>
                                                </div>
                                                <div className="form-group" >
                                                    <label><FormattedMessage id="common.only_active" defaultMessage="Only active" /></label>
                                                    <Toggle name="only_active" defaultChecked={this.state.only_active}
                                                        onChange={this.handleInputChange} className="vertical-align-middle" />
                                                </div>
                                                <div className="form-group" >
                                                    <Link to="/admin/user_new" className="btn_toolbar btn_add_data">
                                                        <FormattedMessage id="common.add" defaultMessage="Add" />
                                                    </Link>
                                                </div>
                                                <div className="form-group" >
                                                    <Button className="btn_toolbar btn_export" onClick={() => this.exportUsers()}>
                                                        <FormattedMessage id="common.export" defaultMessage="Export data" />
                                                    </Button>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid space15">
                        {
                            showTable &&
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="white_box charts">
                                        <ErrorComponent msg={this.state.error} type="error" />
                                        <ErrorComponent msg={this.state.warning} type="warning" />
                                        <div className="table_content">
                                            <table id="datatable-responsive" className="table dt-responsive bottom_table stripe_cols_table" cellSpacing="0" width="100%">
                                                <thead>
                                                    <tr>
                                                        <th><FormattedMessage id="common.username" defaultMessage="Username" /></th>
                                                        <th><FormattedMessage id="common.title" defaultMessage="Title" /></th>
                                                        <th><FormattedMessage id="common.status" defaultMessage="Status" /></th>
                                                        {is_admin && <th><div className="vertical-text"><FormattedMessage id="role.admin" defaultMessage="Admin" /></div></th>}
                                                        <th><div className="vertical-text"><FormattedMessage id="role.power_user" defaultMessage="Power-user" /></div></th>
                                                        <th><div className="vertical-text"><FormattedMessage id="role.demo_user" defaultMessage="Demo-user" /></div></th>
                                                        <th><div className="vertical-text"><FormattedMessage id="role.ticket_user" defaultMessage="Ticketing" /></div></th>
                                                        <th><div className="vertical-text"><FormattedMessage id="role.electricity_admin" defaultMessage="Electricity admin" /></div></th>
                                                        <th><div className="vertical-text"><FormattedMessage id="role.electricity_user" defaultMessage="Electricity user" /></div></th>
                                                        <th><div className="vertical-text"><FormattedMessage id="role.planner" defaultMessage="Planner" /></div></th>
                                                        <th><div className="vertical-text"><FormattedMessage id="role.plant_manager" defaultMessage="Plant manager" /></div></th>
                                                        <th><div className="vertical-text"><FormattedMessage id="role.shopfloor_manager" defaultMessage="Shopfloor manager" /></div></th>
                                                        <th><div className="vertical-text"><FormattedMessage id="common.kiosk_user" defaultMessage="Kiosk user" /></div></th>
                                                        <th>&nbsp;</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        filtered_users.map((el, i) => {

                                                            return <tr key={i}>
                                                                <td title={el.uuid}>{el.username}<br /><span className="text-muted">{el.uuid}</span></td>
                                                                <td>{el.title}</td>
                                                                <td>
                                                                    {el.active ?
                                                                        <span className="badge badge-success"><FormattedMessage id="common.active" defaultMessage="Active" /></span> :
                                                                        <span className="badge badge-secondary"><FormattedMessage id="common.inactive" defaultMessage="Inactive" /></span>}
                                                                    <br />
                                                                    {el.must_reset && <span className="badge badge-warning"><FormattedMessage id="common.password_reset" defaultMessage="Password reset" /></span>}
                                                                    {el.must_activate_twofa &&
                                                                        <span className="badge badge-warning">
                                                                            <FormattedMessage
                                                                                id={"common.twofa_must_activate" + niceDateTime(new Date(el.must_activate_twofa_date))}
                                                                                defaultMessage={"Must activate 2FA until: " + niceDateTime(new Date(el.must_activate_twofa_date))}
                                                                            />
                                                                        </span>}
                                                                    {USER_TAGS_ACCESS.twofa(el.tags) && <span className="badge badge-success"><FormattedMessage id="2FA" defaultMessage="2FA" /></span>}
                                                                </td>
                                                                {is_admin && <td>
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={el.is_admin}
                                                                        onChange={() => { this.handleRoleToggle(el, ROLE_ADMIN); }} />
                                                                </td>}
                                                                <td>
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={el.is_power_user}
                                                                        onChange={() => { this.handleRoleToggle(el, ROLE_POWER_USER); }} />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={el.is_demo_user}
                                                                        onChange={() => { this.handleRoleToggle(el, ROLE_DEMO_USER); }} />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={el.is_ticket_user}
                                                                        onChange={() => { this.handleRoleToggle(el, ROLE_TICKET_USER); }} />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={el.is_electricity_admin}
                                                                        onChange={() => { this.handleRoleToggle(el, ROLE_MAN_ELECTRICITY_ADMIN); }} />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={el.is_electricity_user}
                                                                        onChange={() => { this.handleRoleToggle(el, ROLE_MAN_ELECTRICITY_USER); }} />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={el.is_planner}
                                                                        onChange={() => { this.handleRoleToggle(el, ROLE_MAN_PLANNER); }} />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={el.is_plant_manager}
                                                                        onChange={() => { this.handleRoleToggle(el, ROLE_MAN_PLANT_MANAGER); }} />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={el.is_shopfloor_manager}
                                                                        onChange={() => { this.handleRoleToggle(el, ROLE_MAN_SHOPFLOOR_MANAGER); }} />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={el.is_kiosk}
                                                                        onChange={() => { this.handleKioskToggle(el); }} />
                                                                </td>
                                                                <td>
                                                                    <div style={{ minWidth: "100px" }}>
                                                                        <Authorization.button
                                                                            permission={PERMISSION_NAMES.ShiftTableEdit}
                                                                            className="far fa-edit btn btn-short btn-outline-primary"
                                                                            style={{ marginLeft: "5px", cursor: "pointer" }}
                                                                            onClick={() => { this.showUserInfo(el.uuid); }}
                                                                            title={"Edit user"}
                                                                        />
                                                                    </div>
                                                                </td>
                                                            </tr>;
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </section>
            </article>
        )
    }
}

export default UsersComponent;
