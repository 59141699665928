// @flow

// main imports
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ReactRouterPropTypes from "react-router-prop-types";
import { FormattedMessage } from "react-intl";

// models
import type { ILineData, IPlantData } from "../../lib/backend/manufacturing2.generated.types";

// backend
import { getBackend } from "../../lib/backend/Backend2";
import {
    waitForManufacturingAsync, getPlantsForUser, getLineGroupsForUser, filterLinesForUser,
    forceManufacturingCacheRefresh
} from "../../lib/BusinessLogic";
import * as Auth from "../../lib/Auth";
import { parseUrlQueryVariables } from "../../lib/Util";

// import subcomponents
import LinesList from "./LinesList"
import LinesDetails from "./LinesDetails"
import ErrorComponent from "../ErrorComponent";
import { translate } from "../IntlProviderWrapper";
import * as Redirect from "../URLHandler/Redirect";

import type { ResourceEditFiltersState } from "../redux/reducers/resourceEditFiltersReducer";
import { ResourcesLayout } from "../MainContent";

// defining types
type Props = {
    match: ReactRouterPropTypes.match,
    history: ReactRouterPropTypes.history,
    location: ReactRouterPropTypes.location,
    resource_edit_filters: ResourceEditFiltersState
}

type State = {
    lines: ILineData[],
    line_uuid_to_group_titles: Map<string, string[]>,
    plants: IPlantData[],
    plant_uuid: string,
    error: string,
    warning: string,
    selected: ILineData | null
}

/**
 * Displaying production lines list.
 */
class LinesComponent extends Component<Props, State> {
    linesListRef: { current: null | LinesList };

    /**
     * Constructor.
     * @param {Props} props Props object.
     * @param {State} state State object.
     */
    constructor(props: Props, state: State) {
        super(props);
        this.state = {
            lines: [],
            line_uuid_to_group_titles: new Map(),
            plants: [],
            plant_uuid: "",
            error: "",
            warning: "",
            selected: null
        };
        this.linesListRef = React.createRef();
    }

    /**
     * Load component data from backend.
     */
    async loadComponent() {
        try {
            await waitForManufacturingAsync();
            const plants = getPlantsForUser();
            const line_groups = getLineGroupsForUser();
            let lines = filterLinesForUser(
                (await getBackend().manufacturing.getLines({})).lines, true
            );
            // collect plant weights
            const plant_weights: Map<string, number> = new Map();
            plants.forEach(x => {
                plant_weights.set(x.uuid, x.weight);
            });

            // collect lingroup weights
            const linegroup_weights: Map<string, number> = new Map();
            // line => linegroup mapping
            const line_linegroup: Map<string, string> = new Map();
            line_groups.forEach(x => {
                const lines1 = x.line_uuids;
                lines1.forEach(x1 => {
                    line_linegroup.set(x1, x.uuid);
                });
                linegroup_weights.set(x.uuid, x.weight);
            });

            if (this.props.resource_edit_filters.plant_uuid) {
                lines = lines.filter(l => l.plant_uuid === this.props.resource_edit_filters.plant_uuid);
            }

            // add plant weight and linegroup weight to each line
            const line_linegroup_weights: Map<string, number> = new Map();
            const line_plant_weights: Map<string, number> = new Map();
            lines.forEach(x => {
                if (line_linegroup.has(x.uuid)) {
                    const linegroup_uuid = line_linegroup.get(x.uuid) || "";
                    if (linegroup_weights.has(linegroup_uuid)) {
                        const line_linegroup_weight = linegroup_weights.get(linegroup_uuid);
                        if (typeof (line_linegroup_weight) !== "undefined") {
                            line_linegroup_weights.set(x.uuid, line_linegroup_weight);
                        }
                    }
                }
                if (plant_weights.has(x.plant_uuid)) {
                    const line_plant_weight = plant_weights.get(x.plant_uuid);
                    if (typeof (line_plant_weight) !== "undefined") {
                        line_plant_weights.set(x.uuid, line_plant_weight);
                    }
                }
            });
            // sort lines  by plant, linegroup, line weight
            lines.sort((a, b) => {
                let a_plant_weight = Number.MAX_SAFE_INTEGER;
                if (line_plant_weights.has(a.uuid)) {
                    a_plant_weight = line_plant_weights.get(a.uuid);
                    if (typeof (a_plant_weight) === "undefined") {
                        a_plant_weight = Number.MAX_SAFE_INTEGER;
                    }
                }
                let b_plant_weight = Number.MAX_SAFE_INTEGER;
                if (line_plant_weights.has(b.uuid)) {
                    b_plant_weight = line_plant_weights.get(b.uuid);
                    if (typeof (b_plant_weight) === "undefined") {
                        b_plant_weight = Number.MAX_SAFE_INTEGER;
                    }
                }
                let a_linegroup_weight = Number.MAX_SAFE_INTEGER;
                if (line_linegroup_weights.has(a.uuid)) {
                    a_linegroup_weight = line_linegroup_weights.get(a.uuid);
                    if (typeof (a_linegroup_weight) === "undefined") {
                        a_linegroup_weight = Number.MAX_SAFE_INTEGER;
                    }
                }
                let b_linegroup_weight = Number.MAX_SAFE_INTEGER;
                if (line_linegroup_weights.has(b.uuid)) {
                    b_linegroup_weight = line_linegroup_weights.get(b.uuid);
                    if (typeof (b_linegroup_weight) === "undefined") {
                        b_linegroup_weight = Number.MAX_SAFE_INTEGER;
                    }
                }
                if (a_plant_weight > b_plant_weight) {
                    return 1;
                }
                if (a_plant_weight < b_plant_weight) {
                    return -1;
                }
                if (a_linegroup_weight > b_linegroup_weight) {
                    return 1;
                }
                if (a_linegroup_weight < b_linegroup_weight) {
                    return -1;
                }
                if (a.weight > b.weight) {
                    return 1;
                }
                if (a.weight < b.weight) {
                    return -1;
                }
                return 1;
            });
            let selected = null;
            // Select initial line.
            if (this.props.match.params.uuid) {
                let filtered = lines.filter(line => line.uuid === this.props.match.params.uuid);
                if (filtered.length) {
                    selected = filtered[0];
                }
            } else if (lines.length) {
                selected = lines[0];
            }

            // prepare map from line to its linegroups (can be more!)
            const line_uuid_to_group_titles: Map<string, string[]> = new Map();
            for (const line_group of line_groups) {
                for (const line_uuid of line_group.line_uuids) {
                    const group_titles = line_uuid_to_group_titles.get(line_uuid);
                    if (group_titles !== undefined) {
                        group_titles.push(line_group.title);
                        line_uuid_to_group_titles.set(line_uuid, group_titles);
                    } else {
                        line_uuid_to_group_titles.set(line_uuid, [line_group.title]);
                    }
                }
            }

            const url_params = parseUrlQueryVariables(this.props.location.search);
            const plant_uuid = url_params.plant_uuid !== undefined ? url_params.plant_uuid : "";

            this.setState({
                lines,
                line_uuid_to_group_titles,
                plants,
                plant_uuid,
                selected,
                error: "",
                warning: ""
            });

        } catch (e) {
            this.setState({ error: e });
        }
    }

    /**
     * Mounting data for this component.
     */
    async componentDidMount() {
        await this.loadComponent();
    }

    async componentDidUpdate(prevProps) {
        if(prevProps.resource_edit_filters.plant_uuid !== this.props.resource_edit_filters.plant_uuid) {
            await this.loadComponent();
        }

        if (prevProps.resource_edit_filters.lines_title_filter !== this.props.resource_edit_filters.lines_title_filter) {
            const { plant_uuid, lines_title_filter } = this.props.resource_edit_filters;

            this.setState({
                selected: this.selectLine(lines_title_filter, plant_uuid)
            });
        }
    }
    /**
     * Deleting a selected line.
     *
     * @param {string} uuid Line universal unique identifier.
     */
    handleToDelete = async (uuid: string) => {
        try {
            await getBackend().manufacturing.deleteLine({ line_uuid: uuid });
            await forceManufacturingCacheRefresh();
            this.handleToUpdate();
        } catch (err) {
            this.handleToError(err.message);
        }
    }

    /**
     * Select the first line that matches all the given criteria.
     * @param {string} contentFilter Line search term.
     * @param {string} plantUuid Plant UUID.
     * @param {ILineData[]} [lines] List of lines to select from.
     */
    selectLine(contentFilter: string, plantUuid: string, lines: ILineData[] = this.state.lines): ILineData | null {
        for (let line of lines) {
            const visible = this.linesListRef.current && this.linesListRef.current.includes(contentFilter, line);

            if ((plantUuid === line.plant_uuid || plantUuid === "") && visible) {
                return line;
            }
        }

        return null;
    }


    /**
     * Handling change in any input component.
     */
    handleInputChange = (event: Event) => {
        if (event.currentTarget instanceof HTMLInputElement || event.currentTarget instanceof HTMLSelectElement) {
            // read new values
            const target = event.currentTarget;
            const value = target.value;
            const name = target.name;

            if (name !== "plant_uuid") {
                // update new datasource placeholder
                this.setState(prev_state => {
                    prev_state[name] = value;
                    return prev_state;
                });
            }

            let titleFilter = this.props.resource_edit_filters.lines_title_filter;
            let plantUuid = this.props.resource_edit_filters.plant_uuid;

            if (name === "plant_uuid") {
                plantUuid = value;
                const line = this.state.lines.filter(line => line.plant_uuid === plantUuid);
                const line_uuid = line.length > 0 ? line[0].uuid : "";

                Redirect.resourceEditFilters(
                    this.props.history,
                    "/digital-twin/resources/lines/" + line_uuid,
                    {
                        ...this.props.resource_edit_filters,
                        plant_uuid: plantUuid
                    }
                );
            } else if (name === "title_filter") {
                titleFilter = value;

                Redirect.resourceEditFilters(
                    this.props.history,
                    "/digital-twin/resources/lines/",
                    {
                        ...this.props.resource_edit_filters,
                        lines_title_filter: titleFilter
                    }
                );
            }

            this.setState({
                selected: this.selectLine(titleFilter, plantUuid)
            });
        }
    };

    /**
     * Handle `click` events for line items.
     */
    handleLineClick(line: ILineData) {
        this.setState({ selected: line });

        // Update URL.
        this.props.history.push(`/digital-twin/resources/lines/${line.uuid}`);
    }

    /**
     * Handle, that can be called by the children, to update the data.
     */
    handleToUpdate = () => {
        this.loadComponent();
    }

    /**
     * Handling error from subordinate component.
     */
    handleToError = (str: string) => {
        this.setState({ error: str });
    }

    /**
     * Rendering JSX for current component.
     */
    render() {
        const can_edit = Auth.isInRole(Auth.ROLE_ADMIN) || Auth.isInRole(Auth.ROLE_POWER_USER) || Auth.isInRole(Auth.ROLE_DEMO_USER);
        return (<ResourcesLayout>
                <ResourcesLayout.Main>
                    <ResourcesLayout.List>
                        <ResourcesLayout.Search>
                            <div className="form-group form-group-stretch">
                                <input type="text" className="form-control search_bar" placeholder={translate("common.search", "Search") + " ..."}
                                    name="title_filter" value={this.props.resource_edit_filters.lines_title_filter} onChange={this.handleInputChange} />
                            </div>
                            <div className="form-group">
                                <select name="plant_uuid" className="form-control select_filter_control" value={this.props.resource_edit_filters.plant_uuid} onChange={this.handleInputChange}>
                                    <option key={-1} value="">
                                        {translate("Manufacturing.Lines.all_plants", "All Plants")}
                                    </option>
                                    {
                                        this.state.plants.map((plant, i) => {
                                            return <option key={i} value={plant.uuid}>{plant.title}</option>
                                        })
                                    }
                                </select>
                            </div>
                            {can_edit && <div className="form-group">
                                <Link to="/digital-twin/resources/line/create" className="btn btn-inv-primary btn-icon btn-icon-add"
                                    title={translate("Manufacturing.Lines.add_new_line", "Add new line")}>
                                    <span className="sr-only">
                                        <FormattedMessage id="Manufacturing.Lines.add_new_line" defaultMessage="Add new line" />
                                    </span>
                                </Link>
                            </div>}
                        </ResourcesLayout.Search>
                        <LinesList
                            ref={this.linesListRef}
                            lines={this.state.lines}
                            selected={this.state.selected}
                            filter={this.props.resource_edit_filters.lines_title_filter}
                            plantFilter={this.props.resource_edit_filters.plant_uuid}
                            line_uuid_to_group_titles={this.state.line_uuid_to_group_titles}
                            handleLineClick={(line) => { this.handleLineClick(line) }} />
                    </ResourcesLayout.List>
                    <ResourcesLayout.Content>
                        <ErrorComponent msg={this.state.error} type="error" />
                        <ErrorComponent msg={this.state.warning} type="warning" />
                        {this.state.selected &&
                            <LinesDetails
                                selected={this.state.selected}
                                editLink="/digital-twin/resources/line/edit/"
                                kanbanLink="/digital-twin/resources/line/kanban/"
                                handleToDelete={this.handleToDelete}
                            />
                        }
                    </ResourcesLayout.Content>
            </ResourcesLayout.Main>
        </ResourcesLayout>
        )
    }

}

export default connect(
    state => {
        return { resource_edit_filters: state.resource_edit_filters }
    },
    null
)(LinesComponent);
