// @flow
import React from "react";
import Loader from "../../../Loader";
import { connect } from "react-redux";

import type { ReduxState } from "../reducers/reducers";
import type { ReportState } from "../reducers/report";

type Props = {
    report_loading: boolean,
    report_created_at: number,
    pane_id: string
}
type State = {}

const getReportCreatedAt = (report: ReportState) => {
    if (!report || !report.report_data) return null;
    return report.report_data.created_at;
}

class ReportLoader extends React.Component<Props, State> {

    render() {
        const pane = document.querySelector("#" + this.props.pane_id);
        let rect = null;

        // if a previous report has not been loaded then we show loader icon
        const show_loading = !this.props.report_created_at;

        let style = { display: "none" };

        if (pane && this.props.report_loading) {
            // if a previous report has already been loaded then we show the white background when loading a new report
            rect = pane.getBoundingClientRect();
            style = {
                backgroundColor: "white",
                opacity: "0.3",
                position: "absolute",
                width: rect.width,
                height: rect.height,
                display: "unset"
            }
        }

        return <div style={style}>
            {show_loading && <Loader small />}
        </div>;
    }
}

export default connect(
    (state: ReduxState, props: Props) => {
        const report = state.gantt_chart_report;
        return {
            report_loading: report.report_loading,
            report_created_at: getReportCreatedAt(report)
        }
    },
    null
)(ReportLoader);
