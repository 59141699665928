// @flow
import * as React from "react";
import { stringCompare } from "../lib/Util";
import type { SelectedDatasourceObj } from "../lib/Models";
import * as td from "../lib/backend/datasources.generated.types";
import ChevronListItem from "./react/ChevronListItem";

type Props = {
    selected: SelectedDatasourceObj | null,
    datasources: Array<td.IDatasourceRec>,
    handleDatasourceClick: (td.IDatasourceRec) => void
};

type State = {};

// Prepares a list of datasources to be displayed on datasource page as a scrollable side bar on the le
class DatasourceList extends React.Component<Props, State> {

    handleDatasourceClick(datasource: td.IDatasourceRec, i: number) {
        this.props.handleDatasourceClick(datasource);
    }

    // prepare list of datasources
    render() {
        // check what is the id of currently selected datasource so we can highlight it
        const selected_uuid = this.props.selected && this.props.selected.datasource ?
            this.props.selected.datasource.uuid : "";
        // render list of datasources to HTML
        const sortedDatasourceList = this.props.datasources.sort((a, b) => stringCompare(a.title, b.title));
        const datasourceList = sortedDatasourceList.map((datasource, i) => {
            const is_active = datasource.uuid === selected_uuid;
            return (
                <ChevronListItem
                    text={datasource.title}
                    is_active={is_active}
                    onClick={() => this.handleDatasourceClick(datasource, i)}
                />
            );
        });
        // pack inside list group and return
        return (
            <ul className="nav nav-tabs">
                <div className="nav-tabs-parent" ref="nav">
                    {datasourceList}
                </div>
            </ul>
        );
    }
}

export default DatasourceList;
